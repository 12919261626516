import React, {useState} from "react";
import {useRecoilState, useSetRecoilState} from "recoil";
import {Button, Col, Form, Input, Row, Space, Switch, Typography} from "antd";
import {notificationMessage, selfPermissionObj} from "../../../../../../../../../recoil_state";
import {useTranslation} from "react-i18next";
import {useIsMobile} from "../../../../../../../../_common/layout";
import {changePasswordMt5} from "../../../../../../../../api/graphql/tradingAccountMt5";

const AccountMt5ChangePassword = ({data, id}) => {
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const [loading, setLoading] = useState(false);
    const tradingAccountPermission = selfPermission?.tradingAccount?.account;
    const {t} = useTranslation();
    const isMobile = useIsMobile(768);
    const labelCol = {span: isMobile ? 24 : 8, offset: 0};
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [form] = Form.useForm();
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#!$%^&*()-_+=])[A-Za-z\d@#!$%^&*()-_+=]{8,}$/;

    const generateRandomPassword = () => {
        const lowerChars: string = "abcdefghijklmnopqrstuvwxyz";
        const upperChars: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const digits: string = "0123456789";
        const specialChars: string = "!@#$%^&*()_-+={[}]|;:'\",<.>/?";

        let password: string[] = [];

        // Add at least one lower case character
        password.push(lowerChars[Math.floor(Math.random() * lowerChars.length)]);

        // Add at least one upper case character
        password.push(upperChars[Math.floor(Math.random() * upperChars.length)]);

        // Add at least one digit
        password.push(digits[Math.floor(Math.random() * digits.length)]);

        // Add at least one special character
        password.push(specialChars[Math.floor(Math.random() * specialChars.length)]);

        // Fill the rest of the password with random characters
        const allChars: string = lowerChars + upperChars + digits + specialChars;
        while (password.length < 10) {
            password.push(allChars[Math.floor(Math.random() * allChars.length)]);
        }

        // Shuffle the password characters
        for (let i: number = password.length - 1; i > 0; i--) {
            const j: number = Math.floor(Math.random() * (i + 1));
            const temp: string = password[j];
            password[j] = password[i];
            password[i] = temp;
        }

        return password.join("");
    };

    const randomPasswordForMaster = () => {
        const password = generateRandomPassword();

        form.setFieldsValue({
            masterPassword: password,
        });
    };
    const randomPasswordForInvestor = () => {
        const password = generateRandomPassword();

        form.setFieldsValue({
            investorPassword: password,
        });
    };

    const onSubmit = async (value) => {
        await setLoading(true);
        try {
            if (!value?.masterPassword && !value?.investorPassword) {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t("tradingAccount.pleaseFillInAtLeastOneField"),
                    key: "changePasswordMt5",
                });

                return false;
            }

            const response = await changePasswordMt5({
                account: id,
                masterPassword: value?.masterPassword,
                investorPassword: value?.investorPassword,
                sendEmailNotification: value?.sendEmailNotification ? true : false,
            });

            if (response?.changePasswordMt5?.__typename === "TradingAccountChangePasswordMt5") {
                if (response?.changePasswordMt5?.masterPassword) {
                    if (response?.changePasswordMt5?.masterPassword.__typename === "TradingAccountChangePasswordMt5Status") {
                        await setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("tradingAccount.updateMasterPasswordSuccess"),
                            key: "changeMasterPasswordMt5",
                        });
                    } else if (response?.changePasswordMt5?.masterPassword.__typename === "BaseError") {
                        if (response?.changePasswordMt5?.masterPassword.errObj?.errObj?.MTRetCode === 3006) {
                            await setNotificationMessage({
                                status: "error",
                                title: "",
                                msg: t("tradingAccount.invalidMasterPassword"),
                                key: "changeMasterPasswordMt5",
                            });
                        } else {
                            await setNotificationMessage({
                                status: "error",
                                title: "",
                                msg: t(response?.changePasswordMt5?.masterPassword.errKey),
                                key: "changeMasterPasswordMt5",
                            });
                        }
                    }
                }

                if (response?.changePasswordMt5?.investorPassword) {
                    if (response?.changePasswordMt5?.investorPassword.__typename === "TradingAccountChangePasswordMt5Status") {
                        await setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("tradingAccount.updateInvestorPasswordSuccess"),
                            key: "changeInvestorPasswordMt5",
                        });
                    } else if (response?.changePasswordMt5?.investorPassword.__typename === "BaseError") {
                        if (response?.changePasswordMt5?.investorPassword.errObj?.errObj?.MTRetCode === 3006) {
                            await setNotificationMessage({
                                status: "error",
                                title: "",
                                msg: t("tradingAccount.invalidInvestorPassword"),
                                key: "changeInvestorPasswordMt5",
                            });
                        } else {
                            await setNotificationMessage({
                                status: "error",
                                title: "",
                                msg: t(response?.changePasswordMt5?.investorPassword.errKey),
                                key: "changeInvestorPasswordMt5",
                            });
                        }
                    }
                }
            } else if (response?.changePasswordMt5?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.changePasswordMt5?.errKey),
                    key: "changePasswordMt5",
                });
            } else if (response?.changePasswordMt5?.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.changePasswordMt5?.msg),
                    key: "changePasswordMt5",
                });
            }
        } finally {
            setLoading(false);
        }
    };

    const isInvestorAccount = data?.product?.productType?.includes("investor");

    return (
        <div id="AccountChangePassword">
            <Form form={form} onFinish={onSubmit}>
                <Row gutter={[16, 16]}>
                    {!isInvestorAccount &&
                        <>
                            <Col span={22}>
                                <Form.Item
                                    labelCol={labelCol}
                                    labelAlign={"left"}
                                    label={t("Master password")}
                                    name={"masterPassword"}
                                    rules={[{pattern: passwordRegex, message: t("admin.err.invalid_password_format")?.toString()}]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                <Button onClick={randomPasswordForMaster}>Random</Button>
                            </Col>
                        </>
                    }
                    <Col span={22}>
                        <Form.Item
                            labelCol={labelCol}
                            labelAlign={"left"}
                            label={t("Read-only(investor) password")}
                            name={"investorPassword"}
                            rules={[{pattern: passwordRegex, message: t("admin.err.invalid_password_format")?.toString()}]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Button onClick={randomPasswordForInvestor}>Random</Button>
                    </Col>
                    <Col span={22}>
                        <Form.Item
                            labelCol={labelCol}
                            labelAlign={"left"}
                            label={t("tradingAccount.send_email_notification") + ":"}
                            name={"sendEmailNotification"}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Typography.Paragraph className={"mt5-password-note mt-4"}>{t("tradingAccount.changeMt5PasswordNote")}</Typography.Paragraph>
                    </Col>
                </Row>

                <Col span={24} style={{marginTop: "2%"}}>
                    <Row justify={"end"}>
                        <Space>
                            <Button htmlType={"submit"} type={"primary"} loading={loading} disabled={loading}>
                                {t("common.submit")}
                            </Button>
                        </Space>
                    </Row>
                </Col>
            </Form>
        </div>
    );
};

export default AccountMt5ChangePassword;
