import {useTranslation} from "react-i18next";
import {getColumnSearchProps} from "../../../../components/table/function/filterTable";
import React from "react";

const LabelColumn = (props) => {
    const {text} = props;
    const {t} = useTranslation();
    return <p>{t(text)}</p>;
};

export const columns = [
    {
        title: "Label",
        dataIndex: "label",
        key: "label",
        // ...getColumnSearchProps(),
        render: (text) => <LabelColumn text={text} />,
    },
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        // ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Code",
        dataIndex: "code",
        key: "code",
        // ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Latitude",
        dataIndex: "latitude",
        key: "latitude",
        // ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Longitude",
        dataIndex: "longitude",
        key: "longitude",
        // ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
];
