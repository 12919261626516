import {atom} from "recoil";

const elementDrawerIsOpen = atom({
    key: 'elementDrawerIsOpen',
    default: false,
});

const layoutEditableState = atom({
    key: 'layoutEditableState',
    default: false,
});

const currentEditingElement = atom({
    key: 'currentEditingElement',
    default: "",
});


export {
    elementDrawerIsOpen,
    layoutEditableState,
    currentEditingElement
}