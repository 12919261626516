import React, {createRef, useEffect, useState} from "react";
import {Form, Modal, Tabs} from "antd";
import TriggerForm from "./forms/triggerForm";
import ActionForm from "./forms/actionForm";
import ConditionForm from "./forms/conditionForm";
import {createRule, updateRule} from "../../../../../../../api/restful/automationSetting/rule";
import {formatDateTimeTimezone} from "../../../../../../../function/_common";
import {createCustomAction, updateCustomAction} from "../../../../../../../api/restful/automationSetting/customAction";
import {useGetCustomAction, useGetRuleCondition, useGetRuleConditionSchedule} from "../../../../_common/function/useGetQueryHooks";
import {useTranslation} from "react-i18next";
import {
    createRuleCondition,
    createRuleConditionSchedule,
    deleteRuleCondition,
    deleteRuleConditionSchedule,
    updateRuleCondition,
    updateRuleConditionSchedule
} from "../../../../../../../api/restful/automationSetting/ruleCondition";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import {useGetEmailTemplateDropdown} from "../../../../../../messages/function/useGetQueryHooks";
import {dataByLanguageState, logicOperatorAryState, selectedEmailTemplateState} from "../../../state"
import {
    useAddEmailTemplateMutationWithoutNotification,
    useUpdateEmailTemplateMutationWithoutNotification
} from "../../../../../../../api/graphql/emailTemplate";
import dayjs from "dayjs";
import {isArray} from "@apollo/client/utilities";

const CreateUpdateModal = ({isOpen, handleClose, item, fetchActionTemplate, actionTemplate, trigger, fetchRule, actionTemplateReport}) => {
    const {t} = useTranslation()
    const isUpdate = !!item
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [triggerForm] = Form.useForm()
    const [actionForm] = Form.useForm()

    const [action, fetchAction] = useGetCustomAction()
    const [conditionValue, fetchConditionValue] = useGetRuleCondition()
    const [conditionScheduleValue, fetchConditionScheduleValue] = useGetRuleConditionSchedule()

    const [activeTab, setActiveTab] = useState<string>("trigger")
    const [type, setType] = useState<string>("")
    const [selectedTrigger, setSelectedTrigger] = useState<string>("")

    // CONDITION
    const [conditions, setConditions] = useState<any[]>([])
    const [logicOperatorAry, setLogicOperatorAry] = useRecoilState<any>(logicOperatorAryState)
    const [reportTargetedField, setReportTargetedField] = useState<string>("")

    // ACTION
    const [selectedAction, setSelectedAction] = useState<any>(null);
    const [emailTemplate, fetchEmailTemplate] = useGetEmailTemplateDropdown()

    // Send Email Action
    const selectedEmailTemplate = useRecoilValue<any>(selectedEmailTemplateState);
    const dataByLanguage = useRecoilValue<any>(dataByLanguageState)
    const {addEmailTemplate} = useAddEmailTemplateMutationWithoutNotification();
    const {updateEmailTemplate} = useUpdateEmailTemplateMutationWithoutNotification();

    const handleTriggerOnChange = (value) => {
        const found = trigger?.find(d => d._id === value)
        setSelectedTrigger(found)
        fetchActionTemplate(value)
    }

    const triggerFormProps = {
        form: triggerForm,
        trigger: trigger,
        isUpdate: isUpdate,
        item: item,
        type: type,
        handleTriggerOnChange: handleTriggerOnChange,
        setType: setType
    }

    const conditionFromProps = {
        type: type,
        selectedTrigger: selectedTrigger,
        conditions: conditions,
        setConditions: setConditions,
        isUpdate: isUpdate,
        isOpen: isOpen,
        reportTargetedField: reportTargetedField,
        setReportTargetedField: setReportTargetedField
    }

    const actionFormProps = {
        form: actionForm,
        actionList: type === "trigger" ? actionTemplate : type === "schedule_report" ? actionTemplateReport : [],
        selectedAction: selectedAction,
        isUpdate: isUpdate,
        setSelectedAction: setSelectedAction,
        mode: isUpdate ? "edit" : "create",
        emailTemplate: emailTemplate
    }

    const tabItems: any = [
        {
            key: "trigger",
            label: "Trigger",
            forceRender: true,
            children: <TriggerForm {...triggerFormProps}/>,
        },
        {
            key: "conditions",
            label: "Conditions",
            forceRender: true,
            children: <ConditionForm {...conditionFromProps}/>
        },
        {
            key: "actions",
            label: "Actions",
            forceRender: true,
            children: <ActionForm {...actionFormProps}/>
        }
    ]

    const extractFilters = (data) => {
        const filters: any = [];

        // Loop through the keys in the data
        for (const key in data) {
            // Check if the key starts with "filters_" and ends with "_fieldName"
            if (key.startsWith('filters_') && key.endsWith('_fieldName')) {
                // Extract the filter number from the key
                const filterNum = key.split('_')[1];

                // Build the filter object
                const filter = {
                    fieldName: data[key],
                    operator: data[`filters_${filterNum}_operator`],
                    value: [data[`filters_${filterNum}_value`]],
                };

                // Add it to the filters array
                filters.push(filter);
            }
        }

        return filters;
    }

    const onSubmitHandler = async () => {
        const triggerValue = triggerForm.getFieldsValue()
        const actionValue = actionForm.getFieldsValue()
        const actionCopy = {...selectedAction}

        let ruleId = item?._id

        try {
           await triggerForm.validateFields()

            const {triggerName, type, customTrigger, isEnabled, repetition, periodDay, periodMin, periodHour, eventStartDate, eventEndDate} = triggerValue
            const ruleInput = {
                type, customTrigger, isEnabled,
                name: triggerName,
                setting: {
                    repetition, periodDay, periodMin, periodHour,
                    eventStartDate: eventStartDate ? formatDateTimeTimezone(eventStartDate, "toGMT") : null,
                    eventEndDate: eventEndDate ? formatDateTimeTimezone(eventEndDate, "toGMT") : null,
                }
            }
            if (type === "schedule_report") {
                ruleInput["reportTargetedField"] = reportTargetedField
            }

            // EMAIL ACTION
            let templateName = selectedEmailTemplate?.name
            let extraActionInput = {}

            if (selectedAction?.name === "Send Email" && templateName) {
                const htmlObject = Object.keys(dataByLanguage)?.filter(d => d.includes("content") && dataByLanguage[d])?.map(d => {
                    const lang = d.split("_")[1]

                    return {
                        html: dataByLanguage[d],
                        language: lang,
                        head: null,
                        attachment: []
                    }
                })

                const subjectObject = Object.keys(actionValue)?.filter(d => d.includes("subject") && actionValue[d])?.map(d => {
                    const lang = d.split("_")[1]

                    return {
                        label: actionValue[d],
                        language: lang,
                    }
                })

                const emailTemplateInput = {
                    displayName: `${selectedEmailTemplate?.name}_automation_${Date.now()}`,
                    from: actionValue?.from,
                    subject: selectedEmailTemplate?.subject,
                    htmlObject: htmlObject,
                    emailType: actionValue?.emailType,
                    displaySubjectLanguage: subjectObject,
                    type: "message",
                    isFixedEmail: actionValue?.isFixedEmail,
                    isActive: true,
                }

                if (isUpdate) {
                    const {data: response} = await updateEmailTemplate(selectedEmailTemplate?._id, emailTemplateInput)
                    if (response?.updateEmailTemplate?.__typename === "EmailTemplate") {
                        fetchEmailTemplate()
                    } else {
                        return
                    }
                } else {
                    const {data: response} = await addEmailTemplate(emailTemplateInput)
                    if (response?.addEmailTemplate?.__typename === "EmailTemplate") {
                        fetchEmailTemplate()
                        templateName = response?.addEmailTemplate?.name
                    } else {
                        return
                    }
                }

                extraActionInput = {
                    body: actionCopy.body?.map(item => {
                        const isExtraValueExist = Object.keys(actionValue)?.find(d => d === `${item.fieldName}_extraMultiSelect`)
                        let value = actionValue[item.fieldName]
                        if (item.fieldName === "name") value = templateName
                        if (isExtraValueExist) {
                            const oriValue = isArray(actionValue[item?.fieldName]) ? actionValue[item?.fieldName] : []
                            value = [...oriValue, ...(actionValue?.[`${item.fieldName}_extraMultiSelect`]?.split(","))].filter(d => d)
                        }
                        return {
                            ...item,
                            value: value
                        }
                    }),
                }
            }

            if (!isUpdate) {
                const res = await createRule(ruleInput)

                if (res?.status === "Success") {
                    ruleId = res?.data?._id

                    const actionInput = {
                        body: actionValue?.body,
                        isSystem: actionCopy?.isSystem,
                        method: actionCopy?.method,
                        name: actionCopy?.name,
                        params: actionCopy?.params,
                        reportOnly: actionCopy?.reportOnly,
                        triggers: actionCopy?.triggers,
                        url: actionCopy?.url,
                        ...extraActionInput,
                        rule: ruleId
                    }

                    if (actionValue) {
                        const actionResponse = await createCustomAction(actionInput)
                    }

                    //Create rule successfully
                } else {
                    //Create rule failed
                }
            } else {
                const res = await updateRule({
                    ...ruleInput,
                    _id: item?._id
                })

                const actionInput = {
                    body: actionValue?.body,
                    isSystem: actionCopy?.isSystem,
                    method: actionCopy?.method,
                    name: actionCopy?.name,
                    params: actionCopy?.params,
                    reportOnly: actionCopy?.reportOnly,
                    triggers: actionCopy?.triggers,
                    url: actionCopy?.url,
                    ...extraActionInput,
                    rule: ruleId,
                    _id: action?._id
                }

                if (actionValue) {
                    const actionResponse = await updateCustomAction(actionInput)
                }
            }

            if (ruleId && conditions?.length > 0 && (conditions[0]?.condition?.[0]?.value || conditions[0]?.condition?.[0]?.report)) {
                if (type === "trigger") {
                    const promise = conditions.map((group, index) => {
                        if (group?.isDeleted && group?.id) {
                            return deleteRuleCondition({_id: group?.id})
                        } else if (group?.id) {
                            return updateRuleCondition({
                                _id: group?.id,
                                rule: ruleId,
                                joinType: logicOperatorAry?.[index]?.toLowerCase() || "and",
                                criteria: group?.condition?.map((d, i) => {
                                    const value = d?.dataType === "date" && d?.value ? formatDateTimeTimezone(d?.value, "formSubmit") : d?.value?.toString()

                                    return {
                                        field: d?.field,
                                        operator: d?.comparisonOperator,
                                        value: value,
                                        dataType: d?.dataType,
                                        joinType: group?.logicalOperator?.toLowerCase() || "and"
                                    }
                                })
                            })
                        } else {
                            return createRuleCondition({
                                rule: ruleId,
                                joinType: logicOperatorAry?.[index]?.toLowerCase() || "and",
                                criteria: group?.condition?.map((d, i) => {
                                    const value = d?.dataType === "date" && d?.value ? formatDateTimeTimezone(d?.value, "formSubmit") : d?.value?.toString()

                                    return {
                                        field: d?.field,
                                        operator: d?.comparisonOperator,
                                        value: value,
                                        dataType: d?.dataType,
                                        joinType: group?.logicalOperator?.toLowerCase() || "and"
                                    }
                                })
                            })
                        }

                    })

                    const conditionsResponse = await Promise.all(promise)

                    const errAction: any[] = []
                    if (conditionsResponse?.length > 0) {
                        conditionsResponse?.map(d => {
                            if (d?.status !== "Success") {
                                errAction.push("error")
                            }
                        })

                        if (errAction.length > 0) {
                            setNotificationMessage({
                                status: "success",
                                title: "",
                                msg: isUpdate ? "Update Automation Setting Successfully, Conditions Update Failed" : "Create Automation Setting Successfully, Conditions Update Failed",
                                key: "automationSetting",
                            });

                            return
                        } else {
                            setNotificationMessage({
                                status: "success",
                                title: "",
                                msg: isUpdate ? "Update Automation Rule and Conditions Successfully" : "Create Automation Rule and Conditions Successfully",
                                key: "automationSetting",
                            });
                            return
                        }
                    }
                } else if (type === "schedule_report") {
                    const promise = conditions.map((group, index) => {
                        if (group?.isDeleted && group?.id) {
                            return deleteRuleConditionSchedule({_id: group?.id})
                        } else if (group?.id) {
                            return updateRuleConditionSchedule({
                                _id: group?.id,
                                rule: ruleId,
                                joinType: logicOperatorAry?.[index]?.toLowerCase() || "and",
                                criteria: group?.condition?.map((d, i) => {
                                    const filters = extractFilters(d)

                                    return {
                                        reportId: d?.report,
                                        fromDate: d?.date?.[0] ? dayjs(d?.date?.[0]).format("YYYY-MM-DD") : null,
                                        toDate: d?.date?.[1] ? dayjs(d?.date?.[1]).format("YYYY-MM-DD") : null,
                                        reportBody: {
                                            filters: filters
                                        },
                                        joinType: d?.logicalOperator?.toLowerCase() || "and"
                                    }
                                })
                            })
                        } else {
                            return createRuleConditionSchedule({
                                rule: ruleId,
                                joinType: logicOperatorAry?.[index]?.toLowerCase() || "and",
                                criteria: group?.condition?.map((d, i) => {
                                    const filters = extractFilters(d)

                                    return {
                                        reportId: d?.report,
                                        fromDate: d?.date?.[0] ? dayjs(d?.date?.[0]).format("YYYY-MM-DD") : null,
                                        toDate: d?.date?.[1] ? dayjs(d?.date?.[1]).format("YYYY-MM-DD") : null,
                                        reportBody: {
                                            filters: filters
                                        },
                                        joinType: d?.logicalOperator?.toLowerCase() || "and"
                                    }
                                })
                            })
                        }

                    })

                    const conditionsResponse = await Promise.all(promise)

                    const errAction: any[] = []
                    if (conditionsResponse?.length > 0) {
                        conditionsResponse?.map(d => {
                            if (d?.status !== "Success") {
                                errAction.push("error")
                            }
                        })

                        if (errAction.length > 0) {
                            setNotificationMessage({
                                status: "success",
                                title: "",
                                msg: isUpdate ? "Update Automation Setting Successfully, Conditions Update Failed" : "Create Automation Setting Successfully, Conditions Update Failed",
                                key: "automationSetting",
                            });

                            return
                        } else {
                            setNotificationMessage({
                                status: "success",
                                title: "",
                                msg: isUpdate ? "Update Automation Rule and Conditions Successfully" : "Create Automation Rule and Conditions Successfully",
                                key: "automationSetting",
                            });
                            return
                        }
                    }

                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: isUpdate ? "Update Automation Rule Successfully" : "Create Automation Rule Successfully",
                        key: "automationSetting",
                    });
                }
            }

            fetchRule()
            handleClose()
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (!isOpen) {
            setReportTargetedField("")
            setActiveTab("trigger")
            setType("")
            setSelectedAction(null)
            setConditions([])
            setSelectedTrigger("")
            setLogicOperatorAry([])
            triggerForm.resetFields()
            actionForm.resetFields()
            return
        }

        if (item) {
            handleTriggerOnChange(item?.customTrigger?._id)
            fetchAction({rule: item?._id})
            if (item?.type === "trigger") {
                fetchConditionValue({rule: item?._id})
            } else if (item?.type === "schedule_report") {
                fetchConditionScheduleValue({rule: item?._id})
                setReportTargetedField(item?.reportTargetedField)
            }
            setType(item.type)
            triggerForm.setFieldsValue({
                ...item,
                triggerName: item?.name,
                customTrigger: item?.customTrigger?._id,
                ...item?.setting,
                eventStartDate: item?.setting?.eventStartDate ? formatDateTimeTimezone(item?.setting?.eventStartDate, "formValue") : null,
                eventEndDate: item?.setting?.eventEndDate ? formatDateTimeTimezone(item?.setting?.eventEndDate, "formValue") : null,
            })
        }
    }, [isOpen, item])

    useEffect(() => {
        if (conditionValue?.length > 0) {
            const initialConditions = conditionValue?.slice()?.sort((a, b) => a?.position - b?.position)?.map((item, index) => {
                setLogicOperatorAry(prev => [...prev, item?.joinType])

                return {
                    batchId: index + 1,
                    id: item?._id,
                    condition: item?.criteria?.slice()?.sort((a, b) => a?.position - b?.position)?.map((d, i) => ({
                        comparisonOperator: d?.operator,
                        field: d?.field,
                        target: `${d?.targetSource} / ${d?.targetReference}`,
                        logicalOperator: d?.joinType,
                        value: d?.value,
                        position: d?.position
                    })),
                    form: createRef(),
                    position: item?.position
                }
            })

            setConditions(initialConditions)
        } else {
            setConditions([])
        }
    }, [conditionValue, type]);

    function formatToFiltersObject(filtersArray) {
        const formattedData = {};

        // Loop through the array of filter objects
        filtersArray?.forEach((filter, index) => {
            const filterNumber = index + 1; // Starts filter numbering from 1

            // Assign the fields to the appropriate keys
            formattedData[`filters_${filterNumber}_fieldName`] = filter.fieldName;
            formattedData[`filters_${filterNumber}_operator`] = filter.operator;
            [formattedData[`filters_${filterNumber}_value`]] = filter.value;
        });

        return formattedData;
    }

    useEffect(() => {
        if (conditionScheduleValue?.length > 0) {
            const initialConditions = conditionScheduleValue?.slice()?.sort((a, b) => a?.position - b?.position)?.map((item, index) => {
                setLogicOperatorAry(prev => [...prev, item?.joinType])

                return {
                    batchId: index + 1,
                    id: item?._id,
                    condition: item?.criteria?.slice()?.sort((a, b) => a?.position - b?.position)?.map((d, i) => {
                        return {
                            logicalOperator: d?.joinType,
                            position: d?.position,
                            date: [d?.fromDate ? dayjs(d?.fromDate) : null, d?.toDate ? dayjs(d?.toDate) : null],
                            report: d?.reportId,
                            ...formatToFiltersObject(d?.reportBody?.filters)
                        }
                    }),
                    form: createRef(),
                    position: item?.position
                }
            })

            setConditions(initialConditions)
        }
    }, [conditionScheduleValue]);

    useEffect(() => {
        const templates = type === "trigger" ? actionTemplate : actionTemplateReport

        if (action && isOpen && templates) {

            const found = templates?.find(d => d?.name === action?.name)
            setSelectedAction(found)

            if (found?.name === "Send Email") {
                const bodyValue = {}
                action?.body?.map(d => {
                    bodyValue[d?.fieldName] = d?.value
                })

                actionForm.setFieldsValue({
                    action: action?.name,
                    ...bodyValue
                })
            } else {
                actionForm.setFieldsValue({
                    ...action,
                    action: action?.name,
                })
            }
        }
    }, [action, JSON.stringify(actionTemplate), JSON.stringify(actionTemplateReport)]);

    useEffect(() => {
        fetchEmailTemplate()
    }, []);

    return (
        <Modal
            open={isOpen}
            onCancel={handleClose}
            title={isUpdate ? "" : "Create Rule"}
            className={"create-update-automation-modal"}
            getContainer={"#automation-setting"}
            maskClosable={false}
            onOk={onSubmitHandler}
            okText={t("common.submit")}
            destroyOnClose
        >
            <Tabs
                items={tabItems}
                onTabClick={key => setActiveTab(key)}
                activeKey={activeTab}
            />
        </Modal>
    )
}

export default CreateUpdateModal