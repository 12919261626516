import React, {useEffect, useState} from "react";
import {Button, Space, Tabs, Tooltip} from "antd";
import SymbolsTable_1 from "./component/symbolsTable";
import ExportTableButton from "../../../../../../components/table/function/exportTable";
import {Link} from "react-router-dom";
import EditTableUserPreferenceButton from "../../../../../../components/table/function/editTableUserPreferenceButton";
import {SyncOutlined} from "@ant-design/icons";
import {useRefreshSymbolMutation} from "../../../../../../api/graphql/symbol";
import {useTranslation} from "react-i18next";
import useAuthorize from "../../../../../../_common/authorize";
import SymbolsMt5Table from "./component/symbolsMt5Table";
import {refreshSymbolsMt5} from "../../../../../../api/graphql/symbolMt5";
import {useRecoilState} from "recoil";
import {symbolMt5ReloadState} from "../../state";
import {columnsMT5} from "../../function/tableColumn_Mt5";

const Symbols_1 = (props) => {
    const {data, permission} = props;
    const {t} = useTranslation();
    const [exportData, setExportData] = useState(null);
    const [exportColumn, setExportColumn] = useState(null);
    const [currentServer, setCurrentServer] = useState(
        data?.getServerSetting?.data?.[0]
    );
    const [mt5RefreshLoading, setMt5RefreshLoading] = useState(false)
    const [mt5TableRefresh, setMt5TableRefresh] = useRecoilState(symbolMt5ReloadState)
    const [mt5ExportData, setMt5ExportData] = useState<any>([])
    const [mt5ExportLoading, setMt5ExportLoading] = useState(false)
    const [exportLoading, setExportLoading] = useState(false)
    const [serverList, setServerList] = useState<any>([])
    const [currentSymbolTab, setCurrentSymbolTab] = useState<any>(0)

    const tableName = "Symbols";
    const tableNameMt5 = "Mt5 Symbols"
    const columnMt5 = columnsMT5

    const {refreshSymbol, loading} = useRefreshSymbolMutation();
    const {systemErrorNotification} = useAuthorize();

    const handleSymbolMt5Refresh = async (serverId) => {
        try {
            await setMt5RefreshLoading(true)
            await refreshSymbolsMt5(serverId)
        } catch (e) {
            systemErrorNotification();
        } finally {
            setMt5RefreshLoading(false)
            setMt5TableRefresh(true)
        }
    }

    const handleSymbolRefresh = (id) => {
        try {
            refreshSymbol(id);
        } catch (e) {
            systemErrorNotification();
        }
    };

    const addServerList = async (list) => {
        const listing: any = []


        list?.map((item, i) => {
            if (item?.platform === "mt5" || item?.platform === "xTrader") {
                listing.push({
                    label: t(item?.name),
                    key: i,
                    children: (
                        <SymbolsMt5Table
                            tableNameMt5={tableNameMt5}
                            columnMt5={columnMt5}
                            server={item}
                            setMt5ExportData={setMt5ExportData}
                            setMt5ExportLoading={setMt5ExportLoading}
                        />
                    ),
                })
            } else {
                listing.push(
                    {
                        label: t(item?.name),
                        key: i,
                        children: (
                            <SymbolsTable_1
                                server={item}
                                setExportData={setExportData}
                                setExportColumn={setExportColumn}
                                tableName={tableName}
                                currentServer={currentServer}
                                setExportLoading={setExportLoading}
                            />
                        ),
                    }
                )
            }

        })

        setServerList(listing)
    }

    useEffect(() => {
        if (data?.getServerSetting?.data?.length > 0) {
            setCurrentServer(data?.getServerSetting?.data[0]);
        }
    }, [data?.getServerSetting?.data?.[0]]);


    useEffect(() => {
        addServerList(data?.getServerSetting?.data)
    }, [data?.getServerSetting?.data])

    return (
        <div id="symbols">
            <h1>{t(tableName)}</h1>

            {data && data?.getServerSetting?.data && (
                <Tabs
                    defaultActiveKey="1"
                    size={"small"}
                    items={serverList}
                    onChange={(key) => setCurrentSymbolTab(key)}
                    tabBarExtraContent={
                        (currentServer?.platform === "mt5" || currentServer?.platform === "xTrader") ? (
                            <>
                                <Space>
                                    {permission?.symbol?.view && (
                                        <Button>
                                            <Link to="/settings/symbols/mapping">
                                                {t("Symbol Mapping")}
                                            </Link>
                                        </Button>
                                    )}
                                    {permission?.symbol?.view && (
                                        <Button>
                                            <Link to="/settings/symbols/category">
                                                {t("Symbol Category")}
                                            </Link>
                                        </Button>
                                    )}
                                    <EditTableUserPreferenceButton
                                        tableName={tableNameMt5}
                                        tableColumn={columnMt5}
                                    />
                                    <ExportTableButton
                                        loading={mt5ExportLoading}
                                        tableData={mt5ExportData}
                                        tableColumn={columnMt5}
                                        tableName={tableNameMt5}
                                        server={currentServer}
                                    />
                                    {permission?.symbol?.refreshData && (
                                        <Tooltip title={t("Refresh Symbol")}>
                                            <Button
                                                type={"link"}
                                                loading={mt5RefreshLoading}
                                                disabled={mt5RefreshLoading}
                                                onClick={() => handleSymbolMt5Refresh(currentServer.id)}
                                                icon={<SyncOutlined/>}
                                            />
                                        </Tooltip>
                                    )}
                                </Space>
                            </>
                        ) : (
                            <>
                                <Space>
                                    {permission?.symbol?.view && (
                                        <Button>
                                            <Link to="/settings/symbols/mapping">
                                                {t("Symbol Mapping")}
                                            </Link>
                                        </Button>
                                    )}
                                    {permission?.symbol?.view && (
                                        <Button>
                                            <Link to="/settings/symbols/category">
                                                {t("Symbol Category")}
                                            </Link>
                                        </Button>
                                    )}
                                    <EditTableUserPreferenceButton
                                        tableName={tableName}
                                        server={currentServer}
                                        tableColumn={exportColumn}
                                    />
                                    <ExportTableButton
                                        loading={exportLoading}
                                        tableData={exportData}
                                        tableColumn={exportColumn}
                                        tableName={tableName}
                                        server={currentServer}
                                    />
                                    {permission?.symbol?.refreshData && (
                                        <Tooltip title={t("Refresh Symbol")}>
                                            <Button
                                                type={"link"}
                                                loading={loading}
                                                disabled={loading}
                                                onClick={() => handleSymbolRefresh(currentServer?.id)}
                                                icon={<SyncOutlined/>}
                                            />
                                        </Tooltip>
                                    )}
                                </Space>
                            </>
                        )
                    }
                    onTabClick={(key) => {
                        setCurrentServer(data?.getServerSetting?.data[key]);
                    }}
                />
            )}
        </div>
    );
};

export default Symbols_1;
