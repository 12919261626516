import React, {useEffect, useState} from "react";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {Button, Col, Divider, Form, Row, Tabs} from "antd";
import {CloseOutlined, PlusOutlined, SaveOutlined} from "@ant-design/icons";
import yupFormSchema from "../../../../../components/yup/yupFormSchema";
import { useAddVoucherGroupMutation, useUpdateVoucherGroupMutation } from "../../../../../api/graphql/voucherGroup";
import useAuthorize from "../../../../../_common/authorize";
import InputFormItem from "../../../../../components/form/inputFormItem";
import { verticalFormLayout } from "../../../../../components/form/formWrapper";
import CheckboxFormItem from "../../../../../components/form/checkboxFormItem";
import VoucherGroupPool from "./voucherGroupPool";
import RadioGroupFormItem from "../../../../../components/form/radioGroupFormItem";
import SelectFormItem from "../../../../../components/form/selectFormItem";
import { clean_value, getDataWithLanguage, handleOnChangeWithLanguage } from "../function/common";
import TextAreaInputFormItem from "../../../../../components/form/textAreaInputFormItem";
import UploadSingleFormItem from "../../../../../components/form/uploadSingleFormItem";
import { notificationMessage } from "../../../../../../recoil_state";
import { useSetRecoilState } from "recoil";
import { getConfigByName } from "../../../../../../config";
import { BRAND_NAME } from "../../../../../../config/brandVariable";


const VoucherGroupForm = (props) => {
    const {closeFormModal, item, mode, languageData } = props;
    let backendURL = getConfigByName(BRAND_NAME).backendURL;
    const getDefaultLogoUrl = () => {
        return {
            fileList: [{url: `${backendURL}/assets/icon/activitiesRewards/voucher-reward.png`, response: [{link: `${backendURL}/assets/icon/activitiesRewards/voucher-reward.png`}]}],
            path: `${backendURL}/assets/icon/activitiesRewards/voucher-reward.png`
        }
    };

    const {t} = useTranslation();
    const [errMsg, setErrMsg] = useState("");
    const [poolValue, setPoolValue] = useState(item?.pool?.map(item => {
        return {
            voucher: item?.voucher?._id,
            maximumRedeem: item?.maximumRedeem,
            probability: item?.probability 
        }
    }) || [])
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [imageLight, setImageLight] = useState(item?.imageLight? item?.imageLight[0] : getDefaultLogoUrl())
    const [imageDark, setImageDark] = useState(item?.imageDark? item?.imageDark[0] : getDefaultLogoUrl())
    const [rewardValueOptions, setRewardValueOptions] = useState([{key: "all", value: "all", label: "All"}])
    const [activeTab, setActiveTab] = useState("0");
    const [languageObj, setLanguageObj] = useState<any>({
        displayName: item?.displayName || [],
        description: item?.description || [],
        termAndCondition: item?.termAndCondition || [],
    })

    const [initialValues] = useState(() => ({
        displayName: item?.displayName || [],
        name: item?.name || "",
        description: item?.description || [],
        termAndCondition: item?.termAndCondition || [],
        termAndConditionEnabled: item?.termAndConditionEnabled || false,
        isRandomReward: item?.isRandomReward || false,
        rewardValue: item?.rewardValue || null,
        pool: item?.pool || [],
    }));

    useEffect(()=> {
        let result = [{key: "all", value: "all", label: "All"}]
        if(poolValue && poolValue.length > 0){
            let temp = poolValue?.map((_, index) => {
                return {
                    key: index + 1,
                    label: "Any " + (index + 1),
                    value: (index + 1).toString()
                }
            })
            if(temp && temp.length > 0){
                temp = temp.filter((item)=> item.key !== poolValue.length)
                result.push(...temp)
            }
        }
        setRewardValueOptions(result)
    }, [poolValue])

    let schemaObj: any = {
        name: yupFormSchema.string("name", {required: true}),
        isRandomReward: yupFormSchema.boolean("isRandomReward"),
        rewardValue: yupFormSchema.string("rewardValue")
    };

    const voucherGroupSchema = yup.object().shape(schemaObj);

    const form = useForm({
        resolver: yupResolver(voucherGroupSchema),
        mode: "all",
        defaultValues: initialValues,
    });

    const onCancel = () => {
        closeFormModal && closeFormModal();
    };
    
    const uploadOnChange = (e, field) => {
        try {
        if (e?.file?.status === "uploading") {
            switch(field){
                case "light":
                    setImageLight(prev => {
                        return {
                            ...prev,
                            fileList: e?.fileList
                        }
                    })
                    break;
                case "dark":
                    setImageDark(prev => {
                        return {
                            ...prev,
                            fileList: e?.fileList
                        }
                    })
                    break;
            }
        }
        if (e?.file?.status === "done") {
            switch(field){
                case "light":
                    setImageLight(prev => {
                        return {
                            ...prev,
                            fileList: e?.fileList,
                            path: e?.file?.response[0]?.link
                        }
                    })
                    break;
                case "dark":
                    setImageDark(prev => {
                        return {
                            ...prev,
                            fileList: e?.fileList,
                            path: e?.file?.response[0]?.link
                        }
                    })
                    break;
            }

            setNotificationMessage({
            status: "success",
            title: "",
            msg: `${e?.file?.name} file uploaded successfully`,
            key: "updateDesk",
            });
        } else if (e?.file?.status === "error") {
            switch(field){
                case "light":
                    setImageLight(null)
                    break;
                case "dark":
                    setImageDark(null)
                    break;
            }

            setNotificationMessage({
            status: "error",
            title: "",
            msg: `${e?.file?.name} file upload failed. - ${e?.file?.response}`,
            key: "updateDesk",
            });
        }
        } catch (e) {
        systemErrorNotification();
        }
    };

    const addToPool = () => {
        setPoolValue(prev => ([
            ...prev,
            {
                voucher: null,
                maximumRedeem: null,
                probability: null 
            }]
        ))
    }
    const handleOnchange = (e, fieldName, fieldType = "content", languageCode) => {
        handleOnChangeWithLanguage(e, fieldName, fieldType, languageCode, setLanguageObj);
    };

    const {addVoucherGroup} = useAddVoucherGroupMutation();
    const {updateVoucherGroup} = useUpdateVoucherGroupMutation();
    const {systemErrorNotification} = useAuthorize();

    const onSubmit = async (values) => {
        try {
            setErrMsg("");
            const cleanValue = clean_value(values)
            const result = {
                ...cleanValue,
                displayName: languageObj?.displayName || [],
                description: languageObj?.description || [],
                termAndCondition: languageObj?.termAndCondition || [],
                pool: poolValue,
                imageLight: imageLight,
                imageDark: imageDark
            };

            const response = mode === "add" ? await addVoucherGroup(result) : await updateVoucherGroup(item?._id, result);
            const {data} = response;
            const res = mode === "add" ? data?.addVoucherGroup : data?.updateVoucherGroup;
            if (res?.__typename === "BaseError") {
                let errMsg = t(res?.errKey);
                setErrMsg(errMsg);
            } else {
                closeFormModal && closeFormModal();
            }
        } catch (e: any) {
            systemErrorNotification();
        }
    };

    const tabItems = languageData?.map((lang, index) => {
        return {
            key: index?.toString(),
            label: lang?.name,
            children: (
                <Row gutter={16}>
                    <Col xs={24} md={12}>
                        <Col className="gutter-row" xs={24}>
                            <InputFormItem
                                name={`dislayName_${lang.name}`}
                                label={"Display Name"}
                                placeholder={"Display Name"}
                                layout={verticalFormLayout}
                                onChange={(e)=> handleOnchange(e, "displayName", "content", lang.code)}
                                value={getDataWithLanguage(item, "displayName", "content", lang.code)}
                                disabled={mode === "view"}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24}>
                            <InputFormItem
                                name={`description_${lang.name}`}
                                label={"Description"}
                                placeholder={"Description"}
                                layout={verticalFormLayout}
                                onChange={(e)=> handleOnchange(e, "description", "content", lang.code)}
                                value={getDataWithLanguage(item, "description", "content", lang.code)}
                                disabled={mode === "view"}
                            />
                        </Col>
                    </Col>
                    {
                        form.getValues("termAndConditionEnabled") &&
                        <Col xs={24} md={12}>
                            <Col className="gutter-row" xs={24}>
                                <TextAreaInputFormItem
                                    name={`termAndCondition_${lang.name}`}
                                    label={"Term and Conditions"}
                                    placeholder={"Term and Conditions"}
                                    layout={verticalFormLayout}
                                    onChange={(e)=> handleOnchange(e, "termAndCondition", "content", lang.code)}
                                    value={getDataWithLanguage(item, "termAndCondition", "content", lang.code)}
                                    disabled={mode === "view"}
                                />
                            </Col>
                        </Col>
                    }
                </Row>
            ),
        };
    });

    return (
        <div>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="voucherGroup-form-1">
                    {errMsg && <p style={{color: "red"}}>{errMsg}</p>}
                    <Row gutter={16}>
                        <Col className="gutter-row" xs={24} md={12}>
                            <UploadSingleFormItem
                                name={`imageLight`}
                                label={t("Image Light")}
                                layout={verticalFormLayout}
                                listType={"picture-card"}
                                mode={"activities"}
                                fileList={imageLight?.fileList}
                                onChange={(e) =>
                                    uploadOnChange(e, "light")
                                }
                                onRemove={(e) =>
                                    setImageLight(null)
                                }
                                disabled={mode === "view"}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} md={12}>
                            <UploadSingleFormItem
                                name={`imageDark`}
                                label={t("Light Dark")}
                                layout={verticalFormLayout}
                                listType={"picture-card"}
                                mode={"activities"}
                                fileList={imageDark?.fileList}
                                onChange={(e) =>
                                    uploadOnChange(e, "dark")
                                }
                                onRemove={(e) =>
                                    setImageDark(null)
                                }
                                disabled={mode === "view"}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} md={12}>
                            <InputFormItem
                                name={"name"}
                                label={"Voucher Group Name"}
                                placeholder={"Voucher Group Name"}
                                layout={verticalFormLayout}
                                disabled={mode === "view"}
                                required
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} md={12}>
                            <RadioGroupFormItem
                                name={"termAndConditionEnabled"}
                                label={t("Term and Conditions")}
                                options={[
                                    {label: t("Enabled"), value: true},
                                    {label: t("Disabled"), value: false},
                                ]}
                                layout={verticalFormLayout}
                                disabled={mode === "view"}
                            />
                        </Col>
                        <div className="poolCardBar">
                            {poolValue && poolValue.length > 0 &&<h1 style={{display: "contents"}}>{t("Pools")}</h1>}
                            <Button onClick={()=>addToPool()} icon={<PlusOutlined />} className="button-submit-1" style={{float: "right"}}>
                                {t("common.addIntoPool")}
                            </Button>
                        </div>
                    </Row>
                    <Row  gutter={16}>
                        <Col xs={24} md={18}>
                            <VoucherGroupPool 
                                mode={mode} 
                                poolValue={poolValue} 
                                setPoolValue={setPoolValue} 
                                isRandomReward={form.getValues("isRandomReward")} 
                                form={form}
                                voucher={props.voucher}
                            />
                        </Col>
                        {poolValue && poolValue.length > 0 &&
                            <Col xs={24} md={5} className="poolCard">
                                <Col className="gutter-row" xs={24}>
                                    <RadioGroupFormItem
                                        name={"isRandomReward"}
                                        label={t("Is Random Reward")}
                                        options={[
                                            {label: t("Random"), value: true},
                                            {label: t("Selection"), value: false},
                                        ]}
                                        layout={verticalFormLayout}
                                        disabled={mode === "view"}
                                    />
                                </Col>
                                <Col className="gutter-row" xs={24}>
                                    <SelectFormItem
                                        name={"rewardValue"}
                                        label={t("Reward Value")}
                                        options={rewardValueOptions}
                                        placeholder={t("Reward Value")}
                                        disabled={mode === "view"}
                                    />
                                </Col>
                            </Col>
                        }
                        
                    </Row>
                    <Tabs
                        defaultActiveKey="0"
                        items={tabItems}
                        onChange={(activeKey) => setActiveTab(activeKey)}
                        activeKey={activeTab}
                    />
                    <Form.Item className="text-align-right" style={{paddingTop: "12px"}}>
                        {onCancel && (
                            <Button onClick={onCancel} icon={<CloseOutlined />} className="button-cancel-1">
                                {mode === "view" ? t("Close") : t("common.cancel")}
                            </Button>
                        )}
                        {mode !== "view" && (
                            <Button onClick={form.handleSubmit(onSubmit)} icon={<SaveOutlined />} className="button-submit-1">
                                {t("common.submit")}
                            </Button>
                        )}
                    </Form.Item>
                </form>
            </FormProvider>
        </div>
    );
};

export default VoucherGroupForm;
