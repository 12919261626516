import React, {useEffect} from "react";
import {Col, Form, Input, Row} from "antd";
import {useTranslation} from "react-i18next";
import {Checkbox} from "antd/lib";

const TradePanelMt5 = ({initialData}) => {
    const {t} = useTranslation()
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(initialData);
    }, [initialData])

    return (
        <>
            <Row gutter={[16, 16]} style={{marginBottom: "1%", marginTop: "2%"}}>
                <Col span={20} offset={2}>
                    <p>
                        The setting of symbol trading for the group. Please specify trade mode, filling and expiration
                        modes, etc.
                    </p>
                </Col>
            </Row>

            <Form form={form}>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={10} offset={2}>
                        <Form.Item
                            label={t("Use default trade settings")}
                        >
                            <Checkbox disabled={true}
                                      defaultChecked={initialData?.tradeMode === initialData?.tradeModeDefault && initialData?.fillFlags === initialData?.fillFlagsDefault && initialData?.expirFlags === initialData?.expirFlagsDefault && initialData?.orderFlags === initialData?.orderFlagsDefault}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={20} offset={2}>
                        <Form.Item
                            name={"tradeMode"}
                            label={t("Trade")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={20} offset={2}>
                        <Form.Item
                            name={"fillFlags"}
                            label={t("Filling")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={20} offset={2}>
                        <Form.Item
                            name={"expirFlags"}
                            label={t("Expiration")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={20} offset={2}>
                        <Form.Item
                            name={"orderFlags"}
                            label={t("Orders")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={10} offset={2}>
                        <Form.Item
                            label={t("Use default trade level settings")}
                        >
                            <Checkbox disabled={true}
                                      defaultChecked={initialData?.stopsLevel === initialData?.stopsLevelDefault && initialData?.freezeLevel === initialData?.freezeLevelDefault}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={10} offset={2}>
                        <Form.Item
                            name={"stopsLevel"}
                            label={t("Limit & stop level")}
                        >
                            <Input readOnly={true} addonAfter="pt"/>
                        </Form.Item>
                    </Col>
                    <Col span={8} offset={2}>
                        <Form.Item
                            name={"freezeLevel"}
                            label={t("Freeze level")}
                        >
                            <Input readOnly={true} addonAfter="pt"/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default TradePanelMt5