export function removeTypenameKey(obj: any) {
    try {
        if (Array.isArray(obj)) {
            return obj?.map((item) => removeTypenameKey(item));
        } else if (typeof obj === "object" && obj !== null) {
            const newObj = {};
            for (let key in obj) {
                if (key !== "__typename") {
                    newObj[key] = removeTypenameKey(obj[key]);
                }
            }
            return newObj;
        }
        return obj;
    } catch (error) {}
}

export const findImagePath = (value, imageVarData, languageCode) => {
    try {
        let imgObj = imageVarData?.find((v) => {
            if (`{{dynamicVariable:${v?.name}}}` === value) {
                return v?.fileObject?.find((file) => file.language === languageCode);
            }
            return null;
        });

        if (imgObj) {
            return imgObj?.fileObject?.find((file) => file.language === languageCode)?.path;
        } else {
            return value;
        }
    } catch (error) {}
};

export const findTextValue = (value, textVarData, languageCode) => {
    try {
        let textObj = textVarData?.find((v) => {
            if (v?.name === value) {
                return v?.textObject?.find((file) => file.language === languageCode);
            }
            return null;
        });
        if (textObj) {
            return textObj?.textObject?.find((file) => file.language === languageCode)
                ?.text;
        } else {
            return `{{${value}}}`;
        }
    } catch (error) {}
};

export const replaceDynamicVariables = (str, textVarData, languageCode) => {
    try {
        const dynamicVariableRegex = /{{dynamicVariable:(.*?)}}/g;
        const replacedStr = str?.replace(dynamicVariableRegex, (match, variable) =>
            findTextValue(variable, textVarData, languageCode)
        );
        return replacedStr;
    } catch (error) {}
};
