import React, {useMemo, useRef, useState} from "react";
import {Button, Col, Form, Input, InputNumber, Radio, Row, Select, Space, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {useBatchAssignSalesRepLeads} from "../../../../../api/graphql/leads";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../recoil_state";
import {InfoCircleOutlined} from "@ant-design/icons";
import useAuthorize from "../../../../../_common/authorize";

const LeadsBatchAssignForm = ({labelCol, wrapperCol, actionType, userOptions, selectedIds, clear, refetchLeads}) => {
    const {t} = useTranslation()
    const [form] = Form.useForm();
    const numRef = useRef<any>()

    const [assignType, setAssignType] = useState<String>("number")
    const [assignNumbersLength, setAssignNumbersLength] = useState(1)
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const {systemErrorNotification} = useAuthorize();
    const {batchAssignSalesRepLeads} = useBatchAssignSalesRepLeads()

    const ary = new Array(assignNumbersLength).fill(null)

    const AssignLeadsNumber = useMemo(() => {
        return (
            <>
                {
                    ary.map((d, i) => {
                        return (
                            <Col span={24}>
                                <Space align={"end"} wrap>
                                    <Form.Item name={`value_${i + 1}`} className={"margin-bottom-0"} initialValue={0}
                                               style={{width: 150, maxWidth: "100%"}}
                                               label={t("lead.numberAssign")}
                                               labelCol={{span: 24}} wrapperCol={{span: 24}}>
                                        <InputNumber style={{width: 150}} min={0} ref={numRef} max={selectedIds?.length}/>
                                    </Form.Item>

                                    <Form.Item name={`salesRep_${i + 1}`} className={"margin-bottom-0"} style={{width: 250}} label={t("lead.assignTo")}
                                               labelCol={{span: 24}} wrapperCol={{span: 24}}>
                                        <Select
                                            allowClear
                                            style={{width: 250}}
                                            options={userOptions}
                                            optionFilterProp={"label"}
                                            optionLabelProp="selectedLabel"
                                            showSearch
                                        />
                                    </Form.Item>

                                    <Button danger onClick={() => deleteRow(i + 1)}>-</Button>
                                </Space>
                            </Col>
                        )
                    })
                }
            </>
        )
    }, [assignNumbersLength, actionType, selectedIds, numRef])

    const AssignLeadsPercentage = useMemo(() => {
        return (
            <>
                {
                    ary.map((d, i) => {
                        return (
                            <Col span={24}>
                                <Space align={"end"}>
                                    <Form.Item className={"margin-bottom-0"} name={`value_${i + 1}`} initialValue={0} style={{width: 150}}
                                               label={t("lead.percentage")} labelCol={{span: 24}} wrapperCol={{span: 24}}>
                                        <InputNumber onChange={(value) => form.setFieldValue(`number_${i + 1}`, (value || 0) * selectedIds?.length / 100)}
                                                     style={{width: 150}} min={0} max={100}/>
                                    </Form.Item>

                                    <Form.Item className={"margin-bottom-0"} name={`number_${i + 1}`} style={{width: 150}}
                                               label={<>{t("lead.numberAssign")} <Tooltip title={t("lead.assignSalesRepPercentageNote")}><InfoCircleOutlined className={"margin-left-0-25"}/></Tooltip></>}
                                               labelCol={{span: 24}} wrapperCol={{span: 24}}>
                                        <Input style={{width: 150}} disabled/>
                                    </Form.Item>

                                    <Form.Item className={"margin-bottom-0"} name={`salesRep_${i + 1}`} style={{width: 250}} label={t("lead.assignTo")}
                                               labelCol={{span: 24}} wrapperCol={{span: 24}}>
                                        <Select
                                            style={{width: 250}}
                                            options={userOptions}
                                            optionFilterProp={"label"}
                                            optionLabelProp="selectedLabel"
                                            showSearch
                                        />
                                    </Form.Item>

                                    <Button danger onClick={() => deleteRow(i + 1)}>-</Button>
                                </Space>
                            </Col>
                        )
                    })
                }
            </>
        )
    }, [assignNumbersLength, actionType, selectedIds, numRef])

    const deleteRow = (index) => {
        if (assignNumbersLength > 1) {
            setAssignNumbersLength(prevState => prevState -= 1)
            const value = form.getFieldsValue()

            delete value?.[`value_${index}`]
            delete value?.[`salesRep_${index}`]
            delete value?.[`number_${index}`]

            if (index < assignNumbersLength) {
                const length = assignNumbersLength - index

                for (let i = 0; i < length; i++) {
                    if (value?.[`value_${index + i + 1}`] || value?.[`salesRep_${index + i + 1}`]) {
                        value[`value_${index + i}`] = value?.[`value_${index + i + 1}`]
                        value[`salesRep_${index + i}`] = value?.[`salesRep_${index + i + 1}`]
                        value[`number_${index + i}`] = value?.[`number_${index + i + 1}`]

                        delete value?.[`value_${index + i + 1}`]
                        delete value?.[`salesRep_${index + i + 1}`]
                        delete value?.[`number_${index + i + 1}`]
                    }
                }
            }

            form.resetFields()
            form.setFieldsValue(value)
        }
    }

    const submitAssign = async (value) => {
        try {
            const payload: any[] = []
            for (let i = 1; i <= assignNumbersLength; i++) {
                if (value?.[`value_${i}`] && value?.[`salesRep_${i}`]) {
                    payload.push({type: assignType, value: value?.[`value_${i}`], salesRep: value?.[`salesRep_${i}`]})
                }

            }

            const total = payload?.map(d => d?.value).reduce((accumulator, currentValue) => {
                return accumulator + currentValue
            }, 0);

            if ((assignType === "number" && total !== selectedIds?.length) || (assignType === "percentage" && total > 100)) {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t("errMsg.number_of_leads_does_not_match_with_current_value"),
                    key: "batchAssignLeadsMsg",
                });

                return
            }

            if (assignType === "percentage" && total < 100) {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t("errMsg.total_percentage_is_less_than_100"),
                    key: "batchAssignLeadsMsg",
                });

                return
            }

            const response = await batchAssignSalesRepLeads(payload, selectedIds)
            if (response?.batchAssignSalesRepLeads?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.batchAssignSalesRepLeads?.errKey),
                    key: "batchAssignLeadsMsg",
                });
            } else if (response?.batchAssignSalesRepLeads?.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.batchAssignSalesRepLeads?.msg),
                    key: "batchAssignLeadsMsg",
                });
            } else {

                refetchLeads()
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("successMsg.batchAssignLeadsSuccessfully"),
                    key: "batchAssignLeadsMsg",
                });
            }

            clear()
        } catch (e) {
            systemErrorNotification();
        }
    }

    return (
        <div>
            <Form form={form} className={"margin-bottom-0-75"} onFinish={submitAssign}>
                <Row>

                    <Form.Item labelCol={labelCol} labelAlign={"left"} wrapperCol={wrapperCol} style={{width: 400}}>
                        <Radio.Group
                            onChange={e => {
                                setAssignType(e.target.value)
                                setAssignNumbersLength(1)
                                form.resetFields()
                            }}
                            value={assignType}
                            options={[{label: t("lead.number"), value: "number"}, {label: t("lead.percentage"), value: "percentage"}]}
                        />
                    </Form.Item>

                    <Button type={"link"} onClick={() => setAssignNumbersLength(prevState => prevState += 1)}>Add +</Button>
                    {
                        assignType === "number" ?
                            <>
                                {AssignLeadsNumber}
                            </>
                            : assignType === "percentage" ?
                            <Col span={24}>
                                {AssignLeadsPercentage}
                            </Col>
                            :
                            <></>
                    }
                    <Button className={"margin-top-0-75"} onClick={() => form.submit()}>{t("common.confirm")}</Button>
                </Row>
            </Form>
        </div>
    )
}

export default LeadsBatchAssignForm