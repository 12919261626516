import React, {useEffect, useReducer, useState} from "react";
import {Button, Divider, Form, Input, message, Row, Select, Switch, Upload} from "antd";
import {useTranslation} from "react-i18next";
import {getConfigByName} from "../../../../../../../config";
import {BRAND_NAME} from "../../../../../../../config/brandVariable";
import {UploadOutlined} from "@ant-design/icons";
import DocumentsTinyMceEditor from "./documentsTinyMceEditor";
import {updateBrandSettingLegalDocuments} from "../../../../../../api/graphql/legalDocuments";
import useAuthorize from "../../../../../../_common/authorize";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../recoil_state";

const DocumentsEditingForm = (props) => {
    const {
        item,
        isEditing,
        languageCode,
        getLegalDocumentDataWithLanguage,
        legalDocumentData,
        data,
        isEditable,
        fetchLegalDoc
    } = props;

    const {t} = useTranslation();
    const labelCol = {span: 10, offset: 0};
    const wrapperCol = {span: 14, offset: 0};
    const brandConfig = getConfigByName(BRAND_NAME);
    const {baseErrorChecking, systemErrorNotification} = useAuthorize();

    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [isEditorOpen, setIsEditorOpen] = useState<boolean>(false);
    const [isWebsiteHTMLEditorOpen, setIsWebsiteHTMLEditorOpen] = useState<boolean>(false);
    const [submitLoading, setSubmitLoading] = useState(false)
    const [form] = Form.useForm()

    const isShowPageHandler = (state, action) => {
        return {...state, [action]: form.getFieldValue(action) || item[action]}
    }

    const [isShowPage, dispatchIsShowPage] = useReducer<(state: any, action: any) => any>(isShowPageHandler, {
        isUsedForRegistration: false,
        isShowInLoginPage: false
    })

    const documentFileList = getLegalDocumentDataWithLanguage(legalDocumentData, `${isEditing}_pdf_${languageCode}`, "link", languageCode);

    const updateLegalDocOnClick = async (values) => {
        setSubmitLoading(true);

        const input: any = JSON.parse(JSON.stringify(item))
        delete input["__typename"]
        delete input["_id"]

        Object.keys(values).map((key: any) => {
            if (key.includes(item?._id)) {
                const lastUnderscoreIndex = key.lastIndexOf("_")
                const lang = key.slice(lastUnderscoreIndex + 1);
                const field = key?.replace(item?._id, "")?.replace(lang, "")?.replaceAll("_", "")

                let contentFieldName: string
                let value = values[key] || null

                switch (field) {
                    case "registerType":
                    case "loginType":
                        contentFieldName = "type"
                        break
                    case "pdf":
                        contentFieldName = "link"
                        value = values[key]?.file?.response?.[0]?.link
                        break
                    case "fullLink":
                    case "linkWithDomain":
                        contentFieldName = "link"
                        break
                    default:
                        contentFieldName = "content"
                }

                const found = input[field]?.find(d => d?.language === lang)

                if (found && value) {
                    found[contentFieldName] = value;
                } else if (value) {
                    input[field] = [...(input[field] || []), {language: lang, [contentFieldName]: value}]
                }
            } else {
                input[key] = values[key]
            }
        })

        try {
            const {updateBrandSettingLegalDocuments: res} = await updateBrandSettingLegalDocuments(item?._id, input)
            if (res?.__typename === "BaseError") {
                await baseErrorChecking(res)
            } else if (res?.__typename === "LegalDocumentObject") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Update Legal Documents Successfully!"),
                    key: "legalDocuments",
                });

                await fetchLegalDoc()
            }
        } catch (e) {
            console.log(e)
            systemErrorNotification()
        } finally {
            setSubmitLoading(false)
        }
    }

    useEffect(() => {
        if (item) {
            dispatchIsShowPage("isUsedForRegistration")
            dispatchIsShowPage("isShowInLoginPage")
        }
    }, [item]);

    return (
        <>
            <div key={item?.name} style={{padding: 16}}>
                {isEditing === item._id && (
                    <Form style={{minHeight: 370}} className={"document-form"} form={form} onFinish={updateLegalDocOnClick} labelCol={labelCol} wrapperCol={wrapperCol}
                          labelAlign={"left"}>
                        <DocumentsTinyMceEditor
                            title={t(getLegalDocumentDataWithLanguage(legalDocumentData, `${isEditing}_displayName_${languageCode}`, "content", languageCode) || item?.name)}
                            isOpen={isEditorOpen}
                            setIsOpen={setIsEditorOpen}
                            disabled={!isEditable}
                            name={`${isEditing}_tinyMce_${languageCode}`}
                            setValue={form.setFieldValue}
                            initialValue={getLegalDocumentDataWithLanguage(legalDocumentData, `${isEditing}_tinyMce_${languageCode}`, "content", languageCode)}
                        />
                        <DocumentsTinyMceEditor
                            title={t(getLegalDocumentDataWithLanguage(legalDocumentData, `${isEditing}_displayName_${languageCode}`, "content", languageCode) || item?.name)}
                            isOpen={isWebsiteHTMLEditorOpen}
                            setIsOpen={setIsWebsiteHTMLEditorOpen}
                            disabled={!isEditable}
                            name={`${isEditing}_websiteHTML_${languageCode}`}
                            setValue={form.setFieldValue}
                            initialValue={getLegalDocumentDataWithLanguage(legalDocumentData, `${isEditing}_websiteHTML_${languageCode}`, "content", languageCode)}
                        />
                        <Form.Item name={`${isEditing}_displayName_${languageCode}`} label={t("legalDocument.displayName")}
                                   initialValue={getLegalDocumentDataWithLanguage(
                                       legalDocumentData,
                                       `${isEditing}_displayName_${languageCode}`,
                                       "content",
                                       languageCode
                                   ) || item.name}>
                            <Input disabled={!isEditable} placeholder={t("legalDocument.displayName").toString()}/>
                        </Form.Item>
                        <Form.Item name={`${isEditing}_fullLink_${languageCode}`}
                                   label={t("legalDocument.type.link")}
                                   initialValue={getLegalDocumentDataWithLanguage(
                                       legalDocumentData,
                                       `${isEditing}_fullLink_${languageCode}`,
                                       "link",
                                       languageCode
                                   )}
                        >
                            <Input prefix={"https://"} placeholder={"www.example.com/document"} disabled={!isEditable}/>
                        </Form.Item>
                        <Form.Item name={`${isEditing}_linkWithDomain_${languageCode}`}
                                   label={t("legalDocument.type.linkPath")}
                                   initialValue={getLegalDocumentDataWithLanguage(
                                       legalDocumentData,
                                       `${isEditing}_linkWithDomain_${languageCode}`,
                                       "link",
                                       languageCode
                                   )}
                        >
                            <Input prefix={data?.defaultDomain} placeholder={"/document"} disabled={!isEditable}/>
                        </Form.Item>
                        <Form.Item
                            label={t("legalDocument.type.uploadPdf")}
                            name={`${isEditing}_pdf_${languageCode}`}
                            key={`${isEditing}_pdf_${languageCode}`}
                            // valuePropName={"fileList"}
                            colon={false}
                        >
                            <Upload
                                action={`${brandConfig.backendURL}/api/upload/document/pdf`}
                                maxCount={1}
                                beforeUpload={(file) => {
                                    const validFileTypeList = ["application/pdf"];
                                    const isValidImageType = validFileTypeList.includes(file.type);
                                    if (!isValidImageType) {
                                        message.error(`${file.name} is not a pdf file`);
                                    }
                                    return isValidImageType || Upload.LIST_IGNORE;
                                }}
                                defaultFileList={
                                    documentFileList
                                        ? [
                                            {
                                                uid: "document",
                                                name: documentFileList,
                                                url: documentFileList,
                                            },
                                        ]
                                        : []
                                }
                                className={"legal-document-upload"}
                                disabled={!isEditable}
                            >
                                <Button block icon={<UploadOutlined/>} disabled={!isEditable}>
                                    {t("Click to Upload")}
                                </Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item label={t("legalDocument.type.editor")} colon={false}>
                            <Button block key={`${isEditing}_tinyMce_${languageCode}`} onClick={() => setIsEditorOpen(true)} disabled={!isEditable}>
                                {t("common.edit")}
                            </Button>
                        </Form.Item>
                        <Form.Item label={t("legalDocument.type.websiteHTML")} colon={false}>
                            <Button block key={`${isEditing}_websiteHTML_${languageCode}`} onClick={() => setIsWebsiteHTMLEditorOpen(true)}
                                    disabled={!isEditable}>
                                {t("common.edit")}
                            </Button>
                        </Form.Item>
                        <Divider/>
                        <Form.Item label={t("legalDocument.usedForRegistration")} name={"isUsedForRegistration"} colon={false}>
                            <Switch
                                defaultChecked={item?.isUsedForRegistration || false}
                                onChange={() => dispatchIsShowPage("isUsedForRegistration")}
                                disabled={!isEditable}
                            />
                        </Form.Item>
                        {isShowPage?.isUsedForRegistration && (
                            <Form.Item
                                label={t("legalDocument.registrationDocumentType")}
                                name={`${isEditing}_registerType_${languageCode}`}
                                key={`${isEditing}_registerType_${languageCode}`}
                                rules={[{required: true}]}
                                colon={false}
                                initialValue={getLegalDocumentDataWithLanguage(
                                    legalDocumentData,
                                    `${isEditing}_registerType_${languageCode}`,
                                    "type",
                                    languageCode
                                )}
                            >
                                <Select
                                    options={[
                                        {label: t("Link"), value: "fullLink"},
                                        {label: t("Link Path"), value: "linkWithDomain"},
                                        {label: t("Editor"), value: "tinyMce"},
                                        {label: t("PDF File"), value: "pdf"},
                                    ]}
                                    placeholder={t("legalDocument.pleaseSelectDocumentType")}
                                    disabled={!isEditable}
                                />
                            </Form.Item>
                        )}
                        <Form.Item
                            label={t("legalDocument.showInLoginPage")}
                            name={"isShowInLoginPage"}
                            initialValue={item?.isShowInLoginPage || false}
                            colon={false}
                        >
                            <Switch defaultChecked={item?.isShowInLoginPage || false}
                                    onChange={() => dispatchIsShowPage("isShowInLoginPage")}
                                    disabled={!isEditable}/>
                        </Form.Item>
                        {isShowPage?.isShowInLoginPage && (
                            <Form.Item
                                label={t("legalDocument.loginDocumentType")}
                                name={`${isEditing}_loginType_${languageCode}`}
                                key={`${isEditing}_loginType_${languageCode}`}
                                colon={false}
                                rules={[{required: true}]}
                                initialValue={getLegalDocumentDataWithLanguage(
                                    legalDocumentData,
                                    `${isEditing}_loginType_${languageCode}`,
                                    "type",
                                    languageCode
                                )}
                            >
                                <Select
                                    options={[
                                        {label: t("Link"), value: "fullLink"},
                                        {label: t("Link Path"), value: "linkWithDomain"},
                                        {label: t("Editor"), value: "tinyMce"},
                                        {label: t("PDF File"), value: "pdf"},
                                    ]}
                                    placeholder={t("legalDocument.pleaseSelectDocumentType")}
                                    disabled={!isEditable}
                                />
                            </Form.Item>
                        )}
                        <Form.Item label={t("legalDocument.usedForWebsite")} name={"isUsedForWebsite"} colon={false}>
                            <Switch defaultChecked={item?.isUsedForWebsite}
                                    disabled={!isEditable}
                            />
                        </Form.Item>
                    </Form>
                )}
            </div>

            {
                isEditable &&
                <Row className={"margin-top-0-75"} justify={"end"}>
                    <div className={"text-align-right"}>
                        <p>* Please update before switching tabs.</p>
                        <Button
                            onClick={form.submit}
                            loading={submitLoading}
                            type={"primary"}
                        >
                            {t("common.update")}
                        </Button>
                    </div>
                </Row>
            }
        </>
    );
};

export default DocumentsEditingForm;
