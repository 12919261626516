import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {useRecoilValue} from "recoil";
import {Button, Col, Form, Modal, Row, Space, Spin} from "antd";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import {horizontalFormLayout} from "../../../../../../components/form/formWrapper";
import {reportingAllState, reportingAllFieldsState, reportingAllFunctionsState} from "../../../state";
import yupFormSchema from "../../../../../../components/yup/yupFormSchema";
import {getReportingLinkToRelationship, getReportingValidateOperation} from "../../../../../../api/graphql/reporting";
import InputButtonFormItem from "../../../../../../components/form/inputButtonFormItem";
import EditOperationModal from "./editOperationModal";

const labelCol = {span: 8, offset: 0};

const yupSchema = yup.object().shape({
    name: yupFormSchema.string("name", {required: true}),
});

const EditColumnModal = (props) => {
    const {
        formMode,
        item,
        itemIndex,
        groupBy,
        isModalOpen,
        toggleModal,
        columnsValue,
        setColumnsValue,
        columnsSelection,
        availableReports,
        baseReportIds,
    } = props;
    const {t} = useTranslation();

    const [currentColumnValue, setCurrentColumnValue] = useState<any>({});
    const [selectedKeys, setSelectedKeys] = useState<any[]>([]);
    const [operationMsg, setOperationMsg] = useState({status: "", msg: ""});
    const [nameMsg, setNameMsg] = useState({status: "", msg: ""});
    const [isLoading, setIsLoading] = useState(false);
    const [linkable, setLinkable] = useState(false);
    const [availableLinkToReports, setAvailableLinkToReports] = useState<any[]>([]);
    const [availableLinkToReportsFields, setAvailableLinkToReportsFields] = useState<any[]>([]);
    const operationInputRef: any = useRef();

    const [isEditOperationModalOpen, setIsEditOperationModalOpen] = useState(false);

    const toggleEditOperationModal = () => {
        setIsEditOperationModalOpen((prevState) => !prevState);
    };

    const [initialValues] = useState(() => ({
        name: item?.name || "",
        description: item?.description || "",
        operation: item?.operation || "",
    }));

    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
        defaultValues: initialValues,
    });

    const getSelectedKeys = () => {
        try {
            if (item?.keys) {
                setSelectedKeys(item?.keys);
            }
        } catch (error) {}
    };

    const handleKeysChanged = (e, colKey) => {
        let checkedValue = e?.target?.checked;
        const updatedKeys = checkedValue ? [...selectedKeys, colKey] : selectedKeys.filter((v) => v !== colKey);
        setSelectedKeys(updatedKeys);
    };

    const insertKeyToOperation = (value) => {
        const inputElement: any = document.getElementById("operation");
        const startPos = inputElement?.selectionStart;
        const endPos = inputElement?.selectionEnd;
        const formValues = form.getValues();
        const inputValue = formValues?.operation;

        const newInputValue = inputValue ? inputValue.substring(0, startPos) + value + inputValue.substring(endPos) : value;
        form.setValue("operation", newInputValue);

        inputElement?.setSelectionRange(startPos + value?.length, startPos + value?.length);
        inputElement?.focus();
    };

    const handleCheckOperation = async (operation, checkType) => {
        try {
            setIsLoading(true);
            const response = await getReportingValidateOperation(operation, groupBy);
            const res = response?.getReportingValidateOperation;
            if (res?.data?.valid) {
                let successMsg = res?.data?.message;
                setOperationMsg({status: "success", msg: successMsg});
                setLinkable(res?.data?.linkable);
                // setAdminPanelLinkable(res?.data?.adminPanelLinkable);
                // setEnableChangeDisplayName(res?.data?.enableChangeDisplayName);
                // setEnableChangeDisplayMode(res?.data?.enableChangeDisplayMode);
                if (res?.data?.linkable) {
                    getLinkToReportRelationship(operation);
                    // if (checkType === "initial") {
                    //     setIsLinkSelected(true);
                    // }
                }
                return true;
            } else if (res?.errObj?.status === "Fail") {
                const errMsg = res?.errObj?.message;
                setOperationMsg({status: "error", msg: errMsg});
                return false;
            } else {
                const errMsg = res?.errObj?.errors?.operation?.[0];
                setOperationMsg({status: "error", msg: errMsg});
                return false;
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const getLinkToReportRelationship = async (operation) => {
        try {
            const response = await getReportingLinkToRelationship(operation, groupBy);
            const res = response?.getReportingLinkToRelationship;
            if (res?.__typename === "ReportingData") {
                const availableFields = res?.data?.fields?.fields;
                const availableReportIds = res?.data?.reportIds?.reportIds;
                const availableReportNames = res?.data?.reportNames?.reportNames;
                let reports = availableReportIds?.map((id, index) => ({displayName: availableReportNames?.[index], reportId: id}));
                setAvailableLinkToReports(reports);
                setAvailableLinkToReportsFields(availableFields);
            }
        } catch (error) {}
    };

    const checkIfSameColumnNameExists = async (columnName) => {
        if (Number(itemIndex) + 1 == columnsValue?.length) {
            return false;
        }
        return columnsValue?.some((column, i) => {
            if (i === Number(itemIndex)) {
                return false;
            } else {
                const normalize = (s) => s.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
                const normalizedStr1 = normalize(column?.name);
                const normalizedStr2 = normalize(columnName);
                return normalizedStr1 === normalizedStr2;
            }
        });
    };

    const onSubmit = async () => {
        try {
            // name: String
            // description: String
            // operation: String
            // isUsedForCommission: Boolean
            // isUsedForAutomation: Boolean
            // linkedTo: String
            // linkedToReportId: String
            // linkedToReportFieldName: String
            // displayFieldName: String
            // displayMode: String
            // isLiveData: Boolean
            // "fieldIdentifiers": [
            //     {
            //         "fieldKey": "AX45",
            //         "identifiers": "referrerUser"
            //     },
            //     {
            //         "fieldKey": "BD128",
            //         "identifiers": "salesRep"
            //     }
            // ],
            // additionalFieldFilters: [ // Only need pass in when Function's sameTableFunction: true
            //     {
            //         "fieldKey": "AX45",
            //         "identifiers": "CRMUSER"
            //     },
            //     {
            //         "fieldKey": "BD128",
            //         "identifiers": "CLIENT"
            //     }
            // ]
            const formValues = form.getValues();
            // const isOperationValid = formMode === "add" ? await handleCheckOperation(form?.getValues()?.operation, "form") : true;
            // if (isOperationValid) {
            const isSameColumnNameExist = await checkIfSameColumnNameExists(formValues?.name);
            if (formMode === "add" && isSameColumnNameExist) {
                setNameMsg({status: "error", msg: t("Column name already exists")});
            } else {
                setColumnsValue((prevState) => {
                    const newArray = [...prevState];
                    const updatedCol = {
                        ...prevState[itemIndex],
                        ...formValues,
                        ...currentColumnValue,
                    };
                    newArray[itemIndex] = updatedCol;
                    return newArray;
                });
                toggleModal("");
            }
            // }
        } catch (error) {}
    };

    useEffect(() => {
        getSelectedKeys();
        if (item?.operation) {
            handleCheckOperation(item?.operation, "initial");
        }
    }, [item, columnsSelection]);

    const modalTitle = (
        <Row justify="space-between">
            <div>Edit Column</div>
        </Row>
    );

    return (
        <>
            <Modal
                title={modalTitle}
                open={isModalOpen}
                width={"80%"}
                footer={null}
                destroyOnClose
                getContainer="#reports"
                className="editColumnModal"
                maskClosable={false}
                onCancel={() => toggleModal("")}
            >
                <Spin spinning={isLoading}>
                    <FormProvider {...form}>
                        <Form onFinish={form.handleSubmit(onSubmit)} className="edit-column-form-1" labelWrap={true}>
                            <Row>
                                <Col className="gutter-row" xs={24} sm={24} md={24}>
                                    <InputFormItem
                                        name={"name"}
                                        label={"Name"}
                                        placeholder={"Name"}
                                        layout={horizontalFormLayout}
                                        labelCol={labelCol}
                                        labelAlign="left"
                                        required
                                        externalErrorMessage={nameMsg?.status === "error" ? nameMsg?.msg : ""}
                                    />
                                </Col>
                                <Col className="gutter-row" xs={24} sm={24} md={24}>
                                    <InputFormItem
                                        name={"description"}
                                        label={"Description"}
                                        placeholder={"Description"}
                                        layout={horizontalFormLayout}
                                        labelCol={labelCol}
                                        labelAlign="left"
                                    />
                                </Col>
                                <Col className="gutter-row" xs={24} sm={24} md={24}>
                                    <InputButtonFormItem
                                        // ref={operationInputRef}
                                        name={"operation"}
                                        label={"Operation"}
                                        placeholder={"Operation"}
                                        layout={horizontalFormLayout}
                                        labelCol={labelCol}
                                        labelAlign="left"
                                        // value={columnsValue?.[itemIndex]?.operation}
                                        value={currentColumnValue?.operation}
                                        // buttonLabel="Validate"
                                        // buttonOnClick={() => handleCheckOperation(form?.getValues()?.operation, "form")}
                                        buttonLabel="Edit"
                                        buttonOnClick={toggleEditOperationModal}
                                        validateStatus={operationMsg?.status}
                                        externalErrorMessage={operationMsg?.status === "error" ? operationMsg?.msg : ""}
                                        autoComplete="off"
                                        inputDisabled={true}
                                        buttonDisabled={formMode !== "add"}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </FormProvider>
                    <Row gutter={10} align="middle" justify="end" className="margin-top-0-75 margin-bottom-0-75">
                        <Space>
                            <Button onClick={() => toggleModal("")} icon={<CloseOutlined />} className="cancelBtn">
                                {t("common.cancel")}
                            </Button>
                            <Button onClick={onSubmit} icon={<CheckOutlined />}>
                                {t("Save")}
                            </Button>
                        </Space>
                    </Row>
                </Spin>
            </Modal>
            <EditOperationModal
                formMode={formMode}
                item={item}
                itemIndex={itemIndex}
                groupBy={groupBy}
                isModalOpen={isEditOperationModalOpen}
                toggleModal={toggleEditOperationModal}
                currentColumnValue={currentColumnValue}
                setCurrentColumnValue={setCurrentColumnValue}
                columnsSelection={columnsSelection}
                availableReports={availableReports}
                baseReportIds={baseReportIds}
            />
        </>
    );
};

export default EditColumnModal;
