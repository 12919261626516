import React from "react";
import {getColumnSearchProps} from "../../../../components/table/function/filterTable";

export const columns = [
  {
    title: "Parent Name",
    dataIndex: "parent",
    key: "parent",
    width: "30%",
    ...getColumnSearchProps(),
    render: (parent) => <p>{parent?.name || "-"}</p>,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    ...getColumnSearchProps(),
    render: (text) => <p>{text || "-"}</p>,
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    ...getColumnSearchProps(),
    render: (text) => <p>{text || "-"}</p>,
  },
];
