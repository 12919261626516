import React, {useEffect} from "react";
import MediaLibrary_1 from "./layout1/index";
import {useGetFolder, useGetMedia} from "./function/useGetQueryHooks";

const MediaLibrary = () => {
    let layout = 1, component
    const [media, fetchMedia, refetchMedia] = useGetMedia()
    const [folders, fetchFolder, refetchFolder] = useGetFolder()

    useEffect(() => {
        // fetchMedia(false,"filter.isDeleted")
        // fetchFolder(false,"filter.isDeleted")
        fetchMedia()
        fetchFolder()
    }, []);

    switch (layout) {
        case 1:
            component = (
                <MediaLibrary_1 media={media} fetchMedia={fetchMedia} refetchMedia={refetchMedia}
                                folders={folders} fetchFolder={fetchFolder} refetchFolder={refetchFolder}
                />
            )
            break
    }

    return component
}

export default MediaLibrary