import React from "react";
import {Modal} from "antd";
import {useTranslation} from "react-i18next";
import VoucherForm from "./voucherForm";

const VoucherModal = (props) => {
    const {mode, item, isOpen, closeFormModal} = props;
    const {t} = useTranslation();

    const getTitle = (): String => {
        switch (mode) {
            case "add":
                return `${t("Add New Voucher")}`;
            case "view":
                return `${t("View Voucher")}`;
            case "edit":
                return `${t("Edit Voucher")}`;
            default:
                return `${t("Add New Voucher")}`;
        }
    };

    return (
        <Modal
            getContainer="#voucher"
            title={getTitle()}
            open={isOpen}
            width={"80%"}
            footer={null}
            closable={true}
            destroyOnClose
            onCancel={closeFormModal}
        >
            <VoucherForm 
                mode={mode} 
                item={item} 
                closeFormModal={closeFormModal} 
                languageData={props?.languageData}
                product={props.product}
                stock={props.stock}
                commodities={props.commodities}
                report={props.report}
                reportFields={props.reportFields}
                reportFieldTypes={props.reportFieldTypes}
            />
        </Modal>
    );
};

export default VoucherModal;
