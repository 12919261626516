import React, {useEffect, useState} from "react";
import {useRecoilValue} from "recoil";
import {Modal, Tabs} from "antd";
import {useTranslation} from "react-i18next";
import RichTextEditor from "./richTextEditor";
import {languageOptionsState} from "../../../../../users/_common/state";

const DescriptionModal = (props) => {
    const {formMode, descriptionModalMode, value, setValue, isModalOpen, toggleModal} = props;
    const {t} = useTranslation();
    const [editorState, setEditorState] = useState([]);
    const languageOptions: any = useRecoilValue(languageOptionsState);

    const handleDescriptionOk = async () => {
        try {
            await setValue(editorState);
            toggleModal();
        } catch (error) {
        }
    };

    const onEditorStateChange = (languageCode, value) => {
        setEditorState((prevState: any) => {
            const newState = prevState?.map((item) => (item?.language === languageCode ? {...item, content: value} : item));
            const langItem = newState?.find((item) => item?.language === languageCode);
            if (!langItem) {
                newState?.push({language: languageCode, content: value});
            }
            return newState;
        });
    };

    useEffect(() => {
        setEditorState(value ?? []);
    }, [value]);

    const tabItems = languageOptions?.map((lang, index) => {
        const langItem: any = editorState?.find((v: any) => v?.language === lang?.value);
        const editorValue = langItem ? langItem?.content : "";
        return {
            key: index?.toString(),
            label: lang?.label,
            children: (
                <RichTextEditor formMode={formMode} langaugeCode={lang?.value} editorState={editorValue} onEditorStateChange={onEditorStateChange}/>
            ),
        };
    });

    const modalTitle = t(`common.${formMode}`) + ` Description - ` + t(`common.${descriptionModalMode}`);

    return (
        <Modal
            title={modalTitle}
            open={isModalOpen}
            closable={true}
            onCancel={toggleModal}
            onOk={handleDescriptionOk}
            destroyOnClose
            getContainer="#PspSettingTable"
        >
            <Tabs defaultActiveKey="0" items={tabItems}/>
        </Modal>
    );
};

export default DescriptionModal;
