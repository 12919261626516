import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Mission from "./mission";
import MissionGroup from "./missionGroup";
import { Space, Tabs } from "antd";
import { selfPermissionObj } from "../../../../recoil_state";
import { useRecoilValue } from "recoil";
import AddButton from "../../../components/button/addButton";
import { getLanguage } from "../../../api/graphql/language";
import { sortEn } from "../../../function/language";
import { getMultiTierSetting } from "../../../api/graphql/multiTierSetting";
import { getVoucher } from "../../../api/graphql/voucher";
import { getVoucherGroup } from "../../../api/graphql/voucherGroup";
import { getMission } from "../../../api/graphql/mission";
import { getMissionGroup } from "../../../api/graphql/missionGroup";
import { getProduct } from "../../../api/graphql/product";
import { getTrigger } from "../../../api/graphql/trigger";
import { convertRewardTypeToLabel } from "../../../function/_common";
import { getMultiTierSettingDropdown } from "../../../api/graphql/_common";

const MissionSetting = (props) => {
    const {t} = useTranslation();
    const {languageData} = getLanguage({filter: {isActive: true}});
    const [currentTab, setCurrentTab] = useState("mission");
    const [missionFormModalType, setMissionFormModalType] = useState("");
    const [isMissionFormModalOpen, setIsMissionFormModalOpen] = useState(false);
    const [selectedMissionItem, setSelectedMissionItem] = useState({});
    const [formModalType, setFormModalType] = useState("");
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});

    const { data: multiTierSettingData } = getMultiTierSettingDropdown(localStorage.getItem("g_userId") || localStorage.getItem("userId"), "crmUser");
    const { data: voucherData } = getVoucher({filter: null});
    const { data: voucherGroupData } = getVoucherGroup({filter: null});
    const { data: missionData } = getMission({filter: null});
    const { data: missionGroupData } = getMissionGroup({filter: null});
    const { data: productData } = getProduct({filter: {serverMode: "live"}})
    const { data: triggerData } = getTrigger({filter: null});

    const [multiTierSetting, setMultiTierSetting] = useState([])
    const [product, setProduct] = useState([])
    const [voucher, setVoucher] = useState([])
    const [voucherGroup, setVoucherGroup] = useState([])
    const [mission, setMission] = useState([])
    const [missionGroup, setMissionGroup] = useState([])
    
    const preload = (productData, multiTierSettingData, voucherData, voucherGroupData, missionData, missionGroupData) => {
        if(productData && !(product.length > 0)){
            const products = productData?.getProduct?.data || []
            const productValue = products && products?.map((item) => {
                return {
                    label: `${item?.server?.name} - ${item?.spreadGroup.length > 0 ? `${item?.spreadGroup[0]?.name} - ` : ""}${item?.name}`,
                    value: item?._id
                }
            })
            setProduct(productValue)
        }
        if(multiTierSettingData && !(multiTierSetting.length > 0)){
            const multiTierSettings = multiTierSettingData?.getMultiTierSettingDropdown?.data || []
            const multiTierSettingValue = multiTierSettings && multiTierSettings?.map((item) => {
                return {
                    label: item?.name,
                    value: item?._id
                }
            })
            setMultiTierSetting(multiTierSettingValue)
        }
        if(voucherData && !(voucher.length > 0)){
            const vouchers = voucherData?.getVoucher?.data || []
            const voucherValue = vouchers && vouchers?.map((item) => {
                return {
                    label: `${convertRewardTypeToLabel(item?.rewardType)} - ${item?.name}`,
                    value: item?._id,
                    type: "vouchers",
                    rewardType: item?.rewardType,
                }
            })
            setVoucher(voucherValue)
        }
        if(voucherGroupData && !(voucherGroup.length > 0)){
            const voucherGroups = voucherGroupData?.getVoucherGroup?.data || []
            const voucherGroupValue = voucherGroups && voucherGroups?.map((item) => {
                return {
                    label: `Voucher Groups - ${item?.name}`,
                    value: item?._id,
                    type: "voucherGroups"
                }
            })
            setVoucherGroup(voucherGroupValue)
        }
        if(missionData && !(mission.length > 0)){
            const activity = missionData?.getMission?.data || []
            const missionValue = activity && activity?.map((item) => {
                return {
                    label: item?.name,
                    value: item?._id,
                    missionGroup: item?.missionGroup?._id?.toString()
                }
            })
            setMission(missionValue)
        }
        if(missionGroupData && !(missionGroup.length > 0)){
            const missionGroups = missionGroupData?.getMissionGroup?.data || []
            const missionGroupValue = missionGroups && missionGroups?.map((item) => {
                return {
                    label: item?.name,
                    value: item?._id
                }
            })
            setMissionGroup(missionGroupValue)
        }
    }

    useEffect(() => {
        preload(productData, multiTierSettingData, voucherData, voucherGroupData, missionData, missionGroupData)
    }, [productData, multiTierSettingData, voucherData, voucherGroupData, missionData, missionGroupData])
    

    const permission = useRecoilValue<any>(selfPermissionObj);

    const openFormModal = (type, record?) => {
        setFormModalType(type);
        setIsFormModalOpen(true);
        if (record) {
          setSelectedItem(record);
        }
        else {
            setSelectedItem({})
        }
    };

    const closeFormModal = () => {
      setFormModalType("");
      setSelectedItem({});
      setIsFormModalOpen(false);
    };

    const openMissionFormModal = (type, record?) => {
        setMissionFormModalType(type);
        setIsMissionFormModalOpen(true);
        if (record) {
          setSelectedMissionItem(record);
        }
        else {
            setSelectedMissionItem({})
        }
    };

    const closeMissionFormModal = () => {
      setMissionFormModalType("");
      setSelectedMissionItem({});
      setIsMissionFormModalOpen(false);
    };

    let tabItems = [
        {
            label: t("mission.mission"),
            key: "mission",
            children: (
                <Mission 
                    formModalType={missionFormModalType}
                    setFormModalType={setMissionFormModalType}
                    isFormModalOpen={isMissionFormModalOpen}
                    setIsFormModalOpen={setIsMissionFormModalOpen}
                    selectedItem={selectedMissionItem}
                    setSelectedItem={setSelectedMissionItem}
                    permission={permission}
                    product={product}
                    multiTierSetting={multiTierSetting}
                    voucher={voucher}
                    voucherGroup={voucherGroup}
                    mission={mission}
                    missionGroup={missionGroup}
                    trigger={triggerData?.getTrigger?.data}
                    openFormModal={openMissionFormModal}
                    closeFormModal={closeMissionFormModal}
                    languageData={sortEn(languageData?.getLanguage?.data)}
                />
            ),
        },
        {
            label: t("mission.missionGroup"),
            key: "missionGroup",
            children: (<MissionGroup
                formModalType={formModalType}
                setFormModalType={setFormModalType}
                isFormModalOpen={isFormModalOpen}
                setIsFormModalOpen={setIsFormModalOpen}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                permission={permission}
                product={product}
                multiTierSetting={multiTierSetting}
                voucher={voucher}
                voucherGroup={voucherGroup}
                mission={mission}
                missionGroup={missionGroup}
                openFormModal={openFormModal}
                closeFormModal={closeFormModal}
                languageData={sortEn(languageData?.getLanguage?.data)}
            />),
        },
    ];

    return (
        <div id="missionSetting">
            <h1>{t("Mission Setting")}</h1>
            <Tabs
                defaultActiveKey="mission"
                size={"small"}
                items={tabItems}
                tabBarExtraContent={
                    <>
                        <Space>
                            {permission?.activitiesAndRewardSetting?.mission?.add?.add && currentTab === "mission" && <AddButton text={t("mission.addMission")} addHandler={() => openMissionFormModal("add")} />}
                            {permission?.activitiesAndRewardSetting?.mission?.add?.add && currentTab === "missionGroup" && <AddButton text={t("mission.addMissionGroup")} addHandler={() => openFormModal("add")} />}
                        </Space>
                    </>
                }
                onTabClick={(key) => setCurrentTab(key)}
            />
        </div>
    );
};

export default MissionSetting;
