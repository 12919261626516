import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Col, message, Row} from "antd";

import {verticalFormLayout} from "../../../../../../components/form/formWrapper";
import UploadSingleFormItem from "../../../../../../components/form/uploadSingleFormItem";
import {getListFromObject, handleOnChangeWithLanguage,} from "../../../function/common";
import {SaveOutlined} from "@ant-design/icons";

const ImageForm = (props) => {
    const {languageCode, data, setData, isEditable, onBrandDetailsSubmit, submitLoading} = props;
    const {t} = useTranslation();

    const uploadOnChange = (e, field) => {
        if (e?.file?.status === "uploading") {
            handleOnChangeWithLanguage(
                e?.fileList,
                field,
                "fileList",
                languageCode,
                setData
            );
        }
        if (e?.file?.status === "done") {
            handleOnChangeWithLanguage(
                e?.file?.response[0]?.link,
                field,
                "link",
                languageCode,
                setData
            );
            handleOnChangeWithLanguage(
                e?.fileList,
                field,
                "fileList",
                languageCode,
                setData
            );
            message.success(`${e?.file?.name} file uploaded successfully`);
        } else if (e?.file?.status === "error") {
            handleOnChangeWithLanguage(
                null,
                field,
                "fileList",
                languageCode,
                setData
            );
            message.error(`${e?.file?.name} file upload failed.`);
        }
    };

    const uploadOnRemove = (value, field) => {
        const fileList = getListFromObject(
            data?.companyLogo,
            "fileList",
            languageCode
        );
        const latestFileList = fileList.filter((item) => item._uid === value?.uid);
        handleOnChangeWithLanguage(
            latestFileList,
            field,
            "fileList",
            languageCode,
            setData
        );
        handleOnChangeWithLanguage(null, field, "link", languageCode, setData);
    };

    return (
        <>
            <Row gutter={[16, 16]} style={{marginTop: "30px"}}>
                <Col xs={6}>
                    <UploadSingleFormItem
                        name={"companyLogo"}
                        label={t("brandSetting.companyLogo")}
                        layout={verticalFormLayout}
                        listType={"picture-card"}
                        fileList={getListFromObject(
                            data?.companyLogo,
                            "fileList",
                            languageCode
                        )}
                        onChange={(e) => uploadOnChange(e, "companyLogo")}
                        onRemove={(e) => uploadOnRemove(e, "companyLogo")}
                        condition={[
                            `- ${t("Upload image in format JPG, JPEG, SVG, WEBP, and PNG only.")}`,
                            `- ${t("Uploaded image size should be less than 2 MB.")}`,
                        ]}
                        disabled={!isEditable}
                    />
                </Col>
                <Col xs={6}>
                    <UploadSingleFormItem
                        name={"companyLogoInverse"}
                        label={t("brandSetting.companyLogoInverse")}
                        layout={verticalFormLayout}
                        listType={"picture-card"}
                        fileList={getListFromObject(
                            data?.companyLogoInverse,
                            "fileList",
                            languageCode
                        )}
                        onChange={(e) => uploadOnChange(e, "companyLogoInverse")}
                        onRemove={(e) => uploadOnRemove(e, "companyLogoInverse")}
                        condition={[
                            `- ${t("Upload image in format JPG, JPEG, SVG, WEBP, and PNG only.")}`,
                            `- ${t("Uploaded image size should be less than 2 MB.")}`,
                        ]}
                        disabled={!isEditable}
                    />
                </Col>
                <Col xs={6}>
                    <UploadSingleFormItem
                        name={"companyLogoSmall"}
                        label={t("brandSetting.companyLogoSmall")}
                        layout={verticalFormLayout}
                        listType={"picture-card"}
                        fileList={getListFromObject(
                            data?.companyLogoSmall,
                            "fileList",
                            languageCode
                        )}
                        onChange={(e) => uploadOnChange(e, "companyLogoSmall")}
                        onRemove={(e) => uploadOnRemove(e, "companyLogoSmall")}
                        condition={[
                            `- ${t("Upload image in format JPG, JPEG, SVG, WEBP, and PNG only.")}`,
                            `- ${t("Uploaded image size should be less than 2 MB.")}`,
                        ]}
                        disabled={!isEditable}
                    />
                </Col>
                <Col xs={6}>
                    <UploadSingleFormItem
                        name={"companyLogoInverseSmall"}
                        label={t("brandSetting.companyLogoInverseSmall")}
                        layout={verticalFormLayout}
                        listType={"picture-card"}
                        fileList={getListFromObject(
                            data?.companyLogoInverseSmall,
                            "fileList",
                            languageCode
                        )}
                        onChange={(e) => uploadOnChange(e, "companyLogoInverseSmall")}
                        onRemove={(e) => uploadOnRemove(e, "companyLogoInverseSmall")}
                        condition={[
                            `- ${t("Upload image in format JPG, JPEG, SVG, WEBP, and PNG only.")}`,
                            `- ${t("Uploaded image size should be less than 2 MB.")}`,
                        ]}
                        disabled={!isEditable}
                    />
                </Col>
            </Row>
            <Row gutter={[16, 16]} style={{marginTop: "30px"}}>
                <Col xs={6}>
                    <UploadSingleFormItem
                        name={"siteFavicon"}
                        label={t("brandSetting.siteFavicon")}
                        layout={verticalFormLayout}
                        listType={"picture-card"}
                        fileList={getListFromObject(
                            data?.siteFavicon,
                            "fileList",
                            languageCode
                        )}
                        onChange={(e) => uploadOnChange(e, "siteFavicon")}
                        onRemove={(e) => uploadOnRemove(e, "siteFavicon")}
                        condition={[
                            `- ${t("Upload image in format ICO, JPG, JPEG, SVG, and PNG only. Preferably in ICO format.")}`,
                            `- ${t("Uploaded image size should be less than 2 MB.")}`,
                        ]}
                        disabled={!isEditable}
                    />
                </Col>
                <Col xs={6}>
                    <UploadSingleFormItem
                        name={"siteFaviconInverse"}
                        label={t("brandSetting.siteFaviconInverse")}
                        layout={verticalFormLayout}
                        listType={"picture-card"}
                        fileList={getListFromObject(
                            data?.siteFaviconInverse,
                            "fileList",
                            languageCode
                        )}
                        onChange={(e) => uploadOnChange(e, "siteFaviconInverse")}
                        onRemove={(e) => uploadOnRemove(e, "siteFaviconInverse")}
                        condition={[
                            `- ${t("Upload image in format ICO, JPG, JPEG, SVG, and PNG only. Preferably in ICO format.")}`,
                            `- ${t("Uploaded image size should be less than 2 MB.")}`,
                        ]}
                        disabled={!isEditable}
                    />
                </Col>
            </Row>
            {isEditable &&
            <Row className={"margin-top-0-75"} justify={"end"}>
                <Button
                    onClick={onBrandDetailsSubmit}
                    loading={submitLoading}
                    type={"primary"}
                >
                    {t("common.update")}
                </Button>
            </Row>
            }
        </>
    );
};

export default ImageForm;
