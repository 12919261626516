import React, {useState} from "react";
import {useRecoilState, useSetRecoilState} from "recoil";
import {Card, Divider, Spin} from "antd";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import yupFormSchema from "../../../../../../../components/yup/yupFormSchema";
import SelectFormItem from "../../../../../../../components/form/selectFormItem";
import {horizontalFormLayout} from "../../../../../../../components/form/formWrapper";
import {useTranslation} from "react-i18next";
import SwitchFormItem from "../../../../../../../components/form/switchFormItem";
import {
    useUpdateTradingAccountLeverageMutation,
    useUpdateTradingAccountMutation,
    useUpdateTradingAccountSpreadGroupMutation,
} from "../../../../../../../api/graphql/tradingAccount";
import {useUpdateUserLoginEnabledMutation} from "../../../../../../../api/graphql/user";
import {notificationMessage, selfPermissionObj} from "../../../../../../../../recoil_state";
import useAuthorize from "../../../../../../../_common/authorize";
import CheckboxFormItem from "../../../../../../../components/form/checkboxFormItem";

const AccountSettingCard = (props) => {
    const {data} = props;

    const [toggleLoading, setToggleLoading] = useState(false);
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const tradingAccountPermission = selfPermission?.tradingAccount?.account;
    const [errMsg, setErrMsg] = useState("");
    const {updateTradingAccount} = useUpdateTradingAccountMutation();
    const {updateTradingAccountLeverage} = useUpdateTradingAccountLeverageMutation();
    const {updateTradingAccountSpreadGroup} = useUpdateTradingAccountSpreadGroupMutation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {systemErrorNotification} = useAuthorize();
    const {t} = useTranslation();

    const labelCol = {span: 12, offset: 0};

    const spreadGroupOptions = data?.product?.spreadGroup?.map((d, i) => {
        return {
            value: d?._id || "",
            label: d?.name || "",
        };
    });

    const leverageListing = data?.product?.leverage?.map((d, i) => {
        return {
            value: d || "",
            label: d || "",
        };
    });

    const yupSchema = yup.object().shape({
        spreadGroup: yupFormSchema.string("spreadGroup", {required: true}),
        leverage: yupFormSchema.string("leverage", {required: true}),
        hasTradingPermission: yupFormSchema.boolean("hasTradingPermission", {
            required: false,
        }),
        isLoginEnabled: yupFormSchema.boolean("isLoginEnabled", {
            required: false,
        }),
        isSendReport: yupFormSchema.boolean("isSendReport", {required: false}),
        isSuspicious: yupFormSchema.boolean("isSuspicious", {required: false}),
        hasWithdrawalPermission: yupFormSchema.boolean("hasWithdrawalPermission", {required: false}),
        hasDepositPermission: yupFormSchema.boolean("hasDepositPermission", {required: false}),
    });

    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
    });

    const onSubmit = async (value) => {
        try {
            await setToggleLoading(true);
            const response = await updateTradingAccount(data?._id, {
                user: data?.user?._id,
                isSendReport: value?.isSendReport,
                isSuspicious: value?.isSuspicious,
                isDefault: value?.isDefault,
                isLoginEnabled: value?.isLoginEnabled,
                isPrivate: value?.isPrivate,
                hasTradingPermission: value?.hasTradingPermission,
                hasDepositPermission: value?.hasDepositPermission,
                hasWithdrawalPermission: value?.hasWithdrawalPermission,
            });

            if (response?.data?.updateTradingAccount.__typename === "TradingAccount") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("common.updateSuccess"),
                    key: "updateTradingAccount",
                });
            } else if (response?.data?.updateTradingAccount.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.data?.updateTradingAccount?.errKey),
                    key: "updateTradingAccount",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.data?.updateTradingAccount?.msg),
                    key: "updateTradingAccount",
                });
            }
            setToggleLoading(false);
        } catch (e) {
            setToggleLoading(false);
            systemErrorNotification();
        }
    };

    const onSubmitLeverage = async (value) => {
        try {
            await setToggleLoading(true);
            const response = await updateTradingAccountLeverage(data?._id, {
                user: data?.user?._id,
                leverage: value?.leverage,
            });

            if (response?.data?.updateTradingAccountLeverage.__typename === "TradingAccount") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("common.updateSuccess"),
                    key: "updateTradingAccountLeverage",
                });
            } else if (response?.data?.updateTradingAccountLeverage.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.data?.updateTradingAccountLeverage?.errKey),
                    key: "updateTradingAccountLeverage",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.data?.updateTradingAccountLeverage?.msg),
                    key: "updateTradingAccountLeverage",
                });
            }
            setToggleLoading(false);
        } catch (e) {
            setToggleLoading(false);
            systemErrorNotification();
        }
    };

    const onSubmitSpreadGroup = async (value) => {
        try {
            await setToggleLoading(true);
            const response = await updateTradingAccountSpreadGroup(data?._id, {
                user: data?.user?._id,
                spreadGroup: value?.spreadGroup,
            });

            if (response?.data?.updateTradingAccountSpreadGroup.__typename === "TradingAccount") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("common.updateSuccess"),
                    key: "updateTradingAccountSpreadGroup",
                });
            } else if (response?.data?.updateTradingAccountSpreadGroup.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.data?.updateTradingAccountSpreadGroup?.errKey),
                    key: "updateTradingAccountSpreadGroup",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.data?.updateTradingAccountSpreadGroup?.msg),
                    key: "updateTradingAccountSpreadGroup",
                });
            }
            setToggleLoading(false);
        } catch (e) {
            setToggleLoading(false);
            systemErrorNotification();
        }
    };

    const isEditable = tradingAccountPermission?.edit?.edit;
    const isEditableSpreadGroup = tradingAccountPermission?.edit?.spreadGroup;
    const isEditableLeverage = tradingAccountPermission?.edit?.leverage;

    return (
        <div id="AccountSettingCard">
            <Spin tip="Loading..." spinning={!data?._id}>
                <Card>
                    <h4>{t("module.accountSetting")}</h4>

                    <FormProvider {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)} className="form-1">
                            {errMsg && <p style={{color: "red"}}>{errMsg}</p>}

                            {tradingAccountPermission?.view?.spreadGroup && (
                                <SelectFormItem
                                    name={"spreadGroup"}
                                    label={t("tradingAccount.spread_group")}
                                    placeholder={t("tradingAccount.spread_group")}
                                    layout={horizontalFormLayout}
                                    options={spreadGroupOptions}
                                    mode={"single"}
                                    labelCol={labelCol}
                                    labelAlign={"left"}
                                    onChange={form.handleSubmit(onSubmitSpreadGroup)}
                                    value={data?.spreadGroup?._id}
                                    disabled={!isEditableSpreadGroup || toggleLoading}
                                    loading={toggleLoading}
                                />
                            )}
                            {tradingAccountPermission?.view?.leverage && (
                                <SelectFormItem
                                    name={"leverage"}
                                    label={t("tradingAccount.leverage")}
                                    placeholder={t("tradingAccount.leverage")}
                                    layout={horizontalFormLayout}
                                    options={leverageListing}
                                    mode={"single"}
                                    labelCol={labelCol}
                                    labelAlign={"left"}
                                    onChange={form.handleSubmit(onSubmitLeverage)}
                                    value={data?.leverage}
                                    disabled={!isEditableLeverage || toggleLoading}
                                    loading={toggleLoading}
                                />
                            )}
                            {(tradingAccountPermission?.view?.spreadGroup || tradingAccountPermission?.view?.leverage) && <Divider />}
                            <CheckboxFormItem
                                name={"hasTradingPermission"}
                                text={t("tradingAccount.trading_permission")}
                                onChange={form.handleSubmit(onSubmit)}
                                value={data?.hasTradingPermission || false}
                                disabled={!isEditable || toggleLoading}
                            />
                            <CheckboxFormItem
                                name={"isLoginEnabled"}
                                text={t("tradingAccount.login_enabled")}
                                onChange={form.handleSubmit(onSubmit)}
                                value={data?.user?.isLoginEnabled || false}
                                disabled={!isEditable || toggleLoading}
                            />
                            <CheckboxFormItem
                                name={"isSendReport"}
                                text={t("tradingAccount.send_report")}
                                onChange={form.handleSubmit(onSubmit)}
                                value={data?.isSendReport || false}
                                disabled={!isEditable || toggleLoading}
                            />

                            <CheckboxFormItem
                                name={"isPrivate"}
                                text={t("tradingAccount.is_private")}
                                onChange={form.handleSubmit(onSubmit)}
                                value={data?.isPrivate || false}
                                disabled={!isEditable || toggleLoading}
                            />

                            <CheckboxFormItem
                                name={"isSuspicious"}
                                text={t("tradingAccount.is_suspicious")}
                                onChange={form.handleSubmit(onSubmit)}
                                value={data?.isSuspicious || false}
                                disabled={!isEditable || toggleLoading}
                            />

                            <CheckboxFormItem
                                name={"hasDepositPermission"}
                                text={t("tradingAccount.is_deposit_enabled")}
                                onChange={form.handleSubmit(onSubmit)}
                                value={data?.hasDepositPermission || false}
                                disabled={!isEditable || toggleLoading}
                            />

                            <CheckboxFormItem
                                name={"hasWithdrawalPermission"}
                                text={t("tradingAccount.is_withdrawal_enabled")}
                                onChange={form.handleSubmit(onSubmit)}
                                value={data?.hasWithdrawalPermission || false}
                                disabled={!isEditable || toggleLoading}
                            />
                        </form>
                    </FormProvider>
                </Card>
            </Spin>
        </div>
    );
};

export default AccountSettingCard;
