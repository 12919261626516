import React, {useEffect, useState} from "react";
import {Popconfirm, Space, Tooltip} from "antd";
import {CopyOutlined, DeleteOutlined, EditOutlined, EyeOutlined, MailOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {columns} from "../../../function/tableColumn";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import {
    deletePushNotificationTemplate,
    getPushNotificationTemplate,
} from "../../../../../../api/graphql/pushNotificationTemplate";
import {FormType} from "../../../function/type";
import useAuthorize from "../../../../../../_common/authorize";

const TemplateTable = (props) => {
    const {tableName, toggleFormModal, pushNotificationTemplatePermission, selectedTemplate} = props;
    const {t} = useTranslation();
    const [tableData, setTableData] = useState<any>([]);
    const [count, setCount] = useState([]);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [filter, setFilter] = useState({});
    const [orderBy, setOrderBy] = useState("");
    const [loading, setLoading] = useState(false);

    const getTableData = async () => {
        try {
            setLoading(true);
            const response = await getPushNotificationTemplate({
                filter: {
                    ...filter,
                    type: "system",
                }
            }, limit, offset, orderBy);
            setTableData(response?.getPushNotificationTemplate?.data);
            setCount(response?.getPushNotificationTemplate?.total);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const {systemErrorNotification, baseErrorChecking} = useAuthorize();

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize);
        setOffset(limit * (page - 1));
    };

    const handleDeleteTemplate = async (id) => {
        try {
            const res: any = await deletePushNotificationTemplate(id);
            if (res?.deletePushNotificationTemplate?.__typename === "PushNotificationTemplate") {
                setOffset(0);
            } else {
                return await baseErrorChecking(res?.deletePushNotificationTemplate);
            }
        } catch (e) {
            console.log(e);
            systemErrorNotification();
        } finally {
            await getTableData()
        }
    };

    useEffect(() => {
        selectedTemplate === "" && getTableData()
    }, [selectedTemplate]);

    return (
        <div className={"margin-top-0-75"}>
            <TableAntDesign
                tableName={tableName}
                data={tableData}
                loading={loading}
                columns={[
                    ...columns(),
                    {
                        title: t("common.action"),
                        key: "action",
                        width: 132,
                        render: (_, record: any) => (
                            <Space size="middle">
                                <Tooltip title={t("View")}>
                                    <a onClick={() => toggleFormModal(FormType.View, record)}>
                                        <EyeOutlined/>
                                    </a>
                                </Tooltip>
                                {pushNotificationTemplatePermission?.edit?.edit && (
                                    <Tooltip title={t("Edit")}>
                                        <a onClick={() => toggleFormModal(FormType.Edit, record)}>
                                            <EditOutlined/>
                                        </a>
                                    </Tooltip>
                                )}
                                {pushNotificationTemplatePermission?.add?.add && (
                                    <Tooltip title={t("Duplicate")}>
                                        <Popconfirm
                                            placement="left"
                                            description={t("Are you sure to duplicate this template?")}
                                            title={t("Duplicate Template")}
                                            onConfirm={() => toggleFormModal(FormType.Add, record)}
                                            okText={t("Yes")}
                                            okType="danger"
                                            cancelText={t("No")}
                                        >
                                            <a>
                                                <CopyOutlined/>
                                            </a>
                                        </Popconfirm>
                                    </Tooltip>
                                )}
                                {pushNotificationTemplatePermission?.delete?.delete && (
                                    <Tooltip title={t("Delete")}>
                                        <Popconfirm
                                            placement="left"
                                            description={t("Are you sure to delete this template?")}
                                            title={"Delete Template"}
                                            onConfirm={() => handleDeleteTemplate(record?._id)}
                                            okText={t("common.yes")}
                                            cancelText={t("common.no")}
                                        >
                                            <a>
                                                <DeleteOutlined/>
                                            </a>
                                        </Popconfirm>
                                    </Tooltip>
                                )}
                                {/*<Tooltip title={t("Test Send Push Notification")}>*/}
                                {/*    <a onClick={() => openPushNotificationModal(record.name)}>*/}
                                {/*        <MailOutlined/>*/}
                                {/*    </a>*/}
                                {/*</Tooltip>*/}
                            </Space>
                        ),
                    },
                ]}
                rowKey="id"
                size="small"
                filter={setFilter}
                order={setOrderBy}
                pagination={{
                    pageSize: limit,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                    total: count,
                    showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
                }}
            />
        </div>
    );
};

export default TemplateTable;
