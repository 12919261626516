import {getBooleanFilterProps, getColumnSearchProps} from "../../../../../components/table/function/filterTable";
import React, {useState} from "react";
import {Switch, Tag} from "antd";
import {BsCheck, BsX} from "react-icons/bs";
import {useUpdateUserLoginEnabledMutation} from "../../../../../api/graphql/user";
import {useRecoilValue} from "recoil";
import {selfPermissionObj} from "../../../../../../recoil_state";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";

const SeeMoreComponent = ({text}) => {
    const [view, setView] = useState(false);

    let tagsToShow = text?.slice(0, view ? text.length : 3);

    const renderTag = (tag, i) => (
        <Tag
            key={i}
            style={{margin: 2}}
            color={i === 0 ? "blue" : "default"}
        >
            {tag?.name}
        </Tag>
    );

    const renderSeeMoreTag = () => (
        <Tag
            style={{margin: 2, cursor: "pointer"}}
            color={"green"}
            onClick={() => setView(true)}
        >
            ...
        </Tag>
    );

    return (
        <div>
            {tagsToShow?.map(renderTag)}
            {!view && text?.length > 3 && renderSeeMoreTag()}
        </div>
    );
};

const IsSettingEnabledColumn = (props) => {
    const {updateUserLoginEnabled} = useUpdateUserLoginEnabledMutation();
    const permission = useRecoilValue<any>(selfPermissionObj);
    const isLoginEnabledOnChange = (value, record) => {
        updateUserLoginEnabled(record?._id, {isLoginEnabled: value})
    };

    let component
    if (permission?.user?.clientsUser?.edit?.edit && props.record?.isDeleted === false) {
        component = <Switch
            checkedChildren={<CheckOutlined/>}
            unCheckedChildren={<CloseOutlined/>}
            checked={props?.value}
            size={"small"}
            onChange={(value) => isLoginEnabledOnChange(value, props.record)}
        />
    } else {
        component = props?.value ? <BsCheck style={{ color: 'green' }}/> : <BsX style={{ color: 'red' }}/>
    }

    return component
};

export const columnsCommSetting = [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Report",
        dataIndex: "reportName",
        key: "reportName",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Report Payout",
        dataIndex: "reportPayoutName",
        key: "reportPayoutName",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Is Enabled",
        dataIndex: "isEnabled",
        key: "isEnabled",
        ...getBooleanFilterProps(),
        render: (text, record) => <IsSettingEnabledColumn value={text} record={record}/>,
    }
];

export const columnsRuleSetting = [
    {
        title: "Rule Name",
        dataIndex: "name",
        key: "name",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Account Type",
        dataIndex: "accountType",
        key: "accountType",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Product",
        dataIndex: "product",
        key: "product",
        ...getColumnSearchProps(),
        render: (text,record) => {
            let component;

            record?.productType === "all" ? component = <p>All Products</p> : component =
                <SeeMoreComponent text={text}/>

            return component
        },
    },
    {
        title: "Comm. Group",
        dataIndex: "payoutCommGroup",
        key: "payoutCommGroup",
        ...getColumnSearchProps(),
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Comm. Mode",
        dataIndex: "commMode",
        key: "commMode",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Comm. Type",
        dataIndex: "commType",
        key: "commType",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Comm. Level",
        dataIndex: "commLevel",
        key: "commLevel",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Comm. Rank",
        dataIndex: "commRank",
        key: "commRank",
        ...getColumnSearchProps(),
        render: (text, record) => {

            let component;

            record?.commRankType === "all" ? component = <p>All Comm Rank</p> : component =
                <SeeMoreComponent text={text}/>

            return component
        },
    },
    {
        title: "Comm. Payout",
        dataIndex: "commPayout",
        key: "commPayout",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Network",
        dataIndex: "payoutNetworkType",
        key: "payoutNetworkType",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Trading Account",
        dataIndex: "tradingAccountCount",
        key: "tradingAccountCount",
        render: (text, record) => text > 0 ? <a onClick={() => window.open(`/account/trading-account?commRule=${record?._id}`)}>{text}</a> : text,
    },
    {
        title: "Client User",
        dataIndex: "clientUserCount",
        key: "clientUserCount",
        render: (text, record) => text > 0 ? <a onClick={() => window.open(`/users/client?commRule=${record?._id}`)}>{text}</a> : text,
    },
    {
        title: "Crm User",
        dataIndex: "crmUserCount",
        key: "crmUserCount",
        render: (text, record) => text > 0 ? <a onClick={() => window.open(`/users/crm-user?commRule=${record?._id}`)}>{text}</a> : text,
    },
    {
        title: "is Enabled",
        dataIndex: "isEnabled",
        key: "isEnabled",
        ...getBooleanFilterProps(),
        render: (text) => (text) ? <BsCheck style={{color: 'green'}}/> : <BsX style={{color: 'red'}}/>,
    },
]