import React, {useState} from "react";
import {Button, Form} from "antd";
import {FormProvider, useForm} from "react-hook-form";
import {useAddProductGroupMutation, useUpdateProductGroupMutation,} from "../../../../../../api/graphql/productGroup";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import yupFormSchema from "../../../../../../components/yup/yupFormSchema";
import {verticalFormLayout} from "../../../../../../components/form/formWrapper";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import {useTranslation} from "react-i18next";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import useAuthorize from "../../../../../../_common/authorize";
import InputNumberFormItem from "../../../../../../components/form/inputNumberFormItem";

const ProductGroupForm = (props) => {
    const {mode, item, close, currentTab} = props;
    const {t} = useTranslation();
    const [errMsg, setErrMsg] = useState("");
    const {addProductGroup} = useAddProductGroupMutation();
    const {updateProductGroup} = useUpdateProductGroupMutation();
    const {systemErrorNotification} = useAuthorize();

    const yupSchema = yup.object().shape({
        name: yupFormSchema.string("Product Group Name", {required: true}),
        // spreadAdjustment: yupFormSchema.number("Spread Adjustment", {
        //     required: true,
        // }),
    });

    const [initialValues] = useState(() => ({
        name: item?.name || "",
        // spreadAdjustment: item?.spreadAdjustment || 0,
    }));

    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
        defaultValues: initialValues,
    });

    const onSubmit = async (value) => {
        try {
            const res =
                mode === "Add"
                    ? await addProductGroup({
                        name: value?.name,
                        // spreadAdjustment: value?.spreadAdjustment,
                        type: currentTab,
                    })
                    : await updateProductGroup(item?.id || item?._id, {
                        name: value?.name,
                        // spreadAdjustment: value?.spreadAdjustment,
                        type: currentTab,
                    });
            if (res?.__typename === "BaseError") {
                let errMsg = t(res?.errKey);
                setErrMsg(errMsg);
              } else {
                setErrMsg("");
                close();
              }
        } catch (error: any) {
            systemErrorNotification();
        }
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="form-1">
                {/*{errMsg && <p style={{ color: "red" }}>{errMsg}</p>}*/}
                <InputFormItem
                    name={"name"}
                    label={t("Product Group Name")}
                    placeholder={t("Product Group Name")}
                    layout={verticalFormLayout}
                    required
                    disabled={mode === "view"}
                />
                {/* <InputNumberFormItem
                    name={"spreadAdjustment"}
                    label={t("Spread Adjustment (+/- %)")}
                    placeholder={t("Spread Adjustment")}
                    layout={verticalFormLayout}
                    required
                    step={0.01}
                    style={{width: "100%"}}
                    disabled={mode === "view"}
                /> */}
                <Form.Item className="text-align-right" style={{paddingTop: "12px"}}>
                    {close && (
                        <Button
                            onClick={close}
                            icon={<CloseOutlined/>}
                            className="button-cancel-1"
                        >
                            {t("common.cancel")}
                        </Button>
                    )}
                    <Button
                        onClick={form.handleSubmit(onSubmit)}
                        className="button-submit-1"
                    >
                        {t("common.submit")}
                    </Button>
                </Form.Item>
            </form>
        </FormProvider>
    );
};

export default ProductGroupForm;
