import React, {useEffect, useState} from 'react';
import {Badge, Button, Card, Col, Empty, List, Popconfirm, Row, Space, Spin, Tag, Tooltip} from 'antd';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useTranslation} from "react-i18next";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {fieldListFormPropState, profileInformationSettingLoading} from "../../../state";
import useAuthorize from '../../../../../../_common/authorize';


const SettingCards = (props) => {
    const {updateField, updateLoading, fields, setFields, profileInformationPermission, currentTab} = props
    const {basicField, loginField, residenceField, additionalField, corporateField} = fields;
    const ContainerHeight = "70vh";
    const {t} = useTranslation();
    const setFieldListFormProp = useSetRecoilState<any>(fieldListFormPropState)

    const [isUpdating, setIsUpdating] = useState<boolean>(false)

    const loadingGet = useRecoilValue(profileInformationSettingLoading)
    const {systemErrorNotification} = useAuthorize()

    useEffect(() => {
        if (!updateLoading && !loadingGet) setIsUpdating(false)
    }, [loadingGet, updateLoading])

    const onDragEnd = async (result) => {
        try {
            setIsUpdating(true)
            if (!result?.destination) return
            let position = getIndexFromField(result?.destination?.index, result?.destination?.droppableId)
            if (result?.destination?.index !== result?.source?.index) {
                const reorderedItems = Array.from(fields[result?.destination?.droppableId]);
                const [movedItem] = reorderedItems?.splice(result?.source?.index, 1);
                reorderedItems?.splice(result?.destination?.index, 0, movedItem);

                setFields({...fields, [result?.destination?.droppableId]: reorderedItems})

                await updateField(result?.draggableId, position)
            }
        } catch (e) {
            systemErrorNotification()
        }
    };

    const getIndexFromField = (index, droppableId) => {
        return fields?.[droppableId]?.[index].position
    }

    const renderListItem = (item, categoryColor) => {
        return (
            <List.Item key={item?._id}>
                <Card
                    className={"registrationCard margin-top-0-75"}
                    key={item?._id}
                    style={{width: "100%"}}
                >
                    <Row>
                        <Col flex={"auto"}>
                            <h4>{t(item?.fieldList?.label)} {item?.fieldList?.isRequired ?
                                <span
                                    style={{color: "red"}}> * </span> : undefined}
                            </h4>
                        </Col>
                        {profileInformationPermission?.edit?.edit &&
                        <Col flex={"30px"}>
                                <Space size={0}>
                                    {currentTab === "leads" &&
                                    <Tooltip title={t("common.edit")}>
                                        <Button type={"link"}
                                                onClick={() => {
                                                    setFieldListFormProp({
                                                        isOpen: true,
                                                        type: "leads",
                                                        item: item?.fieldList,
                                                        close: () => {
                                                            setFieldListFormProp({isOpen: false})
                                                        }
                                                    })
                                                }}
                                                icon={<AiOutlineEdit/>}
                                        />
                                    </Tooltip>
                                    }
                            <Popconfirm
                                placement="left"
                                description={t("Are you sure to delete this field?")}
                                title={t(`Delete Profile Information Field`)}
                                onConfirm={(e) => {
                                    updateField(item?.fieldList?._id, null)
                                }}
                                okText={t("Yes")}
                                okType="danger"
                                cancelText={t("No")}
                            >
                                    <Tooltip
                                        title={t("Delete")}>
                                        <Button type={"link"}
                                                disabled={updateLoading}
                                                icon={
                                                    <AiOutlineDelete/>}
                                                danger/>
                                    </Tooltip>
                            </Popconfirm>
                                </Space>
                        </Col>}
                        <Col span={24}>
                            <Tag color="blue"
                                 bordered={false}><small>{item?.fieldList?.fieldType?.toUpperCase()}</small></Tag>
                            <Tag color={categoryColor}
                                 bordered={false}><small>{item?.fieldList?.category?.replace(/(INFORMATION)/i, "")?.toUpperCase()}</small></Tag>
                            {
                                item?.fieldList?.isConfirmRequired &&
                                <Tag color="magenta" style={{marginTop: 4}}
                                     bordered={false}><small>{t("CONFIRM REQUIRED")}</small></Tag>
                            }
                            {
                                item?.fieldList?.isSensitive &&
                                <Tag color="volcano"
                                     bordered={false}><small>{t("SENSITIVE")}</small></Tag>
                            }
                            {
                                item?.fieldList?.isUnique &&
                                <Tag color="orange"
                                     bordered={false}><small>{t("UNIQUE")}</small></Tag>
                            }
                            {
                                item?.fieldList?.isUsedForLogin &&
                                <Tag color="red"
                                     bordered={false}><small>{t("USED FOR LOGIN")}</small></Tag>
                            }
                        </Col>
                    </Row>
                </Card>
            </List.Item>
        )
    }

    const draggableList = (listItem, title, style, badgeColor, field) => {
        return (
            <Badge.Ribbon text={t(title)} color={badgeColor}>
                <List style={{...style, padding: 10, paddingTop: 32, borderRadius: 12}}
                      className={"margin-bottom-0-75"}>
                    <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                        <Droppable droppableId={field}>
                            {(droppableProvided) => (
                                <div ref={droppableProvided?.innerRef}>
                                    {listItem?.map((item, index) => {
                                        let categoryColor = ""; // Initialize with a default value
                                        switch (item?.fieldList?.category) {
                                            case "basicInformation":
                                                categoryColor = "gold";
                                                break;
                                            case "loginInformation":
                                                categoryColor = "lime";
                                                break;
                                            case "residentialInformation":
                                                categoryColor = "green";
                                                break;
                                            case "additionalInformation":
                                                categoryColor = "geekblue";
                                                break;
                                            case "corporateInformation":
                                                categoryColor = "purple";
                                                break;
                                            default:
                                                categoryColor = "";
                                        }

                                        return (
                                            <Draggable key={item?._id} draggableId={item?.fieldList?._id}
                                                       index={index}
                                                       isDragDisabled={!profileInformationPermission?.edit?.edit && isUpdating}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided?.innerRef}
                                                        {...provided?.draggableProps}
                                                        {...provided?.dragHandleProps}
                                                        style={{
                                                            ...provided?.draggableProps.style,
                                                            opacity: snapshot?.isDragging ? 0.8 : 1,
                                                        }}
                                                    >
                                                        {renderListItem(item, categoryColor)}
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {droppableProvided?.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </List>
            </Badge.Ribbon>
        );
    };

    return (
        <div id={"setting-card"}>
            <Row style={{marginBottom: "15px"}}>
                <Col flex={"auto"}>
                    <h3>{t("Setting")}</h3>
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{height: ContainerHeight, overflow: "auto", padding: "0 16px"}}>
                    {
                        basicField?.length > 0 || loginField?.length > 0 || residenceField?.length > 0 || additionalField?.length > 0 || corporateField?.length > 0 ? (
                            <>
                                {
                                    basicField?.length > 0 &&
                                    draggableList(basicField, "Basic Information", {backgroundColor: "#fffbe6"}, "gold", "basicField")
                                }
                                {
                                    residenceField?.length > 0 &&
                                    draggableList(residenceField, "Residence Information", {backgroundColor: "#f6ffed"}, "green", "residenceField")
                                }
                                {
                                    additionalField?.length > 0 &&
                                    draggableList(additionalField, "Additional Information", {backgroundColor: "#f0f5ff"}, "geekblue", "additionalField")
                                }
                                {
                                    corporateField?.length > 0 &&
                                    draggableList(corporateField, "Corporate Information", {backgroundColor: "#faf0ff"}, "purple", "corporateField")
                                }
                                {
                                    currentTab !== "leads" && loginField?.length > 0 &&
                                    draggableList(loginField, "Login Information", {backgroundColor: "#fcffe6"}, "lime", "loginField")
                                }
                            </>
                        ) : updateLoading ? (
                            <Col span={24} style={{marginTop: "20%", textAlign: "center"}}>
                                <Spin size="large"/>
                            </Col>
                        ) : (
                            <Col span={24} style={{paddingTop: "20%"}}>
                                <Empty/>
                            </Col>
                        )
                    }
                </Col>
            </Row>
        </div>
    )
}

export default SettingCards