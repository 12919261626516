import React, {useEffect, useState} from "react";
import Task_1 from "./view/layout1";
import {getCrmTaskApproval} from "../../api/graphql/crmTask";
import {useRecoilState} from "recoil";
import {
    crmTaskArchivedMonthFilterState,
    crmTaskArchivedTableFilterState,
    crmTaskDateKeySearchState,
    crmTaskEndTimeSearchState,
    crmTaskFilterState,
    crmTaskLimitState,
    crmTaskNetworkUserIdState,
    crmTaskOffsetState,
    crmTaskOrderBySearchState,
    crmTaskStartTimeSearchState,
    crmTaskTableFilterState
} from "./state";

function TaskPage() {
    let layout = 1;
    let component;

    const [crmTaskStartTimeSearch, setCrmTaskStartTimeSearch] = useRecoilState(crmTaskStartTimeSearchState);
    const [crmTaskEndTimeSearch, setCrmTaskEndTimeSearch] = useRecoilState(crmTaskEndTimeSearchState);
    const [crmTaskDateKeySearch, setCrmTaskDateKeySearch] = useRecoilState(crmTaskDateKeySearchState);
    const [crmTaskOrderBySearch] = useRecoilState(crmTaskOrderBySearchState);
    const [crmTaskFilter, setCrmTaskFilter] = useRecoilState(crmTaskFilterState)
    const [crmTaskNetworkUserId] = useRecoilState(crmTaskNetworkUserIdState)
    const [crmTaskTableFilter, setCrmTaskTableFilter] = useRecoilState<any>(crmTaskTableFilterState)
    const [crmTaskLimit] = useRecoilState(crmTaskLimitState)
    const [crmTaskOffset, setCrmTaskOffset] = useRecoilState(crmTaskOffsetState)
    const [crmTaskArchivedTableFilter] = useRecoilState<any>(crmTaskArchivedTableFilterState)
    const [crmTaskArchivedMonthFilter, setCrmTaskArchivedMonthFilter] = useRecoilState(crmTaskArchivedMonthFilterState)

    const isClearFilterVisible = Object.keys(crmTaskTableFilter)?.length > 0 || (crmTaskDateKeySearch && crmTaskStartTimeSearch)
    const resetFilters = () => {
        setCrmTaskTableFilter({})
        setCrmTaskStartTimeSearch("")
        setCrmTaskEndTimeSearch("")
        setCrmTaskDateKeySearch("")
    }

    const getLastTwoMonths = () => {
        if(!crmTaskArchivedMonthFilter || crmTaskArchivedMonthFilter == "2099-01"){
            const currentDate = new Date();
            currentDate.setMonth(currentDate.getMonth());
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
            setCrmTaskArchivedMonthFilter(`${year}-${month}`)
        }
    }

    const taskGraphQl = getCrmTaskApproval({
        filter: {statusList: ["pending", "pending approval", "pendingEmailVerification", "processing"], ...crmTaskFilter, ...crmTaskTableFilter}
    }, crmTaskNetworkUserId, crmTaskLimit, crmTaskOffset, crmTaskOrderBySearch, crmTaskStartTimeSearch, crmTaskEndTimeSearch, crmTaskDateKeySearch)

    const taskArchivedGraphQl = getCrmTaskApproval({
        filter: {statusList: ["approved", "rejected", "failed", "rejectedByBank", "expired", "cancelled"], ...crmTaskFilter, ...crmTaskArchivedTableFilter}
    }, crmTaskNetworkUserId, crmTaskLimit, crmTaskOffset, crmTaskOrderBySearch, crmTaskStartTimeSearch, crmTaskEndTimeSearch, crmTaskDateKeySearch, crmTaskArchivedMonthFilter)

    useEffect(() => {
        getLastTwoMonths()
    }, [crmTaskFilter,crmTaskArchivedTableFilter])

    switch (layout) {
        case 1:
            component = <Task_1
                data={taskGraphQl?.data?.getCrmTaskApproval?.data}
                dataArchived={taskArchivedGraphQl?.data?.getCrmTaskApproval?.data}
                refetch={taskGraphQl?.refetchQuery}
                refetchArchived={taskArchivedGraphQl?.refetchQuery}
                exportData={[]}
                exportDataLoading={false}
                moduleListing={taskGraphQl?.data?.getCrmTaskApproval?.moduleList}
                subModuleListing={taskGraphQl?.data?.getCrmTaskApproval?.subModuleList}
                total={taskGraphQl?.data?.getCrmTaskApproval?.total}
                totalArchived={taskArchivedGraphQl?.data?.getCrmTaskApproval?.total}
                loading={taskGraphQl?.loading}
                loadingArchived={taskArchivedGraphQl?.loading}
                isClearFilterVisible={isClearFilterVisible}
                resetFilters={resetFilters}
            />;
            break;

        default:
            component = <Task_1
                data={taskGraphQl?.data?.getCrmTaskApproval?.data}
                dataArchived={taskArchivedGraphQl?.data?.getCrmTaskApproval?.data}
                refetch={taskGraphQl?.refetchQuery}
                refetchArchived={taskArchivedGraphQl?.refetchQuery}
                exportData={[]}
                exportDataLoading={false}
                moduleListing={taskGraphQl?.data?.getCrmTaskApproval?.moduleList}
                subModuleListing={taskGraphQl?.data?.getCrmTaskApproval?.subModuleList}
                total={taskGraphQl?.data?.getCrmTaskApproval?.total}
                totalArchived={taskArchivedGraphQl?.data?.getCrmTaskApproval?.total}
                loading={taskGraphQl?.loading}
                loadingArchived={taskArchivedGraphQl?.loading}
                isClearFilterVisible={isClearFilterVisible}
                resetFilters={resetFilters}
            />;
            break;
    }

    return component;
}

export default TaskPage;
