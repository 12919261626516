import React, {useCallback, useEffect, useState} from "react";
import {Form, Input, Modal, Select, Switch} from "antd";
import {useTranslation} from "react-i18next";
import {labelCol, validateMessages} from "../../../../../function";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {generalListDocumentGroupState, stepSettingModalPropState} from "../../../../../state";
import {useFlowBuilderFunction} from "../../../../../function/flowBuilderFunction";

const DocumentGroupModalForm = (props) => {
    const {isAdd, isOpen, close, isDocument = false, item, isView} = props
    const {t} = useTranslation()
    const [submitLoading, setSubmitLoading] = useState(false)
    const [form] = Form.useForm()
    const {
        createUserVerificationDocumentGroupFunction,
        updateUserVerificationDocumentGroupFunction
    } = useFlowBuilderFunction()
    const groupList = useRecoilValue<any>(generalListDocumentGroupState)

    const onSubmit = async (value) => {
        setSubmitLoading(true)
        if (isAdd) {
            await createUserVerificationDocumentGroupFunction({...value, step: item?.data?.step?._id}, item?.currentCountry, item?.data?.step?.clientType)

        } else if (!isAdd && !isDocument) {
            delete value["group"]
            await updateUserVerificationDocumentGroupFunction(item?.data?._id, value, item?.currentCountry)
        } else {
            await updateUserVerificationDocumentGroupFunction(item?.group?._id, {
                documents: {
                    ...value,
                    document: item?.data?.document?._id
                }
            }, item?.currentCountry)
        }
        setSubmitLoading(false)
        close()
    }

    useEffect(() => {
        form.resetFields()
    }, [isOpen])

    const excludeExistingGroupOptions = useCallback(() => {
        const option = groupList?.map(docGroup => {
            const match = item?.data?.step?.documentGroup?.find(d => d?.group?._id === docGroup?._id)
            if (!match) {
                return {label: t(docGroup?.displayNameLabel || docGroup?.label), value: docGroup?._id}
            }
        })?.filter(d => d !== undefined)

        return option ? option : []
    }, [groupList, item?.data?.step?.documentGroup])

    const enabledInitialChecked = isDocument ? item?.data?.isEnabled : item?.data?.isEnabled

    const [title, setTitle] = useState("")
    useEffect(() => {
        if (isView) setTitle("accountVerification.documentGroup")
        else if (isAdd && !isDocument) setTitle("accountVerification.addDocumentGroup")
        else if (!isDocument && !isAdd) setTitle("accountVerification.updateDocumentGroup")
        else setTitle("accountVerification.updateDocument")
    }, [isAdd, isDocument])

    return (
        <Modal
            title={t(title)}
            open={isOpen}
            onCancel={close}
            onOk={form.submit}
            cancelText={t("common.cancel")}
            okText={isAdd ? t("common.add") : t("common.update")}
            okButtonProps={{loading: submitLoading}}
            destroyOnClose={true}
            footer={isView ? null : undefined}
        >
            <Form form={form} onFinish={onSubmit} validateMessages={validateMessages}>
                {!isDocument &&
                <Form.Item
                    labelCol={labelCol}
                    label={t("accountVerification.group")}
                    name={"group"}
                    rules={[{required: true}]}
                    initialValue={item?.data?.group?._id}
                >
                    <Select
                        placeholder={t("accountVerification.placeholder.selectGroup")}
                        options={excludeExistingGroupOptions()}
                        showSearch
                        optionFilterProp={"label"}
                        disabled={!isAdd || isDocument || isView}
                    />
                </Form.Item>
                }
                {
                    !isDocument &&
                    <Form.Item
                        label={t("accountVerification.isEnabled")}
                        name={"isEnabled"}
                        colon={false}
                        style={{textAlign: "right"}}
                        initialValue={enabledInitialChecked}
                        className={"margin-top-0-75"}
                    >
                        <Switch defaultChecked={enabledInitialChecked} disabled={isView}/>
                    </Form.Item>
                }
                {/*{isDocument &&*/}
                {/*<Form.Item*/}
                {/*    labelCol={labelCol}*/}
                {/*    label={t("accountVerification.document")}*/}
                {/*    name={"document"}*/}
                {/*    initialValue={item?.data?.document?.name}*/}
                {/*    required*/}
                {/*>*/}
                {/*    <Input disabled/>*/}
                {/*</Form.Item>*/}
                {/*}*/}
                {/*{*/}
                {/*    !isAdd &&*/}
                {/*    <Form.Item*/}
                {/*        label={t("accountVerification.isRequired")}*/}
                {/*        name={"isRequired"}*/}
                {/*        colon={false}*/}
                {/*        style={{textAlign: "right"}}*/}
                {/*        initialValue={requiredInitialChecked}*/}
                {/*        className={"margin-top-0-75"}*/}
                {/*    >*/}
                {/*        <Switch defaultChecked={requiredInitialChecked}/>*/}
                {/*    </Form.Item>*/}
                {/*}*/}
            </Form>
        </Modal>
    )
}

export default DocumentGroupModalForm