import {BsCheck, BsX} from "react-icons/bs";
import {formatDateTimeTimezone} from "../../../../../function/_common";
import React from "react";

export const Columns = () => {

    return [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: "Is Enabled",
            dataIndex: "isEnabled",
            key: "isEnabled",
            render: (text) => (text) ? <BsCheck style={{color: 'green'}}/> : <BsX style={{color: 'red'}}/>,
        },
        {
            title: "Last Schedule Event",
            dataIndex: "lastScheduleEvent",
            key: "lastScheduleEvent",
            render: (text, record) => <p>{text && text !== record?.createdAt ? formatDateTimeTimezone(text) : "-"}</p>,
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (_, record) => <p>{record?.statistic?.actions}</p>,
        },
        {
            title: "Actions Error",
            dataIndex: "actionsErr",
            key: "actionsErr",
            render: (_, record) => <p>{record?.statistic?.actionsErr}</p>,
        },
        {
            title: "Events",
            dataIndex: "events",
            key: "events",
            render: (_, record) => <p>{record?.statistic?.events}</p>,
        },
    ]
}