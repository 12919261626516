import React, {useEffect, useMemo, useState} from "react"
import {Link, useNavigate, useSearchParams} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {useRecoilState, useRecoilValue} from "recoil"
import {Button, Dropdown, Row, Select, Space} from "antd"
import type {MenuProps} from "antd"
import ReportComponent from "./component/reportComponent"
import AddButton from "../../../../../components/button/addButton"
import CreateEditReportModal from "./component/createEditReportModal"

import {getReportingCategory} from "../../../../../api/graphql/reportingCategory"
import {reportingCategoryState, reportingAllState} from "../../state"
import {getSearchParamsObj} from "../../../../../function/_common"
import GroupByRowReportModal from "./component/groupByRowReportModal"

const Report_1 = (props) => {
    const {refreshAllReportData, reportsPermission, reportCategoryPermission, getAllReports, getAllCreateEditModalData} = props
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

    const paramReportId: any = searchParams.get("reportId")
    const paramCategoryId: any = searchParams.get("categoryId")

    const [reportingCategories, setReportCategories] = useRecoilState<any>(reportingCategoryState)
    const reportingAll = useRecoilValue<any>(reportingAllState)

    const [selectedReportID, setSelectedReportID] = useState("")
    const [selectedCategoryID, setSelectedCategoryID] = useState("")
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isGroupByRowReportModalOpen, setIsGroupByRowReportModalOpen] = useState(false)
    const [formMode, setFormMode] = useState("")
    const [createReportType, setCreateReportType] = useState<any>("")
    const [reportDataloading, setReportDataLoading] = useState(true)

    const toggleModal = (mode: string | "", reportType?: string | "") => {
        setIsModalOpen((prevState) => !prevState)
        setFormMode(mode)
        setCreateReportType(reportType)
    }

    const toggleGroupByRowReportModal = (mode: string | "") => {
        setIsGroupByRowReportModalOpen((prevState) => !prevState)
        setFormMode(mode)
    }

    const onChangeReport = (value: string, options, categoryId) => {
        navigate(`/reports?reportId=${options?.reportId}&categoryId=${categoryId}`)
    }

    const onSearch = (value: string) => {
        console.log("search:", value)
    }

    const getReportCategory = async () => {
        try {
            const response = await getReportingCategory({
                filter: {isEnabled: true},
            })
            const list = response?.getReportingCategory?.data || []
            const sortedList = [...list].sort((a, b) => a?.sortingNumber - b?.sortingNumber)

            let defaultShownCategoryId = sortedList[0]?._id
            let defaultShownReportId = sortedList[0]?.reports && sortedList[0]?.reports[0]?.reprotId

            const defaultCategory = sortedList.find((v) => v?.isDefault) || sortedList?.[0]
            if (defaultCategory) {
                defaultShownCategoryId = defaultCategory?._id
                defaultShownReportId = defaultCategory?.reports?.find((v) => v?.isDefault)?.reportId || defaultCategory.reports[0]?.reportId
            }

            setReportCategories(sortedList)

            if (!paramReportId && !selectedReportID && defaultShownReportId) {
                setSelectedCategoryID(defaultShownCategoryId)
                setSelectedReportID(defaultShownReportId)
                // setSearchParams((prevState) => {
                //     const searchParamsObj = getSearchParamsObj(prevState);
                //     return {...searchParamsObj, reportId: defaultShownReportId, categoryId: defaultShownCategoryId};
                // });
            }
            return {reportId: defaultShownReportId, categoryId: defaultShownCategoryId}
        } catch (error) {}
    }

    useEffect(() => {
        setSelectedReportID(paramReportId)
        setSelectedCategoryID(paramCategoryId)
    }, [paramReportId, paramCategoryId])

    useEffect(() => {
        let cancelled = false
        getReportCategory().then(
            (data: any) => {
                if (!cancelled) {
                    if (!paramReportId && !selectedReportID) {
                        setSearchParams((prevState) => {
                            const searchParamsObj = getSearchParamsObj(prevState)
                            return {...searchParamsObj, reportId: data?.reportId, categoryId: data?.categoryId}
                        })
                    }
                } else {
                    console.log("Cancelled")
                }
            },
            (error) => {
                if (!cancelled) {
                    console.log(error)
                }
            }
        )
        return () => {
            cancelled = true
        }
    }, [isModalOpen, isGroupByRowReportModalOpen])

    useEffect(() => {
        refreshAllReportData()
    }, [isModalOpen, isGroupByRowReportModalOpen])

    const CategoryDropdown = ({category}) => {
        let categoryName = t(category?.label)
        let reportOptions = category?.reports
            ?.filter((v) => reportingAll?.some((y) => y?.reportId === v?.reportId))
            .map((item) => {
                let reportObj = reportingAll?.find((report) => report?.reportId === item?.reportId)
                return {
                    label: reportObj?.displayName,
                    value: item?.reportId,
                    reportId: item?.reportId,
                }
            })
        let selectValue = selectedCategoryID === category?._id ? selectedReportID : null
        return (
            <Select
                key={category?._id}
                showSearch
                placeholder={categoryName}
                optionFilterProp="children"
                value={selectValue}
                onChange={(value, options) => onChangeReport(value, options, category?._id)}
                onSearch={onSearch}
                filterOption={(input, option) => (option?.label ?? "")?.toString().toLowerCase().includes(input.toLowerCase())}
                options={reportOptions}
                style={{
                    minWidth: 150,
                }}
                disabled={reportDataloading}
            />
        )
    }

    const reportSummaryData = useMemo(() => {
        let reportInfo = reportingAll?.find((report) => report?.reportId === selectedReportID)
        return reportInfo
    }, [reportingAll, selectedReportID])

    const selectedCategoryName = useMemo(() => {
        return reportingCategories?.find((c) => c?._id === selectedCategoryID)?.name
    }, [reportingCategories, selectedCategoryID])

    const addCommissionReportItems: MenuProps["items"] = [
        {
            key: "commissionCalculation",
            label: (
                <Button type={"link"} onClick={() => toggleModal("add", "commission")}>
                    {t("Create Commission Calculation Report")}
                </Button>
            ),
        },
        {
            key: "commissionPayout",
            label: (
                <Button type={"link"} onClick={() => toggleModal("add", "commissionPayout")}>
                    {t("Create Commission Payout Report")}
                </Button>
            ),
        },
    ]

    return (
        <div id="reports">
            {isModalOpen && (
                <CreateEditReportModal
                    createReportType={createReportType}
                    formMode={formMode}
                    isModalOpen={isModalOpen}
                    toggleModal={toggleModal}
                    setSelectedCategoryID={setSelectedCategoryID}
                    setSelectedReportID={setSelectedReportID}
                    getAllCreateEditModalData={getAllCreateEditModalData}
                />
            )}
            {isGroupByRowReportModalOpen && (
                <GroupByRowReportModal
                    formMode={formMode}
                    isModalOpen={isGroupByRowReportModalOpen}
                    toggleModal={toggleGroupByRowReportModal}
                    selectedReportID={selectedReportID}
                    setSelectedReportID={setSelectedReportID}
                    setSelectedCategoryID={setSelectedCategoryID}
                    reportSummaryData={reportSummaryData}
                />
            )}
            <Row justify="space-between">
                <div>
                    {reportingCategories?.map((category, index) => (
                        <CategoryDropdown key={index} category={category} />
                    ))}
                </div>
                <Space>
                    {reportCategoryPermission?.view?.view && (
                        <Button>
                            <Link to="/reports/category">{t("Report Category")}</Link>
                        </Button>
                    )}
                    {reportsPermission?.add?.add && <AddButton text={"Create Report"} addHandler={() => toggleModal("add", "common")} />}
                    {reportsPermission?.add?.add && reportSummaryData?.isGroupByRowEnabled && <AddButton text={"Create Group By Row Report"} addHandler={() => toggleGroupByRowReportModal("add")} />}
                    {reportsPermission?.add?.add && reportCategoryPermission?.view?.commissionReports && (
                        <Dropdown trigger={["click"]} menu={{items: addCommissionReportItems}} placement="bottom">
                            <Button type={"primary"}>Create Commission Report</Button>
                        </Dropdown>
                    )}
                </Space>
            </Row>
            <ReportComponent
                selectedReportID={selectedReportID}
                refreshAllReportData={refreshAllReportData}
                getAllReports={getAllReports}
                getReportCategory={getReportCategory}
                reportsPermission={reportsPermission}
                reportDataloading={reportDataloading}
                setReportDataLoading={setReportDataLoading}
                getAllCreateEditModalData={getAllCreateEditModalData}
            />
        </div>
    )
}

export default Report_1
