import {gql, useMutation, useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {ApolloClientQuery} from "./_apolloClient";

const GET_PROFILE_INFORMATION = gql`
query getProfileInformationSetting($filter: ProfileInformationSettingSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
  getProfileInformationSetting(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
    ... on ProfileInformationSettingArray {
      data {
        _id
        fieldList {
          _id
          label
          fieldName
          fieldType
          isSensitive
          isRequired
          isUnique
          isUsedForLogin
          isConfirmRequired
          isDisplayToClient
          columnWidth
          minCharacterLimit
          maxCharacterLimit
          optionValue {
            label
            value
            key
          }
          category
        }
        type
        position
      }
    }
    
    ...on BaseError {
      errKey
      errMsg
    }
  }
}
`

const getProfileInformationSetting = (filter?, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data} = useQuery(GET_PROFILE_INFORMATION, {
            variables: {
                filter: filter,
                limit: limit,
                offset: offset,
                orderBy: orderBy,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, data}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const getProfileInformationSettingAsync = async (filter?, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_PROFILE_INFORMATION,
            {
                filter: filter,
                limit: limit,
                offset: offset,
                orderBy: orderBy,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const UPDATE_PROFILE_INFORMATION_SETTING = gql`
mutation updateProfileInformationSetting($input: ProfileInformationSettingInput) {
  updateProfileInformationSetting(input: $input) {
    ...on ProfileInformationSetting {
      _id
      fieldList {
        _id
        label
        fieldName
        fieldType
        isSensitive
        isRequired
        isUnique
        isUsedForLogin
        optionValue {
          label
          value
          key
        }
        category
      }
      type
      position
    }
    ...on BaseError {
      errKey
      errMsg
    }
    ...on CrmTaskApproval {
      msg
    }
  }
}
`;

const useUpdateProfileInformationSettingMutation = (): { updateProfileInformationSetting: any, data: any, loading: boolean, error: any } => {
    try{
        const { t } = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updateProfileInformationSettingMutation, { data, loading, error }] = useMutation(UPDATE_PROFILE_INFORMATION_SETTING);

        const updateProfileInformationSetting = (input) => {
            return updateProfileInformationSettingMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {updateProfileInformationSetting}}) {
                    const typename = updateProfileInformationSetting?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateProfileInformationSetting?.errKey),
                            key: "updateProfileInformationSetting",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateProfileInformationSetting?.msg),
                            key: "updateProfileInformationSetting",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t(`Update Profile Information Setting Successfully`),
                            key: "updateProfileInformationSetting",
                        });
                    }
                },
                refetchQueries: [GET_PROFILE_INFORMATION]
            });
        };

        return { updateProfileInformationSetting, data, loading, error }
    }
    catch(err: any){
        console.error(err);
        return { updateProfileInformationSetting: null, data: null, loading: false, error: err };
    }
}

export {
    GET_PROFILE_INFORMATION,
    getProfileInformationSetting,
    getProfileInformationSettingAsync,
    useUpdateProfileInformationSettingMutation
}
