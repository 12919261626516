import React from "react";
import {getServerSetting} from "../../../api/graphql/serverSetting";
import MamPammProduct_1 from "./view/layout1/index";
import {useRecoilValue} from "recoil";
import {selfPermissionObj} from "../../../../recoil_state";

function MamPammProduct() {
  let layout = 1;
  let component;
  const permission = useRecoilValue<any>(selfPermissionObj)
  const { data: serverData } = getServerSetting({ filter: null });

  switch (layout) {
    case 1:
      component = (
          <MamPammProduct_1
              serverData={serverData?.getServerSetting?.data}
              permission={permission?.mampamm?.product}
          />
      );
      break;

    default:
      component = (
          <MamPammProduct_1
              serverData={serverData?.getServerSetting?.data}
              permission={permission?.mampamm?.product}
          />
      );
      break;
  }

  return component;
}

export default MamPammProduct;
