import {gql, useMutation, useQuery} from "@apollo/client";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {useTranslation} from "react-i18next";
import {GET_USER_NETWORK_TREE} from "./userNetworkTree";
import {ApolloClientQuery} from "./_apolloClient";

const GET_USER_COUNT = gql`
    query getUserCount($filter: UserInput) {
        getUserCount(filter: $filter)
    }
`;

const getUserCount = ({filter}) => {
    try {
        const {loading, error, data} = useQuery(GET_USER_COUNT, {
            variables: {
                filter: filter,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const getUserCountAsync = async ({filter}) => {
    try {
        return await ApolloClientQuery(
            GET_USER_COUNT,
            {
                filter: filter,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_ARCHIVE_USER_COUNT = gql`
    query getArchiveUserCount($filter: UserInput) {
        getArchiveUserCount(filter: $filter)
    }
`;

const getArchiveUserCount = ({filter}) => {
    try {
        const {loading, error, data} = useQuery(GET_ARCHIVE_USER_COUNT, {
            variables: {
                filter: filter,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const getArchiveUserCountAsync = async ({filter}) => {
    try {
        return await ApolloClientQuery(
            GET_ARCHIVE_USER_COUNT,
            {
                filter: filter,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_USER_BY_ID = gql`
    query getUserByID($id: String!) {
        getUserByID(id: $id) {
            ... on User {
                _id
                id
                firstName
                middleName
                lastName
                fullName
                aliasFirstName
                aliasMiddleName
                aliasLastName
                aliasFullName
                username
                email
                gender
                dateOfBirth
                age
                countryCode
                mobileNo
                completedMobileNo
                domain
                userType
                identificationType
                identificationNo
                comment
                language
                newsletter
                address
                city
                postalCode
                state
                country
                occupationType
                purposeOfTransaction
                nameOfEmployer
                natureOfBusiness
                approximateNetWorth
                countryOfTaxResidency
                sourceOfWealth
                affiliateCode
                companyName
                profileImage
                eWallet {_id isDepositEnable isWithdrawalEnable balance}
                experiencePoint
                pspUserId
                status
                clientAccountType
                rank { _id name isDefaultRank }
                desk { _id name }
                referrer { _id fullName username }
                salesRep { _id fullName username }
                role { _id name isDefaultCrmUserPermission }
                lastDepositDate
                isLoginEnabled
                isTestAccount
                isKycApproved
                isDeleted
                deletedReason
                legalName
                dateOfIncorporation
                dbaAliasTradingName
                incorporationAddress
                incorporationCity
                incorporationPostalCode
                incorporationCountry
                registeredAddress
                registeredCity
                registeredPostalCode
                registeredCountry
                businessAddress
                businessCity
                businessPostalCode
                businessCountry
                representativeType
                formerName
                formerDbaAliasTradingName
                registrationNumber
                corporateEmail
                corporateCountryCode
                corporateMobileNo
                legalForm
                isSuspicious
                verifiedEmail
                previousFollowUp { followUpDateTime }
                latestFollowUp { followUpDateTime }
                emailVerificationCode
                loginStartDate
                loginEndDate
                nationality
                isActive
                signature
                theme
                createdAt
                updatedAt
                companyNumber
                IncorporationDate
                BusinessNature
                PositionInCompany
                representativeAddress
                representativeCity
                representativePostalCode
                representativeState
                representativeCountry
                isTemporaryPasswordEnabled
                directClient
                account
                isDeletedByClient
                commRule {
                    rule
                    ruleName
                    group
                    groupName
                    commissionSetting
                    commissionSettingName
                    ruleType
                    commRuleType
                    commRuleAuto
                }
                lastActivitiesDate
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getUserById = (id) => {
    try {
        const {loading, error, data} = useQuery(GET_USER_BY_ID, {
            variables: {
                id: id,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const getUserByIdAsync = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            GET_USER_BY_ID,
            {
                id: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_ARCHIVE_USER = gql`
    query getArchiveUser(
        $filter: UserInput
        $limit: Int
        $offset: Int
        $orderBy: String
    ) {
        getArchiveUser(
            filter: $filter
            limit: $limit
            offset: $offset
            orderBy: $orderBy
        ) {
            ... on UserAry {
                data {
                    _id
                    id
                    firstName
                    middleName
                    lastName
                    fullName
                    aliasFirstName
                    aliasMiddleName
                    aliasLastName
                    aliasFullName
                    username
                    email
                    gender
                    dateOfBirth
                    age
                    countryCode
                    mobileNo
                    completedMobileNo
                    domain
                    userType
                    identificationType
                    identificationNo
                    comment
                    language
                    newsletter
                    address
                    city
                    postalCode
                    state
                    country
                    occupationType
                    purposeOfTransaction
                    nameOfEmployer
                    natureOfBusiness
                    approximateNetWorth
                    countryOfTaxResidency
                    sourceOfWealth
                    affiliateCode
                    companyName
                    profileImage
                    eWallet {_id isDepositEnable isWithdrawalEnable balance}
                    experiencePoint
                    pspUserId
                    status
                    clientAccountType
                    rank { _id name }
                    desk { _id name }
                    referrer { _id fullName username }
                    salesRep { _id fullName username }
                    role { _id name isDefaultCrmUserPermission }
                    lastDepositDate
                    isLoginEnabled
                    isTestAccount
                    isKycApproved
                    isDeleted
                    deletedReason
                    legalName
                    dateOfIncorporation
                    dbaAliasTradingName
                    incorporationAddress
                    incorporationCity
                    incorporationPostalCode
                    incorporationCountry
                    registeredAddress
                    registeredCity
                    registeredPostalCode
                    registeredCountry
                    businessAddress
                    businessCity
                    businessPostalCode
                    businessCountry
                    representativeType
                    formerName
                    formerDbaAliasTradingName
                    registrationNumber
                    corporateEmail
                    corporateCountryCode
                    corporateMobileNo
                    legalForm
                    isSuspicious
                    verifiedEmail
                    previousFollowUp { followUpDateTime }
                    latestFollowUp { followUpDateTime }
                    emailVerificationCode
                    loginStartDate
                    loginEndDate
                    nationality
                    isActive
                    signature
                    theme
                    createdAt
                    updatedAt
                    companyNumber
                    IncorporationDate
                    BusinessNature
                    PositionInCompany
                    representativeAddress
                    representativeCity
                    representativePostalCode
                    representativeState
                    representativeCountry
                    isTemporaryPasswordEnabled
                    directClient
                    account
                    isDeletedByClient
                    lastActivitiesDate
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getArchiveUser = ({filter}, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data} = useQuery(GET_ARCHIVE_USER, {
            variables: {
                filter: filter,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const getArchiveUserAsync = async ({filter}, limit?, offset?, orderBy?) => {
    try {
        return await ApolloClientQuery(
            GET_ARCHIVE_USER,
            {
                filter: filter,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_USER = gql`
    query getUser(
        $filter: UserInput
        $limit: Int
        $offset: Int
        $orderBy: String
    ) {
        getUser(
            filter: $filter
            limit: $limit
            offset: $offset
            orderBy: $orderBy
        ) {
            ... on UserAry {
                data {
                    _id
                    id
                    firstName
                    middleName
                    lastName
                    fullName
                    aliasFirstName
                    aliasMiddleName
                    aliasLastName
                    aliasFullName
                    username
                    email
                    gender
                    dateOfBirth
                    age
                    countryCode
                    mobileNo
                    completedMobileNo
                    domain
                    userType
                    identificationType
                    identificationNo
                    comment
                    language
                    newsletter
                    address
                    city
                    postalCode
                    state
                    country
                    occupationType
                    purposeOfTransaction
                    nameOfEmployer
                    natureOfBusiness
                    approximateNetWorth
                    countryOfTaxResidency
                    sourceOfWealth
                    affiliateCode
                    companyName
                    profileImage
                    eWallet {_id isDepositEnable isWithdrawalEnable balance}
                    experiencePoint
                    pspUserId
                    status
                    clientAccountType
                    rank { _id name }
                    desk { _id name }
                    referrer { _id fullName username }
                    salesRep { _id fullName username }
                    role { _id name isDefaultCrmUserPermission }
                    lastDepositDate
                    isLoginEnabled
                    isTestAccount
                    isKycApproved
                    isDeleted
                    deletedReason
                    legalName
                    dateOfIncorporation
                    dbaAliasTradingName
                    incorporationAddress
                    incorporationCity
                    incorporationPostalCode
                    incorporationCountry
                    registeredAddress
                    registeredCity
                    registeredPostalCode
                    registeredCountry
                    businessAddress
                    businessCity
                    businessPostalCode
                    businessCountry
                    representativeType
                    formerName
                    formerDbaAliasTradingName
                    registrationNumber
                    corporateEmail
                    corporateCountryCode
                    corporateMobileNo
                    legalForm
                    isSuspicious
                    verifiedEmail
                    previousFollowUp { followUpDateTime }
                    latestFollowUp { followUpDateTime lastFollowUp }
                    emailVerificationCode
                    loginStartDate
                    loginEndDate
                    nationality
                    isActive
                    signature
                    theme
                    createdAt
                    updatedAt
                    companyNumber
                    IncorporationDate
                    BusinessNature
                    PositionInCompany
                    representativeAddress
                    representativeCity
                    representativePostalCode
                    representativeState
                    representativeCountry
                    isTemporaryPasswordEnabled
                    directClient
                    account
                    isDeletedByClient
                    lastActivitiesDate
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getUser = ({filter}, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data} = useQuery(GET_USER, {
            variables: {
                filter: filter,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const getUserAsync = async ({filter}, limit?, offset?, orderBy?) => {
    try {
        return await ApolloClientQuery(
            GET_USER,
            {
                filter: filter,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_USER_SUBSET = gql`
    query getUserSubset($filter: UserSubsetInput, $limit: Int, $offset: Int, $orderBy: String) {
        getUserSubset(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on UserSubsetAry {
                total
                data {
                    _id
                    parentId
                    affiliateCode
                    nationality
                    username
                    fullName
                    identificationNo
                    email
                    dateOfBirth
                    countryCode
                    mobileNo
                    completedMobileNo
                    gender
                    address
                    city
                    postalCode
                    state
                    country
                    leadSource
                    tag
                    userType
                    rank
                    referrer
                    referrerId
                    salesRepId
                    lastDepositDate
                    lastActivitiesDate
                    nextFollowUp
                    lastFollowUp
                    domain
                    language
                    isLoginEnabled
                    accountCount
                    eWallet
                    registrationDate
                    commRule
                    clientAccountType
                    comment
                    role
                    desk
                    loginStartDate
                    loginEndDate
                    isDeleted
                    directClient
                }
            }
        }
    }
`

const getUserSubset = async ({filter}, limit?, offset?, orderBy?) => {
    try {
        return await ApolloClientQuery(
            GET_USER_SUBSET,
            {
                filter: filter,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_USER = gql`
    mutation addUser($input: UserInput) {
        addUser(input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on User {
                id
                _id
                userType
                email
                domain
                username
                firstName
                middleName
                lastName
                fullName
                age
                identificationNo
                dateOfBirth
                gender
                comment
                countryCode
                mobileNo
                completedMobileNo
                profileImage
                affiliateCode
                pspUserId
                language
                newsletter
                verifiedEmail
                address
                city
                postalCode
                state
                country
                status
                rank {_id name}
                desk {_id name}
                role {_id name}
                referrer {
                    _id
                    fullName
                    username
                }
                salesRep {
                    _id
                    fullName
                    username
                }
                eWallet {_id isDepositEnable isWithdrawalEnable balance}
                experiencePoint
                clientAccountType
                isLoginEnabled
                isTestAccount
                isKycApproved
                latestFollowUp{followUpDateTime}
                previousFollowUp{lastFollowUp}
                account
                directClient
                loginStartDate
                loginEndDate
                lastDepositDate
                createdAt
                updatedAt
                companyNumber
                IncorporationDate
                BusinessNature
                PositionInCompany
                representativeAddress
                representativeCity
                representativePostalCode
                representativeState
                representativeCountry
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;

const useAddUserMutation = (): { addUser: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [addUserMutation, {data, loading, error}] = useMutation(ADD_USER);

        const addUser = (input) => {
            return addUserMutation({
                variables: {
                    input: input,
                },
                refetchQueries: [GET_USER, GET_USER_COUNT, GET_USER_NETWORK_TREE, GET_USER_FILTER]
            });
        };

        return {addUser, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {addUser: null, data: null, loading: false, error: err};
    }
}

const UPDATE_USER = gql`
    mutation updateUser($id: String!, $input: UserInput) {
        updateUser(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on User {
                id
                _id
                userType
                email
                domain
                username
                firstName
                middleName
                lastName
                fullName
                age
                identificationNo
                dateOfBirth
                gender
                comment
                countryCode
                mobileNo
                completedMobileNo
                profileImage
                affiliateCode
                pspUserId
                language
                newsletter
                verifiedEmail
                address
                city
                postalCode
                state
                country
                status
                rank {_id name}
                desk {_id name}
                role {_id name}
                referrer {
                    _id
                    fullName
                    username
                }
                salesRep {
                    _id
                    fullName
                    username
                }
                eWallet {_id isDepositEnable isWithdrawalEnable balance}
                experiencePoint
                clientAccountType
                isSuspicious
                isLoginEnabled
                isTestAccount
                isKycApproved
                latestFollowUp{followUpDateTime}
                previousFollowUp{lastFollowUp}
                account
                directClient
                loginStartDate
                loginEndDate
                lastDepositDate
                createdAt
                updatedAt
                companyNumber
                IncorporationDate
                BusinessNature
                PositionInCompany
                representativeAddress
                representativeCity
                representativePostalCode
                representativeState
                representativeCountry
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;

const useUpdateUserMutation = (): { updateUser: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [updateUserMutation, {data, loading, error}] = useMutation(UPDATE_USER);

        const updateUser = (id, input) => {
            return updateUserMutation({
                variables: {
                    id: id,
                    input: input,
                },
                update(cache, {data: {updateUser}}) {
                    const typename = updateUser?.__typename;
                    if (typename === "BaseError") {
                        let msg = updateUser?.errObj?.length > 0 ? t("admin.err.unique_field_found", {field: updateUser?.errObj?.join(",")}) : t(updateUser?.errKey)
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: msg,
                            key: "updateUser",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateUser?.msg),
                            key: "updateUser",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update User Successfully"),
                            key: "updateUser",
                        });
                    }
                },
                //refetchQueries: [GET_USER_BY_ID]
            });
        };

        return {updateUser, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {updateUser: null, data: null, loading: false, error: err};
    }
}


const UPDATE_USER_DOMAIN = gql`
    mutation updateUserDomain($ids: [String], $domain: String) {
        updateUserDomain(ids: $ids, domain: $domain) {
            ... on CrmTaskApproval {
                msg
            }
            ... on UserAry {
                data {
                    _id
                    id
                    firstName
                    middleName
                    lastName
                    fullName
                    aliasFirstName
                    aliasMiddleName
                    aliasLastName
                    aliasFullName
                    username
                    email
                    gender
                    dateOfBirth
                    age
                    countryCode
                    mobileNo
                    completedMobileNo
                    domain
                    userType
                    identificationType
                    identificationNo
                    comment
                    language
                    newsletter
                    address
                    city
                    postalCode
                    state
                    country
                    occupationType
                    purposeOfTransaction
                    nameOfEmployer
                    natureOfBusiness
                    approximateNetWorth
                    countryOfTaxResidency
                    sourceOfWealth
                    affiliateCode
                    companyName
                    profileImage
                    eWallet {_id isDepositEnable isWithdrawalEnable balance}
                    experiencePoint
                    pspUserId
                    status
                    clientAccountType
                    rank { _id name }
                    desk { _id name }
                    referrer { _id fullName username }
                    salesRep { _id fullName username }
                    role { _id name isDefaultCrmUserPermission }
                    lastDepositDate
                    isLoginEnabled
                    isTestAccount
                    isKycApproved
                    isDeleted
                    deletedReason
                    legalName
                    dateOfIncorporation
                    dbaAliasTradingName
                    incorporationAddress
                    incorporationCity
                    incorporationPostalCode
                    incorporationCountry
                    registeredAddress
                    registeredCity
                    registeredPostalCode
                    registeredCountry
                    businessAddress
                    businessCity
                    businessPostalCode
                    businessCountry
                    representativeType
                    formerName
                    formerDbaAliasTradingName
                    registrationNumber
                    corporateEmail
                    corporateCountryCode
                    corporateMobileNo
                    legalForm
                    isSuspicious
                    verifiedEmail
                    previousFollowUp { followUpDateTime }
                    latestFollowUp { followUpDateTime lastFollowUp }
                    emailVerificationCode
                    loginStartDate
                    loginEndDate
                    nationality
                    isActive
                    signature
                    theme
                    createdAt
                    updatedAt
                    companyNumber
                    IncorporationDate
                    BusinessNature
                    PositionInCompany
                    representativeAddress
                    representativeCity
                    representativePostalCode
                    representativeState
                    representativeCountry
                    isTemporaryPasswordEnabled
                    directClient
                    account
                    isDeletedByClient
                    lastActivitiesDate
                }
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;

const useUpdateUserDomainMutation = (): { updateUserDomain: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [updateUserDomainMutation, {data, loading, error}] = useMutation(UPDATE_USER_DOMAIN);

        const updateUserDomain = (ids, domain) => {
            return updateUserDomainMutation({
                variables: {
                    ids: ids,
                    domain: domain,
                },
                update(cache, {data: {updateUserDomain}}) {
                    const typename = updateUserDomain?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateUserDomain?.errKey),
                            key: "updateUserDomain",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateUserDomain?.msg),
                            key: "updateUserDomain",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update User Domain Successfully"),
                            key: "updateUserDomain",
                        });
                    }
                },
                refetchQueries: [GET_USER]
            });
        };

        return {updateUserDomain, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {updateUserDomain: null, data: null, loading: false, error: err};
    }
}

const UPDATE_GENERAL_USER = gql`
    mutation updateGeneralUser($id: String, $input: GeneralUserInput) {
        updateGeneralUser(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on User {
                id
                _id
                userType
                email
                domain
                username
                firstName
                middleName
                lastName
                fullName
                age
                identificationNo
                dateOfBirth
                gender
                comment
                countryCode
                mobileNo
                completedMobileNo
                profileImage
                affiliateCode
                pspUserId
                language
                newsletter
                verifiedEmail
                address
                city
                postalCode
                state
                country
                status
                rank {_id name}
                desk {_id name}
                role {_id name}
                referrer {
                    _id
                    fullName
                    username
                }
                salesRep {
                    _id
                    fullName
                    username
                }
                eWallet {_id isDepositEnable isWithdrawalEnable balance}
                experiencePoint
                clientAccountType
                isSuspicious
                isLoginEnabled
                isTestAccount
                isKycApproved
                latestFollowUp{followUpDateTime}
                previousFollowUp{lastFollowUp}
                account
                directClient
                loginStartDate
                loginEndDate
                lastDepositDate
                createdAt
                updatedAt
                companyNumber
                IncorporationDate
                BusinessNature
                PositionInCompany
                representativeAddress
                representativeCity
                representativePostalCode
                representativeState
                representativeCountry
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useUpdateGeneralUserMutation = (): { updateGeneralUser: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [updateGeneralUserMutation, {data, loading, error}] = useMutation(UPDATE_GENERAL_USER);

        const updateGeneralUser = (id, input) => {
            return updateGeneralUserMutation({
                variables: {
                    id: id,
                    input: input,
                },
                update(cache, {data: {updateGeneralUser}}) {
                    const typename = updateGeneralUser?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateGeneralUser?.errKey),
                            key: "updateGeneralUser",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateGeneralUser?.msg),
                            key: "updateGeneralUser",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update User Successfully"),
                            key: "updateGeneralUser",
                        });
                    }
                },
                //refetchQueries: [GET_USER_BY_ID]
            });
        };

        return {updateGeneralUser, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {updateGeneralUser: null, data: null, loading: false, error: err};
    }
}

const DELETE_USER = gql`
    mutation deleteUser($id: String!) {
        deleteUser(id: $id) {
            ... on CrmTaskApproval {
                msg
            }
            ... on User {
                id
                isDeleted
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useDeleteUserMutation = (): {
    deleteUser: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [deleteUserMutation, {data, loading, error}] = useMutation(DELETE_USER);

        const deleteUser = (id) => {
            return deleteUserMutation({
                variables: {
                    id: id,
                },
                update(cache, {data: {deleteUser}}) {
                    const typename = deleteUser?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(deleteUser?.errKey),
                            key: "deleteUser",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(deleteUser?.msg),
                            key: "deleteUser",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Delete User Successfully"),
                            key: "deleteUser",
                        });
                    }
                },
                refetchQueries: [GET_USER, GET_USER_COUNT, GET_USER_NETWORK_TREE]
            });
        };

        return {deleteUser, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {deleteUser: null, data: null, loading: false, error: err};
    }
};

const CHECK_IS_MASTER_ADMIN = gql`
    query checkIsMasterAdmin($id: String!) {
        checkIsMasterAdmin(id: $id)
    }
`;

const checkIsMasterAdmin = (id) => {
    try {
        const {loading, error, data} = useQuery(CHECK_IS_MASTER_ADMIN, {
            variables: {
                id: id,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, isMasterAdmin: data?.checkIsMasterAdmin};
    } catch (err: any) {
        console.error(err);
        return {isMasterAdmin: null, loading: false, error: err};
    }
};

const BATCH_UPDATE = gql`
    mutation batchUpdate($ids: [String]!, $input: UserInput, $userType: String!) {
        batchUpdate(ids: $ids, input: $input, userType: $userType){
            ...on UserAry {
                data {
                    id
                    _id
                    userType
                    email
                    domain
                    username
                    firstName
                    middleName
                    lastName
                    fullName
                    identificationNo
                    dateOfBirth
                    gender
                    comment
                    countryCode
                    mobileNo
                    completedMobileNo
                    profileImage
                    affiliateCode
                    pspUserId
                    language
                    newsletter
                    verifiedEmail
                    address
                    city
                    postalCode
                    state
                    country
                    status
                    rank {_id name}
                    desk {_id name}
                    role {_id name}
                    referrer {
                        _id
                        fullName
                    }
                    salesRep {
                        _id
                        fullName
                    }
                    eWallet {_id isDepositEnable isWithdrawalEnable balance}
                    experiencePoint
                    clientAccountType
                    isLoginEnabled
                    isTestAccount
                    isKycApproved
                    latestFollowUp{followUpDateTime}
                    previousFollowUp{lastFollowUp}
                    account
                    directClient
                    loginStartDate
                    loginEndDate
                    lastDepositDate
                    createdAt
                    updatedAt
                    companyNumber
                    IncorporationDate
                    BusinessNature
                    PositionInCompany
                    representativeAddress
                    representativeCity
                    representativePostalCode
                    representativeState
                    representativeCountry
                }
            }
            ...on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`

const useBatchUpdate = (): { batchUpdate: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [batchUpdateMutation, {data, loading, error}] = useMutation(BATCH_UPDATE);

        const batchUpdate = (ids, input, userType) => {
            return batchUpdateMutation({
                variables: {
                    ids: ids,
                    input: input,
                    userType: userType
                },
                update(cache, {data: {batchUpdate}}) {
                    const typename = batchUpdate?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(batchUpdate?.errKey),
                            key: "batchUpdate",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(batchUpdate?.msg),
                            key: "batchUpdate",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Batch Update Successfully"),
                            key: "batchUpdate",
                        });
                    }
                },
                refetchQueries: [GET_USER]
            });
        };

        return {batchUpdate, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {batchUpdate: null, data: null, loading: false, error: err};
    }
}

const BATCH_UPDATE_REFERRER = gql`
    mutation batchUpdateReferrer($ids: [String]!, $referrer: String, $userType: String!) {
        batchUpdateReferrer(ids: $ids, referrer: $referrer, userType: $userType){
            ...on UserAry {
                data {
                    id
                    _id
                    userType
                    email
                    domain
                    username
                    firstName
                    middleName
                    lastName
                    fullName
                    identificationNo
                    dateOfBirth
                    gender
                    comment
                    countryCode
                    mobileNo
                    completedMobileNo
                    profileImage
                    affiliateCode
                    pspUserId
                    language
                    newsletter
                    verifiedEmail
                    address
                    city
                    postalCode
                    state
                    country
                    status
                    rank {_id name}
                    desk {_id name}
                    role {_id name}
                    referrer {
                        _id
                        fullName
                    }
                    salesRep {
                        _id
                        fullName
                    }
                    eWallet {_id isDepositEnable isWithdrawalEnable balance}
                    clientAccountType
                    isLoginEnabled
                    isTestAccount
                    isKycApproved
                    latestFollowUp{followUpDateTime}
                    previousFollowUp{lastFollowUp}
                    account
                    directClient
                    loginStartDate
                    loginEndDate
                    lastDepositDate
                    createdAt
                    updatedAt
                }
            }
            ...on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`

const useBatchUpdateReferrer = (): { batchUpdateReferrer: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [batchUpdateReferrerMutation, {data, loading, error}] = useMutation(BATCH_UPDATE_REFERRER);

        const batchUpdateReferrer = (ids, referrer, userType) => {
            return batchUpdateReferrerMutation({
                variables: {
                    ids: ids,
                    referrer: referrer,
                    userType: userType
                },
                update(cache, {data: {batchUpdateReferrer}}) {
                    const typename = batchUpdateReferrer?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(batchUpdateReferrer?.errKey),
                            key: "batchUpdateReferrer",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(batchUpdateReferrer?.msg),
                            key: "batchUpdateReferrer",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Batch Edit Referrer Successfully"),
                            key: "batchUpdateReferrer",
                        });
                    }
                },
                refetchQueries: [GET_USER]
            });
        };

        return {batchUpdateReferrer, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {batchUpdateReferrer: null, data: null, loading: false, error: err};
    }
}

const BATCH_CREATE_FOLLOW_UP = gql`
    mutation batchCreateFollowUp($userId: String, $followings: [String], $input: FollowUpInput) {
        batchCreateFollowUp(userId: $userId, followings: $followings, input: $input){
            ...on FollowUpAry {
                data {
                    _id
                    user {username}
                    followingUser {username}
                    followingLeads {name}
                    followingType
                    type
                    description
                    followUpDateTime
                    lastFollowUp
                    isFollowUp
                }
            }
            ...on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`

const useBatchCreateFollowUp = (): { batchCreateFollowUp: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [batchCreateFollowUpMutation, {data, loading, error}] = useMutation(BATCH_CREATE_FOLLOW_UP);

        const batchCreateFollowUp = (userId, followings, input) => {
            return batchCreateFollowUpMutation({
                variables: {
                    userId: userId,
                    followings: followings,
                    input: input
                },
                update(cache, {data: {batchCreateFollowUp}}) {
                    const typename = batchCreateFollowUp?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(batchCreateFollowUp?.errKey),
                            key: "batchCreateFollowUp",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(batchCreateFollowUp?.msg),
                            key: "batchCreateFollowUp",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Batch Create Follow Up Successfully"),
                            key: "batchCreateFollowUp",
                        });
                    }
                },
                refetchQueries: [GET_USER]
            });
        };

        return {batchCreateFollowUp, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {batchCreateFollowUp: null, data: null, loading: false, error: err};
    }
}

const UPDATE_USER_ROLE = gql`
    mutation updateUserRole($id: String, $input: RolesInput) {
        updateUserRole(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on User {
                id
                _id
                userType
                email
                domain
                username
                firstName
                middleName
                lastName
                fullName
                age
                identificationNo
                dateOfBirth
                gender
                comment
                countryCode
                mobileNo
                completedMobileNo
                profileImage
                affiliateCode
                pspUserId
                language
                newsletter
                verifiedEmail
                address
                city
                postalCode
                state
                country
                status
                rank {_id name}
                desk {_id name}
                role {_id name}
                referrer {
                    _id
                    fullName
                    username
                }
                salesRep {
                    _id
                    fullName
                    username
                }
                eWallet {_id isDepositEnable isWithdrawalEnable balance}
                experiencePoint
                clientAccountType
                isSuspicious
                isLoginEnabled
                isTestAccount
                isKycApproved
                latestFollowUp{followUpDateTime}
                previousFollowUp{lastFollowUp}
                account
                directClient
                loginStartDate
                loginEndDate
                lastDepositDate
                createdAt
                updatedAt
                companyNumber
                IncorporationDate
                BusinessNature
                PositionInCompany
                representativeAddress
                representativeCity
                representativePostalCode
                representativeState
                representativeCountry
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useUpdateUserRoleMutation = (): { updateUserRole: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [updateUserRoleMutation, {data, loading, error}] = useMutation(UPDATE_USER_ROLE);

        const updateUserRole = (id, input) => {
            return updateUserRoleMutation({
                variables: {
                    id: id,
                    input: input,
                },
                update(cache, {data: {updateUserRole}}) {
                    const typename = updateUserRole?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateUserRole?.errKey),
                            key: "updateUserRole",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateUserRole?.msg),
                            key: "updateUserRole",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update User Role Successfully"),
                            key: "updateUserRole",
                        });
                    }
                },
                //refetchQueries: [GET_USER_BY_ID]
            });
        };

        return {updateUserRole, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {updateUserRole: null, data: null, loading: false, error: err};
    }
}

const UPDATE_USER_REFERRER = gql`
    mutation updateUserReferrer($id: String, $input: ReferrerInput) {
        updateUserReferrer(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on User {
                id
                _id
                userType
                email
                domain
                username
                firstName
                middleName
                lastName
                fullName
                age
                identificationNo
                dateOfBirth
                gender
                comment
                countryCode
                mobileNo
                completedMobileNo
                profileImage
                affiliateCode
                pspUserId
                language
                newsletter
                verifiedEmail
                address
                city
                postalCode
                state
                country
                status
                rank {_id name}
                desk {_id name}
                role {_id name}
                referrer {
                    _id
                    fullName
                    username
                }
                salesRep {
                    _id
                    fullName
                    username
                }
                eWallet {_id isDepositEnable isWithdrawalEnable balance}
                experiencePoint
                clientAccountType
                isSuspicious
                isLoginEnabled
                isTestAccount
                isKycApproved
                latestFollowUp{followUpDateTime}
                previousFollowUp{lastFollowUp}
                account
                directClient
                loginStartDate
                loginEndDate
                lastDepositDate
                createdAt
                updatedAt
                companyNumber
                IncorporationDate
                BusinessNature
                PositionInCompany
                representativeAddress
                representativeCity
                representativePostalCode
                representativeState
                representativeCountry
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useUpdateUserReferrerMutation = (): { updateUserReferrer: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [updateUserReferrerMutation, {data, loading, error}] = useMutation(UPDATE_USER_REFERRER);

        const updateUserReferrer = (id, input) => {
            return updateUserReferrerMutation({
                variables: {
                    id: id,
                    input: input,
                },
                update(cache, {data: {updateUserReferrer}}) {
                    const typename = updateUserReferrer?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateUserReferrer?.errKey),
                            key: "updateUserReferrer",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateUserReferrer?.msg),
                            key: "updateUserReferrer",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update User Referrer Successfully"),
                            key: "updateUserReferrer",
                        });
                    }
                },
                //refetchQueries: [GET_USER_BY_ID]
            });
        };

        return {updateUserReferrer, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {updateUserReferrer: null, data: null, loading: false, error: err};
    }
}

const UPDATE_USER_RANK = gql`
    mutation updateUserRank($id: String, $input: RankInput) {
        updateUserRank(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on User {
                id
                _id
                userType
                email
                domain
                username
                firstName
                middleName
                lastName
                fullName
                age
                identificationNo
                dateOfBirth
                gender
                comment
                countryCode
                mobileNo
                completedMobileNo
                profileImage
                affiliateCode
                pspUserId
                language
                newsletter
                verifiedEmail
                address
                city
                postalCode
                state
                country
                status
                rank {_id name}
                desk {_id name}
                role {_id name}
                referrer {
                    _id
                    fullName
                    username
                }
                salesRep {
                    _id
                    fullName
                    username
                }
                eWallet {_id isDepositEnable isWithdrawalEnable balance}
                experiencePoint
                clientAccountType
                isSuspicious
                isLoginEnabled
                isTestAccount
                isKycApproved
                latestFollowUp{followUpDateTime}
                previousFollowUp{lastFollowUp}
                account
                directClient
                loginStartDate
                loginEndDate
                lastDepositDate
                createdAt
                updatedAt
                companyNumber
                IncorporationDate
                BusinessNature
                PositionInCompany
                representativeAddress
                representativeCity
                representativePostalCode
                representativeState
                representativeCountry
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useUpdateUserRankMutation = (): { updateUserRank: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [updateUserRankMutation, {data, loading, error}] = useMutation(UPDATE_USER_RANK);

        const updateUserRank = (id, input) => {
            return updateUserRankMutation({
                variables: {
                    id: id,
                    input: input,
                },
                update(cache, {data: {updateUserRank}}) {
                    const typename = updateUserRank?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateUserRank?.errKey),
                            key: "updateUserRank",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateUserRank?.msg),
                            key: "updateUserRank",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update User Rank Successfully"),
                            key: "updateUserRank",
                        });
                    }
                },
                //refetchQueries: [GET_USER_BY_ID]
            });
        };

        return {updateUserRank, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {updateUserRank: null, data: null, loading: false, error: err};
    }
}

const UPDATE_USER_DESK = gql`
    mutation updateUserDesk($id: String, $input: DeskInput) {
        updateUserDesk(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on User {
                id
                _id
                userType
                email
                domain
                username
                firstName
                middleName
                lastName
                fullName
                age
                identificationNo
                dateOfBirth
                gender
                comment
                countryCode
                mobileNo
                completedMobileNo
                profileImage
                affiliateCode
                pspUserId
                language
                newsletter
                verifiedEmail
                address
                city
                postalCode
                state
                country
                status
                rank {_id name}
                desk {_id name}
                role {_id name}
                referrer {
                    _id
                    fullName
                    username
                }
                salesRep {
                    _id
                    fullName
                    username
                }
                eWallet {_id isDepositEnable isWithdrawalEnable balance}
                experiencePoint
                clientAccountType
                isSuspicious
                isLoginEnabled
                isTestAccount
                isKycApproved
                latestFollowUp{followUpDateTime}
                previousFollowUp{lastFollowUp}
                account
                directClient
                loginStartDate
                loginEndDate
                lastDepositDate
                createdAt
                updatedAt
                companyNumber
                IncorporationDate
                BusinessNature
                PositionInCompany
                representativeAddress
                representativeCity
                representativePostalCode
                representativeState
                representativeCountry
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useUpdateUserDeskMutation = (): { updateUserDesk: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [updateUserDeskMutation, {data, loading, error}] = useMutation(UPDATE_USER_DESK);

        const updateUserDesk = (id, input) => {
            return updateUserDeskMutation({
                variables: {
                    id: id,
                    input: input,
                },
                update(cache, {data: {updateUserDesk}}) {
                    const typename = updateUserDesk?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateUserDesk?.errKey),
                            key: "updateUserDesk",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateUserDesk?.msg),
                            key: "updateUserDesk",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update User Desk Successfully"),
                            key: "updateUserDesk",
                        });
                    }
                },
                //refetchQueries: [GET_USER_BY_ID]
            });
        };

        return {updateUserDesk, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {updateUserDesk: null, data: null, loading: false, error: err};
    }
}

const UPDATE_SUSPICIOUS_STATUS = gql`
    mutation updateSuspicousStatus($id: String!, $input: SuspicousStatusInput) {
        updateSuspicousStatus(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on User {
                id
                _id
                userType
                email
                domain
                username
                firstName
                middleName
                lastName
                fullName
                age
                identificationNo
                dateOfBirth
                gender
                comment
                countryCode
                mobileNo
                completedMobileNo
                profileImage
                affiliateCode
                pspUserId
                language
                newsletter
                verifiedEmail
                address
                city
                postalCode
                state
                country
                status
                rank {_id name}
                desk {_id name}
                role {_id name}
                referrer {
                    _id
                    fullName
                    username
                }
                salesRep {
                    _id
                    fullName
                    username
                }
                eWallet {_id isDepositEnable isWithdrawalEnable balance}
                experiencePoint
                clientAccountType
                isSuspicious
                isLoginEnabled
                isTestAccount
                isKycApproved
                latestFollowUp{followUpDateTime}
                previousFollowUp{lastFollowUp}
                account
                directClient
                loginStartDate
                loginEndDate
                lastDepositDate
                createdAt
                updatedAt
                companyNumber
                IncorporationDate
                BusinessNature
                PositionInCompany
                representativeAddress
                representativeCity
                representativePostalCode
                representativeState
                representativeCountry
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useUpdateUserSuspiciousMutation = (): { updateSuspicousStatus: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [updateSuspicousStatusMutation, {data, loading, error}] = useMutation(UPDATE_SUSPICIOUS_STATUS);

        const updateSuspicousStatus = (id, input) => {
            return updateSuspicousStatusMutation({
                variables: {
                    id: id,
                    input: input,
                },
                update(cache, {data: {updateSuspicousStatus}}) {
                    const typename = updateSuspicousStatus?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateSuspicousStatus?.errKey),
                            key: "updateSuspicousStatus",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateSuspicousStatus?.msg),
                            key: "updateSuspicousStatus",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update User Suspicious Status Successfully"),
                            key: "updateSuspicousStatus",
                        });
                    }
                },
                //refetchQueries: [GET_USER_BY_ID]
            });
        };

        return {updateSuspicousStatus, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {updateSuspicousStatus: null, data: null, loading: false, error: err};
    }
}

const UPDATE_SALES_REP = gql`
    mutation updateUserSalesRep($id: String, $input: SalesRepInput) {
        updateUserSalesRep(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on User {
                id
                _id
                userType
                email
                domain
                username
                firstName
                middleName
                lastName
                fullName
                age
                identificationNo
                dateOfBirth
                gender
                comment
                countryCode
                mobileNo
                completedMobileNo
                profileImage
                affiliateCode
                pspUserId
                language
                newsletter
                verifiedEmail
                address
                city
                postalCode
                state
                country
                status
                rank {_id name}
                desk {_id name}
                role {_id name}
                referrer {
                    _id
                    fullName
                    username
                }
                salesRep {
                    _id
                    fullName
                    username
                }
                eWallet {_id isDepositEnable isWithdrawalEnable balance}
                experiencePoint
                clientAccountType
                isSuspicious
                isLoginEnabled
                isTestAccount
                isKycApproved
                latestFollowUp{followUpDateTime}
                previousFollowUp{lastFollowUp}
                account
                directClient
                loginStartDate
                loginEndDate
                lastDepositDate
                createdAt
                updatedAt
                companyNumber
                IncorporationDate
                BusinessNature
                PositionInCompany
                representativeAddress
                representativeCity
                representativePostalCode
                representativeState
                representativeCountry
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useUpdateUserSalesRepMutation = (): { updateUserSalesRep: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [updateUserSalesRepMutation, {data, loading, error}] = useMutation(UPDATE_SALES_REP);

        const updateUserSalesRep = (id, input) => {
            return updateUserSalesRepMutation({
                variables: {
                    id: id,
                    input: input,
                },
                update(cache, {data: {updateUserSalesRep}}) {
                    const typename = updateUserSalesRep?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateUserSalesRep?.errKey),
                            key: "updateUserSalesRep",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateUserSalesRep?.msg),
                            key: "updateUserSalesRep",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update User Sales Rep Successfully"),
                            key: "updateUserSalesRep",
                        });
                    }
                },
                //refetchQueries: [GET_USER_BY_ID]
            });
        };

        return {updateUserSalesRep, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {updateUserSalesRep: null, data: null, loading: false, error: err};
    }
}

const UPDATE_FREEZE_STATUS = gql`
    mutation updateFreezeStatus($id: String!, $input: FreezeStatusInput) {
        updateFreezeStatus(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on User {
                id
                _id
                userType
                email
                domain
                username
                firstName
                middleName
                lastName
                fullName
                age
                identificationNo
                dateOfBirth
                gender
                comment
                countryCode
                mobileNo
                completedMobileNo
                profileImage
                affiliateCode
                pspUserId
                language
                newsletter
                verifiedEmail
                address
                city
                postalCode
                state
                country
                status
                rank {_id name}
                desk {_id name}
                role {_id name}
                referrer {
                    _id
                    fullName
                    username
                }
                salesRep {
                    _id
                    fullName
                    username
                }
                eWallet {_id isDepositEnable isWithdrawalEnable balance}
                experiencePoint
                clientAccountType
                isSuspicious
                isLoginEnabled
                isTestAccount
                isKycApproved
                latestFollowUp{followUpDateTime}
                previousFollowUp{lastFollowUp}
                account
                directClient
                loginStartDate
                loginEndDate
                lastDepositDate
                createdAt
                updatedAt
                companyNumber
                IncorporationDate
                BusinessNature
                PositionInCompany
                representativeAddress
                representativeCity
                representativePostalCode
                representativeState
                representativeCountry
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useUpdateFreezeStatusMutation = (): { updateFreezeStatus: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [updateFreezeStatusMutation, {data, loading, error}] = useMutation(UPDATE_FREEZE_STATUS);

        const updateFreezeStatus = (id, input) => {
            return updateFreezeStatusMutation({
                variables: {
                    id: id,
                    input: input,
                },
                update(cache, {data: {updateFreezeStatus}}) {
                    const typename = updateFreezeStatus?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateFreezeStatus?.errKey),
                            key: "updateFreezeStatus",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateFreezeStatus?.msg),
                            key: "updateFreezeStatus",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t(`${input.isFreezed ? "Freeze" : "Unfreeze"} E-Wallet Successfully`),
                            key: "updateFreezeStatus",
                        });
                    }
                },
                //refetchQueries: [GET_USER_BY_ID]
            });
        };

        return {updateFreezeStatus, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {updateFreezeStatus: null, data: null, loading: false, error: err};
    }
}

const UPDATE_USER_LOGIN_ENABLED = gql`
    mutation updateUserLoginEnabled($id: String!, $input: LoginEnabledInput) {
        updateUserLoginEnabled(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on User {
                id
                _id
                userType
                email
                domain
                username
                firstName
                middleName
                lastName
                fullName
                age
                identificationNo
                dateOfBirth
                gender
                comment
                countryCode
                mobileNo
                completedMobileNo
                profileImage
                affiliateCode
                pspUserId
                language
                newsletter
                verifiedEmail
                address
                city
                postalCode
                state
                country
                status
                rank {_id name}
                desk {_id name}
                role {_id name}
                referrer {
                    _id
                    fullName
                    username
                }
                salesRep {
                    _id
                    fullName
                    username
                }
                eWallet {_id isDepositEnable isWithdrawalEnable balance}
                experiencePoint
                clientAccountType
                isSuspicious
                isLoginEnabled
                isTestAccount
                isKycApproved
                latestFollowUp{followUpDateTime}
                previousFollowUp{lastFollowUp}
                account
                directClient
                loginStartDate
                loginEndDate
                lastDepositDate
                createdAt
                updatedAt
                companyNumber
                IncorporationDate
                BusinessNature
                PositionInCompany
                representativeAddress
                representativeCity
                representativePostalCode
                representativeState
                representativeCountry
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useUpdateUserLoginEnabledMutation = (): { updateUserLoginEnabled: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [updateUserLoginEnabledMutation, {data, loading, error}] = useMutation(UPDATE_USER_LOGIN_ENABLED);

        const updateUserLoginEnabled = (id, input) => {
            return updateUserLoginEnabledMutation({
                variables: {
                    id: id,
                    input: input,
                },
                update(cache, {data: {updateUserLoginEnabled}}) {
                    const typename = updateUserLoginEnabled?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateUserLoginEnabled?.errKey),
                            key: "updateUserLoginEnabled",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateUserLoginEnabled?.msg),
                            key: "updateUserLoginEnabled",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t(input?.isLoginEnabled === true ? "Update User Login Enabled Successfully" : "Update User Login Disabled Successfully"),
                            key: "updateUserLoginEnabled",
                        });
                    }
                }
            });
        };

        return {updateUserLoginEnabled, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {updateUserLoginEnabled: null, data: null, loading: false, error: err};
    }
}

const GO_LIVE = gql`
    mutation goLive($id: String!, $input: UserInput) {
        goLive(id: $id, input: $input){
            ... on User {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`

const useGoLiveMutation = (): { goLive: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [goLiveMutation, {data, loading, error}] = useMutation(GO_LIVE);

        const goLive = (id, input) => {
            return goLiveMutation({
                variables: {
                    id: id,
                    input: input,
                },
                update(cache, {data: {goLive}}) {
                    const typename = goLive?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(goLive?.errKey),
                            key: "goLive",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(goLive?.msg),
                            key: "goLive",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("User Go Live Successfully"),
                            key: "goLive",
                        });
                    }
                },
                //refetchQueries: [GET_USER_BY_ID]
            });
        };

        return {goLive, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {goLive: null, data: null, loading: false, error: err};
    }
}

const UPDATE_EWALLET_WITHDRAWAL_STATUS = gql`
    mutation updateEwalletWithdrawalStatus($id: String!, $input: EwalletWithdrawalEnableStatus) {
        updateEwalletWithdrawalStatus(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on User {
                id
                _id
                userType
                email
                domain
                username
                firstName
                middleName
                lastName
                fullName
                age
                identificationNo
                dateOfBirth
                gender
                comment
                countryCode
                mobileNo
                completedMobileNo
                profileImage
                affiliateCode
                pspUserId
                language
                newsletter
                verifiedEmail
                address
                city
                postalCode
                state
                country
                status
                rank {_id name}
                desk {_id name}
                role {_id name}
                referrer {
                    _id
                    fullName
                    username
                }
                salesRep {
                    _id
                    fullName
                    username
                }
                eWallet {_id isDepositEnable isWithdrawalEnable balance}
                experiencePoint
                clientAccountType
                isSuspicious
                isLoginEnabled
                isTestAccount
                isKycApproved
                latestFollowUp{followUpDateTime}
                previousFollowUp{lastFollowUp}
                account
                directClient
                loginStartDate
                loginEndDate
                lastDepositDate
                createdAt
                updatedAt
                companyNumber
                IncorporationDate
                BusinessNature
                PositionInCompany
                representativeAddress
                representativeCity
                representativePostalCode
                representativeState
                representativeCountry
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useUpdateEwalletWithdrawalStatusMutation = (): { updateEwalletWithdrawalStatus: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [updateEwalletWithdrawalStatusMutation, {
            data,
            loading,
            error
        }] = useMutation(UPDATE_EWALLET_WITHDRAWAL_STATUS);

        const updateEwalletWithdrawalStatus = (id, input) => {
            return updateEwalletWithdrawalStatusMutation({
                variables: {
                    id: id,
                    input: input,
                },
                update(cache, {data: {updateEwalletWithdrawalStatus}}) {
                    const typename = updateEwalletWithdrawalStatus?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateEwalletWithdrawalStatus?.errKey),
                            key: "updateEwalletWithdrawalStatus",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateEwalletWithdrawalStatus?.msg),
                            key: "updateEwalletWithdrawalStatus",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t(`${input?.isWithdrawalEnable ? "Enabled" : "Disabled"} User E-Wallet Withdrawal Successfully!`),
                            key: "updateEwalletWithdrawalStatus",
                        });
                    }
                },
                //refetchQueries: [GET_USER_BY_ID]
            });
        };

        return {updateEwalletWithdrawalStatus, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {updateEwalletWithdrawalStatus: null, data: null, loading: false, error: err};
    }
}

const UPDATE_EWALLET_DEPOSIT_STATUS = gql`
    mutation updateEwalletDepositStatus($id: String!, $input: EwalletDepositEnableStatus) {
        updateEwalletDepositStatus(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on User {
                id
                _id
                userType
                email
                domain
                username
                firstName
                middleName
                lastName
                fullName
                age
                identificationNo
                dateOfBirth
                gender
                comment
                countryCode
                mobileNo
                completedMobileNo
                profileImage
                affiliateCode
                pspUserId
                language
                newsletter
                verifiedEmail
                address
                city
                postalCode
                state
                country
                status
                rank {_id name}
                desk {_id name}
                role {_id name}
                referrer {
                    _id
                    fullName
                    username
                }
                salesRep {
                    _id
                    fullName
                    username
                }
                eWallet {_id isDepositEnable isWithdrawalEnable balance}
                experiencePoint
                clientAccountType
                isSuspicious
                isLoginEnabled
                isTestAccount
                isKycApproved
                latestFollowUp{followUpDateTime}
                previousFollowUp{lastFollowUp}
                account
                directClient
                loginStartDate
                loginEndDate
                lastDepositDate
                createdAt
                updatedAt
                companyNumber
                IncorporationDate
                BusinessNature
                PositionInCompany
                representativeAddress
                representativeCity
                representativePostalCode
                representativeState
                representativeCountry
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useUpdateEwalletDepositStatusMutation = (): { updateEwalletDepositStatus: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [updateEwalletDepositStatusMutation, {data, loading, error}] = useMutation(UPDATE_EWALLET_DEPOSIT_STATUS);

        const updateEwalletDepositStatus = (id, input) => {
            return updateEwalletDepositStatusMutation({
                variables: {
                    id: id,
                    input: input,
                },
                update(cache, {data: {updateEwalletDepositStatus}}) {
                    const typename = updateEwalletDepositStatus?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateEwalletDepositStatus?.errKey),
                            key: "updateEwalletDepositStatus",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateEwalletDepositStatus?.msg),
                            key: "updateEwalletDepositStatus",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t(`${input?.isDepositEnable ? "Enabled" : "Disabled"} User E-Wallet Deposit Successfully!`),
                            key: "updateEwalletDepositStatus",
                        });
                    }
                },
                //refetchQueries: [GET_USER_BY_ID]
            });
        };

        return {updateEwalletDepositStatus, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {updateEwalletDepositStatus: null, data: null, loading: false, error: err};
    }
}

const RECOVER_CLIENT_USER = gql`
    mutation recoverClientUser($id: String) {
        recoverClientUser(id: $id) {
            ... on CrmTaskApproval {
                msg
            }
            ... on User {
                id
                _id
                userType
                email
                domain
                username
                firstName
                middleName
                lastName
                fullName
                age
                identificationNo
                dateOfBirth
                gender
                comment
                countryCode
                mobileNo
                completedMobileNo
                profileImage
                affiliateCode
                pspUserId
                language
                newsletter
                verifiedEmail
                address
                city
                postalCode
                state
                country
                status
                rank {_id name}
                desk {_id name}
                role {_id name}
                referrer {
                    _id
                    fullName
                    username
                }
                salesRep {
                    _id
                    fullName
                    username
                }
                eWallet {_id isDepositEnable isWithdrawalEnable balance}
                experiencePoint
                clientAccountType
                isSuspicious
                isLoginEnabled
                isTestAccount
                isKycApproved
                latestFollowUp{followUpDateTime}
                previousFollowUp{lastFollowUp}
                account
                directClient
                loginStartDate
                loginEndDate
                lastDepositDate
                createdAt
                updatedAt
                companyNumber
                IncorporationDate
                BusinessNature
                PositionInCompany
                representativeAddress
                representativeCity
                representativePostalCode
                representativeState
                representativeCountry
                isDeletedByClient
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useRecoverClientUserMutation = (): { recoverClientUser: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [recoverClientUserMutation, {data, loading, error}] = useMutation(RECOVER_CLIENT_USER);

        const recoverClientUser = (id) => {
            return recoverClientUserMutation({
                variables: {
                    id: id,
                },
                update(cache, {data: {recoverClientUser}}) {
                    const typename = recoverClientUser?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(recoverClientUser?.errKey),
                            key: "recoverClientUser",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(recoverClientUser?.msg),
                            key: "recoverClientUser",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t(`Account Recovered successfully`),
                            key: "recoverClientUser",
                        });
                    }
                },
                //refetchQueries: [GET_USER_BY_ID]
            });
        };

        return {recoverClientUser, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {recoverClientUser: null, data: null, loading: false, error: err};
    }
}

const GET_USER_FILTER = gql`
    query getUserFilter(
        $filter: UserInput
        $limit: Int
        $offset: Int
        $orderBy: String
    ) {
        getUserFilter(
            filter: $filter
            limit: $limit
            offset: $offset
            orderBy: $orderBy
        ) {
            ... on UserAry {
                data {
                    _id
                    id
                    firstName
                    middleName
                    lastName
                    fullName
                    aliasFirstName
                    aliasMiddleName
                    aliasLastName
                    aliasFullName
                    username
                    email
                    gender
                    dateOfBirth
                    age
                    countryCode
                    mobileNo
                    completedMobileNo
                    domain
                    userType
                    identificationType
                    identificationNo
                    comment
                    language
                    newsletter
                    address
                    city
                    postalCode
                    state
                    country
                    occupationType
                    purposeOfTransaction
                    nameOfEmployer
                    natureOfBusiness
                    approximateNetWorth
                    countryOfTaxResidency
                    sourceOfWealth
                    affiliateCode
                    companyName
                    profileImage
                    eWallet {_id isDepositEnable isWithdrawalEnable balance}
                    experiencePoint
                    pspUserId
                    status
                    clientAccountType
                    rank { _id name }
                    desk { _id name }
                    referrer { _id fullName username }
                    salesRep { _id fullName username }
                    role { _id name isDefaultCrmUserPermission }
                    lastDepositDate
                    isLoginEnabled
                    isTestAccount
                    isKycApproved
                    isDeleted
                    deletedReason
                    legalName
                    dateOfIncorporation
                    dbaAliasTradingName
                    incorporationAddress
                    incorporationCity
                    incorporationPostalCode
                    incorporationCountry
                    registeredAddress
                    registeredCity
                    registeredPostalCode
                    registeredCountry
                    businessAddress
                    businessCity
                    businessPostalCode
                    businessCountry
                    representativeType
                    formerName
                    formerDbaAliasTradingName
                    registrationNumber
                    corporateEmail
                    corporateCountryCode
                    corporateMobileNo
                    legalForm
                    isSuspicious
                    verifiedEmail
                    previousFollowUp { followUpDateTime }
                    latestFollowUp { followUpDateTime lastFollowUp }
                    emailVerificationCode
                    loginStartDate
                    loginEndDate
                    nationality
                    isActive
                    signature
                    theme
                    createdAt
                    updatedAt
                    companyNumber
                    IncorporationDate
                    BusinessNature
                    PositionInCompany
                    representativeAddress
                    representativeCity
                    representativePostalCode
                    representativeState
                    representativeCountry
                    isTemporaryPasswordEnabled
                    directClient
                    account
                    isDeletedByClient
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getUserFilter = ({filter}, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data} = useQuery(GET_USER_FILTER, {
            variables: {
                filter: filter,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UPDATE_USER_COMMISSION = gql`
    mutation updateUserCommission($id: String, $input: TradingAccountCommRuleInput) {
        updateUserCommission(id: $id, input: $input) {
            ... on User {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useUpdateUserCommissionMutation = () => {
    const [updateUserCommissionMutation, {
        data,
        loading,
        error
    }] = useMutation(UPDATE_USER_COMMISSION);

    const updateUserCommission = (id, input) => {
        return updateUserCommissionMutation({
            variables: {
                id: id,
                input: input,
            },
            //refetchQueries: [GET_USER_BY_ID]
        });
    };

    return {updateUserCommission, data, loading, error};
};

const RESET_USER_COMMISSION = gql`
    mutation resetUserCommission($id: String!, $commissionSetting: String) {
        resetUserCommission(id: $id, commissionSetting: $commissionSetting) {
            ... on User {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useResetUserCommissionMutation = () => {
    const [resetUserCommissionMutation, {
        data,
        loading,
        error
    }] = useMutation(RESET_USER_COMMISSION);

    const resetUserCommission = (id, commissionSetting) => {
        return resetUserCommissionMutation({
            variables: {
                id: id,
                commissionSetting: commissionSetting,
            },
            //refetchQueries: [GET_USER_BY_ID]
        });
    };

    return {resetUserCommission, data, loading, error};
};

const RESTORE_ARCHIVE_USER = gql`
    mutation restoreArchiveUser($id: String!, $referrer: String!, $salesRep: String!) {
        restoreArchiveUser(id: $id, referrer: $referrer, salesRep: $salesRep) {
            ... on CrmTaskApproval {
                msg
            }
            ... on User {
                id
                _id
                userType
                email
                domain
                username
                firstName
                middleName
                lastName
                fullName
                age
                identificationNo
                dateOfBirth
                gender
                comment
                countryCode
                mobileNo
                completedMobileNo
                profileImage
                affiliateCode
                pspUserId
                language
                newsletter
                verifiedEmail
                address
                city
                postalCode
                state
                country
                status
                rank {_id name}
                desk {_id name}
                role {_id name}
                referrer {
                    _id
                    fullName
                    username
                }
                salesRep {
                    _id
                    fullName
                    username
                }
                eWallet {_id isDepositEnable isWithdrawalEnable balance}
                experiencePoint
                clientAccountType
                isSuspicious
                isLoginEnabled
                isTestAccount
                isKycApproved
                latestFollowUp{followUpDateTime}
                previousFollowUp{lastFollowUp}
                account
                directClient
                loginStartDate
                loginEndDate
                lastDepositDate
                createdAt
                updatedAt
                companyNumber
                IncorporationDate
                BusinessNature
                PositionInCompany
                representativeAddress
                representativeCity
                representativePostalCode
                representativeState
                representativeCountry
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

const restoreArchiveUserMutation = (): { restoreArchiveUser: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [restoreArchiveUserMutation, {data, loading, error}] = useMutation(RESTORE_ARCHIVE_USER);

        const restoreArchiveUser = (id, referrer, salesRep) => {
            return restoreArchiveUserMutation({
                variables: {
                    id: id,
                    referrer: referrer,
                    salesRep: salesRep,
                },
                update(cache, {data: {restoreArchiveUser}}) {
                    const typename = restoreArchiveUser?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(restoreArchiveUser?.errKey),
                            key: "restoreArchiveUser",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(restoreArchiveUser?.msg),
                            key: "restoreArchiveUser",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Restore Archive User Successfully"),
                            key: "restoreArchiveUser",
                        });
                    }
                },
                //refetchQueries: [GET_USER_BY_ID]
            });
        };

        return {restoreArchiveUser, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {restoreArchiveUser: null, data: null, loading: false, error: err};
    }
}

const SHIFT_REFERRER_FOR_DOWNLINE_AND_DELETE = gql`
    mutation shiftReferrerForDownlineAndDelete($id: String!, $shiftDownlineToReferrer: String!) {
        shiftReferrerForDownlineAndDelete(id: $id, shiftDownlineToReferrer: $shiftDownlineToReferrer) {
            ... on CrmTaskApproval {
                msg
            }
            ... on User {
                id
                _id
                userType
                email
                domain
                username
                firstName
                middleName
                lastName
                fullName
                age
                identificationNo
                dateOfBirth
                gender
                comment
                countryCode
                mobileNo
                completedMobileNo
                profileImage
                affiliateCode
                pspUserId
                language
                newsletter
                verifiedEmail
                address
                city
                postalCode
                state
                country
                status
                rank {_id name}
                desk {_id name}
                role {_id name}
                referrer {
                    _id
                    fullName
                    username
                }
                salesRep {
                    _id
                    fullName
                    username
                }
                eWallet {_id isDepositEnable isWithdrawalEnable balance}
                experiencePoint
                clientAccountType
                isSuspicious
                isLoginEnabled
                isTestAccount
                isKycApproved
                latestFollowUp{followUpDateTime}
                previousFollowUp{lastFollowUp}
                account
                directClient
                loginStartDate
                loginEndDate
                lastDepositDate
                createdAt
                updatedAt
                companyNumber
                IncorporationDate
                BusinessNature
                PositionInCompany
                representativeAddress
                representativeCity
                representativePostalCode
                representativeState
                representativeCountry
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;

const useShiftReferrerForDownlineAndDeleteMutation = (): { shiftReferrerForDownlineAndDelete: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [shiftReferrerForDownlineAndDeleteMutation, {
            data,
            loading,
            error
        }] = useMutation(SHIFT_REFERRER_FOR_DOWNLINE_AND_DELETE);

        const shiftReferrerForDownlineAndDelete = (id, shiftDownlineToReferrer) => {
            return shiftReferrerForDownlineAndDeleteMutation({
                variables: {
                    id: id,
                    shiftDownlineToReferrer: shiftDownlineToReferrer,
                },
                update(cache, {data: {shiftReferrerForDownlineAndDelete}}) {
                    const typename = shiftReferrerForDownlineAndDelete?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(shiftReferrerForDownlineAndDelete?.errKey),
                            key: "shiftReferrerForDownlineAndDelete",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(shiftReferrerForDownlineAndDelete?.msg),
                            key: "shiftReferrerForDownlineAndDelete",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Shift Referrer for Downline Successfully"),
                            key: "shiftReferrerForDownlineAndDelete",
                        });
                    }
                },
                //refetchQueries: [GET_USER_BY_ID]
            });
        };

        return {shiftReferrerForDownlineAndDelete, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {shiftReferrerForDownlineAndDelete: null, data: null, loading: false, error: err};
    }
}


export {
    getUserCount,
    getUserCountAsync,
    getArchiveUserCount,
    getArchiveUserCountAsync,
    getUserById,
    getUser,
    getUserAsync,
    getUserSubset,
    getArchiveUser,
    getArchiveUserAsync,
    useAddUserMutation,
    useUpdateUserMutation,
    useUpdateGeneralUserMutation,
    useDeleteUserMutation,
    checkIsMasterAdmin,
    useBatchUpdate,
    useBatchUpdateReferrer,
    useBatchCreateFollowUp,
    useUpdateUserRoleMutation,
    useUpdateUserReferrerMutation,
    useUpdateUserRankMutation,
    useUpdateUserDeskMutation,
    useUpdateUserSuspiciousMutation,
    useUpdateUserSalesRepMutation,
    useUpdateFreezeStatusMutation,
    useUpdateUserLoginEnabledMutation,
    useGoLiveMutation,
    useUpdateEwalletWithdrawalStatusMutation,
    useUpdateEwalletDepositStatusMutation,
    useRecoverClientUserMutation,
    GET_USER_BY_ID,
    getUserFilter,
    useUpdateUserCommissionMutation,
    useResetUserCommissionMutation,
    useShiftReferrerForDownlineAndDeleteMutation,
    restoreArchiveUserMutation,
    useUpdateUserDomainMutation,
    getUserByIdAsync
};
