import React from 'react'

const Page404 = (props) => {
    return(
        <div>
            Page not found
        </div>
    )
}

export default Page404