import {gql, useMutation, useQuery} from "@apollo/client";
import {useSetRecoilState} from "recoil";
import {useTranslation} from "react-i18next";
import {notificationMessage} from "../../../recoil_state";
import {GET_PSP_SETTING_DETAIL} from "./pspSettingDetail";
import {ApolloClientQuery} from "./_apolloClient";

const GET_PSP_SETTING_COUNT = gql`
    query getPspSettingCount($filter: PspSettingSearchInput) {
        getPspSettingCount(filter: $filter)
    }
`;

const getPspSettingCount = ({filter}) => {
    try {
        const {loading, error, data} = useQuery(GET_PSP_SETTING_COUNT, {
            variables: {
                filter: {
                    displayName: filter?.displayName || null,
                    enabled: filter?.enabled || null,
                    depositEnabled: filter?.depositEnabled || null,
                    withdrawalEnabled: filter?.withdrawalEnabled || null,
                },
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_PSP_SETTING_BY_ID = gql`
    query getPspSettingByID($id: String!) {
        getPspSettingByID(id: $id) {
            ... on PspSetting {
                _id
                id
                position
                name
                displayName
                processingTime
                description
                enabled
                depositEnabled
                withdrawalEnabled
                isMerchantSetup
                showBankDetails
                depositDescription {
                    language
                    content
                }
                withdrawalDescription {
                    language
                    content
                }
                logo_light {
                    link
                    language
                    fileList
                }
                logo_dark {
                    link
                    language
                    fileList
                }
                logoSmall_light {
                    link
                    language
                    fileList
                }
                logoSmall_dark {
                    link
                    language
                    fileList
                }
                defaultLogo_light {
                    fileList
                    language
                    link
                }
                defaultLogo_dark {
                    fileList
                    language
                    link
                }
                defaultLogoSmall_light {
                    fileList
                    language
                    link
                }
                defaultLogoSmall_dark {
                    fileList
                    language
                    link
                }
                minDeposit
                minWithdrawal
                maxDeposit
                maxWithdrawal
                bankDetails {
                    beneficiaryBank
                    bankAddress
                    beneficiaryAccountName
                    beneficiaryAccountNumber
                    beneficiarySwiftCode
                    bankRoutingNumber
                    paymentDetails
                }
                localDepoBankDetails {
                    bankName
                    bankAddress
                    swiftCode
                    beneficiaryAccountName
                    accountNumber
                    beneficiaryAddress
                }
                isDeleted
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getPspSettingById = (id) => {
    try {
        const {loading, error, data} = useQuery(GET_PSP_SETTING_BY_ID, {
            variables: {
                id: id,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_PSP_SETTING = gql`
    query getPspSetting($filter: PspSettingSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
        getPspSetting(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on PspSettingArray {
                data {
                    _id
                    id
                    name
                    displayName
                    processingTime
                    description
                    enabled
                    depositEnabled
                    withdrawalEnabled
                    isMerchantSetup
                    showBankDetails
                    depositDescription {
                        language
                        content
                    }
                    withdrawalDescription {
                        language
                        content
                    }
                    logo_light {
                        link
                        language
                        fileList
                    }
                    logo_dark {
                        link
                        language
                        fileList
                    }
                    logoSmall_light {
                        link
                        language
                        fileList
                    }
                    logoSmall_dark {
                        link
                        language
                        fileList
                    }
                    minDeposit
                    minWithdrawal
                    maxDeposit
                    maxWithdrawal
                    bankDetails {
                        beneficiaryBank
                        bankAddress
                        beneficiaryAccountName
                        beneficiaryAccountNumber
                        beneficiarySwiftCode
                        bankRoutingNumber
                        paymentDetails
                    }
                    localDepoBankDetails {
                        bankName
                        bankAddress
                        swiftCode
                        beneficiaryAccountName
                        accountNumber
                        beneficiaryAddress
                    }
                    position
                    isDeleted
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getPspSetting = ({filter}, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data} = useQuery(GET_PSP_SETTING, {
            variables: {
                filter: {
                    name: filter?.name || null,
                    displayName: filter?.displayName || null,
                    enabled: filter?.enabled || null,
                    depositEnabled: filter?.depositEnabled || null,
                    withdrawalEnabled: filter?.withdrawalEnabled || null,
                    isMerchantSetup: filter?.isMerchantSetup || null,
                    taskView: filter?.taskView || null,
                },
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_PSP_SETTING_FORM = gql`
    mutation addPspSettingForm($input: PspSettingFormInput) {
        addPspSettingForm(input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on PspSettingForm {
                pspSetting {
                    _id
                    id
                    position
                    name
                    displayName
                    processingTime
                    description
                    enabled
                    depositEnabled
                    withdrawalEnabled
                    isMerchantSetup
                    showBankDetails
                    depositDescription {
                        language
                        content
                    }
                    withdrawalDescription {
                        language
                        content
                    }
                    logo_light {
                        link
                        language
                        fileList
                    }
                    logo_dark {
                        link
                        language
                        fileList
                    }
                    logoSmall_light {
                        link
                        language
                        fileList
                    }
                    logoSmall_dark {
                        link
                        language
                        fileList
                    }
                    defaultLogo_light {
                        fileList
                        language
                        link
                    }
                    defaultLogo_dark {
                        fileList
                        language
                        link
                    }
                    defaultLogoSmall_light {
                        fileList
                        language
                        link
                    }
                    defaultLogoSmall_dark {
                        fileList
                        language
                        link
                    }
                    minDeposit
                    minWithdrawal
                    maxDeposit
                    maxWithdrawal
                    bankDetails {
                        beneficiaryBank
                        bankAddress
                        beneficiaryAccountName
                        beneficiaryAccountNumber
                        beneficiarySwiftCode
                        bankRoutingNumber
                        paymentDetails
                    }
                    localDepoBankDetails {
                        bankName
                        bankAddress
                        swiftCode
                        beneficiaryAccountName
                        accountNumber
                        beneficiaryAddress
                    }
                    isDeleted
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
                pspSettingCurrency {
                    _id
                    id
                    currency
                    conversionRate
                    digits
                    fixedRate
                    depositEnabled
                    withdrawalEnabled
                    settlement
                    settlementFixed
                    availableBank {
                        bankCode
                        bankName
                    }
                }
                pspSettingDetail {
                    _id
                    id
                    displayName
                    depositEnabled
                    withdrawalEnabled
                    paymentMethod
                    minDeposit
                    minWithdrawal
                    maxDeposit
                    maxWithdrawal
                    feeType
                    feeRounding
                    feeRoundingType
                    formType
                    depositFee
                    depositFeeFixed
                    depositFeeCondition
                    depositFeeClient
                    depositFeeFixedClient
                    withdrawalFee
                    withdrawalFeeFixed
                    withdrawalFeeCondition
                    withdrawalFeeClient
                    withdrawalFeeFixedClient
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useAddPspSettingFormMutation = (): {
    addPspSettingForm: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const {t} = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [addPspSettingFormMutation, {data, loading, error}] = useMutation(ADD_PSP_SETTING_FORM);

        const addPspSettingForm = (input) => {
            return addPspSettingFormMutation({
                variables: {
                    input: {
                        pspSetting: input?.pspSetting || null,
                        pspSettingCurrency: input?.pspSettingCurrency || null,
                        pspSettingDetail: input?.pspSettingDetail || null,
                    },
                },
                update(cache, {data: {addPspSettingForm}}) {
                    const typename = addPspSettingForm?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(addPspSettingForm?.errKey),
                            key: "addPspSettingForm",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(addPspSettingForm?.msg),
                            key: "addPspSettingForm",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Successfully"),
                            key: "addPspSettingForm",
                        });
                    }
                },
            });
        };

        let typename = data?.addPspSettingForm?.__typename;
        if (typename === "CrmTaskApproval" || typename === "BaseError") {
            // Notification
        }

        return {addPspSettingForm, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {addPspSettingForm: null, data: null, loading: false, error: err};
    }
};

const UPDATE_PSP_SETTING = gql`
    mutation updatePspSetting($id: String!, $input: PspSettingInput) {
        updatePspSetting(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on PspSetting {
                _id
                id
                position
                name
                displayName
                processingTime
                description
                enabled
                depositEnabled
                withdrawalEnabled
                isMerchantSetup
                showBankDetails
                depositDescription {
                    language
                    content
                }
                withdrawalDescription {
                    language
                    content
                }
                logo_light {
                    link
                    language
                    fileList
                }
                logo_dark {
                    link
                    language
                    fileList
                }
                logoSmall_light {
                    link
                    language
                    fileList
                }
                logoSmall_dark {
                    link
                    language
                    fileList
                }
                defaultLogo_light {
                    fileList
                    language
                    link
                }
                defaultLogo_dark {
                    fileList
                    language
                    link
                }
                defaultLogoSmall_light {
                    fileList
                    language
                    link
                }
                defaultLogoSmall_dark {
                    fileList
                    language
                    link
                }
                minDeposit
                minWithdrawal
                maxDeposit
                maxWithdrawal
                bankDetails {
                    beneficiaryBank
                    bankAddress
                    beneficiaryAccountName
                    beneficiaryAccountNumber
                    beneficiarySwiftCode
                    bankRoutingNumber
                    paymentDetails
                }
                localDepoBankDetails {
                    bankName
                    bankAddress
                    swiftCode
                    beneficiaryAccountName
                    accountNumber
                    beneficiaryAddress
                }
                isDeleted
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useUpdatePSPSettingMutation = (): {
    updatePspSetting: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const {t} = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updatePspSettingMutation, {data, loading, error}] = useMutation(UPDATE_PSP_SETTING);

        const updatePspSetting = (id, input) => {
            return updatePspSettingMutation({
                variables: {
                    id: id,
                    input: {
                        name: input.name || null,
                        displayName: input.displayName || null,
                        processingTime: input.processingTime || null,
                        description: input.description || null,
                        enabled: input.enabled || null,
                        depositEnabled: input.depositEnabled || null,
                        withdrawalEnabled: input.withdrawalEnabled || null,
                        isMerchantSetup: input.isMerchantSetup || null,
                        logo_light: input.logo_light || null,
                        logo_dark: input.logo_dark || null,
                        logoSmall_light: input.logoSmall_light || null,
                        logoSmall_dark: input.logoSmall_dark || null,
                        minDeposit: input.minDeposit || null,
                        minWithdrawal: input.minWithdrawal || null,
                        maxDeposit: input.maxDeposit || null,
                        maxWithdrawal: input.maxWithdrawal || null,
                        bankDetails: input.bankDetails || null,
                        localDepoBankDetails: input.localDepoBankDetails || null,
                        position: input.position || null,
                    },
                },
                update(cache, {data: {updatePspSetting}}) {
                    const typename = updatePspSetting?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updatePspSetting?.errKey),
                            key: "updatePspSetting",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updatePspSetting?.msg),
                            key: "updatePspSetting",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Successfully"),
                            key: "updatePspSetting",
                        });
                    }
                },
                refetchQueries: [GET_PSP_SETTING],
            });
        };

        let typename = data?.updatePspSetting?.__typename;
        if (typename === "CrmTaskApproval" || typename === "BaseError") {
            // Notification
        }

        return {updatePspSetting, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {updatePspSetting: null, data: null, loading: false, error: err};
    }
};

const UPDATE_PSP_SETTING_FORM = gql`
    mutation updatePspSettingForm($input: PspSettingFormInput) {
        updatePspSettingForm(input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on PspSettingForm {
                pspSetting {
                    _id
                    id
                    name
                    displayName
                    processingTime
                    description
                    enabled
                    depositEnabled
                    withdrawalEnabled
                    isMerchantSetup
                    showBankDetails
                    depositDescription {
                        language
                        content
                    }
                    withdrawalDescription {
                        language
                        content
                    }
                    logo_light {
                        link
                        language
                        fileList
                    }
                    logo_dark {
                        link
                        language
                        fileList
                    }
                    logoSmall_light {
                        link
                        language
                        fileList
                    }
                    logoSmall_dark {
                        link
                        language
                        fileList
                    }
                    defaultLogo_light {
                        fileList
                        language
                        link
                    }
                    defaultLogo_dark {
                        fileList
                        language
                        link
                    }
                    defaultLogoSmall_light {
                        fileList
                        language
                        link
                    }
                    defaultLogoSmall_dark {
                        fileList
                        language
                        link
                    }
                    minDeposit
                    minWithdrawal
                    maxDeposit
                    maxWithdrawal
                    bankDetails {
                        beneficiaryBank
                        bankAddress
                        beneficiaryAccountName
                        beneficiaryAccountNumber
                        beneficiarySwiftCode
                        bankRoutingNumber
                        paymentDetails
                    }
                    localDepoBankDetails {
                        bankName
                        bankAddress
                        swiftCode
                        beneficiaryAccountName
                        accountNumber
                        beneficiaryAddress
                    }
                    isDeleted
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
                pspSettingCurrency {
                    _id
                    id
                    currency
                    conversionRate
                    digits
                    fixedRate
                    depositEnabled
                    withdrawalEnabled
                    settlement
                    settlementFixed
                    availableBank {
                        bankCode
                        bankName
                    }
                }
                pspSettingDetail {
                    _id
                    id
                    displayName
                    depositEnabled
                    withdrawalEnabled
                    paymentMethod
                    minDeposit
                    minWithdrawal
                    maxDeposit
                    maxWithdrawal
                    feeType
                    feeRounding
                    feeRoundingType
                    formType
                    depositFee
                    depositFeeFixed
                    depositFeeCondition
                    depositFeeClient
                    depositFeeFixedClient
                    withdrawalFee
                    withdrawalFeeFixed
                    withdrawalFeeCondition
                    withdrawalFeeClient
                    withdrawalFeeFixedClient
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useUpdatePspSettingFormMutation = (): {
    updatePspSettingForm: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const {t} = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updatePspSettingFormMutation, {data, loading, error}] = useMutation(UPDATE_PSP_SETTING_FORM);

        const updatePspSettingForm = (input) => {
            return updatePspSettingFormMutation({
                variables: {
                    input: {
                        pspSetting: input.pspSetting || null,
                        pspSettingCurrency: input.pspSettingCurrency || null,
                        pspSettingDetail: input.pspSettingDetail || null,
                    },
                },
                update(cache, {data: {updatePspSettingForm}}) {
                    const typename = updatePspSettingForm?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updatePspSettingForm?.errKey),
                            key: "updatePspSettingForm",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updatePspSettingForm?.msg),
                            key: "updatePspSettingForm",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Successfully"),
                            key: "updatePspSettingForm",
                        });
                    }
                },
            });
        };

        return {updatePspSettingForm, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            updatePspSettingForm: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const SET_PSP_MERCHANT = gql`
    mutation setPspMerchant($input: JSON) {
        setPspMerchant(input: $input)
    }
`;

const useSetPspMerchantMutation = (): {
    setPspMerchant: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const {t} = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [setPspMerchantMutation, {data, loading, error}] = useMutation(SET_PSP_MERCHANT);

        const setPspMerchant = (input) => {
            return setPspMerchantMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {setPspMerchant}}) {
                    const typename = setPspMerchant?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(setPspMerchant?.errKey),
                            key: "setPspMerchant",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(setPspMerchant?.msg),
                            key: "setPspMerchant",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Successfully"),
                            key: "setPspMerchant",
                        });
                    }
                },
            });
        };

        return {setPspMerchant, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            setPspMerchant: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const SET_ALL_PSP_SETTING = gql`
    mutation setAllPspSetting {
        setAllPspSetting {
            ... on PspSettingSetup {
                status
            }
        }
    }
`;

const useSetAllPspSettingMutation = (): {
    setAllPspSetting: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const {t} = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [setAllPspSettingMutation, {data, loading, error}] = useMutation(SET_ALL_PSP_SETTING);

        const setAllPspSetting = (input) => {
            return setAllPspSettingMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {setAllPspSetting}}) {
                    const typename = setAllPspSetting?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(setAllPspSetting?.errKey),
                            key: "setAllPspSetting",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(setAllPspSetting?.msg),
                            key: "setAllPspSetting",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Refresh Successfully"),
                            key: "setAllPspSetting",
                        });
                    }
                },
                refetchQueries: [GET_PSP_SETTING, GET_PSP_SETTING_DETAIL],
            });
        };

        return {setAllPspSetting, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            setAllPspSetting: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const GET_CURRENCY_RATE = gql`
    query getCurrencyRate {
        getCurrencyRate {
            data {
                currency
                rate
                isDeleted
            }
        }
    }
`;

async function getCurrencyRate() {
    const variables = {
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_CURRENCY_RATE, variables, false);

    return execute;
}

const UPDATE_PSP_SETTING_POSITION = gql`
    mutation updatePspSettingPosition($fromPsp: String!, $toPsp: String!) {
        updatePspSettingPosition(fromPsp: $fromPsp, toPsp: $toPsp) {
            ... on CrmTaskApproval {
                msg
            }
            ... on PspSetting {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;

const useUpdatePSPSettingPositionMutation = (): {
    updatePspSettingPosition: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const {t} = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updatePspSettingPositionMutation, {data, loading, error}] = useMutation(UPDATE_PSP_SETTING_POSITION);

        const updatePspSettingPosition = (fromPsp, toPsp) => {
            return updatePspSettingPositionMutation({
                variables: {fromPsp, toPsp},
                update(cache, {data: {updatePspSettingPosition}}) {
                    const typename = updatePspSettingPosition?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updatePspSettingPosition?.errKey),
                            key: "updatePspSettingPosition",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updatePspSettingPosition?.msg),
                            key: "updatePspSettingPosition",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Position Successfully"),
                            key: "updatePspSettingPosition",
                        });
                    }
                },
                refetchQueries: [GET_PSP_SETTING],
            });
        };

        let typename = data?.updatePspSetting?.__typename;
        if (typename === "CrmTaskApproval" || typename === "BaseError") {
            // Notification
        }

        return {updatePspSettingPosition, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {updatePspSettingPosition: null, data: null, loading: false, error: err};
    }
};

export {
    getPspSettingCount,
    getPspSettingById,
    getPspSetting,
    useAddPspSettingFormMutation,
    useUpdatePSPSettingMutation,
    useUpdatePspSettingFormMutation,
    useSetPspMerchantMutation,
    useSetAllPspSettingMutation,
    getCurrencyRate,
    useUpdatePSPSettingPositionMutation
};
