import React from "react";
import {Form, Input, Modal} from "antd";
import {useTranslation} from "react-i18next";
import {labelCol, validateMessages} from "../../../../../function";

const GeneralListDocumentForm = (props) => {
    const {isAdd, loading, isView, isOpen, setIsOpen, onSubmit, form, item} = props
    const {t} = useTranslation()

    return (
        <Modal
            title={t(isAdd ? "accountVerification.addDocument" : "accountVerification.updateDocument")}
            open={isOpen}
            onCancel={() => setIsOpen(false)}
            onOk={form.submit}
            cancelText={t("common.cancel")}
            okText={isAdd ? t("common.add") : t("common.update")}
            okButtonProps={{loading: loading}}
            destroyOnClose={true}
            footer={isView ? null : undefined}
        >
            <Form form={form} onFinish={onSubmit} validateMessages={validateMessages}>
                <Form.Item
                    labelCol={labelCol}
                    label={t("accountVerification.documentName")}
                    name={"name"}
                    rules={[{required: true}]}
                    initialValue={item?.name}
                >
                    <Input placeholder={t("accountVerification.placeholder.documentName").toString()} disabled={isView}/>
                </Form.Item>
                {/*<Form.Item*/}
                {/*    label={t("accountVerification.isEnabled")}*/}
                {/*    name={"isEnabled"}*/}
                {/*    colon={false}*/}
                {/*    style={{textAlign: "right"}}*/}
                {/*    initialValue={item?.isEnabled}*/}
                {/*    className={"margin-top-0-75"}*/}
                {/*>*/}
                {/*    <Switch defaultChecked={item?.isEnabled}/>*/}
                {/*</Form.Item>*/}
            </Form>
        </Modal>
    )
}

export default GeneralListDocumentForm