import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import {Popconfirm, Space} from "antd";
import {useSetRecoilState} from "recoil";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import {columnsCampaignTag} from "../../../function/tableColumn_campaignTag";
import useAuthorize from "../../../../../../_common/authorize";
import {deleteLeadTag, getLeadTag} from "../../../../../../api/graphql/leadTag";
import {notificationMessage} from "../../../../../../../recoil_state";
import LeadTagModal from "./leadTagModal";

function LeadTagTable_1(props) {
    const {permission, languageData, currentTab, isAddModalOpen, setIsAddModalOpen} = props;
    const {t} = useTranslation();
    const {systemErrorNotification} = useAuthorize();
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const [mode, setMode] = useState("");
    const [selectedItem, setSelectedItem] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRequesting, setIsRequesting] = useState(false);

    const [exportData, setExportData] = useState<any>(null);
    const [exportColumn, setExportColumn] = useState<any>(null);

    const [tableData, setTableData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [orderBy, setOrderBy] = useState("");
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [filter, setFilter] = useState<any>({});

    const getTableData = async () => {
        try {
            const response = await getLeadTag({filter}, limit, offset, orderBy);
            setTableData(response?.getLeadTag?.data);
            setTotalCount(response?.getLeadTag?.total);
        } catch (error) {}
    };

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize);
        setOffset(limit * (page - 1));
    };

    const openAddModal = () => {
        setMode("Add");
        setIsModalOpen(true);
    };

    const openEditModal = (record) => {
        setMode("Edit");
        setIsModalOpen(true);
        setSelectedItem(record);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setIsAddModalOpen(false);
        setSelectedItem({});
    };

    const handleDelete = async (id) => {
        try {
            const res = await deleteLeadTag(id);
            const response = res?.deleteLeadTag;
            if (response?.__typename === "LeadTag") {
                await getTableData();
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("common.deleteSuccess"),
                    key: "deleteLeadTag",
                });
            } else if (response?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.errKey),
                    key: "deleteLeadTag",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.msg),
                    key: "deleteLeadTag",
                });
            }
        } catch (error) {
            systemErrorNotification();
        } finally {
            closeModal();
        }
    };

    useEffect(() => {
        getTableData();
    }, [filter, orderBy, limit, offset, isModalOpen, currentTab]);

    useEffect(() => {
        if (currentTab === "Lead Tag" && isAddModalOpen) {
            openAddModal();
        }
    }, [isAddModalOpen, currentTab]);

    return (
        <div className={"margin-top-0-75"}>
            {isModalOpen && <LeadTagModal mode={mode} item={selectedItem} isOpen={isModalOpen} closeModal={closeModal} languageData={languageData} />}
            <TableAntDesign
                data={tableData}
                tableName={currentTab}
                columns={[
                    ...columnsCampaignTag(),
                    {
                        title: t("common.action"),
                        key: "action",
                        width: 100,
                        align: "center",
                        render: (_, record: any) => (
                            <Space>
                                {permission?.edit?.edit && (
                                    <a style={{color: "green"}} onClick={() => openEditModal(record)}>
                                        <AiOutlineEdit />
                                    </a>
                                )}
                                {permission?.delete?.delete && (
                                    <Popconfirm
                                        placement="left"
                                        description={t("Are you sure to delete this leadTag?")}
                                        title={t(`Delete the leadTag`)}
                                        onConfirm={() => {
                                            handleDelete(record?.id || record?._id);
                                        }}
                                        okText={t("Yes")}
                                        okType="danger"
                                        cancelText={t("No")}
                                    >
                                        <a>
                                            <AiOutlineDelete style={{color: "red"}} />
                                        </a>
                                    </Popconfirm>
                                )}
                            </Space>
                        ),
                    },
                ]}
                size={"small"}
                loading={isRequesting}
                filter={setFilter}
                actionPermission={permission?.edit?.edit || permission?.delete?.delete}
                order={setOrderBy}
                pagination={{
                    pageSize: limit,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                    total: totalCount,
                    showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
                }}
            />
        </div>
    );
}

export default LeadTagTable_1;
