import { gql, useMutation, useQuery } from '@apollo/client'

const GET_LEVERAGE_PROFILE_COUNT = gql`
  query getLeverageProfileCount($filter: LeverageProfileInput) {
    getLeverageProfileCount(filter: $filter)
  }
`;

const getLeverageProfileCount = ({filter}) => {
  try{
    const { loading, error, data } = useQuery(GET_LEVERAGE_PROFILE_COUNT, {
        variables: {
            filter: {
              leverageId: filter?.leverageId || null,
              name: filter?.name || null,
              serverName: filter?.serverName || null,
            }
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}

const GET_LEVERAGE_PROFILE_BY_ID = gql`
  query getLeverageProfileByID($id: String!) {
    getLeverageProfileByID(id: $id){
      ... on LeverageProfile {
        _id
        id
        leverageId
        name
        description
        tiers {volume, hasVolume, leverage, hasLeverage}
        utcLastUpdateTimestamp
        server {name}
        createdBy {username}
        updatedBy {username}
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getLeverageProfileById = (id) => {
  try{
    const { loading, error, data } = useQuery(GET_LEVERAGE_PROFILE_BY_ID, {
        variables: {
          id: id
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}


const GET_LEVERAGE_PROFILE = gql`
  query getLeverageProfile($filter: LeverageProfileInput, $limit: Int, $offset: Int, $orderBy: String) {
    getLeverageProfile(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy){
      ... on LeverageProfileArray {
        data {
          _id
          id
          leverageId
          name
          description
          tiers {volume, hasVolume, leverage, hasLeverage}
          utcLastUpdateTimestamp
          server {name}
          createdBy {username}
          updatedBy {username}
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getLeverageProfile = ({filter}, limit?, offset?, orderBy?) => {
  try{
    const { loading, error, data } = useQuery(GET_LEVERAGE_PROFILE, {
        variables: {
          filter: {
            leverageId: filter?.leverageId || null,
            name: filter?.name || null,
            serverName: filter?.serverName || null,
          },
          limit: limit || null,
          offset: offset || null,
          orderBy: orderBy || null,
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}

const REFRESH_LEVERAGE_PROFILE = gql`
  mutation refreshLeverageProfile($server: String!) {
    refreshLeverageProfile(server: $server){
      ... on LeverageProfileArray {
        data {
          _id
          id
          leverageId
          name
          description
          tiers {volume, hasVolume, leverage, hasLeverage}
          utcLastUpdateTimestamp
          server {name}
          createdBy {username}
          updatedBy {username}
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`

const useRefreshLeverageProfileMutation = (): { refreshLeverageProfile: any, data: any, loading: boolean, error: any } => {
  try{
    const [useRefreshLeverageProfileMutation, { data, loading, error }] = useMutation(REFRESH_LEVERAGE_PROFILE);

    const refreshLeverageProfile = (input) => {
      return useRefreshLeverageProfileMutation({
        variables: {
          input: {
            server: input.server || null,
          },
        },
      });
    };

    return { refreshLeverageProfile, data, loading, error } 
  }
  catch(err: any){
    console.error(err);
    return { refreshLeverageProfile: null, data: null, loading: false, error: err };
  }
}

export {
  getLeverageProfileCount, 
  getLeverageProfileById, 
  getLeverageProfile, 
  useRefreshLeverageProfileMutation
}