import React from "react";
import {useTranslation} from "react-i18next";
import {getColumnSearchProps} from "../../../../components/table/function/filterTable";

export const columns = () => {
    const {t} = useTranslation();

    return [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: "Server",
            dataIndex: "server",
            key: "server",
            ...getColumnSearchProps(),
            render: (text) => <p>{text?.name}</p>,
        },
        {
            title: "Provider",
            dataIndex: "provider",
            key: "provider",
            filters: [{text: "easyMAM", value: "easyMAM"}],
            render: (text) => <p>{text}</p>,
        },
        {
            title: "Status",
            dataIndex: "isActive",
            key: "isActive",
            filters: [
                {text: "Active", value: true},
                {text: "Inactive", value: false},
            ],
            render: (isActive) => <p>{isActive ? t("common.active") : t("common.inactive")}</p>,
        },
    ];
};
