import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Input, Modal, Row, Select, Space, Switch} from "antd";
import {useTranslation} from "react-i18next";
import TextArea from "antd/es/input/TextArea";
import {createCustomTrigger, updateCustomTrigger} from "../../../../../../../api/restful/automationSetting/customTrigger";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import {DeleteOutlined} from "@ant-design/icons";

const CreateUpdateModal = ({isOpen, handleCancel, item, refetch}) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const isUpdate = !!item

    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!isOpen) {
            form.resetFields()
            form.setFieldsValue({payload: [{fieldName: null, dataType: null, optionsValue: [{label: null, value: null}], optionsType: null}]});
            return
        }

        if (isUpdate && isOpen) {
            form.setFieldsValue({
                ...item,
                payload: item?.payload ? item?.payload : [{fieldName: null, dataType: null, optionsValue: [{label: null, value: null}], optionsType: null}]
            })
        }
    }, [isUpdate, isOpen]);

    const onSubmit = async (value) => {
        try {
            setLoading(true)

            if (!isUpdate) {
                const response = await createCustomTrigger(value)

                if (response?.status === "Success") {
                    refetch()
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: `Create Trigger Successfully`,
                        key: "customTriggerInformation",
                    })
                }

                handleCancel()
            } else {
                const response = await updateCustomTrigger({_id: item._id, ...value})

                if (response?.status === "Success") {
                    refetch()
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: `Update Trigger - ${response?.data?.trigger} Successfully`,
                        key: "customTriggerInformation",
                    })
                }

                handleCancel()
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal
            open={isOpen}
            onCancel={handleCancel}
            title={isUpdate ? "Update Custom Trigger" : "Create Custom Trigger"}
            onOk={() => form.submit()}
            okText={t("common.submit")}
            okButtonProps={{loading: loading}}
            className={"custom-trigger-create-update-modal"}
            cancelText={t("common.cancel")}
            width={"70%"}
        >
            <Form form={form} onFinish={onSubmit} labelCol={{span: 24}} className={"margin-bottom-0-75"} labelAlign={"left"} colon={false}>
                <Form.Item name={"isEnabled"} label={t("Enabled")} valuePropName={"checked"}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={"trigger"} label={t("Trigger")}>
                    <Input/>
                </Form.Item>
                <Form.Item name={"triggerFrom"} label={t("Trigger From")}>
                    <Input/>
                </Form.Item>
                <Form.Item name={"triggerGroup"} label={t("Trigger Group")}>
                    <Input/>
                </Form.Item>
                <Form.Item name={"priority"} label={t("Priority")}>
                    <Select
                        options={[
                            {label: "High", value: "high"},
                            {label: "Medium", value: "medium"},
                            {label: "Low", value: "low"},
                        ]}
                    />
                </Form.Item>
                <Form.Item label={t("Payload")}>
                    <Card size={"small"}>
                        <Form.List name={"payload"}>
                            {(fields, {add, remove}, {errors}) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Form.Item {...field} className={"form-list-item-container"}>
                                            <>
                                                <Col span={8}>
                                                    <Form.Item name={[field.name, 'fieldName']}>
                                                        <Input placeholder={"Field Name"}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={7}>
                                                    <Form.Item name={[field.name, 'dataType']}>
                                                        <Select
                                                            placeholder={"Data Type"}
                                                            options={[
                                                                {label: "String", value: "string"},
                                                                {label: "Array", value: "array"},
                                                                {label: "Number", value: "number"},
                                                                {label: "Boolean", value: "boolean"},
                                                                {label: "Unknown", value: "unknown"},
                                                            ]}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                {/*<Col span={8}>*/}
                                                {/*    <Form.Item name={[field.name, 'optionsValue']}>*/}
                                                {/*        <Input placeholder={"Options Value (Split with ,)"}/>*/}
                                                {/*    </Form.Item>*/}
                                                {/*</Col>*/}
                                                <Col span={8}>
                                                    <Form.Item name={[field.name, 'optionsType']}>
                                                        <Input placeholder={"Options Type"}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={1} className={"text-align-center"}>
                                                    <a style={{color: "red"}} onClick={() => remove(field.name)}>
                                                        <DeleteOutlined/>
                                                    </a>
                                                </Col>
                                                <Col span={23} className={"margin-bottom-0-5"}>
                                                    <Card size={"small"}>
                                                        <Form.List name={[field.name, 'optionsValue']}>
                                                            {(innerFields, {add: addInner, remove: removeInner}, {errors}) => (
                                                                <>
                                                                    <Row>
                                                                        <Col span={23}>
                                                                            <Row justify={"space-between"}
                                                                                 className={"margin-bottom-0-3 margin-left-0-5 margin-right-0-25"}>
                                                                                <label><small>Options Value</small></label>
                                                                                <Button size={"small"} onClick={() => addInner()}>{t("common.add")}</Button>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                    {innerFields.map((innerField, index) => (
                                                                        <Form.Item {...innerField} className={"form-list-item-container"}>
                                                                            <>
                                                                                <Col span={12}>
                                                                                    <Form.Item name={[innerField.name, 'label']}>
                                                                                        <Input placeholder={"Label"}/>
                                                                                    </Form.Item>
                                                                                </Col>
                                                                                <Col span={11}>
                                                                                    <Form.Item name={[innerField.name, 'value']}>
                                                                                        <Input placeholder={"Value"}/>
                                                                                    </Form.Item>
                                                                                </Col>
                                                                                <Col span={1} className={"text-align-center"}>
                                                                                    <a style={{color: "red"}} onClick={() => removeInner(innerField.name)}>
                                                                                        <DeleteOutlined/>
                                                                                    </a>
                                                                                </Col>
                                                                            </>
                                                                        </Form.Item>
                                                                    ))}
                                                                </>
                                                            )}
                                                        </Form.List>
                                                    </Card>
                                                </Col>
                                            </>
                                        </Form.Item>
                                    ))}

                                    <Button className={"margin-top-0-75"} type="dashed" onClick={() => add()} block>
                                        + Add Item
                                    </Button>
                                </>
                            )}
                        </Form.List>
                    </Card>
                </Form.Item>
            </Form>

            <br/>
        </Modal>
    )
}

export default CreateUpdateModal