import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Avatar, Badge, Col, Empty, List, Modal, Row, Spin, Tag, Typography} from "antd";
import VirtualList from "rc-virtual-list";
// import {updateUserPushNotificationReadStatus} from "../../../../api/graphql/pushNotification";
import {useSetRecoilState} from "recoil";
import {notificationMessage, unreadPushNotificationCountState} from "../../../../../recoil_state";
import {formatDate, formatDateTime} from "../../../users/_common/function";
import {useGetHeaderPushNotificationLogByUserId} from "../../function/common";
import {updatePushNotificationLogReadStatus} from "../../../../api/graphql/pushNotification";
import {NotificationOutlined} from "@ant-design/icons";
import moment from "moment";

const {Paragraph} = Typography

const PushNotificationListing = (props) => {
    const {isHeader} = props
    const {t} = useTranslation();
    const [selected, setSelected] = useState<any>({})
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [currentData, setCurrentData] = useState<any>({})
    const [updatedRead, setUpdatedRead] = useState<string[]>([])
    const setUnreadPushNotificationCount = useSetRecoilState(unreadPushNotificationCountState);
    const [data, total, fetchPushNotificationLog, loading] = useGetHeaderPushNotificationLogByUserId();
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const ContainerHeight = 400;

    const renderTinyMCEContent = (content) => {
        return <div className={"tinyMceParagraph"} dangerouslySetInnerHTML={{__html: content}}/>;
    };

    const selectItem = (item) => {
        setSelected({})
    }

    const timezone = localStorage.getItem("timezone");

    const dateToday = formatDate(new Date())

    const listComponent =
        <List>
            <VirtualList
                className={"clickable"}
                data={data}
                height={ContainerHeight}
                itemHeight={47}
                itemKey="stock"
            >
                {(item, number) => {
                    const time = item?.createdAt
                    const isToday = formatDate(time) === dateToday
                    const dateFormat = isToday ? "hh:mm A" : "DD/MM, hh:mm A"

                    let formattedDate = ""

                    if (time) {
                        formattedDate = moment(time)?.format(dateFormat);
                        if (timezone) {
                            formattedDate = moment(time)?.utcOffset(+timezone).format(dateFormat);
                        }
                    }

                    return (
                        <>
                            <div
                                className={"text-start push-notification-card"}
                                onClick={async () => {
                                    if (isHeader) {
                                        setCurrentData(item)
                                        setModalOpen(true);
                                        if (!item?.isRead && !updatedRead.includes(item?._id)) {
                                            const res = await updatePushNotificationLogReadStatus([item?._id], true)
                                            if (res?.updatePushNotificationLogReadStatus?.status === "success") {
                                                setUpdatedRead(prev => [...prev, item?._id])
                                                setUnreadPushNotificationCount(prev => prev - 1)
                                            }
                                        }
                                        // else if (!updatedRead.includes(item?._id)) {
                                        //     const res = await updatePushNotificationLogReadStatus([item?._id], true)
                                        //     setUpdatedRead(prev => [...prev, res?.updatePushNotificationLogReadStatus[0]?._id])
                                        // }

                                        // setMessage({
                                        //     status: "open",
                                        //     title: item?.notification?.title,
                                        //     msg: <>
                                        //         {item?.notification?.body}
                                        //         <Image src={item?.notification?.image} preview={false}/>
                                        //     </>,
                                        //     icon: <Image src={item?.notification?.icon} preview={false}/>,
                                        //     onClose: async () => {
                                        //         const res = await updatePushNotificationLogReadStatus([item?._id], true)
                                        //         if (res?.updatePushNotificationLogReadStatus?.status === "success") {
                                        //             setUpdatedRead(prev => [...prev, item?._id])
                                        //             setUnreadPushNotificationCount(prev => prev - 1)
                                        //         }
                                        //     }
                                        // })
                                    } else {
                                        selectItem(item)
                                    }
                                }}
                            >
                                <Row wrap={false} gutter={[12, 0]}>
                                    <Col flex={"none"}>
                                        <Badge offset={[-35, 5]} dot={!item?.isRead && !updatedRead.includes(item?._id)}>
                                            <Avatar size={40} src={item?.notification?.icon} shape={"circle"} icon={<NotificationOutlined/>}/>
                                        </Badge>
                                    </Col>
                                    <Col flex={"auto"}>
                                        <Row wrap={false} align={"middle"}>
                                            <Col flex={"auto"}>
                                                <Row wrap={false}>
                                                    {/*{!item?.isRead && !updatedRead.includes(item?._id) &&*/}
                                                    {/*<Col span={2}>*/}
                                                    {/*    <Badge dot offset={[-2, 2]}/>*/}
                                                    {/*</Col>*/}
                                                    {/*}*/}
                                                    <Col span={!item?.isRead && !updatedRead.includes(item?._id) ? 22 : 24}>
                                                        <Paragraph ellipsis={{rows: 1}} className={"title"}>
                                                            {item?.notification?.title}
                                                        </Paragraph>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row wrap={false} align={"middle"}>
                                            <Col flex={"auto"}>
                                                <Paragraph ellipsis={{rows: 1}} className={"description"}>
                                                    {item?.notification?.body}
                                                </Paragraph>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className={"text-end date-time"} style={{fontSize: "8px"}}>
                                                {formattedDate}
                                            </Col>
                                        </Row>
                                    </Col>
                                    {item?.notification?.image &&
                                    <Col flex={"none"} className={"push-notification-image"}>
                                        <Avatar src={item?.notification?.image} shape={"square"} size={55}/>
                                    </Col>
                                    }
                                </Row>
                            </div>
                            {/*{(number === 7 || number === total - 1) &&*/}
                            {/*<div className={"p-2"}>*/}
                            {/*    <Button>See All</Button>*/}
                            {/*</div>*/}
                            {/*}*/}
                        </>
                    )
                }}
            </VirtualList>
        </List>

    const emptyComponent = (
        <div className={"mx-auto mt-4"}>
            <Empty/>
        </div>
    )

    return (
        <div id={"pushNotificationListing"}>
            <Spin spinning={loading}>
                {data?.length > 0 ? listComponent :
                    emptyComponent}
            </Spin>
            <Modal
                title={
                    <>
                        {currentData?.notification?.title}
                        <p className={"date-time"}>
                            {formatDateTime(currentData?.updatedAt || currentData?.createdAt)}
                        </p>
                    </>
                }
                width={"60%"}
                open={modalOpen}
                footer={null}
                onCancel={() => setModalOpen(false)}
                className={"push-notification-modal"}
                getContainer={() => document.querySelector("#root") as HTMLElement}
            >
                {currentData?.notification?.body && <p className={"margin-bottom-0-75"}>{currentData?.notification?.body}</p>}
                {currentData?.notification?.image &&
                <div className={"text-align-center margin-bottom-0-75"}>
                    <img src={currentData?.notification?.image}/>
                </div>
                }
                {currentData?.longContent && <div>{renderTinyMCEContent(currentData?.longContent)}</div>}
            </Modal>
        </div>
    )
};

export default PushNotificationListing;