import React, {useEffect} from "react";
import {Col, Form, Input, Row} from "antd";
import {useTranslation} from "react-i18next";
import {Checkbox} from "antd/lib";

const ExecutionPanelMt5 = ({initialData}) => {
    const {t} = useTranslation()
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(initialData);
    }, [initialData])

    return (
        <>
            <Row gutter={[16, 16]} style={{marginBottom: "1%", marginTop: "2%"}}>
                <Col span={20} offset={2}>
                    <p>
                        Please set up parameters of orders execution by symbols for the group.
                    </p>
                </Col>
            </Row>

            <Form form={form}>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={10} offset={2}>
                        <Form.Item
                            label={t("Use default execution settings")}
                        >
                            <Checkbox disabled={true}
                                      defaultChecked={initialData?.execMode === initialData?.execModeDefault && initialData?.rETimeout === initialData?.rETimeoutDefault && initialData?.rEFlags === initialData?.rEFlagsDefault}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={20} offset={2}>
                        <Form.Item
                            name={"execMode"}
                            label={t("Execution")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={20} offset={2}>
                        <Form.Item
                            name={"rETimeout"}
                            label={t("Timeout")}
                        >
                            <Input readOnly={true} addonAfter={"seconds for confirmation"}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={10} offset={2}>
                        <Form.Item
                            label={t("Confirm orders")}
                        >
                            <Checkbox disabled={true}
                                      defaultChecked={initialData?.rEFlags === "REQUEST_FLAGS_ORDER" }/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default ExecutionPanelMt5