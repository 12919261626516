import {useState} from "react";
import {getEmailSendDraft, getEmailTemplateBatchLog, getEmailTemplateLog} from "../../../api/graphql/message";
import {getEmailTemplate, getEmailTemplateAsync} from "../../../api/graphql/emailTemplate";
import {getCompanyEmail} from "../../../api/graphql/companyEmail";
import {getCompanyEmailTypes} from "../../../api/graphql/companyEmailType";
import {getPushNotificationBatchLog, getPushNotificationDraft} from "../../../api/graphql/pushNotification";
import {
    getPushNotificationTemplate,
    getPushNotificationTemplateById
} from "../../../api/graphql/pushNotificationTemplate";

// EMAIL //

export const useGetEmailSendDraft = () => {
    const [data, setData] = useState<any>([])
    const [total, setTotal] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [props, setProps] = useState({filter: {isDeleted: false, isSent: false}, limit: 20, offset: 0, orderBy: "createdAt_DSC"})

    const fetchData = async () => {
        try {
            setLoading(true)

            const {filter, limit, offset, orderBy} = props
            const {getEmailSendDraft: response} = await getEmailSendDraft(filter, limit, offset, orderBy)

            setData(response?.data)
            setTotal(response?.total)
        } catch (e) {

        } finally {
            setLoading(false)
        }
    }

    return [data, total, fetchData, props, setProps] as const
}

export const useGetEmailSendDraftArchive = () => {
    const [data, setData] = useState<any>([])
    const [total, setTotal] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [props, setProps] = useState({filter: {isDeleted: true, isSent: false}, limit: 20, offset: 0, orderBy: "createdAt_DSC"})

    const fetchData = async () => {
        try {
            setLoading(true)

            const {filter, limit, offset, orderBy} = props
            const {getEmailSendDraft: response} = await getEmailSendDraft(filter, limit, offset, orderBy)

            setData(response?.data)
            setTotal(response?.total)
        } catch (e) {

        } finally {
            setLoading(false)
        }
    }

    return [data, total, fetchData, props, setProps] as const
}

export const useGetEmailTemplateDropdown = () => {
    const [data, setData] = useState<any>([])

    const fetchEmailTemplate = async () => {
        try {
            const {getEmailTemplate} = await getEmailTemplateAsync({isActive: true})
            // const options = getEmailTemplate?.data?.map(d => ({
            //     label: d.displayName || d.name,
            //     value: d._id,
            //     html: d.htmlObject,
            //     subject: d.displaySubjectLanguage
            // }))

            setData(getEmailTemplate?.data)
        } catch (e) {

        }
    }

    return [data, fetchEmailTemplate] as const
}

export const useGetEmailLog = () => {
    const [data, setData] = useState<any>([])
    const [total, setTotal] = useState<number>(0)
    const [props, setProps] = useState({filter: {fromTemplate: "message"}, limit: 20, offset: 0, orderBy: "createdAt_DSC"})

    const fetchLog = async () => {
        try {
            const {filter, limit, offset, orderBy} = props
            const {getEmailTemplateBatchLog: res} = await getEmailTemplateBatchLog(filter, limit, offset, orderBy)

            setData(res?.data)
            setTotal(res?.total)
        } catch (e) {

        }
    }

    return [data, total, props, setProps, fetchLog] as const
}

export const useGetEmailSenderDropdown = () => {
    const [emailType, setEmailType] = useState<any>([])
    const [senderEmail, setSenderEmail] = useState<any[]>([])

    const fetchEmailType = async () => {
        try {
            const {getCompanyEmail: email} = await getCompanyEmail({filter: null});
            const {getCompanyEmailTypes: type} = await getCompanyEmailTypes({filter: null});

            setEmailType(type?.data?.map(d => ({
                label: d?.name,
                value: d?._id
            })))

            setSenderEmail(email?.data?.map(d => ({
                label: d?.name + " - " + d?.email,
                value: d?._id
            })))

        } catch (e) {

        }
    }

    return [emailType, senderEmail, fetchEmailType] as const
}

// PUSH NOTIFICATION //

export const useGetPushNotificationDraft = () => {
    const [data, setData] = useState<any>([])
    const [total, setTotal] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [props, setProps] = useState({filter: {}, limit: 20, offset: 0, orderBy: "createdAt_DSC"})

    const fetchData = async () => {
        try {
            setLoading(true)

            const {filter, limit, offset, orderBy} = props
            const {getPushNotificationDraft: response} = await getPushNotificationDraft(filter, limit, offset, orderBy)

            setData(response?.data)
            setTotal(response?.total)
        } catch (e) {

        } finally {
            setLoading(false)
        }
    }

    return [data, total, fetchData, props, setProps] as const
}

export const useGetPushNotificationDraftArchive = () => {
    const [data, setData] = useState<any>([])
    const [total, setTotal] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [props, setProps] = useState({filter: {isDeleted: true}, limit: 20, offset: 0, orderBy: "createdAt_DSC"})

    const fetchData = async () => {
        try {
            setLoading(true)

            const {filter, limit, offset, orderBy} = props
            const {getPushNotificationDraft: response} = await getPushNotificationDraft(filter, limit, offset, orderBy)

            setData(response?.data)
            setTotal(response?.total)
        } catch (e) {

        } finally {
            setLoading(false)
        }
    }

    return [data, total, fetchData, props, setProps] as const
}

export const useGetPushNotificationLog = () => {
    const [data, setData] = useState<any>([])
    const [total, setTotal] = useState<number>(0)
    const [props, setProps] = useState({filter: {}})

    const fetchLog = async () => {
        try {
            const {filter} = props
            const {getPushNotificationBatchLog: res} = await getPushNotificationBatchLog(filter)

            setData(res?.data)
            setTotal(res?.total)
        } catch (e) {

        }
    }

    return [data, total, props, setProps, fetchLog] as const
}

export const useGetPushNotificationTemplateDropdown = () => {
    const [data, setData] = useState<any>([])

    const fetchPushNotificationTemplate = async () => {
        try {
            const res = await getPushNotificationTemplate({
                filter: {isActive: true, type: "system"}
            })

            setData(res?.getPushNotificationTemplate?.data)
        } catch (e) {

        }
    }

    return [data, fetchPushNotificationTemplate] as const
}
