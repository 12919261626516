import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, Modal, Row} from "antd";
import {useTranslation} from "react-i18next";
import TableAntDesign from "../../../../../../../../components/table/tableAntDesign";

const CommissionMt5 = ({initialData}) => {
    const {t} = useTranslation()
    const [form] = Form.useForm();

    const [commissionData, setCommissionData] = useState<any>([]);
    const [tierModal, setTierModal] = useState<any>(false);
    const [tierData, setTierData] = useState<any>(false);

    useEffect(() => {
        form.setFieldsValue({
            ...initialData
        });
    }, [initialData])

    const CommissionDataColumns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (text) => text || "-",
        },
        {
            title: "Symbol",
            dataIndex: "path",
            key: "path",
            render: (text) => text || "-",
        },
        {
            title: "Range",
            dataIndex: "rangeMode",
            key: "rangeMode",
            render: (text) => text || "-",
        },
        {
            title: "Charge",
            dataIndex: "chargeMode",
            key: "chargeMode",
            render: (text) => text || "-",
        },
        {
            title: "Entry",
            dataIndex: "entryMode",
            key: "entryMode",
            render: (text) => text || "-",
        },
        {
            title: "Turnover Currency",
            dataIndex: "turnoverCurrency",
            key: "turnoverCurrency",
            render: (text) => text || "-",
        },
        {
            title: "Tier Total",
            dataIndex: "tierTotal",
            key: "tierTotal",
            render: (text, record) => {

                return (
                    <>
                        <Button type={"link"} onClick={() => {
                            setTierData(record?.tierData)
                            setTierModal(true)
                        }}>
                            {text || "-"}
                        </Button>
                    </>
                )
            },
        },
    ]

    const CommissionTierColumns = [
        {
            title: "From",
            dataIndex: "rangeFrom",
            key: "rangeFrom",
            render: (text) => text || "-",
        },
        {
            title: "To",
            dataIndex: "rangeTo",
            key: "rangeTo",
            render: (text) => text || "-",
        },
        {
            title: "Commission",
            dataIndex: "value",
            key: "value",
            render: (text) => text || "-",
        },

        {
            title: "Minimal",
            dataIndex: "minimal",
            key: "minimal",
            render: (text) => text || "-",
        },
        {
            title: "Maximal",
            dataIndex: "maximal",
            key: "maximal",
            render: (text) => text || "-",
        },
        {
            title: "Mode",
            dataIndex: "mode",
            key: "mode",
            render: (text) => text || "-",
        },
        {
            title: "Currency",
            dataIndex: "currency",
            key: "currency",
            render: (text) => text || "-",
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            render: (text) => text || "-",
        }
    ]

    const preload = () => {

        if (initialData?.commissionData?.length > 0) {
            setCommissionData(initialData?.commissionData)
        }

    }

    useEffect(() => {
        preload()
    }, [initialData])


    const CommissionDetails = () => {
        return (
            <>
                <Row gutter={[16, 16]} style={{marginBottom: "1%", marginTop: "2%"}}>
                    <Col span={24}>
                        <p>
                            Adjustment of commissions parameters of the symbol.
                        </p>
                    </Col>
                </Row>

                <Form form={form}>
                    <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                        <Col span={12}>
                            <Form.Item
                                name={"commissionData.name"}
                                label={t("Name")}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={"limitHistory"}
                                label={t("Available history")}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </>
        )
    }

    return (
        <>
            <Row gutter={[16, 16]} style={{marginBottom: "1%", marginTop: "2%"}}>
                <Col span={24}>
                    <TableAntDesign
                        data={commissionData}
                        tableName={"Commission"}
                        columns={CommissionDataColumns}
                        size={"small"}
                        isTableLocalSearch={true}
                        pagination={{
                            size: "small",
                            pageSize: 5,
                            total: commissionData?.length,
                            showTotal: (total) => <p>Total {total} items</p>
                        }}
                    />
                </Col>

            </Row>
            <Modal
                title={"Commission Tier"}
                open={tierModal}
                onCancel={() => {
                    setTierModal(false)
                    setTierData([])
                }}
                footer={null}
                width={1000}
                destroyOnClose
            >
                <TableAntDesign
                    data={tierData}
                    tableName={"Commission Tier"}
                    columns={CommissionTierColumns}
                    size={"small"}
                    isTableLocalSearch={true}
                    pagination={{
                        size: "small",
                        pageSize: 5,
                        total: commissionData?.length,
                        showTotal: (total) => <p>Total {total} items</p>
                    }}
                />
            </Modal>
        </>
    )
}

export default CommissionMt5