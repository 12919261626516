import React from "react";
import AutoBindLeads_1 from "./view/layout1";
import {getProfileInformationSetting} from "../../../../api/graphql/profileInformation";

function AutoBindLeads() {
    let layout = 1;
    let component;
    const tableName = "Auto Bind Leads"
    const {data:profileInformationLead} = getProfileInformationSetting({type: "leads"})
    const {data:profileInformationClient} = getProfileInformationSetting({type: "client"})
    const filteredProfileInformationClient = profileInformationClient?.getProfileInformationSetting?.data?.filter(d => d?.position !== null)
    const filteredProfileInformationLead = profileInformationLead?.getProfileInformationSetting?.data?.filter(d => d?.position !== null)

    switch (layout) {
        case 1:
            component = (
                <AutoBindLeads_1 tableName={tableName} profileInformationLead={filteredProfileInformationLead} profileInformationClient={filteredProfileInformationClient}/>
            );
            break;

        default:
            component = (
                <AutoBindLeads_1 tableName={tableName} profileInformationLead={filteredProfileInformationLead} profileInformationClient={filteredProfileInformationClient}/>
            );
            break;
    }

    return component;
};

export default AutoBindLeads;
