import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Input, Row, Select, Space} from "antd";
import {EditOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useGetPspSettingCurrency} from "../../../../../function/useQueryHooks";

const PspForm = (props) => {
    const {onSubmitHandler, editable, setEditable, sectionTitle, sectionItems, matchedData} = props;
    const {t} = useTranslation();

    const [form] = Form.useForm();

    const [currency, setCurrency] = useState();
    const [availableBankList, setAvailableBankList] = useState();
    const [pspCurrencyData, fetchPspSettingCurrency] = useGetPspSettingCurrency()

    const currencyOptions = pspCurrencyData?.map((d) => {
        return {label: t(d?.currency), value: d?.currency};
    });

    useEffect(() => {
        setCurrency(matchedData?.currency);
    }, [matchedData]);

    useEffect(() => {
        const currentData = pspCurrencyData?.find((d) => d?.currency === currency);

        const bankListOptions = currentData?.availableBank?.map((d) => {
            return {label: d?.bankName, value: d?.bankCode};
        });
        setAvailableBankList(bankListOptions);
    }, [currency, pspCurrencyData]);

    useEffect(() => {
        if (sectionItems[0]?.pspSetting?._id) {
            fetchPspSettingCurrency({pspSetting: sectionItems[0]?.pspSetting?._id})
        }
    }, [sectionItems[0]?.pspSetting?._id]);

    return (
        <Form onFinish={onSubmitHandler} style={{width: "100%"}} key={sectionTitle} labelCol={{flex: "150px"}} labelWrap form={form} colon={false}>
            <Card className={"psp-info-card margin-top-0-75"} style={{width: "100%"}}>
                <Row gutter={[8, 8]}>
                    <Col span={24}>
                        <Row justify={"space-between"}>
                            <h3 className={"margin-bottom-0"}>{sectionTitle}</h3>
                            {editable === sectionTitle ? (
                                <Space>
                                    <Button onClick={() => setEditable("")}>{t("common.cancel")}</Button>
                                    <Button onClick={() => form.submit()}>{t("common.submit")}</Button>
                                </Space>
                            ) : (
                                <Button type={"link"} onClick={() => setEditable(sectionTitle)} icon={<EditOutlined/>}/>
                            )}
                        </Row>
                    </Col>
                    <>
                        {(sectionItems as any[])?.map((item) => {
                            const initialValue = matchedData ? matchedData[item?.fieldName] : null;
                            if (item?.fieldName !== "bank") {
                                return (
                                    <Col xs={24} md={12} key={item?.fieldName + "_" + item?.pspSetting?._id}>
                                        <Form.Item
                                            labelAlign={"left"}
                                            label={t(`psp.${item?.fieldDisplayName || item?.fieldName}`)}
                                            name={item?.fieldName + "_" + item?.pspSetting?._id}
                                            initialValue={initialValue}
                                            required
                                        >
                                            <Input disabled={editable !== sectionTitle}/>
                                        </Form.Item>
                                    </Col>
                                );
                            } else {
                                return (
                                    <>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                labelAlign={"left"}
                                                label={t(`clientUser.bank.currency`)}
                                                name={"currency" + "_" + sectionItems[0]?.pspSetting?._id}
                                                initialValue={matchedData?.currency}
                                                required
                                            >
                                                <Select
                                                    disabled={editable !== sectionTitle}
                                                    options={currencyOptions}
                                                    onSelect={(value) => {
                                                        setCurrency(value);
                                                        form.setFieldValue("bank" + "_" + sectionItems[0]?.pspSetting?._id, "");
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        {currency && (
                                            <Col xs={24} md={12}>
                                                <Form.Item
                                                    labelAlign={"left"}
                                                    label={t(`Bank`)}
                                                    name={"bank" + "_" + sectionItems[0]?.pspSetting?._id}
                                                    initialValue={matchedData?.bank}
                                                    required
                                                >
                                                    <Select disabled={editable !== sectionTitle} options={availableBankList}/>
                                                </Form.Item>
                                            </Col>
                                        )}
                                    </>
                                );
                            }
                        })}
                    </>
                </Row>
            </Card>
        </Form>
    );
};

export default PspForm;
