import React from "react";
import {getColumnSearchProps} from "../../../../../components/table/function/filterTable";

export const columnsCT = [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: true,
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Description",
        dataIndex: "description",
        key: "description",
        sorter: true,
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Base Symbol ID",
        dataIndex: "baseSymbolId",
        key: "baseSymbolId",
        align: "center",
        sorter: true,
        ...getColumnSearchProps(),
        render: (text) => <a>{text}</a>,
    },
];
