import React, {memo} from "react";
import {Modal} from "antd";
import SymbolMappingCategoryForm from "./categoryForm";
import {useTranslation} from "react-i18next";

const SymbolCategoryModal = (props) => {
    const {item, isOpen, server, close, securities, platform,assetClassMt5} = props
    const {t} = useTranslation()

    return (
        <Modal
            title={t(`Edit Symbol Category`)}
            open={isOpen}
            onCancel={() => close()}
            footer={null}
            width={500}
            destroyOnClose
        >
            <SymbolMappingCategoryForm
                server={server}
                platform={platform}
                item={item}
                securities={securities}
                close={close}
                assetClassMt5={assetClassMt5}
            />
        </Modal>
    );
};

export default memo(SymbolCategoryModal);
