import {gql, useMutation} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";
import {GET_TRADING_ACCOUNT} from "./tradingAccount";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {useTranslation} from "react-i18next";

const GET_TRADING_ACCOUNT_DETAIL = gql`
    query getTradingAccountDetail($id: String!) {
        getTradingAccountDetail(id: $id) {
            ... on TradingAccount {
                _id
                user {
                    _id
                    username
                    isLoginEnabled
                    clientAccountType
                }
                accountId
                traderId
                brokerName
                accessRights
                depositAssetId
                hasTradingPermission
                spreadGroup {
                    _id
                    groupId
                    name
                }
                mt5SpreadGroup {
                    _id
                    group
                }
                product {
                    _id
                    id
                    name
                    leverage
                    server {
                        _id
                        platform
                        platformDisplayName
                        mode
                        name
                        resetPasswordLink
                    }
                    spreadGroup {
                        _id
                        name
                    }
                    spreadGroupMt5{
                        _id
                        group
                    }
                    mode
                }
                leverage
                leverageInCents
                balance
                lockBalance
                asset
                totalBalance
                margin
                tradingVoucher
                availableMargin
                marginLevel
                credit
                activeCredit
                mode
                platform
                equity
                registrationDatetime
                hasDepositPermission
                hasWithdrawalPermission
                createdAt
                isSendReport
                isSuspicious
                lastUpdated
                isDefault
                isPrivate
                commRule{
                    rule
                    group
                    commissionSetting
                    ruleType
                    commRuleType
                    commRuleAuto
                }
                openPosition
                freeMarginWithdrawableAmount
                managerUpdateDatetime
                mt5Rights
                platformAccountId
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getTradingAccountMt5Detail = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            GET_TRADING_ACCOUNT_DETAIL,
            {
                id: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const CREATE_TRADING_ACCOUNT_MT5 = gql`
    mutation createTradingAccountMt5($input: TradingAccountMt5CreateInput) {
        createTradingAccountMt5(input: $input) {
            ... on TradingAccount {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useCreateTradingAccountMt5Mutation = (): {
    createTradingAccountMt5: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [createTradingAccountMt5Mutation, {data, loading, error}] = useMutation(CREATE_TRADING_ACCOUNT_MT5);

        const createTradingAccountMt5 = (input) => {
            return createTradingAccountMt5Mutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {createTradingAccountMt5}}) {
                    const typename = createTradingAccountMt5?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(createTradingAccountMt5?.errKey),
                            key: "createTradingAccountMt5",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(createTradingAccountMt5?.msg),
                            key: "createTradingAccountMt5",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Add Trading Account Successfully"),
                            key: "createTradingAccountMt5",
                        });
                    }
                },
                refetchQueries: [GET_TRADING_ACCOUNT]
            });
        };

        return {createTradingAccountMt5, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {createTradingAccountMt5: null, data: null, loading: false, error: err};
    }
};

const CREATE_TRADING_ACCOUNT_MT5_MONEY_MANAGER = gql`
    mutation createTradingAccountMt5MoneyManager($input: TradingAccountMoneyManagerMt5CreateInput) {
        createTradingAccountMt5MoneyManager(input: $input) {
            ... on TradingAccount {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useCreateTradingAccountMt5MoneyManagerMutation = (): {
    createTradingAccountMt5MoneyManager: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [createTradingAccountMt5MoneyManagerMutation, {data, loading, error}] = useMutation(CREATE_TRADING_ACCOUNT_MT5_MONEY_MANAGER);

        const createTradingAccountMt5MoneyManager = (input) => {
            return createTradingAccountMt5MoneyManagerMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {createTradingAccountMt5MoneyManager}}) {
                    const typename = createTradingAccountMt5MoneyManager?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(createTradingAccountMt5MoneyManager?.errKey),
                            key: "createTradingAccountMt5MoneyManager",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(createTradingAccountMt5MoneyManager?.msg),
                            key: "createTradingAccountMt5MoneyManager",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Add Trading Account Successfully"),
                            key: "createTradingAccountMt5MoneyManager",
                        });
                    }
                },
                refetchQueries: [GET_TRADING_ACCOUNT]
            });
        };

        return {createTradingAccountMt5MoneyManager, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {createTradingAccountMt5MoneyManager: null, data: null, loading: false, error: err};
    }
};

const CREATE_TRADING_ACCOUNT_MT5_INVESTOR = gql`
    mutation createTradingAccountMt5Investor($input: TradingAccountInvestorMt5CreateInput) {
        createTradingAccountMt5Investor(input: $input) {
            ... on TradingAccount {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useCreateTradingAccountMt5InvestorMutation = (): {
    createTradingAccountMt5Investor: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [createTradingAccountMt5InvestorMutation, {data, loading, error}] = useMutation(CREATE_TRADING_ACCOUNT_MT5_INVESTOR);

        const createTradingAccountMt5Investor = (input) => {
            return createTradingAccountMt5InvestorMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {createTradingAccountMt5Investor}}) {
                    const typename = createTradingAccountMt5Investor?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(createTradingAccountMt5Investor?.errKey),
                            key: "createTradingAccountMt5Investor",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(createTradingAccountMt5Investor?.msg),
                            key: "createTradingAccountMt5Investor",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Add Trading Account Successfully"),
                            key: "createTradingAccountMt5Investor",
                        });
                    }
                },
                refetchQueries: [GET_TRADING_ACCOUNT]
            });
        };

        return {createTradingAccountMt5Investor, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {createTradingAccountMt5Investor: null, data: null, loading: false, error: err};
    }
};

const LINK_TRADING_ACCOUNT_MT5 = gql`
    mutation linkTradingAccountMt5($input: TradingAccountMt5LinkInput) {
        linkTradingAccountMt5(input: $input) {
            ... on TradingAccount {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useLinkTradingAccountMt5Mutation = (): {
    linkTradingAccountMt5: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [linkTradingAccountMt5Mutation, {data, loading, error}] = useMutation(LINK_TRADING_ACCOUNT_MT5);

        const linkTradingAccountMt5 = (input) => {
            return linkTradingAccountMt5Mutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {linkTradingAccountMt5}}) {
                    const typename = linkTradingAccountMt5?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(linkTradingAccountMt5?.errKey),
                            key: "linkTradingAccountMt5",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(linkTradingAccountMt5?.msg),
                            key: "linkTradingAccountMt5",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Link Trading Account Successfully"),
                            key: "linkTradingAccountMt5",
                        });
                    }
                },
                refetchQueries: [GET_TRADING_ACCOUNT]
            });
        };

        return {linkTradingAccountMt5, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {linkTradingAccountMt5: null, data: null, loading: false, error: err};
    }
};

const LINK_TRADING_ACCOUNT_MT5_MONEY_MANAGER = gql`
    mutation linkTradingAccountMt5MoneyManager($input: TradingAccountMt5LinkInput) {
        linkTradingAccountMt5MoneyManager(input: $input) {
            ... on TradingAccount {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useLinkTradingAccountMt5MoneyManagerMutation = (): {
    linkTradingAccountMt5MoneyManager: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [linkTradingAccountMt5MoneyManagerMutation, {data, loading, error}] = useMutation(LINK_TRADING_ACCOUNT_MT5_MONEY_MANAGER);

        const linkTradingAccountMt5MoneyManager = (input) => {
            return linkTradingAccountMt5MoneyManagerMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {linkTradingAccountMt5MoneyManager}}) {
                    const typename = linkTradingAccountMt5MoneyManager?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(linkTradingAccountMt5MoneyManager?.errKey),
                            key: "linkTradingAccountMt5MoneyManager",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(linkTradingAccountMt5MoneyManager?.msg),
                            key: "linkTradingAccountMt5MoneyManager",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Link Money Manager Trading Account Successfully"),
                            key: "linkTradingAccountMt5MoneyManager",
                        });
                    }
                },
                refetchQueries: [GET_TRADING_ACCOUNT]
            });
        };

        return {linkTradingAccountMt5MoneyManager, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {linkTradingAccountMt5MoneyManager: null, data: null, loading: false, error: err};
    }
};


const LINK_TRADING_ACCOUNT_MT5_INVESTOR = gql`
    mutation linkTradingAccountMt5Investor($input: TradingAccountInvestorMt5LinkInput) {
        linkTradingAccountMt5Investor(input: $input) {
            ... on TradingAccount {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useLinkTradingAccountMt5InvestorMutation = (): {
    linkTradingAccountMt5Investor: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [linkTradingAccountMt5InvestorMutation, {data, loading, error}] = useMutation(LINK_TRADING_ACCOUNT_MT5_INVESTOR);

        const linkTradingAccountMt5Investor = (input) => {
            return linkTradingAccountMt5InvestorMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {linkTradingAccountMt5Investor}}) {
                    const typename = linkTradingAccountMt5Investor?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(linkTradingAccountMt5Investor?.errKey),
                            key: "linkTradingAccountMt5Investor",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(linkTradingAccountMt5Investor?.msg),
                            key: "linkTradingAccountMt5Investor",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Link Money Manager Trading Account Successfully"),
                            key: "linkTradingAccountMt5Investor",
                        });
                    }
                },
                refetchQueries: [GET_TRADING_ACCOUNT]
            });
        };

        return {linkTradingAccountMt5Investor, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {linkTradingAccountMt5Investor: null, data: null, loading: false, error: err};
    }
};

const UPDATE_TRADING_ACCOUNT_MT5 = gql`
    mutation updateTradingAccountMt5($id: String!, $input: TradingAccountMt5UpdateInput!){
        updateTradingAccountMt5(id: $id, input: $input) {
            ... on TradingAccount {
                _id
                user {
                    username
                    isLoginEnabled
                }
                accountId
                traderId
                brokerName
                accessRights
                depositAssetId
                hasTradingPermission
                spreadGroup {
                    _id
                    groupId
                    name
                }
                product {
                    _id
                    id
                    name
                    leverage
                    server {
                        platform
                        mode
                    }
                }
                leverage
                leverageInCents
                balance
                margin
                tradingVoucher
                hasDepositPermission
                hasWithdrawalPermission
                availableMargin
                marginLevel
                credit
                equity
                registrationDatetime
                isSendReport
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const updateTradingAccountMt5 = async (id, input) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_TRADING_ACCOUNT_MT5,
            {
                id: id,
                input: input,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UPDATE_TRADING_ACCOUNT_MT5_PRODUCT = gql`
    mutation updateTradingAccountMt5Product($id: String!, $input: TradingAccountMt5UpdateInput!){
        updateTradingAccountMt5Product(id: $id, input: $input) {
            ... on TradingAccount {
                _id
                user {
                    username
                    isLoginEnabled
                }
                accountId
                traderId
                brokerName
                accessRights
                depositAssetId
                hasTradingPermission
                spreadGroup {
                    _id
                    groupId
                    name
                }
                product {
                    _id
                    id
                    name
                    leverage
                    server {
                        platform
                        mode
                    }
                }
                leverage
                leverageInCents
                balance
                margin
                tradingVoucher
                hasDepositPermission
                hasWithdrawalPermission
                availableMargin
                marginLevel
                credit
                equity
                registrationDatetime
                isSendReport
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const updateTradingAccountMt5Product = async (id, input) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_TRADING_ACCOUNT_MT5_PRODUCT,
            {
                id: id,
                input: input,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UPDATE_TRADING_ACCOUNT_MT5_LEVERAGE = gql`
    mutation updateTradingAccountMt5Leverage($id: String!, $input: TradingAccountMt5UpdateInput!){
        updateTradingAccountMt5Leverage(id: $id, input: $input) {
            ... on TradingAccount {
                _id
                user {
                    username
                    isLoginEnabled
                }
                accountId
                traderId
                brokerName
                accessRights
                depositAssetId
                hasTradingPermission
                spreadGroup {
                    _id
                    groupId
                    name
                }
                product {
                    _id
                    id
                    name
                    leverage
                    server {
                        platform
                        mode
                    }
                }
                leverage
                leverageInCents
                balance
                margin
                tradingVoucher
                hasDepositPermission
                hasWithdrawalPermission
                availableMargin
                marginLevel
                credit
                equity
                registrationDatetime
                isSendReport
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const updateTradingAccountMt5Leverage = async (id, input) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_TRADING_ACCOUNT_MT5_LEVERAGE,
            {
                id: id,
                input: input,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UPDATE_TRADING_ACCOUNT_MT5_SPREAD_GROUP = gql`
    mutation updateTradingAccountMt5SpreadGroup($id: String!, $input: TradingAccountMt5UpdateInput!){
        updateTradingAccountMt5SpreadGroup(id: $id, input: $input) {
            ... on TradingAccount {
                _id
                user {
                    username
                    isLoginEnabled
                }
                accountId
                traderId
                brokerName
                accessRights
                depositAssetId
                hasTradingPermission
                spreadGroup {
                    _id
                    groupId
                    name
                }
                product {
                    _id
                    id
                    name
                    leverage
                    server {
                        platform
                        mode
                    }
                }
                leverage
                leverageInCents
                balance
                margin
                tradingVoucher
                hasDepositPermission
                hasWithdrawalPermission
                availableMargin
                marginLevel
                credit
                equity
                registrationDatetime
                isSendReport
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const updateTradingAccountMt5SpreadGroup = async (id, input) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_TRADING_ACCOUNT_MT5_SPREAD_GROUP,
            {
                id: id,
                input: input,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const DEPOSIT_MANUAL_TRADING_ACCOUNT_MT5 = gql`
    mutation depositManualTradingAccountMt5($input: mt5DepositInput){
        depositManualTradingAccountMt5(input: $input) {
            ... on TradingAccountMt5Transaction {
                _id
                dealId
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const depositManualTradingAccountMt5 = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            DEPOSIT_MANUAL_TRADING_ACCOUNT_MT5,
            {
                input: input,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const DEPOSIT_EWALLET_TRADING_ACCOUNT_MT5 = gql`
    mutation depositEWalletTradingAccountMt5($input: mt5DepositInput){
        depositEWalletTradingAccountMt5(input: $input) {
            ... on TradingAccountMt5Transaction {
                _id
                dealId
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const depositEWalletTradingAccountMt5 = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            DEPOSIT_EWALLET_TRADING_ACCOUNT_MT5,
            {
                input: input,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const DEPOSIT_NEGATIVE_BALANCE_PROTECTION_TRADING_ACCOUNT_MT5 = gql`
    mutation depositNegativeBalanceProtectionTradingAccountMt5($input: mt5DepositInput){
        depositNegativeBalanceProtectionTradingAccountMt5(input: $input) {
            ... on TradingAccountMt5Transaction {
                _id
                dealId
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const depositNegativeBalanceProtectionTradingAccountMt5 = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            DEPOSIT_NEGATIVE_BALANCE_PROTECTION_TRADING_ACCOUNT_MT5,
            {
                input: input,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const WITHDRAWAL_MANUAL_TRADING_ACCOUNT_MT5 = gql`
    mutation withdrawalManualTradingAccountMt5($input: mt5WithdrawalInput){
        withdrawalManualTradingAccountMt5(input: $input) {
            ... on TradingAccountMt5Transaction {
                _id
                dealId
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const withdrawalManualTradingAccountMt5 = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            WITHDRAWAL_MANUAL_TRADING_ACCOUNT_MT5,
            {
                input: input,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const WITHDRAWAL_EWALLET_TRADING_ACCOUNT_MT5 = gql`
    mutation withdrawalEWalletTradingAccountMt5($input: mt5WithdrawalInput){
        withdrawalEWalletTradingAccountMt5(input: $input) {
            ... on TradingAccountMt5Transaction {
                _id
                dealId
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const withdrawalEWalletTradingAccountMt5 = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            WITHDRAWAL_EWALLET_TRADING_ACCOUNT_MT5,
            {
                input: input,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_TRADING_ACCOUNT_MT5_TRANSACTION = gql`
    query getTradingAccountMt5Transaction(
        $filter: tradingAccountMt5TransactionFilter
        $orderBy: String
        $limit: Int
        $offset: Int
        $startDate: String
        $endDate: String
        $dateKey: String
    ){
        getTradingAccountMt5Transaction(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
            startDate: $startDate
            endDate: $endDate
            dateKey: $dateKey
        ) {
            ... on TradingAccountMt5TransactionAry {
                data{
                    _id
                    dealId
                    dealIdDisplay
                    login
                    account{
                        _id
                        platformAccountId
                        accountId
                        platform
                    }
                    type
                    mt5Type
                    amount
                    status
                    depositType
                    withdrawalType
                    comment
                    sendEmailNotification
                    transactionType
                    createdAt
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const getTradingAccountMt5Transaction = async (filter, orderBy?, limit?, offset?, startDate?, endDate?, dateKey?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_TRADING_ACCOUNT_MT5_TRANSACTION,
            {
                filter: filter,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
                orderBy: orderBy || null,
                limit: limit || null,
                offset: offset || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_TRADING_CREDIT_TRANSACTION_MT5 = gql`
    mutation addTradingCreditTransactionMt5($input: TradingMt5CreditTransactionInput){
        addTradingCreditTransactionMt5(input: $input) {
            ... on TradingMt5CreditTransaction {
                _id
                dealId
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const addTradingCreditTransactionMt5 = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            ADD_TRADING_CREDIT_TRANSACTION_MT5,
            {
                input: input,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_TRADING_MT5_CREDIT_TRANSACTION = gql`
    query getTradingMt5CreditTransaction(
        $filter: TradingMt5CreditTransactionFilter
        $orderBy: String
        $limit: Int
        $offset: Int
        $startDate: String
        $endDate: String
        $dateKey: String
    ){
        getTradingMt5CreditTransaction(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
            startDate: $startDate
            endDate: $endDate
            dateKey: $dateKey
        ) {
            ... on TradingMt5CreditTransactionAry {
                data{
                    _id
                    account{
                        _id
                    }
                    dealIdDisplay
                    transactionType
                    type
                    amount
                    debt
                    status
                    comment
                    mt5Type
                    createdBy{
                        _id
                        username
                    }
                    expirationDatetime
                    createdAt
                    sendEmailNotification
                    payload
                    result
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const getTradingMt5CreditTransaction = async (filter, orderBy?, limit?, offset?, startDate?, endDate?, dateKey?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_TRADING_MT5_CREDIT_TRANSACTION,
            {
                filter: filter,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
                orderBy: orderBy || null,
                limit: limit || null,
                offset: offset || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UPDATE_TRADING_CREDIT_TRANSACTION_MT5 = gql`
    mutation updateTradingCreditTransactionMt5($id:String,$input: TradingMt5CreditTransactionUpdateInput){
        updateTradingCreditTransactionMt5(id:$id,input: $input) {
            ... on TradingMt5CreditTransaction {
                _id
                dealId
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const updateTradingCreditTransactionMt5 = async (id, input) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_TRADING_CREDIT_TRANSACTION_MT5,
            {
                id: id,
                input: input,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_TRADING_VOUCHER_TRANSACTION_MT5 = gql`
    mutation addTradingVoucherTransactionMt5($input: TradingMt5VoucherTransactionInput){
        addTradingVoucherTransactionMt5(input: $input) {
            ... on TradingMt5VoucherTransaction {
                _id
                dealId
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const addTradingVoucherTransactionMt5 = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            ADD_TRADING_VOUCHER_TRANSACTION_MT5,
            {
                input: input,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_TRADING_MT5_VOUCHER_TRANSACTION = gql`
    query getTradingMt5VoucherTransaction(
        $filter: TradingMt5VoucherTransactionFilter
        $orderBy: String
        $limit: Int
        $offset: Int
        $startDate: String
        $endDate: String
        $dateKey: String
    ){
        getTradingMt5VoucherTransaction(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
            startDate: $startDate
            endDate: $endDate
            dateKey: $dateKey
        ) {
            ... on TradingMt5VoucherTransactionAry {
                data{
                    _id
                    account{
                        _id
                    }
                    dealIdDisplay
                    transactionType
                    type
                    amount
                    debt
                    status
                    comment
                    mt5Type
                    createdBy{
                        _id
                        username
                    }
                    expirationDatetime
                    createdAt
                    sendEmailNotification
                    payload
                    result
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const getTradingMt5VoucherTransaction = async (filter, orderBy?, limit?, offset?, startDate?, endDate?, dateKey?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_TRADING_MT5_VOUCHER_TRANSACTION,
            {
                filter: filter,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
                orderBy: orderBy || null,
                limit: limit || null,
                offset: offset || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UPDATE_TRADING_VOUCHER_TRANSACTION_MT5 = gql`
    mutation updateTradingVoucherTransactionMt5($id:String,$input: TradingMt5VoucherTransactionUpdateInput){
        updateTradingVoucherTransactionMt5(id:$id,input: $input) {
            ... on TradingMt5VoucherTransaction {
                _id
                dealId
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const updateTradingVoucherTransactionMt5 = async (id, input) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_TRADING_VOUCHER_TRANSACTION_MT5,
            {
                id: id,
                input: input,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const SYNC_TRADING_ACCOUNT_TRANSACTION_MT5 = gql`
    mutation syncTradingAccountTransactionMt5($ids:[String]){
        syncTradingAccountTransactionMt5(ids:$ids)
    }
`;

const syncTradingAccountTransactionMt5 = async (ids) => {
    try {
        const execute = await ApolloClientQuery(
            SYNC_TRADING_ACCOUNT_TRANSACTION_MT5,
            {
                ids: ids,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const SYNC_TRADING_CREDIT_TRANSACTION_MT5 = gql`
    mutation syncTradingCreditTransactionMt5($ids:[String]){
        syncTradingCreditTransactionMt5(ids:$ids)
    }
`;

const syncTradingCreditTransactionMt5 = async (ids) => {
    try {
        const execute = await ApolloClientQuery(
            SYNC_TRADING_CREDIT_TRANSACTION_MT5,
            {
                ids: ids,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const SYNC_TRADING_VOUCHER_TRANSACTION_MT5 = gql`
    mutation syncTradingVoucherTransactionMt5($ids:[String]){
        syncTradingVoucherTransactionMt5(ids:$ids)
    }
`;

const syncTradingVoucherTransactionMt5 = async (ids) => {
    try {
        const execute = await ApolloClientQuery(
            SYNC_TRADING_VOUCHER_TRANSACTION_MT5,
            {
                ids: ids,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const CHANGE_PASSWORD_MT5 = gql`
    mutation changePasswordMt5($input:TradingAccountMt5ChangePasswordInput){
        changePasswordMt5(input:$input){
            ... on TradingAccountChangePasswordMt5 {
                masterPassword {
                    ... on TradingAccountChangePasswordMt5Status {
                        status
                    }
                    ... on BaseError {
                        errKey
                        errMsg
                        errObj
                    }
                }
                investorPassword {
                    ... on TradingAccountChangePasswordMt5Status {
                        status
                    }
                    ... on BaseError {
                        errKey
                        errMsg
                        errObj
                    }
                }
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const changePasswordMt5 = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            CHANGE_PASSWORD_MT5,
            {
                input: input,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const BATCH_UPDATE_TRADING_ACCOUNT_MT5 = gql`
    mutation batchUpdateTradingAccountMt5($ids:[String],$input:TradingAccountMt5UpdateInput){
        batchUpdateTradingAccountMt5(ids:$ids,input:$input){
            ... on BatchUpdateTradingAccountMt5 {
                result
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const batchUpdateTradingAccountMt5 = async (ids, input) => {
    try {
        const execute = await ApolloClientQuery(
            BATCH_UPDATE_TRADING_ACCOUNT_MT5,
            {
                ids: ids,
                input: input,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};


export {
    useCreateTradingAccountMt5Mutation,
    useCreateTradingAccountMt5MoneyManagerMutation,
    useCreateTradingAccountMt5InvestorMutation,
    useLinkTradingAccountMt5Mutation,
    useLinkTradingAccountMt5MoneyManagerMutation,
    useLinkTradingAccountMt5InvestorMutation,
    updateTradingAccountMt5,
    updateTradingAccountMt5Product,
    updateTradingAccountMt5Leverage,
    updateTradingAccountMt5SpreadGroup,
    depositManualTradingAccountMt5,
    depositEWalletTradingAccountMt5,
    depositNegativeBalanceProtectionTradingAccountMt5,
    withdrawalManualTradingAccountMt5,
    withdrawalEWalletTradingAccountMt5,
    getTradingAccountMt5Transaction,
    addTradingCreditTransactionMt5,
    getTradingMt5CreditTransaction,
    updateTradingCreditTransactionMt5,
    addTradingVoucherTransactionMt5,
    getTradingMt5VoucherTransaction,
    updateTradingVoucherTransactionMt5,
    syncTradingAccountTransactionMt5,
    syncTradingCreditTransactionMt5,
    syncTradingVoucherTransactionMt5,
    changePasswordMt5,
    batchUpdateTradingAccountMt5,
    getTradingAccountMt5Detail
}