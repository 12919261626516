import {ApolloClient, ApolloLink, HttpLink, InMemoryCache, split} from "@apollo/client";
import {getConfigByName} from "../../../config";
import {BRAND_NAME} from "../../../config/brandVariable";
import {getMainDefinition} from '@apollo/client/utilities';
import {OperationDefinitionNode} from 'graphql';

const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({headers = {}}) => ({
        headers: {
            ...headers,
            authorization: "Bearer " + (localStorage.getItem("ghost_a_t") || localStorage.getItem("a_t") || ""),
        },
    }));

    return forward(operation);
});

const baseInstrumentLink = new HttpLink({
    uri: `${getConfigByName(BRAND_NAME).instrumentsURL}`,
});
const baseLink = new HttpLink({
    uri: `${getConfigByName(BRAND_NAME).graphqlURL}`,
});
const loadLink = new HttpLink({
    uri: `${getConfigByName(BRAND_NAME).graphqlLoadURL}`,
});

const splitLink = split(
    (operation) => {
        const context = operation.getContext();
        const definition = getMainDefinition(operation.query);

        if ((definition as OperationDefinitionNode).kind === 'OperationDefinition') {
            const operationType = (definition as OperationDefinitionNode).operation;

            // Check if the context.endPoint is 'baseInstrumentLink'
            if (context.endPoint === 'baseInstrumentLink') {
                return true; // Use baseInstrumentLink
            }

            // Use baseLink for queries
            if (operationType === 'query') {
                return true; // Use baseLink
            }

            // Use loadLink for mutations
            if (operationType === 'mutation') {
                return false; // Use loadLink
            }
        }

        return false; // default to false for other cases
    },
    split(
        (operation) => operation.getContext().endPoint === 'baseInstrumentLink',
        baseInstrumentLink,
        baseLink // Use baseLink for queries
    ),
    loadLink
);

const apolloLogger = new ApolloLink((operation, forward) => {
    return forward(operation);
});

const client: any = new ApolloClient({
    // uri: `${graphqlURL}`,
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    merge(existing = [], incoming: any) {
                        return [...existing, ...incoming];
                    },
                },
            },
        },
    }),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: "cache-and-network",
        },
    },
    link: ApolloLink.from([authMiddleware, apolloLogger, splitLink]),
});

export default client;

const clientLoad: any = new ApolloClient({
    // uri: `${graphqlURL}`,
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    merge(existing = [], incoming: any) {
                        return [...existing, ...incoming];
                    },
                },
            },
        },
    }),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: "cache-and-network",
        },
    },
    link: ApolloLink.from([authMiddleware, apolloLogger, loadLink]),
});

export async function ApolloClientQuery(query, variables, isMutation = false) {
    try {

        let response;

        if (isMutation) {
            response = await clientLoad["mutate"]({
                ["mutation"]: query,
                variables,
            });
        } else {
            response = await client["query"]({
                ["query"]: query,
                variables,
            });
        }

        return response?.data;

    } catch (error) {
        console.log(error);
        throw error;
    }
}
