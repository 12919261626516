import {gql, useMutation, useQuery} from '@apollo/client'
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {useTranslation} from "react-i18next";
import {GET_EWALLET_RECORD, GET_EWALLET_STATEMENT_WITH_TASK} from "./eWallet";
import {GET_USER_BY_ID} from "./user";

const GET_EWALLET_ADJUSTMENT_COUNT = gql`
  query getEWalletAdjustmentCount($filter: EWalletAdjustmentSearchInput) {
    getEWalletAdjustmentCount(filter: $filter)
  }
`;

const getEWalletAdjustmentCount = ({filter}) => {
    try {
        const {loading, error, data} = useQuery(GET_EWALLET_ADJUSTMENT_COUNT, {
            variables: {
                filter: {
                    transactionNo: filter?.transactionNo || null,
                    type: filter?.type || null,
                    description: filter?.description || null,
                    remark: filter?.remark || null
                }
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, data}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const GET_EWALLET_ADJUSTMENT_BY_ID = gql`
  query getEWalletAdjustmentByID($id: String!) {
    getEWalletAdjustmentByID(id: $id){
      ... on EWalletAdjustment {
        _id
        id
        transactionNo
        transactionDate
        user {username}
        type
        amount
        description
        remark
        createdBy {username}
        updatedBy {username}
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getEWalletAdjustmentById = (id) => {
    try {
        const {loading, error, data} = useQuery(GET_EWALLET_ADJUSTMENT_BY_ID, {
            variables: {
                id: id
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, data}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}


const GET_EWALLET_ADJUSTMENT = gql`
  query getEWalletAdjustment($filter: EWalletAdjustmentSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
    getEWalletAdjustment(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy){
       ... on EWalletAdjustmentArray {
        data {
          _id
          id
          transactionNo
          transactionDate
          user {username}
          type
          amount
          description
          remark
          createdBy {username}
          updatedBy {username}
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getEWalletAdjustment = ({filter}, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data} = useQuery(GET_EWALLET_ADJUSTMENT, {
            variables: {
                filter: {
                    transactionNo: filter?.transactionNo || null,
                    type: filter?.type || null,
                    description: filter?.description || null,
                    remark: filter?.remark || null,
                },
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, data}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const ADD_EWALLET_ADJUSTMENT = gql`
  mutation addEWalletAdjustment($input: EWalletAdjustmentInput) {
    addEWalletAdjustment(input: $input){
      ... on EWalletAdjustment {
        _id
        id
        transactionNo
        transactionDate
        user {username}
        type
        amount
        description
        remark
        createdBy {username}
        updatedBy {username}
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useAddEWalletAdjustmentMutation = (): { addEWalletAdjustment: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [addEWalletAdjustmentMutation, {data, loading, error}] = useMutation(ADD_EWALLET_ADJUSTMENT);

        const addEWalletAdjustment = (input) => {
            return addEWalletAdjustmentMutation({
                variables: {
                    input: {
                        user: input.user || null,
                        type: input.type || null,
                        amount: input.amount || null,
                        description: input.description || null,
                        remark: input.remark || null,
                        comment: input?.comment || null
                    },
                },
                update(cache, {data: {addEWalletAdjustment}}) {
                    const typename = addEWalletAdjustment?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(addEWalletAdjustment?.errKey),
                            key: "addEWalletAdjustment",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(addEWalletAdjustment?.msg),
                            key: "addEWalletAdjustment",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Add E-Wallet Adjustment Successfully"),
                            key: "addEWalletAdjustment",
                        });
                    }
                },
                refetchQueries: [GET_EWALLET_STATEMENT_WITH_TASK,GET_USER_BY_ID]
            });
        };

        return {addEWalletAdjustment, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {addEWalletAdjustment: null, data: null, loading: false, error: err};
    }
}

const UPDATE_EWALLET_ADJUSTMENT = gql`
mutation updateEWalletAdjustment($id: String!, $input: EWalletAdjustmentInput) {
    updateEWalletAdjustment(id: $id, input: $input){
      ... on EWalletAdjustment {
        _id
        id
        transactionNo
        transactionDate
        user {username}
        type
        amount
        description
        remark
        createdBy {username}
        updatedBy {username}
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useUpdateEWalletAdjustmentMutation = (): { updateEWalletAdjustment: any, data: any, loading: boolean, error: any } => {
    try {
        const [updateEWalletAdjustmentMutation, {data, loading, error}] = useMutation(UPDATE_EWALLET_ADJUSTMENT);

        const updateEWalletAdjustment = (id, input) => {
            return updateEWalletAdjustmentMutation({
                variables: {
                    id: id,
                    input: {
                        transactionNo: input.transactionNo || null,
                        transactionDate: input.transactionDate || null,
                        user: input.user || null,
                        type: input.type || null,
                        amount: input.amount || null,
                        description: input.description || null,
                        remark: input.remark || null
                    },
                },
            });
        };

        return {updateEWalletAdjustment, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {updateEWalletAdjustment: null, data: null, loading: false, error: err};
    }
}


const DELETE_EWALLET_ADJUSTMENT = gql`
  mutation deleteEWalletAdjustment($id: String!){
    deleteEWalletAdjustment(id: $id){
      ... on EWalletAdjustment {
        _id
        id
        isDeleted
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useDeleteBankInformationMutation = (): { deleteBankInformation: any, data: any, loading: boolean, error: any } => {
    try {
        const [deleteBankInformationMutation, {data, loading, error}] = useMutation(DELETE_EWALLET_ADJUSTMENT);

        const deleteBankInformation = (id) => {
            return deleteBankInformationMutation({
                variables: {
                    id: id,
                },
            });
        };

        return {deleteBankInformation, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {deleteBankInformation: null, data: null, loading: false, error: err};
    }
}

const BATCH_EWALLET_ADJUSTMENT = gql`
mutation batchEWalletAdjustment($userid: [String], $input: EWalletAdjustmentInput) {
    batchEWalletAdjustment(userid: $userid, input: $input){
      ... on EWalletAdjustmentBatch {
        status
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useBatchEWalletAdjustmentMutation = (): { batchEWalletAdjustment: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [batchEWalletAdjustmentMutation, {data, loading, error}] = useMutation(BATCH_EWALLET_ADJUSTMENT);

        const batchEWalletAdjustment = (userid, input) => {
            return batchEWalletAdjustmentMutation({
                variables: {
                    userid: userid,
                    input: input,
                },
                update(cache, {data: {batchEWalletAdjustment}}) {
                    const typename = batchEWalletAdjustment?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(batchEWalletAdjustment?.errKey),
                            key: "batchEWalletAdjustment",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(batchEWalletAdjustment?.msg),
                            key: "batchEWalletAdjustment",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Batch E-Wallet Adjustment Successfully"),
                            key: "batchEWalletAdjustment",
                        });
                    }
                },
            });
        };

        return {batchEWalletAdjustment, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {batchEWalletAdjustment: null, data: null, loading: false, error: err};
    }
}

const BATCH_IMPORT_EWALLET_ADJUSTMENT = gql`
mutation batchImportEWalletAdjustment($platform: String, $mode: String, $uniqueField: [String], $input: [EWalletImportAdjustmentInput]) {
    batchImportEWalletAdjustment(platform: $platform, mode: $mode, uniqueField: $uniqueField, input: $input){
      ... on EWalletAdjustmentBatch {
        status
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useBatchImportEWalletAdjustmentMutation = (): { batchImportEWalletAdjustment: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [batchImportEWalletAdjustmentMutation, {data, loading, error}] = useMutation(BATCH_IMPORT_EWALLET_ADJUSTMENT);

        const batchImportEWalletAdjustment = (platform, mode, uniqueField, input) => {
            return batchImportEWalletAdjustmentMutation({
                variables: {
                    platform: platform,
                    mode: mode,
                    uniqueField: uniqueField,
                    input: input,
                },
                update(cache, {data: {batchImportEWalletAdjustment}}) {
                    const typename = batchImportEWalletAdjustment?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(batchImportEWalletAdjustment?.errKey),
                            key: "batchImportEWalletAdjustment",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(batchImportEWalletAdjustment?.msg),
                            key: "batchImportEWalletAdjustment",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Import E-Wallet Adjustment Successfully"),
                            key: "batchImportEWalletAdjustment",
                        });
                    }
                },
            });
        };

        return {batchImportEWalletAdjustment, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {batchImportEWalletAdjustment: null, data: null, loading: false, error: err};
    }
}


export {
    getEWalletAdjustmentCount,
    getEWalletAdjustmentById,
    getEWalletAdjustment,
    useAddEWalletAdjustmentMutation,
    useUpdateEWalletAdjustmentMutation,
    useDeleteBankInformationMutation,
    useBatchEWalletAdjustmentMutation,
    useBatchImportEWalletAdjustmentMutation
}