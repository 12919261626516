import {gql} from "@apollo/client"
import {ApolloClientQuery} from "./_apolloClient"

const GET_MAM_PAMM_MODULE_SETTING = gql`
    query getMamPammModuleSetting {
        getMamPammModuleSetting {
            ... on MamPammModuleSetting {
                autoApproveDepositInTask
                autoApproveWithdrawalInTask
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`

const getMamPammModuleSetting = async () => {
    try {
        const execute = await ApolloClientQuery(
            GET_MAM_PAMM_MODULE_SETTING,
            {
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        )

        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const UPDATE_MAM_PAMM_MODULE_SETTING = gql`
    mutation updateMamPammModuleSetting($input: MamPammModuleSettingInput) {
        updateMamPammModuleSetting(input: $input) {
            ... on MamPammModuleSetting {
                autoApproveDepositInTask
                autoApproveWithdrawalInTask
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`

const updateMamPammModuleSetting = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_MAM_PAMM_MODULE_SETTING,
            {
                input: {
                    autoApproveDepositInTask: input.autoApproveDepositInTask || false,
                    autoApproveWithdrawalInTask: input.autoApproveWithdrawalInTask || false,
                },
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        )

        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

export {getMamPammModuleSetting, updateMamPammModuleSetting}
