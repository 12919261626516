import React from "react";
import {getPspCurrencyList} from "../../../api/graphql/_common";
import EWalletSetting_1 from "./view/layout1";

const EWalletSetting = () => {
    let layout = 1
    let component
    const {data} = getPspCurrencyList()

    switch (layout) {
        case 1:
            component = (
                <EWalletSetting_1 />
            );
            break;

        default:
            component = (
                <EWalletSetting_1 />
            );
            break;
    }

    return component;
}

export default EWalletSetting