import React, {useState} from "react";
import TableAntDesign from "../../../../components/table/tableAntDesign";
import {useTranslation} from "react-i18next";
import {deleteHardEmailSendDraft} from "../../../../api/graphql/message";
import useAuthorize from "../../../../_common/authorize";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../recoil_state";
import {Button, Empty, Popconfirm, Segmented} from "antd";
import {MessageDraftTableColumns, NotificationDraftTableColumns} from "../../function/tableColumns";
import {deletePushNotificationDraft} from "../../../../api/graphql/pushNotification";

const Trash = ({
                   messagePermission,
                   emailDraftDeletedTotal,
                   emailDraftDeletedProps,
                   setEmailDraftDeletedProps,
                   emailDraftDeletedData,
                   emailTemplate,
                   fetchEmailDraftDeletedData,
                   pushNotificationDraftDeletedTotal,
                   pushNotificationDraftDeletedProps,
                   setPushNotificationDraftDeletedProps,
                   pushNotificationDraftDeletedData,
                   pushNotificationTemplate,
                   fetchPushNotificationDraftDeletedData
               }) => {
    const {t} = useTranslation()
    const {baseErrorChecking, systemErrorNotification} = useAuthorize();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [type, setType] = useState<any>(1)
    const [currentPage, setCurrentPage] = useState(1)

    const removeDeleted = async (id) => {
        switch (type) {
            case 1:
                try {
                    const {deleteHardEmailSendDraft: res} = await deleteHardEmailSendDraft(id)

                    if (res?.__typename === "EmailSendDraft") {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("message.record_deleted"),
                            key: "messagesNotification",
                        });

                        fetchEmailDraftDeletedData()
                    } else if (res?.__typename === "BaseError") {
                        baseErrorChecking(res)
                    }
                } catch (e) {
                    systemErrorNotification()
                }
                break
            case 2:
                try {
                    const {deletePushNotificationDraft: res} = await deletePushNotificationDraft(id)

                    if (res?.__typename === "EmailSendDraft") {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("message.record_deleted"),
                            key: "messagesNotification",
                        });

                        fetchPushNotificationDraftDeletedData()
                    } else if (res?.__typename === "BaseError") {
                        baseErrorChecking(res)
                    }
                } catch (e) {
                    systemErrorNotification()
                }
                break
        }
    }

    const onChangePageHandler = (page, pageSize) => {
        setCurrentPage(page)
        switch (type) {
            case 1:
                setEmailDraftDeletedProps(prev => ({...prev, limit: pageSize, offset: prev.limit * (page - 1)}))
                break
            case 2:
                setPushNotificationDraftDeletedProps(prev => ({...prev, limit: pageSize, offset: pageSize * (page - 1)}))
                break
        }
    }

    const segmentedOptions = [
        {label: "Email", value: 1},
        {label: "Notification", value: 2}
    ]

    return (
        <div id={"trash"}>
            <Segmented className={"margin-bottom-0-5"} options={segmentedOptions} onChange={(value) => setType(value)}/>
            {type === 1 && (
                <TableAntDesign
                    data={emailDraftDeletedData}
                    actionPermission={messagePermission?.send || messagePermission?.delete}
                    columns={[...MessageDraftTableColumns(), {
                        label: t("common.action"),
                        key: "action",
                        render: (_, record) => {
                            return (
                                <Popconfirm title={t("message.delete_record_popconfirm")}
                                            onConfirm={() => removeDeleted(record?._id)}>
                                    <Button size={"small"} danger>{t("common.delete")}</Button>
                                </Popconfirm>
                            )
                        }
                    }]}
                    pagination={{
                        pageSize: emailDraftDeletedProps.limit,
                        showSizeChanger: true,
                        total: emailDraftDeletedTotal,
                        current: currentPage,
                        onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                        showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                    }}
                    tableName={"Draft"}
                />
            )}
            {type === 2 && (
                <TableAntDesign
                    data={pushNotificationDraftDeletedData}
                    actionPermission={messagePermission?.send || messagePermission?.delete}
                    columns={[...NotificationDraftTableColumns(), {
                        label: t("common.action"),
                        key: "action",
                        render: (_, record) => {
                            return (
                                <Popconfirm title={t("message.delete_record_popconfirm")}
                                            onConfirm={() => removeDeleted(record?._id)}>
                                    <Button size={"small"} danger>{t("common.delete")}</Button>
                                </Popconfirm>
                            )
                        }
                    }]}
                    pagination={{
                        pageSize: pushNotificationDraftDeletedProps.limit,
                        showSizeChanger: true,
                        total: pushNotificationDraftDeletedTotal,
                        current: currentPage,
                        onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                        showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                    }}
                    tableName={"Draft"}
                />
            )}
        </div>
    )
}

export default Trash