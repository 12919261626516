import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const InvalidPermisson_1: React.FC = () => {
  const navigate = useNavigate();

  const navigateToDashboard = () => {
    navigate(`/`);
  };
  return (
    <Result
      status="warning"
      title="Access denied"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Button type="primary" key="console" onClick={navigateToDashboard}>
          Back Home
        </Button>
      }
    />
  );
};

export default InvalidPermisson_1;
