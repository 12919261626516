import React, {useEffect, useState} from "react";
import TaskTable_1 from "./component/taskTable";
import {useTranslation} from "react-i18next";
import {Button, DatePicker, Form, Row, Select, Space, Tag} from "antd";
import {useForm} from "react-hook-form";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
    crmTaskArchivedMonthFilterState,
    crmTaskArchivedTableFilterState,
    crmTaskDateKeySearchState,
    crmTaskEndTimeSearchState,
    crmTaskFilterState,
    crmTaskLimitState,
    crmTaskNetworkUserIdState,
    crmTaskOffsetState,
    crmTaskOrderBySearchState,
    crmTaskStartTimeSearchState,
    crmTaskTableFilterState
} from "../../state";
import EditTableUserPreferenceButton from "../../../../components/table/function/editTableUserPreferenceButton";
import {ColumnTask} from "../../function/tableColumn";
import moment from "moment";
import {SyncOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import {useIsMobile} from "../../../../_common/layout";
import {userOptionsState} from "../../../users/_common/state";
import {formatDateTimeTimezone} from "../../../../function/_common";


const Task_1 = (props) => {
    const {
        data,
        exportData,
        loading,
        total,
        moduleListing,
        subModuleListing,
        refetch,
        refetchArchived,
        dataArchived,
        totalArchived,
        loadingArchived,
        isClearFilterVisible,
        resetFilters
    } = props
    const {t} = useTranslation()
    const [moduleList, setModuleList] = useState([])
    const [subModuleList, setSubModuleList] = useState([])
    const [taskExportData, setTaskExportData] = useState([])
    const [listType, setListType] = useState("active")
    const [crmTaskFilter, setCrmTaskFilter] = useRecoilState<any>(crmTaskFilterState)
    const isMobile = useIsMobile(768);

    const setCrmTaskStartTimeSearch = useSetRecoilState(crmTaskStartTimeSearchState);
    const setCrmTaskEndTimeSearch = useSetRecoilState(crmTaskEndTimeSearchState);
    const setCrmTaskDateKeySearch = useSetRecoilState(crmTaskDateKeySearchState);
    const setCrmTaskOrderBySearch = useSetRecoilState(crmTaskOrderBySearchState);
    const setCrmTaskNetworkUserId = useSetRecoilState(crmTaskNetworkUserIdState)
    const [crmTaskLimit, setCrmTaskLimit] = useRecoilState(crmTaskLimitState)
    const setCrmTaskOffset = useSetRecoilState(crmTaskOffsetState)
    const userOptions = useRecoilValue(userOptionsState);

    const [crmTaskTableFilter, setCrmTaskTableFilter] = useRecoilState<any>(crmTaskTableFilterState)
    const [crmTaskArchivedTableFilter, setCrmTaskArchivedTableFilter] = useRecoilState<any>(crmTaskArchivedTableFilterState)
    const [crmTaskArchivedMonthFilter, setCrmTaskArchivedMonthFilter] = useRecoilState(crmTaskArchivedMonthFilterState)

    const column = ColumnTask()
    const columnTask = [...column, {
        title: t("task.status"),
        dataIndex: "statusList",
        key: "statusList",
        filters: listType === "active" ? [
            {text: "Processing", value: "processing"},
            {text: "Pending", value: "pending"},
            {text: "Pending Approval", value: "pending approval"},
            {text: "Pending Email Verification", value: "pendingEmailVerification"},
        ] : [
            {text: "Approved", value: "approved"},
            {text: "Rejected", value: "rejected"},
            {text: "Failed", value: "failed"},
            {text: "Rejected By Bank", value: "rejectedByBank"},
            {text: "Expired", value: "expired"},
            {text: "Cancelled", value: "cancelled"},
        ],
        render: (text, record) => {
            let color;
            let displayText = text
            if (text === "approved" && record?.subModuleName === "E-Wallet Withdrawal" && !record?.description?.includes("Approved and processed")) {
                displayText = "approved & processing"
            }

            const capitalizedText = displayText?.charAt(0)?.toUpperCase() + displayText?.slice(1);

            switch (displayText) {
                case "pending":
                    color = "yellow";
                    break;
                case "pending approval":
                    color = "orange";
                    break;
                case "approved":
                case "approved & processing":
                    color = "green";
                    break;
                default:
                    color = "red";
                    break;
            }

            return (
                <Tag bordered={false} color={color}>
                    {capitalizedText}
                </Tag>
            );
        },
    }]

    const tableName = "Task"
    const server = "crmTask"
    const labelCol = {span: 8, offset: 0};

    const processSubModuleListing = async () => {
        let options: any
        if (subModuleListing) {

            options = await Promise.all(subModuleListing
                .filter((list) => list !== null) // Filter out null values
                .map((list) => ({
                    label: t(list),
                    value: list,
                })))
            options = [
                {
                    label: "All",
                    value: null,
                },
                ...options
            ]
            setSubModuleList(options)
        }
    }

    const processModuleListing = async () => {
        let options: any
        if (moduleListing) {
            options = await Promise.all(moduleListing.map((list) => {
                return {
                    label: t(list),
                    value: list,
                }
            }))
            options = [
                {
                    label: "All",
                    value: null,
                },
                ...options
            ]
            setModuleList(options)
        }
    }

    const processData = async () => {
        if (exportData) {
            const latestData: any = await Promise.all(exportData?.map((task) => {
                return {
                    ...task,
                    status: task?.isClaimed === true && task?.status === "pending" ? "pending approval" : task?.status,
                    actionType: t("crmTask." + task?.actionType),
                    requestedBy: task?.requestedBy?.username,
                    claimBy: task?.claimBy?.username,
                    approvedBy: task?.approvedBy?.username,
                    approvedDatetime: formatDateTimeTimezone(task?.approvedDatetime) || "-",
                    createdAt: formatDateTimeTimezone(task?.createdAt) || "-"
                }
            }))

            setTaskExportData(latestData)
        }

    }

    const changeDateMonth = (e) => {
        setCrmTaskArchivedMonthFilter(moment(e?.$d)?.format("YYYY-MM"))
    }

    const form = useForm({
        mode: "all",
        defaultValues: {
            mainModule: null,
            subModule: null,
        }
    });

    const activeTab = () => {
        setListType("active")
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth());

        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
        setCrmTaskArchivedMonthFilter(`${year}-${month}`)
    }

    useEffect(() => {
        processModuleListing()
    }, [moduleListing])

    useEffect(() => {
        processSubModuleListing()
    }, [subModuleListing])

    useEffect(() => {
        processData()
    }, [exportData])


    return (
        <div id={"task"}>
            <h1>
                {t("module.task")}
                {
                    isClearFilterVisible &&
                    <Button className={"margin-left-0-5"} size={"small"} onClick={() => resetFilters()}>
                        {t("common.clearFilter")}
                    </Button>
                }
            </h1>
            <Row justify={"space-between"} wrap>
                <Space wrap align={"start"}>
                    <Form.Item
                        name={"mainModule"}
                        label={t("task.module")}
                        labelAlign={"left"}
                        style={{width: 300}}
                        labelCol={{span: 7}}
                    >
                        <Select
                            placeholder={t("task.choose_a_module")}
                            options={moduleList || []}
                            showSearch={true}
                            onChange={(e) => {
                                setCrmTaskFilter({
                                    ...crmTaskFilter,
                                    mainModuleName: e
                                })
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"subModule"}
                        label={t("task.sub_module")}
                        labelAlign={"left"}
                        style={{width: 300}}
                        labelCol={{span: 7}}
                    >
                        <Select
                            placeholder={t("task.choose_a_sub_module")}
                            options={subModuleList || []}
                            showSearch={true}
                            onChange={(e) => {
                                setCrmTaskFilter({
                                    ...crmTaskFilter,
                                    subModuleName: e
                                })
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"networkUserId"}
                        labelAlign={"left"}
                        style={{width: 300}}
                    >
                        <Select
                            placeholder={t("task.filter_user_network")}
                            options={userOptions || []}
                            showSearch={true}
                            allowClear
                            optionFilterProp={"label"}
                            optionLabelProp="selectedLabel"
                            onChange={(e) => setCrmTaskNetworkUserId(e)}
                        />
                    </Form.Item>
                    {
                        listType === "archived" && (
                            <DatePicker.MonthPicker defaultValue={dayjs(crmTaskArchivedMonthFilter)}
                                                    style={{marginTop: isMobile ? 5 : 0}} onChange={changeDateMonth}
                                                    disabledDate={current => {
                                                        const threeMonthsAgo = dayjs().subtract(3, 'months');
                                                        if (current && current.isBefore(threeMonthsAgo.startOf('month'), 'day')) {
                                                            return true;
                                                        }
                                                        const lastDayOfCurrentMonth = dayjs().endOf('month');
                                                        if (current && current.isAfter(lastDayOfCurrentMonth, 'day')) {
                                                            return true;
                                                        }
                                                        return false;
                                                    }}/>

                        )
                    }
                </Space>
                <Space className={isMobile ? "margin-top-0-75" : ""}>
                    {/*<ExportTableButton*/}
                    {/*    tableData={taskExportData}*/}
                    {/*    tableColumn={columnTask}*/}
                    {/*    tableName={tableName}*/}
                    {/*    server={null}*/}
                    {/*    loading={exportDataLoading}*/}
                    {/*/>*/}
                    <Button onClick={() => {
                        if (listType === "active") {
                            setListType("archived");
                            setCrmTaskOffset(0)
                            setCrmTaskLimit(20)
                        } else {
                            activeTab();
                            setCrmTaskOffset(0)
                            setCrmTaskLimit(20)
                        }
                    }}>
                        {/*{listType === "active" ? t("task.view_history") : t("task.view_action_list")}*/}
                        {listType === "active" ? t("History List") : t("Pending List")}
                    </Button>
                    <EditTableUserPreferenceButton
                        tableName={tableName}
                        tableColumn={columnTask}
                        displayTableName={t("module.task")}
                    />
                    <Button
                        type={"link"}
                        loading={loading}
                        onClick={listType === "active" ? refetch : refetchArchived}
                        icon={<SyncOutlined/>}
                    />
                </Space>
            </Row>
            {
                listType === "active" ? (
                    <TaskTable_1 data={data} loading={loading} tableName={tableName} server={server} total={total}
                                 setCrmTaskEndTimeSearch={setCrmTaskEndTimeSearch}
                                 refetch={refetch}
                                 setCrmTaskDateKeySearch={setCrmTaskDateKeySearch}
                                 setCrmTaskOrderBySearch={setCrmTaskOrderBySearch}
                                 setCrmTaskTableFilter={setCrmTaskTableFilter}
                                 crmTaskLimit={crmTaskLimit}
                                 setCrmTaskLimit={setCrmTaskLimit}
                                 setCrmTaskOffset={setCrmTaskOffset}
                                 setCrmTaskStartTimeSearch={setCrmTaskStartTimeSearch}
                                 crmTaskTableFilter={crmTaskTableFilter}
                                 listType={listType}
                                 columnTask={columnTask}
                    />
                ) : (
                    <TaskTable_1 data={dataArchived} loading={loadingArchived} tableName={tableName} server={server}
                                 total={totalArchived}
                                 refetch={refetch}
                                 setCrmTaskEndTimeSearch={setCrmTaskEndTimeSearch}
                                 setCrmTaskDateKeySearch={setCrmTaskDateKeySearch}
                                 setCrmTaskOrderBySearch={setCrmTaskOrderBySearch}
                                 setCrmTaskTableFilter={setCrmTaskArchivedTableFilter}
                                 crmTaskLimit={crmTaskLimit}
                                 setCrmTaskLimit={setCrmTaskLimit}
                                 setCrmTaskOffset={setCrmTaskOffset}
                                 setCrmTaskStartTimeSearch={setCrmTaskStartTimeSearch}
                                 crmTaskTableFilter={crmTaskArchivedTableFilter}
                                 listType={listType}
                                 columnTask={columnTask}
                    />
                )
            }

        </div>
    )
}

export default Task_1;

