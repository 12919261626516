import React, {useEffect, useState} from "react";
import {Col, Input, InputNumber, Row, Select} from "antd";
import {useTranslation} from "react-i18next";
import {
    useApproveCrmTaskMutation,
    useRejectCrmTaskMutation,
    useUpdateCrmTaskMutation
} from "../../../../../../../api/graphql/crmTask";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import {getUserById} from "../../../../../../../api/graphql/user";
import TaskClientDetails from "../../clientDetails";
import {formatMoney} from "../../../../../../accounts/tradingAccount/detail/function";
import {getPspSettingCurrency} from "../../../../../../../api/graphql/pspSettingCurrency";
import {getPspSetting} from "../../../../../../../api/graphql/pspSetting";
import useAuthorize from "../../../../../../../_common/authorize";
import {calculateWithdrawal} from "../../../../../../../api/graphql/eWalletWithdrawal";
import {getBankInformation} from "../../../../../../../api/graphql/bankInformation";
import UploadItem from "../../../../../../../components/form/uploadItem";


function EwalletWithdrawalLayout_1(props) {
    const {item, mode} = props
    const {TextArea} = Input;
    const [approveBtnLoading, setApproveBtnLoading] = useState(false)
    const [rejectBtnLoading, setRejectBtnLoading] = useState(false)
    const withdrawalObj = {
        psp: item?.payload?.input?.psp,
        currency: item?.payload?.input?.currency,
        paymentMethod: item?.payload?.input?.paymentMethod ? item?.payload?.input?.paymentMethod :
            item?.payload?.input?.withdrawalType === "bankwire" ? item?.payload?.input?.psp :
                item?.payload?.input?.withdrawalType === "crypto" ? item?.payload?.input?.currency : "-",
        amount: item?.payload?.input?.amount,
        withdrawalType: item?.payload?.input?.withdrawalType
    }
    const [delayObj, setDelayObj] = useState<any>(withdrawalObj)
    const [eWalletWithdrawalObj, setEWalletWithdrawalObj] = useState(withdrawalObj)
    const [bankInSlip, setBankInSlip] = useState<any>(item?.result?.bankInSlipFileList ? {fileList: item?.result?.bankInSlipFileList} : {})
    const [pspCurrency, setPspCurrency] = useState(item?.payload?.input?.currency || "")
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {data} = getPspSetting({filter: {name: item?.payload?.input?.psp, taskView: true}});
    const {pspCurrencyData} = getPspSettingCurrency({
        filter: {pspSetting: data?.getPspSetting?.data[0]?._id, taskView: true},
    });
    const [comment, setComment] = useState(item?.result?.comment ? item?.result?.comment : item?.payload?.input?.comment)
    const {data: bankInformation} = getBankInformation({filter: {id: item?.payload?.input?.bankInformationId}})
    const {approveCrmTask} = useApproveCrmTaskMutation()
    const {rejectCrmTask} = useRejectCrmTaskMutation()
    const {updateCrmTask} = useUpdateCrmTaskMutation()
    const {systemErrorNotification} = useAuthorize()
    const {withdrawalCalculation, loading} = calculateWithdrawal({input: eWalletWithdrawalObj})

    // Find bank name from psp setting currency available bank
    const formDataBankName = bankInformation?.getBankInformation?.data[0]?.formData?.bank
    const currency = item?.result?.currency ? item?.result?.currency : item?.payload?.input?.currency
    const bankName = pspCurrencyData?.getPspSettingCurrency?.data?.find(d => d?.currency === currency)?.availableBank?.find(d => d?.bankCode === formDataBankName)?.bankName

    useEffect(() => {
        let temp = {
            ...withdrawalCalculation?.calculateWithdrawal,
            comment: comment,
            bankInSlip: bankInSlip?.fileList?.length > 0 ? bankInSlip?.fileList[0]?.response[0]?.link : "",
            bankInSlipFileList: bankInSlip?.fileList,
        }
        props.setUpdatedPayload && props.setUpdatedPayload(temp)
    }, [withdrawalCalculation])

    useEffect(() => {
        setEWalletWithdrawalObj({
            psp: item?.payload?.input?.psp,
            currency: item?.payload?.input?.currency,
            paymentMethod: item?.payload?.input?.paymentMethod ? item?.payload?.input?.paymentMethod :
                item?.payload?.input?.withdrawalType === "bankwire" ? item?.payload?.input?.psp :
                    item?.payload?.input?.withdrawalType === "crypto" ? item?.payload?.input?.currency : "-",
            amount: item?.payload?.input?.amount,
            withdrawalType: item?.payload?.input?.withdrawalType
        })
    }, [item])

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            return setEWalletWithdrawalObj(delayObj)
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [delayObj]);

    const ContainerHeight = 600;
    const {t} = useTranslation()
    const approveTaskAction = async () => {
        try {
            await setApproveBtnLoading(true)
            if (!pspCurrency || pspCurrency.trim() === "") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: "please select a valid currency",
                    key: "approveCrmTask",
                });
                setApproveBtnLoading(false)
                return false
            }
            const response = await approveCrmTask(item?._id, {pspCurrency: pspCurrency})
            if (response?.data?.approveCrmTask?.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("task.task_approved"),
                    key: "approveCrmTask",
                });
                setApproveBtnLoading(false)
            } else if (response?.data?.approveCrmTask?.__typename === "BaseError") {

                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.data?.approveCrmTask?.errKey),
                    key: "approveCrmTask",
                });
                setApproveBtnLoading(false)
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.data?.approveCrmTask?.msg),
                    key: "approveCrmTask",
                });
                setApproveBtnLoading(false)
            }
        } catch (e) {
            systemErrorNotification()
            setApproveBtnLoading(false)
        }
    }
    const rejectTaskAction = async () => {
        try {
            await setRejectBtnLoading(true)
            const response = await rejectCrmTask(item?._id)
            if (response?.data?.rejectCrmTask?.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("task.task_rejected"),
                    key: "rejectCrmTask",
                });
                setRejectBtnLoading(false)
            } else if (response?.data?.rejectCrmTask?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response.data?.rejectCrmTask?.errKey),
                    key: "rejectCrmTask",
                });
                setRejectBtnLoading(false)
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response.data?.rejectCrmTask?.msg),
                    key: "rejectCrmTask",
                });
                setRejectBtnLoading(false)
            }
        } catch (e) {
            systemErrorNotification()
            setRejectBtnLoading(false)
        }
    }

    const userDetail = getUserById(item?.payload?.input?.user)
    const uploadOnChange = (e) => {
        try {
            console.log(e)
            if (e?.file?.status === "uploading") {
                setBankInSlip(prev => {
                    return {
                        ...prev,
                        fileList: e?.fileList
                    }
                })
                props.setUpdatedPayload && props.setUpdatedPayload(prev => {
                    return {...prev, bankInSlip: "", bankInSlipFileList: []}
                })
            }
            if (e?.file?.status === "done") {
                setBankInSlip(prev => {
                    return {
                        ...prev,
                        fileList: e?.fileList,
                        path: e?.file?.response[0]?.link
                    }
                })
                props.setUpdatedPayload && props.setUpdatedPayload(prev => {
                    return {...prev, bankInSlip: e?.file?.response[0]?.link, bankInSlipFileList: e?.fileList}
                })

                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: `${e?.file?.name} file uploaded successfully`,
                    key: "updateDesk",
                });
            } else if (e?.file?.status === "error") {
                setBankInSlip([])

                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: `${e?.file?.name} file upload failed. - ${e?.file?.response}`,
                    key: "updateDesk",
                });
                props.setUpdatedPayload && props.setUpdatedPayload(prev => {
                    return {...prev, bankInSlip: "", bankInSlipFileList: []}
                })
            }
        } catch (e) {
            systemErrorNotification();
        }
    };

    return (
        <div id={"eWalletLayout"}>
            <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                    {
                        userDetail?.data?.getUserByID && (
                            <TaskClientDetails data={userDetail?.data?.getUserByID}/>
                        )
                    }
                </Col>
                <Col xs={24} md={12}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <h3>{t("task.ewallet_withdrawal")}</h3>
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.transaction_no")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {item?.payload?.input?.transactionNo}
                        </Col>
                        {
                            item?.payload?.input?.withdrawalType === "bankwire" &&
                            <>
                                <Col xs={12} lg={8}>
                                    Bank In Slip:
                                </Col>
                                <Col xs={12} lg={16}>
                                    <UploadItem
                                        name="eWalletBankInSlip"
                                        mode={"ewallet"}
                                        listType={"picture-card"}
                                        fileList={bankInSlip?.fileList}
                                        onChange={(e: any) => {
                                            uploadOnChange(e)
                                        }}
                                        onRemove={(e) => {
                                            setBankInSlip([])
                                        }}
                                        disabled={mode !== "verify"}
                                    />
                                </Col>
                            </>
                        }
                        <Col xs={12} lg={8}>
                            {t("task.display_name")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {data?.getPspSetting?.data[0]?.displayName}
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.payment_method")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {
                                mode === "verify" &&
                                (withdrawalCalculation && withdrawalCalculation?.calculateWithdrawal && withdrawalCalculation?.calculateWithdrawal?.paymentMethod) ?
                                    (withdrawalCalculation?.calculateWithdrawal?.paymentMethod) :
                                    item?.result?.paymentMethod ?
                                        (item?.result?.paymentMethod) :
                                        item?.payload?.input?.paymentMethod ?
                                            (item?.payload?.input?.paymentMethod) :
                                            "-"
                            }
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.currency")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {(mode === "verify" && (item?.payload?.input?.withdrawalType === "bankwire")) ?
                                <Select
                                    style={{
                                        width: 120,
                                    }}
                                    onChange={e => {
                                        setPspCurrency(e)
                                        setDelayObj({...withdrawalObj, currency: e, targetField: "currency"})
                                        // setEWalletWithdrawalObj(prev => {return {...withdrawalObj, currency: e, targetField: "currency"}})
                                    }}
                                    value={pspCurrency}
                                    options={pspCurrencyData?.getPspSettingCurrency?.data?.length > 0 ? pspCurrencyData?.getPspSettingCurrency?.data?.map(item => {
                                        return {
                                            value: item?.currency,
                                            label: item?.currency,
                                        }
                                    }) : []}
                                />
                                : item?.result?.currency ? item?.result?.currency : item?.payload?.input?.currency ? item?.payload?.input?.currency : "-"
                            }
                        </Col>
                        {
                            item?.payload?.input.withdrawalType === "crypto" &&
                            <>
                                <Col xs={12} lg={8}>
                                    {t("task.crypto_ewallet_address")}:
                                </Col>
                                <Col xs={12} lg={16}>
                                    {item?.payload?.input?.cryptoDestinationAddress ? item?.payload?.input?.cryptoDestinationAddress : "-"}
                                </Col>
                            </>
                        }
                        <Col xs={12} lg={8}>
                            {t("task.ewallet_withdrawal_amount_usd")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {item?.status === "approved" ? item?.result?.amount ? formatMoney(item?.result?.amount) : "-" : item?.payload?.input?.amount ? formatMoney(item?.payload?.input?.amount) : "-"}
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.fee_charged_on_client_usd")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {
                                mode === "verify" ? (
                                        <InputNumber onChange={(e) => {
                                            // setExtraAmount(e)
                                            setDelayObj({
                                                ...withdrawalCalculation?.calculateWithdrawal,
                                                feeClient: e,
                                                targetField: "feeClient"
                                            })
                                            // setEWalletWithdrawalObj(prev => {return {...withdrawalCalculation?.calculateWithdrawal, feeClient: e, targetField: "feeClient"}})
                                        }} value={withdrawalCalculation?.calculateWithdrawal?.feeClient}
                                                     style={{width: "100%"}} min={0}
                                                     defaultValue={item?.payload?.input?.feeClient ? item?.payload?.input?.feeClient : 0}/>
                                    ) :
                                    (item?.result?.feeClient !== null && item?.result?.feeClient !== undefined) ?
                                        formatMoney(item?.result?.feeClient) :
                                        (withdrawalCalculation && withdrawalCalculation?.calculateWithdrawal && withdrawalCalculation?.calculateWithdrawal?.feeClient !== null && withdrawalCalculation?.calculateWithdrawal?.feeClient !== undefined) ?
                                            formatMoney(withdrawalCalculation?.calculateWithdrawal?.feeClient) :
                                            (item?.payload?.input?.feeClient !== null && item?.payload?.input?.feeClient !== undefined) ?
                                                formatMoney(item?.payload?.input?.feeClient) :
                                                "-"
                            }
                        </Col>
                        <Col xs={12} lg={8}>
                            <span style={{fontWeight: "bold"}}>{t("task.clientReceiveNetAmount_USD")}:</span>
                        </Col>
                        <Col xs={12} lg={16}>
                            {
                                mode === "verify" &&
                                (withdrawalCalculation && withdrawalCalculation?.calculateWithdrawal && withdrawalCalculation?.calculateWithdrawal?.actualAmount !== null && withdrawalCalculation?.calculateWithdrawal?.actualAmount !== undefined) ?
                                    formatMoney(withdrawalCalculation?.calculateWithdrawal?.actualAmount) :
                                    (item?.result?.actualAmount !== null && item?.result?.actualAmount !== undefined) ?
                                        formatMoney(item?.result?.actualAmount) :
                                        (item?.payload?.input?.actualAmount !== null && item?.payload?.input?.actualAmount !== undefined) ?
                                            formatMoney(item?.payload?.input?.actualAmount) :
                                            "-"
                            }
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.psp_fee_usd")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {
                                mode === "verify" &&
                                (withdrawalCalculation && withdrawalCalculation?.calculateWithdrawal && withdrawalCalculation?.calculateWithdrawal?.feeCompany !== null && withdrawalCalculation?.calculateWithdrawal?.feeCompany !== undefined) ?
                                    formatMoney(withdrawalCalculation?.calculateWithdrawal?.feeCompany) :
                                    (item?.result?.feeCompany !== null && item?.result?.feeCompany !== undefined) ?
                                        formatMoney(item?.result?.feeCompany) :
                                        (item?.payload?.input?.feeCompany !== null && item?.payload?.input?.feeCompany !== undefined) ?
                                            formatMoney(item?.payload?.input?.feeCompany) :
                                            "-"
                            }
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.rate")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {
                                mode === "verify" ? (
                                        <InputNumber required onChange={(e) => {
                                            // setEWalletWithdrawalObj(prev => {return {...withdrawalCalculation?.calculateWithdrawal, rate: e, targetField: "rate"}})
                                            setDelayObj({
                                                ...withdrawalCalculation?.calculateWithdrawal,
                                                rate: e,
                                                targetField: "rate"
                                            })
                                        }} value={withdrawalCalculation?.calculateWithdrawal?.rate} style={{width: "100%"}}
                                                     min={0}
                                                     defaultValue={item?.payload?.input?.rate ? item?.payload?.input?.rate : 0}/>
                                    ) :
                                    (item?.result?.rate !== null && item?.result?.rate !== undefined) ?
                                        formatMoney(item?.result?.rate, item?.payload?.input.withdrawalType === "crypto" ? 10 : 4) :
                                        (item?.payload?.input?.rate !== null && item?.payload?.input?.rate !== undefined) ?
                                            formatMoney(item?.payload?.input?.rate, item?.payload?.input.withdrawalType === "crypto" ? 10 : 4) :
                                            "-"
                            }
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.ewallet_withdrawal_amount")} ({mode === "verify" ? pspCurrency : item?.result?.currency ? item?.result?.currency : item?.payload?.input?.currency}):
                        </Col>
                        <Col xs={12} lg={16}>
                            {
                                mode === "verify" &&
                                (withdrawalCalculation && withdrawalCalculation?.calculateWithdrawal && withdrawalCalculation?.calculateWithdrawal?.convertedAmount !== null && withdrawalCalculation?.calculateWithdrawal?.convertedAmount !== undefined) ?
                                    formatMoney(withdrawalCalculation?.calculateWithdrawal?.convertedAmount, item?.payload?.input?.withdrawalType === "crypto" ? 10 : 2) :
                                    (item?.result?.convertedAmount !== null && item?.result?.convertedAmount !== undefined) ?
                                        formatMoney(item?.result?.convertedAmount, item?.payload?.input?.withdrawalType === "crypto" ? 10 : 2) :
                                        (item?.payload?.input?.convertedAmount !== null && item?.payload?.input?.convertedAmount !== undefined) ?
                                            formatMoney(item?.payload?.input?.convertedAmount, item?.payload?.input?.withdrawalType === "crypto" ? 10 : 2) :
                                            "-"
                            }
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.fee_charged_on_client")} ({mode === "verify" ? pspCurrency : item?.result?.currency ? item?.result?.currency : item?.payload?.input?.currency}):
                        </Col>
                        <Col xs={12} lg={16}>
                            {
                                mode === "verify" &&
                                (withdrawalCalculation && withdrawalCalculation?.calculateWithdrawal && withdrawalCalculation?.calculateWithdrawal?.convertedFeeClient !== null && withdrawalCalculation?.calculateWithdrawal?.convertedFeeClient !== undefined) ?
                                    formatMoney(withdrawalCalculation?.calculateWithdrawal?.convertedFeeClient, item?.payload?.input.withdrawalType === "crypto" ? 10 : 2) :
                                    (item?.result?.convertedFeeClient !== null && item?.result?.convertedFeeClient !== undefined) ?
                                        formatMoney(item?.result?.convertedFeeClient, item?.payload?.input.withdrawalType === "crypto" ? 10 : 2) :
                                        (item?.payload?.input?.convertedFeeClient !== null && item?.payload?.input?.convertedFeeClient !== undefined) ?
                                            formatMoney(item?.payload?.input?.convertedFeeClient, item?.payload?.input.withdrawalType === "crypto" ? 10 : 2) :
                                            "-"
                            }
                        </Col>
                        <Col xs={12} lg={8}>
                            <span
                                style={{fontWeight: "bold"}}>{t("task.clientReceiveNetAmount")} ({mode === "verify" ? pspCurrency : item?.result?.currency ? item?.result?.currency : item?.payload?.input?.currency}):</span>
                        </Col>
                        <Col xs={12} lg={16}>
                            {
                                mode === "verify" &&
                                (withdrawalCalculation && withdrawalCalculation?.calculateWithdrawal && withdrawalCalculation?.calculateWithdrawal?.convertedActualAmount !== null && withdrawalCalculation?.calculateWithdrawal?.convertedActualAmount !== undefined) ?
                                    formatMoney(withdrawalCalculation?.calculateWithdrawal?.convertedActualAmount, item?.payload?.input?.withdrawalType === "crypto" ? 10 : 2) :
                                    (item?.result?.convertedActualAmount !== null && item?.result?.convertedActualAmount !== undefined) ?
                                        formatMoney(item?.result?.convertedActualAmount, item?.payload?.input?.withdrawalType === "crypto" ? 10 : 2) :
                                        (item?.payload?.input?.convertedActualAmount !== null && item?.payload?.input?.convertedActualAmount !== undefined) ?
                                            formatMoney(item?.payload?.input?.convertedActualAmount, item?.payload?.input?.withdrawalType === "crypto" ? 10 : 2) :
                                            "-"
                            }
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.psp_fee")} ({mode === "verify" ? pspCurrency : item?.result?.currency ? item?.result?.currency : item?.payload?.input?.currency}):
                        </Col>
                        <Col xs={12} lg={16}>
                            {
                                mode === "verify" &&
                                (withdrawalCalculation && withdrawalCalculation?.calculateWithdrawal && withdrawalCalculation?.calculateWithdrawal?.convertedFeeCompany !== null && withdrawalCalculation?.calculateWithdrawal?.convertedFeeCompany !== undefined) ?
                                    formatMoney(withdrawalCalculation?.calculateWithdrawal?.convertedFeeCompany, item?.payload?.input?.withdrawalType === "crypto" ? 10 : 2) :
                                    (item?.result?.convertedFeeCompany !== null && item?.result?.convertedFeeCompany !== undefined) ?
                                        formatMoney(item?.result?.convertedFeeCompany, item?.payload?.input?.withdrawalType === "crypto" ? 10 : 2) :
                                        (item?.payload?.input?.convertedFeeCompany !== null && item?.payload?.input?.convertedFeeCompany !== undefined) ?
                                            formatMoney(item?.payload?.input?.convertedFeeCompany, item?.payload?.input?.withdrawalType === "crypto" ? 10 : 2) :
                                            "-"
                            }
                        </Col>
                        {
                            (item?.payload?.input?.withdrawalType !== "crypto" && item?.payload?.input?.bankInformationId) &&
                            (
                                <>
                                    <Col xs={12} lg={8}>
                                        {t("task.bank_name")}:
                                    </Col>
                                    <Col xs={12} lg={16}>
                                        {bankName || bankInformation?.getBankInformation?.data[0]?.formData?.bank}
                                    </Col>
                                    <Col xs={12} lg={8}>
                                        {t("task.bank_account_holder")}:
                                    </Col>
                                    <Col xs={12} lg={16}>
                                        {bankInformation?.getBankInformation?.data[0]?.formData?.account_holder}
                                    </Col>
                                    <Col xs={12} lg={8}>
                                        {t("task.bank_account_number")}:
                                    </Col>
                                    <Col xs={12} lg={16}>
                                        {bankInformation?.getBankInformation?.data[0]?.formData?.account_no}
                                    </Col>
                                </>
                            )
                        }
                        <Col xs={12} lg={8}>
                            {t("task.remark")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {item?.payload?.input?.remark}
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.comment")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {
                                mode === "verify" ? (
                                        <Input onChange={(e) => {
                                            setComment(e.target.value)
                                            props.setUpdatedPayload && props.setUpdatedPayload(prev => {
                                                return {...prev, comment: e.target.value}
                                            })
                                            props.setComment && props.setComment(e.target.value)
                                        }} value={comment} style={{width: "100%"}}/>
                                    ) :
                                    item?.comment ?
                                        item?.comment :
                                        item?.result?.comment ?
                                            item?.result?.comment :
                                            item?.payload?.input?.comment ?
                                                item?.payload?.input?.comment :
                                                "-"
                            }
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.requested_by")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {item?.requestedBy?.username}
                        </Col>
                    </Row>
                </Col>
            </Row>


        </div>
    )

}

export default EwalletWithdrawalLayout_1