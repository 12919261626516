import React from "react";
import ListTableCell from "./listTableCell";
import {useTranslation} from "react-i18next";
import {ColumnsType} from 'antd/lib/table'

const FeeColumn = (props) => {
  const { record } = props;
  const { t } = useTranslation();
  let feeRoundingTypeText = record?.feeRoundingType
    ? t(`psp.feeRoundingTypeValue.${record?.feeRoundingType}`)
    : "";
  return (
    <div>
      <p>{t(`psp.feeTypeValue.${record?.feeType}`)}</p>
      <p>
        Rounding: {record?.feeRounding || "-"} {feeRoundingTypeText}
      </p>
    </div>
  );
};

export const expandedRowRenderColumn: ColumnsType<any> = [
  {
    title: "Currency",
    dataIndex: "pspCurrency",
    key: "currency",
    sorter: (a, b) =>
      a?.pspCurrency?.currency?.localeCompare(b?.pspCurrency?.currency),
    render: (pspCurrency, res) => {
      return (
        <p className="text-bold">
          {res?.children ? pspCurrency?.currency : ""}
        </p>
      );
    },
  },
  {
    title: "Conversion Rate",
    dataIndex: "pspCurrency",
    key: "conversionRate",
    render: (_, record) => {
      let fixedRate = record?.pspCurrency?.fixedRate;
      let text = fixedRate
        ? `Fixed: ${fixedRate}`
        : record?.pspCurrency?.conversionRate;
      return <p>{record?.children ? text : ""}</p>;
    },
  },
  {
    title: "Digits",
    dataIndex: "digits",
    key: "digits",
    align: "center",
    // sorter: (a, b) => a?.paymentMethod?.localeCompare(b?.paymentMethod),
    render: (_, record) => {
      let text = record?.pspCurrency?.digits
      return <p>{record?.children ? text : ""}</p>;
    },
  },
  {
    title: "Payment Method",
    dataIndex: "paymentMethod",
    key: "paymentMethod",
    sorter: (a, b) => a?.paymentMethod?.localeCompare(b?.paymentMethod),
    render: (text) => <p>{text}</p>,
  },
  {
    title: "Fee",
    dataIndex: "feeType",
    key: "feeType",
    sorter: (a, b) => a?.feeType?.localeCompare(b?.feeType),
    render: (_, record) => {
      if (!record?.children) {
        return <FeeColumn record={record} />;
      }
    },
  },
  {
    title: "Deposit",
    key: "deposit",
    render: (_, record) => (
      <ListTableCell
        record={record}
        cellName="paymentMethodDeposit"
        listGrid={{ column: 2, xs: 1, sm: 1 }}
      />
    ),
  },
  {
    title: "Withdrawal",
    //   dataIndex: "withdrawal",
    key: "withdrawal",
    render: (_, record) => (
      <ListTableCell
        record={record}
        cellName="paymentMethodWithdrawal"
        listGrid={{ column: 2, xs: 1, sm: 1 }}
      />
    ),
  },
  {
    title: "Settlement",
    key: "pspCurrency",
    render: (_, record) => {
      let currency = record?.pspCurrency?.currency || "";
      let settlement = record?.pspCurrency?.settlement || "";
      let settlementFixed = record?.pspCurrency?.settlementFixed || "";
      return (
        <p>
          {record?.children
            ? `Fee: ${settlement}% + ${currency} ${settlementFixed}`
            : ""}
        </p>
      );
    },
  },
];
