import {Handle, NodeProps, Position} from "reactflow";
import React, {useEffect, useState} from "react";
import {Button, Card, Divider, List, Popconfirm, Row, Space, Tag, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {switchFieldTypeTagColor} from "../../../../function";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {documentGroupSettingModalPropState, fieldSettingModalPropState, sortStepSettingModalPropState, stepSettingModalPropState} from "../../../../state";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useFlowBuilderFunction} from "../../../../function/flowBuilderFunction";
import {selfPermissionObj} from "../../../../../../../../recoil_state";

const CountryNode = ({data, isConnectable}: NodeProps) => {
        const {t} = useTranslation()

        return (
            <>
                <Card
                    size={"small"}
                >
                    {`${t(data?.label)}`}
                </Card>
                <Handle
                    type="source"
                    position={Position.Right}
                    isConnectable={isConnectable}
                />
            </>
        );
    }
;

const ClientTypeNode = ({data, isConnectable}: NodeProps) => {
        const {t} = useTranslation()
        const setStepProp = useSetRecoilState<any>(stepSettingModalPropState)
        const setSortStepProp = useSetRecoilState<any>(sortStepSettingModalPropState)
        const permission = useRecoilValue<any>(selfPermissionObj)

        return (
            <>
                <Handle
                    type="target"
                    position={Position.Left}
                    isConnectable={isConnectable}
                />
                <Card
                    className={"drag-handle"}
                    size={"small"}
                >
                    {`${t(`accountVerification.clientTypeOption.${data?.label}`)}`}
                </Card>
                <Space size={"small"} className={"add-button"}>
                    {permission?.userSetting?.accountVerification?.add?.add &&
                    <Button type={"link"} onClick={() => setStepProp({
                        isAdd: true,
                        isOpen: true,
                        close: () => {
                            setStepProp({isOpen: false})
                        },
                        item: data?.data
                    })}>{t("accountVerification.addStep")}</Button>
                    }
                    <Divider type={"vertical"}/>
                    {permission?.userSetting?.accountVerification?.edit?.edit &&
                    <Button type={"link"} onClick={() => setSortStepProp({
                        isOpen: true,
                        close: () => {
                            setSortStepProp({isOpen: false})
                        },
                        item: data?.data
                    })}>{t("accountVerification.sortStep")}</Button>
                    }
                </Space>
                <Handle
                    type="source"
                    position={Position.Right}
                    isConnectable={isConnectable}
                />
            </>
        );
    }
;

const StepNode = ({data, isConnectable}: NodeProps) => {
    const {t} = useTranslation()
    const setStepProp = useSetRecoilState<any>(stepSettingModalPropState)
    const setDocumentGroupProp = useSetRecoilState<any>(documentGroupSettingModalPropState)
    const {deleteUserVerificationStepsFunction} = useFlowBuilderFunction()
    const permission = useRecoilValue<any>(selfPermissionObj)
    const editPermission = permission?.userSetting?.accountVerification?.edit?.edit

    return (
        <>
            <Handle
                type="target"
                position={Position.Left}
                isConnectable={isConnectable}
            />
            <Card
                className={"drag-handle"}
                size={"small"}
            >
                <Row justify={"space-between"}>
                    {`${t(data?.label)} - ${t(data?.stepData?.step?.verificationType)}`}
                    <Space>
                        <Tooltip title={t(editPermission ? "common.edit" : "common.view")}>
                            <Button
                                onClick={() => {
                                    setStepProp({
                                        isAdd: false,
                                        isOpen: true,
                                        close: () => {
                                            setStepProp({isOpen: false})
                                        },
                                        item: data?.stepData,
                                        isView: !editPermission
                                    })
                                }}
                                icon={editPermission ? <EditOutlined/> : <EyeOutlined/>}
                                type={"link"}
                                size={"small"}/>
                        </Tooltip>
                        {permission?.userSetting?.accountVerification?.delete?.delete &&
                        <Tooltip title={t("common.delete")}>
                            <Popconfirm
                                placement="left"
                                title={t("accountVerification.popconfirm.title.step.delete")}
                                description={t("accountVerification.popconfirm.description.step.delete")}
                                onConfirm={async () => {
                                    await deleteUserVerificationStepsFunction(data?.stepData?.step?._id, data?.stepData?.currentCountry)
                                }}
                                okText={t("common.yes")}
                                cancelText={t("common.no")}
                            >
                                <Button icon={<DeleteOutlined/>} type={"link"} danger size={"small"}/>
                            </Popconfirm>
                        </Tooltip>
                        }
                    </Space>
                </Row>
            </Card>
            {permission?.userSetting?.accountVerification?.add?.add &&
            <Button className={"add-button"} type={"link"} onClick={() => {
                setDocumentGroupProp({
                    isAdd: true,
                    isOpen: true,
                    close: () => {
                        setDocumentGroupProp({isOpen: false})
                    },
                    item: {
                        data: data?.stepData, currentCountry: data?.stepData?.currentCountry
                    }
                })
            }}>{t("accountVerification.addDocumentGroup")}</Button>
            }
            <Handle
                type="source"
                position={Position.Right}
                isConnectable={isConnectable}
            />
        </>
    );
};

const DocumentGroupNode = ({data, isConnectable}: NodeProps) => {
    const {t} = useTranslation()
    const setDocumentGroupProp = useSetRecoilState<any>(documentGroupSettingModalPropState)
    const {deleteUserVerificationDocumentGroupFunction} = useFlowBuilderFunction()
    const permission = useRecoilValue<any>(selfPermissionObj)
    const permissionEdit = permission?.userSetting?.accountVerification?.edit?.edit

    return (
        <Row>
            <Handle
                type="target"
                position={Position.Left}
                isConnectable={isConnectable}
            />
            <Card
                className={"drag-handle"}
                size={"small"}
            >
                <Row justify={"space-between"}>
                    {`${t(data?.label)}`}
                    <Space>
                        <Tooltip title={t(permissionEdit ? "common.edit" : "common.view")}>
                            <Button
                                onClick={() => {
                                    setDocumentGroupProp({
                                        isAdd: false,
                                        isOpen: true,
                                        close: () => {
                                            setDocumentGroupProp({isOpen: false})
                                        },
                                        item: {
                                            data: data?.data, currentCountry: data?.currentCountry
                                        },
                                        isView: !permissionEdit
                                    })
                                }}
                                icon={permissionEdit ? <EditOutlined/> : <EyeOutlined/>}
                                type={"link"}
                                size={"small"}/>
                        </Tooltip>
                        {permission?.userSetting?.accountVerification?.delete?.delete &&
                        <Tooltip title={t("common.delete")}>
                            <Popconfirm
                                placement="left"
                                title={t("accountVerification.popconfirm.title.documentGroup.delete")}
                                description={t("accountVerification.popconfirm.description.documentGroup.delete")}
                                onConfirm={async () => {
                                    await deleteUserVerificationDocumentGroupFunction(data?.data?._id, data?.currentCountry)
                                }}
                                okText={t("common.yes")}
                                cancelText={t("common.no")}
                            >
                                <Button icon={<DeleteOutlined/>} type={"link"} danger size={"small"}/>
                            </Popconfirm>
                        </Tooltip>
                        }
                    </Space>
                </Row>
            </Card>
            <Handle
                type="source"
                position={Position.Right}
                isConnectable={isConnectable}
            />
        </Row>
    );
};

const DocumentNode = ({data, isConnectable}: NodeProps) => {
        const {t} = useTranslation()
        const setDocumentGroupProp = useSetRecoilState<any>(documentGroupSettingModalPropState)
        const setFieldProp = useSetRecoilState<any>(fieldSettingModalPropState)
        const {deleteUserVerificationFieldFunction, sortUserVerificationFieldFunction} = useFlowBuilderFunction()
        const [field, setField] = useState<any>()
        const [dragEnabled, setDragEnabled] = useState(true)
        const permission = useRecoilValue<any>(selfPermissionObj)
        const permissionEdit = permission?.userSetting?.accountVerification?.edit?.edit

        useEffect(() => {
            setField(data?.data?.fieldList?.slice()?.sort((a, b) => a?.sortingNumber - b?.sortingNumber))
        }, [data])

        const onDragEnd = async (result) => {
            setDragEnabled(false)
            const items: any = Array.from(field);
            const [reorderedItem] = items.splice(result?.source?.index, 1);
            items.splice(result?.destination?.index, 0, reorderedItem);

            setField(items);
            const ids = items?.map(d => d?._id)

            await sortUserVerificationFieldFunction(ids, items?.[0]?.groupSetting, items?.[0]?.document)
            setDragEnabled(true)
        }

        return (
            <>
                <Handle
                    type="target"
                    position={Position.Left}
                    isConnectable={isConnectable}
                />
                <Card
                    className={"drag-handle nodrag"}
                    size={"small"}
                >
                    <Row justify={"start"}>
                        {`${t(data?.label)}`}
                        {/*<Space>*/}
                        {/*    <Tooltip title={t("common.edit")}>*/}
                        {/*        <Button*/}
                        {/*            onClick={() => {*/}
                        {/*                setDocumentGroupProp({*/}
                        {/*                    isAdd: false,*/}
                        {/*                    isOpen: true,*/}
                        {/*                    isDocument: true,*/}
                        {/*                    close: () => {*/}
                        {/*                        setDocumentGroupProp({isOpen: false})*/}
                        {/*                    },*/}
                        {/*                    item: {*/}
                        {/*                        data: data?.data, currentCountry: data?.currentCountry, group: data?.group*/}
                        {/*                    }*/}
                        {/*                })*/}
                        {/*            }}*/}
                        {/*            icon={<EditOutlined/>}*/}
                        {/*            type={"link"}*/}
                        {/*            size={"small"}/>*/}
                        {/*    </Tooltip>*/}
                        {/*</Space>*/}
                    </Row>
                    {field?.length > 0 &&
                    <List className={"margin-top-0-75"}>
                        <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                            <Droppable droppableId={`${data?.data?._id}-${data?.data?.document?._id}`}>
                                {(droppableProvided) => (
                                    <div ref={droppableProvided?.innerRef}>
                                        {field?.map((item, index) => {
                                            return (
                                                <Draggable key={`${item?._id}-${item?.field?._id}`}
                                                           draggableId={`${item?._id}-${item?.field?._id}`}
                                                           index={index}
                                                           isDragDisabled={!dragEnabled || !permissionEdit}
                                                >
                                                    {(provided, snapshot) => {
                                                        if (snapshot.isDragging) {
                                                            provided.draggableProps.style.left = provided.draggableProps.style.offsetLeft;
                                                            provided.draggableProps.style.top = provided.draggableProps.style.offsetTop;
                                                        }

                                                        return (
                                                            <div
                                                                ref={provided?.innerRef}
                                                                {...provided?.draggableProps}
                                                                {...provided?.dragHandleProps}
                                                                style={{
                                                                    ...provided?.draggableProps.style,
                                                                    // opacity: snapshot?.isDragging ? 0.8 : 1,
                                                                    backgroundColor: "rgb(238 249 255)",
                                                                    marginBottom: "0.15rem",
                                                                    paddingInline: 8,
                                                                    width: snapshot.isDragging ? "calc(100% - 24px)" : "100%",
                                                                    height: "unset",
                                                                }}
                                                            >
                                                                <Row justify={"space-between"} align={"middle"}>
                                                                    <small>{item?.isRequired && <span
                                                                        style={{color: "red"}}>* </span>}{t(item?.field?.label)}</small>
                                                                    <div>
                                                                        <Tooltip title={t(permissionEdit ? "common.edit" : "common.view")}>
                                                                            <Button
                                                                                onClick={() => {
                                                                                    setFieldProp({
                                                                                        isAdd: false,
                                                                                        isOpen: true,
                                                                                        close: () => {
                                                                                            setFieldProp({isOpen: false})
                                                                                        },
                                                                                        item: {
                                                                                            document: data?.data,
                                                                                            currentCountry: data?.currentCountry,
                                                                                            group: data?.group,
                                                                                            data: item
                                                                                        },
                                                                                        isView: !permissionEdit
                                                                                    })
                                                                                }}
                                                                                style={{fontSize: 10}}
                                                                                icon={permissionEdit ? <EditOutlined/> : <EyeOutlined/>}
                                                                                type={"link"}
                                                                                size={"small"}/>
                                                                        </Tooltip>
                                                                        {permission?.userSetting?.accountVerification?.delete?.delete &&
                                                                        <Tooltip title={t("common.delete")}>
                                                                            <Popconfirm
                                                                                placement="left"
                                                                                title={t("accountVerification.popconfirm.title.field.delete")}
                                                                                description={t("accountVerification.popconfirm.description.field.delete")}
                                                                                onConfirm={async () => {
                                                                                    await deleteUserVerificationFieldFunction(item?._id, data?.currentCountry)
                                                                                }}
                                                                                okText={t("common.yes")}
                                                                                cancelText={t("common.no")}
                                                                            >
                                                                                <Button icon={<DeleteOutlined/>}
                                                                                        type={"link"}
                                                                                        style={{fontSize: 10}}
                                                                                        danger size={"small"}/>
                                                                            </Popconfirm>
                                                                        </Tooltip>
                                                                        }
                                                                    </div>
                                                                </Row>
                                                            </div>
                                                        )
                                                    }}
                                                </Draggable>
                                            );
                                        })}
                                        {droppableProvided?.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </List>
                    }
                </Card>
                {permission?.userSetting?.accountVerification?.add?.add &&
                <Button className={"add-button"} type={"link"} onClick={() => {
                    setFieldProp({
                        isAdd: true,
                        isOpen: true,
                        close: () => {
                            setFieldProp({isOpen: false})
                        },
                        item: {
                            document: data?.data, currentCountry: data?.currentCountry, group: data?.group
                        }
                    })
                }}>{t("accountVerification.addField")}</Button>
                }
                <Handle
                    type="source"
                    position={Position.Right}
                    isConnectable={isConnectable}
                />
            </>
        );
    }
;

const FieldGroupNode = ({data, isConnectable}: NodeProps) => {
        const {t} = useTranslation()
        const setFieldProp = useSetRecoilState<any>(fieldSettingModalPropState)
        const {deleteUserVerificationFieldFunction} = useFlowBuilderFunction()
        const permission = useRecoilValue<any>(selfPermissionObj)

        return (
            <Row>
                <Handle
                    type="target"
                    position={Position.Left}
                    isConnectable={isConnectable}
                />
                <Card
                    className={"drag-handle"}
                    size={"small"}
                    title={
                        <Row justify={"space-between"}>
                            {`${t(data?.label)}`}
                            <Space>
                                {permission?.userSetting?.accountVerification?.edit?.edit &&
                                <Tooltip title={t("common.edit")}>
                                    <Button
                                        onClick={() => {
                                            setFieldProp({
                                                isAdd: false,
                                                isOpen: true,
                                                close: () => {
                                                    setFieldProp({isOpen: false})
                                                },
                                                item: {
                                                    document: data?.document,
                                                    currentCountry: data?.currentCountry,
                                                    group: data?.group,
                                                    data: data?.data
                                                }
                                            })
                                        }}
                                        icon={<EditOutlined/>}
                                        type={"link"}
                                        size={"small"}/>
                                </Tooltip>
                                }
                                {permission?.userSetting?.accountVerification?.delete?.delete &&
                                <Tooltip title={t("common.delete")}>
                                    <Popconfirm
                                        placement="left"
                                        title={t("accountVerification.popconfirm.title.field.delete")}
                                        description={t("accountVerification.popconfirm.description.field.delete")}
                                        onConfirm={async () => {
                                            await deleteUserVerificationFieldFunction(data?.data?._id, data?.currentCountry)
                                        }}
                                        okText={t("common.yes")}
                                        cancelText={t("common.no")}
                                    >
                                        <Button icon={<DeleteOutlined/>} type={"link"} danger size={"small"}/>
                                    </Popconfirm>
                                </Tooltip>
                                }
                            </Space>
                        </Row>
                    }
                >
                    {
                        data?.data?.field?.fieldGroup?.map((d) => {
                            const tagColor = switchFieldTypeTagColor(d?.fieldType)

                            return (
                                <Tag bordered={false} key={d?._id} color={tagColor}>{t(d?.label)}</Tag>
                            )
                        })
                    }
                </Card>
            </Row>
        );
    }
;

const FieldNode = ({data, isConnectable}: NodeProps) => {
        const {t} = useTranslation()
        const setFieldProp = useSetRecoilState<any>(fieldSettingModalPropState)
        const {deleteUserVerificationFieldFunction} = useFlowBuilderFunction()

        return (
            <Row>
                <Handle
                    type="target"
                    position={Position.Left}
                    isConnectable={isConnectable}
                />
                <Card
                    className={"drag-handle"}
                    size={"small"}
                >
                    <Row justify={"space-between"}>
                        {`${t(data?.label)}`}
                        <Space>
                            <Tooltip title={t("common.edit")}>
                                <Button
                                    onClick={() => {
                                        setFieldProp({
                                            isAdd: false,
                                            isOpen: true,
                                            close: () => {
                                                setFieldProp({isOpen: false})
                                            },
                                            item: {
                                                document: data?.document,
                                                currentCountry: data?.currentCountry,
                                                group: data?.group,
                                                data: data?.data
                                            }
                                        })
                                    }}
                                    icon={<EditOutlined/>}
                                    type={"link"}
                                    size={"small"}/>
                            </Tooltip>
                            <Tooltip title={t("common.delete")}>
                                <Popconfirm
                                    placement="left"
                                    title={t("accountVerification.popconfirm.title.field.delete")}
                                    description={t("accountVerification.popconfirm.description.field.delete")}
                                    onConfirm={async () => {
                                        await deleteUserVerificationFieldFunction(data?.data?._id, data?.currentCountry)
                                    }}
                                    okText={t("common.yes")}
                                    cancelText={t("common.no")}
                                >
                                    <Button icon={<DeleteOutlined/>} type={"link"} danger size={"small"}/>
                                </Popconfirm>
                            </Tooltip>
                        </Space>
                    </Row>
                </Card>
            </Row>
        );
    }
;


export
{
    CountryNode, ClientTypeNode, StepNode, DocumentGroupNode, DocumentNode, FieldGroupNode, FieldNode
}
