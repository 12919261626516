import React, {useEffect, useState} from "react";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import {columnsCT} from "../../../function/tableColumn_CT";
import {getAsset, getAssetCount} from "../../../../../../api/graphql/asset";
import {useTranslation} from "react-i18next";

function AssetsTable_1(props) {
    const {server, setExportData, setExportColumn, tableName, currentServer} = props
    const {t} = useTranslation()
    const [limit, setLimit] = useState(10)
    const [offset, setOffset] = useState(0)
    const [orderBy, setOrderBy] = useState("")
    const [filter, setFilter] = useState({serverName: server?.name})

    const {data: totalCount} = getAssetCount({filter: filter});
    const {loading, error, data} = getAsset({filter: filter}, limit, offset, orderBy);
    const {data: exportData} = getAsset({filter: filter}, 0, 0, orderBy);

    const columns = server?.platform === "cTrader" ? columnsCT : []

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize)
        setOffset(limit * (page - 1))
    }

    useEffect(() => {
        if (exportData?.getAssets?.data?.length > 0 && columns?.length > 0) {
            setExportColumn(columns)
            setExportData(exportData?.getAssets?.data)
        }
    }, [server?.name, exportData?.getAssets?.data, currentServer?.name])

    return (
        <div className={"margin-top-0-75"}>
            <TableAntDesign data={data?.getAssets?.data}
                            tableName={tableName}
                            columns={columns}
                            size={"small"}
                            loading={loading}
                            filter={setFilter}
                            server={server?.name}
                            order={setOrderBy}
                            pagination={{
                                pageSize: limit,
                                showSizeChanger: true,
                                onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                                total: totalCount?.getAssetsCount,
                                showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                            }}
            />
        </div>
    )
};

export default AssetsTable_1;
