import { gql, useMutation, useQuery } from '@apollo/client'
import { useSetRecoilState } from 'recoil';
import { notificationMessage } from '../../../recoil_state';
import {useTranslation} from "react-i18next";
import { GET_EWALLET_STATEMENT_WITH_TASK } from './eWallet';
import { GET_CRM_TASK_APPROVAL } from './crmTask';

const GET_EWALLET_DEPOSIT_COUNT = gql`
  query getEWalletDepositCount($filter: EWalletDepositSearchInput) {
    getEWalletDepositCount(filter: $filter)
  }
`;

const getEWalletDepositCount = ({filter}) => {
  try{
    const { loading, error, data } = useQuery(GET_EWALLET_DEPOSIT_COUNT, {
        variables: {
            filter: {
                  refNo: filter?.refNo || null,
                  createdType: filter?.createdType || null,
                  transactionNo: filter?.transactionNo || null,
                  paymentMethod: filter?.paymentMethod || null,
                  type: filter?.type || null
            }
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}

const GET_EWALLET_DEPOSIT_BY_ID = gql`
  query getEWalletDepositByID($id: String!) {
    getEWalletDepositByID(id: $id){
      ... on EWalletDeposit {
        _id
          id
          refNo
          createdType
          transactionNo
          transactionDate
          user {username}
          amount
          currency
          rate
          paymentMethod
          bankInDate
          fee
          feeClient
          feeCompany
          actualAmount
          actionAt
          adminRefNo
          adminRemark
          convertedAmount
          convertedFee
          convertedFeeCompany
          convertedFeeClient
          convertedActualAmount
          cryptoAmount
          referrerUser {username}
          referrerStaffUser {username}
          salesRepUser {username}
          excludeReport
          type
          commType
          bankCode
          importId
          remark
          createdBy {username}
          updatedBy {username}
          createdAt
          updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getEWalletDepositById = (id) => {
  try{
    const { loading, error, data } = useQuery(GET_EWALLET_DEPOSIT_BY_ID, {
        variables: {
          id: id
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}


const GET_EWALLET_DEPOSIT = gql`
  query getEWalletDeposit($filter: EWalletDepositSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
    getEWalletDeposit(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy){
      ... on EWalletDepositArray {
        data {
          _id
          id
          psp
          transactionNo
          transactionDate
          refNo
          user {username}
          amount
          currency
          rate
          paymentMethod
          bankInDate
          feeClient
          feeCompany
          actualAmount
          convertedAmount
          convertedFeeCompany
          convertedFeeClient
          convertedActualAmount
          referrerUser {username}
          salesRepUser {username}
          remark
          transactionStatus
          createdBy {username}
          updatedBy {username}
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getEWalletDeposit = ({filter}, limit?, offset?, orderBy?) => {
  try{
    const { loading, error, data } = useQuery(GET_EWALLET_DEPOSIT, {
        variables: {
          filter: {
              user: filter?.user || null,
              psp: filter?.psp || null,
              transactionNo: filter?.transactionNo || null,
              paymentMethod: filter?.paymentMethod || null,
              type: filter?.type || null,
              rejectReason: filter?.rejectReason || null,
              transactionStatus: filter?.transactionStatus || null,
              pspSettingId: filter?.pspSettingId || null,
              pspSettingCurrencyId: filter?.pspSettingCurrencyId || null,
          },
          limit: limit || null,
          offset: offset || null,
          orderBy: orderBy || null,
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}

const GET_EWALLET_DEPOSIT_DROPDOWN_BASED_ON_PERMISSION = gql`
  query getEWalletDepositDropdownBasedOnPermission($filter: EWalletDepositSearchInput) {
    getEWalletDepositDropdownBasedOnPermission(filter: $filter){
      ... on EWalletDepositArray {
        data {
          _id
          id
          psp
          transactionNo
          transactionDate
          refNo
          user {username}
          amount
          currency
          rate
          paymentMethod
          bankInDate
          feeClient
          feeCompany
          actualAmount
          convertedAmount
          convertedFeeCompany
          convertedFeeClient
          convertedActualAmount
          referrerUser {username}
          salesRepUser {username}
          remark
          transactionStatus
          createdBy {username}
          updatedBy {username}
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getEWalletDepositDropdownBasedOnPermission = ({filter}) => {
  try{
    const { loading, error, data } = useQuery(GET_EWALLET_DEPOSIT_DROPDOWN_BASED_ON_PERMISSION, {
        variables: {
          filter: filter
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}

const ADD_EWALLET_DEPOSIT = gql`
  mutation addEWalletDeposit($input: EWalletDepositInput) {
    addEWalletDeposit(input: $input){
      ... on EWalletDeposit {
         _id
        id
        refNo
        createdType
        transactionNo
        transactionDate
        user {username}
        amount
        currency
        rate
        paymentMethod
        bankInDate
        fee
        feeClient
        feeCompany
        actualAmount
        actionAt
        adminRefNo
        adminRemark
        convertedAmount
        convertedFee
        convertedFeeCompany
        convertedFeeClient
        convertedActualAmount
        cryptoAmount
        referrerUser {username}
        referrerStaffUser {username}
        salesRepUser {username}
        excludeReport
        type
        commType
        bankCode
        importId
        remark
        createdBy {username}
        updatedBy {username}
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useAddEWalletDepositMutation = (): { addEWalletDeposit: any, data: any, loading: boolean, error: any } => {
  try{
    const [addEWalletDepositMutation, { data, loading, error }] = useMutation(ADD_EWALLET_DEPOSIT);

    const addEWalletDeposit = (input) => {
      return addEWalletDepositMutation({
        variables: {
          input: {
            refNo: input.refNo || null,
            createdType: input.createdType || null,
            transactionNo: input.transactionNo || null,
            transactionDate: input.transactionDate || null,
            user: input.user || null,
            amount: input.amount || null,
            currency: input.currency || null,
            rate: input.rate || null,
            paymentMethod: input.paymentMethod || null,
            bankInDate: input.bankInDate || null,
            fee: input.fee || null,
            feeClient: input.feeClient || null,
            feeCompany: input.feeCompany || null,
            actualAmount: input.actualAmount || null,
            actionAt: input.actionAt || null,
            adminRefNo: input.adminRefNo || null,
            adminRemark: input.adminRemark || null,
            convertedAmount: input.convertedAmount || null,
            convertedFee: input.convertedFee || null,
            convertedFeeCompany: input.convertedFeeCompany || null,
            convertedFeeClient: input.convertedFeeClient || null,
            convertedActualAmount: input.convertedActualAmount || null,
            cryptoAmount: input.cryptoAmount || null,
            referrerUser: input.referrerUser || null,
            referrerStaffUser: input.referrerStaffUser || null,
            salesRepUser: input.salesRepUser || null,
            excludeReport: input.excludeReport || null,
            type: input.type || null,
            commType: input.commType || null,
            bankCode: input.bankCode || null,
            importId: input.importId || null,
            remark: input.remark || null,
          },
        },
      });
    };

    return { addEWalletDeposit, data, loading, error } 
  }
  catch(err: any){
    console.error(err);
    return { addEWalletDeposit: null, data: null, loading: false, error: err };
  }
}

const UPDATE_EWALLET_DEPOSIT = gql`
mutation updateEwalletDeposit($id: String!, $input: EWalletClientDepositEditInput) {
    updateEwalletDeposit(id: $id, input: $input){
      ... on EWalletDeposit {
        _id
        id
        refNo
        transactionNo
        transactionDate
        user {username}
        amount
        currency
        rate
        paymentMethod
        bankInDate
        feeClient
        feeCompany
        actualAmount
        convertedAmount
        convertedFeeCompany
        convertedFeeClient
        convertedActualAmount
        createdBy {username}
        updatedBy {username}
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useUpdateEwalletDepositMutation = (): { updateEwalletDeposit: any, data: any, loading: boolean, error: any } => {
  try{
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const { t } = useTranslation();
    const [updateEwalletDepositMutation, { data, loading, error }] = useMutation(UPDATE_EWALLET_DEPOSIT);

    const updateEwalletDeposit = (id, input) => {
      return updateEwalletDepositMutation({
        variables: {
          id: id,
          input: {
            user: input?.user,
            comment: input?.comment || ""
          },
        },
        update(cache, {data: {updateEwalletDeposit}}) {
            const typename = updateEwalletDeposit?.__typename;
            if (typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(updateEwalletDeposit?.errKey),
                    key: "updateEwalletDeposit",
                });
            }  else {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Update Ewallet Deposit Successfully"),
                    key: "updateEwalletDeposit",
                });
            }
        },
        refetchQueries: [GET_EWALLET_STATEMENT_WITH_TASK, GET_CRM_TASK_APPROVAL]
      });
    };

    return { updateEwalletDeposit, data, loading, error } 
  }
  catch(err: any){
    console.error(err);
    return { updateEwalletDeposit: null, data: null, loading: false, error: err };
  }
}


const UPDATE_EWALLET_DEPOSIT_COMMISSION_DATE = gql`
mutation updateEwalletDepositCommissionDate($id: String!, $input: EwalletDepositCommisionDateInput) {
  updateEwalletDepositCommissionDate(id: $id, input: $input){
      ... on EWalletDeposit {
        _id
        id
        refNo
        transactionNo
        transactionDate
        user {username}
        amount
        currency
        rate
        paymentMethod
        bankInDate
        feeClient
        feeCompany
        actualAmount
        convertedAmount
        convertedFeeCompany
        convertedFeeClient
        convertedActualAmount
        createdBy {username}
        updatedBy {username}
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useUpdateEwalletDepositCommissionDateMutation = (): { updateEwalletDepositCommissionDate: any, data: any, loading: boolean, error: any } => {
  try{
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const { t } = useTranslation();
    const [updateEwalletDepositCommissionDateMutation, { data, loading, error }] = useMutation(UPDATE_EWALLET_DEPOSIT_COMMISSION_DATE);

    const updateEwalletDepositCommissionDate = (id, input) => {
      return updateEwalletDepositCommissionDateMutation({
        variables: {
          id: id,
          input: input,
        },
        update(cache, {data: {updateEwalletDepositCommissionDate}}) {
            const typename = updateEwalletDepositCommissionDate?.__typename;
            if (typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(updateEwalletDepositCommissionDate?.errKey),
                    key: "updateEwalletDepositCommissionDate",
                });
            }  else {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Update Ewallet Deposit Successfully"),
                    key: "updateEwalletDepositCommissionDate",
                });
            }
        },
        refetchQueries: [GET_EWALLET_STATEMENT_WITH_TASK]
      });
    };

    return { updateEwalletDepositCommissionDate, data, loading, error } 
  }
  catch(err: any){
    console.error(err);
    return { updateEwalletDepositCommissionDate: null, data: null, loading: false, error: err };
  }
}

const DELETE_EWALLET_DEPOSIT = gql`
  mutation deleteEWalletDeposit($id: String!){
    deleteEWalletDeposit(id: $id){
      ... on EWalletDeposit {
        _id
        id
        isDeleted
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useDeleteEWalletDepositMutation = (): { deleteEWalletDeposit: any, data: any, loading: boolean, error: any } => {
  try{
    const [deleteEWalletDepositMutation, { data, loading, error }] = useMutation(DELETE_EWALLET_DEPOSIT);

    const deleteEWalletDeposit = (id) => {
      return deleteEWalletDepositMutation({
        variables: {
          id: id,
        },
      });
    };

    return { deleteEWalletDeposit, data, loading, error } 
  }
  catch(err: any){
    console.error(err);
    return { deleteEWalletDeposit: null, data: null, loading: false, error: err };
  }
}

const CALCULATE_DEPOSIT = gql`
  query calculateDeposit($input: EWalletClientDepositRateInput) {
    calculateDeposit(input: $input){
      ... on EWalletDeposit {
        psp
        amount
        currency
        rate
        paymentMethod
        feeClient
        feeCompany
        actualAmount
        convertedAmount
        convertedFeeCompany
        convertedFeeClient
        convertedActualAmount
        formType
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`


const calculateDeposit = ({input}) => {
  try{
    const { loading, error, data } = useQuery(CALCULATE_DEPOSIT, {
        variables: {
          input: {
            psp: input?.psp || null,
            currency: input?.currency || null,
            paymentMethod: input?.paymentMethod || null,
            amount: input?.amount || null,
            actualAmount: input?.actualAmount || null,
            rate: input?.rate || null,
            convertedFeeClient: input?.convertedFeeClient || null,
            convertedFeeCompany: input?.convertedFeeCompany || null,
            convertedActualAmount: input?.convertedActualAmount || null,
            targetField: input?.targetField || "currency",
            formType: input?.formType || null,
            lastEditedCurrency: input?.lastEditedCurrency || "",
            taskId: input?.taskId || null
          },
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, depositCalulation: data }
  }
  catch(err: any){
    console.error(err);
    return { depositCalulation: null, loading: false, error: err };
  }
}

export {
  getEWalletDepositCount, 
  getEWalletDepositById, 
  getEWalletDeposit, 
  useAddEWalletDepositMutation, 
  useUpdateEwalletDepositMutation,
  useDeleteEWalletDepositMutation,
  calculateDeposit,
  getEWalletDepositDropdownBasedOnPermission,
  useUpdateEwalletDepositCommissionDateMutation
}