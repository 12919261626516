import React, {useEffect} from "react";
import {Form, Input, Modal} from "antd";
import {useTranslation} from "react-i18next";
import {updateFolder} from "../../../../../../api/restful/arena/media_folder";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../recoil_state";

const RenameFolderModal = ({open, handleClose, folder, refetchFolder}) => {
    const {t} = useTranslation()
    const [form] = Form.useForm()
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    useEffect(() => {
        if (open) form.resetFields()
    }, [open])

    const handleRenameFolder = async ({name}) => {
        const {parent, _id} = folder
        try {
            const response = await updateFolder({_id, parent, name})
            if (response.status === "Success") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Rename Folder Successfully",
                    key: "arenaMediaLibraryNotification",
                })
                refetchFolder()
                handleClose()
            } else {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: `Rename Folder Failed: ${response?.errObj}`,
                    key: "arenaMediaLibraryNotification",
                })
            }
        } catch (e) {
        }
    }

    return (
        <Modal
            open={open}
            onCancel={handleClose}
            title={"Rename Folder"}
            width={300}
            closeIcon={false}
            centered={true}
            okText={t("common.apply")}
            cancelText={t("common.cancel")}
            onOk={form.submit}
        >
            <Form form={form} onFinish={handleRenameFolder} initialValues={folder} layout={"vertical"}>
                <Form.Item name={"name"}>
                    <Input/>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default RenameFolderModal