import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import {Button, Col, Divider, Form, Row} from "antd";

import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import yupFormSchema from "../../../../../../components/yup/yupFormSchema";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import SelectFormItem from "../../../../../../components/form/selectFormItem";
import TimezoneSelectFormItem from "../../../../../../components/form/timezoneSelectFormtItem";
import MultipleCheckboxFormItem from "../../../../../../components/form/multipleCheckboxFormItem";
import RadioGroupFormItem from "../../../../../../components/form/radioGroupFormItem";
import {horizontalFormLayout, verticalFormLayout} from "../../../../../../components/form/formWrapper";
import {
    useAddServerSettingMutation,
    useUpdateServerSettingMutation,
} from "../../../../../../api/graphql/serverSetting";
import useAuthorize from "../../../../../../_common/authorize";
import {getProfileInformationSetting} from "../../../../../../api/graphql/profileInformation";
import InputNumberFormItem from "../../../../../../components/form/inputNumberFormItem";

const PlatformServerForm = (props) => {
    const {mode, item, closeModal, setSpinning} = props;
    const {t} = useTranslation();
    const [errMsg, setErrMsg] = useState("");
    const [serverMode, setServerMode] = useState([]);
    const [clientFields, setClientFields] = useState<any>([]);
    const [platform, setPlatform] = useState("");

    const {data: profileInformation} = getProfileInformationSetting({type: "client"})

    const yupSchema = (platform === "mt5") ? yup.object().shape({
        platform: yupFormSchema.string("platform", {required: true}),
        name: yupFormSchema.string("name", {required: true}),
        brokerName: yupFormSchema.string("brokerName", {required: true}),
        mode: yupFormSchema.array("mode", {required: true}),
        ip: yupFormSchema.string("ip", {required: true}),
        serverStatus: yupFormSchema.string("serverStatus"),
        port: yupFormSchema.string("port"),
        timezone: yupFormSchema.string("timezone", {required: true}),
        isActive: yupFormSchema.boolean("isActive"),
        id: yupFormSchema.string("id"),
        whiteLabel: yupFormSchema.string("whiteLabel"),
        username: yupFormSchema.string("username", {required: true}),
        password: yupFormSchema.string("password", {required: true}),
        environment: yupFormSchema.string("environment"),
        "tradingKey-email": yupFormSchema.string("Email", {required: true}),
    }) : (platform === "xTrader") ? yup.object().shape({
        platform: yupFormSchema.string("platform", {required: true}),
        platformDisplayName: yupFormSchema.string("platformDisplayName", {required: true}),
        platformAccountNo: yupFormSchema.string("platformAccountNo", {required: true}),
        name: yupFormSchema.string("name", {required: true}),
        brokerName: yupFormSchema.string("brokerName", {required: true}),
        mode: yupFormSchema.array("mode", {required: true}),
        ip: yupFormSchema.string("ip", {required: true}),
        serverStatus: yupFormSchema.string("serverStatus"),
        port: yupFormSchema.string("port"),
        timezone: yupFormSchema.string("timezone", {required: true}),
        isActive: yupFormSchema.boolean("isActive"),
        id: yupFormSchema.string("id"),
        whiteLabel: yupFormSchema.string("whiteLabel"),
        username: yupFormSchema.string("username", {required: true}),
        password: yupFormSchema.string("password", {required: true}),
        environment: yupFormSchema.string("environment"),
    }) : yup.object().shape({
        platform: yupFormSchema.string("platform", {required: true}),
        name: yupFormSchema.string("name", {required: true}),
        brokerName: yupFormSchema.string("brokerName", {required: true}),
        mode: yupFormSchema.array("mode", {required: true}),
        ip: yupFormSchema.string("ip"),
        serverStatus: yupFormSchema.string("serverStatus"),
        port: yupFormSchema.string("port"),
        timezone: yupFormSchema.string("timezone", {required: true}),
        isActive: yupFormSchema.boolean("isActive"),
        id: yupFormSchema.string("id"),
        whiteLabel: yupFormSchema.string("whiteLabel"),
        username: yupFormSchema.string("username"),
        password: yupFormSchema.string("password"),
        plantId: yupFormSchema.string("plantId"),
        environment: yupFormSchema.string("environment"),
        proxy: yupFormSchema.string("proxy"),
        proxyName: yupFormSchema.string("proxyName"),
        resetPasswordLink: yupFormSchema.string("resetPasswordLink", {matches: /^https:\/\//, matchesErrorMessage: t("errMsg.link_must_start_from_https")})
    });

    const [initialValues] = useState(() => ({
        ...item,
        platform: item?.platform || "",
        platformDisplayName: item?.platformDisplayName || "",
        platformAccountNo: item?.platformAccountNo || 0,
        name: item?.name || "",
        brokerName: item?.brokerName || "",
        mode: item?.mode || "",
        ip: item?.ip || "",
        isActive: item?.isActive ? true : false,
        port: item?.port || "",
        timezone: item?.timezone || "",
        whiteLabel: item?.whiteLabel || "",
        id: item?.id || "",
        username: item?.username || "",
        password: item?.password || "",
        plantId: item?.plantId || "",
        environment: item?.environment || "",
        proxy: item?.proxy || "",
        proxyName: item?.proxyName || "",
        resetPasswordLink: item?.resetPasswordLink || ""
    }));

    var form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
        defaultValues: initialValues,
    });

    const {addServerLoading, addServerSetting} = useAddServerSettingMutation();
    const {updateServerLoading, updateServerSetting} =
        useUpdateServerSettingMutation();
    const {systemErrorNotification} = useAuthorize();

    const onCancel = () => {
        closeModal();
    };

    const onSubmit = async (values) => {

        const newValues = await extractFields(values)

        try {
            setSpinning(true);
            if (newValues?.mode?.length > 0) {
                const response =
                    mode === "add"
                        ? await addServerSetting(newValues)
                        : await updateServerSetting(item?.id, newValues);
                const {data} = response;
                if (
                    mode === "add" &&
                    data?.addServerSetting?.__typename === "BaseError"
                ) {
                    setErrMsg(`${t(data?.addServerSetting?.errKey)}`);
                    setSpinning(addServerLoading);
                } else if (
                    mode === "edit" &&
                    data?.updateServerSetting?.__typename === "BaseError"
                ) {
                    setErrMsg(`${t(data?.updateServerSetting.errKey)}`);
                    setSpinning(updateServerLoading);
                } else {
                    setSpinning(false);
                    setErrMsg("");
                    closeModal();
                }
            } else {
                setErrMsg("Please select Mode");
            }
        } catch (error: any) {
            setSpinning(false);
            systemErrorNotification();
        }
    };

    const onModeChange = (e) => {
        try {
            if (form.getValues().platform === "cTrader") {
                if (e?.length <= 1) {
                    form.setValue("environment", e?.[0]);
                    setServerMode(e);
                    setErrMsg("");
                } else {
                    setErrMsg("Please select one mode only for cTrader server");
                }
            } else {
                setServerMode(e);
            }
        } catch (error) {
        }
    };

    useEffect(() => {
        setServerMode(item?.mode);
    }, [item?.mode]);

    useEffect(() => {
        setPlatform(form.getValues().platform)
    }, [form.getValues().platform, item?.platform])

    useEffect(() => {
        if (profileInformation?.getProfileInformationSetting?.data?.length > 0) {
            const result = profileInformation?.getProfileInformationSetting?.data?.map((item) => {
                return {
                    label: t(item?.fieldList?.label),
                    value: item?.fieldList?.fieldName
                }
            })

            const added = [{value: null, label: "None"}]
            setClientFields([...added, ...result])
        }
    }, [profileInformation?.getProfileInformationSetting?.data])

    function extractFields(obj) {
        const result: any = [];
        let platform;
        const extractedObj: any = {};

        for (const key in obj) {
            if (key.startsWith('tradingKey') && obj[key] !== undefined && obj[key] !== null) {
                const tradingKey = key.substring(11); // Remove 'tradingKey-' prefix
                result.push({tradingKey: tradingKey, clientField: obj[key]});
            } else {
                extractedObj[key] = obj[key];
            }
        }

        extractedObj.fieldForTrading = result;

        for (let key in extractedObj) {
            if (key.includes("tradingKey-")) {
                delete extractedObj[key]; // Corrected to delete keys from extractedObj
            }
        }

        return extractedObj;
    }


    const mt5Field = platform === "cTrader" ?
        ["name", "lastName", "address", "city", "email", "phone", "state", "zipCode"]
        : ["name", "firstName", "lastName", "middleName", "company", "city", "state", "zipCode", "address", "phone", "email", "comment", "leadSource", "leadCampaign"];


    const requiredFields = platform === "cTrader" ? ["name", "email"] : ["name", "email", "firstName", "lastName"]
        
    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="form-1">
                {errMsg && <p style={{color: "red"}}>{errMsg}</p>}
                <Row gutter={[16, 16]}>
                    <Col xs={12}>
                        <SelectFormItem
                            name={"platform"}
                            label={t("Platform")}
                            placeholder={t("Platform")}
                            options={[
                                {label: t("MT5"), value: "mt5"},
                                {label: t("cTrader"), value: "cTrader"},
                                {label: t("xTrader"), value: "xTrader"},
                            ]}
                            layout={verticalFormLayout}
                            required
                            disabled={mode === "view" || mode === "edit"}
                        />
                    </Col>
                    {
                        form.getValues().platform === "xTrader" && (
                            <Col xs={12}>
                                <InputFormItem
                                    name={"platformDisplayName"}
                                    label={t("Platform Display Name")}
                                    placeholder={t("Platform Display Name")}
                                    layout={verticalFormLayout}
                                    required
                                    disabled={mode === "view"}
                                />
                            </Col>
                        )
                    }
                    <Col xs={12}>
                        <InputFormItem
                            name={"whiteLabel"}
                            label={t("White Label")}
                            placeholder={t("White Label")}
                            layout={verticalFormLayout}
                            required
                            disabled={mode === "view"}
                        />
                    </Col>
                    <Col xs={12}>
                        <InputFormItem
                            name={"name"}
                            label={t("Name")}
                            placeholder={t("Name")}
                            layout={verticalFormLayout}
                            required
                            disabled={mode === "view"}
                        />
                    </Col>
                    <Col xs={12}>
                        <InputFormItem
                            name={"brokerName"}
                            label={t("Broker Name")}
                            placeholder={t("Broker Name")}
                            layout={verticalFormLayout}
                            required
                            disabled={mode === "view"}
                        />
                    </Col>
                    <Col xs={12}>
                        <MultipleCheckboxFormItem
                            name={"mode"}
                            label={t("Mode")}
                            options={[
                                {label: t("Live"), value: "live"},
                                {label: t("Demo"), value: "demo"},
                            ]}
                            value={serverMode}
                            layout={verticalFormLayout}
                            required
                            disabled={mode === "view" || mode === "edit"}
                            onChange={onModeChange}
                        />
                    </Col>
                </Row>

                {(form.getValues().platform === "mt5" || form.getValues().platform === "xTrader") && (
                    <Row gutter={[16, 16]}>
                        <Col xs={12}>
                            <InputFormItem
                                name={"ip"}
                                label={t("IP Address")}
                                placeholder={t("IP Address")}
                                layout={verticalFormLayout}
                                required
                                disabled={mode === "view"}
                            />
                        </Col>
                        <Col xs={12}>
                            <TimezoneSelectFormItem
                                name={"timezone"}
                                label={t("Timezone")}
                                layout={verticalFormLayout}
                                required
                                disabled={mode === "view"}
                            />
                        </Col>

                        <Col xs={12}>
                            <InputFormItem
                                name={"username"}
                                label={t("Username")}
                                placeholder={"Username"}
                                layout={verticalFormLayout}
                                required
                                disabled={mode === "view"}
                            />
                        </Col>
                        <Col xs={12}>
                            <InputFormItem
                                name={"password"}
                                type="password"
                                label={t("Password")}
                                placeholder={t("Password")}
                                layout={verticalFormLayout}
                                required
                                disabled={mode === "view"}
                            />
                        </Col>

                        <Col xs={12}>
                            <RadioGroupFormItem
                                name={"isActive"}
                                label={t("Status")}
                                options={[
                                    {label: t("Active"), value: true},
                                    {label: t("Inactive"), value: false},
                                ]}
                                layout={verticalFormLayout}
                                required
                                disabled={mode === "view"}
                            />
                        </Col>
                    </Row>
                )}

                {form.getValues().platform === "cTrader" && (
                    <>
                        <Row gutter={[16, 16]}>
                            <Col xs={12}>
                                <TimezoneSelectFormItem
                                    name={"timezone"}
                                    label={t("Timezone")}
                                    layout={verticalFormLayout}
                                    required
                                    disabled={mode === "view"}
                                />
                            </Col>
                            <Col xs={12}>
                                <RadioGroupFormItem
                                    name={"isActive"}
                                    label={t("Status")}
                                    options={[
                                        {label: t("Active"), value: true},
                                        {label: t("Inactive"), value: false},
                                    ]}
                                    layout={verticalFormLayout}
                                    required
                                    disabled={mode === "view"}
                                />
                            </Col>
                            <Col xs={12}>
                                <InputFormItem
                                    name={"username"}
                                    label={t("Id")}
                                    placeholder={"Id"}
                                    layout={verticalFormLayout}
                                    required
                                    disabled={mode === "view" || mode === "edit"}
                                />
                            </Col>
                            <Col xs={12}>
                                <InputFormItem
                                    name={"password"}
                                    label={t("Password")}
                                    placeholder={t("Password")}
                                    layout={verticalFormLayout}
                                    required
                                    disabled={mode === "view"}
                                />
                            </Col>
                            <Col xs={12}>
                                <InputFormItem
                                    name={"plantId"}
                                    label={t("Plant Id")}
                                    placeholder={"Plant Id"}
                                    layout={verticalFormLayout}
                                    required
                                    disabled={mode === "view" || mode === "edit"}
                                />
                            </Col>
                            <Col xs={12}>
                                <InputFormItem
                                    name={"environment"}
                                    label={t("Environment")}
                                    placeholder={t("Environment")}
                                    layout={verticalFormLayout}
                                    required
                                    disabled
                                    //   disabled={mode === "view" || mode === "edit"}
                                />
                            </Col>
                            <Col xs={12}>
                                <InputFormItem
                                    name={"proxy"}
                                    label={t("Proxy")}
                                    placeholder={"Proxy"}
                                    layout={verticalFormLayout}
                                    required
                                    disabled={mode === "view" || mode === "edit"}
                                />
                            </Col>
                            <Col xs={12}>
                                <InputFormItem
                                    name={"proxyName"}
                                    label={t("Proxy Name")}
                                    placeholder={"Proxy Name"}
                                    layout={verticalFormLayout}
                                    required
                                    disabled={mode === "view" || mode === "edit"}
                                />
                            </Col>
                            <Col xs={12}>
                                <InputFormItem
                                    name={"resetPasswordLink"}
                                    label={t("Reset Password Link")}
                                    placeholder={"Reset Password Link"}
                                    layout={verticalFormLayout}
                                    disabled={mode === "view"}
                                />
                            </Col>
                        </Row>
                    </>
                )}

                {
                    platform != "" && clientFields?.length > 0 && (
                        <>
                            <Divider/>
                            <h4 style={{marginBottom: "3%"}}>
                                Trading Platform Field Configuration ({platform})
                            </h4>


                            <Row gutter={[16, 16]}>


                                {
                                    form.getValues().platform === "xTrader" && (
                                        <>
                                            <Col xs={12}>
                                                <InputNumberFormItem
                                                    min={0}
                                                    max={99999}
                                                    required
                                                    name={"platformAccountNo"}
                                                    label={t("Platform Initial Account No")}
                                                    placeholder={t("Platform Initial Account No")}
                                                    layout={horizontalFormLayout}
                                                    disabled={mode === "view"}
                                                    style={{width: "100%"}}
                                                />
                                            </Col>
                                            <Col xs={12}/>
                                        </>
                                    )
                                }


                                {
                                    mt5Field?.map((item) => {
                                        return (
                                            <>
                                                <Col xs={12}>
                                                    <SelectFormItem
                                                        label={item}
                                                        showSearch
                                                        name={"tradingKey-" + item}
                                                        layout={verticalFormLayout}
                                                        options={clientFields}
                                                        mode={"single"}
                                                        disabled={mode === "view"}
                                                        optionFilterProp={"label"}
                                                        // required={item === "email" || item === "name"}
                                                        required={requiredFields?.includes(item)}
                                                    />
                                                </Col>
                                            </>
                                        )
                                    })
                                }
                            </Row>
                        </>
                    )
                }

                <Form.Item className="text-align-right" style={{paddingTop: "12px"}}>
                    {onCancel && (
                        <Button
                            onClick={onCancel}
                            icon={<CloseOutlined/>}
                            className="button-cancel-1"
                        >
                            {t("common.cancel")}
                        </Button>
                    )}
                    <Button
                        onClick={form.handleSubmit(onSubmit)}
                        icon={<SaveOutlined/>}
                        className="button-submit-1"
                    >
                        {t("common.submit")}
                    </Button>
                </Form.Item>
            </form>
        </FormProvider>
    );
};

export default PlatformServerForm;
