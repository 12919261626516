import React, {memo, useCallback, useEffect, useState} from "react";
import {Button, Form} from "antd";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import yupFormSchema from "../../../../../../../components/yup/yupFormSchema";
import InputFormItem from "../../../../../../../components/form/inputFormItem";
import {useTranslation} from "react-i18next";
import {CloseOutlined} from "@ant-design/icons";
import {useUpdateSymbolMappingCategory} from "../../../../../../../api/graphql/symbolMappingCategory";
import CascaderFormItem from "../../../../../../../components/form/cascaderFormItem";

const SymbolMappingCategoryForm = (props) => {
    const {item, server, close, securities, platform, assetClassMt5} = props

    const {t} = useTranslation();

    const {updateSymbolMappingCategory} = useUpdateSymbolMappingCategory()

    const [defaultSymbolValue, setDefaultSymbolValue] = useState<any>([])
    const [defaultSymbolValueMt5, setDefaultSymbolValueMt5] = useState<any>([])


    const yupSchema = yup.object().shape({
        categoryName: yupFormSchema.string("categoryName", {required: true}),
        symbol: yupFormSchema.array("symbol", {required: true}),
    });

    const yupSchemaMt5 = yup.object().shape({
        categoryName: yupFormSchema.string("categoryName", {required: true}),
        symbolMt5: yupFormSchema.array("symbolMt5", {required: true}),
    });

    const [initialValues] = useState(() => ({
        categoryName: item?.category?.categoryName,
    }))

    const securitiesFormOptions = [...securities]?.sort((a, b) => a.name > b.name ? 1 : -1)?.map(d => {
        return {
            label: d?.name,
            value: d?.symbolCategoryList?.symbolCategoryId,
            children: d?.symbolCategoryList?.symbolList?.map(item => {
                return {
                    label: item?.name,
                    value: item?._id,
                }
            })
        }
    })

    const securitiesFormMt5Options = [...assetClassMt5]?.sort((a, b) => a.name > b.name ? 1 : -1)?.map(d => {
        return {
            label: d?.name,
            value: d?._id,
            children: d?.symbols?.map(item => {
                return {
                    label: item?.symbol,
                    value: item?._id,
                }
            })
        }
    })

    const processDefaultSymbolValueMt5 = useCallback(async (data) => {
        const mappedData = data?.map(item => {
            const foundOption = securitiesFormMt5Options.find(opt => opt.children.some(child => child.value === item));
            if (foundOption) {
                return [foundOption.value, item];
            }
        });
        return mappedData?.filter(item => item !== undefined);
    }, [item]);


    const processDefaultSymbolValue = useCallback(async (data) => {
        const mappedData = data?.map(item => {
            const foundOption = securitiesFormOptions.find(opt => opt.children.some(child => child.value === item));
            if (foundOption) {
                return [foundOption.value, item];
            }
        });

        return mappedData?.filter(item => item !== undefined);
    }, [item]);

    useEffect(() => {

        if ((platform === "mt5" || platform === "xTrader") && assetClassMt5) {
            const defaultSymbols = item?.symbolMt5?.map(d => d?._id)
            processDefaultSymbolValueMt5(defaultSymbols)?.then(data => {
                setDefaultSymbolValueMt5(data)
            })
        } else if (platform === "cTrader") {
            const defaultSymbols = item?.symbol?.map(d => d?._id)
            processDefaultSymbolValue(defaultSymbols)?.then(data => setDefaultSymbolValue(data))
        }

    }, [item, platform, assetClassMt5])


    const form = (platform === "mt5" || platform === "xTrader")  ? useForm({
        resolver: yupResolver(yupSchemaMt5),
        mode: "all",
        defaultValues: initialValues
    }) : useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
        defaultValues: initialValues
    });

    const onSubmit = async (value) => {

        if (platform === "mt5") {
            await updateSymbolMappingCategory({
                category: item?.category?._id,
                symbolMt5: value?.symbolMt5?.map(d => d[1]),
                server: server
            })
        } else {
            await updateSymbolMappingCategory({
                category: item?.category?._id,
                symbol: value?.symbol?.map(d => d[1]),
                server: server
            })
        }

        close()
    }

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="form-1">
                {/*{errMsg && <p style={{color:"red"}}>{errMsg}</p>}*/}
                <InputFormItem
                    name={"categoryName"}
                    label={t("categoryName")}
                    labelCol={{span: 8, offset: 0}}
                    wrapperCol={{span: 16, offset: 0}}
                    disabled
                />
                {
                    (platform === "mt5" || platform === "xTrader") ? (
                        <CascaderFormItem
                            name={"symbolMt5"}
                            label={t("Symbol")}
                            placeholder={t("Symbol")}
                            labelCol={{span: 8, offset: 0}}
                            wrapperCol={{span: 16, offset: 0}}
                            options={securitiesFormMt5Options}
                            value={defaultSymbolValueMt5}
                            multiple
                            showChild
                        />
                    ) : (
                        <CascaderFormItem
                            name={"symbol"}
                            label={t("Symbol")}
                            placeholder={t("Symbol")}
                            labelCol={{span: 8, offset: 0}}
                            wrapperCol={{span: 16, offset: 0}}
                            options={securitiesFormOptions}
                            value={defaultSymbolValue}
                            multiple
                            showChild
                        />
                    )
                }

                <Form.Item className="text-align-right" style={{paddingTop: "12px"}}>
                    <Button
                        onClick={close}
                        icon={<CloseOutlined/>}
                        className="button-cancel-1"
                    >
                        {t("common.cancel")}
                    </Button>
                    <Button
                        onClick={form.handleSubmit(onSubmit)}

                        className="button-submit-1"
                    >
                        {t("common.submit")}
                    </Button>
                </Form.Item>
            </form>
        </FormProvider>
    )
}

export default memo(SymbolMappingCategoryForm)

