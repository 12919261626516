import React from "react";
import {ColumnsType} from "antd/es/table";
import { Image, Space, Tag } from "antd";
import moment from "moment";


export const columns: ColumnsType<any> = [
    {
        title: "Position",
        dataIndex: "position",
        key: "position",
        render: (text) => <p>{text}</p>,
    },
    {
        title: "",
        dataIndex: "imageLight",
        key: "imageLight",
        render: (field) => {
            if(field?.length > 0){
                return <Image className="tableIcon" src={field[0]?.path} alt="logo" />
            }
            else {
                return <></>
            }
        },
    },
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        defaultSortOrder: "ascend",
        sorter: (a, b) => a?.name?.localeCompare(b?.name),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Display Name",
        dataIndex: "displayName",
        key: "displayName",
        defaultSortOrder: "ascend",
        sorter: (a, b) => a?.displayName?.filter(item => item.language === "en")?.[0]?.content.localeCompare(b?.displayName?.filter(item => item.language === "en")?.[0]?.content),
        render: (_, record) => <p>{record?.displayName?.filter(item => item.language === "en")?.[0]?.content}</p>,
    },
    {
        title: "Description",
        dataIndex: "description",
        key: "description",
        defaultSortOrder: "ascend",
        sorter: (a, b) => a?.description?.filter(item => item.language === "en")?.[0]?.content.localeCompare(b?.description?.filter(item => item.language === "en")?.[0]?.content),
        render: (_, record) => {
            if(record?.description?.filter(item => item.language === "en").length > 0){
                return <p>{record?.description?.filter(item => item.language === "en")?.[0]?.content?.substring(0, 30)}...</p>
            }
            else {
                return <></>
            }
        },
    },
    {
        title: "Mission Code",
        dataIndex: "missionCode",
        key: "missionCode",
        sorter: (a, b) => a?.missionCode?.localeCompare(b?.missionCode),
        render: (text) => <p>{text?.toString()}</p>,
    },
    {
        title: "Network Type",
        dataIndex: "networkType",
        key: "networkType",
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Network Include",
        dataIndex: "networkInclude",
        key: "networkInclude",
        width: 250,
        render: (_, record) => (
            <span>
                <Space size={[0, 8]} wrap>
                    {record && record?.networkType === "partial" && record?.network && record?.network.length > 0
                        ? record?.network?.map((item) => {
                              return (
                                  <Tag color="success" key={item?.username}>
                                      {item?.username}
                                  </Tag>
                              );
                          })
                        : "-"}
                </Space>
            </span>
        ),
    },
    {
        title: "Network Exclude",
        key: "networkExclude",
        dataIndex: "networkExclude",
        width: 250,
        render: (_, record) => (
            <span>
                {record && record?.networkType === "partial" && record?.exclude && record?.exclude.length > 0
                    ? record?.exclude?.map((tag) => {
                          return (
                              <Tag color="error" key={tag?.username}>
                                  {tag?.username}
                              </Tag>
                          );
                      })
                    : "-"}
            </span>
        ),
    },
    {
        title: "Mission Start Date",
        key: "missionEventDurationStartDate",
        dataIndex: "missionEventDurationStartDate",
        sorter: (a, b) => moment(a) > moment(b),
        render: (text) => <p>{text ? moment(text)?.format("DD/MM/YYYY") : ""}</p>,
    },
    {
        title: "Mission End Date",
        key: "missionEventDurationEndDate",
        dataIndex: "missionEventDurationEndDate",
        sorter: (a, b) => moment(a) > moment(b),
        render: (text) => <p>{text ? moment(text)?.format("DD/MM/YYYY") : ""}</p>,
    },
];

export const userClaimMissionColumn: ColumnsType<any> = [
    {
        title: "Mission",
        dataIndex: "missionId",
        key: "missionId",
        render: (record) => <p>{record?.name}</p>,
    },
    {
        title: "Username",
        dataIndex: "user",
        key: "user",
        render: (record) => <p>{record?.username}</p>,
    },
    {
        title: "Mission Completed",
        dataIndex: "isMissionCompleted",
        key: "isMissionCompleted",
        render: (field) => <p>{field?.toString()}</p>,
    },
    {
        title: "Voucher Issued",
        dataIndex: "isVoucherIssued",
        key: "isVoucherIssued",
        render: (field) => <p>{field?.toString()}</p>,
    },
    {
        title: "Mission Expired",
        dataIndex: "isMissionExpired",
        key: "isMissionExpired",
        render: (field) => <p>{field?.toString()}</p>,
    },
    {
        title: "Mission Claimed At",
        dataIndex: "missionClaimedAt",
        key: "missionClaimedAt",
        render: (text, record) => {
            return <p>{record?.missionClaimedAtEditedDate ? moment(record?.missionClaimedAtEditedDate).format("DD/MM/YYYY"): moment(text).format("DD/MM/YYYY")}</p>
        },
    },
    {
        title: "Mission Complete Date",
        dataIndex: "missionCompleteDate",
        key: "missionCompleteDate",
        render: (text) => {
            return <p>{text ? moment(text).format("DD/MM/YYYY") : ""}</p>
        },
    }
]