import React, {useEffect, useState} from "react";
import {useRecoilState, useSetRecoilState} from "recoil";
import ServerSettings_1 from "./view/layout1";
import {getServerSetting} from "../../../api/graphql/serverSetting";
import {columns} from "./function/tableColumn";
import {selfPermissionObj, timezoneList} from "../../../../recoil_state";
import {getTimezoneList} from "../../../api/graphql/_common";

function ServerSettings() {
    let layout = 1;
    let component;
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const setTimezoneList = useSetRecoilState(timezoneList);
    const serverSettingPermission = selfPermission?.brandSetting?.serverSetting;
    const [orderBy, setOrderBy] = useState(null);
    const {loading, data, error} = getServerSetting({filter: null}, 0, 0, orderBy);
    const {data: timezoneData} = getTimezoneList();

    useEffect(() => {
        setTimezoneList(timezoneData?.getTimezoneList);
    }, [timezoneData]);

    switch (layout) {
        case 1:
            component = (
                <ServerSettings_1
                    data={data?.getServerSetting?.data}
                    columns={columns}
                    loading={loading}
                    error={error}
                    serverSettingPermission={serverSettingPermission}
                    setOrderBy={setOrderBy}
                    orderBy={orderBy}
                />
            );
            break;

        default:
            component = (
                <ServerSettings_1
                    data={data?.getServerSetting?.data}
                    columns={columns}
                    loading={loading}
                    error={error}
                    serverSettingPermission={serverSettingPermission}
                />
            );
            break;
    }

    return component;
}

export default ServerSettings;
