import {gql, useQuery} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";

const GET_COMPANY_EMAIL_TYPES_COUNT = gql`
    query getCompanyEmailTypesCount($filter: CompanyEmailTypesSearchInput) {
        getCompanyEmailTypesCount(filter: $filter)
    }
`;

const getCompanyEmailTypesCount = async ({filter}) => {
    try {
        const execute = await ApolloClientQuery(
            GET_COMPANY_EMAIL_TYPES_COUNT,
            {
                filter: {
                    name: filter?.name || null,
                },
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_COMPANY_EMAIL_TYPES_BY_ID = gql`
    query getCompanyEmailTypesByID($id: String!) {
        getCompanyEmailTypesByID(id: $id) {
            ... on CompanyEmailTypes {
                id
                _id
                name
                isDeleted
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getCompanyEmailTypesById = (id) => {
    try {
        const {loading, error, data} = useQuery(GET_COMPANY_EMAIL_TYPES_BY_ID, {
            variables: {
                id: id,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_COMPANY_EMAIL_TYPES = gql`
    query getCompanyEmailTypes($filter: CompanyEmailTypesSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
        getCompanyEmailTypes(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on CompanyEmailTypesArray {
                data {
                    id
                    _id
                    name
                    isDeleted
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getCompanyEmailTypes = async ({filter}, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_COMPANY_EMAIL_TYPES,
            {
                filter: {
                    name: filter?.name || null,
                },
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_COMPANY_EMAIL_TYPES = gql`
    mutation addCompanyEmailTypes($input: CompanyEmailTypesInput) {
        addCompanyEmailTypes(input: $input) {
            ... on CompanyEmailTypes {
                id
                _id
                name
                isDeleted
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const addCompanyEmailTypes = async (input) => {
    const variables = {
        input: {
            name: input.name || null,
        },
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(ADD_COMPANY_EMAIL_TYPES, variables, true);

    return execute;
};

const UPDATE_COMPANY_EMAIL_TYPES = gql`
    mutation updateCompanyEmailTypes($id: String!, $input: CompanyEmailTypesInput) {
        updateCompanyEmailTypes(id: $id, input: $input) {
            ... on CompanyEmailTypes {
                id
                _id
                name
                isDeleted
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const updateCompanyEmailTypes = async (id, input) => {
    const variables = {
        id: id,
        input: {
            name: input.name || null,
        },
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(UPDATE_COMPANY_EMAIL_TYPES, variables, true);

    return execute;
};

const DELETE_COMPANY_EMAIL_TYPES = gql`
    mutation deleteCompanyEmailTypes($id: String!) {
        deleteCompanyEmailTypes(id: $id) {
            ... on CompanyEmailTypes {
                id
                _id
                isDeleted
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const deleteCompanyEmailTypes = async (id) => {
    const variables = {
        id: id,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(DELETE_COMPANY_EMAIL_TYPES, variables, true);

    return execute;
};

export {
    getCompanyEmailTypesCount,
    getCompanyEmailTypesById,
    getCompanyEmailTypes,
    addCompanyEmailTypes,
    updateCompanyEmailTypes,
    deleteCompanyEmailTypes,
};
