import {gql, useQuery} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";

const GET_PSP_SETTING_FIELD = gql`
  query getPspSettingField( $filter: PspSettingFieldInput, $limit: Int, $offset: Int, $orderBy: String ) {
    getPspSettingField( filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy ) {
      ...on PspSettingFieldArray {
        data {
          _id
          id
          pspSetting {
            _id
            name
            displayName
            description
            enabled
            depositEnabled
            withdrawalEnabled
          }
          fieldName
          fieldDisplayName
          fieldType
          fieldDisplayName
        }
      }
      ...on BaseError {
        errKey
        errMsg
      }
    }
  }

`;

const getPspSettingField = ({ filter }, limit?, offset?, orderBy?) => {
    try {
        const { loading, error, data } = useQuery(GET_PSP_SETTING_FIELD, {
            variables: {
                filter: filter,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return { loading, error, data };
    } catch (err: any) {
        console.error(err);
        return { data: null, loading: false, error: err };
    }
};

const getPspSettingFieldAsync = async ({ filter }, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_PSP_SETTING_FIELD,
            {
                filter: filter,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

export {
    getPspSettingField,
    getPspSettingFieldAsync
}