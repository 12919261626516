import {useState} from "react";
import {getBadgeSetting} from "../../../../../api/restful/arena/badgeSetting";

export const useGetBadgeSetting = () => {
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(true)

    const fetchBadgeSetting = async () => {
        try {
            setLoading(true)
            const {data} = await getBadgeSetting()

            console.log(data)
            setData(data || [])
        } catch (e) {

        } finally {
            setLoading(false)
        }
    }

    return [data, loading, fetchBadgeSetting] as const
}