import {useState} from "react";
import {getGiftSetting} from "../../../../../api/restful/arena/giftSetting";

export const useGetGiftSetting = () => {
    const [data, setData] = useState<any>(null)

    const fetchGiftSetting = async () => {
        try {
            const {data} = await getGiftSetting()

            console.log(data)
            setData(data)
        } catch (e) {

        }
    }

    return [data, fetchGiftSetting] as const
}