import {Form, Checkbox, Space} from 'antd';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import FormErrors from './formErrors';

const CheckboxGroupFormItem = (props) => {
    const {
        layout,
        label,
        name,
        value,
        defaultValue,
        externalErrorMessage,
        onChange,
        hint,
        disabled,
        required,
        options,
        labelCol,
        labelAlign,
        loading
    } = props

    const {
        register,
        watch,
        setValue,
        formState: { touchedFields, isSubmitted, errors }
    } = useFormContext()

    const errorMessage = FormErrors.errorMessage(
        name,
        errors,
        touchedFields,
        isSubmitted,
        externalErrorMessage
    )

    const formHelperText = errorMessage || hint

    useEffect(() => {
        register(name)
    }, [register, name])

    useEffect(() => {
        if (value) {
            setValue(name, value, { shouldValidate: true });
        }
    }, [value])

    return (
        <Form.Item
            {...layout}
            label={label}
            labelCol={labelCol}
            labelWrap={true}
            required={required}
            validateStatus={errorMessage?'error':'success'}
            help={formHelperText}
            labelAlign={labelAlign}
            colon={false}
        >
            <Checkbox.Group
                name={name}
                defaultValue={defaultValue}
                value={watch(name)}
                disabled={disabled}
                onChange={(selectedValues) => {
                    setValue(name, selectedValues, { shouldValidate: true });
                    onChange && onChange(selectedValues);
                }}
            >
                <Space direction={"vertical"}>
                {options?.map((option, idx) => (
                    <Checkbox
                        style={{marginLeft: option?.isSub ? "1rem" : 0}}
                        key={idx}
                        id={option.value}
                        value={option.value}
                        checked={watch(name)}
                        disabled={(option?.isSub && !watch(name)?.includes(option?.parent)) || loading}
                    >
                        {option.label}
                    </Checkbox>
                ))}
                </Space>
            </Checkbox.Group>
        </Form.Item>
    )
}

CheckboxGroupFormItem.defaultProps = {
    required: false,
    disabled: false,
    labelCol: {span: 6, offset: 0},
}

CheckboxGroupFormItem.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.array,
    defaultValue: PropTypes.array,
    externalErrorMessage: PropTypes.string,
    options: PropTypes.array,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    layout: PropTypes.object,
    hint: PropTypes.string,
    labelAlign: PropTypes.string,
    loading: PropTypes.bool
}

export default CheckboxGroupFormItem