import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {CloseOutlined, QuestionCircleOutlined, SaveOutlined, UploadOutlined} from "@ant-design/icons";
import {Button, Col, Divider, Form, Row, Space, Tooltip, Upload} from "antd";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import yupFormSchema from "../../../../../../components/yup/yupFormSchema";
import SelectFormItem from "../../../../../../components/form/selectFormItem";
import {verticalFormLayout} from "../../../../../../components/form/formWrapper";
import useAuthorize from "../../../../../../_common/authorize";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../recoil_state";
import TextAreaInputFormItem from "../../../../../../components/form/textAreaInputFormItem";
import {
    addPushNotificationServerSetting,
    updatePushNotificationServerSetting
} from "../../../../../../api/graphql/pushNotificationServerSetting";
import {removeTypenameKey} from "../../../../../../function/_common";

const PushNotificationServerForm = (props) => {
    const {mode, item, closeModal, setSpinning, data} = props;
    const {t} = useTranslation();
    const [errMsg, setErrMsg] = useState("");
    const [values, setValues] = useState<any>({});
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [uploadedFields, setUploadedFields] = useState<any>({});
    const [isInitialValuesSet, setIsInitialValuesSet] = useState<boolean>(false);
    const {systemErrorNotification} = useAuthorize();

    const platformOptions = [
        {label: t("Web"), value: "web"},
        {label: t("Mobile"), value: "mobile"},
        {label: t("App (iOS)"), value: "app_ios"},
        {label: t("App (Android)"), value: "app_android"},
    ]

    const yupSchema = yup.object().shape({
        name: yupFormSchema.string("name", {required: true}),
        provider: yupFormSchema.string("provider", {required: true}),
        targetPlatform: yupFormSchema.string("targetPlatform", {required: true}),
        apiKey: yupFormSchema.string("apiKey", {required: true}),
        authDomain: yupFormSchema.string("authDomain", {required: true}),
        projectId: yupFormSchema.string("projectId", {required: true}),
        storageBucket: yupFormSchema.string("storageBucket", {required: true}),
        messagingSenderId: yupFormSchema.string("messagingSenderId", {required: true}),
        appId: yupFormSchema.string("appId", {required: true}),
        measurementId: yupFormSchema.string("measurementId"),
        vapid: yupFormSchema.string("vapid", {required: true}),
        type: yupFormSchema.string("type", {required: true}),
        project_id: yupFormSchema.string("project_id", {required: true}),
        private_key_id: yupFormSchema.string("private_key_id", {required: true}),
        private_key: yupFormSchema.string("private_key", {required: true}),
        client_email: yupFormSchema.string("client_email", {required: true}),
        client_id: yupFormSchema.string("client_id", {required: true}),
        auth_uri: yupFormSchema.string("auth_uri", {required: true}),
        token_uri: yupFormSchema.string("token_uri", {required: true}),
        auth_provider_x509_cert_url: yupFormSchema.string("auth_provider_x509_cert_url", {required: true}),
        client_x509_cert_url: yupFormSchema.string("client_x509_cert_url", {required: true}),
        universe_domain: yupFormSchema.string("universe_domain", {required: true}),
    });

    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
    });

    const onCancel = () => {
        closeModal();
    };

    const onSubmit = async () => {
        try {
            setSpinning(true);
            let input = values;
            input = await removeTypenameKey(input);
            const res =
                mode === "add" ?
                    await addPushNotificationServerSetting(input)
                    :
                    await updatePushNotificationServerSetting(item?._id, input);
            const response = mode === "add" ? res?.addPushNotificationServerSetting : res?.updatePushNotificationServerSetting;
            if (response?.__typename === "PushNotificationServerSetting") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: mode === "add" ? t("common.addSuccess") : t("common.updateSuccess"),
                    key: "addUpdatePushNotificationServerSetting",
                });
                closeModal();
            } else if (response?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.errKey),
                    key: "addUpdatePushNotificationServerSetting",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.msg),
                    key: "addUpdatePushNotificationServerSetting",
                });
                closeModal();
            }
        } catch (error: any) {
            setSpinning(false);
            systemErrorNotification();
        }
    };

    const setupInitialValues = async () => {
        try {
            const formValues: any = {
                name: item?.name || null,
                provider: item?.provider || null,
                targetPlatform: mode === "add" ? null : item?.targetPlatform || null,
                apiKey: item?.firebase?.config?.apiKey || null,
                authDomain: item?.firebase?.config?.authDomain || null,
                projectId: item?.firebase?.config?.projectId || null,
                storageBucket: item?.firebase?.config?.storageBucket || null,
                messagingSenderId: item?.firebase?.config?.messagingSenderId || null,
                appId: item?.firebase?.config?.appId || null,
                measurementId: item?.firebase?.config?.measurementId || null,
                vapid: item?.firebase?.vapid || null,
                type: item?.firebase?.credential?.type || null,
                project_id: item?.firebase?.credential?.project_id || null,
                private_key_id: item?.firebase?.credential?.private_key_id || null,
                private_key: item?.firebase?.credential?.private_key || null,
                client_email: item?.firebase?.credential?.client_email || null,
                client_id: item?.firebase?.credential?.client_id || null,
                auth_uri: item?.firebase?.credential?.auth_uri || null,
                token_uri: item?.firebase?.credential?.token_uri || null,
                auth_provider_x509_cert_url: item?.firebase?.credential?.auth_provider_x509_cert_url || null,
                client_x509_cert_url: item?.firebase?.credential?.client_x509_cert_url || null,
                universe_domain: item?.firebase?.credential?.universe_domain || null,
            };

            const nestedValues: any = {
                name: item?.name || null,
                provider: item?.provider || null,
                targetPlatform: mode === "add" ? null : item?.targetPlatform || null,
                firebase: {
                    config: {
                        apiKey: item?.firebase?.config?.apiKey || null,
                        authDomain: item?.firebase?.config?.authDomain || null,
                        projectId: item?.firebase?.config?.projectId || null,
                        storageBucket: item?.firebase?.config?.storageBucket || null,
                        messagingSenderId: item?.firebase?.config?.messagingSenderId || null,
                        appId: item?.firebase?.config?.appId || null,
                        measurementId: item?.firebase?.config?.measurementId || null,
                    },
                    vapid: item?.firebase?.vapid || null,
                    credential: {
                        type: item?.firebase?.credential?.type || null,
                        project_id: item?.firebase?.credential?.project_id || null,
                        private_key_id: item?.firebase?.credential?.private_key_id || null,
                        private_key: item?.firebase?.credential?.private_key || null,
                        client_email: item?.firebase?.credential?.client_email || null,
                        client_id: item?.firebase?.credential?.client_id || null,
                        auth_uri: item?.firebase?.credential?.auth_uri || null,
                        token_uri: item?.firebase?.credential?.token_uri || null,
                        auth_provider_x509_cert_url: item?.firebase?.credential?.auth_provider_x509_cert_url || null,
                        client_x509_cert_url: item?.firebase?.credential?.client_x509_cert_url || null,
                        universe_domain: item?.firebase?.credential?.universe_domain || null,
                    }
                }
            };

            form.reset(formValues);
            setValues(nestedValues);
            setIsInitialValuesSet(true)
        } catch (error) {
            console.log(error);
        }
    };

    const handleValueChange = async (field, value) => {
        try {
            const fieldParts = field.split(".");
            const mainField = fieldParts[0];
            const nestedField = fieldParts.slice(1).join(".");

            setValues((prevState) => {
                const updatedState = {...prevState};

                if (nestedField) {
                    if (!updatedState[mainField]) {
                        updatedState[mainField] = {};
                    }
                    const keys = nestedField.split(".");
                    let current = updatedState[mainField];

                    for (let i = 0; i < keys.length - 1; i++) {
                        if (!current[keys[i]]) {
                            current[keys[i]] = {};
                        }
                        current = current[keys[i]];
                    }

                    current[keys[keys.length - 1]] = value;
                } else {
                    updatedState[mainField] = value;
                }

                return updatedState;
            });


            form.setValue(field, value);  // Update form value
        } catch (error) {
            console.error("Error updating value:", error);
        }
    };

    useEffect(() => {
        !isInitialValuesSet && setupInitialValues();
    }, []);

    const handleFileUpload = (file: any) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            if (e.target && e.target.result) {
                try {
                    const json = JSON.parse(e.target.result as string);
                    setUploadedFields(json)

                    Object.keys(json).forEach(key => {
                        const value = json[key];

                        form.setValue(key, value || null);
                        handleValueChange(`firebase.credential.${key}`, value || null);

                    });
                } catch (error) {
                    console.error("Error parsing JSON file:", error);
                    setErrMsg("Invalid JSON file");
                }
            }
        };
        reader.readAsText(file);
        return false;
    };

    const isValueUploaded = (key) => {
        return uploadedFields.hasOwnProperty(key) && uploadedFields[key] !== "" && uploadedFields[key] !== null;
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="form-1">
                {errMsg && <p style={{color: "red"}}>{errMsg}</p>}
                <Divider orientation={"left"} orientationMargin={"0"}>
                    Basic Information
                </Divider>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <InputFormItem
                            name={"name"}
                            label={t("Name")}
                            placeholder={t("Name")}
                            layout={verticalFormLayout}
                            required
                            onChange={(value) => handleValueChange("name", value)}
                            disabled={mode === "view"}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <SelectFormItem
                            name={"provider"}
                            label={t("Provider")}
                            placeholder={t("Provider")}
                            options={[
                                {label: t("Firebase"), value: "firebase"},
                            ]}
                            layout={verticalFormLayout}
                            required
                            disabled={mode === "view" || mode === "edit"}
                            onChange={(value) => {
                                handleValueChange("provider", value)
                                form.setValue("targetPlatform", null)
                            }}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <SelectFormItem
                            name={"targetPlatform"}
                            label={t("Platform")}
                            placeholder={t("Platform")}
                            options={mode === "view" || mode === "edit" ? platformOptions : platformOptions.filter(option => {
                                const isPlatformUsed = data?.some(setting => setting.provider === form.getValues().provider && setting.targetPlatform === option.value);
                                return !isPlatformUsed;
                            })}
                            layout={verticalFormLayout}
                            required
                            disabled={mode === "view" || mode === "edit" || !form.getValues().provider}
                            onChange={(value) => handleValueChange("targetPlatform", value)}
                        />
                    </Col>
                </Row>
                <Divider orientation={"left"} orientationMargin={"0"}>
                    Configuration
                </Divider>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <InputFormItem
                            name={"apiKey"}
                            label={t("API Key")}
                            placeholder={t("API Key")}
                            layout={verticalFormLayout}
                            required
                            onChange={(value) => handleValueChange("firebase.config.apiKey", value)}
                            disabled={mode === "view"}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <InputFormItem
                            name={"authDomain"}
                            label={t("Auth Domain")}
                            placeholder={t("Auth Domain")}
                            layout={verticalFormLayout}
                            required
                            onChange={(value) => handleValueChange("firebase.config.authDomain", value)}
                            disabled={mode === "view"}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <InputFormItem
                            name={"projectId"}
                            label={t("Project ID")}
                            placeholder={t("Project ID")}
                            layout={verticalFormLayout}
                            required
                            onChange={(value) => handleValueChange("firebase.config.projectId", value)}
                            disabled={mode === "view"}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <InputFormItem
                            name={"storageBucket"}
                            label={t("Storage Bucket")}
                            placeholder={t("Storage Bucket")}
                            layout={verticalFormLayout}
                            required
                            onChange={(value) => handleValueChange("firebase.config.storageBucket", value)}
                            disabled={mode === "view"}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <InputFormItem
                            name={"messagingSenderId"}
                            label={t("Message Sender ID")}
                            placeholder={t("Message Sender ID")}
                            layout={verticalFormLayout}
                            required
                            onChange={(value) => handleValueChange("firebase.config.messagingSenderId", value)}
                            disabled={mode === "view"}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <InputFormItem
                            name={"appId"}
                            label={t("App ID")}
                            placeholder={t("App ID")}
                            layout={verticalFormLayout}
                            required
                            onChange={(value) => handleValueChange("firebase.config.appId", value)}
                            disabled={mode === "view"}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <InputFormItem
                            name={"measurementId"}
                            label={t("Measurement ID")}
                            placeholder={t("Measurement ID")}
                            layout={verticalFormLayout}
                            tooltip={"Only available if Google Analytics is turned on"}
                            onChange={(value) => handleValueChange("firebase.config.measurementId", value)}
                            disabled={mode === "view"}
                        />
                    </Col>
                </Row>
                <Divider orientation={"left"} orientationMargin={"0"}>
                    Web Push Certificate Key Pair
                </Divider>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <InputFormItem
                            name={"vapid"}
                            label={t("Web Push Certificate Key Pair")}
                            placeholder={t("Web Push Certificate Key Pair")}
                            layout={verticalFormLayout}
                            required
                            tooltip={"Generate a key pair on the Firebase console (Project > Your App > Settings > Cloud Messaging > Web Configuration)"}
                            onChange={(value) => handleValueChange("firebase.vapid", value)}
                            disabled={mode === "view"}
                        />
                    </Col>
                </Row>
                <Divider orientation={"left"} orientationMargin={"0"}>
                    <Space>
                        Credentials
                        {mode !== "view" &&
                        <>
                            <Upload
                                name={"JSON"}
                                // action={action}
                                style={{width: "100%"}}
                                accept={".json"}
                                maxCount={1}
                                showUploadList={false}
                                onChange={(e) => {
                                }}
                                beforeUpload={handleFileUpload}
                            >
                                <Button icon={<UploadOutlined/>}>Click to Upload</Button>
                            </Upload>
                            <Tooltip
                                title={"Generate a new private key on the Firebase console (Project > Your App > Settings > Service Accounts) and upload the .json file"}>
                                <QuestionCircleOutlined className={"form-title-tooltip"}/>
                            </Tooltip>
                        </>
                        }
                    </Space>
                </Divider>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <InputFormItem
                            name={"type"}
                            label={t("Type")}
                            placeholder={t("Type")}
                            layout={verticalFormLayout}
                            required
                            disabled={isValueUploaded("type") || mode === "view"}
                            onChange={(value) => handleValueChange("firebase.credential.type", value)}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <InputFormItem
                            name={"project_id"}
                            label={t("Project ID")}
                            placeholder={t("Project ID")}
                            layout={verticalFormLayout}
                            required
                            disabled={isValueUploaded("project_id") || mode === "view"}
                            onChange={(value) => handleValueChange("firebase.credential.project_id", value)}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <InputFormItem
                            name={"private_key_id"}
                            label={t("Private Key ID")}
                            placeholder={t("Private Key ID")}
                            layout={verticalFormLayout}
                            required
                            disabled={isValueUploaded("private_key_id") || mode === "view"}
                            onChange={(value) => handleValueChange("firebase.credential.private_key_id", value)}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <TextAreaInputFormItem
                            name={"private_key"}
                            label={t("Private Key")}
                            placeholder={t("Private Key")}
                            layout={verticalFormLayout}
                            required
                            disabled={isValueUploaded("private_key") || mode === "view"}
                            rows={1}
                            onChange={(value) => handleValueChange("firebase.credential.private_key", value)}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <InputFormItem
                            name={"client_email"}
                            label={t("Client Email")}
                            placeholder={t("Client Email")}
                            layout={verticalFormLayout}
                            required
                            disabled={isValueUploaded("client_email") || mode === "view"}
                            onChange={(value) => handleValueChange("firebase.credential.client_email", value)}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <InputFormItem
                            name={"client_id"}
                            label={t("Client ID")}
                            placeholder={t("Client ID")}
                            layout={verticalFormLayout}
                            required
                            disabled={isValueUploaded("client_id") || mode === "view"}
                            onChange={(value) => handleValueChange("firebase.credential.client_id", value)}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <InputFormItem
                            name={"auth_uri"}
                            label={t("Auth URI")}
                            placeholder={t("Auth URI")}
                            layout={verticalFormLayout}
                            required
                            disabled={isValueUploaded("auth_uri") || mode === "view"}
                            onChange={(value) => handleValueChange("firebase.credential.auth_uri", value)}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <InputFormItem
                            name={"token_uri"}
                            label={t("Token URI")}
                            placeholder={t("Token URI")}
                            layout={verticalFormLayout}
                            required
                            disabled={isValueUploaded("token_uri") || mode === "view"}
                            onChange={(value) => handleValueChange("firebase.credential.token_uri", value)}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <InputFormItem
                            name={"auth_provider_x509_cert_url"}
                            label={t("Auth Provider x509 Cert URL")}
                            placeholder={t("Auth Provider x509 Cert URL")}
                            layout={verticalFormLayout}
                            required
                            disabled={isValueUploaded("auth_provider_x509_cert_url") || mode === "view"}
                            onChange={(value) => handleValueChange("firebase.credential.auth_provider_x509_cert_url", value)}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <InputFormItem
                            name={"client_x509_cert_url"}
                            label={t("Client x509 Cert URL")}
                            placeholder={t("Client x509 Cert URL")}
                            layout={verticalFormLayout}
                            required
                            disabled={isValueUploaded("client_x509_cert_url") || mode === "view"}
                            onChange={(value) => handleValueChange("firebase.credential.client_x509_cert_url", value)}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <InputFormItem
                            name={"universe_domain"}
                            label={t("Universe Domain")}
                            placeholder={t("Universe Domain")}
                            layout={verticalFormLayout}
                            required
                            disabled={isValueUploaded("universe_domain") || mode === "view"}
                            onChange={(value) => handleValueChange("firebase.credential.universe_domain", value)}
                        />
                    </Col>
                </Row>
                <Form.Item className="text-align-right" style={{paddingTop: "12px"}}>
                    {onCancel && (
                        <Button
                            onClick={onCancel}
                            icon={<CloseOutlined/>}
                            className="button-cancel-1"
                        >
                            {mode === "view" ? t("common.close") : t("common.cancel")}
                        </Button>
                    )}
                    {mode !== "view" && (
                        <Button
                            onClick={form.handleSubmit(onSubmit)}
                            icon={<SaveOutlined/>}
                            className={"button-submit-1"}
                        >
                            {t("common.submit")}
                        </Button>
                    )}
                </Form.Item>
            </form>
        </FormProvider>
    );
};

export default PushNotificationServerForm;
