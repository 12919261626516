import React, {useEffect, useState} from "react";
import {Button, Divider, Row, Switch} from "antd";
import {useTranslation} from "react-i18next";
import {
    getPromoMaterialSetting,
    useUpdatePromoMaterialSettingMutation
} from "../../../../../../../api/graphql/promoMaterialSetting";
import useAuthorize from "../../../../../../../_common/authorize";

const PromoMaterialSetting = (props) => {
    const {setCustomizeSubmitButton, close} = props
    const {t} = useTranslation()

    const {data} = getPromoMaterialSetting()
    const {updatePromoMaterialSetting} = useUpdatePromoMaterialSettingMutation()
    const {systemErrorNotification} = useAuthorize()
    const [input, setInput] = useState<any>()

    const onSubmit = () => {
       try{
           updatePromoMaterialSetting(input)
           close()
       }catch (e) {
           systemErrorNotification()
       }
    }

    useEffect(() => {
        setInput(data?.getPromoMaterialSetting)
    }, [data?.getPromoMaterialSetting])

    useEffect(() => {
        setCustomizeSubmitButton(
            <Button onClick={onSubmit} type={"primary"}>{t("common.submit")}</Button>
        )
    }, [input])

    return (
        <>
            <Divider/>
            <div style={{padding: 16}}>
                <Row justify={"space-between"} className={"margin-top-0-75"}>
                    <label>{t("Banners")}</label>
                    <Switch
                        checked={input?.banners}
                        onChange={(value) => setInput({...input, banners: value})}
                    />
                </Row>
                <Row justify={"space-between"} className={"margin-top-0-75"}>
                    <label>{t("Videos")}</label>
                    <Switch
                        checked={input?.videos}
                        onChange={(value) => setInput({...input, videos: value})}
                    />
                </Row>
                <Row justify={"space-between"} className={"margin-top-0-75"}>
                    <label>{t("Landings")}</label>
                    <Switch
                        checked={input?.landings}
                        onChange={(value) => setInput({...input, landings: value})}
                    />
                </Row>
                <Row justify={"space-between"} className={"margin-top-0-75"}>
                    <label>{t("GIFs")}</label>
                    <Switch
                        checked={input?.gifs}
                        onChange={(value) => setInput({...input, gifs: value})}
                    />
                </Row>
                <Row justify={"space-between"} className={"margin-top-0-75"}>
                    <label>{t("Logos")}</label>
                    <Switch
                        checked={input?.logos}
                        onChange={(value) => setInput({...input, logos: value})}
                    />
                </Row>
                <Row justify={"space-between"} className={"margin-top-0-75"}>
                    <label>{t("Widgets")}</label>
                    <Switch
                        checked={input?.widgets}
                        onChange={(value) => setInput({...input, widgets: value})}
                    />
                </Row>
            </div>
        </>
    )
}

export default PromoMaterialSetting