import React from "react";
import {ExcotradesLoadingScreen} from "./view/excotrades";
import {BRAND_NAME} from "../../../config/brandVariable";
import {DefaultLoadingScreen} from "./view/default";

const LoadingScreen = () => {

    let component
    switch (BRAND_NAME) {
        case "excotrades":
            component = <ExcotradesLoadingScreen/>;
            break;

        default:
            component = <DefaultLoadingScreen/>;
            break;
    }

    return component;
}

export default LoadingScreen
