import React, {useEffect, useState} from "react";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {Button, Col, Form, Row} from "antd";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import yupFormSchema from "../../../../../../components/yup/yupFormSchema";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import {verticalFormLayout} from "../../../../../../components/form/formWrapper";
import RadioGroupFormItem from "../../../../../../components/form/radioGroupFormItem";
import {
    addPushNotificationTemplate,
    updatePushNotificationTemplate,
} from "../../../../../../api/graphql/pushNotificationTemplate";
import ButtonAntDesign from "../../../../../../components/button/buttonAntDesign";
import {getLanguage} from "../../../../../../api/graphql/language";
import useAuthorize from "../../../../../../_common/authorize";
import SelectFormItem from "../../../../../../components/form/selectFormItem";
import {removeTypenameKey} from "../../../../../../function/_common";

const yupSchema = yup.object().shape({
    name: yupFormSchema.string("name", {required: true})
        .test(
            "no-edited",
            `Name cannot contain "- Edited"`,
            value => value ? !value.includes("- Edited") : true
        ),
    isActive: yupFormSchema.boolean("isActive", {required: true}),
    targetPlatform: yupFormSchema.array("targetPlatform", {required: true}),
});

const TemplateForm = (props) => {
    const {mode, item, closeModal} = props;
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [addLoading, setAddLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const {systemErrorNotification} = useAuthorize();
    const [errMsg, setErrMsg] = useState("");
    const [initialValues] = useState(() => ({
        name: item?.name || "",
        isActive: item?.isActive || false,
        targetPlatform: item?.targetPlatform || [],
    }));
    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
        defaultValues: initialValues,
    });

    const {languageData} = getLanguage({filter: null});

    const onCancel = () => closeModal();

    const setupDataObject = () => {
        return languageData?.getLanguage?.data?.map((lang) => ({
            data: null,
            notification: {
                title: "",
                body: "",
                icon: "",
                image: "",
            },
            longContent: "",
            language: lang.code,
        }));
    };

    const openEditor = (templateId?, mode?) => {
        // navigate(`/settings/push-notification-template-editor/${templateId}?mode=${mode}`);
        window.open(
          `${window.location.origin}/settings/push-notification-template-editor/${templateId}?mode=${mode}`,
          "_blank"
        );
    };

    const onSubmit = async (values) => {
        try {
            const result = {
                ...values,
                dataArrayOfObject: item?.dataArrayOfObject || setupDataObject(),
            };
            const data = mode === "add" ?
                await addPushNotificationTemplate(result)
                :
                await updatePushNotificationTemplate(item?._id, removeTypenameKey(result));
            const res = mode === "add" ? data?.addPushNotificationTemplate : data?.updatePushNotificationTemplate;
            if (res?.__typename === "BaseError") {
                let errMsg = t(res?.errKey);
                setErrMsg(errMsg);
            } else {
                if (res && mode === "add") {
                    openEditor(res?._id, "add");
                }
                setErrMsg("");
                closeModal();
            }
        } catch (e: any) {
            console.log(e);
            systemErrorNotification();
        }
    };

    useEffect(() => {
        if (form.getValues("targetPlatform") && form.getValues("targetPlatform").length === 0) {
            form.setValue("targetPlatform", null)
        }
    })

    return (
        <FormProvider {...form}>
            <form onSubmit={form?.handleSubmit(onSubmit)} className="emailTemplate-form-1">
                {errMsg && <p style={{color: "red"}}>{errMsg}</p>}
                <Row gutter={16}>
                    <Col className="gutter-row" xs={24} md={12}>
                        <InputFormItem
                            name={"name"}
                            label={"Name"}
                            placeholder={"Name"}
                            layout={verticalFormLayout}
                            disabled={mode === "view"}
                            required
                        />
                    </Col>
                    <Col className="gutter-row" xs={24} md={12}>
                        <RadioGroupFormItem
                            name={"isActive"}
                            label={t("Status")}
                            options={[
                                {label: t("Active"), value: true},
                                {label: t("Inactive"), value: false},
                            ]}
                            layout={verticalFormLayout}
                            disabled={mode === "view"}
                            required
                        />
                    </Col>
                    <Col className="gutter-row" xs={24} md={12}>
                        <SelectFormItem
                            name={"targetPlatform"}
                            label={t("Platform")}
                            placeholder={t("Platform")}
                            layout={verticalFormLayout}
                            disabled={mode === "view"}
                            allowClear
                            options={[
                                {label: t("Web"), value: "web"},
                                {label: t("Mobile"), value: "mobile"},
                                {label: t("App (iOS)"), value: "app_ios"},
                                {label: t("App (Android)"), value: "app_android"},
                            ]}
                            mode={"multiple"}
                            required
                        />
                    </Col>
                </Row>
                {props?.children}
                {mode !== "add" && (
                    <ButtonAntDesign
                        text={mode === "edit" ? t("Open in Editor") : t("View in Editor")}
                        onClick={() => openEditor(item?._id, mode)}
                        type="primary"
                        className="margin-top-0-75"
                    />
                )}
                <Form.Item className="text-align-right" style={{paddingTop: "12px"}}>
                    {onCancel && (
                        <Button onClick={onCancel} icon={<CloseOutlined/>} className="button-cancel-1">
                            {mode === "view" ? t("Close") : t("common.cancel")}
                        </Button>
                    )}
                    {mode !== "view" && (
                        <Button
                            disabled={addLoading || updateLoading}
                            onClick={form?.handleSubmit(onSubmit)}
                            icon={<SaveOutlined/>}
                            className="button-submit-1"
                        >
                            {t("common.submit")}
                        </Button>
                    )}
                </Form.Item>
            </form>
        </FormProvider>
    );
};

export default TemplateForm;
