import React from "react";
import {Link} from "react-router-dom";
import type {MenuProps} from "antd";
import {LayoutOutlined, UserOutlined, SettingOutlined, FileDoneOutlined, UsergroupAddOutlined, FileTextOutlined, MailOutlined} from "@ant-design/icons";
import {protectedRoute} from "../../../routers/protectedRoute";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    path?: String | null,
    lang?: String | null,
    icon?: React.ReactNode | null,
    children?: MenuItem[] | null,
    type?: "group" | null,
    permission?: String[] | null
): MenuItem {
    return {
        key,
        path,
        lang,
        icon,
        children,
        label,
        type,
        permission,
    } as MenuItem;
}

const settingsArray = protectedRoute?.filter((route) => route.headerCategory === "Settings");

const items: MenuProps["items"] = [
    // getItem(
    //   <Link to="/dashboard">Dashboard</Link>,
    //   "m1",
    //   <LayoutOutlined />,
    //   null,
    //   null,
    //   null
    // ),
    getItem(
        "Users",
        "user",
        null,
        "module.users",
        <UsergroupAddOutlined />,
        protectedRoute
            .filter((route) => route.headerCategory === "Users") // Filter routes with category "settings"
            .map((route, index) => {
                const path = route.path;
                const name = route.name;
                const headerSubcategoryName = route.headerSubcategoryName;
                const key = `user${index + 1}`;
                const lang = route.lang;
                const permission: any = [route?.permission];
                return {
                    key,
                    path,
                    lang,
                    label: <Link to={path}>{name}</Link>,
                    permission,
                    headerSubcategoryName,
                };
            }),
        null,
        ["user:clientsUser:view:view", "user:crmUser:view:view", "user:lead:view:view", "user:salesOpportunity:view"]
    ),
    getItem(
        "Accounts",
        "accounts",
        null,
        "module.accounts",
        <UserOutlined />,
        protectedRoute
            .filter((route) => route.headerCategory === "Accounts") // Filter routes with category "settings"
            .map((route, index) => {
                const path = route.path;
                const name = route.name;
                const headerSubcategoryName = route.headerSubcategoryName;
                const key = `accounts${index + 1}`;
                const lang = route.lang;
                const permission: any = [route?.permission];
                return {
                    key,
                    path,
                    lang,
                    label: <Link to={path}>{name}</Link>,
                    permission,
                    headerSubcategoryName,
                };
            }),
        null,
        ["tradingAccount:account:view:view", "tradingAccountMt5:moneyManagerAccount:view:view"]
    ),
    getItem(<Link to="/task">Task</Link>, "task", "/task", "module.task", <FileDoneOutlined />, null, null, ["task:task:view"]),
    getItem(<Link to="/reports">Reports</Link>, "reports", "/reports", "module.reports", <FileTextOutlined />, null, null, ["reports:reports:view"]),
    getItem(<Link to="/message">Message</Link>, "message", "/message", "module.message", <MailOutlined />, null, null, ["message:message:view"]),
    getItem(
        "Setting",
        "setting",
        null,
        "module.setting",
        <SettingOutlined />,
        settingsArray // Filter routes with category "settings"
            ?.map((route, index) => {
                const path = route.path;
                const name = route.headerSubcategoryName;
                const headerSubcategoryName = route.headerSubcategoryName;
                const lang = route.lang;
                const key = `setting${index + 1}`;
                const permission: any = [route?.permission];
                return {
                    key,
                    path,
                    lang,
                    label: <Link to={path}>{name}</Link>,
                    permission,
                    headerSubcategoryName,
                };
            }),
        null,
        [
            "brandSetting:serverSetting:view",
            "brandSetting:brandDetails:view",
            "brandSetting:iconList:view",
            "brandSetting:currencySetting:view",
            "brandSetting:platformDownload:view",
            "brandSetting:companyEmail:view",
            "brandSetting:moduleSettings:view",
            "brandSetting:language:view",
            "userSetting:roleTemplate:view",
            "userSetting:userNetworkTree:view",
            "userSetting:salesRepNetworkTree:view",
            "userSetting:desk:view",
            "userSetting:fields:view",
            "userSetting:registrationWorkflow:view",
            "userSetting:accountVerification:view",
            "accountSetting:assets:view",
            "accountSetting:symbol:view",
            "accountSetting:securitiesGroup:view",
            "accountSetting:spreadGroup:view",
            "accountSetting:productGroup:view",
            "accountSetting:product:view",
            "depositWithdrawalSetting:pspSetting:view",
            "commission:setting:view",
            "messageSetting:dynamicVariable:view",
            "messageSetting:emailTemplate:view",
            "messageSetting:pushNotificationTemplate:view",
            "systemSetting:cron:view",
            "activitiesAndRewardSetting:mission:view",
            "activitiesAndRewardSetting:voucher:view",
            "activitiesAndRewardSetting:voucherProgram:view",
            "activities:promoMaterial:view",
            "mampamm:provider:view",
            "mampamm:product:view",
            "mampamm:legalDocument:view",
            "automation:setting:view"
        ]
    ),
];

export {items};
