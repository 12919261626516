import React, {useState} from "react";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import {columnLoggerDetails} from "../../../function/tableColumn";
import {useTranslation} from "react-i18next";
import {Button, Col, Row, Space, Tooltip} from "antd";
import EditTableUserPreferenceButton from "../../../../../../components/table/function/editTableUserPreferenceButton";
import {EyeOutlined} from "@ant-design/icons";
import LogJSONModal from "./logJsonModal";

const LogTable = (props) => {

    const {
        data,
        total,
        limit,
        setLimit,
        setOffset,
        setOrder,
        setStartDate,
        setEndDate,
        setDateKey,
        setFilter,
        refetchQuery,
        loading,
        tableName,
        idModal
    } = props
    const {t} = useTranslation()

    const columns = columnLoggerDetails(idModal)

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [recordModal, setRecordModal] = useState<any>({});

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize)
        setOffset(limit * (page - 1))
    }

    return (
        <div className={"margin-top-0-75"}>
            <Row justify="space-between" style={{marginBottom: "15px"}}>
                <Col span={17}>
                    <h1>{t(tableName)}</h1>
                </Col>
                <Space>
                    <Button onClick={refetchQuery}>
                        Refresh
                    </Button>
                    <EditTableUserPreferenceButton tableName={tableName} tableColumn={columns}/>
                </Space>
            </Row>
            <TableAntDesign data={data}
                            tableName={tableName}
                            columns={[...columns,
                                {
                                    title: t("common.action"),
                                    key: "action",
                                    width: 132,
                                    render: (_, record: any) => record?.result || record?.payload ? (

                                        <Space size="middle">
                                            <Tooltip title={t("View")}>
                                                <a onClick={() => {
                                                    setIsOpenModal(true)
                                                    setRecordModal(record)
                                                }}>
                                                    <EyeOutlined/>
                                                </a>
                                            </Tooltip>
                                        </Space>
                                    ) : null,
                                },
                            ]}
                            size={"small"}
                            server={null}
                            loading={loading}
                            filter={setFilter}
                            startDate={setStartDate}
                            endDate={setEndDate}
                            dateKey={setDateKey}
                            pagination={{
                                pageSize: limit,
                                showSizeChanger: true,
                                position: "bottomCenter",
                                total: total,
                                onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                                showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                            }}
            />

            <LogJSONModal isOpen={isOpenModal} record={recordModal} closeModal={() => {
                setIsOpenModal(false)
                setRecordModal({})
            }}/>
        </div>
    )
}

export default LogTable