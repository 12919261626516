import React from "react";
import {useRecoilState} from "recoil";
import {Button} from "antd";
import {selfPermissionObj} from "../../../../../../../../recoil_state";
import {useTranslation} from "react-i18next";

const AccountChangePassword = ({data, id}) => {
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const tradingAccountPermission = selfPermission?.tradingAccount?.account;
    const {t} = useTranslation();

    return (
        <div id="AccountChangePassword">
            {
                data?.product?.server?.resetPasswordLink ?
                    <iframe
                        style={{height: "70vh"}}
                        width="100%"
                        loading="lazy"
                        allowFullScreen
                        src={data?.product?.server?.resetPasswordLink}
                        scrolling={"auto"}
                    />
                    :
                    <Button
                        type="default"
                        onClick={() => {
                            window.open("https://id.ctrader.com/reset", "_blank");
                        }}
                        disabled={!tradingAccountPermission?.edit?.changePassword}
                    >
                        {t("tradingAccount.reset_ct_password")}
                    </Button>
            }
        </div>
    );
};

export default AccountChangePassword;
