import React from "react";
import {getColumnSearchProps} from "../../../../components/table/function/filterTable";

export const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: "30%",
    ...getColumnSearchProps(),
    render: (text) => <p>{text}</p>,
  },
  // {
  //   title: "Spread Adjustment (+/- %)",
  //   dataIndex: "spreadAdjustment",
  //   key: "spreadAdjustment",
  //   ...getColumnSearchProps(),
  //   render: (text) => <p>{text}</p>,
  // },
];
