import React from "react";
import {Button, Space, Upload} from "antd";
import * as XLSX from "xlsx";
import {useTranslation} from "react-i18next";
import {dataConversion, exportToExcel, makeId,} from "../../../function/common";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../recoil_state";

const LanguageTableToolbar = (props) => {
    const {
        setTranslationFile,
        openAddModal,
        data,
        openUploadModal,
        languagePermission,
    } = props;
    const {t} = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const getAllExistedLanguageCode = (data) => {
        return data?.map((item) => {
            return item?.code;
        });
    };

    const convertCsvToArray = (data) => {
        const test = data?.replace(/\r\n|\n\r|\r/g, "{row}").split("{row}");
        const rowKey = test.shift()?.split(",");
        const result = test.map((item) => {
            const tempItem = item?.split(",");
            var rows: any = {};
            tempItem.forEach((value, index) => {
                if (value && value !== "") {
                    rows["ids"] = makeId(20) + "_" + index;
                    rows[rowKey[index]] = value;
                }
            });
            return rows;
        });
        return result.slice(0, -1);
    };

    return (
        <div className="margin-left-auto">
            <Space>
                <Upload
                    accept=".xlsx, .csv"
                    showUploadList={false}
                    beforeUpload={(file) => {
                        const [fileExtension] = file?.name.split(".").reverse();
                        const reader = new FileReader();

                        if (fileExtension === "csv") {
                            reader.onload = (e) => {
                                setTranslationFile(convertCsvToArray(e?.target?.result));
                            };
                            reader.readAsText(file);
                            openUploadModal(true);
                        } else if (fileExtension === "xlsx") {
                            reader.onload = (e) => {
                                const workbook = XLSX.read(e?.target?.result, {type: "binary"});
                                for (const Sheet in workbook.Sheets) {
                                    var data: any = XLSX.utils.sheet_to_json(
                                        workbook.Sheets[Sheet]
                                    );
                                    var result = data?.map((item, index) => {
                                        return {
                                            ...item,
                                            ids: makeId(20) + "_" + index,
                                        };
                                    });
                                    setTranslationFile(result);
                                }
                            };
                            reader.readAsBinaryString(file);
                            openUploadModal(true);
                        } else {
                            setNotificationMessage({
                                status: "error",
                                title: "",
                                msg: t("Invalid File"),
                                key: "addLanguage",
                            });

                        }
                        // Prevent upload
                        return false;
                    }}
                >
                    <Button>{t("Upload All")}</Button>
                </Upload>
                <Button
                    onClick={() =>
                        exportToExcel(
                            dataConversion(data),
                            "xlsx",
                            getAllExistedLanguageCode(data)
                        )
                    }
                >
                    {t("Export All")}
                </Button>
                {languagePermission?.add?.add && (
                    <Button onClick={() => openAddModal()}>{t("Add New Language")}</Button>
                )}
            </Space>
        </div>
    );
};

export default LanguageTableToolbar;
