import {useState} from "react";
import {getUserLeadsLite} from "../../../../../api/graphql/leads";

export const useGetUserLeadsLite = () => {
    const [data, setData] = useState<any>({data: [], total: 0});
    const [loading, setLoading] = useState(false);
    const [previousParam, setParam] = useState({})

    const fetchLeads = async (param) => {
        const {filter, filterType, filterTypeId, limit, offset, orderBy, startDate, endDate, dateKey} = param
        setLoading(true);
        setParam(param)

        try {
            const {getUserLeadsLite: response} = await getUserLeadsLite({filter: filter}, filterType, filterTypeId, limit, offset, orderBy, startDate, endDate, dateKey)
            setData(response)
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }

    const refetchLeads = () => fetchLeads(previousParam)

    return [data, loading, fetchLeads, refetchLeads] as const
}