import { gql, useMutation, useQuery } from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";

const GET_USER_NETWORK_TREE_COUNT = gql`
  query getUserNetworkTreeCount {
    getUserNetworkTreeCount
  }
`;

const getUserNetworkTreeCount = () => {
  try {
    const { loading, error, data } = useQuery(GET_USER_NETWORK_TREE_COUNT, {
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data: data?.getUserNetworkTreeCount };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_USER_NETWORK_TREE_BY_ID = gql`
  query getUserNetworkTreeByID($id: String!) {
    getUserNetworkTreeByID(id: $id) {
      ... on UserNetworkTree {
        id
        userid {
          username
        }
        parent {
          username
        }
        left
        right
        createdBy {
          email
        }
        updatedBy {
          email
        }
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getUserNetworkTreeById = (id) => {
  try {
    const { loading, error, data } = useQuery(GET_USER_NETWORK_TREE_BY_ID, {
      variables: {
        id: id,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_USER_NETWORK_TREE = gql`
  query getUserNetworkTree {
    getUserNetworkTree {
      ... on UserNetworkTreeArray {
        data {
          _id
          parent {
            _id
            username
            fullName
          }
          userid {
            _id
            username
            fullName
            userType
            identificationNo
            email
            desk {
              _id
              name
              description
            }
            rank {_id name}
            createdAt
            referrer {
              _id
            }
          }
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getUserNetworkTree = () => {
  try {
    const { loading, error, data, refetch } = useQuery(GET_USER_NETWORK_TREE, {
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data, refetch };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const getUserNetworkTreeAsync = async () => {
  try {
    const execute = await ApolloClientQuery(
        GET_USER_NETWORK_TREE,
        {
          context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t"),
            },
          },
        },
        false
    );

    return execute;
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_USER_NETWORK_TREE_DOWNLINE = gql`
  query getUserNetworkTreeDownline($userids: [String]) {
    getUserNetworkTreeDownline(userids: $userids) {
      ... on UserNetworkTreeArray {
        data {
          userid {
            _id
            username
            fullName
            email
            identificationNo
          }
        }
      }
    }
  }
`;

const getUserNetworkTreeDownline = (userids) => {
  try {
    const { loading, error, data } = useQuery(GET_USER_NETWORK_TREE_DOWNLINE, {
      variables: {
        userids: userids,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, networkTreeDownlineData: data };
  } catch (err: any) {
    console.error(err);
    return { networkTreeDownlineData: null, loading: false, error: err };
  }
};

const SORT_USER_NETWORK_TREE = gql`
  mutation sortUserNetworkTree {
    sortUserNetworkTree {
      ... on UserNetworkTreeArray {
        data {
          _id
          userid {
            username
          }
          parent {
            username
          }
          left
          right
          createdBy {
            email
          }
          updatedBy {
            email
          }
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useSortUserNetworkTreeMutation = (): {
  sortUserNetworkTree: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const [sortUserNetworkTreeMutation, { data, loading, error }] = useMutation(
      SORT_USER_NETWORK_TREE
    );

    const sortUserNetworkTree = () => {
      return sortUserNetworkTreeMutation();
    };

    return { sortUserNetworkTree, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return {
      sortUserNetworkTree: null,
      data: null,
      loading: false,
      error: err,
    };
  }
};

const SORT_TARGET_USER_NETWORK_TREE = gql`
  mutation sortTargetUserNetworkTree {
    sortTargetUserNetworkTree {
      ... on UserNetworkTreeArray {
        data {
          _id
          userid {
            username
          }
          parent {
            username
          }
          left
          right
          createdBy {
            email
          }
          updatedBy {
            email
          }
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useSortTargetUserNetworkTreeMutation = (): {
  sortUserNetworkTree: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const [sortUserNetworkTreeMutation, { data, loading, error }] = useMutation(
      SORT_TARGET_USER_NETWORK_TREE
    );

    const sortUserNetworkTree = (userTemplateId) => {
      return sortUserNetworkTreeMutation({
        variables: {
          userTemplateId: userTemplateId,
        },
      });
    };

    return { sortUserNetworkTree, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return {
      sortUserNetworkTree: null,
      data: null,
      loading: false,
      error: err,
    };
  }
};

const GET_USER_NETWORK_TREE_DROPDOWN_BASED_ON_PERMISSION = gql`
    query getUserNetworkTreeDropdownBasedOnPermission($permission: String) {
      getUserNetworkTreeDropdownBasedOnPermission(permission: $permission) {
            ... on UserNetworkTreeArray {
                data {
                    _id
                    userid {
                      username
                      desk {name}
                      rank {name}
                      identificationNo
                      email
                      _id
                    }
                }
            }
        }
    }
`;

const getUserNetworkTreeDropdownBasedOnPermission = (input) => {
    try {
        const {loading, error, data} = useQuery(GET_USER_NETWORK_TREE_DROPDOWN_BASED_ON_PERMISSION, {
            variables: input,
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};


export {
  getUserNetworkTreeCount,
  getUserNetworkTreeById,
  getUserNetworkTree,
  getUserNetworkTreeAsync,
  getUserNetworkTreeDownline,
  useSortUserNetworkTreeMutation,
  useSortTargetUserNetworkTreeMutation,
  getUserNetworkTreeDropdownBasedOnPermission,
  GET_USER_NETWORK_TREE
};
