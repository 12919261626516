import React, {useState} from "react";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import VoucherTopBar from "../../component/voucherTopBar";
import {useTranslation} from "react-i18next";
import { getVoucher, getVoucherCount, useDeleteVoucherMutation } from "../../../../../../api/graphql/voucher";
import VoucherModal from "../../component/voucherModal";
import { Popconfirm, Space, Tooltip } from "antd";
import { CopyOutlined, DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import useAuthorize from "../../../../../../_common/authorize";

function Voucher_1(props) {
    const {
        tableName,
        permissionActivities,
        column
    } = props;

    const {t} = useTranslation();

    const {deleteVoucher} = useDeleteVoucherMutation()
    const { systemErrorNotification } = useAuthorize();

    const [orderBy, setOrderBy] = useState<any>("")
    const [limit, setLimit] = useState<any>(20)
    const [offset, setOffset] = useState<any>(0)
    const [filter, setFilter] = useState<any>(null)

    const {data: totalCount} = getVoucherCount({filter: filter})
    const {data, loading} = getVoucher({filter: filter}, limit, offset, orderBy)
    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize)
        setOffset(limit * (page - 1))
    }


    const deleteOnConfirm = async (id) => {
      try {
        await deleteVoucher(id);
      } catch (e) {
        console.log(e);
        systemErrorNotification();
      }
    };

    const duplicateItem = (record) => {
      props?.setFormModalType && props?.setFormModalType("add")
      props?.setSelectedItem && props?.setSelectedItem(record)
      props?.setIsFormModalOpen && props?.setIsFormModalOpen(true)
    }

    return (
        <div id={"voucher"}>
            {permissionActivities?.voucher?.add?.add  && (
                <VoucherModal 
                  mode={props.formModalType} 
                  item= {props.selectedItem} 
                  isOpen={props.isFormModalOpen} 
                  closeFormModal={props.closeFormModal} 
                  languageData={props.languageData} 
                  product={props.product}
                  stock={props.stock}
                  commodities={props.commodities}
                  report={props.report}
                  reportFields={props.reportFields}
                  reportFieldTypes={props.reportFieldTypes}
                />
            )}
            {column?.length > 0 && (
                <TableAntDesign
                    data={data?.getVoucher?.data}
                    tableName={tableName}
                    columns={[
                        ...column,
                        {
                            title: t("common.action"),
                            key: "action",
                            width: 132,
                            render: (_, record: any) => (
                              <Space size="middle">
                                <Tooltip title={t("View")}>
                                  <a onClick={() => props.openFormModal && props.openFormModal("view", record)}>
                                    <EyeOutlined />
                                  </a>
                                </Tooltip>
                                {permissionActivities?.voucher?.edit?.edit && (
                                  <Tooltip title={t("Edit")}>
                                    <a onClick={() => props.openFormModal && props.openFormModal("edit", record)}>
                                      <EditOutlined />
                                    </a>
                                  </Tooltip>
                                )}
                                {permissionActivities?.voucher?.edit?.edit && (
                                  <Popconfirm
                                      placement="left"
                                      description={t("Are you sure to duplicate this voucher?")}
                                      title={t(`Duplicate Voucher`)}
                                      onConfirm={() => {
                                          duplicateItem(record);
                                      }}
                                      okText={t("Yes")}
                                      okType="danger"
                                      cancelText={t("No")}
                                  >
                                    <Tooltip title={t("Duplicate")}>
                                        <a>
                                            <CopyOutlined/>
                                        </a>
                                </Tooltip>
                                  </Popconfirm>
                                )}
                                {permissionActivities?.voucher?.delete?.delete && (
                                    <Tooltip title={t("Delete")}>
                                      <Popconfirm
                                        placement="left"
                                        title={t("Are you sure to delete this base voucher?")}
                                        onConfirm={() => {
                                          deleteOnConfirm(record._id);
                                        }}
                                        okText={t("common.yes")}
                                        cancelText={t("common.no")}
                                      >
                                        <a>
                                          <DeleteOutlined />
                                        </a>
                                      </Popconfirm>
                                    </Tooltip>
                                )}
                                </Space>
                            )
                        }
                    ]}
                    size={"small"}
                    loading={loading}
                    filter={setFilter}
                    order={setOrderBy}
                    pagination={{
                        pageSize: limit,
                        showSizeChanger: true,
                        onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                        total: totalCount,
                        showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                    }}
                />
            )}
        </div>
    );
}

export default Voucher_1;
