import React, {useEffect, useState} from "react";
import {Button, Col, Form, Modal, Row, Select, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {SyncOutlined} from "@ant-design/icons";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import useAuthorize from "../../../../../../../_common/authorize";
import { useShiftReferrerForDownlineAndDeleteMutation } from "../../../../../../../api/graphql/user";

const labelCol = {span: 8, offset: 0}
const wrapperCol = {span: 16, offset: 0}

const ShiftReferrerModal = ({isOpen, setIsOpen, referrerOptions, data}) => {
    const {t} = useTranslation()
    const [form] = Form.useForm()

    const {shiftReferrerForDownlineAndDelete} = useShiftReferrerForDownlineAndDeleteMutation()
    const {systemErrorNotification} = useAuthorize();
    const [targetReferrer, setTargetReferrer] = useState("")

    const onShiftReferrer = () => {
        shiftReferrerForDownlineAndDelete(data?._id?.toString(), targetReferrer?.toString())
        setIsOpen(false)
    }

    useEffect(() => {
        form.resetFields()
        setTargetReferrer("")
    }, [isOpen])

    return (
        <Modal
            open={isOpen}
            onCancel={() => setIsOpen(false)}
            okText={t("common.confirm")}
            cancelText={t("common.cancel")}
            title={t("user.shiftReferrerForDownline")}
            onOk={() => form.submit()}
            destroyOnClose
        >
            <Form form={form} onFinish={onShiftReferrer} labelWrap={true}>
                
            <Form.Item
                labelAlign={"left"}
                label={t("user.referrer")}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
            >
                <Select
                    value={targetReferrer}
                    placeholder={t("user.referrer")}
                    options={referrerOptions?.filter(d => d?.value !== data?._id)}
                    optionFilterProp={"label"}
                    optionLabelProp={"selectedLabel"}
                    showSearch
                    onChange={value => {
                        try {
                            setTargetReferrer(value?.toString())
                        } catch (e) {
                            console.log(e)
                            systemErrorNotification()
                        }
                    }}
                    style={{width: "100%"}}
                />
            </Form.Item>
            </Form>
        </Modal>
    )
}

export default ShiftReferrerModal