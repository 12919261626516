import React, {useEffect, useState} from "react";
import {Button, Col, Form, Modal, Row, Tabs} from "antd";
import {useSetRecoilState} from "recoil";
import {FormProvider, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {CloseOutlined} from "@ant-design/icons";
import yupFormSchema from "../../../../../../components/yup/yupFormSchema";
import {verticalFormLayout} from "../../../../../../components/form/formWrapper";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import useAuthorize from "../../../../../../_common/authorize";
import {addPromoTag, updatePromoTag} from "../../../../../../api/graphql/promoTag";
import {notificationMessage} from "../../../../../../../recoil_state";

const PromoTagModal = (props) => {
    const {isOpen, closeModal, mode, item, languageData} = props;
    const {t} = useTranslation();
    const [values, setValues] = useState<any>({});
    const [errMsg, setErrMsg] = useState("");

    const {systemErrorNotification} = useAuthorize();
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const yupSchema = yup.object().shape({
        name: yupFormSchema.string("name", {required: true}),
    });

    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
    });

    const setupInitialValues = async () => {
        try {
            let formValues: any = {name: item?.name, displayName: item?.displayName};
            form.setValue("name", item?.name);
            setValues(formValues);
        } catch (error) {
            console.log(error);
        }
    };

    const handleValueChange = async (value) => {
        try {
            form.setValue("name", value);
            setValues((prevState) => ({...prevState, name: value}));
        } catch (error) {}
    };

    const handleOnChangeWithLanguage = (value, field, fieldType, languageCode, setData) => {
        try {
            setData((prevState) => {
                if (!prevState || !prevState[field]) {
                    return {
                        ...prevState,
                        [field]: [
                            {
                                [fieldType]: value,
                                language: languageCode,
                            },
                        ],
                    };
                }

                var currentData = [...prevState[field]] || [];
                var index = currentData?.findIndex((item) => item?.language === languageCode);

                if (index === -1) {
                    currentData?.push({[fieldType]: value, language: languageCode});
                } else {
                    const updatedObj = {...currentData[index], [fieldType]: value};
                    currentData[index] = updatedObj;
                }

                var result = {
                    ...prevState,
                    [field]: currentData,
                };

                return result;
            });
        } catch (error) {
            console.log(error);
        }
    };

    const onSubmit = async () => {
        try {
            const res = mode === "Add" ? await addPromoTag(values) : await updatePromoTag(item?.id || item?._id, values);
            const response = mode === "Add" ? res?.addPromoTag : res?.updatePromoTag;
            if (response?.__typename === "PromoTag") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: mode === "Add" ? t("common.addSuccess") : t("common.updateSuccess"),
                    key: "addUpdatePromoTag",
                });
                closeModal();
            } else if (response?.__typename === "BaseError") {
                let errMsg = t(response?.errKey);
                setErrMsg(errMsg);
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.msg),
                    key: "addUpdatePromoTag",
                });
                closeModal();
            }
        } catch (error: any) {
            systemErrorNotification();
        }
    };

    useEffect(() => {
        setupInitialValues();
    }, [item, isOpen]);

    const tabItems = languageData?.map((lang, index) => {
        let inputValue = values?.displayName?.find((v) => v?.language === lang.code)?.label;
        return {
            key: index?.toString(),
            label: lang?.name,
            children: (
                <Row gutter={16} style={{marginTop: "12px"}}>
                    <Col className="gutter-row" xs={24}>
                        <InputFormItem
                            name={`displayName_${lang.code}`}
                            label={"Display Name"}
                            placeholder={"Display Name"}
                            layout={verticalFormLayout}
                            disabled={mode === "view"}
                            value={inputValue}
                            onChange={(e) => handleOnChangeWithLanguage(e, "displayName", "label", lang.code, setValues)}
                        />
                    </Col>
                </Row>
            ),
        };
    });

    return (
        <Modal title={t(`${mode} Promo Tag`)} open={isOpen} onCancel={() => closeModal()} footer={null} width={"70%"} destroyOnClose>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="form-1">
                    {errMsg && <p style={{color: "red"}}>{errMsg}</p>}
                    <InputFormItem
                        name={"name"}
                        label={t("Name")}
                        placeholder={t("Name")}
                        layout={verticalFormLayout}
                        required
                        disabled={mode === "view"}
                        onChange={handleValueChange}
                        value={values?.name}
                    />
                    <Tabs defaultActiveKey="0" items={tabItems} />
                    <Form.Item className="text-align-right" style={{paddingTop: "12px"}}>
                        {closeModal && (
                            <Button onClick={closeModal} icon={<CloseOutlined />} className="button-cancel-1">
                                {t("common.cancel")}
                            </Button>
                        )}
                        <Button onClick={form.handleSubmit(onSubmit)} className="button-submit-1">
                            {t("common.submit")}
                        </Button>
                    </Form.Item>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default PromoTagModal;
