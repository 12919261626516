import React, {useEffect, useState} from "react";
import Helmet from "react-helmet";
import {Outlet, useNavigate} from "react-router-dom";
import {useStore} from "react-context-hook";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {Button, Layout, Row, Space} from "antd";
import {Header_1} from "../components/header";
import {Sider_1} from "../components/sider";
// import { Breadcrumb_1 } from "../components/breadcrumb";
import {useVerifyAccessTokenMutation} from "../api/graphql/auth";
import {userLogoutState, userToken} from "../../recoil_state";
import {useTranslation} from "react-i18next";

const Layout_1 = (props) => {
    const userLogout = useRecoilValue(userLogoutState);
    const [theme] = useStore("theme", "light");
    const {t} = useTranslation();

    const [collapsed, setCollapsed] = useState(true);

    const exitGhostLogin = () => {
        localStorage.removeItem("ghost_a_t");
        localStorage.removeItem("g_userId");
        localStorage.removeItem("g_username");
        window.location.reload();
    };

    useEffect(() => {
        setCollapsed(true);
    }, [location.pathname]);

    return (
        <>
            {/*<Helmet title={"Admin Panel"}/>*/}
            {!userLogout && (
                <Layout className={theme === "light" ? "light-theme layout1" : "dark-theme layout1"}>
                    <Sider_1 collapsed={collapsed} setCollapsed={setCollapsed} collapsedWidth={location.pathname.startsWith("/settings") ? 80 : 0}/>
                    <Layout className="site-layout">
                        <Header_1/>
                        {/*<Breadcrumb_1 />*/}
                        <Outlet/>
                        {localStorage.getItem("ghost_a_t") && (
                            <div className={"ghost-login-banner"}>
                                <Space>
                                    <p className={"margin-bottom-0"}>
                                        {t("common.currentlyViewAs")}
                                        <strong>{localStorage.getItem("g_username")}</strong>
                                    </p>
                                    <Button onClick={() => exitGhostLogin()}>{t("common.exitViewAs")}</Button>
                                </Space>
                            </div>
                        )}
                    </Layout>
                </Layout>
            )}
        </>
    );
};

export default Layout_1;
