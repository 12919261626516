import {Button, Checkbox, message, Modal, Row, Space, Table} from "antd";
import React, {useEffect, useState} from "react";
import {DndContext} from "@dnd-kit/core";
import {arrayMove, SortableContext, useSortable, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {LockOutlined, MenuOutlined} from "@ant-design/icons";
import {getUserTableSetting, useUpdateUserTableSetting} from "../../../api/graphql/tableSetting";
import {useTranslation} from "react-i18next";

const UserTableForm = (props) => {
    const {tableName, displayTableName, server, tableColumn, open, hideModal, defaultData, isMasterAdmin, showAdminModal} = props;
    const serverId = server?.id || "";
    const {t} = useTranslation();

    const [dataSource, setDataSource] = useState([
        {
            fieldName: "",
            isHide: false,
            key: 1,
        },
    ]);

    const {data} = getUserTableSetting(localStorage.getItem("userId"), {
        filter: {
            tableName: tableName,
            serverName: server?.name,
        },
    });
    const {updateUserTableSetting, loading} = useUpdateUserTableSetting();

    const openAdminSetting = () => {
        hideModal();
        showAdminModal();
    };

    const fixed = defaultData?.fieldData
        ?.map((d) => {
            if (d?.isLock === true) return d?.defaultPosition;
        })
        .filter(Boolean);

    const TableRow = ({children, ...props}) => {
        const {attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging} = useSortable({
            id: props["data-row-key"],
        });
        const style = {
            ...props.style,
            transform: CSS?.Transform?.toString(
                transform && {
                    ...transform,
                    scaleY: 1,
                }
            )?.replace(/translate3d\(([^,]+),/, "translate3d(0,"),
            transition,
            ...(isDragging
                ? {
                    position: "relative",
                    zIndex: 9999,
                }
                : {}),
        };

        if (fixed?.includes(props["data-row-key"])) {
            return (
                <tr {...props} ref={setNodeRef} style={style}>
                    {React?.Children?.map(children, (child) => {
                        if (child?.key === "sort") {
                            return React?.cloneElement(child, {
                                children: <LockOutlined/>,
                            });
                        }
                        return child;
                    })}
                </tr>
            );
        }

        return (
            <tr {...props} ref={setNodeRef} style={style} {...attributes}>
                {React?.Children?.map(children, (child) => {
                    if (child?.key === "sort") {
                        return React?.cloneElement(child, {
                            children: (
                                <MenuOutlined
                                    ref={setActivatorNodeRef}
                                    style={{
                                        touchAction: "none",
                                        cursor: "move",
                                    }}
                                    {...listeners}
                                />
                            ),
                        });
                    }
                    return child;
                })}
            </tr>
        );
    };

    const onDragEnd = ({active, over}) => {
        if (active?.id !== over?.id) {
            setDataSource((previous) => {
                const activeIndex = previous.findIndex((i) => i?.key === active?.id);
                const overIndex = previous.findIndex((i) => i?.key === over?.id);
                return arrayMove(previous, activeIndex, overIndex);
            });
        }
    };

    const column = [
        {
            key: "sort",
        },
        {
            title: t("common.column"),
            key: "title",
            dataIndex: "title",
        },
        {
            title: t("common.show"),
            dataIndex: "isHide",
            key: "isHide",
            render: (_, record, rowIndex) => {
                const isDisabled = fixed?.includes(record?.position);
                return (
                    <Checkbox
                        name={"isHide"}
                        checked={isDisabled ? true : record?.isHide}
                        disabled={isDisabled}
                        onChange={(e) => {
                            handleCheckboxChange(e?.target?.checked, rowIndex, "isHide");
                        }}
                    />
                );
            },
        },
    ];

    const handleCheckboxChange = (checked, rowIndex, checkboxIndex) => {
        const newData = [...dataSource];
        newData[rowIndex][checkboxIndex] = checked;
        setDataSource(newData);
    };

    const setOriData = async () => {
        if (defaultData?.fieldData?.length > 0) {
            return await defaultData?.fieldData
                ?.map((d, i) => {
                    if (d?.isSelectableByUser) {
                        const matchingObj =
                            data?.getUserTableSetting?.tableName !== null || data?.getUserTableSetting?.tableColumn?.length !== 0
                                ? data?.getUserTableSetting?.tableColumn?.find((initialObj) => initialObj?.columnName === d?.fieldName)
                                : [];
                        const matchingObjColumn = tableColumn?.find(
                            (initialObj) =>
                                (Array?.isArray(initialObj?.dataIndex) ? initialObj?.dataIndex?.join("_") : initialObj?.dataIndex) === d?.fieldName
                        );
                        return {
                            fieldName:
                                matchingObj?.columnName ||
                                d?.fieldName ||
                                (Array.isArray(matchingObjColumn?.dataIndex)
                                    ? matchingObjColumn?.dataIndex?.join("_")
                                    : matchingObjColumn?.dataIndex),
                            title: matchingObjColumn?.title,
                            isHide: data?.getUserTableSetting?.tableColumn?.length > 0 ? !!matchingObj?.columnName : d?.isDisplayToUser,
                            position: data?.getUserTableSetting?.tableColumn?.length > 0 ? matchingObj?.position : d?.defaultPosition || null,
                            key: i + 1,
                        };
                    } else {
                        return null;
                    }
                })
                .filter((item) => item !== null && item?.title);
        } else {
            return await tableColumn?.map((d, i) => {
                const matchingObj =
                    data?.getUserTableSetting?.tableName !== null
                        ? data?.getUserTableSetting?.tableColumn?.find(
                            (initialObj) => initialObj?.columnName === (Array.isArray(d?.dataIndex) ? d?.dataIndex?.join("_") : d?.dataIndex)
                        )
                        : [];

                return {
                    fieldName: Array.isArray(d?.dataIndex) ? d?.dataIndex?.join("_") : d?.dataIndex,
                    title: d?.title,
                    isHide: data?.getUserTableSetting?.tableColumn?.length > 0 ? !!matchingObj?.columnName : true,
                    position: matchingObj?.position || null,
                    key: i + 1,
                };
            });
        }
    };

    useEffect(() => {
        setOriData()?.then((data) => setDataSource(data?.sort((a, b) => (a?.position != null ? a?.position : Infinity) - (b?.position != null ? b?.position : Infinity))));
    }, [data, tableColumn, defaultData, open]);

    const onConfirm = async () => {
        const formattedData = dataSource?.map((item, index) => {
            if (item?.isHide) {
                return {
                    columnName: item?.fieldName,
                    position: index + 1,
                };
            }
        })
            .filter((item) => item !== undefined);
        if (formattedData?.length < 1) {
            message.error(t("Please select at least one column"));
        } else {
            await updateUserTableSetting({
                userid: localStorage.getItem("userId"),
                tableName: tableName,
                tableColumn: formattedData,
                server: serverId,
            });
            hideModal();
        }
    };

    const resetToDefault = async () => {
        await updateUserTableSetting({
            userid: localStorage.getItem("userId"),
            tableName: tableName,
            tableColumn: [],
            server: serverId,
        });
    };

    return (
        dataSource && (
            <Modal title={t(`common.table_columns_user_setting`, {table: displayTableName ?? tableName})} open={open} footer={null} onCancel={hideModal}
                   width={600}>
                <DndContext onDragEnd={onDragEnd}>
                    <SortableContext items={dataSource?.map((i) => i.key)} strategy={verticalListSortingStrategy}>
                        <Table
                            className={"large-padding-table"}
                            components={{
                                body: {
                                    row: TableRow,
                                },
                            }}
                            rowKey="key"
                            columns={column}
                            dataSource={dataSource}
                            pagination={false}
                        />
                    </SortableContext>
                </DndContext>
                <Row justify={isMasterAdmin ? "space-between" : "end"} className={"margin-top-0-75"}>
                    {isMasterAdmin && (
                        <Button type={"link"} onClick={openAdminSetting} disabled={loading}>
                            {t("common.adminSetting")}
                        </Button>
                    )}
                    <Space>
                        <Button onClick={() => resetToDefault()}>{t("common.reset_to_default")}</Button>
                        <Button onClick={() => hideModal()}>{t("common.cancel")}</Button>
                        <Button type={"primary"} loading={loading} onClick={() => onConfirm()}>
                            {t("common.update")}
                        </Button>
                    </Space>
                </Row>
            </Modal>
        )
    );
};
export default UserTableForm;
