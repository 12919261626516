import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Space, Tabs} from "antd";
import PromoMaterialTable_1 from "./component/promoMaterialTable";
import CampaignTable_1 from "./component/campaignTable";
import PromoTagTable_1 from "./component/promoTagTable";
import LeadTagTable_1 from "./component/leadTagTable";
import EditTableUserPreferenceButton from "../../../../../components/table/function/editTableUserPreferenceButton";
import {columnsPromoMaterial} from "../../function/tableColumn_promoMaterial";
import {columnsCampaignTag} from "../../function/tableColumn_campaignTag";

const PromoMaterial_1 = (props) => {
    const {permission, languageData} = props;
    const {t} = useTranslation();
    const [currentTab, setCurrentTab] = useState<any>("Promo Material");
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);

    const openAddModal = () => {
        setIsAddModalOpen(true);
    };

    const tableProps = {
        permission,
        languageData,
        currentTab,
        isAddModalOpen,
        setIsAddModalOpen,
    };

    const tabItems: any = [
        {
            label: t("Promo Material"),
            size: "small",
            key: "Promo Material",
            children: <PromoMaterialTable_1 {...tableProps} />,
        },
        {
            label: t("Campaign"),
            size: "small",
            key: "Campaign",
            children: <CampaignTable_1 {...tableProps} />,
        },
        {
            label: t("Promo Tag"),
            size: "small",
            key: "Promo Tag",
            children: <PromoTagTable_1 {...tableProps} />,
        },
        {
            label: t("Lead Tag"),
            size: "small",
            key: "Lead Tag",
            children: <LeadTagTable_1 {...tableProps} />,
        },
    ];

    const columns = currentTab === "Promo Material" ? columnsPromoMaterial() : columnsCampaignTag();

    return (
        <div id="promoMaterial">
            <Tabs
                defaultActiveKey="Promo Material"
                size={"small"}
                items={tabItems}
                tabBarExtraContent={
                    <>
                        <Space>
                            <EditTableUserPreferenceButton tableName={currentTab} tableColumn={columns} />
                            {/* <ExportTableButton loading={exportLoading} tableData={exportData} tableColumn={exportColumn} tableName={tableName} /> */}
                            {permission?.add?.add && (
                                <Button type={"primary"} onClick={() => openAddModal()}>
                                    {t(`Add ${currentTab}`)}
                                </Button>
                            )}
                        </Space>
                    </>
                }
                onTabClick={(key) => {
                    setCurrentTab(key);
                }}
            />
        </div>
    );
};

export default PromoMaterial_1;
