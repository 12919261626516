import React, {useEffect, useState} from "react";
import TableAntDesign from "../../../../../../../components/table/tableAntDesign";
import {columnsCT} from "../../../function/tableColumn_SymbolMappingCategory_CT";
import {AiOutlineEdit} from "react-icons/ai";
import SymbolCategoryModal from "./categoryModal";
import {
    getSymbolMappingCategory,
    getSymbolMappingCategoryCount,
} from "../../../../../../../api/graphql/symbolMappingCategory";
import {getAssetClassDetails} from "../../../../../../../api/graphql/assetClass";
import {Space, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {columnsMT5} from "../../../function/tableColumn_SymbolMappingCategory_Mt5";
import {getAssetClassMt5} from "../../../../../../../api/graphql/assetClassMt5";

function SymbolCategoryTable_1(props) {
    const {
        server,
        isOpen,
        close,
        openEdit,
        selectedItem,
        setExportData,
        setExportColumn,
        tableName,
        permission,
        currentServer,
        setExportLoading
    } = props;
    const {t} = useTranslation()
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [filter, setFilter] = useState({serverName: server?.name});
    const [orderBy, setOrderBy] = useState("");
    const [assetClassMt5, setAssetClassMt5] = useState<any>([]);

    const columns = server?.platform === "cTrader" ? columnsCT : columnsMT5;

    const {data, loading, error} = getSymbolMappingCategory(
        {filter: {...filter, isParent: false}},
        limit,
        offset,
        orderBy
    );

    const {data: exportData, loading: exportLoading} = getSymbolMappingCategory(
        {filter: {...filter, isParent: false}},
        0,
        0,
        orderBy
    );
    const {data: totalCount} = getSymbolMappingCategoryCount({filter: {...filter, isParent: false}})

    const {data: securitiesList} = getAssetClassDetails({filter: {serverName: server?.name}});

    const preloadMt5 = async () => {

        if(server){
            const response = await getAssetClassMt5({
                server: server?.id
            });

            const latestData = await response?.getAssetClassMt5?.data?.map((d, i) => {

                const symbolName = d?.symbols?.map((sym) => {
                    return sym.symbol
                })

                return {
                    ...d,
                    server: d?.server?.name || "-",
                    symbolName: symbolName.length > 0 ? symbolName?.join(", ") : symbolName,
                    key: i + 1,
                };
            });


            setAssetClassMt5(latestData)
        }

    }

    useEffect(() => {
        if (server && server?.platform === "mt5") {
            preloadMt5()
        }
    }, [server])

    useEffect(() => {
        if (currentServer === server?.name && exportData?.getSymbolMappingCategory?.data?.length > 0) {
            setExportColumn(columns);
            finalExportData(exportData?.getSymbolMappingCategory?.data).then(
                (latestData) => {
                    setExportData(latestData);
                }
            );
        }
    }, [exportData?.getSymbolMappingCategory?.data, currentServer]);

    const finalExportData = async (data) => {
        const filtered = await data?.map((d, i) => {
            return {
                category: d?.category?.categoryName,
                symbol: d?.symbol?.map((d) => d?.name)?.join(", "),
            };
        });
        return filtered;
    };

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize);
        setOffset(limit * (page - 1));
    };

    useEffect(() => {
        setExportLoading(exportLoading)
    }, [exportLoading])


    return (
        <div className={"margin-top-0-75"}>
            {(securitiesList?.getAssetClassDetails?.data?.length > 0 || server?.platform === "mt5") &&
            currentServer === server?.name && (
                <SymbolCategoryModal
                    isOpen={isOpen}
                    close={close}
                    item={selectedItem}
                    server={server?.id}
                    platform={server?.platform}
                    securities={securitiesList?.getAssetClassDetails?.data}
                    assetClassMt5={assetClassMt5}
                />
            )}
            <TableAntDesign
                data={data?.getSymbolMappingCategory?.data}
                tableName={tableName}
                rowKey={(record) => record?.category?._id}
                columns={[
                    ...columns,
                    {
                        title: t("common.action"),
                        key: "action",
                        width: 132,
                        render: (_, record: any) => (
                            <Space>
                                <Tooltip title={t("common.edit")}>
                                    <a style={{color: "green"}} onClick={() => openEdit(record)}>
                                        <AiOutlineEdit/>
                                    </a>
                                </Tooltip>
                            </Space>
                        ),
                    },
                ]}
                size={"small"}
                loading={loading}
                filter={setFilter}
                server={server?.name}
                order={setOrderBy}
                scroll={false}
                actionPermission={permission}
                pagination={{
                    pageSize: limit,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                    total: totalCount?.getSymbolMappingCategoryCount,
                    showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                }}
            />
        </div>
    );
}

export default SymbolCategoryTable_1;
