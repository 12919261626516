import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Voucher from "./voucher";
import VoucherGroup from "./voucherGroup";
import { Space, Tabs } from "antd";
import { selfPermissionObj } from "../../../../recoil_state";
import { useRecoilValue } from "recoil";
import AddButton from "../../../components/button/addButton";
import { getLanguage } from "../../../api/graphql/language";
import { sortEn } from "../../../function/language";
import { getSymbolDropdown } from "../../../api/graphql/symbol";
import { getProduct } from "../../../api/graphql/product";
import { getVoucher } from "../../../api/graphql/voucher";
import { getReportingAll, getReportingFields, getReportingTypes } from "../../../api/graphql/reporting";
import { convertRewardTypeToLabel } from "../../../function/_common";
import { getSymbolMappingCategory } from "../../../api/graphql/symbolMappingCategory";

const VoucherSetting = (props) => {
    const {t} = useTranslation();
    const {languageData} = getLanguage({filter: {isActive: true}});
    const [currentTab, setCurrentTab] = useState("voucher");
    const [voucherFormModalType, setVoucherFormModalType] = useState("");
    const [isVoucherFormModalOpen, setIsVoucherFormModalOpen] = useState(false);
    const [selectedVoucherItem, setSelectedVoucherItem] = useState({});
    const [formModalType, setFormModalType] = useState("");
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [reportFields, setReportFields] = useState<any>([])
    const [reportFieldTypes, setReportFieldTypes] = useState<any>([])
    const [currentReport, setCurrentReport] = useState<any>([])
    
    const {data: stockData} = getSymbolDropdown({filter: {serverMode: "live"}})
    const {data: productData} = getProduct({filter: {serverMode: "live"}})
    const {data: voucherData} = getVoucher({filter: null})
    const {data: symbolMappingCategoryData} = getSymbolMappingCategory({filter: {}})

    const [stock, setStock] = useState([])
    const [commodities, setCommodities] = useState([])
    const [product, setProduct] = useState([])
    const [voucher, setVoucher] = useState([])

    const preload = async (productData, stockData, voucherData, symbolMappingCategoryData) => {
        if (symbolMappingCategoryData && !(symbolMappingCategoryData?.length > 0)) {
            const categoryData = symbolMappingCategoryData?.getSymbolMappingCategory?.data || []

            const stockCategories = categoryData?.filter(cat => cat?.category?.type === "stocks")
            let stockValue: any = []
            stockCategories?.forEach(stockCat => {
                if (stockCat && stockCat?.symbol?.length) {
                    stockCat?.symbol.map((sym) => {
                        stockValue.push({
                            label: stockCat?.server?.name + ` - ` + sym?.name,
                            value: sym?._id,
                        })
                    })
                } else {
                    stockCat?.symbolMt5.map((sym) => {
                        stockValue.push({
                            label: stockCat?.server?.name + ` - ` + sym?.symbol,
                            value: sym?._id,
                        })
                    })
                }
            });
            setStock(stockValue)

            const commoditiesCategories = categoryData?.filter(cat => cat?.category?.type === "commodities")
            let commoditiesValue: any = []
            commoditiesCategories?.forEach(commoditiesCat => {
                if (commoditiesCat && commoditiesCat?.symbol?.length) {
                    commoditiesCat?.symbol.map((sym) => {
                        commoditiesValue.push({
                            label: commoditiesCat?.server?.name + ` - ` + sym?.name,
                            value: sym?._id,
                        })
                    })
                } else {
                    commoditiesCat?.symbolMt5.map((sym) => {
                        commoditiesValue.push({
                            label: commoditiesCat?.server?.name + ` - ` + sym?.symbol,
                            value: sym?._id,
                        })
                    })
                }
            });
            setCommodities(commoditiesValue)
        }
        if(productData && !(product.length > 0)){
            const products = productData?.getProduct?.data || []
            const productValue = products && products?.map((item) => {
                return {
                    label: `${item?.server?.name} - ${item?.spreadGroup.length > 0 ? `${item?.spreadGroup[0]?.name} - ` : ""}${item?.name}`,
                    value: item?._id
                }
            })
            setProduct(productValue)
        }
        // if(stockData && !(stock.length > 0)){
        //     const stocks = stockData?.getSymbolDropdown?.data || []
        //     const stockValue = stocks && stocks?.map((item) => {
        //         return {
        //             label: item?.server?.name + ` - ` + item?.name,
        //             value: item?._id,
        //             type: item?.baseAssetId[0]?.type
        //         }
        //     })
        //     setStock(stockValue)
        // }
        if(voucherData && !(voucher.length > 0)){
            const vouchers = voucherData?.getVoucher?.data || []
            const voucherValue = vouchers && vouchers?.map((item) => {
                return {
                    label: `${convertRewardTypeToLabel(item?.rewardType)} - ${item?.name}`,
                    value: item?._id
                }
            })
            setVoucher(voucherValue)
        }
    }

    useEffect(() => {
        preload(productData, stockData, voucherData, symbolMappingCategoryData)
    }, [productData, stockData, voucherData, symbolMappingCategoryData])

    const permission = useRecoilValue<any>(selfPermissionObj);

    const openFormModal = (type, record?) => {
        setFormModalType(type);
        setIsFormModalOpen(true);
        if (record) {
          setSelectedItem(record);
        }
        else {
            setSelectedItem({})
        }
    };

    const closeFormModal = () => {
      setFormModalType("");
      setSelectedItem({});
      setIsFormModalOpen(false);
    };

    const openVoucherFormModal = (type, record?) => {
        setVoucherFormModalType(type);
        setIsVoucherFormModalOpen(true);
        if (record) {
          setSelectedVoucherItem(record);
        }
        else {
            setSelectedVoucherItem({})
        }
    };

    const closeVoucherFormModal = () => {
      setVoucherFormModalType("");
      setSelectedVoucherItem({});
      setIsVoucherFormModalOpen(false);
    };
    
    useEffect(() => {
        getReportFields()
        getReportFieldTypes()
    },[])

    const getReportFields = async () => {
        try {
            if(!reportFields || reportFields.length === 0){
                const response = await getReportingFields();
                if (response?.getReportingFields?.__typename === "ReportingDataAry") {
                    let filteredReportingFields = response?.getReportingFields?.data?.filter(item => item?.isUsedForAutomation)
                    setReportFields(filteredReportingFields);

                    let reportIdSet = new Set()
                    filteredReportingFields.forEach(item => {
                        reportIdSet.add(item?.reportId)
                    })

                    const reportResponse = await getReportingAll()
                    if (response?.getReportingFields?.__typename === "ReportingDataAry") {
                        let filteredReport = reportResponse?.getReportingAll?.data?.filter(item => reportIdSet.has(item?.reportId))
                        setCurrentReport(filteredReport)
                    }
                }
            }
        } catch (err) {
            console.log(err);
        } 
    };
    const getReportFieldTypes = async () => {
        try{
            const response = await getReportingTypes();
            const list = response?.getReportingTypes?.data;
            if (list) {
                setReportFieldTypes(list);
            }
        } catch (err) {
            console.log(err);
        } 
    };

    let tabItems = [
        {
            label: t("reward.voucher"),
            key: "voucher",
            children: (
                <Voucher 
                    formModalType={voucherFormModalType}
                    setFormModalType={setVoucherFormModalType}
                    isFormModalOpen={isVoucherFormModalOpen}
                    setIsFormModalOpen={setIsVoucherFormModalOpen}
                    selectedItem={selectedVoucherItem}
                    setSelectedItem={setSelectedVoucherItem}
                    permission={permission}
                    product={product}
                    stock={stock}
                    commodities={commodities}
                    voucher={voucher}
                    report={currentReport}
                    reportFields={reportFields}
                    reportFieldTypes={reportFieldTypes}
                    openFormModal={openVoucherFormModal}
                    closeFormModal={closeVoucherFormModal}
                    languageData={sortEn(languageData?.getLanguage?.data)}
                />
            ),
        },
        {
            label: t("reward.voucherGroup"),
            key: "voucherGroup",
            children: (<VoucherGroup
                formModalType={formModalType}
                setFormModalType={setFormModalType}
                isFormModalOpen={isFormModalOpen}
                setIsFormModalOpen={setIsFormModalOpen}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                permission={permission}
                product={product}
                stock={stock}
                commodities={commodities}
                voucher={voucher}
                openFormModal={openFormModal}
                closeFormModal={closeFormModal}
                languageData={sortEn(languageData?.getLanguage?.data)}
            />),
        },
    ];

    return (
        <div id="voucherSetting">
            <h1>{t("Voucher Setting")}</h1>
            <Tabs
                defaultActiveKey="voucher"
                size={"small"}
                items={tabItems}
                tabBarExtraContent={
                    <>
                        <Space>
                            {permission?.activitiesAndRewardSetting?.voucher?.add?.add && currentTab === "voucher" && <AddButton text={t("reward.addVoucher")} addHandler={() => openVoucherFormModal("add")} />}
                            {permission?.activitiesAndRewardSetting?.voucher?.add?.add && currentTab === "voucherGroup" && <AddButton text={t("reward.addVoucherGroup")} addHandler={() => openFormModal("add")} />}
                        </Space>
                    </>
                }
                onTabClick={(key) => setCurrentTab(key)}
            />
        </div>
    );
};

export default VoucherSetting;
