import {Button, Col, Form, Modal, Row} from "antd";
import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {useSetRecoilState} from "recoil";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
// import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import {refetchQuery} from "../../../../../../function/refetchQuery";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import {verticalFormLayout} from "../../../../../../components/form/formWrapper";
import {useSetPspMerchantMutation} from "../../../../../../api/graphql/pspSetting";
import {notificationMessage} from "../../../../../../../recoil_state";
import {getConfigByName} from "../../../../../../../config";
import {BRAND_NAME} from "../../../../../../../config/brandVariable";

const SetupPspModal = (props) => {
    const {selectedPsp, pspSettingFieldList, isModalOpen, toggleModal} = props;
    const {t} = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const brandConfig = getConfigByName(BRAND_NAME);

    const {setPspMerchant} = useSetPspMerchantMutation();

    const pspSettingName = useMemo(() => selectedPsp?.name, [selectedPsp?.name]);

    const fieldList = useMemo(
        () => pspSettingFieldList?.filter((v) => v?.pspSetting?.name === pspSettingName),
        [isModalOpen, selectedPsp, pspSettingFieldList]
    );

    const shapeWithDynamicFields = fieldList?.reduce((obj, key) => {
        let fieldName = key?.fieldName;
        obj[fieldName] = yup.mixed().required();
        return obj;
    }, {});

    const yupSchemaPspSetting = yup.object().shape(shapeWithDynamicFields);

    const setPspMerchantForm = useForm({
        resolver: yupResolver(yupSchemaPspSetting),
        mode: "all",
    });

    const closeModal = () => {
        toggleModal("");
    };

    const handleSubmit = async () => {
        try {
            let values = setPspMerchantForm.getValues();
            await yupSchemaPspSetting.validate(values);
            let input = {
                ...values,
                psp: pspSettingName,
                merchant: brandConfig?.pspMerchant, // To Amend - hardcoded for testing now
            };
            const response = await setPspMerchant(JSON.stringify(input));
            if (response?.data?.setPspMerchant?.status) {
                refetchQuery("getPspSetting");
                refetchQuery("getPspSettingDetail");
                closeModal();
            }
        } catch (error: any) {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: t(error?.message),
                key: "pspSettingFormModal",
            });
        }
    };

    return (
        <Modal
            className="setupPspModal-1"
            title={selectedPsp?.isMerchantSetup ? `Update ${pspSettingName}` : `Setup ${pspSettingName}`}
            open={isModalOpen}
            width={"50%"}
            footer={null}
            destroyOnClose
            onCancel={closeModal}
            maskClosable={false}
            getContainer="#PspSettingTable"
        >
            <FormProvider {...setPspMerchantForm}>
                <Form className="setPspMerchant-form-1" labelWrap={true}>
                    <Row gutter={10}>
                        {fieldList?.map((field, index) => (
                            <Col key={index} className="gutter-row" span={24}>
                                <InputFormItem
                                    name={field?.fieldName}
                                    label={field?.fieldDisplayName}
                                    placeholder={field?.fieldDisplayName}
                                    layout={verticalFormLayout}
                                    labelCol={{span: 8, offset: 0}}
                                    labelAlign="left"
                                    required
                                />
                            </Col>
                        ))}
                    </Row>
                    <Row justify="end" className="margin-top-0-75">
                        <Button
                            onClick={closeModal}
                            // icon={<CloseOutlined />}
                            className="cancelBtn"
                        >
                            {t("common.cancel")}
                        </Button>
                        <Button
                            onClick={handleSubmit}
                            // icon={<SaveOutlined />}
                            className="button-submit-1"
                        >
                            {t("common.submit")}
                        </Button>
                    </Row>
                </Form>
            </FormProvider>
        </Modal>
    );
};

export default SetupPspModal;
