import {useSetRecoilState} from "recoil";
import {salesOpportunityStatusData, salesOpportunityStatusLoading} from "../state";
import {
    createSalesOpportunityStatus,
    deleteSalesOpportunityStatus,
    getSalesOpportunityStatus,
    sortSalesOpportunityStatus,
    updateSalesOpportunityStatus
} from "../../../../api/graphql/salesOpportunitySetting";
import useAuthorize from "../../../../_common/authorize";
import {notificationMessage} from "../../../../../recoil_state";
import {useTranslation} from "react-i18next";

export const useSalesOpportunitySettingFunction = () => {
    const setData = useSetRecoilState<any>(salesOpportunityStatusData)
    const setLoading = useSetRecoilState<any>(salesOpportunityStatusLoading)

    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {t} = useTranslation();
    const {baseErrorChecking, systemErrorNotification} = useAuthorize()

    const getSalesOpportunityStatusFunction = async () => {
        setLoading(true)
        try {
            const response = await getSalesOpportunityStatus()

            if (response?.getSalesOpportunityStatus?.__typename === "BaseError") {
                baseErrorChecking(response?.createUserVerificationField)
            } else {
                setData({
                    data: [...response?.getSalesOpportunityStatus?.data]?.sort((a, b) => a?.position - b?.position),
                    total: response?.getSalesOpportunityStatus?.total
                })
            }
        } catch (e) {
            systemErrorNotification()
        } finally {
            setLoading(false)
        }
    }

    const addSalesOpportunityStatusFunction = async (input, setLoading) => {
        try {
            setLoading(true)
            const response = await createSalesOpportunityStatus(input)

            if (response?.createSalesOpportunityStatus?.__typename === "BaseError") {
                baseErrorChecking(response?.createSalesOpportunityStatus)

                return "error"
            } else if (response?.createSalesOpportunityStatus?.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.createSalesOpportunityStatus?.msg),
                    key: "salesOpportunitySetting",
                });

                return "success"
            } else {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Add Sales Opportunity Status Successfully"),
                    key: "salesOpportunitySetting",
                });
                const refetch = await getSalesOpportunityStatusFunction()

                return "success"
            }
        } catch (e) {
            systemErrorNotification()
        } finally {
            setLoading(false)
        }
    }

    const updateSalesOpportunityStatusFunction = async (id, input, setLoading) => {
        try {
            setLoading(true)
            const response = await updateSalesOpportunityStatus(id, input)

            if (response?.updateSalesOpportunityStatus?.__typename === "BaseError") {
                baseErrorChecking(response?.updateSalesOpportunityStatus)

                return "error"
            } else if (response?.updateSalesOpportunityStatus?.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.updateSalesOpportunityStatus?.msg),
                    key: "salesOpportunitySetting",
                });

                return "success"
            } else {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Update Sales Opportunity Status Successfully"),
                    key: "salesOpportunitySetting",
                });
                const refetch = await getSalesOpportunityStatusFunction()

                return "success"
            }
        } catch (e) {
            systemErrorNotification()
        } finally {
            setLoading(false)
        }
    }

    const deleteSalesOpportunityStatusFunction = async (id, setLoading) => {
        try {
            setLoading(true)
            const response = await deleteSalesOpportunityStatus(id)

            if (response?.deleteSalesOpportunityStatus?.__typename === "BaseError") {
                baseErrorChecking(response?.deleteSalesOpportunityStatus)

                return "error"
            } else if (response?.deleteSalesOpportunityStatus?.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.deleteSalesOpportunityStatus?.msg),
                    key: "salesOpportunitySetting",
                });
            } else {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Delete Sales Opportunity Status Successfully"),
                    key: "salesOpportunitySetting",
                });
                const refetch = await getSalesOpportunityStatusFunction()
            }
        } catch (e) {
            systemErrorNotification()
        } finally {
            setLoading(false)
        }
    }

    const sortSalesOpportunityStatusFunction = async (input) => {
        try {
            setLoading(true)
            const response = await sortSalesOpportunityStatus(input)

            if (response?.sortSalesOpportunityStatus?.__typename === "BaseError") {
                baseErrorChecking(response?.sortSalesOpportunityStatus)

                return "error"
            } else if (response?.sortSalesOpportunityStatus?.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.sortSalesOpportunityStatus?.msg),
                    key: "salesOpportunitySetting",
                });
            } else {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Update Sales Opportunity Status Successfully"),
                    key: "salesOpportunitySetting",
                });
                const refetch = await getSalesOpportunityStatusFunction()
            }
        } catch (e) {
            systemErrorNotification()
        } finally {
            setLoading(false)
        }
    }

    return {
        getSalesOpportunityStatusFunction,
        addSalesOpportunityStatusFunction,
        updateSalesOpportunityStatusFunction,
        deleteSalesOpportunityStatusFunction,
        sortSalesOpportunityStatusFunction
    }
}