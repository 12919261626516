import {getColumnSearchProps, getSingleSelectFilterProps} from "../../../../../components/table/function/filterTable";
import React from "react";
import {useTranslation} from "react-i18next";
import {formatDateTimeTimezone} from "../../../../../function/_common";
import {Button} from "antd";

export const pendingListColumns = () => {
    const {t} = useTranslation();

    return [
        {
            title: t("lead.pending_list.file"),
            dataIndex: "fileName",
            key: "fileName",
            width: "40%",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        // {
        //     title: t("File Path"),
        //     dataIndex: "filePath",
        //     key: "filePath",
        //     ...getColumnSearchProps(),
        //     render: (text) => <p>{text || "-"}</p>,
        // },
        {
            title: t("lead.pending_list.uploadedBy"),
            dataIndex: "createdBy",
            key: "createdBy",
            render: (text) => <p>{text?.username || "-"}</p>,
        },
        {
            title: t("lead.pending_list.status"),
            dataIndex: "status",
            key: "status",
            ...getSingleSelectFilterProps([
                {label: t("lead.upload_status.failed"), value: "failed"},
                {label: t("lead.upload_status.in_queue"), value: "in_queue"},
                {label: t("lead.upload_status.processing"), value: "processing"},
                {label: t("lead.upload_status.success"), value: "success"},
                {label: t("lead.upload_status.success_with_error"), value: "success_with_error"}
            ]),
            render: (text) => <p>{text ? t(`lead.upload_status.${text}`) : "-"}</p>,
        },
        {
            title: t("lead.pending_list.remarks"),
            dataIndex: "remarks",
            key: "remarks",
            ...getColumnSearchProps(),
            render: (text) => text ? <a href={text.replace("https:/", "https://")} target={"_blank"} rel={"noreferrer"}><Button size={"small"}>{t("lead.pending_list.download_error_list")}</Button></a> : <p>-</p>,
        },
        {
            title: t("lead.pending_list.updatedAt"),
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (text) => {
                const convertedDate = text ? (new Date(parseInt(text))).toISOString() : null;
                return <p>{convertedDate ? formatDateTimeTimezone(convertedDate) : "-"}</p>
            },
        },
    ]
}