import React, {useEffect} from "react";
import ArenaColorSetting_1 from "./view/layout1";
import {useGetColorSetting} from "./function/useGetQueryHooks";

const ArenaColorSetting = () => {
    let layout = 1;
    let component;
    const [data, fetchData] = useGetColorSetting()

    useEffect(() => {
        fetchData()
    }, []);

    switch (layout) {
        case 1:
            component = <ArenaColorSetting_1 data={data} fetchData={fetchData}/>
            break
    }

    return component
}

export default ArenaColorSetting