import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Form, Input, Modal, Popconfirm, Row, Space, Switch, Tabs} from "antd";
import {useTranslation} from "react-i18next";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {languageOptionsState} from "../../../../../users/_common/state";
import {getConfigByName} from "../../../../../../../config";
import {BRAND_NAME} from "../../../../../../../config/brandVariable";
import {addMamPammLegalDocument, getMamPammLegalDocumentById, updateMamPammLegalDocument} from "../../../../../../api/graphql/mamPammLegalDocument";
import useAuthorize from "../../../../../../_common/authorize";
import {notificationMessage} from "../../../../../../../recoil_state";
import TinyMceEditor from "./tinyMceEditor";

const LegalDocumentModal = (props) => {
    const {isOpen, handleCancel, mode, selectedDocument, moneyManagerAccountId, fetchLegalDocumentsFunction} = props
    const {t} = useTranslation()
    const [form] = Form.useForm()
    const {baseErrorChecking} = useAuthorize();

    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const languageOptions = useRecoilValue<any>(languageOptionsState);
    const [initialValues, setInitialValues] = useState({});
    const [defaultLegalDocument, setDefaultLegalDocument] = useState<any>({});

    const brandConfig = getConfigByName(BRAND_NAME)
    const title = mode === "edit" ? t("mampamm.legal_doc.update_lagel_doc") : t("mampamm.legal_doc.add_new_legal_doc")

    const getDefaultLegalDocument = async (id) => {
        try {
            const {getMamPammLegalDocumentByID: doc} = await getMamPammLegalDocumentById(id)
            setDefaultLegalDocument(doc)
        } catch (e) {}
    }

    useEffect(() => {
        if (selectedDocument) {
            let initialDisplayName = {}, initialTinyMce = {}
            languageOptions?.map(lang => {
                initialDisplayName[`displayName_${lang?.value}`] = selectedDocument?.displayName?.find(d => d?.language === lang?.value)?.content
                initialTinyMce[`tinyMce_${lang?.value}`] = selectedDocument?.tinyMce?.find(d => d?.language === lang?.value)?.content
            })

            setInitialValues({
                name: selectedDocument?.name,
                isActive: selectedDocument?.isActive || false,
                ...initialDisplayName,
                ...initialTinyMce
            })

            if (selectedDocument?.defaultDocumentId) {
               getDefaultLegalDocument(selectedDocument?.defaultDocumentId)
            }
        } else {
            setInitialValues({
                isActive: false
            })
        }
    }, [selectedDocument]);

    useEffect(() => {
        form.resetFields()
    }, [initialValues]);

    const rule = (label) => {
        return [
            {required: true, message: t("errorMsg.required.pleaseFillIn", {field: label})}
        ]
    }

    const tabItems = languageOptions?.map(lang => {
        return {
            key: lang?.value,
            label: lang?.label,
            forceRender: true,
            children: (
                <div className={"margin-top-0-75"}>
                    <Form.Item rules={rule(t("mampamm.legal_doc.displayName"))} name={`displayName_${lang?.value}`} label={t("mampamm.legal_doc.displayName")}>
                        <Input/>
                    </Form.Item>
                    <TinyMceEditor apiKey={brandConfig?.tinyMCEApiKey} rules={rule(t("mampamm.legal_doc.content"))} name={`tinyMce_${lang?.value}`}
                                   label={t("mampamm.legal_doc.content")} setValue={form.setFieldValue}/>
                </div>
            )
        }
    })

    const abstractValueByLanguage = (value, key) => {
        return Object.keys(value).map(d => {
            if (d?.includes(key)) {
                const lang = d.split("_")[1]

                return {
                    language: lang,
                    content: value[d]
                }
            }
        })?.filter(d => d !== undefined)
    }

    const handleSave = async (value) => {
        try {
            const input = {
                name: value.name,
                displayName: abstractValueByLanguage(value, "displayName"),
                tinyMce: abstractValueByLanguage(value, "tinyMce"),
                isActive: value.isActive,
                moneyManagerAccount: moneyManagerAccountId
            }

            if (mode === "edit") {
                const response = await updateMamPammLegalDocument(selectedDocument?._id, input)

                if (response?.updateMamPammLegalDocument?.__typename === "BaseError") {
                    await baseErrorChecking(response?.updateMamPammLegalDocument)
                    return
                } else if (response?.updateMamPammLegalDocument?.__typename === "CrmTaskApproval") {
                    setNotificationMessage({
                        status: "info",
                        title: "",
                        msg: t(response?.updateMamPammLegalDocument?.msg),
                        key: "mamPammLegalDocumentNotification",
                    });
                } else {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: t("mampamm.update_legal_doc_successfully"),
                        key: "mamPammLegalDocumentNotification",
                    });
                }
            } else {
                const response = await addMamPammLegalDocument(input)

                if (response?.addMamPammLegalDocument?.__typename === "BaseError") {
                    await baseErrorChecking(response?.addMamPammLegalDocument)
                    return
                } else if (response?.addMamPammLegalDocument?.__typename === "CrmTaskApproval") {
                    setNotificationMessage({
                        status: "info",
                        title: "",
                        msg: t(response?.addMamPammLegalDocument?.msg),
                        key: "mamPammLegalDocumentNotification",
                    });
                } else {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: t("mampamm.add_legal_doc_successfully"),
                        key: "mamPammLegalDocumentNotification",
                    });
                }
            }

            fetchLegalDocumentsFunction()
            handleCancel()
        } catch (e) {
        }
    }

    const resetContentToDefaultHandler = () => {
        let initialTinyMce = {}

        languageOptions?.map(lang => {
            initialTinyMce[`tinyMce_${lang?.value}`] = defaultLegalDocument?.tinyMce?.find(d => d?.language === lang?.value)?.content
        })

        setInitialValues(prevState => ({...prevState, ...initialTinyMce}))
    }

    return (
        <Modal
            open={isOpen}
            className={"update-legal-docs-modal"}
            title={title}
            maskClosable={false}
            onCancel={handleCancel}
            width={"90%"}
            destroyOnClose
            footer={
                <Row justify={"end"}>
                    <Space>
                        {
                            selectedDocument?.defaultDocumentId &&
                            <Popconfirm
                                title={t("mampamm.legal_doc.reset_content_to_default")}
                                description={t("mampamm.legal_doc.reset_description")}
                                okText={t("common.confirm")}
                                onConfirm={resetContentToDefaultHandler}
                                cancelText={t("common.cancel")}
                                overlayStyle={{width: 300}}
                                getPopupContainer={(triggerNode) => triggerNode.parentElement as HTMLElement}
                            >
                            <Button>{t("mampamm.legal_doc.reset_content_to_default")}</Button>
                            </Popconfirm>
                        }
                        <Button type={"primary"} onClick={() => form.submit()}>{t("common.save")}</Button>
                    </Space>
                </Row>
            }
        >
            <Form form={form} layout={"vertical"} onFinish={handleSave} initialValues={initialValues}>
                <Row gutter={[16, 2]}>
                    <Col xs={24} md={15}>
                        <Form.Item rules={rule(t("mampamm.legal_doc.name"))} name={"name"} label={t("mampamm.legal_doc.name")}>
                            <Input disabled={mode === "edit"}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={9}>
                        <Form.Item name={"isActive"} label={t("mampamm.legal_doc.isActive")} valuePropName={"checked"}>
                            <Switch/>
                        </Form.Item>
                    </Col>
                </Row>
                <Tabs items={tabItems}/>
            </Form>
        </Modal>
    )
}

export default LegalDocumentModal