import React, {useEffect} from "react";
import {Button, Col, Form, Input, Modal, Popconfirm, Row, Select, Space} from "antd";
import {useTranslation} from "react-i18next";
import {CurrencyList} from "../../../../../../../../../../config/currencyList";
import {useIsMobile} from "../../../../../../../../../_common/layout";

const AddBankModal = (props) => {
    const {isOpen, setIsOpen, onSubmitHandler, updateLoading} = props
    const {t} = useTranslation()
    const [form] = Form.useForm()
    const isMobile = useIsMobile(768);

    const closeModal = () => {
        setIsOpen(false)
    }

    const currencyOptions = Object.entries(CurrencyList).map(([code, data]) => ({
        label: t(`${code} - ${data?.name}`),
        value: code,
    }));

    useEffect(() => {
        form.resetFields()
    },[isOpen])

    return (
        <Modal
            title={t("common.addBank")}
            getContainer="#clientDetail"
            open={isOpen}
            width={isMobile ? "90%" : "60%"}
            closable={true}
            destroyOnClose
            okText={t("common.add")}
            cancelText={t("common.cancel")}
            onOk={form.submit}
            okButtonProps={{loading: updateLoading}}
            onCancel={closeModal}
        >
            <Form
                onFinish={onSubmitHandler}
                style={{width: "100%"}}
                labelCol={{flex: "150px"}}
                labelWrap
                form={form}
                colon={false}
            >
                <Row gutter={[16, 8]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            labelAlign={"left"}
                            label={t("clientUser.bank.bankName")}
                            name={`bank_add`}
                            rules={[{required: true}]}
                            required
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            labelAlign={"left"}
                            label={t("clientUser.bank.accountNo")}
                            name={`account_no_add`}
                            rules={[{required: true}]}
                            required
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            labelAlign={"left"}
                            label={t("clientUser.bank.accountHolder")}
                            name={`account_holder_add`}
                            rules={[{required: true}]}
                            required
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            labelAlign={"left"}
                            label={t("clientUser.bank.currency")}
                            name={`currency_add`}
                            rules={[{required: true}]}
                            required
                        >
                            <Select
                                options={currencyOptions}
                                showSearch
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            labelAlign={"left"}
                            label={t("clientUser.bank.swiftCode")}
                            name={`swift_code_add`}
                            rules={[{required: true}]}
                            required
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            labelAlign={"left"}
                            label={t("clientUser.bank.iban")}
                            name={`iban_add`}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            labelAlign={"left"}
                            label={t("clientUser.bank.bankAddress1")}
                            name={`bank_address_1_add`}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            labelAlign={"left"}
                            label={t("clientUser.bank.bankAddress2")}
                            name={`bank_address_2_add`}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            labelAlign={"left"}
                            label={t("clientUser.bank.city")}
                            name={`city_add`}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            labelAlign={"left"}
                            label={t("clientUser.bank.state")}
                            name={`state_add`}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            labelAlign={"left"}
                            label={t("clientUser.bank.postcode")}
                            name={`postcode_add`}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            labelAlign={"left"}
                            label={t("clientUser.bank.country")}
                            name={`country_add`}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default AddBankModal