import React from "react";
import {Row, Space} from "antd";
import {useTranslation} from "react-i18next";
import ButtonAntDesign from "../../../../../../components/button/buttonAntDesign";

const AnnouncementTopBar = (props) => {
    const {
        openFormModal,
        announcementPermission
    } = props;
    const {t} = useTranslation();

    return (
        <Row className={"margin-bottom-0-75 display-flex"} style={{justifyContent: "end"}}>
            <Space>
                {announcementPermission?.messageSetting?.announcement?.add ? (
                    <ButtonAntDesign
                        type={"primary"}
                        onClick={() => openFormModal("Add")}
                        text={t("Add")}
                    />
                ) : <div style={{height: "26px"}}/>}
            </Space>
        </Row>
    );
};

export default AnnouncementTopBar;
