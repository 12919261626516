import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Divider, Form, Input, InputNumber, InputRef, message, Modal, Radio, Row, Select, Space, Switch, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {useUpdateFieldListMutation, useUpdateLeadsFieldListMutation} from "../../../../../../api/graphql/fieldsSetting";
import useAuthorize from "../../../../../../_common/authorize";
import {DeleteOutlined, PlusOutlined, UndoOutlined} from "@ant-design/icons";
import {isArray} from "@apollo/client/utilities";

const FieldSettingModalForm = (props) => {
    const {type, isOpen, close, item} = props
    const {t} = useTranslation()
    const [form] = Form.useForm()
    const {systemErrorNotification} = useAuthorize()
    const [unique, setUnique] = useState<boolean>()
    const [required, setRequired] = useState<boolean>()
    const [optionValueItems, setOptionValueItems] = useState<String[]>([]);
    const [name, setName] = useState('');
    const inputRef = useRef<InputRef>(null);

    const {updateFieldList, loading} = useUpdateFieldListMutation()
    const {updateUserLeadsFieldList, loading: leadsLoading} = useUpdateLeadsFieldListMutation()

    const onSubmit = async (value) => {
        try {
            if (type === "leads") await updateUserLeadsFieldList({
                ...value,
                fieldList: item?._id,
                // minCharacterLimit: value?.minCharacterLimit || 0,
                // maxCharacterLimit: value?.maxCharacterLimit || 0
            })


            else {
                const input = {
                    ...value,
                    minCharacterLimit: value?.minCharacterLimit || 0,
                    maxCharacterLimit: value?.maxCharacterLimit || 0
                }

                if (item?.isOptionValuesEditable) {
                    input.optionValue = optionValueItems?.join(",");
                }
                // const optionValue = optionValueItems?.map((d) => d.replace(/[^a-zA-Z0-9\s]|[\./#]/g, '').replace(/\s+/g, '_').toLowerCase())?.join(",")

                await updateFieldList(item?._id, input);
            }
        } catch (e) {
            systemErrorNotification()
        }
        close()
    }


    useEffect(() => {
        setUnique(item?.isUnique)
        setRequired(item?.isRequired)
    }, [item])

    useEffect(() => {
        if (isOpen) form.resetFields()
    }, [isOpen])

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.preventDefault();
        if (!name) {
            message.error("Please Fill in comment field.")
            inputRef.current?.focus()
            return
        }

        if (optionValueItems.find(d => d === name)) {
            message.error("Duplicate comment found.")
            return
        }

        setOptionValueItems([...optionValueItems, name]);
        setName('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    useEffect(() => {
        if (isArray(item?.optionValue)) setOptionValueItems(item?.optionValue?.map(d => t(d?.label)))
    }, [item])

    return (
        <Modal
            title={`${t("field.updateFieldSetting")} - ${t(item?.label)}`}
            open={isOpen}
            onCancel={close}
            onOk={form.submit}
            cancelText={t("common.cancel")}
            okText={t("common.update")}
            okButtonProps={{loading: loading || leadsLoading}}
            cancelButtonProps={{disabled: loading || leadsLoading}}
            destroyOnClose={true}
        >
            <Form onFinish={onSubmit} form={form} style={{margin: 16}}>
                <Form.Item
                    label={t("field.isSensitive")}
                    name={"isSensitive"}
                    colon={false}
                    initialValue={item?.isSensitive}
                    style={{textAlign: "right"}}
                    className={"margin-top-0-75"}
                >
                    <Switch defaultChecked={item?.isSensitive}/>
                </Form.Item>
                <Form.Item
                    label={t("field.isRequired")}
                    name={"isRequired"}
                    colon={false}
                    initialValue={item?.isRequired}
                    style={{textAlign: "right"}}
                    className={"margin-top-0-75"}
                >
                    <Switch defaultChecked={item?.isRequired} onChange={(val) => setRequired(val)}/>
                </Form.Item>
                <Form.Item
                    label={t("field.isConfirmRequired")}
                    name={"isConfirmRequired"}
                    colon={false}
                    initialValue={item?.isConfirmRequired}
                    style={{textAlign: "right"}}
                    className={"margin-top-0-75"}
                >
                    <Switch defaultChecked={item?.isConfirmRequired}/>
                </Form.Item>
                <Form.Item
                    label={t("field.isUnique")}
                    name={"isUnique"}
                    colon={false}
                    initialValue={item?.isUnique}
                    style={{textAlign: "right"}}
                    className={"margin-top-0-75"}
                >
                    <Switch defaultChecked={item?.isUnique} onChange={(val) => setUnique(val)}/>
                </Form.Item>
                {
                    item?.fieldType !== "password" && type !== "leads" &&
                    <Form.Item
                        label={t("field.isDisplayToClient")}
                        name={"isDisplayToClient"}
                        colon={false}
                        initialValue={item?.isDisplayToClient}
                        style={{textAlign: "right"}}
                        className={"margin-top-0-75"}
                    >
                        <Switch defaultChecked={item?.isDisplayToClient}/>
                    </Form.Item>
                }
                {
                    (type === "general" && unique && required) &&
                    <Form.Item
                        label={t("field.isUsedForLogin")}
                        name={"isUsedForLogin"}
                        colon={false}
                        initialValue={item?.isUsedForLogin}
                        style={{textAlign: "right"}}
                        className={"margin-top-0-75"}
                    >
                        <Switch defaultChecked={item?.isUsedForLogin}/>
                    </Form.Item>
                }
                {
                    type !== "leads" &&
                    <>
                        {
                            (item?.fieldType === "text" || item?.fieldType === "textarea" || item?.fieldType === "password") &&
                            <>
                                <Row align={"middle"} className={"margin-top-0-75"}>
                                    <Col span={23}>
                                        <Form.Item
                                            label={t("field.minCharacterLimit")}
                                            name={"minCharacterLimit"}
                                            colon={false}
                                            initialValue={item?.minCharacterLimit || null}
                                            style={{textAlign: "right"}}
                                        >
                                            <InputNumber min={1} formatter={value => value ? `${value}` : "-"}/>
                                        </Form.Item>
                                    </Col>

                                    <Tooltip title={t("common.reset")}>
                                        <UndoOutlined className={"clickable margin-left-auto"} onClick={() => form.setFieldValue("minCharacterLimit", null)}/>
                                    </Tooltip>
                                </Row>
                                { item?.fieldType !== "password" &&
                                <Row align={"middle"} className={"margin-top-0-75"}>
                                    <Col span={23}>
                                        <Form.Item
                                            label={t("field.maxCharacterLimit")}
                                            name={"maxCharacterLimit"}
                                            colon={false}
                                            initialValue={item?.maxCharacterLimit || null}
                                            style={{textAlign: "right"}}
                                        >
                                            <InputNumber min={1} formatter={value => value ? `${value}` : "-"}/>
                                        </Form.Item>
                                    </Col>

                                    <Tooltip title={t("common.reset")}>
                                        <UndoOutlined className={"clickable margin-left-auto"} onClick={() => form.setFieldValue("maxCharacterLimit", null)}/>
                                    </Tooltip>
                                </Row>
                                }
                            </>
                        }
                        {
                            item?.fieldType !== "password" &&
                            <Form.Item
                                label={t("field.columnWidth")}
                                name={"columnWidth"}
                                colon={false}
                                initialValue={item?.columnWidth}
                                style={{textAlign: "right"}}
                                className={"margin-top-0-75"}
                            >
                                <Radio.Group
                                    options={[
                                        {label: t("common.auto"), value: 0},
                                        {label: "XS", value: 1},
                                        {label: "S", value: 2},
                                        {label: "M", value: 3},
                                        {label: "L", value: 4},
                                        {label: "XL", value: 5},
                                    ]}
                                    optionType={"button"}
                                    size={"small"}
                                />
                            </Form.Item>
                        }
                        {
                            item?.fieldType === "dropdown" && item?.isOptionValuesEditable &&
                            <Form.Item
                                label={t("field.optionValue")}
                                name={"optionValue"}
                                colon={false}
                                className={"margin-top-0-75"}
                                wrapperCol={{span: 12}}
                                labelCol={{span: 12}}
                                labelAlign={"left"}
                            >
                                <Select
                                    placeholder={t("common.fieldDropdownPreview")}
                                    value={null}
                                    dropdownMatchSelectWidth={false}
                                    dropdownRender={(menu) => (
                                        <>
                                            {menu}
                                            <>
                                                <Divider style={{margin: '8px 0'}}/>
                                                <Space style={{padding: '0 8px 4px'}}>
                                                    <Input
                                                        ref={inputRef}
                                                        value={name}
                                                        onChange={onNameChange}
                                                        onClick={(e) => e.stopPropagation()}
                                                        onKeyDown={(e) => e.stopPropagation()}
                                                    />
                                                    <Button type="text" icon={<PlusOutlined/>} onClick={addItem}>
                                                        {t("common.add")}
                                                    </Button>
                                                </Space>
                                            </>
                                        </>
                                    )}
                                >
                                    {
                                        optionValueItems !== undefined &&
                                        optionValueItems.map((d) => {
                                            return (
                                                <Select.Option value={d} label={d}>
                                                    <span>{d}</span>
                                                    <span style={{float: "right"}} className={"margin-left-0-25"}>
                                                      <DeleteOutlined
                                                          onClick={(e) => {
                                                              e.stopPropagation();
                                                              setOptionValueItems(prevState => prevState?.filter(c => c !== d))
                                                          }}
                                                      />
                                                    </span>
                                                </Select.Option>
                                            );
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </>
                }
            </Form>
        </Modal>
    )
}

export default FieldSettingModalForm