import React, {useEffect, useState} from "react";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import {columnsCT} from "../../../function/tableColumn_CT";
import {getProduct, getProductCount, useDeleteProductMutation} from "../../../../../../api/graphql/product";
import ProductModal from "./productModal";
import {Popconfirm, Space, Tooltip} from "antd";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import {getSpreadGroup} from "../../../../../../api/graphql/spreadGroup";
import {getProductGroup} from "../../../../../../api/graphql/productGroup";
import {useTranslation} from "react-i18next";
import useAuthorize from "../../../../../../_common/authorize";
import {getSpreadGroupMt5} from "../../../../../../api/graphql/spreadGroupMt5";
import {columnsMT5} from "../../../function/tableColumn_MT5";
import {refetchQuery} from "../../../../../../function/refetchQuery";

function ProductTable_1(props) {
    const {
        server,
        isOpen,
        close,
        mode,
        productMode,
        openEdit,
        selectedItem,
        tableName,
        setExportData,
        setExportColumn,
        currentServer,
        permission,
        setExportLoading,
    } = props;
    const {t} = useTranslation();
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [orderBy, setOrderBy] = useState("");
    const [filter, setFilter] = useState({serverName: server?.name});
    const [spreadGroupMt5, setSpreadGroupMt5] = useState([]);

    const {data: totalCount} = getProductCount({filter: {...filter, mode: productMode, productType: ["selfTradeLive", "selfTradeDemo"]}});
    const {loading, error, data} = getProduct({filter: {...filter, mode: productMode, productType: ["selfTradeLive", "selfTradeDemo"]}}, limit, offset, orderBy);
    const {data: spreadGroupList} = getSpreadGroup({filter: {serverName: server?.name}});
    const {data: productGroupList} = getProductGroup({filter: {type: server?.mode?.length > 1 ? null : server?.mode?.[0]}});
    const {data: exportData, loading: exportLoading} = getProduct(
        {
            filter: {
                ...filter,
                mode: productMode,
                productType: ["selfTradeLive", "selfTradeDemo"]
            },
        },
        0,
        0,
        orderBy
    );
    const {deleteProduct} = useDeleteProductMutation();
    const {systemErrorNotification} = useAuthorize();

    const columns = server?.platform === "cTrader" ? columnsCT : columnsMT5;

    const handleDeleteProduct = (id) => {
        try {
            deleteProduct(id);
        } catch (error) {
            systemErrorNotification();
        }
    };

    const finalExportData = async (data) => {
        const filtered = await data?.map((d, i) => {
            return {
                ...d,
                spreadGroup: d?.spreadGroup?.map((d) => d?.name)?.join(", "),
                spreadGroupMt5: d?.spreadGroupMt5?.map((d) => d?.group)?.join(", "),
                leverage: d?.leverage?.join(", "),
                productType: d?.productType?.replace("Live", "")?.replace("Demo", "")?.replace("T", " t"),
                productGroup: d?.productGroup?.name,
                networkType_network: d?.network?.map((user) => user?.username)?.join(", "),
                interestRateType_interestRate: `${d?.interestRate} /${d?.interestRateType}`,
                include: d?.network?.map((user) => user?.username)?.join(", "),
                exclude: d?.exclude?.map((user) => user?.username)?.join(", "),
                networkType: d?.networkType?.replace("partial", "Some User")?.replace("all", "All User")?.replace("own", "Only Me"),
            };
        });
        return filtered;
    };

    const getSpreadGroupMt5Preload = async () => {
        const response = await getSpreadGroupMt5({
            serverSetting: server?.id,
        });

        setSpreadGroupMt5(response?.getSpreadGroupMt5?.data);
    };

    useEffect(() => {
        if (server?.platform === "mt5" || server?.platform === "xTrader") {
            getSpreadGroupMt5Preload();
        }

        if (exportData?.getProduct?.data?.length > 0 && columns?.length > 0) {
            finalExportData(exportData?.getProduct?.data).then((latestData) => {
                setExportData(latestData);
            });
            setExportColumn(columns);
        }
    }, [exportData?.getProduct?.data, server?.name, currentServer]);

    useEffect(() => {
        setExportLoading(exportLoading);
    }, [exportLoading]);

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize);
        setOffset(limit * (page - 1));
    };

    useEffect(() => {
        if (offset === totalCount?.getProductCount) setOffset(0);
    }, [totalCount?.getProductCount]);

    useEffect(() => {
        refetchQuery("getProduct");
    }, [isOpen]);

    return (
        <div className={"margin-top-0-75"}>
            <h3>{productMode === "live" ? "Live Product" : "Demo Product"}</h3>
            <ProductModal
                spreadGroupList={spreadGroupList}
                spreadGroupMt5={spreadGroupMt5}
                productGroupList={productGroupList}
                isOpen={isOpen && currentServer?.name === server?.name}
                close={close}
                item={selectedItem}
                mode={mode}
                server={server}
            />
            <TableAntDesign
                data={data?.getProduct?.data}
                tableName={tableName}
                columns={[
                    ...columns,
                    {
                        title: "Action",
                        key: "action",
                        width: 100,
                        align: "center",
                        render: (_, record: any) => (
                            <Space>
                                {permission?.edit?.edit && (
                                    <Tooltip title={t("common.edit")}>
                                        <a style={{color: "green"}} onClick={() => openEdit(record)}>
                                            <AiOutlineEdit />
                                        </a>
                                    </Tooltip>
                                )}
                                {permission?.delete?.delete && (
                                    <Tooltip title={t("common.delete")}>
                                        <Popconfirm
                                            placement="left"
                                            description={t("Are you sure to delete this product?")}
                                            title={t(`Delete the Product`)}
                                            onConfirm={() => {
                                                handleDeleteProduct(record.id);
                                            }}
                                            okText={t("Yes")}
                                            okType="danger"
                                            cancelText={t("No")}
                                        >
                                            <a>
                                                <AiOutlineDelete style={{color: "red"}} />
                                            </a>
                                        </Popconfirm>
                                    </Tooltip>
                                )}
                            </Space>
                        ),
                    },
                ]}
                size={"small"}
                actionPermission={permission?.edit?.edit || permission?.delete?.delete}
                loading={loading}
                filter={setFilter}
                server={server?.name}
                order={setOrderBy}
                pagination={{
                    pageSize: limit,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                    total: totalCount?.getProductCount,
                    showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
                }}
            />
        </div>
    );
}

export default ProductTable_1;
