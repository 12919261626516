import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {useLoginMutation} from "../../../shared/api/graphql/auth";
import {userLogoutState, userToken} from "../../../recoil_state";
import {BRAND_NAME} from "../../../config/brandVariable";
import LoginPage_excotrades from "./view/excotrades";
import LoginPage_ridgewayMarkets from "./view/ridgewaymarkets";
import LoginPage_alliumtrades from "./view/alliumtrades";
import LoginPage_fundamarkets from "./view/fundamarkets";
import LoginPage_minosfutures from "./view/minosfutures";
import LoginPage_cronoscap from "./view/cronoscap";
import LoginPage_metapro from "./view/metapro";
import LoginPage_quorvex from "./view/quorvex";
import LoginPage_BlueOcean from "./view/blueocean";
import LoginPage_Antos from "./view/antos";
import useAuthorize from "../../../shared/_common/authorize";
import {brandDetailsState} from "../../../shared/pages/users/_common/state";
import LoginPage_dearc from "./view/dearc";

function LoginPage() {
    let layout = 1;
    let component;
    const navigate = useNavigate();
    const {getClientDeviceInfo} = useAuthorize();
    const setUserToken = useSetRecoilState(userToken);
    const setUserLogout = useSetRecoilState(userLogoutState);
    const brandSetting = useRecoilValue(brandDetailsState);
    const {login, data} = useLoginMutation();

    const [loading, setLoading] = useState(false);

    const loginAction = async (values) => {
        setLoading(true)

        const {ip, browser, device} = await getClientDeviceInfo();
        let input = {...values, ip, browser, device};
        login(input).then((i) => {
            if (i.data?.login.login) {
                setUserToken(i.data?.login.accessToken);
                localStorage.setItem("username", i.data?.login?.username);
                localStorage.setItem("a_t", i.data?.login.accessToken);
                localStorage.setItem("userId", i.data?.login.userid);
                setUserLogout(false);
                return navigate("/users/client");
            }
        });

        setLoading(false)
    };

    const props = {
        loginAction: loginAction,
        loginData: data,
        loginLoading: loading,
        brandSetting: brandSetting,
    };

    switch (BRAND_NAME) {
        case "excotrades":
            component = <LoginPage_excotrades {...props} />;
            break;
        case "ridgewaymarkets":
            component = <LoginPage_ridgewayMarkets {...props} />;
            break;
        case "alliumtrades":
            component = <LoginPage_alliumtrades {...props} />;
            break;
        case "fundamarkets":
            component = <LoginPage_fundamarkets {...props} />;
            break;
        case "minosfutures":
            component = <LoginPage_minosfutures {...props} />;
            break;
        case "cronoscap":
            component = <LoginPage_cronoscap {...props} />;
            break;
        case "metapromarkets":
            component = <LoginPage_metapro {...props} />;
            break;
        case "qvmarkets":
            component = <LoginPage_quorvex {...props} />;
            break;
        case "blueocean":
            component = <LoginPage_BlueOcean {...props} />;
            break;
        case "antospnc":
        case "antospncOld":
            component = <LoginPage_Antos {...props} />;
            break;
        case "dearc":
            component = <LoginPage_dearc {...props} />;
            break;
        default:
            component = <LoginPage_excotrades {...props} />;
            break;
    }

    return component;
}

export default LoginPage;
