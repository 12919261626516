import React from "react";
import {getServerSetting} from "../../../api/graphql/serverSetting";
import SpreadGroup_1 from "./view/layout1/index";
import {useRecoilValue} from "recoil";
import {selfPermissionObj} from "../../../../recoil_state";

function SpreadGroup() {
  let layout = 1;
  let component;
  const permission = useRecoilValue<any>(selfPermissionObj)
  const { data, loading } = getServerSetting({ filter: null });

  switch (layout) {
    case 1:
      component = (
          <SpreadGroup_1
              data={data}
              loading={loading}
              permission={permission?.accountSetting?.spreadGroup}
          />
      );
      break;

    default:
      component = (
          <SpreadGroup_1
              data={data}
              loading={loading}
              permission={permission?.accountSetting?.spreadGroup}
          />
      );
      break;
  }

  return component;
}

export default SpreadGroup;
