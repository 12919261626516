import React from "react";
import {Button, Col, Divider, Row} from "antd";
import {useTranslation} from "react-i18next";
import {getDataWithLanguage, handleOnChangeWithLanguage,} from "../../../function/common";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import TextAreaInputFormItem from "../../../../../../components/form/textAreaInputFormItem";
import {SaveOutlined} from "@ant-design/icons";

const GeneralForm = (props) => {
    const {languageCode, data, setData, isEditable, onBrandDetailsSubmit, submitLoading} = props;
    const {t} = useTranslation();
    const labelCol = {span: 12, offset: 0}

    const handleOnchange = (e, fieldName, fieldType = "content") => {
        handleOnChangeWithLanguage(e, fieldName, fieldType, languageCode, setData);
    };


    return (
        <>
            <Row gutter={[16, 16]} style={{marginTop: "30px"}}>
                <Col xs={24} md={12}>
                    <InputFormItem
                        name={`companyName_${languageCode}`}
                        label={t("brandSetting.companyName")}
                        placeholder={t("brandSetting.companyName")}
                        value={getDataWithLanguage(
                            data,
                            "companyName",
                            "content",
                            languageCode
                        )}
                        onChange={(e) => handleOnchange(e, "companyName", "content")}
                        disabled={!isEditable}
                        labelCol={labelCol}
                        wrapperCol={labelCol}
                    />
                </Col>
                <Col xs={24} md={12}>
                    <InputFormItem
                        name={`companyNameShort_${languageCode}`}
                        label={t("brandSetting.companyNameShort")}
                        placeholder={t("brandSetting.companyNameShort")}
                        value={getDataWithLanguage(
                            data,
                            "companyNameShort",
                            "content",
                            languageCode
                        )}
                        onChange={(e) => handleOnchange(e, "companyNameShort", "content")}
                        disabled={!isEditable}
                        labelCol={labelCol}
                        wrapperCol={labelCol}
                    />
                </Col>
                <Col xs={24} md={12}>
                    <TextAreaInputFormItem
                        name={`companyAddress_${languageCode}`}
                        label={t("brandSetting.companyAddress")}
                        placeholder={t("brandSetting.companyAddress")}
                        value={getDataWithLanguage(
                            data,
                            "companyAddress",
                            "content",
                            languageCode
                        )}
                        onChange={(e) => handleOnchange(e, "companyAddress", "content")}
                        disabled={!isEditable}
                        labelCol={labelCol}
                        wrapperCol={labelCol}
                    />
                </Col>
                <Col xs={24} md={12}>
                    <InputFormItem
                        name={`copyRight_${languageCode}`}
                        label={t("brandSetting.copyRight")}
                        placeholder={t("brandSetting.copyRight")}
                        value={getDataWithLanguage(
                            data,
                            "copyRight",
                            "content",
                            languageCode
                        )}
                        onChange={(e) => handleOnchange(e, "copyRight", "content")}
                        disabled={!isEditable}
                        labelCol={labelCol}
                        wrapperCol={labelCol}
                    />
                </Col>
            </Row>

            <Divider style={{marginTop: "30px"}}/>

            <Row gutter={[16, 16]} style={{marginTop: "30px"}}>
                <Col xs={24} md={24}>
                    <InputFormItem
                        name={`companySlogan_${languageCode}`}
                        label={t("brandSetting.companySlogan")}
                        placeholder={t("brandSetting.companySlogan")}
                        showCount
                        maxLength={50}
                        value={getDataWithLanguage(
                            data,
                            "companySlogan",
                            "content",
                            languageCode
                        )}
                        onChange={(e) => handleOnchange(e, "companySlogan", "content")}
                        disabled={!isEditable}
                        labelCol={{span: 6, offset: 0}}
                        wrapperCol={{span: 18, offset: 0}}
                    />
                </Col>
            </Row>
            {isEditable &&
            <Row className={"margin-top-0-75"} justify={"end"}>
                <Button
                    onClick={onBrandDetailsSubmit}
                    loading={submitLoading}
                    type={"primary"}
                >
                    {t("common.update")}
                </Button>
            </Row>
            }
        </>
    );
};

export default GeneralForm;
