import React from "react";
import {getServerSetting} from "../../../../api/graphql/serverSetting";
import SymbolCategory_1 from "./view/layout1/index";
import {useRecoilValue} from "recoil";
import {selfPermissionObj} from "../../../../../recoil_state";

function SymbolCategory() {
    let layout = 1;
    let component;
    const { data, loading, error } = getServerSetting({ filter: null });
    const permission = useRecoilValue<any>(selfPermissionObj)

    switch (layout) {
        case 1:
            component = (
                <SymbolCategory_1
                    data={data}
                    loading={loading}
                    error={error}
                    permission={permission?.accountSetting}
                />
            );
            break;

        default:
            component = (
                <SymbolCategory_1
                    data={data}
                    loading={loading}
                    error={error}
                    permission={permission?.accountSetting}
                />
            );
            break;
    }

    return component;
}

export default SymbolCategory;
