import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import * as yup from "yup";
import yupFormSchema from "../../../../../../../components/yup/yupFormSchema";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import SelectFormItem from "../../../../../../../components/form/selectFormItem";
import {horizontalFormLayout} from "../../../../../../../components/form/formWrapper";
import InputFormItem from "../../../../../../../components/form/inputFormItem";
import {Button, Form} from "antd";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import {useRecoilValue} from "recoil";
import {categoryListOptionState} from "../../../state";
import {useAddSymbolMappingCategoryCoreMutation, useUpdateSymbolMappingCategoryCoreMutation} from "../../../../../../../api/graphql/symbolMappingCategory";
import useAuthorize from "../../../../../../../_common/authorize";

const SymbolCategoryForm = (props) => {
    const {mode, item, close} = props
    const {t} = useTranslation();

    const categoryFormOption = useRecoilValue(categoryListOptionState)
    const {addSymbolMappingCategoryCore, loading: loadingAdd} = useAddSymbolMappingCategoryCoreMutation()
    const {updateSymbolMappingCategoryCore, loading: loadingUpdate} = useUpdateSymbolMappingCategoryCoreMutation()
    const {systemErrorNotification} = useAuthorize();

    const yupSchema = yup.object().shape({
        parent: yupFormSchema.string("parent"),
        categoryName: yupFormSchema.string("categoryName", {required: true}),
        label: yupFormSchema.string("label", {required: true}),
    });

    const [initialValues] = useState(() => ({
        parent: item?.parent?._id || "",
        categoryName: item?.categoryName || "",
        label: item?.label || "",
    }));

    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
        defaultValues: initialValues,
    });

    const setupLabel = (e) => {
        form.setValue("label", "symbolCategory." + e?.toLowerCase()?.replaceAll(" ", "_"))
    }

    const onSubmit = async (value) => {
        try {
            if (mode === "Add") {
                const res = await addSymbolMappingCategoryCore(value)
                if (res?.data?.addSymbolMappingCategoryCore?.errKey === "admin.err.record_similar_name_exist") {
                    form.setError("categoryName", {message: t(res?.data?.addSymbolMappingCategoryCore?.errKey).toString()})
                    return
                }
            } else if (mode === "Edit") {
                await updateSymbolMappingCategoryCore(item?._id, value)
            }
            close()
        } catch (error) {
            systemErrorNotification();
        }
    }

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="form-1">
                <SelectFormItem
                    name={"parent"}
                    label={t("Parent")}
                    labelCol={{span: 8, offset: 0}}
                    wrapperCol={{span: 16, offset: 0}}
                    layout={horizontalFormLayout}
                    options={categoryFormOption}
                />
                <InputFormItem
                    name={"categoryName"}
                    label={t("Category Name")}
                    placeholder={t("Category Name")}
                    labelCol={{span: 8, offset: 0}}
                    wrapperCol={{span: 16, offset: 0}}
                    layout={horizontalFormLayout}
                    onChange={setupLabel}
                    required
                />
                <InputFormItem
                    name={"label"}
                    label={t("Label")}
                    placeholder={t("Label")}
                    labelCol={{span: 8, offset: 0}}
                    wrapperCol={{span: 16, offset: 0}}
                    layout={horizontalFormLayout}
                    disabled
                    required
                />
                <SelectFormItem
                    name={"type"}
                    label={t("Type")}
                    labelCol={{span: 8, offset: 0}}
                    wrapperCol={{span: 16, offset: 0}}
                    layout={horizontalFormLayout}
                    options={[
                        {label: t("symbolCategory.type.stocks"), value: "stocks"},
                        {label: t("symbolCategory.type.cryptos"), value: "cryptos"},
                        {label: t("symbolCategory.type.currencies"), value: "currencies"},
                        {label: t("symbolCategory.type.commodities"), value: "commodities"},
                        {label: t("symbolCategory.type.indices"), value: "indices"},
                    ]}
                />
                <Form.Item className="text-align-right" style={{paddingTop: "12px"}}>
                    <Button
                        onClick={close}
                        icon={<CloseOutlined/>}
                        className="button-cancel-1"
                        disabled={loadingAdd || loadingUpdate}
                    >
                        {t("common.cancel")}
                    </Button>
                    <Button
                        onClick={form.handleSubmit(onSubmit)}
                        icon={<SaveOutlined/>}
                        className="button-submit-1"
                        loading={loadingAdd || loadingUpdate}
                    >
                        {t("common.submit")}
                    </Button>
                </Form.Item>
            </form>
        </FormProvider>
    )
}

export default SymbolCategoryForm