import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {FormProvider} from "react-hook-form";
import {Button, Col, Divider, Form, Modal, Row, message} from "antd";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import {horizontalFormLayout, verticalFormLayout} from "../../../../../../components/form/formWrapper";
import SwitchFormItem from "../../../../../../components/form/switchFormItem";
// import UploadFormItem from "../../../component/uploadFormItem";
import InputNumberFormItem from "../../../../../../components/form/inputNumberFormItem";
import SelectInputFormItem from "../../../../../../components/form/selectInputFormItem";
import BankDetailsForm from "./bankDetailsForm";
import LocalDepoBankDetailsForm from "./localDepoBankDetailsForm";
import SelectFormItem from "../../../../../../components/form/selectFormItem";
import {getConfigByName} from "../../../../../../../config";
import {BRAND_NAME} from "../../../../../../../config/brandVariable";
import LogoUploadFormItem from "../../../component/logoUploadFormItem";
import useAuthorize from "../../../../../../_common/authorize";
import {processingTimeOptions} from "../../../function/common";
import {useIsMobile} from "../../../../../../_common/layout";

const PspSettingForm = (props) => {
    const {form, formMode, formPsp, item, pspSettingName, pspCurrencyList, handleSelectedCurChg, toogleDescriptionModal} = props;
    const {t} = useTranslation();
    const isMobile = useIsMobile(768);
    const {systemErrorNotification} = useAuthorize();
    const [errMsg, setErrMsg] = useState("");

    let backendURL = getConfigByName(BRAND_NAME).backendURL;
    const getDefaultLogoUrl = (formPsp, backendURL, fallbackUrl) => {
        return formPsp === "BankWire" || formPsp === "LocalDepo" ? `${backendURL}/assets/icon/psp/${fallbackUrl}` : "";
    };

    const [initialValues, setInitialValues] = useState(() => ({
        logo_light:
            item?.logo_light?.[0]?.link || getDefaultLogoUrl(formPsp, backendURL, formPsp === "LocalDepo" ? "local_depo.png" : "bankwire.png"),
        logo_dark: item?.logo_dark?.[0]?.link || getDefaultLogoUrl(formPsp, backendURL, formPsp === "LocalDepo" ? "local_depo.png" : "bankwire.png"),
        logoSmall_light:
            item?.logoSmall_light?.[0]?.link ||
            getDefaultLogoUrl(formPsp, backendURL, formPsp === "LocalDepo" ? "local_depo_small.png" : "bankwire_small.png"),
        logoSmall_dark:
            item?.logoSmall_dark?.[0]?.link ||
            getDefaultLogoUrl(formPsp, backendURL, formPsp === "LocalDepo" ? "local_depo_small.png" : "bankwire_small.png"),
    }));

    // const uploadOnRemove = (value, field) => {};

    const labelCol = {span: 10, offset: 0};

    // const crytoPspList = ["BTC", "ETH", "USDT"];

    const selectedCurrencyOptions = useMemo(() => pspCurrencyList?.map((cur) => ({label: cur, value: cur})), [pspCurrencyList]);

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");

    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        let filePath = file;
        setPreviewImage(filePath);
        setPreviewOpen(true);
    };

    const uploadOnChange = (e, field) => {
        try {
            const invalidFileSize = e?.file?.originFileObj?.size / 1000000 > 2;
            if (e?.file?.status === "done" && !invalidFileSize) {
                const newImageLink = e?.file?.response[0]?.link;
                form.setValue(field, newImageLink);
                setInitialValues((prevState) => {
                    return {
                        ...prevState,
                        [field]: newImageLink,
                    };
                });
            } else if (e?.file?.status === "error") {
                let errMsg = `${e?.file?.name} file upload failed.`;
                let accpetFileTypes = ["image/png", "image/jpeg"];
                if (!accpetFileTypes?.includes(e?.file?.type)) {
                    errMsg = `${e?.file?.name} - Invalid file format`;
                } else if (invalidFileSize) {
                    errMsg = `${e?.file?.name} - Invalid file size`;
                }
                message.error(errMsg);
            }
        } catch (e) {
            systemErrorNotification();
        }
    };

    const resetLogo = async (currentLogoName) => {
        try {
            const defaultLogoName = currentLogoName?.replace("logo", "defaultLogo");
            let defaultLogoLink = item?.[defaultLogoName]?.[0]?.link;
            if (formMode === "add") {
                switch (formPsp) {
                    case "BankWire":
                        defaultLogoLink = await getDefaultLogoUrl(
                            formPsp,
                            backendURL,
                            currentLogoName?.includes("Small") ? "bankwire_small.png" : "bankwire.png"
                        );
                        break;
                    case "LocalDepo":
                        defaultLogoLink = await getDefaultLogoUrl(
                            formPsp,
                            backendURL,
                            currentLogoName?.includes("Small") ? "local_depo_small.png" : "local_depo.png"
                        );
                        break;
                    default:
                        break;
                }
            }
            form.setValue(currentLogoName, defaultLogoLink);
            setInitialValues((prevState) => {
                return {
                    ...prevState,
                    [currentLogoName]: defaultLogoLink,
                };
            });
        } catch (e) {
            systemErrorNotification();
        }
    };

    let logoCondition = [
        `- ${t("Upload image in format JPEG, PNG only.")}`,
        `- ${t("Uploaded image size should be 300 x 300 px.")}`,
        `- ${t("Uploaded image size should not be more than 2 MB.")}`,
    ];

    let logoSmallCondition = [
        `- ${t("Upload image in format JPEG, PNG only.")}`,
        `- ${t("Uploaded image size should be 150 x 80 px.")}`,
        `- ${t("Uploaded image size should not be more than 2 MB.")}`,
    ];

    const processingTimeValue = form?.getValues()?.processingTime;

    useEffect(() => {
        if (processingTimeValue) {
            form?.setValue("processingTimeInput", "");
        }
    }, [processingTimeValue]);

    return (
        <div>
            <Modal open={previewOpen} footer={null} onCancel={handleCancel} destroyOnClose>
                <img style={{width: "100%"}} src={previewImage} />
            </Modal>
            <FormProvider {...form}>
                <Form
                    // onSubmit={form.handleSubmit(onSubmit)}
                    className="pspSetting-form-1"
                    labelWrap={true}
                >
                    {errMsg && <p style={{color: "red"}}>{errMsg}</p>}
                    <Row gutter={10}>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <InputFormItem
                                name={"name"}
                                label={"Name"}
                                placeholder={"Name"}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                disabled
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <InputFormItem
                                name={"displayName"}
                                label={"Display Name"}
                                placeholder={"Display Name"}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                disabled={formMode === "view"}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <InputFormItem
                                name={"description"}
                                label={"Description"}
                                placeholder={"Description"}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                disabled={formMode === "view"}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <SelectFormItem
                                name={"processingTime"}
                                label={t("Processing Time")}
                                options={processingTimeOptions}
                                placeholder={t("Processing Time")}
                                mode={"single"}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                disabled={formMode === "view"}
                                required
                            />
                        </Col>
                        <Col span={24}>
                            <Row justify="space-between" align="middle">
                                <Col className="gutter-row" xs={24} sm={8} md={8}>
                                    <LogoUploadFormItem
                                        data={initialValues?.logo_light}
                                        name={"logo_light"}
                                        handlePreview={handlePreview}
                                        uploadOnChange={uploadOnChange}
                                        resetLogo={resetLogo}
                                        disabled={formMode === "view"}
                                    />
                                </Col>
                                <Col className="gutter-row" xs={24} sm={8} md={8}>
                                    <LogoUploadFormItem
                                        data={initialValues?.logo_dark}
                                        name={"logo_dark"}
                                        handlePreview={handlePreview}
                                        uploadOnChange={uploadOnChange}
                                        resetLogo={resetLogo}
                                        disabled={formMode === "view"}
                                    />
                                </Col>
                                <Col md={8}>
                                    {logoCondition &&
                                        logoCondition?.length > 0 &&
                                        logoCondition?.map((item, index) => {
                                            return (
                                                <p
                                                    key={index}
                                                    style={{
                                                        color: "red",
                                                        fontSize: "12px",
                                                        marginBottom: 0,
                                                    }}
                                                >
                                                    {item}
                                                </p>
                                            );
                                        })}
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row justify="space-between" align="middle">
                                <Col className="gutter-row" xs={24} sm={8} md={8}>
                                    <LogoUploadFormItem
                                        data={initialValues?.logoSmall_light}
                                        name={"logoSmall_light"}
                                        handlePreview={handlePreview}
                                        uploadOnChange={uploadOnChange}
                                        resetLogo={resetLogo}
                                        disabled={formMode === "view"}
                                    />
                                </Col>
                                <Col className="gutter-row" xs={24} sm={8} md={8}>
                                    <LogoUploadFormItem
                                        data={initialValues?.logoSmall_dark}
                                        name={"logoSmall_dark"}
                                        handlePreview={handlePreview}
                                        uploadOnChange={uploadOnChange}
                                        resetLogo={resetLogo}
                                        disabled={formMode === "view"}
                                    />
                                </Col>
                                <Col md={8}>
                                    {logoSmallCondition &&
                                        logoSmallCondition?.length > 0 &&
                                        logoSmallCondition?.map((item, index) => {
                                            return (
                                                <p
                                                    key={index}
                                                    style={{
                                                        color: "red",
                                                        fontSize: "12px",
                                                        marginBottom: 0,
                                                    }}
                                                >
                                                    {item}
                                                </p>
                                            );
                                        })}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider />
                    <Row gutter={16}>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <SwitchFormItem
                                name={"depositEnabled"}
                                label={t("Deposit Enabled")}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                size="small"
                                disabled={formMode === "view"}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <SwitchFormItem
                                name={"withdrawalEnabled"}
                                label={t("Withdrawal Enabled")}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                size="small"
                                disabled={formMode === "view"}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <InputNumberFormItem
                                name={"minDeposit"}
                                label={"Min Deposit"}
                                placeholder={"Min Deposit"}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                disabled={formMode === "view"}
                                min={0}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <InputNumberFormItem
                                name={"minWithdrawal"}
                                label={"Min Withdrawal"}
                                placeholder={"Min Withdrawal"}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                disabled={formMode === "view"}
                                min={0}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <InputNumberFormItem
                                name={"maxDeposit"}
                                label={"Max Deposit"}
                                placeholder={"Max Deposit"}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                disabled={formMode === "view"}
                                // value={maxDepositValue}
                                min={0}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <InputNumberFormItem
                                name={"maxWithdrawal"}
                                label={"Max Withdrawal"}
                                placeholder={"Max Withdrawal"}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                disabled={formMode === "view"}
                                // value={maxWithdrawalValue}
                                min={0}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <Form.Item label={t("Deposit Description")} labelAlign="left" labelCol={labelCol} colon={false}>
                                <Button block onClick={() => toogleDescriptionModal("deposit")} className="cancelBtn">
                                    {formMode === "view" ? t("View") : t("Edit")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <Form.Item label={t("Withdrawal Description")} labelAlign="left" labelCol={labelCol} colon={false}>
                                <Button block onClick={() => toogleDescriptionModal("withdrawal")} className="cancelBtn">
                                    {formMode === "view" ? t("View") : t("Edit")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* <Row gutter={16}>
            {crytoPspList?.includes(pspSettingName) ? (
              <Col className="gutter-row" xs={24} sm={12} md={12}>
                <InputFormItem
                  name={"cryptoWalletAddress"}
                  label={"Crypto Wallet Address"}
                  placeholder={"Crypto Wallet Address"}
                  layout={horizontalFormLayout}
                  labelCol={labelCol}
                  labelAlign="left"
                  disabled={formMode === "view"}
                />
              </Col>
            ) : null}
          </Row> */}

                    {pspSettingName?.includes("LocalDepo") ? <LocalDepoBankDetailsForm labelCol={labelCol} formMode={formMode} /> : null}

                    {pspSettingName?.includes("BankWire") ? <BankDetailsForm labelCol={labelCol} formMode={formMode} form={form} /> : null}

                    {formMode === "add" && (
                        <>
                            <Row gutter={16}>
                                <Col className="gutter-row" xs={24} sm={12} md={12}>
                                    <SelectFormItem
                                        name={"selectedCurrency"}
                                        label={formPsp === "LocalDepo" ? t("Selected Currency") : t("Selected Currencies")}
                                        options={selectedCurrencyOptions}
                                        placeholder={formPsp === "LocalDepo" ? t("Selected Currency") : t("Selected Currencies")}
                                        mode={formPsp === "LocalDepo" ? "single" : "multiple"}
                                        layout={horizontalFormLayout}
                                        labelCol={labelCol}
                                        labelAlign="left"
                                        onChange={handleSelectedCurChg}
                                        required
                                    />
                                </Col>
                            </Row>
                            <Divider />
                        </>
                    )}
                </Form>
            </FormProvider>
        </div>
    );
};

export default PspSettingForm;
