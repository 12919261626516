import React, {useEffect, useState} from "react";
import {Col, Row, Space, Spin} from "antd";
import ProfileCard from "./component/profileCard";
import {useTranslation} from "react-i18next";
import BindModal from "./component/bindModal";
import SalesOpportunityCard from "./component/salesOpportunityCard";
import InformationCard from "./component/informationCard";
import ActionCard from "./component/actionCard";
import ActivityLogCard from "./component/activityLogCard";
import {getUser} from "../../../../../../api/graphql/user";
import {useGetFollowUp} from "../../../../clients/detail/function/useQueryHooks";


const LeadsDetail_1 = (props) => {
    const {data, profileInformation, permissionUser, leadId} = props
    const {t} = useTranslation()

    const [bindModalIsOpen, setBindModalIsOpen] = useState(false)
    const [loading, setLoading] = useState(true)

    const {data: userList} = getUser({filter: {userType: "client"}})
    const [followUp, fetchFollowUp, refetchFollowUp] = useGetFollowUp()

    const userOptions = userList?.getUser?.data?.map(d => {
        return {
            label: t(`${d?.fullName} - ${d?.username}`),
            value: d?._id
        }
    })

    useEffect(() => {
        if (leadId) fetchFollowUp({followingLeads: leadId})
    }, [leadId]);

    useEffect(() => {
        if (data && profileInformation && permissionUser) {
            setLoading(false)
        }
    }, [data, profileInformation, permissionUser])

    return (
        <div id="leadsDetail">
            <Spin tip="Loading..." spinning={loading}>
                {permissionUser?.leads?.bind?.bind &&
                <BindModal
                    isOpen={bindModalIsOpen}
                    setIsOpen={setBindModalIsOpen}
                    data={data}
                    userOptions={userOptions}
                />
                }
                <Row gutter={[16, 16]}>
                    <Col
                        xs={24} xl={(permissionUser?.leads?.view?.followUp || permissionUser?.leads?.add?.followUp) ? 16 : 24}>
                        <Space direction={"vertical"} style={{width: "100%"}}>
                            <ProfileCard data={data} setBindModalIsOpen={setBindModalIsOpen}
                                         permissionUser={permissionUser}/>
                            {permissionUser?.salesOpportunity?.view &&
                            <SalesOpportunityCard data={data} permissionUser={permissionUser} leadId={leadId}/>}
                            <InformationCard data={data} profileInformation={profileInformation}
                                             permissionUser={permissionUser}/>
                        </Space>
                    </Col>
                    {(permissionUser?.leads?.view?.followUp || permissionUser?.leads?.add?.followUp) &&
                    <Col xs={24} xl={8}>
                        <Space direction={"vertical"} style={{width: "100%"}}>
                            {permissionUser?.leads?.add?.followUp && <ActionCard data={data} refetchFollowUp={refetchFollowUp}/>}
                            {permissionUser?.leads?.view?.followUp &&
                            <ActivityLogCard data={data} permissionUser={permissionUser} followUp={followUp} refetchFollowUp={refetchFollowUp}/>}
                        </Space>
                    </Col>
                    }
                </Row>
            </Spin>
        </div>
    )
}

export default LeadsDetail_1;

