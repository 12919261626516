import React, {useEffect} from "react";
import TableAntDesign from "../../../../../../../components/table/tableAntDesign";
import {useTranslation} from "react-i18next";
import {columnsCategoryCT} from "../../../function/tableColumn_SymbolMappingCategory_CT";
import AddCategoryModal from "./addCategoryModal";
import {Popconfirm, Space, Tooltip} from "antd";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import {useDeleteSymbolMappingCategoryCoreMutation} from "../../../../../../../api/graphql/symbolMappingCategory";
import useAuthorize from "../../../../../../../_common/authorize";

const CategoriesTable = (props) => {
    const {
        data,
        totalCount,
        loading,
        setFilter,
        setLimit,
        limit,
        setOffset,
        setOrderBy,
        mode,
        isOpen,
        close,
        openEditModal,
        selectedItem,
        fullCategoryList,
        currentServer,
        setExportData,
        setExportColumn,
        tableName
    } = props
    const {t} = useTranslation()

    const {deleteSymbolMappingCategoryCore} = useDeleteSymbolMappingCategoryCoreMutation()
    const {systemErrorNotification} = useAuthorize();

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize)
        setOffset(limit * (page - 1))
    }

    const handleDeleteSymbolMappingCategoryCore = (id) => {
        try {
            deleteSymbolMappingCategoryCore(id)
        } catch (error) {
            systemErrorNotification()
        }
    }

    useEffect(() => {
        if (currentServer === "categories" && fullCategoryList?.length > 0) {
            setExportColumn([...columnsCategoryCT])
            setExportData(fullCategoryList)
        }
    }, [fullCategoryList, currentServer])

    return (
        <div className={"margin-top-0-75"}>
            <AddCategoryModal
                mode={mode}
                item={selectedItem}
                category={data}
                isOpen={isOpen}
                close={close}
            />
            <TableAntDesign
                data={data}
                tableName={tableName}
                rowKey={(record) => record?._id}
                columns={[...columnsCategoryCT,
                    {
                        title: t("common.action"),
                        key: "action",
                        width: 132,
                        render: (_, record: any) => (
                            <Space>
                                <Tooltip title={t("common.edit")}>
                                    <a style={{color: "green"}} onClick={() => openEditModal(record)}>
                                        <AiOutlineEdit/>
                                    </a>
                                </Tooltip>
                                <Tooltip title={t("common.delete")}>
                                    <Popconfirm
                                        placement="left"
                                        description={t("Are you sure to delete this category?")}
                                        title={t(`Delete the Symbol Category`)}
                                        onConfirm={() => {
                                            handleDeleteSymbolMappingCategoryCore(record.id);
                                        }}
                                        okText={t("Yes")}
                                        okType="danger"
                                        cancelText={t("No")}
                                    >
                                        <a>
                                            <AiOutlineDelete style={{color: "red"}}/>
                                        </a>
                                    </Popconfirm>
                                </Tooltip>
                            </Space>
                        ),
                    }
                ]}
                size={"small"}
                loading={loading}
                filter={setFilter}
                order={setOrderBy}
                pagination={{
                    pageSize: limit,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                    total: totalCount,
                    showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                }}
            />
        </div>
    )
}

export default CategoriesTable