import React, {useEffect, useState, Fragment, cloneElement} from "react";
import {useTranslation} from "react-i18next";
import {useAccountVerificationFunction} from "../../../../../../function/accountVerificationFunction";
import dayjs from "dayjs";
import {Badge, Breadcrumb, Button, Form, Row, Space} from "antd";
import {userVerificationUploadFileListState} from "../../../../../../../state";
import {useRecoilState} from "recoil";
import ConditionalFieldGroup from "./conditionalFieldGroup";
import Field from "./field";
import {formatDateUTC} from "../../../../../../../../../../function/_common";

const VerificationDocumentFieldList = ({
                                           selectedCountry,
                                           form,
                                           fieldLists,
                                           documentRecord,
                                           verificationRecord,
                                           isDocumentDraft,
                                           selectedDocument,
                                           selectedDocumentGroup,
                                           selectedVerificationType,
                                           handleOnFormFinish,
                                           onUploadDraggerChange,
                                           createNewDocumentRecord,
                                       }) => {
    const {t} = useTranslation();
    const shouldDisableFields = ["draft verification", "pending verification"].includes(documentRecord?.status);
    const [isResubmitting, setIsResubmitting] = useState(false);
    const {getFlatFieldList, DATE_FORMAT} = useAccountVerificationFunction()

    const handleResubmit = async () => {
        setIsResubmitting(true);

        await createNewDocumentRecord({
            groupSetting: selectedDocumentGroup?._id,
            document: selectedDocument?.document?._id,
        });
        setIsResubmitting(false);
    };

    const getFormInitialValues = () => {
        if (!selectedDocument?.fieldList) {
            return {};
        }
        const flatFieldList = getFlatFieldList(selectedDocument?.fieldList);

        const memberIdMap = documentRecord?.fieldValue?.reduce(
            (a, b) =>
                b.field.fieldGroupType !== "multiple"
                    ? a
                    : [
                        ...a,
                        {
                            memberId: b.memberId,
                            value: b.value,
                            valueMobile: b.valueMobile,
                            referenceId: b.referenceId._id,
                            type: b.type,
                        },
                    ],
            []
        );

        const result = flatFieldList.reduce((a, b) => {
            const targetField = documentRecord?.fieldValue?.find(
                (v) =>
                    ["draft verification", "pending verification", "verified"].includes(v.status) &&
                    b?.settingId === v?.fieldSetting?._id &&
                    (!v?.referenceId || b?.referenceId === v.referenceId?._id)
            );

            if (!targetField || targetField?.status === "verification expired") {
                return a;
            }

            let expiredDatePayload;
            if (targetField?.hasExpiredDate && targetField?.expiredDate) {
                expiredDatePayload = {
                    [`${b.label}.expiredDate`]: formatDateUTC(targetField?.value, "formValue"),
                };
            }

            const value = b.type === "date" && targetField?.value ? formatDateUTC(targetField?.value, "formValue") : targetField?.value;

            let payload = {...a, [b.label]: value};

            if (expiredDatePayload) {
                payload = {...payload, ...expiredDatePayload};
            }

            if (b.type === "mobile" && targetField?.valueMobile) {
                payload[b.label] = targetField?.valueMobile?.mobileNo;
                payload[`${b.label}.mobile`] = targetField?.valueMobile?.countryCode;
            }

            const targetMembers = memberIdMap?.filter((d) => d.referenceId === b?.referenceId);

            const fieldType = flatFieldList?.find((d) => d?.referenceId === b?.referenceId)?.type;

            if (targetMembers?.length) {
                targetMembers.forEach((targetMember) => {
                    payload[`${b.label}___${targetMember.memberId}`] =
                        fieldType === "date" && targetMember?.value ? formatDateUTC(targetMember?.value, "formValue") : targetMember?.value;

                    if (b.type === "mobile" && targetMember?.valueMobile) {
                        payload[`${b.label}___${targetMember.memberId}`] = targetMember?.valueMobile?.mobileNo;
                        payload[`${b.label}___${targetMember.memberId}.mobile`] = targetMember?.valueMobile?.countryCode;
                    }
                });
            }

            return payload;
        }, {});

        return result;
    };

    useEffect(() => {
        const formInitialValues = getFormInitialValues();
        if (formInitialValues && Object.keys(formInitialValues)?.length) {
            form.setFieldsValue(formInitialValues);
        } else {
            form.resetFields();
        }
    }, [form]);

    return (
        <div className="verification-sub-card">
            <Space style={{width: "100%"}} size={16} direction="vertical">
                <Row justify={"space-between"}>
                    <div className="account-verification-title">
                        <Breadcrumb
                            itemRender={(a:any, b, c:any) => {
                                return a.index === c.length - 1 ? (
                                    <span className="ant-breadcrumb-link">
                                        {t(a.title)}{" "}
                                        {documentRecord?.status && !["start", "cancelled"].includes(documentRecord?.status) ? (
                                            <Badge
                                                style={{marginBottom: "3px", marginLeft: "3px"}}
                                                // color={documentRecord?.status === "verification failed" ? "error" : "default"}
                                                className={documentRecord?.status === "verification failed" ? "error" : "default"}
                                                count={t(`accountVerification.status.${documentRecord?.status?.replace(" ", "_")}`)}
                                            />
                                        ) : null}
                                    </span>
                                ) : (
                                    <span className="ant-breadcrumb-link">{t(a.title)}</span>
                                );
                            }}
                            items={[
                                {title: t(selectedCountry)},
                                {title: t(selectedVerificationType?.label)},
                                {title: t(selectedDocumentGroup?.group?.label)},
                                {title: t(selectedDocument?.document?.label)},
                            ].map((d, index) => ({...d, index}))}
                            separator={">"}
                        />
                    </div>
                    {isDocumentDraft ? (
                        <Button
                            className="secondary-small-button"
                            onClick={() => {
                                handleResubmit();
                            }}
                            loading={isResubmitting}
                            disabled={isResubmitting}
                        >
                            {t("accountVerification.resubmitDocument")}
                        </Button>
                    ) : null}
                </Row>
                <Form
                    form={form}
                    name={selectedDocument?.document?.label}
                    layout="vertical"
                    key={selectedDocument?.document?.label}
                    onFinish={handleOnFormFinish}
                    className="test"
                >
                    <Space style={{width: "100%"}} size={16} direction="vertical">
                        {[...(fieldLists || [])]
                            ?.sort((a, b) => a.sortingNumber - b.sortingNumber)
                            .map(({name, ...fieldList}, i) => (
                                <ConditionalFieldGroup
                                    fieldGroupType={fieldList.field.fieldGroupType}
                                    isDraft={shouldDisableFields}
                                    documentRecord={documentRecord}
                                >
                                    <Field
                                        form={form}
                                        key={fieldList._id}
                                        field={fieldList.field}
                                        linkField={fieldList?.linkField}
                                        {...fieldList}
                                        onUploadDraggerChange={onUploadDraggerChange}
                                        isDraft={shouldDisableFields}
                                        documentRecord={documentRecord}
                                        selectedCountry={selectedCountry}
                                    />
                                </ConditionalFieldGroup>
                            ))}
                    </Space>
                </Form>
            </Space>
        </div>
    );
}

export default VerificationDocumentFieldList