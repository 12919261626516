import React from "react";
import IconList_1 from "./view/layout1";
import {getIconList} from "../../../api/graphql/iconList";
import {useRecoilValue} from "recoil";
import {iconTypeState} from "../../../components/sider/state";

const IconList = () => {
    let layout = 1;
    let component;

    const iconType = useRecoilValue(iconTypeState)
    const {data, loading} = getIconList({type: iconType},null,null,"name_ASC")

    switch (layout) {
        case 1:
            component = <IconList_1 data={data?.getIconList?.data} loading={loading}/>
            break;

        default:
            component = <IconList_1 data={data?.getIconList?.data} loading={loading}/>;
            break;
    }

    return component;
}

export default IconList