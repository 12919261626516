import React from "react";
import {Col, Collapse, InputNumber, Radio, Row, Select} from "antd";
import {useContextMenuFunctions} from "../../../function/contextMenuFunctions";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";

const LegendMenuItems = ({setDetailData, setting, getContainer}) => {
    const {handleFormInputValueChange} = useContextMenuFunctions(setDetailData)
    const options = [
        {label: "1", value: 24},
        {label: "2", value: 12},
        {label: "3", value: 8},
        {label: "4", value: 6},
    ]

    return (
        <>
            <div className={"sections"}>
                <p>Grid Layout</p>
                <Select
                    getPopupContainer={getContainer}
                    options={[
                        {label: "Fixed", value: "fixed"},
                        {label: "Responsive", value: "responsive"},
                    ]}
                    onChange={value => handleFormInputValueChange(value, "layout")}
                    value={setting?.layout}
                />

                <p className={"margin-top-0-75"}>Items per row</p>
                {
                    setting?.layout === "fixed" ?
                        <Select
                            getPopupContainer={getContainer}
                            onChange={value => handleFormInputValueChange(value, "span")}
                            value={setting?.span}
                            options={options}
                        /> :
                        <Row gutter={8}>
                            <Col span={8}>
                                <Select
                                    getPopupContainer={getContainer}
                                    placeholder={"sm"}
                                    value={setting?.sm}
                                    options={options}
                                    onChange={value => handleFormInputValueChange(value, "sm")}
                                />
                            </Col>
                            <Col span={8}>
                                <Select
                                    getPopupContainer={getContainer}
                                    placeholder={"md"}
                                    value={setting?.md}
                                    options={options}
                                    onChange={value => handleFormInputValueChange(value, "md")}
                                />
                            </Col>
                            <Col span={8}>
                                <Select
                                    getPopupContainer={getContainer}
                                    placeholder={"lg"}
                                    value={setting?.lg}
                                    options={options}
                                    onChange={value => handleFormInputValueChange(value, "lg")}
                                />
                            </Col>
                        </Row>
                }
            </div>
            <div className={"sections"}>
                <Collapse ghost accordion>
                    <CollapsePanel key={"color"} header={"Border"}>
                        <p>Border Color</p>
                        <Select options={[]} className={"w-100"} getPopupContainer={getContainer}/>

                        <p className={"margin-top-0-75"}>Border Width</p>
                        <InputNumber className={"w-100"} addonAfter={"px"} value={setting?.borderWidth}
                                     onChange={value => handleFormInputValueChange(value, "borderWidth")} controls={false}/>

                        <p className={"margin-top-0-75"}>Border Radius</p>
                        <InputNumber className={"w-100"} addonAfter={"px"} value={setting?.borderRadius}
                                     onChange={value => handleFormInputValueChange(value, "borderRadius")} controls={false}/>
                    </CollapsePanel>
                </Collapse>
            </div>
            <div className={"sections"}>
                <Collapse ghost accordion>
                    <CollapsePanel key={"color"} header={"Colors"}>
                        <p>Background Color</p>
                        <Select options={[]} className={"w-100"} getPopupContainer={getContainer}/>

                        <p className={"margin-top-0-75"}>Text Color</p>
                        <Select options={[]} className={"w-100"} getPopupContainer={getContainer}/>
                    </CollapsePanel>
                </Collapse>
            </div>
        </>
    )
}

export default LegendMenuItems