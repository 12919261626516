import React from "react";
import {Col, Row} from "antd";
import {useTranslation} from "react-i18next";
import {renderProfileInformation} from "../../../../../../_common/function";

const ResidenceInformation = (props) => {
    const {data, editable, residenceField, isReadSensitive} = props
    const {t} = useTranslation()

    return (
        data &&
        <Row gutter={[16, 16]} className={"margin-top-0-75 margin-bottom-0-75"}>
            {residenceField?.length > 0 && (
                <Col span={24}>
                    <h3>{t("Residence Information")}</h3>
                    <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                        {renderProfileInformation(residenceField, data, t, !editable, isReadSensitive)}
                    </Row>
                </Col>
            )}
        </Row>
    )
}

export default ResidenceInformation