import React from "react";
import {useTranslation} from "react-i18next";
import {Breadcrumb, Card, Space, Tooltip} from "antd";
import {useAccountVerificationFunction} from "../../../../../../function/accountVerificationFunction";
import OptionalRibbonOnCard from "./optionalRibbonOnCard";

const SelectDocument = ({
                            requiredType,
                            numberOfRequired,
                            partiallyRequiredGroup,
                            selectedCountry,
                            documents,
                            verificationRecord,
                            handleSelectDocument,
                            selectedDocument,
                            selectedDocumentGroup,
                            selectedVerificationType,
                        }) => {
    const {t} = useTranslation();
    const {RequiredTypeToMessage, getDocumentStatus, statusToRibbonText} = useAccountVerificationFunction()

    const sortedDocuments = [...documents].sort((a, b) => a.sortingNumber - b.sortingNumber);

    return (
        <Card className="narrow-padding">
            <Space style={{width: "100%"}} size={16} direction="vertical">
                <div className="account-verification-title">
                    <Breadcrumb
                        items={[
                            {title: t(selectedCountry)},
                            {title: t(selectedVerificationType?.label)},
                            {
                                title: ` ${t(selectedDocumentGroup?.group?.label)} (${RequiredTypeToMessage(requiredType, numberOfRequired)})`,
                            },
                        ]}
                    />
                    {/* <RequiredFieldTooltip
            msg={RequiredTypeToMessage(requiredType, numberOfRequired)}
          /> */}
                </div>
                {sortedDocuments.map((document, i, documents) => {
                    const status = getDocumentStatus(document?.document?._id, selectedDocumentGroup, verificationRecord);

                    const color = status === "verification failed" ? "red" : "default";
                    return (
                        <OptionalRibbonOnCard key={document?._id} showRibbon={status} color={color} text={statusToRibbonText(status)}>
                            <Card
                                className={`account-verification-card-selection ${
                                    selectedDocument?._id === document?._id ? "account-verification-card-selected" : ""
                                }`}
                                onClick={() => {
                                    handleSelectDocument(document);
                                }}
                            >
                                <div className="account-verification-card-selection-title">
                                    {requiredType === "all" ||
                                    (requiredType === "partially" && partiallyRequiredGroup?.includes(selectedDocumentGroup?.group?._id)) ? (
                                        <Tooltip title={"This section is required"}>
                                            <span className="account-verification-required-mark">*</span>
                                        </Tooltip>
                                    ) : null}
                                    {t(document?.document?.label)}
                                </div>
                            </Card>
                        </OptionalRibbonOnCard>
                    );
                })}
            </Space>
        </Card>
    );
}

export default SelectDocument