import React from "react";
import {Button, Col, Image, Row, Space} from "antd";
import {formatMoney} from "../../../../../../../accounts/tradingAccount/detail/function";
import {getPspSetting} from "../../../../../../../../api/graphql/pspSetting";
import {LinkOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {formatDateTimeTimezone} from "../../../../../../../../function/_common";

const EWalletDeposit = ({record}) => {
    const {t} = useTranslation()
    const task = record?.task
    const pspCurrency = task?.result?.currency
    const { data: pspSettingData } =  getPspSetting({ filter: {name: task?.result?.psp, taskView: true} });
    
    return (
        <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
                <h3 className={"margin-top-0-75"}>{t("clientUser.ewallet.depositInfo")}</h3>
                <Row gutter={{xs: 4, md: 16}} align={"bottom"}>
                    <Col xs={24} md={14} className={"margin-top-0-75"}>{t("task.status")}</Col>
                    <Col xs={24} md={10}>{task?.status}</Col>

                    <Col xs={24} md={14} className={"margin-top-0-75"}>{t("task.transactionNo")}</Col>
                    <Col xs={24} md={10}>{task?.result?.transactionNo}</Col>

                    <Col xs={24} md={14} className={"margin-top-0-75"}>{t("task.bank_in_date")}</Col>
                    {/*<Col xs={24} md={10}>{moment(task?.result?.bankInDate)?.format("YYYY-MM-DD HH:mm:ss")}</Col>*/}
                    <Col xs={24} md={10}>{formatDateTimeTimezone(task?.result?.bankInDate)}</Col>

                    <Col xs={24} md={14} className={"margin-top-0-75"}>{t("task.display_name")}</Col>
                    <Col xs={24} md={10}>{pspSettingData?.getPspSetting?.data[0]?.displayName}</Col>

                    <Col xs={24} md={14} className={"margin-top-0-75"}>{t("task.payment_method")}</Col>
                    <Col xs={24} md={10}>{task?.result?.paymentMethod}</Col>

                    {
                        task?.result.formType === "crypto" && (
                            <>
                                <Col xs={24} md={14} className={"margin-top-0-75"}>{t("task.transaction_hash")}</Col>
                                <Col xs={24} md={10}>{task?.payload?.input?.txnHash}</Col>
                            </>
                        )}

                    <Col xs={24} md={14} className={"margin-top-0-75"}>{t("task.currency")}</Col>
                    <Col xs={24} md={10}>{task?.result?.currency}</Col>

                    {/*<Col xs={24} md={14}>Amount paid by Client ({mode === "verify"? record?.payload?.input?.currency : record?.task?.result?.currency ?  record?.task?.result?.currency : record?.task?.payload?.input?.currency}):</Col>*/}
                    <Col xs={24} md={14} className={"margin-top-0-75"}>{t("task.amount_paid_by_client")} ({pspCurrency})</Col>
                    <Col xs={24} md={10}>{formatMoney(task?.result?.convertedActualAmount || 0)}</Col>

                    {/*<Col xs={24} md={14}>Fee Charged on Client ({mode === "verify"? pspCurrency : item?.result?.currency ?  item?.result?.currency : task?.result.currency}):</Col>*/}
                    <Col xs={24} md={14} className={"margin-top-0-75"}>{t("task.fee_charged_on_client")} ({pspCurrency})</Col>
                    <Col xs={24} md={10}>{formatMoney(task?.result?.convertedFeeClient || 0)}</Col>

                    {/*<Col xs={24} md={14}>Net Amount ({mode === "verify"? pspCurrency : item?.result?.currency ?  item?.result?.currency : task?.result.currency}):</Col>*/}
                    <Col xs={24} md={14} className={"margin-top-0-75"}>{t("task.net_amount")} ({pspCurrency})</Col>
                    <Col xs={24} md={10}>{formatMoney(task?.result?.convertedAmount || 0)}</Col>

                    <Col xs={24} md={14} className={"margin-top-0-75"}>{t("task.psp_fee")} ({pspCurrency})</Col>
                    <Col xs={24} md={10}>{formatMoney(task?.result?.convertedFeeCompany || 0)}</Col>

                    <Col xs={24} md={14} className={"margin-top-0-75"}>{t("task.rate")}</Col>
                    <Col xs={24} md={10}>{task?.result?.rate}</Col>

                    <Col xs={24} md={14} className={"margin-top-0-75"}>{t("task.amount_paid_by_client_usd")}</Col>
                    <Col xs={24} md={10}>{formatMoney(task?.result?.actualAmount || 0)}</Col>

                    <Col xs={24} md={14} className={"margin-top-0-75"}>{t("task.amount_charged_by_client_usd")}</Col>
                    <Col xs={24} md={10}>{formatMoney(task?.result?.feeClient || 0)}</Col>

                    <Col xs={24} md={14} className={"margin-top-0-75"}>{t("task.ewallet_deposit_amount_usd")}</Col>
                    <Col xs={24} md={10}>{formatMoney(task?.result?.amount || 0)}</Col>

                    <Col xs={24} md={14} className={"margin-top-0-75"}>{t("task.psp_fee_usd")}</Col>
                    <Col xs={24} md={10}>{formatMoney(task?.result?.feeCompany || 0)}</Col>

                    <Col xs={24} md={14} className={"margin-top-0-75"}>{t("task.remark")}</Col>
                    <Col xs={24} md={10}>{task?.result?.remark}</Col>

                    <Col xs={24} md={14} className={"margin-top-0-75"}>{t("task.comment")}</Col>
                    <Col xs={24} md={10}>{task?.result?.comment || task?.comment}</Col>

                    <Col xs={24} md={14} className={"margin-top-0-75"}>{t("task.requested_by")}</Col>
                    <Col xs={24} md={10}>{task?.requestedBy?.username}</Col>
                </Row>
            </Col>
            <Col xs={24} md={12}>
                <h3 className={"margin-top-0-75"}>{t("clientUser.ewallet.uploadInformation")}</h3>
                <Row gutter={[16, 16]}>
                    {/*<Col xs={24} md={14} className="margin-top-0-75>Admin Ref No.</Col>*/}
                    {/*<Col xs={24} md={10}></Col>*/}

                    {/*<Col xs={24} md={14} className="margin-top-0-75>Admin Remark</Col>*/}
                    {/*<Col xs={24} md={10}></Col>*/}

                    <Col xs={24}>{t("task.bank_slip")}</Col>
                    <Col xs={24}>
                        <Space>
                        <Image width={200} height={100} style={{objectFit: "cover"}} src={task?.result?.bankInSlip}/>
                            {
                                task?.result?.bankInSlip &&
                                <Button
                                    type={"link"}
                                    icon={<LinkOutlined/>}
                                    onClick={() => window.open(task?.result?.bankInSlip, "_blank")}
                                />
                            }
                        </Space>
                    </Col>
                </Row>
            </Col>
        </Row>
    )

}

export default EWalletDeposit