import {Button, Form} from 'antd';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useFormContext} from 'react-hook-form';
import FormErrors from './formErrors';
import {PlusOutlined, UploadOutlined} from '@ant-design/icons';
import {Modal, Upload} from 'antd';
import {getConfigByName} from "../../../config";
import {BRAND_NAME} from "../../../config/brandVariable";

const UploadItem = (props) => {
    const {
        name,
        onChange,
        listType,
        maxLength,
        fileList,
        onRemove,
        disabled,
        mode,
        condition,
        showUploadList,
    } = props

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileField, setFileField] = useState([]);

    const getBase64 = (file): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
        if (!file?.response[0]?.link && !file?.preview) {
            file.preview = await getBase64(file?.thumbUrl);
        }
        setPreviewImage(file?.response[0]?.link || (file?.preview));
        setPreviewOpen(true);
        setPreviewTitle(file?.name || file?.response[0]?.link!.substring(file?.response[0]?.link!.lastIndexOf('/') + 1));
    };

    const pictureUploadButton = (
        <div>
            <div className='upload-button-1'><PlusOutlined/> Upload ( Max: {maxLength} )</div>
        </div>
    );

    const pictureCardUploadButton = (
        <div>
            <PlusOutlined/>
            <div style={{marginTop: 8}}>Upload</div>
        </div>
    );


    const textUploadButton = (
        <Button icon={<UploadOutlined/>}>Click to Upload</Button>
    )
    const brandConfig = getConfigByName(BRAND_NAME)

    const getUploadLink = (mode) => {
        switch(mode){
            case "activities":
                return `${brandConfig?.backendURL}/api/upload/activities/image`
            case "image":
                return `${brandConfig?.backendURL}/api/upload/profile/image`
            case "dmg":
                return `${brandConfig?.backendURL}/api/upload/platform/dmg`
            case "exe":
                return `${brandConfig?.backendURL}/api/upload/platform/exe`
            case "apk":
                return `${brandConfig?.backendURL}/api/upload/platform/apk`
            case "pdf":
                return `${brandConfig?.backendURL}/api/upload/document/pdf`
            case "ewallet":
                return `${brandConfig?.backendURL}/api/upload/ewallet/image`
        }
    }

    return (
        <>
            <Upload
                id={name}
                action={getUploadLink(mode)}
                listType={listType}
                fileList={fileList || fileField}
                onChange={(e: any) => {
                    setFileField(e.fileList)
                    onChange && onChange(e)
                }}
                onPreview={handlePreview}
                maxCount={1}
                onRemove={(e) => {
                    onRemove && onRemove(e)
                }}
                disabled={disabled}
                showUploadList={showUploadList}
            >
                {listType === "picture" && pictureUploadButton}
                {listType === "picture-card" && pictureCardUploadButton}
                {listType === "text" && textUploadButton}
            </Upload>
            {condition && condition.length > 0 && (
                condition.map(item => {
                    return (<p style={{color:"black", fontSize: "12px", maxWidth:"160px"}}>{item}</p>)
                })
            )}
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="placeholder" style={{width: '100%'}} src={previewImage}/>
            </Modal>
        </>
    )
}

UploadItem.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    listType: PropTypes.string,
    maxLength: PropTypes.number,
    fileList: PropTypes.array,
    onRemove: PropTypes.func,
    mode: PropTypes.string,
    condition: PropTypes.array,
    showUploadList: PropTypes.bool,
    disabled: PropTypes.bool
}

export default UploadItem