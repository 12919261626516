export const accountType = [
    {
        label: "Self-Trading",
        value: "self-trading"
    },
    {
        label: "Follower",
        value: "follower"
    },
    {
        label: "Investor",
        value: "investor"
    },
    {
        label: "All",
        value: "all"
    },
]

export const commType = [
    {
        label: "Rank",
        value: "rank"
    },
    {
        label: "Level",
        value: "level"
    },
]

export const commMode = [
    {
        label: "Fixed $",
        value: "Fixed $"
    },
    {
        label: "Value x %",
        value: "Value x %"
    },
    {
        label: "Value x $",
        value: "Value x $"
    },
    // {
    //     label:"Pip",
    //     value:"pip"
    // },
    // {
    //     label:"$ + pip",
    //     value:"$ + pip"
    // },
]

export const commModeSpreadEarning = [
    {
        label: "%",
        value: "%"
    }
]


export const commPayout = [
    {
        label: "Daily",
        value: "daily"
    },
    {
        label: "Weekly",
        value: "weekly"
    },
    {
        label: "Monthly",
        value: "monthly"
    },
    {
        label: "Weekly + Month End",
        value: "weekly + month end"
    },
]

export const calcFrequency = [
    {
        label: "Daily",
        value: "daily"
    },
    {
        label: "Weekly",
        value: "weekly"
    },
    {
        label: "Monthly",
        value: "monthly"
    },
    {
        label: "Weekly + Month End",
        value: "weekly + month end"
    },
]

export const unpaidComm = [
    {
        label: "Compress",
        value: "compress"
    },
    {
        label: "Void",
        value: "void"
    },
]

export const dealEntry = [
    {
        label: "All",
        value: "all"
    },
    {
        label: "In",
        value: "in"
    },
    {
        label: "Out",
        value: "out"
    },
]

export const dealAction = [
    {
        label: "All",
        value: "all"
    },
    {
        label: "Buy",
        value: "buy"
    },
    {
        label: "Sell",
        value: "sell"
    },
]

export const dealProfit = [
    {
        label: "All",
        value: "all"
    },
    {
        label: "Profit",
        value: "profit"
    },
    {
        label: "Loss",
        value: "loss"
    },
]

export const calculationNetworkType = [
    {
        label: "Referrer Network",
        value: "referrer network"
    },
    {
        label: "Sales Rep Network",
        value: "sales rep network"
    },
]

export const calculationSymbolType = [
    {
        label: "All Symbols",
        value: "all"
    },
    {
        label: "Some Symbols",
        value: "some"
    },
]

export const commBase = [
    {
        label: "per Lot",
        value: "lot"
    },
    {
        label: "%",
        value: "%"
    },
]

export const commBaseSpreadEarning = [
    {
        label: "per Lot",
        value: "lot"
    },
]

export const accEnvMode = [
    {
        label: "Live",
        value: "live"
    },
    {
        label: "Demo",
        value: "demo"
    },
    {
        label: "All",
        value: "all"
    },
]

export const payoutNetworkType = [
    {
        label: "All Users",
        value: "all"
    },
    {
        label: "Some Users",
        value: "partial"
    },
    {
        label: "Only Me",
        value: "only me"
    },
]

export const productsType = [
    {
        label: "All Products",
        value: "all"
    },
    {
        label: "Some Products",
        value: "some"
    },
]

export const coEfficientType = [
    {
        label: "Calculation Include Bonus coefficient",
        value: true
    },
    {
        label: "Calculation Ignore Bonus coefficient",
        value: false
    },
]

export const commRanksType = [
    {
        label: "All Rank",
        value: "all"
    },
    {
        label: "Some Rank",
        value: "some"
    },
]