import React, {useEffect, useState} from "react";
import {useRecoilState} from "recoil";
import {Button, Col, List, Row, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    LockOutlined,
    MinusOutlined,
    PlusOutlined,
    UnlockOutlined,
} from "@ant-design/icons";
import {useUpdateWebModuleSettingMutation} from "../../../../../../api/graphql/webModuleSetting";
import {selfPermissionObj} from "../../../../../../../recoil_state";
import useAuthorize from "../../../../../../_common/authorize";

const ModuleWebNavigation = (props) => {
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const webModuleSettingPermission = selfPermission?.brandSetting?.moduleSettings;
    const [activeModule, setActiveModule] = useState<any>([]);
    const [availableModule, setAvailableModule] = useState<any>([]);

    const {t} = useTranslation();
    const {item, module} = props;
    const limit = 5;

    const {updateWebModuleSetting} = useUpdateWebModuleSettingMutation();
    const {systemErrorNotification} = useAuthorize()

    const updateModuleSettingFunction = async (input: any) => {
        try {
            await updateWebModuleSetting(input);
        } catch (e) {
            systemErrorNotification()
        }
    };

    const restructureModuleFunction = async (items) => {
      try{
          const ActiveModule: any = [];
          await items?.map((item) => {
              if (item?.moduleId?.isEnabled === true && item?.position !== null) {
                  ActiveModule.push(item);
              }
          });

          const sortedActiveModule = ActiveModule.sort((a, b) => a.position - b.position);
          const availableModule = module?.filter((mod) => {
              const found = sortedActiveModule.find((s) => mod.name === s.moduleId.name);
              return !found && mod?.isEnabled;
          });

          setAvailableModule(availableModule);
          setActiveModule(sortedActiveModule);
      }catch (e) {
          systemErrorNotification()
      }
    };

    useEffect(() => {
        restructureModuleFunction(item);
    }, [item, module]);

    const isEditable = webModuleSettingPermission?.edit?.edit;

    return (
        <Row gutter={[16, 16]}>
            <Col span={12} style={{marginTop: "15px"}}>
                <List
                    size="small"
                    header={
                        <h4 className={"margin-bottom-0"}>
                            Navigation Item ({activeModule.length}/{limit})
                        </h4>
                    }
                    bordered
                    dataSource={activeModule}
                    renderItem={(item: any, index) => {
                        return (
                            <List.Item
                                actions={[
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            updateModuleSettingFunction({
                                                isLocked: item.isLocked !== true || false,
                                                position: item.position,
                                                moduleId: item?.moduleId?._id,
                                            });
                                        }}
                                        disabled={!isEditable}
                                        icon={item.isLocked === false ? <UnlockOutlined/> : <LockOutlined/>}
                                    />,
                                    <>
                                        {item.position > 1 ? (
                                            <Button
                                                onClick={() => {
                                                    updateModuleSettingFunction({
                                                        isLocked: item.isLocked,
                                                        position: item.position - 1,
                                                        moduleId: item?.moduleId?._id,
                                                    });
                                                }}
                                                icon={<ArrowUpOutlined/>}
                                                disabled={!isEditable}
                                            />
                                        ) : (
                                            <Button icon={<ArrowUpOutlined/>} type="primary" disabled/>
                                        )}
                                    </>,
                                    <>
                                        {index + 1 !== activeModule.length ? (
                                            <Button
                                                onClick={() => {
                                                    updateModuleSettingFunction({
                                                        isLocked: item.isLocked,
                                                        position: item.position + 1,
                                                        moduleId: item?.moduleId?._id,
                                                    });
                                                }}
                                                icon={<ArrowDownOutlined/>}
                                                disabled={!isEditable}
                                            />
                                        ) : (
                                            <Button icon={<ArrowDownOutlined/>} type="primary" disabled/>
                                        )}
                                    </>,
                                    <Button
                                        danger
                                        onClick={() => {
                                            const position = item?.position;
                                            updateModuleSettingFunction({
                                                moduleId: item?.moduleId?._id,
                                                isLocked: false,
                                                position: null,
                                            });
                                        }}
                                        icon={<MinusOutlined/>}
                                        disabled={!isEditable}
                                    />,
                                ]}
                            >
                                {item.moduleId.name}
                            </List.Item>
                        );
                    }}
                />
            </Col>
            <Col span={12} style={{marginTop: "15px"}}>
                <List
                    size="small"
                    header={<h4 className={"margin-bottom-0"}>Items For Selection</h4>}
                    bordered
                    pagination={{
                        pageSize: 10,
                        position: "bottom",
                        showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                    }}
                    dataSource={availableModule}
                    renderItem={(item: any, index) => {
                        if (item.isEnabled === true) {
                            return (
                                <List.Item
                                    actions={[
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                updateModuleSettingFunction({
                                                    moduleId: item._id,
                                                    isLocked: false,
                                                    position: activeModule.length + 1,
                                                });
                                            }}
                                            icon={<PlusOutlined/>}
                                            disabled={!isEditable}
                                        />,
                                    ]}
                                >
                                    {item.name}
                                </List.Item>
                            );
                        }
                    }}
                />
            </Col>
        </Row>
    );
};

export default ModuleWebNavigation;
