import React from "react";
import {useRecoilValue} from "recoil";
import {selfPermissionObj} from "../../../../recoil_state";
import {getLanguage} from "../../../api/graphql/language";
import PromoMaterial_1 from "./view/layout1";
import "./css/index.css"

function ProductGroup() {
    let layout = 1;
    let component;
    const permission = useRecoilValue<any>(selfPermissionObj);
    const {languageData} = getLanguage({
        filter: null,
    });

    switch (layout) {
        case 1:
            component = <PromoMaterial_1 permission={permission?.activities?.promoMaterial} languageData={languageData?.getLanguage?.data} />;
            break;

        default:
            component = <PromoMaterial_1 permission={permission?.activities?.promoMaterial} languageData={languageData?.getLanguage?.data} />;
            break;
    }

    return component;
}

export default ProductGroup;
