const switchFieldTypeTagColor = (fieldType) => {
    switch (fieldType) {
        case "text":
            return "blue"
        case "file":
            return "geekblue"
        case "video":
            return "purple"
        case "image":
            return "cyan"
        case "textArea":
            return "green"
        case "date":
            return "lime"
        case "select":
            return "gold"
        case "multiSelect":
            return "orange"
        case "radio":
            return "volcano"
        case "rate":
            return "red"
        case "group":
            return "default"
        default:
            return "default"
    }
}

const validateMessages = {
    required: '${label} is a required field!',
};

const labelCol = {span: 24, offset: 0}

export {switchFieldTypeTagColor, validateMessages, labelCol}