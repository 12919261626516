import React, {useCallback} from "react";
import {Button, Card, Checkbox, Col, DatePicker, Form, Input, Radio, Row, Select, Space, Upload} from "antd";
import {useTranslation} from "react-i18next";

const RegistrationPreview = (props) => {
    const {fields} = props
    const {
        basicField,
        loginField,
        residenceField,
        additionalField,
        corporateField,
        registrationField,
        demoField
    } = fields;
    const {t} = useTranslation();
    const ContainerHeight = "70vh";

    const registrationFieldList = registrationField?.length > 0 && (
        registrationField?.map(item => {
            return item?.fieldList
        })
    )

    const renderField = useCallback((item) => {
        const field = item?.fieldList;
        const fieldTypeComponents = {
            password: (
                <Input.Password disabled/>
            ),
            text: (
                <Input disabled/>
            ),
            file: (
                <Upload disabled>
                    <Button disabled>{t("Upload")}</Button>
                </Upload>
            ),
            date: (
                <DatePicker disabled style={{width: "100%"}}/>
            ),
            dropdown: (
                <Select disabled/>
            ),
            textarea: (
                <Input.TextArea disabled/>
            ),
            radioButton: (
                <Radio.Group disabled>
                    {field?.optionValue?.map((option, idx) => (
                        <Radio.Button key={option?.value} id={idx} value={option?.value}>
                            {option?.label}
                        </Radio.Button>
                    ))}
                </Radio.Group>
            ),
            checkbox: (
                <Checkbox disabled/>
            ),
            checkboxGroup: (
                <Checkbox.Group options={field?.optionValue} disabled/>
            ),
            mobile: (
                <Space.Compact style={{width: "100%"}}>
                    <Select
                        options={field?.optionValue} disabled
                        style={{width: 100}}
                    />
                    <Input disabled/>
                </Space.Compact>
            )
        };

        return (
            <>
                <Col span={12} key={item?._id}>
                    <Form.Item
                        name={field?.fieldName + item?.mode + item?.platform + Date.now()}
                        label={t(field?.label)}
                        required={field?.isRequired}
                    >
                        {fieldTypeComponents[field?.fieldType]}
                    </Form.Item>
                </Col>
                { field?.isConfirmRequired &&
                    <Col span={12}>
                    <Form.Item
                        name={`${field?.fieldName + item?.mode + item?.platform + Date.now()}_confirm`}
                        label={`${t("user.confirmField", {label: t(field?.label)})}`}
                        required={field?.isRequired}
                    >
                        {fieldTypeComponents[field?.fieldType]}
                    </Form.Item>
                </Col>}
            </>
        );
    }, [fields]);

    return (
        <div id={"registration-preview"}>
            <Row style={{marginBottom: "30px"}}>
                <Col flex={"auto"}>
                    <h4>User Preview</h4>
                </Col>
            </Row>
            <Card style={{height: ContainerHeight, overflow: "auto", padding: 16}}>
                <Form layout="vertical">
                    {basicField?.length > 0 && (
                        <>
                            <h4>{t("field.category.basicInformation")}</h4>
                            <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                                {basicField?.map((item) => renderField(item))}
                            </Row>
                        </>
                    )}
                    {loginField?.length > 0 && (
                        <>
                            <h4>{t("field.category.loginInformation")}</h4>
                            <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                                {loginField?.map((item) => renderField(item))}
                            </Row>
                        </>
                    )}
                    {residenceField?.length > 0 && (
                        <>
                            <h4>{t("Residence Information")}</h4>
                            <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                                {residenceField?.map((item) => renderField(item))}
                            </Row>
                        </>
                    )}
                    {additionalField?.length > 0 && (
                        <>
                            <h4>{t("field.category.additionalInformation")}</h4>
                            <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                                {additionalField?.map((item) => renderField(item))}
                            </Row>
                        </>
                    )}
                    {corporateField?.length > 0 && (
                        <>
                            <h4>{t("Corporate Information")}</h4>
                            <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                                {corporateField?.map((item) => renderField(item))}
                            </Row>
                        </>
                    )}
                    {demoField?.length > 0 && (
                        <>
                            <h4>{t("Demo Account")}</h4>
                            <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                                {demoField?.map((item) => renderField(item))}
                            </Row>
                        </>
                    )}
                    <Row justify={"center"}>
                        {
                            registrationFieldList?.length > 0 && registrationFieldList?.find(item => item?.label === "user.checkTermAndCondition") && (
                                <Checkbox disabled>{t("Terms and Condition")}</Checkbox>
                            )
                        }
                    </Row>
                </Form>
            </Card>
        </div>
    );
};
export default RegistrationPreview