import React from "react";
import {Space, Tag} from "antd";
import {getColumnSearchProps} from "../../../../components/table/function/filterTable";
import TranslationText from "../../../../components/table/component/translationText";

export const columns = [
    {
        title: "Parent",
        dataIndex: "parent",
        key: "parent",
        defaultSortOrder: "ascend",
        ...getColumnSearchProps(),
        render: (parent) => <p>{parent?.name}</p>,
    },
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        ...getColumnSearchProps(),
        render: (text, record) => (
            <span>
                {text}
                {record?.isDefaultCrmUserPermission && (
                    <Tag color="blue" className="margin-left-0-5">
                        <TranslationText languageKey="common.default" />
                    </Tag>
                )}
            </span>
        ),
    },
    {
        title: "Status",
        dataIndex: "isActive",
        key: "isActive",
        ...getColumnSearchProps(),
        render: (text) => {
            const displayText = text ? "Active" : "Inactive";
            return <p>{displayText}</p>;
        },
    },
    {
        title: "User Count",
        dataIndex: "userCount",
        key: "userCount",
        ...getColumnSearchProps(),
        render: (text, record) => {
            return <a onClick={() => window.open(`/users/crm-user?role=${record?.id}`)}>{text}</a>;
        },
    },
    {
        title: "Network Type",
        dataIndex: "networkType",
        key: "networkType",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Network Include",
        dataIndex: "networkInclude",
        key: "networkInclude",
        width: 250,
        ...getColumnSearchProps(),
        render: (_, record) => (
            <span>
                <Space size={[0, 8]} wrap>
                    {record && record?.networkType === "partial" && record?.network && record?.network.length > 0
                        ? record?.network?.map((item) => {
                              return (
                                  <Tag color="success" key={item?.username}>
                                      {item?.username}
                                  </Tag>
                              );
                          })
                        : "-"}
                </Space>
            </span>
        ),
    },
    {
        title: "Network Exclude",
        key: "networkExclude",
        dataIndex: "networkExclude",
        width: 250,
        ...getColumnSearchProps(),
        render: (_, record) => (
            <span>
                {record && record?.networkType === "partial" && record?.exclude && record?.exclude.length > 0
                    ? record?.exclude?.map((tag) => {
                          return (
                              <Tag color="error" key={tag?.username}>
                                  {tag?.username}
                              </Tag>
                          );
                      })
                    : "-"}
            </span>
        ),
    },
];
