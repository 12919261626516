import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {Button, Popconfirm, Row, Space} from "antd";
import EditTableUserPreferenceButton from "../../../../../components/table/function/editTableUserPreferenceButton";
import ExportTableButton from "../../../../../components/table/function/exportTable";
import {columns} from "../../function/tableColumn";
import {deleteMamPammProvider, getMamPammProvider} from "../../../../../api/graphql/mamPammProvider";
import useAuthorize from "../../../../../_common/authorize";
import {notificationMessage} from "../../../../../../recoil_state";
import MamPammProviderModal from "./component/mamPammProviderModal";
import TableAntDesign from "../../../../../components/table/tableAntDesign";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";

const MamPammProvider_1 = (props) => {
    const {permission, serverData, languageData} = props;
    const {t} = useTranslation();
    const {systemErrorNotification} = useAuthorize();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const tableName = "MAM/PAMM Provider";

    const [mode, setMode] = useState("");
    const [selectedItem, setSelectedItem] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRequesting, setIsRequesting] = useState(false);

    const [exportData, setExportData] = useState<any>([]);
    const [exportColumn, setExportColumn] = useState<any>([]);

    const [tableData, setTableData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [orderBy, setOrderBy] = useState("");
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [filter, setFilter] = useState<any>({});

    const getTableData = async () => {
        try {
            setIsRequesting(true);
            const response = await getMamPammProvider({filter}, limit, offset, orderBy);
            setTableData(response?.getMamPammProvider?.data);
            setTotalCount(response?.getMamPammProvider?.total);
        } catch (error) {
        } finally {
            setIsRequesting(false);
        }
    };

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize);
        setOffset(limit * (page - 1));
    };

    const openAddModal = () => {
        setMode("Add");
        setIsModalOpen(true);
    };

    const openEditModal = (record) => {
        setMode("Edit");
        setIsModalOpen(true);
        setSelectedItem(record);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedItem({});
    };

    const handleDelete = async (id) => {
        try {
            const res = await deleteMamPammProvider(id);
            const response = res?.deleteMamPammProvider;
            if (response?.__typename === "MamPammProvider") {
                await getTableData();
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("common.deleteSuccess"),
                    key: "deleteMamPammProvider",
                });
            } else if (response?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.errKey),
                    key: "deleteMamPammProvider",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.msg),
                    key: "deleteMamPammProvider",
                });
            }
        } catch (error) {
            systemErrorNotification();
        } finally {
            closeModal();
        }
    };

    useEffect(() => {
        getTableData();
    }, [filter, orderBy, limit, offset, isModalOpen]);

    return (
        <div id="mamPammProvider">
            <Row justify={"space-between"} className={"margin-bottom-0-75"}>
                <h1>{t("MAM/PAMM Provider")}</h1>
                <Space>
                    <EditTableUserPreferenceButton tableName={tableName} tableColumn={columns()} />
                    <ExportTableButton tableData={exportData} tableColumn={columns()} tableName={tableName} />
                    {permission?.add?.add && (
                        <Button className="margin-left-auto" onClick={openAddModal}>
                            {t("Add Provider")}
                        </Button>
                    )}
                </Space>
            </Row>

            <div className={"margin-top-0-75"}>
                {isModalOpen && (
                    <MamPammProviderModal
                        mode={mode}
                        item={selectedItem}
                        isOpen={isModalOpen}
                        closeModal={closeModal}
                        languageData={languageData}
                        serverData={serverData}
                    />
                )}
                <TableAntDesign
                    data={tableData}
                    tableName={tableName}
                    columns={[
                        ...columns(),
                        {
                            title: t("common.action"),
                            key: "action",
                            width: 100,
                            align: "center",
                            render: (_, record: any) => (
                                <Space>
                                    {permission?.edit?.edit && (
                                        <a style={{color: "green"}} onClick={() => openEditModal(record)}>
                                            <AiOutlineEdit />
                                        </a>
                                    )}
                                    {permission?.delete?.delete && (
                                        <Popconfirm
                                            placement="left"
                                            description={t("Are you sure to delete this provider?")}
                                            title={t(`Delete the Provider`)}
                                            onConfirm={() => {
                                                handleDelete(record?.id || record?._id);
                                            }}
                                            okText={t("Yes")}
                                            okType="danger"
                                            cancelText={t("No")}
                                        >
                                            <a>
                                                <AiOutlineDelete style={{color: "red"}} />
                                            </a>
                                        </Popconfirm>
                                    )}
                                </Space>
                            ),
                        },
                    ]}
                    size={"small"}
                    loading={isRequesting}
                    filter={setFilter}
                    actionPermission={permission?.edit?.edit || permission?.delete?.delete}
                    order={setOrderBy}
                    pagination={{
                        pageSize: limit,
                        showSizeChanger: true,
                        onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                        total: totalCount,
                        showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
                    }}
                />
            </div>
        </div>
    );
};

export default MamPammProvider_1;
