import {atom} from "recoil";

const registrationSettingPlatformState = atom({
    key: 'registrationPlatform',
    default: "webRegister",
});

const registrationSettingModeState = atom({
    key: 'registrationMode',
    default: "live",
});

const registrationSettingState = atom<any[]>({
    key: 'registrationState',
    default: [],
});

const registrationSettingLoadingState = atom<any>({
    key: 'registrationLoading',
    default: false,
});

export {
    registrationSettingPlatformState,
    registrationSettingModeState,
    registrationSettingState,
    registrationSettingLoadingState
}