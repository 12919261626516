import React, {useEffect} from "react";
import {Col, Form, Input, Row} from "antd";
import {useTranslation} from "react-i18next";

const CompanyPanelMt5 = ({initialData}) => {
    const {t} = useTranslation()

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(initialData);
    }, [initialData])

    return (
        <>
            <Row gutter={[16, 16]} style={{marginBottom: "1%", marginTop: "2%"}}>
                <Col span={24}>
                    <p>
                        Please specify details of the group servicing company and the folder with mail and report
                        templates.
                    </p>
                </Col>
            </Row>

            <Form form={form}>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={24}>
                        <Form.Item
                            name={"company"}
                            label={t("Company")}
                            initialValue={initialData?.company}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={"companyPage"}
                            label={t("Company site")}
                            initialValue={initialData?.companyPage}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={"companyEmail"}
                            label={t("Company email")}
                            initialValue={initialData?.companyEmail}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={"companyDepositPage"}
                            label={t("Deposit site")}
                            initialValue={initialData?.companyDepositPage}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={"companyWithdrawalPage"}
                            label={t("Withdrawal site")}
                            initialValue={initialData?.companyWithdrawalPage}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={"companySupportPage"}
                            label={t("Support site")}
                            initialValue={initialData?.companySupportPage}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={"companySupportEmail"}
                            label={t("Support email")}
                            initialValue={initialData?.companySupportEmail}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            name={"templateFolder"}
                            label={<span style={{color: "red"}}>{t("Templates folder")}</span>}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default CompanyPanelMt5