import React from "react";
import HtmlEditorToolbar from "./htmlEditorToolbar";
import VariableForm from "./variableForm";

const HtmlEditor = (props) => {
    const { 
        plainHtml, 
        onHtmlStateChange,
        onEditorStateChange
    } = props

    const handleUpdate = () => {
        onEditorStateChange(plainHtml)
    }
    return (
        <>
            <VariableForm />
            <HtmlEditorToolbar handleUpdate={handleUpdate}/>
            <textarea
                className="html-editor-1"
                value={plainHtml}
                onChange={e=>onHtmlStateChange(e?.target?.value)}
            />
        </>
    )
}

export default HtmlEditor