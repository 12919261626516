import {useCallback, useState} from "react";
import {getMedia} from "../../../../../api/restful/arena/media_file";
import {getMediaFolder} from "../../../../../api/restful/arena/media_folder";

export const useGetMedia = () => {
    const [data, setData] = useState<any>(null);
    const [params, setParams] = useState<any>({});

    const fetchMedia = useCallback(
        async (value?: any, type?: string) => {
            try {
                let param = {...params}

                if (type === "filter.isDeleted") {
                    param = {...param, filter: {...param.filter, isDeleted: value}}
                }
                // if (type === "filter.fileType") {
                //     param = {...param, filter: {...param.filter, fileType: value}}
                // } else if (type === "filter.category") {
                //     if (value) {
                //         param = {...param, filter: {...param.filter, category: value}}
                //     } else {
                //         delete param.filter?.category
                //     }
                // }

                if (JSON.stringify(param) !== JSON.stringify(params)) {
                    setParams(param)
                }

                const response = await getMedia(param)
                setData(response.data || [])
            } catch (e) {
                console.error("Error fetching media:", e)
            }
        },
        [params]
    );

    const refetch = useCallback(async () => {
        try {
            const response = await getMedia(params);
            setData(response.data || []);
        } catch (e) {
            console.error("Error refetching media:", e);
        }
    }, [params]);

    return [data, fetchMedia, refetch] as const
}


export const useGetFolder = () => {
    const [data, setData] = useState<any>(null);
    const [params, setParams] = useState<any>({});

    const fetchFolder = useCallback(async (value?: any, type?: string) => {
            try {
                let param = {...params}
                if (type === "filter.isDeleted") {
                    param = {...param, filter: {...param.filter, isDeleted: value}}
                }

                if (JSON.stringify(param) !== JSON.stringify(params)) {
                    setParams(param)
                }

                const response = await getMediaFolder(param)
                setData(response.data || [])
            } catch (e) {
                console.error("Error fetching media:", e)
            }
        },
        [params]
    );

    const refetch = useCallback(async () => {
        try {
            const response = await getMediaFolder(params);
            setData(response.data || []);
        } catch (e) {
            console.error("Error refetching media:", e);
        }
    }, [params]);

    return [data, fetchFolder, refetch] as const
}