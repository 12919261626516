import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { Popconfirm, Space, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import RoleTemplateModal from "../component/roleTemplateModal";
import {
  getRoleTemplate,
  getRoleTemplateCount,
  useDeleteRoleTemplateMutation,
} from "../../../../../../api/graphql/roleTemplate";
import { getNetworkIdFromRecord } from "../../../function/common";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import { columns } from "../../../function/tableColumn";
import { notificationMessage } from "../../../../../../../recoil_state";
import { refetchQuery } from "../../../../../../function/refetchQuery";
import useAuthorize from "../../../../../../_common/authorize";

const RoleSettings = (props) => {
  const {
    formMode,
    isModalOpen,
    parentPermissionArrayData,
    networkTreeDownlineData,
    setNetworkList,
    targetParent,
    setTargetParent,
    editType,
    setEditType,
    toggleModal,
    setExportData,
    setExportColumn,
    refetchRoleNetwork,
    roleTemplatePermission,
    setLoading,
  } = props;
  const { t } = useTranslation();
  const tableName = "Role Settings";
  const [selectedItem, setSelectedItem] = useState({});
  const [orderBy, setOrderBy] = useState("parent_ASC");
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [filter, setFilter] = useState({});

  const { data: totalCount } = getRoleTemplateCount({ filter: filter });
  const { loading, error, data } = getRoleTemplate(
    { filter: filter },
    0,
    0,
    orderBy
  );
  const { deleteRoleTemplate } = useDeleteRoleTemplateMutation();
  const { systemErrorNotification } = useAuthorize();

  const deleteOnConfirm = async (id) => {
    try {
      await deleteRoleTemplate(id);
    } catch (e) {
      console.log(e);
      systemErrorNotification();
    }
  };

  const onChangePageHandler = (page, pageSize) => {
    setLimit(pageSize);
    setOffset(limit * (page - 1));
  };

  const openViewModal = (record) => {
    setSelectedItem(record);
    setTargetParent(record?.parent?._id);
    setNetworkList(getNetworkIdFromRecord(record?.network));
    toggleModal("view");
    setEditType("both");
  };

  const openEditModal = (record, editType) => {
    setSelectedItem(record);
    setTargetParent(record?.parent?._id);
    setNetworkList(getNetworkIdFromRecord(record?.network));
    toggleModal("edit");
    setEditType(editType);
  };

  const closeModal = () => {
    toggleModal("");
    setSelectedItem({});
  };

  useEffect(() => {
    setExportColumn(columns);
    const exportData = data?.getRoleTemplate?.data?.map((v) => ({
      ...v,
      parent: v?.parent?.name || "-",
    }));
    setExportData(exportData);
    refetchRoleNetwork();
  }, [data?.getRoleTemplate?.data]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return (
    <div id="roleTemplate" className={"margin-top-0-75"}>
      <RoleTemplateModal
        mode={formMode}
        item={selectedItem}
        isOpen={isModalOpen}
        parentPermissionArrayData={parentPermissionArrayData}
        targetParent={targetParent}
        setTargetParent={setTargetParent}
        roleTemplateList={data?.getRoleTemplate?.data}
        networkTreeDownlineData={networkTreeDownlineData}
        setNetworkList={setNetworkList}
        editType={editType}
        setEditType={setEditType}
        closeModal={closeModal}
      />

      <TableAntDesign
        tableName={tableName}
        data={data?.getRoleTemplate?.data}
        actionPermission={
          roleTemplatePermission?.edit?.edit ||
          roleTemplatePermission?.delete?.delete
        }
        columns={[
          ...columns,
          {
            title: t("common.action"),
            key: "action",
            width: 250,
            render: (_, record: any) => (
              <Space size="middle">
                <Tooltip title="View">
                  <a onClick={() => openViewModal(record)}>
                    <EyeOutlined />
                  </a>
                </Tooltip>
                {roleTemplatePermission?.edit?.edit && (
                  <>
                    <Tooltip title={t("roleSetting.editGeneralSettings")}>
                      <a onClick={() => openEditModal(record, "general")}>
                        <EditOutlined /> {t("common.general")}
                      </a>
                    </Tooltip>
                    {!record?.isMasterAdmin && (
                      <Tooltip title={t("roleSetting.editPermission")}>
                        <a onClick={() => openEditModal(record, "permission")}>
                          <EditOutlined /> {t("common.permission")}
                        </a>
                      </Tooltip>
                    )}
                  </>
                )}
                {!record?.isMasterAdmin &&
                  roleTemplatePermission?.delete?.delete &&
                  !record?.isDefaultCrmUserPermission && (
                    <Tooltip title={t("roleSetting.deletePermission")}>
                      <Popconfirm
                        placement="left"
                        title={t("roleSetting.delete.popconfirm.title")}
                        description={t(
                          "roleSetting.delete.popconfirm.description"
                        )}
                        onConfirm={() => {
                          deleteOnConfirm(record?.id);
                        }}
                        okText={t("common.yes")}
                        cancelText={t("common.no")}
                      >
                        <a style={{color: "red"}}>
                          <DeleteOutlined />
                        </a>
                      </Popconfirm>
                    </Tooltip>
                  )}
              </Space>
            ),
          },
        ]}
        size={"small"}
        loading={loading}
        filter={setFilter}
        order={setOrderBy}
        rowKey="id"
        pagination={{
          pageSize: limit,
          showSizeChanger: true,
          onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
          total: totalCount?.getRoleTemplateCount,
          showTotal: (total) => (
            <p>{t("pagination.totalItems", { totalCount: total })}</p>
          ),
        }}
      />
    </div>
  );
};

export default RoleSettings;
