import React, {useEffect, useState} from "react";
import {DragDropContext} from "react-beautiful-dnd";
import {Empty, Row, Spin} from "antd";
import BoardViewCard from "./boardViewCard";
import SalesOpportunityForm from "./salesOpportunityForm";
import {useSalesOpportunityFunction} from "../../../function";
import {useUpdateSalesOpportunityMutation} from "../../../../../../api/graphql/salesOpportunity";
import useAuthorize from "../../../../../../_common/authorize";

const BoardView = (props) => {
    const {statusList, permissionUser, fields, refetchSalesOpportunity} = props

    const {fetchSalesOpportunityBoardView} = useSalesOpportunityFunction()
    const {systemErrorNotification} = useAuthorize()
    const {updateSalesOpportunity} = useUpdateSalesOpportunityMutation()

    const fieldForLogin = fields?.map(d => {
        return d?.fieldName
    }) || []
    //Last in First out
    const maxCardLength = 50

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [formData, setFormData] = useState<any>()
    const [statusChanged, setStatusChanged] = useState<boolean>(true)
    const [data, setData] = useState<object | null>(null)
    const [loading, setLoading] = useState(false)
    const [offsetLimitStatus, setOffsetLimitStatus] = useState<object | null>(null)
    const [currentLoad, setCurrentLoad] = useState<string>("")

    const preload = async () => {
        setLoading(true)
        const dataObj = {}
        if (currentLoad) {
            const currentStatus = statusList?.data?.find(d => d?.name === currentLoad)
            const offsetLimit = offsetLimitStatus?.[currentLoad]
            const response = await fetchSalesOpportunityBoardView(currentStatus?._id, {
                isArchived: false,
                isReviewed: false
            }, offsetLimit?.limit, offsetLimit?.offset, null)

            setData(prevState => ({
                ...prevState,
                [currentStatus?._id]: {
                    ...prevState?.[currentStatus?._id],
                    data: [...prevState?.[currentStatus?._id]?.data, ...response?.getSalesOpportunityLite?.data]
                }
            }))

            setCurrentLoad("")
        } else {
            const promises = [...statusList?.data]?.sort((a, b) => a?.position - b?.position)?.map(async (status, key) => {
                const offsetLimit = offsetLimitStatus?.[status?.name]
                const response = await fetchSalesOpportunityBoardView(status?._id, {
                    isArchived: false,
                    isReviewed: false
                }, offsetLimit?.limit, offsetLimit?.offset, null);

                dataObj[status?._id] = {
                    ...response?.getSalesOpportunityLite,
                    status: status
                }
            });
            await Promise.all(promises);

            const keyValueArray = Object.entries(dataObj);
            keyValueArray.sort((a: any, b: any) => a[1]?.status?.position - b[1]?.status?.position);

            const sortedObj = {};
            for (const [key, value] of keyValueArray) {
                sortedObj[key] = value;
            }

            setData(sortedObj)
        }

        setLoading(false)
        setStatusChanged(false)
    }

    const onDragEnd = async (result) => {
        try {
            if (!result.destination || !data) return;
            const {draggableId, source, destination} = result;

            if (source.droppableId !== destination.droppableId) {
                const sourceColumn = data[source.droppableId];
                const destColumn = data[destination.droppableId];
                const sourceData = [...sourceColumn.data];
                const destData = [...destColumn.data];
                const [removed] = sourceData.splice(source.index, 1);
                destData.splice(destination.index, 0, removed);
                setData({
                    ...data,
                    [source.droppableId]: {
                        ...sourceColumn,
                        data: sourceData
                    },
                    [destination.droppableId]: {
                        ...destColumn,
                        data: destData
                    }
                });

                await updateSalesOpportunity(draggableId, {salesOppStatus: destination?.droppableId})
                await preload()
            } else {
                const column = data[source.droppableId];
                const copiedItems = [...column.data];
                const [removed] = copiedItems.splice(source.index, 1);
                copiedItems.splice(destination.index, 0, removed);
                setData({
                    ...data,
                    [source.droppableId]: {
                        ...column,
                        items: copiedItems
                    }
                });
            }
        } catch (e) {
            systemErrorNotification()
        }
    };

    const setInitialOffsetLimit = async () => {
        const initial: object = {}
        await statusList?.data?.map(d => {
            initial[d?.name] = {offset: 0, limit: 10}
        })

        setOffsetLimitStatus(initial)
    }

    useEffect(() => {
        if (statusList?.data?.length > 0 && !offsetLimitStatus) {
            setInitialOffsetLimit()
        }
    }, [statusList])

    useEffect(() => {
        if (!statusChanged && !currentLoad) return
        if (offsetLimitStatus && statusList) {
            preload()
        }
    }, [offsetLimitStatus, statusChanged, currentLoad, statusList])

    return (
        <div className={"board-wrapper"}>
            {permissionUser?.salesOpportunity?.edit?.edit &&
            <SalesOpportunityForm
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                mode={"Edit"}
                formData={formData}
                type={formData?.userType}
                statusList={statusList}
                setStatusChanged={setStatusChanged}
                refetchSalesOpportunity={refetchSalesOpportunity}
            />
            }
            {
                data ?
                    <Row className={"margin-top-0-75 board-card-container"} justify={"center"}>
                        <DragDropContext
                            onDragEnd={result => onDragEnd(result)}
                        >
                            {
                                Object.keys(data)?.map(key => (
                                    <BoardViewCard
                                        key={key}
                                        data={data[key]}
                                        loading={loading}
                                        permissionUser={permissionUser}
                                        fieldForLogin={fieldForLogin}
                                        setFormData={setFormData}
                                        setIsOpen={setIsOpen}
                                        setStatusChanged={setStatusChanged}
                                        setOffsetLimitStatus={setOffsetLimitStatus}
                                        setCurrentLoad={setCurrentLoad}
                                    />
                                ))
                            }
                        </DragDropContext>
                    </Row>
                    : loading ?
                    <div className={"h-100 display-flex margin-top-0-75"}>
                        <Spin spinning={loading} style={{margin: "auto"}}/>
                    </div>
                    :
                    <Empty/>
            }
        </div>
    )
};

export default BoardView