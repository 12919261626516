const getNetworkIdFromRecord = (array) => {
  try {
    if (!array || !(array?.length > 0)) {
      return [];
    }

    return array?.map((item) => {
      return item?._id;
    });
  } catch (error) {}
};

const flattenPermissionObject = (obj, parentKey = "") => {
  try {
    return Object.entries(obj)?.flatMap(([key, value]) => {
      const nestedKey = parentKey ? `${parentKey}:${key}` : key;
      if (typeof value === "object") {
        return flattenPermissionObject(value, nestedKey);
      } else if (value === true) {
        return nestedKey;
      }
      return [];
    });
  } catch (error) {}
};

function convertArrayToObjectValue(array, value) {
  try {
    const result = {};

    for (const item of array) {
      const keys = item?.split(":");
      let currentObj = result;

      for (let i = 0; i < keys?.length; i++) {
        const key = keys[i];

        if (!currentObj.hasOwnProperty(key)) {
          if (i === keys?.length - 1) {
            currentObj[key] = value;
          } else {
            currentObj[key] = {};
          }
        }

        currentObj = currentObj[key];
      }
    }
    return result;
  } catch (error) {}
}

const convertObjValueToCheckedValue = (obj, checkedValue) => {
  try {
    const amendedObj = { ...obj };
    for (let key in amendedObj) {
      if (typeof amendedObj[key] === "object") {
        convertObjValueToCheckedValue(amendedObj[key], checkedValue); // Recursively call the function for nested objects
      } else {
        amendedObj[key] = checkedValue; // Set the value to true
      }
    }
    return amendedObj;
  } catch (error) {}
};

function checkAllValuesInObjTrue(obj) {
  try {
    for (const key in obj) {
      if (typeof obj[key] === "object") {
        if (!checkAllValuesInObjTrue(obj[key])) {
          return false;
        }
      } else if (obj[key] !== true) {
        return false;
      }
    }
    return true;
  } catch (error) {}
}

function checkSomeValuesInObjTrue(obj) {
  try {
    for (const key in obj) {
      if (typeof obj[key] === "object") {
        if (checkSomeValuesInObjTrue(obj[key])) {
          return true;
        }
      } else if (obj[key] === true) {
        return true;
      }
    }
    return false;
  } catch (error) {}
}

function combineObjects(obj1, obj2) {
  try {
    const combinedObj = { ...obj1 }; // Create a new object as a copy of the first object

    for (const key in obj2) {
      if (obj2?.hasOwnProperty(key)) {
        if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
          // Recursively combine nested objects
          combinedObj[key] = combineObjects(obj1[key], obj2[key]);
        } else {
          // Overwrite the value from the first object with the value from the second object
          combinedObj[key] = obj2[key];
        }
      }
    }

    return combinedObj;
  } catch (error) {}
}

const mapNetworkToDropdown = (data) => {
  try {
    if (!data || !(data?.length > 0)) {
      return [];
    }
    return data?.map((item) => {
      return {
        label: item?.username,
        value: item?._id,
      };
    });
  } catch (error) {}
};

const mapNetworkToString = (data) => {
  try {
    let result: any[] = [];
    if (!data || !(data?.length > 0)) {
      return null;
    }
    data?.forEach((item) => {
      if (typeof item === "object") {
        result?.push(item?.value);
      } else {
        result?.push(item);
      }
    });
    return result;
  } catch (error) {}
};

export {
  getNetworkIdFromRecord,
  flattenPermissionObject,
  convertArrayToObjectValue,
  convertObjValueToCheckedValue,
  checkAllValuesInObjTrue,
  checkSomeValuesInObjTrue,
  combineObjects,
  mapNetworkToDropdown,
  mapNetworkToString,
};
