import axios from "axios";
import {getQueryStringParams} from "./_commonFunction";

export async function getInstrumentRestful({filter}, page, numOfRecords, orderBy) {
    const filterParams = getQueryStringParams(filter)

    return await axios({
        url: `https://instruments.nextrades.ai/api/rest/getInstruments?page=${page}&numOfRecords=${numOfRecords}&orderBy=${orderBy}&${filterParams}`,
        method: "get",
    })
        .then((response) => {
            return response?.data;
        })
        .catch((error) => {
            console.log(error);
            throw error;
        });
}

export async function getInstrumentCountRestful({filter}) {
    const filterParams = getQueryStringParams(filter)

    return await axios({
        url: `https://instruments.nextrades.ai/api/rest/getInstrumentsCount?${filterParams}`,
        method: "get",
    })
        .then((response) => {
            return response?.data;
        })
        .catch((error) => {
            console.log(error);
            throw error;
        });
}