export const CountryCodeList_Language = [
    {
        key: "AF",
        label: "countryCode.afghanistan",
        value: "+93",
    },
    {
        key: "AX",
        label: "countryCode.alandIslands",
        value: "+358",
    },
    {
        key: "AL",
        label: "countryCode.albania",
        value: "+355",
    },
    {
        key: "DZ",
        label: "countryCode.algeria",
        value: "+213",
    },
    {
        key: "AS",
        label: "countryCode.americanSamoa",
        value: "+1684",
    },
    {
        key: "AD",
        label: "countryCode.andorra",
        value: "+376",
    },
    {
        key: "AO",
        label: "countryCode.angola",
        value: "+244",
    },
    {
        key: "AI",
        label: "countryCode.anguilla",
        value: "+1264",
    },
    {
        key: "AQ",
        label: "countryCode.antarctica",
        value: "+672",
    },
    {
        key: "AG",
        label: "countryCode.antiguaAndBarbuda",
        value: "+1268",
    },
    {
        key: "AR",
        label: "countryCode.argentina",
        value: "+54",
    },
    {
        key: "AM",
        label: "countryCode.armenia",
        value: "+374",
    },
    {
        key: "AW",
        label: "countryCode.aruba",
        value: "+297",
    },
    {
        key: "AU",
        label: "countryCode.australia",
        value: "+61",
    },
    {
        key: "AT",
        label: "countryCode.austria",
        value: "+43",
    },
    {
        key: "AZ",
        label: "countryCode.azerbaijan",
        value: "+994",
    },
    {
        key: "BS",
        label: "countryCode.bahamas",
        value: "+1242",
    },
    {
        key: "BH",
        label: "countryCode.bahrain",
        value: "+973",
    },
    {
        key: "BD",
        label: "countryCode.bangladesh",
        value: "+880",
    },
    {
        key: "BB",
        label: "countryCode.barbados",
        value: "+1246",
    },
    {
        key: "BY",
        label: "countryCode.belarus",
        value: "+375",
    },
    {
        key: "BE",
        label: "countryCode.belgium",
        value: "+32",
    },
    {
        key: "BZ",
        label: "countryCode.belize",
        value: "+501",
    },
    {
        key: "BJ",
        label: "countryCode.benin",
        value: "+229",
    },
    {
        key: "BM",
        label: "countryCode.bermuda",
        value: "+1441",
    },
    {
        key: "BT",
        label: "countryCode.bhutan",
        value: "+975",
    },
    {
        key: "BO",
        label: "countryCode.boliviaPlurinationalStateOf",
        value: "+591",
    },
    {
        key: "BA",
        label: "countryCode.bosniaAndHerzegovina",
        value: "+387",
    },
    {
        key: "BW",
        label: "countryCode.botswana",
        value: "+267",
    },
    {
        key: "BR",
        label: "countryCode.brazil",
        value: "+55",
    },
    {
        key: "IO",
        label: "countryCode.britishIndianOceanTerritory",
        value: "+246",
    },
    {
        key: "BN",
        label: "countryCode.bruneiDarussalam",
        value: "+673",
    },
    {
        key: "BG",
        label: "countryCode.bulgaria",
        value: "+359",
    },
    {
        key: "BF",
        label: "countryCode.burkinaFaso",
        value: "+226",
    },
    {
        key: "BI",
        label: "countryCode.burundi",
        value: "+257",
    },
    {
        key: "KH",
        label: "countryCode.cambodia",
        value: "+855",
    },
    {
        key: "CM",
        label: "countryCode.cameroon",
        value: "+237",
    },
    {
        key: "CA",
        label: "countryCode.canada",
        value: "+1",
    },
    {
        key: "CV",
        label: "countryCode.capeVerde",
        value: "+238",
    },
    {
        key: "KY",
        label: "countryCode.caymanIslands",
        value: "+ 345",
    },
    {
        key: "CF",
        label: "countryCode.centralAfricanRepublic",
        value: "+236",
    },
    {
        key: "TD",
        label: "countryCode.chad",
        value: "+235",
    },
    {
        key: "CL",
        label: "countryCode.chile",
        value: "+56",
    },
    {
        key: "CN",
        label: "countryCode.china",
        value: "+86",
    },
    {
        key: "CX",
        label: "countryCode.christmasIsland",
        value: "+61",
    },
    {
        key: "CC",
        label: "countryCode.cocos KeelingIslands",
        value: "+61",
    },
    {
        key: "CO",
        label: "countryCode.colombia",
        value: "+57",
    },
    {
        key: "KM",
        label: "countryCode.comoros",
        value: "+269",
    },
    {
        key: "CG",
        label: "countryCode.congo",
        value: "+242",
    },
    {
        key: "CD",
        label: "countryCode.congoTheDemocraticRepublicOfTheCongo",
        value: "+243",
    },
    {
        key: "CK",
        label: "countryCode.cookIslands",
        value: "+682",
    },
    {
        key: "CR",
        label: "countryCode.costaRica",
        value: "+506",
    },
    {
        key: "CI",
        label: "countryCode.coteDIvoire",
        value: "+225",
    },
    {
        key: "HR",
        label: "countryCode.croatia",
        value: "+385",
    },
    {
        key: "CU",
        label: "countryCode.cuba",
        value: "+53",
    },
    {
        key: "CY",
        label: "countryCode.cyprus",
        value: "+357",
    },
    {
        key: "CZ",
        label: "countryCode.czechRepublic",
        value: "+420",
    },
    {
        key: "DK",
        label: "countryCode.denmark",
        value: "+45",
    },
    {
        key: "DJ",
        label: "countryCode.djibouti",
        value: "+253",
    },
    {
        key: "DM",
        label: "countryCode.dominica",
        value: "+1767",
    },
    {
        key: "DO",
        label: "countryCode.dominicanRepublic",
        value: "+1849",
    },
    {
        key: "EC",
        label: "countryCode.ecuador",
        value: "+593",
    },
    {
        key: "EG",
        label: "countryCode.egypt",
        value: "+20",
    },
    {
        key: "SV",
        label: "countryCode.elSalvador",
        value: "+503",
    },
    {
        key: "GQ",
        label: "countryCode.equatorialGuinea",
        value: "+240",
    },
    {
        key: "ER",
        label: "countryCode.eritrea",
        value: "+291",
    },
    {
        key: "EE",
        label: "countryCode.estonia",
        value: "+372",
    },
    {
        key: "ET",
        label: "countryCode.ethiopia",
        value: "+251",
    },
    {
        key: "FK",
        label: "countryCode.falklandIslandsMalvinas",
        value: "+500",
    },
    {
        key: "FO",
        label: "countryCode.faroeIslands",
        value: "+298",
    },
    {
        key: "FJ",
        label: "countryCode.fiji",
        value: "+679",
    },
    {
        key: "FI",
        label: "countryCode.finland",
        value: "+358",
    },
    {
        key: "FR",
        label: "countryCode.france",
        value: "+33",
    },
    {
        key: "GF",
        label: "countryCode.frenchGuiana",
        value: "+594",
    },
    {
        key: "PF",
        label: "countryCode.frenchPolynesia",
        value: "+689",
    },
    {
        key: "GA",
        label: "countryCode.gabon",
        value: "+241",
    },
    {
        key: "GM",
        label: "countryCode.gambia",
        value: "+220",
    },
    {
        key: "GE",
        label: "countryCode.georgia",
        value: "+995",
    },
    {
        key: "DE",
        label: "countryCode.germany",
        value: "+49",
    },
    {
        key: "GH",
        label: "countryCode.ghana",
        value: "+233",
    },
    {
        key: "GI",
        label: "countryCode.gibraltar",
        value: "+350",
    },
    {
        key: "GR",
        label: "countryCode.greece",
        value: "+30",
    },
    {
        key: "GL",
        label: "countryCode.greenland",
        value: "+299",
    },
    {
        key: "GD",
        label: "countryCode.grenada",
        value: "+1473",
    },
    {
        key: "GP",
        label: "countryCode.guadeloupe",
        value: "+590",
    },
    {
        key: "GU",
        label: "countryCode.guam",
        value: "+1671",
    },
    {
        key: "GT",
        label: "countryCode.guatemala",
        value: "+502",
    },
    {
        key: "GG",
        label: "countryCode.guernsey",
        value: "+44",
    },
    {
        key: "GN",
        label: "countryCode.guinea",
        value: "+224",
    },
    {
        key: "GW",
        label: "countryCode.guineaBissau",
        value: "+245",
    },
    {
        key: "GY",
        label: "countryCode.guyana",
        value: "+595",
    },
    {
        key: "HT",
        label: "countryCode.haiti",
        value: "+509",
    },
    {
        key: "VA",
        label: "countryCode.holySeeVaticanCityState",
        value: "+379",
    },
    {
        key: "HN",
        label: "countryCode.honduras",
        value: "+504",
    },
    {
        key: "HK",
        label: "countryCode.hongKong",
        value: "+852",
    },
    {
        key: "HU",
        label: "countryCode.hungary",
        value: "+36",
    },
    {
        key: "IS",
        label: "countryCode.iceland",
        value: "+354",
    },
    {
        key: "IN",
        label: "countryCode.india",
        value: "+91",
    },
    {
        key: "ID",
        label: "countryCode.indonesia",
        value: "+62",
    },
    {
        key: "IR",
        label: "countryCode.iranIslamicRepublicOfPersianGulf",
        value: "+98",
    },
    {
        key: "IQ",
        label: "countryCode.iraq",
        value: "+964",
    },
    {
        key: "IE",
        label: "countryCode.ireland",
        value: "+353",
    },
    {
        key: "IM",
        label: "countryCode.isleOfMan",
        value: "+44",
    },
    {
        key: "IL",
        label: "countryCode.israel",
        value: "+972",
    },
    {
        key: "IT",
        label: "countryCode.italy",
        value: "+39",
    },
    {
        key: "JM",
        label: "countryCode.jamaica",
        value: "+1876",
    },
    {
        key: "JP",
        label: "countryCode.japan",
        value: "+81",
    },
    {
        key: "JE",
        label: "countryCode.jersey",
        value: "+44",
    },
    {
        key: "JO",
        label: "countryCode.jordan",
        value: "+962",
    },
    {
        key: "KZ",
        label: "countryCode.kazakhstan",
        value: "+77",
    },
    {
        key: "KE",
        label: "countryCode.kenya",
        value: "+254",
    },
    {
        key: "KI",
        label: "countryCode.kiribati",
        value: "+686",
    },
    {
        key: "KP",
        label: "countryCode.koreaDemocraticPeoplesRepublicOfKorea",
        value: "+850",
    },
    {
        key: "KR",
        label: "countryCode.koreaRepublicOfSouthKorea",
        value: "+82",
    },
    {
        key: "KW",
        label: "countryCode.kuwait",
        value: "+965",
    },
    {
        key: "KG",
        label: "countryCode.kyrgyzstan",
        value: "+996",
    },
    {
        key: "LA",
        label: "countryCode.laos",
        value: "+856",
    },
    {
        key: "LV",
        label: "countryCode.latvia",
        value: "+371",
    },
    {
        key: "LB",
        label: "countryCode.lebanon",
        value: "+961",
    },
    {
        key: "LS",
        label: "countryCode.lesotho",
        value: "+266",
    },
    {
        key: "LR",
        label: "countryCode.liberia",
        value: "+231",
    },
    {
        key: "LY",
        label: "countryCode.libyanArabJamahiriya",
        value: "+218",
    },
    {
        key: "LI",
        label: "countryCode.liechtenstein",
        value: "+423",
    },
    {
        key: "LT",
        label: "countryCode.lithuania",
        value: "+370",
    },
    {
        key: "LU",
        label: "countryCode.luxembourg",
        value: "+352",
    },
    {
        key: "MO",
        label: "countryCode.macao",
        value: "+853",
    },
    {
        key: "MK",
        label: "countryCode.macedonia",
        value: "+389",
    },
    {
        key: "MG",
        label: "countryCode.madagascar",
        value: "+261",
    },
    {
        key: "MW",
        label: "countryCode.malawi",
        value: "+265",
    },
    {
        key: "MY",
        label: "countryCode.malaysia",
        value: "+60",
    },
    {
        key: "MV",
        label: "countryCode.maldives",
        value: "+960",
    },
    {
        key: "ML",
        label: "countryCode.mali",
        value: "+223",
    },
    {
        key: "MT",
        label: "countryCode.malta",
        value: "+356",
    },
    {
        key: "MH",
        label: "countryCode.marshallIslands",
        value: "+692",
    },
    {
        key: "MQ",
        label: "countryCode.martinique",
        value: "+596",
    },
    {
        key: "MR",
        label: "countryCode.mauritania",
        value: "+222",
    },
    {
        key: "MU",
        label: "countryCode.mauritius",
        value: "+230",
    },
    {
        key: "YT",
        label: "countryCode.mayotte",
        value: "+262",
    },
    {
        key: "MX",
        label: "countryCode.mexico",
        value: "+52",
    },
    {
        key: "FM",
        label: "countryCode.micronesiaFederatedStatesOfMicronesia",
        value: "+691",
    },
    {
        key: "MD",
        label: "countryCode.moldova",
        value: "+373",
    },
    {
        key: "MC",
        label: "countryCode.monaco",
        value: "+377",
    },
    {
        key: "MN",
        label: "countryCode.mongolia",
        value: "+976",
    },
    {
        key: "ME",
        label: "countryCode.montenegro",
        value: "+382",
    },
    {
        key: "MS",
        label: "countryCode.montserrat",
        value: "+1664",
    },
    {
        key: "MA",
        label: "countryCode.morocco",
        value: "+212",
    },
    {
        key: "MZ",
        label: "countryCode.mozambique",
        value: "+258",
    },
    {
        key: "MM",
        label: "countryCode.myanmar",
        value: "+95",
    },
    {
        key: "NA",
        label: "countryCode.namibia",
        value: "+264",
    },
    {
        key: "NR",
        label: "countryCode.nauru",
        value: "+674",
    },
    {
        key: "NP",
        label: "countryCode.nepal",
        value: "+977",
    },
    {
        key: "NL",
        label: "countryCode.netherlands",
        value: "+31",
    },
    {
        key: "AN",
        label: "countryCode.netherlandsAntilles",
        value: "+599",
    },
    {
        key: "NC",
        label: "countryCode.newCaledonia",
        value: "+687",
    },
    {
        key: "NZ",
        label: "countryCode.newZealand",
        value: "+64",
    },
    {
        key: "NI",
        label: "countryCode.nicaragua",
        value: "+505",
    },
    {
        key: "NE",
        label: "countryCode.niger",
        value: "+227",
    },
    {
        key: "NG",
        label: "countryCode.nigeria",
        value: "+234",
    },
    {
        key: "NU",
        label: "countryCode.niue",
        value: "+683",
    },
    {
        key: "NF",
        label: "countryCode.norfolkIsland",
        value: "+672",
    },
    {
        key: "MP",
        label: "countryCode.northernMarianaIslands",
        value: "+1670",
    },
    {
        key: "NO",
        label: "countryCode.norway",
        value: "+47",
    },
    {
        key: "OM",
        label: "countryCode.oman",
        value: "+968",
    },
    {
        key: "PK",
        label: "countryCode.pakistan",
        value: "+92",
    },
    {
        key: "PW",
        label: "countryCode.palau",
        value: "+680",
    },
    {
        key: "PS",
        label: "countryCode.palestinianTerritoryOccupied",
        value: "+970",
    },
    {
        key: "PA",
        label: "countryCode.panama",
        value: "+507",
    },
    {
        key: "PG",
        label: "countryCode.papuaNewGuinea",
        value: "+675",
    },
    {
        key: "PY",
        label: "countryCode.paraguay",
        value: "+595",
    },
    {
        key: "PE",
        label: "countryCode.peru",
        value: "+51",
    },
    {
        key: "PH",
        label: "countryCode.philippines",
        value: "+63",
    },
    {
        key: "PN",
        label: "countryCode.pitcairn",
        value: "+872",
    },
    {
        key: "PL",
        label: "countryCode.poland",
        value: "+48",
    },
    {
        key: "PT",
        label: "countryCode.portugal",
        value: "+351",
    },
    {
        key: "PR",
        label: "countryCode.puertoRico",
        value: "+1939",
    },
    {
        key: "QA",
        label: "countryCode.qatar",
        value: "+974",
    },
    {
        key: "RO",
        label: "countryCode.romania",
        value: "+40",
    },
    {
        key: "RU",
        label: "countryCode.russia",
        value: "+7",
    },
    {
        key: "RW",
        label: "countryCode.rwanda",
        value: "+250",
    },
    {
        key: "RE",
        label: "countryCode.reunion",
        value: "+262",
    },
    {
        key: "BL",
        label: "countryCode.saintBarthelemy",
        value: "+590",
    },
    {
        key: "SH",
        label: "countryCode.saintHelenaAscensionAndTristanDaCunha",
        value: "+290",
    },
    {
        key: "KN",
        label: "countryCode.saintKittsAndNevis",
        value: "+1869",
    },
    {
        key: "LC",
        label: "countryCode.saintLucia",
        value: "+1758",
    },
    {
        key: "MF",
        label: "countryCode.saintMartin",
        value: "+590",
    },
    {
        key: "PM",
        label: "countryCode.saintPierreAndMiquelon",
        value: "+508",
    },
    {
        key: "VC",
        label: "countryCode.saintVincentAndTheGrenadines",
        value: "+1784",
    },
    {
        key: "WS",
        label: "countryCode.samoa",
        value: "+685",
    },
    {
        key: "SM",
        label: "countryCode.sanMarino",
        value: "+378",
    },
    {
        key: "ST",
        label: "countryCode.saoTomeAndPrincipe",
        value: "+239",
    },
    {
        key: "SA",
        label: "countryCode.saudiArabia",
        value: "+966",
    },
    {
        key: "SN",
        label: "countryCode.senegal",
        value: "+221",
    },
    {
        key: "RS",
        label: "countryCode.serbia",
        value: "+381",
    },
    {
        key: "SC",
        label: "countryCode.seychelles",
        value: "+248",
    },
    {
        key: "SL",
        label: "countryCode.sierraLeone",
        value: "+232",
    },
    {
        key: "SG",
        label: "countryCode.singapore",
        value: "+65",
    },
    {
        key: "SK",
        label: "countryCode.slovakia",
        value: "+421",
    },
    {
        key: "SI",
        label: "countryCode.slovenia",
        value: "+386",
    },
    {
        key: "SB",
        label: "countryCode.solomonIslands",
        value: "+677",
    },
    {
        key: "SO",
        label: "countryCode.somalia",
        value: "+252",
    },
    {
        key: "ZA",
        label: "countryCode.southAfrica",
        value: "+27",
    },
    {
        key: "SS",
        label: "countryCode.southSudan",
        value: "+211",
    },
    {
        key: "GS",
        label: "countryCode.southGeorgiaAndTheSouthSandwichIslands",
        value: "+500",
    },
    {
        key: "ES",
        label: "countryCode.spain",
        value: "+34",
    },
    {
        key: "LK",
        label: "countryCode.sriLanka",
        value: "+94",
    },
    {
        key: "SD",
        label: "countryCode.sudan",
        value: "+249",
    },
    {
        key: "SR",
        label: "countryCode.suriname",
        value: "+597",
    },
    {
        key: "SJ",
        label: "countryCode.svalbardAndJanMayen",
        value: "+47",
    },
    {
        key: "SZ",
        label: "countryCode.swaziland",
        value: "+268",
    },
    {
        key: "SE",
        label: "countryCode.sweden",
        value: "+46",
    },
    {
        key: "CH",
        label: "countryCode.switzerland",
        value: "+41",
    },
    {
        key: "SY",
        label: "countryCode.syrianArabRepublic",
        value: "+963",
    },
    {
        key: "TW",
        label: "countryCode.taiwan",
        value: "+886",
    },
    {
        key: "TJ",
        label: "countryCode.tajikistan",
        value: "+992",
    },
    {
        key: "TZ",
        label: "countryCode.tanzaniaUnitedRepublicOfTanzania",
        value: "+255",
    },
    {
        key: "TH",
        label: "countryCode.thailand",
        value: "+66",
    },
    {
        key: "TL",
        label: "countryCode.timorLeste",
        value: "+670",
    },
    {
        key: "TG",
        label: "countryCode.togo",
        value: "+228",
    },
    {
        key: "TK",
        label: "countryCode.tokelau",
        value: "+690",
    },
    {
        key: "TO",
        label: "countryCode.tonga",
        value: "+676",
    },
    {
        key: "TT",
        label: "countryCode.trinidadAndTobago",
        value: "+1868",
    },
    {
        key: "TN",
        label: "countryCode.tunisia",
        value: "+216",
    },
    {
        key: "TR",
        label: "countryCode.turkey",
        value: "+90",
    },
    {
        key: "TM",
        label: "countryCode.turkmenistan",
        value: "+993",
    },
    {
        key: "TC",
        label: "countryCode.turksAndCaicosIslands",
        value: "+1649",
    },
    {
        key: "TV",
        label: "countryCode.tuvalu",
        value: "+688",
    },
    {
        key: "UG",
        label: "countryCode.uganda",
        value: "+256",
    },
    {
        key: "UA",
        label: "countryCode.ukraine",
        value: "+380",
    },
    {
        key: "AE",
        label: "countryCode.unitedArabEmirates",
        value: "+971",
    },
    {
        key: "GB",
        label: "countryCode.unitedKingdom",
        value: "+44",
    },
    {
        key: "US",
        label: "countryCode.unitedStates",
        value: "+1",
    },
    {
        key: "UY",
        label: "countryCode.uruguay",
        value: "+598",
    },
    {
        key: "UZ",
        label: "countryCode.uzbekistan",
        value: "+998",
    },
    {
        key: "VU",
        label: "countryCode.vanuatu",
        value: "+678",
    },
    {
        key: "VE",
        label: "countryCode.venezuelaBolivarianRepublicOfVenezuela",
        value: "+58",
    },
    {
        key: "VN",
        label: "countryCode.vietnam",
        value: "+84",
    },
    {
        key: "VG",
        label: "countryCode.virginIslandsBritish",
        value: "+1284",
    },
    {
        key: "VI",
        label: "countryCode.virginIslandsUS",
        value: "+1340",
    },
    {
        key: "WF",
        label: "countryCode.wallisAndFutuna",
        value: "+681",
    },
    {
        key: "YE",
        label: "countryCode.yemen",
        value: "+967",
    },
    {
        key: "ZM",
        label: "countryCode.zambia",
        value: "+260",
    },
    {
        key: "ZW",
        label: "countryCode.zimbabwe",
        value: "+263",
    }
];
