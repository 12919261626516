import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Row, Space, Tabs, TabsProps, Upload} from "antd";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {useAddFollowUp} from "../../../../../../../api/graphql/followUp";
import TextAreaInputFormItem from "../../../../../../../components/form/textAreaInputFormItem";
import {verticalFormLayout} from "../../../../../../../components/form/formWrapper";
import DatePickerInputForm from "../../../../../../../components/form/datePickerFormItem";
import SelectFormItem from "../../../../../../../components/form/selectFormItem";
import {getAvailableEmailTemplate} from "../../../../../../../api/graphql/emailTemplate";
import useAuthorize from "../../../../../../../_common/authorize";
import {disableDateTime} from "../../../../../_common/function";
import * as yup from "yup";
import yupFormSchema from "../../../../../../../components/yup/yupFormSchema";
import {yupResolver} from "@hookform/resolvers/yup";
import {getConfigByName} from "../../../../../../../../config";
import {BRAND_NAME} from "../../../../../../../../config/brandVariable";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import {formatDateTimeTimezone} from "../../../../../../../function/_common";

const ActionCard = (props) => {
    const {data, refetchFollowUp} = props
    const {t} = useTranslation()
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const {addFollowUp} = useAddFollowUp()
    const {systemErrorNotification} = useAuthorize()
    const [currentTab, setCurrentTab] = useState<any>("followUp");
    const [schema, setSchema] = useState<any>(null)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [fileList, setFileList] = useState<any>([])
    const {disabledDate} = disableDateTime()
    const brandConfig = getConfigByName(BRAND_NAME);

    const labelCol = {span: 24, offset: 0}
    const {data: emailTemplate} = getAvailableEmailTemplate({
        isEmailVerified: data?.verifiedEmail,
    });
    const emailTemplateOption =
        emailTemplate?.getAvailableEmailTemplate?.data?.map((d) => {
            return {label: d?.displayName, value: d?._id};
        });

    const form = useForm({
        resolver: yupResolver(schema),
        mode: "all",
    });

    const onSubmit = async (value) => {
        try {
            setSubmitLoading(true)
            const {addFollowUp:response} = await addFollowUp(
                localStorage?.getItem("userId"),
                {
                    ...value,
                    followUpDateTime: value?.followUpDateTime ? formatDateTimeTimezone(value?.followUpDateTime, "formSubmit") : null,
                    followingLeads: data?._id,
                    type: currentTab,
                    followingType: "userLeads",
                    attachment: fileList?.map(d => d?.url || d?.response?.[0]?.link)
                }
            )

            if (response?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(addFollowUp?.errKey),
                    key: "addFollowUp",
                });
            } else if (response?.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(addFollowUp?.msg),
                    key: "addFollowUp",
                });
            } else {
                refetchFollowUp()

                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Create Follow Up Successfully"),
                    key: "addFollowUp",
                });
            }

            setFileList([])
            form.reset()
        } catch (e) {
            console.log(e);
            systemErrorNotification()
        } finally {
            setSubmitLoading(false)
        }
    }

    const items: TabsProps["items"] = [
        {
            label: t("Follow Up"),
            key: "followUp",
            children: (
                <FormProvider {...form}>
                    <form className="form-1">
                        <Row
                            gutter={[16, 16]}
                            className={"margin-top-0-75 margin-bottom-0-75"}
                        >
                            <Col span={24}>
                                <Space direction={"vertical"} style={{width: "100%"}}>
                                    <TextAreaInputFormItem
                                        name={"description"}
                                        labelCol={labelCol}
                                        label={t("Description")}
                                        placeholder={t("Description")}
                                        layout={verticalFormLayout}
                                        rows={3}
                                        required
                                    />
                                    <DatePickerInputForm
                                        name={"followUpDateTime"}
                                        labelCol={labelCol}
                                        disabledDate={disabledDate}
                                        label={t("Set up follow up time")}
                                        layout={verticalFormLayout}
                                        format={"YYYY-MM-DD HH:mm"}
                                        showTime={{format: "HH:mm"}}
                                        getPopupContainer={triggerNode => triggerNode.parentElement}
                                    />
                                    <Form.Item
                                        label={t("common.attachments")}
                                        labelCol={{span: 24}}
                                        labelAlign={"left"}
                                    >
                                        <Upload
                                            action={`${brandConfig?.backendURL}/api/upload/followUp/document`}
                                            onChange={e => setFileList(e.fileList)}
                                            multiple
                                            fileList={fileList}
                                        >
                                            <Button>{t("common.upload")}</Button>
                                        </Upload>
                                    </Form.Item>
                                </Space>
                            </Col>
                            <Button
                                className={"margin-left-auto"}
                                onClick={form.handleSubmit(onSubmit)}
                                loading={submitLoading}
                            >
                                {t("Submit")}
                            </Button>
                        </Row>
                    </form>
                </FormProvider>
            ),
        },
        {
            label: t("Email"),
            key: "email",
            children: (
                <FormProvider {...form}>
                    <form className="form-1">
                        <Row
                            gutter={[16, 16]}
                            className={"margin-top-0-75 margin-bottom-0-75"}
                        >
                            <Col span={24}>
                                <Space direction={"vertical"} style={{width: "100%"}}>
                                    <SelectFormItem
                                        name={"emailTemplateId"}
                                        label={t("common.emailTemplate")}
                                        labelCol={labelCol}
                                        placeholder={t("common.emailTemplate")}
                                        layout={verticalFormLayout}
                                        options={emailTemplateOption}
                                        optionFilterProp={"label"}
                                        showSearch
                                        required
                                    />
                                    <DatePickerInputForm
                                        name={"followUpDateTime"}
                                        label={t("Set up follow up time")}
                                        disabledDate={disabledDate}
                                        labelCol={labelCol}
                                        layout={verticalFormLayout}
                                        format={"YYYY-MM-DD HH:mm"}
                                        showTime={{format: "HH:mm"}}
                                        getPopupContainer={triggerNode => triggerNode.parentElement}
                                    />
                                </Space>
                            </Col>
                            <Button
                                className={"margin-left-auto"}
                                onClick={form.handleSubmit(onSubmit)}
                                loading={submitLoading}
                            >
                                {t("Submit")}
                            </Button>
                        </Row>
                    </form>
                </FormProvider>
            ),
        },
    ];

    const settingSchema = () => {
        if (currentTab === "email") {
            setSchema(yup.object().shape({
                emailTemplateId: yupFormSchema.string("emailTemplateId", {required: true}),
                followUpDateTime: yupFormSchema.string("followUpDateTime"),
            }))
        } else {
            setSchema(yup.object().shape({
                description: yupFormSchema.string("description", {required: true}),
                followUpDateTime: yupFormSchema.string("followUpDateTime"),
            }))
        }
    }

    useEffect(() => {
        settingSchema()
    }, [currentTab])

    return (
        <Card style={{width: "100%"}}>
            <h3>{t("Action to take")}</h3>
            <Tabs
                size={"small"}
                items={items}
                onTabClick={(tab) => {
                    setCurrentTab(tab);
                    form.reset();
                }}
                destroyInactiveTabPane
            />
        </Card>
    )
}

export default ActionCard