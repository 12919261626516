const domain = window.location.hostname

let BRAND_NAME: string

switch (domain) {
    case "localhost":
    case "secure.worthycodes.com":
        BRAND_NAME = "staging"
        break

    case "secure.excotrades.com":
        BRAND_NAME = "excotrades"
        break

    case "secure.ridgewaymarkets.com":
    case "secure.ridgewaymarkets.net":
        BRAND_NAME = "ridgewaymarkets"
        break

    case "secure.alliumtrades.com":
        BRAND_NAME = "alliumtrades"
        break

    case "secure.metapromarkets.com":
    case "securestg.metapromarkets.com":
        BRAND_NAME = "metapromarkets"
        break

    case "admin.fundamarkets.com":
    case "secure.fundamarkets.com":
    case "secure.fundamarkets.net":
        BRAND_NAME = "fundamarkets"
        break

    case "admin.minosfutures.com":
    case "secure.minosfutures.com":
    case "secure.minosbrokers.com":
    case "secure.minosbrokers.net":
        BRAND_NAME = "minosfutures"
        break

    case "admin.cronoscap.com":
    case "secure.cronoscap.com":
    case "secure.cronoscap.net":
        BRAND_NAME = "cronoscap"
        break

    case "localhost":
    case "admin.blueocex.com":
    case "secure.blueocex.com":
    case "secure.blueoceanfin.com":
        BRAND_NAME = "blueocean"
        break

    case "admin.antos.io":
    case "secure.antospnc.com":
    case "secure.antos.io":
    case "securestg.antos.io":
        BRAND_NAME = "antospncOld"
        break

    case "secure.antospnc.ai":
    case "securestg.antospnc.ai":
        BRAND_NAME = "antospnc"
        break

    case "secure.qvmarkets.com":
    case "securestg.qvmarkets.com":
        BRAND_NAME = "qvmarkets"
        break

    case "secure.dearc.ai":
        BRAND_NAME = "dearc"
        break
}

export {BRAND_NAME}
