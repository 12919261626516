import React from "react";
import {Table} from "antd";
import {expandedRowRenderColumn} from "./expandedRowRenderColumn";

const expandedRowRender = (record, index, indent, expanded) => {
  return (
    <Table
      dataSource={record?.currencyList}
      columns={expandedRowRenderColumn}
      pagination={false}
      rowKey={(record) =>
        record?.children ? record?.pspCurrency?._id : record?._id
      }
      rowClassName="expandedRow"
    />
  );
};

export default expandedRowRender;
