import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, Row, Select, Space} from "antd";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import {TbBracketsContain, TbEqual, TbEqualNot, TbMathEqualGreater, TbMathEqualLower, TbMathGreater, TbMathLower} from "react-icons/tb";

const ScheduleReportFilters = ({reportFields, onFieldsChange, restField, name, detail, conditionIndex}) => {
    const [filtersCount, setFiltersCount] = useState(1);

    const onAddFilters = () => {
        setFiltersCount(prev => prev + 1)
    }

    const onDeleteFilters = (index) => {
        const foundForm = detail.form.current
        const formData = foundForm.getFieldsValue()
        const newFormData = formData[`condition${detail?.batchId}`][conditionIndex]

        if (newFormData) {
            Object.keys(newFormData).map(d => {
                if (d.includes(`_${index}_`)) {
                    delete newFormData[d]
                }
            })

            formData[`condition${detail.batchId}`][conditionIndex] = Object.keys(newFormData).reduce((acc, key) => {
                const match = key.match(/^filters_(\d+)_(.*)/); // Match 'filters_X_' where X is a number
                if (match && parseInt(match[1]) > 1) {
                    const newNumber = parseInt(match[1]) - 1;    // Subtract 1 from the original number
                    const newKey = `filters_${newNumber}_${match[2]}`;  // Create the new key with the adjusted number
                    acc[newKey] = newFormData[key];
                } else {
                    acc[key] = newFormData[key];  // Keep other keys unchanged
                }
                return acc;
            }, {});

            foundForm.setFieldsValue({...formData});

            if (filtersCount > 1) setFiltersCount(prev => prev - 1)
        }
    }

    const reportOperators = [
        {label: <Space><TbEqual/>Equals to</Space>, value: "E", key: "="},
        {label: <Space><TbEqualNot/>Not Equals to</Space>, value: "NE", key: "≠"},
        {label: <Space><TbMathGreater/>Greater Than</Space>, value: "GT", key: ">"},
        {label: <Space><TbMathEqualGreater/>Greater Then or Equals to</Space>, value: "GTE", key: "≥"},
        {label: <Space><TbMathLower/>Less Than</Space>, value: "LT", key: "<"},
        {label: <Space><TbMathEqualLower/>Less Then or Equals to</Space>, value: "LTE", key: "≤"},
        {label: <Space><TbBracketsContain/>Contains</Space>, value: "CONTAINS", key: "[...]"},
    ]

    useEffect(() => {
        if (filtersCount === 1) {
            const formData = detail?.form?.current?.getFieldsValue()?.[`condition${detail?.batchId}`]?.[conditionIndex];

            if (formData) {
                const largestIndex = Object.keys(formData)
                    .reduce((max, key) => {
                        const match = key.match(/filters_(\d+)/);
                        return match ? Math.max(max, parseInt(match[1])) : max;
                    }, 0);

                setFiltersCount(largestIndex || 1);
            }
        }
    }, [detail]);

    return (
        <>
            {
                [...Array(filtersCount)]?.map((_, index) => (
                    <Row gutter={[16, 4]} key={index}>
                        <Col span={3}>
                        </Col>
                        <Col span={8}>
                            <Form.Item {...restField} name={[name, `filters_${index + 1}_fieldName`]}>
                                <Select
                                    style={{width: '100%'}}
                                    options={reportFields?.sort((a, b) => a?.uiName?.localeCompare(b?.uiName))?.map(d => ({label: d?.uiName, value: d?.reportFieldName}))}
                                    onChange={onFieldsChange}
                                    disabled={reportFields?.length === 0}
                                    allowClear
                                    showSearch
                                    optionFilterProp={"label"}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item {...restField} name={[name, `filters_${index + 1}_operator`]}>
                                <Select
                                    style={{width: '100%'}}
                                    options={reportOperators}
                                    onChange={onFieldsChange}
                                    dropdownMatchSelectWidth={false}
                                    optionLabelProp={"key"}
                                    disabled={reportFields?.length === 0}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item {...restField} name={[name, `filters_${index + 1}_value`]}>
                                <Input onKeyUp={onFieldsChange} disabled={reportFields?.length === 0}/>
                            </Form.Item>
                        </Col>
                        <Col span={3} className={"text-align-right"}>
                            <Button type={"link"}
                                    style={{padding: "unset"}}
                                    icon={<PlusOutlined/>}
                                    onClick={onAddFilters}
                                    disabled={reportFields?.length === 0}
                            />
                            <Button type={"link"}
                                    style={{padding: "unset"}}
                                    danger
                                    icon={<DeleteOutlined/>}
                                    disabled={index === 0}
                                    onClick={() => onDeleteFilters(index + 1)}
                            />
                        </Col>
                    </Row>
                ))
            }
        </>
    )
}

export default ScheduleReportFilters