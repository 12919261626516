import React, { useEffect, useState } from "react";
import {Modal} from "antd";
import {useTranslation} from "react-i18next";
import { getUserVoucherProgram, getUserVoucherProgramCount } from "../../../../api/graphql/voucherProgram";
import TableAntDesign from "../../../../components/table/tableAntDesign";
import { userVoucherProgramColumn } from "../function/tableColumn";

const VoucherProgramStatusModal = (props) => {
    const {mode, item, isOpen, closeModal, tableName} = props;
    const {t} = useTranslation();
    const getTitle = (): String => {
        switch (mode) {
            case "totalClaimed":
                return `${t("Voucher Program Total Claimed")}`;
            case "totalRedeemed":
                return `${t("Voucher Program Total Redeemed")}`;
            default:
                return `${t("Voucher Program Modal")}`;
        }
    };

    const [currentData, setCurrentData] = useState<any>([])
    const [orderBy, setOrderBy] = useState<any>("")
    const [limit, setLimit] = useState<any>(20)
    const [offset, setOffset] = useState<any>(0)
    const [filter, setFilter] = useState<any>(null)
    const {data:voucherProgramCount} = getUserVoucherProgramCount({filter: filter})

    useEffect(() => {
        async function fetchUserVoucherProgram(){
            let filter:any = null
            switch(mode){
                case "totalClaimed":
                    filter = {voucherProgramId: item?._id?.toString()}
                    setFilter(filter)
                    break
                case "totalRedeemed":
                    filter = {voucherProgramId: item?._id?.toString(), isVoucherIssued: true}
                    setFilter(filter)
                    break
            }
            const userVoucherProgram = await getUserVoucherProgram(filter, limit, offset, orderBy)
            if(userVoucherProgram?.getUserVoucherProgram?.data) {
                setCurrentData(userVoucherProgram?.getUserVoucherProgram?.data)
            }
            
        }

        fetchUserVoucherProgram()
    }, [item, mode, limit, offset])

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize)
        setOffset(limit * (page - 1))
    }


    return (
        <Modal
            getContainer="#voucherProgram"
            title={getTitle()}
            open={isOpen}
            width={"80%"}
            footer={null}
            closable={true}
            destroyOnClose
            onCancel={closeModal}
        >   
            <TableAntDesign
                data={currentData}
                tableName={tableName}
                columns={userVoucherProgramColumn}
                size={"small"}
                filter={setFilter}
                order={setOrderBy}
                loading={false}
                pagination={{
                    pageSize: limit,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                    total: voucherProgramCount,
                    showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                }}
            />
        </Modal>
    );
};

export default VoucherProgramStatusModal;
