import React from "react";

import {getModuleSetting} from "../../../api/graphql/moduleSetting";
import Module_1 from "./view/layout1";
import {getWebModuleSetting} from "../../../api/graphql/webModuleSetting";
import {getMobileModuleSetting} from "../../../api/graphql/mobileModuleSetting";

function Modules() {
    let layout = 1;
    let component;

    const mainModule = getModuleSetting({ filter: null });
    const webModule = getWebModuleSetting({ filter: {hasPosition: true} });
    const mobileModule = getMobileModuleSetting({ filter: {hasPosition: true} });

    switch (layout) {
        case 1:
            component = (
                <Module_1
                    data={mainModule?.data?.getModuleSetting?.data}
                    webData={webModule?.data?.getWebModuleSetting?.data}
                    mobileData={mobileModule?.data?.getMobileModuleSetting?.data}
                    loading={mainModule?.loading}
                    error={mainModule?.error}
                />
            );
            break;

        default:
            component = (
                <Module_1
                    data={mainModule?.data?.getModuleSetting?.data}
                    webData={webModule?.data?.getWebModuleSetting?.data}
                    mobileData={mobileModule?.data?.getMobileModuleSetting?.data}
                    loading={mainModule?.loading}
                    error={mainModule?.error}
                />
            );
            break;
    }

    return component;
}

export default Modules;
