import {useState} from "react";
import useAuthorize from "../../../../../_common/authorize";
import {getTradingAccountSubset} from "../../../../../api/graphql/tradingAccount";
import {getSecureInitialTradingAccountDetails} from "../../../../../api/graphql/initialData";
import { useNavigate } from "react-router-dom";

export const useGetTradingAccounts = () => {
    const [data, setData] = useState({data: [], total: 0});
    const [loading, setLoading] = useState<boolean>(false)
    const [params, setParams] = useState<any>({})
    const {systemErrorNotification} = useAuthorize()

    const fetchTradingAccounts = async (filter, filterType?, filterId?, orderBy?, limit?, offset?, startDate?, endDate?, dateKey?) => {
        setParams({filter, filterType, filterId, orderBy, limit, offset, startDate, endDate, dateKey})
        setLoading(true)
        try {
            const response = await getTradingAccountSubset(filter, filterType, filterId, orderBy, limit, offset, startDate, endDate, dateKey)
            setData(response?.getTradingAccountSubset)
        } catch (e) {
            systemErrorNotification()
        } finally {
            setLoading(false)
        }
    }

    const refetchTradingAccounts = () => {
        fetchTradingAccounts(params?.filter, params?.filterType, params?.filterId, params?.orderBy, params?.limit, params?.offset, params?.startDate, params?.endDate, params?.dateKey)
    }

    return [data, loading, fetchTradingAccounts, refetchTradingAccounts] as const
}

export const useGetSecureInitialTradingAccountDetails = () => {
    const [data, setData] = useState<any>({data: [], total: 0});
    const [loading, setLoading] = useState<boolean>(false)
    const [id, setId] = useState<any>("")
    const {baseErrorChecking, systemErrorNotification} = useAuthorize();
    const navigate = useNavigate()

    const fetchSecureInitialTradingAccountDetails = async (id) => {
        setId(id)
        setLoading(true)
        try {
            const response = await getSecureInitialTradingAccountDetails(id)
            if (response?.getSecureInitialTradingAccountDetails?.getTradingAccountDetail?.__typename === "BaseError") {
                baseErrorChecking(response?.getSecureInitialTradingAccountDetails?.getTradingAccountDetail)
                navigate("/account/trading-account")
            }
            setData(response?.getSecureInitialTradingAccountDetails)
        } catch (e) {
            systemErrorNotification()
        } finally {
            setLoading(false)
        }
    }

    const refetchSecureInitialTradingAccountDetails = () => {
        fetchSecureInitialTradingAccountDetails(id)
    }

    return [data, loading, fetchSecureInitialTradingAccountDetails, refetchSecureInitialTradingAccountDetails] as const
}