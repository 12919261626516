import React from "react";
import {Tag} from "antd"
import {getColumnSearchProps} from "../../../../components/table/function/filterTable";

export const columnsMt5 = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    align: "center",
    width: "20%",
    ...getColumnSearchProps(),
    render: (text) => <p>{text}</p>,
  },
  {
    title: "Symbols",
    dataIndex: "symbols",
    key: "symbols",
    width: "80%",
    ...getColumnSearchProps(),
    render: (text) => (
        <>
          {text?.length > 0 && text?.map((tag) => (
              <Tag key={tag} style={{margin: 2}}>
                {tag?.symbol}
              </Tag>
          ))}
        </>
    ),
  },
];

