import { Form, Checkbox  } from 'antd';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import FormErrors from './formErrors';

const CheckboxFormItem = (props) => {
    const {
        layout,
        label,
        name,
        value,
        externalErrorMessage,
        onChange,
        hint,
        disabled,
        required,
        labelCol,
        labelAlign,
        text,
        colon,
        className,
    } = props

    const { 
        register, 
        watch,
        setValue,
        formState: { touchedFields, isSubmitted, errors }
    } = useFormContext()

    const errorMessage = FormErrors.errorMessage(
        name,
        errors,
        touchedFields,
        isSubmitted,
        externalErrorMessage
    )
    
    const formHelperText = errorMessage || hint

    useEffect(() => {
        register(name)
    }, [register, name])

    useEffect(() => {
        if (value != null || value !== undefined) {
            setValue(name, value, { shouldValidate: true });
        }
    }, [value])
    
    return (
        <Form.Item
            {...layout}
            label={label}
            labelCol={labelCol}
            labelWrap={true}
            required={required}
            validateStatus={errorMessage?'error':'success'}
            help={formHelperText}
            labelAlign={labelAlign}
            colon={colon}
            className={className}
        >
            <Checkbox
                id={name}
                name={name}
                checked={watch(name)}
                onChange={(e)=>{
                    setValue(name, Boolean(e?.target?.checked), {shouldValidate: true})
                    onChange && onChange(Boolean(e?.target?.checked))
                }}
                disabled={disabled}
            >{text}</Checkbox>
        </Form.Item>
    )
}

CheckboxFormItem.defaultProps = {
    required: false,
    disabled: false,
    colon: false,
}

CheckboxFormItem.propTypes = {
    label: PropTypes.string,
    labelCol: PropTypes.object,
    name: PropTypes.string.isRequired,
    value: PropTypes.bool,
    externalErrorMessage: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    layout: PropTypes.object,
    hint: PropTypes.string,
    labelAlign: PropTypes.string,
    text: PropTypes.string,
    className: PropTypes.string,
}

export default CheckboxFormItem