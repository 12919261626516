import {gql, useMutation, useQuery} from "@apollo/client";
import {useSetRecoilState} from "recoil";
import {useTranslation} from "react-i18next";
import {notificationMessage} from "../../../recoil_state";

const GET_PSP_SETTING_DETAIL_DETAIL_COUNT = gql`
    query getPspSettingDetailCount($filter: PspSettingDetailSearchInput) {
        getPspSettingDetailCount(filter: $filter)
    }
`;

const getPspSettingDetailCount = ({filter}) => {
    try {
        const {loading, error, data} = useQuery(GET_PSP_SETTING_DETAIL_DETAIL_COUNT, {
            variables: {
                filter: {
                    pspSetting: filter?.pspSetting || null,
                    pspCurrency: filter?.pspCurrency || null,
                    paymentMethod: filter?.paymentMethod || null,
                    depositEnabled: filter?.depositEnabled || null,
                    withdrawalEnabled: filter?.withdrawalEnabled || null,
                },
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_PSP_SETTING_DETAIL_DETAIL_BY_ID = gql`
    query getPspSettingDetailByID($id: String!) {
        getPspSettingDetailByID(id: $id) {
            ... on PspSettingDetail {
                _id
                id
                pspSetting {
                    _id
                    id
                    position
                    name
                    displayName
                    processingTime
                    description
                    enabled
                    depositEnabled
                    withdrawalEnabled
                    isMerchantSetup
                    showBankDetails
                    depositDescription {
                        language
                        content
                    }
                    withdrawalDescription {
                        language
                        content
                    }
                    logo_light {
                        fileList
                        language
                        link
                    }
                    logo_dark {
                        fileList
                        language
                        link
                    }
                    logoSmall_light {
                        fileList
                        language
                        link
                    }
                    logoSmall_dark {
                        fileList
                        language
                        link
                    }
                    defaultLogo_light {
                        fileList
                        language
                        link
                    }
                    defaultLogo_dark {
                        fileList
                        language
                        link
                    }
                    defaultLogoSmall_light {
                        fileList
                        language
                        link
                    }
                    defaultLogoSmall_dark {
                        fileList
                        language
                        link
                    }
                    minDeposit
                    minWithdrawal
                    maxDeposit
                    maxWithdrawal
                    bankDetails {
                        beneficiaryBank
                        bankAddress
                        beneficiaryAccountName
                        beneficiaryAccountNumber
                        beneficiarySwiftCode
                        bankRoutingNumber
                        paymentDetails
                    }
                    localDepoBankDetails {
                        bankName
                        bankAddress
                        swiftCode
                        beneficiaryAccountName
                        accountNumber
                        beneficiaryAddress
                    }
                    isDeleted
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
                pspCurrency {
                    _id
                    id
                    currency
                    conversionRate
                    digits
                    fixedRate
                    depositEnabled
                    withdrawalEnabled
                    settlement
                    settlementFixed
                    availableBank {
                        bankCode
                        bankName
                    }
                }
                displayName
                depositEnabled
                withdrawalEnabled
                paymentMethod
                minDeposit
                minWithdrawal
                maxDeposit
                maxWithdrawal
                feeType
                feeRounding
                feeRoundingType
                formType
                cryptoWalletAddress
                depositFee
                depositFeeFixed
                depositFeeCondition
                depositFeeClient
                depositFeeFixedClient
                withdrawalFee
                withdrawalFeeFixed
                withdrawalFeeCondition
                withdrawalFeeClient
                withdrawalFeeFixedClient
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getPspSettingDetailById = (id) => {
    try {
        const {loading, error, data} = useQuery(GET_PSP_SETTING_DETAIL_DETAIL_BY_ID, {
            variables: {
                id: id,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_PSP_SETTING_DETAIL = gql`
    query getPspSettingDetail($filter: PspSettingDetailSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
        getPspSettingDetail(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on PspSettingDetailArray {
                data {
                    _id
                    id
                    pspSetting {
                        _id
                        id
                        position
                        name
                        displayName
                        processingTime
                        description
                        enabled
                        depositEnabled
                        withdrawalEnabled
                        isMerchantSetup
                        showBankDetails
                        depositDescription {
                            language
                            content
                        }
                        withdrawalDescription {
                            language
                            content
                        }
                        logo_light {
                            fileList
                            link
                            language
                        }
                        logo_dark {
                            fileList
                            link
                            language
                        }
                        logoSmall_light {
                            fileList
                            link
                            language
                        }
                        logoSmall_dark {
                            fileList
                            link
                            language
                        }
                        defaultLogo_light {
                            fileList
                            language
                            link
                        }
                        defaultLogo_dark {
                            fileList
                            language
                            link
                        }
                        defaultLogoSmall_light {
                            fileList
                            language
                            link
                        }
                        defaultLogoSmall_dark {
                            fileList
                            language
                            link
                        }
                        minDeposit
                        minWithdrawal
                        maxDeposit
                        maxWithdrawal
                        bankDetails {
                            beneficiaryBank
                            bankAddress
                            beneficiaryAccountName
                            beneficiaryAccountNumber
                            beneficiarySwiftCode
                            bankRoutingNumber
                            paymentDetails
                        }
                        localDepoBankDetails {
                            bankName
                            bankAddress
                            swiftCode
                            beneficiaryAccountName
                            accountNumber
                            beneficiaryAddress
                        }
                        createdBy {
                            username
                        }
                        updatedBy {
                            username
                        }
                        createdAt
                        updatedAt
                    }
                    pspCurrency {
                        _id
                        id
                        currency
                        conversionRate
                        digits
                        fixedRate
                        depositEnabled
                        withdrawalEnabled
                        settlement
                        settlementFixed
                        availableBank {
                            bankCode
                            bankName
                        }
                    }
                    displayName
                    depositEnabled
                    withdrawalEnabled
                    paymentMethod
                    minDeposit
                    minWithdrawal
                    maxDeposit
                    maxWithdrawal
                    feeType
                    feeRounding
                    feeRoundingType
                    formType
                    cryptoWalletAddress
                    depositFee
                    depositFeeFixed
                    depositFeeCondition
                    depositFeeClient
                    depositFeeFixedClient
                    withdrawalFee
                    withdrawalFeeFixed
                    withdrawalFeeCondition
                    withdrawalFeeClient
                    withdrawalFeeFixedClient
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getPspSettingDetail = ({filter}, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data, refetch} = useQuery(GET_PSP_SETTING_DETAIL, {
            variables: {
                filter: {
                    pspSetting: filter?.pspSetting || null,
                    pspCurrency: filter?.pspCurrency || null,
                    isPspEnabled: filter?.isPspEnabled || null,
                    paymentMethod: filter?.paymentMethod || null,
                    depositEnabled: filter?.depositEnabled || null,
                    withdrawalEnabled: filter?.withdrawalEnabled || null,
                    taskView: filter?.taskView || null
                },
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        const refreshQuery: any = () => {
            refetch();
        };

        return {loading, error, data, refreshQuery};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};


const GET_PSP_SETTING_DETAIL_DROPDOWN = gql`
    query getPspSettingDetailDropdown($filter: PspSettingDetailSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
        getPspSettingDetailDropdown(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on PspSettingDetailArray {
                data {
                    _id
                    id
                    pspSetting {
                        _id
                        id
                        name
                        displayName
                    }
                    pspCurrency {
                        _id
                        id
                        currency
                    }
                    displayName
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getPspSettingDetailDropdown = ({filter}, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data} = useQuery(GET_PSP_SETTING_DETAIL_DROPDOWN, {
            variables: {
                filter: {
                    pspSetting: filter?.pspSetting || null,
                    pspCurrency: filter?.pspCurrency || null,
                    isPspEnabled: filter?.isPspEnabled || null,
                    paymentMethod: filter?.paymentMethod || null,
                    depositEnabled: filter?.depositEnabled || null,
                    withdrawalEnabled: filter?.withdrawalEnabled || null,
                    taskView: filter?.taskView || null
                },
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UPDATE_PSP_SETTING_DETAIL = gql`
    mutation updatePspSettingDetail($id: String!, $input: PspSettingDetailInput) {
        updatePspSettingDetail(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on PspSettingDetail {
                _id
                id
                pspSetting {
                    _id
                    id
                    position
                    name
                    displayName
                    processingTime
                    description
                    enabled
                    depositEnabled
                    withdrawalEnabled
                    isMerchantSetup
                    showBankDetails
                    depositDescription {
                        language
                        content
                    }
                    withdrawalDescription {
                        language
                        content
                    }
                    logo_light {
                        fileList
                        language
                        link
                    }
                    logo_dark {
                        fileList
                        language
                        link
                    }
                    logoSmall_light {
                        fileList
                        language
                        link
                    }
                    logoSmall_dark {
                        fileList
                        language
                        link
                    }
                    defaultLogo_light {
                        fileList
                        language
                        link
                    }
                    defaultLogo_dark {
                        fileList
                        language
                        link
                    }
                    defaultLogoSmall_light {
                        fileList
                        language
                        link
                    }
                    defaultLogoSmall_dark {
                        fileList
                        language
                        link
                    }
                    minDeposit
                    minWithdrawal
                    maxDeposit
                    maxWithdrawal
                    bankDetails {
                        beneficiaryBank
                        bankAddress
                        beneficiaryAccountName
                        beneficiaryAccountNumber
                        beneficiarySwiftCode
                        bankRoutingNumber
                        paymentDetails
                    }
                    localDepoBankDetails {
                        bankName
                        bankAddress
                        swiftCode
                        beneficiaryAccountName
                        accountNumber
                        beneficiaryAddress
                    }
                    isDeleted
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
                pspCurrency {
                    _id
                    id
                    currency
                    conversionRate
                    digits
                    fixedRate
                    depositEnabled
                    withdrawalEnabled
                    settlement
                    settlementFixed
                    availableBank {
                        bankCode
                        bankName
                    }
                }
                displayName
                depositEnabled
                withdrawalEnabled
                paymentMethod
                minDeposit
                minWithdrawal
                maxDeposit
                maxWithdrawal
                feeType
                feeRounding
                feeRoundingType
                formType
                cryptoWalletAddress
                depositFee
                depositFeeFixed
                depositFeeCondition
                depositFeeClient
                depositFeeFixedClient
                withdrawalFee
                withdrawalFeeFixed
                withdrawalFeeCondition
                withdrawalFeeClient
                withdrawalFeeFixedClient
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useUpdatePspSettingDetailMutation = (): {
    updatePspSettingDetail: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const {t} = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updatePspSettingDetailMutation, {data, loading, error}] = useMutation(UPDATE_PSP_SETTING_DETAIL);

        const updatePspSettingDetail = (id, input) => {
            return updatePspSettingDetailMutation({
                variables: {
                    id: id,
                    input: {
                        pspSetting: input.pspSetting || null,
                        pspCurrency: input.pspCurrency || null,
                        depositEnabled: input.depositEnabled || null,
                        withdrawalEnabled: input.withdrawalEnabled || null,
                        paymentMethod: input.paymentMethod || null,
                        minDeposit: input.minDeposit || null,
                        minWithdrawal: input.minWithdrawal || null,
                        maxDeposit: input.maxDeposit || null,
                        maxWithdrawal: input.maxWithdrawal || null,
                        displayName: input.displayName || null,
                        feeType: input.feeType || null,
                        feeRounding: input.feeRounding || null,
                        feeRoundingType: input.feeRoundingType || null,
                        cryptoWalletAddress: input.cryptoWalletAddress || null,
                        depositFee: input.depositFee || null,
                        depositFeeFixed: input.depositFeeFixed || null,
                        depositFeeCondition: input.depositFeeCondition || null,
                        depositFeeClient: input.depositFeeClient || null,
                        depositFeeFixedClient: input.depositFeeFixedClient || null,
                        withdrawalFee: input.withdrawalFee || null,
                        withdrawalFeeFixed: input.withdrawalFeeFixed || null,
                        withdrawalFeeCondition: input.withdrawalFeeCondition || null,
                        withdrawalFeeClient: input.withdrawalFeeClient || null,
                        withdrawalFeeFixedClient: input.withdrawalFeeFixedClient || null,
                    },
                },
                update(cache, {data: {updatePSPSettingDetail}}) {
                    const typename = updatePSPSettingDetail?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updatePSPSettingDetail?.errKey),
                            key: "updatePSPSettingDetail",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updatePSPSettingDetail?.msg),
                            key: "updatePSPSettingDetail",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Successfully"),
                            key: "updatePSPSettingDetail",
                        });
                    }
                },
            });
        };

        return {updatePspSettingDetail, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            updatePspSettingDetail: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

export {getPspSettingDetailCount, getPspSettingDetailById, getPspSettingDetail, useUpdatePspSettingDetailMutation, getPspSettingDetailDropdown, GET_PSP_SETTING_DETAIL};
