import React from "react";
import {Tag} from "antd";
import {getColumnSearchProps} from "../../../../../components/table/function/filterTable";

export const columnsCategoryCT = [
    {
        title: "Parent",
        dataIndex: "parent",
        key: "parent",
        width: "50%",
        ...getColumnSearchProps(),
        render: (text) => <p>{text?.categoryName}</p>,
    },
    {
        title: "Category",
        dataIndex: "categoryName",
        key: "categoryName",
        ...getColumnSearchProps(),
        render: (text, record) => <p>{text || record?.categoryName}</p>,
    },
    {
        title: "Type",
        dataIndex: "type",
        key: "type",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
]

export const columnsCT = [
    {
        title: "Category",
        dataIndex: "category",
        key: "category",
        ...getColumnSearchProps(),
        width: "20%",
        render: (text) => <p>{text?.categoryName}</p>,
    },
    {
        title: "Symbol",
        dataIndex: "symbol",
        key: "symbol",
        render: (text, record) => {
            return (
                text?.length > 0 && record?.symbol?.map((tag,i) => (
                    <Tag key={i} style={{margin: 2}}>
                        {tag?.name}
                    </Tag>
                ))
            )
        },
    },
];
