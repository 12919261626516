import React from "react";
import {useRecoilState} from "recoil";
import PlatformDownload_1 from "./view/layout1";
import {columns} from "./function/tableColumn";
import {getPlatform} from "../../../api/graphql/platform";
import {selfPermissionObj} from "../../../../recoil_state";
import { getLanguage } from "../../../api/graphql/language";

function PlatformDownload() {
  let layout = 1;
  let component;
  const [selfPermission] = useRecoilState<any>(selfPermissionObj);
  const platformDownloadPermission =
    selfPermission?.brandSetting?.platformDownload;
  const platform = getPlatform({ filter: null });
  const { languageData} = getLanguage({
    filter: null,
  });
  switch (layout) {
    case 1:
      component = (
        <PlatformDownload_1
          platform={platform?.data?.getPlatform?.data}
          columns={columns}
          loading={platform?.loading}
          error={platform?.error}
          platformDownloadPermission={platformDownloadPermission}
          languageData={languageData?.getLanguage?.data}
        />
      );
      break;

    default:
      component = (
        <PlatformDownload_1
          platform={platform?.data?.getPlatform?.data}
          columns={columns}
          loading={platform?.loading}
          error={platform?.error}
          platformDownloadPermission={platformDownloadPermission}
          languageData={languageData?.data?.getLanguage}
        />
      );
      break;
  }

  return component;
}

export default PlatformDownload;
