import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Checkbox, Form, Modal, Select, Space} from "antd";
import InfoModal from "../activities/infoModal";

const RedeemVoucherModal = ({isOpen, handleClose, item, applyVoucherFunction, isApplyVoucherLoading}) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();

    const [disclaimerChecked, setDisclaimerChecked] = useState(false);
    const disclaimerNeeded = ["tradingVoucher, bonusVoucher"];
    const [checkedTerms, setCheckedTerms] = useState(false);
    const [infoModalIsOpen, setInfoModalIsOpen] = useState(false);
    const [info, setInfo] = useState("");

    const toggleInfoModal = () => {
        setInfoModalIsOpen((prevState) => !prevState);
    };

    const handleOk = async (value) => {
        const res = await applyVoucherFunction(item?._id, {tradingAccountId: item?.tradingAccountId?.[0]?._id || value?.tradingAcc});
    };

    useEffect(() => {
        form.resetFields();
        setCheckedTerms(false);
    }, [isOpen]);

    return (
        <>
            <Modal
                open={isOpen}
                onCancel={() => handleClose()}
                title={t("reward.redeemVoucher")}
                okText={t("common.confirm")}
                onOk={() => form.submit()}
                okButtonProps={{className: "primary_btn", type: "default", disabled: item?.voucherId?.termAndConditionEnabled && !checkedTerms}}
                cancelButtonProps={{className: "secondary_btn", disabled: isApplyVoucherLoading}}
                cancelText={t("common.cancel")}
                confirmLoading={isApplyVoucherLoading}
            >
                {!item?.tradingAccountId && (
                    <Form form={form} onFinish={handleOk} layout={"vertical"}>
                        <Form.Item
                            className={"mb-1"}
                            label={t("mission.label.selectTradingAccount")}
                            name={`tradingAcc`}
                            rules={[
                                {
                                    required: true,
                                    message: t("errorMsg.required.pleaseFillIn", {field: t("user.tradingAccount")})?.toString(),
                                },
                            ]}
                        >
                            <Select options={item?.canClaimForTradingAccount?.map((d) => ({label: `${d?.accountId} - ${d?.product?.name} (${d?.mode})`, value: d?._id}))} />
                        </Form.Item>
                    </Form>
                )}
                {disclaimerNeeded?.includes(item?.voucherId?.rewardType) && !item?.voucherId?.voucherStackable && (
                    <>
                        {item?.voucherId?.rewardType === "tradingVoucher" ? (
                            <p className={"secondary-text"}>
                                {t("reward.currentTradingVoucher")}: <span>{item?.tradingAccountId?.tradingVoucher}</span>
                            </p>
                        ) : (
                            <p className={"secondary-text"}>
                                {t("reward.currentCredit")}: <span>{item?.tradingAccountId?.credit}</span>
                            </p>
                        )}
                        <Checkbox className={"mt-3"} checked={disclaimerChecked} onChange={(val) => setDisclaimerChecked(val?.target?.checked)}>
                            <p>{t(`reward.voucherDisclaimer.${item?.voucherId?.rewardType}`)}</p>
                        </Checkbox>
                    </>
                )}
                {item?.voucherId?.termAndConditionEnabled && (
                    <Space>
                        <Checkbox checked={checkedTerms} onChange={(e) => setCheckedTerms(e?.target?.checked)} />
                        <a
                            onClick={() => {
                                toggleInfoModal();
                                setInfo("terms");
                            }}
                        >
                            {t("common.readAndAgreed_termsAndConditions")}
                        </a>
                    </Space>
                )}
            </Modal>
            <InfoModal isOpen={infoModalIsOpen} handleClose={toggleInfoModal} item={item?.voucherId} info={info} />
        </>
    );
};

export default RedeemVoucherModal;
