import React, {useEffect, useState} from "react";
import TableAntDesign from "../../../../../../../components/table/tableAntDesign";
import {columnTradingAccount} from "../../../function/tableColumn";
import {useRecoilState, useSetRecoilState} from "recoil";
import {
    tradingAccountDateKeySearchState,
    tradingAccountEndTimeSearchState,
    tradingAccountFilterState,
    tradingAccountLimitState,
    tradingAccountOffsetState,
    tradingAccountOrderBySearchState,
    tradingAccountStartTimeSearchState
} from "../../../state";
import {WarningTwoTone} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {selfPermissionObj} from "../../../../../../../../recoil_state";

function TradingAccountTable_1(props) {
    const {
        data,
        total,
        server,
        tableName,
        loading,
        setBatchUpdateRecord,
        batchUpdateRecord,
        isSpreadGroup
    } = props
    const {t} = useTranslation()

    const [tradingAccountLimit, setTradingAccountLimit] = useRecoilState(tradingAccountLimitState)
    const setTradingAccountOffset = useSetRecoilState(tradingAccountOffsetState)
    const [tradingAccountFilter, setTradingAccountFilter] = useRecoilState(tradingAccountFilterState)
    const setTradingAccountStartTimeSearch = useSetRecoilState(tradingAccountStartTimeSearchState);
    const setTradingAccountEndTimeSearch = useSetRecoilState(tradingAccountEndTimeSearchState);
    const setTradingAccountDateKeySearch = useSetRecoilState(tradingAccountDateKeySearchState);
    const setTradingAccountOrderBySearch = useSetRecoilState(tradingAccountOrderBySearchState);
    const [dataLatest, setDataLatest] = useState<any>([])
    const [excludedCondition, setExcludedCondition] = useState<any>([])

    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const tradingAccountPermission = selfPermission?.tradingAccount?.account;

    const columns = columnTradingAccount(isSpreadGroup)

    const navigate = useNavigate();

    const onChangePageHandler = (page, pageSize) => {
        setTradingAccountLimit(pageSize)
        setTradingAccountOffset(tradingAccountLimit * (page - 1))
    }

    const processData = async () => {
        if (data) {
            const latestRecord: any = await Promise.all(data?.map((d) => {

                    let accountId: any;

                    // if (d?.platform === "xTrader") {
                    //     accountId = d?.platformAccountId;
                    // } else {
                        accountId = d?.accountId;
                    // }

                    return {
                        ...d,
                        user: <a onClick={() => window.open("/users/client/detail?id=" + d?.user, "_blank")}>
                            {d?.username}{d?.isSuspicious === true &&
                        <WarningTwoTone twoToneColor={"#FFCC00"} style={{marginLeft: 4}}/>}
                        </a>,
                        accountId: <a onClick={() => window.open("/account/trading-account/detail?id=" + d?.parentId, "_blank")}>
                            {accountId}{d?.isSuspicious === true &&
                        <WarningTwoTone twoToneColor={"#FFCC00"} style={{marginLeft: 4}}/>}
                        </a>,
                        // isLoginEnabled: d?.user?.isLoginEnabled,
                        // productObj: d?.product,
                        product: tradingAccountPermission?.view?.product ? d?.productName : "-",
                        productType: tradingAccountPermission?.view?.product ? d?.productType : "-",
                        server: tradingAccountPermission?.view?.product ? d?.server : "-",
                        spreadGroup: tradingAccountPermission?.view?.spreadGroup ? d?.spreadGroupName : "-",
                        leverage: tradingAccountPermission?.view?.leverage ? d?.leverage : "-",
                        userId: d?.user?._id
                    }
                })
            )
            setDataLatest(latestRecord)
        }

    }

    useEffect(() => {
        processData()
    }, [data])

    const onRowSelection = (selectedRowKeys, selectedRows) => {
        setBatchUpdateRecord(selectedRows);

        let excludedAry: any = [];
        for (const row of selectedRows) {
            if (row?.server) {
                excludedAry.push({
                    field: "server",
                    operator: "!=",
                    value: row?.server
                })
            }
        }

        setExcludedCondition(excludedAry)
    };

    return (
        <div className={"margin-top-0-75"}>
            <TableAntDesign data={dataLatest}
                            tableName={tableName}
                            columns={[...columns]}
                            size={"small"}
                            // server={server}
                            loading={loading}
                            filter={setTradingAccountFilter}
                            filterValue={tradingAccountFilter}
                            startDate={setTradingAccountStartTimeSearch}
                            endDate={setTradingAccountEndTimeSearch}
                            dateKey={setTradingAccountDateKeySearch}
                            order={setTradingAccountOrderBySearch}
                            onRowSelectionHandler={onRowSelection}
                            rowKey={"parentId"}
                            selectedRowKeys={batchUpdateRecord?.map((d) => d?.parentId)}
                            selectionType={isSpreadGroup ? null : "checkbox"}
                            disabledCheckboxProps={excludedCondition}
                            pagination={{
                                pageSize: tradingAccountLimit,
                                showSizeChanger: true,
                                position: "bottomCenter",
                                total: total,
                                onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                                showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                            }}
            />
        </div>
    )
};

export default TradingAccountTable_1;
