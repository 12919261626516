import React, {useState} from "react";
import TableAntDesign from "../../../../../components/table/tableAntDesign";
import {columnCron} from "../../function/tableColumn";
import {useTranslation} from "react-i18next";
import {Button, Col, Row, Space} from "antd";
import EditTableUserPreferenceButton from "../../../../../components/table/function/editTableUserPreferenceButton";
import {useRunCronMutation, useUpdateCronMutation} from "../../../../../api/graphql/cron";
import useAuthorize from "../../../../../_common/authorize";
import {useRecoilState, useSetRecoilState} from "recoil";
import {notificationMessage, selfPermissionObj} from "../../../../../../recoil_state";

const Cron_1 = (props) => {

    const {
        refetchCronData,
        data,
        total,
        limit,
        setLimit,
        setOffset,
        setOrder,
        setStartDate,
        setEndDate,
        setDateKey,
        setFilter
    } = props

    const {t} = useTranslation()

    const {systemErrorNotification} = useAuthorize()
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const cronPermission = selfPermission?.systemSetting?.cron;

    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const tableName = "Cron Setting"

    const columns = columnCron(refetchCronData)

    const {runCron} = useRunCronMutation()
    const {updateCron} = useUpdateCronMutation()

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize)
        setOffset(limit * (page - 1))
    }

    const CronBtn = ({record}) => {
        const [btnLoading, setBtnLoading] = useState(false);
        const runCronFunction = async (name) => {

            try {
                await setBtnLoading(true); // Add id to btnLoading

                const response = await runCron(name);

                if (response?.data?.runCron.__typename === "Cron") {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: t("Cron processing"),
                        key: "runCron",
                    });
                } else if (response?.data?.runCron.__typename === "BaseError") {
                    setNotificationMessage({
                        status: "error",
                        title: "",
                        msg: t(response?.data?.runCron?.errKey),
                        key: "runCron",
                    });
                } else {
                    setNotificationMessage({
                        status: "info",
                        title: "",
                        msg: t(response?.data?.runCron?.msg),
                        key: "runCron",
                    });
                }

            } catch (e) {
                systemErrorNotification()
            } finally {
                refetchCronData()
                setBtnLoading(false);
            }

        }

        return (
            <Button size={"small"} loading={btnLoading} disabled={btnLoading}
                    onClick={() => runCronFunction(record?.name)}>
                Run Cron
            </Button>
        )
    }

    const UpdateCronStatus = ({record}) => {
        const [btnLoading, setBtnLoading] = useState(false);
        const updateCronFunction = async (name) => {

            try {
                await setBtnLoading(true); // Add id to btnLoading

                const response = await updateCron(record?.name, {status: name});

                if (response?.data?.updateCron.__typename === "Cron") {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: t("Cron Updated"),
                        key: "updateCron",
                    });
                } else if (response?.data?.updateCron.__typename === "BaseError") {
                    setNotificationMessage({
                        status: "error",
                        title: "",
                        msg: t(response?.data?.updateCron?.errKey),
                        key: "updateCron",
                    });
                } else {
                    setNotificationMessage({
                        status: "info",
                        title: "",
                        msg: t(response?.data?.updateCron?.msg),
                        key: "updateCron",
                    });
                }

            } catch (e) {
                systemErrorNotification()
            } finally {
                refetchCronData()
                setBtnLoading(false);
            }

        }

        return (
            <>
                {
                    record?.status !== "unsubscribe" ? (
                        <Button size={"small"} danger loading={btnLoading}
                                onClick={() => updateCronFunction("unsubscribe")}>
                            Unsubscribe
                        </Button>
                    ) : (
                        <Button size={"small"} loading={btnLoading}
                                onClick={() => updateCronFunction("in_queue")}>
                            Subscribe
                        </Button>
                    )
                }
            </>
        )

    }

    return (
        <div className={"margin-top-0-75"}>
            <Row justify="space-between" style={{marginBottom: "15px"}}>
                <Col span={17}>
                    <h1>{t(tableName)}</h1>
                </Col>
                <Space>
                    <EditTableUserPreferenceButton tableName={tableName} tableColumn={columns}/>
                </Space>
            </Row>
            <TableAntDesign data={data}
                            tableName={tableName}
                            columns={[...columns, {
                                title: t("common.action"),
                                key: "action",
                                width: 100,
                                align: "left",
                                render: (_, record: any) => {
                                    return (
                                        <>
                                            <Space>
                                                {cronPermission?.run && <CronBtn record={record}/>}
                                                <UpdateCronStatus record={record}/>
                                            </Space>

                                        </>
                                    )
                                },
                            }]}
                            size={"small"}
                            server={null}
                            loading={false}
                            filter={setFilter}
                            startDate={setStartDate}
                            endDate={setEndDate}
                            dateKey={setDateKey}
                            order={setOrder}
                            pagination={{
                                pageSize: limit,
                                showSizeChanger: true,
                                position: "bottomCenter",
                                total: total,
                                onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                                showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                            }}
            />
        </div>
    )
}

export default Cron_1