// myIcon.js
import React, { memo } from 'react';
const SuccessIcon = (props) => (
    <svg {...props} viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
        <path d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z" fill="#25CE69"/>
        <path d="M23.88 42.732C23.5633 42.7323 23.2496 42.6698 22.9572 42.5483C22.6647 42.4268 22.3992 42.2486 22.176 42.024L13.776 33.624C13.3631 33.1668 13.1418 32.5682 13.1581 31.9524C13.1743 31.3365 13.4269 30.7504 13.8633 30.3156C14.2997 29.8807 14.8867 29.6303 15.5026 29.6162C16.1185 29.6022 16.7161 29.8255 17.1719 30.24L23.904 36.9599L42.888 17.976C43.3383 17.5257 43.9491 17.2726 44.586 17.2726C45.2229 17.2726 45.8336 17.5257 46.2839 17.976C46.7343 18.4263 46.9873 19.0372 46.9873 19.674C46.9873 20.3109 46.7343 20.9216 46.2839 21.3719L25.5959 42.0479C25.3693 42.2696 25.1009 42.4442 24.8064 42.5616C24.5118 42.679 24.197 42.737 23.88 42.732Z" fill="white"/>

    </svg>

);
export default memo(SuccessIcon);
