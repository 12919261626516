import React from "react";
import NavigationRouteSettingTable from "./navigationRouteSettingTable";

const NavigationRouteSettingPage = (props) => {
  const { permission } = props;

  return (
    <div className={"margin-top-0-75"}>
      <NavigationRouteSettingTable permission={permission} type="webTradersRoom" />
      <NavigationRouteSettingTable permission={permission} type="mobileTradersRoom" />
      <NavigationRouteSettingTable permission={permission} type="webAdminPanel" />
      <NavigationRouteSettingTable permission={permission} type="mobileAdminPanel" />
      <NavigationRouteSettingTable permission={permission} type="external" />
    </div>
  );
};

export default NavigationRouteSettingPage;
