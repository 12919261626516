import React, {useEffect, useMemo} from "react"
import {ConfigProvider, notification, theme} from "antd"
import {useTranslation} from "react-i18next"
import {useStore, withStore} from "react-context-hook"
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil"
import {notificationMessage, pushNotificationServerSettingState, userLogoutState, userToken} from "./recoil_state"
import RouterComponent from "./shared/routers"
import InitialDataSetup from "./InitialDataSetup"
import useAuthorize from "./shared/_common/authorize"
import Helmet from "react-helmet"
import {getToken, onMessage} from "firebase/messaging"
import {initializeFirebase, messaging} from "./config/firebaseConfig"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import customParseFormat from "dayjs/plugin/customParseFormat"
import localeData from "dayjs/plugin/localeData"
import weekday from "dayjs/plugin/weekday"
import weekOfYear from "dayjs/plugin/weekOfYear"
import weekYear from "dayjs/plugin/weekYear"
import quarterOfYear from "dayjs/plugin/quarterOfYear"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import {brandDetailsState} from "./shared/pages/users/_common/state"
import {updateUserPushNotificationToken} from "./shared/api/graphql/pushNotification"
import {useGetHeaderPushNotificationLogByUserId} from "./shared/pages/announcement/function/common"
import {checkBrowserFirebaseCompatibility} from "./shared/function/_common"

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
dayjs.extend(quarterOfYear)
dayjs.extend(utc)
dayjs.extend(timezone)

function App() {
    const {t} = useTranslation()
    const userTokenData = useRecoilValue(userToken)
    const {trackUserLogout, handleTokenExpiration, trackUserActivity, startTokenExpirationCheck, redirectToClient, getClientDeviceInfo} = useAuthorize()

    // const errorMessageObj = useRecoilValue(errorMessage);
    const notificationMessageObj = useRecoilValue(notificationMessage)
    const [userLogout, setUserLogout] = useRecoilState(userLogoutState)
    const [api, contextHolder] = notification.useNotification()
    const currentPath = window.location.pathname
    const [themeColor, setThemeColor] = useStore("theme", "light")
    const brandSetting = useRecoilValue<any>(brandDetailsState)
    const setNotificationMessage = useSetRecoilState(notificationMessage)
    const [pushNotificationData, pushNotificationTotal, fetchPushNotificationLog, pushNotificationLoading] = useGetHeaderPushNotificationLogByUserId()
    const userIdStorage = localStorage.getItem("userId")
    const PNServerSetting = useRecoilValue<any>(pushNotificationServerSettingState)

    const openNotificationWithIcon = (type, msg, title, key, icon = null) => {
        api[type]({
            icon: icon,
            message: t(title),
            description: msg,
            key: key,
            // duration: key === "sessionExpired" ? 0 : 4.5,
            duration: 4.5,
        })
    }

    useEffect(() => {
        redirectToClient(currentPath)
    }, [currentPath])

    useEffect(() => {
        trackUserLogout()
    }, [userLogout])

    // useEffect(() => {
    //   if (errorMessageObj?.errKey) {
    //     openNotificationWithIcon("error", errorMessageObj?.errKey, "");
    //   } else if (errorMessageObj?.msg) {
    //     openNotificationWithIcon("info", errorMessageObj?.msg, "");
    //   }
    // }, [errorMessageObj]);

    const runVerifyingFunction = async () => {
        if (userTokenData) {
            // await trackUserActivity(60 * 60 * 1000, handleTokenExpiration);
            await startTokenExpirationCheck(60 * 30 * 1000)
        } else if (!localStorage.getItem("a_t")) {
            setUserLogout(true)
        }
    }

    useEffect(() => {
        runVerifyingFunction()
    }, [userTokenData])

    // useEffect(() => {
    //     if (!userLogout) {
    //         startTokenExpirationCheck(60 * 30 * 1000);
    //     }
    // }, [userLogout]);

    useEffect(() => {
        const {status, msg, title, key, icon} = notificationMessageObj
        if (msg) {
            openNotificationWithIcon(status, msg, title, key, icon)
        }
    }, [notificationMessageObj])

    // Security quick fix
    // useEffect(() => {
    //     if (!localStorage.getItem("F")) {
    //         handleTokenExpiration()
    //         localStorage.setItem("F", "dHJ1ZQ==");
    //     }
    // }, []);

    async function getUpdateFCMToken() {
        try {
            let userId = localStorage.getItem("userId")
            const token = await getToken(messaging, {
                vapidKey: PNServerSetting?.firebase?.vapid,
            })
            localStorage.setItem("fcmToken", token)
            if (userId) {
                await updateUserPushNotificationToken({
                    user: userId,
                    token: token,
                    provider: "firebase",
                    platform: "web",
                })
            }
        } catch (error) {}
    }

    const initializeFirebaseApp = async () => {
        try {
            await initializeFirebase(PNServerSetting?.firebase?.config)
            requestNotificationPermission()
        } catch (error) {}
    }

    useEffect(() => {
        if (Object.keys(PNServerSetting)?.length > 0) {
            initializeFirebaseApp()
        }
    }, [PNServerSetting])

    async function requestNotificationPermission() {
        try {
            const {browser} = await getClientDeviceInfo()
            if (browser === "Safari") return

            if (!checkBrowserFirebaseCompatibility() || !messaging) {
                console.error("Your browser does not support Firebase Messaging or Messaging is not initialized.")
                return
            }

            const permission = Notification.permission
            if (permission === "granted") {
                getUpdateFCMToken()
            } else if (permission !== "denied") {
                const result = await Notification.requestPermission()
                if (result === "granted") {
                    getUpdateFCMToken()
                } else {
                    console.warn("User denied notification permission.")
                }
            } else {
                console.warn("User denied notification permission.")

            }
        } catch (error) {
            console.error("An error occurred while requesting notification permission:", error)
        }
    }

    useEffect(() => {
        if (messaging) {
            onMessage(messaging, (payload) => {
                setNotificationMessage({
                    status: "open",
                    title: payload?.notification?.title as string,
                    msg: (
                        <>
                            {payload?.notification?.body}
                            {payload?.notification?.image && <img src={payload?.notification?.image} />}
                        </>
                    ),
                    icon: <img src={payload?.notification?.icon} />,
                    key: null,
                })

                fetchPushNotificationLog()
            })
        }
    }, [messaging])

    const themeConfig: any = {
        algorithm: themeColor === "dark" ? theme.darkAlgorithm : theme.defaultAlgorithm,
        token: {
            colorPrimary: "#5F75E8",
            colorDangerItemText: "#F83535",
            fontSize: 12,
            borderRadius: 4,
            controlHeight: 26,
        },
        components: {
            Menu: {
                itemSelectedColor: "#5F75E8",
            },
            // Table: {
            //     cellPaddingBlock: 4,
            //     cellFontSize: 12,
            // },
            Button: {
                contentFontSizeSM: 10,
            },
            Form: {
                colon: false,
                labelWrap: true,
            },
        },
    }

    return (
        <>
            <ConfigProvider theme={themeConfig}>
                <Helmet>
                    <link rel="stylesheet" href={window.location.origin + `/brand/color/${themeColor}.css`} />
                    <link rel="icon" href={brandSetting?.siteFavicon} />
                    <link rel="apple-touch-icon" href={brandSetting?.siteFavicon} />
                    <title>{brandSetting?.companyName}</title>
                    <meta name="description" content={brandSetting?.companyName} />
                </Helmet>
                {contextHolder}
                <InitialDataSetup />
                <RouterComponent />
            </ConfigProvider>
        </>
    )
}

export default withStore(App)
