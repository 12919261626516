import React from "react";
import {getColumnSearchProps} from "../../../../../components/table/function/filterTable";

export const columnsMT5 = [
    {
        title: "Symbol",
        dataIndex: "symbol",
        key: "symbol",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Path",
        dataIndex: "path",
        key: "path",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "ISIN",
        dataIndex: "isin",
        key: "isin",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Description",
        dataIndex: "description",
        key: "description",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "International",
        dataIndex: "international",
        key: "international",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Category",
        dataIndex: "category",
        key: "category",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Exchange",
        dataIndex: "exchange",
        key: "exchange",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "CFI",
        dataIndex: "cfi",
        key: "cfi",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Sector",
        dataIndex: "sector",
        key: "sector",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Industry",
        dataIndex: "industry",
        key: "industry",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Country",
        dataIndex: "country",
        key: "country",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Basis",
        dataIndex: "basis",
        key: "basis",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Source",
        dataIndex: "source",
        key: "source",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Page",
        dataIndex: "page",
        key: "page",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Currency Base",
        dataIndex: "currencyBase",
        key: "currencyBase",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Currency Base Digits",
        dataIndex: "currencyBaseDigits",
        key: "currencyBaseDigits",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Currency Profit",
        dataIndex: "currencyProfit",
        key: "currencyProfit",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Currency Profit Digits",
        dataIndex: "currencyProfitDigits",
        key: "currencyProfitDigits",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Currency Margin",
        dataIndex: "currencyMargin",
        key: "currencyMargin",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Currency Margin Digits",
        dataIndex: "currencyMarginDigits",
        key: "currencyMarginDigits",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Color",
        dataIndex: "color",
        key: "color",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Color Background",
        dataIndex: "colorBackground",
        key: "colorBackground",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Digits",
        dataIndex: "digits",
        key: "digits",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Point",
        dataIndex: "point",
        key: "point",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Multiply",
        dataIndex: "multiply",
        key: "multiply",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Tick Flags",
        dataIndex: "tickFlags",
        key: "tickFlags",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Tick Book Depth",
        dataIndex: "tickBookDepth",
        key: "tickBookDepth",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Filter Soft",
        dataIndex: "filterSoft",
        key: "filterSoft",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Filter Soft Ticks",
        dataIndex: "filterSoftTicks",
        key: "filterSoftTicks",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Filter Hard",
        dataIndex: "filterHard",
        key: "filterHard",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Filter Hard Ticks",
        dataIndex: "filterHardTicks",
        key: "filterHardTicks",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Filter Discard",
        dataIndex: "filterDiscard",
        key: "filterDiscard",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Filter Spread Max",
        dataIndex: "filterSpreadMax",
        key: "filterSpreadMax",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Filter Spread Min",
        dataIndex: "filterSpreadMin",
        key: "filterSpreadMin",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Filter Gap",
        dataIndex: "filterGap",
        key: "filterGap",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Filter Gap Ticks",
        dataIndex: "filterGapTicks",
        key: "filterGapTicks",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Trade Mode",
        dataIndex: "tradeMode",
        key: "tradeMode",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Calc Mode",
        dataIndex: "calcMode",
        key: "calcMode",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Exec Mode",
        dataIndex: "execMode",
        key: "execMode",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "GTC Mode",
        dataIndex: "gtcMode",
        key: "gtcMode",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Fill Flags",
        dataIndex: "fillFlags",
        key: "fillFlags",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Expir Flags",
        dataIndex: "expirFlags",
        key: "expirFlags",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Spread",
        dataIndex: "spread",
        key: "spread",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Spread Balance",
        dataIndex: "spreadBalance",
        key: "spreadBalance",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Spread Diff",
        dataIndex: "spreadDiff",
        key: "spreadDiff",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Spread Diff Balance",
        dataIndex: "spreadDiffBalance",
        key: "spreadDiffBalance",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Tick Value",
        dataIndex: "tickValue",
        key: "tickValue",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Tick Size",
        dataIndex: "tickSize",
        key: "tickSize",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Contract Size",
        dataIndex: "contractSize",
        key: "contractSize",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Stops Level",
        dataIndex: "stopsLevel",
        key: "stopsLevel",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Freeze Level",
        dataIndex: "freezeLevel",
        key: "freezeLevel",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Quotes Timeout",
        dataIndex: "quotesTimeout",
        key: "quotesTimeout",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Volume Min",
        dataIndex: "volumeMin",
        key: "volumeMin",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Volume Min Ext",
        dataIndex: "volumeMinExt",
        key: "volumeMinExt",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Volume Max",
        dataIndex: "volumeMax",
        key: "volumeMax",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Volume Max Ext",
        dataIndex: "volumeMaxExt",
        key: "volumeMaxExt",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Volume Step",
        dataIndex: "volumeStep",
        key: "volumeStep",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Volume Step Ext",
        dataIndex: "volumeStepExt",
        key: "volumeStepExt",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Volume Limit",
        dataIndex: "volumeLimit",
        key: "volumeLimit",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Volume Limit Ext",
        dataIndex: "volumeLimitExt",
        key: "volumeLimitExt",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Margin Flags",
        dataIndex: "marginFlags",
        key: "marginFlags",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Margin Initial",
        dataIndex: "marginInitial",
        key: "marginInitial",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Margin Maintenance",
        dataIndex: "marginMaintenance",
        key: "marginMaintenance",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Margin Long",
        dataIndex: "marginLong",
        key: "marginLong",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Margin Short",
        dataIndex: "marginShort",
        key: "marginShort",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Margin Limit",
        dataIndex: "marginLimit",
        key: "marginLimit",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Margin Stop",
        dataIndex: "marginStop",
        key: "marginStop",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Margin Stop Limit",
        dataIndex: "marginStopLimit",
        key: "marginStopLimit",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Margin Hedged",
        dataIndex: "marginHedged",
        key: "marginHedged",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Margin Rate Currency",
        dataIndex: "marginRateCurrency",
        key: "marginRateCurrency",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Margin Rate Liquidity",
        dataIndex: "marginRateLiquidity",
        key: "marginRateLiquidity",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Swap Mode",
        dataIndex: "swapMode",
        key: "swapMode",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Swap Long",
        dataIndex: "swapLong",
        key: "swapLong",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Swap Short",
        dataIndex: "swapShort",
        key: "swapShort",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Swap 3 Day",
        dataIndex: "swap3Day",
        key: "swap3Day",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Time Start",
        dataIndex: "timeStart",
        key: "timeStart",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Time Expiration",
        dataIndex: "timeExpiration",
        key: "timeExpiration",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Re Flags",
        dataIndex: "reFlags",
        key: "reFlags",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Re Timeout",
        dataIndex: "reTimeout",
        key: "reTimeout",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "IE Check Mode",
        dataIndex: "ieCheckMode",
        key: "ieCheckMode",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "IE Timeout",
        dataIndex: "ieTimeout",
        key: "ieTimeout",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "IE Slip Profit",
        dataIndex: "ieSlipProfit",
        key: "ieSlipProfit",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "IE Slip Losing",
        dataIndex: "ieSlipLosing",
        key: "ieSlipLosing",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "IE Volume Max",
        dataIndex: "ieVolumeMax",
        key: "ieVolumeMax",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "IE Volume Max Ext",
        dataIndex: "ieVolumeMaxExt",
        key: "ieVolumeMaxExt",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "IE Flags",
        dataIndex: "ieFlags",
        key: "ieFlags",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Price Settle",
        dataIndex: "priceSettle",
        key: "priceSettle",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Price Limit Max",
        dataIndex: "priceLimitMax",
        key: "priceLimitMax",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Price Limit Min",
        dataIndex: "priceLimitMin",
        key: "priceLimitMin",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Trade Flags",
        dataIndex: "tradeFlags",
        key: "tradeFlags",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Order Flags",
        dataIndex: "orderFlags",
        key: "orderFlags",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Face Value",
        dataIndex: "faceValue",
        key: "faceValue",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Accrued Interest",
        dataIndex: "accruedInterest",
        key: "accruedInterest",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Splice Type",
        dataIndex: "spliceType",
        key: "spliceType",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Splice Time Type",
        dataIndex: "spliceTimeType",
        key: "spliceTimeType",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Chart Mode",
        dataIndex: "chartMode",
        key: "chartMode",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Option Mode",
        dataIndex: "optionMode",
        key: "optionMode",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    },
    {
        title: "Price Strike",
        dataIndex: "priceStrike",
        key: "priceStrike",
        ...getColumnSearchProps(),
        render: (text) => <p>{text !== null && text !== undefined && text !== "" ? text : "-"}</p>,
    }
];
