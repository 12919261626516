import React, {useCallback, useEffect, useState} from "react";
import {Button, Divider, Form, InputNumber, Row, Switch} from "antd";
import {useTranslation} from "react-i18next";
import {getTradingAccountSetting, useUpdateTradingAccountSettingMutation} from "../../../../../../../api/graphql/tradingAccountSetting";
import useAuthorize from "../../../../../../../_common/authorize";
import {useUpdateEWalletSettingMutation} from "../../../../../../../api/graphql/eWalletSetting";
import _ from 'lodash';

const TradingAccountSetting = (props) => {
    const {setCustomizeSubmitButton, module, close, eWalletSetting, refetchEWalletSetting} = props
    const {t} = useTranslation()

    const [input, setInput] = useState<any>()
    const [eWalletSettingInput, setEWalletSettingInput] = useState<any>()

    const {data} = getTradingAccountSetting()
    const {updateTradingAccountSetting} = useUpdateTradingAccountSettingMutation()
    const {updateEWalletSetting} = useUpdateEWalletSettingMutation()
    const {systemErrorNotification} = useAuthorize()

    const onSubmit = async () => {
        try {
            if (["deposit", "withdrawal"].includes(module)) {

                if (eWalletSettingInput?.depositWithKyc === eWalletSetting?.depositWithKyc && eWalletSettingInput?.withdrawalWithKyc === eWalletSetting?.withdrawalWithKyc) {
                    return
                }

                await updateEWalletSetting(eWalletSettingInput)
                refetchEWalletSetting()
            }

            if (_.isEqual(input, data?.getTradingAccountSetting)) {
                close()
                return
            }

            updateTradingAccountSetting(input)
            close()
        } catch (e) {
            systemErrorNotification()
        }
    }

    useEffect(() => {
        setInput(data?.getTradingAccountSetting || {})
    }, [data?.getTradingAccountSetting])

    useEffect(() => {
        if (eWalletSetting?._id) {
            setEWalletSettingInput({depositWithKyc: eWalletSetting?.depositWithKyc, withdrawalWithKyc: eWalletSetting?.withdrawalWithKyc})
        }
    }, [eWalletSetting]);

    const submitButton = useCallback(() => (
        <Button onClick={onSubmit} type={"primary"}>
            {t("common.submit")}
        </Button>
    ), [input, eWalletSettingInput]);

    useEffect(() => {
        setCustomizeSubmitButton(submitButton);
    }, [submitButton]);

    return (
        <>
            <Divider/>
            <Row justify={"space-between"} style={{padding: 16, paddingBottom: 0}}>
                {
                    module === "deposit" && (
                        <>
                            <label>{t("Auto Deposit into Trading Account")}</label>
                            <Switch
                                checked={input?.autoDeposit}
                                onChange={(value) => setInput({...input, autoDeposit: value})}
                            />
                        </>
                    )
                }
                {
                    module === "withdrawal" && (
                        <>
                            <label>{t("Auto Withdrawal From Trading Account to EWallet")}</label>
                            <Switch
                                checked={input?.autoWithdrawal}
                                onChange={(value) => setInput({...input, autoWithdrawal: value})}
                            />
                        </>
                    )
                }
                {
                    module === "wallet" && (
                        <>
                            <Form.Item
                                label={t("Trading Account Limit (Live)")}
                                colon={false}
                                labelCol={{span: 10}}
                                wrapperCol={{span: 14}}
                                style={{width: "100%", textAlign: "right"}}
                            >
                                <InputNumber
                                    value={input?.tradingAccountLimitLive}
                                    min={0}
                                    onChange={(value) => setInput({...input, tradingAccountLimitLive: parseInt(value)})}
                                />
                            </Form.Item>
                            <Form.Item
                                label={t("Trading Account Limit (Demo)")}
                                colon={false}
                                labelCol={{span: 10}}
                                wrapperCol={{span: 14}}
                                style={{width: "100%", textAlign: "right"}}
                                className={"margin-top-0-75"}
                            >
                                <InputNumber
                                    value={input?.tradingAccountLimitDemo}
                                    min={0}
                                    onChange={(value) => setInput({...input, tradingAccountLimitDemo: parseInt(value)})}
                                />
                            </Form.Item>
                            <Form.Item
                                label={t("Free Margin Percentage")}
                                colon={false}
                                labelCol={{span: 10}}
                                wrapperCol={{span: 14}}
                                style={{width: "100%", textAlign: "right"}}
                                className={"margin-top-0-75"}
                            >
                                <InputNumber
                                    value={input?.freeMarginPercentage}
                                    min={0}
                                    onChange={(value) => setInput({...input, freeMarginPercentage: parseFloat(value)})}
                                />
                            </Form.Item>
                        </>
                    )
                }
            </Row>
            <Row justify={"space-between"} style={{padding: 16}}>
                {
                    eWalletSetting?._id && (
                        <>
                            {
                                module === "deposit" && (
                                    <>
                                        <label>{t("Allow Deposits Without User Verification")}</label>
                                        <Switch
                                            checked={!eWalletSettingInput?.depositWithKyc}
                                            onChange={(value) => setEWalletSettingInput(prev => ({...prev, depositWithKyc: !value}))}
                                        />
                                    </>
                                )
                            }
                            {
                                module === "withdrawal" && (
                                    <>
                                        <label>{t("Allow Withdrawal Without User Verification")}</label>
                                        <Switch
                                            checked={!eWalletSettingInput?.withdrawalWithKyc}
                                            onChange={(value) => setEWalletSettingInput(prev => ({...prev, withdrawalWithKyc: !value}))}
                                        />
                                    </>
                                )
                            }
                        </>
                    )
                }
            </Row>
        </>
    )
}

export default TradingAccountSetting