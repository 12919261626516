import React, {useEffect} from "react";
import {Checkbox, Col, Form, Input, Row} from "antd";
import {useTranslation} from "react-i18next";

const ReportsMt5 = ({initialData}) => {
    const {t} = useTranslation()
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            ...initialData
        });
    }, [initialData])

    return (
        <>
            <Row gutter={[16, 16]} style={{marginBottom: "1%", marginTop: "2%"}}>
                <Col span={24}>
                    <p>
                        The platform can daily save the end-of-day state of accounts to a special database. That data is
                        used for generating daily statements and various reports
                        for managers.
                    </p>
                </Col>
            </Row>

            <Form form={form}>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={24}>
                        <Form.Item
                            name={"reportsMode"}
                            label={t("Generate report data")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={24}>
                        <Form.Item
                            name={"generateStatement"}
                            label={t("Generate statement for clients")}
                        >
                            <Checkbox disabled={true}
                                      defaultChecked={(initialData?.reportsFlags === "REPORTSFLAGS_STATEMENTS" || initialData?.reportsFlags === "REPORTSFLAGS_ALL")}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={24}>
                        <Form.Item
                            name={"sendStatementByEmail"}
                            label={t("Send statements by email")}
                        >
                            <Checkbox disabled={true}
                                      defaultChecked={(initialData?.reportsFlags === "REPORTSFLAGS_EMAIL" || initialData?.reportsFlags === "REPORTSFLAGS_ALL")}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={24}>
                        <Form.Item
                            name={"reportsEmail"}
                            label={t("Mail server")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={24}>
                        <Form.Item
                            name={"sendCopyToSupport"}
                            label={t("Send copies to support email")}
                        >
                            <Checkbox disabled={true}
                                      defaultChecked={(initialData?.reportsFlags === "REPORTSFLAGS_SUPPORT" || initialData?.reportsFlags === "REPORTSFLAGS_ALL")}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default ReportsMt5