import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {getSalesOpportunity, useBatchUpdateSalesOpportunityMutation} from "../../../../../api/graphql/salesOpportunity";
import TableView from "./component/tableView";
import {Button, Popconfirm, Row, Segmented, Space} from "antd";
import {AppstoreOutlined, BarsOutlined} from "@ant-design/icons";
import EditTableUserPreferenceButton from "../../../../../components/table/function/editTableUserPreferenceButton";
import {Columns} from "../../function/tableColumn";
import {useRecoilValue} from "recoil";
import {fieldListState} from "../../../../../../recoil_state";
import BoardView from "./component/boardView";
import {useGetSalesOpportunity} from "../../function";

function SalesOpportunity_1(props) {
    const {tableName, permissionUser, statusList} = props
    const {t} = useTranslation()
    const [orderBy, setOrderBy] = useState<any>("")
    const [limit, setLimit] = useState<any>(20)
    const [offset, setOffset] = useState<any>(0)
    const [filter, setFilter] = useState<any>({})
    const [startDate, setStartDate] = useState<any>("")
    const [endDate, setEndDate] = useState<any>("")
    const [dateKey, setDateKey] = useState<any>("")
    const [view, setView] = useState<any>("table")
    const [isViewArchive, setIsViewArchive] = useState<any>(false)
    const [batchUpdateRecord, setBatchUpdateRecord] = useState<any>([]);
    const columns = Columns()

    const [data, loading, fetchSalesOpportunity, refetchSalesOpportunity] = useGetSalesOpportunity()

    const isClearFilterVisible = Object.keys(filter)?.length > 0 || (dateKey && startDate)
    const resetFilters = () => {
        setFilter({})
        setStartDate("")
        setEndDate("")
        setDateKey("")
    }

    useEffect(() => {

        fetchSalesOpportunity({
            ...filter,
            isArchived: isViewArchive
        }, limit, offset, orderBy, startDate, endDate, dateKey)

    }, [isViewArchive, filter, limit, offset, orderBy, startDate, endDate, dateKey]);

    const fieldList = useRecoilValue<any>(fieldListState)
    const {batchUpdateSalesOpportunity, loading: updateLoading} = useBatchUpdateSalesOpportunityMutation()

    useEffect(() => {
        setOffset(0)
    }, [isViewArchive])

    useEffect(() => {
        setOffset(0)
        if (view === "table") {
            setLimit(20)
        } else {
            setLimit(0)
            setFilter({})
        }
    }, [view])

    const archiveRecord = async () => {
        try {
            await batchUpdateSalesOpportunity(batchUpdateRecord?.map(d => d?._id), true)
            setBatchUpdateRecord([])

            refetchSalesOpportunity()
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className={"h-100"}>
            <h1>
                {isViewArchive ? t("user.tableArchived", {tableName: t("module.salesOpportunity")}) : t("user.tableActive", {tableName: t("module.salesOpportunity")})}
                {
                    isClearFilterVisible &&
                    <Button className={"margin-left-0-5"} size={"small"} onClick={() => resetFilters()}>
                        {t("common.clearFilter")}
                    </Button>
                }
            </h1>
            <Row justify={view === "table" && !isViewArchive && batchUpdateRecord?.length > 0 ? "space-between" : "end"}
                 className={"margin-bottom-0-75"} wrap>
                {view === "table" && !isViewArchive && batchUpdateRecord?.length > 0 &&
                    <Popconfirm
                        placement="left"
                        description={t("salesOpportunity.popconfirm.description.archive")}
                        title={t(`salesOpportunity.popconfirm.title.archive`)}
                        onConfirm={() => {
                            archiveRecord();
                        }}
                        okText={t("common.yes")}
                        cancelText={t("common.no")}
                    >
                        <Button loading={updateLoading}>
                            {t("common.archive")}
                        </Button>
                    </Popconfirm>
                }
                <Space wrap>
                    {
                        view === "table" &&
                        <>
                            <Button onClick={() => {
                                setIsViewArchive(!isViewArchive)
                            }}>{t(!isViewArchive ? "salesOpportunity.showArchive" : "salesOpportunity.showActive")}</Button>
                            <EditTableUserPreferenceButton
                                tableName={tableName}
                                displayTableName={t("module.salesOpportunity")}
                                tableColumn={columns}
                            />
                        </>
                    }
                    <Segmented
                        defaultValue={view}
                        onChange={(value => {
                            setView(value)
                        })}
                        options={[
                            {
                                label: t("salesOpportunity.tableView"),
                                value: 'table',
                                icon: <BarsOutlined/>,
                            },
                            {
                                label: t("salesOpportunity.boardView"),
                                value: 'board',
                                icon: <AppstoreOutlined/>,
                            },
                        ]}
                    />
                </Space>
            </Row>
            {
                view === "table" ?
                    <TableView
                        data={data}
                        statusList={statusList}
                        loading={loading}
                        limit={limit}
                        setLimit={setLimit}
                        setOffset={setOffset}
                        setFilter={setFilter}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        setDateKey={setDateKey}
                        setOrderBy={setOrderBy}
                        tableName={tableName}
                        permissionUser={permissionUser}
                        isViewArchive={isViewArchive}
                        batchUpdateRecord={batchUpdateRecord}
                        setBatchUpdateRecord={setBatchUpdateRecord}
                        filter={filter}
                        refetchSalesOpportunity={refetchSalesOpportunity}
                    />
                    :
                    <BoardView
                        statusList={statusList}
                        permissionUser={permissionUser}
                        fields={fieldList?.filter(d => d?.isUsedForLogin)}
                        refetchSalesOpportunity={refetchSalesOpportunity}
                    />
                // <BoardViewOld
                //     statusList={data?.getSalesOpportunity?.statusList}
                //     statusListNew={statusList}
                //     data={boardData}
                //     loading={loading}
                //     permissionUser={permissionUser}
                //     fields={fieldList?.filter(d => d?.isUsedForLogin)}
                // />
            }
        </div>
    )
}

export default SalesOpportunity_1;