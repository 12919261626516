import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {
    currencySettingState,
    fieldListState,
    languageDataList,
    languageDataLoading, pushNotificationServerSettingState,
    selfPermissionList,
    selfPermissionLoading,
    selfPermissionObj
} from "./recoil_state";
import {convertLanguageFile} from "./shared/function/convertLanguageFile";
import i18next from "i18next";
import {
    arenaMediaFileState, arenaMediaFolderState,
    brandDetailsState,
    brandDomainOptionsState,
    deskOptionsState, languageOptionsState,
    rankOptionsState,
    roleOptionsState,
    salesRepNetworkOptionsState,
    userNetworkOptionsState,
    userOptionsState
} from "./shared/pages/users/_common/state";
import {useSalesOpportunitySettingFunction} from "./shared/pages/settings/salesOpportunitySetting/function";
import {getSecureInitialData, getSecureInitialDataWithoutToken} from "./shared/api/graphql/initialData";
import useAuthorize from "./shared/_common/authorize";
import {formatSalesRepNetworkOptions, formatUserNetworkOptions, formatUserOptions} from "./shared/function/_common";
import {getPNServerSettingWithoutToken} from "./shared/api/graphql/pushNotification";
import {getConfigByName} from "./config";
import {BRAND_NAME} from "./config/brandVariable";
import {getMediaFolder} from "./shared/api/restful/arena/media_folder";
import {getMedia} from "./shared/api/restful/arena/media_file";
import {useSetDropdown} from "./shared/_common/dropdowns";

const InitialDataSetup = () => {
    const {i18n} = useTranslation();
    const {systemErrorNotification} = useAuthorize()
    const {getSalesOpportunityStatusFunction} = useSalesOpportunitySettingFunction()
    const {getArenaMedia, getArenaFolder} = useSetDropdown()

    const setSelfPermissionList = useSetRecoilState(selfPermissionList);
    const setSelfPermissionObj = useSetRecoilState(selfPermissionObj);
    const setSelfPermissionLoading = useSetRecoilState(selfPermissionLoading);
    const setLanguageDataList = useSetRecoilState(languageDataList);
    const setLanguageDataLoading = useSetRecoilState(languageDataLoading);
    const setBrandDetail = useSetRecoilState(brandDetailsState);
    const setCurrencySettingState = useSetRecoilState(currencySettingState);
    const setFieldList = useSetRecoilState(fieldListState)
    const setBrandDomainOptions = useSetRecoilState(brandDomainOptionsState);
    const setDeskOptions = useSetRecoilState(deskOptionsState);
    const setRankOptions = useSetRecoilState(rankOptionsState);
    const setRoleOptions = useSetRecoilState(roleOptionsState);
    const setSalesRepNetworkOptions = useSetRecoilState(salesRepNetworkOptionsState);
    const setUserOptions = useSetRecoilState(userOptionsState);
    const setUserNetworkOptions = useSetRecoilState(userNetworkOptionsState);
    const setLanguageOptions = useSetRecoilState(languageOptionsState);
    const setPNServerSetting = useSetRecoilState(pushNotificationServerSettingState)

    const token = localStorage.getItem("a_t");

    const getInitialDataWithoutToken = async () => {
        setLanguageDataLoading(true);
        try {
            const res = await getSecureInitialDataWithoutToken(localStorage.getItem("lang"))
            const pushNotificationSetting = await getPNServerSettingWithoutToken("web", "firebase");

            const {
                getCrmUserBrandSetting: brandSetting,
                getLanguage: languageData,
                getLanguageDropdown: languageDropdown
            } = res?.getSecureInitialDataWithoutToken

            // BRAND SETTING
            setBrandDetail(brandSetting);

            // LANGUAGE SETUP
            if (brandSetting?.timezone) {
                localStorage.setItem("timezone", brandSetting?.timezone);
            }

            let languageFile = convertLanguageFile(languageData?.data);
            setLanguageDataList(languageData?.data);

            languageData?.data?.forEach((item) => {
                i18next.addResourceBundle(item.code, "translation", languageFile[item.code]["translation"]);
            });

            const lang = localStorage.getItem("lang");
            if (lang) {
                await i18n.changeLanguage(lang);
            } else {
                localStorage.setItem("lang", "en");
                await i18n.changeLanguage("en");
            }

            // LANGUAGE DROPDOWN
            setLanguageOptions(languageDropdown?.data?.map(d => ({
                label: d?.nativeName || d?.name,
                value: d?.code,
            })))

            // PUSH NOTIFICATION
            const getPushNotificationSetting = pushNotificationSetting?.getPNServerSettingWithoutToken
            setPNServerSetting(getPushNotificationSetting)
        } catch (e) {
            systemErrorNotification()
        } finally {
            setLanguageDataLoading(false)
        }
    }
    const getInitialData = async () => {
        try {
            setSelfPermissionLoading(true)
            const res = await getSecureInitialData(localStorage.getItem("lang"))
            const {
                getBrandDomainDropdown: brandDomain,
                getCurrencySetting: currencySetting,
                getDeskDropdown: deskDropdown,
                getFieldList: fieldList,
                getMultiTierSettingDropdown: rankDropdown,
                getRoleTemplateDropdown: roleDropdown,
                getSalesRepNetworkTree: salesRepNetworkTree,
                getSelfPermissionList: selfPermissionList,
                getSelfPermissionListObject: selfPermissionListObject,
                getUserDropdown: userDropdown,
                getUserNetworkTree: userNetworkTree
            } = res?.getSecureInitialData

            // PERMISSION
            setSelfPermissionList(selfPermissionList)
            setSelfPermissionObj(selfPermissionListObject)
            setSelfPermissionLoading(false)

            // CURRENCY SETTING
            setCurrencySettingState(currencySetting?.data)

            // FIELD LIST
            setFieldList(fieldList?.data || [])

            // USER DROPDOWN
            setUserOptions(userDropdown?.data?.map((d) => formatUserOptions(d)));

            // USER NETWORK TREE DROPDOWN
            setUserNetworkOptions(userNetworkTree?.data?.map((d) => formatUserNetworkOptions(d)))

            // RANK DROPDOWN
            setRankOptions(rankDropdown?.data?.map((d) => {
                return {
                    label: d?.name,
                    value: d?._id,
                    isDefault: d?.isDefaultRank,
                    type: d?.type,
                };
            }))

            // ROLE DROPDOWN
            setRoleOptions(roleDropdown?.data?.map((d) => {
                return {
                    label: d?.name,
                    value: d?._id,
                    isDefault: d?.isDefaultCrmUserPermission,
                };
            }))

            // SALES REP NETWORK DROPDOWN
            setSalesRepNetworkOptions(salesRepNetworkTree?.data?.map((d) => formatSalesRepNetworkOptions(d)))

            // DESK DROPDOWN
            setDeskOptions(deskDropdown?.data?.map((d) => {
                    return {
                        label: d?.name,
                        value: d?._id,
                        selectedLabel: d?.name,
                    };
                })
            );

            // DOMAIN DROPDOWN
            setBrandDomainOptions(brandDomain?.data)

            // Arena Media
            const arenaURL = getConfigByName(BRAND_NAME).arenaURL
            if (localStorage.getItem("arenaToken") && arenaURL) {
                getArenaMedia()
                getArenaFolder()
            }

        } catch (e) {
            systemErrorNotification()
        }
    }

    useEffect(() => {
        getInitialDataWithoutToken()
    }, []);

    useEffect(() => {
        if (token) {
            getInitialData()
            getSalesOpportunityStatusFunction()
        }
    }, [token])

    return <></>;
}

export default InitialDataSetup