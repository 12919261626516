import React, { ReactNode } from "react";
import { Button } from "antd";
import { BaseButtonProps } from "antd/es/button/button";

type ButtonType = {
  text: string;
  onClick: () => void;
  icon?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
};

const ButtonAntDesign = (props: ButtonType & BaseButtonProps) => {
  const { text } = props;

  return <Button {...props}>{text}</Button>;
};

export default ButtonAntDesign;
