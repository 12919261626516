import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Space, Tabs} from "antd";
import AddButton from "../../../../../components/button/addButton";
import {refetchQuery} from "../../../../../function/refetchQuery";
import EditTableUserPreferenceButton from "../../../../../components/table/function/editTableUserPreferenceButton";
import ExportTableButton from "../../../../../components/table/function/exportTable";
import RoleSettings from "./component/roleSettings";
import Structure from "./component/structure";
import ExportDBTableButton from "./component/exportDBTable";
import ImportDBTableButton from "./component/importDBTable";
import {getIsMainMasterAdmin} from "../../../../../api/graphql/_common";

const RoleTemplate_1 = (props) => {
    const {networkData, parentPermissionArrayData, targetParent, setTargetParent, networkTreeDownlineData, setNetworkList, roleTemplatePermission} =
        props;
    const {t} = useTranslation();
    const [exportData, setExportData] = useState(null);
    const [exportColumn, setExportColumn] = useState(null);
    const [currentTab, setCurrentTab] = useState("setting");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formMode, setFormMode] = useState("");
    const [editType, setEditType] = useState("both"); // both, general, permission
    const [loading, setLoading] = useState(false);
    const [isMainMasterAdmin, setIsMainMasterAdmin] = useState(false);

    const toggleModal = (mode: "" | "view" | "add" | "edit") => {
        setIsModalOpen((prevState) => !prevState);
        setFormMode(mode);
        if (mode === "add") {
            setTargetParent("");
            setEditType("both");
        }
    };

    const checkIsMasterAdmin = async () => {
        let response = await getIsMainMasterAdmin();
        setIsMainMasterAdmin(response?.isMainMasterAdmin);
    };

    useEffect(() => {
        checkIsMasterAdmin();
    }, []);

    const refetchRoleNetwork = async () => {
        refetchQuery("getRoleNetworkTree");
    };

    let tabItems = [
        {
            label: t("common.setting"),
            key: "setting",
            children: (
                <RoleSettings
                    formMode={formMode}
                    isModalOpen={isModalOpen}
                    parentPermissionArrayData={parentPermissionArrayData}
                    networkTreeDownlineData={networkTreeDownlineData}
                    setNetworkList={setNetworkList}
                    targetParent={targetParent}
                    setTargetParent={setTargetParent}
                    editType={editType}
                    setEditType={setEditType}
                    toggleModal={toggleModal}
                    setExportData={setExportData}
                    setExportColumn={setExportColumn}
                    refetchRoleNetwork={refetchRoleNetwork}
                    roleTemplatePermission={roleTemplatePermission}
                    setLoading={setLoading}
                />
            ),
        },
        {
            label: t("common.structure"),
            key: "structure",
            children: <Structure data={networkData} currentTab={currentTab} refetchRoleNetwork={refetchRoleNetwork} />,
        },
    ];

    return (
        <div id="role">
            <h1>{t("module.roleSetting")}</h1>
            <Tabs
                defaultActiveKey="setting"
                size={"small"}
                items={tabItems}
                tabBarExtraContent={
                    <>
                        <Space>
                            {roleTemplatePermission?.add?.add && currentTab === "setting" && (
                                <AddButton text={t("roleSetting.addRole")} addHandler={() => toggleModal("add")} />
                            )}
                            {currentTab === "setting" && <EditTableUserPreferenceButton tableName={"Role Settings"} tableColumn={exportColumn} />}
                            <ExportTableButton loading={loading} tableData={exportData} tableColumn={exportColumn} tableName={"Role Settings"} />
                            {isMainMasterAdmin && <ImportDBTableButton tableName={"roletemplates"} />}
                            {isMainMasterAdmin && <ExportDBTableButton tableName={"roletemplates"} />}
                            {/* {isMainMasterAdmin && <ImportDBTableButton tableName={currentTab === "setting" ? "roletemplates" : "rolenetworktree"} />}
                            {isMainMasterAdmin && <ExportDBTableButton tableName={currentTab === "setting" ? "roletemplates" : "rolenetworktree"} />} */}
                        </Space>
                    </>
                }
                onTabClick={(key) => setCurrentTab(key)}
            />
        </div>
    );
};

export default RoleTemplate_1;
