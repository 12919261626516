import React, {useEffect, useRef, useState} from "react";
import {Button, Checkbox, Col, Form, Input, Modal, Radio, Row, Select, Space, Tabs, Tag} from "antd";
import {useTranslation} from "react-i18next";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {languageOptionsState, userOptionsState} from "../../../users/_common/state";
import {Editor} from "@tinymce/tinymce-react";
import {getConfigByName} from "../../../../../config";
import {BRAND_NAME} from "../../../../../config/brandVariable";
import {
    useAddEmailTemplateMutationWithoutNotification,
    useSendEmailMutationWithoutNotification,
    useUpdateEmailTemplateMutationWithoutNotification
} from "../../../../api/graphql/emailTemplate";
import useAuthorize from "../../../../_common/authorize";
import {addEmailSendDraft, updateEmailSendDraft} from "../../../../api/graphql/message";
import {notificationMessage} from "../../../../../recoil_state";

const SendMessageModal = ({
                              isOpen,
                              handleCancel,
                              emailTemplate,
                              mode,
                              selectedRecord,
                              fetchEmailLogData,
                              fetchEmailDraftData,
                              fetchEmailTemplate,
                              emailTypeDropdown,
                              senderEmailDropdown,
                              setActiveTab
                          }) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const editorRef = useRef<any>(null);
    const brandConfig = getConfigByName(BRAND_NAME);
    const {baseErrorChecking} = useAuthorize()

    const languageOptions = useRecoilValue<any>(languageOptionsState)
    const userOptions = useRecoilValue<any>(userOptionsState);
    const [userGroup, setUserGroup] = useState("")
    const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<any>(null);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [isFixedEmail, setIsFixedEmail] = useState<boolean | null>(null);
    const [action, setAction] = useState("")
    const [currentLanguage, setCurrentLanguage] = useState<string>("en");
    const [extraEmail, setExtraEmail] = useState<string[]>([])
    const [dataByLanguage, setDataByLanguage] = useState<any>({})
    const [initialData, setInitialData] = useState({})
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const {addEmailTemplate} = useAddEmailTemplateMutationWithoutNotification();
    const {updateEmailTemplate} = useUpdateEmailTemplateMutationWithoutNotification();
    const {sendEmail} = useSendEmailMutationWithoutNotification();

    const labelCol = {span: 6}
    const wrapperCol = {span: 18}

    const resetFormFields = () => {
        form.setFieldValue("type", "email")

        if (mode === "edit") {
            const template = emailTemplate?.find(d => d?.name === selectedRecord?.name)
            setSelectedEmailTemplate(template)

            const emails: string[] = []
            const toValue = selectedRecord?.to?.map(email => {
                const found = userOptions?.find(e => e?.email === email)
                if (found) {
                    return email
                } else {
                    emails.push(email)
                }
            })?.filter(d => d)

            setExtraEmail(emails)
            setIsFixedEmail(template?.isFixedEmail)

            form.setFieldsValue({
                network: selectedRecord?.sendInNetwork,
                template: template?.name,
                isFixedEmail: template?.isFixedEmail,
                from: template?.from?._id,
                emailType: template?.emailType?._id,
                to: toValue,
            })
        } else {
            form.resetFields()
        }
    }

    useEffect(() => {
        if (isOpen === "message") {
            setUserGroup("")
            setSelectedEmailTemplate(null)
            setIsEditing(false)
            setExtraEmail([])
            setDataByLanguage({})
            setIsFixedEmail(null)

            resetFormFields()
        }
    }, [isOpen, selectedRecord]);

    useEffect(() => {
        if (selectedEmailTemplate) setEmailInitialValue()
    }, [selectedEmailTemplate]);

    const setEmailInitialValue = () => {
        selectedEmailTemplate?.htmlObject?.map(d => {
            const foundLanguage = languageOptions?.find(lang => lang?.value === d?.language)?.value
            if (foundLanguage) {
                setDataByLanguage(prev => ({
                    ...prev,
                    [`content_${foundLanguage}`]: d?.html
                }))

                setInitialData(prev => ({
                    ...prev,
                    [`content_${foundLanguage}`]: d?.html
                }))
            }
        })

        selectedEmailTemplate?.displaySubjectLanguage?.map(d => {
            const foundLanguage = languageOptions?.find(lang => lang?.value === d?.language)?.value
            if (foundLanguage) {
                form.setFieldValue(`subject_${foundLanguage}`, d?.label)
            }
        })
    }

    const extraInputOnKeyDown = (e, field) => {
        const error = form.getFieldError(field);
        if (error.length) {
            form.setFields([
                {
                    name: "to_extraInput",
                    errors: []
                }
            ])
        }

        if (e.key === "Enter") {
            e.preventDefault();
            const originalValue: string = form.getFieldValue(field)

            const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

            if (!!originalValue.match(validRegex)) {
                setExtraEmail(prev => [...prev, originalValue])
                form.setFieldValue(field, "")
            } else {
                form.setFields([
                    {
                        name: "to_extraInput",
                        errors: [t("errorMsg.type.email")]
                    }
                ])
            }
        }
    }

    const contentTabs = languageOptions?.map(lang => {
        const html = selectedEmailTemplate?.htmlObject?.find(d => d?.language === lang?.value)?.html

        return {
            key: lang?.value,
            label: lang?.label,
            children: (
                <div className={"margin-top-0-75"}>
                    <Form.Item label={t("message.subject")} name={`subject_${lang?.value}`}>
                        <Input disabled={!isEditing}/>
                    </Form.Item>

                    <Form.Item className={"margin-top-0-75"} wrapperCol={{span: 24}}>
                        {
                            !isEditing &&
                            <div>
                                <p>{t("message.content_preview")}: </p>
                                <div className={'tinyMceParagraph'} dangerouslySetInnerHTML={{__html: html}}/>
                            </div>
                        }
                    </Form.Item>
                </div>
            ),
            forceRender: true
        }
    })

    const onSubmit = async (value) => {
        setSubmitLoading(true)
        const htmlObject = Object.keys(dataByLanguage)?.filter(d => d.includes("content") && dataByLanguage[d])?.map(d => {
            const lang = d.split("_")[1]

            return {
                html: dataByLanguage[d],
                language: lang,
                head: null,
                attachment: []
            }
        })

        const subjectObject = Object.keys(value)?.filter(d => d.includes("subject") && value[d])?.map(d => {
            const lang = d.split("_")[1]

            return {
                label: value[d],
                language: lang,
            }
        })

        const emailTemplateInput = {
            displayName: `${selectedEmailTemplate?.name}_message_${Date.now()}`,
            from: value?.from,
            subject: selectedEmailTemplate?.subject,
            htmlObject: htmlObject,
            emailType: value?.emailType,
            displaySubjectLanguage: subjectObject,
            type: "message",
            isFixedEmail: value?.isFixedEmail,
            isActive: true,
        }

        try {
            if (mode === "add") {
                const {data: response} = await addEmailTemplate(emailTemplateInput)

                if (response?.addEmailTemplate?.__typename === "EmailTemplate") {
                    fetchEmailTemplate()
                    const template = response?.addEmailTemplate?.name
                    const sendEmailInput = {
                        name: template,
                        to: [...value?.to, ...extraEmail],
                        sendInNetwork: value?.network || false,
                        sendUserType: value?.toGroup
                    }

                    const emailResponse = action === "send" ? await sendEmail(sendEmailInput) : await addEmailSendDraft(sendEmailInput)
                    if (emailResponse?.data?.sendEmail?.__typename === "EmailTemplateBatchLog" || emailResponse?.addEmailSendDraft?.__typename === "EmailSendDraft") {
                        handleCancel()
                        fetchEmailDraftData()
                        fetchEmailLogData()

                        if (emailResponse?.data?.sendEmail?.__typename === "EmailTemplateBatchLog") {
                            setNotificationMessage({
                                status: "success",
                                title: "",
                                msg: t("message.email_sent_successfully"),
                                key: "messagesNotification",
                            });

                            setActiveTab("outbox")
                        } else {
                            setNotificationMessage({
                                status: "success",
                                title: "",
                                msg: t("message.draft_saved_successfully"),
                                key: "messagesNotification",
                            });

                            setActiveTab("draft")
                        }
                    }
                } else if (response?.addEmailTemplate?.__typename === "BaseError") {
                    baseErrorChecking(response?.addEmailTemplate)
                    return
                }

            } else {
                if (isEditing) {
                    const {data: response} = await updateEmailTemplate(selectedEmailTemplate?._id, emailTemplateInput)
                    if (response?.updateEmailTemplate?.__typename === "EmailTemplate") {
                        fetchEmailTemplate()
                        await runSaveSendEmailInDraft(value)
                    } else if (response?.updateEmailTemplate?.__typename === "BaseError") {
                        baseErrorChecking(response?.updateEmailTemplate)
                        return
                    }
                } else {
                    await runSaveSendEmailInDraft(value)
                }
            }
        } catch (e) {
            console.log(e)
        } finally {
            setSubmitLoading(false)
        }
    }

    const runSaveSendEmailInDraft = async (value) => {
        const sendEmailInput = {
            name: value?.template,
            to: [...value?.to, ...extraEmail],
            sendInNetwork: value?.network || false,
            sendUserType: value?.toGroup
        }

        const emailResponse = action === "send" ? await sendEmail(sendEmailInput) : await updateEmailSendDraft(selectedRecord?._id, sendEmailInput)

        if (emailResponse?.data?.sendEmail?.__typename === "EmailTemplateBatchLog" || emailResponse?.updateEmailSendDraft?.__typename === "EmailSendDraft") {
            handleCancel()
            fetchEmailDraftData()
            fetchEmailLogData()

            if (emailResponse?.data?.sendEmail?.__typename === "EmailTemplateBatchLog") {
                await updateEmailSendDraft(selectedRecord?._id, {...sendEmailInput, isSent: true})

                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("message.email_sent_successfully"),
                    key: "messagesNotification",
                });

                setActiveTab("outbox")
            } else {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("message.draft_saved_successfully"),
                    key: "messagesNotification",
                });

                setActiveTab("draft")
            }
        } else {
            baseErrorChecking(emailResponse?.data?.sendEmail || emailResponse?.updateEmailSendDraft)
            return
        }
    }

    const handleRemove = (str) => {
        const index = extraEmail.indexOf(str);
        return extraEmail.splice(index, 1);
    }

    const onTabChangeHandler = (e) => {
        setCurrentLanguage(e)
        setInitialData(dataByLanguage)
    }

    return (
        <Modal
            open={isOpen === "message"}
            onCancel={handleCancel}
            title={t("message.send_message")}
            getContainer={"#messages"}
            className={"send-message-modal"}
            footer={
                <Row justify={"end"}>
                    <Space>
                        <Button onClick={handleCancel}>{t("common.cancel")}</Button>
                        <Button type={"primary"} onClick={() => {
                            form.submit()
                            setAction("save")
                        }} loading={submitLoading && action === "save"} disabled={submitLoading}>{t("common.save")}</Button>
                        <Button type={"primary"} onClick={() => {
                            form.submit()
                            setAction("send")
                        }} loading={submitLoading && action === "send"} disabled={submitLoading}>{t("common.send")}</Button>
                    </Space>
                </Row>
            }
        >
            <Form onFinish={onSubmit} form={form} labelCol={labelCol} wrapperCol={wrapperCol} labelAlign={"left"}>
                {/*<Form.Item label={t("Type")} name={"type"}>*/}
                {/*    <Select*/}
                {/*        options={[{label: t("Email"), value: "email"}]}*/}
                {/*        disabled*/}
                {/*    />*/}
                {/*</Form.Item>*/}

                <Form.Item label={t("message.to")}>
                    <Row gutter={8}>
                        <Col span={5}>
                            <Form.Item name={"toGroup"} wrapperCol={{span: 24}}>
                                <Select
                                    options={[
                                        {label: t("common.all"), value: "all"},
                                        {label: t("user.client"), value: "client"},
                                        {label: t("user.crmUser"), value: "crmUser"},
                                    ]}
                                    defaultValue={"all"}
                                    onChange={(e) => form.setFieldValue("toGroup", e)}
                                    placeholder={t("message.select_group")}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={19}>
                            <Form.Item name={"to"} wrapperCol={{span: 24}}>
                                <Select
                                    options={userOptions?.map(d => ({...d, value: d?.email}))}
                                    mode={"multiple"}
                                    maxTagCount={1}
                                    optionFilterProp={"label"}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>
                {
                    extraEmail?.length > 0 &&
                    <Row>
                        <Col span={18} push={6}>
                            {
                                extraEmail?.map(email => {
                                    return (
                                        <Tag className={"margin-bottom-0-3"} key={email} closable onClose={() => handleRemove(email)}>{email}</Tag>
                                    )
                                })
                            }
                        </Col>
                    </Row>
                }
                <Form.Item label={(" ")} name={"to_extraInput"} colon={false}>
                    <Input onKeyDown={(e) => extraInputOnKeyDown(e, "to_extraInput")} placeholder={t("message.to_extra_input.placeholder").toString()}/>
                </Form.Item>

                <Form.Item label={t("message.network")} name={"network"} valuePropName={"checked"}>
                    <Checkbox>{t("message.network_note")}</Checkbox>
                </Form.Item>

                <Form.Item label={t("message.email_template")} name={"template"}>
                    <Select
                        options={emailTemplate?.filter(d => d?.type === "custom")?.map(d => ({
                            label: d.displayName || d.name,
                            value: d._id
                        }))}
                        onChange={(e) => setSelectedEmailTemplate(emailTemplate?.find(d => d._id === e))}
                        disabled={mode === "edit" && selectedRecord?.name}
                    />
                </Form.Item>

                <Form.Item label={t("message.sender")} name={"isFixedEmail"}>
                    <Radio.Group
                        options={[
                            {label: t("message.sender_option.user_domain"), value: false},
                            {label: t("message.sender_option.fixed_email"), value: true},
                        ]}
                        onChange={(e) => setIsFixedEmail(e.target.value)}
                    />
                </Form.Item>
                {
                    isFixedEmail ? (
                        <Form.Item label={t("message.sender_email")} name={"from"}>
                            <Select
                                options={senderEmailDropdown}
                            />
                        </Form.Item>
                    ) : isFixedEmail === false ? (
                        <Form.Item label={t("message.email_type")} name={"emailType"}>
                            <Select
                                options={emailTypeDropdown}
                            />
                        </Form.Item>
                    ) : <></>
                }
                {
                    selectedEmailTemplate &&
                    <Tabs
                        items={contentTabs}
                        tabBarExtraContent={
                            !isEditing &&
                            <Button onClick={() => setIsEditing(true)}>{t("message.edit_content")}</Button>
                        }
                        onTabClick={(e) => onTabChangeHandler(e)}
                    />
                }
                {
                    isEditing &&
                    <Editor
                        onInit={(_, editor) => (editorRef.current = editor)}
                        apiKey={brandConfig?.tinyMCEApiKey}
                        init={{
                            height: 500,
                            menubar: true,
                            plugins: [
                                "advlist",
                                "autolink",
                                "lists",
                                "link",
                                "image",
                                "charmap",
                                "preview",
                                "anchor",
                                "searchreplace",
                                "visualblocks",
                                "code",
                                "fullscreen",
                                "insertdatetime",
                                "media",
                                "table",
                                "code",
                                "help",
                                "wordcount",
                            ],
                            toolbar:
                                "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media component link anchor codesample | ltr rtl",
                            content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                        initialValue={initialData?.[`content_${currentLanguage}`]}
                        onEditorChange={(newValue, editor) =>
                            setDataByLanguage(prev => ({
                                ...prev,
                                [`content_${currentLanguage}`]: newValue
                            }))
                        }
                    />
                }
            </Form>
        </Modal>
    )
}

export default SendMessageModal