import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";

const GET_WEB_MODULE_SETTING_COUNT = gql`
  query getWebModuleSettingCount($filter: WebModuleSettingSearchInput) {
    getWebModuleSettingCount(filter: $filter)
  }
`;

const getWebModuleSettingCount = ({ filter }) => {
  try {
    const { loading, error, data } = useQuery(GET_WEB_MODULE_SETTING_COUNT, {
      variables: {
        filter: {
          moduleName: filter?.moduleName || null,
          position: filter?.position || null,
          isLocked: filter?.isLocked || null,
        },
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_WEB_MODULE_SETTING_BY_ID = gql`
  query getWebModuleSettingByID($id: String!) {
    getWebModuleSettingByID(id: $id) {
      ... on WebModuleSetting {
        _id
        moduleId {
          name
        }
        position
        isLocked
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getWebModuleSettingById = (id) => {
  try {
    const { loading, error, data } = useQuery(GET_WEB_MODULE_SETTING_BY_ID, {
      variables: {
        id: id,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_WEB_MODULE_SETTING = gql`
  query getWebModuleSetting(
    $filter: WebModuleSettingSearchInput
    $limit: Int
    $offset: Int
    $orderBy: String
  ) {
    getWebModuleSetting(
      filter: $filter
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      ... on WebModuleSettingArray {
        data {
          _id
          moduleId {
            _id
            name
            isEnabled
          }
          position
          isLocked
          createdBy {
            username
          }
          updatedBy {
            username
          }
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getWebModuleSetting = ({ filter }, limit?, offset?, orderBy?) => {
  try {
    const { loading, error, data } = useQuery(GET_WEB_MODULE_SETTING, {
      variables: {
        filter: {
          position: filter?.position || null,
          isLocked: filter?.isLocked || null,
        },
        limit: limit || null,
        offset: offset || null,
        orderBy: orderBy || null,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const UPDATE_WEB_MODULE_SETTING = gql`
  mutation updateWebModuleSetting($input: WebModuleSettingInput) {
    updateWebModuleSetting(input: $input) {
      ... on CrmTaskApproval {
        msg
      }
      ... on WebModuleSetting {
        id
        moduleId {
          name
        }
        position
        isLocked
        createdBy {
          username
        }
        updatedBy {
          username
        }
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useUpdateWebModuleSettingMutation = (): {
  updateWebModuleSetting: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const { t } = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [updateWebModuleSettingMutation, { data, loading, error }] =
      useMutation(UPDATE_WEB_MODULE_SETTING);

    const updateWebModuleSetting = (input) => {
      return updateWebModuleSettingMutation({
        variables: {
          input: input,
        },
        update(cache, {data: {updateWebModuleSetting}}) {
          const typename = updateWebModuleSetting?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(updateWebModuleSetting?.errKey),
              key: "updateWebModuleSetting",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(updateWebModuleSetting?.msg),
              key: "updateWebModuleSetting",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Update Web Module Setting Successfully"),
              key: "updateWebModuleSetting",
            });
          }
        },
        refetchQueries: [GET_WEB_MODULE_SETTING]
      });
    };

    return { updateWebModuleSetting, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return {
      updateWebModuleSetting: null,
      data: null,
      loading: false,
      error: err,
    };
  }
};

export {
  getWebModuleSettingCount,
  getWebModuleSettingById,
  getWebModuleSetting,
  useUpdateWebModuleSettingMutation,
  GET_WEB_MODULE_SETTING
};
