import React, {useEffect} from "react";
import {Col, Form, Input, Row, Select} from "antd";
import {useTranslation} from "react-i18next";
import {Checkbox} from "antd/lib";

const NewsPanelMt5 = ({initialData}) => {
    const {t} = useTranslation()
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(initialData);
    }, [initialData])
    return (
        <>
            <Row gutter={[16, 16]} style={{marginBottom: "1%", marginTop: "2%"}}>
                <Col span={24}>
                    <p>
                        Please specify the settings of news received by the group and the possibility of
                        using the mail system.
                    </p>
                </Col>
            </Row>

            <Form form={form}>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={24}>
                        <Form.Item
                            name={"newsMode"}
                            label={t("News")}
                            initialValue={initialData?.newsMode}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={"newsCategory"}
                            label={t("News categories")}
                            initialValue={initialData?.newsCategory}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={"newsLangData"}
                            label={t("News languages")}
                            initialValue={initialData?.newsLangData}
                        >
                            <Select
                                mode="multiple"
                                style={{width: '100%'}}
                                placeholder="Please select"
                                disabled={true}
                                options={initialData?.newsLangData?.map((item) => {
                                    return {
                                        label: item,
                                        value: item
                                    }
                                })}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={10}>
                        <Form.Item
                            name={"enableMailSystem"}
                            label={<span style={{color: "red"}}>{t("Enable internal mail system")}</span>}
                        >
                            <Checkbox disabled={true}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default NewsPanelMt5