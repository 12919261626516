import React from "react";
import {getColumnSearchProps, getSingleSelectFilterProps} from "../../../../../components/table/function/filterTable";
import {Tag} from "antd";
import {useRecoilValue} from "recoil";
import {fieldListState} from "../../../../../../recoil_state";
import {useTranslation} from "react-i18next";

const AccountColumn = ({text, user}) => {
    return text > 0 ? <a onClick={() => window.open(`/account/trading-account?user=${user}`)}>{text}</a> : <p>0</p>
}

const StatusTagColumn = ({text}) => {
    const {t} = useTranslation()

    return <Tag bordered={false} color={text === "demo" ? "default" : text === "individual" ? "geekblue" : "green"}>{t(`user.statusTag.${text}`)}</Tag>
}

export const ColumnsClient = (refetchData) => {
    const {t} = useTranslation()
    const fieldList = useRecoilValue<any>(fieldListState)

    return [
        {
            title: t("user.userType"),
            dataIndex: "clientAccountType",
            key: "clientAccountType",
            ...getColumnSearchProps(),
            render: (text) => <StatusTagColumn text={text}/>,
        },
        {
            title: t("user.rank"),
            dataIndex: "rank",
            key: "rank",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("user.referrer"),
            dataIndex: "referrer",
            key: "referrer",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("user.registrationTime"),
            dataIndex: "registrationDate",
            key: "registrationDate",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        // {
        //     title: t("user.registrationTime"),
        //     dataIndex: "createdAt",
        //     key: "createdAt",
        //     ...getColumnSearchProps(),
        //     render: (text) => <p>{text || "-"}</p>,
        // },
        {
            title: t("user.lastDepositDate"),
            dataIndex: "lastDepositDate",
            key: "lastDepositDate",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("user.lastFollowUp"),
            dataIndex: "lastFollowUp",
            key: "lastFollowUp",
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("user.nextFollowUp"),
            dataIndex: "nextFollowUp",
            key: "nextFollowUp",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("user.domain"),
            dataIndex: "domain",
            key: "domain",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("user.language"),
            dataIndex: "language",
            key: "language",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("user.account"),
            dataIndex: "accountCount",
            key: "accountCount",
            ...getColumnSearchProps(),
            align: "center",
            render: (text, record) => <AccountColumn text={text} user={record?.parentId}/>,
        },
        {
            title: t("user.eWallet"),
            dataIndex: "eWallet",
            key: "eWallet",
            align: "right",
            render: (text) => <p>{text?.isFreezed === true ?
                <Tag color={"red"}>{t("ewallet.freeze")}</Tag> : null} {parseFloat(text?.balance || 0)?.toFixed(2)}</p>,
        },
        {
            title: t("user.lastActivitiesDate"),
            dataIndex: "lastActivitiesDate",
            key: "lastActivitiesDate",
            render: (text) => <p>{text ? text : "-"}</p>,
        },
        {
            title: t("user.comment"),
            dataIndex: "comment",
            key: "comment",
            ...getSingleSelectFilterProps(fieldList?.find(d => d?.fieldName === "clientComment")?.optionValue?.map(d => ({
                label: t(d?.label),
                value: d?.value
            }))),
            render: (text) => <p>{text || "-"}</p>,
        },
    ]
}