import React, {useCallback, useEffect, useState} from "react";
import {
    Avatar,
    Button,
    Card,
    Col,
    Divider,
    Dropdown,
    Form,
    Input,
    Menu,
    message,
    Modal,
    Popconfirm,
    Radio,
    Row,
    Select,
    Space,
    Switch,
    Tag,
    Tooltip,
    Upload,
} from "antd";
import {useTranslation} from "react-i18next";
import {DeleteOutlined, FlagOutlined, UploadOutlined, WarningTwoTone} from "@ant-design/icons";
import {MenuProps} from "antd/lib";
import {useRecoilValue} from "recoil";
import {brandDomainOptionsState, rankOptionsState, userOptionsState} from "../../../../../_common/state";
import useAuthorize from "../../../../../../../_common/authorize";
import GoLiveModalForm from "./goLiveModalForm";
import {getConfigByName} from "../../../../../../../../config";
import {BRAND_NAME} from "../../../../../../../../config/brandVariable";
import {useIsMobile} from "../../../../../../../_common/layout";
import CommRuleModal from "./commRuleModal";
import ShiftReferrerModal from "./shiftReferrerModal";
import RestoreArchivedModal from "./restoreArchivedModal";
import {
    useDeleteUserMutation,
    useRecoverClientUserMutation,
    useUpdateEwalletDepositStatusMutation,
    useUpdateEwalletWithdrawalStatusMutation,
    useUpdateFreezeStatusMutation,
    useUpdateGeneralUserMutation,
    useUpdateUserDomainMutation,
    useUpdateUserRankMutation,
    useUpdateUserReferrerMutation,
    useUpdateUserSalesRepMutation,
    useUpdateUserSuspiciousMutation,
} from "../../../../../../../api/graphql/user";
import {useAddEWalletTransferMutation} from "../../../../../../../api/graphql/eWalletTransfer";
import {useAddEWalletAdjustmentMutation} from "../../../../../../../api/graphql/eWalletAdjustment";
import {useSendEmailMutation} from "../../../../../../../api/graphql/emailTemplate";
import {useTempLoginMutation} from "../../../../../../../api/graphql/auth";
import {useGetUserDropdownWithAdditionalIds} from "../../../function/useQueryHooks";
import {formatDateTimeTimezone} from "../../../../../../../function/_common";

const ProfileCard = (props) => {
    const {data, permissionUser, fieldForLogin, referralSetting, refetchUserByID, refetchEWalletTransaction} = props;
    const {t, i18n} = useTranslation();
    const labelCol = {span: 8, offset: 0};
    const {systemErrorNotification, getUserIPAddress, baseErrorChecking} = useAuthorize();
    const isMobile = useIsMobile(768);
    const brandConfig = getConfigByName(BRAND_NAME);

    const [goLiveIsOpen, setGoLiveIsOpen] = useState<any>(false);
    const [commRuleModalIsOpen, setCommRuleModalIsOpen] = useState(false);
    const [restoreArchivedModalOpen, setRestoreArchivedModalOpen] = useState(false);
    const [isOpen, setIsOpen] = useState<any>();
    const [formXML, setFormXML] = useState<any>();
    const [formTitle, setFormTitle] = useState<any>();
    const [clientStatus, setClientStatus] = useState<any>();
    const [shiftReferrerModalIsOpen, setShiftReferrerModalIsOpen] = useState(false);

    // MUTATION
    const {deleteUser} = useDeleteUserMutation();
    const {addEWalletTransfer} = useAddEWalletTransferMutation();
    const {addEWalletAdjustment} = useAddEWalletAdjustmentMutation();
    const {sendEmail} = useSendEmailMutation();
    const {updateSuspicousStatus} = useUpdateUserSuspiciousMutation();
    const {updateUserReferrer} = useUpdateUserReferrerMutation();
    const {updateUserSalesRep} = useUpdateUserSalesRepMutation();
    const {updateFreezeStatus} = useUpdateFreezeStatusMutation();
    const {updateGeneralUser, loading} = useUpdateGeneralUserMutation();
    const {updateUserRank} = useUpdateUserRankMutation();
    const {recoverClientUser} = useRecoverClientUserMutation();
    const {updateEwalletWithdrawalStatus, loading: updateWithdrawalLoading} = useUpdateEwalletWithdrawalStatusMutation();
    const {updateEwalletDepositStatus, loading: updateDepositLoading} = useUpdateEwalletDepositStatusMutation();
    const {updateUserDomain} = useUpdateUserDomainMutation();
    const {tempLogin} = useTempLoginMutation();

    // DROPDOWN OPTIONS
    const [referralOptions, fetchReferralOptions] = useGetUserDropdownWithAdditionalIds()
    const [salesRepOption, fetchSalesRepOptions] = useGetUserDropdownWithAdditionalIds()
    const rankOptions = useRecoilValue<any>(rankOptionsState)
    const userOptions = useRecoilValue<any>(userOptionsState);
    const brandDropdownOptions = useRecoilValue(brandDomainOptionsState)

    useEffect(() => {
        fetchReferralOptions([data?.referrer?._id])
    }, [data?.referrer?._id]);

    useEffect(() => {
        fetchSalesRepOptions([data?.salesRep?._id])
    }, [data?.salesRep?._id]);

    const freezeOnClick = async () => {
        try {
            if (data?.eWallet?.isFreezed) await updateFreezeStatus(data?._id, {isFreezed: false});
            else await updateFreezeStatus(data?._id, {isFreezed: true});

            refetchUserByID()
        } catch (e) {
            console.log(e);
            systemErrorNotification();
        }
    };

    const onManualVerify = async () => {
        try {
            await updateGeneralUser(data?._id, {verifiedEmail: true});
            refetchUserByID()
        } catch (e) {
            console.log(e);
            systemErrorNotification();
        }
    };

    const onResendEmail = () => {
        try {
            sendEmail({
                name: "Activation",
                to: [data?.email],
                language: i18n.language,
                sendUserType: "client",
            });
        } catch (e) {
            console.log(e);
            systemErrorNotification();
        }
    };

    const onRevertToDemo = async () => {
        try {
            await updateGeneralUser(new URLSearchParams(location.search).get("id"), {clientAccountType: "demo"});
            refetchUserByID()
        } catch (e) {
            console.log(e);
            systemErrorNotification();
        }
    };

    const recoverAccount = async () => {
        try {
            await recoverClientUser(data?._id);
            refetchUserByID()
        } catch (e) {
            console.log(e);
            systemErrorNotification();
        }
    };

    const userVerificationTag = (type?) => {
        if (data?.status) {
            switch (data?.status) {
                case "draft verification":
                    return (
                        <Tag color={"volcano"}>
                            {t("user.statusTag.draftVerification")} {type ? t(`accountVerification.clientTypeOption.${type}`) : ""}
                        </Tag>
                    );
                case "verification failed":
                    return (
                        <Tag color={"orange"}>
                            {t("user.statusTag.verificationFail")} {type ? t(`accountVerification.clientTypeOption.${type}`) : ""}
                        </Tag>
                    );
                case "not verified":
                    return (
                        <Tag color={"orange"}>
                            {t("user.statusTag.not_verified")} {type ? t(`accountVerification.clientTypeOption.${type}`) : ""}
                        </Tag>
                    );
                case "pending verification":
                    return (
                        <Tag color={"yellow"}>
                            {t("user.statusTag.pending_verification")} {type ? t(`accountVerification.clientTypeOption.${type}`) : ""}
                        </Tag>
                    );
                case "verified":
                    return (
                        <Tag color={"green"}>
                            {t("user.statusTag.verified")} {type ? t(`accountVerification.clientTypeOption.${type}`) : ""}
                        </Tag>
                    );
                case "verification expired":
                    return (
                        <Tag color={"yellow"}>
                            {t("user.statusTag.expired")} {type ? t(`accountVerification.clientTypeOption.${type}`) : ""}
                        </Tag>
                    );
                case "restricted until remediation":
                    return (
                        <Tag color={"red"}>
                            {t("user.statusTag.restricted_until_remediation")} {type ? t(`accountVerification.clientTypeOption.${type}`) : ""}
                        </Tag>
                    );
                case "restricted until closure":
                    return (
                        <Tag color={"red"}>
                            {t("user.statusTag.restricted_until_closure")} {type ? t(`accountVerification.clientTypeOption.${type}`) : ""}
                        </Tag>
                    );
            }
        } else return;
    };

    const renderClientStatus = useCallback(async () => {
        const tagColor = data?.clientAccountType === "demo" ? "geekblue" : "gold";
        const accountType =
            data?.clientAccountType &&
            (data?.clientAccountType === "individual" ? (
                <>
                    {permissionUser?.clientsUser?.edit?.edit ? (
                        <Dropdown
                            dropdownRender={() => (
                                <Menu>
                                    <Menu.Item key="1">
                                        <a key="revertLive" onClick={onRevertToDemo}>
                                            {t("user.revertToDemo")}
                                        </a>
                                    </Menu.Item>
                                </Menu>
                            )}
                            placement="top"
                            trigger={["click"]}
                            arrow
                        >
                            <a>
                                <Tag color={"blue"}>{t(`user.statusTag.${data?.clientAccountType}`)}</Tag>
                            </a>
                        </Dropdown>
                    ) : (
                        <Tag color={"blue"}>{t(`user.statusTag.${data?.clientAccountType}`)}</Tag>
                    )}
                </>
            ) : (
                <Tag color={tagColor}>{t(`user.statusTag.${data?.clientAccountType}`)}</Tag>
            ));

        const items: MenuProps["items"] = [
            {
                key: "manualVerify",
                label: <a onClick={onManualVerify}>{t("user.manualVerify")}</a>,
            },
            {
                key: "resendEmail",
                label: <a onClick={onResendEmail}>{t("user.resendEmail")}</a>,
            },
        ];
        if (data?.isDeletedByClient) {
            return (
                <>
                    {permissionUser?.clientsUser?.edit?.edit ? (
                        <Dropdown
                            dropdownRender={() => (
                                <Menu>
                                    <Menu.Item key="1" onClick={() => recoverAccount()}>
                                        <a key="restoreAccount">{t("user.restoreAccount")}</a>
                                    </Menu.Item>
                                </Menu>
                            )}
                            placement="top"
                            trigger={["click"]}
                            arrow
                        >
                            <a>
                                <Tag color={"red"}>{t("user.statusTag.deletedByClient")}</Tag>
                            </a>
                        </Dropdown>
                    ) : (
                        <Tag color={"red"}>{t("user.statusTag.deletedByClient")}</Tag>
                    )}
                    {accountType}
                </>
            );
        } else if (data?.verifiedEmail === false) {
            return (
                <>
                    {permissionUser?.clientsUser?.edit?.edit ? (
                        <Dropdown menu={{items}} placement="top" trigger={["click"]} arrow>
                            <a>
                                <Tag color={"red"}>{t("user.statusTag.pendingEmailVerification")}</Tag>
                            </a>
                        </Dropdown>
                    ) : (
                        <Tag color={"red"}>{t("user.statusTag.pendingEmailVerification")}</Tag>
                    )}
                    {accountType}
                </>
            );
        } else if (data?.clientAccountType === "corporate") {
            return (
                <>
                    {userVerificationTag()}
                    {accountType}
                </>
            );
        } else if (data?.isKycApproved && data?.clientAccountType === "individual" && data?.status !== "verified") {
            return (
                <>
                    {userVerificationTag("kyb")}
                    <Tag color={"green"}>
                        {t("user.statusTag.verified")} {t(`accountVerification.clientTypeOption.kyc`)}
                    </Tag>
                    {accountType}
                </>
            );
        } else if (data?.status) {
            return (
                <>
                    {userVerificationTag()}
                    {accountType}
                </>
            );
        } else {
            return accountType;
        }
    }, [data?.clientAccountType, data?.status, data?.isKycApproved]);

    useEffect(() => {
        if (data) renderClientStatus()?.then((xml) => setClientStatus(xml));
    }, [data]);

    const [form] = Form.useForm();
    useEffect(() => {
        form.resetFields();
    }, [isOpen]);

    const transferOnClick = () => {
        setFormTitle(t("ewallet.transfer"));
        setFormXML(
            <Form onFinish={transferOnSubmit} form={form}>
                <Space direction={"vertical"} style={{width: "100%"}}>
                    <Form.Item label={t("ewallet.balance")} name={"balance"} labelCol={labelCol}>
                        <>$ {parseFloat(data?.eWallet?.balance)?.toFixed(2) || "0.00"}</>
                    </Form.Item>
                    <Form.Item
                        label={t("common.amount")}
                        name={"amount"}
                        labelCol={labelCol}
                        rules={[
                            {
                                required: true,
                                message: t("errorMsg.required.pleaseFillIn", {field: t("common.amount")}).toString(),
                            },
                        ]}
                    >
                        <Input placeholder={t("common.amount").toString()}/>
                    </Form.Item>
                    <Form.Item
                        label={t("ewallet.transferTo")}
                        name={"toUserId"}
                        labelCol={labelCol}
                        rules={[
                            {
                                required: true,
                                message: t("errorMsg.required.pleaseFillIn", {field: t("ewallet.transferTo")}).toString(),
                            },
                        ]}
                    >
                        <Select
                            options={userOptions?.filter((item) => item?.value !== data?._id?.toString())}
                            showSearch
                            optionFilterProp={"label"}
                            optionLabelProp={"selectedLabel"}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t("common.comment")}
                        name={"comment"}
                        labelCol={labelCol}
                        rules={[
                            {
                                required: true,
                                message: t("errorMsg.required.pleaseFillIn", {field: t("common.comment")}).toString(),
                            },
                        ]}
                    >
                        <Input placeholder={t("common.comment").toString()}/>
                    </Form.Item>
                </Space>
                <Row className={"display-flex margin-top-0-75"}>
                    <Space className="margin-left-auto">
                        <Button onClick={() => setIsOpen(false)}>{t("common.cancel")}</Button>
                        <Form.Item>
                            <Button type={"primary"} htmlType={"submit"}>
                                {t("common.submit")}
                            </Button>
                        </Form.Item>
                    </Space>
                </Row>
            </Form>
        );
        setIsOpen(true);
    };

    const transferOnSubmit = async (val) => {
        try {
            if (!parseFloat(val?.amount)) {
                form.setFields([
                    {
                        name: "amount",
                        value: "",
                        errors: [t("errorMsg.type.invalid", {field: t("common.amount")})],
                    },
                ]);
            } else {
                await addEWalletTransfer({
                    ...val,
                    fromUserId: data?._id,
                });

                refetchUserByID()
                refetchEWalletTransaction()
                setIsOpen(false);
            }
        } catch (e) {
            systemErrorNotification();
        }
    };

    const adjustmentOnClick = () => {
        setFormTitle(t("ewallet.adjustment"));
        setFormXML(
            <Form form={form} onFinish={adjustmentOnSubmit} labelAlign={"left"}>
                <Space direction={"vertical"} style={{width: "100%"}}>
                    <Form.Item label={t("ewallet.balance")} name={"balance"} labelCol={labelCol}>
                        <>$ {parseFloat(data?.eWallet?.balance)?.toFixed(2) || "0.00"}</>
                    </Form.Item>
                    <Form.Item
                        label={t("common.transactionType")}
                        name={"type"}
                        labelCol={labelCol}
                        rules={[
                            {
                                required: true,
                                message: t("errorMsg.required.pleaseFillIn", {field: t("common.transactionType")}).toString(),
                            },
                        ]}
                    >
                        <Radio.Group>
                            <Radio.Button value={"deposit"}>{t("common.deposit")}</Radio.Button>
                            <Radio.Button value={"withdrawal"}>{t("common.withdrawal")}</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label={t("common.amount")}
                        name={"amount"}
                        labelCol={labelCol}
                        rules={[
                            {
                                required: true,
                                message: t("errorMsg.required.pleaseFillIn", {field: t("common.amount")}).toString(),
                            },
                        ]}
                    >
                        <Input placeholder={t("common.amount").toString()}/>
                    </Form.Item>
                    <Form.Item
                        label={t("common.comment")}
                        name={"comment"}
                        labelCol={labelCol}
                        rules={[
                            {
                                required: true,
                                message: t("errorMsg.required.pleaseFillIn", {field: t("common.comment")}).toString(),
                            },
                        ]}
                    >
                        <Input placeholder={t("common.comment").toString()}/>
                    </Form.Item>
                </Space>
                <Row className={"display-flex margin-top-0-75"}>
                    <Space className="margin-left-auto">
                        <Button onClick={() => setIsOpen(false)}>{t("common.cancel")}</Button>
                        <Form.Item>
                            <Button type={"primary"} htmlType={"submit"}>
                                {t("common.submit")}
                            </Button>
                        </Form.Item>
                    </Space>
                </Row>
            </Form>
        );
        setIsOpen(true);
    };

    const adjustmentOnSubmit = async (value) => {
        try {
            if (!parseFloat(value?.amount)) {
                form.setFields([
                    {
                        name: "amount",
                        value: "",
                        errors: [t("errorMsg.type.invalid", {field: t("common.amount")})],
                    },
                ]);
            } else {
                await addEWalletAdjustment({
                    ...value,
                    user: data?._id,
                });

                refetchUserByID()
                refetchEWalletTransaction()
                setIsOpen(false);
            }
        } catch (e) {
            console.log(e);
            systemErrorNotification();
        }
    };

    const deleteUserOnConfirm = async () => {
        try {
            const response = await deleteUser(data?._id);
            if (response?.data?.deleteUser?.__typename === "User") window.location.reload();
            else if (
                response?.data?.deleteUser?.__typename === "BaseError" &&
                response?.data?.deleteUser?.errKey === "admin.userNetwork.err.existing_downline"
            ) {
                setShiftReferrerModalIsOpen(true);
            }
        } catch (e) {
            console.log(e);
            systemErrorNotification();
        }
    };

    const ghostLoginOnClick = async () => {
        try {
            const ip = await getUserIPAddress();

            const response = await tempLogin({
                login: data.username,
                ip: ip,
                type: "client",
            });

            const login = response?.data?.tempLogin;
            if (login?.__typename === "AuthPayload") {
                let subDomain = "member";
                // if (["antospnc"]?.includes(BRAND_NAME)) {
                //     // Debug - Temporary
                //     subDomain = "memberstg";
                // }
                window.open(
                    `https://${subDomain}.${data?.domain?.replace("secure.", "")?.replace("https://", "")}/#/dashboard?token=${response?.data?.tempLogin?.accessToken}`,
                    "_blank",
                    "noreferrer"
                );
            } else {
                baseErrorChecking(login);
            }
        } catch (e) {
            console.log(e);
            systemErrorNotification();
        }
    };

    const onRestoreModalClick = () => {
        setRestoreArchivedModalOpen(true);
    };

    const uploadOnChange = async (e) => {
        try {
            if (e?.file?.status === "done") {
                await updateGeneralUser(new URLSearchParams(location.search).get("id"), {profileImage: e?.file?.response?.[0]?.link});
                refetchUserByID()
            } else if (e?.file?.status === "error") {
                message.error(`${e?.file?.name} file upload failed.`);
            }
        } catch (e) {
            systemErrorNotification();
        }
    };

    const handleCopy = (link) => {
        try {
            const affiliateLink = link;
            const textField = document.createElement("textarea");
            textField.innerText = affiliateLink;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand("copy");
            textField.remove();

            message.success(t("user.successMsg.affiliateLinkCopiedToClipboard"));
        } catch (e) {
            console.log(e);
            systemErrorNotification();
        }
    };

    const suspiciousItem: any = [
        {
            key: "revert",
            label: (
                <Menu.Item onClick={async () => {
                    await updateSuspicousStatus(data?._id, {isSuspicious: false})
                    refetchUserByID()
                }}>
                    <a>{t("common.revert")}</a>
                </Menu.Item>
            ),
        },
    ];

    const blockDepositOnClick = async (value) => {
        try {
            await updateEwalletDepositStatus(data?._id, {isDepositEnable: value});
            refetchUserByID()
        } catch (e) {
            console.log(e);
            systemErrorNotification();
        }
    };

    const blockWithdrawalOnClick = async (value) => {
        try {
            await updateEwalletWithdrawalStatus(data?._id, {isWithdrawalEnable: value});
            refetchUserByID()
        } catch (e) {
            console.log(e);
            systemErrorNotification();
        }
    };

    const liveReferralLinkPath = `https://member.${data?.domain?.replace("https://", "")}/#/register?type=live&affiliate=${data?.affiliateCode}`;
    const demoReferralLinkPath = `https://member.${data?.domain?.replace("https://", "")}/#/register?type=demo&affiliate=${data?.affiliateCode}`;
    const homePageLinkPath = `${data?.domain}/?affiliate=${data?.affiliateCode}`;

    return (
        <Card style={{width: "100%"}}>
            <Form labelWrap colon={false}>
                <Row justify={"space-between"}>
                    <Space>
                        <Tooltip title={t("Upload Profile Image")}>
                            <Upload
                                action={`${brandConfig?.backendURL}/api/upload/profile/image`}
                                listType="picture-circle"
                                maxCount={1}
                                showUploadList={false}
                                defaultFileList={
                                    data?.profileImage
                                        ? [
                                            {
                                                uid: "profile-image",
                                                name: "profile-image",
                                                url: data?.profileImage,
                                            },
                                        ]
                                        : []
                                }
                                onChange={(e) => uploadOnChange(e)}
                                className={"profile-image"}
                            >
                                <Avatar
                                    src={data?.profileImage || "https://xsgames.co/randomusers/avatar.php?g=pixel&key=2"}
                                    size={64}
                                    icon={<UploadOutlined/>}
                                />
                            </Upload>
                        </Tooltip>
                        <div>
                            <Row className={"margin-bottom-0-3"}>
                                <h1 style={{marginBottom: 0, lineHeight: 2}}>
                                    {data?.username}{" "}
                                    {data?.fullName && (
                                        <Tooltip title={data?.fullName?.length > 30 ? data?.fullName : null}>
                                            ({data?.fullName?.slice(0, 27)}
                                            {data?.fullName?.length > 30 ? "..." : ""})
                                        </Tooltip>
                                    )}
                                    {/*{data?.fullName ? `${data?.username} (${data?.fullName})` : data?.username}*/}
                                </h1>
                                {data?.isSuspicious && (
                                    <>
                                        {permissionUser?.clientsUser?.edit?.edit ? (
                                            <Dropdown
                                                overlay={
                                                    <Menu>
                                                        {suspiciousItem.map((item) => {
                                                            return <Menu.Item key={item.key}>{item.label}</Menu.Item>;
                                                        })}
                                                    </Menu>
                                                }
                                                placement="top"
                                                trigger={["click"]}
                                                arrow
                                            >
                                                <Tooltip title={t("common.suspicious")} placement={"right"}>
                                                    <Button type={"link"} icon={<WarningTwoTone twoToneColor={"#FFCC00"}/>}/>
                                                </Tooltip>
                                            </Dropdown>
                                        ) : (
                                            <Tooltip title={t("common.suspicious")} placement={"right"}>
                                                <WarningTwoTone twoToneColor={"#FFCC00"}/>
                                            </Tooltip>
                                        )}
                                    </>
                                )}
                                {permissionUser?.clientsUser?.delete?.delete && !data?.isDeleted && isMobile && (
                                    <Popconfirm
                                        placement="left"
                                        title={t("clientUser.delete.popconfirm.title")}
                                        description={t("clientUser.delete.popconfirm.description")}
                                        onConfirm={() => {
                                            deleteUserOnConfirm();
                                        }}
                                        okText={t("common.yes")}
                                        cancelText={t("common.no")}
                                    >
                                        <Button type={"link"} danger icon={<DeleteOutlined/>}/>
                                    </Popconfirm>
                                )}
                                {permissionUser?.clientsUser?.delete?.delete && (
                                    <ShiftReferrerModal
                                        isOpen={shiftReferrerModalIsOpen}
                                        setIsOpen={setShiftReferrerModalIsOpen}
                                        referrerOptions={userOptions?.filter((d) => d?.value !== data?._id)}
                                        data={data}
                                    />
                                )}
                            </Row>
                            {clientStatus}
                            {data?.commRule?.length > 0 &&
                                data?.commRule?.map((d) => {
                                    const name = (d?.commRuleType === "commissionRule" ? d?.ruleName : d?.groupName) || "";
                                    const isTooltipNeeded = name.length > 20;
                                    const truncatedRuleName = name.length > 20 ? name.slice(0, 20) + "..." : name;

                                    return (
                                        <Tooltip title={isTooltipNeeded ? name : undefined}>
                                            <Tag
                                                onClick={() => setCommRuleModalIsOpen(true)}
                                                className={permissionUser?.clientsUser?.edit?.commRule && "clickable"}
                                            >
                                                {truncatedRuleName}
                                            </Tag>
                                        </Tooltip>
                                    );
                                })}
                            {data?.commRule?.length > 0 && permissionUser?.clientsUser?.edit?.commRule && (
                                <CommRuleModal isOpen={commRuleModalIsOpen} setIsOpen={setCommRuleModalIsOpen} user={data}/>
                            )}
                        </div>
                    </Space>
                    {permissionUser?.clientsUser?.edit?.edit && (
                        <Space wrap>
                            <>
                                {data?.clientAccountType === "demo" && !data?.isDeleted && (
                                    <Button
                                        onClick={() => {
                                            setGoLiveIsOpen(true);
                                        }}
                                    >
                                        {t("user.goLive")}
                                    </Button>
                                )}
                                {data?.clientAccountType === "corporate" && !data?.isDeleted && (
                                    <Button
                                        loading={loading}
                                        onClick={async () => {
                                            await updateGeneralUser(data?._id, {
                                                clientAccountType: "individual",
                                            })
                                            refetchUserByID()
                                        }}
                                    >
                                        {t("user.downgrade")}
                                    </Button>
                                )}
                            </>
                            {!data?.isDeleted && (
                                <Dropdown
                                    overlay={
                                        <Menu>
                                            {referralSetting?.demoReferralLink && (
                                                <Menu.Item onClick={() => handleCopy(demoReferralLinkPath)}>
                                                    <Tooltip title={demoReferralLinkPath}>{t("referral.demo")}</Tooltip>
                                                </Menu.Item>
                                            )}
                                            {referralSetting?.liveReferralLink && (
                                                <Menu.Item onClick={() => handleCopy(liveReferralLinkPath)}>
                                                    <Tooltip title={liveReferralLinkPath}>{t("referral.live")}</Tooltip>
                                                </Menu.Item>
                                            )}
                                            {referralSetting?.referralLink && (
                                                <Menu.Item onClick={() => handleCopy(homePageLinkPath)}>
                                                    <Tooltip title={homePageLinkPath}>{t("referral.homepage")}</Tooltip>
                                                </Menu.Item>
                                            )}
                                        </Menu>
                                    }
                                >
                                    <Button>{t("user.copyAffiliateLink")}</Button>
                                </Dropdown>
                            )}
                            {permissionUser?.clientsUser?.delete?.restore && data?.isDeleted && (
                                <Button onClick={() => onRestoreModalClick()}>{t("common.restore")}</Button>
                            )}
                            {permissionUser?.clientsUser?.delete?.restore && data?.isDeleted && (
                                <RestoreArchivedModal
                                    isOpen={restoreArchivedModalOpen}
                                    setIsOpen={setRestoreArchivedModalOpen}
                                    data={data}
                                    referrerOptions={userOptions?.filter((d) => d?.value !== data?._id)}
                                />
                            )}
                            {permissionUser?.clientsUser?.login && !data?.isDeleted && (
                                <Button onClick={() => ghostLoginOnClick()}>{t("common.login")}</Button>
                            )}
                            {data?.isSuspicious === false && !data?.isDeleted && (
                                <Popconfirm
                                    placement="left"
                                    title={t("clientUser.suspicious.popconfirm.title")}
                                    description={t("user.suspiciousUser")}
                                    onConfirm={async () => {
                                        await updateSuspicousStatus(data?._id, {
                                            isSuspicious: true,
                                        });
                                        refetchUserByID()
                                    }}
                                    okText={t("common.yes")}
                                    cancelText={t("common.no")}
                                >
                                    <Button icon={<FlagOutlined/>} danger>
                                        {t("common.suspicious")}
                                    </Button>
                                </Popconfirm>
                            )}
                            {permissionUser?.clientsUser?.delete?.delete && !data?.isDeleted && !isMobile && !data?.isDeleted && (
                                <Popconfirm
                                    placement="left"
                                    title={t("clientUser.delete.popconfirm.title")}
                                    description={t("clientUser.delete.popconfirm.description")}
                                    onConfirm={() => {
                                        deleteUserOnConfirm();
                                    }}
                                    okText={t("common.yes")}
                                    cancelText={t("common.no")}
                                >
                                    <Button type={"link"} danger icon={<DeleteOutlined/>}/>
                                </Popconfirm>
                            )}
                        </Space>
                    )}
                </Row>
                <Divider/>
                <Row gutter={[16, 2]}>
                    <Col span={12}>
                        <Form.Item labelAlign={"left"} label={t("user.referrer")} labelCol={labelCol} initialValue={data?.referrer?._id}>
                            <Select
                                value={referralOptions ? data?.referrer?._id : null}
                                placeholder={t("user.referrer")}
                                options={referralOptions?.filter((d) => d?.value !== data?._id)}
                                optionFilterProp={"label"}
                                showSearch
                                onChange={async (value) => {
                                    await updateUserReferrer(data?._id, {referrer: value})
                                    refetchUserByID()
                                }}
                                style={{width: "100%"}}
                                disabled={!permissionUser?.clientsUser?.edit?.referrer || data?.isDeleted}
                                optionLabelProp={"selectedLabel"}
                            />
                        </Form.Item>
                        <Form.Item labelAlign={"left"} label={t("user.salesRep")} labelCol={labelCol} initialValue={data?.salesRep?._id}>
                            <Select
                                value={salesRepOption ? data?.salesRep?._id : null}
                                placeholder={t("user.salesRep")}
                                options={salesRepOption?.filter((d) => d?.value !== data?._id)}
                                optionFilterProp={"label"}
                                showSearch
                                onChange={async (value) => {
                                    await updateUserSalesRep(data?._id, {salesRep: value})
                                    refetchUserByID()
                                }}
                                style={{width: "100%"}}
                                disabled={!permissionUser?.clientsUser?.edit?.salesRep || data?.isDeleted}
                                optionLabelProp={"selectedLabel"}
                            />
                        </Form.Item>
                        <Form.Item labelAlign={"left"} label={t("user.rank")} labelCol={labelCol}>
                            <Select
                                placeholder={t("user.rank")}
                                options={rankOptions?.filter((d) => !d?.isDefault && d?.type === "client")}
                                optionFilterProp={"label"}
                                showSearch
                                style={{width: "100%"}}
                                value={data?.rank?.isDefaultRank || !rankOptions?.find((d) => d?.value === data?.rank?._id) ? null : data?.rank?._id}
                                disabled={!permissionUser?.clientsUser?.edit?.rank || data?.isDeleted}
                                onChange={async (value) => {
                                    try {
                                        await updateUserRank(data?._id, {rank: value});
                                        refetchUserByID()
                                    } catch (e) {
                                        console.log(e);
                                        systemErrorNotification();
                                    }
                                }}
                            />
                        </Form.Item>
                        <Form.Item labelAlign={"left"} label={t("user.domain")} labelCol={labelCol}>
                            <Select
                                placeholder={t("user.domain")}
                                options={brandDropdownOptions}
                                optionFilterProp={"label"}
                                showSearch
                                style={{width: "100%"}}
                                value={data?.domain}
                                disabled={!permissionUser?.clientsUser?.edit?.edit || data?.isDeleted}
                                onChange={async (value) => {
                                    try {
                                        await updateUserDomain([data?._id], value);
                                        refetchUserByID()
                                    } catch (e) {
                                        console.log(e);
                                        systemErrorNotification();
                                    }
                                }}
                            />
                        </Form.Item>
                        <Row>
                            <Col span={8}>
                                <label>{t("user.lastActivitiesDate")}</label>
                            </Col>
                            <p>{data?.lastActivitiesDate ? formatDateTimeTimezone(data?.lastActivitiesDate) : "-"}</p>
                        </Row>
                    </Col>
                    <Col span={12}>
                        {permissionUser?.clientsUser?.view?.ewallet && (
                            // <Form.Item labelAlign={"left"} label={t("ewallet.balance")}
                            //            labelCol={{span: 8, offset: 0}} wrapperCol={{span: 16}}>
                            //     <Input
                            //         placeholder={t("ewallet.balance").toString()}
                            //         value={parseFloat(data?.eWallet?.balance)?.toFixed(2) || "0.00"}
                            //         style={{width: "100%"}}
                            //         disabled
                            //     />
                            // </Form.Item>
                            <Row className={"margin-bottom-0-5"}>
                                <Col xs={24} sm={8}>
                                    <label>{t("ewallet.balance")}</label>
                                </Col>
                                <Col xs={24} sm={16}>
                                    <strong>
                                        {/* {formatMoney(data?.eWallet?.balance ? Math.floor(parseFloat(data?.eWallet?.balance) * 100) / 100 : 0)} USD */}
                                        {data?.eWallet?.balance ? parseFloat(data?.eWallet?.balance)?.toFixed(2) : 0} USD
                                    </strong>
                                </Col>
                            </Row>
                        )}
                        {permissionUser?.clientsUser?.edit?.ewalletBlockDeposit && (
                            <Form.Item labelAlign={"left"} name={"isDepositEnable"} label={t("user.isDepositEnabled")} labelCol={{span: 8}}>
                                <Switch
                                    loading={updateDepositLoading}
                                    checked={data?.eWallet?.isDepositEnable}
                                    onChange={(value) => blockDepositOnClick(value)}
                                    disabled={data?.isDeleted}
                                />
                            </Form.Item>
                        )}
                        {permissionUser?.clientsUser?.edit?.ewalletBlockWithdrawal && (
                            <Form.Item labelAlign={"left"} name={"isWithdrawalEnable"} label={t("user.isWithdrawalEnable")} labelCol={{span: 8}}>
                                <Switch
                                    className={"margin-left-auto"}
                                    loading={updateWithdrawalLoading}
                                    checked={data?.eWallet?.isWithdrawalEnable}
                                    onChange={(value) => blockWithdrawalOnClick(value)}
                                    disabled={data?.isDeleted}
                                />
                            </Form.Item>
                        )}
                        {!isMobile && (
                            <Space wrap className={"margin-top-0-75"}>
                                {permissionUser?.clientsUser?.edit?.ewalletTransfer && !data?.isDeleted && (
                                    <Button onClick={() => transferOnClick()}>{t("common.transfer")}</Button>
                                )}
                                {permissionUser?.clientsUser?.edit?.ewalletAdjustment && !data?.isDeleted && (
                                    <Button onClick={() => adjustmentOnClick()}>{t("common.adjustment")}</Button>
                                )}
                            </Space>
                        )}
                    </Col>
                    {isMobile && (
                        <Space wrap className={"margin-left-auto"}>
                            {permissionUser?.clientsUser?.edit?.ewalletTransfer && (
                                <Button onClick={() => transferOnClick()}>{t("common.transfer")}</Button>
                            )}
                            {permissionUser?.clientsUser?.edit?.ewalletAdjustment && (
                                <Button onClick={() => adjustmentOnClick()}>{t("common.adjustment")}</Button>
                            )}
                        </Space>
                    )}
                </Row>
            </Form>

            <Modal
                title={formTitle}
                open={isOpen}
                footer={null}
                onCancel={() => {
                    form.resetFields();
                    setIsOpen(false);
                }}
                width={500}
                destroyOnClose={true}
            >
                {formXML}
            </Modal>

            {
                data?.clientAccountType === "demo" &&
                <GoLiveModalForm
                    isOpen={goLiveIsOpen}
                    setIsOpen={setGoLiveIsOpen}
                    data={data}
                />
            }
        </Card>
    );
};

export default ProfileCard;
