import {atom} from "recoil";

// Send Email Action
export const selectedEmailTemplateState = atom({
    key: "selectedEmailTemplateState",
    default: null,
});

export const dataByLanguageState = atom({
    key: "dataByLanguageState",
    default: {},
});

export const logicOperatorAryState = atom({
    key: "logicOperatorAryState",
    default: [],
})