import React from "react";
import {Button, Radio, Row, Select, Switch} from "antd";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useContextMenuFunctions} from "../../../function/contextMenuFunctions";

const PodiumMenuItems = ({setDetailData, setting, getContainer}) => {
    const {handleFormInputValueChange} = useContextMenuFunctions(setDetailData)

    const handleDragEnd = (result) => {
        const {source, destination} = result;

        if (!destination) return;

        const reorderedItems = Array.from(setting?.sort);
        const [removed] = reorderedItems.splice(source.index, 1);
        reorderedItems.splice(destination.index, 0, removed);

        handleFormInputValueChange(reorderedItems, "sort")
    };

    const onDisplayCountChange = (value) => {
        const items = Array.from({ length: value }, (_, i) => (i + 1).toString())

        handleFormInputValueChange(value, "displayCount")
        handleFormInputValueChange(items, "sort")
    }

    return (
        <>
            <div className={"sections"}>
                <p>Number display</p>
                <Select
                    getPopupContainer={getContainer}
                    options={[
                        {label: 3, value: 3},
                        {label: 5, value: 5}
                    ]}
                    value={setting?.displayCount}
                    onSelect={onDisplayCountChange}
                />
            </div>

            <div className={"sections"}>
                <Row justify={"space-between"} align={"middle"}>
                    <p className={"margin-bottom-0"}>Display Username</p>
                    <Switch checked={setting?.showUsername} onChange={value => handleFormInputValueChange(value, "showUsername")} />
                </Row>

                <Row justify={"space-between"} className={"margin-top-0-75"} align={"middle"}>
                    <p className={"margin-bottom-0"}>Display Data</p>
                    <Switch checked={setting?.showData} onChange={value => handleFormInputValueChange(value, "showData")}/>
                </Row>
                <Row justify={"space-between"} className={"margin-top-0-75"} align={"middle"}>
                    <p className={"margin-bottom-0"}>Display Image</p>
                    <Switch checked={setting?.showImage} onChange={value => handleFormInputValueChange(value, "showImage")}/>
                </Row>
                <Radio.Group className={"margin-top-0-3"} value={setting?.displayImage} onChange={e => handleFormInputValueChange(e.target.value, "displayImage")}>
                    <Radio value={"avatar"}>Avatar</Radio>
                    <Radio value={"profileImage"}>Profile Image</Radio>
                </Radio.Group>
            </div>
            <div className={"sections"}>
                <Row justify={"space-between"} align={"middle"}>
                    <p className={"margin-bottom-0"}>Show Podium Image</p>
                    <Switch checked={setting?.showPodiumImage} onChange={value => handleFormInputValueChange(value, "showPodiumImage")}/>
                </Row>
                <Row justify={"space-between"} className={"margin-top-0-75"} align={"middle"}>
                    <p className={"margin-bottom-0"}>Podium Image</p>
                    <Button>Upload</Button>
                </Row>
            </div>
            <div className={"sections"}>
                <p>Sort</p>
                <Row gutter={16} className={"number-sorting position-relative"}>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="droppable" direction="horizontal">
                            {(droppableProvided) => (
                                <div
                                    className="horizontal-list"
                                    {...droppableProvided.droppableProps}
                                    ref={droppableProvided.innerRef}
                                    style={{display: 'flex', gap: '8px', paddingInlineStart: 8}}
                                >
                                    {setting?.sort && setting?.sort.map((item, index) => (
                                        <Draggable key={item} draggableId={item} index={index}>
                                            {(provided, snapshot) => {
                                                if (snapshot.isDragging) {
                                                    provided.draggableProps.style.left = (index * (32 + 8)) + 8;
                                                    provided.draggableProps.style.top = undefined;
                                                }

                                                return (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{
                                                            ...provided.draggableProps.style,
                                                            userSelect: 'none',
                                                        }}
                                                    >
                                                        <div>{item}</div>
                                                    </div>
                                                )
                                            }}
                                        </Draggable>
                                    ))}
                                    {droppableProvided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Row>
            </div>


        </>
    )
}

export default PodiumMenuItems