export const getTextFromObject = (array, field, language) => {
    const index = array?.findIndex((item) => item?.language === language);
  
    if (!array || !array[index] || !array[index][field]) {
      return "";
    }
  
    return array[index][field];
  };

export const extractIdFromObject = (array) => {
    var result: any[] = [];
    if (!array || !(array.length > 0)) {
      return [];
    }
  
    array.forEach((item) => {
      result.push(item?.id);
    });
  
    return result;
  };