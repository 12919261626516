import {gql, useMutation, useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {ApolloClientQuery} from "./_apolloClient";

const GET_BRAND_SETTING = gql`
    query getBrandSetting {
        getBrandSetting {
            ... on BrandSetting {
                companyLogo {
                    language
                    fileList
                    link
                }
                companyLogoInverse {
                    language
                    fileList
                    link
                }
                companyLogoSmall {
                    language
                    fileList
                    link
                }
                companyLogoInverseSmall {
                    language
                    fileList
                    link
                }
                siteFavicon {
                    language
                    fileList
                    link
                }
                siteFaviconInverse {
                    language
                    fileList
                    link
                }
                companyName {
                    language
                    content
                }
                companyNameShort {
                    language
                    content
                }
                supportEmail {
                    language
                    content
                }
                companyAddress {
                    language
                    content
                }
                copyRight {
                    language
                    content
                }
                timezone
                pushNotification
                # showBankDetails
                facebookLink {
                    language
                    link
                }
                instaLink {
                    language
                    link
                }
                youtubeLink {
                    language
                    link
                }
                twitterLink {
                    language
                    link
                }
                linkedInLink {
                    language
                    link
                }
                companySlogan {
                    language
                    content
                }
                domain
                defaultDomain
                aboutPage {
                    language
                    link
                }
                supportPage {
                    language
                    link
                }
                companyTermOfUseLink {
                    language
                    link
                }
                companyPrivacyPolicyLink {
                    language
                    link
                }
                companyReturnRefundPolicyLink {
                    language
                    link
                }
                companyRiskDisclosureLink {
                    language
                    link
                }
                companyTermOfBusinessLink {
                    language
                    link
                }
                theme
                layout
                usernameCharacterLimit
                companyNo
                licenseNo
                incorporationCountry
                redirectNavigation {
                    conditionType
                    conditionValue
                    redirectUri
                    deviceType
                    webNavigation {_id id name}
                    appNavigation {_id id name}
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getBrandSetting = () => {
    try {
        const {loading, error, data} = useQuery(GET_BRAND_SETTING, {
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_CRM_USER_BRAND_SETTING = gql`
    query getCrmUserBrandSetting($filter: BrandSettingFilterInput) {
        getCrmUserBrandSetting(filter: $filter) {
            ... on UserBrandSetting {
                companyLogo
                companyLogoInverse
                companyLogoSmall
                companyLogoInverseSmall
                siteFavicon
                siteFaviconInverse
                companyName
                companyNameShort
                supportEmail
                companyAddress
                copyRight
                timezone
                pushNotification
                # showBankDetails
                facebookLink
                instaLink
                youtubeLink
                twitterLink
                linkedInLink
                companySlogan
                domain
                defaultDomain
                aboutPage
                supportPage
                companyTermOfUseLink
                companyPrivacyPolicyLink
                companyReturnRefundPolicyLink
                companyRiskDisclosureLink
                companyTermOfBusinessLink
                theme
                layout
                usernameCharacterLimit
                companyNo
                licenseNo
                incorporationCountry
                redirectNavigation {
                    conditionType
                    conditionValue
                    redirectUri
                    deviceType
                    webNavigation {_id id name}
                    appNavigation {_id id name}
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getCrmUserBrandSetting = (filter) => {
    try {
        const {loading, error, data} = useQuery(GET_CRM_USER_BRAND_SETTING, {
            variables: {
                filter: filter,
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const getUserBrandSettingAsync = async (filter) => {
    try {
        const execute = await ApolloClientQuery(
            GET_CRM_USER_BRAND_SETTING,
            {
                filter: filter,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};


const GET_BRAND_DOMAIN_DROPDOWN = gql`
    query getBrandDomainDropdown{
        getBrandDomainDropdown {
            ... on BrandDomainArray {
                data {
                    label
                    value
                    key
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getBrandDomainDropdown = () => {
    try {
        const {loading, error, data} = useQuery(GET_BRAND_DOMAIN_DROPDOWN);

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const getBrandDomainDropdownAsync = async () => {
    try {
        const execute = await ApolloClientQuery(
            GET_BRAND_DOMAIN_DROPDOWN,
            {
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UPDATE_BRAND_SETTING = gql`
    mutation updateBrandSetting($input: BrandSettingInput) {
        updateBrandSetting(input: $input) {
            ... on BrandSetting {
                companyLogo {
                    language
                    fileList
                    link
                }
                companyLogoInverse {
                    language
                    fileList
                    link
                }
                companyLogoSmall {
                    language
                    fileList
                    link
                }
                companyLogoInverseSmall {
                    language
                    fileList
                    link
                }
                siteFavicon {
                    language
                    fileList
                    link
                }
                siteFaviconInverse {
                    language
                    fileList
                    link
                }
                companyName {
                    language
                    content
                }
                companyNameShort {
                    language
                    content
                }
                supportEmail {
                    language
                    content
                }
                companyAddress {
                    language
                    content
                }
                copyRight {
                    language
                    content
                }
                timezone
                pushNotification
                # showBankDetails
                facebookLink {
                    language
                    link
                }
                instaLink {
                    language
                    link
                }
                youtubeLink {
                    language
                    link
                }
                twitterLink {
                    language
                    link
                }
                linkedInLink {
                    language
                    link
                }
                companySlogan {
                    language
                    content
                }
                domain
                defaultDomain
                aboutPage {
                    language
                    link
                }
                supportPage {
                    language
                    link
                }
                companyTermOfUseLink {
                    language
                    link
                }
                companyPrivacyPolicyLink {
                    language
                    link
                }
                companyReturnRefundPolicyLink {
                    language
                    link
                }
                companyRiskDisclosureLink {
                    language
                    link
                }
                companyTermOfBusinessLink {
                    language
                    link
                }
                theme
                layout
                usernameCharacterLimit
                companyNo
                licenseNo
                incorporationCountry
                redirectNavigation {
                    conditionType
                    conditionValue
                    redirectUri
                    deviceType
                    webNavigation {_id id name}
                    appNavigation {_id id name}
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useUpdateBrandSettingMutation = (): {
    updateBrandSetting: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const {t} = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updateBrandSettingMutation, {data, loading, error}] =
            useMutation(UPDATE_BRAND_SETTING);

        const updateBrandSetting = (input) => {
            return updateBrandSettingMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {updateBrandSetting}}) {
                    const typename = updateBrandSetting?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateBrandSetting?.errKey),
                            key: "updateBrandSetting",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateBrandSetting?.msg),
                            key: "updateBrandSetting",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t(`Update Brand Setting Successfully`),
                            key: "updateBrandSetting",
                        });
                    }
                }
            });
        };

        return {updateBrandSetting, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {updateBrandSetting: null, data: null, loading: false, error: err};
    }
};

export {getBrandSetting, getCrmUserBrandSetting, getUserBrandSettingAsync, useUpdateBrandSettingMutation, getBrandDomainDropdown, getBrandDomainDropdownAsync};
