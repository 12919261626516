import React, {useEffect, useState} from "react";
import {Button, Col, Form, Modal, Row, Space, Tabs} from "antd";
import {useTranslation} from "react-i18next";
import TableAntDesign from "../../../../../../../../components/table/tableAntDesign";
import CommonPanelMt5 from "../symbolSetting/commonPanel";
import TradePanelMt5 from "../symbolSetting/tradePanel";
import ExecutionPanelMt5 from "../symbolSetting/executionPanel";
import MarginPanelMt5 from "../symbolSetting/marginPanel";
import MarginRatesPanelMt5 from "../symbolSetting/marginRatesPanel";
import SwapPanelMt5 from "../symbolSetting/swapPanel";
import EditTableUserPreferenceButton
    from "../../../../../../../../components/table/function/editTableUserPreferenceButton";

const SymbolsMt5 = ({initialData}) => {
    const {t} = useTranslation()
    const [form] = Form.useForm();
    const [symbolModal, setSymbolModal] = useState<any>(false);
    const [currentSymbol, setCurrentSymbol] = useState<any>({});

    useEffect(() => {
        form.setFieldsValue({
            ...initialData
        });
    }, [initialData])

    const SymbolSettingColumns = [
        {
            title: "Symbol",
            dataIndex: "path",
            key: "path",
            render: (text, record) => {
                return (
                    <>
                        <Button type={"link"} onClick={() => {
                            setCurrentSymbol(record)
                            setSymbolModal(true)
                        }}>
                            {text || "-"}
                        </Button>
                    </>
                )
            },
        },
        {
            title: "Spread",
            dataIndex: "spreadDiff",
            key: "spreadDiff",
            render: (text) => {
                if (typeof text === 'number' && text > 0) {
                    return `+${text}`;
                }
                return text || "-";
            },
        },
        {
            title: "Trade",
            dataIndex: "tradeMode",
            key: "tradeMode",
            render: (text) => text || "-",
        }
    ]

    const items = [
        {
            key: 'common',
            label: 'Common',
            children: <CommonPanelMt5 initialData={currentSymbol}/>,
        }, {
            key: 'trade',
            label: 'Trade',
            children: <TradePanelMt5 initialData={currentSymbol}/>,
        }, {
            key: 'execution',
            label: 'Execution',
            children: <ExecutionPanelMt5 initialData={currentSymbol}/>,
        }, {
            key: 'margin',
            label: 'Margin',
            children: <MarginPanelMt5 initialData={currentSymbol}/>,
        }, {
            key: 'marginRate',
            label: 'Margin Rates',
            children: <MarginRatesPanelMt5 initialData={currentSymbol}/>,
        }, {
            key: 'swap',
            label: 'Swaps',
            children: <SwapPanelMt5 initialData={currentSymbol}/>,
        },
    ];


    return (
        <>
            <Row gutter={[16, 16]} style={{marginBottom: "1%", marginTop: "2%"}}>
                <Col span={24}>
                    <p>
                        Please set up individual parameters of symbols trade for the group.
                    </p>
                </Col>
            </Row>


            <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                <Col span={24}>
                    <Row justify={"end"} style={{marginBottom: "1%"}}>
                        <Space>
                            <EditTableUserPreferenceButton tableName={"spread group default settings"}
                                                           tableColumn={SymbolSettingColumns}/>
                        </Space>
                    </Row>
                    <TableAntDesign
                        data={initialData?.symbolData}
                        tableName={"spread group default settings"}
                        columns={SymbolSettingColumns}
                        size={"small"}
                        isTableLocalSearch={true}
                        pagination={{
                            size: "small",
                            pageSize: 5,
                            total: initialData?.symbolData?.length,
                            showTotal: (total) => <p>Total {total} items</p>
                        }}
                    />
                </Col>
            </Row>

            <Modal
                title={"Symbol - " + currentSymbol?.path}
                open={symbolModal}
                onCancel={() => {
                    setSymbolModal(false)
                    setCurrentSymbol({})
                }}
                footer={null}
                width={1000}
                destroyOnClose
            >
                <Tabs defaultActiveKey="1" items={items}/>
            </Modal>
        </>
    )
}

export default SymbolsMt5