import {gql, useMutation} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";

const GET_EWALLET_SETTING = gql`
    query getEWalletSetting {
        getEWalletSetting {
            ... on EWalletSetting {
                _id
                currency
                depositWithKyc
                withdrawalWithKyc
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

const getEWalletSetting = async () => {
    try {
        return await ApolloClientQuery(
            GET_EWALLET_SETTING,
            {
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UPDATE_EWALLET_SETTING = gql`
    mutation updateEWalletSetting($input: EWalletSettingInput) {
        updateEWalletSetting(input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on EWalletSetting {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

const useUpdateEWalletSettingMutation = (): {
    updateEWalletSetting: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const { t } = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updateEWalletSettingMutation, {data, loading, error}] = useMutation(UPDATE_EWALLET_SETTING);

        const updateEWalletSetting = async (input) => {
            return await updateEWalletSettingMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {updateEWalletSetting}}) {
                    const typename = updateEWalletSetting?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateEWalletSetting?.errKey),
                            key: "updateEWalletSetting",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateEWalletSetting?.msg),
                            key: "updateEWalletSetting",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update EWallet Setting Successfully"),
                            key: "updateEWalletSetting",
                        });
                    }
                },
            });
        };

        return {updateEWalletSetting, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {updateEWalletSetting: null, data: null, loading: false, error: err};
    }
};

export {
    getEWalletSetting,
    useUpdateEWalletSettingMutation,
}

