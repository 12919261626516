import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";

const GET_ANNOUNCEMENT = gql`
    query getAnnouncement($filter: AnnouncementInput, $limit: Int, $offset: Int, $orderBy: String) {
        getAnnouncement(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on AnnouncementAry {
                data {
                    _id
                    name
                    dataArrayOfObject {
                        title
                        content
                        description
                        language
                    }
                    isPublished
                    recipient {
                        _id
                        username
                    }
                    sendInNetwork
                    userType
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                    status
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getAnnouncement = async ({filter}, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_ANNOUNCEMENT,
            {
                filter: filter,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_ANNOUNCEMENT_BY_ID = gql`
    query getAnnouncementById($id: String!) {
        getAnnouncementById(id: $id) {
            ... on Announcement {
                _id
                name
                userType
                dataArrayOfObject {
                    title
                    content
                    description
                    language
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getAnnouncementById = async (id?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_ANNOUNCEMENT_BY_ID,
            {
                id: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_USER_ANNOUNCEMENT = gql`
    query getUserAnnouncement($filter: UserAnnouncementInput, $limit: Int, $offset: Int, $orderBy: String) {
        getUserAnnouncement(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on UserAnnouncementAry {
                total
                data {
                    _id
                    userId {id}
                    announcementId
                    dataOfObject {
                        title
                        content
                        description
                        language
                    }
                    isRead
                    createdAt
                    updatedAt
                    isAnnouncementUpdated
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getUserAnnouncement = async ({filter}, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_USER_ANNOUNCEMENT,
            {
                filter: filter,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_USER_ANNOUNCEMENT_BY_USER_ID = gql`
    query getUserAnnouncementByUserId($id: String, $limit: Int, $offset: Int, $orderBy: String) {
        getUserAnnouncementByUserId(id: $id, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on UserAnnouncementWithUnreadAry {
                total
                unreadTotal
                data {
                    _id
                    announcementId
                    dataOfObject {
                        title
                        content
                        description
                        language
                    }
                    isRead
                    createdAt
                    updatedAt
                    announcementUpdatedAt
                    isAnnouncementUpdated
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getUserAnnouncementByUserId = async (id?, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_USER_ANNOUNCEMENT_BY_USER_ID,
            {
                id: id || null,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_ANNOUNCEMENT = gql`
    mutation addAnnouncement($input: AnnouncementInput) {
        addAnnouncement(input: $input) {
            ... on Announcement {
                _id
                name
                isPublished
                recipient {
                    _id
                    username
                }
                sendInNetwork
                userType
                userType
                status
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const addAnnouncement = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            ADD_ANNOUNCEMENT,
            {
                input: input,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UPDATE_ANNOUNCEMENT = gql`
    mutation updateAnnouncement($id: String!, $input: AnnouncementInput) {
        updateAnnouncement(id: $id, input: $input) {
            ... on Announcement {
                _id
                name
                dataArrayOfObject {
                  title
                  content
                  description
                  language
                }
                isPublished
                recipient {
                    _id
                    username
                }
                sendInNetwork
                userType
                createdBy {username}
                updatedBy {username}
                createdAt
                updatedAt
                status
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const updateAnnouncement = async (id, input) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_ANNOUNCEMENT,
            {
                id: id,
                input: input,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const DELETE_ANNOUNCEMENT = gql`
    mutation deleteAnnouncement($id: String!) {
        deleteAnnouncement(id: $id)
    }
`;

const deleteAnnouncement = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            DELETE_ANNOUNCEMENT,
            {
                id: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const PUBLISH_ANNOUNCEMENT_TO_QUEUE = gql`
    mutation publishAnnouncementToQueue($id: String!) {
        publishAnnouncementToQueue(id: $id)
    }
`;

const publishAnnouncementToQueue = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            PUBLISH_ANNOUNCEMENT_TO_QUEUE,
            {
                id: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UNPUBLISH_ANNOUNCEMENT = gql`
    mutation unpublishAnnouncement($id: String!) {
        unpublishAnnouncement(id: $id)
    }
`;

const unpublishAnnouncement = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            UNPUBLISH_ANNOUNCEMENT,
            {
                id: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UPDATE_USER_ANNOUNCEMENT_READ_STATUS = gql`
    mutation updateUserAnnouncementReadStatus($id: [String]!, $isRead: Boolean!) {
        updateUserAnnouncementReadStatus(id: $id, isRead: $isRead)
    }
`;

const updateUserAnnouncementReadStatus = async (id, isRead) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_USER_ANNOUNCEMENT_READ_STATUS,
            {
                id: id,
                isRead: isRead,
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_USER_ANNOUNCEMENT_UNREAD_COUNT = gql`
    query getUserAnnouncementByUserId($id: String, $limit: Int, $offset: Int, $orderBy: String) {
        getUserAnnouncementByUserId(id: $id, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on UserAnnouncementWithUnreadAry {
                unreadTotal
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getUserAnnouncementUnreadCount = async (id?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_USER_ANNOUNCEMENT_UNREAD_COUNT,
            {
                id: id,
                limit: null,
                offset: null,
                orderBy: null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

export {
    getAnnouncement,
    getAnnouncementById,
    getUserAnnouncement,
    getUserAnnouncementByUserId,
    addAnnouncement,
    updateAnnouncement,
    deleteAnnouncement,
    publishAnnouncementToQueue,
    unpublishAnnouncement,
    updateUserAnnouncementReadStatus,
    getUserAnnouncementUnreadCount
};
