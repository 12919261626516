import React, {useState} from "react";
import MoneyManagerTable from "./component/moneyManagerTable";
import InvestorTable from "./component/investorTable";
import {Col, Row} from "antd";

const MamPamm_1 = (props) => {
    return (
        <Row id="mamPamm" gutter={[16, 16]}>
            <Col md={8}>
                <MoneyManagerTable {...props} />
            </Col>
            <Col md={16}>
                <InvestorTable {...props} />
            </Col>
        </Row>
    );
};

export default MamPamm_1;
