import React from "react";
import {useRecoilState} from "recoil";
import EmailTemplatePage_1 from "./view/layout1";
import {getEmailTemplate} from "../../../api/graphql/emailTemplate";
import {getLanguage} from "../../../api/graphql/language";
import {sortEn} from "../../../function/language";
import {getDynamicVariable} from "../../../api/graphql/dynamicVariable";
import {getDynamicEmailComponent} from "../../../api/graphql/dynamicEmailComponent";
import {selfPermissionObj} from "../../../../recoil_state";
import { getNewsletter } from "../../../api/graphql/newsletter";

function EmailTemplatePage() {
    let layout = 1;
    let component;
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const emailTemplatePermission = selfPermission?.messageSetting?.emailTemplate;
    const dynamicVariablePermission = selfPermission?.messageSetting?.dynamicVariable;

    const {data, loading, error} = getEmailTemplate({filter: {name: null, subject: null, type: null}});
    const {languageData} = getLanguage({filter: {isActive: true}});
    const {data: newsletterData} = getNewsletter({filter: null})
    const {variableData} = getDynamicVariable({
        filter: {
            isActive: true,
        },
    });
    const {componentData} = getDynamicEmailComponent({filter: null});

    switch (layout) {
        case 1:
            component = (
                <EmailTemplatePage_1
                    data={data?.getEmailTemplate?.data}
                    languageData={sortEn(languageData?.getLanguage?.data)}
                    variableData={variableData?.getDynamicVariable?.data}
                    componentData={componentData?.getDynamicEmailComponent?.data}
                    loading={loading}
                    error={error}
                    emailTemplatePermission={emailTemplatePermission}
                    dynamicVariablePermission={dynamicVariablePermission}
                    newsletterData={newsletterData?.getNewsletter?.data}
                />
            );
            break;

        default:
            component = (
                <EmailTemplatePage_1
                    data={data?.getEmailTemplate?.data}
                    languageData={sortEn(languageData?.getLanguage?.data)}
                    variableData={variableData?.getDynamicVariable?.data}
                    componentData={componentData?.getDynamicEmailComponent?.data}
                    loading={loading}
                    error={error}
                    emailTemplatePermission={emailTemplatePermission}
                    dynamicVariablePermission={dynamicVariablePermission}
                    newsletterData={newsletterData?.getNewsletter?.data}
                />
            );
            break;
    }

    return component;
}

export default EmailTemplatePage;
