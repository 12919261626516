import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import TableAntDesign from "../../../../../../../components/table/tableAntDesign";
import {columnsRuleSetting} from "../../../function/ruleSettingColumns";
import {Popconfirm, Space} from "antd";
import {CalculatorOutlined, CopyOutlined, EditOutlined, UnorderedListOutlined} from "@ant-design/icons";
import {AiOutlineDelete} from "react-icons/ai";
import RuleSettingModal from "./ruleSettingModal";
import {deleteCommissionRule, duplicateCommissionRule} from "../../../../../../../api/graphql/commission";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import useAuthorize from "../../../../../../../_common/authorize";
import ParameterModal from "./parameterModal";

function RuleSettingTable_1(props) {
    const {
        data,
        total,
        server,
        tableName,
        loading,
        commSetting,
        limit,
        setLimit,
        setOffset,
        setFilter,
        setOrderBy,
        dealReason,
        productData, symbolData, userData, commGroup, rankData, preload
    } = props
    const {t} = useTranslation()

    const [openModal, setOpenModal] = useState(false);
    const [openParamModal, setOpenParamModal] = useState(false);
    const [editData, setEditData] = useState({});
    const [tableData, setTableData] = useState<any>([]);
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {systemErrorNotification} = useAuthorize()
    const columns = columnsRuleSetting

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize)
        setOffset(limit * (page - 1))
    }

    const formattedData = (record) => {
        const payoutSomeUsers = record?.payoutSomeUsers?.map((item) => {
            return item?._id
        })

        const partiallyHidden = record?.partiallyHidden?.map((item) => {
            return item?._id
        })

        const calculationSymbols = record?.calculationSymbols?.map((item) => {
            return item?._id
        })

        const products = record?.product?.map((item) => {
            return item?._id
        })

        const commRanks = record?.commRank?.map((item) => {
            return item?._id
        })

        const commRanksFormatted = record?.commRank?.map((item) => {
            return {
                value: item?._id,
                label: item?.name,
                rank: item?.rank
            }
        })

        return {
            ...record,
            payoutSomeUsers: payoutSomeUsers,
            partiallyHidden: partiallyHidden,
            calculationSymbols: calculationSymbols,
            product: products,
            productType: record?.productType,
            calculationSymbolType: record?.calculationSymbolType,
            payoutCommGroup: record?.payoutCommGroup?._id,
            commRank: commRanks,
            commRankFormatted: commRanksFormatted,
        }
    }

    const deleteId = async (id) => {
        try {
            const response = await deleteCommissionRule(id)
            if (response?.deleteCommissionRule?.__typename === "CommissionRule") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Deleted Successfully"),
                    key: "CommissionRule",
                });
            } else if (response?.deleteCommissionRule.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.deleteCommissionRule?.errKey),
                    key: "CommissionRule",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.deleteCommissionRule?.msg),
                    key: "CommissionRule",
                });
            }
        } catch (e) {
            systemErrorNotification()
        } finally {
            preload()
        }
    }

    const duplicateRule = async (id) => {
        try {
            const response = await duplicateCommissionRule(id)
            if (response?.duplicateCommissionRule?.__typename === "CommissionRule") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Duplicate Successfully"),
                    key: "CommissionRule",
                });
            } else if (response?.duplicateCommissionRule.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.duplicateCommissionRule?.errKey),
                    key: "CommissionRule",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.duplicateCommissionRule?.msg),
                    key: "CommissionRule",
                });
            }
        } catch (e) {
            systemErrorNotification()
        } finally {
            preload()
        }
    }

    const reformatData = async () => {
        const newData = await Promise.all(data.map((item)=>{
            return {
                ...item,
                payoutCommGroup:item?.payoutCommGroup?.name
            }
        }))
        setTableData(newData)
    }

    useEffect(()=>{
        reformatData()
    },[data])


    return (
        <div className={"margin-top-0-75"}>

            <TableAntDesign data={tableData}
                            tableName={tableName}
                            columns={[...columns, {
                                title: t("common.action"),
                                key: "action",
                                width: 100,
                                align: "left",
                                render: (_, record: any) => {
                                    const formatted = formattedData(record)
                                    return (
                                        <Space>
                                            <a onClick={() => window.open("/settings/commission-batch-details?id=" + record?._id, "_blank")}>
                                                <CalculatorOutlined/>
                                            </a>
                                            <a onClick={() => {
                                                setOpenModal(true)
                                                setEditData(formatted)
                                            }}>
                                                <EditOutlined/>
                                            </a>
                                            <a onClick={() => {
                                                setOpenParamModal(true)
                                                setEditData(formatted)
                                            }}>
                                                <UnorderedListOutlined/>
                                            </a>

                                            <Popconfirm
                                                placement="left"
                                                description={t("Are you sure to duplicate this comm rule?")}
                                                title={t(`Duplicate Comm Rule`)}
                                                onConfirm={() => {
                                                    duplicateRule(record?._id);
                                                }}
                                                okText={t("Yes")}
                                                okType="danger"
                                                cancelText={t("No")}
                                            >
                                                <a>
                                                    <CopyOutlined/>
                                                </a>
                                            </Popconfirm>
                                            <Popconfirm
                                                placement="left"
                                                description={t("Are you sure to delete this comm rule?")}
                                                title={t(`Delete Comm Rule`)}
                                                onConfirm={() => {
                                                    deleteId(record?._id);
                                                }}
                                                okText={t("Yes")}
                                                okType="danger"
                                                cancelText={t("No")}
                                            >
                                                <a>
                                                    <AiOutlineDelete style={{color: "red"}}/>
                                                </a>
                                            </Popconfirm>
                                        </Space>
                                    )
                                },
                            }]}
                            server={server}
                            loading={loading}
                            size={"small"}
                            isTableLocalSearch={true}
                            pagination={{
                                size: "small",
                                pageSize: 20,
                                total: total,
                                showTotal: (total) => <p>Total {total} items</p>
                            }}
            />
            {/*<TableAntDesign data={data}*/}
            {/*                tableName={tableName}*/}
            {/*                columns={[...columns, {*/}
            {/*                    title: t("common.action"),*/}
            {/*                    key: "action",*/}
            {/*                    width: 100,*/}
            {/*                    align: "left",*/}
            {/*                    render: (_, record: any) => {*/}
            {/*                        const formatted = formattedData(record)*/}
            {/*                        return (*/}
            {/*                            <Space>*/}
            {/*                                <a onClick={() => window.open("/settings/commission-batch-details?id=" + record?._id, "_blank")}>*/}
            {/*                                    <CalculatorOutlined/>*/}
            {/*                                </a>*/}
            {/*                                <a onClick={() => {*/}
            {/*                                    setOpenModal(true)*/}
            {/*                                    setEditData(formatted)*/}
            {/*                                }}>*/}
            {/*                                    <EditOutlined/>*/}
            {/*                                </a>*/}
            {/*                                <a onClick={() => {*/}
            {/*                                    setOpenParamModal(true)*/}
            {/*                                    setEditData(formatted)*/}
            {/*                                }}>*/}
            {/*                                    <UnorderedListOutlined/>*/}
            {/*                                </a>*/}

            {/*                                <Popconfirm*/}
            {/*                                    placement="left"*/}
            {/*                                    description={t("Are you sure to duplicate this comm rule?")}*/}
            {/*                                    title={t(`Duplicate Comm Rule`)}*/}
            {/*                                    onConfirm={() => {*/}
            {/*                                        duplicateRule(record?._id);*/}
            {/*                                    }}*/}
            {/*                                    okText={t("Yes")}*/}
            {/*                                    okType="danger"*/}
            {/*                                    cancelText={t("No")}*/}
            {/*                                >*/}
            {/*                                    <a>*/}
            {/*                                        <CopyOutlined/>*/}
            {/*                                    </a>*/}
            {/*                                </Popconfirm>*/}
            {/*                                <Popconfirm*/}
            {/*                                    placement="left"*/}
            {/*                                    description={t("Are you sure to delete this comm rule?")}*/}
            {/*                                    title={t(`Delete Comm Rule`)}*/}
            {/*                                    onConfirm={() => {*/}
            {/*                                        deleteId(record?._id);*/}
            {/*                                    }}*/}
            {/*                                    okText={t("Yes")}*/}
            {/*                                    okType="danger"*/}
            {/*                                    cancelText={t("No")}*/}
            {/*                                >*/}
            {/*                                    <a>*/}
            {/*                                        <AiOutlineDelete style={{color: "red"}}/>*/}
            {/*                                    </a>*/}
            {/*                                </Popconfirm>*/}
            {/*                            </Space>*/}
            {/*                        )*/}
            {/*                    },*/}
            {/*                }]}*/}
            {/*                size={"small"}*/}
            {/*                server={server}*/}
            {/*                loading={loading}*/}
            {/*                filter={setFilter}*/}
            {/*                order={setOrderBy}*/}
            {/*                pagination={{*/}
            {/*                    pageSize: limit,*/}
            {/*                    showSizeChanger: true,*/}
            {/*                    position: "bottomCenter",*/}
            {/*                    total: total,*/}
            {/*                    onChange: (page, pageSize) => onChangePageHandler(page, pageSize),*/}
            {/*                    showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,*/}
            {/*                }}*/}
            {/*/>*/}

            <RuleSettingModal
                isOpen={openModal}
                closeModal={() => {
                    setOpenModal(false)
                    setEditData({})
                }}
                productData={productData}
                commSetting={commSetting}
                dealReason={dealReason}
                userData={userData} commGroup={commGroup} rankData={rankData} preload={preload}
                modalType={"edit"} editData={editData}
            />

            <ParameterModal isOpen={openParamModal} closeModal={() => setOpenParamModal(false)}
                            data={editData} rankData={rankData} userData={userData} symbolData={symbolData}/>


        </div>
    )
};

export default RuleSettingTable_1;
