export const leaderboardData = [
    {
        "_id": "66b5c0f8a54475f97b7ce9f2",
        "name": "Best Trader Award",
        "description": "Best Trader Award in 2024",
        "networkView": [],
        "networkTypeView": "all",
        "podiumTotal": 3,
        "podiumRewards": [
            {
                "type": "gifts",
                "value": "rolex submarine",
                "position": 1,
                "light": "",
                "dark": ""
            }
        ],
        "rankByPrimary": "nettDeposits",
        "rankBySecondary": "",
        "type": "whoever to reach",
        "reportCondition": {
            "reportId": "64d0a107847e88a7d5978e3c",
            "networkEntity": [],
            "networkOperation": "",
            "networkIds": "",
            "conditions": [
                {
                    "fieldName": "nettDeposits",
                    "operator": "GTE",
                    "value": [
                        "100"
                    ]
                }
            ],
            "startDate": "2024-07-01",
            "endDate": "2026-07-31"
        },
        "displayField": [
            "traderId"
        ],
        "isEnabled": true
    }
]