import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import {Button, Col, Form, Row} from "antd";
import {useSetRecoilState} from "recoil";

import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import yupFormSchema from "../../../../../../components/yup/yupFormSchema";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import {verticalFormLayout} from "../../../../../../components/form/formWrapper";
import useAuthorize from "../../../../../../_common/authorize";
import {notificationMessage} from "../../../../../../../recoil_state";
import { useAddNavigationRouteSettingMutation, useUpdateNavigationRouteSettingMutation } from "../../../../../../api/graphql/navigationRouteSetting";
import SelectFormItem from "../../../../../../components/form/selectFormItem";

const NavigationRouteSettingForm = (props) => {
    const {mode, item, closeModal, type} = props;
    const {t} = useTranslation();
    const {systemErrorNotification} = useAuthorize();
    const [errMsg, setErrMsg] = useState("");

    const { addNavigationRouteSetting } = useAddNavigationRouteSettingMutation()
    const { updateNavigationRouteSetting } = useUpdateNavigationRouteSettingMutation()

    const yupSchema = yup.object().shape({
        name: yupFormSchema.string("name", {
            required: true,
            requiredErrorMessage: "Name is a required field",
        }),
        type: yupFormSchema.string("type", {
            required: true,
            requiredErrorMessage: "Type is a required field",
        }),
        value: yupFormSchema.string("value", {
            required: true,
            requiredErrorMessage: "Value is a required field",
        }),
    });

    const [initialValues] = useState(() => ({
        name: item?.name || "",
        type: item?.type || type,
        value: item?.value || "",
    }));

    var form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
        defaultValues: initialValues,
    });

    const onCancel = () => {
        closeModal();
    };

    const onSubmit = async (values) => {
        try {
            const response = mode === "add" ? await addNavigationRouteSetting(values) : await updateNavigationRouteSetting(item._id, values);
            if ((mode === "add" && response?.data?.addNavigationRouteSetting && response?.data?.addNavigationRouteSetting.__typename === "NavigationRouteSetting") ||
                (mode === "edit" && response?.data?.updateNavigationRouteSetting && response?.data?.updateNavigationRouteSetting?.__typename === "NavigationRouteSetting")
            ) {
                setErrMsg("");
                closeModal();
            } 
        } catch (error: any) {
            systemErrorNotification();
        }
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="form-1">
                {errMsg && <p style={{color: "red"}}>{errMsg}</p>}
                <Row gutter={10}>
                    <Col xs={12}>
                        <InputFormItem
                            name={"name"}
                            label={t("Name")}
                            placeholder={t("Name")}
                            layout={verticalFormLayout}
                            required
                            disabled={mode === "view"}
                        />
                    </Col>
                    <Col xs={12}>
                        <SelectFormItem
                            name={"type"}
                            label={t("Type")}
                            placeholder={t("Type")}
                            layout={verticalFormLayout}
                            options={[
                                {
                                    label: t("Web Traders Room Navigation"),
                                    value: "webTradersRoom"
                                },
                                {
                                    label: t("Mobile Traders Room Navigation"),
                                    value: "mobileTradersRoom"
                                },
                                {
                                    label: t("Web Admin Panel Navigation"),
                                    value: "webAdminPanel"
                                },
                                {
                                    label: t("Mobile Admin Panel Navigation"),
                                    value: "mobileAdminPanel"
                                },
                                {
                                    label: t("External Navigation"),
                                    value: "external"
                                },
                            ]}
                            required
                            disabled
                        />
                    </Col>
                    <Col xs={24}>
                        <InputFormItem
                            name={"value"}
                            label={t("Value")}
                            placeholder={t("Value")}
                            layout={verticalFormLayout}
                            required
                            disabled={mode === "view"}
                        />
                    </Col>
                </Row>

                <Form.Item className="text-align-right" style={{paddingTop: "12px"}}>
                    {onCancel && (
                        <Button onClick={onCancel} icon={<CloseOutlined />} className="button-cancel-1">
                            {t("common.cancel")}
                        </Button>
                    )}
                    {mode !== "view" && (
                        <Button onClick={form.handleSubmit(onSubmit)} icon={<SaveOutlined />} className="button-submit-1">
                            {t("common.submit")}
                        </Button>
                    )}
                </Form.Item>
            </form>
        </FormProvider>
    );
};

export default NavigationRouteSettingForm;
