import React, {useEffect, useState} from "react"
import {Avatar, Button, Card, Col, Divider, Dropdown, Form, Menu, message, Popconfirm, Row, Select, Space, Tag, Tooltip, Upload} from "antd"
import {useTranslation} from "react-i18next"
import {
    useDeleteUserMutation,
    useUpdateGeneralUserMutation,
    useUpdateUserDeskMutation,
    useUpdateUserDomainMutation,
    useUpdateUserRankMutation,
    useUpdateUserReferrerMutation,
    useUpdateUserRoleMutation,
    useUpdateUserSalesRepMutation,
} from "../../../../../../../api/graphql/user"
import {DeleteOutlined, UploadOutlined} from "@ant-design/icons"
import {getReferralDeskDropdownFunction, getUserRankDropdownFunction, getUserRoleDropdownFunction} from "../../../../../_common/function"
import {useRecoilValue} from "recoil"
import {brandDomainOptionsState, userOptionsState} from "../../../../../_common/state"
import {useNavigate} from "react-router-dom"
import useAuthorize from "../../../../../../../_common/authorize"
import {useTempLoginMutation} from "../../../../../../../api/graphql/auth"
import {getConfigByName} from "../../../../../../../../config"
import {BRAND_NAME} from "../../../../../../../../config/brandVariable"
import {useIsMobile} from "../../../../../../../_common/layout"
import CommRuleModal from "./commRuleModal"
import ShiftReferrerModal from "./shiftReferrerModal"
import RestoreArchivedModal from "./restoreArchivedModal"
import {useGetUserDropdownWithAdditionalIds} from "../../../../../clients/detail/function/useQueryHooks"

const ProfileCard = (props) => {
    const {data, permissionUser, referralSetting, refetchUserByID} = props
    const isMobile = useIsMobile(768)
    const {t} = useTranslation()
    const navigate = useNavigate()
    const labelCol = {span: 8, offset: 0}
    const wrapperCol = {span: 16, offset: 0}
    const [roles, setRoles] = useState()
    const userNetworkOptions = useRecoilValue<any>(userOptionsState)
    const [deskDropdown, setDeskDropdown] = useState<any>([])
    const [rankDropdown, setRankDropdown] = useState<any>([])
    const [roleDropdown, setRoleDropdown] = useState<any>([])
    const [commRuleModalIsOpen, setCommRuleModalIsOpen] = useState(false)
    const [restoreArchivedModalOpen, setRestoreArchivedModalOpen] = useState(false)
    const [shiftReferrerModalIsOpen, setShiftReferrerModalIsOpen] = useState(false)

    const brandDropdownOptions = useRecoilValue(brandDomainOptionsState)
    const [referralOptions, fetchReferralOptions] = useGetUserDropdownWithAdditionalIds()
    const [salesRepOption, fetchSalesRepOptions] = useGetUserDropdownWithAdditionalIds()
    const {updateGeneralUser} = useUpdateGeneralUserMutation()
    const {deleteUser} = useDeleteUserMutation()
    const {updateUserRole} = useUpdateUserRoleMutation()
    const {updateUserReferrer} = useUpdateUserReferrerMutation()
    const {updateUserSalesRep} = useUpdateUserSalesRepMutation()
    const {updateUserRank} = useUpdateUserRankMutation()
    const {updateUserDesk} = useUpdateUserDeskMutation()
    const {baseErrorChecking, systemErrorNotification, getUserIPAddress} = useAuthorize()
    const {tempLogin} = useTempLoginMutation()
    const {updateUserDomain} = useUpdateUserDomainMutation()

    useEffect(() => {
        fetchReferralOptions([data?.referrer?._id])
    }, [data?.referrer?._id]);

    useEffect(() => {
        fetchSalesRepOptions([data?.salesRep?._id])
    }, [data?.salesRep?._id])

    const brandConfig = getConfigByName(BRAND_NAME)

    const preload = async () => {
        const desk = await getReferralDeskDropdownFunction(data?._id)
        setDeskDropdown(desk?.getDeskDropdown?.data?.map((d) => ({label: d?.name, value: d?._id})))

        const rank = await getUserRankDropdownFunction(data?._id, "crmUser")
        setRankDropdown(rank?.getMultiTierSettingDropdown?.data?.map((d) => ({label: d?.name, value: d?._id, isDefault: d?.isDefaultRank})))

        const role = await getUserRoleDropdownFunction(data?._id)
        setRoleDropdown(role?.getRoleTemplateDropdown?.data?.map((d) => ({label: d?.name, value: d?._id, isDefault: d?.isDefaultCrmUserPermission})))
    }

    useEffect(() => {
        preload()
    }, [data?._id])

    const uploadOnChange = (e) => {
        try {
            if (e?.file?.status === "done") {
                updateGeneralUser(new URLSearchParams(location.search).get("id"), {profileImage: e?.file?.response?.[0]?.link})

                refetchUserByID()
            } else if (e?.file?.status === "error") {
                message.error(`${e?.file?.name} file upload failed.`)
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    const deleteUserOnConfirm = async () => {
        try {
            const response = await deleteUser(data?._id)
            if (response?.data?.deleteUser?.__typename === "User") window.location.reload()
            else if (response?.data?.deleteUser?.__typename === "BaseError" && response?.data?.deleteUser?.errKey === "admin.userNetwork.err.existing_downline") {
                setShiftReferrerModalIsOpen(true)
            }
        } catch (e) {
            console.log(e)
            systemErrorNotification()
        }
    }

    const handleCopy = (link) => {
        try {
            const affiliateLink = link
            const textField = document.createElement("textarea")
            textField.innerText = affiliateLink
            document.body.appendChild(textField)
            textField.select()
            document.execCommand("copy")
            textField.remove()

            message.success(t("user.successMsg.affiliateLinkCopiedToClipboard"))
        } catch (e) {
            console.log(e)
            systemErrorNotification()
        }
    }

    const ghostLoginOnClick = async () => {
        try {
            const ip = await getUserIPAddress()
            const response = await tempLogin({
                login: data?.username,
                ip: ip,
                type: "crmUser",
            })

            const login = response?.data?.tempLogin
            if (login?.__typename === "AuthPayload") {
                if (login?.login) {
                    await localStorage.setItem("ghost_a_t", login?.accessToken)
                    await localStorage.setItem("g_userId", data?._id)
                    await localStorage.setItem("g_username", data?.username)
                    navigate("/users/client/")
                    window.location.reload()
                }
            } else {
                baseErrorChecking(login)
            }
        } catch (e) {
            console.log(e)
            systemErrorNotification()
        }
    }

    const onRestoreModalClick = () => {
        setRestoreArchivedModalOpen(true)
    }

    useEffect(() => {
        const role = data?.role
            ?.map((d) => {
                const matched = roleDropdown?.find((c) => c?.value === d?._id && !c?.isDefault)
                if (matched) return d?._id
            })
            .filter((d) => d !== undefined)
        setRoles(role)
    }, [data, roleDropdown])

    const liveReferralLinkPath = `https://member.${data?.domain?.replace("https://", "")}/#/register?type=live&affiliate=${data?.affiliateCode}`
    const demoReferralLinkPath = `https://member.${data?.domain?.replace("https://", "")}/#/register?type=demo&affiliate=${data?.affiliateCode}`
    const homePageLinkPath = `${data?.domain}/?affiliate=${data?.affiliateCode}`

    return (
        <>
            <Card style={{width: "100%"}}>
                <Form>
                    <Row justify={"space-between"}>
                        <Space>
                            <Tooltip title={t("Upload Profile Image")}>
                                <Upload
                                    action={`${brandConfig?.backendURL}/api/upload/profile/image`}
                                    listType="picture-circle"
                                    maxCount={1}
                                    showUploadList={false}
                                    defaultFileList={
                                        data?.profileImage
                                            ? [
                                                  {
                                                      uid: "profile-image",
                                                      name: "profile-image",
                                                      url: data?.profileImage,
                                                  },
                                              ]
                                            : []
                                    }
                                    onChange={(e) => uploadOnChange(e)}
                                    className={"profile-image"}
                                >
                                    <Avatar src={data?.profileImage || "https://xsgames.co/randomusers/avatar.php?g=pixel&key=2"} size={64} icon={<UploadOutlined />} />
                                </Upload>
                            </Tooltip>
                            <div>
                                <h1>{data?.username}</h1>
                                <Tag color={data?.status === "active" ? "green" : "default"}>{t(`user.statusTag.${data?.status?.replace(" ", "_")}`)}</Tag>
                                {data &&
                                    data?.commRule?.map((d) => {
                                        const name = (d?.commRuleType === "commissionRule" ? d?.rule?.name : d?.group?.name) || ""
                                        const isTooltipNeeded = name.length > 20
                                        const truncatedRuleName = name.length > 20 ? name.slice(0, 20) + "..." : name

                                        return (
                                            <Tooltip title={isTooltipNeeded ? name : undefined}>
                                                <Tag onClick={() => setCommRuleModalIsOpen(true)} className={permissionUser?.crmUser?.edit?.commRule && "clickable"}>
                                                    {truncatedRuleName}
                                                </Tag>
                                            </Tooltip>
                                        )
                                    })}
                                {permissionUser?.crmUser?.edit?.commRule && <CommRuleModal isOpen={commRuleModalIsOpen} setIsOpen={setCommRuleModalIsOpen} user={data} />}
                            </div>
                        </Space>
                        <Space>
                            <Dropdown
                                overlay={
                                    <Menu>
                                        {referralSetting?.demoReferralLink && (
                                            <Menu.Item onClick={() => handleCopy(demoReferralLinkPath)}>
                                                <Tooltip title={demoReferralLinkPath}>{t("referral.demo")}</Tooltip>
                                            </Menu.Item>
                                        )}
                                        {referralSetting?.liveReferralLink && (
                                            <Menu.Item onClick={() => handleCopy(liveReferralLinkPath)}>
                                                <Tooltip title={liveReferralLinkPath}>{t("referral.live")}</Tooltip>
                                            </Menu.Item>
                                        )}
                                        {referralSetting?.referralLink && (
                                            <Menu.Item onClick={() => handleCopy(homePageLinkPath)}>
                                                <Tooltip title={homePageLinkPath}>{t("referral.homepage")}</Tooltip>
                                            </Menu.Item>
                                        )}
                                    </Menu>
                                }
                            >
                                <Button>{t("user.copyAffiliateLink")}</Button>
                            </Dropdown>
                            {permissionUser?.crmUser?.login && <Button onClick={() => ghostLoginOnClick()}>{t("common.login")}</Button>}
                            {permissionUser?.crmUser?.delete?.restore && data?.isDeleted && <Button onClick={() => onRestoreModalClick()}>{t("common.restore")}</Button>}
                            {permissionUser?.crmUser?.delete?.restore && data?.isDeleted && (
                                <RestoreArchivedModal isOpen={restoreArchivedModalOpen} setIsOpen={setRestoreArchivedModalOpen} data={data} referrerOptions={userNetworkOptions?.filter((d) => d?.value !== data?._id)} />
                            )}
                            {permissionUser?.crmUser?.delete?.delete && (
                                <Popconfirm
                                    placement="left"
                                    title={t("crmUser.delete.popconfirm.title")}
                                    description={t("crmUser.delete.popconfirm.description")}
                                    onConfirm={() => {
                                        deleteUserOnConfirm()
                                    }}
                                    okText={t("common.yes")}
                                    cancelText={t("common.no")}
                                >
                                    <Button type={"link"} danger icon={<DeleteOutlined />} />
                                </Popconfirm>
                            )}
                            {permissionUser?.crmUser?.delete?.delete && (
                                <ShiftReferrerModal isOpen={shiftReferrerModalIsOpen} setIsOpen={setShiftReferrerModalIsOpen} referrerOptions={userNetworkOptions?.filter((d) => d?.value !== data?._id)} data={data} />
                            )}
                        </Space>
                    </Row>
                    <Divider />
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item labelAlign={"left"} label={t("user.referrer")} labelCol={labelCol} wrapperCol={wrapperCol}>
                                <Select
                                    value={data?.referrer?._id}
                                    placeholder={t("user.referrer")}
                                    options={referralOptions?.filter((d) => d?.value !== data?._id)}
                                    optionFilterProp={"label"}
                                    optionLabelProp={"selectedLabel"}
                                    showSearch
                                    onChange={async (value) => {
                                        try {
                                            await updateUserReferrer(data?._id, {referrer: value})
                                            refetchUserByID()
                                        } catch (e) {
                                            console.log(e)
                                            systemErrorNotification()
                                        }
                                    }}
                                    style={{width: "100%"}}
                                    disabled={!permissionUser?.crmUser?.edit?.referrer}
                                />
                            </Form.Item>
                            <Form.Item labelAlign={"left"} label={t("user.salesRep")} labelCol={labelCol} initialValue={data?.salesRep?._id}>
                                <Select
                                    value={salesRepOption ? data?.salesRep?._id : null}
                                    placeholder={t("user.salesRep")}
                                    // options={salesRepOption?.filter((d) => d?.value !== data?._id && d?.userType === "crmUser")}
                                    options={salesRepOption?.filter((d) => d?.value !== data?._id)}
                                    optionFilterProp={"label"}
                                    showSearch
                                    onChange={async (value) => {
                                        await updateUserSalesRep(data?._id, {salesRep: value})
                                        refetchUserByID()
                                    }}
                                    style={{width: "100%"}}
                                    disabled={!permissionUser?.crmUser?.edit?.salesRep || data?.isDeleted}
                                    optionLabelProp={"selectedLabel"}
                                />
                            </Form.Item>
                            <Form.Item labelAlign={"left"} label={t("user.domain")} labelCol={labelCol}>
                                <Select
                                    placeholder={t("user.domain")}
                                    options={brandDropdownOptions}
                                    optionFilterProp={"label"}
                                    showSearch
                                    style={{width: "100%"}}
                                    value={data?.domain}
                                    disabled={!permissionUser?.crmUser?.edit?.edit}
                                    onChange={async (value) => {
                                        try {
                                            await updateUserDomain([data?._id], value)
                                        } catch (e) {
                                            console.log(e)
                                            systemErrorNotification()
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item labelAlign={"left"} label={t("user.desk")} labelCol={labelCol} wrapperCol={wrapperCol}>
                                <Select
                                    value={data?.desk?._id}
                                    placeholder={t("user.desk")}
                                    options={deskDropdown}
                                    optionFilterProp={"label"}
                                    showSearch
                                    onChange={async (value) => {
                                        try {
                                            await updateUserDesk(data?._id, {desk: value})
                                            refetchUserByID()
                                        } catch (e) {
                                            console.log(e)
                                            systemErrorNotification()
                                        }
                                    }}
                                    style={{width: "100%"}}
                                    disabled={!permissionUser?.crmUser?.edit?.desk}
                                />
                            </Form.Item>
                            <Form.Item labelAlign={"left"} label={t("user.rank")} labelCol={labelCol} wrapperCol={wrapperCol}>
                                <Select
                                    placeholder={t("user.rank")}
                                    options={rankDropdown?.filter((d) => !d?.isDefault)}
                                    optionFilterProp={"label"}
                                    showSearch
                                    style={{width: "100%"}}
                                    value={data?.rank?.isDefaultRank || !rankDropdown?.find((d) => d?.value === data?.rank?._id) ? null : data?.rank?._id}
                                    disabled={!permissionUser?.crmUser?.edit?.rank}
                                    onChange={async (value) => {
                                        try {
                                            await updateUserRank(data?._id, {rank: value})
                                            refetchUserByID()
                                        } catch (e) {
                                            console.log(e)
                                            systemErrorNotification()
                                        }
                                    }}
                                />
                            </Form.Item>
                            <Form.Item labelAlign={"left"} label={t("user.roles")} labelCol={labelCol} wrapperCol={wrapperCol}>
                                <Select
                                    placeholder={t("user.roles")}
                                    options={roleDropdown}
                                    optionFilterProp={"label"}
                                    showSearch
                                    maxTagCount={isMobile ? 0 : 1}
                                    mode={"multiple"}
                                    value={roles}
                                    onChange={async (value) => {
                                        try {
                                            await updateUserRole(data?._id, {role: value})
                                            refetchUserByID()
                                        } catch (e) {
                                            console.log(e)
                                            systemErrorNotification()
                                        }
                                    }}
                                    style={{width: "100%"}}
                                    disabled={!permissionUser?.crmUser?.edit?.role}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    )
}

export default ProfileCard
