import React from "react";
import {Button, Card, Col, DatePicker, Form, Input, InputNumber, Row, Select, Switch} from "antd";
import TextArea from "antd/es/input/TextArea";
import {DeleteOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

const CreateUpdateForm = () => {
    const {t} = useTranslation()

    return (
        <>
            <Row className={"margin-bottom-0-75"} gutter={[16, 4]}>
                <Col span={24}>
                    <h1 className={"margin-bottom-0"}>General Setting</h1>
                </Col>
                <Col span={12}>
                    <Form.Item label={"Name"} name={"name"}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={"Description"} name={"description"}>
                        <TextArea autoSize/>
                    </Form.Item>
                    <Form.Item label={"Type"} name={"type"}>
                        <Select
                            options={[
                                {label: "Ranking", value: "ranking"},
                                {label: "Whoever to Reach", value: "whoever to reach"},
                                {label: "First to Reach", value: "first to reach"}, // Set value to whoever to reach
                            ]}
                            allowClear
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={"Enabled"} name={"isEnabled"} valuePropName={"checked"}>
                        <Switch/>
                    </Form.Item>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
                    >
                        {({ getFieldValue }) =>
                            getFieldValue('type') === 'first to reach' ? (
                                <Form.Item label={"No. of Winners"} name={"podiumTotal"}>
                                    <InputNumber disabled className={"w-100"}/>
                                </Form.Item>
                            ) :  (
                                <Form.Item label={"Total Rewards"} name={"podiumTotal"}>
                                    <InputNumber className={"w-100"}/>
                                </Form.Item>
                            )
                        }
                    </Form.Item>
                    {/*<Form.Item label={"Total Rewards"} name={"podiumTotal"}>*/}
                    {/*    <InputNumber disabled={type === "first to reach"} className={"w-100"}/>*/}
                    {/*</Form.Item>*/}
                    <Form.Item label={"Network Type"} name={"networkTypeView"}>
                        <Select
                            options={[
                                {label: t("network.all"), value: "all"},
                                {label: t("network.partial"), value: "some"},
                            ]}
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.networkTypeView !== currentValues.networkTypeView}
                    >
                        {({ getFieldValue }) =>
                            getFieldValue('networkTypeView') === 'some' ? (
                                <Form.Item label={"Network"} name={"networkView"}>
                                    <Select
                                        options={[]}
                                        allowClear
                                    />
                                </Form.Item>
                            ) : null
                        }
                    </Form.Item>
                </Col>
            </Row>
            <Row className={"margin-bottom-0-5"} gutter={[16, 4]}>
                <Col span={24}>
                    <h1 className={"margin-bottom-0"}>Report Condition</h1>
                    <Form.Item label={"Report"} name={"reportId"}>
                        <Select
                            options={[]}
                            allowClear
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={"Rank By (Primary)"} name={"rankByPrimary"}>
                        <Select
                            options={[]}
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item label={"Start Date"} name={"startDate"}>
                        <DatePicker style={{width: "100%"}}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={"Rank By (Secondary)"} name={"rankBySecondary"}>
                        <Select
                            options={[]}
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item label={"End Date"} name={"endDate"}>
                        <DatePicker style={{width: "100%"}}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row className={"margin-bottom-0-5"} gutter={[16, 4]}>
                <Col span={24}>
                    <Form.List name={"podiumRewards"}>
                        {(fields, {add, remove}, {errors}) => (
                            <>
                                <Row justify={"space-between"} className={"margin-bottom-0-5 margin-top-0-75"}>
                                    <h1 className={"margin-bottom-0"}>Rewards</h1>
                                    <Button onClick={() => add()}>{t("common.add")}</Button>
                                </Row>
                                <Card>
                                    {fields.map((field, index) => (
                                        <Form.Item {...field}>
                                            <Row gutter={8}>
                                                <Col span={7}>
                                                    <Form.Item name={[field.name, 'positionStart']}>
                                                        <InputNumber className={"w-100"} placeholder={"Position Start"}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={7}>
                                                    <Form.Item name={[field.name, 'positionEnd']}>
                                                        <InputNumber className={"w-100"} placeholder={"Position End"}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item name={[field.name, 'rewardSetting']}>
                                                        <Select options={[]} placeholder={"Select Reward"}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={2} className={"text-align-right"}>
                                                    <Button icon={<DeleteOutlined/>} type={"link"} danger onClick={() => remove(field.name)}/>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    ))}
                                </Card>
                            </>
                        )}
                    </Form.List>
                </Col>
            </Row>
            {/*{*/}
            {/*    isUpdate &&*/}
            {/*    <Row className={"margin-bottom-0-75 margin-top-0-75"} gutter={[16, 4]}>*/}
            {/*        <Col span={24}>*/}
            {/*            <h1 className={"margin-bottom-0"}>UI Interface</h1>*/}
            {/*            <Button onClick={() => window.open(`/settings/arena/leaderboard-ui-editor/${item?._id}`, "_blank")}>Open in Editor</Button>*/}
            {/*        </Col>*/}
            {/*    </Row>*/}
            {/*}*/}
        </>
    )
}

export default CreateUpdateForm