import {Image, List, Switch} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";

const ListTableCell = (props) => {
  const { t } = useTranslation();
  const { record, cellName, listGrid } = props;

  let pspSettingData = record?.pspSetting;

  let nameCellData = [
    {
      //   label: t("Logo"),
      value: pspSettingData?.logo_light && pspSettingData?.logo_light[0]?.link,
      type: "image",
    },
    { label: t("Name"), value: pspSettingData?.name || "-", type: "string" },
    {
      label: t("Display Name"),
      value: pspSettingData?.displayName || "-",
      type: "string",
    },
    {
      label: t("Description"),
      value: pspSettingData?.description || "-",
      type: "string",
    },
    // {
    //   label: t("Logo (small)"),
    //   value: pspSettingData?.logoSmall[0]?.link,
    //   type: "image",
    // },
  ];

  let mainSettingsCellData = [
    {
      label: t("Processing Time"),
      value: pspSettingData?.processingTime || "-",
      type: "string",
    },
    {
      label: t("Deposit Enabled"),
      value: pspSettingData?.depositEnabled,
      type: "boolean",
    },
    {
      label: t("Withdrawal Enabled"),
      value: pspSettingData?.withdrawalEnabled,
      type: "boolean",
    },
    {
      label: t("Enabled"),
      value: pspSettingData?.enabled,
      type: "boolean",
    },
  ];

  //   let settingsCellData = [
  //     {
  //       label: t("Min Deposit"),
  //       value: record?.minDeposit,
  //       type: "string",
  //     },
  //     {
  //       label: t("Max Deposit"),
  //       value: record?.maxDeposit,
  //       type: "string",
  //     },
  //     {
  //       label: t("Min Withdrawal"),
  //       value: record?.minWithdrawal,
  //       type: "string",
  //     },
  //     {
  //       label: t("Max Withdrawal"),
  //       value: record?.maxWithdrawal,
  //       type: "string",
  //     },
  //   ];

  let paymentMethodDepositCellData = [
    {
      // label: t("Enabled"),
      value: record?.children
        ? record?.pspCurrency?.depositEnabled
        : record?.depositEnabled,
      type: "boolean",
    },
    {
      // label: t("Enabled"),
      value: record?.children
        ? ""
        : `Min: ${record?.minDeposit} | Max: ${record?.maxDeposit}`,
      type: "string",
      isShown: !record?.children,
    },
    {
      // label: t("Enabled"),
      value: record?.children
        ? ""
        : `Fee: ${record?.depositFee}% ${record?.depositFeeCondition} ${record?.pspCurrency?.currency} ${record?.depositFeeFixed}`,
      type: "string",
      isShown: !record?.children,
    },
    {
      // label: t("Enabled"),
      value: record?.children
        ? ""
        : `Client: ${record?.depositFeeClient}% ${record?.depositFeeCondition} ${record?.pspCurrency?.currency} ${record?.depositFeeFixedClient}`,
      type: "string",
      isShown: !record?.children,
    },
  ];

  let paymentMethodWithdrawalCellData = [
    {
      // label: t("Enabled"),
      value: record?.children
        ? record?.pspCurrency?.withdrawalEnabled
        : record?.withdrawalEnabled,
      type: "boolean",
    },
    {
      // label: t("Enabled"),
      value: record?.children
        ? ""
        : `Min: ${record?.minWithdrawal} | Max: ${record?.maxWithdrawal}`,
      type: "string",
      isShown: !record?.children,
    },
    {
      // label: t("Enabled"),
      value: record?.children
        ? ""
        : `Fee: ${record?.withdrawalFee}% ${record?.withdrawalFeeCondition} ${record?.pspCurrency?.currency} ${record?.withdrawalFeeFixed}`,
      type: "string",
      isShown: !record?.children,
    },
    {
      // label: t("Enabled"),
      value: record?.children
        ? ""
        : `Client: ${record?.withdrawalFeeClient}% ${record?.withdrawalFeeCondition} ${record?.pspCurrency?.currency} ${record?.withdrawalFeeFixedClient}`,
      type: "string",
      isShown: !record?.children,
    },
  ];

  let data: any = [];
  switch (cellName) {
    case "name":
      data = nameCellData;
      break;
    case "mainSettings":
      data = mainSettingsCellData;
      break;
    // case "settings":
    //   data = settingsCellData;
    //   break;
    case "paymentMethodDeposit":
      data = paymentMethodDepositCellData;
      break;
    case "paymentMethodWithdrawal":
      data = paymentMethodWithdrawalCellData;
      break;
    default:
      break;
  }

  const listItem = (item) => {
    if (item?.isShown || item?.isShow === undefined) {
      let label = <p className="listLabel">{item?.label}</p>;
      let value;
      switch (item?.type) {
        case "string":
          value = <p className="listValue">{item?.value}</p>;
          break;
        case "boolean":
          value = <Switch checked={item?.value} size="small" disabled />;
          break;
        case "image":
          value = item?.value ? (
            <Image className="pspLogo" src={item?.value} alt="logo" />
          ) : (
            <p>-</p>
          );
          break;
        default:
          value = <p className="listValue">{item?.value}</p>;
          break;
      }
      return (
        <>
          {label}
          {value}
        </>
      );
    }
  };

  return (
    <List
      size="small"
      grid={{ gutter: 8, column: 1, xs: 1, sm: 1, ...listGrid }}
      dataSource={data}
      renderItem={(item: any) => (
        <List.Item className="cellListItem">{listItem(item)}</List.Item>
      )}
    />
  );
};

export default ListTableCell;
