import React from "react";
import CommLogBatchTable from "./component/commLogBatchTable";
import {Breadcrumb} from "antd";

const CommLogBatch = (props) => {

    const {data,headerDetails,setHeaderFunction,currentPage} = props;

    return (
        <>
            <Breadcrumb
                separator=">"
                items={headerDetails}
            />

            <CommLogBatchTable data={data} headerDetails={headerDetails} setHeaderFunction={setHeaderFunction} currentPage={currentPage}/>

        </>

    )

}

export default CommLogBatch