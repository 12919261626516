import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Tag} from "antd";
import {formatDateTimeTimezone} from "../../../function/_common";
import {getColumnSearchProps, getSingleSelectFilterProps} from "../../../components/table/function/filterTable";
import {useRecoilValue} from "recoil";
import {userOptionsState} from "../../users/_common/state";

const EmailSeeMoreComponent = (props) => {
    const {text, record} = props;
    const [view, setView] = useState(false);

    const emails = Array.isArray(text) ? text : (record?.to || []);

    const emailsToShow = emails.slice(0, view ? emails.length : 1);

    const renderEmailTag = (email, i) => (
        <Tag key={i} style={{margin: 2}} color={"geekblue"}>
            {email?.email}
        </Tag>
    );

    const renderSeeMoreTag = () => (
        <Tag style={{margin: 2, cursor: "pointer"}} color={"default"} onClick={() => setView(true)}>
            +{emails.length - emailsToShow.length}
        </Tag>
    );

    return (
        <div>
            {emailsToShow.length > 0 ? (
                <>
                    {emailsToShow.map(renderEmailTag)}
                    {!view && emails.length > 1 && renderSeeMoreTag()}
                </>
            ) : (
                "-"
            )}
        </div>
    );
};

const PushNotificationSeeMoreComponent = (props) => {
    const {text, record} = props;
    const userOptions = useRecoilValue<any>(userOptionsState);
    const [view, setView] = useState(false);

    const recipient = Array.isArray(text) ? text : (record?.recipient || []);
    const mappedTags = recipient?.map(id => {
        const toUser = userOptions?.find(user => user.value === id);
        return toUser?.selectedLabel;
    });

    const tagsToShow = mappedTags?.slice(0, view ? mappedTags.length : 1);

    const renderTag = (tag, i) => (
        <Tag key={i} style={{margin: 2}} color={"geekblue"}>
            {tag}
        </Tag>
    );

    const renderSeeMoreTag = () => (
        <Tag style={{margin: 2, cursor: "pointer"}} color={"default"} onClick={() => setView(true)}>
            +{mappedTags?.length - tagsToShow?.length}
        </Tag>
    );

    return (
        <div>
            {tagsToShow?.length > 0 ? (
                <>
                    {tagsToShow?.map(renderTag)}
                    {!view && mappedTags?.length > 1 && renderSeeMoreTag()}
                </>
            ) : (
                "-"
            )}
        </div>
    );
};

const UserTypeComponent = (props: any) => {
    const {t} = useTranslation();
    const {text} = props;
    let color = "default"
    let type = ""

    switch (text) {
        default:
        case "all":
            type = t("common.all");
            break;
        case "client":
            color = "green";
            type = t("user.client");
            break;
        case "crmUser":
            color = "blue";
            type = t("user.crmUser");
            break;
    }

    return <Tag color={color}>{type}</Tag>
}

const StatusComponent = (props: any) => {
    const {t} = useTranslation();
    const {text} = props;
    let color = "default"
    let status

    switch (text) {
        case "success":
            color = "green";
            status = t("message.status_success");
            break;
        case "failed":
            color = "red";
            status = t("message.status_fail");
            break;
        case "processing":
            color = "geekblue";
            status = t("message.status_processing");
            break;
        case "success_with_fail":
            color = "lime";
            status = t("message.success_with_fail");
            break;
        case "in_queue":
            color = "purple";
            status = t("lead.upload_status.in_queue");
            break;
        default:
            status = text
    }

    return <Tag color={color}>{status}</Tag>
}

export const MessageOutboxTableColumns = (emailTemplate?) => {
    const {t} = useTranslation();

    return [
        {
            title: t("message.from"),
            dataIndex: "from",
            key: "from",
            render: (_, record) => <p>{record?.sendFromEmail?.emailName || "-"}</p>,
        },
        {
            title: t("message.to"),
            dataIndex: "to",
            key: "to",
            width: 200,
            render: (text, record) => {
                return <EmailSeeMoreComponent record={record}/>
            },
        },
        {
            title: t("user.userType"),
            dataIndex: "userType",
            key: "userType",
            ...getColumnSearchProps(),
            render: (text) => {
                return <UserTypeComponent text={text}/>
            },
        },
        {
            title: t("message.subject"),
            dataIndex: "subject",
            key: "subject",
            render: (_, record) => {
                const foundTemplate = emailTemplate?.find(d => d?.name === record?.fromTemplate?.name)?.displaySubjectLanguage
                const subject = foundTemplate?.find(d => d?.language === localStorage.getItem("language"))?.label

                return <p>{subject || "-"}</p>
            }
        },
        {
            title: t("message.status"),
            dataIndex: "status",
            key: "status",
            ...getSingleSelectFilterProps([
                {label: t("message.status_success"), value: "success"},
                {label: t("message.success_with_fail"), value: "success_with_fail"},
                {label: t("message.status_fail"), value: "fail"},
                {label: t("message.status_processing"), value: "processing"},
            ]),
            render: (text) => {
                return <StatusComponent text={text}/>
            },
        },
        {
            title: t("message.total_success"),
            dataIndex: "totalSuccess",
            key: "totalSuccess",
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("message.total_fail"),
            dataIndex: "totalFail",
            key: "totalFail",
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("message.date_time"),
            dataIndex: "dateTime",
            key: "dateTime",
            render: (_, record) => <p>{formatDateTimeTimezone(record?.createdAt)}</p>,
        }
    ]
}

export const MessageDraftTableColumns = (emailTemplate?) => {
    const {t} = useTranslation();

    return [
        {
            title: t("message.from"),
            dataIndex: "from",
            key: "from",
            render: (_, record) => <p>{record?.sendFromEmail?.emailName || "-"}</p>,
        },
        {
            title: t("user.userType"),
            dataIndex: "userType",
            key: "userType",
            ...getColumnSearchProps(),
            render: (text) => {
                return <UserTypeComponent text={text}/>
            },
        },
        {
            title: t("message.to"),
            dataIndex: "to",
            key: "to",
            width: 200,
            render: (text, record) => {
                return <EmailSeeMoreComponent record={record}/>
            },
        },
        {
            title: t("message.subject"),
            dataIndex: "subject",
            key: "subject",
            render: (_, record) =>
                <p>{record?.fromTemplate?.subject || emailTemplate?.find(d => d?.name === (record?.name))?.subject || "-"}</p>,
        },
        // {
        //     title: "Type",
        //     dataIndex: "type",
        //     key: "type",
        //     render: () => <Tag color={"geekblue"}>{"Email"}</Tag>,
        // },
        {
            title: t("message.date_time"),
            dataIndex: "dateTime",
            key: "dateTime",
            render: (_, record) =>
                <p>{formatDateTimeTimezone(record?.createdAt || emailTemplate?.find(d => d?.name === record?.name)?.updatedAt)}</p>,
        }
    ]
}

export const NotificationOutboxTableColumns = () => {
    const {t} = useTranslation();

    return [
        {
            title: t("message.to"),
            dataIndex: "recipient",
            key: "recipient",
            width: 200,
            ...getColumnSearchProps(),
            render: (text, record) => {
                return <PushNotificationSeeMoreComponent record={record}/>
            },
        },
        {
            title: t("user.userType"),
            dataIndex: "userType",
            key: "userType",
            ...getColumnSearchProps(),
            render: (text) => {
                return <UserTypeComponent text={text}/>
            },
        },
        {
            title: t("message.from_template"),
            dataIndex: "fromTemplate",
            key: "fromTemplate",
            render: (text) => {
                if (text?.name && text?.name?.includes("- Edited")) {
                    const originalName = text?.name?.split("- Edited")[0]?.trim();

                    return (
                        <p>
                            {originalName}<Tag className={"edited-tag"}>Edited</Tag>
                        </p>
                    );
                }
                return <p>{text?.name || "-"}</p>;
            }
        },
        {
            title: t("message.status"),
            dataIndex: "status",
            key: "status",
            ...getSingleSelectFilterProps([
                {label: t("message.status_success"), value: "success"},
                {label: t("message.success_with_fail"), value: "success_with_fail"},
                {label: t("message.status_fail"), value: "fail"},
                {label: t("message.status_processing"), value: "processing"},
            ]),
            render: (text) => {
                return <StatusComponent text={text}/>
            },
        },
        {
            title: t("message.total_success"),
            dataIndex: "totalSuccess",
            key: "totalSuccess",
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("message.total_fail"),
            dataIndex: "totalFail",
            key: "totalFail",
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("message.date_time"),
            dataIndex: "dateTime",
            key: "dateTime",
            render: (_, record) => <p>{formatDateTimeTimezone(record?.createdAt)}</p>,
        }
    ]
}

export const NotificationDraftTableColumns = () => {
    const {t} = useTranslation();

    return [
        {
            title: t("message.to"),
            dataIndex: "recipient",
            key: "recipient",
            width: 200,
            ...getColumnSearchProps(),
            render: (text, record) => {
                return <PushNotificationSeeMoreComponent record={record}/>
            },
        },
        {
            title: t("user.userType"),
            dataIndex: "userType",
            key: "userType",
            ...getColumnSearchProps(),
            render: (text) => {
                return <UserTypeComponent text={text}/>
            },
        },
        {
            title: t("message.from_template"),
            dataIndex: "fromTemplate",
            key: "fromTemplate",
            render: (text) => {
                if (text?.name && text?.type === "message" && text?.name?.includes("- Edited")) {
                    const originalName = text?.name?.split("- Edited")[0]?.trim();

                    return (
                        <p>
                            {originalName}<Tag className={"edited-tag"}>Edited</Tag>
                        </p>
                    );
                }
                return <p>{text?.name || "-"}</p>;
            }
        },
        {
            title: t("message.send_in_network"),
            dataIndex: "sendInNetwork",
            key: "sendInNetwork",
            render: (boolean) => {
                const text = boolean ? "Yes" : "No"

                return <p>{text}</p>
            }
        },
        {
            title: t("message.date_time"),
            dataIndex: "dateTime",
            key: "dateTime",
            render: (_, record) => <p>{formatDateTimeTimezone(record?.updatedAt || record?.createdAt)}</p>,
        }
    ]
}
