import { Form, Input, InputNumber, Select, Space } from "antd";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import FormErrors from "./formErrors";

const InputNumberSelectFormItem = (props) => {
  const {
    layout,
    label,
    nameInput,
    nameSelect,
    placeholder,
    prefix,
    externalErrorMessage,
    required,
    onChange,
    disabled,
    labelCol,
    labelAlign,
    mode,
    options,
    showSearch,
    filterOption,
    showArrow,
    selectFieldWidth,
    allowClear,
    colon,
    max,
    min,
    addonBefore,
    addonAfter,
    extra,
    decimalSeparator,
    formatter,
    parser,
    precision,
    step,
  } = props;

  const {
    register,
    watch,
    setValue,
    formState: { touchedFields, isSubmitted, errors },
  } = useFormContext();

  const errorMessage = FormErrors.errorMessage(
    nameInput,
    errors,
    touchedFields,
    isSubmitted,
    externalErrorMessage
  );

  useEffect(() => {
    register(nameInput, nameSelect);
  }, [register, nameInput, nameSelect]);

  return (
    <Form.Item
      {...layout}
      labelCol={labelCol}
      labelWrap={true}
      label={label}
      labelAlign={labelAlign}
      colon={colon}
      required={required}
      validateStatus={errorMessage ? "error" : "success"}
      help={errorMessage}
      extra={extra}
    >
      <Space.Compact block>
        <InputNumber
          id={nameInput}
          name={nameInput}
          max={max}
          min={min}
          value={watch(nameInput)}
          onChange={(value) => {
            setValue(nameInput, value, { shouldValidate: true });
            onChange && onChange(value);
          }}
          placeholder={placeholder}
          prefix={prefix}
          disabled={disabled}
          addonAfter={addonAfter}
          addonBefore={addonBefore}
          decimalSeparator={decimalSeparator}
          formatter={formatter}
          parser={parser}
          precision={precision}
          step={step}
        />
        <Select
          id={nameSelect}
          mode={mode}
          options={options}
          value={watch(nameSelect)}
          onChange={(e) => {
            setValue(nameSelect, e, { shouldValidate: true });
            onChange && onChange(e);
          }}
          filterOption={filterOption}
          placeholder={placeholder}
          showSearch={showSearch}
          showArrow={showArrow}
          allowClear={allowClear}
          disabled={disabled}
          style={{ width: selectFieldWidth, textAlign: "center" }}
        />
      </Space.Compact>
    </Form.Item>
  );
};

InputNumberSelectFormItem.defaultProps = {
  required: false,
  disabled: false,
  controls: true,
  colon: false,
  labelCol: { span: 24, offset: 0 },
};

InputNumberSelectFormItem.propTypes = {
  label: PropTypes.string,
  labelAlign: PropTypes.string,
  nameInput: PropTypes.string.isRequired,
  nameSelect: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  prefix: PropTypes.object,
  value: PropTypes.string,
  externalErrorMessage: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  layout: PropTypes.object,
  options: PropTypes.array,
  selectFieldWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.number,
  min: PropTypes.number,
  extra: PropTypes.string,
  controls: PropTypes.bool || PropTypes.func,
  decimalSeparator: PropTypes.string,
  formatter: PropTypes.func,
  parser: PropTypes.func,
  precision: PropTypes.number,
  readOnly: PropTypes.bool,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  stringMode: PropTypes.bool,
  onStep: PropTypes.func,
  colon: PropTypes.bool,
};

export default InputNumberSelectFormItem;
