import React, {useEffect, useState} from "react";
import {Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Space, Steps, Upload} from "antd";
import {useTranslation} from "react-i18next";
import {useAddSalesOpportunityMutation, useUpdateSalesOpportunityMutation} from "../../../../../../api/graphql/salesOpportunity";
import dayjs from "dayjs";
import useAuthorize from "../../../../../../_common/authorize";
import {useRecoilValue} from "recoil";
import {salesOpportunityStatusData} from "../../../../../settings/salesOpportunitySetting/state";
import TextArea from "antd/es/input/TextArea";
import {getConfigByName} from "../../../../../../../config";
import {BRAND_NAME} from "../../../../../../../config/brandVariable";
import {formatDateUTC} from "../../../../../../function/_common";

const SalesOpportunityForm = (props) => {
    const {isOpen, setIsOpen, data, mode, formData, type, statusList, setStatusChanged, refetchSalesOpportunity} = props

    const {t} = useTranslation()
    const [form] = Form.useForm()
    const labelCol = {span: 10, offset: 0}
    const [current, setCurrent] = useState<any>(0);
    const [status, setStatus] = useState<any>(formData?.salesOppStatus?._id)
    const [fileList, setFileList] = useState<any>([])

    const {addSalesOpportunity, loading: addLoading} = useAddSalesOpportunityMutation()
    const {updateSalesOpportunity, loading: updateLoading} = useUpdateSalesOpportunityMutation()
    const {systemErrorNotification} = useAuthorize()

    const salesOppStatusList = useRecoilValue<any>(salesOpportunityStatusData)
    const lostStatus = salesOppStatusList?.data?.find(d => d?.type === "end")
    const brandConfig = getConfigByName(BRAND_NAME);

    const onCancel = () => {
        setIsOpen(false)
    }

    const onSubmit = async (value) => {
        try {
            const formattedValue = {
                ...value,
                date: formatDateUTC(value?.date, "formSubmit"),
                amount: value?.amount?.toString(),
                attachment: fileList?.map(d => d?.url || d?.response?.[0]?.link),
                [type]: data?._id,
                userType: type
            }

            delete formattedValue["salesRep"];
            if (type === "lead") delete formattedValue["client"]
            if (type === "client") delete formattedValue["lead"]

            if (mode === "Add") {
                const response = await addSalesOpportunity(formattedValue)
                if (response?.data?.addSalesOpportunity?.__typename === "SalesOpportunity") {
                    refetchSalesOpportunity()
                    setIsOpen(false)
                }
            } else if (mode === "Edit") {
                const response = await updateSalesOpportunity(formData?._id, formattedValue)
                if (response?.data?.updateSalesOpportunity?.__typename === "SalesOpportunity") {
                    refetchSalesOpportunity()
                    setIsOpen(false)
                }
            }
        } catch (e) {
            console.log(e)
            systemErrorNotification()
        } finally {
            setStatusChanged !== undefined && setStatusChanged(true)
        }
    }

    const onChange = (value) => {
        setCurrent(value)
        setStatus(salesOppStatusList?.data?.find((obj) => obj?.position === (value + 1))?._id);
    };

    useEffect(() => {
        if (isOpen) {
            setStatus(formData?.salesOppStatus?._id)
            setCurrent(salesOppStatusList?.data?.find((obj) => obj?._id === formData?.salesOppStatus?._id)?.position - 1)
            form.resetFields()

            const files = formData?.attachment?.map(d => {
                const lastIndex = d.lastIndexOf("_");

                return {
                    uid: d,
                    name: d?.slice(0, lastIndex)?.replace(`${brandConfig?.backendURL}/assets/salesOpportunity/`, ""),
                    url: d,
                }
            })

            setFileList(files)
        }
    }, [formData, isOpen])

    const stepsItems = salesOppStatusList?.data?.slice(0, -1)?.map((val) => {
        return {title: t(val?.displayName)};
    })

    const statusOptions = salesOppStatusList?.data?.map((val) => {
        return {label: t(val?.displayName), value: val?._id, key: val?.position};
    })

    const renderedSteps = stepsItems?.map((item, index) => (
        <Steps.Step
            key={index}
            title={item.title}
            disabled={status === lostStatus?._id}
        />
    ));

    return (
        <>
            <Modal
                title={t(mode === "Add" ? "salesOpportunity.addSalesOpportunity" : "salesOpportunity.editSalesOpportunity")}
                open={isOpen}
                footer={(
                    <Row justify={"space-between"}>
                        {
                            mode !== "Add" && status !== lostStatus?._id &&
                            <Button danger onClick={() => {
                                setStatus(lostStatus?._id)
                                form.setFieldValue("salesOppStatus", lostStatus?._id)
                            }}>
                                {t(lostStatus?.displayName)}
                            </Button>
                        }
                        <Space className={"margin-left-auto"}>
                            <Button onClick={onCancel}>{t("common.cancel")}</Button>
                            <Button loading={addLoading || updateLoading} type={"primary"}
                                    onClick={() => form.submit()}>{t("common.submit")}</Button>
                        </Space>
                    </Row>
                )}
                onCancel={onCancel}
                destroyOnClose
                width={"max-content"}
            >
                <Form form={form} colon={false} onFinish={onSubmit} className="form-1" labelWrap>
                    <Steps
                        current={current}
                        onChange={onChange}
                        progressDot={true}
                        size={"small"}
                        responsive
                        style={{marginTop: "24px", marginBottom: "24px", overflowX: "auto"}}
                    >{renderedSteps}</Steps>
                    <Row gutter={[16, 16]} className={"margin-top-0-75 margin-bottom-0-75"}>
                        <Col span={12}>
                            <Space direction={"vertical"} style={{width: "100%"}}>
                                <Form.Item
                                    name={"salesOpportunityName"}
                                    label={t("salesOpportunity.salesOpportunityName")}
                                    labelCol={labelCol}
                                    labelAlign={"left"}
                                    initialValue={formData?.salesOpportunityName}
                                    required
                                >
                                    <Input placeholder={t("salesOpportunity.salesOpportunityName").toString()}/>
                                </Form.Item>
                                {
                                    type === "lead" ?
                                        <Form.Item
                                            name={"lead"}
                                            label={t("salesOpportunity.leadsName")}
                                            labelCol={labelCol}
                                            labelAlign={"left"}
                                            initialValue={formData?.name || data?.fullName}
                                            required
                                        >
                                            <Input disabled placeholder={t("salesOpportunity.leadsName").toString()}/>
                                        </Form.Item>
                                        :
                                        <Form.Item
                                            name={"client"}
                                            label={t("salesOpportunity.clientsName")}
                                            labelCol={labelCol}
                                            labelAlign={"left"}
                                            initialValue={formData?.username || data?.username}
                                            required
                                        >
                                            <Input disabled placeholder={t("salesOpportunity.clientsName").toString()}/>
                                        </Form.Item>
                                }
                                <Form.Item
                                    name={"salesRep"}
                                    label={t("user.salesRep")}
                                    labelCol={labelCol}
                                    labelAlign={"left"}
                                    initialValue={formData?.salesRepName || data?.salesRep?.username}
                                    required
                                >
                                    <Input disabled placeholder={t("user.salesRep").toString()}/>
                                </Form.Item>
                                <Form.Item
                                    label={t("common.attachments")}
                                    labelCol={labelCol}
                                    labelAlign={"left"}
                                >
                                    <Upload
                                        action={`${brandConfig?.backendURL}/api/upload/salesOpportunity/document`}
                                        onChange={e => setFileList(e.fileList)}
                                        multiple
                                        fileList={fileList}
                                    >
                                        <Button>{t("common.upload")}</Button>
                                    </Upload>
                                </Form.Item>
                            </Space>
                        </Col>
                        <Col span={12}>
                            <Space direction={"vertical"} style={{width: "100%"}}>
                                <Form.Item
                                    name={"salesOppStatus"}
                                    label={t("common.status")}
                                    labelCol={labelCol}
                                    labelAlign={"left"}
                                    initialValue={formData?.salesOppStatus?._id}
                                    required
                                >
                                    <Select
                                        options={statusOptions}
                                        value={status}
                                        onChange={(val) => {
                                            const found = statusOptions?.find(d => d?.value === val)

                                            setStatus(val)
                                            setCurrent(found.key - 1)
                                        }}
                                        placeholder={t("common.status")}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name={"amount"}
                                    label={t("common.amount")}
                                    labelCol={labelCol}
                                    labelAlign={"left"}
                                    initialValue={formData?.amount}
                                >
                                    <InputNumber
                                        placeholder={t("common.amount").toString()}
                                        min={0}
                                        step={2}
                                        style={{width: "100%"}}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name={"date"}
                                    label={t("common.date")}
                                    labelCol={labelCol}
                                    labelAlign={"left"}
                                    initialValue={formData?.date ? formatDateUTC(formData?.date, "formValue") : null}
                                    required
                                >
                                    <DatePicker style={{width: "100%"}}/>
                                </Form.Item>
                                <Form.Item
                                    name={"remark"}
                                    label={t("salesOpportunity.remark")}
                                    labelCol={labelCol}
                                    labelAlign={"left"}
                                    initialValue={formData?.remark}
                                    required
                                >
                                    <TextArea rows={2}/>
                                </Form.Item>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
};

export default SalesOpportunityForm