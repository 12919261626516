import {getDeskDropdownAsync, getMultiTierSettingDropdownAsync, getRoleTemplateDropdownAsync, getUserDropdown} from "../api/graphql/_common";
import {formatSalesRepNetworkOptions, formatUserNetworkOptions, formatUserOptions} from "../function/_common";
import {getUserNetworkTreeAsync} from "../api/graphql/userNetworkTree";
import {getSalesRepNetworkTreeAsync} from "../api/graphql/salesRepNetworkTree";
import {useSetRecoilState} from "recoil";
import {
    arenaMediaFileState, arenaMediaFolderState,
    brandDomainOptionsState,
    deskNetworkOptionsState,
    deskOptionsState, languageOptionsState,
    rankOptionsState, roleOptionsState,
    salesRepNetworkOptionsState,
    userNetworkOptionsState,
    userOptionsState
} from "../pages/users/_common/state";
import {getBrandDomainDropdownAsync} from "../api/graphql/brandSetting";
import {getLanguageDropdown} from "../api/graphql/language";
import {getMedia} from "../api/restful/arena/media_file";
import {getMediaFolder} from "../api/restful/arena/media_folder";

export const useSetDropdown = () => {
    const setUserOptions = useSetRecoilState(userOptionsState);
    const setUserNetworkOptions = useSetRecoilState(userNetworkOptionsState);
    const setSalesRepNetworkOptions = useSetRecoilState(salesRepNetworkOptionsState);
    const setDeskOptions = useSetRecoilState(deskOptionsState);
    const setDeskNetworkOptions = useSetRecoilState(deskNetworkOptionsState);
    const setRankOptions = useSetRecoilState(rankOptionsState);
    const setRoleOptions = useSetRecoilState(roleOptionsState);
    const setBrandDomainOptions = useSetRecoilState(brandDomainOptionsState);
    const setLanguageOptions = useSetRecoilState(languageOptionsState);
    const setArenaMediaFile = useSetRecoilState(arenaMediaFileState)
    const setArenaMediaFolder = useSetRecoilState(arenaMediaFolderState)

    const setUpUserOptions = async () => {
        const response: any = await getUserDropdown(localStorage.getItem("g_userId") || localStorage.getItem("userId"));
        setUserOptions(response?.getUserDropdown?.data?.map((d) => formatUserOptions(d)));
    };

    const setUpUserNetworkOptions = async () => {
        const userNetworkList = await getUserNetworkTreeAsync()
        setUserNetworkOptions(userNetworkList?.getUserNetworkTree?.data?.map((d) => formatUserNetworkOptions(d)))
    }

    const setUpSalesRepNetworkOptions = async () => {
        const salesRepNetworkList = await getSalesRepNetworkTreeAsync()
        setSalesRepNetworkOptions(salesRepNetworkList?.getSalesRepNetworkTree?.data?.map((d) => formatSalesRepNetworkOptions(d)))
    }

    const setUpDeskOptions = async () => {
        const deskList = await getDeskDropdownAsync(localStorage.getItem("g_userId") || localStorage.getItem("userId"))
        setDeskNetworkOptions(deskList?.getDeskDropdown?.data?.map((d) => {
            return {
                label: d?.name,
                value: d?._id,
                selectedLabel: d?.name,
            };
        }))

        setDeskOptions(deskList?.getDeskDropdown?.data?.map((d) => {
                return {
                    label: d?.name,
                    value: d?._id,
                    selectedLabel: d?.name,
                };
            })
        );
    }

    const setUpRoleOptions = async () => {
        const roleList = await getRoleTemplateDropdownAsync(localStorage.getItem("g_userId") || localStorage.getItem("userId"))
        setRoleOptions(roleList?.getRoleTemplateDropdown?.data?.map((d) => {
            return {
                label: d?.name,
                value: d?._id,
                isDefault: d?.isDefaultCrmUserPermission,
            };
        }))
    }

    const setUpRankOptions = async () => {
        const rankList = await getMultiTierSettingDropdownAsync(localStorage.getItem("g_userId") || localStorage.getItem("userId"))
        setRankOptions(rankList?.getMultiTierSettingDropdown?.data?.map((d) => {
            return {
                label: d?.name,
                value: d?._id,
                isDefault: d?.isDefaultRank,
                type: d?.type,
            };
        }))
    }

    const setUpBrandDomainOptions = async () => {
        const domains = await getBrandDomainDropdownAsync()
        setBrandDomainOptions(domains?.getBrandDomainDropdown?.data)
    }

    const setUpLanguageOptions = async () => {
        const language = await getLanguageDropdown()
        setLanguageOptions(language?.getLanguageDropdown?.data?.map(d => ({
            label: d?.nativeName || d?.name,
            value: d?.code,
        })))
    }

    const getArenaMedia = async () => {
        try {
            const responseMedia = await getMedia({filter: {isDeleted: false}})
            setArenaMediaFile(responseMedia)
        } catch (e) {}
    }

    const getArenaFolder = async () => {
        try {
            const responseFolder = await getMediaFolder({filter: {isDeleted: false}})
            setArenaMediaFolder(responseFolder)
        } catch (e) {}
    }

    return {
        setUpUserOptions,
        setUpUserNetworkOptions,
        setUpSalesRepNetworkOptions,
        setUpDeskOptions,
        setUpRoleOptions,
        setUpRankOptions,
        setUpBrandDomainOptions,
        setUpLanguageOptions,
        getArenaMedia,
        getArenaFolder
    }
}