import {gql, useApolloClient, useMutation, useQuery} from "@apollo/client";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {useTranslation} from "react-i18next";
import {ApolloClientQuery} from "./_apolloClient";

const GET_ALL_COLLECTION_FIELDS = gql`
    query {
        getAllCollectionFields
    }
`;

const getAllCollectionFields = () => {
    try {
        const {loading, error, data} = useQuery(GET_ALL_COLLECTION_FIELDS, {
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_USER_TABLE_ADMIN_SETTING = gql`
    query getUserTableAdminSetting(
        $filter: UserTableAdminSettingSearchInput
        $limit: Int
        $offset: Int
        $orderBy: String
    ) {
        getUserTableAdminSetting(
            filter: $filter
            limit: $limit
            offset: $offset
            orderBy: $orderBy
        ) {
            ... on UserTableAdminSettingArray {
                data {
                    id
                    tableName
                    fieldData {
                        fieldName
                        isDisplayToUser
                        isSelectableByUser
                        isLock
                        defaultPosition
                    }
                    server {
                        name
                    }
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getUserTableAdminSetting = ({filter}, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data} = useQuery(GET_USER_TABLE_ADMIN_SETTING, {
            variables: {
                filter: {
                    tableName: filter?.tableName || null,
                    serverName: filter?.serverName || null,
                },
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const getUserTableAdminSettingAsync = async ({filter}, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_USER_TABLE_ADMIN_SETTING,
            {
                filter: {
                    tableName: filter?.tableName || null,
                    serverName: filter?.serverName || null,
                },
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};


const UPDATE_USER_TABLE_ADMIN_SETTING = gql`
    mutation updateUserTableAdminSetting($input: UserTableAdminSettingListInput) {
        updateUserTableAdminSetting(input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on UserTableAdminSettingArray {
                data {
                    _id
                    id
                    tableName
                    fieldData {
                        fieldName
                        isDisplayToUser
                        isSelectableByUser
                        isLock
                        defaultPosition
                    }
                    server {
                        name
                    }
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useUpdateUserTableAdminSetting = (): {
    updateUserTableAdminSetting: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [updateUserTableAdminSettingMutation, {data, loading, error}] =
            useMutation(UPDATE_USER_TABLE_ADMIN_SETTING);

        const updateUserTableAdminSetting = (input) => {
            return updateUserTableAdminSettingMutation({
                variables: {
                    input: {
                        data: {
                            tableName: input?.tableName || null,
                            fieldData: input?.fieldData || null,
                            server: input?.server || null,
                        },
                    },
                },
                update(cache, {data: {updateUserTableAdminSetting}}) {
                    const typename = updateUserTableAdminSetting?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateUserTableAdminSetting?.errKey),
                            key: "updateUserTableAdminSetting",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateUserTableAdminSetting?.msg),
                            key: "updateUserTableAdminSetting",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t(
                                `Update ${input?.tableName} Table Admin Setting Successfully`
                            ),
                            key: "updateUserTableAdminSetting",
                        });
                    }
                },
                refetchQueries: [GET_USER_TABLE_ADMIN_SETTING]
            });
        };

        return {updateUserTableAdminSetting, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            updateUserTableAdminSetting: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const GET_USER_TABLE_SETTING = gql`
    query getUserTableSetting(
        $userid: String
        $filter: UserTableSettingSearchInput
    ) {
        getUserTableSetting(userid: $userid, filter: $filter) {
            ... on UserTableSetting {
                id
                userid {
                    fullName
                }
                tableName
                tableColumn {
                    columnName
                    position
                }
                server {
                    name
                }
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getUserTableSetting = (userid, {filter}) => {
    try {
        const {loading, error, data} = useQuery(GET_USER_TABLE_SETTING, {
            variables: {
                userid: userid,
                filter: {
                    tableName: filter?.tableName || null,
                    serverName: filter?.serverName || null,
                },
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UPDATE_USER_TABLE_SETTING = gql`
    mutation updateUserTableSetting($input: UserTableSettingInput) {
        updateUserTableSetting(input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on UserTableSetting {
                id
                userid {
                    fullName
                }
                tableName
                tableColumn {
                    columnName
                    position
                }
                server {
                    name
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useUpdateUserTableSetting = (): {
    updateUserTableSetting: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const client = useApolloClient();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [updateUserTableSettingMutation, {data, loading, error}] =
            useMutation(UPDATE_USER_TABLE_SETTING);

        const updateUserTableSetting = (input) => {
            return updateUserTableSettingMutation({
                variables: {
                    input: {
                        userid: input?.userid,
                        tableName: input?.tableName,
                        tableColumn: input?.tableColumn,
                        server: input?.server,
                    },
                },
                update(cache, {data: {updateUserTableSetting}}) {
                    const typename = updateUserTableSetting?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateUserTableSetting?.errKey),
                            key: "updateUserTableSetting",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateUserTableSetting?.msg),
                            key: "updateUserTableSetting",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t(`Update ${input?.tableName} Table Columns Successfully`),
                            key: "updateUserTableSetting",
                        });
                    }
                },
                refetchQueries: [GET_USER_TABLE_SETTING]
            });
        };

        return {updateUserTableSetting, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            updateUserTableSetting: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};


const GET_USER_TABLE_ADMIN_SETTING_EXPORT_DATA = gql`
    query getUserTableAdminSettingExportData{
        getUserTableAdminSettingExportData
    }
`;

const getUserTableAdminSettingExportData = () => {
    try {
        const {loading, error, data} = useQuery(GET_USER_TABLE_ADMIN_SETTING_EXPORT_DATA, {
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const IMPORT_USER_TABLE_ADMIN_SETTING_EXPORT_DATA = gql`
    mutation importUserTableAdminSettingData($input: String) {
        importUserTableAdminSettingData(input: $input) {
            ... on UserTableAdminSettingArray {
                data {
                    id
                    tableName
                    fieldData {
                        fieldName
                        isDisplayToUser
                        isSelectableByUser
                        isLock
                        defaultPosition
                    }
                    server {
                        name
                    }
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useImportUserTableAdminSettingData = (): {
    importUserTableAdminSettingData: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const {t} = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [importUserTableAdminSettingDataMutation, {data, loading, error}] =
            useMutation(IMPORT_USER_TABLE_ADMIN_SETTING_EXPORT_DATA);

        const importUserTableAdminSettingData = (input) => {
            return importUserTableAdminSettingDataMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {importUserTableAdminSettingData}}) {
                    const typename = importUserTableAdminSettingData?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(importUserTableAdminSettingData?.errKey),
                            key: "importUserTableAdminSettingData",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(importUserTableAdminSettingData?.msg),
                            key: "importUserTableAdminSettingData",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Successfully"),
                            key: "importUserTableAdminSettingData",
                        });
                    }
                },
                refetchQueries: [GET_USER_TABLE_ADMIN_SETTING],
            });
        };

        return {importUserTableAdminSettingData, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {importUserTableAdminSettingData: null, data: null, loading: false, error: err};
    }
};

export {
    getAllCollectionFields,
    getUserTableAdminSetting,
    getUserTableAdminSettingAsync,
    useUpdateUserTableAdminSetting,
    getUserTableSetting,
    useUpdateUserTableSetting,
    getUserTableAdminSettingExportData,
    useImportUserTableAdminSettingData
};
