import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Col, Popconfirm, Row, Tooltip} from "antd";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import InputFormItem from "../../../../../components/form/inputFormItem";
import { verticalFormLayout } from "../../../../../components/form/formWrapper";
import SelectFormItem from "../../../../../components/form/selectFormItem";
import InputNumberFormItem from "../../../../../components/form/inputNumberFormItem";


const VoucherGroupPool = (props) => {
    const {poolValue, setPoolValue, mode, isRandomReward, form, voucher} = props;
    const {t} = useTranslation();

    const removeFromPool = (index) => {
        let result = [...poolValue]
        for(const [keys,_] of Object.entries(poolValue[index])){
            form.setValue(keys + "_" + (poolValue?.length - 1), null)
        }
        result.splice(index, 1)
        setPoolValue(result)
    }

    const handleOnChange = (index, field, value) => {
        let result = [...poolValue]
        result[index][field] = value
        setPoolValue(result)
    }

    // const voucherOptions = () => {
    //     let options = [...voucher]
    //     let usedVoucher = poolValue?.map(item => item?.voucher)
    //     options = options.filter(item => !usedVoucher.includes(item.value))
    //     return options
    // }

    return (
        <>
            {
                poolValue.map((item, index)=> {
                    return (
                    <Row gutter={16} className="poolCard">
                        <Col className="gutter-row" xs={24} md={12}>
                            <SelectFormItem
                                name={`voucher_${index}`}
                                label={"Base Voucher"}
                                placeholder={"Base Voucher"}
                                options={voucher}
                                value={item?.voucher}
                                layout={verticalFormLayout}
                                onChange={(e)=> handleOnChange(index, "voucher", e)}
                                disabled={mode === "view"}
                            />
                        </Col>
                        <Col className="gutter-row" xs={12} md={6}>
                            <InputNumberFormItem
                                name={`maximumRedeem_${index}`}
                                label={"Max Redeem"}
                                placeholder={"Max Redeem"}
                                value={item?.maximumRedeem}
                                layout={verticalFormLayout}
                                onChange={(e)=> handleOnChange(index, "maximumRedeem", e)}
                                disabled={mode === "view"}
                                min={0}
                            />
                        </Col>
                        {
                            isRandomReward &&
                            <Col className="gutter-row" xs={11} md={5}>
                                <InputNumberFormItem
                                    name={`probability_${index}`}
                                    label={"Probability %"}
                                    placeholder={"Probability"}
                                    value={item?.probability}
                                    layout={verticalFormLayout}
                                    onChange={(e)=> handleOnChange(index, "probability", e)}
                                    disabled={mode === "view"}
                                    min={0}
                                />
                            </Col>
                        }
                        <Tooltip title={t("Delete")}>
                          <Popconfirm
                            placement="left"
                            title={t("Are you sure to delete this voucher from the pool?")}
                            onConfirm={() => {
                                removeFromPool(index);
                            }}
                            okText={t("common.yes")}
                            cancelText={t("common.no")}
                          >
                            <a>
                              <DeleteOutlined />
                            </a>
                          </Popconfirm>
                        </Tooltip>
                    </Row>
                    )})
            }
        </>
    );
};

export default VoucherGroupPool;
