import React from "react";

export const columns = [
  {
    title: "Email Component Name",
    dataIndex: "name",
    key: "name",
    render: (text) => <p>{text}</p>,
  },
  {
    title: "Component Type",
    dataIndex: "componentType",
    key: "componentType",
    render: (text) => <p>{text}</p>,
  },
  {
    title: "Status",
    dataIndex: "isActive",
    key: "isActive",
    render: (isActive) => <p>{isActive? "Active": "Inactive"}</p>,
  },
];
