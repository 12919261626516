import React from "react";
import {useRecoilState} from "recoil";
import Desk_1 from "./view/layout1/index";
import {getDeskNetworkTree} from "../../../api/graphql/deskNetworkTree";
import {selfPermissionObj} from "../../../../recoil_state";

function Desk() {
  let layout = 1;
  let component;
  const { data, loading, error, refetch } = getDeskNetworkTree();
  const [selfPermission] = useRecoilState<any>(selfPermissionObj);
  const deskPermission = selfPermission?.userSetting?.desk;

  switch (layout) {
    case 1:
      component = (
        <Desk_1
          networkData={data?.getDeskNetworkTree?.data}
          loading={loading}
          error={error}
          deskPermission={deskPermission}
          refetchNetworkTree={refetch}
        />
      );
      break;

    default:
      component = (
        <Desk_1
          networkData={data?.getDeskNetworkTree?.data}
          loading={loading}
          error={error}
          deskPermission={deskPermission}
          refetchNetworkTree={refetch}
        />
      );
      break;
  }

  return component;
}

export default Desk;
