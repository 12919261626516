import React, {useState} from "react"
import {useSetRecoilState} from "recoil"
import {useTranslation} from "react-i18next"
import {Button, Col, Tabs} from "antd"
import AccountReportTable from "./accountReportTable"
import {checkIsMasterAdmin} from "../../../../../../../api/graphql/user"
import {useReimportTradesMutation, useUpdateAndReimportTradesMutation} from "../../../../../../../api/graphql/populatingReporting"
import {notificationMessage} from "../../../../../../../../recoil_state"
import useAuthorize from "../../../../../../../_common/authorize"
import AccountTableDeal from "./accountTableDeal"
import AccountTableOrder from "./accountTableOrder"
import AccountTableClosedPosition from "./accountTableClosedPosition"

const AccountTabDetails = (props) => {
    const {traderId, accountId, detail, tradingAccountPermission, tradingAccountMT5Permission, refreshQuery} = props
    const {t} = useTranslation()
    const setNotificationMessage = useSetRecoilState(notificationMessage)
    const {systemErrorNotification} = useAuthorize()
    const {isMasterAdmin} = checkIsMasterAdmin(localStorage.getItem("g_userId") || localStorage.getItem("userId"))
    const {reimportTrades, loading: reimportTradesLoading} = useReimportTradesMutation()
    const {updateAndReimportTrades, loading: updateAndReimportTradesLoading} = useUpdateAndReimportTradesMutation()

    const [dealTotalCount, setDealTotalCount] = useState(0)
    const [orderTotalCount, setOrderTotalCount] = useState(0)
    const [closedPositionTotalCount, setClosedPositionTotalCount] = useState(0)

    const performWipeAndReimportTrades = async () => {
        try {
            const response = await reimportTrades(detail?._id, detail?.platform)

            if (response?.data?.reimportTrades.__typename === "ReportingData") {
                setNotificationMessage({
                    status: "success",
                    title: t("tradingAccount.reimport_trades_processing"),
                    msg: t("tradingAccount.kindly_wait_at_least_2_min_for_data_to_update"),
                    key: "reimportTrades",
                })
            } else if (response?.data?.reimportTrades.__typename === "BaseError") {
                console.log("error reimportTrades")
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.data?.reimportTrades?.errObj?.message),
                    key: "reimportTrades",
                })
            }
        } catch (e) {
            systemErrorNotification()
        } finally {
        }
    }

    const performUpdateAndReimportTrades = async () => {
        try {
            const response = await updateAndReimportTrades(detail?._id, detail?.platform)

            if (response?.data?.updateAndReimportTrades.__typename === "ReportingData") {
                setNotificationMessage({
                    status: "success",
                    title: t("tradingAccount.reimport_trades_processing"),
                    msg: t("tradingAccount.kindly_wait_at_least_2_min_for_data_to_update"),
                    key: "updateAndReimportTrades",
                })
            } else if (response?.data?.updateAndReimportTrades.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.data?.updateAndReimportTrades?.errObj?.message),
                    key: "updateAndReimportTrades",
                })
            }
        } catch (e) {
            systemErrorNotification()
        } finally {
        }
    }

    const tradingAccountTabItems = [
        {
            key: "1",
            label: (
                <>
                    {t("tradingAccount.closedPosition")} ({closedPositionTotalCount || 0})
                </>
            ),
            children: (
                <Col span={24} style={{marginTop: "15px"}}>
                    {detail?.mode === "live" ? (
                        <AccountReportTable
                            displayName={t("tradingAccount.closedPosition")}
                            traderId={traderId}
                            accountId={accountId}
                            accDetails={props?.detail}
                            selectedReportID={"64d0a107847e88a7d5978e39"}
                            tradingAccountPermission={tradingAccountPermission}
                            totalCount={closedPositionTotalCount}
                            setTotalCount={setClosedPositionTotalCount}
                            refreshQuery={refreshQuery}
                        />
                    ) : (
                        <AccountTableClosedPosition detail={detail} totalCount={closedPositionTotalCount} setTotalCount={setClosedPositionTotalCount} />
                    )}
                </Col>
            ),
            forceRender: true,
        },
        {
            key: "2",
            label: (
                <>
                    {t("tradingAccount.orders")} ({orderTotalCount || 0})
                </>
            ),
            children: (
                <Col span={24} style={{marginTop: "15px"}}>
                    {detail?.mode === "live" ? (
                        <AccountReportTable
                            displayName={t("tradingAccount.orders")}
                            traderId={traderId}
                            accountId={accountId}
                            accDetails={props?.detail}
                            selectedReportID={"64d0a107847e88a7d5978e3b"}
                            totalCount={orderTotalCount}
                            setTotalCount={setOrderTotalCount}
                        />
                    ) : (
                        <AccountTableOrder detail={detail} totalCount={orderTotalCount} setTotalCount={setOrderTotalCount} />
                    )}
                </Col>
            ),
            forceRender: true,
        },
        {
            key: "3",
            label: (
                <>
                    {t("tradingAccount.deals")} ({dealTotalCount || 0})
                </>
            ),
            children: (
                <Col span={24} style={{marginTop: "15px"}}>
                    {detail?.mode === "live" ? (
                        <AccountReportTable
                            displayName={t("tradingAccount.deals")}
                            traderId={traderId}
                            accountId={accountId}
                            accDetails={props?.detail}
                            selectedReportID={"64d0a107847e88a7d5978e3a"}
                            totalCount={dealTotalCount}
                            setTotalCount={setDealTotalCount}
                        />
                    ) : (
                        <AccountTableDeal detail={detail} totalCount={dealTotalCount} setTotalCount={setDealTotalCount} />
                    )}
                </Col>
            ),
            forceRender: true,
        },
    ]

    const isReimportTradesBtnShown = detail?.product?.mode === "live" && ((detail?.platform === "cTrader" && tradingAccountPermission?.reimportTrades) || (detail?.platform === "mt5" && tradingAccountMT5Permission?.reimportTrades))

    const tabBarExtraContent = (
        <>
            {/* <Tooltip title={t("Refresh Table")}>
                <Button
                    type={"link"}
                    // loading={reimportTradesLoading}
                    disabled={reimportTradesLoading}
                    // onClick={() => refreshTable()}
                    icon={<SyncOutlined />}
                />
            </Tooltip> */}
            {isMasterAdmin && isReimportTradesBtnShown && (
                <Button onClick={performWipeAndReimportTrades} className="button-submit-1 margin-right-0" loading={reimportTradesLoading} disabled={reimportTradesLoading} type={"primary"}>
                    Wipe and Reimport Trades
                </Button>
            )}
            {isReimportTradesBtnShown && (
                <Button onClick={performUpdateAndReimportTrades} className="button-submit-1 margin-right-0" loading={updateAndReimportTradesLoading} disabled={updateAndReimportTradesLoading} type={"primary"}>
                    {/* {t("tradingAcc.reimportTrades")} */}
                    Update and Reimport Trades
                </Button>
            )}
        </>
    )

    return (
        <div style={{marginTop: "15px"}}>
            <Tabs defaultActiveKey="1" items={tradingAccountTabItems} tabBarExtraContent={tabBarExtraContent} />
        </div>
    )
}

export default AccountTabDetails
