import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";


const GET_ASSET_CLASS_MT5 = gql`
    query getAssetClassMt5(
        $filter: AssetClassMt5Filter
        $orderBy: String
        $limit: Int
        $offset: Int
        $startDate: String
        $endDate: String
        $dateKey: String
    ) {
        getAssetClassMt5(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
            startDate: $startDate
            endDate: $endDate
            dateKey: $dateKey
        )  {
            ... on AssetClassMt5Array {
                data{
                    _id
                    name
                    symbols {
                        _id
                        symbol
                    }
                    server { name }
                }
                total
            }
            ... on BaseError {
                errKey
                errObj
            }
        }
    }
`;

const getAssetClassMt5 = async (filter, orderBy?, limit?, offset?, startDate?, endDate?, dateKey?) => {
    try {
        const execute = await ApolloClientQuery(GET_ASSET_CLASS_MT5, {
                filter: filter,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
                orderBy: orderBy || null,
                limit: limit || null,
                offset: offset || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const REFRESH_ASSET_CLASS_MT5 = gql`
    query refreshAssetClassMt5($serverId: String){
        refreshAssetClassMt5(serverId:$serverId) {
            ... on AssetClassMt5Array {
                data{
                    _id
                }
                total
            }
            ... on BaseError {
                errKey
                errObj
            }
        }
    }
`;

const refreshAssetClassMt5 = async (serverId) => {
    try {
        const execute = await ApolloClientQuery(
            REFRESH_ASSET_CLASS_MT5,
            {
                serverId: serverId,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

export {
    getAssetClassMt5,
    refreshAssetClassMt5,
}