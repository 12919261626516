import axios from "axios";
import {getConfigByName} from "../../../../config";
import {BRAND_NAME} from "../../../../config/brandVariable";

const url = getConfigByName(BRAND_NAME).automationURL
const config = {headers: {"Content-Type": "text/plain"}}

export async function getRule() {
    try {
        const filter = {filter: {}, populate:["customTrigger"]};
        return await axios.post(`${url}/api/rule/get`, JSON.stringify(filter), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export async function createRule(input) {
    try {
        return await axios.post(`${url}/api/rule/create`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export async function updateRule(input) {
    try {
        return await axios.post(`${url}/api/rule/update`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export async function deleteRule(input) {
    try {
        return await axios.post(`${url}/api/rule/delete`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}