import React, {createRef, useEffect, useState} from "react";
import {Button, Form, Row, Select, Tag} from "antd";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import ScheduleConditionGroup from "./scheduleConditionGroup";
import {useRecoilState, useRecoilValue} from "recoil";
import {logicOperatorAryState} from "../../../../state";

const ScheduleConditionForm = ({
                                   fieldOptions,
                                   reportFields,
                                   onReportTargetedFieldSelect,
                                   availableReports,
                                   allReports,
                                   conditions,
                                   setConditions,
                                   setLogicalOperator,
                                   reportTargetedField
                               }) => {
    const [reportsDropdown, setReportsDropdown] = useState<any>([])

    const addConditionGroup = () => {
        try {
            const largestBatchId = conditions.reduce((maxBatchId, obj) => {
                return obj.batchId > maxBatchId ? obj.batchId : maxBatchId;
            }, -1);

            const newConditionGroup = [...conditions, {
                batchId: largestBatchId + 1 || 1,
                form: createRef(),
                condition: [{comparisonOperator: null, field: null, value: null}],
            }]

            setConditions(newConditionGroup);
        } catch (e) {
            console.log(e)
        }
    }

    const onConditionGroupDragEnd = (result) => {

    }

    const removeConditionGroup = (indexToFilter) => {
        try {
            const filteredArray = conditions.map(element => {
                if (element?.batchId !== indexToFilter) return element

                if (!element.id) return null

                return {
                    ...element,
                    isDeleted: true
                }
            }).filter(d => d);

            setConditions(filteredArray);
        } catch (e) {
            console.log(e)
        }
    };

    const onFinish = (form, batch) => {
        try {
            const formFound = form.current;
            if (formFound) {
                const values = formFound.getFieldsValue();

                const updatedConditionGroup = conditions.map((item) => {
                    if (item.batchId === batch) {
                        return {...item, condition: values?.["condition" + batch]};
                    }
                    return item;
                });

                setConditions(updatedConditionGroup)
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        const ids = availableReports?.map(d => d?.reportId)
        const foundReports = allReports?.filter(report => ids?.includes(report._id));
        setReportsDropdown([...foundReports]);
    }, [availableReports]);

    useEffect(() => {
        if (conditions?.length === 0) addConditionGroup();
    }, [conditions?.length]);

    return (
        <>
            <p><small>* If any conditions are set on Reports for the scheduled event, an action will be executed for each report data row that meets the
                specified conditions.</small></p>
            <p>Report Field</p>
            <Select
                style={{width: "100%"}}
                value={reportTargetedField}
                options={fieldOptions?.slice()?.sort((a, b) => a?.label?.localeCompare(b?.label))}
                onChange={onReportTargetedFieldSelect}
                allowClear showSearch
                optionFilterProp={"label"}
            />
            {
                availableReports?.length > 0 && availableReports?.map(report => (
                    <Tag key={report?.reportId}>{report?.displayName}</Tag>
                ))
            }
            <Row className={"margin-top-0-75"} justify={"end"}>
                <Button onClick={addConditionGroup}>
                    Add Condition Group
                </Button>
            </Row>
            {/*<DragDropContext onDragEnd={(result) => onConditionGroupDragEnd(result)}>*/}
            {/*    <Droppable droppableId={"condition-groups"}>*/}
            {/*        {(droppableProvided) => (*/}
            {/*            <div ref={droppableProvided?.innerRef}*/}
            {/*                 {...droppableProvided.draggableProps}*/}
            {/*                 {...droppableProvided.dragHandleProps}*/}
            {/*            >*/}
                            {conditions.filter(d => !d?.isDeleted).map((detail, index) => (
                                // <Draggable key={detail.batchId}
                                //            draggableId={detail.batchId.toString()}
                                //            index={index}
                                // >
                                //     {(provided, snapshot) => {
                                //         return (
                                //             <div
                                //                 ref={provided?.innerRef}
                                //                 {...provided?.draggableProps}
                                //                 style={{
                                //                     ...provided?.draggableProps.style,
                                //                 }}
                                //             >
                                                <ScheduleConditionGroup
                                                    conditions={conditions}
                                                    detail={detail}
                                                    index={index}
                                                    setLogicalOperator={setLogicalOperator}
                                                    setConditions={setConditions}
                                                    // dragHandle={provided?.dragHandleProps}
                                                    removeConditionGroup={removeConditionGroup}
                                                    onFinish={onFinish}
                                                    reportsDropdown={reportsDropdown}
                                                    reportFields={reportFields}
                                                />
                                //             </div>
                                //         )
                                //     }}
                                // </Draggable>
                            ))}
            {/*                {droppableProvided?.placeholder}*/}
            {/*            </div>*/}
            {/*        )}*/}
            {/*    </Droppable>*/}
            {/*</DragDropContext>*/}
        </>
    )
}

export default ScheduleConditionForm