import React from "react";
import {useRecoilState} from "recoil";
import Language_1 from "./view/layout1";
import {languageColumns} from "./function/tableColumn";
import {getLanguage} from "../../../api/graphql/language";
import {selfPermissionObj} from "../../../../recoil_state";

function LanguagePage() {
  let layout = 1;
  let component;
  const [selfPermission] = useRecoilState<any>(selfPermissionObj);
  const languagePermission = selfPermission?.brandSetting?.language;

  const { loading, languageData, error } = getLanguage({ filter: null });

  switch (layout) {
    case 1:
      component = (
        <Language_1
          data={languageData?.getLanguage?.data}
          columns={languageColumns}
          loading={loading}
          error={error}
          languagePermission={languagePermission}
        />
      );
      break;

    default:
      component = (
        <Language_1
          data={languageData?.getLanguage?.data}
          columns={languageColumns}
          loading={loading}
          error={error}
          languagePermission={languagePermission}
        />
      );
      break;
  }

  return component;
}

export default LanguagePage;
