import React, {useState} from "react";
import {Draggable, Droppable} from "react-beautiful-dnd";
import {useTranslation} from "react-i18next";
import {Button, Card, List, Popconfirm, Row, Space, Spin, Tooltip} from "antd";
import VirtualList from "rc-virtual-list";
import {DeleteOutlined, EditOutlined, UserOutlined} from "@ant-design/icons";
import useAuthorize from "../../../../../../_common/authorize";
import {useBatchReviewSalesOpportunityMutation} from "../../../../../../api/graphql/salesOpportunity";
import moment from "moment";
import {MdOutlineArchive} from "react-icons/md";

const BoardViewCard = (props) => {
    const {t} = useTranslation()
    const {data, loading, permissionUser, fieldForLogin, setIsOpen, setFormData, setStatusChanged, setOffsetLimitStatus, setCurrentLoad} = props
    const {systemErrorNotification, baseErrorChecking} = useAuthorize()
    const {batchReviewSalesOpportunity} = useBatchReviewSalesOpportunityMutation()

    const [reviewLoading, setReviewLoading] = useState<any>(false)
    const containerHeight = window.innerHeight - 250;

    const reviewSalesOpportunity = async () => {
        try {
            setReviewLoading(true)
            const ids = data?.data?.map(d => d?._id)
            const response = await batchReviewSalesOpportunity(ids, true)
            if (response?.data?.batchReviewSalesOpportunity?.__typename === "SalesOpportunityAry") {
                setStatusChanged(true)
                setReviewLoading(false)
            } else {
                await baseErrorChecking(response?.batchReviewSalesOpportunity)
            }
        } catch (e) {
            systemErrorNotification()
        } finally {
            setReviewLoading(false)
        }
    }

    const openUserDetails = (id, userType) => {
        const url = `/users/${userType}/detail?id=` + id
        window.open(url);
    }

    const editOnClick = (obj) => {
        setFormData(obj)
        setIsOpen(true)
    }

    const loadMoreHandler = () => {
        setCurrentLoad(data?.status?.name)
        setOffsetLimitStatus(prevState => ({
            ...prevState,
            [data?.status?.name]: {
                ...prevState[data?.status?.name],
                offset: prevState[data?.status?.name]?.limit + prevState[data?.status?.name]?.offset
            }
        }))
    }

    return (
        <div key={data?.status?._id} className={"sales-opportunity-card"}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    userSelect: "none",
                    width: 300
                }}
                className={"card-container"}
                key={data?.status?._id}
            >
                <div className={"progress-container"}>
                    <div style={{margin: "auto"}}>
                        <Space align={"center"} className={data?.status.type === "end" ? "margin-bottom-0" :"margin-bottom-0-3"}>
                            <h3 className={"margin-bottom-0"}>{t(data?.status?.displayName)}</h3>
                            {data?.status.type === "end" &&
                                <Tooltip title={t("salesOpportunity.review")}>
                                    <Popconfirm
                                        placement="left"
                                        title={t("salesOpportunity.popconfirm.title.review")}
                                        description={t("salesOpportunity.popconfirm.description.review")}
                                        onConfirm={() => {
                                            reviewSalesOpportunity()
                                        }}
                                        okText={t("common.yes")}
                                        cancelText={t("common.no")}
                                        okButtonProps={{loading: reviewLoading}}
                                        overlayStyle={{width: 300}}
                                    >
                                        <Button size={"small"} type={"link"} danger icon={<MdOutlineArchive style={{fontSize: 18}}/>}/>
                                    </Popconfirm>
                                </Tooltip>
                            }
                        </Space>
                        <p className={"margin-bottom-0"}>
                            {t("common.amount")}: {data?.status?.totalSalesOppAmount || 0}
                            &nbsp; | &nbsp;
                            {t("common.total")}: {data?.total ? `${data?.data?.length}/${data?.total}` : 0}
                        </p>
                    </div>
                </div>

                <Spin spinning={loading}>
                    <div className={"board-container"} style={{height: containerHeight}}>
                        <Droppable droppableId={data?.status?._id} key={data?.status?._id}>
                            {(provided, snapshot) => {
                                return (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={{
                                            background: snapshot.isDraggingOver ? "#f2f2f2" : "transparent",
                                            padding: 16,
                                            width: 300,
                                            height: "100%"
                                        }}
                                    >
                                        <List>
                                            <VirtualList
                                                data={data?.data}
                                                height={containerHeight}
                                                itemKey="_id"
                                            >
                                                {(cardData, i) => {
                                                    return (
                                                        <Draggable
                                                            key={cardData?._id}
                                                            draggableId={cardData?._id}
                                                            index={i}
                                                            isDragDisabled={!permissionUser?.salesOpportunity?.edit?.edit}
                                                        >
                                                            {(provided, snapshot) => {
                                                                return (
                                                                    <div
                                                                        className={"sales-opportunity-card"}
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={provided.draggableProps.style}
                                                                    >
                                                                        <Card
                                                                            key={cardData?.salesOpportunityName}
                                                                            size="small"
                                                                            title={cardData?.salesOpportunityName}
                                                                            extra={
                                                                                <>
                                                                                    {
                                                                                        permissionUser?.leads?.view?.view && cardData?.lead?._id ? (
                                                                                            <a
                                                                                                onClick={() =>
                                                                                                    openUserDetails(cardData?.lead?._id, cardData?.userType)
                                                                                                }
                                                                                            >
                                                                                                {cardData?.lead?.fullName || cardData?.lead?.username || cardData?.lead?.[fieldForLogin?.[0]] || cardData?.lead?.email}
                                                                                            </a>
                                                                                        ) : permissionUser?.clientsUser?.view?.view && cardData?.client?._id ? (
                                                                                            <a
                                                                                                onClick={() =>
                                                                                                    openUserDetails(cardData?.client?._id, cardData?.userType)
                                                                                                }
                                                                                            >
                                                                                                {fieldForLogin?.includes("username") ? cardData?.client?.username : cardData?.client?.[fieldForLogin?.[0]]}
                                                                                            </a>
                                                                                        ) : <p>{cardData?.lead?.name || cardData?.client?.fullName}</p>
                                                                                    }
                                                                                </>
                                                                            }
                                                                            actions={[
                                                                                permissionUser?.leads?.view?.view && (
                                                                                    <UserOutlined
                                                                                        key="user"
                                                                                        onClick={() => openUserDetails(cardData?.lead?._id || cardData?.client?._id, cardData?.userType)}
                                                                                    />
                                                                                ),
                                                                                permissionUser?.salesOpportunity?.edit?.edit && (
                                                                                    <EditOutlined
                                                                                        key="edit"
                                                                                        onClick={() => editOnClick({...cardData})}
                                                                                    />
                                                                                ),
                                                                            ]}
                                                                        >
                                                                            <Space direction="vertical">
                                                                                {permissionUser?.followUp?.read?.read &&
                                                                                    <>
                                                                                        <div>
                                                                                            <h5 className="margin-bottom-0">{t("user.lastFollowUp")}</h5>
                                                                                            <p>{moment(cardData?.lead?.latestFollowUp?.lastFollowUp || cardData?.client?.latestFollowUp?.lastFollowUp)?.format("YYYY-MM-DD HH:mm:ss")}</p>
                                                                                        </div>
                                                                                        <div>
                                                                                            <h5 className="margin-bottom-0">{t("user.nextFollowUp")}</h5>
                                                                                            <p>{moment(cardData?.lead?.latestFollowUp?.followUpDateTime || cardData?.client?.latestFollowUp?.followUpDateTime)?.format("YYYY-MM-DD HH:mm:ss")}</p>
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                                <div>
                                                                                    <h5 className="margin-bottom-0">{t("common.amount")}</h5>
                                                                                    <p>{cardData?.amount}</p>
                                                                                </div>
                                                                                <div>
                                                                                    <h5 className="margin-bottom-0">{t("salesOpportunity.remark")}</h5>
                                                                                    <p>{cardData?.remark && cardData?.remark?.length > 50 ? cardData?.remark?.slice(0, 47)?.padEnd(50, "...") : cardData?.remark}</p>
                                                                                </div>
                                                                            </Space>
                                                                        </Card>
                                                                    </div>
                                                                );
                                                            }}
                                                        </Draggable>
                                                    )
                                                }}
                                            </VirtualList>
                                        </List>
                                        {provided.placeholder}
                                    </div>
                                )
                            }}
                        </Droppable>
                    </div>
                </Spin>
            </div>

            {
                data?.data?.length < data?.total &&
                <Row justify={"center"}>
                    <Button onClick={() => loadMoreHandler()} className={"margin-top-0-3"}>Load More</Button>
                </Row>
            }
        </div>
    )
}

export default BoardViewCard