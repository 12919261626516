import React, { useState } from "react";
import { Button, Col, Modal, Row, Upload } from "antd";
import { useTranslation } from "react-i18next";
import { UploadOutlined } from "@ant-design/icons";
import { useImportRoleTemplateData } from "../../../../../../api/graphql/roleTemplate";
import { useImportRoleNetworkTreeData } from "../../../../../../api/graphql/roleNetworkTree";

const ImportDBTableButton = (props) => {
    const {tableName} = props
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const [ confirmModal, setConfirmModal ] = useState(false)
    const [ importData, setImportData ] = useState<any>("")
    const { importRoleTemplateData } = useImportRoleTemplateData()
    const { importRoleNetworkTreeData } = useImportRoleNetworkTreeData()

    const handleImport = () => {
        setLoading(true)
        switch(tableName){
            case "roletemplates":
                importRoleTemplateData(importData)
                closeModal()
                break
            case "rolenetworktree":
                importRoleNetworkTreeData(importData)
                closeModal()
                break
            default:
                break
        }
        setLoading(false)
    }

    const openModal = () => {
        setConfirmModal(true)
    }

    const closeModal = () => {
        setConfirmModal(false)
        setImportData("")
    }

    const getTitle = () => {
        switch(tableName){
            case "roletemplates":
                return t("Import Role Settings")
            case "rolenetworktree":
                return t("Import Role Network Tree")
        }
    }

    return (
        <>
            <Button
                onClick={() => openModal()}
                loading={loading}
            >
                {t("common.importRoleSetting")}
            </Button>

            <Modal
                title={getTitle()}
                open={confirmModal}
                onCancel={() => closeModal()}
                footer={null}
                width={500}
                destroyOnClose
            >
                <p>
                    {t(`roleSetting.importForm.${tableName}`)}
                </p>
                <Row style={{marginTop: "12px"}}>
                    <Col xs={12}>
                        <Upload 
                            accept=".json"
                            beforeUpload={file => {
                                const reader = new FileReader();
                                reader.onload = e => {
                                    setImportData(e?.target?.result)
                                };
                                reader.readAsText(file);
                                ;

                                return false;
                            }}
                            maxCount={1}
                        >
                            <Button icon={<UploadOutlined />}>{t("Select File")}</Button>
                        </Upload>
                    </Col>
                    <Col xs={12} style={{textAlign: "right"}}>
                        <Button
                            onClick={() => handleImport()}
                            loading={loading}
                            disabled={!importData || importData === "" || loading}
                        >
                            {tableName === "roletemplates" ? t("common.importRoleSetting"): 
                                tableName === "rolenetworktree" ? t("common.importRoleNetworkTree"): ""
                            }
                        </Button>
                    </Col>
                </Row>
            </Modal>
        </>
    );
}

export default ImportDBTableButton