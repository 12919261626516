import React, {useEffect, useState} from 'react';
import {Modal, Tabs} from 'antd';

import DynamicEmailComponentForm from './generalForm'
import RichTextEditor from './richTextEditor';
import HtmlEditor from './htmlEditor';
import {getTextFromObject, handleObjectChange} from '../../../function/common';
import TemplatePreview from './templatePreview';
import {useTranslation} from 'react-i18next';

const TemplateModal = (props) => {
  const { 
    mode,
    item,
    isOpen,
    closeModal,
    languageData
 } = props
 const { t } = useTranslation()
 
 const [ editorValue, setEditorValue] = useState(item?.htmlObject || []);
 const [ plainHtml, setPlainHtml ] = useState(item?.htmlObject || [])

 useEffect(()=>{
  setEditorValue(item?.htmlObject || [])
  setPlainHtml(item?.htmlObject || [])
 }, [item])

 const onEditorValueChange = (value, language) => {
  handleObjectChange(setEditorValue, value, "html", language)
  handleObjectChange(setPlainHtml, value, "html", language)
 }

 const getTitle = ():String => {
   switch(mode){
     case "add":
       return `${t('Add New Email Component')}`
     case "view":
       return `${t('View Email Component')}`
     case "edit":
       return `${t('Edit Email Component')}`
     default: 
      return `${t('Add New Email Component')}`
   }
 }

const getItems = (language) => {
  return [
    {
      key: '1',
      label: `Rich Text Editor`,
      children: <RichTextEditor
        editorState={getTextFromObject(editorValue, "html", language)}
        onEditorStateChange={e=>onEditorValueChange(e,language)}
      />,
    },
    {
      key: '2',
      label: `Html Editor`,
      children: <HtmlEditor
        plainHtml={getTextFromObject(plainHtml, "html", language)}
        onEditorStateChange={e=>onEditorValueChange(e,language)}
        onHtmlStateChange={e=>handleObjectChange(setPlainHtml, e,"html", language)}
      />,
    },
    {
      key: '3',
      label: `Preview`,
      children: <TemplatePreview
        plainHtml={getTextFromObject(plainHtml, "html", language)}
        language={language}
      />,
    },
  ]
}
  
const languageitems = languageData?.map((item, index) => {
  return {
    key: index,
    label: item.name,
    children: 
      <Tabs
      defaultActiveKey="1"
      items={getItems(item.code)}
    />
  }
}) || []

  return (
    <Modal
        className="template-editor-1"
        title={getTitle()}
        open={isOpen}
        width={"80%"}
        footer={null}
        onCancel={closeModal}
        maskClosable={false}
        destroyOnClose
    >
      <DynamicEmailComponentForm
        htmlState={editorValue} 
        mode={mode} 
        item={item} 
        closeModal={closeModal}
      >
        <Tabs
          defaultActiveKey="1"
          items={languageitems}
        />
      </DynamicEmailComponentForm>
    </Modal>
  );
};

export default TemplateModal;