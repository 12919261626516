import React, {useEffect, useMemo, useState} from "react";
import {Space} from "antd";
import TableAntDesign from "../../../../../../../../components/table/tableAntDesign";
import {ColumnPositionMt5} from "../../../../function/tableColumn";
import EditTableUserPreferenceButton
    from "../../../../../../../../components/table/function/editTableUserPreferenceButton";
import ExportTableButton from "../../../../../../../../components/table/function/exportTable";
import useAuthorize from "../../../../../../../../_common/authorize";
import {getConfigByName} from "../../../../../../../../../config";
import {BRAND_NAME} from "../../../../../../../../../config/brandVariable";
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {tradingAccountMt5OpenPositionProfitState} from "../../../../state";

const AccountMt5Position = (props) => {
    const {accountId, detail} = props
    const {t} = useTranslation()
    const [socket, setSocket] = useState<any>(null);
    const [data, setData] = useState<any>([]);
    const setTotalProfit = useSetRecoilState(tradingAccountMt5OpenPositionProfitState);

    const {systemErrorNotification} = useAuthorize()
    const columns = useMemo(() => ColumnPositionMt5(t), [])

    const brandConfig = getConfigByName(BRAND_NAME)

    useEffect(() => {
        try {
            const newSocket = new WebSocket(brandConfig?.mt5TickSocket + '/ws/position/getByLogin?login=' + accountId);
            // const newSocket = new WebSocket(brandConfig?.mt5WebSocket + '/ws/position/getByLogin?login=50022');

            newSocket.addEventListener('message', (event) => {
                const rawData = event.data;
                const positionData = JSON.parse(rawData);

                let positionLatest: any = [];

                if (positionData?.total) {
                    setTotalProfit(positionData?.total?.toFixed(2))
                }

                if (Array.isArray(positionData?.positions)) {
                    for (const position of positionData?.positions) {
                        positionLatest.push({
                            ...position,
                            volume: (position?.volume / 10000) * (position?.contractSize),
                            volume_lot: (position?.volume / 10000)
                        })
                    }
                }

                positionLatest.sort((a, b) => {
                    // First, sort by symbol
                    const symbolComparison = a.symbol.localeCompare(b.symbol);
                    if (symbolComparison !== 0) {
                        // If symbols are different, return the comparison result
                        return symbolComparison;
                    } else {
                        // If symbols are the same, sort by position
                        return a.position - b.position;
                    }
                });

                setData(positionLatest);

            });

            setSocket(newSocket);

            return () => {
                newSocket.close();
            };

        } catch (e) {
            console.log(e)
        }
    }, [accountId]);

    const userPreferenceButton = useMemo(() => (
        <EditTableUserPreferenceButton
            tableName={"Open Positions MT5"}
            tableColumn={columns}
            displayTableName={t("tradingAccount.openPosition")}
        />
    ), [columns])

    return (
        <div id={"AccountPosition"} style={{marginTop: "15px"}}>
            <h4>{t("tradingAccount.openPosition")} ({data?.length})</h4>
            <div className={"display-flex margin-bottom-0-75"}>
                <Space className={"margin-left-auto"}>

                    {userPreferenceButton}

                    <ExportTableButton
                        tableData={data}
                        tableColumn={columns}
                        tableName={"Open Positions MT5"}
                    />

                </Space>
            </div>

            <TableAntDesign data={data}
                            tableName={"Open Positions MT5"}
                            columns={columns}
                            size={"small"}
                            loading={!accountId}
                            isTableLocalSearch={true}
                            pagination={{
                                pageSize: 10,
                                showSizeChanger: true,
                                position: "bottomCenter",
                                total: data?.length,
                                showTotal: (total) => <p>Total {total} items</p>,
                            }}/>

        </div>
    );
};

export default AccountMt5Position;
