import React, {useEffect, useState} from "react";
import {useRecoilValue} from "recoil";
import {selfPermissionObj} from "../../../../recoil_state";
import MamPammLegalDocument_1 from "./view/layout1/index";
import {getMamPammLegalDocument} from "../../../api/graphql/mamPammLegalDocument";
import {languageOptionsState} from "../../users/_common/state";

function MamPammLegalDocument() {
    let layout = 1;
    let component;
    const permission = useRecoilValue<any>(selfPermissionObj);
    const languageData = useRecoilValue<any>(languageOptionsState);
    const [data, setData] = useState([]);

    const getData = async () => {
        try {
            const result = await getMamPammLegalDocument({filter: {isDefault: [true]}}, null, null, "createdAt_ASC");
            setData(result?.getMamPammLegalDocument?.data);
        } catch (error) {}
    };

    useEffect(() => {
        getData();
    }, []);

    switch (layout) {
        case 1:
            component = (
                <MamPammLegalDocument_1 permission={permission?.mampamm?.legalDocument} language={languageData} data={data} getData={getData} />
            );
            break;

        default:
            component = (
                <MamPammLegalDocument_1 permission={permission?.mampamm?.legalDocument} language={languageData} data={data} getData={getData} />
            );
            break;
    }

    return component;
}

export default MamPammLegalDocument;
