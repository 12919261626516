import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";

const GET_BRAND_SETTING_LEGAL_DOCUMENTS = gql`
    query getBrandSettingLegalDocuments {
        getBrandSettingLegalDocuments {
            ... on LegalDocumentObjectAry {
                data {
                    _id
                    name
                    isUsedForRegistration
                    isShowInLoginPage
                    isUsedForWebsite
                    displayName {
                        language
                        content
                    }
                    registerType {
                        language
                        type
                    }
                    loginType {
                        language
                        type
                    }
                    fullLink {
                        language
                        link
                    }
                    linkWithDomain {
                        language
                        link
                    }
                    tinyMce {
                        language
                        content
                    }
                    websiteHTML {
                        language
                        content
                    }
                    pdf {
                        link
                        fileList
                        language
                    }
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

const getBrandSettingLegalDocuments = async () => {
    try {
        const execute = await ApolloClientQuery(GET_BRAND_SETTING_LEGAL_DOCUMENTS, {}, false);

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_BRAND_SETTING_LEGAL_DOCUMENTS = gql`
    mutation addBrandSettingLegalDocuments($input: LegalDocumentObjectInput) {
        addBrandSettingLegalDocuments(input: $input) {
            ... on LegalDocumentObject {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }

`;

const addBrandSettingLegalDocuments = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            ADD_BRAND_SETTING_LEGAL_DOCUMENTS,
            {
                input: input,
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UPDATE_BRAND_SETTING_LEGAL_DOCUMENTS = gql`
    mutation updateBrandSettingLegalDocuments($id: String!, $input: LegalDocumentObjectInput) {
        updateBrandSettingLegalDocuments(id: $id, input: $input) {
            ... on LegalDocumentObject {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }

`;

const updateBrandSettingLegalDocuments = async (id, input) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_BRAND_SETTING_LEGAL_DOCUMENTS,
            {
                id: id,
                input: input,
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const DELETE_BRAND_SETTING_LEGAL_DOCUMENTS = gql`
    mutation deleteBrandSettingLegalDocuments($id: String!) {
        deleteBrandSettingLegalDocuments(id: $id) {
            ... on LegalDocumentObject {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }

`;

const deleteBrandSettingLegalDocuments = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            DELETE_BRAND_SETTING_LEGAL_DOCUMENTS,
            {
                id: id,
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

export {
    getBrandSettingLegalDocuments,
    addBrandSettingLegalDocuments,
    updateBrandSettingLegalDocuments,
    deleteBrandSettingLegalDocuments
}