import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Input, Modal, Radio, Row, Select, Switch, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import TextArea from "antd/es/input/TextArea";
import {createCustomTrigger, updateCustomTrigger} from "../../../../../../../api/restful/automationSetting/customTrigger";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import {DeleteOutlined, InfoCircleOutlined, InfoOutlined} from "@ant-design/icons";
import {createCustomActionTemplate, updateCustomActionTemplate} from "../../../../../../../api/restful/automationSetting/customActionTemplate";
import BodyFormList from "./bodyFormList";

const CreateUpdateModal = ({isOpen, handleCancel, item, refetch, customTriggerData}) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const isUpdate = !!item

    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [loading, setLoading] = useState(false);
    const [referenceFromOptions, setReferenceFromOptions] = useState<any>([]);
    const [isReportOnly, setIsReportOnly] = useState(null);

    useEffect(() => {
        if (!isOpen) {
            onReportOnlyChangeHandler(null)
            form.resetFields()
            form.setFieldsValue({body: [{fieldName: null, dataType: null, referenceFrom: null, value: null}]});
            return
        }

        if (isUpdate && isOpen) {
            onReportOnlyChangeHandler(item?.reportOnly)
            form.setFieldsValue({
                ...item,
                body: item?.body ? item?.body : [{fieldName: null, dataType: null, referenceFrom: null, value: null}]
            })
        }
    }, [isUpdate, isOpen]);

    const onSubmit = async (value) => {
        try {
            setLoading(true)

            if (!isUpdate) {
                const response = await createCustomActionTemplate(value)

                if (response?.status === "Success") {
                    refetch()
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: `Create Action Successfully`,
                        key: "customActionInformation",
                    })
                }

                handleCancel()
            } else {
                const response = await updateCustomActionTemplate({_id: item._id, ...value})

                if (response?.status === "Success") {
                    refetch()
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: `Update Action - ${response?.data?.name} Successfully`,
                        key: "customActionInformation",
                    })
                }

                handleCancel()
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const onReportOnlyChangeHandler = (value) => {
        if (value === null) {
            setIsReportOnly(null)
            return
        }

        setIsReportOnly(value)
        const referenceFromValue = value ? "custom" : null

        form.setFieldsValue({
            body: [{fieldName: null, dataType: null, referenceFrom: referenceFromValue, value: null}],
            triggers: []
        })

        if (value) {
            setReferenceFromOptions([
                {label: "Custom", value: "custom"},
            ])
        } else {
            setReferenceFromOptions([
                {label: "Custom", value: "custom"},
                {label: "Trigger Payload", value: "payload"},
            ])
        }
    }

    const requiredRule = [{required: true}]

    return (
        <Modal
            open={isOpen}
            onCancel={handleCancel}
            title={isUpdate ? "Update Custom Action" : "Create Custom Action"}
            onOk={() => form.submit()}
            okText={t("common.submit")}
            okButtonProps={{loading: loading}}
            cancelText={t("common.cancel")}
            className={"custom-action-create-update-modal"}
            width={"70%"}
        >
            <Form form={form} onFinish={onSubmit} labelCol={{span: 24}} className={"margin-bottom-0-75"} labelAlign={"left"} colon={false}>
                <Form.Item name={"isEnabled"} label={t("Enabled")} valuePropName={"checked"}>
                    <Switch/>
                </Form.Item>
                <Form.Item rules={requiredRule} name={"reportOnly"} label={"Automation Type"}>
                    <Radio.Group onChange={e => onReportOnlyChangeHandler(e.target.value)}>
                        <Radio value={true}>Schedule Events</Radio>
                        <Radio value={false}>Trigger Events</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item rules={requiredRule} name={"name"} label={t("Name")}>
                    <Input/>
                </Form.Item>
                {
                    isReportOnly !== null &&
                    <>
                        {
                            !isReportOnly &&
                            <Form.Item rules={requiredRule} name={"triggers"} label={"Bind action to triggers"}>
                                <Select
                                    showSearch={true}
                                    optionFilterProp={"label"}
                                    mode={"multiple"}
                                    maxTagCount={5}
                                    maxTagTextLength={20}
                                    allowClear
                                    options={customTriggerData?.map(d => ({label: d?.trigger, value: d?._id}))}
                                />
                            </Form.Item>
                        }
                        <Form.Item rules={requiredRule} name={"method"} label={t("Method")}>
                            <Select
                                options={[
                                    {label: "POST", value: "post" },
                                    {label: "GET", value: "get" },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item rules={requiredRule} name={"url"} label={t("URL")}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label={t("Body")}>
                            <Card size={"small"}>
                                <Form.List name={"body"}>
                                    {(fields, {add, remove}, {errors}) => (
                                        <>
                                            {fields.map((field, index) => (
                                                <Form.Item {...field} className={"form-list-item-container"}>
                                                    <BodyFormList field={field} referenceFromOptions={referenceFromOptions} remove={remove}
                                                                  isReportOnly={isReportOnly}/>
                                                </Form.Item>
                                            ))}

                                            <Button type="dashed" onClick={() => add()} block>
                                                + Add Item
                                            </Button>
                                        </>
                                    )}
                                </Form.List>
                            </Card>
                        </Form.Item>
                    </>
                }
            </Form>
            <br/>
        </Modal>
    )
}

export default CreateUpdateModal