import {
    getBooleanFilterProps,
    getColumnSearchProps,
    getDateRangeFilterProps
} from "../../../../../components/table/function/filterTable";
import React, {useState} from "react";
import {Tag, Tooltip} from "antd";
import {BsCheck, BsX} from "react-icons/bs";
import moment from "moment";
import {formatMoney} from "./index";

const SeeMoreComponent = ({text}) => {
    const [view, setView] = useState(false);

    let tagsToShow = text?.slice(0, view ? text.length : 3);

    const renderTag = (tag, i) => (
        <Tag
            key={i}
            style={{margin: 2}}
            color={i === 0 ? "blue" : "default"}
        >
            {tag?.name}
        </Tag>
    );

    const renderSeeMoreTag = () => (
        <Tag
            style={{margin: 2, cursor: "pointer"}}
            color={"green"}
            onClick={() => setView(true)}
        >
            ...
        </Tag>
    );

    return (
        <div>
            {tagsToShow?.map(renderTag)}
            {!view && text?.length > 3 && renderSeeMoreTag()}
        </div>
    );
};

export const columnsCommissionLogBatch = [
    {
        title: "Record No",
        dataIndex: "transactionName",
        key: "transactionName",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Batch Name",
        dataIndex: "batchName",
        key: "batchName",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Rule Name",
        dataIndex: "ruleName",
        key: "ruleName",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Total Report",
        dataIndex: "totalPayout",
        key: "totalPayout",
        ...getColumnSearchProps(),
        render: (text) => <p>{text ? text : 0}</p>,
    },
    {
        title: "Start Date",
        dataIndex: "calculatedStartDate",
        key: "calculatedStartDate",
        ...getDateRangeFilterProps(),
        render: (text) => <p>{text ? moment(text)?.format("YYYY-MM-DD") : "-"}</p>
    },
    {
        title: "End Date",
        dataIndex: "calculatedEndDate",
        key: "calculatedEndDate",
        ...getDateRangeFilterProps(),
        render: (text) => <p>{text ? moment(text)?.format("YYYY-MM-DD") : "-"}</p>
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        ...getColumnSearchProps(),
        render: (text, record) => {

            if (text === "pending final calculation") {
                return <p>{text + " on " + moment(record?.calculatedEndDate)?.format("YYYY-MM-DD")}</p>

            } else {
                return <p>{text}</p>
            }
        },
    },
    {
        title: "Calculation Processed",
        dataIndex: "isProcessing",
        key: "isProcessing",
        ...getBooleanFilterProps(),
        render: (text) => (text) ? <BsCheck style={{color: 'green'}}/> : <BsX style={{color: 'red'}}/>,
    },
    {
        title: "Network Tree Date",
        dataIndex: "networkTreeHistoryDate",
        key: "networkTreeHistoryDate",
        ...getDateRangeFilterProps(),
        render: (text) => <p>{text ? moment(text)?.format("YYYY-MM-DD") : "-"}</p>
    },
    {
        title: "Last Calculation",
        dataIndex: "lastCalculation",
        key: "lastCalculation",
        ...getDateRangeFilterProps(),
        render: (text) => <p>{text ? moment(text)?.format("YYYY-MM-DD HH:mm:ss") : "-"}</p>
    },
    {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        ...getDateRangeFilterProps(),
        render: (text) => <p>{text ? moment(text)?.format("YYYY-MM-DD HH:mm:ss") : "-"}</p>
    },
    {
        title: "Requested Payment By",
        dataIndex: "requestedPaymentBy",
        key: "requestedPaymentBy",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Requested At",
        dataIndex: "requestedAt",
        key: "requestedAt",
        ...getDateRangeFilterProps(),
        render: (text) => <p>{text ? moment(text)?.format("YYYY-MM-DD HH:mm:ss") : "-"}</p>
    },
    {
        title: "Approved Payment By",
        dataIndex: "approvedPaymentBy",
        key: "approvedPaymentBy",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Approved At",
        dataIndex: "approvedAt",
        key: "approvedAt",
        ...getDateRangeFilterProps(),
        render: (text) => <p>{text ? moment(text)?.format("YYYY-MM-DD HH:mm:ss") : "-"}</p>
    },
    {
        title: "Rejected Payment By",
        dataIndex: "rejectedPaymentBy",
        key: "rejectedPaymentBy",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Rejected At",
        dataIndex: "rejectedAt",
        key: "rejectedAt",
        ...getDateRangeFilterProps(),
        render: (text) => <p>{text ? moment(text)?.format("YYYY-MM-DD HH:mm:ss") : "-"}</p>
    },
    {
        title: "Reverse Payment By",
        dataIndex: "reversePaymentBy",
        key: "reversePaymentBy",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Reversed At",
        dataIndex: "reversedAt",
        key: "reversedAt",
        ...getDateRangeFilterProps(),
        render: (text) => <p>{text ? moment(text)?.format("YYYY-MM-DD HH:mm:ss") : "-"}</p>
    },
]

export const columnsCommissionBatch = [
    {
        title: "Record Id",
        dataIndex: "transactionTitle",
        key: "transactionTitle",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Total Payout",
        dataIndex: "totalPayout",
        key: "totalPayout",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Start Date",
        dataIndex: "calculatedStartDate",
        key: "calculatedStartDate",
        ...getDateRangeFilterProps(),
        render: (text) => <p>{text ? moment(text)?.format("YYYY-MM-DD") : "-"}</p>
    },
    {
        title: "End Date",
        dataIndex: "calculatedEndDate",
        key: "calculatedEndDate",
        ...getDateRangeFilterProps(),
        render: (text) => <p>{text ? moment(text)?.format("YYYY-MM-DD") : "-"}</p>
    },
    {
        title: "Created By",
        dataIndex: "createdBy",
        key: "createdBy",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
]

export const columnsCommissionLog = [
    {
        title: "Record No",
        dataIndex: "transactionName",
        key: "transactionName",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Batch No",
        dataIndex: "batchTransactionName",
        key: "batchTransactionName",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Batch Name",
        dataIndex: "batchName",
        key: "batchName",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Rule name",
        dataIndex: "ruleName",
        key: "ruleName",
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Rule Type",
        dataIndex: "ruleType",
        key: "ruleType",
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Tier Name",
        dataIndex: "tierName",
        key: "tierName",
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Account No",
        dataIndex: "tradingAccount",
        key: "tradingAccount",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Symbol",
        dataIndex: "symbol",
        key: "symbol",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Comm Type",
        dataIndex: "commType",
        key: "commType",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Payout Type",
        dataIndex: "type",
        key: "type",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Paid To",
        dataIndex: "assignedUser",
        key: "assignedUser",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Rank",
        dataIndex: "assignedUserRank",
        key: "assignedUserRank",
        ...getColumnSearchProps(),
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Level",
        dataIndex: "assignedUserLevel",
        key: "assignedUserLevel",
        ...getColumnSearchProps(),
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Report Name",
        dataIndex: "reportName",
        key: "reportName",
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Calculation Field",
        dataIndex: "reportCalculationField",
        key: "reportCalculationField",
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Calculation Amount",
        dataIndex: "calculatedAmount",
        key: "calculatedAmount",
        ...getColumnSearchProps(),
        render: (text) => <p>{formatMoney(text, "$")}</p>,
    },
    {
        title: "Assigned Amount",
        dataIndex: "assignedAmount",
        key: "assignedAmount",
        ...getColumnSearchProps(),
        render: (text, record) =>
            <p>{record?.commRuleSnapshot?.CommMode === "Value x %" ? text + '%' : formatMoney(text, "$")}</p>,
    },
    {
        title: "Assigned Compressed Amount",
        dataIndex: "assignedCompressedAmount",
        key: "assignedCompressedAmount",
        ...getColumnSearchProps(),
        render: (text, record) =>
            <p>{record?.commRuleSnapshot?.CommMode === "%" ? text + '%' : formatMoney(text, "$")}</p>,

    },
    {
        title: "Is Included Coefficient",
        dataIndex: "includedCoefficient",
        key: "includedCoefficient",
        ...getBooleanFilterProps(),
        render: (text) => (text) ? <BsCheck style={{color: 'green'}}/> : <BsX style={{color: 'red'}}/>,
    },
    {
        title: "Coefficient",
        dataIndex: "calculatedCoefficient",
        key: "calculatedCoefficient",
        ...getColumnSearchProps(),
        render: (text) => text ? <p>{text * 100}%</p> : "-",
    },
    {
        title: "Total Paid",
        dataIndex: "totalAssignedAmountRound",
        key: "totalAssignedAmountRound",
        ...getColumnSearchProps(),
        render: (text, record) => <p style={{cursor: "pointer"}}><Tooltip title={record?.totalAssignedAmount}>
            {formatMoney(text, "$")}
        </Tooltip></p>,
    },
    {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        ...getDateRangeFilterProps(),
        render: (text) => <p>{text}</p>,
    },
]

export const columnsCommissionLogPayment = [
    {
        title: "Record No",
        dataIndex: "transactionName",
        key: "transactionName",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Batch No",
        dataIndex: "batchTransactionName",
        key: "batchTransactionName",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Rule Name",
        dataIndex: "rule",
        key: "rule",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Batch Name",
        dataIndex: "batchName",
        key: "batchName",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Assignee",
        dataIndex: "user",
        key: "user",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Rank",
        dataIndex: "rank",
        key: "rank",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Assignee User Type",
        dataIndex: "userType",
        key: "userType",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Total Calculation Amount",
        dataIndex: "value",
        key: "value",
        ...getColumnSearchProps(),
        render: (text) => <p>{formatMoney(text, "$")}</p>,
    },
    {
        title: "Total Payout Amount",
        dataIndex: "payoutAmountRound",
        key: "payoutAmountRound",
        ...getColumnSearchProps(),
        render: (text) => <p>{formatMoney(text, "$")}</p>,
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Paid to E-wallet",
        dataIndex: "isPaid",
        key: "isPaid",
        ...getColumnSearchProps(),
        render: (text) => (text) ? <BsCheck style={{color: 'green'}}/> : <BsX style={{color: 'red'}}/>,
    },
    {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        ...getDateRangeFilterProps(),
        render: (text) => <p>{text}</p>,
    },
]

export const columnsCommissionBatchDetail = [
    {
        title: "Record No",
        dataIndex: "transactionTitle",
        key: "transactionTitle",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Rule name",
        dataIndex: "ruleName",
        key: "ruleName",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Rule Type",
        dataIndex: "ruleType",
        key: "ruleType",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Tier Name",
        dataIndex: "tierName",
        key: "tierName",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Deal Id",
        dataIndex: "dealId",
        key: "dealId",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Account No",
        dataIndex: "tradingAccount",
        key: "tradingAccount",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Symbol",
        dataIndex: "symbol",
        key: "symbol",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Comm Type",
        dataIndex: "commType",
        key: "commType",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Paid To",
        dataIndex: "assigned",
        key: "assigned",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Rank",
        dataIndex: "rank",
        key: "rank",
        ...getColumnSearchProps(),
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Rank Level",
        dataIndex: "rankLevel",
        key: "rankLevel",
        ...getColumnSearchProps(),
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Deal Amount",
        dataIndex: "amount",
        key: "amount",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Assigned Value",
        dataIndex: "assignedValue",
        key: "assignedValue",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Assigned Compressed Value",
        dataIndex: "assignedCompressedValue",
        key: "assignedCompressedValue",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Total Paid",
        dataIndex: "totalAssignedInUsd",
        key: "totalAssignedInUsd",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        ...getDateRangeFilterProps(),
        render: (text) => <p>{text}</p>,
    },
]