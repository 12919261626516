import {Col, Form, Image, Space, Spin, Tooltip, Upload} from "antd";
import {EyeOutlined, PlusOutlined, RedoOutlined} from "@ant-design/icons";
import React from "react";
import {useTranslation} from "react-i18next";
import {getConfigByName} from "../../../../../../../config";
import {BRAND_NAME} from "../../../../../../../config/brandVariable";
import useAuthorize from "../../../../../../_common/authorize";
import {useStore} from "react-context-hook";

const IconImage = (props) => {
    const {
        data,
        permission,
        handlePreview,
        uploadOnChange,
        resetIcon,
        currentFormItem,
        isModule = false,
        previewEnable = true,
        loading = false,
        updatingIcon
    } = props
    const {t} = useTranslation()

    const brandConfig = getConfigByName(BRAND_NAME)
    const [theme] = useStore("theme", "light");

    const src = isModule ? data[currentFormItem]?.[0]?.path : data?.[currentFormItem.name]?.[0]?.path
    const name = isModule ? currentFormItem : currentFormItem.name
    const label = isModule ? t(`icon.${currentFormItem}`) : t(currentFormItem.label)
    const color = name?.includes("Light") ? "light" : "dark"
    const {systemErrorNotification} = useAuthorize()

    return (
        <Col span={isModule ? 6 : 2} key={data?._id + name}>
            <Form.Item>
                {src ?
                    <Spin className={"preview-image-container-spin"} spinning={loading && (updatingIcon === name || (updatingIcon?.label === data?.label && updatingIcon?.field === name))}>
                        <div className={`preview-image-container ${theme === color ? "" : "inverse"}`}>
                            <Image
                                className={"preview-image"}
                                src={src}
                                height={"100%"}
                                width={"100%"}
                                preview={false}
                            />
                            {
                                permission &&
                                <div className={"action-bar"}>
                                    <Space className={"action-bar-inner"}>
                                        {previewEnable && <Tooltip title={t("Preview")}>
                                            <a onClick={() => handlePreview(src)}><EyeOutlined color={"#d2d2d2"}/></a>
                                        </Tooltip>}
                                        <Tooltip title={t("Upload")}>
                                            <Upload
                                                name={currentFormItem.name}
                                                action={`${brandConfig?.backendURL}/api/upload/profile/image`}
                                                style={{width: "100%"}}
                                                maxCount={1}
                                                showUploadList={false}
                                                defaultFileList={
                                                    [{
                                                        uid: data?._id,
                                                        name: data?.name,
                                                        url: src
                                                    }]
                                                }
                                                onChange={(e) => {
                                                    try {
                                                        uploadOnChange(e, name, data)
                                                    } catch (e) {
                                                        systemErrorNotification()
                                                    }
                                                }
                                                }
                                            >
                                                <a><PlusOutlined/></a>
                                            </Upload>
                                        </Tooltip>
                                        <Tooltip title={t("Reset to default")}>
                                            <a onClick={() => resetIcon(data, name)}>
                                                <RedoOutlined/>
                                            </a>
                                        </Tooltip>
                                    </Space>
                                </div>
                            }
                        </div>
                    </Spin>
                    :
                    <Upload
                        className={"ori-upload"}
                        name={name}
                        action={`${brandConfig?.backendURL}/api/upload/profile/image`}
                        style={{width: "100%"}}
                        listType={"picture-card"}
                        maxCount={1}
                        showUploadList={false}
                        defaultFileList={
                            [{
                                uid: data?._id,
                                name: data?.name,
                                url: data?.[name]?.[0]?.path
                            }]
                        }
                        onChange={(e) => {
                            try {
                                uploadOnChange(e, name, data)
                            } catch (e) {
                                systemErrorNotification()
                            }
                        }}
                    >
                        <div>
                            <PlusOutlined/>
                            <div style={{marginTop: 8}}>Upload</div>
                        </div>
                    </Upload>
                }
                <p className={"image-title margin-top-0-3"} style={{fontWeight: 500}}>{t(data?.label)}</p>
                <p className={"image-title"}>{label}</p>
            </Form.Item>
        </Col>
    )
}

export default IconImage