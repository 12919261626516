import React, {Fragment, useEffect, useReducer, useState} from "react";
import {Button, Col, Divider, Dropdown, Form, Image, Input, Row, Select, Space, Tag, Tooltip} from "antd";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {languageOptionsState} from "../../../../../users/_common/state";
import {MenuOutlined, PlusOutlined, SettingOutlined} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import MediaSelect from "../../../../_common/mediaComponent";
import {FaRegHeart, FaRegStar} from "react-icons/fa";
import {updateDescription} from "../../../../../../api/restful/arena/description";
import {notificationMessage} from "../../../../../../../recoil_state";

const DescriptionSetting = ({description, fetchDescription}) => {
    const languageOptions = useRecoilValue<any>(languageOptionsState)
    const [form] = Form.useForm()
    const setNotificationMessage = useSetRecoilState(notificationMessage)
    const [isFormTouched, setIsFormTouched] = useState(false)

    const changeCurrentViewHandler = (state, action) => {
        if (isFormTouched) {
            if (confirm("Are you sure to continue without save?")) {
                return {...state, [action.type]: action.value}
            } else {
                return state
            }
        }

        return {...state, [action.type]: action.value}
    }
    const [{type, language}, dispatchCurrentView] = useReducer<(state: any, action: any) => any>(changeCurrentViewHandler, {type: "lives", language: "en"})

    // const options = [
    //     {value: 'lives', label: 'Lives'},
    //     {value: 'level', label: 'Level'},
    //     {value: 'rank', label: 'Rank'},
    //     {value: 'exp', label: 'Experience Points'}
    // ]

    const options = description?.map(item => {
        let label = ""
        switch (item?.name) {
            case "lives":
                label = "Lives"
                break
            case "level":
                label = "Level"
                break
            case "rank":
                label = "Rank"
                break
            case "exp":
                label = "Experience Points"
                break
        }

        return {
            value: item?.name, label: label, key: item?._id
        }
    })

    const onUpdateHandler = async (value) => {
        const {title, content} = value

        try {
            const found = description.find(d => d.name === type)
            const {isDeleted, ...copy} = JSON.parse(JSON.stringify(found))

            if (title) {
                const titleIndex = copy.title.findIndex(item => item.language === language)
                if (titleIndex > -1) {
                    copy.title[titleIndex].content = title
                } else {
                    copy.title.push({content: title, language})
                }
            }

            if (content) {
                const contentIndex = copy.extReference.content.findIndex(item => item.language === language);
                if (contentIndex > -1) {
                    copy.extReference.content[contentIndex].content = content;
                } else {
                    copy.extReference.content.push({content, language});
                }
            }

            const response = await updateDescription(copy)

            if (response?.status === "Success") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Update Description Setting Successfully",
                    key: "arenaDescriptionSettingNotification",
                });

                fetchDescription()
            } else {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: `Update Description Setting Failed: ${response?.errObj}`,
                    key: "arenaDescriptionSettingNotification",
                });
            }
        } catch (e) {
        }
    }

    useEffect(() => {
        if (description && description?.length > 0) {
            const found = description.find(d => d.name === type)
            form.setFieldsValue({
                title: found.title.find(d => d.language === language)?.content,
                content: found.extReference.content?.find(d => d.language === language)?.content
            })
        }
    }, [description, type, language]);

    const handleFormChange = (value, field) => {
        const found = description.find(d => d.name === type)

        if (field === "title") {
            const title = found.title.find(d => d.language === language)?.content
            if (value === title) setIsFormTouched(false)
            else setIsFormTouched(true)
        }

        if (field === "content") {
            const contentValue = form.getFieldValue("content")
            const content = found.extReference.content.find(d => d.language === language)?.content
            if (JSON.stringify(contentValue) === JSON.stringify(content)) setIsFormTouched(false)
            else setIsFormTouched(true)
        }
    }

    return (
        <>
            {/*<p className={"margin-top-0-75"} style={{marginBottom: 16}}></p>*/}
            {/*<Tabs className={"items-tab"} items={tabItems} tabPosition={"left"}/>*/}
            <Form layout={"vertical"} form={form} onFinish={onUpdateHandler}>
                <Row className={"description-panel"} gutter={16}>
                    <Col span={16}>
                        <Row className={"margin-top-0-75"} gutter={8}>
                            <Col span={10}>
                                <Select
                                    options={options}
                                    placeholder={"Type"}
                                    className={"w-100"}
                                    value={type}
                                    onChange={value => dispatchCurrentView({type: "type", value: value})}
                                />
                            </Col>
                            <Col span={10}>
                                <Select
                                    options={languageOptions?.map(lang => ({value: lang?.value, label: lang?.label}))}
                                    placeholder={"Language"}
                                    className={"w-100"}
                                    value={language}
                                    onChange={value => dispatchCurrentView({type: "language", value: value})}
                                />
                            </Col>
                            <Col span={4} className={"text-align-right"}>
                                <Button type={"primary"} onClick={form.submit}>Update</Button>
                            </Col>
                        </Row>
                        <Divider/>

                        <div>
                            <Form.Item name={"title"} label={"Title"}>
                                <Input onChange={e => handleFormChange(e.target.value, "title")}/>
                            </Form.Item>

                            <Form.List name="content">
                                {(fields, {add, remove}) => {
                                    const items = [
                                        {
                                            key: "paragraph",
                                            label: <a onClick={() => add({type: "text"})}>Paragraph</a>,

                                        },
                                        {
                                            key: "image",
                                            label: <a onClick={() => add({type: "image"})}>Image</a>
                                        }
                                    ]

                                    return (
                                        <>
                                            <Form.Item className={"margin-top-0-75"} label={
                                                <Space>
                                                    <>Content</>
                                                    <Dropdown menu={{items}}>
                                                        <Tooltip title={"Add Content"}>
                                                            <a><PlusOutlined/></a>
                                                        </Tooltip>
                                                    </Dropdown>
                                                </Space>
                                            }>
                                                <div style={{marginBottom: 4}}>
                                                    {fields.map(({key, name, ...restField}) => {
                                                        const items = [
                                                            form.getFieldValue(["content", name, "type"]) === "text" ? {
                                                                key: 'text-align',
                                                                label: 'Text Align',
                                                                children: [
                                                                    {
                                                                        key: 'align-start',
                                                                        label: <a
                                                                            onClick={() => form.setFieldValue(["content", name, "textAlign"], "start")}>Left</a>,
                                                                    },
                                                                    {
                                                                        key: 'align-center',
                                                                        label: <a
                                                                            onClick={() => form.setFieldValue(["content", name, "textAlign"], "center")}>Center</a>,
                                                                    },
                                                                    {
                                                                        key: 'align-end',
                                                                        label: <a
                                                                            onClick={() => form.setFieldValue(["content", name, "textAlign"], "end")}>Right</a>,
                                                                    },
                                                                ],
                                                            } : null,
                                                            {
                                                                key: 'justify',
                                                                label: 'Justify',
                                                                children: [
                                                                    {
                                                                        key: 'justify-start',
                                                                        label: <a
                                                                            onClick={() => form.setFieldValue(["content", name, "align"], "start")}>Start</a>,
                                                                    },
                                                                    {
                                                                        key: 'justify-center',
                                                                        label: <a
                                                                            onClick={() => form.setFieldValue(["content", name, "align"], "center")}>Center</a>,
                                                                    },
                                                                    {
                                                                        key: 'justify-end',
                                                                        label: <a onClick={() => form.setFieldValue(["content", name, "align"], "end")}>End</a>,
                                                                    },
                                                                ],
                                                            },
                                                            {
                                                                key: "delete",
                                                                label: <a style={{color: "red"}} onClick={() => remove(name)}>Delete</a>,
                                                                danger: true
                                                            },
                                                        ].filter(d => d)

                                                        return (
                                                            <Form.Item key={key} noStyle
                                                                       shouldUpdate={(prevValues, currValues) => prevValues?.content?.length !== currValues?.content?.length}>
                                                                {() => {
                                                                    return (
                                                                        <Row gutter={8} align={"middle"} className={"margin-bottom-0-75"}>
                                                                            <Col span={22}>
                                                                                <Form.Item className={"margin-bottom-0"} name={[name, 'value']}>
                                                                                    {
                                                                                        form.getFieldValue(["content", name, "type"]) === "text" ?
                                                                                            <TextArea autoSize={{minRows: 2}}
                                                                                                      onChange={e => handleFormChange(e, "content")}/> :
                                                                                            <MediaSelect title={"Select Media"}
                                                                                                         selectedFile={form.getFieldValue(["content", name, "value"])}
                                                                                                         passMedia={(value) => {
                                                                                                             handleFormChange(value, "content")
                                                                                                             form.setFieldValue(["content", name, "value"], value)
                                                                                                         }}
                                                                                            />
                                                                                    }
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col span={2} className={"text-align-center"}>
                                                                                <Space>
                                                                                    <Dropdown menu={{items}} placement={"bottom"}>
                                                                                        <SettingOutlined/>
                                                                                    </Dropdown>
                                                                                    <MenuOutlined style={{cursor: "grab"}}/>
                                                                                </Space>
                                                                            </Col>
                                                                        </Row>
                                                                    )
                                                                }}
                                                            </Form.Item>
                                                        )
                                                    })}
                                                </div>
                                            </Form.Item>
                                        </>
                                    )
                                }}
                            </Form.List>
                        </div>
                    </Col>
                    <Col span={8}>
                        <h1 className={"margin-top-0-75"}>Preview</h1>
                        <div className={"preview-container"}>
                            <Form.Item shouldUpdate>
                                {() => {
                                    const {title, content} = form.getFieldsValue()
                                    return (
                                        <>
                                            <Row justify={"space-between"} align={"middle"}>
                                                <h2>{title}</h2>
                                                <Tag>
                                                    <Space align={"center"}>
                                                        <span>You have:</span>
                                                        <span>
                                                            <FaRegStar style={{fontSize: 9, color: "#e3d813"}}/>
                                                            <strong style={{fontSize: 12, fontWeight: 600}}> 10</strong>
                                                        </span>
                                                    </Space>
                                                </Tag>
                                            </Row>
                                            <div style={{display: "flex", flexDirection: "column"}}>
                                                {
                                                    content?.length > 0 && content?.map((item, i) => (
                                                        <div key={i}
                                                             style={{
                                                                 display: "flex",
                                                                 textAlign: item?.textAlign === "center" ? "center" : item?.textAlign === "end" ? "right" : "unset",
                                                                 justifyContent: item?.align,
                                                             }}
                                                        >
                                                            {
                                                                item?.type === "text" ? (
                                                                    <p style={{whiteSpace: "pre-wrap"}}>{item?.value}</p>
                                                                ) : item?.type === "image" ? (
                                                                    <Image preview={false} src={item?.value}/>
                                                                ) : <></>
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </>
                                        // <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>
                                    )
                                }}
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default DescriptionSetting