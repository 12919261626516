import { gql, useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { notificationMessage } from "../../../recoil_state";
import { refetchQuery } from "../../function/refetchQuery";

const GET_ROLE_NETWORK_TREE_COUNT = gql`
  query getRoleNetworkTreeCount {
    getRoleNetworkTreeCount
  }
`;

const getRoleNetworkTreeCount = () => {
  try {
    const { loading, error, data } = useQuery(GET_ROLE_NETWORK_TREE_COUNT, {
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_ROLE_NETWORK_TREE_BY_ID = gql`
  query getRoleNetworkTreeByID($id: String!) {
    getRoleNetworkTreeByID(id: $id) {
      ... on RoleNetworkTree {
        id
        parent {
          name
        }
        roleTemplateId {
          name
        }
        left
        right
        createdBy {
          username
        }
        updatedBy {
          username
        }
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getRoleNetworkTreeById = (id) => {
  try {
    const { loading, error, data } = useQuery(GET_ROLE_NETWORK_TREE_BY_ID, {
      variables: {
        id: id,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_ROLE_NETWORK_TREE = gql`
  query getRoleNetworkTree {
    getRoleNetworkTree {
      ... on RoleNetworkTreeArray {
        data {
          _id
          id
          parent {
            _id
            name
          }
          roleTemplateId {
            _id
            name
            parent {
              _id
            }
          }
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getRoleNetworkTree = () => {
  try {
    const { loading, error, data } = useQuery(GET_ROLE_NETWORK_TREE, {
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const SORT_ROLE_NETWORK_TREE = gql`
  mutation sortRoleNetworkTree {
    sortRoleNetworkTree {
      ... on RoleNetworkTreeArray {
        data {
          _id
          id
          parent {
            name
          }
          roleTemplateId {
            name
          }
          left
          right
          createdBy {
            username
          }
          updatedBy {
            username
          }
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useSortRoleNetworkTreeMutation = (): {
  sortRoleNetworkTree: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const [sortRoleNetworkTreeMutation, { data, loading, error }] = useMutation(
      SORT_ROLE_NETWORK_TREE
    );

    const sortRoleNetworkTree = () => {
      return sortRoleNetworkTreeMutation();
    };

    return { sortRoleNetworkTree, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return {
      sortRoleNetworkTree: null,
      data: null,
      loading: false,
      error: err,
    };
  }
};

const GET_ROLE_NETWORK_TREE_EXPORT_DATA = gql`
  query getRoleNetworkTreeExportData{
    getRoleNetworkTreeExportData
  }
`;

const getRoleNetworkTreeExportData = () => {
  try {
    const { loading, error, data } = useQuery(GET_ROLE_NETWORK_TREE_EXPORT_DATA, {
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};


const SORT_TARGET_ROLE_NETWORK_TREE = gql`
  mutation sortTargetRoleNetworkTree($roleTemplateId: String!) {
    sortTargetRoleNetworkTree(roleTemplateId: $roleTemplateId) {
      ... on RoleNetworkTreeArray {
        data {
          _id
          id
          parent {
            name
          }
          roleTemplateId {
            name
          }
          left
          right
          createdBy {
            username
          }
          updatedBy {
            username
          }
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;
const useSortTargetRoleNetworkTreeMutation = (): {
  sortRoleNetworkTree: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const [sortRoleNetworkTreeMutation, { data, loading, error }] = useMutation(
      SORT_TARGET_ROLE_NETWORK_TREE
    );

    const sortRoleNetworkTree = (roleTemplateId) => {
      return sortRoleNetworkTreeMutation({
        variables: {
          roleTemplateId: roleTemplateId,
        },
      });
    };

    return { sortRoleNetworkTree, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return {
      sortRoleNetworkTree: null,
      data: null,
      loading: false,
      error: err,
    };
  }
};

const IMPORT_ROLE_NETWORK_TREE_DATA = gql`
  mutation importRoleNetworkTreeData($input: String) {
    importRoleNetworkTreeData(input: $input) {
      ... on RoleNetworkTreeArray {
        data {
          _id
          id
          parent {
            name
          }
          roleTemplateId {
            name
          }
          left
          right
          createdBy {
            username
          }
          updatedBy {
            username
          }
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
        errObj
      }
    }
  }
`;

const useImportRoleNetworkTreeData = (): {
  importRoleNetworkTreeData: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const { t } = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [importRoleNetworkTreeDataMutation, { data, loading, error }] =
      useMutation(IMPORT_ROLE_NETWORK_TREE_DATA);

    const importRoleNetworkTreeData = (input) => {
      return importRoleNetworkTreeDataMutation({
        variables: {
          input: input,
        },
        update(cache, { data: { importRoleNetworkTreeData } }) {
          const typename = importRoleNetworkTreeData?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(importRoleNetworkTreeData?.errKey),
              key: "importRoleNetworkTreeData",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(importRoleNetworkTreeData?.msg),
              key: "importRoleNetworkTreeData",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Update Successfully"),
              key: "importRoleNetworkTreeData",
            });
          }
        },
        refetchQueries: [GET_ROLE_NETWORK_TREE, GET_ROLE_NETWORK_TREE_COUNT],
      });
    };

    return { importRoleNetworkTreeData, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return { importRoleNetworkTreeData: null, data: null, loading: false, error: err };
  }
};

export {
  getRoleNetworkTreeCount,
  getRoleNetworkTreeById,
  getRoleNetworkTree,
  useSortRoleNetworkTreeMutation,
  useSortTargetRoleNetworkTreeMutation,
  getRoleNetworkTreeExportData,
  useImportRoleNetworkTreeData
};
