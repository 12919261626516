import React from "react"
import dayjs, {Dayjs} from "dayjs"
import {Tooltip} from "antd"
import moment from "moment"
import type {ColumnsType} from "antd/es/table"
import {AlignType} from "rc-table/lib/interface"
import {getReportBooleanFilterProps, getReportColumnSearchProps, getReportDigitsExactFilterProps} from "../../../../components/table/function/filterReportTable"
import {formatDateTime} from "../../../users/_common/function"
import {getConfigByName} from "../../../../../config"
import {BRAND_NAME} from "../../../../../config/brandVariable"
import {formatDateTimeMT5} from "../../../../function/_common"

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
}

const rangePresets: {
    label: string
    value: [Dayjs, Dayjs]
}[] = [
    {label: "Today", value: [dayjs().add(0, "d"), dayjs()]},
    {label: "Yesterday", value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")]},
    {label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()]},
    {label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()]},
    {label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()]},
    {
        label: "This Week",
        value: [dayjs().startOf("week"), dayjs()],
    },
    {
        label: "Last Week",
        value: [dayjs().subtract(1, "week").startOf("week"), dayjs().subtract(1, "week").endOf("week")],
    },
    {
        label: "This Month",
        value: [dayjs().startOf("month"), dayjs()],
    },
    {
        label: "Last Month",
        value: [dayjs().subtract(1, "month").startOf("month"), dayjs().subtract(1, "month").endOf("month")],
    },
    {
        label: "This Quarter",
        value: [dayjs().startOf("quarter"), dayjs()],
    },
    {
        label: "Last Quarter",
        value: [dayjs().subtract(1, "quarter").startOf("quarter"), dayjs().subtract(1, "quarter").endOf("quarter")],
    },
    {
        label: "This Year",
        value: [dayjs().startOf("year"), dayjs()],
    },
    {
        label: "Last Year",
        value: [dayjs().subtract(1, "year").startOf("year"), dayjs().subtract(1, "year").endOf("year")],
    },

    {
        label: "All Time",
        value: [dayjs().year(2020).startOf("year"), dayjs()],
    },
]

const getDatesValueFromRangePreset = (rangeValue) => {
    let dates: any
    let label: any
    switch (rangeValue) {
        case "TODAY":
        case "Today":
            label = "Today"
            break
        case "YESTERDAY":
        case "Yesterday":
            label = "Yesterday"
            break
        case "LAST7DAYS":
        case "Last 7 Days":
            label = "Last 7 Days"
            break
        case "LAST14DAYS":
        case "Last 14 Days":
            label = "Last 14 Days"
            break
        case "LAST30DAYS":
        case "Last 30 Days":
            label = "Last 30 Days"
            break
        case "THISMONTH":
        case "This Month":
            label = "This Month"
            break
        case "LASTMONTH":
        case "Last Month":
            label = "Last Month"
            break
        case "ALLTIME":
        case "All Time":
            label = "All Time"
            break
        default:
            label = "Last Month"
            break
    }
    dates = rangePresets?.find((v: any) => v?.label === label)?.value
    return dates
}

function getTextBeforeOpeningParentheses(inputString) {
    const regex = /[^()]+(?=\()/g
    const matches = inputString.match(regex)

    if (matches) {
        return matches.map((match) => match.trim().replace(".", ""))
    } else {
        console.log("No opening parentheses found.")
        return []
    }
}

function extractUniqueAlphanumericWordsInParentheses(inputString) {
    const regex = /\(([^)]+)\)/g
    const uniqueMatches = new Set()

    let match
    while ((match = regex.exec(inputString)) !== null) {
        // const words = match[1].match(/\b[A-Za-z0-9]+\b/g);
        const words = match[1].match(/\b[A-Za-z0-9]+\b/g)
        if (words) {
            words.forEach((word) => {
                if (/[A-Za-z]+\d|\d+[A-Za-z]+/.test(word)) {
                    uniqueMatches.add(word)
                }
            })
        }
    }

    return Array.from(uniqueMatches)
}

function extractUniqueAlphanumericWordsWithRegex(inputString) {
    const regex = /\b[A-Za-z]+\d+\b/g
    const uniqueMatches = new Set()
    let match

    while ((match = regex.exec(inputString)) !== null) {
        uniqueMatches.add(match[0])
    }

    return Array.from(uniqueMatches)
}

function addSumOfDeskNetwork(desk) {
    if (desk?.children && desk?.children?.length > 0) {
        Object.keys(desk)?.forEach((key) => {
            if (typeof desk?.[key]?.displayValue === "number") {
                const sumOfChildren = desk?.children?.reduce((sum, child) => sum + addSumOfDeskNetwork(child)[key]?.sumOfNetwork || 0, 0)
                desk[key] = {...desk[key], sumOfNetwork: desk[key]?.displayValue + sumOfChildren}
            }
        })
        desk.children.forEach((child) => addSumOfDeskNetwork(child))
    } else {
        // If there are no children, set sumOfDeskNetwork properties to the current displayValue
        Object.keys(desk).forEach((key) => {
            if (typeof desk?.[key]?.displayValue === "number") {
                desk[key] = {...desk[key], sumOfNetwork: desk[key]?.displayValue}
            }
        })
    }
    return desk // Return the modified desk object
}

function removeEmptyChildren(obj) {
    if (Array.isArray(obj)) {
        // If obj is an array, create a new array without empty children
        return obj.map((child) => removeEmptyChildren(child))
    } else if (typeof obj === "object" && obj !== null) {
        // If obj is an object, create a new object without the "children" key if it's empty
        const newObj = {}
        for (const key in obj) {
            if (key === "children" && Array.isArray(obj[key]) && obj[key].length === 0) {
                // Skip "children" key
            } else {
                // Recursively call the function on the value of the key
                newObj[key] = removeEmptyChildren(obj[key])
            }
        }
        return newObj
    }
    return obj
}

const transformToDeskTreeData = (data) => {
    try {
        const result: any = []
        const lookup: any = {}

        // console.time("deskid");
        data?.forEach((item) => {
            lookup[item?.deskid] = {
                ...item,
                children: [],
                // totalDirectDownline: 0,
                // totalDownline: 0,
            }
        })
        // console.timeEnd("deskid");

        // console.time("pusToParent");
        data?.forEach((item) => {
            if (lookup && item?.parent?._id && lookup[item?.parent?._id]) {
                lookup[item?.parent._id]?.children?.push(lookup[item?.deskid])
                // lookup[item?.parent._id].totalDirectDownline += 1;
            } else {
                result?.push(lookup[item?.deskid])
            }
        })
        // console.timeEnd("pusToParent");

        // console.time("removeEmptyChildren");
        result?.forEach(removeEmptyChildren)
        // console.timeEnd("removeEmptyChildren");
        // console.time("addSumOfDeskNetwork");
        // result?.forEach(addSumOfDeskNetwork);
        // console.timeEnd("addSumOfDeskNetwork");

        return result
    } catch (error) {}
}

function isValidCurrencyCode(code) {
    // Ensure the code is a non-empty string with a length of 3 and consists of uppercase letters
    return typeof code === "string" && code.length === 3 && /^[A-Z]+$/.test(code)
}

const setupReportColumns = async (
    reportFields,
    reportingAllTypes,
    displayMode,
    reportViewMode,
    setColumns,
    reportSummaryData,
    isMasterAdmin,
    expandedRowKeys,
    currencySetting,
    filter?,
    fromDate?,
    toDate?,
    isPLReport?,
    setEditDepositWithdrawalProps?,
    renderTransactionTypeColumn?
) => {
    try {
        const reportDefaultOrderByColumn = reportSummaryData?.orderByColumn
        const reportDefaultOrderByDirection = reportSummaryData?.orderByDirection
        const tradingPlatform = getConfigByName(BRAND_NAME).tradingPlatform
        const reportTimeAffectedByMT5 = ["64d0a107847e88a7d5978e3a", "64d0a107847e88a7d5978e3b", "64d0a107847e88a7d5978e39", "64d0a107847e88a7d5978e5e"]

        if (reportFields?.length > 0) {
            const generatedColumns: ColumnsType<any> = reportFields
                ?.filter((v) => v?.uiName !== "baseId")
                ?.map((field: any, index: Number) => {
                    let colDisplayName = field?.uiName
                    if (reportSummaryData?.groupBy && field?.uiName === "baseField") {
                        switch (reportSummaryData?.groupByType) {
                            case "USER":
                                colDisplayName = "Username"
                                break
                            case "CRMUSER":
                                colDisplayName = "CRM User"
                                break
                            case "SALESREP":
                                colDisplayName = "Sales Rep"
                                break
                            case "DESK":
                                colDisplayName = "Desk Name"
                                break
                            case "TRADINGACCOUNT":
                                colDisplayName = "Account No."
                                break
                            case "PSPSETTING":
                                colDisplayName = "PSP"
                                break
                            default:
                                break
                        }
                    }

                    let title = (
                        <Tooltip title={field?.description} className="margin-left-0-25">
                            <span className="display-flex">
                                {colDisplayName}
                                {/* {field?.description && (
                                <Tooltip title={field?.description} className="margin-left-0-25">
                                    <a onClick={() => console.log(field)}>
                                        <InfoCircleOutlined />
                                    </a>
                                </Tooltip>
                            )} */}
                            </span>
                        </Tooltip>
                    )
                    const fieldType = reportingAllTypes?.find((v: any) => v?._id === field?.typeId)?.name
                    const linkedTo = field?.linkedTo
                    const linkedToReportId = field?.linkedToReportId
                    const isTradingAccountDepositWithdrawColumn = setEditDepositWithdrawalProps && reportSummaryData?._id === "64d0a107847e88a7d5978e3c" && ["deposits", "withdrawals", "adjustmentDaily"].includes(field?.reportFieldName)
                    const isNumberFormattable = field?.isNumberFormattable
                    let cellAlignment = fieldType === "integer" || fieldType === "double" ? "right" : "left"

                    let renderItem = (item, record?) => {
                        const isRowExpanded = expandedRowKeys?.includes(record?._id?.displayValue)
                        let hrefPath = item?.linkedPath
                        if (linkedTo === "report" && linkedToReportId) {
                            hrefPath = `/reports?reportId=${linkedToReportId}`
                            if (fromDate && toDate) {
                                hrefPath += `&fromDate=${fromDate}&toDate=${toDate}`
                            }

                            if (isPLReport) {
                                hrefPath += `&filterType=${reportViewMode === "tree" && !isRowExpanded ? "DESKNETWORK" : "DESK"}`
                                hrefPath += `&filterTypeId=${record?.baseId?.displayValue}`
                            }

                            if (field?.linkedToReportFields?.length > 0) {
                                field?.linkedToReportFields?.forEach((v) => {
                                    let value = v?.inputValue || record?.[v?.fromReportFieldName]?.displayValue
                                    hrefPath += `&${v?.linkedToReportFieldName}=${value}`
                                })
                            }
                        }

                        const currencyName = item?.currencyName
                        const currencyData = currencyName ? currencySetting?.find((c: any) => c?.code === currencyName) : null
                        const currencyDigits = currencyData?.digits
                        // const currencySymbol = currencyData?.symbol;
                        const className = field?.fieldName === "checkMatching" && record?.checkMatching?.displayValue !== 0 ? "text-danger" : ""
                        let content = ""
                        switch (fieldType) {
                            case "integer":
                            case "double":
                                let value = isRowExpanded || reportViewMode === "table" ? item?.displayValue : item?.sumOfNetwork
                                // let value = reportViewMode === "tree" && (!isRowExpanded) && item?.sumOfChildren ? item?.sumOfChildren : item?.displayValue
                                let display = value
                                    ? currencyName
                                        ? (isValidCurrencyCode(currencyName) &&
                                              value?.toLocaleString(undefined, {
                                                  style: "currency",
                                                  currency: currencyName,
                                                  minimumFractionDigits: currencyDigits,
                                                  maximumFractionDigits: currencyDigits,
                                              })) ||
                                          value?.toLocaleString(undefined, {
                                              minimumFractionDigits: currencyDigits,
                                              maximumFractionDigits: currencyDigits,
                                          })
                                        : // isNumberFormattable
                                          // ? value?.toLocaleString(undefined, {
                                          //       minimumFractionDigits: 0,
                                          //       maximumFractionDigits: 2,
                                          //   })
                                          // :
                                          value
                                    : "-"
                                content = display ?? "-"
                                break
                            case "string":
                            case "objectId":
                            case "id":
                                content = item?.displayValue ?? "-"
                                break
                            case "timestamp":
                            case "dateTime":
                                content = item?.displayValue ? (tradingPlatform === "MT5" && reportTimeAffectedByMT5.includes(reportSummaryData?._id) ? formatDateTimeMT5(item?.displayValue) : formatDateTime(item?.displayValue)) : "-"
                                break
                            case "boolean":
                                content = item?.displayValue === true ? "true" : "false"
                                break
                            default:
                                content = item?.displayValue ?? "-"
                                break
                        }

                        const voidedAry = ["VOID", "VOID_AND_REOPENED", "VOID_OPEN_POSITION"]

                        return linkedTo || hrefPath ? (
                            <a target="_blank" href={hrefPath}>
                                <p className={className}>{content}</p>
                            </a>
                        ) : isTradingAccountDepositWithdrawColumn ? (
                            <a
                                onClick={() =>
                                    setEditDepositWithdrawalProps({
                                        isOpen: true,
                                        accountId: record?.accountNo?.displayValue,
                                        transactionType: field?.reportFieldName,
                                    })
                                }
                            >
                                <p>{content}</p>
                            </a>
                        ) : renderTransactionTypeColumn && field?.reportFieldName === "transactionType" ? (
                            renderTransactionTypeColumn(item, record)
                        ) : voidedAry.includes(record?.commissionStatus?.displayValue) ? (
                            <p className={"voided-record"}>{content}</p>
                        ) : (
                            <p className={className}>{content}</p>
                        )
                    }

                    let searchProps = () => {
                        switch (fieldType) {
                            case "integer":
                            case "double":
                                return getReportDigitsExactFilterProps(0, null, 1, filter, field?.reportFieldName)
                            case "boolean":
                                return getReportBooleanFilterProps(filter, field?.reportFieldName)
                            case "timestamp":
                            case "dateTime":
                                return null
                            case "string":
                            case "objectId":
                            case "id":
                            default:
                                return getReportColumnSearchProps(filter, field?.reportFieldName)
                        }
                    }
                    const isReportDefaultOrderByField = reportDefaultOrderByColumn === field?.fieldName
                    const randomString = reportViewMode === "tree" ? Math.random().toString(16).slice(2) : ""

                    return {
                        title: title,
                        dataIndex: field?.reportFieldName,
                        // key: field?.reportFieldName,
                        key: field?.reportFieldName + "-" + field?._id + randomString,
                        reportFieldName: field?.reportFieldName,
                        fieldName: field?.fieldName,
                        fieldId: field?._id,
                        fieldType: fieldType,
                        isHidden: field?.isHidden,
                        align: cellAlignment as AlignType,
                        width: field?.uiName === "baseField" ? 250 : "auto",
                        ...(isReportDefaultOrderByField &&
                            reportDefaultOrderByDirection && {
                                defaultSortOrder: reportDefaultOrderByDirection === "ASCENDING" ? "ascend" : "descend", // Debug
                            }),
                        ...(reportViewMode === "tree"
                            ? {
                                  filtered: false, // Debug
                              }
                            : {...searchProps()}),
                        render: (item, record) => renderItem(item, record),
                    }
                })

            let amendedColumns: any = [...generatedColumns]

            // Hardcoded to filter out the extra fields - Comment out - Reporting Server cater for this
            // if (reportData?.[0]) {
            //     let excludedFields = ["timestamp", "platform", "server", "whiteLabel", "_id", "baseId"];
            //     let reportFieldsList = reportFields?.map((v: any) => v?.reportFieldName);
            //     let baseFields = Object.keys(reportData?.[0])?.filter((key) => {
            //         return ![...excludedFields, ...reportFieldsList]?.includes(key);
            //     });
            //     // console.log("baseFields"); // debug
            //     // console.log(baseFields); // debug
            //     if (baseFields?.length > 0) {
            //         let baseFieldsColumn = baseFields?.map((field) => {
            //             return {
            //                 title: field,
            //                 dataIndex: field,
            //                 key: field,
            //                 render: (item) => <p>{item?.displayValue}</p>,
            //             };
            //         });
            //         amendedColumns = [...baseFieldsColumn, ...amendedColumns];
            //     }
            // }

            amendedColumns.unshift({
                title: "Date",
                dataIndex: "timestamp",
                key: "timestamp",
                fieldName: "timestamp",
                render: (item) => <p>{item?.displayValue ? moment(item?.displayValue)?.format("YYYY-MM-DD") : "-"}</p>,
            })

            if (displayMode === "SUMUP") {
                amendedColumns = amendedColumns.filter((v: any) => v?.title !== "Date" && v?.dataIndex !== "timestamp") // Remove the Date column if displayMode is not "DATE"
            }

            amendedColumns?.sort(function (x, y) {
                return x?.fieldName == "baseField" ? -1 : y?.fieldName == "baseField" ? 1 : 0
            })

            if (!isMasterAdmin) {
                amendedColumns = amendedColumns?.filter((col) => !col?.isHidden)
            }

            setColumns(amendedColumns)
        } else {
            setColumns([])
        }
    } catch (error) {}
}

export {reorder, rangePresets, getDatesValueFromRangePreset, getTextBeforeOpeningParentheses, extractUniqueAlphanumericWordsInParentheses, extractUniqueAlphanumericWordsWithRegex, removeEmptyChildren, transformToDeskTreeData, setupReportColumns}
