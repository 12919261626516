import {atom} from "recoil";

const reportingAllState = atom({
    key: "reportingAllState",
    default: [],
});
const reportingCategoryState = atom({
    key: "reportingCategoryState",
    default: [],
});
const reportingCategoryLoadingState = atom({
    key: "reportingCategoryLoadingState",
    default: [],
});
const reportingAllFieldsState = atom({
    key: "reportingAllFieldsState",
    default: [],
});
const reportingAllFunctionsState = atom({
    key: "reportingAllFunctionsState",
    default: [],
});
const reportingAllOperationsState = atom({
    key: "reportingAllOperationsState",
    default: [],
});
const reportingAllTypesState = atom({
    key: "reportingAllTypesState",
    default: [],
});
const reportingAllFieldIdentifiersState = atom({
    key: "reportingAllFieldIdentifiersState",
    default: [],
});
const reportingAllAddionalFieldIdentifiersState = atom({
    key: "reportingAllAddionalFieldIdentifiersState",
    default: [],
});

export {
    reportingAllState,
    reportingCategoryState,
    reportingCategoryLoadingState,
    reportingAllFieldsState,
    reportingAllFunctionsState,
    reportingAllOperationsState,
    reportingAllTypesState,
    reportingAllFieldIdentifiersState,
    reportingAllAddionalFieldIdentifiersState,
};
