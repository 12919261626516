export const groupByPspSettingName = (data) => {
    return (
        data?.reduce((result, item) => {
            const pspSettingName = item?.pspSetting?.name;

            if (!result[pspSettingName]) {
                result[pspSettingName] = [];
            }

            result[pspSettingName].push(item);

            return result;
        }, {}) || []
    );
};