import React, {useEffect, useState} from "react";
import {Button, Col, Divider, Row, Select, Space} from "antd";
import {useTranslation} from "react-i18next";
import {PlusOutlined} from "@ant-design/icons";
import RichTextEditor from "../richTextEditor";
import {getDataWithLanguage, handleOnChangeWithLanguage} from "../../../../../brandDetails/function/common";
import {
    getCommunitySetting,
    useUpdateCommunitySettingMutation
} from "../../../../../../../api/graphql/communitySetting";
import useAuthorize from "../../../../../../../_common/authorize";

const CommunitySetting = (props) => {
    const {languageList, setCustomizeSubmitButton, close} = props
    const {t} = useTranslation()
    const documents = ["Terms of Community", "Rules & Regulations", "Brand Page Policy", "Brand Content Policy", "Includes Only Brand Users"]

    const [currentFile, setCurrentFile] = useState("")
    const [currentFileName, setCurrentFileName] = useState("")
    const [isOpenEditor, setIsOpenEditor] = useState(false)
    const [languageCode, setLanguageCode] = useState("en")
    const [data, setData] = useState<any>()

    const {data: communitySetting, loading} = getCommunitySetting()
    const {updateCommunitySetting} = useUpdateCommunitySettingMutation()
    const {systemErrorNotification} = useAuthorize()

    useEffect(() => {
        setData(communitySetting?.getCommunitySetting)
    }, [loading])

    const openEditorModal = (val) => {
        setIsOpenEditor(true)
        setCurrentFile(val)

        switch (val) {
            case "Terms of Community":
                setCurrentFileName("termOfCommunity")
                break
            case "Rules & Regulations":
                setCurrentFileName("ruleAndRegulation")
                break
            case "Brand Page Policy":
                setCurrentFileName("brandPagePolicy")
                break
            case "Brand Content Policy":
                setCurrentFileName("brandContentPolicy")
                break
            case "Includes Only Brand Users":
                setCurrentFileName("includesOnlyBrandUsers")
                break
        }
    }

    const handleOnchange = (e, fieldName, fieldType = "document") => {
        handleOnChangeWithLanguage(e, fieldName, fieldType, languageCode, setData)
    }

    const onSubmit = (value) => {
       try{
           updateCommunitySetting(value)
           close()
       }catch (e) {
           systemErrorNotification()
       }
    }

    useEffect(() => {
        setCustomizeSubmitButton(
            <Button onClick={() => onSubmit(data)} type={"primary"}>{t("common.submit")}</Button>
        )
    }, [data])

    return (
        <>
            <RichTextEditor
                isOpen={isOpenEditor}
                setIsOpen={setIsOpenEditor}
                title={t(currentFile)}
                editorState={getDataWithLanguage(data, currentFileName, "document", languageCode)}
                onEditorStateChange={(e) => handleOnchange(e, currentFileName, "document")}
            />
            <Divider/>
            <Row style={{padding: 16}}>
                <Col span={24}>
                    {
                        documents?.map(d => {
                            return (
                                <Row justify={"space-between"} className={"margin-bottom-0-75"} key={d}>
                                    <p className={"margin-bottom-0"}>{t(d)}</p>
                                    <Space.Compact>
                                        <Select
                                            style={{width: 150}}
                                            defaultValue={"en"}
                                            options={languageList?.map(lang => {
                                                return {
                                                    label: lang?.name,
                                                    value: lang?.code
                                                }
                                            })}
                                            onChange={(value => setLanguageCode(value))}
                                        />
                                        <Button icon={<PlusOutlined/>} onClick={() => openEditorModal(d)}/>
                                    </Space.Compact>
                                </Row>
                            )
                        })
                    }
                </Col>
            </Row>
        </>
    )
};

export default CommunitySetting