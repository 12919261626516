import React, {useState} from "react";
import {useParams} from "react-router";
import {useSearchParams} from "react-router-dom";
import PushNotificationTemplateEditor_1 from "./view/layout1";
import {getLanguage} from "../../../api/graphql/language";
import {sortEn} from "../../../function/language";
import {
    getPushNotificationTemplateById,
    updatePushNotificationTemplate
} from "../../../api/graphql/pushNotificationTemplate";
import {getDynamicVariable} from "../../../api/graphql/dynamicVariable";
import useAuthorize from "../../../_common/authorize";
import {removeTypenameKey} from "../../../function/_common";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../recoil_state";
import {useTranslation} from "react-i18next";

function PushNotificationTemplateEditor() {
    let layout = 1;
    let component;
    const {t} = useTranslation();
    const {templateId} = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false)
    const mode = searchParams.get("mode");
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const {languageData} = getLanguage({filter: null});
    const data: any = getPushNotificationTemplateById(templateId);

    const {variableData: imageVarData} = getDynamicVariable({
        filter: {variableType: "image", isActive: true},
    });
    const {variableData: textVarData} = getDynamicVariable({
        filter: {variableType: "text", isActive: true},
    });
    const {variableData: pdfVarData} = getDynamicVariable({
        filter: {variableType: "pdf", isActive: true},
    });
    const {systemErrorNotification} = useAuthorize();

    const handleTemplateUpdate = async (input) => {
        try {
            setLoading(true)
            await updatePushNotificationTemplate(templateId, removeTypenameKey(input));
            setNotificationMessage({
                status: "success",
                title: "",
                msg: t("message.push_notification_template_updated_successfully"),
                key: "messagesNotification",
            });
            setLoading(false)
        } catch (e: any) {
            console.log(e);
            systemErrorNotification();
        }
    };
    switch (layout) {
        case 1:
            component = (
                <PushNotificationTemplateEditor_1
                    mode={mode}
                    data={data}
                    loading={loading}
                    languageData={sortEn(languageData?.getLanguage?.data)}
                    imageVarData={imageVarData?.getDynamicVariable?.data}
                    textVarData={textVarData?.getDynamicVariable?.data}
                    pdfVarData={pdfVarData?.getDynamicVariable?.data}
                    handleTemplateUpdate={handleTemplateUpdate}
                />
            );
            break;

        default:
            component = (
                <PushNotificationTemplateEditor_1
                    mode={mode}
                    data={data}
                    loading={loading}
                    languageData={sortEn(languageData?.getLanguage?.data)}
                    imageVarData={imageVarData?.getDynamicVariable?.data}
                    textVarData={textVarData?.getDynamicVariable?.data}
                    pdfVarData={pdfVarData?.getDynamicVariable?.data}
                    handleTemplateUpdate={handleTemplateUpdate}
                />
            );
            break;
    }

    return component;
}

export default PushNotificationTemplateEditor;
