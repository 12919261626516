import {Form, Select} from "antd";
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import FormErrors from "./formErrors";
import {getTimezoneList} from "../../api/graphql/_common";

const TimezoneSelectFormItem = (props) => {
    const {
        layout,
        label,
        name,
        externalErrorMessage,
        onChange,
        hint,
        disabled,
        required,
        mode,
        showSearch,
        showArrow,
        allowClear,
        placeholder,
        value,
    } = props;

    const {
        register,
        watch,
        setValue,
        formState: {touchedFields, isSubmitted, errors},
    } = useFormContext();

    const {data} = getTimezoneList();
    const mapData = (data) => {
        let temp = data?.getTimezoneList.map((item) => {
            return {
                label: item?.label,
                value: item?.value,
                key: item?.label,
            };
        });

        return temp;
    }

    const errorMessage = FormErrors.errorMessage(
        name,
        label,
        errors,
        touchedFields,
        isSubmitted,
        externalErrorMessage
    );

    const formHelperText = errorMessage || hint;

    useEffect(() => {
        register(name);
    }, [register, name]);

    useEffect(() => {
        if (value) {
            setValue(name, value, {shouldValidate: true});
        }
    }, [value]);

    return (
        <Form.Item
            {...layout}
            label={label}
            required={required}
            validateStatus={errorMessage ? "error" : "success"}
            help={formHelperText}
        >
            <Select
                id={name}
                mode={mode}
                options={data && mapData(data)}
                value={watch(name)}
                onChange={(e) => {
                    setValue(name, e, {shouldValidate: true});
                    onChange && onChange(e);
                }}
                placeholder={placeholder}
                showSearch={showSearch}
                showArrow={showArrow}
                allowClear={allowClear}
                disabled={disabled}
            />
        </Form.Item>
    );
};

TimezoneSelectFormItem.defaultProps = {
    required: false,
    disabled: false,
};

TimezoneSelectFormItem.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    externalErrorMessage: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    layout: PropTypes.object,
    hint: PropTypes.string,
    showSearch: PropTypes.bool,
    showArrow: PropTypes.bool,
    allowClear: PropTypes.bool,
    placeholder: PropTypes.string,
    mode: PropTypes.string,
    value: PropTypes.any,
};

export default TimezoneSelectFormItem;
