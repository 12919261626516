import React from "react";
import {BsCheck, BsX} from "react-icons/bs";
import {
    getBooleanFilterProps,
    getColumnSearchProps,
    getDigitsFilterProps
} from "../../../../components/table/function/filterTable";

export const columnsCT = [
  {
    title: "Asset ID",
    dataIndex: "assetId",
    key: "assetId",
    ...getColumnSearchProps(),
    render: (text) => <p>{text || "-"}</p>,
  },
  {
    title: "Asset Name",
    dataIndex: "name",
    key: "name",
    ...getColumnSearchProps(),
    render: (text) => <p>{text || "-"}</p>,
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    ...getColumnSearchProps(),
    render: (text) => <p>{text || "-"}</p>,
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    filters: [
      {text: "PROTO_STOCK", value: "PROTO_STOCK"},
      {text: "PROTO_METALS", value: "PROTO_METALS"},
      {text: "PROTO_COMMODITY", value: "PROTO_COMMODITY"},
      {text: "PROTO_FOREX", value: "PROTO_FOREX"},
      {text: "PROTO_CRYPTO", value: "PROTO_CRYPTO"},
      {text: "PROTO_INDICES", value: "PROTO_INDICES"},
    ],
    render: (text) => <p>{text || "-"}</p>,
  },
  {
    title: "In Deposit Asset",
    dataIndex: "depositAsset",
    key: "depositAsset",
    align: "center",
    ...getBooleanFilterProps(),
    // onFilter: (value, record) => record.depositAsset.toString().indexOf(value) === 0,
    render: (text) => (text) ? <BsCheck style={{ color: 'green' }}/> : <BsX style={{ color: 'red' }}/>,
  },
  {
    title: "Can be Deposit Asset",
    dataIndex: "depositable",
    key: "depositable",
    align: "center",
    ...getBooleanFilterProps(),
    render: (text) => (text) ? <BsCheck style={{ color: 'green' }}/> : <BsX style={{ color: 'red' }}/>,
  },
  {
    title: "Used for Conversion",
    dataIndex: "major",
    key: "major",
    align: "center",
    ...getBooleanFilterProps(),
    render: (text) => (text) ? <BsCheck style={{ color: 'green' }}/> : <BsX style={{ color: 'red' }}/>,
  },
  {
    title: "Name for cTrader",
    dataIndex: "displayName",
    key: "displayName",
    ...getColumnSearchProps(),
    render: (text) => <p>{text || "-"}</p>,
  },
  {
    title: "Digits",
    dataIndex: "digits",
    key: "digits",
    ...getDigitsFilterProps(0, 10, 1),
    render: (text) => <p>{text || "-"}</p>,
  },
];
