import React from "react";
import {getColumnSearchProps} from "../../../../../components/table/function/filterTable";

export const columnsMT5 = [
    {
        title: "Name",
        dataIndex: "symbolNameMt5",
        key: "symbolNameMt5",
        sorter: true,
        ...getColumnSearchProps(),
        render: (text,record) => <p>{record?.name}</p>,
    },
    {
        title: "Description",
        dataIndex: "descriptionMt5",
        key: "descriptionMt5",
        sorter: true,
        ...getColumnSearchProps(),
        render: (text,record) => <p>{record?.description}</p>,
    },
    {
        title: "Base Symbol ID",
        dataIndex: "baseSymbolId",
        key: "baseSymbolId",
        align: "center",
        sorter: true,
        ...getColumnSearchProps(),
        render: (text) => <a>{text}</a>,
    },
];
