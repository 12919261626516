import React, {useEffect, useState} from "react";
import {Button, Divider, InputNumber, Row, Select, Switch} from "antd";
import {useTranslation} from "react-i18next";
import {getReferralSetting, useUpdateReferralSettingMutation} from "../../../../../../../api/graphql/referralSetting";
import useAuthorize from "../../../../../../../_common/authorize";

const ReferralSetting = (props) => {
    const {setCustomizeSubmitButton, close} = props
    const {t} = useTranslation()

    const {data} = getReferralSetting()
    const {updateReferralSetting} = useUpdateReferralSettingMutation()
    const {systemErrorNotification} = useAuthorize()
    const [input, setInput] = useState<any>()

    const onSubmit = () => {
       try{
           updateReferralSetting(input)
           close()
       }catch (e) {
           systemErrorNotification()
       }
    }

    useEffect(() => {
        setInput(data?.getReferralSetting)
    }, [data?.getReferralSetting])

    useEffect(() => {
        setCustomizeSubmitButton(
            <Button onClick={onSubmit} type={"primary"}>{t("common.submit")}</Button>
        )
    }, [input])

    return (
        <>
            <Divider/>
            <div style={{padding: 16}}>
                <Row justify={"space-between"}>
                    <label>{t("Referral Link")}</label>
                    <Switch
                        checked={input?.referralLink}
                        onChange={(value) => setInput({...input, referralLink: value})}
                    />
                </Row>
                <Row justify={"space-between"} className={"margin-top-0-75"}>
                    <label>{t("LIVE Referral Link")}</label>
                    <Switch
                        checked={input?.liveReferralLink}
                        onChange={(value) => setInput({...input, liveReferralLink: value})}
                    />
                </Row>
                <Row justify={"space-between"} className={"margin-top-0-75"}>
                    <label>{t("DEMO Referral Link")}</label>
                    <Switch
                        checked={input?.demoReferralLink}
                        onChange={(value) => setInput({...input, demoReferralLink: value})}
                    />
                </Row>
                <Row justify={"space-between"} className={"margin-top-0-75"}>
                    <label>{t("Referral Display Type")}</label>
                   <Select
                        style={{width: "115px"}}
                        options={[
                           {label: t("All Network"), value: "all"},
                           {label: t("Direct Client"), value: "directClient"},
                           {label: t("Level"), value: "level"},
                       ]}
                       value={input?.referralDisplayType}
                       defaultValue={"all"}
                       onChange={(value => setInput({...input, referralDisplayType: value}))}
                   />
                </Row>
                {
                    input?.referralDisplayType === "level" &&
                    <Row justify={"space-between"} className={"margin-top-0-75"}>
                    <label>{t("Level")}</label>
                        <InputNumber min={1} value={input?.level} style={{width: "115px"}} onChange={(value => setInput({...input, level: value}))} />
                    </Row>
                }
            </div>
        </>
    )
}

export default ReferralSetting