import React, {useEffect, useMemo, useState} from "react";
import {useSetRecoilState, useRecoilValue} from "recoil";
import {useTranslation} from "react-i18next";
import {Popconfirm, Space} from "antd";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import {CSS} from "@dnd-kit/utilities";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import {columnsCategory} from "../../../function/tableColumn_ReportingCategory";
import {useDeleteReportingCategory, useSortReportingCategory} from "../../../../../../api/graphql/reportingCategory";
import {reportingCategoryLoadingState, reportingCategoryState} from "../../../../_general/state";
import {notificationMessage} from "../../../../../../../recoil_state";
import {useReportingCategoryFunction} from "../../../function";
import {DndContext, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {arrayMove, SortableContext, useSortable, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {restrictToVerticalAxis} from "@dnd-kit/modifiers";
import useAuthorize from "../../../../../../_common/authorize";

const CategoriesTable = (props) => {
    const {data, totalCount, loading, setFilter, setLimit, limit, setOffset, setOrderBy, openEditModal, tableName, reportCategoryPermission} = props;
    const {t} = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const reportingCategory = useRecoilValue<any>(reportingCategoryState);
    const reportingCategoryLoading = useRecoilValue<any>(reportingCategoryLoadingState);

    const [isRequesting, setIsRequesting] = useState(false);
    const [draggable, setDraggable] = useState(false);
    const [dataSource, setDataSource] = useState<any>([]);

    const {getReportCategory} = useReportingCategoryFunction();
    const {sortReportingCategory} = useSortReportingCategory();
    const {deleteReportingCategory} = useDeleteReportingCategory();
    const {systemErrorNotification} = useAuthorize();

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize);
        setOffset(limit * (page - 1));
    };

    const handleDelete = async (id) => {
        try {
            setIsRequesting(true);
            const response = await deleteReportingCategory(id);
            const typename = response?.data?.deleteReportingCategory?.__typename;
            if (typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.data?.deleteReportingCategory?.errKey),
                    key: "deleteReportingCategory",
                });
            } else if (typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.data?.deleteReportingCategory?.msg),
                    key: "deleteReportingCategory",
                });
            } else {
                getReportCategory({isEnabled: null});
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Delete Category Successfully"),
                    key: "deleteReportingCategory",
                });
            }
        } catch (error) {
            systemErrorNotification();
        } finally {
            setIsRequesting(false);
        }
    };

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 1,
            },
        })
    );

    const onDragEnd = async ({active, over}) => {
        try {
            setIsRequesting(true);
            if (over && active.id !== over?.id) {
                const activeIndex = dataSource.findIndex((i) => i._id === active.id);
                const overIndex = dataSource.findIndex((i) => i._id === over?.id);
                let newList = arrayMove(dataSource, activeIndex, overIndex);
                let ids = newList?.map((i: any) => i?._id);
                setDataSource(newList);
                const response = await sortReportingCategory(ids);
                const typename = response?.data?.sortReportingCategory?.__typename;
                if (typename === "BaseError") {
                    setNotificationMessage({
                        status: "error",
                        title: "",
                        msg: t(response?.data?.sortReportingCategory?.errKey),
                        key: "sortReportingCategory",
                    });
                } else if (typename === "CrmTaskApproval") {
                    setNotificationMessage({
                        status: "info",
                        title: "",
                        msg: t(response?.data?.sortReportingCategory?.msg),
                        key: "sortReportingCategory",
                    });
                } else {
                    getReportCategory({isEnabled: null});
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: t("Sort Category Successfully"),
                        key: "sortReportingCategory",
                    });
                }
            }
        } catch (e) {
            systemErrorNotification();
        } finally {
            setIsRequesting(false);
        }
    };

    useEffect(() => {
        setDataSource(reportingCategory);
        if (reportingCategory?.length > 0) {
            setDraggable(true);
        }
    }, [reportingCategory]);

    const tableRow = (props) => {
        // if (updateLoading || props["data-row-key"] === defaultRank) {
        //     return <tr {...props} />;
        // }

        const {attributes, listeners, setNodeRef, transform, transition, isDragging} = useSortable({
            id: props["data-row-key"] ? props["data-row-key"] : "",
        });

        const style = {
            ...props.style,
            transform: CSS.Transform.toString(
                transform && {
                    ...transform,
                    scaleY: 1,
                }
            ),
            transition,
            cursor: "move",
            ...(isDragging
                ? {
                      position: "relative",
                      zIndex: 9999,
                  }
                : {}),
        };

        return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
    };

    const Table = useMemo(() => {
        return (
            <TableAntDesign
                data={dataSource}
                tableName={tableName}
                component={
                    draggable
                        ? {
                              body: {
                                  row: tableRow,
                              },
                          }
                        : undefined
                }
                columns={[
                    ...columnsCategory(),
                    {
                        title: t("common.action"),
                        key: "action",
                        width: 132,
                        render: (_, record: any) => (
                            <Space>
                                {reportCategoryPermission?.edit?.edit && (
                                    <a style={{color: "green"}} onClick={() => openEditModal(record)}>
                                        <AiOutlineEdit />
                                    </a>
                                )}
                                {reportCategoryPermission?.delete?.delete && !record?.isSystem && (
                                    <Popconfirm
                                        placement="left"
                                        description={t("Are you sure to delete this category?")}
                                        title={t(`Delete the Category`)}
                                        onConfirm={() => {
                                            handleDelete(record?._id);
                                        }}
                                        okText={t("Yes")}
                                        okType="danger"
                                        cancelText={t("No")}
                                    >
                                        <a>
                                            <AiOutlineDelete style={{color: "red"}} />
                                        </a>
                                    </Popconfirm>
                                )}
                            </Space>
                        ),
                    },
                ]}
                size={"small"}
                loading={loading || isRequesting || reportingCategoryLoading}
                filter={setFilter}
                order={setOrderBy}
                pagination={{
                    pageSize: limit,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                    total: totalCount,
                    showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
                }}
            />
        );
    }, [dataSource, loading, limit, totalCount, loading, isRequesting, reportingCategoryLoading]);

    return (
        <div className={"margin-top-0-75"}>
            {reportingCategory?.length > 0 && reportCategoryPermission?.edit?.edit ? (
                <DndContext onDragEnd={onDragEnd} sensors={sensors} modifiers={[restrictToVerticalAxis]}>
                    <SortableContext
                        items={dataSource?.length > 0 ? dataSource?.map((i) => i._id)?.filter((item) => item !== null) : []}
                        strategy={verticalListSortingStrategy}
                    >
                        {Table}
                    </SortableContext>
                </DndContext>
            ) : (
                <>{Table}</>
            )}
        </div>
    );
};

export default CategoriesTable;
