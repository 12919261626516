import React, {useState} from "react";
import DOMPurify from "dompurify";
import {getDynamicVariable} from "../../../../../../api/graphql/dynamicVariable";
import {getDynamicEmailComponent} from "../../../../../../api/graphql/dynamicEmailComponent";
import TemplatePreviewToolbar from "./templatePreviewToolbar";

const TemplatePreview = (props) => {
  const { plainHtml, language } = props;

  const [preview, setPreview] = useState("");
  const { variableData } = getDynamicVariable({ filter: null });
  const { componentData } = getDynamicEmailComponent({ filter: null });

  const sanitizeContent = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  const renderPreview = (html, language = "en") => {
    let result = html;

    const dynamicVariable = result
      ? result.match(/{{dynamicVariable:.*?}}/g)
      : null;
    if (dynamicVariable) {
      let imageVariable = variableData?.getDynamicVariable?.data?.filter(
        (item) => item.variableType === "image"
      );
      let textVariable = variableData?.getDynamicVariable?.data?.filter(
        (item) => item.variableType === "text"
      );

      for (const item of dynamicVariable) {
        var tempItem = item?.replace(/{{|}}/g, "");
        var targetName = tempItem?.split(":");

        var [isImage] = imageVariable?.filter(
          (item) => item?.name === targetName[1]
        );
        let [isText] = textVariable?.filter(
          (item) => item?.name === targetName[1]
        );

        if (isImage) {
          var targetImage = isImage?.fileObject?.find(
            (item) => item?.language === language
          );
          result = result?.replace(item, targetImage?.path || item);
        } else if (isText) {
          var targetVariable = isText?.textObject?.find(
            (item) => item?.language === language
          );
          result = result?.replace(item, targetVariable?.text || item);
        }
      }
    }
    setPreview(result);
  };

  return (
    <div className="template-preview-1">
      <TemplatePreviewToolbar
        handlePreview={() => renderPreview(plainHtml, language)}
      />
      <div
        className="preview"
        dangerouslySetInnerHTML={sanitizeContent(preview)}
      ></div>
    </div>
  );
};

export default TemplatePreview;
