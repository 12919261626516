import {useTranslation} from "react-i18next";

export default class FormErrors {
    static errorMessage(
        name,
        label,
        errors,
        touched,
        isSubmitted,
        externalErrorMessage = null
    ){

        const {t} = useTranslation()
        
        if(externalErrorMessage){
            return externalErrorMessage
        }

        if(!isSubmitted && !touched[name]){
            return null
        }

        const fieldErrors = errors[name]

        if (fieldErrors?.type === "required" && label) {
            return t("errorMsg.required.pleaseFillIn", {field: label})
        }

        return(
            fieldErrors?.[0]?.message ||
            fieldErrors?.message ||
            fieldErrors ||
            null
        )
    }
}