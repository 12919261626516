import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import {Button, Col, Form, Row} from "antd";
import {useSetRecoilState} from "recoil";

import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import yupFormSchema from "../../../../../../components/yup/yupFormSchema";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import RadioGroupFormItem from "../../../../../../components/form/radioGroupFormItem";
import {verticalFormLayout} from "../../../../../../components/form/formWrapper";
import {addCompanyEmail, updateCompanyEmail} from "../../../../../../api/graphql/companyEmail";
import useAuthorize from "../../../../../../_common/authorize";
import {notificationMessage} from "../../../../../../../recoil_state";
import SelectFormItem from "../../../../../../components/form/selectFormItem";
import InputNumberFormItem from "../../../../../../components/form/inputNumberFormItem";

const CompanyEmailForm = (props) => {
    const {mode, item, closeModal, getCompanyEmailData, emailTypeOptions, domainOptions} = props;
    const {t} = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {baseErrorChecking, systemErrorNotification} = useAuthorize();
    const [errMsg, setErrMsg] = useState("");
    const [loading, setLoading] = useState(false)

    const yupSchema = yup.object().shape({
        name: yupFormSchema.string("name", {
            required: true,
            requiredErrorMessage: "Name is a required field",
        }),
        senderName: yupFormSchema.string("senderName", {
            required: true,
            requiredErrorMessage: "Sender Name is a required field",
        }),
        // emailType: yupFormSchema.string("emailType", {
        //     required: false,
        //     // requiredErrorMessage: "Email Type is a required field",
        // }),
        emailDomain: yupFormSchema.string("emailDomain", {
            required: false,
            // requiredErrorMessage: "Email Domain is a required field",
        }),
        email: yupFormSchema.email("email", {
            required: true,
            requiredErrorMessage: "Sender Email is a required field",
            typeError: "Sender email is invalid"
        }),
        host: yupFormSchema.string("host", {
            required: true,
            requiredErrorMessage: "Host is a required field",
        }),
        port: yupFormSchema.number("port", {
            required: true,
            requiredErrorMessage: "Port is a required field",
        }),
        username: yupFormSchema.string("username", {
            required: true,
            requiredErrorMessage: "Username is a required field",
        }),
        password: yupFormSchema.string("password", {
            required: mode === "add",
            requiredErrorMessage: "Password is a required field",
        }),
        driver: yupFormSchema.string("driver"),
        isActive: yupFormSchema.boolean("isActive"),
    });

    const [initialValues] = useState(() => ({
        name: item?.name || "",
        senderName: item?.senderName || "",
        email: item?.email || "",
        emailType: item?.emailType?._id || "",
        emailDomain: item?.emailDomain || "",
        host: item?.host || "",
        port: item?.port,
        username: item?.username || "",
        password: "",
        isActive: !!item?.isActive,
        driver: "smtp",
    }));

    var form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
        defaultValues: initialValues,
    });

    const onCancel = () => {
        closeModal();
    };

    const onSubmit = async (values) => {
        try {
            setLoading(true)
            const response = mode === "add" ? await addCompanyEmail(values) : await updateCompanyEmail(item._id, values);
            if (mode === "add" && response?.addCompanyEmail.errMsg) {
                return await baseErrorChecking(response?.addCompanyEmail);
            } else if (mode === "edit" && response?.updateCompanyEmail.errMsg) {
                return await baseErrorChecking(response?.updateCompanyEmail);
            } else {
                getCompanyEmailData();
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: mode === "add" ? "Add Successfully" : "Update Successfully",
                    key: "companyEmailTypes",
                });
                setErrMsg("");
                closeModal();
                setLoading(false)
            }
        } catch (error: any) {
            systemErrorNotification();
        } finally {
            setLoading(false)
        }
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="form-1">
                {errMsg && <p style={{color: "red"}}>{errMsg}</p>}
                <>
                    <Row gutter={10}>
                        <Col xs={12}>
                            <InputFormItem
                                name={"name"}
                                label={t("Name")}
                                placeholder={t("Name")}
                                layout={verticalFormLayout}
                                required
                                disabled={mode === "view"}
                            />
                        </Col>
                        <Col xs={12}>
                            <RadioGroupFormItem
                                name={"isActive"}
                                label={t("Status")}
                                options={[
                                    {label: t("Active"), value: true},
                                    {label: t("Inactive"), value: false},
                                ]}
                                layout={verticalFormLayout}
                                required
                                disabled={mode === "view"}
                            />
                        </Col>
                    </Row>

                    <Row gutter={10}>
                        <Col xs={12}>
                            <InputFormItem
                                name={"senderName"}
                                label={t("Sender Name")}
                                placeholder={t("Sender Name")}
                                layout={verticalFormLayout}
                                required
                                disabled={mode === "view"}
                            />
                        </Col>
                        <Col xs={12}>
                            <InputFormItem
                                name={"email"}
                                label={t("Sender Email")}
                                placeholder={t("Email")}
                                layout={verticalFormLayout}
                                required
                                disabled={mode === "view"}
                            />
                        </Col>
                    </Row>

                    <Row gutter={10}>
                        <Col xs={12}>
                            <SelectFormItem
                                name={"emailType"}
                                label={t("Email Type")}
                                options={emailTypeOptions}
                                mode={"single"}
                                placeholder={t("Email Type")}
                            />
                        </Col>
                        <Col xs={12}>
                            <SelectFormItem
                                name={"emailDomain"}
                                label={t("Email Domain")}
                                options={domainOptions}
                                mode={"single"}
                                placeholder={t("Email Domain")}
                                required={form?.getValues("emailType") !== null && form?.getValues("emailType") !== undefined && form?.getValues("emailType") !== ""}
                            />
                        </Col>
                    </Row>

                    <Row gutter={10}>
                        <Col xs={12} style={{width: "100px"}}>
                            <InputNumberFormItem
                                name={"port"}
                                label={t("Port ")}
                                placeholder={t("Port")}
                                max={9999}
                                style={{width: "100%"}}
                                layout={verticalFormLayout}
                                required
                                disabled={mode === "view"}
                            />
                        </Col>
                        <Col xs={12} style={{width: "300px"}}>
                            <InputFormItem
                                name={"host"}
                                label={t("Host")}
                                placeholder={t("Host")}
                                required
                                layout={verticalFormLayout}
                                disabled={mode === "view"}
                            />
                        </Col>
                    </Row>

                    <Row gutter={10}>
                        <Col xs={12}>
                            <InputFormItem
                                name={"username"}
                                label={t("Username")}
                                placeholder={"Username"}
                                layout={verticalFormLayout}
                                required
                                disabled={mode === "view"}
                            />
                        </Col>
                        <Col xs={12}>
                            <InputFormItem
                                name={"password"}
                                label={t("Password")}
                                placeholder={"Password"}
                                layout={verticalFormLayout}
                                required
                                disabled={mode === "view"}
                            />
                        </Col>
                    </Row>
                </>

                <Form.Item className="text-align-right" style={{paddingTop: "12px"}}>
                    {onCancel && (
                        <Button onClick={onCancel} icon={<CloseOutlined />} className="button-cancel-1">
                            {t("common.cancel")}
                        </Button>
                    )}
                    {mode !== "view" && (
                        <Button loading={loading} onClick={form.handleSubmit(onSubmit)} icon={<SaveOutlined />} className="button-submit-1">
                            {t("common.submit")}
                        </Button>
                    )}
                </Form.Item>
            </form>
        </FormProvider>
    );
};

export default CompanyEmailForm;
