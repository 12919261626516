import React, {useState} from "react";
import {Badge} from "antd";

const OptionalRibbonOnCard = (props) => {
    const {
        children,
        showRibbon,
        text,
        color,
        className,
    } = props
    const [specialPaddingRight, setSpecialPaddingRight] = useState<any>(0);
    if (!showRibbon) {
        return children;
    }
    return (
        <Badge.Ribbon
            text={text}
            color={color}
            className={className}
            style={{ pointerEvents: "none"}}
        >
            <div
                // ref={(n:any) => {
                //     if (n) {
                //         setSpecialPaddingRight(n?.nextElementSibling.offsetWidth);
                //     }
                // }}
                // style={{"--verification-card-with-ribbon-padding-right": `${specialPaddingRight}px`}}
            >
                {children}
            </div>
        </Badge.Ribbon>
    );
}

export default OptionalRibbonOnCard