import React, { useEffect, useState } from "react"
import { useSetRecoilState } from "recoil";
import { notificationMessage } from "../../../../../../../../recoil_state";
import { useTranslation } from "react-i18next";
import useAuthorize from "../../../../../../../_common/authorize";
import { Button, Col, Input, Modal, Row, Select } from "antd";
import { useUpdateEwalletDepositMutation } from "../../../../../../../api/graphql/eWalletDeposit";
import { getUserFilter } from "../../../../../../../api/graphql/user";

const EWalletUpdateShiftUserModal = (props) => {
    const {taskDetail, open, setOpen} = props
    const {updateEwalletDeposit} = useUpdateEwalletDepositMutation()
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {systemErrorNotification} = useAuthorize();
    const {t} = useTranslation();
    
    const {data:userDropdown} = getUserFilter({filter: {userType: "client"}})
    const userDropdownOptions = userDropdown?.getUserFilter?.data?.filter(item => item.userType === "client")?.map(item => {
        return {
            value: item?._id?.toString(),
            label: `${item?.username} ${item.identificationNo? "- " + item.identificationNo + " " : ""}${item.email? "(" + item?.email + ")": ""}`
        }
    }) || []

    const [user, setUser] = useState<any>(taskDetail?.payload?.input?.user?._id)
    const [comment, setComment] = useState<any>("")

    useEffect(() => {
        setUser(taskDetail?.payload?.input?.user?._id)
    }, [taskDetail])

    const ewalletDepositShiftUser = async () => {
        try {
            const response = await updateEwalletDeposit(taskDetail?.payload?.input?._id, {
                user: user,
                comment: comment
            });
            if (response?.data?.updateEwalletDeposit?.__typename === "CrmTaskApproval") {
                setOpen(false)
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Shift User"),
                    key: "updateEwalletDeposit",
                });
            } else if (response?.data?.updateEwalletDeposit?.__typename === "BaseError") {
                setOpen(false)
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.data?.updateEwalletDeposit?.errKey),
                    key: "updateEwalletDeposit",
                });
            } else {
                setOpen(false)
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.data?.updateEwalletDeposit?.msg),
                    key: "updateEwalletDeposit",
                });
            }
        } catch (e) {
            setOpen(false)
            systemErrorNotification();
        }
    };

    return (
    <Modal
        title={t("Shift User")}
        open={open}
        onCancel={() => setOpen(false)}
        closable={true}
        footer={null}
        width={640}
        destroyOnClose
    >
        <Row style={{marginTop: "12px"}}>
            <Col span={5} >{t("User")} :</Col>
            <Col span={1}/>
            <Col span={17}>
                <Select
                    options={userDropdownOptions}
                    onChange={e=>{
                        setUser(e)
                    }}
                    value={user}
                    showSearch
                    optionFilterProp="label"
                    style={{width: "446px"}}
                />
            </Col>
        </Row>
        <Row style={{marginTop: "12px"}}>
            <Col span={5}>{t("Comment")} :</Col>
            <Col span={1}/>
            <Col span={17}>
                <Input
                    onChange={e=>{
                        setComment(e?.target?.value)
                    }}
                    value={comment}
                    style={{width: "446px"}}
                />
            </Col>
        </Row>
        <Row>
            <Col span={24} style={{textAlign: "right", marginTop: "15px"}}>
                <Button onClick={() => ewalletDepositShiftUser()}>
                    {t("Save")}
                </Button>
            </Col>
        </Row>
    </Modal>
    )
}

export default EWalletUpdateShiftUserModal