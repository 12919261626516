const configMap = {
    staging: {
        brandName: "excotrades",
        backendURL: "https://admin.worthycodes.com", // Staging
        graphqlURL: "https://admin.worthycodes.com/graphql", // Staging
        graphqlLoadURL: "https://admin.worthycodes.com/graphql",
        // nextradesWebSocket: "wss://socket2.nextrades.ai",// Staging
        // backendURL: "http://localhost:41960",
        // graphqlURL: "http://localhost:41960/graphql",
        // graphqlLoadURL: "http://localhost:41960/graphql",
        nextradesWebSocket: "wss://socket2.nextrades.ai",
        mt5WebSocket: "wss://xsocket.antospnc.app", // Production
        mt5TickSocket: "wss://tick.antospnc.app",
        instrumentsURL: "https://instruments.nextrades.ai/graphql",
        tinyMCEApiKey: "4vywrwhvzhrd3738e76mefu0srcziz2or7w2kbtxf2kln4g6", // it.overwatchs
        pspMerchant: "blueocean",
        tradingPlatform: "MT5",
        reportingBrandName: "exco",
    },
    excotrades: {
        brandName: "excotrades",
        backendURL: "https://admin.excotrades.app", // Production
        graphqlURL: "https://admin.excotrades.app/graphql", // Production
        graphqlLoadURL: "https://admin-load.excotrades.app/graphql",
        automationURL: "https://automation.excotrades.app",
        nextradesWebSocket: "wss://socket1.nextrades.ai", // Production
        mt5WebSocket: "ws://localhost:80", // Production
        mt5TickSocket: "ws://localhost:80",
        instrumentsURL: "https://instruments.nextrades.ai/graphql",
        tinyMCEApiKey: "8voj8bl08cburoty2jjl259wd2e28tx1ka5d2nid4dc9rc1a",
        pspMerchant: "excoglobal",
        tradingPlatform: "cTrader",
        reportingBrandName: "exco",
    },
    ridgewaymarkets: {
        brandName: "ridgewaymarkets",
        backendURL: "https://admin.ridgewaymarkets.app", // Production
        graphqlURL: "https://admin.ridgewaymarkets.app/graphql", // Production
        graphqlLoadURL: "https://admin-load.ridgewaymarkets.app/graphql",
        automationURL: "https://automation.ridgewaymarkets.app",
        nextradesWebSocket: "wss://socket2.nextrades.ai", // Production
        mt5WebSocket: "ws://localhost:80", // Production
        mt5TickSocket: "ws://localhost:80",
        instrumentsURL: "https://instruments.nextrades.ai/graphql",
        tinyMCEApiKey: "fpx5sjbtu103inuzsmom3ujwi2u9tqtjt1txc4978sr38wi7",
        pspMerchant: "ridgewaymarkets",
        tradingPlatform: "cTrader",
        reportingBrandName: "ridgewaymarkets",
    },
    alliumtrades: {
        brandName: "alliumtrades",
        backendURL: "https://admin.alliumtrades.app", // Production
        graphqlURL: "https://admin.alliumtrades.app/graphql", // Production
        graphqlLoadURL: "https://admin-load.alliumtrades.app/graphql",
        nextradesWebSocket: "wss://socket1.nextrades.ai", // Production
        mt5WebSocket: "ws://localhost:80", // Production
        mt5TickSocket: "ws://localhost:80",
        instrumentsURL: "https://instruments.nextrades.ai/graphql",
        tinyMCEApiKey: "h8gpt98fuf8ea4fycw4qpj8f340o337ju3b7jix82narmazy",
        pspMerchant: "alliumtrades",
        tradingPlatform: "cTrader",
        reportingBrandName: "alliumtrades",
    },
    metapromarkets: {
        brandName: "metapromarkets",
        backendURL: "https://admin.metapromarkets.app", // Production
        graphqlURL: "https://admin.metapromarkets.app/graphql", // Production
        graphqlLoadURL: "https://admin-load.metapromarkets.app/graphql",
        nextradesWebSocket: "wss://socket2.nextrades.ai", // Production
        mt5WebSocket: "ws://localhost:80", // Production
        mt5TickSocket: "ws://localhost:80",
        instrumentsURL: "https://instruments.nextrades.ai/graphql",
        tinyMCEApiKey: "s34eyulhienvbrgexconcheqz74s2qdcfa3m4zd6rjs436zj",
        pspMerchant: "metapromarkets",
        tradingPlatform: "cTrader",
        reportingBrandName: "metapro",
    },
    fundamarkets: {
        brandName: "fundamarkets",
        backendURL: "https://admin.fundamarkets.app",
        graphqlURL: "https://admin.fundamarkets.app/graphql",
        graphqlLoadURL: "https://admin-load.fundamarkets.app/graphql",
        automationURL: "https://automation.fundamarkets.app",
        nextradesWebSocket: "wss://socket2.nextrades.ai",
        mt5WebSocket: "ws://localhost:80",
        mt5TickSocket: "ws://localhost:80",
        instrumentsURL: "https://instruments.nextrades.ai/graphql",
        tinyMCEApiKey: "vhjf3v1qh5hsdegvi62cackut20jvzxebjyh7tp0mowja9zp",
        pspMerchant: "fundamarkets",
        tradingPlatform: "cTrader",
        reportingBrandName: "fundamarkets",
    },
    minosfutures: {
        brandName: "minosfutures",
        backendURL: "https://admin.minosmarkets.app", // Production
        graphqlURL: "https://admin.minosmarkets.app/graphql", // Production
        graphqlLoadURL: "https://admin-load.minosmarkets.app/graphql",
        automationURL: "https://automation.minosmarkets.app",
        nextradesWebSocket: "wss://socket1.nextrades.ai", // Production
        mt5WebSocket: "ws://localhost:80", // Production
        mt5TickSocket: "ws://localhost:80",
        instrumentsURL: "https://instruments.nextrades.ai/graphql",
        tinyMCEApiKey: "u6misbuo1ldsvhrledn79hp8q1el7cr59e2u7nypdz94xbmh",
        pspMerchant: "minosfutures",
        tradingPlatform: "cTrader",
        reportingBrandName: "minosfutures",
    },
    cronoscap: {
        brandName: "cronoscap",
        backendURL: "https://admin.cronoscap.app", // Production
        graphqlURL: "https://admin.cronoscap.app/graphql", // Production
        graphqlLoadURL: "https://admin-load.cronoscap.app/graphql",
        automationURL: "https://automation.cronoscap.app",
        nextradesWebSocket: "wss://socket2.nextrades.ai", // Production
        mt5WebSocket: "ws://localhost:80", // Production
        mt5TickSocket: "ws://localhost:80",
        instrumentsURL: "https://instruments.nextrades.ai/graphql",
        tinyMCEApiKey: "vhdvl94u3tlq1uk9wqfwybhfq5epkzdhpe5e4igmiybb941a",
        pspMerchant: "cronoscap",
        tradingPlatform: "cTrader",
        reportingBrandName: "cronoscap",
    },
    blueocean: {
        brandName: "blueocean",
        backendURL: "https://admin.blueoceanfin.app", // Production
        graphqlURL: "https://admin.blueoceanfin.app/graphql", // Production
        graphqlLoadURL: "https://admin-load.blueoceanfin.app/graphql",
        automationURL: "https://automation.blueoceanfin.app",
        arenaURL: "https://arena.blueoceanfin.app",
        nextradesWebSocket: "wss://socket2.nextrades.ai", // Production
        mt5WebSocket: "wss://xsocket.nextrades.ai", // Production
        mt5TickSocket: "wss://tick.nextrades.ai",
        // mt5WebSocket: "ws://localhost:80", // Production
        instrumentsURL: "https://instruments.nextrades.ai/graphql",
        tinyMCEApiKey: "mraprgjxsxttz1mrzxksm3b14o8lil75p6i00pdrfu2821oc",
        pspMerchant: "blueocean",
        tradingPlatform: "MT5",
        reportingBrandName: "blueocean",
    },
    antospncOld: {
        brandName: "antospnc",
        backendURL: "https://admin-old.antospnc.app", // Production
        graphqlURL: "https://admin-old.antospnc.app/graphql", // Production
        graphqlLoadURL: "https://admin-load-old.antospnc.app/graphql",
        automationURL: "https://automation.antospnc.app",
        arenaURL: "https://arena.antospnc.app",
        nextradesWebSocket: "wss://socket2.nextrades.ai", // Production
        mt5WebSocket: "wss://xsocket.antospnc.app",
        mt5TickSocket: "wss://tick.antospnc.app",
        instrumentsURL: "https://instruments.nextrades.ai/graphql",
        tinyMCEApiKey: "csc7l4jb3ju4l6cvjs0zi1l62rjvzmp0ld9xtyyq6ng5ujnz",
        pspMerchant: "antospnc",
        tradingPlatform: "MT5",
        reportingBrandName: "antospnc",
    },
    antospnc: {
        brandName: "antospnc",
        backendURL: "https://admin.antospnc.app", // Production
        graphqlURL: "https://admin.antospnc.app/graphql", // Production
        graphqlLoadURL: "https://admin-load.antospnc.app/graphql",
        automationURL: "https://automation.antospnc.app",
        arenaURL: "https://arena.antospnc.app",
        nextradesWebSocket: "wss://socket2.nextrades.ai", // Production
        mt5WebSocket: "wss://xsocket.antospnc.app",
        mt5TickSocket: "wss://tick.antospnc.app",
        instrumentsURL: "https://instruments.nextrades.ai/graphql",
        tinyMCEApiKey: "csc7l4jb3ju4l6cvjs0zi1l62rjvzmp0ld9xtyyq6ng5ujnz",
        pspMerchant: "antospnc",
        tradingPlatform: "MT5",
        reportingBrandName: "antospnc",
    },
    qvmarkets: {
        brandName: "qvmarkets",
        backendURL: "https://admin.qvmarkets.app", // Production
        graphqlURL: "https://admin.qvmarkets.app/graphql", // Production
        graphqlLoadURL: "https://admin-load.qvmarkets.app/graphql",
        nextradesWebSocket: "wss://socket2.nextrades.ai", // Production
        mt5WebSocket: "ws://localhost:80", // Production
        mt5TickSocket: "ws://localhost:80",
        instrumentsURL: "https://instruments.nextrades.ai/graphql",
        tinyMCEApiKey: "uic8b6l2dymyowftdjgs3hypq1d0wwytn1r1ddzn8s8nfmej",
        pspMerchant: "qvmarkets",
        tradingPlatform: "cTrader",
        reportingBrandName: "qvmarkets",
    },
    dearc: {
        brandName: "dearc",
        backendURL: "https://admin.dearc.app", // Production
        graphqlURL: "https://admin.dearc.app/graphql", // Production
        graphqlLoadURL: "https://admin.dearc.app/graphql",
        nextradesWebSocket: "wss://socket2.nextrades.ai", // Production
        mt5WebSocket: "wss://xsocket.nextrades.ai", // Production
        mt5TickSocket: "wss://tick.nextrades.ai",
        instrumentsURL: "https://instruments.nextrades.ai/graphql",
        tinyMCEApiKey: "4vywrwhvzhrd3738e76mefu0srcziz2or7w2kbtxf2kln4g6", // it.overwatchs
        pspMerchant: "dearc",
        tradingPlatform: "MT5",
        reportingBrandName: "dearc",
    },
    // Add more configuration mappings for different brand
};

const getConfigByName = (name) => {
    const config = configMap[name];
    if (config) {
        return config;
    } else {
        // Return a default configuration or handle the case when the name is not found
        return null;
    }
};

export {getConfigByName};
