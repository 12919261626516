import React, {useEffect} from "react";
import {Col, Form, Input, Row} from "antd";
import {useTranslation} from "react-i18next";
import {Checkbox} from "antd/lib";

const MarginPanelMt5 = ({initialData}) => {
    const {t} = useTranslation()
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(initialData);
    }, [initialData])

    return (
        <>
            <Row gutter={[16, 16]} style={{marginBottom: "1%", marginTop: "2%"}}>
                <Col span={20} offset={2}>
                    <p>
                        Please specify margin requirements for the symbols group.
                    </p>
                </Col>
            </Row>

            <Form form={form}>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={10} offset={2}>
                        <Form.Item
                            label={t("Use default margin values")}
                        >
                            <Checkbox disabled={true} defaultChecked={
                                initialData?.marginInitial === initialData?.marginInitialDefault &&
                                initialData?.marginHedged === initialData?.marginHedgedDefault &&
                                initialData?.marginMaintenance === initialData?.marginMaintenanceDefault
                            }/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={20} offset={2}>
                        <Form.Item
                            name={"marginInitial"}
                            label={t("Initial margin")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={20} offset={2}>
                        <Form.Item
                            name={"marginHedged"}
                            label={t("Hedged margin")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={20} offset={2}>
                        <Form.Item
                            name={"marginMaintenance"}
                            label={t("Maintenance margin")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={10} offset={2}>
                        <Form.Item
                            label={t("Use default margin settings")}
                        >
                            <Checkbox disabled={true} defaultChecked={initialData?.marginFlags === initialData?.marginFlagsDefault}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={10} offset={2}>
                        <Form.Item
                            label={t("Calculate hedged margin using larger leg")}
                        >
                            <Checkbox disabled={true} defaultChecked={initialData?.marginFlags === "MARGIN_FLAGS_HEDGE_LARGE_LEG" || initialData?.marginFlags === "MARGIN_FLAGS_ALL"}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={10} offset={2}>
                        <Form.Item
                            label={<span style={{color: "red"}}>{t("Exclude long position PnL from free margin and margin level")}</span>}
                        >
                            <Checkbox disabled={true} defaultChecked={initialData?.marginFlags === "MARGIN_FLAGS_ALL"}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={10} offset={2}>
                        <Form.Item
                            label={<span style={{color: "red"}}>{t("Recalculate margin exchange rate at the End of Day")}</span>}
                        >
                            <Checkbox disabled={true} defaultChecked={initialData?.marginFlags === "MARGIN_FLAGS_ALL"}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={10} offset={2}>
                        <Form.Item
                            label={t("Check before executing order")}
                        >
                            <Checkbox disabled={true} defaultChecked={initialData?.marginFlags === "MARGIN_FLAGS_CHECK_PROCESS" || initialData?.marginFlags === "MARGIN_FLAGS_ALL"}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={10} offset={2}>
                        <Form.Item
                            label={t("Check on SL-TP trigger")}
                        >
                            <Checkbox
                                disabled={true}
                                defaultChecked={initialData?.marginFlags === "MARGIN_FLAGS_CHECK_SLTP" || initialData?.marginFlags === "MARGIN_FLAGS_ALL"}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default MarginPanelMt5