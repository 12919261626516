import React, {useState} from "react";
import {Button, Row, Space, Table, Tooltip} from "antd";
import CreateUpdateModal from "./components/createUpdateModal";
import {leaderboardData} from "../../data/data";
import {Columns} from "../../function/columns";
import {BgColorsOutlined, DeleteOutlined, EditOutlined, EyeOutlined, PicLeftOutlined} from "@ant-design/icons";
import ColorSchemaForm from "./components/colorSchemaForm";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";

const LeaderboardSettings_1 = () => {
    const [isCreateUpdateModalOpen, setIsCreateUpdateModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null)
    const [defaultStep, setDefaultStep] = useState(0)
    // const [] = useState()
    const column = Columns()

    const handleModalOpen = (record, type?) => {
        setIsCreateUpdateModalOpen(true);
        setSelectedRecord(record)
        setDefaultStep(type === "color" ? 1 : 0)
    }

    const handleModalClose = (type?) => {
        setIsCreateUpdateModalOpen(false);
        setSelectedRecord(null)
    }

    return (
        <div id={"leaderboard-setting"}>
            <Row justify={"space-between"} align={"middle"} className={"margin-bottom-0-75"}>
                <h1>Leaderboard Setting</h1>
                <Button onClick={() => handleModalOpen(null)} type={"primary"}>Add Leaderboard</Button>
            </Row>

            <TableAntDesign
                tableName={"Leaderboard Setting"}
                data={leaderboardData}
                columns={[...column, {
                    title: "Action",
                    key: "action",
                    render: (_, record) => (
                        <Space>
                            <Tooltip title={"Edit"}>
                                <a onClick={() => handleModalOpen(record)}>
                                    <EditOutlined/>
                                </a>
                            </Tooltip>
                            <Tooltip title={"Color Schema"}>
                                <a onClick={() => handleModalOpen(record, "color")}>
                                    <BgColorsOutlined/>
                                </a>
                            </Tooltip>
                            <Tooltip title={"Edit UI"}>
                                <a onClick={() => window.open(`/settings/arena/leaderboard-ui-editor/${record?._id}`, "_blank")}>
                                    <PicLeftOutlined/>
                                </a>
                            </Tooltip>
                            <Tooltip title={"Delete"}>
                                <a style={{color: "red"}}>
                                    <DeleteOutlined/>
                                </a>
                            </Tooltip>
                        </Space>
                    )
                }]}
            />

            <CreateUpdateModal
                open={isCreateUpdateModalOpen}
                handleCancel={handleModalClose}
                item={selectedRecord}
                defaultStep={defaultStep}
            />
        </div>
    )
}

export default LeaderboardSettings_1;