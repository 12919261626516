import React from "react";
import {Tabs} from "antd";
import TabItems from "../../function/tabItems";
import {useTranslation} from "react-i18next";
import {useRecoilState, useRecoilValue} from "recoil";
import {currentTabState, tabBarExtraContentState} from "../../state";
import {selfPermissionObj} from "../../../../../../recoil_state";

const AccountVerification_1 = () => {
    const tabItem = TabItems()
    const {t} = useTranslation()
    const component = useRecoilValue(tabBarExtraContentState)
    const [currentTab, setCurrentTab] = useRecoilState<any>(currentTabState)

    return (
        <div id={"account-verification"}>
            <h1>{t("accountVerification.accountVerification")}</h1>
            <Tabs
                size={"small"}
                items={tabItem}
                defaultActiveKey={currentTab}
                tabBarExtraContent={component}
                onTabClick={(key) => setCurrentTab(key)}
            />
        </div>
    )
}

export default AccountVerification_1