import React, {useEffect, useState} from "react";
import {Button, Col, Collapse, Row, Skeleton, Space, Tag} from "antd";
import {CheckOutlined, CloseOutlined, DownOutlined, LoadingOutlined, UpOutlined, WarningOutlined} from "@ant-design/icons";

const UploadStatus = ({uploadList, display, close, media, fileStatus, folders, length, completedFiles}) => {
    const [expanded, setExpanded] = useState(true);
    const list = [...(uploadList || []), ...(completedFiles || [])]

    useEffect(() => {
        // console.log(uploadList)
    }, []);

    return (
        display &&
        <div className={"upload-status-container"}>
            <div className={`expanded-view ${expanded ? "" : "hidden"}`}>
                <Row justify={"space-between"} align={"middle"}>
                    <h1>Uploads</h1>
                    <Space>
                        <Button onClick={() => setExpanded(false)} type={"link"} icon={<DownOutlined/>}/>
                        <Button onClick={close} type={"link"} icon={<CloseOutlined/>}/>
                    </Space>
                </Row>

                <div className={"upload-list-container"}>
                    {
                        uploadList && uploadList.length > 0 && uploadList?.map(item => {
                            return (
                                <Row className={"upload-item"}>
                                    <Col span={16}>
                                        <p className={"upload-item-name"}>{item?.name}</p>
                                        <Tag color={"geekblue"} bordered={false}>{item?.type?.replace("image/", "")?.replace("application/", "")?.toUpperCase()}</Tag>
                                        <span className={"secondary-text"}><small>Uploading...</small></span>
                                    </Col>
                                    <Col span={8}></Col>
                                    <Col span={24}>
                                        <div className="loader"></div>
                                    </Col>
                                </Row>
                            )
                        })
                    }
                    {
                        completedFiles && completedFiles.length > 0 && completedFiles?.map(item => {
                            const found = media?.find(d => d?.displayName === item?.name || d?.name === item?.name)
                            const folder = found?.folder ? folders?.find(d => found?.folder === d?._id)?.name : "All Files"

                            return (
                                <Row className={"upload-item"}>
                                    <Col span={16}>
                                        <p className={"upload-item-name"}>{item?.displayName || item?.name}</p>
                                        <Tag color={"geekblue"} bordered={false}>{(item?.fileType)?.replace(".", "")?.toUpperCase()}</Tag>
                                        <span className={"secondary-text"}><small>Uploaded to {folder}</small></span>
                                    </Col>
                                    <Col span={8}></Col>
                                    <div style={{height: 6}}/>
                                </Row>
                            )
                        })
                    }
                </div>
            </div>
            <div className={"status-container"}>
                <Row align={"middle"}>
                    <Col flex={"40px"} className={`status-icon ${fileStatus}`}>
                        {fileStatus === "uploading" ? <LoadingOutlined /> : fileStatus === "success" ? <CheckOutlined/> : fileStatus === "failed" ? <CloseOutlined/> : <WarningOutlined/>}
                    </Col>
                    <Col flex={1}>
                        <div className={"margin-left-0-75"}>
                            <h1 className={"margin-bottom-0-3"}>Upload {fileStatus === "uploading" ? "Uploading" : fileStatus === "success" ? "Success" : fileStatus === "failed" ? "Failed" : "Success With Error"}!</h1>
                            <p>{length} Upload Complete</p>
                        </div>
                    </Col>
                    {
                        !expanded &&
                        <Col flex={"60px"} className={"text-align-right"}>
                            <Space>
                                <Button onClick={() => setExpanded(true)} type={"link"} icon={<UpOutlined/>}/>
                                <Button onClick={close} type={"link"} icon={<CloseOutlined/>}/>
                            </Space>
                        </Col>
                    }
                </Row>
            </div>
            {/*<Collapse*/}
            {/*    items={[*/}
            {/*        {*/}
            {/*            label: "Uploads",*/}
            {/*            key: "upload"*/}
            {/*        }*/}
            {/*    ]}*/}
            {/*    expandIcon={({isActive}) => isActive ? <DownOutlined/> : <UpOutlined/>}*/}
            {/*/>*/}
        </div>
    )
}

export default UploadStatus