import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Row, Space, Tabs, TabsProps, Upload} from "antd";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {useAddFollowUp} from "../../../../../../../api/graphql/followUp";
import TextAreaInputFormItem from "../../../../../../../components/form/textAreaInputFormItem";
import {verticalFormLayout} from "../../../../../../../components/form/formWrapper";
import DatePickerInputForm from "../../../../../../../components/form/datePickerFormItem";
import SelectFormItem from "../../../../../../../components/form/selectFormItem";
import useAuthorize from "../../../../../../../_common/authorize";
import {disableDateTime} from "../../../../../_common/function";
import * as yup from "yup";
import yupFormSchema from "../../../../../../../components/yup/yupFormSchema";
import {yupResolver} from "@hookform/resolvers/yup";
import {getConfigByName} from "../../../../../../../../config";
import {BRAND_NAME} from "../../../../../../../../config/brandVariable";
import {useGetAvailableEmailTemplate} from "../../../function/useQueryHooks";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import {formatDateTimeTimezone} from "../../../../../../../function/_common";

const ActionCard = (props) => {
    const {data, fetchFollowUp} = props
    const {t} = useTranslation()
    const {systemErrorNotification} = useAuthorize()
    const {addFollowUp} = useAddFollowUp()
    const [currentTab, setCurrentTab] = useState<any>("followUp");
    const [schema, setSchema] = useState<any>(null)
    const [fileList, setFileList] = useState<any>([])
    const {disabledDate} = disableDateTime()
    const brandConfig = getConfigByName(BRAND_NAME);
    const [submitLoading, setSubmitLoading] = useState(false)
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const labelCol = {span: 24, offset: 0}
    const [emailTemplate, fetchAvailableEmailTemplate] = useGetAvailableEmailTemplate()
    const emailTemplateOption = emailTemplate?.map((d) => ({label: d?.displayName, value: d?._id}));

    const form = useForm({
        resolver: yupResolver(schema),
        mode: "all",
    });

    useEffect(() => {
        fetchAvailableEmailTemplate({isEmailVerified: data?.verifiedEmail})
    }, [data?.verifiedEmail]);

    const onSubmit = async (value) => {
        try {
            setSubmitLoading(true)
            const {addFollowUp:response} = await addFollowUp(
                localStorage?.getItem("userId"),
                {
                    ...value,
                    followUpDateTime: value?.followUpDateTime ? formatDateTimeTimezone(value?.followUpDateTime, "formSubmit",  "YYYY-MM-DD HH:mm") : null,
                    followingUser: data?._id,
                    type: currentTab,
                    followingType: "users",
                    attachment: fileList?.map(d => d?.url || d?.response?.[0]?.link)
                }
            )

            if (response?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(addFollowUp?.errKey),
                    key: "addFollowUp",
                });
            } else if (response?.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(addFollowUp?.msg),
                    key: "addFollowUp",
                });
            } else {
                fetchFollowUp({followingUser: data?._id})

                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Create Follow Up Successfully"),
                    key: "addFollowUp",
                });
            }

            setFileList([])
            form.reset()
        } catch (e) {
            console.log(e)
            systemErrorNotification()
        } finally {
            setSubmitLoading(false)
        }
    }

    const items: TabsProps["items"] = [
        {
            label: t("user.followUp"),
            key: "followUp",
            children: (
                <FormProvider {...form}>
                    <form className="follow-up-form">
                        <Row
                            gutter={[16, 16]}
                            className={"margin-top-0-75 margin-bottom-0-75"}
                        >
                            <Col span={24}>
                                <Space direction={"vertical"} style={{width: "100%"}}>
                                    <TextAreaInputFormItem
                                        name={"description"}
                                        labelCol={labelCol}
                                        label={t("user.followUpDescription")}
                                        placeholder={t("user.followUpDescription")}
                                        layout={verticalFormLayout}
                                        rows={3}
                                        required
                                    />
                                    <DatePickerInputForm
                                        name={"followUpDateTime"}
                                        labelCol={labelCol}
                                        disabledDate={disabledDate}
                                        label={t("user.setUpFollowUpTime")}
                                        placeholder={""}
                                        layout={verticalFormLayout}
                                        format={"YYYY-MM-DD HH:mm"}
                                        showTime={{format: "HH:mm"}}
                                        getPopupContainer={triggerNode => triggerNode.parentElement}
                                    />
                                    <Form.Item
                                        label={t("common.attachments")}
                                        labelCol={{span: 24}}
                                        labelAlign={"left"}
                                    >
                                        <Upload
                                            action={`${brandConfig?.backendURL}/api/upload/followUp/document`}
                                            onChange={e => setFileList(e.fileList)}
                                            multiple
                                            fileList={fileList}
                                        >
                                            <Button>{t("common.upload")}</Button>
                                        </Upload>
                                    </Form.Item>
                                </Space>
                            </Col>
                            <Button
                                className={"margin-left-auto"}
                                onClick={form.handleSubmit(onSubmit)}
                                loading={submitLoading}
                            >
                                {t("common.submit")}
                            </Button>
                        </Row>
                    </form>
                </FormProvider>
            ),
        },
        {
            label: t("user.email"),
            key: "email",
            children: (
                <FormProvider {...form}>
                    <form className="form-1">
                        <Row
                            gutter={[16, 16]}
                            className={"margin-top-0-75 margin-bottom-0-75"}
                        >
                            <Col span={24}>
                                <Space direction={"vertical"} style={{width: "100%"}}>
                                    <SelectFormItem
                                        name={"emailTemplateId"}
                                        label={t("common.emailTemplate")}
                                        labelCol={labelCol}
                                        placeholder={t("common.emailTemplate")}
                                        layout={verticalFormLayout}
                                        options={emailTemplateOption}
                                        optionFilterProp={"label"}
                                        showSearch
                                        required
                                    />
                                    <DatePickerInputForm
                                        name={"followUpDateTime"}
                                        label={t("user.setUpFollowUpTime")}
                                        placeholder={""}
                                        disabledDate={disabledDate}
                                        labelCol={labelCol}
                                        layout={verticalFormLayout}
                                        format={"YYYY-MM-DD HH:mm"}
                                        showTime={{format: "HH:mm"}}
                                        getPopupContainer={triggerNode => triggerNode.parentElement}
                                    />
                                </Space>
                            </Col>
                            <Button
                                className={"margin-left-auto"}
                                onClick={form.handleSubmit(onSubmit)}
                                loading={submitLoading}
                            >
                                {t("common.send")}
                            </Button>
                        </Row>
                    </form>
                </FormProvider>
            ),
        },
    ];

    const settingSchema = () => {
        if (currentTab === "email") {
            setSchema(yup.object().shape({
                emailTemplateId: yupFormSchema.string("emailTemplateId", {required: true}),
                followUpDateTime: yupFormSchema.string("followUpDateTime"),
            }))
        } else {
            setSchema(yup.object().shape({
                description: yupFormSchema.string("description", {required: true}),
                followUpDateTime: yupFormSchema.string("followUpDateTime"),
            }))
        }
    }

    useEffect(() => {
        settingSchema()
    }, [currentTab])

    return (
        <Card style={{width: "100%"}}>
            <h3>{t("common.actionToTake")}</h3>
            <Tabs
                size={"small"}
                items={items}
                onTabClick={(tab) => {
                    setCurrentTab(tab);
                    form.reset();
                }}
            />
        </Card>
    )
}

export default ActionCard