import React from "react";
import {Image} from "antd";
import {getColumnSearchProps} from "../../../../components/table/function/filterTable";
import {FilePdfOutlined, FontSizeOutlined} from "@ant-design/icons";

export const columns = [
    {
        title: "Variable Name",
        dataIndex: "name",
        key: "name",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Preview",
        dataIndex: "variableType",
        key: "file",
        width: 200,
        render: (text, record) => {
            let component;
            switch (record?.variableType) {
                case "image":
                    let imagePath = record?.fileObject && record?.fileObject[0]?.path;
                    component = imagePath ? <Image className="previewImage" src={imagePath} alt={text} /> : null;
                    break;
                case "pdf":
                    // component = <span><i className="fa fa-file-pdf-o" aria-hidden="true"></i></span>;
                    component = <FilePdfOutlined className="previewIcon" />;
                    break;
                case "text":
                    component = <FontSizeOutlined className="previewIcon" />;
                    break;
            }
            return component;
        },
    },
    {
        title: "Variable Type",
        dataIndex: "variableType",
        key: "variableType",
        filters: [
            {text: "Image", value: "image"},
            {text: "Text", value: "text"},
            {text: "PDF", value: "pdf"},
        ],
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Status",
        dataIndex: "isActive",
        key: "isActive",
        filters: [
            {text: "Active", value: true},
            {text: "Inactive", value: false},
        ],
        filterMultiple: false,
        render: (isActive) => <p>{isActive ? "Active" : "Inactive"}</p>,
    },
];
