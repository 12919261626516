import React, {useEffect, useMemo, useState} from "react";
import {useRecoilState, useSetRecoilState} from "recoil";
import {Card, Divider, Spin} from "antd";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import yupFormSchema from "../../../../../../../../components/yup/yupFormSchema";
import SelectFormItem from "../../../../../../../../components/form/selectFormItem";
import {horizontalFormLayout} from "../../../../../../../../components/form/formWrapper";
import {useTranslation} from "react-i18next";
import SwitchFormItem from "../../../../../../../../components/form/switchFormItem";
import {notificationMessage, selfPermissionObj,} from "../../../../../../../../../recoil_state";
import useAuthorize from "../../../../../../../../_common/authorize";
import {
    updateTradingAccountMt5,
    updateTradingAccountMt5Leverage,
    updateTradingAccountMt5SpreadGroup
} from "../../../../../../../../api/graphql/tradingAccountMt5";
import CheckboxFormItem from "../../../../../../../../components/form/checkboxFormItem";
import CheckboxGroupFormItem from "../../../../../../../../components/form/checkboxGroupFromItem";

const AccountMt5SettingCard = (props) => {
    const {data} = props;

    const [toggleLoading, setToggleLoading] = useState(false)
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const tradingAccountPermission = selfPermission?.tradingAccountMt5?.account;
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {systemErrorNotification} = useAuthorize()
    const {t} = useTranslation();

    const labelCol = {span: 12, offset: 0};

    const spreadGroupOptions = data?.product?.spreadGroupMt5?.map((d, i) => {
        return {
            value: d?._id || "",
            label: d?.group || "",
        };
    });

    const leverageListing = data?.product?.leverage?.map((d, i) => {
        return {
            value: d || "",
            label: d || "",
        };
    });

    const rightsListing = useMemo(() => {
        let listing = [
            {label: t("tradingAccount.mt5RightsOption.USER_RIGHT_ENABLED"), value: "USER_RIGHT_ENABLED"},
            {label: t("tradingAccount.mt5RightsOption.USER_RIGHT_PASSWORD"), value: "USER_RIGHT_PASSWORD"},
            {label: t("tradingAccount.mt5RightsOption.USER_RIGHT_OTP_ENABLED"), value: "USER_RIGHT_OTP_ENABLED"},
            {label: t("tradingAccount.mt5RightsOption.USER_RIGHT_RESET_PASS"), value: "USER_RIGHT_RESET_PASS"},
            {label: t("tradingAccount.mt5RightsOption.USER_RIGHT_TRADE_DISABLED_OPP"), value: "USER_RIGHT_TRADE_DISABLED", opposite: true}, // For Investor Account - shown but disabled
            {label: t("tradingAccount.mt5RightsOption.USER_RIGHT_EXPERT"), value: "USER_RIGHT_EXPERT", isSub: true, parent: "USER_RIGHT_TRADE_DISABLED"},  // For Investor Account - shown but disabled
            {label: t("tradingAccount.mt5RightsOption.USER_RIGHT_TRAILING"), value: "USER_RIGHT_TRAILING", isSub: true, parent: "USER_RIGHT_TRADE_DISABLED"},  // For Investor Account - shown but disabled
            {label: t("tradingAccount.mt5RightsOption.USER_RIGHT_REPORTS"), value: "USER_RIGHT_REPORTS"},
            {label: t("tradingAccount.mt5RightsOption.USER_RIGHT_API_ENABLED"), value: "USER_RIGHT_API_ENABLED"},
            {label: t("tradingAccount.mt5RightsOption.USER_RIGHT_SPONSORED_HOSTING"), value: "USER_RIGHT_SPONSORED_HOSTING"},
        ]
        if (data?.product?.productType?.includes("investor")) {
            // let rightsForInvestor = ["USER_RIGHT_ENABLED", "USER_RIGHT_TRADE_DISABLED", "USER_RIGHT_EXPERT", "USER_RIGHT_TRAILING", "USER_RIGHT_REPORTS"] 
            let rightsForInvestor = ["USER_RIGHT_ENABLED", "USER_RIGHT_REPORTS"]
            listing = listing?.filter(v => {
                return rightsForInvestor?.includes(v?.value)
            })
        }
        return listing;
    },[data])

    const yupSchema = yup.object().shape({
        spreadGroup: yupFormSchema.string("spreadGroup", {required: true}),
        leverage: yupFormSchema.string("leverage", {required: true}),
        hasTradingPermission: yupFormSchema.boolean("hasTradingPermission", {
            required: false,
        }),
        isLoginEnabled: yupFormSchema.boolean("isLoginEnabled", {
            required: false,
        }),
        isSuspicious: yupFormSchema.boolean("isSuspicious", {required: false}),
        hasWithdrawalPermission: yupFormSchema.boolean("hasWithdrawalPermission", {required: false}),
        hasDepositPermission: yupFormSchema.boolean("hasDepositPermission", {required: false}),
    });

    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
    });

    const onSubmit = async (value) => {
        try {
            await setToggleLoading(true)
            let rights
            if ( value?.mt5Rights?.length > 0 && value?.mt5Rights?.includes("USER_RIGHT_TRADE_DISABLED")) {
                rights = value?.mt5Rights?.filter(d => d !== "USER_RIGHT_TRADE_DISABLED")
            } else {
                rights = [...value?.mt5Rights, "USER_RIGHT_TRADE_DISABLED"]
            }

            const response = await updateTradingAccountMt5(data?._id, {
                user: data?.user?._id,
                isSuspicious: value?.isSuspicious,
                isDefault: value?.isDefault,
                isPrivate: value?.isPrivate,
                mt5Rights: rights,
                hasDepositPermission: value?.hasDepositPermission,
                hasWithdrawalPermission: value?.hasWithdrawalPermission,
            });
            // await refreshQuery()

            if (response?.updateTradingAccountMt5.__typename === "TradingAccount") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("common.updateSuccess"),
                    key: "updateTradingAccountMt5",
                });
            } else if (response?.updateTradingAccountMt5.__typename === "BaseError") {
                resetFormFields()
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.updateTradingAccountMt5?.errKey),
                    key: "updateTradingAccountMt5",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.updateTradingAccountMt5?.msg),
                    key: "updateTradingAccountMt5",
                });
            }
            setToggleLoading(false)
        } catch (e) {
            setToggleLoading(false)
            systemErrorNotification()
        }
    };

    const onSubmitLeverage = async (value) => {
        try {
            await setToggleLoading(true)
            const response = await updateTradingAccountMt5Leverage(data?._id, {
                user: data?.user?._id,
                leverage: value?.leverage,
            });
            // await refreshQuery()

            if (response?.updateTradingAccountMt5Leverage.__typename === "TradingAccount") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("common.updateSuccess"),
                    key: "updateTradingAccountMt5Leverage",
                });
            } else if (response?.updateTradingAccountMt5Leverage.__typename === "BaseError") {
                resetFormFields()
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.updateTradingAccountMt5Leverage?.errKey),
                    key: "updateTradingAccountMt5Leverage",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.updateTradingAccountMt5Leverage?.msg),
                    key: "updateTradingAccountMt5Leverage",
                });
            }
            setToggleLoading(false)
        } catch (e) {
            setToggleLoading(false)
            systemErrorNotification()
        }
    };

    const onSubmitSpreadGroup = async (value) => {
        try {
            await setToggleLoading(true)
            const response = await updateTradingAccountMt5SpreadGroup(data?._id, {
                user: data?.user?._id,
                spreadGroup: value?.spreadGroup,
            });
            // await refreshQuery()

            if (response?.updateTradingAccountMt5SpreadGroup.__typename === "TradingAccount") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("common.updateSuccess"),
                    key: "updateTradingAccountMt5SpreadGroup",
                });
            } else if (response?.updateTradingAccountMt5SpreadGroup.__typename === "BaseError") {
                resetFormFields()
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.updateTradingAccountMt5SpreadGroup?.errKey),
                    key: "updateTradingAccountMt5SpreadGroup",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.updateTradingAccountMt5SpreadGroup?.msg),
                    key: "updateTradingAccountMt5SpreadGroup",
                });
            }
            setToggleLoading(false)
        } catch (e) {
            setToggleLoading(false)
            systemErrorNotification()
        }
    };

    const resetFormFields = () => {
        if (data?.mt5Rights && data?.mt5Rights?.includes("USER_RIGHT_NONE")) {
            form.reset({...data, spreadGroup: data?.mt5SpreadGroup?._id, mt5Rights: []})
        } else if (data?.mt5Rights?.includes("USER_RIGHT_TRADE_DISABLED")) {
            form.reset({...data, spreadGroup: data?.mt5SpreadGroup?._id, mt5Rights: data?.mt5Rights?.filter(d => d !== "USER_RIGHT_TRADE_DISABLED")})
        } else {
            form.reset({...data, spreadGroup: data?.mt5SpreadGroup?._id, mt5Rights: [...data?.mt5Rights, "USER_RIGHT_TRADE_DISABLED"]})
        }
    }

    useEffect(() => {
        resetFormFields()
    }, [data])

    const isEditable = tradingAccountPermission?.edit?.edit;
    const isEditableSpreadGroup = tradingAccountPermission?.edit?.spreadGroup;
    const isEditableLeverage = tradingAccountPermission?.edit?.leverage;

    return (
        <div id="AccountSettingCard">
            <Spin tip="Loading..." spinning={!data?._id}>
                <Card>
                    <h4>{t("module.accountSetting")}</h4>

                    <FormProvider {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)} className="form-1">
                            {
                                tradingAccountPermission?.view?.spreadGroup && (
                                    <SelectFormItem
                                        name={"spreadGroup"}
                                        label={t("tradingAccount.spread_group")}
                                        placeholder={t("tradingAccount.spread_group")}
                                        layout={horizontalFormLayout}
                                        options={spreadGroupOptions}
                                        mode={"single"}
                                        labelCol={labelCol}
                                        labelAlign={"left"}
                                        onChange={form.handleSubmit(onSubmitSpreadGroup)}
                                        value={data?.mt5SpreadGroup?._id}
                                        disabled={!isEditableSpreadGroup || toggleLoading}
                                        loading={toggleLoading}
                                    />
                                )
                            }
                            {
                                tradingAccountPermission?.view?.leverage && (
                                    <SelectFormItem
                                        name={"leverage"}
                                        label={t("tradingAccount.leverage")}
                                        placeholder={t("tradingAccount.leverage")}
                                        layout={horizontalFormLayout}
                                        options={leverageListing}
                                        mode={"single"}
                                        labelCol={labelCol}
                                        labelAlign={"left"}
                                        onChange={form.handleSubmit(onSubmitLeverage)}
                                        value={data?.leverage}
                                        disabled={!isEditableLeverage || toggleLoading}
                                        loading={toggleLoading}
                                    />
                                )
                            }
                            {
                                (tradingAccountPermission?.view?.spreadGroup || tradingAccountPermission?.view?.leverage) &&
                                <Divider/>
                            }

                            {/*<SelectFormItem*/}
                            {/*    name={"mt5Rights"}*/}
                            {/*    label={t("tradingAccount.rights")}*/}
                            {/*    placeholder={t("tradingAccount.rights")}*/}
                            {/*    layout={horizontalFormLayout}*/}
                            {/*    options={rightsListing}*/}
                            {/*    mode={"multiple"}*/}
                            {/*    labelCol={labelCol}*/}
                            {/*    labelAlign={"left"}*/}
                            {/*    onChange={form.handleSubmit(onSubmit)}*/}
                            {/*    value={mt5Rights}*/}
                            {/*    disabled={!isEditable || toggleLoading}*/}
                            {/*    loading={toggleLoading}*/}
                            {/*/>*/}
                            <CheckboxGroupFormItem
                                name={"mt5Rights"}
                                options={rightsListing}
                                value={data?.mt5Rights}
                                onChange={form.handleSubmit(onSubmit)}
                                loading={toggleLoading}
                                disabled={!isEditable || toggleLoading}
                            />
                            <Divider/>
                            <CheckboxFormItem
                                name={"isDefault"}
                                text={t("tradingAccount.is_default")}
                                onChange={form.handleSubmit(onSubmit)}
                                value={data?.isDefault || false}
                                disabled={!isEditable || toggleLoading}
                            />

                            <CheckboxFormItem
                                name={"isPrivate"}
                                text={t("tradingAccount.is_private")}
                                onChange={form.handleSubmit(onSubmit)}
                                value={data?.isPrivate || false}
                                disabled={!isEditable || toggleLoading}
                            />

                            <CheckboxFormItem
                                name={"isSuspicious"}
                                text={t("tradingAccount.is_suspicious")}
                                onChange={form.handleSubmit(onSubmit)}
                                value={data?.isSuspicious || false}
                                disabled={!isEditable || toggleLoading}
                            />

                            <CheckboxFormItem
                                name={"hasDepositPermission"}
                                text={t("tradingAccount.is_deposit_enabled")}
                                onChange={form.handleSubmit(onSubmit)}
                                value={data?.hasDepositPermission || false}
                                disabled={!isEditable || toggleLoading}
                            />

                            <CheckboxFormItem
                                name={"hasWithdrawalPermission"}
                                text={t("tradingAccount.is_withdrawal_enabled")}
                                onChange={form.handleSubmit(onSubmit)}
                                value={data?.hasWithdrawalPermission || false}
                                disabled={!isEditable || toggleLoading}
                            />
                        </form>
                    </FormProvider>
                </Card>
            </Spin>
        </div>
    );
};

export default AccountMt5SettingCard;
