import {atom} from "recoil";

const userOptionsState = atom({
    key: "userOptions",
    default: [],
});

const userNetworkOptionsState = atom({
    key: "userNetworkOptions",
    default: [],
});

const salesRepNetworkOptionsState = atom({
    key: "salesRepNetworkOptions",
    default: [],
});

const deskOptionsState = atom({
    key: "deskOptions",
    default: [],
});

const deskNetworkOptionsState = atom({
    key: "deskNetworkOptions",
    default: [],
});

const rankOptionsState = atom({
    key: "rankNetworkOptions",
    default: [],
});

const roleOptionsState = atom({
    key: "roleNetworkOptions",
    default: [],
});

const brandDetailsState = atom({
    key: "brandDetail",
    default: [],
});

const clientProfileInformationSettingState = atom({
    key: "clientProfileInformationSetting",
    default: [],
});

const languageOptionsState = atom({
    key: "languageOptionsState",
    default: [],
});

const newsletterOptionsState = atom({
    key: "newsletterOptionsState",
    default: [],
});

const brandDomainOptionsState = atom({
    key: "brandDomainOptionsState",
    default: [],
});

const arenaMediaFileState = atom({
    key: "arenaMediaFileState",
    default: [],
})

const arenaMediaFolderState = atom({
    key: "arenaMediaFolderState",
    default: [],
})

export {
    userOptionsState,
    userNetworkOptionsState,
    salesRepNetworkOptionsState,
    deskOptionsState,
    deskNetworkOptionsState,
    rankOptionsState,
    roleOptionsState,
    brandDetailsState,
    clientProfileInformationSettingState,
    languageOptionsState,
    newsletterOptionsState,
    brandDomainOptionsState,
    arenaMediaFileState,
    arenaMediaFolderState
};
