
function convertCsvToArray(data) {
    try {
        const test = data?.replace(/\r\n|\n\r|\r/g, "{row}").split("{row}")
        const rowKey = test?.shift()?.split(",")
        const result = test?.map(item => {
            const tempItem = item?.split(",")
            var rows: any = {}
            tempItem?.forEach((value, index) => {
                if (value && value !== "") {
                    rows[rowKey[index]] = value
                }
            })
            return rows
        })
    return result?.slice(0, -1)
    } catch (error) {
        console.log(error)        
    }
  }

export {
    convertCsvToArray
}