import { Form, Input } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import FormErrors from './formErrors';

const InputFormItem2 = (props) => {
    const {
        layout,
        label,
        name,
        placeholder,
        prefix,
        value,
        type,
        externalErrorMessage,
        required,
        onChange,
        disabled
    } = props

    const { 
        formState: { touchedFields, isSubmitted, errors }
    } = useFormContext()

    const errorMessage = FormErrors.errorMessage(
        name,
        errors,
        touchedFields,
        isSubmitted,
        externalErrorMessage
    )
    return (
        <Form.Item
            {...layout}
            label={label}
            required={required}
            validateStatus={errorMessage?'error':'success'}
            help={errorMessage}
        >
            <Input
                id={name}
                type={type}
                value={value}
                onChange={(e)=>{
                    onChange && onChange(e?.target?.value)
                }}
                placeholder={placeholder}
                prefix={prefix}
                disabled={disabled}
            />
        </Form.Item>
    )
}

InputFormItem2.defaultProps = {
    type: 'text',
    required: false,
    disabled: false,
}

InputFormItem2.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    prefix: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
    externalErrorMessage: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    layout: PropTypes.object,
}

export default InputFormItem2