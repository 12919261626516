import { gql, useMutation, useQuery } from "@apollo/client";
import { useSetRecoilState } from "recoil";
import { errorMessage } from "../../../recoil_state";
import { ApolloClientQuery } from "./_apolloClient";

const GET_SERVER_SETTING_COUNT = gql`
  query getServerSettingCount($filter: ServerSettingSearchInput) {
    getServerSettingCount(filter: $filter)
  }
`;

const getServerSettingCount = ({ filter }) => {
  try {
    const { loading, error, data } = useQuery(GET_SERVER_SETTING_COUNT, {
      variables: {
        filter: {
          name: filter?.name || null,
          mode: filter?.mode || null,
          serverStatus: filter?.serverStatus || null,
        },
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_SERVER_SETTING_BY_ID = gql`
  query getServerSettingByID($id: String!) {
    getServerSettingByID(id: $id) {
      ... on ServerSetting {
        id
        name
        mode
        ip
        port
        timezone
        serverStatus
        platform
        proxy
        proxyName
        plantId
        brokerName
        username
        environment
        isActive
        whiteLabel
        initiateFirstSetup
        firstTimeFetchDataCompleted
        resetPasswordLink
        createdBy {
          username
        }
        updatedBy {
          username
        }
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getServerSettingById = (id) => {
  try {
    const { loading, error, data } = useQuery(GET_SERVER_SETTING_BY_ID, {
      variables: {
        id: id,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_SERVER_SETTING = gql`
  query getServerSetting(
    $filter: ServerSettingSearchInput
    $limit: Int
    $offset: Int
    $orderBy: String
  ) {
    getServerSetting(
      filter: $filter
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      ... on ServerSettingArray {
        data {
          _id
          id
          name
          mode
          ip
          port
          timezone
          serverStatus
          platform
          platformDisplayName
          platformAccountNo
          fieldForTrading{
            tradingKey
            clientField
          }
          proxy
          proxyName
          plantId
          whiteLabel
          brokerName
          environment
          isActive
          initiateFirstSetup
          firstTimeFetchDataCompleted
          resetPasswordLink
          username
          createdBy {
            username
          }
          updatedBy {
            username
          }
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getServerSetting = ({ filter }, limit?, offset?, orderBy?) => {
  try {
    const { loading, error, data } = useQuery(GET_SERVER_SETTING, {
      variables: {
        filter: {
          name: filter?.name || null,
          mode: filter?.mode || null,
          serverStatus: filter?.serverStatus || null,
        },
        limit: limit || null,
        offset: offset || null,
        orderBy: orderBy || null,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_SERVER_SETTING_LOADING_STATUS = gql`
  query getServerSettingLoadingStatus(
    $serverId: String!
  ) {
    getServerSettingLoadingStatus(
      serverId: $serverId
    ) {
      ... on ServerSettingLoadingStatus {
        spreadGroupLoading
        spreadGroupLastUpdatedDate
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;


const getServerSettingLoadingStatus = async (serverId) => {
  try {
      if(serverId){
        const execute = await ApolloClientQuery(
          GET_SERVER_SETTING_LOADING_STATUS,
            {
              serverId: serverId,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );
  
        return execute;
      }
  } catch (err: any) {
      console.error(err);
      return {data: null, loading: false, error: err};

  }
};

const ADD_SERVER_SETTING = gql`
  mutation addServerSetting($input: ServerSettingInput) {
    addServerSetting(input: $input) {
      ... on CrmTaskApproval {
        msg
      }
      ... on ServerSetting {
        id
        name
        mode
        ip
        port
        timezone
        serverStatus
        platform
        platformDisplayName
        platformAccountNo
        fieldForTrading{
          tradingKey
          clientField
        }
        proxy
        proxyName
        plantId
        environment
        whiteLabel
        username
        brokerName
        isActive
        initiateFirstSetup
        firstTimeFetchDataCompleted
        resetPasswordLink
        createdBy {
          username
        }
        updatedBy {
          username
        }
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useAddServerSettingMutation = (): {
  addServerSetting: any;
  data: any;
  addServerLoading: boolean;
  error: any;
} => {
  try {
    const setErrorMessage = useSetRecoilState(errorMessage);
    const [addServerSettingMutation, { data, loading, error }] = useMutation(
      ADD_SERVER_SETTING,
      {
        update(cache, { data: { addServerSetting } }) {
          let typename = addServerSetting?.__typename;
          if (typename === "CrmTaskApproval" || typename === "BaseError") {
            setErrorMessage(addServerSetting);
          } else {
            cache.modify({
              fields: {
                getServerSetting(existingData = []) {
                  const newRef = cache.writeFragment({
                    data: addServerSetting,
                    fragment: gql`
                      fragment NewServerSetting on ServerSetting {
                        id
                        name
                        mode
                        ip
                        port
                        timezone
                        serverStatus
                        platform
                        platformDisplayName
                        platformAccountNo
                        fieldForTrading{
                          tradingKey
                          clientField
                        }
                        proxy
                        proxyName
                        plantId
                        whiteLabel
                        environment
                        username
                        brokerName
                        isActive
                        initiateFirstSetup
                        firstTimeFetchDataCompleted
                        resetPasswordLink
                        createdBy {
                          username
                        }
                        updatedBy {
                          username
                        }
                        createdAt
                        updatedAt
                      }
                    `,
                  });
                  return {
                    ...existingData,
                    data:
                      existingData?.data?.length > 0
                        ? [newRef, ...existingData.data]
                        : [newRef],
                  };
                },
                getServerSettingCount(existingData) {
                  return existingData + 1;
                },
              },
            });
          }
        },
      }
    );

    const addServerSetting = (input) => {
      return addServerSettingMutation({
        variables: {
          input: {
            name: input.name || null,
            mode: input.mode || null,
            ip: input.ip || null,
            port: input.port || null,
            timezone: input.timezone || null,
            proxy: input.proxy || null,
            serverStatus: input.serverStatus || null,
            platform: input.platform || null,
            platformDisplayName: input.platformDisplayName || null,
            platformAccountNo: input.platformAccountNo ? parseInt(input.platformAccountNo) : 0,
            fieldForTrading: input.fieldForTrading || [],
            proxyName: input.proxyName || null,
            whiteLabel: input.whiteLabel || null,
            plantId: input.plantId || null,
            environment: input.environment || null,
            brokerName: input.brokerName || null,
            username: input.username || null,
            password: input.password || null,
            initiateFirstSetup: input.initiateFirstSetup || null,
            isActive: input.isActive ? true : false,
            resetPasswordLink: input.resetPasswordLink || null
          },
        },
      });
    };

    return { addServerSetting, data, addServerLoading: loading, error };
  } catch (err: any) {
    console.error(err);
    return {
      addServerSetting: null,
      data: null,
      addServerLoading: false,
      error: err,
    };
  }
};

const UPDATE_SERVER_SETTING = gql`
  mutation updateServerSetting($id: String!, $input: ServerSettingInput) {
    updateServerSetting(id: $id, input: $input) {
      ... on CrmTaskApproval {
        msg
      }
      ... on ServerSetting {
        id
        name
        mode
        ip
        port
        timezone
        serverStatus
        platform
        platformDisplayName
        platformAccountNo
        fieldForTrading{
          tradingKey
          clientField
        }
        proxy
        proxyName
        plantId
        username
        environment
        whiteLabel
        brokerName
        isActive
        initiateFirstSetup
        firstTimeFetchDataCompleted
        resetPasswordLink
        createdBy {
          username
        }
        updatedBy {
          username
        }
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useUpdateServerSettingMutation = (): {
  updateServerSetting: any;
  data: any;
  updateServerLoading: boolean;
  error: any;
} => {
  try {
    const setErrorMessage = useSetRecoilState(errorMessage);
    const [updateServerSettingMutation, { data, loading, error }] = useMutation(
      UPDATE_SERVER_SETTING
    );

    const updateServerSetting = (id, input) => {
      return updateServerSettingMutation({
        variables: {
          id: id,
          input: {
            name: input.name || null,
            mode: input.mode || null,
            ip: input.ip || null,
            port: input.port || null,
            timezone: input.timezone || null,
            proxy: input.proxy || null,
            serverStatus: input.serverStatus || null,
            whiteLabel: input.whiteLabel || null,
            platform: input.platform || null,
            platformDisplayName: input.platformDisplayName || null,
            platformAccountNo: input.platformAccountNo ? parseInt(input.platformAccountNo) : 0,
            fieldForTrading: input.fieldForTrading || null,
            proxyName: input.proxyName || null,
            plantId: input.plantId || null,
            brokerName: input.brokerName || null,
            environment: input.environment || null,
            username: input.username || null,
            password: input.password || null,
            initiateFirstSetup: input.initiateFirstSetup || null,
            isActive: input.isActive ? true : false,
            resetPasswordLink: input.resetPasswordLink || null
          },
        },
      });
    };

    let typename = data?.updateServerSetting?.__typename;
    if (typename === "CrmTaskApproval" || typename === "BaseError") {
      setErrorMessage(data?.updateServerSetting);
    }

    return { updateServerSetting, data, updateServerLoading: loading, error };
  } catch (err: any) {
    console.error(err);
    return {
      updateServerSetting: null,
      data: null,
      updateServerLoading: false,
      error: err,
    };
  }
};

const DELETE_SERVER_SETTING = gql`
  mutation deleteServerSetting($id: String!) {
    deleteServerSetting(id: $id) {
      ... on CrmTaskApproval {
        msg
      }
      ... on ServerSetting {
        id
        isDeleted
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useDeleteServerSettingMutation = (): {
  deleteServerSetting: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const [deleteServerSettingMutation, { data, loading, error }] = useMutation(
      DELETE_SERVER_SETTING,
      {
        update(cache, { data: { deleteServerSetting } }) {
          let typename = deleteServerSetting?.__typename;
          if (typename === "ServerSetting") {
            cache.modify({
              fields: {
                getServerSetting(existingData = [], { readField }) {
                  let data = existingData?.data?.filter((ref) => {
                    const id = readField("id", ref);
                    return id !== deleteServerSetting.id;
                  });
                  return { data, __typename: "ServerSettingArray" };
                },
              },
            });
          } else if (
            typename === "CrmTaskApproval" ||
            typename === "BaseError"
          ) {
            // Notification
          }
        },
      }
    );

    const deleteServerSetting = (id) => {
      return deleteServerSettingMutation({
        variables: {
          id: id,
        },
      });
    };

    return { deleteServerSetting, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return {
      deleteServerSetting: null,
      data: null,
      loading: false,
      error: err,
    };
  }
};

export {
  getServerSettingCount,
  getServerSettingById,
  getServerSetting,
  getServerSettingLoadingStatus,
  GET_SERVER_SETTING_LOADING_STATUS,
  useAddServerSettingMutation,
  useUpdateServerSettingMutation,
  useDeleteServerSettingMutation,
};
