import {atom} from "recoil";


const assetClassMt5ReloadState = atom({
    key: 'symbolMt5ReloadState',
    default: false,
});

export {
    assetClassMt5ReloadState,
}