import React, {useEffect, useRef, useState} from "react";
import {Col, Row, Spin, Table} from "antd";
import {useTranslation} from "react-i18next";
import {isArray} from "@apollo/client/utilities";
import {getUserTableAdminSetting, getUserTableSetting} from "../../api/graphql/tableSetting";

const TableAntDesign = (props) => {
    const {
        data, //Table Data
        columns, // Table Column
        size,
        loading,
        pagination, // Pagination options
        filter, // Set API Filter
        filterValue, // Filter State Value
        startDate, // Date Filter StartDate
        endDate, // Date Filter EndDate
        dateKey, // Date Filter DateKey
        server, // Server Name
        order, // Set API Order
        onRow,
        rowKey, // specified rowKey else _id
        selectionType,
        onRowSelectionHandler, // On Row Change
        selectedRowKeys,
        disabledCheckboxProps,
        tableName, // For Export and Table Preference
        sort,
        scroll = {x: "max-content"},
        isTableLocalSearch,
        actionPermission = true, // permission for Action Column
        component = undefined, // for dnd table
        tableSpin = true,
        rowClassName,
        onRowSelect // On Row Select
    } = props;

    const sortEnable = sort === undefined;

    const tableRef = useRef<any>();
    const {t} = useTranslation();
    const [tableColumn, setTableColumn] = useState<any>([]);
    const [dataSource, setDataSource] = useState<any>();

    const {data: adminSettingTable, loading: adminSettingLoading} = getUserTableAdminSetting({
        filter: {
            tableName: tableName,
            serverName: server || null,
        },
    });
    const {
        data: userTablePreference,
        loading: userSettingLoading
    } = getUserTableSetting(localStorage.getItem("userId"), {
        filter: {
            tableName: tableName,
            serverName: server || null,
        },
    });

    useEffect(() => {
        setDataSource(data);
    }, [data]);

    const formatColumns = async () => {
        const actionObj = columns?.find((obj) => obj?.key === "action");
        if (
            userTablePreference?.getUserTableSetting?.tableColumn?.length > 0 &&
            adminSettingTable?.getUserTableAdminSetting?.data?.[0]?.fieldData?.length > 0
        ) {
            const userPreference = userTablePreference.getUserTableSetting.tableColumn;
            const adminPreference = adminSettingTable.getUserTableAdminSetting?.data[0].fieldData;
            return await userPreference
                .map((d) => {
                    const matchingAdminObj = adminPreference?.find(
                        (obj) => obj?.fieldName === d?.columnName || (Array.isArray(obj?.fieldName) && obj?.fieldName.join("_") === d?.columnName)
                    );

                    const matchingObj = columns?.find(
                        (obj) =>
                            (obj?.dataIndex === d?.columnName || (Array.isArray(obj?.dataIndex) && obj?.dataIndex.join("_") === d?.columnName)) &&
                            matchingAdminObj?.isSelectableByUser
                    );
                    return {
                        ...matchingObj,
                        sorter: d?.key !== "action" && sortEnable && !isTableLocalSearch,
                    };
                })
                ?.filter((d) => d?.key)
                .concat(actionPermission && actionObj ? actionObj : []);
        } else if (
            userTablePreference?.getUserTableSetting?.tableColumn?.length > 0 &&
            !adminSettingTable?.getUserTableAdminSetting?.data?.[0]?.fieldData?.length
        ) {
            const userPreference = userTablePreference.getUserTableSetting.tableColumn;
            return await userPreference
                .map((d) => {
                    const matchingObj = columns?.find(
                        (obj) => obj?.dataIndex === d?.columnName || (Array.isArray(obj?.dataIndex) && obj?.dataIndex.join("_") === d?.columnName)
                    );
                    return {
                        ...matchingObj,
                        sorter: d?.key !== "action" && sortEnable && !isTableLocalSearch,
                    };
                })
                .concat(actionPermission && actionObj ? actionObj : []);
        } else if (
            adminSettingTable?.getUserTableAdminSetting?.data?.[0]?.fieldData?.length > 0 &&
            !userTablePreference?.getUserTableSetting?.tableColumn?.length
        ) {
            const userPreference = adminSettingTable.getUserTableAdminSetting?.data[0].fieldData;
            return await userPreference
                ?.filter((d) => d?.isDisplayToUser)
                .map((d) => {
                    const matchingObj = columns?.find(
                        (obj) => obj?.dataIndex === d?.fieldName || (Array.isArray(obj?.dataIndex) && obj?.dataIndex.join("_") === d?.columnName)
                    );
                    return {
                        ...matchingObj,
                        sorter: d?.key !== "action" && sortEnable && !isTableLocalSearch,
                    };
                })
                .concat(actionPermission && actionObj ? actionObj : []);
        } else {
            return await columns
                ?.map((d) => {
                    return {
                        ...d,
                        sorter: d?.key !== "action" && sortEnable && !isTableLocalSearch,
                    };
                })
                ?.filter((d) => !(d?.key === "action"))
                ?.concat(actionPermission && actionObj ? actionObj : []);
        }
    };

    const formatColumnsWithSearchValue = async (data) => {
        if (filterValue) {
            return await data?.map(column => {
                return {
                ...column,
                    filteredValue: filterValue?.[column?.dataIndex] || null
                }
            })
        } else return data
    }

    useEffect(() => {
        if (!adminSettingLoading && !userSettingLoading) {
            formatColumns()?.then(data => formatColumnsWithSearchValue(data))?.then((data) => setTableColumn(data?.sort((a, b) => a?.defaultPosition - b?.defaultPosition)));
        }
    }, [
        adminSettingTable?.getUserTableAdminSetting?.data,
        userTablePreference?.getUserTableSetting?.tableColumn,
        adminSettingLoading,
        userSettingLoading,
        filterValue
    ]);

    const rowSelection = {
        selectedRowKeys,
        ...(onRowSelect ? {onSelect: (record, selected, selectedRows, changeRows) => onRowSelect(record, selected, selectedRows, changeRows)} : null),
        ...(onRowSelectionHandler ? {onChange: (selectedRowKeys, selectedRows) => onRowSelectionHandler(selectedRowKeys, selectedRows)} : null),
    };

    const onChange = (pagination, filters, sorter, extra) => {
        if (isTableLocalSearch) {
            const filteredData: any = [];

            for (let key in filters) {
                if (filters[key] === null) {
                    delete filters[key];
                }
            }

            if (Object.keys(filters).length > 0) {
                for (const [key, value] of Object.entries(filters)) {
                    const [...val] = isArray(value) ? value : "";
                    data?.map((d) => {
                        if (isArray(val) && (val[0] === "true" || val[0] === "false")) {
                            if (d[key]?.toString()?.toLowerCase()?.includes(val[0]?.toLowerCase())) filteredData.push(d);
                        } else if (isArray(val) && val[0] === "string") {
                            if (d[key]?.toString()?.toLowerCase()?.includes(val[1]?.toLowerCase())) filteredData.push(d);
                        } else if (d[key]?.toString()?.toLowerCase()?.includes(val?.toString()?.toLowerCase())) {
                            filteredData.push(d);
                        }
                    });
                }
                setDataSource(filteredData);
            } else {
                setDataSource(data);
            }
        } else {
            const newFilters = {};
            for (const [key, value] of Object.entries(filters)) {
                const [...val] = isArray(value) ? value : "";
                if (!val?.[0] && !!value) {
                    newFilters[key] = value
                } else if (isArray(val)) {
                    if (val[0] === "true") {
                        newFilters[key] = true;
                    } else if (val[0] === "false") {
                        newFilters[key] = false;
                    } else if (val[0] === "date") {
                        startDate(val[1][0]);
                        endDate(val[1][1]);
                        dateKey(key);
                    } else if (val[0] === "string") {
                        newFilters[key] = val?.length > 0 ? val[1] : undefined;
                    } else {
                        newFilters[key] = val?.length > 0 ? val : undefined;
                    }
                } else {
                    newFilters[key] = val ? val[0] : undefined;
                }
            }


            const formattedFilters = Object.fromEntries(Object.entries(newFilters)?.filter(([key, value]) => value !== undefined));

            const filterParams = server !== null && server !== undefined ? {
                ...formattedFilters,
                serverName: server
            } : formattedFilters;

            if (filter && filterParams) {
                if (JSON.stringify(filterParams) !== JSON.stringify(filterValue)) filter(filterParams)
            }

            if (order && sorter.order) {
                if (sorter.order === "ascend") {
                    order(`${sorter?.column?.dataIndex}_ASC`);
                } else if (sorter.order === "descend") {
                    order(`${sorter?.column?.dataIndex}_DESC`);
                } else {
                    order(null);
                }
            }
        }
    };

    const amendedPagination = isTableLocalSearch
        ? {
            showSizeChanger: true,
            total: dataSource?.length,
            showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
        }
        : pagination;

    return (
        <Row>
            {tableColumn?.length > 0 ? (
                <Col span={24}>
                    <Table
                        className={"antd-table-default"}
                        components={component}
                        ref={tableRef}
                        bordered={false}
                        columns={tableColumn}
                        rowClassName={rowClassName}
                        rowKey={rowKey ? (record) => record?.[rowKey] : (record) => record?.id || record?._id}
                        onRow={onRow ? (record, rowIndex) => onRow(record, rowIndex) : undefined}
                        dataSource={dataSource}
                        size={size}
                        pagination={amendedPagination}
                        onChange={onChange}
                        scroll={scroll}
                        loading={loading}
                        rowSelection={
                            selectionType
                                ? {
                                    type: selectionType,
                                    getCheckboxProps: (record) => {
                                        // Check if there are any conditions to evaluate
                                        if (disabledCheckboxProps && disabledCheckboxProps.length > 0) {
                                            // Use every to ensure all conditions match based on the specified operator
                                            const isDisabled = disabledCheckboxProps.every(prop => {
                                                switch (prop.operator) {
                                                    case '=':
                                                        return record[prop.field] === prop.value;
                                                    case '!=':
                                                        return record[prop.field] !== prop.value;
                                                    // Add cases for other operators as needed
                                                    default:
                                                        return false; // Unknown operator: condition not met
                                                }
                                            });
                                            return {disabled: isDisabled};
                                        }
                                        return {};
                                    },
                                    ...rowSelection,
                                }
                                : undefined
                        }
                        showSorterTooltip={false}
                    />
                </Col>
            ) : tableSpin ? (
                <Col span={24} style={{marginTop: "15%", textAlign: "center"}}>
                    <Spin size="large"/>
                </Col>
            ) : null}
        </Row>
    );
};

export default TableAntDesign;
