import React, {useState} from "react";
import TableAntDesign from "../../../../../components/table/tableAntDesign";
import {useTranslation} from "react-i18next";
import {columns} from "../../function/columns";
import {useRecoilValue} from "recoil";
import {selfPermissionObj} from "../../../../../../recoil_state";
import {Row, Space} from "antd";
import EditTableUserPreferenceButton from "../../../../../components/table/function/editTableUserPreferenceButton";
import ExportTableButton from "../../../../../components/table/function/exportTable";
import {AiOutlineEdit} from "react-icons/ai";
import SettingFormModal from "./settingFormModal";
import {useUpdateCurrencySettingMutation} from "../../../../../api/graphql/currencySetting";
import useAuthorize from "../../../../../_common/authorize";

const CurrencySetting_1 = (props) => {
        const {data, exportData, loading, totalCount, setLimit, limit, setOffset, setFilter, setOrderBy, tableName} = props
        const [isOpen, setIsOpen] = useState(false)
        const [current, setCurrent] = useState()
        const {t} = useTranslation()
        const permission = useRecoilValue<any>(selfPermissionObj)

        const {updateCurrencySetting} = useUpdateCurrencySettingMutation()
        const {systemErrorNotification} = useAuthorize()
        const onChangePageHandler = (page, pageSize) => {
            setLimit(pageSize)
            setOffset(limit * (page - 1))
        }

        const openEdit = (record) => {
            setCurrent(record)
            setIsOpen(true)
        }

        const onSubmit = (val) => {
            try {
                updateCurrencySetting(val)
                setIsOpen(false)
            } catch (e) {
                systemErrorNotification()
            }
        }

        return (
            <>
                {permission?.brandSetting?.currencySetting?.edit?.edit &&
                <SettingFormModal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    data={current}
                    onSubmit={onSubmit}
                />}
                <Row justify={"space-between"} className={"margin-bottom-0-75"}>
                    <h1>{t(tableName)}</h1>
                    <Space>
                        <EditTableUserPreferenceButton
                            tableName={tableName}
                            tableColumn={columns}
                        />
                        <ExportTableButton
                            tableData={exportData}
                            tableColumn={columns}
                            tableName={tableName}
                        />
                    </Space>
                </Row>
                <TableAntDesign
                    data={data}
                    tableName={tableName}
                    columns={[...columns,
                        {
                            title: t("common.action"),
                            key: "action",
                            width: 100,
                            align: "center",
                            render: (_, record: any) => (
                                <a style={{color: "green"}} onClick={() => openEdit(record)}>
                                    <AiOutlineEdit/>
                                </a>
                            ),
                        },]}
                    size={"small"}
                    loading={loading}
                    filter={setFilter}
                    order={setOrderBy}
                    actionPermission={permission?.brandSetting?.currencySetting?.edit?.edit}
                    pagination={{
                        pageSize: limit,
                        showSizeChanger: true,
                        position: "bottomCenter",
                        total: totalCount?.getCurrencySettingCount,
                        onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                        showTotal: (total) => <p>Total {total} items</p>,
                    }}
                />
            </>
        );
    }
;

export default CurrencySetting_1