import React from "react";
import Announcement_1 from "./view/layout1";
import {useRecoilState, useRecoilValue} from "recoil";
import {columns} from "./function/tableColumn";
import {selfPermissionObj} from "../../../../recoil_state";
import {getLanguage} from "../../../api/graphql/language";
import {sortEn} from "../../../function/language";
import {getUserNetworkTreeDownline} from "../../../api/graphql/userNetworkTree";
import {getDynamicVariable} from "../../../api/graphql/dynamicVariable";

const Announcement = (props) => {
    let layout = 1;
    let component;
    const selfPermission = useRecoilValue<any>(selfPermissionObj);
    const tableName = "Announcement"
    const dynamicVariablePermission = selfPermission?.messageSetting?.dynamicVariable;
    const {languageData} = getLanguage({filter: {isActive: true}});
    const {networkTreeDownlineData} = getUserNetworkTreeDownline([localStorage.getItem("g_userId") || localStorage.getItem("userId")])
    const {variableData: imageVarData} = getDynamicVariable({
        filter: {variableType: "image", isActive: true},
    });
    const {variableData: textVarData} = getDynamicVariable({
        filter: {variableType: "text", isActive: true},
    });
    const amendedImageVarData = dynamicVariablePermission?.view ? imageVarData?.getDynamicVariable?.data : [];

    switch (layout) {
        case 1:
            component = (
                <Announcement_1
                    tableName={tableName}
                    announcementPermission={selfPermission}
                    column={columns()}
                    languageData={sortEn(languageData?.getLanguage?.data)}
                    userNetworkTreeDropdown={networkTreeDownlineData?.getUserNetworkTreeDownline?.data}
                    imageVarData={amendedImageVarData}
                    textVarData={textVarData?.getDynamicVariable?.data}
                />
            );
            break;

        default:
            component = (
                <Announcement_1
                    tableName={tableName}
                    announcementPermission={selfPermission}
                    column={columns()}
                    languageData={sortEn(languageData?.getLanguage?.data)}
                    userNetworkTreeDropdown={networkTreeDownlineData?.getUserNetworkTreeDownline?.data}
                    imageVarData={amendedImageVarData}
                    textVarData={textVarData?.getDynamicVariable?.data}
                />
            );
            break;
    }

    return component;
};

export default Announcement;
