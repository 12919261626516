import React from "react";
import {useAccountVerificationFunction} from "../../../../../../function/accountVerificationFunction";
import {Button, Col, Row} from "antd";
import {CloseOutlined} from "@ant-design/icons";

const FilePreview = (props) => {
    // const {IconCommon} = useShared();
    const {link, isClickable, isDraft, handleDelete} = props
    const {getLastPartOfUrl} = useAccountVerificationFunction()
    const result = getLastPartOfUrl(link);
    if (!result) {
        return null;
    }

    const {name, category} = result;
    let component: any = null;

    switch (category) {
        case "image": {
            component = (
                <div style={{textAlign: "center"}}>
                    <img src={link} style={{width: "100%", maxWidth: "30%"}}/>
                </div>
            );
            break;
        }
        case "video": {
            component = (
                <Row
                    gutter={10}
                    style={{
                        textAlign: "left",
                        paddingRight: 28,
                    }}
                >
                    <Col>
                        {/*<IconCommon iconName={"video"} className="upload-icon"/>*/}
                    </Col>
                    <Col flex={"auto"}>{name}</Col>
                </Row>
            );
            break;
        }
        case "document": {
            const specifyType = name.endsWith(".pdf") ? "pdf" : name.endsWith(".xls") || name.endsWith(".xlsx") ? "xls" : "task";

            component = (
                <Row
                    gutter={10}
                    style={{
                        textAlign: "left",
                        paddingRight: 28,
                    }}
                >
                    <Col>
                        {/*<IconCommon iconName={specifyType} className="upload-icon"/>*/}
                    </Col>
                    <Col flex={"auto"}>{name}</Col>
                </Row>
            );
            break;
        }
        default: {
        }
    }

    const wrappedComponent = (
        <div
            style={
                handleDelete
                    ? {
                        position: "absolute",
                        padding: "16px 24px",
                        width: "100%",
                        top: 0,
                        left: 0,
                    }
                    : {
                        visibility: "hidden",
                        pointerEvents: "none",
                    }
            }
        >
            <div style={{position: "relative"}}>
                {component}
                {handleDelete && !isDraft ? (
                    <Button
                        type={"text"}
                        style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: 0,
                            width: 24,
                        }}
                        onClick={() => handleDelete?.()}
                    >
                        <CloseOutlined style={{verticalAlign: "middle"}}/>
                    </Button>
                ) : null}
            </div>
        </div>
    );

    if (isClickable) {
        return <a target="_blank" href={link} className="external-link">{component}</a>
    }
    return wrappedComponent;
}

export default FilePreview