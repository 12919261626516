import {gql, useMutation, useQuery} from "@apollo/client";
import {useSetRecoilState} from "recoil";
import {useTranslation} from "react-i18next";
import {notificationMessage} from "../../../recoil_state";
import {refetchQuery} from "../../function/refetchQuery";
import {ApolloClientQuery} from "./_apolloClient";

const GET_ROLE_TEMPLATE_COUNT = gql`
    query getRoleTemplateCount($filter: RoleTemplateSearchInput) {
        getRoleTemplateCount(filter: $filter)
    }
`;

const getRoleTemplateCount = ({filter}) => {
    try {
        const {loading, error, data} = useQuery(GET_ROLE_TEMPLATE_COUNT, {
            variables: {
                filter: {
                    name: filter?.name || null,
                    description: filter?.description || null,
                    permission: filter?.permission || null,
                    parent: filter?.parent || null,
                },
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_ROLE_TEMPLATE_BY_ID = gql`
    query getRoleTemplateByID($id: String!) {
        getRoleTemplateByID(id: $id) {
            ... on RoleTemplateObj {
                id
                name
                description
                permission
                isActive
                isDeleted
                parent {
                    _id
                    name
                }
                networkType
                network {
                    _id
                    username
                }
                exclude {
                    _id
                    username
                }
                isMasterAdmin
                isDefaultCrmUserPermission
                userCount
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getRoleTemplateById = (id) => {
    try {
        const {loading, error, data} = useQuery(GET_ROLE_TEMPLATE_BY_ID, {
            variables: {
                id: id,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_ROLE_TEMPLATE = gql`
    query getRoleTemplate(
        $filter: RoleTemplateSearchInput
        $limit: Int
        $offset: Int
        $orderBy: String
    ) {
        getRoleTemplate(
            filter: $filter
            limit: $limit
            offset: $offset
            orderBy: $orderBy
        ) {
            ... on RoleTemplateObjArray {
                data {
                    id
                    name
                    description
                    isActive
                    isDeleted
                    permission
                    parent {
                        _id
                        name
                        networkType
                    }
                    networkType
                    network {
                        _id
                        username
                    }
                    exclude {
                        _id
                        username
                    }
                    isMasterAdmin
                    isDefaultCrmUserPermission
                    userCount
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getRoleTemplate = ({filter}, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data} = useQuery(GET_ROLE_TEMPLATE, {
            variables: {
                filter: filter,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};


const GET_ROLE_TEMPLATE_EXPORT_DATA = gql`
    query getRoleTemplateExportData{
        getRoleTemplateExportData
    }
`;

const getRoleTemplateExportData = () => {
    try {
        const {loading, error, data} = useQuery(GET_ROLE_TEMPLATE_EXPORT_DATA, {
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};


const GET_ROLE_TEMPLATE_DOWNLINE_BY_LEVEL = gql`
    query getRoleTemplateDownlineByLevel(
        $roleTemplateIds: [String]
        $level: Int
    ) {
        getRoleTemplateDownlineByLevel(
            roleTemplateIds: $roleTemplateIds
            level: $level
        ) {
            ... on RoleTemplateObjArray {
                data {
                    id
                    name
                    description
                    isActive
                    isDeleted
                    permission
                    parent {
                        _id
                        name
                        networkType
                    }
                    networkType
                    network {
                        _id
                        username
                    }
                    exclude {
                        _id
                        username
                    }
                    isMasterAdmin
                    isDefaultCrmUserPermission
                    userCount
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getRoleTemplateDownlineByLevel = (roleTemplateIds, level) => {
    try {
        const {loading, error, data} = useQuery(GET_ROLE_TEMPLATE_DOWNLINE_BY_LEVEL, {
            variables: {
                roleTemplateIds: roleTemplateIds,
                level: level || 0,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_ROLE_TEMPLATE = gql`
    mutation addRoleTemplate($input: RoleTemplateInput) {
        addRoleTemplate(input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on RoleTemplateObj {
                id
                name
                description
                isActive
                isDeleted
                permission
                parent {
                    _id
                    name
                }
                networkType
                network {
                    _id
                    username
                }
                exclude {
                    _id
                    username
                }
                isMasterAdmin
                isDefaultCrmUserPermission
                userCount
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useAddRoleTemplateMutation = (): {
    addRoleTemplate: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const {t} = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [addRoleTemplateMutation, {data, loading, error}] =
            useMutation(ADD_ROLE_TEMPLATE);

        const addRoleTemplate = (input) => {
            return addRoleTemplateMutation({
                variables: {
                    input: {
                        name: input?.name || null,
                        description: input?.description || null,
                        isActive: input?.isActive || null,
                        parent: input?.parent || null,
                        networkType: input?.networkType || null,
                        network: input?.network || null,
                        exclude: input?.exclude || null,
                        permission: input?.permission || null,
                        applyToDownlineType: input?.applyToDownlineType || null,
                        applyToDownline: input?.applyToDownline || null
                    },
                },
                update(cache, {data: {addRoleTemplate}}) {
                    const typename = addRoleTemplate?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(addRoleTemplate?.errKey),
                            key: "addRoleTemplate",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(addRoleTemplate?.msg),
                            key: "addRoleTemplate",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Add Successfully"),
                            key: "addRoleTemplate",
                        });
                    }
                },
                refetchQueries: [GET_ROLE_TEMPLATE, GET_ROLE_TEMPLATE_COUNT],
            });
        };

        return {addRoleTemplate, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {addRoleTemplate: null, data: null, loading: false, error: err};
    }
};

const UPDATE_ROLE_TEMPLATE = gql`
    mutation updateRoleTemplate($id: String!, $input: RoleTemplateInput) {
        updateRoleTemplate(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on RoleTemplateObj {
                id
                name
                description
                isActive
                isDeleted
                permission
                parent {
                    _id
                    name
                }
                networkType
                network {
                    _id
                    username
                }
                exclude {
                    _id
                    username
                }
                isMasterAdmin
                isDefaultCrmUserPermission
                userCount
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;

const useUpdateRoleTemplateMutation = (): {
    updateRoleTemplate: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const {t} = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updateRoleTemplateMutation, {data, loading, error}] =
            useMutation(UPDATE_ROLE_TEMPLATE);

        const updateRoleTemplate = (id, input) => {
            return updateRoleTemplateMutation({
                variables: {
                    id: id,
                    input: {
                        name: input?.name || null,
                        description: input?.description || null,
                        isActive: input?.isActive || null,
                        parent: input?.parent || null,
                        networkType: input?.networkType || null,
                        network: input?.network || null,
                        exclude: input?.exclude || null,
                        permission: input?.permission || null,
                        removeChildPermission: input?.removeChildPermission || null,
                        applyToDownlineType: input?.applyToDownlineType || null,
                        applyToDownline: input?.applyToDownline || null
                    },
                },
                update(cache, {data: {updateRoleTemplate}}) {
                    const typename = updateRoleTemplate?.__typename;
                    if (typename === "BaseError") {
                        // setNotificationMessage({
                        //   status: "error",
                        //   title: "",
                        //   msg: t(updateRoleTemplate?.errKey),
                        //   key: "updateRoleTemplate",
                        // });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateRoleTemplate?.msg),
                            key: "updateRoleTemplate",
                        });
                    } else {
                        refetchQuery("getRoleTemplate");
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Successfully"),
                            key: "updateRoleTemplate",
                        });
                    }
                },
                refetchQueries: [GET_ROLE_TEMPLATE, GET_ROLE_TEMPLATE_COUNT],
            });
        };

        return {updateRoleTemplate, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {updateRoleTemplate: null, data: null, loading: false, error: err};
    }
};

const IMPORT_ROLE_TEMPLATE_DATA = gql`
    mutation importRoleTemplateData($input: String) {
        importRoleTemplateData(input: $input) {
            ... on RoleTemplateObjArray {
                data {
                    id
                    name
                    description
                    isActive
                    isDeleted
                    permission
                    parent {
                        _id
                        name
                        networkType
                    }
                    networkType
                    network {
                        _id
                        username
                    }
                    exclude {
                        _id
                        username
                    }
                    isMasterAdmin
                    isDefaultCrmUserPermission
                    userCount
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;

const useImportRoleTemplateData = (): {
    importRoleTemplateData: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const {t} = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [importRoleTemplateDataMutation, {data, loading, error}] =
            useMutation(IMPORT_ROLE_TEMPLATE_DATA);

        const importRoleTemplateData = (input) => {
            return importRoleTemplateDataMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {importRoleTemplateData}}) {
                    const typename = importRoleTemplateData?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(importRoleTemplateData?.errKey),
                            key: "importRoleTemplateData",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(importRoleTemplateData?.msg),
                            key: "importRoleTemplateData",
                        });
                    } else {
                        refetchQuery("getRoleTemplate");
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Successfully"),
                            key: "importRoleTemplateData",
                        });
                    }
                },
                refetchQueries: [GET_ROLE_TEMPLATE, GET_ROLE_TEMPLATE_COUNT],
            });
        };

        return {importRoleTemplateData, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {importRoleTemplateData: null, data: null, loading: false, error: err};
    }
};

const DELETE_ROLE_TEMPLATE = gql`
    mutation deleteRoleTemplate($id: String!) {
        deleteRoleTemplate(id: $id) {
            ... on CrmTaskApproval {
                msg
            }
            ... on RoleTemplate {
                id
                isDeleted
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useDeleteRoleTemplateMutation = (): {
    deleteRoleTemplate: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const {t} = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [deleteRoleTemplateMutation, {data, loading, error}] =
            useMutation(DELETE_ROLE_TEMPLATE);

        const deleteRoleTemplate = (id) => {
            return deleteRoleTemplateMutation({
                variables: {
                    id: id,
                },
                update(cache, {data: {deleteRoleTemplate}}) {
                    const typename = deleteRoleTemplate?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(deleteRoleTemplate?.errKey),
                            key: "deleteRoleTemplate",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(deleteRoleTemplate?.msg),
                            key: "deleteRoleTemplate",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Delete Successfully"),
                            key: "deleteRoleTemplate",
                        });
                    }
                },
                refetchQueries: [GET_ROLE_TEMPLATE, GET_ROLE_TEMPLATE_COUNT],
            });
        };

        return {deleteRoleTemplate, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {deleteRoleTemplate: null, data: null, loading: false, error: err};
    }
};

const GET_PARENT_PERMISSION_ARRAY = gql`
    query getParentPermissionArray($targetParent: String) {
        getParentPermissionArray(targetParent: $targetParent)
    }
`;

const getParentPermissionArray = (targetParent) => {
    try {
        const {loading, error, data} = useQuery(GET_PARENT_PERMISSION_ARRAY, {
            variables: {
                targetParent: targetParent,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, permissionArrayData: data};
    } catch (err: any) {
        console.error(err);
        return {permissionArrayData: null, loading: false, error: err};
    }
};

const GET_PARENT_PERMISSION_OBJECT = gql`
    query getParentPermissionObject($targetParent: String) {
        getParentPermissionObject(targetParent: $targetParent)
    }
`;

const getParentPermissionObject = (targetParent) => {
    try {
        const {loading, error, data} = useQuery(GET_PARENT_PERMISSION_OBJECT, {
            variables: {
                targetParent: targetParent,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, permissionObjectData: data};
    } catch (err: any) {
        console.error(err);
        return {permissionObjectData: null, loading: false, error: err};
    }
};

const GET_NETWORK_BY_ROLE_TEMPLATE = gql`
    query getNetworkByRoleTemplate($id: String!) {
        getNetworkByRoleTemplate(id: $id) {
            _id
            id
            userType
            email
            domain
            username
            firstName
            middleName
            lastName
            fullName
            identificationNo
            dateOfBirth
            gender
            age
            comment
            countryCode
            mobileNo
            completedMobileNo
            profileImage
            affiliateCode
            pspUserId
            language
            newsletter
            verifiedEmail
            address
            city
            postalCode
            state
            country
            status
            rank {
                _id
                name
                rank
            }
            desk {
                _id
                name
            }
            referrer {
                username
            }
            createdAt
            updatedAt
            createdBy {
                username
            }
            updatedBy {
                username
            }
        }
    }
`;

const getNetworkByRoleTemplate = (id) => {
    try {
        const {data, loading, error} = useQuery(GET_NETWORK_BY_ROLE_TEMPLATE, {
            variables: {
                id: id,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_SELF_PERMISSION_LIST = gql`
    query getSelfPermissionList {
        getSelfPermissionList
    }
`;

const getSelfPermissionList = () => {
    try {
        const {data, loading, error} = useQuery(GET_SELF_PERMISSION_LIST);

        return {selfPermissionData: data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {selfPermissionData: null, loading: false, error: err};
    }
};

const getSelfPermissionListAsync = async () => {
    try {
        const execute = await ApolloClientQuery(
            GET_SELF_PERMISSION_LIST,
            {
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_SELF_PERMISSION_LIST_OBJECT = gql`
    query getSelfPermissionListObject {
        getSelfPermissionListObject
    }
`;

const getSelfPermissionListObject = () => {
    try {
        const {data, loading, error} = useQuery(GET_SELF_PERMISSION_LIST_OBJECT);

        return {selfPermissionObjectData: data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {selfPermissionObjectData: null, loading: false, error: err};
    }
};

const getSelfPermissionListObjectAsync = async () => {
    try {
        const execute = await ApolloClientQuery(
            GET_SELF_PERMISSION_LIST_OBJECT,
            {
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_CURRENT_PERMISSION_LIST = gql`
    query getCurrentPermissionList {
        getCurrentPermissionList
    }
`;

const getCurrentPermissionList = () => {
    try {
        const {data, loading, error} = useQuery(GET_CURRENT_PERMISSION_LIST);

        return {currentPermissionData: data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {currentPermissionData: null, loading: false, error: err};
    }
};

const SET_DEFAULT_CRM_USER_PERMISSION = gql`
    mutation setDefaultCrmUserPermission($id: String!) {
        setDefaultCrmUserPermission(id: $id) {
            ... on CrmTaskApproval {
                msg
            }
            ... on RoleTemplate {
                id
                name
                description
                isActive
                isDeleted
                permission
                parent {
                    _id
                    name
                }
                networkType
                network {
                    _id
                    username
                }
                exclude {
                    _id
                    username
                }
                isMasterAdmin
                isDefaultCrmUserPermission
                userCount
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useSetDefaultCrmUserPermissionMutation = (): {
    setDefaultCrmUserPermission: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const {t} = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [setDefaultCrmUserPermissionMutation, {data, loading, error}] =
            useMutation(SET_DEFAULT_CRM_USER_PERMISSION);

        const setDefaultCrmUserPermission = (id) => {
            return setDefaultCrmUserPermissionMutation({
                variables: {
                    id: id,
                },
                update(cache, {data: {setDefaultCrmUserPermission}}) {
                    const typename = setDefaultCrmUserPermission?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(setDefaultCrmUserPermission?.errKey),
                            key: "setDefaultCrmUserPermission",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(setDefaultCrmUserPermission?.msg),
                            key: "setDefaultCrmUserPermission",
                        });
                    } else {
                        refetchQuery("getRoleTemplate");
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Successfully"),
                            key: "setDefaultCrmUserPermission",
                        });
                    }
                },
                refetchQueries: [GET_ROLE_TEMPLATE, GET_ROLE_TEMPLATE_COUNT],
            });
        };

        return {setDefaultCrmUserPermission, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            setDefaultCrmUserPermission: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const UPDATE_ADMIN_PERMISSION = gql`
    mutation updateAdminPermission {
        updateAdminPermission {
            ... on RoleTemplate {
                _id
                permission
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`

async function updateAdminPermission() {
    const execute = await ApolloClientQuery(UPDATE_ADMIN_PERMISSION, {}, true);

    return execute;
}

export {
    getRoleTemplateCount,
    getRoleTemplateById,
    getRoleTemplate,
    useAddRoleTemplateMutation,
    useUpdateRoleTemplateMutation,
    useDeleteRoleTemplateMutation,
    getNetworkByRoleTemplate,
    getParentPermissionObject,
    getParentPermissionArray,
    getSelfPermissionList,
    getSelfPermissionListAsync,
    getSelfPermissionListObject,
    getSelfPermissionListObjectAsync,
    getCurrentPermissionList,
    useSetDefaultCrmUserPermissionMutation,
    getRoleTemplateDownlineByLevel,
    getRoleTemplateExportData,
    useImportRoleTemplateData,
    updateAdminPermission
};
