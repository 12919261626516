import React from "react";
import {Button, Col, Row, Space} from "antd";
import {useTranslation} from "react-i18next";
import SelectFormItem from "../../../../../../components/form/selectFormItem";
import TimezoneSelectFormItem from "../../../../../../components/form/timezoneSelectFormtItem";
import {horizontalFormLayout, verticalFormLayout} from "../../../../../../components/form/formWrapper";
import SwitchFormItem from "../../../../../../components/form/switchFormItem";
import RadioGroupFormItem from "../../../../../../components/form/radioGroupFormItem";
import MultipleInputFormItem from "../../../../../../components/form/multipleInputFormItem";
import {SaveOutlined} from "@ant-design/icons";
import InputNumberFormItem from "../../../../../../components/form/inputNumberFormItem";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import {countryList_Language} from "../../../../../../../config/countryList_Language";

const OtherForm = (props) => {
    const {data, setData, isEditable, onBrandDetailsSubmit, submitLoading} = props;
    const {t} = useTranslation();

    const handleSetValue = (e, field) => {
        setData((prevState) => {
            return {
                ...prevState,
                [field]: e,
            };
        });
    };

    return (
        <>
            <Row gutter={[48, 48]} style={{marginTop: "30px"}}>
                <Col xs={24} md={12}>
                    <Space direction={"vertical"} size={"large"} style={{width: "100%"}}>
                        <Col xs={24} md={24}>
                            <InputFormItem
                                name={"companyNo"}
                                label={t("brandSetting.companyNo")}
                                layout={verticalFormLayout}
                                value={data?.companyNo || ""}
                                onChange={(e) => handleSetValue(e, "companyNo")}
                                disabled={!isEditable}
                            />
                        </Col>
                        <Col xs={24} md={24}>
                            <InputFormItem
                                name={"licenseNo"}
                                label={t("brandSetting.licenseNo")}
                                layout={verticalFormLayout}
                                value={data?.licenseNo || ""}
                                onChange={(e) => handleSetValue(e, "licenseNo")}
                                disabled={!isEditable}
                            />
                        </Col>
                        <Col xs={24} md={24}>
                            <SelectFormItem
                                name={"incorporationCountry"}
                                label={t("brandSetting.incorporationCountry")}
                                layout={verticalFormLayout}
                                value={data?.incorporationCountry || ""}
                                options={countryList_Language?.map((v) => ({...v, label: t(v.label)}))}
                                onChange={(e) => handleSetValue(e, "incorporationCountry")}
                                disabled={!isEditable}
                            />
                        </Col>
                    </Space>
                </Col>
                <Col xs={24} md={12}>
                    <Space direction={"vertical"} size={"large"} style={{width: "100%"}}>
                        <TimezoneSelectFormItem
                            name={"timezone"}
                            label={t("brandSetting.timezone")}
                            value={data?.timezone || ""}
                            onChange={(e) => handleSetValue(e, "timezone")}
                            layout={verticalFormLayout}
                            disabled={!isEditable}
                        />
                        <SwitchFormItem
                            name={"pushNotification"}
                            label={t("brandSetting.pushNotification")}
                            value={data?.pushNotification ?? false}
                            onChange={(e) => handleSetValue(e, "pushNotification")}
                            disabled={!isEditable}
                            className={"push-notification-switch"}
                        />
                        <Row>
                            <Col xs={24} md={12}>
                                <RadioGroupFormItem
                                    name={"theme"}
                                    label={t("brandSetting.theme")}
                                    options={[
                                        {label: t("Light"), value: "light"},
                                        {label: t("Dark"), value: "dark"},
                                        {label: t("User Device Setting"), value: "deviceSetting"},
                                    ]}
                                    defaultValue={data?.theme || ""}
                                    direction={"vertical"}
                                    layout={verticalFormLayout}
                                    onChange={(e) => handleSetValue(e, "theme")}
                                    disabled={!isEditable}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <RadioGroupFormItem
                                    name={"layout"}
                                    label={t("brandSetting.layout")}
                                    options={[
                                        {label: t("Layout 1 - Classic"), value: "1"},
                                        {label: t("Layout 2 - Professional"), value: "2"},
                                        {label: t("Layout 3 - Modern"), value: "3"},
                                        {label: t("Layout 4 - Standard"), value: "4"},
                                        {label: t("Layout 5 - Grid"), value: "5"},
                                    ]}
                                    direction={"vertical"}
                                    defaultValue={data?.layout || ""}
                                    layout={verticalFormLayout}
                                    onChange={(e) => handleSetValue(e, "layout")}
                                    disabled={!isEditable}
                                />
                            </Col>
                        </Row>
                    </Space>
                </Col>
                <Col xs={2}></Col>
            </Row>
            {isEditable && (
                <Row className={"margin-top-0-75"} justify={"end"}>
                    <Button onClick={onBrandDetailsSubmit} loading={submitLoading} type={"primary"}>
                        {t("common.update")}
                    </Button>
                </Row>
            )}
        </>
    );
};

export default OtherForm;
