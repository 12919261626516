import {Button, Checkbox, Col, Form, message, Row, Select, Switch} from "antd";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useRecoilState, useSetRecoilState} from "recoil";
import {notificationMessage, selfPermissionObj} from "../../../../../../../../recoil_state";
import {getProduct} from "../../../../../../../api/graphql/product";
import {useBatchUpdateTradingAccountMutation} from "../../../../../../../api/graphql/tradingAccount";
import useAuthorize from "../../../../../../../_common/authorize";
import {batchUpdateTradingAccountMt5} from "../../../../../../../api/graphql/tradingAccountMt5";


const TradingAccountBatchEditForm_1 = (props) => {

    const {batchUpdateRecord, setView,refetch} = props

    const {t} = useTranslation();
    const [form] = Form.useForm()
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const tradingAccountPermission = selfPermission?.tradingAccount?.account;
    const tradingAccountMt5Permission = selfPermission?.tradingAccountMt5?.account;
    const [product, setProduct] = useState([])
    const [leverage, setLeverage] = useState([])
    const [spreadGroup, setSpreadGroup] = useState([])
    const [btnLoading, setBtnLoading] = useState(false)
    const labelCol = {span: 7, offset: 0};
    const wrapperCol = {span: 18, offset: 0};

    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {batchUpdateTradingAccount} = useBatchUpdateTradingAccountMutation()
    const {systemErrorNotification} = useAuthorize()

    const {data} = getProduct({filter: null})

    const preload = async (data) => {
        const products = data?.getProduct?.data || []

        let validProduct: any = []

        await Promise.all(products && products?.map((item) => {
            if (item?.server?._id === batchUpdateRecord?.[0]?.server) {
                validProduct.push({
                    label: item?.name,
                    value: item?._id
                })
            }
        }))

        setProduct(validProduct)
    }

    const productOnChange = async (e) => {
        const products = data?.getProduct?.data || []

        const details = products.find((item) => item._id === e)

        form.resetFields(['spreadGroup', 'leverage']);
        const leverages = details?.leverage?.map((item) => {
            return {
                label: item,
                value: item
            }
        })

        let spreadGroups: any = []
        if (batchUpdateRecord?.[0]?.platform === "mt5") {
            await Promise.all(details?.spreadGroupMt5?.map((item) => {
                spreadGroups.push({
                    label: item?.group,
                    value: item?._id
                })
            }))
        } else {
            await Promise.all(details?.spreadGroup?.map((item) => {
                spreadGroups.push({
                    label: item?.name,
                    value: item?._id
                })
            }))
        }

        setLeverage(leverages)
        setSpreadGroup(spreadGroups)
    }

    useEffect(() => {

        preload(data)

    }, [data])

    const onSubmit = async (values) => {
        await setBtnLoading(true)
        let fieldNames: any = []
        for (const property in values) {
            if (property.endsWith("Checked") && values[property]) {
                fieldNames.push(property?.replace("Checked", ""))
            }
        }

        const filterInputFunction = (obj, keys) => {
            return keys.reduce((acc, key) => {
                if (obj?.hasOwnProperty(key)) {
                    acc[key] = obj[key];
                }
                return acc;
            }, {});
        }

        const ids = await Promise.all(batchUpdateRecord && batchUpdateRecord?.map((item) => {
            return item.parentId
        }))

        const filteredInput = await filterInputFunction(values, fieldNames);

        try {
            if (fieldNames?.length > 0) {

                if (batchUpdateRecord?.[0]?.platform === "mt5") {
                    const response = await batchUpdateTradingAccountMt5(ids, filteredInput)
                    if (response?.batchUpdateTradingAccountMt5.__typename === "BatchUpdateTradingAccountMt5") {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("common.updateSuccess"),
                            key: "batchUpdateTradingAccountMt5",
                        });
                        refetch()
                    } else if (response?.batchUpdateTradingAccountMt5.__typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(response?.batchUpdateTradingAccountMt5?.errKey),
                            key: "batchUpdateTradingAccountMt5",
                        });
                    } else {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(response?.batchUpdateTradingAccountMt5?.msg),
                            key: "batchUpdateTradingAccountMt5",
                        });
                    }
                } else {
                    const response = await batchUpdateTradingAccount(ids, filteredInput)

                    if (response?.data?.batchUpdateTradingAccount.__typename === "TradingAccountAry") {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("common.updateSuccess"),
                            key: "batchUpdateTradingAccount",
                        });
                        refetch()
                    } else if (response?.data?.batchUpdateTradingAccount.__typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(response?.data?.batchUpdateTradingAccount?.errKey),
                            key: "batchUpdateTradingAccount",
                        });
                    } else {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(response?.data?.batchUpdateTradingAccount?.msg),
                            key: "batchUpdateTradingAccount",
                        });
                    }
                }


                setView(false)
            } else {
                message.error("Please fill in at least one field");
            }
        } catch (e) {
            systemErrorNotification()
        } finally {
            setBtnLoading(false)
        }

    }

    const rightsListing = [
        {
            value: "USER_RIGHT_ENABLED",
            label: "USER_RIGHT_ENABLED",
        },
        {
            value: "USER_RIGHT_PASSWORD",
            label: "USER_RIGHT_PASSWORD",
        },
        {
            value: "USER_RIGHT_TRADE_DISABLED",
            label: "USER_RIGHT_TRADE_DISABLED",
        },
        {
            value: "USER_RIGHT_INVESTOR",
            label: "USER_RIGHT_INVESTOR",
        },
        {
            value: "USER_RIGHT_CONFIRMED",
            label: "USER_RIGHT_CONFIRMED",
        },
        {
            value: "USER_RIGHT_TRAILING",
            label: "USER_RIGHT_TRAILING",
        },
        {
            value: "USER_RIGHT_EXPERT",
            label: "USER_RIGHT_EXPERT",
        },
        {
            value: "USER_RIGHT_OBSOLETE",
            label: "USER_RIGHT_OBSOLETE",
        },
        {
            value: "USER_RIGHT_REPORTS",
            label: "USER_RIGHT_REPORTS",
        },
        {
            value: "USER_RIGHT_READONLY",
            label: "USER_RIGHT_READONLY",
        },
        {
            value: "USER_RIGHT_RESET_PASS",
            label: "USER_RIGHT_RESET_PASS",
        },
        {
            value: "USER_RIGHT_OTP_ENABLED",
            label: "USER_RIGHT_OTP_ENABLED",
        },
        {
            value: "USER_RIGHT_SPONSORED_HOSTING",
            label: "USER_RIGHT_SPONSORED_HOSTING",
        },
        {
            value: "USER_RIGHT_API_ENABLED",
            label: "USER_RIGHT_API_ENABLED",
        },
    ]

    return (
        <Col span={8} style={{marginTop: "15px"}}>
            {
                batchUpdateRecord?.[0]?.platform === "mt5" ? (
                    <div>
                        <Form
                            form={form}
                            className={"margin-bottom-0-75"}
                            colon={false}
                            labelAlign={"left"}
                            onFinish={(value) => onSubmit(value)}>

                            {
                                tradingAccountMt5Permission?.view?.product && (
                                    <Row>
                                        <Col span={24} className={"display-flex"}>
                                            <Form.Item name={"productChecked"} valuePropName="checked"
                                                       className={"margin-right-0-5"}>
                                                <Checkbox/>
                                            </Form.Item>
                                            <Form.Item label={t("tradingAccount.product")}
                                                       name={"product"}
                                                       labelCol={labelCol}
                                                       wrapperCol={wrapperCol}
                                                       style={{width: "100%"}}
                                            >
                                                <Select onChange={productOnChange} options={product} showSearch={true}
                                                        optionFilterProp={"label"}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )
                            }
                            {
                                tradingAccountMt5Permission?.view?.spreadGroup && (
                                    <Row>
                                        <Col span={24} className={"display-flex"}>
                                            <Form.Item name={"spreadGroupChecked"} valuePropName="checked"
                                                       className={"margin-right-0-5"}>
                                                <Checkbox/>
                                            </Form.Item>
                                            <Form.Item label={t("tradingAccount.spread_group")}
                                                       name={"spreadGroup"}
                                                       labelCol={labelCol}
                                                       wrapperCol={wrapperCol}
                                                       style={{width: "100%"}}
                                            >
                                                <Select options={spreadGroup} showSearch={true}
                                                        optionFilterProp={"label"}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )
                            }
                            {
                                tradingAccountMt5Permission?.view?.leverage && (
                                    <Row>
                                        <Col span={24} className={"display-flex"}>
                                            <Form.Item name={"leverageChecked"} valuePropName="checked"
                                                       className={"margin-right-0-5"}>
                                                <Checkbox/>
                                            </Form.Item>
                                            <Form.Item label={t("tradingAccount.leverage")}
                                                       name={"leverage"}
                                                       labelCol={labelCol}
                                                       wrapperCol={wrapperCol}
                                                       style={{width: "100%"}}
                                            >
                                                <Select options={leverage} showSearch={true}
                                                        optionFilterProp={"label"}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )
                            }
                            <Row>
                                <Col span={24} className={"display-flex"}>
                                    <Form.Item name={"mt5RightsChecked"} valuePropName="checked"
                                               className={"margin-right-0-5"}>
                                        <Checkbox/>
                                    </Form.Item>
                                    <Form.Item label={t("tradingAccount.rights")}
                                               name={"mt5Rights"}
                                               labelCol={labelCol}
                                               wrapperCol={wrapperCol}
                                               style={{width: "100%"}}
                                    >
                                        <Select options={rightsListing} showSearch={true} mode="multiple"
                                                optionFilterProp={"label"}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className={"display-flex"}>
                                    <Form.Item name={"hasDepositPermissionChecked"} valuePropName="checked"
                                               className={"margin-right-0-5"}>
                                        <Checkbox/>
                                    </Form.Item>
                                    <Form.Item label={t("tradingAccount.is_deposit_enabled")}
                                               name={"hasDepositPermission"}
                                               labelCol={labelCol}
                                               wrapperCol={wrapperCol}
                                               style={{width: "100%"}}
                                               initialValue={false}
                                    >
                                        <Switch/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className={"display-flex"}>
                                    <Form.Item name={"hasWithdrawalPermissionChecked"} valuePropName="checked"
                                               className={"margin-right-0-5"}>
                                        <Checkbox/>
                                    </Form.Item>
                                    <Form.Item label={t("tradingAccount.is_withdrawal_enabled")}
                                               name={"hasWithdrawalPermission"}
                                               labelCol={labelCol}
                                               wrapperCol={wrapperCol}
                                               style={{width: "100%"}}
                                               initialValue={false}
                                    >
                                        <Switch/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className={"display-flex"}>
                                    <Form.Item name={"isDefaultChecked"} valuePropName="checked"
                                               className={"margin-right-0-5"}>
                                        <Checkbox/>
                                    </Form.Item>
                                    <Form.Item label={t("tradingAccount.is_default")}
                                               name={"isDefault"}
                                               labelCol={labelCol}
                                               wrapperCol={wrapperCol}
                                               style={{width: "100%"}}
                                               initialValue={false}
                                    >
                                        <Switch/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className={"display-flex"}>
                                    <Form.Item name={"isPrivateChecked"} valuePropName="checked"
                                               className={"margin-right-0-5"}>
                                        <Checkbox/>
                                    </Form.Item>
                                    <Form.Item label={t("tradingAccount.is_private")}
                                               name={"isPrivate"}
                                               labelCol={labelCol}
                                               wrapperCol={wrapperCol}
                                               style={{width: "100%"}}
                                               initialValue={false}
                                    >
                                        <Switch/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className={"display-flex"}>
                                    <Form.Item name={"isSuspiciousChecked"} valuePropName="checked"
                                               className={"margin-right-0-5"}>
                                        <Checkbox/>
                                    </Form.Item>
                                    <Form.Item label={t("tradingAccount.is_suspicious")}
                                               name={"isSuspicious"}
                                               labelCol={labelCol}
                                               wrapperCol={wrapperCol}
                                               style={{width: "100%"}}
                                               initialValue={false}
                                    >
                                        <Switch/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Col span={24} style={{textAlign: "right"}}>
                                <Button type={"primary"} htmlType={"submit"} loading={btnLoading} disabled={btnLoading}>
                                    {t("common.submit")}
                                </Button>
                            </Col>
                        </Form>
                    </div>
                ) : (
                    <div>
                        <Form
                            form={form}
                            className={"margin-bottom-0-75"}
                            colon={false}
                            labelAlign={"left"}
                            onFinish={(value) => onSubmit(value)}>
                            {
                                tradingAccountPermission?.view?.product && (
                                    <Row>
                                        <Col span={24} className={"display-flex"}>
                                            <Form.Item name={"productChecked"} valuePropName="checked"
                                                       className={"margin-right-0-5"}>
                                                <Checkbox/>
                                            </Form.Item>
                                            <Form.Item label={t("tradingAccount.product")}
                                                       name={"product"}
                                                       labelCol={labelCol}
                                                       wrapperCol={wrapperCol}
                                                       style={{width: "100%"}}
                                            >
                                                <Select onChange={productOnChange} options={product} showSearch={true}
                                                        optionFilterProp={"label"}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )
                            }
                            {
                                tradingAccountPermission?.view?.spreadGroup && (
                                    <Row>
                                        <Col span={24} className={"display-flex"}>
                                            <Form.Item name={"spreadGroupChecked"} valuePropName="checked"
                                                       className={"margin-right-0-5"}>
                                                <Checkbox/>
                                            </Form.Item>
                                            <Form.Item label={t("tradingAccount.spread_group")}
                                                       name={"spreadGroup"}
                                                       labelCol={labelCol}
                                                       wrapperCol={wrapperCol}
                                                       style={{width: "100%"}}
                                            >
                                                <Select options={spreadGroup} showSearch={true}
                                                        optionFilterProp={"label"}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )
                            }
                            {
                                tradingAccountPermission?.view?.leverage && (
                                    <Row>
                                        <Col span={24} className={"display-flex"}>
                                            <Form.Item name={"leverageChecked"} valuePropName="checked"
                                                       className={"margin-right-0-5"}>
                                                <Checkbox/>
                                            </Form.Item>
                                            <Form.Item label={t("tradingAccount.leverage")}
                                                       name={"leverage"}
                                                       labelCol={labelCol}
                                                       wrapperCol={wrapperCol}
                                                       style={{width: "100%"}}
                                            >
                                                <Select options={leverage} showSearch={true}
                                                        optionFilterProp={"label"}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )
                            }
                            <Row>
                                <Col span={24} className={"display-flex"}>
                                    <Form.Item name={"hasTradingPermissionChecked"} valuePropName="checked"
                                               className={"margin-right-0-5"}>
                                        <Checkbox/>
                                    </Form.Item>
                                    <Form.Item label={t("tradingAccount.trading_permission")}
                                               name={"hasTradingPermission"}
                                               labelCol={labelCol}
                                               wrapperCol={wrapperCol}
                                               style={{width: "100%"}}
                                               initialValue={false}
                                    >
                                        <Switch/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className={"display-flex"}>
                                    <Form.Item name={"isLoginEnabledChecked"} valuePropName="checked"
                                               className={"margin-right-0-5"}>
                                        <Checkbox/>
                                    </Form.Item>
                                    <Form.Item label={t("tradingAccount.login_enabled")}
                                               name={"isLoginEnabled"}
                                               labelCol={labelCol}
                                               wrapperCol={wrapperCol}
                                               style={{width: "100%"}}
                                               initialValue={false}
                                    >
                                        <Switch/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className={"display-flex"}>
                                    <Form.Item name={"isSendReportChecked"} valuePropName="checked"
                                               className={"margin-right-0-5"}>
                                        <Checkbox/>
                                    </Form.Item>
                                    <Form.Item label={t("tradingAccount.send_report")}
                                               name={"isSendReport"}
                                               labelCol={labelCol}
                                               wrapperCol={wrapperCol}
                                               style={{width: "100%"}}
                                               initialValue={false}
                                    >
                                        <Switch/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className={"display-flex"}>
                                    <Form.Item name={"hasDepositPermissionChecked"} valuePropName="checked"
                                               className={"margin-right-0-5"}>
                                        <Checkbox/>
                                    </Form.Item>
                                    <Form.Item label={t("tradingAccount.is_deposit_enabled")}
                                               name={"hasDepositPermission"}
                                               labelCol={labelCol}
                                               wrapperCol={wrapperCol}
                                               style={{width: "100%"}}
                                               initialValue={false}
                                    >
                                        <Switch/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className={"display-flex"}>
                                    <Form.Item name={"hasWithdrawalPermissionChecked"} valuePropName="checked"
                                               className={"margin-right-0-5"}>
                                        <Checkbox/>
                                    </Form.Item>
                                    <Form.Item label={t("tradingAccount.is_withdrawal_enabled")}
                                               name={"hasWithdrawalPermission"}
                                               labelCol={labelCol}
                                               wrapperCol={wrapperCol}
                                               style={{width: "100%"}}
                                               initialValue={false}
                                    >
                                        <Switch/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className={"display-flex"}>
                                    <Form.Item name={"isDefaultChecked"} valuePropName="checked"
                                               className={"margin-right-0-5"}>
                                        <Checkbox/>
                                    </Form.Item>
                                    <Form.Item label={t("tradingAccount.is_default")}
                                               name={"isDefault"}
                                               labelCol={labelCol}
                                               wrapperCol={wrapperCol}
                                               style={{width: "100%"}}
                                               initialValue={false}
                                    >
                                        <Switch/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className={"display-flex"}>
                                    <Form.Item name={"isPrivateChecked"} valuePropName="checked"
                                               className={"margin-right-0-5"}>
                                        <Checkbox/>
                                    </Form.Item>
                                    <Form.Item label={t("tradingAccount.is_private")}
                                               name={"isPrivate"}
                                               labelCol={labelCol}
                                               wrapperCol={wrapperCol}
                                               style={{width: "100%"}}
                                               initialValue={false}
                                    >
                                        <Switch/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className={"display-flex"}>
                                    <Form.Item name={"isSuspiciousChecked"} valuePropName="checked"
                                               className={"margin-right-0-5"}>
                                        <Checkbox/>
                                    </Form.Item>
                                    <Form.Item label={t("tradingAccount.is_suspicious")}
                                               name={"isSuspicious"}
                                               labelCol={labelCol}
                                               wrapperCol={wrapperCol}
                                               style={{width: "100%"}}
                                               initialValue={false}
                                    >
                                        <Switch/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Col span={24} style={{textAlign: "right"}}>
                                <Button type={"primary"} htmlType={"submit"} loading={btnLoading} disabled={btnLoading}>
                                    {t("common.submit")}
                                </Button>
                            </Col>
                        </Form>

                    </div>
                )
            }

        </Col>
    )
}

export default TradingAccountBatchEditForm_1