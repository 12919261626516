import React, {useState} from "react";
import {Button, Col, Modal, Space, Spin} from "antd";
import {
    getCrmTaskApprovalDetail,
    useApproveCrmTaskMutation,
    useRejectCrmTaskMutation,
    useUnclaimedCrmTaskMutation,
    useUpdateCrmTaskMutation,
} from "../../../../../api/graphql/crmTask";
import ActionApprovalLayout_1 from "./moduleTaskLayout/_actionApprovalLayout";
import TradingAccountLayout_1 from "./moduleTaskLayout/tradingAccount/_tradingAccountWithdrawalLayout";
import TradingAccountTransactionLayout_1 from "./moduleTaskLayout/tradingAccount/_tradingAccountTransactionLayout";
import TradingAccountCreditLayout_1 from "./moduleTaskLayout/tradingAccount/_tradingAccountCreditLayout";
import TradingAccountTradingVoucherLayout_1 from "./moduleTaskLayout/tradingAccount/_tradingAccountTradingVoucherLayout";
import EwalletDepositLayout_1 from "./moduleTaskLayout/eWallet/_ewalletDepositLayout";
import EwalletWithdrawalLayout_1 from "./moduleTaskLayout/eWallet/_ewalletWithdrawalLayout";
import EwalletAdjustmentLayout_1 from "./moduleTaskLayout/eWallet/_ewalletAdjustmentLayout";
import EwalletTransferLayout_1 from "./moduleTaskLayout/eWallet/_ewalletTransferLayout";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {notificationMessage, selfPermissionObj} from "../../../../../../recoil_state";
import useAuthorize from "../../../../../_common/authorize";
import {useTranslation} from "react-i18next";
import AccountVerificationApprovalLayout_1 from "./moduleTaskLayout/accountVerification/_accountVerificationApprovalLayout";
import CommLogPaymentTable from "../../../../settings/commissionRuleSetting/batchDetails/view/layout1/component/commLogPaymentTable";
import PspSettlementLayout_1 from "./moduleTaskLayout/pspSettlementAdjustments/_pspSettlementLayout";
import PspAdjustmentLayout_1 from "./moduleTaskLayout/pspSettlementAdjustments/_pspAdjustmentLayout";
import EWalletUpdatePaymentMethodModal from "./moduleTaskLayout/eWallet/_ewalletUpdatePaymentMethodModal";
import EWalletUpdateShiftUserModal from "./moduleTaskLayout/eWallet/_ewalletShiftUserModal";
import TradingAccountMt5TransactionLayout_1 from "./moduleTaskLayout/tradingAccount/_tradingAccountMt5TransactionLayout";
import TradingAccountMt5CreditLayout_1 from "./moduleTaskLayout/tradingAccount/_tradingAccountMt5CreditLayout";
import TradingAccountMt5TradingVoucherLayout_1 from "./moduleTaskLayout/tradingAccount/_tradingAccountMt5TradingVoucherLayout";
import TradingAccountMt5Layout_1 from "./moduleTaskLayout/tradingAccount/_tradingAccountMt5WithdrawalLayout";
import {useSetDropdown} from "../../../../../_common/dropdowns";

const TaskModal = (props) => {
    const {isOpen, closeModal, id, mode, refetch} = props;
    const {t} = useTranslation();
    const {data, loading} = getCrmTaskApprovalDetail(id);
    const {setUpUserOptions} = useSetDropdown()

    const [unclaimedBtnLoading, setUnclaimedBtnLoading] = useState(false);
    const [approveBtnLoading, setApproveBtnLoading] = useState(false);
    const [rejectBtnLoading, setRejectBtnLoading] = useState(false);
    const [comment, setComment] = useState(null);
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {approveCrmTask} = useApproveCrmTaskMutation();
    const {rejectCrmTask} = useRejectCrmTaskMutation();
    const {unClaimCrmTask} = useUnclaimedCrmTaskMutation();
    const {updateCrmTask} = useUpdateCrmTaskMutation();
    const {systemErrorNotification} = useAuthorize();
    const [updatedPayload, setUpdatedPayload] = useState(null);
    const [isDynamicFormValid, setDynamicFormValid] = useState({validate: true, errorMessage: ""});
    const currentUserId = localStorage.getItem("g_userId") || localStorage.getItem("userId");
    const [subModal, setSubModal] = useState(false)
    const [shiftUserModal, setShiftUserModal] = useState(false)
    const permission = useRecoilValue<any>(selfPermissionObj);

    const handleDynamicFormValidation = (isValidObj) => {
        setDynamicFormValid(isValidObj);
    };

    if (id === undefined) {
        closeModal();
    }

    const resetForm = () => {
        setComment(null);
        setUpdatedPayload(null);
        setDynamicFormValid({validate: true, errorMessage: ""});
    };

    const title = mode === "verify" ? t("task.verify_task") : t("task.view_details");

    const taskDetail = data?.getCrmTaskApprovalDetails || {};

    const approveTaskAction = async (isRerun?) => {
        try {
            if (isDynamicFormValid.validate) {
                await setApproveBtnLoading(true);
                let response: any;

                if (updatedPayload !== null) {
                    response = await approveCrmTask(taskDetail?._id, updatedPayload, isRerun);
                } else if (comment) {
                    response = await approveCrmTask(taskDetail?._id, {comment: comment}, isRerun);
                } else {
                    response = await approveCrmTask(taskDetail?._id, null, isRerun);
                }
                if (response?.data?.approveCrmTask?.__typename === "CrmTaskApproval") {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: t("task.task_approved"),
                        key: "approveCrmTask",
                    });

                    if (taskDetail?.functionName === "users.createClientUser") {
                        setUpUserOptions()
                    }

                    setApproveBtnLoading(false);
                    closeModal();
                    resetForm();
                } else if (response?.data?.approveCrmTask?.__typename === "BaseError") {
                    if (response?.data?.approveCrmTask?.errKey === "admin.err.unique_field_found") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t("admin.err.unique_field_found", {
                                field: response?.data?.approveCrmTask?.errObj?.field?.map((d) => t(`user.${d}`))?.join(", "),
                            }),
                            key: "approveCrmTask",
                        });
                    } else {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(response?.data?.approveCrmTask?.errKey),
                            key: "approveCrmTask",
                        });
                    }
                    setApproveBtnLoading(false);
                    closeModal();
                    resetForm();
                } else {
                    setNotificationMessage({
                        status: "info",
                        title: "",
                        msg: t(response?.data?.approveCrmTask?.msg),
                        key: "approveCrmTask",
                    });
                    setApproveBtnLoading(false);
                    closeModal();
                    resetForm();
                }
            } else {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: isDynamicFormValid.errorMessage ?? "",
                    key: "systemNotification",
                });
                closeModal();
                resetForm();
            }
        } catch (e) {
            systemErrorNotification();
            setApproveBtnLoading(false);
        } finally {
            refetch();
        }
    };

    const rejectTaskAction = async () => {
        try {
            await setRejectBtnLoading(true);
            let response: any;
            if (comment) {
                response = await rejectCrmTask(taskDetail?._id, comment);
            } else {
                response = await rejectCrmTask(taskDetail?._id);
            }
            if (response?.data?.rejectCrmTask?.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("task.task_rejected"),
                    key: "rejectCrmTask",
                });
                setRejectBtnLoading(false);
                closeModal();
                resetForm();
            } else if (response?.data?.rejectCrmTask?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.data?.rejectCrmTask?.errKey),
                    key: "rejectCrmTask",
                });
                setRejectBtnLoading(false);
                closeModal();
                resetForm();
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.data?.rejectCrmTask?.msg),
                    key: "rejectCrmTask",
                });
                setRejectBtnLoading(false);
                closeModal();
                resetForm();
            }
        } catch (e) {
            systemErrorNotification();
            setRejectBtnLoading(false);
        } finally {
            refetch();
        }
    };

    const unclaimedTaskAction = async () => {
        try {
            await setUnclaimedBtnLoading(true);
            const response = await unClaimCrmTask(currentUserId, taskDetail?._id);
            if (response?.data?.unClaimCrmTask?.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Task Unclaimed"),
                    key: "unClaimCrmTask",
                });
                setUnclaimedBtnLoading(false);
            } else if (response?.data?.unClaimCrmTask?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.data?.unClaimCrmTask?.errKey),
                    key: "unClaimCrmTask",
                });
                setUnclaimedBtnLoading(false);
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.data?.unClaimCrmTask?.msg),
                    key: "unClaimCrmTask",
                });
                setUnclaimedBtnLoading(false);
            }
        } catch (e) {
            systemErrorNotification();
            setUnclaimedBtnLoading(false);
        }
    };

    const renderLayoutComponent = () => {
        if (
            taskDetail?.mainModuleName === "Trading Account" &&
            (taskDetail?.subModuleName === "Withdrawal" || taskDetail?.subModuleName === "Deposit") &&
            taskDetail?.actionType === "crmUserAction"
        ) {
            return <TradingAccountTransactionLayout_1 data={taskDetail} mode={mode}/>;
        }
        if (
            taskDetail?.mainModuleName === "Trading Account" &&
            (taskDetail?.subModuleName === "Credit In" || taskDetail?.subModuleName === "Credit Out")
        ) {
            return <TradingAccountCreditLayout_1 data={taskDetail} mode={mode}/>;
        }
        if (
            taskDetail?.mainModuleName === "Trading Account" &&
            (taskDetail?.subModuleName === "Voucher In" || taskDetail?.subModuleName === "Voucher Out")
        ) {
            return <TradingAccountTradingVoucherLayout_1 data={taskDetail} mode={mode}/>;
        }

        if (
            taskDetail?.mainModuleName === "Trading Account Mt5" &&
            (taskDetail?.subModuleName === "Withdrawal" || taskDetail?.subModuleName === "Deposit") &&
            taskDetail?.actionType === "crmUserAction"
        ) {
            return <TradingAccountMt5TransactionLayout_1 data={taskDetail} mode={mode}/>;
        }
        if (
            taskDetail?.mainModuleName === "Trading Account Mt5" &&
            (taskDetail?.subModuleName === "Credit In" || taskDetail?.subModuleName === "Credit Out")
        ) {
            return <TradingAccountMt5CreditLayout_1 data={taskDetail} mode={mode}/>;
        }
        if (
            taskDetail?.mainModuleName === "Trading Account Mt5" &&
            (taskDetail?.subModuleName === "Voucher In" || taskDetail?.subModuleName === "Voucher Out")
        ) {
            return <TradingAccountMt5TradingVoucherLayout_1 data={taskDetail} mode={mode}/>;
        }

        if (taskDetail?.mainModuleName === "Client" && taskDetail?.subModuleName === "E-Wallet Deposit") {
            return (
                <EwalletDepositLayout_1
                    item={taskDetail}
                    mode={mode}
                    setUpdatedPayload={setUpdatedPayload}
                    updatedPayload={updatedPayload}
                    handleValidation={handleDynamicFormValidation}
                    setComment={setComment}
                />
            );
        }
        if (taskDetail?.mainModuleName === "Client" && taskDetail?.subModuleName === "E-Wallet Withdrawal") {
            return (
                <EwalletWithdrawalLayout_1
                    item={taskDetail}
                    mode={mode}
                    setUpdatedPayload={setUpdatedPayload}
                    updatedPayload={updatedPayload}
                    setComment={setComment}
                />
            );
        }
        if (taskDetail?.mainModuleName === "Client" && taskDetail?.subModuleName === "E-Wallet Adjustment") {
            return <EwalletAdjustmentLayout_1 data={taskDetail} mode={mode}/>;
        }
        if (taskDetail?.mainModuleName === "Client" && taskDetail?.subModuleName === "E-Wallet Transfer") {
            return <EwalletTransferLayout_1 data={taskDetail} mode={mode}/>;
        }

        if (taskDetail?.mainModuleName === "Commission" && taskDetail?.subModuleName === "Payout") {
            return <CommLogPaymentTable id={taskDetail?.payload?.batchId} task={true}/>;
        }

        if (taskDetail?.mainModuleName === "Reports" && taskDetail?.subModuleName === "Psp Settlement") {
            return <PspSettlementLayout_1 data={taskDetail} mode={mode}/>;
        }

        if (taskDetail?.mainModuleName === "Reports" && taskDetail?.subModuleName === "Psp Adjustment") {
            return <PspAdjustmentLayout_1 data={taskDetail} mode={mode}/>;
        }

        if (taskDetail?.actionType === "userVerificationApproval") {
            return <AccountVerificationApprovalLayout_1 data={taskDetail} mode={mode} setComment={setComment}
                                                        comment={comment}/>;
        }

        if (taskDetail?.mainModuleName === "Trading Account Mt5" && taskDetail?.actionType === "tradingAccountWithdrawal") {
            return <TradingAccountMt5Layout_1 data={taskDetail} mode={mode}/>;
        }

        if (taskDetail?.actionType === "tradingAccountWithdrawal") {
            return <TradingAccountLayout_1 data={taskDetail} mode={mode}/>;
        }

        if (taskDetail?.actionType === "crmUserAction") {
            return <ActionApprovalLayout_1 data={taskDetail} mode={mode}/>;
        }

        return <ActionApprovalLayout_1 data={taskDetail} mode={mode}/>;
    };

    const depositRegex = new RegExp("Pending approval from bank", "g");

    return (
        <Modal
            title={title}
            open={isOpen}
            onCancel={() => closeModal()}
            closable={true}
            footer={null}
            width={1200}
            destroyOnClose
            getContainer={"#task"}
        >
            {taskDetail && Object.keys(taskDetail)?.length > 0 ? renderLayoutComponent() : <Spin/>}

            {mode === "verify" &&
            taskDetail?.status === "pending approval" &&
            taskDetail?.description &&
            !depositRegex.test(taskDetail?.description) && (
                <Col span={24} style={{textAlign: "right", marginTop: "15px"}}>
                    <Space>
                        {
                            taskDetail?.moduleName === "eWalletWithdrawal" && permission.user.clientsUser.edit.ewalletEditPendingWithdrawal &&
                            <Button onClick={() => setSubModal(true)}>
                                {t("Change Payment Method")}
                            </Button>
                        }
                        <Button onClick={unclaimedTaskAction}
                                loading={unclaimedBtnLoading || approveBtnLoading || rejectBtnLoading} danger>
                            {t("task.unclaim")}
                        </Button>
                        <Button onClick={rejectTaskAction}
                                loading={approveBtnLoading || rejectBtnLoading || unclaimedBtnLoading} danger>
                            {t("task.reject")}
                        </Button>
                        <Button onClick={() => approveTaskAction(false)}
                                loading={approveBtnLoading || rejectBtnLoading || unclaimedBtnLoading}>
                            {t("task.approve")}
                        </Button>
                    </Space>
                </Col>
            )}
            {mode === "verify" && taskDetail?.status === "failed" && taskDetail?.isActionAvailable === true && (
                <Col span={24} style={{textAlign: "right", marginTop: "15px"}}>
                    <Space>
                        <Button onClick={rejectTaskAction} loading={rejectBtnLoading} danger>
                            {t("task.reject")}
                        </Button>
                        <Button onClick={() => approveTaskAction(true)} loading={approveBtnLoading}>
                            {t("task.rerun_approval")}
                        </Button>
                    </Space>
                </Col>
            )}

            {taskDetail?.moduleName === "eWalletDeposit" &&
            taskDetail?.status === "approved" &&
            permission.user.clientsUser.edit.ewalletEditDeposit &&
            (
                <Col span={24} style={{textAlign: "right", marginTop: "15px"}}>
                    <Space>
                        <Button onClick={() => setShiftUserModal(true)}>
                            {t("Shift User")}
                        </Button>
                    </Space>
                </Col>
            )}

            <EWalletUpdatePaymentMethodModal taskDetail={taskDetail} open={subModal} setOpen={setSubModal}/>
            <EWalletUpdateShiftUserModal taskDetail={taskDetail} open={shiftUserModal} setOpen={setShiftUserModal}/>
        </Modal>
    );
};

export default TaskModal;
