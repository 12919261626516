import {Button, Checkbox, message, Modal, Row, Space, Table, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import {DndContext} from "@dnd-kit/core";
import {arrayMove, SortableContext, useSortable, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {LockOutlined, MenuOutlined, UndoOutlined, UnlockOutlined} from "@ant-design/icons";
import {useUpdateUserTableAdminSetting} from "../../../api/graphql/tableSetting";
import {useTranslation} from "react-i18next";

const AdminTableForm = (props) => {
    const {tableName, displayTableName, server, tableColumn, open, hideModal, data, loading, showUserModal, resetColumnAry, resetColumnTooltipTitle} = props;
    const serverId = server?.id || "";
    const {t} = useTranslation();

    const [clicked, setClicked] = useState<any>([]);
    const [dataSource, setDataSource] = useState([
        {
            fieldName: "",
            isDisplayToUser: false,
            isSelectableByUser: false,
            isLock: false,
            key: 1,
        },
    ]);

    const {updateUserTableAdminSetting, loading: loadingUpdate} = useUpdateUserTableAdminSetting();

    const openUserSetting = () => {
        hideModal();
        showUserModal();
    };

    const TableRow = ({children, ...props}) => {
        const {attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging} = useSortable({
            id: props["data-row-key"],
        });
        const style = {
            ...props.style,
            transform: CSS?.Transform?.toString(
                transform && {
                    ...transform,
                    scaleY: 1,
                }
            )?.replace(/translate3d\(([^,]+),/, "translate3d(0,"),
            transition,
            ...(isDragging
                ? {
                    position: "relative",
                    zIndex: 9999,
                }
                : {}),
        };
        return (
            <tr {...props} ref={setNodeRef} style={style} {...attributes}>
                {React?.Children?.map(children, (child) => {
                    if (child?.key === "sort") {
                        return React?.cloneElement(child, {
                            children: (
                                <MenuOutlined
                                    ref={setActivatorNodeRef}
                                    style={{
                                        touchAction: "none",
                                        cursor: "move",
                                    }}
                                    {...listeners}
                                />
                            ),
                        });
                    }
                    return child;
                })}
            </tr>
        );
    };

    const onDragEnd = ({active, over}) => {
        if (active?.id !== over?.id) {
            setDataSource((previous) => {
                const activeIndex = previous.findIndex((i) => i?.key === active?.id);
                const overIndex = previous.findIndex((i) => i?.key === over?.id);
                return arrayMove(previous, activeIndex, overIndex);
            });
        }
    };

    const column = [
        {
            key: "sort",
            width: 20,
        },
        {
            key: "lock",
            dataIndex: "isLock",
            width: 20,
            onCell: (record, rowIndex) => {
                return {
                    onClick: () => {
                        if (record?.isDisplayToUser) {
                            const newData = [...dataSource];
                            newData[rowIndex]["isLock"] = !record?.isLock;
                            setDataSource(newData);
                        }
                    },
                };
            },
            render: (_, record, rowIndex) => {
                const lockIcon = record?.isLock ? <LockOutlined/> : <UnlockOutlined/>;
                return <>{record?.isDisplayToUser ? lockIcon : <UnlockOutlined style={{opacity: 0.2}}/>}</>;
            },
        },
        {
            title: t("common.column"),
            key: "title",
            dataIndex: "title",
        },
        {
            title: t("common.displayToUser"),
            dataIndex: "isDisplayToUser",
            key: "isDisplayToUser",
            render: (_, record, rowIndex) => {
                const current: any = clicked.find((item: any) => item.id === record?.title);
                const newData = [...dataSource];
                const indeterminate = record?.isSelectableByUser ? record?.isSelectableByUser && !record?.isDisplayToUser : current?.indeterminate;
                const check =
                    (record?.isDisplayToUser === true
                        ? record?.isSelectableByUser && record?.isDisplayToUser
                        : record?.isDisplayToUser === false
                            ? false
                            : current?.checked) && !indeterminate;

                return (
                    <Checkbox
                        name={"isDisplayToUser"}
                        indeterminate={indeterminate}
                        checked={check}
                        onClick={() => {
                            if (current) {
                                const {clickedNumber} = current;
                                let updatedClicked = [...clicked];

                                if (clickedNumber % 3 === 0) {
                                    updatedClicked = updatedClicked.map((item) =>
                                        item.id === current.id
                                            ? {
                                                ...item,
                                                checked: false,
                                                indeterminate: false,
                                                clickedNumber: clickedNumber + 1,
                                            }
                                            : item
                                    );
                                    newData[rowIndex]["isSelectableByUser"] = false;
                                    newData[rowIndex]["isDisplayToUser"] = false;
                                } else if (clickedNumber % 3 === 2 || clickedNumber === 2) {
                                    updatedClicked = updatedClicked.map((item) =>
                                        item.id === current.id
                                            ? {
                                                ...item,
                                                checked: true,
                                                indeterminate: false,
                                                clickedNumber: clickedNumber + 1,
                                            }
                                            : item
                                    );
                                    newData[rowIndex]["isDisplayToUser"] = true;
                                } else {
                                    updatedClicked = updatedClicked.map((item) =>
                                        item.id === current.id
                                            ? {
                                                ...item,
                                                checked: false,
                                                indeterminate: true,
                                                clickedNumber: clickedNumber + 1,
                                            }
                                            : item
                                    );
                                    newData[rowIndex]["isSelectableByUser"] = true;
                                }

                                setClicked(updatedClicked);
                                setDataSource(newData);
                            }
                        }}
                    />
                );
            },
        },
    ];

    const displayClick = async (index, obj) => {
        let ary = clicked || [];
        let num;

        if (obj?.isDisplayToUser && obj?.isSelectableByUser) num = 3;
        else if (obj?.isSelectableByUser && !obj?.isDisplayToUser) num = 2;
        else if (obj?.isDisplayToUser === false && obj?.isSelectableByUser === false) num = 1;
        else num = 3;

        const existingItemIndex = clicked?.findIndex((item) => item?.id === index);

        if (existingItemIndex === -1) {
            ary.push({
                id: index,
                clickedNumber: num,
                indeterminate: false,
                checked: true,
            });
        } else {
            ary[existingItemIndex] = {
                ...ary[existingItemIndex],
                clickedNumber: num,
                indeterminate: false,
                checked: true,
            };
        }

        setClicked(ary);
    };

    const setOriData = async (fieldData) => {
        const hasData = fieldData?.length > 0;
        return (
            (await tableColumn) &&
            (await Promise.all(
                tableColumn
                    ?.map(async (d, i) => {
                        const matchingObj = await fieldData?.find(
                            (initialObj) => initialObj?.fieldName === (Array?.isArray(d?.dataIndex) ? d?.dataIndex?.join("_") : d?.dataIndex)
                        );
                        await displayClick(d?.title, matchingObj?.length > 0 ? matchingObj : {});
                        return {
                            fieldName: matchingObj?.fieldName || (Array?.isArray(d?.dataIndex) ? d?.dataIndex?.join("_") : d?.dataIndex),
                            title: d?.title,
                            isDisplayToUser: hasData ? matchingObj?.isDisplayToUser ?? false : true,
                            isSelectableByUser: hasData ? matchingObj?.isSelectableByUser ?? false : true,
                            isLock: hasData ? matchingObj?.isLock : false,
                            defaultPosition: matchingObj?.defaultPosition || null,
                            key: i + 1,
                        };
                    })
                    ?.filter((d) => !d?.title)
            ))
        );
    };

    const resetData = (resetting = false) => {
        setClicked([]);
        setDataSource([
            {
                fieldName: "",
                isDisplayToUser: false,
                isSelectableByUser: false,
                isLock: false,
                key: 1,
            },
        ]);

        setOriData(resetting ? resetColumnAry : data?.fieldData).then((data) => setDataSource(data?.sort((a, b) => (a?.defaultPosition != null ? a?.defaultPosition : Infinity) - (b?.defaultPosition != null ? b?.defaultPosition : Infinity))));
    };

    useEffect(() => {
        if (tableColumn?.length > 0 && !loading) {
            resetData();
        }
    }, [tableColumn, loading, open]);

    const onConfirm = async () => {
        const formattedData = dataSource.map((item, index) => ({
            fieldName: item?.fieldName,
            isDisplayToUser: (item?.isDisplayToUser && item?.isSelectableByUser) || false,
            isSelectableByUser: item?.isSelectableByUser || false,
            isLock: (item?.isDisplayToUser && item?.isLock && item?.isSelectableByUser) || false,
            defaultPosition: index + 1,
        }));

        const displayingColumn = formattedData?.filter((item) => item?.isSelectableByUser === true);

        if (displayingColumn?.length < 1) {
            message.error(t("Please select at least one column"));
        } else {
            await updateUserTableAdminSetting({
                tableName: tableName,
                fieldData: formattedData,
                server: serverId,
            });
            hideModal();
        }
    };

    const onCancel = () => {
        resetData();
        hideModal();
    };

    const resetToDefault = async () => {
        await updateUserTableAdminSetting({
            tableName: tableName,
            fieldData: [],
            server: serverId,
        });
    };

    return (
        <Modal
            title={t(`common.table_columns_admin_setting`, {table: displayTableName ?? tableName})}
            open={open}
            footer={null}
            onCancel={onCancel}
            width={600}
            destroyOnClose={true}
        >
            {dataSource && (
                <DndContext onDragEnd={onDragEnd}>
                    <SortableContext items={dataSource?.map((i) => i.key)} strategy={verticalListSortingStrategy}>
                        <Table
                            className={"large-padding-table"}
                            components={{
                                body: {
                                    row: TableRow,
                                },
                            }}
                            rowKey="key"
                            columns={column}
                            dataSource={dataSource}
                            pagination={false}
                        />
                    </SortableContext>
                </DndContext>
            )}
            <Row justify={"space-between"} className={"margin-top-0-75"}>
                <Button type={"link"} onClick={openUserSetting} disabled={loading}>
                    {t("common.userSetting")}
                </Button>
                <Space>
                    {
                        resetColumnAry?.length > 0 &&
                        <Tooltip overlayStyle={{width: 200}} title={t(resetColumnTooltipTitle)}>
                            <Button onClick={() => resetData(true)} type={"link"} icon={<UndoOutlined/>}/>
                        </Tooltip>
                    }
                    <Button onClick={() => resetToDefault()}>{t("common.reset_to_default")}</Button>
                    <Button onClick={() => hideModal()}>{t("common.cancel")}</Button>
                    <Button loading={loadingUpdate} type={"primary"} onClick={() => onConfirm()}>
                        {t("common.confirm")}
                    </Button>
                </Space>
            </Row>
        </Modal>
    );
};

export default AdminTableForm;
