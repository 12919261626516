import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";

const GET_PSP_SETTLEMENT_COUNT = gql`
    query getPspSettlementCount($filter: PspSettlementInput) {
        getPspSettlementCount(filter: $filter)
    }
`;

const getPspSettlementCount = async ({filter}) => {
    try {
        const execute = await ApolloClientQuery(
            GET_PSP_SETTLEMENT_COUNT,
            {
                filter: {
                    pspSettingId: filter?.pspSettingId || null,
                    pspSettingCurrencyId: filter?.pspSettingCurrencyId || null,
                    settlementDate: filter?.settlementDate || null,
                    remark: filter?.remark || null,
                    convertedAmount: filter?.convertedAmount || null,
                    rate: filter?.rate || null,
                    excludeFee: filter?.excludeFee || null,
                    desks: filter?.desks || null,
                    isDeleted: filter?.isDeleted || null,
                },
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_PSP_SETTLEMENT_BY_ID = gql`
    query getPspSettlementByID($id: String!) {
        getPspSettlementByID(id: $id) {
            ... on PspSettlement {
                _id
                id
                pspSettingId {
                    _id
                    name
                    displayName
                }
                pspSettingCurrencyId {
                    _id
                    currency
                }
                settlementDate
                remark
                amount
                convertedAmount
                rate
                desks {
                    desk {
                        _id
                        name
                    }
                    percentage
                    value
                }
                fee
                convertedFee
                excludeFee
                isDeleted
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getPspSettlementById = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            GET_PSP_SETTLEMENT_BY_ID,
            {
                id: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_PSP_SETTLEMENT = gql`
    query getPspSettlement($filter: PspSettlementSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
        getPspSettlement(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on PspSettlementArray {
                total
                data {
                    _id
                    id
                    pspSettingId {
                        _id
                        displayName
                        name
                    }
                    pspSettingCurrencyId {
                        _id
                        currency
                    }
                    settlementDate
                    transactionNo
                    remark
                    amount
                    convertedAmount
                    rate
                    desks {
                        desk {
                            _id
                            name
                        }
                        percentage
                        value
                    }
                    fee
                    adjustedFee
                    convertedFee
                    adjustedConvertedFee
                    excludeFee
                    isDeleted
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getPspSettlement = async ({filter}, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_PSP_SETTLEMENT,
            {
                filter: {
                    transactionNo: filter?.transactionNo || null,
                    pspSettingId: filter?.pspSettingId || null,
                    pspSettingName: filter?.pspSettingName || null,
                    pspSettingDisplayName: filter?.pspSettingDisplayName || null,
                    pspSettingCurrencyId: filter?.pspSettingCurrencyId || null,
                    pspSettingCurrencyName: filter?.pspSettingCurrencyName || null,
                    amount: filter?.amount || null,
                    convertedAmount: filter?.convertedAmount || null,
                    fee: filter?.fee || null,
                    convertedFee: filter?.convertedFee || null,
                    rate: filter?.rate || null,
                    excludeFee: filter?.excludeFee,
                    deskName: filter?.deskName || null,
                    remark: filter?.remark || null,
                    isDeleted: filter?.isDeleted,
                },
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_PSP_SETTLEMENT_DROPDOWN_BASED_ON_PERMISSION = gql`
    query getPspSettlementDropdownBasedOnPermission($filter: PspSettlementSearchInput) {
        getPspSettlementDropdownBasedOnPermission(filter: $filter) {
            ... on PspSettlementArray {
                total
                data {
                    _id
                    id
                    pspSettingId {
                        _id
                        displayName
                        name
                    }
                    pspSettingCurrencyId {
                        _id
                        currency
                    }
                    settlementDate
                    transactionNo
                    remark
                    amount
                    convertedAmount
                    rate
                    desks {
                        desk {
                            _id
                            name
                        }
                        percentage
                        value
                    }
                    fee
                    adjustedFee
                    convertedFee
                    adjustedConvertedFee
                    excludeFee
                    isDeleted
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getPspSettlementDropdownBasedOnPermission = async ({filter}) => {
    try {
        const execute = await ApolloClientQuery(
            GET_PSP_SETTLEMENT_DROPDOWN_BASED_ON_PERMISSION,
            {
                variables: {
                    filter: filter
                },
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};


const ADD_PSP_SETTLEMENT = gql`
    mutation addPspSettlement($input: PspSettlementInput) {
        addPspSettlement(input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on PspSettlement {
                _id
                id
                pspSettingId {
                    _id
                    displayName
                }
                pspSettingCurrencyId {
                    _id
                    currency
                }
                settlementDate
                remark
                amount
                convertedAmount
                rate
                desks {
                    desk {
                        _id
                        name
                    }
                    percentage
                    value
                }
                fee
                convertedFee
                excludeFee
                isDeleted
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const addPspSettlement = async (input) => {
    const variables = {
        input: {
            pspSettingId: input?.pspSettingId || null,
            pspSettingCurrencyId: input?.pspSettingCurrencyId || null,
            settlementDate: input?.settlementDate || null,
            remark: input?.remark || null,
            convertedAmount: input?.convertedAmount || null,
            rate: input?.rate || null,
            excludeFee: input?.excludeFee || null,
            desks: input?.desks || null,
            // isDeleted: input?.isDeleted || null,
        },
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(ADD_PSP_SETTLEMENT, variables, true);

    return execute;
};

const UPDATE_PSP_ADJUSTMENT = gql`
    mutation updatePspSettlement($id: String!, $input: PspSettlementInput) {
        updatePspSettlement(id: $id, input: $input) {
            ... on PspSettlement {
                _id
                isDeleted
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const updatePspSettlement = async (id, input) => {
    const variables = {
        id: id,
        input: {
            pspSettingId: input?.pspSettingId || null,
            pspSettingCurrencyId: input?.pspSettingCurrencyId || null,
            settlementDate: input?.settlementDate || null,
            remark: input?.remark || null,
            convertedAmount: input?.convertedAmount || null,
            rate: input?.rate || null,
            excludeFee: input?.excludeFee || null,
            desks: input?.desks || null,
            // isDeleted: input?.isDeleted || null,
        },
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(UPDATE_PSP_ADJUSTMENT, variables, true);

    return execute;
};

const DELETE_PSP_ADJUSTMENT = gql`
    mutation deletePspSettlement($id: String!) {
        deletePspSettlement(id: $id) {
            ... on PspSettlement {
                _id
                isDeleted
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const deletePspSettlement = async (id) => {
    const variables = {
        id: id,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(DELETE_PSP_ADJUSTMENT, variables, true);

    return execute;
};

export {getPspSettlementCount, getPspSettlementById, getPspSettlement, addPspSettlement, updatePspSettlement, deletePspSettlement, getPspSettlementDropdownBasedOnPermission};
