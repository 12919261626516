import React, {useEffect} from "react";
import {BRAND_NAME} from "../../../../../config/brandVariable";
import CustomAction_1 from "./view/layout1";
import {useGetCustomAction} from "./function/useGetQueryHooks";
import {useGetCustomTrigger} from "../customTrigger/function/useGetQueryHooks";

const CustomAction = () => {
    let layout = 1;
    let component: any;
    const [data, fetch] = useGetCustomAction()
    const [customTriggerData, fetchCustomTrigger] = useGetCustomTrigger()

    useEffect(() => {
        fetch()
        fetchCustomTrigger()
    }, []);

    const props = {
        data: data,
        refetch: fetch,
        customTriggerData: customTriggerData
    }

    switch (layout) {
        case 1:
            component = <CustomAction_1 {...props} />
            break;

        default:
            component = <CustomAction_1 {...props} />
            break;
    }

    // Hard code automation setting for Antos and Exco
    return ["antospnc", "antospncOld", "blueocean", "excotrades", "ridgewaymarkets", "fundamarkets", "minosfutures", "cronoscap", ].includes(BRAND_NAME) ? component : <></>;
}

export default CustomAction