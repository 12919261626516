import React, {useState} from "react";
import {getColumnSearchProps, getDateRangeFilterProps,} from "../../../../../components/table/function/filterTable";
import moment from "moment";
import {formatMoney} from "./index";
import {DatePicker, Tag} from "antd";
import dayjs from "dayjs";
import {useUpdateTradingCreditTransactionMutation, useUpdateTradingVoucherTransactionMutation} from "../../../../../api/graphql/tradingAccount";
import {useRecoilState, useSetRecoilState} from "recoil";
import {notificationMessage, selfPermissionObj} from "../../../../../../recoil_state";
import {useTranslation} from "react-i18next";
import useAuthorize from "../../../../../_common/authorize";
import {BsCheck, BsX} from "react-icons/bs";
import {updateTradingCreditTransactionMt5, updateTradingVoucherTransactionMt5} from "../../../../../api/graphql/tradingAccountMt5";
import {formatDateTimeMT5, formatDateTimeTimezone, formatDateUTC} from "../../../../../function/_common";

const formatDate = (timestamp) => (timestamp ? moment(timestamp).format('YYYY-MM-DD HH:mm:ss') : '');
const labelCol = {span: 7, offset: 0};

const SeeMoreComponent = ({text}) => {
    const [view, setView] = useState(false);

    let tagsToShow = text?.slice(0, view ? text.length : 3);

    const renderTag = (tag, i) => (
        <Tag
            key={i}
            style={{margin: 2}}
            color={"default"}
        >
            {tag}
        </Tag>
    );

    const renderSeeMoreTag = () => (
        <Tag
            style={{margin: 2, cursor: "pointer"}}
            color={"green"}
            onClick={() => setView(true)}
        >
            ...
        </Tag>
    );

    return (
        <div>
            {tagsToShow?.map(renderTag)}
            {!view && text?.length > 3 && renderSeeMoreTag()}
        </div>
    );
};

const ExpiredDateCreditColumn = (props) => {

    const {text, record} = props
    const {updateTradingCreditTransaction} = useUpdateTradingCreditTransactionMutation()
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const tradingAccountCreditTxnPermission = selfPermission?.tradingAccount?.creditTransaction;
    const {t} = useTranslation();
    const isAddable = tradingAccountCreditTxnPermission?.edit?.edit;

    const {systemErrorNotification} = useAuthorize()

    const changeCreditExpiredDate = async (value, record) => {
        try {
            const response = await updateTradingCreditTransaction(record?.recordId, {
                expirationDatetime: value ? moment(value?.$d).format("YYYY-MM-DD") : null
            })

            if (
                response?.data?.updateTradingCreditTransaction.__typename ===
                "tradingCreditTransaction"
            ) {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Updated Credit Transaction Successfully"),
                    key: "updateTradingCreditTransaction",
                });
            } else if (
                response?.data?.updateTradingCreditTransaction.__typename === "BaseError"
            ) {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.data?.updateTradingCreditTransaction?.errKey),
                    key: "updateTradingCreditTransaction",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.data?.updateTradingCreditTransaction?.msg),
                    key: "updateTradingCreditTransaction",
                });
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    return (
        <>
            <DatePicker
                onChange={(e) => changeCreditExpiredDate(e, record)}
                name={"expirationDatetime"}
                placeholder={t("tradingAccount.expired_date").toString()}
                defaultValue={text && formatDateUTC(text, "formValue")}
                disabled={!isAddable || record?.recordStatus === "cancelled"}
                disabledDate={current => {
                    return current && current.isBefore(dayjs().startOf("day")); // Use dayjs instead of moment
                }}
            />
            {
                record?.debt > 0 && (
                    <div>
                        <small>{t("tradingAccount.pendingDeduction")}: ${formatMoney(record?.debt)}</small>
                    </div>
                )
            }
            {
                record?.remark && (
                    <div>
                        <small>{record?.remark}</small>
                    </div>
                )
            }
        </>
    )
}

const ExpiredDateCreditMt5Column = (props) => {

    const {text, record} = props
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const tradingAccountCreditTxnPermission = selfPermission?.tradingAccountMt5?.creditTransaction;
    const {t} = useTranslation();
    const isAddable = tradingAccountCreditTxnPermission?.edit?.edit;

    const {systemErrorNotification} = useAuthorize()

    const changeCreditExpiredDate = async (value, record) => {
        try {
            const response = await updateTradingCreditTransactionMt5(record?.transactionNo?.value || record?.dealIdDisplay, {
                expirationDatetime: value ? moment(value?.$d).format("YYYY-MM-DD") : null
            })

            if (
                response?.updateTradingCreditTransactionMt5.__typename ===
                "TradingMt5CreditTransaction"
            ) {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Updated Credit Transaction Successfully"),
                    key: "updateTradingCreditTransactionMt5",
                });
            } else if (
                response?.updateTradingCreditTransactionMt5.__typename === "BaseError"
            ) {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.updateTradingCreditTransactionMt5?.errKey),
                    key: "updateTradingCreditTransactionMt5",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.updateTradingCreditTransactionMt5?.msg),
                    key: "updateTradingCreditTransactionMt5",
                });
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    return (
        <>
            <DatePicker
                onChange={(e) => changeCreditExpiredDate(e, record)}
                name={"expirationDatetime"}
                placeholder={t("tradingAccount.expired_date").toString()}
                defaultValue={text && dayjs(text)}
                disabled={!isAddable || record?.recordStatus === "cancelled"}
                disabledDate={current => {
                    return current && current.isBefore(dayjs().startOf("day")); // Use dayjs instead of moment
                }}
            />
            {
                record?.debt > 0 && (
                    <div>
                        <small>{t("tradingAccount.pendingDeduction")}: ${formatMoney(record?.debt)}</small>
                    </div>
                )
            }
            {
                record?.remark && (
                    <div>
                        <small>{record?.remark}</small>
                    </div>
                )
            }
        </>
    )
}

const ExpiredDateVoucherMt5Column = (props) => {

    const {text, record} = props
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const tradingAccountCreditTxnPermission = selfPermission?.tradingAccountMt5?.creditTransaction;
    const {t} = useTranslation();
    const isAddable = tradingAccountCreditTxnPermission?.edit?.edit;

    const {systemErrorNotification} = useAuthorize()

    const changeCreditExpiredDate = async (value, record) => {
        try {
            const response = await updateTradingVoucherTransactionMt5(record?.transactionNo?.value || record?.dealIdDisplay, {
                expirationDatetime: value ? moment(value?.$d).format("YYYY-MM-DD") : null
            })

            if (
                response?.updateTradingVoucherTransactionMt5.__typename ===
                "TradingMt5VoucherTransaction"
            ) {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Updated Voucher Transaction Successfully"),
                    key: "updateTradingVoucherTransactionMt5",
                });
            } else if (
                response?.updateTradingVoucherTransactionMt5.__typename === "BaseError"
            ) {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.updateTradingVoucherTransactionMt5?.errKey),
                    key: "updateTradingVoucherTransactionMt5",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.updateTradingVoucherTransactionMt5?.msg),
                    key: "updateTradingVoucherTransactionMt5",
                });
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    return (
        <>
            <DatePicker
                onChange={(e) => changeCreditExpiredDate(e, record)}
                name={"expirationDatetime"}
                placeholder={t("tradingAccount.expired_date").toString()}
                defaultValue={text && dayjs(text)}
                disabled={!isAddable || record?.recordStatus === "cancelled"}
                disabledDate={current => {
                    return current && current.isBefore(dayjs().startOf("day")); // Use dayjs instead of moment
                }}
            />
            {
                record?.debt > 0 && (
                    <div>
                        <small>{t("tradingAccount.pendingDeduction")}: ${formatMoney(record?.debt)}</small>
                    </div>
                )
            }
            {
                record?.remark && (
                    <div>
                        <small>{record?.remark}</small>
                    </div>
                )
            }
        </>
    )
}

const ExpiredDateVoucherColumn = (props) => {

    const {text, record} = props
    const {updateTradingVoucherTransaction} = useUpdateTradingVoucherTransactionMutation()
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const tradingAccountVoucherTxnPermission =
        selfPermission?.tradingAccount?.creditTransaction;
    const {t} = useTranslation();
    const isAddable = tradingAccountVoucherTxnPermission?.edit?.edit;

    const {systemErrorNotification} = useAuthorize()

    const changeVoucherExpiredDate = async (value, record) => {
        try {
            const response = await updateTradingVoucherTransaction(record.recordId, {
                expirationDatetime: value ? formatDateTimeTimezone(value?.$d, "formSubmit") : null
            })

            if (
                response?.data?.updateTradingVoucherTransaction.__typename ===
                "tradingVoucherTransaction"
            ) {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Updated Voucher Transaction Successfully"),
                    key: "updateTradingVoucherTransaction",
                });
            } else if (
                response?.data?.updateTradingVoucherTransaction.__typename === "BaseError"
            ) {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.data?.updateTradingVoucherTransaction?.errKey),
                    key: "updateTradingVoucherTransaction",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.data?.updateTradingVoucherTransaction?.msg),
                    key: "updateTradingVoucherTransaction",
                });
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    return (
        <>
            <DatePicker
                onChange={(e) => changeVoucherExpiredDate(e, record)}
                name={t("tradingAccount.expired_date").toString()}
                placeholder={"Expired Date"}
                defaultValue={text ? formatDateUTC(text, "formValue") : null}
                disabled={!isAddable || record?.recordStatus === "cancelled"}
                disabledDate={current => {
                    return current && current.isBefore(dayjs().startOf("day")); // Use dayjs instead of moment
                }}
            />
            {
                record?.debt > 0 && (
                    <div>
                        <small>{t("tradingAccount.pendingDeduction")}: ${formatMoney(record?.debt)}</small>
                    </div>
                )
            }
            {
                record?.remark && (
                    <div>
                        <small>{record?.remark}</small>
                    </div>
                )
            }
        </>
    )
}

export const ColumnTradingAccountDemoTransaction = () => {
    const {t} = useTranslation()

    return [
        {
            title: t("tradingAccount.transactionNo"),
            dataIndex: "balanceHistoryIdDisplay",
            key: "balanceHistoryIdDisplay",
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.createdAt"),
            dataIndex: "createdAt",
            key: "createdAt",
            ...getDateRangeFilterProps(),
            render: (text) => <p>{text ? moment(text)?.format("YYYY-MM-DD HH:mm:ss") : ""}</p>

        },
        {
            title: t("tradingAccount.transaction_type"),
            dataIndex: "transactionType",
            key: "transactionType",
            filters: [
                {text: t("tradingAccount.withdrawal"), value: "WITHDRAWAL"},
                {text: t("tradingAccount.deposit"), value: "DEPOSIT"},
                {text: t("tradingAccount.adjustmentIn"), value: "ADJUSTMENT IN"},
                {text: t("tradingAccount.adjustmentOut"), value: "ADJUSTMENT OUT"},
            ],
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.amount"),
            dataIndex: "amount",
            key: "amount",
            ...getColumnSearchProps(),
            render: (text) => <p>{formatMoney(text)}</p>,
        },
        {
            title: t("tradingAccount.comment"),
            dataIndex: "comment",
            key: "comment",
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
    ];
}

export const ColumnTradingCreditDemoTransaction = () => {
    const {t} = useTranslation()

    return [
        {
            title: t("tradingAccount.transactionNo"),
            dataIndex: "balanceHistoryIdDisplay",
            key: "balanceHistoryIdDisplay",
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.createdAt"),
            dataIndex: "createdAt",
            key: "createdAt",
            ...getDateRangeFilterProps(),
            render: (text) => <p>{text ? moment(text)?.format("YYYY-MM-DD HH:mm:ss") : ""}</p>

        },
        {
            title: t("tradingAccount.transaction_type"),
            dataIndex: "transactionType",
            key: "transactionType",
            filters: [
                {text: t("tradingAccount.credit_in"), value: "CREDIT IN"},
                {text: t("tradingAccount.credit_out"), value: "CREDIT OUT"},
            ],
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.amount"),
            dataIndex: "amount",
            key: "amount",
            ...getColumnSearchProps(),
            render: (text) => <p>{formatMoney(text)}</p>,
        },
        {
            title: t("tradingAccount.expiryDate"),
            dataIndex: "expirationDatetime",
            fieldName: "expirationDatetime",
            key: "expirationDatetime",
            ...getColumnSearchProps(),
            render: (text, record) => {
                if (record?.transactionType === "CREDIT IN") {
                    return (
                        <ExpiredDateCreditColumn text={text} record={{
                            ...record,
                            recordStatus: record?.status,
                            recordId: record?._id
                        }}/>
                    )
                } else {
                    return <>-</>
                }

            },
        },
        {
            title: t("tradingAccount.comment"),
            dataIndex: "comment",
            key: "comment",
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
    ];
}

export const ColumnTradingAccountMT5DemoTransaction = () => {
    const {t} = useTranslation()

    return [
        {
            title: t("tradingAccount.transactionNo"),
            dataIndex: "dealIdDisplay",
            key: "dealIdDisplay",
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.createdAt"),
            dataIndex: "createdAt",
            key: "createdAt",
            ...getDateRangeFilterProps(),
            render: (text) => <p>{text ? moment(text)?.format("YYYY-MM-DD HH:mm:ss") : ""}</p>

        },
        {
            title: t("tradingAccount.transaction_type"),
            dataIndex: "transactionType",
            key: "transactionType",
            filters: [
                {text: t("tradingAccount.withdrawal"), value: "WITHDRAWAL"},
                {text: t("tradingAccount.deposit"), value: "DEPOSIT"},
                {text: t("tradingAccount.adjustmentIn"), value: "ADJUSTMENT IN"},
                {text: t("tradingAccount.adjustmentOut"), value: "ADJUSTMENT OUT"},
            ],
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.amount"),
            dataIndex: "amount",
            key: "amount",
            ...getColumnSearchProps(),
            render: (text) => <p>{formatMoney(text)}</p>,
        },
        {
            title: t("tradingAccount.comment"),
            dataIndex: "comment",
            key: "comment",
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
    ];
}

export const ColumnTradingMT5CreditDemoTransaction = () => {
    const {t} = useTranslation()

    return [
        {
            title: t("tradingAccount.transactionNo"),
            dataIndex: "dealIdDisplay",
            key: "dealIdDisplay",
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.createdAt"),
            dataIndex: "createdAt",
            key: "createdAt",
            ...getDateRangeFilterProps(),
            render: (text) => <p>{text ? moment(text)?.format("YYYY-MM-DD HH:mm:ss") : ""}</p>

        },
        {
            title: t("tradingAccount.transaction_type"),
            dataIndex: "transactionType",
            key: "transactionType",
            filters: [
                {text: t("tradingAccount.credit_in"), value: "CREDIT IN"},
                {text: t("tradingAccount.credit_out"), value: "CREDIT OUT"},
            ],
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.amount"),
            dataIndex: "amount",
            key: "amount",
            ...getColumnSearchProps(),
            render: (text) => <p>{formatMoney(text)}</p>,
        },
        {
            title: t("tradingAccount.expiryDate"),
            dataIndex: "expirationDatetime",
            fieldName: "expirationDatetime",
            key: "expirationDatetime",
            ...getColumnSearchProps(),
            render: (text, record) => {
                if (record?.transactionType === "CREDIT IN") {
                    return (
                        <ExpiredDateCreditMt5Column text={text} record={{
                            ...record,
                            recordStatus: record?.status,
                            recordId: record?._id
                        }}/>
                    )
                } else {
                    return <>-</>
                }

            },
        },
        {
            title: t("tradingAccount.comment"),
            dataIndex: "comment",
            key: "comment",
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
    ];
}

export const ColumnTradingMT5VoucherDemoTransaction = () => {
    const {t} = useTranslation()

    return [
        {
            title: t("tradingAccount.transactionNo"),
            dataIndex: "dealIdDisplay",
            key: "dealIdDisplay",
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.createdAt"),
            dataIndex: "createdAt",
            key: "createdAt",
            ...getDateRangeFilterProps(),
            render: (text) => <p>{text ? moment(text)?.format("YYYY-MM-DD HH:mm:ss") : ""}</p>

        },
        {
            title: t("tradingAccount.transaction_type"),
            dataIndex: "transactionType",
            key: "transactionType",
            filters: [
                {text: "Credit In", value: "TRADING VOUCHER IN"},
                {text: "Credit Out", value: "TRADING VOUCHER OUT"},
            ],
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.amount"),
            dataIndex: "amount",
            key: "amount",
            ...getColumnSearchProps(),
            render: (text) => <p>{formatMoney(text)}</p>,
        },
        {
            title: t("tradingAccount.expiryDate"),
            dataIndex: "expirationDatetime",
            fieldName: "expirationDatetime",
            key: "expirationDatetime",
            ...getColumnSearchProps(),
            render: (text, record) => {
                if (record?.transactionType === "TRADING VOUCHER IN") {
                    return (
                        <ExpiredDateVoucherMt5Column text={text} record={{
                            ...record,
                            recordStatus: record?.status,
                            recordId: record?._id
                        }}/>
                    )
                } else {
                    return <>-</>
                }

            },
        },
        {
            title: t("tradingAccount.comment"),
            dataIndex: "comment",
            key: "comment",
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
    ];
}

export const ColumnTradingAccountTransaction = () => {
    const {t} = useTranslation()

    return [
        {
            title: t("tradingAccount.transactionNo"),
            dataIndex: "transactionNo",
            key: "transactionNo",
            reportFieldName: "transactionNo",
            ...getColumnSearchProps(),
            render: (text) => <p>{text?.value}</p>,
        },
        {
            title: t("tradingAccount.createdAt"),
            dataIndex: "createdAt",
            key: "createdAt",
            reportFieldName: "createdAt",
            ...getDateRangeFilterProps(),
            render: (text) => <p>{text ? formatDateTimeTimezone(text?.value) : ""}</p>

        },
        {
            title: t("tradingAccount.transaction_type"),
            dataIndex: "transactionType",
            key: "transactionType",
            reportFieldName: "transactionType",
            filters: [
                {text: t("tradingAccount.withdrawal"), value: "WITHDRAWAL"},
                {text: t("tradingAccount.deposit"), value: "DEPOSIT"},
                {text: t("tradingAccount.adjustmentIn"), value: "ADJUSTMENT IN"},
                {text: t("tradingAccount.adjustmentOut"), value: "ADJUSTMENT OUT"},
            ],
            render: (text) => <p>{text?.value}</p>,
        },
        {
            title: t("tradingAccount.amount"),
            dataIndex: "amount",
            key: "amount",
            reportFieldName: "amount",
            ...getColumnSearchProps(),
            render: (text) => <p>{formatMoney(text?.value)}</p>,
        },
        {
            title: t("tradingAccount.comment"),
            dataIndex: "comment",
            key: "comment",
            reportFieldName: "comment",
            ...getColumnSearchProps(),
            render: (text) => <p>{text?.value}</p>,
        },
        {
            title: t("tradingAccount.externalNote"),
            dataIndex: "externalNote",
            key: "externalNote",
            reportFieldName: "externalNote",
            ...getColumnSearchProps(),
            render: (text) => <p>{text?.value}</p>,
        },
    ];
}

export const ColumnTradingCreditTransaction = () => {
    const {t} = useTranslation()

    return [
        {
            title: t("tradingAccount.transactionNo"),
            dataIndex: "transactionNo",
            fieldName: "transactionNo",
            key: "transactionNo",
            ...getColumnSearchProps(),
            render: (text) => <p>{text?.value}</p>,
        },
        {
            title: t("tradingAccount.createdAt"),
            dataIndex: "createdAt",
            fieldName: "createdAt",
            key: "createdAt",
            ...getDateRangeFilterProps(),
            render: (text) => <p>{text ? formatDateTimeTimezone(text?.value) : ""}</p>

        },
        {
            title: t("tradingAccount.transaction_type"),
            dataIndex: "transactionType",
            fieldName: "transactionType",
            key: "transactionType",
            filters: [
                {text: t("tradingAccount.credit_in"), value: "CREDIT IN"},
                {text: t("tradingAccount.credit_out"), value: "CREDIT OUT"},
            ],
            render: (text) => <p>{text?.value}</p>,
        },
        {
            title: t("tradingAccount.amount"),
            dataIndex: "amount",
            fieldName: "amount",
            key: "amount",
            ...getColumnSearchProps(),
            render: (text) => <p>{formatMoney(text?.value)}</p>,
        },
        {
            title: t("tradingAccount.expiryDate"),
            dataIndex: "expirationDatetime",
            fieldName: "expirationDatetime",
            key: "expirationDatetime",
            ...getColumnSearchProps(),
            render: (text, record) => {
                if (record?.transactionType?.value === "CREDIT IN") {
                    return (
                       record?.platform?.value === "MT5" ? <ExpiredDateCreditMt5Column text={text?.value} record={record}/> : <ExpiredDateCreditColumn text={text?.value} record={record}/>
                    )
                } else {
                    return <>-</>
                }

            },
        },
        {
            title: t("tradingAccount.comment"),
            dataIndex: "comment",
            fieldName: "comment",
            key: "comment",
            ...getColumnSearchProps(),
            render: (text) => <p>{text?.value}</p>,
        },
    ];
}

export const ColumnTradingVoucherTransaction = () => {
    const {t} = useTranslation()

    return [
        {
            title: t("tradingAccount.transactionNo"),
            dataIndex: "transactionNo",
            fieldName: "transactionNo",
            key: "transactionNo",
            ...getColumnSearchProps(),
            render: (text) => <p>{text?.value}</p>,
        },
        {
            title: t("tradingAccount.createdAt"),
            dataIndex: "createdAt",
            fieldName: "createdAt",
            key: "createdAt",
            ...getDateRangeFilterProps(),
            render: (text) => <p>{text ? formatDateTimeTimezone(text?.value) : ""}</p>

        },
        {
            title: t("tradingAccount.transaction_type"),
            dataIndex: "transactionType",
            fieldName: "transactionType",
            key: "transactionType",
            filters: [
                {text: "Credit In", value: "TRADING VOUCHER IN"},
                {text: "Credit Out", value: "TRADING VOUCHER OUT"},
            ],
            render: (text) => <p>{text?.value}</p>,
        },
        {
            title: t("tradingAccount.amount"),
            dataIndex: "amount",
            fieldName: "amount",
            key: "amount",
            ...getColumnSearchProps(),
            render: (text) => <p>{formatMoney(text?.value)}</p>,
        },
        {
            title: t("tradingAccount.expiryDate"),
            dataIndex: "expirationDatetime",
            fieldName: "expirationDatetime",
            key: "expirationDatetime",
            ...getColumnSearchProps(),
            render: (text, record) => {
                if (record?.transactionType?.value === "TRADING VOUCHER IN") {
                    return (
                        record?.platform?.value === "MT5" ? <ExpiredDateVoucherMt5Column text={text?.value} record={record}/> : <ExpiredDateVoucherColumn text={text?.value} record={record}/>
                    )
                } else {
                    return <>-</>
                }

            },
        },
        {
            title: t("tradingAccount.comment"),
            dataIndex: "comment",
            fieldName: "comment",
            key: "comment",
            ...getColumnSearchProps(),
            render: (text) => <p>{text?.value}</p>,
        },

    ];
}

export const columnDeals = () => {
    const {t} = useTranslation()

    return [
        {
            title: t("tradingAccount.createTimeStamp"),
            dataIndex: 'createTimestamp',
            key: 'createTimestamp',
            ...getDateRangeFilterProps(),
            render: (text) => <p>{formatDate(text)}</p>,
        },
        {
            title: t("tradingAccount.symbolName"),
            dataIndex: 'symbolName',
            key: 'symbolName',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.dealID"),
            dataIndex: 'dealId',
            key: 'dealId',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.tradeSide"),
            dataIndex: 'tradeSide',
            key: 'tradeSide',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.direction"),
            dataIndex: 'direction',
            key: 'direction',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.volumeLots"),
            dataIndex: 'volumeLots',
            key: 'volumeLots',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.volume"),
            dataIndex: 'volume',
            key: 'volume',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.price"),
            dataIndex: 'price',
            key: 'price',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.positionID"),
            dataIndex: 'positionId',
            key: 'positionId',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.executionTimeStamp"),
            dataIndex: 'executionTimestamp',
            key: 'executionTimestamp',
            ...getDateRangeFilterProps(),
            render: (text) => <p>{formatDate(text)}</p>,
        },
        {
            title: t("tradingAccount.stopLoss"),
            dataIndex: 'stopLoss',
            key: 'stopLoss',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.takeProfit"),
            dataIndex: 'takeProfit',
            key: 'takeProfit',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.reason"),
            dataIndex: 'channel',
            key: 'channel',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.swap"),
            dataIndex: 'swap',
            key: 'swap',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.commission"),
            dataIndex: 'commission',
            key: 'commission',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.netProfit"),
            dataIndex: 'netProfit',
            key: 'netProfit',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.ip_address"),
            dataIndex: 'ipAddress',
            key: 'ipAddress',
            ...getColumnSearchProps(),
            render: (text) => <SeeMoreComponent text={text}/>,
        },
        {
            title: t("tradingAccount.comment"),
            dataIndex: 'comment',
            key: 'comment',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.dealStatus"),
            dataIndex: 'dealStatus',
            key: 'dealStatus',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
    ];
}

export const columnOrder = () => {
    const {t} = useTranslation()

    return [
        {
            title: t("tradingAccount.createTimeStamp"),
            dataIndex: 'createTimestamp',
            key: 'createTimestamp',
            ...getDateRangeFilterProps(),
            render: (text) => <p>{formatDate(text)}</p>,
        },
        {
            title: t("tradingAccount.symbolName"),
            dataIndex: 'symbolName',
            key: 'symbolName',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.orderID"),
            dataIndex: 'orderId',
            key: 'orderId',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.positionID"),
            dataIndex: 'positionId',
            key: 'positionId',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.tradeSide"),
            dataIndex: 'tradeSide',
            key: 'tradeSide',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.direction"),
            dataIndex: 'direction',
            key: 'direction',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.volumeLots"),
            dataIndex: 'volumeLots',
            key: 'volumeLots',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.volume"),
            dataIndex: 'volume',
            key: 'volume',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.executionPrice"),
            dataIndex: 'executionPrice',
            key: 'executionPrice',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.ip_address"),
            dataIndex: 'ipAddress',
            key: 'ipAddress',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.expirationTimestamp"),
            dataIndex: 'expirationTimestamp',
            key: 'expirationTimestamp',
            ...getDateRangeFilterProps(),
            render: (text) => <p>{text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-'}</p>,
        },
        {
            title: t("tradingAccount.value"),
            dataIndex: 'value',
            key: 'value',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.orderType"),
            dataIndex: 'orderType',
            key: 'orderType',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.orderStatus"),
            dataIndex: 'orderStatus',
            key: 'orderStatus',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.stopLoss"),
            dataIndex: 'stopLoss',
            key: 'stopLoss',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.takeProfit"),
            dataIndex: 'takeProfit',
            key: 'takeProfit',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.stopPrice"),
            dataIndex: 'stopPrice',
            key: 'stopPrice',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.limitPrice"),
            dataIndex: 'limitPrice',
            key: 'limitPrice',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.isStopOut"),
            dataIndex: 'isStopOut',
            key: 'isStopOut',
            ...getColumnSearchProps(),
            render: (text) => <p>{text ? <BsCheck style={{color: 'green'}}/> : <BsX style={{color: 'red'}}/>}</p>,
        },
        {
            title: t("tradingAccount.reason"),
            dataIndex: 'reason',
            key: 'reason',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.comment"),
            dataIndex: 'comment',
            key: 'comment',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
    ];
}

export const columnClosedPosition = () => {
    const {t} = useTranslation()

    return [
        {
            title: t("tradingAccount.positionID"),
            dataIndex: 'positionId',
            key: 'positionId',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.symbol"),
            dataIndex: 'symbolName',
            key: 'symbolName',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.type"),
            dataIndex: 'type',
            key: 'type',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.volumeLots"),
            dataIndex: 'volumeLots',
            key: 'volumeLots',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.volume"),
            dataIndex: 'volume',
            key: 'volume',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.openTimestamp"),
            dataIndex: 'openTimestamp',
            key: 'openTimestamp',
            ...getDateRangeFilterProps(),
            render: (text) => <p>{text ? moment(text)?.format('YYYY-MM-DD HH:mm:ss') : ''}</p>,
        },
        {
            title: t("tradingAccount.openPrice"),
            dataIndex: 'openPrice',
            key: 'openPrice',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.openIp"),
            dataIndex: 'openIpAddresses',
            key: 'openIpAddresses',
            ...getColumnSearchProps(),
            render: (text) => <p>{Array?.isArray(text) ? text?.join(', ') : text}</p>,
        },
        {
            title: t("tradingAccount.openingOrderId"),
            dataIndex: 'openingOrderId',
            key: 'openingOrderId',
            ...getColumnSearchProps(),
            render: (text) => <p>{Array?.isArray(text) ? text?.join(', ') : text}</p>,
        },
        {
            title: t("tradingAccount.orderID"),
            dataIndex: 'orderId',
            key: 'orderId',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.closeTimestamp"),
            dataIndex: 'closeTimestamp',
            key: 'closeTimestamp',
            ...getDateRangeFilterProps(),
            render: (text) => <p>{text ? moment(text)?.format('YYYY-MM-DD HH:mm:ss') : ''}</p>,
        },

        {
            title: t("tradingAccount.closePrice"),
            dataIndex: 'closePrice',
            key: 'closePrice',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.closeIp"),
            dataIndex: 'closeIpAddresses',
            key: 'closeIpAddresses',
            ...getColumnSearchProps(),
            render: (text) => <p>{Array?.isArray(text) ? text?.join(', ') : text}</p>,
        },
        {
            title: t("tradingAccount.stopLoss"),
            dataIndex: 'stopLoss',
            key: 'stopLoss',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.takeProfit"),
            dataIndex: 'takeProfit',
            key: 'takeProfit',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.value_baseCurrency"),
            dataIndex: 'valueBaseCurrency',
            key: 'valueBaseCurrency',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.value_usd"),
            dataIndex: 'valueUsd',
            key: 'valueUsd',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.commission"),
            dataIndex: 'commission',
            key: 'commission',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.swap"),
            dataIndex: 'swap',
            key: 'swap',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.netProfit"),
            dataIndex: 'netProfit',
            key: 'netProfit',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.change"),
            dataIndex: 'change',
            key: 'change',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.comment"),
            dataIndex: 'comment',
            key: 'comment',
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        }
    ];
}

export const ColumnPosition = () => {
    const {t} = useTranslation()

    return [
        {
            title: t("tradingAccount.positionID"),
            dataIndex: "positionId",
            key: "positionId",
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("user.client"),
            dataIndex: "client",
            key: "client",
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.positionStatus"),
            dataIndex: "positionStatus",
            key: "positionStatus",
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.traderID"),
            dataIndex: "traderId",
            key: "traderId",
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.whiteLabel"),
            dataIndex: "whiteLabel",
            key: "whiteLabel",
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.openTime"),
            dataIndex: "openTimestamp",
            key: "openTimestamp",
            render: (text) => <p>{text ? moment(text)?.format("YYYY-MM-DD HH:mm:ss") : ""}</p>
        },
        {
            title: t("tradingAccount.openPrice"),
            dataIndex: "openPrice",
            key: "openPrice",
            render: (text) => <p style={{textAlign: "right"}}>{text}</p>,
        },
        {
            title: t("tradingAccount.symbol"),
            dataIndex: "symbol",
            key: "symbol",
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.symbolID"),
            dataIndex: "symbolId",
            key: "symbolId",
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.type"),
            dataIndex: "type",
            key: "type",
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.volume_lots"),
            dataIndex: "volumeLots",
            key: "volumeLots",
            render: (text) => <p style={{textAlign: "right"}}>{text}</p>,
        },
        {
            title: t("tradingAccount.volume_units"),
            dataIndex: "volume",
            key: "volume",
            render: (text) => <p style={{textAlign: "right"}}>{text ? formatMoney(text) : "-"}</p>,
        },
        {
            title: t("tradingAccount.stopLoss"),
            dataIndex: "stopLoss",
            key: "stopLoss",
            render: (text) => <p style={{textAlign: "right"}}>{text ? formatMoney(text) : "-"}</p>,
        },
        {
            title: t("tradingAccount.takeProfit"),
            dataIndex: "takeProfit",
            key: "takeProfit",
            render: (text) => <p style={{textAlign: "right"}}>{text ? formatMoney(text) : "-"}</p>,
        },
        {
            title: t("tradingAccount.commission"),
            dataIndex: "commission",
            key: "commission",
            render: (text) => <p>{formatMoney(text)}</p>,
        },
        {
            title: t("tradingAccount.swap"),
            dataIndex: "swap",
            key: "swap",
            render: (text) => <p
                style={{color: text < 0 ? "red" : "green", textAlign: "right"}}>{text ? formatMoney(text) : "-"}</p>,
        },
        {
            title: t("tradingAccount.netProfit"),
            dataIndex: "netProfit",
            key: "netProfit",
            render: (text) => <p
                style={{color: text < 0 ? "red" : "green", textAlign: "right"}}>{text ? formatMoney(text) : "-"}</p>,
        },
        {
            title: t("tradingAccount.priceChange"),
            dataIndex: "change",
            key: "change",
            render: (text) => <p style={{textAlign: "right"}}>{text ? formatMoney(text) : "-"}</p>,
        },
        {
            title: t("tradingAccount.comment"),
            dataIndex: "comment",
            key: "comment",
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.points"),
            dataIndex: "points",
            key: "points",
            render: (text) => <p style={{textAlign: "right"}}>{text}</p>,
        },
        {
            title: t("tradingAccount.pips"),
            dataIndex: "pips",
            key: "pips",
            render: (text) => <p style={{textAlign: "right"}}>{text}</p>,
        },
        {
            title: t("tradingAccount.pipPosition"),
            dataIndex: "pipPosition",
            key: "pipPosition",
            render: (text) => <p style={{textAlign: "right"}}>{text ? formatMoney(text) : "-"}</p>,
        },
        {
            title: t("tradingAccount.profit_pips"),
            dataIndex: "pipProfit",
            key: "pipProfit",
            render: (text) => <p style={{textAlign: "right"}}>{text ? formatMoney(text) : "-"}</p>,
        },
        {
            title: t("tradingAccount.spread"),
            dataIndex: "spread",
            key: "spread",
            render: (text) => <p style={{textAlign: "right"}}>{text ? formatMoney(text) : "-"}</p>,
        },
        {
            title: t("tradingAccount.currentPrice"),
            dataIndex: "currentPrice",
            key: "currentPrice",
            render: (text) => <p style={{textAlign: "right"}}>{text ? formatMoney(text) : "-"}</p>,
        }
    ];
}

export const ColumnPositionMt5 = (t) => {

    return [
        {
            title: t("tradingAccount.symbol"),
            dataIndex: "symbol",
            key: "symbol",
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.positionID"),
            dataIndex: "position",
            key: "position",
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.createdTime"),
            dataIndex: "timeCreateMsc",
            key: "timeCreateMsc",
            // render: (text) => <p>{text ? moment(text)?.format("YYYY-MM-DD HH:mm:ss") : ""}</p>
            render: (text) => <p>{text ? formatDateTimeMT5(text) : ""}</p>
        },
        {
            title: t("tradingAccount.type"),
            dataIndex: "type",
            key: "type",
            render: (text, record) => <p>{record?.action === "POSITION_BUY" ? "Buy" : "Sell"}</p>,
        },
        {
            title: t("tradingAccount.volume_lots"),
            dataIndex: "volume_lot",
            key: "volume_lot",
            width: "3%",
            render: (text, record) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.volume_units"),
            dataIndex: "volume",
            key: "volume",
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.openPrice"),
            dataIndex: "priceOpen",
            key: "priceOpen",
            render: (text) => <p>{text ? formatMoney(text) : "-"}</p>,
        },
        {
            title: t("tradingAccount.stopLoss"),
            dataIndex: "priceSL",
            key: "priceSL",
            render: (text) => <p>{text ? formatMoney(text) : "-"}</p>,
        },
        {
            title: t("tradingAccount.takeProfit"),
            dataIndex: "priceTP",
            key: "priceTP",
            render: (text) => <p>{text ? formatMoney(text) : "-"}</p>,
        },
        {
            title: t("tradingAccount.price"),
            dataIndex: "priceCurrent",
            key: "priceCurrent",
            width: "6%",
            render: (text) => <p>{text ? formatMoney(text) : "-"}</p>,
        },
        {
            title: t("tradingAccount.swap"),
            dataIndex: "storage",
            key: "storage",
            render: (text) => <p>{text ? formatMoney(text) : "-"}</p>,
        },
        {
            title: t("tradingAccount.commission"),
            dataIndex: "commission",
            key: "commission",
            render: (text) => <p>{formatMoney(text)}</p>,
        },
        {
            title: t("tradingAccount.reason"),
            dataIndex: "reason",
            key: "reason",
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("tradingAccount.profit"),
            dataIndex: "profitCalc",
            key: "profitCalc",
            width: "6%",
            render: (text) => <p>{text ? formatMoney(text) : "-"}</p>,
        }
    ];
}

