import {atom} from "recoil";

const iconTypeState = atom({
    key: "iconType",
    default: "common",
});

export {
    iconTypeState
}
