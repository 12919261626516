import React, {useState} from "react";
import {Button, Row, Space} from "antd";
import {useTranslation} from "react-i18next";

const VoucherProgramTopBar = (props) => {
    const {
        openFormModal,
        permissionActivities
    } = props;
    const {t} = useTranslation();

    return (
        <>
            <Row className={"margin-bottom-0-75 display-flex"} style={{justifyContent: "end"}}>
                <Space>
                    {permissionActivities?.voucherProgram?.add?.add && (
                        <Button type={"primary"}  onClick={() => openFormModal("add")} >
                            {t("common.add")}
                        </Button>
                    )}
                </Space>
            </Row>
        </>
    );
};

export default VoucherProgramTopBar;
