import React, {useState} from "react";
import {Button, Col, DatePicker, Form, Modal, Row, Spin} from "antd";
import {useTranslation} from "react-i18next";
import {commissionRerunBatch} from "../../../../../../../api/graphql/commission";
import useAuthorize from "../../../../../../../_common/authorize";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import moment from "moment";

const {RangePicker} = DatePicker;

const CommLogBatchRecalculateModal = (props) => {

    const {isOpen, closeModal, data, batchRecord, preload, isLocalCalculation} = props;

    const [syncLoading, setSyncLoading] = useState(false)

    const {t} = useTranslation()
    const labelCol = {span: 24, offset: 0}
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {systemErrorNotification} = useAuthorize()

    const [form] = Form.useForm();

    const rerunBatch = async (values) => {
        await setSyncLoading(true)
        try {

            const networkDate = values?.networkDate ? moment(values?.networkDate?.$d)?.format("YYYY-MM-DD HH:mm:ss") : null

            const response = await commissionRerunBatch(batchRecord?.rule?._id, batchRecord?._id, networkDate, isLocalCalculation);

            if (response?.commissionRerunBatch?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.commissionRerunBatch?.errKey),
                    key: "commissionRerunBatch",
                });
            } else if (response?.commissionRerunBatch?.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(data?.commissionRerunBatch?.msg),
                    key: "commissionRerunBatch",
                });
            } else {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Rerun Batch Successfully"),
                    key: "commissionRerunBatch",
                });
            }

        } catch (e) {
            systemErrorNotification()
        } finally {
            setSyncLoading(false)
            preload();
        }

    }

    return (
        <Modal
            title={"Commission Log Batch Recalculate"}
            open={isOpen}
            onCancel={() => closeModal()}
            footer={null}
            width={500}
            destroyOnClose
        >
            <Spin spinning={syncLoading}>
                <Form onFinish={rerunBatch} form={form}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item
                                labelCol={labelCol}
                                label={t("Network Date")}
                                name={"networkDate"}
                            >
                                <DatePicker style={{width: "100%"}}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Col span={24} style={{textAlign: "end", marginTop: "30px"}}>
                        <Button htmlType={"submit"} disabled={syncLoading}>
                            Recalculate
                        </Button>
                    </Col>
                </Form>
            </Spin>
        </Modal>

    )

}

export default CommLogBatchRecalculateModal