import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import SearchableTree from "../../../../../../components/tree/searchableTree";
import {calculateTotalDownline} from "../../../../../../function/calculateTotalDownline";

const Structure = (props) => {
    const {data, currentTab, refetchRoleNetwork} = props;
    const {t} = useTranslation();
    const [amendedData, setAmendedData] = useState<any>([]);

    const transformData = (data) => {
        try {
            const result: any = [];
            const lookup: any = {};

            data?.forEach((item) => {
                lookup[item?.roleTemplateId?._id] = {
                    ...item,
                    children: [],
                    totalDirectDownline: 0,
                    totalDownline: 0,
                };
            });

            data?.forEach((item) => {
                if (lookup && item?.parent?._id && lookup[item?.parent?._id]) {
                    lookup[item?.parent?._id]?.children?.push(lookup[item?.roleTemplateId?._id]);
                    lookup[item?.parent?._id].totalDirectDownline += 1;
                } else {
                    result?.push(lookup[item?.roleTemplateId?._id]);
                }
            });

            // Amend fields
            function amendFields(obj) {
                obj.key = obj?.roleTemplateId?._id;
                obj.totalDownline = calculateTotalDownline(obj);
                let name = obj?.roleTemplateId?.name || "-";
                //   let totalDirectDownline = obj?.totalDirectDownline;
                //   let totalDownline = obj?.totalDownline;
                obj.title = `${name}`;
                delete obj?._id;
                delete obj?.roleTemplateId;
                delete obj?.parent;
                delete obj?.__typename;
                if (obj?.children) {
                    obj?.children?.forEach(amendFields);
                }
            }
            result?.forEach(amendFields);

            return result;
        } catch (error) {}
    };

    useEffect(() => {
        if (data?.length > 0) {
            const result = transformData(data);
            setAmendedData(result);
        }
    }, [data]);

    useEffect(() => {
        if (currentTab === "structure") {
            refetchRoleNetwork();
        }
    }, [currentTab]);

    return (
        <div id="role-network-tree" className="margin-top-0-75">
            <SearchableTree defaultData={amendedData} note={t("Role Name")} refetchNetwork={refetchRoleNetwork} />
        </div>
    );
};

export default Structure;
