import React, {useEffect, useState} from "react";
import {Button, Col, Row, Tag} from "antd";
import TableAntDesign from "../../../../../../../components/table/tableAntDesign";
import {SymbolMappingModalColumns} from "../../../function/tableColumn_SymbolMappingModal";
import {useTranslation} from "react-i18next";
import {getQuoteSetting, useUpdateQuoteSettingMutation} from "../../../../../../../api/graphql/quoteSetting";
import useAuthorize from "../../../../../../../_common/authorize";
import {getInstruments, getInstrumentsCount} from "../../../../../../../api/graphql/symbolMapping";
import {getInstrumentCountRestful, getInstrumentRestful} from "../../../../../../../api/restful/intruments";

const FavouriteSymbol = () => {
    const {t} = useTranslation()

    const [selectedSymbol, setSelectedSymbol] = useState<any>([])

    const [filter, setFilter] = useState({})
    const [limit, setLimit] = useState(10)
    const [offset, setOffset] = useState(1)
    const [orderBy, setOrderBy] = useState("")
    const [selectedRowKey, setSelectedRowKey] = useState<any>([])
    const [currentSelectedRowKey, setCurrentSelectedRowKey] = useState<any>([])

    const {data: favouriteList} = getQuoteSetting()
    const {updateQuoteSetting, loading: updateLoading} = useUpdateQuoteSettingMutation()
    const {systemErrorNotification} = useAuthorize()

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedSymbolAry, setSelectedSymbolAry] = useState([])
    const [selectedSymbolLoading, setSelectedSymbolLoading] = useState(false)
    const [totalCount, setTotalCount] = useState(0)

    const getInstrumentData = async () => {
        setLoading(true)
        const response = await getInstrumentRestful({filter: filter},
            offset || 1,
            limit || 10,
            orderBy || null)

        setData(response)
        setLoading(false)
    }

    const getInstrumentDataAry = async () => {
        setSelectedSymbolLoading(true)
        const response = await getInstrumentRestful({filter: {base_symbol_id: selectedRowKey?.join(", ")}},
            1,
            selectedRowKey?.length || 1,
            null)

        setSelectedSymbolAry(response)
        setSelectedSymbolLoading(false)
    }

    const getInstrumentDataCount = async () => {
        const response = await getInstrumentCountRestful({filter: filter})
        setTotalCount(response)
    }

    useEffect(() => {
        getInstrumentData()
    },[filter, offset, limit, orderBy])

    useEffect(() => {
        getInstrumentDataAry()
    },[selectedRowKey])

    useEffect(() => {
        getInstrumentDataCount()
    },[filter])

    // const {data, loading} = getInstruments(
    //     {filter: filter},
    //     offset,
    //     limit,
    //     orderBy
    // )

    // const {data: selectedSymbolAry, loading: selectedSymbolLoading} = getInstruments(
    //     {filter: {base_symbol_id: selectedRowKey?.join(", ")}}, 1, selectedRowKey?.length || 1
    // )

    // const {data: totalCount} = getInstrumentsCount({
    //     filter: filter,
    // });

    useEffect(() => {
        if (!selectedSymbolLoading && selectedRowKey?.length > 0) {
            setSelectedSymbol(
                selectedSymbolAry?.map((item:any) => {
                    return {
                        ticker_symbol: item?.ticker_symbol,
                        base_symbol_id: item?.base_symbol_id
                    }
                })
            )
        }
    }, [selectedSymbolAry])

    const onRowSelection = (record, selected, selectedRows, changeRows) => {
        setSelectedRowKey(prevState => {
            if (selected) {
                return [...new Set([...prevState, record?.base_symbol_id])]
            } else {
                setSelectedSymbol(prevState => prevState?.filter(item => item?.base_symbol_id !== record?.base_symbol_id))
                return prevState?.filter(d => d !== record?.base_symbol_id)
            }
        })

    }

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize)
        setOffset(page)
    }

    const onSubmit = () => {
        try {
            const result = selectedRowKey?.map(key => key.toString())
            updateQuoteSetting({favouriteSymbol: result})
        } catch (e) {
            systemErrorNotification()
        }
    }

    useEffect(() => {
        if (favouriteList?.getQuoteSetting?.favouriteSymbol && favouriteList?.getQuoteSetting?.favouriteSymbol?.length > 0) {
            setSelectedRowKey(favouriteList?.getQuoteSetting?.favouriteSymbol?.map(Number))
        }
    }, [favouriteList?.getQuoteSetting])

    const handleClose = (removedTag) => {
        setSelectedRowKey(prevState => prevState?.filter(item => item !== removedTag?.base_symbol_id))
        setSelectedSymbol(prevState => prevState?.filter(item => item?.base_symbol_id !== removedTag?.base_symbol_id))
    };

    return (
        <>
            <Row className={"margin-top-0-75"} style={{display: "block"}}>
                <Col span={24} className={"margin-bottom-0-75"}>
                    <Row justify={"space-between"} align={"bottom"}>
                        <Col flex={"auto"}>
                            <>{t("Favourite Symbols")}</>
                            <div
                                style={{
                                    margin: "8px 8px 0 0",
                                    padding: 4,
                                    border: "1px solid rgba(136, 136, 136, .3)",
                                    borderRadius: 8,
                                    minHeight: 28
                                }}
                            >
                                {
                                    selectedSymbol?.length > 0 && selectedSymbol?.map(item => {
                                        return <Tag bordered={false} key={item?.base_symbol_id} closable onClose={(e) => {
                                            e.preventDefault();
                                            handleClose(item);
                                        }
                                        }>{item?.ticker_symbol}</Tag>
                                    })
                                }
                            </div>
                        </Col>
                        <Button type={"primary"} loading={updateLoading}
                                onClick={() => onSubmit()}>{t("common.update")}</Button>
                    </Row>
                </Col>
                <TableAntDesign
                    tableName={"mappingModal"}
                    data={data}
                    columns={SymbolMappingModalColumns}
                    size={"small"}
                    loading={loading}
                    rowKey={"base_symbol_id"}
                    filter={setFilter}
                    order={setOrderBy}
                    selectedRowKeys={selectedRowKey}
                    selectionType={"checkbox"}
                    onRowSelect={onRowSelection}
                    pagination={{
                        pageSize: limit,
                        showSizeChanger: true,
                        onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                        total: totalCount,
                        showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                    }}
                />
            </Row>
        </>
    )
}

export default FavouriteSymbol