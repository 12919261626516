import React, {useState} from "react";
import "./css/index.css";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import yupFormSchema from "../../../../../shared/components/yup/yupFormSchema";
import InputFormItem from "../../../../../shared/components/form/inputFormItem";
import loginBackground from "./assets/login_background.png";

import {FormProvider, useForm} from "react-hook-form";
import {Button, Col, Divider, Image, Row, Space} from "antd";
import {verticalFormLayout} from "../../../../../shared/components/form/formWrapper";
import {useTranslation} from "react-i18next";

const LoginPage_alliumtrades = (props) => {
    const {loginAction, loginData, loginLoading, brandSetting, languageList, setLanguage} = props;
    const {t} = useTranslation();

    const [isTyping, setIsTyping] = useState<any>(false);

    const loginSchema = yup.object().shape({
        login: yupFormSchema.string("login", {
            required: true,
            requiredErrorMessage: "Username Credential is required",
        }),
        password: yupFormSchema.string("password", {
            required: true,
            requiredErrorMessage: "Password is required",
        }),
    });

    const [initialValues] = useState(() => ({
        login: "",
        password: "",
    }));

    const form = useForm({
        resolver: yupResolver(loginSchema),
        mode: "all",
        defaultValues: initialValues,
    });

    const onSubmit = async (values) => {
        const record = {
            ...values,
            type: "crmUser",
        };
        loginAction(record);
        setIsTyping(false);
    };

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const styleLoginBtn = {
        // backgroundImage: `url(${isHovered ? loginBtnHover : loginBtn})`,
    };

    return (
        <div id="allium-login-page" style={{backgroundImage: `url(${loginBackground})`}}>
            <Row justify={"space-between"} className={"login-header"}>
                <Image src={brandSetting?.companyLogo} preview={false} width={120}/>
                <Space className={"header-action"}>
                    <Divider type={"vertical"}/>
                    <a className={"active"}>{t("LOGIN")}</a>
                    <Divider type={"vertical"}/>
                    <a href={brandSetting?.aboutPage} target={"_blank"}>
                        {t("ABOUT US")}
                    </a>
                    <Divider type={"vertical"}/>
                    <a href={brandSetting?.supportPage} target={"_blank"}>
                        {t("SUPPORT")}
                    </a>
                </Space>
            </Row>
            <Row align={"middle"} id="login-page-1">
                <Col
                    xs={22}
                    sm={14}
                    md={10}
                    lg={8}
                    xl={7}
                    className="login-card"
                    // style={{backgroundImage: `url(${loginBox})`}}
                >
                    <Col className="login-form-logo-1 text-align-center margin-bottom-0-75">
                        <Image src={brandSetting?.companyLogo} preview={false} height={80} width={"auto"}/>
                    </Col>
                    {/*<div className="login-title text-align-center">{t("Login")}</div>*/}
                    <FormProvider {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)} className="login-form-1">
                            <InputFormItem
                                className={"login-input margin-bottom-0-75"}
                                name={"login"}
                                label={"Username"}
                                placeholder={"Username"}
                                layout={verticalFormLayout}
                                onChange={(e) => setIsTyping(e)}
                                required
                            />
                            <InputFormItem
                                name={"password"}
                                label={"Password"}
                                placeholder={"Password"}
                                type={"password"}
                                layout={verticalFormLayout}
                                onChange={(e) => setIsTyping(e)}
                                required
                            />
                            {loginData && loginData.login && loginData.login.errMsg && isTyping === false && (
                                <p
                                    style={{
                                        color: "red",
                                        marginTop: 16,
                                        fontSize: 12,
                                        textAlign: "center"
                                    }}
                                >
                                    {loginData.login.errMsg}
                                </p>
                            )}
                            <Row justify={"center"}>
                                <Button
                                    className="btn-login"
                                    type="primary"
                                    onClick={form.handleSubmit(onSubmit)}
                                    disabled={loginLoading}
                                    loading={loginLoading}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    style={styleLoginBtn}
                                >
                                    {t("Login")}
                                </Button>
                            </Row>
                        </form>
                    </FormProvider>
                </Col>
            </Row>
            <p className={"text-align-center margin-top-0-75"} style={{color: "#222222", fontSize: 11}}>
                Copyright © {brandSetting?.copyRight} {brandSetting?.companyName} - All rights reserved.
            </p>
        </div>
    );
};

export default LoginPage_alliumtrades;
