import { gql, useMutation, useQuery } from '@apollo/client'

const GET_COMMISSION_PROFILE_COUNT = gql`
  query getCommissionProfileCount($filter: CommissionProfileInput) {
    getCommissionProfileCount(filter: $filter)
  }
`;

const getCommissionProfileCount = ({filter}) => {
  try{
    const { loading, error, data } = useQuery(GET_COMMISSION_PROFILE_COUNT, {
        variables: {
            filter: {
              commissionProfileId: filter?.commissionProfileId || null,
              name: filter?.name || null,
              serverName: filter?.serverName || null,
            }
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}

const GET_COMMISSION_PROFILE_BY_ID = gql`
  query getCommissionProfileByID($id: String!) {
    getCommissionProfileByID(id: $id){
      ... on CommissionProfile {
        id
        commissionProfileId
        name
        description
        utcLastUpdateTimestamp
        commissionType
        minCommissionType
        minCommissionAssetId
        preciseTradingCommissionRate
        preciseMinCommission
        pnlConversionFeeRate
        avoidMinCommissionOnStopOut
        server {name}
        createdBy {username}
        updatedBy {username}
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getCommissionProfileById = (id) => {
  try{
    const { loading, error, data } = useQuery(GET_COMMISSION_PROFILE_BY_ID, {
        variables: {
          id: id
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}


const GET_COMMISSION_PROFILE = gql`
  query getCommissionProfile($filter: CommissionProfileInput, $limit: Int, $offset: Int, $orderBy: String) {
    getCommissionProfile(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy){
    ... on CommissionProfileArray {
      data {
        id
        commissionProfileId
        name
        description
        utcLastUpdateTimestamp
        commissionType
        minCommissionType
        minCommissionAssetId
        preciseTradingCommissionRate
        preciseMinCommission
        pnlConversionFeeRate
        avoidMinCommissionOnStopOut
        server {name}
        createdBy {username}
        updatedBy {username}
        createdAt
        updatedAt
      }
    }
    ... on BaseError {
      errKey
      errMsg
    }
  }
}
`;

const getCommissionProfile = ({filter}, limit?, offset?, orderBy?) => {
  try{
    const { loading, error, data } = useQuery(GET_COMMISSION_PROFILE, {
        variables: {
          filter: {
            commissionProfileId: filter?.commissionProfileId || null,
            name: filter?.name || null,
            serverName: filter?.serverName || null,
          },
          limit: limit || null,
          offset: offset || null,
          orderBy: orderBy || null,
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}

const REFRESH_COMMISSION_PROFILE = gql`
 mutation refreshCommissionProfile($server: String!) {
      refreshCommissionProfile(server: $server){
      ... on CommissionProfileArray {
        data {
          id
          commissionProfileId
          name
          description
          utcLastUpdateTimestamp
          commissionType
          minCommissionType
          minCommissionAssetId
          preciseTradingCommissionRate
          preciseMinCommission
          pnlConversionFeeRate
          avoidMinCommissionOnStopOut
          server {name}
          createdBy {username}
          updatedBy {username}
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
       }
      }
 }
`

const useRefreshCommissionProfileMutation = (): { refreshCommissionProfile: any, data: any, loading: boolean, error: any } => {
  try{
    const [useRefreshCommissionProfileMutation, { data, loading, error }] = useMutation(REFRESH_COMMISSION_PROFILE);

    const refreshCommissionProfile = (input) => {
      return useRefreshCommissionProfileMutation({
        variables: {
          input: {
            server: input.server || null,
          },
        },
      });
    };

    return { refreshCommissionProfile, data, loading, error } 
  }
  catch(err: any){
    console.error(err);
    return { refreshCommissionProfile: null, data: null, loading: false, error: err };
  }
}

export {
  getCommissionProfileCount, 
  getCommissionProfileById, 
  getCommissionProfile, 
  useRefreshCommissionProfileMutation
}