import React, {useEffect, useState} from "react";
import {getCron, getCronLogDetails} from "../../../api/graphql/cron";
import {useLocation} from "react-router-dom";
import {getLogger} from "../../../api/graphql/logger";
import Logger_1 from "./view/layout1";

function LogPage() {
    let layout = 1;
    let component;

    const [limit, setLimit] = useState(20)
    const [offset, setOffset] = useState(0)
    const [filter, setFilter] = useState({})
    const [order, setOrder] = useState('id DESC')
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [dateKey, setDateKey] = useState(null)

    const {data, refetchQuery,loading} = getLogger({filter: filter}, {}, order, limit, offset, startDate, endDate, dateKey)

    switch (layout) {
        case 1:
            component = <Logger_1
                data={data?.getLogger?.data}
                total={data?.getLogger?.total}
                refetchQuery={refetchQuery}
                loading={loading}
                limit={limit}
                setLimit={setLimit}
                setOffset={setOffset}
                setOrder={setOrder}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setDateKey={setDateKey}
                setFilter={setFilter}
                tableName={"Audit Logs"}
                idModal={true}
            />;
            break;

        default:
            component = <Logger_1
                data={data?.getLogger?.data}
                total={data?.getLogger?.total}
                refetchQuery={refetchQuery}
                limit={limit}
                loading={loading}
                setLimit={setLimit}
                setOffset={setOffset}
                setOrder={setOrder}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setDateKey={setDateKey}
                setFilter={setFilter}
                tableName={"Audit Logs"}
                idModal={true}
            />;
            break;
    }

    return component;
}

export default LogPage