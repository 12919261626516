import React from "react";
import {BsCheck, BsX} from "react-icons/bs";
import {
    getBooleanFilterProps,
    getColumnSearchProps,
    getDateRangeFilterProps,
} from "../../../../../components/table/function/filterTable";
import moment from "moment";
import {formatMoney} from "./index";
import {Tag} from "antd";
import {useTranslation} from "react-i18next";
import {formatDateTimeMT5, formatDateTimeTimezone} from "../../../../../function/_common";
import {formatDateTime} from "../../../../users/_common/function";

export const columnTradingAccount = (isSpreadGroup?) => {
    const {t} = useTranslation()
    const searchParams = new URLSearchParams(location.search);
    const spreadGroup = searchParams.get('spreadGroup');

    return [
        {
            title: t("user.username"),
            dataIndex: "user",
            key: "user",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("tradingAccount.accountId"),
            dataIndex: "accountId",
            key: "accountId",
            ...getColumnSearchProps(),
            render: (text) => text || "-",
        },
        {
            title: t("tradingAccount.platform"),
            dataIndex: "platform",
            key: "platform",
            ...getColumnSearchProps(),
            render: (text) =>{
                return(
                    <p>{text || "-"}</p>
                )
            },
        },
        {
            title: t("tradingAccount.mode"),
            dataIndex: "mode",
            key: "mode",
            filters: [
                {text: t("accountSetting.server_live"), value: "live"},
                {text: t("accountSetting.server_demo"), value: "demo"},
            ],
            render: (text) => <Tag color={text === "live" ? "green" : "geekblue"}
                                   bordered={false}>{t(`accountSetting.server_${text}`) || "-"}</Tag>,
        },
        {
            title: t("tradingAcc.productType"),
            dataIndex: "productType",
            key: "productType",
            ...getColumnSearchProps(),
            render: (text) => <p>{text ? t(`accountSetting.${text}`) : "-"}</p>,
        },
        {
            title: t("tradingAccount.spread_group"),
            dataIndex: "spreadGroup",
            key: "spreadGroup",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("tradingAccount.product"),
            dataIndex: "product",
            key: "product",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("tradingAccount.leverage"),
            dataIndex: "leverage",
            key: "leverage",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("tradingAccount.is_default"),
            dataIndex: "isDefault",
            key: "isDefault",
            ...getBooleanFilterProps(),
            render: (text) => (text) ? <BsCheck style={{color: 'green'}}/> : <BsX style={{color: 'red'}}/>,
        },
        {
            title: t("tradingAccount.is_deposit_enabled"),
            dataIndex: "hasDepositPermission",
            key: "hasDepositPermission",
            ...getBooleanFilterProps(),
            render: (text) => (text) ? <BsCheck style={{color: 'green'}}/> : <BsX style={{color: 'red'}}/>,
        },
        {
            title: t("tradingAccount.is_withdrawal_enabled"),
            dataIndex: "hasWithdrawalPermission",
            key: "hasWithdrawalPermission",
            ...getBooleanFilterProps(),
            render: (text) => (text) ? <BsCheck style={{color: 'green'}}/> : <BsX style={{color: 'red'}}/>,
        },
        {
            title: t("tradingAccount.is_private"),
            dataIndex: "isPrivate",
            key: "isPrivate",
            ...getBooleanFilterProps(),
            render: (text) => (text) ? <BsCheck style={{color: 'green'}}/> : <BsX style={{color: 'red'}}/>,
        },
        {
            title: t("tradingAccount.trading_permission"),
            dataIndex: "hasTradingPermission",
            key: "hasTradingPermission",
            ...getBooleanFilterProps(),
            render: (text) => (text) ? <BsCheck style={{color: 'green'}}/> : <BsX style={{color: 'red'}}/>,
        },
        {
            title: t("tradingAccount.login_enabled"),
            dataIndex: "isLoginEnabled",
            key: "isLoginEnabled",
            ...getBooleanFilterProps(),
            render: (text) => (text) ? <BsCheck style={{color: 'green'}}/> : <BsX style={{color: 'red'}}/>,
        },
        {
            title: t("tradingAccount.registrationTime"),
            dataIndex: "registrationDate",
            key: "registrationDate",
            ...getDateRangeFilterProps(),
            // render: (text) => <p>{text ? moment(text)?.format("YYYY-MM-DD HH:mm:ss") : "-"}</p>
            render: (text, record) => <p>{text ? (record?.platform === "mt5" ? formatDateTimeMT5(text) : formatDateTimeTimezone(text)) : "-"}</p>

        },
        {
            title: t("tradingAccount.pendingWithdrawalAmount"),
            dataIndex: "lockBalance",
            key: "lockBalance",
            render: (text) => <p style={{textAlign: "right"}}>{text ? formatMoney(text) : formatMoney(0)}</p>,
        },
        {
            title: t("tradingAccount.balance"),
            dataIndex: "balance",
            key: "balance",
            render: (text) => <p style={{textAlign: "right"}}>{text ? formatMoney(text) : formatMoney(0)}</p>,
        },
        {
            title: t("tradingAccount.active_credit"),
            dataIndex: "activeCredit",
            key: "credit",
            render: (text) => <p style={{textAlign: "right"}}>{text ? formatMoney(text) : formatMoney(0)}</p>,
        },
        {
            title: t("tradingAccount.credit"),
            dataIndex: "credit",
            key: "credit",
            render: (text) => <p style={{textAlign: "right"}}>{text ? formatMoney(text) : formatMoney(0)}</p>,
        },
        {
            title: t("tradingAccount.tradingVoucher"),
            dataIndex: "tradingVoucher",
            key: "tradingVoucher",
            render: (text) => <p style={{textAlign: "right"}}>{text ? formatMoney(text) : formatMoney(0)}</p>,
        },
        {
            title: t("tradingAccount.equity"),
            dataIndex: "equity",
            key: "equity",
            render: (text) => <p style={{textAlign: "right"}}>{text ? formatMoney(text) : formatMoney(0)}</p>,
        },
        {
            title: t("tradingAccount.margin"),
            dataIndex: "margin",
            key: "margin",
            render: (text) => <p style={{textAlign: "right"}}>{text ? formatMoney(text) : formatMoney(0)}</p>,
        },
        {
            title: t("tradingAccount.availableMargin"),
            dataIndex: "availableMargin",
            key: "availableMargin",
            render: (text) => <p style={{textAlign: "right"}}>{text ? formatMoney(text) : formatMoney(0)}</p>,
        },
        {
            title: t("tradingAccount.marginLevel"),
            dataIndex: "marginLevel",
            key: "marginLevel",
            render: (text) => <p style={{textAlign: "right"}}>{text ? formatMoney(text) : formatMoney(0)}</p>,
        }
    ]
};
