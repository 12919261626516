import {atom} from "recoil";

const commissionProfile = atom({
    key: "commissionProfile",
    default: [],
});

const assetClass = atom({
    key: "assetClass",
    default: [],
});

const leverageProfile = atom({
    key: "leverageProfile",
    default: [],
});

const gslSchedule = atom({
    key: "gslSchedule",
    default: [],
});

const volumeProfile = atom({
    key: "volumeProfile",
    default: [],
});

const executionProfile = atom({
    key: "executionProfile",
    default: [],
});

const protectionProfile = atom({
    key: "protectionProfile",
    default: [],
});

const swapFreeProfile = atom({
    key: "swapFreeProfile",
    default: [],
});

const symbol = atom({
    key: "symbol",
    default: [],
});

const spreadGroupMt5ReloadState = atom({
    key: 'symbolMt5ReloadState',
    default: false,
});

const currentServerState = atom({
    key: 'currentServerState',
    default: null,
});


export {
    commissionProfile,
    assetClass,
    leverageProfile,
    gslSchedule,
    volumeProfile,
    executionProfile,
    protectionProfile,
    swapFreeProfile,
    symbol,
    spreadGroupMt5ReloadState,
    currentServerState
}