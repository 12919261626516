import React, {useEffect, useState} from "react";
import {Button, Col, Form, Modal, Row, Select, Tooltip} from "antd";
import {getCommissionRuleByNetwork, getCommissionSetting} from "../../../../../../../api/graphql/commission";
import {useTranslation} from "react-i18next";
import {SyncOutlined} from "@ant-design/icons";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import useAuthorize from "../../../../../../../_common/authorize";
import {useResetTradingAccountCommissionMutation, useUpdateTradingAccountCommissionMutation} from "../../../../../../../api/graphql/tradingAccount";

const CommRuleModal = ({isOpen, setIsOpen, account}) => {
    const {t} = useTranslation()
    const [form] = Form.useForm()

    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [commissionSettings, setCommissionSettings] = useState<any>([]);
    const [commRuleOptions, setCommRuleOptions] = useState<any>({});
    const [commissionInitialValue, setCommissionInitialValue] = useState<any>([]);

    const {systemErrorNotification} = useAuthorize();
    const {updateTradingAccountCommission} = useUpdateTradingAccountCommissionMutation()
    const {resetTradingAccountCommission} = useResetTradingAccountCommissionMutation()

    const getCommissionRules = async () => {

        const response = await getCommissionSetting({commissionTagging: "Trading Account"})
        const types: any = []

        if (response?.getCommissionSetting?.data?.length > 0) {
            for (const setting of response?.getCommissionSetting?.data) {
                types.push(setting?._id)
            }
        }

        setCommissionSettings(response?.getCommissionSetting?.data)

        let obj: any = {}

        const rulesByNetwork = await getCommissionRuleByNetwork(account?._id)
        const group = rulesByNetwork?.getCommissionRuleByNetwork?.group || []
        const rules = rulesByNetwork?.getCommissionRuleByNetwork?.rules || []

        const initalCommissionType: any = []

        for (const type of types) {
            let rulesResult = rules?.filter((item) => item?.commissionSetting?._id === type)
            let groupResult = group?.filter((item) => item?.commissionSetting?._id === type)

            if (rulesResult?.length > 0) {
                rulesResult = rulesResult?.map((item) => {
                    return {
                        label: item?.name,
                        value: item?._id,
                    }
                })
            }

            if (groupResult?.length > 0) {
                groupResult = groupResult?.map((item) => {
                    return {
                        label: item?.name,
                        value: item?._id,
                    }
                })
            }

            if (groupResult?.length > 0 || rulesResult?.length > 0) {
                obj[type] = {
                    rules: rulesResult,
                    group: groupResult
                }
            }

            const foundInitial = account?.commRule?.find((item) => item?.commissionSetting === type)

            if (foundInitial) {
                initalCommissionType.push({
                    commRuleAuto: foundInitial?.commRuleAuto,
                    commRuleType: foundInitial?.commRuleType,
                    commissionSetting: foundInitial?.commissionSetting,
                    rule: foundInitial?.rule,
                    group: foundInitial?.group,
                })
            } else {
                initalCommissionType.push({
                    commissionSetting: type,
                    group: null,
                    rule: null,
                    commRuleAuto: true,
                    commRuleType: null
                })
            }

        }

        setCommRuleOptions(obj)
        setCommissionInitialValue(initalCommissionType)
    }

    const onSubmitComm = async (val) => {
        let result = ""

        for (const d of Object.keys(val)) {
            const rules = commRuleOptions[d]?.rules;
            let commRuleType;
            let foundRules = rules?.find((item) => item?.value === val[d]);

            if (foundRules) {
                commRuleType = "commissionRule";
            } else {
                commRuleType = "commissionGroups";
            }

            const newValue = {
                commissionSetting: d,
                group: commRuleType === "commissionGroups" ? val[d] : null,
                rule: commRuleType === "commissionRule" ? val[d] : null,
                commRuleAuto: false,
                commRuleType: commRuleType,
            };

            try {
                const response = await updateTradingAccountCommission(account?._id, newValue);

                if (response?.data?.updateTradingAccountCommission.__typename === "TradingAccount") {
                    result = "success";
                } else if (response?.data?.updateTradingAccountCommission.__typename === "BaseError") {
                    break;
                } else {
                    systemErrorNotification();
                    break;
                }

                if (result === "success") {
                    setIsOpen(false);

                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: t("common.updateSuccess"),
                        key: "updateTradingAccountCommission",
                    });
                }
            } catch (e) {
                systemErrorNotification();
                break;
            }
        }
    };

    const resetComm = async (commissionSetting) => {
        try {
            const response = await resetTradingAccountCommission(account?._id, commissionSetting);

            if (response?.data?.resetTradingAccountCommission.__typename === "TradingAccount") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("tradingAccount.reset_successfully"),
                    key: "resetTradingAccountCommission",
                });
            } else if (response?.data?.resetTradingAccountCommission.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.data?.resetTradingAccountCommission?.errKey),
                    key: "resetTradingAccountCommission",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.data?.resetTradingAccountCommission?.msg),
                    key: "resetTradingAccountCommission",
                });
            }
        } catch (e) {
            systemErrorNotification();
        }
    }

    useEffect(() => {
        getCommissionRules()
    }, [account])

    useEffect(() => {
        form.resetFields()
    }, [isOpen, account])

    return (
        <Modal
            open={isOpen}
            onCancel={() => setIsOpen(false)}
            okText={t("common.confirm")}
            cancelText={t("common.cancel")}
            title={t("tradingAccount.commissionRules")}
            onOk={() => form.submit()}
        >
            <Form form={form} onFinish={onSubmitComm} labelWrap={true}>
                {
                    Object.keys(commRuleOptions)?.map((option) => {

                        const rules = commRuleOptions[option]?.rules
                        const group = commRuleOptions[option]?.group

                        const combineOptions = [...rules, ...group]

                        const found = commissionInitialValue?.find((item) => item?.commissionSetting === option)

                        if (rules?.length > 0 || group?.length > 0) {

                            const commSetting = commissionSettings?.find((item) => item?._id === option)

                            return (
                                <Row align={"bottom"}>
                                    <Col span={23}>
                                        <Form.Item
                                            labelAlign={"left"}
                                            name={`${option}`}
                                            label={t(commSetting?.label)}
                                            initialValue={found?.commRuleType === "commissionGroups" ? found?.group : found?.rule}
                                            labelCol={{span: 8}}
                                        >
                                            <Select
                                                showSearch={true}
                                                optionFilterProp={"label"}
                                                options={combineOptions}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}>
                                        <Tooltip placement="top"
                                                 title={t("common.reset_to_default")}>
                                            <Button className={"margin-bottom-0-3"} type={"link"} onClick={() => resetComm(option)}>
                                                <SyncOutlined/>
                                            </Button>
                                        </Tooltip>
                                    </Col>
                                </Row>
                            )
                        }
                    })
                }
            </Form>
        </Modal>
    )
}

export default CommRuleModal