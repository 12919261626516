import {Form, DatePicker, Col, Row} from "antd";
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import FormErrors from "./formErrors";
import dayjs from "dayjs";
import moment from "moment";

const DateRangePickerInputForm = (props) => {
    const {
        layout,
        label,
        startName,
        endName,
        startValue,
        endValue,
        startDisabled,
        endDisabled,
        placeholder,
        externalErrorMessage,
        required,
        onChange,
        disabled,
        labelCol,
        wrapperCol,
        labelAlign,
        style,
        showTime,
        format,
        defaultValue,
        disabledDate,
        disabledDateTime,
        getPopupContainer,
        colon,
        requiredStar
    } = props;

    const {
        register,
        watch,
        setValue,
        formState: {touchedFields, isSubmitted, errors},
    } = useFormContext();

    const errorMessage = FormErrors.errorMessage(
        startName,
        label,
        errors,
        touchedFields,
        isSubmitted,
        externalErrorMessage
    );

    useEffect(() => {
        register(startName);
        register(endName);
    }, [register, startName, endName]);

    useEffect(() => {
        if (startValue) {
            setValue(startName, dayjs(startValue), { shouldValidate: true });
        }
        if (endValue) {
            setValue(endName, dayjs(endValue), { shouldValidate: true });
        }
    }, [startValue, endValue])

    return (
        <Form.Item
            {...layout}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            label={requiredStar? <><span className="label-asterisk">*</span>{label}</>:label}
            required={required}
            validateStatus={errorMessage ? "error" : "success"}
            help={errorMessage}
            labelAlign={labelAlign}
            labelWrap
            colon={colon}
        >
            <Row>
                <Col xs={10} md={8}>
                    <DatePicker
                        id={startName}
                        name={startName}
                        value={watch(startName)}
                        defaultValue={defaultValue}
                        placeholder={placeholder}
                        disabled={startDisabled}
                        disabledDate={disabledDate}
                        disabledTime={disabledDateTime}
                        format={format}
                        onChange={(val) => {
                            setValue(startName, val, {shouldValidate: true});
                            onChange && onChange(val);
                        }}
                        showTime={showTime}
                        style={style || {width: "100%"}}
                        getPopupContainer={getPopupContainer}
                        allowClear={false}
                    />
                </Col>
                <Col xs={4} md={2} style={{textAlign: "center"}}>
                    to
                </Col>
                <Col xs={10} md={8}>
                    <DatePicker
                        id={endName}
                        name={endName}
                        value={watch(endName)}
                        defaultValue={defaultValue}
                        placeholder={placeholder}
                        disabled={endDisabled}
                        disabledDate={disabledDate}
                        disabledTime={disabledDateTime}
                        format={format}
                        onChange={(val) => {
                            setValue(endName, val, {shouldValidate: true});
                            onChange && onChange(val);
                        }}
                        showTime={showTime}
                        style={style || {width: "100%"}}
                        getPopupContainer={getPopupContainer}
                        allowClear={false}
                    />
                </Col>
            </Row>
        </Form.Item>
    );
}

DateRangePickerInputForm.defaultProps = {
    required: false,
    disabled: false,
    colon: false
};

DateRangePickerInputForm.propTypes = {
    layout: PropTypes.object,
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    startName: PropTypes.string,
    endName: PropTypes.string,
    startValue: PropTypes.string,
    endValue: PropTypes.string,
    defaultValue: PropTypes.object,
    externalErrorMessage: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    labelCol: PropTypes.object,
    wrapperCol: PropTypes.object,
    style: PropTypes.object,
    showTime: PropTypes.object,
    format: PropTypes.string,
    disabledDate: PropTypes.object,
    disabledDateTime: PropTypes.object,
    getPopupContainer: PropTypes.any,
    labelAlign: PropTypes.string,
    requiredStar: PropTypes.bool,
    startDisabled: PropTypes.bool,
    endDisabled: PropTypes.bool,
};

export default DateRangePickerInputForm;
