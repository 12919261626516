import React, {useEffect, useState} from "react";
import {Button, Space, Tabs} from "antd";
import SymbolCategoryTable_1 from "./component/symbolCategoryTable";
import {Link} from "react-router-dom";
import ExportTableButton from "../../../../../../components/table/function/exportTable";
import {useTranslation} from "react-i18next";
import CategoriesTable from "./component/categoriesTable";
import {getSymbolMappingCategoryCore, getSymbolMappingCategoryCoreCount} from "../../../../../../api/graphql/symbolMappingCategory";
import {useSetRecoilState} from "recoil";
import {categoryListOptionState} from "../../state";

const SymbolCategory_1 = (props) => {
    const {data, permission} = props;
    const {t} = useTranslation();
    const [isOpenSymbolCategoryForm, setIsOpenSymbolCategoryForm] = useState(false);
    const [isOpenCategoryForm, setIsOpenCategoryForm] = useState(false);
    const [mode, setMode] = useState("");
    const [selectedItem, setSelectedItem] = useState({});
    const tableName = "Symbol Category";

    const [exportData, setExportData] = useState<any>(null);
    const [exportColumn, setExportColumn] = useState<any>(null);
    const [currentServer, setCurrentServer] = useState<any>("categories");
    const [isAddEnabled, setIsAddEnabled] = useState(true);
    const [exportLoading, setExportLoading] = useState(false);

    const [orderBy, setOrderBy] = useState("");
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [filter, setFilter] = useState(null);
    const {data: categoryList, loading} = getSymbolMappingCategoryCore({filter: filter}, limit, offset, orderBy);
    const {data: fullCategoryList} = getSymbolMappingCategoryCore({filter: null});
    const {data: totalCountList} = getSymbolMappingCategoryCoreCount({filter: filter});
    const setCategoryList = useSetRecoilState(categoryListOptionState);

    useEffect(() => {
        const list = fullCategoryList?.getSymbolMappingCategoryCore?.data;
        if (list?.length > 0) {
            const categoryFormOption = list?.map((d) => {
                return {
                    label: d?.categoryName,
                    value: d?._id,
                };
            });

            setCategoryList(categoryFormOption);
        }
    }, [fullCategoryList]);

    const openAddModal = () => {
        setMode("Add");
        setIsOpenCategoryForm(true);
    };

    const openEditModal = (record) => {
        setMode("Edit");
        setIsOpenCategoryForm(true);
        setSelectedItem(record);
    };

    const closeModal = () => {
        setIsOpenSymbolCategoryForm(false);
        setIsOpenCategoryForm(false);
        setSelectedItem({});
    };

    const openEditSymbols = (record) => {
        setIsOpenSymbolCategoryForm(true);
        setSelectedItem(record);
    };

    const tabItems =
        data &&
        data?.getServerSetting &&
        data?.getServerSetting?.data?.map((item, i) => {
            return {
                label: item?.name,
                key: item?.name,
                children: (
                    <SymbolCategoryTable_1
                        server={item}
                        isOpen={isOpenSymbolCategoryForm}
                        close={closeModal}
                        item={selectedItem}
                        openEdit={openEditSymbols}
                        selectedItem={selectedItem}
                        setExportData={setExportData}
                        setExportColumn={setExportColumn}
                        tableName={tableName}
                        permission={permission?.symbol?.edit?.edit}
                        totalCount={totalCountList?.getSymbolMappingCategoryCoreCount}
                        currentServer={currentServer}
                        setExportLoading={setExportLoading}
                    />
                ),
            };
        });

    return (
        <div id="symbol-category">
            <h1>{t(tableName)}</h1>
            {data?.getServerSetting?.data && (
                <Tabs
                    defaultActiveKey="categories"
                    size={"small"}
                    items={[
                        {
                            label: t("Categories"),
                            key: "categories",
                            children: (
                                <CategoriesTable
                                    data={categoryList?.getSymbolMappingCategoryCore?.data}
                                    fullCategoryList={fullCategoryList?.getSymbolMappingCategoryCore?.data}
                                    totalCount={totalCountList?.getSymbolMappingCategoryCoreCount}
                                    loading={loading}
                                    setFilter={setFilter}
                                    setOffset={setOffset}
                                    setLimit={setLimit}
                                    limit={limit}
                                    setOrderBy={setOrderBy}
                                    mode={mode}
                                    isOpen={isOpenCategoryForm}
                                    close={closeModal}
                                    openEditModal={openEditModal}
                                    selectedItem={selectedItem}
                                    currentServer={currentServer}
                                    setExportData={setExportData}
                                    setExportColumn={setExportColumn}
                                    tableName={tableName}
                                />
                            ),
                        },
                        ...tabItems,
                    ]}
                    tabBarExtraContent={
                        <Space>
                            {permission?.symbol?.edit?.edit && isAddEnabled && <Button onClick={() => openAddModal()}>{t("Add Category")}</Button>}
                            {permission?.symbol?.view && (
                                <Button>
                                    <Link to="/settings/symbols/mapping">{t("Symbol Mapping")}</Link>
                                </Button>
                            )}
                            <ExportTableButton
                                loading={exportLoading}
                                tableData={exportData}
                                tableColumn={exportColumn}
                                tableName={tableName}
                                server={currentServer}
                            />
                        </Space>
                    }
                    onTabClick={(key) => {
                        setCurrentServer(key);
                        if (key === "categories") setIsAddEnabled(true);
                        else setIsAddEnabled(false);
                    }}
                />
            )}
        </div>
    );
};

export default SymbolCategory_1;
