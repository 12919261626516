import {gql, useMutation, useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";

const GET_MARKET_SETTING = gql`
  query getMarketSetting {
    getMarketSetting {
      ... on MarketSetting {
        oneClickTrading {
          document
          language
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getMarketSetting = () => {
    try {
        const {loading, error, data} = useQuery(GET_MARKET_SETTING, {
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, data}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const UPDATE_MARKET_SETTING = gql`
  mutation updateMarketSetting($input: MarketSettingInput) {
    updateMarketSetting(input: $input) {
      ... on MarketSetting {
        oneClickTrading {
          document
          language
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useUpdateMarketSettingMutation = (): {
    updateMarketSetting: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const { t } = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updateMarketSettingMutation, {data, loading, error}] = useMutation(UPDATE_MARKET_SETTING);

        const updateMarketSetting = (input) => {
            return updateMarketSettingMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {updateMarketSetting}}) {
                    const typename = updateMarketSetting?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateMarketSetting?.errKey),
                            key: "updateMarketSetting",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateMarketSetting?.msg),
                            key: "updateMarketSetting",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Market Setting Successfully"),
                            key: "updateMarketSetting",
                        });
                    }
                },
            });
        };

        return {updateMarketSetting, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {updateMarketSetting: null, data: null, loading: false, error: err};
    }
};

export {
    getMarketSetting,
    useUpdateMarketSettingMutation
}


