export function formatMoney(amount: any, mode?, digits = 2) {
    let amountParse = parseFloat(amount)
    let result = (amountParse)?.toFixed(digits)?.replace(/\d(?=(\d{3})+\.)/g, '$&,')
    let response = "";
    switch (mode) {
        case "%":
            response = result + "%"
            break;
        case "$":
            response = "$"+result
            break;
        default:
            response = result
    }

    return response;
}

export function generateTransactionNumber(code, number) {
    // Try to parse the number as an integer
    const parsedNumber = parseInt(number);

    // Check if the parsedNumber is a valid positive integer
    if (isNaN(parsedNumber) || parsedNumber < 0 || !Number.isInteger(parsedNumber)) {
        return 'Invalid input';
    }

    // Format the parsedNumber with leading zeros
    const formattedNumber = String(parsedNumber).padStart(3, '0');

    // Combine the code and formatted number with a hyphen
    const transactionNumber = `${code}-${formattedNumber}`;

    return transactionNumber;
}