import React from "react";
import {Button, Modal, message} from "antd";
import { RetweetOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const VoucherProgramVoucherCodeModal = (props) => {
    const {item, isOpen, closeFormModal, refreshVoucherCode} = props;
    const {t} = useTranslation()
    
    const copyText = (value) => {
        navigator.clipboard.writeText(value)
        message.success(t(`Copied to clipboard`));
    }

    return (
        <Modal
            getContainer="#voucherProgram"
            title={"Voucher Code"}
            open={isOpen}
            width={"40%"}
            footer={null}
            closable={true}
            destroyOnClose
            onCancel={closeFormModal}
        >
            <Button
                className="voucherCodeRefreshButton"
                icon={<RetweetOutlined />}
                onClick={() => refreshVoucherCode()}
            />
            {item && item.length > 0 && item?.map(x => {
                return <div className="voucherCode" onClick={()=> copyText(x)}>{x}</div>
            })}
        </Modal>
    );
};

export default VoucherProgramVoucherCodeModal;
