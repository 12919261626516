import React, {useEffect, useState} from "react";
import {Button, Divider, Form, Input, Row} from "antd";
import {useTranslation} from "react-i18next";
import {getNewsfeedSetting, useUpdateNewsFeedSettingMutation} from "../../../../../../../api/graphql/newsFeedSetting";
import useAuthorize from "../../../../../../../_common/authorize";

const NewsFeedSetting = (props) => {
    const {setCustomizeSubmitButton, close} = props
    const {t} = useTranslation()

    const [input, setInput] = useState<any>()
    const {data} = getNewsfeedSetting()
    const {updateNewsfeedSetting} = useUpdateNewsFeedSettingMutation()
    const {systemErrorNotification} = useAuthorize()

    const onSubmit = () => {
       try{
           updateNewsfeedSetting(input)
           close()
       }catch (e) {
           systemErrorNotification()
       }
    }

    useEffect(() => {
        setInput({
            comments: data?.getNewsfeedSetting?.comments,
        })
    },[data?.getNewsfeedSetting])

    useEffect(() => {
        setCustomizeSubmitButton(
            <Button onClick={onSubmit} type={"primary"}>{t("common.submit")}</Button>
        )
    },[input])

    return (
        <>
            <Divider/>
            <Row style={{padding: 16}}>
                <Form.Item
                    label={t("user.comment")}
                    colon={false}
                    labelCol={{span: 10}}
                    wrapperCol={{span: 14}}
                    style={{width: "100%"}}
                >
                    <Input
                        value={input?.comments}
                        onChange={(e) => setInput({comments: e.target?.value})}
                        placeholder={t("user.comment").toString()}/>
                </Form.Item>
            </Row>
        </>
    )
}

export default NewsFeedSetting