import {gql, useMutation, useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";

const GET_COMMUNITY_SETTING = gql`
  query getCommunitySetting {
    getCommunitySetting {
      ... on CommunitySetting {
        termOfCommunity {
          language
          document
        }
        ruleAndRegulation {
          language
          document
        }
        brandPagePolicy {
          language
          document
        }
        brandContentPolicy {
          language
          document
        }
        includesOnlyBrandUsers {
          language
          document
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getCommunitySetting = () => {
    try {
        const {loading, error, data} = useQuery(GET_COMMUNITY_SETTING, {
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, data}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const UPDATE_COMMUNITY_SETTING = gql`
  mutation updateCommunitySetting($input: CommunitySettingInput) {
    updateCommunitySetting(input: $input) {
      ... on CommunitySetting {
        termOfCommunity {
          language
          document
        }
        ruleAndRegulation {
          language
          document
        }
        brandPagePolicy {
          language
          document
        }
        brandContentPolicy {
          language
          document
        }
        includesOnlyBrandUsers {
          language
          document
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useUpdateCommunitySettingMutation = (): {
    updateCommunitySetting: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const { t } = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updateCommunitySettingMutation, {data, loading, error}] = useMutation(UPDATE_COMMUNITY_SETTING);

        const updateCommunitySetting = (input) => {
            return updateCommunitySettingMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {updateCommunitySetting}}) {
                    const typename = updateCommunitySetting?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateCommunitySetting?.errKey),
                            key: "updateCommunitySetting",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateCommunitySetting?.msg),
                            key: "updateCommunitySetting",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Community Setting Successfully"),
                            key: "updateCommunitySetting",
                        });
                    }
                },
            });
        };

        return {updateCommunitySetting, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {updateCommunitySetting: null, data: null, loading: false, error: err};
    }
};

export {
    getCommunitySetting,
    useUpdateCommunitySettingMutation
}


