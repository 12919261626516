import React, {useEffect, useRef, useState} from "react";
import {Editor} from "@tinymce/tinymce-react";
import {getConfigByName} from "../../../../../../../config";
import {Button, Modal, Space} from "antd";
import {useTranslation} from "react-i18next";
import {BRAND_NAME} from "../../../../../../../config/brandVariable";

const RichTextEditor = (props) => {
    const {editorState, onEditorStateChange, title, isOpen, setIsOpen, disabled, form} = props;
    const editorRef = useRef<any>(null);
    const {t} = useTranslation();
    const brandConfig = getConfigByName(BRAND_NAME);
    const [content, setContent] = useState<any>()

    const [cancelIsOpen, setCancelIsOpen] = useState(false)

    const handleCancel = () => {
        if (content  !== editorState) {
            setCancelIsOpen(true)
        } else {
            setIsOpen(false)
        }
    }

    const handleSave = async() => {
        try {
            await onEditorStateChange(content)
            await form.submit()
            setIsOpen(false)
        } catch (error) {
            
        }
    }

    useEffect(() => {
        setContent(editorState)
    },[editorState])

    return (
        <>
            <Modal
                open={cancelIsOpen}
                title={"Cancel Edit"}
                onOk={() => {
                    setCancelIsOpen(false)
                    setIsOpen(false)
                }}
                onCancel={() => setCancelIsOpen(false)}
                okText={t("common.confirm")}
            >
                Unsave changes made, confirmed to close will remove changes made
            </Modal>
        <Modal
            className="template-editor-1"
            title={title}
            open={isOpen}
            width={"80%"}
            footer={
                <Space>
                    <Button className="cancelBtn" onClick={() => handleCancel()}>
                        {disabled ? t("Close") : t("common.cancel")}
                    </Button>
                    {!disabled && (
                        <Button type="primary" onClick={handleSave}>
                            {t("Save")}
                        </Button>
                    )}
                </Space>
            }
            onCancel={() => setIsOpen(false)}
            destroyOnClose
        >
            <Editor
                onInit={(_, editor) => (editorRef.current = editor)}
                initialValue={editorState}
                apiKey={brandConfig?.tinyMCEApiKey}
                init={{
                    height: 500,
                    menubar: true,
                    plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount",
                    ],
                    toolbar:
                        "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media component link anchor codesample | ltr rtl",
                    content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
                // onEditorChange={onEditorStateChange}
                onEditorChange={(e) => setContent(e)}
                disabled={disabled}
            />
        </Modal>
        </>
    );
}

export default RichTextEditor;
