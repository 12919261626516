import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Badge, Col, Empty, List, Modal, Row, Tag, Typography} from "antd";
import VirtualList from "rc-virtual-list";
import {updateUserAnnouncementReadStatus} from "../../../../api/graphql/announcement";
import {useSetRecoilState} from "recoil";
import {unreadAnnouncementCountState} from "../../../../../recoil_state";
import {formatDateTime} from "../../../users/_common/function";

const {Paragraph} = Typography

const AnnouncementListing = (props) => {
    const {
        isHeader,
        data,
        loading,
        total
    } = props
    const {t} = useTranslation();
    const [selected, setSelected] = useState<any>({})
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [currentData, setCurrentData] = useState<any>({})
    const [updatedRead, setUpdatedRead] = useState<string[]>([])
    const setUnreadAnnouncementCount = useSetRecoilState(unreadAnnouncementCountState);

    const ContainerHeight = 400;

    const renderTinyMCEContent = (content) => {
        return <div className={"tinyMceParagraph"} dangerouslySetInnerHTML={{__html: content}}/>;
    };

    const selectItem = (item) => {
        setSelected({})
    }

    const listComponent =
        <List>
            <VirtualList
                className={"clickable"}
                data={data}
                height={ContainerHeight}
                itemHeight={47}
                itemKey="stock"
            >
                {(item, number) => {
                    const title = item?.dataOfObject?.title
                    const description = item?.dataOfObject?.description
                    const time = item?.createdAt
                    // const isToday = formatDate(time, true, "YYYY-MM-DD") === dateToday

                    return (
                        <>

                            <div
                                className={"text-start announcement-card"}
                                onClick={async () => {
                                    if (isHeader) {
                                        setCurrentData(item)
                                        setModalOpen(true);
                                        if (!item?.isRead && !updatedRead.includes(item?._id)) {
                                            const res = await updateUserAnnouncementReadStatus([item?._id], true)
                                            if (res?.updateUserAnnouncementReadStatus[0]?.isRead) {
                                                setUpdatedRead(prev => [...prev, res?.updateUserAnnouncementReadStatus[0]?._id])
                                                setUnreadAnnouncementCount(prev => prev - 1)
                                            }
                                        } else if (item?.isAnnouncementUpdated && !updatedRead.includes(item?._id)) {
                                            const res = await updateUserAnnouncementReadStatus([item?._id], true)
                                            setUpdatedRead(prev => [...prev, res?.updateUserAnnouncementReadStatus[0]?._id])
                                        }
                                    } else {
                                        selectItem(item)
                                    }
                                }}
                            >
                                <Row wrap={false} align={"middle"}>
                                    <Col flex={"auto"}>
                                        <Row wrap={false}>
                                            {!item?.isRead && !updatedRead.includes(item?._id) &&
                                            <Col span={2}>
                                                <Badge dot offset={[-2, 2]}/>
                                            </Col>
                                            }
                                            <Col span={!item?.isRead && !updatedRead.includes(item?._id) ? 22 : 24}>
                                                <Paragraph className={"title"} ellipsis>
                                                    {title}
                                                </Paragraph>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className={"text-end date-time"}>
                                        {formatDateTime(time)}
                                    </Col>
                                </Row>
                                <Row wrap={false} align={"middle"}>
                                    <Col flex={"auto"}>
                                        <Paragraph ellipsis={{rows: 2}} className={"description"}>
                                            {description}
                                        </Paragraph>
                                    </Col>
                                    {item?.isAnnouncementUpdated && !updatedRead.includes(item?._id) &&
                                    <Col>
                                        <Tag className={"announcement-updated-tag"} color="processing">
                                            Updated
                                        </Tag>
                                    </Col>
                                    }
                                </Row>
                            </div>
                            {/*{(number === 7 || number === total - 1) &&*/}
                            {/*<div className={"p-2"}>*/}
                            {/*    <Button>See All</Button>*/}
                            {/*</div>*/}
                            {/*}*/}
                        </>
                    )
                }}
            </VirtualList>
        </List>

    return (
        <div id={"announcementListing"}>
            {data && Object.keys(data)?.length > 0 ? listComponent : <Empty description={t("common.noData")}/>}
            <Modal
                title={
                    <>
                        {currentData?.dataOfObject?.title}
                        <p className={"date-time"}>
                            {formatDateTime(currentData?.createdAt)}
                            {currentData?.announcementUpdatedAt && ` (Last updated on ${formatDateTime(currentData?.announcementUpdatedAt)})`}
                        </p>
                    </>
                }
                width={"80%"}
                open={modalOpen}
                footer={null}
                onCancel={() => setModalOpen(false)}
                className={"announcement-modal"}
                getContainer={() => document.querySelector("#root") as HTMLElement}
            >
                <p>{currentData?.dataOfObject?.description}</p>
                <div className={"mt-3"}>{renderTinyMCEContent(currentData?.dataOfObject?.content)}</div>
            </Modal>
        </div>
    )
};

export default AnnouncementListing;