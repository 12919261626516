import React, {useEffect, useMemo, useState} from "react";
import {Input, Tree} from "antd";
import type {DataNode} from "antd/es/tree";
import {DownOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import ButtonAntDesign from "../button/buttonAntDesign";

const {Search} = Input;

const getParentKey = (key: React.Key, tree: DataNode[]): React.Key => {
    let parentKey: React.Key;
    for (let i = 0; i < tree?.length; i++) {
        const node = tree[i];
        if (node?.children) {
            if (node?.children.some((item) => item?.key === key)) {
                parentKey = node?.key;
            } else if (getParentKey(key, node?.children)) {
                parentKey = getParentKey(key, node?.children);
            }
        }
    }
    return parentKey!;
};

const SearchableTree = ({defaultData, note, refetchNetwork, type, crmUserData = []}: any) => {
    const {t} = useTranslation();

    const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
    const [searchValue, setSearchValue] = useState("");
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [dataList, setDataList] = useState<any[]>([]);
    const [isShowCrmUsersOnly, setIsShowCrmUsersOnly] = useState(false)
    const data = (type === "userNetworkTree" && isShowCrmUsersOnly) ? crmUserData : defaultData

    const onExpand = (newExpandedKeys: React.Key[]) => {
        setExpandedKeys(newExpandedKeys);
        setAutoExpandParent(false);
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const {value} = e?.target;
            const newExpandedKeys = dataList
                ?.map((item) => {
                    if (item?.title?.toLowerCase()?.indexOf(value?.toLowerCase()) > -1) {
                        return getParentKey(item?.key, data);
                    }
                    return null;
                })
                ?.filter((item, i, self) => item && self?.indexOf(item) === i);
            setExpandedKeys(newExpandedKeys as React.Key[]);
            setSearchValue(value);
            setAutoExpandParent(true);
        } catch (error) {
        }
    };

    const expandAll = () => {
        let allKeys = dataList?.map((v) => v?.key);
        setExpandedKeys(allKeys);
    };

    const expandAllCrmUser = () => {
        // let allKeys = dataList?.filter(d => d?.haveCrmUsersChild)?.map(d => d?.key);
        // setExpandedKeys(allKeys);
        setIsShowCrmUsersOnly(prevState => !prevState)

        let allKeys = dataList?.map((v) => v?.key);
        setExpandedKeys(allKeys);
    };

    const collapseAll = () => {
        setExpandedKeys([]);
    };

    const generateList = (data: any[]) => {
        try {
            for (let i = 0; i < data?.length; i++) {
                const node = data[i];
                const {key, title, userType, haveCrmUsersChild} = node;
                let obj = {key, title: title as string, userType, haveCrmUsersChild};
                setDataList((prevState) => [...prevState, obj]);
                if (node?.children) {
                    generateList(node?.children);
                }
            }
        } catch (error) {
        }
    };

    useEffect(() => {
        if (data?.length > 0) {
            generateList(data);
        }
    }, [data]);

    const treeData = useMemo(() => {
        const loop = (data: DataNode[]): DataNode[] =>
            data?.map((item: any) => {
                const strTitle = item?.title as string;
                const lowercaseTitle = strTitle?.toLowerCase();
                const lowercaseSearchValue = searchValue?.toLowerCase();
                const index = lowercaseTitle?.indexOf(lowercaseSearchValue);
                const beforeStr = strTitle?.substring(0, index);
                const afterStr = strTitle?.slice(index + searchValue?.length);
                const title =
                    index > -1 ? (
                        <span>
                            {beforeStr}
                            <span className={"site-tree-search-value" + " " + item?.userType} style={{color: "red"}}>
                                {strTitle?.substring(index, index + searchValue?.length)}
                            </span>
                            {afterStr}
                        </span>
                    ) : (
                        <span className={item?.userType}>{strTitle}</span>
                    );
                if (item?.children) {
                    return {title, key: item?.key, children: loop(item?.children)};
                }

                return {
                    title,
                    key: item?.key,
                };
            });

        return loop(data);
    }, [searchValue, data]);

    return (
        <div id="searchable-tree">
            <div className="display-flex flex-wrap margin-bottom-0-75">
                <Search
                    allowClear
                    placeholder="Search"
                    onChange={onChange}
                    className="w-50 margin-bottom-0-75 "
                />
                <ButtonAntDesign
                    text={t("Refresh")}
                    onClick={refetchNetwork}
                    className="button-submit-1"
                />
                <ButtonAntDesign
                    text={t("Expand All")}
                    onClick={expandAll}
                    className="button-submit-1"
                />
                {
                    type === "userNetworkTree" &&
                    <ButtonAntDesign
                        text={t(isShowCrmUsersOnly ? "Show All Users" : "Show Crm Users Only")}
                        onClick={expandAllCrmUser}
                        className="button-submit-1"
                    />
                }
                <ButtonAntDesign
                    text={t("Collapse All")}
                    onClick={collapseAll}
                    className="button-submit-1"
                />
            </div>
            <div className="margin-bottom-0-75 text-note">{note}</div>
            <Tree
                showLine
                multiple
                onSelect={onExpand} // onClick treeNode will expand / collapse it
                onExpand={onExpand}
                expandedKeys={expandedKeys}
                autoExpandParent={autoExpandParent}
                treeData={treeData}
                switcherIcon={<DownOutlined/>}
            />
        </div>
    );
};

export default SearchableTree;
