import React, { Suspense } from 'react';
import LoadingScreen from "../../brand/_auth/loadingScene";

export const withLazyComponent = (Children) => {
  return (props) => (
    <Suspense fallback={<LoadingScreen />}>
      <div className="page-layout">
        <Children {...props} />
      </div>
    </Suspense>
  )
}