import {Form, Rate, Select} from 'antd';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import FormErrors from './formErrors';

const RateFormItem = (props) => {
    const {
        layout,
        label,
        name,
        externalErrorMessage,
        hint,
        required,
        allowClear,
        disabled,
        onChange,
        labelCol,
        labelAlign,
        value,
        colon
    } = props

    const {
        register,
        watch,
        setValue,
        formState: { touchedFields, isSubmitted, errors }
    } = useFormContext()

    const errorMessage = FormErrors.errorMessage(
        name,
        errors,
        touchedFields,
        isSubmitted,
        externalErrorMessage
    )

    const formHelperText = errorMessage || hint

    useEffect(() => {
        register(name)
    }, [register, name])

    useEffect(() => {
        if(value){
            setValue(name, value, {shouldValidate: true})
        }
    }, [value])

    return (
        <Form.Item
            {...layout}
            labelCol={labelCol}
            labelWrap={true}
            label={label}
            labelAlign={labelAlign}
            required={required}
            validateStatus={errorMessage?'error':'success'}
            help={formHelperText}
            colon={colon}
        >
            <Rate
                value={watch(name)}
                allowClear={allowClear}
                disabled={disabled}
                onChange={(val) => {
                    setValue(name, val, {shouldValidate: true});
                    onChange && onChange(val);
                }}
            />
        </Form.Item>
    )
}

RateFormItem.defaultProps = {
    required: false,
    disabled: false,
    labelCol: {span: 6, offset: 0},
}

RateFormItem.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    externalErrorMessage: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    layout: PropTypes.object,
    hint: PropTypes.string,
    options: PropTypes.array,
    showSearch: PropTypes.bool,
    showArrow: PropTypes.bool,
    allowClear: PropTypes.bool,
    placeholder: PropTypes.string,
    mode: PropTypes.string,
    filterOption: PropTypes.func,
    value: PropTypes.string,
    labelAlign: PropTypes.string,
    colon: PropTypes.bool
}

export default RateFormItem