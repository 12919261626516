import React from "react";
import {Collapse, InputNumber, Radio, Row, Select, Switch} from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import {useContextMenuFunctions} from "../../../function/contextMenuFunctions";

const RankingMenuItems = ({setDetailData, setting, getContainer}) => {
    const {handleFormInputValueChange} = useContextMenuFunctions(setDetailData)

    return (
        <>
            <div className={"sections"}>
                <Row justify={"space-between"} align={"middle"}>
                    <p className={"margin-bottom-0"}>Display Image</p>
                    <Switch checked={setting?.showImage} onChange={value => handleFormInputValueChange(value, "showImage")}/>
                </Row>
                <Radio.Group className={"margin-top-0-3"} value={setting?.displayImage}
                             onChange={e => handleFormInputValueChange(e.target.value, "displayImage")}>
                    <Radio value={"avatar"}>Avatar</Radio>
                    <Radio value={"profileImage"}>Profile Image</Radio>
                </Radio.Group>

                <p className={"margin-top-0-75"}>Max Display Count</p>
                <InputNumber className={"w-100"} value={setting?.maxDisplayCount} onChange={value => handleFormInputValueChange(value, "maxDisplayCount")}
                             controls={false}/>

                {/*<p className={"margin-top-0-75"}>Show per Page</p>*/}
                {/*<InputNumber className={"w-100"} value={setting?.showPerPage} onChange={value => handleFormInputValueChange(value, "showPerPage")}*/}
                {/*             controls={false}/>*/}
            </div>
            <div className={"sections"}>
                <p>Layout</p>
                <Select
                    options={[
                        {label: "Layout 1", value: "layout-1"}
                    ]}
                    className={"w-100"}
                    getPopupContainer={getContainer}
                    value={setting?.layout}
                    onChange={value => handleFormInputValueChange(value, "layout")}
                />
            </div>

            <div className={"sections"}>
                <Collapse ghost accordion>
                    <CollapsePanel key={"color"} header={"Colors"}>
                        <p>Border Color</p>
                        <Select options={[]} className={"w-100"} getPopupContainer={getContainer}
                                value={setting?.borderColor}
                                onChange={value => handleFormInputValueChange(value, "borderColor")}
                        />

                        <p className={"margin-top-0-75"}>Data Color</p>
                        <Select options={[]} className={"w-100"} getPopupContainer={getContainer}
                                value={setting?.dataColor}
                                onChange={value => handleFormInputValueChange(value, "dataColor")}
                        />

                        <p className={"margin-top-0-75"}>Text Color</p>
                        <Select options={[]} className={"w-100"} getPopupContainer={getContainer}
                                value={setting?.dataColor}
                                onChange={value => handleFormInputValueChange(value, "dataColor")}
                        />
                    </CollapsePanel>
                </Collapse>
            </div>
        </>
    )
}

export default RankingMenuItems