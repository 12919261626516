import React, {useEffect, useMemo, useState} from "react";
import {
    deleteCommissionSetting,
    getCommissionSetting,
    sortCommissionSetting
} from "../../../../../../../api/graphql/commission";
import useAuthorize from "../../../../../../../_common/authorize";
import {Button, Popconfirm, Row, Space} from "antd";
import {useTranslation} from "react-i18next";
import {useRecoilState, useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import {columnsCommSetting} from "../../../function/ruleSettingColumns";
import {useNavigate} from "react-router-dom";
import TableAntDesign from "../../../../../../../components/table/tableAntDesign";
import {AiOutlineDelete} from "react-icons/ai";
import CommSettingModal from "./commSettingModal";
import {EditOutlined} from "@ant-design/icons";
import {restrictToVerticalAxis} from "@dnd-kit/modifiers";
import {arrayMove, SortableContext, useSortable, verticalListSortingStrategy,} from "@dnd-kit/sortable";
import {DndContext, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {CSS} from "@dnd-kit/utilities";
import EditTableUserPreferenceButton
    from "../../../../../../../components/table/function/editTableUserPreferenceButton";
import {columns} from "../../../function/multiTierColumns";
import {commissionSettingRefresh} from "../../../state";
import {getReportingAll} from "../../../../../../../api/graphql/reporting";

const CommSetting = (props) => {

    const {preloadSetting} = props
    const {t} = useTranslation()

    const [openModal, setOpenModal] = useState(false);
    const [modalType, setModalType] = useState("add");
    const [editData, setEditData] = useState({});
    const [filter, setFilter] = useState(null);
    const [orderBy, setOrderBy] = useState<any>("position_ASC")
    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(0);
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [dateKey, setDateKey] = useState(null)
    const [loading, setLoading] = useState(false)
    const [allReport, setAllReport] = useState<any>()

    const [data, setData] = useState<any>([])
    const [total, setTotal] = useState(0)
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [commissionSettingRefreshState, setCommissionSettingRefreshState] = useRecoilState<any>(commissionSettingRefresh);
    const {systemErrorNotification} = useAuthorize()
    const columns = columnsCommSetting

    const navigate = useNavigate();

    const tableName = "Commission Setting";

    const preload = async () => {
        try {
            await setLoading(true);

            const response = await getCommissionSetting(filter, null, null, orderBy, startDate, endDate, dateKey)
            const allReport = await getReportingAll();
            setAllReport(allReport)

            const modifiedData = response?.getCommissionSetting?.data?.map(d => {
                const foundCommissionPayoutReport = allReport?.getReportingAll?.data?.find(report => report?.commissionSettingId === d?._id)

                return foundCommissionPayoutReport ? {...d, reportPayoutName: foundCommissionPayoutReport?.displayName} : d
            })

            setData(modifiedData)
            setTotal(response?.getCommissionSetting?.total)

        } catch (e) {
            systemErrorNotification()
        } finally {
            setLoading(false);
        }
    }

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize)
        setOffset(limit * (page - 1))
    }

    const deleteId = async (id) => {
        try {
            const response = await deleteCommissionSetting(id)
            if (response?.deleteCommissionSetting?.__typename === "CommissionSetting") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Deleted Successfully"),
                    key: "CommissionSetting",
                });
            } else if (response?.deleteCommissionSetting.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.deleteCommissionSetting?.errKey),
                    key: "CommissionSetting",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.deleteCommissionSetting?.msg),
                    key: "CommissionSetting",
                });
            }
        } catch (e) {
            systemErrorNotification()
        } finally {
            preload()
            preloadSetting()
        }
    }

    const onDragEnd = async ({active, over}) => {
        try {
            if (over?.id && active.id !== over.id) {
                let arrayPosition: any = []
                setData((prev) => {
                    const activeIndex = prev.findIndex((item) => item._id === active.id);
                    const overIndex = prev.findIndex((item) => item._id === over.id);
                    const result: any = arrayMove(prev, activeIndex, overIndex);

                    arrayPosition = result.map(item => item._id);
                    return result;
                });

                await sortCommissionSetting(arrayPosition);
            }
        } catch (e) {
            console.error(e); // Log the error for debugging
            systemErrorNotification();
        } finally {
            setCommissionSettingRefreshState(true)
        }
    };


    useEffect(() => {
        preload()
    }, [])

    const tableRow = (props) => {
        // if (updateLoading || props["data-row-key"] === defaultRank) {
        //     return <tr {...props} />;
        // }

        const {
            attributes,
            listeners,
            setNodeRef,
            transform,
            transition,
            isDragging,
        } = useSortable({
            id: props["data-row-key"] ? props["data-row-key"] : "",
        });

        const style = {
            ...props.style,
            transform: CSS.Transform.toString(
                transform && {
                    ...transform,
                    scaleY: 1,
                }
            ),
            transition,
            cursor: "move",
            ...(isDragging
                ? {
                    position: "relative",
                    zIndex: 9999,
                }
                : {}),
        };

        return (
            <tr
                {...props}
                ref={setNodeRef}
                style={style}
                {...attributes}
                {...listeners}
            />
        );
    };

    const Table = useMemo(() => {
        return (
            <TableAntDesign data={data}
                            tableName={tableName}
                            columns={[...columns, {
                                title: t("common.action"),
                                key: "action",
                                width: 100,
                                align: "left",
                                render: (_, record: any) => {
                                    return (
                                        <Space>
                                            <a onClick={() => {
                                                setOpenModal(true)
                                                setModalType("edit")
                                                setEditData(record)
                                            }}>
                                                <EditOutlined/>
                                            </a>
                                            {
                                                !record?.isSystem &&
                                                <Popconfirm
                                                    placement="left"
                                                    description={t("Are you sure to delete this comm setting?")}
                                                    title={t(`Delete Comm Setting`)}
                                                    onConfirm={() => {
                                                        deleteId(record?._id);
                                                    }}
                                                    okText={t("Yes")}
                                                    okType="danger"
                                                    cancelText={t("No")}
                                                >
                                                    <a>
                                                        <AiOutlineDelete style={{color: "red"}}/>
                                                    </a>
                                                </Popconfirm>
                                            }
                                        </Space>
                                    )
                                },
                            }]}
                            size={"small"}
                            component={{
                                body: {
                                    row: tableRow,
                                },
                            }}
                            startDate={setStartDate}
                            endDate={setEndDate}
                            dateKey={setDateKey}
                            server={null}
                            loading={loading}
                            filter={setFilter}
                            order={setOrderBy}
                            pagination={{
                                pageSize: limit,
                                showSizeChanger: true,
                                position: "bottomCenter",
                                total: total,
                                onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                                showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                            }}
            />
        )
    }, [limit, total, data]);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 1,
            },
        })
    );

    return (
        <div style={{marginTop: "15px"}}>
            <Row justify={"end"} className={"margin-bottom-0-75"}>
                <Space>
                    <EditTableUserPreferenceButton
                        tableName={tableName}
                        tableColumn={columns}
                    />
                    <Button type={"primary"} onClick={() => {
                        setOpenModal(true)
                        setModalType("add")
                        setEditData({})
                    }}>
                        {t("common.add")}
                    </Button>
                </Space>
            </Row>

            <div className={"margin-top-0-75"}>

                <DndContext
                    onDragEnd={onDragEnd}
                    sensors={sensors}
                    modifiers={[restrictToVerticalAxis]}
                >
                    <SortableContext
                        items={
                            data?.length > 0
                                ? data?.map((i) => i._id)?.filter((item) => item !== null)
                                : []
                        }
                        strategy={verticalListSortingStrategy}
                    >
                        {Table}
                    </SortableContext>
                </DndContext>
            </div>

            <CommSettingModal modalType={modalType} isOpen={openModal}
                              closeModal={() => {
                                  setOpenModal(false)
                                  setEditData({})
                              }}
                              allReport={allReport}
                              preload={preload} editData={editData} preloadSetting={preloadSetting}/>
        </div>

    )
}

export default CommSetting