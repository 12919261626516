import {useState} from "react";
import {getCustomActionTemplate, getCustomActionTemplateByTrigger} from "../../../../../api/restful/automationSetting/customActionTemplate";
import {getCustomTrigger} from "../../../../../api/restful/automationSetting/customTrigger";
import {getCustomAction} from "../../../../../api/restful/automationSetting/customAction";
import {getRuleCondition, getRuleConditionSchedule} from "../../../../../api/restful/automationSetting/ruleCondition";

export const useGetCustomTrigger = () => {
    const [data, setData] = useState<any>(null)

    const fetchCustomTrigger = async () => {
        try {
            const {data} = await getCustomTrigger()
            setData(data)
        } catch (e) {

        }
    }

    return [data, fetchCustomTrigger] as const
}

export const useGetCustomActionTemplate = () => {
    const [data, setData] = useState<any>(null)

    const fetchCustomActionTemplate = async (filter) => {
        try {
            const {data} = await getCustomActionTemplate(filter)
            setData(data)
        } catch (e) {

        }
    }

    return [data, fetchCustomActionTemplate] as const
}

export const useGetCustomAction = () => {
    const [data, setData] = useState<any>(null)

    const fetchCustomAction = async (filter) => {
        try {
            const {data} = await getCustomAction(filter)
            setData(data?.[0])
        } catch (e) {

        }
    }

    return [data, fetchCustomAction] as const
}

export const useGetCustomActionTemplateByTrigger = () => {
    const [data, setData] = useState<any>(null)

    const fetchCustomActionTemplateByTrigger = async (trigger) => {
        try {
            const {data} = await getCustomActionTemplateByTrigger(trigger)
            setData(data)
        } catch (e) {

        }
    }

    const emptyActionTemplate = () => {
        setData([])
    }

    return [data, fetchCustomActionTemplateByTrigger, emptyActionTemplate] as const
}

// CONDITION
export const useGetRuleCondition = () => {
    const [data, setData] = useState<any>(null)

    const fetchRuleCondition = async (filter) => {
        try {
            const {data} = await getRuleCondition(filter)
            setData(data)
        } catch (e) {

        }
    }

    return [data, fetchRuleCondition] as const
}

export const useGetRuleConditionSchedule = () => {
    const [data, setData] = useState<any>(null)

    const fetchRuleConditionSchedule = async (filter) => {
        try {
            const {data} = await getRuleConditionSchedule(filter)
            setData(data)
        } catch (e) {

        }
    }

    return [data, fetchRuleConditionSchedule] as const
}