import React from "react";
import VoucherInformation from "./reward/voucherInformation";

const RewardTabItem = (props) => {
    const {data, permissionUser} = props;

    return (
        <>
            <VoucherInformation data={data} permissionUser={permissionUser} />
        </>
    );
};

export default RewardTabItem;
