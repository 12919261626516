import { gql, useMutation, useQuery } from '@apollo/client'

const GET_GSL_SCHEDULE_COUNT = gql`
  query getGslScheduleCount($filter: GslScheduleInput) {
    getGslScheduleCount(filter: $filter)
  }
`;

const getGslScheduleCount = ({filter}) => {	
	try{
    const { loading, error, data } = useQuery(GET_GSL_SCHEDULE_COUNT, {
        variables: {
            filter: {
              scheduleId: filter?.scheduleId || null,
              name: filter?.name || null,
              serverName: filter?.serverName || null,
            }
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
	}
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}

const GET_GSL_SCHEDULE_BY_ID = gql`
  query getGslScheduleByID($id: String!) {
    getGslScheduleByID(id: $id){
      ... on GslSchedule {
        _id
        id
        scheduleId
        name
        tiers {
            startTime
            multiplier
            volume
            hasVolume
            leverage
            hasLeverage
        }
        isDeleted
        server {name}
        createdBy {username}
        updatedBy {username}
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }

`;

const getGslScheduleById = (id) => {	
	try{
    const { loading, error, data } = useQuery(GET_GSL_SCHEDULE_BY_ID, {
        variables: {
          id: id
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
	}
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}


const GET_GSL_SCHEDULE = gql`
  query getGslSchedule($filter: GslScheduleInput, $limit: Int, $offset: Int, $orderBy: String) {
    getGslSchedule(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy){
        ... on GslScheduleArray {
        data {
          _id
          id
          scheduleId
          name
          tiers {
              startTime
              multiplier
              volume
              hasVolume
              leverage
              hasLeverage
          }
          isDeleted
          server {name}
          createdBy {username}
          updatedBy {username}
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getGslSchedule = ({filter}, limit?, offset?, orderBy?) => {	
	try{
    const { loading, error, data } = useQuery(GET_GSL_SCHEDULE, {
        variables: {
          filter: {
            scheduleId: filter?.scheduleId || null,
            name: filter?.name || null,
            serverName: filter?.serverName || null,
          },
          limit: limit || null,
          offset: offset || null,
          orderBy: orderBy || null,
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
	}
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}

const REFRESH_GSL_SCHEDULE = gql`
  mutation refreshGslSchedule($server: String!) {
    refreshGslSchedule(server: $server){
      ... on GslScheduleArray {
        data {
          _id
          id
          scheduleId
          name
          tiers {
              startTime
              multiplier
              volume
              hasVolume
              leverage
              hasLeverage
          }
          isDeleted
          server {name}
          createdBy {username}
          updatedBy {username}
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`

const useRefreshGslScheduleMutation = (): { refreshGslSchedule: any, data: any, loading: boolean, error: any } => {
  try{
    const [useRefreshGslScheduleMutation, { data, loading, error }] = useMutation(REFRESH_GSL_SCHEDULE);

    const refreshGslSchedule = (input) => {
      return useRefreshGslScheduleMutation({
        variables: {
          input: {
            server: input.server || null,
          },
        },
      });
    };

    return { refreshGslSchedule, data, loading, error } 
  }
  catch(err: any){
    console.error(err);
    return { refreshGslSchedule: null, data: null, loading: false, error: err };
  }
}

export {
  getGslScheduleCount, 
  getGslScheduleById, 
  getGslSchedule, 
  useRefreshGslScheduleMutation
}