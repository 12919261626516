import React, {useEffect, useState} from "react";
import {Button, Checkbox, Col, Empty, Form, Input, Modal, Row, Select, Space, Tabs, Tag} from "antd";
import {useTranslation} from "react-i18next";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {languageOptionsState, userOptionsState} from "../../../users/_common/state";
// import {
//     useAddEmailTemplateMutationWithoutNotification,
//     useSendEmailMutationWithoutNotification,
//     useUpdateEmailTemplateMutationWithoutNotification
// } from "../../../../api/graphql/pushNotificationTemplate";
import useAuthorize from "../../../../_common/authorize";
import {notificationMessage} from "../../../../../recoil_state";
import {
    addPushNotificationDraft,
    addPushNotificationTemplateMessageType,
    archivePushNotificationDraft,
    pushNotificationToQueueByBatch,
    updatePushNotificationDraft
} from "../../../../api/graphql/pushNotification";
import moment from "moment";
import {
    getPushNotificationTemplateById,
    updatePushNotificationTemplate
} from "../../../../api/graphql/pushNotificationTemplate";
import GrapeJSEditor from "../../../settings/pushNotificationTemplateEditor/view/layout1/component/GrapeJSEditor";

const SendPushNotificationModal = ({
                                       isOpen,
                                       handleCancel,
                                       pushNotificationTemplate,
                                       mode,
                                       selectedRecord,
                                       fetchPushNotificationLogData,
                                       fetchPushNotificationDraftData,
                                       fetchPushNotificationTemplate,
                                       setActiveTab,
                                       selectedPushNotificationTemplate,
                                       setSelectedPushNotificationTemplate,
                                       draftTemplate,
                                       setDraftTemplate,
                                       isDraft,
                                       imageVarData,
                                       textVarData,
                                       pdfVarData,
                                       notificationDataObject,
                                       setNotificationDataObject
                                   }) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const {baseErrorChecking} = useAuthorize()
    const languageOptions = useRecoilValue<any>(languageOptionsState)
    const userOptions = useRecoilValue<any>(userOptionsState);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [action, setAction] = useState("")
    const [currentLanguage, setCurrentLanguage] = useState<string>("en");
    const [dataByLanguage, setDataByLanguage] = useState<any>({})
    const [initialData, setInitialData] = useState({})
    const [userGroup, setUserGroup] = useState<string | null>("");
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const platformOptions = [
        {label: "Web", value: "web", color: "blue"},
        {label: "Mobile", value: "mobile", color: "orange"},
        {label: "App (iOS)", value: "app_ios", color: "purple"},
        {label: "App (Android)", value: "app_android", color: "green"},
    ];

    const labelCol = {span: 6}
    const wrapperCol = {span: 18}

    const resetFormFields = async () => {
        if (mode === "edit") {
            if (isDraft) {
                const template = pushNotificationTemplate?.find(d => d?._id === selectedRecord?.fromTemplate?._id)
                setSelectedPushNotificationTemplate(template)
                setNotificationDataObject(template?.dataArrayOfObject)
                form.setFieldValue("to", selectedRecord?.recipient)
                form.setFieldValue("template", selectedRecord?.fromTemplate?._id)
                form.setFieldValue("network", selectedRecord?.sendInNetwork)
                form.setFieldValue("userType", selectedRecord?.userType || "all")

                if (!pushNotificationTemplate?.some(template => template._id === selectedRecord?.fromTemplate?._id)) {
                    setDraftTemplate([{
                        value: selectedRecord?.fromTemplate?._id,
                        label: selectedRecord?.fromTemplate?.name?.includes("- Edited") ? (
                            <>
                                {selectedRecord?.fromTemplate?.name?.split("- Edited")[0]?.trim()}
                                <Tag className={"edited-tag-disabled"} style={{marginLeft: 8}}>Edited</Tag>
                            </>
                        ) : selectedRecord?.fromTemplate?.name,
                    }]);

                    const response = await getPushNotificationTemplateById(selectedRecord?.fromTemplate?._id)
                    const fetchedTemplate = response?.getPushNotificationTemplateById
                    setSelectedPushNotificationTemplate(fetchedTemplate)
                    setNotificationDataObject(template?.dataArrayOfObject)
                }
            }
        } else {
            form.resetFields()
        }
    }

    useEffect(() => {
        if (isOpen === "pushNotification") {
            setIsEditing(false)
            resetFormFields()
        }
    }, [isOpen, selectedRecord]);

    useEffect(() => {
        if (selectedPushNotificationTemplate) setInitialValues()
    }, [selectedPushNotificationTemplate]);

    const setInitialValues = () => {
        form.setFieldValue("targetPlatform", selectedPushNotificationTemplate?.targetPlatform)
        setNotificationDataObject(selectedPushNotificationTemplate?.dataArrayOfObject)

        selectedPushNotificationTemplate?.dataArrayOfObject?.map(d => {
            const foundLanguage = languageOptions?.find(lang => lang?.value === d?.language)?.value
            if (foundLanguage) {
                setDataByLanguage(prev => ({
                    ...prev,
                    [`title_${foundLanguage}`]: d?.notification?.title,
                    [`body_${foundLanguage}`]: d?.notification?.body,
                    [`icon_${foundLanguage}`]: d?.notification?.icon,
                    [`image_${foundLanguage}`]: d?.notification?.image,
                    [`longContent_${foundLanguage}`]: d?.longContent,
                }))

                setInitialData(prev => ({
                    ...prev,
                    [`title_${foundLanguage}`]: d?.notification?.title,
                    [`body_${foundLanguage}`]: d?.notification?.body,
                    [`icon_${foundLanguage}`]: d?.notification?.icon,
                    [`image_${foundLanguage}`]: d?.notification?.image,
                    [`longContent_${foundLanguage}`]: d?.longContent,
                }))

                form.setFieldValue(`title_${foundLanguage}`, d?.notification?.title)
                form.setFieldValue(`body_${foundLanguage}`, d?.notification?.body)
                form.setFieldValue(`icon_${foundLanguage}`, d?.notification?.icon)
                form.setFieldValue(`image_${foundLanguage}`, d?.notification?.image)
                form.setFieldValue(`longContent_${foundLanguage}`, d?.longContent)
            }
        })
    }

    const contentTabs = languageOptions?.map(lang => {
        let notificationDataLanguage: any = selectedPushNotificationTemplate?.dataArrayOfObject?.find((v: any) => v?.language === lang?.value);
        const html = notificationDataLanguage?.longContent
        return {
            key: lang?.value,
            label: lang?.label,
            children: (
                <div className={"margin-top-0-75"}>
                    <Form.Item
                        label={t("message.title")}
                        name={`title_${lang?.value}`}
                    >
                        <Input disabled={!isEditing || submitLoading}/>
                    </Form.Item>
                    <Form.Item
                        label={t("message.body")}
                        name={`body_${lang?.value}`}
                    >
                        <Input.TextArea disabled={!isEditing || submitLoading}/>
                    </Form.Item>
                    <Form.Item
                        label={t("message.icon")}
                        name={`icon_${lang?.value}`}
                    >
                        <Input disabled={!isEditing || submitLoading}/>
                    </Form.Item>
                    <Form.Item
                        label={t("message.image")}
                        name={`image_${lang?.value}`}
                    >
                        <Input disabled={!isEditing || submitLoading}/>
                    </Form.Item>
                    <Form.Item
                        className={"margin-top-0-75"}
                        wrapperCol={{span: 24}}
                        name={`longContent_${lang?.value}`}
                    >
                        {!isEditing ? (
                            <div>
                                <p>{t("message.content_preview")}: </p>
                                {html && html !== "" ?
                                    <div className={"tinyMceParagraph"} dangerouslySetInnerHTML={{__html: html}}/>
                                    :
                                    <Empty/>
                                }
                            </div>
                        ) : (
                            <GrapeJSEditor
                                languageCode={lang.value}
                                data={selectedPushNotificationTemplate}
                                imageVarData={imageVarData}
                                textVarData={textVarData}
                                pdfVarData={pdfVarData}
                                setNotificationDataObject={setNotificationDataObject}
                                // handleEmailTemplateUpdate
                            />
                        )}
                    </Form.Item>
                </div>
            ),
            forceRender: true
        }
    })

    const resetStateAfterSuccess = (messageKey) => {
        handleCancel();
        setDataByLanguage({});
        fetchPushNotificationLogData();
        fetchPushNotificationDraftData();
        setNotificationMessage({
            status: "success",
            title: "",
            msg: t(messageKey),
            key: "messagesNotification",
        });
        setActiveTab("outbox");
    };

    const handleSendNotification = async (sendPushNotificationInput, draftId) => {
        const response = await pushNotificationToQueueByBatch(sendPushNotificationInput);

        if (response?.pushNotificationToQueueByBatch?.__typename === "PushNotificationBatchLog") {
            resetStateAfterSuccess("message.push_notification_queued_successfully");

            if (isDraft) {
                await archivePushNotificationDraft(draftId);
            }
        } else if (response?.pushNotificationToQueueByBatch?.__typename === "BaseError") {
            baseErrorChecking(response?.pushNotificationToQueueByBatch);
        }
    };

    const handleSaveNotification = async (sendPushNotificationInput, draftId) => {
        let response;

        if (isDraft) {
            response = await updatePushNotificationDraft(draftId, sendPushNotificationInput);
        } else {
            response = await addPushNotificationDraft(sendPushNotificationInput);
        }

        if (response?.updatePushNotificationDraft?.__typename === "PushNotificationDraft" ||
            response?.addPushNotificationDraft?.__typename === "PushNotificationDraft") {
            resetStateAfterSuccess("message.push_notification_updated_successfully");
            setActiveTab("draft");
        } else if (response?.updatePushNotificationDraft?.__typename === "BaseError" ||
            response?.addPushNotificationDraft?.__typename === "BaseError") {
            baseErrorChecking(response?.updatePushNotificationDraft || response?.addPushNotificationDraft);
        }
    };

    const onSubmit = async (value) => {
        const template = form.getFieldValue("template")
        const users = form.getFieldValue("to") && form.getFieldValue("to")?.length > 0

        if (!template) {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Please select a template",
                key: "messagesNotification",
            });
            return
        }

        if (!users) {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Please select users to send to",
                key: "messagesNotification",
            });
            return
        }

        setSubmitLoading(true);
        // const dataArrayOfObject = notificationDataObject
        const longContentMap = notificationDataObject?.reduce((acc, obj) => {
            acc[obj?.language] = obj?.longContent || "";
            return acc;
        }, {});

        const dataArrayOfObject = Object.keys(dataByLanguage)
            .map((d) => {
                const lang = d.split("_")[1];
                return {
                    longContent: longContentMap[lang] || "",
                    language: lang,
                    notification: {
                        title: form.getFieldValue(`title_${lang}`) || "",
                        body: form.getFieldValue(`body_${lang}`) || "",
                        icon: form.getFieldValue(`icon_${lang}`) || "",
                        image: form.getFieldValue(`image_${lang}`) || "",
                    },
                };
            })
            .filter((item, index, self) =>
                index === self.findIndex((i) => i.language === item.language)
            );

        try {
            let id = selectedPushNotificationTemplate?._id;
            let draftId = isDraft ? selectedRecord?._id : null;

            if (isEditing) {
                const pushNotificationTemplateInput = {
                    targetPlatform: selectedPushNotificationTemplate?.targetPlatform,
                    name: selectedPushNotificationTemplate?.name.includes("- Edited")
                        ? selectedPushNotificationTemplate?.name
                        : `${selectedPushNotificationTemplate?.name} - Edited ${moment().utc().format()}`,
                    dataArrayOfObject,
                    isActive: true,
                };

                if (selectedPushNotificationTemplate?.type === "message") {
                    const response = await updatePushNotificationTemplate(id, pushNotificationTemplateInput);
                    if (response?.updatePushNotificationTemplate?.__typename === "PushNotificationTemplate") {
                        resetStateAfterSuccess("message.push_notification_queued_successfully");
                    } else if (response?.updatePushNotificationTemplate?.__typename === "BaseError") {
                        baseErrorChecking(response?.updatePushNotificationTemplate);
                        return;
                    }
                } else if (selectedPushNotificationTemplate?.type === "system") {
                    const response = await addPushNotificationTemplateMessageType(pushNotificationTemplateInput);
                    if (response?.addPushNotificationTemplateMessageType?.__typename === "PushNotificationTemplate") {
                        id = response?.addPushNotificationTemplateMessageType?._id;
                        resetStateAfterSuccess("message.push_notification_queued_successfully");
                    } else if (response?.addPushNotificationTemplateMessageType?.__typename === "BaseError") {
                        baseErrorChecking(response?.addPushNotificationTemplateMessageType);
                        return;
                    }
                }
            }

            const sendPushNotificationInput = {
                fromTemplate: id,
                recipient: value?.to,
                sendInNetwork: value?.network || false,
                userType: value?.userType || "all",
            };

            if (action === "send") {
                await handleSendNotification(sendPushNotificationInput, draftId);
            } else if (action === "save") {
                await handleSaveNotification(sendPushNotificationInput, draftId);
            }
        } catch (e) {
            console.log(e);
        } finally {
            fetchPushNotificationTemplate();
            setSubmitLoading(false);
        }
    };

    const onTabChangeHandler = (e) => {
        setCurrentLanguage(e)
        setInitialData(dataByLanguage)
    }

    return (
        <Modal
            open={isOpen === "pushNotification"}
            onCancel={handleCancel}
            title={t("message.send_push_notification")}
            getContainer={"#messages"}
            className={"send-message-modal"}
            footer={
                <Row justify={"end"}>
                    <Space>
                        <Button onClick={handleCancel}>
                            {t("common.cancel")}
                        </Button>
                        <Button
                            type={"primary"}
                            onClick={() => {
                                setAction("save")
                                form.submit()
                            }}
                            loading={submitLoading && action === "save"}
                            disabled={submitLoading}
                        >
                            {t("common.save")}
                        </Button>
                        <Button
                            type={"primary"}
                            onClick={() => {
                                setAction("send")
                                form.submit()
                            }}
                            loading={submitLoading && action === "send"}
                            disabled={submitLoading}
                        >
                            {t("common.send")}
                        </Button>
                    </Space>
                </Row>
            }
        >
            <Form onFinish={onSubmit} form={form} labelCol={labelCol} wrapperCol={wrapperCol} labelAlign={"left"}>
                <Form.Item label={t("message.to")} required>
                    <Row gutter={8}>
                        <Col span={5}>
                            <Form.Item name={"userType"} wrapperCol={{span: 24}}>
                                <Select
                                    options={[
                                        {label: t("common.all"), value: "all"},
                                        {label: t("common.clients"), value: "client"},
                                        {label: t("module.crmUser"), value: "crmUser"},
                                    ]}
                                    defaultValue={"all"}
                                    placeholder={t("common.select_group")}
                                    disabled={submitLoading}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={19}>
                            <Form.Item name={"to"} wrapperCol={{span: 24}}>
                                <Select
                                    allowClear
                                    options={userOptions
                                        ?.map(d => ({
                                            ...d,
                                            value: d?.value,
                                        }))}
                                    mode={"multiple"}
                                    maxTagCount={1}
                                    optionFilterProp={"label"}
                                    disabled={submitLoading}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item
                    label={t("message.network")}
                    name={"network"}
                    valuePropName={"checked"}
                >
                    <Checkbox disabled={submitLoading}>
                        {t("message.network_note")}
                    </Checkbox>
                </Form.Item>
                <Form.Item
                    label={t("message.from_template")}
                    name={"template"}
                    required
                >
                    <Select
                        options={draftTemplate && draftTemplate?.length > 0 ? draftTemplate :
                            pushNotificationTemplate?.filter(d => d?.type === "system")?.map(d => ({
                                label: d.name,
                                value: d._id,
                            }))}
                        onChange={(e) => setSelectedPushNotificationTemplate(pushNotificationTemplate?.find(d => d._id === e))}
                        disabled={submitLoading || isEditing || isDraft}
                    />
                </Form.Item>
                {selectedPushNotificationTemplate && (
                    <Form.Item label={t("message.target_platform")} name={"targetPlatform"}>
                        <Select
                            mode={"multiple"}
                            tagRender={({label, value, onClose}) => {
                                const option = platformOptions.find(option => option.value === value);
                                const color = option ? option.color : "red";

                                return (
                                    <Tag color={color} closable={isEditing && !submitLoading} onClose={onClose}>
                                        {label}
                                    </Tag>
                                );
                            }}
                            options={platformOptions}
                            disabled={!isEditing || submitLoading}
                        />
                    </Form.Item>
                )}
                {selectedPushNotificationTemplate && (
                    <Tabs
                        items={contentTabs}
                        tabBarExtraContent={
                            !isEditing &&
                            <Button onClick={() => setIsEditing(true)}>{t("message.edit_content")}</Button>
                        }
                        onTabClick={(e) => onTabChangeHandler(e)}
                    />
                )}
            </Form>
        </Modal>
    )
}

export default SendPushNotificationModal