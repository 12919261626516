import React, {useEffect} from "react";
import LeaderboardSettingsUIEditor_1 from "./view/layout1";
import {useParams} from "react-router";
import "./view/css/style.css"
import {DetailData} from "./data/data";

const LeaderboardSettingsUIEditor = () => {
    const {id} = useParams();
    let layout = 1;
    let component;

    useEffect(() => {
        console.log(id)
    }, [id]);

    const props = {
        data: DetailData
    }

    switch (layout) {
        case 1:
            component = <LeaderboardSettingsUIEditor_1 {...props}/>
            break;

        default:
            component = <LeaderboardSettingsUIEditor_1 {...props}/>
            break;
    }

    return component;
}

export default LeaderboardSettingsUIEditor;