import * as yup from "yup";

const yupFormSchema = {
  string(label, config?) {
    config = config || {};

    let yupChain = yup
      .string()
      .transform((cv, ov) => {
        return ov === "" ? null : cv;
      })
      .nullable(true)
      .trim()
      .label(label);

    if (config.required) {
      if(config.requiredErrorMessage){
        yupChain = yupChain.required(config.requiredErrorMessage);
      }
      else {
        yupChain = yupChain.required();
      }
    }

    if (config.matches) {
      if (config.matchesErrorMessage) {
        yupChain = yupChain.matches(config.matches, {message: config.matchesErrorMessage});
      } else {
        yupChain = yupChain.matches(config.matches);
      }
    }

    return yupChain;
  },
  email(label, config?) {
    config = config || {};

    let yupChain = yup
      .string()
      .transform((cv, ov) => {
        return ov === "" ? null : cv;
      })
      .nullable(true)
      .trim()
      .label(label);

    if (config.required) {
      yupChain = yupChain.required();
    }

    if (config.typeError) {
      yupChain = yupChain.email(config.typeError)
    } else {
      yupChain = yupChain.email()
    }

    if (config.matches) {
      yupChain = yupChain.matches(config.matches);
    }

    return yupChain;
  },
  boolean(label, config?) {
    config = config || {};

    let yupChain = yup.bool().label(label);

    if (config.required) {
      yupChain = yupChain.required();
    }

    return yupChain;
  },
  number(label, config?) {
    config = config || {};
    let yupChain = yup.number().label(label).nullable(true);

    if (config.required) {
      yupChain = yupChain.required();
    }

    if (config.min !== null && config.min !== undefined) {
      yupChain = yupChain.min(config.min);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }
    return yupChain;
  },
  array(label, config?) {
    config = config || {};

    let yupChain = yup.array().label(label).nullable(true);

    if (config.required) {
      yupChain = yupChain.required();
    }

    return yupChain;
  },
  date(label, config) {
    config = config || {};

    let yupChain = yup
      .date()
      .transform((cv, ov) => {
        return ov === "" ? null : cv;
      })
      .nullable(true)
      .label(label);

    if (config.required) {
      yupChain = yupChain.required();
    }

    return yupChain;
  },
};

export default yupFormSchema;
