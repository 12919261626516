import React from "react";
import {Tabs} from "antd";
import LevelSetting from "./components/levelSetting";
import DescriptionSetting from "./components/descriptionSetting";
import "./style/style.css"

const GeneralSetting_1 = ({levels, fetchLevelSetting, description, fetchDescription}) => {

    const items = [
        {
            key: 'level',
            label: 'Level',
            children: <LevelSetting data={levels} fetchLevelSetting={fetchLevelSetting}/>,
        },
        {
            key: "description",
            label: 'Description',
            children: <DescriptionSetting description={description} fetchDescription={fetchDescription}/>
        }
    ]

    return (
        <div id={"general-setting"}>
            <h1>General Setting</h1>

            <Tabs items={items}/>
        </div>
    )
}

export default GeneralSetting_1