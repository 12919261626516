import React, {useEffect, useState} from "react";
import {Col, DatePicker, Divider, Form, Input, Row, Select} from "antd";
import {useTranslation} from "react-i18next";
import {renderProfileInformation} from "../../../../../../_common/function";
import dayjs from "dayjs";
import {useRecoilValue} from "recoil";
import {fieldListState} from "../../../../../../../../../recoil_state";
import {formatDateUTC} from "../../../../../../../../function/_common";

const BasicInformation = (props) => {
    const {data, editable, basicField, loginField, additionalField, isReadSensitive, form} = props
    const [startDate, setStartDate] = useState<any>()
    const [endDate, setEndDate] = useState<any>()
    const {t} = useTranslation()
    const fieldList = useRecoilValue<any>(fieldListState)

    useEffect(() => {
        setStartDate(data?.loginStartDate)
        setEndDate(data?.loginEndDate)
    },[data])

    return (
        data &&
        <Row gutter={[16, 16]} className={"margin-top-0-75 margin-bottom-0-75"}>
            {basicField?.length > 0 && (
                <Col span={24}>
                    <h3>{t("field.category.basicInformation")}</h3>
                    <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                        {renderProfileInformation(basicField, data, t, !editable, isReadSensitive)}
                        <Col xs={24} md={12}>
                            <Form.Item
                                name={"comment"}
                                label={t("user.comment")}
                                labelCol={{span: 10, offset: 0}}
                                initialValue={data?.comment}
                                labelAlign={"left"}
                            >
                                <Select
                                    showSearch
                                    disabled={!editable}
                                    allowClear
                                    options={fieldList?.find(d => d?.fieldName === "crmUserComment")?.optionValue?.map(d => ({...d, label: t(d?.label)}))}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name={"loginStartDate"}
                                label={t("user.startDate")}
                                labelCol={{span: 10, offset: 0}}
                                initialValue={data?.loginStartDate ? formatDateUTC(data?.loginStartDate, "formValue") : undefined}
                                labelAlign={"left"}
                            >
                                <DatePicker
                                    disabled={!editable}
                                    style={{width: "100%"}}
                                    disabledDate={current => {
                                        return current && current.isAfter(dayjs(endDate).startOf("day"));
                                    }}
                                    onChange={value => setStartDate(value)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name={"loginEndDate"}
                                label={t("user.endDate")}
                                labelCol={{span: 10, offset: 0}}
                                initialValue={data?.loginEndDate ? formatDateUTC(data?.loginEndDate, "formValue") : undefined}
                                labelAlign={"left"}
                            >
                                <DatePicker
                                    disabled={!editable}
                                    style={{width: "100%"}}
                                    disabledDate={current => {
                                        return current && current.isBefore(dayjs(startDate).startOf("day"));
                                    }}
                                    allowClear
                                    onChange={value => setEndDate(value)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            )}
            {loginField?.length > 0 && (
                <>
                    <Divider/>
                    <Col span={24}>
                        <h3>{t("field.category.loginInformation")}</h3>
                        <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                            {renderProfileInformation(loginField, data, t, !editable, isReadSensitive, form)}
                        </Row>
                    </Col>
                </>
            )}

            {additionalField?.length > 0 && (
                <>
                    <Divider/>
                    <Col span={24}>
                        <h3>{t("field.category.additionalInformation")}</h3>
                        <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                            {renderProfileInformation(additionalField, data, t, !editable, isReadSensitive)}
                        </Row>
                    </Col>
                </>
            )}
        </Row>
    )
}

export default BasicInformation