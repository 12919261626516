import React from "react";
import {useTranslation} from "react-i18next";
import {Modal} from "antd";
import CompanyEmailTypesForm from "./companyEmailTypesForm";

const CompanyEmailTypesModal = (props) => {
    const {mode, item, isOpen, closeModal, getCompanyEmailTypesData} = props;
    const {t} = useTranslation();

    const getTitle = (): String => {
        switch (mode) {
            case "add":
                return `${t("Add New Email Types")}`;
            case "view":
                return `${t("View Email Types")}`;
            case "edit":
                return `${t("Edit Email Types")}`;
            default:
                return `${t("Add New Email Types")}`;
        }
    };
    return (
        <Modal getContainer={"#companyEmailTypes"} title={getTitle()} open={isOpen} width={"50%"} footer={null} destroyOnClose onCancel={closeModal}>
            <CompanyEmailTypesForm mode={mode} item={item} closeModal={closeModal} getCompanyEmailTypesData={getCompanyEmailTypesData}/>
        </Modal>
    );
};

export default CompanyEmailTypesModal;
