import {gql, useMutation, useQuery} from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import {notificationMessage} from "../../../recoil_state";

const GET_MISSION_GROUP_COUNT = gql`
    query getMissionGroupCount($filter: MissionGroupSearchInput) {
        getMissionGroupCount(filter: $filter)
    }
`;

const getMissionGroupCount = ({filter}) => {
    try {
        const {loading, error, data} = useQuery(GET_MISSION_GROUP_COUNT, {
            variables: {
                filter: {
                    name: filter?.name || null,
                },
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_MISSION_GROUP = gql`
    query getMissionGroup($filter: MissionGroupSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
        getMissionGroup(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ...on MissionGroupArray {
                data {
                    _id
                    id
                    name
                    displayName {content language}
                    description {content language}
                    imageLight{
                        fileList
                        path
                    }
                    imageDark{
                        fileList
                        path
                    }
                    defaultImageLight{
                        fileList
                        path
                    }
                    defaultImageDark{
                        fileList
                        path
                    }
                    isDeleted
                    createdAt
                    createdBy {username}
                    updatedBy {username}
                    updatedAt
                }
            }
            ...on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getMissionGroup = ({filter}, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data, refetch} = useQuery(GET_MISSION_GROUP, {
            variables: {
                filter: filter,
                limit: limit,
                offset: offset,
                orderBy: orderBy
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        const refreshQuery = () => {
            refetch();
        };

        return {loading, error, data, refreshQuery};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_MISSION_GROUP = gql`
    mutation addMissionGroup($input: MissionGroupInput) {
        addMissionGroup(input: $input) {
            ... on MissionGroup {
                    _id
                    id
                    name
                    displayName {content language}
                    description {content language}
                    imageLight{
                        fileList
                        path
                    }
                    imageDark{
                        fileList
                        path
                    }
                    defaultImageLight{
                        fileList
                        path
                    }
                    defaultImageDark{
                        fileList
                        path
                    }
                    isDeleted
                    createdAt
                    createdBy {username}
                    updatedBy {username}
                    updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useAddMissionGroupMutation = (): {
    addMissionGroup: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [addMissionGroupMutation, {data, loading, error}] = useMutation(ADD_MISSION_GROUP);

        const addMissionGroup = (input) => {
            return addMissionGroupMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {addMissionGroup}}) {
                    const typename = addMissionGroup?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(addMissionGroup?.errKey),
                            key: "addMissionGroup",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(addMissionGroup?.msg),
                            key: "addMissionGroup",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Add Successfully"),
                            key: "addMissionGroup",
                        });
                    }
                },
                refetchQueries: [GET_MISSION_GROUP, GET_MISSION_GROUP_COUNT],
            });
        };

        return {addMissionGroup, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            addMissionGroup: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};


const UPDATE_MISSION_GROUP = gql`
    mutation updateMissionGroup($id: String!, $input: MissionGroupInput) {
        updateMissionGroup(id: $id, input: $input) {
            ... on MissionGroup {
                _id
                id
                name
                displayName {content language}
                description {content language}
                isDeleted
                imageLight{
                    fileList
                    path
                }
                imageDark{
                    fileList
                    path
                }
                defaultImageLight{
                    fileList
                    path
                }
                defaultImageDark{
                    fileList
                    path
                }
                createdAt
                createdBy {username}
                updatedBy {username}
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useUpdateMissionGroupMutation = (): {
    updateMissionGroup: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const {t} = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updateMissionGroupMutation, {data, loading, error}] = useMutation(UPDATE_MISSION_GROUP);

        const updateMissionGroup = (id, input) => {
            return updateMissionGroupMutation({
                variables: {
                    id: id,
                    input: input,
                },
                update(cache, {data: {updateMissionGroup}}) {
                    const typename = updateMissionGroup?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateMissionGroup?.errKey),
                            key: "updateMissionGroup",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateMissionGroup?.msg),
                            key: "updateMissionGroup",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Successfully"),
                            key: "updateMissionGroup",
                        });
                    }
                },
                refetchQueries: [GET_MISSION_GROUP, GET_MISSION_GROUP_COUNT],
            });
        };

        return {updateMissionGroup, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            updateMissionGroup: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};


const DELETE_MISSION_GROUP = gql`
    mutation deleteMissionGroup($id: String!) {
        deleteMissionGroup(id: $id) {
            ... on MissionGroup {
                _id
                id
                isDeleted
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useDeleteMissionGroupMutation = (): {
    deleteMissionGroup: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [deleteMissionGroupMutation, {data, loading, error}] = useMutation(DELETE_MISSION_GROUP);

        const deleteMissionGroup = (id) => {
            return deleteMissionGroupMutation({
                variables: {
                    id: id,
                },
                update(cache, {data: {deleteMissionGroup}}) {
                    const typename = deleteMissionGroup?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(deleteMissionGroup?.errKey),
                            key: "deleteMissionGroup",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(deleteMissionGroup?.msg),
                            key: "deleteMissionGroup",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Delete Successfully"),
                            key: "deleteMissionGroup",
                        });
                    }
                },
                refetchQueries: [GET_MISSION_GROUP, GET_MISSION_GROUP_COUNT],
            });
        };

        return {deleteMissionGroup, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            deleteMissionGroup: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};


export {
    getMissionGroup,
    getMissionGroupCount,
    useAddMissionGroupMutation,
    useUpdateMissionGroupMutation,
    useDeleteMissionGroupMutation
};
