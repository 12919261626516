import React, {useEffect, useState} from "react";
import {useGeneralListFunction} from "../../../../function/generalListFunction";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {currentTabState, generalListDocumentState, tabBarExtraContentState} from "../../../../state";
import {Button, Card, Form, List, Popconfirm, Row, Space, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import GeneralListDocumentForm from "./modalForm/generalListDocumentForm";
import {getIsMainMasterAdmin} from "../../../../../../../api/graphql/_common";
import ImportDBTableButton from "../importDBTable";
import ExportDBTableButton from "../exportDBTable";

const GeneralListDocument = ({permission}) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const {getGeneralListDocument, createGeneralListDocument, updateGeneralListDocument, deleteGeneralListDocument} = useGeneralListFunction();
    const document = useRecoilValue<any>(generalListDocumentState);

    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isAdd, setIsAdd] = useState<any>();
    const [isView, setIsView] = useState<any>(false);
    const [editingItem, setEditingItem] = useState<any>();
    const setTabBarExtraContent = useSetRecoilState<any>(tabBarExtraContentState);
    const currentTab = useRecoilValue(currentTabState);
    const [isMainMasterAdmin, setIsMainMasterAdmin] = useState(false);

    const checkIsMasterAdmin = async () => {
        let response = await getIsMainMasterAdmin();
        setIsMainMasterAdmin(response?.isMainMasterAdmin);
    };

    useEffect(() => {
        checkIsMasterAdmin();
    }, []);

    useEffect(() => {
        if (currentTab === "document") {
            getGeneralListDocument();

            permission?.add?.add &&
                setTabBarExtraContent(
                    <>
                        <Tooltip title={t("accountVerification.addDocument")}>
                            <Button
                                onClick={() => {
                                    form.resetFields();
                                    setEditingItem(null);
                                    setIsAdd(true);
                                    setIsOpen(true);
                                    setIsView(false);
                                }}
                            >
                                {t("common.add")}
                            </Button>
                        </Tooltip>
                        {isMainMasterAdmin && <ImportDBTableButton />}
                        {isMainMasterAdmin && <ExportDBTableButton />}
                    </>
                );
        }
    }, [currentTab, isMainMasterAdmin, permission?.add?.add]);

    const onSubmit = async (value) => {
        setLoading(true);
        if (isAdd === true) {
            await createGeneralListDocument(value);
        } else {
            await updateGeneralListDocument(editingItem?._id, value);
        }
        setLoading(false);
        setIsOpen(false);
    };

    return (
        <div id={"general-list-country"} className={"margin-top-0-75"}>
            <GeneralListDocumentForm
                isAdd={isAdd}
                loading={loading}
                isOpen={isOpen}
                isView={isView}
                setIsOpen={setIsOpen}
                onSubmit={onSubmit}
                form={form}
                item={editingItem}
            />
            <List
                grid={{gutter: 16, column: 4}}
                dataSource={document}
                renderItem={(item: any) => (
                    <List.Item>
                        <Card size={"small"}>
                            <Row justify={"space-between"}>
                                <span>{t(item?.label)}</span>
                                <Space>
                                    <Tooltip title={t(permission?.edit?.edit ? "common.edit" : "common.view")}>
                                        <Button
                                            onClick={() => {
                                                form.resetFields();
                                                setEditingItem(item);
                                                setIsAdd(false);
                                                setIsOpen(true);
                                                setIsView(!permission?.edit?.edit);
                                            }}
                                            icon={permission?.edit?.edit ? <EditOutlined /> : <EyeOutlined />}
                                            type={"link"}
                                            size={"small"}
                                        />
                                    </Tooltip>
                                    {permission?.delete?.delete && (
                                        <Tooltip title={t("common.delete")}>
                                            <Popconfirm
                                                placement="left"
                                                title={t("accountVerification.popconfirm.title.document.delete")}
                                                description={t("accountVerification.popconfirm.description.document.delete")}
                                                onConfirm={async () => {
                                                    await deleteGeneralListDocument(item?._id);
                                                }}
                                                okText={t("common.yes")}
                                                cancelText={t("common.no")}
                                            >
                                                <Button icon={<DeleteOutlined />} type={"link"} danger size={"small"} />
                                            </Popconfirm>
                                        </Tooltip>
                                    )}
                                </Space>
                            </Row>
                        </Card>
                    </List.Item>
                )}
            />
        </div>
    );
};

export default GeneralListDocument;
