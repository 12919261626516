import React, {useEffect, useState} from "react";
import {Form, Input, Modal} from "antd";
import {useTranslation} from "react-i18next";
import {useSalesOpportunitySettingFunction} from "../../../function";

const AddEditStatusModal = ({isOpen, setIsOpen, mode, record}) => {
    const {t} = useTranslation()
    const {addSalesOpportunityStatusFunction, updateSalesOpportunityStatusFunction} = useSalesOpportunitySettingFunction()
    const [form] = Form.useForm()

    const [loading, setLoading] = useState(false)

    const title = mode === "add" ? "Add Sales Opportunity Status" : "Edit Sales Opportunity Status"
    const okText = mode === "add" ? "common.add" : "common.update"

    const submitForm = async (value) => {
        let response
        if (mode === "add") {
            response = await addSalesOpportunityStatusFunction(value, setLoading)
        } else {
            response = await updateSalesOpportunityStatusFunction(record?._id, value, setLoading)
        }

        if (response === "success") setIsOpen(false)
    }

    useEffect(() => {
        form.setFieldsValue({name: record?.name || ""})
    }, [record, isOpen])

    return (
        <Modal
            title={t(title)}
            open={isOpen}
            cancelText={t("common.cancel")}
            onCancel={() => setIsOpen(false)}
            okText={t(okText)}
            onOk={() => form.submit()}
            okButtonProps={{loading: loading}}
        >
            <Form form={form} layout={"vertical"} onFinish={submitForm}>
                <Form.Item
                    label={t("Name")}
                    name={"name"}
                    rules={[
                        {
                            required: true,
                            message: t("errorMsg.required.pleaseFillIn", {field: t("Name")}).toString()
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default AddEditStatusModal