import {atom} from "recoil";

const categoryListOptionState = atom({
    key: 'categoryListOption',
    default: [],
});

export {
    categoryListOptionState
}
