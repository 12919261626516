import React from "react";
import {BsCheck, BsX} from "react-icons/bs";
import {formatDateUTC} from "../../../../../function/_common";

export const columns = () => {
    return [
        {
            title: "Type",
            dataIndex: "source",
            key: "source",
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: "ExpiredAt",
            dataIndex: "expiredAt",
            key: "expiredAt",
            render: (text) => <p>{text ? formatDateUTC(text): "-"}</p> ,
        },
        {
            title: "Enabled",
            dataIndex: "isEnabled",
            key: "isEnabled",
            render: (text) => (text) ? <BsCheck style={{color: 'green'}}/> : <BsX style={{color: 'red'}}/>,
        },
        {
            title: "Max Count",
            dataIndex: "maxCount",
            key: "maxCount",
            render: (text) => <p>{text || "-"}</p>,
        },
    ]
}