import React, {useEffect, useState} from "react"
// import {getServerSetting} from "../../../../api/graphql/serverSetting";
import ReportCategory_1 from "./view/layout1/index"
import {useRecoilState, useRecoilValue} from "recoil"
import {selfPermissionObj} from "../../../../recoil_state"
import {getReportingCategory} from "../../../api/graphql/reportingCategory"
import {getReportingAll} from "../../../api/graphql/reporting"
import {reportingCategoryState, reportingAllState} from "../_general/state"
import {useReportingCategoryFunction} from "./function"

function ReportCategory() {
    let layout = 1
    let component
    const [selfPermission] = useRecoilState<any>(selfPermissionObj)
    const reportsPermission = selfPermission?.reports?.reports
    const reportCategoryPermission = selfPermission?.reports?.reportCategory
    const [orderBy, setOrderBy] = useState("")
    const [reportingAll, setReportsAll] = useRecoilState<any>(reportingAllState)
    const reportingCategory = useRecoilValue<any>(reportingCategoryState)

    const [reportOptions, setReportOptions] = useState([])

    const {getReportCategory} = useReportingCategoryFunction()

    const getAllReports = async () => {
        try {
            const response = await getReportingAll()
            const list = response?.getReportingAll?.data
            if (list) {
                setReportsAll(list)
            }
        } catch (error) {}
    }

    const setupReportOptions = () => {
        try {
            const reportsWithCategory = reportingCategory?.flatMap((d) => d?.reports?.map((e) => e?.reportId)) ?? []

            if (reportingAll?.length > 0) {
                const options = reportingAll?.map((d) => {
                    return {
                        label: d?.displayName,
                        value: d?.reportId,
                        level: d?.level,
                    }
                })
                setReportOptions(options)
            }
        } catch (error) {}
    }

    useEffect(() => {
        setupReportOptions()
    }, [reportingAll])

    useEffect(() => {
        const filter = {isEnabled: null}
        getReportCategory(filter)
        getAllReports()
    }, [])

    switch (layout) {
        case 1:
            component = <ReportCategory_1 allReportData={reportingAll} reportsPermission={reportsPermission} reportCategoryPermission={reportCategoryPermission} reportOptions={reportOptions} setOrderBy={setOrderBy} />
            break

        default:
            component = <ReportCategory_1 allReportData={reportingAll} reportsPermission={reportsPermission} reportCategoryPermission={reportCategoryPermission} reportOptions={reportOptions} setOrderBy={setOrderBy} />
            break
    }

    return component
}

export default ReportCategory
