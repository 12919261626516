import React from "react";
import {useTranslation} from "react-i18next";
import {Card, Col, Modal, Row} from "antd";


const LogJSONModal = (props) => {
    const {isOpen, closeModal, record} = props;
    const {t} = useTranslation()


    return (
        <Modal
            title={"Log Debug"}
            open={isOpen}
            onCancel={() => closeModal()}
            footer={null}
            width={1300}
            destroyOnClose
        >
            <Row gutter={[16, 16]} style={{marginTop: "1%"}}>
                {
                    record?.payload ? (
                        <Col span={record?.result ? 12 : 24}>
                            <Card title="Payload">
                                {record?.payload}
                            </Card>
                        </Col>
                    ) : null
                }
                {
                    record?.result ? (
                        <Col span={record?.payload ? 12 : 24}>
                            <Card title="Response" style={{minHeight: "3%"}}>
                                {record?.result}
                            </Card>
                        </Col>
                    ) : null
                }
            </Row>
        </Modal>
    )
}

export default LogJSONModal