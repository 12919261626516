import axios from "axios";
import {getConfigByName} from "../../../../config";
import {BRAND_NAME} from "../../../../config/brandVariable";

const url = getConfigByName(BRAND_NAME).arenaURL
const config = {headers: {"Content-Type": "text/plain", "authorization": `Bearer ${localStorage.getItem("arenaToken")}`}}

export const getMediaFolder = async (param = {}) => {
    try {
        return await axios.post(`${url}/api/media/folder/get`, JSON.stringify({"filter" : {}}), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export const createFolder = async (input) => {
    try {
        return await axios.post(`${url}/api/media/folder/create`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export const updateFolder = async (input) => {
    try {
        return await axios.post(`${url}/api/media/folder/update`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export const deleteFolder = async (input) => {
    try {
        return await axios.post(`${url}/api/media/folder/delete`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export const restoreFolder = async (input) => {
    try {
        return await axios.post(`${url}/api/media/folder/restore`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export const hardDeleteFolder = async (input) => {
    try {
        return await axios.post(`${url}/api/media/folder/delete/hard`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}