import {useState} from "react";
import {getCustomTrigger} from "../../../../../api/restful/automationSetting/customTrigger";

export const useGetCustomTrigger = () => {
    const [data, setData] = useState<any>(null)

    const fetchCustomTrigger = async () => {
       try {
           const {data} = await getCustomTrigger()
           setData(data)
       } catch (e) {

       }
    }

    return [data, fetchCustomTrigger] as const
}