import axios from "axios";
import {getConfigByName} from "../../../../../config";
import {BRAND_NAME} from "../../../../../config/brandVariable";

const handleObjectChange = (setArray, value, field, language) => {
  setArray((prevState) => {
    const index = prevState.findIndex((item) => item?.language === language);
    const temp = [...prevState];
    if (index === -1) {
      temp.push({ [field]: value, language: language });
    } else {
      const updatedObj = { ...temp[index], [field]: value };
      temp[index] = updatedObj;
    }
    return temp;
  });
};

const getTextFromObject = (array, field, language) => {
  const index = array?.findIndex((item) => item?.language === language);

  if (!array || !array[index] || !array[index][field]) {
    return "";
  }

  return array[index][field];
};

const getListFromObject = (array, field, language) => {
  const index = array?.findIndex((item) => item?.language === language);

  if (!array || !array[index]) {
    return [];
  }

  return array[index][field];
};

const getPdfIdFromObject = (array, field, language) => {
  const index = array?.findIndex((item) => item?.language === language);

  if (!array || !array[index] || !array[index][field]) {
    return "";
  }

  let pdfIDList = array[index][field]?.map((v) => v.id) || [];
  return pdfIDList;
};

const imageUploadHandler: any = async (blobInfo, success, failure) => {
  var uploadObject;
  const formData = new FormData();
  formData.append("file", blobInfo.blob(), blobInfo.filename());
  const brandConfig = getConfigByName(BRAND_NAME)

  await axios
    .post(`${brandConfig?.backendURL}/api/upload/profile/image`, formData, {
      withCredentials: false,
    })
    .then(async (response) => {
      const json = response?.data[0];
      if (!json || typeof json.link !== "string") {
        failure("Invalid JSON: " + JSON.stringify(json));
        return;
      }
      uploadObject = json;

      success(json.link);
    })
    .catch((error) => {
      console.log(error);
    });

  return uploadObject.link;
};

const extractIdFromObject = (array) => {
  var result: any[] = [];
  if (!array || !(array.length > 0)) {
    return [];
  }

  array.forEach((item) => {
    result.push(item?.id);
  });

  return result;
};

const handleOnChangeWithLanguage = (value, field, fieldType, languageCode, setData) => {
  try {
    setData((prevState) => {
      if (!prevState || !prevState[field]) {
        return {
          ...prevState,
          [field]: [
            {
              [fieldType]: value,
              language: languageCode,
            },
          ],
        };
      }

      var currentData = [...prevState[field]] || [];
      var index = currentData?.findIndex((item) => item?.language === languageCode);

      if (index === -1) {
        currentData?.push({[fieldType]: value, language: languageCode});
      } else {
        const updatedObj = {...currentData[index], [fieldType]: value};
        currentData[index] = updatedObj;
      }

      var result = {
        ...prevState,
        [field]: currentData,
      };

      return result;
    });
  } catch (error) {
    console.log(error);
  }
};

export {
  getListFromObject,
  getTextFromObject,
  getPdfIdFromObject,
  handleObjectChange,
  imageUploadHandler,
  extractIdFromObject,
  handleOnChangeWithLanguage,
};
