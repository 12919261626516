import {useState} from "react";
import {getProfileInformationSettingAsync} from "../../../api/graphql/profileInformation";

export const useGetProfileInformationSetting = () => {
    const [profileInformation, setData] = useState([]);
    const [profileInformationLoading, setLoading] = useState(false);

    const fetchProfileInformationSetting = async (filter: object|null) => {
        try {
            setLoading(true)
            const response = await getProfileInformationSettingAsync(filter)
            if (response?.getProfileInformationSetting?.data) {
                setData(response?.getProfileInformationSetting?.data?.filter(d => d?.position !== null))
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    return {profileInformation, profileInformationLoading, fetchProfileInformationSetting}
}