import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Popconfirm, Row, Space} from "antd";
import CreateUpdateModal from "./component/createUpdateModal";
import "./css/style.css"
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import {Columns} from "../../function/columns";
import {AiOutlineEdit} from "react-icons/ai";
import {deleteRule} from "../../../../../../api/restful/automationSetting/rule";
import {DeleteOutlined} from "@ant-design/icons";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../recoil_state";

const AutomationSetting_1 = ({data, actionTemplate, trigger, fetchActionTemplate, fetchRule, emptyActionTemplate, actionTemplateReport}) => {
    const {t} = useTranslation();
    const columns = Columns()

    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [createUpdateModalIsOpen, setCreateUpdateModalIsOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);

    const handleCloseCreateUpdateModal = () => {
        setCreateUpdateModalIsOpen(false);
        setSelectedRecord(null)
        emptyActionTemplate()
    }

    const handleDeleteRule = async (id) => {
        try {
            const res = await deleteRule({"_id": id})

            if (res.status === "Success") {
                fetchRule()
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Automation Rule deleted successfully",
                    key: "automationSetting",
                });
            } else {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: "Automation Rule deleted failed: ",
                    key: "automationSetting",
                });
                fetchRule()
            }
        } catch (e: any) {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Automation Rule deleted failed: " + e?.response?.data?.errObj,
                key: "automationSetting",
            });
        }
    }

    return (
        <div id={"automation-setting"}>
            <Row justify={"space-between"} className={"margin-bottom-0-75"}>
                <h1>{t("Automation Setting")}</h1>
                <Button onClick={() => setCreateUpdateModalIsOpen(true)}>Create Rule</Button>
            </Row>

            <TableAntDesign
                className={"margin-top-0-75"}
                data={data}
                tableName={"Automation Setting"}
                sort={false}
                columns={[...columns,
                    {
                        title: t("common.action"),
                        key: "action",
                        width: 80,
                        render: (_, record) => {
                            return (
                                <Space>
                                    <a onClick={() => {
                                        setCreateUpdateModalIsOpen(true)
                                        setSelectedRecord(record)
                                    }}>
                                        <AiOutlineEdit/>
                                    </a>
                                    <Popconfirm
                                        placement="left"
                                        title={t("Are you sure to delete this automation rule?")}
                                        onConfirm={() => {
                                            handleDeleteRule(record?._id);
                                        }}
                                        okText={t("common.yes")}
                                        cancelText={t("common.no")}
                                    >
                                        <a style={{color: "red"}}><DeleteOutlined/></a>
                                    </Popconfirm>
                                </Space>
                            )
                        }
                    }
                ]}
            />

            <CreateUpdateModal isOpen={createUpdateModalIsOpen} handleClose={handleCloseCreateUpdateModal} item={selectedRecord}
                               fetchActionTemplate={fetchActionTemplate}
                               actionTemplate={actionTemplate} trigger={trigger} fetchRule={fetchRule} actionTemplateReport={actionTemplateReport}/>
        </div>
    )
}

export default AutomationSetting_1