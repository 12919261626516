import {gql, useMutation} from "@apollo/client"
import {ApolloClientQuery} from "./_apolloClient"

const REIMPORT_TRADES = gql`
    mutation reimportTrades($id: String, $platform: String) {
        reimportTrades(id: $id, platform: $platform) {
            ... on ReportingData {
                data
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const useReimportTradesMutation = (): {
    reimportTrades: any
    data: any
    loading: boolean
    error: any
} => {
    try {
        const [reimportTradesMutation, {data, loading, error}] = useMutation(REIMPORT_TRADES)

        const reimportTrades = (id, platform) => {
            return reimportTradesMutation({
                variables: {
                    id: id,
                    platform: platform,
                },
            })
        }

        return {reimportTrades, data, loading, error}
    } catch (err: any) {
        console.error(err)
        return {
            reimportTrades: null,
            data: null,
            loading: false,
            error: err,
        }
    }
}

const UPDATE_AND_REIMPORT_TRADES = gql`
    mutation updateAndReimportTrades($id: String, $platform: String) {
        updateAndReimportTrades(id: $id, platform: $platform) {
            ... on ReportingData {
                data
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const useUpdateAndReimportTradesMutation = (): {
    updateAndReimportTrades: any
    data: any
    loading: boolean
    error: any
} => {
    try {
        const [updateAndReimportTradesMutation, {data, loading, error}] = useMutation(UPDATE_AND_REIMPORT_TRADES)

        const updateAndReimportTrades = (id, platform) => {
            return updateAndReimportTradesMutation({
                variables: {
                    id: id,
                    platform: platform,
                },
            })
        }

        return {updateAndReimportTrades, data, loading, error}
    } catch (err: any) {
        console.error(err)
        return {
            updateAndReimportTrades: null,
            data: null,
            loading: false,
            error: err,
        }
    }
}

const UPDATE_LATEST_TRADING_ACCOUNT_REPORT = gql`
    mutation updateLatestTradingAccountReport($id: String) {
        updateLatestTradingAccountReport(id: $id) {
            ... on ReportingData {
                data
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const useUpdateLatestTradingAccountReportMutation = (): {
    updateLatestTradingAccountReport: any
    data: any
    loading: boolean
    error: any
} => {
    try {
        const [updateLatestTradingAccountReportMutation, {data, loading, error}] = useMutation(UPDATE_LATEST_TRADING_ACCOUNT_REPORT)

        const updateLatestTradingAccountReport = (id) => {
            return updateLatestTradingAccountReportMutation({
                variables: {
                    id: id,
                },
            })
        }

        return {updateLatestTradingAccountReport, data, loading, error}
    } catch (err: any) {
        console.error(err)
        return {
            updateLatestTradingAccountReport: null,
            data: null,
            loading: false,
            error: err,
        }
    }
}

export {useReimportTradesMutation, useUpdateAndReimportTradesMutation, useUpdateLatestTradingAccountReportMutation}
