import {gql, useMutation, useQuery} from "@apollo/client";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {useTranslation} from "react-i18next";
import {ApolloClientQuery} from "./_apolloClient";
import {GET_USER_BY_ID} from "./user";

const GET_PRODUCT_COUNT = gql`
    query getProductCount($filter: ProductSearchInput) {
        getProductCount(filter: $filter)
    }
`;

const getProductCount = ({filter}) => {
    try {
        const {loading, error, data} = useQuery(GET_PRODUCT_COUNT, {
            variables: {
                filter: filter,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_PRODUCT_BY_ID = gql`
    query getProductByID($id: String!) {
        getProductByID(id: $id) {
            ... on Product {
                id
                name
                code
                minDeposit
                spreadGroup {
                    name
                }
                leverage
                productType
                networkType
                network {
                    username
                }
                exclude {
                    username
                }
                productGroup {
                    name
                }
                mamPammProvider {
                    name
                }
                interestRate
                interestRateType
                server {
                    name
                }
                showPublic
                isDeleted
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getProductById = (id) => {
    try {
        const {loading, error, data} = useQuery(GET_PRODUCT_BY_ID, {
            variables: {
                id: id,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_PRODUCT = gql`
    query getProduct(
        $filter: ProductSearchInput
        $limit: Int
        $offset: Int
        $orderBy: String
    ) {
        getProduct(
            filter: $filter
            limit: $limit
            offset: $offset
            orderBy: $orderBy
        ) {
            ... on ProductArray {
                data {
                    id
                    _id
                    name
                    code
                    minDeposit
                    spreadGroup {
                        _id
                        name
                        isDeleted
                    }
                    spreadGroupMt5{
                        _id
                        group
                    }
                    leverage
                    productType
                    mode
                    networkType
                    network {
                        username
                        _id
                    }
                    exclude {
                        username
                        _id
                    }
                    productGroup {
                        name
                        _id
                    }
                    mamPammProvider {
                        name
                    }
                    interestRate
                    interestRateType
                    server {
                        _id
                        name
                    }
                    showPublic
                    isDeleted
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getProduct = ({filter}, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data} = useQuery(GET_PRODUCT, {
            variables: {
                filter: filter,
                limit: limit ?? null,
                offset: offset ?? null,
                orderBy: orderBy ?? null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_PRODUCT = gql`
    mutation addProduct($input: ProductInput) {
        addProduct(input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on Product {
                id
                name
                code
                minDeposit
                spreadGroup {
                    name
                    id
                }
                leverage
                productType
                networkType
                network {
                    username
                    _id
                }
                exclude {
                    username
                    _id
                }
                productGroup {
                    name
                    _id
                }
                interestRate
                interestRateType
                server {
                    name
                }
                showPublic
                isDeleted
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useAddProductMutation = (): {
    addProduct: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [addProductMutation, {data, loading, error}] = useMutation(ADD_PRODUCT)

        const addProduct = (input) => {
            return addProductMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {addProduct}}) {
                    const typename = addProduct?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(addProduct?.errKey),
                            key: "addProduct",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(addProduct?.msg),
                            key: "addProduct",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Add Product Successfully"),
                            key: "addProduct",
                        });
                    }
                },
                refetchQueries: [GET_PRODUCT, GET_PRODUCT_COUNT]
            });
        };

        return {addProduct, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {addProduct: null, data: null, loading: false, error: err};
    }
};

const UPDATE_PRODUCT = gql`
    mutation updateProduct($id: String!, $input: ProductInput) {
        updateProduct(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on Product {
                id
                name
                code
                minDeposit
                spreadGroup {
                    name
                }
                spreadGroupMt5 {
                    group
                }
                leverage
                productType
                networkType
                network {
                    username
                    _id
                }
                exclude {
                    username
                    _id
                }
                productGroup {
                    name
                    _id
                }
                interestRate
                interestRateType
                server {
                    name
                }
                showPublic
                isDeleted
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;

const useUpdateProductMutation = (): {
    updateProduct: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [updateProductMutation, {data, loading, error}] =
            useMutation(UPDATE_PRODUCT);

        const updateProduct = (id, input) => {
            return updateProductMutation({
                variables: {
                    id: id,
                    input: input,
                },
                update(cache, {data: {updateProduct}}) {
                    const typename = updateProduct?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateProduct?.errKey),
                            key: "updateProduct",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateProduct?.msg),
                            key: "updateProduct",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Product Successfully"),
                            key: "updateProduct",
                        });
                    }
                },
            });
        };

        return {updateProduct, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {updateProduct: null, data: null, loading: false, error: err};
    }
};

const UPDATE_PRODUCT_AND_CHANGE_SPREAD_GROUP = gql`
    mutation updateProductAndChangeSpreadGroupForExistingUser($id: String!, $input: ProductInput, $newSpreadGroup: String!, $tradingAccount: [String]) {
        updateProductAndChangeSpreadGroupForExistingUser(id: $id, input: $input, newSpreadGroup: $newSpreadGroup, tradingAccount: $tradingAccount) {
            ... on CrmTaskApproval {
                msg
            }
            ... on Product {
                id
                name
                code
                minDeposit
                spreadGroup {
                    name
                }
                spreadGroupMt5 {
                    group
                }
                leverage
                productType
                networkType
                network {
                    username
                    _id
                }
                exclude {
                    username
                    _id
                }
                productGroup {
                    name
                    _id
                }
                interestRate
                interestRateType
                server {
                    name
                }
                showPublic
                isDeleted
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;

const useUpdateProductAndChangeSpreadGroupForExistingUserMutation = (): {
    updateProductAndChangeSpreadGroupForExistingUser: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [updateProductAndChangeSpreadGroupForExistingUserMutation, {data, loading, error}] =
            useMutation(UPDATE_PRODUCT_AND_CHANGE_SPREAD_GROUP);

        const updateProductAndChangeSpreadGroupForExistingUser = (id, input, newSpreadGroup, tradingAccount) => {
            return updateProductAndChangeSpreadGroupForExistingUserMutation({
                variables: {
                    id: id,
                    input: input,
                    newSpreadGroup: newSpreadGroup,
                    tradingAccount: tradingAccount
                },
                update(cache, {data: {updateProductAndChangeSpreadGroupForExistingUser}}) {
                    const typename = updateProductAndChangeSpreadGroupForExistingUser?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateProductAndChangeSpreadGroupForExistingUser?.errKey),
                            key: "updateProduct",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateProductAndChangeSpreadGroupForExistingUser?.msg),
                            key: "updateProduct",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Product Successfully"),
                            key: "updateProduct",
                        });
                    }
                },
            });
        };

        return {updateProductAndChangeSpreadGroupForExistingUser, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {updateProductAndChangeSpreadGroupForExistingUser: null, data: null, loading: false, error: err};
    }
};

const DELETE_PRODUCT = gql`
    mutation deleteProduct($id: String!) {
        deleteProduct(id: $id) {
            ... on CrmTaskApproval {
                msg
            }
            ... on Product {
                id
                isDeleted
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;

const useDeleteProductMutation = (): {
    deleteProduct: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [deleteProductMutation, {data, loading, error}] = useMutation(DELETE_PRODUCT);

        const deleteProduct = (id) => {
            return deleteProductMutation({
                variables: {
                    id: id,
                },
                update(cache, {data: {deleteProduct}}) {
                    const typename = deleteProduct?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(deleteProduct?.errKey),
                            key: "deleteProduct",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(deleteProduct?.msg),
                            key: "deleteProduct",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Delete Product Successfully"),
                            key: "deleteProduct",
                        });
                    }
                },
                refetchQueries: [GET_PRODUCT, GET_PRODUCT_COUNT]
            });
        };

        return {deleteProduct, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {deleteProduct: null, data: null, loading: false, error: err};
    }
};

const GET_AVAILABLE_PRODUCT_BY_USERID = gql`
    query getAvailableProductByUserId($input: ProductSearchInputByUserId) {
        getAvailableProductByUserId(input: $input){
            ...on ProductArray {
                data {
                    _id
                    name
                    productType
                    mode
                    mamPammProvider {
                        provider
                    }
                    server {
                        _id
                        name
                        platform
                    }
                }
            }
            ...on BaseError {
                errMsg
                errKey
            }
        }
    }
`;

const getAvailableProductByUserId = (userId, serverName) => {
    try {
        const {loading, error, data} = useQuery(GET_AVAILABLE_PRODUCT_BY_USERID, {
            variables: {
                input: {
                    userId: userId,
                    serverName: serverName,
                }
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_ALL_AVAILABLE_PRODUCT_BY_USERID = gql`
    query getAllAvailableProductByUserId($userid: String!, $productType: [String]) {
        getAllAvailableProductByUserId(userid: $userid, productType: $productType){
            ...on ProductArray {
                data {
                    _id
                    name
                    productType
                    mode
                    mamPammProvider {
                        provider
                    }
                    server {
                        _id
                        name
                        environment
                        platform
                    }
                }
            }
            ...on BaseError {
                errMsg
                errKey
            }
        }
    }
`;

const getAllAvailableProductByUserId = (userid, productType) => {
    try {
        const {loading, error, data} = useQuery(GET_ALL_AVAILABLE_PRODUCT_BY_USERID, {
            variables: {
                userid: userid,
                productType: productType
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const getAllAvailableProductByUserIdAsync = async (userid, productType) => {
    try {
        const execute = await ApolloClientQuery(
            GET_ALL_AVAILABLE_PRODUCT_BY_USERID,
            {
                userid: userid,
                productType: productType,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};


const GET_TRADING_ACCOUNT_PRODUCT_DROPDOWN = gql`
    query getTradingAccountProductDropdown($userid: String!, $tradingAccountId:String!, $productType: [String]) {
        getTradingAccountProductDropdown(userid: $userid, tradingAccountId: $tradingAccountId, productType: $productType){
            ...on ProductArray {
                data {
                    _id
                    name
                    server {
                        name
                        environment
                    }
                }
            }
            ...on BaseError {
                errMsg
                errKey
            }
        }
    }
`;

const getTradingAccountProductDropdown = (userid, tradingAccountId, productType) => {
    try {
        const {loading, error, data} = useQuery(GET_TRADING_ACCOUNT_PRODUCT_DROPDOWN, {
            variables: {
                userid: userid,
                tradingAccountId: tradingAccountId,
                productType: productType
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};


export {
    getProductCount,
    getProductById,
    getProduct,
    useAddProductMutation,
    useUpdateProductMutation,
    useDeleteProductMutation,
    getAvailableProductByUserId,
    getAllAvailableProductByUserId,
    getTradingAccountProductDropdown,
    useUpdateProductAndChangeSpreadGroupForExistingUserMutation,
    getAllAvailableProductByUserIdAsync,
    GET_PRODUCT,
    GET_PRODUCT_COUNT
};
