import React, {useState} from "react";
import {Upload} from "antd";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../recoil_state";
import UploadStatus from "./uploadStatus";
import {UploadOutlined} from "@ant-design/icons";
import {uploadMedia} from "../../../../../../api/restful/arena/media_file";

const OuterUploadDragger = ({children, currentView, currentPath, refetchMedia, media, folders}) => {
    const setNotificationMessage = useSetRecoilState(notificationMessage)
    const [dragOver, setDragOver] = useState(false)
    const [displayStatus, setDisplayStatus] = useState<boolean>(false)
    const [files, setFiles] = useState<any[]>([])
    const [completedFiles, setCompletedFiles] = useState<any[]>([])
    const [fileStatus, setFileStatus] = useState<string>("")
    const [lastUploadLength, setLastUploadLength] = useState<number>(0)

    const onDragOver = (event: React.DragEvent) => {
        event.preventDefault()
        event.stopPropagation()

        if (!dragOver) setDragOver(true)
    }

    const onDragLeave = (e) => {
        if (dragOver) setDragOver(false)
    }

    const handleDrop = (event: React.DragEvent) => {
        event.preventDefault()
        event.stopPropagation()
        setDisplayStatus(true)
        setDragOver(false)

        const files = Array.from(event.dataTransfer.files)
        setFiles(prev => [...prev, ...files])
        handleUploadMedia(files)
    }

    const handleUploadMedia = async (fileList) => {
        setFileStatus("uploading")
        const promises: any[] = []

        fileList?.forEach(file => {
            const formData = new FormData()
            formData.append("folder", currentPath.at(-1)?._id || "")
            formData.append("file", file)

            promises.push(uploadMedia(formData))
        })

        try {
            const response: any[] = await Promise.allSettled(promises)
            const successAry = response.filter(r => r.status === "fulfilled");
            const failedAry = response.filter(r => r.status === "rejected").map(r => {
                const validArray = JSON.parse(`[${r.reason?.response?.data.replace(/}{/g, "},{")}]`);
                return r.reason?.response?.data?.errObj || validArray?.[0] || "Unknown Error"
            })
            setLastUploadLength(successAry?.length + failedAry?.length)

            const mapSuccessArray = () => {
                const completed: any = []
                successAry?.forEach(r => {
                    const found = fileList.find(d => d?.name === r?.value?.data?.[0]?.displayName)
                    completed.push({...found, ...r?.value?.data?.[0]})
                })

                setCompletedFiles(prev => [...prev, ...completed])
                setFiles([])
            }

            if (successAry.length > 0 && failedAry.length === 0) {
                setFileStatus("success")
                mapSuccessArray()
                refetchMedia()
            } else if (successAry.length > 0) {
                setFileStatus("success_with_error")
                mapSuccessArray()
                refetchMedia()
            } else if (failedAry.length > 0) {
                setFileStatus("failed")
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: `Upload Media Failed: ${failedAry?.map(d => d?.errObj)?.join(",")}`,
                    key: "arenaMediaLibraryNotification",
                });
            }
        } catch (e) {
        }
    }

    return (
        <div
            onDragOver={onDragOver}
            onDrop={handleDrop}
            onDragLeave={onDragLeave}
        >
            <Upload.Dragger
                accept="image/png, image/jpeg, image/gif, image/webp, image/svg+xml"
                disabled={currentView !== "All Files"}
                className={`outer-dragger ${dragOver ? "drag-hovered" : ""}`}
                openFileDialogOnClick={false}
                beforeUpload={() => false}
                showUploadList={false}
            >
                {children}
            </Upload.Dragger>

            <UploadStatus
                uploadList={files}
                display={displayStatus}
                close={() => {
                    setDisplayStatus(false)
                    setFiles([])
                    setCompletedFiles([])
                    setLastUploadLength(0)
                }}
                media={media}
                fileStatus={fileStatus}
                folders={folders}
                completedFiles={completedFiles}
                length={lastUploadLength}
            />

            <div className={`drop-notification ${!dragOver ? "hidden" : ""}`}>
                <p><UploadOutlined/> &nbsp; Drop your files to upload.</p>
            </div>
        </div>
    )
}

export default OuterUploadDragger