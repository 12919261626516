import axios from "axios";
import {getConfigByName} from "../../../../config";
import {BRAND_NAME} from "../../../../config/brandVariable";

const url = getConfigByName(BRAND_NAME).arenaURL
const config = {headers: {"Content-Type": "text/plain", "authorization": `Bearer ${localStorage.getItem("arenaToken")}`}}

export const getBoxSetting = async () => {
    try {
        return await axios.post(`${url}/api/box/setting/get`, {filter: {}}, config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export const createBoxSetting = async (input) => {
    try {
        return await axios.post(`${url}/api/box/setting/create`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export const updateBoxSetting = async (input) => {
    try {
        return await axios.post(`${url}/api/box/setting/update`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export const deleteBoxSetting = async (input) => {
    try {
        return await axios.post(`${url}/api/box/setting/delete`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}