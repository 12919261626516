import React from "react";
import {useTranslation} from "react-i18next";

export const Columns = () => {
    const {t} = useTranslation();

    return [
        {
            title: t("Name"),
            dataIndex: "name",
            key: "name",
            render: text => <p>{text || "-"}</p>,
        },
        {
            title: t("Method"),
            dataIndex: "method",
            key: "method",
            render: text => <p>{text || "-"}</p>,
        },
        {
            title: t("URL"),
            dataIndex: "url",
            key: "url",
            render: text => <p>{text || "-"}</p>,
        },
    ]
}