import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {SaveOutlined} from "@ant-design/icons";
import {Button, Checkbox, Col, Form, Row, Select, Steps} from "antd";

import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import yupFormSchema from "../../../../../../components/yup/yupFormSchema";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import SelectFormItem from "../../../../../../components/form/selectFormItem";
import NetworkListByRoleTemplateFormItem from "../../../../../../components/form/networkListByRoleTemplateFormItem";
import {verticalFormLayout} from "../../../../../../components/form/formWrapper";

import RoleSelectFormItem from "../../../../../../components/form/roleSelectFormItem";
import {useAddRoleTemplateMutation, useUpdateRoleTemplateMutation} from "../../../../../../api/graphql/roleTemplate";
import PermissionFormItem from "./permissionFormItem";
import ExcludeListByRoleTemplateFormItem from "../../../../../../components/form/excludeListByNetworkListFormItem";
import {combineObjects, convertArrayToObjectValue, flattenPermissionObject, mapNetworkToDropdown, mapNetworkToString} from "../../../function/common";
import useAuthorize from "../../../../../../_common/authorize";
import {getRoleNetworkTree} from "../../../../../../api/graphql/roleNetworkTree";

const yupSchema = yup.object().shape({
    parent: yupFormSchema.string("parent", {required: true}),
    name: yupFormSchema.string("name", {required: true}),
    description: yupFormSchema.string("description"),
    isActive: yupFormSchema.boolean("isActive"),
    networkType: yupFormSchema.string("networkType", {required: true}),
    network: yupFormSchema.array("network"),
    exclude: yupFormSchema.array("exclude"),
    permission: yupFormSchema.array("permission", {required: true}),
});

const RoleTemplateForm = (props) => {
    const {
        mode,
        item,
        closeModal,
        parentPermissionArrayData,
        targetParent,
        setTargetParent,
        roleTemplateList,
        networkTreeDownlineData,
        setNetworkList,
        editType,
        setEditType,
    } = props;
    const {t} = useTranslation();
    const [errMsg, setErrMsg] = useState("");

    const filterWithDownline = () => {
        let filterList = [...applyToDownlineList];
        if (item?.id) {
            filterList.push(item.id);
        }
        let filteredItems = roleNetworkTree?.getRoleNetworkTree?.data?.filter((item) => filterList?.includes(item?.parent?._id));
        let filteredItemId = filteredItems?.map((item) => item?.roleTemplateId?._id);

        let filteredResult = roleTemplateList?.filter((item) => filteredItemId?.includes(item.id));
        let result = filteredResult?.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });
        setApplyToDownlineOptions(result);
    };

    const [currentStep, setCurrentStep] = useState(0);
    const [checkedPermissionKeys, setCheckedPermissionKeys] = useState({});
    const [removeChildPermission, setRemoveChildPermission] = useState(false);
    const [removeChildPermissionShown, setRemoveChildPermissionShown] = useState(false);
    const [childPermissionConflictObj, setChildPermissionConflictObj] = useState({});
    const [applyToDownline, setApplyToDownline] = useState(false);
    const [applyToDownlineType, setApplyToDownlineType] = useState("all");
    const [applyToDownlineList, setApplyToDownlineList] = useState<any>([]);
    const [applyToDownlineOptions, setApplyToDownlineOptions] = useState<any>([]);

    const {data: roleNetworkTree} = getRoleNetworkTree();

    useEffect(() => {
        filterWithDownline();
    }, [applyToDownlineList]);

    const [initialValues] = useState(() => ({
        parent: item?.parent?._id || "",
        name: item?.name || "",
        description: item?.description || "",
        isActive: item?.isActive ? true : false,
        networkType: item?.networkType || "",
        network: mapNetworkToDropdown(item?.network) || [],
        exclude: mapNetworkToDropdown(item?.exclude) || [],
        permission: item?.permission || "",
    }));

    const {addRoleTemplate, loading: addRoleTemplateLoading} = useAddRoleTemplateMutation();
    const {updateRoleTemplate, loading: updateRoleTemplateLoading} = useUpdateRoleTemplateMutation();
    const {systemErrorNotification} = useAuthorize();

    var form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
        defaultValues: initialValues,
    });

    const next = () => {
        setCurrentStep(currentStep + 1);
    };

    const prev = () => {
        setCurrentStep(currentStep - 1);
    };

    const onCancel = () => {
        closeModal();
    };

    const onSubmit = async (values) => {
        try {
            setErrMsg("");
            var result = {
                ...values,
                network: mapNetworkToString(values?.network),
                exclude: mapNetworkToString(values?.exclude),
                permission: JSON.stringify(checkedPermissionKeys),
                removeChildPermission: removeChildPermission,
            };
            if (applyToDownline) {
                result = {
                    ...result,
                    applyToDownlineType: applyToDownlineType,
                    applyToDownline: applyToDownlineList,
                };
            }
            const res = mode === "add" ? await addRoleTemplate(result) : await updateRoleTemplate(item.id, result);
            const responseData = mode === "add" ? res?.data?.addRoleTemplate : res?.data?.updateRoleTemplate;
            if (responseData?.__typename === "BaseError") {
                if (responseData?.errKey === "admin.roleTemplate.err.child_has_more_permission_than_parent") {
                    let conflictObj: any = convertArrayToObjectValue(responseData?.errObj, true);
                    setChildPermissionConflictObj(conflictObj);
                    setRemoveChildPermissionShown(true);
                }
                let errMsg = t(responseData?.errKey);
                setErrMsg(errMsg);
            } else {
                closeModal();
            }
        } catch (e: any) {
            console.log(e);
            systemErrorNotification();
        }
    };

    const handleParentSelection = (e) => {
        try {
            setTargetParent(e);
            setEditType("both");
            form?.setValue("network", []);
            form?.setValue("exclude", []);
        } catch (error) {}
    };

    const handleNetworkChange = (e) => {
        setNetworkList(e);
    };

    const handleApplyDownlineChange = (e) => {
        let parentNetwork = roleNetworkTree?.getRoleNetworkTree?.data
            ?.filter((x) => x?.parent?._id === item?.id)
            .map((item) => item?.roleTemplateId?._id);
        let levelList: any = parentNetwork;
        let finalValue: any = [];
        while (levelList && levelList.length > 0) {
            let level = e.filter((item) => levelList.includes(item));
            if (level && level.length > 0) {
                let newLevelList = roleNetworkTree?.getRoleNetworkTree?.data
                    ?.filter((x) => level.includes(x?.parent?._id))
                    .map((item) => item?.roleTemplateId?._id);
                if (newLevelList && newLevelList.length > 0) {
                    levelList = newLevelList;
                } else {
                    levelList = [];
                }
                finalValue.push(...level);
                setApplyToDownlineList(finalValue);
            } else {
                levelList = [];
                setApplyToDownlineList(finalValue);
            }
        }
    };

    const handleApplyToDownlineCheckChange = () => {
        if (applyToDownline) {
            setApplyToDownlineType("");
            setApplyToDownlineList([]);
        }
        setApplyToDownline(!applyToDownline);
    };

    useEffect(() => {
        if (parentPermissionArrayData?.length > 0) {
            let permissionObj = convertArrayToObjectValue(parentPermissionArrayData, false);
            let amendedPermissionObj = {...permissionObj};
            if (item?.permission) {
                let itemPermissionList = flattenPermissionObject(item?.permission);
                let itemPermissionObj = convertArrayToObjectValue(itemPermissionList, true);
                amendedPermissionObj = combineObjects(permissionObj, itemPermissionObj);
            }
            setCheckedPermissionKeys(amendedPermissionObj);
        } else {
            setCheckedPermissionKeys({});
        }
    }, [item?.permission, parentPermissionArrayData]);

    const steps = [
        {
            title: t("common.basic"),
            content: "Basic-content",
        },
        {
            title: t("common.permission"),
            content: "Permission-content",
        },
    ];

    const items = steps.map((item) => ({key: item.title, title: item.title}));

    let formValues = form.getValues();
    const isNextBtnEnabled = formValues?.parent?.length > 0 || item?.isMasterAdmin;

    const targetParentNetworkType = roleTemplateList?.find((v) => v?.id === targetParent)?.networkType;

    const networkTypeOptions =
        (!item?.parent && !targetParent) || item?.parent?.networkType === "all" || targetParentNetworkType === "all"
            ? [
                  {
                      label: t("network.all"),
                      value: "all",
                  },
                  {
                      label: t("network.partial"),
                      value: "partial",
                  },
                  {
                      label: t("network.own"),
                      value: "own",
                  },
              ]
            : [
                  {
                      label: t("network.partial"),
                      value: "partial",
                  },
                  {
                      label: t("network.own"),
                      value: "own",
                  },
              ];

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="form-1">
                {errMsg && <p style={{color: "red", marginBottom: 0}}>{errMsg}</p>}
                {removeChildPermissionShown && (
                    <Row justify="end" className="margin-top-0-5">
                        <Checkbox
                            checked={removeChildPermission}
                            className="permissionCheckbox subModule"
                            onChange={(e) => setRemoveChildPermission(e?.target?.checked)}
                            disabled={mode === "view"}
                        >
                            <p style={{color: "red", marginBottom: 0}}>{t("Confirm to remove child permissions")}</p>
                        </Checkbox>
                    </Row>
                )}
                {editType === "both" && (
                    <Row style={{width: "75%", margin: "auto"}}>
                        <Steps current={currentStep} items={items} />
                    </Row>
                )}

                {(editType === "general" || (editType === "both" && currentStep === 0)) && (
                    <>
                        <Row gutter={10}>
                            <Col xs={12}>
                                <RoleSelectFormItem
                                    name={"parent"}
                                    label={t("common.parent")}
                                    onChange={handleParentSelection}
                                    layout={verticalFormLayout}
                                    currentRole={item?.id || null}
                                    disabled={mode === "view" || item?.isMasterAdmin}
                                    showSearch
                                    required
                                />
                            </Col>
                            <Col xs={12}>
                                <SelectFormItem
                                    name={"isActive"}
                                    label={t("common.status")}
                                    placeholder={t("common.status")}
                                    options={[
                                        {
                                            label: t("common.active"),
                                            value: true,
                                        },
                                        {
                                            label: t("common.inactive"),
                                            value: false,
                                        },
                                    ]}
                                    layout={verticalFormLayout}
                                    disabled={mode === "view" || item?.isDefaultCrmUserPermission || item?.isMasterAdmin}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            <Col xs={12}>
                                <InputFormItem
                                    name={"name"}
                                    label={t("roleSetting.name")}
                                    placeholder={t("roleSetting.name")}
                                    layout={verticalFormLayout}
                                    disabled={mode === "view"}
                                    required
                                />
                            </Col>
                            <Col xs={12}>
                                <InputFormItem
                                    name={"description"}
                                    label={t("roleSetting.description")}
                                    placeholder={t("roleSetting.description")}
                                    layout={verticalFormLayout}
                                    disabled={mode === "view"}
                                />
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            <Col xs={12}>
                                <SelectFormItem
                                    name={"networkType"}
                                    label={t("network.networkType")}
                                    placeholder={t("network.networkType")}
                                    options={networkTypeOptions}
                                    layout={verticalFormLayout}
                                    disabled={mode === "view" || item?.isMasterAdmin}
                                    required
                                />
                            </Col>

                            {form?.getValues()?.networkType === "partial" && (
                                <Col xs={12}>
                                    <NetworkListByRoleTemplateFormItem
                                        name={"network"}
                                        label={t("network.networkInclude")}
                                        mode="multiple"
                                        roleID={form?.getValues()?.parent}
                                        onChange={handleNetworkChange}
                                        layout={verticalFormLayout}
                                        disabled={mode === "view"}
                                    />
                                </Col>
                            )}

                            {form?.getValues()?.networkType === "partial" && (
                                <Col xs={12}>
                                    <ExcludeListByRoleTemplateFormItem
                                        name={"exclude"}
                                        label={t("network.networkExclude")}
                                        mode="multiple"
                                        networkTreeDownlineData={networkTreeDownlineData}
                                        layout={verticalFormLayout}
                                        disabled={mode === "view"}
                                    />
                                </Col>
                            )}
                        </Row>
                    </>
                )}

                {(editType === "permission" || (editType === "both" && currentStep === 1)) && (
                    <Row>
                        <Col span={24}>
                            <PermissionFormItem
                                mode={mode}
                                checkedPermissionKeys={checkedPermissionKeys}
                                setCheckedPermissionKeys={setCheckedPermissionKeys}
                                parentPermissionArrayData={parentPermissionArrayData}
                                childPermissionConflictObj={childPermissionConflictObj}
                            />
                        </Col>
                    </Row>
                )}

                {mode === "edit" && (editType === "permission" || currentStep === steps?.length - 1) && (
                    <Row style={{marginTop: "4px"}}>
                        <Col span={4}>
                            <Checkbox checked={applyToDownline} onChange={() => handleApplyToDownlineCheckChange()} style={{marginTop: "3px"}}>
                                {t("roleSetting.applyToDownline")}
                            </Checkbox>
                        </Col>
                        {applyToDownline && (
                            <Col span={2}>
                                <Select
                                    options={[
                                        {value: "all", label: "All"},
                                        {value: "partial", label: "Partial"},
                                    ]}
                                    value={applyToDownlineType}
                                    onChange={(e) => setApplyToDownlineType(e)}
                                    style={{width: "100%"}}
                                />
                            </Col>
                        )}
                        {applyToDownline && applyToDownlineType === "partial" && (
                            <Col span={5}>
                                <Select
                                    mode={"multiple"}
                                    options={applyToDownlineOptions}
                                    value={applyToDownlineList}
                                    filterOption={(input, option: any) => option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                                    onChange={handleApplyDownlineChange}
                                    style={{width: "100%"}}
                                />
                            </Col>
                        )}
                    </Row>
                )}

                <Form.Item className="form-button-1">
                    <>
                        <Button onClick={onCancel} className="cancelBtn" type="default">
                            {t("common.cancel")}
                        </Button>
                        {editType === "both" && (
                            <>
                                {currentStep > 0 && (
                                    <Button type="primary" className="previousBtn" onClick={() => prev()}>
                                        {t("common.previous")}
                                    </Button>
                                )}
                                {currentStep < steps?.length - 1 && (
                                    <Button type="primary" className="nextBtn" disabled={!isNextBtnEnabled} onClick={() => next()}>
                                        {t("common.next")}
                                    </Button>
                                )}
                            </>
                        )}
                        {mode !== "view" && (editType === "general" || editType === "permission" || currentStep === steps?.length - 1) && (
                            <Button
                                type="primary"
                                onClick={() => {
                                    onSubmit(form?.getValues());
                                }}
                                icon={<SaveOutlined />}
                                className="button-submit-1"
                                loading={addRoleTemplateLoading || updateRoleTemplateLoading}
                                disabled={addRoleTemplateLoading || updateRoleTemplateLoading}
                            >
                                {t("common.submit")}
                            </Button>
                        )}
                    </>
                </Form.Item>
            </form>
        </FormProvider>
    );
};

export default RoleTemplateForm;
