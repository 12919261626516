export const addCommand = (editor: Record<string, any>) => {
    var pfx = editor.getConfig().stylePrefix;
    var modal = editor.Modal;
    var cmdm = editor.Commands;
    var codeViewer = editor.CodeManager.getViewer("CodeMirror").clone();
    var container = document.createElement("div");
    var btnEdit = document.createElement("button");

    codeViewer.set({
        codeName: "htmlmixed",
        readOnly: 0,
        theme: "hopscotch",
        autoBeautify: true,
        autoCloseTags: true,
        autoCloseBrackets: true,
        lineWrapping: true,
        styleActiveLine: true,
        smartIndent: true,
        indentWithTabs: true,
    });

    btnEdit.innerHTML = "Save";
    btnEdit.className = pfx + "btn-prim " + pfx + "btn-import";
    btnEdit.onclick = function () {
        var code = codeViewer.editor.getValue();
        // editor?.DomComponents?.getWrapper()?.set("content", "");
        editor.setComponents(code.trim());
        modal.close();
    };

    cmdm.add('open-assets', () => console.log('do nothing'));

    cmdm.add("html-edit", {
        run: function (editor, sender) {
            sender && sender.set("active", 0);
            var viewer = codeViewer.editor;
            modal.setTitle("Edit code");
            if (!viewer) {
                var txtarea = document.createElement("textarea");
                container.appendChild(txtarea);
                container.appendChild(btnEdit);
                codeViewer.init(txtarea);
                viewer = codeViewer.editor;
            }
            // var InnerHtml = editor.getHtml();
            var InnerHtml = editor?.runCommand("gjs-get-inlined-html");
            // var Css = editor.getCss();
            modal.setContent("");
            modal.setContent(container);
            // codeViewer.setContent(InnerHtml + "<style>" + Css + "</style>");
            codeViewer.setContent(InnerHtml);
            modal.open();
            viewer.refresh();
        },
    });
};
