import React from "react";
import {Col, Row, Spin} from "antd";
import {useTranslation} from "react-i18next";
import {getUserById} from "../../../../../../../api/graphql/user";
import TaskClientDetails from "../../clientDetails";
import {getTradingAccountDetail, getTradingAccountTransaction} from "../../../../../../../api/graphql/tradingAccount";
import {formatMoney} from "../../../../../../accounts/tradingAccount/detail/function";
import AccountDetailCard from "../../../../../../accounts/tradingAccount/detail/view/layout1/component/accountDetailCard";
import {formatDateTimeTimezone} from "../../../../../../../function/_common";

function TradingAccountLayout_1(props) {
    const {data} = props

    const {t} = useTranslation()

    const transactionDetailGraphQL = getTradingAccountTransaction({
        filter: {
            _id: data?.payload?.id
        }
    })

    const transactionDetail = transactionDetailGraphQL?.data?.getTradingAccountTransaction?.data[0] || {}

    const tradingAccountDetail = getTradingAccountDetail(transactionDetail?.account?._id)

    const userDetail = getUserById(transactionDetail?.account?.user?._id)

    const clarifyTransactionType = (type, deposit) => {
        let keyword
        if (deposit) {
            switch (type) {
                case "manual":
                    keyword = "Manual Deposit"
                    break;
                case "negativeBalanceProtection":
                    keyword = "Negative Balance Protection"
                    break;
                case "ewallet":
                    keyword = "Deposit via E-Wallet"
                    break;
            }
        } else {
            switch (type) {
                case "manual":
                    keyword = "Manual Withdrawal"
                    break;
                case "ewallet":
                    keyword = "Withdrawal to E-Wallet"
                    break;
            }
        }

        return keyword

    }

    return (
        <div id={"tradingAccountLayout"}>
            <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                    <Spin spinning={!tradingAccountDetail?.data?.getTradingAccountDetail?._id}>
                        <AccountDetailCard data={tradingAccountDetail?.data?.getTradingAccountDetail}
                                           traderId={tradingAccountDetail?.data?.getTradingAccountDetail?.traderId}
                                           viewOnly={true}/>
                    </Spin>
                    <br/>
                    {
                        userDetail?.data?.getUserByID && (
                            <TaskClientDetails data={userDetail?.data?.getUserByID}/>
                        )
                    }
                </Col>

                <Col xs={24} md={12}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <h3>{t("task.account_withdrawal_details")}</h3>
                        </Col>
                        <Col span={6}>
                            {t("task.account_id")}:
                        </Col>
                        <Col span={18}>
                            {transactionDetail?.account?.accountId}
                        </Col>
                        <Col span={6}>
                            {t("task.account_user")}:
                        </Col>
                        <Col span={18}>
                            {userDetail?.data?.getUserByID?.username}
                        </Col>
                        <Col span={6}>
                            {t("task.amount")}:
                        </Col>
                        <Col span={18}>
                            {formatMoney(transactionDetail?.amount)}
                        </Col>
                        <Col span={6}>
                            {t("task.type")}:
                        </Col>
                        <Col span={18}>
                            {transactionDetail?.type}
                        </Col>
                        <Col span={6}>
                            {transactionDetail?.type === "withdrawal" ? `${t("task.withdrawal_type")}:` : `${t("task.deposit_type")}:`}
                        </Col>
                        <Col span={18}>
                            {transactionDetail?.type === "withdrawal" ? clarifyTransactionType(transactionDetail?.withdrawalType, false) : clarifyTransactionType(transactionDetail?.depositType, true)}
                        </Col>
                        <Col span={6}>
                            {t("task.comment")}:
                        </Col>
                        <Col span={18}>
                            {transactionDetail?.comment}
                        </Col>
                        <Col span={6}>
                            {t("task.created_time")}:
                        </Col>
                        <Col span={18}>
                            {formatDateTimeTimezone(transactionDetail?.createdAt)}
                        </Col>
                        <Col span={6}>
                            {t("task.requested_by")}:
                        </Col>
                        <Col span={18}>
                            {data?.requestedBy?.username}
                        </Col>
                    </Row>
                </Col>

            </Row>


        </div>
    )

}

export default TradingAccountLayout_1