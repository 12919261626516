import React, {useEffect, useState} from "react";
import {Button, Card, Col, Row, Space, Tabs, TabsProps} from "antd";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {useAddFollowUp} from "../../../../../../../api/graphql/followUp";
import TextAreaInputFormItem from "../../../../../../../components/form/textAreaInputFormItem";
import {verticalFormLayout} from "../../../../../../../components/form/formWrapper";
import DatePickerInputForm from "../../../../../../../components/form/datePickerFormItem";
import SelectFormItem from "../../../../../../../components/form/selectFormItem";
import {getAvailableEmailTemplate} from "../../../../../../../api/graphql/emailTemplate";
import useAuthorize from "../../../../../../../_common/authorize";
import {disableDateTime} from "../../../../../_common/function";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import yupFormSchema from "../../../../../../../components/yup/yupFormSchema";
import {formatDateTimeTimezone} from "../../../../../../../function/_common";

const ActionCard = (props) => {
    const {data, refetchFollowUp} = props
    const {t} = useTranslation()

    const {addFollowUp} = useAddFollowUp()
    const {systemErrorNotification} = useAuthorize()
    const [currentTab, setCurrentTab] = useState<any>("followUp");
    const [schema, setSchema] = useState<any>(null)
    const {disabledDate} = disableDateTime()
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const labelCol = {span: 24, offset: 0}
    const {data: emailTemplate} = getAvailableEmailTemplate({
        isEmailVerified: data?.verifiedEmail,
    });
    const emailTemplateOption =
        emailTemplate?.getAvailableEmailTemplate?.data?.map((d) => {
            return {label: d?.displayName, value: d?._id};
        });

    const form = useForm({
        resolver: yupResolver(schema),
        mode: "all",
    });

    const onSubmit = async (value) => {
        try {

            if (currentTab === "followUp" && !value?.description) {
                setNotificationMessage({
                    status: "error",
                    title: "Error",
                    msg: "Description required!",
                    key: "systemNotification",
                });
                return false
            } else if (currentTab === "email" && !value?.emailTemplateId) {
                setNotificationMessage({
                    status: "error",
                    title: "Error",
                    msg: "Email template required!",
                    key: "systemNotification",
                });
                return false
            }

            const {addFollowUp:response} = await addFollowUp(
                localStorage?.getItem("userId"),
                {
                    ...value,
                    followUpDateTime: value?.followUpDateTime ? formatDateTimeTimezone(value?.followUpDateTime, "formSubmit",  "YYYY-MM-DD HH:mm") : null,
                    followingUser: data?._id,
                    type: currentTab,
                    followingType: "users"
                }
            )

            if (response?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(addFollowUp?.errKey),
                    key: "addFollowUp",
                });
            } else if (response?.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(addFollowUp?.msg),
                    key: "addFollowUp",
                });
            } else {
                refetchFollowUp()

                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Create Follow Up Successfully"),
                    key: "addFollowUp",
                });
            }

            form.reset()
        } catch (e) {
            console.log(e)
            systemErrorNotification()
        }
    }



    const items: TabsProps["items"] = [
        {
            label: t("user.followUp"),
            key: "followUp",
            children: (
                <FormProvider {...form}>
                    <form className="form-1">
                        <Row
                            gutter={[16, 16]}
                            className={"margin-top-0-75 margin-bottom-0-75"}
                        >
                            <Col span={24}>
                                <Space direction={"vertical"} style={{width: "100%"}}>
                                    <TextAreaInputFormItem
                                        name={"description"}
                                        labelCol={labelCol}
                                        label={t("user.followUpDescription")}
                                        placeholder={t("user.followUpDescription")}
                                        layout={verticalFormLayout}
                                        rows={3}
                                        required
                                    />
                                    <DatePickerInputForm
                                        name={"followUpDateTime"}
                                        disabledDate={disabledDate}
                                        labelCol={labelCol}
                                        label={t("user.setUpFollowUpTime")}
                                        layout={verticalFormLayout}
                                        format={"YYYY-MM-DD HH:mm"}
                                        showTime={{format: "HH:mm"}}
                                        getPopupContainer={triggerNode => triggerNode.parentElement}
                                    />
                                </Space>
                            </Col>
                            <Button
                                className={"margin-left-auto"}
                                onClick={form.handleSubmit(onSubmit)}
                            >
                                {t("common.submit")}
                            </Button>
                        </Row>
                    </form>
                </FormProvider>
            ),
        },
        {
            label: t("user.email"),
            key: "email",
            children: (
                <FormProvider {...form}>
                    <form className="form-1">
                        <Row
                            gutter={[16, 16]}
                            className={"margin-top-0-75 margin-bottom-0-75"}
                        >
                            <Col span={24}>
                                <Space direction={"vertical"} style={{width: "100%"}}>
                                    <SelectFormItem
                                        name={"emailTemplateId"}
                                        label={t("common.emailTemplate")}
                                        labelCol={labelCol}
                                        placeholder={t("common.emailTemplate")}
                                        layout={verticalFormLayout}
                                        options={emailTemplateOption}
                                        optionFilterProp={"label"}
                                        allowClear showSearch
                                        required
                                    />
                                    <DatePickerInputForm
                                        name={"followUpDateTime"}
                                        label={t("user.setUpFollowUpTime")}
                                        labelCol={labelCol}
                                        disabledDate={disabledDate}
                                        layout={verticalFormLayout}
                                        format={"YYYY-MM-DD HH:mm"}
                                        showTime={{format: "HH:mm"}}
                                        getPopupContainer={triggerNode => triggerNode.parentElement}
                                    />
                                </Space>
                            </Col>
                            <Button
                                className={"margin-left-auto"}
                                onClick={form.handleSubmit(onSubmit)}
                            >
                                {t("common.send")}
                            </Button>
                        </Row>
                    </form>
                </FormProvider>
            ),
        },
    ];

    const settingSchema = () => {
        if (currentTab === "email") {
            setSchema(yup.object().shape({
                emailTemplateId: yupFormSchema.string("emailTemplateId", {required: true}),
                followUpDateTime: yupFormSchema.string("followUpDateTime"),
            }))
        } else {
            setSchema(yup.object().shape({
                description: yupFormSchema.string("description", {required: true}),
                followUpDateTime: yupFormSchema.string("followUpDateTime"),
            }))
        }
    }

    useEffect(() => {
        settingSchema()
    },[currentTab])


    return (
        <Card style={{width: "100%"}}>
            <h3>{t("common.actionToTake")}</h3>
            <Tabs
                size={"small"}
                items={items}
                onTabClick={(tab) => {
                    setCurrentTab(tab);
                    form.reset();
                }}
            />
        </Card>
    )
}

export default ActionCard