import React, {useEffect, useState} from "react";
import {Button, Card, Col, Row, Space, Spin, Tag, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import useAuthorize from "../../../../../../../_common/authorize";
import {DeleteOutlined, EditOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {formatMoney} from "../../../function";
import ParameterEditDetailsModal from "./parameterEditDetailsModal";
import ParameterRankCriteriaModal from "./parameterRankCriteriaModal";
import {deleteCommissionParameter, getCommissionParameterSetting} from "../../../../../../../api/graphql/commission";
import ParameterEditModal from "./parameterEditModal";
import Paragraph from "antd/es/typography/Paragraph";


const ParameterCard = (props) => {

    const {
        rank,
        data,
        userData,
        getParameter,
        ruleData,
        conditions
    } = props;
    const {t} = useTranslation()

    const [openOverriding, setOpenOverriding] = useState<any>(false)
    const [openExcess, setOpenExcess] = useState<any>(false)
    const [openCashback, setOpenCashback] = useState<any>(false)
    const [openRankCriteria, setOpenRankCriteria] = useState<any>(false)
    const [rankCriteria, setRankCriteria] = useState<any>({})
    const [rankAbove, setRankAbove] = useState<any>([])
    const [rankCriteriaDetails, setRankCriteriaDetails] = useState<any>([])
    const [rankCriteriaTotal, setRankCriteriaTotal] = useState<any>(0)
    const [overridingTotal, setOverridingTotal] = useState<any>(0)

    const [levelCriteriaDetails, setLevelCriteriaDetails] = useState<any>([])
    const [levelCriteriaTotal, setLevelCriteriaTotal] = useState<any>(0)

    const [loading, setLoading] = useState<any>(false)
    const [editModal, setEditModal] = useState(false)

    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {systemErrorNotification} = useAuthorize();

    const SymbolName = () => {
        let symbolName;

        if (data?.isAllSymbol === true) {
            symbolName = <Tag color="default">{"All Symbols"}</Tag>
        } else {
            if (data?.symbolType === "group") {

                symbolName = <>
                    {[...data?.assetClass, ...data?.assetClassMt5]?.map((group) => {
                        return (
                            <Tag color="geekblue"
                                 style={{marginBottom: "3px", maxWidth: "100%", whiteSpace: "break-spaces"}}>{group?.server?.name + " - " + group?.name}</Tag>
                        )
                    })}

                </>
            } else {
                symbolName = <>
                    {[...data?.symbol, ...data?.symbolMt5]?.map((symbol) => {

                        return (
                            <Tag color="blue"
                                 style={{marginBottom: "3px", maxWidth: "100%", whiteSpace: "break-spaces"}}>{symbol?.server?.name + " - " + (symbol?.name || symbol?.symbol)}</Tag>
                        )
                    })}

                </>
            }
        }

        return symbolName

    }

    const preload = async () => {
        await setRankCriteriaDetails([])
        await setRankCriteriaTotal(0)

        try {

            const response = await getCommissionParameterSetting({
                parameter: data?._id,
                commType: ruleData?.commType
            })

            let cashback = data?.cashbackClient || 0
            let overriding = 0;
            if (Array.isArray(data?.overriding) && data?.overriding?.length > 0) {

                await Promise.all(data?.overriding?.map((item) => {
                    overriding += item?.amount
                }))
            }

            let totalAmount = cashback + overriding

            if (ruleData?.commType === "rank") {
                if (response?.getCommissionParameterSetting?.data?.length > 0) {
                    const formattedRankData: any = []

                    for (const item of response?.getCommissionParameterSetting?.data) {
                        const foundRank = rank?.find((r) => r?.value === item?.rank?._id)

                        if (foundRank) {
                            formattedRankData.push({
                                rank: item?.rank,
                                total: item?.total || 0
                            })
                            totalAmount += item?.total
                        }
                    }

                    setRankCriteriaDetails(formattedRankData)
                }
                setRankCriteriaTotal(totalAmount)

            } else {

                const formattedRankData: any = []

                for (let i = 0; i < ruleData?.commLevel; i++) {

                    const level = i + 1
                    const settings = response?.getCommissionParameterSetting?.data

                    const itemFound = settings?.find((item) => item?.level === level);

                    if (itemFound) {
                        formattedRankData.push({
                            level: level,
                            total: itemFound?.total || 0
                        })

                        totalAmount += itemFound?.total
                    } else {
                        formattedRankData.push({
                            level: level,
                            total: 0
                        })
                    }
                }
                setLevelCriteriaDetails(formattedRankData)
                setLevelCriteriaTotal(totalAmount)
            }

            setOverridingTotal(overriding)

        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const deleteParameter = async () => {
        try {
            const response = await deleteCommissionParameter(data?._id)

            if (response?.deleteCommissionParameter?.__typename === "CommissionParameter") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Added Successfully"),
                    key: "CommissionParameter",
                });
            } else if (response?.deleteCommissionParameter.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.deleteCommissionParameter?.errKey),
                    key: "CommissionParameter",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.deleteCommissionParameter?.msg),
                    key: "CommissionParameter",
                });
            }
        } catch (e) {
            systemErrorNotification()
        } finally {
            getParameter()
        }
    }

    useEffect(() => {
        const functional = async () => {
            if (data) {
                await setLoading(true)
                preload()
            } else {
                await setLoading(true)
                await setRankCriteriaDetails([])
                await setRankCriteriaTotal(0)
            }
        }
        functional()
    }, [data?._id, ruleData])


    return (
        <>
            <Spin spinning={loading}>
                <Card style={{background: "#fafafa"}} bordered={false}>
                    <Row align="middle" gutter={16}>
                        <Col span={12}>
                            <Row align="middle" gutter={20}>
                                <Col span={10} style={{marginBottom: "15px"}}>
                                    <SymbolName/>
                                </Col>
                                <Col span={2}>
                                    <Space>
                                        <Button type={"link"} style={{padding: "unset", color: "red"}}
                                                onClick={deleteParameter}>
                                            <DeleteOutlined/>
                                        </Button>
                                        <Button type={"link"} style={{padding: "unset"}}
                                                onClick={() => setEditModal(true)}>
                                            <EditOutlined/>
                                        </Button>
                                    </Space>
                                </Col>

                                <Col span={3} offset={1} style={{textAlign: "center", marginBottom: "15px"}}>
                                    <h5>Cashback</h5>
                                    <Button type={"link"} onClick={() => setOpenCashback(true)}>
                                        {
                                            (data?.cashbackClient) ? formatMoney(data?.cashbackClient, ruleData?.commMode) :
                                                <EditOutlined/>
                                        }
                                    </Button>
                                </Col>
                                <Col span={3} style={{textAlign: "center", marginBottom: "15px"}}>
                                    <h5>Overriding</h5>
                                    <Button type={"link"} onClick={() => setOpenOverriding(true)}>

                                        {
                                            (data?.overriding?.length > 0 && overridingTotal > 0) ? formatMoney(overridingTotal, ruleData?.commMode) :
                                                <EditOutlined/>
                                        }

                                    </Button>
                                </Col>
                                {
                                    ruleData?.unpaidComm === "compress" ? (
                                        <Col span={3} style={{textAlign: "center", marginBottom: "15px"}}>
                                            <h5>Excess</h5>
                                            <Button type={"link"} onClick={() => setOpenExcess(true)}>

                                                {
                                                    (data?.excess?.user?.length > 0 && data?.excess?.percentage) ? formatMoney(data?.excess?.percentage) + "%" :
                                                        <EditOutlined/>
                                                }
                                            </Button>
                                        </Col>
                                    ) : null
                                }

                            </Row>
                        </Col>
                        <Col span={10}>
                            <Row gutter={20}>
                                {
                                    ruleData?.commType === "rank" ? (
                                        <>
                                            {
                                                rank?.sort((a, b) => a.rank - b.rank)?.map((item) => {

                                                    const rankAbove = rank.filter((r) => r.rank <= item.rank)
                                                    const getTotal = rankCriteriaDetails?.find((r) => r?.rank?._id === item?.value)?.total
                                                    return (
                                                        <Col xs={12} md={6}
                                                             style={{textAlign: "center", marginBottom: "15px"}}>
                                                            <Paragraph className={"margin-bottom-0"}
                                                                       ellipsis={{rows: 2, tooltip: true}}>
                                                                <h5 style={{
                                                                    fontSize: ".83em",
                                                                    height: 32
                                                                }}>{item?.label}</h5>
                                                            </Paragraph>
                                                            <Button type={"link"} onClick={() => {
                                                                setOpenRankCriteria(true)
                                                                setRankCriteria(item)
                                                                setRankAbove(rankAbove)
                                                            }}>
                                                                {getTotal ? formatMoney(getTotal, ruleData?.commMode) : formatMoney(0, ruleData?.commMode)}
                                                            </Button>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </>
                                    ) : (
                                        <>
                                            {
                                                levelCriteriaDetails?.sort((a, b) => a.level - b.level)?.map((item, index) => {

                                                    const levelAbove = levelCriteriaDetails.filter((r) => r.level >= item.level)
                                                    const getTotal = levelCriteriaDetails?.find((r) => r?.level === item?.level)?.total


                                                    return (
                                                        <Col xs={12} md={6}
                                                             style={{textAlign: "center", marginBottom: "15px"}}>
                                                            <Paragraph className={"margin-bottom-0"}
                                                                       ellipsis={{rows: 2, tooltip: true}}>
                                                                <h5 style={{
                                                                    fontSize: ".83em",
                                                                    height: 32
                                                                }}>Level {item?.level}{index === 0 ? " (Direct)" : ""}</h5>
                                                            </Paragraph>
                                                            <Button type={"link"} onClick={() => {
                                                                setOpenRankCriteria(true)
                                                                setRankCriteria(item)
                                                                setRankAbove(levelAbove)
                                                            }}>
                                                                {getTotal ? formatMoney(getTotal, ruleData?.commMode) : formatMoney(0, ruleData?.commMode)}
                                                            </Button>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </>
                                    )
                                }

                            </Row>
                        </Col>
                        <Col span={2} style={{marginBottom: "15px", textAlign: "center"}}>
                            <Tooltip placement="rightTop" title={"Excluding excess"}>
                                <h5 style={{cursor: "pointer"}}>Total <InfoCircleOutlined/></h5>
                            </Tooltip>
                            <Button type={"link"} style={{color: "black"}} disabled>
                                {ruleData?.commType === "rank" ? formatMoney(rankCriteriaTotal, ruleData?.commMode) : formatMoney(levelCriteriaTotal, ruleData?.commMode)}
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </Spin>
            <ParameterEditDetailsModal type={"overriding"} data={data} isOpen={openOverriding}
                                       closeModal={() => setOpenOverriding(false)}
                                       userData={userData} getParameter={getParameter} setOverridingTotal={setOverridingTotal}/>

            <ParameterEditDetailsModal type={"excess"} data={data} isOpen={openExcess}
                                       closeModal={() => setOpenExcess(false)}
                                       userData={userData} getParameter={getParameter}/>

            <ParameterEditDetailsModal type={"cashback"} data={data} isOpen={openCashback}
                                       closeModal={() => setOpenCashback(false)}
                                       userData={userData} getParameter={getParameter}/>

            <ParameterRankCriteriaModal isOpen={openRankCriteria}
                                        conditions={conditions}
                                        closeModal={() => setOpenRankCriteria(false)} rank={rankCriteria} data={data}
                                        rankAbove={rankAbove} preloadRank={preload} commType={ruleData?.commType}/>

            <ParameterEditModal isOpen={editModal} closeModal={() => setEditModal(false)} data={data} type={"edit"}
                                ruleData={ruleData}
                                getParameter={getParameter}/>
        </>
    )

}

export default ParameterCard