import React from "react";
import {Col, Row, Tag} from "antd";
import {useTranslation} from "react-i18next";
import {getPspSettingById} from "../../../../../../../api/graphql/pspSetting";
import {getPspSettingCurrencyById} from "../../../../../../../api/graphql/pspSettingCurrency";
import {getDeskDropdown} from "../../../../../../../api/graphql/_common";
import {formatDateTimeTimezone, formatDateUTC} from "../../../../../../../function/_common";

function PspAdjustmentLayout_1(props) {
    const {data, mode} = props;

    const {t} = useTranslation();

    const payloadData = data?.payload?.input;

    const pspSettingData = getPspSettingById(payloadData?.pspSettingId);
    const pspSettingCurrencyData = getPspSettingCurrencyById(payloadData?.pspSettingCurrencyId);
    const {data: deskList} = getDeskDropdown(localStorage.getItem("g_userId") || localStorage.getItem("userId"));

    const pspName = pspSettingData?.data?.getPspSettingByID?.displayName;
    const currencyName = pspSettingCurrencyData?.data?.getPspSettingCurrencyByID?.currency;
    const deskName = deskList?.getDeskDropdown?.data?.find((desk) => desk?._id === payloadData?.deskId)?.name;

    return (
        <div id={"tradingAccountLayout"}>
            <Row gutter={[16, 16]}>
                <Col xs={24} lg={12}>
                    <Row gutter={[16, 12]} style={{marginBottom: "15px"}}>
                        <Col xs={12} lg={8}>
                            <h4>{t("task.mainModuleName")}:</h4>
                        </Col>
                        <Col xs={12} lg={16}>
                            {t("module." + data?.moduleName)}
                        </Col>
                        <Col xs={12} lg={8}>
                            <h4>{t("task.type")}:</h4>
                        </Col>
                        <Col xs={12} lg={16}>
                            {t("crmTask." + data?.actionType)}
                        </Col>
                        <Col xs={12} lg={8}>
                            <h4>{t("task.status")}:</h4>
                        </Col>
                        <Col xs={12} lg={16}>
                            {data?.status === "pending" ? (
                                <Tag color={"warning"}>{data?.isClaimed === true ? "pending approval" : "pending"}</Tag>
                            ) : data?.status === "approved" ? (
                                <Tag color={"success"}>{data?.status}</Tag>
                            ) : (
                                <Tag color={"error"}>{data?.status}</Tag>
                            )}
                        </Col>
                        <Col xs={12} lg={8}>
                            <h4>{t("task.description")}:</h4>
                        </Col>
                        <Col xs={12} lg={16}>
                            {data?.description}
                        </Col>
                        <Col xs={12} lg={8}>
                            <h4>{t("task.created_time")}:</h4>
                        </Col>
                        <Col xs={12} lg={16}>
                            {formatDateTimeTimezone(data?.createdAt)}
                        </Col>
                        <Col xs={12} lg={8}>
                            <h4>{t("task.requestedBy")}:</h4>
                        </Col>
                        <Col xs={12} lg={16}>
                            {data?.requestedBy?.username}
                        </Col>
                        <Col xs={12} lg={8}>
                            <h4>{t("task.claimBy")}:</h4>
                        </Col>
                        <Col xs={12} lg={16}>
                            {data?.claimBy?.username}
                        </Col>
                        {data?.status === "approved" && (
                            <>
                                <Col xs={12} lg={8}>
                                    <h4>{t("task.approvedBy")}:</h4>
                                </Col>
                                <Col xs={12} lg={16}>
                                    {data?.approvedBy?.username}
                                </Col>
                                <Col xs={12} lg={8}>
                                    <h4>{t("task.approvedTime")}:</h4>
                                </Col>
                                <Col xs={12} lg={16}>
                                    {formatDateTimeTimezone(data?.approvedDatetime)}
                                </Col>
                            </>
                        )}
                        {data?.status === "rejected" && (
                            <>
                                <Col xs={12} lg={8}>
                                    <h4>{t("task.rejectedBy")}:</h4>
                                </Col>
                                <Col xs={12} lg={16}>
                                    {data?.rejectedBy?.username}
                                </Col>
                                <Col xs={12} lg={8}>
                                    <h4>{t("task.rejectedTime")}:</h4>
                                </Col>
                                <Col xs={12} lg={16}>
                                    {formatDateTimeTimezone(data?.rejectedDatetime)}
                                </Col>
                            </>
                        )}
                    </Row>
                </Col>

                <Col xs={24} md={12}>
                    <Row gutter={[16, 12]} style={{marginBottom: "15px"}}>
                        <Col xs={12} lg={8}>
                            <h4>{t("PSP")}:</h4>
                        </Col>
                        <Col xs={12} lg={16}>
                            {pspName}
                        </Col>
                        <Col xs={12} lg={8}>
                            <h4>{t("common.currency")}:</h4>
                        </Col>
                        <Col xs={12} lg={16}>
                            {currencyName}
                        </Col>
                        <Col xs={12} lg={8}>
                            <h4>{t("common.type")}:</h4>
                        </Col>
                        <Col xs={12} lg={16}>
                            {t(`common.${payloadData?.type}`) ?? "-"}
                        </Col>
                        {payloadData?.type === "balance" ? (
                            <>
                                <Col xs={12} lg={8}>
                                    <h4>{t("common.date")}:</h4>
                                </Col>
                                <Col xs={12} lg={16}>
                                    {formatDateUTC(payloadData?.adjustmentDate)}
                                </Col>
                                <Col xs={12} lg={8}>
                                    <h4>{t("user.desk")}:</h4>
                                </Col>
                                <Col xs={12} lg={16}>
                                    {deskName || "-"}
                                </Col>
                            </>
                        ) : (
                            <>
                                {/* <Col xs={12} lg={8}>
                                    <h4>{t("task.transaction_no")}:</h4>
                                </Col>
                                <Col xs={12} lg={16}>
                                    {payloadData?.transactionId}
                                </Col> */}
                            </>
                        )}
                        <Col xs={12} lg={8}>
                            <h4>{t("common.amount")}:</h4>
                        </Col>
                        <Col xs={12} lg={16}>
                            {payloadData?.convertedAmount}
                        </Col>
                        <Col xs={12} lg={8}>
                            <h4>{t("common.rate")}:</h4>
                        </Col>
                        <Col xs={12} lg={16}>
                            {payloadData?.rate ?? "-"}
                        </Col>
                        <Col xs={12} lg={8}>
                            <h4>{t("common.remark")}:</h4>
                        </Col>
                        <Col xs={12} lg={16}>
                            {payloadData?.remark ?? "-"}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default PspAdjustmentLayout_1;
