import React, {useEffect, useState} from "react";
import TableAntDesign from "../../../../../components/table/tableAntDesign";
import {Button, Space, Tag} from "antd";
import {EditOutlined, EyeOutlined} from "@ant-design/icons";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {useTranslation} from "react-i18next";
import {ColumnTask} from "../../../function/tableColumn";
import {useClaimCrmTaskMutation} from "../../../../../api/graphql/crmTask";
import {notificationMessage} from "../../../../../../recoil_state";
import TaskModal from "./taskModal";
import moment from "moment";
import useAuthorize from "../../../../../_common/authorize";
import {checkIsMasterAdmin} from "../../../../../api/graphql/user";
import {crmTaskOffsetState} from "../../../state";
import {formatDateTimeTimezone} from "../../../../../function/_common";


function TaskTable_1(props) {

    const {
        data,
        loading,
        tableName,
        refetch,
        total,
        setCrmTaskStartTimeSearch,
        setCrmTaskEndTimeSearch,
        setCrmTaskDateKeySearch,
        setCrmTaskOrderBySearch,
        setCrmTaskLimit, crmTaskLimit,
        setCrmTaskOffset,
        crmTaskTableFilter,
        setCrmTaskTableFilter,
        listType,
        columnTask
    } = props
    const {t} = useTranslation()

    const [taskData, setTaskData] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [taskIdModal, setTaskIdModal] = useState<any>(null);
    const [taskModalMode, setTaskModalMode] = useState<any>("");

    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const crmTaskOffset = useRecoilValue(crmTaskOffsetState)
    const {isMasterAdmin} = checkIsMasterAdmin(localStorage.getItem("g_userId") || localStorage.getItem("userId"));
    const currentUserId = localStorage.getItem("g_userId") || localStorage.getItem("userId")
    const {claimCrmTask} = useClaimCrmTaskMutation()
    const {systemErrorNotification} = useAuthorize()

    const onChangePageHandler = (page, pageSize) => {
        setCrmTaskLimit(pageSize)
        setCrmTaskOffset(crmTaskLimit * (page - 1))
    }

    const processData = async () => {
        await setTaskData([])
        if (data) {
            const latestData: any = await Promise.all(data?.map((task) => {
                return {
                    ...task,
                    statusList: task?.status,
                    errMsg: t(task?.errMsg),
                    actionType: t("crmTask." + task?.actionType),
                    requestedBy: task?.requestedBy?.username,
                    claimById: task?.claimBy?._id,
                    claimBy: task?.claimBy?.username,
                    approvedBy: task?.approvedBy?.username,
                    approvedDatetime: task?.approvedDatetime ? formatDateTimeTimezone(task?.approvedDatetime) : "-",
                }
            }))

            setTaskData(latestData)
        }

    }

    const claimTask = async (id) => {

        try {
            const response = await claimCrmTask(currentUserId, id)

            if (response?.data?.claimCrmTask?.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("task.claim_successfully"),
                    key: "claimCrmTask",
                });
                await viewTaskModal(id, "verify")
            } else if (response?.data?.claimCrmTask?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.data?.claimCrmTask?.errKey),
                    key: "claimCrmTask",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.data?.claimCrmTask?.msg),
                    key: "claimCrmTask",
                });
            }

        } catch (e) {
            systemErrorNotification()
        }

    }

    const viewTaskModal = async (id, mode) => {
        await setTaskIdModal(id)
        await setTaskModalMode(mode)
        setOpenModal(true)
    }

    useEffect(() => {
        processData()
    }, [data])


    useEffect(() => {
        onChangePageHandler
    }, [crmTaskOffset])

    return (
        <div className={"margin-top-0-75"}>
            <TableAntDesign data={taskData}
                            tableName={tableName}
                            columns={[...columnTask, {
                                title: t("common.action"),
                                key: "action",
                                width: 100,
                                align: "left",
                                render: (_, record: any) => {
                                    return (
                                        <Space>

                                            {
                                                record?.status !== "expired" && (
                                                    <>
                                                        {
                                                            record?.status === "pending" && (
                                                                <Button size={"small"}
                                                                        onClick={() => claimTask(record._id)}>
                                                                    {t("task.claim")}
                                                                </Button>
                                                            )
                                                        }
                                                        {
                                                            (currentUserId === record?.claimById || isMasterAdmin) && (record?.status === "pending approval" || record?.status === "failed" && record?.isActionAvailable === true) && (record?.description !== "Pending approval from bank") && (
                                                                <a onClick={() => viewTaskModal(record?._id, "verify")}>
                                                                    <EditOutlined/>
                                                                </a>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }

                                            <a onClick={() => viewTaskModal(record?._id, "detail")}>
                                                <EyeOutlined/>
                                            </a>
                                        </Space>
                                    )
                                },
                            },]}
                            size={"small"}
                            loading={loading}
                            filter={setCrmTaskTableFilter}
                            filterValue={crmTaskTableFilter}
                            startDate={setCrmTaskStartTimeSearch}
                            endDate={setCrmTaskEndTimeSearch}
                            dateKey={setCrmTaskDateKeySearch}
                            order={setCrmTaskOrderBySearch}
                            pagination={{
                                pageSize: crmTaskLimit,
                                current: (crmTaskOffset / crmTaskLimit) + 1,
                                showSizeChanger: true,
                                position: "bottomCenter",
                                total: total,
                                onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                                showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                            }}
            />

            <TaskModal refetch={refetch} isOpen={openModal} id={taskIdModal} mode={taskModalMode} closeModal={() => {
                setOpenModal(false)
                setTaskIdModal(null)
            }}/>
        </div>
    )
}

export default TaskTable_1