import React, {useState} from "react";
import {BsCheck, BsX} from "react-icons/bs";
import {getBooleanFilterProps, getColumnSearchProps} from "../../../../components/table/function/filterTable";
import {Tag} from "antd";

const SeeMoreSpreadGroupComponent = ({text}) => {
    const [view, setView] = useState(false);

    let tagsToShow = text?.slice(0, view ? text.length : 3);

    const renderTag = (tag, i) => (
        <Tag
            bordered={false}
            key={i}
            style={{margin: 2}}
            color={i === 0 ? "blue" : "default"}
        >
            {tag?.group}
        </Tag>
    );

    const renderSeeMoreTag = () => (
        <Tag
            bordered={false}
            style={{margin: 2, cursor: "pointer"}}
            color={"green"}
            onClick={() => setView(true)}
        >
            ...
        </Tag>
    );

    return (
        <div>
            {
                tagsToShow?.length > 0 ? (
                    <>
                        {tagsToShow?.map(renderTag)}
                        {!view && text?.length > 3 && renderSeeMoreTag()}
                    </>
                ) : "-"
            }
        </div>
    );
};

const SeeMoreLeverageComponent = ({text}) => {
    const [view, setView] = useState(false);

    let tagsToShow = text?.slice(0, view ? text.length : 3);

    const renderTag = (tag, i) => (
        <Tag
            bordered={false}
            key={i}
            style={{margin: 2}}
            color={i === 0 ? "blue" : "default"}
        >
            {tag}
        </Tag>
    );

    const renderSeeMoreTag = () => (
        <Tag
            bordered={false}
            style={{margin: 2, cursor: "pointer"}}
            color={"green"}
            onClick={() => setView(true)}
        >
            ...
        </Tag>
    );

    return (
        <div>
            {
                tagsToShow?.length > 0 ? (
                    <>
                        {tagsToShow?.map(renderTag)}
                        {!view && text?.length > 3 && renderSeeMoreTag()}
                    </>
                ) : "-"
            }
        </div>
    );
};

const SeeMoreNetworkComponent = (prop) => {
    const [view, setView] = useState(false);
    const {text, record} = prop
    const network = text || record?.network || []

    let tagsToShow = network?.slice(0, view ? network.length : 3);

    const renderTag = (tag, i) => (
        <Tag
            bordered={false}
            key={i}
            style={{margin: 2}}
            color={i === 0 ? "blue" : "default"}
        >
            {tag?.username}
        </Tag>
    );

    const renderSeeMoreTag = () => (
        <Tag
            bordered={false}
            style={{margin: 2, cursor: "pointer"}}
            color={"green"}
            onClick={() => setView(true)}
        >
            ...
        </Tag>
    );

    return (
        <div>
            {
                tagsToShow?.length > 0 ? (
                    <>
                        {tagsToShow?.map(renderTag)}
                        {!view && text?.length > 3 && renderSeeMoreTag()}
                    </>
                ) : "-"
            }
        </div>
    );
};

export const columnsMT5 = [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        ...getColumnSearchProps(),
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Code",
        dataIndex: "code",
        key: "code",
        ...getColumnSearchProps(),
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Product Type",
        dataIndex: "productType",
        key: "productType",
        ...getColumnSearchProps(),
        render: (text) => <p>{text?.replace("Live", "")?.replace("Demo", "")?.replace("T", " t")}</p>,
    },
    {
        title: "Product Mode",
        dataIndex: "mode",
        key: "mode",
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Min Deposit",
        dataIndex: "minDeposit",
        key: "minDeposit",
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Spread Group",
        dataIndex: "spreadGroupMt5",
        key: "spreadGroupMt5",
        textWrap: 'word-break',
        render: (text) => <SeeMoreSpreadGroupComponent text={text || "-"}/>,
    },
    {
        title: "Leverage",
        dataIndex: "leverage",
        key: "leverage",
        textWrap: 'word-break',
        render: (text) => <SeeMoreLeverageComponent text={text || "-"}/>,
    },
    {
        title: "Which network can view",
        dataIndex: "networkType",
        key: "networkType",
        render: (text) => {
            if (text === "partial") return <p>Some Users</p>
            else if (text === "all") return <p>All Users</p>
            else if (text === "own") return <p>Only Me</p>
        }
    },
    {
        title: "Include",
        dataIndex: "include",
        key: "include",
        render: (text, record) => <SeeMoreNetworkComponent record={record}/>,
    },
    {
        title: "Exclude",
        dataIndex: "exclude",
        key: "exclude",
        render: (text) => <SeeMoreNetworkComponent text={text || "-"}/>,
    },
    {
        title: "Product Group",
        dataIndex: "productGroup",
        key: "productGroup",
        render: (text) => <p>{text?.name}</p>
    },
    // {
    //     title: "Interest Rate %",
    //     dataIndex: ["interestRateType", "interestRate"],
    //     key: "interestRate",
    //     render: (text, record) => <p><strong>{record?.interestRate}</strong> /{record?.interestRateType}</p>,
    // },
    {
        title: "Public",
        dataIndex: "showPublic",
        key: "showPublic",
        ...getBooleanFilterProps(),
        render: (text) => (text) ? <BsCheck style={{color: 'green'}}/> : <BsX style={{color: 'red'}}/>,
    },
];
