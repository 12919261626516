import { gql, useMutation, useQuery } from "@apollo/client";
import { useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { errorMessage, notificationMessage } from "../../../recoil_state";

const GET_PLATFORM_COUNT = gql`
  query getPlatformCount($filter: PlatformInput) {
    getPlatformCount(filter: $filter)
  }
`;

const getPlatformCount = ({ filter }) => {
  try {
    const { loading, error, data } = useQuery(GET_PLATFORM_COUNT, {
      variables: {
        filter: {
          server: filter?.server || null,
        },
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_PLATFORM_BY_ID = gql`
  query getPlatformByID($id: String!) {
    getPlatformByID(id: $id) {
      ... on Platform {
        id
        displayName
        type
        windowLinkObject {
          displayNameLanguage {label language}
          displayName
          isEnabled
          link
          linkFileList
          icon
          iconFileList
          defaultIcon
          defaultIconFileList
        }
        macOSXLinkObject {
          displayNameLanguage {label language}
          displayName
          isEnabled
          link
          linkFileList
          icon
          iconFileList
          defaultIcon
          defaultIconFileList
        }
        androidAPKLinkObject {
          displayNameLanguage {label language}
          displayName
          isEnabled
          link
          linkFileList
          icon
          iconFileList
          defaultIcon
          defaultIconFileList
        }
        iOSDownloadLinkObject {
          displayNameLanguage {label language}
          displayName
          isEnabled
          link
          icon
          iconFileList
          defaultIcon
          defaultIconFileList
        }
        androidDownloadLinkObject {
          displayNameLanguage {label language}
          displayName
          isEnabled
          link
          icon
          iconFileList
          defaultIcon
          defaultIconFileList
        }
        huaweiDownloadLinkObject {
          displayNameLanguage {label language}
          displayName
          isEnabled
          link
          icon
          iconFileList
          defaultIcon
          defaultIconFileList
        }
        server {
          name
        }
        createdBy {
          username
        }
        updatedBy {
          username
        }
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getPlatformById = (id) => {
  try {
    const { loading, error, data } = useQuery(GET_PLATFORM_BY_ID, {
      variables: {
        id: id,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_PLATFORM = gql`
  query getPlatform(
    $filter: PlatformInput
    $limit: Int
    $offset: Int
    $orderBy: String
  ) {
    getPlatform(
      filter: $filter
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      ... on PlatformArray {
        data {
          id
          displayName
          type
          windowLinkObject {
            displayNameLanguage {label language}
            displayName
            isEnabled
            link
            linkFileList
            icon
            iconFileList
            defaultIcon
            defaultIconFileList
          }
          macOSXLinkObject {
            displayNameLanguage {label language}
            displayName
            isEnabled
            link
            linkFileList
            icon
            iconFileList
            defaultIcon
            defaultIconFileList
          }
          androidAPKLinkObject {
            displayNameLanguage {label language}
            displayName
            isEnabled
            link
            linkFileList
            icon
            iconFileList
            defaultIcon
            defaultIconFileList
          }
          iOSDownloadLinkObject {
            displayNameLanguage {label language}
            displayName
            isEnabled
            link
            icon
            iconFileList
            defaultIcon
            defaultIconFileList
          }
          androidDownloadLinkObject {
            displayNameLanguage {label language}
            displayName
            isEnabled
            link
            icon
            iconFileList
            defaultIcon
            defaultIconFileList
          }
          huaweiDownloadLinkObject {
            displayNameLanguage {label language}
            displayName
            isEnabled
            link
            icon
            iconFileList
            defaultIcon
            defaultIconFileList
          }
          server {
            _id
            name
          }
          createdBy {
            username
          }
          updatedBy {
            username
          }
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getPlatform = ({ filter }, limit?, offset?, orderBy?) => {
  try {
    const { loading, error, data } = useQuery(GET_PLATFORM, {
      variables: {
        limit: limit || null,
        offset: offset || null,
        orderBy: orderBy || null,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const ADD_PLATFORM = gql`
  mutation addPlatform($input: PlatformInput) {
    addPlatform(input: $input) {
      ... on CrmTaskApproval {
        msg
      }
      ... on Platform {
        id
        displayName
        type
        windowLinkObject {
          displayNameLanguage {label language}
          displayName
          isEnabled
          link
          linkFileList
          icon
          iconFileList
          defaultIcon
          defaultIconFileList
        }
        macOSXLinkObject {
          displayNameLanguage {label language}
          displayName
          isEnabled
          link
          linkFileList
          icon
          iconFileList
          defaultIcon
          defaultIconFileList
        }
        androidAPKLinkObject {
          displayNameLanguage {label language}
          displayName
          isEnabled
          link
          linkFileList
          icon
          iconFileList
          defaultIcon
          defaultIconFileList
        }
        iOSDownloadLinkObject {
          displayNameLanguage {label language}
          displayName
          isEnabled
          link
          icon
          iconFileList
          defaultIcon
          defaultIconFileList
        }
        androidDownloadLinkObject {
          displayNameLanguage {label language}
          displayName
          isEnabled
          link
          icon
          iconFileList
          defaultIcon
          defaultIconFileList
        }
        huaweiDownloadLinkObject {
          displayNameLanguage {label language}
          displayName
          isEnabled
          link
          icon
          iconFileList
          defaultIcon
          defaultIconFileList
        }
        server {
          name
        }
        createdBy {
          username
        }
        updatedBy {
          username
        }
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useAddPlatformMutation = (): {
  addPlatform: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const setErrorMessage = useSetRecoilState(errorMessage);
    const [addPlatformMutation, { data, loading, error }] = useMutation(
      ADD_PLATFORM,
      {
        update(cache, { data: { addPlatform } }) {
          let typename = addPlatform?.__typename;
          if (typename === "Platform") {
            cache.modify({
              fields: {
                getPlatform(existingData = []) {
                  const newRef = cache.writeFragment({
                    data: addPlatform,
                    fragment: gql`
                      fragment NewPlatform on Platform {
                        id
                        displayName
                        type
                        windowLinkObject {
                          displayNameLanguage {label language}
                          displayName
                          isEnabled
                          link
                          linkFileList
                          icon
                          iconFileList
                          defaultIcon
                          defaultIconFileList
                        }
                        macOSXLinkObject {
                          displayNameLanguage {label language}
                          displayName
                          isEnabled
                          link
                          linkFileList
                          icon
                          iconFileList
                          defaultIcon
                          defaultIconFileList
                        }
                        androidAPKLinkObject {
                          displayNameLanguage {label language}
                          displayName
                          isEnabled
                          link
                          linkFileList
                          icon
                          iconFileList
                          defaultIcon
                          defaultIconFileList
                        }
                        iOSDownloadLinkObject {
                          displayNameLanguage {label language}
                          displayName
                          isEnabled
                          link
                          icon
                          iconFileList
                          defaultIcon
                          defaultIconFileList
                        }
                        androidDownloadLinkObject {
                          displayNameLanguage {label language}
                          displayName
                          isEnabled
                          link
                          icon
                          iconFileList
                          defaultIcon
                          defaultIconFileList
                        }
                        huaweiDownloadLinkObject {
                          displayNameLanguage {label language}
                          displayName
                          isEnabled
                          link
                          icon
                          iconFileList
                          defaultIcon
                          defaultIconFileList
                        }
                        server {
                          name
                        }
                        createdBy {
                          username
                        }
                        updatedBy {
                          username
                        }
                        createdAt
                        updatedAt
                      }
                    `,
                  });
                  return {
                    ...existingData,
                    data:
                      existingData?.data?.length > 0
                        ? [newRef, ...existingData.data]
                        : [newRef],
                  };
                },
                getPlatformCount(existingData) {
                  return existingData + 1;
                },
              },
            });
          } else if (
            typename === "CrmTaskApproval" ||
            typename === "BaseError"
          ) {
            setErrorMessage(addPlatform);
          }
        },
      }
    );

    const addPlatform = (input) => {
      return addPlatformMutation({
        variables: {
          input: {
            windowLinkObject: input.windowLinkObject || null,
            macOSXLinkObject: input.macOSXLinkObject || null,
            androidAPKLinkObject: input.androidAPKLinkObject || null,
            iOSDownloadLinkObject: input.iOSDownloadLinkObject || null,
            androidDownloadLinkObject: input.androidDownloadLinkObject || null,
            huaweiDownloadLinkObject: input.huaweiDownloadLinkObject || null,
            server: input.server || null,
          },
        },
      });
    };

    return { addPlatform, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return { addPlatform: null, data: null, loading: false, error: err };
  }
};

const UPDATE_PLATFORM = gql`
  mutation updatePlatform($id: String, $input: PlatformInput) {
    updatePlatform(id: $id, input: $input) {
      ... on CrmTaskApproval {
        msg
      }
      ... on Platform {
        id
        displayName
        type
        windowLinkObject {
          displayNameLanguage {label language}
          displayName
          isEnabled
          link
          linkFileList
          icon
          iconFileList
          defaultIcon
          defaultIconFileList
        }
        macOSXLinkObject {
          displayNameLanguage {label language}
          displayName
          isEnabled
          link
          linkFileList
          icon
          iconFileList
          defaultIcon
          defaultIconFileList
        }
        androidAPKLinkObject {
          displayNameLanguage {label language}
          displayName
          isEnabled
          link
          linkFileList
          icon
          iconFileList
          defaultIcon
          defaultIconFileList
        }
        iOSDownloadLinkObject {
          displayNameLanguage {label language}
          displayName
          isEnabled
          link
          icon
          iconFileList
          defaultIcon
          defaultIconFileList
        }
        androidDownloadLinkObject {
          displayNameLanguage {label language}
          displayName
          isEnabled
          link
          icon
          iconFileList
          defaultIcon
          defaultIconFileList
        }
        huaweiDownloadLinkObject {
          displayNameLanguage {label language}
          displayName
          isEnabled
          link
          icon
          iconFileList
          defaultIcon
          defaultIconFileList
        }
        server {
          name
        }
        createdBy {
          username
        }
        updatedBy {
          username
        }
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useUpdatePlatformMutation = (): {
  updatePlatform: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const { t } = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [updatePlatformMutation, { data, loading, error }] =
      useMutation(UPDATE_PLATFORM);

    const updatePlatform = (id, input) => {
      return updatePlatformMutation({
        variables: {
          id: id,
          input: {
            windowLinkObject: input?.windowLinkObject || null,
            macOSXLinkObject: input?.macOSXLinkObject || null,
            androidAPKLinkObject: input?.androidAPKLinkObject || null,
            iOSDownloadLinkObject: input?.iOSDownloadLinkObject || null,
            androidDownloadLinkObject: input?.androidDownloadLinkObject || null,
            huaweiDownloadLinkObject: input?.huaweiDownloadLinkObject || null,
            server: input?.server || null,
          },
        },
        update(cache, { data: { updatePlatform } }) {
          const typename = updatePlatform?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(updatePlatform?.errKey),
              key: "updatePlatform",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(updatePlatform?.msg),
              key: "updatePlatform",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Update Successfully"),
              key: "updatePlatform",
            });
          }
        },
      });
    };

    return { updatePlatform, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return { updatePlatform: null, data: null, loading: false, error: err };
  }
};

const DELETE_PLATFORM = gql`
  mutation deletePlatform($id: String!) {
    deletePlatform(id: $id) {
      ... on CrmTaskApproval {
        msg
      }
      ... on Platform {
        id
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useDeletePlatformMutation = (): {
  deletePlatform: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const setErrorMessage = useSetRecoilState(errorMessage);
    const [deletePlatformMutation, { data, loading, error }] = useMutation(
      DELETE_PLATFORM,
      {
        update(cache, { data: { deletePlatform } }) {
          let typename = deletePlatform?.__typename;
          if (typename === "Platform") {
            cache.modify({
              fields: {
                getPlatform(existingData = [], { readField }) {
                  let data = existingData?.data?.filter((ref) => {
                    const id = readField("id", ref);
                    return id !== deletePlatform.id;
                  });
                  return { data, __typename: "PlatformArray" };
                },
              },
            });
          } else if (
            typename === "CrmTaskApproval" ||
            typename === "BaseError"
          ) {
            setErrorMessage(deletePlatform);
          }
        },
      }
    );

    const deletePlatform = (id) => {
      return deletePlatformMutation({
        variables: {
          id: id,
        },
      });
    };

    return { deletePlatform, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return { deletePlatform: null, data: null, loading: false, error: err };
  }
};

export {
  getPlatformCount,
  getPlatformById,
  getPlatform,
  useAddPlatformMutation,
  useUpdatePlatformMutation,
  useDeletePlatformMutation,
};
