const handleOnChangeWithLanguage = (value, field, fieldType, languageCode, setData) => {
  try {
    setData((prevState) => {
      if (!prevState || !prevState[field]) {
        return {
          ...prevState,
          [field]: [
            {
              [fieldType]: value,
              language: languageCode,
            },
          ],
        };
      }

      var currentData = [...prevState[field]] || [];
      var index = currentData?.findIndex((item) => item?.language === languageCode);

      if (index === -1) {
        currentData?.push({ [fieldType]: value, language: languageCode });
      } else {
        const updatedObj = { ...currentData[index], [fieldType]: value };
        currentData[index] = updatedObj;
      }

      var result = {
        ...prevState,
        [field]: currentData,
      };

      return result;
    });
  } catch (error) {
    console.log(error);
  }
};

const handleLegalDocumentOnChangeWithLanguage = (value, fieldWithName, fieldType, languageCode, setData) => {
  try {
    setData((prevState) => {
      const lastUnderscoreIndex = fieldWithName.lastIndexOf("_");
      const secondLastUnderscoreIndex = fieldWithName.lastIndexOf("_", lastUnderscoreIndex - 1);
      const documentName = fieldWithName.slice(0, secondLastUnderscoreIndex);
      const field = fieldWithName.slice(secondLastUnderscoreIndex + 1, lastUnderscoreIndex);

      let result = prevState.map((item) => JSON.parse(JSON.stringify(item)));
      let currentData = result?.length > 0 ? result?.find((d) => d?.name === documentName) : undefined;
      if (!result?.length) {
        result = [
          {
            enabled: true,
            name: documentName,
            [field]: [{ [fieldType]: value, language: languageCode }],
          },
        ];
      } else if (currentData !== undefined) {
        const languageIndex = currentData[field]?.findIndex((item) => item?.language === languageCode);
        const currentKey = currentData[field];
        if (languageIndex !== -1 && currentKey) {
          currentData[field][languageIndex][fieldType] = value;
        } else if (currentKey) {
          currentData[field] = [...currentData[field], { [fieldType]: value, language: languageCode }];
        } else {
          currentData = { ...currentData, [field]: [{ [fieldType]: value, language: languageCode }] };
        }
        result[result.findIndex((d) => d?.name === documentName)] = currentData;
      } else {
        result.push({
          enabled: true,
          name: documentName,
          [field]: [{ [fieldType]: value, language: languageCode }],
        });
      }

      return result;
    });
  } catch (error) {
    console.log(error);
  }
};

const getLegalDocumentDataWithLanguage = (data, fieldWithName, fieldType, languageCode) => {
  try {
    const lastUnderscoreIndex = fieldWithName.lastIndexOf("_");
    const secondLastUnderscoreIndex = fieldWithName.lastIndexOf("_", lastUnderscoreIndex - 1);
    const documentId = fieldWithName.slice(0, secondLastUnderscoreIndex);
    const field = fieldWithName.slice(secondLastUnderscoreIndex + 1, lastUnderscoreIndex);

    const currentData = data?.find((item) => item?._id === documentId);
    if (currentData && currentData[field]) {
      const index = currentData[field]?.findIndex((item) => item?.language === languageCode);

      if (index < 0 || currentData[field]?.length === 0) {
        return undefined;
      }

      return currentData[field][index][fieldType];
    }
  } catch (error) {
    console.log(error);
  }
};

const getDataWithLanguage = (data, field, fieldType, languageCode) => {
  try {
    const index = data[field]?.findIndex((item) => item?.language === languageCode);

    if (!data[field] || !data[field] || !data[field][index]) {
      return "";
    }

    return data[field][index][fieldType];
  } catch (error) {
    console.log(error);
  }
};

const getListFromObject = (array, field, language) => {
  if (!array) {
    return [];
  }

  const index = array.findIndex((item) => item?.language === language);

  if (!array || !array[index]) {
    return [];
  }

  return array[index][field];
};

export {
  handleOnChangeWithLanguage,
  handleLegalDocumentOnChangeWithLanguage,
  getLegalDocumentDataWithLanguage,
  getDataWithLanguage,
  getListFromObject,
};
