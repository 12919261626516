import React from "react";
import MultiTierSettingTable from "./multiTierSettingTable";

const MultiTierSetting = (props) => {
  const { permission } = props;

  /* type = "crmUser" or "client", */

  return (
    <div className={"margin-top-0-75"}>
      <MultiTierSettingTable permission={permission} type="crmUser" />
      <MultiTierSettingTable permission={permission} type="client" />
    </div>
  );
};

export default MultiTierSetting;
