import React from "react";
import {useRecoilState} from "recoil";
import PushNotificationTemplate_1 from "./view/layout1";
import {selfPermissionObj} from "../../../../recoil_state";

function PushNotificationTemplatePage() {
    let layout = 1;
    let component;
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const pushNotificationTemplatePermission =
        selfPermission?.messageSetting?.pushNotificationTemplate;

    switch (layout) {
        case 1:
            component = (
                <PushNotificationTemplate_1
                    pushNotificationTemplatePermission={
                        pushNotificationTemplatePermission
                    }
                />
            );
            break;

        default:
            component = (
                <PushNotificationTemplate_1
                    pushNotificationTemplatePermission={
                        pushNotificationTemplatePermission
                    }
                />
            );
            break;
    }

    return component;
}

export default PushNotificationTemplatePage;
