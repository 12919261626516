import React, {useEffect, useState} from "react";
import {Col, Row} from "antd";
import FieldCards from "./fieldCards";
import SettingCards from "./settingCards";
import UserPreview from "./userPreview";
import {useRecoilValue} from "recoil";
import {profileInformationSettingState, userTypeState} from "../../../state";
import {useUpdateProfileInformationSettingMutation} from "../../../../../../api/graphql/profileInformation";
import {formatProfileInformation} from "../../../../../users/_common/function";
import useAuthorize from "../../../../../../_common/authorize";
import LeadsFieldCards from "./leadsFieldCard";

const SettingFields = (props) => {
    const {profileInformationPermission, fieldListPermission, currentTab, tab} = props

    const userType = useRecoilValue(userTypeState)
    const {updateProfileInformationSetting, loading: updateLoading} = useUpdateProfileInformationSettingMutation()
    const {systemErrorNotification} = useAuthorize()

    const updateField = async (fieldList, position) => {
        try {
            await updateProfileInformationSetting({
                fieldList: fieldList,
                type: userType,
                position: position
            })
        } catch (e) {
            systemErrorNotification()
        }

    }

    const profileInformation = useRecoilValue(profileInformationSettingState)

    const [fields, setFields] = useState<any>({
        basicField: [],
        loginField: [],
        residenceField: [],
        additionalField: [],
        corporateField: [],
        registrationField: [],
        demoField: [],
    });

    useEffect(() => {
        formatProfileInformation(fields, setFields, profileInformation)
    }, [profileInformation])

    return (
        currentTab === tab ?
            (<div id={"setting-fields"}>
                <Row gutter={[32, 32]} style={{marginTop: 16, width: "100%"}}>
                    <Col span={6}>
                        {
                            currentTab === "leads" ?
                                <LeadsFieldCards updateField={updateField} fieldListPermission={fieldListPermission}
                                                 updateLoading={updateLoading}
                                                 profileInformationPermission={profileInformationPermission}
                                /> :
                                <FieldCards updateField={updateField} fieldListPermission={fieldListPermission}
                                            updateLoading={updateLoading}
                                            profileInformationPermission={profileInformationPermission}
                                />
                        }
                    </Col>
                    <Col span={7}>
                        <SettingCards updateField={updateField} fields={fields} setFields={setFields}
                                      profileInformationPermission={profileInformationPermission}
                                      updateLoading={updateLoading} currentTab={currentTab}/>
                    </Col>
                    <Col span={11}>
                        <UserPreview fields={fields} currentTab={currentTab}/>
                    </Col>
                </Row>
            </div>) : <></>
    )
}

export default SettingFields