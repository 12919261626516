import React, {useEffect} from "react";
import {useLocation} from 'react-router-dom';
import CrmUserDetail_1 from "./view/layout1";
import {useRecoilValue} from "recoil";
import {selfPermissionObj} from "../../../../../recoil_state";
import {getReferralSetting} from "../../../../api/graphql/referralSetting";
import {useGetUserByID} from "../../clients/detail/function/useQueryHooks";

function CrmUserDetail() {

    let layout = 1;
    let component;

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const permission = useRecoilValue<any>(selfPermissionObj);
    const id = searchParams.get('id');
    const {data: referralSetting} = getReferralSetting()

    const [data, loading, fetchUserByID, refetchUserByID, profileInformation] = useGetUserByID()

    useEffect(() => {
        if (id) {
            fetchUserByID(id, true)
        }
    }, [id]);

    switch (layout) {
        case 1:
            component = <CrmUserDetail_1
                data={data}
                profileInformation={profileInformation}
                permissionUser={permission?.user}
                referralSetting={referralSetting?.getReferralSetting}
                refetchUserByID={refetchUserByID}
                crmUserId={id}
            />;
            break;

        default:
            component = <CrmUserDetail_1
                data={data}
                profileInformation={profileInformation}
                permissionUser={permission?.user}
                referralSetting={referralSetting?.getReferralSetting}
                refetchUserByID={refetchUserByID}
                crmUserId={id}
            />;
            break;
    }

    return component;
}

export default CrmUserDetail