const clearTypeNameFromInputObject = (object) => {
  if(!object){
    return null
  }
  let temp = {
    ...object
  }
  delete temp.__typename
  
  return temp
}

const clearTypeNameFromInputList = (array) => {
  if(!array || !(array.length > 0)){
    return null
  }

  let result:any = []
  
  for(let i = 0; i < array.length; i++){
    let temp = {
      ...array[i]
    }
    delete temp.__typename
    result.push(temp)
  }

  return result
}

export { 
  clearTypeNameFromInputList,
  clearTypeNameFromInputObject
}