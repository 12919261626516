import {Form, Switch} from 'antd';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import FormErrors from './formErrors';

const SwitchFormItem = (props) => {
    const {
        layout,
        label,
        name,
        externalErrorMessage,
        onChange,
        hint,
        disabled,
        required,
        labelCol,
        wrapperCol,
        labelAlign,
        value,
        size,
        colon,
        className,
        loading,
        switchAlignRight
    } = props

    const { 
        register, 
        watch,
        setValue,
        formState: { touchedFields, isSubmitted, errors }
    } = useFormContext()

    const errorMessage = FormErrors.errorMessage(
        name,
        errors,
        touchedFields,
        isSubmitted,
        externalErrorMessage
    )
    
    const formHelperText = errorMessage || hint

    useEffect(() => {
        register(name)
    }, [register, name])

    useEffect(() => {
        if(value){
            setValue(name, value, {shouldValidate: true})
        }
    }, [value])
    
    return (
        <Form.Item
            {...layout}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            labelAlign={labelAlign}
            labelWrap={true}
            label={label}
            colon={colon}
            required={required}
            validateStatus={errorMessage?'error':'success'}
            help={formHelperText}
            className={className}
            style={switchAlignRight ? {textAlign: "right"} : {}}
        >
            <Switch
                id={name}
                checked={watch(name)}
                onChange={(e)=>{
                    setValue(name, e, {shouldValidate: true})
                    onChange && onChange(e)
                }}
                size={size}
                disabled={disabled}
                loading={loading}

            />
        </Form.Item>
    )
}

SwitchFormItem.defaultProps = {
    required: false,
    disabled: false,
    colon: false,
    size: "default",
    labelCol: {span: 6, offset: 0},
}

SwitchFormItem.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    size: PropTypes.string,
    externalErrorMessage: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    layout: PropTypes.object,
    hint: PropTypes.string,
    value: PropTypes.bool,
    loading: PropTypes.bool,
    labelAlign: PropTypes.string,
    className: PropTypes.string,
    wrapperCol: PropTypes.object,
    switchAlignRight: PropTypes.bool
}

export default SwitchFormItem