import React, {useEffect, useState} from "react";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {Button, Col, Form, Row, Tabs} from "antd";
import {CloseOutlined, PlusOutlined, SaveOutlined} from "@ant-design/icons";
import yupFormSchema from "../../../../../components/yup/yupFormSchema";
import {useAddMissionMutation, useUpdateMissionMutation} from "../../../../../api/graphql/mission";
import useAuthorize from "../../../../../_common/authorize";
import InputFormItem from "../../../../../components/form/inputFormItem";
import {verticalFormLayout} from "../../../../../components/form/formWrapper";
import SelectFormItem from "../../../../../components/form/selectFormItem";
import {
    missionDurationType,
    voucherAvailability,
    missionCodeType,
    missionEnd,
    conditionCalculationPeriod,
    autoCompleteExpiredMission,
} from "../function/enumerators";
import RadioGroupFormItem from "../../../../../components/form/radioGroupFormItem";
import {clean_value, getDataWithLanguage, handleOnChangeWithLanguage} from "../function/common";
import TextAreaInputFormItem from "../../../../../components/form/textAreaInputFormItem";
import MissionRewards from "./missionRewards";
import {getUserNetworkTreeDownline} from "../../../../../api/graphql/userNetworkTree";
import {convertGMTDateTimeToDisplayDateString, convertTimezoneToString, formatUserOptions} from "../../../../../function/_common";
import {userOptionsState} from "../../../../users/_common/state";
import {useRecoilValue} from "recoil";
import SelectDurationFormItem from "../../../../../components/form/selectDurationFormItem";
import DateRangePickerInputForm from "../../../../../components/form/dateRangePickerFormItem";
import dayjs from "dayjs";
import MissionAutomation from "./missionAutomation";
import {getMissionAutomationSettingById} from "../../../../../api/graphql/missionAutomationSetting";
import UploadSingleFormItem from "../../../../../components/form/uploadSingleFormItem";
import {notificationMessage} from "../../../../../../recoil_state";
import {useSetRecoilState} from "recoil";
import InputNumberFormItem from "../../../../../components/form/inputNumberFormItem";
import {getConfigByName} from "../../../../../../config";
import {BRAND_NAME} from "../../../../../../config/brandVariable";
import RandomStringFormItem from "../../../../../components/form/randomStringFormItem";
import moment from "moment";

const MissionForm = (props) => {
    const {
        closeFormModal,
        item,
        mode,
        languageData,
        product,
        multiTierSetting,
        voucher,
        voucherGroup,
        mission,
        missionGroup,
        trigger,
        userNetworkTreeDropdown,
    } = props;

    let backendURL = getConfigByName(BRAND_NAME).backendURL;
    const getDefaultLogoUrl = () => {
        return {
            fileList: [
                {
                    url: `${backendURL}/assets/icon/activitiesRewards/mission-reward.png`,
                    response: [{link: `${backendURL}/assets/icon/activitiesRewards/mission-reward.png`}],
                },
            ],
            path: `${backendURL}/assets/icon/activitiesRewards/mission-reward.png`,
        };
    };

    const {t} = useTranslation();
    const [activeTab, setActiveTab] = useState("0");
    const [errMsg, setErrMsg] = useState("");
    const [currentSelectUsers, setCurrentSelectUsers] = useState<any[]>(item?.network?.map((d) => d?._id) || []);
    const {networkTreeDownlineData} = getUserNetworkTreeDownline(currentSelectUsers);
    const {data: missionAutomationSetting} = getMissionAutomationSettingById(item?.automationId || "");
    const [selectedTrigger, setSelectedTrigger] = useState<any>([]);
    const [rewardValueOptions, setRewardValueOptions] = useState([{key: "all", value: "all", label: "All"}]);
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [imageLight, setImageLight] = useState(item?.imageLight ? item?.imageLight[0] : getDefaultLogoUrl());
    const [imageDark, setImageDark] = useState(item?.imageDark ? item?.imageDark[0] : getDefaultLogoUrl());
    const [conditionGroup, setConditionGroup] = useState<any>([
        {
            position: 0,
            logicalOperator: "and",
            criteriaItems: [
                {
                    comparisonOperator: "=",
                    reportId: "",
                    reportFieldId: "",
                    logicalOperator: "and",
                    position: 0,
                    value: "",
                },
            ],
        },
    ]);

    const [rewardValue, setRewardValue] = useState(
        item?.rewards?.map((item) => {
            return {
                reward: item?.reward?._id || undefined,
                rewardType: item?.rewardType || undefined,
                voucherExpiryType: item?.voucherExpiryType || undefined,
                voucherExpiryValue: item?.voucherExpiryValue || undefined,
                voucherExpiryDate: item?.voucherExpiryDate ? dayjs(item?.voucherExpiryDate) : undefined,
                voucherDurationType: item?.voucherDurationType || undefined,
                voucherDurationValue: item?.voucherDurationValue || undefined,
                voucherDurationDate: item?.voucherDurationDate ? dayjs(item?.voucherDurationDate) : undefined,
                voucherStartDate: item?.voucherStartDate ? dayjs(item?.voucherStartDate) : undefined,
                maxRedeem: item?.maxRedeem || undefined,
                isFixedReward: item?.isFixedReward !== null && item?.isFixedReward !== undefined ? item?.isFixedReward : false,
            };
        }) || [
            {
                reward: undefined,
                rewardType: undefined,
                voucherExpiryType: "unlimited",
                voucherExpiryValue: 30,
                voucherExpiryDate: dayjs(new Date()),
                voucherDurationType: "unlimited",
                voucherDurationValue: 30,
                voucherDurationDate: dayjs(new Date()),
                voucherStartDate: dayjs(new Date()),
                maxRedeem: 0,
                isFixedReward: true,
            },
        ]
    );

    useEffect(() => {
        if (missionAutomationSetting?.getMissionAutomationSettingById?.criteriaGroups) {
            setConditionGroup(
                missionAutomationSetting?.getMissionAutomationSettingById?.criteriaGroups.map((item) => {
                    let temp = {...item};
                    delete temp.__typename;
                    let temp2 = temp?.criteriaItems?.map((x) => {
                        let y = {...x};
                        delete y.__typename;
                        for (var i = 0; i < y?.displayName?.length; i++) {
                            let tempDisplayName = y?.displayName?.map((z) => {
                                let tempDisplayItem = {...z};
                                delete tempDisplayItem?.__typename;
                                let tempDisplayItemContent = {...tempDisplayItem?.content};
                                delete tempDisplayItemContent.__typename;
                                tempDisplayItem.content = tempDisplayItemContent;
                                return tempDisplayItem;
                            });
                            y.displayName = tempDisplayName;
                        }
                        return y;
                    });
                    temp.criteriaItems = temp2;
                    return temp;
                })
            );
        }
        if (missionAutomationSetting?.getMissionAutomationSettingById?.triggerGroups) {
            setSelectedTrigger(
                missionAutomationSetting?.getMissionAutomationSettingById?.triggerGroups.map((item) => {
                    let temp = {...item};
                    delete temp.__typename;
                    let temp2 = temp?.criteriaItems?.map((x) => {
                        let y = {...x};
                        delete y.__typename;
                        return y;
                    });
                    temp.criteriaItems = temp2;
                    return temp;
                })
            );
        }
    }, [missionAutomationSetting]);

    useEffect(() => {
        let result = [{key: "all", value: "all", label: "All"}];
        if (rewardValue && rewardValue.length > 0) {
            let selectableValue = rewardValue?.filter((item) => !item?.isFixedReward);
            let temp = selectableValue?.map((_, index) => {
                return {
                    key: index + 1,
                    label: "Any " + (index + 1),
                    value: (index + 1).toString(),
                };
            });
            if (temp && temp.length > 0) {
                temp = temp.filter((item) => item.key !== selectableValue.length);
                result.push(...temp);
            }
        }
        setRewardValueOptions(result);
    }, [rewardValue]);

    const [dataObj, setDataObj] = useState<any>({
        displayName: item?.displayName || [],
        description: item?.description || [],
        termAndCondition: item?.termAndCondition || [],
        rules: item?.rules || [],
        rewards: item?.rewards || [],
    });
    const networkTreeDownLineOptions = networkTreeDownlineData?.getUserNetworkTreeDownline?.data?.map((d) => {
        return formatUserOptions(d?.userid);
    });

    const generateRandomString = (length) => {
        let result = "";
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }

        return result;
    };

    const uploadOnChange = (e, field) => {
        try {
            if (e?.file?.status === "uploading") {
                switch (field) {
                    case "light":
                        setImageLight((prev) => {
                            return {
                                ...prev,
                                fileList: e?.fileList,
                            };
                        });
                        break;
                    case "dark":
                        setImageDark((prev) => {
                            return {
                                ...prev,
                                fileList: e?.fileList,
                            };
                        });
                        break;
                }
            }
            if (e?.file?.status === "done") {
                switch (field) {
                    case "light":
                        setImageLight((prev) => {
                            return {
                                ...prev,
                                fileList: e?.fileList,
                                path: e?.file?.response[0]?.link,
                            };
                        });
                        break;
                    case "dark":
                        setImageDark((prev) => {
                            return {
                                ...prev,
                                fileList: e?.fileList,
                                path: e?.file?.response[0]?.link,
                            };
                        });
                        break;
                }

                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: `${e?.file?.name} file uploaded successfully`,
                    key: "updateDesk",
                });
            } else if (e?.file?.status === "error") {
                switch (field) {
                    case "light":
                        setImageLight(null);
                        break;
                    case "dark":
                        setImageDark(null);
                        break;
                }

                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: `${e?.file?.name} file upload failed. - ${e?.file?.response}`,
                    key: "updateDesk",
                });
            }
        } catch (e) {
            systemErrorNotification();
        }
    };

    const mapItemToValue = (array) => {
        if (!array || !(array.length > 0)) {
            return [];
        }
        return array.map((item) => item?.id || item?._id);
    };

    const [initialValues] = useState(() => ({
        name: item?.name || "",
        displayName: item?.displayName || [],
        description: item?.description || [],
        termAndConditionEnabled: item?.termAndConditionEnabled || false,
        termAndCondition: item?.termAndCondition || [],
        rules: item?.rules || [],
        isRandomReward: item?.isRandomReward || false,
        rewardValue: item?.rewardValue || "all",
        rewards: item?.rewards || [],
        networkType: item?.networkType || "all",
        network: mapItemToValue(item?.network) || [],
        exclude: mapItemToValue(item?.exclude) || [],
        missionCodeType: item?.missionCodeType || "autoGenerate",
        missionCode: item?.missionCode || generateRandomString(6),
        isMissionCodeRequired: item?.isMissionCodeRequired || false,
        staffRank: item?.staffRank ? mapItemToValue(item?.staffRank) : [],
        availability: item?.availability || "all",
        automationId: item?.automationId || undefined,
        continuationMission: item?.continuationMission || false,
        continuationMissionId: item?.continuationMissionId ? item?.continuationMissionId?._id : undefined,
        missionEnd: item?.missionEnd || "byMissionDurationEndDateAndMissionEventDurationEndDate",
        product: item?.product || "all",
        productId: item?.productId ? mapItemToValue(item?.productId) : [],
        missionGroup: item?.missionGroup ? item?.missionGroup?._id : undefined,
        conditionCalculationPeriod: item?.conditionCalculationPeriod || "startFromEventDuration",
        autoClaim: item?.autoClaim || false,
        missionEventDurationStartDate: item?.missionEventDurationStartDate
            ? dayjs(convertGMTDateTimeToDisplayDateString(item?.missionEventDurationStartDate))
            : dayjs(new Date()),
        missionEventDurationEndDate: item?.missionEventDurationEndDate
            ? dayjs(convertGMTDateTimeToDisplayDateString(item?.missionEventDurationEndDate))
            : dayjs(new Date()),
        missionDurationType: item?.missionDurationType || "unlimited",
        missionDurationValue: item?.missionDurationValue || 30,
        missionDurationDate: item?.missionDurationDate ? dayjs(convertGMTDateTimeToDisplayDateString(item?.missionDurationDate)) : dayjs(new Date()),
        autoComplete: item?.autoComplete || false,
        missionCodeDigits: item?.missionCodeDigits || "6",
        autoCompleteExpiredMission: item?.autoCompleteExpiredMission || "autoCompleteMission",
    }));

    let schemaObj: any = {
        name: yupFormSchema.string("name", {required: true}),
        termAndConditionEnabled: yupFormSchema.boolean("termAndConditionEnabled"),
        networkType: yupFormSchema.string("networkType"),
        network: yupFormSchema.array("network"),
        exclude: yupFormSchema.array("exclude"),
        missionCodeType: yupFormSchema.string("missionCodeType", {required: true}),
        missionCode: yupFormSchema.string("missionCode", {required: true}),
        missionCodeDigits: yupFormSchema.number("missionCodeDigits"),
        isMissionCodeRequired: yupFormSchema.boolean("isMissionCodeRequired"),
        availability: yupFormSchema.string("availability"),
        continuationMission: yupFormSchema.boolean("continuationMission"),
        product: yupFormSchema.string("product", {required: true}),
        productId: yupFormSchema.array("productId"),
        missionDurationType: yupFormSchema.string("missionDurationType"),
        missionDurationValue: yupFormSchema.string("missionDurationValue"),
        autoComplete: yupFormSchema.boolean("autoComplete"),
        autoCompleteExpiredMission: yupFormSchema.string("autoCompleteExpiredMission"),
        isRandomReward: yupFormSchema.boolean("isRandomReward"),
        rewardValue: yupFormSchema.string("rewardValue"),
    };

    const handleOnchange = (e, fieldName, fieldType = "content", languageCode) => {
        handleOnChangeWithLanguage(e, fieldName, fieldType, languageCode, setDataObj);
    };

    const missionSchema = yup.object().shape(schemaObj);

    const form = useForm({
        resolver: yupResolver(missionSchema),
        mode: "all",
        defaultValues: initialValues,
    });

    const addRewards = () => {
        setRewardValue((prev) => [
            ...prev,
            {
                reward: undefined,
                rewardType: undefined,
                voucherExpiryType: "unlimited",
                voucherExpiryValue: 30,
                voucherExpiryDate: dayjs(new Date()),
                voucherDurationType: "unlimited",
                voucherDurationValue: 30,
                voucherDurationDate: dayjs(new Date()),
                voucherStartDate: dayjs(new Date()),
                maxRedeem: 0,
                isFixedReward: true,
            },
        ]);
    };
    const tabItems = languageData?.map((lang, index) => {
        return {
            key: index?.toString(),
            label: lang?.name,
            children: (
                <Row gutter={16}>
                    <Col className="gutter-row" xs={24} md={12}>
                        <InputFormItem
                            name={`dislayName_${lang.name}`}
                            label={"Display Name"}
                            placeholder={"Display Name"}
                            layout={verticalFormLayout}
                            onChange={(e) => handleOnchange(e, "displayName", "content", lang.code)}
                            value={getDataWithLanguage(dataObj, "displayName", "content", lang.code)}
                            disabled={mode === "view"}
                        />
                    </Col>
                    <Col className="gutter-row" xs={24} md={12}>
                        <InputFormItem
                            name={`description_${lang.name}`}
                            label={"Description"}
                            placeholder={"Description"}
                            layout={verticalFormLayout}
                            onChange={(e) => handleOnchange(e, "description", "content", lang.code)}
                            value={getDataWithLanguage(dataObj, "description", "content", lang.code)}
                            disabled={mode === "view"}
                        />
                    </Col>
                    <Col className="gutter-row" xs={24} md={12}>
                        <TextAreaInputFormItem
                            name={`rules_${lang.name}`}
                            label={"Rules"}
                            placeholder={"Rules"}
                            layout={verticalFormLayout}
                            onChange={(e) => handleOnchange(e, "rules", "content", lang.code)}
                            value={getDataWithLanguage(dataObj, "rules", "content", lang.code)}
                            disabled={mode === "view"}
                        />
                    </Col>
                    {form.getValues("termAndConditionEnabled") && (
                        <Col className="gutter-row" xs={24} md={12}>
                            <TextAreaInputFormItem
                                name={`termAndCondition_${lang.name}`}
                                label={"Term and Conditions"}
                                placeholder={"Term and Conditions"}
                                layout={verticalFormLayout}
                                onChange={(e) => handleOnchange(e, "termAndCondition", "content", lang.code)}
                                value={getDataWithLanguage(dataObj, "termAndCondition", "content", lang.code)}
                                disabled={mode === "view"}
                            />
                        </Col>
                    )}
                </Row>
            ),
        };
    });

    const onCancel = () => {
        closeFormModal && closeFormModal();
    };

    const {addMission} = useAddMissionMutation();
    const {updateMission} = useUpdateMissionMutation();
    const {systemErrorNotification} = useAuthorize();

    const onSubmit = async (values) => {
        try {
            setErrMsg("");
            if (values?.missionCodeType === "manual") {
                values.missionCodeDigits = values?.missionCode?.length;
            }
            const timezone = localStorage.getItem("timezone");
            let timezoneString = await convertTimezoneToString(timezone);
            values.missionDurationDate = moment(values.missionDurationDate?.format("YYYY-MM-DD") + "T23:59:59.000" + timezoneString);
            values.missionEventDurationStartDate = moment(
                values.missionEventDurationStartDate?.format("YYYY-MM-DD") + "T00:00:00.000" + timezoneString
            );
            values.missionEventDurationEndDate = moment(values.missionEventDurationEndDate?.format("YYYY-MM-DD") + "T23:59:59.000" + timezoneString);

            if (rewardValue && rewardValue.length > 0) {
                for (var i = 0; i < rewardValue.length; i++) {
                    if (!rewardValue[i].reward) {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: `mission.rewardCannotBeBlank`,
                            key: "updateMission",
                        });
                        return;
                    }
                }
            }

            const cleanValue = clean_value(values);
            const result = {
                ...cleanValue,
                displayName: dataObj?.displayName || [],
                description: dataObj?.description || [],
                termAndCondition: dataObj?.termAndCondition || [],
                rules: dataObj?.rules || [],
                rewards: rewardValue || [],
                automationConditionGroup: conditionGroup,
                automationTriggerGroup: selectedTrigger,
                imageLight: imageLight,
                imageDark: imageDark,
            };
            const response = mode === "add" ? await addMission(result) : await updateMission(item?._id, result);
            const {data: missionData} = response;
            const res = mode === "add" ? missionData?.addMission : missionData?.updateMission;
            if (res?.__typename === "BaseError") {
                let errMsg = t(res?.errKey);
                setErrMsg(errMsg);
            } else {
                closeFormModal && closeFormModal();
            }
        } catch (e: any) {
            systemErrorNotification();
        }
    };

    return (
        <div>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="mission-form-1">
                    {errMsg && <p style={{color: "red"}}>{errMsg}</p>}
                    <Row gutter={16}>
                        <Col className="gutter-row" xs={24} md={12}>
                            <UploadSingleFormItem
                                name={`imageLight`}
                                label={t("Image Light")}
                                layout={verticalFormLayout}
                                listType={"picture-card"}
                                mode={"activities"}
                                fileList={imageLight?.fileList}
                                onChange={(e) => uploadOnChange(e, "light")}
                                onRemove={(e) => setImageLight(null)}
                                disabled={mode === "view"}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} md={12}>
                            <UploadSingleFormItem
                                name={`imageDark`}
                                label={t("Light Dark")}
                                layout={verticalFormLayout}
                                listType={"picture-card"}
                                mode={"activities"}
                                fileList={imageDark?.fileList}
                                onChange={(e) => uploadOnChange(e, "dark")}
                                onRemove={(e) => setImageDark(null)}
                                disabled={mode === "view"}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} md={12}>
                            <InputFormItem
                                name={"name"}
                                label={"Mission Name"}
                                placeholder={"Mission Name"}
                                layout={verticalFormLayout}
                                disabled={mode === "view"}
                                required
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} md={12}>
                            <RadioGroupFormItem
                                name={"termAndConditionEnabled"}
                                label={t("Term and Conditions")}
                                options={[
                                    {label: t("Enabled"), value: true},
                                    {label: t("Disabled"), value: false},
                                ]}
                                layout={verticalFormLayout}
                                disabled={mode === "view"}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <SelectFormItem
                                name={"networkType"}
                                label={t("Network Type")}
                                options={[
                                    {
                                        label: t("network.all"),
                                        value: "all",
                                    },
                                    {
                                        label: t("network.partial"),
                                        value: "partial",
                                    },
                                    {
                                        label: t("network.onlyMe"),
                                        value: "own",
                                    },
                                ]}
                                mode={"single"}
                                placeholder={t("Network Type")}
                                disabled={mode === "view" || item?.missionTotalClaimed > 0 || item?.missionTotalCompleted > 0}
                                required
                            />
                        </Col>
                        {form.getValues("networkType") === "partial" && (
                            <>
                                <Col xs={24} md={12}>
                                    <SelectFormItem
                                        name={"network"}
                                        label={t("Network")}
                                        options={userNetworkTreeDropdown.map((item) => {
                                            return {
                                                label:
                                                    item?.userid?.username +
                                                    (item?.userid?.identificationNo ? " - " + item?.userid?.identificationNo : "") +
                                                    (item?.userid?.email ? " (" + item?.userid?.email + ")" : ""),
                                                value: item?.userid?._id,
                                                key: item?.userid?._id,
                                            };
                                        })}
                                        mode={"multiple"}
                                        onChange={(val) => setCurrentSelectUsers(val)}
                                        placeholder={t("Network")}
                                        disabled={mode === "view" || item?.missionTotalClaimed > 0 || item?.missionTotalCompleted > 0}
                                        optionFilterProp={"label"}
                                        showSearch
                                        required
                                    />
                                </Col>
                                <Col xs={24} md={12}>
                                    <SelectFormItem
                                        name={"exclude"}
                                        label={t("Exclude")}
                                        options={networkTreeDownLineOptions}
                                        placeholder={t("Exclude")}
                                        disabled={mode === "view" || item?.missionTotalClaimed > 0 || item?.missionTotalCompleted > 0}
                                        optionFilterProp={"label"}
                                        showSearch
                                        mode={"multiple"}
                                        optionLabelProp="selectedLabel"
                                    />
                                </Col>
                            </>
                        )}
                        <Col xs={24} md={12}>
                            <SelectFormItem
                                name={"missionCodeType"}
                                label={t("Mission Code Type")}
                                options={missionCodeType}
                                mode={"single"}
                                placeholder={t("Mission Code Type")}
                                disabled={mode === "view" || item?.missionTotalClaimed > 0 || item?.missionTotalCompleted > 0}
                                required
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} md={12}>
                            <RadioGroupFormItem
                                name={"isMissionCodeRequired"}
                                label={t("Is Mission Code required for claiming mission")}
                                options={[
                                    {label: t("Yes"), value: true},
                                    {label: t("No"), value: false},
                                ]}
                                layout={verticalFormLayout}
                                disabled={mode === "view" || item?.missionTotalClaimed > 0 || item?.missionTotalCompleted > 0}
                            />
                        </Col>
                        {form.getValues("missionCodeType") === "manual" && (
                            <Col className="gutter-row" xs={24} md={12}>
                                <InputFormItem
                                    name={"missionCode"}
                                    label={"Mission Code"}
                                    placeholder={"Mission Code"}
                                    layout={verticalFormLayout}
                                    disabled={mode === "view" || item?.missionTotalClaimed > 0 || item?.missionTotalCompleted > 0}
                                    required
                                />
                            </Col>
                        )}
                        {form.getValues("missionCodeType") === "autoGenerate" && (
                            <>
                                <Col xs={24} md={12}>
                                    <InputNumberFormItem
                                        name={"missionCodeDigits"}
                                        label={t("Mission Code Digits")}
                                        placeholder={t("Mission Code Digits")}
                                        disabled={mode === "view" || item?.missionTotalClaimed > 0 || item?.missionTotalCompleted > 0}
                                        layout={verticalFormLayout}
                                        style={{width: "100%"}}
                                    />
                                </Col>
                                <Col className="gutter-row" xs={24} md={12}>
                                    <RandomStringFormItem
                                        name={"missionCode"}
                                        label={"Mission Code"}
                                        placeholder={"Mission Code"}
                                        layout={verticalFormLayout}
                                        disabled={mode === "view" || item?.missionTotalClaimed > 0 || item?.missionTotalCompleted > 0}
                                        randomStringLength={form.getValues("missionCodeDigits")}
                                        required
                                    />
                                </Col>
                            </>
                        )}
                        <Col xs={24} md={12}>
                            <SelectFormItem
                                name={"staffRank"}
                                label={t("CRM User Rank")}
                                options={multiTierSetting}
                                mode={"multiple"}
                                placeholder={t("CRM User Rank")}
                                disabled={mode === "view" || item?.missionTotalClaimed > 0 || item?.missionTotalCompleted > 0}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <SelectFormItem
                                name={"availability"}
                                label={t("Availability")}
                                options={voucherAvailability}
                                mode={"single"}
                                placeholder={t("Availability")}
                                disabled={mode === "view" || item?.missionTotalClaimed > 0 || item?.missionTotalCompleted > 0}
                                required
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <SelectFormItem
                                name={"missionEnd"}
                                label={t("Mission End")}
                                options={missionEnd}
                                mode={"single"}
                                placeholder={t("missionEnd")}
                                disabled={mode === "view" || item?.missionTotalClaimed > 0 || item?.missionTotalCompleted > 0}
                                required
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} md={12}>
                            <RadioGroupFormItem
                                name={"continuationMission"}
                                label={t("Continuation Mission")}
                                options={[
                                    {label: t("True"), value: true},
                                    {label: t("False"), value: false},
                                ]}
                                layout={verticalFormLayout}
                                disabled={mode === "view" || item?.missionTotalClaimed > 0 || item?.missionTotalCompleted > 0}
                            />
                        </Col>
                        {form.getValues("continuationMission") && (
                            <Col className="gutter-row" xs={24} md={12}>
                                <SelectFormItem
                                    name={"continuationMissionId"}
                                    label={t("Continuation Mission Id")}
                                    options={mission.filter((item) => {
                                        let currentMissionGroup = form.getValues("missionGroup")?.toString();
                                        return item.missionGroup?.toString() !== currentMissionGroup;
                                    })}
                                    mode={"single"}
                                    placeholder={t("Continuation Mission Id")}
                                    disabled={mode === "view" || item?.missionTotalClaimed > 0 || item?.missionTotalCompleted > 0}
                                    allowClear
                                />
                            </Col>
                        )}
                        <Col xs={24} md={12}>
                            <SelectFormItem
                                name={"product"}
                                label={t("tradingAccount.product")}
                                options={[
                                    {
                                        label: t("All"),
                                        value: "all",
                                    },
                                    {
                                        label: t("Partial"),
                                        value: "partial",
                                    },
                                ]}
                                mode={"single"}
                                placeholder={t("Product Type")}
                                disabled={mode === "view" || item?.missionTotalClaimed > 0 || item?.missionTotalCompleted > 0}
                                required
                            />
                        </Col>
                        {form.getValues("product") === "partial" && (
                            <>
                                <Col xs={24} md={12}>
                                    <SelectFormItem
                                        name={"productId"}
                                        label={t("Product Id")}
                                        options={product}
                                        mode={"multiple"}
                                        placeholder={t("Product Id")}
                                        disabled={mode === "view" || item?.missionTotalClaimed > 0 || item?.missionTotalCompleted > 0}
                                        optionFilterProp={"label"}
                                        showSearch
                                    />
                                </Col>
                            </>
                        )}
                        <Col xs={24} md={12}>
                            <DateRangePickerInputForm
                                startName={"missionEventDurationStartDate"}
                                endName={"missionEventDurationEndDate"}
                                layout={verticalFormLayout}
                                wrapperCol={{span: 24}}
                                label={t("Mission Event Duration")}
                                requiredStar
                                disabled={mode === "view"}
                                startDisabled={mode === "view" || item?.missionTotalClaimed > 0 || item?.missionTotalCompleted > 0}
                                endDisabled={mode === "view"}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <SelectDurationFormItem
                                name={`missionDurationType`}
                                label={t("Mission Duration Type")}
                                options={missionDurationType}
                                mode={"single"}
                                placeholder={t("Mission Duration Type")}
                                disabled={mode === "view"}
                                endDateName={`missionDurationDate`}
                                endDateValue={form.getValues("missionDurationDate") || undefined}
                                daysName={`missionDurationValue`}
                                daysValue={form.getValues("missionDurationValue") || undefined}
                                requiredStar
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <SelectFormItem
                                name={"conditionCalculationPeriod"}
                                label={t("Condition Calculation Period")}
                                options={conditionCalculationPeriod}
                                mode={"single"}
                                placeholder={t("Condition Calculation Period")}
                                disabled={mode === "view" || item?.missionTotalClaimed > 0 || item?.missionTotalCompleted > 0}
                                required
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} md={12}>
                            <RadioGroupFormItem
                                name={"autoClaim"}
                                label={t("Claim Method")}
                                options={[
                                    {label: t("Auto Claim"), value: true},
                                    {label: t("Manual Claim"), value: false},
                                ]}
                                layout={verticalFormLayout}
                                disabled={mode === "view" || item?.missionTotalClaimed > 0 || item?.missionTotalCompleted > 0}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} md={12}>
                            <RadioGroupFormItem
                                name={"autoComplete"}
                                label={t("Mission Complete Method")}
                                options={[
                                    {label: t("Auto Complete"), value: true},
                                    {label: t("Manual Complete"), value: false},
                                ]}
                                layout={verticalFormLayout}
                                disabled={mode === "view"}
                            />
                        </Col>
                        {!form.getValues("autoComplete") && (
                            <Col className="gutter-row" xs={24} md={12}>
                                <SelectFormItem
                                    name={"autoCompleteExpiredMission"}
                                    label={t("When Mission Expired")}
                                    options={autoCompleteExpiredMission}
                                    mode={"single"}
                                    placeholder={t("When Mission Expired")}
                                    disabled={mode === "view"}
                                />
                            </Col>
                        )}
                        <Col xs={24} md={12}>
                            <SelectFormItem
                                name={"missionGroup"}
                                label={t("Mission Group")}
                                options={missionGroup}
                                placeholder={t("Mission Group")}
                                disabled={mode === "view" || item?.missionTotalClaimed > 0 || item?.missionTotalCompleted > 0}
                                optionFilterProp={"label"}
                                allowClear
                                showSearch
                            />
                        </Col>
                        <Col xs={24} md={24}>
                            <MissionAutomation
                                trigger={trigger}
                                selectedTrigger={selectedTrigger}
                                setSelectedTrigger={setSelectedTrigger}
                                conditionGroup={conditionGroup}
                                languageData={languageData}
                                setConditionGroup={setConditionGroup}
                                currentRecord={item}
                                mode={mode}
                            />
                        </Col>

                        <div className="poolCardBar">
                            {rewardValue && rewardValue.length > 0 && <h1 style={{display: "contents"}}>{t("Rewards")}</h1>}
                            <Button
                                onClick={() => addRewards()}
                                icon={<PlusOutlined />}
                                className="button-submit-1"
                                style={{float: "right"}}
                                disabled={mode === "view" || item?.missionTotalClaimed > 0 || item?.missionTotalCompleted > 0}
                            >
                                {t("common.addRewards")}
                            </Button>
                        </div>
                    </Row>

                    <Row gutter={16}>
                        <Col xs={24} md={18}>
                            <MissionRewards
                                mode={mode}
                                rewardValue={rewardValue}
                                setRewardValue={setRewardValue}
                                form={form}
                                currentRecord={item}
                                voucher={voucher}
                                voucherGroup={voucherGroup}
                            />
                        </Col>
                        {rewardValue && rewardValue.length > 0 && (
                            <Col xs={24} md={5} className="poolCard">
                                <Col className="gutter-row" xs={24}>
                                    <SelectFormItem
                                        name={"rewardValue"}
                                        label={t("Reward Value")}
                                        options={rewardValueOptions}
                                        placeholder={t("Reward Value")}
                                        disabled={mode === "view" || item?.missionTotalClaimed > 0 || item?.missionTotalCompleted > 0}
                                    />
                                </Col>
                            </Col>
                        )}
                    </Row>
                    <Tabs defaultActiveKey="0" items={tabItems} onChange={(activeKey) => setActiveTab(activeKey)} activeKey={activeTab} />
                    <Form.Item className="text-align-right" style={{paddingTop: "12px"}}>
                        {onCancel && (
                            <Button onClick={onCancel} icon={<CloseOutlined />} className="button-cancel-1">
                                {mode === "view" ? t("Close") : t("common.cancel")}
                            </Button>
                        )}
                        {mode !== "view" && (
                            <Button onClick={form.handleSubmit(onSubmit)} icon={<SaveOutlined />} className="button-submit-1">
                                {t("common.submit")}
                            </Button>
                        )}
                    </Form.Item>
                </form>
            </FormProvider>
        </div>
    );
};

export default MissionForm;
