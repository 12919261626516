import {gql, useMutation} from "@apollo/client";
import {useSetRecoilState} from "recoil";
import {useTranslation} from "react-i18next";
import {notificationMessage} from "../../../recoil_state";
import {ApolloClientQuery} from "./_apolloClient";

const GET_PUSH_NOTIFICATION_TEMPLATE_BY_ID = gql`
    query getPushNotificationTemplateById($id: String!) {
        getPushNotificationTemplateById(id: $id) {
            ... on PushNotificationTemplate {
                _id
                name
                dataArrayOfObject {
                    data
                    notification {
                        title
                        body
                        icon
                        image
                    }
                    longContent
                    language
                }
                targetPlatform
                type
                isActive
                isDeleted
                createdBy {username}
                updatedBy {username}
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getPushNotificationTemplateById = async (id?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_PUSH_NOTIFICATION_TEMPLATE_BY_ID,
            {
                id: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_PUSH_NOTIFICATION_TEMPLATE = gql`
    query getPushNotificationTemplate($filter: PushNotificationTemplateInput) {
        getPushNotificationTemplate(filter: $filter) {
            ... on PushNotificationTemplateArray {
                data {
                    _id
                    name
                    targetPlatform
                    dataArrayOfObject {
                        data
                        notification {
                            title
                            body
                            icon
                            image
                        }
                        longContent
                        language
                    }
                    isActive
                    isDeleted
                    createdBy {username}
                    updatedBy {username}
                    createdAt
                    updatedAt
                    type
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getPushNotificationTemplate = async ({filter}, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_PUSH_NOTIFICATION_TEMPLATE,
            {
                filter: filter,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_PUSH_NOTIFICATION_TEMPLATE = gql`
    mutation addPushNotificationTemplate($input: PushNotificationTemplateInput) {
        addPushNotificationTemplate(input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on PushNotificationTemplate {
                _id
                name
                dataArrayOfObject {
                    data
                    notification {
                        title
                        body
                        icon
                        image
                    }
                    longContent
                    language
                }
                targetPlatform
                type
                isActive
                isDeleted
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const addPushNotificationTemplate = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            ADD_PUSH_NOTIFICATION_TEMPLATE,
            {
                input: input,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UPDATE_PUSH_NOTIFICATION_TEMPLATE = gql`
    mutation updatePushNotificationTemplate($id: String!, $input: PushNotificationTemplateInput) {
        updatePushNotificationTemplate(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on PushNotificationTemplate {
                _id
                name
                dataArrayOfObject {
                    data
                    notification {
                        title
                        body
                        icon
                        image
                    }
                    longContent
                    language
                }
                targetPlatform
                type
                isActive
                isDeleted
                createdBy {username}
                updatedBy {username}
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const updatePushNotificationTemplate = async (id, input) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_PUSH_NOTIFICATION_TEMPLATE,
            {
                id: id,
                input: input,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const DELETE_PUSH_NOTIFICATION_TEMPLATE = gql`
    mutation deletePushNotificationTemplate($id: String!) {
        deletePushNotificationTemplate(id: $id) {
            ... on CrmTaskApproval {
                msg
            }
            ... on PushNotificationTemplate {
                _id
                isDeleted
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const deletePushNotificationTemplate = async (id) => {
    const variables = {
        id: id,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(DELETE_PUSH_NOTIFICATION_TEMPLATE, variables, true);

    return execute;
};


const TEST_SEND_PUSH_NOTIFICATION = gql`
    mutation sendPushNotification($input: PushNotificationInputMessage!) {
        sendPushNotification(input: $input) {
            ... on PushNotificationSendStatus {
                status
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useSendPushNotificationMutation = (): {
    sendPushNotification: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [sendPushNotificationMutation, {data, loading, error}] = useMutation(TEST_SEND_PUSH_NOTIFICATION);

        const sendPushNotification = (input) => {
            return sendPushNotificationMutation({
                variables: {
                    input: {
                        templateName: input?.templateName || null,
                        username: input?.username || null,
                        userid: input?.userid || null,
                        devicePlatform: input?.devicePlatform || null,
                        deviceToken: input?.deviceToken || null,
                        data: input?.data || null,
                        notification: input?.notification || null,
                        language: input?.language || "en",
                    },
                },
                update(cache, {data: {sendPushNotification}}) {
                    const typename = sendPushNotification?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(sendPushNotification?.errKey),
                            key: "sendEmail",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(sendPushNotification?.msg),
                            key: "sendEmail",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Sent Email Successfully"),
                            key: "sendEmail",
                        });
                    }
                },
            });
        };

        return {sendPushNotification, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {sendPushNotification: null, data: null, loading: false, error: err};
    }
};


export {
    getPushNotificationTemplateById,
    getPushNotificationTemplate,
    addPushNotificationTemplate,
    updatePushNotificationTemplate,
    deletePushNotificationTemplate,
    useSendPushNotificationMutation
};
