import React, {useCallback, useEffect, useState} from "react";
import {Col, Collapse, Row, Space, Switch} from "antd";
import {useTranslation} from "react-i18next";
import {useRecoilState} from "recoil";
import {PlusOutlined, SettingOutlined} from "@ant-design/icons";
import {useUpdateModuleSettingMutation} from "../../../../../../api/graphql/moduleSetting";
import {getLanguage} from "../../../../../../api/graphql/language";
import SettingFormModal from "./settingFormModal";
import {selfPermissionObj} from "../../../../../../../recoil_state";
import useAuthorize from "../../../../../../_common/authorize";
import {getEWalletSetting} from "../../../../../../api/graphql/eWalletSetting";

const ModuleTable = (props) => {
    const {item, loading} = props;
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const [mainModule, setMainModule] = useState<any[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [formData, setFormData] = useState<any>({});
    const [eWalletSetting, setEWalletSetting] = useState<any>({});

    const {languageData} = getLanguage({filter: null});
    const {updateModuleSetting} = useUpdateModuleSettingMutation();
    const {systemErrorNotification} = useAuthorize()

    const moduleSettingsPermission = selfPermission?.brandSetting?.moduleSettings;

    const preloadEWalletSetting = async () => {
        try {
            const {getEWalletSetting: response} = await getEWalletSetting()
            if (response?.__typename === "EWalletSetting") {
                setEWalletSetting(response)
            }
        } catch (e) {}
    }

    const updateModuleSettingFunction = async (id, input: any) => {
        try {
            await updateModuleSetting(id, input);
        } catch (e) {
            systemErrorNotification()
        }
    };

    const openUpdateModuleSettingModal = (value) => {
        setIsOpen(true);
        setFormData(value);
    };

    const closeUpdateModuleSettingModal = () => {
        setIsOpen(false);
        setFormData({});
    };

    const restructureModuleFunction = useCallback(async (items) => {
        try {
            const mainModule: any = [];
            const childMap: any = {};

            items?.forEach((item) => {
                const {_id, parent} = item;
                if (!parent || parent._id === _id) {
                    mainModule.push(item);
                } else {
                    if (!childMap[parent._id]) {
                        childMap[parent._id] = [];
                    }
                    childMap[parent._id].push(item);
                }
            });

            const result = mainModule
                .map((item, i) => {
                    const formattedItem = {...item};
                    const children = childMap[item._id] || [];
                    if (children.length > 0) {
                        formattedItem.children = children?.map((c) => {
                            return {
                                ...c,
                                key: c?._id,
                            };
                        });
                    } else {
                        delete formattedItem.children;
                    }
                    return formattedItem;
                })
                ?.map((d) => {
                    return {
                        ...d,
                        key: d?._id,
                    };
                });

            setMainModule(result);
        } catch (e) {
            systemErrorNotification()
        }
    }, [item]);

    useEffect(() => {
        restructureModuleFunction(item);
    }, [item, loading]);

    useEffect(() => {
        const matchedItem = item?.find(item => item?._id === formData?._id)
        if (matchedItem) {
            setFormData(matchedItem)
        }
    }, [item])

    useEffect(() => {
        preloadEWalletSetting()
    }, []);

    const renderModuleSetting = (col, index) => {
        const numberOfCard = mainModule?.length / col;
        const slicePosition = [index * numberOfCard, (index + 1) * numberOfCard];
        return (
            mainModule?.length > 0 &&
            mainModule?.slice(...slicePosition)?.map((d, i) => {
                if (d?.children) {
                    return (
                        <Row className={"module-card margin-top-0-75"} key={d?._id}>
                            <Collapse
                                expandIcon={() => <PlusOutlined/>}
                                style={{width: "100%"}}
                            >
                                <Collapse.Panel
                                    key={d?._id}
                                    header={<h4 className={"margin-bottom-0"}>{d?.name}</h4>}
                                    collapsible={"icon"}
                                    children={d?.children?.map((c) => {
                                        return (
                                            <Row justify={"space-between"} key={c?._id}>
                                                <p className={"margin-bottom-0-35 margin-top-0-35"}>
                                                    {c?.name}
                                                </p>
                                                <Space size={24} align={"start"}>
                                                    {moduleSettingsPermission?.edit?.edit && (
                                                        <a onClick={() => openUpdateModuleSettingModal(c)}>
                                                            <SettingOutlined/>
                                                        </a>
                                                    )}
                                                    <Switch
                                                        onClick={(val) => {
                                                            updateModuleSettingFunction(c._id, {
                                                                isEnabled: val,
                                                            });
                                                        }}
                                                        checked={c.isEnabled}
                                                        size={"small"}
                                                        disabled={
                                                            !c?.isEditable || !d?.isEditable ||
                                                            !moduleSettingsPermission?.edit?.edit
                                                        }
                                                    />
                                                </Space>
                                            </Row>
                                        );
                                    })}
                                    extra={
                                        <Space size={24} align={"start"}>
                                            {moduleSettingsPermission?.edit?.edit && (
                                                <a onClick={() => openUpdateModuleSettingModal(d)}>
                                                    <SettingOutlined/>
                                                </a>
                                            )}
                                            <Switch
                                                onClick={(val) => {
                                                    updateModuleSettingFunction(d?._id, {isEnabled: val});
                                                }}
                                                checked={d?.isEnabled}
                                                size={"small"}
                                                disabled={
                                                    !d?.isEditable ||
                                                    !moduleSettingsPermission?.edit?.edit
                                                }
                                            />
                                        </Space>
                                    }
                                />
                            </Collapse>
                        </Row>
                    );
                } else {
                    return (
                        <Row
                            justify={"space-between"}
                            className={"module-card margin-top-0-75"}
                            key={d?._id}
                        >
                            <h4 className={"margin-bottom-0"}>{d?.name}</h4>
                            <Space size={24}>
                                {moduleSettingsPermission?.edit?.edit && (
                                    <a onClick={() => openUpdateModuleSettingModal(d)}>
                                        <SettingOutlined/>
                                    </a>
                                )}
                                <Switch
                                    onClick={(val) => {
                                        updateModuleSettingFunction(d?._id, {isEnabled: val});
                                    }}
                                    checked={d?.isEnabled}
                                    size={"small"}
                                    disabled={!d?.isEditable ||
                                        !moduleSettingsPermission?.edit?.edit}
                                />
                            </Space>
                        </Row>
                    );
                }
            })
        );
    };

    const moduleSettingsColumns = [
        {span: 8, index: 0},
        {span: 8, index: 1},
        {span: 8, index: 2},
    ];

    return (
        <>
            <Row gutter={[24, 16]} style={{marginTop: "30px"}}>
                {mainModule && moduleSettingsColumns.map(({span, index}) => (
                    <Col span={span} key={index}>
                        {renderModuleSetting(3, index)}
                    </Col>
                ))}
            </Row>

            <SettingFormModal
                moduleData={formData}
                close={closeUpdateModuleSettingModal}
                updateModuleSetting={updateModuleSetting}
                languageList={languageData?.getLanguage?.data}
                updateModuleSettingFunction={updateModuleSettingFunction}
                isOpen={isOpen}
                eWalletSetting={eWalletSetting}
                refetchEWalletSetting={preloadEWalletSetting}
            />
        </>
    );
};

export default ModuleTable;
