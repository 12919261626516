import React from "react";
import {Modal} from "antd";
import {useTranslation} from "react-i18next";
import MissionForm from "./missionForm";

const MissionModal = (props) => {
    const {mode, item, isOpen, closeFormModal, userNetworkTreeDropdown} = props;
    const {t} = useTranslation();

    const getTitle = (): String => {
        switch (mode) {
            case "add":
                return `${t("Add New Mission")}`;
            case "view":
                return `${t("View Mission")}`;
            case "edit":
                return `${t("Edit Mission")}`;
            default:
                return `${t("Add New Mission")}`;
        }
    };

    return (
        <Modal
            getContainer="#mission"
            title={getTitle()}
            open={isOpen}
            width={"80%"}
            footer={null}
            closable={true}
            destroyOnClose
            onCancel={closeFormModal}
        >   
            <MissionForm 
                mode={mode} 
                item={item} 
                closeFormModal={closeFormModal} 
                languageData={props?.languageData}
                product={props.product}
                multiTierSetting={props.multiTierSetting}
                trigger={props.trigger}
                voucher={props.voucher}
                voucherGroup={props.voucherGroup}
                mission={props.mission}
                missionGroup={props.missionGroup}
                userNetworkTreeDropdown={userNetworkTreeDropdown}
            />
        </Modal>
    );
};

export default MissionModal;
