import React, {useEffect, useState} from "react";
import {Button, Row, Space, Tabs} from "antd";
import {Link} from "react-router-dom";
import ExportTableButton from "../../../../../components/table/function/exportTable";
import {useTranslation} from "react-i18next";
import CategoriesTable from "./component/categoriesTable";
import {useSetRecoilState} from "recoil";
import CategoryModal from "./component/categoryModal";

const ReportCategory_1 = (props) => {
    const {data, loading, error, reportsPermission, reportCategoryPermission, allReportData, reportOptions, setOrderBy} = props;
    const {t} = useTranslation();
    // const [isOpenSymbolCategoryForm, setIsOpenSymbolCategoryForm] =
    //   useState(false);
    const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
    const [mode, setMode] = useState("");
    const [selectedItem, setSelectedItem] = useState({});
    const tableName = "Report Category";

    const [exportData, setExportData] = useState<any>(null);
    const [exportColumn, setExportColumn] = useState<any>(null);

    const openAddModal = () => {
        setMode("Add");
        setIsCategoryModalOpen(true);
    };

    const openEditModal = (record) => {
        setMode("Edit");
        setIsCategoryModalOpen(true);
        setSelectedItem(record);
    };

    const closeModal = () => {
        setIsCategoryModalOpen(false);
        setSelectedItem({});
    };

    return (
        <div id="report-category">
            <Row justify="space-between">
                <h1>{t(tableName)}</h1>
                <Space>
                    {reportsPermission?.view && (
                        <Button className="marign-right-0-5">
                            <Link to="/reports">{t("Reports")}</Link>
                        </Button>
                    )}
                    {reportCategoryPermission?.add?.add && <Button onClick={() => openAddModal()}>{t("Add Category")}</Button>}
                    {/* <ExportTableButton
            tableData={exportData}
            tableColumn={exportColumn}
            tableName={tableName}
          /> */}
                </Space>
            </Row>
            <CategoriesTable
                data={data?.getReportingCategory?.data}
                totalCount={data?.getReportingCategory?.total}
                loading={loading}
                setFilter={setOrderBy}
                // setOffset={setOffset}
                // setLimit={setLimit}
                // limit={limit}
                setOrderBy={setOrderBy}
                openEditModal={openEditModal}
                setExportData={setExportData}
                setExportColumn={setExportColumn}
                tableName={tableName}
                reportCategoryPermission={reportCategoryPermission}
            />
            <CategoryModal
                mode={mode}
                item={selectedItem}
                category={data}
                isOpen={isCategoryModalOpen}
                close={closeModal}
                reportOptions={reportOptions}
            />
        </div>
    );
};

export default ReportCategory_1;
