import React, {useEffect, useMemo, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useStore} from "react-context-hook";
import {useTranslation} from "react-i18next";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {Avatar, Badge, Divider, Dropdown, Image, Layout, Menu, Popover, Row, Select, Switch} from "antd";
import {BellOutlined, GlobalOutlined, KeyOutlined, LogoutOutlined, UserOutlined} from "@ant-design/icons";
import {BsMoon, BsSun} from "react-icons/bs";
import {items} from "../../function/menuItem";
import {
    languageDataLoading,
    selfPermissionList,
    unreadAnnouncementCountState, unreadPushNotificationCountState,
    userLogoutState
} from "../../../../../recoil_state";
import {brandDetailsState, languageOptionsState} from "../../../../pages/users/_common/state";
import ChangePasswordModal from "./component/changePasswordModal";
import {useIsMobile} from "../../../../_common/layout";
import {BRAND_NAME} from "../../../../../config/brandVariable";
import PromoMaterialTable_1 from "../../../../pages/activities/promoMaterial/view/layout1/component/promoMaterialTable";
import CampaignTable_1 from "../../../../pages/activities/promoMaterial/view/layout1/component/campaignTable";
import Announcement from "../../../../pages/announcement";
import {getUserAnnouncementUnreadCount} from "../../../../api/graphql/announcement";

const {Header} = Layout;

const langMenuItems = [
    {key: "en-US", label: "English"},
    {key: "ms-MY", label: "Bm"},
];

const Header_1 = (props) => {
    const {t} = useTranslation()
    const [selfPermission] = useRecoilState<any>(selfPermissionList);
    const [changePasswordIsOpen, setChangePasswordIsOpen] = useState<any>(false);
    const brandDetails = useRecoilValue<any>(brandDetailsState);
    const languageList = useRecoilValue(languageOptionsState)
    const setUserLogout = useSetRecoilState(userLogoutState);
    const languageLoading = useRecoilValue(languageDataLoading);
    const [unreadAnnouncementCount, setUnreadAnnouncementCount] = useRecoilState(unreadAnnouncementCountState);
    const unreadPushNotificationCount = useRecoilValue(unreadPushNotificationCountState);
    const [theme, setTheme] = useStore("theme", "light");
    const userId = localStorage.getItem("userId")
    const [menuSelectedKeys, setMenuSelectedKeys] = useState<any[]>([]);
    const navigate = useNavigate();
    const isMobile = useIsMobile(768);

    const {i18n} = useTranslation();

    const onClickMenu = ({item, key, keyPath, domEvent}) => {
        setMenuSelectedKeys(keyPath);
    };

    // const handleLangMenuClick = (lng) => {
    //   i18n.changeLanguage(lng.key);
    // };

    const preload = async () => {
        const res = await getUserAnnouncementUnreadCount(userId);
        setUnreadAnnouncementCount(res?.getUserAnnouncementByUserId?.unreadTotal);
    };

    useEffect(() => {
        preload();
    }, []);

    function getUniqueHeaderSubcategory(array) {
        const uniqueNames = new Set();
        const resultArray: any = [];

        for (const item of array) {
            if (!uniqueNames.has(item.headerSubcategoryName)) {
                uniqueNames?.add(item.headerSubcategoryName);
                resultArray?.push({...item, label: <Link to={item?.path}>{t(item?.headerSubcategoryName)}</Link>});
            }
        }

        return resultArray;
    }

    const tabItems: any = [
        {
            label: t("Announcement"),
            size: "small",
            key: "announcement",
            children: <PromoMaterialTable_1/>,
        },
        {
            label: t("Push Notifications"),
            size: "small",
            key: "notifications",
            children: <CampaignTable_1/>,
        }
    ];

    const languageOnChange = (e) => {
        localStorage.setItem("lang", e)
        i18n.changeLanguage(e);

        window.location.reload();
    };

    const languageMenu = (
        <Row align={"middle"} style={{marginTop: 2}}>
            <GlobalOutlined style={{color: "grey"}}/>
            <Select
                popupMatchSelectWidth={false}
                suffixIcon={null}
                defaultValue={localStorage.getItem("lang")}
                onChange={(e) => languageOnChange(e)}
                options={languageList}
            />
        </Row>
    );

    const announcementDropdown = (
        <Row align={"middle"} style={{paddingInline: "16px", marginTop: 2}}>
            <Badge count={unreadAnnouncementCount + unreadPushNotificationCount} size={"small"}>
                <Popover
                    id={"announcementPopover"}
                    trigger={"click"}
                    className={"clickable"}
                    content={<Announcement isHeader/>}
                    placement={"bottom"}
                    getPopupContainer={() => document.getElementById("root") as HTMLElement}
                >
                    <BellOutlined style={{fontSize: "14px"}}/>
                </Popover>
            </Badge>
        </Row>
    );

    const menuItems = [
        {
            key: "changePassword",
            icon: <KeyOutlined />,
            label: t("common.changePassword"),
            onClick: () => setChangePasswordIsOpen(true),
        },
        {
            key: "logOut",
            icon: <LogoutOutlined />,
            label: t("common.logOut"),
            onClick: () => setUserLogout(true),
        },
    ];

    const permittedMenuItems: any = useMemo(() => {
        const menuItems = items
            ?.filter((v: any) => {
                if (!v?.permission) {
                    return true;
                }
                return v.permission.some((p) => selfPermission?.includes(p));
            })
            .map((v: any) => {
                if (!v?.children) {
                    return {...v, label: <Link to={v?.path}>{t(v.lang)}</Link>};
                }
                const amendedChildren = v?.children?.filter((child) =>
                    child?.permission?.some((permission) => {
                        return !permission || selfPermission?.includes(permission);
                    })
                );

                return {...v, label: t(v.lang), children: getUniqueHeaderSubcategory(amendedChildren)};
            });

        // Hard code automation setting for Antos and Exco
        if (!["antospnc", "antospncOld", "blueocean", "excotrades", "ridgewaymarkets", "fundamarkets", "minosfutures", "cronoscap", ].includes(BRAND_NAME) && menuItems) {
            menuItems.forEach(item => {
                if (item.children) {
                    item.children = item.children.filter(child => child.headerSubcategoryName !== "Automation Setting");
                }
            });
        }

        return menuItems
    }, [items, selfPermission, languageLoading]);

    const processMenuItems = (keyFilter) =>
        permittedMenuItems
            ?.filter((menu) => (keyFilter === "setting" ? menu?.key === "setting" : menu?.key !== "setting"))
            ?.map((item) => ({
                ...item,
                children: item?.children?.map(({ headerSubcategoryName, ...rest }) => rest),
            }));

    const leftMenuItems = processMenuItems("not-setting");
    const rightMenuItems = processMenuItems("setting");

    const updateCurrentMenuKeys = () => {
        try {
            let updatedMenuKeys: any[] = [];
            permittedMenuItems.forEach((parent) => {
                if (parent?.path === location.pathname) {
                    updatedMenuKeys = [parent?.key];
                }
                parent?.children?.forEach((child) => {
                    if (child?.path === location.pathname) {
                        updatedMenuKeys = [child?.key, parent?.key];
                    }
                });
            });
            if (location.pathname.startsWith("/settings")) {
                updatedMenuKeys?.push("setting");
            }
            setMenuSelectedKeys(updatedMenuKeys);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        updateCurrentMenuKeys();
    }, [location.pathname, permittedMenuItems]);

    function removeCircularReferences() {
        const seen = new WeakSet();
        return (key, value) => {
            if (typeof value === "object" && value !== null) {
                if (seen.has(value)) {
                    return; // Ignore circular reference
                }
                seen.add(value);
            }
            return value;
        };
    }

    return (
        <Header className="layout-header">
            <Row className="sider-header" wrap={false} justify={"space-between"}
                 style={{width: isMobile ? "max-content" : "unset"}}>
                <Row>
                    <Image
                        src={theme === "light" ? brandDetails?.companyLogoSmall : brandDetails?.companyLogoInverseSmall}
                        height={35}
                        preview={false}
                    />
                    <Menu
                        triggerSubMenuAction={isMobile ? "click" : "hover"}
                        onClick={onClickMenu}
                        selectedKeys={menuSelectedKeys}
                        mode="horizontal"
                        items={leftMenuItems}
                        style={{borderBottom: "none"}}
                        disabledOverflow={!isMobile}
                    />
                </Row>
                <Row align={"middle"} className={"header-action-btn"}>
                    <Menu
                        onClick={onClickMenu}
                        selectedKeys={menuSelectedKeys}
                        mode="horizontal"
                        triggerSubMenuAction={"click"}
                        items={rightMenuItems?.map((d) => ({...d, label: d?.icon, icon: null}))}
                        style={{borderBottom: "none", flexGrow: 1}}
                        disabledOverflow={!isMobile}
                    />
                    <Divider type={"vertical"}/>
                    {announcementDropdown}
                    <Divider type={"vertical"}/>
                    {languageMenu}
                    <Divider type={"vertical"}/>
                    <Switch
                        defaultChecked
                        checkedChildren={<BsSun/>}
                        unCheckedChildren={<BsMoon/>}
                        onChange={() => setTheme(theme === "light" ? "dark" : "light")}
                    />
                    <Divider type={"vertical"}/>
                    <div>
                        <span
                            style={{
                                fontWeight: 500,
                                fontSize: 12,
                            }}
                        >
                            {localStorage.getItem("g_username") || localStorage.getItem("username")}
                        </span>
                        <Dropdown menu={{items: menuItems}} trigger={["click"]} arrow={{pointAtCenter: true}}>
                            <Avatar size={32} icon={<UserOutlined/>} style={{cursor: "pointer", marginLeft: 8}}/>
                        </Dropdown>
                    </div>
                </Row>
            </Row>
            <ChangePasswordModal isOpen={changePasswordIsOpen} setIsOpen={setChangePasswordIsOpen}/>
        </Header>
    );
};

export default Header_1;
