import {
    Button,
    Input,
    InputNumber,
    Select,
    Space,
    Row,
    DatePicker,
} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import React from "react";
import dayjs, {Dayjs} from "dayjs";

const {Search} = Input;
const {RangePicker} = DatePicker;

const getReportColumnSearchProps = (filter, reportFieldName) => {
    let input;
    const filteredValue = filter?.find((f) => f?.fieldName === reportFieldName)?.value || null
    return {
        filteredValue: filteredValue,
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => {
            return (
                <div style={{padding: 8}}>
                    <Search
                        ref={(node) => {
                            input = node;
                        }}
                        placeholder={`Search`}
                        value={selectedKeys}
                        onChange={(e) => {
                            setSelectedKeys([e?.target?.value]);
                        }}
                        onPressEnter={() => confirm()}
                        onSearch={() => confirm()}
                        style={{width: 150, marginBottom: 8, display: "block"}}
                    />
                    <Button
                        onClick={() => {
                            setSelectedKeys([]);
                            confirm();
                        }}
                        style={{width: 90, display: " block", marginLeft: "auto"}}
                    >
                        Reset
                    </Button>
                </div>
            )
        },
        onFilterDropdownOpenChange: (open) => {
            if (open) {
                setTimeout(() => {
                    input.focus();
                }, 100);
            }
        },
        filterIcon: (filtered) => (
            <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}}/>
        ),
    };
};

const getReportBooleanFilterProps = (filter, reportFieldName) => {
    const filteredValue = filter?.find((f) => f?.fieldName === reportFieldName)?.value || null
    return {
        filteredValue: filteredValue,
        filters: [
            {text: "True", value: true},
            {text: "False", value: false},
        ],
        filterMultiple: false,
    };
};

let num1,
    num2,
    op1 = ">",
    op2 = "<";
const getReportDigitsFilterProps = (min, max, step, filter, reportFieldName) => {
    const filteredValue = filter?.find((f) => f?.fieldName === reportFieldName)?.value || null
    return {
        filteredValue: filteredValue,
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => (
            <Space direction={"vertical"} style={{padding: 8}}>
                <Space.Compact block>
                    <Select
                        options={[
                            {value: ">", label: "Greater than"},
                            {value: ">=", label: "Greater than or equal to"},
                            {value: "<", label: "Less than"},
                            {value: "<=", label: "Less than or equal to"},
                            {value: "=", label: "Equal to"},
                            {value: "!=", label: "Not equal to"},
                        ]}
                        defaultValue={op1}
                        showArrow={false}
                        onSelect={(value) => (op1 = value)}
                        style={{width: 200, textAlign: "center"}}
                    />
                    <InputNumber
                        className="site-input-left"
                        min={min}
                        max={max}
                        step={step}
                        value={selectedKeys}
                        onChange={(value) => (num1 = value)}
                        style={{width: 50}}
                    />
                </Space.Compact>
                <Space.Compact block className={"margin-bottom-0-75"}>
                    <Select
                        options={[
                            {value: ">", label: "Greater than"},
                            {value: ">=", label: "Greater than or equal to"},
                            {value: "<", label: "Less than"},
                            {value: "<=", label: "Less than or equal to"},
                            {value: "=", label: "Equal to"},
                            {value: "!=", label: "Not equal to"},
                        ]}
                        defaultValue={op2}
                        showArrow={false}
                        onSelect={(value) => (op2 = value)}
                        style={{width: 200, textAlign: "center"}}
                    />
                    <InputNumber
                        className="site-input-right"
                        min={min}
                        max={max}
                        step={step}
                        value={selectedKeys}
                        onChange={(value) => (num2 = value)}
                        style={{width: 50}}
                    />
                </Space.Compact>
                <Row justify={"space-between"}>
                    <Button
                        type={"link"}
                        onClick={() => {
                            (num1 = null), (num2 = null), (op1 = ""), (op2 = "");
                            setSelectedKeys([]);
                            confirm();
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        onClick={() => {
                            const filterValue = op2
                                ? `${op1} ${num1} ${op2} ${num2}`
                                : `${op1} ${num1}`;
                            setSelectedKeys([filterValue]);
                            confirm();
                        }}
                    >
                        Confirm
                    </Button>
                </Row>
            </Space>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}}/>
        ),
    };
};

const getReportDigitsExactFilterProps = (min, max, step, filter, reportFieldName) => {
    const filteredValue = filter?.find((f) => f?.fieldName === reportFieldName)?.value || null
    return {
        filteredValue: filteredValue,
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => (
            <Space direction={"vertical"} style={{padding: 8}}>
                <InputNumber
                    min={min}
                    max={max}
                    step={step}
                    value={selectedKeys}
                    onChange={(value) => setSelectedKeys([value?.toString()])}
                    style={{width: 150}}
                />
                <Row justify={"space-between"}>
                    <Button
                        type={"link"}
                        onClick={() => {
                            setSelectedKeys([]);
                            confirm();
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        onClick={() => {
                            confirm();
                        }}
                    >
                        Confirm
                    </Button>
                </Row>
            </Space>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}}/>
        ),
    };
};

const getReportDateRangeFilterProps = (filter, reportFieldName) => {
    let filtered;
    const filteredValue = filter?.find((f) => f?.fieldName === reportFieldName)?.value || null
    const rangePresets: {
        label: string;
        value: [Dayjs, Dayjs];
    }[] = [
        {label: "Today", value: [dayjs().add(0, "d"), dayjs()]},
        {label: "Yesterday", value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")]},
        {label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()]},
        {label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()]},
        {label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()]},
        {
            label: "This Month",
            value: [dayjs().startOf("month"), dayjs().endOf("month")],
        },
        {
            label: "Last Month",
            value: [
                dayjs().subtract(1, "month").startOf("month"),
                dayjs().subtract(1, "month").endOf("month"),
            ],
        },
    ];

    return {
        filteredValue: filteredValue,
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => (
            <Space direction={"vertical"} style={{padding: 8}}>
                <RangePicker
                    presets={rangePresets}
                    // onChange={(dates: any | (Dayjs | null)[], dateStrings: string[]) =>
                    onChange={(dates: any, dateStrings: any[]) =>
                        setSelectedKeys(["date", dateStrings])
                    }
                />
                <Row justify={"space-between"}>
                    <Button
                        onClick={() => {
                            confirm();
                            filtered = true;
                        }}
                    >
                        Confirm
                    </Button>
                    <Button
                        type={"link"}
                        onClick={() => {
                            setSelectedKeys(["date", "", ""]);
                            filtered = false;
                            confirm();
                        }}
                    >
                        Reset
                    </Button>
                </Row>
            </Space>
        ),
        filterIcon: () => (
            <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}}/>
        ),
    };
};

export {
    getReportColumnSearchProps,
    getReportBooleanFilterProps,
    getReportDigitsFilterProps,
    getReportDigitsExactFilterProps,
    getReportDateRangeFilterProps,
};
