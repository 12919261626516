import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Modal, Pagination, Popconfirm, Row, Space, Tabs, Timeline, Tooltip, Upload} from "antd";
import {useDeleteFollowUp, useUpdateFollowUp} from "../../../../../../../api/graphql/followUp";
import {useTranslation} from "react-i18next";
import {disableDateTime} from "../../../../../_common/function";
import useAuthorize from "../../../../../../../_common/authorize";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import TextAreaInputFormItem from "../../../../../../../components/form/textAreaInputFormItem";
import {verticalFormLayout} from "../../../../../../../components/form/formWrapper";
import DatePickerInputForm from "../../../../../../../components/form/datePickerFormItem";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import yupFormSchema from "../../../../../../../components/yup/yupFormSchema";
import {getConfigByName} from "../../../../../../../../config";
import {BRAND_NAME} from "../../../../../../../../config/brandVariable";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import {formatDateTimeTimezone} from "../../../../../../../function/_common";

const ActivityLogCard = (props) => {
    const {permissionUser, followUp, refetchFollowUp} = props
    const {t} = useTranslation()
    const [itemFollowUp, setItemFollowUp] = useState<any[]>()
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [isOpen, setIsOpen] = useState<boolean>()
    const [editingItem, setEditingItem] = useState<any>()
    const {disabledDate} = disableDateTime()
    const {systemErrorNotification} = useAuthorize()
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const {updateFollowUp, loading} = useUpdateFollowUp()
    const {deleteFollowUp} = useDeleteFollowUp()

    const [fileList, setFileList] = useState<any>([])
    const brandConfig = getConfigByName(BRAND_NAME);

    useEffect(() => {
        const files = editingItem?.attachment?.map(d => {
            const lastIndex = d.lastIndexOf("_");

            return {
                uid: d,
                name: d?.slice(0, lastIndex)?.replace(`${brandConfig?.backendURL}/assets/followUp/`, ""),
                url: d,
            }
        })

        setFileList(files)
    }, [editingItem])

    const initialValues = {
        followUpDateTime: editingItem?.followUpDateTime ? dayjs(editingItem?.followUpDateTime) : undefined,
        description: editingItem?.description
    }

    const yupSchema = yup.object().shape({
        description: yupFormSchema.string("description", {required: true}),
        followUpDateTime: yupFormSchema.string("followUpDateTime"),
    });

    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
        defaultValues: initialValues
    });

    const updateFollowUpOnSubmit = async (value) => {
        try {
            Object.keys(value).forEach(key => {
                if (value[key] === undefined) {
                    delete value[key];
                } else if (key === "followUpDateTime") {
                    value[key] = value?.followUpDateTime ? formatDateTimeTimezone(value?.followUpDateTime, "formSubmit") : null
                }

                if (fileList) {
                    value.attachment = fileList?.map(d => d?.url || d?.response?.[0]?.link)
                }
            });

            const {updateFollowUp: response} = await updateFollowUp(editingItem?._id, value)

            if (response?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(updateFollowUp?.errKey),
                    key: "updateFollowUp",
                });
            } else if (response?.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(updateFollowUp?.msg),
                    key: "updateFollowUp",
                });
            } else {
                refetchFollowUp()
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Update Follow Up Successfully"),
                    key: "updateFollowUp",
                });
            }

            setIsOpen(false)

        } catch (e) {
            console.log(e);
            systemErrorNotification();
        }
    }

    const deleteFollowUpHandler = async (id) => {
        try {
            const {deleteFollowUp: response} = await deleteFollowUp(id)

            if (response?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(deleteFollowUp?.errKey),
                    key: "deleteFollowUp",
                });
            } else if (response?.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(deleteFollowUp?.msg),
                    key: "deleteFollowUp",
                });
            } else {
                refetchFollowUp()
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Delete Follow Up Successfully"),
                    key: "deleteFollowUp",
                });
            }
        } catch (e) {

        }
    }

    const formatItems = async (data) => {
        const paginatedData = data?.slice((currentPage - 1) * pageSize, currentPage * pageSize);
        return await paginatedData?.map((d, i) => {
            return {
                children: (
                    <Card size={"small"} key={d?._id}>
                        <Row justify={"space-between"} align={"middle"}>
                            <Col flex={2}>
                                <h4 className={"margin-bottom-0"}>{d?.type === "email" ? `Email - ${d?.emailTemplateId?.name}` : d?.description?.length > 30 ? d?.description?.slice(0, 27)?.padEnd(30, ".") : d?.description}</h4>
                                <p className={"margin-bottom-0"}>
                                    <small>{formatDateTimeTimezone(d?.followUpDateTime, null, "YYYY-MM-DD HH:mm")}</small></p>
                            </Col>
                            <Col className={"text-align-right"}>
                                {d?.type !== "email" && permissionUser?.leads?.edit?.followUp &&
                                    <Space>
                                        <Tooltip title={t("common.update")}>
                                            <Button size={"small"} type={"link"} onClick={() => {
                                                setEditingItem(d)
                                                setIsOpen(true)
                                            }} icon={<EditOutlined/>}/>
                                        </Tooltip>
                                        <Tooltip title={t("common.delete")}>
                                            <Popconfirm
                                                placement="left"
                                                title={t("user.popconfirm.delete_follow_up")}
                                                description={t("user.popconfirm.description.delete_follow_up")}
                                                onConfirm={async () => {
                                                    await deleteFollowUpHandler(d?._id)
                                                }}
                                                okText={t("common.yes")}
                                                cancelText={t("common.no")}
                                            >
                                                <Button icon={<DeleteOutlined/>} type={"link"} danger size={"small"}/>
                                            </Popconfirm>
                                        </Tooltip>
                                    </Space>
                                }
                                <div>
                                    <Space className={"follow-up-created-by"}>
                                        <p className={"margin-bottom-0"}>{t("common.by")} {d?.createdBy?.username}</p>
                                        <p className={"margin-bottom-0"}>{formatDateTimeTimezone(d?.createdAt)}</p>
                                    </Space>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                )
            }
        })
    }

    const handlePaginationChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    useEffect(() => {
        formatItems(followUp)?.then(data => setItemFollowUp(data));
    }, [followUp, currentPage, pageSize]);

    const tabItems = [
        {
            key: "1",
            label: t("user.followUp"),
            children: <>
                <Timeline items={itemFollowUp} style={{marginTop: "2rem"}}/>
                <div style={{display: "flex", justifyContent: "center"}}>
                    {
                        followUp?.length > 0 &&
                        <Pagination
                            current={currentPage}
                            pageSize={pageSize}
                            total={followUp?.length}
                            onChange={handlePaginationChange}
                        />
                    }
                </div>
            </>
        },
        {
            key: "2",
            label: t("user.operationHistory"),
            children: <div style={{marginTop: "2rem"}}/>
        },
        {
            key: "3",
            label: t("user.tradingHistory"),
            children: <div style={{marginTop: "2rem"}}/>
        }
    ]

    const validateMessages = {
        required: '${label} is a required field!',
    };

    useEffect(() => {
        form.reset(initialValues)
    }, [isOpen])

    return (
        <>
            {permissionUser?.leads?.edit?.followUp &&
                <Modal
                    open={isOpen}
                    onCancel={() => setIsOpen(false)}
                    onOk={form.handleSubmit(updateFollowUpOnSubmit)}
                    okText={t("common.update")}
                    cancelText={t("common.cancel")}
                    okButtonProps={{loading: loading}}
                >
                    <FormProvider {...form}>
                        <Form onFinish={form.handleSubmit(updateFollowUpOnSubmit)} validateMessages={validateMessages}>
                            <TextAreaInputFormItem
                                name={"description"}
                                label={t("Description")}
                                placeholder={t("Description")}
                                layout={verticalFormLayout}
                                value={editingItem?.description}
                                rows={3}
                                required
                            />
                            <DatePickerInputForm
                                name={"followUpDateTime"}
                                disabledDate={disabledDate}
                                labelCol={{span: 24}}
                                label={t("Set up follow up time")}
                                layout={verticalFormLayout}
                                defaultValue={editingItem?.followUpDateTime ? formatDateTimeTimezone(editingItem?.followUpDateTime, "formValue") : undefined}
                                format={"YYYY-MM-DD HH:mm"}
                                showTime={{format: "HH:mm"}}
                                getPopupContainer={triggerNode => triggerNode.parentElement}
                            />
                            <Form.Item
                                label={t("common.attachments")}
                                labelCol={{span: 24}}
                                labelAlign={"left"}
                            >
                                <Upload
                                    action={`${brandConfig?.backendURL}/api/upload/followUp/document`}
                                    onChange={e => setFileList(e.fileList)}
                                    multiple
                                    fileList={fileList}
                                >
                                    <Button>{t("common.upload")}</Button>
                                </Upload>
                            </Form.Item>
                        </Form>
                    </FormProvider>
                </Modal>
            }
            <Card style={{width: "100%"}}>
                <Tabs
                    defaultActiveKey="followUp"
                    items={tabItems}
                />
            </Card>
        </>
    )
}

export default ActivityLogCard