import {Col, Form, Row, Select} from "antd"
import React, {useEffect, useMemo, useState} from "react"
import {useTranslation} from "react-i18next"
import {useRecoilState, useRecoilValue} from "recoil"
import dayjs from "dayjs"
import SelectFormItem from "../../../../../../components/form/selectFormItem"
import {verticalFormLayout} from "../../../../../../components/form/formWrapper"
import DateRangePickerInputForm from "../../../../../../components/form/dateRangePickerFormItem"
import {reportingAllState, reportingCategoryState} from "../../../../../reports/_general/state"
import {getReportingAll, getReportingFieldsByReportId} from "../../../../../../api/graphql/reporting"
import {getUserNetworkTreeDropdownBasedOnPermission} from "../../../../../../api/graphql/userNetworkTree"
import {deskOptionsState, salesRepNetworkOptionsState} from "../../../../../users/_common/state"
import {formatUserNetworkOptions} from "../../../../../../function/_common"
import RadioGroupFormItem from "../../../../../../components/form/radioGroupFormItem"
import {getReportingCategoryDropdown} from "../../../../../../api/graphql/reportingCategory"

const ReportTableForm = (props) => {
    const {form, mode, reportTableObjectData} = props
    const {t} = useTranslation()
    const [reportingAll, setReportingAll] = useRecoilState<any>(reportingAllState)
    const [reportCategories, setReportCategories] = useRecoilState<any>(reportingCategoryState)
    const [reportFields, setReportFields] = useState([])
    const [selectedReportCategoryID, setSelectedReportCategoryID] = useState("")
    const [selectedReportID, setSelectedReportID] = useState("")
    const [dateRangeType, setDateRangeType] = useState("")
    const deskOptions = useRecoilValue(deskOptionsState)
    const salesRepNetworkOptions = useRecoilValue(salesRepNetworkOptionsState)
    const [reportViewMode, setReporViewMode] = useState("")

    const [filterType, setFilterType] = useState("")
    const [filterTypeId, setFilterTypeId] = useState([])
    const [userNetworkOptions, setUserNetworkOptions] = useState<any>([])

    const {data: userNetworkTree} = getUserNetworkTreeDropdownBasedOnPermission({permission: "reports:reports:view"})

    const getAllReports = async () => {
        try {
            const response = await getReportingAll()
            const list = response?.getReportingAll?.data
            if (list) {
                setReportingAll(list)
            }
        } catch (error) {}
    }

    const getReportCategory = async () => {
        try {
            const response = await getReportingCategoryDropdown()
            const list = response?.getReportingCategoryDropdown?.data || []
            const sortedList = [...list].sort((a, b) => a?.sortingNumber - b?.sortingNumber)
            setReportCategories(sortedList)
        } catch (error) {}
    }

    const getReportFields = async () => {
        try {
            if (selectedReportID) {
                const response = await getReportingFieldsByReportId(selectedReportID)
                if (response?.getReportingFieldsByReportId?.__typename === "ReportingDataAry") {
                    setReportFields(response?.getReportingFieldsByReportId?.data)
                }
            }
        } catch (err) {
            console.log(err)
        } finally {
        }
    }

    const onFilterTypeChg = async (value) => {
        try {
            setFilterType(value)
            let entity
            let operation
            switch (value) {
                case "USERNETWORK":
                    entity = "USER"
                    operation = "NETWORK"
                    break
                case "USERNETWORKONLY":
                    entity = "USER"
                    operation = "NETWORK ONLY"
                    break
                case "DESK":
                    entity = "DESK"
                    operation = "SINGLE"
                    break
                case "DESKNETWORK":
                    entity = "DESK"
                    operation = "NETWORK"
                    break
                case "DESKNETWORKONLY":
                    entity = "DESK"
                    operation = "NETWORK ONLY"
                    break
                case "SALESREPNETWORK":
                    entity = "SALESREP"
                    operation = "NETWORK"
                    break
                case "SALESREPNETWORKONLY":
                    entity = "SALESREP"
                    operation = "NETWORK ONLY"
                    break
                default:
                    break
            }
            form.setValue("reportTableObject.data.entity", entity)
            form.setValue("reportTableObject.data.operation", operation)
            if (filterTypeId?.length > 0) {
                setFilterTypeId([])
                form.setValue("reportTableObject.data.id", [])
            }
        } catch (error) {}
    }

    const onReportCategoryChange = async (value) => {
        try {
            setSelectedReportCategoryID(value)
            setSelectedReportID("")
            form.resetField("reportTableObject.reportId")
            form.resetField("reportTableObject.reportFieldsName")
        } catch (error) {}
    }

    const onReportChange = async (value) => {
        try {
            setSelectedReportID(value)
            form.resetField("reportTableObject.reportFieldsName")
        } catch (error) {}
    }

    useEffect(() => {
        getAllReports()
        getReportCategory()
        if (mode === "add") {
            form.setValue("reportTableObject.displayMode", "SUMUP")
            form.setValue("reportTableObject.reportViewMode", "TABLE")
            setReporViewMode("TABLE")
        }
    }, [])

    useEffect(() => {
        if (mode === "add" || !reportTableObjectData) return

        const {reportCategoryId, reportId, reportFieldsName, displayMode, reportViewMode, dateRangeType, fromDate, toDate, data} = reportTableObjectData

        const setFormValues = (field, value) => form.setValue(`reportTableObject.${field}`, value)
        const setValues = () => {
            setFormValues("reportCategoryId", reportCategoryId)
            setFormValues("reportId", reportId)
            setFormValues("reportFieldsName", reportFieldsName)
            setFormValues("displayMode", displayMode)
            setFormValues("reportViewMode", reportViewMode)
            setFormValues("dateRangeType", dateRangeType)

            if (dateRangeType === "CUSTOM") {
                setFormValues("fromDate", dayjs(fromDate))
                setFormValues("toDate", dayjs(toDate))
            }

            let filterType
            if (data?.operation === "NETWORK") {
                filterType = `${data.entity}NETWORK`
            } else if (data?.operation === "NETWORKONLY") {
                filterType = `${data.entity}NETWORKONLY`
            } else if (data?.operation === "SINGLE") {
                filterType = data.entity
            }

            if (filterType) {
                onFilterTypeChg(filterType)
                if (data?.id) {
                    setFilterTypeId(data.id)
                    setFormValues("data.id", data.id)
                }
            }
        }

        setValues()
        setSelectedReportCategoryID(reportCategoryId)
        setSelectedReportID(reportId)
        setDateRangeType(dateRangeType)
        setReporViewMode(reportViewMode)
    }, [reportTableObjectData])

    useEffect(() => {
        if (selectedReportID) {
            getReportFields()
        }
    }, [selectedReportID])

    const reportSummaryData = useMemo(() => {
        let reportInfo = reportingAll?.find((report) => report?.reportId === selectedReportID)
        return reportInfo
    }, [reportingAll, selectedReportID])

    const reportFilterByType = useMemo(() => {
        return reportSummaryData?.filterByType || ""
    }, [reportSummaryData, selectedReportID])

    const reportingCategoriesOptions = useMemo(() => {
        return reportCategories?.map((v) => ({
            label: t(v?.label),
            value: v?._id,
        }))
    }, [reportCategories])

    const reportOptions = useMemo(() => {
        const reportList = reportCategories?.find((v) => v?._id === selectedReportCategoryID)?.reports
        if (reportList?.length) {
            return reportList?.map((report) => {
                let reportName = reportingAll?.find((a) => a?.reportId === report?.reportId)?.displayName
                return {label: reportName, value: report?.reportId}
            })
        }
        return []
    }, [reportCategories, selectedReportCategoryID, reportingAll])

    const reportFieldOptions = useMemo(() => {
        if (reportFields?.length) {
            return reportFields?.map((field: any) => {
                let displayName = field?.uiName
                if (reportSummaryData?.groupBy && field?.uiName === "baseField") {
                    switch (reportSummaryData?.groupByType) {
                        case "USER":
                            displayName = "Username"
                            break
                        case "CRMUSER":
                            displayName = "CRM User"
                            break
                        case "SALESREP":
                            displayName = "Sales Rep"
                            break
                        case "DESK":
                            displayName = "Desk Name"
                            break
                        case "TRADINGACCOUNT":
                            displayName = "Account No."
                            break
                        case "PSPSETTING":
                            displayName = "PSP"
                            break
                        default:
                            break
                    }
                }
                return {
                    label: displayName,
                    value: field?.reportFieldName,
                }
            })
        }
        return []
    }, [reportFields, selectedReportID, reportSummaryData])

    const networkFilterOptions = useMemo(() => {
        let list: any = []

        reportFilterByType?.length > 0 &&
            reportFilterByType?.map((type) => {
                switch (type) {
                    case "USER":
                        list.push(
                            {value: "USERNETWORK", label: t("common.userNetwork")}
                            // {value: "USERNETWORKONLY", label: t("common.userNetworkOnly")},
                        )
                        break
                    case "CRMUSER":
                        list.push(
                            {value: "SALESREPNETWORK", label: t("common.salesRepNetwork")}
                            // {value: "SALESREPNETWORKONLY", label: t("common.salesRepNetworkOnly")},
                        )
                        break
                    case "DESK":
                        list.push(
                            {value: "DESK", label: t("common.desk")},
                            {value: "DESKNETWORK", label: t("common.deskNetwork")}
                            // {value: "DESKNETWORKONLY", label: t("common.deskNetworkOnly")},
                        )
                        break
                    case "SALESREP":
                        list.push(
                            // {value: "SALESREP", label: t("common.salesRep")}, // userid
                            {value: "SALESREPNETWORK", label: t("common.salesRepNetwork")} // Sales Rep network tree id
                            // {value: "SALESREPNETWORKONLY", label: t("common.salesRepNetworkOnly")},
                        )
                        break
                    default:
                        break
                }
            })

        return list
    }, [reportFilterByType, selectedReportID])

    const dateRangeTypeOptions = [
        {label: t("date.custom"), value: "CUSTOM"},
        {label: t("date.today"), value: "TODAY"},
        {label: t("date.yesterday"), value: "YESTERDAY"},
        {label: t("date.last7Days"), value: "LAST7DAYS"},
        {label: t("date.last14Days"), value: "LAST14DAYS"},
        {label: t("date.last30Days"), value: "LAST30DAYS"},
        {
            label: t("date.thisWeek"),
            value: "THISWEEK",
        },
        {
            label: t("date.lastWeek"),
            value: "LASTWEEK",
        },
        {
            label: t("date.thisMonth"),
            value: "THISMONTH",
        },
        {
            label: t("date.lastMonth"),
            value: "LASTMONTH",
        },
        {
            label: t("date.thisQuarter"),
            value: "THISQUARTER",
        },
        {
            label: t("date.lastQuarter"),
            value: "LASTQUARTER",
        },
        {
            label: t("date.thisYear"),
            value: "THISYEAR",
        },
        {
            label: t("date.lastYear"),
            value: "LASTYEAR",
        },
        {
            label: t("date.allTime"),
            value: "ALLTIME",
        },
    ]

    useEffect(() => {
        if (userNetworkTree?.getUserNetworkTreeDropdownBasedOnPermission?.data?.length > 0) {
            setUserNetworkOptions(userNetworkTree?.getUserNetworkTreeDropdownBasedOnPermission?.data?.map((d) => formatUserNetworkOptions(d)))
        }
    }, [userNetworkTree])

    const NetworkFilterSection = () => (
        <Col xs={12}>
            <Form.Item labelCol={{span: 24, offset: 0}} label={t("Filter By")} name={"networkFilter"}>
                <Row>
                    <Col span={12}>
                        <Select
                            value={filterType}
                            onChange={(value) => {
                                onFilterTypeChg(value)
                            }}
                            options={networkFilterOptions}
                            className="w-100"
                            disabled={mode === "view"}
                        />
                    </Col>
                    <Col span={12}>
                        {(filterType === "USERNETWORK" || filterType === "USERNETWORKONLY") && (
                            <Select
                                id="userNetworkFilter"
                                value={filterTypeId}
                                onChange={(value) => {
                                    setFilterTypeId(value)
                                    form.setValue("reportTableObject.data.id", value)
                                }}
                                options={userNetworkOptions}
                                optionFilterProp={"label"}
                                optionLabelProp="selectedLabel"
                                showSearch
                                mode="multiple"
                                className="w-100"
                                disabled={mode === "view"}
                            />
                        )}
                        {(filterType === "DESK" || filterType === "DESKNETWORK" || filterType === "DESKNETWORKONLY") && (
                            <Select
                                id="deskNetworkFilter"
                                value={filterTypeId}
                                onChange={(value) => {
                                    setFilterTypeId(value)
                                    form.setValue("reportTableObject.data.id", value)
                                }}
                                options={deskOptions}
                                optionFilterProp={"label"}
                                optionLabelProp="selectedLabel"
                                showSearch
                                mode="multiple"
                                className="w-100"
                                disabled={mode === "view"}
                            />
                        )}
                        {(filterType === "SALESREPNETWORK" || filterType === "SALESREPNETWORKONLY") && (
                            <Select
                                id="salesRepNetworkFilter"
                                value={filterTypeId}
                                onChange={(value) => {
                                    setFilterTypeId(value)
                                    form.setValue("reportTableObject.data.id", value)
                                }}
                                options={salesRepNetworkOptions}
                                optionFilterProp={"label"}
                                optionLabelProp="selectedLabel"
                                showSearch
                                mode="multiple"
                                className="w-100"
                                disabled={mode === "view"}
                            />
                        )}
                    </Col>
                </Row>
            </Form.Item>
        </Col>
    )

    const plReportID = "6543837c534e1f5cafb4abfc"

    return (
        <>
            <Row gutter={10}>
                <Col xs={12}>
                    <SelectFormItem
                        name={"reportTableObject.reportCategoryId"}
                        label={t("Report Category")}
                        placeholder={t("Report Category")}
                        options={reportingCategoriesOptions}
                        layout={verticalFormLayout}
                        disabled={mode === "view"}
                        onChange={onReportCategoryChange}
                        required
                    />
                </Col>
                <Col xs={12}>
                    <SelectFormItem name={"reportTableObject.reportId"} label={t("Report Name")} placeholder={t("Report Name")} options={reportOptions} layout={verticalFormLayout} disabled={mode === "view"} onChange={onReportChange} required />
                </Col>
                <Col xs={12}>
                    <SelectFormItem
                        name={"reportTableObject.reportFieldsName"}
                        label={t("Report Fields To display")}
                        placeholder={t("Report Fields")}
                        mode="multiple"
                        options={reportFieldOptions}
                        layout={verticalFormLayout}
                        disabled={mode === "view"}
                        optionFilterProp="label"
                        filterSort={(optionA, optionB) => (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())}
                        required
                    />
                </Col>
                <Col xs={12}>
                    <RadioGroupFormItem
                        name={"reportTableObject.displayMode"}
                        label={t("Display Mode")}
                        options={[
                            {label: t("By Total"), value: "SUMUP"},
                            {label: t("By Date"), value: "DATE"},
                        ]}
                        layout={verticalFormLayout}
                        required
                        disabled={mode === "view"}
                        defaultValue="SUMUP"
                    />
                </Col>
                <Col xs={12}>
                    <SelectFormItem
                        name={"reportTableObject.dateRangeType"}
                        label={t("Date Range")}
                        placeholder={t("Date Range")}
                        options={dateRangeTypeOptions}
                        layout={verticalFormLayout}
                        disabled={mode === "view"}
                        onChange={(value) => setDateRangeType(value)}
                        required
                    />
                </Col>
                <Col xs={12}>
                    <DateRangePickerInputForm
                        startName={"reportTableObject.fromDate"}
                        endName={"reportTableObject.toDate"}
                        layout={verticalFormLayout}
                        wrapperCol={{span: 24}}
                        label={t("Report Date Range")}
                        disabled={mode === "view"}
                        requiredStar={dateRangeType === "CUSTOM"}
                        startDisabled={dateRangeType !== "CUSTOM" || mode === "view"}
                        endDisabled={dateRangeType !== "CUSTOM" || mode === "view"}
                    />
                </Col>
                {selectedReportID === plReportID && (
                // {reportSummaryData?.isTreeViewEnabled && (
                    <Col xs={12}>
                        <RadioGroupFormItem
                            name={"reportTableObject.reportViewMode"}
                            label={t("Report View Mode")}
                            options={[
                                {label: t("Tree View"), value: "TREE"},
                                {label: t("Table View"), value: "TABLE"},
                            ]}
                            layout={verticalFormLayout}
                            required
                            disabled={mode === "view"}
                            defaultValue={"TABLE"}
                            onChange={(value) => {
                                setReporViewMode(value)
                            }}
                        />
                    </Col>
                )}
                <NetworkFilterSection />
            </Row>
        </>
    )
}

export default ReportTableForm
