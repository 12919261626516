import * as XLSX from "xlsx";
import FileSaver from 'file-saver'
import moment from "moment";

const handleObjectChange = (setArray, value, field, language) => {
    setArray(prevState => {
      const index = prevState.findIndex(item => item.language === language);
      const temp = [...prevState];
      if (index === -1) {
        temp.push({ [field]: value, language: language });
      } else {
        const updatedObj = { ...temp[index], [field]: value };
        temp[index] = updatedObj;
      }
      
      return temp;
    });
  };


  const getTextFromObject = (array, field, language) => {
    const index = array.findIndex(item => item.language === language)

    if(!array || !array[index] || !array[index][field]){
      return ""
    }

    return array[index][field]
  }

  const getListFromObject = (array, field, language) => {
    const index = array.findIndex(item => item.language === language)
    
    if(!array || !array[index]){
      return []
    }
    
    return array[index][field]
  }

  

  function exportToExcel(file, fileType:any = "xlsx") {
    var fileFormat = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
    if(fileType === "csv"){
      fileFormat = "text/csv;charset=utf-8"
    }
    if(!file){
      return null
    }
    let header = ['variableName', 'type', 'emailValue', 'displayName', 'fileType', 'text', 'fileName', 'path', 'language', 'isActive'];
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [header]);
    XLSX.utils.sheet_add_json(ws, file, { origin: 'A2', skipHeader: true });
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const buffer = XLSX.write(wb, { bookType: fileType, type: 'array', cellStyles:true });
    const finalData = new Blob([buffer], { type: fileFormat });
    FileSaver.saveAs(finalData, `VariableFile_${moment().format("DD-MM-YYYY")}.${fileType}`);
  }

  export {
    getListFromObject,
    getTextFromObject,
    handleObjectChange,
    exportToExcel
  }