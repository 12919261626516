import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Button, Empty, Modal, Space, Tag} from "antd";
import {FormProvider, useForm} from "react-hook-form";
import {useSetRecoilState} from "recoil";
import {Row, Col, Form} from "antd";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {useRecoilValue} from "recoil";
import {yupResolver} from "@hookform/resolvers/yup";
import {SortableContext, rectSortingStrategy} from "@dnd-kit/sortable";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import {horizontalFormLayout} from "../../../../../../components/form/formWrapper";
import {reorder} from "../../../function/common";
import AddButton from "../../../../../../components/button/addButton";
import {DndContext, closestCenter, DragOverlay, useSensor, useSensors, PointerSensor} from "@dnd-kit/core";
import SortableItem from "./SortableItem";
import Item from "./Item";
import {reportingAllState, reportingCategoryState, reportingAllFieldsState} from "../../../state";
import {
    getReportingBaseReportId,
    getReportingGroupByBaseReportId,
    getReportingRelationship,
    useCreateReportMutation,
    useMultiGroupByReportMutation,
    useUpdateReportMutation,
} from "../../../../../../api/graphql/reporting";
import yupFormSchema from "../../../../../../components/yup/yupFormSchema";
import SelectFormItem from "../../../../../../components/form/selectFormItem";
import MultipleGroupByReportField from "./multipleGroupByReportField";
import {notificationMessage} from "../../../../../../../recoil_state";

import EditColumnModal from "./editColumnModal";
import {getUserTableAdminSetting} from "../../../../../../api/graphql/tableSetting";
import CheckboxFormItem from "../../../../../../components/form/checkboxFormItem";
import {getCommissionSettingDropdown} from "../../../../../../api/graphql/commission";

const labelCol = {span: 8, offset: 0};

const CreateEditReportModal = (props) => {
    const {
        createReportType,
        formMode,
        isModalOpen,
        toggleModal,
        setSelectedCategoryID,
        selectedReportID,
        reportSummaryData,
        reportFields,
        setSelectedReportID,
        getAllCreateEditModalData,
    } = props;

    const yupSchema = yup.object().shape({
        name: yupFormSchema.string("name", {required: true}),
        description: yupFormSchema.string("description", {required: true}),
    })

    const commissionSchema = yup.object().shape({
        name: yupFormSchema.string("name", {required: true}),
        description: yupFormSchema.string("description", {required: true}),
        baseReportIdentifiers: yupFormSchema.string("baseReportIdentifiers", {required: true})
    })

    const {t} = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const reportingFields = useRecoilValue<any>(reportingAllFieldsState);
    const reportingCategory = useRecoilValue<any>(reportingCategoryState);
    const reportingAll = useRecoilValue<any>(reportingAllState);
    const [columnsValue, setColumnsValue] = useState<any>([]);
    const [isEditColModalOpen, setIsEditColModalOpen] = useState(false);
    const [selectedColumn, setSelectedColumn] = useState({});
    const [selectedColumnIndex, setSelectedColumnIndex] = useState(0);
    const [columnsSelection, setColumnsSelection] = useState([]);
    const [availableReports, setAvailableReports] = useState<any>([]);
    const [availableGroupByReports, setAvailableGroupByReports] = useState<any>({current: [], new: []});
    const [baseReportIds, setBaseReportIds] = useState<any[]>([]);
    const [isGroupByReportSelected, setIsGroupByReportSelected] = useState(false);
    const [commissionSettingOptions, setCommissionSettingOptions] = useState<any>([]);
    const [calculationReportColumns, setCalculationReportColumns] = useState<any>(null);

    const {multiGroupByReport} = useMultiGroupByReportMutation();
    const {createReport, loading: createLoading} = useCreateReportMutation();
    const {updateReport, loading: updateLoading} = useUpdateReportMutation();
    const {data: adminSettingTable, loading: adminSettingLoading} = getUserTableAdminSetting({
        filter: {
            tableName: selectedReportID,
            serverName: null,
        },
    });

    const [activeId, setActiveId] = useState<string | null>(null);
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        })
    );

    const [initialValues] = useState<any>(() => ({
        categoryId: null,
        name: "",
        description: "",
        network: "",
        orderByColumnName: "",
        orderByDirection: "",
    }));

    const form = useForm({
        resolver: yupResolver(createReportType === "commissionPayout" ? commissionSchema : yupSchema),
        mode: "all",
        defaultValues: initialValues,
    });

    const getCommissionSettingOptions = async () => {
        if (createReportType === "commissionPayout") {
            const dropdown = await getCommissionSettingDropdown();
            setCommissionSettingOptions(
                dropdown?.getCommissionSettingDropdown?.data?.map((d) => ({
                    label: t(d?.label),
                    value: d?._id,
                    reportId: d?.reportId
                }))
            );
        }
    };

    const toggleEditColModal = (col: any, colIndex: number) => {
        setIsEditColModalOpen((prevState) => !prevState);
        setSelectedColumn(col);
        setSelectedColumnIndex(colIndex);
    };

    const handleDragStart = useCallback((event: any) => {
        setActiveId(event.active.id);
    }, []);

    const handleDragEnd = useCallback((event: any) => {
        const {active, over} = event;
        if (active.id !== over?.id) {
            setColumnsValue((items) => {
                return reorder(items, active.id, over?.id);
            });
        }
        setActiveId(null);
    }, []);

    const handleDragCancel = useCallback(() => {
        setActiveId(null);
    }, []);

    const addColumn = () => {
        let addedIndex = columnsValue?.length;
        toggleEditColModal({}, addedIndex);
    };

    const deleteColumn = (id) => {
        let index = Number(id);
        setColumnsValue((prevState) => {
            const modifiedArray = [...prevState.slice(0, index), ...prevState.slice(index + 1)];
            return modifiedArray;
        });
    };

    const getBaseReportIds = async () => {
        try {
            let newBaseReportIds: any = [];
            if (createReportType === "commissionPayout") {
                newBaseReportIds = ["666026785d1d7fee9e16e993"];
                setBaseReportIds(newBaseReportIds);
            } else {
                if (columnsValue?.length > 0) {
                    const colOperations = columnsValue?.map((col) => col?.operation)?.filter((v) => v !== null);
                    if (isGroupByReportSelected) {
                        const res = await getReportingGroupByBaseReportId(colOperations);
                        newBaseReportIds =
                            res?.getReportingGroupByBaseReportId?.data !== "000000000000000000000000"
                                ? res?.getReportingGroupByBaseReportId?.data
                                : false;
                    } else {
                        const res = await getReportingBaseReportId(colOperations);
                        newBaseReportIds =
                            res?.getReportingBaseReportId?.data !== "000000000000000000000000" ? [res?.getReportingBaseReportId?.data] : false;
                    }
                } else {
                    setColumnsSelection(reportingFields);
                }
            }
            if (newBaseReportIds) {
                setBaseReportIds(newBaseReportIds);
            }
        } catch (error) {
        }
    };

    const getAvailableFields = async () => {
        try {
            if (baseReportIds?.length > 0) {
                const relationshipRes = await getReportingRelationship(baseReportIds?.[0], isGroupByReportSelected);
                const availableFields = relationshipRes?.getReportingRelationship?.data?.fields?.fields;
                const availableReportIds = relationshipRes?.getReportingRelationship?.data?.reportIds?.reportIds;
                const availableReportNames = relationshipRes?.getReportingRelationship?.data?.reportNames?.reportNames;
                if (availableFields) {
                    setColumnsSelection(availableFields);
                } else {
                    setColumnsSelection([]);
                }
                if (availableReportIds) {
                    let reports = availableReportIds?.map((id, index) => ({displayName: availableReportNames?.[index], reportId: id}));
                    setAvailableReports(reports);
                } else {
                    setAvailableReports([]);
                }
            }
        } catch (error) {
        }
    };

    const getMultiGroupByReportList = async () => {
        const filteredBaseReportIds = baseReportIds?.filter((id) => id != "");
        if (filteredBaseReportIds?.length > 0) {
            const input =
                filteredBaseReportIds?.length > 1 && baseReportIds?.length === filteredBaseReportIds?.length
                    ? filteredBaseReportIds?.slice(0, -1)
                    : filteredBaseReportIds;

            const currentRes = await multiGroupByReport(input); // Get the Current groupBy report list
            const newRes = await multiGroupByReport(filteredBaseReportIds); // Get the Next groupBy report list

            const currentResData = currentRes?.data?.multiGroupByReport?.data;
            const newResData = newRes?.data?.multiGroupByReport?.data;

            const currentResError = currentRes?.data?.multiGroupByReport?.__typename === "BaseError";
            const newResError = newRes?.data?.multiGroupByReport?.__typename === "BaseError";

            if (currentResError || newResError) {
                let errMsg = newRes?.data?.multiGroupByReport?.errObj?.message || currentRes?.data?.multiGroupByReport?.errObj?.message;
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: errMsg,
                    key: "multiGroupByReport",
                });
            } else {
                const currentReportIds = currentResData?.reportIds?.reportIds;
                const currentReportNames = currentResData?.reportNames?.reportNames;

                const newReportIds = newResData?.reportIds?.reportIds;
                const newReportNames = newResData?.reportNames?.reportNames;

                let currentReports = currentReportIds?.map((id, index) => ({label: currentReportNames?.[index], value: id}));
                let newReports = newReportIds?.map((id, index) => ({label: newReportNames?.[index], value: id}));

                setAvailableGroupByReports(() => ({current: currentReports, new: newReports}));
            }
        }
    };

    const onSubmit = async () => {
        try {
            const formValues = form.getValues();
            let amendedColumnsValue = columnsValue?.map((col) => ({
                name: col?.name,
                description: col?.description ?? null,
                fieldId: col?.fieldId,
                ...(formMode === "edit" && {
                    isUsedForCommission: col?.isUsedForCommission,
                    isUsedForAutomation: col?.isUsedForAutomation,
                }),
                ...(formMode === "add" && {
                    operation: col?.operation ?? null,
                    linkedTo: col?.linkedTo ?? null,
                    linkedToReportId: col?.linkedToReportId ?? null,
                    linkedToReportFieldName: col?.linkedToReportFieldName ?? null,
                    displayFieldName: col?.displayFieldName ?? null,
                    displayMode: col?.displayMode ?? null,
                    isLiveData: col?.isLiveData ?? null,
                    fieldFilters: col?.fieldFilters ?? null,
                    additionalFieldFilters: col?.additionalFieldFilters ?? null,
                }),
            }));

            const input = {
                name: formValues?.name,
                description: formValues?.description,
                columns: amendedColumnsValue,
                ...(createReportType === "commissionPayout" && {
                    commissionReportType: "COMMISSION_PAYOUT_REPORT"
                }),
                ...(createReportType === "commission" && {
                    commissionReportType: "COMMISSION_CALCULATION_REPORT"
                }),
                ...(formMode === "add" && {
                    // editable: false,
                    categoryId: formValues?.categoryId,
                    baseReportIds: baseReportIds,
                    groupBy: isGroupByReportSelected,
                    // networkTypeBase: formValues?.network !== "own",
                }),
                ...(formMode === "add" &&
                    createReportType !== "commissionPayout" && {
                        networkTypeBase: false,
                        networkOnly: formValues?.network === "networkOnly",
                    }),
                ...(formMode === "add" &&
                    createReportType === "commissionPayout" && {
                        baseReportIdentifiers: [formValues?.baseReportIdentifiers],
                        groupBy: isGroupByReportSelected, // true
                        commissionBase: true,
                        baseReportIds: baseReportIds, // ["666026785d1d7fee9e16e993"]
                    }),
                ...(formMode === "edit" && {
                    orderByColumnName: formValues?.orderByColumnName, // only allow for Update Report
                    orderByDirection: formValues?.orderByDirection, // only allow for Update Report
                }),
            };

            const response = formMode === "add" ? await createReport(input) : await updateReport(selectedReportID, input);
            const resError = response?.data?.createReport?.__typename === "BaseError" || response?.data?.updateReport?.__typename === "BaseError";
            if (response?.data?.createReport?.__typename === "Reporting") {
                // CreateReport
                if (formValues?.categoryId) {
                    setSelectedCategoryID(formValues?.categoryId);
                } else {
                    setSelectedCategoryID("");
                }
                setSelectedReportID(response?.data?.createReport?.reportId);
                toggleModal("");
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Create Successfully",
                    key: "createReport",
                });
            } else if (response?.data?.updateReport?.__typename === "ReportingData") {
                // UpdateReport
                toggleModal("");
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Update Successfully",
                    key: "updateReport",
                });
            } else if (resError) {
                let errMsg = response?.data?.createReport?.errObj?.title ? `${response?.data?.createReport?.errObj?.title}: ${response?.data?.createReport?.errObj?.errors?.description}` : response?.data?.updateReport?.errObj?.message;
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: errMsg,
                    key: formMode === "add" ? "createReport" : "updateReport",
                });
            }
        } catch (error) {
        }
    };

    useEffect(() => {
        getCommissionSettingOptions();
        getAllCreateEditModalData();
        setColumnsSelection(reportingFields);
        setAvailableReports(reportingAll);
        if (createReportType === "commissionPayout") {
            setIsGroupByReportSelected(true);
        }
    }, []);

    useEffect(() => {
        getBaseReportIds();
    }, [columnsValue, isGroupByReportSelected]);

    useEffect(() => {
        getAvailableFields();
        if (baseReportIds?.length > 0 && isGroupByReportSelected) {
            getMultiGroupByReportList();
        }
    }, [baseReportIds, isGroupByReportSelected]);

    useEffect(() => {
        if (columnsValue?.length === 0 && createReportType !== "commissionPayout") {
            setBaseReportIds([]);
        }
    }, [columnsValue]);

    useEffect(() => {
        if (reportSummaryData && Object.keys(reportSummaryData)?.length > 0) {
            form.setValue("name", reportSummaryData?.displayName);
            form.setValue("description", reportSummaryData?.description);
            form.setValue("orderByColumnName", reportSummaryData?.orderByColumnName);
            form.setValue("orderByDirection", reportSummaryData?.orderByDirection);
        }
    }, [reportSummaryData]);

    useEffect(() => {
        const adminColumnSetting = adminSettingTable?.getUserTableAdminSetting?.data?.[0]?.fieldData;
        if (reportFields?.length > 0) {
            let colValues = reportFields
                ?.filter((v) => !["baseId", "baseField"]?.includes(v?.reportFieldName))
                ?.map((field) => {
                    const position = adminColumnSetting?.find((v) => v?.fieldName === field?.reportFieldName)?.defaultPosition;
                    return {
                        position: position,
                        fieldId: field?.fieldId,
                        name: field?.uiName,
                        fieldName: field?.fieldName,
                        description: field?.description,
                        operation: field?.operation,
                        keys: field?.listOfKeys,
                        linkedTo: field?.linkedTo,
                        linkedToReportId: field?.linkedToReportId,
                        linkedToReportFieldName: field?.linkedToReportFieldName, // To amend
                        displayFieldName: field?.displayFieldName,
                        displayMode: field?.displayMode,
                        isLiveData: field?.isLiveData,
                        isUsedForAutomation: field?.isUsedForAutomation,
                        isUsedForCommission: field?.isUsedForCommission,
                    };
                })
                ?.sort((a, b) => a?.position - b?.position);
            setColumnsValue(colValues);
        }
    }, [reportFields, adminSettingTable]);

    useEffect(() => {
        // userId - BD122
        // traderId - BI5
        // referrerId - BD59
        if (createReportType === "commission") {
            let colValues = [
                {
                    name: "traderId",
                    description: "Trader ID from TradingAccounts Table",
                    operation: "BI5",
                    keys: ["BI5"],
                    isUsedForCommission: true,
                    isUsedForAutomation: false,
                    linkedTo: null,
                    linkedToReportId: null,
                    linkedToReportFieldName: null,
                    disabledEdit: true,
                },
                {
                    name: "userId",
                    description: "User ID from AdminPanelUser Table",
                    operation: "BD122",
                    keys: ["BD122"],
                    isUsedForCommission: true,
                    isUsedForAutomation: false,
                    linkedTo: null,
                    linkedToReportId: null,
                    linkedToReportFieldName: null,
                    disabledEdit: true,
                },
                {
                    name: "referrerId",
                    description: "Referrer ID from AdminPanelUser Table",
                    operation: "BD59",
                    keys: ["BD59"],
                    isUsedForCommission: true,
                    isUsedForAutomation: false,
                    linkedTo: null,
                    linkedToReportId: null,
                    linkedToReportFieldName: null,
                    disabledEdit: true,
                },
            ];
            setColumnsValue(colValues);
        }

        if (createReportType === "commissionPayout" || createReportType === "commission") {
            let commissionCategoryId: any = reportingCategory?.find((v) => v?.name === "Commission Reports")?._id;
            form.setValue("categoryId", commissionCategoryId);
        }
    }, [createReportType, reportingCategory]);

    const isUsedForAutomationShown = useMemo(() => {
        if (formMode === "add" && createReportType === "commission") {
            return true;
        } else if (formMode === "edit") {
            return reportFields?.some((f) => f?.fieldName === "userId");
        } else {
            return false;
        }
    }, [columnsValue, reportFields]);

    const isUsedForCommissionShown = useMemo(() => {
        if (formMode === "add" && createReportType === "commission") {
            return true;
        } else if (formMode === "edit") {
            const reportFieldNameList = reportFields?.map((f) => f?.fieldName);
            return ["userId", "traderId"]?.every((element) => reportFieldNameList.includes(element));
            // return ["userId", "traderId", "referrerId"]?.every((element) => reportFieldNameList.includes(element));
        } else {
            return false;
        }
    }, [columnsValue, reportFields]);

    const draggableList = () => {
        return (
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragCancel={handleDragCancel}
            >
                {/* <SortableContext items={columnsValue} strategy={rectSortingStrategy} disabled={updateLoading || createLoading || formMode === "edit"}> */}
                <SortableContext items={columnsValue} strategy={rectSortingStrategy} disabled={true}>
                    <Row gutter={10}>
                        {columnsValue.map((col, index) => (
                            <Col key={index} xs={12} sm={8} md={8} xl={6} className="margin-top-0-5">
                                <SortableItem
                                    formMode={formMode}
                                    item={col}
                                    key={index}
                                    id={index.toString()}
                                    toggleEditColModal={toggleEditColModal}
                                    deleteColumn={deleteColumn}
                                    setColumnsValue={setColumnsValue}
                                    isUsedForAutomationShown={isUsedForAutomationShown}
                                    isUsedForCommissionShown={isUsedForCommissionShown}
                                />
                            </Col>
                        ))}
                    </Row>
                </SortableContext>
                <DragOverlay adjustScale style={{transformOrigin: "0 0 "}}>
                    {activeId ? (
                        <Item
                            formMode={formMode}
                            item={columnsValue[activeId]}
                            id={activeId}
                            isDragging
                            toggleEditColModal={toggleEditColModal}
                            deleteColumn={deleteColumn}
                            setColumnsValue={setColumnsValue}
                            isUsedForAutomationShown={isUsedForAutomationShown}
                            isUsedForCommissionShown={isUsedForCommissionShown}
                        />
                    ) : null}
                </DragOverlay>
            </DndContext>
        );
    };

    // const groupByReportList = ["AdminPanelUsers", "UserNetworkTrees", "Desks", "DeskNetworkTrees", "SalesRepNetworkTrees"];
    // const groupByReportOptions = useMemo(() => {
    //     let options = [{label: "-", value: ""}];
    //     reportingAll?.map((v) => {
    //         if (v?.groupByEnabled) {
    //             options?.push({label: v?.displayName, value: v?.reportId});
    //         }
    //     });
    //     return options?.sort();
    // }, [reportingAll]);

    const reportingCategoryOptions = useMemo(() => {
        let category = reportingCategory?.map((category) => ({
            label: category?.name,
            value: category?._id,
        }));
        return [{label: "-", value: ""}, ...category];
    }, [reportingCategory]);

    const orderByColumnNameOptions = useMemo(() => {
        let opt = reportFields
            ?.filter((v) => !["baseId"]?.includes(v?.reportFieldName))
            .map((field) => {
                let label = field?.uiName;
                if (reportSummaryData?.groupBy && field?.uiName === "baseField") {
                    switch (reportSummaryData?.groupByType) {
                        case "USER":
                            label = "Username";
                            break;
                        case "CRMUSER":
                            label = "CRM User";
                            break;
                        case "SALESREP":
                            label = "Sales Rep";
                            break;
                        case "DESK":
                            label = "Desk Name";
                            break;
                        case "TRADINGACCOUNT":
                            label = "Account No.";
                            break;
                        case "PSPSETTING":
                            label = "PSP";
                            break;
                        default:
                            break;
                    }
                }
                return {
                    label: label,
                    value: field?.fieldName,
                };
            });
        return opt;
    }, [columnsValue, reportSummaryData]);

    const networkOptions = [
        {label: "Own", value: "own"},
        {label: "Network", value: "network"},
        {label: "Network Only", value: "networkOnly"},
    ];

    const modalTitle = useMemo(() => {
        switch (formMode) {
            case "add":
                return createReportType === "commission" ? "Create Commission Calculation Report" : createReportType === "commissionPayout" ? "Create Commission Payout Report" : "Create Report";
            case "edit":
                return "Edit Report";
            case "view":
                return "View Report";
            default:
                break;
        }
    }, [formMode]);

    const commissionSettingOnClickHandler = (value) => {
        const calculationReportId = commissionSettingOptions?.find(d => d.value === value).reportId

        const reportName = reportingAll?.find(d => d?._id === calculationReportId)?.displayName
        const columns = reportingFields?.filter(d => d?.reportId === calculationReportId)?.map(d => d?.uiName)

        setCalculationReportColumns({reportName: reportName, column: columns})
    }

    useEffect(() => {
        setCalculationReportColumns(null)
    }, [isModalOpen]);

    return (
        <Modal
            title={modalTitle}
            open={isModalOpen}
            width={"80%"}
            footer={null}
            destroyOnClose
            getContainer="#reports"
            className="createEditReportModal"
            maskClosable={false}
            onCancel={() => toggleModal("")}
            // onOk={() => toggleModal("")}
        >
            {isEditColModalOpen && (
                <EditColumnModal
                    formMode={formMode}
                    item={selectedColumn}
                    itemIndex={selectedColumnIndex}
                    groupBy={isGroupByReportSelected}
                    isModalOpen={isEditColModalOpen}
                    toggleModal={toggleEditColModal}
                    columnsValue={columnsValue}
                    setColumnsValue={setColumnsValue}
                    columnsSelection={columnsSelection}
                    baseReportIds={baseReportIds}
                    availableReports={availableReports}
                />
            )}
            <FormProvider {...form}>
                <Form onFinish={form.handleSubmit(onSubmit)} className="create-report-form-1" labelWrap={true}>
                    <Row gutter={10} className="section">
                        <Col className="gutter-row" xs={24} sm={24} md={24}>
                            <label className="sectionLabel">1. Details</label>
                        </Col>
                        <Col className="gutter-row" xs={24} sm={24} md={24}>
                            <InputFormItem
                                name={"name"}
                                label={"Name"}
                                placeholder={"Name"}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                required
                                disabled={formMode === "view" || updateLoading || createLoading}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={24} md={24}>
                            <InputFormItem
                                name={"description"}
                                label={"Description"}
                                placeholder={"Description"}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                required
                                disabled={formMode === "view" || updateLoading || createLoading}
                            />
                        </Col>
                        {formMode === "add" && (
                            <>
                                <Col className="gutter-row" xs={24} sm={24} md={24}>
                                    <SelectFormItem
                                        name={"categoryId"}
                                        label={t("Category")}
                                        placeholder={t("Category")}
                                        layout={horizontalFormLayout}
                                        mode={"single"}
                                        labelCol={labelCol}
                                        labelAlign="left"
                                        options={reportingCategoryOptions}
                                        getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                        disabled={
                                            createReportType === "commission" ||
                                            createReportType === "commissionPayout" ||
                                            formMode === "view" ||
                                            updateLoading ||
                                            createLoading
                                        }
                                    />
                                </Col>
                                {createReportType === "commissionPayout" && (
                                    <Col className="gutter-row" xs={24} sm={24} md={24}>
                                        <SelectFormItem
                                            name={"baseReportIdentifiers"}
                                            label={t("Commission Setting")}
                                            placeholder={t("Commission Setting")}
                                            layout={horizontalFormLayout}
                                            mode={"single"}
                                            labelCol={labelCol}
                                            labelAlign="left"
                                            required={true}
                                            options={commissionSettingOptions}
                                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                            disabled={formMode === "view" || updateLoading || createLoading}
                                            onChange={commissionSettingOnClickHandler}
                                        />
                                    </Col>
                                )}
                                <Col className="gutter-row" xs={24} sm={24} md={24}>
                                    <CheckboxFormItem
                                        name="groupBy"
                                        label={t("Group By Report")}
                                        value={formMode === "add" && createReportType === "commissionPayout" ? true : isGroupByReportSelected}
                                        onChange={(e) => setIsGroupByReportSelected(e)}
                                        disabled={formMode !== "add" || updateLoading || createLoading || createReportType === "commissionPayout"}
                                        labelCol={labelCol}
                                        labelAlign="left"
                                    />
                                    {/* <div style={{height: "32px"}}>
                                        <label className="margin-right-0-25">{"Group By Report"}</label>
                                        <Checkbox
                                            checked={isGroupByReportSelected}
                                            onChange={(e) => setIsGroupByReportSelected(e.target.checked)}
                                            disabled={formMode !== "add" || updateLoading || createLoading}
                                        />
                                    </div> */}
                                </Col>
                            </>
                        )}
                    </Row>
                    {
                        calculationReportColumns && createReportType === "commissionPayout" &&
                        <Row gutter={10} className="section">
                            <Col className="gutter-row" span={24}>
                                <label className="sectionLabel">Commission Calculation Report Fields ({calculationReportColumns?.reportName})</label>
                            </Col>
                            <Col span={24}>
                                {
                                    calculationReportColumns?.column?.map(column => (
                                        <Tag key={column} className={"margin-bottom-0-3"} color={"geekblue"}>{column}</Tag>
                                    ))
                                }
                            </Col>
                        </Row>
                    }
                    <Row gutter={10} className="section">
                        <Col className="gutter-row" xs={12} sm={12} md={12}>
                            <label className="sectionLabel">2. Choose Columns</label>
                        </Col>
                        {formMode === "add" && (
                            <Col className="gutter-row text-align-right" xs={12} sm={12} md={12}>
                                <AddButton text={"Add Column"} addHandler={addColumn} disabled={createLoading}/>
                            </Col>
                        )}
                        <Col span={24}>{columnsValue?.length > 0 ? draggableList() : <Empty description="No column added yet"/>}</Col>
                    </Row>
                    <Row gutter={10} className="section" style={{marginTop: "1rem"}}>
                        <Col className="gutter-row" xs={24} sm={24} md={24}>
                            <label className="sectionLabel">3. Settings</label>
                        </Col>
                        {/* <Col className="gutter-row" xs={24} sm={24} md={24}>
                            <MultipleGroupByReportField
                                label="Group By"
                                value={baseReportIds}
                                setValue={setBaseReportIds}
                                onChange={(e) => console.log(e)}
                                options={availableGroupByReports}
                                reportingAll={reportingAll}
                                isGroupByReportSelected={isGroupByReportSelected}
                                setIsGroupByReportSelected={setIsGroupByReportSelected}
                                columnsValue={columnsValue}
                                disabled={formMode !== "add" || updateLoading || createLoading || !baseReportIds?.length}
                            />
                        </Col> */}
                        {formMode === "edit" && (
                            <Col className="gutter-row" xs={24} sm={24} md={24}>
                                <SelectFormItem
                                    name={"orderByColumnName"}
                                    label={t("Order By Column Name")}
                                    placeholder={t("Order By Column Name")}
                                    layout={horizontalFormLayout}
                                    mode={"single"}
                                    labelCol={labelCol}
                                    labelAlign="left"
                                    options={orderByColumnNameOptions}
                                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                    disabled={formMode === "view" || updateLoading || createLoading}
                                />
                            </Col>
                        )}
                        {formMode === "edit" && (
                            <Col className="gutter-row" xs={24} sm={24} md={24}>
                                <SelectFormItem
                                    name={"orderByDirection"}
                                    label={t("Order By Direction")}
                                    placeholder={t("Order By Direction")}
                                    layout={horizontalFormLayout}
                                    mode={"single"}
                                    labelCol={labelCol}
                                    labelAlign="left"
                                    options={[
                                        {label: "ASCENDING", value: "ASCENDING"},
                                        {label: "DESCENDING", value: "DESCENDING"},
                                    ]}
                                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                    disabled={formMode === "view" || updateLoading || createLoading}
                                />
                            </Col>
                        )}
                        {/* <Col className="gutter-row" xs={24} sm={24} md={24}>
                            <SelectFormItem
                                name={"network"}
                                label={t("Network")}
                                placeholder={t("Network")}
                                layout={horizontalFormLayout}
                                mode={"single"}
                                labelCol={labelCol}
                                labelAlign="left"
                                options={networkOptions}
                                size="small"
                                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                            />
                        </Col> */}
                    </Row>
                    <Row justify="end" className="section">
                        <Button
                            onClick={() => toggleModal("")}
                            icon={<CloseOutlined/>}
                            className="cancelBtn"
                            disabled={updateLoading || createLoading}
                        >
                            {formMode === "view" ? t("Close") : t("common.cancel")}
                        </Button>
                        {formMode !== "view" && (
                            <Button
                                // onClick={() => toggleModal("")}
                                htmlType="submit"
                                icon={<SaveOutlined/>}
                                className="button-submit-1"
                                disabled={updateLoading || createLoading}
                                loading={updateLoading || createLoading}
                            >
                                {t("common.submit")}
                            </Button>
                        )}
                    </Row>
                </Form>
            </FormProvider>
        </Modal>
    );
};

export default CreateEditReportModal;
