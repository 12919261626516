import React, {useEffect} from "react";
import {Button, Card, Col, Form, Row, Select, Space} from "antd";
import {useTranslation} from "react-i18next";
import {logicalOperator} from "../../../../data/operatorDropdown";
import {DeleteOutlined, MenuOutlined} from "@ant-design/icons";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import ConditionItem from "./conditionItem";

const ConditionGroup = ({
                            conditions,
                            detail,
                            index,
                            setLogicalOperator,
                            logicOperatorAry,
                            setConditions,
                            dragHandle,
                            removeConditionGroup,
                            onFinish,
                            selectedTrigger
                        }) => {
    const {t} = useTranslation()

    const keys = conditions.filter(d => !d?.isDeleted)?.length - 1
    const logicalOperatorValue = logicOperatorAry?.[index]

    const onConditionItemDragEnd = (result) => {
        if (!isNaN(result.destination?.index) && result.destination.index !== result.source.index) {
            const sourceIndex = result.source.index;
            const destinationIndex = result.destination?.index;
            const batchId = parseInt(result.destination.droppableId)

            let newConditionGroup = [...conditions];

            const conditionGroupToUpdate = newConditionGroup.find(d => d.batchId === batchId);

            if (conditionGroupToUpdate) {
                const [movedItem] = conditionGroupToUpdate.condition.splice(sourceIndex, 1);
                conditionGroupToUpdate.condition.splice(destinationIndex, 0, movedItem);

                setConditions(newConditionGroup);
            }
        }
    }

    return (
        <Row gutter={16}>
            <Col span={24}>
                <>
                    <Card size={"small"} style={{background: "#efefef", marginTop: "15px"}}
                          bordered={false}>
                        <Form
                            name={"dynamic_form_nest_item-" + detail.batchId}
                            ref={detail?.form}
                            autoComplete="off"
                            key={detail.batchId}
                        >
                            <Form.List key={detail.batchId}
                                       name={"condition" + detail?.batchId}
                                       initialValue={detail?.condition}>
                                {(fields, {add, remove}) => {
                                    return (
                                        <>
                                            <Row align={"middle"} className={"margin-bottom-0-5"}>
                                                <Col xs={12} sm={20}>
                                                    <Space>
                                                        <MenuOutlined
                                                            // style={{
                                                            //     touchAction: "none",
                                                            //     cursor: "grab",
                                                            // }}
                                                            // {...dragHandle}
                                                        />
                                                        <span>{t("Condition Group")} {index + 1}</span>
                                                    </Space>
                                                </Col>
                                                <Col className={"text-align-right padding-right-0-75"} xs={12} sm={4}>
                                                    <Space>
                                                        <Button type="link"
                                                                style={{padding: "unset"}}
                                                                onClick={() => add()}
                                                            // icon={<PlusOutlined/>}
                                                        >
                                                            Add Condition
                                                        </Button>
                                                        {
                                                            !(conditions?.length === 1 && index === 0) &&
                                                            <Button type={"link"}
                                                                    style={{padding: "unset"}}
                                                                    danger
                                                                    onClick={() => removeConditionGroup(detail?.batchId)}>
                                                                <DeleteOutlined/>
                                                            </Button>
                                                        }
                                                    </Space>
                                                </Col>
                                            </Row>
                                            <Card className={"condition-item-container"}>
                                                <DragDropContext onDragEnd={(result) => onConditionItemDragEnd(result)}>
                                                    <Droppable droppableId={detail?.batchId.toString()}>
                                                        {(droppableProvided) => (
                                                            <div ref={droppableProvided?.innerRef}
                                                                 {...droppableProvided.draggableProps}
                                                                 {...droppableProvided.dragHandleProps}
                                                            >
                                                                {fields.map(({key, name, ...restField}, i) => (
                                                                    <Draggable key={i}
                                                                               draggableId={i.toString()}
                                                                               index={i}
                                                                    >
                                                                        {(provided, snapshot) => {
                                                                            return (
                                                                                <div
                                                                                    ref={provided?.innerRef}
                                                                                    {...provided?.draggableProps}
                                                                                    style={{
                                                                                        ...provided?.draggableProps.style,
                                                                                    }}
                                                                                >
                                                                                    <ConditionItem
                                                                                        key={key}
                                                                                        index={key}
                                                                                        detail={detail}
                                                                                        restField={restField}
                                                                                        onFinish={onFinish}
                                                                                        dragHandle={provided?.dragHandleProps}
                                                                                        remove={remove}
                                                                                        name={name}
                                                                                        selectedTrigger={selectedTrigger}
                                                                                        isLast={fields?.length === 1 && i === 0}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }}
                                                                    </Draggable>
                                                                ))}
                                                                {droppableProvided?.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            </Card>
                                        </>
                                    )
                                }}
                            </Form.List>
                        </Form>

                    </Card>
                    {
                        conditions.filter(d => !d?.isDeleted)?.length > 0 && index !== keys && (
                            <Col span={24} style={{textAlign: "center", marginTop: "15px"}}>
                                <Select style={{width: "10%"}}
                                        value={logicalOperatorValue}
                                        options={logicalOperator}
                                        onChange={(e) => setLogicalOperator(e, index)}/>
                            </Col>
                        )
                    }
                </>
            </Col>
        </Row>
    )
}

export default ConditionGroup