import React from "react";
import {useTranslation} from "react-i18next";
import {renderProfileInformation} from "../../../../../../_common/function";
import {Checkbox, Col, Divider, Form, Input, Row, Select} from "antd";
import {useRecoilValue} from "recoil";
import {languageOptionsState, newsletterOptionsState} from "../../../../../../_common/state";
import {fieldListState} from "../../../../../../../../../recoil_state";

const CorporateInformation = (props) => {
    const {data, editable, isReadSensitive, corporateField, loginField, form} = props;
    const {t} = useTranslation();

    const newsletterOptions = useRecoilValue<any>(newsletterOptionsState)
    const languageOptions = useRecoilValue<any>(languageOptionsState)
    const commentValue = ["deposited", "followingUp", "noAnswer", "garbage/invalidNo", "blocked/giveUp", "assistanceNeeded", "wrongPerson"]
    const fieldList = useRecoilValue<any>(fieldListState)

    return (
        data && (
            <Row gutter={[16, 16]} className={"margin-top-0-75 margin-bottom-0-75"}>
                {corporateField?.length > 0 && (
                    <Col span={24}>
                        <h3>{t("field.category.basicInformation")}</h3>
                        <Row gutter={[16, 16]} style={{marginBottom: 32}}>
                            {renderProfileInformation(corporateField, data, t, !editable, isReadSensitive)}
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name={"language"}
                                    label={t("user.language")}
                                    labelCol={{span: 10, offset: 0}}
                                    initialValue={data?.language}
                                    labelAlign={"left"}
                                >
                                    <Select
                                        placeholder={t("user.language").toString()}
                                        options={languageOptions}
                                        disabled={!editable}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name={"comment"}
                                    label={t("user.comment")}
                                    labelCol={{span: 10, offset: 0}}
                                    initialValue={data?.comment}
                                    labelAlign={"left"}
                                >
                                    <Select
                                        showSearch
                                        disabled={!editable}
                                        allowClear
                                        options={fieldList?.find(d => d?.fieldName === "clientComment")?.optionValue?.map(d => ({...d, label: t(d?.label)}))}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={"isLoginEnabled"}
                                    label={t("user.isLoginEnabled")}
                                    labelCol={{span: 10, offset: 0}}
                                    initialValue={data?.isLoginEnabled}
                                    valuePropName="checked"
                                    labelAlign={"left"}
                                >
                                    <Checkbox disabled={!editable}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={"isTestAccount"}
                                    label={t("user.isTestAccount")}
                                    labelCol={{span: 10, offset: 0}}
                                    initialValue={data?.isTestAccount}
                                    valuePropName="checked"
                                    labelAlign={"left"}
                                >
                                    <Checkbox disabled={!editable}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name={"newsletter"}
                                    label={t("user.newsletter")}
                                    labelCol={{span: 10, offset: 0}}
                                    initialValue={data?.newsletter}
                                    labelAlign={"left"}
                                >
                                    <Checkbox.Group style={{width: "100%"}}>
                                        <Row>
                                            {newsletterOptions &&
                                            newsletterOptions?.map((d) => {
                                                return (
                                                    <Col span={24} style={{marginBottom: 8}} key={d?.value}>
                                                        <Checkbox
                                                            disabled={!editable}
                                                            value={d?.value}
                                                        >
                                                            {t(`newsletter.${d?.label}`)}
                                                        </Checkbox>
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                )}
                <Divider/>
                {loginField?.length > 0 && (
                    <Col span={24}>
                        <h3>{t("field.category.loginInformation")}</h3>
                        <Row gutter={[16, 16]} style={{marginBottom: 32}}>
                            {renderProfileInformation(loginField, data, t, !editable, isReadSensitive)}
                        </Row>
                    </Col>
                )}
            </Row>
        )
    );
};

export default CorporateInformation;
