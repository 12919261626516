import {gql, useMutation, useQuery} from "@apollo/client"
import {ApolloClientQuery} from "./_apolloClient"

const GET_SALES_REP_NETWORK_TREE_COUNT = gql`
    query getSalesRepNetworkTreeCount {
        getSalesRepNetworkTreeCount
    }
`

const getSalesRepNetworkTreeCount = () => {
    try {
        const {loading, error, data} = useQuery(GET_SALES_REP_NETWORK_TREE_COUNT, {
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        })

        return {loading, error, data: data?.getSalesRepNetworkTreeCount}
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const GET_SALES_REP_NETWORK_TREE_BY_ID = gql`
    query getSalesRepNetworkTreeByID($id: String!) {
        getSalesRepNetworkTreeByID(id: $id) {
            ... on SalesRepNetworkTree {
                _id
                id
                userid {
                    username
                }
                parent {
                    username
                }
                left
                right
                createdBy {
                    email
                }
                updatedBy {
                    email
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`

const getSalesRepNetworkTreeByID = (id) => {
    try {
        const {loading, error, data} = useQuery(GET_SALES_REP_NETWORK_TREE_BY_ID, {
            variables: {
                id: id,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        })

        return {loading, error, data}
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const GET_SALES_REP_NETWORK_TREE = gql`
    query getSalesRepNetworkTree {
        getSalesRepNetworkTree {
            ... on SalesRepNetworkTreeArray {
                data {
                    _id
                    id
                    userid {
                        _id
                        username
                        fullName
                        identificationNo
                        email
                        userType
                        createdAt
                        referrer {
                            _id
                        }
                    }
                    parent {
                        _id
                    }
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`

const getSalesRepNetworkTree = () => {
    try {
        const {loading, error, data, refetch} = useQuery(GET_SALES_REP_NETWORK_TREE, {
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        })

        return {loading, error, data, refetch}
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const getSalesRepNetworkTreeAsync = async () => {
    try {
        const execute = await ApolloClientQuery(
            GET_SALES_REP_NETWORK_TREE,
            {
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        )

        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const GET_SALES_REP_NETWORK_TREE_DOWNLINE = gql`
    query getSalesRepNetworkTreeDownline($userids: [String]) {
        getSalesRepNetworkTreeDownline(userids: $userids) {
            ... on SalesRepNetworkTreeArray {
                data {
                    userid {
                        username
                        fullName
                        _id
                    }
                }
            }
        }
    }
`

const getSalesRepNetworkTreeDownline = (userids) => {
    try {
        const {loading, error, data} = useQuery(GET_SALES_REP_NETWORK_TREE_DOWNLINE, {
            variables: {
                userids: userids,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        })

        return {loading, error, networkTreeDownlineData: data}
    } catch (err: any) {
        console.error(err)
        return {networkTreeDownlineData: null, loading: false, error: err}
    }
}

const SORT_SALES_REP_NETWORK_TREE = gql`
    mutation sortSalesRepNetworkTree {
        sortSalesRepNetworkTree {
            ... on SalesRepNetworkTreeArray {
                data {
                    _id
                    userid {
                        username
                    }
                    parent {
                        username
                    }
                    left
                    right
                    createdBy {
                        email
                    }
                    updatedBy {
                        email
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`

const useSortSalesRepNetworkTreeMutation = (): {
    sortSalesRepNetworkTree: any
    data: any
    loading: boolean
    error: any
} => {
    try {
        const [sortSalesRepNetworkTreeMutation, {data, loading, error}] = useMutation(SORT_SALES_REP_NETWORK_TREE)

        const sortSalesRepNetworkTree = () => {
            return sortSalesRepNetworkTreeMutation()
        }

        return {sortSalesRepNetworkTree, data, loading, error}
    } catch (err: any) {
        console.error(err)
        return {
            sortSalesRepNetworkTree: null,
            data: null,
            loading: false,
            error: err,
        }
    }
}

const SORT_TARGET_SALES_REP_NETWORK_TREE = gql`
    mutation sortTargetSalesRepNetworkTree {
        sortTargetSalesRepNetworkTree {
            ... on SalesRepNetworkTreeArray {
                data {
                    _id
                    userid {
                        username
                    }
                    parent {
                        username
                    }
                    left
                    right
                    createdBy {
                        email
                    }
                    updatedBy {
                        email
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`

const useSortTargetSalesRepNetworkTreeMutation = (): {
    sortSalesRepNetworkTree: any
    data: any
    loading: boolean
    error: any
} => {
    try {
        const [sortSalesRepNetworkTreeMutation, {data, loading, error}] = useMutation(SORT_TARGET_SALES_REP_NETWORK_TREE)

        const sortSalesRepNetworkTree = (userTemplateId) => {
            return sortSalesRepNetworkTreeMutation({
                variables: {
                    userTemplateId: userTemplateId,
                },
            })
        }

        return {sortSalesRepNetworkTree, data, loading, error}
    } catch (err: any) {
        console.error(err)
        return {
            sortSalesRepNetworkTree: null,
            data: null,
            loading: false,
            error: err,
        }
    }
}

const GET_SALES_REP_NETWORK_TREE_DROPDOWN_BASED_ON_PERMISSION = gql`
    query getSalesRepNetworkTreeDropdownBasedOnPermission($permission: String) {
        getSalesRepNetworkTreeDropdownBasedOnPermission(permission: $permission) {
            ... on SalesRepNetworkTreeArray {
                data {
                    _id
                    userid {
                        username
                        desk {
                            name
                        }
                        rank {
                            name
                        }
                        identificationNo
                        email
                        _id
                    }
                }
            }
        }
    }
`

const getSalesRepNetworkTreeDropdownBasedOnPermission = (input) => {
    try {
        const {loading, error, data} = useQuery(GET_SALES_REP_NETWORK_TREE_DROPDOWN_BASED_ON_PERMISSION, {
            variables: input,
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        })

        return {loading, error, data}
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

export {
    getSalesRepNetworkTreeCount,
    getSalesRepNetworkTreeByID,
    getSalesRepNetworkTree,
    getSalesRepNetworkTreeAsync,
    getSalesRepNetworkTreeDownline,
    useSortSalesRepNetworkTreeMutation,
    useSortTargetSalesRepNetworkTreeMutation,
    getSalesRepNetworkTreeDropdownBasedOnPermission,
}
