import React from "react";
import {Typography} from "antd";

const TextComponent = ({data, gridLayout, setting, setDetailData, found}) => {
    const {Text} = Typography
    const {italic, textIndent, underline, backgroundColor, highlight, link, list, ...restStyle} = setting

    const onChange = (e) => {
        const newData = {...data}?.interfaceDetail.map(item => {
            if (item.layoutId === gridLayout?.i) {
                return {
                    ...item,
                    value: e
                }
            }

            return item
        })

        setDetailData({...data, interfaceDetail: newData})
    }


    return (
        <div style={{background: setting?.backgroundColor, textAlign: setting?.textAlign}}>
            <Text
                editable={{triggerType: ["text"], enterIcon: null, onChange: onChange}}
                className={"element-text"}
                style={{
                    ...restStyle,
                    fontStyle: italic ? "italic" : "normal",
                    textDecoration: underline ? "underline" : "none",
                    marginLeft: (textIndent || 0) * 10,
                    backgroundColor: highlight,
                }}
            >
                {found?.value || "Click here to edit the text."}
            </Text>
        </div>
    )
}

export default TextComponent
