import React from "react";

export const columns = [
    // {
    //     title: "Lead ID",
    //     dataIndex: "leadId",
    //     key: "leadId",
    //     ...getColumnSearchProps(),
    //     render: (text) => <p>{text}</p>,
    // },
    // {
    //     title: "Lead name",
    //     dataIndex: "name",
    //     key: "name",
    //     ...getColumnSearchProps(),
    //     render: (text) => <p>{text}</p>,
    // },
    // {
    //     title: "Lead Email",
    //     dataIndex: "email",
    //     key: "email",
    //     ...getColumnSearchProps(),
    //     render: (text) => <p>{text}</p>,
    // },
    // {
    //     title: "Lead Country Code",
    //     dataIndex: "countryCode",
    //     key: "countryCode",
    //     ...getColumnSearchProps(),
    //     render: (text) => <p>{text}</p>,
    // },
    // {
    //     title: "Lead Mobile",
    //     dataIndex: "mobileNo",
    //     key: "mobileNo",
    //     ...getColumnSearchProps(),
    //     render: (text) => <p>{text}</p>,
    // },
    // {
    //     title: "Owner",
    //     dataIndex: "owner",
    //     key: "owner",
    //     ...getColumnSearchProps(),
    //     render: (text) => <p>{text?.username}</p>,
    // },
    // {
    //     title: "Sales Rep",
    //     dataIndex: "salesRep",
    //     key: "salesRep",
    //     ...getColumnSearchProps(),
    //     render: (text) => <p>{text?.username}</p>,
    // },
    // {
    //     title: "Client ID",
    //     dataIndex: "match",
    //     key: "clientId",
    //     ...getColumnSearchProps(),
    //     render: (text) => <p>{text?.id}</p>,
    // },
    // {
    //     title: "Client Name",
    //     dataIndex: "match",
    //     key: "clientName",
    //     ...getColumnSearchProps(),
    //     render: (text) => <p>{text?.name}</p>,
    // },
    // {
    //     title: "Client Mobile",
    //     dataIndex: "match",
    //     key: "clientMobile",
    //     ...getColumnSearchProps(),
    //     render: (text) => <p>{text?.mobileNo}</p>,
    // },
    // {
    //     title: "Client Email",
    //     dataIndex: "match",
    //     key: "clientEmail",
    //     ...getColumnSearchProps(),
    //     render: (text) => <p>{text?.email}</p>,
    // },
];