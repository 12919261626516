import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Space, Tabs} from "antd";
import Structure from "./component/structure";
import DeskTable_1 from "./component/deskTable";
import EditTableUserPreferenceButton from "../../../../../components/table/function/editTableUserPreferenceButton";
import ExportTableButton from "../../../../../components/table/function/exportTable";
import AddButton from "../../../../../components/button/addButton";

const Desk_1 = (props) => {
    const {networkData, deskPermission, refetchNetworkTree} = props;
    const {t} = useTranslation();
    const tableName = "Desk Settings";
    const [exportData, setExportData] = useState(null);
    const [exportColumn, setExportColumn] = useState(null);
    const [currentTab, setCurrentTab] = useState("setting");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formMode, setFormMode] = useState("");
    const [activeTab, setActiveTab] = useState("setting");
    const [loading, setLoading] = useState(true);

    const toggleModal = (mode: string | "") => {
        setIsModalOpen((prevState) => !prevState);
        setFormMode(mode);
    };

    const refetchDeskNetwork = async () => {
        await refetchNetworkTree();
    };

    let tabItems = [
        {
            label: "Setting",
            key: "setting",
            children: (
                <DeskTable_1
                    tableName={tableName}
                    activeTab={activeTab}
                    formMode={formMode}
                    isModalOpen={isModalOpen}
                    toggleModal={toggleModal}
                    setExportData={setExportData}
                    setExportColumn={setExportColumn}
                    refetchDeskNetwork={refetchDeskNetwork}
                    deskPermission={deskPermission}
                    setLoading={setLoading}
                />
            ),
        },
        {
            label: "Structure",
            key: "structure",
            children: <Structure data={networkData} currentTab={currentTab} refetchDeskNetwork={refetchDeskNetwork} />,
        },
    ];

    return (
        <div id="desk">
            <h1>{t("Desk")}</h1>
            <Tabs
                defaultActiveKey="setting"
                onChange={(key) => setActiveTab(key)}
                size={"small"}
                items={tabItems}
                tabBarExtraContent={
                    <>
                        <Space>
                            {currentTab === "setting" && <EditTableUserPreferenceButton tableName={tableName} tableColumn={exportColumn} />}
                            <ExportTableButton loading={loading} tableData={exportData} tableColumn={exportColumn} tableName={tableName} />
                            {deskPermission?.add?.add && <AddButton text={"Add Desk"} addHandler={() => toggleModal("add")} />}
                        </Space>
                    </>
                }
                onTabClick={(key) => setCurrentTab(key)}
            />
        </div>
    );
};

export default Desk_1;
