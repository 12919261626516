import React from "react";
import {ColumnsType} from "antd/es/table";
import { Space, Tag } from "antd";
import moment from "moment";


export const columns: ColumnsType<any> = [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        defaultSortOrder: "ascend",
        sorter: (a, b) => a?.name?.localeCompare(b?.name),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Display Name",
        dataIndex: "displayName",
        key: "displayName",
        defaultSortOrder: "ascend",
        sorter: (a, b) => a?.displayName?.filter(item => item.language === "en")?.[0]?.content.localeCompare(b?.displayName?.filter(item => item.language === "en")?.[0]?.content),
        render: (_, record) => <p>{record?.displayName?.filter(item => item.language === "en")?.[0]?.content}</p>,
    },
    {
        title: "Description",
        dataIndex: "description",
        key: "description",
        defaultSortOrder: "ascend",
        sorter: (a, b) => a?.description?.filter(item => item.language === "en")?.[0]?.content.localeCompare(b?.description?.filter(item => item.language === "en")?.[0]?.content),
        render: (_, record) => {
            if(record?.description?.filter(item => item.language === "en").length > 0){
                return <p>{record?.description?.filter(item => item.language === "en")?.[0]?.content?.substring(0, 30)}...</p>
            }
            else {
                return <></>
            }
        },
    },
    {
        title: "Network Type",
        dataIndex: "networkType",
        key: "networkType",
        sorter: (a, b) => a?.networkType?.localeCompare(b?.networkType),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Network Include",
        dataIndex: "networkInclude",
        key: "networkInclude",
        width: 250,
        render: (_, record) => (
            <span>
                <Space size={[0, 8]} wrap>
                    {record && record?.networkType === "partial" && record?.network && record?.network.length > 0
                        ? record?.network?.map((item) => {
                              return (
                                  <Tag color="success" key={item?.username}>
                                      {item?.username}
                                  </Tag>
                              );
                          })
                        : "-"}
                </Space>
            </span>
        ),
    },
    {
        title: "Network Exclude",
        key: "networkExclude",
        dataIndex: "networkExclude",
        width: 250,
        render: (_, record) => (
            <span>
                {record && record?.networkType === "partial" && record?.exclude && record?.exclude.length > 0
                    ? record?.exclude?.map((tag) => {
                          return (
                              <Tag color="error" key={tag?.username}>
                                  {tag?.username}
                              </Tag>
                          );
                      })
                    : "-"}
            </span>
        ),
    },
    {
        title: "Voucher Code Type",
        key: "voucherCodeType",
        dataIndex: "voucherCodeType",
        width: 250,
        sorter: (a, b) => a?.voucherCodeType?.localeCompare(b?.voucherCodeType),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Voucher Code",
        dataIndex: "voucherCode",
        key: "voucherCode",
        sorter: (a, b) => a?.voucherCode?.localeCompare(b?.voucherCode),
        render: (text) => <p>{text?.toString()?.substring(0, 30)}...</p>,
    },
    {
        title: "Voucher Program Start Date",
        key: "voucherProgramStartDate",
        dataIndex: "voucherProgramStartDate",
        sorter: (a, b) => moment(a) > moment(b),
        render: (text) => <p>{text ? moment(text)?.format("DD/MM/YYYY") : ""}</p>,
    },
    {
        title: "Voucher Program End Date",
        key: "voucherProgramEndDate",
        dataIndex: "voucherProgramEndDate",
        sorter: (a, b) => moment(a) > moment(b),
        render: (text) => <p>{text ? moment(text)?.format("DD/MM/YYYY") : ""}</p>,
    },
];

export const userVoucherProgramColumn: ColumnsType<any> = [
    {
        title: "Voucher Program",
        dataIndex: "voucherProgramId",
        key: "voucherProgramId",
        render: (record) => <p>{record?.name}</p>,
    },
    {
        title: "Username",
        dataIndex: "user",
        key: "user",
        render: (record) => <p>{record?.username}</p>,
    },
    {
        title: "Voucher Issued",
        dataIndex: "isVoucherIssued",
        key: "isVoucherIssued",
        render: (field) => <p>{field?.toString()}</p>,
    },
    {
        title: "Voucher Program Expired",
        dataIndex: "isVoucherProgramExpired",
        key: "isVoucherProgramExpired",
        render: (field) => <p>{field?.toString()}</p>,
    },
    {
        title: "Voucher Program Claimed At",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text, record) => {
            return <p>{moment(record?.createdAt).format("DD/MM/YYYY")}</p>
        },
    }
]