import {useNavigate} from "react-router-dom";
import {useVerifyAccessTokenMutation} from "../api/graphql/auth";
import {useRecoilState, useSetRecoilState} from "recoil";
import {notificationMessage, userLogoutState, userToken} from "../../recoil_state";
import {useTranslation} from "react-i18next";
import {store} from "react-context-hook";
import axios from "axios";
import {useGetHeaderPushNotificationLogByUserId} from "../pages/announcement/function/common";

const useAuthorize = () => {
    const navigate = useNavigate();
    const {verifyAccessToken} = useVerifyAccessTokenMutation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const setUserToken = useSetRecoilState<any>(userToken);
    const [userLogout, setUserLogout] = useRecoilState(userLogoutState);
    const [pushNotificationData, pushNotificationTotal, fetchPushNotificationLog, loading] = useGetHeaderPushNotificationLogByUserId();
    const {t} = useTranslation();

    const handleTokenExpiration = () => {
        localStorage.setItem("userId", "");
        localStorage.setItem("a_t", "");
        localStorage.removeItem("ghost_a_t");
        localStorage.removeItem("g_userId");
        localStorage.removeItem("g_username");
        setUserToken("");
        setUserLogout(true);
        navigate("/login");
    };

    const checkTokenExpiration = async (userToken) => {
        try {
            if (userToken) {
                const verify = await verifyAccessToken(userToken);
                if (verify?.data?.verifyAccessToken?.login) {
                    setUserLogout(false);
                    fetchPushNotificationLog()
                    return "verify";
                } else {
                    handleTokenExpiration();
                    return "failed";
                }
            } else {
                handleTokenExpiration();
                return "failed";
            }
        } catch (e) {
            console.log(e);
        }
    };

    const trackUserLogout = async () => {
        const logoutChannel = new BroadcastChannel("logoutChannel");
        logoutChannel.onmessage = (event) => {
            if (event.data === "logout") {
                setUserToken("");
                store.reset;
                handleTokenExpiration()
            }
        };

        if (userLogout) {
            logoutChannel.postMessage("logout");
        }
    };

    const trackUserActivity = (timeoutInMinutes, logoutFunction) => {
        let timer;
        let isLoggedIn = true;
        let isPageVisible = true;
        const broadcastChannel = new BroadcastChannel("userActivityChannel"); // Create a new Broadcast Channel

        const expiredFunction = () => {
            if (isLoggedIn && isPageVisible) {
                isLoggedIn = false; // Set the flag to false to indicate user logout
                clearTimeout(timer);
                logoutFunction();

                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: "Session Expired",
                    key: "systemNotification",
                });
            }
        };

        const resetTimer = () => {
            clearTimeout(timer);
            timer = setTimeout(expiredFunction, timeoutInMinutes);
        };

        const clearTimer = () => {
            clearTimeout(timer);
        };

        const activityHandler = () => {
            resetTimer();
            broadcastChannel.postMessage("userActive"); // Notify other tabs that user is active
        };

        // Add event listeners for user activity
        window.addEventListener("mousemove", activityHandler);
        window.addEventListener("keydown", activityHandler);
        window.addEventListener("mousedown", activityHandler);

        // Listen for broadcast messages from other tabs
        broadcastChannel.onmessage = (event) => {
            if (event.data === "userActive") {
                resetTimer(); // Reset the timer when a broadcast message is received from another tab indicating user activity
            }
        };

        // Initialize the timer
        resetTimer();

        // Function to stop tracking user activity and clear the timer
        const stopTrackingUserActivity = () => {
            isLoggedIn = false;
            window.removeEventListener("mousemove", activityHandler);
            window.removeEventListener("keydown", activityHandler);
            window.removeEventListener("mousedown", activityHandler);
            broadcastChannel.close(); // Close the Broadcast Channel when stopping tracking
            clearTimer();
        };

        // Return the function to stop tracking user activity
        return stopTrackingUserActivity;
    };

    const startTokenExpirationCheck = async (intervalInMinutes) => {
        try {
            let tokenExpirationInterval;
            const token = localStorage.getItem("a_t");

            async function verifyToken() {
                try {
                    const tokenLatest = localStorage.getItem("a_t");
                    if (tokenLatest) {
                        const result = await checkTokenExpiration(tokenLatest);
                        if (result === "failed") {
                            setNotificationMessage({
                                status: "error",
                                title: "",
                                msg: "Session Expired",
                                key: "systemNotification",
                            });

                            clearInterval(tokenExpirationInterval);
                        }
                    } else {
                        clearInterval(tokenExpirationInterval);
                    }
                } catch (e) {
                    clearInterval(tokenExpirationInterval);
                    systemErrorNotification();
                }
            }

            if (token) {
                await verifyToken();
                tokenExpirationInterval = setInterval(verifyToken, intervalInMinutes);
            } else {
                setUserLogout(true);
            }

            function stopTokenExpirationCheck() {
                clearInterval(tokenExpirationInterval);
            }

            return stopTokenExpirationCheck;
        } catch (e) {
            console.log(e);
        }
    };

    const redirectToClient = async (currentPath) => {
        const tokenLatest = localStorage.getItem("a_t");
        setUserToken(tokenLatest);

        if (tokenLatest) {
            // const result = await checkTokenExpiration(tokenLatest);
            // if (result === "failed") {
            //     setNotificationMessage({
            //         status: "error",
            //         title: "",
            //         msg: "Session Expired",
            //         key: "systemNotification",
            //     });
            //
            // } else
            if (currentPath === "/" || !currentPath) navigate("/users/client");
        } else {
            if (currentPath === "/" || !currentPath) navigate("/users/client");
            else return;
        }
    };

    const baseErrorChecking = async (response) => {
        if (response?.__typename === "BaseError") {
            if (response?.errKey === "admin.auth.err.token_expired") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: "Session Expired",
                    key: "systemNotification",
                });

                handleTokenExpiration();
            } else {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: response?.errKey,
                    key: "systemNotification",
                });
            }
            return {
                status: "fail",
            };
        } else if (response?.typename === "CrmTaskApproval") {
            setNotificationMessage({
                status: "info",
                title: "",
                msg: t(response?.msg),
                key: "updatePlatform",
            });

            return {
                status: "success",
            };
        }
    };

    const systemErrorNotification = (errorMessage = null) => {
        const token = localStorage.getItem("a_t");

        if (token) {
            setNotificationMessage({
                status: "error",
                title: "Error",
                msg: errorMessage || "An unexpected error occurred. Please try again later.",
                key: "systemNotification",
            });
        } else if (!userLogout) {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Session Expired",
                key: "systemNotification",
            });

            handleTokenExpiration();
        }
    };

    const getUserIPAddress = async () => {
        try {
            // const res = await axios.get("https://geolocation-db.com/json/", {timeout: 2000});
            // return res?.data?.IPv4;
            const res = await axios.get("https://api.ipify.org?format=json", {timeout: 2000});
            return res?.data?.ip
        } catch (error) {
            return null;
        }
    };

    const getClientDeviceInfo = async () => {
        let browser = (navigator as any).userAgentData;

        if (browser) {
            browser = browser.brands.find((b) => b.brand !== "Chromium" && !b.brand.includes("Brand"))?.brand;
        } else {
            browser = navigator.userAgent;
            if (browser.match(/chrome|chromium|crios/i)) {
                browser = "Chrome";
            } else if (browser.match(/firefox|fxios/i)) {
                browser = "Firefox";
            } else if (browser.match(/safari/i)) {
                browser = "Safari";
            } else if (browser.match(/opr\//i)) {
                browser = "Opera";
            } else if (browser.match(/edg/i)) {
                browser = "Edge";
            } else {
                browser = "Unknown";
            }
        }

        const userAgent = navigator.userAgent;
        let device = "Unknown";
        if (/Android/i.test(userAgent)) {
            device = "Android";
        } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
            device = "iOS";
        } else if (/Windows Phone|IEMobile|WPDesktop/i.test(userAgent)) {
            device = "Windows Phone";
        } else if (/Windows/i.test(userAgent)) {
            device = "Windows";
        } else if (/Macintosh|MacIntel|MacPPC|Mac68K/i.test(userAgent)) {
            device = "Mac";
        } else if (/Linux|X11/i.test(userAgent)) {
            device = "Linux";
        }

        try {
            // const token = await fetchFCMToken();
            // const res = await axios.get("https://geolocation-db.com/json/", {timeout: 2000});
            const res = await axios.get("https://api.ipify.org?format=json", {timeout: 2000});
            return {
                // ip: res?.data?.IPv4 || null,
                ip: res?.data?.ip || null,
                // deviceToken: token || null,
                browser,
                device,
            };
        } catch (error) {
            return {
                ip: null,
                // deviceToken: null,
                browser,
                device,
            };
        }
    };

    return {
        handleTokenExpiration,
        checkTokenExpiration,
        trackUserLogout,
        trackUserActivity,
        startTokenExpirationCheck,
        redirectToClient,
        baseErrorChecking,
        systemErrorNotification,
        getUserIPAddress,
        getClientDeviceInfo,
    };
};

export default useAuthorize;
