import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Modal} from "antd";
import LogTable from "./logTable";
import {getLogger} from "../../../../../../api/graphql/logger";


const LogModal = (props) => {
    const {isOpen, closeModal, parent,idModal} = props;
    const {t} = useTranslation()

    const [limit, setLimit] = useState(20)
    const [offset, setOffset] = useState(0)
    const [filter, setFilter] = useState({})
    const [order, setOrder] = useState('id DESC')
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [dateKey, setDateKey] = useState(null)

    const {data, refetchQuery, loading} = getLogger({
        filter: {
            ...filter,
            parent: parent
        }
    }, {}, order, limit, offset, startDate, endDate, dateKey)

    return (
        <Modal
            title={""}
            open={isOpen}
            onCancel={() => closeModal()}
            footer={null}
            width={1300}
            destroyOnClose
        >
            <LogTable
                data={data?.getLogger?.data}
                total={data?.getLogger?.total}
                refetchQuery={refetchQuery}
                loading={loading}
                limit={limit}
                setLimit={setLimit}
                setOffset={setOffset}
                setOrder={setOrder}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setDateKey={setDateKey}
                setFilter={setFilter}
                tableName={"Relevant Audit Logs"}
                idModal={idModal}
            />
        </Modal>
    )
}

export default LogModal