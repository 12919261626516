import { gql, useMutation, useQuery } from "@apollo/client";

const GET_PROTECTION_PROFILE_COUNT = gql`
  query getProtectionProfileCount($filter: ProtectionProfileInput) {
    getProtectionProfileCount(filter: $filter)
  }
`;

const getProtectionProfileCount = ({ filter }) => {
  try {
    const { loading, error, data } = useQuery(GET_PROTECTION_PROFILE_COUNT, {
      variables: {
        filter: {
          protectionProfileId: filter?.protectionProfileId || null,
          name: filter?.name || null,
          serverName: filter?.serverName || null,
        },
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_PROTECTION_PROFILE_BY_ID = gql`
  query getProtectionProfileByID($id: String!) {
    getProtectionProfileByID(id: $id) {
      ... on ProtectionProfile {
        id
        protectionProfileId
        name
        description
        gslCharge
        slDistance
        tpDistance
        gslDistance
        distanceSetIn
        tolerance
        server {
          name
        }
        createdBy {
          username
        }
        updatedBy {
          username
        }
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getProtectionProfileById = (id) => {
  try {
    const { loading, error, data } = useQuery(GET_PROTECTION_PROFILE_BY_ID, {
      variables: {
        id: id,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_PROTECTION_PROFILE = gql`
  query getProtectionProfile(
    $filter: ProtectionProfileInput
    $limit: Int
    $offset: Int
    $orderBy: String
  ) {
    getProtectionProfile(
      filter: $filter
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      ... on ProtectionProfileArray {
        data {
          id
          protectionProfileId
          name
          description
          gslCharge
          slDistance
          tpDistance
          gslDistance
          distanceSetIn
          tolerance
          server {
            name
          }
          createdBy {
            username
          }
          updatedBy {
            username
          }
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getProtectionProfile = ({ filter }, limit?, offset?, orderBy?) => {
  try {
    const { loading, error, data } = useQuery(GET_PROTECTION_PROFILE, {
      variables: {
        filter: {
          protectionProfileId: filter?.protectionProfileId || null,
          name: filter?.name || null,
          serverName: filter?.serverName || null,
        },
        limit: limit || null,
        offset: offset || null,
        orderBy: orderBy || null,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const REFRESH_PROTECTION_PROFILE = gql`
  mutation refreshProtectionProfile($server: String!) {
    refreshProtectionProfile(server: $server) {
      ... on ProtectionProfileArray {
        id
        protectionProfileId
        name
        description
        gslCharge
        slDistance
        tpDistance
        gslDistance
        distanceSetIn
        tolerance
        server {
          name
        }
        createdBy {
          username
        }
        updatedBy {
          username
        }
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useRefreshProtectionProfileMutation = (): {
  refreshProtectionProfile: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const [useRefreshProtectionProfileMutation, { data, loading, error }] =
      useMutation(REFRESH_PROTECTION_PROFILE);

    const refreshProtectionProfile = (input) => {
      return useRefreshProtectionProfileMutation({
        variables: {
          input: {
            server: input.server || null,
          },
        },
      });
    };

    return { refreshProtectionProfile, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return {
      refreshProtectionProfile: null,
      data: null,
      loading: false,
      error: err,
    };
  }
};

export {
  getProtectionProfileCount,
  getProtectionProfileById,
  getProtectionProfile,
  useRefreshProtectionProfileMutation,
};
