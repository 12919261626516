import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Col, Form, Modal, Row, Select, Space} from "antd";
import {CaretDownFilled} from "@ant-design/icons";
import {countryList_Language} from "../../../../../../../../../../../config/countryList_Language";

const SelectVerificationCountry = ({
                                       selectedCountry,
                                       handleSelectCountry,
                                       preSelectedCountry,
                                       verificationTypes,
                                       createNewVerificationRecord,
                                       isCreatingVerificationRecord,
                                       isCreateVerificationRecordModalOpen,
                                       setIsCreateVerificationRecordModalOpen,}) => {
    const {t} = useTranslation();
    const steps = verificationTypes || null;

    return (
        <>
            {/*<Card className="verification-sub-card">*/}
            <Space style={{width: "100%"}} size={16} direction="vertical">
                {/*<div className="account-verification-title">Select Country</div>*/}
                <Form layout="vertical">
                    <Form.Item label={t("accountVerification.selectCountry")} initialValue={preSelectedCountry}>
                        <Select
                            style={{width: "100%"}}
                            showSearch
                            // placeholder={t("accountVerification.placeholder.selectCountry")}
                            optionFilterProp="children"
                            value={selectedCountry}
                            defaultValue={preSelectedCountry}
                            onChange={(country) => handleSelectCountry(country)}
                            filterOption={(input, option:any) => (option?.label ?? "").toLowerCase().indexOf(input?.toLowerCase()) > -1}
                            suffixIcon={<CaretDownFilled style={{pointerEvents: "none"}}/>}
                            options={countryList_Language?.map(d => ({label: t(d?.label), value: t(d?.label)}))}
                            disabled={preSelectedCountry}
                            className="selectVerificationCountry"
                        />
                    </Form.Item>
                </Form>
                {steps?.length > 0 && (
                    // <Card>
                    <Row gutter={12} justify={"center"} className={"steps-preview-container m-1"}>
                        <Col span={24}>
                            {/*<div className={"mb-3 account-verification-title text-center"}><strong>{t("Verification Steps")}</strong></div>*/}
                            {steps.map((step, index) => {
                                let icon;
                                switch (step?.verificationType) {
                                    case "poi":
                                        icon = "ic-passport-license-num";
                                        break;
                                    case "por":
                                        icon = "residence-info";
                                        break;
                                    case "liveness_check":
                                        icon = "liveness-check";
                                        break;
                                    case "selfie":
                                        icon = "image";
                                        break;
                                    case "video_selfie":
                                        icon = "video";
                                        break;
                                }

                                return (
                                    <div className={"text-start mt-3 mx-auto"} key={step?._id}>
                                        <Space size={12}>
                                            {/*<IconCommon style={{width: 32}} className={"steps-icon"} iconName={icon}/>*/}
                                            <div className="text-start">
                                                <p className={"margin-bottom-0"}><strong>{`${t("accountVerification.step")?.toUpperCase()} ${index + 1} - ${t(step?.label)}`}</strong></p>
                                                {step?.description ? (
                                                    <p>{t(step?.description)}</p>
                                                ) : null}
                                            </div>
                                        </Space>
                                    </div>
                                );
                            })}
                        </Col>
                    </Row>
                    // </Card>
                )}
            </Space>
            {/*</Card>*/}
            <Modal
                className="verification-modal"
                title={t("accountVerification.startVerification")}
                open={isCreateVerificationRecordModalOpen}
                onCancel={() => setIsCreateVerificationRecordModalOpen(false)}
                onOk={() => createNewVerificationRecord()}
                destroyOnClose={true}
                width={"600px"}
                getContainer="#layout-4"
                footer={
                    <Row justify={"end"} gutter={16}>
                        <Col>
                            <Button className="secondary-button" onClick={() => setIsCreateVerificationRecordModalOpen(false)}>
                                {t("common.cancel")}
                            </Button>
                        </Col>
                        <Col>
                            <Button type={"primary"} className="primary-button" onClick={() => createNewVerificationRecord()} loading={isCreatingVerificationRecord}>
                                {t("accountVerification.startVerificationProcess")}
                            </Button>
                        </Col>
                    </Row>
                }
            >
                <div>{t("accountVerification.startVerificationDescription")}</div>
            </Modal>
        </>
    );
}

export default SelectVerificationCountry
