import { Form, Select  } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import FormErrors from './formErrors';
import { getCompanyEmail } from '../../api/graphql/companyEmail';

const EmailProviderFormItem = (props) => {
    const {
        layout,
        label,
        name,
        externalErrorMessage,
        onChange,
        hint,
        disabled,
        required,
        mode,
        showSearch,
        showArrow,
        allowClear,
        placeholder
    } = props

    const { 
        register, 
        watch,
        setValue,
        formState: { touchedFields, isSubmitted, errors }
    } = useFormContext()

    const [companyEmail, setCompanyEmail] = useState([])

    const getCompanyEmailData = async () => {
        try {
            const response = await getCompanyEmail({
                filter: null,
            });
            const data = response?.getCompanyEmail?.data;
            setCompanyEmail(data);
        } catch (error) {
        } finally {
        }
    };

  

    useEffect(() => {
        getCompanyEmailData();
    }, []);

    const mapData = (companyEmail) => {
        let temp = companyEmail?.map(item => {
            return {
                label: item?.name + " - " + item?.email,
                value: item?._id
            }})
        return temp
    }

    const errorMessage = FormErrors.errorMessage(
        name,
        errors,
        touchedFields,
        isSubmitted,
        externalErrorMessage
    )
    
    const formHelperText = errorMessage || hint

    useEffect(() => {
        register(name)
    }, [register, name])
    

    return (
        <Form.Item
            {...layout}
            label={label}
            required={required}
            validateStatus={errorMessage?'error':'success'}
            help={formHelperText}
        >
                <Select
                    id={name}
                    mode={mode}
                    options={companyEmail && mapData(companyEmail)}
                    value={watch(name)}
                    onChange={(e)=>{
                        setValue(name, e, {shouldValidate: true})
                        onChange && onChange(e)
                    }}
                    placeholder={placeholder}
                    showSearch={showSearch}
                    showArrow={showArrow}
                    allowClear={allowClear}
                    disabled={disabled}
                />
        </Form.Item>
    )
}

EmailProviderFormItem.defaultProps = {
    required: false,
    disabled: false,
}

EmailProviderFormItem.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    externalErrorMessage: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    layout: PropTypes.object,
    hint: PropTypes.string,
    showSearch: PropTypes.bool,
    showArrow: PropTypes.bool,
    allowClear: PropTypes.bool,
    placeholder: PropTypes.string,
    mode: PropTypes.string,
}

export default EmailProviderFormItem