import {gql, useMutation, useQuery} from "@apollo/client";
import {useSetRecoilState} from "recoil";
import {useTranslation} from "react-i18next";
import {notificationMessage} from "../../../recoil_state";
import {ApolloClientQuery} from "./_apolloClient";

const GET_PSP_SETTING_CURRENCY_COUNT = gql`
    query getPspSettingCurrencyCount($filter: PspSettingCurrencySearchInput) {
        getPspSettingCurrencyCount(filter: $filter)
    }
`;

const getPspSettingCurrencyCount = ({filter}) => {
    try {
        const {loading, error, data} = useQuery(GET_PSP_SETTING_CURRENCY_COUNT, {
            variables: {
                filter: {
                    pspSetting: filter?.pspSetting || null,
                    currency: filter?.currency || null,
                    depositEnabled: filter?.depositEnabled || null,
                    withdrawalEnabled: filter?.withdrawalEnabled || null,
                    settlement: filter?.settlement || null,
                    settlementFixed: filter?.settlementFixed || null,
                },
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_PSP_SETTING_CURRENCY_BY_ID = gql`
    query getPspSettingCurrencyByID($id: String!) {
        getPspSettingCurrencyByID(id: $id) {
            ... on PspSettingCurrency {
                _id
                id
                pspSetting {
                    _id
                    id
                    position
                    name
                    displayName
                    processingTime
                    description
                    enabled
                    depositEnabled
                    withdrawalEnabled
                    showBankDetails
                    logo_light {
                        link
                        fileList
                        language
                    }
                    logo_dark {
                        link
                        fileList
                        language
                    }
                    logoSmall_light {
                        link
                        fileList
                        language
                    }
                    logoSmall_dark {
                        link
                        fileList
                        language
                    }
                    minDeposit
                    minWithdrawal
                    maxDeposit
                    maxWithdrawal
                    isDeleted
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
                currency
                conversionRate
                digits
                fixedRate
                depositEnabled
                withdrawalEnabled
                settlement
                settlementFixed
                availableBank {
                    bankCode
                    bankName
                }
                isDeleted
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getPspSettingCurrencyById = (id) => {
    try {
        const {loading, error, data} = useQuery(GET_PSP_SETTING_CURRENCY_BY_ID, {
            variables: {
                id: id,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_PSP_SETTING = gql`
    query getPspSettingCurrency(
        $filter: PspSettingCurrencySearchInput
        $limit: Int
        $offset: Int
        $orderBy: String
    ) {
        getPspSettingCurrency(
            filter: $filter
            limit: $limit
            offset: $offset
            orderBy: $orderBy
        ) {
            ... on PspSettingCurrencyArray {
                data {
                    _id
                    id
                    pspSetting {
                        _id
                        id
                        position
                        name
                        displayName
                        processingTime
                        description
                        enabled
                        depositEnabled
                        withdrawalEnabled
                        showBankDetails
                        logo_light {
                            fileList
                            link
                            language
                        }
                        logo_dark {
                            fileList
                            link
                            language
                        }
                        logoSmall_light {
                            fileList
                            link
                            language
                        }
                        logoSmall_dark {
                            fileList
                            link
                            language
                        }
                        minDeposit
                        minWithdrawal
                        maxDeposit
                        maxWithdrawal
                        isDeleted
                        createdBy {
                            username
                        }
                        updatedBy {
                            username
                        }
                        createdAt
                        updatedAt
                    }
                    currency
                    conversionRate
                    digits
                    fixedRate
                    depositEnabled
                    withdrawalEnabled
                    settlement
                    settlementFixed
                    availableBank {
                        bankCode
                        bankName
                    }
                    isDeleted
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getPspSettingCurrency = ({filter}, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data} = useQuery(GET_PSP_SETTING, {
            variables: {
                filter: {
                    pspSetting: filter?.pspSetting || null,
                    currency: filter?.currency || null,
                    depositEnabled: filter?.depositEnabled || null,
                    withdrawalEnabled: filter?.withdrawalEnabled || null,
                    settlement: filter?.settlement || null,
                    settlementFixed: filter?.settlementFixed || null,
                    taskView: filter?.taskView || null
                },
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, pspCurrencyData: data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const getPspSettingCurrencyAsync = async ({filter}, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_PSP_SETTING,
            {
                filter: {
                    pspSetting: filter?.pspSetting || null,
                    currency: filter?.currency || null,
                    depositEnabled: filter?.depositEnabled || null,
                    withdrawalEnabled: filter?.withdrawalEnabled || null,
                    settlement: filter?.settlement || null,
                    settlementFixed: filter?.settlementFixed || null,
                    taskView: filter?.taskView || null
                },
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

// const ADD_PSP_SETTING = gql`
//   mutation addPspSettingCurrency($input: PspSettingCurrencyInput) {
//     addPspSettingCurrency(input: $input) {
//       ... on CrmTaskApproval {
//         msg
//       }
//       ... on PspSettingCurrency {
//         _id
//         id
//         pspSetting {
//           _id
//           id
//           name
//           displayName
//           processingTime
//           description
//           enabled
//           depositEnabled
//           withdrawalEnabled
//           logo {
//             displayName
//             fileName
//             path
//             fileList
//             language
//             document
//             link
//           }
//           logoSmall {
//             displayName
//             fileName
//             path
//             fileList
//             language
//             document
//             link
//           }
//           minDeposit
//           minWithdrawal
//           maxDeposit
//           maxWithdrawal
//           isDeleted
//           createdBy {
//             username
//           }
//           updatedBy {
//             username
//           }
//           createdAt
//           updatedAt
//         }
//         pspCurrency {
//           _id
//           id
//           currency
//           conversionRate
//           fixedRate
//           depositEnabled
//           withdrawalEnabled
//           settlement
//           settlementFixed
//           availableBank
//         }
//         depositEnabled
//         withdrawalEnabled
//         paymentMethod
//         minDeposit
//         minWithdrawal
//         maxDeposit
//         maxWithdrawal
//         feeType
//         feeRounding
//         depositFee
//         depositFeeFixed
//         depositFeeCondition
//         depositFeeClient
//         depositFeeFixedClient
//         withdrawalFee
//         withdrawalFeeFixed
//         withdrawalFeeCondition
//         withdrawalFeeClient
//         withdrawalFeeFixedClient
//         createdBy {
//           username
//         }
//         updatedBy {
//           username
//         }
//         createdAt
//         updatedAt
//       }
//       ... on BaseError {
//         errKey
//         errMsg
//       }
//     }
//   }
// `;

// const useAddPspSettingCurrencyMutation = (): {
//   addPspSettingCurrency: any;
//   data: any;
//   loading: boolean;
//   error: any;
// } => {
//   try {
//     const { t } = useTranslation();
//     const setNotificationMessage = useSetRecoilState(notificationMessage);
//     const [addPspSettingCurrencyMutation, { data, loading, error }] = useMutation(
//       ADD_PSP_SETTING,
//       {
//         update(cache, { data: { addPspSettingCurrency } }) {
//           let typename = addPspSettingCurrency?.__typename;
//           if (typename === "BaseError") {
//             setNotificationMessage({
//               status: "error",
//               title: "",
//               msg: t(data?.addPspSettingCurrency?.errKey),
//               key: "addPspSettingCurrency",
//             });
//           } else if (typename === "CrmTaskApproval") {
//             setNotificationMessage({
//               status: "info",
//               title: "",
//               msg: t(data?.addPspSettingCurrency?.msg),
//               key: "addPspSettingCurrency",
//             });
//           } else {
//             cache.modify({
//               fields: {
//                 getPspSetting(existingData = []) {
//                   const newRef = cache.writeFragment({
//                     data: addPspSettingCurrency,
//                     fragment: gql`
//                       fragment NewPspSettingCurrency on PspSettingCurrency {
//                         _id
//                         id
//                         pspSetting {
//                           _id
//                           id
//                           name
//                           displayName
//                           processingTime
//                           description
//                           enabled
//                           depositEnabled
//                           withdrawalEnabled
//                           logo {
//                             displayName
//                             fileName
//                             path
//                             fileList
//                             language
//                             document
//                             link
//                           }
//                           logoSmall {
//                             displayName
//                             fileName
//                             path
//                             fileList
//                             language
//                             document
//                             link
//                           }
//                           minDeposit
//                           minWithdrawal
//                           maxDeposit
//                           maxWithdrawal
//                           isDeleted
//                           createdBy {
//                             username
//                           }
//                           updatedBy {
//                             username
//                           }
//                           createdAt
//                           updatedAt
//                         }
//                         pspCurrency {
//                           _id
//                           id
//                           currency
//                           conversionRate
//                           fixedRate
//                           depositEnabled
//                           withdrawalEnabled
//                           settlement
//                           settlementFixed
//                           availableBank
//                         }
//                         depositEnabled
//                         withdrawalEnabled
//                         paymentMethod
//                         minDeposit
//                         minWithdrawal
//                         maxDeposit
//                         maxWithdrawal
//                         feeType
//                         feeRounding
//                         depositFee
//                         depositFeeFixed
//                         depositFeeCondition
//                         depositFeeClient
//                         depositFeeFixedClient
//                         withdrawalFee
//                         withdrawalFeeFixed
//                         withdrawalFeeCondition
//                         withdrawalFeeClient
//                         withdrawalFeeFixedClient
//                         createdBy {
//                           username
//                         }
//                         updatedBy {
//                           username
//                         }
//                         createdAt
//                         updatedAt
//                       }
//                     `,
//                   });
//                   return {
//                     ...existingData,
//                     data:
//                       existingData?.data?.length > 0
//                         ? [newRef, ...existingData?.data]
//                         : [newRef],
//                   };
//                 },
//                 getPspSettingCount(existingData) {
//                   return existingData + 1;
//                 },
//               },
//             });
//             setNotificationMessage({
//               status: "success",
//               title: "",
//               msg: t("Added Successfully"),
//               key: "addPspSettingCurrency",
//             });
//           }
//         },
//       }
//     );

//     const addPspSettingCurrency = (input) => {
//       return addPspSettingCurrencyMutation({
//         variables: {
//           input: {
//             pspSetting: input.pspSetting || null,
//             currency: input.currency || null,
//             displayName: input.displayName || null,
//             paymentMethod: input.paymentMethod || null,
//             conversionRate: input.conversionRate || null,
//             deposit: input.deposit || null,
//             withdrawal: input.withdrawal || null,
//             minDeposit: input.minDeposit || null,
//             minWithdrawal: input.minWithdrawal || null,
//             maxDeposit: input.maxDeposit || null,
//             maxWithdrawal: input.maxWithdrawal || null,
//             feeType: input.feeType || null,
//             depositFee: input.depositFee || null,
//             depositFeeFixed: input.depositFeeFixed || null,
//             depositFeeCondition: input.depositFeeCondition || null,
//             depositFeeClient: input.depositFeeClient || null,
//             depositFeeFixedClient: input.depositFeeFixedClient || null,
//             withdrawalFee: input.withdrawalFee || null,
//             withdrawalFeeFixed: input.withdrawalFeeFixed || null,
//             withdrawalFeeCondition: input.withdrawalFeeCondition || null,
//             withdrawalFeeClient: input.withdrawalFeeClient || null,
//             withdrawalFeeFixedClient: input.withdrawalFeeFixedClient || null,
//             settlement: input.settlement || null,
//             settlementFixed: input.settlementFixed || null,
//           },
//         },
//       });
//     };

//     return { addPspSettingCurrency, data, loading, error };
//   } catch (err: any) {
//     console.error(err);
//     return {
//       addPspSettingCurrency: null,
//       data: null,
//       loading: false,
//       error: err,
//     };
//   }
// };

const UPDATE_PSP_SETTING = gql`
    mutation updatePspSettingCurrency(
        $id: String!
        $input: PspSettingCurrencyInput
    ) {
        updatePspSettingCurrency(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on PspSettingCurrency {
                _id
                id
                pspSetting {
                    _id
                    id
                    name
                    displayName
                    processingTime
                    description
                    enabled
                    depositEnabled
                    withdrawalEnabled
                    showBankDetails
                    logo_light {
                        link
                        fileList
                        language
                    }
                    logo_dark {
                        link
                        fileList
                        language
                    }
                    logoSmall_light {
                        link
                        fileList
                        language
                    }
                    logoSmall_dark {
                        link
                        fileList
                        language
                    }
                    minDeposit
                    minWithdrawal
                    maxDeposit
                    maxWithdrawal
                    bankWireBankAddress
                    bankWireBankName
                    bankWireBankAccountNo
                    isDeleted
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
                currency
                conversionRate
                digits
                fixedRate
                depositEnabled
                withdrawalEnabled
                settlement
                settlementFixed
                availableBank {
                    bankCode
                    bankName
                }
                isDeleted
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useUpdatePspSettingCurrencyMutation = (): {
    updatePspSettingCurrency: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const {t} = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updatePspSettingCurrencyMutation, {data, loading, error}] =
            useMutation(UPDATE_PSP_SETTING);

        const updatePspSettingCurrency = (id, input) => {
            return updatePspSettingCurrencyMutation({
                variables: {
                    id: id,
                    input: {
                        pspSetting: input.pspSetting || null,
                        currency: input.pspCurrency || null,
                        conversionRate: input.conversionRate || null,
                        digits: input.digits || null,
                        fixedRate: input.fixedRate || null,
                        depositEnabled: input.depositEnabled || null,
                        withdrawalEnabled: input.withdrawalEnabled || null,
                        settlememt: input.settlememt || null,
                        settlememtFixed: input.settlememtFixed || null,
                    },
                },
                update(cache, {data: {updatePSPSettingCurrency}}) {
                    const typename = updatePSPSettingCurrency?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updatePSPSettingCurrency?.errKey),
                            key: "updatePSPSettingCurrency",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updatePSPSettingCurrency?.msg),
                            key: "updatePSPSettingCurrency",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Successfully"),
                            key: "updatePSPSettingCurrency",
                        });
                    }
                },
            });
        };

        return {updatePspSettingCurrency, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            updatePspSettingCurrency: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

export {
    getPspSettingCurrencyCount,
    getPspSettingCurrencyById,
    getPspSettingCurrency,
    getPspSettingCurrencyAsync,
    // useAddPspSettingCurrencyMutation,
    useUpdatePspSettingCurrencyMutation,
};
