import React, {useMemo, useState} from "react"
import {useRecoilValue} from "recoil"
import {useTranslation} from "react-i18next"
import {Avatar, Checkbox, Col, Form, Input, InputNumber, Row, Select, Space, Upload, message} from "antd"
import {UploadOutlined} from "@ant-design/icons"
import {getAvailableProductByUserId} from "../../../../../../../../../api/graphql/product"
import {userOptionsState} from "../../../../../../../_common/state"
import {getUserNetworkTreeDownline} from "../../../../../../../../../api/graphql/userNetworkTree"
import {formatUserOptions} from "../../../../../../../../../function/_common"
import {getConfigByName} from "../../../../../../../../../../config"
import {BRAND_NAME} from "../../../../../../../../../../config/brandVariable"
import useAuthorize from "../../../../../../../../../_common/authorize"

const MoneyManagerAccountSettingsForm = (props) => {
    const {form, labelCol, data, selectedProductObj, selectedProduct, setProfileImage} = props
    const {t} = useTranslation()
    const {systemErrorNotification} = useAuthorize()

    const [currentSelectUsers, setCurrentSelectUsers] = useState<any[]>([])
    const [isSubscriptionFeeTicked, setIsSubscriptionFeeTicked] = useState(false)
    const [isMoneyManagerProfitSharingTicked, setIsMoneyManagerProfitSharingTicked] = useState(false)
    const [networkTypeSelect, setNetworkTypeSelect] = useState("all")

    const {networkTreeDownlineData} = getUserNetworkTreeDownline(currentSelectUsers)
    const {data: allAvailableProductByServer} = getAvailableProductByUserId(data?._id, selectedProductObj?.server?.name)

    const userOptions: any = useRecoilValue(userOptionsState)
    const networkTreeDownLineOptions = networkTreeDownlineData?.getUserNetworkTreeDownline?.data?.map((d) => {
        return formatUserOptions(d?.userid)
    })

    const handleSubscriptionFeeCheckBox = async (e) => {
        try {
            setIsSubscriptionFeeTicked(e?.target?.checked)
        } catch (error) {}
    }

    const handleMoneyManagerProfitSharingCheckBox = async (e) => {
        try {
            setIsMoneyManagerProfitSharingTicked(e?.target?.checked)
        } catch (error) {}
    }

    const investorProductOptions = useMemo(() => {
        let options: any = []
        if (selectedProductObj?.server?._id) {
            options = allAvailableProductByServer?.getAvailableProductByUserId?.data
                ?.filter((d) => {
                    let productType = selectedProductObj?.mode === "live" ? "investorLive" : "investorDemo"
                    return d?.mode === selectedProductObj?.mode && d?.server?._id === selectedProductObj?.server?._id && d?.mamPammProvider?.provider === selectedProductObj?.mamPammProvider?.provider && productType?.includes(d?.productType)
                })
                ?.map((d) => ({
                    label: (
                        <Row justify={"space-between"}>
                            <span>{d?.server?.name + " - " + d?.name}</span> <span>{d?.server?.platform?.toUpperCase()}</span>
                        </Row>
                    ),
                    searchLabel: `${d?.server?.name + " - " + d?.name} ${d?.server?.platform?.toUpperCase()}`,
                    value: d?._id,
                }))
        }
        return options
    }, [allAvailableProductByServer, selectedProductObj, selectedProduct])

    const timeframeOptions = [
        {label: t("mampamm.timeframe_option.daily"), value: "DAILY"},
        {label: t("mampamm.timeframe_option.weekly"), value: "WEEKLY"},
        {label: t("mampamm.timeframe_option.monthly"), value: "MONTHLY"},
        {label: t("mampamm.timeframe_option.weekly_plus_month_end"), value: "WEEKLY_PLUS_MONTH_END"},
        // {label: t("mampamm.timeframe_option.quarterly"), value: "QUARTERLY"},
        // {label: t("mampamm.timeframe_option.yearly"), value: "YEARLY"},
    ]

    const networkTypeOptions = [
        {label: t("network.all"), value: "all"},
        {label: t("network.partial"), value: "partial"},
        {label: t("network.onlyMe"), value: "onlyMe"},
    ]

    //   GROSS_PROFIT
    //   GROSS_REALISED_PROFIT
    //   NET_PROFIT
    //   NET_REALISED_PROFIT

    const moneyManagerProfitSharingTypeOptions = [
        // {label: t("mampamm.money_manager_profit_sharing_type_option.gross_profit"), value: "GROSS_PROFIT"},
        // {label: t("mampamm.money_manager_profit_sharing_type_option.gross_realised_profit"), value: "GROSS_REALISED_PROFIT"},
        {label: t("mampamm.money_manager_profit_sharing_type_option.net_profit"), value: "NET_PROFIT"},
        // {label: t("mampamm.money_manager_profit_sharing_type_option.net_realised_profit"), value: "NET_REALISED_PROFIT"},
    ]

    const easyMAMTradingModeOptions = [
        {label: t("mampamm.trading_mode_option.equity"), value: "EQUITY"},
        {label: t("mampamm.trading_mode_option.balance"), value: "BALANCE"},
        {label: t("mampamm.trading_mode_option.free_margin"), value: "FREE_MARGIN"},
        {label: t("mampamm.trading_mode_option.post_trade_equity"), value: "POST_TRADE_EQUITY"},
        {label: t("mampamm.trading_mode_option.post_trade_balance"), value: "POST_TRADE_BALANCE"},
        {label: t("mampamm.trading_mode_option.post_trade_free_margin"), value: "POST_TRADE_FREE_MARGIN"},
    ]

    const brandConfig = getConfigByName(BRAND_NAME)

    const uploadOnChange = (e) => {
        try {
            if (e?.file?.status === "done") {
                setProfileImage(e?.file?.response?.[0]?.link)
                message.success(`${e?.file?.name} file upload successfully.`)
            } else if (e?.file?.status === "error") {
                message.error(`${e?.file?.name} file upload failed.`)
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    return (
        <Col span={24}>
            <h3>{t("mampamm.account_setting")}</h3>

            <Space direction={"vertical"} style={{width: "100%"}}>
                <Form.Item name={"name"} label={t("mampamm.name")} labelCol={labelCol} rules={[{required: true}]}>
                    <Input placeholder={t("mampamm.name").toString()} />
                </Form.Item>
                <Form.Item name={"description"} label={t("mampamm.description")} labelCol={labelCol}>
                    <Input placeholder={t("mampamm.description").toString()} />
                </Form.Item>
                <Form.Item name={"profileImage"} label={t("user.profileImage")} labelCol={labelCol}>
                    <Upload
                        action={`${brandConfig?.backendURL}/api/upload/mamPamm/image`}
                        listType="picture-card"
                        maxCount={1}
                        showUploadList={true}
                        defaultFileList={
                            data?.profileImage
                                ? [
                                      {
                                          uid: "profile-image",
                                          name: "profile-image",
                                          url: data?.profileImage,
                                      },
                                  ]
                                : []
                        }
                        onChange={(e) => uploadOnChange(e)}
                        onRemove={() => setProfileImage("")}
                    >
                        <Avatar src={data?.profileImage} size={64} icon={<UploadOutlined />} />
                    </Upload>
                </Form.Item>
                <Form.Item name={"investorProduct"} label={t("mampamm.investor_product")} labelCol={labelCol} rules={[{required: true}]}>
                    <Select options={investorProductOptions} placeholder={t("mampamm.investor_product")} showSearch filterOption={(input, option) => (option?.searchLabel ?? "")?.toString().toLowerCase().includes(input.toLowerCase())} />
                </Form.Item>
                <Form.Item name={"minDeposit"} label={t("common.minDeposit")} labelCol={labelCol} rules={[{required: true}]}>
                    <InputNumber placeholder={t("common.minDeposit").toString()} min={0} />
                </Form.Item>
                <Form.Item name={"autoDeposit"} label={t("mampamm.deposit_auto_approve")} labelCol={labelCol}>
                    <Checkbox onChange={(e) => form.setFieldValue("autoDeposit", e?.target?.checked)} />
                </Form.Item>
                <Form.Item name={"autoWithdrawal"} label={t("mampamm.withdrawal_auto_approve")} labelCol={labelCol}>
                    <Checkbox onChange={(e) => form.setFieldValue("autoWithdrawal", e?.target?.checked)} />
                </Form.Item>

                {/* Subscription */}
                <Form.Item label={t("mampamm.has_subscription_fee")} labelCol={labelCol}>
                    <Checkbox onChange={handleSubscriptionFeeCheckBox} checked={isSubscriptionFeeTicked}></Checkbox>
                </Form.Item>
                {isSubscriptionFeeTicked && (
                    <>
                        <Form.Item name={"subscriptionFee"} label={t("mampamm.subscription_fee")} labelCol={labelCol} rules={[{required: true}]}>
                            <InputNumber placeholder={t("mampamm.subscriptionFee").toString()} min={0} />
                        </Form.Item>
                        <Form.Item name={"subscriptionFeeTimeframe"} label={t("mampamm.subscription_fee_timeframe")} labelCol={labelCol} rules={[{required: true}]}>
                            <Select options={timeframeOptions} placeholder={t("mampamm.subscription_fee_timeframe")} />
                        </Form.Item>
                    </>
                )}

                {/* Money Manager Profit Sharing */}
                <Form.Item label={t("mampamm.has_money_manager_profit_sharing")} labelCol={labelCol}>
                    <Checkbox onChange={handleMoneyManagerProfitSharingCheckBox} checked={isMoneyManagerProfitSharingTicked}></Checkbox>
                </Form.Item>
                {isMoneyManagerProfitSharingTicked && (
                    <>
                        <Form.Item name={"moneyManagerProfitSharingType"} label={t("mampamm.money_manager_profit_sharing_type")} labelCol={labelCol} rules={[{required: true}]}>
                            <Select options={moneyManagerProfitSharingTypeOptions} placeholder={t("mampamm.money_manager_profit_sharing_type")} />
                        </Form.Item>
                        <Form.Item name={"moneyManagerProfitSharingTimeframe"} label={t("mampamm.money_manager_profit_timeframe")} labelCol={labelCol} rules={[{required: true}]}>
                            <Select options={timeframeOptions} placeholder={t("mampamm.money_manager_profit_timeframe")} />
                        </Form.Item>
                        <Form.Item name={"moneyManagerProfitSharing"} label={t("mampamm.money_manager_profit_sharing")} labelCol={labelCol} rules={[{required: true}]}>
                            <InputNumber placeholder={t("mampamm.money_manager_profit_sharing").toString()} addonAfter="%" />
                        </Form.Item>
                        <Form.Item name={"traderProfitSharing"} label={t("mampamm.trader_profit_sharing")} labelCol={labelCol} rules={[{required: true}]} initialValue={data?.traderProfitSharing}>
                            <InputNumber placeholder={t("mampamm.trader_profit_sharing").toString()} addonAfter="%" min={0} max={100} style={{marginLeft: 20}} />
                        </Form.Item>
                        <Form.Item
                            name={"commissionProfitSharing"}
                            label={t("mampamm.commission_profit_sharing")}
                            labelCol={labelCol}
                            rules={[{required: true}]}
                            initialValue={data?.commissionProfitSharing}
                            help={<small style={{marginLeft: 20}}>Trader Profit Sharing + Commission Profit Sharing must be equal to Money Manager Profit Sharing</small>}
                        >
                            <InputNumber placeholder={t("mampamm.commission_profit_sharing").toString()} addonAfter="%" min={0} max={100} style={{marginLeft: 20}} />
                        </Form.Item>
                    </>
                )}

                <Form.Item name={"investorProfitSharing"} label={t("mampamm.investor_profit_sharing")} initialValue={100} labelCol={labelCol} rules={[{required: true}]}>
                    <InputNumber placeholder={t("mampamm.investor_profit_sharing").toString()} addonAfter="%" min={0} />
                </Form.Item>

                <Form.Item name={"networkType"} label={t("Which Network can View")} labelCol={labelCol} rules={[{required: true}]} initialValue={"all"}>
                    <Select options={networkTypeOptions} onChange={(value) => setNetworkTypeSelect(value)} value={networkTypeSelect} />
                </Form.Item>
                {networkTypeSelect === "partial" && (
                    <>
                        <Form.Item name={"network"} label={t("Include")} labelCol={labelCol}>
                            <Select
                                placeholder={t("network.network")}
                                onChange={(val) => setCurrentSelectUsers(val)}
                                options={userOptions}
                                optionLabelProp="selectedLabel"
                                mode={"multiple"}
                                filterOption={(input, option) => (option?.label ?? "")?.toString().toLowerCase().includes(input.toLowerCase())}
                            />
                        </Form.Item>
                        <Form.Item name={"exclude"} label={t("Exclude")} labelCol={labelCol}>
                            <Select
                                placeholder={t("network.exclude")}
                                options={networkTreeDownLineOptions}
                                optionLabelProp="selectedLabel"
                                mode={"multiple"}
                                filterOption={(input, option) => (option?.label ?? "")?.toString().toLowerCase().includes(input.toLowerCase())}
                            />
                        </Form.Item>
                    </>
                )}

                {selectedProductObj?.mamPammProvider?.provider === "easyMAM" && (
                    <>
                        <Form.Item name={"easyMAM_tradingMode"} label={t("mampamm.trading_mode")} labelCol={labelCol} rules={[{required: true}]}>
                            <Select placeholder={t("mampamm.trading_mode")} options={easyMAMTradingModeOptions} />
                        </Form.Item>
                    </>
                )}
            </Space>
        </Col>
    )
}

export default MoneyManagerAccountSettingsForm
