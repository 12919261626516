import {gql, useMutation, useQuery} from "@apollo/client";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {useTranslation} from "react-i18next";
import {GET_PRODUCT, GET_PRODUCT_COUNT} from "./product";

const ADD_MAM_PAMM_PRODUCT = gql`
    mutation addMamPammProduct($input: MamPammProductInput) {
        addMamPammProduct(input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on Product {
                id
                name
                code
                minDeposit
                spreadGroup {
                    name
                    id
                }
                leverage
                productType
                networkType
                network {
                    username
                    _id
                }
                exclude {
                    username
                    _id
                }
                productGroup {
                    name
                    _id
                }
                mamPammProvider {
                    name
                    _id
                }
                interestRate
                interestRateType
                server {
                    name
                }
                showPublic
                isDeleted
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useAddMamPammProductMutation = (): {
    addMamPammProduct: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [addMamPammProductMutation, {data, loading, error}] = useMutation(ADD_MAM_PAMM_PRODUCT);

        const addMamPammProduct = (input) => {
            return addMamPammProductMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {addMamPammProduct}}) {
                    const typename = addMamPammProduct?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(addMamPammProduct?.errKey),
                            key: "addMamPammProduct",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(addMamPammProduct?.msg),
                            key: "addMamPammProduct",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Add Product Successfully"),
                            key: "addMamPammProduct",
                        });
                    }
                },
                refetchQueries: [GET_PRODUCT, GET_PRODUCT_COUNT],
            });
        };

        return {addMamPammProduct, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {addMamPammProduct: null, data: null, loading: false, error: err};
    }
};

const UPDATE_MAM_PAMM_PRODUCT = gql`
    mutation updateMamPammProduct($id: String!, $input: MamPammProductInput) {
        updateMamPammProduct(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on Product {
                id
                name
                code
                minDeposit
                spreadGroup {
                    name
                }
                spreadGroupMt5 {
                    group
                }
                leverage
                productType
                networkType
                network {
                    username
                    _id
                }
                exclude {
                    username
                    _id
                }
                productGroup {
                    name
                    _id
                }
                mamPammProvider {
                    name
                    _id
                }
                interestRate
                interestRateType
                server {
                    name
                }
                showPublic
                isDeleted
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;

const useUpdateMamPammProductMutation = (): {
    updateMamPammProduct: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [updateMamPammProductMutation, {data, loading, error}] = useMutation(UPDATE_MAM_PAMM_PRODUCT);

        const updateMamPammProduct = (id, input) => {
            return updateMamPammProductMutation({
                variables: {
                    id: id,
                    input: input,
                },
                update(cache, {data: {updateMamPammProduct}}) {
                    const typename = updateMamPammProduct?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateMamPammProduct?.errKey),
                            key: "updateMamPammProduct",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateMamPammProduct?.msg),
                            key: "updateMamPammProduct",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Product Successfully"),
                            key: "updateMamPammProduct",
                        });
                    }
                },
            });
        };

        return {updateMamPammProduct, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {updateMamPammProduct: null, data: null, loading: false, error: err};
    }
};

const UPDATE_MAM_PAMM_PRODUCT_AND_CHANGE_SPREAD_GROUP = gql`
    mutation updateMamPammProductAndChangeSpreadGroupForExistingUser(
        $id: String!
        $input: MamPammProductInput
        $newSpreadGroup: String!
        $tradingAccount: [String]
    ) {
        updateMamPammProductAndChangeSpreadGroupForExistingUser(
            id: $id
            input: $input
            newSpreadGroup: $newSpreadGroup
            tradingAccount: $tradingAccount
        ) {
            ... on CrmTaskApproval {
                msg
            }
            ... on Product {
                id
                name
                code
                minDeposit
                spreadGroup {
                    name
                }
                spreadGroupMt5 {
                    group
                }
                leverage
                productType
                networkType
                network {
                    username
                    _id
                }
                exclude {
                    username
                    _id
                }
                productGroup {
                    name
                    _id
                }
                mamPammProvider {
                    name
                    _id
                }
                interestRate
                interestRateType
                server {
                    name
                }
                showPublic
                isDeleted
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;

const useUpdateMamPammProductAndChangeSpreadGroupForExistingUserMutation = (): {
    updateMamPammProductAndChangeSpreadGroupForExistingUser: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [updateMamPammProductAndChangeSpreadGroupForExistingUserMutation, {data, loading, error}] = useMutation(
            UPDATE_MAM_PAMM_PRODUCT_AND_CHANGE_SPREAD_GROUP
        );

        const updateMamPammProductAndChangeSpreadGroupForExistingUser = (id, input, newSpreadGroup, tradingAccount) => {
            return updateMamPammProductAndChangeSpreadGroupForExistingUserMutation({
                variables: {
                    id: id,
                    input: input,
                    newSpreadGroup: newSpreadGroup,
                    tradingAccount: tradingAccount,
                },
                update(cache, {data: {updateMamPammProductAndChangeSpreadGroupForExistingUser}}) {
                    const typename = updateMamPammProductAndChangeSpreadGroupForExistingUser?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateMamPammProductAndChangeSpreadGroupForExistingUser?.errKey),
                            key: "updateMamPammProduct",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateMamPammProductAndChangeSpreadGroupForExistingUser?.msg),
                            key: "updateMamPammProduct",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Product Successfully"),
                            key: "updateMamPammProduct",
                        });
                    }
                },
            });
        };

        return {updateMamPammProductAndChangeSpreadGroupForExistingUser, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {updateMamPammProductAndChangeSpreadGroupForExistingUser: null, data: null, loading: false, error: err};
    }
};

const DELETE_MAM_PAMM_PRODUCT = gql`
    mutation deleteMamPammProduct($id: String!) {
        deleteMamPammProduct(id: $id) {
            ... on CrmTaskApproval {
                msg
            }
            ... on Product {
                id
                isDeleted
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;

const useDeleteMamPammProductMutation = (): {
    deleteMamPammProduct: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [deleteMamPammProductMutation, {data, loading, error}] = useMutation(DELETE_MAM_PAMM_PRODUCT);

        const deleteMamPammProduct = (id) => {
            return deleteMamPammProductMutation({
                variables: {
                    id: id,
                },
                update(cache, {data: {deleteMamPammProduct}}) {
                    const typename = deleteMamPammProduct?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(deleteMamPammProduct?.errKey),
                            key: "deleteMamPammProduct",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(deleteMamPammProduct?.msg),
                            key: "deleteMamPammProduct",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Delete Product Successfully"),
                            key: "deleteMamPammProduct",
                        });
                    }
                },
                refetchQueries: [GET_PRODUCT, GET_PRODUCT_COUNT],
            });
        };

        return {deleteMamPammProduct, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {deleteMamPammProduct: null, data: null, loading: false, error: err};
    }
};

export {
    useAddMamPammProductMutation,
    useUpdateMamPammProductMutation,
    useDeleteMamPammProductMutation,
    useUpdateMamPammProductAndChangeSpreadGroupForExistingUserMutation,
};
