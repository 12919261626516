import React, {useEffect, useState} from "react";
import {Button, Col, Form, InputNumber, Modal, Row, Select} from "antd";
import {useTranslation} from "react-i18next";
import {updateCommissionParameter} from "../../../../../../../api/graphql/commission";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import useAuthorize from "../../../../../../../_common/authorize";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";


const ParameterEditDetailsModal = (props) => {

    const {
        data,
        isOpen,
        userData,
        closeModal,
        getParameter,
        type,
        setOverridingTotal
    } = props;
    const {t} = useTranslation()
    const [user, setUser] = useState([]);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [overridingDataInitial, setOverridingDataInitial] = useState([]);
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {systemErrorNotification} = useAuthorize()
    const labelCol = {span: 24, offset: 0}

    const preloadUser = async () => {

        const users = userData?.map((item) => {

            let label = item?.username;
            if (item?.identificationNo) {
                label += ` - ${item?.identificationNo}`;
            }
            if (item?.email) {
                label += ` (${item?.email})`;
            }
            return {
                label: label,
                value: item?._id
            }

        })

        setUser(users)
    }

    useEffect(() => {

        const dataInit: any = data;

        if (Array.isArray(dataInit?.overriding)) {
            const users = dataInit?.overriding?.map((item) => {
                return {
                    user: item?.user?._id,
                    amount: item?.amount,
                };
            });

            setOverridingDataInitial(users)
        }

    }, [data])

    useEffect(() => {
        preloadUser()
    }, [userData, data])

    const onSubmitOverriding = async (values) => {
        try {
            await setLoadingBtn(true)
            const response = await updateCommissionParameter(data._id, {
                overriding: values?.overriding
            })

            if (response?.updateCommissionParameter?.__typename === "CommissionParameter") {

                let overriding = 0;
                if (Array.isArray(values?.overriding) && values?.overriding?.length > 0) {

                    await Promise.all(values?.overriding?.map((item) => {
                        overriding += item?.amount
                    }))
                }

                setOverridingTotal(overriding)

                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Updated Successfully"),
                    key: "CommissionParameter",
                });
            } else if (response?.updateCommissionParameter.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.updateCommissionParameter?.errKey),
                    key: "CommissionParameter",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.updateCommissionParameter?.msg),
                    key: "CommissionParameter",
                });
            }

        } catch (e) {
            systemErrorNotification()
        } finally {
            getParameter()
            closeModal()
            setLoadingBtn(false)

        }
    }

    const onSubmitExcess = async (values) => {
        await setLoadingBtn(true)

        try {
            const response = await updateCommissionParameter(data._id, {
                excess: values
            })

            if (response?.updateCommissionParameter?.__typename === "CommissionParameter") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Updated Successfully"),
                    key: "CommissionParameter",
                });
            } else if (response?.updateCommissionParameter.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.updateCommissionParameter?.errKey),
                    key: "CommissionParameter",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.updateCommissionParameter?.msg),
                    key: "CommissionParameter",
                });
            }

        } catch (e) {
            systemErrorNotification()
        } finally {
            setLoadingBtn(false)

            getParameter()
            closeModal()
        }
    }

    const onSubmitCashback = async (values) => {
        await setLoadingBtn(true)

        try {
            const response = await updateCommissionParameter(data._id, {
                cashbackClient: values?.cashbackClient
            })

            if (response?.updateCommissionParameter?.__typename === "CommissionParameter") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Updated Successfully"),
                    key: "CommissionParameter",
                });
            } else if (response?.updateCommissionParameter.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.updateCommissionParameter?.errKey),
                    key: "CommissionParameter",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.updateCommissionParameter?.msg),
                    key: "CommissionParameter",
                });
            }

        } catch (e) {
            systemErrorNotification()
        } finally {
            setLoadingBtn(false)

            getParameter()
            closeModal()
        }
    }

    return (
        <Modal
            title={type === "overriding" ? "Parameter Settings - Overriding" : type === "excess" ? "Parameter Settings - Excess" : "Parameter Settings - Cashback"}
            open={isOpen}
            onCancel={() => closeModal()}
            footer={null}
            width={800}
            destroyOnClose
        >
            <Form
                onFinish={type === "overriding" ? onSubmitOverriding : type === "excess" ? onSubmitExcess : onSubmitCashback}>
                <Row gutter={20}>
                    {
                        type === "cashback" ? (
                            <Col span={24}>
                                <Form.Item
                                    labelCol={labelCol}
                                    label={t("Cashback")}
                                    name={"cashbackClient"}
                                    initialValue={data?.cashbackClient}
                                >
                                    <InputNumber min={0} step={2} style={{width: "100%"}}/>
                                </Form.Item>
                            </Col>
                        ) : type === "overriding" ? (
                            <>
                                <Col span={24}>
                                    <Form.List name="overriding" initialValue={overridingDataInitial}>
                                        {(fields, {add, remove}) => (
                                            <>
                                                <Row gutter={[16, 16]} style={{marginBottom: "3%"}}>
                                                    {fields.map(({key, name, ...restField}) => (
                                                        <>
                                                            <Col span={20}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'user']}
                                                                    rules={[{
                                                                        required: true,
                                                                        message: 'IB is required'
                                                                    }]}
                                                                >
                                                                    <Select options={user} showSearch
                                                                            optionFilterProp={"label"}/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={3}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'amount']}
                                                                    rules={[{
                                                                        required: true,
                                                                        message: 'Amount is required'
                                                                    }]}
                                                                >
                                                                    <InputNumber min={0} step={2}/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={1}>
                                                                <MinusCircleOutlined onClick={() => remove(name)}/>
                                                            </Col>
                                                        </>
                                                    ))}
                                                </Row>
                                                <Form.Item>
                                                    <Button type="dashed" onClick={() => add()} block
                                                            icon={<PlusOutlined/>}>
                                                        Add field
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Col>

                                {/*<Col span={20}>*/}
                                {/*    <Form.Item*/}
                                {/*        labelCol={labelCol}*/}
                                {/*        label={t("IB")}*/}
                                {/*        name={"user"}*/}
                                {/*        initialValue={data?.overriding?.user?.map((item) => {*/}
                                {/*            return item?._id*/}
                                {/*        }) || null}*/}
                                {/*    >*/}
                                {/*        <Select mode={"multiple"} options={user} showSearch optionFilterProp={"label"}/>*/}
                                {/*    </Form.Item>*/}
                                {/*</Col>*/}
                                {/*<Col span={4}>*/}
                                {/*    <Form.Item*/}
                                {/*        labelCol={labelCol}*/}
                                {/*        label={t("Amount")}*/}
                                {/*        name={"amount"}*/}
                                {/*        initialValue={data?.overriding?.amount}*/}
                                {/*    >*/}
                                {/*        <InputNumber min={0} step={2}/>*/}
                                {/*    </Form.Item>*/}
                                {/*</Col>*/}
                            </>
                        ) : (
                            <>
                                <Col span={20}>
                                    <Form.Item
                                        labelCol={labelCol}
                                        label={t("IB")}
                                        name={"user"}
                                        initialValue={data?.excess?.user?.map((item) => {
                                            return item?._id
                                        }) || null}
                                    >
                                        <Select mode={"multiple"} options={user} showSearch optionFilterProp={"label"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        labelCol={labelCol}
                                        label={t("Percentage")}
                                        name={"percentage"}
                                        initialValue={data?.excess?.percentage}
                                    >
                                        <InputNumber min={0} step={2}/>
                                    </Form.Item>
                                </Col>
                            </>
                        )
                    }


                    <Col span={24} style={{textAlign: "center", marginTop: "15px"}}>
                        <Button type={"primary"} htmlType={"submit"} style={{width: "100%"}} loading={loadingBtn}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal>

    )

}

export default ParameterEditDetailsModal