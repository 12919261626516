import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";

const GET_MAM_PAMM_LEGAL_DOCUMENT_BY_ID = gql`
    query getMamPammLegalDocumentByID($id: String!) {
        getMamPammLegalDocumentByID(id: $id) {
            ... on MamPammLegalDocument {
                _id
                id
                name
                isActive
                isDeleted
                isDefault
                displayName {
                    language
                    content
                }
                tinyMce {
                    language
                    content
                }
                isDeleted
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getMamPammLegalDocumentById = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            GET_MAM_PAMM_LEGAL_DOCUMENT_BY_ID,
            {
                id: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_MAM_PAMM_LEGAL_DOCUMENT = gql`
    query getMamPammLegalDocument($filter: MamPammLegalDocumentFilterInput, $limit: Int, $offset: Int, $orderBy: String) {
        getMamPammLegalDocument(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on MamPammLegalDocumentArray {
                total
                data {
                    _id
                    id
                    name
                    isActive
                    isDeleted
                    isDefault
                    displayName {
                        language
                        content
                    }
                    tinyMce {
                        language
                        content
                    }
                    isDeleted
                    defaultDocumentId
                    # createdBy {
                    #     username
                    # }
                    # updatedBy {
                    #     username
                    # }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getMamPammLegalDocument = async ({filter}, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_MAM_PAMM_LEGAL_DOCUMENT,
            {
                filter: {
                    language: filter?.language || null,
                    moneyManagerAccount: filter?.moneyManagerAccount || null,
                    isDefault: filter?.isDefault,
                },
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_MAM_PAMM_LEGAL_DOCUMENT = gql`
    mutation addMamPammLegalDocument($input: MamPammLegalDocumentInput) {
        addMamPammLegalDocument(input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on MamPammLegalDocument {
                _id
                id
                name
                isActive
                isDeleted
                isDefault
                displayName {
                    language
                    content
                }
                tinyMce {
                    language
                    content
                }
                isDeleted
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const addMamPammLegalDocument = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            ADD_MAM_PAMM_LEGAL_DOCUMENT,
            {
                input: {
                    name: input.name || null,
                    displayName: input.displayName || null,
                    tinyMce: input.tinyMce || null,
                    isActive: input.isActive ?? false,
                    isDefault: input.isDefault ?? false,
                    moneyManagerAccount: input.moneyManagerAccount || null,
                },
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UPDATE_MAM_PAMM_LEGAL_DOCUMENT = gql`
    mutation updateMamPammLegalDocument($id: String!, $input: MamPammLegalDocumentInput) {
        updateMamPammLegalDocument(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on MamPammLegalDocument {
                _id
                id
                name
                isActive
                isDeleted
                isDefault
                displayName {
                    language
                    content
                }
                tinyMce {
                    language
                    content
                }
                isDeleted
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const updateMamPammLegalDocument = async (id, input) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_MAM_PAMM_LEGAL_DOCUMENT,
            {
                id: id,
                input: {
                    name: input.name || null,
                    displayName: input.displayName || null,
                    tinyMce: input.tinyMce || null,
                    isActive: input.isActive ?? false,
                    isDefault: input.isDefault ?? false,
                },
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const DELETE_MAM_PAMM_LEGAL_DOCUMENT = gql`
    mutation deleteMamPammLegalDocument($id: String!) {
        deleteMamPammLegalDocument(id: $id) {
            ... on CrmTaskApproval {
                msg
            }
            ... on MamPammLegalDocument {
                _id
                id
                isDeleted
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const deleteMamPammLegalDocument = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            DELETE_MAM_PAMM_LEGAL_DOCUMENT,
            {
                id: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

export {getMamPammLegalDocumentById, getMamPammLegalDocument, addMamPammLegalDocument, updateMamPammLegalDocument, deleteMamPammLegalDocument};
