import React, {useCallback, useEffect, useState} from "react";
import {Form, Input, InputNumber, Modal, Select, Switch} from "antd";
import {useTranslation} from "react-i18next";
import {labelCol, validateMessages} from "../../../../../function";
import {useRecoilValue} from "recoil";
import {
    generalListCountryState,
    generalListDocumentGroupState,
    userVerificationConfigListState
} from "../../../../../state";
import {useFlowBuilderFunction} from "../../../../../function/flowBuilderFunction";
import TextArea from "antd/es/input/TextArea";

const StepModalForm = (props) => {
    const {isAdd, isOpen, close, item, isView} = props
    const {t} = useTranslation()
    const config = useRecoilValue<any>(userVerificationConfigListState)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [form] = Form.useForm()
    const {createUserVerificationStepsFunction, updateUserVerificationStepsFunction} = useFlowBuilderFunction()
    const countryList = useRecoilValue<any>(generalListCountryState)
    const [selectedRequiredType, setSelectedRequiredType] = useState<any>()
    const [selectedDocumentGroup, setSelectedDocumentGroup] = useState<any>([])

    const countryOptions = countryList?.map(d => ({label: t(d?.country), value: d?._id}))
    const groupList = useRecoilValue<any>(generalListDocumentGroupState)

    const onSubmit = async (value) => {
        setSubmitLoading(true)
        if (isAdd) {
            await createUserVerificationStepsFunction(value, item?.currentCountry)
        } else {
            delete value["country"]
            delete value["clientType"]
            delete value["verificationType"]
            await updateUserVerificationStepsFunction(item?.step?._id, value, item?.currentCountry)
        }
        setSubmitLoading(false)
        close()
    }

    useEffect(() => {
        form.resetFields()
    }, [isOpen])

    useEffect(() => {
        setSelectedRequiredType(item?.step?.requiredType || "")
        setSelectedDocumentGroup(item?.step?.documentGroup?.map(d => ({label: t(d?.group?.label), value: d?._id})))
    }, [item])

    const excludeExistedStep = useCallback(() => {
        const verificationType = config[`${item?.type?.clientType}VerificationType`]

        const option = verificationType?.map(t => {
            const match = item?.type?.steps?.find(d => d?.verificationType === t)
            if (!match) {
                return {label: t, value: t}
            }
        })?.filter(d => d !== undefined)

        return option ? option : []
    }, [item?.type])

    return (
        <Modal
            title={isAdd ? t("accountVerification.addStep") : isView ? t("accountVerification.step") : t("accountVerification.updateStep")}
            open={isOpen}
            onCancel={close}
            onOk={form.submit}
            cancelText={t("common.cancel")}
            okText={isAdd ? t("common.add") : t("common.update")}
            okButtonProps={{loading: submitLoading}}
            footer={isView ? null : undefined}
            destroyOnClose={true}
        >
            <Form form={form} onFinish={onSubmit} validateMessages={validateMessages}>
                <Form.Item
                    labelCol={labelCol}
                    label={t("accountVerification.country")}
                    name={"country"}
                    rules={[{required: true}]}
                    initialValue={item?.country}
                >
                    <Select
                        options={countryOptions}
                        disabled={item?.country || isView}
                        showSearch
                        optionFilterProp={"label"}
                    />
                </Form.Item>
                <Form.Item
                    labelCol={labelCol}
                    label={t("accountVerification.clientType")}
                    name={"clientType"}
                    rules={[{required: true}]}
                    initialValue={item?.type?.clientType || item?.type}
                >
                    <Select
                        options={config?.clientType?.map(d => ({
                            label: t(`accountVerification.clientTypeOption.${d}`),
                            value: d
                        }))}
                        disabled={item?.type?.clientType || item?.type || isView}
                    />
                </Form.Item>
                <Form.Item
                    labelCol={labelCol}
                    label={t("accountVerification.verificationType")}
                    name={"verificationType"}
                    rules={[{required: true}]}
                    initialValue={item?.step?.verificationType}
                >
                    <Select
                        options={excludeExistedStep()}
                        placeholder={t("accountVerification.placeholder.verificationType")}
                        disabled={item?.step?.verificationType || isView}
                    />
                </Form.Item>
                {isAdd &&
                <Form.Item
                    labelCol={labelCol}
                    label={t("accountVerification.group")}
                    name={"group"}
                    rules={[{required: true}]}
                    initialValue={item?.step?.documentGroup}
                >
                    <Select
                        options={groupList?.map(docGroup => ({label: t(docGroup?.label), value: docGroup?._id}))}
                        placeholder={t("accountVerification.placeholder.selectGroup")}
                        mode={"multiple"}
                        disabled={item?.step?.documentGroup || isView}
                        onChange={(val, option) => setSelectedDocumentGroup(option)}
                        showSearch
                        optionFilterProp={"label"}
                        allowClear
                    />
                </Form.Item>
                }
                <Form.Item
                    labelCol={labelCol}
                    label={t("accountVerification.requiredType")}
                    name={"requiredType"}
                    rules={[{required: true}]}
                    initialValue={item?.step?.requiredType}
                >
                    <Select
                        options={config?.requiredType?.map(d => ({
                            label: t(`accountVerification.requiredTypeOption.${d}`),
                            value: d
                        }))}
                        placeholder={t("accountVerification.placeholder.requiredType")}
                        onSelect={value => setSelectedRequiredType(value)}
                        disabled={isView}
                    />
                </Form.Item>
                {
                    selectedRequiredType === "partially" &&
                    <Form.Item
                        labelCol={labelCol}
                        label={t("accountVerification.partiallyRequiredGroup")}
                        name={"partiallyRequiredGroup"}
                        rules={[{required: true}]}
                        initialValue={item?.step?.partiallyRequiredGroup}
                    >
                        <Select
                            options={selectedDocumentGroup}
                            placeholder={t("accountVerification.placeholder.partiallyRequiredGroup")}
                            mode={"multiple"}
                            disabled={isView}
                        />
                    </Form.Item>
                }
                {
                    selectedRequiredType === "any" &&
                    <Form.Item
                        labelCol={labelCol}
                        label={t("accountVerification.numberOfRequired")}
                        name={"numberOfRequired"}
                        rules={[{required: true}]}
                        initialValue={item?.step?.numberOfRequired || 1}
                    >
                        <InputNumber
                            placeholder={t("accountVerification.placeholder.numberOfRequired").toString()}
                            style={{width: "100%"}}
                            min={1}
                            max={selectedDocumentGroup?.length || 1}
                            disabled={isView}
                        />
                    </Form.Item>
                }
                <Form.Item
                    labelCol={labelCol}
                    label={t("accountVerification.label")}
                    name={"label"}
                    rules={[{required: true}]}
                    initialValue={t(item?.step?.label)}
                >
                    <Input
                        placeholder={t("accountVerification.placeholder.label").toString()}
                        disabled={isView}
                    />
                </Form.Item>
                <Form.Item
                    labelCol={labelCol}
                    label={t("accountVerification.description")}
                    name={"description"}
                    rules={[{required: true}]}
                    initialValue={t(item?.step?.description)}
                >
                    <TextArea
                        placeholder={t("accountVerification.placeholder.description").toString()}
                        disabled={isView}
                    />
                </Form.Item>
                {
                    !isAdd &&
                    <Form.Item
                        label={t("accountVerification.isEnabled")}
                        name={"isEnabled"}
                        colon={false}
                        style={{textAlign: "right"}}
                        initialValue={item?.step?.isEnabled}
                        className={"margin-top-0-75"}
                    >
                        <Switch defaultChecked={item?.step?.isEnabled} disabled={isView}/>
                    </Form.Item>
                }
            </Form>
        </Modal>
    )
};

export default StepModalForm