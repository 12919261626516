import {getPushNotificationLogByUserId} from "../../../api/graphql/pushNotification";
import {useState} from "react";
import {useRecoilState, useSetRecoilState} from "recoil";
import {headerPushNotificationDataState, unreadPushNotificationCountState} from "../../../../recoil_state";

export const useGetHeaderPushNotificationLogByUserId = () => {
    const setUnreadPushNotificationCount = useSetRecoilState<number>(unreadPushNotificationCountState);
    const [data, setData] = useRecoilState<any>(headerPushNotificationDataState)
    const [total, setTotal] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)

    const fetchLog = async () => {
        try {
            setLoading(true)
            const id = localStorage.getItem("userId")
            const {getPushNotificationLogByUserId: res} = await getPushNotificationLogByUserId(id, 8, 0)
            setData(res?.data)
            setUnreadPushNotificationCount(res?.unreadTotal)
            setTotal(res?.total)
            setLoading(false)
        } catch (e) {

        }
    }

    return [data, total, fetchLog, loading] as [any, number, () => Promise<void>, boolean];
}