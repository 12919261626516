import React, {useEffect, useState} from "react";
import TradingAccountDetail_1 from "./view/layout1";
import {useLocation} from "react-router-dom";
import {getTradingAccountDetailAsync} from "../../../../api/graphql/tradingAccount";
import useAuthorize from "../../../../_common/authorize";
import {useUpdateLatestTradingAccountReportMutation} from "../../../../api/graphql/populatingReporting";
import {useGetSecureInitialTradingAccountDetails} from "../_general/function/useQueryHooks";
import {getUserByIdAsync} from "../../../../api/graphql/user";
import "./view/css/style.css"

function TradingAccountDetailPage() {
    let layout = 1;
    let component;

    const [tradingAccountDetails, setTradingAccountDetails] = useState<any>({});
    const [loading, setLoading] = useState<any>(false);
    const [userDetail, setUserDetail] = useState([]);

    const {systemErrorNotification} = useAuthorize();
    const {updateLatestTradingAccountReport} = useUpdateLatestTradingAccountReportMutation();
    const [initialData, initialDataLoading, fetchSecureInitialTradingAccountDetails] = useGetSecureInitialTradingAccountDetails();

    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);

    const id = searchParams.get("id");

    const refreshQuery = async () => {

        const response = await getTradingAccountDetailAsync(id)
        if (response?.getTradingAccountDetail?.__typename === "TradingAccount") {
            setTradingAccountDetails(response?.getTradingAccountDetail)
        }

    }

    const setUserDetails = async () => {
        if (tradingAccountDetails?.user?._id) {
            const response = await getUserByIdAsync(tradingAccountDetails?.user?._id)

            if (response?.getUserByID?.__typename === "User") {
                setUserDetail(response?.getUserByID)
            }
        }
    }

    const preload = async () => {
        try {
            setLoading(true)
            await fetchSecureInitialTradingAccountDetails(id)
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false)
            refreshQuery()
        }
    };

    const setInitialData = () => {

        if (initialData?.getTradingAccountDetail?.__typename === "TradingAccount") {
            setTradingAccountDetails(initialData?.getTradingAccountDetail)
        }
    }

    useEffect(() => {
        setInitialData()
    }, [initialData])

    const triggerUpdateLatestTradingAccountReport = async (id) => {
        try {
            const response = await updateLatestTradingAccountReport(id);

            if (response?.data?.updateLatestTradingAccountReport.__typename === "ReportingData") {
                console.log("triggerUpdateLatestTradingAccountReport");
            } else if (response?.data?.updateLatestTradingAccountReport.__typename === "BaseError") {
                console.log("error updateLatestTradingAccountReport");
            }
        } catch (e) {
            systemErrorNotification();
        } finally {
        }
    };

    useEffect(() => {
        if (id && Object.keys(tradingAccountDetails)?.length === 0) {
            preload();
        }
    }, [id, tradingAccountDetails?.mode]);

    useEffect(() => {
        setUserDetails()
    }, [tradingAccountDetails?.user?._id]);

    useEffect(() => {
        if (tradingAccountDetails?.product?.mode === "live") {
            triggerUpdateLatestTradingAccountReport(id);
        }
    }, [tradingAccountDetails?.product?.mode]);

    switch (layout) {
        case 1:
            component = (
                <TradingAccountDetail_1
                    id={id}
                    data={tradingAccountDetails}
                    refreshQuery={refreshQuery}
                    initialDataLoading={loading}
                    userDetail={userDetail}
                />
            );
            break;

        default:
            component = (
                <TradingAccountDetail_1
                    id={id}
                    data={tradingAccountDetails}
                    refreshQuery={refreshQuery}
                    initialDataLoading={loading}
                    userDetail={userDetail}
                />
            );
            break;
    }

    return component;
}

export default TradingAccountDetailPage;
