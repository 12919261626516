import React, {createRef, useEffect, useState} from "react";
import {Button, Form, Row} from "antd";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import ConditionGroup from "./conditionGroup";
import {useRecoilValue} from "recoil";
import {logicOperatorAryState} from "../../../../state";

const TriggerConditionForm = ({selectedTrigger, conditions, setConditions, setLogicalOperator, isUpdate}) => {
    const logicOperatorAry = useRecoilValue<any>(logicOperatorAryState)

    const addConditionGroup = () => {
        try {
            const largestBatchId = conditions.reduce((maxBatchId, obj) => {
                return obj.batchId > maxBatchId ? obj.batchId : maxBatchId;
            }, -1);

            const newConditionGroup = [...conditions, {
                batchId: largestBatchId + 1 || 1,
                form: createRef(),
                condition: [{comparisonOperator: null, field: null, value: null}],
            }]

            setConditions(newConditionGroup);
        } catch (e) {
            console.log(e)
        }
    };

    const removeConditionGroup = (indexToFilter) => {
        try {
            const filteredArray = conditions.map(element => {
                if (element?.batchId !== indexToFilter) return element

                if (!element.id) return null

                return {
                    ...element,
                    isDeleted: true
                }
            }).filter(d => d);

            setConditions(filteredArray);
        } catch (e) {
            console.log(e)
        }
    };

    const onConditionGroupDragEnd = (result) => {
        // const sourceIndex = result.source.index;
        // const destinationIndex = result.destination.index;
        //
        // let newDataAry = [...conditionGroup];
        //
        // const [movedItem] = newDataAry.splice(sourceIndex, 1);
        //
        // newDataAry.splice(destinationIndex, 0, movedItem);
        // setConditionGroup(newDataAry);
    }

    const onFinish = (form, batch) => {
        try {
            const formFound = form.current;
            if (formFound) {
                const values = formFound.getFieldsValue();

                const updatedConditionGroup = conditions.map((item) => {
                    if (item.batchId === batch) {
                        return {...item, condition: values?.["condition" + batch]};
                    }
                    return item;
                });

                setConditions(updatedConditionGroup)
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (conditions?.length === 0 && selectedTrigger && !isUpdate) {
            addConditionGroup()
        }
    }, [conditions, selectedTrigger]);

    return (
        selectedTrigger ?
            <>
                <Row justify={"end"}>
                    <Button onClick={addConditionGroup}>
                        Add Condition Group
                    </Button>
                </Row>
                <DragDropContext onDragEnd={(result) => onConditionGroupDragEnd(result)}>
                    <Droppable droppableId={"condition-groups"}>
                        {(droppableProvided) => (
                            <div ref={droppableProvided?.innerRef}
                                 {...droppableProvided.draggableProps}
                                 {...droppableProvided.dragHandleProps}
                            >
                                {conditions.filter(d => !d?.isDeleted).map((detail, index) => (
                                    <Draggable key={detail.batchId}
                                               draggableId={detail.batchId.toString()}
                                               index={index}
                                    >
                                        {(provided, snapshot) => {
                                            return (
                                                <div
                                                    ref={provided?.innerRef}
                                                    {...provided?.draggableProps}
                                                    style={{
                                                        ...provided?.draggableProps.style,
                                                    }}
                                                >
                                                    <ConditionGroup
                                                        conditions={conditions}
                                                        detail={detail}
                                                        index={index}
                                                        setLogicalOperator={setLogicalOperator}
                                                        logicOperatorAry={logicOperatorAry}
                                                        setConditions={setConditions}
                                                        dragHandle={provided?.dragHandleProps}
                                                        removeConditionGroup={removeConditionGroup}
                                                        onFinish={onFinish}
                                                        selectedTrigger={selectedTrigger}
                                                    />
                                                </div>
                                            )
                                        }}
                                    </Draggable>
                                ))}
                                {droppableProvided?.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </> :
            <>Condition Not Available.</>
    )
}

export default TriggerConditionForm