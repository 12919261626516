import {gql, useMutation, useQuery} from '@apollo/client'
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {useTranslation} from "react-i18next";

const GET_ASSET_CLASS_COUNT = gql`
  query getAssetClassCount($filter: AssetClassInput) {
    getAssetClassCount(filter: $filter)
  }
`;

const getAssetClassCount = ({filter}) => {
    try {
        const {loading, error, data} = useQuery(GET_ASSET_CLASS_COUNT, {
            variables: {
                filter: {
                    assetClassId: filter?.assetClassId || null,
                    name: filter?.name || null,
                    serverName: filter?.serverName || null,
                }
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, data}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const GET_ASSET_CLASS_BY_ID = gql`
  query getAssetClassByID($id: String!) {
    getAssetClassByID(id: $id){
        id
        assetClassId
        name
        sortingNumber
        defaultLots
        server {name}
        createdBy {username}
        updatedBy {username}
        createdAt
        updatedAt
    }
  }
`;

const getAssetClassById = (id) => {
    try {
        const {loading, error, data} = useQuery(GET_ASSET_CLASS_BY_ID, {
            variables: {
                id: id
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, data}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}


const GET_ASSET_CLASS = gql`
  query getAssetClass($filter: AssetClassInput, $limit: Int, $offset: Int, $orderBy: String) {
    getAssetClass(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy){
     ... on AssetClassArray {
          data {
            _id
            id
            assetClassId
            name
            sortingNumber
            defaultLots
            server {
              name
            }
            createdBy {
              username
            }
            updatedBy {
              username
            }
            createdAt
            updatedAt
          }
        }
        ... on BaseError {
          errKey
          errMsg
        }
      }
    }
`;

const getAssetClass = ({filter}, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data} = useQuery(GET_ASSET_CLASS, {
            variables: {
                filter: {
                    assetClassId: filter?.assetClassId || null,
                    name: filter?.name || null,
                    serverName: filter?.serverName || null,
                },
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, data}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const GET_ASSET_CLASS_DETAILS = gql`
  query getAssetClassDetails($filter: AssetClassInput, $limit: Int, $offset: Int, $orderBy: String) {
    getAssetClassDetails(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy){
     ... on AssetClassDetailsArray {
          data {
            _id
            id
            assetClassId
            symbolCategoryList{
                symbolCategoryId
                assetClassId
                symbolList { 
                    name 
                    _id
                    symbolId
                }
            }
            name
            sortingNumber
            defaultLots
            isDeleted
            server { name
             _id}
          }
        }
        ... on BaseError {
          errKey
          errMsg
        }
      }
    }
`;

const getAssetClassDetails = ({filter}, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data} = useQuery(GET_ASSET_CLASS_DETAILS, {
            variables: {
                filter: {
                    assetClassId: filter?.assetClassId || null,
                    name: filter?.name || null,
                    serverName: filter?.serverName || null,
                    symbolName: filter?.symbolName || null,
                },
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, data}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const REFRESH_ASSET_CLASS = gql`
    mutation refreshAssetClass($server: String!) {
        refreshAssetClass(server: $server){
        ... on AssetClassArray {
              data {
                _id
                id
                assetClassId
                name
                sortingNumber
                defaultLots
                server {
                  name
                }
                createdBy {
                  username
                }
                updatedBy {
                  username
                }
                createdAt
                updatedAt
              }
            }
            ... on BaseError {
              errKey
              errMsg
            }
          }
        }

`

const useRefreshAssetClassMutation = (): { refreshAssetClass: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [useRefreshAssetClassMutation, {data, loading, error}] = useMutation(REFRESH_ASSET_CLASS);

        const refreshAssetClass = (server) => {
            return useRefreshAssetClassMutation({
                variables: {
                    server: server,
                },
                update(cache, {data: {refreshAssetClass}}) {
                    const typename = refreshAssetClass?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(refreshAssetClass?.errKey),
                            key: "refreshAssetClass",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Refresh Securities Group Successfully"),
                            key: "refreshAssetClass",
                        });
                    }
                },
                refetchQueries: [GET_ASSET_CLASS, GET_ASSET_CLASS_COUNT]
            });
        };

        return {refreshAssetClass, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {refreshAssetClass: null, data: null, loading: false, error: err};
    }
}


export {
    getAssetClassCount,
    getAssetClassById,
    getAssetClass,
    getAssetClassDetails,
    useRefreshAssetClassMutation
}