import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";

const GET_EMAIL_TEMPLATE_LOG = gql`
    query getEmailTemplateLog($filter: EmailTemplateLogSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
        getEmailTemplateLog(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on EmailTemplateLogArray {
                data {
                    _id
                    email
                    html
                    sendStatus
                    failedMessage
                    createdBy {
                        _id
                        username
                    }
                    createdAt
                    fromTemplate {
                        _id
                        displayName
                        subject
                        type
                    }
                    sendFromEmail {
                        email
                        emailName
                    }
                }
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

const getEmailTemplateLog = async (filter, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_EMAIL_TEMPLATE_LOG,
            {
                filter: filter,
                limit: limit,
                offset: offset,
                orderBy: orderBy,
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

// Draft
const GET_EMAIL_SEND_DRAFT = gql`
    query getEmailSendDraft($filter: EmailSendDraftInput, $limit: Int, $offset: Int, $orderBy: String) {
        getEmailSendDraft(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on EmailSendDraftAry {
                data {
                    _id
                    name
                    to
                    cc
                    bcc
                    language
                    sendInNetwork
                    sendUserType
                    createdBy {
                        _id
                        username
                    }
                    updatedBy {
                        _id
                        username
                    }
                    sendFromEmail{
                        email
                        emailName
                    }
                    isSent
                    isDeleted
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

const getEmailSendDraft = async (filter, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_EMAIL_SEND_DRAFT,
            {
                filter: filter,
                limit: limit,
                offset: offset,
                orderBy: orderBy,
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_EMAIL_SEND_DRAFT_BY_ID = gql`
    query getEmailSendDraftById($id: String!) {
        getEmailSendDraftById(id: $id) {
            ... on EmailSendDraft {
                _id
                name
                to
                cc
                bcc
                language
                sendInNetwork
                sendUserType
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

const getEmailSendDraftById = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            GET_EMAIL_SEND_DRAFT_BY_ID,
            {
                id: id
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_EMAIL_SEND_DRAFT = gql`
    mutation addEmailSendDraft($input: EmailSendDraftInput) {
        addEmailSendDraft(input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on EmailSendDraft {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

const addEmailSendDraft = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            ADD_EMAIL_SEND_DRAFT,
            {
                input: input,
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UPDATE_EMAIL_SEND_DRAFT = gql`
    mutation updateEmailSendDraft($id: String!, $input: EmailSendDraftInput) {
        updateEmailSendDraft(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on EmailSendDraft {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

const updateEmailSendDraft = async (id, input) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_EMAIL_SEND_DRAFT,
            {
                id: id,
                input: input,
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const DELETE_SOFT_EMAIL_SEND_DRAFT = gql`
    mutation deleteSoftEmailSendDraft($id: String!) {
        deleteSoftEmailSendDraft(id: $id) {
            ... on CrmTaskApproval {
                msg
            }
            ... on EmailSendDraft {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

const deleteSoftEmailSendDraft = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            DELETE_SOFT_EMAIL_SEND_DRAFT,
            {
                id: id,
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const DELETE_HARD_EMAIL_SEND_DRAFT = gql`
    mutation deleteHardEmailSendDraft($id: String!) {
        deleteHardEmailSendDraft(id: $id) {
            ... on CrmTaskApproval {
                msg
            }
            ... on EmailSendDraft {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

const deleteHardEmailSendDraft = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            DELETE_HARD_EMAIL_SEND_DRAFT,
            {
                id: id,
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

// Batch Log
const GET_EMAIL_TEMPLATE_BATCH_LOG = gql`
    query getEmailTemplateBatchLog($filter: EmailTemplateBatchLogsInput, $limit: Int, $offset: Int, $orderBy: String) {
        getEmailTemplateBatchLog(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on EmailTemplateBatchLogAry {
                total
                data {
                    _id
                    fromTemplate {
                        name
                        subject
                        type
                    }
                    totalSuccess
                    totalFail
                    sendFromEmail {
                        email
                        emailName
                    }
                    status
                    createdAt
                    updatedAt
                    to {
                        userId
                        email
                        userName
                        status
                    }
                }
            }
        }
    }
`

const getEmailTemplateBatchLog = async (filter, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_EMAIL_TEMPLATE_BATCH_LOG,
            {
                filter: filter,
                limit: limit,
                offset: offset,
                orderBy: orderBy,
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

export {
    getEmailTemplateLog,
    getEmailSendDraft,
    getEmailSendDraftById,
    addEmailSendDraft,
    updateEmailSendDraft,
    deleteSoftEmailSendDraft,
    deleteHardEmailSendDraft,
    getEmailTemplateBatchLog
}