import React, { memo } from "react";

const GoogleChromeIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M44.874 12.148C40.746 4.893 32.944 0 24 0 15.143 0 7.407 4.798 3.248 11.936l10.383 17.808A11.798 11.798 0 0112.147 24c0-6.546 5.306-11.852 11.852-11.852h20.875z"
      fill="#DB4437"
    ></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.933 48l10.334-18.073v-.002A11.847 11.847 0 0124 35.852a11.849 11.849 0 01-10.37-6.108L3.248 11.936A23.89 23.89 0 000 24c0 13.233 10.709 23.964 23.933 24z"
      fill="#0F9D58"
    ></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M48 24c0 13.255-10.745 24-24 24h-.067l10.334-18.074A11.8 11.8 0 0035.852 24c0-6.546-5.306-11.852-11.851-11.852h20.873A23.89 23.89 0 0148 24z"
      fill="#FFCD40"
    ></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M35.852 24c0 6.545-5.307 11.852-11.852 11.852-6.546 0-11.852-5.307-11.852-11.852 0-6.546 5.306-11.852 11.852-11.852 6.545 0 11.852 5.306 11.852 11.852zm-2.371 0a9.481 9.481 0 11-18.963 0 9.481 9.481 0 0118.963 0z"
      fill="#F1F1F1"
    ></path>
    <path
      d="M24 33.481a9.481 9.481 0 100-18.962 9.481 9.481 0 000 18.962z"
      fill="#4285F4"
    ></path>
  </svg>
);
export default memo(GoogleChromeIcon);
