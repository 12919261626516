import axios from "axios";
import {getConfigByName} from "../../../../config";
import {BRAND_NAME} from "../../../../config/brandVariable";

const url = getConfigByName(BRAND_NAME).arenaURL
const config = {headers: {"Content-Type": "text/plain", "authorization": `Bearer ${localStorage.getItem("arenaToken")}`}}

export const getMedia = async (param = {}) => {
    try {
        return await axios.post(`${url}/api/media/file/get`, JSON.stringify(param), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export const uploadMedia = async (input) => {
    try {
        return await axios.post(`${url}/api/media/file/upload`, input, config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export const updateMedia = async (input) => {
    try {
        return await axios.post(`${url}/api/media/file/update`, input, config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export const updateMediaFolder = async (input) => {
    try {
        return await axios.post(`${url}/api/media/file/folder/update`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export const deleteMedia = async (input) => {
    try {
        return await axios.post(`${url}/api/media/file/delete`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export const restoreMedia = async (input) => {
    try {
        return await axios.post(`${url}/api/media/file/restore`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export const hardDeleteMedia = async (input) => {
    try {
        return await axios.post(`${url}/api/media/file/delete/hard`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}