import React, {useEffect, useState} from "react";
import {Button, Card, Form, List, Popconfirm, Row, Space, Tag, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useGeneralListFunction} from "../../../../function/generalListFunction";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {currentTabState, generalListFieldGroupState, tabBarExtraContentState, userVerificationConfigListState} from "../../../../state";
import GeneralListFieldGroupForm from "./modalForm/generalListFieldGroupForm";
import GeneralListFieldGroupPreview from "./modalForm/generalListFieldGroupPreview";
import {switchFieldTypeTagColor} from "../../../../function";
import {getIsMainMasterAdmin} from "../../../../../../../api/graphql/_common";
import ImportDBTableButton from "../importDBTable";
import ExportDBTableButton from "../exportDBTable";

const GeneralListFieldGroup = ({permission}) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const {getGeneralListField, createGeneralListField, updateGeneralListField, deleteGeneralListField} = useGeneralListFunction();
    const fieldGroup = useRecoilValue<any>(generalListFieldGroupState);
    const config = useRecoilValue<any>(userVerificationConfigListState);

    const [isFormOpen, setIsFormOpen] = useState(false);
    // const [isPreviewOpen, setIsPreviewOpen] = useState(false)
    const [isAdd, setIsAdd] = useState<any>();
    const [isView, setIsView] = useState<any>();
    const [editingItem, setEditingItem] = useState<any>();
    const [submitLoading, setSubmitLoading] = useState(false);
    const setTabBarExtraContent = useSetRecoilState<any>(tabBarExtraContentState);
    const currentTab = useRecoilValue(currentTabState);
    const [isMainMasterAdmin, setIsMainMasterAdmin] = useState(false);

    const checkIsMasterAdmin = async () => {
        let response = await getIsMainMasterAdmin();
        setIsMainMasterAdmin(response?.isMainMasterAdmin);
    };

    useEffect(() => {
        checkIsMasterAdmin();
    }, []);

    useEffect(() => {
        if (currentTab === "fieldGroup") {
            getGeneralListField({}, false);

            setTabBarExtraContent(
                permission?.add?.add && (
                    <>
                        <Tooltip title={t("accountVerification.addFieldGroup")}>
                            <Button
                                onClick={() => {
                                    form.resetFields();
                                    setEditingItem(null);
                                    setIsAdd(true);
                                    setIsFormOpen(true);
                                    setIsView(false);
                                }}
                            >
                                {t("common.add")}
                            </Button>
                        </Tooltip>
                        {isMainMasterAdmin && <ImportDBTableButton />}
                        {isMainMasterAdmin && <ExportDBTableButton />}
                    </>
                )
            );
        }
    }, [currentTab, isMainMasterAdmin, permission?.add?.add]);

    const submitField = async (value) => {
        setSubmitLoading(true);
        const formattedValue = {fieldType: "group", ...value};
        if (isAdd) {
            await createGeneralListField(formattedValue, false);
        } else {
            await updateGeneralListField(editingItem?._id, formattedValue, false);
        }
        setSubmitLoading(false);
        setIsFormOpen(false);
    };

    return (
        <div id={"general-list-field"} className={"margin-top-0-75"}>
            <GeneralListFieldGroupForm
                isAdd={isAdd}
                isView={isView}
                isOpen={isFormOpen}
                setIsOpen={setIsFormOpen}
                submitLoading={submitLoading}
                submitField={submitField}
                config={config}
                item={editingItem}
                form={form}
            />
            {/*<GeneralListFieldGroupPreview*/}
            {/*    isOpen={isPreviewOpen}*/}
            {/*    setIsOpen={setIsPreviewOpen}*/}
            {/*    item={editingItem}*/}
            {/*/>*/}
            <List
                grid={{gutter: 16, column: 4}}
                dataSource={fieldGroup?.filter((item) => item?.fieldType === "group")}
                renderItem={(item: any) => {
                    return (
                        <List.Item>
                            <Card
                                size={"small"}
                                title={
                                    <Row justify={"space-between"}>
                                        <span>{t(item?.label)}</span>
                                        <Space>
                                            {/*<Tooltip title={t("common.preview")}>*/}
                                            {/*    <Button*/}
                                            {/*        onClick={() => {*/}
                                            {/*            setIsPreviewOpen(true)*/}
                                            {/*            setEditingItem(item)*/}
                                            {/*        }}*/}
                                            {/*        icon={<EyeOutlined/>}*/}
                                            {/*        type={"link"}*/}
                                            {/*        size={"small"}/>*/}
                                            {/*</Tooltip>*/}
                                            <Tooltip title={t(permission?.edit?.edit ? "common.edit" : "common.view")}>
                                                <Button
                                                    onClick={() => {
                                                        form.resetFields();
                                                        setEditingItem(item);
                                                        setIsAdd(false);
                                                        setIsFormOpen(true);
                                                        setIsView(!permission?.edit?.edit);
                                                    }}
                                                    icon={permission?.edit?.edit ? <EditOutlined /> : <EyeOutlined />}
                                                    type={"link"}
                                                    size={"small"}
                                                />
                                            </Tooltip>
                                            {permission?.delete?.delete && (
                                                <Tooltip title={t("common.delete")}>
                                                    <Popconfirm
                                                        placement="left"
                                                        title={t("accountVerification.popconfirm.title.fieldGroup.delete")}
                                                        description={t("accountVerification.popconfirm.description.fieldGroup.delete")}
                                                        onConfirm={async () => {
                                                            await deleteGeneralListField(item?._id, false);
                                                        }}
                                                        okText={t("common.yes")}
                                                        cancelText={t("common.no")}
                                                    >
                                                        <Button icon={<DeleteOutlined />} type={"link"} danger size={"small"} />
                                                    </Popconfirm>
                                                </Tooltip>
                                            )}
                                        </Space>
                                    </Row>
                                }
                            >
                                {item?.fieldGroup?.map((d) => {
                                    const tagColor = switchFieldTypeTagColor(d?.fieldType);

                                    return (
                                        <Tag bordered={false} key={d?._id} color={tagColor}>
                                            {t(d?.label)}
                                        </Tag>
                                    );
                                })}
                            </Card>
                        </List.Item>
                    );
                }}
            />
        </div>
    );
};

export default GeneralListFieldGroup;
