import React, {useEffect} from "react";
import {Col, Form, Input, Row} from "antd";
import {useTranslation} from "react-i18next";
import {Checkbox} from "antd/lib";

const CommonPanelMt5 = ({initialData}) => {
    const {t} = useTranslation()
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(initialData);
    }, [initialData])

    return (
        <>
            <Row gutter={[16, 16]} style={{marginBottom: "1%", marginTop: "2%"}}>
                <Col span={24}>
                    <p>
                        Group is a set of users that have the same permission settings and service conditions. Please
                        specific name of group, deposit currency, trade server, and authentication type.
                    </p>
                </Col>
            </Row>

            <Form form={form}>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={12}>
                        <Form.Item
                            name={"group"}
                            label={t("Name")}
                            initialValue={initialData?.group}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={"currency"}
                            label={t("Currency")}
                            initialValue={initialData?.currency}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={12}>
                        <Form.Item
                            name={"server"}
                            label={t("Trade server")}
                            initialValue={initialData?.server}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={"currencyDigits"}
                            label={t("Digits")}
                            initialValue={initialData?.currencyDigits}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={12}>
                        <Form.Item
                            name={"authMode"}
                            label={t("Authentication")}
                            initialValue={initialData?.authMode}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={"authPasswordMin"}
                            label={t("Minimum password length")}
                            initialValue={initialData?.authPasswordMin}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={12}>
                        <Form.Item
                            name={"authOtpMode"}
                            label={t("One time password")}
                            initialValue={initialData?.authOtpMode}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name={"passwordUsage"}
                            label={t("Force one-time password usage")}
                        >
                            <Checkbox disabled={true}
                                      defaultChecked={(initialData?.permissionsFlags.includes("PERMISSION_FORCED_OTP_USAGE") || initialData?.reportsFlags.includes("PERMISSION_ALL"))}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={12}>
                        <Form.Item
                            name={"pushNotification"}
                            label={<span style={{color: "red"}}>{t("Push notifications")}</span>}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={12}>
                        <Form.Item
                            label={t("Enable connections")}
                        >
                            <Checkbox disabled={true}
                                      defaultChecked={(initialData?.permissionsFlags.includes("PERMISSION_ENABLE_CONNECTION") || initialData?.reportsFlags.includes("PERMISSION_ALL"))}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={12}>
                        <Form.Item
                            label={t("Enable certificate confirmation")}
                        >
                            <Checkbox disabled={true}
                                      defaultChecked={(initialData?.permissionsFlags.includes("PERMISSION_CERT_CONFIRM") || initialData?.reportsFlags.includes("PERMISSION_ALL"))}/>

                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={12}>
                        <Form.Item
                            label={t("Change password at first login")}
                        >
                            <Checkbox disabled={true}
                                      defaultChecked={(initialData?.permissionsFlags.includes("PERMISSION_RESET_PASSWORD") || initialData?.reportsFlags.includes("PERMISSION_ALL"))}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={12}>
                        <Form.Item
                            label={t("Show the risk warning window after connection")}
                        >
                            <Checkbox disabled={true}
                                      defaultChecked={(initialData?.permissionsFlags.includes("PERMISSION_RISK_WARNING") || initialData?.reportsFlags.includes("PERMISSION_ALL"))}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={12}>
                        <Form.Item
                            label={t("Enforce country-specific regulatory restrictions for retail clients")}
                        >
                            <Checkbox disabled={true}
                                      defaultChecked={(initialData?.permissionsFlags.includes("PERMISSION_REGULATION_PROTECT") || initialData?.reportsFlags.includes("PERMISSION_ALL"))}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default CommonPanelMt5