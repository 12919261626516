import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Row} from "antd";
import TemplateTable from "./component/templateTable";
import {FormType} from "../../function/type";
import TemplateFormModal from "./component/templateFormModal";
import TestSendPushNotificationForm from "./component/testSendPushNotificationForm";

const PushNotificationTemplate_1 = (props) => {
    const {pushNotificationTemplatePermission} = props;
    const {t} = useTranslation();
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [modalFormType, setModalFormType] = useState<FormType>(FormType.DEFAULT);
    const [selectedTemplate, setSelectedTemplate] = useState("");
    const [sendPushNotificationModal, setSendPushNotificationModal] = useState(false);
    const [templateName, setTemplateName] = useState("");

    const toggleFormModal = async (formType: FormType, record?: any) => {
        setIsFormModalOpen((prevState) => !prevState);
        setSelectedTemplate(record ?? "");
        setModalFormType(formType);
    };

    const openSendPushNotificationModal = (record) => {
        setTemplateName(record);
        setSendPushNotificationModal(true);
    };

    const closeSendPushNotificationModal = () => {
        setTemplateName("");
        setSendPushNotificationModal(false);
    };

    const tableName = "Push Notification Template";

    return (
        <div id="pushNotificationTemplate">
            <div className="display-flex">
                <h1>{t("Push Notification Template")}</h1>
            </div>
            {pushNotificationTemplatePermission?.add?.add && (
                <Row justify={"end"}>
                    <Button className="margin-left-auto" onClick={() => toggleFormModal(FormType.Add)}>
                        {t("Add New Template")}
                    </Button>
                </Row>
            )}
            <TemplateTable
                tableName={tableName}
                toggleFormModal={toggleFormModal}
                openPushNotificationModal={openSendPushNotificationModal}
                pushNotificationTemplatePermission={pushNotificationTemplatePermission}
                selectedTemplate={selectedTemplate}
            />
            <TestSendPushNotificationForm
                templateName={templateName}
                isOpen={sendPushNotificationModal}
                closeModal={closeSendPushNotificationModal}
            />
            <TemplateFormModal
                selectedTemplate={selectedTemplate}
                modalFormType={modalFormType}
                isFormModalOpen={isFormModalOpen}
                toggleFormModal={toggleFormModal}
            />
        </div>
    );
};

export default PushNotificationTemplate_1;
