import {Button, Space} from "antd";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import AdminTableForm from "../component/adminTableForm";
import UserTableForm from "../component/userTableForm";
import {checkIsMasterAdmin} from "../../../api/graphql/user";
import {getUserTableAdminSetting, getUserTableAdminSettingAsync} from "../../../api/graphql/tableSetting";
import {useSearchParams} from "react-router-dom";

const EditTableUserPreferenceButton = (props) => {
    const {tableName, server = null, tableColumn, item, displayTableName, resetTableName, resetColumnTooltipTitle} = props;
    const {t} = useTranslation();

    const [adminFormOpen, setAdminFormOpen] = useState(false);
    const [userFormOpen, setUserFormOpen] = useState(false);
    const [resetColumn, setResetColumn] = useState<any>([]);
    const {isMasterAdmin} = checkIsMasterAdmin(localStorage.getItem("g_userId") || localStorage.getItem("userId"));

    const {data, loading} = getUserTableAdminSetting({
        filter: {
            tableName: tableName,
            serverName: server?.name || server,
        },
    });

    const showAdminModal = () => {
        setAdminFormOpen(true);
    };

    const hideAdminModal = () => {
        setAdminFormOpen(false);
    };

    const showUserModal = () => {
        setUserFormOpen(true);
    };

    const hideUserModal = () => {
        setUserFormOpen(false);
    };

    const getResetTableColumn = async () => {
        const {getUserTableAdminSetting} = await getUserTableAdminSettingAsync({
            filter: {
                tableName: resetTableName,
                serverName: server?.name || server,
            },
        })

        setResetColumn(getUserTableAdminSetting?.data?.[0]?.fieldData)
    }

    useEffect(() => {
        if (isMasterAdmin && resetTableName) {
            getResetTableColumn()
        } else {
            setResetColumn([])
        }
    }, [isMasterAdmin, resetTableName]);

    return (
        <>
            {/* {data?.getUserTableAdminSetting?.data && ( */}
                <AdminTableForm
                    tableName={tableName}
                    displayTableName={displayTableName}
                    server={server}
                    tableColumn={tableColumn}
                    item={item}
                    loading={loading}
                    open={adminFormOpen}
                    hideModal={hideAdminModal}
                    data={data?.getUserTableAdminSetting?.data?.[0]}
                    showUserModal={showUserModal}
                    resetColumnAry={resetColumn}
                    resetColumnTooltipTitle={resetColumnTooltipTitle}
                />
            {/* )} */}
            {/* {data?.getUserTableAdminSetting?.data && ( */}
                <UserTableForm
                    tableName={tableName}
                    displayTableName={displayTableName}
                    server={server}
                    tableColumn={tableColumn}
                    item={item}
                    open={userFormOpen}
                    hideModal={hideUserModal}
                    defaultData={data?.getUserTableAdminSetting?.data?.[0]}
                    isMasterAdmin={isMasterAdmin}
                    showAdminModal={showAdminModal}
                />
            {/* )} */}
            <Space>
                <Button onClick={showUserModal} disabled={loading}>
                    {t("common.editColumns")}
                </Button>
                {/*{isMasterAdmin?.checkIsMasterAdmin &&*/}
                {/*<Button onClick={showAdminModal} disabled={loading}>*/}
                {/*    {t("Columns Admin Setting")}*/}
                {/*</Button>}*/}
            </Space>
        </>
    );
};
export default EditTableUserPreferenceButton;
