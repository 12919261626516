import React, {useEffect, useMemo, useState} from "react";
import {Button, Popconfirm, Row, Select, Space, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {EditOutlined} from "@ant-design/icons";
import {
    addUserClaimMission,
    forfeitUserClaimMission,
    getAvailableUserMission,
    getUserClaimMission,
    refreshUserClaimMission,
    userCompleteMission,
} from "../../../../../../../../api/graphql/mission";
import TableAntDesign from "../../../../../../../../components/table/tableAntDesign";
import EditTableUserPreferenceButton from "../../../../../../../../components/table/function/editTableUserPreferenceButton";
import {notificationMessage} from "../../../../../../../../../recoil_state";
import useAuthorize from "../../../../../../../../_common/authorize";
import MissionInvitationModal from "./missionInvitationModal";
import ClaimMissionModal from "./claimMissionModal";
import SelectRewardModal from "./selectRewardModal";
import EditMissionModal from "./editMissionModal";
import {formatDateTimeTimezone} from "../../../../../../../../function/_common";

const MissionInformation = (props) => {
    const {data, permissionUser} = props;
    const {t} = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {baseErrorChecking, systemErrorNotification} = useAuthorize();

    const [missionType, setMissionType] = useState("active");
    const [isLoading, setIsLoading] = useState(false);
    const [filter, setFilter] = useState<any>({
        // isMissionCompleted: false,
        // isMissionExpired: false,
        isActive: true,
        user: data?._id,
    });
    const [limit, setLimit] = useState<any>(null);
    const [offset, setOffset] = useState<any>(0);
    const [orderBy, setOrderBy] = useState<any>("");
    const [userClaimedMission, setUserClaimedMission] = useState<any>([]);

    const [availableUserMissionFilter, setAvailableUserMissionFilter] = useState<any>({
        isClaimed: false,
    });
    const [availableUserMissionLimit, setAvailableUserMissionLimit] = useState<any>(10);
    const [availableUserMissionOffset, setAvailableUserMissionOffset] = useState<any>(0);
    const [availableUserMissionOrderBy, setAvailableUserMissionOrderBy] = useState<any>("");
    const [total, setTotal] = useState<any>(0);
    const [availableUserMission, setAvailableUserMission] = useState<any>([]);

    const [invitationModalIsOpen, setInvitationModalIsOpen] = useState<boolean>(false);
    const [claimMissionModalIsOpen, setClaimMissionModalIsOpen] = useState<boolean>(false);
    const [selectRewardIsOpen, setSelectRewardIsOpen] = useState<boolean>(false);
    const [editMissionModalIsOpen, setEditMissionModalIsOpen] = useState<boolean>(false);
    const [isMissionActionLoading, setIsMissionActionLoading] = useState(false);
    const [activeMission, setActiveMission] = useState<any>({});
    const [refreshMissionLoading, setRefreshMissionLoading] = useState(false)

    const toggleInvitationModal = () => {
        setInvitationModalIsOpen((prevState) => !prevState);
    };

    const toggleClaimModal = (record) => {
        setActiveMission(record);
        setClaimMissionModalIsOpen((prevState) => !prevState);
    };

    const toggleSelectRewardModal = (record) => {
        setActiveMission(record);
        setSelectRewardIsOpen((prevState) => !prevState);
    };

    const toggleEditMissionModal = (record) => {
        setActiveMission(record);
        setEditMissionModalIsOpen((prevState) => !prevState);
    };

    const onChangePageHandler = (page, pageSize) => {
        if (missionType === "available") {
            setAvailableUserMissionLimit(pageSize);
            setAvailableUserMissionOffset(limit * (page - 1));
        } else {
            setLimit(pageSize);
            setOffset(limit * (page - 1));
        }
    };

    const getAvailableUserMissionFunction = async () => {
        try {
            setIsLoading(true);
            const response = await getAvailableUserMission(
                data?._id,
                availableUserMissionFilter,
                availableUserMissionLimit,
                availableUserMissionOffset,
                availableUserMissionOrderBy
            );
            setAvailableUserMission(response?.getAvailableUserMission?.data);
        } catch (e) {
        } finally {
            setIsLoading(false);
        }
    };

    const getUserClaimedMissionFunction = async () => {
        try {
            setIsLoading(true);
            const response = await getUserClaimMission(filter, limit, offset, orderBy);
            setUserClaimedMission(response?.getUserClaimMission?.data);
        } catch (e) {
        } finally {
            setIsLoading(false);
        }
    };

    const handleClaimMission = async (input) => {
        try {
            setIsMissionActionLoading(true);
            const response = await addUserClaimMission(input);
            if (response?.addUserClaimMission?.__typename === "BaseError") {
                return await baseErrorChecking(response?.addUserClaimMission);
            } else {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Mission Claimed.",
                    key: "missionNotification",
                });
                getAvailableUserMissionFunction();
                getUserClaimedMissionFunction();
            }
        } catch (error) {
            systemErrorNotification();
        } finally {
            setIsMissionActionLoading(false);
        }
    };

    const handleForfeitMission = async (missionId) => {
        try {
            setIsMissionActionLoading(true);
            const response = await forfeitUserClaimMission(missionId);
            if (response?.forfeitUserClaimMission?.__typename === "BaseError") {
                return await baseErrorChecking(response?.forfeitUserClaimMission);
            } else {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Mission Forfeited.",
                    key: "missionNotification",
                });
                getAvailableUserMissionFunction();
                getUserClaimedMissionFunction();
            }
        } catch (error) {
            systemErrorNotification();
        } finally {
            setIsMissionActionLoading(false);
        }
    };

    const handleCompleteMission = async (record) => {
        try {
            setIsMissionActionLoading(true);
            if (record?.missionId?.rewardValue === "all") {
                const rewards = record?.missionId?.rewards?.map((d) => {
                    if (d?.rewardType === "vouchers") {
                        return {rewardId: d?.reward?._id, voucherId: [d?.reward?._id]};
                    } else {
                        return {rewardId: d?.reward?._id, voucherId: d?.reward?.pool?.map((pool) => pool?.voucher?._id)};
                    }
                });
                const response = await userCompleteMission(record?._id, {
                    userId: data?._id,
                    rewards: rewards,
                });
                if (response?.userCompleteMission?.__typename === "BaseError") {
                    return await baseErrorChecking(response?.userCompleteMission);
                } else {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: "Mission Completed!",
                        key: "missionNotification",
                    });
                }
            } else {
                toggleSelectRewardModal(record);
            }
        } catch (error) {
            systemErrorNotification();
        } finally {
            setIsMissionActionLoading(false);
        }
    };

    const refreshUserClaimedMissionFunction = async () => {
        try {
            setRefreshMissionLoading(true)
            const response = await refreshUserClaimMission(data?._id)

            if (response?.refreshUserClaimMission?.status === "Success") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Refresh Mission Progress Successfully!",
                    key: "missionNotification",
                });

                await getUserClaimedMissionFunction()
            } else {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: "Refresh Mission Progress Failed.",
                    key: "missionNotification",
                });
            }

        } catch (e) {
            systemErrorNotification();
        } finally {
            setRefreshMissionLoading(false)
        }
    }

    useEffect(() => {
        getUserClaimedMissionFunction();
    }, [JSON.stringify(filter), limit, offset, orderBy, claimMissionModalIsOpen, editMissionModalIsOpen]);

    useEffect(() => {
        getAvailableUserMissionFunction();
    }, [
        availableUserMissionFilter,
        availableUserMissionLimit,
        availableUserMissionOffset,
        availableUserMissionOrderBy,
        claimMissionModalIsOpen,
    ]);

    useEffect(() => {
        if (missionType === "active") {
            setFilter((prevState) => ({...prevState, isActive: true}));
        } else if (missionType === "history") {
            setFilter((prevState) => ({...prevState, isActive: false}));
        }
    }, [missionType]);

    const columns: any = [
        {
            title: t("common.name"),
            dataIndex: "displayName",
            key: "displayName",
            width: 150,
            render: (_, record) => {
                let lang = localStorage.getItem("language");
                let name =
                    missionType === "available"
                        ? record?.displayName?.[0]?.content || record?.name
                        : record?.missionId?.displayName?.find((v) => v.language === lang)?.content ||
                          record?.missionId?.displayName?.[0]?.content ||
                          record?.missionId?.name;
                return <p>{name}</p>;
            },
        },
        {
            title: t("common.description"),
            dataIndex: "description",
            key: "description",
            width: 250,
            render: (_, record) => {
                let lang = localStorage.getItem("language");
                let description =
                    missionType === "available"
                        ? record?.description?.find((v) => v.language === lang)?.content || record?.description?.[0]?.content
                        : record?.missionId?.description?.find((v) => v.language === lang)?.content || record?.missionId?.description?.[0]?.content;
                return <p>{description}</p>;
            },
        },
        {
            title: t("mission.missionProgress"),
            dataIndex: "missionTarget",
            key: "missionTarget",
            width: 250,
            render: (_, record) => {
                let lang = localStorage.getItem("language");

                return (
                    <>
                        {(record?.missionTarget?.length > 0
                            ? record?.missionTarget
                            : record?.missionAutomationSetting?.criteriaGroups?.flatMap((group) => group?.criteriaItems)
                        )?.map((d) => {
                            let displayName = d?.displayName?.find((display) => display?.language === lang)?.content || d?.displayName?.[0]?.content;
                            let label = displayName?.displayLabel || "";
                            let measurementUnits = displayName?.measurementUnits || "";
                            let tooltipTitle = `${label} ${d?.operatorName || d?.comparisonOperator} ${
                                d?.targetValue || d?.value
                            } ${measurementUnits}`;
                            let progress = `${d?.currentValue || 0}/${d?.targetValue || d?.value} ${measurementUnits}`;

                            return (
                                <Tooltip title={tooltipTitle} key={d?.id}>
                                    <p>
                                        {label}: {progress}
                                    </p>
                                </Tooltip>
                            );
                        })}
                    </>
                );
            },
        },
        {
            title: t("mission.missionGroup"),
            dataIndex: "missionGroup",
            key: "missionGroup",
            render: (_, record) => {
                let lang = localStorage.getItem("language");
                let name =
                    missionType === "available"
                        ? record?.missionGroup?.displayName?.[0]?.content || record?.missionGroup?.name
                        : record?.missionGroup?.missionId?.displayName?.[0]?.content || record?.missionGroup?.name;
                return <p>{name || "-"}</p>;
            },
        },
        {
            title: t("mission.claimedDate"),
            dataIndex: "missionClaimedAt",
            key: "missionClaimedAt",
            render: (text, record) => {
                const date = record?.missionClaimedAtEditedDate ? record?.missionClaimedAtEditedDate : record?.missionClaimedAt;
                const displayText = missionType === "available" ? "N/A" : date ? formatDateTimeTimezone(date) : "-";
                return <p>{displayText}</p>;
            },
        },
        {
            title: t("mission.completedDate"),
            dataIndex: "missionCompleteDate",
            key: "missionCompleteDate",
            render: (text, record) => <p>{missionType === "available" ? "N/A" : text ? formatDateTimeTimezone(text) : "-"}</p>,
        },
        {
            title: t("mission.account"),
            dataIndex: "tradingAccount",
            key: "tradingAccount",
            render: (tradingAccount) => {
                if (tradingAccount?._id) {
                    return (
                        <a onClick={() => window.open("/account/trading-account/detail?id=" + tradingAccount?._id)}>{tradingAccount?.accountId}</a>
                    );
                }
                return missionType === "available" ? "N/A" : <p>-</p>;
            },
        },
        {
            title: t("common.status"),
            dataIndex: "task",
            key: "task",
            render: (_, record) => {
                let status = missionType === "available" ? t("common.available") : t("common.ongoing");
                if (record?.isMissionCompleted) {
                    status = t("common.completed");
                }
                if (record?.isMissionExpired) {
                    status = t("common.expired");
                }
                return <p>{status}</p>;
            },
        },
        {
            title: t("mission.device"),
            dataIndex: "device",
            key: "device",
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("mission.browser"),
            dataIndex: "browser",
            key: "browser",
            render: (text) => <p>{text}</p>,
        },
    ];

    const missionTypeOptions = [
        {label: t("common.active"), value: "active"},
        {label: t("common.available"), value: "available"},
        {label: t("common.history"), value: "history"},
    ];

    let tableData = [];
    let tableName = "";
    let tableSetFilter = setFilter;
    let tableSetOrderBy = setOrderBy;
    switch (missionType) {
        case "active":
            tableData = userClaimedMission;
            tableName = "userMissionTableActive";
            tableSetFilter = setFilter;
            tableSetOrderBy = setOrderBy;
            break;
        case "history":
            tableData = userClaimedMission;
            tableName = "userMissionTableHistory";
            tableSetFilter = setFilter;
            tableSetOrderBy = setOrderBy;
            break;
        case "available":
            tableData = availableUserMission;
            tableName = "userMissionTableAvailable";
            tableSetFilter = setAvailableUserMissionFilter;
            tableSetOrderBy = setAvailableUserMissionOrderBy;
            break;
        default:
            break;
    }

    const amendedColumns = useMemo(() => {
        let col = [...columns];
        if (permissionUser?.clientsUser?.edit?.activities && missionType !== "history") {
            col.push({
                title: t("common.action"),
                dataIndex: "action",
                width: 132,
                render: (_, record: any) => {
                    return (
                        <Space size="middle">
                            {missionType === "available" && (
                                <>
                                    {record?.isClaimable ? (
                                        <Button
                                            onClick={() => {
                                                if (
                                                    record?.isTradingAccountRequired ||
                                                    record?.isMissionCodeRequired ||
                                                    record?.termAndConditionEnabled
                                                ) {
                                                    toggleClaimModal(record);
                                                } else {
                                                    setActiveMission(record);
                                                    handleClaimMission({
                                                        user: data?._id,
                                                        missionId: record?._id,
                                                    });
                                                }
                                            }}
                                            size="small"
                                            loading={isMissionActionLoading && activeMission?._id === record?._id}
                                        >
                                            {t("mission.claim")}
                                        </Button>
                                    ) : (
                                        <Tooltip title={t("mission.claimTooltip.missionGroup")}>
                                            <div className={"mt-auto ms-auto"}>
                                                <Button size="small" disabled={true} className={"primary-small-button"}>
                                                    {t("mission.claim")}
                                                </Button>
                                            </div>
                                        </Tooltip>
                                    )}
                                </>
                            )}
                            {missionType === "active" && (
                                <>
                                    <Tooltip title={t("common.edit")}>
                                        <a onClick={() => toggleEditMissionModal(record)}>
                                            <EditOutlined />
                                        </a>
                                    </Tooltip>
                                    <Button
                                        size="small"
                                        className={"my-1"}
                                        loading={isMissionActionLoading && activeMission?._id === record?._id}
                                        onClick={() => {
                                            handleCompleteMission(record);
                                        }}
                                    >
                                        {t("mission.complete")}
                                    </Button>
                                    <Popconfirm
                                        placement="left"
                                        title={t("mission.forfeitMissionConfirmation")}
                                        onConfirm={() => {
                                            handleForfeitMission(record?._id);
                                        }}
                                        okText={t("common.yes")}
                                        cancelText={t("common.no")}
                                    >
                                        <Button size="small" danger loading={isMissionActionLoading && activeMission?._id === record?._id}>
                                            {t("mission.forfeit")}
                                        </Button>
                                    </Popconfirm>
                                </>
                            )}
                        </Space>
                    );
                },
            });
        }
        return col;
    }, [missionType, isMissionActionLoading, permissionUser]);

    useEffect(() => {
        console.log(userClaimedMission, tableData, missionType)
    }, []);

    return (
        <>
            <div className={"margin-top-0-75"}>
                <Row justify={"space-between"} className={"margin-bottom-0-5"}>
                    <h3>{t("module.mission")}</h3>
                    <div>
                        <Space wrap>
                            {
                                missionType === "active" && tableData?.length > 0 &&
                                    <Button
                                        onClick={() => refreshUserClaimedMissionFunction()}
                                        loading={refreshMissionLoading}
                                    >
                                        Refresh Mission Progress
                                    </Button>
                            }
                            <Select
                                showSearch
                                style={{minWidth: 100}}
                                optionFilterProp={"label"}
                                options={missionTypeOptions}
                                value={missionType}
                                onChange={(value) => setMissionType(value)}
                            />
                            {permissionUser?.clientsUser?.add?.activities && (
                                <Button onClick={toggleInvitationModal}>{t("mission.missionInvitation")}</Button>
                            )}
                            <EditTableUserPreferenceButton
                                tableName={tableName}
                                displayTableName={t(`mission.${tableName}`)}
                                tableColumn={amendedColumns}
                            />
                        </Space>
                    </div>
                </Row>
                <TableAntDesign
                    data={tableData}
                    tableName={tableName}
                    columns={amendedColumns}
                    size={"small"}
                    loading={isLoading}
                    filter={tableSetFilter}
                    order={tableSetOrderBy}
                    isTableLocalSearch={true}
                    tableSpin={false}
                    pagination={{
                        pageSize: limit,
                        showSizeChanger: true,
                        onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                        total: total,
                        showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
                    }}
                />
            </div>
            <MissionInvitationModal isOpen={invitationModalIsOpen} close={toggleInvitationModal} data={data} getAvailableUserMissionFunction={getAvailableUserMissionFunction}/>
            <ClaimMissionModal
                isOpen={claimMissionModalIsOpen}
                handleClose={toggleClaimModal}
                item={activeMission}
                data={data}
                handleClaimMission={handleClaimMission}
                isMissionActionLoading={isMissionActionLoading}
                setIsMissionActionLoading={setIsMissionActionLoading}
            />
            <SelectRewardModal
                isOpen={selectRewardIsOpen}
                handleClose={toggleSelectRewardModal}
                item={activeMission}
                data={data}
                isMissionActionLoading={isMissionActionLoading}
                setIsMissionActionLoading={setIsMissionActionLoading}
            />
            {editMissionModalIsOpen && (
                <EditMissionModal isOpen={editMissionModalIsOpen} close={toggleEditMissionModal} data={data} item={activeMission} />
            )}
        </>
    );
};

export default MissionInformation;
