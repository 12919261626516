import React, {useEffect} from "react";
import noob from "../../../assets/avatar/noob-level.png"
import stillNoob from "../../../assets/avatar/still-noob-level.png"
import rookie from "../../../assets/avatar/rookie.png"
import elite from "../../../assets/avatar/elite.png"
import master from "../../../assets/avatar/master.png"
import commander from "../../../assets/avatar/commander.png"
import legendary from "../../../assets/avatar/legendary.png"
import {Col, Image, Row} from "antd";

const LegendComponent = ({setting, currentBreakpoint}) => {
    const DATA = [
        {src: noob, name: "Noob"},
        {src: stillNoob, name: "Still Noob"},
        {src: rookie, name: "Rookie"},
        {src: elite, name: "Elite"},
        {src: master, name: "Master"},
        {src: commander, name: "Commander"},
        {src: legendary, name: "Legendary"},
    ]

    return (
        <div className={"legend-component"}
            style={{
                borderWidth: setting.borderWidth,
                borderColor: "#FF0000",
                borderRadius: setting.borderRadius,
                borderStyle: "solid"
            }}
        >
            <Row justify={"center"} gutter={[8, 8]} align={"bottom"}>
                {
                    DATA?.map(item => {
                        return (
                            <Col span={setting.layout === "fixed" ? setting.span : setting[currentBreakpoint]} key={item.name} className={"text-align-center"}>
                                <Image
                                    preview={false}
                                    src={item.src}
                                    width={"70%"}
                                />
                                <p className={"margin-top-0-75 margin-bottom-0"}>{item.name}</p>
                            </Col>
                        )
                    })
                }
            </Row>
        </div>
    )
}

export default LegendComponent