import {Button, Form, Input, Space} from "antd";
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import FormErrors from "./formErrors";
import moment from "moment";

const InputButtonFormItem = (props) => {
    const {
        layout,
        label,
        labelAlign,
        name,
        placeholder,
        prefix,
        suffix,
        value,
        type,
        validateStatus,
        externalErrorMessage,
        required,
        onChange,
        inputDisabled,
        buttonDisabled,
        disabled,
        maxLength,
        showCount,
        labelCol,
        wrapperCol,
        addonBefore,
        addonAfter,
        extra,
        colon,
        style,
        className,
        allowClear,
        autoComplete,
        buttonLabel,
        buttonOnClick,
        size,
    } = props;

    const {
        register,
        watch,
        setValue,
        formState: {touchedFields, isSubmitted, errors},
    } = useFormContext();

    const errorMessage = FormErrors.errorMessage(name, label, errors, touchedFields, isSubmitted, externalErrorMessage);

    useEffect(() => {
        register(name);
    }, [register, name]);

    useEffect(() => {
        if (value) {
            setValue(name, value, {shouldValidate: true});
        }
    }, [value]);

    return (
        <Form.Item
            {...layout}
            labelCol={labelCol}
            className={className}
            wrapperCol={wrapperCol}
            label={label}
            labelAlign={labelAlign}
            colon={colon}
            required={required}
            validateStatus={validateStatus}
            // validateStatus={"error"}
            help={errorMessage}
            extra={extra}
            labelWrap
            hasFeedback
        >
            <Space.Compact style={{width: "100%"}}>
                {type === "password" ? (
                    <Input.Password
                        id={name}
                        name={name}
                        maxLength={maxLength}
                        showCount={showCount}
                        value={watch(name)}
                        onChange={(e) => {
                            setValue(name, e.target?.value, {shouldValidate: true});
                            onChange && onChange(e.target?.value);
                        }}
                        placeholder={placeholder}
                        prefix={prefix}
                        disabled={disabled || inputDisabled}
                        suffix={suffix}
                        addonAfter={addonAfter}
                        addonBefore={addonBefore}
                        style={style}
                        size={size}
                    />
                ) : (
                    <Input
                        id={name}
                        name={name}
                        type={type}
                        maxLength={maxLength}
                        showCount={showCount}
                        value={watch(name)}
                        onChange={(e) => {
                            setValue(name, e.target?.value, {shouldValidate: true});
                            onChange && onChange(e.target?.value);
                        }}
                        placeholder={placeholder}
                        prefix={prefix}
                        disabled={disabled || inputDisabled}
                        suffix={suffix}
                        addonAfter={addonAfter}
                        addonBefore={addonBefore}
                        style={style}
                        autoComplete={autoComplete}
                        allowClear={allowClear}
                        size={size}
                    />
                )}
                <Button size={size} onClick={buttonOnClick} disabled={disabled || buttonDisabled}>
                    {buttonLabel}
                </Button>
            </Space.Compact>
        </Form.Item>
    );
};

InputButtonFormItem.defaultProps = {
    type: "text",
    required: false,
    inputDisabled: false,
    buttonDisabled: false,
    disabled: false,
    colon: false,
    size: "middle",
    autoComplete: "off",
    labelCol: {span: 24, offset: 0},
};

InputButtonFormItem.propTypes = {
    label: PropTypes.string,
    labelAlign: PropTypes.string,
    wrapperCol: PropTypes.object,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    prefix: PropTypes.any,
    suffix: PropTypes.any,
    addonBefore: PropTypes.object,
    addonAfter: PropTypes.object,
    value: PropTypes.string,
    type: PropTypes.string,
    validateStatus: PropTypes.string,
    externalErrorMessage: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    inputDisabled: PropTypes.bool,
    buttonDisabled: PropTypes.bool,
    disabled: PropTypes.bool,
    layout: PropTypes.object,
    style: PropTypes.object,
    maxLength: PropTypes.number,
    showCount: PropTypes.bool,
    extra: PropTypes.string,
    allowClear: PropTypes.bool,
    className: PropTypes.string,
    buttonLabel: PropTypes.string,
    buttonOnClick: PropTypes.func,
};

export default InputButtonFormItem;
