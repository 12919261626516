import React, {useEffect, useState} from "react";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import yupFormSchema from "../../../../../../components/yup/yupFormSchema";
import {verticalFormLayout} from "../../../../../../components/form/formWrapper";
import {FormProvider, useForm} from "react-hook-form";
import {Button, Col, Form, Modal, Row} from "antd";
import {useTranslation} from "react-i18next";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import SelectFormItem from "../../../../../../components/form/selectFormItem";
import useAuthorize from "../../../../../../_common/authorize";
import {useSendPushNotificationMutation} from "../../../../../../api/graphql/pushNotificationTemplate";
import {useRecoilState} from "recoil";
import {userOptionsState} from "../../../../../users/_common/state";
import {getUserDropdown} from "../../../../../../api/graphql/_common";
import {formatUserOptions} from "../../../../../../function/_common";

const emailTemplateSchema = yup.object().shape({
    templateName: yupFormSchema.string("templateName"),
    userid: yupFormSchema.string("userid"),
    language: yupFormSchema.string("language"),
});

const TestSendPushNotificationForm = (props) => {
    const {isOpen, templateName, closeModal} = props;
    const [userOptions, setUserOptions] = useRecoilState<any>(userOptionsState);
    const [originalUserOptions, setOriginalUserOptions] = useState<any>([])
    const {t} = useTranslation();

    const refreshUserOptions = async () => {
        const response: any = await getUserDropdown(localStorage.getItem("g_userId") || localStorage.getItem("userId"));
        setOriginalUserOptions(response?.getUserDropdown?.data)
        setUserOptions(response?.getUserDropdown?.data?.map((d) => formatUserOptions(d)));
    };

    const [initialValues] = useState(() => ({
        templateName: "",
        userid: "",
        language: "en"
    }));

    const {sendPushNotification} = useSendPushNotificationMutation();
    const {systemErrorNotification} = useAuthorize();

    const form = useForm({
        resolver: yupResolver(emailTemplateSchema),
        mode: "all",
        defaultValues: initialValues,
    });

    const onCancel = () => {
        closeModal();
        form.reset();
    };

    const onSubmit = async (values) => {
        try {
            let targetUser = originalUserOptions.filter(item => item?._id?.toString() === values.userid) || []
            const result = {
                templateName: templateName,
                userid: values?.userid,
                username: targetUser[0]?.username,
                language: values?.language || "en",
            };

            await sendPushNotification(result);
        } catch (e) {
            console.log(e);
            systemErrorNotification();
        }
    };

    useEffect(() => {
        refreshUserOptions();
    }, [isOpen]);

    return (
        <Modal
            getContainer="#pushNotification"
            title={t("Test Send Push Notification")}
            open={isOpen}
            width={"80%"}
            footer={null}
            destroyOnClose
        >
            <FormProvider {...form}>
                <form onSubmit={form?.handleSubmit(onSubmit)}>
                    <Row gutter={10}>
                        <Col span={12}>
                            <SelectFormItem
                                name={"userid"}
                                label={t("user.username")}
                                placeholder={t("user.username")}
                                options={userOptions}
                                layout={verticalFormLayout}
                                optionFilterProp={"label"}
                                optionLabelProp="selectedLabel"
                                allowClear
                                showSearch
                            />
                        </Col>
                        <Col span={12}>
                            <SelectFormItem
                                name={"language"}
                                label={t("Language")}
                                placeholder={t("Language")}
                                options={[
                                    {
                                        label: t("English"),
                                        value: "en",
                                    },
                                    {
                                        label: t("Chinese"),
                                        value: "cn",
                                    },
                                ]}
                                layout={verticalFormLayout}
                            />
                        </Col>
                    </Row>
                    <Form.Item className="text-align-right" style={{paddingTop: "12px"}}>
                        {onCancel && (
                            <Button
                                onClick={onCancel}
                                icon={<CloseOutlined/>}
                                className="button-cancel-1"
                            >
                                {t("common.cancel")}
                            </Button>
                        )}
                        <Button
                            onClick={form?.handleSubmit(onSubmit)}
                            icon={<SaveOutlined/>}
                            className="button-submit-1"
                        >
                            {t("common.submit")}
                        </Button>
                    </Form.Item>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default TestSendPushNotificationForm;
