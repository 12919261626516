import {useState} from "react";
import {getUserByIdAsync} from "../../../../../api/graphql/user";
import useAuthorize from "../../../../../_common/authorize";
import {useNavigate} from "react-router-dom";
import {getProfileInformationSettingAsync} from "../../../../../api/graphql/profileInformation";
import {getUserDropdown} from "../../../../../api/graphql/_common";
import {formatUserOptions} from "../../../../../function/_common";
import {getRegistrationSettingAsync} from "../../../../../api/graphql/register";
import {bool} from "yup";
import {getEWalletStatementWithTask} from "../../../../../api/graphql/eWallet";
import {getBankInformationAsync} from "../../../../../api/graphql/bankInformation";
import {getPspSettingFieldAsync} from "../../../../../api/graphql/pspSettingField";
import {getTradingAccountAsync} from "../../../../../api/graphql/tradingAccount";
import {getPspSettingCurrencyAsync} from "../../../../../api/graphql/pspSettingCurrency";
import {getAvailableEmailTemplateAsync} from "../../../../../api/graphql/emailTemplate";
import {getFollowUpAsync} from "../../../../../api/graphql/followUp";
import {getLoggerAsync} from "../../../../../api/graphql/logger";

export const useGetUserByID = () => {
    const [data, setData] = useState<object | null>(null)
    const [loading, setLoading] = useState<boolean>(true);
    const [userId, setUserId] = useState<string>("");
    const [profileInformation, setProfileInformation] = useState<object[] | null>(null)

    const {baseErrorChecking, systemErrorNotification} = useAuthorize();
    const navigate = useNavigate()

    const fetchUserByID = async (id: string, initialLoad: boolean = false) => {
        try {
            if (initialLoad) setLoading(true)
            setUserId(id)

            const {getUserByID} = await getUserByIdAsync(id)
            if (getUserByID?.__typename === "BaseError") {
                baseErrorChecking(getUserByID)
                navigate("/users/client")
            } else if (getUserByID?.__typename === "User") {
                setData(getUserByID)
                setLoading(false)

                if (initialLoad) {
                    const type = getUserByID?.clientAccountType === "corporate" ? "corporate" : "client";
                    const {getProfileInformationSetting} = await getProfileInformationSettingAsync({type: type})
                    setProfileInformation(getProfileInformationSetting?.data?.filter(d => d?.position !== null))
                }
            }
        } catch (error) {
            systemErrorNotification()
        } finally {
            setLoading(false)
        }
    }

    const refetchUserByID = async (id: string) => {
        fetchUserByID(userId)
    }

    return [data, loading, fetchUserByID, refetchUserByID, profileInformation] as const
}

export const useGetUserDropdownWithAdditionalIds = () => {
    const [options, setOptions] = useState<any[]>([])

    const fetchUserDropdownWithAdditionalIds = async (ids) => {
        const {getUserDropdown: response} = await getUserDropdown(localStorage.getItem("userId"), ids)

        setOptions(response?.data?.map((d) => formatUserOptions(d)))
    }

    return [options, fetchUserDropdownWithAdditionalIds] as const
}

export const useGetRegistrationSetting = () => {
    const [data, setData] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(true)

    const fetchRegistrationSetting = async (filter: object) => {
        try {
            setLoading(true)
            const {getRegistrationSetting} = await getRegistrationSettingAsync(filter)
            setData(getRegistrationSetting?.data)
        } catch (e) {
        } finally {
            setLoading(false)
        }
    }

    return [data, loading, fetchRegistrationSetting] as const
}

export const useGetEWalletTransaction = () => {
    const [transactions, setTransaction] = useState<any>({data: [], total: 0})
    const [summary, setSummary] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(false)
    const [param, setParam] = useState<object>({})

    const fetchEWalletTransaction = async (filter) => {
        try {
            setLoading(true)
            setParam(filter)

            const {getEWalletStatementWithTask: response} = await getEWalletStatementWithTask(filter)

            const data = response?.data?.filter((d: any) => d?.task?.status !== "cancelled")?.map((item: any, index: number) => ({...item, key: index}))

            setTransaction({
                data: data,
                total: data?.length
            });
            setSummary({
                totalDeposit: response?.totalDeposit,
                pendingDeposit: response?.pendingDeposit,
                totalWithdrawal: response?.totalWithdrawal,
                pendingWithdrawal: response?.pendingWithdrawal,
                netDeposit: response?.netDeposit,
                commission: response?.commission,
                netAdjustment: response?.netAdjustment,
            });

        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const refetchEWalletTransaction = () => {
        fetchEWalletTransaction(param)
    }

    return [transactions, summary, loading, fetchEWalletTransaction, refetchEWalletTransaction] as const
}

export const useGetBankInformation = () => {
    const [data, setData] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [param, setParam] = useState<boolean>(false)

    const fetchBankInformation = async (filter) => {
        try {
            setParam(filter)
            setLoading(true)
            const {getBankInformation} = await getBankInformationAsync({filter: filter})
            setData(getBankInformation?.data)
        } catch (e) {

        } finally {
            setLoading(false)
        }
    }

    const refetchBankInformation = () => {
        fetchBankInformation(param)
    }

    return [data, loading, fetchBankInformation, refetchBankInformation] as const
}

export const useGetTradingAccount = () => {
    const [data, setData] = useState<any>({data: [], total: 0})
    const [loading, setLoading] = useState<boolean>(false)

    const fetchTradingAccount = async (filter) => {
        try {
            setLoading(true)
            const {getTradingAccount} = await getTradingAccountAsync({filter: filter})
            setData(getTradingAccount)
        } catch (e) {

        } finally {
            setLoading(false)
        }
    }

    return [data, loading, fetchTradingAccount] as const
}

export const useGetPspSettingField = () => {
    const [data, setData] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const fetchPspSettingField = async (filter) => {
        try {
            setLoading(true)
            const {getPspSettingField} = await getPspSettingFieldAsync({filter: filter})
            setData(getPspSettingField?.data)
        } catch (e) {

        } finally {
            setLoading(false)
        }
    }

    return [data, loading, fetchPspSettingField] as const
}

export const useGetPspSettingCurrency = () => {
    const [data, setData] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const fetchPspSettingCurrency = async (filter) => {
        try {
            setLoading(true)
            const {getPspSettingCurrency} = await getPspSettingCurrencyAsync({filter: filter})
            setData(getPspSettingCurrency?.data)
        } catch (e) {

        } finally {
            setLoading(false)
        }
    }

    return [data, fetchPspSettingCurrency] as const
}

export const useGetAvailableEmailTemplate = () => {
    const [data, setData] = useState<any[]>([])

    const fetchAvailableEmailTemplate = async (filter) => {
        try {
            const {getAvailableEmailTemplate} = await getAvailableEmailTemplateAsync(filter)
            setData(getAvailableEmailTemplate?.data)
        } catch (e) {

        }
    }

    return [data, fetchAvailableEmailTemplate] as const
}

export const useGetFollowUp = () => {
    const [data, setData] = useState<any[]>([])
    const [filter, setFilter] = useState<any>()

    const fetchFollowUp = async (filter) => {
        try {
            setFilter(filter)
            const {getFollowUp} = await getFollowUpAsync({filter: filter})
            setData(getFollowUp?.data)
        } catch (e) {

        }
    }

    const refetchFollowUp = () => {
        fetchFollowUp(filter)
    }

    return [data, fetchFollowUp, refetchFollowUp] as const
}

export const useGetLogger = () => {
    const [data, setData] = useState<any>({data: [], total: 0})
    const [loading, setLoading] = useState(false)

    const fetchLog = async (filter, orFilter?, orderBy?, limit?, offset?) => {
        try {
            setLoading(true)
            const {getLogger} = await getLoggerAsync(filter, orFilter, orderBy, limit, offset)
            setData(getLogger)
        } catch (e) {

        } finally {
            setLoading(true)
        }
    }

    return [data, loading, fetchLog] as const
}