import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, Modal, Rate, Row, Select, Space} from "antd";
import {useTranslation} from "react-i18next";
import {useAddUserLeadsMutation} from "../../../../../api/graphql/leads";
import {formatProfileInformation, renderProfileInformation, validateConfirmKeys} from "../../../_common/function";
import useAuthorize from "../../../../../_common/authorize";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {fieldListState, notificationMessage} from "../../../../../../recoil_state";

const LeadsAddForm = (props) => {
    const {close, isOpen, tableName, profileInformation, refetchLeads} = props;
    const {t} = useTranslation();
    const {addUserLeads, loading} = useAddUserLeadsMutation();
    const {systemErrorNotification} = useAuthorize()
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [form] = Form.useForm()

    const [fields, setFields] = useState<any>({
        basicField: [],
        loginField: [],
        residenceField: [],
        additionalField: [],
    });
    const {basicField, loginField, residenceField, additionalField} = fields;
    const fieldList = useRecoilValue<any>(fieldListState)

    useEffect(() => {
        formatProfileInformation(fields, setFields, profileInformation);
    }, [profileInformation]);

    useEffect(() => {
        form.resetFields()
    },[isOpen])

    const onSubmit = async (value) => {
        try {
            Object.keys(value).forEach((key) => {
                if (value[key] === undefined) {
                    delete value[key];
                }
            });
            const validated = await validateConfirmKeys(value, setNotificationMessage, t)

            if (validated) {
                value["mobileNo"] = value?.mobileNo?.toString()
                delete value["combinedMobileNo"];
                value["potential"] = value?.potential?.toString()
                const {addUserLeads:response} = await addUserLeads(localStorage?.getItem("userId"), value);

                if (response?.__typename === "BaseError") {
                    setNotificationMessage({
                        status: "error",
                        title: "",
                        msg: t(addUserLeads?.errKey),
                        key: "addUserLeads",
                    });

                    return
                } else if (response?.__typename === "CrmTaskApproval") {
                    setNotificationMessage({
                        status: "info",
                        title: "",
                        msg: t(addUserLeads?.msg),
                        key: "addUserLeads",
                    });
                } else {
                    refetchLeads()

                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: t("Add User Leads Successfully"),
                        key: "addUserLeads",
                    });
                }

                close();
            }

        } catch (error: any) {
            console.log(error.message);
            systemErrorNotification()
        }
    };

    return (
        <Modal
            title={t("lead.addForm.modalTitle")}
            open={isOpen}
            footer={null}
            width={800}
            onCancel={() => close()}
            destroyOnClose
        >
            <Form onFinish={onSubmit} form={form} colon={false} labelWrap={true}>
                <Row gutter={[16, 16]} className={"margin-top-0-75 margin-bottom-0-75"}>
                    {basicField?.length > 0 && (
                        <Col span={24}>
                            <h4>{t("field.category.basicInformation")}</h4>
                            <Row gutter={[16, 16]} style={{marginBottom: 32}}>
                                {renderProfileInformation(basicField, {}, t, false, false, null, true)}
                                <Col span={12}>
                                    <Form.Item name={"comment"} label={t("lead.comment")} labelCol={{span: 10, offset: 0}} labelAlign="left">
                                        <Select
                                            showSearch
                                            allowClear
                                            options={fieldList?.find(d => d?.fieldName === "leadsComment")?.optionValue?.map(d => ({...d, label: t(d?.label)}))}
                                            // options={[
                                            //     {value: "deposited", label: t("comment.deposited")},
                                            //     {value: "followingUp", label: t("comment.followingUp")},
                                            //     {value: "noAnswer", label: t("comment.noAnswer")},
                                            //     {value: "garbage/invalidNo", label: t("comment.garbage_invalidNo")},
                                            //     {value: "blocked/giveUp", label: t("comment.blocked_giveUp")},
                                            //     {value: "assistanceNeeded", label: t("comment.assistanceNeeded")},
                                            //     {value: 'wrongPerson', label: t('comment.wrongPerson')},
                                            // ]}
                                            // dropdownRender={(menu) => {
                                            //     return (
                                            //         <>
                                            //             {menu}
                                            //             <Input placeholder={t("comment.others").toString()}
                                            //                    onChange={(e) => form.setFieldsValue({comment: e.target?.value})}
                                            //                    onKeyDown={(e) => e.stopPropagation()}
                                            //             />
                                            //         </>
                                            //     )
                                            // }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={"potential"}
                                        label={t("lead.potential")}
                                        labelCol={{span: 10, offset: 0}}
                                        labelAlign="left"
                                    >
                                        <Rate/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    )}
                    {loginField?.length > 0 && (
                        <Col span={24}>
                            <h4>{t("field.category.loginInformation")}</h4>
                            <Row gutter={[16, 16]} style={{marginBottom: 32}}>
                                {renderProfileInformation(loginField, {}, t, false)}
                            </Row>
                        </Col>
                    )}
                    {residenceField?.length > 0 && (
                        <Col span={24}>
                            <h4>{t("field.category.residenceInformation")}</h4>
                            <Row gutter={[16, 16]} style={{marginBottom: 32}}>
                                {renderProfileInformation(residenceField, {}, t, false)}
                            </Row>
                        </Col>
                    )}
                    {additionalField?.length > 0 && (
                        <Col span={24}>
                            <h4>{t("field.category.additionalInformation")}</h4>
                            <Row gutter={[16, 16]} style={{marginBottom: 32}}>
                                {renderProfileInformation(additionalField, {}, t, false)}
                            </Row>
                        </Col>
                    )}
                </Row>
                <Form.Item className="text-align-right" style={{paddingTop: "12px"}}>
                    <Space>
                        <Button onClick={close}>{t("common.cancel")}</Button>
                        <Button type={"primary"} loading={loading} htmlType={"submit"}>{t("common.add")}</Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default LeadsAddForm;
