import React from "react";

export const languageColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text, record) => <p>{record?.nativeName}</p>,
  },
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
    render: (text) => <p>{text}</p>,
  },
  {
    title: "Status",
    dataIndex: "isActive",
    key: "isActive",
    render: (isActive) => <p>{isActive? "Active": "Inactive" }</p>,
  },
];

export const translationFileColumns = [
  {
    title: "Key",
    dataIndex: "key",
    key: "key",
    editable: false,
    width: "40%"
  }
]