import React, {useEffect, useState} from "react";
import {Space, Table, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {AiOutlineEdit} from "react-icons/ai";
import {EyeOutlined} from "@ant-design/icons";
import {CSS} from "@dnd-kit/utilities";
import {DndContext, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {restrictToVerticalAxis} from "@dnd-kit/modifiers";
import {tableColumn} from "./tableColumn";
import expandedRowRender from "./expandedRowRender";
import {getPspSettingDetail} from "../../../../../../api/graphql/pspSettingDetail";
import {FormType} from "../../../function/formType";
import PspSettingFormModal from "./pspSettingFormModal";
import {SortableContext, useSortable, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {useUpdatePSPSettingMutation, useUpdatePSPSettingPositionMutation} from "../../../../../../api/graphql/pspSetting";
import {removeTypenameKey} from "../../../../pushNotificationTemplateEditor/function";
import useAuthorize from "../../../../../../_common/authorize";
import { useIsMobile } from "../../../../../../_common/layout";

function PspSettingTable_1(props) {
    const {
        // tableName,
        pspSettingPermission,
        pspSettingCurrencyPermission,
        pspSettingDetailPermission,
    } = props;
    const {t} = useTranslation();
    const {systemErrorNotification} = useAuthorize();
    const isMobile = useIsMobile(768);

    const [tableData, setTableData] = useState([]);
    const [filter, setFilter] = useState({});
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [formMode, setFormMode] = useState<FormType>(FormType.DEFAULT);
    const [selectedRow, setSelectedRow] = useState("");

    const toggleFormModal = async (formType: FormType, record?: any) => {
        if (record) {
            await setSelectedRow(record);
        }
        await setFormMode(formType);
        setIsFormModalOpen((prevState) => !prevState);
    };

    const {loading, error, data, refreshQuery} = getPspSettingDetail({filter: filter});
    const {updatePspSetting, loading: updateLoading} = useUpdatePSPSettingMutation();
    const {updatePspSettingPosition, loading: updatePositionLoading} = useUpdatePSPSettingPositionMutation();

    function groupByPspSettingName(data) {
        return (
            data?.reduce((result, item) => {
                const pspSettingName = item?.pspSetting?.name;

                if (!result[pspSettingName]) {
                    result[pspSettingName] = [];
                }

                result[pspSettingName].push(item);

                return result;
            }, {}) || []
        );
    }

    function groupByCurrency(data) {
        return (
            data?.reduce((result, item) => {
                const currency = item?.pspCurrency?.currency;

                if (!result[currency]) {
                    result[currency] = [];
                }

                result[currency].push(item);

                return result;
            }, {}) || []
        );
    }

    useEffect(() => {
        const groupedDataByPsp = groupByPspSettingName(data?.getPspSettingDetail?.data);

        let amendedGroupedDataByPsp: any = [];
        if (groupedDataByPsp) {
            amendedGroupedDataByPsp = Object.keys(groupedDataByPsp)
                ?.map((psp) => {
                    let pspSettingData = groupedDataByPsp[psp][0]?.pspSetting;
                    const groupedDataByCurrency = groupByCurrency(groupedDataByPsp[psp]);
                    let pspCurrencyData = Object.keys(groupedDataByCurrency)?.map((cur) => {
                        return {
                            pspCurrency: groupedDataByCurrency[cur][0]?.pspCurrency,
                            children: groupedDataByCurrency[cur],
                        };
                    });

                    return {pspSetting: pspSettingData, currencyList: pspCurrencyData};
                })
                ?.filter((v: any) => v?.pspSetting?.enabled === true); // filter out those PSP settings that are enabled false
        }

        setTableData(amendedGroupedDataByPsp);
    }, [data?.getPspSettingDetail?.data]);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 1,
            },
        })
    );

    const onDragEnd = async ({active, over}) => {
        try {
            if (active.id !== over?.id) {
                updatePspSettingPosition(active.id, over.id)

                // let selectedPspId = active?.id;
                // let newPosition = over?.data?.current?.sortable?.index + 1;
                //
                // if (newPosition) {
                //     let selectedPspObj: any = amendedTableData?.find((v: any) => v?.pspSetting?._id === selectedPspId);
                //     let selectedPspSettingObj = removeTypenameKey(selectedPspObj?.pspSetting);
                //     let input = {...selectedPspSettingObj, position: newPosition};
                //     updatePspSetting(selectedPspId, input);
                // }
            }
        } catch (e) {
            systemErrorNotification();
        } finally {
            setTimeout(() => {
                refreshQuery();
            }, 1000);
        }
    };

    const isEditable = pspSettingPermission?.edit?.edit && pspSettingCurrencyPermission?.edit?.edit && pspSettingDetailPermission?.edit?.edit;

    const tableRow = (props) => {
        if (updateLoading) {
            return <tr {...props} />;
        }

        const {attributes, listeners, setNodeRef, transform, transition, isDragging} = useSortable({
            id: props["data-row-key"] ? props["data-row-key"] : "",
        });
        const style = {
            ...props.style,
            transform: CSS.Transform.toString(
                transform && {
                    ...transform,
                    scaleY: 1,
                }
            ),
            transition,
            cursor: "move",
            ...(isDragging
                ? {
                      position: "relative",
                      zIndex: 9999,
                  }
                : {}),
        };
        return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
    };

    let amendedTableData = tableData?.sort((a: any, b: any) => a?.pspSetting?.position - b?.pspSetting?.position);

    return (
        <div id="PspSettingTable" className={"margin-top-0-75"}>
            <DndContext onDragEnd={onDragEnd} sensors={sensors} modifiers={[restrictToVerticalAxis]}>
                <SortableContext
                    // rowKey array
                    items={amendedTableData.map((record: any) => (record ? record?.pspSetting?._id : ""))}
                    strategy={verticalListSortingStrategy}
                >
                    <Table
                        dataSource={amendedTableData}
                        rowKey={(record) => (record ? record?.pspSetting?._id : "")}
                        // tableName={tableName}
                        components={
                            isEditable && !isMobile
                                ? {
                                      body: {
                                          row: tableRow,
                                      },
                                  }
                                : undefined
                        }
                        columns={[
                            ...tableColumn,
                            {
                                title: t("common.action"),
                                key: "action",
                                width: 100,
                                align: "center",
                                render: (_, record: any) => (
                                    <Space>
                                        {pspSettingPermission?.view && (
                                            <Tooltip title="View">
                                                <a onClick={() => toggleFormModal(FormType.View, record)}>
                                                    <EyeOutlined />
                                                </a>
                                            </Tooltip>
                                        )}
                                        {isEditable && (
                                            <Tooltip title="Edit">
                                                <a onClick={() => toggleFormModal(FormType.Edit, record)}>
                                                    <AiOutlineEdit />
                                                </a>
                                            </Tooltip>
                                        )}
                                    </Space>
                                ),
                            },
                        ]}
                        size={"small"}
                        loading={loading || updateLoading}
                        pagination={false}
                        expandable={{expandedRowRender}}
                    />
                </SortableContext>
            </DndContext>

            {isFormModalOpen ? (
                <PspSettingFormModal
                    selectedRow={selectedRow}
                    formMode={formMode}
                    isFormModalOpen={isFormModalOpen}
                    toggleFormModal={toggleFormModal}
                />
            ) : null}
        </div>
    );
}

export default PspSettingTable_1;
