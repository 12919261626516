import React, {useEffect, useState} from "react";
import grapesjs from "grapesjs";
import newsletterPlugin from "grapesjs-preset-newsletter";
import "grapesjs/dist/css/grapes.min.css";
import {addCommand} from "../../../function/addCommand";
import {getTextFromObject} from "../../../function/common";

function GrapeJSEditor(props) {
    const {data, languageCode, imageVarData, textVarData, pdfVarData, setNotificationDataObject} = props;
    const [pushNotificationTemplate, setPushNotificationTemplate] = useState("");
    const [editor, setEditor] = useState<Record<string, any>>();

    const renderCustomBlocks = (editor: Record<string, any>) => {
        const bm = editor?.Blocks;
        textVarData?.map((v: any) => {
            bm.add(v?.name, {
                // label: "VAR-" + v.name,
                label: v?.name,
                // media: `<i class="fa fa-text-width" aria-hidden="true"></i>`,
                category: "Brand-Text-Variable",
                activate: v?.isActive,
                content: `<div data-gjs-editable="false">{{dynamicVariable:${v?.name}}}</div>`,
            });
        });
        pdfVarData?.map((v: any) => {
            let filePath = v?.fileObject?.find((v) => v?.language === languageCode)?.path;
            bm?.add(v?.name, {
                label: v?.name,
                media: `<i class="fa fa-file-pdf-o" aria-hidden="true"></i>`,
                category: "Brand-PDF-Variable",
                activate: v?.isActive,
                content: `<div data-gjs-editable="false"><a style="box-sizing: border-box; color: #3b97e3;" href=${filePath}>{{dynamicVariable:${v?.name}}}</a></div>`,
                // content: {
                //   type: "link",
                //   components: `<a style="box-sizing: border-box; color: #3b97e3;" href="{{${v?.name}}}">{{${v?.name}}}</a>`
                // },
            });
        });
        imageVarData?.map((v: any) => {
            let imgPath = v?.fileObject?.find((v) => v?.language === languageCode)?.path;
            if (imgPath) {
                bm?.add(v?.name, {
                    label: v?.name,
                    // media: `<i class="fa fa-file-image-o" aria-hidden="true"></i>`,
                    media: `<img src=${imgPath} alt=${v?.name} />`,
                    category: "Brand-Image-Variable",
                    activate: v?.isActive,
                    content: `<img src=${imgPath} alt=${v?.name} style="box-sizing: border-box; color: black;">`,
                });
            }
        });
    };

    const renderEditBtn = (editor: Record<string, any>) => {
        editor?.Panels?.addButton("options", [
            {
                id: "edit",
                className: "fa fa-edit",
                command: "html-edit",
                attributes: {
                    title: "Edit",
                },
            },
        ]);
    };

    useEffect(() => {
        if (data?.dataArrayOfObject) {
            const htmldata = getTextFromObject(data?.dataArrayOfObject, "longContent", languageCode);
            setPushNotificationTemplate(htmldata);
        }
    }, [languageCode, data]);

    useEffect(() => {
        if (editor && textVarData && pdfVarData && imageVarData) renderCustomBlocks(editor);
    }, [editor, textVarData, pdfVarData, imageVarData]);

    useEffect(() => {
        if (editor && data) {
            addCommand(editor);
            renderEditBtn(editor);
        }
    }, [editor, data]);

    useEffect(() => {
        if (editor) {
            editor.on("change:changesCount", (e) => {
                // Change!
                // console.log("editor event");
                // console.log(e);
                const am = editor.AssetManager;
                let htmlData = editor?.runCommand("gjs-get-inlined-html");
                setNotificationDataObject(prev => {
                    const tempData = [...prev];
                    const index = tempData.findIndex(item => item.language === languageCode);

                    if (index !== -1) {
                        tempData[index] = {
                            ...tempData[index],
                            longContent: htmlData
                        };
                    } else {
                        tempData.push({
                            longContent: htmlData,
                            language: languageCode
                        });
                    }

                    return tempData;
                });

                am.render();
            });
        }
    }, [editor]);

    useEffect(() => {
        const editor = grapesjs.init({
            container: `#gjs-${languageCode}`,
            fromElement: true,
            height: "700px",
            storageManager: false,
            forceClass: false,
            modal: {
                backdrop: false,
            },
            plugins: [newsletterPlugin],
            pluginsOpts: {
                [newsletterPlugin.toString()]: {
                    modalLabelImport: "Paste all your code here below and click import",
                    modalLabelExport: "Copy the code and use it wherever you want",
                    importPlaceholder: '<table class="table"><tr><td class="cell">Hello world!</td></tr></table>',
                    cellStyle: {
                        "font-size": "12px",
                        "font-weight": 300,
                        "vertical-align": "top",
                        color: "rgb(111, 119, 125)",
                        margin: 0,
                        padding: 0,
                    },
                },
            },
            projectData: {
                pages: [
                    {
                        component: pushNotificationTemplate,
                    },
                ],
            },
        });
        setEditor(editor);
    }, [pushNotificationTemplate]);

    return (
        <div id={`gjs-${languageCode}`}/>
    );
}

export default GrapeJSEditor;
