import React from "react";
import {getColumnSearchProps} from "../../../../../components/table/function/filterTable";

export const columnsCT = [
  {
    title: "Symbol Id",
    dataIndex: "symbolId",
    key: "symbolId",
    ...getColumnSearchProps(),
    render: (text) => <p>{text || "-"}</p>,
  },
  {
    title: "Symbol",
    dataIndex: "name",
    key: "name",
    ...getColumnSearchProps(),
    render: (text) => <p>{text || "-"}</p>
  },
  {
    title: "Bid",
    dataIndex: "bid",
    key: "bid",
    align: "right",
    shouldCellUpdate: (record, prevRecord) => prevRecord?.bid != record?.bid,
    render: (text) => <p>{text || "-"}</p>
  },
  {
    title: "Ask",
    dataIndex: "ask",
    key: "ask",
    align: "right",
    shouldCellUpdate: (record, prevRecord) => prevRecord?.ask != record?.ask,
    render: (text) => <p>{text || "-"}</p>
  },
  {
    title: "Spread",
    dataIndex: "spread",
    key: "spread",
    align: "right",
    shouldCellUpdate: (record, prevRecord) => prevRecord?.spread != record?.spread,
    render: (text) => <p>{text || "-"}</p>
  },
  {
    title: "Quote Time",
    dataIndex: "quoteTime",
    key: "quoteTime",
    align: "right",
    render: (text) => <p>{text || "-"}</p>
  },
  {
    title: "Digits",
    dataIndex: "digits",
    key: "digits",
    align: "right",
    render: (text) => <p>{text || "-"}</p>
  },
  {
    title: "Mark Up Bid",
    dataIndex: "bidMarkUp",
    key: "bidMarkUp",
    align: "right",
    render: (text) => <p>{text || "-"}</p>
  },
  {
    title: "Mark Up Ask",
    dataIndex: "askMarkUp",
    key: "askMarkUp",
    align: "right",
    render: (text) => <p>{text || "-"}</p>
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (text) => <p>{text || "-"}</p>
  },
  {
    title: "Base Asset",
    dataIndex: "baseAssetId",
    key: "baseAssetId",
    render: (text) => <p>{text?.length > 0 ? text?.[0]?.name : "-"}</p>
  },
  {
    title: "Quote Asset",
    dataIndex: "quoteAssetId",
    key: "quoteAssetId",
    render: (text) => <p>{text?.length > 0 ? text?.[0]?.name : "-"}</p>
  },
  {
    title: "Asset Class",
    dataIndex: "assetClass",
    key: "assetClass",
    render: (text) => <p>{text || "-"}</p>
  },
  {
    title: "Symbol Category",
    dataIndex: "symbolCategoryId",
    key: "symbolCategoryId",
    render: (text) => <p>{text?.length > 0 ? text?.[0]?.name : "-"}</p>
  },
  {
    title: "Price Feed",
    dataIndex: "priceLiquidityFeedId",
    key: "priceLiquidityFeedId",
    render: (text) => <p>{text?.length > 0 ? text?.[0]?.type : "-"}</p>
  },
  {
    title: "Trade Feed",
    dataIndex: "tradeLiquidityFeedId",
    key: "tradeLiquidityFeedId",
    render: (text) => <p>{text?.length > 0 ? text?.[0]?.type : "-"}</p>
  },
  {
    title: "Dividend Time",
    dataIndex: "defaultDividendTime",
    key: "defaultDividendTime",
    render: (text) => <p>{text || "-"}</p>
  },
  {
    title: "Session Profile",
    dataIndex: "scheduleProfileId",
    key: "scheduleProfileId",
    render: (text) => <p>{text?.length > 0 ? text?.[0]?.name : "-"}</p>
  },
  // {
  //   title: "Popular Market",
  //   dataIndex: "popularMarket",
  //   key: "popularMarket",
  //   render: (text) => <p>{text || "-}</p>
  // },
];
