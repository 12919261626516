import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useRecoilState} from "recoil";
import {getSpreadGroupMt5} from "../../../../../../api/graphql/spreadGroupMt5";
import useAuthorize from "../../../../../../_common/authorize";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import {spreadGroupMt5ReloadState} from "../../../state";
import {Col, Row} from "antd";
import {SpreadGroupMt5Sider} from "./spreadGroupMt5Sider";


const SpreadGroupMt5Table = ({tableNameMt5, columnMt5, server}) => {

    const [loading, setLoading] = useState(false)
    const [batchData, setBatchData] = useState([])
    const [batchDataTotal, setBatchDataTotal] = useState(0)
    const [orderBy, setOrderBy] = useState("");
    const [filter, setFilter] = useState({});
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [dateKey, setDateKey] = useState("");
    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(0);
    const [mt5TableRefresh, setMt5TableRefresh] = useRecoilState(spreadGroupMt5ReloadState)
    const [currentData, setCurrentData] = useState({});

    const {t} = useTranslation()
    const {systemErrorNotification} = useAuthorize()

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize)
        setOffset(limit * (page - 1))
    }

    const preload = async () => {
        try {
            await setLoading(true)

            const response = await getSpreadGroupMt5({
                ...filter,
                serverSetting: server?.id,
                isArchived: false,
            }, orderBy, limit, offset, startDate, endDate, dateKey)

            setBatchDataTotal(response?.getSpreadGroupMt5?.total)
            setBatchData(response?.getSpreadGroupMt5?.data)

            if (Object.keys(currentData)?.length === 0) {
                setCurrentData(response?.getSpreadGroupMt5?.data?.[0])
            }

        } catch (e) {
            systemErrorNotification()
        } finally {
            setLoading(false)
            setMt5TableRefresh(false)
        }
    }

    useEffect(() => {
        if (mt5TableRefresh) {
            preload()
        }
    }, [mt5TableRefresh])

    useEffect(() => {

        if(server?.id){
            preload()
        }


    }, [server,limit, offset, orderBy, startDate, endDate, dateKey, filter])

    const onRow = (record, rowIndex) => {
        return {
            onClick: () => {
                setCurrentData(record);
            },
        };
    };


    return (
        <Row gutter={[16, 16]} className={"margin-top-0-75"}>
            <Col span={6}>
                <TableAntDesign
                    data={batchData}
                    tableName={tableNameMt5}
                    columns={columnMt5}
                    size={"small"}
                    loading={loading}
                    filter={setFilter}
                    order={setOrderBy}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    setDateKey={setDateKey}
                    onRow={onRow}
                    pagination={{
                        pageSize: limit,
                        showSizeChanger: true,
                        onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                        total: batchDataTotal,
                        showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
                    }}
                />
            </Col>
            <Col span={18}>
                <SpreadGroupMt5Sider initialData={currentData} server={server}/>
            </Col>
        </Row>
    )
}

export default SpreadGroupMt5Table