import { gql, useMutation, useQuery } from "@apollo/client";
import { useSetRecoilState } from "recoil";
import { errorMessage, notificationMessage } from "../../../recoil_state";
import { useTranslation } from "react-i18next";

const GET_PRODUCT_GROUP_COUNT = gql`
  query getProductGroupCount($filter: ProductGroupSearchInput) {
    getProductGroupCount(filter: $filter)
  }
`;

const getProductGroupCount = ({ filter }) => {
  try {
    const { loading, error, data } = useQuery(GET_PRODUCT_GROUP_COUNT, {
      variables: {
        filter: {
          name: filter?.name || null,
          spreadAdjustment: filter?.spreadAdjustment || null,
          type: filter?.type || null,
        },
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_PRODUCT_GROUP_BY_ID = gql`
  query getProductGroupByID($id: String!) {
    getProductGroupByID(id: $id) {
      ... on ProductGroup {
        id
        _id
        name
        spreadAdjustment
        type
        createdBy {
          username
        }
        updatedBy {
          username
        }
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getProductGroupById = (id) => {
  try {
    const { loading, error, data } = useQuery(GET_PRODUCT_GROUP_BY_ID, {
      variables: {
        id: id,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_PRODUCT_GROUP = gql`
  query getProductGroup(
    $filter: ProductGroupSearchInput
    $limit: Int
    $offset: Int
    $orderBy: String
  ) {
    getProductGroup(
      filter: $filter
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      ... on ProductGroupArray {
        data {
          id
          _id
          name
          spreadAdjustment
          type
          createdBy {
            username
          }
          updatedBy {
            username
          }
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getProductGroup = ({ filter }, limit?, offset?, orderBy?) => {
  try {
    const { loading, error, data } = useQuery(GET_PRODUCT_GROUP, {
      variables: {
        filter: {
          name: filter?.name || null,
          spreadAdjustment: filter?.spreadAdjustment || null,
          type: filter?.type || null,
        },
        limit: limit || null,
        offset: offset || null,
        orderBy: orderBy || null,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const ADD_PRODUCT_GROUP = gql`
  mutation addProductGroup($input: ProductGroupInput) {
    addProductGroup(input: $input) {
      ... on CrmTaskApproval {
        msg
      }
      ... on ProductGroup {
        id
        _id
        name
        spreadAdjustment
        type
        createdBy {
          username
        }
        updatedBy {
          username
        }
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useAddProductGroupMutation = (): {
  addProductGroup: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const { t } = useTranslation();
    const [addProductGroupMutation, { data, loading, error }] =
      useMutation(ADD_PRODUCT_GROUP);

    const addProductGroup = (input) => {
      return addProductGroupMutation({
        variables: {
          input: {
            name: input.name,
            spreadAdjustment: input.spreadAdjustment,
            server: input.server,
            type: input.type,
          },
        },
        update(cache, { data: { addProductGroup } }) {
          const typename = addProductGroup?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(addProductGroup?.errKey),
              key: "addProductGroup",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(addProductGroup?.msg),
              key: "addProductGroup",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Add Successfully"),
              key: "addProductGroup",
            });
          }
        },
        refetchQueries: [GET_PRODUCT_GROUP, GET_PRODUCT_GROUP_COUNT],
      });
    };

    return { addProductGroup, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return { addProductGroup: null, data: null, loading: false, error: err };
  }
};

const UPDATE_PRODUCT_GROUP = gql`
  mutation updateProductGroup($id: String!, $input: ProductGroupInput) {
    updateProductGroup(id: $id, input: $input) {
      ... on CrmTaskApproval {
        msg
      }
      ... on ProductGroup {
        id
        _id
        name
        spreadAdjustment
        type
        createdBy {
          username
        }
        updatedBy {
          username
        }
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useUpdateProductGroupMutation = (): {
  updateProductGroup: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const { t } = useTranslation();
    const [updateProductGroupMutation, { data, loading, error }] =
      useMutation(UPDATE_PRODUCT_GROUP);

    const updateProductGroup = (id, input) => {
      return updateProductGroupMutation({
        variables: {
          id: id,
          input: {
            name: input.name,
            spreadAdjustment: input.spreadAdjustment,
            server: input.server,
            type: input.type,
          },
        },
        update(cache, { data: { updateProductGroup } }) {
          const typename = updateProductGroup?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(updateProductGroup?.errKey),
              key: "updateProductGroup",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(updateProductGroup?.msg),
              key: "updateProductGroup",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Update Product Group Successfully"),
              key: "updateProductGroup",
            });
          }
        },
        refetchQueries: [GET_PRODUCT_GROUP, GET_PRODUCT_GROUP_COUNT],
      });
    };

    return { updateProductGroup, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return { updateProductGroup: null, data: null, loading: false, error: err };
  }
};

const DELETE_PRODUCT_GROUP = gql`
  mutation deleteProductGroup($id: String!) {
    deleteProductGroup(id: $id) {
      ... on CrmTaskApproval {
        msg
      }
      ... on ProductGroup {
        id
        isDeleted
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useDeleteProductGroupMutation = (): {
  deleteProductGroup: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const { t } = useTranslation();
    const [deleteProductGroupMutation, { data, loading, error }] =
      useMutation(DELETE_PRODUCT_GROUP);

    const deleteProductGroup = (id) => {
      return deleteProductGroupMutation({
        variables: {
          id: id,
        },
        update(cache, { data: { deleteProductGroup } }) {
          const typename = deleteProductGroup?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(deleteProductGroup?.errKey),
              key: "deleteProductGroup",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(deleteProductGroup?.msg),
              key: "deleteProductGroup",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Delete Successfully"),
              key: "deleteProductGroup",
            });
          }
        },
        refetchQueries: [GET_PRODUCT_GROUP, GET_PRODUCT_GROUP_COUNT],
      });
    };

    return { deleteProductGroup, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return { deleteProductGroup: null, data: null, loading: false, error: err };
  }
};

export {
  getProductGroupCount,
  getProductGroupById,
  getProductGroup,
  useAddProductGroupMutation,
  useUpdateProductGroupMutation,
  useDeleteProductGroupMutation,
};
