import React, {useEffect} from "react";
import {Col, Input, List, Row, Tag} from "antd";
import {useTranslation} from "react-i18next";
import {useRecoilValue} from "recoil";
import moment from "moment";
import {FieldTimeOutlined, LaptopOutlined} from "@ant-design/icons";
import {getUserById} from "../../../../../../../api/graphql/user";
import TaskClientDetails from "../../clientDetails";
import {useClientDetailFunction} from "../../../../../../users/clients/detail/function";
import {userAccountVerificationRecordsState} from "../../../../../../users/clients/state";
import AccountVerificationFieldComponent from "./_accountVerificationFieldComponent";
import {formatDateTimeTimezone} from "../../../../../../../function/_common";

function AccountVerificationApprovalLayout_1(props) {
    const {data, mode, setComment, comment} = props;
    const {getUserVerificationRecordFunction} = useClientDetailFunction();
    const userVerificationRecord = useRecoilValue<any>(userAccountVerificationRecordsState);
    // const [dataSource, setDataSource] = useState<any>()
    const {t} = useTranslation();

    // const onUpdateUserVerificationRecordValue = async (id, value, valueMobile, expiredDate) => {
    //     try {
    //         let input: any = {};
    //         if (valueMobile) {
    //             input.valueMobile = valueMobile;
    //         }
    //         if (value) {
    //             input.value = value;
    //         }
    //         if (expiredDate) {
    //             input.expiredDate = expiredDate;
    //         }
    //         if (Object.keys(input)?.length > 0) {
    //             const res = await updateUserVerificationRecordValue(id, input);
    //             if (res?.updateUserVerificationRecordValue?.__typename === "UserVerificationRecordValue") {
    //                 getUserVerificationRecordFunction({task: data?._id});
    //                 onEditingFieldChanged(id);
    //             }
    //         }
    //     } catch (error) {}
    // };

    const getUserVerificationRecord = () => {
        try {
            getUserVerificationRecordFunction({task: data?._id});
        } catch (error) {}
    };

    useEffect(() => {
        setComment(null)
        if (data?.payload?.record) {
            getUserVerificationRecord();
        }
    }, [data]);

    const userDetail = getUserById(data?.payload?.user);

    // const processData = useCallback(() => {
    //     const step = recordDocuments?.map(d => {
    //         return {
    //             step: d?.groupSetting?.step,
    //             fieldValue: d?.fieldValue,
    //         }
    //     })
    //     setDataSource(step)
    // },[recordDocuments])
    //
    // useEffect(() => {
    //     processData()
    // },[recordDocuments])

    const excludeStatusList =
        data?.status === "pending"
            ? ["cancelled", "draft verification", "start", "not verified", "verification failed", "approved"]
            : ["cancelled", "draft verification", "start", "not verified"];
    const listDataSource = userVerificationRecord?.[0]?.recordDocument?.filter((v) => !excludeStatusList?.includes(v?.status));

    return (
        <div id={"kycApprovalLayout"}>
            <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>{userDetail?.data?.getUserByID && <TaskClientDetails data={userDetail?.data?.getUserByID} removeKyc={true} />}</Col>

                <Col xs={24} md={12}>
                    <List
                        size={"small"}
                        className="accountVerificationList margin-bottom-0-75"
                        itemLayout="horizontal"
                        dataSource={listDataSource}
                        renderItem={(item: any) => {
                            const duration = `${item?.duration?.days ? item?.duration?.days + " days" : ""}  ${(item?.duration?.hours ?? 0)
                                .toString()
                                .padStart(2, "0")} hrs ${(item?.duration?.minutes ?? 0).toString().padStart(2, "0")} mins ${(
                                item?.duration?.seconds ?? 0
                            )
                                .toString()
                                .padStart(2, "0")} sec`;
                            let tagColor;
                            switch (item?.status) {
                                case "verification failed":
                                    tagColor = "red";
                                    break;
                                case "verified":
                                    tagColor = "green";
                                    break;
                                case "verification expired":
                                    tagColor = "orange";
                                    break;
                                case "pending verification":
                                    tagColor = "yellow";
                                    break;
                                default:
                                    tagColor = "default";
                                    break;
                            }

                            return (
                                <List.Item>
                                    <Row gutter={[16, 16]} className={"margin-bottom-0-5"} style={{width: "100%"}} align={"middle"}>
                                        <Col span={24}>
                                            <div>
                                                <strong>{t(item?.group?.label)}</strong>{" "}
                                                <Tag color={tagColor} bordered={false}>
                                                    {t(`accountVerification.status.${item?.status.replaceAll(" ", "_")}`)}
                                                </Tag>
                                            </div>
                                            <div>
                                                <small>{t(item?.document?.label)}</small>
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                            <Row>
                                                <Col span={12}>
                                                    <LaptopOutlined /> &nbsp;<small>{`${item?.device} | ${item?.browser}`}</small>
                                                </Col>
                                                <Col span={12}>
                                                    <FieldTimeOutlined /> &nbsp;
                                                    <small>{t("task.start")}: {formatDateTimeTimezone(item?.clientStartTime)}</small>
                                                </Col>
                                                <Col span={12}>
                                                    <FieldTimeOutlined /> &nbsp;<small>{t("task.duration")}: {duration}</small>
                                                </Col>
                                                <Col span={12}>
                                                    <FieldTimeOutlined /> &nbsp;
                                                    <small>{t("task.end")}: {formatDateTimeTimezone(item?.clientEndTime)}</small>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={24}>
                                            {item?.fieldValue
                                                ?.filter((v) => !excludeStatusList?.includes(v?.status))
                                                ?.sort((a, b) => a?.memberId - b?.memberId)
                                                ?.map((d) => {
                                                    return (
                                                        <AccountVerificationFieldComponent
                                                            mode={mode}
                                                            d={d}
                                                            getUserVerificationRecord={getUserVerificationRecord}
                                                        />
                                                    );
                                                })}
                                        </Col>
                                    </Row>
                                </List.Item>
                            );
                        }}
                    />
                </Col>
                {/*{*/}
                {/*    mode === "verify" && data?.status === "pending" && data?.isClaimed === true && (*/}
                {/*        <Col span={24} style={{textAlign: "right", marginTop: "15px"}}>*/}
                {/*            <Space>*/}
                {/*                <Button onClick={rejectTaskAction} loading={rejectBtnLoading} danger>*/}
                {/*                    Reject*/}
                {/*                </Button>*/}
                {/*                <Button onClick={approveTaskAction} loading={approveBtnLoading}>*/}
                {/*                    Approve*/}
                {/*                </Button>*/}
                {/*            </Space>*/}
                {/*        </Col>*/}
                {/*    )*/}
                {/*}*/}
                <Col span={24}>
                    <label>{t("task.comment")}: </label>
                    <Input
                        value={comment || userVerificationRecord?.[0]?.comment}
                        disabled={mode === "detail"}
                        onChange={(e) => setComment(e.target.value)}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default AccountVerificationApprovalLayout_1;
