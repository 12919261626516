import React, {useState} from "react";
import {Modal, Spin} from "antd";
import PushNotificationServerForm from "./pushNotificationServerSettingsForm";
import {useTranslation} from "react-i18next";

const PushNotificationServerSettingsModal = (props) => {
    const {mode, item, isOpen, closeModal, data} = props
    const [spinning, setSpinning] = useState(false)
    const {t} = useTranslation()

    const getTitle = (): String => {
        switch (mode) {
            case "add":
                return `${t("Add New Push Notification Server")}`
            case "view":
                return `${t("View Push Notification Server")}`
            case "edit":
                return `${t("Edit Push Notification Server")}`
            default:
                return `${t("Add New Push Notification New Server")}`
        }
    }

    function convertToTradingKey(record) {
        if (record) {
            const result = {...record};
            if (record?.fieldForTrading && record?.fieldForTrading?.length > 0) {
                record?.fieldForTrading?.forEach(item => {
                    result[`tradingKey-${item.tradingKey}`] = item.clientField;
                });
            }
            return result;
        }
    }

    return (
        <Modal
            getContainer={"#serverSettings"}
            title={getTitle()}
            open={isOpen}
            width={"80%"}
            footer={null}
            onCancel={closeModal}
            destroyOnClose
        >
            <Spin spinning={false} tip={mode === "add" ? "Fetching data from server" : "Updating"}>
                <PushNotificationServerForm
                    mode={mode}
                    item={convertToTradingKey(item)}
                    closeModal={closeModal}
                    setSpinning={setSpinning}
                    data={data}
                />
            </Spin>
        </Modal>
    );
};

export default PushNotificationServerSettingsModal;
