import {Popconfirm, Space, Table, Tooltip} from "antd";
import React, { useState } from "react";
import {columns} from "../../../function/tableColumn";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

const CompanyEmailTable = (props) => {
    const {data, companyEmailPermission, toggleModal, deleteCompanyEmail} = props;
    const {t} = useTranslation();
    const [limit, setLimit] = useState<any>(10)

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize)
    }

    return (
        <Table
            className={"margin-top-0-75"}
            dataSource={data}
            scroll={{x: true}}
            columns={[
                ...columns,
                {
                    title: t("common.action"),
                    key: "action",
                    width: 132,
                    render: (_, record: any) => (
                        <Space size="middle">
                            <Tooltip title={t("View")}>
                                <a onClick={() => toggleModal("view", record)}>
                                    <EyeOutlined />
                                </a>
                            </Tooltip>
                            {companyEmailPermission?.edit?.edit && (
                                <Tooltip title={t("Edit Permission")}>
                                    <a onClick={() => toggleModal("edit", record)}>
                                        <EditOutlined />
                                    </a>
                                </Tooltip>
                            )}
                            {companyEmailPermission?.delete?.delete && (
                                <Tooltip title={t("Delete")}>
                                    <Popconfirm
                                        placement="left"
                                        title={"Are you sure to delete this email?"}
                                        description={"Delete the Email"}
                                        onConfirm={() => {
                                            deleteCompanyEmail(record._id);
                                        }}
                                        okText={t("common.yes")}
                                        cancelText={t("common.no")}
                                    >
                                        <a>
                                            <DeleteOutlined />
                                        </a>
                                    </Popconfirm>
                                </Tooltip>
                            )}
                        </Space>
                    ),
                },
            ]}
            size="small"
            pagination={{
                pageSize: limit,
                showSizeChanger: true,
                onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                total: data?.length || 0,
                showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
            }}
        />
    );
};

export default CompanyEmailTable;
