import React, {useEffect, useMemo, useState} from "react";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {Button, Col, Form, Row} from "antd";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import yupFormSchema from "../../../../../../components/yup/yupFormSchema";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import {verticalFormLayout} from "../../../../../../components/form/formWrapper";

import RadioGroupFormItem from "../../../../../../components/form/radioGroupFormItem";
import EmailProviderFormItem from "../../../../../../components/form/emailProviderFormItem";
import {useAddEmailTemplateMutation, useUpdateEmailTemplateMutation} from "../../../../../../api/graphql/emailTemplate";
import {extractIdFromObject} from "../../../function/common";
import useAuthorize from "../../../../../../_common/authorize";
import {getCompanyEmailTypes} from "../../../../../../api/graphql/companyEmailType";
import SelectFormItem from "../../../../../../components/form/selectFormItem";

const EmailTemplateForm = (props) => {
    const {mode, item, htmlState, closeModal, plainHtml, openEditor, newsletterData, displaySubjectLanguage, languageData} = props;

    const {t} = useTranslation();
    const [errMsg, setErrMsg] = useState("");
    const [emailTypesData, setEmailTypesData] = useState([]);
    const [isFixedEmail, setIsFixedEmail] = useState(item?.isFixedEmail);

    const getCompanyAnnouncementNewsletterId = () => {
        let [target] = newsletterData.filter((item) => item.name === "companyAnnouncement") ?? [];
        return target?._id?.toString();
    };

    const [initialValues] = useState(() => ({
        displayName: item?.displayName || "",
        from: item?.from?._id || "",
        taggedToNewsletter: item?.taggedToNewsletter?._id || getCompanyAnnouncementNewsletterId(),
        subject: item?.subject || "",
        displaySubjectLanguage: item?.displaySubjectLanguage || [],
        emailType: item?.emailType?._id || "",
        isFixedEmail: item?.isFixedEmail ? true : false,
        isActive: item?.isActive ? true : false,
    }));

    let schemaObj: any = useMemo(() => {
        let obj: any = {
            displayName: yupFormSchema.string("displayName", {required: true}),
            subject: yupFormSchema.string("subject"),
            isActive: yupFormSchema.boolean("isActive"),
            isFixedEmail: yupFormSchema.boolean("isFixedEmail"),
            taggedToNewsletter: yupFormSchema.string("taggedToNewsletter"),
        };
        if (isFixedEmail) {
            obj.from = yupFormSchema.string("sender email", {required: isFixedEmail});
        } else {
            obj.emailType = yupFormSchema.string("emailType", {required: !isFixedEmail});
        }
        return;
    }, [isFixedEmail]);

    const emailTemplateSchema = yup.object().shape(schemaObj);

    const emailTypeOptions = useMemo(() => {
        return emailTypesData?.map((type: any) => ({
            label: type?.name,
            value: type?._id,
        }));
    }, [emailTypesData]);

    const newsletterOptions = useMemo(() => {
        return newsletterData
            ?.map((type: any) => ({
                label: type?.description,
                value: type?._id,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [newsletterData]);

    const form = useForm({
        resolver: yupResolver(emailTemplateSchema),
        mode: "all",
        defaultValues: initialValues,
    });

    const onCancel = () => {
        closeModal();
    };

    const getCompanyEmailTypesData = async () => {
        try {
            const response = await getCompanyEmailTypes({
                filter: null,
            });
            const data = response?.getCompanyEmailTypes?.data;
            setEmailTypesData(data);
        } catch (error) {}
    };

    useEffect(() => {
        getCompanyEmailTypesData();
    }, []);

    const {addEmailTemplate} = useAddEmailTemplateMutation();
    const {updateEmailTemplate} = useUpdateEmailTemplateMutation();
    const {systemErrorNotification} = useAuthorize();

    const mergeObject = () => {
        try {
            let res = [...htmlState]?.map((item) => {
                let html = item?.html || null;
                return {
                    ...item,
                    html,
                    head: plainHtml?.filter((x) => x?.language === item?.language)[0]?.head || null,
                    attachment: extractIdFromObject(htmlState?.filter((x) => x?.language === item?.language)[0]?.attachment) || null,
                };
            });
            return res;
        } catch (error) {
            console.log(error);
        }
    };

    const onSubmit = async (values) => {
        try {
            setErrMsg("");
            const result = {
                ...values,
                htmlObject: mergeObject(),
                displaySubjectLanguage: displaySubjectLanguage,
            };
            if (isFixedEmail) {
                result.emailType = "";
            } else {
                result.from = "";
            }
            const isAllSubjectLanguageFilled = languageData?.every((v) => {
                let subjectLang = displaySubjectLanguage?.find((d) => d?.language === v?.code);
                if (subjectLang && subjectLang?.label) {
                    return true;
                } else {
                    return false;
                }
            });
            const isAllRequiredFieldsFilled = isAllSubjectLanguageFilled && (isFixedEmail ? result?.from?.length > 0 : result?.emailType?.length > 0);
            if (isAllRequiredFieldsFilled) {
                const response = mode === "add" ? await addEmailTemplate(result) : await updateEmailTemplate(item?._id, result);
                const {data} = response;
                const res = mode === "add" ? data?.addEmailTemplate : data?.updateEmailTemplate;
                if (res?.__typename === "BaseError") {
                    let errMsg = t(res?.errKey);
                    setErrMsg(errMsg);
                } else {
                    if (res && mode === "add") {
                        openEditor(res?.id);
                    }
                    setErrMsg("");
                    closeModal();
                }
            } else {
                let errMsg = t("errorMsg.fillInAllReqFields");
                setErrMsg(errMsg);
            }
        } catch (e: any) {
            systemErrorNotification();
        }
    };

    // useEffect(() => {
    //     form.setValue("from", item?.from?._id);
    // }, [item]);

    return (
        <div>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="emailTemplate-form-1">
                    {errMsg && <p style={{color: "red"}}>{errMsg}</p>}
                    <Row gutter={16}>
                        <Col className="gutter-row" xs={24} md={12}>
                            <InputFormItem
                                name={"displayName"}
                                label={"Name"}
                                placeholder={"Name"}
                                layout={verticalFormLayout}
                                disabled={mode === "view"}
                                required
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} md={12}>
                            <RadioGroupFormItem
                                name={"isActive"}
                                label={t("Status")}
                                options={[
                                    {label: t("Active"), value: true},
                                    {label: t("Inactive"), value: false},
                                ]}
                                layout={verticalFormLayout}
                                required
                                disabled={mode === "view" || item?.isNotImplemented}
                            />
                        </Col>
                        {/* <Col className="gutter-row" xs={24} md={24}>
                            <InputFormItem
                                name={"subject"}
                                label={"Subject"}
                                placeholder={"Subject"}
                                layout={verticalFormLayout}
                                disabled={mode === "view"}
                                required
                            />
                        </Col> */}
                        <Col className="gutter-row" xs={24} md={12}>
                            <RadioGroupFormItem
                                name={"isFixedEmail"}
                                label={t("Send by")}
                                options={[
                                    {label: t("User Domain"), value: false},
                                    {label: t("Fixed Email"), value: true},
                                ]}
                                value={isFixedEmail}
                                onChange={(e) => setIsFixedEmail(e)}
                                layout={verticalFormLayout}
                                required
                                disabled={mode === "view"}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} md={12}>
                            <SelectFormItem
                                name={"taggedToNewsletter"}
                                label={"Tag to Newsletter"}
                                placeholder={"Tag to Newsletter"}
                                layout={verticalFormLayout}
                                options={newsletterOptions}
                                disabled
                                optionFilterProp={"label"}
                                showSearch
                                allowClear
                            />
                        </Col>
                        {isFixedEmail ? (
                            <Col className="gutter-row" xs={24} md={12}>
                                <EmailProviderFormItem
                                    name={"from"}
                                    label={"Sender Email"}
                                    placeholder={"Sender Email"}
                                    layout={verticalFormLayout}
                                    disabled={mode === "view"}
                                    required
                                />
                            </Col>
                        ) : (
                            <>
                                <Col xs={12}>
                                    <SelectFormItem
                                        name={"emailType"}
                                        label={t("Email Type")}
                                        options={emailTypeOptions}
                                        mode={"single"}
                                        placeholder={t("Email Type")}
                                        disabled={mode === "view"}
                                        required
                                    />
                                </Col>
                            </>
                        )}
                    </Row>

                    {props.children}

                    <Form.Item className="text-align-right" style={{paddingTop: "12px"}}>
                        {onCancel && (
                            <Button onClick={onCancel} icon={<CloseOutlined />} className="button-cancel-1">
                                {mode === "view" ? t("Close") : t("common.cancel")}
                            </Button>
                        )}
                        {mode !== "view" && (
                            <Button onClick={form.handleSubmit(onSubmit)} icon={<SaveOutlined />} className="button-submit-1">
                                {t("common.submit")}
                            </Button>
                        )}
                    </Form.Item>
                </form>
            </FormProvider>
        </div>
    );
};

export default EmailTemplateForm;
