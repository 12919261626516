import React, {useEffect, useMemo, useState} from "react";
import {useRecoilValue} from "recoil";
import {Button, Col, Form, Modal, Row, Select, Space} from "antd";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import SelectFormItem from "../../../../../../components/form/selectFormItem";
import CheckboxFormItem from "../../../../../../components/form/checkboxFormItem";
// import InputSelectFormItem from "../../../../../../components/form/inputSelectFormItem";
import {getUserNetworkTreeDownline} from "../../../../../../api/graphql/userNetworkTree";
import {userOptionsState} from "../../../../../users/_common/state";
import useAuthorize from "../../../../../../_common/authorize";
import {formatUserOptions} from "../../../../../../function/_common";
import yupFormSchema from "../../../../../../components/yup/yupFormSchema";
import InputNumberFormItem from "../../../../../../components/form/inputNumberFormItem";
import {
    useAddMamPammProductMutation,
    useUpdateMamPammProductAndChangeSpreadGroupForExistingUserMutation,
    useUpdateMamPammProductMutation,
} from "../../../../../../api/graphql/mampammProduct";

const ProductForm = (props) => {
    const {formMode, item, close, server, spreadGroupList, productGroupList, spreadGroupMt5, mamPammProviderOptions} = props;
    const {t} = useTranslation();
    const [networkUserSelect, setNetworkUserSelect] = useState(item?.networkType || "all");
    const [currentSelectUsers, setCurrentSelectUsers] = useState<any[]>(item?.network?.map((d) => d?._id) || []);
    const {addMamPammProduct, loading: loadingAdd} = useAddMamPammProductMutation();
    const {updateMamPammProduct, loading: loadingUpdate} = useUpdateMamPammProductMutation();
    const {updateMamPammProductAndChangeSpreadGroupForExistingUser, loading: loadingUpdateAndChangeSpreadGroupForExistingUser} =
        useUpdateMamPammProductAndChangeSpreadGroupForExistingUserMutation();
    const {networkTreeDownlineData} = getUserNetworkTreeDownline(currentSelectUsers);
    const {systemErrorNotification} = useAuthorize();
    const [updateTradingAccountSpreadGroupModal, setUpdateTradingAccountSpreadGroupModal] = useState(false);
    const [targetSpreadGroup, setTargetSpreadGroup] = useState<any>(null);
    const [tradingAccount, setTradingAccount] = useState<any>([]);
    const [modeField, setModeField] = useState<any>(null);
    const [validProductGroup, setValidProductGroup] = useState<any>([]);

    const networkTreeDownLineOptions = networkTreeDownlineData?.getUserNetworkTreeDownline?.data?.map((d) => {
        return formatUserOptions(d?.userid);
    });
    const userOptions = useRecoilValue(userOptionsState);

    const labelCol = {span: 6, offset: 0};
    const wrapperCol = {span: 18, offsets: 0};

    const yupSchema =
        server?.platform === "mt5" || server?.platform === "xTrader"
            ? yup.object().shape({
                  name: yupFormSchema.string("Name", {required: true}),
                  code: yupFormSchema.string("Code", {required: true}),
                  productType: yupFormSchema.string("Product Type", {required: true}),
                  mode: yupFormSchema.string("Product Mode", {required: true}),
                  minDeposit: yupFormSchema.number("Min Deposit", {required: true}),
                  spreadGroupMt5: yupFormSchema.array("Spread Group Mt5", {required: true}),
                  leverage: yupFormSchema.array("Leverage", {required: true}),
                  networkType: yupFormSchema.string("Network Type", {required: true}),
                  network: yupFormSchema.array("Network"),
                  exclude: yupFormSchema.array("Exclude"),
                  showPublic: yupFormSchema.boolean("Show Public", {required: true}),
                  productGroup: yupFormSchema.string("Product Group"),
                  mamPammProvider: yupFormSchema.string("MAM/PAMM Provider", {required: true}),
                  // interestRate: yupFormSchema.string("Interest Rate"),
                  // interestRateType: yupFormSchema.string("Interest Rate Type"),
              })
            : yup.object().shape({
                  name: yupFormSchema.string("Name", {required: true}),
                  code: yupFormSchema.string("Code", {required: true}),
                  mode: yupFormSchema.string("Product Mode", {required: true}),
                  productType: yupFormSchema.string("Product Type", {required: true}),
                  minDeposit: yupFormSchema.number("Min Deposit", {required: true}),
                  spreadGroup: yupFormSchema.array("Spread Group", {required: true}),
                  leverage: yupFormSchema.array("Leverage", {required: true}),
                  networkType: yupFormSchema.string("Network Type", {required: true}),
                  network: yupFormSchema.array("Network"),
                  exclude: yupFormSchema.array("Exclude"),
                  showPublic: yupFormSchema.boolean("Show Public", {required: true}),
                  productGroup: yupFormSchema.string("Product Group"),
                  mamPammProvider: yupFormSchema.string("MAM/PAMM Provider", {required: true}),
                  // interestRate: yupFormSchema.number("Interest Rate"),
                  // interestRateType: yupFormSchema.string("Interest Rate Type"),
              });

    const [initialValues] = useState(() => ({
        name: item?.name || "",
        code: item?.code || "",
        productType: item?.productType || "",
        mode: item?.mode || "",
        minDeposit: item?.minDeposit || null,
        spreadGroup: item?.spreadGroup?.map((d) => d?._id) || undefined,
        spreadGroupMt5: item?.spreadGroupMt5?.map((d) => d?._id) || undefined,
        leverage: item?.leverage || undefined,
        networkType: item?.networkType || "all",
        network: item?.network?.map((d) => d?._id) || [],
        exclude: item?.exclude?.map((d) => d?._id) || [],
        showPublic: item?.showPublic || false,
        productGroup: item?.productGroup?._id || "",
        mamPammProvider: item?.mamPammProvider?._id || "",
        // interestRate: item?.interestRate || null,
        // interestRateType: item?.interestRateType || "Daily",
    }));

    const leverageOptions = [
        {value: "1:1", label: "1:1"},
        {value: "1:2", label: "1:2"},
        {value: "1:5", label: "1:5"},
        {value: "1:10", label: "1:10"},
        {value: "1:20", label: "1:20"},
        {value: "1:25", label: "1:25"},
        {value: "1:50", label: "1:50"},
        {value: "1:100", label: "1:100"},
        {value: "1:200", label: "1:200"},
        {value: "1:300", label: "1:300"},
        {value: "1:400", label: "1:400"},
        {value: "1:500", label: "1:500"},
        {value: "1:1000", label: "1:1000"},
        {value: "1:2000", label: "1:2000"},
    ];

    const liveProductTypeOptions = [
        {value: "moneyManagerLive", label: t("accountSetting.moneyManagerLive")},
        {value: "investorLive", label: t("accountSetting.investorLive")},
    ];

    const demoProductTypeOptions = [
        {value: "moneyManagerDemo", label: t("accountSetting.moneyManagerDemo")},
        {value: "investorDemo", label: t("accountSetting.investorDemo")},
    ];

    const spreadGroupOptions = spreadGroupList?.getSpreadGroup?.data?.map((d, i) => {
        return {
            value: d?.id || "",
            label: d?.name || "",
        };
    });

    const spreadGroupMt5Options = spreadGroupMt5?.map((d, i) => {
        return {
            value: d?._id || "",
            label: d?.group || "",
        };
    });

    const productGroupOptions = () => {
        if (modeField && productGroupList?.getProductGroup?.data?.__typename != "BaseError") {
            const filteredAndMapped = productGroupList?.getProductGroup?.data
                .filter((d) => modeField === d?.type)
                .map((d) => ({
                    value: d?.id || "",
                    label: d?.name || "",
                }));

            setValidProductGroup(filteredAndMapped);
        }
    };

    const serverModeOptions = server?.mode?.map((d, i) => {
        return {
            value: d || "",
            label: t(`common.${d}`) || "",
        };
    });

    const netWorkSelectOptions = [
        {value: "all", label: t("All Users")},
        {value: "partial", label: t("Some Users")},
        {value: "own", label: t("Only Me")},
    ];

    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
        defaultValues: initialValues,
    });

    const onSubmit = async (value) => {
        try {
            let temp: any = null;
            formMode === "Add"
                ? await addMamPammProduct({
                      name: value?.name,
                      code: value?.code,
                      productType: value?.productType,
                      minDeposit: value?.minDeposit?.toString(),
                      spreadGroup: value?.spreadGroup,
                      spreadGroupMt5: value?.spreadGroupMt5,
                      mode: modeField,
                      leverage: value?.leverage,
                      networkType: value?.networkType,
                      network: value?.network || [localStorage.getItem("userId")],
                      exclude: value?.exclude,
                      showPublic: value?.showPublic,
                      productGroup: value?.productGroup || null,
                      mamPammProvider: value?.mamPammProvider || null,
                      // interestRateType: value?.interestRateType,
                      // interestRate: parseFloat(value?.interestRate) || null,
                      server: server?.id,
                  })
                : (temp = await updateMamPammProduct(item?.id, {
                      name: value?.name,
                      code: value?.code,
                      productType: value?.productType,
                      minDeposit: value?.minDeposit?.toString(),
                      spreadGroup: value?.spreadGroup,
                      spreadGroupMt5: value?.spreadGroupMt5,
                      mode: modeField,
                      leverage: value?.leverage,
                      networkType: value?.networkType,
                      network: value?.network || [localStorage.getItem("userId")],
                      exclude: value?.exclude,
                      showPublic: value?.showPublic,
                      productGroup: value?.productGroup || null,
                      mamPammProvider: value?.mamPammProvider || null,
                      // interestRateType: value?.interestRateType,
                      // interestRate: parseFloat(value?.interestRate),
                      server: server?.id,
                  }));

            // if(formMode !== "Add"){
            //     if(temp && temp?.data?.updateProduct?.__typename === "BaseError" && temp?.data?.updateProduct?.errKey === "admin.product.err.product_missing_spreadgroup"){
            //         setTradingAccount(temp.data.updateProduct.errObj)
            //         setUpdateTradingAccountSpreadGroupModal(true)
            //     }
            // }
            // else{
            //     close();
            // }
            close();
        } catch (error: any) {
            console.log(error);
            systemErrorNotification();
        }
    };

    const onUpdateTradingAccountSubmit = async (value) => {
        try {
            let temp: any = null;
            await updateMamPammProductAndChangeSpreadGroupForExistingUser(
                item?.id,
                {
                    name: value?.name,
                    code: value?.code,
                    productType: value?.productType,
                    minDeposit: value?.minDeposit?.toString(),
                    spreadGroup: value?.spreadGroup,
                    spreadGroupMt5: value?.spreadGroupMt5,
                    mode: value?.mode,
                    leverage: value?.leverage,
                    networkType: value?.networkType,
                    network: value?.network || [localStorage.getItem("userId")],
                    exclude: value?.exclude,
                    showPublic: value?.showPublic,
                    productGroup: value?.productGroup || null,
                    // interestRateType: value?.interestRateType,
                    // interestRate: parseFloat(value?.interestRate),
                    server: server?.id,
                },
                targetSpreadGroup,
                tradingAccount.map((item) => item._id?.toString())
            );

            clearUpdateSpreadGroupForExistingUser();
            close();
        } catch (error: any) {
            console.log(error);
            systemErrorNotification();
        }
    };

    const clearUpdateSpreadGroupForExistingUser = () => {
        setUpdateTradingAccountSpreadGroupModal(false);
        setTradingAccount([]);
        setTargetSpreadGroup(null);
    };

    const getCurrentSpreadGroup = () => {
        let temp = form.getValues("spreadGroup");
        let tempSpreadGroupOptions = [];
        if (spreadGroupList?.getSpreadGroup?.data?._typename !== "BaseError") {
            tempSpreadGroupOptions = spreadGroupList?.getSpreadGroup?.data
                ?.filter((item) => temp?.includes(item.id.toString()))
                .map((d, i) => {
                    return {
                        value: d?.id || "",
                        label: d?.name || "",
                    };
                });
        }

        return tempSpreadGroupOptions;
    };

    useEffect(() => {
        if (formMode === "Add" && serverModeOptions?.length === 1) {
            setModeField(serverModeOptions[0]?.value);
        } else if (formMode !== "Add" && item?.mode) {
            setModeField(item.mode);
        }
    }, [formMode, item, serverModeOptions]);

    useEffect(() => {
        if (modeField != null) {
            productGroupOptions();
        }
    }, [modeField]);

    const productTypeOptions = useMemo(() => {
        let options: any = [];
        if (server?.mode.includes("live")) {
            if (!modeField || modeField === "live") {
                options = [...options, ...liveProductTypeOptions];
            }
        }
        if (server?.mode.includes("demo")) {
            if (!modeField || modeField === "demo") {
                options = [...options, ...demoProductTypeOptions];
            }
        }
        return options;
    }, [modeField]);

    return (
        <>
            <FormProvider {...form}>
                <Form onFinish={form.handleSubmit(onSubmit)} className="form-1">
                    <InputFormItem
                        name={"name"}
                        label={t("Product Name")}
                        placeholder={t("Product Name")}
                        required
                        disabled={formMode === "view"}
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}
                    />
                    <InputFormItem
                        name={"code"}
                        label={t("Product Code")}
                        placeholder={t("Product Code")}
                        required
                        disabled={formMode === "view"}
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}
                    />
                    <SelectFormItem
                        name={"mode"}
                        label={t("Mode")}
                        options={serverModeOptions}
                        optionFilterProp={"label"}
                        showSearch
                        required
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}
                        value={modeField}
                        onChange={(e) => {
                            setModeField(e);
                        }}
                    />
                    <SelectFormItem
                        name={"productType"}
                        label={t("Product Type")}
                        options={productTypeOptions}
                        optionFilterProp={"label"}
                        showSearch
                        required
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}
                        filterSort={(optionA, optionB) => optionA?.label?.toLowerCase().localeCompare(optionB?.label?.toLowerCase())}
                    />
                    <InputNumberFormItem
                        style={{width: "100%"}}
                        name={"minDeposit"}
                        label={t("Min Deposit")}
                        placeholder={t("Min Deposit")}
                        required
                        disabled={formMode === "view"}
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}
                    />
                    {server?.platform === "mt5" ? (
                        <SelectFormItem
                            name={"spreadGroupMt5"}
                            label={t("Spread Group Mt5")}
                            options={spreadGroupMt5Options}
                            optionFilterProp={"label"}
                            showSearch
                            required
                            mode={"multiple"}
                            labelCol={labelCol}
                            wrapperCol={wrapperCol}
                        />
                    ) : server?.platform === "xTrader" ? (
                        <SelectFormItem
                            name={"spreadGroupMt5"}
                            label={t("Spread Group ") + server?.platformDisplayName}
                            options={spreadGroupMt5Options}
                            optionFilterProp={"label"}
                            showSearch
                            required
                            mode={"multiple"}
                            labelCol={labelCol}
                            wrapperCol={wrapperCol}
                        />
                    ) : (
                        <SelectFormItem
                            name={"spreadGroup"}
                            label={t("Spread Group")}
                            options={spreadGroupOptions}
                            optionFilterProp={"label"}
                            showSearch
                            required
                            mode={"multiple"}
                            labelCol={labelCol}
                            wrapperCol={wrapperCol}
                        />
                    )}

                    <p
                        style={{
                            fontSize: 10,
                            marginBottom: 5,
                            color: "#555",
                            marginLeft: 190,
                            lineHeight: 0.8,
                        }}
                    >
                        *First one will be default
                    </p>
                    <SelectFormItem
                        name={"leverage"}
                        label={t("Leverage")}
                        options={leverageOptions}
                        optionFilterProp={"label"}
                        showSearch
                        required
                        mode={"multiple"}
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}
                    />
                    <p
                        style={{
                            fontSize: 10,
                            marginBottom: 5,
                            color: "#555",
                            marginLeft: 190,
                            lineHeight: 0.8,
                        }}
                    >
                        *First one will be default
                    </p>

                    <SelectFormItem
                        name={"networkType"}
                        label={t("Which Network can View")}
                        options={netWorkSelectOptions}
                        required
                        onChange={(value) => setNetworkUserSelect(value)}
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}
                    />

                    {(networkUserSelect === "partial" || networkUserSelect === "hide") && (
                        <>
                            <SelectFormItem
                                name={"network"}
                                label={t("Include")}
                                options={userOptions}
                                optionFilterProp={"label"}
                                showSearch
                                mode={"multiple"}
                                labelCol={labelCol}
                                wrapperCol={wrapperCol}
                                onChange={(val) => setCurrentSelectUsers(val)}
                                allowClear={true}
                                optionLabelProp="selectedLabel"
                            />
                            <SelectFormItem
                                name={"exclude"}
                                label={t("Exclude")}
                                options={networkTreeDownLineOptions}
                                optionFilterProp={"label"}
                                showSearch
                                mode={"multiple"}
                                labelCol={labelCol}
                                wrapperCol={wrapperCol}
                                allowClear={true}
                                optionLabelProp="selectedLabel"
                            />
                        </>
                    )}

                    <SelectFormItem
                        name={"productGroup"}
                        label={t("Product Group")}
                        options={validProductGroup}
                        optionFilterProp={"label"}
                        showSearch
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}
                        allowClear={true}
                    />

                    <SelectFormItem
                        name={"mamPammProvider"}
                        label={t("MAM/PAMM Provider")}
                        options={mamPammProviderOptions}
                        optionFilterProp={"label"}
                        showSearch
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}
                        allowClear={true}
                        required
                    />
                    {/* <InputSelectFormItem
                        nameInput={"interestRate"}
                        nameSelect={"interestRateType"}
                        label={t("Interest Rate % (Yearly)")}
                        placeholder={t("Interest Rate")}
                        firstWidth={150}
                        options={[
                            {key: "daily", value: "Daily"},
                            {key: "weekly", value: "Weekly"},
                            {key: "monthly", value: "Monthly"},
                        ]}
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}
                    /> */}
                    <CheckboxFormItem name={"showPublic"} label={t("Public")} labelCol={labelCol} />
                    <Form.Item className="text-align-right" style={{paddingTop: "12px"}}>
                        <Space>
                            <Button onClick={close}>{t("common.cancel")}</Button>
                            <Button onClick={form.handleSubmit(onSubmit)} loading={loadingAdd || loadingUpdate} type={"primary"}>
                                {t("common.submit")}
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </FormProvider>

            <Modal
                title={t(`Select New Spread Group for Affected Trading Account`)}
                open={updateTradingAccountSpreadGroupModal}
                onCancel={() => clearUpdateSpreadGroupForExistingUser()}
                footer={null}
                width={800}
                destroyOnClose
            >
                <h1>{t("Affected Trading Account")}</h1>
                {tradingAccount &&
                    tradingAccount.length > 0 &&
                    tradingAccount.map((item) => {
                        return (
                            <Row>
                                <Col xs={12}>{item.accountId}</Col>
                                <Col xs={6}>{item.product.name}</Col>
                                <Col xs={6}>{item.spreadGroup.name}</Col>
                            </Row>
                        );
                    })}
                <Row style={{marginTop: "12px"}}>
                    <Col xs={12}>
                        <Select
                            showSearch
                            placeholder={t("Select a Spread Group")}
                            optionFilterProp="label"
                            onChange={(e) => setTargetSpreadGroup(e)}
                            options={[getCurrentSpreadGroup()]}
                            style={{width: "200px"}}
                        />
                    </Col>
                    <Col xs={12} style={{textAlign: "right"}}>
                        <Button onClick={() => clearUpdateSpreadGroupForExistingUser()} style={{marginRight: "6px"}}>
                            {t("common.cancel")}
                        </Button>
                        <Button onClick={form.handleSubmit(onUpdateTradingAccountSubmit)} loading={loadingAdd || loadingUpdate} type={"primary"}>
                            {t("common.submit")}
                        </Button>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default ProductForm;
