import React, {useEffect, useState} from "react";
import {Form, Modal, Select} from "antd";
import {useTranslation} from "react-i18next";
import useAuthorize from "../../../../../../../_common/authorize";
import { restoreArchiveUserMutation } from "../../../../../../../api/graphql/user";

const labelCol = {span: 8, offset: 0}
const wrapperCol = {span: 16, offset: 0}

const RestoreArchivedModal = ({isOpen, setIsOpen, referrerOptions, data}) => {
    console.log(data)
    const {t} = useTranslation()
    const [form] = Form.useForm()

    const {restoreArchiveUser} = restoreArchiveUserMutation()
    const {systemErrorNotification} = useAuthorize();
    const [targetReferrer, setTargetReferrer] = useState(data?.referrer?._id ?? "")
    const [targetSalesRep, setTargetSalesRep] = useState(data?.salesRep?._id ?? "")

    const onRestoreArchivedUser = async () => {
        let response = await restoreArchiveUser(data?._id?.toString(), targetReferrer?.toString(), targetSalesRep?.toString())
        if (response?.data?.restoreArchiveUser?.__typename === "User") window.location.reload();
        setIsOpen(false)
    }

    useEffect(() => {
        form.resetFields()
        setTargetReferrer(data?.referrer?._id ?? "")
        setTargetSalesRep(data?.salesRep?._id ?? "")
    }, [isOpen])

    return (
        <Modal
            open={isOpen}
            onCancel={() => setIsOpen(false)}
            okText={t("common.confirm")}
            cancelText={t("common.cancel")}
            title={t("user.restoreArchivedUser")}
            onOk={() => form.submit()}
            destroyOnClose
        >
            <Form form={form} onFinish={onRestoreArchivedUser} labelWrap={true}>
                
            <Form.Item
                labelAlign={"left"}
                label={t("user.referrer")}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                required
            >
                <Select
                    value={targetReferrer}
                    placeholder={t("user.referrer")}
                    options={referrerOptions?.filter(d => d?.value !== data?._id)}
                    optionFilterProp={"label"}
                    optionLabelProp={"selectedLabel"}
                    showSearch
                    onChange={value => {
                        try {
                            setTargetReferrer(value?.toString())
                        } catch (e) {
                            console.log(e)
                            systemErrorNotification()
                        }
                    }}
                    style={{width: "100%"}}
                />
            </Form.Item>
            <Form.Item
                labelAlign={"left"}
                label={t("user.referrer")}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                required
            >
                <Select
                    value={targetSalesRep}
                    placeholder={t("user.salesRep")}
                    options={referrerOptions?.filter(d => d?.value !== data?._id)}
                    optionFilterProp={"label"}
                    optionLabelProp={"selectedLabel"}
                    showSearch
                    onChange={value => {
                        try {
                            setTargetSalesRep(value?.toString())
                        } catch (e) {
                            console.log(e)
                            systemErrorNotification()
                        }
                    }}
                    style={{width: "100%"}}
                />
            </Form.Item>
            </Form>
        </Modal>
    )
}

export default RestoreArchivedModal