import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useRecoilValue} from "recoil";
import {userAccountVerificationRecordsState} from "../../../../../../state";
import {useClientDetailFunction} from "../../../../../function";
import {Col, Row, Tag, Timeline} from "antd";
import {FieldTimeOutlined, LaptopOutlined} from "@ant-design/icons";
import TableAntDesign from "../../../../../../../../../components/table/tableAntDesign";
import {AccountVerificationColumns} from "../../../../../function/accountVerificationColumns";
import moment from "moment";
import FieldsModal from "./fieldsModal";
import AccountVerificationModal from "./accountVerificationModal";
import {formatDateTimeTimezone} from "../../../../../../../../../function/_common";

const AccountVerification = (props) => {
        const {user} = props
        const {t} = useTranslation()
        const userVerificationRecord = useRecoilValue<any>(userAccountVerificationRecordsState)
        const {getUserVerificationRecordFunction} = useClientDetailFunction()
        const [selectedRow, setSelectedRow] = useState<any>()
        const [selectedRecord, setSelectedRecord] = useState<any>()
        const [data, setData] = useState<any>([])
        const [isOpenFieldsModal, setIsOpenFieldModal] = useState<any>(false)
        const [selectedRecordValue, setSelectedRecordValue] = useState<any>()
        const column = AccountVerificationColumns()

        useEffect(() => {
            getUserVerificationRecordFunction({user: user?._id})
        }, [user?._id])

        useEffect(() => {
            setSelectedRow(userVerificationRecord?.[0])
            setSelectedRecord(userVerificationRecord?.[0]?.recordDocument)

            const formatted = userVerificationRecord?.map((item) => {

                return {
                    ...item,
                    clientStartTime: item?.clientStartTime ? formatDateTimeTimezone(item?.clientStartTime) : "",
                    clientEndTime: item?.clientEndTime ? formatDateTimeTimezone(item?.clientEndTime) : "",
                    rejectedDatetime: item?.task?.rejectedDatetime ? formatDateTimeTimezone(item?.task?.rejectedDatetime) : "",
                    approvedDatetime: item?.task?.approvedDatetime ? formatDateTimeTimezone(item?.task?.approvedDatetime) : "",
                    taskStatus: item?.task?.status,
                    actionBy: item?.task?.approvedBy?.username || item?.task?.rejectedBy?.username,
                    actionDateTime: (item?.task?.rejectedDatetime || item?.task?.approvedDatetime) ? formatDateTimeTimezone(item?.task?.rejectedDatetime || item?.task?.approvedDatetime) : "",
                    countryName: t(item?.countryName)
                }
            })

            setData(formatted)

        }, [userVerificationRecord])

        const onRow = (record) => {
            return {
                style: {
                    cursor: "pointer",
                    background: record?._id === selectedRow?._id ? "#eff2ff" : ""
                },
                onClick: () => {
                    setSelectedRow(record)
                    setSelectedRecord(record?.recordDocument);
                },
            };
        };


        const openFieldsModal = (item) => {
            setIsOpenFieldModal(true)
            setSelectedRecordValue(item)
        }

        const closeFieldsModal = () => {
            setIsOpenFieldModal(false)
            setSelectedRecordValue(null)
        }

        return (
            <>
                <Timeline style={{padding: 16, marginTop: 16}}>
                    {
                        selectedRecord?.filter(item => item?.fieldValue?.length > 0)?.map(item => {
                            // const duration = `${(item?.duration?.hours ?? 0).toString().padStart(2, '0')} : ${(item?.duration?.minutes ?? 0).toString().padStart(2, '0')} : ${(item?.duration?.seconds ?? 0).toString().padStart(2, '0')}`;
                            const {days, hours, minutes, seconds} = item?.duration
                            const duration = t("accountVerification.display_duration", {day: days, hour: hours, min: minutes, sec: seconds})

                            let tagColor, timelineDot
                            switch (item?.status) {
                                case "rejected" :
                                    tagColor = "red"
                                    timelineDot = "red"
                                    break
                                case "approved" :
                                    tagColor = "green"
                                    timelineDot = "green"
                                    break
                                case "expired" :
                                    tagColor = "red"
                                    timelineDot = "red"
                                    break
                                case "cancelled" :
                                    tagColor = "default"
                                    timelineDot = "grey"
                                    break
                                case "start":
                                    tagColor = "blue"
                                    timelineDot = "blue"
                                    break
                                case "verified":
                                    tagColor = "green"
                                    timelineDot = "green"
                                    break
                                case "verification failed":
                                    tagColor = "red"
                                    timelineDot = "red"
                                    break
                                default :
                                    tagColor = "yellow"
                                    timelineDot = "yellow"
                                    break
                            }

                            return (
                                <Timeline.Item
                                    color={timelineDot}
                                    key={item?._id}
                                    style={{paddingBottom: 36}}
                                >
                                    <Row gutter={[16, 16]} style={{width: "100%"}} align={"top"}>
                                        <Col xs={24} md={8}>
                                            <Tag color={tagColor} bordered={false}>{t(`accountVerification.status.${item?.status?.replaceAll(" ", "_")}`)}</Tag>
                                            <div><strong>{t(item?.document?.label)}</strong></div>
                                            <div><small>{`${t(item?.group?.label)}`}</small></div>
                                        </Col>
                                        <Col xs={24} md={10}>
                                            {
                                                item?.fieldValue?.length > 0 ?
                                                    <a onClick={() => openFieldsModal(item)}>{t("accountVerification.fields", {number: item?.fieldValue?.length})}</a>
                                                    :
                                                    <></>
                                            }
                                            <div>{t("accountVerification.startTime")}: {item?.clientStartTime ? formatDateTimeTimezone(item?.clientStartTime) : "N/A"}</div>
                                            <div>{t("accountVerification.endTime")}: {item?.clientEndTime ? formatDateTimeTimezone(item?.clientEndTime) : "N/A"}</div>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            {
                                                item?.device || item?.browser ?
                                                    <div><LaptopOutlined/> &nbsp;<>{`${item?.device} | ${item?.browser}`}</>
                                                    </div> : ""
                                            }
                                            <div><FieldTimeOutlined/> &nbsp;<>{duration}</>
                                            </div>
                                        </Col>
                                    </Row>
                                </Timeline.Item>
                            )
                        })
                    }
                </Timeline>
                <TableAntDesign
                    data={data}
                    tableName={"Account Verification Client"}
                    columns={column}
                    size={"small"}
                    loading={!userVerificationRecord}
                    isTableLocalSearch={true}
                    onRow={onRow}
                    pagination={{
                        size: "small",
                        pageSize: 5,
                        total: userVerificationRecord?.length,
                        showTotal: (total) => <p>Total {total} items</p>
                    }}
                />
                <FieldsModal isOpen={isOpenFieldsModal} close={closeFieldsModal} item={selectedRecordValue}/>
                <AccountVerificationModal user={user} selectedUserType={user?.isKycApproved ? "kyb" : "kyc"}/>
            </>
        )
    }
;

export default AccountVerification