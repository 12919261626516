import React from "react";
import {Button, Form, Input, InputNumber, Modal, Row, Space} from "antd";
import {useTranslation} from "react-i18next";

const SettingFormModal = (props) => {
    const {isOpen, setIsOpen, data, onSubmit} = props
    const {t} = useTranslation()

    const labelCol = {span: 8, offset: 0}

    return (
        data &&
        <Modal
            title={t("Edit Currency")}
            open={isOpen}
            onCancel={() => setIsOpen(false)}
            footer={null}
            width={500}
            destroyOnClose
        >
            <Form onFinish={onSubmit}>
                <Form.Item
                    name={"code"}
                    initialValue={data?.code}
                    style={{display: "none"}}
                />
                <Form.Item
                    labelCol={labelCol}
                    label={t("Currency").toString()}
                    name={"name"}
                    initialValue={data?.name}
                >
                    <Input disabled value={data?.name}/>
                </Form.Item>
                <Form.Item
                    labelCol={labelCol}
                    label={t("Digits").toString()}
                    name={"digits"}
                    initialValue={data?.digits}
                >
                    <InputNumber placeholder={t("Digits").toString()} style={{width: "100%"}}/>
                </Form.Item>

                <Form.Item>
                    <Row justify={"end"} className={"margin-top-0-75"}>
                        <Space>
                            <Button onClick={() => setIsOpen(false)}>{t("common.cancel")}</Button>
                            <Button type={"primary"} htmlType={"submit"}>{t("Confirm")}</Button>
                        </Space>
                    </Row>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default SettingFormModal