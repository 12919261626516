import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useRecoilState, useSetRecoilState} from "recoil";
import {FormProvider, useForm} from "react-hook-form";
import {Button, Col, Form, Row, Space} from "antd";
import {yupResolver} from "@hookform/resolvers/yup";
import moment from "moment";
import * as yup from "yup";
import {useAddTradingCreditTransactionMutation,} from "../../../../../../../api/graphql/tradingAccount";
import yupFormSchema from "../../../../../../../components/yup/yupFormSchema";
import SelectFormItem from "../../../../../../../components/form/selectFormItem";
import {horizontalFormLayout} from "../../../../../../../components/form/formWrapper";
import DatePickerFormItem from "../../../../../../../components/form/datePickerFormItem";
import {ColumnTradingCreditTransaction} from "../../../function/tableColumn";
import TextAreaInputFormItem from "../../../../../../../components/form/textAreaInputFormItem";
import {notificationMessage, selfPermissionObj,} from "../../../../../../../../recoil_state";
import EditTableUserPreferenceButton from "../../../../../../../components/table/function/editTableUserPreferenceButton";
import InputNumberFormItem from "../../../../../../../components/form/inputNumberFormItem";
import useAuthorize from "../../../../../../../_common/authorize";
import TableReportAntDesign from "../../../../../../../components/table/tableReportAntDesign";
import {useIsMobile} from "../../../../../../../_common/layout";
import AccountTransactionLocal from "./accountTransactionLocal";
import {tradingAccountCreditRefreshState} from "../../../state";

const AccountTransactionCredit = (props) => {
    const {
        setPageCredit,
        rowsCredit,
        setRowsCredit,
        setOrderByCredit,
        setFilterCredit,
        reportDataCredit,
        totalCountCredit,
        loadingCredit,
        refreshQuery,
        refreshQueryTransaction,
        data
    } = props

    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const tradingAccountCreditTxnPermission =
        selfPermission?.tradingAccount?.creditTransaction;
    const [btnLoading, setBtnLoading] = useState(false);
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const seTradingAccountCreditRefresh = useSetRecoilState(tradingAccountCreditRefreshState);
    const {systemErrorNotification} = useAuthorize()

    const tableName = "Credit Transaction Table"
    const columns = ColumnTradingCreditTransaction()

    const {addTradingCreditTransaction} =
        useAddTradingCreditTransactionMutation();

    const onChangePageHandler = (page, pageSize) => {
        setPageCredit(page);
        setRowsCredit(pageSize);
    }

    const yupSchema = yup.object().shape({
        type: yupFormSchema.string("type", {required: true}),
        amount: yupFormSchema.number("amount", {required: true}),
        comment: yupFormSchema.string("comment", {required: false}),
        expirationDatetime: yupFormSchema.string("expirationDatetime", {
            required: false,
        }),
        sendEmailNotification: yupFormSchema.boolean("sendEmailNotification", {
            required: false,
        }),
    });

    const isMobile = useIsMobile(768);
    const labelCol = {span: isMobile ? 24 : 7, offset: 0};

    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
    });

    const {t} = useTranslation();

    const refetchTradingAcc = () => {
        try {
            refreshQuery()
            refreshQueryTransaction()
        } catch (e) {
            systemErrorNotification()
        }
    }

    const onSubmit = async (value) => {
        try {
            await setBtnLoading(true)
            const response = await addTradingCreditTransaction({
                user: props?.data?.user?._id,
                account: props?.id,
                type: value?.type,
                amount: value?.amount.toString(),
                expirationDatetime: value?.expirationDatetime && moment(value?.expirationDatetime).format("YYYY-MM-DD"),
                comment: value?.comment,
                sendEmailNotification: false,
            });

            if (
                response?.data?.addTradingCreditTransaction.__typename ===
                "tradingCreditTransaction"
            ) {
                form.reset();
                if(data?.mode === "demo"){
                    seTradingAccountCreditRefresh(true)
                }
                refetchTradingAcc()
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Create Credit Transaction Successfully"),
                    key: "addTradingCreditTransaction",
                });
            } else if (
                response?.data?.addTradingCreditTransaction.__typename === "BaseError"
            ) {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.data?.addTradingCreditTransaction?.errKey),
                    key: "addTradingCreditTransaction",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.data?.addTradingCreditTransaction?.msg),
                    key: "addTradingCreditTransaction",
                });
            }
            setBtnLoading(false)
        } catch (error: any) {
            systemErrorNotification()
            setBtnLoading(false)
        }
    };

    const transactionTypeOptions = [
        {
            value: "in",
            label: t("tradingAccount.credit_in"),
        },
        {
            value: "out",
            label: t("tradingAccount.credit_out"),
        },
    ];

    const isAddable = tradingAccountCreditTxnPermission?.add?.add;


    return (
        <div id="AccountTransaction">
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="form-1">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12}>
                            <SelectFormItem
                                name={"type"}
                                label={t("tradingAccount.transaction_type") + ':'}
                                placeholder={t("tradingAccount.transaction_type")}
                                layout={horizontalFormLayout}
                                options={transactionTypeOptions}
                                mode={"single"}
                                required
                                labelCol={labelCol}
                                labelAlign={"left"}
                                value={"in"}
                                disabled={!isAddable}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            {
                               (form.getValues("type") === "in" || !form.getValues("type")) && (

                                    <DatePickerFormItem
                                        name={"expirationDatetime"}
                                        label={t("tradingAccount.expired_date")}
                                        placeholder={t("tradingAccount.expired_date")}
                                        layout={horizontalFormLayout}
                                        labelCol={labelCol}
                                        labelAlign={"left"}
                                        disabled={!isAddable}
                                        disabledDate={current => {
                                            return current && current < moment().startOf("day");
                                        }}
                                    />

                                )
                            }
                        </Col>
                        <Col xs={24} md={12}>
                            <InputNumberFormItem
                                name={"amount"}
                                label={t("tradingAccount.amount") + ':'}
                                placeholder={t("tradingAccount.amount")}
                                layout={horizontalFormLayout}
                                required
                                labelCol={labelCol}
                                labelAlign={"left"}
                                style={{width: "100%"}}
                                disabled={!isAddable}
                                min={0.01}
                                step={2}
                                // formatter={formatValue}
                                // parser={parseValue}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <TextAreaInputFormItem
                                name={"comment"}
                                label={t("tradingAccount.comment")}
                                placeholder={t("tradingAccount.comment")}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign={"left"}
                                disabled={!isAddable}
                                // maxLength={25}
                                showCount={true}
                            />
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                className="text-align-right"
                                style={{paddingTop: "12px"}}
                            >
                                <Button
                                    onClick={form.handleSubmit(onSubmit)}
                                    loading={btnLoading}
                                    className="button-submit-1"
                                    disabled={!isAddable || btnLoading}
                                    type={"primary"}
                                >
                                    {t("common.submit")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </form>
            </FormProvider>

            <Col span={24} style={{marginTop: "15px"}}>

                {
                    data?.mode === "demo" ? (
                        <AccountTransactionLocal data={data} type={"Credit Transaction"}/>
                    ): (
                       <>
                           <div className={"display-flex margin-bottom-0-75"}>
                               <Space className={"margin-left-auto"}>
                                   <EditTableUserPreferenceButton
                                       tableName={tableName}
                                       tableColumn={columns}
                                   />
                               </Space>
                           </div>
                           <TableReportAntDesign
                               tableName={tableName}
                               size="small"
                               columns={columns}
                               loading={loadingCredit}
                               data={reportDataCredit}
                               filter={setFilterCredit}
                               order={setOrderByCredit}
                               rowKey={(record, index) => index}
                               scroll={{
                                   scrollToFirstRowOnChange: true,
                                   x: true,
                               }}
                               pagination={{
                                   pageSize: rowsCredit,
                                   showSizeChanger: true,
                                   onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                                   total: totalCountCredit,
                                   showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
                               }}
                           />
                       </>
                    )
                }

            </Col>
        </div>
    );

};

export default AccountTransactionCredit;
