import React from "react";
import "./view/css/style.css"
import Announcement_1 from "./view/layout1";

const Announcement = (props) => {
    const {isHeader} = props
    let layout = 1;
    let component;

    switch (layout) {
        case 1:
            component = <Announcement_1 isHeader={isHeader}/>
            break;

        default:
            component = <Announcement_1 isHeader={isHeader}/>
            break;
    }

    return component;
}

export default Announcement