import React from "react";
import {BsCheck, BsX} from "react-icons/bs";
import {getColumnSearchProps, getBooleanFilterProps} from "../../../../components/table/function/filterTable";
import {formatDate} from "../../../users/_common/function";

export const pspSettlementTableColumns = [
    {
        title: "Settlement Date",
        dataIndex: "settlementDate",
        key: "settlementDate",
        render: (text) => <p>{formatDate(text) || "-"}</p>,
    },
    {
        title: "Transaction No",
        dataIndex: "transactionNo",
        key: "transactionNo",
        ...getColumnSearchProps(),
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "PSP Name",
        dataIndex: "pspSettingName",
        key: "pspSettingName",
        ...getColumnSearchProps(),
        render: (_, record) => <p>{record?.pspSettingId?.name || "-"}</p>,
    },
    {
        title: "PSP Display Name",
        dataIndex: "pspSettingDisplayName",
        key: "pspSettingDisplayName",
        ...getColumnSearchProps(),
        render: (_, record) => <p>{record?.pspSettingId?.displayName || "-"}</p>,
    },
    {
        title: "Currency",
        dataIndex: "pspSettingCurrencyName",
        key: "pspSettingCurrencyName",
        ...getColumnSearchProps(),
        render: (_, record) => <p>{record?.pspSettingCurrencyId?.currency || "-"}</p>,
    },
    {
        title: "Amount",
        dataIndex: "convertedAmount",
        key: "convertedAmount",
        align: "right",
        ...getColumnSearchProps(),
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Amount (USD)",
        dataIndex: "amount",
        key: "amount",
        align: "right",
        ...getColumnSearchProps(),
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Fee",
        dataIndex: "convertedFee",
        key: "convertedFee",
        align: "right",
        ...getColumnSearchProps(),
        render: (_, record) => <p>{record?.adjustedConvertedFee || record?.convertedFee || "-"}</p>,
    },
    {
        title: "Fee (USD)",
        dataIndex: "fee",
        key: "fee",
        align: "right",
        ...getColumnSearchProps(),
        render: (_, record) => <p>{record?.adjustedFee || record?.fee || "-"}</p>,
    },
    {
        title: "Exclude Fee",
        dataIndex: "excludeFee",
        key: "excludeFee",
        ...getBooleanFilterProps(),
        render: (text) => {
            return text ? <BsCheck style={{color: "green"}} /> : <BsX style={{color: "red"}} />;
        },
    },
    {
        title: "Desk",
        dataIndex: "deskName",
        key: "deskName",
        ...getColumnSearchProps(),
        render: (_, record) => (
            <div>
                {record?.desks?.map((desk, index) => (
                    <p key={index}>
                        {desk?.desk?.name} : {desk?.percentage} % : {desk?.value}
                    </p>
                ))}
            </div>
        ),
    },
    {
        title: "Remark",
        dataIndex: "remark",
        key: "remark",
        ...getColumnSearchProps(),
        render: (text) => <p>{text || "-"}</p>,
    },
];
