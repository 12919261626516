import React, {useEffect} from "react";
import {Form, Input, Modal} from "antd";
import {useTranslation} from "react-i18next";

const InstrumentDetailModal_1 = (props) => {
    const {
        item,
        isOpen,
        closeModal,
    } = props
    const { t } = useTranslation()
    const [form] = Form.useForm()

    useEffect(() => {
        form.setFieldsValue({
            baseSymbolId: item?.base_symbol_id,
            country: item?.country,
            instrumentName: item?.instrument_name,
            stockExchangeAcronym: item?.stock_exchange_acronym,
            tickerSymbol: item?.ticker_symbol
        })
    },[item])

    return (
        <Modal
            title={t("Instrument Details")}
            open={isOpen}
            onCancel={() => {
                closeModal()
            }}
            footer={null}
            width={500}
            destroyOnClose
        >
            <Form form={form}>
                <Form.Item
                    name="baseSymbolId"
                    label={t("Base Symbol ID")}
                    labelCol={{span: 10, offset: 0}}
                >
                    <Input disabled/>
                </Form.Item>
                <Form.Item
                    name="country"
                    label={t("Country")}
                    labelCol={{span: 10, offset: 0}}
                >
                    <Input disabled/>
                </Form.Item>
                <Form.Item
                    name="instrumentName"
                    label={t("InstrumentName")}
                    labelCol={{span: 10, offset: 0}}
                >
                    <Input disabled/>
                </Form.Item>
                <Form.Item
                    name="stockExchangeAcronym"
                    label={t("Stock Exchange Acronym")}
                    labelCol={{span: 10, offset: 0}}
                >
                    <Input disabled/>
                </Form.Item>
                <Form.Item
                    name="tickerSymbol"
                    label={t("Ticker Symbol")}
                    labelCol={{span: 10, offset: 0}}
                >
                    <Input disabled/>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default InstrumentDetailModal_1;
