import React, {useEffect, useState} from "react";
import grapesjs from "grapesjs";
import newsletterPlugin from "grapesjs-preset-newsletter";
import "grapesjs/dist/css/grapes.min.css";
import {getTextFromObject} from "../../../function/common";

function TemplatePreviewGrapeJS(props) {
    const {data, languageCode, activeTab} = props;
    const [announcement, setAnnouncement] = useState("");
    const [editor, setEditor] = useState<Record<string, any>>();

    useEffect(() => {
        if (data?.dataArrayOfObject) {
            let htmldata = getTextFromObject(data?.dataArrayOfObject, "content", languageCode);
            setAnnouncement(htmldata);
        }
    }, [languageCode, data]);

    useEffect(() => {
        try {
            // if (announcement && !editor) {
            // if (announcement) {
            const editor = grapesjs.init({
                container: `#gjs-${languageCode}`,
                fromElement: true,
                height: "100%",
                storageManager: false,
                forceClass: false,
                //   headless: true,
                // showToolbar: false,
                plugins: [newsletterPlugin],
                pluginsOpts: {
                    [newsletterPlugin.toString()]: {
                        modalLabelImport: "Paste all your code here below and click import",
                        modalLabelExport: "Copy the code and use it wherever you want",
                        importPlaceholder: '<table class="table"><tr><td class="cell">Hello world!</td></tr></table>',
                        cellStyle: {
                            "font-size": "12px",
                            "font-weight": 300,
                            "vertical-align": "top",
                            color: "rgb(111, 119, 125)",
                            margin: 0,
                            padding: 0,
                        },
                    },
                },
                projectData: {
                    pages: [
                        {
                            component: announcement,
                        },
                    ],
                },
            });
            setEditor(editor);
            editor?.runCommand("preview");
        } catch (error) {
            console.error(error);
        }
        return () => {
        };
    }, [announcement]);

    let previewIframe: any = document.getElementsByClassName(`gjs-frame`);

    useEffect(() => {
        setTimeout(() => {
            if (Array.from(previewIframe)?.length > 0) {
                Array.from(previewIframe).forEach(function (iframe: any) {
                    let doc = iframe?.contentDocument;
                    if (doc) {
                        doc.body.style.pointerEvents = "none";
                    }
                });
            }
        }, 200);
    }, [activeTab, previewIframe]);

    return (
        <div id={`gjs-${languageCode}`} className="announcementPreview"/>
    );
}

export default TemplatePreviewGrapeJS;
