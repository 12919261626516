import React, {useEffect, useState} from "react";
import {useRecoilState} from "recoil";
import PushNotificationServerSettings_1 from "./view/layout1";
import {columns} from "./function/tableColumn";
import {selfPermissionObj} from "../../../../recoil_state";
import {getPushNotificationServerSetting} from "../../../api/graphql/pushNotificationServerSetting";

function PushNotificationServerSettings() {
    let layout = 1;
    let component;
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const pushNotificationServerSettingPermission = selfPermission?.brandSetting?.pushNotificationServerSetting;
    const [orderBy, setOrderBy] = useState(null);
    const [loading, setLoading] = useState<boolean>(false)
    const [total, setTotal] = useState<number>(0)
    const [tableData, setTableData] = useState<any>([]);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [filter, setFilter] = useState<any>({});
    const [error, setError] = useState<any>(null);

    const getTableData = async () => {
        try {
            setLoading(true);
            const response = await getPushNotificationServerSetting({filter: {}}, limit, offset, orderBy);
            setTableData(response?.getPushNotificationServerSetting?.data?.filter(item => !item?.isDeleted));
            setTotal(response?.getPushNotificationServerSetting?.total);
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getTableData();
    }, [filter, orderBy, limit, offset]);

    switch (layout) {
        case 1:
            component = (
                <PushNotificationServerSettings_1
                    data={tableData}
                    columns={columns()}
                    loading={loading}
                    error={error}
                    pushNotificationServerSettingPermission={pushNotificationServerSettingPermission}
                    setOrderBy={setOrderBy}
                    orderBy={orderBy}
                    getTableData={getTableData}
                />
            );
            break;

        default:
            component = (
                <PushNotificationServerSettings_1
                    data={tableData}
                    columns={columns()}
                    loading={loading}
                    error={error}
                    pushNotificationServerSettingPermission={pushNotificationServerSettingPermission}
                    getTableData={getTableData}
                />
            );
            break;
    }

    return component;
}

export default PushNotificationServerSettings;
