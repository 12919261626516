import {Card, Row, Space} from "antd";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import EditTableUserPreferenceButton from "../../../../../../components/table/function/editTableUserPreferenceButton";
import {columnsMoneyManager} from "../../../function/columnsMoneyManager";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import {AiOutlineEdit} from "react-icons/ai";
import MoneyManagerAccountSettingModal from "./moneyManagerAccountSettingModal";
// import ExportTableButton from "../../../../../../components/table/function/exportTable";

const MoneyManagerTable = (props) => {
    const {
        data,
        loading,
        setFilter,
        setOrderBy,
        permission,
        limit,
        totalCount,
        setLimit,
        setOffset,
        selectedMoneyManager,
        setSelectedMoneyManager,
        getMoneyManagerListing,
    } = props;
    const {t} = useTranslation();
    const tableName = "Money Manager";

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize);
        setOffset(limit * (page - 1));
    };

    const toggleMoneyManagerModal = (record) => {
        setSelectedRecord(record);
        setIsModalOpen((prevState) => !prevState);
    };

    const onRow = (record, rowIndex) => {
        return {
            onClick: () => {
                setSelectedMoneyManager(record?._id);
            },
        };
    };

    const rowClassName = (record, index) => {
        if (record?._id === selectedMoneyManager) {
            return "rowHighlighted clickable";
        }
        return "clickable";
    };

    return (
        <Card>
            {isModalOpen && (
                <MoneyManagerAccountSettingModal
                    isOpen={isModalOpen}
                    onCancel={() => toggleMoneyManagerModal("")}
                    selectedRecord={selectedRecord}
                    getMoneyManagerListing={getMoneyManagerListing}
                />
            )}
            <Row justify={"space-between"} className={"margin-bottom-0-75"}>
                <h1>{t(tableName)}</h1>
                <Space>
                    <EditTableUserPreferenceButton tableName={tableName} tableColumn={columnsMoneyManager} />
                    {/* <ExportTableButton tableData={exportData} tableColumn={columns} tableName={tableName} /> */}
                </Space>
            </Row>
            <TableAntDesign
                data={data}
                tableName={tableName}
                columns={[
                    {
                        title: t("common.action"),
                        key: "edit",
                        align: "center",
                        render: (_, record: any) => (
                            <a style={{color: "green"}} onClick={() => toggleMoneyManagerModal(record)}>
                                <AiOutlineEdit />
                            </a>
                        ),
                    },
                    ...columnsMoneyManager,
                ]}
                size={"small"}
                loading={loading}
                filter={setFilter}
                order={setOrderBy}
                onRow={onRow}
                rowClassName={rowClassName}
                pagination={{
                    pageSize: limit,
                    showSizeChanger: true,
                    position: "bottomCenter",
                    total: totalCount,
                    onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                    showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
                }}
            />
        </Card>
    );
};

export default MoneyManagerTable;
