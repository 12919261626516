import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";

const GET_USER_VERIFICATION_CONFIG_LIST = gql`
  query getUserVerificationConfigList{
    getUserVerificationConfigList{
      ... on UserVerificationConfigList {
        clientType
        tag
        status
        fieldType
        fieldGroupType
        textLinkField
        dateLinkField
        fileLinkField
        mobileLinkField
        kycVerificationType
        kybVerificationType
        requiredType
        countryList
        nationalityList
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getUserVerificationConfigList = async () => {
    try {
        const execute = await ApolloClientQuery(
            GET_USER_VERIFICATION_CONFIG_LIST,
            {
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};


const GET_USER_VERIFICATION_COUNTRY = gql`
  query getUserVerificationCountry{
    getUserVerificationCountry{
      ... on UserVerificationCountryArray {
        data {
          _id
          country
          isDeleted
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getUserVerificationCountry = async () => {
    try {
        const execute = await ApolloClientQuery(
            GET_USER_VERIFICATION_COUNTRY,
            {
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const CREATE_USER_VERIFICATION_COUNTRY = gql`
 mutation createUserVerificationCountry($country: String!) {
   createUserVerificationCountry(country: $country) {
     ... on UserVerificationCountry {
         _id
         country
         isDeleted
     }
     ... on BaseError {
         errKey
         errMsg
     }
     ... on CrmTaskApproval {
        msg
     }
   }
 }

`;

const createUserVerificationCountry = async (country) => {

    const variables = {
        country: country,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(CREATE_USER_VERIFICATION_COUNTRY, variables, true);

    return execute;
}

const UPDATE_USER_VERIFICATION_COUNTRY = gql`
 mutation updateUserVerificationCountry($id: String!, $isEnabled: Boolean) {
   updateUserVerificationCountry(id: $id, isEnabled: $isEnabled) {
     ... on UserVerificationCountry {
         _id
         country
         isDeleted
     }
     ... on BaseError {
         errKey
         errMsg
     }
     ... on CrmTaskApproval {
        msg
     }
   }
 }

`;

const updateUserVerificationCountry = async (id, isEnabled) => {

    const variables = {
        id: id,
        isEnabled: isEnabled,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(UPDATE_USER_VERIFICATION_COUNTRY, variables, true);

    return execute;
}

const DELETE_USER_VERIFICATION_COUNTRY = gql`
 mutation deleteUserVerificationCountry($id: String!) {
   deleteUserVerificationCountry(id: $id) {
     ... on UserVerificationCountry {
         _id
         country
         isDeleted
     }
     ... on BaseError {
         errKey
         errMsg
     }
     ... on CrmTaskApproval {
        msg
     }
   }
 }

`;

const deleteUserVerificationCountry = async (id) => {
    const variables = {
        id: id,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(DELETE_USER_VERIFICATION_COUNTRY, variables, true);

    return execute;
}

const GET_USER_VERIFICATION_FIELD = gql`
  query getUserVerificationField($filter: UserVerificationFieldFilter, $excludeGroup: Boolean){
    getUserVerificationField(filter: $filter, excludeGroup: $excludeGroup){
      ... on UserVerificationFieldArray {
        data {
          _id
          name
          label
          tag
          fieldType
          fieldGroupType
          fieldValue {label value}
          isDeleted
          fieldGroup {
            _id
            name
            label
            tag
            fieldType
            fieldGroupType
            fieldValue {label value}
            isDeleted
          }
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getUserVerificationField = async (filter, excludeGroup) => {
    try {
        const execute = await ApolloClientQuery(
            GET_USER_VERIFICATION_FIELD,
            {
                filter: filter,
                excludeGroup: excludeGroup,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const CREATE_USER_VERIFICATION_FIELD = gql`
 mutation createUserVerificationField($input: UserVerificationFieldInput!) {
   createUserVerificationField(input: $input) {
     ... on UserVerificationField {
        _id
        name
        label
        tag
        fieldType
        fieldGroupType
        fieldValue {label value}
        isDeleted
        fieldGroup {
          _id
          name
          label
          tag
          fieldType
          fieldGroupType
          fieldValue {label value}
          isDeleted
        }
     }
     ... on BaseError {
         errKey
         errMsg
     }
     ... on CrmTaskApproval {
        msg
     }
   }
 }

`;

const createUserVerificationField = async (input) => {

    const variables = {
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(CREATE_USER_VERIFICATION_FIELD, variables, true);

    return execute;
}

const UPDATE_USER_VERIFICATION_FIELD = gql`
 mutation updateUserVerificationField($id: String!, $input: UserVerificationFieldInput) {
   updateUserVerificationField(id: $id, input: $input) {
     ... on UserVerificationField {
        _id
        name
        label
        tag
        fieldType
        fieldGroupType
        fieldValue {label value}
        isDeleted
        fieldGroup {
          _id
          name
          label
          tag
          fieldType
          fieldGroupType
          fieldValue {label value}
          isDeleted
        }
     }
     ... on BaseError {
         errKey
         errMsg
     }
     ... on CrmTaskApproval {
        msg
     }
   }
 }

`;

const updateUserVerificationField = async (id, input) => {
    const variables = {
        id: id,
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(UPDATE_USER_VERIFICATION_FIELD, variables, true);

    return execute;
}

const DELETE_USER_VERIFICATION_FIELD = gql`
 mutation deleteUserVerificationField($id: String!) {
   deleteUserVerificationField(id: $id) {
     ... on UserVerificationField {
        _id
        name
        label
        tag
        fieldType
        fieldGroupType
        fieldValue {label value}
        isDeleted
        fieldGroup {
          _id
          name
          label
          tag
          fieldType
          fieldGroupType
          fieldValue {label value}
          isDeleted
        }
     }
     ... on BaseError {
         errKey
         errMsg
     }
     ... on CrmTaskApproval {
        msg
     }
   }
 }

`;

const deleteUserVerificationField = async (id) => {
    const variables = {
        id: id,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(DELETE_USER_VERIFICATION_FIELD, variables, true);

    return execute;
}

const GET_USER_VERIFICATION_DOCUMENT = gql`
  query getUserVerificationDocument {
    getUserVerificationDocument {
      ... on UserVerificationDocumentArray {
        data {
          _id
          name
          label
          isDeleted
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
     }
    }
  }
`;

const getUserVerificationDocument = async () => {
    try {
        const execute = await ApolloClientQuery(
            GET_USER_VERIFICATION_DOCUMENT,
            {
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const CREATE_USER_VERIFICATION_DOCUMENT = gql`
 mutation createUserVerificationDocument($input: UserVerificationDocumentInput!) {
   createUserVerificationDocument(input: $input) {
     ... on UserVerificationDocument {
        _id
        name
        label
        isDeleted
     }
     ... on BaseError {
         errKey
         errMsg
     }
     ... on CrmTaskApproval {
        msg
     }
   }
 }

`;

const createUserVerificationDocument = async (input) => {
    const variables = {
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(CREATE_USER_VERIFICATION_DOCUMENT, variables, true);

    return execute;
}

const UPDATE_USER_VERIFICATION_DOCUMENT = gql`
 mutation updateUserVerificationDocument($id: String!, $input: UserVerificationDocumentInput!) {
   updateUserVerificationDocument(id: $id, input: $input) {
     ... on UserVerificationDocument {
        _id
        name
        label
        isDeleted
     }
     ... on BaseError {
         errKey
         errMsg
     }
     ... on CrmTaskApproval {
        msg
     }
   }
 }

`;

const updateUserVerificationDocument = async (id, input) => {
    const variables = {
        id: id,
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(UPDATE_USER_VERIFICATION_DOCUMENT, variables, true);

    return execute;
}


const DELETE_USER_VERIFICATION_DOCUMENT = gql`
 mutation deleteUserVerificationDocument($id: String!) {
   deleteUserVerificationDocument(id: $id) {
     ... on UserVerificationDocument {
        _id
        name
        label
        isDeleted
     }
     ... on BaseError {
         errKey
         errMsg
     }
     ... on CrmTaskApproval {
        msg
     }
   }
 }

`;

const deleteUserVerificationDocument = async (id) => {
    const variables = {
        id: id,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(DELETE_USER_VERIFICATION_DOCUMENT, variables, true);

    return execute;
}

const GET_USER_VERIFICATION_DOCUMENT_GROUP = gql`
  query getUserVerificationDocumentGroup {
    getUserVerificationDocumentGroup {
      ... on UserVerificationDocumentGroupArray {
        data {
          _id
          name
          label
          isDeleted
          displayName
          displayNameLabel
          documents {
          _id
          name
          label
          isDeleted
          }
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getUserVerificationDocumentGroup = async () => {
    try {
        const execute = await ApolloClientQuery(
            GET_USER_VERIFICATION_DOCUMENT_GROUP,
            {
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const CREATE_USER_VERIFICATION_DOCUMENT_GROUP = gql`
 mutation createUserVerificationDocumentGroup($input: UserVerificationDocumentGroupInput!) {
   createUserVerificationDocumentGroup(input: $input) {
     ... on UserVerificationDocumentGroup {
        _id
        name
        label
        isDeleted
        displayName
        displayNameLabel
        documents {
        _id
        name
        label
        isDeleted
        }
     }
     ... on BaseError {
         errKey
         errMsg
     }
     ... on CrmTaskApproval {
        msg
     }
   }
 }

`;

const createUserVerificationDocumentGroup = async (input) => {
    const variables = {
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(CREATE_USER_VERIFICATION_DOCUMENT_GROUP, variables, true);

    return execute;
}

const UPDATE_USER_VERIFICATION_DOCUMENT_GROUP = gql`
 mutation updateUserVerificationDocumentGroup($id: String!, $input: UserVerificationDocumentGroupInput!) {
   updateUserVerificationDocumentGroup(id: $id, input: $input) {
     ... on UserVerificationDocumentGroup {
        _id
        name
        label
        isDeleted
        displayName
        displayNameLabel
        documents {
        _id
        name
        label
        isDeleted
        }
     }
     ... on BaseError {
         errKey
         errMsg
     }
     ... on CrmTaskApproval {
        msg
     }
   }
 }

`;

const updateUserVerificationDocumentGroup = async (id, input) => {
    const variables = {
        id: id,
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(UPDATE_USER_VERIFICATION_DOCUMENT_GROUP, variables, true);

    return execute;
}

const DELETE_USER_VERIFICATION_DOCUMENT_GROUP = gql`
 mutation deleteUserVerificationDocumentGroup($id: String!) {
   deleteUserVerificationDocumentGroup(id: $id) {
     ... on UserVerificationDocumentGroup {
        _id
        name
        label
        isDeleted
        displayName
        displayNameLabel
        documents {
        _id
        name
        label
        isDeleted
        }
     }
     ... on BaseError {
         errKey
         errMsg
     }
     ... on CrmTaskApproval {
        msg
     }
   }
 }

`;

const deleteUserVerificationDocumentGroup = async (id) => {
    const variables = {
        id: id,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(DELETE_USER_VERIFICATION_DOCUMENT_GROUP, variables, true);

    return execute;
}


export {
    getUserVerificationConfigList,
    getUserVerificationCountry,
    createUserVerificationCountry,
    updateUserVerificationCountry,
    deleteUserVerificationCountry,
    getUserVerificationField,
    createUserVerificationField,
    updateUserVerificationField,
    deleteUserVerificationField,
    getUserVerificationDocument,
    createUserVerificationDocument,
    updateUserVerificationDocument,
    deleteUserVerificationDocument,
    getUserVerificationDocumentGroup,
    createUserVerificationDocumentGroup,
    updateUserVerificationDocumentGroup,
    deleteUserVerificationDocumentGroup
}