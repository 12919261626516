import React from "react";
import {Col, Divider, Form, Input, Row, Select} from "antd";
import {useTranslation} from "react-i18next";
import {renderProfileInformation} from "../../../../../../_common/function";
import {useRecoilValue} from "recoil";
import {fieldListState} from "../../../../../../../../../recoil_state";

const BasicInformation = (props) => {
    const {data, editable, basicField, loginField, additionalField, isReadSensitive, form} = props
    const {t} = useTranslation()
    const fieldList = useRecoilValue<any>(fieldListState)

    return (
        data &&
        <Row gutter={[16, 16]} className={"margin-top-0-75 margin-bottom-0-75"}>
            {basicField?.length > 0 && (
                <Col span={24}>
                    <h3>{t("field.category.basicInformation")}</h3>
                    <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                        {renderProfileInformation(basicField, data, t, !editable, isReadSensitive, null, true)}
                        <Col xs={24} md={12}>
                            <Form.Item
                                name={"comment"}
                                label={t("user.comment")}
                                labelCol={{span: 10, offset: 0}}
                                initialValue={data?.comment}
                                labelAlign={"left"}
                            >
                                <Select
                                    showSearch
                                    disabled={!editable}
                                    allowClear
                                    options={fieldList?.find(d => d?.fieldName === "leadsComment")?.optionValue?.map(d => ({...d, label: t(d?.label)}))}
                                    // options={[
                                    //     {value: "deposited", label: t("comment.deposited")},
                                    //     {value: "followingUp", label: t("comment.followingUp")},
                                    //     {value: "noAnswer", label: t("comment.noAnswer")},
                                    //     {value: "garbage/invalidNo", label: t("comment.garbage_invalidNo")},
                                    //     {value: "blocked/giveUp", label: t("comment.blocked_giveUp")},
                                    //     {value: "assistanceNeeded", label: t("comment.assistanceNeeded")},
                                    //     {value: 'wrongPerson', label: t('comment.wrongPerson')},
                                    // ]}
                                    // dropdownRender={(menu) => {
                                    //     return (
                                    //         <>
                                    //             {menu}
                                    //             <Input placeholder={t("comment.others").toString()}
                                    //                    defaultValue={!commentValue?.includes(data?.comment) ? data?.comment : ""}
                                    //                    onChange={(e) => form.setFieldsValue({comment: e.target?.value})}
                                    //                    onKeyDown={(e) => e.stopPropagation()}
                                    //             />
                                    //         </>
                                    //     )
                                    // }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            )}
            {loginField?.length > 0 && (
                <>
                    <Divider/>
                    <Col span={24}>
                        <h3>{t("field.category.loginInformation")}</h3>
                        <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                            {renderProfileInformation(loginField, data, t, !editable, isReadSensitive)}
                        </Row>
                    </Col>
                </>
            )}
            {additionalField?.length > 0 && (
                <>
                    <Divider/>
                    <Col span={24}>
                        <h3>{t("field.category.additionalInformation")}</h3>
                        <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                            {renderProfileInformation(additionalField, data, t, !editable, isReadSensitive)}
                        </Row>
                    </Col>
                </>
            )}
        </Row>
    )
}

export default BasicInformation