import {gql, useMutation, useQuery} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";
import {GET_USER_BY_ID} from "./user";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { notificationMessage } from "../../../recoil_state";

const GET_USER_VERIFICATION_AVAILABLE_FIELD_SETTING = gql`
  query getUserVerificationAvailableFieldSetting($filter: UserVerificationAvailableFieldFilter){
    getUserVerificationAvailableFieldSetting(filter: $filter){
      ... on UserVerificationAvailableField {
        data
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getUserVerificationAvailableFieldSetting = async (filter = {country: null}) => {
    try {
        const execute = await ApolloClientQuery(
            GET_USER_VERIFICATION_AVAILABLE_FIELD_SETTING,
            {
                filter: filter,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_USER_VERIFICATION_STEP = gql`
  query getUserVerificationSteps($filter: UserVerificationStepsFilter){
    getUserVerificationSteps(filter: $filter){
      ... on UserVerificationStepsArray {
        data {
          _id
          country {_id}
          verificationType
          clientType
          label
          description
          requiredType
          numberOfRequired
          partiallyRequiredGroup {
              _id
              group {_id label}
          }
          sortingNumber
          isEnabled
          isDeleted
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getUserVerificationSteps = async (filter = {country: null}) => {
    try {
        const execute = await ApolloClientQuery(
            GET_USER_VERIFICATION_STEP,
            {
                filter: filter,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const CREATE_USER_VERIFICATION_STEPS = gql`
 mutation createUserVerificationSteps($input: UserVerificationStepsCreateInput!) {
   createUserVerificationSteps(input: $input) {
     ... on UserVerificationSteps {
         _id
        country {_id}
        verificationType
        clientType
        label
        description
        requiredType
        numberOfRequired
        partiallyRequiredGroup {
            _id
            group {_id label}
        }
        sortingNumber
        isEnabled
        isDeleted
     }
     ... on BaseError {
         errKey
         errMsg
     }
     ... on CrmTaskApproval {
        msg
     }
   }
 }

`;

const createUserVerificationSteps = async (input) => {
    const variables = {
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(CREATE_USER_VERIFICATION_STEPS, variables, true);

    return execute;
}

const UPDATE_USER_VERIFICATION_STEPS = gql`
 mutation updateUserVerificationSteps($id: String!, $input: UserVerificationStepsUpdateInput!) {
   updateUserVerificationSteps(id: $id, input: $input) {
     ... on UserVerificationSteps {
        _id
        country {_id}
        verificationType
        clientType
        label
        description
        requiredType
        numberOfRequired
        partiallyRequiredGroup {
            _id
            group {_id label}
        }
        sortingNumber
        isEnabled
        isDeleted
     }
     ... on BaseError {
         errKey
         errMsg
     }
     ... on CrmTaskApproval {
        msg
     }
   }
 }

`;

const updateUserVerificationSteps = async (id, input) => {
    const variables = {
        id: id,
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(UPDATE_USER_VERIFICATION_STEPS, variables, true);

    return execute;
}

const DELETE_USER_VERIFICATION_STEPS = gql`
 mutation deleteUserVerificationSteps($id: String!) {
   deleteUserVerificationSteps(id: $id) {
     ... on UserVerificationSteps {
         _id
         country {_id}
         verificationType
         clientType
         label
         description
         requiredType
         numberOfRequired
         partiallyRequiredGroup {
             _id
             group {_id label}
         }
         sortingNumber
         isEnabled
         isDeleted
     }
     ... on BaseError {
         errKey
         errMsg
     }
     ... on CrmTaskApproval {
        msg
     }
   }
 }

`;

const deleteUserVerificationSteps = async (id) => {
    const variables = {
        id: id,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(DELETE_USER_VERIFICATION_STEPS, variables, true);

    return execute;
}

const SORT_USER_VERIFICATION_STEPS = gql`
 mutation sortUserVerificationSteps($ids: [String]!, $clientType: String!, $country: String!) {
   sortUserVerificationSteps(ids: $ids, clientType: $clientType, country: $country) {
     ... on UserVerificationStepsArray {
        data {
          _id
          country {
            _id
            country
            isDeleted
          }
          verificationType
          clientType
          label
          sortingNumber
          isEnabled
          isDeleted
        }
      }
     ... on BaseError {
         errKey
         errMsg
     }
     ... on CrmTaskApproval {
        msg
     }
   }
 }

`;

const sortUserVerificationSteps = async (ids, clientType, country) => {
    const variables = {
        ids: ids,
        clientType: clientType,
        country: country,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(SORT_USER_VERIFICATION_STEPS, variables, true);

    return execute;
}

const GET_USER_VERIFICATION_GROUP_SETTING = gql`
  query getUserVerificationDocumentGroupSetting($filter: UserVerificationDocumentGroupSettingFilter){
    getUserVerificationDocumentGroupSetting(filter: $filter){
      ... on UserVerificationDocumentGroupSettingArray {
        data {
          _id
          step { _id sortingNumber }
          group {
            _id
            name
            label
            isDeleted
            documents {
              _id
              name
              label
              isDeleted
            }
          }
          isDeleted
          documents {
            document {
              _id
              name
              label
              isDeleted
            }
          }
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getUserVerificationDocumentGroupSetting = async (filter) => {
    try {
        const execute = await ApolloClientQuery(
            GET_USER_VERIFICATION_GROUP_SETTING,
            {
                filter: filter,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const CREATE_USER_VERIFICATION_GROUP_SETTING = gql`
 mutation createUserVerificationDocumentGroupSetting($input: UserVerificationDocumentGroupSettingInput!) {
   createUserVerificationDocumentGroupSetting(input: $input) {
     ... on UserVerificationDocumentGroupSetting {
       _id
       step { _id sortingNumber }
       isEnabled
       isDeleted
       documents {
         document {
           _id
           name
           label
           isDeleted
         }
       }
     }
     ... on BaseError {
         errKey
         errMsg
     }
     ... on CrmTaskApproval {
        msg
     }
   }
 }

`;

const createUserVerificationDocumentGroupSetting = async (input) => {
    const variables = {
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(CREATE_USER_VERIFICATION_GROUP_SETTING, variables, true);

    return execute;
}

const UPDATE_USER_VERIFICATION_GROUP_SETTING = gql`
 mutation updateUserVerificationDocumentGroupSetting($id: String!, $input: UserVerificationDocumentGroupSettingUpdateInput) {
   updateUserVerificationDocumentGroupSetting(id: $id, input: $input) {
     ... on UserVerificationDocumentGroupSetting {
       _id
       step { _id sortingNumber }
       isEnabled
       isDeleted
       documents {
         document {
           _id
           name
           label
           isDeleted
         }
       }
     }
     ... on BaseError {
         errKey
         errMsg
     }
     ... on CrmTaskApproval {
        msg
     }
   }
 }

`;

const updateUserVerificationDocumentGroupSetting = async (id, input) => {
    const variables = {
        id: id,
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(UPDATE_USER_VERIFICATION_GROUP_SETTING, variables, true);

    return execute;
}

const DELETE_USER_VERIFICATION_GROUP_SETTING = gql`
 mutation deleteUserVerificationDocumentGroupSetting($id: String!) {
   deleteUserVerificationDocumentGroupSetting(id: $id) {
     ... on UserVerificationDocumentGroupSetting {
       _id
       step { _id sortingNumber }
       isEnabled
       isDeleted
       documents {
         document {
           _id
           name
           label
           isDeleted
         }
       }
     }
     ... on BaseError {
         errKey
         errMsg
     }
     ... on CrmTaskApproval {
        msg
     }
   }
 }

`;

const deleteUserVerificationDocumentGroupSetting = async (id) => {
    const variables = {
        id: id,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(DELETE_USER_VERIFICATION_GROUP_SETTING, variables, true);

    return execute;
}

const GET_USER_VERIFICATION_FIELD_SETTING = gql`
  query getUserVerificationFieldSetting($filter: UserVerificationFieldSettingFilter){
    getUserVerificationFieldSetting(filter: $filter){
      ... on UserVerificationFieldSettingArray {
        data {
          _id
          document { _id }
          field { _id }
          linkField
          isLinkRequired
          isRequired
          hasExpiredDate
          sortingNumber
          isRepresentativeInfo
          isEnabled
          isDeleted
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getUserVerificationFieldSetting = async (filter) => {
    try {
        const execute = await ApolloClientQuery(
            GET_USER_VERIFICATION_FIELD_SETTING,
            {
                filter: filter,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const CREATE_USER_VERIFICATION_FIELD_SETTING = gql`
 mutation createUserVerificationFieldSetting($input: UserVerificationFieldSettingInput!) {
   createUserVerificationFieldSetting(input: $input) {
     ... on UserVerificationFieldSetting {
       _id
       document { _id }
       field { _id }
       linkField
       isLinkRequired
       isRequired
       hasExpiredDate
       sortingNumber
       isEnabled
       isDeleted
     }
     ... on BaseError {
         errKey
         errMsg
     }
     ... on CrmTaskApproval {
        msg
     }
   }
 }

`;

const createUserVerificationFieldSetting = async (input) => {
    const variables = {
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(CREATE_USER_VERIFICATION_FIELD_SETTING, variables, true);

    return execute;
}

const UPDATE_USER_VERIFICATION_FIELD_SETTING = gql`
 mutation updateUserVerificationFieldSetting($id: String!,$input: UserVerificationFieldSettingUpdateInput) {
   updateUserVerificationFieldSetting(id: $id, input: $input) {
     ... on UserVerificationFieldSetting {
       _id
       document { _id }
       field { _id }
       linkField
       isLinkRequired
       isRequired
       hasExpiredDate
       sortingNumber
       isEnabled
       isDeleted
     }
     ... on BaseError {
         errKey
         errMsg
     }
     ... on CrmTaskApproval {
        msg
     }
   }
 }

`;

const updateUserVerificationFieldSetting = async (id, input) => {
    const variables = {
        id: id,
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(UPDATE_USER_VERIFICATION_FIELD_SETTING, variables, true);

    return execute;
}

const DELETE_USER_VERIFICATION_FIELD_SETTING = gql`
 mutation deleteUserVerificationFieldSetting($id: String!) {
   deleteUserVerificationFieldSetting(id: $id) {
     ... on UserVerificationFieldSetting {
       _id
       document { _id }
       field { _id }
       linkField
       isLinkRequired
       isRequired
       hasExpiredDate
       sortingNumber
       isEnabled
       isDeleted
     }
     ... on BaseError {
         errKey
         errMsg
     }
     ... on CrmTaskApproval {
        msg
     }
   }
 }

`;

const deleteUserVerificationFieldSetting = async (id) => {
    const variables = {
        id: id,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(DELETE_USER_VERIFICATION_FIELD_SETTING, variables, true);

    return execute;
}

const SORT_USER_VERIFICATION_FIELD_SETTING = gql`
 mutation sortUserVerificationFieldSetting($ids: [String]!, $groupSettingId: String!, $documentId: String!) {
   sortUserVerificationFieldSetting(ids: $ids, groupSettingId: $groupSettingId, documentId: $documentId) {
     ... on UserVerificationFieldSettingArray {
        data {
          _id
          document { _id }
          field { _id }
          linkField
          isLinkRequired
          isRequired
          hasExpiredDate
          sortingNumber
          isEnabled
          isDeleted
        }
      }
     ... on BaseError {
         errKey
         errMsg
     }
     ... on CrmTaskApproval {
        msg
     }
   }
 }

`;

const sortUserVerificationFieldSetting = async (ids, groupSettingId, documentId) => {
    const variables = {
        ids: ids,
        groupSettingId: groupSettingId,
        documentId: documentId,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(SORT_USER_VERIFICATION_FIELD_SETTING, variables, true);

    return execute;
}

const GET_USER_VERIFICATION_RECORD_DOCUMENTS = gql`
  query getUserVerificationRecordDocumentIds($ids: [String]){
    getUserVerificationRecordDocumentIds(ids: $ids){
      ... on UserVerificationRecordDocumentArray {
        data {
          _id
          documentName
          verificationType
          groupSetting {
            _id
            step {
              _id
              verificationType
              label
              sortingNumber
            }
            documents {
              document {
                _id
                label
              }
            }
          }
          clientType
          clientStartTime
          clientEndTime
          device
          browser
          ip
          deviceToken
          status
          reviewDatetime
          duration
          fieldValue {
            _id
            documentRecord { _id }
            user { _id }
            field { _id label fieldType }
            linkField
            hasExpiredDate
            isRequired
            value
            status
            expiredDate
            syncToUser
          }
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getUserVerificationRecordDocumentIds = async (ids) => {
    try {
        const execute = await ApolloClientQuery(
            GET_USER_VERIFICATION_RECORD_DOCUMENTS,
            {
                ids: ids,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_USER_VERIFICATION_RECORD_VALUE = gql`
  query getUserVerificationRecordValue($filter: UserVerificationRecordValueFilter){
    getUserVerificationRecordValue(filter: $filter){
      ... on UserVerificationRecordValueArray {
        data {
          _id
          memberId
          referenceId {
            _id
            fieldType 
            label
            fieldGroupType
            fieldGroup {
              _id
              fieldType
              label
            }
          }
          documentRecord {
            _id
            documentName
            verificationType
            groupSetting {
              _id
              step {
                _id
                verificationType
                label
                sortingNumber
              }
              documents {
                document {
                  _id
                  label
                }
              }
            }
            clientType
            clientStartTime
            clientEndTime
            device
            browser
            ip
            deviceToken
            status
            reviewDatetime
            duration
            fieldValue {
              _id
              documentRecord { _id }
              user { _id }
              field { _id label fieldType }
              linkField
              hasExpiredDate
              isRequired
              value
              status
              expiredDate
              syncToUser
              modifiedBy {
                _id
                username
              }
              modifiedAt
            }
          }
          user { _id }
          field { _id label fieldType }
          fieldSetting { _id linkField isRepresentativeInfo sortingNumber }
          valueMobile {countryCode mobileNo}
          status
          linkField
          hasExpiredDate
          isRequired
          value
          status
          expiredDate
          syncToUser
          modifiedBy {
                _id
                username
              }
              modifiedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getUserVerificationRecordValue = async (filter) => {
    try {
        const execute = await ApolloClientQuery(
            GET_USER_VERIFICATION_RECORD_VALUE,
            {
                filter: filter,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_USER_VERIFICATION_RECORD = gql`
  query getUserVerificationRecord($filter: UserVerificationRecordFilter){
    getUserVerificationRecord(filter: $filter){
      ... on UserVerificationRecordArray {
        data {
          _id
          recordId
          task {
            claimBy {_id username}
            approvedBy {_id username}
            rejectedBy {_id username}
            rejectedDatetime
            approvedDatetime
            status
            comment
          }
          comment
          user {_id username}
          countryName
          clientType
          device
          deviceToken
          browser
          ip
          status
          clientStartTime
          clientEndTime
          duration
          createdAt
          recordDocument {
            _id
            browser
            device
            clientStartTime
            clientEndTime
            duration
            document { _id label}
            group {
              _id
              label
            }
            groupSetting {
              step {description}
            }
            status
            fieldValue {
              _id
              documentRecord{
                groupName
                documentName
                verificationType
                clientEndTime
                clientStartTime
                duration
              }
              field {
                _id
                fieldType 
                label
                fieldGroupType
                fieldGroup {
                  _id
                  fieldType
                  label
                }
              }
              fieldSetting { _id sortingNumber isRepresentativeInfo }
              referenceId {
                _id
                fieldType 
                label
                fieldGroupType
                fieldGroup {
                  _id
                  fieldType
                  label
                }
              }
              memberId
              linkField
              hasExpiredDate
              isRequired
              value
              valueMobile { countryCode mobileNo }
              status
              expiredDate
              syncToUser
              modifiedBy {
                _id
                username
              }
              modifiedAt
            }
          }
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getUserVerificationRecord = async (filter) => {
    try {
        const execute = await ApolloClientQuery(
            GET_USER_VERIFICATION_RECORD,
            {
                filter: filter,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const UPDATE_USER_VERIFICATION_RECORD_VALUE = gql`
 mutation updateUserVerificationRecordValue($id: String!,$input: UserVerificationRecordValueUpdateInput) {
   updateUserVerificationRecordValue(id: $id, input: $input) {
     ... on UserVerificationRecordValue {
       _id
       documentRecord { _id }
       field { _id }
       linkField
       isRequired
       hasExpiredDate
       value
       valueMobile {
        countryCode
        mobileNo
       }
       expiredDate
     }
     ... on BaseError {
         errKey
         errMsg
     }
     ... on CrmTaskApproval {
        msg
     }
   }
 }

`;

const updateUserVerificationRecordValue = async (id, input) => {
    const variables = {
        id: id, // Pass in userVerificationRecordValue Id
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(UPDATE_USER_VERIFICATION_RECORD_VALUE, variables, true);

    return execute;
}

const DUPLICATE_USER_VERIFICATION_COUNTRY_FLOW = gql`
 mutation duplicateUserVerificationCountryFlow($fromCountry: String!, $toCountry: String!) {
  duplicateUserVerificationCountryFlow(fromCountry: $fromCountry, toCountry: $toCountry) {
    ... on CrmTaskApproval {
      msg
    }
    ... on UserVerificationCountryFlow {
      steps {
        _id
        country {
          country
          _id
        }
      }
    }
    ... on BaseError {
      errKey
      errMsg
      errObj
    }
  }
}
`;

const duplicateUserVerificationCountryFlow = async (fromCountry, toCountry) => {
    const variables = {
        fromCountry: fromCountry,
        toCountry: toCountry,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(DUPLICATE_USER_VERIFICATION_COUNTRY_FLOW, variables, true);

    return execute;
};

const DELETE_USER_VERIFICATION_COUNTRY_FLOW = gql`
 mutation deleteUserVerificationCountryFlow($country: String!) {
  deleteUserVerificationCountryFlow(country: $country) {
    ... on CrmTaskApproval {
      msg
    }
    ... on UserVerificationCountryFlow {
      steps {
        _id
      }
    }
    ... on BaseError {
      errKey
      errMsg
    }
  }
}
`;

const deleteUserVerificationCountryFlow = async (country) => {
    const variables = {
        country: country,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(DELETE_USER_VERIFICATION_COUNTRY_FLOW, variables, true);

    return execute;
};

export const CREATE_USER_VERIFICATION_RECORD_DOCUMENT = gql`
  mutation createUserVerificationRecordDocument(
    $input: UserVerificationRecordDocumentInput!
  ) {
    createUserVerificationRecordDocument(input: $input) {
      ... on UserVerificationRecordDocument {
        _id
        ip
        status
      }
      ... on BaseError {
        errMsg
      }
    }
  }
`;

const createUserVerificationRecordDocument = async (value) => {
    const variables = {
        input: value,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(
        CREATE_USER_VERIFICATION_RECORD_DOCUMENT,
        variables,
        true
    );

    return execute;
}

const CREATE_USER_VERIFICATION_RECORD = gql`
  mutation createUserVerificationRecord($input: UserVerificationRecordInput) {
    createUserVerificationRecord(input: $input) {
      ... on UserVerificationRecord {
        recordId
        _id
        title
        status
        duration
        countryName
        clientType
        recordDocument {
          _id
          clientType
          verificationType
          country {
            country
          }
          groupName
          document {
            _id
            name
            label
          }
          group {
            _id
            label
            name
          }
          groupSetting {
            documents {
              document {
                _id
                name
                label
              }
            }
            group {
              name
              label
              _id
            }
          }
          status
          clientStartTime
          clientEndTime
          fieldValue {
            referenceId {
              _id
            }
            field {
              _id
              fieldType
              fieldValue {
                label
                value
              }
              fieldGroup {
                label
                fieldType
                _id
              }
              fieldGroupType
            }
            fieldSetting {
              _id
            }
            value
            status
            hasExpiredDate
            expiredDate
          }
        }
      }
      ... on BaseError {
        errMsg
      }
    }
  }
`;

const createUserVerificationRecord = async (input) => {
    const variables = {
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };

    return await ApolloClientQuery(
        CREATE_USER_VERIFICATION_RECORD,
        variables,
        true
    );
}

const CANCEL_USER_VERIFICATION_RECORD = gql`
  mutation cancelUserVerificationRecord(
    $input: UserVerificationRecordCancelInput
  ) {
    cancelUserVerificationRecord(input: $input) {
      ... on UserVerificationRecord {
        _id
        clientType
        status
      }
      ... on BaseError {
        errMsg
      }
    }
  }
`;

const cancelUserVerificationRecord = async ({user, record}) => {
    const variables = {
        input: {
            user,
            record,
        },
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    return await ApolloClientQuery(
        CANCEL_USER_VERIFICATION_RECORD,
        variables,
        true
    );
}

const SAVE_USER_VERIFICATION_RECORD = gql`
  mutation saveUserVerificationRecord(
    $input: UserVerificationRecordValueInput
  ) {
    saveUserVerificationRecord(input: $input) {
      ... on UserVerificationRecord {
        _id
        status
        duration
        countryName
        clientType
        recordDocument {
          _id
          clientType
          verificationType
          country {
            country
          }
          groupName
          document {
            _id
            name
            label
          }
          group {
            _id
            label
            name
          }
          groupSetting {
            documents {
              document {
                _id
                name
                label
              }
            }
            group {
              name
              label
              _id
            }
          }
          status
          clientStartTime
          clientEndTime
        }
      }
      ... on BaseError {
        errMsg
        errKey
      }
    }
  }
`;

const saveUserVerificationRecord = async ({record, documentRecord, user, field}) => {
    const variables = {
        input: {
            record,
            documentRecord,
            user,
            field,
        },
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };

    return await ApolloClientQuery(
        SAVE_USER_VERIFICATION_RECORD,
        variables,
        true
    );
}

const COMPLETE_USER_VERIFICATION_RECORD = gql`
  mutation completeUserVerificationRecord($record: String!, $user: String!) {
    completeUserVerificationRecord(record: $record, user: $user) {
      ... on UserVerificationRecord {
        _id
        status
        duration
        countryName
        clientType
        recordDocument {
          _id
          clientType
          verificationType
          country {
            country
          }
          groupName
          document {
            _id
            name
            label
          }
          group {
            _id
            label
            name
          }
          groupSetting {
            documents {
              document {
                _id
                name
                label
              }
            }
            group {
              name
              label
              _id
            }
          }
          status
          clientStartTime
          clientEndTime
        }
      }
      ... on BaseError {
        errMsg
      }
    }
  }
`;

const completeUserVerificationRecord = async ({record, user}) => {
    const variables = {
        record,
        user,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
        refetchQueries: [GET_USER_BY_ID]
    };
    return await ApolloClientQuery(
        COMPLETE_USER_VERIFICATION_RECORD,
        variables,
        true
    );
}

export const GET_USER_VERIFICATION_FIELD = gql`
  query getUserVerificationAvailableField(
    $filter: UserVerificationAvailableFieldFilter
  ) {
    getUserVerificationAvailableField(filter: $filter) {
      ... on UserVerificationAvailableField {
        data
      }
    }
  }
`;

const getUserVerificationAvailableField = async (countryId) => {
    const variables = {
        filter: {
            country: countryId,
        },
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };

    return await ApolloClientQuery(
        GET_USER_VERIFICATION_FIELD,
        variables,
        false
        // "cache-first"
    );
}


const GET_USER_VERIFICATION_EXPORT_DATA = gql`
  query getUserVerificationExportData{
    getUserVerificationExportData
  }
`;

const getUserVerificationExportData = () => {
  try {
    const { loading, error, data } = useQuery(GET_USER_VERIFICATION_EXPORT_DATA, {
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const IMPORT_USER_VERIFICATION_DATA = gql`
  mutation importUserVerificationData($input: String) {
    importUserVerificationData(input: $input)
  }
`;

const useImportUserVerificationData = (): {
  importUserVerificationData: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const { t } = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [importUserVerificationDataMutation, { data, loading, error }] =
      useMutation(IMPORT_USER_VERIFICATION_DATA);

    const importUserVerificationData = (input) => {
      return importUserVerificationDataMutation({
        variables: {
          input: input,
        },
        update(cache, { data: { importUserVerificationData } }) {
          const typename = importUserVerificationData?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(importUserVerificationData?.errKey),
              key: "importUserVerificationData",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(importUserVerificationData?.msg),
              key: "importUserVerificationData",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Update Successfully"),
              key: "importUserVerificationData",
            });
          }
        },
        refetchQueries: [GET_USER_VERIFICATION_FIELD, GET_USER_VERIFICATION_FIELD_SETTING, GET_USER_VERIFICATION_GROUP_SETTING, GET_USER_VERIFICATION_STEP],
      });
    };

    return { importUserVerificationData, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return { importUserVerificationData: null, data: null, loading: false, error: err };
  }
};


export {
    getUserVerificationAvailableFieldSetting,
    getUserVerificationSteps,
    createUserVerificationSteps,
    updateUserVerificationSteps,
    deleteUserVerificationSteps,
    sortUserVerificationSteps,
    getUserVerificationDocumentGroupSetting,
    createUserVerificationDocumentGroupSetting,
    updateUserVerificationDocumentGroupSetting,
    deleteUserVerificationDocumentGroupSetting,
    getUserVerificationFieldSetting,
    createUserVerificationFieldSetting,
    updateUserVerificationFieldSetting,
    deleteUserVerificationFieldSetting,
    sortUserVerificationFieldSetting,
    getUserVerificationRecordDocumentIds,
    getUserVerificationRecordValue,
    getUserVerificationRecord,
    updateUserVerificationRecordValue,
    duplicateUserVerificationCountryFlow,
    deleteUserVerificationCountryFlow,
    createUserVerificationRecordDocument,
    createUserVerificationRecord,
    cancelUserVerificationRecord,
    saveUserVerificationRecord,
    completeUserVerificationRecord,
    getUserVerificationAvailableField,
    getUserVerificationExportData,
    useImportUserVerificationData
}

