import {EditOutlined, MoneyCollectOutlined, MoreOutlined, ThunderboltOutlined} from "@ant-design/icons";
import {Button, Card, Col, Popconfirm, Row, Space, Tooltip, Typography} from "antd";
import React, {forwardRef, HTMLAttributes, CSSProperties} from "react";
import {useTranslation} from "react-i18next";
import {AiOutlineDelete} from "react-icons/ai";

const {Paragraph} = Typography;

export type ItemProps = HTMLAttributes<HTMLDivElement> & {
    formMode: string;
    item: any;
    id: string;
    withOpacity?: boolean;
    isDragging?: boolean;
    toggleEditColModal: Function;
    deleteColumn: Function;
    setColumnsValue: Function;
    isUsedForAutomationShown: boolean;
    isUsedForCommissionShown: boolean;
};

const Item = forwardRef<HTMLDivElement, ItemProps>(
    (
        {
            formMode,
            id,
            withOpacity,
            isDragging,
            style,
            item,
            toggleEditColModal,
            deleteColumn,
            setColumnsValue,
            isUsedForAutomationShown,
            isUsedForCommissionShown,
            ...props
        },
        ref
    ) => {
        const {t} = useTranslation();

        const inlineStyles: CSSProperties = {
            opacity: withOpacity ? "0.5" : "1",
            transformOrigin: "50% 50%",
            borderRadius: "5px",
            cursor: isDragging ? "grabbing" : "grab",
            backgroundColor: "#ffffff",
            // boxShadow: isDragging
            //     ? "rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px"
            //     : "rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px",
            transform: isDragging ? "scale(1.05)" : "scale(1)",
            display: "flex",
            height: "100%",
            ...style,
        };

        const isUsedForAutomation = item?.isUsedForAutomation;
        const isUsedForCommission = item?.isUsedForCommission;
        const disabledEdit = item?.disabledEdit;
        const mustGroupBy = item?.mustGroupBy;

        const handleColumnsValueChange = (field, index) => {
            try {
                let newFieldValue = field === "isUsedForAutomation" ? !isUsedForAutomation : !isUsedForCommission;
                setColumnsValue((prevState) => {
                    const newArray = [...prevState];
                    const updatedCol = {
                        ...prevState[index],
                        [field]: newFieldValue,
                    };
                    newArray[index] = updatedCol;
                    return newArray;
                });
            } catch (error) {}
        };

        let ColumnInfo = () => {
            const tooltipTitle = (
                <>
                    <div>
                        <small className="margin-bottom-0">{item?.description}</small>
                    </div>
                </>
            );
            return (
                <Tooltip title={tooltipTitle}>
                    <p className="columnName">
                        {item?.name}
                        {/* <span className="margin-left-0-5" style={{cursor: "pointer"}}>
                            {item?.operation && (
                                <Tooltip title={tooltipTitle}>
                                    <InfoCircleOutlined />
                                </Tooltip>
                            )}
                        </span> */}
                    </p>
                </Tooltip>
            );
        };

        return (
            <div ref={ref} style={inlineStyles} {...props}>
                {/* <MoreOutlined className="dragIcon" /> */}
                <Card key={item?._id} className="w-100 h-100" bodyStyle={{padding: "5px", height: "100%"}}>
                    <Row align="top" className="h-100">
                        <Col flex={"auto"} className="h-100">
                            <Space className="display-flex justify-content-between align-items-start">
                                <ColumnInfo />
                                <div className="display-flex">
                                    {isUsedForAutomationShown && (
                                        <Tooltip title={t("Is Used For Automation")}>
                                            <Button
                                                type={"link"}
                                                icon={<ThunderboltOutlined />}
                                                onClick={() => handleColumnsValueChange("isUsedForAutomation", id)}
                                                className={`columnSettingsBtn ${isUsedForAutomation ? "toggleOn" : "toggleOff"}`}
                                                disabled={disabledEdit}
                                            />
                                        </Tooltip>
                                    )}
                                    {isUsedForCommissionShown && (
                                        <Tooltip title={t("Is Used For Commission")}>
                                            <Button
                                                type={"link"}
                                                icon={<MoneyCollectOutlined />}
                                                onClick={() => handleColumnsValueChange("isUsedForCommission", id)}
                                                className={`columnSettingsBtn ${isUsedForCommission ? "toggleOn" : "toggleOff"}`}
                                                disabled={disabledEdit}
                                            />
                                        </Tooltip>
                                    )}
                                    <Button
                                        type={"link"}
                                        icon={<EditOutlined />}
                                        onClick={() => toggleEditColModal(item, id)}
                                        className="columnSettingsBtn"
                                        disabled={disabledEdit}
                                    />
                                    {/* <Col>
                                        <Button
                                            type={"link"}
                                            icon={<DragOutlined />}
                                            style={{color: "darkGrey", cursor: "grab"}}
                                            className="columnSettingsBtn"
                                        />
                                    </Col> */}
                                </div>
                            </Space>
                            <Paragraph ellipsis={{rows: 2}} type="secondary" className="margin-bottom-0">
                                {item?.operation}
                            </Paragraph>
                            <Paragraph ellipsis={{rows: 2}} type="warning" className="margin-bottom-0">
                                {mustGroupBy && "Group by Column"}
                            </Paragraph>
                        </Col>

                        {formMode === "add" && !disabledEdit && (
                            <Col>
                                <Popconfirm
                                    placement="left"
                                    description={t("Are you sure to delete this column?")}
                                    title={t(`Delete Column`)}
                                    onConfirm={(e) => {
                                        deleteColumn(id);
                                    }}
                                    okText={t("Yes")}
                                    okType="danger"
                                    cancelText={t("No")}
                                >
                                    <Tooltip title={t("Delete")}>
                                        <Button type={"link"} icon={<AiOutlineDelete />} danger className="columnSettingsBtn" />
                                    </Tooltip>
                                </Popconfirm>
                            </Col>
                        )}
                    </Row>
                </Card>
            </div>
        );
    }
);

export default Item;
