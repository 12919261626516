import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import {Button, Col, Form, Row} from "antd";
import {useSetRecoilState} from "recoil";

import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import yupFormSchema from "../../../../../../components/yup/yupFormSchema";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import {verticalFormLayout} from "../../../../../../components/form/formWrapper";
import useAuthorize from "../../../../../../_common/authorize";
import {addCompanyEmailTypes, updateCompanyEmailTypes} from "../../../../../../api/graphql/companyEmailType";
import {notificationMessage} from "../../../../../../../recoil_state";

const CompanyEmailTypesForm = (props) => {
    const {mode, item, closeModal, getCompanyEmailTypesData} = props;
    const {t} = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {baseErrorChecking, systemErrorNotification} = useAuthorize();
    const [errMsg, setErrMsg] = useState("");

    const yupSchema = yup.object().shape({
        name: yupFormSchema.string("name", {
            required: true,
            requiredErrorMessage: "Name is a required field",
        }),
    });

    const [initialValues] = useState(() => ({
        name: item?.name || "",
    }));

    var form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
        defaultValues: initialValues,
    });

    const onCancel = () => {
        closeModal();
    };

    const onSubmit = async (values) => {
        try {
            const response = mode === "add" ? await addCompanyEmailTypes(values) : await updateCompanyEmailTypes(item._id, values);
            if (mode === "add" && response?.addCompanyEmailTypes.errMsg) {
                return await baseErrorChecking(response?.addCompanyEmailTypes);
            } else if (mode === "edit" && response?.updateCompanyEmailTypes.errMsg) {
                return await baseErrorChecking(response?.updateCompanyEmailTypes);
            } else {
                getCompanyEmailTypesData();
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: mode === "add" ? "Add Successfully" : "Update Successfully",
                    key: "companyEmailTypes",
                });
                setErrMsg("");
                closeModal();
            }
        } catch (error: any) {
            systemErrorNotification();
        }
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="form-1">
                {errMsg && <p style={{color: "red"}}>{errMsg}</p>}
                <Row gutter={10}>
                    <Col xs={12}>
                        <InputFormItem
                            name={"name"}
                            label={t("Name")}
                            placeholder={t("Name")}
                            layout={verticalFormLayout}
                            required
                            disabled={mode === "view"}
                        />
                    </Col>
                </Row>

                <Form.Item className="text-align-right" style={{paddingTop: "12px"}}>
                    {onCancel && (
                        <Button onClick={onCancel} icon={<CloseOutlined />} className="button-cancel-1">
                            {t("common.cancel")}
                        </Button>
                    )}
                    {mode !== "view" && (
                        <Button onClick={form.handleSubmit(onSubmit)} icon={<SaveOutlined />} className="button-submit-1">
                            {t("common.submit")}
                        </Button>
                    )}
                </Form.Item>
            </form>
        </FormProvider>
    );
};

export default CompanyEmailTypesForm;
