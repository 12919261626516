import {Modal} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";
import SymbolCategoryForm from "./symbolCategoryForm";

const AddCategoryModal = (props) => {
    const {mode, item, isOpen, close} = props
    const {t} = useTranslation()

    return (
        <Modal
            title={t(`${mode} Category`)}
            open={isOpen}
            onCancel={close}
            footer={null}
            width={500}
            destroyOnClose
        >
            <SymbolCategoryForm
                item={item}
                mode={mode}
                close={close}
            />
        </Modal>
    )
}

export default AddCategoryModal