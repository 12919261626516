import React, {useEffect, useState} from "react";
import {Button, Space, Tabs, Tooltip} from "antd";
import SpreadGroupTable_1 from "./component/spreadGroupTable";
import EditTableUserPreferenceButton from "../../../../../components/table/function/editTableUserPreferenceButton";
import ExportTableButton from "../../../../../components/table/function/exportTable";
import {SyncOutlined} from "@ant-design/icons";
import {useRefreshProfileMutation, useRefreshSpreadGroupMutation} from "../../../../../api/graphql/spreadGroup";
import {useTranslation} from "react-i18next";
import useAuthorize from "../../../../../_common/authorize";
import SpreadGroupMt5Table from "./component/spreadGroupMt5Table";
import {columnsSpreadGroupMT5} from "../../function/tableColumn_Mt5";
import {refreshSpreadGroupMt5} from "../../../../../api/graphql/spreadGroupMt5";
import {useRecoilState} from "recoil";
import {currentServerState, spreadGroupMt5ReloadState} from "../../state";
import {getServerSettingLoadingStatus} from "../../../../../api/graphql/serverSetting";
import moment from "moment";

const SpreadGroup_1 = (props) => {
    const {data, permission, loading} = props;
    const {t} = useTranslation()
    const [exportData, setExportData] = useState(null)
    const [exportColumn, setExportColumn] = useState(null)
    const [mt5RefreshLoading, setMt5RefreshLoading] = useState(false)
    const [currentServer, setCurrentServer] = useRecoilState<any>(currentServerState)
    const [serverList, setServerList] = useState<any>([])
    const tableName = "Spread Group"
    const tableNameMt5 = "Mt5 Spread Group"
    const {refreshSpreadGroup, loading: refreshLoading} = useRefreshSpreadGroupMutation();
    const [ctSpreadGroupLoading, setCtSpreadGroupLoading] = useState(false)
    const [ctSpreadGroupLastUpdatedDate, setCtSpreadGroupLoadingLastUpdatedDate] = useState(null)
    // const serverSettingLoadingStatus = getServerSettingLoadingStatus(currentServer?.id);
    const {refreshProfile, loading: refreshProfileLoading} = useRefreshProfileMutation()
    const [currentSymbolTab, setCurrentSymbolTab] = useState<any>(0)
    const [mt5TableRefresh, setMt5TableRefresh] = useRecoilState(spreadGroupMt5ReloadState)

    const {systemErrorNotification} = useAuthorize();

    const columnMt5 = columnsSpreadGroupMT5()

    const handleSpreadGroupRefresh = (id) => {
        try {
            refreshSpreadGroup(id);
        } catch (error) {
            systemErrorNotification();
        }
    }

    const handleSpreadGroupMt5Refresh = async (serverId) => {
        try {
            await setMt5RefreshLoading(true)
            await refreshSpreadGroupMt5(serverId)
        } catch (e) {
            systemErrorNotification();
        } finally {
            setMt5RefreshLoading(false)
            setMt5TableRefresh(true)
        }
    }

    useEffect(() => {
        async function fetchServerSettingLoadingStatus() {
            let response = await getServerSettingLoadingStatus(currentServer?.id)
            setCtSpreadGroupLoading(response?.getServerSettingLoadingStatus?.spreadGroupLoading)
            if (response?.getServerSettingLoadingStatus?.spreadGroupLastUpdatedDate) {
                setCtSpreadGroupLoadingLastUpdatedDate(response?.getServerSettingLoadingStatus?.spreadGroupLastUpdatedDate)
            } else {
                setCtSpreadGroupLoadingLastUpdatedDate(currentServer?.createdAt)
            }
        }

        if (currentServer?.id) {
            fetchServerSettingLoadingStatus()
            const intervalFetching = setInterval(async () => {
                let response = await getServerSettingLoadingStatus(currentServer?.id)
                setCtSpreadGroupLoading(response?.getServerSettingLoadingStatus?.spreadGroupLoading)
                if (response?.getServerSettingLoadingStatus?.spreadGroupLastUpdatedDate) {
                    setCtSpreadGroupLoadingLastUpdatedDate(response?.getServerSettingLoadingStatus?.spreadGroupLastUpdatedDate)
                } else {
                    setCtSpreadGroupLoadingLastUpdatedDate(currentServer?.createdAt)
                }
            }, 5000)

            return () => clearInterval(intervalFetching)
        }

    }, [currentServer?.id])

    useEffect(() => {
        if (data?.getServerSetting?.data?.length > 0) {
            setCurrentServer(data?.getServerSetting?.data?.[0])
        }
    }, [data?.getServerSetting?.data?.[0]])

    useEffect(() => {
        addServerList()
    }, [data?.getServerSetting?.data])

    const addServerList = async () => {
        const listing: any = []

        data &&
        data?.getServerSetting &&
        data?.getServerSetting?.data?.map((item, i) => {

            if (item?.platform === "mt5" || item?.platform === "xTrader") {
                listing.push({
                    label: t(item?.name),
                    key: i,
                    children: (
                        <SpreadGroupMt5Table tableNameMt5={tableNameMt5} columnMt5={columnMt5} server={item}/>
                    ),
                })
            } else {
                listing.push(
                    {
                        label: t(item?.name),
                        key: i,
                        children: <SpreadGroupTable_1 server={item} setExportData={setExportData}
                                                      setExportColumn={setExportColumn} tableName={tableName}
                                                      currentServer={item}
                        />,
                    }
                )
            }


        })
        setServerList(listing)
    }

    return (
        <div id="spread-group">
            <h1>{t(tableName)}</h1>

            {data && data?.getServerSetting?.data && (
                <Tabs
                    defaultActiveKey="1"
                    size={"small"}
                    items={serverList}
                    onChange={(key) => setCurrentSymbolTab(key)}
                    tabBarExtraContent={
                        (currentServer?.platform === "mt5" || currentServer?.platform === "xTrader") ? (
                            <>
                                <Space>
                                    <EditTableUserPreferenceButton
                                        tableName={tableNameMt5}
                                        tableColumn={columnMt5}
                                    />
                                    {permission?.refreshData && (
                                        <Tooltip title={t("Refresh Spread Group")}>
                                            <Button
                                                type={"link"}
                                                loading={mt5RefreshLoading}
                                                disabled={mt5RefreshLoading}
                                                onClick={() => handleSpreadGroupMt5Refresh(currentServer?.id)}
                                                icon={<SyncOutlined/>}
                                            />
                                        </Tooltip>
                                    )}
                                </Space>
                            </>
                        ) : (
                            <>
                                <Space>
                                    {ctSpreadGroupLastUpdatedDate &&
                                    <>
                                        {t("Last Refresh Date")}: {moment(new Date(ctSpreadGroupLastUpdatedDate)).format('LLL')}
                                    </>
                                    }
                                    <Button
                                        onClick={() => refreshProfile(currentServer?.id)}
                                        loading={loading || refreshProfileLoading}
                                    >
                                        Refresh All Profile
                                    </Button>
                                    <EditTableUserPreferenceButton
                                        tableName={tableName}
                                        server={currentServer}
                                        tableColumn={exportColumn}
                                    />
                                    <ExportTableButton
                                        loading={loading || refreshLoading}
                                        tableData={exportData}
                                        tableColumn={exportColumn}
                                        tableName={tableName}
                                        server={currentServer}
                                    />
                                    {permission?.refreshData &&
                                    <Tooltip title={t("Refresh Spread Group")}>
                                        <Button
                                            type={"link"}
                                            loading={loading || refreshLoading || ctSpreadGroupLoading}
                                            disabled={loading || refreshLoading || ctSpreadGroupLoading}
                                            onClick={() => handleSpreadGroupRefresh(currentServer?.id)}
                                            icon={<SyncOutlined/>}
                                        />
                                    </Tooltip>
                                    }
                                </Space>
                            </>
                        )

                    }
                    onTabClick={(key) => {
                        setCurrentServer(data?.getServerSetting?.data?.[key])
                    }}
                />
            )}
        </div>
    );
}

export default SpreadGroup_1;
