import React from "react";
import {Card} from "antd";
import DetailTable from "../../../../../salesOpportunity/view/layout1/component/detailTable";

const SalesOpportunityCard = (props) => {
    const {data, permissionUser, clientId} = props

    return (
        <>
            <Card style={{width: "100%"}}>
                <DetailTable
                    filter={{client: clientId}}
                    permissionUser={permissionUser}
                    user={data}
                />
            </Card>
        </>
    )
};

export default SalesOpportunityCard