import React, {useEffect, useState} from "react";
import {
    getSymbolMapping,
    getSymbolMappingCount,
    useUpdateSymbolMapping,
} from "../../../../../../../api/graphql/symbolMapping";
import {columnsCT} from "../../../function/tableColumn_SymbolMapping_CT";
import {getSymbolCount, getSymbolShort,} from "../../../../../../../api/graphql/symbol";
import {AiOutlineEdit} from "react-icons/ai";
import SymbolsMappingModal_1 from "./mappingModal";
import {Table} from "antd";
import {getUserTableAdminSetting, getUserTableSetting,} from "../../../../../../../api/graphql/tableSetting";
import {isArray} from "@apollo/client/utilities";
import InstrumentDetailModal_1 from "./instrumentDetailsModal";
import {useTranslation} from "react-i18next";
import ExcelMappingModalModal_1 from "./excelMappingModal";
import {getInstrumentCountRestful, getInstrumentRestful} from "../../../../../../../api/restful/intruments";
import {columnsMT5} from "../../../function/tableColumn_SymbolMapping_MT5";
import {getSymbolsMt5} from "../../../../../../../api/graphql/symbolMt5";

function SymbolMappingTable_1(props) {
    const {
        server,
        setExportData,
        setExportColumn,
        tableName,
        showExcelModal,
        setShowExcelModal,
        serverList,
        editPermission,
        currentServer,
        setExportLoading,
    } = props;
    const {t} = useTranslation()
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [filter, setFilter] = useState<any>({serverName: server?.name});
    const [filterSymbol, setFilterSymbol] = useState<any>(filter);
    const [filterSymbolMapping, setFilterSymbolMapping] = useState<any>(filter);
    const [orderBy, setOrderBy] = useState("");
    const [orderByBase, setOrderByBase] = useState("");
    const [selectedItem, setSelectedItem] = useState({});
    const [editSymbolMappingModal, setEditSymbolMappingModal] = useState(false);
    const [symbolMt5, setSymbolMt5] = useState<any>([]);
    const [mt5Loading, setMt5Loading] = useState<any>(false);
    const [mappedData, setMappedData] = useState();
    const [localExportData, setLocalExportData] = useState<any>();

    const [instrumentFilter, setInstrumentFilter] = useState({});
    const [instrumentLimit, setInstrumentLimit] = useState(10);
    const [instrumentOffset, setInstrumentOffset] = useState(1);
    const [instrumentOrderBy, setInstrumentOrderBy] = useState("");

    const [instrumentList, setInstrumentList] = useState([])
    const [instrumentLoading, setInstrumentLoading] = useState(false)
    const [instrumentCount, setInstrumentCount] = useState(false)

    const [detailInstrument, setDetailInstrument] = useState([])
    // const {data: instrumentList, loading: instrumentLoading} = getInstruments(
    //     {filter: instrumentFilter},
    //     instrumentOffset || 1,
    //     instrumentLimit || 10,
    //     instrumentOrderBy
    // );
    // const {data: instrumentCount} = getInstrumentsCount({
    //     filter: instrumentFilter,
    // });
    // const {data: detailInstrument} = getInstruments(
    //     {filter: detailSymbolId}, 1, 1
    // );

    const {data: totalCount} = getSymbolCount({filter: filter});
    const {data, loading} = getSymbolShort(
        {filter: filterSymbol},
        0,
        0,
        orderBy
    );
    const {data: exportDataList, loading: exportDataListLoading} = getSymbolShort(
        {filter: filterSymbol},
        0,
        0,
        orderBy
    );
    const {data: baseList, loading: baseListLoading} = getSymbolMapping(
        {filter: filterSymbolMapping},
        0,
        0,
        orderByBase
    );

    const {data: symbolMappingCount} = getSymbolMappingCount({
        filter: filterSymbolMapping,
    });

    const [detailSymbolId, setDetailSymbolId] = useState({base_symbol_id: "0"})
    const [isOpenDetailModal, setIsOpenDetailModal] = useState(false)

    const {data: adminSettingTable} = getUserTableAdminSetting({
        filter: {
            tableName: tableName,
            serverName: server?.name,
        },
    });
    const {data: userTablePreference} = getUserTableSetting(
        localStorage.getItem("userId"),
        {
            filter: {
                tableName: tableName,
                serverName: server?.name,
            },
        }
    );

    const {updateSymbolMapping} = useUpdateSymbolMapping();
    const columns = server?.platform === "cTrader" ? columnsCT : columnsMT5;

    const [tableColumn, setTableColumn] = useState<any>(columns);

    const getInstrumentDetail = async () => {
        const response = await getInstrumentRestful({filter: detailSymbolId}, 1, 1, null)

        setDetailInstrument(response)
    }

    const getInstrumentData = async () => {
        setInstrumentLoading(true)
        const response = await getInstrumentRestful({filter: instrumentFilter},
            instrumentOffset || 1,
            instrumentLimit || 10,
            instrumentOrderBy || null)

        setInstrumentList(response)
        setInstrumentLoading(false)
    }

    const getInstrumentDataCount = async () => {
        const response = await getInstrumentCountRestful({filter: instrumentFilter})
        setInstrumentCount(response)
    }

    const getSymbolMt5 = async () => {
        try {
            const response = await getSymbolsMt5({
                server: server._id
            })

            setSymbolMt5(response?.getSymbolsMt5?.data)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (server?.platform) {
            getSymbolMt5()
        }
    }, [server])

    useEffect(() => {
        if (detailSymbolId) {
            getInstrumentDetail()
        }
    }, [detailSymbolId])

    useEffect(() => {
        getInstrumentData()
    }, [instrumentFilter, instrumentOffset, instrumentLimit, instrumentOrderBy])

    useEffect(() => {
        getInstrumentDataCount()
    }, [instrumentFilter])

    useEffect(() => {
        setExportLoading(exportDataListLoading || baseListLoading);
    }, [exportDataListLoading, baseListLoading]);

    useEffect(() => {
        if (server?.platform === "mt5") {
            setFilterSymbol({
                name: filter?.name,
                serverName: server?.name,
            });
        } else {
            setFilterSymbol({
                symbolId: filter?.symbolId,
                name: filter?.name,
                serverName: server?.name,
            });
        }

        setFilterSymbolMapping({
            baseSymbolId: filter?.baseSymbolId,
            symbolNameMt5: filter?.symbolNameMt5,
            description: filter?.description,
            descriptionMt5: filter?.descriptionMt5,
            serverName: server?.name,
        });
    }, [filter]);

    useEffect(() => {
        if (orderBy?.includes("baseSymbolId")) {
            setOrderBy("");
            setOrderByBase(orderBy);
        } else setOrderByBase("");
    }, [orderBy]);

    useEffect(() => {
        if (columns?.length > 0 && localExportData?.length > 0) {
            setExportColumn(columns);
            setExportData(localExportData);
        }
    }, [localExportData, currentServer]);

    const mapData = async (data, baseData) => {
        let latestData;
        if (!filterSymbolMapping?.baseSymbolId && !filterSymbolMapping?.description && !orderByBase) {
            latestData = await data?.map((d) => {
                if (server?.platform === "mt5" || server?.platform === "xTrader") {
                    return {
                        name: d?.symbol,
                        description: d?.description,
                        baseSymbolId:
                            baseData?.find((item) => item?.symbolMt5?._id === d?._id)
                                ?.baseSymbolId || "-",
                        id: d?._id,
                        server: d?.server?._id
                    };
                } else {
                    return {
                        name: d?.name,
                        description: d?.description,
                        baseSymbolId:
                            baseData?.find((item) => item?.symbol?._id === d?._id)
                                ?.baseSymbolId || "-",
                        id: d?._id,
                        server: d?.server?._id
                    };
                }

            });
        } else {
            latestData = await baseData?.map((d) => {
                let matchedSymbol;
                if (server?.platform === "mt5" || server?.platform === "xTrader") {
                    matchedSymbol = data?.find(
                        (item) => item?._id === d?.symbolMt5?._id
                    );
                    return {
                        baseSymbolId: d?.baseSymbolId,
                        name: matchedSymbol?.symbol,
                        description: matchedSymbol?.description,
                        id: matchedSymbol?._id,
                        server: matchedSymbol?.server?._id
                    };
                } else {
                    matchedSymbol = data?.find(
                        (item) => item?._id === d?.symbol?._id
                    );
                    return {
                        baseSymbolId: d?.baseSymbolId,
                        name: matchedSymbol?.name,
                        description: matchedSymbol?.description,
                        id: matchedSymbol?._id,
                        server: matchedSymbol?.server?._id
                    };
                }


            });
        }

        return latestData;
    };

    useEffect(() => {

        if (server?.platform === "mt5" || server?.platform === "xTrader") {

            setMt5Loading(true)
            if (symbolMt5?.length > 0) {
                mapData(symbolMt5, baseList?.getSymbolMapping?.data).then(
                    (latestData) => {
                        setMappedData(latestData);
                        setMt5Loading(false)
                    }
                );
            }
            mapData(
                symbolMt5,
                baseList?.getSymbolMapping?.data
            ).then((latestData) => {
                setLocalExportData(latestData);
            });

        } else if (server?.platform === "cTrader") {
            mapData(data?.getSymbol?.data, baseList?.getSymbolMapping?.data).then(
                (latestData) => {
                    setMappedData(latestData);
                }
            );

            mapData(
                exportDataList?.getSymbol?.data,
                baseList?.getSymbolMapping?.data
            ).then((latestData) => {
                setLocalExportData(latestData);
            });
        }


    }, [
        data?.getSymbol?.data,
        baseList?.getSymbolMapping?.data,
        exportDataList?.getSymbol?.data,
        symbolMt5
    ]);

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize);
        setOffset(limit * (page - 1));
    };

    const openEditModal = (record) => {
        setSelectedItem(record);
        setEditSymbolMappingModal(true);
    };
    const closeEditModal = () => {
        setEditSymbolMappingModal(false);
        setSelectedItem({});
    };

    const formatColumns = async () => {
        if (userTablePreference?.getUserTableSetting?.tableColumn) {
            const actionObj = columns.find((obj) => obj.key === "action");
            const userPreference = userTablePreference?.getUserTableSetting?.tableColumn;
            const formattedColumn = await userPreference?.map((d) => {
                const matchingObj = columns?.find(
                    (obj) =>
                        obj?.dataIndex === d?.columnName ||
                        (Array.isArray(obj?.dataIndex) &&
                            obj?.dataIndex.join("_") === d?.columnName)
                );
                return {
                    ...matchingObj,
                };
            }).concat(actionObj);

            return formattedColumn;
        } else if (adminSettingTable?.getUserTableAdminSetting[0]?.fieldData) {
            const actionObj = columns.find((obj) => obj.key === "action");
            const userPreference = adminSettingTable?.getUserTableAdminSetting[0]?.fieldData;
            const formattedColumn = await userPreference
                ?.map((d) => {
                    if (d?.isDefaultDisplayInTable) {
                        const matchingObj = columns?.find(
                            (obj) =>
                                obj?.dataIndex === d?.fieldName ||
                                (Array.isArray(obj?.dataIndex) &&
                                    obj?.dataIndex.join("_") === d?.columnName)
                        );
                        return {
                            ...matchingObj,
                        };
                    } else return null;
                }).concat(actionObj)
                ?.filter((item) => item !== null);

            return formattedColumn;
        } else {
            return columns;
        }
    };

    useEffect(() => {
        formatColumns()?.then((data) => {
            const column = [...data?.sort((a, b) => a?.defaultPosition - b?.defaultPosition)
                .map((d, i) => {
                    return {
                        ...d,
                        render: d?.dataIndex === "baseSymbolId" ? (text) => <a
                            onClick={() => openDetailModal(text)}>{text}</a> : d?.render
                    }
                })]

            const columnWithAction = [
                ...column,
                {
                    title: t("common.action"),
                    key: "action",
                    width: 132,
                    render: (_, record: any) => (
                        <a
                            style={{color: "green"}}
                            onClick={() => openEditModal(record)}
                        >
                            <AiOutlineEdit/>
                        </a>
                    ),
                }
            ]

            setTableColumn(editPermission ? columnWithAction : column)
        })

    }, [
        userTablePreference?.getUserTableSetting?.tableColumn,
        adminSettingTable?.getUserTableAdminSetting[0]?.fieldData,
        columns,
    ]);

    const onChange = (pagination, filters, sorter, extra) => {
        const newFilters = {};
        for (const [key, value] of Object.entries(filters)) {
            const [...val] = isArray(value) ? value : ""
            if (isArray(val)) {
                if (val[0] === "true") {
                    newFilters[key] = true
                } else if (val[0] === "false") {
                    newFilters[key] = false
                } else if (val[0] === "string") {
                    newFilters[key] = val?.length > 0 ? val[1] : undefined;
                } else {
                    newFilters[key] = val?.length > 0 ? val : undefined;
                }
            } else {
                newFilters[key] = val ? val[0] : undefined;
            }
        }

        setFilter({
            ...newFilters,
            serverName: server?.name,
        });

        if (sorter.order === "ascend") {
            sorter?.column?.dataIndex === "baseSymbolId"
                ? setOrderByBase(`${sorter?.column?.dataIndex}_ASC`)
                : setOrderBy(`${sorter?.column?.dataIndex}_ASC`);
        } else if (sorter.order === "descend") {
            sorter?.column?.dataIndex === "baseSymbolId"
                ? setOrderByBase(`${sorter?.column?.dataIndex}_DSC`)
                : setOrderBy(`${sorter?.column?.dataIndex}_DSC`);
        } else {
            setOrderBy("");
            setOrderByBase("")
        }
    };

    const openDetailModal = (record) => {
        setDetailSymbolId({base_symbol_id: record})
        setIsOpenDetailModal(true)
    }

    const closeDetailModal = () => {
        setDetailSymbolId({base_symbol_id: "undefined"})
        setIsOpenDetailModal(false)
    }

    return (
        tableColumn && (
            <div className={"margin-top-0-75"}>
                {detailInstrument &&
                <InstrumentDetailModal_1
                    item={detailInstrument?.[0]}
                    isOpen={isOpenDetailModal}
                    closeModal={closeDetailModal}
                />}
                <SymbolsMappingModal_1
                    item={selectedItem}
                    server={server}
                    isOpen={editSymbolMappingModal}
                    setOpen={setEditSymbolMappingModal}
                    closeModal={closeEditModal}
                    data={instrumentList}
                    setFilter={setInstrumentFilter}
                    setOffset={setInstrumentOffset}
                    limit={instrumentLimit}
                    setLimit={setInstrumentLimit}
                    setOrderBy={setInstrumentOrderBy}
                    loading={instrumentLoading}
                    totalCount={instrumentCount}
                    updateSymbolMapping={updateSymbolMapping}
                />
                <ExcelMappingModalModal_1
                    isOpen={showExcelModal}
                    closeModal={() => setShowExcelModal(false)}
                    server={serverList}
                />
                <Table
                    dataSource={mappedData}
                    columns={tableColumn}
                    rowKey={(record) => record.id || record?.base_symbol_id}
                    size={"small"}
                    bordered={false}
                    loading={(server?.platform === "mt5" || server?.platform === "xTrader") ? mt5Loading : loading}
                    onChange={onChange}
                    pagination={{
                        pageSize: limit,
                        showSizeChanger: true,
                        onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                        total: filterSymbolMapping?.baseSymbolId
                            ? symbolMappingCount?.getSymbolMappingCount
                            : totalCount?.getSymbolCount,
                        showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                    }}
                />
            </div>
        )
    );
};

export default SymbolMappingTable_1;
