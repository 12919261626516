import React from "react";
import {Tooltip} from "antd";

const OptionalTooltipCard = ({title, children}) => {
    if (title) {
        return <Tooltip title={title}>{children}</Tooltip>;
    }
    return children;
}

export default OptionalTooltipCard