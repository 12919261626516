import React, {useCallback, useEffect, useMemo, useState} from "react";
import TableAntDesign from "../../../../../../../components/table/tableAntDesign";
import {columnsCT} from "../../../function/tableColumn_CT";
import {getSymbol, getSymbolCount} from "../../../../../../../api/graphql/symbol";
import {Col, Row} from "antd";
import {SymbolSider} from "./symbolSider";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {getAssetClass} from "../../../../../../../api/graphql/assetClass";

function SymbolsTable_1(props) {
    const {
        server,
        setExportData,
        setExportColumn,
        tableName,
        currentServer,
        setExportLoading,
    } = props;
    const {t} = useTranslation();
    const [orderBy, setOrderBy] = useState("");
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [filter, setFilter] = useState({serverName: server?.name});
    const [selectedSymbol, setSelectedSymbol] = useState(0);
    const [sideBarOpen, setSideBarOpen] = useState(false);

    const {data: initialAssetClass} = getAssetClass({filter: {serverName: ""}});
    const assetClassData = initialAssetClass?.getAssetClass?.data
    const {data: totalCount} = getSymbolCount({filter});
    const {data, loading, error} = getSymbol(
        {filter: filter},
        limit,
        offset,
        orderBy
    );
    const {data: exportData, loading: exportLoading} = getSymbol({filter: filter});
    const columns = useMemo(() => (server?.platform === "cTrader" ? columnsCT : []), [server?.platform]);

    const [initialPriceData, setInitialPriceData] = useState<any>([]);
    const [socketData, setSocketData] = useState<any>([]);
    const [initialData, setInitialData] = useState<any>([]);
    const [dataSource, setDataSource] = useState<any>(data?.getSymbol?.data);

    const [socket, setSocket] = useState<any>(null);

    const isJson = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return JSON.parse(str);
    }

    const setUpData = useCallback(() => {
        if (server?.platform === "cTrader" && server?.mode?.[0] === "live") {
            const currentURL = server?.plantId === "blueocean" ? "socket2" : "socket1";
            const currentServer = server?.plantId === "blueocean" ? "server2" : "server1";
            const apiUrl = `https://ctrader.nextrades.ai/api/rest/spreadGroupSymbolPrice?serverName=${currentServer}`;

            fetch(apiUrl)
                .then((response) => response?.json())
                .then((data) => setInitialPriceData(data?.payload))
                .catch((error) => console.error("Error fetching data:", error));

            const newSocket = new WebSocket(`wss://${currentURL}.nextrades.ai`);
            newSocket?.addEventListener("message", (event) => {
                const rawData = event?.data;
                const receivedData:any = isJson(rawData) || {};

                setSocketData((prevData) => {
                    const existingPositionIndex = prevData?.findIndex(
                        (position) => position?.positionId === receivedData?.positionId
                    );

                    if (existingPositionIndex !== -1) {
                        const updatedPositions = [...prevData];
                        updatedPositions[existingPositionIndex] = receivedData;
                        return updatedPositions;
                    } else {
                        return [...prevData, receivedData];
                    }
                });
            });

            setSocket(newSocket);

            return () => {
                newSocket?.close();
            };
        }
    }, [server]);

    useEffect(() => {
        setUpData();
    }, [server]);

    useEffect(() => {
        setExportLoading(exportLoading);
    }, [exportLoading]);

    useEffect(() => {
        if (exportData?.getSymbol?.data?.length > 0 && columns?.length > 0) {
            setExportColumn(columns);
            formatInitialData(exportData?.getSymbol?.data).then((data) => {
                setExportData(data)
            })
        }
    }, [exportData?.getSymbol?.data, server?.name, currentServer?.name, columns, setExportColumn, setExportData]);

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize);
        setOffset(limit * (page - 1));
    };

    const onRow = (record, rowIndex) => {
        return {
            onClick: () => {
                setSelectedSymbol(record);
                setSideBarOpen(true);
            },
        };
    };

    useEffect(() => {
        if (data?.getSymbol?.data)
            formatInitialData(data?.getSymbol?.data).then((data) => {
                setInitialData(data);
                setSocketData(data);
                setDataSource(data);
            });
    }, [loading, initialPriceData, data?.getSymbol?.data]);

    useEffect(() => {
        formatData(initialData).then((data) => setDataSource(data));
    }, [socketData, initialData]);

    const formatInitialData = async (data) => {
        return await data?.map((d) => {
            const currentInitialData = initialPriceData?.find(
                (data) => data?.symbolId === d?.symbolId
            );

            return {
                ...d,
                ask: !isNaN(currentInitialData?.ask) ? currentInitialData?.ask : "-",
                bid: !isNaN(currentInitialData?.bid) ? currentInitialData?.bid : "-",
                spread: !isNaN(currentInitialData?.spreadInPoints) ? currentInitialData?.spreadInPoints : "-",
                quoteTime: "N/A",
                assetClass: assetClassData?.find((obj) => obj?.assetClassId === d?.symbolCategoryId?.[0]?.assetClassId)?.name,
                defaultDividendTime: d?.defaultDividendTime ? moment(d?.defaultDividendTime)?.format("YYYY-MM-DD HH:mm:ss") : "",
            };
        });
    };

    const formatData = async (data) => {
        return await data?.map((d) => {
            const currentSocketData = socketData?.find(
                (data) => data?.symbolId === d?.symbolId
            );
            return {
                ...d,
                ask: currentSocketData?.ask,
                bid: currentSocketData?.bid,
                spread: currentSocketData?.spread,
                quoteTime: "N/A",
            };
        });
    };

    return (
        <Row gutter={[16, 16]} className={"margin-top-0-75"}>
            <Col span={24}>
                <TableAntDesign
                    data={dataSource}
                    tableName={tableName}
                    columns={columns}
                    size={"small"}
                    loading={loading}
                    filter={setFilter}
                    server={server?.name}
                    order={setOrderBy}
                    onRow={onRow}
                    pagination={{
                        pageSize: limit,
                        showSizeChanger: true,
                        onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                        total: totalCount?.getSymbolCount,
                        showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
                    }}
                />
            </Col>
            <Col span={24}>{sideBarOpen && <SymbolSider initialData={selectedSymbol}/>}</Col>
        </Row>
    );
}

export default SymbolsTable_1;
