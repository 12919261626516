import React, {useEffect, useState} from "react";
import {Form, Input, Modal} from "antd";
import {useTranslation} from "react-i18next";
import {updateUserPassword} from "../../../../../api/graphql/_common";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../recoil_state";
import useAuthorize from "../../../../../_common/authorize";

const ChangePasswordModal = ({isOpen, setIsOpen}) => {
    const {t} = useTranslation()
    const [form] = Form.useForm()
    const labelCol = {span: 24}
    const [loading, setLoading] = useState(false)
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {baseErrorChecking, systemErrorNotification} = useAuthorize()

    const changePasswordFunction = async (val) => {
        try {
            setLoading(true)
            if (/\s/.test(val?.password)) {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t("errorMsg.password.containBlackSpace"),
                    key: "profileNotification",
                });
            } else if (val?.password === val?.confirmPassword) {
                delete val["confirmPassword"]
                const response = await updateUserPassword(localStorage.getItem("userId"), val)
                if (response?.updateUserPassword?.__typename !== "User") {
                    await baseErrorChecking(response?.updateUserPassword)
                    setLoading(false)
                    setIsOpen(false)
                } else {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: t("changePassword.passwordUpdated"),
                        key: "changePassword",
                    });
                }

                setLoading(false)
                setIsOpen(false)
            } else {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t("common.passwordMismatch"),
                    key: "changePassword",
                });
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    useEffect(() => {
        form.resetFields()
    }, [isOpen])

    return (
        <Form onFinish={changePasswordFunction} form={form} labelCol={labelCol}>
            <Modal
                title={t(`common.changePassword`)}
                open={isOpen}
                onCancel={() => setIsOpen(false)}
                width={500}
                cancelText={t("common.cancel")}
                okText={t("common.submit")}
                onOk={() => form.submit()}
                okButtonProps={{loading: loading}}
                getContainer={"#root"}
            >
                <Form.Item
                    label={t("changePassword.currentPassword")}
                    name={"existingPassword"}
                    rules={[{required: true, message: t("errorMsg.required.pleaseFillIn", {field: t("changePassword.currentPassword")}).toString()}]}
                >
                    <Input.Password/>
                </Form.Item>
                <Form.Item
                    label={t("changePassword.newPassword")}
                    name={"password"}
                    rules={[{required: true, message: t("errorMsg.required.pleaseFillIn", {field: t("changePassword.newPassword")}).toString()}]}
                >
                    <Input.Password/>
                </Form.Item>
                <Form.Item
                    label={t("changePassword.confirmPassword")}
                    name={"confirmPassword"}
                    rules={[{required: true, message: t("errorMsg.required.pleaseFillIn", {field: t("changePassword.confirmPassword")}).toString()}]}
                >
                    <Input.Password/>
                </Form.Item>
            </Modal>
        </Form>
    )
}

export default ChangePasswordModal