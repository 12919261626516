import React, {useState} from "react";
import {useSetRecoilState} from "recoil";
import {useTranslation} from "react-i18next";
import {Col, DatePicker, Form, Modal, Row} from "antd";
import dayjs from "dayjs";
import {notificationMessage} from "../../../../../../../recoil_state";
import {repopulateReportData} from "../../../../../../api/graphql/reporting";

const RepopulateReportModal = (props) => {
    const {isModalOpen, toggleModal, reportSummaryData, getAllReports} = props;
    const {t} = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const [fromDate, setFromDate] = useState<any>();
    const [toDate, setToDate] = useState<any>();
    const [isRequesting, setIsRequesting] = useState(false);

    const onSubmit = async () => {
        try {
            setIsRequesting(true);
            if (!fromDate || !toDate) {
                return setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t("admin.err.fill_in_required_field"),
                    key: "repopulateReport",
                });
            }
            if (fromDate.isAfter(toDate)) {
                return setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: "Start Date cannot be after End Date",
                    key: "repopulateReport",
                });
            } else {
                let input = {
                    fromDate: fromDate?.format("YYYY-MM-DD"),
                    toDate: toDate?.format("YYYY-MM-DD"),
                    populationPath: reportSummaryData?.populationPath,
                };
                const response = await repopulateReportData(input);
                if (response?.repopulateReportData?.data?.statusCode === 200) {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: "Repopulating. Please wait for a few minutes for the report to be updated.",
                        key: "repopulateReport",
                    });
                    getAllReports();
                    toggleModal();
                }
            }
        } catch (error) {
        } finally {
            setIsRequesting(false);
        }
    };

    return (
        <Modal
            title={"Repopulate Report"}
            open={isModalOpen}
            width={"80%"}
            // footer={null}
            destroyOnClose
            getContainer="#reports"
            className="repopulateReportModal"
            maskClosable={false}
            onCancel={toggleModal}
            onOk={onSubmit}
            confirmLoading={isRequesting}
        >
            <Row>
                <Col xs={24} md={24}>
                    <Form.Item
                        name={"fromDate"}
                        label={t("user.startDate")}
                        labelCol={{span: 10, offset: 0}}
                        initialValue={dayjs()}
                        labelAlign={"left"}
                        required
                    >
                        <DatePicker
                            style={{width: "100%"}}
                            disabledDate={(current) => {
                                return (
                                    (current && current.isBefore(dayjs("2020-01-01").startOf("day"))) || (current && current > dayjs().endOf("day"))
                                );
                            }}
                            onChange={(value) => setFromDate(value)}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={24}>
                    <Form.Item
                        name={"toDate"}
                        label={t("user.endDate")}
                        labelCol={{span: 10, offset: 0}}
                        initialValue={dayjs()}
                        labelAlign={"left"}
                        required
                    >
                        <DatePicker
                            style={{width: "100%"}}
                            disabledDate={(current) => {
                                return (
                                    (current && current.isBefore(dayjs("2020-01-01").startOf("day"))) || (current && current > dayjs().endOf("day"))
                                );
                            }}
                            onChange={(value) => setToDate(value)}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Modal>
    );
};

export default RepopulateReportModal;
