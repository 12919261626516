import React, {useEffect, useState} from "react";
import Cron_1 from "./view/layout1";
import {getCron} from "../../../api/graphql/cron";
import {useGetCron} from "./function/useQueryHooks";

function CronPage() {
    let layout = 1;
    let component;

    const [limit, setLimit] = useState(20)
    const [offset, setOffset] = useState(0)
    const [filter, setFilter] = useState({})
    const [order, setOrder] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [dateKey, setDateKey] = useState(null)

    const [cronData, cronTotal, cronLoading, fetchCronData, refetchCronData] = useGetCron()

    useEffect(()=>{
        fetchCronData(filter,limit,offset,order)
    },[filter,limit,offset,order])

    switch (layout) {
        case 1:
            component = <Cron_1
                data={cronData}
                exportData={cronData}
                total={cronTotal}
                limit={limit}
                setLimit={setLimit}
                setOffset={setOffset}
                setOrder={setOrder}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setDateKey={setDateKey}
                setFilter={setFilter}
                refetchCronData={refetchCronData}
            />;
            break;

        default:
            component = <Cron_1
                data={cronData}
                exportData={cronData}
                total={cronTotal}
                limit={limit}
                setLimit={setLimit}
                setOffset={setOffset}
                setOrder={setOrder}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setDateKey={setDateKey}
                setFilter={setFilter}
                refetchCronData={refetchCronData}
            />;
            break;
    }

    return component;
}

export default CronPage