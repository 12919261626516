import {getUserVerificationRecordDocumentIds} from "../../../api/graphql/userVerification";
import {useSetRecoilState} from "recoil";
import {userVerificationRecordDocumentIdsState} from "../state";
import useAuthorize from "../../../_common/authorize";

export function formatMoney(amount: any){
    let amountParse = parseFloat(amount)
    return (amountParse)?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export const useTaskFunction = () =>{
    const setRecordDocumentIds = useSetRecoilState(userVerificationRecordDocumentIdsState)
    const {systemErrorNotification} = useAuthorize()

    const getUserVerificationRecordDocumentIdsFunction = async (input) => {
        try {
            const response: any = await getUserVerificationRecordDocumentIds(input);
            if (response?.getUserVerificationRecordDocumentIds?.data) {
                const data = response?.getUserVerificationRecordDocumentIds?.data;
                setRecordDocumentIds(data);
            }
        } catch (e) {
            systemErrorNotification()
        }
    };

    return { getUserVerificationRecordDocumentIdsFunction }
}