import React, {useEffect, useState} from "react";
import {Col, Row, Select, Space, Spin, Tabs} from "antd";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import "../layout1/style.css";
import TemplatePreview from "./component/templatePreview";
import TemplateForm from "./component/templateForm";
import ButtonAntDesign from "../../../../../components/button/buttonAntDesign";
import GrapeJSEditor from "./component/GrapeJSEditor";

const PushNotificationTemplateEditor_1 = (props) => {
    const {mode, data, loading, imageVarData, textVarData, pdfVarData, languageData, handleTemplateUpdate} = props;
    const {t} = useTranslation();
    const [deviceType, setDeviceType] = useState("android");
    const [activeTab, setActiveTab] = useState("en");
    const [notificationData, setNotificationData] = useState<any>({});
    const [notificationDataObject, setNotificationDataObject] = useState([]);

    const fetchData = async () => {
        try {
            const dataArray = await data;
            setNotificationData(dataArray?.getPushNotificationTemplateById);
            setNotificationDataObject(dataArray?.getPushNotificationTemplateById?.dataArrayOfObject)
        } catch (e) {
            console.error(e);
        }
    };

    const handleDeviceTypeChg = (value: string) => {
        setDeviceType(value);
    };

    const onTabChange = (key: string) => {
        setActiveTab(key);
    };
    const handleSave = () => {
        let input = {
            // ...notificationData,
            dataArrayOfObject: notificationDataObject,
        };
        handleTemplateUpdate(input);
    };

    useEffect(() => {
        fetchData();
    }, [data]);

    const tabItems = languageData?.map((lang, index) => {
        let notificationDataLanguage: any = notificationDataObject?.find((v: any) => v?.language === lang?.code);
        return {
            key: lang?.code,
            label: lang?.name,
            children: (
                <Row gutter={[16, 24]}>
                    <Col xs={24} md={12}>
                        <TemplateForm
                            mode={mode}
                            item={notificationDataLanguage?.notification}
                            languageCode={lang?.code}
                            textVarData={textVarData}
                            imageVarData={imageVarData}
                            setNotificationDataObject={setNotificationDataObject}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <Row justify="center">
                            <Col
                                span={24}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    margin: "1rem",
                                }}
                            >
                                <Select
                                    // showSearch
                                    value={deviceType}
                                    onChange={handleDeviceTypeChg}
                                    placeholder={t("Component Type")}
                                    options={[
                                        {label: t("Android"), value: "android"},
                                        {label: t("iPhone"), value: "iphone"},
                                        {label: t("Windows"), value: "windows"},
                                        {label: t("MacOS"), value: "macos"},
                                    ]}
                                    style={{width: 150}}
                                />
                            </Col>
                            <Col span={24}>
                                <TemplatePreview
                                    notificationData={notificationDataLanguage}
                                    deviceType={deviceType}
                                    languageCode={lang?.code}
                                    imageVarData={imageVarData}
                                    textVarData={textVarData}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24}>
                        <h1>
                            {t("Push Notification Long Content")} - {notificationData?.name}
                        </h1>
                        <GrapeJSEditor
                            languageCode={lang.code}
                            data={notificationData}
                            imageVarData={imageVarData}
                            textVarData={textVarData}
                            pdfVarData={pdfVarData}
                            setNotificationDataObject={setNotificationDataObject}
                            // handleEmailTemplateUpdate
                        />
                    </Col>
                </Row>
            ),
        };
    });

    return (
        <div id="pushNotificationTemplateEditor">
            <Row>
                <Col span={16}>
                    <h1>
                        {t("Push Notification Template Editor")} - {notificationData?.name}
                    </h1>
                </Col>
                <Col span={8} className="backLinkContainer">
                    <Link to="/settings/push-notification-template" className="text-link">
                        {t("Back to Push Notification Template")}
                    </Link>
                </Col>
            </Row>
            <Spin tip="Loading..." spinning={loading}>
                <Tabs
                    defaultActiveKey="en"
                    items={tabItems}
                    onChange={onTabChange}
                    tabBarExtraContent={
                        <Space>
                            {mode !== "view" &&
                            <ButtonAntDesign
                                disabled={loading}
                                text={t("common.save")}
                                onClick={handleSave}
                                type="primary"/>
                            }
                        </Space>
                    }
                />
            </Spin>
        </div>
    );
};

export default PushNotificationTemplateEditor_1;
