import moment from "moment";
import {Checkbox, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select, Space} from "antd";
import React from "react";
import dayjs from "dayjs";
import {getColumnSearchProps, getSingleSelectFilterProps} from "../../../components/table/function/filterTable";
import {SyncOutlined, WarningTwoTone} from "@ant-design/icons";
import {ApolloClientQuery} from "../../../api/graphql/_apolloClient";
import {GET_USER_BY_ID} from "../../../api/graphql/user";
import {GET_DESK_DROPDOWN, GET_MULTI_TIER_SETTING_DROPDOWN, GET_ROLE_TEMPLATE_DROPDOWN} from "../../../api/graphql/_common";
import {formatDateUTC} from "../../../function/_common";

const formatDate = (date) => {
    const timezone = localStorage.getItem("timezone");
    let formattedDate = date ? moment(date)?.format("YYYY-MM-DD") : "";
    if (timezone) {
        formattedDate = moment(date)?.utcOffset(+timezone).format("YYYY-MM-DD");
    }
    return formattedDate;
};

const formatDateTime = (date) => {
    const timezone = localStorage.getItem("timezone");
    let formattedDate = date ? moment(date)?.format("YYYY-MM-DD HH:mm:ss") : "";
    if (timezone) {
        formattedDate = moment(date)?.utcOffset(+timezone).format("YYYY-MM-DD HH:mm:ss");
    }
    return formattedDate;
};

const formatDateTimeMinute = (date) => {
    const timezone = localStorage.getItem("timezone");
    let formattedDate = date ? moment(date)?.format("YYYY-MM-DD HH:mm") : "";
    if (timezone) {
        formattedDate = moment(date)?.utcOffset(+timezone).format("YYYY-MM-DD HH:mm");
    }
    return formattedDate;
};

const disableDateTime = () => {
    const range = (start: number, end: number) => {
        const result: any = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    };

    const disabledDate = (current) => {
        return current && current < dayjs().startOf("day");
    };

    const disabledTime = (_, type) => {
        // console.log(type)
        // if (type === 'start') {
        return {
            disabledHours: () => range(0, dayjs().hour()),
            disabledMinutes: () => range(0, dayjs().minute()),
        };
        // }

        // return {
        //     disabledHours: () => [],
        //     disabledMinutes: () => [],
        //     disabledSeconds: () => [],
        // };
    };

    return {disabledDate, disabledTime};
};

const hideSensitiveData = (value) => {
    try {
        if (value?.length < 3) return "****";
        else if (value?.length > 2 && value?.length < 8) return `${value?.slice(0, 1)}*****${value?.slice(-1)}`;
        else return `${value?.slice(0, 1)}*****${value?.slice(-3)}`;
    } catch (error) {
    }
};

const formatProfileInformation = async (fields, setFields, list) => {
    try {
        if (list?.length > 0 && list?.length < fields?.length) {
            const mutableArray: any = Array?.from(list);
            const sortedArray = await mutableArray?.sort((a, b) => a.position - b.position);
            setFields(sortedArray);

            const deletedField = fields?.find((obj) => {
                return !list.some((deletedObj) => deletedObj._id === obj?._id);
            });

            const category = deletedField?.fieldList?.category;
            if (category?.includes("basicInformation")) {
                await setFields((prevState) => ({
                    ...prevState,
                    basicField: prevState.basicField?.filter((item) => item?._id !== deletedField._id),
                }));
            } else if (category.includes("loginInformation")) {
                await setFields((prevState) => ({
                    ...prevState,
                    loginField: prevState.loginField.filter((item) => item?._id !== deletedField._id),
                }));
            } else if (category.includes("residentialInformation")) {
                await setFields((prevState) => ({
                    ...prevState,
                    residenceField: prevState.residenceField.filter((item) => item?._id !== deletedField._id),
                }));
            } else if (category.includes("additionalInformation")) {
                await setFields((prevState) => ({
                    ...prevState,
                    additionalField: prevState.additionalField.filter((item) => item?._id !== deletedField._id),
                }));
            } else if (category.includes("corporateInformation")) {
                await setFields((prevState) => ({
                    ...prevState,
                    corporateField: prevState.corporateField.filter((item) => item?._id !== deletedField._id),
                }));
            } else if (category.includes("registrationInformation")) {
                await setFields((prevState) => ({
                    ...prevState,
                    registrationField: prevState.registrationField.filter((item) => item?._id !== deletedField._id),
                }));
            } else if (category.includes("demoInformation")) {
                await setFields((prevState) => ({
                    ...prevState,
                    demoField: prevState.demoField.filter((item) => item?._id !== deletedField._id),
                }));
            }
        } else if (list?.length > 0) {
            const mutableArray: any = Array.from(list);
            const sortedArray = await mutableArray.sort((a, b) => a.position - b.position);
            setFields(sortedArray);

            const categorizedFields: any = {
                basicField: [],
                loginField: [],
                residenceField: [],
                additionalField: [],
                corporateField: [],
                registrationField: [],
                demoField: [],
            };

            mutableArray?.forEach((item: any) => {
                const category = item?.fieldList?.category;

                if (category === "basicInformation") {
                    if (!categorizedFields?.basicField?.some((existingItem) => existingItem?._id === item?._id)) {
                        categorizedFields?.basicField?.push(item);
                    }
                } else if (category === "loginInformation") {
                    if (!categorizedFields?.loginField?.some((existingItem) => existingItem?._id === item?._id)) {
                        categorizedFields?.loginField?.push(item);
                    }
                } else if (category === "residentialInformation") {
                    if (!categorizedFields?.residenceField?.some((existingItem) => existingItem?._id === item?._id)) {
                        categorizedFields?.residenceField?.push(item);
                    }
                } else if (category === "additionalInformation") {
                    if (!categorizedFields?.additionalField?.some((existingItem) => existingItem?._id === item?._id)) {
                        categorizedFields?.additionalField?.push(item);
                    }
                } else if (category === "corporateInformation") {
                    if (!categorizedFields?.corporateField?.some((existingItem) => existingItem?._id === item?._id)) {
                        categorizedFields?.corporateField?.push(item);
                    }
                } else if (category === "registrationInformation") {
                    if (!categorizedFields?.registrationField?.some((existingItem) => existingItem?._id === item?._id)) {
                        categorizedFields?.registrationField?.push(item);
                    }
                } else if (category === "demoInformation") {
                    if (!categorizedFields?.demoField?.some((existingItem) => existingItem?._id === item?._id)) {
                        categorizedFields?.demoField?.push(item);
                    }
                }
            });

            categorizedFields &&
            Object.keys(categorizedFields)?.forEach((category) => {
                categorizedFields[category]?.sort((a, b) => a?.position - b?.position);
            });

            return await setFields(categorizedFields);
        } else
            return await setFields({
                basicField: [],
                loginField: [],
                residenceField: [],
                additionalField: [],
                corporateField: [],
                registrationField: [],
                demoField: [],
            });
    } catch (error) {
    }
};

const renderProfileInformation = (fieldList, data, t, editable, isReadSensitive = true, form?, isLead = false) => {
    try {
        const isKycApproved = data?.userType === "client" && data?.isKycApproved;
        return fieldList?.map((d) => {
            const nonUpdatableFields = ["tag", "leadSource", "age", "affiliateCode"];
            const field = d?.fieldList;
            const isRequired =
                data?.userType === "client"
                    ? (isKycApproved || field?.category === "loginInformation") && field?.fieldType !== "password"
                        ? field?.isRequired
                        : false
                    : (field?.fieldType !== "password" || !data?.username) && !nonUpdatableFields.includes(field?.fieldName)
                        ? field?.isRequired
                        : false;

            const countryCode = field?.category === "corporateInformation" ? "corporateCountryCode" : "countryCode"
            const mobileNo = field?.category === "corporateInformation" ? "corporateMobileNo" : "mobileNo"

            //Sensitive
            let defaultValue = data?.[field?.fieldName];
            if (field?.fieldName === "dateOfBirth") {
                defaultValue = !data?.[field?.fieldName]
                    ? null
                    : dayjs(data?.[field?.fieldName])?.utc()?.format("YYYY-MM-DD") + "T12:00:00+00:00";
            }
            if (field?.isSensitive && defaultValue && !isReadSensitive) {
                defaultValue = hideSensitiveData(defaultValue);
            }

            const shuffleString = (str) => {
                const array = str?.split("");
                for (let i = array?.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [array[i], array[j]] = [array[j], array[i]];
                }
                return array?.join("");
            };

            const generateRandomPassword = async (length) => {
                const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
                const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
                const numbers = "0123456789";

                let password = "";

                password += uppercaseLetters[Math.floor(Math.random() * uppercaseLetters?.length)];
                password += numbers[Math.floor(Math.random() * numbers?.length)];

                const remainingLength = length - 3;
                const allCharacters = lowercaseLetters + uppercaseLetters + numbers;
                for (let i = 0; i < remainingLength; i++) {
                    password += allCharacters[Math.floor(Math.random() * allCharacters?.length)];
                }

                return shuffleString(password);
            };

            const fieldTypeComponents = {
                password: <Input.Password disabled={editable} autoComplete={"new-password"}/>,
                text: <Input maxLength={field?.maxCharacterLimit || null} showCount={field?.maxCharacterLimit && !editable} autoComplete={"off"}
                             disabled={editable || (!isLead && nonUpdatableFields?.includes(field?.fieldName))}/>,
                date:
                    field?.fieldName === "dateOfBirth" ? (
                        <DatePicker
                            disabled={editable}
                            style={{width: "100%"}}
                            disabledDate={(current) => {
                                return current && current.isAfter(dayjs().subtract(18, "years"));
                            }}
                            showToday={false}
                            defaultPickerValue={dayjs().subtract(18, "years")}
                            format="YYYY-MM-DD"
                        />
                    ) : (
                        <DatePicker
                            disabled={editable}
                            style={{width: "100%"}}
                            defaultValue={data?.[field?.fieldName] ? formatDateUTC(data?.[field?.fieldName], "formValue") : undefined}
                        />
                    ),
                dropdown: (
                    <Select
                        showSearch
                        optionFilterProp={"label"}
                        disabled={editable}
                        options={field?.optionValue?.map((d) => {
                            return {
                                ...d,
                                label: t(d?.label),
                            };
                        })}
                    />
                ),
                textarea: <Input.TextArea maxLength={field?.maxCharacterLimit || null} showCount={field?.maxCharacterLimit && !editable} disabled={editable}/>,
                radioButton: (
                    <Radio.Group disabled={editable}>
                        {field?.optionValue?.map((option, idx) => (
                            <Radio.Button key={option?.value} id={idx} value={option?.value}>
                                {t(option?.label)}
                            </Radio.Button>
                        ))}
                    </Radio.Group>
                ),
                checkbox: <Checkbox disabled={editable}/>,
                checkboxGroup: <Checkbox.Group options={field?.optionValue} disabled={editable}/>,
                mobile: (
                    <Space.Compact style={{width: "100%"}}>
                        <Form.Item
                            name={countryCode}
                            initialValue={
                                field?.isSensitive && defaultValue && !isReadSensitive ? hideSensitiveData(data?.[countryCode]) : data?.[countryCode]
                            }
                        >
                            <Select
                                options={field?.optionValue?.map((d) => {
                                    return {
                                        ...d,
                                        label: t(d?.label),
                                    };
                                })}
                                disabled={editable}
                                style={{width: 80}}
                                dropdownMatchSelectWidth={200}
                                optionFilterProp={"label"}
                                optionLabelProp={"value"}
                                showSearch
                            />
                        </Form.Item>
                        <Form.Item
                            name={mobileNo}
                            style={{width: "100%"}}
                            initialValue={field?.isSensitive && defaultValue && !isReadSensitive ? hideSensitiveData(data?.[mobileNo]) : data?.[mobileNo]}
                        >
                            <InputNumber disabled={editable} controls={false} style={{width: "100%", height: 26}}/>
                        </Form.Item>
                    </Space.Compact>
                ),
            };

            return (
                <Col xs={24} md={12} key={field?.fieldName?.replace(/\s+/g, "")}>
                    <Form.Item
                        name={field?.fieldName === "mobileNo" ? "combinedMobileNo" : field?.fieldName?.replace(/\s+/g, "")}
                        label={t(field?.label)}
                        required={isRequired}
                        initialValue={field?.fieldType !== "date" ? defaultValue : defaultValue ? formatDateUTC(defaultValue, "formValue") : undefined}
                        labelCol={{span: 10, offset: 0}}
                        labelAlign="left"
                        rules={[
                            {
                                required: isRequired,
                                ...(field?.fieldName?.includes("email") && {type: "email"}),
                                ...(field?.fieldName === "username" && {
                                    validator: (rule, value, callback) => {
                                        if (!value) {
                                            callback(t("errorMsg.required.requiredField", {field: t(field?.label)}));
                                        } else if (!/^[a-zA-Z0-9!@#$%^&*()-_=+[\]{}|;:'",.<>/?\\]*$/.test(value)) {
                                            callback(t("errMsg.usernameValidate"));
                                        } else {
                                            callback();
                                        }
                                    },
                                }),
                                ...(
                                    field?.minCharacterLimit && {
                                        min: field?.minCharacterLimit,
                                        message: t("errMsg.minimum_characters_required", {length: field?.minCharacterLimit})
                                    }
                                )
                            },
                        ]}
                        colon={false}
                    >
                        {fieldTypeComponents[field?.fieldType]}
                    </Form.Item>
                    {field?.fieldName === "password" && form && !editable && (
                        <Row justify={"end"}>
                            <a
                                onClick={async () => {
                                    const newPassword = await generateRandomPassword(8);
                                    form.setFieldsValue({[field?.fieldName]: newPassword});
                                }}
                            >
                                {t("common.generatePassword")} <SyncOutlined/>
                            </a>
                        </Row>
                    )}
                </Col>
            );
        });
    } catch (error) {
    }
};

const formatSensitiveFields = (data, fields) => {
    try {
        const sensitiveField = fields?.filter((item) => item?.fieldList?.isSensitive)?.map((d) => d?.fieldList?.fieldName);

        if (sensitiveField.includes("mobileNo")) sensitiveField.push("completedMobileNo")

        return data?.map((item) => {
            const formattedItem = {...item};

            sensitiveField.forEach((field) => {
                if (formattedItem[field]) {
                    formattedItem[field] = hideSensitiveData(formattedItem[field]);
                }
            });

            return formattedItem;
        });
    } catch (error) {
    }
};

const validateConfirmKeys = async (dataObject, setNotificationMessage, t) => {
    for (const key in dataObject) {
        if (key.endsWith("_confirm")) {
            const originalKey = key.replace("_confirm", "");
            if (dataObject[originalKey] !== dataObject[key]) {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t("errorMsg.confirm.notMatch", {label: t(`user.${originalKey}`)}),
                    key: "confirmNotMatch",
                });
                return false;
            } else {
                delete dataObject[key];
            }
        }
    }

    return true;
};

const renderColumns = (fields, setColumn, extraColumns, type, t, autoBindType: any = undefined) => {
    try {
        let columnAry: any = [];
        const sortedField = fields?.slice()?.sort((a, b) => a.position - b.position);
        const loginField = sortedField?.filter((item) => item?.fieldList?.isUsedForLogin === true)?.map((d) => d?.fieldList?.fieldName);
        const fixedColumnWidth = 50

        sortedField &&
        sortedField?.map((d) => {
            const fieldList = d?.fieldList;
            const label = autoBindType ? `${t(fieldList?.label)} (${t(`user.${autoBindType.toLowerCase()}`)})` : t(fieldList?.label);
            const dataIndex = autoBindType === "Client" ? `match_${fieldList?.fieldName}` : fieldList?.fieldName;
            let column;
            if ((loginField && loginField?.includes(fieldList?.fieldName)) || fieldList?.fieldName === "username" || (type === "lead" && fieldList?.fieldName === "fullName")) {
                column = [
                    {
                        title: label,
                        dataIndex: dataIndex,
                        key: dataIndex,
                        ellipsis: fieldList?.columnWidth > 0,
                        width: fieldList?.columnWidth > 0 ? fixedColumnWidth * fieldList?.columnWidth : "auto",
                        textWrap: 'word-break',
                        ...getColumnSearchProps(),
                        render: (text, record) => {
                            let value;
                            if (autoBindType && autoBindType === "Client") value = record?.match?.[fieldList.fieldName];
                            const userId = (type === "client" || type === "crm-user") ? record?.parentId : (record?.match?._id || record._id)

                            return (
                                <>
                                    <a style={{width: fieldList?.columnWidth > 0 ? (fixedColumnWidth * fieldList?.columnWidth) - 12 : "100%",}}
                                       onClick={() => window.open(`/users/${type}/detail?id=${userId}`, "_blank")}>
                                        {value || text || "-"}
                                    </a>
                                    {type === "client" &&
                                        (record?.isSuspicious === true || record?.match?.isSuspicious) &&
                                        fieldList?.fieldName === "username" && (
                                            <WarningTwoTone twoToneColor={"#FFCC00"} style={{marginLeft: 4}}/>
                                        )}
                                </>
                            );
                        },
                    },
                ];
            } else if (fieldList?.fieldType === "date") {
                column = [
                    {
                        title: label,
                        dataIndex: dataIndex,
                        key: dataIndex,
                        ellipsis: fieldList?.columnWidth > 0,
                        width: fieldList?.columnWidth > 0 ? fixedColumnWidth * fieldList?.columnWidth : "auto",
                        textWrap: 'word-break',
                        // ...getDateRangeFilterProps(),
                        render: (text, record) => {
                            let value;
                            if (autoBindType && autoBindType === "Client") value = record?.match?.[fieldList.fieldName];
                            return <p
                                style={{width: fieldList?.columnWidth > 0 ? (fixedColumnWidth * fieldList?.columnWidth) - 12 : "100%",}}>{value || text ? formatDateUTC(value || text) : "-"}</p>;
                        },
                    },
                ];
            } else if (fieldList?.fieldType === "mobile") {
                const isCorporate = fieldList?.fieldName === "corporateMobileNo";
                const countryCodeTitle = isCorporate ? "user.corporateCountryCode" : "user.countryCode";
                const mobileNoTitle = isCorporate ? "user.corporateMobileNo" : "user.mobileNo";
                const countryCodeKey = isCorporate ? "corporateCountryCode" : autoBindType === "Client" ? "match_countryCode" : "countryCode";
                const mobileNoKey = isCorporate ? "corporateMobileNo" : autoBindType === "Client" ? "match_mobileNo" : "mobileNo";

                column = [
                    {
                        title: autoBindType ? `${t(countryCodeTitle)} (${t(`user.${autoBindType.toLowerCase()}`)})` : t(countryCodeTitle),
                        dataIndex: countryCodeKey,
                        key: countryCodeKey,
                        ellipsis: fieldList?.columnWidth > 0,
                        width: fieldList?.columnWidth > 0 ? fixedColumnWidth * fieldList?.columnWidth : "auto",
                        textWrap: 'word-break',
                        ...getColumnSearchProps(),
                        render: (text, record) => {
                            let value;
                            if (autoBindType && autoBindType === "Client") value = record?.match?.[fieldList.fieldName];
                            return <p
                                style={{width: fieldList?.columnWidth > 0 ? (fixedColumnWidth * fieldList?.columnWidth) - 12 : "100%",}}>{t(value || text || "-")}</p>;
                        },
                    },
                    {
                        title: autoBindType ? `${t(mobileNoTitle)} (${t(`user.${autoBindType.toLowerCase()}`)})` : t(mobileNoTitle),
                        dataIndex: mobileNoKey,
                        key: mobileNoKey,
                        ellipsis: fieldList?.columnWidth > 0,
                        width: fieldList?.columnWidth > 0 ? fixedColumnWidth * fieldList?.columnWidth : "auto",
                        textWrap: 'word-break',
                        ...getColumnSearchProps(),
                        render: (text, record) => {
                            let value;
                            if (autoBindType && autoBindType === "Client") value = record?.match?.[fieldList.fieldName];
                            return <p
                                style={{width: fieldList?.columnWidth > 0 ? (fixedColumnWidth * fieldList?.columnWidth) - 12 : "100%",}}>{value || text || "-"}</p>;
                        },
                    },
                    {
                        title: autoBindType ? `${t("user.completedMobileNo")} (${t(`user.${autoBindType.toLowerCase()}`)})` : t("user.completedMobileNo"),
                        dataIndex: autoBindType === "Client" ? "match_completedMobileNo" : "completedMobileNo",
                        key: autoBindType === "Client" ? "match_completedMobileNo" : "completedMobileNo",
                        ellipsis: fieldList?.columnWidth > 0,
                        width: fieldList?.columnWidth > 0 ? fixedColumnWidth * fieldList?.columnWidth : "auto",
                        textWrap: 'word-break',
                        ...getColumnSearchProps(),
                        render: (text, record) => {
                            let value;
                            if (autoBindType && autoBindType === "Client") value = record?.match?.[fieldList.fieldName];
                            return <p
                                style={{width: fieldList?.columnWidth > 0 ? (fixedColumnWidth * fieldList?.columnWidth) - 12 : "100%",}}>{value || text || "-"}</p>;
                        },
                    },
                ];
            } else if (fieldList?.fieldName) {
                column = [
                    {
                        title: label,
                        dataIndex: dataIndex,
                        key: dataIndex,
                        ellipsis: fieldList?.columnWidth > 0,
                        width: fieldList?.columnWidth > 0 ? fixedColumnWidth * fieldList?.columnWidth : "auto",
                        textWrap: 'word-break',
                        ...(
                            fieldList?.fieldType === "dropdown" && fieldList?.optionValue?.length > 0
                                ? getSingleSelectFilterProps(fieldList.optionValue?.map(d => ({label: t(d?.label), value: d?.value})))
                                // ? { filters: fieldList.optionValue?.map(d => ({text: t(d?.label), value: d?.value})), filterMultiple: false }
                                : getColumnSearchProps()
                        ),
                        render: (text, record) => {
                            let value;
                            if (autoBindType && autoBindType === "Client") value = record?.match?.[fieldList.fieldName];

                            return <p style={{
                                width: fieldList?.columnWidth > 0 ? (fixedColumnWidth * fieldList?.columnWidth) - 12 : "100%",
                            }}>{fieldList?.optionValue?.length > 0 ? t(value || text || "-") : value || text || "-"}</p>;
                        },
                    },
                ];
            }

            columnAry?.push(...column);
        });

        setColumn([...columnAry?.filter((d) => d?.dataIndex !== "password" && d?.dataIndex !== "match_password"), ...extraColumns]);
    } catch (error) {
    }
};

const getUserByIdFunction = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            GET_USER_BY_ID,
            {
                id: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const getReferralDeskDropdownFunction = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            GET_DESK_DROPDOWN,
            {
                userId: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const getUserRoleDropdownFunction = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            GET_ROLE_TEMPLATE_DROPDOWN,
            {
                userId: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const getUserRankDropdownFunction = async (id, type?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_MULTI_TIER_SETTING_DROPDOWN,
            {
                userId: id,
                type: type || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

export {
    formatDate,
    formatDateTime,
    formatDateTimeMinute,
    disableDateTime,
    formatProfileInformation,
    renderProfileInformation,
    formatSensitiveFields,
    renderColumns,
    validateConfirmKeys,
    getUserByIdFunction,
    getReferralDeskDropdownFunction,
    getUserRoleDropdownFunction,
    getUserRankDropdownFunction,
};
