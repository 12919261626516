import React, {useEffect, useState} from "react";
import {Col, Form, Modal, Row, Spin} from "antd";
import {
    formatProfileInformation,
    renderProfileInformation,
    validateConfirmKeys
} from "../../../../../_common/function";
import {useTranslation} from "react-i18next";
import {useGoLiveMutation} from "../../../../../../../api/graphql/user";
import useAuthorize from "../../../../../../../_common/authorize";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import {useGetRegistrationSetting} from "../../../function/useQueryHooks";

const GoLiveModalForm = (props) => {
    const {isOpen, setIsOpen, data} = props
    const [form] = Form.useForm()
    const {t} = useTranslation()
    const [fields, setFields] = useState<any>({
        basicField: [],
        residenceField: [],
        additionalField: [],
    });
    const {basicField, residenceField, additionalField} = fields;
    const {goLive, loading} = useGoLiveMutation()
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {systemErrorNotification} = useAuthorize()
    const [registrationFields, registrationFieldsLoading, fetchRegistrationSetting] = useGetRegistrationSetting()

    const [initialValue, setInitialValue] = useState<any>({})

    const closeModal = () => {
        setIsOpen(false)
    }

    const onSubmit = async (value) => {
        try {
            Object.keys(value).forEach((key) => {
                if (value[key] === undefined) {
                    delete value[key];
                }
            });

            const validated = await validateConfirmKeys(value, setNotificationMessage, t)

            if (validated) {
                value["mobileNo"] = value?.mobileNumber?.toString()
                delete value["combinedMobileNo"];

                await goLive(data?._id, {...value, clientAccountType: "individual"})
                closeModal()
            }
        } catch (e) {
            console.log(e)
            systemErrorNotification()
            closeModal()
        }
    }

    useEffect(() => {
        fetchRegistrationSetting({mode: "live", platform: "web"})
    }, []);

    useEffect(() => {
        if (registrationFields?.length > 0) {
            formatProfileInformation(fields, setFields, registrationFields?.filter((d) => d?.position !== null));
        }
    }, [registrationFields])

    useEffect(() => {
        if (isOpen) {
            setInitialValue({...data, affiliateCode: undefined})
            form.resetFields()
        }
    }, [isOpen])

    return (
        <Modal
            title={t("Client Go Live")}
            getContainer="#clientDetail"
            open={isOpen}
            width={"60%"}
            closable={true}
            destroyOnClose
            okText={t("common.submit")}
            onOk={form.submit}
            okButtonProps={{loading: loading}}
            onCancel={closeModal}
        >
            <Row justify={"center"} align={"middle"} style={{minHeight: 200}}>
                <Spin spinning={registrationFieldsLoading}>
                    <Form onFinish={onSubmit} form={form}>
                        {basicField?.length > 0 && (
                            <Col span={24}>
                                <h4>{t("field.category.basicInformation")}</h4>
                                <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                                    {renderProfileInformation(basicField, initialValue, t, false, true, form)}
                                </Row>
                            </Col>
                        )}
                        {residenceField?.length > 0 && (
                            <Col span={24}>
                                <h4>{t("field.category.residenceInformation")}</h4>
                                <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                                    {renderProfileInformation(residenceField, initialValue, t, false, true, form)}
                                </Row>
                            </Col>
                        )}
                        {additionalField?.length > 0 && (
                            <Col span={24}>
                                <h4>{t("field.category.additionalInformation")}</h4>
                                <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                                    {renderProfileInformation(additionalField, initialValue, t, false, true, form)}
                                </Row>
                            </Col>
                        )}
                    </Form>
                </Spin>
            </Row>
        </Modal>
    )
}

export default GoLiveModalForm