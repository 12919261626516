import React, {useEffect, useState} from "react";
import TableAntDesign from "../../../../../components/table/tableAntDesign";
import {useTranslation} from "react-i18next";
import {Popconfirm, Space, Tooltip} from "antd";
import {CopyOutlined, DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import useAuthorize from "../../../../../_common/authorize";
import {getMultiTierSetting} from "../../../../../api/graphql/multiTierSetting";
import AnnouncementModal from "./component/announcementModal";
import AnnouncementTopBar from "./component/announcementTopBar";
import {
    deleteAnnouncement,
    getAnnouncement,
    publishAnnouncementToQueue,
    unpublishAnnouncement
} from "../../../../../api/graphql/announcement";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../recoil_state";
import {useNavigate} from "react-router-dom";
import "./css/style.css"
import ButtonAntDesign from "../../../../../components/button/buttonAntDesign";

function Announcement_1(props) {
    const {
        tableName,
        announcementPermission,
        column,
        languageData,
        userNetworkTreeDropdown,
        imageVarData,
        textVarData
    } = props;
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {systemErrorNotification} = useAuthorize();
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const [viewModalMode, setViewModalMode] = useState("")
    const [viewModalOpen, setViewModalOpen] = useState(false)
    const [viewModalSelectedItem, setViewModalSelectedItem] = useState({})

    const [formModalType, setFormModalType] = useState("");
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [isRequesting, setIsRequesting] = useState(false);

    const [tableData, setTableData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [orderBy, setOrderBy] = useState("");
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [filter, setFilter] = useState<any>({});

    const [multiTierSettingData, setMultiTierSettingData] = useState<any>([])

    const getTableData = async () => {
        try {
            setIsRequesting(true);
            const response = await getAnnouncement({filter: {}}, limit, offset, orderBy);
            setTableData(response?.getAnnouncement?.data);
            setTotalCount(response?.getAnnouncement?.total);
        } catch (error) {
        } finally {
            setIsRequesting(false);
        }
    };

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize)
        setOffset(limit * (page - 1))
    }

    const getMultiTierSettingData = async () => {
        try {
            const res = await getMultiTierSetting({filter: {type: "crmUser"}});
            if (res?.getMultiTierSetting?.__typename === "MultiTierSettingArray") {
                setMultiTierSettingData(res?.getMultiTierSetting?.data);
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        getMultiTierSettingData()
    }, [])

    const deleteOnConfirm = async (id) => {
        try {
            const res = await deleteAnnouncement(id);
            const response = res?.deleteAnnouncement;
            if (response?.__typename === "BaseError") {
                await getTableData();
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.errKey),
                    key: "deleteAnnouncement",
                });
            } else {
                await getTableData();
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("common.deleteSuccess"),
                    key: "deleteAnnouncement",
                });
            }
        } catch (error) {
            systemErrorNotification();
        } finally {
            closeFormModal();
        }
    };

    const openEditor = (announcementId?) => {
        navigate(`/settings/announcement-editor/${announcementId}`);
    };

    const openFormModal = (type, record?) => {
        setFormModalType(type);
        setIsFormModalOpen(true);
        if (record) {
            setSelectedItem(record);
        } else {
            setSelectedItem({})
        }
    };

    const closeFormModal = async () => {
        await getTableData();
        setFormModalType("");
        setSelectedItem({});
        setIsFormModalOpen(false);
    };

    const duplicateItem = (record) => {
        setFormModalType("Add")
        setSelectedItem(record)
        setIsFormModalOpen(true)
    }

    const publishOnConfirm = async (id) => {
        try {
            const res = await publishAnnouncementToQueue(id);
            const response = res?.publishAnnouncementToQueue;
            if (response?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.errKey),
                    key: "publishAnnouncement",
                });
            } else {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("common.publishSuccess"),
                    key: "publishAnnouncement",
                });
            }
        } catch (error) {
            systemErrorNotification();
        } finally {
            closeFormModal();
        }
    };

    const unpublishOnConfirm = async (id) => {
        try {
            const res = await unpublishAnnouncement(id);
            const response = res?.unpublishAnnouncement;
            if (response?.__typename === "BaseError") {
                await getTableData();
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.errKey),
                    key: "unpublishAnnouncement",
                });
            } else {
                await getTableData();
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("common.unpublishSuccess"),
                    key: "unpublishAnnouncement",
                });
            }
        } catch (error) {
            systemErrorNotification();
        } finally {
            closeFormModal();
        }
    };

    useEffect(() => {
        getTableData();
    }, [filter, orderBy, limit, offset]);

    return (
        <div id={"announcement"}>
            <h1 style={{position: "absolute"}}>{t("Announcement")}</h1>
            <AnnouncementModal
                mode={formModalType}
                item={selectedItem}
                isOpen={isFormModalOpen}
                closeModal={closeFormModal}
                languageData={languageData}
                newsletterData={tableData}
                userNetworkTreeDropdown={userNetworkTreeDropdown}
                openEditor={openEditor}
                publishOnConfirm={publishOnConfirm}
                unpublishOnConfirm={unpublishOnConfirm}
                getTableData={getTableData}
                imageVarData={imageVarData}
                textVarData={textVarData}
                announcementPermission={announcementPermission}
            />
            <AnnouncementTopBar
                openFormModal={openFormModal}
                announcementPermission={announcementPermission}
            />
            {column?.length > 0 && (
                <TableAntDesign
                    scroll={{x: "max-content"}}
                    data={tableData}
                    tableName={tableName}
                    columns={[
                        ...column,
                        {
                            fixed: "right",
                            title: t("common.action"),
                            key: "action",
                            width: 132,
                            render: (_, record: any) => (
                                <Space size="middle">
                                    <Tooltip title={t("View")}>
                                        <a onClick={() => openFormModal("View", record)}>
                                            <EyeOutlined/>
                                        </a>
                                    </Tooltip>
                                    {announcementPermission?.messageSetting?.announcement?.edit && (
                                        record?.status === "processing" || record?.status === "in_queue" ?
                                            <div style={{width: "10px"}}/>
                                            :
                                            <Tooltip title={t("Edit")}>
                                                <a onClick={() => openFormModal("Edit", record)}>
                                                    <EditOutlined/>
                                                </a>
                                            </Tooltip>
                                    )}
                                    {announcementPermission?.messageSetting?.announcement?.add && (
                                        <Popconfirm
                                            placement="left"
                                            description={t("Are you sure to duplicate this announcement?")}
                                            title={t(`Duplicate Announcement`)}
                                            onConfirm={() => duplicateItem(record)}
                                            okText={t("Yes")}
                                            okType="danger"
                                            cancelText={t("No")}
                                        >
                                            <Tooltip title={t("Duplicate")}>
                                                <a>
                                                    <CopyOutlined/>
                                                </a>
                                            </Tooltip>
                                        </Popconfirm>
                                    )}
                                    {announcementPermission?.messageSetting?.announcement?.delete && (
                                        record?.status === "processing" || record?.status === "in_queue" ?
                                            <div style={{width: "10px"}}/>
                                            :
                                            <Tooltip title={t("Delete")}>
                                                <Popconfirm
                                                    placement="left"
                                                    title={t("Are you sure to delete this announcement?")}
                                                    onConfirm={() => {
                                                        deleteOnConfirm(record._id);
                                                    }}
                                                    okText={t("common.yes")}
                                                    cancelText={t("common.no")}
                                                >
                                                    <a>
                                                        <DeleteOutlined/>
                                                    </a>
                                                </Popconfirm>
                                            </Tooltip>
                                    )}
                                    {!record?.isPublished && announcementPermission?.messageSetting?.announcement?.publish && (
                                        record?.status === "processing" || record?.status === "in_queue" ?
                                            null
                                            :
                                            <Popconfirm
                                                placement="topRight"
                                                description={t("Are you sure to publish this announcement?")}
                                                title={t("Publish Announcement")}
                                                onConfirm={() => publishOnConfirm(record?._id)}
                                                okText={t("Yes")}
                                                okType={"danger"}
                                                cancelText={t("No")}
                                            >
                                                <ButtonAntDesign
                                                    text={t("Publish")}
                                                    onClick={() => {}}
                                                    type={"primary"}
                                                    className={"publish-btn"}
                                                />
                                            </Popconfirm>
                                    )}
                                    {record?.isPublished && announcementPermission?.messageSetting?.announcement?.unpublish && (
                                        record?.status === "processing" || record?.status === "in_queue" ?
                                            null
                                            :
                                            <Popconfirm
                                                placement="topRight"
                                                description={t("Are you sure to unpublish this announcement?")}
                                                title={t("Unpublish Announcement")}
                                                onConfirm={() => unpublishOnConfirm(record?._id)}
                                                okText={t("Yes")}
                                                okType={"danger"}
                                                cancelText={t("No")}
                                            >
                                                <ButtonAntDesign
                                                    text={t("Unpublish")}
                                                    onClick={() => {}}
                                                    className={"publish-btn"}
                                                />
                                            </Popconfirm>
                                    )}
                                </Space>
                            )
                        }
                    ]}
                    size={"small"}
                    // loading={loading}
                    filter={setFilter}
                    order={setOrderBy}
                    pagination={{
                        pageSize: limit,
                        showSizeChanger: true,
                        onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                        total: totalCount,
                        showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                    }}
                />
            )}
        </div>
    );
}

export default Announcement_1;
