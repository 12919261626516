import React, {useState} from "react";
import {Button, Col, Form, Input, Modal, Row} from "antd";
import TableAntDesign from "../../../../../../../components/table/tableAntDesign";
import {SymbolMappingModalColumns} from "../../../function/tableColumn_SymbolMappingModal";
import {useTranslation} from "react-i18next";
import {verticalFormLayout} from "../../../../../../../components/form/formWrapper";

const SymbolsMappingModal_1 = (props) => {
    const {
        item,
        server,
        isOpen,
        closeModal,
        data,
        setFilter,
        setLimit,
        limit,
        setOffset,
        setOrderBy,
        loading,
        totalCount,
        updateSymbolMapping
    } = props
    const { t } = useTranslation()
    const [ selectedBase, setSelectedBase ] = useState(0)

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize)
        setOffset(page)
    }

    const onRowSelection = (selectedRowKeys, selectedRows) => {
        setSelectedBase(selectedRows?.[0]?.base_symbol_id)
    }

    const onConfirm = () => {
        if(server?.platform === "mt5" || server?.platform === "xtrader"){
            updateSymbolMapping({
                symbolMt5: item?.id,
                server: item?.server,
                baseSymbolId: selectedBase?.toString()
            })
        }else{
            updateSymbolMapping({
                symbol: item?.id,
                server: item?.server,
                baseSymbolId: selectedBase?.toString()
            })
        }

        resetTable()
    }


    const resetTable = () => {
        setSelectedBase(0)
        setOffset(1)
        setFilter({})
        setOrderBy("")
        closeModal()
    }

    return (
        <Modal
            title={(
                <Row>
                    <p>{t("Edit Symbol Mapping")}</p>
                    <Button className={"margin-left-auto"}
                            type={"primary"}
                            onClick={onConfirm}
                            disabled={!selectedBase}>
                        Confirm
                    </Button>
                </Row>
            )}
            open={isOpen}
            onCancel={() => {
                resetTable()
            }}
            footer={null}
            width={"80%"}
            destroyOnClose
        >
            <Form className={"margin-bottom-0-75"}>
                <Row gutter={[16,16]}>
                    <Col span={12}>
                        <Form.Item
                            label={t("Symbol Name")}
                            name="symbolName"
                            initialValue={item?.name}
                            {...verticalFormLayout}
                            colon={false}
                        >
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={t("Description")}
                            name="description"
                            initialValue={item?.description}
                            {...verticalFormLayout}
                            colon={false}
                        >
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <TableAntDesign
                tableName={"mappingModal"}
                data={data}
                columns={SymbolMappingModalColumns}
                size={"small"}
                loading={loading}
                rowKey={"base_symbol_id"}
                filter={setFilter}
                order={setOrderBy}
                selectionType={"radio"}
                onRowSelectionHandler={onRowSelection}
                pagination={{
                    pageSize: limit,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                    total: totalCount,
                    showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                }}
            />
        </Modal>
    );
};

export default SymbolsMappingModal_1;
