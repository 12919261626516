import { gql, useMutation, useQuery } from "@apollo/client";
import { useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { notificationMessage } from "../../../recoil_state";
import { GET_DESK_NETWORK_TREE } from "./deskNetworkTree";

const GET_DESK_COUNT = gql`
  query getDeskCount($filter: DeskInput) {
    getDeskCount(filter: $filter)
  }
`;

const getDeskCount = ({ filter }) => {
  try {
    const { loading, error, data } = useQuery(GET_DESK_COUNT, {
      variables: {
        filter: {
          name: filter?.name || null,
          description: filter?.description || null,
          parent: filter?.parent || null,
        },
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_DESK_BY_ID = gql`
  query getDeskByID($id: String!) {
    getDeskByID(id: $id) {
      ... on Desk {
        id
        _id
        name
        description
        isMasterDesk
        parent {
          _id
          name
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getDeskById = (id) => {
  try {
    const { loading, error, data } = useQuery(GET_DESK_BY_ID, {
      variables: {
        id: id,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_DESK = gql`
  query getDesk(
    $filter: DeskInput
    $limit: Int
    $offset: Int
    $orderBy: String
  ) {
    getDesk(
      filter: $filter
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      ... on DeskArray {
        data {
          id
          _id
          name
          description
          isMasterDesk
          parent {
            _id
            name
          }
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getDesk = ({ filter }, limit?, offset?, orderBy?) => {
  try {
    const { loading, error, data } = useQuery(GET_DESK, {
      variables: {
        filter: {
          name: filter?.name ?? null,
          description: filter?.description ?? null,
          parent: filter?.parent ?? null,
        },
        limit: limit ?? null,
        offset: offset ?? null,
        orderBy: orderBy ?? null,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_DESK_DROPDOWN = gql`
  query getDeskDropdown(
    $filter: DeskInput
    $limit: Int
    $offset: Int
    $orderBy: String
  ) {
    getDeskDropdown(
      filter: $filter
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      ... on DeskArray {
        data {
          id
          _id
          name
          description
          isMasterDesk
          parent {
            _id
            name
          }
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getDeskDropdown = ({ filter }, limit?, offset?, orderBy?) => {
  try {
    const { loading, error, data } = useQuery(GET_DESK_DROPDOWN, {
      variables: {
        filter: {
          name: filter?.name ?? null,
          description: filter?.description ?? null,
          parent: filter?.parent ?? null,
        },
        limit: limit ?? null,
        offset: offset ?? null,
        orderBy: orderBy ?? null,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};


const GET_ALL_DESK_DROPDOWN = gql`
  query getAllDeskDropdown{
    getAllDeskDropdown{
      ... on DeskArray {
        data {
          id
          _id
          name
          description
          isMasterDesk
          parent {
            _id
            name
          }
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getAllDeskDropdown = () => {
  try {
    const { loading, error, data } = useQuery(GET_ALL_DESK_DROPDOWN, {
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const ADD_DESK = gql`
  mutation addDesk($input: DeskInput) {
    addDesk(input: $input) {
      ... on Desk {
        id
        _id
        name
        description
        isMasterDesk
        parent {
          _id
          name
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`;

const useAddDeskMutation = (): {
  addDesk: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const { t } = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [addDeskMutation, { data, loading, error }] = useMutation(ADD_DESK);

    const addDesk = (input) => {
      return addDeskMutation({
        variables: {
          input: {
            name: input.name || null,
            description: input.description || null,
            parent: input.parent || null,
          },
        },
        update(cache, { data: { addDesk } }) {
          const typename = addDesk?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(addDesk?.errKey),
              key: "addDesk",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(addDesk?.msg),
              key: "addDesk",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Add Successfully"),
              key: "addDesk",
            });
          }
        },
        refetchQueries: [GET_DESK, GET_DESK_COUNT, GET_DESK_NETWORK_TREE],
      });
    };

    return { addDesk, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return { addDesk: null, data: null, loading: false, error: err };
  }
};

const UPDATE_DESK = gql`
  mutation updateDesk($id: String!, $input: DeskInput) {
    updateDesk(id: $id, input: $input) {
      ... on Desk {
        id
        _id
        name
        description
        isMasterDesk
        parent {
          _id
          name
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`;

const useUpdateDeskMutation = (): {
  updateDesk: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const { t } = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [updateDeskMutation, { data, loading, error }] =
      useMutation(UPDATE_DESK);

    const updateDesk = (id, input) => {
      return updateDeskMutation({
        variables: {
          id: id,
          input: {
            name: input.name || null,
            description: input.description || null,
            parent: input.parent || null,
          },
        },
        update(cache, { data: { updateDesk } }) {
          const typename = updateDesk?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(updateDesk?.errKey),
              key: "updateDesk",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(updateDesk?.msg),
              key: "updateDesk",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Update Successfully"),
              key: "updateDesk",
            });
          }
        },
        refetchQueries: [GET_DESK, GET_DESK_COUNT, GET_DESK_NETWORK_TREE],
      });
    };

    let typename = data?.updateCompanyEmail?.__typename;
    if (typename === "CrmTaskApproval" || typename === "BaseError") {
      // Notification
    }

    return { updateDesk, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return { updateDesk: null, data: null, loading: false, error: err };
  }
};

const DELETE_DESK = gql`
  mutation deleteDesk($id: String!) {
    deleteDesk(id: $id) {
      ... on Desk {
        id
        isDeleted
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`;

const useDeleteDeskMutation = (): {
  deleteDesk: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const { t } = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [deleteDeskMutation, { data, loading, error }] =
      useMutation(DELETE_DESK);

    const deleteDesk = (id) => {
      return deleteDeskMutation({
        variables: {
          id: id,
        },
        update(cache, { data: { deleteDesk } }) {
          const typename = deleteDesk?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(deleteDesk?.errKey),
              key: "deleteDesk",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(deleteDesk?.msg),
              key: "deleteDesk",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Delete Successfully"),
              key: "deleteDesk",
            });
          }
        },
        refetchQueries: [GET_DESK, GET_DESK_COUNT, GET_DESK_NETWORK_TREE],
      });
    };

    return { deleteDesk, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return { deleteDesk: null, data: null, loading: false, error: err };
  }
};

export {
  getDeskCount,
  getDeskById,
  getDesk,
  useAddDeskMutation,
  useUpdateDeskMutation,
  useDeleteDeskMutation,
  getDeskDropdown,
  getAllDeskDropdown
};
