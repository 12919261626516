const sortEn = (array) => {
    if(!array || !(array.length > 0)){
        return []
    }
    
    let temp = [...array]
    const index = temp.findIndex(item => item.code === "en")

    if (index !== -1) {
        const [removed] = temp.splice(index, 1);
        temp.unshift(removed);
    }

    return temp
}

export {
    sortEn
}