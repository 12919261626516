import React, {useEffect, useState} from "react";
import {Col, Row} from "antd";
import {formatMoney} from "../../../../../../../accounts/tradingAccount/detail/function";
import {getBankInformation} from "../../../../../../../../api/graphql/bankInformation";
import {getPspSetting} from "../../../../../../../../api/graphql/pspSetting";
import {useTranslation} from "react-i18next";
import {formatDateTimeTimezone} from "../../../../../../../../function/_common";
import {useGetPspSettingCurrency} from "../../../../function/useQueryHooks";

const EWalletWithdrawal = ({record}) => {
    const {t} = useTranslation()
    const task = record?.task
    const pspCurrency = record?.task?.result?.currency || task?.payload?.input?.currency
    const [bank, setBank] = useState(null)
    const {data: bankInformation} = getBankInformation({filter: {id: bank}})
    const {data: pspSettingData} = getPspSetting({filter: {name: (task?.result?.psp || task?.payload?.input?.psp), taskView: true}});
    const [pspCurrencyData, fetchPspSettingCurrency] = useGetPspSettingCurrency()

    // Find bank name from psp setting currency available bank
    const formDataBankName = bankInformation?.getBankInformation?.data[0]?.formData?.bank
    const bankName = pspCurrencyData?.find(d => d?.currency === pspCurrency)?.availableBank?.find(d => d?.bankCode === formDataBankName)?.bankName

    useEffect(() => {
        const bankInfoId = task?.result?.bankInformationId || task?.payload?.input?.bankInformationId
        const pspSettingId = task?.result?.pspSettingId || task?.payload?.input?.pspSettingId

        if (bankInfoId) setBank(bankInfoId)
        if (pspSettingId) fetchPspSettingCurrency({pspSetting: pspSettingId})
    }, [record])

    let status = record?.eWalletTransactionId?.transactionStatus === "pending" && task?.status === "approved" ? "approved & processing" : task?.status

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24} md={bank ? 12 : 24}>
                <h3 className={"margin-top-0-75"}>{t("clientUser.ewallet.withdrawalInfo")}</h3>
                <Row gutter={{xs: 4, md: 16}} align={"bottom"}>
                    <Col xs={24} md={10} className={"margin-top-0-75"}>{t("task.status")}</Col>
                    <Col xs={24} md={12}>{status}</Col>

                    <Col xs={24} md={10} className={"margin-top-0-75"}>{t("task.transactionNo")}</Col>
                    <Col xs={24} md={12}>{task?.result?.transactionNo || task?.payload?.input?.transactionNo}</Col>

                    <Col xs={24} md={10} className={"margin-top-0-75"}>{t("task.transaction_date")}</Col>
                    <Col xs={24} md={12}>{formatDateTimeTimezone(task?.result?.createdAt || task?.payload?.input?.createdAt)}</Col>

                    <Col xs={24} md={10} className={"margin-top-0-75"}>{t("task.display_name")}</Col>
                    <Col xs={24} md={12}>{pspSettingData?.getPspSetting?.data[0]?.displayName}</Col>

                    <Col xs={24} md={10} className={"margin-top-0-75"}>{t("task.payment_method")}</Col>
                    <Col xs={24} md={12}>{task?.result?.psp || task?.payload?.input?.psp}</Col>

                    <Col xs={24} md={10} className={"margin-top-0-75"}>{t("task.currency")}</Col>
                    <Col xs={24} md={12}>{pspCurrency}</Col>

                    {(task?.result?.withdrawalType === "crypto" || task?.payload?.input?.withdrawalType === "crypto") &&
                        <>
                            <Col xs={24} md={10} className={"margin-top-0-75"}>{t("task.crypto_ewallet_address")}</Col>
                            <Col xs={24} md={12}>{task?.payload?.input?.cryptoDestinationAddress}</Col>
                        </>
                    }

                    <Col xs={24} md={10} className={"margin-top-0-75"}>{t("task.ewallet_withdrawal_amount_usd")}</Col>
                    <Col xs={24} md={12}>{formatMoney(task?.result?.amount || task?.payload?.input?.amount || 0)}</Col>

                    <Col xs={24} md={10} className={"margin-top-0-75"}>{t("task.fee_charged_on_client_usd")}</Col>
                    <Col xs={24} md={12}>{formatMoney(task?.result?.feeClient || task?.payload?.input?.feeClient || 0)}</Col>

                    <Col xs={24} md={10} className={"margin-top-0-75"}><strong>{t("task.clientReceiveNetAmount_USD")}</strong></Col>
                    <Col xs={24} md={12}>{formatMoney(task?.result?.actualAmount || task?.payload?.input?.actualAmount || 0)}</Col>

                    <Col xs={24} md={10} className={"margin-top-0-75"}>{t("task.psp_fee_usd")}</Col>
                    <Col xs={24} md={12}>{formatMoney(task?.result?.feeCompany || task?.payload?.input?.feeCompany || 0)}</Col>

                    <Col xs={24} md={10} className={"margin-top-0-75"}>{t("task.rate")}</Col>
                    <Col xs={24} md={12}>{task?.result?.rate || task?.payload?.input?.rate}</Col>

                    <Col xs={24} md={10} className={"margin-top-0-75"}>{t("task.ewallet_withdrawal_amount")} ({pspCurrency})</Col>
                    <Col xs={24} md={12}>{formatMoney(task?.result?.convertedAmount || task?.payload?.input?.convertedAmount || 0)}</Col>

                    <Col xs={24} md={10} className={"margin-top-0-75"}>{t("task.fee_charged_on_client")} ({pspCurrency})</Col>
                    <Col xs={24} md={12}>{formatMoney(task?.result?.convertedFeeClient || task?.payload?.input?.convertedFeeClient || 0)}</Col>

                    <Col xs={24} md={10} className={"margin-top-0-75"}><strong>{t("task.clientReceiveNetAmount")} ({pspCurrency})</strong></Col>
                    <Col xs={24} md={12}>{formatMoney(task?.result?.convertedActualAmount || task?.payload?.input?.convertedActualAmount || 0)}</Col>

                    <Col xs={24} md={10} className={"margin-top-0-75"}>{t("task.psp_fee")} ({pspCurrency})</Col>
                    <Col xs={24} md={12}>{formatMoney(task?.result?.convertedFeeCompany || task?.payload?.input?.convertedFeeCompany || 0)}</Col>

                    <Col xs={24} md={10} className={"margin-top-0-75"}>{t("task.remark")}</Col>
                    <Col xs={24} md={12}>{task?.result?.remark || task?.payload?.input?.remark}</Col>

                    <Col xs={24} md={10} className={"margin-top-0-75"}>{t("task.comment")}</Col>
                    <Col xs={24} md={12}>{task?.result?.comment || task?.comment}</Col>

                    <Col xs={24} md={10} className={"margin-top-0-75"}>{t("task.requested_by")}</Col>
                    <Col xs={24} md={12}>{task?.requestedBy?.username}</Col>
                </Row>
            </Col>
            {
                bank &&
                <Col xs={24} md={12}>
                    <h3 className={"margin-top-0-75"}>Bank</h3>
                    <Row>
                        <Col xs={24} md={10} className={"margin-top-0-75"}>{t("clientUser.bank.bankName")}</Col>
                        <Col xs={24} md={12}>{bankName || bankInformation?.getBankInformation?.data[0]?.formData?.bank}</Col>

                        <Col xs={24} md={10} className={"margin-top-0-75"}>{t("clientUser.bank.accountHolder")}</Col>
                        <Col xs={24} md={12}> {bankInformation?.getBankInformation?.data[0]?.formData?.account_holder}</Col>

                        <Col xs={24} md={10} className={"margin-top-0-75"}>{t("clientUser.bank.accountNo")}</Col>
                        <Col xs={24} md={12}>  {bankInformation?.getBankInformation?.data[0]?.formData?.account_no}</Col>
                    </Row>
                </Col>
            }
        </Row>
    )

}

export default EWalletWithdrawal