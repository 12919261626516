import React, {useEffect, useState} from "react";
import {Button, Popconfirm, Row, Space, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import ServerSettingsModal from "./component/serverSettingsModal";
import {useDeleteServerSettingMutation} from "../../../../../api/graphql/serverSetting";
import TableAntDesign from "../../../../../components/table/tableAntDesign";
import EditTableUserPreferenceButton from "../../../../../components/table/function/editTableUserPreferenceButton";
import {ColumnPosition} from "../../../../accounts/tradingAccount/detail/function/tableColumn";
import ExportTableButton from "../../../../../components/table/function/exportTable";

const ServerSettings_1 = (props) => {
    const {data, loading, columns, serverSettingPermission, setOrderBy, orderBy} = props;
    const {t} = useTranslation();
    const {deleteServerSetting} = useDeleteServerSettingMutation();
    const tableName = "Server Setting";
    const columnPosition = ColumnPosition();

    const [addServerSettingsModal, setAddServerSettingsModal] = useState(false);
    const [editServerSettingsModal, setEditServerSettingsModal] = useState(false);
    const [viewServerSettingsModal, setViewServerSettingsModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [socket1, setSocket1] = useState<any>(null);
    const [socket2, setSocket2] = useState<any>(null);
    const [socket1Data, setSocket1Data] = useState<any>({});
    const [socket2Data, setSocket2Data] = useState<any>({});

    const openViewModal = (record) => {
        setSelectedItem(record);
        setViewServerSettingsModal(true);
    };

    const closeViewModal = () => {
        setViewServerSettingsModal(false);
        setSelectedItem({});
    };

    const openAddModal = () => {
        setAddServerSettingsModal(true);
    };

    const closeAddModal = () => {
        setAddServerSettingsModal(false);
    };

    const openEditModal = (record) => {
        setSelectedItem(record);
        setEditServerSettingsModal(true);
    };
    const closeEditModal = () => {
        setEditServerSettingsModal(false);
        setSelectedItem({});
    };

    const setupSocket = (serverNum: string) => {
        try {
            const newSocket: any = new WebSocket(`wss://socket${serverNum}.nextrades.ai/status`);

            newSocket?.addEventListener("open", (event) => {
                console.log("connect");
                // console.log(event);
            });

            newSocket?.addEventListener("message", (event) => {
                const rawData = event.data;
                // console.log(rawData);
                const jsonStartIndex = rawData.indexOf("{"); // Find the index of the opening curly brace
                const jsonString = rawData.substring(jsonStartIndex); // Extract the JSON portion of the string
                const receivedData = JSON.parse(jsonString);
                // console.log('receivedData')
                // console.log(receivedData)
                // setSocket1Data(receivedData);
            });

            if (serverNum === "1") {
                setSocket1(newSocket);
            } else if (serverNum === "2") {
                setSocket2(newSocket);
            }
        } catch (error) {}
    };

    useEffect(() => {
        setupSocket("1");
        setupSocket("2");
        // Clean up the WebSocket connection on component unmount
        return () => {
            console.log("close socket");
            socket1?.close();
            socket2?.close();
        };
    }, []);

    return (
        <div id="serverSettings">
            <ServerSettingsModal mode="add" isOpen={addServerSettingsModal} setOpen={setAddServerSettingsModal} closeModal={closeAddModal} />
            <ServerSettingsModal
                mode="edit"
                item={selectedItem}
                isOpen={editServerSettingsModal}
                setOpen={setEditServerSettingsModal}
                closeModal={closeEditModal}
            />
            <ServerSettingsModal
                mode="view"
                item={selectedItem}
                isOpen={viewServerSettingsModal}
                setOpen={setViewServerSettingsModal}
                closeModal={closeViewModal}
            />
            <Row justify={"space-between"} className={"margin-bottom-0-75"}>
                <h1>{t("Server Settings")}</h1>
                <Space>
                    <EditTableUserPreferenceButton tableName={tableName} tableColumn={columns} />
                    <ExportTableButton tableData={data} tableColumn={columns} tableName={tableName} />
                    {serverSettingPermission?.add?.add && (
                        <Button className="margin-left-auto" onClick={openAddModal}>
                            {t("Add Server Settings")}
                        </Button>
                    )}
                </Space>
            </Row>

            <TableAntDesign
                data={data}
                size="small"
                tableName={tableName}
                loading={loading}
                order={setOrderBy}
                columns={[
                    ...columns,
                    {
                        title: t("common.action"),
                        key: "action",
                        width: 132,
                        render: (_, record: any) => (
                            <Space size="middle">
                                <Tooltip title={t("View")}>
                                    <a onClick={() => openViewModal(record)}>
                                        <EyeOutlined />
                                    </a>
                                </Tooltip>
                                {serverSettingPermission?.edit?.edit && (
                                    <Tooltip title={t("Edit Server")}>
                                        <a onClick={() => openEditModal(record)}>
                                            <EditOutlined />
                                        </a>
                                    </Tooltip>
                                )}
                                {serverSettingPermission?.delete?.delete && (
                                    <Popconfirm
                                        placement="left"
                                        title={"Are you sure to delete this server?"}
                                        description={`Delete the Server`}
                                        onConfirm={() => {
                                            deleteServerSetting(record.id);
                                        }}
                                        okText={t("common.yes")}
                                        cancelText={t("common.no")}
                                    >
                                        <a>
                                            <DeleteOutlined />
                                        </a>
                                    </Popconfirm>
                                )}
                            </Space>
                        ),
                    },
                ]}
            />
        </div>
    );
};

export default ServerSettings_1;
