import React, {useEffect, useState} from "react";
import {Col, Form, Row, Space} from "antd";
import {useTranslation} from "react-i18next";
import TableAntDesign from "../../../../../../../components/table/tableAntDesign";
import {getSymbolSettingComparison} from "../../../../../../../api/graphql/spreadGroupMt5";
import {columnsSymbolSettingMT5} from "../../../../function/tableColumn_Mt5";
import EditTableUserPreferenceButton
    from "../../../../../../../components/table/function/editTableUserPreferenceButton";
import ExportTableButton from "../../../../../../../components/table/function/exportTable";
import {getConfigByName} from "../../../../../../../../config";
import {BRAND_NAME} from "../../../../../../../../config/brandVariable";

const SymbolSettingMt5 = ({initialData}) => {
    const {t} = useTranslation()
    const [form] = Form.useForm();
    const [symbolComparisonData, setSymbolComparisonData] = useState<any>([]);
    const [loading, setLoading] = useState<any>(true);
    const brandConfig = getConfigByName(BRAND_NAME)
    const columnsCompare = columnsSymbolSettingMT5();

    const preload = async () => {
        try {
            await setLoading(true)
            const response = await getSymbolSettingComparison(initialData?._id)

            const symbols = response?.getSymbolSettingComparison?.data;

            setSymbolComparisonData(symbols)
        } finally {
            setLoading(false)
            // tickDataSocket();
        }
    }

    const tickDataSocket = () => {
        if (symbolComparisonData?.length > 0) {
            const newSocket = new WebSocket(brandConfig?.mt5TickSocket + '/ws/tick_data/get?spreadGroup=' + initialData?.group);

            newSocket.addEventListener('message', (event) => {
                const rawData = event.data;
                const tickData = JSON.parse(rawData);
                // Update the state with the new tick data
                setSymbolComparisonData(prevSymbolData => {
                    // Find index of the symbol in symbolComparisonData
                    const index = prevSymbolData.findIndex(item => item.symbol === tickData.symbol);

                    const latestTick = {
                        bid: tickData?.bid.toFixed(tickData?.digits),
                        ask: tickData?.ask.toFixed(tickData?.digits),
                        spreadInPoints: tickData?.spreadInPoints.toFixed(tickData?.digits),
                        averageSpreadInPoints: tickData?.averageSpreadInPoints.toFixed(tickData?.digits),
                        spreadInUsd: tickData?.spreadInUsd.toFixed(tickData?.digits),
                        averageSpreadInUsd: tickData?.averageSpreadInUsd.toFixed(tickData?.digits),
                        spreadInUsdPerLot: tickData?.spreadInUsdPerLot.toFixed(tickData?.digits),
                        averageSpreadInUsdPerLot: tickData?.averageSpreadInUsdPerLot.toFixed(tickData?.digits),
                    }

                    if (index !== -1) {
                        // Symbol found, update tickData for that symbol
                        return prevSymbolData.map((item, i) => i === index ? {...item, ...latestTick} : item);
                    } else {
                        // Symbol not found, add new tickData to symbolComparisonData
                        return [...prevSymbolData, latestTick];
                    }
                });
            });
        }
    };

    useEffect(() => {
        form.setFieldsValue({
            ...initialData
        });
        preload()
    }, [initialData])

    return (
        <>

            <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                <Col span={24}>
                    <Row justify={"end"} style={{marginBottom: "1%"}}>
                        <Space>
                            <ExportTableButton tableData={symbolComparisonData} tableColumn={columnsCompare}
                                               tableName={"spread group symbol settings"}/>
                            <EditTableUserPreferenceButton tableName={"spread group symbol settings"}
                                                           tableColumn={columnsCompare}/>
                        </Space>
                    </Row>
                    <TableAntDesign
                        data={symbolComparisonData}
                        tableName={"spread group symbol settings"}
                        columns={columnsCompare}
                        size={"small"}
                        loading={loading}
                        isTableLocalSearch={true}
                        pagination={{
                            size: "small",
                            pageSize: 5,
                            total: symbolComparisonData?.length,
                            showTotal: (total) => <p>Total {total} items</p>
                        }}
                    />
                </Col>
            </Row>

        </>
    )
}

export default SymbolSettingMt5