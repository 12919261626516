import {Form, InputNumber, Radio, Space} from "antd";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import FormErrors from "./formErrors";

const RangeGroupFormItem = (props) => {
  const {
    layout,
    label,
    name,
    value,
    startRange,
    endRange,
    onStartRangeChange,
    onEndRangeChange,
    fixValue,
    onFixValueChange,
    externalErrorMessage,
    options,
    defaultValue,
    onChange,
    hint,
    disabled,
    required,
    direction,
    requiredStar
  } = props;

  const {
    register,
    watch,
    setValue,
    formState: { touchedFields, isSubmitted, errors },
  } = useFormContext();

  const errorMessage = FormErrors.errorMessage(
    name,
    errors,
    touchedFields,
    isSubmitted,
    externalErrorMessage
  );

  const formHelperText = errorMessage || hint;

  useEffect(() => {
    register(name);
  }, [register, name]);

  return (
    <Form.Item
      {...layout}
      label={requiredStar? <><span className="label-asterisk">*</span>{label}</>:label}
      required={required}
      validateStatus={errorMessage ? "error" : "success"}
      help={formHelperText}
    >
      <Radio.Group
        name={name}
        defaultValue={defaultValue}
        value={value || watch(name)}
        disabled={disabled}
        onChange={(e) => {
          setValue(name, e?.target?.value, { shouldValidate: true });
          onChange && onChange(e?.target?.value);
        }}
      >
        <Space direction={direction}>
        {options.map((option) => (
          <Radio key={option.value} id={option.value} value={option.value}>
            {option.label}
          </Radio>
        ))}
        </Space>
      </Radio.Group>
        {   watch(name) && 
            <div style={{float: "right"}}>
                <InputNumber
                    className="rangeBox"
                    name={startRange}
                    value={watch(startRange)}
                    onChange={(value) => {
                        setValue(startRange, value, {shouldValidate: true});
                        onStartRangeChange && onStartRangeChange(value);
                    }}
                    disabled={disabled}
                    required
                />
                &nbsp;
                ~
                &nbsp;
                <InputNumber
                    className="rangeBox"
                    name={endRange}
                    value={watch(endRange)}
                    onChange={(value) => {
                        setValue(endRange, value, {shouldValidate: true});
                        onEndRangeChange && onEndRangeChange(value);
                    }}
                    disabled={disabled}
                    required
                />
            </div>
        }
        {
          !watch(name) && 
          <div style={{float: "right"}}>
              <InputNumber
                  className="rangeBox"
                  name={fixValue}
                  value={watch(fixValue)}
                  onChange={(value) => {
                      setValue(fixValue, value, {shouldValidate: true});
                      onFixValueChange && onFixValueChange(value);
                  }}
                  disabled={disabled}
                  required
              />
          </div>
        }
    </Form.Item>
  );
};

RangeGroupFormItem.defaultProps = {
  required: false,
  disabled: false,
};

RangeGroupFormItem.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool,
  fixValue: PropTypes.string,
  onFixValueChange: PropTypes.func,
  startRange: PropTypes.string,
  endRange: PropTypes.string,
  onStartRangeChange: PropTypes.func,
  onEndRangeChange: PropTypes.func,
  externalErrorMessage: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  layout: PropTypes.object,
  hint: PropTypes.string,
  direction: PropTypes.string,
  requiredStar: PropTypes.bool
};

export default RangeGroupFormItem;
