import React, { useState } from "react";
import { Button } from "antd";
import * as FileSaver from "file-saver";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getRoleTemplateExportData } from "../../../../../../api/graphql/roleTemplate";
import { getRoleNetworkTreeExportData } from "../../../../../../api/graphql/roleNetworkTree";

const ExportDBTableButton = (props) => {
    const {tableName} = props
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const { data: roleTemplateData } = getRoleTemplateExportData()
    const { data: roleNetworkTreeData } = getRoleNetworkTreeExportData()

    const handleExport = (name) => {
        setLoading(true)
        const currentDate = moment().format("YYYYMMDDHHmm");
        let data:any = null
        if(tableName === "roletemplates"){
            data = roleTemplateData?.getRoleTemplateExportData
        }
        else if(tableName === "rolenetworktree"){
            data = roleNetworkTreeData?.getRoleNetworkTreeExportData
        }

        var blob = new Blob([JSON.stringify(data)], {type: "application/json"});
        if(blob){
            FileSaver.saveAs(blob, `${name}_${currentDate}`);
            setLoading(false)
        }
    }

    return (
        <Button
            onClick={() => handleExport(tableName)}
            loading={loading}
            disabled={!roleTemplateData?.getRoleTemplateExportData?.length || !roleNetworkTreeData?.getRoleNetworkTreeExportData?.length || loading}
        >
            {tableName === "roletemplates" ? t("common.exportRoleSetting"): 
                tableName === "rolenetworktree" ? t("common.exportRoleNetworkTree"): ""
            }
        </Button>
    );
}

export default ExportDBTableButton