import {getColumnSearchProps} from "../../../../../components/table/function/filterTable";
import React from "react";
import {useTranslation} from "react-i18next";
import {BsCheck, BsX} from "react-icons/bs";

export const Columns = () => {
    const {t} = useTranslation();

    return [
        {
            title: t("Trigger"),
            dataIndex: "trigger",
            key: "trigger",
            render: text => <p>{text || "-"}</p>,
        },
        {
            title: t("Trigger From"),
            dataIndex: "triggerFrom",
            key: "triggerFrom",
            width: 150,
            render: text => <p>{text || "-"}</p>,
        },
        {
            title: t("Trigger Group"),
            dataIndex: "triggerGroup",
            key: "triggerGroup",
            width: 150,
            render: text => <p>{text || "-"}</p>,
        },
        {
            title: t("Priority"),
            dataIndex: "priority",
            key: "priority",
            width: 60,
            render: text => <p>{text || "-"}</p>,
        },
        {
            title: t("Enabled"),
            dataIndex: "isEnabled",
            key: "isEnabled",
            width: 120,
            align: "center",
            render: (text) => (text) ? <BsCheck style={{color: 'green'}}/> : <BsX style={{color: 'red'}}/>,
        }
    ]
}