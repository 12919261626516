import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Empty, Row, Space, Spin, Tag, Tooltip,} from 'antd';
import VirtualList from 'rc-virtual-list';
import {useTranslation} from "react-i18next";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {
    fieldCategoryState,
    fieldListFormPropState,
    leadsFieldListLoading,
    leadsFieldListState,
    profileInformationSettingState,
} from "../../../state";
import {AiOutlineEdit, AiOutlinePlus} from "react-icons/ai";


const LeadsFieldCards = (props) => {
    const {updateField, fieldListPermission, profileInformationPermission, updateLoading} = props
    const ContainerHeight = "70vh";
    const {t} = useTranslation();

    const fieldList = useRecoilValue(leadsFieldListState)
    const loading = useRecoilValue(leadsFieldListLoading)
    const profileInformation = useRecoilValue(profileInformationSettingState)
    const setFieldListFormProp = useSetRecoilState<any>(fieldListFormPropState)
    const category = useRecoilValue<any>(fieldCategoryState)

    const [fieldData, setFieldData] = useState<any>([])

    const addField = async (record: any) => {
        const response = await updateField(record?._id, profileInformation?.length ? profileInformation?.length + 1 : 1)
    }

    const formatData = async (presetFields, existingFields) => {
        if (presetFields?.length > 0 && existingFields?.length > 0) {
            return await presetFields?.filter(presetItem => {
                const formattedExistingFields = existingFields?.filter(item => item?.position !== null)
                return !formattedExistingFields?.some(existingItem => presetItem?._id === existingItem?.fieldList?._id);
            })
        } else return presetFields
    }

    useEffect(() => {
        formatData(fieldList, profileInformation)?.then(data => setFieldData(data?.filter(item => item?.category !== "registrationInformation")))
    }, [fieldList, profileInformation])

    let categoryColor
    const data = category?.length > 0 ? fieldData?.filter(d => category?.includes(d?.category)) : fieldData

    return (
        <div id={"field-cards"}>
            <Row style={{marginBottom: "15px"}}>
                <Col flex={"auto"}>
                    <h3>{t("Field")}</h3>
                </Col>
            </Row>

            <Row>
                <Col span={24} style={{height: ContainerHeight, overflow: "auto"}}>
                    {
                        fieldListPermission?.view && data?.length > 0 ? (
                            <VirtualList
                                data={data}
                                itemKey="_id"
                            >
                                {(item, i) => {
                                    switch (item?.category) {
                                        case "basicInformation" :
                                            categoryColor = "gold"
                                            break
                                        case "residentialInformation" :
                                            categoryColor = "green"
                                            break
                                        case "additionalInformation" :
                                            categoryColor = "geekblue"
                                            break
                                    }

                                    return (
                                        <Card className={"margin-top-0-75"} key={item?._id} style={{marginRight: 8}}>
                                            <Row>
                                                <Col flex={"auto"}>
                                                    <Row justify={"space-between"}>
                                                        <h4>{t(item?.label)}{item?.isRequired ?
                                                            <span style={{color: "red"}}> * </span> : undefined}</h4>
                                                        <Space size={0}>
                                                            {/*{requiredIcon(item)}*/}
                                                            {/*{uniqueIcon(item)}*/}
                                                            {/*{sensitiveIcon(item)}*/}
                                                            <Tooltip title={t("common.edit")}>
                                                                <Button type={"link"}
                                                                        onClick={() => {
                                                                            setFieldListFormProp({
                                                                                isOpen: true,
                                                                                type: "leads",
                                                                                item: item,
                                                                                close: () => {
                                                                                    setFieldListFormProp({isOpen: false})
                                                                                }
                                                                            })
                                                                        }}
                                                                        icon={<AiOutlineEdit/>}
                                                                />
                                                            </Tooltip>
                                                            {profileInformationPermission?.edit?.edit &&
                                                            <Tooltip title={t("common.add")}>
                                                                    <Button type={"link"}
                                                                            disabled={updateLoading}
                                                                            onClick={() => {
                                                                            addField(item)
                                                                        }}
                                                                        icon={
                                                                            <AiOutlinePlus/>}
                                                                />
                                                            </Tooltip>
                                                            }
                                                        </Space>
                                                    </Row>
                                                    <Tag color={"blue"}
                                                         bordered={false}><small>{item?.fieldType?.toUpperCase()}</small></Tag>
                                                    <Tag color={categoryColor}
                                                         bordered={false}><small>{item?.category?.replace(/(INFORMATION)/i, "")?.toUpperCase()}</small></Tag>
                                                    {
                                                        item?.isConfirmRequired &&
                                                        <Tag color="magenta"
                                                             bordered={false}><small>{t("CONFIRM REQUIRED")}</small></Tag>
                                                    }
                                                    {
                                                        item?.isSensitive &&
                                                        <Tag color="volcano"
                                                             bordered={false}><small>{t("SENSITIVE")}</small></Tag>
                                                    }
                                                    {
                                                        item?.isUnique &&
                                                        <Tag color="orange"
                                                             bordered={false}><small>{t("UNIQUE")}</small></Tag>
                                                    }
                                                </Col>
                                            </Row>
                                        </Card>
                                    )
                                }}
                            </VirtualList>
                        ) : fieldListPermission?.view && loading ? (
                            <Col span={24} style={{marginTop: "20%", textAlign: "center"}}>
                                <Spin size="large"/>
                            </Col>
                        ) : (
                            <Col span={24} style={{paddingTop: "20%"}}>
                                <Empty/>
                            </Col>
                        )
                    }
                </Col>
            </Row>
        </div>
    )
}

export default LeadsFieldCards