import React from "react";
import {getColumnSearchProps, getDateRangeFilterProps} from "../../../components/table/function/filterTable";
import {formatMoney} from "../../accounts/tradingAccount/detail/function";
import {useTranslation} from "react-i18next";
import {formatDateTimeTimezone} from "../../../function/_common";

export const ColumnTask = () => {
    const {t} = useTranslation();

    return [
        {
            title: t("task.taskID"),
            dataIndex: "taskTitle",
            key: "taskTitle",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("task.mainModuleName"),
            dataIndex: "mainModuleName",
            key: "mainModuleName",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("task.subModuleName"),
            dataIndex: "subModuleName",
            key: "subModuleName",
            ...getColumnSearchProps(),
            render: (text) => <p>{text ? text : "-"}</p>,
        },
        {
            title: t("task.actionType"),
            dataIndex: "actionType",
            key: "actionType",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("task.transactionNo"),
            dataIndex: "additionalInfoUserTransactionNo",
            key: "additionalInfoUserTransactionNo",
            ...getColumnSearchProps(),
            render: (e, record) => <p>{record?.additionalInfo?.transactionNo || "-"}</p>,
        },
        {
            title: t("user.username"),
            dataIndex: "additionalInfoUserUsername",
            key: "additionalInfoUserUsername",
            ...getColumnSearchProps(),
            render: (e, record) => {
                return record?.additionalInfo?.user?.username ? (
                    <a
                        onClick={() =>
                            window.open(`/users/${record?.additionalInfo?.user?.userType}/detail?id=${record?.additionalInfo?.user?._id}`, "_blank")
                        }
                    >
                        {record?.additionalInfo?.user?.username}
                    </a>
                ) : (
                    "-"
                );
            },
        },
        {
            title: t("task.name"),
            dataIndex: "additionalInfoUserFullName",
            key: "additionalInfoUserFullName",
            ...getColumnSearchProps(),
            render: (e, record) => {
                return record?.additionalInfo?.user?.fullName ? (
                    <a
                        onClick={() =>
                            window.open(`/users/${record?.additionalInfo?.user?.userType}/detail?id=${record?.additionalInfo?.user?._id}`, "_blank")
                        }
                    >
                        {record?.additionalInfo?.user?.fullName}
                    </a>
                ) : (
                    "-"
                );
            },
        },
        {
            title: t("task.amount"),
            dataIndex: "additionalInfoUserAmount",
            key: "additionalInfoUserAmount",
            ...getColumnSearchProps(),
            render: (e, record) => {
                let amount = "-";
                switch (record?.subModuleName) {
                    case "Psp Adjustment":
                    case "Psp Settlement":
                        amount = record?.payload?.input?.convertedAmount;
                        break;
                    default:
                        amount = record?.additionalInfo?.transactionAmount ? formatMoney(record?.additionalInfo?.transactionAmount) : "-";
                        break;
                }
                return <p>{amount}</p>;
            },
        },
        {
            title: t("task.account"),
            dataIndex: "additionalInfoUserAccount",
            key: "additionalInfoUserAccount",
            ...getColumnSearchProps(),
            render: (e, record) => <p>{record?.additionalInfo?.account || "-"}</p>,
        },
        {
            title: t("task.additionalInfo"),
            dataIndex: "additionalInfoUserAdditionalInformation",
            key: "additionalInfoUserAdditionalInformation",
            ...getColumnSearchProps(),
            render: (e, record) => <p>{record?.additionalInfo?.additionalInformation || "-"}</p>,
        },
        {
            title: t("task.description"),
            dataIndex: "description",
            key: "description",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("task.errMsg"),
            dataIndex: "errMsg",
            key: "errMsg",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("task.requestedBy"),
            dataIndex: "requestedBy",
            key: "requestedBy",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("task.requestedTime"),
            dataIndex: "createdAt",
            key: "createdAt",
            ...getDateRangeFilterProps(),
            render: (text) => <p>{text ? formatDateTimeTimezone(text) : "-"}</p>,
        },
        {
            title: t("task.claimBy"),
            dataIndex: "claimBy",
            key: "claimBy",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("task.approvedBy"),
            dataIndex: "approvedBy",
            key: "approvedBy",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("task.approvedTime"),
            dataIndex: "approvedDatetime",
            key: "approvedDatetime",
            ...getDateRangeFilterProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
    ];
};
