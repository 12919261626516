import {gql, useMutation, useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";

const GET_PROMO_MATERIAL_SETTING = gql`
  query getPromoMaterialSetting {
    getPromoMaterialSetting {
      ... on PromoMaterialSetting {
        banners
        videos
        landings
        gifs
        logos
        widgets
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getPromoMaterialSetting = () => {
    try {
        const {loading, error, data} = useQuery(GET_PROMO_MATERIAL_SETTING, {
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, data}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const UPDATE_PROMO_MATERIAL_SETTING = gql`
  mutation updatePromoMaterialSetting($input: PromoMaterialSettingInput) {
    updatePromoMaterialSetting(input: $input) {
      ... on PromoMaterialSetting {
        banners
        videos
        landings
        gifs
        logos
        widgets
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useUpdatePromoMaterialSettingMutation = (): {
    updatePromoMaterialSetting: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const { t } = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updatePromoMaterialSettingMutation, {data, loading, error}] = useMutation(UPDATE_PROMO_MATERIAL_SETTING);

        const updatePromoMaterialSetting = (input) => {
            return updatePromoMaterialSettingMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {updatePromoMaterialSetting}}) {
                    const typename = updatePromoMaterialSetting?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updatePromoMaterialSetting?.errKey),
                            key: "updatePromoMaterialSetting",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updatePromoMaterialSetting?.msg),
                            key: "updatePromoMaterialSetting",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Promo Material Setting Successfully"),
                            key: "updatePromoMaterialSetting",
                        });
                    }
                },
            });
        };

        return {updatePromoMaterialSetting, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {updatePromoMaterialSetting: null, data: null, loading: false, error: err};
    }
};

export {
    getPromoMaterialSetting,
    useUpdatePromoMaterialSettingMutation
}


