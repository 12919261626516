import { gql, useMutation, useQuery } from '@apollo/client'
import { useSetRecoilState } from 'recoil';
import { notificationMessage } from '../../../recoil_state';
import { useTranslation } from 'react-i18next';
import { GET_EWALLET_STATEMENT_WITH_TASK } from './eWallet';
import { GET_CRM_TASK_APPROVAL } from './crmTask';

const GET_EWALLET_WITHDRAWAL_COUNT = gql`
  query getEWalletWithdrawalCount($filter: EWalletWithdrawalSearchInput) {
    getEWalletWithdrawalCount(filter: $filter)
  }
`;

const getEWalletWithdrawalCount = ({filter}) => {
  try{
    const { loading, error, data } = useQuery(GET_EWALLET_WITHDRAWAL_COUNT, {
        variables: {
            filter: {
              refNo: filter?.refNo || null,
              transactionNo: filter?.transactionNo || null,
              paymentMethod: filter?.paymentMethod || null,
              type: filter?.type || null,
            }
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}

const GET_EWALLET_WITHDRAWAL_BY_ID = gql`
  query getEWalletWithdrawalByID($id: String!) {
    getEWalletWithdrawalByID(id: $id){
        ... on EWalletWithdrawal {
          _id
          id
          psp
          transactionNo
          transactionDate
          refNo
          user {username}
          amount
          currency
          rate
          paymentMethod
          feeClient
          feeCompany
          actualAmount
          convertedAmount
          convertedFeeCompany
          convertedFeeClient
          convertedActualAmount
          referrerUser {username}
          salesRepUser {username}
          branch
          remark
          transactionStatus
          createdBy {username}
          updatedBy {username}
          createdAt
          updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getEWalletWithdrawalById = (id) => {
  try{
    const { loading, error, data } = useQuery(GET_EWALLET_WITHDRAWAL_BY_ID, {
        variables: {
          id: id
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}


const GET_EWALLET_WITHDRAWAL = gql`
  query getEWalletWithdrawal($filter: EWalletWithdrawalSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
    getEWalletWithdrawal(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy){
        ... on EWalletWithdrawalArray {
        data {
          _id
          id
          psp
          transactionNo
          transactionDate
          refNo
          user {username}
          amount
          currency
          rate
          paymentMethod
          feeClient
          feeCompany
          actualAmount
          convertedAmount
          convertedFeeCompany
          convertedFeeClient
          convertedActualAmount
          referrerUser {username}
          salesRepUser {username}
          branch
          remark
          transactionStatus
          createdBy {username}
          updatedBy {username}
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getEWalletWithdrawal = ({filter}, limit?, offset?, orderBy?) => {
  try{
    const { loading, error, data } = useQuery(GET_EWALLET_WITHDRAWAL, {
        variables: {
          filter: {
              user: filter?.user || null,
              psp: filter?.psp || null,
              transactionNo: filter?.transactionNo || null,
              paymentMethod: filter?.paymentMethod || null,
              type: filter?.type || null,
              rejectReason: filter?.rejectReason || null,
              transactionStatus: filter?.transactionStatus || null,
              pspSettingId: filter?.pspSettingId || null,
              pspSettingCurrencyId: filter?.pspSettingCurrencyId || null,
          },
          limit: limit || null,
          offset: offset || null,
          orderBy: orderBy || null,
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}


const GET_EWALLET_WITHDRAWAL_DROPDOWN_BASED_ON_PERMISSION = gql`
  query getEWalletWithdrawalDropdownBasedOnPermission($filter: EWalletWithdrawalSearchInput) {
    getEWalletWithdrawalDropdownBasedOnPermission(filter: $filter){
        ... on EWalletWithdrawalArray {
        data {
          _id
          id
          psp
          transactionNo
          transactionDate
          refNo
          user {username}
          amount
          currency
          rate
          paymentMethod
          feeClient
          feeCompany
          actualAmount
          convertedAmount
          convertedFeeCompany
          convertedFeeClient
          convertedActualAmount
          referrerUser {username}
          salesRepUser {username}
          branch
          remark
          transactionStatus
          createdBy {username}
          updatedBy {username}
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getEWalletWithdrawalDropdownBasedOnPermission = ({filter}) => {
  try{
    const { loading, error, data } = useQuery(GET_EWALLET_WITHDRAWAL_DROPDOWN_BASED_ON_PERMISSION, {
        variables: {
          filter: filter
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}

const ADD_EWALLET_WITHDRAWAL = gql`
  mutation addEWalletWithdrawal($input: EWalletWithdrawalInput) {
    addEWalletWithdrawal(input: $input){
      ... on EWalletWithdrawal {
          _id
          id
          psp
          transactionNo
          transactionDate
          refNo
          user {username}
          amount
          currency
          rate
          paymentMethod
          feeClient
          feeCompany
          actualAmount
          convertedAmount
          convertedFeeCompany
          convertedFeeClient
          convertedActualAmount
          referrerUser {username}
          salesRepUser {username}
          branch
          remark
          transactionStatus
          createdBy {username}
          updatedBy {username}
          createdAt
          updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useAddEWalletWithdrawalMutation = (): { addEWalletWithdrawal: any, data: any, loading: boolean, error: any } => {
  try{
    const [addEWalletWithdrawalMutation, { data, loading, error }] = useMutation(ADD_EWALLET_WITHDRAWAL);

    const addEWalletWithdrawal = (input) => {
      return addEWalletWithdrawalMutation({
        variables: {
          input: {
            transactionNo: input.transactionNo || null,
            transactionDate: input.transactionDate || null,
            fromUserId: input.fromUserId || null,
            toUserId: input.toUserId || null,
            amount: input.amount || null,
            remark: input.remark || null,
          },
        },
      });
    };

    return { addEWalletWithdrawal, data, loading, error } 
  }
  catch(err: any){
    console.error(err);
    return { addEWalletWithdrawal: null, data: null, loading: false, error: err };
  }
}

const UPDATE_EWALLET_WITHDRAWAL_PAYMENT_METHOD = gql`
mutation updateEwalletWithdrawalPaymentMethod($id: String!, $input: EWalletWithdrawalPaymentMethodInput) {
  updateEwalletWithdrawalPaymentMethod(id: $id, input: $input){
        ... on EWalletWithdrawal {
          _id
          id
          psp
          transactionNo
          transactionDate
          refNo
          user {username}
          amount
          currency
          rate
          paymentMethod
          feeClient
          feeCompany
          actualAmount
          convertedAmount
          convertedFeeCompany
          convertedFeeClient
          convertedActualAmount
          referrerUser {username}
          salesRepUser {username}
          branch
          remark
          transactionStatus
          createdBy {username}
          updatedBy {username}
          createdAt
          updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useUpdateEwalletWithdrawalPaymentMethodMutation = (): { updateEwalletWithdrawalPaymentMethod: any, data: any, loading: boolean, error: any } => {
  try{
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const { t } = useTranslation();
    const [updateEwalletWithdrawalPaymentMethodMutation, { data, loading, error }] = useMutation(UPDATE_EWALLET_WITHDRAWAL_PAYMENT_METHOD);

    const updateEwalletWithdrawalPaymentMethod = (id, input) => {
      return updateEwalletWithdrawalPaymentMethodMutation({
        variables: {
          id: id,
          input: {
            pspSettingId: input.pspSettingId,
            pspSettingCurrencyId: input.pspSettingCurrencyId,
            pspSettingDetailId: input.pspSettingDetailId,
          },
        },
        update(cache, {data: {updateEwalletWithdrawalPaymentMethod}}) {
            const typename = updateEwalletWithdrawalPaymentMethod?.__typename;
            if (typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(updateEwalletWithdrawalPaymentMethod?.errKey),
                    key: "updateEwalletWithdrawalPaymentMethod",
                });
            }  else {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Update Ewallet Withdrawal Successfully"),
                    key: "updateEwalletWithdrawalPaymentMethod",
                });
            }
        },
        refetchQueries: [GET_CRM_TASK_APPROVAL]
      });
    };

    return { updateEwalletWithdrawalPaymentMethod, data, loading, error } 
  }
  catch(err: any){
    console.error(err);
    return { updateEwalletWithdrawalPaymentMethod: null, data: null, loading: false, error: err };
  }
}


// const UPDATE_EWALLET_WITHDRAWAL = gql`
// mutation updateEWalletWithdrawal($id: String!, $input: EWalletWithdrawalInput) {
//     updateEWalletWithdrawal(id: $id, input: $input){
//         ... on EWalletWithdrawal {
//           _id
//           id
//           psp
//           transactionNo
//           transactionDate
//           refNo
//           user {username}
//           amount
//           currency
//           rate
//           paymentMethod
//           feeClient
//           feeCompany
//           actualAmount
//           convertedAmount
//           convertedFeeCompany
//           convertedFeeClient
//           convertedActualAmount
//           referrerUser {username}
//           salesRepUser {username}
//           branch
//           remark
//           transactionStatus
//           createdBy {username}
//           updatedBy {username}
//           createdAt
//           updatedAt
//       }
//       ... on BaseError {
//         errKey
//         errMsg
//       }
//       ... on CrmTaskApproval {
//         msg
//       }
//     }
//   }
// `

// const useUpdateEWalletWithdrawalMutation = (): { updateEWalletWithdrawal: any, data: any, loading: boolean, error: any } => {
//   try{
//     const [updateEWalletWithdrawalMutation, { data, loading, error }] = useMutation(UPDATE_EWALLET_WITHDRAWAL);

//     const updateEWalletWithdrawal = (id, input) => {
//       return updateEWalletWithdrawalMutation({
//         variables: {
//           id: id,
//           input: {
//             transactionNo: input.transactionNo || null,
//             transactionDate: input.transactionDate || null,
//             fromUserId: input.fromUserId || null,
//             toUserId: input.toUserId || null,
//             amount: input.amount || null,
//             remark: input.remark || null,
//           },
//         },
//       });
//     };

//     return { updateEWalletWithdrawal, data, loading, error } 
//   }
//   catch(err: any){
//     console.error(err);
//     return { updateEWalletWithdrawal: null, data: null, loading: false, error: err };
//   }
// }

const UPDATE_EWALLET_WITHDRAWAL_COMMISSION_DATE = gql`
mutation updateEwalletWithdrawalCommissionDate($id: String!, $input: EwalletWithdrawalCommisionDateInput) {
  updateEwalletWithdrawalCommissionDate(id: $id, input: $input){
        ... on EWalletWithdrawal {
          _id
          id
          psp
          transactionNo
          transactionDate
          refNo
          user {username}
          amount
          currency
          rate
          paymentMethod
          feeClient
          feeCompany
          actualAmount
          convertedAmount
          convertedFeeCompany
          convertedFeeClient
          convertedActualAmount
          referrerUser {username}
          salesRepUser {username}
          branch
          remark
          transactionStatus
          createdBy {username}
          updatedBy {username}
          createdAt
          updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useUpdateEwalletWithdrawalCommissionDateMutation = (): { updateEwalletWithdrawalCommissionDate: any, data: any, loading: boolean, error: any } => {
  try{
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const { t } = useTranslation();
    const [updateEwalletWithdrawalCommissionDateMutation, { data, loading, error }] = useMutation(UPDATE_EWALLET_WITHDRAWAL_COMMISSION_DATE);

    const updateEwalletWithdrawalCommissionDate = (id, input) => {
      return updateEwalletWithdrawalCommissionDateMutation({
        variables: {
          id: id,
          input: input,
        },
        update(cache, {data: {updateEwalletWithdrawalCommissionDate}}) {
            const typename = updateEwalletWithdrawalCommissionDate?.__typename;
            if (typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(updateEwalletWithdrawalCommissionDate?.errKey),
                    key: "updateEwalletWithdrawalCommissionDate",
                });
            }  else {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Update Ewallet Withdrawal Successfully"),
                    key: "updateEwalletWithdrawalCommissionDate",
                });
            }
        },
        refetchQueries: [GET_EWALLET_STATEMENT_WITH_TASK]
      });
    };

    return { updateEwalletWithdrawalCommissionDate, data, loading, error } 
  }
  catch(err: any){
    console.error(err);
    return { updateEwalletWithdrawalCommissionDate: null, data: null, loading: false, error: err };
  }
}

const DELETE_EWALLET_WITHDRAWAL = gql`
  mutation deleteEWalletWithdrawal($id: String!){
    deleteEWalletWithdrawal(id: $id){
       ... on EWalletWithdrawal {
        _id
        id
        isDeleted
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useDeleteEWalletWithdrawalMutation = (): { deleteEWalletWithdrawal: any, data: any, loading: boolean, error: any } => {
  try{
    const [deleteEWalletWithdrawalMutation, { data, loading, error }] = useMutation(DELETE_EWALLET_WITHDRAWAL);

    const deleteEWalletWithdrawal = (id) => {
      return deleteEWalletWithdrawalMutation({
        variables: {
          id: id,
        },
      });
    };

    return { deleteEWalletWithdrawal, data, loading, error } 
  }
  catch(err: any){
    console.error(err);
    return { deleteEWalletWithdrawal: null, data: null, loading: false, error: err };
  }
}

const CALCULATE_WITHDRAWAL = gql`
  query calculateWithdrawal($input: EWalletClientWithdrawalRateInput) {
    calculateWithdrawal(input: $input){
        ... on EWalletWithdrawal {
          psp
          amount
          currency
          rate
          paymentMethod
          feeClient
          feeCompany
          actualAmount
          convertedAmount
          convertedFeeCompany
          convertedFeeClient
          convertedActualAmount
          withdrawalType
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const calculateWithdrawal = ({input}) => {
  try{
    const { loading, error, data } = useQuery(CALCULATE_WITHDRAWAL, {
        variables: {
          input: {
            psp: input?.psp || null,
            currency: input?.currency || null,
            paymentMethod: input?.paymentMethod || null,
            amount: input?.amount || null,
            actualAmount: input?.actualAmount || null,
            rate: input?.rate || null,
            feeClient: input?.feeClient || null,
            convertedFeeClient: input?.convertedFeeClient || null,
            convertedFeeCompany: input?.convertedFeeCompany || null,
            convertedActualAmount: input?.convertedActualAmount || null,
            targetField: input?.targetField || "currency",
            withdrawalType: input?.withdrawalType || null,
          },
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, withdrawalCalculation: data }
  }
  catch(err: any){
    console.error(err);
    return { withdrawalCalculation: null, loading: false, error: err };
  }
}
export {
  getEWalletWithdrawalCount, 
  getEWalletWithdrawalById, 
  getEWalletWithdrawal, 
  useAddEWalletWithdrawalMutation, 
  // useUpdateEWalletWithdrawalMutation,
  useDeleteEWalletWithdrawalMutation,
  calculateWithdrawal,
  getEWalletWithdrawalDropdownBasedOnPermission,
  useUpdateEwalletWithdrawalCommissionDateMutation,
  useUpdateEwalletWithdrawalPaymentMethodMutation
}