import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {useRecoilState} from "recoil"
import EmailTemplateEditor_1 from "./view/layout1"
import {getEmailTemplateByIdAsync, useUpdateEmailTemplateMutation} from "../../../api/graphql/emailTemplate"
import {getLanguage} from "../../../api/graphql/language"
import {sortEn} from "../../../function/language"
import {getDynamicVariable} from "../../../api/graphql/dynamicVariable"
import {selfPermissionObj} from "../../../../recoil_state"
import useAuthorize from "../../../_common/authorize"
import "./css/common/index.css"

function EmailTemplateEditor() {
    let layout = 1
    let component
    const {templateId} = useParams()
    const [selfPermission] = useRecoilState<any>(selfPermissionObj)
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)
    const emailTemplatePermission = selfPermission?.messageSetting?.emailTemplate
    const dynamicVariablePermission = selfPermission?.messageSetting?.dynamicVariable

    const {languageData} = getLanguage({filter: {isActive: true}})
    const {variableData: imageVarData} = getDynamicVariable({
        filter: {variableType: "image", isActive: true},
    })
    const {variableData: textVarData} = getDynamicVariable({
        filter: {variableType: "text", isActive: true},
    })
    const {variableData: pdfVarData} = getDynamicVariable({
        filter: {variableType: "pdf", isActive: true},
    })
    const {variableData: reportTableVarData} = getDynamicVariable({
        filter: {variableType: "reportTable", isActive: true},
    })
    // const { variableData } = getDynamicVariable({ filter: null });
    // const { componentData } = getDynamicEmailComponent({ filter: null });
    const {updateEmailTemplate, loading: updateLoading} = useUpdateEmailTemplateMutation()
    const {systemErrorNotification} = useAuthorize()

    const handleEmailTemplateUpdate = async (input) => {
        try {
            await updateEmailTemplate(templateId, input)
            getData()
        } catch (e: any) {
            console.log(e)
            systemErrorNotification()
        }
    }

    const getData = async () => {
        try {
            setLoading(true)
            const response = await getEmailTemplateByIdAsync(templateId)
            setData(response.getEmailTemplateByID)
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getData()
    }, [templateId])

    const amendedImageVarData = dynamicVariablePermission?.view ? imageVarData?.getDynamicVariable?.data : []

    switch (layout) {
        case 1:
            component = (
                <EmailTemplateEditor_1
                    data={data}
                    imageVarData={amendedImageVarData}
                    textVarData={textVarData?.getDynamicVariable?.data}
                    pdfVarData={pdfVarData?.getDynamicVariable?.data}
                    reportTableVarData={reportTableVarData?.getDynamicVariable?.data}
                    languageData={sortEn(languageData?.getLanguage?.data)}
                    handleEmailTemplateUpdate={handleEmailTemplateUpdate}
                    emailTemplatePermission={emailTemplatePermission}
                    loading={loading}
                    updateLoading={updateLoading}
                    //   variableData={variableData?.getDynamicVariable?.data}
                    //   componentData={componentData?.getDynamicEmailComponent}
                />
            )
            break

        default:
            component = (
                <EmailTemplateEditor_1
                    data={data}
                    imageVarData={amendedImageVarData}
                    textVarData={textVarData?.getDynamicVariable?.data}
                    pdfVarData={pdfVarData?.getDynamicVariable?.data}
                    reportTableVarData={reportTableVarData?.getDynamicVariable?.data}
                    languageData={sortEn(languageData?.getLanguage?.data)}
                    handleEmailTemplateUpdate={handleEmailTemplateUpdate}
                    emailTemplatePermission={emailTemplatePermission}
                    loading={loading}
                    updateLoading={updateLoading}
                    //   variableData={variableData?.getDynamicVariable?.data}
                    //   componentData={componentData?.getDynamicEmailComponent}
                />
            )
            break
    }

    return component
}

export default EmailTemplateEditor
