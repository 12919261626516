import { gql, useMutation } from "@apollo/client";

const LOGIN = gql`
  mutation login($input: AuthInput) {
    login(input: $input)
    {
      ...on AuthPayload{
        username
        login
        accessToken
        userid
      }
      ...on BaseError {
        errKey
        errMsg
      }
    }
  }
`

const useLoginMutation = (): { login: any, data: any, loading: boolean, error: any } => {
  try{
    const [loginMutation, { data, loading, error }] = useMutation(LOGIN);

    const login = (input) => {
      return loginMutation({
        variables: {
          input: {
            login: input.login || null,
            password: input.password || null,
            type: input.type || null,
            ip: input.ip || null,
            browser: input.browser || null,
            device: input.device || null,
            // deviceId: input.deviceId || null,
            // language: input.language || null,
            platform: "web"
          },
        },
      });
    };

    return { login, data, loading, error } 
  }
  catch(err: any){
    console.error(err);
    return { login: null, data: null, loading: false, error: err };
  }
}

const VERIFY_ACCESS_TOKEN = gql`
  mutation verifyAccessToken($accessToken: String) {
    verifyAccessToken(accessToken: $accessToken)
    {
      ...on AuthPayload{
        username
        login
        userid
        username
        accessToken
      }
      ...on BaseError {
        errKey
        errMsg
      }
    }
  }
`

const useVerifyAccessTokenMutation = (): { verifyAccessToken: any, data: any, loading: boolean, error: any } => {
  try{
    const [verifyAccessTokenMutation, { data, loading, error }] = useMutation(VERIFY_ACCESS_TOKEN);

    const verifyAccessToken = (input) => {
      return verifyAccessTokenMutation({
        variables: {
          accessToken: input
        },
      });
    };

    return { verifyAccessToken, data, loading, error } 
  }
  catch(err: any){
    console.error(err);
    return { verifyAccessToken: null, data: null, loading: false, error: err };
  }
}

const TEMP_LOGIN = gql`
  mutation tempLogin($input: AuthTempInput) {
    tempLogin(input: $input)
    {
      ...on AuthPayload{
        username
        login
        accessToken
        refreshToken
        userid
      }
      ...on BaseError {
        errKey
        errMsg
      }
    }
  }
`

const useTempLoginMutation = (): { tempLogin: any, data: any, loading: boolean, error: any } => {
  try{
    const [tempLoginMutation, { data, loading, error }] = useMutation(TEMP_LOGIN);

    const tempLogin = (input) => {
      return tempLoginMutation({
        variables: {
          input: input,
        },
      });
    };

    return { tempLogin, data, loading, error }
  }
  catch(err: any){
    console.error(err);
    return { tempLogin: null, data: null, loading: false, error: err };
  }
}


const SETUP_FIREBASE_TOKEN = gql`
  mutation setupFirebaseToken($input: FirebaseTokenInput) {
    setupFirebaseToken(input: $input)
  }
`

const setupFirebaseTokenMutation = (): { setupFirebaseToken: any, data: any, loading: boolean, error: any } => {
  try{
    const [setupFirebaseTokenMutation, { data, loading, error }] = useMutation(SETUP_FIREBASE_TOKEN);

    const setupFirebaseToken = (input) => {
      return setupFirebaseTokenMutation({
        variables: {
          input: input,
        },
      });
    };

    return { setupFirebaseToken, data, loading, error }
  }
  catch(err: any){
    console.error(err);
    return { setupFirebaseToken: null, data: null, loading: false, error: err };
  }
}

export {
  useLoginMutation,
  useVerifyAccessTokenMutation,
  useTempLoginMutation,
  setupFirebaseTokenMutation
}