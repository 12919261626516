import React, { useState } from "react";
import { Button } from "antd";
import * as FileSaver from "file-saver";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getUserTableAdminSettingExportData } from "../../../../../../api/graphql/tableSetting";

const ExportDBTableButton = (props) => {
    const {tableName} = props
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const { data: adminTableSetting } = getUserTableAdminSettingExportData()

    const handleExport = (name) => {
        setLoading(true)
        const currentDate = moment().format("YYYYMMDDHHmm");
        let data:any = null
        if(tableName === "admintablesetting"){
            data = adminTableSetting?.getUserTableAdminSettingExportData
        }

        var blob = new Blob([JSON.stringify(data)], {type: "application/json"});
        if(blob){
            FileSaver.saveAs(blob, `${name}_${currentDate}`);
            setLoading(false)
        }
    }

    return (
        <Button
            onClick={() => handleExport(tableName)}
            loading={loading}
            disabled={!adminTableSetting?.getUserTableAdminSettingExportData?.length || loading}
        >
            {t("common.exportAdminTableSetting")}
        </Button>
    );
}

export default ExportDBTableButton