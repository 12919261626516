export function formatMoney(amount: any, mode?, digits = 2) {
    let amountParse = parseFloat(amount)
    let result = (amountParse)?.toFixed(digits)?.replace(/\d(?=(\d{3})+\.)/g, '$&,')
    let response = "";
    switch (mode) {
        case "%":
            response = result + "%"
            break;
        case "$":
            response = "$"+result
            break;
        default:
            response = result
    }

    return response;
}