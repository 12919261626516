import React, {useEffect, useMemo, useState} from "react";
import {useSetRecoilState} from "recoil";
import {Button, Col, Form, Modal, Row} from "antd";
import {FormProvider, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {CloseOutlined} from "@ant-design/icons";
import yupFormSchema from "../../../../../../components/yup/yupFormSchema";
import {horizontalFormLayout} from "../../../../../../components/form/formWrapper";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import useAuthorize from "../../../../../../_common/authorize";
import SelectFormItem from "../../../../../../components/form/selectFormItem";
import {removeTypenameKey} from "../../../../../../function/_common";
import {notificationMessage} from "../../../../../../../recoil_state";
import SwitchFormItem from "../../../../../../components/form/switchFormItem";
import {addMamPammProvider, updateMamPammProvider} from "../../../../../../api/graphql/mamPammProvider";

const MamPammProviderModal = (props) => {
    const {isOpen, closeModal, mode, item, serverData} = props;
    const {t} = useTranslation();
    const [values, setValues] = useState<any>({});

    const {systemErrorNotification} = useAuthorize();
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const yupSchema = yup.object().shape({
        name: yupFormSchema.string("name", {required: true}),
        server: yupFormSchema.string("server", {required: true}),
        provider: yupFormSchema.string("provider", {required: true}),
        isActive: yupFormSchema.boolean("isActive"),
    });

    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
    });

    const setupInitialValues = async () => {
        try {
            let formValues: any = {
                name: item?.name,
                server: item?.server?._id,
                provider: item?.provider,
                isActive: item?.isActive,
            };
            form.setValue("name", item?.name);
            form.setValue("server", item?.server?._id);
            form.setValue("provider", item?.provider);
            form.setValue("isActive", item?.isActive);
            setValues(formValues);
        } catch (error) {
            console.log(error);
        }
    };

    const handleValueChange = async (field, value) => {
        try {
            form.setValue(field, value);
            setValues((prevState) => ({...prevState, [field]: value}));
        } catch (error) {}
    };

    const onSubmit = async () => {
        try {
            let input = values;
            input = await removeTypenameKey(input);
            delete input.serverPlatform;
            const res = mode === "Add" ? await addMamPammProvider(input) : await updateMamPammProvider(item?.id || item?._id, input);
            const response = mode === "Add" ? res?.addMamPammProvider : res?.updateMamPammProvider;
            if (response?.__typename === "MamPammProvider") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: mode === "Add" ? t("common.addSuccess") : t("common.updateSuccess"),
                    key: "addUpdateMamPammProvider",
                });
                closeModal();
            } else if (response?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.errKey),
                    key: "addUpdateMamPammProvider",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.msg),
                    key: "addUpdateMamPammProvider",
                });
                closeModal();
            }
        } catch (error: any) {
            systemErrorNotification();
        }
    };

    useEffect(() => {
        setupInitialValues();
    }, [item, isOpen]);
    
    const serverPlatform = serverData?.find((server) => server?._id === values?.server)?.platform;

    const providerOptions = useMemo(() => {
        let options: any = null;
        switch (serverPlatform) {
            case "mt5":
                options = [{label: "easyMAM", value: "easyMAM"}];
                break;
            default:
                options = null;
                break;
        }
        return options;
    }, [values?.server, serverData]);

    const serverOptions = useMemo(() => {
        return serverData ? [...serverData].map((v) => ({label: v?.name, value: v?._id})) : null;
    }, [serverData, values]);

    return (
        <Modal
            title={t(`${mode} MAM/PAMM Provider`)}
            getContainer="#mamPammProvider"
            open={isOpen}
            onCancel={() => closeModal()}
            footer={null}
            width={"60%"}
            destroyOnClose
        >
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="form-1">
                    <Row gutter={[16, 16]} align={"bottom"}>
                        <Col md={24}>
                            <Row gutter={[16, 16]} align={"bottom"}>
                                <Col span={12}>
                                    <SelectFormItem
                                        name={"server"}
                                        label={t("Server")}
                                        options={serverOptions}
                                        optionFilterProp={"label"}
                                        showSearch
                                        required
                                        disabled={mode === "view"}
                                        value={values?.server}
                                        onChange={(value) => handleValueChange("server", value)}
                                    />
                                </Col>
                                <Col span={12}>
                                    <InputFormItem
                                        name={"serverPlatform"}
                                        label={t("Server Platform")}
                                        placeholder={t("Server Platform")}
                                        layout={horizontalFormLayout}
                                        disabled={true}
                                        value={serverPlatform ? t(`common.${serverPlatform}`) : null}
                                    />
                                </Col>
                                <Col span={12}>
                                    <SelectFormItem
                                        name={"provider"}
                                        label={t("Provider")}
                                        options={providerOptions}
                                        optionFilterProp={"label"}
                                        showSearch
                                        required
                                        disabled={mode === "view"}
                                        value={values?.provider}
                                        onChange={(value) => handleValueChange("provider", value)}
                                    />
                                </Col>
                                <Col span={12}>
                                    <InputFormItem
                                        name={"name"}
                                        label={t("Name")}
                                        placeholder={t("Name")}
                                        layout={horizontalFormLayout}
                                        required
                                        disabled={mode === "view"}
                                        onChange={(value) => handleValueChange("name", value)}
                                    />
                                </Col>
                                <Col md={12}>
                                    <SwitchFormItem
                                        name={"isActive"}
                                        label={t("Is Active")}
                                        labelAlign={"left"}
                                        disabled={mode === "view"}
                                        labelCol={{span: 24, offset: 0}}
                                        value={values?.isActive}
                                        onChange={(value) => handleValueChange("isActive", value)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Form.Item className="text-align-right" style={{paddingTop: "12px"}}>
                        {closeModal && (
                            <Button onClick={closeModal} icon={<CloseOutlined />} className="button-cancel-1">
                                {t("common.cancel")}
                            </Button>
                        )}
                        <Button onClick={form.handleSubmit(onSubmit)} className="button-submit-1">
                            {t("common.submit")}
                        </Button>
                    </Form.Item>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default MamPammProviderModal;
