import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Col, Row} from "antd";
import ElementsDrawer from "../components/elementsDrawer";
import {Responsive, WidthProvider} from "react-grid-layout";
import {useRecoilValue} from "recoil";
import {elementDrawerIsOpen, layoutEditableState} from "../../state/state";
import GridItem from "../components/gridItem";
import ElementContextMenu from "../components/contextMenu/elementContextMenu";
import _ from "lodash"
import podium from "../../assets/podium.png"

const LeaderboardSettingsUIEditor_1 = ({data}) => {
    const ResponsiveGridLayout = WidthProvider(Responsive);

    // Testing data
    const EXAMPLE = useMemo(() => {
        return {
            interfaceDetail: [
                {
                    type: "text",
                    layoutId: "text-1",
                    value: "A B C D E F G",
                    setting: {
                        fontSize: 14,
                        fontWeight: 400,
                        italic: false,
                        underline: false,
                        color: "#494242",
                        backgroundColor: "transparent",
                        link: null,
                        textAlign: "left",
                        list: "",
                        textIndent: 0,
                        letterSpacing: 0,
                        lineHeight: "normal"
                    },
                    layout: {
                        lg: {
                            w: 12,
                            h: 2,
                            x: 0,
                            y: 0,
                        },
                        md: {
                            w: 6,
                            h: 2,
                            x: 0,
                            y: 0,
                        },
                        sm: {
                            w: 2,
                            h: 2,
                            x: 0,
                            y: 0,
                        }
                    }
                },
                {
                    type: "text",
                    layoutId: "text-2",
                    value: "H I J K L M N",
                    setting: {
                        fontSize: 14,
                        fontWeight: 400,
                        italic: false,
                        underline: false,
                        color: "#000000",
                        backgroundColor: "transparent",
                        link: null,
                        textAlign: "left",
                        list: "",
                        textIndent: 0,
                        letterSpacing: 0,
                        lineHeight: "normal"
                    },
                    layout: {
                        lg: {
                            w: 12,
                            h: 3,
                            x: 0,
                            y: 1,
                        },
                        md: {
                            w: 6,
                            h: 2,
                            x: 0,
                            y: 1,
                        },
                        sm: {
                            w: 2,
                            h: 4,
                            x: 0,
                            y: 2,
                        }
                    },
                }
            ]
        }
    }, [])

    const [layout, setLayout] = useState<any>(null)
    const layoutRef = useRef(layout);
    const [currentBreakpoint, setCurrentBreakpoint] = useState<"sm" | "md" | "lg">("lg");
    const [currentWidth, setCurrentWidth] = useState<380 | 780 | "100%">("100%");
    const [currentDroppingItem, setCurrentDroppingItem] = useState<any>({i: "dropping-item", w: 1, h: 1})
    const [detailData, setDetailData] = useState<any>(null);
    const isElementDrawerOpen = useRecoilValue(elementDrawerIsOpen)
    const layoutEditable = useRecoilValue(layoutEditableState)

    const addSectionHandler = () => {
        const newItem = {
            i: `${layoutRef?.current?.[currentBreakpoint]?.length + 1}`,
            x: 0,
            y: Infinity,
            w: 12,
            h: 2
        }

        const newLayout = {...layoutRef.current, [currentBreakpoint]: [...layoutRef.current?.[currentBreakpoint], newItem]}
        layoutRef.current = newLayout;
        setLayout(newLayout)
    }

    const onStartDropDrag = (e, key) => {
        let item: any
        switch (key) {
            case "text":
                item = {i: "text", w: 4, h: 2}
                break
            case "image":
                item = {i: "image", w: 2, h: 4}
                break
            case "blank":
                item = {i: "blank", w: 1, h: 1}
                break
            case "ranking":
                item = {i: "ranking", w: 6, h: 16}
                break
            case "podium":
                item = {i: "podium", w: 6, h: 10}
                break
            case "legend":
                item = {i: "legend", w: 6, h: 6}
                break
        }

        e.dataTransfer.setData("text/plain", "")
        setCurrentDroppingItem(item)
    }

    const onLayoutChange = useCallback((newLayout, allLayouts) => {
        if (_.isEqual(allLayouts, layoutRef.current)) {
            return
        }

        if (newLayout?.length === layoutRef.current?.[currentBreakpoint]?.length) {
            layoutRef.current = allLayouts;
            setLayout(layoutRef.current)
        }
    }, [currentBreakpoint])

    const drawerProps = {
        setCurrentWidth: setCurrentWidth,
        currentWidth: currentWidth,
        onStartDropDrag: onStartDropDrag
    }

    const children = React.useMemo(() => {
        return layoutRef.current?.[currentBreakpoint]?.map(d => (
            <div key={d?.i} id={`grid-element-${d?.i}`} className={`grid-item-wrapper type-${d?.i?.split("-")?.[0]}`}>
                <GridItem gridLayout={d} data={detailData} setDetailData={setDetailData} currentBreakpoint={currentBreakpoint}/>
            </div>
        ))
    }, [layoutRef.current?.[currentBreakpoint]?.length, detailData, currentBreakpoint]);

    const defaultSetting = (type) => {
        let setting
        switch (type) {
            case "text":
                setting = {
                    fontSize: 14,
                    fontWeight: 400,
                    italic: false,
                    underline: false,
                    color: "#000000",
                    backgroundColor: "transparent",
                    link: null,
                    textAlign: "left",
                    list: "",
                    textIndent: 0,
                    letterSpacing: 0,
                    lineHeight: "normal"
                }
                break
            case "image":
                setting = {
                    sizeAuto: true,
                    width: "auto",
                    height: "auto",
                    alt: "",
                    tooltip: "",
                    src: null
                }
                break
            case "blank":
                setting = {}
                break
            case "ranking":
                setting = {
                    showImage: true,
                    displayImage: "avatar",
                    maxDisplayCount: 500,
                    showPerPage: 10,
                    layout: "layout-1",
                    borderColor: "--border-color",
                    dataColor: "--data-color",
                    accentColor: "--accent-color",
                }
                break
            case "legend":
                setting = {
                    layout: "fixed",
                    span: 6,
                    sm: 24,
                    md: 12,
                    lg: 6,
                    borderColor: "--border-color",
                    borderWidth: 0,
                    borderRadius: 0,
                    backgroundColor: "--background-color",
                    textColor: "--textColor"
                }
                break
            case "podium":
                setting = {
                    displayCount: 3,
                    showUsername: true,
                    showData: true,
                    showImage: true,
                    displayImage: "avatar",
                    showPodiumImage: true,
                    podiumImage: podium,
                    sort: ["1", "2", "3"]
                }
                break
        }

        return setting
    }

    const onDrop = (layout, layoutItem, _event) => {
        const {x, y, w, h} = layoutItem
        let layoutSetting = {}
        switch (layoutItem?.i) {
            case "podium":
                layoutSetting = {minH: 10, minW: 6}
                break
            case "ranking":
                layoutSetting = {minH: 15, minW: 6}
                break
            case "legend":
                layoutSetting = {minH: 6, minW: 6}
                break
        }

        setDetailData(prev => {
            return {
                interfaceDetail: [
                    ...prev?.interfaceDetail,
                    {
                        type: layoutItem?.i,
                        layoutId: `${layoutItem?.i}-${layout?.length}`,
                        value: null,
                        layout: {
                            lg: {
                                x, y, w, h, ...layoutSetting
                            },
                            md: {
                                x, y, w, h, ...layoutSetting
                            },
                            sm: {
                                x, y, w, h, ...layoutSetting
                            }
                        },
                        setting: defaultSetting(layoutItem?.i)
                    }
                ]
            }
        })

        const item = {...layoutItem, i: `${layoutItem?.i}-${layout?.length}`, ...layoutSetting}

        const newLayout = {...layoutRef.current};
        Object.keys(newLayout).forEach((breakpoint) => {
            newLayout[breakpoint] = [...(layoutRef.current[breakpoint] || []), item];
        });

        layoutRef.current = newLayout
        setLayout(newLayout)
    };

    const transformData = (data) => {
        const result = {lg: [], md: [], sm: []};

        if (data?.interfaceDetail) {
            data.interfaceDetail.forEach(item => {
                const {layoutId, layout} = item;

                Object.keys(layout).forEach(size => {
                    result[size].push({
                        ...layout[size],
                        i: layoutId,
                    });
                });
            });
        }

        return result;
    };

    useEffect(() => {
        if (!detailData) {
            setDetailData(EXAMPLE)
            const result = transformData(EXAMPLE)

            layoutRef.current = result
            setLayout(result)
        }
    }, [EXAMPLE]);

    useEffect(() => {
        if (layout) {
            setLayout(prev => {
                const result = Object.keys(prev)?.reduce((acc, key) => {
                    acc[key] = layout?.[key]?.map(d => ({
                        ...d,
                        isDraggable: layoutEditable,
                        isResizable: layoutEditable
                    }));
                    return acc;
                }, {});

                layoutRef.current = result
                return result
            })

        }
    }, [layoutEditable])

    const onBreakpointChange = (newBP) => {
        if (newBP !== currentBreakpoint) setCurrentBreakpoint(newBP)
    }

    return (
        <div id={"leaderboard-setting-ui-editor"}>
            <Row gutter={[16, 16]} style={{transition: "all .3s", width: isElementDrawerOpen ? "calc(100% - 280px)" : "100%"}}>
                <Col span={24} className={"d-flex"}>
                    <div className={`canvas-container margin-left-auto margin-right-auto ${layoutEditable ? "layout-editable" : ""}`}
                         style={{width: currentWidth}}
                        // onContextMenu={(e) => e.preventDefault()}
                    >
                        <ResponsiveGridLayout
                            margin={[8, 8]}
                            layouts={layout || {}}
                            rowHeight={30}
                            className={"position-relative"}
                            onLayoutChange={onLayoutChange}
                            useCSSTransforms={false}
                            breakpoints={{sm: 0, md: 767, lg: 996}}
                            onBreakpointChange={onBreakpointChange}
                            cols={{lg: 12, md: 6, sm: 2}}
                            onDrop={onDrop}
                            isDroppable={layoutEditable}
                            // onDropDragOver={onDropDragOver}
                            droppingItem={currentDroppingItem}
                            isDraggable={layoutEditable}
                            isResizable={layoutEditable}
                        >
                            {children}
                        </ResponsiveGridLayout>

                        {/*<Button style={{height: 80}} block type={"dashed"} onClick={addSectionHandler}>+ New Element</Button>*/}
                    </div>
                </Col>

                <ElementsDrawer {...drawerProps}/>
                <ElementContextMenu detailData={detailData} setDetailData={setDetailData}/>
            </Row>
        </div>
    )
}

export default LeaderboardSettingsUIEditor_1;