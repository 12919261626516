import React, {useEffect} from "react";
import BadgeSetting_1 from "./view/layout1";
import {useGetBadgeSetting} from "./function/useGetQueryHooks";

const BadgeSetting = () => {
    let component, layout = 1
    const [badges, loading, fetchBadges] = useGetBadgeSetting()

    useEffect(() => {
        fetchBadges()
    }, [])

    const props = {badges, loading, fetchBadges}

    switch (layout) {
        case 1:
            component = <BadgeSetting_1 {...props}/>
            break
    }

    return component
}

export default BadgeSetting