import { gql, useMutation, useQuery } from "@apollo/client";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {useTranslation} from "react-i18next";

const GET_SYMBOL_COUNT = gql`
  query getSymbolCount($filter: SymbolInput) {
    getSymbolCount(filter: $filter)
  }
`;

const getSymbolCount = ({ filter }) => {
  try {
    const { loading, error, data } = useQuery(GET_SYMBOL_COUNT, {
      variables: {
        filter: {
          symbolId: filter?.symbolId || null,
          name: filter?.name || null,
          serverName: filter?.serverName || null,
          serverMode: filter?.serverMode || null
        },
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_SYMBOL_BY_ID = gql`
  query getSymbolByID($id: String!) {
    getSymbolByID(id: $id) {
      ... on SymbolWithId {
        id
        symbolId
        name
        digits
        pipPosition
        lpEnabled
        enabled
        bidMarkUp
        askMarkUp
        description
        sortingNumber
        utcLastUpdateTimestamp
        measurementUnits
        quoteExpirationTimeout
        lotSize
        enableShortSelling
        symbolCategoryId {
          symbolCategoryId
          assetClassId
          name
          color
          sortingNumber
          expanded
          isDefault
          isDomEnabled
        }
        favorite
        tags
        baseAssetId {
          assetId
          name
          description
          type
          depositAsset
          depositable
          displayName
          major
          digits
          calendarAlias
        }
        quoteAssetId {
          assetId
          name
          description
          type
          depositAsset
          depositable
          displayName
          major
          digits
          calendarAlias
        }
        tradingMode
        defaultSwapRollover3Days
        defaultSwapLong
        defaultSwapShort
        defaultLeverageId {
          leverageId
          name
          description
          tiers {
            volume
            hasVolume
            leverage
            hasLeverage
          }
          utcLastUpdateTimestamp
        }
        priceLiquidityFeedId {
          liquidityFeedId
          name
          type
          configurationSuffix
          enabled
        }
        tradeLiquidityFeedId {
          liquidityFeedId
          name
          type
          configurationSuffix
          enabled
        }
        defaultDividendTime
        scheduleProfileId {
          scheduleProfileId
          name
          description
          scheduleTimeZone
          interval {
            startSecond
            hasStartSecond
            endSecond
            hasEndSecond
            isGlobalSessionStart
            hasIsGlobalSessionStart
            isGlobalSessionEnd
            hasIsGlobalSessionEnd
          }
          utcLastUpdateTimestamp
        }
        defaultCommissionProfileId {
          commissionProfileId
          name
          description
          utcLastUpdateTimestamp
          commissionType
          minCommissionType
          minCommissionAssetId
          preciseTradingCommissionRate
          preciseMinCommission
          pnlConversionFeeRate
          avoidMinCommissionOnStopOut
        }
        defaultVolumeProfileId {
          volumeProfileId
          name
          description
          minVolume
          stepVolume
          maxVolume
          minStake
          maxStake
          maxExposure
          utcLastUpdateTimestamp
        }
        defaultExecutionProfileId {
          executionProfileId
          name
          description
          utcLastUpdateTimestamp
          minBookBDelay
          maxBookBDelay
          executionPolicy
          gslExecutionPolicy
          conditionVolumeUsd
        }
        autochartistAlias
        tradingCentralAlias
        defaultProtectionProfileId {
          protectionProfileId
          name
          description
          gslCharge
          slDistance
          tpDistance
          gslDistance
          distanceSetIn
          tolerance
        }
        defaultSwapFreeProfileId {
          swapFreeProfileId
          name
          description
          rolloverCommission
          skipRolloverDays
          rolloverChargePeriod
          rolloverCommission3Days
          utcLastUpdateTimestamp
        }
        defaultGSLScheduleId {
          scheduleId
          name
          tiers {
            startTime
            multiplier
          }
        }
        holidayProfileId {
          holidayProfileId
          name
          description
          holidayId
          utcLastUpdateTimestamp
        }
        bbspAlias
        defaultTradeNotificationProfileId {
          tradeNotificationId
          name
          description
          bookType
          notifyAtCbroker
          notifyAtEmail
          usdVolumeCbroker
          usdVolumeEmail
          utcLastUpdateTimestamp
          emails
        }
        defaultSwapTime
        defaultSwapPeriod
        translation {
          locale
          title
        }
        minimumSpreadConstraint {
          enabled
          threeholdDigits
          bias
        }
        futuresSettings {
          datesTimeZone
          lastTradeTimestampInMinutes
          expirationTimestampInMinutes
        }
        server {
          name
        }
        createdBy {
          username
        }
        updatedBy {
          username
        }
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getSymbolById = (id) => {
  try {
    const { loading, error, data } = useQuery(GET_SYMBOL_BY_ID, {
      variables: {
        id: id,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_SYMBOL = gql`
  query getSymbol(
    $filter: SymbolInput
    $limit: Int
    $offset: Int
    $orderBy: String
  ) {
    getSymbol(
      filter: $filter
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      ... on SymbolArray {
        data {
          _id
          id
          symbolId
          name
          digits
          pipPosition
          lpEnabled
          enabled
          bidMarkUp
          askMarkUp
          description
          sortingNumber
          utcLastUpdateTimestamp
          measurementUnits
          quoteExpirationTimeout
          lotSize
          enableShortSelling
          symbolCategoryId {
            symbolCategoryId
            assetClassId
            name
            color
            sortingNumber
            expanded
            isDefault
            isDomEnabled
          }
          favorite
          tags
          baseAssetId {
            assetId
            name
            description
            type
            depositAsset
            depositable
            displayName
            major
            digits
            calendarAlias
          }
          quoteAssetId {
            assetId
            name
            description
            type
            depositAsset
            depositable
            displayName
            major
            digits
            calendarAlias
          }
          tradingMode
          defaultSwapRollover3Days
          defaultSwapLong
          defaultSwapShort
          defaultLeverageId {
            leverageId
            name
            description
            tiers {
              volume
              hasVolume
              leverage
              hasLeverage
            }
            utcLastUpdateTimestamp
          }
          priceLiquidityFeedId {
            liquidityFeedId
            name
            type
            configurationSuffix
            enabled
          }
          tradeLiquidityFeedId {
            liquidityFeedId
            name
            type
            configurationSuffix
            enabled
          }
          defaultDividendTime
          scheduleProfileId {
            scheduleProfileId
            name
            description
            scheduleTimeZone
            interval {
              startSecond
              hasStartSecond
              endSecond
              hasEndSecond
              isGlobalSessionStart
              hasIsGlobalSessionStart
              isGlobalSessionEnd
              hasIsGlobalSessionEnd
            }
            utcLastUpdateTimestamp
          }
          defaultCommissionProfileId {
            commissionProfileId
            name
            description
            utcLastUpdateTimestamp
            commissionType
            minCommissionType
            minCommissionAssetId
            preciseTradingCommissionRate
            preciseMinCommission
            pnlConversionFeeRate
            avoidMinCommissionOnStopOut
          }
          defaultVolumeProfileId {
            volumeProfileId
            name
            description
            minVolume
            stepVolume
            maxVolume
            minStake
            maxStake
            maxExposure
            utcLastUpdateTimestamp
          }
          defaultExecutionProfileId {
            executionProfileId
            name
            description
            utcLastUpdateTimestamp
            minBookBDelay
            maxBookBDelay
            executionPolicy
            gslExecutionPolicy
            conditionVolumeUsd
          }
          autochartistAlias
          tradingCentralAlias
          defaultProtectionProfileId {
            protectionProfileId
            name
            description
            gslCharge
            slDistance
            tpDistance
            gslDistance
            distanceSetIn
            tolerance
          }
          defaultSwapFreeProfileId {
            swapFreeProfileId
            name
            description
            rolloverCommission
            skipRolloverDays
            rolloverChargePeriod
            rolloverCommission3Days
            utcLastUpdateTimestamp
          }
          defaultGSLScheduleId {
            scheduleId
            name
            tiers {
              startTime
              multiplier
            }
          }
          holidayProfileId {
            holidayProfileId
            name
            description
            holidayId
            utcLastUpdateTimestamp
          }
          bbspAlias
          defaultTradeNotificationProfileId {
            tradeNotificationId
            name
            description
            bookType
            notifyAtCbroker
            notifyAtEmail
            usdVolumeCbroker
            usdVolumeEmail
            utcLastUpdateTimestamp
            emails
          }
          defaultSwapTime
          defaultSwapPeriod
          translation {
            locale
            title
          }
          minimumSpreadConstraint {
            enabled
            threeholdDigits
            bias
          }
          futuresSettings {
            datesTimeZone
            lastTradeTimestampInMinutes
            expirationTimestampInMinutes
          }
          server {
            name
            _id
          }
          createdBy {
            username
          }
          updatedBy {
            username
          }
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getSymbol = ({ filter }, limit?, offset?, orderBy?) => {
  try {
    const { loading, error, data } = useQuery(GET_SYMBOL, {
      variables: {
        filter: {
          symbolId: filter?.symbolId || null,
          name: filter?.name || null,
          serverName: filter?.serverName || null,
          serverMode: filter?.serverMode || null
        },
        limit: limit || null,
        offset: offset || null,
        orderBy: orderBy || null,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_SYMBOL_SHORT = gql`
  query getSymbol(
    $filter: SymbolInput
    $limit: Int
    $offset: Int
    $orderBy: String
  ) {
    getSymbol(
      filter: $filter
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      ... on SymbolArray {
        data {
          _id
          id
          symbolId
          name
          description
          server { _id }
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getSymbolShort = ({ filter }, limit?, offset?, orderBy?) => {
  try {
    const { loading, error, data } = useQuery(GET_SYMBOL_SHORT, {
      variables: {
        filter: {
          symbolId: filter?.symbolId || null,
          name: filter?.name || null,
          serverName: filter?.serverName || null,
        },
        limit: limit || null,
        offset: offset || null,
        orderBy: orderBy || null,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const REFRESH_SYMBOL = gql`
  mutation refreshSymbol($server: String!) {
    refreshSymbol(server: $server) {
      ... on SymbolArray {
        data {
          id
          symbolId
          name
          digits
          pipPosition
          lpEnabled
          enabled
          bidMarkUp
          askMarkUp
          description
          sortingNumber
          utcLastUpdateTimestamp
          measurementUnits
          quoteExpirationTimeout
          lotSize
          enableShortSelling
          symbolCategoryId {
            symbolCategoryId
            assetClassId
            name
            color
            sortingNumber
            expanded
            isDefault
            isDomEnabled
          }
          favorite
          tags
          baseAssetId {
            assetId
            name
            description
            type
            depositAsset
            depositable
            displayName
            major
            digits
            calendarAlias
          }
          quoteAssetId {
            assetId
            name
            description
            type
            depositAsset
            depositable
            displayName
            major
            digits
            calendarAlias
          }
          tradingMode
          defaultSwapRollover3Days
          defaultSwapLong
          defaultSwapShort
          defaultLeverageId {
            leverageId
            name
            description
            tiers {
              volume
              hasVolume
              leverage
              hasLeverage
            }
            utcLastUpdateTimestamp
          }
          priceLiquidityFeedId {
            liquidityFeedId
            name
            type
            configurationSuffix
            enabled
          }
          tradeLiquidityFeedId {
            liquidityFeedId
            name
            type
            configurationSuffix
            enabled
          }
          defaultDividendTime
          scheduleProfileId {
            scheduleProfileId
            name
            description
            scheduleTimeZone
            interval {
              startSecond
              hasStartSecond
              endSecond
              hasEndSecond
              isGlobalSessionStart
              hasIsGlobalSessionStart
              isGlobalSessionEnd
              hasIsGlobalSessionEnd
            }
            utcLastUpdateTimestamp
          }
          defaultCommissionProfileId {
            commissionProfileId
            name
            description
            utcLastUpdateTimestamp
            commissionType
            minCommissionType
            minCommissionAssetId
            preciseTradingCommissionRate
            preciseMinCommission
            pnlConversionFeeRate
            avoidMinCommissionOnStopOut
          }
          defaultVolumeProfileId {
            volumeProfileId
            name
            description
            minVolume
            stepVolume
            maxVolume
            minStake
            maxStake
            maxExposure
            utcLastUpdateTimestamp
          }
          defaultExecutionProfileId {
            executionProfileId
            name
            description
            utcLastUpdateTimestamp
            minBookBDelay
            maxBookBDelay
            executionPolicy
            gslExecutionPolicy
            conditionVolumeUsd
          }
          autochartistAlias
          tradingCentralAlias
          defaultProtectionProfileId {
            protectionProfileId
            name
            description
            gslCharge
            slDistance
            tpDistance
            gslDistance
            distanceSetIn
            tolerance
          }
          defaultSwapFreeProfileId {
            swapFreeProfileId
            name
            description
            rolloverCommission
            skipRolloverDays
            rolloverChargePeriod
            rolloverCommission3Days
            utcLastUpdateTimestamp
          }
          defaultGSLScheduleId {
            scheduleId
            name
            tiers {
              startTime
              multiplier
            }
          }
          holidayProfileId {
            holidayProfileId
            name
            description
            holidayId
            utcLastUpdateTimestamp
          }
          bbspAlias
          defaultTradeNotificationProfileId {
            tradeNotificationId
            name
            description
            bookType
            notifyAtCbroker
            notifyAtEmail
            usdVolumeCbroker
            usdVolumeEmail
            utcLastUpdateTimestamp
            emails
          }
          defaultSwapTime
          defaultSwapPeriod
          translation {
            locale
            title
          }
          minimumSpreadConstraint {
            enabled
            threeholdDigits
            bias
          }
          futuresSettings {
            datesTimeZone
            lastTradeTimestampInMinutes
            expirationTimestampInMinutes
          }
          server {
            name
          }
          createdBy {
            username
          }
          updatedBy {
            username
          }
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useRefreshSymbolMutation = (): {
  refreshSymbol: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const { t } = useTranslation();
    const [useRefreshSymbolMutation, { data, loading, error }] =
      useMutation(REFRESH_SYMBOL);

    const refreshSymbol = (server) => {
      return useRefreshSymbolMutation({
        variables: {
            server: server,
        },
        update(cache, {data: {refreshSymbol}}) {
          const typename = refreshSymbol?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(refreshSymbol?.errKey),
              key: "refreshSymbol",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Refresh Symbol Successfully"),
              key: "refreshSymbol",
            });
          }
        },
        refetchQueries: [GET_SYMBOL, GET_SYMBOL_COUNT]
      });
    };

    return { refreshSymbol, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return { refreshSymbol: null, data: null, loading: false, error: err };
  }

  
};

const GET_SYMBOL_DROPDOWN = gql`
  query getSymbolDropdown($filter: SymbolInput){
    getSymbolDropdown(filter: $filter){
      ... on SymbolArray {
        data {
          _id
          id
          symbolId
          name
          baseAssetId {
            type
          }
          server {
            name
            _id
          }
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getSymbolDropdown = ({filter}) => {
  try {
    const { loading, error, data } = useQuery(GET_SYMBOL_DROPDOWN, {
      variables: {
        filter: filter,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

export {
  getSymbolCount,
  getSymbolById,
  getSymbol,
  useRefreshSymbolMutation,
  getSymbolShort,
  getSymbolDropdown
};
