import React, {useEffect, useState} from "react";
import {getUserLeadsAutoBind} from "../../../../../../api/graphql/leads";
import {columns} from "../../function/tableColumn";
import LeadsTopBar from "../../../_general/component/leadsTopBar";
import {getUserNetworkTree} from "../../../../../../api/graphql/userNetworkTree";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import {useTranslation} from "react-i18next";
import {renderColumns} from "../../../../_common/function";
import {useRecoilValue} from "recoil";
import {selfPermissionObj} from "../../../../../../../recoil_state";

function AutoBindLeads_1(props) {
    const {tableName, profileInformationLead, profileInformationClient} = props
    const {t} = useTranslation()
    const permission = useRecoilValue<any>(selfPermissionObj)

    const [fieldNames, setFieldNames] = useState<any>([])

    const [batchUpdateRecord, setBatchUpdateRecord] = useState<any>([])

    const {data: userNetworkList} = getUserNetworkTree()
    const {data, loading} = getUserLeadsAutoBind(fieldNames)
    const [dataSource, setDataSource] = useState()
    const [column, setColumn] = useState<any>();
    const [leadColumn, setLeadColumn] = useState<any>([])
    const [clientColumn, setClientColumn] = useState<any>([])

    useEffect(() => {
        if (leadColumn?.length > 0 && clientColumn?.length > 0)
        setColumn([...leadColumn, ...clientColumn])
    }, [leadColumn, clientColumn])

    useEffect(() => {
        if (profileInformationLead?.length > 0) {
            renderColumns(profileInformationLead, setLeadColumn, columns, "lead", t, "Lead")
        }
    }, [profileInformationLead]);

    useEffect(() => {
        if (profileInformationClient?.length > 0) {
            renderColumns(profileInformationClient, setClientColumn, [], "client", t, "Client")
        }
    }, [profileInformationClient]);

    useEffect(() => {
        setDataSource(
            data?.getUserLeadsAutoBind?.data?.map(d => {
                return {
                    ...d,
                    ...Object.keys(d?.match || {}).reduce((result, key) => {
                        result[`match_${key}`] = d?.match[key];
                        return result;
                    }, {}),
                }
            })
        )
    },[data?.getUserLeadsAutoBind?.data])

    const onRowSelection = (selectedRowKeys, selectedRows) => {
        setBatchUpdateRecord(selectedRows)
    }

    const fieldsOption = profileInformationLead?.map(d => {
        return {label: t(d?.fieldList?.label), value: d?.fieldList?.fieldName}
    })

    return (
        column &&
        <>
            <h1>{t(tableName)}</h1>
            <LeadsTopBar tableName={tableName}
                         batchUpdateRecord={batchUpdateRecord}
                         setBatchUpdateRecord={setBatchUpdateRecord}
                         userNetworkList={userNetworkList?.getUserNetworkTree?.data}
                         setCheckDuplicateFieldNames={setFieldNames}
                         exportData={data?.getUserLeadsAutoBind?.data}
                         exportColumn={column}
                         fieldsOption={fieldsOption}
                         permission={permission?.user?.leads}
            />
            <TableAntDesign data={dataSource}
                            tableName={tableName}
                            columns={column}
                            size={"small"}
                            loading={loading}
                            selectedRowKeys={batchUpdateRecord?.map(d => d?._id)}
                            selectionType={"checkbox"}
                            onRowSelectionHandler={onRowSelection}
                            pagination={{
                                showSizeChanger: true,
                                total: data?.checkSimilarityByField?.data?.length,
                                showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                            }}
                            isTableLocalSearch={true}
            />
        </>
    )
};

export default AutoBindLeads_1;