import {Button, Col, DatePicker, Form, Row, Select} from "antd";
import {DeleteOutlined, MenuOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {logicalOperator} from "../../../../data/operatorDropdown";
import ScheduleReportFilters from "./scheduleReportFilters";

const ScheduleConditionItem = ({detail, index, restField, onFinish, remove, name, isLast, reportsDropdown, reportFields}) => {
    const [reportFieldOptions, setReportFieldsOptions] = useState<any[]>([])

    const reportOnClick = (form, batch, value) => {
        const formFound = form.current;
        if (formFound) {
            const foundFields = reportFields?.filter(d => d?.reportId === value)
            setReportFieldsOptions(foundFields)
        }
        onFinish(form, batch)
    }

    useEffect(() => {
        const valueFound = detail.form.current.getFieldsValue()?.[`condition${detail.batchId}`]?.[index]
        if (valueFound && reportFieldOptions?.length === 0) {
            reportOnClick(detail.form, detail.batchId, valueFound?.report)
        }
    }, [JSON.stringify(detail?.form?.current?.getFieldsValue())]);

    return (
        <Row align={"top"}>
            <Col span={1}>
                {/*<MenuOutlined className={"sort-handler"} {...dragHandle}/>*/}
                <MenuOutlined />
            </Col>
            <Col span={23}>
                <Row gutter={[16,4]}>
                    <Col span={3}>
                        {
                            index > 0 && (
                                <Form.Item
                                    {...restField}
                                    name={[name, 'logicalOperator']}
                                    initialValue={detail?.condition?.[index - 1]?.logicalOperator}
                                >
                                    <Select
                                        style={{width: "100%"}}
                                        options={logicalOperator}
                                        onChange={() => onFinish(detail.form, detail.batchId)}
                                        allowClear
                                    />
                                </Form.Item>
                            )
                        }
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            {...restField}
                            name={[name, 'report']}
                        >
                            <Select
                                style={{width: "100%"}}
                                options={reportsDropdown?.sort((a, b) => a?.displayName?.localeCompare(b?.displayName))?.map(d => ({label: d?.displayName, value: d?._id}))}
                                disabled={!reportsDropdown?.length}
                                onChange={(value) => reportOnClick(detail.form, detail.batchId, value)}
                                showSearch
                                optionFilterProp={"label"}
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            {...restField}
                            name={[name, 'date']}
                        >
                            <DatePicker.RangePicker
                                style={{width: "100%"}}
                                disabled={[!reportsDropdown?.length, !reportsDropdown?.length]}
                                onChange={() => onFinish(detail.form, detail.batchId)}
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                    <Col span={3} className={"text-align-right"}>
                        {
                            index > 0 && (
                                <Button type={"link"}
                                        style={{padding: "unset"}}
                                        danger
                                        icon={<DeleteOutlined/>}
                                        onClick={() => {
                                            remove(name)
                                            onFinish(detail.form, detail.batchId)
                                        }}
                                />
                            )
                        }
                    </Col>
                    <Col span={24} className={"margin-bottom-0-75"}>
                        <ScheduleReportFilters reportFields={reportFieldOptions} onFieldsChange={() => onFinish(detail.form, detail.batchId)} restField={restField} name={name} detail={detail} conditionIndex={index}/>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default ScheduleConditionItem