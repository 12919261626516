import React from "react";
import {Col, Collapse, Spin} from "antd";
import {useTranslation} from "react-i18next";
import CommonPanel from "./sider/commonPanel";
import SymbolSettingPanel from "./sider/symbolSettingPanel";
import TradingAccountPage from "../../../../../accounts/tradingAccount/_general";

const {Panel} = Collapse;

const SpreadGroupSider = (props) => {
    const {initialData, server} = props;
    const {t} = useTranslation();

    return (
        initialData ? (
            <Collapse accordion size={"small"}>
                <Panel showArrow={false} header={initialData?.name || t("Detail")} key="common">
                    <CommonPanel initialData={initialData}/>
                </Panel>
                <Panel
                    showArrow={false}
                    header={"Symbol Settings"}
                    key={"symbolSettings"}
                >
                    <SymbolSettingPanel server={server} initialData={initialData}/>
                </Panel>
                <Panel
                    showArrow={false}
                    header={"Accounts"}
                    key={"accounts"}
                >
                    <TradingAccountPage spreadGroupId={initialData?.id}/>
                    {/*<AccountsPanel spreadGroup={initialData?.name}/>*/}
                </Panel>
            </Collapse>
        ) : (
            <Col span={24} style={{marginTop: "20%", textAlign: "center"}}>
                <Spin size="large"/>
            </Col>
        )
    );
};

export {SpreadGroupSider};
