import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../recoil_state";
import {stepSettingModalPropState, userVerificationAvailableFieldState, userVerificationStepState} from "../state";
import {
    createUserVerificationDocumentGroupSetting,
    createUserVerificationFieldSetting,
    createUserVerificationSteps,
    deleteUserVerificationCountryFlow,
    deleteUserVerificationDocumentGroupSetting,
    deleteUserVerificationFieldSetting,
    deleteUserVerificationSteps,
    duplicateUserVerificationCountryFlow,
    getUserVerificationAvailableFieldSetting,
    getUserVerificationDocumentGroupSetting,
    getUserVerificationFieldSetting,
    getUserVerificationSteps,
    sortUserVerificationFieldSetting,
    sortUserVerificationSteps,
    updateUserVerificationDocumentGroupSetting,
    updateUserVerificationFieldSetting,
    updateUserVerificationSteps
} from "../../../../api/graphql/userVerification";
import useAuthorize from "../../../../_common/authorize";
import {getLanguage} from "../../../../api/graphql/language";

const useFlowBuilderFunction = () => {
    const setNotificationMessage = useSetRecoilState(notificationMessage)
    const setStep = useSetRecoilState(userVerificationStepState)
    const setAvailableField = useSetRecoilState(userVerificationAvailableFieldState)
    const setStepProp = useSetRecoilState<any>(stepSettingModalPropState)
    const {baseErrorChecking, systemErrorNotification} = useAuthorize()
    const {refreshQuery}: any = getLanguage({filter: null})

    const getUserVerificationAvailableFieldFunction = async (country) => {
        try {
            const response: any = await getUserVerificationAvailableFieldSetting({country: country});
            if (response?.getUserVerificationAvailableFieldSetting?.data) {
                const data = response?.getUserVerificationAvailableFieldSetting?.data?.[0];
                setAvailableField(data);
            }
        } catch (e) {
            systemErrorNotification()
        }
    };

    const getUserVerificationStepsFunction = async (filter?) => {
        try {
            const response: any = await getUserVerificationSteps(filter);
            if (response?.getUserVerificationSteps?.data) {
                const step = response?.getUserVerificationSteps?.data;
                setStep(step);
            }
        } catch (e) {
            systemErrorNotification()
        }
    };

    const createUserVerificationStepsFunction = async (input, currentCountry) => {
        try {
            const group = input["group"];
            delete input["group"];
            const response: any = await createUserVerificationSteps(input);
            if (response.createUserVerificationSteps.__typename === "UserVerificationSteps") {
                if (group?.length > 0) {
                    const promises = group.map(id => createUserVerificationDocumentGroupSetting({
                        group: id,
                        step: response.createUserVerificationSteps?._id
                    }));
                    await Promise.all(promises);
                }

                await refreshQuery();
                const availableField: any = await getUserVerificationAvailableFieldSetting({country: currentCountry});
                setAvailableField(availableField?.getUserVerificationAvailableFieldSetting?.data?.[0]);

                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Create Step Successfully",
                    key: "userVerificationNotification",
                });

                return {
                    status: "success",
                };
            } else {
                return await baseErrorChecking(response?.createUserVerificationSteps);
            }
        } catch (e) {
            systemErrorNotification();
        }
    };


    const updateUserVerificationStepsFunction = async (id, input, currentCountry) => {
        try {
            const response: any = await updateUserVerificationSteps(id, input)
            if (response.updateUserVerificationSteps.__typename === "UserVerificationSteps") {
                await refreshQuery()
                const availableField: any = await getUserVerificationAvailableFieldSetting({country: currentCountry});
                setAvailableField(availableField?.getUserVerificationAvailableFieldSetting?.data?.[0]);

                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Update Step Successfully",
                    key: "userVerificationNotification",
                });

                return {
                    status: "success",
                }
            } else {
                return await baseErrorChecking(response?.updateUserVerificationSteps)
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    const deleteUserVerificationStepsFunction = async (id, currentCountry) => {
        try {
            const response: any = await deleteUserVerificationSteps(id)
            if (response.deleteUserVerificationSteps.__typename === "UserVerificationSteps") {
                const availableField: any = await getUserVerificationAvailableFieldSetting({country: currentCountry});
                setAvailableField(availableField?.getUserVerificationAvailableFieldSetting?.data?.[0]);

                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Delete Step Successfully",
                    key: "userVerificationNotification",
                });

                return {
                    status: "success",
                }
            } else {
                return await baseErrorChecking(response?.deleteUserVerificationSteps)
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    const sortUserVerificationStepsFunction = async (ids, clientType, country, currentCountry) => {
        try {
            const response: any = await sortUserVerificationSteps(ids, clientType, country)
            if (response.sortUserVerificationSteps.__typename === "UserVerificationStepsArray") {
                const availableField: any = await getUserVerificationAvailableFieldSetting({country: currentCountry});
                setAvailableField(availableField?.getUserVerificationAvailableFieldSetting?.data?.[0]);

                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Sort Step Successfully",
                    key: "userVerificationNotification",
                });

                return {
                    status: "success",
                }
            } else {
                return await baseErrorChecking(response?.sortUserVerificationSteps)
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    const getUserVerificationDocumentGroupFunction = async (filter?) => {
        try {
            const response: any = await getUserVerificationDocumentGroupSetting(filter);
            if (response?.getUserVerificationDocumentGroupSetting?.data) {
                //     const data = response?.getUserVerificationDocumentGroupSetting?.data;
                //     setDocumentGroup(data);
                return response?.getUserVerificationDocumentGroupSetting?.data
            }
        } catch (e) {
            systemErrorNotification()
        }
    };

    const createUserVerificationDocumentGroupFunction = async (input, currentCountry, clientType?) => {
        try {
            const response: any = await createUserVerificationDocumentGroupSetting(input)
            if (response?.createUserVerificationDocumentGroupSetting?.__typename === "UserVerificationDocumentGroupSetting") {
                await refreshQuery()
                const availableField: any = await getUserVerificationAvailableFieldSetting({country: currentCountry});
                const ary: any = availableField?.getUserVerificationAvailableFieldSetting?.data?.[0]
                await setAvailableField(ary);

                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Create Document Group Setting Successfully",
                    key: "userVerificationNotification",
                });

                console.log(ary?.clientType?.find(d => d?.clientType === clientType)?.steps?.find(d => d?._id === input?.step))

                setStepProp({
                    isAdd: false,
                    isOpen: true,
                    close: () => {
                        setStepProp({isOpen: false})
                    },
                    item: {
                        country: ary?._id,
                        type: clientType,
                        step: ary?.clientType?.find(d => d?.clientType === clientType)?.steps?.find(d => d?._id === input?.step)
                    }
                })

                return {
                    status: "success",
                }
            } else {
                return await baseErrorChecking(response?.createUserVerificationDocumentGroupSetting)
            }
        } catch (e) {
            console.log(e)
            systemErrorNotification()
        }
    }

    const updateUserVerificationDocumentGroupFunction = async (id, input, currentCountry) => {
        try {
            const response: any = await updateUserVerificationDocumentGroupSetting(id, input)
            if (response.updateUserVerificationDocumentGroupSetting.__typename === "UserVerificationDocumentGroupSetting") {
                await refreshQuery()
                const availableField: any = await getUserVerificationAvailableFieldSetting({country: currentCountry});
                setAvailableField(availableField?.getUserVerificationAvailableFieldSetting?.data?.[0]);

                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Update Document Group Setting Successfully",
                    key: "userVerificationNotification",
                });

                return {
                    status: "success",
                }
            } else {
                return await baseErrorChecking(response?.updateUserVerificationDocumentGroupSetting)
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    const deleteUserVerificationDocumentGroupFunction = async (id, currentCountry) => {
        try {
            const response: any = await deleteUserVerificationDocumentGroupSetting(id)
            if (response.deleteUserVerificationDocumentGroupSetting.__typename === "UserVerificationDocumentGroupSetting") {
                const availableField: any = await getUserVerificationAvailableFieldSetting({country: currentCountry});
                setAvailableField(availableField?.getUserVerificationAvailableFieldSetting?.data?.[0]);

                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Delete Document Group Setting Successfully",
                    key: "userVerificationNotification",
                });

                return {
                    status: "success",
                }
            } else {
                return await baseErrorChecking(response?.deleteUserVerificationDocumentGroupSetting)
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    const getUserVerificationFieldFunction = async (filter?) => {
        try {
            const response: any = await getUserVerificationFieldSetting(filter);
            if (response?.getUserVerificationFieldSetting?.data.__typename === "UserVerificationFieldSettingArray") {
                return response?.getUserVerificationFieldSetting?.data
            }
        } catch (e) {
            systemErrorNotification()
        }
    };

    const createUserVerificationFieldFunction = async (input, currentCountry) => {
        try {
            const response: any = await createUserVerificationFieldSetting(input)
            if (response.createUserVerificationFieldSetting.__typename === "UserVerificationFieldSetting") {
                await refreshQuery()
                const availableField: any = await getUserVerificationAvailableFieldSetting({country: currentCountry});
                setAvailableField(availableField?.getUserVerificationAvailableFieldSetting?.data?.[0]);

                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Create Field Setting Successfully",
                    key: "userVerificationNotification",
                });

                return {
                    status: "success",
                }
            } else {
                return await baseErrorChecking(response?.createUserVerificationFieldSetting)
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    const updateUserVerificationFieldFunction = async (id, input, currentCountry) => {
        try {
            const response: any = await updateUserVerificationFieldSetting(id, input)
            if (response.updateUserVerificationFieldSetting.__typename === "UserVerificationFieldSetting") {
                await refreshQuery()
                const availableField: any = await getUserVerificationAvailableFieldSetting({country: currentCountry});
                setAvailableField(availableField?.getUserVerificationAvailableFieldSetting?.data?.[0]);

                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Update Field Setting Successfully",
                    key: "userVerificationNotification",
                });

                return {
                    status: "success",
                }
            } else {
                return await baseErrorChecking(response?.updateUserVerificationFieldSetting)
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    const deleteUserVerificationFieldFunction = async (id, currentCountry) => {
        try {
            const response: any = await deleteUserVerificationFieldSetting(id)
            if (response.deleteUserVerificationFieldSetting.__typename === "UserVerificationFieldSetting") {
                const availableField: any = await getUserVerificationAvailableFieldSetting({country: currentCountry});
                setAvailableField(availableField?.getUserVerificationAvailableFieldSetting?.data?.[0]);

                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Delete Field Setting Successfully",
                    key: "userVerificationNotification",
                });

                return {
                    status: "success",
                }
            } else {
                return await baseErrorChecking(response?.deleteUserVerificationFieldSetting)
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    const sortUserVerificationFieldFunction = async (ids, groupSettingId, documentId) => {
        try {
            const response: any = await sortUserVerificationFieldSetting(ids, groupSettingId, documentId)
            if (response?.sortUserVerificationFieldSetting?.__typename === "UserVerificationFieldSettingArray") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Sort Field Setting Successfully",
                    key: "userVerificationNotification",
                });

                return {
                    status: "success",
                }
            } else {
                return await baseErrorChecking(response?.sortUserVerificationFieldSetting)
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    const duplicateUserVerificationCountryFlowFunction = async (value, currentCountry) => {
        try {
            const response: any = await duplicateUserVerificationCountryFlow(value?.from, value?.to)
            if (response.duplicateUserVerificationCountryFlow.__typename === "UserVerificationCountryFlow") {
                if (currentCountry) {
                    const availableField: any = await getUserVerificationAvailableFieldSetting({country: currentCountry});
                    setAvailableField(availableField?.getUserVerificationAvailableFieldSetting?.data?.[0]);

                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: "Duplicate Country Flow Successfully",
                        key: "userVerificationNotification",
                    });
                } else {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: "Create Country and Duplicated Flow Successfully",
                        key: "userVerificationNotification",
                    });
                }

                return {
                    status: "success",
                }
            } else {
                return await baseErrorChecking(response?.duplicateUserVerificationCountryFlow)
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    const deleteUserVerificationCountryFlowFunction = async (country) => {
        try {
            const response: any = await deleteUserVerificationCountryFlow(country._id)
            if (response.deleteUserVerificationCountryFlow.__typename === "UserVerificationCountryFlow") {
                const availableField: any = await getUserVerificationAvailableFieldSetting({country: country.country});
                setAvailableField(availableField?.getUserVerificationAvailableFieldSetting?.data?.[0]);

                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Delete Country Flow Successfully",
                    key: "userVerificationNotification",
                });

                return {
                    status: "success",
                }
            } else {
                return await baseErrorChecking(response?.deleteUserVerificationCountryFlow)
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    return {
        getUserVerificationAvailableFieldFunction,
        getUserVerificationStepsFunction,
        createUserVerificationStepsFunction,
        updateUserVerificationStepsFunction,
        deleteUserVerificationStepsFunction,
        sortUserVerificationStepsFunction,
        getUserVerificationDocumentGroupFunction,
        createUserVerificationDocumentGroupFunction,
        updateUserVerificationDocumentGroupFunction,
        deleteUserVerificationDocumentGroupFunction,
        getUserVerificationFieldFunction,
        createUserVerificationFieldFunction,
        updateUserVerificationFieldFunction,
        deleteUserVerificationFieldFunction,
        sortUserVerificationFieldFunction,
        duplicateUserVerificationCountryFlowFunction,
        deleteUserVerificationCountryFlowFunction
    }
}

export {useFlowBuilderFunction}