import React, {useEffect, useState} from "react"
import "./css/index.css"
import * as yup from "yup"
import {yupResolver} from "@hookform/resolvers/yup"
import yupFormSchema from "../../../../../shared/components/yup/yupFormSchema"
import InputFormItem from "../../../../../shared/components/form/inputFormItem"
import {FormProvider, useForm} from "react-hook-form"
import {Button, Col, Image, Row, Space} from "antd"
import {verticalFormLayout} from "../../../../../shared/components/form/formWrapper"
import {useTranslation} from "react-i18next"
import backgroundImage from "./assets/login-bg.jpg"
import {useNavigate} from "react-router-dom"

const LoginPage_dearc = (props) => {
    const {loginAction, loginData, loginLoading, brandSetting, languageList, setLanguage} = props
    const {t} = useTranslation()
    const navigate = useNavigate()

    const [isTyping, setIsTyping] = useState<any>(false)
    const [sloganIndex, setSloganIndex] = useState(2)

    const brandSlogan = brandSetting?.companySlogan

    useEffect(() => {
        if (sloganIndex < brandSlogan?.length) {
            let timeout = setTimeout(() => {
                setSloganIndex((i) => i + 1)
            }, 50)
            return () => clearTimeout(timeout)
        }
    }, [sloganIndex, brandSlogan])

    const loginSchema = yup.object().shape({
        login: yupFormSchema.string("login", {
            required: true,
            requiredErrorMessage: "Username Credential is required",
        }),
        password: yupFormSchema.string("password", {
            required: true,
            requiredErrorMessage: "Password is required",
        }),
    })

    const [initialValues] = useState(() => ({
        login: "",
        password: "",
    }))

    const form = useForm({
        resolver: yupResolver(loginSchema),
        mode: "all",
        defaultValues: initialValues,
    })

    const onSubmit = async (values) => {
        const record = {
            ...values,
            type: "crmUser",
        }
        loginAction(record)
        setIsTyping(false)
    }

    return (
        <div id="dearc-login-page">
            <div className={"dearc-login-content-container"}>
                <div className={"login-header"}>
                    <Image className={"clickable logo"} onClick={() => navigate("/login")} preview={false} src={brandSetting?.companyLogoInverse} width={80} />
                    <Space className={"header-link-group"} size={"large"}>
                        <a className={"header-link active"}>LOGIN</a>
                        <a className={"header-link"} onClick={() => window.open("https://" + brandSetting?.aboutPage, "_blank")}>
                            ABOUT US
                        </a>
                        <a className={"header-link"} onClick={() => window.open("https://" + brandSetting?.supportPage, "_blank")}>
                            SUPPORT
                        </a>
                    </Space>
                </div>

                <div className={"login-content"}>
                    <div className={"login-form-container"}>
                        <div className="form-content text-align-center">
                            <Image className={"mb-5 mt-2"} preview={false} src={brandSetting?.companyLogo} width={140} />
                            <Row justify={"center"} className={"mt-4"}>
                                <Col xs={24} className={"login-input-area"}>
                                    <FormProvider {...form}>
                                        <form onSubmit={form.handleSubmit(onSubmit)} className="login-form-1">
                                            <InputFormItem className={"login-input margin-bottom-0-75"} name={"login"} label={"Username"} placeholder={"Username"} layout={verticalFormLayout} onChange={(e) => setIsTyping(e)} required />
                                            <InputFormItem name={"password"} label={"Password"} placeholder={"Password"} type={"password"} layout={verticalFormLayout} onChange={(e) => setIsTyping(e)} required />
                                            {loginData && loginData.login && loginData.login.errMsg && isTyping === false && (
                                                <p
                                                    style={{
                                                        color: "red",
                                                        position: "absolute",
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    {loginData.login.errMsg}
                                                </p>
                                            )}
                                            <Row justify={"center"}>
                                                <Button block className="btn-login" type="primary" onClick={form.handleSubmit(onSubmit)} disabled={loginLoading} loading={loginLoading}>
                                                    {t("Login")}
                                                </Button>
                                            </Row>
                                        </form>
                                    </FormProvider>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <p className={"text-align-center margin-top-0-75"} style={{color: "#FFF", fontSize: 11}}>
                    Copyright © {brandSetting?.copyRight} {brandSetting?.companyName} - All rights reserved.
                </p>
            </div>
        </div>
    )
}

export default LoginPage_dearc
