import React, {useEffect, useMemo, useState} from "react";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {Button, Col, Divider, Form, Row, Tabs, Tooltip} from "antd";
import {CloseOutlined, EyeOutlined, PlusOutlined, SaveOutlined} from "@ant-design/icons";
import yupFormSchema from "../../../../components/yup/yupFormSchema";
import { getExistingVoucherCode, useAddVoucherProgramMutation, useUpdateVoucherProgramMutation } from "../../../../api/graphql/voucherProgram";
import useAuthorize from "../../../../_common/authorize";
import InputFormItem from "../../../../components/form/inputFormItem";
import { verticalFormLayout } from "../../../../components/form/formWrapper";
import SelectFormItem from "../../../../components/form/selectFormItem";
import { rewardType, voucherAvailability, voucherCodeType, voucherExpiryType, voucherMode } from "../function/enumerators";
import RadioGroupFormItem from "../../../../components/form/radioGroupFormItem";
import { clean_value, generateRandomString, getDataWithLanguage, handleOnChangeWithLanguage } from "../function/common";
import TextAreaInputFormItem from "../../../../components/form/textAreaInputFormItem";
import VoucherProgramRewards from "./voucherProgramRewards";
import { getUserNetworkTreeDownline } from "../../../../api/graphql/userNetworkTree";
import { convertRewardTypeToLabel, formatUserOptions } from "../../../../function/_common";
import { userOptionsState } from "../../../users/_common/state";
import {useRecoilValue} from "recoil";
import dayjs from "dayjs";
import UploadSingleFormItem from "../../../../components/form/uploadSingleFormItem";
import { notificationMessage } from "../../../../../recoil_state";
import { useSetRecoilState } from "recoil";
import InputNumberFormItem from "../../../../components/form/inputNumberFormItem";
import VoucherProgramVoucherCodeModal from "./voucherProgramVoucherCodeModal";
import RandomStringArrayFormItem from "../../../../components/form/randomStringArrayFormItem";
import DateRangePickerInputForm from "../../../../components/form/dateRangePickerFormItem";
import { getConfigByName } from "../../../../../config";
import { BRAND_NAME } from "../../../../../config/brandVariable";


const VoucherProgramForm = (props) => {
    const {closeFormModal, item, mode, languageData, multiTierSettingData,voucherData,voucherGroupData,existingVoucherCode} = props;

    let backendURL = getConfigByName(BRAND_NAME).backendURL;
    const getDefaultLogoUrl = () => {
        return {
            fileList: [{url: `${backendURL}/assets/icon/activitiesRewards/voucher-reward.png`, response: [{link: `${backendURL}/assets/icon/activitiesRewards/voucher-reward.png`}]}],
            path: `${backendURL}/assets/icon/activitiesRewards/voucher-reward.png`
        }
    };

    const {t} = useTranslation();
    const [activeTab, setActiveTab] = useState("0");
    const [errMsg, setErrMsg] = useState("");
    const [currentSelectUsers, setCurrentSelectUsers] = useState<any[]>(item?.network?.map((d) => d?._id) || []);
    const {networkTreeDownlineData} = getUserNetworkTreeDownline(currentSelectUsers);
    const userOptions = useRecoilValue(userOptionsState);
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [imageLight, setImageLight] = useState(item?.imageLight? item?.imageLight[0] : getDefaultLogoUrl())
    const [imageDark, setImageDark] = useState(item?.imageDark? item?.imageDark[0] : getDefaultLogoUrl())
    const [maximumRedemption, setMaximumRedemption] = useState(item?.maximumRedemption || 0)
    const [voucherProgramModal, setVoucherProgramModal] = useState(false)
    const [rewardValueOptions, setRewardValueOptions] = useState([{key: "all", value: "all", label: "All"}])

    const crmUserRankOptions = multiTierSettingData?.map((d) => {
        return {
            key: d?._id,
            value: d?._id,
            label: d?.name
        }
    });
    const voucherDataOptions = voucherData?.getVoucher?.data?.filter(item => item.voucherType !== "percentage")?.map((d) => {
        return {
            key: d?._id,
            value: d?._id,
            label: `${convertRewardTypeToLabel(d?.rewardType)} - ${d?.name}`,
            rewardType: d?.rewardType,
            type: "vouchers"
        }
    });
    const voucherGroupDataOptions = voucherGroupData?.getVoucherGroup?.data?.filter(item => {
        for(var i = 0; i < item?.pool?.length; i++){
            if(item?.pool[i]?.voucher && item?.pool[i]?.voucher?.voucherType === "percentage"){
                return false
            }
        }
        return true
    }).map((d) => {
        return {
            key: d?._id,
            value: d?._id,
            label: `Voucher Group - ${d?.name}`,
            type: "voucherGroups"
        }
    });

    const [dataObj, setDataObj] = useState<any>({
        displayName: item?.displayName || [],
        description: item?.description || [],
        termAndCondition: item?.termAndCondition || [],
        rules: item?.rules || [],
        rewards: item?.rewards || [],
    })
    const networkTreeDownLineOptions = networkTreeDownlineData?.getUserNetworkTreeDownline?.data?.map((d) => {
        return formatUserOptions(d?.userid);
    });

    const mapItemToValue = (array) => {
        if(!array || !(array.length > 0)){
            return []
        }
        return array?.map(item => item?.id || item?._id)
    }
    const [rewardValue, setRewardValue] = useState(item?.rewards?.map(item => {
        return {
            reward: item?.reward ?  item?.reward?._id : undefined,
            rewardType: item?.rewardType,
            voucherExpiryType: item?.voucherExpiryType || "unlimited",
            voucherExpiryValue: item?.voucherExpiryValue || 30,
            voucherExpiryDate: item?.voucherExpiryDate ? dayjs(item?.voucherExpiryDate) : dayjs(new Date()),
            voucherDurationType: item?.voucherDurationType || "unlimited",
            voucherDurationValue: item?.voucherDurationValue || 30,
            voucherDurationDate: item?.voucherDurationDate? dayjs(item?.voucherDurationDate)  : dayjs(new Date()),
            voucherStartDate: item?.voucherStartDate? dayjs(item?.voucherStartDate)  : dayjs(new Date()),
            maxRedeem: item?.maxRedeem || undefined,
            isFixedReward: (item?.isFixedReward !== null && item?.isFixedReward !== undefined)? item?.isFixedReward : false,
        }
    }) || [])

    const [initialValues] = useState(() => ({
        name: item?.name || "",
        displayName: item?.displayName || [],
        description: item?.description || [],
        termAndConditionEnabled: item?.termAndConditionEnabled || false,
        termAndCondition : item?.termAndCondition || [],
        rules: item?.rules || [],
        rewards: item?.rewards || [],
        rewardValue: item?.rewardValue || "all",
        networkType: item?.networkType || "all",
        network: mapItemToValue(item?.network) || [],
        exclude: mapItemToValue(item?.exclude) || [],
        voucherCodeType: item?.voucherCodeType || "autoGenerate",
        voucherCode: item?.voucherCode || [generateRandomString(6, existingVoucherCode?.getExistingVoucherCode?.voucherCode)],
        crmUserRank: item?.crmUserRank ? mapItemToValue(item?.crmUserRank) : [],
        availability: item?.availability || "all",
        maximumRedemption: item?.maximumRedemption || undefined,
        voucherCodeDigits: item?.voucherCodeDigits || 6,
        voucherMode: item?.voucherMode || "voucherCode",
        voucherProgramStartDate: item?.voucherProgramStartDate? dayjs(item?.voucherProgramStartDate) : dayjs(new Date()),
        voucherProgramEndDate:  item?.voucherProgramEndDate? dayjs(item?.voucherProgramEndDate) : dayjs(new Date()),
    }));

    let schemaObj: any = {
        name: yupFormSchema.string("name", {required: true}),
        termAndConditionEnabled: yupFormSchema.boolean("termAndConditionEnabled"),
        networkType: yupFormSchema.string("networkType"),
        network: yupFormSchema.array("network"),
        exclude: yupFormSchema.array("exclude"),
        voucherCodeType: yupFormSchema.string("voucherCodeType"),
        voucherCode: yupFormSchema.array("voucherCode"),
        availability: yupFormSchema.string("availability"),
        maximumRedemption: yupFormSchema.number("maximumRedemption"),
        voucherCodeDigits: yupFormSchema.number("voucherCodeDigits"),
        voucherMode: yupFormSchema.string("voucherMode"),
        rewardValue: yupFormSchema.string("rewardValue"),
    };

    const handleOnchange = (e, fieldName, fieldType = "content", languageCode) => {
        handleOnChangeWithLanguage(e, fieldName, fieldType, languageCode, setDataObj);
    };
    
    useEffect(()=> {
        let result = [{key: "all", value: "all", label: "All"}]
        if(rewardValue && rewardValue.length > 0){
            let selectableValue = rewardValue?.filter(item => !item?.isFixedReward)
            let temp = selectableValue?.map((_, index) => {
                return {
                    key: index + 1,
                    label: "Any " + (index + 1),
                    value: (index + 1).toString()
                }
            })
            if(temp && temp.length > 0){
                temp = temp.filter((item)=> item.key !== selectableValue.length)
                result.push(...temp)
            }
        }
        setRewardValueOptions(result)
    }, [rewardValue])
    
    const uploadOnChange = (e, field) => {
        try {
        if (e?.file?.status === "uploading") {
            switch(field){
                case "light":
                    setImageLight(prev => {
                        return {
                            ...prev,
                            fileList: e?.fileList
                        }
                    })
                    break;
                case "dark":
                    setImageDark(prev => {
                        return {
                            ...prev,
                            fileList: e?.fileList
                        }
                    })
                    break;
            }
        }
        if (e?.file?.status === "done") {
            switch(field){
                case "light":
                    setImageLight(prev => {
                        return {
                            ...prev,
                            fileList: e?.fileList,
                            path: e?.file?.response[0]?.link
                        }
                    })
                    break;
                case "dark":
                    setImageDark(prev => {
                        return {
                            ...prev,
                            fileList: e?.fileList,
                            path: e?.file?.response[0]?.link
                        }
                    })
                    break;
            }

            setNotificationMessage({
            status: "success",
            title: "",
            msg: `${e?.file?.name} file uploaded successfully`,
            key: "updateDesk",
            });
        } else if (e?.file?.status === "error") {
            switch(field){
                case "light":
                    setImageLight(null)
                    break;
                case "dark":
                    setImageDark(null)
                    break;
            }

            setNotificationMessage({
            status: "error",
            title: "",
            msg: `${e?.file?.name} file upload failed. - ${e?.file?.response}`,
            key: "updateDesk",
            });
        }
        } catch (e) {
        systemErrorNotification();
        }
    };

    const voucherModeOptions = voucherMode?.filter(item => {
        if(!(maximumRedemption > 0)){
            if(item.value === "oneCodePerVoucher"){
                return false
            }
        }
        return true
    })

    const voucherProgramSchema = yup.object().shape(schemaObj);

    const form = useForm({
        resolver: yupResolver(voucherProgramSchema),
        mode: "all",
        defaultValues: initialValues,
    });

    const onVoucherModeChange = (value, hardRefresh = false, maxRedemption = maximumRedemption) => {
        if(value === "oneCodePerVoucher"){
            form.setValue("voucherCodeType", "autoGenerate")
            let tempVoucherCode = form.getValues("voucherCode")
            let currentDigits = form.getValues("voucherCodeDigits")
            let latestVoucherCode:any = []
            for(var i = 0; i < maxRedemption; i++){
                if(hardRefresh){
                    let checkList:any = [...latestVoucherCode]
                    if(existingVoucherCode?.getExistingVoucherCode?.voucherCode?.length > 0){
                        checkList = [
                            ...checkList,
                            existingVoucherCode?.getExistingVoucherCode?.voucherCode
                        ]
                    }
                    let newVoucherCode = generateRandomString(currentDigits, checkList)
                    latestVoucherCode.push(newVoucherCode)
                }
                else {
                    if(tempVoucherCode[i] && tempVoucherCode[i].length === currentDigits){
                        latestVoucherCode.push(tempVoucherCode[i])
                    }
                    else {
                        let checkList:any = [...latestVoucherCode]
                        if(existingVoucherCode?.getExistingVoucherCode?.voucherCode?.length > 0){
                            checkList = [
                                ...checkList,
                                existingVoucherCode?.getExistingVoucherCode?.voucherCode
                            ]
                        }
                        let newVoucherCode = generateRandomString(currentDigits, checkList)
                        latestVoucherCode.push(newVoucherCode)
                    }
                }
            }
            form.setValue("voucherCode", latestVoucherCode)
        }
        else if(value === "voucherCode"){
                let tempVoucherCode = form.getValues("voucherCode")
                if(tempVoucherCode && tempVoucherCode?.length > 0){
                    form.setValue("voucherCode", [tempVoucherCode[0]])
                }
        }
    }

    const onVoucherCodeDigitsChange = () => {
        if(form.getValues("voucherCodeType") === "autoGenerate"){
            if(form.getValues("voucherMode") === "oneCodePerVoucher"){
                onVoucherModeChange("oneCodePerVoucher", true)
            }
            else if(form.getValues("voucherMode") === "voucherCode"){
                onVoucherModeChange("oneCodePerVoucher", true, 1)
            }
        }
    }

    const onMaximumRedemptionChange = (value) => {
        setMaximumRedemption(value)
        if(form.getValues("voucherCodeType") === "autoGenerate" && form.getValues("voucherMode") === "oneCodePerVoucher"){
            onVoucherModeChange("oneCodePerVoucher", true, value)
        }
        if(!value || value === 0){
            form.setValue("voucherMode", "voucherCode")
            let currentVoucherCode = form.getValues("voucherCode")
            if(currentVoucherCode && currentVoucherCode.length > 0){
                form.setValue("voucherCode", [currentVoucherCode[0]])
            }
        }
    }
    
    const resetFieldValue = (fields) => {
        if(fields && fields.length > 0){
            fields.forEach(item => {
                form.setValue(item, undefined)
            })
        }
    }

    const addRewards = () => {
        setRewardValue(prev => ([
            ...prev,
            {
                reward: null,
                rewardType: null,
                voucherExpiryType: "unlimited",
                voucherExpiryValue: 30,
                voucherExpiryDate: dayjs(new Date()),
                voucherDurationType: "unlimited",
                voucherDurationValue: 30,
                voucherDurationDate: dayjs(new Date()),
                voucherStartDate: dayjs(new Date()),
                maxRedeem: 1,
                isFixedReward: true,
            }]
        ))
    }
    const tabItems = languageData?.map((lang, index) => {
        return {
            key: index?.toString(),
            label: lang?.name,
            children: (
                <Row gutter={16}>
                    <Col className="gutter-row" xs={24} md={12}>
                        <InputFormItem
                            name={`dislayName_${lang.name}`}
                            label={"Display Name"}
                            placeholder={"Display Name"}
                            layout={verticalFormLayout}
                            onChange={(e)=> handleOnchange(e, "displayName", "content", lang.code)}
                            value={getDataWithLanguage(dataObj, "displayName", "content", lang.code)}
                            disabled={mode === "view"}
                        />
                    </Col>
                    <Col className="gutter-row" xs={24} md={12}>
                        <InputFormItem
                            name={`description_${lang.name}`}
                            label={"Description"}
                            placeholder={"Description"}
                            layout={verticalFormLayout}
                            onChange={(e)=> handleOnchange(e, "description", "content", lang.code)}
                            value={getDataWithLanguage(dataObj, "description", "content", lang.code)}
                            disabled={mode === "view"}
                        />
                    </Col>
                    <Col className="gutter-row" xs={24} md={12}>
                        <TextAreaInputFormItem
                            name={`rules_${lang.name}`}
                            label={"Rules"}
                            placeholder={"Rules"}
                            layout={verticalFormLayout}
                            onChange={(e)=> handleOnchange(e, "rules", "content", lang.code)}
                            value={getDataWithLanguage(dataObj, "rules", "content", lang.code)}
                            disabled={mode === "view"}
                        />
                    </Col>
                    {
                        form.getValues("termAndConditionEnabled") &&
                        <Col className="gutter-row" xs={24} md={12}>
                            <TextAreaInputFormItem
                                name={`termAndCondition_${lang.name}`}
                                label={"Term and Conditions"}
                                placeholder={"Term and Conditions"}
                                layout={verticalFormLayout}
                                onChange={(e)=> handleOnchange(e, "termAndCondition", "content", lang.code)}
                                value={getDataWithLanguage(dataObj, "termAndCondition", "content", lang.code)}
                                disabled={mode === "view"}
                            />
                        </Col>
                    }
                </Row>
            ),
        };
    });

    const onCancel = () => {
        closeFormModal && closeFormModal();
    };

    const {addVoucherProgram} = useAddVoucherProgramMutation();
    const {updateVoucherProgram} = useUpdateVoucherProgramMutation();
    const {systemErrorNotification} = useAuthorize();


    const onSubmit = async (values) => {
        try {
            setErrMsg("");

            if(rewardValue && rewardValue.length > 0){
                for(var i = 0; i < rewardValue.length; i++){
                    if(!rewardValue[i].reward){
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: `mission.rewardCannotBeBlank`,
                            key: "updateVoucherProgram",
                        });
                        return
                    }
                }
            }

            const cleanValue = clean_value(values)
            const result = {
                ...cleanValue,
                displayName: dataObj?.displayName || [],
                description: dataObj?.description || [],
                termAndCondition: dataObj?.termAndCondition || [],
                rules: dataObj?.rules || [],
                rewards: rewardValue || [],
                imageLight: imageLight,
                imageDark: imageDark
            };
            const response = (mode === "add") ? await addVoucherProgram(result) : await updateVoucherProgram(item?._id, result);
            const {data} = response;
            const res = mode === "add" ? data?.addVoucherProgram : data?.updateVoucherProgram;
            if (res?.__typename === "BaseError") {
                let errMsg = t(res?.errKey);
                setErrMsg(errMsg);
            } else {
                closeFormModal && closeFormModal();
            }
        } catch (e: any) {
            systemErrorNotification();
        }
    };

    return (
        <div>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="voucherProgram-form-1">
                    {errMsg && <p style={{color: "red"}}>{errMsg}</p>}
                    <Row gutter={16}>
                        <Col className="gutter-row" xs={24} md={12}>
                            <UploadSingleFormItem
                                name={`imageLight`}
                                label={t("Image Light")}
                                layout={verticalFormLayout}
                                listType={"picture-card"}
                                mode={"activities"}
                                fileList={imageLight?.fileList}
                                onChange={(e) =>
                                    uploadOnChange(e, "light")
                                }
                                onRemove={(e) =>
                                    setImageLight(null)
                                }
                                disabled={mode === "view"}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} md={12}>
                            <UploadSingleFormItem
                                name={`imageDark`}
                                label={t("Light Dark")}
                                layout={verticalFormLayout}
                                listType={"picture-card"}
                                mode={"activities"}
                                fileList={imageDark?.fileList}
                                onChange={(e) =>
                                    uploadOnChange(e, "dark")
                                }
                                onRemove={(e) =>
                                    setImageDark(null)
                                }
                                disabled={mode === "view"}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} md={12}>
                            <InputFormItem
                                name={"name"}
                                label={"Voucher Program Name"}
                                placeholder={"Voucher Program Name"}
                                layout={verticalFormLayout}
                                disabled={mode === "view"}
                                required
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} md={12}>
                            <RadioGroupFormItem
                                name={"termAndConditionEnabled"}
                                label={t("Term and Conditions")}
                                options={[
                                    {label: t("Enabled"), value: true},
                                    {label: t("Disabled"), value: false},
                                ]}
                                layout={verticalFormLayout}
                                disabled={mode === "view"}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <SelectFormItem
                                name={"networkType"}
                                label={t("Network Type")}
                                options={[
                                    {
                                        label: t("network.all"),
                                        value: "all",
                                    },
                                    {
                                        label: t("network.partial"),
                                        value: "partial",
                                    },
                                    {
                                        label: t("network.own"),
                                        value: "own",
                                    },
                                ]}
                                mode={"single"}
                                placeholder={t("Network Type")}
                                disabled={mode === "view"}
                                required
                            />
                        </Col>
                        {form.getValues("networkType") === "partial" &&
                            <>
                                <Col xs={24} md={12}>
                                    <SelectFormItem
                                        name={"network"}
                                        label={t("Network")}
                                        options={userOptions}
                                        mode={"multiple"}
                                        onChange={(val) => setCurrentSelectUsers(val)}
                                        placeholder={t("Network")}
                                        disabled={mode === "view"}
                                        optionFilterProp={"label"}
                                        showSearch
                                        optionLabelProp="selectedLabel"
                                    />
                                </Col>
                                <Col xs={24} md={12}>
                                    <SelectFormItem
                                        name={"exclude"}
                                        label={t("Exclude")}
                                        options={networkTreeDownLineOptions}
                                        placeholder={t("Exclude")}
                                        disabled={mode === "view"}
                                        optionFilterProp={"label"}
                                        showSearch
                                        mode={"multiple"}
                                        optionLabelProp="selectedLabel"
                                    />
                                </Col>
                            </>
                        }
                        <Col xs={22} md={form.getValues("voucherMode") === "oneCodePerVoucher" ? 11 : 12}>
                            <SelectFormItem
                                name={"voucherMode"}
                                label={t("Voucher Mode")}
                                options={voucherModeOptions}
                                mode={"single"}
                                placeholder={t("Voucher Mode")}
                                disabled={mode === "view"}
                                onChange={e => onVoucherModeChange(e)}
                                required
                            />
                        </Col>
                        {
                            form.getValues("voucherMode") === "oneCodePerVoucher" &&
                            <Col xs={2} md={1}>
                                <Col xs={24}>&nbsp;</Col>
                                <Tooltip title={t("common.voucherCode")}>
                                    <EyeOutlined onClick={()=>setVoucherProgramModal(true)} style={{marginTop: "12px"}}/>
                                </Tooltip>
                            </Col>
                        }
                        <Col xs={24} md={12}>
                            <SelectFormItem
                                name={"voucherCodeType"}
                                label={t("Voucher Code Type")}
                                options={voucherCodeType}
                                mode={"single"}
                                placeholder={t("Network Type")}
                                disabled={mode === "view" || form.getValues("voucherMode") === "oneCodePerVoucher"}
                                required
                            />
                        </Col>
                        {
                            form.getValues("voucherCodeType") === "autoGenerate" && form.getValues("voucherMode") === "voucherCode" && 
                            <>
                                <Col xs={24} md={12}>
                                    <InputNumberFormItem
                                        name={"voucherCodeDigits"}
                                        label={t("Voucher Code Digits")}
                                        placeholder={t("Voucher Code Digits")}
                                        disabled={mode === "view"}
                                        layout={verticalFormLayout}
                                        onChange={()=>onVoucherCodeDigitsChange()}
                                        style={{width: "100%"}}
                                    />
                                </Col>
                                <Col className="gutter-row" xs={24} md={12}>
                                    <RandomStringArrayFormItem
                                        name={"voucherCode"}
                                        label={"Voucher Code"}
                                        placeholder={"Voucher Code"}
                                        layout={verticalFormLayout}
                                        disabled={mode === "view"}
                                        randomStringLength={form.getValues("voucherCodeDigits")}
                                        required
                                        showRefresh
                                    />
                                </Col>
                            </>
                        }
                        {
                            form.getValues("voucherCodeType") === "autoGenerate" && form.getValues("voucherMode") === "oneCodePerVoucher" && 
                            <>
                                <Col xs={24} md={12}>
                                    <InputNumberFormItem
                                        name={"voucherCodeDigits"}
                                        label={t("Voucher Code Digits")}
                                        placeholder={t("Voucher Code Digits")}
                                        disabled={mode === "view"}
                                        layout={verticalFormLayout}
                                        style={{width: "100%"}}
                                        onChange={()=>onVoucherCodeDigitsChange()}
                                    />
                                </Col>
                            </>
                        }
                        {
                            form.getValues("voucherCodeType") === "manual" &&  form.getValues("voucherMode") === "voucherCode" && 
                            <>
                                <Col className="gutter-row" xs={24} md={12}>
                                    <RandomStringArrayFormItem
                                        name={"voucherCode"}
                                        label={"Voucher Code"}
                                        placeholder={"Voucher Code"}
                                        layout={verticalFormLayout}
                                        disabled={mode === "view"}
                                        required
                                    />
                                </Col>
                            </>
                        }
                        <Col xs={24} md={12}>
                            <InputNumberFormItem
                                name={"maximumRedemption"}
                                label={t("Maximum Redepmtion")}
                                placeholder={t("Maximum Redemption")}
                                disabled={mode === "view"}
                                required={form.getValues("voucherMode") === "oneCodePerVoucher"}
                                onChange={e=>onMaximumRedemptionChange(e)}
                                layout={verticalFormLayout}
                                style={{width: "100%"}}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <DateRangePickerInputForm
                                startName={"voucherProgramStartDate"}
                                endName={"voucherProgramEndDate"}
                                layout={verticalFormLayout}
                                wrapperCol={{span: 24}}
                                label={t("Voucher Program Duration")}
                                disabled={mode === "view"}
                                requiredStar
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <SelectFormItem
                                name={"crmUserRank"}
                                label={t("CRM User Rank")}
                                options={crmUserRankOptions}
                                mode={"multiple"}
                                placeholder={t("CRM User Rank")}
                                disabled={mode === "view"}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <SelectFormItem
                                name={"availability"}
                                label={t("Availability")}
                                options={voucherAvailability}
                                mode={"single"}
                                placeholder={t("Availability")}
                                disabled={mode === "view"}
                                required
                            />
                        </Col>
                        <div className="poolCardBar">
                            {rewardValue && rewardValue.length > 0 &&<h1 style={{display: "contents"}}>{t("Rewards")}</h1>}
                            <Button onClick={()=>addRewards()} icon={<PlusOutlined />} className="button-submit-1" style={{float: "right"}}>
                                {t("common.addRewards")}
                            </Button>
                        </div>
                    </Row>
                    <Row  gutter={16}>
                        <Col xs={24} md={18}>
                        <VoucherProgramRewards
                            mode={mode} 
                            rewardValue={rewardValue} 
                            setRewardValue={setRewardValue} 
                            form={form}
                            voucher={voucherDataOptions}
                            voucherGroup={voucherGroupDataOptions}
                        />
                        </Col>
                        {rewardValue && rewardValue.length > 0 &&
                            <Col xs={24} md={5} className="poolCard">
                                <Col className="gutter-row" xs={24}>
                                    <SelectFormItem
                                        name={"rewardValue"}
                                        label={t("Reward Value")}
                                        options={rewardValueOptions}
                                        placeholder={t("Reward Value")}
                                        disabled={mode === "view"}
                                    />
                                </Col>
                            </Col>
                        }
                    </Row>
                    <Tabs
                        defaultActiveKey="0"
                        items={tabItems}
                        onChange={(activeKey) => setActiveTab(activeKey)}
                        activeKey={activeTab}
                    />
                    <Form.Item className="text-align-right" style={{paddingTop: "12px"}}>
                        {onCancel && (
                            <Button onClick={onCancel} icon={<CloseOutlined />} className="button-cancel-1">
                                {mode === "view" ? t("Close") : t("common.cancel")}
                            </Button>
                        )}
                        {mode !== "view" && (
                            <Button onClick={form.handleSubmit(onSubmit)} icon={<SaveOutlined />} className="button-submit-1">
                                {t("common.submit")}
                            </Button>
                        )}
                    </Form.Item>
                </form>
            </FormProvider>
            
            <VoucherProgramVoucherCodeModal 
                item= {form.getValues("voucherCode")} 
                isOpen={voucherProgramModal} 
                closeFormModal={() => setVoucherProgramModal(false)} 
                languageData={languageData} 
                refreshVoucherCode={()=> onVoucherModeChange("oneCodePerVoucher", true)}
            />
        </div>
    );
};

export default VoucherProgramForm;
