import React, {useEffect, useState} from "react";
import "./css/index.css";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import yupFormSchema from "../../../../../shared/components/yup/yupFormSchema";
import InputFormItem from "../../../../../shared/components/form/inputFormItem";
import {FormProvider, useForm} from "react-hook-form";
import {Button, Image, Row} from "antd";
import {verticalFormLayout} from "../../../../../shared/components/form/formWrapper";
import {useTranslation} from "react-i18next";
import {Dots} from "./component/dot";

const LoginPage_fundamarkets = (props) => {
    const {loginAction, loginData, loginLoading, brandSetting, languageList, setLanguage} = props;
    const {t} = useTranslation();

    const [isTyping, setIsTyping] = useState<any>(false);
    const [sloganIndex, setSloganIndex] = useState(2);

    const brandSlogan = brandSetting?.companySlogan;

    useEffect(() => {
        if (sloganIndex < brandSlogan?.length) {
            let timeout = setTimeout(() => {
                setSloganIndex((i) => i + 1);
            }, 50);
            return () => clearTimeout(timeout);
        }
    }, [sloganIndex, brandSlogan]);

    const loginSchema = yup.object().shape({
        login: yupFormSchema.string("login", {
            required: true,
            requiredErrorMessage: "Username Credential is required",
        }),
        password: yupFormSchema.string("password", {
            required: true,
            requiredErrorMessage: "Password is required",
        }),
    });

    const [initialValues] = useState(() => ({
        login: "",
        password: "",
    }));

    const form = useForm({
        resolver: yupResolver(loginSchema),
        mode: "all",
        defaultValues: initialValues,
    });

    const onSubmit = async (values) => {
        const record = {
            ...values,
            type: "crmUser",
        };
        loginAction(record);
        setIsTyping(false);
    };

    return (
        <div id="funda-login-page">
            <a
                className={"switch-to-old-site-link"}
                onClick={() => window.open(`https://secures.${window.location.hostname.split(".").slice(1).join(".")}`)}
            >
                {/* {t("common.switchToOldSite")} */}
                Switch To Old Site
            </a>
            <div className="subSection">
                <Dots
                    className="dots-tr"
                    onHover={() => {
                        if (sloganIndex === brandSlogan?.length) {
                            setSloganIndex(0);
                        }
                    }}
                />
                <Dots className="dots-bl" />
                <div className={`slogan ${sloganIndex === brandSlogan?.length ? "complete" : ""}`}>
                    {brandSlogan?.slice(0, sloganIndex)}
                    <span className="blinker" />
                </div>
            </div>

            <div className="mainSection">
                <div className="logo">
                    <Image preview={false} width={100} src={brandSetting?.companyLogo} className={"clickable"} />
                </div>
                <div className={"form-content"}>
                    <div className="login-title">{t("Login")}</div>
                    <FormProvider {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)} className="login-form-1">
                            <InputFormItem
                                className={"login-input margin-bottom-0-75"}
                                name={"login"}
                                label={"Username"}
                                placeholder={"Username"}
                                layout={verticalFormLayout}
                                onChange={(e) => setIsTyping(e)}
                                required
                            />
                            <InputFormItem
                                name={"password"}
                                label={"Password"}
                                placeholder={"Password"}
                                type={"password"}
                                layout={verticalFormLayout}
                                onChange={(e) => setIsTyping(e)}
                                required
                            />
                            {loginData && loginData.login && loginData.login.errMsg && isTyping === false && (
                                <p
                                    style={{
                                        color: "red",
                                        marginTop: 16,
                                        fontSize: 12,
                                        textAlign: "center",
                                    }}
                                >
                                    {loginData.login.errMsg}
                                </p>
                            )}
                            <Row justify={"center"}>
                                <Button block className="btn-login" type="primary" loading={loginLoading} onClick={form.handleSubmit(onSubmit)} disabled={loginLoading}>
                                    {t("Login")}
                                </Button>
                            </Row>
                        </form>
                    </FormProvider>
                </div>
                <p className={"text-align-center margin-top-0-75"} style={{color: "#222222", fontSize: 11}}>
                    Copyright © {brandSetting?.copyRight} {brandSetting?.companyName} - All rights reserved.
                </p>
            </div>
        </div>
    );
};

export default LoginPage_fundamarkets;
