import React, {useEffect, useState} from "react";

import {useRecoilValue} from "recoil";
import {selfPermissionObj} from "../../../../../recoil_state";
import {useLocation} from "react-router-dom";
import CommLogBatch from "./view/layout1";
import {getCommissionRuleById} from "../../../../api/graphql/commission";

const CommissionRuleBatch = () => {

    const permission = useRecoilValue<any>(selfPermissionObj)
    let layout = 1;
    let component;

    const [data, setData] = useState({});
    const [headerDetails, setHeaderDetails] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState<any>(1);


    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    const setHeaderFunction = (id, title, isOpen) => {
        setHeaderDetails((prevHeaderDetails) => {
            // Find the index of the header with the specified id
            const headerIndex = prevHeaderDetails.findIndex((item) => item?.id === id);

            if (headerIndex !== -1) {
                // If the header with the given id exists
                if (isOpen === false) {
                    // If isOpen is false, remove the header with the given id
                    const updatedHeaderDetails = prevHeaderDetails.filter((item) => item.id <= id);
                    return updatedHeaderDetails;
                } else {
                    // If isOpen is true, update the header with the given id
                    const updatedHeaderDetails = [...prevHeaderDetails];
                    updatedHeaderDetails[headerIndex] = {
                        id,
                        title,
                        isOpen,
                        onClick: () => setHeaderFunction(id, title, false),
                    };
                    return updatedHeaderDetails;
                }
            } else {
                // If the header with the given id does not exist, insert it at the correct position

                const updatedHeaderDetails = [...prevHeaderDetails, { id, title, isOpen, onClick: () => setHeaderFunction(id, title, false) }];

                if (id === 2) {
                    const id1Index = updatedHeaderDetails.findIndex((item) => item?.id === 1);
                    if (id1Index !== -1) {
                        updatedHeaderDetails[id1Index] = {
                            ...updatedHeaderDetails[id1Index],
                            onClick: ()=>{
                                const newUrl = `${window.location.origin}${window.location.pathname}`;

                                window.location.replace(newUrl);
                            },
                        };
                    }
                }

                return updatedHeaderDetails.sort((a, b) => a.id - b.id);
            }
        });
    };

    useEffect(()=>{
        const largestKeyValue = headerDetails.reduce((max, item) => {
            return item.id > max ? item.id : max;
        }, -Infinity);

        setCurrentPage(largestKeyValue)
    },[headerDetails])



    const preload = async () => {

        await setHeaderFunction(1, "Commission Batch Details", true)

        if (id) {
            const response = await getCommissionRuleById(id)

            if (response?.getCommissionRuleById?.__typename === "CommissionRule") {
                setData(response?.getCommissionRuleById)

                await setHeaderFunction(2, response?.getCommissionRuleById?.name, true)
            }
        }

    }

    useEffect(() => {
        preload()
    }, [id])

    switch (layout) {
        case 1:
            component = (
                <CommLogBatch data={data} headerDetails={headerDetails} setHeaderFunction={setHeaderFunction} currentPage={currentPage}/>
            );
            break;

        default:
            component = (
                <CommLogBatch data={data} headerDetails={headerDetails} setHeaderFunction={setHeaderFunction} currentPage={currentPage}/>
            );
            break;
    }

    return component;
}

export default CommissionRuleBatch