import React, { useEffect, useState } from "react";
import {Modal} from "antd";
import {useTranslation} from "react-i18next";
import { getUserClaimMission, getUserClaimMissionCount } from "../../../../../api/graphql/mission";
import TableAntDesign from "../../../../../components/table/tableAntDesign";
import { userClaimMissionColumn } from "../function/tableColumn";

const MissionStatusModal = (props) => {
    const {mode, item, isOpen, closeModal, tableName} = props;
    const {t} = useTranslation();
    const getTitle = (): String => {
        switch (mode) {
            case "totalClaimed":
                return `${t("Mission Total Claimed")}`;
            case "totalCompleted":
                return `${t("Mission Total Completed")}`;
            default:
                return `${t("Mission Modal")}`;
        }
    };

    const [currentData, setCurrentData] = useState<any>([])
    const [orderBy, setOrderBy] = useState<any>("")
    const [limit, setLimit] = useState<any>(20)
    const [offset, setOffset] = useState<any>(0)
    const [filter, setFilter] = useState<any>(null)
    const {data:userClaimMissionCount} = getUserClaimMissionCount({filter: filter})

    useEffect(() => {
        async function fetchUserClaimMission(){
            let filter:any = null
            switch(mode){
                case "totalClaimed":
                    filter = {missionId: item?._id?.toString()}
                    setFilter(filter)
                    break
                case "totalCompleted":
                    filter = {missionId: item?._id?.toString(), isMissionCompleted: true}
                    setFilter(filter)
                    break
            }
            const userClaimMission = await getUserClaimMission(filter, limit, offset, orderBy)
            if(userClaimMission?.getUserClaimMission?.data) {
                setCurrentData(userClaimMission?.getUserClaimMission?.data)
            }
            
        }

        fetchUserClaimMission()
    }, [item, mode, limit, offset])

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize)
        setOffset(limit * (page - 1))
    }


    return (
        <Modal
            getContainer="#mission"
            title={getTitle()}
            open={isOpen}
            width={"80%"}
            footer={null}
            closable={true}
            destroyOnClose
            onCancel={closeModal}
        >   
            <TableAntDesign
                data={currentData}
                tableName={tableName}
                columns={userClaimMissionColumn}
                size={"small"}
                filter={setFilter}
                order={setOrderBy}
                loading={false}
                pagination={{
                    pageSize: limit,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                    total: userClaimMissionCount,
                    showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                }}
            />
        </Modal>
    );
};

export default MissionStatusModal;
