import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Input, Segmented, Select, Space, Tabs} from "antd";
import RegistrationSettingTab from "./component/registrationSettingTab";
import {useSetRecoilState} from "recoil";
import {registrationSettingModeState, registrationSettingPlatformState} from "../../state";

const RegistrationSetting_1 = (props) => {
    const {permission, clientField} = props

    const {t} = useTranslation()
    const [currentTab, setCurrentTab] = useState<any>("webRegister")
    const [categoryFilter, setCategoryFilter] = useState<any>()
    const [labelFilter, setLabelFilter] = useState<any>()

    const setPlatform = useSetRecoilState(registrationSettingPlatformState)
    const setMode = useSetRecoilState(registrationSettingModeState)

    const propsItem = {
        permission,
        clientField,
        categoryFilter,
        labelFilter
    }

    const tabItems = [
        {
            key: "webRegister",
            label: t("Web Registration"),
            children: <RegistrationSettingTab {...propsItem}/>
        },
        {
            key: "mobileRegister",
            label: t("Mobile Registration"),
            children: <RegistrationSettingTab {...propsItem}/>
        },
    ]

    useEffect(() => {
        if (currentTab?.includes("Register")) {
            setPlatform(currentTab?.replace("Register", ""))
        }
    }, [currentTab])

    return (
        <div id={"registration-workflow"}>
            <h1>{t("Registration Workflow")}</h1>
            <Tabs
                defaultActiveKey={currentTab}
                items={tabItems}
                tabBarExtraContent={
                    <Space>
                        {permission?.fields?.view &&
                        <>
                            <Input.Search
                                placeholder={t("Search")?.toString()}
                                onSearch={(val) => setLabelFilter(val)}
                                allowClear
                            />
                            <Select
                                style={{width: 200}}
                                placeholder={t("Field Category")}
                                allowClear
                                options={[
                                    {label: "All", value: "all"},
                                    {label: "Basic", value: "basicInformation"},
                                    {label: "Login", value: "loginInformation"},
                                    {label: "Residential", value: "residentialInformation"},
                                    {label: "Additional", value: "additionalInformation"},
                                    {label: "Corporate", value: "corporateInformation"},
                                    {label: "Registration", value: "registrationInformation"}
                                ]}
                                onChange={(val) => {
                                    if (val.includes("all")) {
                                        setCategoryFilter([])
                                    } else {
                                        setCategoryFilter(val)
                                    }
                                }}
                            />
                        </>}
                        <Segmented
                            options={[
                                {label: "LIVE", value: "live"},
                                {label: "DEMO", value: "demo"}
                            ]}
                            defaultValue={"live"}
                            onChange={value => setMode(value?.toString())}
                        />
                    </Space>
                }
                onTabClick={(key) => setCurrentTab(key)}
            />
        </div>
    )
}

export default RegistrationSetting_1