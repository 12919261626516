import React, {useEffect, useState} from "react";
import {Button, Card, Form, message, Space, Tabs} from "antd";
import {useTranslation} from "react-i18next";
import {useUpdateUserLeadsMutation} from "../../../../../../../api/graphql/leads";
import {EditOutlined} from "@ant-design/icons";
import {formatProfileInformation, validateConfirmKeys} from "../../../../../_common/function";
import BasicInformation from "./InformationForm/basicInformation";
import ResidenceInformation from "./InformationForm/residenceInformation";
import useAuthorize from "../../../../../../../_common/authorize";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";

const InformationCard = (props) => {
    const {data, profileInformation, permissionUser} = props
    const {t} = useTranslation()
    const [editable, setEditable] = useState<boolean>(false)
    const [form] = Form.useForm()

    const [fields, setFields] = useState<any>({
        basicField: [],
        loginField: [],
        residenceField: [],
        additionalField: [],
    })
    const {basicField, loginField, residenceField, additionalField} = fields;
    const {systemErrorNotification} = useAuthorize()
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    useEffect(() => {
        formatProfileInformation(fields, setFields, profileInformation)
    }, [profileInformation])

    const {updateUserLeads, loading} = useUpdateUserLeadsMutation()

    const onSubmit = async (value) => {
       try{
           Object.keys(value).forEach(key => {
               if (value[key] === undefined) {
                   delete value[key];
               }
           });

           const validated = await validateConfirmKeys(value, setNotificationMessage, t)

           if (validated) {
               value["mobileNo"] = value?.mobileNo?.toString()
               delete value["combinedMobileNo"];
               await updateUserLeads(data?._id, value)
               setEditable(false)
           }
       }catch (e) {
           console.log(e);
           systemErrorNotification()
       }
    }

    const items:any = [
        {
            label: t("field.category.basicInformation"),
            key: "basic",
            children: (
                <BasicInformation
                    data={data}
                    editable={editable}
                    basicField={basicField}
                    loginField={loginField}
                    additionalField={additionalField}
                    isReadSensitive={permissionUser?.leads?.view?.sensitiveInformation}
                    form={form}
                />
            )
        },
        residenceField?.length > 0 ? {
            label: t("Residence Information"),
            key: "address",
            children: (
                <ResidenceInformation
                    data={data}
                    editable={editable}
                    residenceField={residenceField}
                    isReadSensitive={permissionUser?.leads?.view?.sensitiveInformation}
                />
            )
        } : null,
    ];

    return (
        <Card style={{width: "100%"}}>
            <Form onFinish={onSubmit} form={form} colon={false}>
                <Tabs
                    size={"small"}
                    items={items}
                    tabBarExtraContent={
                        permissionUser?.leads?.edit?.edit &&
                        <>
                            {editable ?
                                <Space>
                                    <Button onClick={() => setEditable(false)}>{t("common.cancel")}</Button>
                                    <Button loading={loading} type={"primary"} htmlType={"submit"}>{t("Confirm")}</Button>
                                </Space> :
                                <Button type={"link"} onClick={() => setEditable(true)} icon={<EditOutlined/>}/>}
                        </>
                    }
                />
            </Form>
        </Card>
    )
}

export default InformationCard