import React, {useEffect, useMemo, useState} from "react";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {Button, Col, Divider, Form, Row, Tabs} from "antd";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import yupFormSchema from "../../../../../components/yup/yupFormSchema";
import { useAddVoucherMutation, useUpdateVoucherMutation } from "../../../../../api/graphql/voucher";
import useAuthorize from "../../../../../_common/authorize";
import InputFormItem from "../../../../../components/form/inputFormItem";
import { verticalFormLayout } from "../../../../../components/form/formWrapper";
import SelectFormItem from "../../../../../components/form/selectFormItem";
import { rewardType, voucherType } from "../function/enumerators";
import RadioGroupFormItem from "../../../../../components/form/radioGroupFormItem";
import RangeGroupFormItem from "../../../../../components/form/rangeGroupFormItem";
import InputNumberFormItem from "../../../../../components/form/inputNumberFormItem";
import { clean_value, getDataWithLanguage, handleOnChangeWithLanguage } from "../function/common";
import TextAreaInputFormItem from "../../../../../components/form/textAreaInputFormItem";
import UploadSingleFormItem from "../../../../../components/form/uploadSingleFormItem";
import { useSetRecoilState } from "recoil";
import { notificationMessage } from "../../../../../../recoil_state";
import { getConfigByName } from "../../../../../../config";
import { BRAND_NAME } from "../../../../../../config/brandVariable";


const VoucherForm = (props) => {
    const {closeFormModal, item, mode, languageData, stock, commodities, product, report, reportFields, reportFieldTypes} = props;
    let backendURL = getConfigByName(BRAND_NAME).backendURL;
    const getDefaultLogoUrl = () => {
        return {
            fileList: [{url: `${backendURL}/assets/icon/activitiesRewards/voucher-reward.png`, response: [{link: `${backendURL}/assets/icon/activitiesRewards/voucher-reward.png`}]}],
            path: `${backendURL}/assets/icon/activitiesRewards/voucher-reward.png`
        }
    };

    const {t} = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [activeTab, setActiveTab] = useState("0");
    const [errMsg, setErrMsg] = useState("");
    const [languageObj, setLanguageObj] = useState<any>({
        displayName: item?.displayName || [],
        description: item?.description || [],
        termAndCondition: item?.termAndCondition || [],
    })
    const [imageLight, setImageLight] = useState(item?.imageLight? item?.imageLight[0] : getDefaultLogoUrl())
    const [imageDark, setImageDark] = useState(item?.imageDark? item?.imageDark[0] : getDefaultLogoUrl())
    
    const [initialValues] = useState(() => ({
        displayName: item?.displayName || [],
        description: item?.description || [],
        termAndCondition: item?.termAndCondition || [],
        name: item?.name || "",
        rewardType: item?.rewardType || "",
        voucherType:item?.voucherType || undefined,
        voucherValue: item?.voucherValue || null,
        stockVoucher: item?.stockVoucher?._id,
        maxAmount: item?.maxAmount || null,
        product: item?.product?._id || null,
        termAndConditionEnabled: item?.termAndConditionEnabled || false,
        voucherStackable: item?.voucherStackable || false, 
        voucherValueIsRange: item?.voucherValueIsRange || false, 
        voucherValueStartRange: item?.voucherValueStartRange || null, 
        voucherValueEndRange: item?.voucherValueEndRange || null, 
        targetReportId: item?.targetReportId || null,
        targetReportFieldId: item?.targetReportFieldId || null
    }));

    let schemaObj: any = {
        name: yupFormSchema.string("name", {required: true}),
        rewardType: yupFormSchema.string("rewardType", {required: true}),
        voucherType: yupFormSchema.string("voucherType").test("required-voucher-Type", `${t("voucher.voucherTypeCannotBeBlank")}`, function(voucherType) {
            const { rewardType } = this.parent;
            if((rewardType === "cashVoucher" || rewardType === "tradingVoucher" || rewardType === "bonusVoucher")) return voucherType !== null && voucherType !== undefined && voucherType !== "";
            return true}),
        product: yupFormSchema.string("product").test("required-product", `${t("voucher.productCannotBeBlank")}`, function(product) {
            const { rewardType } = this.parent;
            if(rewardType === "accountUpgradeVoucher") return product !== null && product !== undefined && product !== "";
            return true
          }),
        maxAmount: yupFormSchema.number("maxAmount", { min: 0}),
        termAndConditionEnabled: yupFormSchema.boolean("termAndConditionEnabled"),
        stockVoucher: yupFormSchema.string("stockVoucher").test("required-stock-voucher", `${t("voucher.stockVoucherCannotBeBlank")}`, function(stockVoucher) {
            const { rewardType } = this.parent;
            if(rewardType === "commoditiesVoucher" || rewardType === "stockVoucher") return stockVoucher !== null && stockVoucher !== undefined && stockVoucher !== "";
            return true
          }),
        voucherStackable: yupFormSchema.boolean("voucherStackable"),
        voucherValueIsRange: yupFormSchema.boolean("voucherValueIsRange"),
        voucherValue: yupFormSchema.number("voucherValue", { min: 0}),
        voucherValueStartRange: yupFormSchema.number("voucherValueStartRange", { min: 0}),
        voucherValueEndRange: yupFormSchema.number("voucherValueEndRange", { min: 0}),
        targetReportId: yupFormSchema.string("targetReportId").test("required-report-id", `${t("voucher.reportCannotBeBlank")}`, function(reportId) {
            const { rewardType, voucherType } = this.parent;
            if((rewardType === "cashVoucher" || rewardType === "tradingVoucher" || rewardType === "bonusVoucher") && voucherType === "percentage") return reportId !== null && reportId !== undefined && reportId !== "";
            return true
          }),
        targetReportFieldId: yupFormSchema.string("targetReportFieldId").test("required-report-field", `${t("voucher.reportFieldCannotBeBlank")}`, function(reportFieldId) {
            const { rewardType, voucherType } = this.parent;
            if((rewardType === "cashVoucher" || rewardType === "tradingVoucher" || rewardType === "bonusVoucher") && voucherType === "percentage") return reportFieldId !== null && reportFieldId !== undefined && reportFieldId !== "";
            return true
          })
    };

    const handleOnchange = (e, fieldName, fieldType = "content", languageCode) => {
        handleOnChangeWithLanguage(e, fieldName, fieldType, languageCode, setLanguageObj);
    };

    const voucherSchema = yup.object().shape(schemaObj);

    const form = useForm({
        resolver: yupResolver(voucherSchema),
        mode: "all",
        defaultValues: initialValues,
    });
    
    const resetFieldValue = (fields) => {
        if(fields && fields.length > 0){
            fields.forEach(item => {
                form.setValue(item, undefined)
            })
        }
    }
    
    const uploadOnChange = (e, field) => {
        try {
        if (e?.file?.status === "uploading") {
            switch(field){
                case "light":
                    setImageLight(prev => {
                        return {
                            ...prev,
                            fileList: e?.fileList
                        }
                    })
                    break;
                case "dark":
                    setImageDark(prev => {
                        return {
                            ...prev,
                            fileList: e?.fileList
                        }
                    })
                    break;
            }
        }
        if (e?.file?.status === "done") {
            switch(field){
                case "light":
                    setImageLight(prev => {
                        return {
                            ...prev,
                            fileList: e?.fileList,
                            path: e?.file?.response[0]?.link
                        }
                    })
                    break;
                case "dark":
                    setImageDark(prev => {
                        return {
                            ...prev,
                            fileList: e?.fileList,
                            path: e?.file?.response[0]?.link
                        }
                    })
                    break;
            }

            setNotificationMessage({
            status: "success",
            title: "",
            msg: `${e?.file?.name} file uploaded successfully`,
            key: "updateDesk",
            });
        } else if (e?.file?.status === "error") {
            switch(field){
                case "light":
                    setImageLight(null)
                    break;
                case "dark":
                    setImageDark(null)
                    break;
            }

            setNotificationMessage({
            status: "error",
            title: "",
            msg: `${e?.file?.name} file upload failed. - ${e?.file?.response}`,
            key: "updateDesk",
            });
        }
        } catch (e) {
        systemErrorNotification();
        }
    };

    const getCurrentReportOptions = () => {
        return report?.map(item => {
            return {
                value: item.reportId,
                label: item.displayName
            }
        })
    }

    const getReportFields = (reportId) => {
        return reportFields?.filter(item => item.reportId === reportId)?.map(item => {
            return {
                value: item.fieldId,
                label: item.uiName
            }
        })
    }

    const tabItems = languageData?.map((lang, index) => {
        return {
            key: index?.toString(),
            label: lang?.name,
            children: (
                <Row gutter={16}>
                    <Col xs={24} md={12}>
                        <Col className="gutter-row" xs={24}>
                            <InputFormItem
                                name={`dislayName_${lang.name}`}
                                label={"Display Name"}
                                placeholder={"Display Name"}
                                layout={verticalFormLayout}
                                onChange={(e)=> handleOnchange(e, "displayName", "content", lang.code)}
                                value={getDataWithLanguage(languageObj, "displayName", "content", lang.code)}
                                disabled={mode === "view"}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24}>
                            <InputFormItem
                                name={`description_${lang.name}`}
                                label={"Description"}
                                placeholder={"Description"}
                                layout={verticalFormLayout}
                                onChange={(e)=> handleOnchange(e, "description", "content", lang.code)}
                                value={getDataWithLanguage(languageObj, "description", "content", lang.code)}
                                disabled={mode === "view"}
                            />
                        </Col>
                    </Col>
                    {
                        form.getValues("termAndConditionEnabled") &&
                        <Col xs={24} md={12}>
                            <Col className="gutter-row" xs={24}>
                                <TextAreaInputFormItem
                                    name={`termAndCondition_${lang.name}`}
                                    label={"Term and Conditions"}
                                    placeholder={"Term and Conditions"}
                                    layout={verticalFormLayout}
                                    onChange={(e)=> handleOnchange(e, "termAndCondition", "content", lang.code)}
                                    value={getDataWithLanguage(languageObj, "termAndCondition", "content", lang.code)}
                                    disabled={mode === "view"}
                                />
                            </Col>
                        </Col>
                    }
                </Row>
            ),
        };
    });

    const onCancel = () => {
        closeFormModal && closeFormModal();
    };

    const {addVoucher} = useAddVoucherMutation();
    const {updateVoucher} = useUpdateVoucherMutation();
    const {systemErrorNotification} = useAuthorize();


    const onSubmit = async (values) => {
        try {
            setErrMsg("");
            const cleanValue = clean_value(values)
            const result = {
                ...cleanValue,
                displayName: languageObj?.displayName || [],
                description: languageObj?.description || [],
                termAndCondition: languageObj?.termAndCondition || [],
                imageLight: imageLight,
                imageDark: imageDark
            };
            
            if(result.rewardType !== "accountUpgradeVoucher"){
                if(!result.voucherValueIsRange) {
                    if(result.voucherValue === null || result.voucherValue === undefined || result.voucherValue === 0){
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: `${t("Voucher value cannot be blank")}`,
                            key: "updateVoucherValue",
                        })
                        return
                    }
                }
                else if(result.voucherValueIsRange){
                    if(result.voucherValueStartRange === 0 || result.voucherValueStartRange === null || result.voucherValueStartRange === undefined || result.voucherValueEndRange === null || result.voucherValueEndRange === undefined || result.voucherValueEndRange === 0){
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: `${t("Voucher value cannot be blank")}`,
                            key: "updateVoucherValue",
                        })
                        return
                    }
                } 

            }

            const response = mode === "add" ? await addVoucher(result) : await updateVoucher(item?._id, result);
            const {data} = response;
            const res = mode === "add" ? data?.addVoucher : data?.updateVoucher;
            if (res?.__typename === "BaseError") {
                let errMsg = t(res?.errKey);
                setErrMsg(errMsg);
            } else {
                closeFormModal && closeFormModal();
            }
        } catch (e: any) {
            systemErrorNotification();
        }
    };

    return (
        <div>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="voucher-form-1">
                    {errMsg && <p style={{color: "red"}}>{errMsg}</p>}
                    <Row gutter={16}>
                        <Col className="gutter-row" xs={24} md={12}>
                            <UploadSingleFormItem
                                name={`imageLight`}
                                label={t("Image Light")}
                                layout={verticalFormLayout}
                                listType={"picture-card"}
                                mode={"activities"}
                                fileList={imageLight?.fileList}
                                onChange={(e) =>
                                    uploadOnChange(e, "light")
                                }
                                onRemove={(e) =>
                                    setImageLight(null)
                                }
                                disabled={mode === "view"}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} md={12}>
                            <UploadSingleFormItem
                                name={`imageDark`}
                                label={t("Light Dark")}
                                layout={verticalFormLayout}
                                listType={"picture-card"}
                                mode={"activities"}
                                fileList={imageDark?.fileList}
                                onChange={(e) =>
                                    uploadOnChange(e, "dark")
                                }
                                onRemove={(e) =>
                                    setImageDark(null)
                                }
                                disabled={mode === "view"}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} md={12}>
                            <InputFormItem
                                name={"name"}
                                label={"Voucher Name"}
                                placeholder={"Voucher Name"}
                                layout={verticalFormLayout}
                                disabled={mode === "view"}
                                required
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <SelectFormItem
                                name={"rewardType"}
                                label={t("Reward Type")}
                                options={rewardType}
                                mode={"single"}
                                placeholder={t("Reward Type")}
                                disabled={mode === "view"}
                                onChange={()=>resetFieldValue(["voucherType", "voucherValue", "targetReportId", "targetReportFieldId", "stockVoucher", "maxAmount", "product", "voucherValueStartRange", "voucherValueEndRange"])}
                                required
                            />
                        </Col>
                        {
                            form.getValues("rewardType") === "cashVoucher" && 
                            <>
                            <Col xs={24} md={12}>
                                <SelectFormItem
                                    name={"voucherType"}
                                    label={t("Voucher Type")}
                                    options={voucherType}
                                    mode={"single"}
                                    placeholder={t("Voucher Type")}
                                    disabled={mode === "view"}
                                    required
                                />
                            </Col>
                            <Col className="gutter-row" xs={24} md={12}>
                                <RangeGroupFormItem
                                    name={"voucherValueIsRange"}
                                    startRange={"voucherValueStartRange"}
                                    endRange={"voucherValueEndRange"}
                                    fixValue={"voucherValue"}
                                    label={t("Voucher Value Is Range")}
                                    options={[
                                        {label: t("Range"), value: true},
                                        {label: t("Exact"), value: false},
                                    ]}
                                    layout={verticalFormLayout}
                                    onChange={(value)=>{
                                        if(value){
                                            resetFieldValue(["voucherValue"])
                                        }
                                        else {
                                            resetFieldValue(["voucherValueStartRange", "voucherValueEndRange"])
                                        }
                                    }}
                                    disabled={mode === "view"}
                                    requiredStar
                                />
                            </Col>
                            {
                                form.getValues("voucherType") === "percentage" &&
                                <>
                                    <Col xs={24} md={12}>
                                        <SelectFormItem
                                            name={"targetReportId"}
                                            label={t("Target Report")}
                                            options={getCurrentReportOptions()}
                                            mode={"single"}
                                            placeholder={t("Target Report")}
                                            disabled={mode === "view"}
                                            onChange={(value)=>{
                                                if(value){
                                                    resetFieldValue(["targetReportFieldId"])
                                                }
                                            }}
                                            required
                                        />
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <SelectFormItem
                                            name={"targetReportFieldId"}
                                            label={t("Target Report Field")}
                                            options={getReportFields(form.getValues("targetReportId"))}
                                            mode={"single"}
                                            placeholder={t("Target Report Field")}
                                            disabled={mode === "view"}
                                            required
                                        />
                                    </Col>
                                </>
                            }
                            <Col className="gutter-row" xs={24} md={12}>
                                <InputNumberFormItem
                                    name={"maxAmount"}
                                    label={"Max Amount"}
                                    placeholder={"Max Amount"}
                                    layout={verticalFormLayout}
                                    disabled={mode === "view"}
                                    style={{width: "100%"}}
                                />
                            </Col>
                            </>
                        }
                        {
                            (form.getValues("rewardType") === "bonusVoucher"  || form.getValues("rewardType") === "tradingVoucher") && 
                            <>
                            <Col xs={24} md={12}>
                                <SelectFormItem
                                    name={"voucherType"}
                                    label={t("Voucher Type")}
                                    options={voucherType}
                                    mode={"single"}
                                    placeholder={t("Voucher Type")}
                                    disabled={mode === "view"}
                                    required
                                />
                            </Col>
                            <Col className="gutter-row" xs={24} md={12}>
                                <RadioGroupFormItem
                                    name={"voucherStackable"}
                                    label={t("Voucher Stackable")}
                                    options={[
                                        {label: t("Stackable"), value: true},
                                        {label: t("Non Stackable"), value: false},
                                    ]}
                                    layout={verticalFormLayout}
                                    disabled={mode === "view"}
                                />
                            </Col>
                            <Col className="gutter-row" xs={24} md={12}>
                                <RangeGroupFormItem
                                    name={"voucherValueIsRange"}
                                    startRange={"voucherValueStartRange"}
                                    endRange={"voucherValueEndRange"}
                                    fixValue={"voucherValue"}
                                    label={t("Voucher Value Is Range")}
                                    options={[
                                        {label: t("Range"), value: true},
                                        {label: t("Exact"), value: false},
                                    ]}
                                    layout={verticalFormLayout}
                                    onChange={(value)=>{
                                        if(value){
                                            resetFieldValue(["voucherValue"])
                                        }
                                        else {
                                            resetFieldValue(["voucherValueStartRange", "voucherValueEndRange"])
                                        }
                                    }}
                                    disabled={mode === "view"}
                                    requiredStar
                                />
                            </Col>
                            {
                                form.getValues("voucherType") === "percentage" &&
                                <>
                                    <Col xs={24} md={12}>
                                        <SelectFormItem
                                            name={"targetReportId"}
                                            label={t("Target Report")}
                                            options={getCurrentReportOptions()}
                                            mode={"single"}
                                            placeholder={t("Target Report")}
                                            disabled={mode === "view"}
                                            onChange={(value)=>{
                                                if(value){
                                                    resetFieldValue(["targetReportFieldId"])
                                                }
                                            }}
                                            required
                                        />
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <SelectFormItem
                                            name={"targetReportFieldId"}
                                            label={t("Target Report Field")}
                                            options={getReportFields(form.getValues("targetReportId"))}
                                            mode={"single"}
                                            placeholder={t("Target Report Field")}
                                            disabled={mode === "view"}
                                            required
                                        />
                                    </Col>
                                </>
                            }
                            <Col className="gutter-row" xs={24} md={12}>
                                <InputFormItem
                                    name={"maxAmount"}
                                    label={"Max Amount"}
                                    placeholder={"Max Amount"}
                                    layout={verticalFormLayout}
                                    disabled={mode === "view"}
                                />
                            </Col>
                            </>
                        }
                        {
                            (form.getValues("rewardType") === "stockVoucher" || form.getValues("rewardType") === "commoditiesVoucher")  && 
                            <>
                            <Col xs={24} md={12}>
                                <SelectFormItem
                                    name={"stockVoucher"}
                                    label={t("Stock Voucher")}
                                    options={form.getValues("rewardType") === "stockVoucher" ? stock : 
                                            form.getValues("rewardType") === "commoditiesVoucher" ? commodities : stock}
                                    mode={"single"}
                                    placeholder={t("Stock Voucher")}
                                    showSearch
                                    disabled={mode === "view"}
                                    optionFilterProp={"label"}
                                    required
                                />
                            </Col>
                            <Col className="gutter-row" xs={24} md={12}>
                                <RangeGroupFormItem
                                    name={"voucherValueIsRange"}
                                    startRange={"voucherValueStartRange"}
                                    endRange={"voucherValueEndRange"}
                                    fixValue={"voucherValue"}
                                    label={t("Voucher Value Is Range")}
                                    options={[
                                        {label: t("Range"), value: true},
                                        {label: t("Exact"), value: false},
                                    ]}
                                    layout={verticalFormLayout}
                                    onChange={(value)=>{
                                        if(value){
                                            resetFieldValue(["voucherValue"])
                                        }
                                        else {
                                            resetFieldValue(["voucherValueStartRange", "voucherValueEndRange"])
                                        }
                                    }}
                                    disabled={mode === "view"}
                                    requiredStar
                                />
                            </Col>
                            </>
                        }
                        {
                            form.getValues("rewardType") === "accountUpgradeVoucher" && 
                            <>
                            <Col xs={24} md={12}>
                                <SelectFormItem
                                    name={"product"}
                                    label={t("Product")}
                                    options={product}
                                    placeholder={t("Product")}
                                    disabled={mode === "view"}
                                    required
                                />
                            </Col>
                            </>
                        }
                        <Col className="gutter-row" xs={24} md={12}>
                            <RadioGroupFormItem
                                name={"termAndConditionEnabled"}
                                label={t("Term and Conditions")}
                                options={[
                                    {label: t("Enabled"), value: true},
                                    {label: t("Disabled"), value: false},
                                ]}
                                layout={verticalFormLayout}
                                disabled={mode === "view"}
                            />
                        </Col>
                    </Row>
                    <Tabs
                        defaultActiveKey="0"
                        items={tabItems}
                        onChange={(activeKey) => setActiveTab(activeKey)}
                        activeKey={activeTab}
                    />
                    <Form.Item className="text-align-right" style={{paddingTop: "12px"}}>
                        {onCancel && (
                            <Button onClick={onCancel} icon={<CloseOutlined />} className="button-cancel-1">
                                {mode === "view" ? t("Close") : t("common.cancel")}
                            </Button>
                        )}
                        {mode !== "view" && (
                            <Button onClick={form.handleSubmit(onSubmit)} icon={<SaveOutlined />} className="button-submit-1">
                                {t("common.submit")}
                            </Button>
                        )}
                    </Form.Item>
                </form>
            </FormProvider>
        </div>
    );
};

export default VoucherForm;
