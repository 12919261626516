import React, {useEffect, useState} from "react";
import {Button, Col, Divider, Dropdown, Form, InputNumber, Popconfirm, Row, Space, Tooltip} from "antd";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../recoil_state";
import {useTranslation} from "react-i18next";
import {EditOutlined} from "@ant-design/icons";
import DisplayNameModal from "./displayNameModal";
import {MenuProps} from "antd/lib";
import MediaSelect from "../../../../_common/mediaComponent";
import {updateLevelSetting} from "../../../../../../api/restful/arena/levelSetting";

const LevelSetting = ({data, fetchLevelSetting}) => {
    const {t} = useTranslation()
    const [form] = Form.useForm()
    const labelCol = {span: 24}
    const setNotificationMessage = useSetRecoilState(notificationMessage)
    const [prevMaxLevel, setPrevMaxLevel] = useState<number>(data?.maxLevel)
    const [differentMaxLevel, setDifferentMaxLevel] = useState<boolean>(false)
    const [displayNameModalIsOpen, setDisplayModalIsOpen] = useState<boolean>(false)
    const [currentItem, setCurrentItem] = useState<any>(null)

    useEffect(() => {
        form.resetFields()

        setPrevMaxLevel(data?.maxLevel)
        generateLevels()
    }, [data]);

    const onSubmit = async (value) => {
        const levels = value?.levels?.map((item, i) => ({
            ...item,
            level: i + 1,
        })) || []

        try {
            const response = await updateLevelSetting({levels: levels, maxLevel: levels.length})

            if (response?.status === "Success") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Update Level Setting Successfully",
                    key: "arenaLevelSettingNotification",
                });

                fetchLevelSetting()
            } else {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: `Update Level Setting Failed: ${response?.errObj}`,
                    key: "arenaLevelSettingNotification",
                });
            }
        } catch (e) {
        }
    }

    const generateLevels = (clear = false) => {
        if (!clear) {
            const {maxLevel} = form.getFieldsValue()

            setPrevMaxLevel(maxLevel)
            setDifferentMaxLevel(false)
            form.setFieldValue("levels", Array.from(Array(maxLevel)).map((_, i) => ({level: i + 1})))
        } else {
            setPrevMaxLevel(1)
            form.setFieldsValue({levels: [{level: 1}], maxLevel: 1})
        }
    }

    const handleAdd = (index, add) => {
        form.setFieldValue("maxLevel", form.getFieldValue("levels")?.length + 1)

        add({expRequired: null, level: index + 1}, index)
    }

    const renderLevels = (field, add) => {
        const items: MenuProps["items"] = [
            {key: "before", label: <a onClick={() => handleAdd(field.name, add)}>Before</a>},
            {key: "after", label: <a onClick={() => handleAdd(field.name + 1, add)}>After</a>}
        ];

        return (
            <div className={"margin-bottom-0-5"} key={field.name}>
                <Row gutter={[8, 4]} align={"middle"}>
                    <Col span={2} className={"text-align-center"}>
                        <Form.Item>
                            {field.name + 1}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className={"margin-bottom-0"} name={[field.name, 'expRequired']}>
                            <InputNumber className={"w-100"} step={100} min={0}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item className={"margin-bottom-0 text-align-center"} shouldUpdate>
                            {({getFieldValue, setFieldValue}) => {
                                const currentValue = getFieldValue(['levels', field.name, 'light']);
                                return (
                                    <MediaSelect
                                        title={`Select Level ${field.name + 1} Icon (Light)`}
                                        passMedia={(value) => setFieldValue(['levels', field.name, 'light'], value)}
                                        selectedFile={currentValue}
                                    />
                                );
                            }}
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item className={"margin-bottom-0 text-align-center"} shouldUpdate>
                            {({getFieldValue, setFieldValue}) => {
                                const currentValue = getFieldValue(['levels', field.name, 'dark']);
                                return (
                                    <MediaSelect
                                        title={`Select Level ${field.name + 1} Icon (Dark)`}
                                        passMedia={(value) => setFieldValue(['levels', field.name, 'dark'], value)}
                                        selectedFile={currentValue}
                                    />
                                );
                            }}
                        </Form.Item>
                    </Col>
                    <Col span={4} className={"text-align-center"}>
                        <Button type={"primary"} icon={<EditOutlined/>} onClick={() => {
                            setDisplayModalIsOpen(true)
                            setCurrentItem(field.name + 1)
                        }}/>
                    </Col>
                    <Col span={2}>
                        <Dropdown menu={{items}} trigger={["click"]}>
                            <Tooltip title={"Add Level"}><Button type={"link"}>+</Button></Tooltip>
                        </Dropdown>
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <>
            <Form onFinish={onSubmit} initialValues={data} form={form} className={"margin-top-0-75 margin-bottom-0-75"} labelCol={labelCol}>
                <Row align={"bottom"}>
                    <Col span={8}>
                        <Form.Item name={"maxLevel"} label={"Max Level"}>
                            <InputNumber className={"w-100"} min={1} onChange={value => {
                                if (value !== prevMaxLevel) setDifferentMaxLevel(true)
                                else setDifferentMaxLevel(false)
                            }}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item>
                            {
                                differentMaxLevel &&
                                <Popconfirm
                                    title={"Continuing will overwrite all level settings you’ve configured. Are you sure you want to proceed?"}
                                    onConfirm={() => generateLevels(false)}
                                    overlayStyle={{maxWidth: 300}}
                                >
                                    <Button className={"margin-left-0-5"}>{t("common.confirm")}</Button>
                                </Popconfirm>
                            }
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Row justify={"end"}>
                            <Space>
                                <Button onClick={() => generateLevels(true)}>Clear All</Button>
                                <Button type={"primary"} onClick={form.submit}>Save</Button>
                            </Space>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Divider/>
                        <Form.List name={"levels"}>
                            {(fields, {add}) => {
                                const middleIndex = fields?.length <= 4 ? 4 : (Math.ceil(fields.length / 2));
                                const leftFields = fields.slice(0, middleIndex);
                                const rightFields = fields.slice(middleIndex);

                                return (
                                    <Row gutter={[8, 8]}>
                                        <Col span={12}>
                                            <Row gutter={[8, 8]} align={"middle"} className={"text-align-center"}>
                                                <Col span={2}>
                                                    <p>Level</p>
                                                </Col>
                                                <Col span={8}>
                                                    <p><span style={{color: "red"}}>*</span> Level Requirement (XP Points)</p>
                                                </Col>
                                                <Col span={4}>
                                                    <p>Icon Light</p>
                                                </Col>
                                                <Col span={4}>
                                                    <p>Icon Dark</p>
                                                </Col>
                                                <Col span={4}>
                                                    <p>Display Name</p>
                                                </Col>
                                            </Row>
                                            {leftFields.map((field, index) => renderLevels(field, add))}
                                        </Col>

                                        <Col span={12}>
                                            <div style={{height: 32}}/>
                                            {rightFields.map((field, index) => renderLevels(field, add))}
                                        </Col>
                                    </Row>
                                )
                            }}
                        </Form.List>
                    </Col>
                </Row>
            </Form>

            <DisplayNameModal
                open={displayNameModalIsOpen}
                handleClose={() => setDisplayModalIsOpen(false)}
                item={currentItem}
                form={form}
            />
        </>
    )
}

export default LevelSetting