import React, {useMemo, useState} from "react";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import {useTranslation} from "react-i18next";
import {getMission, getMissionCount, useDeleteMissionMutation, useUpdateMissionPositionMutation} from "../../../../../../api/graphql/mission";
import MissionModal from "../../component/missionModal";
import {Popconfirm, Space, Tooltip} from "antd";
import {CopyOutlined, DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import useAuthorize from "../../../../../../_common/authorize";
import MissionTopBar from "../../component/missionTopBar";
import {DndContext, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {SortableContext, useSortable, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {restrictToVerticalAxis} from "@dnd-kit/modifiers";
import {CSS} from "@dnd-kit/utilities";
import MissionStatusModal from "../../component/missionStatusModal";

function Mission_1(props) {
    const {tableName, permissionMission, column, languageData, userNetworkTreeDropdown} = props;

    const {t} = useTranslation();

    const {deleteMission} = useDeleteMissionMutation();
    const {systemErrorNotification} = useAuthorize();

    const [viewModalMode, setViewModalMode] = useState("");
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [viewModalSelectedItem, setViewModalSelectedItem] = useState({});

    const [orderBy, setOrderBy] = useState<any>("");
    const [limit, setLimit] = useState<any>(20);
    const [offset, setOffset] = useState<any>(0);
    const [filter, setFilter] = useState<any>(null);

    const {data: totalCount} = getMissionCount({filter: filter});
    const {data, loading} = getMission({filter: filter}, limit, offset, orderBy);

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize);
        setOffset(limit * (page - 1));
    };

    const deleteOnConfirm = async (id) => {
        try {
            await deleteMission(id);
        } catch (e) {
            console.log(e);
            systemErrorNotification();
        }
    };

    const duplicateItem = (record) => {
        props?.setFormModalType && props?.setFormModalType("add");
        let duplicatedRecord = {...record};
        delete duplicatedRecord?.missionTotalClaimed;
        delete duplicatedRecord?.missionTotalCompleted;
        props?.setSelectedItem && props?.setSelectedItem(duplicatedRecord);
        props?.setIsFormModalOpen && props?.setIsFormModalOpen(true);
    };

    const {updateMissionPosition, loading: updateLoading} = useUpdateMissionPositionMutation();

    const tableRow = (props) => {
        if (updateLoading) {
            return <tr {...props} />;
        }

        const {attributes, listeners, setNodeRef, transform, transition, isDragging} = useSortable({
            id: props["data-row-key"] ? props["data-row-key"] : "",
        });

        const style = {
            ...props.style,
            transform: CSS.Transform.toString(
                transform && {
                    ...transform,
                    scaleY: 1,
                }
            ),
            transition,
            cursor: "move",
            ...(isDragging
                ? {
                      position: "relative",
                      zIndex: 9999,
                  }
                : {}),
        };

        return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
    };

    const onViewTotalClaim = (record) => {
        setViewModalMode("totalClaimed");
        setViewModalSelectedItem(record);
        setTimeout(() => {
            setViewModalOpen(true);
        }, 1200);
    };

    const onViewTotalCompleted = (record) => {
        setViewModalMode("totalCompleted");
        setViewModalSelectedItem(record);
        setTimeout(() => {
            setViewModalOpen(true);
        }, 1200);
    };

    const Table = useMemo(() => {
        return (
            <TableAntDesign
                data={data?.getMission?.data}
                tableName={tableName}
                component={{
                    body: {
                        row: tableRow,
                    },
                }}
                columns={[
                    ...column,
                    {
                        title: "Mission Total Claimed",
                        key: "missionTotalClaimed",
                        dataIndex: "missionTotalClaimed",
                        sorter: (a, b) => a - b,
                        render: (text, record) => <a onClick={() => onViewTotalClaim(record)}>{text}</a>,
                    },
                    {
                        title: "Mission Total Completed",
                        key: "missionTotalCompleted",
                        dataIndex: "missionTotalCompleted",
                        sorter: (a, b) => a - b,
                        render: (text, record) => <a onClick={() => onViewTotalCompleted(record)}>{text}</a>,
                    },
                    {
                        title: t("common.action"),
                        key: "action",
                        width: 132,
                        render: (_, record: any) => (
                            <Space size="middle">
                                <Tooltip title={t("View")}>
                                    <a onClick={() => props.openFormModal && props.openFormModal("view", record)}>
                                        <EyeOutlined />
                                    </a>
                                </Tooltip>
                                {permissionMission?.mission?.edit?.edit && (
                                    <Tooltip title={t("Edit")}>
                                        <a onClick={() => props.openFormModal && props.openFormModal("edit", record)}>
                                            <EditOutlined />
                                        </a>
                                    </Tooltip>
                                )}
                                {permissionMission?.mission?.edit?.edit && (
                                    <Popconfirm
                                        placement="left"
                                        description={t("Are you sure to duplicate this mission?")}
                                        title={t(`Duplicate Mission`)}
                                        onConfirm={() => {
                                            duplicateItem(record);
                                        }}
                                        okText={t("Yes")}
                                        okType="danger"
                                        cancelText={t("No")}
                                    >
                                        <Tooltip title={t("Duplicate")}>
                                            <a>
                                                <CopyOutlined />
                                            </a>
                                        </Tooltip>
                                    </Popconfirm>
                                )}
                                {permissionMission?.mission?.delete?.delete && (
                                    <Tooltip title={t("Delete")}>
                                        <Popconfirm
                                            placement="left"
                                            title={t("Are you sure to delete this mission?")}
                                            onConfirm={() => {
                                                deleteOnConfirm(record._id);
                                            }}
                                            okText={t("common.yes")}
                                            cancelText={t("common.no")}
                                        >
                                            <a>
                                                <DeleteOutlined />
                                            </a>
                                        </Popconfirm>
                                    </Tooltip>
                                )}
                            </Space>
                        ),
                    },
                ]}
                size={"small"}
                loading={loading}
                filter={setFilter}
                order={setOrderBy}
                pagination={{
                    pageSize: limit,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                    total: totalCount,
                    showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
                }}
            />
        );
    }, [data, loading, permissionMission, limit, totalCount, updateLoading]);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 1,
            },
        })
    );

    const onDragEnd = async ({active, over}) => {
        try {
            if (over && active.id !== over?.id) {
                updateMissionPosition(active.id, over.data?.current.sortable.index + 1 + offset * limit);
            }
        } catch (e) {
            systemErrorNotification();
        }
    };

    return (
        <div id={"mission"}>
            {permissionMission?.mission?.add?.add && (
                <MissionModal
                    mode={props.formModalType}
                    item={props.selectedItem}
                    isOpen={props.isFormModalOpen}
                    closeFormModal={props.closeFormModal}
                    languageData={languageData}
                    product={props.product}
                    trigger={props.trigger}
                    multiTierSetting={props.multiTierSetting}
                    voucher={props.voucher}
                    voucherGroup={props.voucherGroup}
                    mission={props.mission}
                    missionGroup={props.missionGroup}
                    userNetworkTreeDropdown={userNetworkTreeDropdown}
                />
            )}
            <MissionStatusModal
                mode={viewModalMode}
                item={viewModalSelectedItem}
                isOpen={viewModalOpen}
                closeModal={() => setViewModalOpen(false)}
                tableName={"mission" + viewModalMode}
            />
            <DndContext onDragEnd={onDragEnd} sensors={sensors} modifiers={[restrictToVerticalAxis]}>
                <SortableContext
                    items={data?.getMission?.data?.length > 0 ? data?.getMission?.data?.map((i) => i._id)?.filter((item) => item !== null) : []}
                    strategy={verticalListSortingStrategy}
                >
                    {Table}
                </SortableContext>
            </DndContext>
        </div>
    );
}

export default Mission_1;
