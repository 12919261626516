import {Image, Tooltip} from "antd";
import dummyImage from "../../../assets/dummy-image.jpg";
import React from "react";

const ImageComponent = ({setting}) => {
    const {alt, sizeAuto, src, tooltip, ...restStyle} = setting

    return (
        <div>
            <Tooltip title={tooltip}>
                <Image
                    preview={false}
                    src={src || dummyImage}
                    style={restStyle}
                    alt={alt}
                />
            </Tooltip>
        </div>
    )
}

export default ImageComponent
