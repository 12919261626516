import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, InputNumber, Modal, Row, Select, Spin, Switch} from "antd";
import {useTranslation} from "react-i18next";
import {
    commissionTaggingUser,
    createCommissionSetting,
    updateCommissionSetting, updateCommissionSettingSystem
} from "../../../../../../../api/graphql/commission";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import useAuthorize from "../../../../../../../_common/authorize";
import {
    getReportingAll,
    getReportingFieldsByReportId,
    getReportingTypes
} from "../../../../../../../api/graphql/reporting";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";


const CommSettingModal = (props) => {

    const {
        editData,
        isOpen,
        closeModal,
        modalType,
        preload,
        preloadSetting,
        allReport
    } = props;
    const {t} = useTranslation()
    const [form] = Form.useForm();

    const [reportId, setReportId] = useState<any>(null);
    // const [reportPayoutId, setReportPayoutId] = useState<any>(null);
    const [fields, setFields] = useState<any>([]);
    const [fieldsSelect, setFieldsSelect] = useState<any>([]);
    const [reports, setReports] = useState<any>([])
    // const [reportsPayout, setReportsPayout] = useState<any>([])
    const [commissionTagging, setCommissionTagging] = useState<any>("")
    const [loading, setLoading] = useState<any>(false)
    const [fieldCalculatedSelect, setFieldCalculatedSelect] = useState<any>([]);
    const labelCol = {span: 24, offset: 0}
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {systemErrorNotification} = useAuthorize()

    const commissionTaggingValues = [
        {
            label: "Users",
            value: "User"
        },
        {
            label: "Trading Account",
            value: "Trading Account"
        },
    ]

    const comparisonOperator = [
        {
            label: "=",
            value: "E"
        },
        {
            label: "≥",
            value: "GTE"
        },
        {
            label: ">",
            value: "GT"
        },
        {
            label: "≤",
            value: "LTE"
        },
        {
            label: "<",
            value: "LT"
        },
        {
            label: "≠",
            value: "NE"
        },
        {
            label: "Include",
            value: "CONTAINS"
        },
    ]

    const getReports = async () => {
        const reportsResponse: any = [];
        const reportsPayoutResponse: any = [];

        if (allReport?.getReportingAll?.data && allReport?.getReportingAll?.data?.length > 0) {
            for (const report of allReport?.getReportingAll?.data) {

                if (report?.commissionReportType === "COMMISSION_PAYOUT_REPORT") {
                    reportsPayoutResponse.push({
                        label: report?.displayName,
                        value: report?.reportId
                    })
                }

                if (report?.level > 0 && report?.commissionReportType === "COMMISSION_CALCULATION_REPORT") {

                    if (commissionTagging === "Trading Account") {

                        if (report?.groupByType !== "USER" && report?.groupByType !== "CRMUSER") {
                            reportsResponse.push({
                                label: report?.displayName,
                                value: report?.reportId
                            })
                        }

                    } else {
                        reportsResponse.push({
                            label: report?.displayName,
                            value: report?.reportId
                        })
                    }

                }
            }
        }

        setReports(reportsResponse);
        // setReportsPayout(reportsPayoutResponse);

        return reportsResponse
    }

    const getFields = async (reportId) => {

        const response = await getReportingFieldsByReportId(reportId)
        const types = await getReportingTypes()

        const typesResult = types?.getReportingTypes?.data

        const fieldSelectData: any = []
        const fieldCalculatedSelectData: any = []
        const fieldsArray: any = []


        if (response?.getReportingFieldsByReportId?.data && response?.getReportingFieldsByReportId?.data?.length > 0) {
            for (const field of response?.getReportingFieldsByReportId?.data) {
                fieldSelectData.push({
                    label: field?.uiName,
                    value: field?.reportFieldName,
                    typeId: field?.typeId
                })

                if (field?.typeId === 2 || field?.typeId === 6) {
                    fieldCalculatedSelectData.push({
                        label: field?.uiName,
                        value: field?.reportFieldName,
                        typeId: field?.typeId
                    })
                }


                fieldsArray.push({
                    ...field,
                    fieldType: typesResult?.find((item) => item?._id === field?.typeId)
                })

            }
        }

        setFieldsSelect(fieldSelectData)
        setFieldCalculatedSelect(fieldCalculatedSelectData)
        setFields(fieldsArray)
    }

    const onSubmit = async (values: any) => {
        await setLoading(true)
        try {

            const foundReport = reports.find((item) => item?.value === values?.reportId);
            // const foundReportPayout = reportsPayout.find((item) => item?.value === values?.reportPayoutId);

            let reportFilterField: any = []

            if (values?.reportFilterField?.length > 0) {
                reportFilterField = values?.reportFilterField?.map((field) => {

                    const foundField = fields?.find((item) => item?.reportFieldName === field?.fieldName)

                    return {
                        ...field,
                        value: field?.value?.toString(),
                        fieldType: foundField?.fieldType?.name
                    }
                })
            }

            if (modalType === "add") {

                const response = await createCommissionSetting({
                    ...values,
                    reportName: foundReport?.label,
                    reportId: reportId,
                    // reportPayoutId: reportPayoutId,
                    // reportPayoutName: foundReportPayout?.label,
                    reportFilterField: reportFilterField,
                    commissionTagging: commissionTagging
                })

                if (response?.createCommissionSetting?.__typename === "CommissionSetting") {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: t("Added Successfully"),
                        key: "CommissionSetting",
                    });

                    preload()
                    preloadSetting()
                    closeModal()


                } else if (response?.createCommissionSetting.__typename === "BaseError") {
                    setNotificationMessage({
                        status: "error",
                        title: "",
                        msg: t(response?.createCommissionSetting?.errKey),
                        key: "CommissionSetting",
                    });
                } else {
                    setNotificationMessage({
                        status: "info",
                        title: "",
                        msg: t(response?.createCommissionSetting?.msg),
                        key: "CommissionSetting",
                    });
                }
            } else {
                const response = editData?.isSystem ? await updateCommissionSettingSystem(editData._id, {
                    label: values?.name,
                    reportFilterField: reportFilterField,
                    isEnabled: values?.isEnabled
                }) : await updateCommissionSetting(editData._id, {
                    ...values,
                    reportName: foundReport?.label,
                    reportId: reportId,
                    // reportPayoutId: reportPayoutId,
                    // reportPayoutName: foundReportPayout?.label,
                    reportFilterField: reportFilterField,
                    commissionTagging: commissionTagging
                })

                const responseData = response?.updateCommissionSettingSystem || response?.updateCommissionSetting

                if (responseData?.__typename === "CommissionSetting") {
                    setNotificationMessage({
                        status: "success",
                        title: t("common.updateSuccess"),
                        msg: t("Account tagging might take up to 1-2 mins to be in effect"),
                        key: "CommissionSetting",
                    });
                    // await commissionTaggingUser(editData?._id)
                    preload()
                    preloadSetting()
                    closeModal()
                } else if (responseData.__typename === "BaseError") {
                    setNotificationMessage({
                        status: "error",
                        title: "",
                        msg: t(responseData?.errKey),
                        key: "CommissionSetting",
                    });
                } else {
                    setNotificationMessage({
                        status: "info",
                        title: "",
                        msg: t(responseData?.msg),
                        key: "CommissionSetting",
                    });
                    closeModal()
                }
            }

        } catch (e) {
            systemErrorNotification()
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {

        const process = async () => {
            const reportsData: any = await getReports();

            if (reportsData?.length > 0 && reportId) {
                const found = reportsData?.find((item) => item?.value === reportId)
                if (!found) {
                    form.setFieldsValue({
                        reportCalculationField: null,
                        reportFilterField: [],
                        reportId: null
                    });
                    setReportId(null)
                }
            }

        }

        process()
    }, [commissionTagging, reportId, editData])

    useEffect(() => {

        if (editData) {
            setReportId(editData?.reportId)
            // setReportPayoutId(editData?.reportPayoutId)
            form.setFieldsValue({
                name: editData?.name,
                commissionTagging: editData?.commissionTagging,
                reportCalculationField: editData?.reportCalculationField,
                reportFilterField: editData?.reportFilterField,
                isEnabled: editData?.isEnabled,
                reportId: editData?.reportId,
                // reportPayoutId: editData?.reportPayoutId
            });
        }

        if (modalType === "add") {
            form.resetFields();
            setReportId(null)
        }

        setCommissionTagging(editData?.commissionTagging ?? null)

    }, [editData])

    useEffect(() => {
        if (reportId) {
            getFields(reportId)

            if (editData?.reportId !== reportId) {
                form.setFieldsValue({
                    reportCalculationField: null,
                    reportFilterField: [],
                });
            }

        }


    }, [reportId, editData])

    const closeModalFunction = () => {
        form.resetFields()
        setFields([])
        setFieldsSelect([])
        setReports([])
        setCommissionTagging("")
        setFieldCalculatedSelect("")
        closeModal()
    }

    const addTypeIdFunction = (e, key) => {
        const fieldValues = form.getFieldValue("reportFilterField")
        const fieldData = fieldValues?.map((v) => {

            const foundFieldTypeId = fieldsSelect?.find((item) => item?.value === v?.fieldName)

            return {
                ...v,
                typeId: foundFieldTypeId?.typeId
            }

        })

        form.setFieldsValue({
            reportFilterField: fieldData
        })

    }

    return (

        <Modal
            title={modalType === "add" ? "Add Commission Setting" : "Edit Commission Setting"}
            open={isOpen}
            onCancel={closeModalFunction}
            footer={null}
            width={1000}
            destroyOnClose
        >
            <Spin spinning={loading}>
                {
                    (modalType === "add" || editData && Object.keys(editData)?.length > 0) && (
                        <Form onFinish={onSubmit} form={form}>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Form.Item
                                        labelCol={labelCol}
                                        label={t("Name")}
                                        name={"name"}
                                        rules={[{required: true}]}
                                        initialValue={editData?.name}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        labelCol={labelCol}
                                        label={t("Commission Tagging")}
                                        name={"commissionTagging"}
                                        initialValue={editData?.commissionTagging ?? "User"}
                                        rules={[{required: true}]}
                                    >
                                        <Select options={commissionTaggingValues} allowClear showSearch
                                                onChange={(e) => setCommissionTagging(e)}
                                                optionFilterProp={"label"} disabled={editData?.isSystem}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        labelCol={labelCol}
                                        label={t("Calculation Report")}
                                        name={"reportId"}
                                        rules={[{required: true}]}
                                        initialValue={reportId}
                                    >
                                        <Select options={reports} allowClear showSearch onChange={(e) => setReportId(e)}
                                                optionFilterProp={"label"} disabled={editData?.isSystem}/>
                                    </Form.Item>
                                </Col>
                                {/*<Col span={24}>*/}
                                {/*    <Form.Item*/}
                                {/*        labelCol={labelCol}*/}
                                {/*        label={t("Payout Report")}*/}
                                {/*        name={"reportPayoutId"}*/}
                                {/*        rules={[{required: false}]}*/}
                                {/*        initialValue={reportPayoutId}*/}
                                {/*    >*/}
                                {/*        <Select options={reportsPayout} allowClear showSearch*/}
                                {/*                onChange={(e) => setReportPayoutId(e)}*/}
                                {/*                optionFilterProp={"label"}/>*/}
                                {/*    </Form.Item>*/}
                                {/*</Col>*/}
                                <Col span={24}>
                                    <Form.Item
                                        labelCol={labelCol}
                                        label={t("Report Calculation Field")}
                                        name={"reportCalculationField"}
                                        rules={[{required: true}]}
                                        initialValue={editData?.reportCalculationField}
                                    >
                                        <Select options={fieldCalculatedSelect} allowClear showSearch
                                                optionFilterProp={"label"} disabled={editData?.isSystem}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <label style={{marginBottom: "1%"}}>
                                        Report Filter Field
                                    </label>
                                    <Form.List
                                        name={"reportFilterField"}
                                        initialValue={editData?.reportFilterField}
                                    >
                                        {(fields, {add, remove}) => {
                                            return (
                                                <>
                                                    {fields.map(({
                                                                     key,
                                                                     name,
                                                                     ...restField
                                                                 }) => {

                                                        const init = editData?.reportFilterField?.[key]

                                                        return (
                                                            <Row gutter={20}>
                                                                <Col span={8} offset={1}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'fieldName']}
                                                                        initialValue={init?.fieldName}
                                                                    >
                                                                        <Select options={fieldsSelect} allowClear
                                                                                showSearch
                                                                                onChange={(e) => addTypeIdFunction(e, key)}
                                                                                optionFilterProp={"label"}/>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={5}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'operator']}
                                                                        initialValue={init?.operator}
                                                                    >
                                                                        <Select
                                                                            style={{width: "100%"}}
                                                                            options={comparisonOperator}/>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={8}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'value']}
                                                                        initialValue={init?.value}
                                                                    >
                                                                        {
                                                                            form.getFieldValue("reportFilterField")?.[key]?.typeId === 2 || form.getFieldValue("reportFilterField")?.[key]?.typeId === 6 ? (
                                                                                <InputNumber style={{width: "100%"}}/>
                                                                            ) : (
                                                                                <Input style={{width: "100%"}}/>
                                                                            )
                                                                        }

                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={2}>
                                                                    <MinusCircleOutlined
                                                                        onClick={() => {
                                                                            remove(name)
                                                                        }}/>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    })}
                                                    <Form.Item>
                                                        <Button type="dashed"
                                                                onClick={() => add()}
                                                                style={{marginTop: "15px"}}
                                                                block
                                                                icon={<PlusOutlined/>}>
                                                            Add Criteria
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            )
                                        }}
                                    </Form.List>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        labelCol={labelCol}
                                        label={t("Is Enabled")}
                                        name={"isEnabled"}
                                        initialValue={editData?.isEnabled}
                                    >
                                        <Switch defaultChecked={editData?.isEnabled}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Col span={24} style={{textAlign: "end", marginTop: "30px"}}>
                                <Button htmlType={"submit"}>
                                    Submit
                                </Button>
                            </Col>
                        </Form>
                    )
                }
            </Spin>
        </Modal>


    )

}

export default CommSettingModal