import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, Modal, Row, Select} from "antd";
import {useTranslation} from "react-i18next";
import {
    addCommissionGroup,
    getCommissionSetting,
    updateCommissionGroup
} from "../../../../../../../api/graphql/commission";
import useAuthorize from "../../../../../../../_common/authorize";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";


const CommGroupModal = (props) => {

    const {isOpen, closeModal, userData, preload, type, data} = props;
    const {t} = useTranslation()
    const labelCol = {span: 24, offset: 0}
    const [user, setUser] = useState([]);
    const [commSetting, setCommSetting] = useState([]);
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {systemErrorNotification} = useAuthorize()

    const preloadCommSetting = async () => {
        const response = await getCommissionSetting(null)

        const settings: any = []

        for (const setting of response?.getCommissionSetting?.data) {
            settings.push({
                label: setting?.name,
                value: setting?._id
            })
        }

        setCommSetting(settings)
    }

    const preloadUser = async () => {

        const users = userData?.map((item) => {

            let label = item?.username;
            if (item?.identificationNo) {
                label += ` - ${item?.identificationNo}`;
            }
            if (item?.email) {
                label += ` (${item?.email})`;
            }
            return {
                label: label,
                value: item?._id
            }
        })
        setUser(users)
    }

    const onSubmit = async (values) => {
        try {

            if (type === "add") {
                const response = await addCommissionGroup(values)

                if (response?.addCommissionGroup?.__typename === "CommissionGroup") {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: t("Added Successfully"),
                        key: "CommissionGroup",
                    });
                } else if (response?.addCommissionGroup.__typename === "BaseError") {
                    setNotificationMessage({
                        status: "error",
                        title: "",
                        msg: t(response?.addCommissionGroup?.errKey),
                        key: "CommissionGroup",
                    });
                } else {
                    setNotificationMessage({
                        status: "info",
                        title: "",
                        msg: t(response?.addCommissionGroup?.msg),
                        key: "CommissionGroup",
                    });
                }

            } else {
                const response = await updateCommissionGroup(data?._id, values)

                if (response?.updateCommissionGroup?.__typename === "CommissionGroup") {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: t("common.updateSuccess"),
                        key: "CommissionGroup",
                    });
                } else if (response?.updateCommissionGroup.__typename === "BaseError") {
                    setNotificationMessage({
                        status: "error",
                        title: "",
                        msg: t(response?.updateCommissionGroup?.errKey),
                        key: "CommissionGroup",
                    });
                } else {
                    setNotificationMessage({
                        status: "info",
                        title: "",
                        msg: t(response?.updateCommissionGroup?.msg),
                        key: "CommissionGroup",
                    });
                }

            }


        } catch (e) {
            systemErrorNotification()
        } finally {
            await preload()
            closeModal()
        }
    }

    useEffect(() => {
        preloadUser()
        preloadCommSetting()
    }, [userData])

    return (
        <Modal
            title={"Add Comm Group"}
            open={isOpen}
            onCancel={() => closeModal()}
            footer={null}
            width={1000}
            destroyOnClose
        >
            <>
                {
                    (type === "add" || data && Object.keys(data)?.length > 0) && (
                        <Form onFinish={onSubmit}>
                            <Row gutter={[16, 0]}>
                                <Col span={12}>
                                    <Form.Item
                                        labelCol={labelCol}
                                        label={t("Commission Setting")}
                                        name={"commissionSetting"}
                                        rules={[{required: true}]}
                                        initialValue={data?.commissionSetting?._id}
                                    >
                                        <Select options={commSetting} showSearch/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        labelCol={labelCol}
                                        label={t("Group Name")}
                                        name={"name"}
                                        initialValue={data?.name}
                                        rules={[{required: true}]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        labelCol={labelCol}
                                        label={t("Network")}
                                        name={"network"}
                                        initialValue={data?.network}
                                        rules={[{required: true}]}
                                    >
                                        <Select mode="multiple" allowClear options={user} showSearch
                                                optionFilterProp={"label"}/>
                                    </Form.Item>
                                </Col>

                            </Row>
                            <Col span={24} style={{textAlign: "end", marginTop: "30px"}}>
                                <Button htmlType={"submit"}>
                                    Submit
                                </Button>
                            </Col>
                        </Form>
                    )
                }
            </>
        </Modal>

    )

}

export default CommGroupModal