import React from "react";
import {getColumnSearchProps} from "../../../../components/table/function/filterTable";
import {formatMoney} from "./index";
import {useTranslation} from "react-i18next";

export const columnsSpreadGroupMT5 = () => {

    return [
        {
            title: "Name",
            dataIndex: "group",
            key: "group",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: "SO",
            dataIndex: "marginStopOut",
            key: "marginStopOut",
            ...getColumnSearchProps(),
            render: (text, record) => {

                if (record?.marginSoMode === "STOPOUT_PERCENT") {
                    return <p>{text + "%" || "-"}</p>
                } else {
                    return <p>{formatMoney(text) || "-"}</p>
                }
            },
        }
    ];
}

export const columnsSymbolSettingMT5 = () => {
    const {t} = useTranslation()
    function compare(key, value, record) {

        if (record?.amendmentField?.includes(key)) {
            return (
                <p style={{color: "blue"}}>
                    {value !== null && value !== undefined ? value : "-"}
                </p>
            )
        } else {
            return (
                <p>
                    {value !== null && value !== undefined ? value : "-"}
                </p>
            )
        }
    }

    return [
        {
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: "Path",
            dataIndex: "path",
            key: "path",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("Bid"),
            dataIndex: "bid",
            key: "bid",
            align: "right",
            ...getColumnSearchProps(),
            render: (text) => (text ? text : "-"),
        },
        {
            title: t("Ask"),
            dataIndex: "ask",
            key: "ask",
            align: "right",
            ...getColumnSearchProps(),
            render: (text) => (text ? text : "-"),
        },
        {
            title: t("Spread in Points"),
            dataIndex: "spreadInPoints",
            key: "spreadInPoints",
            align: "right",
            ...getColumnSearchProps(),
            render: (text) => (text ? text : "-"),
        },
        {
            title: t("Avg. Spread in Points"),
            dataIndex: "averageSpreadInPoints",
            key: "averageSpreadInPoints",
            align: "right",
            ...getColumnSearchProps(),
            render: (text) => (text ? text : "-"),
        },
        {
            title: t("Spread in USD"),
            dataIndex: "spreadInUsd",
            key: "spreadInUsd",
            align: "right",
            ...getColumnSearchProps(),
            render: (text) => (text ? text : "-"),
        },
        {
            title: t("Avg. Spread in USD"),
            dataIndex: "averageSpreadInUsd",
            key: "averageSpreadInUsd",
            align: "right",
            ...getColumnSearchProps(),
            render: (text) => (text ? text : "-"),
        },
        {
            title: t("Spread in USD / Lot"),
            dataIndex: "spreadInUsdPerLot",
            key: "spreadInUsdPerLot",
            align: "right",
            ...getColumnSearchProps(),
            render: (text) => (text ? text : "-"),
        },
        {
            title: t("Avg. Spread in USD / Lot"),
            dataIndex: "averageSpreadInUsdPerLot",
            key: "averageSpreadInUsdPerLot",
            align: "right",
            ...getColumnSearchProps(),
            render: (text) => (text ? text : "-"),
        },
        {
            title: "Trade Mode",
            dataIndex: "tradeMode",
            key: "tradeMode",
            ...getColumnSearchProps(),
            render: (text, record) => compare("tradeMode", text, record),
        },
        {
            title: "Execution Mode",
            dataIndex: "execMode",
            key: "execMode",
            ...getColumnSearchProps(),
            render: (text, record) => compare("execMode", text, record),
        },
        {
            title: "Fill Flags",
            dataIndex: "fillFlags",
            key: "fillFlags",
            ...getColumnSearchProps(),
            render: (text, record) => compare("fillFlags", text, record),
        },
        {
            title: "Expiration Flags",
            dataIndex: "expirFlags",
            key: "expirFlags",
            ...getColumnSearchProps(),
            render: (text, record) => compare("expirFlags", text, record),
        },
        {
            title: "Spread Diff",
            dataIndex: "spreadDiff",
            key: "spreadDiff",
            ...getColumnSearchProps(),
            render: (text, record) => compare("spreadDiff", text, record),
        },
        {
            title: "Spread Diff Balance",
            dataIndex: "spreadDiffBalance",
            key: "spreadDiffBalance",
            ...getColumnSearchProps(),
            render: (text, record) => compare("spreadDiffBalance", text, record),
        },
        {
            title: "Stops Level",
            dataIndex: "stopsLevel",
            key: "stopsLevel",
            ...getColumnSearchProps(),
            render: (text, record) => compare("stopsLevel", text, record),
        },
        {
            title: "Freeze Level",
            dataIndex: "freezeLevel",
            key: "freezeLevel",
            ...getColumnSearchProps(),
            render: (text, record) => compare("freezeLevel", text, record),
        },
        {
            title: "Volume Min",
            dataIndex: "volumeMin",
            key: "volumeMin",
            ...getColumnSearchProps(),
            render: (text, record) => compare("volumeMin", text, record),
        },
        {
            title: "Volume Min Ext",
            dataIndex: "volumeMinExt",
            key: "volumeMinExt",
            ...getColumnSearchProps(),
            render: (text, record) => compare("volumeMinExt", text, record),
        },
        {
            title: "Volume Max",
            dataIndex: "volumeMax",
            key: "volumeMax",
            ...getColumnSearchProps(),
            render: (text, record) => compare("volumeMax", text, record),
        },
        {
            title: "Volume Max Ext",
            dataIndex: "volumeMaxExt",
            key: "volumeMaxExt",
            ...getColumnSearchProps(),
            render: (text, record) => compare("volumeMaxExt", text, record),
        },
        {
            title: "Volume Step",
            dataIndex: "volumeStep",
            key: "volumeStep",
            ...getColumnSearchProps(),
            render: (text, record) => compare("volumeStep", text, record),
        },
        {
            title: "Volume Step Ext",
            dataIndex: "volumeStepExt",
            key: "volumeStepExt",
            ...getColumnSearchProps(),
            render: (text, record) => compare("volumeStepExt", text, record),
        },
        {
            title: "Volume Limit",
            dataIndex: "volumeLimit",
            key: "volumeLimit",
            ...getColumnSearchProps(),
            render: (text, record) => compare("volumeLimit", text, record),
        },
        {
            title: "Volume Limit Ext",
            dataIndex: "volumeLimitExt",
            key: "volumeLimitExt",
            ...getColumnSearchProps(),
            render: (text, record) => compare("volumeLimitExt", text, record),
        },
        {
            title: "Margin Flags",
            dataIndex: "marginFlags",
            key: "marginFlags",
            ...getColumnSearchProps(),
            render: (text, record) => compare("marginFlags", text, record),
        },
        {
            title: "Margin Initial",
            dataIndex: "marginInitial",
            key: "marginInitial",
            ...getColumnSearchProps(),
            render: (text, record) => compare("marginInitial", text, record),
        },
        {
            title: "Margin Maintenance",
            dataIndex: "marginMaintenance",
            key: "marginMaintenance",
            ...getColumnSearchProps(),
            render: (text, record) =>
                compare("marginMaintenance", text, record),
        },
        {
            title: "RE Flags",
            dataIndex: "rEFlags",
            key: "rEFlags",
            ...getColumnSearchProps(),
            render: (text, record) => compare("rEFlags", text, record),
        },
        {
            title: "Permissions Flags",
            dataIndex: "permissionsFlags",
            key: "permissionsFlags",
            ...getColumnSearchProps(),
            render: (text, record) =>
                compare("permissionsFlags", text, record),
        },
        {
            title: "Book Depth Limit",
            dataIndex: "bookDepthLimit",
            key: "bookDepthLimit",
            ...getColumnSearchProps(),
            render: (text, record) =>
                compare("bookDepthLimit", text, record),
        },
    ]
}


