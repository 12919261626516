import React, {useEffect, useState} from "react";
import {Button, Divider, Row, Switch} from "antd";
import {useTranslation} from "react-i18next";
import {
    getNewsletter, useUpdateNewsletterMutation,
} from "../../../../../../../api/graphql/newsletter";
import useAuthorize from "../../../../../../../_common/authorize";

const NewsletterSetting = (props) => {
    const {setCustomizeSubmitButton, close, updateViewableRank, viewableRank} = props
    const {t} = useTranslation()

    const {data: newsletterList} = getNewsletter({filter: null})
    const {updateNewsletter} = useUpdateNewsletterMutation()
    const {systemErrorNotification} = useAuthorize()

    const [input, setInput] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const onSubmit = async () => {
        setLoading(true)
        try {
            const promises = input.map(d => updateNewsletter(d?.newsletter, {isEnabled: d?.isEnabled}))

            await Promise.all([
                updateViewableRank(),
                promises
            ])
            close()
        } catch (e) {
            systemErrorNotification()
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        const defaultValue = newsletterList?.getNewsletter?.data?.map(d => {
            return {
                newsletter: d?._id,
                isEnabled: d?.isEnabled,
            }
        })
        setInput(defaultValue)
    }, [newsletterList?.getNewsletter?.data])

    useEffect(() => {
        setCustomizeSubmitButton(
            <Button onClick={onSubmit} type={"primary"} loading={loading}>{t("common.submit")}</Button>
        )
    }, [input, loading, viewableRank])

    const handleToggleSwitch = (newsletterId, isEnabled) => {
        const updatedInput = [...input];
        const newsletterIndex = updatedInput.findIndex(item => item?.newsletter === newsletterId);

        if (newsletterIndex !== -1) {
            updatedInput[newsletterIndex].isEnabled = isEnabled;
            setInput(updatedInput);
        }
    };

    return (
        <>
            <div className={"extra-form"} style={{padding: 16}}>
                {
                    newsletterList?.getNewsletter?.data?.map(d => {
                        const checked = input?.find(c => c?.newsletter === d?._id)?.isEnabled || false
                        return (
                            <Row justify={"space-between"} className={"margin-top-0-75"} key={d?.newsletter?._id}>
                                <label>{t(`newsletter.${d?.name}`)}</label>
                                <Switch
                                    checked={checked}
                                    onChange={isChecked => handleToggleSwitch(d?._id, isChecked)}
                                />
                            </Row>
                        )
                    })
                }
            </div>
        </>
    )
}

export default NewsletterSetting