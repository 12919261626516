import React, {useEffect} from "react";
import {BRAND_NAME} from "../../../../../config/brandVariable";
import {useGetCustomTrigger} from "./function/useGetQueryHooks";
import CustomTrigger_1 from "./view/layout1";

const CustomTrigger = () => {
    let layout = 1;
    let component: any;
    const [data, fetchCustomTrigger] = useGetCustomTrigger()

    useEffect(() => {
        fetchCustomTrigger()
    }, []);

    const props = {
        data: data,
        refetch: fetchCustomTrigger
    }

    switch (layout) {
        case 1:
            component = <CustomTrigger_1 {...props} />
            break;

        default:
            component = <CustomTrigger_1 {...props} />
            break;
    }

    // Hard code automation setting for Antos and Exco
    return ["antospnc", "antospncOld", "blueocean", "excotrades", "ridgewaymarkets", "fundamarkets", "minosfutures", "cronoscap", ].includes(BRAND_NAME) ? component : <></>;
}

export default CustomTrigger