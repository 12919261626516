import React, {useEffect, useState} from "react";
import {Button, Col, Modal, Row} from "antd";
import {useTranslation} from "react-i18next";
import {getCommissionParameterSetting, updateCommissionParameterSetting} from "../../../../../../../api/graphql/commission";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import useAuthorize from "../../../../../../../_common/authorize";
import ParameterRankCriteriaCard from "./parameterRankCriteriaCard";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

const ParameterRankCriteriaModal = (props) => {

    const {
        isOpen,
        closeModal,
        data,
        rank,
        rankAbove,
        preloadRank,
        conditions,
        commType
    } = props;
    const {t} = useTranslation()
    const [tierCriteria, setTierCriteria] = useState<any>([])
    const [initialCriteria, setInitialCriteria] = useState<any>([])
    const [btnLoading, setBtnLoading] = useState<any>(false)
    const [initialRun, setInitialRun] = useState<any>(false)
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {systemErrorNotification} = useAuthorize()

    const onSave = async () => {
        await setBtnLoading(true)
        try {

            let formattedData: any = []

            if (tierCriteria?.length > 0) {
                formattedData = await Promise.all(tierCriteria?.map((item) => {
                    const groups = item?.criteriaGroup
                    // Check if groups is an array before proceeding
                    if (Array.isArray(groups)) {
                        const updatedGroups = groups.map(group => {
                            // Create a copy of the group object without the 'form' property
                            const {form, ...rest} = group;
                            return rest;
                        });

                        // Replace the original 'groups' array with the updated one
                        item.criteriaGroup = updatedGroups;
                    }

                    delete item.form

                    return item
                }))
            }

            const saveObj = {
                parameter: data?._id,
                rank: rank?.value,
                level: rank?.level,
                commType: commType,
                tier: formattedData || []
            }


            const response = await updateCommissionParameterSetting(saveObj)

            if (response?.updateCommissionParameterSetting?.__typename === "CommissionParameterSetting") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Updated Successfully"),
                    key: "CommissionParameter",
                });
            } else if (response?.updateCommissionParameterSetting.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.updateCommissionParameterSetting?.errKey),
                    key: "CommissionParameter",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.updateCommissionParameterSetting?.msg),
                    key: "CommissionParameter",
                });
            }

        } catch (e) {
            systemErrorNotification()
        } finally {
            setBtnLoading(false)
            preloadRank()
        }

    }

    const deleteTier = (tierIdToDelete) => {
        const updatedTierCriteria = tierCriteria.filter((tier) => tier.tierId !== tierIdToDelete);
        setTierCriteria(updatedTierCriteria);
    };

    const addTier = async () => {
        const largestBatchId = tierCriteria.reduce((maxBatchId, obj) => {
            return obj.tierId > maxBatchId ? obj.tierId : maxBatchId;
        }, -1);

        setTierCriteria([...tierCriteria, {
            tierId: largestBatchId + 1 || 1,
            position: largestBatchId + 1 || 1,
            form: React.createRef()
        }]);

    }

    const preload = async () => {
        try {
            let response;

            if (commType === "rank") {
                response = await getCommissionParameterSetting({
                    parameter: data?._id,
                    rank: rank?.value,
                    commType: "rank"
                })
            } else {
                response = await getCommissionParameterSetting({
                    parameter: data?._id,
                    level: rank?.level,
                    commType: "level"
                })
            }

            const formattedData: any = [];
            for (let i = 0; i < response?.getCommissionParameterSetting?.data?.[0]?.criteria?.length; i++) {
                const item = response?.getCommissionParameterSetting?.data?.[0]?.criteria[i]

                const groups: any = []
                for (let j = 0; j < item?.criteriaGroup?.length; j++) {
                    const itemGroup = item?.criteriaGroup?.[j]
                    groups.push({
                        ...itemGroup,
                        criteria: itemGroup?.criteria || [],
                        batchId: j + 1,
                        form: React.createRef(),

                    })
                }

                if (commType === "rank") {
                    const ranks: any = []
                    if (item?.rankAmount?.length > 0) {
                        await Promise.all(item?.rankAmount?.map((itemRank) => {
                            ranks.push({
                                rank: itemRank?.rank?._id,
                                value: itemRank?.value
                            })
                        }))

                    }

                    const obj = {
                        position: item?.position,
                        name: item?.name,
                        rankAmount: ranks,
                        tierId: i + 1,
                        criteriaGroup: groups
                    }
                    formattedData.push(obj)

                } else {

                    const levels: any = []

                    if (item?.levelAmount?.length > 0) {
                        await Promise.all(item?.levelAmount?.map((itemLevel) => {
                            levels.push({
                                level: itemLevel?.level,
                                value: itemLevel?.value
                            })
                        }))

                    }

                    const obj = {
                        position: item?.position,
                        name: item?.name,
                        levelAmount: levels,
                        tierId: i + 1,
                        criteriaGroup: groups
                    }
                    formattedData.push(obj)
                }
            }

            setInitialCriteria(formattedData)
            setTierCriteria(formattedData)

        } catch (e) {
            console.log(e)
        } finally {
            setInitialRun(true)
        }
    }

    useEffect(() => {
        if (data && rank) {
            const functional = async () => {
                await setInitialRun(false)
                await preload()
            }
            functional()
        }
    }, [data, rank, rankAbove, commType])


    async function moveUp(id) {
        const arr = [...tierCriteria]

        for (let i = 0; i < arr.length; i++) {
            if (arr[i].tierId === id && i !== 0) {
                // Swap position values
                const temp = arr[i].position - 1;

                // Find the item with the new position
                const found = arr.find((item) => item.position === temp);

                if (found) {
                    found.position++; // Increment the position of the found item by 1
                }

                arr[i].position = temp

            }
        }

        setTierCriteria(prevState => {
            // Create a copy of the previous state
            const arr = [...prevState];
            // rest of your logic
            arr.sort((a, b) => a.position - b.position);
            return arr; // return the new state
        });


    }


    function moveDown(id) {
        const arr = tierCriteria
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].tierId === id && i !== arr.length - 1) {
                const temp = arr[i].position + 1;

                // Find the item with the new position
                const found = arr.find((item) => item.position === temp);

                if (found) {
                    found.position = found.position - 1; // Increment the position of the found item by 1
                }

                arr[i].position = temp

            }
        }

        setTierCriteria(prevState => {
            // Create a copy of the previous state
            const arr = [...prevState];
            // rest of your logic
            arr.sort((a, b) => a.position - b.position);
            return arr; // return the new state
        });
    }

    const onDragEnd = (result) => {
        if (!result?.destination || result?.destination.index === result?.source.index) return

        const movingItem = tierCriteria?.find(d => d?.tierId?.toString() === result.draggableId)
        const movingDataPosition = movingItem?.position
        const destinationPosition = result?.destination.index + 1

        setTierCriteria(prevState => {
            const temp = [...prevState]

            temp.forEach((item, index) => {
                if (item?.tierId === movingItem?.tierId) {
                    item.position = destinationPosition;
                } else if (movingDataPosition > destinationPosition) {
                    if (item?.position >= destinationPosition && item?.position < movingDataPosition) {
                        item.position += 1;
                    }
                } else if (destinationPosition > movingDataPosition) {
                    if (item?.position <= destinationPosition && item?.position > movingDataPosition) {
                        item.position -= 1;
                    }
                }
            });

            return temp;
        })
    }

    return (
        <Modal
            title={rank?.label}
            open={isOpen}
            onCancel={() => closeModal()}
            footer={null}
            width={1200}
            destroyOnClose
        >
            <Row gutter={16} style={{marginBottom: "15px", marginTop: "2%"}} justify="end">
                <Col>
                    <Button onClick={addTier}>
                        Add Tier
                    </Button>
                </Col>
                <Col>
                    <Button onClick={onSave} loading={btnLoading} disabled={btnLoading}>
                        Save
                    </Button>
                </Col>
            </Row>
            <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                <Droppable droppableId={"criteria"}>
                    {(droppableProvided) => (
                        <div ref={droppableProvided?.innerRef}>
                            {
                                initialRun && [...tierCriteria]?.sort((a, b) => a.position - b.position)?.map((tier, index) => {
                                    return (
                                        <Draggable key={tier.tierId?.toString()} draggableId={tier.tierId?.toString()}
                                                   index={index}
                                            // isDragDisabled={!profileInformationPermission?.edit?.edit && isUpdating}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided?.innerRef}
                                                    {...provided?.draggableProps}
                                                    {...provided?.dragHandleProps}
                                                    style={{
                                                        ...provided?.draggableProps.style,
                                                        opacity: snapshot?.isDragging ? 0.8 : 1,
                                                    }}
                                                >
                                                    <div key={tier.tierId} style={{marginBottom: "15px"}}>
                                                        <ParameterRankCriteriaCard rankAbove={rankAbove} tierId={tier?.tierId}
                                                                                   conditions={conditions}
                                                                                   tierCriteria={tierCriteria}
                                                                                   initialCriteria={initialCriteria}
                                                                                   setTierCriteria={setTierCriteria} tier={tier}
                                                                                   commType={commType}
                                                                                   deleteTier={deleteTier}
                                                                                   moveUp={moveUp}
                                                                                   position={tier?.position}
                                                                                   totalPosition={tierCriteria?.length}
                                                                                   moveDown={moveDown}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>

                                    )
                                })
                            }

                            {droppableProvided?.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </Modal>

    )

}

export default ParameterRankCriteriaModal