const rewardType = [
    {key: "cashVoucher", label: "Cash Voucher", value: "cashVoucher"},
    {key: "tradingVoucher", label: "Trading Voucher", value: "tradingVoucher"},
    {key: "bonusVoucher", label: "Bonus Voucher", value: "bonusVoucher"},
    {key: "stockVoucher", label: "Stock Voucher", value: "stockVoucher"},
    {key: "commoditiesVoucher", label: "Commodities Voucher", value: "commoditiesVoucher"},
    {key: "accountUpgradeVoucher", label: "Account Upgrade Voucher", value: "accountUpgradeVoucher"},
]

const voucherType = [
    {key: "percentage", label: "percentage", value: "percentage"},
    {key: "value", label: "value", value: "value"},
]

const voucherExpiryType = [
    {key: "unlimited", label: "unlimited", value: "unlimited"},
    {key: "days", label: "days", value: "days"},
    {key: "endDate", label: "endDate", value: "endDate"},
]
export {
    rewardType,
    voucherType,
    voucherExpiryType
}