import React from "react";
import {useRecoilState} from "recoil";
import {Col, Row, Tabs} from "antd";
import {useTranslation} from "react-i18next";

import ModuleTable from "./component/moduleTable";
import ModuleWebNavigation from "./component/moduleWebNavigation";
import ModuleMobileNavigation from "./component/moduleMobileNavigation";
import {selfPermissionObj} from "../../../../../../recoil_state";
import "./css/style.css"

const Module_1 = (props) => {
    const {data, loading, mobileData, webData, language} = props;
    const {t} = useTranslation();
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const webModuleSettingPermission =
        selfPermission?.brandSetting?.moduleSettings;
    const mobileModuleSettingPermission =
        selfPermission?.brandSetting?.moduleSettings;

    const items = [
        {
            key: "1",
            label: `${t("Modules")}`,
            children: <ModuleTable item={data} loading={loading} language={language}/>,
        },
    ];

    if (webModuleSettingPermission?.view) {
        items.push({
            key: "2",
            label: `${t("Web Navigation")}`,
            children: (
                <ModuleWebNavigation item={webData} module={data} language={language}/>
            ),
        });
    }

    if (mobileModuleSettingPermission?.view) {
        items.push({
            key: "3",
            label: `${t("Mobile Navigation")}`,
            children: (
                <ModuleMobileNavigation
                    item={mobileData}
                    module={data}
                    language={language}
                />
            ),
        });
    }

    return (
        <div id="webModules">
            <Row>
                <Col span={20}>
                    <h1>{t("Module Settings")}</h1>
                </Col>
            </Row>

            <Tabs defaultActiveKey="1" items={items}/>
        </div>
    );
};

export default Module_1;
