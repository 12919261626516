import React, {useState} from "react";
import {Button, Checkbox, DatePicker, Form, Input, message, Radio, Row, Segmented, Select, Space} from "antd";
import {useTranslation} from "react-i18next";
import EditTableUserPreferenceButton from "../../../../../components/table/function/editTableUserPreferenceButton";
import ExportTableButton from "../../../../../components/table/function/exportTable";
import TextArea from "antd/es/input/TextArea";
import {useBatchCreateFollowUp, useBatchUpdate, useBatchUpdateReferrer, useUpdateUserDomainMutation} from "../../../../../api/graphql/user";
import {useBatchEWalletAdjustmentMutation} from "../../../../../api/graphql/eWalletAdjustment";
import {useRecoilValue} from "recoil";
import {brandDomainOptionsState, userOptionsState} from "../../../_common/state";
import useAuthorize from "../../../../../_common/authorize";
import {disableDateTime} from "../../../_common/function";
import {useIsMobile} from "../../../../../_common/layout";
import {fieldListState} from "../../../../../../recoil_state";
import {formatDateTimeTimezone} from "../../../../../function/_common";

const ClientsTopBar = (props) => {
    const {
        setIsAddClientsOpen,
        setIsImportEWalletOpen,
        batchUpdateRecord,
        setBatchUpdateRecord,
        filterType,
        setFilterType,
        setFilterTypeId,
        tableName,
        exportData,
        exportColumn,
        permissionUser,
        isShowingArchive,
        setIsShowingArchive,
        refetchUserData
    } = props;
    const [updateActionXML, setUpdateActionXML] = useState<any>();
    const isMobile = useIsMobile(768);
    const {t} = useTranslation();

    const {batchCreateFollowUp, loading: loadingBatchFollowUp} = useBatchCreateFollowUp();
    const {batchUpdate, loading: loadingBatchUpdate} = useBatchUpdate();
    const {batchUpdateReferrer} = useBatchUpdateReferrer();
    const {batchEWalletAdjustment} = useBatchEWalletAdjustmentMutation();
    const {systemErrorNotification} = useAuthorize();
    const {updateUserDomain} = useUpdateUserDomainMutation()
    const {disabledDate} = disableDateTime();

    const userOptions = useRecoilValue(userOptionsState);
    const brandDropdownOptions = useRecoilValue(brandDomainOptionsState)

    const batchUpdateIds = batchUpdateRecord?.map((d) => d?.parentId);
    const labelCol = {span: 6};

    const fieldList = useRecoilValue<any>(fieldListState)

    const [form] = Form.useForm()
    const batchEdit = () => {
        try {
            let dateTime;
            form.resetFields()
            const batchFollowUpOnSubmit = async (value) => {
                let fieldNames: any = [];
                for (const property in value) {
                    if (property.endsWith("Checked") && value[property]) {
                        fieldNames.push(property?.replace("Checked", ""));
                    }
                }

                if (fieldNames?.length > 0) {
                    if (fieldNames?.includes("followUp")) {
                        if (value?.description) {
                            await batchCreateFollowUp(localStorage?.getItem("userId"), batchUpdateIds, {
                                description: value?.description,
                                followUpDateTime: dateTime ? formatDateTimeTimezone(dateTime, "formSubmit") : null,
                                type: "followUp",
                                followingType: "users",
                            });

                            refetchUserData()
                            clear();
                        } else {
                            message.error(t("errorMsg.required.pleaseFillIn", {field: t("common.description")}));
                        }
                    }
                    if (fieldNames?.includes("comment")) {
                        if (value?.comment) {
                            await batchUpdate(
                                batchUpdateIds,
                                {
                                    comment: value?.comment,
                                },
                                "client"
                            );

                            refetchUserData()
                            clear();
                        } else {
                            message.error(t("errorMsg.required.pleaseFillIn", {field: t("user.comment")}));
                        }
                    }
                } else {
                    message.error(t("admin.err.please_fill_in_at_least_one_field"));
                }
            };

            const onChange = (dates: any, dateStrings: any) => {
                dateTime = dateStrings;
            };

            setUpdateActionXML(
                <Form form={form} className={"margin-bottom-0-75 margin-top-0-75"} onFinish={(value) => batchFollowUpOnSubmit(value)}>
                    <div className={"margin-bottom-0-75"}>
                        <div>
                            <Space>
                                <Form.Item name={"commentChecked"} valuePropName="checked">
                                    <Checkbox/>
                                </Form.Item>
                                <Form.Item label={t("common.comment")} name={"comment"}>
                                    <Select
                                        showSearch
                                        style={{width: 200}}
                                        allowClear
                                        options={fieldList?.find(d => d?.fieldName === "clientComment")?.optionValue?.map(d => ({...d, label: t(d?.label)}))}
                                    />
                                </Form.Item>
                            </Space>
                        </div>
                        <Space>
                            <Form.Item name={"followUpChecked"} valuePropName="checked">
                                <Checkbox/>
                            </Form.Item>
                            {t("user.followUp")}
                        </Space>
                        <Form.Item label={t("common.description")} name={"description"} labelCol={{span: 24}} required>
                            <TextArea rows={2}/>
                        </Form.Item>
                        <Form.Item label={t("user.setUpFollowUpTime")} name={"followUpDateTime"} labelCol={{span: 24}}>
                            <DatePicker
                                format={"YYYY-MM-DD HH:mm"}
                                style={{width: "100%"}}
                                showTime={{format: "HH:mm"}}
                                disabledDate={disabledDate}
                                onChange={onChange}
                                placeholder={""}
                            />
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loadingBatchUpdate || loadingBatchFollowUp}>
                            {t("common.submit")}
                        </Button>
                    </Form.Item>
                </Form>
            );
        } catch (e) {
            console.error(e);
            systemErrorNotification();
        }
    };

    const batchEditReferrer = () => {
        try {
            const batchEditReferrerOnSubmit = async (value) => {
                await batchUpdateReferrer(batchUpdateIds, value?.referrer, "client");
                refetchUserData()
                clear();
            };

            setUpdateActionXML(
                <Form className={"margin-bottom-0-75 margin-top-0-75"} onFinish={(value) => batchEditReferrerOnSubmit(value)}>
                    <div className={"margin-bottom-0-75"}>
                        <Form.Item label={t("user.referrer")} name={"referrer"}>
                            <Select showSearch style={{width: 200}} optionFilterProp={"label"} options={userOptions} optionLabelProp="selectedLabel"/>
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {t("common.submit")}
                        </Button>
                    </Form.Item>
                </Form>
            );
        } catch (e) {
            console.error(e);
            systemErrorNotification();
        }
    };

    const batchUpdateEWalletAdjustment = () => {
        try {
            const batchEditEWalletAdjustmentOnSubmit = async (value) => {
                await batchEWalletAdjustment(batchUpdateIds, {
                    type: value?.type,
                    amount: parseFloat(value?.amount),
                    description: value?.description,
                });

                refetchUserData()
                clear();
            };

            setUpdateActionXML(
                <Form className={"margin-bottom-0-75 margin-top-0-75"} onFinish={(value) => batchEditEWalletAdjustmentOnSubmit(value)}>
                    <div className={"margin-bottom-0-75"}>
                        <Form.Item label={t("user.transactionType")} name={"type"} labelCol={labelCol}>
                            <Radio.Group>
                                <Radio.Button value="deposit">{t("common.deposit")}</Radio.Button>
                                <Radio.Button value="withdrawal">{t("common.withdrawal")}</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label={t("common.amount")} name={"amount"} labelCol={labelCol}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label={t("common.description")} name={"description"} labelCol={labelCol}>
                            <Input/>
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {t("common.submit")}
                        </Button>
                    </Form.Item>
                </Form>
            );
        } catch (e) {
            console.error(e);
            systemErrorNotification();
        }
    };

    const batchEditDomain = () => {
        try {
            const batchUpdateUserDomainOnSubmit = async (value) => {
                await updateUserDomain(
                    batchUpdateIds,
                    value?.domain
                )

                refetchUserData()
                clear()
            }

            setUpdateActionXML(
                <Form
                    className={"margin-bottom-0-75 margin-top-0-75"}
                    onFinish={(value) => batchUpdateUserDomainOnSubmit(value)}>
                    <div className={"margin-bottom-0-75"}>
                        <Form.Item label={t("brandSetting.domain")} name={"domain"}>
                            <Select
                                showSearch
                                style={{width: 200}}
                                options={brandDropdownOptions}
                                optionFilterProp={"label"}
                                optionLabelProp="selectedLabel"
                            />
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {t("common.submit")}
                        </Button>
                    </Form.Item>
                </Form>
            )
        } catch (e) {
            console.error(e)
            systemErrorNotification()
        }

    }

    const clear = () => {
        setUpdateActionXML(<></>);
        setBatchUpdateRecord([]);
    };

    return (
        <>
            <Row className={"margin-bottom-0-75 display-flex"} wrap>
                {permissionUser?.clientsUser?.batchAction && batchUpdateRecord?.length > 0 ? (
                    <div>
                        <Space>
                            {permissionUser?.clientsUser?.edit?.edit && <Button onClick={() => batchEdit()}>{t("common.batchEdit")}</Button>}
                            {permissionUser?.clientsUser?.edit?.referrer && (
                                <Button onClick={() => batchEditReferrer()}>{t("common.batchChangeReferrer")}</Button>
                            )}
                            {permissionUser?.eWalletAdjustment?.add?.add && (
                                <Button onClick={() => batchUpdateEWalletAdjustment()}>{t("common.batchEWalletAdjustment")}</Button>
                            )}
                            {permissionUser?.clientsUser?.edit?.edit && <Button onClick={() => batchEditDomain()}>{t("common.editUserDomain")}</Button>}
                            <Button onClick={() => clear()}>{t("common.cancel")}</Button>
                        </Space>
                        {updateActionXML}
                    </div>
                ) : (
                    <>
                        <Space className={"margin-bottom-0-3"} wrap>
                            {permissionUser?.clientsUser?.view?.archive && (
                                <Segmented
                                    options={[
                                        {label: t("user.active"), value: "active"},
                                        {label: t("user.archive"), value: "archive"},
                                    ]}
                                    defaultValue={"active"}
                                    onChange={value => setIsShowingArchive(value !== "active")}
                                />
                            )}
                            <Select
                                value={filterType || "all"}
                                style={{width: 200}}
                                onChange={(value) => {
                                    setFilterType(value);
                                    if (value === "all") {
                                        setFilterTypeId(null);
                                    }
                                }}
                                options={[
                                    {value: "all", label: t("common.all")},
                                    {value: "user", label: t("common.filterBasedOnUserTree")},
                                ]}
                            />
                            {filterType === "user" && (
                                <Select
                                    style={{width: 250}}
                                    onChange={(value) => {
                                        setFilterTypeId(value);
                                    }}
                                    options={userOptions}
                                    optionFilterProp={"label"}
                                    allowClear
                                    showSearch
                                    optionLabelProp="selectedLabel"
                                />
                            )}
                        </Space>
                        <Space className={"margin-left-auto margin-bottom-0-3"} wrap>
                            <EditTableUserPreferenceButton tableName={tableName} tableColumn={exportColumn}/>
                            <ExportTableButton tableData={exportData} tableColumn={exportColumn} tableName={tableName}/>
                            {permissionUser?.clientsUser?.edit?.ewalletAdjustment && !isShowingArchive && (
                                <Button onClick={() => setIsImportEWalletOpen(true)}>{t("user.importExcelEWalletAdjustment")}</Button>
                            )}
                            {permissionUser?.clientsUser?.add?.add && !isShowingArchive && (
                                <Button type={"primary"} onClick={() => setIsAddClientsOpen(true)}>
                                    {t("common.add")}
                                </Button>
                            )}
                        </Space>
                    </>
                )}
            </Row>
        </>
    );
};

export default ClientsTopBar;
