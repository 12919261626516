import React, {useCallback, useEffect, useState} from "react";
import ReactFlow, {Background, Controls, Panel, Position, useEdgesState, useNodesState} from "reactflow";
import {useFlowBuilderFunction} from "../../../../function/flowBuilderFunction";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {
    currentTabState,
    documentGroupSettingModalPropState,
    fieldSettingModalPropState,
    generalListCountryState,
    sortStepSettingModalPropState,
    stepSettingModalPropState,
    tabBarExtraContentState,
    userVerificationAvailableFieldState,
    userVerificationStepState,
} from "../../../../state";
import {Button, Form, Popconfirm, Select, Space, Spin, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {ClientTypeNode, CountryNode, DocumentGroupNode, DocumentNode, FieldGroupNode, FieldNode, StepNode} from "./customNode";
import StepModalForm from "./modalForm/step";
import {useGeneralListFunction} from "../../../../function/generalListFunction";
import dagre from "dagre";
import DocumentGroupModalForm from "./modalForm/documentGroup";
import FieldModalForm from "./modalForm/field";
import SortStepModalForm from "./modalForm/sortStep";
import DuplicateFlowModalForm from "./modalForm/duplicateFlow";
import {DeleteOutlined} from "@ant-design/icons";
import {getIsMainMasterAdmin} from "../../../../../../../api/graphql/_common";
import ImportDBTableButton from "../importDBTable";
import ExportDBTableButton from "../exportDBTable";

const nodeTypes = {
    countryNode: CountryNode,
    clientTypeNode: ClientTypeNode,
    stepNode: StepNode,
    documentGroupNode: DocumentGroupNode,
    documentNode: DocumentNode,
    fieldGroupNode: FieldGroupNode,
    fieldNode: FieldNode,
};

const FlowBuilder = ({permission}) => {
    const {getUserVerificationAvailableFieldFunction, deleteUserVerificationCountryFlowFunction} = useFlowBuilderFunction();
    const {getGeneralListField} = useGeneralListFunction();
    const steps = useRecoilValue<any>(userVerificationStepState);
    const currentTab = useRecoilValue(currentTabState);
    const countryList = useRecoilValue<any>(generalListCountryState);
    const availableField = useRecoilValue<any>(userVerificationAvailableFieldState);
    const stepProp = useRecoilValue<any>(stepSettingModalPropState);
    const documentGroupProp = useRecoilValue<any>(documentGroupSettingModalPropState);
    const fieldProp = useRecoilValue<any>(fieldSettingModalPropState);
    const sortStepProp = useRecoilValue<any>(sortStepSettingModalPropState);
    const setTabBarExtraContent = useSetRecoilState<any>(tabBarExtraContentState);
    const {t} = useTranslation();
    const [flowLoading, setFlowLoading] = useState<any>(false);
    const [selectedCountry, setSelectedCountry] = useState<any>("Default");
    const [isDuplicateOpen, setIsDuplicateOpen] = useState<any>(false);
    const [isMainMasterAdmin, setIsMainMasterAdmin] = useState(false);

    const checkIsMasterAdmin = async () => {
        let response = await getIsMainMasterAdmin();
        setIsMainMasterAdmin(response?.isMainMasterAdmin);
    };

    useEffect(() => {
        checkIsMasterAdmin();
    }, []);

    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    useEffect(() => {
        if (currentTab === "flowBuilder") {
            getGeneralListField({}, false);
            setTabBarExtraContent(
                permission?.add?.add && (
                    <Space>
                        <Form.Item label={t("accountVerification.country")} style={{marginBottom: 0}}>
                            <Select
                                onSelect={(value) => setSelectedCountry(value)}
                                options={countryList?.map((d) => ({label: d?.country, value: d?.country}))}
                                defaultValue={availableField?.country || "Default"}
                                style={{width: 200}}
                            />
                        </Form.Item>
                        <Button onClick={() => setIsDuplicateOpen(true)}>{t("accountVerification.duplicateFlow")}</Button>
                        {selectedCountry !== "Default" && (
                            <Popconfirm
                                placement="left"
                                description={t("accountVerification.deleteCountryFlow.placeholder")}
                                title={t("accountVerification.deleteCountryFlow.title")}
                                onConfirm={async () => {
                                    await deleteUserVerificationCountryFlowFunction(countryList?.find((d) => d?.country === selectedCountry));
                                }}
                                okText={t("Yes")}
                                okType="danger"
                                cancelText={t("No")}
                            >
                                <Tooltip title={t("accountVerification.deleteCountryFlow.title")}>
                                    <Button type={"link"} danger icon={<DeleteOutlined />} />
                                </Tooltip>
                            </Popconfirm>
                        )}
                        {isMainMasterAdmin && <ImportDBTableButton />}
                        {isMainMasterAdmin && <ExportDBTableButton />}
                    </Space>
                )
            );
        }
    }, [currentTab, steps, selectedCountry, isMainMasterAdmin]);

    const fetchAvailableField = async (country) => {
        setFlowLoading(true);
        await getUserVerificationAvailableFieldFunction(country);
        setFlowLoading(false);
    };

    useEffect(() => {
        if (currentTab === "flowBuilder") {
            fetchAvailableField(selectedCountry);
        }
    }, [selectedCountry, currentTab]);

    const displayCountryFlow = useCallback(async () => {
        const nodeAry: any = [];
        const edgeAry: any = [];
        const position = {x: 0, y: 0};

        await dagreGraph.setGraph({rankdir: "LR"});
        await nodeAry.push({
            id: `${availableField?._id}`,
            data: {label: t(availableField?.country)},
            type: "countryNode",
            position,
        });

        await availableField?.clientType?.forEach((clientType) => {
            nodeAry.push({
                id: `${clientType?.clientType}`,
                dragHandle: ".drag-handle",
                data: {
                    label: t(clientType?.clientType),
                    data: {country: availableField?._id, type: clientType, currentCountry: availableField?.country},
                },
                type: "clientTypeNode",
                position,
            });
            edgeAry.push({
                id: `${availableField?._id}-${clientType?.clientType}`,
                source: availableField?._id,
                target: clientType?.clientType,
            });

            clientType?.steps?.forEach((step) => {
                nodeAry.push({
                    id: `${step?._id}`,
                    dragHandle: ".drag-handle",
                    data: {
                        label: t(step?.label),
                        stepData: {
                            country: availableField?._id,
                            type: clientType,
                            step: step,
                            currentCountry: availableField?.country,
                        },
                    },
                    type: "stepNode",
                    position,
                });
                edgeAry.push({
                    id: `${clientType?.clientType}-${step?._id}`,
                    source: clientType?.clientType,
                    target: step?._id,
                });

                step?.documentGroup?.forEach((group) => {
                    nodeAry.push({
                        id: `${group?._id}`,
                        dragHandle: ".drag-handle",
                        data: {label: t(group?.group?.displayNameLabel || group?.group?.label), data: group, currentCountry: availableField?.country},
                        type: "documentGroupNode",
                        position,
                    });
                    edgeAry.push({
                        id: `${step?._id}-${group?._id}`,
                        source: step?._id,
                        target: group?._id,
                    });

                    group?.documents?.forEach((document) => {
                        nodeAry.push({
                            id: `${document?._id}`,
                            dragHandle: ".drag-handle",
                            data: {
                                label: t(document?.document?.label),
                                group: group,
                                data: document,
                                currentCountry: availableField?.country,
                            },
                            type: "documentNode",
                            position,
                        });
                        edgeAry.push({
                            id: `${group?._id}-${document?._id}`,
                            source: group?._id,
                            target: document?._id,
                        });

                        // document?.fieldList?.forEach(field => {
                        //     nodeAry.push({
                        //         id: `${field?._id}`,
                        //         dragHandle: '.drag-handle',
                        //         data: {label: t(field?.field?.label), group: group, document: document, data: field, currentCountry: availableField?.country},
                        //         type: field?.field?.fieldType === "group" ? "fieldGroupNode" : "fieldNode",
                        //         position
                        //     });
                        //     edgeAry.push({
                        //         id: `${document?._id}-${field?._id}`,
                        //         source: document?._id,
                        //         target: field?._id,
                        //     })
                        // })
                    });
                });
            });
        });

        await Promise.all(
            nodeAry.map(async (node) => {
                const tempNode = document.createElement("div");
                tempNode.style.position = "absolute";
                tempNode.style.visibility = "hidden";
                tempNode.innerHTML = node?.data?.label; // Assuming label contains the content

                document.body.appendChild(tempNode);

                await new Promise((resolve) => requestAnimationFrame(resolve));

                const {width: measuredWidth, height: measuredHeight} = tempNode.getBoundingClientRect();

                document.body.removeChild(tempNode);

                node.width = 250;
                node.height =
                    measuredHeight +
                    30 +
                    (measuredWidth > 250 ? (measuredWidth / 250) * 18 : 0) +
                    (node?.data?.data?.fieldList && node?.data?.data?.fieldList?.length > 0 ? 30 * node?.data?.data?.fieldList?.length : 0);
            })
        );

        await Promise.all(
            nodeAry.map((node) => {
                dagreGraph.setNode(node.id, {width: node.width, height: node.height});
            })
        );

        await edgeAry.forEach((edge) => {
            dagreGraph.setEdge(edge.source, edge.target);
        });

        await dagre.layout(dagreGraph);

        await nodeAry.forEach((node) => {
            const nodeWithPosition = dagreGraph.node(node.id);

            node.targetPosition = Position.Left;
            node.sourcePosition = Position.Right;

            node.position = {
                x: nodeWithPosition.x - node.width / 2,
                y: nodeWithPosition.y - node.height / 2,
            };
        });

        return {nodeAry, edgeAry};
    }, [availableField]);

    useEffect(() => {
        if (availableField) {
            displayCountryFlow()?.then(({nodeAry, edgeAry}) => {
                setNodes(nodeAry);
                setEdges(edgeAry);
            });
        }
    }, [availableField]);

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    return (
        <Spin tip="Loading..." spinning={flowLoading}>
            <div id={"flow-builder"} style={{width: "100vw", height: "100vh", overflow: "hidden"}}>
                <>
                    <StepModalForm {...stepProp} />
                    <DocumentGroupModalForm {...documentGroupProp} />
                    <FieldModalForm {...fieldProp} />
                    <SortStepModalForm {...sortStepProp} />
                </>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    nodeTypes={nodeTypes}
                    defaultEdgeOptions={{type: "smoothstep"}}
                    fitView
                >
                    {availableField?.country ? (
                        <Panel className={"flow-builder-panel"} position="top-left">
                            {t("accountVerification.panel.currentlyViewing")}
                            <strong>{t(availableField?.country)}</strong>
                        </Panel>
                    ) : (
                        <Panel className={"flow-builder-panel"} position="top-left">
                            {t("accountVerification.panel.pleaseSelectCountryToView")}
                        </Panel>
                    )}
                    <Controls position={"top-left"} />
                    <Background />
                </ReactFlow>
            </div>
            <DuplicateFlowModalForm isOpen={isDuplicateOpen} setIsOpen={setIsDuplicateOpen} currentCountry={selectedCountry} />
        </Spin>
    );
};

export default FlowBuilder;
