import React from "react";
import type {ColumnsType} from "antd/es/table";

interface DataType {
    email: string;
    isActive: string;
    host: string;
    driver: string;
    secure: string;
}

export const tableColumnTypes: ColumnsType<DataType> = [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: (a: any, b: any) => a?.name?.localeCompare(b?.name),
        render: (text) => <p>{text || "-"}</p>,
    },
];
