import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Col, Divider, Dropdown, Form, Menu, message, Modal, Row, Select, Space} from "antd";
import CommunitySetting from "./settingForm/communitySetting";
import NewsFeedSetting from "./settingForm/newsFeedSetting";
import EventsSetting from "./settingForm/eventsSetting";
import TradingAccountSetting from "./settingForm/tradingAccountSetting";
import TradeIdeaSetting from "./settingForm/tradeIdeaSetting";
import PromoMaterialSetting from "./settingForm/promoMaterialSetting";
import ReferralSetting from "./settingForm/referralSetting";
import MarketSetting from "./settingForm/marketSetting";
import NewsletterSetting from "./settingForm/newsletterSetting";
import {FormProvider, useForm} from "react-hook-form";
import IconImage from "../../../../iconList/view/layout1/component/iconImage";
import {useUpdateIconListMutation} from "../../../../../../api/graphql/iconList";
import {refetchQuery} from "../../../../../../function/refetchQuery";
import {QuestionCircleOutlined} from "@ant-design/icons";
import useAuthorize from "../../../../../../_common/authorize";
import MamPammModuleSetting from "./settingForm/mamPammSetting";
import {useRecoilValue} from "recoil";
import {rankOptionsState} from "../../../../../users/_common/state";

const SettingFormModal = (props) => {
    const {moduleData, close, languageList, isOpen, updateModuleSettingFunction, eWalletSetting, refetchEWalletSetting} = props
    const {t} = useTranslation()
    const [currentModuleForm, setCurrentModuleForm] = useState<any>()
    const [customizeSubmitButton, setCustomizeSubmitButton] = useState<any>()
    const [updatingIcon, setUpdatingIcon] = useState<any>()

    const [viewableRank, setViewableRank] = useState<any>([])
    const rankOptions = useRecoilValue<any>(rankOptionsState);
    const [rankLoading, setRankLoading] = useState<boolean>(false)

    const iconAry = ["iconLightActive", "iconLightInactive", "iconDarkActive", "iconDarkInactive"]
    const {updateIconList, loading} = useUpdateIconListMutation()
    const {systemErrorNotification} = useAuthorize()
    const form = useForm({mode: 'all'})

    const uploadOnChange = async (e, field, item) => {
        try {
            if (e?.file?.status === "done") {
                setUpdatingIcon(field)
                await updateIconList({
                    name: `nav-${item?.name?.replace(/[./]/g, '').replace(/[^a-zA-Z]/g, '-')?.toLowerCase()}`,
                    type: "module",
                    [field]: [{
                        path: e?.file?.response[0]?.link,
                        fileList: [e?.file]
                    }]
                })
                refetchQuery("getModuleSetting")
                // message.success(`${e.file?.name} file uploaded successfully`);
            } else if (e?.file?.status === "error") {
                message.error(`${e?.file?.name} file upload failed.`);
            }
        } catch (e) {
            systemErrorNotification()
        }
    };

    const resetIcon = async (item, currentIcon) => {
        const defaultIcon = currentIcon?.replace("icon", "defaultIcon")
        await updateIconList({
            name: `nav-${item?.name?.replace(" ", "-")?.toLowerCase()}`,
            type: "module",
            [currentIcon]: item?.[defaultIcon]
        })
        refetchQuery("getModuleSetting")
    }

    const updateViewableRank = async () => {
        setRankLoading(true)
        await updateModuleSettingFunction(moduleData?._id, {visibleToRank: viewableRank})
        setRankLoading(false)
    }

    useEffect(() => {
        switch (moduleData?.name) {
            case "Community":
                setCurrentModuleForm(
                    <CommunitySetting
                        languageList={languageList}
                        setCustomizeSubmitButton={setCustomizeSubmitButton}
                        close={close}
                    />
                )
                break
            case "News Feed":
                setCurrentModuleForm(
                    <NewsFeedSetting
                        setCustomizeSubmitButton={setCustomizeSubmitButton}
                        close={close}
                    />
                )
                break
            case "Events":
                setCurrentModuleForm(
                    <EventsSetting
                        setCustomizeSubmitButton={setCustomizeSubmitButton}
                        close={close}
                    />
                )
                break
            case "Deposit":
                setCurrentModuleForm(
                    <TradingAccountSetting
                        setCustomizeSubmitButton={setCustomizeSubmitButton}
                        module={"deposit"}
                        close={close}
                        eWalletSetting={eWalletSetting}
                        refetchEWalletSetting={refetchEWalletSetting}
                    />
                )
                break
            case "Withdrawal":
                setCurrentModuleForm(
                    <TradingAccountSetting
                        setCustomizeSubmitButton={setCustomizeSubmitButton}
                        module={"withdrawal"}
                        close={close}
                        eWalletSetting={eWalletSetting}
                        refetchEWalletSetting={refetchEWalletSetting}
                    />
                )
                break
            case "Self-Trade":
                setCurrentModuleForm(
                    <TradingAccountSetting
                        setCustomizeSubmitButton={setCustomizeSubmitButton}
                        module={"wallet"}
                        close={close}
                    />
                )
                break
            case "Trade Ideas":
                setCurrentModuleForm(
                    <TradeIdeaSetting
                        setCustomizeSubmitButton={setCustomizeSubmitButton}
                        close={close}
                        updateViewableRank={updateViewableRank}
                        viewableRank={viewableRank}
                    />
                )
                break
            case "Promo Materials":
                setCurrentModuleForm(
                    <PromoMaterialSetting
                        setCustomizeSubmitButton={setCustomizeSubmitButton}
                        close={close}
                    />
                )
                break
            case "Referral":
                setCurrentModuleForm(
                    <ReferralSetting
                        setCustomizeSubmitButton={setCustomizeSubmitButton}
                        close={close}
                    />
                )
                break
            case "Markets":
                setCurrentModuleForm(
                    <MarketSetting
                        languageList={languageList}
                        setCustomizeSubmitButton={setCustomizeSubmitButton}
                        close={close}
                    />
                )
                break
            // case "Quotes":
            //     setCurrentModuleForm(
            //         <QuoteSetting
            //             setCustomizeSubmitButton={setCustomizeSubmitButton}
            //             close={close}
            //         />
            //     )
            //     break
            case "Newsletter":
                setCurrentModuleForm(
                    <NewsletterSetting
                        setCustomizeSubmitButton={setCustomizeSubmitButton}
                        close={close}
                        updateViewableRank={updateViewableRank}
                        viewableRank={viewableRank}
                    />
                )
                break
            case "MAM/PAMM":
                setCurrentModuleForm(
                    <MamPammModuleSetting
                        languageList={languageList}
                        setCustomizeSubmitButton={setCustomizeSubmitButton}
                        close={close}
                    />
                )
                break
            default:
                setCurrentModuleForm(<></>)
                break
        }
    }, [moduleData, viewableRank, eWalletSetting])

    useEffect(() => {
        if (moduleData?.parent?.name === "Analysis") {
            setViewableRank(moduleData?.visibleToRank?.map(d => d._id))
        }
    }, [moduleData]);

    useEffect(() => {
        if (!isOpen) setCustomizeSubmitButton(null)
    }, [isOpen]);

    return (
        <>
            <Modal
                title={t(`Edit ${moduleData?.name} Module Setting`)}
                open={isOpen}
                onCancel={close}
                footer={null}
                width={"50%"}
                getContainer="#webModules"
                destroyOnClose
            >
                {
                    moduleData?.name && (
                        <div id={"module-setting-form"}>
                            <FormProvider {...form}>
                                <form className='form-1'>
                                    <Space style={{marginBottom: 8}}>
                                        <h3 style={{marginBottom: 0}}>{t("common.uploadIcon")}</h3>
                                        <Dropdown
                                            overlay={
                                                <Menu>
                                                    <div style={{padding: 10}}>
                                                        <p className={"margin-bottom-0"}>
                                                            - {t("common.onlyImage")}
                                                        </p>
                                                        <p className={"margin-bottom-0"}>
                                                            - {t("common.notExceed50px")}
                                                        </p>
                                                    </div>
                                                </Menu>
                                            }
                                            trigger={["click"]}
                                            arrow={{pointAtCenter: true}}
                                        >
                                            <QuestionCircleOutlined/>
                                        </Dropdown>
                                    </Space>
                                    <Row gutter={[32, 32]} style={{width: "100%", marginLeft: 0, marginBottom: 16}}>
                                        {
                                            iconAry?.map(item => {
                                                return (
                                                    <IconImage
                                                        data={moduleData}
                                                        currentFormItem={item}
                                                        permission={true}
                                                        previewEnable={false}
                                                        uploadOnChange={uploadOnChange}
                                                        resetIcon={resetIcon}
                                                        isModule={true}
                                                        loading={loading}
                                                        updatingIcon={updatingIcon}
                                                    />
                                                )
                                            })
                                        }
                                    </Row>

                                    {
                                        moduleData?.parent?.name === "Analysis" &&
                                        <>
                                            <Divider/>
                                            <div className={"extra-form"} style={{padding: 16}}>
                                                <Row justify={"space-between"} className={"margin-top-0-75"}>
                                                    <label>{t("Viewable Rank")}</label>
                                                    <Col span={14}>
                                                        <Select
                                                            value={viewableRank}
                                                            style={{width: "100%"}}
                                                            options={rankOptions?.filter(d => d?.type === "client")}
                                                            mode={"multiple"}
                                                            optionFilterProp={"label"}
                                                            allowClear
                                                            onChange={value => setViewableRank(value)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </>
                                    }
                                    {currentModuleForm}
                                    <Row style={{padding: 16}}>
                                        <Form.Item className={"margin-left-auto margin-top-0-75"}>
                                            <Space>
                                                <Button onClick={() => close()}>{t("Cancel")}</Button>
                                                {customizeSubmitButton}
                                                {
                                                    moduleData?.parent?.name === "Analysis" && !["Trade Ideas", "Newsletter"].includes(moduleData?.name) &&
                                                    <Button loading={rankLoading} onClick={async () => {
                                                        await updateViewableRank()
                                                        close()
                                                    }} type={"primary"}>{t("common.submit")}</Button>
                                                }
                                            </Space>
                                        </Form.Item>
                                    </Row>
                                </form>
                            </FormProvider>
                        </div>
                    )
                }
            </Modal>
        </>

    )
};

export default SettingFormModal