import React, {useEffect} from "react";
import {Button, Col, Modal, Row} from "antd";
import {useNavigate} from "react-router-dom";

const RewardReminder = ({open, handleClose, item, type}) => {
    const navigate = useNavigate();

    const handleRedirectToRewardSetting = () => {
        navigate(`/settings/arena/reward-setting?type=${type}&id=${item._id}`)
    }

    useEffect(() => {
        console.log(item)
    }, [item]);

    let title
    switch (type) {
        case "gifts":
            title = "Gift setting created successfully! Add this gift to a reward to assign it."
            break
        case "box":
            title = "Box setting created successfully! Add this box to a reward to assign it."
            break
        case "badges":
            title = "Badge setting created successfully! Add this badge to a reward to assign it."
            break
    }

    return (
        <Modal
            open={open}
            onCancel={handleClose}
            footer={null}
            closeIcon={false}
            maskClosable={false}
            width={300}
            className={"text-align-center"}
            centered
        >
            <h1>{title}</h1>
            <Row gutter={8} className={"margin-top-0-75"}>
                <Col span={8}>
                    <Button type={"link"} block onClick={handleClose}>Cancel</Button>
                </Col>
                <Col span={16}>
                    <Button type={"primary"} block onClick={handleRedirectToRewardSetting}>Go to Reward Setting</Button>
                </Col>
            </Row>
        </Modal>
    )
}

export default RewardReminder