import {Space} from "antd";
import React, {useEffect, useState} from "react";
import {
    ColumnTradingAccountMT5DemoTransaction,
    ColumnTradingMT5CreditDemoTransaction,
    ColumnTradingMT5VoucherDemoTransaction
} from "../../../../function/tableColumn";
import {useTranslation} from "react-i18next";
import EditTableUserPreferenceButton
    from "../../../../../../../../components/table/function/editTableUserPreferenceButton";
import TableAntDesign from "../../../../../../../../components/table/tableAntDesign";
import {useRecoilState} from "recoil";
import {tradingAccountCreditRefreshState, tradingAccountTransactionRefreshState} from "../../../../state";
import {
    getTradingAccountMt5Transaction,
    getTradingMt5CreditTransaction,
    getTradingMt5VoucherTransaction
} from "../../../../../../../../api/graphql/tradingAccountMt5";


const AccountMt5TransactionLocal = (props) => {

    const {t} = useTranslation()
    const {
        data,
        type
    } = props

    const tableName = type
    const Header = type === "Deposit/Withdrawal" ? t("tradingAccount.deposit_withdrawal_record") : ''
    const columns = type === "Deposit/Withdrawal" ? ColumnTradingAccountMT5DemoTransaction() : type === "Credit Transaction" ? ColumnTradingMT5CreditDemoTransaction() : type === "Voucher Transaction" ? ColumnTradingMT5VoucherDemoTransaction() : [];

    const [transactionData, setTransactionData] = useState([]);
    const [transactionDataCount, setTransactionDataCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [orderBy, setOrderBy] = useState<any>("")
    const [limit, setLimit] = useState<any>(10)
    const [offset, setOffset] = useState<any>(0)
    const [filter, setFilter] = useState<any>(null)
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [dateKey, setDateKey] = useState("");
    const [tradingAccountCreditRefresh, setTradingAccountCreditRefresh] = useRecoilState(tradingAccountCreditRefreshState);
    const [tradingAccountTransactionRefresh, setTradingAccountTransactionRefresh] = useRecoilState(tradingAccountTransactionRefreshState);
    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize)
        setOffset(limit * (page - 1))
    }

    const preload = async () => {
        try {
            await setLoading(true)
            if (type === "Deposit/Withdrawal") {

                const response = await getTradingAccountMt5Transaction({
                    account: data?._id,
                    status: "success",
                    ...filter
                }, orderBy, limit, offset, startDate, endDate, dateKey)

                setTransactionData(response?.getTradingAccountMt5Transaction?.data)
                setTransactionDataCount(response?.getTradingAccountMt5Transaction?.total)

            } else if (type === "Credit Transaction") {

                const response = await getTradingMt5CreditTransaction({
                    account: data?._id,
                    status: "success",
                    ...filter
                }, orderBy, limit, offset, startDate, endDate, dateKey)

                setTransactionData(response?.getTradingMt5CreditTransaction?.data)
                setTransactionDataCount(response?.getTradingMt5CreditTransaction?.total)

            } else if (type === "Voucher Transaction") {
                const response = await getTradingMt5VoucherTransaction({
                    account: data?._id,
                    status: "success",
                    ...filter
                }, orderBy, limit, offset, startDate, endDate, dateKey)

                setTransactionData(response?.getTradingMt5VoucherTransaction?.data)
                setTransactionDataCount(response?.getTradingMt5VoucherTransaction?.total)
            }

        } finally {
            setTradingAccountCreditRefresh(false)
            setTradingAccountTransactionRefresh(false)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (data) {
            preload()
        }
    }, [data, limit, offset, orderBy, startDate, endDate, dateKey, filter])

    useEffect(() => {
        if (tradingAccountCreditRefresh || tradingAccountTransactionRefresh) {
            preload()
        }
    }, [tradingAccountCreditRefresh, tradingAccountTransactionRefresh])

    return (
        <div id="accountHistory">
            <h4>{Header}</h4>
            <div className={"display-flex margin-bottom-0-75"}>
                <Space className={"margin-left-auto"}>
                    <EditTableUserPreferenceButton
                        tableName={tableName}
                        tableColumn={columns}
                        displayTableName={Header}
                    />
                </Space>
            </div>
            <TableAntDesign data={transactionData}
                            tableName={tableName}
                            columns={[...columns]}
                            size={"small"}
                            server={null}
                            loading={loading}
                            filter={setFilter}
                            startDate={setStartDate}
                            endDate={setEndDate}
                            dateKey={setDateKey}
                            order={setOrderBy}
                            pagination={{
                                pageSize: limit,
                                showSizeChanger: true,
                                position: "bottomCenter",
                                total: transactionDataCount,
                                onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                                showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                            }}
            />
        </div>
    )

}

export default AccountMt5TransactionLocal;
