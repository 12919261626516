import React, {useEffect} from "react";
import GiftSettings_1 from "./view/layout1";
import {useGetGiftSetting} from "./function/useGetQueryHooks";

const GiftSettings = () => {
    let component
    let layout = 1
    const [data, fetchGiftSetting] = useGetGiftSetting()

    useEffect(() => {
        fetchGiftSetting()
    }, []);

    switch (layout) {
        case 1:
            component = <GiftSettings_1 data={data} fetchGiftSetting={fetchGiftSetting}/>
            break
    }

    return component
}

export default GiftSettings