import React, {useEffect} from "react";
import {Col, Form, Input, Row} from "antd";
import {useTranslation} from "react-i18next";
import {Checkbox} from "antd/lib";

const MarginRatesPanelMt5 = ({initialData}) => {
    const {t} = useTranslation()
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(initialData);
    }, [initialData])

    return (
        <>
            <Row gutter={[16, 16]} style={{marginBottom: "1%", marginTop: "2%"}}>
                <Col span={20} offset={2}>
                    <p>
                        Please specify margin rates for different types of trade operations.
                    </p>
                </Col>
            </Row>

            <Form form={form}>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={10} offset={2}>
                        <Form.Item
                            label={t("Use default margin rate settings")}
                        >
                            <Checkbox disabled={true} defaultChecked={
                                initialData?.marginRateLiquidity === initialData?.marginRateLiquidityDefault &&
                                initialData?.marginRateCurrency === initialData?.marginRateCurrencyDefault
                            }/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={20} offset={2}>
                        <Form.Item
                            name={"marginRateLiquidity"}
                            label={t("Liquidity margin rate")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={20} offset={2}>
                        <Form.Item
                            name={"marginRateCurrency"}
                            label={t("Currency margin rate")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </>
    )
}

export default MarginRatesPanelMt5