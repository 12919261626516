import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {FormProvider} from "react-hook-form";
import {Col, Form, Row} from "antd";
import {horizontalFormLayout} from "../../../../../../components/form/formWrapper";
import SwitchFormItem from "../../../../../../components/form/switchFormItem";
import RadioButtonGroupFormItem from "../../../../../../components/form/radioButtonGroupFormItem";
import InputNumberFormItem from "../../../../../../components/form/inputNumberFormItem";

const PspCurencyForm = (props) => {
  const { form, item, labelCol, formMode } = props;
  const { t } = useTranslation();

  const [errMsg, setErrMsg] = useState("");
  const [conversionRate, setConversionRate] = useState("");

  const [initialValues] = useState(() => ({
    // enabled: item?.enabled ? true : false,
    // depositEnabled: item?.depositEnabled ? true : false,
    // withdrawalEnabled: item?.withdrawalEnabled ? true : false,
    // name: item?.name || "",
    // displayName: item?.displayName || "",
    // processingTime: item?.processingTime || "",
    // logo: item?.logo[0]?.link || "",
    // logoSmall: item?.logoSmall[0]?.link || "",
  }));

  const onConversionRateChg = (value) => {
    setConversionRate(value);
    if (value === "floating") {
      form.setValue(`${currencyName}-fixedRate`, 0);
    }
  };

  useEffect(() => {
    setConversionRate(item?.conversionRate);
  }, [item]);

  let currencyName = item?.currency?.replace(/\./g, "_");

  return (
    <div>
      <FormProvider {...form}>
        <Form className="pspCurrency-form-1" labelWrap={true}>
          {errMsg && <p style={{ color: "red" }}>{errMsg}</p>}
          <Row gutter={16}>
            <Col className="gutter-row" xs={24} sm={12} md={12}>
              <SwitchFormItem
                name={`${currencyName}-depositEnabled`}
                label={t("Deposit Enabled")}
                labelCol={labelCol}
                labelAlign="left"
                size="small"
                disabled={formMode === "view"}
              />
            </Col>
            <Col className="gutter-row" xs={24} sm={12} md={12}>
              <SwitchFormItem
                name={`${currencyName}-withdrawalEnabled`}
                label={t("Withdrawal Enabled")}
                labelCol={labelCol}
                labelAlign="left"
                size="small"
                disabled={formMode === "view"}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" xs={24} sm={12} md={12}>
              <RadioButtonGroupFormItem
                name={`${currencyName}-conversionRate`}
                label={t("Conversion Rate")}
                options={[
                  { label: t("Floating"), value: "floating" },
                  { label: t("Fixed"), value: "fixed" },
                ]}
                layout={horizontalFormLayout}
                labelCol={labelCol}
                wrapperCol={labelCol}
                labelAlign="left"
                disabled={formMode === "view"}
                onChange={onConversionRateChg}
                defaultValue={conversionRate}
                // buttonStyle="solid"
              />
            </Col>
            <Col className="gutter-row" xs={24} sm={12} md={12}>
              {conversionRate === "fixed" ? (
                <InputNumberFormItem
                  name={`${currencyName}-fixedRate`}
                  label={"Fixed Rate"}
                  placeholder={"Fixed Rate"}
                  layout={horizontalFormLayout}
                  labelCol={labelCol}
                  labelAlign="left"
                  disabled={formMode === "view"}
                  min={0}
                />
              ) : null}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" xs={24} sm={12} md={12}>
              <InputNumberFormItem
                name={`${currencyName}-settlement`}
                label={"Settlement %"}
                placeholder={"Settlement %"}
                layout={horizontalFormLayout}
                labelCol={labelCol}
                labelAlign="left"
                disabled={formMode === "view"}
                min={0}
              />
            </Col>
            <Col className="gutter-row" xs={24} sm={12} md={12}>
              <InputNumberFormItem
                name={`${currencyName}-settlementFixed`}
                label={"Settlement (Fixed)"}
                placeholder={"Settlement (Fixed)"}
                layout={horizontalFormLayout}
                labelCol={labelCol}
                labelAlign="left"
                disabled={formMode === "view"}
                min={0}
              />
            </Col>
            <Col className="gutter-row" xs={24} sm={12} md={12}>
              <InputNumberFormItem
                name={`${currencyName}-digits`}
                label={"Digits"}
                placeholder={"Digits"}
                layout={horizontalFormLayout}
                labelCol={labelCol}
                labelAlign="left"
                disabled={formMode === "view"}
                min={0}
              />
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </div>
  );
};

export default PspCurencyForm;
