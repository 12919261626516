import React, {useEffect, useState} from "react";
import {Button, Space, Tabs} from "antd";
import ProductTable_1 from "./component/productTable";
import ExportTableButton from "../../../../../components/table/function/exportTable";
import EditTableUserPreferenceButton from "../../../../../components/table/function/editTableUserPreferenceButton";
import {useTranslation} from "react-i18next";
import {getMamPammProvider} from "../../../../../api/graphql/mamPammProvider";

const Product_1 = (props) => {
    const {serverData, permission} = props;
    const {t} = useTranslation();
    const [isOpenProductForm, setIsOpenProductForm] = useState(false);
    const [mode, setMode] = useState("");
    const [selectedItem, setSelectedItem] = useState({});

    const [exportData, setExportData] = useState(null);
    const [exportColumn, setExportColumn] = useState(null);
    const [currentServer, setCurrentServer] = useState<any>();
    const [exportLoading, setExportLoading] = useState(false);
    const [mamPammProviderOptions, setMamPammProviderOptions] = useState([]);
    const tableName = "MAM/PAMM Product";

    const getMamPammProviderOptions = async () => {
        try {
            if (currentServer) {
                const response = await getMamPammProvider({filter: {serverId: currentServer?._id, isActive: [true]}});
                if (response?.getMamPammProvider?.data) {
                    let options = response?.getMamPammProvider?.data?.map((v) => ({label: v?.name, value: v?._id}));
                    setMamPammProviderOptions(options);
                }
            }
        } catch (error) {
        } finally {
        }
    };

    const openAddModal = () => {
        setMode("Add");
        setIsOpenProductForm(true);
    };

    const openEditModal = (record) => {
        setMode("Edit");
        setIsOpenProductForm(true);
        setSelectedItem(record);
    };

    const closeModal = () => {
        setIsOpenProductForm(false);
        setSelectedItem({});
    };

    useEffect(() => {
        if (serverData?.length > 0) {
            setCurrentServer(serverData?.[0]);
        }
    }, [serverData]);

    useEffect(() => {
        getMamPammProviderOptions();
    }, [currentServer]);

    return (
        <div id="mamPammProduct">
            <h1>{t(tableName)}</h1>

            {serverData && (
                <Tabs
                    defaultActiveKey="1"
                    size={"small"}
                    items={serverData?.map((item, i) => {
                        return {
                            label: t(item?.name),
                            key: i,
                            children: (
                                <>
                                    {item?.mode?.map((modeName) => (
                                        <ProductTable_1
                                            server={item}
                                            isOpen={isOpenProductForm}
                                            close={closeModal}
                                            item={selectedItem}
                                            mode={mode}
                                            openEdit={openEditModal}
                                            selectedItem={selectedItem}
                                            setExportData={setExportData}
                                            setExportColumn={setExportColumn}
                                            tableName={tableName}
                                            currentServer={currentServer}
                                            setExportLoading={setExportLoading}
                                            permission={permission}
                                            productMode={modeName}
                                            mamPammProviderOptions={mamPammProviderOptions}
                                        />
                                    ))}
                                </>
                            ),
                        };
                    })}
                    tabBarExtraContent={
                        <Space>
                            <EditTableUserPreferenceButton tableName={tableName} server={currentServer} tableColumn={exportColumn} />
                            <ExportTableButton
                                loading={exportLoading}
                                tableData={exportData}
                                tableColumn={exportColumn}
                                tableName={tableName}
                                server={currentServer}
                            />
                            {permission?.add?.add && (
                                <Button type={"primary"} onClick={() => openAddModal()}>
                                    {t("Add Product")}
                                </Button>
                            )}
                        </Space>
                    }
                    onTabClick={(key) => {
                        setCurrentServer(serverData?.[key]);
                    }}
                />
            )}
        </div>
    );
};

export default Product_1;
