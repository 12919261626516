import { gql, useMutation, useQuery } from "@apollo/client";

const GET_VOLUME_PROFILE_COUNT = gql`
  query getVolumeProfileCount($filter: VolumeProfileInput) {
    getVolumeProfileCount(filter: $filter)
  }
`;

const getVolumeProfileCount = ({ filter }) => {
  try {
    const { loading, error, data } = useQuery(GET_VOLUME_PROFILE_COUNT, {
      variables: {
        filter: {
          volumeProfileId: filter?.volumeProfileId || null,
          name: filter?.name || null,
          serverName: filter?.serverName || null,
        },
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_VOLUME_PROFILE_BY_ID = gql`
  query getVolumeProfileByID($id: String!) {
    getVolumeProfileByID(id: $id) {
      ... on VolumeProfile {
        id
        volumeProfileId
        name
        description
        minVolume
        stepVolume
        maxVolume
        minStake
        maxStake
        maxExposure
        utcLastUpdateTimestamp
        server {
          name
        }
        createdBy {
          username
        }
        updatedBy {
          username
        }
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getVolumeProfileById = (id) => {
  try {
    const { loading, error, data } = useQuery(GET_VOLUME_PROFILE_BY_ID, {
      variables: {
        id: id,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_VOLUME_PROFILE = gql`
  query getVolumeProfile(
    $filter: VolumeProfileInput
    $limit: Int
    $offset: Int
    $orderBy: String
  ) {
    getVolumeProfile(
      filter: $filter
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      ... on VolumeProfileArray {
        data {
          id
          volumeProfileId
          name
          description
          minVolume
          stepVolume
          maxVolume
          minStake
          maxStake
          maxExposure
          utcLastUpdateTimestamp
          server {
            name
          }
          createdBy {
            username
          }
          updatedBy {
            username
          }
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getVolumeProfile = ({ filter }, limit?, offset?, orderBy?) => {
  try {
    const { loading, error, data } = useQuery(GET_VOLUME_PROFILE, {
      variables: {
        filter: {
          volumeProfileId: filter?.volumeProfileId || null,
          name: filter?.name || null,
          serverName: filter?.serverName || null,
        },
        limit: limit || null,
        offset: offset || null,
        orderBy: orderBy || null,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const REFRESH_VOLUME_PROFILE = gql`
  mutation refreshVolumeProfile($server: String!) {
    refreshVolumeProfile(server: $server) {
      ... on VolumeProfileArray {
        data {
          id
          volumeProfileId
          name
          description
          minVolume
          stepVolume
          maxVolume
          minStake
          maxStake
          maxExposure
          utcLastUpdateTimestamp
          server {
            name
          }
          createdBy {
            username
          }
          updatedBy {
            username
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

const useRefreshVolumeProfileMutation = (): {
  refreshVolumeProfile: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const [useRefreshVolumeProfileMutation, { data, loading, error }] =
      useMutation(REFRESH_VOLUME_PROFILE);

    const refreshVolumeProfile = (input) => {
      return useRefreshVolumeProfileMutation({
        variables: {
          input: {
            server: input.server || null,
          },
        },
      });
    };

    return { refreshVolumeProfile, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return {
      refreshVolumeProfile: null,
      data: null,
      loading: false,
      error: err,
    };
  }
};

export {
  getVolumeProfileCount,
  getVolumeProfileById,
  getVolumeProfile,
  useRefreshVolumeProfileMutation,
};
