import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {Card, Empty, Pagination, Row, Timeline} from "antd";
import {useGetLogger} from "../../../../function/useQueryHooks";
import {formatDateTimeTimezone} from "../../../../../../../../function/_common";

const OperationHistory = (props) => {
    const {data, permissionUser} = props
    const {t} = useTranslation()

    const [itemLog, setItemLog] = useState<any[]>([])
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const [log, loading, fetchLog] = useGetLogger()

    useEffect(() => {
        fetchLog({filter: {logCategory: "userLastActivities", requestedId: data?._id}},
            {targetUserId: [data?._id]},
            "id DESC",
            pageSize,
            (currentPage - 1) * pageSize)
    }, [data?._id, pageSize, currentPage]);

    const handlePaginationChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const formatFollowUpItems = async (logs) => {
        return await logs?.map((d, i) => {
            return {
                children: (
                    <Card size={"small"} key={d?._id}>
                        <div>
                            <h4 className={"margin-bottom-0"}>{d?.functionName}</h4>
                            <p><small>{d?.content}</small></p>
                        </div>
                        <Row justify={d?.requestedUsername !== data?.username ? "space-between" : "end"}>
                            {
                                d?.requestedUsername !== data?.username &&
                                <p className={"margin-bottom-0"}><small><strong>{t("task.requested_by")} {d?.requestedUsername}</strong></small></p>
                            }
                            <p className={"margin-bottom-0"}>{formatDateTimeTimezone(d?.createdAt)}</p>
                        </Row>
                    </Card>
                )
            }
        })
    }

    useEffect(() => {
        formatFollowUpItems(log?.data)?.then(data => setItemLog(data));
    }, [log?.data, currentPage, pageSize]);

    return (
        <div id={"operation-history"}>
            <Timeline items={itemLog} style={{marginTop: "2rem"}}/>
            {
                log?.total > 0 ?
                    <div className={"text-align-right"}>
                        <Pagination
                            className={"display-inline-block"}
                            size={"small"}
                            current={currentPage}
                            pageSize={pageSize}
                            total={log?.total}
                            showTotal={(total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>}
                            onChange={handlePaginationChange}
                        />
                    </div>
                    :
                    <Empty description={t("common.noData")}/>
            }
        </div>
    )
}

export default OperationHistory