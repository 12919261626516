import {gql, useMutation, useQuery} from "@apollo/client";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {useTranslation} from "react-i18next";
import {ApolloClientQuery} from "./_apolloClient";

const GET_CURRENCY_SETTING_COUNT = gql`
  query getCurrencySettingCount($filter: CurrencySettingSearchInput) {
    getCurrencySettingCount(filter: $filter)
  }
`;

const getCurrencySettingCount = (filter) => {
    try{
        const { loading, error, data } = useQuery(GET_CURRENCY_SETTING_COUNT, {
            variables: {
                filter: filter || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return { loading, error, data }
    }
    catch(err: any){
        console.error(err);
        return { data: null, loading: false, error: err };
    }
}

const GET_CURRENCY_SETTING = gql`
  query getCurrencySetting($filter: CurrencySettingSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
    getCurrencySetting(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy){
      ... on CurrencySettingArray {
        data {
          _id
          id
          label
          name
          symbol
          code
          digits
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getCurrencySetting = (filter?, limit?, offset?, orderBy?) => {
    try{
        const { loading, error, data } = useQuery(GET_CURRENCY_SETTING, {
            variables: {
                filter: filter || null,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return { loading, error, data }
    }
    catch(err: any){
        console.error(err);
        return { data: null, loading: false, error: err };
    }
}

const getCurrencySettingAsync = async (filter?, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_CURRENCY_SETTING,
            {
                filter: filter || null,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return { data: null, loading: false, error: err };
    }
};

const UPDATE_CURRENCY_SETTING = gql`
mutation updateCurrencySetting($input: CurrencySettingInput) {
    updateCurrencySetting(input: $input){
      ... on CurrencySetting {
        _id
        id
        label
        name
        symbol
        code
        digits
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useUpdateCurrencySettingMutation = (): { updateCurrencySetting: any, data: any, loading: boolean, error: any } => {
    try{
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [updateCurrencySettingMutation, { data, loading, error }] = useMutation(UPDATE_CURRENCY_SETTING);

        const updateCurrencySetting = (input) => {
            return updateCurrencySettingMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {updateCurrencySetting}}) {
                    const typename = updateCurrencySetting?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateCurrencySetting?.errKey),
                            key: "updateCurrencySetting",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateCurrencySetting?.msg),
                            key: "updateCurrencySetting",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Currency Setting Successfully"),
                            key: "updateCurrencySetting",
                        });
                    }
                },
            });
        };

        return { updateCurrencySetting, data, loading, error }
    }
    catch(err: any){
        console.error(err);
        return { updateCurrencySetting: null, data: null, loading: false, error: err };
    }
}

export {
    getCurrencySettingCount,
    getCurrencySetting,
    getCurrencySettingAsync,
    useUpdateCurrencySettingMutation
}