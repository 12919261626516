import React from "react";
import noob from "../../../assets/avatar/noob-level.png"
import stillNoob from "../../../assets/avatar/still-noob-level.png"
import rookie from "../../../assets/avatar/rookie.png"
import elite from "../../../assets/avatar/elite.png"
import master from "../../../assets/avatar/master.png"
import commander from "../../../assets/avatar/commander.png"
import legendary from "../../../assets/avatar/legendary.png"
import gold from "../../../assets/gold-medal.png"
import silver from "../../../assets/silver-medal.png"
import bronze from "../../../assets/bronze-medal.png"
import normal from "../../../assets/normal-medal.png"
import rankingBG2 from "../../../assets/ranking-bg2.png"
import {Col, Image, List, Row} from "antd";

const RankingComponent = () => {
    const LEGEND_LIST = [
        {
            level: 1,
            condition: 50,
            field: "total",
            width: 300,
            height: 163,
            name: "Noob",
            src: noob
        },
        {
            level: 2,
            condition: 1000,
            field: "total",
            width: 241,
            height: 300,
            name: "Still Noob",
            src: stillNoob
        },
        {
            level: 3,
            condition: 10000,
            field: "total",
            width: 350,
            height: 344,
            name: "Rookie",
            src: rookie
        },
        {
            level: 4,
            condition: 20000,
            field: "total",
            width: 257,
            height: 350,
            name: "Elite",
            src: elite
        },
        {
            level: 5,
            condition: 30000,
            field: "total",
            width: 326,
            height: 350,
            name: "Master",
            src: master
        },
        {
            level: 6,
            condition: 40000,
            field: "total",
            width: 380,
            height: 386,
            name: "Commander",
            src: commander
        },
        {
            level: 6,
            condition: 50000,
            field: "total",
            width: 349,
            height: 420,
            name: "Legendary",
            src: legendary
        }
    ]
    const FAKE_DATA = [
        {
            username: "DUMMY 7",
            total: 50000,
            today: 50000,
            week: 50000,
            month: 50000,
        },
        {
            username: "DUMMY 1",
            total: 40000,
            today: 40000,
            week: 40000,
            month: 40000,
        },
        {
            username: "DUMMY 2",
            total: 30000,
            today: 30000,
            week: 30000,
            month: 30000,
        },
        {
            username: "DUMMY 3",
            total: 20000,
            today: 20000,
            week: 20000,
            month: 20000,
        },
        {
            username: "DUMMY 4",
            total: 10000,
            today: 10000,
            week: 10000,
            month: 10000,
        },
        {
            username: "DUMMY 5",
            total: 1000,
            today: 1000,
            week: 1000,
            month: 1000,
        },
        {
            username: "DUMMY 6",
            total: 50,
            today: 50,
            week: 50,
            month: 50,
        },
    ]

    const getLegendSrc = (user) => {
        const sortedLegendList = LEGEND_LIST.slice().sort((a, b) => b.condition - a.condition);

        return sortedLegendList.find(legend => {
            const field = legend.field;
            return user[field] >= legend.condition;
        }) || {
            level: 1,
            condition: 50,
            field: "total",
            width: 300,
            height: 163,
            name: "Noob",
            src: noob
        }
    };

    return (
        <div className={"ranking-component"}>
            <List
                dataSource={FAKE_DATA}
                renderItem={(item, i) => {
                    const legendData = getLegendSrc(item)
                    let width, height, size = 60
                    if (legendData.width > legendData.height) {
                        width = size
                        height = size / legendData.width * legendData.height
                    } else {
                        height = size
                        width = legendData.width / legendData.height * size
                    }

                    return (
                        (
                            <List.Item key={item.username}>
                                <Row gutter={8} className={"w-100"} align={"middle"}>
                                    <Col flex={"60px"} className={"position-relative"}>
                                        <p className={"position-absolute text-align-center"}
                                           style={{width: 60, top: "28%", zIndex: 2, color: "#FFFFFF", fontWeight: 600}}>{i + 1}</p>
                                        <Image
                                            width={60}
                                            preview={false}
                                            src={i === 0 ? gold : i === 1 ? silver : i === 2 ? bronze : normal}
                                        />
                                    </Col>
                                    <Col flex={"80px"}>
                                        <div className={"legend-image-container"} style={{
                                            background: `center / contain no-repeat url(${rankingBG2})`,
                                            backgroundSize: "contain",
                                            height: 80,
                                            width: 80,
                                            display: "flex"
                                        }}>
                                            <Image
                                                width={width}
                                                height={height}
                                                preview={false}
                                                src={legendData.src}
                                            />
                                        </div>
                                    </Col>
                                    <Col flex={1}>
                                        <p className={"display-username"}><strong>{item?.username}</strong> ({legendData.name})</p>
                                        <Row className={"list-data"}>
                                            <Col span={6}>
                                                <p>Today</p>
                                                <p>${item.today}</p>
                                            </Col>
                                              <Col span={6}>
                                                <p>Week</p>
                                                <p>${item.week}</p>
                                            </Col>
                                              <Col span={6}>
                                                <p>Month</p>
                                                <p>${item.month}</p>
                                            </Col>
                                              <Col span={6}>
                                                <p>Total</p>
                                                <p>${item.total}</p>
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>
                            </List.Item>
                        )
                    )
                }}
            />
        </div>
    )
}

export default RankingComponent