import {useTranslation} from "react-i18next"
import React, {useEffect, useState} from "react"
import {useSetRecoilState} from "recoil"
import {Button, DatePicker, Popconfirm, Space} from "antd"
import {RedoOutlined} from "@ant-design/icons"
import {ColumnsType} from "antd/es/table"
import dayjs, {Dayjs} from "dayjs"
import TableReportAntDesign from "../../../../../../../components/table/tableReportAntDesign"
import {getReportingDetails, getReportingFieldsByReportId, getReportingSummary, getReportingTypes, updateClosedPositionReportCommissionStatus} from "../../../../../../../api/graphql/reporting"
import EditTableUserPreferenceButton from "../../../../../../../components/table/function/editTableUserPreferenceButton"
import {setupReportColumns} from "../../../../../../reports/_general/function/common"
import {checkIsMasterAdmin} from "../../../../../../../api/graphql/user"
import {getCurrencySetting} from "../../../../../../../api/graphql/currencySetting"
import {voidClosedPosition} from "../../../../../../../api/graphql/voidReopenClosedPosition"
import ReopenPositionModalCT from "./reopenPositionModalCT"
import useAuthorize from "../../../../../../../_common/authorize"
import {notificationMessage} from "../../../../../../../../recoil_state"
import AdjustCommissionSwapModalCT from "./adjustCommissionSwapModalCT"
import {BRAND_NAME} from "../../../../../../../../config/brandVariable"

const AccountReportTable = (props) => {
    const {traderId, accountId, accDetails, selectedReportID, displayName, tradingAccountPermission, totalCount, setTotalCount, refreshQuery} = props

    const {RangePicker} = DatePicker
    const {isMasterAdmin} = checkIsMasterAdmin(localStorage.getItem("g_userId") || localStorage.getItem("userId"))

    const [page, setPage] = useState(1)
    const [rows, setRows] = useState(20)
    const [orderBy, setOrderBy] = useState<any>(null)
    const [filter, setFilter] = useState([])
    const [reportData, setReportData] = useState([])
    const [reportTotalRowData, setReportTotalRowData] = useState({})
    const [loading, setLoading] = useState(false)
    const [summary, setSummary] = useState<any>({})
    const [selectedDates, setSelectedDates] = useState<any>([])
    const [reportFields, setReportFields] = useState([])
    const [reportingAllTypes, setReportingTypes] = useState([])
    const [displayMode, setDisplayMode] = useState<any>("SUMUP")
    const [reportViewMode, setReportViewMode] = useState<any>("table")
    const [columns, setColumns] = useState<ColumnsType<any>>([])
    const [currencySetting, setCurrencySetting] = useState<ColumnsType<any>>([])

    const [isOpenReopenPosition, setIsOpenReopenPosition] = useState(false)
    const [reopenPositionRecord, setReopenPositionRecord] = useState(null)
    const [reopenPositionStatus, setReopenPositionStatus] = useState("")

    const [isOpenAdjustCommissionSwap, setIsOpenAdjustCommissionSwap] = useState(false)
    const [adjustCommissionSwapnRecord, setAdjustCommissionSwapnRecord] = useState(null)

    const {baseErrorChecking, systemErrorNotification} = useAuthorize()
    const setNotificationMessage = useSetRecoilState(notificationMessage)

    const {t} = useTranslation()
    const {data} = getCurrencySetting()
    const onChangePageHandler = (page, pageSize) => {
        setPage(page)
        setRows(pageSize)
    }

    const rangePresets: {
        label: string
        value: [Dayjs, Dayjs]
    }[] = [
        {label: t("date.today"), value: [dayjs().add(0, "d"), dayjs()]},
        {label: t("date.yesterday"), value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")]},
        {label: t("date.last7Days"), value: [dayjs().add(-7, "d"), dayjs()]},
        {label: t("date.last14Days"), value: [dayjs().add(-14, "d"), dayjs()]},
        {label: t("date.last30Days"), value: [dayjs().add(-30, "d"), dayjs()]},
        {
            label: t("date.thisWeek"),
            value: [dayjs().startOf("week"), dayjs()],
        },
        {
            label: t("date.lastWeek"),
            value: [dayjs().subtract(1, "week").startOf("week"), dayjs().subtract(1, "week").endOf("week")],
        },
        {
            label: t("date.thisMonth"),
            value: [dayjs().startOf("month"), dayjs()],
        },
        {
            label: t("date.lastMonth"),
            value: [dayjs().subtract(1, "month").startOf("month"), dayjs().subtract(1, "month").endOf("month")],
        },
        {
            label: t("date.thisQuarter"),
            value: [dayjs().startOf("quarter"), dayjs()],
        },
        {
            label: t("date.lastQuarter"),
            value: [dayjs().subtract(1, "quarter").startOf("quarter"), dayjs().subtract(1, "quarter").endOf("quarter")],
        },
        {
            label: t("date.thisYear"),
            value: [dayjs().startOf("year"), dayjs()],
        },
        {
            label: t("date.lastYear"),
            value: [dayjs().subtract(1, "year").startOf("year"), dayjs().subtract(1, "year").endOf("year")],
        },

        {
            label: t("date.allTime"),
            value: [dayjs().year(1970).startOf("year"), dayjs()],
        },
    ]

    const getDatesValueFromRangePreset = (rangeValue) => {
        let dates: any
        let label: any
        switch (rangeValue) {
            case "TODAY":
            case "Today":
                label = t("date.today")
                break
            case "YESTERDAY":
            case "Yesterday":
                label = t("date.yesterday")
                break
            case "LAST7DAYS":
            case "Last 7 Days":
                label = t("date.last7Days")
                break
            case "LAST14DAYS":
            case "Last 14 Days":
                label = t("date.last14Days")
                break
            case "LAST30DAYS":
            case "Last 30 Days":
                label = t("date.last30Days")
                break
            case "THISMONTH":
            case "This Month":
                label = t("date.thisMonth")
                break
            case "LASTMONTH":
            case "Last Month":
                label = t("date.lastMonth")
                break
            case "ALLTIME":
            case "All Time":
                label = t("common.all")
                break
            default:
                label = "date.lastMonth"
                break
        }
        dates = rangePresets?.find((v: any) => v?.label === label)?.value
        return dates
    }

    const getReportDetails = async () => {
        try {
            setLoading(true)
            // let amendedFilters: any = [
            //     {
            //         fieldName: accDetails?.platform === "mt5" ? "accountNo" : "traderId",
            //         operator: "E",
            //         value: [traderId],
            //     },
            // ];
            let amendedFilters: any = [
                {
                    fieldName: "accountNo",
                    operator: "E",
                    value: [accountId],
                },
            ]
            if (orderBy !== null) {
                amendedFilters = [...amendedFilters, orderBy]
            }
            if (filter?.length > 0) {
                amendedFilters = [...amendedFilters, ...filter]
            }

            let data = {
                filters: amendedFilters?.length > 0 ? amendedFilters : null,
            }

            let fromDate = selectedDates?.[0]?.format("YYYY-MM-DD")
            let toDate = selectedDates?.[1]?.format("YYYY-MM-DD")

            const response: any = await getReportingDetails(selectedReportID?.toString(), data, fromDate, toDate, page, rows, null, accDetails?.mode, null, true, null, false, false, true)

            const reportSummary = await getReportingSummary(selectedReportID?.toString(), accDetails?.mode)

            if (reportSummary?.getReportingSummary?.__typename === "ReportingData") {
                const reportInfo = reportSummary?.getReportingSummary?.data?.payload
                setSummary(reportInfo)
                if (selectedDates?.length === 0) {
                    const dates = getDatesValueFromRangePreset("ALLTIME")
                    setSelectedDates(dates)
                }

                if (reportInfo?.orderByColumn && reportInfo?.orderByDirection) {
                    setOrderBy({
                        fieldName: reportInfo?.orderByColumn,
                        operator: reportInfo?.orderByDirection,
                        value: null,
                    })
                }
            }

            if (response?.getReportingDetails?.__typename === "ReportingDataAry") {
                setReportData(response?.getReportingDetails?.res?.payload?.payload)
                setTotalCount(response?.getReportingDetails?.res?.totalCount)
                let reportTotalRowData: any = {}
                if (response?.getReportingDetails?.res?.payload?.total) {
                    reportTotalRowData.total = response?.getReportingDetails?.res?.payload?.total
                }
                if (response?.getReportingDetails?.res?.payload?.pending) {
                    reportTotalRowData.pending = response?.getReportingDetails?.res?.payload?.pending
                }
                setReportTotalRowData(reportTotalRowData)
            } else {
                setReportData([])
                setTotalCount(0)
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const onRangePickerChange: any = async (dates: any, dateStrings: any[]) => {
        try {
            if (dates) {
                await setSelectedDates(dates)
            } else {
                await setSelectedDates([])
                console.log("Clear")
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getReportFields = async () => {
        try {
            const response = await getReportingFieldsByReportId(selectedReportID)
            if (response?.getReportingFieldsByReportId?.__typename === "ReportingDataAry") {
                setReportFields(response?.getReportingFieldsByReportId?.data)
            }
        } catch (err) {
            console.log(err)
        } finally {
        }
    }

    const getAllTypes = async () => {
        const response = await getReportingTypes()
        const list = response?.getReportingTypes?.data
        if (list) {
            setReportingTypes(list)
        }
    }

    const resetFilters = () => {
        setFilter([])
        setSelectedDates([])
    }

    useEffect(() => {
        setCurrencySetting(data?.getCurrencySetting?.data)
    }, [data?.getCurrencySetting?.data])

    useEffect(() => {
        if (selectedReportID) {
            getReportFields()
            getAllTypes()
        }
    }, [selectedReportID])

    useEffect(() => {
        setupReportColumns(reportFields, reportingAllTypes, displayMode, reportViewMode, setColumns, summary, isMasterAdmin, [], currencySetting, filter)
    }, [reportFields, reportingAllTypes, displayMode, reportViewMode, summary, isMasterAdmin, currencySetting, filter])

    useEffect(() => {
        if (accountId && accDetails.mode) {
            getReportDetails()
        }
    }, [accountId, page, rows, accDetails, selectedDates, orderBy, filter])

    // useEffect(() => {
    //     if (accountId && accDetails.mode) {
    //         const thisMonthDates = getDatesValueFromRangePreset("THISMONTH");
    //         setSelectedDates(thisMonthDates);
    //     }
    // }, [accountId, accDetails]);

    const isClearFilterVisible = Object.keys(filter)?.length > 0 || selectedDates?.length > 0

    const voidClosedPositionHandle = async (record) => {
        try {
            const response = await voidClosedPosition(
                accDetails?._id,
                record?.positionId?.displayValue?.toString(),
                {
                    fieldName: "commissionStatus",
                    rowId: record?._id?.displayValue,
                    value: "VOID",
                },
                false
            )

            if (response?.voidClosedPosition?.__typename === "BaseError") {
                baseErrorChecking(response?.voidClosedPosition)
            } else {
                getReportDetails()
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Void Closed Position Successfully"),
                    key: "voidClosedPosition",
                })
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    const updateCommissionStatusToVoidOpenPosition = async (record) => {
        try {
            const response = await updateClosedPositionReportCommissionStatus({
                fieldName: "commissionStatus",
                rowId: record?._id?.displayValue,
                value: "VOID_OPEN_POSITION",
            })

            if (response?.updateClosedPositionReportCommissionStatus?.__typename === "BaseError") {
                baseErrorChecking(response?.updateClosedPositionReportCommissionStatus)
            } else {
                // refreshQuery()
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Update Commission Status Successfully"),
                    key: "updateClosedPositionReportCommissionStatus",
                })
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    const hardcodedExcoMigratedAccount = [
        1015255, 1014927, 1016394, 1015570, 1016371, 1016303, 1015217, 1015749, 1016486, 1016033, 1016563, 1016280, 1016488, 1016426, 1015032, 1015523, 1016096, 1016538, 1016417, 1016556, 1016406, 1016421, 1016479, 1016424, 1015732, 1016259, 1015831,
        1016571, 1015515, 1016220, 1015832, 1015209, 1015170, 1015714, 1015699, 1016409, 1015761, 1016179, 1016585, 1016536, 1016580, 1016549, 1016532, 1016529, 1016570, 1016518, 1015406, 1016183, 1016309, 1015550, 1015186, 1015444, 1016565, 1016614,
        1016617, 1016513, 1016380, 1015301, 1015619, 1015500, 1016597, 1016318, 1016494, 1016224, 1016357, 1016612, 1016180,
    ]

    const hardCodedStartDate = dayjs.utc("2024-11-13T00:00:00").startOf("day").unix() * 1000
    const hardCodedEndDate = dayjs.utc("2024-11-15T23:59:59").endOf("day").unix() * 1000

    return (
        <div id="accountDeal">
            <div className={"display-flex margin-bottom-0-75"}>
                <Space className={"margin-left-auto"}>
                    <RedoOutlined className="text-important" onClick={getReportDetails} spin={loading} />
                    {isClearFilterVisible ? (
                        <Button size={"small"} onClick={() => resetFilters()}>
                            {t("common.clearFilter")}
                        </Button>
                    ) : null}
                    <RangePicker presets={rangePresets} placeholder={[t("common.startDate"), t("common.endDate")]} onChange={onRangePickerChange} defaultValue={selectedDates} value={selectedDates} />
                    <Button type={"link"} loading={loading} onClick={getReportDetails}>
                        {t("common.search")}
                    </Button>

                    <EditTableUserPreferenceButton tableName={"tradingAcc-" + selectedReportID} tableColumn={columns} displayTableName={displayName} />
                </Space>
            </div>

            <TableReportAntDesign
                tableName={"tradingAcc-" + selectedReportID}
                size="small"
                loading={loading}
                columns={
                    selectedReportID === "64d0a107847e88a7d5978e39"
                        ? [
                              accDetails?.platform === "cTrader" &&
                                  tradingAccountPermission?.edit?.voidClosedPosition && {
                                      title: t("common.action"),
                                      key: "action",
                                      render: (_, record) => {
                                          const closeTime = record?.closeTimestamp?.displayValue
                                          const accountNo = record?.accountNo?.displayValue
                                          const commissionStatus = record?.commissionStatus?.displayValue
                                          const isUpdateCommissionStatusBtnShown =
                                              BRAND_NAME === "excotrades" && hardcodedExcoMigratedAccount?.includes(accountNo) && !commissionStatus && closeTime >= hardCodedStartDate && closeTime <= hardCodedEndDate
                                          return (
                                              <Space className={"void-action"}>
                                                  {isMasterAdmin && isUpdateCommissionStatusBtnShown && (
                                                      <Button size={"small"} onClick={() => updateCommissionStatusToVoidOpenPosition(record)}>
                                                          Update commissionStatus to VOID OPEN POSITION
                                                      </Button>
                                                  )}
                                                  {record?.commissionStatus?.displayValue === "VOID" ? (
                                                      <Button
                                                          size={"small"}
                                                          onClick={() => {
                                                              setIsOpenReopenPosition(true)
                                                              setReopenPositionRecord(record)
                                                              setReopenPositionStatus("reopen")
                                                          }}
                                                      >
                                                          Reopen
                                                      </Button>
                                                  ) : record?.commissionStatus?.displayValue === "VOID_AND_REOPENED" ? (
                                                      <></>
                                                  ) : record?.commissionStatus?.displayValue === "VOID_OPEN_POSITION" ? (
                                                      <>
                                                          {tradingAccountPermission?.edit?.adjustCommissionSwap && (
                                                              <Button
                                                                  size={"small"}
                                                                  onClick={() => {
                                                                      setIsOpenAdjustCommissionSwap(true)
                                                                      setAdjustCommissionSwapnRecord(record)
                                                                  }}
                                                              >
                                                                  Adjust Comm/Swap
                                                              </Button>
                                                          )}
                                                      </>
                                                  ) : (
                                                      <>
                                                          {tradingAccountPermission?.edit?.reopenClosedPosition && (
                                                              <Button
                                                                  size={"small"}
                                                                  onClick={() => {
                                                                      setIsOpenReopenPosition(true)
                                                                      setReopenPositionRecord(record)
                                                                      setReopenPositionStatus("voidAndReopen")
                                                                  }}
                                                              >
                                                                  Void and Reopen
                                                              </Button>
                                                          )}
                                                          <Popconfirm
                                                              placement="left"
                                                              description={t("Void Closed Position")}
                                                              onConfirm={() => {
                                                                  voidClosedPositionHandle(record)
                                                              }}
                                                              okText={t("common.yes")}
                                                              cancelText={t("common.no")}
                                                              overlayStyle={{width: 300}}
                                                              title={"Are you sure you want to void this closed position?"}
                                                          >
                                                              <Button size={"small"}>Void</Button>
                                                          </Popconfirm>
                                                      </>
                                                  )}
                                              </Space>
                                          )
                                      },
                                  },
                              ...columns,
                          ].filter((d) => d)
                        : columns
                }
                data={reportData}
                summaryData={reportTotalRowData}
                filter={setFilter}
                order={setOrderBy}
                rowKey={(record, index) => index}
                scroll={{
                    scrollToFirstRowOnChange: true,
                    x: true,
                }}
                pagination={{
                    pageSize: rows,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                    total: totalCount,
                    showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
                }}
            />

            {tradingAccountPermission?.edit?.reopenClosedPosition && (
                <ReopenPositionModalCT
                    accountId={accDetails?._id}
                    isOpen={isOpenReopenPosition}
                    handleCancel={() => setIsOpenReopenPosition(false)}
                    closedPosition={reopenPositionRecord}
                    status={reopenPositionStatus}
                    getReportDetails={getReportDetails}
                />
            )}
            {tradingAccountPermission?.edit?.adjustCommissionSwap && isOpenAdjustCommissionSwap && (
                <AdjustCommissionSwapModalCT
                    accountId={accDetails?._id}
                    isOpen={isOpenAdjustCommissionSwap}
                    handleCancel={() => setIsOpenAdjustCommissionSwap(false)}
                    closedPosition={adjustCommissionSwapnRecord}
                    getReportDetails={getReportDetails}
                    refreshQuery={refreshQuery}
                />
            )}
        </div>
    )
}

export default AccountReportTable
