import {Button, Card, Col, Form, Row} from "antd";
import {FormProvider, useForm} from "react-hook-form";
import SelectFormItem from "../../../../../../../components/form/selectFormItem";
import {horizontalFormLayout} from "../../../../../../../components/form/formWrapper";
import SwitchFormItem from "../../../../../../../components/form/switchFormItem";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {yupResolver} from "@hookform/resolvers/yup";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import * as yup from "yup";
import yupFormSchema from "../../../../../../../components/yup/yupFormSchema";
import {
    useAddTradingAccountTransactionEWalletMutation,
    useAddTradingAccountTransactionManualMutation,
    useAddTradingAccountTransactionNegativeBalanceProtectionMutation
} from "../../../../../../../api/graphql/tradingAccount";
import useAuthorize from "../../../../../../../_common/authorize";
import InputNumberFormItem from "../../../../../../../components/form/inputNumberFormItem";
import TextAreaInputFormItem from "../../../../../../../components/form/textAreaInputFormItem";
import {
    depositEWalletTradingAccountMt5,
    depositManualTradingAccountMt5,
    depositNegativeBalanceProtectionTradingAccountMt5,
    withdrawalEWalletTradingAccountMt5,
    withdrawalManualTradingAccountMt5
} from "../../../../../../../api/graphql/tradingAccountMt5";


const TradingAccountBatchTransactionForm_1 = (props) => {

    const {batchUpdateRecord, refetch, setView} = props
    const [btnLoading, setBtnLoading] = useState(false)
    const [transactionType, setTransactionType] = useState("deposit");
    const [depositType, setDepositType] = useState("");

    const [invalidAcc, setInvalidAcc] = useState<any>([]);

    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {systemErrorNotification} = useAuthorize()

    const {addTradingAccountTransactionEWallet} = useAddTradingAccountTransactionEWalletMutation({
        filter: {
            account: props?.id,
        },
    });
    const {addTradingAccountTransactionNegativeBalanceProtection} = useAddTradingAccountTransactionNegativeBalanceProtectionMutation({
        filter: {
            account: props?.id,
        },
    });
    const {addTradingAccountTransactionManual} = useAddTradingAccountTransactionManualMutation({
        filter: {
            account: props?.id,
        },
    });

    const yupSchema = yup.object().shape({
        type: yupFormSchema.string("type", {required: true}),
        amount: yupFormSchema.number("amount", {required: true}),
        depositType: yupFormSchema.string("depositType", {required: true}),
        comment: yupFormSchema.string("comment", {required: false}),
        sendEmailNotification: yupFormSchema.boolean("sendEmailNotification", {
            required: false,
        }),
    });

    const labelCol = {span: 8, offset: 0};

    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
    });

    const {t} = useTranslation();

    const onSubmit = async (value) => {
        try {
            await setBtnLoading(true)
            await setInvalidAcc([])

            const valid: any = []
            const invalid: any = []
            const pending: any = []

            for (let j = 0; j < batchUpdateRecord?.length; j++) {
                const acc = batchUpdateRecord[j];
                const data = {
                    user: acc?.userId,
                    account: acc._id,
                    ...value
                }

                let result;
                switch (value?.depositType) {
                    case "manual":
                        result = batchUpdateRecord?.[0]?.platform === "mt5" ? await onSubmitManualMt5(data) : await onSubmitManual(data);
                        break;
                    case "ewallet":
                        result = batchUpdateRecord?.[0]?.platform === "mt5" ? await onSubmitEWalletMt5(data) : await onSubmitEWallet(data);
                        break;
                    case "negativeBalanceProtection":
                        result = batchUpdateRecord?.[0]?.platform === "mt5" ? await onSubmitNegativeBalanceProtectionMt5(data) : await onSubmitNegativeBalanceProtection(data);
                        break;
                }

                if (result?.status === "success") {
                    valid.push({
                        account: acc,
                        msg: result?.msg
                    })
                } else if (result?.status === "error") {
                    invalid.push({
                        account: acc,
                        msg: result?.msg
                    })
                } else {
                    pending.push({
                        account: acc,
                        msg: result?.msg
                    })
                }
            }

            if (valid?.length > 0 || pending?.length > 0) {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("tradingAccount.create_transaction_successfully"),
                    key: "addTradingAccountTransaction",
                });
            }

            setInvalidAcc(invalid)
            refetch()

        } catch (error: any) {
            systemErrorNotification()
        } finally {
            setBtnLoading(false)
            setView(false)
        }
    };

    const onSubmitManual = async (value) => {
        try {
            const response = await addTradingAccountTransactionManual({
                user: value.user,
                account: value?.account,
                type: value?.type,
                amount: value?.amount.toString(),
                comment: value?.comment,
                sendEmailNotification: value?.sendEmailNotification,
            });

            if (
                response?.data?.addTradingAccountTransactionManual.__typename ===
                "tradingAccountTransaction"
            ) {
                form.reset();
                return {
                    status: "success",
                    msg: t("tradingAccount.create_transaction_successfully"),
                }
            } else if (
                response?.data?.addTradingAccountTransactionManual.__typename === "BaseError"
            ) {
                return {
                    status: "error",
                    msg: t(response?.data?.addTradingAccountTransactionManual?.errKey)
                }
            } else {
                return {
                    status: "pending",
                    msg: t(response?.data?.addTradingAccountTransactionManual?.msg)
                }
            }
        } catch (error: any) {
            systemErrorNotification()
        }
    };
    const onSubmitEWallet = async (value) => {
        try {
            const response = await addTradingAccountTransactionEWallet({
                user: value.user,
                account: value?.account,
                type: value?.type,
                amount: value?.amount.toString(),
                comment: value?.comment,
                sendEmailNotification: value?.sendEmailNotification,
            });

            if (
                response?.data?.addTradingAccountTransactionEWallet.__typename ===
                "tradingAccountTransaction"
            ) {
                form.reset();
                return {
                    status: "success",
                    msg: t("tradingAccount.create_transaction_successfully"),
                }
            } else if (
                response?.data?.addTradingAccountTransactionEWallet.__typename === "BaseError"
            ) {
                return {
                    status: "error",
                    msg: t(response?.data?.addTradingAccountTransactionEWallet?.errKey)
                }
            } else {
                return {
                    status: "pending",
                    msg: t(response?.data?.addTradingAccountTransactionEWallet?.msg)
                }
            }

        } catch (error: any) {
            systemErrorNotification()
        }
    };
    const onSubmitNegativeBalanceProtection = async (value) => {
        try {
            const response = await addTradingAccountTransactionNegativeBalanceProtection({
                user: value.user,
                account: value?.account,
                type: value?.type,
                amount: value?.amount.toString(),
                comment: value?.comment,
                sendEmailNotification: value?.sendEmailNotification,
            });

            if (
                response?.data?.addTradingAccountTransactionNegativeBalanceProtection.__typename ===
                "tradingAccountTransaction"
            ) {
                form.reset();
                return {
                    status: "success",
                    msg: t("tradingAccount.create_transaction_successfully"),
                }
            } else if (
                response?.data?.addTradingAccountTransactionNegativeBalanceProtection.__typename === "BaseError"
            ) {
                return {
                    status: "error",
                    msg: t(response?.data?.addTradingAccountTransactionNegativeBalanceProtection?.errKey)
                }
            } else {
                return {
                    status: "pending",
                    msg: t(response?.data?.addTradingAccountTransactionNegativeBalanceProtection?.msg)
                }
            }

        } catch (error: any) {
            systemErrorNotification()
        }
    };

    const onSubmitManualMt5 = async (value) => {
        try {

            if (value?.type === "deposit") {

                const response = await depositManualTradingAccountMt5({
                    user: value.user,
                    account: value?.account,
                    amount: value?.amount.toString(),
                    comment: value?.comment,
                    sendEmailNotification: value?.sendEmailNotification,
                });

                if (response?.depositManualTradingAccountMt5.__typename === "TradingAccountMt5Transaction") {
                    form.reset();
                    return {
                        status: "success",
                        msg: t("tradingAccount.create_transaction_successfully"),
                    }
                } else if (
                    response?.depositManualTradingAccountMt5.__typename === "BaseError"
                ) {
                    return {
                        status: "error",
                        msg: t(response?.data?.depositManualTradingAccountMt5?.errKey)
                    }
                } else {
                    return {
                        status: "pending",
                        msg: t(response?.data?.depositManualTradingAccountMt5?.msg)
                    }
                }


            } else if (value?.type === "withdrawal") {
                const response = await withdrawalManualTradingAccountMt5({
                    user: value.user,
                    account: value?.account,
                    amount: value?.amount.toString(),
                    comment: value?.comment,
                    sendEmailNotification: value?.sendEmailNotification,
                });

                if (response?.withdrawalManualTradingAccountMt5.__typename === "TradingAccountMt5Transaction") {
                    form.reset();
                    return {
                        status: "success",
                        msg: t("tradingAccount.create_transaction_successfully"),
                    }
                } else if (
                    response?.withdrawalManualTradingAccountMt5.__typename === "BaseError"
                ) {
                    return {
                        status: "error",
                        msg: t(response?.data?.withdrawalManualTradingAccountMt5?.errKey)
                    }
                } else {
                    return {
                        status: "pending",
                        msg: t(response?.data?.withdrawalManualTradingAccountMt5?.msg)
                    }
                }
            }


        } catch (error: any) {
            systemErrorNotification()
            setBtnLoading(false)
        }
    };

    const onSubmitEWalletMt5 = async (value) => {
        try {


            if (value?.type === "deposit") {

                const response = await depositEWalletTradingAccountMt5({
                    user: value.user,
                    account: value?.account,
                    amount: value?.amount.toString(),
                    comment: value?.comment,
                    sendEmailNotification: value?.sendEmailNotification,
                });

                if (response?.depositEWalletTradingAccountMt5.__typename === "TradingAccountMt5Transaction") {
                    form.reset();
                    return {
                        status: "success",
                        msg: t("tradingAccount.create_transaction_successfully"),
                    }
                } else if (
                    response?.depositEWalletTradingAccountMt5.__typename === "BaseError"
                ) {
                    return {
                        status: "error",
                        msg: t(response?.data?.depositEWalletTradingAccountMt5?.errKey)
                    }
                } else {
                    return {
                        status: "pending",
                        msg: t(response?.data?.depositEWalletTradingAccountMt5?.msg)
                    }
                }


            } else if (value?.type === "withdrawal") {
                const response = await withdrawalEWalletTradingAccountMt5({
                    user: value.user,
                    account: value?.account,
                    amount: value?.amount.toString(),
                    comment: value?.comment,
                    sendEmailNotification: value?.sendEmailNotification,
                });

                if (response?.withdrawalEWalletTradingAccountMt5.__typename === "TradingAccountMt5Transaction") {
                    form.reset();
                    return {
                        status: "success",
                        msg: t("tradingAccount.create_transaction_successfully"),
                    }
                } else if (
                    response?.withdrawalEWalletTradingAccountMt5.__typename === "BaseError"
                ) {
                    return {
                        status: "error",
                        msg: t(response?.data?.withdrawalEWalletTradingAccountMt5?.errKey)
                    }
                } else {
                    return {
                        status: "pending",
                        msg: t(response?.data?.withdrawalEWalletTradingAccountMt5?.msg)
                    }
                }
            }

        } catch (error: any) {
            systemErrorNotification()
            setBtnLoading(false)
        }
    };

    const onSubmitNegativeBalanceProtectionMt5 = async (value) => {
        try {

            const response = await depositNegativeBalanceProtectionTradingAccountMt5({
                user: value.user,
                account: value?.account,
                amount: value?.amount.toString(),
                comment: value?.comment,
                sendEmailNotification: value?.sendEmailNotification,
            });

            if (response?.depositNegativeBalanceProtectionTradingAccountMt5.__typename === "TradingAccountMt5Transaction") {
                form.reset();
                return {
                    status: "success",
                    msg: t("tradingAccount.create_transaction_successfully"),
                }
            } else if (
                response?.depositNegativeBalanceProtectionTradingAccountMt5.__typename === "BaseError"
            ) {
                return {
                    status: "error",
                    msg: t(response?.data?.depositNegativeBalanceProtectionTradingAccountMt5?.errKey)
                }
            } else {
                return {
                    status: "pending",
                    msg: t(response?.data?.depositNegativeBalanceProtectionTradingAccountMt5?.msg)
                }
            }

        } catch (error: any) {
            systemErrorNotification()
            setBtnLoading(false)
        }
    };

    const transactionTypeOptions = [
        {
            value: "deposit",
            label: t("tradingAccount.deposit"),
        },
        {
            value: "withdrawal",
            label: t("tradingAccount.withdrawal"),
        },
    ];

    const depositTypeOptions = [
        {
            value: "manual",
            label: t("tradingAccount.manual_deposit"),
        },
        {
            value: "negativeBalanceProtection",
            label: t("tradingAccount.negative_balance_protection"),
        },
        {
            value: "ewallet",
            label: t("tradingAccount.deposit_via_ewallet"),
        },
    ];

    const withdrawalTypeOptions = [
        {
            value: "manual",
            label: t("tradingAccount.manual_withdrawal"),
        },
        {
            value: "ewallet",
            label: t("tradingAccount.withdrawal_to_ewallet"),
        },
    ];

    return (
        <Row gutter={[16, 16]}>
            <Col span={8} style={{marginTop: "15px"}}>
                <div>
                    {/*<h4>Deposit / Withdrawal</h4>*/}
                    <FormProvider {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)} className="form-1">
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <SelectFormItem
                                        labelAlign={"left"}
                                        name={"type"}
                                        label={t("tradingAccount.transaction_type")}
                                        placeholder={t("tradingAccount.transaction_type")}
                                        layout={horizontalFormLayout}
                                        options={transactionTypeOptions}
                                        mode={"single"}
                                        labelCol={labelCol}
                                        required
                                        onChange={(e) => {
                                            setTransactionType(e);
                                        }}
                                        value={transactionType}
                                    />
                                </Col>

                                {
                                    (props?.data?.user?.clientAccountType !== "demo" && props?.data?.product?.mode !== "demo") && (
                                        <>
                                            {transactionType === "deposit" ? (
                                                <Col span={24}>
                                                    <SelectFormItem
                                                        labelAlign={"left"}
                                                        name={"depositType"}
                                                        label={t("tradingAccount.deposit_type")}
                                                        placeholder={t("tradingAccount.deposit_type")}
                                                        onChange={(e) => setDepositType(e)}
                                                        layout={horizontalFormLayout}
                                                        options={depositTypeOptions}
                                                        required
                                                        mode={"single"}
                                                        labelCol={labelCol}
                                                        value={""}
                                                    />
                                                </Col>
                                            ) : (
                                                <Col span={24}>
                                                    <SelectFormItem
                                                        labelAlign={"left"}
                                                        name={"depositType"}
                                                        label={t("tradingAccount.withdrawal_type")}
                                                        placeholder={t("tradingAccount.withdrawal_type")}
                                                        layout={horizontalFormLayout}
                                                        onChange={(e) => setDepositType(e)}
                                                        options={withdrawalTypeOptions}
                                                        required
                                                        mode={"single"}
                                                        labelCol={labelCol}
                                                        value={""}
                                                    />
                                                </Col>
                                            )}
                                        </>
                                    )
                                }

                                <Col span={24}>
                                    <InputNumberFormItem
                                        labelAlign={"left"}
                                        name={"amount"}
                                        label={t("tradingAccount.amount")}
                                        placeholder={t("tradingAccount.amount")}
                                        layout={horizontalFormLayout}
                                        required
                                        labelCol={labelCol}
                                        style={{width: "100%"}}
                                        min={1}
                                        step={2}
                                        // formatter={formatValue}
                                        // parser={parseValue}
                                    />
                                </Col>
                                <Col span={24}>
                                    <SwitchFormItem
                                        labelAlign={"left"}
                                        name={"sendEmailNotification"}
                                        label={t("tradingAccount.send_email_notification")}
                                        labelCol={{span: 8, offset: 0}}
                                        value={true}
                                    />
                                </Col>
                                <Col span={24}>
                                    <TextAreaInputFormItem
                                        labelAlign={"left"}
                                        name={"comment"}
                                        label={t("tradingAccount.comment")}
                                        placeholder={t("tradingAccount.comment")}
                                        layout={horizontalFormLayout}
                                        labelCol={labelCol}
                                        disabled={depositType !== "manual"}
                                    />
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        className="text-align-right"
                                        style={{paddingTop: "12px"}}
                                    >
                                        <Button
                                            type={"primary"}
                                            onClick={form.handleSubmit(onSubmit)}
                                            className="button-submit-1"
                                            loading={btnLoading}
                                            disabled={btnLoading}
                                        >
                                            {t("common.submit")}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </form>
                    </FormProvider>

                </div>
            </Col>
            <Col span={14} offset={1}>
                {
                    invalidAcc?.length > 0 && (
                        <Card style={{width: "100%"}}>
                            {
                                invalidAcc?.map((item) => {
                                    return (
                                        <div style={{marginBottom: "5px"}}>
                                            <Col span={24}>
                                                {t("tradingAccount.account")}:{item?.account?.accountId}
                                            </Col>
                                            <Col span={24}>
                                                {t("tradingAccount.message")}: <span
                                                style={{color: "red"}}>{item?.msg}</span>
                                            </Col>
                                        </div>
                                    )
                                })
                            }
                        </Card>
                    )
                }

            </Col>

        </Row>
    )
}

export default TradingAccountBatchTransactionForm_1