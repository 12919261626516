import {gql, useMutation, useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {ApolloClientQuery} from "./_apolloClient";

const GET_MISSION_COUNT = gql`
    query getMissionCount($filter: MissionSearchInput) {
        getMissionCount(filter: $filter)
    }
`;

const getMissionCount = ({filter}) => {
    try {
        const {loading, error, data} = useQuery(GET_MISSION_COUNT, {
            variables: {
                filter: {
                    name: filter?.name || null,
                },
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_MISSION = gql`
    query getMission($filter: MissionSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
        getMission(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on MissionArray {
                data {
                    _id
                    id
                    name
                    position
                    displayName {
                        content
                        language
                    }
                    description {
                        content
                        language
                    }
                    termAndConditionEnabled
                    termAndCondition {
                        content
                        language
                    }
                    automationId
                    continuationMission
                    continuationMissionId {
                        _id
                        id
                        name
                    }
                    product
                    productId {
                        _id
                        id
                        name
                    }
                    missionEnd
                    missionGroup {
                        _id
                        id
                        name
                    }
                    conditionCalculationPeriod
                    autoClaim
                    missionEventDurationStartDate
                    missionEventDurationEndDate
                    missionDurationType
                    missionDurationValue
                    missionDurationDate
                    rules {
                        content
                        language
                    }
                    isRandomReward
                    rewardValue
                    missionCode
                    rewards {
                        rewardType
                        reward {
                            ... on Voucher {
                                _id
                                id
                                name
                            }
                            ... on VoucherGroup {
                                _id
                                id
                                name
                            }
                        }
                        voucherExpiryType
                        voucherExpiryValue
                        voucherExpiryDate
                        voucherDurationType
                        voucherDurationValue
                        voucherDurationDate
                        voucherStartDate
                        maxRedeem
                        isFixedReward
                    }
                    networkType
                    network {
                        _id
                        id
                        username
                    }
                    exclude {
                        _id
                        id
                        username
                    }
                    isMissionCodeRequired
                    missionCodeType
                    missionCode
                    missionCodeDigits
                    staffRank {
                        _id
                        id
                        name
                    }
                    availability
                    autoComplete
                    autoCompleteExpiredMission
                    imageLight {
                        fileList
                        path
                    }
                    imageDark {
                        fileList
                        path
                    }
                    defaultImageLight {
                        fileList
                        path
                    }
                    defaultImageDark {
                        fileList
                        path
                    }
                    isDeleted
                    createdAt
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    updatedAt
                    missionTotalClaimed
                    missionTotalCompleted
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getMission = ({filter}, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data, refetch} = useQuery(GET_MISSION, {
            variables: {
                filter: filter,
                limit: limit,
                offset: offset,
                orderBy: orderBy,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        const refreshQuery = () => {
            refetch();
        };

        return {loading, error, data, refreshQuery};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_MISSION_DROPDOWN = gql`
    query getMissionDropdown {
        getMissionDropdown {
            ... on MissionArray {
                data {
                    _id
                    id
                    name
                    position
                    displayName {
                        content
                        language
                    }
                    description {
                        content
                        language
                    }
                    termAndConditionEnabled
                    termAndCondition {
                        content
                        language
                    }
                    automationId
                    continuationMission
                    continuationMissionId {
                        _id
                        id
                        name
                    }
                    product
                    productId {
                        _id
                        id
                        name
                    }
                    missionEnd
                    missionGroup {
                        _id
                        id
                        name
                    }
                    conditionCalculationPeriod
                    autoClaim
                    missionEventDurationStartDate
                    missionEventDurationEndDate
                    missionDurationType
                    missionDurationValue
                    missionDurationDate
                    rules {
                        content
                        language
                    }
                    isRandomReward
                    rewardValue
                    missionCode
                    networkType
                    network {
                        _id
                        id
                        username
                    }
                    exclude {
                        _id
                        id
                        username
                    }
                    isMissionCodeRequired
                    missionCodeType
                    missionCode
                    missionCodeDigits
                    staffRank {
                        _id
                        id
                        name
                    }
                    availability
                    autoComplete
                    autoCompleteExpiredMission
                    imageLight {
                        fileList
                        path
                    }
                    imageDark {
                        fileList
                        path
                    }
                    defaultImageLight {
                        fileList
                        path
                    }
                    defaultImageDark {
                        fileList
                        path
                    }
                    isDeleted
                    createdAt
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getMissionDropdown = async () => {
    try {
        // const {loading, error, data, refetch} = useQuery(GET_MISSION_DROPDOWN, {
        //     context: {
        //         headers: {
        //             authorization: "Bearer " + localStorage.getItem("a_t"),
        //         },
        //     },
        // });

        // const refreshQuery = () => {
        //     refetch();
        // };

        // return {loading, error, data, refreshQuery};

        const variables = {
            // filter: filter,
            // limit: limit,
            // offset: offset,
            // orderBy: orderBy,
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        };

        const execute = await ApolloClientQuery(GET_MISSION_DROPDOWN, variables, false);

        return execute;
        // return ["test"]
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_AVAILABLE_USER_MISSION = gql(`
query GetAvailableUserMission($userid: String!, $filter: MissionSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
    getAvailableUserMission(userid: $userid, filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
        ... on AvailableUserMissionArray {
            data {
                _id
                id
                name
                isRandomReward
                rewardValue
                missionCode
                networkType
                missionCodeType
                availability
                automationId
                continuationMission
                missionEnd
                product
                missionEventDurationStartDate
                missionEventDurationEndDate
                missionDurationType
                missionDurationValue
                missionDurationDate
                conditionCalculationPeriod
                autoClaim
                termAndConditionEnabled
                isDeleted
                autoComplete
                autoCompleteExpiredMission
                isTradingAccountRequired
                isClaimable
                isMissionCodeRequired
                createdAt
                updatedAt
                displayName {
                    language
                    content
                }
                description {
                    language
                    content
                }
                rules {
                    language
                    content
                }
                rewards {
                    rewardType
                    reward {
                        ... on VoucherGroup {
                            _id
                            id
                            name
                            isRandomReward
                            termAndConditionEnabled
                            rewardValue
                            isDeleted
                            createdAt
                            updatedAt
                            displayName {
                                language
                                content
                            }
                            description {
                                language
                                content
                            }
                            pool {
                                maximumRedeem
                                probability
                                voucher {
                                    _id
                                    name
                                    termAndConditionEnabled
                                    rewardType
                                    voucherType
                                    voucherValueIsRange
                                    voucherValue
                                    voucherValueStartRange
                                    voucherValueEndRange
                                    voucherStackable
                                    maxAmount
                                    isDeleted
                                    createdAt
                                    updatedAt
                                    displayName {
                                        language
                                        content
                                    }
                                    description {
                                        language
                                        content
                                    }
                                    termAndCondition {
                                        language
                                        content
                                    }
                                    product {
                                        _id
                                        name
                                    }
                                    stockVoucher {
                                        _id
                                        baseSymbolId
                                        name
                                    }
                                    defaultImageLight {
                                        fileList
                                        path
                                    }
                                    defaultImageDark {
                                        fileList
                                        path
                                    }
                                    imageLight {
                                        fileList
                                        path
                                    }
                                    imageDark {
                                        fileList
                                        path
                                    }
                                }
                            }
                            termAndCondition {
                                language
                                content
                            }
                            defaultImageLight {
                                fileList
                                path
                            }
                            defaultImageDark {
                                fileList
                                path
                            }
                            imageLight {
                                fileList
                                path
                            }
                            imageDark {
                                fileList
                                path
                            }
                        }
                        ... on Voucher {
                            _id
                            id
                            name
                            termAndConditionEnabled
                            rewardType
                            voucherType
                            voucherValueIsRange
                            voucherValue
                            voucherValueStartRange
                            voucherValueEndRange
                            voucherStackable
                            maxAmount
                            isDeleted
                            createdAt
                            updatedAt
                            displayName {
                                language
                                content
                            }
                            description {
                                language
                                content
                            }
                            termAndCondition {
                                language
                                content
                            }
                            product {
                                _id
                                name
                            }
                            stockVoucher {
                                _id
                                baseSymbolId
                                name
                            }
                            defaultImageLight {
                                fileList
                                path
                            }
                            defaultImageDark {
                                fileList
                                path
                            }
                            imageLight {
                                fileList
                                path
                            }
                            imageDark {
                                fileList
                                path
                            }
                        }
                    }
                    voucherExpiryType
                    voucherExpiryDate
                    voucherExpiryValue
                    voucherDurationType
                    voucherDurationValue
                    voucherDurationDate
                    maxRedeem
                    isFixedReward
                }
                continuationMissionId {
                    _id
                }
                productId {
                    _id
                    name
                }
                missionGroup {
                    _id
                    name
                    displayName {language content}
                }
                termAndCondition {
                    language
                    content
                }
                canClaimForTradingAccount {
                    _id
                    accountId
                    mode
                    product {
                        name
                    }
                }
                defaultImageLight {
                    fileList
                    path
                }
                defaultImageDark {
                    fileList
                    path
                }
                imageLight {
                    fileList
                    path
                }
                imageDark {
                    fileList
                    path
                }
                missionAutomationSetting
            }
            totalCount
        }
        ... on BaseError {
            errKey
            errMsg
            errObj
        }
    }
}
`);

async function getAvailableUserMission(userId, filter, limit, offset, orderBy) {
    const variables = {
        userid: userId,
        filter,
        limit,
        offset,
        orderBy,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_AVAILABLE_USER_MISSION, variables, false);

    return execute;
}


const GET_USER_CLAIM_MISSION_COUNT = gql`
    query getUserClaimMissionCount($filter: MissionSearchInput) {
        getUserClaimMissionCount(filter: $filter)
    }
`;

const getUserClaimMissionCount = ({filter}) => {
    try {
        const {loading, error, data} = useQuery(GET_USER_CLAIM_MISSION_COUNT, {
            variables: {
                filter: {
                    name: filter?.name || null,
                },
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};


const GET_USER_CLAIM_MISSION = gql(`
query getUserClaimMission($filter: UserClaimMissionInput, $limit: Int, $offset: Int, $orderBy: String) {
  getUserClaimMission(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
    ... on UserClaimMissionArray {
      data {
        _id
        id
        missionGroupId {
          _id
          name
          displayName {
            language
            content
          }
        }
        missionId {
          _id
          id
          name
          isRandomReward
          rewardValue
          missionCode
          networkType
          missionCodeType
          availability
          automationId
          continuationMission
          missionEnd
          product
          missionEventDurationStartDate
          missionEventDurationEndDate
          missionDurationType
          missionDurationValue
          missionDurationDate
          conditionCalculationPeriod
          autoClaim
          termAndConditionEnabled
          isDeleted
          autoComplete
          autoCompleteExpiredMission
          isTradingAccountRequired
          isMissionCodeRequired
          createdAt
          updatedAt
          displayName {
            language
            content
          }
          description {
            language
            content
          }
          rules {
            language
            content
          }
          rewards {
            rewardType
            reward {
              ... on VoucherGroup {
                _id
                id
                name
                isRandomReward
                termAndConditionEnabled
                rewardValue
                isDeleted
                createdAt
                updatedAt
                displayName {
                  language
                  content
                }
                description {
                  language
                  content
                }
                pool {
                  maximumRedeem
                  probability
                  voucher {
                    _id
                    name
                    termAndConditionEnabled
                    rewardType
                    voucherType
                    voucherValueIsRange
                    voucherValue
                    voucherValueStartRange
                    voucherValueEndRange
                    voucherStackable
                    maxAmount
                    isDeleted
                    createdAt
                    updatedAt
                    displayName {
                      language
                      content
                    }
                    description {
                      language
                      content
                    }
                    termAndCondition {
                      language
                      content
                    }
                    product {
                      _id
                      name
                    }
                    stockVoucher {
                      _id
                      baseSymbolId
                      name
                    }
                    defaultImageLight {
                      fileList
                      path
                    }
                    defaultImageDark {
                      fileList
                      path
                    }
                    imageLight {
                      fileList
                      path
                    }
                    imageDark {
                      fileList
                      path
                    }
                  }
                }
                termAndCondition {
                  language
                  content
                }
                defaultImageLight {
                  fileList
                  path
                }
                defaultImageDark {
                  fileList
                  path
                }
                imageLight {
                  fileList
                  path
                }
                imageDark {
                  fileList
                  path
                }
              }
              ... on Voucher {
                _id
                id
                name
                termAndConditionEnabled
                rewardType
                voucherType
                voucherValueIsRange
                voucherValue
                voucherValueStartRange
                voucherValueEndRange
                voucherStackable
                maxAmount
                isDeleted
                createdAt
                updatedAt
                displayName {
                  language
                  content
                }
                description {
                  language
                  content
                }
                termAndCondition {
                  language
                  content
                }
                product {
                  _id
                  name
                }
                stockVoucher {
                  _id
                  baseSymbolId
                  name
                }
                defaultImageLight {
                  fileList
                  path
                }
                defaultImageDark {
                  fileList
                  path
                }
                imageLight {
                  fileList
                  path
                }
                imageDark {
                  fileList
                  path
                }
              }
            }
            voucherExpiryType
            voucherExpiryDate
            voucherExpiryValue
            voucherDurationType
            voucherDurationValue
            voucherDurationDate
            voucherStartDate
            maxRedeem
            isFixedReward
          }
          continuationMissionId {
            _id
          }
          productId {
            _id
            name
          }
          missionGroup {
            _id
            name
            displayName {language content}
          }
          termAndCondition {
            language
            content
          }
          canClaimForTradingAccount {
            _id
            accountId
            mode
            product {
                name
            }
          }
          defaultImageLight {
            fileList
            path
          }
          defaultImageDark {
            fileList
            path
          }
          imageLight {
            fileList
            path
          }
          imageDark {
            fileList
            path
          }
        }
        isMissionExpired
        isMissionCompleted
        isVoucherIssued
        tradingAccount {
          _id
          accountId
        }
        missionTarget
        missionClaimedAt
        missionClaimedAtEditedDate
        missionCompleteDate
        isDeleted
        user {
          _id
          username
        }
        missionAutomationSetting
      }
    }
  }
}
`);

async function getUserClaimMission(filter, limit, offset, orderBy) {
    const variables = {
        filter: filter,
        limit: limit,
        offset: offset,
        orderBy: orderBy,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_USER_CLAIM_MISSION, variables, false);

    return execute;
}

const ADD_MISSION = gql`
    mutation addMission($input: MissionInput) {
        addMission(input: $input) {
            ... on Mission {
                _id
                id
                name
                position
                displayName {
                    content
                    language
                }
                description {
                    content
                    language
                }
                termAndConditionEnabled
                termAndCondition {
                    content
                    language
                }
                automationId
                continuationMission
                continuationMissionId {
                    _id
                    id
                    name
                }
                missionEnd
                product
                productId {
                    _id
                    id
                    name
                }
                missionEventDurationStartDate
                missionEventDurationEndDate
                missionDurationType
                missionDurationValue
                missionDurationDate
                missionGroup {
                    _id
                    id
                    name
                }
                conditionCalculationPeriod
                autoClaim
                rules {
                    content
                    language
                }
                isRandomReward
                rewardValue
                rewards {
                    rewardType
                    reward {
                        ... on Voucher {
                            _id
                            id
                            name
                        }
                        ... on VoucherGroup {
                            _id
                            id
                            name
                        }
                    }
                    voucherExpiryType
                    voucherExpiryValue
                    voucherExpiryDate
                    voucherDurationType
                    voucherDurationValue
                    voucherDurationDate
                    voucherStartDate
                    maxRedeem
                    isFixedReward
                }
                networkType
                network {
                    _id
                    id
                    username
                }
                exclude {
                    _id
                    id
                    username
                }
                isMissionCodeRequired
                missionCodeType
                missionCode
                missionCodeDigits
                staffRank {
                    _id
                    id
                    name
                }
                availability
                autoComplete
                autoCompleteExpiredMission
                imageLight {
                    fileList
                    path
                }
                imageDark {
                    fileList
                    path
                }
                defaultImageLight {
                    fileList
                    path
                }
                defaultImageDark {
                    fileList
                    path
                }
                isDeleted
                createdAt
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useAddMissionMutation = (): {
    addMission: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [addMissionMutation, {data, loading, error}] = useMutation(ADD_MISSION);

        const addMission = (input) => {
            return addMissionMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {addMission}}) {
                    const typename = addMission?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(addMission?.errKey),
                            key: "addMission",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(addMission?.msg),
                            key: "addMission",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Add Successfully"),
                            key: "addMission",
                        });
                    }
                },
                refetchQueries: [GET_MISSION, GET_MISSION_COUNT],
            });
        };

        return {addMission, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            addMission: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const ADD_USER_EXTRA_MISSION = gql`
    mutation addUserExtraMission($input: UserExtraMissionInput) {
        addUserExtraMission(input: $input) {
            ... on UserExtraMission {
                _id
                id
                missionId
                missionCode
                createdAt
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useAddUserExtraMissionMutation = (): {
    addUserExtraMission: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [addUserExtraMissionMutation, {data, loading, error}] = useMutation(ADD_USER_EXTRA_MISSION);

        const addUserExtraMission = (input) => {
            return addUserExtraMissionMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {addUserExtraMission}}) {
                    const typename = addUserExtraMission?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(addUserExtraMission?.errKey),
                            key: "addUserExtraMission",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(addUserExtraMission?.msg),
                            key: "addUserExtraMission",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Add Successfully"),
                            key: "addUserExtraMission",
                        });
                    }
                },
                refetchQueries: [GET_USER_CLAIM_MISSION, GET_MISSION_COUNT],
            });
        };

        return {addUserExtraMission, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            addUserExtraMission: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const ADD_USER_CLAIM_MISSION = gql`
    mutation AddUserClaimMission($input: UserClaimMissionInput) {
        addUserClaimMission(input: $input) {
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
            ... on UserClaimMission {
                _id
            }
        }
    }
`;

async function addUserClaimMission(input) {
    const variables = {
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(ADD_USER_CLAIM_MISSION, variables, true);

    return execute;
}

const UPDATE_USER_CLAIM_MISSION = gql`
    mutation updateUserClaimMission($id: String!, $input: UserClaimMissionUpdateInput) {
        updateUserClaimMission(id: $id, input: $input) {
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
            ... on UserClaimMission {
                _id
                missionClaimedAt
                missionClaimedAtEditedDate
            }
        }
    }
`;

async function updateUserClaimMission(id, input) {
    const variables = {
        id: id,
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(UPDATE_USER_CLAIM_MISSION, variables, true);

    return execute;
}

const USER_COMPLETE_MISSION = gql`
    mutation userCompleteMission($id: String!, $input: UserCompleteMissionIput) {
        userCompleteMission(id: $id, input: $input) {
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on UserVouchersArray {
                data {
                    _id
                }
            }
        }
    }
`;

async function userCompleteMission(id, input) {
    const variables = {
        id: id,
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(USER_COMPLETE_MISSION, variables, true);

    return execute;
}

const UPDATE_MISSION = gql`
    mutation updateMission($id: String!, $input: MissionInput) {
        updateMission(id: $id, input: $input) {
            ... on Mission {
                _id
                id
                name
                position
                displayName {
                    content
                    language
                }
                description {
                    content
                    language
                }
                termAndConditionEnabled
                termAndCondition {
                    content
                    language
                }
                automationId
                continuationMission
                continuationMissionId {
                    _id
                    id
                    name
                }
                missionEnd
                missionGroup {
                    _id
                    id
                    name
                }
                conditionCalculationPeriod
                autoClaim
                product
                productId {
                    _id
                    id
                    name
                }
                missionEventDurationStartDate
                missionEventDurationEndDate
                missionDurationType
                missionDurationValue
                missionDurationDate
                rules {
                    content
                    language
                }
                isRandomReward
                rewardValue
                rewards {
                    rewardType
                    reward {
                        ... on Voucher {
                            _id
                            id
                            name
                        }
                        ... on VoucherGroup {
                            _id
                            id
                            name
                        }
                    }
                    voucherExpiryType
                    voucherExpiryValue
                    voucherExpiryDate
                    voucherDurationType
                    voucherDurationValue
                    voucherDurationDate
                    voucherStartDate
                    maxRedeem
                    isFixedReward
                }
                networkType
                network {
                    _id
                    id
                    username
                }
                exclude {
                    _id
                    id
                    username
                }
                isMissionCodeRequired
                missionCodeType
                missionCode
                missionCodeDigits
                staffRank {
                    _id
                    id
                    name
                }
                availability
                autoComplete
                autoCompleteExpiredMission
                imageLight {
                    fileList
                    path
                }
                imageDark {
                    fileList
                    path
                }
                defaultImageLight {
                    fileList
                    path
                }
                defaultImageDark {
                    fileList
                    path
                }
                isDeleted
                createdAt
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useUpdateMissionMutation = (): {
    updateMission: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const {t} = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updateMissionMutation, {data, loading, error}] = useMutation(UPDATE_MISSION);

        const updateMission = (id, input) => {
            return updateMissionMutation({
                variables: {
                    id: id,
                    input: input,
                },
                update(cache, {data: {updateMission}}) {
                    const typename = updateMission?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateMission?.errKey),
                            key: "updateMission",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateMission?.msg),
                            key: "updateMission",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Successfully"),
                            key: "updateMission",
                        });
                    }
                },
                refetchQueries: [GET_MISSION, GET_MISSION_COUNT],
            });
        };

        return {updateMission, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            updateMission: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const UPDATE_MISSION_POSITION = gql`
    mutation updateMissionPosition($id: String!, $position: Int) {
        updateMissionPosition(id: $id, position: $position) {
            ... on Mission {
                _id
                id
                name
                position
                displayName {
                    content
                    language
                }
                description {
                    content
                    language
                }
                termAndConditionEnabled
                termAndCondition {
                    content
                    language
                }
                automationId
                continuationMission
                continuationMissionId {
                    _id
                    id
                    name
                }
                missionEnd
                missionGroup {
                    _id
                    id
                    name
                }
                conditionCalculationPeriod
                autoClaim
                product
                productId {
                    _id
                    id
                    name
                }
                missionEventDurationStartDate
                missionEventDurationEndDate
                missionDurationType
                missionDurationValue
                missionDurationDate
                rules {
                    content
                    language
                }
                isRandomReward
                rewardValue
                rewards {
                    rewardType
                    reward {
                        ... on Voucher {
                            _id
                            id
                            name
                        }
                        ... on VoucherGroup {
                            _id
                            id
                            name
                        }
                    }
                    voucherExpiryType
                    voucherExpiryValue
                    voucherExpiryDate
                    voucherDurationType
                    voucherDurationValue
                    voucherDurationDate
                    voucherStartDate
                    maxRedeem
                    isFixedReward
                }
                networkType
                network {
                    _id
                    id
                    username
                }
                exclude {
                    _id
                    id
                    username
                }
                isMissionCodeRequired
                missionCodeType
                missionCode
                missionCodeDigits
                staffRank {
                    _id
                    id
                    name
                }
                availability
                autoComplete
                autoCompleteExpiredMission
                imageLight {
                    fileList
                    path
                }
                imageDark {
                    fileList
                    path
                }
                defaultImageLight {
                    fileList
                    path
                }
                defaultImageDark {
                    fileList
                    path
                }
                isDeleted
                createdAt
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useUpdateMissionPositionMutation = (): {
    updateMissionPosition: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const {t} = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updateMissionPositionMutation, {data, loading, error}] = useMutation(UPDATE_MISSION_POSITION);

        const updateMissionPosition = (id, position) => {
            return updateMissionPositionMutation({
                variables: {
                    id: id,
                    position: position,
                },
                update(cache, {data: {updateMission}}) {
                    const typename = updateMission?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateMission?.errKey),
                            key: "updateMission",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateMission?.msg),
                            key: "updateMission",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Successfully"),
                            key: "updateMission",
                        });
                    }
                },
                refetchQueries: [GET_MISSION, GET_MISSION_COUNT],
            });
        };

        return {updateMissionPosition, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            updateMissionPosition: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const DELETE_MISSION = gql`
    mutation deleteMission($id: String!) {
        deleteMission(id: $id) {
            ... on Mission {
                _id
                id
                isDeleted
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useDeleteMissionMutation = (): {
    deleteMission: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [deleteMissionMutation, {data, loading, error}] = useMutation(DELETE_MISSION);

        const deleteMission = (id) => {
            return deleteMissionMutation({
                variables: {
                    id: id,
                },
                update(cache, {data: {deleteMission}}) {
                    const typename = deleteMission?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(deleteMission?.errKey),
                            key: "deleteMission",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(deleteMission?.msg),
                            key: "deleteMission",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Delete Successfully"),
                            key: "deleteMission",
                        });
                    }
                },
                refetchQueries: [GET_MISSION, GET_MISSION_COUNT],
            });
        };

        return {deleteMission, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            deleteMission: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const FORFEIT_USER_CLAIM_MISSION = gql`
    mutation forfeitUserClaimMission($id: String!) {
        forfeitUserClaimMission(id: $id) {
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on UserClaimMission {
                _id
            }
        }
    }
`;

async function forfeitUserClaimMission(id) {
    const variables = {
        id: id,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(FORFEIT_USER_CLAIM_MISSION, variables, true);

    return execute;
}

const REFRESH_USER_CLAIM_MISSION = gql`
    mutation refreshUserClaimMission($userid: String!) {
        refreshUserClaimMission(userid: $userid)
    }
`;

async function refreshUserClaimMission(userid) {
    const variables = {
        userid: userid,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(REFRESH_USER_CLAIM_MISSION, variables, true);

    return execute;
}

export {
    getMission,
    getMissionCount,
    getMissionDropdown,
    getAvailableUserMission,
    getUserClaimMission,
    getUserClaimMissionCount,
    useAddMissionMutation,
    useAddUserExtraMissionMutation,
    addUserClaimMission,
    updateUserClaimMission,
    userCompleteMission,
    useUpdateMissionMutation,
    useUpdateMissionPositionMutation,
    useDeleteMissionMutation,
    forfeitUserClaimMission,
    refreshUserClaimMission
};
