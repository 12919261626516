import React, {useEffect, useState} from "react";
import {Button, Row, Space, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {getCommissionLogPayment} from "../../../../../../../api/graphql/commission";
import useAuthorize from "../../../../../../../_common/authorize";
import TableAntDesign from "../../../../../../../components/table/tableAntDesign";
import {columnsCommissionLogPayment} from "../../../function/commCalculationColumns";
import moment from "moment";
import {EyeOutlined} from "@ant-design/icons";
import CommLogTable from "./commLogTable";
import EditTableUserPreferenceButton
    from "../../../../../../../components/table/function/editTableUserPreferenceButton";

const CommLogPaymentTable = (props) => {

    const {id, task, setHeaderFunction, currentPage} = props;

    const [loading, setLoading] = useState(false)
    const [batchData, setBatchData] = useState([])
    const [logPage, setLogPage] = useState(false)
    const [batchDataTotal, setBatchDataTotal] = useState(0)
    const [orderBy, setOrderBy] = useState("");
    const [assigned, setAssigned] = useState("");
    const [filter, setFilter] = useState({});
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [dateKey, setDateKey] = useState("");
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);

    const {t} = useTranslation()
    const {systemErrorNotification} = useAuthorize()
    const tableName = "Commission Log"

    const columns = columnsCommissionLogPayment

    const pageKey = 4

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize)
        setOffset(limit * (page - 1))
    }

    const preload = async () => {
        try {
            await setLoading(true)
            const response = await getCommissionLogPayment({
                batch: id,
            })

            const result = response?.getCommissionLogPayment?.data?.map((item) => {

                const AssignedUser = () => {
                    if (item?.user?.userType === "client") {
                        return (
                            <Button
                                style={{fontSize: "12px", padding: "unset"}}
                                onClick={() => window.open("/users/client/detail?id=" + item?.user?._id, "_blank")}
                                type={"link"}>{item?.user?.username}</Button>
                        )
                    } else {
                        return (
                            <Button
                                style={{fontSize: "12px", padding: "unset"}}
                                onClick={() => window.open("/users/crm-user/detail?id=" + item?.user?._id, "_blank")}
                                type={"link"}>{item?.user?.username}</Button>
                        )
                    }
                }

                return {
                    ...item,
                    rule:item?.rule?.name,
                    eWalletStatement: item?.eWalletStatement?.eWalletTransactionId,
                    batchName: item?.batch?.batchName,
                    batchTransactionName: item?.batch?.transactionName,
                    assigned: item?.user?._id,
                    rank: item?.user?.rank?.name,
                    user: <AssignedUser/>,
                    username: item?.user?.username,
                    userType: item?.user?.userType,
                    createdAt: moment(item?.createdAt).format('YYYY-MM-DD HH:mm:ss'),
                }
            })

            setBatchDataTotal(response?.getCommissionLogPayment?.total)
            setBatchData(result)
        } catch (e) {
            systemErrorNotification()
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        preload()
    }, [id])

    return (
        <>
            {
                (currentPage >= pageKey && !task) || logPage ? (
                    <>
                        <CommLogTable task={task} setLogPage={setLogPage} id={id} assigned={assigned}
                                      currentPage={currentPage} setHeaderFunction={setHeaderFunction}/>
                    </>
                ) : (
                    <>

                        {
                            !task && (
                                <Row className={"margin-bottom-0-75 display-flex"} wrap>
                                    <Space className={"margin-left-auto margin-bottom-0-3"} wrap>
                                        <EditTableUserPreferenceButton tableName={tableName} tableColumn={columns}/>
                                    </Space>
                                </Row>

                            )
                        }

                        <TableAntDesign
                            data={batchData}
                            tableName={tableName}
                            columns={[...columns, {
                                title: t("common.action"),
                                key: "action",
                                width: 100,
                                align: "left",
                                render: (_, record: any) => {
                                    return (
                                        <Space>
                                            <Tooltip title="View commission details">
                                                <Button type="link" style={{padding: "2px 4px"}}
                                                        onClick={() => {
                                                            setAssigned(record?.assigned)
                                                            if (!task) {
                                                                setHeaderFunction(4, "Assignee " + record?.username, true)
                                                            } else {
                                                                setLogPage(true)
                                                            }
                                                        }} disabled={record?.totalPayout === 0 && !task}>
                                                    <EyeOutlined/>
                                                </Button>
                                            </Tooltip>
                                        </Space>
                                    )
                                },
                            }]}
                            size={"small"}
                            isTableLocalSearch={true}
                            pagination={{
                                size: "small",
                                pageSize: 20,
                                total: batchDataTotal,
                                showTotal: (total) => <p>Total {total} items</p>
                            }}
                        />

                        {/*<TableAntDesign data={batchData}*/}
                        {/*                tableName={tableName}*/}
                        {/*                columns={[...columns, {*/}
                        {/*                    title: t("common.action"),*/}
                        {/*                    key: "action",*/}
                        {/*                    width: 100,*/}
                        {/*                    align: "left",*/}
                        {/*                    render: (_, record: any) => {*/}
                        {/*                        return (*/}
                        {/*                            <Space>*/}
                        {/*                                <Tooltip title="View commission details">*/}
                        {/*                                    <Button type="link" style={{padding: "2px 4px"}}*/}
                        {/*                                            onClick={() => {*/}
                        {/*                                                setAssigned(record?.assigned)*/}
                        {/*                                                if(!task){*/}
                        {/*                                                    setHeaderFunction(4,"Assignee " + record?.username, true)*/}
                        {/*                                                }else{*/}
                        {/*                                                    setLogPage(true)*/}
                        {/*                                                }*/}
                        {/*                                            }} disabled={record?.totalPayout === 0 && !task}>*/}
                        {/*                                        <EyeOutlined/>*/}
                        {/*                                    </Button>*/}
                        {/*                                </Tooltip>*/}
                        {/*                            </Space>*/}
                        {/*                        )*/}
                        {/*                    },*/}
                        {/*                }]}*/}
                        {/*                size={"small"}*/}
                        {/*                server={null}*/}
                        {/*                startDate={setStartDate}*/}
                        {/*                endDate={setEndDate}*/}
                        {/*                dateKey={setDateKey}*/}
                        {/*                loading={loading}*/}
                        {/*                filter={setFilter}*/}
                        {/*                order={setOrderBy}*/}
                        {/*                pagination={{*/}
                        {/*                    pageSize: limit,*/}
                        {/*                    showSizeChanger: true,*/}
                        {/*                    position: "bottomCenter",*/}
                        {/*                    total: batchDataTotal,*/}
                        {/*                    onChange: (page, pageSize) => onChangePageHandler(page, pageSize),*/}
                        {/*                    showTotal: (total) =>*/}
                        {/*                        <p>{t('pagination.totalItems', {totalCount: total})}</p>,*/}
                        {/*                }}*/}
                        {/*/>*/}
                    </>
                )
            }
        </>
    )

}

export default CommLogPaymentTable