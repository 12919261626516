import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";

const GET_PROMO_TAG_BY_ID = gql`
    query getPromoTagByID($id: String!) {
        getPromoTagByID(id: $id) {
            ... on PromoTag {
                _id
                id
                name
                displayName {
                    label
                    language
                }
                promoMaterialsCount
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getPromoTagById = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            GET_PROMO_TAG_BY_ID,
            {
                id: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );
        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_PROMO_TAG = gql`
    query getPromoTag($filter: PromoTagSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
        getPromoTag(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on PromoTagArray {
                total
                data {
                    _id
                    id
                    name
                    displayName {
                        label
                        language
                    }
                    promoMaterialsCount
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getPromoTag = async ({filter}, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_PROMO_TAG,
            {
                filter: {
                    name: filter?.name || null,
                },
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_PROMO_TAG = gql`
    mutation addPromoTag($input: PromoTagInput) {
        addPromoTag(input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on PromoTag {
                _id
                id
                name
                displayName {
                    label
                    language
                }
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const addPromoTag = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            ADD_PROMO_TAG,
            {
                input: {
                    name: input.name || null,
                    displayName: input.displayName || null,
                },
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UPDATE_PROMO_TAG = gql`
    mutation updatePromoTag($id: String!, $input: PromoTagInput) {
        updatePromoTag(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on PromoTag {
                _id
                id
                name
                displayName {
                    label
                    language
                }
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const updatePromoTag = async (id, input) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_PROMO_TAG,
            {
                id: id,
                input: {
                    name: input.name || null,
                    displayName: input.displayName || null,
                },
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {
            data: null,
            loading: false,
            error: err,
        };
    }
};

const DELETE_PROMO_TAG = gql`
    mutation deletePromoTag($id: String!) {
        deletePromoTag(id: $id) {
            ... on CrmTaskApproval {
                msg
            }
            ... on PromoTag {
                _id
                id
                isDeleted
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const deletePromoTag = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            DELETE_PROMO_TAG,
            {
                id: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {
            data: null,
            loading: false,
            error: err,
        };
    }
};

export {getPromoTagById, getPromoTag, addPromoTag, updatePromoTag, deletePromoTag};
