import React, {useEffect, useState} from "react";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import {Button, Popconfirm, Row, Space, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {columns} from "../../function/tableColumn";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../recoil_state";
import CreateUpdateModal from "./components/createUpdateModal";

const RewardSetting_1 = ({rewards, fetch, rewardOption, type, id}) => {
    const {t} = useTranslation()
    const column = columns()
    const [modalOpen, setModalOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [deleteLoading, setDeleteLoading] = useState(false)
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const handleModalClose = () => {
        setModalOpen(false)
        setCurrentItem(null)
    }

    const handleModalOpen = (item?) => {
        setModalOpen(true)
        setCurrentItem(item || null)
    }

    const handleDeleteRewardSetting = async (id) => {

    }

    useEffect(() => {
        if (type && id) {
            setModalOpen(true)
        }
    }, [type, id]);

    return (
        <div id={"reward-setting"}>
            <Row justify={"space-between"} className={"margin-bottom-0-75"}>
                <h1 className={"margin-bottom-0"}>Reward Setting</h1>
                <Button type={"primary"} onClick={() => handleModalOpen()}>{t("common.add")}</Button>
            </Row>

            <TableAntDesign
                data={rewards || []}
                columns={[
                    ...column,
                    {
                        title: t("common.action"),
                        key: "action",
                        render: (_, record) => {

                            return (
                                <Space>
                                    <Tooltip title={t("Edit")}>
                                        <a onClick={() => handleModalOpen(record)}>
                                            <EditOutlined />
                                        </a>
                                    </Tooltip>
                                    <Popconfirm
                                        placement="left"
                                        title={t("Delete Reward Setting")}
                                        description={t("Are you sure you want to delete this reward setting?")}
                                        onConfirm={() => handleDeleteRewardSetting(record?._id)}
                                        okText={t("common.yes")}
                                        okButtonProps={{loading: deleteLoading}}
                                        cancelText={t("common.no")}
                                    >
                                        <a style={{color: "red"}}><DeleteOutlined /></a>
                                    </Popconfirm>
                                </Space>
                            )
                        }
                    }
                ]}
                size={"small"}
                isTableLocalSearch={true}
                tableName={"Arena Gift Setting"}
            />

            <CreateUpdateModal
                open={modalOpen}
                handleClose={handleModalClose}
                item={currentItem}
                fetchRewards={fetch}
                rewardOption={rewardOption}
                preSelectType={type}
                preSelectId={id}
            />
        </div>
    )
}

export default RewardSetting_1