import React, {useEffect, useState} from "react";
import {Form, Input, Modal, Radio, Select} from "antd";
import {useTranslation} from "react-i18next";
import TextArea from "antd/es/input/TextArea";
import {labelCol, validateMessages} from "../../../../../function";

const GeneralListFieldForm = (props) => {
    const {isAdd, isOpen, setIsOpen, submitLoading, submitField, config, item, form, isView} = props
    const {t} = useTranslation()
    const [fieldType, setFieldType] = useState<any>()
    const [fieldPreview, setFieldPreview] = useState<any>(null)
    const valueAvailableType = ["select", "multiSelect", "radio"]

    const fieldValuePreviewFormatter = async () => {
        const value = form.getFieldValue("fieldValue")
        const options = await value?.split(",").map((item) => item.trim())?.filter(item => item?.length > 0)?.map(d => {
            return {label: d, value: d}
        })
        switch (fieldType) {
            case "select":
                setFieldPreview(<Select options={options}/>)
                break
            case "multiSelect":
                setFieldPreview(<Select mode={"multiple"} options={options}/>)
                break
            case "radio":
                setFieldPreview(<Radio.Group options={options}/>)
                break
        }
    }

    useEffect(() => {
        setFieldPreview(null)
        setFieldType(item?.fieldType || "")
    }, [isOpen])

    return (
        <Modal
            title={t(isAdd ? "accountVerification.addField" : isView ? "accountVerification.field" : "accountVerification.updateField")}
            open={isOpen}
            onCancel={() => setIsOpen(false)}
            onOk={form.submit}
            cancelText={t("common.cancel")}
            okText={isAdd ? t("common.add") : t("common.update")}
            okButtonProps={{loading: submitLoading}}
            footer={isView ? null : undefined}
            destroyOnClose={true}
        >
            <Form form={form} validateMessages={validateMessages} onFinish={submitField}>
                <Form.Item
                    label={t("accountVerification.fieldName")}
                    name={"name"}
                    labelCol={labelCol}
                    initialValue={item?.name}
                    rules={[{required: true}]}
                >
                    <Input placeholder={t("accountVerification.placeholder.fieldName").toString()} disabled={isView}/>
                </Form.Item>
                <Form.Item
                    label={t("accountVerification.tag")}
                    name={"tag"}
                    labelCol={labelCol}
                    initialValue={item?.tag}
                    rules={[{required: true}]}
                >
                    <Select
                        placeholder={t("accountVerification.placeholder.tag")}
                        options={config?.tag?.map(d => {
                            return {label: d, value: d}
                        })}
                        disabled={isView}
                    />
                </Form.Item>
                <Form.Item
                    label={t("accountVerification.fieldType")}
                    name={"fieldType"}
                    labelCol={labelCol}
                    initialValue={item?.fieldType}
                    rules={[{required: true}]}
                >
                    <Select
                        placeholder={t("accountVerification.placeholder.fieldType")}
                        options={config?.fieldType?.filter(item => item !== "group")?.map(d => {
                            return {label: t(`accountVerification.fieldTypeOption.${d}`), value: d}
                        })?.concat(...[{
                            label: t("accountVerification.fieldTypeOption.country"),
                            value: "country"
                        }, {label: t("accountVerification.fieldTypeOption.nationality"), value: "nationality"}])}
                        onSelect={(value => setFieldType(value))}
                        disabled={isView}
                        showSearch={true}
                        optionFilterProp={"label"}
                    />
                </Form.Item>
                {(valueAvailableType?.includes(fieldType)) &&
                <>
                    <Form.Item
                        label={t("accountVerification.fieldValue")}
                        name={"fieldValue"}
                        labelCol={labelCol}
                        initialValue={item?.fieldValue?.map(d => d?.value)?.join(", ")?.toString()}
                    >
                        <TextArea
                            placeholder={t("accountVerification.placeholder.fieldValue").toString()}
                            disabled={isView}
                        />
                    </Form.Item>
                    <a onClick={() => fieldValuePreviewFormatter()}>{fieldPreview ? t("accountVerification.refreshFieldPreview") : t("accountVerification.showFieldPreview")}</a>
                    {fieldPreview &&
                    <Form.Item labelCol={labelCol}>{fieldPreview}</Form.Item>}
                </>
                }

                {/*<Form.Item*/}
                {/*    label={t("accountVerification.isEnabled")}*/}
                {/*    name={"isEnabled"}*/}
                {/*    colon={false}*/}
                {/*    initialValue={item?.isEnabled}*/}
                {/*    style={{textAlign: "right"}}*/}
                {/*    className={"margin-top-0-75"}*/}
                {/*>*/}
                {/*    <Switch defaultChecked={item?.isEnabled}/>*/}
                {/*</Form.Item>*/}
            </Form>
        </Modal>
    )
}

export default GeneralListFieldForm