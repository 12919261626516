import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Tag} from "antd";
import {getColumnSearchProps} from "../../../../components/table/function/filterTable";

export const columnsCampaignTag = () => {
    const {t} = useTranslation();
    const language = localStorage.getItem("language");

    return [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: "Display Name",
            dataIndex: "displayName",
            key: "displayName",
            render: (displayName) => {
                let displayText = displayName?.find((d) => d?.language === language)?.label || displayName?.[0]?.label;
                return <p>{displayText || "-"}</p>;
            },
        },
        {
            title: "Promo Materials Count",
            dataIndex: "promoMaterialsCount",
            key: "promoMaterialsCount",
            render: (text) => <p>{text}</p>,
        },
    ];
};
