import {Form, Select} from "antd";
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import FormErrors from "./formErrors";

const SelectFormItem = (props) => {
    const {
        layout,
        size,
        label,
        name,
        externalErrorMessage,
        onChange,
        hint,
        disabled,
        required,
        options,
        mode,
        showSearch,
        showArrow,
        allowClear,
        placeholder,
        filterOption,
        optionFilterProp,
        labelCol,
        wrapperCol,
        style,
        value,
        labelAlign,
        colon,
        optionLabelProp,
        getPopupContainer,
        loading,
        filterSort,
        className,
        maxTagCount,
    } = props;

    const {
        register,
        watch,
        setValue,
        formState: {touchedFields, isSubmitted, errors},
    } = useFormContext();

    const errorMessage = FormErrors.errorMessage(name, label, errors, touchedFields, isSubmitted, externalErrorMessage);

    const formHelperText = errorMessage || hint;

    useEffect(() => {
        register(name);
    }, [register, name]);

    useEffect(() => {
        if (value) {
            setValue(name, value, {shouldValidate: true});
        }
    }, [value]);

    return (
        <Form.Item
            {...layout}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            label={label}
            labelAlign={labelAlign}
            colon={colon}
            required={required}
            validateStatus={errorMessage ? "error" : "success"}
            help={errorMessage}
            className={className}
            labelWrap
        >
            <Select
                id={name}
                mode={mode}
                loading={loading}
                options={options}
                value={watch(name)}
                optionFilterProp={optionFilterProp}
                optionLabelProp={optionLabelProp}
                filterSort={filterSort}
                onChange={(e) => {
                    setValue(name, e, {shouldValidate: true});
                    onChange && onChange(e);
                }}
                filterOption={filterOption}
                placeholder={placeholder}
                showSearch={showSearch}
                showArrow={showArrow}
                allowClear={allowClear}
                disabled={disabled}
                style={style}
                size={size}
                getPopupContainer={getPopupContainer || undefined}
                maxTagCount={maxTagCount}
                // getPopupContainer={(triggerNode) => triggerNode.parentElement}
            />
        </Form.Item>
    );
};

SelectFormItem.defaultProps = {
    required: false,
    disabled: false,
    colon: false,
    size: "middle",
    labelCol: {span: 24, offset: 0},
};

SelectFormItem.propTypes = {
    label: PropTypes.string,
    labelAlign: PropTypes.string,
    wrapperCol: PropTypes.object,
    name: PropTypes.string.isRequired,
    externalErrorMessage: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    layout: PropTypes.object,
    hint: PropTypes.string,
    options: PropTypes.array,
    showSearch: PropTypes.bool,
    showArrow: PropTypes.bool,
    allowClear: PropTypes.bool,
    placeholder: PropTypes.string,
    mode: PropTypes.string,
    filterOption: PropTypes.func,
    style: PropTypes.object,
    value: PropTypes.any,
    optionFilterProp: PropTypes.string,
    optionLabelProp: PropTypes.string,
    getPopupContainer: PropTypes.any,
    filterSort: PropTypes.func,
    className: PropTypes.string,
    maxTagCount: PropTypes.number,
};

export default SelectFormItem;
