import React from "react";
import podium from "../../../assets/podium.png"
import {Col, Image, Row} from "antd";
import rankingBG2 from "../../../assets/ranking-bg2.png";
import legendary from "../../../assets/avatar/legendary.png"
import commander from "../../../assets/avatar/commander.png"
import master from "../../../assets/avatar/master.png"
import {formatMoney} from "../../../function";

const PodiumComponent = () => {

    return (
        <div className={"podium-component text-align-center"}>
            <Row gutter={8} justify={"space-evenly"} align={"bottom"}>
                <Col style={{marginBottom: -16}}>
                    <div className={"legend-image-container"} style={{
                        background: `center / contain no-repeat url(${rankingBG2})`,
                        backgroundSize: "contain",
                        height: 100,
                        width: 100,
                        display: "flex"
                    }}>
                        <Image
                            width={80}
                            height={80}
                            preview={false}
                            src={commander}
                        />
                    </div>

                    <h1 className={"margin-top-0-3"}><strong>DUMMY 1</strong></h1>
                    <p>(Commander)</p>
                    <p className={"amount"}>${formatMoney(40000, 0)}</p>
                </Col>
                <Col>
                    <div className={"legend-image-container"} style={{
                        background: `center / contain no-repeat url(${rankingBG2})`,
                        backgroundSize: "contain",
                        height: 140,
                        width: 140,
                        display: "flex"
                    }}>
                        <Image
                            width={130}
                            height={130}
                            preview={false}
                            src={legendary}
                        />
                    </div>

                    <h1 className={"margin-top-0-3"}><strong>DUMMY 7</strong></h1>
                    <p>(Legendary)</p>
                    <p className={"amount"}>${formatMoney(50000, 0)}</p>
                </Col>
                <Col style={{marginBottom: -20}}>
                    <div className={"legend-image-container"} style={{
                        background: `center / contain no-repeat url(${rankingBG2})`,
                        backgroundSize: "contain",
                        height: 100,
                        width: 100,
                        display: "flex"
                    }}>
                        <Image
                            width={80}
                            height={80}
                            preview={false}
                            src={master}
                        />
                    </div>

                    <h1 className={"margin-top-0-3"}><strong>DUMMY 2</strong></h1>
                    <p>(Master)</p>
                    <p className={"amount"}>${formatMoney(30000, 0)}</p>
                </Col>
            </Row>
            <Image
                src={podium}
                width={"100%"}
                preview={false}
            />
        </div>
    )
}

export default PodiumComponent