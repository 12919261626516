import React from "react";
import {useTranslation} from "react-i18next";
import LeadsTopBar from "../../../_general/component/leadsTopBar";
import {useRecoilValue} from "recoil";
import {selfPermissionObj} from "../../../../../../../recoil_state";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import {pendingListColumns} from "../../function/tableColumns";
import {UndoOutlined} from "@ant-design/icons";
import {Tooltip} from "antd";

const PendingList_1 = ({tableName, tableDisplayName, data, total, loading, limit, setLimit, setOffset, setFilter, fetchData}) => {
    const {t} = useTranslation();
    const permission = useRecoilValue<any>(selfPermissionObj)
    const column = pendingListColumns()

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize);
        setOffset(limit * (page - 1));
    };

    return (
        <>
            <h1>{t(tableDisplayName)} <Tooltip title={t("common.refresh")}><a onClick={() => fetchData()}><UndoOutlined/></a></Tooltip></h1>
            <LeadsTopBar tableName={tableName}
                         exportData={data}
                         exportColumn={column}
                         permission={permission?.user?.leads}
            />

            <TableAntDesign data={data}
                            tableName={tableName}
                            columns={column}
                            size={"small"}
                            loading={loading}
                            pagination={{
                                pageSize: limit,
                                showSizeChanger: true,
                                onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                                total: total,
                                showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                            }}
                            filter={setFilter}
            />
        </>
    )
}

export default PendingList_1