import React, {useEffect, useState} from "react";
import {Button, Divider, Row, Switch} from "antd";
import {useTranslation} from "react-i18next";
import {
    getTradeIdeaSetting,
    useUpdateTradeIdeaSettingMutation
} from "../../../../../../../api/graphql/tradeIdeaSetting";
import useAuthorize from "../../../../../../../_common/authorize";

const TradeIdeaSetting = (props) => {
    const {setCustomizeSubmitButton, close, updateViewableRank, viewableRank} = props
    const {t} = useTranslation()

    const {data} = getTradeIdeaSetting()
    const {updateTradeIdeaSetting} = useUpdateTradeIdeaSettingMutation()
    const {systemErrorNotification} = useAuthorize()

    const [input, setInput] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false)

    const onSubmit = async () => {
        try {
            setLoading(true)

            await Promise.all([updateViewableRank(), updateTradeIdeaSetting(input)])
            close()
        } catch (e) {
            systemErrorNotification()
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        setInput({
            brandMappedDataOnly: data?.getTradeIdeaSetting?.brandMappedDataOnly,
        })
    }, [data?.getTradeIdeaSetting])

    useEffect(() => {
        setCustomizeSubmitButton(
            <Button onClick={onSubmit} loading={loading} type={"primary"}>{t("common.submit")}</Button>
        )
    }, [input, loading, viewableRank])

    return (
        <>
            <Row className={"extra-form margin-top-0-75"} justify={"space-between"} style={{padding: 16}}>
                <label>{t("Include ONLY Brand Mapped Data")}</label>
                <Switch
                    checked={input?.brandMappedDataOnly}
                    onChange={(value) => setInput({brandMappedDataOnly: value})}
                />
            </Row>
        </>
    )
}

export default TradeIdeaSetting