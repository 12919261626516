import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {useTranslation} from "react-i18next";
import VirtualList from "rc-virtual-list";
import {Button, Col, Empty, List, Popconfirm, Row, Space, Tooltip} from "antd";
import DocumentsEditingForm from "./documentsEditingForm";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {getLegalDocumentDataWithLanguage} from "../../../../brandDetails/function/common";
import {deleteMamPammLegalDocument} from "../../../../../../api/graphql/mamPammLegalDocument";
import useAuthorize from "../../../../../../_common/authorize";
import {notificationMessage} from "../../../../../../../recoil_state";

const DocumentList = (props) => {
    const {data, languageCode, isEditable, language, getData, permission} = props;
    const {t} = useTranslation();
    const {systemErrorNotification} = useAuthorize();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const ContainerHeight = "65vh";
    const [legalDocumentData, setLegalDocumentData] = useState<any>({});

    const [isEditing, setIsEditing] = useState<any>("");

    useEffect(() => {
        if (data) setLegalDocumentData(data);
    }, [data]);

    const deleteDocument = async (documentId) => {
        try {
            const res = await deleteMamPammLegalDocument(documentId);
            const response = res?.deleteMamPammLegalDocument;
            if (response?.__typename === "MamPammLegalDocument") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("common.deleteSuccess"),
                    key: "deleteMamPammLegalDocument",
                });
                getData();
            } else if (response?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.errKey),
                    key: "deleteMamPammLegalDocument",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.msg),
                    key: "deleteMamPammLegalDocument",
                });
            }
        } catch (error: any) {
            systemErrorNotification();
        }
    };

    return (
        <>
            <Row gutter={[16, 16]} style={{marginTop: "30px"}}>
                <Col span={12}>
                    {legalDocumentData?.length === 0 && <Empty className="margin-top-0-75" />}
                    <List>
                        <VirtualList data={data} style={{height: ContainerHeight, overflowY: "auto", marginTop: 16}} itemKey="documents">
                            {(item, index) => (
                                <List.Item key={index} style={{padding: 4}}>
                                    <Row
                                        justify={"space-between"}
                                        align={"middle"}
                                        style={{
                                            width: "100%",
                                            padding: "8px 0 8px 16px",
                                            borderRadius: 8,
                                            backgroundColor: isEditing === item?.name ? "#f1f1f1" : "transparent",
                                            transition: ".5s",
                                        }}
                                    >
                                        <h4
                                            style={{
                                                color: isEditing === item.name ? "#1677ff" : "inherit",
                                                marginBottom: 0,
                                                transition: ".5s",
                                            }}
                                        >
                                            {index + 1}.{" "}
                                            {getLegalDocumentDataWithLanguage(
                                                legalDocumentData,
                                                `${item.name}_displayName_${languageCode}`,
                                                "content",
                                                languageCode
                                            ) || item.name}
                                        </h4>
                                        <Space>
                                            {isEditable ? (
                                                <>
                                                    {permission?.delete?.delete && (
                                                        <Tooltip title={t("common.delete")}>
                                                            <Popconfirm
                                                                placement="left"
                                                                title={t("legalDocument.delete.popconfirm.title")}
                                                                description={t("legalDocument.delete.popconfirm.description")}
                                                                onConfirm={() => deleteDocument(item?._id)}
                                                                okText={t("common.yes")}
                                                                cancelText={t("common.no")}
                                                            >
                                                                <Button type={"link"} danger icon={<DeleteOutlined />} />
                                                            </Popconfirm>
                                                        </Tooltip>
                                                    )}
                                                    {isEditable && (
                                                        <Tooltip title={t("common.edit")}>
                                                            <Button
                                                                onClick={() => setIsEditing(item?._id)}
                                                                type={"link"}
                                                                style={{marginRight: 16}}
                                                                icon={<EditOutlined />}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </>
                                            ) : (
                                                <Tooltip title={t("common.view")}>
                                                    <Button
                                                        onClick={() => setIsEditing(item?._id)}
                                                        type={"link"}
                                                        style={{marginRight: 16}}
                                                        icon={<EyeOutlined />}
                                                    />
                                                </Tooltip>
                                            )}
                                        </Space>
                                    </Row>
                                </List.Item>
                            )}
                        </VirtualList>
                    </List>
                </Col>
                {data?.map((d) => {
                    return (
                        <Col span={12} key={d?.name} style={{display: isEditing === d._id ? "block" : "none"}}>
                            <DocumentsEditingForm
                                item={d}
                                isEditing={isEditing}
                                language={language}
                                getLegalDocumentDataWithLanguage={getLegalDocumentDataWithLanguage}
                                isEditable={isEditable}
                                getData={getData}
                            />
                        </Col>
                    );
                })}
            </Row>
        </>
    );
};

export default DocumentList;
