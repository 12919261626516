import React from "react";
import {useRecoilState} from "recoil";
import DynamicEmailComponentPage_1 from "./view/layout1";
import {columns} from "./function/tableColumn";
import {getDynamicEmailComponent} from "../../../api/graphql/dynamicEmailComponent";
import {getLanguage} from "../../../api/graphql/language";
import {sortEn} from "../../../function/language";
import {selfPermissionObj} from "../../../../recoil_state";

function DynamicEmailComponentPage() {
  let layout = 1;
  let component;
  const [selfPermission] = useRecoilState<any>(selfPermissionObj);
  const dynamicEmailComponentPermission =
    selfPermission?.messageSetting?.emailTemplate;
  const { componentData, loading, error } = getDynamicEmailComponent({
    filter: null,
  });
  const { languageData } = getLanguage({ filter: null });

  switch (layout) {
    case 1:
      component = (
        <DynamicEmailComponentPage_1
          data={componentData?.getDynamicEmailComponent?.data}
          languageData={sortEn(languageData?.getLanguage?.data)}
          columns={columns}
          loading={loading}
          error={error}
          dynamicEmailComponentPermission={dynamicEmailComponentPermission}
        />
      );
      break;

    default:
      component = (
        <DynamicEmailComponentPage_1
          data={componentData?.getDynamicEmailComponent?.data}
          languageData={sortEn(languageData?.getLanguage?.data)}
          columns={columns}
          loading={loading}
          error={error}
          dynamicEmailComponentPermission={dynamicEmailComponentPermission}
        />
      );
      break;
  }

  return component;
}

export default DynamicEmailComponentPage;
