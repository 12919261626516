import axios from "axios";
import {getConfigByName} from "../../../../config";
import {BRAND_NAME} from "../../../../config/brandVariable";

const url = getConfigByName(BRAND_NAME).automationURL
const config = {headers: {"Content-Type": "text/plain"}}

export async function getCustomAction(param = {}) {
    try {
        const filter = {filter: param};
        return await axios.post(`${url}/api/custom_action/get`, JSON.stringify(filter), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export async function createCustomAction (input) {
    try {
        return await axios.post(`${url}/api/custom_action/create`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export async function updateCustomAction (input) {
    try {
        return await axios.post(`${url}/api/custom_action/update`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}