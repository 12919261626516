import React, {useEffect, useState} from "react";
import {Button, Checkbox, Col, Divider, Empty, Image, Popconfirm, Row, Segmented, Space, Tag, Tooltip, Upload} from "antd";
import {useTranslation} from "react-i18next";
import "./css/style.css"
import {getConfigByName} from "../../../../../../config";
import {BRAND_NAME} from "../../../../../../config/brandVariable";
import _ from "lodash"
import CreateFolderModal from "./component/createFolderModal";
import UploadModal from "./component/uploadModal";
import PreviewModal from "./component/previewModal";
import {TbEdit, TbFolderSymlink} from "react-icons/tb";
import {useRecoilState, useSetRecoilState} from "recoil";
import {selectedMediaState} from "../state";
import {CloseOutlined, DeleteOutlined, FileOutlined, FileSyncOutlined, FolderAddOutlined, FolderFilled, UploadOutlined} from "@ant-design/icons";
import {deleteMedia, hardDeleteMedia, restoreMedia, updateMedia, uploadMedia} from "../../../../../api/restful/arena/media_file";
import {notificationMessage} from "../../../../../../recoil_state";
import {deleteFolder, hardDeleteFolder, restoreFolder} from "../../../../../api/restful/arena/media_folder";
import MoveFolderModal from "./component/moveFolderModal";
import RenameFolderModal from "./component/renameFolderModal";
import OuterUploadDragger from "./component/outerUploadDragger";

const MediaLibrary_1 = (props) => {
    const {media, folders, fetchMedia, fetchFolder, refetchMedia, refetchFolder} = props
    const {t} = useTranslation()
    const url = getConfigByName(BRAND_NAME).arenaURL
    const [currentPath, setCurrentPath] = useState<any>(["All Files"])
    const [viewingFolders, setViewingFolders] = useState<any>([])
    const [viewingMedia, setViewingMedia] = useState<any>([])
    const [loading, setLoading] = useState(true)
    const [createFolderModalIsOpen, setCreateFolderModalIsOpen] = useState(false)
    const [uploadMediaModalIsOpen, setUploadMediaModalIsOpen] = useState(false)
    const [previewModalIsOpen, setPreviewModalIsOpen] = useState(false)
    const [foldersModalOpen, setFoldersModalOpen] = useState(false)
    const [renameFolderModalIsOpen, setRenameFolderModalIsOpen] = useState(false)
    const [currentFile, setCurrentFile] = useState<any>(null)
    const folderOption = folders?.map(d => ({label: d?.name, value: d?._id}))
    const [selected, setSelected]: any[] = useRecoilState(selectedMediaState)
    const [currentView, setCurrentView] = useState("All Files")
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const useManyClickHandlers = (...handlers: Array<(e: React.UIEvent<HTMLElement>, type, item?: any) => void>) => {
        const callEventHandler = (e: React.UIEvent<HTMLElement>, type, item?: any) => {
            if (e.detail <= 0) return
            const handler = handlers[e.detail - 1]
            if (handler) {
                handler(e, type, item)
            }
        }

        const debounceHandler = _.debounce(function (e: React.UIEvent<HTMLElement>, type, item?: any) {
            callEventHandler(e, type, item)
        }, 200)

        return (e: React.UIEvent<HTMLElement>, type, item?: any) => {
            e.persist()
            debounceHandler(e, type, item)
        }
    }

    const singleClickHandler = (_, type, item?: any) => {
        onCheckHandler(item?._id, !selected.includes(item?._id))
    }
    const doubleClickHandler = (_, type, item?: any) => {
        if (type === "folder" && item) {
            if (item.parent === currentPath[currentPath.length - 1]?.parent) {
                setCurrentPath(prev => [...prev.slice(0, -1), item])
            } else {
                setCurrentPath(prev => [...prev, item])
            }
        }

        if (type === "file" && item) {
            setCurrentFile(item)
            setPreviewModalIsOpen(true)
        }
    }

    const clickHandler = useManyClickHandlers(singleClickHandler, doubleClickHandler)

    const processCurrentViewingFiles = () => {

        setLoading(true)
        const current = currentPath[currentPath.length - 1]
        if (current === "All Files") {
            setViewingFolders(folders.filter(d => !d.parent))
            setViewingMedia(media.filter(d => !d.folder))
        } else {
            setViewingFolders(folders.filter(d => d.parent === current._id))
            setViewingMedia(media.filter(d => d.folder === current._id))
        }
        setLoading(false)
    }

    const onPathClick = (item) => {
        if (item === "All Files") setCurrentPath(["All Files"])
        else setCurrentPath(prev => {
            const index = prev.findIndex(d => d._id === item._id)
            return prev.slice(0, index + 1)
        })
    }

    const onCheckHandler = (id, checked) => {
        setSelected((prev: any) => checked ? [...prev, id] : prev.filter((item) => item !== id))
    }

    const handleDeleteFiles = async () => {
        try {
            const {selectedMedia, selectedFolder} = splitSelected()

            const promises = [
                selectedMedia.length > 0 ? deleteMedia(selectedMedia) : Promise.resolve(true),
                selectedFolder.length > 0 ? deleteFolder(selectedFolder) : Promise.resolve(true),
            ];

            const results: any[] = await Promise.allSettled(promises);

            const successAry = results.filter(r => r.status === "fulfilled");
            const failedAry = results.filter(r => r.status === "rejected").map(r => {
                const validArray = JSON.parse(`[${r.reason?.response?.data.replace(/}{/g, "},{")}]`);
                return r.reason?.response?.data?.errObj || validArray?.[0] || "Unknown Error"
            })


            if (successAry.length > 0 && failedAry.length === 0) {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: `Delete Items Successfully`,
                    key: "arenaMediaLibraryNotification",
                });
                refetchMedia()
                refetchFolder()
                setSelected([])

            } else if (successAry.length > 0) {
                setNotificationMessage({
                    status: "warning",
                    title: "",
                    msg: `Delete Items Success With Error: ${failedAry.join(", ")}`,
                    key: "arenaMediaLibraryNotification",
                });
                refetchMedia()
                refetchFolder()
                setSelected([])

            } else if (failedAry.length > 0) {
                setNotificationMessage({
                    status: "warning",
                    title: "",
                    msg: `Delete Items Failed: ${failedAry.join(", ")}`,
                    key: "arenaMediaLibraryNotification",
                });
            }
        } catch (e) {
        }
    }

    const handleReplaceImage = async (file, fileList, id) => {
        const formData = new FormData()
        formData.append("id", id)

        if (fileList && fileList.length > 0) {
            formData.append("file", fileList[0])
        }

        try {
            const response = await updateMedia(formData)

            if (response.status === "Success") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Update Media Successfully",
                    key: "arenaMediaLibraryNotification",
                })

                refetchMedia()
            } else {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: `Update Media Failed: ${response?.errObj}`,
                    key: "arenaMediaLibraryNotification",
                })
            }
        } catch (e) {
        }

        return false
    };

    const daysLeft = (dateString: string): number => {
        const startDate = new Date(dateString)
        if (isNaN(startDate.getTime())) {
            throw new Error("Invalid date string provided")
        }

        const targetDate = new Date(startDate)
        targetDate.setDate(targetDate.getDate() + 30)

        const currentDate = new Date()
        const diffInTime = targetDate.getTime() - currentDate.getTime()
        const diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24))

        return diffInDays > 0 ? diffInDays : 0
    }

    const splitSelected = () => {
        const selectedMedia = selected.filter(d => viewingMedia.map(m => m._id).includes(d))
        const selectedFolder = selected.filter(d => viewingFolders.map(m => m._id).includes(d))

        return {selectedMedia, selectedFolder}
    }

    const handleRestoreItems = async () => {
        const {selectedMedia, selectedFolder} = splitSelected()

        const promises = [
            selectedMedia.length > 0 ? restoreMedia(selectedMedia) : Promise.resolve(true),
            selectedFolder.length > 0 ? restoreFolder(selectedFolder) : Promise.resolve(true),
        ];

        const results: any[] = await Promise.allSettled(promises);

        const successAry = results.filter(r => r.status === "fulfilled");
        const failedAry = results.filter(r => r.status === "rejected").map(r => {
                const validArray = JSON.parse(`[${r.reason?.response?.data.replace(/}{/g, "},{")}]`);
                return r.reason?.response?.data?.errObj || validArray?.[0] || "Unknown Error"
            })


        if (successAry.length > 0 && failedAry.length === 0) {
            setNotificationMessage({
                status: "success",
                title: "",
                msg: `Restore Items Successfully`,
                key: "arenaMediaLibraryNotification",
            });
            refetchMedia()
            refetchFolder()
            setSelected([])

        } else if (successAry.length > 0) {
            setNotificationMessage({
                status: "warning",
                title: "",
                msg: `Restore Items Success With Error: ${failedAry.join(", ")}`,
                key: "arenaMediaLibraryNotification",
            });
            refetchMedia()
            refetchFolder()
            setSelected([])

        } else if (failedAry.length > 0) {
            setNotificationMessage({
                status: "warning",
                title: "",
                msg: `Restore Items Failed: ${failedAry.join(", ")}`,
                key: "arenaMediaLibraryNotification",
            });
        }
    }

    const handleHardDeleteItems = async () => {
        const {selectedMedia, selectedFolder} = splitSelected()

        const promises = [
            selectedMedia.length > 0 ? hardDeleteMedia(selectedMedia) : Promise.resolve(true),
            selectedFolder.length > 0 ? hardDeleteFolder(selectedFolder) : Promise.resolve(true),
        ];

        const results: any[] = await Promise.allSettled(promises);

        const successAry = results.filter(r => r.status === "fulfilled");
        const failedAry = results.filter(r => r.status === "rejected").map(r => {
                const validArray = JSON.parse(`[${r.reason?.response?.data.replace(/}{/g, "},{")}]`);
                return r.reason?.response?.data?.errObj || validArray?.[0] || "Unknown Error"
            })


        if (successAry.length > 0 && failedAry.length === 0) {
            setNotificationMessage({
                status: "success",
                title: "",
                msg: `Delete Items Successfully`,
                key: "arenaMediaLibraryNotification",
            });
            refetchMedia()
            refetchFolder()
            setSelected([])

        } else if (successAry.length > 0) {
            setNotificationMessage({
                status: "warning",
                title: "",
                msg: `Delete Items Success With Error: ${failedAry.join(", ")}`,
                key: "arenaMediaLibraryNotification",
            });
            refetchMedia()
            refetchFolder()
            setSelected([])

        } else if (failedAry.length > 0) {
            setNotificationMessage({
                status: "warning",
                title: "",
                msg: `Delete Items Failed: ${failedAry.join(", ")}`,
                key: "arenaMediaLibraryNotification",
            });
        }
    }

    const handleUploadMedia = async (file, fileList) => {
        const formData = new FormData()

        formData.append("folder", currentPath.at(-1)?._id || "")
        if (fileList && fileList.length > 0) {
            formData.append("file", fileList[0])
        }

        try {
            const response = await uploadMedia(formData)

            if (response.status === "Success") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Upload Media Successfully",
                    key: "arenaMediaLibraryNotification",
                })

                refetchMedia()
            } else {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: `Upload Media Failed: ${response?.errObj}`,
                    key: "arenaMediaLibraryNotification",
                })
            }
        } catch (e) {
        }

        return false
    }

    useEffect(() => {
        if (media && folders) processCurrentViewingFiles()
    }, [currentPath, media, folders]);

    useEffect(() => {
        setSelected([])
    }, [currentPath]);

    return (
        <div id={"media-library"}>
            <OuterUploadDragger media={media} folders={folders} currentView={currentView} currentPath={currentPath} refetchMedia={refetchMedia}>
                <div className={`wrapper margin-bottom-0-75`}>
                    <Row justify={"space-between"} align={"middle"} className={"filter-row"}>
                        {currentView === "All Files" ?
                            <div>
                                {currentPath.slice(0, -1).map((d: any) => {
                                    if (d === "All Files") return <a onClick={() => onPathClick("All Files")} className={"path"}>All Files</a>
                                    else return <> &nbsp;/&nbsp; <a onClick={() => onPathClick(d)} className={"path"}>{d?.name}</a></>
                                })}
                                {
                                    <h1 className={"path current"}>{currentPath[currentPath.length - 1]?.name || "All Files"}</h1>
                                }
                            </div> :
                            <div>
                                <h1 className={"path current"}>Deleted Files</h1>
                                <p>You can restore any file deleted in the last 30 days.</p>
                            </div>
                        }
                        <Space>
                            {
                                currentView === "All Files" &&
                                <>
                                    <Button onClick={() => setCreateFolderModalIsOpen(true)} icon={<FolderAddOutlined />}>Create Folder</Button>

                                    <Upload
                                        beforeUpload={handleUploadMedia}
                                        showUploadList={false}
                                        maxCount={1}
                                        accept="image/png, image/jpeg, image/gif, image/webp, image/svg+xml"
                                    >
                                    <Button icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </>
                            }
                            <Segmented
                                options={[
                                    {value: 'All Files', icon: <FileOutlined/>, label: "All Files"},
                                    {value: 'Deleted Files', icon: <DeleteOutlined/>, label: "Deleted Files"}
                                ]}
                                onChange={(value) => setCurrentView(value)}
                            />
                        </Space>
                    </Row>
                    <Row align={"middle"} className={`select-row ${selected?.length > 0 ? "show" : ""}`}>
                        <Space>
                            <Button type={"text"} icon={<CloseOutlined/>} onClick={() => setSelected([])}/>
                            <p className={"margin-bottom-0"}>Selected {selected?.length}</p>

                            <Divider type={"vertical"}/>
                            {
                                currentView === "All Files" &&
                                <Tooltip title={"Delete"}>
                                    <Popconfirm
                                        title={`Are you sure you want to delete ${selected.length > 1 ? "these items" : "this file"}?`}
                                        okText={t("common.delete")}
                                        okButtonProps={{danger: true}}
                                        onConfirm={handleDeleteFiles}
                                    >
                                        <Button type={"text"} icon={<DeleteOutlined/>}/>
                                    </Popconfirm>
                                </Tooltip>
                            }
                            {
                                currentView === "Deleted Files" &&
                                <Space>
                                    <Tooltip title={"Restore"}>
                                        <Button onClick={handleRestoreItems} type={"text"} icon={<FileSyncOutlined/>}/>
                                    </Tooltip>
                                    <Tooltip title={"Permanently Delete"}>
                                        <Popconfirm
                                            title={`Are you sure you want to permanently delete ${selected.length > 1 ? "these items" : "this file"}?`}
                                            okText={t("common.delete")}
                                            okButtonProps={{danger: true}}
                                            onConfirm={handleHardDeleteItems}
                                        >
                                            <Button type={"text"} icon={<DeleteOutlined/>}/>
                                        </Popconfirm>
                                    </Tooltip>
                                </Space>
                            }
                        </Space>
                    </Row>
                </div>
                <>
                    {
                        viewingFolders?.filter(d => currentView === "All Files" ? !d?.isDeleted : d?.isDeleted)?.length > 0 ||
                        viewingMedia?.filter(d => currentView === "All Files" ? !d?.isDeleted : d?.isDeleted)?.length > 0 ? (
                            <Row gutter={[8, 8]}>
                                {
                                    viewingFolders?.filter(d => currentView === "All Files" ? !d?.isDeleted : d?.isDeleted).map(folder => (
                                        <Col xs={24} sm={12} md={8} lg={6} xl={4} key={folder?._id}
                                             className={`media-item-wrapper ${selected.includes(folder?._id) ? "checked" : ""}`}>
                                            <Checkbox
                                                className="action-row first"
                                                checked={selected.includes(folder?._id)}
                                                onChange={(e) => onCheckHandler(folder?._id, e.target.checked)}
                                            />

                                            {
                                                currentView === "All Files" ?
                                                    <Space className="action-row second">
                                                        <Tooltip title={t("Rename")}>
                                                            <Button
                                                                icon={<TbEdit/>}
                                                                onClick={() => {
                                                                    setCurrentFile(folder)
                                                                    setRenameFolderModalIsOpen(true)
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </Space> :
                                                    <Tag color={"geekblue"} bordered={false} className="action-row second margin-top-0-3">
                                                        {daysLeft(folder?.deletedAt)} Days
                                                    </Tag>
                                            }

                                            <div className={"media-item"} onClick={(e) => clickHandler(e, "folder", folder)}>
                                                <div className={"media-item-container"}>
                                                    <FolderFilled className={"default-icon folder-icon"}/>
                                                </div>
                                            </div>
                                            <Space align={"center"}>
                                                <FolderFilled className={"type-icon folder-icon"}/>
                                                <div>
                                                    <p className={"path"} onClick={() => doubleClickHandler(null, "folder", folder)}>{folder?.name}</p>
                                                    <p><small>{folder?.itemsCount || 0} items</small></p>
                                                </div>
                                            </Space>
                                        </Col>
                                    ))
                                }
                                {
                                    viewingMedia?.filter(d => currentView === "All Files" ? !d?.isDeleted : d?.isDeleted).map(media => (
                                        <Col xs={24} sm={12} md={8} lg={6} xl={4} key={media?._id}
                                             className={`media-item-wrapper ${selected.includes(media?._id) ? "checked" : ""}`}>
                                            <Checkbox
                                                className="action-row first"
                                                checked={selected.includes(media?._id)}
                                                onChange={(e) => onCheckHandler(media?._id, e.target.checked)}
                                            />

                                            {
                                                currentView === "All Files" ?
                                                    <Space className="action-row second">
                                                        {
                                                            folders && folders.length > 0 &&
                                                            <Tooltip title={t("Move to")}>
                                                                <Button
                                                                    icon={<TbFolderSymlink/>}
                                                                    onClick={() => {
                                                                        setCurrentFile(media)
                                                                        setFoldersModalOpen(true)
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        }
                                                        <Tooltip title={t("Replace Image")}>
                                                            <Upload
                                                                beforeUpload={(file, fileList) => handleReplaceImage(file, fileList, media?._id)}
                                                                showUploadList={false}
                                                                maxCount={1}
                                                                accept="image/png, image/jpeg, image/gif, image/webp, image/svg+xml"
                                                            >
                                                                <Button icon={<TbEdit/>}/>
                                                            </Upload>
                                                        </Tooltip>
                                                    </Space> :
                                                    <Tag color={"geekblue"} bordered={false} className="action-row second margin-top-0-3">
                                                        {daysLeft(media?.deletedAt)} Days
                                                    </Tag>
                                            }

                                            <div className={"media-item"} onClick={(e) => clickHandler(e, "file", media)}>
                                                <div className={"media-item-container"}>
                                                    <Image
                                                        preview={false}
                                                        src={url + media?.filePath}
                                                        className={"display-image"}
                                                        loading={"lazy"}
                                                    />
                                                </div>
                                            </div>
                                            <Space align={"center"}>
                                                <div>
                                                    <p className={"path"}
                                                       onClick={() => doubleClickHandler(null, "file", media)}>{media?.displayName.replace(media?.fileType, "")}</p>
                                                    <p><small>{media?.fileType.replace(".", "").toUpperCase()}</small></p>
                                                </div>
                                            </Space>
                                        </Col>
                                    ))
                                }
                            </Row>
                        ) : (
                            <div style={{height: 400, display: "flex"}}>
                                <Empty style={{margin: "auto"}}/>
                            </div>
                        )
                    }
                </>
            </OuterUploadDragger>
            <CreateFolderModal
                open={createFolderModalIsOpen}
                handleClose={() => setCreateFolderModalIsOpen(false)}
                refetchFolder={refetchFolder}
                currentPath={currentPath}
                folderOption={folderOption}
            />
            <UploadModal
                open={uploadMediaModalIsOpen}
                handleClose={() => setUploadMediaModalIsOpen(false)}
                fetchMedia={fetchMedia}
                folderOption={folderOption}
                currentPath={currentPath}
            />
            <PreviewModal
                open={previewModalIsOpen}
                handleClose={() => setPreviewModalIsOpen(false)}
                data={media}
                current={currentFile}
                setCurrent={setCurrentFile}
            />
            {
                folders && folders.length > 0 &&
                <MoveFolderModal
                    open={foldersModalOpen}
                    folders={folders}
                    handleClose={() => setFoldersModalOpen(false)}
                    fileId={currentFile?._id}
                    refetchMedia={refetchMedia}
                />
            }
            <RenameFolderModal
                folder={currentFile}
                open={renameFolderModalIsOpen}
                handleClose={() => setRenameFolderModalIsOpen(false)}
                refetchFolder={refetchFolder}
            />
        </div>
    )
}

export default MediaLibrary_1