import React, {useEffect, useMemo, useState} from "react";
import {Button, Col, Form, Modal, Row, Select, Space} from "antd";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {useAddUserMutation} from "../../../../../api/graphql/user";
import {formatProfileInformation, renderProfileInformation, validateConfirmKeys} from "../../../_common/function";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {rankOptionsState, userOptionsState} from "../../../_common/state";
import useAuthorize from "../../../../../_common/authorize";
import {fieldListState, notificationMessage} from "../../../../../../recoil_state";
import {useSetDropdown} from "../../../../../_common/dropdowns";
import {formatDateUTC} from "../../../../../function/_common";

const ClientsAddForm = (props) => {
        const {isOpen, close, profileInformation, permission, refetchUserData} = props;
        const {t} = useTranslation();
        const [form] = Form.useForm();
        const [fields, setFields] = useState<any>({
            basicField: [],
            loginField: [],
            residenceField: [],
            additionalField: [],
        });
        const {basicField, loginField, residenceField, additionalField} = fields;
        const rankOptions = useRecoilValue<any>(rankOptionsState);
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const fieldList = useRecoilValue<any>(fieldListState)
        const userOptions = useRecoilValue(userOptionsState)

        const {addUser, loading} = useAddUserMutation();
        const {baseErrorChecking, systemErrorNotification} = useAuthorize();
        const {setUpUserOptions} = useSetDropdown()

        const rankOptionsClientsUser = useMemo(() => {
            return rankOptions?.filter((v) => v?.type === "client");
        }, [rankOptions]);

        const onSubmit = async (value) => {
            try {
                const nonUpdatableFields = ["tag", "leadSource", "age", "affiliateCode"];
                await Object.keys(value).forEach((key) => {
                    const match = nonUpdatableFields?.find((d) => d === key);
                    if (value[key] === undefined || match) {
                        delete value[key];
                    }
                });
                const validated = await validateConfirmKeys(value, setNotificationMessage, t);

                if (validated) {
                    value["mobileNo"] = value?.mobileNo?.toString();
                    delete value["combinedMobileNo"];
                    value["dateOfBirth"] = value["dateOfBirth"] ? formatDateUTC(value["dateOfBirth"]) : null;
                    let response = await addUser({
                        ...value,
                        userType: "client",
                    });

                    if (response?.data?.addUser?.__typename === "BaseError") {
                        if (response?.data?.addUser?.errKey === "admin.err.user_exist") {
                            response?.data?.addUser?.errObj.forEach((item) => {
                                form.setFields([
                                    {
                                        name: item === "mobileNo" ? "combinedMobileNo" : item,
                                        errors: [t("errMsg.fieldAlreadyExisted", {field: t(`user.${item}`)})],
                                    },
                                ]);
                            });
                        } else {
                            return await baseErrorChecking(response?.data?.addUser);
                        }
                    } else {
                        if (response?.data?.addUser?.__typename === "CrmTaskApproval") {
                            setNotificationMessage({
                                status: "info",
                                title: "",
                                msg: t(response?.data?.addUser?.msg),
                                key: "addUserMsg",
                            });
                        } else {
                            setNotificationMessage({
                                status: "success",
                                title: "",
                                msg: t("successMsg.addUserSuccessfully"),
                                key: "addUserMsg",
                            });
                        }

                        setUpUserOptions();
                        refetchUserData()

                        form.resetFields();
                        close();
                    }
                }
            } catch (e) {
                console.error(e);
                systemErrorNotification();
            }
        };

        useEffect(() => {
            formatProfileInformation(fields, setFields, profileInformation);
        }, [profileInformation]);

        useEffect(() => {
            form.resetFields();
            if (isOpen) {
                form.setFieldValue("rank", rankOptionsClientsUser?.find((d) => d?.isDefault)?.value);
            }
        }, [isOpen]);

        const validateMessages = {
            required: t("errorMsg.required.requiredField", {field: "${label}"}),
        };

        return (
            <Modal
                title={t(`clientUser.addClient`)}
                open={isOpen}
                footer={null}
                onCancel={() => {
                    form.resetFields();
                    close();
                }}
                width={800}
                destroyOnClose
            >
                <Form onFinish={onSubmit} form={form} validateMessages={validateMessages} colon={false} labelWrap={true}>
                    <Row gutter={[16, 16]} className={"margin-top-0-75 margin-bottom-0-75"}>
                        {basicField?.length > 0 && (
                            <Col span={24}>
                                <h4>{t("field.category.basicInformation")}</h4>
                                <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                                    {renderProfileInformation(basicField, {}, t, false, true, form)}
                                    <Col span={12}>
                                        <Form.Item
                                            name={"referrer"}
                                            label={t("user.referrer")}
                                            labelCol={{span: 10, offset: 0}}
                                            labelAlign="left"
                                            rules={[{required: true}]}
                                        >
                                            <Select
                                                options={userOptions}
                                                optionFilterProp={"label"}
                                                optionLabelProp="selectedLabel"
                                                allowClear
                                                showSearch
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={"clientAccountType"}
                                            label={t("user.clientAccountType")}
                                            rules={[{required: true}]}
                                            labelCol={{span: 10, offset: 0}}
                                            labelAlign="left"
                                        >
                                            <Select
                                                options={[
                                                    {label: t("Individual"), value: "individual"},
                                                    {label: t("Demo"), value: "demo"},
                                                ]}
                                                allowClear
                                                showSearch
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={"rank"}
                                            label={t("user.rank")}
                                            rules={[{required: true}]}
                                            labelCol={{span: 10, offset: 0}}
                                            labelAlign="left"
                                        >
                                            <Select
                                                options={rankOptionsClientsUser}
                                                allowClear
                                                showSearch
                                                optionFilterProp={"label"}
                                                disabled={!permission?.edit?.rank}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={"comment"}
                                            label={t("user.comment")}
                                            labelCol={{span: 10, offset: 0}}
                                            labelAlign={"left"}
                                        >
                                            <Select
                                                showSearch
                                                allowClear
                                                options={fieldList?.find(d => d?.fieldName === "clientComment")?.optionValue?.map(d => ({
                                                    ...d,
                                                    label: t(d?.label)
                                                }))}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        )}
                        {loginField?.length > 0 && (
                            <Col span={24}>
                                <h4>{t("field.category.loginInformation")}</h4>
                                <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                                    {renderProfileInformation(loginField, {}, t, false, true, form)}
                                </Row>
                            </Col>
                        )}
                        {residenceField?.length > 0 && (
                            <Col span={24}>
                                <h4>{t("field.category.residenceInformation")}</h4>
                                <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                                    {renderProfileInformation(residenceField, {}, t, false, true, form)}
                                </Row>
                            </Col>
                        )}
                        {additionalField?.length > 0 && (
                            <Col span={24}>
                                <h4>{t("field.category.additionalInformation")}</h4>
                                <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                                    {renderProfileInformation(additionalField, {}, t, false, true, form)}
                                </Row>
                            </Col>
                        )}
                        <Form.Item className={"margin-left-auto"}>
                            <Space>
                                <Button onClick={() => close()}>{t("common.cancel")}</Button>
                                <Button type={"primary"} htmlType={"submit"} loading={loading}>
                                    {t("common.add")}
                                </Button>
                            </Space>
                        </Form.Item>
                    </Row>
                </Form>
            </Modal>
        );
    }
;

export default ClientsAddForm;
