import React, {useState} from "react";
import {Modal, Spin} from "antd";
import PlatformServerForm from "./serverSettingsForm";
import {useTranslation} from "react-i18next";


const ServerSettingsModal = (props) => {
  const { mode, item, isOpen, closeModal} = props
  const [ spinning, setSpinning ] = useState(false)
  const { t } = useTranslation()

  const getTitle = ():String => {
    switch(mode){
      case "add":
        return `${t('Add New Server')}`
      case "view":
        return `${t('View Server')}`
      case "edit":
        return `${t('Edit Server')}`
      default: 
       return `${t('Add New Server')}`
    }
  }

  function convertToTradingKey(record) {
    if(record){
      const result = {...record};
      if(record?.fieldForTrading?.length > 0){
        record?.fieldForTrading?.forEach(item => {
          result[`tradingKey-${item.tradingKey}`] = item.clientField;
        });
      }
      return result;
    }
  }

  return (
    <Modal
      getContainer={"#serverSettings"}
      title={getTitle()}
      open={isOpen}
      width={"80%"}
      footer={null}
      onCancel={closeModal}
      destroyOnClose
    >
        <Spin spinning={spinning} tip={mode === "add" ? "Fetching data from server": "Updating"}>
          <PlatformServerForm mode={mode} item={convertToTradingKey(item)} closeModal={closeModal} setSpinning={setSpinning} />
        </Spin>
    </Modal>
  );
};

export default ServerSettingsModal;
