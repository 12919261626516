import React from "react";
import Leads_1 from "./view/layout1";
import {getProfileInformationSetting} from "../../../../api/graphql/profileInformation";
import {useRecoilValue} from "recoil";
import {selfPermissionObj} from "../../../../../recoil_state";

function Leads() {
    let layout = 1;
    let component;
    const tableName = "Leads"

    const permission = useRecoilValue<any>(selfPermissionObj)
    const {data:profileInformation} = getProfileInformationSetting({type: "leads"})
    const filteredProfileInformation = profileInformation?.getProfileInformationSetting?.data?.filter(d => d?.position !== null)

    switch (layout) {
        case 1:
            component = (
                <Leads_1
                    tableName={tableName}
                    profileInformation={filteredProfileInformation}
                    permission={permission?.user?.leads}
                />
            );
            break;

        default:
            component = (
                <Leads_1
                    tableName={tableName}
                    profileInformation={filteredProfileInformation}
                    permission={permission?.user?.leads}
                />
            );
            break;
    }

    return component;
};

export default Leads;
