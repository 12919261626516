import React from "react";
import {Col, Image, Input, Modal, Rate, Row} from "antd";
import TextArea from "antd/es/input/TextArea";
import {formatDate} from "../../../../../../../_common/function";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {LinkOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import {formatDateUTC} from "../../../../../../../../../function/_common";

const FieldsModal = (props) => {
    const {item, isOpen, close} = props
    const {t} = useTranslation()

    return (
        <Modal
            title={t(item?.document?.label)}
            open={isOpen}
            destroyOnClose
            onCancel={() => close()}
            footer={null}
            getContainer="#clientDetail"
        >
            <Row gutter={16}>
                {
                    item?.fieldValue?.map(d => {
                        let component
                        let ends = ['jpg', 'jpeg', 'png', 'webp', 'svg']

                        switch (d?.field?.fieldType === "group" ? d?.referenceId?.fieldType : d?.field?.fieldType) {
                            case "textarea":
                                component = <TextArea value={d?.value} disabled/>
                                break
                            case "date":
                                component =
                                    <Input value={d?.value ? formatDateUTC(d?.value) : null} disabled/>
                                break
                            case "document":
                                component = d?.value ? (ends.some(ext => d?.value?.endsWith(ext)) ? <Image height={100} style={{objectFit: "cover"}}
                                                                                                           src={d?.value}/> :
                                    <a onClick={() => window.open(d?.value, "_blank")}>{d?.value?.split(/[/]+/)?.pop()} <LinkOutlined/></a>) :
                                    <p>{t("errMsg.noFileUploaded")}</p>
                                break
                            case "multiSelect":
                                component = <Input value={d?.value?.join(", ")} disabled/>
                                break
                            case "rate":
                                component = <Rate value={parseInt(d?.value)} disabled/>
                                break
                            case "mobile":
                                component = <Input prefix={d?.valueMobile?.countryCode} value={d?.valueMobile?.mobileNo} disabled/>
                                break;
                            default :
                                component = <Input value={d?.value} disabled/>
                                break
                        }

                        return (
                            <>
                                <Col span={12} key={d?._id} className={"margin-top-0-3"}>
                                    <Row justify={d?.modifiedBy ? "space-between" : "start"}>
                                        <small>
                                            {t(d?.field?.label)} {d?.field?.fieldType === "group" ? ` - ${t(d?.referenceId?.label)}` : ""}
                                        </small>
                                    </Row>
                                    <div>{component}</div>
                                    {
                                        d?.modifiedBy &&
                                        <div className={"text-align-right"}>
                                            <small>
                                                <div>
                                                    Modified By: &nbsp;
                                                    <a onClick={() => window.open(`/users/crm-user/detail?id=${d?.modifiedBy?._id}`, "_blank")}>{d?.modifiedBy?.username}</a>
                                                </div>
                                                {
                                                    d?.modifiedAt &&
                                                    <div>Modified At: &nbsp; {moment(d?.modifiedAt)?.format("DD-MM-YYYY HH:mm:ss")}</div>
                                                }
                                            </small>
                                        </div>
                                    }
                                </Col>
                                {
                                    d?.hasExpiredDate &&
                                    <Col span={12}>
                                        <div>
                                            <small>{t(d?.field?.label)} {d?.field?.fieldType === "group" ? ` - ${t(d?.referenceId?.label)}` : ""} {` - Expired Date`}</small>
                                        </div>
                                        <Input value={formatDateUTC(d?.expiredDate)} disabled/>
                                    </Col>
                                }
                            </>
                        )
                    })
                }
            </Row>
        </Modal>
    )
};

export default FieldsModal