import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Col, Popconfirm, Row, Tooltip} from "antd";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import InputFormItem from "../../../../components/form/inputFormItem";
import { halfHorizontalFormLayout, verticalFormLayout } from "../../../../components/form/formWrapper";
import SelectFormItem from "../../../../components/form/selectFormItem";
import InputNumberFormItem from "../../../../components/form/inputNumberFormItem";
import { rewardType, voucherExpiryType } from "../function/enumerators";
import SelectDurationFormItem from "../../../../components/form/selectDurationFormItem";
import moment from "moment";
import RadioGroupFormItem from "../../../../components/form/radioGroupFormItem";
import DatePickerInputForm from "../../../../components/form/datePickerFormItem";


const VoucherProgramRewards = (props) => {
    const {rewardValue, setRewardValue, mode, form, voucher = [], voucherGroup = []} = props;
    const {t} = useTranslation();
    const mergedVoucherOptions = 
        [
            ...voucher,
            ...voucherGroup
        ]
    
        
    const removeFromReward = (index) => {
        let result = [...rewardValue]
        for(const [keys,_] of Object.entries(rewardValue[index])){
            form.setValue(keys + "_" + (rewardValue?.length - 1), null)
        }
        result.splice(index, 1)
        setRewardValue(result)
    }

    const handleOnChange = (index, field, value) => {
        if(field === "isFixedReward"){
            value = value === "true"? true : false
        }
        let result = [...rewardValue]
        result[index][field] = value
        setRewardValue(result)
    }
    const getCategoryType = (id) => {
        let [record] = mergedVoucherOptions?.filter(item => item?.value === id)
        return record?.type
    }

    const getRewardType = (id) => {
        let [targetVoucher] = mergedVoucherOptions?.filter(item => item?.value === id) || []
        return targetVoucher?.rewardType
    }

    const resetFieldValue = (fields) => {
        if(fields && fields.length > 0){
            fields.forEach(item => {
                form.setValue(item, undefined)
            })
        }
    }
    
    return (
        <>
            {
                rewardValue.map((item, index)=> {
                    return (
                    <Row gutter={16} className="poolCard">
                        <Col className="gutter-row" xs={24} md={9}>
                            <SelectFormItem
                                name={`reward_${index}`}
                                label={"Reward"}
                                placeholder={"Reward"}
                                options={mergedVoucherOptions || []}
                                value={item?.reward}
                                layout={verticalFormLayout}
                                onChange={(e)=> {
                                    handleOnChange(index, "reward", e)
                                    handleOnChange(index, "rewardType", getCategoryType(e) || undefined)
                                }}
                                disabled={mode === "view"}
                                required
                            />
                        </Col>
                        <Col xs={12} md={7}>
                            <SelectDurationFormItem
                                name={`voucherExpiryType_${index}`}
                                label={t("Voucher Expiry Type")}
                                options={voucherExpiryType}
                                mode={"single"}
                                value={item?.voucherExpiryType}
                                placeholder={t("Voucher Expiry Type")}
                                disabled={mode === "view"}
                                endDateName={`voucherExpiryDate_${index}`}
                                endDateValue={item?.voucherExpiryDate}
                                daysName={`voucherExpiryValue_${index}`}
                                daysValue={item?.voucherExpiryValue}
                                onChange={(e)=> {
                                    handleOnChange(index, "voucherExpiryType", e)
                                }}
                                onEndDateValueChange={(value)=>{
                                    handleOnChange(index, "voucherExpiryDate", value)
                                }}
                                onDaysValueChange={(value)=>{
                                    handleOnChange(index, "voucherExpiryValue", value)
                                }}
                            />
                        </Col>
                        {
                            (getRewardType(item?.reward) === "cashVoucher" || getRewardType(item?.reward) === "stockVoucher" || getRewardType(item?.reward) === "commoditiesVoucher") ?
                            <Col xs={12} md={7} /> :
                            <Col xs={12} md={7}>
                                <SelectDurationFormItem
                                    name={`voucherDurationType_${index}`}
                                    label={t("Voucher Duration Type")}
                                    options={voucherExpiryType}
                                    mode={"single"}
                                    value={item?.voucherDurationType}
                                    placeholder={t("Voucher Duration Type")}
                                    disabled={mode === "view"}
                                    endDateName={`voucherDurationDate_${index}`}
                                    endDateValue={item?.voucherDurationDate}
                                    daysName={`voucherDurationValue_${index}`}
                                    daysValue={item?.voucherDurationValue}
                                    onChange={(e)=> {
                                        handleOnChange(index, "voucherDurationType", e)
                                    }}
                                    onEndDateValueChange={(value)=>{
                                        handleOnChange(index, "voucherDurationDate", value)
                                    }}
                                    onDaysValueChange={(value)=>{
                                        handleOnChange(index, "voucherDurationValue", value)
                                    }}
                                />
                            </Col>
                        }
                        <Tooltip title={t("Delete")}>
                          <Popconfirm
                            placement="left"
                            title={t("Are you sure to delete this reward?")}
                            onConfirm={() => {
                                removeFromReward(index);
                            }}
                            okText={t("common.yes")}
                            cancelText={t("common.no")}
                          >
                            <a>
                              <DeleteOutlined />
                            </a>
                          </Popconfirm>
                        </Tooltip>
                        <Col xs={12} md={9}>
                            <DatePickerInputForm
                                name={`voucheStartDate_${index}`}
                                label={t("Voucher Start Date")}
                                value={item?.voucherStartDate}
                                placeholder={t("Voucher Start Date")}
                                layout={verticalFormLayout}
                                labelCol={{col: 24, span: 24}}
                                disabled={mode === "view"}
                                onChange={(e)=> {
                                    handleOnChange(index, "voucherStartDate", e)
                                }}
                                required
                            />
                        </Col>
                        <Col xs={12} md={8}>
                            <RadioGroupFormItem
                                name={`isFixedReward_${index}`}
                                label={t("Reward Types")}
                                options={[
                                    {label: t("Fixed Reward"), value: "true"},
                                    {label: t("Selectable Reward"), value: "false"},
                                ]}
                                layout={verticalFormLayout}
                                value={item?.isFixedReward?.toString()}
                                onChange={(e)=> {
                                    handleOnChange(index, "isFixedReward", e)
                                }}
                                labelAlign={"centre"}
                                disabled={mode === "view"}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <InputNumberFormItem
                                name={`maxRedeem_${index}`}
                                label={"Max Redeem Count:"}
                                placeholder={"Max Redeem Count"}
                                layout={verticalFormLayout}
                                value={item?.maxRedeem}
                                onChange={(e)=> {
                                    handleOnChange(index, "maxRedeem", e)
                                }}
                                disabled={mode === "view"}
                                min={1}
                                required
                            />
                        </Col>
                    </Row>
                    )})
            }
        </>
    );
};

export default VoucherProgramRewards;
