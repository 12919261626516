import React, {useState} from "react";
import {Button, Col, Drawer, Row, Segmented, Space, Tooltip} from "antd";
import {
    TbAward,
    TbCursorText,
    TbDeviceDesktop,
    TbDeviceIpad,
    TbDeviceMobile,
    TbGift,
    TbLayout,
    TbMarquee2,
    TbPhoto,
    TbPodium,
    TbRobotFace,
    Tb123
} from "react-icons/tb";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import {useRecoilState} from "recoil";
import {elementDrawerIsOpen, layoutEditableState} from "../../state/state";

const ElementsDrawer = ({setCurrentWidth, currentWidth, onStartDropDrag}) => {
    const [drawerOpen, setDrawerOpen] = useRecoilState(elementDrawerIsOpen);
    const [layoutEditable, setLayoutEditable] = useRecoilState(layoutEditableState);

    return (
        <>
            <div style={{
                position: "absolute",
                background: "#acacac",
                padding: 8,
                right: drawerOpen ? 300 : 0,
                top: 80,
                cursor: "pointer",
                zIndex: 100,
                transition: "all .3s",
                borderRadius: "4px 0 0 4px"
            }} onClick={() => setDrawerOpen(prev => !prev)}>
                {drawerOpen ? <RightOutlined /> : <LeftOutlined/>}
            </div>
            <Drawer
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                getContainer={"#leaderboard-setting-ui-editor"}
                title={null}
                closeIcon={null}
                maskClosable={false}
                destroyOnClose={false}
                width={300}
            >

                <div className={"element-container"}>
                    <Space>
                        <Segmented options={[
                            {value: 380, icon: <TbDeviceMobile/>},
                            {value: 780, icon: <TbDeviceIpad/>},
                            {value: "100%", icon: <TbDeviceDesktop/>},
                        ]} onChange={value => setCurrentWidth(value)} value={currentWidth} className={"screen-size-segmented"}/>

                        <Tooltip title={layoutEditable ? "Layout Enabled" : "Layout Disabled"}>
                            <Button onClick={() => setLayoutEditable(prev => !prev)} className={`action-button ${layoutEditable ? "active" : ""}`}
                                    icon={<TbLayout/>}/>
                        </Tooltip>
                    </Space>

                    <h1 className={"section-header margin-top-0-75"}>Element</h1>
                    <Row gutter={[8, 8]} className={"element-grid"}>
                        <Col span={12}>
                            <div
                                className="droppable-element"
                                draggable={true}
                                unselectable="on"
                                onDragStart={(e) => onStartDropDrag(e, "text")}
                            >
                                <div>
                                    <TbCursorText/>
                                    <p>Text</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div
                                className="droppable-element"
                                draggable={true}
                                unselectable="on"
                                onDragStart={(e) => onStartDropDrag(e, "image")}
                            >
                                <div>
                                    <TbPhoto/>
                                    <p>Image</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div
                                className="droppable-element"
                                draggable={true}
                                unselectable="on"
                                onDragStart={(e) => onStartDropDrag(e, "blank")}
                            >
                                <div>
                                    <TbMarquee2/>
                                    <p>Blank Section</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {/*<h1 className={"section-header"}>Section</h1>*/}
                    {/*<Row gutter={[8, 8]} className={"element-grid"}>*/}
                    {/*    <Col span={12}>*/}
                    {/*        <div>*/}
                    {/*            <div>*/}
                    {/*                <TbColumns2/>*/}
                    {/*                <p>2 Columns</p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </Col>*/}
                    {/*    <Col span={12}>*/}
                    {/*        <div>*/}
                    {/*            <div>*/}
                    {/*                <TbColumns3/>*/}
                    {/*                <p>3 Columns</p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}

                    <h1 className={"section-header"}>Pre-build Component</h1>
                    <Row gutter={[8, 8]} className={"element-grid"}>
                        <Col span={12}>
                            <div
                                className="droppable-element"
                                draggable={true}
                                unselectable="on"
                                onDragStart={(e) => onStartDropDrag(e, "ranking")}
                            >
                                <div>
                                    <TbAward/>
                                    <p>Ranking</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div
                                className="droppable-element"
                                draggable={true}
                                unselectable="on"
                                onDragStart={(e) => onStartDropDrag(e, "podium")}
                            >
                                <div>
                                    <Tb123/>
                                    <p>Podium</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div
                                className="droppable-element"
                                draggable={true}
                                unselectable="on"
                                onDragStart={(e) => onStartDropDrag(e, "legend")}
                            >
                                <div>
                                    <TbRobotFace/>
                                    <p>Legend</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div>
                                <div>
                                    <TbGift/>
                                    <p>Prizes</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div>
                                <div>
                                    <TbPodium/>
                                    <p>List</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <h1 className={"section-header"}>Variable</h1>
                    <Row gutter={[8, 8]} className={"element-grid"}>
                        <Col span={12}>
                            <div>
                                <div>
                                    <p>Display Name</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div>
                                <div>
                                    <p>Description</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div>
                                <div>
                                    <p>Date Range</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Drawer>
        </>
)
}

export default ElementsDrawer