import {gql, useMutation, useQuery} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";

const GET_CRON = gql`
    query getCron($filter: CronInput, $limit: Int, $offset: Int, $orderBy: String) {
        getCron(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CronAry {
                total
                data {
                    _id
                    name
                    totalExecution
                    lastExecution
                    status
                    writeToFile
                    histories{
                        success
                        error
                        executionTime
                    }
                    createdAt
                }
            }
        }
    }
`

const getCron = async ({filter}, limit?, offset?, orderBy?) => {
    try {
        return await ApolloClientQuery(
            GET_CRON,
            {
                filter: filter,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_CRON_LOG = gql`
    query getCronLog(
        $filter: CronLogInput
        $orderBy: String
        $limit: Int
        $offset: Int
        $startDate: String
        $endDate: String
        $dateKey: String
    ) {
        getCronLog(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
            startDate: $startDate
            endDate: $endDate
            dateKey: $dateKey
        ) {
            ... on CronLogAry {
                data {
                    _id
                    logTitle
                    status
                    totalDataExecution
                    totalSuccessExecution
                    totalFailExecution
                    startTime
                    endTime
                    createdAt
                }
                total
            }
        }
    }
`;

const getCronLog = ({filter}, orderBy?, limit?, offset?, startDate?, endDate?, dateKey?) => {
    try {
        const {loading, error, data, refetch} = useQuery(GET_CRON_LOG, {
            variables: {
                filter: filter,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
                orderBy: orderBy || null,
                limit: limit || null,
                offset: offset || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        const refetchQuery = () => {
            refetch()
        }

        return {loading, error, data, refetchQuery};
    } catch (err) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_CRON_LOG_DETAILS = gql`
    query getCronLogDetails(
        $filter: CronLogDetailsInput
        $orderBy: String
        $limit: Int
        $offset: Int
        $startDate: String
        $endDate: String
        $dateKey: String
    ) {
        getCronLogDetails(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
            startDate: $startDate
            endDate: $endDate
            dateKey: $dateKey
        ) {
            ... on CronLogDetailsAry {
                data {
                    _id
                    cron{
                        name
                    }
                    log{
                        logTitle
                    }
                    detailTitle
                    status
                    errorLog
                    dataCapture
                    createdAt
                }
                total
            }
        }
    }
`;

const getCronLogDetails = ({filter}, orderBy?, limit?, offset?, startDate?, endDate?, dateKey?) => {
    try {
        const {loading, error, data, refetch} = useQuery(GET_CRON_LOG_DETAILS, {
            variables: {
                filter: filter,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
                orderBy: orderBy || null,
                limit: limit || null,
                offset: offset || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        const refetchQuery = () => {
            refetch()
        }

        return {loading, error, data, refetchQuery};
    } catch (err) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const RUN_CRON = gql`
    mutation runCron(
        $name: String
    ) {
        runCron(
            name: $name
        ) {
            ... on Cron {
                _id
                name
                totalExecution
                lastExecution
                status
                writeToFile
                histories{
                    success
                    error
                    executionTime
                }
                createdAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useRunCronMutation = (): {
    runCron: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const [runCronMutation, {data, loading, error}] =
            useMutation(RUN_CRON);

        const runCron = (name) => {
            return runCronMutation({
                variables: {
                    name: name,
                },
            });
        };

        return {runCron, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {runCron: null, data: null, loading: false, error: err};
    }
};

const UPDATE_CRON = gql`
    mutation updateCron(
        $name: String
        $input:CronUpdateInput
    ) {
        updateCron(
            name: $name
            input:$input
        ) {
            ... on Cron {
                _id
                name
                totalExecution
                lastExecution
                status
                writeToFile
                histories{
                    success
                    error
                    executionTime
                }
                createdAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useUpdateCronMutation = (): {
    updateCron: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const [updateCronMutation, {data, loading, error}] =
            useMutation(UPDATE_CRON);

        const updateCron = (name, input) => {
            return updateCronMutation({
                variables: {
                    name: name,
                    input: input
                },
            });
        };

        let typename = data?.updateCron?.__typename;
        if (typename === "CrmTaskApproval" || typename === "BaseError") {
            // Notification
        }

        return {updateCron, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {updateCron: null, data: null, loading: false, error: err};
    }
};

export {
    getCron,
    getCronLog,
    getCronLogDetails,
    useRunCronMutation,
    useUpdateCronMutation
}