import { gql, useMutation, useQuery } from "@apollo/client";
import {useSetRecoilState} from "recoil";
import {errorMessage, notificationMessage} from "../../../recoil_state";
import {useTranslation} from "react-i18next";

const GET_INSTRUMENTS = gql`
  query getInstruments(
    $filter: InstrumentsSearchInput
    $page: Int!
    $numOfRecords: Int
    $orderBy: String
  ) {
    getInstruments(
      filter: $filter
      page: $page
      numOfRecords: $numOfRecords
      orderBy: $orderBy
    ) {
      base_symbol_id
      country
      stock_exchange_acronym
      ticker_symbol
      instrument_name
      type
    }
  }
`;

const getInstruments = ({ filter }, page?, numOfRecords?, orderBy?) => {
  try {
    const { loading, error, data } = useQuery(GET_INSTRUMENTS, {
      variables: {
        filter: {
          base_symbol_id: filter?.base_symbol_id,
          country: filter?.country,
          stock_exchange_acronym: filter?.stock_exchange_acronym,
          stock_exchange_name: filter?.stock_exchange_name,
          ticker_symbol: filter?.ticker_symbol,
          instrument_name: filter?.instrument_name,
          type: filter?.type,
        },
        page: page,
        numOfRecords: numOfRecords,
        orderBy: orderBy,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
        endPoint: "baseInstrumentLink",
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_INSTRUMENTS_COUNT = gql`
  query getInstrumentsCount($filter: InstrumentsSearchInput) {
    getInstrumentsCount(filter: $filter)
  }
`;

const getInstrumentsCount = ({ filter }) => {
  try {
    const { loading, error, data } = useQuery(GET_INSTRUMENTS_COUNT, {
      variables: {
        filter: {
          base_symbol_id: filter?.base_symbol_id || null,
          country: filter?.country || null,
          stock_exchange_acronym: filter?.stock_exchange_acronym || null,
          stock_exchange_name: filter?.stock_exchange_name || null,
          ticker_symbol: filter?.ticker_symbol || null,
          instrument_name: filter?.instrument_name || null,
          type: filter?.type || null,
        },
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
        endPoint: "baseInstrumentLink",
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_SYMBOL_MAPPING_COUNT = gql`
  query getSymbolMappingCount($filter: SymbolMappingSearchInput) {
    getSymbolMappingCount(filter: $filter)
  }
`;

const getSymbolMappingCount = ({ filter }) => {
  try {
    const { loading, error, data } = useQuery(GET_SYMBOL_MAPPING_COUNT, {
      variables: {
        filter: {
          symbolName: filter?.symbolName ?? null,
          baseSymbolId: filter?.baseSymbolId ?? null,
          serverName: filter?.serverName ?? null,
        },
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_SYMBOL_MAPPING = gql`
  query getSymbolMapping(
    $filter: SymbolMappingSearchInput
    $limit: Int
    $offset: Int
    $orderBy: String
  ) {
    getSymbolMapping(
      filter: $filter
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      ... on SymbolMappingArray {
        data {
          id
          symbol {
            _id
            symbolId
            name
          }
          symbolMt5 {
            _id
            symbol
          }
          server{
            _id
          }
          baseSymbolId
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getSymbolMapping = ({ filter }, limit?, offset?, orderBy?) => {
  try {
    const { loading, error, data } = useQuery(GET_SYMBOL_MAPPING, {
      variables: {
        filter: {
          symbolName: filter?.symbolName ?? null,
          description: filter?.description ?? null,
          baseSymbolId: filter?.baseSymbolId ?? null,
          serverName: filter?.serverName ?? null,
          symbolNameMt5: filter?.symbolNameMt5 ?? null,
          descriptionMt5: filter?.descriptionMt5 ?? null
        },
        limit: limit ?? null,
        offset: offset ?? null,
        orderBy: orderBy ?? null,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const UPDATE_SYMBOL_MAPPING = gql`
  mutation updateSymbolMapping($input: SymbolMappingInput!) {
    updateSymbolMapping(input: $input) {
      ... on CrmTaskApproval {
        msg
      }
      ... on SymbolMapping {
        id
        symbol {
          _id
          symbolId
          name
        }
        baseSymbolId
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

type SymbolMapping = {
  id: string;
  symbol: {
    _id: string;
    symbolId: string;
    name: string;
  };
  baseSymbolId: string;
};


const useUpdateSymbolMapping = () => {
  try {
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const { t } = useTranslation();
    const [updateSymbolMappingMutation, { data, loading, error }] = useMutation(
        UPDATE_SYMBOL_MAPPING,
        {
          update(cache, { data: { updateSymbolMapping } }) {
            let typename = data?.updateSymbolMapping?.__typename;
            if (typename === "BaseError") {
              setNotificationMessage({
                status: "error",
                title: "",
                msg: t(data?.updateSymbolMapping?.errKey),
                key: "updateSymbolMapping",
              });
            } else if (typename === "CrmTaskApproval") {
              setNotificationMessage({
                status: "info",
                title: "",
                msg: t(data?.updateSymbolMapping?.msg),
                key: "updateSymbolMapping",
              });
            } else {
                const existingData = cache.readQuery<{
                  getSymbolMapping: SymbolMapping[];
                }>({
                  query: GET_SYMBOL_MAPPING,
                });

                const index = existingData?.getSymbolMapping.findIndex(
                    (symbolMapping) =>
                        symbolMapping.symbol.symbolId ===
                        updateSymbolMapping.symbol.symbolId
                );

                if (index !== -1 && existingData && index) {
                  existingData.getSymbolMapping[index].symbol.symbolId =
                      updateSymbolMapping.symbol.symbolId;

                  cache.writeQuery({
                    query: GET_SYMBOL_MAPPING,
                    data: existingData,
                  });

                  return;
                }

                const newRef = cache.writeFragment({
                  data: updateSymbolMapping,
                  fragment: gql`
                  fragment NewSymbolMapping on SymbolMapping {
                    id
                    symbol {
                      _id
                      symbolId
                      name
                    }
                    baseSymbolId
                  }
                `,
                });

                cache.modify({
                  fields: {
                    getSymbolMappingCount(existingData) {
                      return existingData + 1;
                    },
                    getSymbolMapping(existingData = []) {
                      return {
                        ...existingData,
                        data:
                            existingData?.data?.length > 0
                                ? [newRef, ...existingData?.data]
                                : [newRef],
                      };
                    },
                  },
                });

                setNotificationMessage({
                  status: "success",
                  title: "",
                  msg: t("Update Symbol Mapping Successfully"),
                  key: "updateSymbolMapping",
                });
            }
          },
        }
    );

    const updateSymbolMapping = (input) => {
      return updateSymbolMappingMutation({
        variables: {
          input: {
            symbol: input?.symbol || null,
            symbolMt5: input?.symbolMt5 || null,
            baseSymbolId: input?.baseSymbolId?.toString() || null,
            server: input?.server || null,
          },
        },
      });
    };

    return { updateSymbolMapping, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return {
      updateSymbolMapping: null,
      data: null,
      loading: false,
      error: err,
    };
  }
};


const DELETE_SYMBOL_MAPPING = gql`
  mutation deleteSymbolMapping($id: String!) {
    deleteSymbolMapping(id: $id) {
      ... on CrmTaskApproval {
        msg
      }
      ... on SymbolMapping {
        id
        isDeleted
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useDeleteSymbolMapping = (): {
  deleteSymbolMapping: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const { t } = useTranslation();
    const [deleteSymbolMappingMutation, { data, loading, error }] = useMutation(
      DELETE_SYMBOL_MAPPING,
      {
        update(cache, { data: { deleteSymbolMapping } }) {
          let typename = deleteSymbolMapping?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(data?.deleteSymbolMapping?.errKey),
              key: "deleteSymbolMapping",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(data?.deleteSymbolMapping?.msg),
              key: "deleteSymbolMapping",
            });
          }
          else {
            cache.modify({
              fields: {
                getSymbolMapping(existingData = [], { readField }) {
                  let data = existingData?.data?.filter((ref) => {
                    const id = readField("id", ref);
                    return id !== deleteSymbolMapping.id;
                  });
                  return { data, __typename: "SymbolMappingArray" };
                },
                getSymbolMappingCount(existingData) {
                  return existingData - 1;
                },
              },
            });

            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Delete Symbol Mapping Successfully"),
              key: "deleteSymbolMapping",
            });
          }
        },
      }
    );

    const deleteSymbolMapping = (id) => {
      return deleteSymbolMappingMutation({
        variables: {
          id: id,
        },
      });
    };

    return { deleteSymbolMapping, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return {
      deleteSymbolMapping: null,
      data: null,
      loading: false,
      error: err,
    };
  }
};

export {
  getInstruments,
  getInstrumentsCount,
  getSymbolMappingCount,
  getSymbolMapping,
  useUpdateSymbolMapping,
  useDeleteSymbolMapping,
};
