import React, {useEffect, useMemo, useRef, useState} from "react"
import {useRecoilValue} from "recoil"
import {useSearchParams} from "react-router-dom"
import {useTranslation} from "react-i18next"
import moment from "moment"
// import {Spin} from "antd";
// import {LoadingOutlined} from "@ant-design/icons";
import fileDownload from "js-file-download"
import type {ColumnsType} from "antd/es/table"
import dayjs from "dayjs"

import TableReportAntDesign from "../../../../../../components/table/tableReportAntDesign"
import {getReportingDetails, getReportingFieldsByReportId} from "../../../../../../api/graphql/reporting"
import {getDatesValueFromRangePreset, removeEmptyChildren, setupReportColumns, transformToDeskTreeData} from "../../../function/common"
import {reportingAllState, reportingAllTypesState} from "../../../state"
import {exportReport} from "../../../../../../api/restful/report"
import CreateEditReportModal from "./createEditReportModal"
import ReportTopBar from "./reportTopBar"
// import {getDeskNetworkTree} from "../../../../../../api/graphql/deskNetworkTree";
import {checkIsMasterAdmin} from "../../../../../../api/graphql/user"
import {currencySettingState} from "../../../../../../../recoil_state"
import {getSearchParamsObj} from "../../../../../../function/_common"
import {getDeskDropdown} from "../../../../../../api/graphql/_common"
import RepopulateReportModal from "./repopulateReportModal"
import EditDepositWithdrawalModal from "./editDepositWithdrawalModal"
// import EditTableUserPreferenceButton from "../../../../../../components/table/function/editTableUserPreferenceButton"
import GroupByRowReportModal from "./groupByRowReportModal"

const ReportComponent = (props) => {
    const {
        selectedReportID,
        refreshAllReportData,
        getReportCategory,
        reportsPermission,
        reportDataloading,
        setReportDataLoading,
        getAllReports,
        getAllCreateEditModalData,
        fromModal,
        initialFilter,
        renderTransactionTypeColumn,
        tableName, //optional - for edit deposit withdrawal modal
    } = props
    const {t} = useTranslation()
    const {isMasterAdmin} = checkIsMasterAdmin(localStorage.getItem("g_userId") || localStorage.getItem("userId"))
    const [searchParams, setSearchParams] = useSearchParams()
    const paramFromDate: any = searchParams.get("fromDate")
    const paramToDate: any = searchParams.get("toDate")
    const paramDisplayMode: any = searchParams.get("displayMode")
    const paramFilterType: any = searchParams.get("filterType")
    const paramFilterTypeId: any = searchParams.get("filterTypeId")

    const reportingAll = useRecoilValue<any>(reportingAllState)
    const reportingAllTypes = useRecoilValue<any>(reportingAllTypesState)
    const currencySetting = useRecoilValue<any>(currencySettingState)

    const {data: deskList} = getDeskDropdown(localStorage.getItem("g_userId") || localStorage.getItem("userId"))

    const [refreshReportData, setRefreshReportData] = useState<boolean>(false)
    // const [reportSummaryData, setReportSummaryData] = useState<any>({});
    const [reportData, setReportData] = useState([])
    const [reportTotalRowData, setReportTotalRowData] = useState({})
    const [reportFields, setReportFields] = useState([])
    const [page, setPage] = useState(1)
    const [rows, setRows] = useState(20)
    const [orderBy, setOrderBy] = useState<any>(null)
    const [displayMode, setDisplayMode] = useState<any>("SUMUP")
    const [loading, setLoading] = useState(false)
    const [isEditReportModalOpen, setIsEditReportModalOpen] = useState(false)
    const [isEditGroupByRowReportModalOpen, setIsEditGroupByRowReportModalOpen] = useState(false)
    const [isRepopulateReportModalOpen, setIsRepopulateReportModalOpen] = useState(false)
    const [totalCount, setTotalCount] = useState(0)
    const [columns, setColumns] = useState<ColumnsType<any>>([])
    const [filter, setFilter] = useState([])
    const [selectedDates, setSelectedDates] = useState<any>([])
    const [filterType, setFilterType] = useState("ALL")
    const [filterTypeId, setFilterTypeId] = useState([])
    const [reportViewMode, setReportViewMode] = useState<any>("table")
    const [expandedRowKeys, setExpandedRowKeys] = useState([])
    const [editDepositWithdrawalProps, setEditDepositWithdrawalProps] = useState({isOpen: false})

    const prevFilterRef = useRef(filter)

    const plReportId = "6543837c534e1f5cafb4abfc"
    const isPLReport = selectedReportID === plReportId

    let fromDate = selectedDates[0]?.format("YYYY-MM-DD")
    let toDate = selectedDates[1]?.format("YYYY-MM-DD")

    // const getReportSummary = async () => {
    //     try {
    //         setLoading(true);
    //         let reportInfo = reportingAll?.find((report) => report?.reportId === selectedReportID);
    //         setReportSummaryData(reportInfo);
    //         return reportInfo;
    //     } catch (err) {
    //         console.log(err);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const reportSummaryData = useMemo(() => {
        let reportInfo = reportingAll?.find((report) => report?.reportId === selectedReportID)
        return reportInfo
    }, [reportingAll, selectedReportID])

    const resetReport = async () => {
        try {
            setPage(1)
            setOrderBy(null)
            setColumns([])
            resetFilters()
            if (!paramDisplayMode) {
                onDisplayModeChg("SUMUP")
            }
            if (isPLReport) {
            // if (isPLReport || reportSummaryData?.isGroupByRowReport) {
                setReportViewMode("tree")
            } else {
                setReportViewMode("table")
            }
        } catch (err) {}
    }

    const getInitialReportSettings = async () => {
        try {
            await resetReport()
            // const summaryData = await getReportSummary();
            await getReportFields()
            if (reportSummaryData && Object.keys(reportSummaryData).length > 0 && !paramFromDate && !paramToDate) {
                const dates = getDatesValueFromRangePreset(reportSummaryData?.defaultDateFilter)
                let fromDate = dayjs(dates?.[0])?.format("YYYY-MM-DD")
                let toDate = dayjs(dates?.[1])?.format("YYYY-MM-DD")
                setSearchParams((prevState) => {
                    const searchParamsObj = getSearchParamsObj(prevState)
                    return {...searchParamsObj, fromDate, toDate}
                })
                setSelectedDates(dates)
            }
            if (reportSummaryData?.orderByColumnName && reportSummaryData?.orderByDirection) {
                setOrderBy({
                    fieldName: reportSummaryData?.orderByColumnName,
                    operator: reportSummaryData?.orderByDirection,
                    value: null,
                })
            }

            const searchParamsObj: any = getSearchParamsObj(searchParams)
            let searchParamKey = Object.keys(searchParamsObj)

            //Check if search parameters have filterType & filterTypeId
            if (paramFilterType && paramFilterTypeId) {
                setFilterType(paramFilterType)
                setFilterTypeId(paramFilterTypeId)
            }

            let paramFilters: any = []
            reportFields.map((field: any) => {
                if (searchParamKey?.includes(field?.reportFieldName)) {
                    paramFilters.push({
                        fieldName: field?.reportFieldName,
                        operator: "CONTAINS",
                        value: [searchParamsObj[field?.reportFieldName]],
                    })
                }
            })
            if (paramFilters?.length > 0) {
                setFilter(initialFilter ? initialFilter.concat(paramFilters) : paramFilters)
            }
        } catch (error) {
        } finally {
        }
    }

    const getReportFields = async () => {
        try {
            if (selectedReportID) {
                const response = await getReportingFieldsByReportId(selectedReportID)
                if (response?.getReportingFieldsByReportId?.__typename === "ReportingDataAry") {
                    setReportFields(response?.getReportingFieldsByReportId?.data)
                }
            }
        } catch (err) {
            console.log(err)
        } finally {
        }
    }

    const getReportDetailsRequestBody = async () => {
        let amendedFilters: any = []
        if (orderBy !== null) {
            amendedFilters = [...amendedFilters, orderBy]
        }
        if (filter?.length > 0) {
            amendedFilters = [...amendedFilters, ...filter]
        }
        let data: any = {
            filters: amendedFilters?.length > 0 ? amendedFilters : null,
        }
        if (filterType !== "ALL" && filterTypeId?.length > 0) {
            let entity
            let operation
            switch (filterType) {
                case "USERNETWORK":
                    entity = "USER"
                    operation = "NETWORK"
                    break
                case "USERNETWORKONLY":
                    entity = "USER"
                    operation = "NETWORK ONLY"
                    break
                case "DESK":
                    entity = "DESK"
                    operation = "SINGLE"
                    break
                case "DESKNETWORK":
                    entity = "DESK"
                    operation = "NETWORK"
                    break
                case "DESKNETWORKONLY":
                    entity = "DESK"
                    operation = "NETWORK ONLY"
                    break
                case "SALESREPNETWORK":
                    entity = "SALESREP"
                    operation = "NETWORK"
                    break
                case "SALESREPNETWORKONLY":
                    entity = "SALESREP"
                    operation = "NETWORK ONLY"
                    break
                default:
                    break
            }
            data = {
                ...data,
                entity: entity, // USER, DESK, SALESREP
                operation: operation, // SINGLE, NETWORK, NETWORK ONLY
                id: filterTypeId,
                // For SINGLE => desk id, user id, sales rep id
                // For NETWORK / NETWORK ONLY => desk id, user network id, sales rep network id
            }
        }
        return data
    }

    const getReportData = async () => {
        try {
            setReportDataLoading(true)
            const data = await getReportDetailsRequestBody()
            const reportId = selectedReportID?.toString()
            let isTreeView = reportViewMode === "tree" && (isPLReport || reportSummaryData?.isGroupByRowReport)
            // let isTreeView = reportViewMode === "tree" && reportSummaryData?.isTreeViewEnabled
            const response: any = await getReportingDetails(
                reportId,
                data,
                fromDate,
                toDate,
                page,
                rows,
                displayMode,
                null,
                null,
                true,
                null, // server plantId - gtech / blueocean
                true,
                isTreeView
            )
            if (response?.getReportingDetails?.__typename === "ReportingDataAry") {
                let reportTotalRowData: any = {}
                const excludedKeysList = ["brandName", "payload", "_id", "payloadType", "rows", "status", " statusCode", "totalcount"]
                const reportPayloadData = response?.getReportingDetails?.res?.payload
                if (reportPayloadData) {
                    Object.keys(reportPayloadData)?.map((key) => {
                        if (!excludedKeysList.includes(key)) {
                            reportTotalRowData[key] = reportPayloadData[key]
                        }
                    })
                }
                let data = {
                    reportId: reportId,
                    reportData: response?.getReportingDetails?.res?.payload?.payload,
                    reportTotalRowData: reportTotalRowData,
                    totalCount: response?.getReportingDetails?.res?.totalCount,
                }
                return data
            }
        } catch (err) {
            console.log(err)
        } finally {
            // setReportDataLoading(false);
        }
    }

    const handleExpand = (expanded, record) => {
        // Update the expandedRowKeys state based on the expanded row
        const keys: any = expanded ? [...expandedRowKeys, record?._id?.displayValue] : expandedRowKeys.filter((key) => key !== record?._id?.displayValue)

        setExpandedRowKeys(keys)
    }

    const toggleEditModal = () => {
        if (reportSummaryData?.isGroupByRowReport) {
            setIsEditGroupByRowReportModalOpen((prevState) => !prevState)
        } else {
            setIsEditReportModalOpen((prevState) => !prevState)
        }
    }

    const toggleRepopulateReportModal = () => {
        setIsRepopulateReportModalOpen((prevState) => !prevState)
    }

    const onChangePageHandler = (page, pageSize) => {
        setPage(page)
        setRows(pageSize)
    }

    const onRangePickerChange: any = (dates: any, dateStrings: any[]) => {
        if (dates) {
            let fromDate = dayjs(dates?.[0])?.format("YYYY-MM-DD")
            let toDate = dayjs(dates?.[1])?.format("YYYY-MM-DD")
            setSearchParams((prevState) => {
                const searchParamsObj = getSearchParamsObj(prevState)
                return {...searchParamsObj, fromDate, toDate}
            })
            setSelectedDates(dates)
        } else {
            console.log("Clear")
        }
    }

    const onDisplayModeChg = (value) => {
        try {
            setSearchParams((prevState) => {
                const searchParamsObj = getSearchParamsObj(prevState)
                return {...searchParamsObj, displayMode: value}
            })
            setReportData([])
            setDisplayMode(value)
        } catch (error) {}
    }

    const onFilterTypeChg = (value) => {
        try {
            setFilterType(value)
            if (filterTypeId?.length > 0) {
                setFilterTypeId([])
            }
        } catch (error) {}
    }

    // const getPresetDateFilter = () => {
    //     try {
    //         if (reportSummaryData && Object.keys(reportSummaryData).length > 0) {
    //             const dates = getDatesValueFromRangePreset(reportSummaryData?.defaultDateFilter);
    //             setSelectedDates(dates);
    //         }
    //     } catch (error) {}
    // };

    const handleExportReport = async () => {
        try {
            setLoading(true)
            let userId = localStorage?.getItem("userId")
            let data = await getReportDetailsRequestBody()

            let fromDate = selectedDates[0]?.format("YYYY-MM-DD")
            let toDate = selectedDates[1]?.format("YYYY-MM-DD")

            let fieldIdsToBeExported = columns?.map((col: any) => col?.fieldId)?.filter((v: any) => !!v)
            data.fieldIdsToBeExported = fieldIdsToBeExported
            delete data.filters

            const requestBody = {
                id: selectedReportID?.toString(),
                data: data,
                fromDate,
                toDate,
                displayMode,
                user: userId,
                page: null,
                rows: null,
                filterNetwork: true,
                isTestAccountExcluded: true,
            }

            const response: any = await exportReport(requestBody)
            if (response) {
                const fileName = reportSummaryData?.displayName + "_" + moment().format("yyyyMMDD_HHmmss") + ".csv"
                fileDownload(response, fileName)
            }
        } catch (err) {
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    const resetFilters = () => {
        setFilter(initialFilter ? initialFilter : [])
        setFilterType("ALL")
        setFilterTypeId([])
    }

    useEffect(() => {
        if (selectedReportID) {
            getInitialReportSettings()
        }
    }, [selectedReportID, reportSummaryData])

    useEffect(() => {
        getReportFields()
    }, [isEditReportModalOpen, isEditGroupByRowReportModalOpen])

    useEffect(() => {
        if (paramFromDate && paramToDate) {
            setSelectedDates([dayjs(paramFromDate), dayjs(paramToDate)])
        }
    }, [paramFromDate, paramToDate])

    useEffect(() => {
        if (paramDisplayMode) {
            setDisplayMode(paramDisplayMode)
        }
    }, [paramDisplayMode])

    useEffect(() => {
        // if (reportSummaryData?.groupByType === "DESK" && displayMode === "SUMUP") {
        if (reportViewMode === "tree" && (isPLReport || reportSummaryData?.isGroupByRowReport)) {
            resetFilters()
            setRows(50)
        } else {
            setRows(20)
        }
    }, [reportViewMode, selectedReportID])

    useEffect(() => {
        setPage(1)
    }, [reportViewMode, displayMode])

    // useEffect(() => {
    //     if (fromDate && toDate) {
    //         // setRefreshReportData(true);
    //         setReportData([]);
    //         setReportTotalRowData({});
    //         setTotalCount(0);
    //     }
    // }, [selectedReportID, page, rows, selectedDates, filter, displayMode, reportingAll, orderBy, reportViewMode, filterTypeId]);

    useEffect(() => {
        let cancelled = false
        if (selectedReportID && page && rows && selectedDates?.length > 0 && displayMode && reportViewMode) {
            getReportData().then(
                (data) => {
                    if (!cancelled) {
                        setReportData(data?.reportData)
                        setReportTotalRowData(data?.reportTotalRowData)
                        setTotalCount(data?.totalCount)
                        setReportDataLoading(false)
                    } else {
                        console.log("Cancelled")
                    }
                },
                (error) => {
                    if (!cancelled) {
                        console.log(error)
                    }
                }
            )
        }
        return () => {
            cancelled = true
        }
    }, [selectedReportID, page, rows, selectedDates, displayMode, orderBy, reportViewMode, refreshReportData, filterTypeId?.length, isEditGroupByRowReportModalOpen])

    useEffect(() => {
        const prevArrayFilter = prevFilterRef.current

        // Function to compare two arrays for changes
        const arraysAreDifferent = (arr1, arr2) => {
            if (arr1.length !== arr2.length) return true
            for (let i = 0; i < arr1.length; i++) {
                if (arr1[i] !== arr2[i]) return true
            }
            return false
        }

        if (arraysAreDifferent(prevArrayFilter, filter)) {
            // Perform the action when the array has changed
            // console.log("Array has changed:", filter);
            // Update the ref to the new array
            prevFilterRef.current = filter

            getReportData().then(
                (data) => {
                    setReportData(data?.reportData)
                    setReportTotalRowData(data?.reportTotalRowData)
                    setTotalCount(data?.totalCount)
                    setReportDataLoading(false)
                },
                (error) => {
                    console.log(error)
                }
            )
        }
    }, [filter])

    useEffect(() => {
        if (!fromModal) {
            getReportCategory()
            refreshAllReportData()
        }
    }, [isEditReportModalOpen, isEditGroupByRowReportModalOpen])

    useEffect(() => {
        setupReportColumns(
            reportFields,
            reportingAllTypes,
            displayMode,
            reportViewMode,
            setColumns,
            reportSummaryData,
            isMasterAdmin,
            expandedRowKeys,
            currencySetting,
            filter,
            fromDate,
            toDate,
            isPLReport,
            setEditDepositWithdrawalProps,
            renderTransactionTypeColumn
        )
    }, [reportFields, reportingAllTypes, displayMode, reportViewMode, reportSummaryData, isMasterAdmin, expandedRowKeys?.length, currencySetting, filter, fromDate, toDate])

    const isClearFilterVisible = Object.keys(filter)?.length > 0 || (filterType !== "ALL" && filterTypeId?.length)

    const amendedReportData = useMemo(() => {
        let data: any = reportData
        if (reportSummaryData?.groupByType === "DESK" && displayMode === "SUMUP" && reportViewMode === "tree") {
            data = data?.map(removeEmptyChildren)
        }
        return data
    }, [reportData, reportSummaryData, deskList, reportViewMode, displayMode])

    useEffect(() => {

        // if (reportViewMode === "tree") {
        // Initialize the expandedRowKeys state with the keys of the first two levels
        const initialKeys: any = amendedReportData?.filter((record: any) => record?.children && record?.children?.length > 0)?.map((record: any) => record?._id?.displayValue)
        // console.log(amendedReportData, initialKeys)

        setExpandedRowKeys(initialKeys)
        // }
    }, [amendedReportData, reportViewMode])

    return (
        <div className="margin-top-0-5">
            {!fromModal && (
                <>
                    {isEditReportModalOpen && (
                        <CreateEditReportModal
                            formMode={"edit"}
                            isModalOpen={isEditReportModalOpen}
                            toggleModal={toggleEditModal}
                            selectedReportID={selectedReportID}
                            reportSummaryData={reportSummaryData}
                            reportFields={reportFields}
                            getAllCreateEditModalData={getAllCreateEditModalData}
                        />
                    )}
                    {isEditGroupByRowReportModalOpen && (
                        <GroupByRowReportModal
                            formMode={"edit"}
                            isModalOpen={isEditGroupByRowReportModalOpen}
                            toggleModal={toggleEditModal}
                            selectedReportID={selectedReportID}
                            // setSelectedReportID={setSelectedReportID}
                            // setSelectedCategoryID={setSelectedCategoryID}
                            reportSummaryData={reportSummaryData}
                        />
                    )}
                    {isRepopulateReportModalOpen && <RepopulateReportModal isModalOpen={isRepopulateReportModalOpen} toggleModal={toggleRepopulateReportModal} reportSummaryData={reportSummaryData} getAllReports={getAllReports} />}
                    {selectedReportID === "64d0a107847e88a7d5978e3c" && (
                        <EditDepositWithdrawalModal reportsPermission={reportsPermission} modalProps={editDepositWithdrawalProps} setProps={setEditDepositWithdrawalProps} reportingAll={reportingAll} setRefreshReportData={setRefreshReportData} />
                    )}
                    <ReportTopBar
                        selectedReportID={selectedReportID}
                        plReportId={plReportId}
                        columns={columns}
                        reportSummaryData={reportSummaryData}
                        reportsPermission={reportsPermission}
                        onFilterTypeChg={onFilterTypeChg}
                        filterType={filterType}
                        toggleEditModal={toggleEditModal}
                        toggleRepopulateReportModal={toggleRepopulateReportModal}
                        displayMode={displayMode}
                        onDisplayModeChg={onDisplayModeChg}
                        onRangePickerChange={onRangePickerChange}
                        selectedDates={selectedDates}
                        filterTypeId={filterTypeId}
                        setFilterTypeId={setFilterTypeId}
                        handleExportReport={handleExportReport}
                        reportViewMode={reportViewMode}
                        setReportViewMode={setReportViewMode}
                        setRefreshReportData={setRefreshReportData}
                        isClearFilterVisible={isClearFilterVisible}
                        resetFilters={resetFilters}
                        reportDataloading={reportDataloading}
                        resetTableName={reportSummaryData?.commissionReportType === "COMMISSION_PAYOUT_REPORT" && reportSummaryData?.commissionCalculationReportId ? reportSummaryData?.commissionCalculationReportId : null}
                    />
                </>
            )}
            {/*{
                fromModal &&
                <Row justify={"end"} className={"margin-bottom-0-75"}>
                   <EditTableUserPreferenceButton tableName={tableName || selectedReportID} tableColumn={columns} displayTableName={reportSummaryData?.displayName} />
                </Row>
            */}
            <TableReportAntDesign
                tableName={tableName || selectedReportID}
                size="small"
                loading={loading || reportDataloading}
                columns={columns}
                data={amendedReportData}
                displayMode={displayMode}
                filter={setFilter}
                order={setOrderBy}
                rowKey={(record) => `${record?._id?.displayValue}${(isPLReport && reportViewMode === "tree") ? "" : Math.floor(1000000000 + Math.random() * 9000000000)}`}
                showSorterTooltip={false}
                rowClassName={(record) => {
                    let tradingAccReportId = "64d0a107847e88a7d5978e3c"
                    let ewalletSummaryReportId = "64f198a729653b86fb6a76f2"
                    if (selectedReportID === tradingAccReportId) {
                        if (record?.checkMatching?.displayValue !== 0 && record?.openedPositions?.displayValue === 0) {
                            return "rowHighlighted"
                        }
                    }
                    if (selectedReportID === ewalletSummaryReportId) {
                        if (record?.checkMatching?.displayValue !== 0) {
                            return "rowHighlighted"
                        }
                    }
                }}
                sort={reportViewMode === "tree" ? false : undefined}
                scroll={{x: "max-content"}}
                pagination={{
                    current: page,
                    pageSize: rows,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                    total: totalCount,
                    showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
                }}
                summaryData={reportTotalRowData}
                expandable={{
                    expandedRowKeys: expandedRowKeys,
                    onExpand: handleExpand,
                }}
            />
            {/* )} */}
        </div>
    )
}

export default ReportComponent
