import {gql, useMutation, useQuery} from "@apollo/client";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {useTranslation} from "react-i18next";

const GET_ICON_LIST = gql`
  query getIconList($filter: IconListSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
    getIconList(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy){
      ... on IconListArray {
        data {
          _id
          id
          name
          type
          label
          iconLightActive {
            fileList
            path
          }
          iconLightInactive {
            fileList
            path
          }
          iconDarkActive {
            fileList
            path
          }
          iconDarkInactive {
            fileList
            path
          }
          defaultIconLightActive {
            fileList
            path
          }
          defaultIconLightInactive {
            fileList
            path
          }
          defaultIconDarkActive {
            fileList
            path
          }
          defaultIconDarkInactive {
            fileList
            path
          }
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getIconList = (filter?, limit?, offset?, orderBy?) => {
    try{
        const { loading, error, data } = useQuery(GET_ICON_LIST, {
            variables: {
                filter: filter || null,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return { loading, error, data }
    }
    catch(err: any){
        console.error(err);
        return { data: null, loading: false, error: err };
    }
}

const UPDATE_ICON_LIST = gql`
mutation updateIconList($input: IconListInput) {
    updateIconList(input: $input){
      ... on IconList {
        _id
        id
        name
        type
        label
        iconLightActive {
          fileList
          path
        }
        iconLightInactive {
          fileList
          path
        }
        iconDarkActive {
          fileList
          path
        }
        iconDarkInactive {
          fileList
          path
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useUpdateIconListMutation = (): { updateIconList: any, data: any, loading: boolean, error: any } => {
    try{
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [updateIconListMutation, { data, loading, error }] = useMutation(UPDATE_ICON_LIST);

        const updateIconList = (input) => {
            return updateIconListMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {updateIconList}}) {
                    const typename = updateIconList?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateIconList?.errKey),
                            key: "updateIconList",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateIconList?.msg),
                            key: "updateIconList",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Icon Successfully"),
                            key: "updateIconList",
                        });
                    }
                },
            });
        };

        return { updateIconList, data, loading, error }
    }
    catch(err: any){
        console.error(err);
        return { updateIconList: null, data: null, loading: false, error: err };
    }
}

export {
    getIconList,
    useUpdateIconListMutation
}