import React, {useEffect, useState} from "react";
import {Modal} from "antd";
import EWalletDeposit from "./eWalletDeposit";
import EWalletWithdrawal from "./eWalletWithdrawal";
import EWalletTransfer from "./eWalletTransfer";
import EWalletAdjustment from "./eWalletAdjustment";
import AccountTransaction from "./accountTransaction";
import {useTranslation} from "react-i18next";

const EWalletDetailModal = (props) => {
    const {isOpen, close, record} = props
    const {t} = useTranslation()

    const [type, setType] = useState<any>()
    const [title, setTitle] = useState<any>()

    useEffect(() => {
        switch (record?.type) {
            case "deposit":
                setType("deposit")
                setTitle(t("task.eWalletDeposit"))
                break
            case "withdrawal":
                setType("withdrawal")
                setTitle(t("task.ewallet_withdrawal"))
                break
            case "account_deposit":
                setType("tradingAccount")
                setTitle(t("clientUser.tradingAccountDeposit"))
                break
            case "account_withdrawal":
                setType("tradingAccount")
                setTitle(t("clientUser.tradingAccountWithdrawal"))
                break
            case "transfer-receiver":
            case "transfer-sender":
                setType("transfer")
                setTitle(t("task.eWalletTransfer"))
                break
            case "adjustment-deposit":
            case "adjustment-withdrawal":
                setType("adjustment")
                setTitle(t("task.ewallet_adjustment"))
                break
        }
    },[record])

    return (
        <Modal
            title={title}
            open={isOpen}
            width={"80%"}
            footer={null}
            destroyOnClose
            getContainer="#clientDetail"
            onCancel={close}
        >
            {
                type === "deposit" ? <EWalletDeposit record={record}/> :
                    type === "withdrawal" ? <EWalletWithdrawal record={record}/> :
                        type === "transfer" ? <EWalletTransfer record={record}/> :
                            type === "adjustment" ? <EWalletAdjustment record={record}/> :
                                type === "tradingAccount" ? <AccountTransaction record={record}/> :
                                <></>
            }
        </Modal>
    )
};

export default EWalletDetailModal