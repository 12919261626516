import {Button, Modal, Row, Tabs} from "antd";
import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {useSetRecoilState} from "recoil";
import * as yup from "yup";
import PspSettingForm from "./pspSettingForm";
import PspSettingFormModalTabItem from "./pspSettingFormModalTabItem";
import {yupResolver} from "@hookform/resolvers/yup";
// import yupFormSchema from "../../../../../../components/yup/yupFormSchema";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import {
    setupPspSettingCurrencyForm,
    setupPspSettingCurrencyInput,
    setupPspSettingDetailForm,
    setupPspSettingDetailInput,
    setupPspSettingForm,
    setupPspSettingInput,
} from "../../../function/common";
import {useAddPspSettingFormMutation, useUpdatePspSettingFormMutation} from "../../../../../../api/graphql/pspSetting";
import {refetchQuery} from "../../../../../../function/refetchQuery";
import {notificationMessage} from "../../../../../../../recoil_state";
import {getConfigByName} from "../../../../../../../config";
import {BRAND_NAME} from "../../../../../../../config/brandVariable";
import DescriptionModal from "./descriptionModal";

const yupSchemaPspSetting = yup.object().shape({
    // selectedCurrency: yupFormSchema.string("selectedCurrency", { required: true }),
    //   enabled: yupFormSchema.boolean("enabled"),
});

const yupSchemaPspSettingCurrency = yup.object().shape({
    // name: yupFormSchema.string("name", { required: true }),
    //   enabled: yupFormSchema.boolean("enabled"),
});
const yupSchemaPspSettingDetail = yup.object().shape({
    // name: yupFormSchema.string("name", { required: true }),
    //   enabled: yupFormSchema.boolean("enabled"),
});

const PspSettingFormModal = (props) => {
    const {selectedRow, formMode, formPsp, isFormModalOpen, toggleFormModal, pspCurrencyList, pspSettingList} = props;
    const {t} = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [activeTab, setActiveTab] = useState("custom");
    const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
    const [descriptionModalMode, setDescriptionModalMode] = useState("");
    const [depositDescriptionValue, setDepositDescriptionValue] = useState([]);
    const [withdrawalDescriptionValue, setWithdrawalDescriptionValue] = useState([]);
    const [selectedPsp, setSelectedPsp] = useState<any>({currencyList: []});
    const [selectedCurrencies, setSelectedCurrencies] = useState([]);
    const {updatePspSettingForm} = useUpdatePspSettingFormMutation();
    const {addPspSettingForm} = useAddPspSettingFormMutation();

    let pspSettingId = useMemo(() => selectedPsp?.pspSetting?._id, [selectedPsp?.pspSetting?._id]);

    let pspSettingName = useMemo(() => selectedPsp?.pspSetting?.name, [selectedPsp?.pspSetting?.name]);

    let modalTitle = useMemo(() => `${t(`common.${formMode}`)} PSP Setting - ${pspSettingName}`, [formMode, pspSettingName]);

    const pspSettingForm = useForm({
        resolver: yupResolver(yupSchemaPspSetting),
        mode: "all",
    });

    const pspSettingCurrencyForm = useForm({
        resolver: yupResolver(yupSchemaPspSettingCurrency),
        mode: "all",
    });

    const pspSettingDetailForm = useForm({
        resolver: yupResolver(yupSchemaPspSettingDetail),
        mode: "all",
    });

    const closeModal = () => {
        toggleFormModal("");
    };

    const toogleDescriptionModal = (mode = "") => {
        setDescriptionModalMode(mode);
        setIsDescriptionModalOpen((prevState) => !prevState);
    };

    const handleSelectedCurChg = (value) => {
        setSelectedCurrencies(formPsp === "BankWire" ? value : [value]);
    };

    const handleAllFormSubmit = async () => {
        try {
            // pspSettingForm
            const pspSettingValues = pspSettingForm.getValues();
            const pspSettingFormResult = setupPspSettingInput(formMode, pspSettingValues);

            // pspCurrencyForm
            const pspCurrencyValues = pspSettingCurrencyForm.getValues();
            const pspSettingCurrencyFormResult = setupPspSettingCurrencyInput(formMode, pspCurrencyValues, pspSettingId, selectedCurrencies);

            // pspSettingDetailForm
            const pspSettingDetailValues = pspSettingDetailForm.getValues();
            const pspSettingDetailFormResult = setupPspSettingDetailInput(
                pspSettingName,
                formMode,
                pspSettingDetailValues,
                pspSettingId,
                selectedCurrencies
            );

            // result
            const result = {
                pspSetting: {...pspSettingFormResult, depositDescription: depositDescriptionValue, withdrawalDescription: withdrawalDescriptionValue},
                pspSettingCurrency: pspSettingCurrencyFormResult,
                pspSettingDetail: pspSettingDetailFormResult,
            };
            // console.log("result");
            // console.log(result);

            const response = formMode === "add" ? await addPspSettingForm(result) : await updatePspSettingForm(result);
            if (
                response?.data?.addPspSettingForm?.__typename === "PspSettingForm" ||
                response?.data?.updatePspSettingForm?.__typename === "PspSettingForm"
            ) {
                refetchQuery("getPspSetting");
                refetchQuery("getPspSettingDetail");
                closeModal();
            }
        } catch (error: any) {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: t(error?.message),
                key: "pspSettingFormModal",
            });
        }
    };

    useEffect(() => {
        if (selectedRow) {
            setSelectedPsp(selectedRow);
        } else {
            let localDepoBankWireList = pspSettingList?.filter((v) => v?.name?.includes(formPsp));
            let localDepoBankWireNo =
                localDepoBankWireList && localDepoBankWireList?.length > 0
                    ? Number(
                          [...localDepoBankWireList]
                              ?.sort((a, b) => a?.name?.split(formPsp)[1] - b?.name?.split(formPsp)[1])
                              ?.pop()
                              ?.name?.split(formPsp)[1]
                      ) + 1
                    : "1";

            let pspName = formPsp + localDepoBankWireNo;
            setSelectedPsp({
                pspSetting: {
                    name: pspName,
                },
            });
        }
    }, [selectedRow, pspSettingList]);

    useEffect(() => {
        if (selectedCurrencies.length > 0) {
            // pspSettingCurrencyForm.reset();
            // pspSettingDetailForm.reset();
            setSelectedPsp((prevState) => {
                let amendedCurrencyList = selectedCurrencies?.map((cur) => {
                    let currencyObjIndex = prevState?.currencyList?.findIndex((v) => v?.pspCurrency?.currency === cur);
                    if (currencyObjIndex >= 0) {
                        return prevState?.currencyList[currencyObjIndex];
                    } else {
                        return {
                            pspCurrency: {
                                currency: cur,
                            },
                            children: [
                                {
                                    paymentMethod: prevState?.pspSetting?.name,
                                    pspCurrency: {
                                        currency: cur,
                                    },
                                },
                            ],
                        };
                    }
                });
                return {
                    ...prevState,
                    currencyList: amendedCurrencyList,
                };
            });
        } else if (!selectedCurrencies.length && formPsp === "BankWire") {
            setSelectedPsp((prevState) => {
                return {
                    ...prevState,
                    currencyList: [],
                };
            });
        }
    }, [selectedCurrencies]);

    useEffect(() => {
        if (selectedPsp) {
            setupPspSettingForm(pspSettingForm, selectedPsp?.pspSetting);
            setupPspSettingCurrencyForm(pspSettingCurrencyForm, selectedPsp?.currencyList);
            setupPspSettingDetailForm(pspSettingDetailForm, selectedPsp?.currencyList);
            const depDescpValue = depositDescriptionValue?.length > 0 ? depositDescriptionValue : selectedPsp?.pspSetting?.depositDescription;
            const withDescpValue =
                withdrawalDescriptionValue?.length > 0 ? withdrawalDescriptionValue : selectedPsp?.pspSetting?.withdrawalDescription;
            setDepositDescriptionValue(depDescpValue);
            setWithdrawalDescriptionValue(withDescpValue);
        }
    }, [selectedPsp]);

    useEffect(() => {
        let backendURL = getConfigByName(BRAND_NAME).backendURL;
        let logoType = formPsp === "BankWire" ? "bankwire" : "local_depo";

        const logoURL = `${backendURL}/assets/icon/psp/${logoType}.png`;
        const logoSmallURL = `${backendURL}/assets/icon/psp/${logoType}_small.png`;

        pspSettingForm.setValue("logo_light", logoURL);
        pspSettingForm.setValue("logo_dark", logoURL);
        pspSettingForm.setValue("logoSmall_light", logoSmallURL);
        pspSettingForm.setValue("logoSmall_dark", logoSmallURL);
    }, [formPsp]);

    const tabItems = selectedPsp?.currencyList?.map((curItem, index) => ({
        key: curItem?.pspCurrency?.currency,
        label: `${curItem?.pspCurrency?.currency}`,
        children: (
            <PspSettingFormModalTabItem
                index={index}
                formMode={formMode}
                pspSettingName={pspSettingName}
                pspCurrencyData={curItem?.pspCurrency}
                paymentMethodList={curItem?.children}
                pspSettingCurrencyForm={pspSettingCurrencyForm}
                pspSettingDetailForm={pspSettingDetailForm}
            />
        ),
    }));

    let isSubmitBtnDisabled = formMode === "add" ? !selectedCurrencies.length : false;

    return (
        <Modal
            className="pspSetting-editor-1"
            title={modalTitle}
            open={isFormModalOpen}
            footer={null}
            destroyOnClose
            onCancel={closeModal}
            maskClosable={false}
            getContainer="#PspSettingTable"
        >
            {isDescriptionModalOpen && (
                <DescriptionModal
                    formMode={formMode}
                    descriptionModalMode={descriptionModalMode}
                    value={descriptionModalMode === "deposit" ? depositDescriptionValue : withdrawalDescriptionValue}
                    setValue={descriptionModalMode === "deposit" ? setDepositDescriptionValue : setWithdrawalDescriptionValue}
                    isModalOpen={isDescriptionModalOpen}
                    toggleModal={toogleDescriptionModal}
                />
            )}
            <PspSettingForm
                form={pspSettingForm}
                formMode={formMode}
                formPsp={formPsp}
                item={selectedRow?.pspSetting}
                pspSettingName={pspSettingName}
                pspCurrencyList={pspCurrencyList}
                handleSelectedCurChg={handleSelectedCurChg}
                toogleDescriptionModal={toogleDescriptionModal}
            />

            {tabItems?.length > 0 ? (
                <Tabs
                    // defaultActiveKey=""
                    // activeKey={activeTab}
                    onChange={(activeKey) => setActiveTab(activeKey)}
                    items={tabItems}
                />
            ) : null}

            <Row justify="end">
                <Button onClick={closeModal} icon={<CloseOutlined />} className="cancelBtn">
                    {formMode === "view" ? t("Close") : t("common.cancel")}
                </Button>
                {formMode !== "view" && (
                    <Button onClick={handleAllFormSubmit} icon={<SaveOutlined />} className="button-submit-1" disabled={isSubmitBtnDisabled}>
                        {t("common.submit")}
                    </Button>
                )}
            </Row>
        </Modal>
    );
};

export default PspSettingFormModal;
