import {Button, Form} from "antd";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import FormErrors from "./formErrors";
import {PlusOutlined, UploadOutlined} from "@ant-design/icons";
import {Modal, Upload} from "antd";
import {getConfigByName} from "../../../config";
import {BRAND_NAME} from "../../../config/brandVariable";
// import { Document, Page  } from 'react-pdf/dist/esm/entry.webpack5'

const PDFUploadFormItem = (props) => {
    const {
        layout,
        label,
        name,
        externalErrorMessage,
        onChange,
        hint,
        required,
        listType,
        maxLength,
        className,
        fileList,
        onRemove,
        disabled,
        mode,
        setErrMsg,
    } = props;

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const brandConfig = getConfigByName(BRAND_NAME);

    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
    };

    const {
        register,
        watch,
        setValue,
        formState: {touchedFields, isSubmitted, errors},
    } = useFormContext();

    const errorMessage = FormErrors.errorMessage(name, label, errors, touchedFields, isSubmitted, externalErrorMessage);

    const formHelperText = errorMessage || hint;

    useEffect(() => {
        register(name);
    }, [register, name]);

    const getBase64 = (file): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
        if (!file.link && !file?.response?.[0]?.link && !file?.preview) {
            file.preview = await getBase64(file?.thumbUrl);
        }
        if (mode === "pdf") {
            window.open(file?.response?.[0]?.link || file?.link);
        } else {
            setPreviewImage(file?.response?.[0]?.link || file?.preview || file?.link);
            setPreviewOpen(true);
            setPreviewTitle(file?.name || file?.response?.[0]?.link!.substring(file?.response?.[0]?.link!.lastIndexOf("/") + 1));
        }
    };

    const pictureUploadButton = (
        <div>
            <div className="upload-button-1">
                {" "}
                <PlusOutlined /> Upload ( Max: {maxLength} )
            </div>
        </div>
    );

    const pictureCardUploadButton = (
        <div>
            <PlusOutlined />
            <div style={{marginTop: 8}}>Upload</div>
        </div>
    );

    const textUploadButton = <Button icon={<UploadOutlined />}>Click to Upload</Button>;

    const validFileTypes = {
        pdf: ["application/pdf"],
        image: ["image/png", "image/jpg", "image/jpeg"],
    };

    const allowedFileSize = {
        pdf: 40000000,
        image: 2000000,
    };

    return (
        <Form.Item
            {...layout}
            className={className}
            label={label}
            required={required}
            validateStatus={errorMessage ? "error" : "success"}
            help={formHelperText}
        >
            <Upload
                id={name}
                action={mode === "pdf" ? `${brandConfig?.backendURL}/api/upload/document/pdf` : `${brandConfig?.backendURL}/api/upload/profile/image`}
                listType={listType}
                fileList={fileList || watch(name)}
                onChange={(e) => {
                    setValue(name, e?.fileList, {shouldValidate: true});
                    onChange && onChange(e);
                }}
                onPreview={handlePreview}
                maxCount={maxLength}
                onRemove={(e) => {
                    onRemove && onRemove(e);
                }}
                disabled={disabled}
                beforeUpload={(file) => {
                    const fileType = mode === "pdf" ? "pdf" : "image";
                    const validFileTypeList = validFileTypes[fileType];
                    const isValidFileType = validFileTypeList.includes(file?.type);
                    const isValidFileSize = file?.size <= allowedFileSize[fileType];

                    let errMsg = "";
                    if (!isValidFileType) {
                        errMsg = `${file?.name} is not a valid file`;
                    } else if (!isValidFileSize) {
                        errMsg = "Upload fail. Image file size is more than 2MB.";
                    }
                    setErrMsg(errMsg);
                    return (isValidFileSize && isValidFileType) || Upload.LIST_IGNORE;
                }}
            >
                {listType === "picture" && pictureUploadButton}
                {listType === "picture-card" && pictureCardUploadButton}
                {listType === "text" && textUploadButton}
            </Upload>
            <Modal open={previewOpen} title={previewTitle} footer={null} width={700} onCancel={handleCancel}>
                {mode === "pdf" ? (
                    <>
                        {/*<Document file={previewImage} onLoadSuccess={onDocumentLoadSuccess}> */}
                        {/*    <Page pageNumber={pageNumber}/>*/}
                        {/*</Document>*/}
                        <p>
                            Page {pageNumber} of {numPages}
                        </p>
                    </>
                ) : (
                    <img alt="placeholder" style={{width: "100%"}} src={previewImage} />
                )}
            </Modal>
        </Form.Item>
    );
};

PDFUploadFormItem.defaultProps = {
    required: false,
    disabled: false,
    maxLength: 1,
    mode: "image",
};

PDFUploadFormItem.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    externalErrorMessage: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    layout: PropTypes.object,
    hint: PropTypes.string,
    listType: PropTypes.string,
    maxLength: PropTypes.number,
    className: PropTypes.string,
    fileList: PropTypes.array,
    onRemove: PropTypes.func,
    mode: PropTypes.string,
    setErrMsg: PropTypes.func,
};

export default PDFUploadFormItem;
