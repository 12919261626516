import React, {useCallback, useEffect, useRef, useState} from "react";
import {Button, Modal, Row, Select, Space} from "antd";
import {useTranslation} from "react-i18next";
import ReportComponent from "./reportComponent";
import {repopulateTradingPlReportByTrader, updateTradingAccountTransactionType} from "../../../../../../api/graphql/reporting";
import useAuthorize from "../../../../../../_common/authorize";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../recoil_state";
import moment from "moment";

const EditDepositWithdrawalModal = ({modalProps, setProps, reportsPermission, reportingAll, setRefreshReportData}) => {
    const {isOpen, accountId, transactionType} = modalProps
    const {t} = useTranslation();
    const {systemErrorNotification} = useAuthorize();
    const selectedReportID = "64d0a107847e88a7d5978e5e"
    const reportName = reportingAll?.find(d => d?.reportId === selectedReportID)?.displayName

    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [reportDataLoading, setReportDataLoading] = useState(true);
    const [initialFilter, setInitialFilter] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [repopulateFailed, setRepopulateFailed] = useState(false)
    const [earliestFromDate, setEarliestFromDate] = useState(null);
    const [tradingAccountId, setTradingAccountId] = useState("");
    const amendedRecordsRef = useRef<any[]>([]);

    const handleTransactionTypeColumnChange = (selected, record) => {
        const initialValue = record?.transactionType?.displayValue;
        const found = amendedRecordsRef.current.find(d => d?.rowId === record?._id?.displayValue);

        if (initialValue === selected) {
            amendedRecordsRef.current = amendedRecordsRef.current.filter(d => d?.rowId !== record?._id?.displayValue);
        } else if (found && selected === found?.newTransactionType) {
            return;
        } else {
            amendedRecordsRef.current = [
                ...amendedRecordsRef.current,
                {
                    rowId: record?._id?.displayValue,
                    newTransactionType: selected,
                }
            ];
        }

        setEarliestFromDate(prevState => {
            const createdAt = record?.createdAt?.displayValue;
            return !prevState || createdAt < prevState ? createdAt : prevState;
        })

        setTradingAccountId(record?.tradingAccountId?.displayValue);
    }

    const renderTransactionTypeColumn = (value, record) => {
        const inAry = ["DEPOSIT", "ADJUSTMENT IN"]
        const outAry = ["WITHDRAWAL", "ADJUSTMENT OUT"]
        const amendedRecord = amendedRecordsRef.current.find(d => d?.rowId === record?._id?.displayValue)

        return reportsPermission?.edit?.edit ? (
            <Select
                defaultValue={amendedRecord?.newTransactionType || value?.displayValue}
                options={(inAry.includes(value?.displayValue) ? inAry : outAry).map(option => ({
                    label: option,
                    value: option
                }))}
                onChange={selected => handleTransactionTypeColumnChange(selected, record)}
            />
        ) : (
            <p>{value?.displayValue}</p>
        )
    }

    useEffect(() => {
        if (accountId && transactionType) {
            let type
            switch (transactionType) {
                case "deposits":
                    type = ["DEPOSIT"]
                    break
                case "withdrawals":
                    type = ["WITHDRAWAL"]
                    break
                case "adjustmentDaily":
                    type = ["ADJUSTMENT IN", "ADJUSTMENT OUT"]
                    break
            }

            setInitialFilter([
                {
                    fieldName: "accountNo",
                    operator: "E",
                    value: [accountId.toString()]
                },
                {
                    fieldName: "transactionType",
                    operator: "E",
                    value: type
                }
            ])
        }
    }, [accountId, transactionType]);

    const repopulateReport = async () => {
        setLoading(true)

        // const reportIds = ["64d0a107847e88a7d5978e5e", "64d0a107847e88a7d5978e3c", "6543837c534e1f5cafb4abfc"]
        // const populationPaths = reportingAll?.filter(d => reportIds.includes(d?.reportId))?.map(d => d?.populationPath)
        //
        // const promises = populationPaths.map(async path => {
        //     const response = await repopulateReportData({
        //         fromDate: moment(earliestFromDate).utc().format("YYYY-MM-DD"),
        //         toDate: dayjs()?.format("YYYY-MM-DD"),
        //         populationPath: path
        //     })
        //
        //     if (response?.repopulateReportData?.data?.statusCode !== 200) {
        //         setRepopulateFailed(true)
        //         setNotificationMessage({
        //             status: "error",
        //             title: "",
        //             msg: "Repopulate failed, please try again.",
        //             key: "repopulateReport",
        //         });
        //         throw new Error("failed");
        //     }
        //
        //     return response
        // });

        try {
            const {repopulateTradingPlReportByTrader: repopulateResponse} = await repopulateTradingPlReportByTrader(tradingAccountId, moment(earliestFromDate).utc().format("YYYY-MM-DD"))

            if (repopulateResponse?.__typename === "BaseError") {
                setRepopulateFailed(true)
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: `${t(repopulateResponse?.errKey)}: ${repopulateResponse?.errObj?.message}`,
                    key: "repopulateReport",
                });
            } else {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Repopulate Successfully",
                    key: "repopulateReport",
                });

                handleCancel()
            }

            // await Promise.all(promises);
            //
            // setRepopulateFailed(false)
            // setNotificationMessage({
            //     status: "success",
            //     title: "",
            //     msg: "Repopulating. Please wait for a few minutes for the report to be updated.",
            //     key: "repopulateReport",
            // });
            // handleCancel()
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const handleSave = async () => {
        setLoading(true)
        let successRequest: any[] = []
        let failedRequest: any[] = []
        // const promises = amendedRecords.map(async record => {
        //     const result = await updateReportCell(record?.reportId, record?.input);
        //
        //     if (result?.data?.updateReportCell?.data?.statusCode === 200) {
        //         successRequest.push(result?.data?.updateReportCell?.data?.payload)
        //     } else {
        //         failedRequest.push(result?.data?.updateReportCell?.data?.payload)
        //     }
        //
        // });

        try {
            const {updateTradingAccountTransactionType: response} = await updateTradingAccountTransactionType(amendedRecordsRef.current)

            if (response?.__typename === "ReportingData") {
                setRefreshReportData(prev => !prev)
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Report Updated Successfully"),
                    key: "updateReportCellNotification",
                });

                repopulateReport()
            } else {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(`Report Updated Failed, please try again.`),
                    key: "updateReportCellNotification",
                });
            }

            // await Promise.all(promises);
            // if (failedRequest.length === 0) {
            //     setNotificationMessage({
            //         status: "success",
            //         title: "",
            //         msg: t("Report Updated Successfully"),
            //         key: "updateReportCellNotification",
            //     });
            //
            //     await repopulateReport()
            // } else if (failedRequest.length > 0 && successRequest.length > 0) {
            //     const transactionNo = failedRequest?.map(d => d?.transactionType?.displayValue)?.join(", ")
            //
            //     setNotificationMessage({
            //         status: "info",
            //         title: "",
            //         msg: t(`Report Updated Successfully, except for transactionNo ${transactionNo}`),
            //         key: "updateReportCellNotification",
            //     });
            //
            //     await repopulateReport()
            // } else {
            //     setNotificationMessage({
            //         status: "error",
            //         title: "",
            //         msg: t(`Report Updated Failed, please try again.`),
            //         key: "updateReportCellNotification",
            //     });
            // }

        } catch (e) {
            systemErrorNotification()
        } finally {
            setLoading(false)
        }
    }

    const handleCancel = () => {
        amendedRecordsRef.current = []
        setRepopulateFailed(false)
        setProps({isOpen: false})
        setLoading(false)
    }

    return (
        <Modal
            open={isOpen}
            maskClosable={false}
            onCancel={handleCancel}
            closeIcon={!reportsPermission?.edit?.edit}
            title={reportsPermission?.edit?.edit ? "Update Transaction Type" : reportName}
            footer={
                reportsPermission?.edit?.edit &&
                <Row justify={"end"}>
                    <Space>
                        {
                            repopulateFailed ?
                                <>
                                    <Button onClick={() => handleCancel()}>{t("Close without repopulate")}</Button>
                                    <Button type={"primary"} loading={loading} onClick={() => repopulateReport()}>{"Repopulate"}</Button>
                                </> :
                                <>
                                    <Button onClick={() => handleCancel()}>{t("common.cancel")}</Button>
                                    <Button type={"primary"} loading={loading} onClick={() => handleSave()}>{t("common.save")}</Button>
                                </>
                        }
                    </Space>
                </Row>
            }
            width={"95%"}
            destroyOnClose
            getContainer="#reports"
        >
            <ReportComponent
                selectedReportID={selectedReportID}
                reportsPermission={reportsPermission}
                reportDataloading={reportDataLoading}
                setReportDataLoading={setReportDataLoading}
                fromModal={true}
                initialFilter={initialFilter}
                renderTransactionTypeColumn={renderTransactionTypeColumn}
                tableName={`${selectedReportID}_edit-transaction-type`}
            />
        </Modal>
    )
}

export default EditDepositWithdrawalModal