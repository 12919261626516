import React, {useEffect, useState} from "react";
import {Button, Row, Space, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {getCommissionLog} from "../../../../../../../api/graphql/commission";
import useAuthorize from "../../../../../../../_common/authorize";
import TableAntDesign from "../../../../../../../components/table/tableAntDesign";
import {columnsCommissionLog} from "../../../function/commCalculationColumns";
import moment from "moment";
import EditTableUserPreferenceButton
    from "../../../../../../../components/table/function/editTableUserPreferenceButton";
import CommLogSnapshot from "./commLogSnapshot";
import {DoubleLeftOutlined, EyeOutlined} from "@ant-design/icons";

const CommLogTable = (props) => {

    const {id, assigned, currentPage, setHeaderFunction, task, setLogPage} = props;

    const [loading, setLoading] = useState(false)
    const [batchData, setBatchData] = useState([])
    const [batchDataTotal, setBatchDataTotal] = useState(0)
    const [orderBy, setOrderBy] = useState("");
    const [filter, setFilter] = useState({});
    const [startDate, setStartDate] = useState("");
    const [snapshotPage, setSnapshotPage] = useState(false);
    const [endDate, setEndDate] = useState("");
    const [dateKey, setDateKey] = useState("");
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [currentData, setCurrentData] = useState({});


    const {t} = useTranslation()
    const {systemErrorNotification} = useAuthorize()
    const tableName = "Commission Log"

    const columns = columnsCommissionLog

    const pageKey = 5

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize)
        setOffset(limit * (page - 1))
    }

    const preload = async () => {
        try {
            await setLoading(true)
            const response = await getCommissionLog({
                batch: id,
                assignedUser: assigned,
                ...filter
            }, limit, offset, orderBy, startDate, endDate, dateKey)

            const result = response?.getCommissionLog?.data?.map((item) => {

                const RedirectTradingAccComponent = () => {
                    return (
                        <Button
                            style={{fontSize: "12px"}}
                            onClick={() => window.open("/account/trading-account/detail?id=" + item?.calculatedTradingAccount?._id, "_blank")}
                            type={"link"}>{item?.calculatedTradingAccount?.accountId}</Button>
                    )
                }

                const AssignedValue = () => {
                    return (
                        <>
                            {item?.commMode === "%" ? item?.assignedAmount + "%" : "$" + item?.assignedAmount}
                        </>
                    )
                }

                const AssignedCompressedValue = () => {
                    return (
                        <>
                            {item?.commMode === "%" ? item?.assignedCompressedAmount + "%" : "$" + item?.assignedCompressedAmount}
                        </>
                    )
                }

                const AssignedUser = () => {
                    if (item?.assignedUser?.userType === "client") {
                        return (
                            <Button
                                style={{fontSize: "12px"}}
                                onClick={() => window.open("/users/client/detail?id=" + item?.assignedUser?._id, "_blank")}
                                type={"link"}>{item?.assignedUser?.username}</Button>
                        )
                    } else {
                        return (
                            <Button
                                style={{fontSize: "12px"}}
                                onClick={() => window.open("/users/crm-user/detail?id=" + item?.assignedUser?._id, "_blank")}
                                type={"link"}>{item?.assignedUser?.username}</Button>
                        )
                    }
                }

                return {
                    ...item,
                    batchName: item?.batch?.batchName,
                    batchTransactionName: item?.batch?.transactionName,
                    reportCalculationField: item?.commSettingSnapshot?.ReportCalculationField,
                    reportName: item?.commSettingSnapshot?.ReportName,
                    ruleName: item?.commRuleSnapshot?.Name,
                    tierName: item?.commParameterSettingCriteriaSnapshot?.Name,
                    ruleType: item?.commRuleSnapshot?.RuleType,
                    symbol: item?.calculatedSymbol?.name,
                    assignedUserRank: item?.assignedUserRank?.name,
                    assignedUser: <AssignedUser/>,
                    assignedValue: <AssignedValue/>,
                    assignedCompressedValue: item?.unpaidComm === "compress" ? <AssignedCompressedValue/> : "-",
                    tradingAccount: <RedirectTradingAccComponent/>,
                    createdAt: moment(item?.createdAt).format('YYYY-MM-DD HH:mm:ss'),
                }
            })

            setBatchDataTotal(response?.getCommissionLog?.total)
            setBatchData(result)
        } catch (e) {
            systemErrorNotification()
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        preload()
    }, [id, limit, offset, orderBy, startDate, endDate, dateKey, filter])

    return (
        <>

            {
                (currentPage >= pageKey && !task) || snapshotPage ? (
                    <>
                        <CommLogSnapshot data={currentData} task={task} setSnapshotPage={setSnapshotPage}/>
                    </>
                ) : (
                    <>
                        {
                            !task ? (
                                <Row className={"margin-bottom-0-75 display-flex"} wrap>
                                    <Space className={"margin-left-auto margin-bottom-0-3"} wrap>
                                        <EditTableUserPreferenceButton tableName={tableName} tableColumn={columns}/>
                                    </Space>
                                </Row>
                            ) : (
                                <Row className={"margin-bottom-0-75 display-flex"} wrap>
                                    <Button onClick={() => setLogPage(false)} icon={<DoubleLeftOutlined />}>
                                        Back
                                    </Button>
                                </Row>
                            )
                        }


                        <TableAntDesign data={batchData}
                                        tableName={tableName}
                                        columns={[...columns, {
                                            title: t("common.action"),
                                            key: "action",
                                            width: 100,
                                            align: "left",
                                            render: (_, record: any) => {
                                                return (
                                                    <Space>
                                                        <Tooltip title="View commission details">
                                                            <Button type="link" style={{padding: "2px 4px"}}
                                                                    onClick={() => {
                                                                        if (!task) {
                                                                            setHeaderFunction(5, "Snapshot " + record?.transactionName, true)
                                                                        } else {
                                                                            setSnapshotPage(true)
                                                                        }
                                                                        setCurrentData(record)
                                                                    }} disabled={record?.totalPayout === 0 && !task}>
                                                                <EyeOutlined/>
                                                            </Button>
                                                        </Tooltip>
                                                    </Space>
                                                )
                                            },
                                        }]}
                                        size={"small"}
                                        server={null}
                                        startDate={setStartDate}
                                        endDate={setEndDate}
                                        dateKey={setDateKey}
                                        loading={loading}
                                        filter={setFilter}
                                        order={setOrderBy}
                                        pagination={{
                                            pageSize: limit,
                                            showSizeChanger: true,
                                            position: "bottomCenter",
                                            total: batchDataTotal,
                                            onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                                            showTotal: (total) =>
                                                <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                                        }}
                        />
                    </>
                )
            }

        </>

    )

}

export default CommLogTable