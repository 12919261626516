import React from "react";
import VoucherGroup_1 from "./view/layout1";
import {useRecoilValue} from "recoil";
import {columns} from "./function/tableColumn";
import { selfPermissionObj } from "../../../../../recoil_state";
import "./css/common/index.css"

const VoucherGroup = (props) => {
    let layout = 1;
    let component;
    const tableName = "Voucher Group"
    switch (layout) {
        case 1:
            component = (
                <VoucherGroup_1
                    tableName={tableName}
                    permissionActivities={props?.permission?.activitiesAndRewardSetting}
                    column={columns}
                    voucher={props.voucher}
                    formModalType={props.formModalType}
                    setFormModalType={props.setFormModalType}
                    isFormModalOpen={props.isFormModalOpen}
                    setIsFormModalOpen={props.setIsFormModalOpen}
                    selectedItem={props.selectedItem}
                    setSelectedItem={props.setSelectedItem}
                    openFormModal={props.openFormModal}
                    closeFormModal={props.closeFormModal}
                    languageData={props.languageData} 
                />
            );
            break;

        default:
            component = (
                <VoucherGroup_1
                    tableName={tableName}
                    permissionActivities={props.permission?.activitiesAndRewardSetting}
                    column={columns}
                    voucher={props.voucher}
                    formModalType={props.formModalType}
                    setFormModalType={props.setFormModalType}
                    isFormModalOpen={props.isFormModalOpen}
                    setIsFormModalOpen={props.setIsFormModalOpen}
                    selectedItem={props.selectedItem}
                    setSelectedItem={props.setSelectedItem}
                    openFormModal={props.openFormModal}
                    closeFormModal={props.closeFormModal}
                    languageData={props.languageData} 
                />
            );
            break;
    }

    return component;
};

export default VoucherGroup;
