import React, {useEffect, useMemo, useState} from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {Button, Col, Form, Modal, Row, Tabs, message} from "antd";
import {FormProvider, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {CloseOutlined} from "@ant-design/icons";
import yupFormSchema from "../../../../../../components/yup/yupFormSchema";
import {horizontalFormLayout, verticalFormLayout} from "../../../../../../components/form/formWrapper";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import useAuthorize from "../../../../../../_common/authorize";
import SelectFormItem from "../../../../../../components/form/selectFormItem";
import {userOptionsState} from "../../../../../users/_common/state";
import {getUserNetworkTreeDownline} from "../../../../../../api/graphql/userNetworkTree";
import {formatUserOptions, removeTypenameKey} from "../../../../../../function/_common";
import {getCampaign} from "../../../../../../api/graphql/campaign";
import {getPromoTag} from "../../../../../../api/graphql/promoTag";
import {getLeadTag} from "../../../../../../api/graphql/leadTag";
import {getListFromObject, getSizeOptionsListing, handleObjectChange, handleOnChangeWithLanguage} from "../../../function/common";
import {notificationMessage} from "../../../../../../../recoil_state";
import {addPromoMaterial, updatePromoMaterial} from "../../../../../../api/graphql/promoMaterial";
import SwitchFormItem from "../../../../../../components/form/switchFormItem";
import MediaUploadFormItem from "./MediaUploadFormItem";

const PromoMaterialModal = (props) => {
    const {isOpen, closeModal, mode, item, currentTab, languageData} = props;
    const {t} = useTranslation();
    const [values, setValues] = useState<any>({});
    const [campaignOptions, setCampaignOptions] = useState([]);
    const [promoTagOptions, setPromoTagOptions] = useState([]);
    const [leadTagOptions, setLeadTagOptions] = useState([]);
    const [sizeOptions, setSizeOptions] = useState([]);
    const [fileObjectValue, setFileObjectValue] = useState(item?.fileObject || []);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState<any>(null);

    const userOptions = useRecoilValue(userOptionsState);
    const {networkTreeDownlineData} = getUserNetworkTreeDownline(values?.network);

    const {systemErrorNotification} = useAuthorize();
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const yupSchema = yup.object().shape({
        name: yupFormSchema.string("name", {required: true}),
        type: yupFormSchema.string("type", {required: true}),
        redirectUrl: yupFormSchema.string("redirectUrl"),
        campaign: yupFormSchema.string("campaign"),
        promoTag: yupFormSchema.array("promoTag"),
        leadTag: yupFormSchema.array("leadTag"),
    });

    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
    });

    const setupInitialValues = async () => {
        try {
            let formValues: any = {
                name: item?.name,
                displayName: item?.displayName,
                type: item?.type,
                campaign: item?.campaign?._id,
                promoTag: item?.promoTag?.map((v) => v?._id),
                leadTag: item?.leadTag?.map((v) => v?._id),
                redirectUrl: item?.redirectUrl,
                isActive: item?.isActive,
                networkType: item?.networkType || "ALL",
                network: item?.network?.map((v) => v?._id),
                exclude: item?.exclude?.map((v) => v?._id),
            };
            form.setValue("name", item?.name);
            form.setValue("type", item?.type);
            setValues(formValues);
            let fileValues = await item?.file?.map((v) => ({
                fileList: [{uid: v?.link, url: v?.link, link: v?.link}],
                language: v?.language,
                size: v?.size,
                path: v?.link,
                type: item?.type,
            }));
            setFileObjectValue(fileValues || []);
        } catch (error) {
            console.log(error);
        }
    };

    const getCampaignOptions = async () => {
        try {
            const filter = {isDeleted: false};
            const response = await getCampaign({filter});
            let options = response?.getCampaign?.data?.map((v) => ({label: v?.name, value: v?._id}));
            setCampaignOptions(options);
        } catch (error) {
        }
    };

    const getPromoTagOptions = async () => {
        try {
            const filter = {isDeleted: false};
            const response = await getPromoTag({filter});
            let options = response?.getPromoTag?.data?.map((v) => ({label: v?.name, value: v?._id}));
            setPromoTagOptions(options);
        } catch (error) {
        }
    };

    const getLeadTagOptions = async () => {
        try {
            const filter = {isDeleted: false};
            const response = await getLeadTag({filter});
            let options = response?.getLeadTag?.data?.map((v) => ({label: v?.name, value: v?._id}));
            setLeadTagOptions(options);
        } catch (error) {
        }
    };

    const getSizeOptions = async () => {
        try {
            const result = await getSizeOptionsListing(values?.type);
            setSizeOptions(result);
        } catch (error) {
        }
    };

    const handleValueChange = async (field, value) => {
        try {
            form.setValue(field, value);
            setValues((prevState) => ({...prevState, [field]: value}));
        } catch (error) {
        }
    };

    const handleCancelPreview = () => setPreviewOpen(false);

    const handlePreview = async (file, isVideo) => {
        setPreviewImage({url: file, isVideo: isVideo});
        setPreviewOpen(true);
    };

    const uploadOnChange = (info, language, size) => {
        try {
            if (info?.file?.status === "uploading") {
                handleObjectChange(setFileObjectValue, info?.fileList, "fileList", language, size, values?.type, info?.event);
            }
            if (info?.file?.status === "done") {
                handleObjectChange(setFileObjectValue, info?.file?.name, "fileName", language, size, values?.type);
                handleObjectChange(setFileObjectValue, info?.file?.response[0]?.link, "path", language, size, values?.type);
                handleObjectChange(setFileObjectValue, info?.fileList, "fileList", language, size, values?.type);
                message?.success(`${info?.file?.name} file uploaded successfully`);
            } else if (info?.file?.status === "error") {
                handleObjectChange(setFileObjectValue, null, "fileList", language, size, values?.type);
                message?.error(`${info?.file?.name} file upload failed.`);
            }
        } catch (error) {
        }
    };

    const uploadOnRemove = (value, language, size) => {
        try {
            setFileObjectValue((prevState) => {
                const temp = [...prevState];
                const index = temp.findIndex((item) => item.language === language && item.size === size);
                if (index > -1) {
                    // only splice array when item is found
                    temp.splice(index, 1); // 2nd parameter means remove one item only
                }
                return temp;
            });
        } catch (error) {
        }
    };

    const onSubmit = async () => {
        try {
            let input = values;
            if (fileObjectValue?.length > 0) {
                input.file = fileObjectValue
                    ?.filter((a) => a?.type === values.type)
                    .map((v) => ({
                        link: v?.path,
                        size: v?.size,
                        language: v?.language,
                    }));
            }
            input = await removeTypenameKey(input);
            const res = mode === "Add" ? await addPromoMaterial(input) : await updatePromoMaterial(item?.id || item?._id, input);
            const response = mode === "Add" ? res?.addPromoMaterial : res?.updatePromoMaterial;
            if (response?.__typename === "PromoMaterial") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: mode === "Add" ? t("common.addSuccess") : t("common.updateSuccess"),
                    key: "addUpdatePromoMaterial",
                });
                closeModal();
            } else if (response?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.errKey),
                    key: "addUpdatePromoMaterial",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.msg),
                    key: "addUpdatePromoMaterial",
                });
                closeModal();
            }
        } catch (error: any) {
            systemErrorNotification();
        }
    };

    useEffect(() => {
        setupInitialValues();
    }, [item, isOpen]);

    useEffect(() => {
        if (isOpen) {
            getCampaignOptions();
            getPromoTagOptions();
            getLeadTagOptions();
        }
    }, [isOpen]);

    useEffect(() => {
        getSizeOptions();
    }, [values?.type]);

    const tabItems = languageData?.map((lang, index) => {
        let inputValue = values?.displayName?.find((v) => v?.language === lang.code)?.label;
        return {
            key: index?.toString(),
            label: lang?.name,
            children: (
                <Row gutter={16} style={{marginTop: "12px"}}>
                    <Col className="gutter-row" xs={24}>
                        <InputFormItem
                            name={`displayName_${lang.code}`}
                            label={"Display Name"}
                            placeholder={"Display Name"}
                            layout={verticalFormLayout}
                            disabled={mode === "view"}
                            value={inputValue}
                            onChange={(e) => handleOnChangeWithLanguage(e, "displayName", "label", lang.code, setValues)}
                        />
                    </Col>
                    <Col span={24}>
                        <Row className="margin-top-0-75">
                            {sizeOptions?.map((size) => {
                                return (
                                    <Col lg={4} md={6}>
                                        <MediaUploadFormItem
                                            name={`fileList_${lang?.code}_${size}`}
                                            fileList={getListFromObject(fileObjectValue, "fileList", lang?.code, size, values?.type)}
                                            handlePreview={handlePreview}
                                            onChange={(e) => uploadOnChange(e, lang?.code, size)}
                                            onRemove={(e) => uploadOnRemove(e, lang?.code, size)}
                                            fileType={values?.type}
                                            disabled={mode === "view"}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    </Col>
                </Row>
            ),
        };
    });

    const typeOptions = ["BANNER", "VIDEO", "LANDING", "GIF", "LOGO"]?.map((v) => ({label: v, value: v}));
    //Widget cater by web tradersroom itself

    const networkTreeDownLineOptions = networkTreeDownlineData?.getUserNetworkTreeDownline?.data?.map((d) => {
        return formatUserOptions(d?.userid);
    });

    const netWorkSelectOptions = [
        {value: "ALL", label: t("All Users")},
        {value: "PARTIAL", label: t("Some Users")},
        {value: "OWN", label: t("Only Me")},
    ];

    return (
        <Modal title={t(`${mode} Promo Material`)} getContainer="#promoMaterial" open={isOpen} onCancel={() => closeModal()} footer={null} width={"60%"}
               destroyOnClose>
            <Modal open={previewOpen} footer={null} onCancel={handleCancelPreview} destroyOnClose>
                {
                    previewImage?.isVideo ?
                        <video style={{width: "100%"}} controls>
                            <source src={previewImage?.url}/>
                        </video>
                        :
                        <img style={{width: "100%"}} src={previewImage?.url}/>
                }
            </Modal>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="form-1">
                    <Row gutter={[16, 16]} align={"bottom"}>
                        <Col md={12}>
                            <Row gutter={[16, 16]} align={"bottom"}>
                                <Col span={24}>
                                    <InputFormItem
                                        name={"name"}
                                        label={t("Name")}
                                        placeholder={t("Name")}
                                        layout={horizontalFormLayout}
                                        required
                                        disabled={mode === "view"}
                                        onChange={(value) => handleValueChange("name", value)}
                                    />
                                </Col>
                                <Col span={24}>
                                    <SelectFormItem
                                        name={"type"}
                                        label={t("Type")}
                                        options={typeOptions}
                                        optionFilterProp={"label"}
                                        showSearch
                                        required
                                        value={values?.type}
                                        onChange={(value) => handleValueChange("type", value)}
                                    />
                                </Col>
                                <Col span={24}>
                                    <InputFormItem
                                        name={"redirectUrl"}
                                        label={t("Redirect URL")}
                                        placeholder={t("Redirect URL")}
                                        disabled={mode === "view"}
                                        value={values?.redirectUrl}
                                        onChange={(value) => handleValueChange("redirectUrl", value)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            <Row gutter={[16, 16]} align={"bottom"}>
                                <Col span={24}>
                                    <SelectFormItem
                                        name={"campaign"}
                                        label={t("Campaign")}
                                        options={campaignOptions}
                                        mode={"single"}
                                        placeholder={t("Campaign")}
                                        disabled={mode === "view"}
                                        optionFilterProp={"label"}
                                        showSearch
                                        value={values?.campaign}
                                        onChange={(value) => handleValueChange("campaign", value)}
                                    />
                                </Col>
                                <Col span={24}>
                                    <SelectFormItem
                                        name={"promoTag"}
                                        label={t("Promo Tag")}
                                        options={promoTagOptions}
                                        mode={"multiple"}
                                        placeholder={t("Promo Tag")}
                                        disabled={mode === "view"}
                                        optionFilterProp={"label"}
                                        showSearch
                                        value={values?.promoTag}
                                        onChange={(value) => handleValueChange("promoTag", value)}
                                    />
                                </Col>
                                <Col span={24}>
                                    <SelectFormItem
                                        name={"leadTag"}
                                        label={t("Lead Tag")}
                                        options={leadTagOptions}
                                        mode={"multiple"}
                                        placeholder={t("Lead Tag")}
                                        disabled={mode === "view"}
                                        optionFilterProp={"label"}
                                        showSearch
                                        value={values?.leadTag}
                                        onChange={(value) => handleValueChange("leadTag", value)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            <SwitchFormItem
                                name={"isActive"}
                                label={t("Is Active")}
                                labelAlign={"left"}
                                disabled={mode === "view"}
                                labelCol={{span: 24, offset: 0}}
                                value={values?.isActive}
                                onChange={(value) => handleValueChange("isActive", value)}
                            />
                        </Col>
                        <Col md={12}>
                            <SelectFormItem
                                name={"networkType"}
                                label={t("Which Network can View")}
                                options={netWorkSelectOptions}
                                required
                                value={values?.networkType}
                                onChange={(value) => handleValueChange("networkType", value)}
                                disabled={mode === "view"}
                            />
                        </Col>
                        {(values?.networkType === "PARTIAL" || values?.networkType === "hide") && (
                            <>
                                <Col md={12}>
                                    <SelectFormItem
                                        name={"network"}
                                        label={t("Include")}
                                        options={userOptions}
                                        optionFilterProp={"label"}
                                        showSearch
                                        mode={"multiple"}
                                        disabled={mode === "view"}
                                        value={values?.network}
                                        onChange={(value) => handleValueChange("network", value)}
                                        allowClear={true}
                                        optionLabelProp="selectedLabel"
                                    />
                                </Col>
                                <Col md={12}>
                                    <SelectFormItem
                                        name={"exclude"}
                                        label={t("Exclude")}
                                        options={networkTreeDownLineOptions}
                                        optionFilterProp={"label"}
                                        showSearch
                                        mode={"multiple"}
                                        disabled={mode === "view"}
                                        value={values?.exclude}
                                        onChange={(value) => handleValueChange("exclude", value)}
                                        allowClear={true}
                                        optionLabelProp="selectedLabel"
                                    />
                                </Col>
                            </>
                        )}
                    </Row>
                    <Tabs defaultActiveKey="0" items={tabItems}/>
                    <Form.Item className="text-align-right" style={{paddingTop: "12px"}}>
                        {closeModal && (
                            <Button onClick={closeModal} icon={<CloseOutlined/>} className="button-cancel-1">
                                {t("common.cancel")}
                            </Button>
                        )}
                        <Button onClick={form.handleSubmit(onSubmit)} className="button-submit-1">
                            {t("common.submit")}
                        </Button>
                    </Form.Item>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default PromoMaterialModal;
