import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, DatePicker, Image, Input, Rate, Row, Select} from "antd";
import {EditOutlined, LinkOutlined} from "@ant-design/icons";
import {useSetRecoilState} from "recoil";
import {formatDate} from "../../../../../../users/_common/function";
import countryList from "../../../../../../../../config/countryList";
import {nationalityList_Language} from "../../../../../../../../config/nationalityList_Language";
import {updateUserVerificationRecordValue} from "../../../../../../../api/graphql/userVerification";
import {notificationMessage} from "../../../../../../../../recoil_state";
import moment from "moment";
import {formatDateTimeTimezone, formatDateUTC} from "../../../../../../../function/_common";

const {TextArea} = Input;

const AccountVerificationFieldComponent = (props) => {
    const {mode, d, getUserVerificationRecord} = props;
    const {t} = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [value, setValue] = useState("");
    const [valueMobile, setValueMobile] = useState({countryCode: "", mobileNo: ""});
    const [expiredDate, setExpiredDate] = useState("");
    const [editable, setEditable] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    
    let component;
    let fieldType = d?.field?.fieldType;
    let linkField = d?.linkField;

    const countryOptions = countryList?.map((d) => {
        return {
            label: t(`${d?.name}`),
            value: d?.name,
        };
    });

    let selectOptions;
    switch (linkField) {
        case "country":
            selectOptions = countryOptions;
            break;
        case "gender":
            selectOptions = [
                {
                    label: t("userVerificationRecordValue.gender.male"),
                    value: "Male",
                },
                {
                    label: t("userVerificationRecordValue.gender.female"),
                    value: "Female",
                },
            ];
            break;
        case "nationality":
            selectOptions = nationalityList_Language?.map((v) => ({...v, label: t(v?.label)}));
            break;
        default:
            selectOptions = [];
            break;
    }

    // For Debug
    // if (editable) {
    //     console.log(d);
    //     console.log(selectOptions);
    // }

    const onUpdateUserVerificationRecordValue = async (id, value, valueMobile, expiredDate) => {
        try {
            setLoading(true);
            let input: any = {};
            if (valueMobile) {
                input.valueMobile = valueMobile;
            }
            if (value) {
                input.value = value;
            }
            if (expiredDate) {
                input.expiredDate = expiredDate;
            }

            if (Object.keys(input)?.length > 0) {
                const response = await updateUserVerificationRecordValue(id, input);
                if (response?.updateUserVerificationRecordValue?.__typename === "UserVerificationRecordValue") {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: t("task.modified_successfully"),
                        key: "updateUserVerificationRecordValue",
                    });
                    setLoading(false);
                    getUserVerificationRecord();
                    setEditable(false);
                } else if (response?.updateUserVerificationRecordValue?.__typename === "BaseError") {
                    setNotificationMessage({
                        status: "error",
                        title: "",
                        msg: t(response?.updateUserVerificationRecordValue?.errKey),
                        key: "updateUserVerificationRecordValue",
                    });
                    setLoading(false);
                } else {
                    setEditable(false);
                    setNotificationMessage({
                        status: "info",
                        title: "",
                        msg: t(response?.updateUserVerificationRecordValue?.msg),
                        key: "updateUserVerificationRecordValue",
                    });
                    setLoading(false);
                }
            }
        } catch (error) {}
    };

    const onDatePickerChange = (dates: any, dateStrings: any, expiredDate = false) => {
        if (expiredDate) {
            setExpiredDate(dateStrings)
            return
        }

        setValue(dateStrings);
    };

    switch (fieldType === "group" ? d?.referenceId?.fieldType : fieldType) {
        case "textarea":
            component = <TextArea value={d?.value} disabled={!editable} />;
            break;
        case "date":
            component = (
                <>
                    <DatePicker
                        style={{width: "100%"}}
                        defaultValue={[d?.value] ? formatDateUTC(d?.value, "formValue") : undefined}
                        value={formatDateUTC(value, "formValue")}
                        onChange={onDatePickerChange}
                        disabled={!editable}
                    />
                </>
            );
            break;
        case "image":
        case "document":
            component = (
                <Row align={"middle"}>
                    <Image
                        width={120}
                        height={80}
                        className={"verification-image"}
                        style={{
                            objectFit: "cover",
                            border: "1px solid #f2f2f2",
                        }}
                        src={d?.value}
                    />
                    <Button className={"margin-left-0-25"} type={"link"} href={d?.value} target={"_blank"} icon={<LinkOutlined />} />
                </Row>
            );
            break;
        case "multiSelect":
            component = <Input value={d?.value?.join(", ")} disabled={!editable} />;
            break;
        case "rate":
            component = <Rate value={parseInt(d?.value)} disabled={!editable} />;
            break;
        case "mobile":
            component = <Input disabled={!editable} prefix={d?.valueMobile?.countryCode} value={d?.valueMobile?.mobileNo} />;
            break;
        case "select":
            component = (
                <Select style={{width: "100%"}} showSearch optionFilterProp={"label"} onChange={setValue} value={value} disabled={!editable} options={selectOptions} />
            );
            break;
        default:
            component = <Input defaultValue={d?.value} value={value} onChange={(e) => setValue(e?.target?.value)} disabled={!editable} />;
            break;
    }

    const setupValue = () => {
        try {
            switch (fieldType === "group" ? d?.referenceId?.fieldType : fieldType) {
                case "textarea":
                    setValue(d?.value);
                    break;
                case "date":
                    setValue(d?.value);
                    break;
                case "image":
                case "document":
                    break;
                case "multiSelect":
                    setValue(d?.value);
                    break;
                case "rate":
                    setValue(d?.value);
                    break;
                case "mobile":
                    component = <Input disabled={!editable} prefix={d?.valueMobile?.countryCode} value={d?.valueMobile?.mobileNo} />;
                    setValueMobile({countryCode: d?.valueMobile?.countryCode, mobileNo: d?.valueMobile?.mobileNo});
                    break;
                default:
                    setValue(d?.value);
                    break;
            }
        } catch (error) {}
    };

    useEffect(() => {
        setupValue();
    }, [d]);

    return (
        <div key={d?._id}>
            <div className="display-flex justify-content-between align-items-center">
                <small>
                    {t(d?.field?.label)} {fieldType === "group" ? ` - ${t(d?.referenceId?.label)}` : ""}
                    {!["document", "image"]?.includes(fieldType) && mode === "verify" && (
                        <Button type={"link"} onClick={() => setEditable(true)} icon={<EditOutlined />} />
                    )}
                    {editable && (
                        <>
                            <Button size="small" type="default" className="margin-right-0-25" onClick={() => setEditable(false)} disabled={loading}>
                                {t("common.cancel")}
                            </Button>
                            <Button
                                size="small"
                                type="primary"
                                onClick={() => onUpdateUserVerificationRecordValue(d?._id, value, valueMobile, expiredDate)}
                                loading={loading}
                            >
                                {t("common.submit")}
                            </Button>
                        </>
                    )}
                </small>
                {
                    d?.modifiedBy &&
                    <div className={"text-align-right"}>
                        <small>
                            <div>
                                {t("task.modified_by")}: &nbsp;
                                <a onClick={() => window.open(`/users/crm-user/detail?id=${d?.modifiedBy?._id}`, "_blank")}>{d?.modifiedBy?.username}</a>
                            </div>
                            {
                                d?.modifiedAt &&
                                <div>{t("task.modified_at")}: &nbsp; {formatDateTimeTimezone(d?.modifiedAt)}</div>
                            }
                        </small>
                    </div>
                }
            </div>
            <div>{component}</div>
            {d?.expiredDate && (
                <>
                    <small>{`${t(d?.field?.label)} - ${t("task.expired_date")}`}</small>
                    <DatePicker className={"w-100"} onChange={(date, dateString) => onDatePickerChange(date, dateString, true)} style={{marginTop: 2}} defaultValue={d?.expiredDate ? formatDateUTC(d?.expiredDate, "formValue") : null} disabled={!editable} />
                </>
            )}
        </div>
    );
};

export default AccountVerificationFieldComponent;
