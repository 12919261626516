import React, {useEffect, useState} from "react";
import {Button, Col, Image, Input, InputNumber, Row, Select, Space} from "antd";
import {useTranslation} from "react-i18next";
import {useApproveCrmTaskMutation, useRejectCrmTaskMutation, useUpdateCrmTaskMutation} from "../../../../../../../api/graphql/crmTask";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import {getUserById} from "../../../../../../../api/graphql/user";
import TaskClientDetails from "../../clientDetails";
import {formatMoney} from "../../../../../../accounts/tradingAccount/detail/function";
import useAuthorize from "../../../../../../../_common/authorize";
import {calculateDeposit} from "../../../../../../../api/graphql/eWalletDeposit";
import {getPspSettingCurrency} from "../../../../../../../api/graphql/pspSettingCurrency";
import {getPspSetting} from "../../../../../../../api/graphql/pspSetting";
import {LinkOutlined} from "@ant-design/icons";
import {formatDateTimeTimezone} from "../../../../../../../function/_common";

function EwalletDepositLayout_1(props) {
    const {item, mode, handleValidation} = props
    const depositObj = {
        psp: item?.payload?.input?.psp,
        currency: item?.payload?.input?.currency,
        paymentMethod: item?.payload?.input?.paymentMethod ? item?.payload?.input?.paymentMethod :
            item?.payload?.input?.formType === "document" ? item?.payload?.input?.psp :
                item?.payload?.input?.formType === "crypto" ? item?.payload?.input?.currency : "-",
        amount: item?.payload?.input?.amount,
        formType: item?.payload?.input?.formType,
        lastEditedCurrency: item?.payload?.input?.lastEditedCurrency,
        taskId: item?._id?.toString()
    }
    const [delayObj, setDelayObj] = useState<any>(depositObj)
    const [eWalletDepositObj, setEWalletDepositObj] = useState<any>(depositObj)
    const [pspCurrency, setPspCurrency] = useState(item?.payload?.input?.currency)
    const [comment, setComment] = useState(item?.result?.comment ? item?.result?.comment : item?.payload?.input?.comment)
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {data} = getPspSetting({filter: {name: item?.payload?.input?.psp, taskView: true}});

    const {pspCurrencyData} = getPspSettingCurrency({
        filter: {pspSetting: data?.getPspSetting?.data[0]?._id, taskView: true},
    });
    const {approveCrmTask} = useApproveCrmTaskMutation()
    const {rejectCrmTask} = useRejectCrmTaskMutation()
    const {updateCrmTask} = useUpdateCrmTaskMutation()
    const {systemErrorNotification} = useAuthorize()
    const ContainerHeight = 600;
    const {t} = useTranslation()
    const {depositCalulation, loading} = calculateDeposit({input: eWalletDepositObj})

    useEffect(() => {
        let temp = {
            ...depositCalulation?.calculateDeposit,
            comment: comment
        }
        props.setUpdatedPayload && props.setUpdatedPayload(temp)
        onSubmitValidation()
    }, [depositCalulation])

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            return setEWalletDepositObj(delayObj)
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [delayObj]);

    // const approveTaskAction = async () => {
    //     try{
    //         await setApproveBtnLoading(true)
    //         if(!extraAmount || extraAmount === 0){
    //             setNotificationMessage({
    //                 status: "error",
    //                 title: "",
    //                 msg: "please insert a valid amount",
    //                 key: "approveCrmTask",
    //             });
    //             setApproveBtnLoading(false)
    //             return false
    //         }
    //         if((mode === "verify" && item?.payload?.input?.formType === "document") && !pspCurrency || pspCurrency.trim() === ""){
    //             setNotificationMessage({
    //                 status: "error",
    //                 title: "",
    //                 msg: "please select a valid currency",
    //                 key: "approveCrmTask",
    //             });
    //             setApproveBtnLoading(false)
    //             return false
    //         }
    //         let tempData = {
    //             ...depositCalulation?.calculateDeposit,
    //             comment: comment
    //         }
    //         const response = await approveCrmTask(item?._id, tempData)
    //         if (response.data?.approveCrmTask?.__typename === "CrmTaskApproval") {
    //             setNotificationMessage({
    //                 status: "success",
    //                 title: "",
    //                 msg: t("task.task_approved"),
    //                 key: "approveCrmTask",
    //             });
    //             setApproveBtnLoading(false)
    //         } else if (response.data?.approveCrmTask?.__typename === "BaseError") {

    //             setNotificationMessage({
    //                 status: "error",
    //                 title: "",
    //                 msg: t(response.data?.approveCrmTask?.errKey),
    //                 key: "approveCrmTask",
    //             });
    //             setApproveBtnLoading(false)
    //         } else {
    //             setNotificationMessage({
    //                 status: "info",
    //                 title: "",
    //                 msg: t(response.data?.approveCrmTask?.msg),
    //                 key: "approveCrmTask",
    //             });
    //             setApproveBtnLoading(false)
    //         }
    //     }catch (e) {
    //         systemErrorNotification()
    //         setApproveBtnLoading(false)
    //     }
    // }
    // const rejectTaskAction = async () => {
    //     try{
    //         await setRejectBtnLoading(true)
    //         const response = await rejectCrmTask(item?._id)
    //         if (response.data?.rejectCrmTask?.__typename === "CrmTaskApproval") {
    //             setNotificationMessage({
    //                 status: "success",
    //                 title: "",
    //                 msg: t("task.task_rejected"),
    //                 key: "rejectCrmTask",
    //             });
    //             setRejectBtnLoading(false)
    //         } else if (response.data?.rejectCrmTask?.__typename === "BaseError") {
    //             setNotificationMessage({
    //                 status: "error",
    //                 title: "",
    //                 msg: t(response.data?.rejectCrmTask?.errKey),
    //                 key: "rejectCrmTask",
    //             });
    //             setRejectBtnLoading(false)
    //         } else {
    //             setNotificationMessage({
    //                 status: "info",
    //                 title: "",
    //                 msg: t(response.data?.rejectCrmTask?.msg),
    //                 key: "rejectCrmTask",
    //             });
    //             setRejectBtnLoading(false)
    //         }
    //     }catch (e) {
    //         systemErrorNotification()
    //         setRejectBtnLoading(false)
    //     }
    // }
    const updateCommentAction = async (value) => {
        try {
            await updateCrmTask(item?._id, {
                comment: value?.target?.value
            })
        } catch (e) {
            systemErrorNotification()
        }
    }
    const userDetail = getUserById(item?.payload?.input?.user?._id)

    const onSubmitValidation = () => {
        if (!depositCalulation?.calculateDeposit?.currency || depositCalulation?.calculateDeposit?.currency?.trim() === "") {
            handleValidation({validate: false, errorMessage: "Currency cannot be blank"})
        } else if (!depositCalulation?.calculateDeposit?.amount || depositCalulation?.calculateDeposit?.amount <= 0) {
            handleValidation({validate: false, errorMessage: "Deposit amount cannot be blank, 0 or negative"})
        } else if (!depositCalulation?.calculateDeposit?.convertedActualAmount || depositCalulation?.calculateDeposit?.convertedActualAmount <= 0) {
            handleValidation({validate: false, errorMessage: "Amount paid by client cannot be blank, 0 or negative"})
        } else if (!depositCalulation?.calculateDeposit?.rate || depositCalulation?.calculateDeposit?.rate <= 0) {
            handleValidation({validate: false, errorMessage: "Rate cannot be blank, 0 or negative"})
        } else {
            handleValidation({validate: true, errorMessage: ""})
        }
    }

    return (
        <div id={"eWalletLayout"}>
            <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                    {
                        userDetail?.data?.getUserByID && (
                            <TaskClientDetails data={userDetail?.data?.getUserByID}/>
                        )
                    }
                </Col>
                <Col xs={24} md={12}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <h3>{t("task.eWalletDeposit")}</h3>
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.transaction_no")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {item?.payload?.input?.transactionNo}
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.bank_in_date")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {formatDateTimeTimezone(item?.payload?.input?.bankInDate)}
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.display_name")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {data?.getPspSetting?.data[0]?.displayName}
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.payment_method")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {
                                mode === "verify" && (item?.payload?.input.formType === "crypto" || item?.payload?.input.formType === "document") &&
                                (depositCalulation && depositCalulation?.calculateDeposit && depositCalulation?.calculateDeposit?.paymentMethod) ?
                                    (depositCalulation?.calculateDeposit?.paymentMethod) :
                                    item?.result?.paymentMethod ?
                                        (item?.result?.paymentMethod) :
                                        item?.payload?.input?.paymentMethod ?
                                            (item?.payload?.input?.paymentMethod) :
                                            "-"
                            }
                        </Col>
                        {
                            ["document", "crypto"]?.includes(item?.payload?.input.formType) && (
                                <>
                                    <Col xs={12} lg={8}>
                                        {t("task.bank_slip")}:
                                    </Col>
                                    <Col xs={12} lg={16}>
                                        <Space>
                                            <Image width={200} height={100} style={{objectFit: "cover"}} src={item?.payload?.input?.bankInSlip || item?.payload?.input?.transactionSlip}/>
                                            {
                                                (item?.payload?.input?.bankInSlip || item?.payload?.input?.transactionSlip) &&
                                                <Button
                                                    type={"link"}
                                                    icon={<LinkOutlined/>}
                                                    onClick={() => window.open(item?.payload?.input?.bankInSlip || item?.payload?.input?.transactionSlip, "_blank")}
                                                />
                                            }
                                        </Space>
                                    </Col>
                                </>
                            )
                        }
                        {
                            item?.payload?.input.formType === "crypto" && (
                                <>
                                    <Col xs={12} lg={8}>
                                        {t("task.transaction_hash")}:
                                    </Col>
                                    <Col xs={12} lg={16}>
                                        {item?.payload?.input?.txnHash}
                                    </Col>
                                </>
                            )
                        }
                        <Col xs={12} lg={8}>
                            {t("task.currency")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {(mode === "verify" && (item?.payload?.input?.formType === "document")) ?
                                <Select
                                    style={{
                                        width: 120,
                                    }}
                                    onChange={e => {
                                        setPspCurrency(e)
                                        // setEWalletDepositObj(prev => {return {...depositObj, currency: e, targetField: "currency"}})
                                        setDelayObj({...depositObj, currency: e, targetField: "currency", lastEditedCurrency: ""})
                                    }}
                                    disabled={loading}
                                    value={pspCurrency}
                                    options={pspCurrencyData?.getPspSettingCurrency?.data?.length > 0 ? pspCurrencyData?.getPspSettingCurrency?.data?.map(item => {
                                        return {
                                            value: item?.currency,
                                            label: item?.currency,
                                        }
                                    }) : []}
                                />
                                : item?.result?.currency ? item?.result?.currency : item?.payload?.input?.currency ? item?.payload?.input?.currency : "-"

                            }
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.amount_paid_by_client")}
                            ({mode === "verify" ? pspCurrency : item?.result?.currency ? item?.result?.currency : item?.payload?.input?.currency}):
                        </Col>
                        <Col xs={12} lg={16}>
                            {
                                mode === "verify" && (item?.payload?.input.formType === "crypto" || item?.payload?.input.formType === "document") ? (
                                        <InputNumber required onChange={(e) => {
                                            // setExtraAmount(e)
                                            setDelayObj({
                                                ...depositCalulation?.calculateDeposit,
                                                convertedActualAmount: e,
                                                targetField: "convertedActualAmount",
                                                lastEditedCurrency: ""
                                            })
                                            // setEWalletDepositObj(prev => {return {...depositCalulation?.calculateDeposit, convertedActualAmount: e, targetField: "convertedActualAmount"}})
                                        }} disabled={loading} value={depositCalulation?.calculateDeposit?.convertedActualAmount} style={{width: "100%"}} min={0}
                                                     defaultValue={item?.payload?.input?.convertedActualamount ? item?.payload?.input?.convertedActualamount : 0}/>
                                    ) :
                                    (item?.result?.convertedActualAmount !== null && item?.result?.convertedActualAmount !== undefined) ?
                                        formatMoney(item?.result?.convertedActualAmount, item?.payload?.input.formType === "crypto" ? 10 : 2) :
                                        (depositCalulation?.calculateDeposit?.convertedActualAmount !== null && depositCalulation?.calculateDeposit?.convertedActualAmount !== undefined) ?
                                            formatMoney(depositCalulation?.calculateDeposit?.convertedActualAmount, item?.payload?.input.formType === "crypto" ? 10 : 2) :
                                            (item?.payload?.input?.convertedActualAmount !== null && item?.payload?.input?.convertedActualAmount !== undefined) ?
                                                formatMoney(item?.payload?.input?.convertedActualAmount, item?.payload?.input.formType === "crypto" ? 10 : 2) :
                                                "-"
                            }
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.fee_charged_on_client")}
                            ({mode === "verify" ? pspCurrency : item?.result?.currency ? item?.result?.currency : item?.payload?.input?.currency}):
                        </Col>
                        <Col xs={12} lg={16}>
                            {
                                mode === "verify" && (item?.payload?.input.formType === "crypto" || item?.payload?.input.formType === "document") ? (
                                        <InputNumber onChange={(e) => {
                                            // setExtraAmount(e)
                                            // setEWalletDepositObj(prev => {return {...depositCalulation?.calculateDeposit, convertedFeeClient: e, targetField: "convertedFeeClient"}})
                                            setDelayObj({
                                                ...depositCalulation?.calculateDeposit,
                                                convertedFeeClient: e,
                                                targetField: "convertedFeeClient",
                                                lastEditedCurrency: ""
                                            })
                                        }} disabled={loading} value={depositCalulation?.calculateDeposit?.convertedFeeClient} style={{width: "100%"}} min={0}
                                                     defaultValue={item?.payload?.input?.convertedActualAmount ? item?.payload?.input?.convertedActualAmount : 0}/>
                                    ) :
                                    item?.result?.convertedFeeClient ?
                                        formatMoney(item?.result?.convertedFeeClient, item?.payload?.input.formType === "crypto" ? 10 : 2) :
                                        (depositCalulation && depositCalulation?.calculateDeposit && depositCalulation?.calculateDeposit?.convertedFeeClient) ?
                                            formatMoney(depositCalulation?.calculateDeposit?.convertedFeeClient, item?.payload?.input.formType === "crypto" ? 10 : 2) :
                                            item?.payload?.input?.convertedFeeClient ?
                                                formatMoney(item?.payload?.input?.convertedFeeClient, item?.payload?.input.formType === "crypto" ? 10 : 2) :
                                                "-"
                            }
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.net_amount")} ({mode === "verify" ? pspCurrency : item?.result?.currency ? item?.result?.currency : item?.payload?.input?.currency}):
                        </Col>
                        <Col xs={12} lg={16}>
                            {
                                mode === "verify" && (item?.payload?.input.formType === "crypto" || item?.payload?.input.formType === "document") &&
                                (depositCalulation && depositCalulation?.calculateDeposit && depositCalulation?.calculateDeposit?.convertedAmount !== null && depositCalulation?.calculateDeposit?.convertedAmount !== undefined) ?
                                    formatMoney(depositCalulation?.calculateDeposit?.convertedAmount, item?.payload?.input.formType === "crypto" ? 10 : 2) :
                                    (item?.result?.convertedAmount !== null && item?.result?.convertedAmount !== undefined) ?
                                        formatMoney(item?.result?.convertedAmount, item?.payload?.input.formType === "crypto" ? 10 : 2) :
                                        (item?.payload?.input?.convertedAmount !== null && item?.payload?.input?.convertedAmount !== undefined) ?
                                            formatMoney(item?.payload?.input?.convertedAmount, item?.payload?.input.formType === "crypto" ? 10 : 2) :
                                            "-"
                            }
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.psp_fee")} ({mode === "verify" ? pspCurrency : item?.result?.currency ? item?.result?.currency : item?.payload?.input?.currency}):
                        </Col>
                        <Col xs={12} lg={16}>
                            {
                                mode === "verify" && (item?.payload?.input.formType === "crypto" || item?.payload?.input.formType === "document") &&
                                (depositCalulation && depositCalulation?.calculateDeposit && depositCalulation?.calculateDeposit?.convertedFeeCompany !== null && depositCalulation?.calculateDeposit?.convertedFeeCompany !== undefined) ?
                                    formatMoney(depositCalulation?.calculateDeposit?.convertedFeeCompany, item?.payload?.input.formType === "crypto" ? 10 : 2) :
                                    (item?.result?.convertedFeeCompany !== null && item?.result?.convertedFeeCompany !== undefined) ?
                                        formatMoney(item?.result?.convertedFeeCompany, item?.payload?.input.formType === "crypto" ? 10 : 2) :
                                        (item?.payload?.input?.convertedFeeCompany !== null && item?.payload?.input?.convertedFeeCompany !== undefined) ?
                                            formatMoney(item?.payload?.input?.convertedFeeCompany, item?.payload?.input.formType === "crypto" ? 10 : 2) :
                                            "-"
                            }
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.rate")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {
                                mode === "verify" && (item?.payload?.input.formType === "crypto" || item?.payload?.input.formType === "document") ? (
                                        <InputNumber required onChange={(e) => {
                                            // setEWalletDepositObj(prev => {return {...depositCalulation?.calculateDeposit, rate: e, targetField: "rate"}})
                                            onSubmitValidation()
                                            setDelayObj({...depositCalulation?.calculateDeposit, rate: e, targetField: "rate", lastEditedCurrency: ""})
                                        }} disabled={loading} value={depositCalulation?.calculateDeposit?.rate} style={{width: "100%"}} min={0}
                                                     defaultValue={item?.payload?.input?.rate ? item?.payload?.input?.rate : 0}/>
                                    ) :
                                    (item?.result?.rate !== null && item?.result?.rate !== undefined) ?
                                        formatMoney(item?.result?.rate, item?.payload?.input.formType === "crypto" ? 10 : 4) :
                                        (item?.payload?.input?.rate !== null && item?.payload?.input?.rate !== undefined) ?
                                            formatMoney(item?.payload?.input?.rate, item?.payload?.input.formType === "crypto" ? 10 : 4) :
                                            "-"
                            }
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.amount_paid_by_client_usd")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {
                                mode === "verify" && (item?.payload?.input.formType === "document") &&
                                (depositCalulation && depositCalulation?.calculateDeposit && depositCalulation?.calculateDeposit?.actualAmount !== null && depositCalulation?.calculateDeposit?.actualAmount !== undefined) ?
                                    formatMoney(depositCalulation?.calculateDeposit?.actualAmount) :
                                    (depositCalulation?.calculateDeposit?.actualAmount !== null && depositCalulation?.calculateDeposit?.actualAmount !== undefined) ?
                                        formatMoney(depositCalulation?.calculateDeposit?.actualAmount) :
                                        (item?.result?.actualAmount !== null && item?.result?.actualAmount !== undefined) ?
                                            formatMoney(item?.result?.actualAmount) :
                                            (item?.payload?.input?.actualAmount !== null && item?.payload?.input?.actualAmount !== undefined) ?
                                                formatMoney(item?.payload?.input?.actualAmount) :
                                                "-"
                            }
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.amount_charged_by_client_usd")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {
                                mode === "verify" && (item?.payload?.input.formType === "crypto" || item?.payload?.input.formType === "document") &&
                                (depositCalulation && depositCalulation?.calculateDeposit && depositCalulation?.calculateDeposit?.feeClient !== null && depositCalulation?.calculateDeposit?.feeClient !== undefined) ?
                                    formatMoney(depositCalulation?.calculateDeposit?.feeClient) :
                                    (item?.result?.feeClient !== null && item?.result?.feeClient !== undefined) ?
                                        formatMoney(item?.result?.feeClient) :
                                        (item?.payload?.input?.feeClient !== null && item?.payload?.input?.feeClient !== undefined) ?
                                            formatMoney(item?.payload?.input?.feeClient) :
                                            "-"
                            }
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.ewallet_deposit_amount_usd")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {mode === "verify" && (item?.payload?.input.formType === "crypto" || item?.payload?.input.formType === "document") &&
                            (depositCalulation && depositCalulation?.calculateDeposit && depositCalulation?.calculateDeposit?.amount !== null && depositCalulation?.calculateDeposit?.amount !== undefined) ?
                                formatMoney(depositCalulation?.calculateDeposit?.amount) :
                                (item?.result?.amount !== null && item?.result?.amount !== undefined) ? formatMoney(item?.result?.amount) :
                                    ((item?.payload?.input?.amount !== null && item?.payload?.input?.amount !== undefined) ? formatMoney(item?.payload?.input?.amount) : "-"
                                    )}
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.psp_fee_usd")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {
                                mode === "verify" && (item?.payload?.input.formType === "crypto" || item?.payload?.input.formType === "document") &&
                                (depositCalulation && depositCalulation?.calculateDeposit && depositCalulation?.calculateDeposit?.feeCompany !== null && depositCalulation?.calculateDeposit?.feeCompany !== undefined) ?
                                    formatMoney(depositCalulation?.calculateDeposit?.feeCompany) :
                                    (item?.result?.feeCompany !== null && item?.result?.feeCompany !== undefined) ?
                                        formatMoney(item?.result?.feeCompany) :
                                        (item?.payload?.input?.feeCompany !== null && item?.payload?.input?.feeCompany !== undefined) ?
                                            formatMoney(item?.payload?.input?.feeCompany) :
                                            "-"
                            }
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.remark")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {item?.payload?.input?.remark || "-"}
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.comment")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {
                                mode === "verify" ? (
                                        <Input onChange={(e) => {
                                            setComment(e.target.value)
                                            props.setUpdatedPayload && props.setUpdatedPayload(prev => {
                                                return {...prev, comment: e.target.value}
                                            })
                                            props.setComment && props.setComment(e.target.value)
                                        }} value={comment} style={{width: "100%"}}/>
                                    ) :
                                    item?.result?.comment ?
                                        item?.result?.comment :
                                        item?.payload?.input?.comment ?
                                            item?.payload?.input?.comment :
                                            "-"
                            }
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.requested_by")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {item?.requestedBy?.username}
                        </Col>
                    </Row>
                </Col>
                {/* {
                    mode === "verify" && item?.status === "pending" && item?.isClaimed === true && (
                        <Col span={24} style={{textAlign: "right",marginTop:"15px"}}>
                            <Space>
                                <Button onClick={rejectTaskAction} loading={rejectBtnLoading} danger>
                                    Reject
                                </Button>
                                <Button onClick={approveTaskAction} loading={approveBtnLoading}>
                                    Approve
                                </Button>
                            </Space>
                        </Col>
                    )
                } */}

            </Row>


        </div>
    )

}

export default EwalletDepositLayout_1