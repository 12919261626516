import React from "react";
import {Modal, Typography} from "antd";
import {useTranslation} from "react-i18next";

const InfoModal = ({isOpen, item, handleClose, info}) => {
    const {t} = useTranslation();
    const language = localStorage.getItem("language");

    const title = info === "rules" ? t("mission.missionRules") : t("mission.termsAndCondition");

    return (
        <Modal title={title} open={isOpen} onCancel={handleClose} footer={null}>
            <Typography.Paragraph>
                <p className={"mt-3"}>
                    {info === "rules"
                        ? item?.rules?.find((d) => d?.language === language)?.content
                        : item?.termAndCondition?.find((d) => d?.language === language)?.content || item?.termAndCondition?.[0]?.content}
                </p>
            </Typography.Paragraph>
        </Modal>
    );
};

export default InfoModal;
