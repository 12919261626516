import React, { useEffect } from "react";
import Helmet from 'react-helmet'
import { Layout } from "antd"
import { Outlet, useNavigate } from "react-router-dom";
import { useVerifyEmailMutation } from "../api/graphql/register";

const {Content} = Layout

const LayoutLogin_1 = () => {
    const { verifyEmail, loading } = useVerifyEmailMutation()
    const navigate = useNavigate();

    const checkVerifyEmail = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const type = urlParams.get('type');
      const email = urlParams.get('email');
      const token = urlParams.get('token');
      
      if(type && type === "verify-email"){
        let data = {
          email: email,
          token: token
        }
        await verifyEmail(data)
        
        window.location.replace("https://member.excotrades.com/#/login")
        // return navigate("/login");
      }
    }

    useEffect(()=> {
        checkVerifyEmail()
    },[])

    
    return(
        <>
            <Helmet title={"Login Page"} />
            <Layout className="login-layout-1">
                <Content>
                    <Outlet/>
                </Content>
            </Layout>
        </>
    )
}

export default LayoutLogin_1