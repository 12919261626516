import React from "react";
import {Button, Col, Row, Select, Space} from "antd";
import {useTranslation} from "react-i18next";
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import MissionAutomationCriteriaItem from "./missionAutomationCriteriaItem";
import { logicalOperator } from "../function/enumerators";


const MissionAutomationCriteriaGroup = (props) => {
    const {t} = useTranslation();
    const {
        setConditionGroup,
        conditionGroup,
        currentReport,
        reportFields,
        reportFieldTypes,
        languageData,
        mode,
        currentRecord
    } = props;

    const deleteConditionGroup = (index) => {
        let updatedRecord = [...conditionGroup]
        updatedRecord.splice(index, 1)
        updatedRecord.forEach((item) => {
            if(item.position >= index){
                item.position -= 1;
            }
        });

        setConditionGroup(updatedRecord)
    }

    const moveUpConditionGroup = (index) => {
        const updatedRecord = conditionGroup?.map((item, i) => ({
            ...item,
            position: i === index ? item.position - 1 : i === index - 1 ? item.position + 1 : item.position
        })).sort((a, b) => a.position - b.position);
    
        setConditionGroup(updatedRecord);
    };

    const moveDownConditionGroup = (index) => {
        const updatedRecord = conditionGroup?.map((item, i) => ({
            ...item,
            position: i === index ? item.position + 1 : i === index + 1 ? item.position - 1 : item.position
        })).sort((a, b) => a.position - b.position);
    
        setConditionGroup(updatedRecord);
    };

    const addConditionGroupItem = (index) => {
        let updatedRecord = [...conditionGroup.map(item => {
            let temp = {...item}
            delete temp.__typename
            return temp
        })]
        let updatedGroupItem = [
            ...updatedRecord[index]?.criteriaItems,
            {
                comparisonOperator: "=",
                reportId: "",
                reportFieldId: "",
                logicalOperator: "and",
                position: updatedRecord[index]?.criteriaItems?.length,
                value: ""
            }
        ]
        updatedRecord[index].criteriaItems = updatedGroupItem
        setConditionGroup(updatedRecord);
    }

    const onChange = (value, fieldName, index) => {
        setConditionGroup(prev => {
            let previous = [...prev]
            previous[index][fieldName] = value
            return previous
        })
    }

    return (
        <>
            {
                conditionGroup?.map((item, index) => {
                    return (
                        <div key={`criteriaGroup_${index}`}>
                            {index > 0 && 
                                <div className="conditionGroupLogicalOperator">
                                    <Select 
                                        value={conditionGroup[index]?.logicalOperator}
                                        options={logicalOperator}
                                        style={{ width: "6%" }}
                                        onChange={(e)=>onChange(e, "logicalOperator",index)}
                                        disabled={currentRecord?.missionTotalClaimed > 0 || currentRecord?.missionTotalCompleted > 0}
                                    />
                                </div>
                            }
                            <Row align="middle" className="conditionGroupCard">
                                <Col xs={12} sm={20}>
                                    <span>{t("Condition Group")} {index + 1}</span>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <Row>
                                        <Space>
                                            <Button type={"link"} style={{padding: "unset"}} onClick={() => {
                                                addConditionGroupItem(index)
                                            }} 
                                            disabled={currentRecord?.missionTotalClaimed > 0 || currentRecord?.missionTotalCompleted > 0}
                                            >
                                                {t("Add Condition")}
                                            </Button>
                                            <Button type={"link"} style={{padding: "unset"}}onClick={() => {
                                                moveUpConditionGroup(index)
                                            }} 
                                            disabled={index===0 || currentRecord?.missionTotalClaimed > 0 || currentRecord?.missionTotalCompleted > 0}
                                            >
                                                <ArrowUpOutlined/>
                                            </Button>
                                            <Button type={"link"} style={{padding: "unset"}} onClick={() => {
                                                moveDownConditionGroup(index)
                                            }} disabled={index === conditionGroup?.length - 1 || currentRecord?.missionTotalClaimed > 0 || currentRecord?.missionTotalCompleted > 0}>
                                                <ArrowDownOutlined/>
                                            </Button>
                                            <Button type={"link"} style={{padding: "unset"}} danger onClick={() => {
                                                deleteConditionGroup(index)
                                            }} disabled={currentRecord?.missionTotalClaimed > 0 || currentRecord?.missionTotalCompleted > 0}>
                                                <DeleteOutlined/>
                                            </Button>
                                        </Space>
                                    </Row>
                                </Col>
                                {item?.criteriaItems && item?.criteriaItems?.length > 0 &&
                                    <Col span={24} className="conditionGroupItems">
                                        <MissionAutomationCriteriaItem
                                            setConditionGroup={setConditionGroup}
                                            conditionGroup={conditionGroup}
                                            currentReport={currentReport}
                                            currentRecord={currentRecord}
                                            reportFields={reportFields}
                                            reportFieldTypes={reportFieldTypes}
                                            item={item.criteriaItems}
                                            groupIndex={index}
                                            languageData={languageData}
                                            mode={mode}
                                        />
                                    </Col>
                                }
                            </Row>
                        </div>
                    )
                })
            }
        </>
    )

}

export default MissionAutomationCriteriaGroup