import React, {memo, useState} from "react";
import {BellFilled, DownCircleFilled, UpCircleFilled,} from "@ant-design/icons";
import {Col, Row, Typography} from "antd";

const {Paragraph, Title} = Typography;

const PreviewAndroid = (props) => {
    const {title, body, iconPath, imagePath} = props;
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleCollapse = () => setIsCollapsed((prevState) => !prevState);

    let expandView = (
        <Row align="top" gutter={10} className="expandView">
            <Col span={2}>
                <div className="bellIconContainer">
                    <BellFilled className="bellIcon"/>
                </div>
            </Col>
            <Col span={22}>
                <Row align="middle" gutter={10}>
                    <Col span={22}>ExampleApp • now</Col>
                    <Col span={2}>
                        <UpCircleFilled className="arrowIcon"/>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <h1 className="notisTitle">{title}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Paragraph ellipsis={{rows: 2}}>{body}</Paragraph>
                    </Col>
                </Row>
                {imagePath && (
                    <Row>
                        <Col span={24}>
                            <img className="notisImage" src={imagePath} alt="notisImage"/>
                        </Col>
                    </Row>
                )}
            </Col>
        </Row>
    );

    let collapsedView = (
        <Row align="middle" gutter={10} className="collapsedView">
            <Col span={2}>
                <div className="bellIconContainer">
                    <BellFilled className="bellIcon"/>
                </div>
            </Col>
            <Col span={16}>
                <Row align="middle" gutter={10}>
                    <Col span={12}>
                        <Title level={5} ellipsis className="notisTitle">
                            {title}
                        </Title>
                    </Col>
                    <Col span={12}>ExampleApp • now</Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Paragraph ellipsis={{rows: 1}} className="notisBody">
                            {body}
                        </Paragraph>
                    </Col>
                </Row>
            </Col>
            <Col span={4}>
                {iconPath && <img src={iconPath} alt="icon" style={{width: "100%"}}/>}
            </Col>
            <Col span={2}>
                <DownCircleFilled className="arrowIcon"/>
            </Col>
        </Row>
    );

    return (
        <div className="previewAndroidContainer">
            <div className="notisContainer">
                <div className="notisContentContainer" onClick={toggleCollapse}>
                    {isCollapsed ? collapsedView : expandView}
                </div>
            </div>
        </div>
    );
};

export default memo(PreviewAndroid);
