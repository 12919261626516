import React, {useEffect, useMemo, useState} from "react";
import {Button, Checkbox, Col, DatePicker, Dropdown, Form, Input, InputNumber, message, Radio, Rate, Row, Segmented, Select, Slider, Space} from "antd";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    useAutoBindUserLeads,
    useBatchCreateFollowUp,
    useBatchDeleteUserLeadsMutation,
    useBatchUpdateUserLeadsMutation,
} from "../../../../../api/graphql/leads";
import dayjs, {Dayjs} from "dayjs";
import ExportTableButton from "../../../../../components/table/function/exportTable";
import EditTableUserPreferenceButton from "../../../../../components/table/function/editTableUserPreferenceButton";
import TextArea from "antd/es/input/TextArea";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {fieldListState, notificationMessage, selfPermissionObj} from "../../../../../../recoil_state";
import useAuthorize from "../../../../../_common/authorize";
import {DownOutlined} from "@ant-design/icons";
import {countryList_Language} from "../../../../../../config/countryList_Language";
import {userOptionsState} from "../../../_common/state";
import {nationalityList_Language} from "../../../../../../config/nationalityList_Language";
import {useIsMobile} from "../../../../../_common/layout";
import LeadsBatchAssignForm from "./leadsBatchAssignForm";
import {formatDateTimeTimezone} from "../../../../../function/_common";

const LeadsTopBar = (props) => {
    const {
        tableName,
        exportData,
        exportColumn,
        isArchived,
        setArchived,
        setFilterType,
        setFilterTypeId,
        filterTypeId,
        filterType,
        openAddFormModal,
        openImportFormModal,
        batchUpdateRecord,
        setBatchUpdateRecord,
        setCheckDuplicateFieldNames,
        setSimilarity,
        setStartDate,
        setEndDate,
        setDateKey,
        fieldsOption,
        permission,
        profileInformation,
        dateValue, // Range picker value
        refetchLeads
    } = props;

    const {t} = useTranslation();
    const {RangePicker} = DatePicker;
    const labelCol = {span: 6, offset: 0};
    const wrapperCol = {span: 18, offset: 0};

    const permissionObj = useRecoilValue<any>(selfPermissionObj);
    const selectedIds = batchUpdateRecord?.map((d) => d?._id);
    const {batchUpdateUserLeads} = useBatchUpdateUserLeadsMutation();
    const {batchDeleteUserLeads} = useBatchDeleteUserLeadsMutation();
    const {autoBindUserLeads} = useAutoBindUserLeads();
    const {batchCreateFollowUp} = useBatchCreateFollowUp();
    const [updateAction, setUpdateAction] = useState<any>({});
    const [updateActionXML, setUpdateActionXML] = useState<any>();
    const [actionType, setActionType] = useState<any>("");
    const {systemErrorNotification} = useAuthorize();
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const [similarityInputValue, setSimilarityInputValue] = useState<any>(0);
    const [form] = Form.useForm();
    const [formAssign] = Form.useForm();

    const fieldList = useRecoilValue<any>(fieldListState)
    const userOptions = useRecoilValue(userOptionsState)
    const isMobile = useIsMobile(768);
    // const userOptions = userNetworkList?.map((d) => {
    //     return formatUserOptions(d?.userid);
    // });

    const items = [
        {
            key: "1",
            label: (
                <Button type={"link"} onClick={setArchived}>
                    <Link to="/users/lead">
                        {t("lead.goToLeadsListing")}
                    </Link>
                </Button>
            ),
        },
        // permission?.bind?.bind && {
        //     key: "2",
        //     label: (
        //         <Button type={"link"}>
        //             <Link to="/users/lead/duplicate-leads">{t("lead.checkDuplicateLeads")}</Link>
        //         </Button>
        //     ),
        // },
        permission?.bind?.bind && {
            key: "3",
            label: (
                <Button type={"link"}>
                    <Link to="/users/lead/autobind-leads">{t("lead.checkAutoBindLeads")}</Link>
                </Button>
            ),
        },
        permission?.add?.add && {
            key: "4",
            label: (
                <Button type={"link"}>
                    <Link to="/users/lead/pending-list">{t("lead.pendingList")}</Link>
                </Button>
            ),
        },
    ];

    const batchUpdateLeadsHandler = async (ids, input) => {
        const {batchUpdateUserLeads: response} = await batchUpdateUserLeads(ids, input);
        if (response?.__typename === "BaseError") {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: t(batchUpdateUserLeads?.errKey),
                key: "batchUpdateUserLeads",
            });

        } else if (response?.__typename === "CrmTaskApproval") {
            setNotificationMessage({
                status: "info",
                title: "",
                msg: t(batchUpdateUserLeads?.msg),
                key: "batchUpdateUserLeads",
            });
        } else {
            refetchLeads()

            setNotificationMessage({
                status: "success",
                title: "",
                msg: t("Update User Leads Successfully"),
                key: "batchUpdateUserLeads",
            });
        }
    }

    const batchEdit = () => {
        try {
            const availableField = ["country", "leadSource", "tag", "nationality"]
            const field: any = {};

            profileInformation.map(profileInformationItem => {
                const fieldName = profileInformationItem?.fieldList?.fieldName;
                if (availableField.includes(fieldName)) {
                    field[fieldName] = true;
                }
            });

            const batchEditOnSubmit = async (values: any) => {
                let fieldNames: any = [];
                for (const property in values) {
                    if (property.endsWith("Checked") && values[property]) {
                        fieldNames.push(property?.replace("Checked", ""));
                    }
                }

                const filterInputFunction = (obj, keys) => {
                    return keys.reduce((acc, key) => {
                        if (obj?.hasOwnProperty(key)) {
                            acc[key] = obj[key]?.toString();
                        }
                        return acc;
                    }, {});
                };

                if (fieldNames?.length === 0) {
                    message.error(t("admin.err.please_fill_in_at_least_one_field"))
                    return
                }

                const filteredInput = await filterInputFunction(values, fieldNames);
                await batchUpdateLeadsHandler(selectedIds, filteredInput)

                form.resetFields()
                clear();
            };

            setActionType("edit");
            setUpdateActionXML(
                <Form form={form} className={"margin-bottom-0-75"} onFinish={(value) => batchEditOnSubmit(value)}>
                    {field?.country &&
                    <Row>
                        <Col span={24} className={"display-flex"}>
                            <Form.Item name={"countryChecked"} valuePropName="checked" className={"margin-right-0-5"}>
                                <Checkbox/>
                            </Form.Item>
                            <Form.Item label={t("user.country")} name={"country"}
                                       labelAlign={"left"}
                                       labelCol={labelCol} wrapperCol={wrapperCol} style={{width: "100%"}}>
                                <Select
                                    placeholder={t("user.country")}
                                    showSearch
                                    options={countryList_Language?.map(d => ({...d, label: t(d?.label)}))}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    }
                    {field?.nationality &&
                    <Row>
                        <Col span={24} className={"display-flex"}>
                            <Form.Item name={"nationalityChecked"} valuePropName="checked" className={"margin-right-0-5"}>
                                <Checkbox/>
                            </Form.Item>
                            <Form.Item label={t("user.nationality")} name={"nationality"}
                                       labelAlign={"left"}
                                       labelCol={labelCol} wrapperCol={wrapperCol} style={{width: "100%"}}>
                                <Select
                                    placeholder={t("user.nationality")}
                                    options={nationalityList_Language?.map(d => ({...d, label: t(d?.label)}))}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    }
                    {field?.leadSource &&
                    <Row>
                        <Col span={24} className={"display-flex"}>
                            <Form.Item name={"leadSourceChecked"} valuePropName="checked" className={"margin-right-0-5"}>
                                <Checkbox/>
                            </Form.Item>
                            <Form.Item
                                label={t("user.leadSource")}
                                labelAlign={"left"}
                                labelCol={labelCol}
                                wrapperCol={wrapperCol}
                                style={{width: "100%"}}
                                name={"leadSource"}
                            >
                                <Input placeholder={t("user.leadSource").toString()}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    }
                    {field?.tag &&
                    <Row>
                        <Col span={24} className={"display-flex"}>
                            <Form.Item name={"tagChecked"} valuePropName="checked" className={"margin-right-0-5"}>
                                <Checkbox/>
                            </Form.Item>
                            <Form.Item label={t("user.tag")}
                                       labelAlign={"left"}
                                       labelCol={labelCol} wrapperCol={wrapperCol} style={{width: "100%"}} name={"tag"}>
                                <Input placeholder={t("user.tag").toString()}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    }
                    <Row>
                        <Col span={24} className={"display-flex"}>
                            <Form.Item name={"commentChecked"} valuePropName="checked" className={"margin-right-0-5"}>
                                <Checkbox/>
                            </Form.Item>
                            <Form.Item label={t("user.comment")}
                                       labelAlign={"left"}
                                       labelCol={labelCol} wrapperCol={wrapperCol} style={{width: "100%"}} name={"comment"}>
                                <Select
                                    showSearch
                                    allowClear
                                    options={fieldList?.find(d => d?.fieldName === "leadsComment")?.optionValue?.map(d => ({...d, label: t(d?.label)}))}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className={"display-flex"}>
                            <Form.Item name={"potentialChecked"} valuePropName="checked" className={"margin-right-0-5"}>
                                <Checkbox/>
                            </Form.Item>
                            <Form.Item
                                label={t("lead.potential")}
                                labelAlign={"left"}
                                labelCol={labelCol}
                                wrapperCol={wrapperCol}
                                style={{width: "100%"}}
                                name={"potential"}
                            >
                                <Rate/>
                            </Form.Item>
                        </Col>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                {t("common.submit")}
                            </Button>
                        </Form.Item>
                    </Row>
                </Form>
            );
        } catch (e) {
            console.log(e);
            systemErrorNotification();
        }
    };

    const batchArchive = (archive) => {
        setActionType("archive");
        setUpdateAction({
            isArchived: archive === "archive",
        });
        setUpdateActionXML(
            <div>
                <p>{archive ? t("lead.archieve.confirmation") : t("lead.unarchieve.confirmation")}</p>
            </div>
        );
    };

    const [assignType, setAssignType] = useState<String>("number")
    const [assignNumbersLength, setAssignNumbersLength] = useState(1)

    const batchAssign = () => {
        setActionType("assign");
        setUpdateActionXML(<></>)
        // setUpdateActionXML(
        //     <Row>
        //         <Col span={24}>
        //             <Form.Item labelCol={labelCol} labelAlign={"left"} wrapperCol={wrapperCol} style={{width: "100%"}}>
        //                 <Radio.Group
        //                     onChange={e => setAssignType(e.target.value)}
        //                     value={assignType}
        //                     options={[{label: t("common.number"), value: "number"}, {label: t("common.percentage"), value: "percentage"}]}/>
        //             </Form.Item>
        //         </Col>
        //         {
        //             assignType === "number" ?
        //                 <Col span={24}>
        //                     <Form.Item>
        //                         hi
        //                     </Form.Item>
        //                 </Col>
        //                 : assignType === "percentage" ?
        //                 <Col span={24}>
        //                     <Form.Item>
        //                         ho
        //                     </Form.Item>
        //                 </Col> :
        //                 <></>
        //         }
        //         <Col span={24}>
        //             <Form.Item label={t("user.assignToSalesRep")} labelCol={labelCol} wrapperCol={wrapperCol} style={{width: "100%"}}>
        //                 <Select
        //                     onChange={(value) => {
        //                         setUpdateAction({
        //                             salesRep: value,
        //                         });
        //                     }}
        //                     options={userOptions}
        //                     optionFilterProp={"label"}
        //                     optionLabelProp="selectedLabel"
        //                     showSearch
        //                 />
        //             </Form.Item>
        //         </Col>
        //     </Row>
        // );
    };

    const batchUnassign = () => {
        setActionType("unassign");
        setUpdateActionXML(
            <div>
                <p>{t(`lead.delete.confirmation`)}</p>
            </div>
        );
        setUpdateAction({
            salesRep: null,
        });
    };

    const batchTransferOwner = () => {
        setActionType("transfer");
        setUpdateActionXML(
            <Row>
                <Col span={24}>
                    <Form.Item label={t("lead.transferOwnerTo")} labelCol={labelCol} wrapperCol={wrapperCol} style={{width: "100%"}}>
                        <Select
                            onChange={(value) => {
                                setUpdateAction({
                                    owner: value,
                                });
                            }}
                            options={userOptions}
                            optionFilterProp={"label"}
                            optionLabelProp="selectedLabel"
                            showSearch
                        />
                    </Form.Item>
                </Col>
            </Row>
        );
    };

    const batchFollowUp = () => {
        try {
            let dateTime;
            const batchFollowUpOnSubmit = async (value) => {
                const {batchCreateFollowUp:response} = await batchCreateFollowUp(localStorage?.getItem("userId"), selectedIds, {
                    description: value?.description,
                    followUpDateTime: formatDateTimeTimezone(value?.followUpDateTime, "formSubmit"),
                    type: "followUp",
                    followingType: "userLeads",
                });

                if (response?.__typename === "BaseError") {
                    setNotificationMessage({
                        status: "error",
                        title: "",
                        msg: t(batchCreateFollowUp?.errKey),
                        key: "batchCreateFollowUp",
                    });
                    return
                } else if (response?.__typename === "CrmTaskApproval") {
                    setNotificationMessage({
                        status: "info",
                        title: "",
                        msg: t(batchCreateFollowUp?.msg),
                        key: "batchCreateFollowUp",
                    });
                } else {
                    refetchLeads()

                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: t("Batch Create Follow Up Successfully"),
                        key: "batchCreateFollowUp",
                    });
                }

                clear();
            };

            const onChange = (dates: any, dateStrings: any) => {
                dateTime = dateStrings;
            };

            setUpdateActionXML(
                <Form className={"margin-bottom-0-75"} onFinish={(value) => batchFollowUpOnSubmit(value)}>
                    <div className={"margin-bottom-0-75"}>
                        <Form.Item label={t("user.followUpDescription")} name={"description"} labelCol={{span: 24}}>
                            <TextArea rows={2} placeholder={t("common.description") || ""}/>
                        </Form.Item>
                        <Form.Item label={t("user.setUpFollowUpTime")} name={"followUpDateTime"} labelCol={{span: 24}}>
                            <DatePicker placeholder={""} format={"YYYY-MM-DD HH:mm"} style={{width: "100%"}} showTime={{format: "HH:mm"}} onChange={onChange}/>
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {t("common.submit")}
                        </Button>
                    </Form.Item>
                </Form>
            );
        } catch (e) {
            console.log(e);
            systemErrorNotification();
        }
    };

    const batchDelete = () => {
        setActionType("delete");
        setUpdateAction({
            isDeleted: true,
        });
        setUpdateActionXML(
            <div>
                <p>{t(`lead.batchDelete.confirmation`)}</p>
            </div>
        );
    };

    const batchAutoBind = () => {
        setActionType("autoBind");
        setUpdateAction([selectedIds, batchUpdateRecord?.map((d) => d?.match?._id)]);
        setUpdateActionXML(
            <div>
                <p>{t(`lead.batchAutoBind.confirmation`)}</p>
            </div>
        );
    };

    const executeBatchUpdate = async () => {
        try {
            if (actionType === "delete") {
               const {batchDeleteUserLeads:response} = await batchDeleteUserLeads(selectedIds);

                if (response?.__typename === "BaseError") {
                    setNotificationMessage({
                        status: "error",
                        title: "",
                        msg: t(batchDeleteUserLeads?.errKey),
                        key: "batchDeleteUserLeads",
                    });

                    return
                } else if (response?.__typename === "CrmTaskApproval") {
                    setNotificationMessage({
                        status: "info",
                        title: "",
                        msg: t(batchDeleteUserLeads?.msg),
                        key: "batchDeleteUserLeads",
                    });
                } else {
                    refetchLeads()
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: t("Delete User Leads Successfully"),
                        key: "batchDeleteUserLeads",
                    });
                }
            } else if (actionType === "autoBind") {
                await autoBindUserLeads(...updateAction);
            } else {
                await batchUpdateLeadsHandler(selectedIds, updateAction)
            }
            clear();
        } catch (e) {
            console.log(e);
            systemErrorNotification();
        }
    };

    const clear = () => {
        form.resetFields()
        setActionType("");
        setUpdateAction({});
        setUpdateActionXML(<></>);
        setBatchUpdateRecord([]);
        setAssignNumbersLength(1)
        formAssign.resetFields()
    };

    const onRangeChange = (dates: any, dateStrings: any[]) => {
        if (dateStrings) {
            setStartDate(dateStrings[0]);
            setEndDate(dateStrings[1]);
            setDateKey("createdAt");
        } else {
            setStartDate("");
            setEndDate("");
            setDateKey("");
        }
    };

    const rangePresets: {
        label: string;
        value: [Dayjs, Dayjs];
    }[] = [
        {label: t("date.today"), value: [dayjs().add(0, "d"), dayjs()]},
        {label: t("date.yesterday"), value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")]},
        {label: t("date.last7Days"), value: [dayjs().add(-7, "d"), dayjs()]},
        {label: t("date.last14Days"), value: [dayjs().add(-14, "d"), dayjs()]},
        {label: t("date.last30Days"), value: [dayjs().add(-30, "d"), dayjs()]},
        {label: t("date.thisMonth"), value: [dayjs().startOf("month"), dayjs().endOf("month")]},
        {
            label: t("date.lastMonth"),
            value: [dayjs().subtract(1, "month").startOf("month"), dayjs().subtract(1, "month").endOf("month")],
        },
    ];

    return (
        <>
            <Row className={"margin-bottom-0-75 display-flex"} wrap>
                {!batchUpdateRecord || batchUpdateRecord?.length === 0 ? (
                    <>
                        <Space direction={"horizontal"} wrap>
                            {
                                permission?.view?.archive && window.location.pathname === "/users/lead" &&
                                <Segmented
                                    options={[
                                        {label: t("user.active"), value: "active"},
                                        {label: t("user.archive"), value: "archive"},
                                    ]}
                                    value={!isArchived ? "active" : "archive"}
                                    onChange={() => setArchived()}
                                />
                            }
                            {tableName === "Leads" && (
                                <>
                                    <Select
                                        value={filterType || "all"}
                                        style={{width: 250}}
                                        onChange={(value) => {
                                            setFilterType(value);
                                        }}
                                        options={[
                                            {value: "all", label: t("common.all")},
                                            {value: "leads assigned to owner", label: t("lead.leadsAssignedToOwner")},
                                            {value: "leads assigned to owner tree", label: t("lead.leadsAssignedToOwnerTree")},
                                            {value: "leads assigned to salesrep", label: t("lead.leadsAssignedToSalesRep")},
                                            {
                                                value: "leads assigned to salesrep tree",
                                                label: t("lead.leadsAssignedToSalesRepTree"),
                                            },
                                            {value: "unassigned", label: t("common.unassigned")},
                                        ]}
                                    />
                                    {filterType !== "all" && filterType !== "unassigned" && (
                                        <Select
                                            value={filterTypeId}
                                            style={{width: 250}}
                                            onChange={(value) => {
                                                setFilterTypeId(value);
                                            }}
                                            options={userOptions}
                                            optionFilterProp={"label"}
                                            optionLabelProp="selectedLabel"
                                            showSearch
                                        />
                                    )}
                                    {t("user.createdDate")}
                                    <RangePicker value={dateValue} placeholder={[t("common.startDate"), t("common.endDate")]} presets={rangePresets}
                                                 onChange={onRangeChange}/>
                                </>
                            )}
                        </Space>
                        {(tableName === "Check Duplicate Leads" || tableName === "Auto Bind Leads") && (
                            <div>
                                <Form
                                    onFinish={(values) => {
                                        setCheckDuplicateFieldNames(values?.field);
                                        if (tableName === "Check Duplicate Leads") {
                                            const similarity = parseFloat(similarityInputValue) / 100;
                                            setSimilarity(similarity);
                                        }
                                    }}
                                >
                                    <Space wrap>
                                        <Form.Item name={"field"} label={t("lead.field")}>
                                            <Select mode={"multiple"} options={fieldsOption} style={{width: 200}} maxTagCount={1}/>
                                        </Form.Item>
                                        {tableName === "Check Duplicate Leads" && (
                                            <>
                                                <Form.Item name={"similarity"} label={t("common.percentage")}>
                                                    <Space wrap>
                                                        <Slider
                                                            min={0}
                                                            max={100}
                                                            style={{width: 200}}
                                                            value={similarityInputValue}
                                                            onChange={(value) => setSimilarityInputValue(value)}
                                                        />
                                                        <Input
                                                            onChange={(e: any) => setSimilarityInputValue(e?.target?.value)}
                                                            value={similarityInputValue}
                                                            style={{width: 80}}
                                                            suffix={"%"}
                                                        />
                                                    </Space>
                                                </Form.Item>
                                                <Form.Item>
                                                    <Button type="primary" htmlType="submit" disabled={!similarityInputValue}>
                                                        {t("common.check")}
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                        {tableName === "Auto Bind Leads" && (
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit">
                                                    {t("common.check")}
                                                </Button>
                                            </Form.Item>
                                        )}
                                    </Space>
                                </Form>
                            </div>
                        )}
                        <Space className={isMobile ? "margin-top-0-75" : "margin-left-auto"} wrap>
                            <ExportTableButton
                                tableData={exportData}
                                tableColumn={exportColumn}
                                tableName={tableName === "Auto Bind Leads" ? tableName : "Leads"}
                            />
                            <EditTableUserPreferenceButton
                                tableName={tableName === "Auto Bind Leads" ? tableName : "Leads"}
                                tableColumn={exportColumn}
                                displayTableName={tableName === "Auto Bind Leads" ? t("lead.autoBindLeads") : t("module.leads")}
                            />
                            {window.location.pathname === '/users/lead' && !isArchived && permission?.add?.add && (
                                <>
                                    <Button onClick={() => openImportFormModal()}>{t("common.import")}</Button>
                                    <Button type={"primary"} onClick={() => openAddFormModal()}>
                                        {t("common.add")}
                                    </Button>
                                </>
                            )}
                            <Dropdown
                                menu={{items}}
                                trigger={["click"]}
                            >
                                <Button type={"dashed"}>
                                    <Space>
                                        {t("common.moreOptions")}
                                        <DownOutlined style={{fontSize: 12}}/>
                                    </Space>
                                </Button>
                            </Dropdown>
                        </Space>
                    </>
                ) : (
                    <div>
                        <Space direction={"horizontal"} className={"margin-bottom-0-75"} wrap>
                            {permission?.batchAction && tableName === "Leads" && (
                                <>
                                    {!isArchived ? (
                                        <>
                                            {permission?.edit?.edit && (
                                                <>
                                                    <Button onClick={() => batchEdit()}>{t("common.edit")}</Button>
                                                    <Button onClick={() => batchAssign()}>{t("common.assign")}</Button>
                                                    <Button onClick={() => batchUnassign()}>{t("common.unassign")}</Button>
                                                    <Button onClick={() => batchTransferOwner()}>{t("common.transfer")}</Button>
                                                    <Button onClick={() => batchArchive("archive")}>{t("common.archive")}</Button>
                                                </>
                                            )}
                                            {permissionObj?.user?.leads?.add?.followUp && (
                                                <Button onClick={() => batchFollowUp()}>{t("user.followUp")}</Button>
                                            )}
                                            <Button onClick={() => clear()}>{t("common.cancel")}</Button>
                                        </>
                                    ) : (
                                        <>
                                            {permission?.edit?.edit && (
                                                <Button onClick={() => batchArchive("unarchive")}>{t("common.unarchive")}</Button>
                                            )}
                                            {permission?.delete?.delete && <Button onClick={() => batchDelete()}>{t("common.delete")}</Button>}
                                            <Button onClick={() => clear()}>{t("common.cancel")}</Button>
                                        </>
                                    )}
                                </>
                            )}
                            {permission?.batchAction && tableName === "Check Duplicate Leads" && (
                                <>
                                    {permission?.edit?.edit && <Button onClick={() => batchArchive("archive")}>{t("common.archive")}</Button>}
                                    <Button onClick={() => clear()}>{t("common.cancel")}</Button>
                                </>
                            )}
                            {permission?.batchAction && tableName === "Auto Bind Leads" && (
                                <>
                                    {permission?.bind?.bind && <Button onClick={() => batchAutoBind()}>{t("lead.autoBind")}</Button>}
                                    <Button onClick={() => clear()}>{t("common.cancel")}</Button>
                                </>
                            )}
                        </Space>
                        {updateActionXML}
                        {
                            actionType === "assign" &&
                            <LeadsBatchAssignForm
                                actionType={actionType}
                                labelCol={labelCol}
                                wrapperCol={wrapperCol}
                                userOptions={userOptions}
                                selectedIds={selectedIds}
                                clear={clear}
                                refetchLeads={refetchLeads}
                            />
                        }
                        {actionType?.length > 0 && actionType !== "edit" && actionType !== "assign" && (
                            <Button onClick={() => executeBatchUpdate()}>{t("common.confirm")}</Button>
                        )}
                    </div>
                )}
            </Row>
        </>
    );
}

export default LeadsTopBar;
