import React, {useEffect, useMemo, useState} from "react";
import {Form, Modal, Row, Steps} from "antd";
import {useTranslation} from "react-i18next";
import CreateUpdateForm from "./createUpdateForm";
import ColorSchemaForm from "./colorSchemaForm";
import {isArray} from "@apollo/client/utilities";

const CreateUpdateModal = ({open, handleCancel, item, defaultStep}) => {
    const {t} = useTranslation();
    const isUpdate = !!item
    const [form] = Form.useForm()
    const [colorForm] = Form.useForm()
    const [currentStep, setCurrentStep] = useState<number>(0)
    const [formTouched, setFormTouched] = useState<boolean>(false)
    const [colorSchemaInitialValue, setColorSchemaInitialValue] = useState<any>({})

    const colorSchemaData = useMemo(() => (
        [
            {
                "light": "linear-gradient(rgb(255,218,218) 0%, rgb(198,80,80) 100%)",
                "dark": "linear-gradient(rgb(92,3,3) 0%, rgb(255,0,0) 100%)",
                "variableName": "gradient-1"
            },
            {
                "variableName": "background-color",
                "light": "#f9c2c2",
                "dark": "#5c1010"
            }
        ]
    ), [])

    const onSubmit = (values) => {
        console.log(values)
    }

    const onValuesChange = (changedValues, allValues) => {
        if (!formTouched) setFormTouched(true)

        const {type} = changedValues

        if (type === "ranking") {

        } else if (type === "whoever to reach") {

        } else if (type === "first to reach") {
            form.setFieldValue("podiumTotal", 1)
        }
    }

    const onColorFormSubmit = ({colorSchema}) => {
        const formattedData = colorSchema.map(item => {
            const newItem = {...item};
            ['light', 'dark'].forEach(key => {
                if (isArray(newItem[key])) {
                    newItem[key] = `linear-gradient(${newItem[key].map(stop => `${stop.color} ${stop.percent}%`).join(', ')})`;
                } else if (newItem[key] && typeof newItem[key] !== "string") {
                    if (newItem[key].isGradient()) {
                        newItem[key] = newItem[key].toCssString()
                    } else {
                        newItem[key] = newItem[key].toHexString()
                    }
                }
            });
            return newItem;
        });

        console.log(formattedData)
    }

    const parseGradient = (color) => {
        const regex = /rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)\s+(\d{1,3})%/g;
        const colorStops: any[] = [];
        let match;
        while ((match = regex.exec(color)) !== null) {
            const [_, r, g, b, percent] = match;
            colorStops.push({
                color: `rgb(${r},${g},${b})`,
                percent: parseInt(percent),
            });
        }

        return colorStops;
    }

    useEffect(() => {
        const formattedData = colorSchemaData?.map(item => {
            const newItem = {...item};

            ['light', 'dark'].forEach(key => {
                if (!newItem[key].startsWith('#')) {
                    newItem[key] = parseGradient(newItem[key])
                }
            })

            return newItem
        })

        setColorSchemaInitialValue({colorSchema: formattedData})
    }, [colorSchemaData])

    const onOk = () => {
        if (currentStep === 0) {
            if (formTouched) {
                form.submit()
            }

            setCurrentStep(currentStep + 1)
        } else if (currentStep === 1) {
            colorForm.submit()
            setCurrentStep(currentStep + 1)
        }
    }

    const onStepChange = (nextStep) => {
        if (currentStep === 0 && formTouched) {
            alert("You have unsaved changes, are you sure you want to quit without save?")
        }

        setCurrentStep(nextStep)
    }

    useEffect(() => {
        if (currentStep === 2) {
            window.open(`/settings/arena/leaderboard-ui-editor/${item?._id}`, "_blank")
            handleCancel()
        }
    }, [currentStep]);

    useEffect(() => {
        if (open) setCurrentStep(defaultStep)
    }, [open, defaultStep]);

    const resetForm = (item) => {
        setFormTouched(false)
        form.resetFields()
    }

    useEffect(() => {
        if (currentStep === 0 && open) resetForm(item)
    }, [item, open, currentStep]);

    useEffect(() => {
        if (currentStep === 1) colorForm.resetFields()
    }, [colorSchemaInitialValue, open, currentStep]);

    return (
        <Modal
            open={open}
            title={isUpdate ? "Update Leaderboard" : "Create Leaderboard"}
            maskClosable={false}
            width={"70%"}
            okText={t("common.next")}
            onOk={onOk}
            cancelText={t("common.cancel")}
            onCancel={handleCancel}
            getContainer={false}
        >
            <Row justify={"center"}>
                <Steps
                    style={{maxWidth: 500}}
                    className={"margin-bottom-0-75"}
                    // type="navigation"
                    size="small"
                    current={currentStep}
                    onChange={onStepChange}
                    items={[
                        {
                            title: 'Setting',
                            status: currentStep === 0 ? 'process' : "finish",
                            disabled: true
                        },
                        {
                            title: 'Color Schema',
                            status: currentStep === 1 ? 'process' : currentStep === 0 ? "wait" : "finish",
                            disabled: true
                        },
                        {
                            title: 'UI Interface',
                            status: currentStep === 2 ? 'process' : "wait",
                            disabled: true
                        },
                    ]}
                />
            </Row>
            {
                currentStep === 0 ? (
                    <Form form={form} onFinish={onSubmit} labelCol={{span: 24}} onValuesChange={onValuesChange} initialValues={item}>
                        <CreateUpdateForm/>
                    </Form>
                ) : currentStep === 1 ? (
                    <Form form={colorForm} onFinish={onColorFormSubmit} className={"margin-top-0-75"} initialValues={colorSchemaInitialValue}>
                        <ColorSchemaForm/>
                    </Form>
                ) : <></>
            }

        </Modal>
    )
}

export default CreateUpdateModal