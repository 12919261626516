import {gql, useMutation, useQuery} from "@apollo/client";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {useTranslation} from "react-i18next";
import {ApolloClientQuery} from "./_apolloClient";

const GET_BANK_INFORMATION = gql`
    query getBankInformation($filter: BankInformationInput, $limit: Int, $offset: Int, $orderBy: String) {
        getBankInformation(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ...on BankInformationArray {
                data {
                    _id
                    user {_id}
                    psp {
                        _id
                        name
                        displayName
                    }
                    formData
                }
            }
            ...on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getBankInformation = ({filter}, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data} = useQuery(GET_BANK_INFORMATION, {
            variables: {
                filter: filter,
                limit: limit,
                offset: offset,
                orderBy: orderBy
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const getBankInformationAsync = async ({filter}, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_BANK_INFORMATION,
            {
                filter: filter,
                limit: limit,
                offset: offset,
                orderBy: orderBy
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};


const UPDATE_BANK_INFORMATION = gql`
  mutation updateBankInformation($input: BankInformationInput){
    updateBankInformation(input: $input){
      ... on BankInformation {
        _id
        user { _id }
        psp {
          _id
          name
        }
        formData
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useUpdateBankInformationMutation = (): { updateBankInformation: any, data: any, loading: boolean, error: any } => {
    try {
        const [updateBankInformationMutation, {data, loading, error}] = useMutation(UPDATE_BANK_INFORMATION);

        const updateBankInformation = (input) => {
            return updateBankInformationMutation({
                variables: {
                    input: input,
                }
            });
        };

        return {updateBankInformation, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {updateBankInformation: null, data: null, loading: false, error: err};
    }
}

const DELETE_BANK_INFORMATION = gql`
  mutation deleteBankInformation($id: String!){
    deleteBankInformation(id: $id){
      ... on BankInformation {
        _id
        isDeleted
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useDeleteBankInformationMutation = (): { deleteBankInformation: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const { t } = useTranslation();
        const [deleteBankInformationMutation, {data, loading, error}] = useMutation(DELETE_BANK_INFORMATION);

        const deleteBankInformation = (id) => {
            return deleteBankInformationMutation({
                variables: {
                    id: id,
                },
                update(cache, {data: {deleteBankInformation}}) {
                    const typename = deleteBankInformation?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(deleteBankInformation?.errKey),
                            key: "deleteBankInformation",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(deleteBankInformation?.msg),
                            key: "deleteBankInformation",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Delete Bank Information Successfully"),
                            key: "deleteBankInformation",
                        });
                    }
                },
                refetchQueries: [GET_BANK_INFORMATION]
            });
        };

        return {deleteBankInformation, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {deleteBankInformation: null, data: null, loading: false, error: err};
    }
}


export {
    getBankInformation,
    getBankInformationAsync,
    useUpdateBankInformationMutation,
    useDeleteBankInformationMutation
}