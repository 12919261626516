import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Popconfirm, Row, Space} from "antd";
import {useRecoilState, useRecoilValue} from "recoil";
import {selfPermissionObj} from "../../../../../../recoil_state";
import {salesOpportunityStatusData, salesOpportunityStatusLoading} from "../../state";
import {columns} from "../../function/columns";
import TableAntDesign from "../../../../../components/table/tableAntDesign";
import EditTableUserPreferenceButton from "../../../../../components/table/function/editTableUserPreferenceButton";
import ExportTableButton from "../../../../../components/table/function/exportTable";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import AddEditStatusModal from "./components/addEditStatusModal";
import {useSalesOpportunitySettingFunction} from "../../function";
import {arrayMove, SortableContext, useSortable, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {DndContext, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {restrictToVerticalAxis} from "@dnd-kit/modifiers";

const SalesOpportunitySetting_1 = () => {
    const {t} = useTranslation()
    const selfPermission = useRecoilValue<any>(selfPermissionObj);
    const permission = selfPermission?.userSetting?.salesOpportunityStatus

    const [data, setData] = useRecoilState<any>(salesOpportunityStatusData)
    const loading = useRecoilValue<any>(salesOpportunityStatusLoading)
    const column = columns()
    const tableName = "Sales Opportunity Status"
    const tableData = data?.data || []

    const [isOpenModal, setIsOpenModal] = useState(false)
    const [mode, setMode] = useState("")
    const [record, setRecord] = useState({})
    const [deleteLoading, setDeleteLoading] = useState(false)

    const [draggable, setDraggable] = useState(false);

    const {deleteSalesOpportunityStatusFunction, sortSalesOpportunityStatusFunction} = useSalesOpportunitySettingFunction()

    const tableRow = (props) => {
        const found = data?.data?.find(d => d?._id === props["data-row-key"])
        if (found && found?.type !== "default") {
            return <tr {...props} />;
        }

        const {attributes, listeners, setNodeRef, transform, transition, isDragging} = useSortable({
            id: props["data-row-key"] ? props["data-row-key"] : "",
        });

        const style = {
            ...props.style,
            transform: CSS.Transform.toString(
                transform && {
                    ...transform,
                    scaleY: 1,
                }
            ),
            transition,
            cursor: "move",
            ...(isDragging
                ? {
                    position: "relative",
                    zIndex: 9999,
                }
                : {}),
        };

        return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
    };

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 1,
            },
        })
    );

    const Table = useMemo(() => {
        return (
            <TableAntDesign
                data={tableData}
                loading={loading}
                tableName={tableName}
                size={"small"}
                scroll={false}
                sort={false}
                actionPermission={permission?.edit?.edit || permission?.delete?.delete}
                columns={
                    [
                        ...column,
                        {
                            title: t("common.action"),
                            key: "action",
                            width: 100,
                            align: "start",
                            render: (_, record: any, index) => (
                                <Space>
                                    {permission?.edit?.edit && (
                                        <a style={{color: "green"}}
                                           onClick={() => {
                                               setRecord(record)
                                               setMode("edit")
                                               setIsOpenModal(true)
                                           }}
                                        >
                                            <AiOutlineEdit/>
                                        </a>
                                    )}
                                    {permission?.delete?.delete && record?.type === "default" && (
                                        <Popconfirm
                                            placement="left"
                                            description={t("Are you sure to delete this status?")}
                                            title={t(`Delete Sales Opportunity Status`)}
                                            onConfirm={() => {
                                                deleteSalesOpportunityStatusFunction(record?._id, setDeleteLoading);
                                            }}
                                            okText={t("common.yes")}
                                            okButtonProps={{loading: deleteLoading}}
                                            okType="danger"
                                            cancelText={t("common.no")}
                                        >
                                            <a>
                                                <AiOutlineDelete style={{color: "red"}}/>
                                            </a>
                                        </Popconfirm>
                                    )}
                                </Space>
                            ),
                        },
                    ]
                }
                component={
                    draggable
                        ? {
                            body: {
                                row: tableRow,
                            },
                        }
                        : undefined
                }
                pagination={{
                    showSizeChanger: true,
                    total: data?.total,
                    showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                }}
            />
        )
    }, [data, loading, permission, draggable])

    const onDragEnd = async ({active, over}) => {
        if (active.id !== over?.id) {
            setDraggable(false)
            const activeIndex = data?.data.findIndex((i) => i._id === active.id);
            const overIndex = data?.data.findIndex((i) => i._id === over?.id);
            const sortedAry: any = arrayMove(data?.data, activeIndex, overIndex)
            const input = sortedAry?.map(d => d?._id)

            setData((prev => ({...prev, data: sortedAry})))

            const response = await sortSalesOpportunityStatusFunction({sort: input})
            setDraggable(true)
        }
    }

    useEffect(() => {
        if (data?.total > 0) setDraggable(true);
    }, [data, loading])

    return (
        <div id="sales-opportunity-setting">
            <Row justify={"space-between"} className={"margin-bottom-0-75"}>
                <h1>{t("Sales Opportunity Setting")}</h1>
                <Space>
                    <EditTableUserPreferenceButton tableName={tableName} tableColumn={column}/>
                    <ExportTableButton tableData={tableData} tableColumn={column} tableName={tableName}/>
                    {
                        permission?.add?.add &&
                        <Button onClick={() => {
                            setMode("add")
                            setIsOpenModal(true)
                            setRecord({})
                        }}>{t("Add Status")}</Button>
                    }
                </Space>
            </Row>
            {tableData?.length > 0 && permission?.edit?.edit ? (
                <DndContext onDragEnd={onDragEnd} sensors={sensors} modifiers={[restrictToVerticalAxis]}>
                    <SortableContext
                        items={tableData?.length > 0 ? tableData?.map((i) => i._id)?.filter((item) => item !== null) : []}
                        strategy={verticalListSortingStrategy}
                    >
                        {Table}
                    </SortableContext>
                </DndContext>
            ) : (
                <>{Table}</>
            )}
            <AddEditStatusModal isOpen={isOpenModal} setIsOpen={setIsOpenModal} mode={mode} record={record}/>
        </div>
    )
}

export default SalesOpportunitySetting_1