import {atom} from "recoil";


const crmTaskFilterState = atom({
    key: 'crmTaskFilterState',
    default: {},
});

const crmTaskNetworkUserIdState = atom({
    key: 'crmTaskNetworkUserIdState',
    default: null,
});

const crmTaskTableFilterState = atom({
    key: 'crmTaskTableFilterState',
    default: {},
});

const crmTaskLimitState = atom({
    key: 'crmTaskLimitState',
    default: 20,
});

const crmTaskOffsetState = atom({
    key: 'crmTaskOffsetState',
    default: 0,
});

const crmTaskStartTimeSearchState = atom({
    key: 'crmTaskStartTimeSearchState',
    default: "",
});

const crmTaskEndTimeSearchState = atom({
    key: 'crmTaskEndTimeSearchState',
    default: "",
});

const crmTaskDateKeySearchState = atom({
    key: 'crmTaskDateKeySearchState',
    default: "",
});

const crmTaskOrderBySearchState = atom({
    key: 'crmTaskOrderBySearchState',
    default: "",
});


const userVerificationRecordDocumentIdsState = atom({
    key: 'userVerificationRecordDocumentIdsState',
    default: null,
});

const crmTaskArchivedFilterState = atom({
    key: 'crmTaskArchivedFilterState',
    default: {},
});

const crmTaskArchivedTableFilterState = atom({
    key: 'crmTaskArchivedTableFilterState',
    default: {},
});

const crmTaskArchivedLimitState = atom({
    key: 'crmTaskArchivedLimitState',
    default: 20,
});

const crmTaskArchivedOffsetState = atom({
    key: 'crmTaskArchivedOffsetState',
    default: 0,
});

const crmTaskArchivedStartTimeSearchState = atom({
    key: 'crmTaskStartTimeArchivedSearchState',
    default: "",
});

const crmTaskArchivedEndTimeSearchState = atom({
    key: 'crmTaskEndTimeArchivedSearchState',
    default: "",
});

const crmTaskArchivedDateKeySearchState = atom({
    key: 'crmTaskDateKeyArchivedSearchState',
    default: "",
});

const crmTaskArchivedOrderBySearchState = atom({
    key: 'crmTaskOrderByArchivedSearchState',
    default: "",
});

const crmTaskArchivedMonthFilterState = atom({
    key: 'crmTaskArchivedMonthArchivedFilterState',
    default: "2099-01",
});


export {
    crmTaskFilterState,
    crmTaskLimitState,
    crmTaskOffsetState,
    crmTaskStartTimeSearchState,
    crmTaskEndTimeSearchState,
    crmTaskDateKeySearchState,
    crmTaskOrderBySearchState,
    crmTaskTableFilterState,
    userVerificationRecordDocumentIdsState,
    crmTaskArchivedDateKeySearchState,
    crmTaskArchivedEndTimeSearchState,
    crmTaskArchivedFilterState,
    crmTaskArchivedLimitState, crmTaskArchivedOffsetState,
    crmTaskArchivedOrderBySearchState,
    crmTaskArchivedTableFilterState,
    crmTaskArchivedStartTimeSearchState,
    crmTaskArchivedMonthFilterState,
    crmTaskNetworkUserIdState
}