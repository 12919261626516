import React, {useEffect, useState} from "react";
import {Button, Card, Form, Space, Tabs,} from "antd";
import {useTranslation} from "react-i18next";
import {EditOutlined} from "@ant-design/icons";
import {useUpdateUserMutation} from "../../../../../../../api/graphql/user";
import BasicInformation from "./InformationForm/basicInformation";
import ResidenceInformation from "./InformationForm/residenceInformation";
import {formatProfileInformation, validateConfirmKeys} from "../../../../../_common/function";
import useAuthorize from "../../../../../../../_common/authorize";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import {formatDateUTC} from "../../../../../../../function/_common";

const InformationCard = (props) => {
    const {data, profileInformation, permissionUser} = props
    const {t} = useTranslation()

    const [editable, setEditable] = useState<boolean>(false)
    const [currentTab, setCurrentTab] = useState<any>("basic")
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [basicForm] = Form.useForm()
    const [residenceForm] = Form.useForm()

    const {updateUser, loading} = useUpdateUserMutation()
    const {systemErrorNotification} = useAuthorize()
    const userInfoOnSubmit = async (value) => {
        try {
            const nonUpdatableFields = ["tag", "leadSource", "age", "affiliateCode"]
            Object.keys(value).forEach((key) => {
                const match = nonUpdatableFields?.find(d => d === key)
                if (value[key] === undefined || match) {
                    delete value[key];
                }
            });

            const validated = await validateConfirmKeys(value, setNotificationMessage, t)
            const form = currentTab === "basic" ? basicForm : residenceForm

            if (validated) {
                delete value["combinedMobileNo"]
                value["mobileNo"] = value?.mobileNo?.toString()
                value["profileImage"] = value?.profileImage?.file?.response?.[0]?.link
                value["dateOfBirth"] = value["dateOfBirth"] ? formatDateUTC(value["dateOfBirth"]) : null;

                const validate = currentTab === "basic" ? ["basicInformation", "loginInformation", "additionalInformation"] : ["residentialInformation"]
                const response = await updateUser(new URLSearchParams(location.search).get('id'), {...value, validationType: validate})
                if (response?.data?.updateUser?.errKey === "admin.err.user_exist") {
                    response?.data?.updateUser?.errObj.forEach(item => {
                        form.setFields([
                            {
                                name: item === "mobileNo" ? "combinedMobileNo" : item,
                                errors: [t("errMsg.fieldAlreadyExisted", {field: t(`user.${item}`)})]
                            }
                        ])
                    })
                } else if (response?.data?.updateUser?.__typename === "User") setEditable(false)
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    const [fields, setFields] = useState<any>({
        basicField: [],
        loginField: [],
        residenceField: [],
        additionalField: [],
    })
    const {basicField, loginField, residenceField, additionalField} = fields;

    useEffect(() => {
        formatProfileInformation(fields, setFields, profileInformation)
    }, [profileInformation])

    const items: any = [
        {
            label: t("field.category.basicInformation"),
            key: "basic",
            children: (
                data &&
                <Form onFinish={userInfoOnSubmit} form={basicForm}>
                    <BasicInformation
                        data={data}
                        editable={editable}
                        basicField={basicField}
                        loginField={loginField}
                        additionalField={additionalField}
                        isReadSensitive={permissionUser?.crmUser?.view?.sensitiveInformation}
                        form={basicForm}
                    />
                </Form>
            )
        },
        residenceField?.length > 0 ? {
            label: t("field.category.residenceInformation"),
            key: "address",
            children: (
                <Form onFinish={userInfoOnSubmit} form={residenceForm}>
                    <ResidenceInformation
                        data={data}
                        editable={editable}
                        residenceField={residenceField}
                        isReadSensitive={permissionUser?.crmUser?.view?.sensitiveInformation}
                    />
                </Form>
            )
        } : null,
    ];

    useEffect(() => {
        setEditable(false)
    }, [currentTab])

    return (
        <Card style={{width: "100%"}}>
            <Tabs
                size={"small"}
                items={items}
                tabBarExtraContent={
                    <>
                        {editable ?
                            <Space>
                                <Button onClick={() => setEditable(false)}>{t("common.cancel")}</Button>
                                {
                                    currentTab === "basic" ?
                                        <Button type={"primary"} loading={loading}
                                                onClick={() => basicForm.submit()}>{t("common.submit")}</Button> :
                                        <Button type={"primary"} loading={loading}
                                                onClick={() => residenceForm.submit()}>{t("common.submit")}</Button>
                                }
                            </Space> :
                            permissionUser?.crmUser?.edit?.edit &&
                            <Button type={"link"} onClick={() => setEditable(true)} icon={<EditOutlined/>}/>
                        }
                    </>
                }
                onTabClick={(key) => setCurrentTab(key)}
            />
        </Card>
    )
}

export default InformationCard