import React from "react";
import {Col, Collapse, Divider, Row, Table} from "antd";
import * as yup from "yup";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import CheckboxFormItem from "../../../../../../../components/form/checkboxFormItem";
import InputFormItem from "../../../../../../../components/form/inputFormItem";
import SelectFormItem from "../../../../../../../components/form/selectFormItem";

const {Panel} = Collapse;

const SymbolSider = (props) => {
    const {initialData} = props;
    const {t} = useTranslation();

    const labelColInput = {span: 8, offset: 0};
    const labelColCheckbox = {span: 12, offset: 8};
    const labelColInputFull = {span: 4, offset: 0};
    const labelColCheckboxFull = {span: 6, offset: 0};

    const yupSchema = yup.object().shape({});

    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
    });

    const columns = [
        {
            title: t("Liquidity Feed"),
            dataIndex: "liquidityFeed",
            key: "liquidityFeed",
        },
        {
            title: t("Price Alias"),
            dataIndex: "priceAlias",
            key: "priceAlias",
        },
        {
            title: t("Trade Alias"),
            dataIndex: "tradeAlias",
            key: "tradeAlias",
        },
    ];

    return (
        <Collapse accordion defaultActiveKey={"common"}>
            <Panel showArrow={false} header={initialData?.name} key="common">
                <FormProvider {...form}>
                    <form className="form-1">
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <CheckboxFormItem
                                    name={"subscribeForQuotes"}
                                    label={t("Subscribe for Quotes")}
                                    labelCol={labelColCheckbox}
                                    // value={initialData?.}
                                    disabled
                                />
                                <CheckboxFormItem
                                    name={"displayInClientTerminal"}
                                    label={t("Display in Client Terminal")}
                                    labelCol={labelColCheckbox}
                                    // value={initialData?.}
                                    disabled
                                />
                            </Col>
                            <Col span={12}>
                                <CheckboxFormItem
                                    name={"displayInPopularMarket"}
                                    label={t("Display in Popular Market")}
                                    labelCol={labelColCheckbox}
                                    // value={initialData?.}
                                    disabled
                                />
                                <CheckboxFormItem
                                    name={"enableShortSelling"}
                                    label={t("Enable Short Selling")}
                                    labelCol={labelColCheckbox}
                                    value={initialData?.enableShortSelling}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <SelectFormItem
                                    name={"tradingMode"}
                                    label={t("Trading Mode")}
                                    labelCol={labelColInputFull}
                                    options={[
                                        {label: t("Full Access")},
                                        {label: t("Fully Disabled")},
                                        {label: t("Disabled (with Execution of Pending Orders)")},
                                        {label: t("Close Only")},
                                    ]}
                                    disabled
                                />
                            </Col>
                            <Divider/>
                            <Col span={24}>
                                <InputFormItem
                                    name={"name"}
                                    label={t("Symbol Name")}
                                    labelCol={labelColInputFull}
                                    disabled
                                    value={initialData?.name}
                                />
                            </Col>
                            <Col span={12}>
                                <InputFormItem
                                    name={"baseAssetId"}
                                    label={t("Base Asset")}
                                    value={initialData?.baseAssetId?.[0]?.name}
                                    labelCol={labelColInput}
                                    disabled
                                />
                            </Col>
                            <Col span={12}>
                                <InputFormItem
                                    name={"quoteAssetId"}
                                    label={t("Quote Asset")}
                                    value={initialData?.quoteAssetId?.[0]?.name}
                                    labelCol={labelColInput}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"description"}
                                    label={t("Description")}
                                    labelCol={labelColInputFull}
                                    disabled
                                    value={initialData?.description}
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"tags"}
                                    label={t("Tags")}
                                    labelCol={labelColInputFull}
                                    disabled
                                    value={initialData?.tags}
                                />
                            </Col>
                            <Divider/>
                            <Col span={12}>
                                <InputFormItem
                                    name={"assetClass"}
                                    label={t("Asset Class")}
                                    labelCol={labelColInput}
                                    value={initialData?.assetClass}
                                    disabled
                                />
                            </Col>
                            <Col span={12}>
                                <InputFormItem
                                    name={"category"}
                                    label={t("Category")}
                                    labelCol={labelColInput}
                                    disabled
                                    value={initialData?.symbolCategoryId?.[0]?.name}
                                />
                            </Col>
                            <Divider/>
                            <Col span={12}>
                                <InputFormItem
                                    name={"pipPosition"}
                                    label={t("Pip Position")}
                                    value={initialData?.pipPosition}
                                    labelCol={labelColInput}
                                    disabled
                                />
                            </Col>
                            <Col span={12}>
                                <InputFormItem
                                    name={"digits"}
                                    label={t("Digits")}
                                    labelCol={labelColInput}
                                    disabled
                                    value={initialData?.digits}
                                />
                            </Col>
                            <Col span={12}>
                                <InputFormItem
                                    name={"lotSize"}
                                    label={t("Lot Size")}
                                    labelCol={labelColInput}
                                    disabled
                                    value={initialData?.lotSize}
                                />
                            </Col>
                            <Col span={12}>
                                <InputFormItem
                                    name={"measurementUnits"}
                                    label={t("Units")}
                                    labelCol={labelColInput}
                                    value={initialData?.measurementUnits}
                                    disabled
                                />
                            </Col>
                            <Divider/>
                            <Col span={24}>
                                <CheckboxFormItem
                                    name={"futuresContract"}
                                    label={t("Futures Contract")}
                                    labelCol={labelColCheckboxFull}
                                    // value={initialData?.}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"datesTimeZone"}
                                    label={t("Time Zone")}
                                    labelCol={labelColInputFull}
                                    value={initialData?.futuresSettings?.datesTimeZone}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"lastTradeTimestampInMinutes"}
                                    label={t("Last Trade Date")}
                                    labelCol={labelColInputFull}
                                    value={initialData?.futuresSettings?.lastTradeTimestampInMinutes}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"expirationTimestampInMinutes"}
                                    label={t("Expiration Date")}
                                    labelCol={labelColInputFull}
                                    value={initialData?.futuresSettings?.expirationTimestampInMinutes}
                                    disabled
                                />
                            </Col>
                        </Row>
                    </form>
                </FormProvider>
            </Panel>
            <Panel showArrow={false} header={t("Symbol Profiles")} key={"symbolProfiles"}>
                <FormProvider {...form}>
                    <form className="form-2">
                        <Row gutter={[16, 4]}>
                            <Col span={24}>
                                <h3>{t("Sessions")}</h3>
                                <InputFormItem
                                    name={"sessionProfile"}
                                    label={t("Session Profile")}
                                    labelCol={labelColInputFull}
                                    value={initialData?.scheduleProfileId?.[0]?.name}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"holidayProfile"}
                                    label={t("Holiday Profile")}
                                    labelCol={labelColInputFull}
                                    value={initialData?.holidayProfileId?.[0]?.name}
                                    disabled
                                />
                            </Col>
                            <Divider />
                            <Col span={24}>
                                <h3>{t("Default Profiles")}</h3>
                                <InputFormItem
                                    name={"defaultCommissionProfileId"}
                                    label={t("Commission Profile")}
                                    labelCol={labelColInputFull}
                                    value={initialData?.defaultCommissionProfileId?.[0]?.name}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"defaultLeverageId"}
                                    label={t("Leverage Profile")}
                                    labelCol={labelColInputFull}
                                    value={initialData?.defaultLeverageId?.[0]?.name}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"defaultVolumeProfileId"}
                                    label={t("Volume Profile")}
                                    labelCol={labelColInputFull}
                                    value={initialData?.defaultVolumeProfileId?.[0]?.name}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"defaultExecutionProfileId"}
                                    label={t("Execution Profile")}
                                    labelCol={labelColInputFull}
                                    value={initialData?.defaultExecutionProfileId?.[0]?.name}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"defaultProtectionProfileId"}
                                    label={t("Protection Profile")}
                                    labelCol={labelColInputFull}
                                    value={initialData?.defaultProtectionProfileId?.[0]?.name}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"defaultSwapFreeProfileId"}
                                    label={t("Swap Free Profile")}
                                    labelCol={labelColInputFull}
                                    value={initialData?.defaultSwapFreeProfileId?.[0]?.name}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"defaultGSLScheduleId"}
                                    label={t("GSL Schedule Profile")}
                                    labelCol={labelColInputFull}
                                    value={initialData?.defaultGSLScheduleId?.[0]?.name}
                                    disabled
                                />
                            </Col>
                            <Divider />
                            <Col span={24}>
                                <h3>{t("Default Swap Settings")}</h3>
                                <InputFormItem
                                    name={"defaultSwapLong"}
                                    label={t("Swap (Long), pips")}
                                    labelCol={labelColInputFull}
                                    value={initialData?.defaultSwapLong}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"defaultSwapShort"}
                                    label={t("Swap (Short), pips")}
                                    labelCol={labelColInputFull}
                                    value={initialData?.defaultSwapShort}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"defaultSwapRollover3Days"}
                                    label={t("3-day Swap")}
                                    labelCol={labelColInputFull}
                                    value={initialData?.defaultSwapRollover3Days}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"weekendSwaps"}
                                    label={t("Weekend Swaps")}
                                    labelCol={labelColInputFull}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"defaultSwapTime"}
                                    label={t("Swap Time")}
                                    labelCol={labelColInputFull}
                                    value={initialData?.defaultSwapTime}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"defaultSwapPeriod"}
                                    label={t("Swap Period, hours")}
                                    labelCol={labelColInputFull}
                                    value={initialData?.defaultSwapPeriod}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"gracePeriod"}
                                    label={t("Grace Period")}
                                    labelCol={labelColInputFull}
                                    disabled
                                />
                            </Col>
                            <Divider />
                            <Col span={24}>
                                <h3>{t("Default Dividends settings")}</h3>
                                <InputFormItem
                                    name={"defaultDividendTime"}
                                    label={t("Dividends Time")}
                                    labelCol={labelColInputFull}
                                    value={initialData?.defaultDividendTime}
                                    disabled
                                />
                            </Col>
                        </Row>
                    </form>
                </FormProvider>
            </Panel>
            <Panel showArrow={false} header={t("Pricing Settings and Data")} key={"pricingSettingsAndData"}>
                <FormProvider {...form}>
                    <form className="form-3">
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <InputFormItem
                                    name={"bidMarkUp"}
                                    label={t("Markup Bid, in Points (Digits)")}
                                    labelCol={labelColInput}
                                    value={initialData?.bidMarkUp}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"askMarkUp"}
                                    label={t("Markup Ask, in Points (Digits)")}
                                    labelCol={labelColInput}
                                    value={initialData?.askMarkUp}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"quoteExpirationTimeout"}
                                    label={t("Quote Expiration Timeout, sec")}
                                    labelCol={labelColInput}
                                    value={initialData?.quoteExpirationTimeout}
                                    disabled
                                />
                            </Col>
                            <Divider />
                            <Col span={24}>
                                <InputFormItem
                                    name={"priceLiquidityFeedId"}
                                    label={t("Pricing Liquidity Feed")}
                                    labelCol={labelColInput}
                                    value={initialData?.priceLiquidityFeedId?.[0]?.name}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"tradeLiquidityFeedId"}
                                    label={t("Trading Liquidity Feed")}
                                    labelCol={labelColInput}
                                    value={initialData?.tradeLiquidityFeedId?.[0]?.name}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"priceAlias"}
                                    label={t("LP Price Alias")}
                                    labelCol={labelColInput}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"tradeAlias"}
                                    label={t("LP Trade Alias")}
                                    labelCol={labelColInput}
                                    disabled
                                />
                            </Col>
                            <Divider />
                            <Col span={24}>
                                <InputFormItem
                                    name={"date"}
                                    label={t("Date")}
                                    labelCol={labelColInput}
                                    disabled
                                />
                            </Col>
                        </Row>
                    </form>
                </FormProvider>
            </Panel>
            <Panel showArrow={false} header={t("Price Filtering")} key={"priceFiltering"}>
                <FormProvider {...form}>
                    <form className="form-4">
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <InputFormItem
                                    name={"dropQuote"}
                                    label={t("Drop Quote by the Specified Value")}
                                    labelCol={labelColInput}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"negativeSpread"}
                                    label={t("Max Negative Spread, pips")}
                                    labelCol={labelColInput}
                                    disabled
                                />
                            </Col>
                        </Row>
                    </form>
                </FormProvider>
            </Panel>
            <Panel showArrow={false} header={t("Feed Symbol Setting")} key={"feedSymbolSetting"}>
                <p>
                    {t("List of the possible Liquidity Feeds for the Symbol")}
                </p>
                <Table columns={columns}/>
            </Panel>
            <Panel showArrow={false} header={t("Aliases for Third-Party Services")} key={"aliasesThirdPartyServices"}>
                <FormProvider {...form}>
                    <form className="form-4">
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <InputFormItem
                                    name={"tradingCentralAlias"}
                                    label={t("Trading Central")}
                                    labelCol={labelColInputFull}
                                    value={initialData?.tradingCentralAlias}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"autochartistAlias"}
                                    label={t("Autochartist")}
                                    labelCol={labelColInputFull}
                                    value={initialData?.autochartistAlias}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"bbspAlias"}
                                    label={t("BBSP")}
                                    labelCol={labelColInputFull}
                                    value={initialData?.bbspAlias}
                                    disabled
                                />
                            </Col>
                        </Row>
                    </form>
                </FormProvider>
            </Panel>
        </Collapse>
    )
};

export {SymbolSider};
