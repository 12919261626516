import React, {useEffect, useState} from "react";
import {Col, Collapse, Form, Input, Modal, Row, Switch} from "antd";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {languageOptionsState} from "../../../../../../users/_common/state";
import {notificationMessage} from "../../../../../../../../recoil_state";
import {createBadgeSetting, updateBadgeSetting} from "../../../../../../../api/restful/arena/badgeSetting";
import MediaSelect from "../../../../../_common/mediaComponent";

const CreateUpdateModal = ({open, handleClose, item, fetchBadges, handleBadgeCreatedRemind}) => {
    const [form] = Form.useForm()
    const languageOptions = useRecoilValue<any>(languageOptionsState)
    const [loading, setLoading] = useState(false)
    const isUpdate = !!item
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const onSubmit = async (value) => {
        setLoading(false)
        if (!isUpdate) {
            try {
                const response = await createBadgeSetting(value)

                if (response?.status === "Success") {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: "Create Badge Successfully",
                        key: "arenaBadgeSettingNotification",
                    })

                    fetchBadges()
                    handleClose()

                    handleBadgeCreatedRemind(response?.data)
                } else {
                    setNotificationMessage({
                        status: "error",
                        title: "",
                        msg: `Create Badge Failed: ${response?.errObj}`,
                        key: "arenaBadgeSettingNotification",
                    })
                }
            } catch (e) {
            } finally {
                setLoading(false)
            }
        } else {
            try {
                const response = await updateBadgeSetting({...value, _id: item?._id})

                if (response?.status === "Success") {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: "Update Badge Successfully",
                        key: "arenaBadgeSettingNotification",
                    });

                    fetchBadges()
                    handleClose()
                } else {
                    setNotificationMessage({
                        status: "error",
                        title: "",
                        msg: `Update Badge Failed: ${response?.errObj}`,
                        key: "arenaBadgeSettingNotification",
                    });
                }
            } catch (e) {
            } finally {
                setLoading(false)
            }
        }
    }

    const items = [
        {
            key: "displayName",
            label: "Display Name",
            children: (
                <Form.List name={"displayName"}>
                    {(fields) => (
                        <>
                            {
                                fields.map(field => {
                                    const language = languageOptions.find(d => d.value === form.getFieldValue(['displayName', field.name, 'language']))?.label
                                    return (
                                        (
                                            <Row className={"margin-bottom-0-3"} gutter={[8, 8]} key={field.name}>
                                                <Col span={8}>
                                                    <>{language}</>
                                                </Col>
                                                <Col span={16}>
                                                    <Form.Item noStyle name={[field.name, 'content']}>
                                                        <Input/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        )
                                    )
                                })
                            }
                        </>
                    )}
                </Form.List>
            )
        },
        {
            key: "description",
            label: "Description",
            children: (
                <Form.List name={"description"}>
                    {(fields) => (
                        <>
                            {
                                fields.map(field => {
                                    const language = languageOptions.find(d => d.value === form.getFieldValue(['description', field.name, 'language']))?.label
                                    return (
                                        (
                                            <Row className={"margin-bottom-0-3"} gutter={[8, 8]} key={field.name}>
                                                <Col span={8}>
                                                    <>{language}</>
                                                </Col>
                                                <Col span={16}>
                                                    <Form.Item noStyle name={[field.name, 'content']}>
                                                        <Input/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        )
                                    )
                                })
                            }
                        </>
                    )}
                </Form.List>
            )
        }
    ]

    useEffect(() => {
        if (!item) {
            form.resetFields()
            form.setFieldValue("displayName", languageOptions?.map(lang => ({language: lang?.value, content: ""})))
            form.setFieldValue("description", languageOptions?.map(lang => ({language: lang?.value, content: ""})))
        } else {
            form.setFieldsValue(item)
        }
    }, [open, item]);


    return (
        <Modal
            open={open}
            onCancel={handleClose}
            title={"Create New Badge Setting"}
            onOk={form.submit}
            okButtonProps={{loading: loading}}
            destroyOnClose
            okText={"Submit"}
        >
            <Form form={form} onFinish={onSubmit} layout={"vertical"}>
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                        <Form.Item name={"name"} label={"Name"}>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={"isEnabled"} label={"Is Enabled"} valuePropName={"checked"}>
                            <Switch/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={"light"} hidden/>
                        <Form.Item label={"Icon (Light)"} shouldUpdate className={"align-start"}>
                            {({getFieldValue, setFieldValue}) => {
                                const currentValue = getFieldValue('light');
                                return (
                                    <MediaSelect
                                        title={`Select Icon (Light)`}
                                        passMedia={(value) => setFieldValue("light", value)}
                                        selectedFile={currentValue}
                                        placement={"bottomLeft"}
                                    />
                                );
                            }}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={"dark"} hidden/>
                        <Form.Item label={"Icon (Dark)"} shouldUpdate className={"align-start"}>
                            {({getFieldValue, setFieldValue}) => {
                                const currentValue = getFieldValue('dark');
                                return (
                                    <MediaSelect
                                        title={`Select Icon (Dark)`}
                                        passMedia={(value) => setFieldValue("dark", value)}
                                        selectedFile={currentValue}
                                        placement={"bottomLeft"}
                                    />
                                );
                            }}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Collapse ghost items={items}/>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default CreateUpdateModal