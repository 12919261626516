import React, {useEffect, useMemo, useState} from "react";
import {Button, Space, Tabs} from "antd";
import ProductTable_1 from "./component/productTable";
import ExportTableButton from "../../../../../components/table/function/exportTable";
import EditTableUserPreferenceButton from "../../../../../components/table/function/editTableUserPreferenceButton";
import {useTranslation} from "react-i18next";

const Product_1 = (props) => {
    const {data, permission} = props;
    const {t} = useTranslation();
    const [isOpenProductForm, setIsOpenProductForm] = useState(false);
    const [mode, setMode] = useState("");
    const [selectedItem, setSelectedItem] = useState({});

    const [exportData, setExportData] = useState(null);
    const [exportColumn, setExportColumn] = useState(null);
    const [currentServer, setCurrentServer] = useState();
    const [exportLoading, setExportLoading] = useState(false);
    const tableName = "Product";

    useEffect(() => {
        if (data?.getServerSetting?.data?.length > 0) {
            setCurrentServer(data?.getServerSetting?.data?.[0]);
        }
    }, [data?.getServerSetting?.data]);

    const openAddModal = () => {
        setMode("Add");
        setIsOpenProductForm(true);
    };

    const openEditModal = (record) => {
        setMode("Edit");
        setIsOpenProductForm(true);
        setSelectedItem(record);
    };

    const closeModal = () => {
        setIsOpenProductForm(false);
        setSelectedItem({});
    };

    const tabItems = useMemo(() => {
        return (
            data &&
            data?.getServerSetting &&
            data?.getServerSetting?.data?.map((item, i) => {
                return {
                    label: t(item?.name),
                    key: i,
                    children: (
                        <>
                            {item?.mode?.map((modeName) => (
                                <ProductTable_1
                                    server={item}
                                    isOpen={isOpenProductForm}
                                    close={closeModal}
                                    item={selectedItem}
                                    mode={mode}
                                    openEdit={openEditModal}
                                    selectedItem={selectedItem}
                                    setExportData={setExportData}
                                    setExportColumn={setExportColumn}
                                    tableName={tableName}
                                    currentServer={currentServer}
                                    setExportLoading={setExportLoading}
                                    permission={permission}
                                    productMode={modeName}
                                />
                            ))}
                        </>
                    ),
                };
            })
        );
    }, [data, isOpenProductForm, selectedItem, tableName, currentServer, permission]);

    return (
        <div id="product">
            <h1>{t(tableName)}</h1>

            {data && data?.getServerSetting?.data && (
                <Tabs
                    defaultActiveKey="1"
                    size={"small"}
                    items={tabItems}
                    tabBarExtraContent={
                        <Space>
                            <EditTableUserPreferenceButton tableName={tableName} server={currentServer} tableColumn={exportColumn} />
                            <ExportTableButton
                                loading={exportLoading}
                                tableData={exportData}
                                tableColumn={exportColumn}
                                tableName={tableName}
                                server={currentServer}
                            />
                            {permission?.add?.add && (
                                <Button type={"primary"} onClick={() => openAddModal()}>
                                    {t("Add Product")}
                                </Button>
                            )}
                        </Space>
                    }
                    onTabClick={(key) => {
                        setCurrentServer(data?.getServerSetting?.data?.[key]);
                    }}
                />
            )}
        </div>
    );
};

export default Product_1;
