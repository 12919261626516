import React, {useMemo} from "react";
import PreviewAndroid from "./previewAndroid";
import PreviewIphone from "./previewIphone";
import PreviewWindows from "./previewWindows";
import PreviewMacOS from "./previewMacOS";
import {findImagePath, replaceDynamicVariables} from "../../../function";

const TemplatePreview = (props) => {
    const {notificationData, deviceType, languageCode, imageVarData, textVarData} = props;

    const imagePath = useMemo(() =>
            findImagePath(notificationData?.notification?.image, imageVarData, languageCode),
        [notificationData, imageVarData, languageCode]);

    const iconPath = useMemo(() =>
            findImagePath(notificationData?.notification?.icon, imageVarData, languageCode),
        [notificationData, imageVarData, languageCode]);

    const title = useMemo(() =>
            replaceDynamicVariables(notificationData?.notification?.title, textVarData, languageCode),
        [notificationData, textVarData, languageCode]);

    const body = useMemo(() =>
            replaceDynamicVariables(notificationData?.notification?.body, textVarData, languageCode),
        [notificationData, textVarData, languageCode]);

    const extendedProps = {...props, title, body, imagePath, iconPath};

    const PreviewContent = () => {
        switch (deviceType) {
            case "android":
                return <PreviewAndroid {...extendedProps} />;
            case "iphone":
                return <PreviewIphone {...extendedProps} />;
            case "windows":
                return <PreviewWindows {...extendedProps} />;
            case "macos":
                return <PreviewMacOS {...extendedProps} />;
            default:
                return null;
        }
    };

    return (
        <div id="pushNotificationTemplatePreview">
            <PreviewContent/>
        </div>
    );
};

export default TemplatePreview;
