import axios from "axios";
import {getConfigByName} from "../../../../config";
import {BRAND_NAME} from "../../../../config/brandVariable";

const url = getConfigByName(BRAND_NAME).automationURL
const config = {headers: {"Content-Type": "text/plain"}}

export async function getCustomActionTemplate(param) {
    try {
        const filter = {filter: param};
        return await axios.post(`${url}/api/custom_action_template/get`, JSON.stringify(filter), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export async function getCustomActionTemplateByTrigger(trigger) {
    try {
        const filter = {trigger: trigger};
        return await axios.post(`${url}/api/custom_action_template/get_by_trigger`, JSON.stringify(filter), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export async function createCustomActionTemplate (input) {
    try {
        return await axios.post(`${url}/api/custom_action_template/create`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export async function updateCustomActionTemplate (input) {
    try {
        return await axios.post(`${url}/api/custom_action_template/update`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export async function deleteCustomActionTemplate (input) {
    try {
        return await axios.post(`${url}/api/custom_action_template/delete`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}