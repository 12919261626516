import React, {useEffect} from "react";
import {Form, Input, Modal, Select, Upload} from "antd";
import {InboxOutlined} from "@ant-design/icons";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../recoil_state";
import {uploadMedia} from "../../../../../../api/restful/arena/media_file";
import {useTranslation} from "react-i18next";

const UploadModal = ({open, handleClose, fetchMedia, folderOption, currentPath}) => {
    const {t} = useTranslation()
    const [form] = Form.useForm()
    const setNotificationMessage = useSetRecoilState(notificationMessage)

    const onSubmit = async (value) => {
        const {folder, file} = value
        const formData = new FormData()

        formData.append("folder", folder)

        if (file && file.length > 0) {
            formData.append("file", file[0].originFileObj)
        }

        try {
            const response = await uploadMedia(formData)
            if (response.status === "Success") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Upload Media Successfully",
                    key: "arenaMediaLibraryNotification",
                });

                fetchMedia()
                handleClose()
            } else {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: `Upload Media Failed: ${response?.errObj}`,
                    key: "arenaMediaLibraryNotification",
                });
            }
        } catch (e:any) {
            console.log(e?.response?.data)

            setNotificationMessage({
                status: "error",
                title: "",
                msg: `Upload Media Failed`,
                key: "arenaMediaLibraryNotification",
            });
        }
    }

    const normalizingFileUpload = (event: any) => {
        if (Array.isArray(event)) {
            return event;
        }
        return event && event.fileList;
    };

    useEffect(() => {
        if (open) {
            form.setFieldsValue({
                file: null,
                folder: currentPath.at(-1)?._id || ""
            })
        }
    },[open, currentPath])

    return (
        <Modal
            open={open}
            title={"Upload Media"}
            onCancel={handleClose}
            onOk={form.submit}
            getContainer={"#media-library"}
            className={"upload-modal"}
            okText={t("common.upload")}
        >
            <Form form={form} onFinish={onSubmit} labelCol={{span: 24}}>
                <Form.Item label={"Folder"} name={"folder"}>
                    <Select options={folderOption}/>
                </Form.Item>
                <Form.Item rules={[{required: true}]} label={"File"} name={"file"} valuePropName={"fileList"} getValueFromEvent={normalizingFileUpload}>
                    <Upload.Dragger
                        beforeUpload={() => false}
                        listType="picture-card"
                        maxCount={1}
                        showUploadList={{showPreviewIcon: false, showRemoveIcon: true}}
                        accept="image/png, image/jpeg, image/gif, image/webp, image/svg+xml"
                    >
                        <p className="ant-upload-drag-icon" style={{marginBottom: 4}}>
                            <InboxOutlined/>
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    </Upload.Dragger>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default UploadModal