import React, {useEffect, useState} from "react";
import TradingAccount_1 from "./view/layout1";
import {getTradingAccount} from "../../../../api/graphql/tradingAccount";
import {useRecoilState} from "recoil";
import {
    tradingAccountDateKeySearchState,
    tradingAccountEndTimeSearchState,
    tradingAccountFilterState,
    tradingAccountFilterTypeIdSearchState,
    tradingAccountFilterTypeSearchState,
    tradingAccountLimitState,
    tradingAccountOffsetState,
    tradingAccountOrderBySearchState,
    tradingAccountStartTimeSearchState
} from "./state";
import {useGetTradingAccounts} from "./function/useQueryHooks";

function TradingAccountPage({spreadGroupId}) {

    const searchParams = new URLSearchParams(location.search);
    const userid = searchParams.get('user');
    const spreadGroup = searchParams.get('spreadGroup') || spreadGroupId;
    const commRule = searchParams.get('commRule');

    const [tradingAccountStartTimeSearch, setTradingAccountStartTimeSearch] = useRecoilState(tradingAccountStartTimeSearchState);
    const [tradingAccountEndTimeSearch, setTradingAccountEndTimeSearch] = useRecoilState(tradingAccountEndTimeSearchState);
    const [tradingAccountDateKeySearch, setTradingAccountDateKeySearch] = useRecoilState(tradingAccountDateKeySearchState);
    const [tradingAccountOrderBySearch, setTradingAccountOrderBySearch] = useRecoilState(tradingAccountOrderBySearchState);
    const [tradingAccountFilter, setTradingAccountFilter] = useRecoilState(tradingAccountFilterState)
    const [tradingAccountLimit, setTradingAccountLimit] = useRecoilState(tradingAccountLimitState)
    const [tradingAccountOffset, setTradingAccountOffset] = useRecoilState(tradingAccountOffsetState)
    const [tradingAccountFilterType, setTradingAccountFilterType] = useRecoilState(tradingAccountFilterTypeSearchState)
    const [tradingAccountFilterId, setTradingAccountFilterId] = useRecoilState(tradingAccountFilterTypeIdSearchState)
    const [tradingAccountExtraFilter, setTradingAccountExtraFilter] = useState({})

    let layout = 1;
    let component;

    const [data, loading, fetchTradingAccounts, refetchTradingAccounts] = useGetTradingAccounts()

    const isClearFilterVisible = Object.keys(tradingAccountFilter)?.length > 0 || (tradingAccountDateKeySearch && tradingAccountStartTimeSearch) || tradingAccountFilterId
    const resetFilters = () => {
        setTradingAccountFilter({})
        setTradingAccountFilterType(null)
        setTradingAccountFilterId(null)
        setTradingAccountDateKeySearch("")
        setTradingAccountStartTimeSearch("")
        setTradingAccountEndTimeSearch("")
        setTradingAccountExtraFilter({})
    }

    // const tradingAccountData = getTradingAccount({
    //     filter: tradingAccountFilter
    // }, tradingAccountFilterType, tradingAccountFilterId, tradingAccountOrderBySearch, tradingAccountLimit, tradingAccountOffset, tradingAccountStartTimeSearch, tradingAccountEndTimeSearch, tradingAccountDateKeySearch)
    //
    // const tradingAccountDataExport = getTradingAccount({
    //     filter: tradingAccountFilter
    // }, tradingAccountFilterType, tradingAccountFilterId, tradingAccountOrderBySearch, null, null, tradingAccountStartTimeSearch, tradingAccountEndTimeSearch, tradingAccountDateKeySearch)

    useEffect(() => {
        fetchTradingAccounts({filter: {...tradingAccountFilter, ...tradingAccountExtraFilter}}, tradingAccountFilterType, tradingAccountFilterId, tradingAccountOrderBySearch, tradingAccountLimit, tradingAccountOffset, tradingAccountStartTimeSearch, tradingAccountEndTimeSearch, tradingAccountDateKeySearch)
    }, [tradingAccountFilter, tradingAccountFilterType, tradingAccountFilterId, tradingAccountOrderBySearch, tradingAccountLimit, tradingAccountOffset, tradingAccountStartTimeSearch, tradingAccountEndTimeSearch, tradingAccountDateKeySearch, tradingAccountExtraFilter]);

    useEffect(() => {
        setTradingAccountLimit(spreadGroupId ? 10 : 20)
        setTradingAccountOffset(0)
        setTradingAccountOrderBySearch("registrationDate_DESC")

        if (userid) {
            setTradingAccountExtraFilter({userid: userid})
        } else if (spreadGroup) {
            setTradingAccountExtraFilter({spreadGroupId: spreadGroup})
        } else if (commRule) {
            setTradingAccountExtraFilter({commRule: commRule})
        }
    }, [userid, spreadGroup, commRule])

    switch (layout) {
        case 1:
            component = <TradingAccount_1
                data={data?.data}
                refetch={refetchTradingAccounts}
                // exportData={tradingAccountDataExport?.data?.getTradingAccount?.data}
                total={data?.total}
                loading={loading}
                userid={userid}
                isSpreadGroup={spreadGroupId}
                spreadGroup={spreadGroup}
                commRule={commRule}
                resetFilters={resetFilters}
                isClearFilterVisible={isClearFilterVisible}
            />;
            break;

        default:
            component = <TradingAccount_1
                data={data?.data}
                refetch={refetchTradingAccounts}
                // exportData={tradingAccountDataExport?.data?.getTradingAccount?.data}
                total={data?.total}
                loading={loading}
                userid={userid}
                isSpreadGroup={spreadGroupId}
                spreadGroup={spreadGroup}
                commRule={commRule}
                resetFilters={resetFilters}
                isClearFilterVisible={isClearFilterVisible}
            />;
            break;
    }

    return component;
}

export default TradingAccountPage;
