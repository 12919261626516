import React, {useEffect, useState} from "react";
import moment from "moment";
import {useTranslation} from "react-i18next";
import SearchableTree from "../../../../../components/tree/searchableTree";
import {calculateTotalDownline} from "../../../../../function/calculateTotalDownline";

const SalesRepNetworkTree_1 = (props) => {
    const {data, refetch} = props;
    const {t} = useTranslation();
    const [amendedData, setAmendedData] = useState<any>([]);

    const transformData = (data) => {
        try {
            const result: any = [];
            const lookup: any = {};

            data?.forEach((item) => {
                lookup[item?.userid?._id] = {
                    ...item,
                    children: [],
                    totalDirectDownline: 0,
                    totalDownline: 0,
                };
            });

            data?.forEach((item) => {
                if (lookup && item?.parent?._id && lookup[item?.parent?._id]) {
                    lookup[item?.parent._id].children.push(lookup[item?.userid?._id]);
                    lookup[item?.parent._id].totalDirectDownline += 1;
                } else {
                    result?.push(lookup[item?.userid?._id]);
                }
            });

            result?.forEach(amendFields);
            setAmendedData(result);

            return result;
        } catch (error) {}
    };

    const amendFields = (obj) => {
        try {
            obj.key = obj?.userid?._id;
            obj.totalDownline = calculateTotalDownline(obj);
            const userData = obj?.userid;
            obj.userType = userData?.userType;
            let joinedDate = moment(userData?.createdAt).format("YYYY-MM-DD");
            let name = userData?.fullName || userData?.lastName || "";
            let username = userData?.username || "-";
            let totalDirectDownline = obj?.totalDirectDownline;
            let totalDownline = obj?.totalDownline;
            obj.title = `${username} ${name ? `(${name})` : ""} | ${userData?.userType} | ${joinedDate} | ${totalDirectDownline} | ${totalDownline}`;

            if (obj?.children) {
                obj?.children.forEach(amendFields);
            }

            delete obj?._id;
            delete obj?.userid;
            delete obj?.parent;
            delete obj?.__typename;

            return obj;
        } catch (error) {}
    };

    const refetchNetwork = () => {
        refetch();
    };

    useEffect(() => {
        if (data?.length > 0) {
            transformData(data);
        }
    }, [data]);

    return (
        <div id="sales-rep-network-tree">
            <h1>{t("Sales Rep Network Tree")}</h1>
            <SearchableTree
                defaultData={amendedData}
                note="Username (Name) | Type | Join Date | Total Direct Downline | Total Downline"
                refetchNetwork={refetchNetwork}
            />
        </div>
    );
};

export default SalesRepNetworkTree_1;
