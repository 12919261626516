import React, {useEffect, useState} from 'react';
import {Card, Col, Empty, Row, Spin, Tag, Tooltip,} from 'antd';
import VirtualList from 'rc-virtual-list';
import {useTranslation} from "react-i18next";
import {PlusOutlined} from "@ant-design/icons";
import {useUpdateRegistrationSettingMutation} from "../../../../../../api/graphql/register";
import {useRecoilValue} from "recoil";
import {registrationSettingModeState, registrationSettingPlatformState, registrationSettingState} from "../../../state";
import {fieldListLoading, fieldListState} from "../../../../fields/state";
import useAuthorize from '../../../../../../_common/authorize';


const RegistrationFieldCard = (props) => {
    const {permission, clientField, categoryFilter, labelFilter} = props
    const ContainerHeight = "70vh";
    const {t} = useTranslation();

    const platform = useRecoilValue(registrationSettingPlatformState)
    const mode = useRecoilValue(registrationSettingModeState)
    const registrationSetting = useRecoilValue(registrationSettingState)
    const loading = useRecoilValue(fieldListLoading)
    const fieldList = useRecoilValue<any>(fieldListState)

    const [fieldData, setFieldData] = useState<any>([])
    const [filteredFieldData, setFilteredFieldData] = useState<any>([])
    const {updateRegistrationSetting, loading: updateLoading} = useUpdateRegistrationSettingMutation()
    const {systemErrorNotification} = useAuthorize();

    const addRegistrationField = async (record: any) => {
        try {
            const response = await updateRegistrationSetting({
                fieldList: record?._id,
                platform: platform,
                mode: mode,
                position: registrationSetting?.length ? registrationSetting?.length + 1 : 1
            }) 
        } catch (error) {
            systemErrorNotification();
        }
    }

    const formatData = async (presetFields, existingFields) => {
        if (fieldList?.length > 0) {
            const registrationFieldList = (mode === "live" ? fieldList?.filter(item => (item?.category === "registrationInformation")) :
                fieldList?.filter(item => (item?.category === "registrationInformation" || item?.category === "demoInformation"))) || []

            presetFields?.push(...registrationFieldList)

            if (presetFields?.length > 0 && existingFields?.length > 0) {
                return await presetFields?.filter(presetItem => {
                    return !existingFields.some(existingItem => presetItem?._id === existingItem?.fieldList?._id);
                })
            } else {
                return presetFields
            }
        } else return presetFields
    }

    useEffect(() => {
        const clientFieldList = clientField?.filter(item => item?.position !== null)?.map(d => d?.fieldList)
        formatData(clientFieldList, registrationSetting)?.then(data => {
            setFieldData(data)
            setFilteredFieldData(data)
        })
    }, [clientField, registrationSetting])

    useEffect(() => {
        if (categoryFilter && categoryFilter !== "all") setFilteredFieldData(fieldData?.filter(item => item?.category === categoryFilter))
        else if (categoryFilter === "all") setFilteredFieldData(fieldData)

        if (labelFilter) setFilteredFieldData(fieldData?.filter(item => item?.label?.toLowerCase()?.includes(labelFilter?.toLowerCase())))
    }, [categoryFilter, labelFilter])

    let categoryColor
    return (
        <div id={"registrationFieldCard"}>
            <Row style={{marginBottom: "15px"}}>
                <Col flex={"auto"}>
                    <h3>{t("Field")}</h3>
                </Col>
            </Row>

            <Row>
                <Col span={24} style={{height: ContainerHeight, overflow: "auto"}}>
                    {
                        permission?.fields?.view && filteredFieldData?.length > 0 ? (
                            <VirtualList
                                data={filteredFieldData}
                                itemKey="_id"
                            >
                                {(item) => {
                                    switch (item?.category) {
                                        case "basicInformation" :
                                            categoryColor = "gold"
                                            break
                                        case "loginInformation" :
                                            categoryColor = "lime"
                                            break
                                        case "residentialInformation" :
                                            categoryColor = "green"
                                            break
                                        case "additionalInformation" :
                                            categoryColor = "geekblue"
                                        case "corporateInformation" :
                                            categoryColor = "purple"
                                            break
                                        case "registrationInformation" :
                                            categoryColor = "cyan"
                                            break
                                        case "demoInformation" :
                                            categoryColor = "default"
                                            break
                                    }

                                    return (
                                        <Card className={"registrationCard margin-top-0-75"} key={item?._id}
                                              style={{marginRight: 8}}>
                                            <Row>
                                                <Col flex={"auto"}>
                                                    <h4>{t(item?.label)} {item?.isRequired ?
                                                        <span style={{color: "red"}}> * </span> : undefined}</h4>
                                                </Col>
                                                {permission?.registrationWorkflow?.edit?.edit &&
                                                <Col flex={"20px"}>
                                                    <Tooltip title={t("Add")}>
                                                        <a onClick={() => {
                                                            addRegistrationField(item)
                                                        }}>
                                                            <PlusOutlined/>
                                                        </a>
                                                    </Tooltip>
                                                </Col>}
                                                <Col span={24}>
                                                    <Tag color={"blue"}
                                                         bordered={false}><small>{item?.fieldType?.toUpperCase()}</small></Tag>
                                                    <Tag color={categoryColor}
                                                         bordered={false}><small>{item?.category?.replace(/(INFORMATION)/i, "")?.toUpperCase()}</small></Tag>
                                                    {
                                                        item?.isConfirmRequired &&
                                                        <Tag color="magenta" style={{marginTop: 4}}
                                                             bordered={false}><small>{t("CONFIRM REQUIRED")}</small></Tag>
                                                    }
                                                    {
                                                        item?.isSensitive &&
                                                        <Tag color="volcano"
                                                             bordered={false}><small>{t("SENSITIVE")}</small></Tag>
                                                    }
                                                    {
                                                        item?.isUnique &&
                                                        <Tag color="orange"
                                                             bordered={false}><small>{t("UNIQUE")}</small></Tag>
                                                    }
                                                    {
                                                        item?.isUsedForLogin &&
                                                        <Tag color="red"
                                                             bordered={false}><small>{t("USED FOR LOGIN")}</small></Tag>
                                                    }
                                                </Col>
                                            </Row>
                                        </Card>
                                    )
                                }}
                            </VirtualList>
                        ) : permission?.fields?.view && loading ? (
                            <Col span={24} style={{marginTop: "20%", textAlign: "center"}}>
                                <Spin size="large"/>
                            </Col>
                        ) : (
                            <Col span={24} style={{paddingTop: "20%"}}>
                                <Empty/>
                            </Col>
                        )
                    }
                </Col>
            </Row>
        </div>
    )
}

export default RegistrationFieldCard