import {gql, useMutation, useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {GET_PROFILE_INFORMATION} from "./profileInformation";
import {ApolloClientQuery} from "./_apolloClient";

const GET_FIELD_LIST = gql`
query getFieldList($filter: FieldListSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
  getFieldList(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
    ...on FieldListArray {
      data {
        _id
        label
        fieldName
        fieldType
        isSensitive
        isRequired
        isUnique
        isConfirmRequired
        isUsedForLogin
        optionValue {
          label
          value
          key
        }
        category
        isOptionValuesEditable
        isDisplayToClient
        columnWidth
        minCharacterLimit
        maxCharacterLimit
      }
    }
    ...on BaseError {
      errKey
      errMsg
    }
  }
}
`

const getFieldList = (filter?, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data:fieldList} = useQuery(GET_FIELD_LIST, {
            variables: {
                filter: filter,
                limit: limit,
                offset: offset,
                orderBy: orderBy,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, fieldList}
    } catch (err: any) {
        console.error(err);
        return {fieldList: null, loading: false, error: err};
    }
}

const getFieldListAsync = async (filter?, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_FIELD_LIST,
            {
                filter: filter,
                limit: limit,
                offset: offset,
                orderBy: orderBy,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {fieldList: null, loading: false, error: err};
    }
}

const GET_LEADS_FIELD_LIST = gql`
query getUserLeadsFieldList($filter: UserLeadsFieldListSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
  getUserLeadsFieldList(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
    ...on UserLeadsFieldListArray {
      data {
        _id
        label
        fieldName
        fieldType
        isSensitive
        isRequired
        isUnique
        isConfirmRequired
        optionValue {
          label
          value
          key
        }
        category
        isOptionValuesEditable
        isDisplayToClient
        columnWidth
        minCharacterLimit
        maxCharacterLimit
      }
    }
    ...on BaseError {
      errKey
      errMsg
    }
  }
}
`

const getUserLeadsFieldList = (filter?, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data:fieldList} = useQuery(GET_LEADS_FIELD_LIST, {
            variables: {
                filter: filter,
                limit: limit,
                offset: offset,
                orderBy: orderBy,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, fieldList}
    } catch (err: any) {
        console.error(err);
        return {fieldList: null, loading: false, error: err};
    }
}

const UPDATE_FIELD_SETTING = gql`
mutation updateFieldList($id: String!, $input: FieldListInput) {
  updateFieldList(id: $id, input: $input) {
    ...on FieldList {
      _id
      label
      fieldName
      fieldType
      isSensitive
      isRequired
      isUnique
      isUsedForLogin
      optionValue {
        label
        value
        key
      }
      category
      isSensitive
    }
    ...on BaseError {
      errKey
      errMsg
    }
    ...on CrmTaskApproval {
      msg
    }
  }
}
`;

const useUpdateFieldListMutation = (): { updateFieldList: any, data: any, loading: boolean, error: any } => {
    try{
        const { t } = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updateFieldListMutation, { data, loading, error }] = useMutation(UPDATE_FIELD_SETTING);

        const updateFieldList = (id, input) => {
            return updateFieldListMutation({
                variables: {
                    id: id,
                    input: input,
                },
                update(cache, {data: {updateFieldList}}) {
                    const typename = updateFieldList?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateFieldList?.errKey),
                            key: "updateFieldList",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateFieldList?.msg),
                            key: "updateFieldList",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t(`Update Field Successfully`),
                            key: "updateFieldList",
                        });
                    }
                },
                refetchQueries:[GET_FIELD_LIST]
            });
        };

        return { updateFieldList, data, loading, error }
    }
    catch(err: any){
        console.error(err);
        return { updateFieldList: null, data: null, loading: false, error: err };
    }
}

const UPDATE_LEADS_FIELD_SETTING = gql`
mutation updateUserLeadsFieldList($input: UserLeadsFieldListInput) {
  updateUserLeadsFieldList(input: $input) {
    ...on UserLeadsFieldList {
      _id
      label
      fieldName
      fieldType
      isSensitive
      isRequired
      isUnique
      isConfirmRequired
      optionValue {
        label
        value
        key
      }
      category
    }
    ...on BaseError {
      errKey
      errMsg
    }
    ...on CrmTaskApproval {
      msg
    }
  }
}
`;

const useUpdateLeadsFieldListMutation = (): { updateUserLeadsFieldList: any, data: any, loading: boolean, error: any } => {
    try{
        const { t } = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updateUserLeadsFieldListMutation, { data, loading, error }] = useMutation(UPDATE_LEADS_FIELD_SETTING);

        const updateUserLeadsFieldList = (input) => {
            return updateUserLeadsFieldListMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {updateUserLeadsFieldList}}) {
                    const typename = updateUserLeadsFieldList?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateUserLeadsFieldList?.errKey),
                            key: "updateUserLeadsFieldList",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateUserLeadsFieldList?.msg),
                            key: "updateUserLeadsFieldList",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t(`Update Field Successfully`),
                            key: "updateUserLeadsFieldList",
                        });
                    }
                },
                refetchQueries: [GET_LEADS_FIELD_LIST, GET_PROFILE_INFORMATION]
            });
        };

        return { updateUserLeadsFieldList, data, loading, error }
    }
    catch(err: any){
        console.error(err);
        return { updateUserLeadsFieldList: null, data: null, loading: false, error: err };
    }
}


export {
    getFieldList,
    getFieldListAsync,
    getUserLeadsFieldList,
    useUpdateFieldListMutation,
    useUpdateLeadsFieldListMutation
}