import {gql, useQuery} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";

const GET_PUSH_NOTIFICATION_SERVER_SETTING = gql`
    query getPushNotificationServerSetting($filter: PushNotificationServerSettingInput, $limit: Int, $offset: Int, $orderBy: String) {
        getPushNotificationServerSetting(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on PushNotificationServerSettingAry {
                data {
                    _id
                    name
                    targetPlatform
                    provider
                    firebase {
                        config {
                            apiKey
                            authDomain
                            projectId
                            storageBucket
                            messagingSenderId
                            appId
                            measurementId
                        }
                        vapid
                        credential {
                            type
                            project_id
                            private_key_id
                            private_key
                            client_email
                            client_id
                            auth_uri
                            token_uri
                            auth_provider_x509_cert_url
                            client_x509_cert_url
                            universe_domain
                        }
                    }
                    isDeleted
                    createdBy {username}
                    updatedBy {username}
                    createdAt
                    updatedAt
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getPushNotificationServerSetting = async ({filter}, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_PUSH_NOTIFICATION_SERVER_SETTING,
            {
                filter: filter,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_PUSH_NOTIFICATION_SERVER_SETTING = gql`
    mutation addPushNotificationServerSetting($input: PushNotificationServerSettingInput) {
        addPushNotificationServerSetting(input: $input) {
            ... on PushNotificationServerSetting {
                _id
                name
                targetPlatform
                provider
                firebase {
                    config {
                        apiKey
                        authDomain
                        projectId
                        storageBucket
                        messagingSenderId
                        appId
                        measurementId
                    }
                    vapid
                    credential {
                        type
                        project_id
                        private_key_id
                        private_key
                        client_email
                        client_id
                        auth_uri
                        token_uri
                        auth_provider_x509_cert_url
                        client_x509_cert_url
                        universe_domain
                    }
                }
                isDeleted
                createdBy {username}
                updatedBy {username}
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const addPushNotificationServerSetting = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            ADD_PUSH_NOTIFICATION_SERVER_SETTING,
            {
                input: input,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UPDATE_PUSH_NOTIFICATION_SERVER_SETTING = gql`
    mutation updatePushNotificationServerSetting($id: String!, $input: PushNotificationServerSettingInput) {
        updatePushNotificationServerSetting(id: $id, input: $input) {
            ... on PushNotificationServerSetting {
                _id
                name
                targetPlatform
                provider
                firebase {
                    config {
                        apiKey
                        authDomain
                        projectId
                        storageBucket
                        messagingSenderId
                        appId
                        measurementId
                    }
                    vapid
                    credential {
                        type
                        project_id
                        private_key_id
                        private_key
                        client_email
                        client_id
                        auth_uri
                        token_uri
                        auth_provider_x509_cert_url
                        client_x509_cert_url
                        universe_domain
                    }
                }
                isDeleted
                createdBy {username}
                updatedBy {username}
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const updatePushNotificationServerSetting = async (id, input) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_PUSH_NOTIFICATION_SERVER_SETTING,
            {
                id: id,
                input: input,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const DELETE_PUSH_NOTIFICATION_SERVER_SETTING = gql`
    mutation deletePushNotificationServerSetting($id: String!) {
        deletePushNotificationServerSetting(id: $id) {
            ... on PushNotificationServerSetting {
                _id
                name
                targetPlatform
                provider
                firebase {
                    config {
                        apiKey
                        authDomain
                        projectId
                        storageBucket
                        messagingSenderId
                        appId
                        measurementId
                    }
                    vapid
                    credential {
                        type
                        project_id
                        private_key_id
                        private_key
                        client_email
                        client_id
                        auth_uri
                        token_uri
                        auth_provider_x509_cert_url
                        client_x509_cert_url
                        universe_domain
                    }
                }
                isDeleted
                createdBy {username}
                updatedBy {username}
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const deletePushNotificationServerSetting = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            DELETE_PUSH_NOTIFICATION_SERVER_SETTING,
            {
                id: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

export {
    getPushNotificationServerSetting,
    addPushNotificationServerSetting,
    updatePushNotificationServerSetting,
    deletePushNotificationServerSetting,
};
