import React from "react";
import type {ColumnsType} from "antd/es/table";

interface DataType {
    name: string;
    value: string;
    type: string;
}

export const tableColumn: ColumnsType<DataType> = [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: (a: any, b: any) => a?.name?.localeCompare(b?.name),
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Value",
        dataIndex: "value",
        key: "value",
        sorter: (a: any, b: any) => a?.value?.localeCompare(b?.value),
        render: (text) => <p>{text || "-"}</p>,
    }
];
