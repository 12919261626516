import {Button, Card, Checkbox, Col, Modal, Row, Space} from "antd";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../../recoil_state";

const SelectVoucherModal = ({data, isOpen, handleClose, item, userSelectVoucherFunction, isSelectVoucherLoading}) => {
    const {t} = useTranslation();
    const language = localStorage.getItem("language");
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const [selectedReward, setSelectedReward] = useState<any>([]);
    const [voucherGroupChildSelected, setVoucherGroupChildSelected] = useState({});
    const fixedReward = item?.voucherProgramId?.rewards?.filter((d) => d?.isFixedReward);
    const totalReward = fixedReward?.length + parseInt(item?.voucherProgramId?.rewardValue);

    useEffect(() => {
        setSelectedReward(fixedReward?.map((d) => d?.reward?._id));
        setVoucherGroupChildSelected({});
    }, [item, isOpen === true]);

    const handleSelectReward = (value) => {
        if (value?.length <= totalReward) {
            const [removed]: any = selectedReward
                ?.map((d) => {
                    if (!value?.includes(d)) return d;
                })
                .filter((d) => d !== undefined);

            setSelectedReward(value);
            setVoucherGroupChildSelected((prevState) => ({...prevState, [removed]: []}));
        } else {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: t("mission.errMsg.youCanOnlySelect", {totalReward: totalReward}),
                key: "selectRewardMessage",
            });
        }
    };

    const handleSelectChildVoucher = (value, total, item) => {
        if (value?.length <= total) {
            setVoucherGroupChildSelected((prevState) => ({...prevState, [item]: value}));
        } else {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: t("mission.errMsg.youCanOnlySelect", {totalReward: total}),
                key: "selectVoucherMessage",
            });
        }
    };

    const handleComplete = async () => {
        const rewards = await selectedReward?.map((d) => {
            const match = Object.keys(voucherGroupChildSelected)?.find((c) => c === d);

            return {
                rewardId: d,
                voucherId: match ? voucherGroupChildSelected[match] : [d],
            };
        });

        await userSelectVoucherFunction(item?._id, {
            userId: data?._id,
            rewards: rewards,
        });

        handleClose();
    };

    return (
        <Modal
            open={isOpen}
            onCancel={handleClose}
            footer={
                <Space>
                    <Button onClick={handleClose} className={"secondary_btn"}>
                        {t("common.cancel")}
                    </Button>
                    <Button
                        disabled={selectedReward?.length < totalReward}
                        className={"primary_btn"}
                        loading={isSelectVoucherLoading}
                        onClick={() => handleComplete()}
                    >
                        {t("common.confirm")}
                    </Button>
                </Space>
            }
            title={
                <>
                    {t("mission.selectReward")} ({selectedReward?.length}/{totalReward})
                </>
            }
        >
            <Checkbox.Group value={selectedReward} onChange={(value) => handleSelectReward(value)}>
                <Row>
                    {item?.voucherProgramId?.rewards?.map((d) => {
                        if (d?.rewardType !== "voucherGroups") {
                            return (
                                <Col key={d?.reward?._id} span={24} className={"my-1"}>
                                    <Checkbox className={"ms-2"} disabled={d?.isFixedReward} value={`${d?.reward?._id}`}>
                                        {d?.reward?.displayName?.find((d) => d?.language === language)?.content}&nbsp;
                                        {d?.rewardType !== "voucherGroups" && t(`reward.voucherType.${d?.reward?.rewardType}`)}
                                    </Checkbox>
                                </Col>
                            );
                        } else {
                            const selectable = d?.reward?.isRandomReward || !selectedReward?.includes(d?.reward?._id);
                            const totalRewardAmount = parseInt(d?.reward?.rewardValue);
                            const remaining = totalRewardAmount - (voucherGroupChildSelected?.[d?.reward?._id]?.length || 0);

                            return (
                                <Col key={d?.reward?._id} span={24} className={"my-1"}>
                                    <Card size={"small"}>
                                        <Checkbox disabled={d?.isFixedReward} value={d?.reward?._id}>
                                            {d?.reward?.displayName?.find((d) => d?.language === language)?.content}
                                        </Checkbox>
                                        <p className={"mb-2 secondary-text ms-4"}>
                                            {d?.reward?.isRandomReward
                                                ? t("mission.msg.randomVouchers", {totalRewardAmount: totalRewardAmount})
                                                : totalRewardAmount < d?.reward?.pool?.length
                                                ? t("mission.msg.selectableVouchers", {totalRewardAmount: totalRewardAmount, remaining: remaining})
                                                : null}
                                        </p>
                                        <Checkbox.Group
                                            value={voucherGroupChildSelected?.[d?.reward?._id]}
                                            onChange={(value) => handleSelectChildVoucher(value, totalRewardAmount, d?.reward?._id)}
                                            disabled={selectable}
                                        >
                                            <Row className={"ms-4"}>
                                                {d?.reward?.pool?.map((pool) => (
                                                    <Col span={24} key={pool?.voucher?._id}>
                                                        <Checkbox value={pool?.voucher?._id}>
                                                            {pool?.voucher?.displayName?.find((d) => d?.language === language)?.content}
                                                        </Checkbox>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Checkbox.Group>
                                    </Card>
                                </Col>
                            );
                        }
                    })}
                </Row>
            </Checkbox.Group>
        </Modal>
    );
};

export default SelectVoucherModal;
