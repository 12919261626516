import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SearchableTree from "../../../../../../components/tree/searchableTree";
import { calculateTotalDownline } from "../../../../../../function/calculateTotalDownline";

const Structure = (props) => {
  const { data, currentTab, refetchDeskNetwork } = props;
  const { t } = useTranslation();
  const [amendedData, setAmendedData] = useState<any>([]);

  const transformData = (data) => {
    try {
      const result: any = [];
      const lookup: any = {};

      data?.forEach((item) => {
        lookup[item?.deskid?._id] = {
          ...item,
          children: [],
          totalDirectDownline: 0,
          totalDownline: 0,
        };
      });

      data?.forEach((item) => {
        if (lookup && item?.parent?._id && lookup[item?.parent?._id]) {
          lookup[item.parent._id].children.push(lookup[item?.deskid?._id]);
          lookup[item.parent._id].totalDirectDownline += 1;
        } else {
          result?.push(lookup[item?.deskid?._id]);
        }
      });

      result?.forEach(amendFields);

      return result;
    } catch (error) {}
  };

  const amendFields = (obj) => {
    try {
      obj.key = obj?.deskid?._id;
      obj.totalDownline = calculateTotalDownline(obj);
      let name = obj?.deskid?.name || "-";
      //   let totalDirectDownline = obj?.totalDirectDownline;
      //   let totalDownline = obj?.totalDownline;
      obj.title = `${name}`;
      delete obj?._id;
      delete obj?.deskid;
      delete obj?.parent;
      delete obj?.__typename;
      if (obj?.children) {
        obj?.children?.forEach(amendFields);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (data?.length > 0) {
      const result = transformData(data);
      setAmendedData(result);
    }
  }, [data]);

  useEffect(() => {
    if (currentTab === "structure") {
      refetchDeskNetwork();
    }
  }, [currentTab]);

  return (
    <div id="desk-network-tree" className="margin-top-0-75">
      <SearchableTree
        defaultData={amendedData}
        note={t("Desk Name")}
        refetchNetwork={refetchDeskNetwork}
      />
    </div>
  );
};

export default Structure;
