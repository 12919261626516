import {useState} from "react";
import {getUserSubset} from "../../../../api/graphql/user";
import {getCron} from "../../../../api/graphql/cron";

export const useGetCron = () => {
    const [userData, setUserData] = useState<any[]>([])
    const [userTotal, setUserTotal] = useState<number>(0)
    const [userLoading, setUserLoading] = useState<boolean>(false)
    const [param, setParam] = useState<any>(null)

    const fetchCronData = async (filter, limit, offset, orderBy) => {
        try {
            setUserLoading(true)
            setParam({ filter, limit, offset, orderBy })
            const response = await getCron(filter, limit, offset, orderBy)

            setUserData(response?.getCron?.data)
            setUserTotal(response?.getCron?.total)
        } catch (e) {
            console.error(e)
        } finally {
            setUserLoading(false)
        }
    }

    const refetchCronData = async () => {
        await fetchCronData(param.filter, param.limit, param.offset, param.orderBy)
    }

    return [userData, userTotal, userLoading, fetchCronData, refetchCronData] as const
}