import React, {useEffect, useState} from "react";
import {Button, Card, Form, Space, Tabs, TabsProps} from "antd";
import {useUpdateUserMutation} from "../../../../../api/graphql/user";
import {useTranslation} from "react-i18next";
import BasicInformation from "../../../../users/clients/detail/view/layout1/component/InformationForm/basicInformation";
import ResidenceInformation from "../../../../users/clients/detail/view/layout1/component/InformationForm/residenceInformation";
import {EditOutlined} from "@ant-design/icons";
import {getProfileInformationSetting} from "../../../../../api/graphql/profileInformation";
import {formatProfileInformation} from "../../../../users/_common/function";
import {useRecoilValue} from "recoil";
import {selfPermissionObj} from "../../../../../../recoil_state";
import useAuthorize from "../../../../../_common/authorize";
import AccountVerification from "../../../../users/clients/detail/view/layout1/component/InformationForm/accountVerification/accountVerification";

const TaskClientDetails = (props) => {
    const {data, removeKyc} = props;
    const {t} = useTranslation();
    const [basicForm] = Form.useForm();
    const [residenceForm] = Form.useForm();

    const [editable, setEditable] = useState<boolean>(false);
    const [currentTab, setCurrentTab] = useState<any>("basic");

    const {updateUser} = useUpdateUserMutation();
    const {systemErrorNotification} = useAuthorize();
    const permission = useRecoilValue<any>(selfPermissionObj);

    const {data: profileInformation} = getProfileInformationSetting({type: "client",});

    const [fields, setFields] = useState<any>({
        basicField: [],
        loginField: [],
        residenceField: [],
        additionalField: [],
    });
    const {basicField, loginField, residenceField, additionalField} = fields;

    useEffect(() => {
        formatProfileInformation(fields, setFields, profileInformation?.getProfileInformationSetting?.data);
    }, [profileInformation]);

    const userInfoOnSubmit = (value) => {
        try {
            const nonUpdatableFields = ["tag", "leadSource", "age", "affiliateCode"];
            Object.keys(value).forEach((key) => {
                const match = nonUpdatableFields?.find((d) => d === key);
                if (value[key] === undefined || match) {
                    delete value[key];
                }
            });

            let validate;
            if (currentTab === "corporate") {
                value["corporateMobileNo"] = value?.mobileNumber?.toString();
                value["corporateCountryCode"] = value?.countryCode;
                delete value["combinedMobileNo"];
                delete value["countryCode"];
            } else if (currentTab === "basic") {
                value["mobileNo"] = value?.mobileNumber?.toString();
                validate = ["basicInformation", "loginInformation", "additionalInformation"];
                delete value["combinedMobileNo"];
            } else if (currentTab === "residence") {
                validate = ["residentialInformation"];
            }

            updateUser(data?._id, {...value, validationType: validate});
            setEditable(false);
        } catch (e) {
            systemErrorNotification();
        }
    };

    const items: TabsProps["items"] = [
        {
            label: t("field.category.basicInformation"),
            key: "basic",
            children: (
                <Form onFinish={userInfoOnSubmit} form={basicForm}>
                    <BasicInformation
                        onSubmit={userInfoOnSubmit}
                        data={data}
                        editable={editable}
                        basicField={basicField}
                        loginField={loginField}
                        isTask={true}
                        isReadSensitive={permission?.user?.clientsUser?.view?.sensitiveInformation}

                    />
                </Form>
            ),
        },
        {
            label: t("task.account_verification"),
            key: "kyc",
            children: <AccountVerification user={data}/>,
        },
        {
            label: t("task.residence_information"),
            key: "address",
            children: (
                <Form onFinish={userInfoOnSubmit} form={residenceForm}>
                    <ResidenceInformation
                        data={data}
                        editable={editable}
                        residenceField={residenceField}
                        isReadSensitive={permission?.user?.clientsUser?.view?.sensitiveInformation}
                    />
                </Form>
            ),
        },
    ];

    const itemsWithoutKyc: TabsProps["items"] = [
        {
            label: t("field.category.basicInformation"),
            key: "basic",
            children: (
                <Form onFinish={userInfoOnSubmit} form={basicForm}>
                    <BasicInformation
                        onSubmit={userInfoOnSubmit}
                        data={data}
                        editable={editable}
                        basicField={basicField}
                        loginField={loginField}
                        isTask={true}
                        isReadSensitive={permission?.user?.clientsUser?.view?.sensitiveInformation}
                        permissionUser={permission?.user}
                    />
                </Form>
            ),
        },
        {
            label: t("task.residence_information"),
            key: "residence",
            children: (
                <Form onFinish={userInfoOnSubmit} form={residenceForm}>
                    <ResidenceInformation
                        data={data}
                        editable={editable}
                        residenceField={residenceField}
                        isReadSensitive={permission?.user?.clientsUser?.view?.sensitiveInformation}
                        permissionUser={permission?.user}
                    />
                </Form>
            ),
        },
    ];

    useEffect(() => {
        setEditable(false);
    }, [currentTab]);

    return (
        <Card style={{width: "100%"}} className={"taskClientDetail"}>
            <Tabs
                size={"small"}
                items={removeKyc ? itemsWithoutKyc : items}
                tabBarExtraContent={
                    <>
                        {editable ? (
                            <Space>
                                <Button onClick={() => setEditable(false)}>{t("Cancel")}</Button>
                                {currentTab === "basic" || currentTab === "corporate" || currentTab === "representative" ? (
                                    <Button onClick={() => basicForm.submit()}>{t("Submit")}</Button>
                                ) : currentTab === "residence" ? (
                                    <Button onClick={() => residenceForm.submit()}>{t("Submit")}</Button>
                                ) : (
                                    <></>
                                )}
                            </Space>
                        ) : permission?.user?.clientsUser?.edit?.edit &&
                        (currentTab === "basic" || currentTab === "corporate" || currentTab === "representative" || currentTab === "residence") ? (
                            // || currentTab === "kyc"
                            !removeKyc && <Button type={"link"} onClick={() => setEditable(true)} icon={<EditOutlined/>}/>
                        ) : (
                            <></>
                        )}
                    </>
                }
                onTabClick={(key) => setCurrentTab(key)}
            />
        </Card>
    );
};

export default TaskClientDetails;
