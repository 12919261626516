import React, {useState} from "react"
import {useRecoilState, useRecoilValue} from "recoil"
import {useTranslation} from "react-i18next"
import {userVerificationUploadFileListState} from "../../../../../../../state"
import {Button, Card, Col, DatePicker, Form, Input, message, Radio, Rate, Row, Select, Upload} from "antd"
import {CountryCodeList_Language} from "../../../../../../../../../../../config/countryCodeList_Language"
import FilePreview from "./filePreview"
import {getConfigByName} from "../../../../../../../../../../../config"
import {BRAND_NAME} from "../../../../../../../../../../../config/brandVariable"
import {useAccountVerificationFunction} from "../../../../../../function/accountVerificationFunction"
import {CloseOutlined, InboxOutlined} from "@ant-design/icons"
import {fieldListState} from "../../../../../../../../../../../recoil_state"

const Field = (props) => {
    const {form, _id, field, hasExpiredDate, isRequired, onUploadDraggerChange, isDraft, isGroupChildren, documentRecord, selectedCountry, onDeleteCurrentGroupItem, groupItemId, memberId, membersCount, groupItemMemberId, linkField} = props
    const [kycUploadFileList, setKycUploadFileList] = useRecoilState<any>(userVerificationUploadFileListState)
    const [isAttachmentDeleted, setIsAttachmentDeleted] = useState(false)

    // const brandConfig = useRecoilValue(globalBrandConfigState);
    const fieldList = useRecoilValue<any>(fieldListState)
    const brandConfig = getConfigByName(BRAND_NAME)
    const {t} = useTranslation()
    const {getLastPartOfUrl} = useAccountVerificationFunction()

    let referenceId
    let fieldSettingId = _id

    if (isGroupChildren) {
        referenceId = field._id
    }

    const customFieldName = groupItemMemberId !== undefined ? field?.label + `___${groupItemId}` : field?.label

    const selectCountryCodeLabel = groupItemMemberId !== undefined ? field?.label + `___${groupItemId}` + ".mobile" : field?.label + ".mobile"

    const answer = documentRecord?.fieldValue?.find((fv) => (!fv.memberId || fv.memberId === groupItemId) && fv?.referenceId?._id === referenceId && fv?.fieldSetting?._id === fieldSettingId)

    // const isVerificationFailed = answer?.status === "verification failed";

    let isDraftBool = isDraft && !(answer?.status === "verification expired")
    const cached = form.getFieldValue(customFieldName)

    const draftValue = answer?.value ?? (typeof cached !== "string" ? undefined : cached) ?? undefined

    const fileList = isAttachmentDeleted ? undefined : kycUploadFileList?.[customFieldName] || (draftValue ? [{response: [{link: draftValue}]}] : undefined)

    const customUploadDraggerHandler = (inputLabel) => (info) => {
        const fileList = onUploadDraggerChange(info)
        if (fileList) {
            form.setFieldValue(inputLabel, fileList)
            setKycUploadFileList((d: any) => ({...d, [inputLabel]: fileList}))
            setIsAttachmentDeleted(false)
        }
    }

    const customUploadDraggerRemoveHandler = (inputLabel) => () => {
        setKycUploadFileList((d: any) => {
            const {[inputLabel]: _, ...n} = d
            return n
        })
        setIsAttachmentDeleted(true)
    }

    const selectCountryCode = (
        <Form.Item name={selectCountryCodeLabel}>
            <Select
                style={{width: 100}}
                showSearch
                disabled={isDraftBool}
                options={CountryCodeList_Language.map(({key, label, value}, index) => ({
                    value: value,
                    label: t(label),
                    key: index,
                }))}
                optionLabelProp={"value"}
                dropdownMatchSelectWidth={false}
            />
        </Form.Item>
    )

    // For document upload
    function getUploadEndpoint(category) {
        switch (category) {
            case "image": {
                return `api/upload/kyc/image`
            }
            case "document": {
                return `api/upload/kyc/document`
            }
            case "video": {
                return `/upload/kyc/video`
            }
        }
        return ""
    }

    let component: any = null
    const linkFieldSetting = fieldList?.find((d) => d?.fieldName === linkField)

    const rule = [
        {
            required: isRequired,
            message: t("errorMsg.required.pleaseFillIn", {
                field: t(field?.label),
            }).toString(),
        },
        ...(linkFieldSetting?.minCharacterLimit
            ? [
                  {
                      min: linkFieldSetting?.minCharacterLimit,
                      message: t("errMsg.minimum_characters_required", {length: linkFieldSetting?.minCharacterLimit}),
                  },
              ]
            : []),
    ]

    switch (field?.fieldType) {
        case "text":
            component = (
                <Form.Item key={field?.label} name={customFieldName} label={t(field?.label)} rules={rule}>
                    <Input disabled={isDraftBool} autoComplete="off" showCount={linkFieldSetting?.maxCharacterLimit} maxLength={linkFieldSetting?.maxCharacterLimit || null} />
                </Form.Item>
            )
            break
        case "textArea":
            component = (
                <Form.Item key={field?.label} name={customFieldName} label={t(field?.label)}>
                    <Input.TextArea rows={3} disabled={isDraftBool} showCount={linkFieldSetting?.maxCharacterLimit} maxLength={linkFieldSetting?.maxCharacterLimit || null} />
                </Form.Item>
            )
            break
        case "date":
            component = (
                <Form.Item key={field?.label} name={customFieldName} label={t(field?.label)} rules={rule}>
                    <DatePicker disabled={isDraftBool} placeholder={""} style={{width: "100%"}} />
                </Form.Item>
            )
            break
        case "mobile":
            component = (
                <Form.Item className={"mobile-input"} key={field?.label} name={customFieldName} label={t(field?.label)} rules={rule}>
                    <Input
                        type="tel"
                        inputMode="numeric"
                        disabled={isDraftBool}
                        addonBefore={selectCountryCode}
                        onKeyDown={(e) => {
                            if (e.key.length === 1 && !/[0-9]/.test(e.key)) {
                                e.preventDefault()
                            }
                        }}
                    />
                </Form.Item>
            )
            break
        case "select":
            component = (
                <Form.Item key={field?.label} name={customFieldName} label={t(field?.label)} rules={rule}>
                    <Select
                        showSearch
                        disabled={isDraftBool}
                        filterOption={(input, option) => (option?.label ?? "")?.toString()?.toLowerCase().includes(input.toLowerCase())}
                        options={(field?.fieldValue?.map((v) => ({...v, label: t(v.label)})) ?? []).sort((a, b) => a.label?.localeCompare?.(b.label))}
                    />
                </Form.Item>
            )
            break
        case "multiSelect":
            component = (
                <Form.Item key={field?.label} name={customFieldName} label={t(field?.label)} rules={rule}>
                    <Select
                        mode="multiple"
                        showSearch
                        allowClear
                        disabled={isDraftBool}
                        filterOption={(input, option) => (option?.label ?? "")?.toString()?.toLowerCase().includes(input.toLowerCase())}
                        options={(field?.fieldValue?.map((v) => ({...v, label: t(v.label)})) ?? []).sort((a, b) => a.label?.localeCompare?.(b.label))}
                    />
                </Form.Item>
            )
            break
        case "radio":
            component = (
                <Form.Item key={field?.label} name={customFieldName} label={t(field?.label)} rules={rule}>
                    <Radio.Group>
                        {field?.fieldValue.map((v) => (
                            <Radio key={v} value={v}>
                                {v}
                            </Radio>
                        ))}
                    </Radio.Group>
                </Form.Item>
            )
            break
        case "rate":
            component = (
                <Form.Item key={field?.label} name={customFieldName} label={t(field?.label)} rules={rule}>
                    <Rate disabled={isDraftBool} />
                </Form.Item>
            )
            break
        case "image": {
            component = (
                <Form.Item key={field?.label} name={customFieldName} label={t(field?.label)} rules={rule}>
                    {isDraftBool && draftValue ? (
                        <FilePreview link={draftValue} isClickable={true} />
                    ) : (
                        <Upload.Dragger
                            name={customFieldName}
                            // accept={"image/*"}
                            maxCount={1}
                            action={() => `${brandConfig?.backendURL}/${getUploadEndpoint("image")}`}
                            onChange={customUploadDraggerHandler(customFieldName)}
                            disabled={isDraftBool}
                            fileList={fileList}
                            onRemove={customUploadDraggerRemoveHandler(customFieldName)}
                            itemRender={(_, field, __, {remove: handleDelete}) => (
                                <FilePreview
                                    handleDelete={() => {
                                        handleDelete()
                                        customUploadDraggerRemoveHandler(customFieldName)?.()
                                    }}
                                    isDraftBool={isDraftBool}
                                    link={field.response?.[0]?.link || draftValue}
                                />
                            )}
                            beforeUpload={(file) => {
                                try {
                                    const isLt10M = file.size / 1024 / 1024 < 10
                                    if (!isLt10M) {
                                        message.error("Image size must be less than 10MB!")
                                    }
                                    const isFileTypeValid = getUploadEndpoint(getLastPartOfUrl(file?.name)?.category)
                                    if (!isFileTypeValid) {
                                        message.error("Invalid file type")
                                    }
                                    return (isFileTypeValid && isLt10M) || Upload.LIST_IGNORE
                                } catch (error) {
                                    console.error(error)
                                }
                            }}
                        >
                            {kycUploadFileList?.[customFieldName]?.[0] || draftValue ? (
                                <FilePreview link={kycUploadFileList?.[customFieldName]?.[0]?.response?.[0]?.link || draftValue} />
                            ) : (
                                <>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">{t("accountVerification.placeholder.upload")}</p>
                                </>
                            )}
                        </Upload.Dragger>
                    )}
                </Form.Item>
            )
            break
        }
        case "video": {
            component = (
                <Form.Item key={customFieldName} name={customFieldName} label={t(field?.label)} rules={rule}>
                    {isDraftBool && draftValue ? (
                        <FilePreview link={draftValue} isClickable={true} />
                    ) : (
                        <Upload.Dragger
                            name={customFieldName}
                            accept={".mp4, .wmv, .avi, .webm"}
                            maxCount={1}
                            action={() => `${brandConfig?.backendURL}/${getUploadEndpoint("video")}`}
                            onChange={customUploadDraggerHandler(customFieldName)}
                            disabled={isDraftBool}
                            fileList={fileList}
                            onRemove={customUploadDraggerRemoveHandler(customFieldName)}
                            itemRender={(_, field, __, {remove: handleDelete}) => (
                                <FilePreview
                                    handleDelete={() => {
                                        handleDelete()
                                        customUploadDraggerRemoveHandler(customFieldName)?.()
                                    }}
                                    isDraftBool={isDraftBool}
                                    link={field.response?.[0]?.link || draftValue}
                                />
                            )}
                            beforeUpload={(file) => {
                                const isLt10M = file.size / 1024 / 1024 < 10
                                if (!isLt10M) {
                                    message.error("Video size must be less than 10MB!")
                                }
                                const isFileTypeValid = getUploadEndpoint(getLastPartOfUrl(file?.name)?.category)
                                if (!isFileTypeValid) {
                                    message.error("Invalid file type")
                                }
                                return (isFileTypeValid && isLt10M) || Upload.LIST_IGNORE
                            }}
                        >
                            {kycUploadFileList?.[customFieldName]?.[0] || draftValue ? (
                                <FilePreview link={kycUploadFileList?.[customFieldName]?.[0]?.response?.[0]?.link || draftValue} />
                            ) : (
                                <>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">{t("accountVerification.placeholder.upload")}</p>
                                </>
                            )}
                        </Upload.Dragger>
                    )}
                </Form.Item>
            )
            break
        }
        case "file":
        case "document": {
            component = (
                <Form.Item key={field?.label} name={customFieldName} label={t(field?.label)} rules={rule}>
                    {isDraftBool && draftValue ? (
                        <FilePreview link={draftValue} isClickable={true} />
                    ) : (
                        <Upload.Dragger
                            name={customFieldName}
                            accept={".jpg, .jpeg, .png, .webp, .gif, .pdf, .xlsx, .xls, .mp4, .wmv, .avi, .webm"}
                            maxCount={1}
                            action={(file) => `${brandConfig?.backendURL}/${getUploadEndpoint(getLastPartOfUrl(file?.name)?.category)}`}
                            onChange={customUploadDraggerHandler(customFieldName)}
                            disabled={isDraftBool}
                            fileList={fileList}
                            onRemove={customUploadDraggerRemoveHandler(customFieldName)}
                            itemRender={(_, field, __, {remove: handleDelete}) => (
                                <FilePreview
                                    handleDelete={() => {
                                        handleDelete()
                                        customUploadDraggerRemoveHandler(customFieldName)?.()
                                    }}
                                    isDraftBool={isDraftBool}
                                    link={field.response?.[0]?.link || draftValue}
                                />
                            )}
                            beforeUpload={(file) => {
                                const isLt10M = file.size / 1024 / 1024 < 10
                                if (!isLt10M) {
                                    message.error("Document size must be less than 10MB!")
                                }
                                const isFileTypeValid = getUploadEndpoint(getLastPartOfUrl(file?.name)?.category)
                                if (!isFileTypeValid) {
                                    message.error("Invalid file type")
                                }
                                return (isFileTypeValid && isLt10M) || Upload.LIST_IGNORE
                            }}
                        >
                            {kycUploadFileList?.[customFieldName]?.[0] || draftValue ? (
                                <FilePreview link={kycUploadFileList?.[customFieldName]?.[0]?.response?.[0]?.link || draftValue} />
                            ) : (
                                <>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">{t("accountVerification.placeholder.upload")}</p>
                                </>
                            )}
                        </Upload.Dragger>
                    )}
                </Form.Item>
            )
            break
        }
        case "group":
            const groupLabel = <div>{t(field?.label) + (memberId !== undefined ? ` - ${memberId + 1}` : "")}</div>
            const groupDelete = (
                <Button size="small" type="link" onClick={() => onDeleteCurrentGroupItem()}>
                    <CloseOutlined />
                </Button>
            )

            const groupLabelSection = (
                <Row justify={"space-between"} style={{width: "100%", marginTop: 8}}>
                    <Col>{groupLabel}</Col>
                    <Col>{membersCount > 1 && !isDraftBool ? groupDelete : null}</Col>
                </Row>
            )
            component = (
                <>
                    <Form.Item
                        className="grouped"
                        // name={field?.label}
                        label={groupLabelSection}
                    >
                        <Card className="verification-group-input-card">
                            <Row gutter={[24, 24]}>
                                {field?.fieldGroup.map((f) => {
                                    // const np = { ...f };
                                    // if (membersCount) {
                                    //   np.groupItemMemberId = memberId;
                                    // }

                                    return (
                                        <Col key={f._id} xs={24} md={{span: Math.max(12, 24 / field?.fieldGroup.length)}} className="nested-field">
                                            <Field
                                                form={form}
                                                field={f}
                                                groupItemId={groupItemId}
                                                groupItemMemberId={memberId}
                                                _id={_id}
                                                isRequired={isRequired}
                                                isDraftBool={isDraftBool}
                                                isGroupChildren={true}
                                                documentRecord={documentRecord}
                                                onUploadDraggerChange={onUploadDraggerChange}
                                            />
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Card>
                    </Form.Item>
                </>
            )
            break

        default:
            break
    }

    if (hasExpiredDate) {
        return (
            <Row gutter={16}>
                <Col span={12}>{component}</Col>
                <Col span={12}>
                    <Form.Item name={`${field?.label}.expiredDate`} label={"Expired Date"} rules={rule}>
                        <DatePicker disabled={isDraftBool} placeholder={""} style={{width: "100%"}} />
                    </Form.Item>
                </Col>
            </Row>
        )
    }

    return <>{component}</>
}

export default Field
