import React, {useEffect, useState} from "react";
import {Button, message, Modal, Row, Select, Space, Table, Upload} from "antd";
import {useTranslation} from "react-i18next";
import * as XLSX from "xlsx";
import {UploadOutlined} from "@ant-design/icons";
import ExportTableButton from "../../../../../components/table/function/exportTable";
import FileSaver from 'file-saver'
import {useBatchImportEWalletAdjustmentMutation} from "../../../../../api/graphql/eWalletAdjustment";
import useAuthorize from "../../../../../_common/authorize";
import {convertCsvToArray} from "../../../../settings/language/function/common";

const ImportEWalletAdjustmentForm = (props) => {
    const {close, isOpen, tableName, fieldList} = props;
    const {t} = useTranslation();
    const [errMsg, setErrMsg] = useState("");
    const {batchImportEWalletAdjustment, loading} = useBatchImportEWalletAdjustmentMutation();

    const [uniqueField, setUniqueField] = useState<any>([])
    const [file, setFile] = useState<any>([])
    const [validData, setValidData] = useState<any>()
    const [invalidData, setInvalidData] = useState<any>()
    const uniqueFieldList = ["email", "username", "completedMobileNo", "user"];
    const [registrationSettingOption, setRegistrationSettingOption] = useState<any>()
    const [actionLoading, setActionLoading] = useState(false)
    const {systemErrorNotification} = useAuthorize()
    const fields = ["username", "email", "completedMobileNo", "user", "type", "amount", "description", "remark", "rejectReason", "transactionStatus",];

    useEffect(() => {
        loading ? setActionLoading(true) : setActionLoading(false)
    }, [loading])

    const oriTableColumn = [
        {
            title: t("common.transaction"),
            dataIndex: "transaction",
        },
        {
            title: t("common.amount"),
            dataIndex: "amount",
        },
        {
            title: t("common.description"),
            dataIndex: "description",
        },
    ]

    const [tableColumn, setTableColumn] = useState<any[]>(oriTableColumn)

    const uniqueTableColumn = [
        {
            title: "User Id",
            dataIndex: "user"
        },
        {
            title: "Username",
            dataIndex: "username",
        },
        {
            title: "Email",
            dataIndex: "email",
        },
        {
            title: "Mobile",
            dataIndex: "completedMobileNo",
        },
    ]

    // const convertCsvToArray = (data) => {
    //     const test = data?.replace(/\r\n|\n\r|\r/g, "{row}").split("{row}");
    //     const rowKey = test.shift()?.split(",");
    //     const result: any = [];
    //     test.forEach(item => {
    //         const tempItem = item.split(",");
    //         const rows: { [key: string]: any } = {};
    //         tempItem.forEach((value, index) => {
    //             if (value && value !== "") {
    //                 rows[rowKey[index]] = value;
    //             }
    //         });
    //         result.push(rows);
    //     });
    //     return result;
    // }

    const onSubmit = async () => {
        try {
            const formattedFile = await file?.map(d => {
                const user = d?.userId;
                const type = d?.transactionType;

                delete d["userId"];
                delete d["transactionType"];
                delete d["ids"]

                const formattedData = {};
                for (const key in d) {
                    if (key === "amount") {
                        formattedData[key] = parseFloat(d[key]);
                    } else {
                        formattedData[key] = String(d[key]);
                    }
                }

                return {
                    ...formattedData,
                    user,
                    type,
                };
            });

            const invalidKeys: any = []
            await formattedFile.forEach((item) => {
                const keys = Object.keys(item);
                invalidKeys.push(...keys.filter((key) => !fields.includes(key)));
            });
            if (invalidKeys.length > 0) {
                console.log(invalidKeys)
                message.error(t("common.invalidImportFormat"))
            } else {
                const response = await batchImportEWalletAdjustment(
                    "web",
                    "demo",
                    uniqueField,
                    formattedFile
                )
                const {data} = response;
                // if (data?.batchImportEWalletAdjustment?.valid || data?.batchImportEWalletAdjustment?.invalid) {
                //     setValidData(data?.batchImportEWalletAdjustment?.valid)
                //     setInvalidData(data?.batchImportEWalletAdjustment?.invalid)
                // } else if (data.batchImportEWalletAdjustment.errMsg) {
                //     setErrMsg(data.batchImportEWalletAdjustment.errMsg);
                // } else {
                //     setErrMsg("");
                // }
                close()
            }
        } catch (error: any) {
            setErrMsg(error.message);
            systemErrorNotification()
        }
    };

    const clearImportedData = () => {
        setValidData([])
        setInvalidData([])
        setTableColumn(oriTableColumn)
        setUniqueField([])
        setFile([])
    }

    const formatRegistrationOption = async () => {
        return await fieldList?.map((d) => {
            if (
                d?.isRequired &&
                d?.isUnique &&
                uniqueFieldList?.includes(d?.fieldName)
            ) {
                return {label: t(`user.${d?.fieldName}`), value: d?.fieldName};
            } else {
                return undefined;
            }
        })?.filter((item) => item !== undefined);
    }

    const uniqueFieldOnChange = (val) => {
        setUniqueField(val)
        const columns = val?.map(d => {
            return uniqueTableColumn?.find(item => item.dataIndex === d)
        })
        oriTableColumn?.unshift(...columns)
        setTableColumn(oriTableColumn)
    }

    // const uniqueFieldOnConfirm = () => {
    //     const columns = uniqueField?.map(d => {
    //         return uniqueTableColumn?.find(item => item.dataIndex === d)
    //     })
    //     oriTableColumn?.unshift(...columns)
    //     setTableColumn(oriTableColumn)
    // }


    function exportToExcelFile(uniqueFields, fileName) {
        try {
            if (uniqueFields?.length > 0) {
                const header = [...uniqueFields, "transactionType", "amount", "description"];
                const data: any = [];

                const ws = XLSX.utils.aoa_to_sheet([header, ...data]);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
                const buffer = XLSX.write(wb, {type: 'array', bookType: 'xlsx'});
                const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                FileSaver.saveAs(blob, `${fileName}.xlsx`);
            }
        } catch (e) {
            console.log(e)
            systemErrorNotification()
        }
    }

    useEffect(() => {
        if (fieldList) {
            formatRegistrationOption().then(option => setRegistrationSettingOption([...option, ({
                label: 'userId',
                value: 'userId'
            })]))
        }
    }, [fieldList])

    useEffect(() => {
        setUniqueField([])
    },[isOpen])

    return (
        <Modal
            title={t(`user.eWalletAdjImportForm.modalTitle`)}
            open={isOpen}
            width={600}
            footer={
                (validData?.length > 0 || invalidData?.length > 0) ? (
                    <>
                        <Button onClick={() => {
                            clearImportedData()
                            close()
                        }}>{t("common.ok")}</Button>
                        <Button onClick={() => {
                            clearImportedData()
                        }}>{t("errorMsg.importAgain")}</Button>
                    </>
                ) : (
                    <>
                        <Button onClick={() => close()}>{t("common.cancel")}</Button>
                        <Button type={"primary"} loading={actionLoading} onClick={() => onSubmit()}
                                disabled={file?.length <= 0}>{t("common.submit")}</Button>
                    </>
                )
            }
            onCancel={() => {
                clearImportedData()
                close()
            }}
            destroyOnClose
            getContainer="#client"
        >
            {(validData?.length > 0 || invalidData?.length > 0) ? (
                    <>
                        {
                            validData?.length > 0 && (
                                <>
                                    <h3>{t("common.validData")}:</h3>
                                    <Table
                                        columns={tableColumn}
                                        dataSource={validData}
                                        size={"small"}
                                    />
                                </>
                            )
                        }
                        {
                            invalidData?.length > 0 && (
                                <>
                                    <Row justify={"space-between"} className={"margin-bottom-0-75"}>
                                        <h3>{t("common.invalidData")}:</h3>
                                        <ExportTableButton
                                            tableData={invalidData}
                                            tableColumn={tableColumn}
                                            tableName={"Invalid EWallet Adjustment"}
                                        />
                                    </Row>
                                    <Table
                                        columns={tableColumn}
                                        dataSource={invalidData}
                                        size={"small"}
                                    />
                                </>
                            )
                        }
                    </>
                ) :
                <>
                    <h3>{t("user.eWalletAdjImportForm.setupImportFiles.title")}</h3>
                    <p>{t("user.eWalletAdjImportForm.setupImportFiles.content")}</p>
                    <br/>
                    <h3>{t("user.eWalletAdjImportForm.importRequirement.title")}</h3>
                    <ol>
                        <li>{t("user.eWalletAdjImportForm.importRequirement.content1")}</li>
                        <li>{t("user.eWalletAdjImportForm.importRequirement.content2")}</li>
                        <li>{t("user.eWalletAdjImportForm.importRequirement.content3")}</li>
                    </ol>
                    <br/>
                    <h3>{t("common.selectUniqueField")} <small>({t("common.selectOneOrMore")})</small></h3>
                    <Space.Compact className={"margin-bottom-0-75 w-100"}>
                        <Select
                            options={registrationSettingOption}
                            onChange={(val) => uniqueFieldOnChange(val)}
                            mode={"multiple"}
                            style={{width: "100%"}}
                            maxTagCount={"responsive"}
                            allowClear
                        />
                        {/*<Button onClick={() => uniqueFieldOnConfirm()}>{t("common.confirm")}</Button>*/}
                    </Space.Compact>
                    <br/>
                    {uniqueField?.length > 0 &&
                    <p>{t("common.download")}
                        <a onClick={() => exportToExcelFile(uniqueField, 'E-Wallet Template')}> {t("user.ewalletTemplate")}.xls</a>
                    </p>
                    }
                    <br/>
                    <h3>{t("common.importFiles")}</h3>
                    <p>* {t("common.CSV/XLSFiles")}</p>
                    <Upload
                        accept=".xlsx, .csv, .xls"
                        showUploadList={true}
                        disabled={uniqueField?.length === 0}
                        maxCount={1}
                        beforeUpload={file => {
                            const [fileExtension] = file?.name.split(".").reverse()
                            const reader = new FileReader();

                            if (fileExtension === "csv") {
                                reader.onload = e => {
                                    setFile(convertCsvToArray(e?.target?.result));
                                };
                                reader.readAsText(file);
                            } else if (fileExtension === "xlsx" || fileExtension === "xls") {
                                reader.onload = e => {
                                    const workbook = XLSX.read(e?.target?.result, {type: "binary"});
                                    for (const Sheet in workbook.Sheets) {
                                        var data: any = XLSX.utils.sheet_to_json(workbook.Sheets[Sheet]);
                                        var result = data?.map(item => {
                                            return {
                                                ...item,
                                            }
                                        })
                                        setFile(result)
                                    }
                                };
                                reader.readAsBinaryString(file);
                            }
                            return false;
                        }}
                        onRemove={() => setFile([])}
                    >
                        <Button disabled={uniqueField?.length === 0}
                                icon={<UploadOutlined/>}>{t("common.clickToUpload")}</Button>
                    </Upload>
                </>
            }
        </Modal>
    );
}

export default ImportEWalletAdjustmentForm;