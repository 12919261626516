import React, {useEffect, useState} from "react";
import {Col, Form, Row, Tabs} from "antd";
import {useTranslation} from "react-i18next";
import GeneralForm from "./component/generalForm";
import ImageForm from "./component/imageForm";
import CompanyInfoForm from "./component/companyInfoForm";
import SocialForm from "./component/socialInfoForm";
import {FormProvider, useForm} from "react-hook-form";
import {useUpdateBrandSettingMutation} from "../../../../../api/graphql/brandSetting";
import DomainForm from "./component/domainForm";
import useAuthorize from "../../../../../_common/authorize";
import OtherForm from "./component/otherForm";
import NavigationRouteSettingPage from "./component/navigationRouteSettingPage";
import {getIsMainMasterAdmin} from "../../../../../api/graphql/_common";
import ExportDBTableButton from "./component/exportDBTable";
import ImportDBTableButton from "./component/importDBTable";
import DocumentsForm from "./component/documentsForm";
import {useGetBrandSettingLegalDocuments} from "../../function/useGetQueryHooks";

const BrandDetails_1 = (props) => {
    const {data, language, brandDetailsPermission} = props;
    const {t} = useTranslation();
    const [currentTab, setCurrentTab] = useState<any>();
    const {systemErrorNotification} = useAuthorize();
    const [isMainMasterAdmin, setIsMainMasterAdmin] = useState(false);
    const [legalDocList, fetchLegalDocs] = useGetBrandSettingLegalDocuments()

    const [finalData, setFinalData] = useState<any>(data);
    var form: any = useForm({
        mode: "all",
        defaultValues: {},
    });

    const {updateBrandSetting, loading} = useUpdateBrandSettingMutation();

    const onSubmit = async (e, document?, isAddDocument?) => {
        try {
            var temp = isAddDocument ? {...finalData, legalDocument: document} : finalData;

            const omitTypename = (key, value) => (key === "__typename" ? undefined : value);
            const newPayload = JSON.parse(JSON.stringify(temp), omitTypename);

            const response = await updateBrandSetting(newPayload);
        } catch (error: any) {
            systemErrorNotification();
        }
    };

    const checkIsMasterAdmin = async () => {
        let response = await getIsMainMasterAdmin();
        setIsMainMasterAdmin(response?.isMainMasterAdmin);
    };

    useEffect(() => {
        checkIsMasterAdmin();
        fetchLegalDocs()
    }, []);

    const isEditable = brandDetailsPermission?.edit?.edit;

    const generalFormItems = language?.map((item, index) => {
        return {
            key: index,
            label: item.name,
            children: (
                <GeneralForm
                    onBrandDetailsSubmit={form.handleSubmit(onSubmit)}
                    submitLoading={loading}
                    languageCode={item.code}
                    data={finalData}
                    setData={setFinalData}
                    isEditable={isEditable}
                />
            ),
        };
    });

    const companyFormItems = language?.map((item, index) => {
        return {
            key: index,
            label: item.name,
            children: (
                <CompanyInfoForm
                    onBrandDetailsSubmit={form.handleSubmit(onSubmit)}
                    submitLoading={loading}
                    languageCode={item.code}
                    data={finalData}
                    setData={setFinalData}
                    isEditable={isEditable}
                />
            ),
        };
    });
    const imagesFormItems = language?.map((item, index) => {
        return {
            key: index,
            label: item.name,
            children: (
                <ImageForm
                    onBrandDetailsSubmit={form.handleSubmit(onSubmit)}
                    submitLoading={loading}
                    languageCode={item.code}
                    data={finalData}
                    setData={setFinalData}
                    isEditable={isEditable}
                />
            ),
        };
    });
    const socialFormItems = language?.map((item, index) => {
        return {
            key: index,
            label: item.name,
            children: (
                <SocialForm
                    onBrandDetailsSubmit={form.handleSubmit(onSubmit)}
                    submitLoading={loading}
                    languageCode={item.code}
                    data={finalData}
                    setData={setFinalData}
                    isEditable={isEditable}
                />
            ),
        };
    });

    const documentsFormItems = language?.map((item, index) => {
        return {
            key: index,
            label: item.name,
            children: (
                // <DocumentsFormOld
                //     addLegalDocument={onSubmit}
                //     onBrandDetailsSubmit={form.handleSubmit(onSubmit)}
                //     submitLoading={loading}
                //     languageCode={item.code}
                //     setData={setFinalData}
                //     isEditable={isEditable}
                //     data={finalData}
                // />
                <DocumentsForm
                    languageCode={item.code}
                    isEditable={isEditable}
                    data={finalData}
                    legalDocList={legalDocList}
                    fetchLegalDoc={fetchLegalDocs}
                />
            ),
        };
    });

    const items = [
        {
            key: "1",
            label: `${t("brandSetting.general")}`,
            children: <Tabs tabPosition={"right"} defaultActiveKey="1" items={generalFormItems}/>,
        },
        {
            key: "2",
            label: `${t("brandSetting.domain")}`,
            children: (
                <DomainForm
                    submitLoading={loading}
                    data={finalData}
                    setData={setFinalData}
                    isEditable={isEditable}
                    onBrandDetailsSubmit={form.handleSubmit(onSubmit)}
                />
            ),
        },
        {
            key: "3",
            label: `${t("brandSetting.company")}`,
            children: <Tabs tabPosition={"right"} defaultActiveKey="1" items={companyFormItems}/>,
        },
        {
            key: "4",
            label: `${t("brandSetting.images")}`,
            children: <Tabs tabPosition={"right"} defaultActiveKey="1" items={imagesFormItems}/>,
        },
        {
            key: "5",
            label: `${t("brandSetting.social")}`,
            children: <Tabs tabPosition={"right"} defaultActiveKey="1" items={socialFormItems}/>,
        },
        {
            key: "6",
            label: `${t("brandSetting.documents")}`,
            children: <Tabs tabPosition={"right"} defaultActiveKey="1" items={documentsFormItems} destroyInactiveTabPane/>,
        },
        // {
        //     key: "7",
        //     label: `${t("brandSetting.navigations")}`,
        //     children: (
        //         <RedirectForm
        //             submitLoading={loading}
        //             data={finalData}
        //             setData={setFinalData}
        //             isEditable={isEditable}
        //             onBrandDetailsSubmit={form.handleSubmit(onSubmit)}
        //         />
        //     ),
        // },
        {
            key: "7",
            label: `${t("brandSetting.others")}`,
            children: (
                <OtherForm
                    submitLoading={loading}
                    data={finalData}
                    setData={setFinalData}
                    isEditable={isEditable}
                    onBrandDetailsSubmit={form.handleSubmit(onSubmit)}
                />
            ),
        },
        {
            key: "8",
            label: `${t("brandSetting.navigationRouteSetting")}`,
            children: <NavigationRouteSettingPage permission={isEditable}/>,
        },
    ];

    return (
        <div id={"brand-details"} className={"form-layout"}>
            <FormProvider {...form}>
                <Form labelWrap={true} labelAlign={"left"}>
                    <div id="brandDetails">
                        <Row>
                            <Col span={17}>
                                <h1>{t("brandSetting.brandDetails")}</h1>
                            </Col>

                            {/*<Col span={4}>*/}
                            {/*    {brandDetailsPermission?.edit?.edit && (*/}
                            {/*        <Form.Item className="text-align-right">*/}
                            {/*            <Button*/}
                            {/*                htmlType={"submit"}*/}
                            {/*                onClick={form.handleSubmit(onSubmit)}*/}
                            {/*                */}
                            {/*                className="button-submit-1"*/}
                            {/*                loading={loading}*/}
                            {/*            >*/}
                            {/*                {t("common.update")}*/}
                            {/*            </Button>*/}
                            {/*        </Form.Item>*/}
                            {/*    )}*/}
                            {/*</Col>*/}
                            {isMainMasterAdmin && (
                                <Col span={7}>
                                    <ImportDBTableButton tableName={"admintablesetting"}/>
                                    <ExportDBTableButton tableName={"admintablesetting"}/>
                                </Col>
                            )}
                        </Row>
                        <Tabs activeKey={currentTab} items={items} onTabClick={(key) => setCurrentTab(key)}/>
                    </div>
                </Form>
            </FormProvider>
        </div>
    );
};

export default BrandDetails_1;
