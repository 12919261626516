export const countryList_Language = [
    {
        "key": "country.afghanistan",
        "label": "country.afghanistan",
        "value": "country.afghanistan"
    },
    {
        "key": "country.landIslands",
        "label": "country.landIslands",
        "value": "country.landIslands"
    },
    {
        "key": "country.albania",
        "label": "country.albania",
        "value": "country.albania"
    },
    {
        "key": "country.algeria",
        "label": "country.algeria",
        "value": "country.algeria"
    },
    {
        "key": "country.americanSamoa",
        "label": "country.americanSamoa",
        "value": "country.americanSamoa"
    },
    {
        "key": "country.andorrA",
        "label": "country.andorrA",
        "value": "country.andorrA"
    },
    {
        "key": "country.angola",
        "label": "country.angola",
        "value": "country.angola"
    },
    {
        "key": "country.anguilla",
        "label": "country.anguilla",
        "value": "country.anguilla"
    },
    {
        "key": "country.antarctica",
        "label": "country.antarctica",
        "value": "country.antarctica"
    },
    {
        "key": "country.antiguaAndBarbuda",
        "label": "country.antiguaAndBarbuda",
        "value": "country.antiguaAndBarbuda"
    },
    {
        "key": "country.argentina",
        "label": "country.argentina",
        "value": "country.argentina"
    },
    {
        "key": "country.armenia",
        "label": "country.armenia",
        "value": "country.armenia"
    },
    {
        "key": "country.aruba",
        "label": "country.aruba",
        "value": "country.aruba"
    },
    {
        "key": "country.australia",
        "label": "country.australia",
        "value": "country.australia"
    },
    {
        "key": "country.austria",
        "label": "country.austria",
        "value": "country.austria"
    },
    {
        "key": "country.azerbaijan",
        "label": "country.azerbaijan",
        "value": "country.azerbaijan"
    },
    {
        "key": "country.bahamas",
        "label": "country.bahamas",
        "value": "country.bahamas"
    },
    {
        "key": "country.bahrain",
        "label": "country.bahrain",
        "value": "country.bahrain"
    },
    {
        "key": "country.bangladesh",
        "label": "country.bangladesh",
        "value": "country.bangladesh"
    },
    {
        "key": "country.barbados",
        "label": "country.barbados",
        "value": "country.barbados"
    },
    {
        "key": "country.belarus",
        "label": "country.belarus",
        "value": "country.belarus"
    },
    {
        "key": "country.belgium",
        "label": "country.belgium",
        "value": "country.belgium"
    },
    {
        "key": "country.belize",
        "label": "country.belize",
        "value": "country.belize"
    },
    {
        "key": "country.benin",
        "label": "country.benin",
        "value": "country.benin"
    },
    {
        "key": "country.bermuda",
        "label": "country.bermuda",
        "value": "country.bermuda"
    },
    {
        "key": "country.bhutan",
        "label": "country.bhutan",
        "value": "country.bhutan"
    },
    {
        "key": "country.bolivia",
        "label": "country.bolivia",
        "value": "country.bolivia"
    },
    {
        "key": "country.bosniaAndHerzegovina",
        "label": "country.bosniaAndHerzegovina",
        "value": "country.bosniaAndHerzegovina"
    },
    {
        "key": "country.botswana",
        "label": "country.botswana",
        "value": "country.botswana"
    },
    {
        "key": "country.bouvetIsland",
        "label": "country.bouvetIsland",
        "value": "country.bouvetIsland"
    },
    {
        "key": "country.brazil",
        "label": "country.brazil",
        "value": "country.brazil"
    },
    {
        "key": "country.britishIndianOceanTerritory",
        "label": "country.britishIndianOceanTerritory",
        "value": "country.britishIndianOceanTerritory"
    },
    {
        "key": "country.bruneiDarussalam",
        "label": "country.bruneiDarussalam",
        "value": "country.bruneiDarussalam"
    },
    {
        "key": "country.bulgaria",
        "label": "country.bulgaria",
        "value": "country.bulgaria"
    },
    {
        "key": "country.burkinaFaso",
        "label": "country.burkinaFaso",
        "value": "country.burkinaFaso"
    },
    {
        "key": "country.burundi",
        "label": "country.burundi",
        "value": "country.burundi"
    },
    {
        "key": "country.cambodia",
        "label": "country.cambodia",
        "value": "country.cambodia"
    },
    {
        "key": "country.cameroon",
        "label": "country.cameroon",
        "value": "country.cameroon"
    },
    {
        "key": "country.canada",
        "label": "country.canada",
        "value": "country.canada"
    },
    {
        "key": "country.capeVerde",
        "label": "country.capeVerde",
        "value": "country.capeVerde"
    },
    {
        "key": "country.caymanIslands",
        "label": "country.caymanIslands",
        "value": "country.caymanIslands"
    },
    {
        "key": "country.centralAfricanRepublic",
        "label": "country.centralAfricanRepublic",
        "value": "country.centralAfricanRepublic"
    },
    {
        "key": "country.chad",
        "label": "country.chad",
        "value": "country.chad"
    },
    {
        "key": "country.chile",
        "label": "country.chile",
        "value": "country.chile"
    },
    {
        "key": "country.china",
        "label": "country.china",
        "value": "country.china"
    },
    {
        "key": "country.christmasIsland",
        "label": "country.christmasIsland",
        "value": "country.christmasIsland"
    },
    {
        "key": "country.cocos KeelingIslands",
        "label": "country.cocos KeelingIslands",
        "value": "country.cocos KeelingIslands"
    },
    {
        "key": "country.colombia",
        "label": "country.colombia",
        "value": "country.colombia"
    },
    {
        "key": "country.comoros",
        "label": "country.comoros",
        "value": "country.comoros"
    },
    {
        "key": "country.congo",
        "label": "country.congo",
        "value": "country.congo"
    },
    {
        "key": "country.congoTheDemocraticRepublicOfThe",
        "label": "country.congoTheDemocraticRepublicOfThe",
        "value": "country.congoTheDemocraticRepublicOfThe"
    },
    {
        "key": "country.cookIslands",
        "label": "country.cookIslands",
        "value": "country.cookIslands"
    },
    {
        "key": "country.costaRica",
        "label": "country.costaRica",
        "value": "country.costaRica"
    },
    {
        "key": "country.croatia",
        "label": "country.croatia",
        "value": "country.croatia"
    },
    {
        "key": "country.cuba",
        "label": "country.cuba",
        "value": "country.cuba"
    },
    {
        "key": "country.cyprus",
        "label": "country.cyprus",
        "value": "country.cyprus"
    },
    {
        "key": "country.czechRepublic",
        "label": "country.czechRepublic",
        "value": "country.czechRepublic"
    },
    {
        "key": "country.denmark",
        "label": "country.denmark",
        "value": "country.denmark"
    },
    {
        "key": "country.djibouti",
        "label": "country.djibouti",
        "value": "country.djibouti"
    },
    {
        "key": "country.dominica",
        "label": "country.dominica",
        "value": "country.dominica"
    },
    {
        "key": "country.dominicanRepublic",
        "label": "country.dominicanRepublic",
        "value": "country.dominicanRepublic"
    },
    {
        "key": "country.ecuador",
        "label": "country.ecuador",
        "value": "country.ecuador"
    },
    {
        "key": "country.egypt",
        "label": "country.egypt",
        "value": "country.egypt"
    },
    {
        "key": "country.elSalvador",
        "label": "country.elSalvador",
        "value": "country.elSalvador"
    },
    {
        "key": "country.equatorialGuinea",
        "label": "country.equatorialGuinea",
        "value": "country.equatorialGuinea"
    },
    {
        "key": "country.eritrea",
        "label": "country.eritrea",
        "value": "country.eritrea"
    },
    {
        "key": "country.estonia",
        "label": "country.estonia",
        "value": "country.estonia"
    },
    {
        "key": "country.ethiopia",
        "label": "country.ethiopia",
        "value": "country.ethiopia"
    },
    {
        "key": "country.falklandIslandsMalvinas",
        "label": "country.falklandIslandsMalvinas",
        "value": "country.falklandIslandsMalvinas"
    },
    {
        "key": "country.faroeIslands",
        "label": "country.faroeIslands",
        "value": "country.faroeIslands"
    },
    {
        "key": "country.fiji",
        "label": "country.fiji",
        "value": "country.fiji"
    },
    {
        "key": "country.finland",
        "label": "country.finland",
        "value": "country.finland"
    },
    {
        "key": "country.france",
        "label": "country.france",
        "value": "country.france"
    },
    {
        "key": "country.frenchGuiana",
        "label": "country.frenchGuiana",
        "value": "country.frenchGuiana"
    },
    {
        "key": "country.frenchPolynesia",
        "label": "country.frenchPolynesia",
        "value": "country.frenchPolynesia"
    },
    {
        "key": "country.frenchSouthernTerritories",
        "label": "country.frenchSouthernTerritories",
        "value": "country.frenchSouthernTerritories"
    },
    {
        "key": "country.gabon",
        "label": "country.gabon",
        "value": "country.gabon"
    },
    {
        "key": "country.gambia",
        "label": "country.gambia",
        "value": "country.gambia"
    },
    {
        "key": "country.georgia",
        "label": "country.georgia",
        "value": "country.georgia"
    },
    {
        "key": "country.germany",
        "label": "country.germany",
        "value": "country.germany"
    },
    {
        "key": "country.ghana",
        "label": "country.ghana",
        "value": "country.ghana"
    },
    {
        "key": "country.gibraltar",
        "label": "country.gibraltar",
        "value": "country.gibraltar"
    },
    {
        "key": "country.greece",
        "label": "country.greece",
        "value": "country.greece"
    },
    {
        "key": "country.greenland",
        "label": "country.greenland",
        "value": "country.greenland"
    },
    {
        "key": "country.grenada",
        "label": "country.grenada",
        "value": "country.grenada"
    },
    {
        "key": "country.guadeloupe",
        "label": "country.guadeloupe",
        "value": "country.guadeloupe"
    },
    {
        "key": "country.guam",
        "label": "country.guam",
        "value": "country.guam"
    },
    {
        "key": "country.guatemala",
        "label": "country.guatemala",
        "value": "country.guatemala"
    },
    {
        "key": "country.guernsey",
        "label": "country.guernsey",
        "value": "country.guernsey"
    },
    {
        "key": "country.guinea",
        "label": "country.guinea",
        "value": "country.guinea"
    },
    {
        "key": "country.guineaBissau",
        "label": "country.guineaBissau",
        "value": "country.guineaBissau"
    },
    {
        "key": "country.guyana",
        "label": "country.guyana",
        "value": "country.guyana"
    },
    {
        "key": "country.haiti",
        "label": "country.haiti",
        "value": "country.haiti"
    },
    {
        "key": "country.heardIslandAndMcdonaldIslands",
        "label": "country.heardIslandAndMcdonaldIslands",
        "value": "country.heardIslandAndMcdonaldIslands"
    },
    {
        "key": "country.holySeeVaticanCityState",
        "label": "country.holySeeVaticanCityState",
        "value": "country.holySeeVaticanCityState"
    },
    {
        "key": "country.honduras",
        "label": "country.honduras",
        "value": "country.honduras"
    },
    {
        "key": "country.hongKong",
        "label": "country.hongKong",
        "value": "country.hongKong"
    },
    {
        "key": "country.hungary",
        "label": "country.hungary",
        "value": "country.hungary"
    },
    {
        "key": "country.iceland",
        "label": "country.iceland",
        "value": "country.iceland"
    },
    {
        "key": "country.india",
        "label": "country.india",
        "value": "country.india"
    },
    {
        "key": "country.indonesia",
        "label": "country.indonesia",
        "value": "country.indonesia"
    },
    {
        "key": "country.iranIslamicRepublicOf",
        "label": "country.iranIslamicRepublicOf",
        "value": "country.iranIslamicRepublicOf"
    },
    {
        "key": "country.iraq",
        "label": "country.iraq",
        "value": "country.iraq"
    },
    {
        "key": "country.ireland",
        "label": "country.ireland",
        "value": "country.ireland"
    },
    {
        "key": "country.isleOfMan",
        "label": "country.isleOfMan",
        "value": "country.isleOfMan"
    },
    {
        "key": "country.israel",
        "label": "country.israel",
        "value": "country.israel"
    },
    {
        "key": "country.italy",
        "label": "country.italy",
        "value": "country.italy"
    },
    {
        "key": "country.jamaica",
        "label": "country.jamaica",
        "value": "country.jamaica"
    },
    {
        "key": "country.japan",
        "label": "country.japan",
        "value": "country.japan"
    },
    {
        "key": "country.jersey",
        "label": "country.jersey",
        "value": "country.jersey"
    },
    {
        "key": "country.jordan",
        "label": "country.jordan",
        "value": "country.jordan"
    },
    {
        "key": "country.kazakhstan",
        "label": "country.kazakhstan",
        "value": "country.kazakhstan"
    },
    {
        "key": "country.kenya",
        "label": "country.kenya",
        "value": "country.kenya"
    },
    {
        "key": "country.kiribati",
        "label": "country.kiribati",
        "value": "country.kiribati"
    },
    {
        "key": "country.koreaRepublicOf",
        "label": "country.koreaRepublicOf",
        "value": "country.koreaRepublicOf"
    },
    {
        "key": "country.kuwait",
        "label": "country.kuwait",
        "value": "country.kuwait"
    },
    {
        "key": "country.kyrgyzstan",
        "label": "country.kyrgyzstan",
        "value": "country.kyrgyzstan"
    },
    {
        "key": "country.latvia",
        "label": "country.latvia",
        "value": "country.latvia"
    },
    {
        "key": "country.lebanon",
        "label": "country.lebanon",
        "value": "country.lebanon"
    },
    {
        "key": "country.lesotho",
        "label": "country.lesotho",
        "value": "country.lesotho"
    },
    {
        "key": "country.liberia",
        "label": "country.liberia",
        "value": "country.liberia"
    },
    {
        "key": "country.libyanArabJamahiriya",
        "label": "country.libyanArabJamahiriya",
        "value": "country.libyanArabJamahiriya"
    },
    {
        "key": "country.liechtenstein",
        "label": "country.liechtenstein",
        "value": "country.liechtenstein"
    },
    {
        "key": "country.lithuania",
        "label": "country.lithuania",
        "value": "country.lithuania"
    },
    {
        "key": "country.luxembourg",
        "label": "country.luxembourg",
        "value": "country.luxembourg"
    },
    {
        "key": "country.macao",
        "label": "country.macao",
        "value": "country.macao"
    },
    {
        "key": "country.northMacedonia",
        "label": "country.northMacedonia",
        "value": "country.northMacedonia"
    },
    {
        "key": "country.madagascar",
        "label": "country.madagascar",
        "value": "country.madagascar"
    },
    {
        "key": "country.malawi",
        "label": "country.malawi",
        "value": "country.malawi"
    },
    {
        "key": "country.malaysia",
        "label": "country.malaysia",
        "value": "country.malaysia"
    },
    {
        "key": "country.maldives",
        "label": "country.maldives",
        "value": "country.maldives"
    },
    {
        "key": "country.mali",
        "label": "country.mali",
        "value": "country.mali"
    },
    {
        "key": "country.malta",
        "label": "country.malta",
        "value": "country.malta"
    },
    {
        "key": "country.marshallIslands",
        "label": "country.marshallIslands",
        "value": "country.marshallIslands"
    },
    {
        "key": "country.martinique",
        "label": "country.martinique",
        "value": "country.martinique"
    },
    {
        "key": "country.mauritania",
        "label": "country.mauritania",
        "value": "country.mauritania"
    },
    {
        "key": "country.mauritius",
        "label": "country.mauritius",
        "value": "country.mauritius"
    },
    {
        "key": "country.mayotte",
        "label": "country.mayotte",
        "value": "country.mayotte"
    },
    {
        "key": "country.mexico",
        "label": "country.mexico",
        "value": "country.mexico"
    },
    {
        "key": "country.micronesiaFederatedStatesOf",
        "label": "country.micronesiaFederatedStatesOf",
        "value": "country.micronesiaFederatedStatesOf"
    },
    {
        "key": "country.moldovaRepublicOf",
        "label": "country.moldovaRepublicOf",
        "value": "country.moldovaRepublicOf"
    },
    {
        "key": "country.monaco",
        "label": "country.monaco",
        "value": "country.monaco"
    },
    {
        "key": "country.mongolia",
        "label": "country.mongolia",
        "value": "country.mongolia"
    },
    {
        "key": "country.montserrat",
        "label": "country.montserrat",
        "value": "country.montserrat"
    },
    {
        "key": "country.morocco",
        "label": "country.morocco",
        "value": "country.morocco"
    },
    {
        "key": "country.mozambique",
        "label": "country.mozambique",
        "value": "country.mozambique"
    },
    {
        "key": "country.myanmar",
        "label": "country.myanmar",
        "value": "country.myanmar"
    },
    {
        "key": "country.namibia",
        "label": "country.namibia",
        "value": "country.namibia"
    },
    {
        "key": "country.nauru",
        "label": "country.nauru",
        "value": "country.nauru"
    },
    {
        "key": "country.nepal",
        "label": "country.nepal",
        "value": "country.nepal"
    },
    {
        "key": "country.netherlands",
        "label": "country.netherlands",
        "value": "country.netherlands"
    },
    {
        "key": "country.netherlandsAntilles",
        "label": "country.netherlandsAntilles",
        "value": "country.netherlandsAntilles"
    },
    {
        "key": "country.newCaledonia",
        "label": "country.newCaledonia",
        "value": "country.newCaledonia"
    },
    {
        "key": "country.newZealand",
        "label": "country.newZealand",
        "value": "country.newZealand"
    },
    {
        "key": "country.nicaragua",
        "label": "country.nicaragua",
        "value": "country.nicaragua"
    },
    {
        "key": "country.niger",
        "label": "country.niger",
        "value": "country.niger"
    },
    {
        "key": "country.nigeria",
        "label": "country.nigeria",
        "value": "country.nigeria"
    },
    {
        "key": "country.niue",
        "label": "country.niue",
        "value": "country.niue"
    },
    {
        "key": "country.norfolkIsland",
        "label": "country.norfolkIsland",
        "value": "country.norfolkIsland"
    },
    {
        "key": "country.northernMarianaIslands",
        "label": "country.northernMarianaIslands",
        "value": "country.northernMarianaIslands"
    },
    {
        "key": "country.norway",
        "label": "country.norway",
        "value": "country.norway"
    },
    {
        "key": "country.oman",
        "label": "country.oman",
        "value": "country.oman"
    },
    {
        "key": "country.pakistan",
        "label": "country.pakistan",
        "value": "country.pakistan"
    },
    {
        "key": "country.palau",
        "label": "country.palau",
        "value": "country.palau"
    },
    {
        "key": "country.palestinianTerritoryOccupied",
        "label": "country.palestinianTerritoryOccupied",
        "value": "country.palestinianTerritoryOccupied"
    },
    {
        "key": "country.panama",
        "label": "country.panama",
        "value": "country.panama"
    },
    {
        "key": "country.papuaNewGuinea",
        "label": "country.papuaNewGuinea",
        "value": "country.papuaNewGuinea"
    },
    {
        "key": "country.paraguay",
        "label": "country.paraguay",
        "value": "country.paraguay"
    },
    {
        "key": "country.peru",
        "label": "country.peru",
        "value": "country.peru"
    },
    {
        "key": "country.philippines",
        "label": "country.philippines",
        "value": "country.philippines"
    },
    {
        "key": "country.pitcairnIslands",
        "label": "country.pitcairnIslands",
        "value": "country.pitcairnIslands"
    },
    {
        "key": "country.poland",
        "label": "country.poland",
        "value": "country.poland"
    },
    {
        "key": "country.portugal",
        "label": "country.portugal",
        "value": "country.portugal"
    },
    {
        "key": "country.puertoRico",
        "label": "country.puertoRico",
        "value": "country.puertoRico"
    },
    {
        "key": "country.qatar",
        "label": "country.qatar",
        "value": "country.qatar"
    },
    {
        "key": "country.reunion",
        "label": "country.reunion",
        "value": "country.reunion"
    },
    {
        "key": "country.romania",
        "label": "country.romania",
        "value": "country.romania"
    },
    {
        "key": "country.russianFederation",
        "label": "country.russianFederation",
        "value": "country.russianFederation"
    },
    {
        "key": "country.rwanda",
        "label": "country.rwanda",
        "value": "country.rwanda"
    },
    {
        "key": "country.saintHelena",
        "label": "country.saintHelena",
        "value": "country.saintHelena"
    },
    {
        "key": "country.saintKittsAndNevis",
        "label": "country.saintKittsAndNevis",
        "value": "country.saintKittsAndNevis"
    },
    {
        "key": "country.saintLucia",
        "label": "country.saintLucia",
        "value": "country.saintLucia"
    },
    {
        "key": "country.saintPierreAndMiquelon",
        "label": "country.saintPierreAndMiquelon",
        "value": "country.saintPierreAndMiquelon"
    },
    {
        "key": "country.saintVincentAndTheGrenadines",
        "label": "country.saintVincentAndTheGrenadines",
        "value": "country.saintVincentAndTheGrenadines"
    },
    {
        "key": "country.samoa",
        "label": "country.samoa",
        "value": "country.samoa"
    },
    {
        "key": "country.sanMarino",
        "label": "country.sanMarino",
        "value": "country.sanMarino"
    },
    {
        "key": "country.saoTomeAndPrincipe",
        "label": "country.saoTomeAndPrincipe",
        "value": "country.saoTomeAndPrincipe"
    },
    {
        "key": "country.saudiArabia",
        "label": "country.saudiArabia",
        "value": "country.saudiArabia"
    },
    {
        "key": "country.senegal",
        "label": "country.senegal",
        "value": "country.senegal"
    },
    {
        "key": "country.serbiaAndMontenegro",
        "label": "country.serbiaAndMontenegro",
        "value": "country.serbiaAndMontenegro"
    },
    {
        "key": "country.seychelles",
        "label": "country.seychelles",
        "value": "country.seychelles"
    },
    {
        "key": "country.sierraLeone",
        "label": "country.sierraLeone",
        "value": "country.sierraLeone"
    },
    {
        "key": "country.singapore",
        "label": "country.singapore",
        "value": "country.singapore"
    },
    {
        "key": "country.slovakia",
        "label": "country.slovakia",
        "value": "country.slovakia"
    },
    {
        "key": "country.slovenia",
        "label": "country.slovenia",
        "value": "country.slovenia"
    },
    {
        "key": "country.solomonIslands",
        "label": "country.solomonIslands",
        "value": "country.solomonIslands"
    },
    {
        "key": "country.somalia",
        "label": "country.somalia",
        "value": "country.somalia"
    },
    {
        "key": "country.southAfrica",
        "label": "country.southAfrica",
        "value": "country.southAfrica"
    },
    {
        "key": "country.southGeorgiaAndTheSouthSandwichIslands",
        "label": "country.southGeorgiaAndTheSouthSandwichIslands",
        "value": "country.southGeorgiaAndTheSouthSandwichIslands"
    },
    {
        "key": "country.spain",
        "label": "country.spain",
        "value": "country.spain"
    },
    {
        "key": "country.sriLanka",
        "label": "country.sriLanka",
        "value": "country.sriLanka"
    },
    {
        "key": "country.sudan",
        "label": "country.sudan",
        "value": "country.sudan"
    },
    {
        "key": "country.suriname",
        "label": "country.suriname",
        "value": "country.suriname"
    },
    {
        "key": "country.svalbardAndJanMayen",
        "label": "country.svalbardAndJanMayen",
        "value": "country.svalbardAndJanMayen"
    },
    {
        "key": "country.swaziland",
        "label": "country.swaziland",
        "value": "country.swaziland"
    },
    {
        "key": "country.sweden",
        "label": "country.sweden",
        "value": "country.sweden"
    },
    {
        "key": "country.switzerland",
        "label": "country.switzerland",
        "value": "country.switzerland"
    },
    {
        "key": "country.syrianArabRepublic",
        "label": "country.syrianArabRepublic",
        "value": "country.syrianArabRepublic"
    },
    {
        "key": "country.taiwan",
        "label": "country.taiwan",
        "value": "country.taiwan"
    },
    {
        "key": "country.tajikistan",
        "label": "country.tajikistan",
        "value": "country.tajikistan"
    },
    {
        "key": "country.tanzaniaUnitedRepublicOf",
        "label": "country.tanzaniaUnitedRepublicOf",
        "value": "country.tanzaniaUnitedRepublicOf"
    },
    {
        "key": "country.thailand",
        "label": "country.thailand",
        "value": "country.thailand"
    },
    {
        "key": "country.timorLeste",
        "label": "country.timorLeste",
        "value": "country.timorLeste"
    },
    {
        "key": "country.togo",
        "label": "country.togo",
        "value": "country.togo"
    },
    {
        "key": "country.tokelau",
        "label": "country.tokelau",
        "value": "country.tokelau"
    },
    {
        "key": "country.tonga",
        "label": "country.tonga",
        "value": "country.tonga"
    },
    {
        "key": "country.trinidadAndTobago",
        "label": "country.trinidadAndTobago",
        "value": "country.trinidadAndTobago"
    },
    {
        "key": "country.tunisia",
        "label": "country.tunisia",
        "value": "country.tunisia"
    },
    {
        "key": "country.turkey",
        "label": "country.turkey",
        "value": "country.turkey"
    },
    {
        "key": "country.turkmenistan",
        "label": "country.turkmenistan",
        "value": "country.turkmenistan"
    },
    {
        "key": "country.turksAndCaicosIslands",
        "label": "country.turksAndCaicosIslands",
        "value": "country.turksAndCaicosIslands"
    },
    {
        "key": "country.tuvalu",
        "label": "country.tuvalu",
        "value": "country.tuvalu"
    },
    {
        "key": "country.uganda",
        "label": "country.uganda",
        "value": "country.uganda"
    },
    {
        "key": "country.ukraine",
        "label": "country.ukraine",
        "value": "country.ukraine"
    },
    {
        "key": "country.unitedArabEmirates",
        "label": "country.unitedArabEmirates",
        "value": "country.unitedArabEmirates"
    },
    {
        "key": "country.unitedKingdom",
        "label": "country.unitedKingdom",
        "value": "country.unitedKingdom"
    },
    {
        "key": "country.unitedStates",
        "label": "country.unitedStates",
        "value": "country.unitedStates"
    },
    {
        "key": "country.unitedStatesMinorOutlyingIslands",
        "label": "country.unitedStatesMinorOutlyingIslands",
        "value": "country.unitedStatesMinorOutlyingIslands"
    },
    {
        "key": "country.uruguay",
        "label": "country.uruguay",
        "value": "country.uruguay"
    },
    {
        "key": "country.uzbekistan",
        "label": "country.uzbekistan",
        "value": "country.uzbekistan"
    },
    {
        "key": "country.vanuatu",
        "label": "country.vanuatu",
        "value": "country.vanuatu"
    },
    {
        "key": "country.venezuela",
        "label": "country.venezuela",
        "value": "country.venezuela"
    },
    {
        "key": "country.vietnam",
        "label": "country.vietnam",
        "value": "country.vietnam"
    },
    {
        "key": "country.virginIslandsBritish",
        "label": "country.virginIslandsBritish",
        "value": "country.virginIslandsBritish"
    },
    {
        "key": "country.virginIslandsUS",
        "label": "country.virginIslandsUS",
        "value": "country.virginIslandsUS"
    },
    {
        "key": "country.wallisAndFutuna",
        "label": "country.wallisAndFutuna",
        "value": "country.wallisAndFutuna"
    },
    {
        "key": "country.westernSahara",
        "label": "country.westernSahara",
        "value": "country.westernSahara"
    },
    {
        "key": "country.yemen",
        "label": "country.yemen",
        "value": "country.yemen"
    },
    {
        "key": "country.zambia",
        "label": "country.zambia",
        "value": "country.zambia"
    },
    {
        "key": "country.zimbabwe",
        "label": "country.zimbabwe",
        "value": "country.zimbabwe"
    }
]