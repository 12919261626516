import React from "react";
import {Modal} from "antd";

import VariableForm from "./variableForm";
import {useTranslation} from "react-i18next";

const VariableModal = (props) => {
    const {
        mode,
        item,
        isOpen,
        closeModal,
        languageData
    } = props
    const { t } = useTranslation()

    const getTitle = ():String => {
      switch(mode){
        case "add":
          return `${t('Add New Variable')}`
        case "view":
          return `${t('View Variable')}`
        case "edit":
          return `${t('Edit Variable')}`
        default: 
         return `${t('Add New Variable')}`
      }
    }

  return (
    <Modal
      getContainer={"#dynamicVariable"}
      title={getTitle()}
      open={isOpen}
      width={1000}
      footer={null}
      destroyOnClose
      onCancel={closeModal}
    >
      <>
        <VariableForm mode={mode} item={item} closeModal={closeModal} languageData={languageData} />
      </>
    </Modal>
  );
};

export default VariableModal;
