import React, {useEffect, useMemo, useState} from "react";
import {useRecoilValue} from "recoil";
import {useTranslation} from "react-i18next";
import {Button, Col, Form, Input, InputNumber, Modal, Row, Select, Space} from "antd";
import {getAllAvailableProductByUserId} from "../../../../../../../../../api/graphql/product";
import AccountDetailCard from "./accountDetailCard";
import {getTradingAccount, useAddTradingAccountMutation, useLinkTradingAccountMutation} from "../../../../../../../../../api/graphql/tradingAccount";
import useAuthorize from "../../../../../../../../../_common/authorize";
import {selfPermissionObj} from "../../../../../../../../../../recoil_state";
import {
    useCreateTradingAccountMt5InvestorMutation,
    useCreateTradingAccountMt5MoneyManagerMutation,
    useCreateTradingAccountMt5Mutation,
    useLinkTradingAccountMt5InvestorMutation,
    useLinkTradingAccountMt5MoneyManagerMutation,
    useLinkTradingAccountMt5Mutation,
} from "../../../../../../../../../api/graphql/tradingAccountMt5";
import MoneyManagerAccountSettingsForm from "./moneyManagerAccountSettingsForm";
import NewMoneyManagerLegalDocumentsModal from "./newMoneyManagerLegalDocumentsModal";

const Account = (props) => {
    const {data, account, loading, fetchTradingAccount} = props;
    const {t} = useTranslation();
    const labelCol = {span: 8, offset: 0};

    const [mode, setMode] = useState("");
    const [actionLoading, setActionLoading] = useState(false);
    const [selectedProductType, setSelectedProductType] = useState<any>();
    const [selectedProduct, setSelectedProduct] = useState<any>();
    const [selectedProductObj, setSelectedProductObj] = useState<any>({});
    const [allAvailableProductFilter, seAllAvailableProductFilter] = useState<any>();
    const [profileImage, setProfileImage] = useState("");

    const [isOpen, setIsOpen] = useState(false);

    const [newMoneyManagerLegalDocsProps, setNewMoneyManagerLegalDocsProps] = useState<any>({isOpen: false});

    const {data: allAvailableProductByProductType, loading: allAvailableProductByProductTypeLoading} = getAllAvailableProductByUserId(
        data?._id,
        allAvailableProductFilter
    );
    const {data: moneyManagerAccountData} = getTradingAccount({filter: {productType: "moneyManager"},});

    const {addTradingAccount, loading: addTradingAccountLoading} = useAddTradingAccountMutation();
    const {linkTradingAccount, loading: linkTradingAccountLoading} = useLinkTradingAccountMutation();
    const {createTradingAccountMt5, loading: createTradingMt5AccountLoading} = useCreateTradingAccountMt5Mutation();
    const {linkTradingAccountMt5, loading: linkTradingAccountMt5Loading} = useLinkTradingAccountMt5Mutation();
    const {createTradingAccountMt5MoneyManager, loading: createTradingMt5AccountMoneyManagerLoading} =
        useCreateTradingAccountMt5MoneyManagerMutation();
    const {linkTradingAccountMt5MoneyManager, loading: linkTradingAccountMt5MoneyManagerLoading} = useLinkTradingAccountMt5MoneyManagerMutation();
    const {linkTradingAccountMt5Investor, loading: linkTradingAccountMt5InvestorLoading} = useLinkTradingAccountMt5InvestorMutation();
    const {createTradingAccountMt5Investor, loading: createTradingMt5AccountInvestorLoading} = useCreateTradingAccountMt5InvestorMutation();

    const {systemErrorNotification} = useAuthorize();
    const permission = useRecoilValue<any>(selfPermissionObj);

    const [form] = Form.useForm();

    const tradingAccountPermission = permission?.tradingAccount;

    const productOptions = useMemo(() => {
        return allAvailableProductByProductType?.getAllAvailableProductByUserId?.data?.map((d) => {
            return {
                label: (
                    <Row justify={"space-between"}>
                        <span>{d?.server?.name + " - " + d?.name}</span> <span>{d?.server?.platform?.toUpperCase()}</span>
                    </Row>
                ),
                searchLabel: `${d?.server?.name + " - " + d?.name} ${d?.server?.platform?.toUpperCase()}`,
                value: `${d?.server?.platform}_${d?._id}_${d?.productType}`,
            };
        });
    }, [allAvailableProductByProductType]);

    const moneyManagerAccountOptions = useMemo(() => {
        return (
            moneyManagerAccountData?.getTradingAccount?.data
                ?.map((d) => {
                    let accountNo = d?.platform === "cTrader" ? d?.accountId : d?.accountId;
                    return {
                        label: `${accountNo} - ${d?.user?.username} - ${d?.product?.name}`,
                        value: `${d?.platform}_${d?._id}`,
                    };
                })
        );
    }, [moneyManagerAccountData]);

    const onClickAddAccount = (type) => {
        setSelectedProductType(type);
        setIsOpen(true);
        setMode("Add");
    };

    const onClickLinkAccount = (type) => {
        setSelectedProductType(type);
        setIsOpen(true);
        setMode("Link");
    };

    const onCancel = () => {
        form.resetFields();
        setSelectedProductType(undefined);
        setIsOpen(false);
    };

    const handleProductChange = async (product) => {
        try {
            setSelectedProduct(product);
            let productObj = allAvailableProductByProductType?.getAllAvailableProductByUserId?.data?.find((v) => v?._id === product?.split("_")?.[1]);
            if (productObj?.mode !== selectedProductObj?.mode) {
                form.setFieldValue("investorProduct", "");
            }
            if (productObj) {
                setSelectedProductObj(productObj);
            }
        } catch (error) {}
    };

    const resetForm = async () => {
        try {
            form.resetFields();
            setSelectedProductObj({});
        } catch (error) {}
    };

    useEffect(() => {
        if (selectedProductType) {
            let filter: any = null;
            switch (selectedProductType) {
                case "selfTrade":
                    filter = ["selfTradeLive", "selfTradeDemo"];
                    break;
                case "moneyManager":
                    filter = ["moneyManagerLive", "moneyManagerDemo"];
                    break;
                case "investor":
                    filter = ["investorLive", "investorDemo"];
                    break;
                default:
                    break;
            }
            seAllAvailableProductFilter(filter);
        }
    }, [selectedProductType]);

    useEffect(() => {
        if (
            addTradingAccountLoading ||
            linkTradingAccountLoading ||
            createTradingMt5AccountLoading ||
            linkTradingAccountMt5Loading ||
            createTradingMt5AccountMoneyManagerLoading ||
            createTradingMt5AccountInvestorLoading ||
            linkTradingAccountMt5MoneyManagerLoading ||
            linkTradingAccountMt5InvestorLoading
        )
            setActionLoading(true);
        else setActionLoading(false);
    }, [
        addTradingAccountLoading,
        linkTradingAccountLoading,
        createTradingMt5AccountLoading,
        linkTradingAccountMt5Loading,
        createTradingMt5AccountMoneyManagerLoading,
        createTradingMt5AccountInvestorLoading,
        linkTradingAccountMt5MoneyManagerLoading,
        linkTradingAccountMt5InvestorLoading,
    ]);

    useEffect(() => {
        if (!isOpen) {
            resetForm();
        } else if (data?.username && isOpen) {
            form.setFieldValue("user", data?.username);
        }
    }, [isOpen, data?.username]);

    const onSubmit = async (value) => {
        try {
            await form.validateFields();
            let platform = "";
            let product = "";
            let productType = "";
            let moneyManagerAccount = "";
            if (value?.product) {
                [platform, product, productType] = value?.product?.split("_");
            }
            if (value?.moneyManagerAccount) {
                [platform, moneyManagerAccount] = value?.moneyManagerAccount?.split("_");
            }

            const getMoneyManagerAccountSettingInput = () => {
                let setting = {...value};
                delete setting?.user;
                delete setting?.product;
                delete setting?.profileImage;
                setting.profileImage = profileImage;
                if (selectedProductObj?.mamPammProvider?.provider === "easyMAM") {
                    setting = {
                        ...setting,
                        account: mode === "add" ? setting?.account : setting?.accountId,
                        easyMAM: {
                            tradingMode: setting?.easyMAM_tradingMode,
                        },
                    };
                    delete setting?.easyMAM_tradingMode;
                    delete setting?.accountId;
                }
                return setting;
            };

            if (mode === "Add") {
                if (platform === "mt5") {
                    if (selectedProductType === "moneyManager") {
                        let moneyManagerAccountSetting = getMoneyManagerAccountSettingInput();
                        const result = await createTradingAccountMt5MoneyManager({
                            product: product,
                            user: data?._id,
                            moneyManagerAccountSetting: moneyManagerAccountSetting,
                        });

                        if (result?.data?.createTradingAccountMt5MoneyManager?.__typename === "TradingAccount") {
                            setNewMoneyManagerLegalDocsProps({isOpen: true, moneyManager: result?.data?.createTradingAccountMt5MoneyManager?._id});
                        }
                    } else if (selectedProductType === "investor") {
                        await createTradingAccountMt5Investor({
                            user: data?._id,
                            amount: value?.amount,
                            moneyManagerAccount: moneyManagerAccount,
                            investorAccountSetting: {
                                moneyManagerAccount: moneyManagerAccount,
                                legalDocumentStatus: {
                                    signature: value?.signature,
                                },
                            },
                        });
                    } else {
                        await createTradingAccountMt5({
                            product: product,
                            user: data?._id,
                            balance: parseFloat(value?.balance || 0),
                        });
                    }
                } else {
                    await addTradingAccount({
                        product: product,
                        user: data?._id,
                        balance: parseFloat(value?.balance || 0),
                    });
                }
            } else {
                {
                    if (platform === "mt5") {
                        if (selectedProductType === "moneyManager") {
                            let moneyManagerAccountSetting = getMoneyManagerAccountSettingInput();
                            // console.log(moneyManagerAccountSetting);
                            await linkTradingAccountMt5MoneyManager({
                                product: product,
                                user: data?._id,
                                accountId: value?.accountId,
                                moneyManagerAccountSetting: moneyManagerAccountSetting,
                            });
                        } else if (selectedProductType === "investor") {
                            await linkTradingAccountMt5Investor({
                                user: data?._id,
                                accountId: value?.accountId,
                                moneyManagerAccount: moneyManagerAccount,
                                signature: value?.signature,
                            });
                        } else {
                            await linkTradingAccountMt5({
                                product: product,
                                user: data?._id,
                                accountId: value?.accountId,
                            });
                        }
                    } else {
                        await linkTradingAccount({
                            product: product,
                            user: data?._id,
                            accountId: value?.accountId,
                        });
                    }
                }
            }

            fetchTradingAccount()
            onCancel();
        } catch (e) {
            systemErrorNotification();
        }
    };

    const productTypeVariant = ["selfTrade", "moneyManager", "investor"];

    const modalTitle = useMemo(() => {
        let title = t(mode === "Add" ? "clientUser.addAccount" : "clientUser.linkAccount");
        switch (selectedProductType) {
            case "selfTrade":
                title += " (" + t("module.self_trade") + ")";
                break;
            case "moneyManager":
                title += " (" + t("mampamm.moneyManager") + ")";
                break;
            case "investor":
                title += " (" + t("mampamm.investor") + ")";
                break;
            default:
                break;
        }
        return title;
    }, [selectedProductType]);

    return (
        <>
            {productTypeVariant?.map((type) => {
                let accountByProductType = account?.filter((a) => a?.product?.productType?.includes(type));
                return (
                    <Row gutter={[16, 16]} className={"margin-top-0-75"}>
                        <Col span={24}>
                            <Row justify={"space-between"}>
                                <Col>
                                    <h3>{t(`product.productType_${type}`)}</h3>
                                </Col>
                                <Col>
                                    {tradingAccountPermission?.account?.link?.link && (
                                        <Button onClick={() => onClickLinkAccount(type)} className={"margin-left-auto"}>
                                            {t("clientUser.linkAccount")}
                                        </Button>
                                    )}
                                    {tradingAccountPermission?.account?.add?.add && (
                                        <Button onClick={() => onClickAddAccount(type)} className={"margin-left-0-75"}>
                                            {t("clientUser.addAccount")}
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                        {accountByProductType?.length > 0 &&
                            accountByProductType
                                ?.slice()
                                ?.sort((a, b) => b?.mode?.localeCompare(a?.mode))
                                ?.map((d) => {
                                    return <AccountDetailCard account={d} key={d?._id} />;
                                })}
                    </Row>
                );
            })}
            {isOpen && (
                <Modal
                    title={modalTitle}
                    open={isOpen}
                    onCancel={onCancel}
                    onOk={form.submit}
                    okText={t("common.submit")}
                    cancelText={t("common.cancel")}
                    okButtonProps={{loading: actionLoading}}
                    cancelButtonProps={{loading: actionLoading}}
                    width={"80%"}
                >
                    <Form form={form} onFinish={onSubmit} className="form-1 margin-top-0-75 margin-bottom-0-75">
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Space direction={"vertical"} style={{width: "100%"}}>
                                    <Form.Item
                                        name={"user"}
                                        label={t("user.username")}
                                        labelCol={labelCol}
                                        // initialValue={data?.username}
                                        rules={[{required: true}]}
                                    >
                                        <Input placeholder={t("tradingAcc.name").toString()} disabled />
                                    </Form.Item>
                                    {selectedProductType !== "investor" && (
                                        <Form.Item name={"product"} label={t("tradingAcc.product")} labelCol={labelCol} rules={[{required: true}]}>
                                            <Select
                                                options={productOptions}
                                                placeholder={t("tradingAcc.product")}
                                                onChange={handleProductChange}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    (option?.searchLabel ?? "")?.toString().toLowerCase().includes(input.toLowerCase())
                                                }
                                                loading={allAvailableProductByProductTypeLoading}
                                            />
                                        </Form.Item>
                                    )}
                                    {selectedProductType === "investor" && (
                                        <>
                                            <Form.Item
                                                name={"moneyManagerAccount"}
                                                label={t("mampamm.moneyManager")}
                                                labelCol={labelCol}
                                                rules={[{required: true}]}
                                            >
                                                <Select options={moneyManagerAccountOptions} placeholder={t("mampamm.moneyManager")} />
                                            </Form.Item>
                                            {mode === "Add" && (
                                                <Form.Item name={"amount"} label={t("common.amount")} labelCol={labelCol} rules={[{required: true}]}>
                                                    <InputNumber min={0} />
                                                </Form.Item>
                                            )}
                                        </>
                                    )}
                                    {mode !== "Add" && (
                                        <Form.Item
                                            name={"accountId"}
                                            label={t("tradingAcc.accountId")}
                                            labelCol={labelCol}
                                            rules={[{required: true}]}
                                        >
                                            <Input placeholder={t("tradingAcc.accountId").toString()} />
                                        </Form.Item>
                                    )}
                                    {selectedProductType === "investor" && (
                                        <Form.Item name={"signature"} label={t("register.signature")} labelCol={labelCol} rules={[{required: true}]}>
                                            <Input placeholder={t("register.signature").toString()} />
                                        </Form.Item>
                                    )}
                                </Space>
                            </Col>
                            {selectedProductType === "moneyManager" && (
                                <MoneyManagerAccountSettingsForm
                                    data={data}
                                    form={form}
                                    labelCol={labelCol}
                                    selectedProduct={selectedProduct}
                                    selectedProductObj={selectedProductObj}
                                    setProfileImage={setProfileImage}
                                />
                            )}
                        </Row>
                    </Form>
                </Modal>
            )}
            <NewMoneyManagerLegalDocumentsModal
                {...newMoneyManagerLegalDocsProps}
                handleCancel={() => setNewMoneyManagerLegalDocsProps({isOpen: false})}
                allAccount={account}
            />
        </>
    );
};

export default Account;
