import React from "react";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {getColumnSearchProps} from "../../../../components/table/function/filterTable";
import {formatDate} from "../../../users/_common/function";

const TranslationText = ({value}) => {
    const {t} = useTranslation();
    const displayText = t(value);
    return <span>{displayText || "-"}</span>;
};

export const pspAdjustmentTableColumns = () => {
    const {t} = useTranslation();
    return [
        {
            title: "Adjustment Date",
            dataIndex: "adjustmentDate",
            key: "adjustmentDate",
            render: (text) => <p>{formatDate(text) || "-"}</p>,
        },
        {
            title: "Field",
            dataIndex: "field",
            key: "field",
            filters: [
                {text: t("common.amount"), value: "amount"},
                {text: t("common.fee"), value: "fee"},
                {text: t("common.balance"), value: "balance"},
            ],
            render: (text) => (
                <p>
                    <TranslationText value={`common.${text}`} />
                </p>
            ),
        },
        {
            title: "PSP Name",
            dataIndex: "pspSettingName",
            key: "pspSettingName",
            ...getColumnSearchProps(),
            render: (_, record) => <p>{record?.pspSettingId?.name || "-"}</p>,
        },
        {
            title: "PSP Display Name",
            dataIndex: "pspSettingDisplayName",
            key: "pspSettingDisplayName",
            ...getColumnSearchProps(),
            render: (_, record) => <p>{record?.pspSettingId?.displayName || "-"}</p>,
        },
        {
            title: "Currency",
            dataIndex: "pspSettingCurrencyName",
            key: "pspSettingCurrencyName",
            ...getColumnSearchProps(),
            render: (_, record) => <p>{record?.pspSettingCurrencyId?.currency || "-"}</p>,
        },
        {
            title: "Amount",
            dataIndex: "convertedAmount",
            key: "convertedAmount",
            align: "right",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: "Amount (USD)",
            dataIndex: "amount",
            key: "amount",
            align: "right",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: "Details",
            dataIndex: "details",
            key: "details",
            render: (details, record) => {
                let text;
                switch (record?.type) {
                    case "deposit":
                    case "withdrawal":
                        text = `${details?.transactionNo} - ${details?.convertedAmount} | Fee: ${details?.convertedFeeCompany}`;
                        break;
                    case "settlement":
                        text = `${formatDate(details?.settlementDate)} | ${details?.convertedAmount} | Fee: ${details?.convertedFee}`;
                        break;
                    default:
                        break;
                }
                return (
                    <p>
                        <strong>
                            <TranslationText value={`common.${record?.type}`} />
                        </strong>
                        : {text}
                    </p>
                );
            },
        },
        {
            title: "Desk",
            dataIndex: "deskId",
            key: "deskId",
            ...getColumnSearchProps(),
            render: (text, record) => {
                if (record?.type === "settlement") {
                    return (
                        <div>
                            {record?.details?.desks?.map((desk, index) => (
                                <p key={index}>
                                    {desk?.name} : {desk?.percentage} %
                                </p>
                            ))}
                        </div>
                    );
                }
                return <p>{text?.name || "-"}</p>;
            },
        },
        {
            title: "Remark",
            dataIndex: "remark",
            key: "remark",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
    ];
};
