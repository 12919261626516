import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Popconfirm, Row, Space} from "antd";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import {DndContext, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {arrayMove, SortableContext, useSortable, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {restrictToVerticalAxis} from "@dnd-kit/modifiers";
import TableAntDesign from "../../../../../../../components/table/tableAntDesign";
import {columns} from "../../../function/multiTierColumns";
import EditTableUserPreferenceButton from "../../../../../../../components/table/function/editTableUserPreferenceButton";
import ExportTableButton from "../../../../../../../components/table/function/exportTable";
import MultiTierSettingForm from "./multiTierSettingForm";
import {
    getMultiTierSetting,
    getMultiTierSettingCount,
    useDeleteMultiTierSettingMutation,
    useUpdateMultiTierSettingMutation,
} from "../../../../../../../api/graphql/multiTierSetting";
import useAuthorize from "../../../../../../../_common/authorize";
import ExportDBTableButton from "./exportDBTable";
import ImportDBTableButton from "./importDBTable";
import {getIsMainMasterAdmin} from "../../../../../../../api/graphql/_common";

const MultiTierSettingTable = (props) => {
    const {permission, type} = props;
    const {t} = useTranslation();
    const [mode, setMode] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [currentEditing, setCurrentEditing] = useState<any>({});
    const [draggable, setDraggable] = useState(false);
    const [defaultRank, setDefaultRank] = useState("");

    const [loading, setIsLoading] = useState(false);
    const [multiTierSettingList, setMultiTierSettingList] = useState<any>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [crmUserRankTotalCount, setCrmUserRankTotalCount] = useState(0);
    const [isMainMasterAdmin, setIsMainMasterAdmin] = useState(false);

    const [orderBy, setOrderBy] = useState("rank_ASC");
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [filter, setFilter] = useState({});

    const {deleteMultiTierSetting, loading: deleteLoading} = useDeleteMultiTierSettingMutation();

    const {updateMultiTierSetting, loading: updateLoading} = useUpdateMultiTierSettingMutation();
    const {systemErrorNotification} = useAuthorize();

    const [dataSource, setDataSource] = useState<any>([]);

    const getMultiTierSettingListing = async () => {
        try {
            setIsLoading(true);
            const res = await Promise.all([
                await getMultiTierSetting({filter: {...filter, type}}, limit, offset, orderBy),
                await getMultiTierSettingCount({
                    filter: {...filter, type: "crmUser"},
                }),
            ]);
            if (res[0]?.getMultiTierSetting?.__typename === "MultiTierSettingArray") {
                if (type === "client") {
                    setMultiTierSettingList(
                        res[0]?.getMultiTierSetting?.data?.map((item) => {
                            return {
                                ...item,
                                originalRank: item?.rank,
                                rank: item?.rank - res[1]?.getMultiTierSettingCount,
                            };
                        })
                    );
                } else {
                    setMultiTierSettingList(res[0]?.getMultiTierSetting?.data);
                }
                setCrmUserRankTotalCount(res[1]?.getMultiTierSettingCount);
            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const getMultiTierSettingListingCount = async () => {
        try {
            setIsLoading(true);
            const res = await getMultiTierSettingCount({
                filter: {...filter, type},
            });
            if (res?.getMultiTierSettingCount) {
                setTotalCount(res?.getMultiTierSettingCount);
            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const checkIsMasterAdmin = async () => {
        let response = await getIsMainMasterAdmin();
        setIsMainMasterAdmin(response?.isMainMasterAdmin);
    };

    useEffect(() => {
        getMultiTierSettingListing();
        getMultiTierSettingListingCount();
    }, [filter, limit, offset, orderBy, isOpen]);

    useEffect(() => {
        if (offset === totalCount) setOffset(0);
    }, [totalCount]);

    useEffect(() => {
        checkIsMasterAdmin();
    }, []);

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize);
        setOffset(limit * (page - 1));
    };

    const openModal = (mode, record) => {
        setIsOpen(true);
        setMode(mode);
        setCurrentEditing(record);
    };

    const deleteRank = async (id) => {
        try {
            await deleteMultiTierSetting(id);
        } catch (e) {
            systemErrorNotification();
        } finally {
            setTimeout(() => {
                getMultiTierSettingListing();
                getMultiTierSettingListingCount();
            }, 1000);
        }
    };

    useEffect(() => {
        if (multiTierSettingList) {
            setDataSource(multiTierSettingList);
            setDraggable(true);

            const defaultRank = multiTierSettingList?.find((d) => d?.isDefaultRank);
            if (defaultRank) setDefaultRank(defaultRank?._id);
        }
    }, [multiTierSettingList]);

    const tableRow = (props) => {
        if (updateLoading || props["data-row-key"] === defaultRank) {
            return <tr {...props} />;
        }

        const {attributes, listeners, setNodeRef, transform, transition, isDragging} = useSortable({
            id: props["data-row-key"] ? props["data-row-key"] : "",
        });

        const style = {
            ...props.style,
            transform: CSS.Transform.toString(
                transform && {
                    ...transform,
                    scaleY: 1,
                }
            ),
            transition,
            cursor: "move",
            ...(isDragging
                ? {
                      position: "relative",
                      zIndex: 9999,
                  }
                : {}),
        };

        return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
    };

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 1,
            },
        })
    );

    const onDragEnd = async ({active, over}) => {
        try {
            if (over && active.id !== over?.id) {
                let tempRank =
                    type === "client" ? over.data?.current.sortable.index + 1 + crmUserRankTotalCount : over.data?.current.sortable.index + 1;
                await updateMultiTierSetting(active.id, {
                    rank: tempRank + offset,
                });
            }
        } catch (e) {
            systemErrorNotification();
        } finally {
            setTimeout(() => {
                getMultiTierSettingListing();
                getMultiTierSettingListingCount();
            }, 1000);
        }
    };

    const tableName = `Multi Tier Setting ${type}`;

    const Table = useMemo(() => {
        return (
            <TableAntDesign
                data={dataSource}
                tableName={tableName}
                component={
                    draggable
                        ? {
                              body: {
                                  row: tableRow,
                              },
                          }
                        : undefined
                }
                scroll={false}
                columns={[
                    ...columns,
                    {
                        title: t("common.action"),
                        key: "action",
                        width: 100,
                        align: "center",
                        render: (_, record: any, index) => (
                            <Space>
                                {permission?.edit?.edit && (
                                    <a style={{color: "green"}} onClick={() => openModal("Edit", record)}>
                                        <AiOutlineEdit />
                                    </a>
                                )}
                                {permission?.delete?.delete && !record?.isDefaultRank && (
                                    <Popconfirm
                                        placement="left"
                                        description={t("Are you sure to delete this rank?")}
                                        title={t(`Delete Rank`)}
                                        onConfirm={() => {
                                            deleteRank(record?._id);
                                        }}
                                        okText={t("Yes")}
                                        okButtonProps={{loading: deleteLoading}}
                                        okType="danger"
                                        cancelText={t("No")}
                                    >
                                        <a>
                                            <AiOutlineDelete style={{color: "red"}} />
                                        </a>
                                    </Popconfirm>
                                )}
                            </Space>
                        ),
                    },
                ]}
                size={"small"}
                loading={loading}
                filter={setFilter}
                actionPermission={permission?.edit?.edit || permission?.delete?.delete}
                order={setOrderBy}
                limit={limit}
                setOffset={setOffset}
                pagination={{
                    pageSize: limit,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                    total: totalCount,
                    showTotal: (total) => <p>{t(`Total ${total} items`)}</p>,
                }}
            />
        );
    }, [dataSource, loading, permission, limit, totalCount, updateLoading]);

    return (
        <div className={"margin-top-0-75"}>
            <Row justify={"space-between"}>
                <h4 className="margin-bottom-0">{t(`user.${type}`)}</h4>
                <Row justify={"end"} className={"margin-bottom-0-75"}>
                    <Space>
                        <EditTableUserPreferenceButton tableName={tableName} tableColumn={columns} />
                        <ExportTableButton tableData={multiTierSettingList} tableColumn={columns} tableName={tableName} />
                        {permission?.add?.add && <Button onClick={() => openModal("Add", {})}>{t("Add Rank")}</Button>}
                        {type === "crmUser" && isMainMasterAdmin && <ImportDBTableButton tableName={"multitiersetting"} />}
                        {type === "crmUser" && isMainMasterAdmin && <ExportDBTableButton tableName={"multitiersetting"} />}
                    </Space>
                </Row>
            </Row>
            {dataSource?.length > 0 && permission?.edit?.edit ? (
                <DndContext onDragEnd={onDragEnd} sensors={sensors} modifiers={[restrictToVerticalAxis]}>
                    <SortableContext
                        items={dataSource?.length > 0 ? dataSource?.map((i) => i._id)?.filter((item) => item !== null) : []}
                        strategy={verticalListSortingStrategy}
                    >
                        {Table}
                    </SortableContext>
                </DndContext>
            ) : (
                <>{Table}</>
            )}
            {(permission?.add?.add || permission?.edit?.edit) && (
                <MultiTierSettingForm mode={mode} type={type} isOpen={isOpen} setIsOpen={setIsOpen} item={currentEditing} totalRank={totalCount} />
            )}
        </div>
    );
};

export default MultiTierSettingTable;
