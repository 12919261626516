import {Col, DatePicker, Form, InputNumber, Row, Select} from "antd";
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import FormErrors from "./formErrors";
import moment from "moment";

const SelectDurationFormItem = (props) => {
    const {
        layout,
        size,
        label,
        name,
        externalErrorMessage,
        onChange,
        hint,
        disabled,
        required,
        options,
        mode,
        showSearch,
        showArrow,
        allowClear,
        placeholder,
        filterOption,
        optionFilterProp,
        labelCol,
        wrapperCol,
        style,
        value,
        labelAlign,
        colon,
        optionLabelProp,
        getPopupContainer,
        endDateValue,
        onEndDateValueChange,
        daysValue,
        onDaysValueChange,
        endDateName,
        daysName,
        disabledDate,
        disabledDateTime,
        requiredStar
    } = props;

    const {
        register,
        watch,
        setValue,
        formState: {touchedFields, isSubmitted, errors},
    } = useFormContext();

    const errorMessage = FormErrors.errorMessage(name, errors, touchedFields, isSubmitted, externalErrorMessage);

    const formHelperText = errorMessage || hint;

    useEffect(() => {
        register(name);
    }, [register, name]);

    useEffect(() => {
        if (value) {
            setValue(name, value, {shouldValidate: true});
        }
    }, [value]);

    return (
        <Form.Item
            {...layout}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            label={requiredStar? <><span className="label-asterisk">*</span>{label}</>:label}
            labelAlign={labelAlign}
            colon={colon}
            required={required}
            validateStatus={errorMessage ? "error" : "success"}
            help={errorMessage}
            labelWrap
        >
            <Row>
                <Col xs={watch(name) === "days"? 14 :watch(name) === "endDate"? 14 :24 }>
                    <Select
                        id={name}
                        mode={mode}
                        options={options}
                        value={watch(name)}
                        optionFilterProp={optionFilterProp}
                        optionLabelProp={optionLabelProp}
                        onChange={(e) => {
                            setValue(name, e, {shouldValidate: true});
                            onChange && onChange(e);
                        }}
                        filterOption={filterOption}
                        placeholder={placeholder}
                        showSearch={showSearch}
                        showArrow={showArrow}
                        allowClear={allowClear}
                        disabled={disabled}
                        style={style}
                        size={size}
                        getPopupContainer={getPopupContainer || undefined}
                        // getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    />
                </Col>
            {   watch(name) === "endDate" && 
                <Col xs = {10}>
                    <div style={{float: "right"}}>
                        <DatePicker
                            className="rangeBox"
                            name={endDateName}
                            value={endDateValue}
                            onChange={(val) => {
                                setValue(endDateName, val, {shouldValidate: true});
                                onEndDateValueChange && onEndDateValueChange(val);
                            }}
                            style={style || {width: "100%"}}
                            getPopupContainer={getPopupContainer}
                            disabled={disabled}
                            disabledDate={disabledDate}
                            disabledTime={disabledDateTime}
                            allowClear={false}
                        />
                    </div>
                </Col>
            }
            {
              watch(name) === "days" && 
              <Col xs = {10}>
                <div style={{float: "right"}}>
                    <InputNumber
                        className="rangeBox"
                        name={daysName}
                        value={daysValue}
                        onChange={(value) => {
                            setValue(daysName, value, {shouldValidate: true});
                            onDaysValueChange && onDaysValueChange(value);
                        }}
                        disabled={disabled}
                    />
                </div>
              </Col>
            }
            </Row>
        </Form.Item>
    );
};

SelectDurationFormItem.defaultProps = {
    required: false,
    disabled: false,
    colon: false,
    size: "middle",
    labelCol: {span: 24, offset: 0},
};

SelectDurationFormItem.propTypes = {
    label: PropTypes.string,
    labelAlign: PropTypes.string,
    wrapperCol: PropTypes.object,
    name: PropTypes.string.isRequired,
    externalErrorMessage: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    endDateName: PropTypes.string,
    endDateValue: PropTypes.any,
    onEndDateValueChange: PropTypes.func,
    daysName: PropTypes.string,
    daysValue: PropTypes.string,
    onDaysValueChange: PropTypes.func,
    disabled: PropTypes.bool,
    layout: PropTypes.object,
    hint: PropTypes.string,
    options: PropTypes.array,
    showSearch: PropTypes.bool,
    showArrow: PropTypes.bool,
    allowClear: PropTypes.bool,
    placeholder: PropTypes.string,
    mode: PropTypes.string,
    filterOption: PropTypes.func,
    style: PropTypes.object,
    value: PropTypes.any,
    optionFilterProp: PropTypes.string,
    optionLabelProp: PropTypes.string,
    disabledDate: PropTypes.object,
    disabledDateTime: PropTypes.object,
    getPopupContainer: PropTypes.any,
    requiredStar: PropTypes.bool
};

export default SelectDurationFormItem;
