import React, {useEffect, useMemo, useState} from "react";
import {useSetRecoilState} from "recoil";
import {Button, Form, Modal, Row} from "antd";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import {FormProvider, useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import SelectFormItem from "../../../../../../../../components/form/selectFormItem";
import {horizontalFormLayout} from "../../../../../../../../components/form/formWrapper";
import yupFormSchema from "../../../../../../../../components/yup/yupFormSchema";
import {getMissionDropdown, useAddUserExtraMissionMutation} from "../../../../../../../../api/graphql/mission";
import {notificationMessage} from "../../../../../../../../../recoil_state";
import {useTranslation} from "react-i18next";

const MissionInvitationModal = (props) => {
    const {t} = useTranslation()
    const {isOpen, close, data, getAvailableUserMissionFunction} = props;
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [missionDropdownData, setMissionDropdownData] = useState<any>([]);
    const [dataLoading, setDataLoading] = useState<boolean>(false);
    const {addUserExtraMission, loading: addLoading} = useAddUserExtraMissionMutation();

    const [initialValues] = useState(() => ({
        missionId: null,
    }));

    const yupSchema = yup.object().shape({
        missionId: yupFormSchema.string("missionId", {required: true}),
    });

    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
        defaultValues: initialValues,
    });

    const getMissionDropdownData = async () => {
        try {
            setDataLoading(true);
            const res = await getMissionDropdown();
            if (res?.getMissionDropdown?.__typename === "MissionArray") {
                setMissionDropdownData(res?.getMissionDropdown?.data);
            }
        } catch (error) {
        } finally {
            setDataLoading(false);
        }
    };

    const onSubmit = async () => {
        try {
            const formValues = form.getValues();
            // let missionCode = missionDropdownData.find((m) => m?._id === formValues?.missionId)?.missionCode;
            let input = {...formValues, user: data?._id};
            const response = await addUserExtraMission(input);
            if (response?.data?.addUserExtraMission?.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("task.task_approved"),
                    key: "addUserExtraMission",
                });
            } else if (response?.data?.addUserExtraMission?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.data?.addUserExtraMission?.errKey),
                    key: "addUserExtraMission",
                });
            } else {
                close();
                getAvailableUserMissionFunction()
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.data?.addUserExtraMission?.msg),
                    key: "addUserExtraMission",
                });
            }
        } catch (error) {
        }
    };

    const labelCol = {span: 8, offset: 0};

    const options = useMemo(() => {
        return missionDropdownData?.map((m: any) => ({label: m?.displayName?.[0]?.content, value: m?._id}));
    }, [missionDropdownData]);

    useEffect(() => {
        getMissionDropdownData();
    }, []);

    return (
        <Modal title={t("mission.missionInvitation")} open={isOpen} width={"80%"} footer={null} getContainer="#clientDetail" onCancel={close}>
            <FormProvider {...form}>
                <Form onFinish={form.handleSubmit(onSubmit)} className="create-report-form-1" labelWrap={true}>
                    <SelectFormItem
                        name={"missionId"}
                        label={t("module.mission")}
                        placeholder={t("Mission")}
                        layout={horizontalFormLayout}
                        mode={"single"}
                        labelCol={labelCol}
                        labelAlign="left"
                        options={options}
                        getPopupContainer={(triggerNode) => triggerNode.parentElement}
                        disabled={addLoading || dataLoading}
                    />
                    <Row justify="end" className="margin-top-0-5">
                        <Button onClick={close} icon={<CloseOutlined/>} className="cancelBtn" disabled={addLoading || dataLoading}>
                            {t("common.cancel")}
                        </Button>
                        <Button
                            htmlType="submit"
                            icon={<SaveOutlined/>}
                            className="button-submit-1"
                            disabled={addLoading || dataLoading}
                            loading={addLoading || dataLoading}
                        >
                            {t("common.submit")}
                        </Button>
                    </Row>
                </Form>
            </FormProvider>
        </Modal>
    );
};

export default MissionInvitationModal;
