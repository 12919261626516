import React from "react";
import CommissionRuleSetting_1 from "./view/layout1";
import {useRecoilValue} from "recoil";
import {selfPermissionObj} from "../../../../../recoil_state";

const CommissionRuleSetting = () => {
    let layout = 1;
    let component;
    const permission = useRecoilValue<any>(selfPermissionObj)

    switch (layout) {
        case 1:
            component = (
                <CommissionRuleSetting_1 permission={permission?.commission}/>
            );
            break;

        default:
            component = (
                <CommissionRuleSetting_1 permission={permission?.commission}/>
            );
            break;
    }

    return component;
}

export default CommissionRuleSetting