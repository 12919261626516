import React, {useEffect, useState} from "react";
import {Col, Row, Spin} from "antd";
import {getTradingAccountDetail} from "../../../../../../../../api/graphql/tradingAccount";
import {formatDateTimeTimezone} from "../../../../../../../../function/_common";

const AccountTransaction = ({record}) => {
    const task = record?.task
    const [account, setAccount] = useState<any>("")
    const tradingAccountDetail = getTradingAccountDetail(account)

    useEffect(() => {
        if (task?.result?.account) {
            setAccount(task?.result?.account)
        }
    }, [record])

    const clarifyTransactionType = (type, deposit) => {
        let keyword
        if (deposit) {
            switch (type) {
                case "manual":
                    keyword = "Manual Deposit"
                    break;
                case "negativeBalanceProtection":
                    keyword = "Negative Balance Protection"
                    break;
                case "ewallet":
                    keyword = "Deposit via E-Wallet"
                    break;
            }
        } else {
            switch (type) {
                case "manual":
                    keyword = "Manual Withdrawal"
                    break;
                case "ewallet":
                    keyword = "Withdrawal to E-Wallet"
                    break;
            }
        }

        return keyword

    }

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={6}>Account ID</Col>
                    <Col xs={24}
                         md={18}>{tradingAccountDetail?.data?.getTradingAccountDetail?.accountId ? tradingAccountDetail?.data?.getTradingAccountDetail?.accountId :
                        <Spin/>}</Col>

                    <Col xs={24} md={6}>Account User</Col>
                    <Col xs={24}
                         md={18}>{tradingAccountDetail?.data?.getTradingAccountDetail?.user?.username ? tradingAccountDetail?.data?.getTradingAccountDetail?.user?.username :
                        <Spin/>}</Col>

                    <Col xs={24} md={6}>Amount</Col>
                    <Col xs={24} md={18}>{task?.result?.amount}</Col>

                    <Col xs={24} md={6}>Type</Col>
                    <Col xs={24} md={18}>{task?.result?.type}</Col>

                    <Col xs={24} md={6}>{task?.result?.type === "withdrawal" ? "Withdrawal Type:" : "Deposit Type:"}</Col>
                    <Col xs={24}
                         md={18}>{task?.result?.type === "withdrawal" ? clarifyTransactionType(task?.result?.withdrawalType, false) : clarifyTransactionType(task?.result?.depositType, true)}</Col>

                    <Col xs={24} md={6}>Comment</Col>
                    <Col xs={24} md={18}>{task?.result?.comment}</Col>

                    <Col xs={24} md={6}>Created Time:</Col>
                    <Col xs={24} md={18}>{formatDateTimeTimezone(task?.result?.createdAt)}</Col>

                    <Col xs={24} md={6}>Requested By</Col>
                    <Col xs={24} md={18}>{task?.requestedBy?.username}</Col>
                </Row>
            </Col>
        </Row>
    )

}

export default AccountTransaction