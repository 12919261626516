import {gql, useMutation, useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {ApolloClientQuery} from "./_apolloClient";

const GET_VOUCHER_PROGRAM_COUNT = gql`
    query getVoucherProgramCount($filter: VoucherProgramSearchInput) {
        getVoucherProgramCount(filter: $filter)
    }
`;

const getVoucherProgramCount = ({filter}) => {
    try {
        const {loading, error, data} = useQuery(GET_VOUCHER_PROGRAM_COUNT, {
            variables: {
                filter: {
                    name: filter?.name || null,
                },
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_VOUCHER_PROGRAM = gql`
    query getVoucherProgram($filter: VoucherProgramSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
        getVoucherProgram(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on VoucherProgramArray {
                data {
                    _id
                    id
                    name
                    displayName {
                        content
                        language
                    }
                    description {
                        content
                        language
                    }
                    termAndConditionEnabled
                    termAndCondition {
                        content
                        language
                    }
                    rules {
                        content
                        language
                    }
                    rewards {
                        rewardType
                        reward {
                            ... on Voucher {
                                _id
                                id
                                name
                            }
                            ... on VoucherGroup {
                                _id
                                id
                                name
                            }
                        }
                        voucherExpiryType
                        voucherExpiryValue
                        voucherExpiryDate
                        voucherDurationType
                        voucherDurationValue
                        voucherDurationDate
                        voucherStartDate
                        maxRedeem
                        isFixedReward
                    }
                    networkType
                    network {
                        _id
                        id
                        username
                    }
                    exclude {
                        _id
                        id
                        username
                    }
                    voucherCodeType
                    voucherCode
                    crmUserRank {
                        _id
                        id
                        name
                    }
                    voucherMode
                    voucherCodeDigits
                    maximumRedemption
                    voucherProgramStartDate
                    voucherProgramEndDate
                    availability
                    rewardValue
                    imageLight {
                        fileList
                        path
                    }
                    imageDark {
                        fileList
                        path
                    }
                    defaultImageLight {
                        fileList
                        path
                    }
                    defaultImageDark {
                        fileList
                        path
                    }
                    isDeleted
                    createdAt
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    updatedAt
                    voucherProgramTotalClaimed
                    voucherProgramTotalRedeemed
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getVoucherProgram = ({filter}, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data, refetch} = useQuery(GET_VOUCHER_PROGRAM, {
            variables: {
                filter: filter,
                limit: limit,
                offset: offset,
                orderBy: orderBy,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        const refreshQuery = () => {
            refetch();
        };

        return {loading, error, data, refreshQuery};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_EXISTING_VOUCHER_CODE = gql`
    query getExistingVoucherCode {
        getExistingVoucherCode {
            ... on VoucherCodeArray {
                voucherCode
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getExistingVoucherCode = () => {
    try {
        const {loading, error, data} = useQuery(GET_EXISTING_VOUCHER_CODE, {
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_VOUCHER_PROGRAM_DROPDOWN = gql`
    query getVoucherProgramDropdown {
        getVoucherProgramDropdown {
            ... on VoucherProgramArray {
                data {
                    _id
                    id
                    name
                    displayName {
                        content
                        language
                    }
                    description {
                        content
                        language
                    }
                    termAndConditionEnabled
                    termAndCondition {
                        content
                        language
                    }
                    rules {
                        content
                        language
                    }
                    rewards {
                        rewardType
                        reward {
                            ... on Voucher {
                                _id
                                id
                                name
                            }
                            ... on VoucherGroup {
                                _id
                                id
                                name
                            }
                        }
                        voucherExpiryType
                        voucherExpiryValue
                        voucherExpiryDate
                        voucherDurationType
                        voucherDurationValue
                        voucherDurationDate
                        maxRedeem
                        isFixedReward
                    }
                    voucherCodeType
                    voucherCode
                    voucherMode
                    voucherCodeDigits
                    maximumRedemption
                    voucherProgramStartDate
                    voucherProgramEndDate
                    availability
                    rewardValue
                    imageLight {
                        fileList
                        path
                    }
                    imageDark {
                        fileList
                        path
                    }
                    defaultImageLight {
                        fileList
                        path
                    }
                    defaultImageDark {
                        fileList
                        path
                    }
                    isDeleted
                    createdAt
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    updatedAt
                    voucherProgramTotalClaimed
                    voucherProgramTotalRedeemed
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getVoucherProgramDropdown = () => {
    try {
        const {loading, error, data, refetch} = useQuery(GET_VOUCHER_PROGRAM_DROPDOWN, {
            variables: {},
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        const refreshQuery = () => {
            refetch();
        };

        return {loading, error, data, refreshQuery};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_USER_VOUCHER_PROGRAM = gql`
    query getUserVoucherProgram($filter: UserVoucherProgramSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
        getUserVoucherProgram(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on UserVoucherProgramArray {
                data {
                    _id
                    id
                    voucherProgramId {
                        _id
                        id
                        name
                        displayName {
                            content
                            language
                        }
                        description {
                            content
                            language
                        }
                        termAndConditionEnabled
                        termAndCondition {
                            content
                            language
                        }
                        rules {
                            content
                            language
                        }
                        rewards {
                            rewardType
                            reward {
                                ... on Voucher {
                                    _id
                                    id
                                    name
                                }
                                ... on VoucherGroup {
                                    _id
                                    id
                                    name
                                }
                            }
                            voucherExpiryType
                            voucherExpiryValue
                            voucherExpiryDate
                            voucherDurationType
                            voucherDurationValue
                            voucherDurationDate
                            maxRedeem
                            isFixedReward
                        }
                        networkType
                        voucherCodeType
                        voucherCode
                        voucherMode
                        voucherCodeDigits
                        maximumRedemption
                        voucherProgramStartDate
                        voucherProgramEndDate
                        availability
                        rewardValue
                        imageLight {
                            fileList
                            path
                        }
                        imageDark {
                            fileList
                            path
                        }
                        defaultImageLight {
                            fileList
                            path
                        }
                        defaultImageDark {
                            fileList
                            path
                        }
                        isDeleted
                        createdAt
                        createdBy {
                            username
                        }
                        updatedBy {
                            username
                        }
                        updatedAt
                    }
                    user {
                        username
                    }
                    voucherCode
                    isVoucherIssued
                    isClaimWithVoucherCode
                    isVoucherProgramExpired
                    isIssuedByCrmUser
                    voucherIssuedAt
                    browser
                    device
                    ipAddress
                    issuedBy {
                        _id
                        id
                        username
                    }
                    createdBy {
                        _id
                        id
                        username
                    }
                    updatedBy {
                        _id
                        id
                        username
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getUserVoucherProgram = async (filter, limit?, offset?, orderBy?) => {
    try {
        const variables = {
            filter: filter,
            limit: limit,
            offset: offset,
            orderBy: orderBy,
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        };
        const execute = await ApolloClientQuery(GET_USER_VOUCHER_PROGRAM, variables, false);
        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};


const GET_USER_VOUCHER_PROGRAM_COUNT = gql`
    query getUserVoucherProgramCount($filter: UserVoucherProgramSearchInput) {
        getUserVoucherProgramCount(filter: $filter)
    }
`;

const getUserVoucherProgramCount = ({filter}) => {
    try {
        const {loading, error, data} = useQuery(GET_USER_VOUCHER_PROGRAM_COUNT, {
            variables: {
                filter: filter
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_USER_VOUCHER_PROGRAM = gql`
    mutation addUserVoucherProgram($input: UserVoucherProgramInput) {
        addUserVoucherProgram(input: $input) {
            ... on UserVoucherProgram {
                _id
                id
                voucherProgramId {
                    _id
                    id
                    name
                    displayName {
                        content
                        language
                    }
                    description {
                        content
                        language
                    }
                }
                user {
                    _id
                    username
                }
                createdAt
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useAddUserVoucherProgramMutation = (): {
    addUserVoucherProgram: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [addUserVoucherProgramMutation, {data, loading, error}] = useMutation(ADD_USER_VOUCHER_PROGRAM);

        const addUserVoucherProgram = (input) => {
            return addUserVoucherProgramMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {addUserVoucherProgram}}) {
                    const typename = addUserVoucherProgram?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(addUserVoucherProgram?.errKey),
                            key: "addUserVoucherProgram",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(addUserVoucherProgram?.msg),
                            key: "addUserVoucherProgram",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Add Successfully"),
                            key: "addUserVoucherProgram",
                        });
                    }
                },
                // refetchQueries: [GET_VOUCHER_PROGRAM, GET_VOUCHER_PROGRAM_COUNT],
            });
        };

        return {addUserVoucherProgram, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            addUserVoucherProgram: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const ADD_VOUCHER_PROGRAM = gql`
    mutation addVoucherProgram($input: VoucherProgramInput) {
        addVoucherProgram(input: $input) {
            ... on VoucherProgram {
                _id
                id
                name
                displayName {
                    content
                    language
                }
                description {
                    content
                    language
                }
                termAndConditionEnabled
                termAndCondition {
                    content
                    language
                }
                rules {
                    content
                    language
                }
                rewards {
                    rewardType
                    reward {
                        ... on Voucher {
                            _id
                            id
                            name
                        }
                        ... on VoucherGroup {
                            _id
                            id
                            name
                        }
                    }
                    voucherExpiryType
                    voucherExpiryValue
                    voucherExpiryDate
                    voucherDurationType
                    voucherDurationValue
                    voucherDurationDate
                    voucherStartDate
                    maxRedeem
                    isFixedReward
                }
                networkType
                network {
                    _id
                    id
                    username
                }
                exclude {
                    _id
                    id
                    username
                }
                voucherCodeType
                voucherCode
                crmUserRank {
                    _id
                    id
                    name
                }
                voucherMode
                voucherCodeDigits
                maximumRedemption
                voucherProgramStartDate
                voucherProgramEndDate
                availability
                rewardValue
                imageLight {
                    fileList
                    path
                }
                imageDark {
                    fileList
                    path
                }
                defaultImageLight {
                    fileList
                    path
                }
                defaultImageDark {
                    fileList
                    path
                }
                isDeleted
                createdAt
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useAddVoucherProgramMutation = (): {
    addVoucherProgram: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [addVoucherProgramMutation, {data, loading, error}] = useMutation(ADD_VOUCHER_PROGRAM);

        const addVoucherProgram = (input) => {
            return addVoucherProgramMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {addVoucherProgram}}) {
                    const typename = addVoucherProgram?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(addVoucherProgram?.errKey),
                            key: "addVoucherProgram",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(addVoucherProgram?.msg),
                            key: "addVoucherProgram",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Add Successfully"),
                            key: "addVoucherProgram",
                        });
                    }
                },
                refetchQueries: [GET_VOUCHER_PROGRAM, GET_VOUCHER_PROGRAM_COUNT],
            });
        };

        return {addVoucherProgram, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            addVoucherProgram: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const UPDATE_VOUCHER_PROGRAM = gql`
    mutation updateVoucherProgram($id: String!, $input: VoucherProgramInput) {
        updateVoucherProgram(id: $id, input: $input) {
            ... on VoucherProgram {
                _id
                id
                name
                displayName {
                    content
                    language
                }
                description {
                    content
                    language
                }
                termAndConditionEnabled
                termAndCondition {
                    content
                    language
                }
                rules {
                    content
                    language
                }
                rewards {
                    rewardType
                    reward {
                        ... on Voucher {
                            _id
                            id
                            name
                        }
                        ... on VoucherGroup {
                            _id
                            id
                            name
                        }
                    }
                    voucherExpiryType
                    voucherExpiryValue
                    voucherExpiryDate
                    voucherDurationType
                    voucherDurationValue
                    voucherDurationDate
                    voucherStartDate
                    maxRedeem
                    isFixedReward
                }
                networkType
                network {
                    _id
                    id
                    username
                }
                exclude {
                    _id
                    id
                    username
                }
                voucherCodeType
                voucherCode
                crmUserRank {
                    _id
                    id
                    name
                }
                voucherMode
                voucherCodeDigits
                maximumRedemption
                voucherProgramStartDate
                voucherProgramEndDate
                availability
                rewardValue
                imageLight {
                    fileList
                    path
                }
                imageDark {
                    fileList
                    path
                }
                defaultImageLight {
                    fileList
                    path
                }
                defaultImageDark {
                    fileList
                    path
                }
                isDeleted
                createdAt
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useUpdateVoucherProgramMutation = (): {
    updateVoucherProgram: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const {t} = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updateVoucherProgramMutation, {data, loading, error}] = useMutation(UPDATE_VOUCHER_PROGRAM);

        const updateVoucherProgram = (id, input) => {
            return updateVoucherProgramMutation({
                variables: {
                    id: id,
                    input: input,
                },
                update(cache, {data: {updateVoucherProgram}}) {
                    const typename = updateVoucherProgram?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateVoucherProgram?.errKey),
                            key: "updateVoucherProgram",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateVoucherProgram?.msg),
                            key: "updateVoucherProgram",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Successfully"),
                            key: "updateVoucherProgram",
                        });
                    }
                },
                refetchQueries: [GET_VOUCHER_PROGRAM, GET_VOUCHER_PROGRAM_COUNT],
            });
        };

        return {updateVoucherProgram, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            updateVoucherProgram: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const DELETE_VOUCHER_PROGRAM = gql`
    mutation deleteVoucherProgram($id: String!) {
        deleteVoucherProgram(id: $id) {
            ... on VoucherProgram {
                _id
                id
                isDeleted
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useDeleteVoucherProgramMutation = (): {
    deleteVoucherProgram: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [deleteVoucherProgramMutation, {data, loading, error}] = useMutation(DELETE_VOUCHER_PROGRAM);

        const deleteVoucherProgram = (id) => {
            return deleteVoucherProgramMutation({
                variables: {
                    id: id,
                },
                update(cache, {data: {deleteVoucherProgram}}) {
                    const typename = deleteVoucherProgram?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(deleteVoucherProgram?.errKey),
                            key: "deleteVoucherProgram",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(deleteVoucherProgram?.msg),
                            key: "deleteVoucherProgram",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Delete Successfully"),
                            key: "deleteVoucherProgram",
                        });
                    }
                },
                refetchQueries: [GET_VOUCHER_PROGRAM, GET_VOUCHER_PROGRAM_COUNT],
            });
        };

        return {deleteVoucherProgram, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            deleteVoucherProgram: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const USER_SELECT_VOUCHER = gql`
    mutation userSelectVoucher($id: String!, $input: UserClaimVoucherProgramIput) {
        userSelectVoucher(id: $id, input: $input) {
            ... on UserVouchersArray {
                data {
                    _id
                }
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;

async function userSelectVoucher(id, input) {
    const variables = {
        id: id,
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(USER_SELECT_VOUCHER, variables, true);

    return execute;
}

export {
    getVoucherProgram,
    getVoucherProgramCount,
    getExistingVoucherCode,
    getVoucherProgramDropdown,
    getUserVoucherProgram,
    getUserVoucherProgramCount,
    useAddUserVoucherProgramMutation,
    useAddVoucherProgramMutation,
    useUpdateVoucherProgramMutation,
    useDeleteVoucherProgramMutation,
    userSelectVoucher,
};
