import {useState} from "react";
import {getColorSetting} from "../../../../../api/restful/arena/color";

export const useGetColorSetting = () => {
    const [data, setData] = useState<any>(null)

    const fetchColorSetting = async () => {
        try {
            const {data} = await getColorSetting()

            console.log(data)
            setData(data)
        } catch (e) {

        }
    }

    return [data, fetchColorSetting] as const
}