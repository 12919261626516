import axios from "axios";
import {getConfigByName} from "../../../../config";
import {BRAND_NAME} from "../../../../config/brandVariable";

const url = getConfigByName(BRAND_NAME).automationURL
const config = {headers: {"Content-Type": "text/plain"}}

export async function getCustomTrigger() {
    try {
        const filter = {filter: {}};
        return await axios.post(`${url}/api/custom_trigger/get`, JSON.stringify(filter), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export async function createCustomTrigger (input) {
    try {
        return await axios.post(`${url}/api/custom_trigger/create`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export async function updateCustomTrigger (input) {
    try {
        return await axios.post(`${url}/api/custom_trigger/update`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export async function deleteCustomTrigger (input) {
    try {
        return await axios.post(`${url}/api/custom_trigger/delete`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}