import React, {memo} from "react";
import {Col, Row, Typography} from "antd";
import {CloseOutlined, EllipsisOutlined} from "@ant-design/icons";
import GoogleChrome from "../../../../../../assets/image/googleChrome";

const {Paragraph} = Typography;

const PreviewWindows = (props) => {
    const {title, body, iconPath, imagePath} = props;

    let content = (
        <div>
            {imagePath && (
                <div>
                    <img className="notisImage" src={imagePath} alt="notisImage"/>
                </div>
            )}
            <div className="notisTextBox">
                <Row align="middle" gutter={10} className="margin-bottom-0-75">
                    <Col span={20}>
                        <Row align="middle">
                            <GoogleChrome className="googleChromeIcon"/>
                            Google Chrome
                        </Row>
                    </Col>
                    <Col span={2}>
                        <EllipsisOutlined/>
                    </Col>
                    <Col span={2}>
                        <CloseOutlined/>
                    </Col>
                </Row>
                <Row align="top" gutter={10}>
                    <Col span={4}>
                        {iconPath && <img src={iconPath} alt="icon" style={{width: "100%"}}/>}
                    </Col>
                    <Col span={20}>
                        <Row>
                            <Col span={24}>
                                <Paragraph ellipsis={{rows: 1}} className="notisTitle">
                                    {title}
                                </Paragraph>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Paragraph ellipsis={{rows: 4}} className="notisBody">
                                    {body}
                                </Paragraph>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );

    return (
        <div className="previewWindowsContainer">
            <div className="notisContainer">
                <div className="notisContentContainer">{content}</div>
            </div>
        </div>
    );
};

export default memo(PreviewWindows);
