import React, {useEffect, useState} from "react";
import RewardSetting_1 from "./view/layout1";
import {useGetRewardSetting} from "./function/useGetQueryHooks";
import {useGetGiftSetting} from "../giftSetting/function/useGetQueryHooks";
import {useGetBadgeSetting} from "../badgeSetting/function/useGetQueryHooks";
import {useGetBoxSetting} from "../boxSetting/function/useGetQueryHooks";

const RewardSetting = () => {
    let layout = 1
    let component
    const [rewards, fetchRewardsSetting] = useGetRewardSetting()
    const [rewardOption, setRewardOption] = useState<any>([])

    const [gifts, fetchGiftSetting] = useGetGiftSetting()
    const [badges, badgeLoading, fetchBadges] = useGetBadgeSetting()
    const [boxes, loading, fetchBoxes] = useGetBoxSetting()

    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get("type");
    const id = searchParams.get("id");


    useEffect(() => {
        fetchRewardsSetting()

        fetchGiftSetting()
        fetchBadges()
        fetchBoxes()
    }, [])

    const formatOptions = (data, type) => {
        return data?.map(d => ({
            value: d?._id,
            label: d?.displayName?.find(d => d?.language === localStorage.getItem("language"))?.content || d?.name,
            type: type
        })) || []
    }

    useEffect(() => {
        setRewardOption([...formatOptions(gifts, "gifts"), ...formatOptions(badges, "badges"), ...formatOptions(boxes, "box")])
    }, [gifts, badges]);

    const props = {rewards, fetch: fetchRewardsSetting, rewardOption, type, id}

    switch (layout) {
        case 1:
            component = <RewardSetting_1 {...props}/>
            break

        default:
            component = <RewardSetting_1 {...props}/>
            break
    }

    return component;
}

export default RewardSetting