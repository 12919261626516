import {getColumnSearchProps} from "../../../../../components/table/function/filterTable";
import React, {useState} from "react";
import {Tag} from "antd";


const SeeMoreComponent = ({ text }) => {
    const [view, setView] = useState(false);

    let tagsToShow = text?.slice(0, view ? text.length : 3);

    const renderTag = (tag, i) => (
        <Tag
            key={i}
            style={{ margin: 2 }}
            color={i === 0 ? "blue" : "default"}
        >
            {tag?.username}
        </Tag>
    );

    const renderSeeMoreTag = () => (
        <Tag
            style={{ margin: 2, cursor: "pointer" }}
            color={"green"}
            onClick={() => setView(true)}
        >
            ...
        </Tag>
    );

    return (
        <div>
            {tagsToShow?.map(renderTag)}
            {!view && text?.length > 3 && renderSeeMoreTag()}
        </div>
    );
};


export const columnsCommGroup = [
    {
        title: "Comm. Setting",
        dataIndex: "commissionSetting",
        key: "commissionSetting",
        ...getColumnSearchProps(),
        render: (text) => <p>{text?.name}</p>,
    },
    {
        title: "Group Name",
        dataIndex: "name",
        key: "name",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },

    {
        title: "Network",
        dataIndex: "network",
        key: "network",
        ...getColumnSearchProps(),
        render: (text) => <SeeMoreComponent text={text} />,
    }
]