import {gql, useMutation, useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {clearTypeNameFromInputList} from "./_commonFunction";
import {notificationMessage} from "../../../recoil_state";
import {ApolloClientQuery} from "./_apolloClient";

const GET_EMAIL_TEMPLATE_COUNT = gql`
    query getEmailTemplateCount($filter: EmailTemplateSearchInput) {
        getEmailTemplateCount(filter: $filter)
    }
`;

const getEmailTemplateCount = ({filter}) => {
    try {
        const {loading, error, data} = useQuery(GET_EMAIL_TEMPLATE_COUNT, {
            variables: {
                filter: {
                    displayName: filter?.displayName || null,
                    subject: filter?.subject || null,
                    type: filter?.type || null,
                },
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_EMAIL_TEMPLATE_BY_ID = gql`
    query getEmailTemplateByID($id: String!) {
        getEmailTemplateByID(id: $id) {
            ... on EmailTemplate {
                _id
                id
                name
                displayName
                from {
                    _id
                    id
                    name
                    email
                }
                subject
                displaySubjectLanguage {
                    label
                    language
                }
                htmlObject {
                    html
                    language
                    head
                    attachment {
                        id
                        name
                    }
                }
                type
                emailType {
                    _id
                    id
                    name
                }
                isFixedEmail
                taggedToNewsletter {
                    _id
                    id
                    name
                }
                isActive
                isDeleted
                isNotImplemented
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getEmailTemplateById = (id) => {
    try {
        const {loading, error, data} = useQuery(GET_EMAIL_TEMPLATE_BY_ID, {
            variables: {
                id: id,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const getEmailTemplateByIdAsync = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            GET_EMAIL_TEMPLATE_BY_ID,
            {
                id: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_EMAIL_TEMPLATE = gql`
    query getEmailTemplate($filter: EmailTemplateSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
        getEmailTemplate(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on EmailTemplateArray {
                data {
                    id
                    _id
                    name
                    displayName
                    from {
                        _id
                        id
                        name
                        email
                    }
                    subject
                    displaySubjectLanguage {
                        label
                        language
                    }
                    htmlObject {
                        html
                        language
                        head
                        attachment {
                            id
                            name
                        }
                    }
                    type
                    emailType {
                        _id
                        id
                        name
                    }
                    isFixedEmail
                    taggedToNewsletter {
                        _id
                        id
                        name
                    }
                    isActive
                    isDeleted
                    isNotImplemented
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getEmailTemplate = ({filter}, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data} = useQuery(GET_EMAIL_TEMPLATE, {
            variables: {
                filter: {
                    displayName: filter?.displayName || null,
                    subject: filter?.subject || null,
                    type: filter?.type || null,
                },
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const getEmailTemplateAsync = async (filter, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_EMAIL_TEMPLATE,
            {
                filter: filter,
                limit: limit,
                offset: offset,
                orderBy: orderBy,
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};


const ADD_EMAIL_TEMPLATE = gql`
    mutation addEmailTemplate($input: EmailTemplateInput) {
        addEmailTemplate(input: $input) {
            ... on EmailTemplate {
                _id
                id
                name
                displayName
                from {
                    _id
                    id
                    name
                    email
                }
                subject
                displaySubjectLanguage {
                    label
                    language
                }
                htmlObject {
                    html
                    language
                    head
                    attachment {
                        id
                        name
                    }
                }
                type
                emailType {
                    _id
                    id
                    name
                }
                isFixedEmail
                taggedToNewsletter {
                    _id
                    id
                    name
                }
                isActive
                isDeleted
                isNotImplemented
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useAddEmailTemplateMutation = (): {
    addEmailTemplate: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [addEmailTemplateMutation, {data, loading, error}] = useMutation(ADD_EMAIL_TEMPLATE);

        const addEmailTemplate = (input) => {
            return addEmailTemplateMutation({
                variables: {
                    input: {
                        displayName: input?.displayName || null,
                        from: input?.from || null,
                        subject: input?.subject || null,
                        displaySubjectLanguage: input?.displaySubjectLanguage || null,
                        emailType: input?.emailType || null,
                        isFixedEmail: input?.isFixedEmail ? true : false,
                        taggedToNewsletter: input?.taggedToNewsletter || null,
                        htmlObject: input?.htmlObject || null,
                        isActive: input?.isActive ? true : false,
                        type: input?.type || "custom",
                    },
                },
                update(cache, {data: {addEmailTemplate}}) {
                    const typename = addEmailTemplate?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(addEmailTemplate?.errKey),
                            key: "addEmailTemplate",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(addEmailTemplate?.msg),
                            key: "addEmailTemplate",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Add Successfully"),
                            key: "addEmailTemplate",
                        });
                    }
                },
                refetchQueries: [GET_EMAIL_TEMPLATE, GET_EMAIL_TEMPLATE_COUNT],
            });
        };

        return {addEmailTemplate, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {addEmailTemplate: null, data: null, loading: false, error: err};
    }
};

const useAddEmailTemplateMutationWithoutNotification = (): {
    addEmailTemplate: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const [addEmailTemplateMutation, {data, loading, error}] = useMutation(ADD_EMAIL_TEMPLATE);

        const addEmailTemplate = (input) => {
            return addEmailTemplateMutation({
                variables: {
                    input: {
                        displayName: input?.displayName || null,
                        from: input?.from || null,
                        subject: input?.subject || null,
                        displaySubjectLanguage: input?.displaySubjectLanguage || null,
                        emailType: input?.emailType || null,
                        isFixedEmail: input?.isFixedEmail ? true : false,
                        taggedToNewsletter: input?.taggedToNewsletter || null,
                        htmlObject: input?.htmlObject || null,
                        isActive: input?.isActive ? true : false,
                        type: input?.type || "custom",
                    },
                },
            });
        };

        return {addEmailTemplate, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {addEmailTemplate: null, data: null, loading: false, error: err};
    }
};

const UPDATE_EMAIL_TEMPLATE = gql`
    mutation updateEmailTemplate($id: String!, $input: EmailTemplateInput) {
        updateEmailTemplate(id: $id, input: $input) {
            ... on EmailTemplate {
                _id
                id
                name
                displayName
                from {
                    _id
                    id
                    name
                    email
                }
                subject
                displaySubjectLanguage {
                    label
                    language
                }
                htmlObject {
                    html
                    language
                    head
                    attachment {
                        id
                        name
                    }
                }
                type
                emailType {
                    _id
                    id
                    name
                }
                isActive
                isDeleted
                isNotImplemented
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useUpdateEmailTemplateMutation = (): {
    updateEmailTemplate: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const {t} = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updateEmailTemplateMutation, {data, loading, error}] = useMutation(UPDATE_EMAIL_TEMPLATE);

        const updateEmailTemplate = (id, input) => {
            return updateEmailTemplateMutation({
                variables: {
                    id: id,
                    input: {
                        displayName: input?.displayName || null,
                        from: input?.from || null,
                        subject: input?.subject || null,
                        displaySubjectLanguage: input?.displaySubjectLanguage || null,
                        isFixedEmail: !!input?.isFixedEmail,
                        taggedToNewsletter: input?.taggedToNewsletter || null,
                        emailType: input?.emailType || null,
                        isActive: !!input?.isActive,
                        htmlObject: clearTypeNameFromInputList(input?.htmlObject) || null,
                    },
                },
                update(cache, {data: {updateEmailTemplate}}) {
                    const typename = updateEmailTemplate?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateEmailTemplate?.errKey),
                            key: "updateEmailTemplate",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateEmailTemplate?.msg),
                            key: "updateEmailTemplate",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Successfully"),
                            key: "updateEmailTemplate",
                        });
                    }
                },
                // refetchQueries: [GET_EMAIL_TEMPLATE, GET_EMAIL_TEMPLATE_COUNT],
            });
        };

        return {updateEmailTemplate, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            updateEmailTemplate: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const useUpdateEmailTemplateMutationWithoutNotification = (): {
    updateEmailTemplate: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const [updateEmailTemplateMutation, {data, loading, error}] = useMutation(UPDATE_EMAIL_TEMPLATE);

        const updateEmailTemplate = (id, input) => {
            return updateEmailTemplateMutation({
                variables: {
                    id: id,
                    input: {
                        displayName: input?.displayName || null,
                        from: input?.from || null,
                        subject: input?.subject || null,
                        displaySubjectLanguage: input?.displaySubjectLanguage || null,
                        isFixedEmail: input?.isFixedEmail ? true : false,
                        taggedToNewsletter: input?.taggedToNewsletter || null,
                        emailType: input?.emailType || null,
                        isActive: input?.isActive ? true : false,
                        htmlObject: clearTypeNameFromInputList(input?.htmlObject) || null,
                    },
                },
            });
        };

        return {updateEmailTemplate, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            updateEmailTemplate: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const DELETE_EMAIL_TEMPLATE = gql`
    mutation deleteEmailTemplate($id: String!) {
        deleteEmailTemplate(id: $id) {
            ... on EmailTemplate {
                _id
                id
                isDeleted
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useDeleteEmailTemplateMutation = (): {
    deleteEmailTemplate: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [deleteEmailTemplateMutation, {data, loading, error}] = useMutation(DELETE_EMAIL_TEMPLATE);

        const deleteEmailTemplate = (id) => {
            return deleteEmailTemplateMutation({
                variables: {
                    id: id,
                },
                update(cache, {data: {deleteEmailTemplate}}) {
                    const typename = deleteEmailTemplate?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(deleteEmailTemplate?.errKey),
                            key: "deleteEmailTemplate",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(deleteEmailTemplate?.msg),
                            key: "deleteEmailTemplate",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Delete Successfully"),
                            key: "deleteEmailTemplate",
                        });
                    }
                },
                refetchQueries: [GET_EMAIL_TEMPLATE, GET_EMAIL_TEMPLATE_COUNT],
            });
        };

        return {deleteEmailTemplate, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            deleteEmailTemplate: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const TEST_SEND_EMAIL_TEMPLATE = gql`
    mutation sendEmail($input: EmailTemplateSendInput!) {
        sendEmail(input: $input) {
#            ... on EmailSendStatus {
#                status
#            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on EmailTemplateBatchLog {
                _id
            }
        }
    }
`;

const useSendEmailMutation = (): {
    sendEmail: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [sendEmailMutation, {data, loading, error}] = useMutation(TEST_SEND_EMAIL_TEMPLATE);

        const sendEmail = (input) => {
            return sendEmailMutation({
                variables: {
                    input: {
                        name: input?.name || null,
                        to: input?.to || null,
                        cc: input?.cc || null,
                        bcc: input?.bcc || null,
                        language: input?.language || "en",
                        sendUserType: input?.sendUserType || null
                    },
                },
                update(cache, {data: {sendEmail}}) {
                    const typename = sendEmail?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(sendEmail?.errKey),
                            key: "sendEmail",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(sendEmail?.msg),
                            key: "sendEmail",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Sent Email Successfully"),
                            key: "sendEmail",
                        });
                    }
                },
            });
        };

        return {sendEmail, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {sendEmail: null, data: null, loading: false, error: err};
    }
};

const useSendEmailMutationWithoutNotification = (): {
    sendEmail: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const [sendEmailMutation, {data, loading, error}] = useMutation(TEST_SEND_EMAIL_TEMPLATE);

        const sendEmail = (input) => {
            return sendEmailMutation({
                variables: {
                    input: {
                        name: input?.name || null,
                        to: input?.to || null,
                        cc: input?.cc || null,
                        bcc: input?.bcc || null,
                        language: input?.language || "en",
                        sendUserType: input?.sendUserType || null
                    },
                },
            });
        };

        return {sendEmail, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {sendEmail: null, data: null, loading: false, error: err};
    }
};

const GET_AVAILABLE_EMAIL_TEMPLATE = gql`
    query getAvailableEmailTemplate($filter: EmailTemplateAvailableSearchInput) {
        getAvailableEmailTemplate(filter: $filter) {
            ... on EmailTemplateArray {
                data {
                    id
                    _id
                    name
                    displayName
                    from {
                        _id
                        id
                        name
                        email
                    }
                    subject
                    displaySubjectLanguage {
                        label
                        language
                    }
                    htmlObject {
                        html
                        language
                        head
                        attachment {
                            id
                            name
                        }
                    }
                    type
                    isActive
                    emailType {
                        _id
                        id
                        name
                    }
                    isFixedEmail
                    taggedToNewsletter {
                        _id
                        id
                        name
                    }
                    isDeleted
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getAvailableEmailTemplate = (filter) => {
    try {
        const {loading, error, data} = useQuery(GET_AVAILABLE_EMAIL_TEMPLATE, {
            variables: {
                filter: filter,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const getAvailableEmailTemplateAsync = async (filter) => {
    try {
        const execute = await ApolloClientQuery(
            GET_AVAILABLE_EMAIL_TEMPLATE,
            {
                filter: filter,
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

export {
    getEmailTemplateCount,
    getEmailTemplateById,
    getEmailTemplateByIdAsync,
    getEmailTemplate,
    getEmailTemplateAsync,
    useAddEmailTemplateMutation,
    useAddEmailTemplateMutationWithoutNotification,
    useUpdateEmailTemplateMutation,
    useUpdateEmailTemplateMutationWithoutNotification,
    useDeleteEmailTemplateMutation,
    useSendEmailMutation,
    useSendEmailMutationWithoutNotification,
    getAvailableEmailTemplate,
    getAvailableEmailTemplateAsync
};
