import React, {useState} from "react";
import {Button, Form, Row, Segmented, Select, Space} from "antd";
import {useTranslation} from "react-i18next";
import EditTableUserPreferenceButton from "../../../../../components/table/function/editTableUserPreferenceButton";
import ExportTableButton from "../../../../../components/table/function/exportTable";
import {useBatchUpdate, useBatchUpdateReferrer, useUpdateUserDomainMutation} from "../../../../../api/graphql/user";
import {useRecoilValue} from "recoil";
import {brandDetailsState, deskOptionsState, userOptionsState} from "../../../_common/state";
import useAuthorize from "../../../../../_common/authorize";
import {useIsMobile} from "../../../../../_common/layout";
import { getBrandDomainDropdown } from "../../../../../api/graphql/brandSetting";

const CrmUserTopBar = (props) => {
    const {
        setIsAddUserOpen,
        batchUpdateRecord,
        setBatchUpdateRecord,
        filterType,
        setFilterType,
        setFilterTypeId,
        tableName,
        exportData,
        exportColumn,
        permissionUser,
        isShowingArchive,
        setIsShowingArchive,
        refetchUserData
    } = props
    const [updateActionXML, setUpdateActionXML] = useState<any>()
    const {t} = useTranslation()

    const {batchUpdate} = useBatchUpdate()
    const {batchUpdateReferrer} = useBatchUpdateReferrer()
    const {updateUserDomain} = useUpdateUserDomainMutation()
    const {data: brandDomainDropdown} = getBrandDomainDropdown()
    const {systemErrorNotification} = useAuthorize()

    const userOptions = useRecoilValue(userOptionsState)
    const deskOptions = useRecoilValue(deskOptionsState)

    const batchUpdateIds = batchUpdateRecord?.map(d => d?.parentId)
    const isMobile = useIsMobile(768);

    const batchEditDesk = () => {
       try{
           const batchEditDeskOnSubmit = async (value) => {
               await batchUpdate(
                   batchUpdateIds,
                   value,
                   "crmUser"
               )

               refetchUserData()
               clear()
           }

           setUpdateActionXML(
               <Form
                   className={"margin-bottom-0-75 margin-top-0-75"}
                   onFinish={(value) => batchEditDeskOnSubmit(value)}>
                   <div className={"margin-bottom-0-75"}>
                       <Form.Item label={t("user.desk")} name={"desk"}>
                           <Select
                               showSearch
                               style={{width: 200}}
                               options={deskOptions}
                           />
                       </Form.Item>
                   </div>
                   <Form.Item>
                       <Button type="primary" htmlType="submit">
                           {t("common.submit")}
                       </Button>
                   </Form.Item>
               </Form>
           )
       }catch (e) {
           console.log(e)
           systemErrorNotification()
       }
    }

    const batchEditReferrer = () => {
        try{
            const batchEditReferrerOnSubmit = async (value) => {
                await batchUpdateReferrer(
                    batchUpdateIds,
                    value?.referrer,
                    "crmUser"
                )

                refetchUserData()
                clear()
            }

            setUpdateActionXML(
                <Form
                    className={"margin-bottom-0-75 margin-top-0-75"}
                    onFinish={(value) => batchEditReferrerOnSubmit(value)}>
                    <div className={"margin-bottom-0-75"}>
                        <Form.Item label={t("user.referrer")} name={"referrer"}>
                            <Select
                                showSearch
                                style={{width: 200}}
                                options={userOptions}
                                optionFilterProp={"label"}
                                optionLabelProp="selectedLabel"
                            />
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {t("common.submit")}
                        </Button>
                    </Form.Item>
                </Form>
            )
        }catch (e) {
            console.log(e)
            systemErrorNotification()
        }
    }

    const batchEditDomain = () => {
        try{
            const batchUpdateUserDomainOnSubmit = async (value) => {
                await updateUserDomain(
                    batchUpdateIds,
                    value?.domain
                )

                refetchUserData()
                clear()
            }

            setUpdateActionXML(
                <Form
                    className={"margin-bottom-0-75 margin-top-0-75"}
                    onFinish={(value) => batchUpdateUserDomainOnSubmit(value)}>
                    <div className={"margin-bottom-0-75"}>
                        <Form.Item label={t("brandSetting.domain")} name={"domain"}>
                            <Select
                                showSearch
                                style={{width: 200}}
                                options={brandDomainDropdown?.getBrandDomainDropdown?.data}
                                optionFilterProp={"label"}
                                optionLabelProp="selectedLabel"
                            />
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {t("common.submit")}
                        </Button>
                    </Form.Item>
                </Form>
            )
        }catch (e) {
            console.log(e)
            systemErrorNotification()
        }

    }

    const clear = () => {
        setUpdateActionXML(<></>)
        setBatchUpdateRecord([])
    }

    return (
        <>
            <Row className={"margin-bottom-0-75 display-flex"} wrap>
                {permissionUser?.crmUser?.batchAction && batchUpdateRecord?.length > 0 ? (
                    <div>
                        <Space>
                            {permissionUser?.crmUser?.edit?.edit && <Button onClick={() => batchEditDesk()}>{t("common.batchEditDesk")}</Button>}
                            {permissionUser?.crmUser?.edit?.referrer && <Button onClick={() => batchEditReferrer()}>{t("common.batchChangeReferrer")}</Button>}
                            {permissionUser?.crmUser?.edit?.edit && <Button onClick={() => batchEditDomain()}>{t("common.editUserDomain")}</Button>}
                            <Button onClick={() => clear()}>{t("common.cancel")}</Button>
                        </Space>
                        {updateActionXML}
                    </div>
                ) : (
                    <>
                        <Space wrap>
                            {
                                permissionUser?.crmUser?.view?.archive &&
                                <Segmented
                                    options={[
                                        {label: t("user.active"), value: "active"},
                                        {label: t("user.archive"), value: "archive"},
                                    ]}
                                    defaultValue={"active"}
                                    onChange={value => setIsShowingArchive(value !== "active")}
                                />
                            }
                            <Select
                                value={filterType || "all"}
                                style={{width: 200}}
                                onChange={(value) => {
                                    setFilterType(value)
                                    if (value === "all") {
                                        setFilterTypeId(null)
                                    }
                                }}
                                options={[
                                    {value: 'all', label: t('common.all')},
                                    {value: 'user', label: t('common.filterBasedOnUserTree')},
                                ]}
                            />
                            {
                                filterType === "user" && (
                                    <Select
                                        style={{width: 250}}
                                        onChange={(value) => {
                                            setFilterTypeId(value)
                                        }}
                                        options={userOptions}
                                        optionFilterProp={"label"}
                                        optionLabelProp="selectedLabel"
                                        showSearch
                                        
                                    />
                                )
                            }
                        </Space>
                        <Space className={!isMobile ? "margin-left-auto" : "margin-top-0-75"} wrap>
                            <EditTableUserPreferenceButton
                                tableName={tableName}
                                tableColumn={exportColumn}
                            />
                            <ExportTableButton
                                tableData={exportData}
                                tableColumn={exportColumn}
                                tableName={tableName}
                            />
                            {permissionUser?.crmUser?.add?.add && !isShowingArchive && <Button type={"primary"} onClick={() => setIsAddUserOpen(true)}>{t("common.add")}</Button>}
                        </Space>
                    </>
                )}
            </Row>
        </>
    )
};

export default CrmUserTopBar