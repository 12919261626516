import React from "react";
import {getServerSetting} from "../../../../api/graphql/serverSetting";
import Symbols_1 from "./view/layout1";
import {useRecoilValue} from "recoil";
import {selfPermissionObj} from "../../../../../recoil_state";

function Symbols() {
  let layout = 1;
  let component;

  const permission = useRecoilValue<any>(selfPermissionObj)

  const { data, loading, error } = getServerSetting({ filter: null });

  switch (layout) {
    case 1:
      component = (
          <Symbols_1
              data={data}
              loading={loading}
              error={error}
              permission={permission?.accountSetting}
          />
      );
      break;

    default:
      component = (
          <Symbols_1
              data={data}
              loading={loading}
              error={error}
              permission={permission?.accountSetting}
          />
      );
      break;
  }

  return component;
}

export default Symbols;
