import React, {useEffect, useState} from "react"
import {Button, Col, Divider, Row, Switch} from "antd"
import {useTranslation} from "react-i18next"
import useAuthorize from "../../../../../../../_common/authorize"
import {getMamPammModuleSetting, updateMamPammModuleSetting} from "../../../../../../../api/graphql/mamPammModuleSetting"
import {notificationMessage} from "../../../../../../../../recoil_state"
import {useSetRecoilState} from "recoil"

const MamPammModuleSetting = (props) => {
    const {setCustomizeSubmitButton} = props
    const {t} = useTranslation()
    const setNotificationMessage = useSetRecoilState(notificationMessage)

    const [input, setInput] = useState<any>({})

    const {systemErrorNotification} = useAuthorize()

    const getData = async () => {
        try {
            const res = await getMamPammModuleSetting()
            if (res?.getMamPammModuleSetting?.__typename === "MamPammModuleSetting") {
                let settings = {...res?.getMamPammModuleSetting}
                delete settings?.__typename
                setInput(settings)
            }
        } catch (error) {
            systemErrorNotification()
        } finally {
        }
    }

    const handleSwitchChange = (field, value) => {
        try {
            setInput((prevState) => ({...prevState, [field]: value}))
        } catch (error) {
            systemErrorNotification()
        } finally {
        }
    }

    const onSubmit = async () => {
        try {
            const res = await updateMamPammModuleSetting(input)
            const response = res?.updateMamPammModuleSetting
            if (response?.__typename === "MamPammModuleSetting") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Update Successfully"),
                    key: "updateMamPammModuleSetting",
                })
            } else if (response?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.errKey),
                    key: "updateMamPammModuleSetting",
                })
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(res?.updateMamPammModuleSetting?.msg),
                    key: "updateMamPammModuleSetting",
                })
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        setCustomizeSubmitButton(
            <Button onClick={() => onSubmit()} type={"primary"}>
                {t("common.submit")}
            </Button>
        )
    }, [input])

    return (
        <>
            <Divider />
            <Row style={{padding: 16}}>
                <Col span={18}>
                    <label>{t("Auto Approve Deposit (Task)")}</label>
                </Col>
                <Col span={6}>
                    <Switch className="float-right" checked={input?.autoApproveDepositInTask} onChange={(value) => handleSwitchChange("autoApproveDepositInTask", value)} />
                </Col>
            </Row>
            <Row style={{padding: 16}}>
                <Col span={18}>
                    <label>{t("Auto Approve Withdrawal (Task)")}</label>
                </Col>
                <Col span={6}>
                    <Switch className="float-right" checked={input?.autoApproveWithdrawalInTask} onChange={(value) => handleSwitchChange("autoApproveWithdrawalInTask", value)} />
                </Col>
            </Row>
        </>
    )
}

export default MamPammModuleSetting
