import {gql, useMutation, useQuery} from '@apollo/client'
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";

const GET_QUOTE_SETTING = gql`
  query getQuoteSetting{
    getQuoteSetting{
      ... on QuoteSetting {
          _id
          id
          favouriteSymbol
        }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getQuoteSetting = () => {
    try {
        const { loading, error, data } = useQuery(GET_QUOTE_SETTING, {
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return { loading, error, data };
    } catch (err: any) {
        console.error(err);
        return { data: null, loading: false, error: err };
    }
};

const UPDATE_QUOTE_SETTING = gql`
mutation updateQuoteSetting($input: QuoteSettingInput) {
  updateQuoteSetting(input: $input) {
    ...on QuoteSetting {
      _id
      favouriteSymbol
    }
    ...on BaseError {
      errKey
      errMsg
    }
    ...on CrmTaskApproval {
      msg
    }
  }
}
`;

const useUpdateQuoteSettingMutation = (): { updateQuoteSetting: any, data: any, loading: boolean, error: any } => {
    try{
        const { t } = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updateQuoteSettingMutation, { data, loading, error }] = useMutation(UPDATE_QUOTE_SETTING);

        const updateQuoteSetting = (input) => {
            return updateQuoteSettingMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {updateQuoteSetting}}) {
                    const typename = updateQuoteSetting?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateQuoteSetting?.errKey),
                            key: "updateQuoteSetting",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateQuoteSetting?.msg),
                            key: "updateQuoteSetting",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t(`Update Quote Setting Successfully`),
                            key: "updateQuoteSetting",
                        });
                    }
                },
            });
        };

        return { updateQuoteSetting, data, loading, error }
    }
    catch(err: any){
        console.error(err);
        return { updateQuoteSetting: null, data: null, loading: false, error: err };
    }
}

export {
    getQuoteSetting,
    useUpdateQuoteSettingMutation
}