import {Cascader, Form, Select} from 'antd';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import FormErrors from './formErrors';

const { SHOW_CHILD, SHOW_PARENT } = Cascader;

const CascaderFormItem = (props) => {
    const {
        layout,
        label,
        name,
        externalErrorMessage,
        onChange,
        hint,
        disabled,
        required,
        options,
        showSearch,
        showArrow,
        allowClear,
        placeholder,
        multiple,
        labelCol,
        wrapperCol,
        showChild,
        value,
        colon
    } = props

    const {
        register,
        watch,
        setValue,
        formState: { touchedFields, isSubmitted, errors }
    } = useFormContext()

    const errorMessage = FormErrors.errorMessage(
        name,
        errors,
        touchedFields,
        isSubmitted,
        externalErrorMessage
    )

    const formHelperText = errorMessage || hint

    useEffect(() => {
        register(name)
    }, [register, name])

    useEffect(() => {
        if (value) {
            setValue(name, value, {shouldValidate: true});
        }
    }, [value]);

    return (
        <Form.Item
            {...layout}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            labelWrap={true}
            label={label}
            required={required}
            validateStatus={errorMessage?'error':'success'}
            help={formHelperText}
            colon={colon}
        >
            <Cascader
                id={name}
                multiple={multiple}
                options={options}
                value={watch(name)}
                onChange={(e)=>{
                    setValue(name, e, {shouldValidate: true})
                    onChange && onChange(e)
                }}
                placeholder={placeholder}
                showSearch={showSearch}
                showArrow={showArrow}
                allowClear={allowClear}
                disabled={disabled}
                showCheckedStrategy={showChild ? SHOW_CHILD : SHOW_PARENT}
            />
        </Form.Item>
    )
}

CascaderFormItem.defaultProps = {
    required: false,
    disabled: false,
    labelCol: {span: 6, offset: 0},
    wrapperCol: {span: 18, offset: 0},
    colon: false
}

CascaderFormItem.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    externalErrorMessage: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    layout: PropTypes.object,
    hint: PropTypes.string,
    options: PropTypes.array,
    showSearch: PropTypes.bool,
    showArrow: PropTypes.bool,
    allowClear: PropTypes.bool,
    placeholder: PropTypes.string,
    multiple: PropTypes.bool,
    showChild: PropTypes.bool,
    value: PropTypes.array
}

export default CascaderFormItem