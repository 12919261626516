import React, {useEffect} from "react";
import Clients_1 from "./view/layout1";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useRecoilValue} from "recoil";
import {fieldListState, selfPermissionObj} from "../../../../../recoil_state";
import {useGetProfileInformationSetting} from "../../_common/userQueryHooks";

const Clients = () => {
    const {t} = useTranslation();
    let layout = 1;
    let component;
    const tableName = t("user.client")

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const userNetworkId = searchParams.get('referrer');
    const commRule = searchParams.get('commRule');

    const permission = useRecoilValue<any>(selfPermissionObj);
    const fieldList = useRecoilValue<any>(fieldListState)

    const {profileInformation, fetchProfileInformationSetting} = useGetProfileInformationSetting()

    useEffect(() => {
        fetchProfileInformationSetting({type: "client"})
    }, []);

    switch (layout) {
        case 1:
            component = (
                <Clients_1
                    tableName={tableName}
                    userNetworkId={userNetworkId}
                    commRule={commRule}
                    fieldList={fieldList}
                    profileInformation={profileInformation}
                    permissionUser={permission?.user}
                />
            );
            break;

        default:
            component = (
                <Clients_1
                    tableName={tableName}
                    userNetworkId={userNetworkId}
                    commRule={commRule}
                    fieldList={fieldList}
                    profileInformation={profileInformation}
                    permissionUser={permission?.user}
                />
            );
            break;
    }

    return component;
};

export default Clients;
