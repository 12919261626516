import React from "react";
import {Col, Row, Spin} from "antd";
import {useTranslation} from "react-i18next";
import {getUserById} from "../../../../../../../api/graphql/user";
import TaskClientDetails from "../../clientDetails";
import {getTradingAccountDetail} from "../../../../../../../api/graphql/tradingAccount";
import {formatMoney} from "../../../../../../accounts/tradingAccount/detail/function";
import AccountDetailCard from "../../../../../../accounts/tradingAccount/detail/view/layout1/component/accountDetailCard";
import {formatDateTimeTimezone} from "../../../../../../../function/_common";

function TradingAccountTransactionLayout_1(props) {
    const {data, mode} = props

    const {t} = useTranslation()

    const tradingAccountDetail = getTradingAccountDetail(data?.payload?.input?.account)

    const userDetail = getUserById(tradingAccountDetail?.data?.getTradingAccountDetail?.user?._id)

    const clarifyTransactionType = (type, deposit) => {
        let keyword
        if (deposit) {
            switch (type) {
                case "manual":
                    keyword = "Manual Deposit"
                    break;
                case "negativeBalanceProtection":
                    keyword = "Negative Balance Protection"
                    break;
                case "ewallet":
                    keyword = "Deposit via E-Wallet"
                    break;
            }
        } else {
            switch (type) {
                case "manual":
                    keyword = "Manual Withdrawal"
                    break;
                case "ewallet":
                    keyword = "Withdrawal to E-Wallet"
                    break;
            }
        }

        return keyword

    }

    return (
        <div id={"tradingAccountLayout"}>
            <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                    <Spin spinning={!tradingAccountDetail?.data?.getTradingAccountDetail?._id}>
                        <AccountDetailCard data={tradingAccountDetail?.data?.getTradingAccountDetail}
                                           traderId={tradingAccountDetail?.data?.getTradingAccountDetail?.traderId}
                                           viewOnly={true}/>
                    </Spin>
                    <br/>
                    {
                        userDetail?.data?.getUserByID && (
                            <TaskClientDetails data={userDetail?.data?.getUserByID}/>
                        )
                    }
                </Col>

                <Col xs={24} md={12}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <h3>{t("Account Transaction Details")}</h3>
                        </Col>
                        <Col span={6}>
                            {t("task.account_id")}:
                        </Col>
                        <Col span={18}>
                            {tradingAccountDetail?.data?.getTradingAccountDetail?.accountId}
                        </Col>
                        <Col span={6}>
                            {t("task.account_user")}:
                        </Col>
                        <Col span={18}>
                            {tradingAccountDetail?.data?.getTradingAccountDetail?.user?.username}
                        </Col>
                        <Col span={6}>
                            {t("task.amount")}:
                        </Col>
                        <Col span={18}>
                            {formatMoney(data?.payload?.input?.amount)}
                        </Col>
                        <Col span={6}>
                            {t("task.type")}:
                        </Col>
                        <Col span={18}>
                            {data?.payload?.input?.type}
                        </Col>
                        <Col span={6}>
                            {data?.payload?.input?.type === "withdrawal" ? t("task.withdrawal_type") : t("task.deposit_type")}:
                        </Col>
                        <Col span={18}>
                            {data?.payload?.input?.type === "withdrawal" ? clarifyTransactionType(data?.payload?.input?.withdrawalType, false) : clarifyTransactionType(data?.payload?.input?.depositType, true)}
                        </Col>
                        <Col span={6}>
                            {t("task.comment")}:
                        </Col>
                        <Col span={18}>
                            {data?.payload?.input?.comment || "-"}
                        </Col>
                        <Col span={6}>
                            {t("task.created_time")}:
                        </Col>
                        <Col span={18}>
                            {formatDateTimeTimezone(data?.createdAt)}
                        </Col>
                        <Col span={6}>
                            {t("task.requested_by")}:
                        </Col>
                        <Col span={18}>
                            {data?.requestedBy?.username}
                        </Col>
                    </Row>
                </Col>

            </Row>


        </div>
    )

}

export default TradingAccountTransactionLayout_1