import React, {useEffect} from "react";
import AccountVerification_1 from "./view/layout1";
import {useGeneralListFunction} from "./function/generalListFunction";
import "./css/index.css"

const AccountVerification = () => {
    const {getUserVerificationConfig,getGeneralListCountry, getGeneralListDocumentGroup} = useGeneralListFunction()
    let layout = 1;
    let component;

    useEffect(() => {
        getUserVerificationConfig()
        getGeneralListCountry()
        getGeneralListDocumentGroup()
    },[])

    switch (layout) {
        case 1:
            component = (
                <AccountVerification_1 />
            );
            break;

        default:
            component = (
                <AccountVerification_1 />
            );
            break;
    }

    return component;
}

export default AccountVerification