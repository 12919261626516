import React, {useEffect, useState} from "react";
import {Tabs} from "antd";
import {useTranslation} from "react-i18next";
import MultiTierSetting from "./component/multiTierSetting";
import CommGroup from "./component/commGroup";
import CommSetting from "./component/commSetting";
import {getCommissionSetting} from "../../../../../../api/graphql/commission";
import useAuthorize from "../../../../../../_common/authorize";
import RuleSetting from "./component/ruleSetting";
import {useRecoilState} from "recoil";
import {leverageProfile} from "../../../../spreadGroup/state";
import {commissionSettingRefresh} from "../../state";

const CommissionRuleSetting_1 = (props) => {
    const {permission} = props
    const {t} = useTranslation()
    const {systemErrorNotification} = useAuthorize()
    const [data, setData] = useState([])
    const [commissionSettingRefreshState, setCommissionSettingRefreshState] = useRecoilState<any>(commissionSettingRefresh);

    const preload = async () => {
        try {
            const response = await getCommissionSetting({
                isEnabled: true
            }, null, null, "position_ASC", null, null, null)

            const settings: any = []
            for (const setting of response?.getCommissionSetting?.data) {
                settings.push({
                    label: t(setting?.label),
                    key: setting._id,
                    children: <RuleSetting commSetting={setting}/>
                })
            }

            setData(settings)


        } catch (e) {
            systemErrorNotification()
        }finally {
            setCommissionSettingRefreshState(false)
        }
    }

    useEffect(()=>{
        preload()
    },[])

    useEffect(()=>{
        if(commissionSettingRefreshState){
            preload()
        }
    },[commissionSettingRefreshState])

    const tabItems: any = [
        {
            label: t("commission.multiTierSetting"),
            key: "multiTier",
            children: <MultiTierSetting permission={permission?.setting}/>
        },
        {
            label: t("commission.commGroup"),
            key: "commGroup",
            children: <CommGroup/>
        },
        {
            label: t("commission.commissionSetting"),
            key: "setting",
            children: <CommSetting preloadSetting={preload}/>
        },
        ...data
    ]

    return (
        <Tabs items={tabItems}/>
    )
}

export default CommissionRuleSetting_1