import React, {useEffect, useState} from "react";
import {getArchiveUser, getArchiveUserCount, getUser, getUserCount} from "../../../../../../api/graphql/user";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import CrmUserTopBar from "../../component/crmUserTopBar";
import CrmUserAddForm from "../../component/crmUserAddForm";
import {useTranslation} from "react-i18next";
import {formatDate, formatDateTime, formatSensitiveFields, renderColumns} from "../../../../_common/function";
import {Columns} from "../../function/tableColumn";
import {Button, Col, Spin} from "antd";
import {useNavigate} from "react-router-dom";
import {getIsMainMasterAdmin} from "../../../../../../api/graphql/_common";
import {useGetUser} from "../../../../clients/_general/function/useQueryHooks";
import {formatDateTimeTimezone} from "../../../../../../function/_common";

function CrmUsers_1(props) {
    const {tableName, profileInformation, permissionUser, roleId, commRule} = props;
    const {t} = useTranslation();
    const [orderBy, setOrderBy] = useState<any>("registrationDate_DSC");
    const [limit, setLimit] = useState<any>(20);
    const [offset, setOffset] = useState<any>(0);
    const [filter, setFilter] = useState<any>({});
    const [isAddUserOpen, setIsAddUserOpen] = useState(false);
    const [filterType, setFilterType] = useState<any>("");
    const [filterTypeId, setFilterTypeId] = useState<any>("");
    const [formattedExportData, setFormattedExportData] = useState<any>([])
    const [formattedData, setFormattedData] = useState<any>([])
    const [isMainMasterAdmin, setIsMainMasterAdmin] = useState(false)
    const [batchUpdateRecord, setBatchUpdateRecord] = useState<any>([]);
    const [isShowingArchive, setIsShowingArchive] = useState(false)
    const navigate = useNavigate()
    const oriColumn = Columns()

    const [userData, userTotal, userLoading, fetchUserData, refetchUserData] = useGetUser()

    useEffect(() => {
            fetchUserData({
                filter: {
                    roleIds: roleId ? [roleId] : null,
                    commRule: commRule,
                    ...filter,
                    userType: "crmUser",
                    userNetwork: filterTypeId,
                    isDeleted: isShowingArchive
                }
            }, limit, offset, orderBy)
    }, [commRule, filter, filterTypeId, limit, offset, orderBy, isShowingArchive]);

    const isClearFilterVisible = Object.keys(filter)?.length > 0 || filterTypeId
    const resetFilters = () => {
        setFilter({})
        setFilterType("")
        setFilterTypeId("")
    }

    const [column, setColumn] = useState<any>();
    useEffect(() => {
        if (profileInformation?.length > 0) {
            renderColumns(profileInformation, setColumn, oriColumn, "crm-user", t);
        }
    }, [profileInformation]);

    useEffect(() => {
        async function getMainMasterAdmin() {
            let response = await getIsMainMasterAdmin()
            setIsMainMasterAdmin(response?.isMainMasterAdmin)
            return response
        }

        getMainMasterAdmin()
    }, []);

    const isLoading = userLoading
    const total = userTotal

    useEffect(() => {
        setOffset(0)
    }, [isShowingArchive])

    useEffect(() => {
        if (roleId) setFilter(prevState => ({...prevState, roleIds: roleId ? [roleId] : null}))
    }, [roleId])

    useEffect(() => {
        if (commRule) setFilter(prevState => ({...prevState, commRule: commRule}))
    }, [commRule])

    const formatCrmUserData = async (data) => {
        return await data?.map((d) => {
            return {
                ...d,
                // role: d?.role?.filter(role => !role?.isDefaultCrmUserPermission),
                // rank: d?.rank?.name,
                // desk: d?.desk?.name,
                // referrer: d?.referrer?.username,
                // createdAt: d?.registrationDate ? formatDateTime(d?.registrationDate) : "",
                registrationDate: d?.registrationDate ? formatDateTimeTimezone(d?.registrationDate) : "",
                loginStartDate: d?.loginStartDate ? formatDateTimeTimezone(d?.loginStartDate) : "",
                loginEndDate: d?.loginEndDate ? formatDateTimeTimezone(d?.loginEndDate) : "",
                nextFollowUp: d?.nextFollowUp ? formatDateTimeTimezone(d?.nextFollowUp) : "-",
                lastFollowUp: d?.lastFollowUp ? formatDateTimeTimezone(d?.lastFollowUp) : "-",
                // nextFollowUp: d?.latestFollowUp?.followUpDateTime ? formatDateTime(d?.latestFollowUp?.followUpDateTime) : "",
                // lastFollowUp: d?.latestFollowUp?.lastFollowUp ? formatDateTime(d?.latestFollowUp?.lastFollowUp) : "",
                comment: t(d?.comment)
            };
        });
    }

    const processData = (data, e, permission) => {
        if (permission) {
            formatCrmUserData(data)?.then(value => e(value))
        } else {
            formatCrmUserData(data)?.then(value => formatSensitiveFields(value, profileInformation))?.then(value => e(value))
        }
    }

    useEffect(() => {
        if (userData && !userLoading) {
            processData(userData, setFormattedData, permissionUser?.clientsUser?.view?.sensitiveInformation)
        }
    }, [userLoading, userData, profileInformation, permissionUser])

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize);
        setOffset(limit * (page - 1));
    };

    const onRowSelection = (selectedRowKeys, selectedRows) => {
        setBatchUpdateRecord(selectedRows);
    };

    const closeAddForm = () => {
        setIsAddUserOpen(false);
    };

    return (
        <div id={"crmUser"}>
            {permissionUser?.crmUser?.add?.add && (
                <CrmUserAddForm permission={permissionUser?.crmUser} isOpen={isAddUserOpen} close={closeAddForm}
                                profileInformation={profileInformation} refetchUserData={refetchUserData}/>
            )}
            <h1>
                {isShowingArchive ? t("user.tableArchived", {tableName: t("user.crmUser")}) : t("user.tableActive", {tableName: t("user.crmUser")})}
                {(roleId || commRule || isClearFilterVisible) &&
                    <Button className={"margin-left-0-5"} size={"small"} onClick={() => {
                        resetFilters()
                        navigate("/users/crm-user")
                    }}>{t("common.clearFilter")}</Button>
                }
            </h1>
            <CrmUserTopBar
                setIsAddUserOpen={setIsAddUserOpen}
                batchUpdateRecord={batchUpdateRecord}
                setBatchUpdateRecord={setBatchUpdateRecord}
                setFilterType={setFilterType}
                setFilterTypeId={setFilterTypeId}
                filterType={filterType}
                tableName={tableName}
                exportData={formattedExportData}
                exportColumn={column}
                permissionUser={permissionUser}
                isShowingArchive={isShowingArchive}
                setIsShowingArchive={setIsShowingArchive}
                refetchUserData={refetchUserData}
            />
            {column?.length > 0 ? (
                <TableAntDesign
                    data={formattedData}
                    tableName={tableName}
                    columns={column}
                    size={"small"}
                    loading={isLoading}
                    filter={setFilter}
                    filterValue={filter}
                    order={setOrderBy}
                    selectionType={permissionUser?.crmUser?.batchAction && !isShowingArchive ? "checkbox" : undefined}
                    onRowSelectionHandler={onRowSelection}
                    rowKey={"parentId"}
                    selectedRowKeys={batchUpdateRecord?.map((d) => d?.parentId)}
                    pagination={{
                        pageSize: limit,
                        showSizeChanger: true,
                        onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                        total: total,
                        showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                    }}
                />
            ) : (
                <Col span={24} style={{marginTop: "17%", textAlign: "center"}}>
                    <Spin size="large"/>
                </Col>
            )}
        </div>
    );
}

export default CrmUsers_1;
