import React, {useMemo, useState} from "react";
import {Button, Tabs} from "antd";
import TemplateModal from "./component/templateModal";
import {useTranslation} from "react-i18next";
import TestSendEmailForm from "./component/testSendEmailForm";
import TemplateTable from "./component/templateTable";

const EmailTemplate_1 = (props) => {
    const {data, loading, languageData, variableData, componentData, emailTemplatePermission, dynamicVariablePermission, newsletterData} = props;
    const {t} = useTranslation();

    const [formModalType, setFormModalType] = useState("");
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    
    const [sendEmailModal, setSendEmailModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [templateName, setTemplateName] = useState("");

    const openSendEmailModal = (record) => {
        setTemplateName(record);
        setSendEmailModal(true);
    };

    const closeSendEmailModal = () => {
        setSendEmailModal(false);
        setTemplateName("");
    };

    const openFormModal = (type, record?) => {
        setFormModalType(type);
        setIsFormModalOpen(true);
        if (record) {
            setSelectedItem(record);
        }
    };

    const closeFormModal = () => {
        setFormModalType("");
        setSelectedItem({});
        setIsFormModalOpen(false);
    };

    const tabList = ["custom", "system"];

    const tabItems = tabList?.map((tab, index) => ({
        key: tab,
        label: t(`common.${tab}`),
        children: (
            <TemplateTable
                loading={loading}
                item={data?.filter((item) => item?.type === tab)}
                openFormModal={openFormModal}
                openSendEmailModal={openSendEmailModal}
                emailTemplatePermission={emailTemplatePermission}
            />
        ),
    }));

    return (
        <div id="emailTemplate">
            <TemplateModal
                mode={formModalType}
                item={selectedItem}
                isOpen={isFormModalOpen}
                languageData={languageData}
                variableData={variableData}
                componentData={componentData}
                closeModal={closeFormModal}
                dynamicVariablePermission={dynamicVariablePermission}
                newsletterData={newsletterData}
            />
            <TestSendEmailForm templateName={templateName} isOpen={sendEmailModal} languageData={languageData} closeModal={closeSendEmailModal} />

            <div className="display-flex">
                <h1>{t("common.emailTemplate")}</h1>
            </div>
            <Tabs
                defaultActiveKey="custom"
                items={tabItems}
                tabBarExtraContent={
                    emailTemplatePermission?.add?.add && (
                        <Button disabled={loading} className="margin-left-auto" onClick={() => openFormModal("add")}>
                            {t("Add Email Template")}
                        </Button>
                    )
                }
            />
        </div>
    );
};

export default EmailTemplate_1;
