import React from "react";
import {getBooleanFilterProps, getColumnSearchProps, getSingleSelectFilterProps,} from "../../../../../components/table/function/filterTable";
import {Switch, Tag} from "antd";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {useUpdateUserLoginEnabledMutation} from "../../../../../api/graphql/user";
import {useNavigate} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {fieldListState, selfPermissionObj} from "../../../../../../recoil_state";
import {BsCheck, BsX} from "react-icons/bs";
import {useTranslation} from "react-i18next";

const IsLoginEnabledColumn = (props) => {
    const {updateUserLoginEnabled} = useUpdateUserLoginEnabledMutation();
    const permission = useRecoilValue<any>(selfPermissionObj);
    const isLoginEnabledOnChange = (value, record) => {
        updateUserLoginEnabled(record?._id, {isLoginEnabled: value})
    };

    let component
    if (permission?.user?.crmUser?.edit?.edit) {
        component = <Switch
            checkedChildren={<CheckOutlined/>}
            unCheckedChildren={<CloseOutlined/>}
            checked={props?.value}
            size={"small"}
            onChange={(value) => isLoginEnabledOnChange(value, props.record)}
        />
    } else {
        component = props?.value ? <BsCheck style={{color: 'green'}}/> : <BsX style={{color: 'red'}}/>
    }

    return component
};

const DirectNameColumn = (props) => {
    const navigate = useNavigate();
    const permission = useRecoilValue<any>(selfPermissionObj);

    return (
        permission?.user?.clientsUser?.view?.view ?
            <a onClick={() => navigate("/users/client?referrer=" + props.record.parentId)}>
                {props.text || "-"}
            </a> : <p>{props.text || "-"}</p>
    )
}

export const Columns = () => {
    const {t} = useTranslation()
    const fieldList = useRecoilValue<any>(fieldListState)

    return [
        {
            title: t("user.affiliateCode"),
            dataIndex: "affiliateCode",
            key: "affiliateCode",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("user.rank"),
            dataIndex: "rank",
            key: "rank",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("user.desk"),
            dataIndex: "desk",
            key: "desk",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("user.role"),
            dataIndex: "role",
            key: "role",
            ...getColumnSearchProps(),
            render: (text) => (
                text.length > 0 && text.map((tag, i) => {
                        if (i < 1) {
                            return (
                                <Tag key={i} style={{margin: "0 2px"}} color={"geekblue"} bordered={false}>
                                    {tag}
                                </Tag>)
                        } else if (i === 1) {
                            return (
                                <Tag key={i} style={{margin: "0 2px"}} bordered={false}>
                                    ...
                                </Tag>)
                        } else return
                    }
                )
            ),
        },
        {
            title: t("user.referrer"),
            dataIndex: "referrer",
            key: "referrer",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("user.directClients"),
            dataIndex: "directClient",
            key: "directClient",
            ...getColumnSearchProps(),
            align: "center",
            render: (text, record) => <DirectNameColumn text={text} record={record}/>,
        },
        {
            title: t("user.startDate"),
            dataIndex: "loginStartDate",
            key: "loginStartDate",
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("user.endDate"),
            dataIndex: "loginEndDate",
            key: "loginEndDate",
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("user.nextFollowUp"),
            dataIndex: "nextFollowUp",
            key: "nextFollowUp",
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("user.lastFollowUp"),
            dataIndex: "lastFollowUp",
            key: "lastFollowUp",
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("user.domain"),
            dataIndex: "domain",
            key: "domain",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("user.registrationTime"),
            dataIndex: "registrationDate",
            key: "registrationDate",
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("user.comment"),
            dataIndex: "comment",
            key: "comment",
            ...getSingleSelectFilterProps(fieldList?.find(d => d?.fieldName === "crmUserComment")?.optionValue?.map(d => ({label: t(d?.label), value: d?.value}))),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("user.loginPermission"),
            dataIndex: "isLoginEnabled",
            key: "isLoginEnabled",
            ...getBooleanFilterProps(),
            align: "center",
            render: (text, record) => <IsLoginEnabledColumn value={text} record={record}/>,
        },
    ]
}