import {Button, Col, DatePicker, Form, Input, InputNumber, Row, Select} from "antd";
import {DeleteOutlined, MenuOutlined} from "@ant-design/icons";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {comparisonOperator, logicalOperator} from "../../../../data/operatorDropdown";
import {useTranslation} from "react-i18next";

const ConditionItem = ({detail, index, restField, onFinish, remove, name, dragHandle, selectedTrigger, isLast}) => {
    const {t} = useTranslation();
    const [fieldOptions, setFieldOptions] = useState<any[]>([])
    const [valueObject, setValueObject] = useState<any>(null)

    const init = detail?.condition?.[index]

    useEffect(() => {
        if (selectedTrigger) {
            const option = selectedTrigger?.payload?.map(d => ({
                ...d,
                label: d?.fieldName,
                value: d?.fieldName,
            }))

            setFieldOptions(option)
        }
    }, [selectedTrigger]);

    const fieldOnClickHandler = (form, batch, value) => {
        const formFound = form.current;
        if (formFound) {
            const found = fieldOptions?.find(d => d?.value === value)
            formFound.setFieldValue([`condition${batch}`, index, "value"], null)
            formFound.setFieldValue([`condition${batch}`, index, "dataType"], found?.dataType)

            setValueObject(found)
        }
        onFinish(form, batch)
    }

    const renderValueObject = useCallback(() => {
        let component

        if (valueObject?.optionsValue && valueObject?.options?.length > 0) {
            const option = valueObject?.optionValue?.map(d => ({...d, label: t(d?.label)}))
            component = <Select options={option} onChange={() => onFinish(detail.form, detail.batchId)}/>
        } else if (valueObject?.dataType === "date") {
            component = <DatePicker onChange={() => onFinish(detail.form, detail.batchId)}/>
        } else if (valueObject?.dataType === "boolean") {
            component = <Select options={[{label: t("common.true"), value: true}, {label: t("common.false"), value: false}]}
                                onChange={() => onFinish(detail.form, detail.batchId)}/>
        } else if (valueObject?.dataType === "number") {
            component = <InputNumber style={{width: "100%"}} onKeyUp={() => onFinish(detail.form, detail.batchId)}/>
        } else {
            component = <Input onKeyUp={() => onFinish(detail.form, detail.batchId)}/>
        }

        return component
    }, [valueObject])

    return (
        <Row align={"middle"}>
            <Col span={1}>
                {/*<MenuOutlined className={"sort-handler"} {...dragHandle}/>*/}
                <MenuOutlined/>
            </Col>
            <Col span={23}>
                <Row gutter={20} justify={"center"}>
                    <Col span={3}>
                        {
                            index > 0 && (
                                <Form.Item
                                    {...restField}
                                    name={[name, 'logicalOperator']}
                                    initialValue={detail?.condition?.[index - 1]?.logicalOperator}
                                >
                                    <Select
                                        style={{width: "100%"}}
                                        options={logicalOperator}
                                        onChange={() => onFinish(detail.form, detail.batchId)}
                                        allowClear
                                    />
                                </Form.Item>
                            )
                        }
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            {...restField}
                            name={[name, 'field']}
                        >
                            <Select
                                style={{width: "100%"}}
                                options={fieldOptions}
                                disabled={!fieldOptions?.length}
                                onChange={(value) => fieldOnClickHandler(detail.form, detail.batchId, value)}
                                optionLabelProp={"value"}
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item
                            {...restField}
                            name={[name, 'comparisonOperator']}
                            initialValue={init?.comparisonOperator}
                        >
                            <Select
                                style={{width: "110%"}}
                                options={comparisonOperator}
                                onChange={() => onFinish(detail.form, detail.batchId)}
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item
                            {...restField}
                            name={[name, 'value']}
                            initialValue={init?.value}
                        >
                            {renderValueObject()}
                        </Form.Item>
                    </Col>
                    <Col span={1}>
                        {
                            !isLast &&
                            <Button
                                type={"link"}
                                danger
                                onClick={() => {
                                    remove(name)
                                    onFinish(detail.form, detail.batchId)
                                }}
                                icon={<DeleteOutlined/>}
                            />
                        }
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default ConditionItem