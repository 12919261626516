import React, {useEffect, useState} from "react";
import TableAntDesign from "../../../../../components/table/tableAntDesign";
import {useTranslation} from "react-i18next";
import { getExistingVoucherCode, getVoucherProgram, getVoucherProgramCount, useDeleteVoucherProgramMutation } from "../../../../../api/graphql/voucherProgram";
import VoucherProgramModal from "../../component/voucherProgramModal";
import { Popconfirm, Space, Tooltip } from "antd";
import { CopyOutlined, DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import useAuthorize from "../../../../../_common/authorize";
import VoucherProgramTopBar from "../../component/voucherProgramTopBar";
import { getMultiTierSetting } from "../../../../../api/graphql/multiTierSetting";
import { getVoucher } from "../../../../../api/graphql/voucher";
import { getVoucherGroup } from "../../../../../api/graphql/voucherGroup";
import VoucherProgramStatusModal from "../../component/voucherProgramStatusModal";

function VoucherProgram_1(props) {
    const {
        tableName,
        permissionActivities,
        column,
        languageData
    } = props;

    const {t} = useTranslation();

    const {deleteVoucherProgram} = useDeleteVoucherProgramMutation()
    const { systemErrorNotification } = useAuthorize();

    const [viewModalMode, setViewModalMode] = useState("")
    const [viewModalOpen, setViewModalOpen] = useState(false)
    const [viewModalSelectedItem, setViewModalSelectedItem] = useState({})

    const [formModalType, setFormModalType] = useState("");
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});

    const [orderBy, setOrderBy] = useState<any>("")
    const [limit, setLimit] = useState<any>(20)
    const [offset, setOffset] = useState<any>(0)
    const [filter, setFilter] = useState<any>(null)

    const [multiTierSettingData, setMultiTierSettingData] = useState<any>([])
    const { data: voucherData } = getVoucher({filter: null});
    const { data: voucherGroupData } = getVoucherGroup({filter: null});
    const { data: existingVoucherCode } = getExistingVoucherCode()
    const {data: totalCount} = getVoucherProgramCount({filter: filter})
    const {data, loading} = getVoucherProgram({filter: filter}, limit, offset, orderBy)
    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize)
        setOffset(limit * (page - 1))
    }

    const getMultiTierSettingData =async () => {
        try {
            const res = await getMultiTierSetting({filter: {type: "crmUser"}});
            if (res?.getMultiTierSetting?.__typename === "MultiTierSettingArray") {
                setMultiTierSettingData(res?.getMultiTierSetting?.data);
              }
        } catch (error) {
            
        }
    }

    const onViewTotalClaim = (record) => {
      setViewModalMode("totalClaimed")
      setViewModalSelectedItem(record)
      setTimeout(() => {
        setViewModalOpen(true)
      }, 1200)
    }
  
    const onViewTotalRedeemed = (record) => {
      setViewModalMode("totalRedeemed")
      setViewModalSelectedItem(record)
      setTimeout(() => {
        setViewModalOpen(true)
      }, 1200)
    }

    useEffect(() => {
        getMultiTierSettingData()
    },[])


    const deleteOnConfirm = async (id) => {
      try {
        await deleteVoucherProgram(id);
      } catch (e) {
        console.log(e);
        systemErrorNotification();
      }
    };

    const openFormModal = (type, record?) => {
        setFormModalType(type);
        setIsFormModalOpen(true);
        if (record) {
          setSelectedItem(record);
        }
        else {
            setSelectedItem({})
        }
    };

    const closeFormModal = () => {
      setFormModalType("");
      setSelectedItem({});
      setIsFormModalOpen(false);
    };

    const duplicateItem = (record) => {
      setFormModalType("add")
      setSelectedItem(record)
      setIsFormModalOpen(true)
    }

    return (
        <div id={"voucherProgram"}>
          <h1 style={{position: "absolute"}}>{t("Voucher Program")}</h1>
            {permissionActivities?.voucherProgram?.add?.add  && (
                <VoucherProgramModal 
                  mode={formModalType} 
                  item= {selectedItem} 
                  isOpen={isFormModalOpen} 
                  closeFormModal={closeFormModal} 
                  languageData={languageData} 
                  multiTierSettingData={multiTierSettingData}
                  voucherData={voucherData}
                  voucherGroupData={voucherGroupData}
                  existingVoucherCode={existingVoucherCode}/>
            )}
            {permissionActivities?.voucherProgram?.add?.add  && (
                <VoucherProgramTopBar 
                  openFormModal={openFormModal}
                  permissionActivities={permissionActivities} 
                />
            )}
            {column?.length > 0 && (
                <TableAntDesign
                    data={data?.getVoucherProgram?.data}
                    tableName={tableName}
                    columns={[
                        ...column,
                        {
                            title: "Voucher Program Total Claimed",
                            key: "voucherProgramTotalClaimed",
                            dataIndex: "voucherProgramTotalClaimed",
                            sorter: (a, b) => a - b,
                            render: (text, record) => 
                              <a onClick={() => onViewTotalClaim(record)}>
                                {text}
                              </a>,
                        },
                        {
                            title: "Voucher Program Total Redeemed",
                            key: "voucherProgramTotalRedeemed",
                            dataIndex: "missionTotalRedeemed",
                            sorter: (a, b) => a - b,
                            render: (text, record) => 
                              <a onClick={() => onViewTotalRedeemed(record)}>
                                {text}
                              </a>,
                        },
                        {
                            title: t("common.action"),
                            key: "action",
                            width: 132,
                            render: (_, record: any) => (
                              <Space size="middle">
                                <Tooltip title={t("View")}>
                                  <a onClick={() => openFormModal("view", record)}>
                                    <EyeOutlined />
                                  </a>
                                </Tooltip>
                                {permissionActivities?.voucherProgram?.edit?.edit && (
                                  <Tooltip title={t("Edit")}>
                                    <a onClick={() => openFormModal("edit", record)}>
                                      <EditOutlined />
                                    </a>
                                  </Tooltip>
                                )}
                                
                                {permissionActivities?.voucherProgram?.edit?.edit && (
                                  <Popconfirm
                                      placement="left"
                                      description={t("Are you sure to duplicate this voucher program?")}
                                      title={t(`Duplicate Voucher Program`)}
                                      onConfirm={() => {
                                          duplicateItem(record);
                                      }}
                                      okText={t("Yes")}
                                      okType="danger"
                                      cancelText={t("No")}
                                  >
                                    <Tooltip title={t("Duplicate")}>
                                        <a>
                                            <CopyOutlined/>
                                        </a>
                                </Tooltip>
                                  </Popconfirm>
                                )}
                                {permissionActivities?.voucherProgram?.delete?.delete && (
                                    <Tooltip title={t("Delete")}>
                                      <Popconfirm
                                        placement="left"
                                        title={t("Are you sure to delete this base voucherProgram?")}
                                        onConfirm={() => {
                                          deleteOnConfirm(record._id);
                                        }}
                                        okText={t("common.yes")}
                                        cancelText={t("common.no")}
                                      >
                                        <a>
                                          <DeleteOutlined />
                                        </a>
                                      </Popconfirm>
                                    </Tooltip>
                                )}
                                </Space>
                            )
                        }
                    ]}
                    size={"small"}
                    loading={loading}
                    filter={setFilter}
                    order={setOrderBy}
                    pagination={{
                        pageSize: limit,
                        showSizeChanger: true,
                        onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                        total: totalCount,
                        showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                    }}
                />
            )}
            <VoucherProgramStatusModal
                mode={viewModalMode} 
                item= {viewModalSelectedItem} 
                isOpen={viewModalOpen} 
                closeModal={()=>setViewModalOpen(false)}  
                tableName={"voucherProgram" + viewModalMode}
            />
        </div>
    );
}

export default VoucherProgram_1;
