import React, {useEffect, useState} from "react";
import {Button, Space, Tabs, Tooltip} from "antd";
import AssetsTable_1 from "./component/assetsTable";
import ExportTableButton from "../../../../../components/table/function/exportTable";
import EditTableUserPreferenceButton from "../../../../../components/table/function/editTableUserPreferenceButton";
import {SyncOutlined} from "@ant-design/icons";
import {useRefreshAssetMutation} from "../../../../../api/graphql/asset";
import {useTranslation} from "react-i18next";
import {useRecoilValue} from "recoil";
import {selfPermissionObj} from "../../../../../../recoil_state";
import useAuthorize from "../../../../../_common/authorize";

const Assets_1 = (props) => {
    const {data, loading} = props;
    const {t} = useTranslation()
    const [exportData, setExportData] = useState(null)
    const [exportColumn, setExportColumn] = useState(null)
    const [currentServer, setCurrentServer] = useState(data?.getServerSetting?.data?.[0])
    const tableName = t("Assets")

    const permission = useRecoilValue<any>(selfPermissionObj)

    const {refreshAssets, loading: refeshLoading} = useRefreshAssetMutation()
    const {systemErrorNotification} = useAuthorize();

    const hendleAssetRefresh = (id) => {
        try {
            refreshAssets(id);
        } catch (error) {
            systemErrorNotification();
        }
    }

    useEffect(() => {
        if (data?.getServerSetting?.data?.length > 0) {
            setCurrentServer(data?.getServerSetting?.data[0])
        }
    }, [data?.getServerSetting?.data])

    return (
        <div id="assets">
            <h1>{t(tableName)}</h1>

            {data?.getServerSetting?.data && (
                <Tabs
                    defaultActiveKey="1"
                    size={"small"}
                    items={
                        data &&
                        data?.getServerSetting &&
                        data?.getServerSetting?.data?.map((item, i) => {
                            if (item?.platform !== "mt5" && item?.platform !== "xTrader") {
                                return {
                                    label: t(item?.name),
                                    key: i,
                                    children: <AssetsTable_1 server={item} setExportData={setExportData}
                                                             setExportColumn={setExportColumn} tableName={tableName}
                                                             currentServer={currentServer}/>,
                                };
                            }
                        })
                    }
                    tabBarExtraContent={
                        <>
                            <Space>
                                <EditTableUserPreferenceButton
                                    tableName={tableName}
                                    server={currentServer}
                                    tableColumn={exportColumn}
                                />
                                <ExportTableButton
                                    loading={refeshLoading || loading}
                                    tableData={exportData}
                                    tableColumn={exportColumn}
                                    tableName={tableName}
                                    server={currentServer}
                                />
                                {
                                    permission?.accountSetting?.assets?.refreshData &&
                                    <Tooltip title={t("Refresh assets")}>
                                        <Button
                                            type={"link"}
                                            loading={refeshLoading || loading}
                                            disabled={refeshLoading || loading}
                                            onClick={() => hendleAssetRefresh(currentServer?.id)}
                                            icon={<SyncOutlined/>}
                                        />
                                    </Tooltip>}
                            </Space>
                        </>
                    }
                    onTabClick={(key) => {
                        setCurrentServer(data?.getServerSetting?.data[key])
                    }}
                />
            )}
        </div>
    );
}

export default Assets_1;
