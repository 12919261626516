import React from "react";
import {Form, Select} from "antd";
import {getPdfIdFromObject} from "../../../function/common";

const PdfMultiSelect = (props) => {
    const {item, onAttachmentChange, languageCode, attachmentOptions, disabled} = props;
    let defaultValue = getPdfIdFromObject(item?.htmlObject, "attachment", languageCode);
    let attachmentOptionsByLanguage = attachmentOptions?.filter((v) => v?.languageCodeList?.includes(languageCode));
    return (
        <Form.Item label="Attachments" name="attachments" className="margin-top-0-75">
            <Select
                mode="multiple"
                allowClear
                style={{width: "100%"}}
                placeholder=""
                defaultValue={defaultValue.length > 0 ? defaultValue : []}
                onChange={(e) => onAttachmentChange(e, languageCode)}
                options={attachmentOptionsByLanguage}
                disabled={disabled}
                filterOption={(input, option: any) => option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
            />
        </Form.Item>
    );
};

export default PdfMultiSelect;
