import { Form, Checkbox } from "antd";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import FormErrors from "./formErrors";

const MultipleCheckboxFormItem = (props) => {
  const {
    layout,
    label,
    name,
    externalErrorMessage,
    options,
    defaultValue,
    onChange,
    hint,
    disabled,
    required,
    value,
  } = props;

  const {
    register,
    watch,
    setValue,
    formState: { touchedFields, isSubmitted, errors },
  } = useFormContext();

  const errorMessage = FormErrors.errorMessage(
    name,
    errors,
    touchedFields,
    isSubmitted,
    externalErrorMessage
  );

  const formHelperText = errorMessage || hint;

  useEffect(() => {
    register(name);
  }, [register, name]);

  return (
    <Form.Item
      {...layout}
      label={label}
      required={required}
      validateStatus={errorMessage ? "error" : "success"}
      help={formHelperText}
    >
      <Checkbox.Group
        name={name}
        options={options}
        defaultValue={defaultValue}
        value={value || watch(name)}
        disabled={disabled}
        onChange={(e) => {
          setValue(name, e, { shouldValidate: true });
          onChange && onChange(e);
        }}
      />
      {/* <Checkbox
        id={name}
        name={name}
        checked={watch(name) || false}
        onChange={(e) => {
          setValue(name, Boolean(e.target.checked), { shouldValidate: true });
          onChange && onChange(Boolean(e.target.checked));
        }}
        disabled={disabled}
      /> */}
    </Form.Item>
  );
};

MultipleCheckboxFormItem.defaultProps = {
  required: false,
  disabled: false,
};

MultipleCheckboxFormItem.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  externalErrorMessage: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array,
  defaultValue: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  layout: PropTypes.object,
  hint: PropTypes.string,
  value: PropTypes.array,
};

export default MultipleCheckboxFormItem;
