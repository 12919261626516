import React from "react";
import {Col, Row} from "antd";
import {formatDateTimeTimezone} from "../../../../../../../../function/_common";

const EWalletAdjustment = ({record}) => {
    const task = record?.task

    return (
        <Row gutter={[16,16]}>
            <Col xs={24}>
                <Row gutter={[16,16]}>
                    <Col xs={24} md={6}>Status</Col>
                    <Col xs={24} md={18}>{task?.status}</Col>

                    <Col xs={24} md={6}>Transaction No</Col>
                    <Col xs={24} md={18}>{task?.result?.transactionNo}</Col>

                    <Col xs={24} md={6}>Transaction Date</Col>
                    <Col xs={24} md={18}>{formatDateTimeTimezone(task?.result?.transactionDate)}</Col>

                    <Col xs={24} md={6}>Amount</Col>
                    <Col xs={24} md={18}>{task?.result?.amount}</Col>

                    <Col xs={24} md={6}>Type</Col>
                    <Col xs={24} md={18}>{task?.result?.type}</Col>

                    <Col xs={24} md={6}>Description</Col>
                    <Col xs={24} md={18}>{task?.result?.description}</Col>

                    <Col xs={24} md={6}>Remark</Col>
                    <Col xs={24} md={18}>{task?.result?.remark}</Col>

                    <Col xs={24} md={6}>Requested By</Col>
                    <Col xs={24} md={18}>{task?.requestedBy?.username}</Col>

                    <Col xs={24} md={6}>Comment</Col>
                    <Col xs={24} md={18}>{task?.result?.comment || task?.comment}</Col>
                </Row>
            </Col>
            {/*<Col xs={24} md={12}>*/}
            {/*    <h3 className={"margin-top-0-75"}>Upload Information</h3>*/}
            {/*    <Row gutter={[16,16]}>*/}
            {/*        <Col xs={24} md={12}>Admin Ref No.</Col>*/}
            {/*        <Col xs={24} md={12}></Col>*/}
            {/*        */}
            {/*        <Col xs={24} md={12}>Admin Remark</Col>*/}
            {/*        <Col xs={24} md={12}></Col>*/}
            {/*        */}
            {/*        <Col xs={24} md={12}>Slip</Col>*/}
            {/*        <Col xs={24} md={12}></Col>*/}
            {/*    </Row>*/}
            {/*</Col>*/}
        </Row>
    )

}

export default EWalletAdjustment