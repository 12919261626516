import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";

const GET_MONEY_MANAGER_ACCOUNT = gql`
    query getMoneyManagerAccount($filter: MoneyManagerAccountSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
        getMoneyManagerAccount(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on MoneyManagerAccountArray {
                total
                data {
                    _id
                    user {
                        _id
                        username
                    }
                    accountId
                    platform
                    leverage
                    creditInUsd
                    balance
                    equity
                    status
                    comment
                    investorNum
                    spreadGroup {
                        name
                    }
                    mt5SpreadGroup {
                        group
                    }
                    product {
                        _id
                        name
                        productType
                        mode
                        server {
                            _id
                            name
                            platform
                            platformDisplayName
                        }
                        mamPammProvider {
                            provider
                        }
                    }
                    moneyManagerAccountSetting {
                        _id
                        name
                        description
                        profileImage
                    }
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getMoneyManagerAccount = async ({filter}, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_MONEY_MANAGER_ACCOUNT,
            {
                filter: {
                    user: filter?.user || null,
                    profileName: filter?.profileName || null,
                    accountId: filter?.accountId || null,
                    platform: filter?.platform || null,
                    product: filter?.product || null,
                    productType: filter?.productType || null,
                    spreadGroup: filter?.spreadGroup || null,
                },
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_INVESTOR_ACCOUNT = gql`
    query getInvestorAccount($filter: InvestorAccountSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
        getInvestorAccount(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on InvestorAccountArray {
                total
                data {
                    _id
                    accountId
                    platform
                    leverage
                    creditInUsd
                    balance
                    equity
                    comment
                    user {
                        _id
                        username
                    }
                    spreadGroup {
                        groupId
                        name
                    }
                    mt5SpreadGroup {
                        _id
                        group
                    }
                    product {
                        name
                        productType
                        server {
                            platformDisplayName
                        }
                    }
                    investorAccountSetting {
                        status
                        easyMAM {
                            stopLoss
                            stopLossStatus
                        }
                    }
                    moneyManager {
                        account {
                            _id
                            accountId
                            user {
                                _id
                                username
                            }
                        }
                        accountSetting {
                            name
                            profileImage
                            easyMAM {
                                tradingMode
                                isDepositEnabled
                                isWithdrawalEnabled
                            }
                        }
                    }
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getInvestorAccount = async ({filter}, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_INVESTOR_ACCOUNT,
            {
                filter: {
                    user: filter?.user || null,
                    moneyManagerAccount: filter?.moneyManagerAccount || null,
                    accountId: filter?.accountId || null,
                    platform: filter?.platform || null,
                    productType: filter?.productType || null,
                    product: filter?.product || null,
                    spreadGroup: filter?.spreadGroup || null,
                },
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_MONEY_MANAGER_ACCOUNT_SETTING_BY_ID = gql`
    query getMoneyManagerAccountSettingByID($id: String!) {
        getMoneyManagerAccountSettingByID(id: $id) {
            ... on MoneyManagerAccountSetting {
                _id
                account {
                    _id
                    accountId
                    user {
                        _id
                    }
                }
                name
                description
                profileImage
                minDeposit
                subscriptionFee
                subscriptionFeeTimeframe
                moneyManagerProfitSharingTimeframe
                moneyManagerProfitSharingType
                moneyManagerProfitSharing
                traderProfitSharing
                commissionProfitSharing
                investorProfitSharing
                networkType
                network {
                    _id
                    username
                }
                exclude {
                    _id
                    username
                }
                investorProduct {
                    _id
                    name
                }
                autoDeposit
                autoWithdrawal
                easyMAM {
                    tradingMode
                    isDepositEnabled
                    isWithdrawalEnabled
                }
                legalDocuments {
                    name
                    displayName {
                        language
                        content
                    }
                    tinyMce {
                        language
                        content
                    }
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getMoneyManagerAccountSettingByID = async (id: string) => {
    try {
        const execute = await ApolloClientQuery(
            GET_MONEY_MANAGER_ACCOUNT_SETTING_BY_ID,
            {
                id: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UPDATE_MONEY_MANAGER_ACCOUNT_SETTING = gql`
    mutation updateMoneyManagerAccountSetting($id: String!, $input: MoneyManagerAccountSettingInput) {
        updateMoneyManagerAccountSetting(id: $id, input: $input) {
            ... on MoneyManagerAccountSetting {
                _id
                account {
                    accountId
                }
                name
                description
                profileImage
                minDeposit
                subscriptionFee
                subscriptionFeeTimeframe
                moneyManagerProfitSharingTimeframe
                moneyManagerProfitSharingType
                moneyManagerProfitSharing
                traderProfitSharing
                commissionProfitSharing
                investorProfitSharing
                networkType
                network {
                    _id
                    username
                }
                exclude {
                    _id
                    username
                }
                investorProduct {
                    _id
                    name
                }
                autoDeposit
                autoWithdrawal
                easyMAM {
                    tradingMode
                    isDepositEnabled
                    isWithdrawalEnabled
                }
                legalDocuments {
                    name
                    displayName {
                        language
                        content
                    }
                    tinyMce {
                        language
                        content
                    }
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const updateMoneyManagerAccountSetting = async (id, input) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_MONEY_MANAGER_ACCOUNT_SETTING,
            {
                id: id,
                input: input,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const CALCULATE_MAM_PAMM_PROFIT_SHARING_MT5 = gql`
    mutation calculateMamPammProfitSharingMT5($investorAccountId: String!) {
        calculateMamPammProfitSharingMT5(investorAccountId: $investorAccountId) {
            profitForCalculation
            currentHighWatermark
            moneyManagerProfitSharingRatio
            investorProfitSharingRatio
            traderProfitSharingRatio
            commissionProfitSharingRatio
            totalProfitSharingAmount
            moneyManagerProfitSharingAmount
            investorProfitSharingAmount
            traderProfitSharingAmount
            commissionProfitSharingAmount
        }
    }
`

const calculateMamPammProfitSharingMT5 = async (investorAccountId) => {
    const variables = {
        investorAccountId: investorAccountId,
    };

    return await ApolloClientQuery(CALCULATE_MAM_PAMM_PROFIT_SHARING_MT5, variables, true);
}

export {getMoneyManagerAccount, getInvestorAccount, getMoneyManagerAccountSettingByID, updateMoneyManagerAccountSetting, calculateMamPammProfitSharingMT5};
