import React, {useState, Fragment, cloneElement} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "antd";

const ConditionalFieldGroup = (props) => {
    const {t} = useTranslation();
    const {fieldGroupType, isDraft, children, fieldMemberId} = props
    const memberIdSet = [...new Set(children.props.documentRecord?.fieldValue?.map((d) => d.memberId).filter((d) => !!d))].sort();

    function generateRandomId() {
        return Math.random().toString(16).slice(2);
    }

    const [groupChild, setGroupChild] = useState(isDraft ? memberIdSet : [generateRandomId()]);

    if (fieldGroupType !== "multiple") {
        return children;
    }

    const handleAddGroupItem = () => {
        setGroupChild((d) => [...d, fieldMemberId ?? generateRandomId()]);
    };

    return (
        <div>
            {groupChild.map((uid:any, i) => (
                <Fragment key={uid}>
                    {cloneElement(children, {
                        ...children.props,
                        key: uid,
                        groupItemId: uid,
                        memberId: i,
                        membersCount: groupChild.length,
                        onDeleteCurrentGroupItem: () => {
                            setGroupChild((d) => d.filter((e) => e !== uid));
                        },
                    })}
                    {!isDraft && i === groupChild.length - 1 ? (
                        <Button size="small" type="link" style={{float: "right", marginTop: 10}} onClick={handleAddGroupItem}>
                            {t("common.add")}
                        </Button>
                    ) : null}
                </Fragment>
            ))}
        </div>
    );
}

export default ConditionalFieldGroup