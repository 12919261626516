import React, {useState} from "react";
import {Button, Col, DatePicker, Form, Modal, Row, Spin, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import useAuthorize from "../../../../../../../_common/authorize";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import moment from "moment";
import {commissionCreateBatch} from "../../../../../../../api/graphql/commission";
import {InfoCircleOutlined} from "@ant-design/icons";

const {RangePicker} = DatePicker;

const CommLogBatchCreateModal = (props) => {

    const {isOpen, closeModal, data, preload} = props;

    const [syncLoading, setSyncLoading] = useState(false)

    const {t} = useTranslation()
    const labelCol = {span: 24, offset: 0}
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {systemErrorNotification} = useAuthorize()

    const [form] = Form.useForm();

    const createBatch = async (values) => {

        await setSyncLoading(true)

        try {
            const startDate = values?.startEndDate?.[0] ? moment(values?.startEndDate?.[0]?.$d)?.format("YYYY-MM-DD HH:mm:ss") : null
            const endDate = values?.startEndDate?.[1] ? moment(values?.startEndDate?.[1]?.$d)?.format("YYYY-MM-DD HH:mm:ss") : null
            const networkDate = values?.networkDate ? moment(values?.networkDate?.$d)?.format("YYYY-MM-DD HH:mm:ss") : null

            const response = await commissionCreateBatch(data?._id, startDate, endDate, networkDate);

            if (response?.commissionCreateBatch?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.commissionCreateBatch?.errKey),
                    key: "commissionCreateBatch",
                });
            } else if (response?.commissionCreateBatch?.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.commissionCreateBatch?.msg),
                    key: "commissionCreateBatch",
                });
                closeModal()
            } else {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Create Batch Successfully"),
                    key: "commissionCreateBatch",
                });
                closeModal()
            }


        } catch (e) {
            systemErrorNotification()
        } finally {
            setSyncLoading(false)
            preload();
        }
    }


    return (
        <Modal
            title={"Create Commission Log Batch"}
            open={isOpen}
            onCancel={() => closeModal()}
            footer={null}
            width={500}
            destroyOnClose
        >
            <Spin spinning={syncLoading}>
                <Form onFinish={createBatch} form={form}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item
                                labelCol={labelCol}
                                label={t("Start & End Date")}
                                name={"startEndDate"}
                                rules={[{required: true}]}
                            >
                                <RangePicker style={{width: "100%"}}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                labelCol={labelCol}
                                label={<>
                                    {t("Network Date")}
                                    <Tooltip
                                        title={t("If the field is empty, the current network tree will be obtained.")}>
                                        <InfoCircleOutlined className={"margin-left-0-25"} size={10}/>
                                    </Tooltip>
                                </>}
                                name={"networkDate"}
                            >
                                <DatePicker style={{width: "100%"}}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Col span={24} style={{textAlign: "end", marginTop: "30px"}}>
                        <Button htmlType={"submit"} disabled={syncLoading}>
                            {t("common.submit")}
                        </Button>
                    </Col>
                </Form>
            </Spin>
        </Modal>

    )

}

export default CommLogBatchCreateModal