import React, {useEffect, useState} from "react";
import {Button, Divider, Form, Input, Row, Space, Switch} from "antd";
import {useTranslation} from "react-i18next";
import RichTextEditor from "../../../../brandDetails/view/layout1/component/richTextEditor";

const DocumentLanguageForm = (props) => {
    const {activeTab, languageCode, inputData, handleEditorOnChange, isEditable, form, submitLoading, handleIsActiveChange, handleDisplayNamerOnChange} = props;
    const {t} = useTranslation();
    const labelCol = {span: 10, offset: 0};
    const wrapperCol = {span: 14, offset: 0};

    const [isEditorOpen, setIsEditorOpen] = useState<boolean>(false);

    const displayNameInitialValue = inputData?.displayName?.find((i) => i?.language === languageCode)?.content || "";
    const tinyMceInitialValue = inputData?.tinyMce?.find((i) => i?.language === languageCode)?.content || "";

    const toggleEditor = () => {
        try {
            setIsEditorOpen((prevState) => !prevState);
        } catch (error) {}
    };

    useEffect(() => {
        form.setFieldValue("name",inputData?.name)
    },[inputData, activeTab])

    return (
        <>
            {isEditorOpen && activeTab === languageCode && (
                <RichTextEditor
                    title={displayNameInitialValue}
                    isOpen={isEditorOpen}
                    setIsOpen={setIsEditorOpen}
                    editorState={tinyMceInitialValue}
                    onEditorStateChange={(e) => handleEditorOnChange(e, languageCode)}
                    disabled={!isEditable}
                    form={form}
                />
            )}
            <div key={inputData?.name} style={{padding: 16}}>
                <Space direction={"vertical"} style={{width: "100%"}}>
                    <Form.Item
                        label={t("common.name")}
                        name={`name`}
                        labelCol={labelCol}
                        rules={[{required: true, message: "Please input document name"}]}
                        initialValue={inputData?.name}
                    >
                        <Input placeholder={t("common.name").toString()} disabled />
                    </Form.Item>
                    <Form.Item
                        label={t("legalDocument.displayName")}
                        name={`displayName_${languageCode}`}
                        labelCol={labelCol}
                        // rules={[{required: true, message: "Please input document display name"}]}
                        initialValue={displayNameInitialValue}
                    >
                        <Input placeholder={t("legalDocument.displayName").toString()} onChange={(e) => handleDisplayNamerOnChange(e?.target?.value, languageCode)} />
                    </Form.Item>
                    <Form.Item label={t("legalDocument.type.editor")} labelCol={labelCol} wrapperCol={wrapperCol} colon={false}>
                        <Button block key={`tinyMce_${languageCode}`} onClick={toggleEditor} disabled={!isEditable}>
                            {t("common.edit")}
                        </Button>
                    </Form.Item>

                    <Divider />
                    <Form.Item label={t("user.isActive")} name={"isActive"} labelCol={labelCol} colon={false} initialValue={inputData?.isActive}>
                        <Switch defaultChecked={inputData?.isActive || false} checked={inputData?.isActive} disabled={!isEditable} onChange={handleIsActiveChange} />
                    </Form.Item>
                </Space>
            </div>
            {isEditable && (
                <>
                    <Row className={"margin-top-0-75"} justify={"end"}>
                        <Button onClick={form.submit} loading={submitLoading} type={"primary"}>
                            {t("common.update")}
                        </Button>
                    </Row>
                    <Row className={"margin-top-0-75"} justify={"end"}>
                        <small>Notes: Update per legal document</small>
                    </Row>
                </>
            )}
        </>
    );
};

export default DocumentLanguageForm;
