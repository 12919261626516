import React from "react";
import {Tag} from "antd";
import {useRecoilValue} from "recoil";
import {timezoneList} from "../../../../../recoil_state";
import {BsCheck, BsX} from "react-icons/bs";

const TimezoneText = ({value}) => {
    const timezone = useRecoilValue<any>(timezoneList);
    const displayText = timezone?.find((v) => v?.value === value)?.label;
    return <p>{displayText || "-"}</p>;
};

export const columns = [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 150,
        sorter: (a, b) => a?.name?.localeCompare(b?.name),
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Mode",
        dataIndex: "mode",
        key: "mode",
        width: 150,
        sorter: (a, b) => a?.mode?.localeCompare(b?.mode),
        render: (items) =>
            items.map((item, index) => (
                <Tag key={index} color={item === "live" ? "green" : "blue"}>
                    {item === "live" ? "LIVE" : "DEMO"}
                </Tag>
            )),
    },
    {
        title: "IP",
        dataIndex: "ip",
        key: "ip",
        width: 150,
        sorter: (a, b) => a?.ip?.localeCompare(b?.ip),
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Port",
        dataIndex: "port",
        key: "port",
        width: 50,
        sorter: (a, b) => a?.port?.localeCompare(b?.port),
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Time Zone",
        dataIndex: "timezone",
        key: "timezone",
        width: 100,
        sorter: (a, b) => a?.timezone?.localeCompare(b?.timezone),
        render: (timezoneValue) => <TimezoneText value={timezoneValue} />,
    },
    {
        title: "Connection",
        dataIndex: "serverStatus",
        key: "serverStatus",
        width: 100,
        sorter: (a, b) => a?.serverStatus?.localeCompare(b?.serverStatus),
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Platform",
        dataIndex: "platform",
        key: "platform",
        width: 80,
        sorter: (a, b) => a?.platform?.localeCompare(b?.platform),
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Broker Name",
        dataIndex: "brokerName",
        key: "brokerName",
        sorter: (a, b) => a?.brokerName?.localeCompare(b?.brokerName),
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Proxy",
        dataIndex: "proxy",
        key: "proxy",
        sorter: (a, b) => a?.proxy?.localeCompare(b?.proxy),
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Proxy Name",
        dataIndex: "proxyNAme",
        key: "proxyName",
        sorter: (a, b) => a?.proxyName?.localeCompare(b?.proxyName),
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Plant Id",
        dataIndex: "plantId",
        key: "plantId",
        sorter: (a, b) => a?.plantId?.localeCompare(b?.plantId),
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "White Label",
        dataIndex: "whiteLabel",
        key: "whiteLabel",
        sorter: (a, b) => a?.whiteLabel?.localeCompare(b?.whiteLabel),
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Environment",
        dataIndex: "environment",
        key: "environment",
        sorter: (a, b) => a?.environment?.localeCompare(b?.environment),
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Username",
        dataIndex: "username",
        key: "username",
        sorter: (a, b) => a?.username?.localeCompare(b?.username),
        render: (text) => <p>{text || "-"}</p>,
    },
    // {
    //   title: "initiateFirstSetup",
    //   dataIndex: "initiateFirstSetup",
    //   key: "initiateFirstSetup",
    //   render: (initiateFirstSetup) => <p>{initiateFirstSetup ? "Yes" : "No"}</p>,
    // },
    // {
    //   title: "First Time Fetch Data Completed",
    //   dataIndex: "firstTimeFetchDataCompleted",
    //   key: "firstTimeFetchDataCompleted",
    //   sorter: (a, b) =>
    //     a?.firstTimeFetchDataCompleted - b?.firstTimeFetchDataCompleted,
    //   render: (firstTimeFetchDataCompleted) => (
    //     <p>{firstTimeFetchDataCompleted ? "Yes" : "No"}</p>
    //   ),
    // },
    {
        title: "Status",
        dataIndex: "isActive",
        key: "isActive",
        width: 80,
        sorter: (a, b) => a?.isActive - b?.isActive,
        render: (isActive, record) => (
            <p>
                {isActive ? "Active " : "Inactive "}
                {record?.paltform === "ctrader" ? (
                    <span>{record?.firstTimeFetchDataCompleted ? <BsCheck style={{color: "green"}} /> : <BsX style={{color: "red"}} />}</span>
                ) : null}
            </p>
        ),
    },
];
