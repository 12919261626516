import React, {useEffect, useState} from "react";
import {useRecoilState} from "recoil";
import {Button, Col, List, Row, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    LockOutlined,
    MinusOutlined,
    PlusOutlined,
    UnlockOutlined,
} from "@ant-design/icons";
import {useUpdateMobileModuleSettingMutation} from "../../../../../../api/graphql/mobileModuleSetting";
import {selfPermissionObj} from "../../../../../../../recoil_state";
import useAuthorize from "../../../../../../_common/authorize";

const ModuleMobileNavigation = (props) => {
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const mobileModuleSettingPermission =
        selfPermission?.brandSetting?.moduleSettings;
    const [activeModule, setActiveModule] = useState<any>([]);
    const [activeBottomModule, setActiveBottomModule] = useState<any>([]);
    const [availableModule, setAvailableModule] = useState<any>([]);

    const {t} = useTranslation();
    const {item, module} = props;
    const sideLimit = 7;
    const bottomLimit = 4;

    const {updateMobileModuleSetting} = useUpdateMobileModuleSettingMutation();
    const {systemErrorNotification} = useAuthorize()

    const movePositionForward = (item) => {
        try {
            updateMobileModuleSetting({
                moduleId: item?.moduleId?._id,
                isLocked: item?.isLocked,
                position: item?.position - 1,
                type: item?.type,
            });
        } catch (e) {
            systemErrorNotification()
        }
    };

    const movePositionBackward = (item) => {
        try {
            updateMobileModuleSetting({
                moduleId: item?.moduleId?._id,
                isLocked: item?.isLocked,
                position: item?.position + 1,
                type: item?.type,
            });
        } catch (e) {
            systemErrorNotification()
        }
    };

    const lockItem = (item) => {
        try {
            updateMobileModuleSetting({
                moduleId: item?.moduleId?._id,
                isLocked: !item.isLocked,
                position: item.position,
                type: item?.type,
            });
        } catch (e) {
            systemErrorNotification()
        }
    };

    const deleteItem = (item) => {
        try{
            updateMobileModuleSetting({
                moduleId: item?.moduleId?._id,
                isLocked: false,
                position: null,
                type: item?.type,
            });
        }catch (e) {
            systemErrorNotification()
        }
    };

    const restructureModuleFunction = async (items) => {
       try{
           const ActiveModule: any = [];
           const BottomModule: any = [];
           await items?.map((item) => {
               if (
                   item.position !== null &&
                   item.moduleId.isEnabled === true &&
                   item.type === "side"
               ) {
                   ActiveModule.push(item);
               }
               if (
                   item.position !== null &&
                   item.moduleId.isEnabled === true &&
                   item.type === "bottom"
               ) {
                   BottomModule.push(item);
               }
           });

           const sortedActiveModule = ActiveModule.sort(
               (a, b) => a.position - b.position
           );
           const sortedBottomModule = BottomModule.sort(
               (a, b) => a.position - b.position
           );
           const availableModule = module?.filter((mod) => {
               const found = sortedActiveModule.find(
                   (s) => mod.name === s.moduleId.name
               );
               const foundBottom = sortedBottomModule.find(
                   (s) => mod.name === s.moduleId.name
               );
               return !found && !foundBottom && mod?.isEnabled;
           });

           setAvailableModule(availableModule);
           setActiveBottomModule(sortedBottomModule);
           setActiveModule(sortedActiveModule);
       }catch (e) {
           systemErrorNotification()
       }
    };

    useEffect(() => {
        restructureModuleFunction(item);
    }, [item, module]);

    const isEditable = mobileModuleSettingPermission?.edit?.edit;

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24} md={12} style={{marginTop: "15px"}}>
                <List
                    size="small"
                    header={
                        <h4 className={"margin-bottom-0"}>
                            {t("Side Navigation Item")} ({activeModule.length}/{sideLimit})
                        </h4>
                    }
                    bordered
                    dataSource={activeModule}
                    renderItem={(item: any, index) => {
                        return (
                            <List.Item
                                actions={[
                                    <Button
                                        type="primary"
                                        onClick={() => lockItem(item)}
                                        icon={item.isLocked ? <LockOutlined/> : <UnlockOutlined/>}
                                        disabled={!isEditable}
                                    />,
                                    <Button
                                        onClick={() => movePositionForward(item)}
                                        icon={<ArrowUpOutlined/>}
                                        disabled={!isEditable || item.position === 1}
                                    />,

                                    <Button
                                        onClick={() => movePositionBackward(item)}
                                        icon={<ArrowDownOutlined/>}
                                        disabled={!isEditable || index + 1 === activeModule.length}
                                    />,
                                    <Button
                                        danger
                                        onClick={() => deleteItem(item)}
                                        icon={<MinusOutlined/>}
                                        disabled={!isEditable}
                                    />,
                                ]}
                            >
                                {item.moduleId.name}
                            </List.Item>
                        );
                    }}
                />
                <List
                    size="small"
                    header={
                        <h4 className={"margin-bottom-0"}>
                            Bottom Navigation Item ({activeBottomModule.length}/{bottomLimit})
                        </h4>
                    }
                    bordered
                    dataSource={activeBottomModule}
                    style={{marginTop: "15px"}}
                    renderItem={(item: any, index) => {
                        return (
                            <List.Item
                                actions={[
                                    <Button
                                        type="primary"
                                        onClick={() => lockItem(item)}
                                        icon={item.isLocked ? <LockOutlined/> : <UnlockOutlined/>}
                                        disabled={!isEditable}
                                    />,

                                    <Button
                                        onClick={() => movePositionForward(item)}
                                        icon={<ArrowUpOutlined/>}
                                        disabled={!isEditable || item.position === 1}
                                    />,
                                    <Button
                                        onClick={() => movePositionBackward(item)}
                                        icon={<ArrowDownOutlined/>}
                                        disabled={
                                            !isEditable || index + 1 === activeBottomModule.length
                                        }
                                    />,
                                    <Button
                                        danger
                                        onClick={() => deleteItem(item)}
                                        icon={<MinusOutlined/>}
                                        disabled={!isEditable}
                                    />,
                                ]}
                            >
                                {item.moduleId.name}
                            </List.Item>
                        );
                    }}
                />
            </Col>
            <Col span={12} style={{marginTop: "15px"}}>
                <List
                    size="small"
                    header={<h4 className={"margin-bottom-0"}>Items For Selection</h4>}
                    bordered
                    dataSource={availableModule}
                    pagination={{
                        pageSize: 10,
                        position: "bottom",
                        showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                    }}
                    renderItem={(item: any, index) => {
                        return (
                            <List.Item
                                actions={[
                                    <Button
                                        onClick={() => {
                                            updateMobileModuleSetting({
                                                moduleId: item?._id,
                                                isLocked: false,
                                                position: activeModule.length + 1,
                                                type: "side",
                                            });
                                        }}
                                        icon={<PlusOutlined/>}
                                        disabled={!isEditable}
                                    >
                                        Side
                                    </Button>,
                                    <Button
                                        onClick={() => {
                                            updateMobileModuleSetting({
                                                moduleId: item?._id,
                                                isLocked: false,
                                                position: activeBottomModule.length + 1,
                                                type: "bottom",
                                            });
                                        }}
                                        icon={<PlusOutlined/>}
                                        disabled={!isEditable}
                                    >
                                        Bottom
                                    </Button>,
                                ]}
                            >
                                {item.name}
                            </List.Item>
                        );
                    }}
                />
            </Col>
        </Row>
    );
};

export default ModuleMobileNavigation;
