import React, {useEffect, useMemo, useState} from "react";
import {useSetRecoilState} from "recoil";
import {useTranslation} from "react-i18next";
import {Button, Row, Space} from "antd";
import moment from "moment";
import {applyVoucher, getUserVouchers} from "../../../../../../../../api/graphql/voucher";
import {getUserVoucherProgram, userSelectVoucher} from "../../../../../../../../api/graphql/voucherProgram";
import TableAntDesign from "../../../../../../../../components/table/tableAntDesign";
import EditTableUserPreferenceButton from "../../../../../../../../components/table/function/editTableUserPreferenceButton";
import useAuthorize from "../../../../../../../../_common/authorize";
import {notificationMessage} from "../../../../../../../../../recoil_state";
import IssueVoucherModal from "./issueVoucherModal";
import RedeemVoucherModal from "./redeemVoucherModal";
import SelectVoucherModal from "./selectVoucherModal";
import {formatDateTimeTimezone} from "../../../../../../../../function/_common";

const VoucherInformation = (props) => {
    const {data, permissionUser} = props;
    const {t} = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {baseErrorChecking, systemErrorNotification} = useAuthorize();
    const [isVoucherProgramLoading, setIsVoucherProgramLoading] = useState(false);
    const [isVoucherProgramActionLoading, setIsVoucherProgramActionLoading] = useState(false);
    const [voucherProgramFilter, setVoucherProgramFilter] = useState<any>({
        // isVoucherIssued: false,
        // isVoucherProgramExpired: false,
        user: data?._id,
    });
    const [voucherProgramLimit, setVoucherProgramLimit] = useState<any>(null);
    const [voucherProgramOffset, setVoucherProgramOffset] = useState<any>(0);
    const [voucherProgramOrderBy, setVoucherProgramOrderBy] = useState<any>("");
    const [voucherProgramTotal, setvoucherProgramTotal] = useState<any>(0);

    const [isLoading, setIsLoading] = useState(false);
    const [filter, setFilter] = useState<any>({
        user: data?._id,
    });
    const [limit, setLimit] = useState<any>(null);
    const [offset, setOffset] = useState<any>(0);
    const [orderBy, setOrderBy] = useState<any>("");
    const [userVouchers, setUserVouchers] = useState<any>([]);
    const [userVoucherProgram, setUserVoucherProgram] = useState<any>([]);
    const [total, setTotal] = useState<any>(0);

    const [isSelectVoucherLoading, setIsSelectVoucherLoading] = useState<boolean>(false);
    const [isApplyVoucherLoading, setIsApplyVoucherLoading] = useState<boolean>(false);
    const [issueModalIsOpen, setIssueModalIsOpen] = useState<boolean>(false);
    const [redeemVoucherModalIsOpen, setRedeemVoucherModalIsOpen] = useState<boolean>(false);
    const [selectVoucherModalIsOpen, setSelectVoucherModalIsOpen] = useState<boolean>(false);
    const [activeVoucher, setActiveVoucher] = useState<any>({});

    const toggleIssueModal = () => {
        setIssueModalIsOpen((prevState) => !prevState);
    };

    const toggleRedeemVoucherModal = (record?) => {
        setActiveVoucher(record);
        setRedeemVoucherModalIsOpen((prevState) => !prevState);
    };

    const toggleSelectVoucherModal = (record) => {
        setActiveVoucher(record);
        setSelectVoucherModalIsOpen((prevState) => !prevState);
    };

    const onChangePageHandlerVoucherProgram = (page, pageSize) => {
        setVoucherProgramLimit(pageSize);
        setVoucherProgramOffset(limit * (page - 1));
    };

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize);
        setOffset(limit * (page - 1));
    };

    const getUserVoucherProgramList = async () => {
        try {
            setIsVoucherProgramLoading(true);
            const response: any = await getUserVoucherProgram(voucherProgramFilter, voucherProgramLimit, voucherProgramOffset, voucherProgramOrderBy);
            setUserVoucherProgram(response?.getUserVoucherProgram?.data);
        } catch (e) {
        } finally {
            setIsVoucherProgramLoading(false);
        }
    };

    const getUserVouchersList = async () => {
        try {
            setIsLoading(true);
            const response = await getUserVouchers(filter, limit, offset, orderBy);
            setUserVouchers(response?.getUserVouchers?.data);
        } catch (e) {
        } finally {
            setIsLoading(false);
        }
    };

    const handleClaimVoucherProgram = async (record) => {
        try {
            setActiveVoucher(record);
            if (record?.voucherProgramId?.rewardValue === "all") {
                const rewards = record?.voucherProgramId?.rewards?.map((r) => {
                    if (r?.rewardType === "vouchers") {
                        return {rewardId: r?.reward?._id, voucherId: [r?.reward?._id]};
                    } else {
                        return {rewardId: r?.reward?._id, voucherId: r?.reward?.pool?.map((pool) => pool?.voucher?._id)};
                    }
                });
                await userSelectVoucherFunction(record?._id, {userId: data?._id, rewards: rewards});
            } else {
                toggleSelectVoucherModal(record);
            }
        } catch (error) {}
    };

    const handleApplyVoucher = async (record) => {
        try {
            setActiveVoucher(record);
            if (
                !record?.tradingAccountId ||
                (record?.voucherId?.rewardType === "tradingVoucher" && !record?.voucherId?.voucherStackable) ||
                (record?.voucherId?.rewardType === "bonusVoucher" && !record?.voucherId?.voucherStackable) ||
                record?.voucherId?.termAndConditionEnabled
            ) {
                toggleRedeemVoucherModal(record);
            } else {
                const res = await applyVoucherFunction(record?._id, {tradingAccountId: record?.tradingAccountId?._id || null});
            }
        } catch (error) {}
    };

    const userSelectVoucherFunction = async (id, input) => {
        try {
            setIsSelectVoucherLoading(true);
            const response = await userSelectVoucher(id, input);
            if (response?.userSelectVoucher?.__typename === "BaseError") {
                return await baseErrorChecking(response?.userSelectVoucher);
            } else {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Vouchers Claimed!",
                    key: "rewardNotification",
                });
                getUserVouchersList();
                getUserVoucherProgramList();
            }
        } catch (e) {
            systemErrorNotification();
        } finally {
            setIsSelectVoucherLoading(false);
        }
    };

    const applyVoucherFunction = async (id, input) => {
        try {
            setIsApplyVoucherLoading(true);
            const response = await applyVoucher(id, input);
            if (response?.applyVoucher?.__typename === "BaseError") {
                return await baseErrorChecking(response?.applyVoucher);
            } else {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Voucher Applied!",
                    key: "rewardNotification",
                });
                getUserVouchersList();
                if (redeemVoucherModalIsOpen) {
                    toggleRedeemVoucherModal()
                }
            }
        } catch (e) {
            systemErrorNotification();
        } finally {
            setIsApplyVoucherLoading(false);
        }
    };

    useEffect(() => {
        getUserVoucherProgramList();
        getUserVouchersList();
    }, [issueModalIsOpen]);

    const columnVoucherProgram: any = [
        {
            title: t("common.name"),
            dataIndex: "displayName",
            render: (_, record) => {
                let lang = localStorage.getItem("language");
                let name =
                    record?.voucherProgramId?.displayName?.find((v) => v.language === lang)?.content ||
                    record?.voucherProgramId?.displayName?.[0]?.content ||
                    record?.voucherProgramId?.name;
                return <p>{name}</p>;
            },
        },
        {
            title: t("common.description"),
            dataIndex: "description",
            width: 250,
            render: (_, record) => {
                let lang = localStorage.getItem("language");
                let description =
                    record?.voucherProgramId?.description?.find((v) => v.language === lang)?.content ||
                    record?.voucherProgramId?.description?.[0]?.content;
                return <p>{description || "-"}</p>;
            },
        },
        {
            title: t("reward.issuedDate"),
            dataIndex: "voucherIssuedAt",
            render: (text, record) => <p>{formatDateTimeTimezone(record?.createdAt) || "-"}</p>,
        },
        {
            title: t("reward.isClaimWithVoucherCode"),
            dataIndex: "isClaimWithVoucherCode",
            render: (text, record) => <p>{text ? "Yes" : "No"}</p>,
        },
        {
            title: t("reward.issuedBy"),
            dataIndex: "issuedBy",
            render: (issuedBy, record) => <p>{issuedBy?.username || "-"}</p>,
        },
        {
            title: t("reward.ipAddress"),
            dataIndex: "ipAddress",
            render: (text, record) => <p>{text}</p>,
        },
        {
            title: t("common.status"),
            dataIndex: "status",
            render: (_, record) => {
                let status = "Available";
                if (record?.isVoucherIssued) {
                    status = t("reward.issued");
                }
                if (record?.isVoucherProgramExpired) {
                    status = "reward.voucherStatus.expired";
                }
                return <p>{status}</p>;
            },
        },
        // {
        //     title: t("Device"),
        //     dataIndex: "device",
        //     render: (text) => <p>{text}</p>,
        // },
        // {
        //     title: t("Browser"),
        //     dataIndex: "browser",
        //     render: (text) => <p>{text}</p>,
        // },
    ];

    const amendedColumnVoucherProgram = useMemo(() => {
        let col = [...columnVoucherProgram];
        if (permissionUser?.clientsUser?.edit?.reward) {
            col.push({
                title: t("common.action"),
                dataIndex: "action",
                width: 132,
                render: (_, record: any) => {
                    return (
                        <Space size="middle">
                            {!record?.isVoucherIssued && !record?.isVoucherProgramExpired ? (
                                <Button
                                    size="small"
                                    onClick={() => handleClaimVoucherProgram(record)}
                                    loading={isSelectVoucherLoading && activeVoucher?._id === record?._id}
                                >
                                    {t("mission.claim")}
                                </Button>
                            ) : (
                                <p>-</p>
                            )}
                        </Space>
                    );
                },
            });
        }
        return col;
    }, [permissionUser]);

    const columnVoucher: any = [
        {
            title: t("common.name"),
            dataIndex: "displayName",
            render: (_, record) => {
                let lang = localStorage.getItem("language");
                let name =
                    record?.voucherId?.displayName?.find((v) => v.language === lang)?.content ||
                    record?.voucherId?.displayName?.[0]?.content ||
                    record?.voucherId?.name;
                return <p>{name}</p>;
            },
        },
        {
            title: t("common.description"),
            dataIndex: "description",
            width: 250,
            render: (_, record) => {
                let lang = localStorage.getItem("language");
                let description =
                    record?.voucherId?.description?.find((v) => v.language === lang)?.content || record?.voucherId?.description?.[0]?.content;
                return <p>{description}</p>;
            },
        },
        {
            title: t("reward.voucherType.title"),
            dataIndex: "rewardType",
            width: 250,
            render: (_, record) => {
                let rewardType = record?.voucherId?.rewardType;
                return <p>{t(`reward.voucherType.${rewardType}`)}</p>;
            },
        },
        {
            title: t("reward.issuedDate"),
            dataIndex: "voucherIssuedDate",
            render: (text, record) => <p>{(text && formatDateTimeTimezone(text)) || "-"}</p>,
        },
        {
            title: t("reward.redeemedDate"),
            dataIndex: "voucherAppliedDate",
            render: (text, record) => <p>{(text && formatDateTimeTimezone(text)) || "-"}</p>,
        },
        {
            title: t("reward.expiredDate"),
            dataIndex: "voucherExpiryDate",
            render: (text, record) => {
                const hasExpiryDate = moment(record?.voucherExpiryDate).isBefore(moment().add(50, "years"));
                return <p>{hasExpiryDate ? formatDateTimeTimezone(text) : "-"}</p>;
            },
        },
        {
            title: t("reward.account"),
            dataIndex: "tradingAccountId",
            render: (tradingAccount) => {
                if (tradingAccount?._id) {
                    return (
                        <a onClick={() => window.open("/account/trading-account/detail?id=" + tradingAccount?._id)}>{tradingAccount?.accountId}</a>
                    );
                }
                return <p>-</p>;
            },
        },
        {
            title: t("common.status"),
            dataIndex: "task",
            render: (_, record) => {
                let status = t("reward.voucherStatus.available");
                if (record?.isVoucherApplied) {
                    status = t("reward.voucherStatus.redeemed");
                }
                if (record?.isVoucherExpired) {
                    status = t("reward.voucherStatus.expired");
                }
                return <p>{status}</p>;
            },
        },
        // {
        //     title: t("Device"),
        //     dataIndex: "device",
        //     render: (text) => <p>{text}</p>,
        // },
        // {
        //     title: t("Browser"),
        //     dataIndex: "browser",
        //     render: (text) => <p>{text}</p>,
        // },
    ];

    const amendedColumnVoucher = useMemo(() => {
        let col = [...columnVoucher];
        if (permissionUser?.clientsUser?.edit?.reward) {
            col.push({
                title: t("common.action"),
                dataIndex: "action",
                width: 132,
                render: (_, record: any) => {
                    return (
                        <Space size="middle">
                            {!record?.isVoucherApplied && !record?.isVoucherExpired && (
                                <Button
                                    size="small"
                                    onClick={() => handleApplyVoucher(record)}
                                    loading={isApplyVoucherLoading}
                                    disabled={isApplyVoucherLoading}
                                >
                                    {t("reward.redeem")}
                                </Button>
                            )}
                        </Space>
                    );
                },
            });
        }
        return col;
    }, [permissionUser, isApplyVoucherLoading]);

    return (
        <>
            <div className={"margin-top-0-75"}>
                <Row justify={"space-between"} className={"margin-bottom-0-5"}>
                    <h3>{t("reward.pendingReward")}</h3>
                    <Space>
                        {permissionUser?.clientsUser?.add?.reward && (
                            <Button onClick={toggleIssueModal} className={"margin-left-auto"}>
                                {t("reward.issueReward")}
                            </Button>
                        )}
                        <EditTableUserPreferenceButton
                            tableName={"userVoucherProgramTable"}
                            displayTableName={t("reward.userVoucherProgramTable")}
                            tableColumn={amendedColumnVoucherProgram}
                        />
                    </Space>
                </Row>
                <TableAntDesign
                    data={userVoucherProgram}
                    tableName={"userVoucherProgramTable"}
                    columns={amendedColumnVoucherProgram}
                    size={"small"}
                    loading={isVoucherProgramLoading}
                    filter={setVoucherProgramFilter}
                    order={setVoucherProgramOrderBy}
                    isTableLocalSearch={true}
                    tableSpin={false}
                    pagination={{
                        pageSize: limit,
                        showSizeChanger: true,
                        onChange: (page, pageSize) => onChangePageHandlerVoucherProgram(page, pageSize),
                        total: total,
                        showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
                    }}
                />
            </div>
            <div className={"margin-top-0-75"}>
                <Row justify={"space-between"} className="margin-bottom-0-5">
                    <h3>{t("reward.voucher")}</h3>
                    <EditTableUserPreferenceButton tableName={"userVoucherTable"} tableColumn={amendedColumnVoucher} />
                </Row>
                <TableAntDesign
                    data={userVouchers}
                    tableName={"userVoucherTable"}
                    columns={amendedColumnVoucher}
                    size={"small"}
                    loading={isLoading || isApplyVoucherLoading}
                    filter={setFilter}
                    order={setOrderBy}
                    isTableLocalSearch={true}
                    tableSpin={false}
                    pagination={{
                        pageSize: limit,
                        showSizeChanger: true,
                        onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                        total: total,
                        showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
                    }}
                />
            </div>
            <IssueVoucherModal
                isOpen={issueModalIsOpen}
                close={toggleIssueModal}
                data={data}
                isVoucherProgramActionLoading={isVoucherProgramActionLoading}
                setIsVoucherProgramActionLoading={setIsVoucherProgramActionLoading}
            />
            <RedeemVoucherModal
                isOpen={redeemVoucherModalIsOpen}
                handleClose={toggleRedeemVoucherModal}
                item={activeVoucher}
                applyVoucherFunction={applyVoucherFunction}
                isApplyVoucherLoading={isApplyVoucherLoading}
            />
            <SelectVoucherModal
                data={data}
                isOpen={selectVoucherModalIsOpen}
                handleClose={toggleSelectVoucherModal}
                item={activeVoucher}
                userSelectVoucherFunction={userSelectVoucherFunction}
                isSelectVoucherLoading={isSelectVoucherLoading}
            />
        </>
    );
};

export default VoucherInformation;
