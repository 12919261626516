import React from "react";
import {useRecoilState} from "recoil";
import BrandDetails_1 from "./view/layout1";
import {getBrandSetting} from "../../../api/graphql/brandSetting";
import {getLanguage} from "../../../api/graphql/language";
import {selfPermissionObj} from "../../../../recoil_state";
import "./style/index.css"

function BrandDetails() {
    let layout = 1;
    let component;
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const brandDetailsPermission = selfPermission?.brandSetting?.brandDetails;
    const {loading, error, data} = getBrandSetting();
    const {languageData, loading: languageLoading} = getLanguage({
        filter: null,
    });

    if (!loading && !languageLoading) {
        switch (layout) {
            case 1:
                component = (
                    <BrandDetails_1
                        data={data?.getBrandSetting}
                        language={languageData?.getLanguage?.data}
                        loading={loading && languageLoading}
                        error={error}
                        brandDetailsPermission={brandDetailsPermission}
                    />
                );
                break;

            default:
                component = (
                    <BrandDetails_1
                        data={data?.getBrandSetting}
                        language={languageData?.getLanguage?.data}
                        loading={loading && languageLoading}
                        error={error}
                        brandDetailsPermission={brandDetailsPermission}
                    />
                );
                break;
        }
    }

    return component;
}

export default BrandDetails;
