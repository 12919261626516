import React, {useEffect, useState} from "react";
import {Col, Row, Space, Spin} from "antd";
import ProfileCard from "./component/profileCard";
import InformationCard from "./component/informationCard";
import ActionCard from "./component/actionCard";
import ActivityLogCard from "./component/activityLogCard";
import {useGetFollowUp} from "../../../../clients/detail/function/useQueryHooks";


const CrmUserDetail_1 = (props) => {
    const { data, profileInformation, permissionUser, referralSetting, refetchUserByID, crmUserId } = props
    const [loading,setLoading] = useState(true)

    const [followUp, fetchFollowUp, refetchFollowUp] = useGetFollowUp()

    useEffect(()=>{
        if(data && profileInformation && permissionUser){
            setLoading(false)
        }
    },[data, profileInformation, permissionUser])

    useEffect(() => {
        fetchFollowUp({followingUser: crmUserId})
    }, [crmUserId]);

    return (
        <div id="crmUserDetail">
            <Spin tip="Loading..." spinning={loading}>
                <Row gutter={[16,16]}>
                    <Col xs={24} xl={(permissionUser?.crmUser?.view?.followUp || permissionUser?.crmUser?.add?.followUp) ? 16 : 24}>
                        <Space direction={"vertical"} style={{width: "100%"}}>
                            <ProfileCard data={data} permissionUser={permissionUser} referralSetting={referralSetting} refetchUserByID={refetchUserByID}/>
                            <InformationCard data={data} profileInformation={profileInformation} permissionUser={permissionUser}/>
                        </Space>
                    </Col>
                    {(permissionUser?.crmUser?.view?.followUp || permissionUser?.crmUser?.add?.followUp) &&
                    <Col xs={24} xl={8}>
                        <Space direction={"vertical"} style={{width: "100%"}}>
                            {permissionUser?.crmUser?.add?.followUp && <ActionCard data={data} refetchFollowUp={refetchFollowUp}/>}
                            {permissionUser?.crmUser?.view?.followUp && <ActivityLogCard permissionUser={permissionUser} followUp={followUp} refetchFollowUp={refetchFollowUp}/>}
                        </Space>
                    </Col>
                    }
                </Row>
            </Spin>
        </div>
    )
}

export default CrmUserDetail_1;

