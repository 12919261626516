import React, {useEffect, useMemo, useState} from "react"
import {useRecoilValue} from "recoil"
import {useTranslation} from "react-i18next"
import {Button, Col, DatePicker, Dropdown, Radio, Row, Select, Space, Spin, Tag, Tooltip} from "antd"
import {DownOutlined, LoadingOutlined} from "@ant-design/icons"
import {salesRepNetworkOptionsState, deskOptionsState} from "../../../../../users/_common/state"
import {rangePresets} from "../../../function/common"
import EditTableUserPreferenceButton from "../../../../../../components/table/function/editTableUserPreferenceButton"
import PspSettlement from "./pspSettlement"
import PspAdjustment from "./pspAdjustment"
import {selfPermissionObj} from "../../../../../../../recoil_state"
import {getUserNetworkTreeDropdownBasedOnPermission} from "../../../../../../api/graphql/userNetworkTree"
import {formatUserNetworkOptions} from "../../../../../../function/_common"
import {checkIsMasterAdmin} from "../../../../../../api/graphql/user"
import {getConfigByName} from "../../../../../../../config"
import {BRAND_NAME} from "../../../../../../../config/brandVariable"
import { getSalesRepNetworkTreeDropdownBasedOnPermission } from "../../../../../../api/graphql/salesRepNetworkTree"

const {RangePicker} = DatePicker

const ReportTopBar = (props) => {
    const {
        selectedReportID,
        plReportId,
        columns,
        reportSummaryData,
        reportsPermission,
        toggleEditModal,
        toggleRepopulateReportModal,
        displayMode,
        onDisplayModeChg,
        selectedDates,
        onRangePickerChange,
        filterType,
        onFilterTypeChg,
        filterTypeId,
        setFilterTypeId,
        handleExportReport,
        reportViewMode,
        setReportViewMode,
        setRefreshReportData,
        isClearFilterVisible,
        resetFilters,
        reportDataloading,
        resetTableName,
    } = props
    const {t} = useTranslation()
    const {data: userNetworkTree} = getUserNetworkTreeDropdownBasedOnPermission({permission: "reports:reports:view"})
    const {data: salesRepNetworkTree} = getSalesRepNetworkTreeDropdownBasedOnPermission({permission: "reports:reports:view"})
    const {isMasterAdmin} = checkIsMasterAdmin(localStorage.getItem("g_userId") || localStorage.getItem("userId"))
    const brandConfig = getConfigByName(BRAND_NAME)

    const [userNetworkOptions, setUserNetworkOptions] = useState<any>([])
    const [salesRepNetworkOptions, setSalesRepNetworkOptions] = useState<any>([])
    // const salesRepNetworkOptions = useRecoilValue(salesRepNetworkOptionsState)
    const deskOptions = useRecoilValue(deskOptionsState)
    const selfPermission = useRecoilValue<any>(selfPermissionObj)
    const pspAdjustmentPermission = selfPermission?.reports?.pspAdjustment
    const pspSettlementPermission = selfPermission?.reports?.pspSettlement

    useEffect(() => {
        if (userNetworkTree?.getUserNetworkTreeDropdownBasedOnPermission?.data?.length > 0) {
            setUserNetworkOptions(userNetworkTree?.getUserNetworkTreeDropdownBasedOnPermission?.data?.map((d) => formatUserNetworkOptions(d)))
        }
    }, [userNetworkTree])

    useEffect(() => {
        if (salesRepNetworkTree?.getSalesRepNetworkTreeDropdownBasedOnPermission?.data?.length > 0) {
            setSalesRepNetworkOptions(salesRepNetworkTree?.getSalesRepNetworkTreeDropdownBasedOnPermission?.data?.map((d) => formatUserNetworkOptions(d)))
        }
    }, [salesRepNetworkTree])

    const reportName = useMemo(() => {
        return reportSummaryData?.displayName || ""
    }, [reportSummaryData])

    const reportFilterByType = useMemo(() => {
        return reportSummaryData?.filterByType || ""
    }, [reportSummaryData])

    const reportStatusList = useMemo(() => {
        return reportSummaryData?.reportStatusList?.filter((s) => s?.brandName === brandConfig?.reportingBrandName)?.map((v) => v.status) || []
    }, [reportSummaryData])

    const isRepopulateBtnDisabled = useMemo(() => {
        return reportStatusList?.includes("CALCULATING_WITH_DATA") || reportStatusList?.includes("CALCULATING_WITHOUT_DATA") || reportStatusList?.includes("PENDING")
    }, [reportSummaryData])

    const reportStatusTag = useMemo(() => {
        let tagColor = ""
        let tagText = reportSummaryData?.status

        if (reportStatusList?.includes("CALCULATING_WITH_DATA")) {
            tagColor = "yellow"
            tagText = "RECALCULATING DATA"
        } else if (reportStatusList?.includes("CALCULATING_WITHOUT_DATA")) {
            tagColor = "yellow"
            tagText = "Generating Data"
        } else if (reportStatusList?.includes("PENDING")) {
            tagColor = "red"
            tagText = "PENDING Creation"
        } else {
            tagColor = "green"
            tagText = "ACTIVE"
        }
        return (
            <Tag color={tagColor} className="margin-right-0-5">
                {tagText}
            </Tag>
        )
    }, [reportSummaryData])

    const networkFilterOptions = useMemo(() => {
        let list: any = []

        reportFilterByType?.length > 0 &&
            reportFilterByType?.map((type) => {
                switch (type) {
                    case "USER":
                        list.push(
                            {value: "USERNETWORK", label: t("common.userNetwork")}
                            // {value: "USERNETWORKONLY", label: t("common.userNetworkOnly")},
                        )
                        break
                    case "CRMUSER":
                        list.push(
                            {value: "SALESREPNETWORK", label: t("common.salesRepNetwork")}
                            // {value: "SALESREPNETWORKONLY", label: t("common.salesRepNetworkOnly")},
                        )
                        break
                    case "DESK":
                        list.push(
                            {value: "DESK", label: t("common.desk")},
                            {value: "DESKNETWORK", label: t("common.deskNetwork")}
                            // {value: "DESKNETWORKONLY", label: t("common.deskNetworkOnly")},
                        )
                        break
                    case "SALESREP":
                        list.push(
                            // {value: "SALESREP", label: t("common.salesRep")}, // userid
                            {value: "SALESREPNETWORK", label: t("common.salesRepNetwork")} // Sales Rep network tree id
                            // {value: "SALESREPNETWORKONLY", label: t("common.salesRepNetworkOnly")},
                        )
                        break
                    default:
                        break
                }
            })

        return list
    }, [reportFilterByType])

    const NetworkFilterSection = () => (
        <div className="margin-top-0-5">
            {/* {reportViewMode !== "tree" && ( */}
            <>
                <label className="margin-right-0-5">Filter By</label>
                <Select
                    value={filterType}
                    style={{width: 150}}
                    onChange={(value) => {
                        onFilterTypeChg(value)
                    }}
                    options={networkFilterOptions}
                    disabled={reportDataloading}
                />
                {(filterType === "USERNETWORK" || filterType === "USERNETWORKONLY") && (
                    <Select
                        id="userNetworkFilter"
                        style={{width: 250}}
                        value={filterTypeId}
                        onChange={(value) => {
                            setFilterTypeId(value)
                        }}
                        options={userNetworkOptions}
                        optionFilterProp={"label"}
                        optionLabelProp="selectedLabel"
                        showSearch
                        mode="multiple"
                        disabled={reportDataloading}
                    />
                )}
                {(filterType === "DESK" || filterType === "DESKNETWORK" || filterType === "DESKNETWORKONLY") && (
                    <Select
                        id="deskNetworkFilter"
                        style={{width: 250}}
                        value={filterTypeId}
                        onChange={(value) => {
                            setFilterTypeId(value)
                        }}
                        options={deskOptions}
                        optionFilterProp={"label"}
                        optionLabelProp="selectedLabel"
                        showSearch
                        mode="multiple"
                        disabled={reportDataloading}
                    />
                )}
                {(filterType === "SALESREPNETWORK" || filterType === "SALESREPNETWORKONLY") && (
                    <Select
                        id="salesRepNetworkFilter"
                        style={{width: 250}}
                        value={filterTypeId}
                        onChange={(value) => {
                            setFilterTypeId(value)
                        }}
                        options={salesRepNetworkOptions}
                        optionFilterProp={"label"}
                        optionLabelProp="selectedLabel"
                        showSearch
                        mode="multiple"
                        disabled={reportDataloading}
                    />
                )}
            </>
            {/* )} */}
        </div>
    )

    const moreOptionsItems = [
        {
            key: "1",
            label: (
                <Button type={"link"} onClick={handleExportReport}>
                    {t("Export Report")}
                </Button>
            ),
        },
        reportsPermission?.edit?.edit && {
            key: "2",
            label: (
                <Button type={"link"} onClick={toggleEditModal}>
                    {t("Edit Report")}
                </Button>
            ),
        },
    ]

    if (isMasterAdmin && reportSummaryData?.populationPath) {
        moreOptionsItems?.push({
            key: "3",
            label: (
                <Button type={"link"} onClick={toggleRepopulateReportModal} disabled={isRepopulateBtnDisabled}>
                    {t("Repopulate Report")}
                </Button>
            ),
        })
    }

    const isNetworkFilterAvailable = reportFilterByType?.length > 0

    const pspDetailedReportId = "64ef5915c7983ee3aa8daea2"
    const pspSummaryReportId = "64f0101a91115547418ddfe4"

    const isPspSettlementAdjustmentBtnShown = [pspDetailedReportId, pspSummaryReportId]?.includes(selectedReportID) && (pspAdjustmentPermission?.view || pspSettlementPermission?.view)

    return (
        <>
            <Row justify="space-between" align="middle" className="margin-bottom-0-5">
                <Col md={isNetworkFilterAvailable ? (isPspSettlementAdjustmentBtnShown ? 10 : 24) : 10}>
                    <div className="display-flex align-items-center">
                        <h3 className="margin-bottom-0">
                            <Tooltip title={reportSummaryData?.description} className="clickable margin-right-0-25">
                                {reportName}
                            </Tooltip>
                        </h3>
                        {reportsPermission?.add?.add && reportsPermission?.edit?.edit && (
                            <>
                                {isMasterAdmin && reportSummaryData?.level && (
                                    <Tag color="blue" className="margin-right-0-25">
                                        Level {reportSummaryData?.level}
                                    </Tag>
                                )}
                                {reportSummaryData?.groupBy && (
                                    <Tag color="purple" className="margin-right-0-25">
                                        {t("Group By")}
                                    </Tag>
                                )}
                                {reportSummaryData?.status && reportStatusTag}
                            </>
                        )}
                        {isClearFilterVisible ? (
                            <Button size={"small"} onClick={() => resetFilters()}>
                                {t("common.clearFilter")}
                            </Button>
                        ) : null}
                        {/* {reportDataloading && <Spin indicator={<LoadingOutlined style={{fontSize: 16}} spin />} />} */}
                    </div>
                </Col>
                {isPspSettlementAdjustmentBtnShown && (
                    <Col sm={24} md={14} className="display-flex justify-content-end margin-bottom-0-5">
                        <Space>
                            <PspSettlement setRefreshReportData={setRefreshReportData} />
                            <PspAdjustment setRefreshReportData={setRefreshReportData} />
                        </Space>
                    </Col>
                )}
                {isNetworkFilterAvailable && (
                    <Col sm={24} md={10}>
                        <NetworkFilterSection />
                    </Col>
                )}
                <Col sm={24} md={14} offset={isPspSettlementAdjustmentBtnShown && !isNetworkFilterAvailable ? 10 : 0}>
                    <Space className="display-flex justify-content-end flex-wrap">
                        <Dropdown menu={{items: moreOptionsItems}} trigger={["click"]} disabled={reportDataloading}>
                            <Button type={"dashed"}>
                                <Space>
                                    {t("common.moreOptions")}
                                    <DownOutlined style={{fontSize: 12}} />
                                </Space>
                            </Button>
                        </Dropdown>
                        {selectedReportID === plReportId && (
                            <Radio.Group onChange={(e) => setReportViewMode(e?.target?.value)} value={reportViewMode}>
                                <Radio.Button value={"table"} disabled={reportDataloading}>
                                    Table View
                                </Radio.Button>
                                <Radio.Button value={"tree"} disabled={reportDataloading || displayMode === "DATE"}>
                                    Tree View
                                </Radio.Button>
                            </Radio.Group>
                        )}
                        {reportSummaryData?.groupBy && (
                            <Radio.Group onChange={(e) => onDisplayModeChg(e?.target?.value)} value={displayMode}>
                                <Radio.Button value={"SUMUP"} disabled={reportDataloading}>
                                    By Total
                                </Radio.Button>
                                <Radio.Button value={"DATE"} disabled={reportDataloading || reportViewMode === "tree"}>
                                    By Date
                                </Radio.Button>
                            </Radio.Group>
                        )}
                        <RangePicker presets={rangePresets} onChange={onRangePickerChange} value={selectedDates} disabled={reportDataloading} />
                        <EditTableUserPreferenceButton
                            tableName={selectedReportID}
                            tableColumn={columns}
                            displayTableName={reportName}
                            resetTableName={resetTableName}
                            resetColumnTooltipTitle={"common.reset_based_on_commission_calculation_report_column"}
                        />
                    </Space>
                </Col>
            </Row>
        </>
    )
}

export default ReportTopBar
