import React from "react";
import {Image} from "antd"

export const columns = () => {
    return [
        {
            title: "Display Name",
            dataIndex: "displayName",
            key: "displayName",
            render: (text) => {
                const found = text?.find(d => d?.language === (localStorage.getItem("language") || "en"))

                return <p>{found ? found?.content : "-"}</p>
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: "Icon (Light)",
            dataIndex: "light",
            key: "light",
            render: (text) => text ? <Image preview={false} src={text}/> : "-",
        },
        {
            title: "Icon (dark)",
            dataIndex: "dark",
            key: "dark",
            render: (text) => text ? <Image preview={false} src={text}/> : "-",
        },
        {
            title: "Max Count",
            dataIndex: "maxCount",
            key: "maxCount",
            render: (text) => <p>{text || "-"}</p>,
        },
    ]
}