import React from "react";
import moment from "moment";
import {getColumnSearchProps} from "../../../../components/table/function/filterTable";
import {Tag} from "antd";

export const columns = () => {
    const platformOptions = [
        {text: "Web", value: "web", color: "blue"},
        {text: "Mobile", value: "mobile", color: "orange"},
        {text: "App (iOS)", value: "app_ios", color: "purple"},
        {text: "App (Android)", value: "app_android", color: "green"},
    ];

    return [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: "Updated at",
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (text) => {
                if (text) {
                    return <p>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</p>
                } else {
                    return <p>-</p>
                }
            },
        },
        {
            title: "Platform",
            dataIndex: "targetPlatform",
            key: "targetPlatform",
            sorter: (a, b) => a?.targetPlatform?.localeCompare(b?.targetPlatform),
            render: (value) =>
                value?.map((platform, i) => {
                    const {text, color} =
                    platformOptions.find(option => option.value === platform) || {text: platform, color: "red"};

                    return (
                        <Tag
                            key={i}
                            bordered={false}
                            color={color}
                        >
                            {text}
                        </Tag>
                    );
                })
        },
        {
            title: "Status",
            dataIndex: "isActive",
            key: "isActive",
            filters: [
                {text: "Active", value: true},
                {text: "Inactive", value: false},
            ],
            filterMultiple: false,
            render: (isActive) => <p>{isActive ? "Active" : "Inactive"}</p>,
        },
    ];
};

