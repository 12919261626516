import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {Button, Checkbox, Modal, Row, Space} from "antd"
import {useSetRecoilState} from "recoil"
import {adjustCommissionSwapByClosedPositionId} from "../../../../../../../api/graphql/voidReopenClosedPosition"
import useAuthorize from "../../../../../../../_common/authorize"
import {notificationMessage} from "../../../../../../../../recoil_state"

const AdjustCommissionSwapModalCT = ({isOpen, handleCancel, closedPosition, accountId, getReportDetails, refreshQuery}) => {
    const {t} = useTranslation()
    const [commissionSelected, setCommissionSelected] = useState<boolean>(true)
    const [swapSelected, setSwapSelected] = useState<boolean>(true)
    const [submitLoading, setSubmitLoading] = useState<boolean>(false)
    const setNotificationMessage = useSetRecoilState(notificationMessage)
    const {baseErrorChecking, systemErrorNotification} = useAuthorize()

    const positionId = closedPosition?.positionId?.displayValue?.toString()
    const title = "Adjust Commission/Swap"

    const handleAdjustCommissionSwap = async () => {
        try {
            setSubmitLoading(true)

            if (!commissionSelected && !swapSelected) {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t("Please Select at least one to proceed"),
                    key: "adjustCommissionSwapByClosedPositionId",
                })
            } else {
                let commission = closedPosition?.commission?.displayValue
                let swap = closedPosition?.swap?.displayValue
                const response = await adjustCommissionSwapByClosedPositionId(accountId, positionId, commissionSelected, swapSelected, commission, swap, {
                    fieldName: "commissionStatus",
                    rowId: closedPosition?._id?.displayValue,
                    value: "VOID",
                })
                if (response?.adjustCommissionSwapByClosedPositionId?.__typename === "BaseError") {
                    baseErrorChecking(response?.adjustCommissionSwapByClosedPositionId)
                } else if (response?.adjustCommissionSwapByClosedPositionId?.status === "success") {
                    getReportDetails()
                    refreshQuery()
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: t("Adjust Successfully."),
                        key: "adjustCommissionSwapByClosedPositionId",
                    })
                }
                handleCancel()
            }
        } catch (e) {
            systemErrorNotification()
        } finally {
            setSubmitLoading(false)
        }
    }

    const onCommissionChange = (e) => {
        try {
            setCommissionSelected(e?.target?.checked)
        } catch (error) {}
    }

    const onSwapChange = (e) => {
        try {
            setSwapSelected(e?.target?.checked)
        } catch (error) {}
    }

    useEffect(() => {}, [isOpen])

    return (
        <Modal
            title={title}
            open={isOpen}
            onCancel={handleCancel}
            footer={
                <Row justify={"end"}>
                    <Space>
                        <Button onClick={handleCancel}>{t("common.cancel")}</Button>
                        <Button loading={submitLoading} onClick={handleAdjustCommissionSwap} type={"primary"}>
                            {t("common.confirm")}
                        </Button>
                    </Space>
                </Row>
            }
        >
            <p>Please Select at least one to proceed</p>
            <Checkbox onChange={onCommissionChange} checked={commissionSelected}>
                Commission
            </Checkbox>
            <Checkbox onChange={onSwapChange} checked={swapSelected}>
                Swap
            </Checkbox>
        </Modal>
    )
}

export default AdjustCommissionSwapModalCT
