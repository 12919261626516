import React from "react";
import Message_1 from "./view/layout1";
import "./view/css/style.css"

const Messages = () => {
    let layout = 1;
    let component;

    switch (layout) {
        case 1:
            component = <Message_1/>
            break;

        default:
            component = <Message_1/>
            break;
    }

    return component;
}

export default Messages