import React, {useEffect} from "react";
import AutomationSetting_1 from "./view/layout1";
import {useLocation} from "react-router-dom";
import {BRAND_NAME} from "../../../../../config/brandVariable";
import {useGetRule} from "./function/useGetQueryHooks";
import {useGetCustomActionTemplate, useGetCustomActionTemplateByTrigger, useGetCustomTrigger} from "../_common/function/useGetQueryHooks";

const AutomationSetting = () => {
    let layout = 1;
    let component: any;
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id') || null;

    const [data, fetchRule] = useGetRule()
    const [trigger, fetchTrigger] = useGetCustomTrigger()
    const [actionTemplate, fetchActionTemplate, emptyActionTemplate] = useGetCustomActionTemplateByTrigger()
    const [actionTemplateReport, fetchActionTemplateReport] = useGetCustomActionTemplate()

    useEffect(() => {
        fetchTrigger()
        fetchRule()
        fetchActionTemplateReport({reportOnly: true})
    }, []);

    const props = {
        data: data,
        actionTemplate: actionTemplate,
        actionTemplateReport: actionTemplateReport,
        trigger: trigger,
        fetchActionTemplate: fetchActionTemplate,
        emptyActionTemplate: emptyActionTemplate,
        fetchRule: fetchRule,
    }

    switch (layout) {
        case 1:
            component = (
                <AutomationSetting_1 {...props}/>
            );
            break;

        default:
            component = (
                <AutomationSetting_1 {...props}/>
            );
            break;
    }

    // Hard code automation setting for Antos and Exco
    return ["antospnc", "antospncOld", "blueocean", "excotrades", "ridgewaymarkets", "fundamarkets", "minosfutures", "cronoscap", ].includes(BRAND_NAME) ? component : <></>;
}

export default AutomationSetting