import axios from "axios";
import {getConfigByName} from "../../../../config";
import {BRAND_NAME} from "../../../../config/brandVariable";

const url = getConfigByName(BRAND_NAME).automationURL
const config = {headers: {"Content-Type": "text/plain"}}

// TRIGGER
export async function getRuleCondition(param = {}) {
    try {
        const filter = {filter: param};
        return await axios.post(`${url}/api/rule_condition/get`, JSON.stringify(filter), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export async function createRuleCondition (input) {
    try {
        return await axios.post(`${url}/api/rule_condition/create`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export async function updateRuleCondition (input) {
    try {
        return await axios.post(`${url}/api/rule_condition/update`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export async function deleteRuleCondition (input) {
    try {
        return await axios.post(`${url}/api/rule_condition/delete`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

// SCHEDULE
export async function getRuleConditionSchedule(param = {}) {
    try {
        const filter = {filter: param};
        return await axios.post(`${url}/api/rule_condition_report/get`, JSON.stringify(filter), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export async function createRuleConditionSchedule (input) {
    try {
        return await axios.post(`${url}/api/rule_condition_report/create`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export async function updateRuleConditionSchedule (input) {
    try {
        return await axios.post(`${url}/api/rule_condition_report/update`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export async function deleteRuleConditionSchedule (input) {
    try {
        return await axios.post(`${url}/api/rule_condition_report/delete`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}