import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";

const GET_PROMO_MATERIAL_BY_ID = gql`
    query getPromoMaterialByID($id: String!) {
        getPromoMaterialByID(id: $id) {
            ... on PromoMaterial {
                _id
                id
                type
                name
                displayName {
                    label
                    language
                }
                campaign {
                    _id
                    name
                    displayName {
                        label
                        language
                    }
                    isDeleted
                }
                promoTag {
                    _id
                    name
                    displayName {
                        label
                        language
                    }
                    isDeleted
                }
                leadTag {
                    _id
                    name
                    displayName {
                        label
                        language
                    }
                    isDeleted
                }
                networkType
                network {
                    _id
                    id
                    username
                }
                exclude {
                    _id
                    id
                    username
                }
                file {
                    link
                    size
                    language
                }
                redirectUrl
                isActive
                isDeleted
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getPromoMaterialById = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            GET_PROMO_MATERIAL_BY_ID,
            {
                id: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_PROMO_MATERIAL = gql`
    query getPromoMaterial($filter: PromoMaterialSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
        getPromoMaterial(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on PromoMaterialArray {
                total
                data {
                    _id
                    id
                    type
                    name
                    displayName {
                        label
                        language
                    }
                    campaign {
                        _id
                        name
                        displayName {
                            label
                            language
                        }
                        isDeleted
                    }
                    promoTag {
                        _id
                        name
                        displayName {
                            label
                            language
                        }
                        isDeleted
                    }
                    leadTag {
                        _id
                        name
                        displayName {
                            label
                            language
                        }
                        isDeleted
                    }
                    networkType
                    network {
                        _id
                        id
                        username
                    }
                    exclude {
                        _id
                        id
                        username
                    }
                    file {
                        link
                        size
                        language
                    }
                    redirectUrl
                    isActive
                    isDeleted
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getPromoMaterial = async ({filter}, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_PROMO_MATERIAL,
            {
                filter: {
                    type: filter?.type || null,
                    name: filter?.name || null,
                    campaign: filter?.campaign || null,
                    promoTag: filter?.promoTag || null,
                    leadTag: filter?.leadTag || null,
                    networkType: filter?.networkType || null,
                    network: filter?.network || null,
                    exclude: filter?.exclude || null,
                    redirectUrl: filter?.redirectUrl || null,
                    isActive: filter?.isActive,
                },
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_PROMO_MATERIAL = gql`
    mutation addPromoMaterial($input: PromoMaterialInput) {
        addPromoMaterial(input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on PromoMaterial {
                _id
                id
                type
                name
                displayName {
                    label
                    language
                }
                campaign {
                    _id
                    name
                    displayName {
                        label
                        language
                    }
                }
                promoTag {
                    _id
                    name
                    displayName {
                        label
                        language
                    }
                }
                leadTag {
                    _id
                    name
                    displayName {
                        label
                        language
                    }
                }
                networkType
                network {
                    _id
                    id
                    username
                }
                exclude {
                    _id
                    id
                    username
                }
                file {
                    link
                    size
                    language
                }
                redirectUrl
                isActive
                isDeleted
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const addPromoMaterial = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            ADD_PROMO_MATERIAL,
            {
                input: {
                    type: input.type || null,
                    name: input.name || null,
                    displayName: input.displayName || null,
                    campaign: input.campaign || null,
                    promoTag: input.promoTag || null,
                    leadTag: input.leadTag || null,
                    file: input.file || null,
                    redirectUrl: input.redirectUrl || null,
                    networkType: input.networkType || null,
                    network: input.network || null,
                    exclude: input.exclude || null,
                    isActive: input.isActive || null,
                },
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UPDATE_PROMO_MATERIAL = gql`
    mutation updatePromoMaterial($id: String!, $input: PromoMaterialInput) {
        updatePromoMaterial(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on PromoMaterial {
                _id
                id
                type
                name
                displayName {
                    label
                    language
                }
                campaign {
                    _id
                    name
                    displayName {
                        label
                        language
                    }
                }
                promoTag {
                    _id
                    name
                    displayName {
                        label
                        language
                    }
                }
                leadTag {
                    _id
                    name
                    displayName {
                        label
                        language
                    }
                }
                networkType
                network {
                    _id
                    id
                    username
                }
                exclude {
                    _id
                    id
                    username
                }
                file {
                    link
                    size
                    language
                }
                redirectUrl
                isActive
                isDeleted
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const updatePromoMaterial = async (id, input) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_PROMO_MATERIAL,
            {
                id: id,
                input: {
                    type: input.type || null,
                    name: input.name || null,
                    displayName: input.displayName || null,
                    campaign: input.campaign || null,
                    promoTag: input.promoTag || null,
                    leadTag: input.leadTag || null,
                    file: input.file || null,
                    redirectUrl: input.redirectUrl || null,
                    networkType: input.networkType || null,
                    network: input.network || null,
                    exclude: input.exclude || null,
                    isActive: input.isActive || null,
                },
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const DELETE_PROMO_MATERIAL = gql`
    mutation deletePromoMaterial($id: String!) {
        deletePromoMaterial(id: $id) {
            ... on CrmTaskApproval {
                msg
            }
            ... on PromoMaterial {
                _id
                id
                isDeleted
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const deletePromoMaterial = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            DELETE_PROMO_MATERIAL,
            {
                id: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

export {getPromoMaterialById, getPromoMaterial, addPromoMaterial, updatePromoMaterial, deletePromoMaterial};
