import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Checkbox, Col, Form, Input, Radio, Row, Select, Tabs, Tag} from "antd"
import {useRecoilState, useRecoilValue} from "recoil";
import {languageOptionsState, userOptionsState} from "../../../../../../../../users/_common/state";
import {Editor} from "@tinymce/tinymce-react";
import {getConfigByName} from "../../../../../../../../../../config";
import {BRAND_NAME} from "../../../../../../../../../../config/brandVariable";
import {useGetEmailSenderDropdown} from "../../../../../../../../messages/function/useGetQueryHooks";
import {dataByLanguageState, selectedEmailTemplateState} from "../../../../../state";

const SendEmailForm = ({form, mode, emailTemplate}) => {
    const {t} = useTranslation()
    const languageOptions = useRecoilValue<any>(languageOptionsState)
    const userOptions = useRecoilValue<any>(userOptionsState);
    const brandConfig = getConfigByName(BRAND_NAME);
    const editorRef = useRef<any>(null);

    const [emailType, senderEmail, fetchEmailType] = useGetEmailSenderDropdown()

    const [userGroup, setUserGroup] = useState("")
    const [extraEmail, setExtraEmail] = useState<string[]>([])
    const [isFixedEmail, setIsFixedEmail] = useState<boolean | null>(null);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [currentLanguage, setCurrentLanguage] = useState<string>("en");
    const [initialData, setInitialData] = useState({})
    const [selectedEmailTemplate, setSelectedEmailTemplate] = useRecoilState<any>(selectedEmailTemplateState);
    const [dataByLanguage, setDataByLanguage] = useRecoilState<any>(dataByLanguageState)

    const resetFormFields = () => {
        form.setFieldValue("type", "email")
        const initialValue = form.getFieldsValue()

        if (mode === "edit" && initialValue?.action) {
            const template = emailTemplate?.find(d => d?.name === initialValue?.name)
            setSelectedEmailTemplate(template)

            let userType
            const emails: string[] = []
            const toValue = initialValue?.to?.length > 0 ? initialValue?.to?.map(email => {
                const found = userOptions?.find(e => e?.email === email)
                if (found) {
                    userType = found?.userType
                    return email
                } else {
                    emails.push(email)
                }
            })?.filter(d => d) : []

            setUserGroup(userType)
            setExtraEmail(emails)
            setIsFixedEmail(template?.isFixedEmail)

            form.setFieldsValue({
                network: initialValue?.sendInNetwork,
                template: template?.name,
                isFixedEmail: template?.isFixedEmail,
                from: template?.from?._id,
                emailType: template?.emailType?._id,
                toGroup: userType,
                to: toValue,
                to_extraMultiSelect: emails?.join(",")
            })
        } else if (initialValue?.action) {
            form.setFieldsValue({action: "Send Email"})
        } else {
            form.resetFields()
        }
    }

    useEffect(() => {
        fetchEmailType()

        setUserGroup("")
        setSelectedEmailTemplate(null)
        setIsEditing(false)
        setExtraEmail([])
        setDataByLanguage({})
        setIsFixedEmail(null)
        setSelectedEmailTemplate(null)

        setTimeout(() => {
            resetFormFields()
        },500)

    }, []);

    const handleRemove = (str) => {
        const index = extraEmail.indexOf(str);
        extraEmail.splice(index, 1)

        form.setFieldValue(`to_extraMultiSelect`, extraEmail.join(","))
    }

    const extraInputOnKeyDown = (e, field) => {
        const error = form.getFieldError(field);
        if (error.length) {
            form.setFields([
                {
                    name: "to_extraInput",
                    errors: []
                }
            ])
        }

        if (e.key === "Enter") {
            e.preventDefault();
            const originalValue: string = form.getFieldValue(field)

            const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

            if (!!originalValue?.match(validRegex)) {
                setExtraEmail(prev => [...prev, originalValue])
                form.setFieldValue(`to_extraMultiSelect`, form.getFieldValue("to_extraMultiSelect") ? `${form.getFieldValue("to_extraMultiSelect")},${originalValue}` : originalValue)

                form.setFieldValue(field, "")
            } else {
                form.setFields([
                    {
                        name: "to_extraInput",
                        errors: [t("errorMsg.type.email")]
                    }
                ])
            }
        }
    }

    const contentTabs = languageOptions?.map(lang => {
        const html = selectedEmailTemplate?.htmlObject?.find(d => d?.language === lang?.value)?.html

        return {
            key: lang?.value,
            label: lang?.label,
            children: (
                <div className={"margin-top-0-75"}>
                    <Form.Item label={t("message.subject")} name={`subject_${lang?.value}`}>
                        <Input disabled={!isEditing}/>
                    </Form.Item>

                    <Form.Item className={"margin-top-0-75"} wrapperCol={{span: 24}}>
                        {
                            !isEditing &&
                            <div>
                                <p>{t("message.content_preview")}: </p>
                                <div className={'tinyMceParagraph'} dangerouslySetInnerHTML={{__html: html}}/>
                            </div>
                        }
                    </Form.Item>
                </div>
            ),
            forceRender: true
        }
    })

    const onTabChangeHandler = (e) => {
        setCurrentLanguage(e)
        setInitialData(dataByLanguage)
    }


    const setEmailInitialValue = () => {
        selectedEmailTemplate?.htmlObject?.map(d => {
            const foundLanguage = languageOptions?.find(lang => lang?.value === d?.language)?.value
            if (foundLanguage) {
                setDataByLanguage(prev => ({
                    ...prev,
                    [`content_${foundLanguage}`]: d?.html
                }))

                setInitialData(prev => ({
                    ...prev,
                    [`content_${foundLanguage}`]: d?.html
                }))
            }
        })

        selectedEmailTemplate?.displaySubjectLanguage?.map(d => {
            const foundLanguage = languageOptions?.find(lang => lang?.value === d?.language)?.value
            if (foundLanguage) {
                form.setFieldValue(`subject_${foundLanguage}`, d?.label)
            }
        })
    }

    useEffect(() => {
        if (selectedEmailTemplate) setEmailInitialValue()
    }, [selectedEmailTemplate]);

    return (
        <div id={"send-email-custom-form"}>
            <Form.Item label={t("message.to")}>
                <Row gutter={8}>
                    <Col span={5}>
                        <Form.Item name={"toGroup"} wrapperCol={{span: 24}}>
                            <Select
                                options={[
                                    {label: t("user.client"), value: "client"},
                                    {label: t("user.crmUser"), value: "crmUser"},
                                ]}
                                onChange={(e) => {
                                    setUserGroup(e)
                                    form.setFieldValue("to", [])
                                }}
                                placeholder={t("Select group")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={19}>
                        <Form.Item name={"to"} wrapperCol={{span: 24}}>
                            <Select
                                options={userOptions?.filter(d => d?.userType === userGroup)?.map(d => ({...d, value: d?.email}))}
                                mode={"multiple"}
                                maxTagCount={1}
                                optionFilterProp={"label"}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form.Item>
            {
                extraEmail?.length > 0 &&
                <Row>
                    <Col span={18} push={6}>
                        {
                            extraEmail?.map(email => {
                                return (
                                    email &&
                                    <Tag className={"margin-bottom-0-3"} key={email} closable onClose={() => handleRemove(email)}>{email}</Tag>
                                )
                            })
                        }
                    </Col>
                </Row>
            }
            <Form.Item label={(" ")} name={"to_extraInput"} colon={false}>
                <Input onKeyDown={(e) => extraInputOnKeyDown(e, "to_extraInput")} placeholder={t("message.to_extra_input.placeholder").toString()}/>
            </Form.Item>
            <Form.Item name={"to_extraMultiSelect"} style={{height: 0}}/>

            <Form.Item label={t("message.network")} name={"sendInNetwork"} valuePropName={"checked"}>
                <Checkbox>{t("message.network_note")}</Checkbox>
            </Form.Item>

            <Form.Item label={t("message.email_template")} name={"name"}>
                <Select
                    options={emailTemplate?.filter(d => d?.type === "custom" || d?.type === "system")?.map(d => ({
                        label: d.displayName || d.name,
                        value: d.name
                    }))}
                    onChange={(e) => setSelectedEmailTemplate(emailTemplate?.find(d => d.name === e))}
                    disabled={mode === "edit" && form.getFieldValue("name")}
                />
            </Form.Item>

            <Form.Item label={t("message.sender")} name={"isFixedEmail"}>
                <Radio.Group
                    options={[
                        {label: t("message.sender_option.user_domain"), value: false},
                        {label: t("message.sender_option.fixed_email"), value: true},
                    ]}
                    onChange={(e) => setIsFixedEmail(e.target.value)}
                />
            </Form.Item>
            {
                isFixedEmail ? (
                    <Form.Item label={t("message.sender_email")} name={"from"}>
                        <Select
                            options={senderEmail}
                        />
                    </Form.Item>
                ) : isFixedEmail === false ? (
                    <Form.Item label={t("message.email_type")} name={"emailType"}>
                        <Select
                            options={emailType}
                        />
                    </Form.Item>
                ) : <></>
            }
            {
                selectedEmailTemplate &&
                <Tabs
                    items={contentTabs}
                    tabBarExtraContent={
                        !isEditing &&
                        <Button onClick={() => setIsEditing(true)}>{t("message.edit_content")}</Button>
                    }
                    onTabClick={(e) => onTabChangeHandler(e)}
                />
            }
            {
                isEditing &&
                <Editor
                    onInit={(_, editor) => (editorRef.current = editor)}
                    apiKey={brandConfig?.tinyMCEApiKey}
                    init={{
                        height: 500,
                        menubar: true,
                        plugins: [
                            "advlist",
                            "autolink",
                            "lists",
                            "link",
                            "image",
                            "charmap",
                            "preview",
                            "anchor",
                            "searchreplace",
                            "visualblocks",
                            "code",
                            "fullscreen",
                            "insertdatetime",
                            "media",
                            "table",
                            "code",
                            "help",
                            "wordcount",
                        ],
                        toolbar:
                            "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media component link anchor codesample | ltr rtl",
                        content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                    initialValue={initialData?.[`content_${currentLanguage}`] || ""}
                    onEditorChange={(newValue, editor) =>
                        setDataByLanguage(prev => ({
                            ...prev,
                            [`content_${currentLanguage}`]: newValue
                        }))
                    }
                />
            }
        </div>
    )
}

export default SendEmailForm