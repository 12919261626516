const rewardType = [
    {key: "voucher", label: "Voucher", value: "voucher"},
    {key: "voucherGroup", label: "Voucher Group", value: "voucherGroup"},
]


const voucherCodeType = [
    {key: "autoGenerate", label: "Auto Generate", value: "autoGenerate"},
    {key: "manual", label: "Manual", value: "manual"},
]

const voucherExpiryType = [
    {key: "unlimited", label: "Unlimited", value: "unlimited"},
    {key: "days", label: "Days", value: "days"},
    {key: "endDate", label: "End Date", value: "endDate"},
]

const voucherAvailability = [
    {key: "all", label: "All", value: "all"},
    {key: "tradersRoomWeb", label: "Web Traders Room", value: "tradersRoomWeb"},
    {key: "tradersRoomApp", label: "App Traders Room", value: "tradersRoomApp"},
]

const voucherMode = [
    {key: "voucherCode", label: "Voucher Code", value: "voucherCode"},
    {key: "oneCodePerVoucher", label: "One code per voucher", value: "oneCodePerVoucher"},
]

export {
    rewardType,
    voucherCodeType,
    voucherExpiryType,
    voucherAvailability,
    voucherMode
}