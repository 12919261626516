import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {useSetRecoilState} from "recoil";
import {Button, Card, Col, Row, Tabs} from "antd";

import InputFormItemMui from "../../../../../../components/form/inputFormItemMui";
import UploadSingleFormItem from "../../../../../../components/form/uploadSingleFormItem";
import {verticalFormLayout} from "../../../../../../components/form/formWrapper";
import {useUpdatePlatformMutation} from "../../../../../../api/graphql/platform";
import {getFileListFromObject, getObjectValue, handleObjectChange} from "../../../function/common";
import {notificationMessage} from "../../../../../../../recoil_state";
import CheckboxFormItem from "../../../../../../components/form/checkboxFormItem";
import useAuthorize from "../../../../../../_common/authorize";
import UploadSingleChunkFormItem from "../../../../../../components/form/uploadSingleChunkFormItem";

const PlatformForm = (props) => {
    const {t} = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {id, serverid, data, platformDownloadPermission, languageData} = props;

    const [value, setValue] = useState(data);
    const [errMsg, setErrMsg] = useState("");

    var form = useForm({
        mode: "all",
        defaultValues: value,
    });

    useEffect(() => {
        setValue(data);
    }, [data]);

    const {updatePlatform} = useUpdatePlatformMutation();
    const {systemErrorNotification} = useAuthorize();

    const onSubmit = async () => {
        try {
            const latestObj = {...value, server: serverid};

            const omitTypename = (key, value) => (key === "__typename" ? undefined : value);
            const newPayload = JSON.parse(JSON.stringify(latestObj), omitTypename);

            const response = await updatePlatform(newPayload?.id?.toString(), newPayload);
            const {data} = response;

            //   if (data?.updatePlatform.errMsg) {
            //     setErrMsg(data?.updatePlatform.errMsg);
            //   } else {
            //     setErrMsg("");
            //   }
        } catch (error: any) {
            systemErrorNotification();
        }
    };

    const uploadOnChange = (e, object, field) => {
        try {
            var fieldList;
            switch (field) {
                case "link":
                    fieldList = "linkFileList";
                    break;
                case "icon":
                    fieldList = "iconFileList";
                    break;
                case "defaultIcon":
                    fieldList = "defaultIconFileList";
                    break;
            }

            if (e?.file?.status === "uploading") {
                handleObjectChange(setValue, e?.fileList, object, fieldList);
            }
            if (e?.file?.status === "done") {
                handleObjectChange(setValue, e?.file?.response[0]?.link, object, field);
                handleObjectChange(setValue, e?.fileList, object, fieldList);

                //   message.success(`${e.file?.name} file uploaded successfully`);
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: `${e?.file?.name} file uploaded successfully`,
                    key: "updateDesk",
                });
            } else if (e?.file?.status === "error") {
                handleObjectChange(setValue, null, object, fieldList);
                //   message.error(`${e.file?.name} file upload failed.`);
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: `${e?.file?.name} file upload failed. - ${e?.file?.response}`,
                    key: "updateDesk",
                });
            }
        } catch (e) {
            systemErrorNotification();
        }
    };

    const uploadOnRemove = (value, object, field) => {
        try {
            var fieldList;
            switch (field) {
                case "link":
                    fieldList = "linkFileList";
                    break;
                case "icon":
                    fieldList = "iconFileList";
                    break;
                case "defaultIcon":
                    fieldList = "defaultIconFileList";
                    break;
            }

            const fileList = getFileListFromObject(value, object, fieldList);
            const latestFileList = fileList.filter((item) => item._uid === value?.uid);
            handleObjectChange(setValue, latestFileList, object, fieldList);
            handleObjectChange(setValue, null, object, fieldList);
            handleObjectChange(setValue, null, object, field);
        } catch (e) {
            systemErrorNotification();
        }
    };

    const isEditable = platformDownloadPermission?.edit?.edit;

    const getLanguageValue = (languageCode, objectName) => {
        let tempTarget = value[objectName]?.displayNameLanguage?.filter((item) => item.language.toString() === languageCode);
        if (tempTarget && tempTarget.length > 0) {
            return tempTarget[0]?.label;
        } else {
            return "";
        }
    };

    const setLanguageValue = (textValue, objectName, languageCode) => {
        setValue((prev) => {
            const temp = {...prev};
            const displayNameLanguageList = [...(temp[objectName]?.displayNameLanguage || [])];
            const targetLanguageIndex = displayNameLanguageList.findIndex((item) => item.language === languageCode);

            if (targetLanguageIndex === -1) {
                displayNameLanguageList.push({
                    label: textValue,
                    language: languageCode,
                });
            } else {
                displayNameLanguageList[targetLanguageIndex] = {
                    label: textValue,
                    language: languageCode,
                };
            }

            return {
                ...prev,
                [objectName]: {
                    ...prev[objectName],
                    displayNameLanguage: displayNameLanguageList,
                },
            };
        });
    };

    const getTabItem = (objectN) => {
        const tabItems = languageData?.map((lang, index) => {
            return {
                key: index?.toString(),
                label: lang?.name,
                children: (
                    <Row gutter={16} style={{marginTop: "12px"}}>
                        <Col className="gutter-row" xs={24}>
                            <InputFormItemMui
                                name={`displayName_${lang.name}`}
                                label={"Language Display Name"}
                                placeholder={"Display Name"}
                                layout={verticalFormLayout}
                                onChange={(e) => setLanguageValue(e, objectN, lang.code)}
                                value={getLanguageValue(lang.code, objectN)}
                            />
                        </Col>
                    </Row>
                ),
            };
        });

        return tabItems;
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="form-1">
                {errMsg && <p style={{color: "red"}}>{errMsg}</p>}
                <div style={{padding: "15px"}}>
                    <Row gutter={16}>
                        <Col span={8} style={{marginBottom: "15px"}}>
                            <Card title="Window" bordered={false}>
                                <div style={{marginBottom: "15px"}}>
                                    <InputFormItemMui
                                        name={`windowLinkObject_displayName_${serverid}`}
                                        label={"Display Name"}
                                        value={getObjectValue(value, "windowLinkObject", "displayName")}
                                        onChange={(e) => handleObjectChange(setValue, e, "windowLinkObject", "displayName")}
                                        disabled={!isEditable}
                                    />
                                </div>
                                <div style={{marginBottom: "15px"}}>
                                    <CheckboxFormItem
                                        name={`windowLinkObject_isEnabled_${serverid}`}
                                        label={"Enabled"}
                                        value={getObjectValue(value, "windowLinkObject", "isEnabled")}
                                        onChange={(e) => handleObjectChange(setValue, e, "windowLinkObject", "isEnabled")}
                                        disabled={!isEditable}
                                    />
                                </div>
                                <div style={{marginBottom: "15px"}}>
                                    <UploadSingleChunkFormItem
                                        name={`windowLinkObject_link_${serverid}`}
                                        label={t("File Upload")}
                                        layout={verticalFormLayout}
                                        listType={"text"}
                                        mode={"exe"}
                                        fileList={getFileListFromObject(value, "windowLinkObject", "linkFileList")}
                                        onChange={(e) => uploadOnChange(e, "windowLinkObject", "link")}
                                        onRemove={(e) => uploadOnRemove(e, "windowLinkObject", "link")}
                                        disabled={!isEditable}
                                    />
                                </div>
                                <div style={{marginBottom: "15px"}}>
                                    <UploadSingleFormItem
                                        name={`windowLinkObject_icon_${serverid}`}
                                        label={t("Icon")}
                                        layout={verticalFormLayout}
                                        listType={"picture-card"}
                                        mode={"image"}
                                        fileList={getFileListFromObject(value, "windowLinkObject", "iconFileList")}
                                        onChange={(e) => uploadOnChange(e, "windowLinkObject", "icon")}
                                        onRemove={(e) => uploadOnRemove(e, "windowLinkObject", "icon")}
                                        disabled={!isEditable}
                                    />
                                </div>
                                <Tabs defaultActiveKey="0" items={getTabItem("windowLinkObject")} />
                            </Card>
                        </Col>
                        <Col span={8} style={{marginBottom: "15px"}}>
                            <Card title="macOSX" bordered={false}>
                                <div style={{marginBottom: "15px"}}>
                                    <InputFormItemMui
                                        name={`macOSXLinkObject_displayName_${serverid}`}
                                        label={"Display Name"}
                                        value={getObjectValue(value, "macOSXLinkObject", "displayName")}
                                        onChange={(e) => handleObjectChange(setValue, e, "macOSXLinkObject", "displayName")}
                                        disabled={!isEditable}
                                    />
                                </div>
                                <div style={{marginBottom: "15px"}}>
                                    <CheckboxFormItem
                                        name={`macOSXLinkObject_isEnabled_${serverid}`}
                                        label={"Enabled"}
                                        value={getObjectValue(value, "macOSXLinkObject", "isEnabled")}
                                        onChange={(e) => handleObjectChange(setValue, e, "macOSXLinkObject", "isEnabled")}
                                        disabled={!isEditable}
                                    />
                                </div>
                                <div style={{marginBottom: "15px"}}>
                                    <UploadSingleChunkFormItem
                                        name={`macOSXLinkObject_link_${serverid}`}
                                        label={t("File Upload")}
                                        layout={verticalFormLayout}
                                        listType={"text"}
                                        mode={"dmg"}
                                        fileList={getFileListFromObject(value, "macOSXLinkObject", "linkFileList")}
                                        onChange={(e) => uploadOnChange(e, "macOSXLinkObject", "link")}
                                        onRemove={(e) => uploadOnRemove(e, "macOSXLinkObject", "link")}
                                        disabled={!isEditable}
                                    />
                                </div>
                                <div style={{marginBottom: "15px"}}>
                                    <UploadSingleFormItem
                                        name={`macOSXLinkObject_icon_${serverid}`}
                                        label={t("Icon")}
                                        layout={verticalFormLayout}
                                        listType={"picture-card"}
                                        mode={"image"}
                                        fileList={getFileListFromObject(value, "macOSXLinkObject", "iconFileList")}
                                        onChange={(e) => uploadOnChange(e, "macOSXLinkObject", "icon")}
                                        onRemove={(e) => uploadOnRemove(e, "macOSXLinkObject", "icon")}
                                        disabled={!isEditable}
                                    />
                                </div>
                                <Tabs defaultActiveKey="0" items={getTabItem("macOSXLinkObject")} />
                            </Card>
                        </Col>
                        <Col span={8} style={{marginBottom: "15px"}}>
                            <Card title="Android APK" bordered={false}>
                                <div style={{marginBottom: "15px"}}>
                                    <InputFormItemMui
                                        name={`androidAPKLinkObject_displayName_${serverid}`}
                                        label={"Display Name"}
                                        value={getObjectValue(value, "androidAPKLinkObject", "displayName")}
                                        onChange={(e) => handleObjectChange(setValue, e, "androidAPKLinkObject", "displayName")}
                                        disabled={!isEditable}
                                    />
                                </div>
                                <div style={{marginBottom: "15px"}}>
                                    <CheckboxFormItem
                                        name={`androidAPKLinkObject_isEnabled_${serverid}`}
                                        label={"Enabled"}
                                        value={getObjectValue(value, "androidAPKLinkObject", "isEnabled")}
                                        onChange={(e) => handleObjectChange(setValue, e, "androidAPKLinkObject", "isEnabled")}
                                        disabled={!isEditable}
                                    />
                                </div>
                                <div style={{marginBottom: "15px"}}>
                                    <UploadSingleChunkFormItem
                                        name={`androidAPKLinkObject_link_${serverid}`}
                                        label={t("File Upload")}
                                        layout={verticalFormLayout}
                                        listType={"text"}
                                        mode={"apk"}
                                        fileList={getFileListFromObject(value, "androidAPKLinkObject", "linkFileList")}
                                        onChange={(e) => uploadOnChange(e, "androidAPKLinkObject", "link")}
                                        onRemove={(e) => uploadOnRemove(e, "androidAPKLinkObject", "link")}
                                        disabled={!isEditable}
                                    />
                                </div>
                                <div style={{marginBottom: "15px"}}>
                                    <UploadSingleFormItem
                                        name={`androidAPKLinkObject_icon_${serverid}`}
                                        label={t("Icon")}
                                        layout={verticalFormLayout}
                                        listType={"picture-card"}
                                        mode={"image"}
                                        fileList={getFileListFromObject(value, "androidAPKLinkObject", "iconFileList")}
                                        onChange={(e) => uploadOnChange(e, "androidAPKLinkObject", "icon")}
                                        onRemove={(e) => uploadOnRemove(e, "androidAPKLinkObject", "icon")}
                                        disabled={!isEditable}
                                    />
                                </div>
                                <Tabs defaultActiveKey="0" items={getTabItem("androidAPKLinkObject")} />
                            </Card>
                        </Col>
                        <Col span={8} style={{marginBottom: "15px"}}>
                            <Card title="iOS" bordered={false}>
                                <div style={{marginBottom: "15px"}}>
                                    <InputFormItemMui
                                        name={`iOSDownloadLinkObject_displayName_${serverid}`}
                                        label={"Display Name"}
                                        value={getObjectValue(value, "iOSDownloadLinkObject", "displayName")}
                                        onChange={(e) => handleObjectChange(setValue, e, "iOSDownloadLinkObject", "displayName")}
                                        disabled={!isEditable}
                                    />
                                </div>
                                <div style={{marginBottom: "15px"}}>
                                    <CheckboxFormItem
                                        name={`iOSDownloadLinkObject_isEnabled_${serverid}`}
                                        label={"Enabled"}
                                        value={getObjectValue(value, "iOSDownloadLinkObject", "isEnabled")}
                                        onChange={(e) => handleObjectChange(setValue, e, "iOSDownloadLinkObject", "isEnabled")}
                                        disabled={!isEditable}
                                    />
                                </div>
                                <div style={{marginBottom: "15px"}}>
                                    <InputFormItemMui
                                        name={`iOSDownloadLinkObject_link_${serverid}`}
                                        label={"Download Link"}
                                        value={getObjectValue(value, "iOSDownloadLinkObject", "link")}
                                        onChange={(e) => handleObjectChange(setValue, e, "iOSDownloadLinkObject", "link")}
                                        disabled={!isEditable}
                                    />
                                </div>
                                <div style={{marginBottom: "15px"}}>
                                    <UploadSingleFormItem
                                        name={`iOSDownloadLinkObject_icon_${serverid}`}
                                        label={t("Icon")}
                                        layout={verticalFormLayout}
                                        listType={"picture-card"}
                                        mode={"image"}
                                        fileList={getFileListFromObject(value, "iOSDownloadLinkObject", "iconFileList")}
                                        onChange={(e) => uploadOnChange(e, "iOSDownloadLinkObject", "icon")}
                                        onRemove={(e) => uploadOnRemove(e, "iOSDownloadLinkObject", "icon")}
                                        disabled={!isEditable}
                                    />
                                </div>
                                <Tabs defaultActiveKey="0" items={getTabItem("iOSDownloadLinkObject")} />
                            </Card>
                        </Col>
                        <Col span={8} style={{marginBottom: "15px"}}>
                            <Card title="Android" bordered={false}>
                                <div style={{marginBottom: "15px"}}>
                                    <InputFormItemMui
                                        name={`androidDownloadLinkObject_displayName_${serverid}`}
                                        label={"Display Name"}
                                        value={getObjectValue(value, "androidDownloadLinkObject", "displayName")}
                                        onChange={(e) => handleObjectChange(setValue, e, "androidDownloadLinkObject", "displayName")}
                                        disabled={!isEditable}
                                    />
                                </div>
                                <div style={{marginBottom: "15px"}}>
                                    <CheckboxFormItem
                                        name={`androidDownloadLinkObject_isEnabled_${serverid}`}
                                        label={"Enabled"}
                                        value={getObjectValue(value, "androidDownloadLinkObject", "isEnabled")}
                                        onChange={(e) => handleObjectChange(setValue, e, "androidDownloadLinkObject", "isEnabled")}
                                        disabled={!isEditable}
                                    />
                                </div>
                                <div style={{marginBottom: "15px"}}>
                                    <InputFormItemMui
                                        name={`androidDownloadLinkObject_link_${serverid}`}
                                        label={"Download Link"}
                                        value={getObjectValue(value, "androidDownloadLinkObject", "link")}
                                        onChange={(e) => handleObjectChange(setValue, e, "androidDownloadLinkObject", "link")}
                                        disabled={!isEditable}
                                    />
                                </div>
                                <div style={{marginBottom: "15px"}}>
                                    <UploadSingleFormItem
                                        name={`androidDownloadLinkObject_icon_${serverid}`}
                                        label={t("Icon")}
                                        layout={verticalFormLayout}
                                        listType={"picture-card"}
                                        mode={"image"}
                                        fileList={getFileListFromObject(value, "androidDownloadLinkObject", "iconFileList")}
                                        onChange={(e) => uploadOnChange(e, "androidDownloadLinkObject", "icon")}
                                        onRemove={(e) => uploadOnRemove(e, "androidDownloadLinkObject", "icon")}
                                        disabled={!isEditable}
                                    />
                                </div>
                                <Tabs defaultActiveKey="0" items={getTabItem("androidDownloadLinkObject")} />
                            </Card>
                        </Col>
                        <Col span={8} style={{marginBottom: "15px"}}>
                            <Card title="Huawei" bordered={false}>
                                <div style={{marginBottom: "15px"}}>
                                    <InputFormItemMui
                                        name={`huaweiDownloadLinkObject_displayName_${serverid}`}
                                        label={"Display Name"}
                                        value={getObjectValue(value, "huaweiDownloadLinkObject", "displayName")}
                                        onChange={(e) => handleObjectChange(setValue, e, "huaweiDownloadLinkObject", "displayName")}
                                        disabled={!isEditable}
                                    />
                                </div>
                                <div style={{marginBottom: "15px"}}>
                                    <CheckboxFormItem
                                        name={`huaweiDownloadLinkObject_isEnabled_${serverid}`}
                                        label={"Enabled"}
                                        value={getObjectValue(value, "huaweiDownloadLinkObject", "isEnabled")}
                                        onChange={(e) => handleObjectChange(setValue, e, "huaweiDownloadLinkObject", "isEnabled")}
                                        disabled={!isEditable}
                                    />
                                </div>
                                <div style={{marginBottom: "15px"}}>
                                    <InputFormItemMui
                                        name={`huaweiDownloadLinkObject_link_${serverid}`}
                                        label={"Download Link"}
                                        value={getObjectValue(value, "huaweiDownloadLinkObject", "link")}
                                        onChange={(e) => handleObjectChange(setValue, e, "huaweiDownloadLinkObject", "link")}
                                        disabled={!isEditable}
                                    />
                                </div>
                                <div style={{marginBottom: "15px"}}>
                                    <UploadSingleFormItem
                                        name={`huaweiDownloadLinkObject_icon_${serverid}`}
                                        label={t("Icon")}
                                        layout={verticalFormLayout}
                                        listType={"picture-card"}
                                        mode={"image"}
                                        fileList={getFileListFromObject(value, "huaweiDownloadLinkObject", "iconFileList")}
                                        onChange={(e) => uploadOnChange(e, "huaweiDownloadLinkObject", "icon")}
                                        onRemove={(e) => uploadOnRemove(e, "huaweiDownloadLinkObject", "icon")}
                                        disabled={!isEditable}
                                    />
                                </div>
                                <Tabs defaultActiveKey="0" items={getTabItem("huaweiDownloadLinkObject")} />
                            </Card>
                        </Col>

                        <Col span={24} style={{textAlign: "end"}}>
                            {isEditable && (
                                <Button type={"primary"} htmlType={"submit"}>
                                    Save
                                </Button>
                            )}
                        </Col>
                    </Row>
                </div>
            </form>
        </FormProvider>
    );
};

export default PlatformForm;
