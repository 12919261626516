import React from "react";
import {getColumnSearchProps} from "../../../../components/table/function/filterTable";

export const columns = () => {
    const providerOptions = [
        {text: "Firebase", value: "firebase"},
    ];

    const platformOptions = [
        {text: "Web", value: "web"},
        {text: "Mobile", value: "mobile"},
        {text: "App (iOS)", value: "app_ios"},
        {text: "App (Android)", value: "app_android"},
    ];

    return [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: "Provider",
            dataIndex: "provider",
            key: "provider",
            sorter: (a, b) => a?.provider?.localeCompare(b?.provider),
            render: (value) => {
                const providerObject = providerOptions.find(option => option.value === value) || {
                    text: "-",
                    value: "-"
                };
                return <p>{providerObject.text}</p>
            }
        },
        {
            title: "Platform",
            dataIndex: "targetPlatform",
            key: "targetPlatform",
            sorter: (a, b) => a?.targetPlatform?.localeCompare(b?.targetPlatform),
            render: (value) => {
                const platformObject = platformOptions.find(option => option.value === value) || {
                    text: "-",
                    value: "-"
                };
                return <p>{platformObject.text}</p>
            }
        },
    ];
};
