import React from "react";
import Voucher_1 from "./view/layout1";
import {useRecoilValue} from "recoil";
import {columns} from "./function/tableColumn";
import { selfPermissionObj } from "../../../../../recoil_state";
import "./css/common/index.css"

const Voucher = (props) => {
    let layout = 1;
    let component;
    const tableName = "Voucher"

    const permission = useRecoilValue<any>(selfPermissionObj);
    
    switch (layout) {
        case 1:
            component = (
                <Voucher_1
                    tableName={tableName}
                    permissionActivities={permission?.activitiesAndRewardSetting}
                    column={columns}
                    product={props.product}
                    stock={props.stock}
                    commodities={props.commodities}
                    formModalType={props.formModalType}
                    setFormModalType={props.setFormModalType}
                    isFormModalOpen={props.isFormModalOpen}
                    setIsFormModalOpen={props.setIsFormModalOpen}
                    selectedItem={props.selectedItem}
                    setSelectedItem={props.setSelectedItem}
                    openFormModal={props.openFormModal}
                    closeFormModal={props.closeFormModal}
                    languageData={props.languageData} 
                    report={props.report}
                    reportFields={props.reportFields}
                    reportFieldTypes={props.reportFieldTypes}
                />
            );
            break;

        default:
            component = (
                <Voucher_1
                    tableName={tableName}
                    permissionActivities={permission?.activitiesAndRewardSetting}
                    column={columns}
                    product={props.product}
                    stock={props.stock}
                    commodities={props.commodities}
                    formModalType={props.formModalType}
                    setFormModalType={props.setFormModalType}
                    isFormModalOpen={props.isFormModalOpen}
                    setIsFormModalOpen={props.setIsFormModalOpen}
                    selectedItem={props.selectedItem}
                    setSelectedItem={props.setSelectedItem}
                    openFormModal={props.openFormModal}
                    closeFormModal={props.closeFormModal}
                    languageData={props.languageData} 
                    report={props.report}
                    reportFields={props.reportFields}
                    reportFieldTypes={props.reportFieldTypes}
                />
            );
            break;
    }

    return component;
};

export default Voucher;
