import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import {
    ApolloProvider,
} from "@apollo/client";
import {RecoilRoot} from "recoil";
import client from "./shared/api/graphql/_apolloClient";
import "./shared/i18n";
import 'react-grid-layout/css/styles.css'
import "react-resizable/css/styles.css"
import "./shared/assets/css/index.css";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <BrowserRouter>
        <RecoilRoot>
            <ApolloProvider client={client}>
                <App/>
            </ApolloProvider>
        </RecoilRoot>
    </BrowserRouter>
);
