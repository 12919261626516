import { gql, useMutation, useQuery } from "@apollo/client";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {useTranslation} from "react-i18next";
import { GET_SERVER_SETTING_LOADING_STATUS } from "./serverSetting";

const GET_SPREAD_GROUP_COUNT = gql`
  query getSpreadGroupCount($filter: SpreadGroupSearchInput) {
    getSpreadGroupCount(filter: $filter)
  }
`;

const getSpreadGroupCount = ({ filter }) => {
  try {
    const { loading, error, data } = useQuery(GET_SPREAD_GROUP_COUNT, {
      variables: {
        filter: {
          groupId: filter?.groupId || null,
          name: filter?.name || null,
          serverName: filter?.serverName || null,
        },
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_SPREAD_GROUP_BY_ID = gql`
  query getSpreadGroupByID($id: String!, $serverName: String!) {
    getSpreadGroupByID(id: $id, serverName: $serverName) {
      ... on SpreadGroupDetails {
        id
        groupId
        name
        description
        enabled
        swapEnabled
        swapAndDividendProfileId {
          swapAndDividendProfileId
          name
          description
          symbol {
            symbolId
            hasSymbolId
            swapRollover3Days
            hasSwapRollover3Days
            swapLong
            hasSwapLong
            swapShort
            hasSwapShort
            swapCalculationType
            hasSwapCalculationType
            dividendsLong
            hasDividendsLong
            dividendsShort
            hasDividendsShort
            dividendTime
            hasDividendTime
            swapTime
            hasSwapTime
            swapPeriod
            hasSwapPeriod
          }
        }
        marginStopout
        priceStreamId {
          priceStreamId
          name
          description
          deleted
          utcLastUpdateTimestamp
          priceStreamSymbol {
            symbolId
            bidMarkup
            askMarkup
            markupIsRelative
          }
        }
        deleted
        hasAccess
        isDealingDesk
        maxAutoExecutionSizeProfileId {
          executionProfileId
          name
          description
          utcLastUpdateTimestamp
          minBookBDelay
          maxBookBDelay
        }
        regionId
        copyDisabled
        copyProvidingDisabled
        isFakeSpreadBetting
        negativeBalanceProtection
        utcLastUpdateTimestamp
        bonusConversionRate
        bonusConvertLots
        bonusConversionRateLots
        blockedChannels
        fairStopOut
        stopoutDisabled
        inactivityGracePeriod
        inactivityChargePeriod
        inactivityFeeAmount
        legalEntityId
        disablePasswordLogin
        allowWorseGSL
        dynamicCommission {
          monthlyVolume
          discount
        }
        symbol {
          symbolId
          enabled
          leverageId
          gslScheduleId
          commissionProfileId
          volumeProfileId
          executionProfileId
          protectionProfileId
          swapFreeProfileId
          tradeNotificationProfileId
        }
        server {
          name
        }
        createdBy {
          username
        }
        updatedBy {
          username
        }
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getSpreadGroupById = (input) => {
  try {
    const { loading, error, data } = useQuery(GET_SPREAD_GROUP_BY_ID, {
      variables: {
        id: input.id ?? null,
        serverName: input.serverName ?? null,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_SPREAD_GROUP_DETAIL_PROFILE = gql`
  query getSpreadGroupDetailProfiles(
    $input: SpreadGroupSymbolDetailProfileInput
  ) {
    getSpreadGroupDetailProfiles(input: $input) {
      ... on GroupSymbolDetailsArray {
        data {
          symbolId {
            id
          }
          enabled
          leverageId {
            id
          }
          gslScheduleId {
            id
          }
          commissionProfileId {
            id
          }
          volumeProfileId {
            id
          }
          executionProfileId {
            id
          }
          protectionProfileId {
            id
          }
          swapFreeProfileId {
            id
          }
          tradeNotificationProfileId {
            id
          }
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getSpreadGroupDetailProfile = (input) => {
  try {
    const { loading, error, data } = useQuery(GET_SPREAD_GROUP_DETAIL_PROFILE, {
      variables: {
        serverName: input?.serverName || null,
        symbol: {
          symbolId: input?.symbolId || null,
          enabled: input?.enabled || null,
          leverageId: input?.leverageId || null,
          gslScheduleId: input?.gslScheduleId || null,
          commissionProfileId: input?.commissionProfileId || null,
          volumeProfileId: input?.volumeProfileId || null,
          executionProfileId: input?.executionProfileId || null,
          protectionProfileId: input?.protectionProfileId || null,
          swapFreeProfileId: input?.swapFreeProfileId || null,
          tradeNotificationProfileId: input?.tradeNotificationProfileId || null,
        },
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_SPREAD_GROUP = gql`
  query getSpreadGroup(
    $filter: SpreadGroupSearchInput
    $limit: Int
    $offset: Int
    $orderBy: String
  ) {
    getSpreadGroup(
      filter: $filter
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      ... on SpreadGroupDetailsArray {
        data {
          id
          groupId
          name
          enabled
          swapEnabled
          marginStopout
          deleted
          hasAccess
          isDealingDesk
          server {
            name
          }
          createdBy {
            username
          }
          updatedBy {
            username
          }
          accountCount
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getSpreadGroup = ({ filter }, limit?, offset?, orderBy?) => {
  try {
    const { loading, error, data } = useQuery(GET_SPREAD_GROUP, {
      variables: {
        filter: {
          groupId: filter?.groupId || null,
          name: filter?.name || null,
          serverName: filter?.serverName || null,
          swapEnabled: filter?.swapEnabled || null,
          marginStopout: filter?.marginStopout || null,
          account: filter?.account || null,
        },
        limit: limit || null,
        offset: offset || null,
        orderBy: orderBy || null,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const REFRESH_SPREAD_GROUP = gql`
  mutation refreshSpreadGroup($server: String!) {
    refreshSpreadGroup(server: $server) {
      ... on SpreadGroupArray {
        data {
          id
          groupId
          name
          enabled
          swapEnabled
          marginStopout
          deleted
          hasAccess
          isDealingDesk
          server {
            name
          }
          createdBy {
            username
          }
          updatedBy {
            username
          }
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useRefreshSpreadGroupMutation = (): {
  refreshSpreadGroup: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {t} = useTranslation();
    const [useRefreshSpreadGroupMutation, { data, loading, error }] =
      useMutation(REFRESH_SPREAD_GROUP);

    const refreshSpreadGroup = (server) => {
      return useRefreshSpreadGroupMutation({
        variables: {
            server: server,
        },
        update(cache, {data: {refreshSpreadGroup}}) {
          const typename = refreshSpreadGroup?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(refreshSpreadGroup?.errKey),
              key: "refreshSpreadGroup",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Requested to Refresh Spread Group Successfully"),
              key: "refreshSpreadGroup",
            });
          }
        },
        refetchQueries: [GET_SPREAD_GROUP, GET_SPREAD_GROUP_COUNT, GET_SERVER_SETTING_LOADING_STATUS]
      });
    };

    return { refreshSpreadGroup, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return { refreshSpreadGroup: null, data: null, loading: false, error: err };
  }
};

const REFRESH_PROFILE = gql`
  mutation refreshProfile($server: String!) {
    refreshProfile(server: $server) {
      ... on RefreshProfile {
        status
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useRefreshProfileMutation = (): {
  refreshProfile: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {t} = useTranslation();
    const [useRefreshProfileMutation, { data, loading, error }] =
        useMutation(REFRESH_PROFILE);

    const refreshProfile = (server) => {
      return useRefreshProfileMutation({
        variables: {
          server: server,
        },
        update(cache, {data: {refreshProfile}}) {
          const typename = refreshProfile?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(refreshProfile?.errKey),
              key: "refreshProfile",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Refresh All Profile Successfully"),
              key: "refreshProfile",
            });
          }
        },
      });
    };

    return { refreshProfile, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return { refreshProfile: null, data: null, loading: false, error: err };
  }
};

export {
  getSpreadGroupCount,
  getSpreadGroupById,
  getSpreadGroupDetailProfile,
  getSpreadGroup,
  useRefreshSpreadGroupMutation,
  useRefreshProfileMutation
};
