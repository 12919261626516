import React, {useEffect} from "react";
import {Button, Col, Form, Input, Row, Space, Tabs, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {Checkbox} from "antd/lib";
import SpreadGroupTable_1 from "../spreadGroupTable";
import CommonPanelMt5 from "./detailPanel/commonPanel";
import EditTableUserPreferenceButton
    from "../../../../../../../components/table/function/editTableUserPreferenceButton";
import {SyncOutlined} from "@ant-design/icons";
import ExportTableButton from "../../../../../../../components/table/function/exportTable";
import CompanyPanelMt5 from "./detailPanel/companyPanel";
import NewsPanelMt5 from "./detailPanel/newsPanel";
import PermissionMt5 from "./detailPanel/permissionPanel";
import MarginMt5 from "./detailPanel/marginPanel";
import SymbolsMt5 from "./detailPanel/SymbolsPanel";
import CommissionMt5 from "./detailPanel/commissionPanel";
import ReportsMt5 from "./detailPanel/ReportsPanel";

const DetailPanelMt5 = ({initialData}) => {
    const {t} = useTranslation()

    const tabList:any = [
        {
            label: t("Common"),
            key: 1,
            children: <CommonPanelMt5 initialData={initialData}/>,
        },
        {
            label: t("Company"),
            key: 2,
            children: <CompanyPanelMt5 initialData={initialData}/>,
        },
        {
            label: t("News & Mail"),
            key: 3,
            children: <NewsPanelMt5 initialData={initialData}/>,
        },
        {
            label: t("Permissions"),
            key: 4,
            children: <PermissionMt5 initialData={initialData}/>,
        },
        {
            label: t("Margin"),
            key: 5,
            children: <MarginMt5 initialData={initialData}/>,
        },
        {
            label: t("Symbols"),
            key: 6,
            children: <SymbolsMt5 initialData={initialData}/>,
        },
        {
            label: t("Commission"),
            key: 7,
            children: <CommissionMt5 initialData={initialData}/>,
        },
        {
            label: t("Reports"),
            key: 8,
            children: <ReportsMt5 initialData={initialData}/>,
        },
    ]


    return (
        <>
            <Tabs
                defaultActiveKey="1"
                size={"small"}
                items={tabList}
            />
        </>
    )
}

export default DetailPanelMt5