import React, {useCallback} from "react";
import {Button, Col, ColorPicker, Form, Input, Row, Space} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import _ from "lodash";

const ColorSchemaForm = () => {
    const showText = useCallback(_.debounce((color) => (color.isGradient() ? "Gradient" : color.toHexString()), 200), []);
    return (
        <>
            <Form.List name={"colorSchema"}>
                {(fields, {add, remove}) => (
                    <>
                        <Row className={"margin-bottom-0-75"} align={"bottom"} justify={"center"} gutter={8}>
                            <Col span={1}/>
                            <Col span={10}>
                                <Space>
                                    <Button onClick={() => add()}>Add</Button>
                                    <p className={"margin-bottom-0"}><strong>Variable Name</strong></p>
                                </Space>
                            </Col>
                            <Col flex={"132px"}>
                                <p className={"margin-bottom-0 text-align-center"}><strong>Light Theme</strong></p>
                            </Col>
                            <Col flex={"132px"}>
                                <p className={"margin-bottom-0 text-align-center"}><strong>Dark Theme</strong></p>
                            </Col>
                            <Col flex={"20px"}/>
                        </Row>
                        {fields.map((field, index) => {
                            return (
                                <Row key={field.key} align={"top"} justify={"center"} gutter={8}>
                                    <Col span={1} className={"text-align-center"}>
                                        <p className={"margin-top-0-3"}>{index + 1}</p>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'variableName']}
                                            rules={[
                                                {
                                                    pattern: /^[a-z0-9-]+$/,
                                                    message: "Only lowercase letters(a-z), numbers(0-9), and hyphens(-) allowed",
                                                },
                                            ]}
                                        >
                                            <Input placeholder={"Example: ranking-background-color"}/>
                                        </Form.Item>
                                    </Col>
                                    <Col flex={"132px"}>
                                        <Form.Item className={"text-align-center"} {...field} name={[field.name, 'light']}>
                                            <ColorPicker mode={['single', 'gradient']} allowClear/>
                                        </Form.Item>
                                    </Col>
                                    <Col flex={"132px"}>
                                        <Form.Item className={"text-align-center"} {...field} name={[field.name, 'dark']}>
                                            <ColorPicker mode={['single', 'gradient']} allowClear/>
                                        </Form.Item>
                                    </Col>
                                    <Col flex={"20px"}>
                                        <Button type={"link"} danger icon={<DeleteOutlined/>} onClick={() => remove(field.name)}/>
                                    </Col>
                                </Row>
                            )
                        })}
                    </>
                )}
            </Form.List>
        </>
    )
}

export default ColorSchemaForm