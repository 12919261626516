import React, {useEffect, useState} from "react";
import {Col, Input, Row, Tag} from "antd";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {
    useApproveCrmTaskMutation,
    useRejectCrmTaskMutation,
    useUpdateCrmTaskMutation
} from "../../../../../../api/graphql/crmTask";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../recoil_state";
import useAuthorize from "../../../../../../_common/authorize";

function ActionApprovalLayout_1(props) {
    const {data,mode} = props
    const { TextArea } = Input;
    const [payloadData,setPayloadData] = useState([])
    const [approveBtnLoading,setApproveBtnLoading] = useState(false)
    const [rejectBtnLoading,setRejectBtnLoading] = useState(false)
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {approveCrmTask} = useApproveCrmTaskMutation()
    const {rejectCrmTask} = useRejectCrmTaskMutation()
    const {updateCrmTask} = useUpdateCrmTaskMutation()
    const {systemErrorNotification} = useAuthorize()

    const ContainerHeight = 450;
    const {t} = useTranslation()

    const approveTaskAction = async () =>{
        try{
            await setApproveBtnLoading(true)
            const response = await approveCrmTask(data?._id)
            if (response?.data?.approveCrmTask?.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("task.task_approved"),
                    key: "approveCrmTask",
                });
                setApproveBtnLoading(false)
            } else if (response?.data?.approveCrmTask?.__typename === "BaseError") {

                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.data?.approveCrmTask?.errKey),
                    key: "approveCrmTask",
                });
                setApproveBtnLoading(false)
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.data?.approveCrmTask?.msg),
                    key: "approveCrmTask",
                });
                setApproveBtnLoading(false)
            }
        }catch (e) {
            systemErrorNotification()
            setApproveBtnLoading(false)
        }
    }
    const rejectTaskAction = async () =>{
        try{
            await setRejectBtnLoading(true)
            const response = await rejectCrmTask(data?._id)
            if (response?.data?.rejectCrmTask?.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("task.task_rejected"),
                    key: "rejectCrmTask",
                });
                setRejectBtnLoading(false)
            } else if (response?.data?.rejectCrmTask?.__typename === "BaseError") {
                console.log("hi")

                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.data?.rejectCrmTask?.errKey),
                    key: "rejectCrmTask",
                });
                setRejectBtnLoading(false)
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.data?.rejectCrmTask?.msg),
                    key: "rejectCrmTask",
                });
                setRejectBtnLoading(false)
            }
        }catch (e) {
            systemErrorNotification()
            setRejectBtnLoading(false)
        }
    }
    const updateCommentAction = async (value) =>{
        try{
            await updateCrmTask(data?._id,{
                comment:value?.target?.value
            })
        }catch (e) {
            systemErrorNotification()
        }
    }

    function convertToDisplayText(propertyName) {
        // Split the property name into words based on camelCase or PascalCase
        const words = propertyName?.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ');

        // Capitalize the first letter of each word
        const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

        // Join the words back together with a space in between
        const displayText = capitalizedWords.join(' ');

        return displayText;
    }

    const processPayloadData = async () =>{
        const ary:any = []
        if(data){
            if (typeof data?.payload === 'object' && data?.payload !== null) {
                await Promise.all(Object.keys(data?.payload).map((key) => {
                    if (key !== "input")
                        ary.push({
                            key: convertToDisplayText(key),
                            value: data?.payload?.[key]
                        })
                }))
            }
            if(data?.payload?.input && !Array.isArray(data?.payload?.input)){
                await data?.payload?.input && Promise.all(Object?.keys(data?.payload?.input)?.map((key)=>{
                    ary?.push({
                        key:convertToDisplayText(key),
                        value:data?.payload?.input[key]
                    })
                }))

            }else{
                if(data?.payload?.input){
                    await Promise.all(data?.payload?.input.map((key)=>{
                        Object.keys(key).map((key2)=>{
                            ary.push({
                                key:convertToDisplayText(key2),
                                value:key[key2]
                            })
                        })

                    }))
                }
            }

            setPayloadData(ary)
        }
    }

    useEffect(()=>{
        processPayloadData()
    },[data])

    return (
        <div id={"actionApprovalLayout"}>
            <Row>
                <Col xs={24} lg={12}>
                    {/*<h4 style={{marginBottom:"15px"}}>Task Details</h4>*/}
                    <Row gutter={[16,12]} style={{marginBottom:"15px"}}>
                        <Col xs={12} lg={8}>
                            <h4>{t("task.mainModuleName")}:</h4>
                        </Col>
                        <Col xs={12} lg={16}>
                            {t("module." + data?.moduleName)}
                        </Col>
                        <Col xs={12} lg={8}>
                            <h4>{t("task.type")}:</h4>

                        </Col>
                        <Col xs={12} lg={16}>
                            {t("crmTask." + data?.actionType)}
                        </Col>
                        <Col xs={12} lg={8}>
                            <h4>{t("task.status")}:</h4>
                        </Col>
                        <Col xs={12} lg={16}>
                            {data?.status === "pending"? <Tag color={"warning"}>{data?.isClaimed === true ? "pending approval":"pending"}</Tag>:data?.status === "approved" ? <Tag color={"success"}>{data?.status}</Tag>:<Tag color={"error"}>{data?.status}</Tag>}
                        </Col>
                        <Col xs={12} lg={8}>
                            <h4>{t("task.description")}:</h4>
                        </Col>
                        <Col xs={12} lg={16}>
                            {data?.description}
                        </Col>
                        <Col xs={12} lg={8}>
                            <h4>{t("task.requestedBy")}:</h4>
                        </Col>
                        <Col xs={12} lg={16}>
                            {data?.requestedBy?.username}
                        </Col>
                        <Col xs={12} lg={8}>
                            <h4>{t("task.claimBy")}:</h4>
                        </Col>
                        <Col xs={12} lg={16}>
                            {data?.claimBy?.username}
                        </Col>
                        {
                            data?.status === "approved" && (
                                <>
                                    <Col xs={12} lg={8}>
                                        <h4>{t("task.approvedBy")}:</h4>
                                    </Col>
                                    <Col xs={12} lg={16}>
                                        {data?.approvedBy?.username}
                                    </Col>
                                    <Col xs={12} lg={8}>
                                        <h4>{t("task.approvedTime")}:</h4>
                                    </Col>
                                    <Col xs={12} lg={16}>
                                        {moment(data?.approvedDatetime)?.format("YYYY-MM-DD HH:mm:ss")}
                                    </Col>
                                </>
                            )
                        }
                        {
                            data?.status === "rejected" && (
                                <>
                                    <Col xs={12} lg={8}>
                                        <h4>{t("task.rejectedBy")}:</h4>
                                    </Col>
                                    <Col xs={12} lg={16}>
                                        {data?.rejectedBy?.username}
                                    </Col>
                                    <Col xs={12} lg={8}>
                                        <h4>{t("task.rejectedTime")}:</h4>
                                    </Col>
                                    <Col xs={12} lg={16}>
                                        {moment(data?.rejectedDatetime)?.format("YYYY-MM-DD HH:mm:ss")}
                                    </Col>
                                </>
                            )
                        }
                        {/*<Col xs=12 lg={8}>*/}
                        {/*    <h4>Comment:</h4>*/}
                        {/*</Col>*/}
                        {/*<Col xs=12 lg={16}>*/}
                        {/*    <TextArea rows={4} defaultValue={data?.comment} onKeyUp={(e)=>{*/}
                        {/*        updateCommentAction(e)*/}
                        {/*    }} placeholder="Write a comment" />*/}
                        {/*</Col>*/}
                    </Row>
                </Col>
                {/*<Col span={12}>*/}
                {/*    /!*<pre>{JSON.stringify(payloadData, null, 2)}</pre>*!/*/}
                {/*    <VirtualList*/}
                {/*        data={payloadData}*/}
                {/*        height={ContainerHeight}*/}
                {/*        itemHeight={50}*/}
                {/*        itemKey="test"*/}
                {/*    >*/}
                {/*        {(item:any) => {*/}
                {/*            return (*/}
                {/*                <Row style={{marginBottom:"15px"}}>*/}
                {/*                    <Col span={8}>*/}
                {/*                        {item.key}*/}
                {/*                    </Col>*/}
                {/*                    <Col xs=12 lg={16}>*/}
                {/*                        <Input defaultValue={item.value} readOnly/>*/}
                {/*                    </Col>*/}
                {/*                </Row>*/}
                {/*            )*/}
                {/*        }}*/}
                {/*    </VirtualList>*/}
                {/*</Col>*/}
                {/*{*/}
                {/*    mode === "verify" && (*/}
                {/*        <Col span={24} style={{textAlign:"right"}}>*/}
                {/*            <Space>*/}
                {/*                <Button onClick={rejectTaskAction} loading={rejectBtnLoading} danger>*/}
                {/*                    Reject*/}
                {/*                </Button>*/}
                {/*                <Button onClick={approveTaskAction} loading={approveBtnLoading}>*/}
                {/*                    Approve*/}
                {/*                </Button>*/}
                {/*            </Space>*/}
                {/*        </Col>*/}
                {/*    )*/}
                {/*}*/}

            </Row>


        </div>
    )

}

export default ActionApprovalLayout_1