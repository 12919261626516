import React, {useEffect, useState} from "react";
import {Modal, Space, Tabs} from "antd";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import EmailTemplateForm from "./emailTemplateForm";
// import TemplatePreview from "./templatePreview";
import PdfMultiSelect from "./pdfMultiSelect";
import {handleObjectChange} from "../../../function/common";
import ButtonAntDesign from "../../../../../../components/button/buttonAntDesign";
import TemplatePreviewGrapeJS from "./templatePreviewGrapeJS";
import InputFormItem from "../../../../../../components/form/inputFormItem";

const TemplateModal = (props) => {
    const {mode, item, isOpen, closeModal, languageData, variableData, componentData, dynamicVariablePermission, newsletterData} = props;
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [editorValue, setEditorValue] = useState(item?.htmlObject || []);
    const [plainHtml, setPlainHtml] = useState(item?.htmlObject || []);
    const [displaySubjectLanguage, setDisplaySubjectLanguage] = useState(
        item?.displaySubjectLanguage?.map((item) => {
            return {label: item.label, language: item.language};
        }) || []
    );
    // const [attachment, setAttachment] = useState(item?.htmlObject || []);
    const [attachmentOptions, setAttachmentOptions] = useState([]);
    const [activeTab, setActiveTab] = useState("0");

    const openEditor = (templateId?) => {
        // navigate(`/settings/email-template-editor/${templateId}`);
        window.open(
          `${window.location.origin}/settings/email-template-editor/${templateId}`,
          "_blank"
        );
    };

    const getAttachmentOptions = () => {
        let pdfVariable = variableData?.filter((v) => v?.variableType === "pdf");
        let options = pdfVariable?.map((v) => {
            let languageCodeList = v?.fileObject?.map((v) => v?.language);
            return {value: v?.id, label: v?.name, languageCodeList};
        });
        setAttachmentOptions(options);
    };

    useEffect(() => {
        setEditorValue(item?.htmlObject || []);
        setPlainHtml(item?.htmlObject || []);
        setDisplaySubjectLanguage(
            item?.displaySubjectLanguage?.map((item) => {
                return {label: item.label, language: item.language};
            }) || []
        );
        // setAttachment(item?.htmlObject || []);
    }, [item]);

    useEffect(() => {
        getAttachmentOptions();
    }, [variableData]);

    const getTitle = (): String => {
        switch (mode) {
            case "add":
                return `${t("Add New Email Template")}`;
            case "view":
                return `${t("View Email Template")}`;
            case "edit":
                return `${t("Edit Email Template")}`;
            default:
                return `${t("Add New Email Template")}`;
        }
    };

    const onAttachmentChange = (e: string[], langCode: string) => {
        let value = e?.map((v) => ({id: v}));
        handleObjectChange(setEditorValue, value, "attachment", langCode);
    };

    const onDisplaySubjectLanguageChange = (value, languageCode) => {
        setDisplaySubjectLanguage((prev) => {
            let temp = [...prev];
            let targetIndex = prev.findIndex((x) => x.language === languageCode);
            if (targetIndex === -1) {
                temp.push({label: value, language: languageCode});
            } else {
                temp[targetIndex].label = value;
            }
            return temp;
        });
    };

    const getDisplaySubjectLanguage = (languageCode) => {
        let targetIndex = displaySubjectLanguage.findIndex((x) => x.language === languageCode);
        if (targetIndex === -1) {
            return "";
        } else {
            return displaySubjectLanguage[targetIndex].label;
        }
    };

    const tabItems = languageData?.map((lang, index) => {
        return {
            key: index?.toString(),
            label: lang?.name,
            children: (
                <>
                    <InputFormItem
                        name={`subject_${lang?.code}`}
                        label={"Subject"}
                        placeholder={"Subject"}
                        // labelCol={{span: 1, offset: 0}}
                        // wrapperCol={{span: 23, offset: 0}}
                        labelAlign={"left"}
                        disabled={mode === "view"}
                        value={getDisplaySubjectLanguage(lang?.code)}
                        onChange={(e) => onDisplaySubjectLanguageChange(e, lang?.code)}
                        className={"margin-top-0-75"}
                        required
                    />
                    <PdfMultiSelect
                        item={item}
                        languageCode={lang?.code}
                        attachmentOptions={attachmentOptions}
                        onAttachmentChange={onAttachmentChange}
                        disabled={!dynamicVariablePermission?.view || mode === "view"}
                    />
                    {mode !== "add" && (
                        <div>
                            <h3>Preview</h3>
                            <TemplatePreviewGrapeJS languageCode={lang?.code} data={item} activeTab={activeTab} className={"margin-top-0-75"} />
                            {/* <TemplatePreview
                plainHtml={getTextFromObject(plainHtml, "html", lang?.code)}
                language={lang?.code}
              /> */}
                        </div>
                    )}
                </>
            ),
        };
    });

    return (
        <Modal
            getContainer="#emailTemplate"
            className="template-editor-1"
            title={getTitle()}
            open={isOpen}
            width={"80%"}
            footer={null}
            closable={true}
            destroyOnClose
            onCancel={closeModal}
        >
            <EmailTemplateForm
                htmlState={editorValue}
                plainHtml={plainHtml}
                mode={mode}
                item={item}
                closeModal={closeModal}
                openEditor={openEditor}
                newsletterData={newsletterData}
                displaySubjectLanguage={displaySubjectLanguage}
                languageData={languageData}
            >
                <Tabs
                    defaultActiveKey="0"
                    items={tabItems}
                    onChange={(activeKey) => setActiveTab(activeKey)}
                    tabBarExtraContent={
                        mode === "edit" ? (
                            <Space>
                                <ButtonAntDesign text={t("Open in Editor")} onClick={() => openEditor(item?._id)} type="primary" />
                            </Space>
                        ) : null
                    }
                />
            </EmailTemplateForm>
        </Modal>
    );
};

export default TemplateModal;
