import React, {useEffect} from "react";
import Mission_1 from "./view/layout1";
import {useRecoilValue} from "recoil";
import {columns} from "./function/tableColumn";
import { selfPermissionObj } from "../../../../../recoil_state";
import { getLanguage } from "../../../../api/graphql/language";
import { sortEn } from "../../../../function/language";
import "./css/common/index.css"
import {getUserNetworkTreeDownline, getUserNetworkTreeDropdownBasedOnPermission} from "../../../../api/graphql/userNetworkTree";

const Mission = (props) => {
    let layout = 1;
    let component;
    const tableName = "Mission"

    const permission = useRecoilValue<any>(selfPermissionObj);
    const {languageData} = getLanguage({filter: {isActive: true}});
    // const {data: userNetworkTreeDropdown} = getUserNetworkTreeDropdownBasedOnPermission({permission: "activitiesAndRewardSetting:mission:view"})
    const {networkTreeDownlineData} = getUserNetworkTreeDownline([localStorage.getItem("g_userId") || localStorage.getItem("userid")])

    switch (layout) {
        case 1:
            component = (
                <Mission_1
                    tableName={tableName}
                    permissionMission={permission?.activitiesAndRewardSetting}
                    column={columns}
                    languageData={sortEn(languageData?.getLanguage?.data)} 
                    formModalType={props.formModalType}
                    setFormModalType={props.setFormModalType}
                    isFormModalOpen={props.isFormModalOpen}
                    setIsFormModalOpen={props.setIsFormModalOpen}
                    selectedItem={props.selectedItem}
                    setSelectedItem={props.setSelectedItem}
                    openFormModal={props.openFormModal}
                    closeFormModal={props.closeFormModal}
                    product={props.product}
                    multiTierSetting={props.multiTierSetting}
                    voucher={props.voucher}
                    voucherGroup={props.voucherGroup}
                    trigger={props.trigger}
                    mission={props.mission}
                    missionGroup={props.missionGroup}
                    userNetworkTreeDropdown={networkTreeDownlineData?.getUserNetworkTreeDownline?.data}
                />
            );
            break;

        default:
            component = (
                <Mission_1
                    tableName={tableName}
                    permissionMission={permission?.activitiesAndRewardSetting}
                    column={columns}
                    languageData={sortEn(languageData?.getLanguage?.data)} 
                    formModalType={props.formModalType}
                    setFormModalType={props.setFormModalType}
                    isFormModalOpen={props.isFormModalOpen}
                    setIsFormModalOpen={props.setIsFormModalOpen}
                    selectedItem={props.selectedItem}
                    setSelectedItem={props.setSelectedItem}
                    openFormModal={props.openFormModal}
                    closeFormModal={props.closeFormModal}
                    product={props.product}
                    multiTierSetting={props.multiTierSetting}
                    voucher={props.voucher}
                    trigger={props.trigger}
                    voucherGroup={props.voucherGroup}
                    mission={props.mission}
                    missionGroup={props.missionGroup}
                    userNetworkTreeDropdown={networkTreeDownlineData?.getUserNetworkTreeDownline?.data}
                />
            );
            break;
    }

    return component;
};

export default Mission;
