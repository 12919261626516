import React, {useMemo, useState} from "react";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {Card, Col, message, Row, Tag} from "antd";
import yupFormSchema from "../../../../../../components/yup/yupFormSchema";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import {verticalFormLayout} from "../../../../../../components/form/formWrapper";
import TextAreaInputFormItem from "../../../../../../components/form/textAreaInputFormItem";

const yupSchema = yup.object().shape({
    title: yupFormSchema.string("title", {required: true}),
    body: yupFormSchema.string("body"),
    icon: yupFormSchema.string("icon"),
    image: yupFormSchema.string("image"),
    // isActive: yupFormSchema.boolean("isActive"),
});

const TemplateForm = (props) => {
    const {mode, item, languageCode, setNotificationDataObject, imageVarData, textVarData} = props;
    const {t} = useTranslation();
    const [errMsg, setErrMsg] = useState("");
    // const [initialValues, setInitialValues] = useState(() => ({
    //   title: item?.title || "",
    //   body: item?.body || "",
    //   icon: item?.icon || "",
    //   image: item?.image || "",
    //   // isActive: item?.isActive ? true : false,
    // }));

    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
        // defaultValues: initialValues,
    });

    // const onSubmit = async (values) => {
    //   const result = {
    //     ...values,
    //   };
    //   console.log("debug onSubmit");
    //   console.log(result);
    // };

    const handleFormValueChange = (field, value) => {
        try {
            setNotificationDataObject((prevState) => {
                return prevState?.map((v) => {
                    if (v.language === languageCode) {
                        return {
                            ...v,
                            notification: {
                                ...v?.notification,
                                [field]: value,
                            },
                        };
                    }
                    return v;
                });
            });
        } catch (error) {
        }
    };

    const copyText = (name) => {
        try {
            let copiedName = "";
            copiedName = "{{dynamicVariable:" + name + "}}";

            navigator.clipboard.writeText(copiedName);
            message.success(t(`Copied to clipboard`));
        } catch (error) {
        }
    };

    let imageVarByLanguage = useMemo(
        () =>
            imageVarData?.filter((v: any) => {
                return v?.fileObject?.find((v) => v?.language === languageCode)?.path;
            }),
        [imageVarData]
    );

    let textVarByLanguage = useMemo(
        () =>
            // textVarData?.filter((v: any) => {
            //   return v?.textObject?.find((v) => v?.language === languageCode)?.text;
            // }),
            textVarData,
        [textVarData]
    );

    const isNotEditable = mode === "view";

    return (
        <>
            <FormProvider {...form}>
                {errMsg && <p style={{color: "red"}}>{errMsg}</p>}
                <Row gutter={16}>
                    <Col className="gutter-row" xs={24} md={24}>
                        <InputFormItem
                            name={"title"}
                            label={"Title"}
                            placeholder={"Title"}
                            layout={verticalFormLayout}
                            value={item?.title}
                            onChange={(value) => handleFormValueChange("title", value)}
                            disabled={isNotEditable}
                        />
                    </Col>
                    <Col className="gutter-row" xs={24} md={24}>
                        <TextAreaInputFormItem
                            name={"body"}
                            label={"Body"}
                            placeholder={"Body"}
                            layout={verticalFormLayout}
                            value={item?.body}
                            onChange={(value) => handleFormValueChange("body", value)}
                            disabled={isNotEditable}
                        />
                    </Col>
                    <Col className="gutter-row" xs={24} md={24}>
                        <InputFormItem
                            name={"icon"}
                            label={"Icon"}
                            placeholder={"Icon"}
                            layout={verticalFormLayout}
                            value={item?.icon}
                            onChange={(value) => handleFormValueChange("icon", value)}
                            extra={t("Accept URL or Image Variable")}
                            disabled={isNotEditable}
                        />
                    </Col>
                    <Col className="gutter-row" xs={24} md={24}>
                        <InputFormItem
                            name={"image"}
                            label={"Image"}
                            placeholder={"Image"}
                            layout={verticalFormLayout}
                            value={item?.image}
                            onChange={(value) => handleFormValueChange("image", value)}
                            extra={t("Accept URL or Image Variable")}
                            disabled={isNotEditable}
                        />
                    </Col>
                </Row>
            </FormProvider>
            <Row>
                <Card title="Text Variable" className="variableCard">
                    <div className="variableContainer">
                        {textVarByLanguage?.map((v, i) => (
                            <Tag key={i} color="blue" onClick={() => copyText(v?.name)}>
                                {v?.name}
                            </Tag>
                        ))}
                    </div>
                </Card>
                <Card title="Image Variable" className="variableCard">
                    <div className="variableContainer">
                        {imageVarByLanguage?.map((v, i) => (
                            <Tag key={i} color="blue" onClick={() => copyText(v?.name)}>
                                {v?.name}
                            </Tag>
                        ))}
                    </div>
                </Card>
            </Row>
        </>
    );
};

export default TemplateForm;
