import React, {useEffect, useState} from "react";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";

import {useTranslation} from "react-i18next";
import {getAssetClassMt5} from "../../../../../../api/graphql/assetClassMt5";
import {columnsMt5} from "../../../function/tableColumn_Mt5";
import {useRecoilState} from "recoil";
import {assetClassMt5ReloadState} from "../../../state";

function SecuritiesTableMt5_1(props) {
    const {server, tableName,setMt5ExportData} = props;
    const {t} = useTranslation();
    const [data, setData] = useState([]);
    const [mt5TableRefresh, setMt5TableRefresh] = useRecoilState(assetClassMt5ReloadState)
    const columns = columnsMt5;

    const preload = async () => {
        const response = await getAssetClassMt5({
            server: server?.id
        });

        const latestData = await response?.getAssetClassMt5?.data?.map((d, i) => {

            const symbolName = d?.symbols?.map((sym) => {
                return sym.symbol
            })

            return {
                ...d,
                server: d?.server?.name || "-",
                symbolName: symbolName.length > 0 ? symbolName?.join(", ") : symbolName,
                key: i + 1,
            };
        });

        setData(latestData)
        setMt5ExportData(latestData)
        setMt5TableRefresh(false)
    }

    useEffect(() => {
        if(server?.id){
            preload()
        }
    }, [server])

    useEffect(() => {
        if(mt5TableRefresh){
            preload()
        }
    }, [mt5TableRefresh])


    return (
        <div className={"margin-top-0-75"}>
            <TableAntDesign data={data}
                            tableName={tableName}
                            columns={columns}
                            size={"small"}
                            server={null}
                            scroll={false}
                            isTableLocalSearch={true}
                            pagination={{
                                pageSize: 10,
                                showSizeChanger: true,
                                position: "bottomCenter",
                                total: data?.length,
                                showTotal: (total) =>
                                    <p>{t('pagination.totalItems', {totalCount: data?.length})}</p>,
                            }}
            />
        </div>
    )
}

export default SecuritiesTableMt5_1;
