import React, {useEffect, useState} from "react"
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil"
import {useTranslation} from "react-i18next"
import {FormProvider, useForm} from "react-hook-form"
import moment from "moment"
import * as yup from "yup"
import {yupResolver} from "@hookform/resolvers/yup"
import {Card, Col, Divider, Dropdown, Menu, Row, Spin, Tag, Tooltip} from "antd"
import SelectFormItem from "../../../../../../../../components/form/selectFormItem"
import {verticalFormLayout} from "../../../../../../../../components/form/formWrapper"
import yupFormSchema from "../../../../../../../../components/yup/yupFormSchema"
import {getUser} from "../../../../../../../../api/graphql/user"
import {notificationMessage, selfPermissionObj} from "../../../../../../../../../recoil_state"
import {getAllAvailableProductByUserIdAsync} from "../../../../../../../../api/graphql/product"
import {formatMoney} from "../../../../function"
import useAuthorize from "../../../../../../../../_common/authorize"
import {formatDateTimeMT5, formatUserOptions} from "../../../../../../../../function/_common"
import {getConfigByName} from "../../../../../../../../../config"
import {BRAND_NAME} from "../../../../../../../../../config/brandVariable"
import CommRuleModal from "../commRuleModal"
import {updateTradingAccountMt5, updateTradingAccountMt5Product} from "../../../../../../../../api/graphql/tradingAccountMt5"
import {tradingAccountMt5OpenPositionProfitState} from "../../../../state"
import { LoadingOutlined } from "@ant-design/icons"
import { updateTradingAccountAdminPanelAccountStatus } from "../../../../../../../../api/graphql/tradingAccount"

const AccountMt5DetailCard = (props) => {
    const {data, traderId, viewOnly, refreshQuery} = props
    const [selfPermission] = useRecoilState<any>(selfPermissionObj)
    const tradingAccountPermission = selfPermission?.tradingAccountMt5?.account
    const [socketData, setSocketData] = useState<any>({})
    const [productChangedLoading, setProductChangedLoading] = useState<any>(false)
    const [allocateChangedLoading, setAllocateChangedLoading] = useState<any>(false)
    const [adminPanelAccountStatusLoading, setAdminPanelAccountStatusLoading] = useState<any>(false)
    const [commRuleModalIsOpen, setCommRuleModalIsOpen] = useState(false)
    const [allAvailableProducts, setAllAvailableProducts] = useState<any>([])
    const [latestEquity, setLatestEquity] = useState(0)
    const [socket, setSocket] = useState<any>(null)
    const {t} = useTranslation()
    const labelCol = {span: 8, offset: 0}
    const setNotificationMessage = useSetRecoilState(notificationMessage)
    const totalOpenPositionProfit = useRecoilValue(tradingAccountMt5OpenPositionProfitState)
    const {systemErrorNotification} = useAuthorize()
    const brandConfig = getConfigByName(BRAND_NAME)

    const yupSchema = yup.object().shape({
        user: yupFormSchema.string("user", {required: true}),
        product: yupFormSchema.string("user", {required: true}),
    })

    const allUser = getUser({
        filter: {
            userType: "client",
        },
    })

    const isSelfTradeAccount = data?.product?.productType?.includes("selfTrade")
    const isMoneyManagerAccount = data?.product?.productType?.includes("moneyManager")
    const isInvestorAccount = data?.product?.productType?.includes("investor")

    let productType: any = []

    if (data?.mode === "live") {
        productType = isSelfTradeAccount ? ["selfTradeLive"] : isMoneyManagerAccount ? ["moneyManagerLive"] : ["investorLive"]
    } else if (data?.mode === "demo") {
        productType = isSelfTradeAccount ? ["selfTradeDemo"] : isMoneyManagerAccount ? ["moneyManagerDemo"] : ["investorDemo"]
    }

    const preload = async () => {
        if (data?.user?._id) {
            const response = await getAllAvailableProductByUserIdAsync(data?.user?._id, productType)
            setAllAvailableProducts(response?.getAllAvailableProductByUserId?.data)
        }
    }

    useEffect(() => {
        preload()
    }, [data?.user?._id])

    const productOptions = allAvailableProducts?.map((d) => {
        return {
            label: <Row justify={"space-between"}>{d?.server?.name + " - " + d?.name}</Row>,
            value: d?._id,
        }
    })

    // const productOptions = allAvailableProduct?.getTradingAccountProductDropdown?.data?.map((d) => {
    //     return {label: d?.server?.name + " - " + d?.name, value: d?._id};
    // });

    const userOptions = allUser?.data?.getUser?.data?.map((d, i) => {
        return formatUserOptions(d)
    })

    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
    })

    const onSubmit = async (value) => {
        try {
            setAllocateChangedLoading(true)
            const response = await updateTradingAccountMt5(data?._id, {
                user: value?.user,
            })

            if (response?.updateTradingAccountMt5.__typename === "TradingAccount") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("common.updateSuccess"),
                    key: "updateTradingAccountMt5",
                })
            } else if (response?.updateTradingAccountMt5.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.updateTradingAccountMt5?.errKey),
                    key: "updateTradingAccountMt5",
                })
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.updateTradingAccountMt5?.msg),
                    key: "updateTradingAccountMt5",
                })
            }
        } catch (e) {
            systemErrorNotification()
        } finally {
            setAllocateChangedLoading(false)
        }
    }

    const onSubmitProduct = async (value) => {
        try {
            await setProductChangedLoading(true)
            const response = await updateTradingAccountMt5Product(data?._id, {
                user: value?.user,
                product: value?.product,
            })

            if (response?.updateTradingAccountMt5Product.__typename === "TradingAccount") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("common.updateSuccess"),
                    key: "updateTradingAccountMt5Product",
                })
                refreshQuery()
            } else if (response?.updateTradingAccountMt5Product.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.updateTradingAccountMt5Product?.errKey),
                    key: "updateTradingAccountMt5Product",
                })
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.updateTradingAccountMt5Product?.msg),
                    key: "updateTradingAccountMt5Product",
                })
            }
        } catch (e) {
            systemErrorNotification()
        } finally {
            setProductChangedLoading(false)
        }
    }

    const updateAdminPanelAccountStatus = async (action) => {
        try {
            setAdminPanelAccountStatusLoading(true)
            let adminPanelAccountStatus, populate
            switch (action) {
                case "archive":
                    adminPanelAccountStatus = "archived"
                    populate = false
                    break
                case "inactive":
                    adminPanelAccountStatus = "inactive"
                    populate = false
                    break
                case "unarchive":
                    adminPanelAccountStatus = "active"
                    populate = true
                    break
                case "active":
                    adminPanelAccountStatus = "active"
                    populate = true
                    break
                default:
                    break
            }
            const response = await updateTradingAccountAdminPanelAccountStatus(data?._id, {
                adminPanelAccountStatus,
                populate,
            })

            if (response?.updateTradingAccountAdminPanelAccountStatus.__typename === "TradingAccount") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("common.updateSuccess"),
                    key: "updateTradingAccountAdminPanelAccountStatus",
                })
            } else if (response?.updateTradingAccountAdminPanelAccountStatus.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.updateTradingAccountAdminPanelAccountStatus?.errKey),
                    key: "updateTradingAccountAdminPanelAccountStatus",
                })
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.updateTradingAccountAdminPanelAccountStatus?.msg),
                    key: "updateTradingAccountAdminPanelAccountStatus",
                })
            }
            refreshQuery()
        } catch (e) {
            systemErrorNotification()
        } finally {
            setAdminPanelAccountStatusLoading(false)
        }
    }

    useEffect(() => {
        try {
            if (data?.product?.mode !== "demo") {
                //pending mt5 socket
            }
        } catch (e) {
            console.log(e)
        }
    }, [traderId])

    useEffect(() => {
        if (totalOpenPositionProfit && data) {
            let latestEquity = data?.totalBalance + data?.credit + parseFloat(totalOpenPositionProfit?.toString())
            setLatestEquity(latestEquity)
        }
    }, [totalOpenPositionProfit])

    const isEditable = tradingAccountPermission?.edit?.edit
    const isEditableProduct = tradingAccountPermission?.edit?.product

    let adminPanelAccountStatusTagColor = "default"
    switch (data?.adminPanelAccountStatus) {
        case "active":
            adminPanelAccountStatusTagColor = "green"
            break
        case "inactive":
        case "archived":
            adminPanelAccountStatusTagColor = "grey"
            break
        case "deleted":
            adminPanelAccountStatusTagColor = "red"
            break
        default:
            break
    }

    return (
        <div id="accountDetailCard">
            <Card>
                <Row>
                    <h4>
                        {t("tradingAcc.accountId")}: {data?.accountId ?? " -"}&nbsp;&nbsp;
                        <Dropdown
                            dropdownRender={() => (
                                <Menu>
                                    {data?.adminPanelAccountStatus === "active" && (
                                        <>
                                            {/* <Menu.Item key="1">
                                                <a key="archive" onClick={() => updateAdminPanelAccountStatus("archive")}>
                                                    Archive
                                                </a>
                                            </Menu.Item> */}
                                            <Menu.Item key="1">
                                                <a key="inactive" onClick={() => updateAdminPanelAccountStatus("inactive")}>
                                                    Inactive
                                                </a>
                                            </Menu.Item>
                                        </>
                                    )}
                                    {/* {data?.adminPanelAccountStatus === "archived" && (
                                        <>
                                            <Menu.Item key="1">
                                                <a key="unarchive" onClick={() => updateAdminPanelAccountStatus("unarchive")}>
                                                    Unarchive
                                                </a>
                                            </Menu.Item>
                                        </>
                                    )} */}
                                    {data?.adminPanelAccountStatus === "inactive" && (
                                        <>
                                            <Menu.Item key="1">
                                                <a key="active" onClick={() => updateAdminPanelAccountStatus("active")}>
                                                    Active
                                                </a>
                                            </Menu.Item>
                                        </>
                                    )}
                                </Menu>
                            )}
                            placement="top"
                            trigger={["click"]}
                            arrow
                            disabled={data?.adminPanelAccountStatus === "deleted"}
                        >
                            <Tag className="clickable" color={adminPanelAccountStatusTagColor}>{adminPanelAccountStatusLoading ? <LoadingOutlined spin /> : t(`tradingAccount.adminPanelAccountStatus.${data?.adminPanelAccountStatus}`)}</Tag>
                        </Dropdown>
                    </h4>
                    <div>
                        {data?.product?.server?.platform ? (
                            <Tag className={"margin-bottom-0-3"} color={"geekblue"}>
                                {data?.product?.server?.platform === "mt5" ? "MT5" : data?.product?.server?.platform === "xTrader" ? data?.product?.server?.platformDisplayName : data?.product?.server?.platform}
                            </Tag>
                        ) : null}
                        {data?.product?.mode ? (
                            <Tag className={"margin-bottom-0-3"} color={data?.product?.mode === "live" ? "lime" : "default"}>
                                {data?.product?.mode}
                            </Tag>
                        ) : null}
                        {data?.product?.productType && <Tag bordered={false}>{t(`product.productType_${data?.product?.productType?.replace("Demo", "")?.replace("Live", "")}`)}</Tag>}
                        {data?.product?.productType?.includes("investor") && <Tag bordered={false}>{data?.investorAccountSetting?.status}</Tag>}
                        {data?.commRule?.length > 0 ? (
                            data?.commRule?.map((d) => {
                                const name = (d?.commRuleType === "commissionRule" ? d?.ruleName : d?.groupName) || ""
                                const isTooltipNeeded = name.length > 20
                                const truncatedRuleName = name.length > 20 ? name.slice(0, 20) + "..." : name

                                return (
                                    <Tooltip title={isTooltipNeeded ? name : undefined}>
                                        <Tag onClick={() => setCommRuleModalIsOpen(true)} className={`margin-bottom-0-3 ${tradingAccountPermission?.edit?.commRule && "clickable"}`}>
                                            {truncatedRuleName}
                                        </Tag>
                                    </Tooltip>
                                )
                            })
                        ) : (
                            <Tooltip title={"No Comm Rule"}>
                                <Tag onClick={() => setCommRuleModalIsOpen(true)} className={`margin-bottom-0-3 ${tradingAccountPermission?.edit?.commRule && "clickable"}`}>
                                    -
                                </Tag>
                            </Tooltip>
                        )}
                        {tradingAccountPermission?.edit?.commRule && <CommRuleModal isOpen={commRuleModalIsOpen} setIsOpen={setCommRuleModalIsOpen} account={data} />}
                    </div>
                </Row>
                {!viewOnly && (
                    <Spin tip="Loading..." spinning={!data?._id}>
                        <>
                            <FormProvider {...form}>
                                <form onSubmit={form.handleSubmit(onSubmit)} className="form-1 margin-top-0-25">
                                    <SelectFormItem
                                        labelAlign={"left"}
                                        name={"user"}
                                        label={t("tradingAccount.allocate_to")}
                                        placeholder={t("tradingAccount.allocate_to")}
                                        layout={verticalFormLayout}
                                        options={userOptions || []}
                                        mode={"single"}
                                        onChange={form.handleSubmit(onSubmit)}
                                        value={userOptions ? data?.user?._id : null}
                                        disabled={!isEditable || allocateChangedLoading}
                                        showSearch={true}
                                        optionFilterProp={"label"}
                                        loading={allocateChangedLoading || !userOptions}
                                        optionLabelProp="selectedLabel"
                                    />

                                    {tradingAccountPermission?.view?.product && productOptions?.length > 0 && (
                                        <SelectFormItem
                                            labelAlign={"left"}
                                            name={"product"}
                                            label={t("tradingAccount.product")}
                                            placeholder={t("tradingAccount.product")}
                                            layout={verticalFormLayout}
                                            options={productOptions || []}
                                            mode={"single"}
                                            onChange={form.handleSubmit(onSubmitProduct)}
                                            value={data?.product?._id}
                                            disabled={!isEditableProduct || productChangedLoading}
                                            showSearch={true}
                                            loading={productChangedLoading}
                                            optionFilterProp={"label"}
                                        />
                                    )}
                                </form>
                            </FormProvider>
                            <Divider />
                        </>
                    </Spin>
                )}
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                        <p>{t("tradingAcc.balance")}</p>
                    </Col>
                    <Col span={12}>
                        <p>
                            {socketData?.balanceDouble !== undefined && socketData?.balanceDouble !== null ? (
                                formatMoney(socketData?.balanceDouble)
                            ) : !isNaN(data?.totalBalance) ? (
                                formatMoney(data?.totalBalance)
                            ) : (
                                <Spin style={{marginLeft: "15px"}} size="small" />
                            )}
                        </p>
                    </Col>
                    <Col span={12}>
                        <p>{t("common.pendingWithdrawalAmount")}</p>
                    </Col>
                    <Col span={12}>
                        <p>{data?.lockBalance ? formatMoney(data?.lockBalance) : "0.00"}</p>
                    </Col>
                    <Col span={12}>
                        <p>{t("tradingAcc.tradingVoucher")}</p>
                    </Col>
                    <Col span={12}>
                        <p>{data?.tradingVoucher !== undefined ? formatMoney(data?.tradingVoucher) : <Spin style={{marginLeft: "15px"}} size="small" />}</p>
                    </Col>
                    <Col span={12}>
                        <p>{t("tradingAcc.credit")}</p>
                    </Col>
                    <Col span={12}>
                        <p>{socketData?.totalBonus !== undefined && socketData?.totalBonus !== null ? formatMoney(socketData?.totalBonus) : !isNaN(data?.credit) ? formatMoney(data?.credit) : <Spin style={{marginLeft: "15px"}} size="small" />}</p>
                    </Col>
                    <Col span={12}>
                        <p>{t("tradingAccount.active_credit")}</p>
                    </Col>
                    <Col span={12}>
                        <p>
                            {socketData?.totalBonus !== undefined && socketData?.totalBonus !== null ? (
                                formatMoney(socketData?.activeBonus)
                            ) : !isNaN(data?.activeCredit) ? (
                                formatMoney(data?.activeCredit)
                            ) : (
                                <Spin style={{marginLeft: "15px"}} size="small" />
                            )}
                        </p>
                    </Col>
                    <Col span={12}>
                        <p>{t("tradingAcc.equity")}</p>
                    </Col>
                    <Col span={12}>
                        <p>{latestEquity !== undefined && socketData?.equity !== null && latestEquity != 0 ? formatMoney(latestEquity) : !isNaN(data?.equity) ? formatMoney(data?.equity) : <Spin style={{marginLeft: "15px"}} size="small" />}</p>
                    </Col>
                    <Col span={12}>
                        <p>{t("tradingAcc.margin")}</p>
                    </Col>
                    <Col span={12}>
                        <p>{socketData?.marginUsed !== undefined && socketData?.marginUsed !== null ? formatMoney(socketData?.marginUsed) : !isNaN(data?.margin) ? formatMoney(data?.margin) : <Spin style={{marginLeft: "15px"}} size="small" />}</p>
                    </Col>
                    <Col span={12}>
                        <p>{t("tradingAcc.availableMargin")}</p>
                    </Col>
                    <Col span={12}>
                        <p>
                            {socketData?.freeMargin !== undefined && socketData?.freeMargin !== null ? (
                                formatMoney(socketData?.freeMargin)
                            ) : !isNaN(data?.availableMargin) ? (
                                formatMoney(data?.availableMargin)
                            ) : (
                                <Spin style={{marginLeft: "15px"}} size="small" />
                            )}
                        </p>
                    </Col>

                    <Col span={12}>
                        <p>{t("tradingAcc.marginLevel")}</p>
                    </Col>
                    <Col span={12}>
                        <p>
                            {socketData?.marginLevel !== undefined && socketData?.marginLevel !== null ? (
                                formatMoney(socketData?.marginLevel)
                            ) : !isNaN(data?.marginLevel) ? (
                                formatMoney(data?.marginLevel)
                            ) : (
                                <Spin style={{marginLeft: "15px"}} size="small" />
                            )}
                        </p>
                    </Col>
                    <Col span={12}>
                        <p>{t("common.currency")}</p>
                    </Col>
                    <Col span={12}>
                        <p>{socketData?.currency !== undefined ? socketData?.currency : data?.asset ? data?.asset : <Spin style={{marginLeft: "15px"}} size="small" />}</p>
                    </Col>
                    <Col span={12}>
                        <p>{t("tradingAcc.unrealizedNetProfit")}</p>
                    </Col>
                    <Col span={12}>
                        <p>{socketData?.unrealizedNetProfit !== undefined && socketData?.unrealizedNetProfit !== null ? formatMoney(socketData?.unrealizedNetProfit) : "-"}</p>
                    </Col>
                    <Col span={12}>
                        <p>{t("tradingAcc.registrationTime")}</p>
                    </Col>
                    <Col span={12}>
                        <p>{formatDateTimeMT5(data?.createdAt)}</p>
                        {/*<p>{moment(data?.createdAt)?.format("YYYY-MM-DD HH:mm:ss")}</p>*/}
                    </Col>
                    <Col span={12}>
                        <p>{t("tradingAccount.last_updated")}</p>
                    </Col>
                    <Col span={12}>
                        <p>{data?.managerUpdateDatetime ? moment(data?.managerUpdateDatetime)?.format("YYYY-MM-DD HH:mm:ss") : "-"}</p>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export default AccountMt5DetailCard
