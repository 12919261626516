import React, { useState } from "react";
import { Button, Form } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import {
  getDesk,
  useAddDeskMutation,
  useUpdateDeskMutation,
} from "../../../../../../api/graphql/desk";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import yupFormSchema from "../../../../../../components/yup/yupFormSchema";
import { horizontalFormLayout } from "../../../../../../components/form/formWrapper";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import SelectFormItem from "../../../../../../components/form/selectFormItem";
import useAuthorize from "../../../../../../_common/authorize";

const DeskForm = (props) => {
  const { mode, item, close } = props;
  const { t } = useTranslation();
  const [errMsg, setErrMsg] = useState("");
  const { addDesk, loading: addLoading } = useAddDeskMutation();
  const { updateDesk, loading: updateLoading } = useUpdateDeskMutation();
  const { data: deskList } = getDesk({
    filter: {},
  });
  const { systemErrorNotification } = useAuthorize();

  const labelCol = { span: 6, offset: 0 };

  const yupSchema = yup.object().shape({
    name: yupFormSchema.string("name", { required: true }),
    description: yupFormSchema.string("description"),
    parent: yupFormSchema.string("parent", {required: item?.isMasterDesk ? false : true}),
  });

  const [initialValues] = useState(() => ({
    name: item?.name || "",
    description: item?.description || "",
    parent: item?.parent?._id || "",
  }));

  const deskOptions = deskList?.getDesk?.data?.map((d, i) => {
    return {
      value: d?.id || "",
      label: d?.name || "",
    };
  });

  const form = useForm({
    resolver: yupResolver(yupSchema),
    mode: "all",
    defaultValues: initialValues,
  });

  const onSubmit = async (value) => {
    try {
      const res =
        mode === "add"
          ? await addDesk({
              name: value?.name,
              description: value?.description,
              parent: value?.parent,
            })
          : await updateDesk(item?.id, {
              name: value?.name,
              description: value?.description,
              parent: value?.parent,
            });
      if (res?.__typename === "BaseError") {
        let errMsg = t(res?.errKey);
        setErrMsg(errMsg);
      } else {
        setErrMsg("");
        close();
      }
    } catch (e: any) {
      console.log(e);
      systemErrorNotification();
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="form-1">
        {errMsg && <p style={{ color: "red" }}>{errMsg}</p>}
        <SelectFormItem
          name={"parent"}
          label={t("Parent Desk")}
          placeholder={t("Parent Desk")}
          layout={horizontalFormLayout}
          options={deskOptions?.filter((opt) => opt?.label !== item?.name)}
          mode={"single"}
          labelCol={labelCol}
          showSearch
          optionFilterProp={"label"}
          required={!item?.isMasterDesk}
        />
        <InputFormItem
          name={"name"}
          label={t("Name")}
          placeholder={t("Name")}
          layout={horizontalFormLayout}
          required
          disabled={mode === "view"}
          labelCol={labelCol}
        />
        <InputFormItem
          name={"description"}
          label={t("Description")}
          placeholder={t("description")}
          layout={horizontalFormLayout}
          disabled={mode === "view"}
          labelCol={labelCol}
        />
        <Form.Item className="text-align-right" style={{ paddingTop: "12px" }}>
          {close && (
            <Button
              onClick={close}
              icon={<CloseOutlined />}
              className="button-cancel-1"
            >
              {t("common.cancel")}
            </Button>
          )}
          <Button
            disabled={addLoading || updateLoading}
            onClick={form.handleSubmit(onSubmit)}
            icon={<SaveOutlined />}
            className="button-submit-1"
          >
            {t("common.submit")}
          </Button>
        </Form.Item>
      </form>
    </FormProvider>
  );
};

export default DeskForm;
