import {gql, useMutation, useQuery} from '@apollo/client'


const GET_CRM_TASK_APPROVAL = gql`
    query getCrmTaskApproval($filter: CrmTaskApprovalFilter,$networkUserId:String, $limit: Int, $offset: Int, $orderBy: String, $startDate: String, $endDate: String, $dateKey: String,$months:String) {
        getCrmTaskApproval(filter: $filter,networkUserId: $networkUserId, limit: $limit, offset: $offset, orderBy: $orderBy, startDate: $startDate, endDate: $endDate, dateKey: $dateKey,months:$months) {
            ... on CrmTaskApprovalAry {
                data {
                    _id
                    taskTitle
                    moduleName
                    mainModuleName
                    subModuleName
                    functionName
                    actionType
                    oldValue
                    payload
                    status
                    comment
                    description
                    entityId
                    result
                    isClaimed
                    isActionAvailable
                    additionalInfo {
                        user {_id ,username, fullName, userType}
                        transactionNo
                        transactionAmount
                        account
                        additionalInformation
                    }
                    errMsg
                    requestedBy {
                        username
                    }
                    approvedBy {
                        username
                    }
                    rejectedBy {
                        username
                    }
                    claimBy {
                        _id
                        username
                    }
                    createdAt
                    approvedDatetime
                    rejectedDatetime
                    msg
                }
                total
                moduleList
                subModuleList
            }
        }
    }
`;

const getCrmTaskApproval = ({filter}, networkUserId?, limit?, offset?, orderBy?, startDate?, endDate?, dateKey?, months?) => {
    try {
        const {loading, error, data, refetch} = useQuery(GET_CRM_TASK_APPROVAL, {
            variables: {
                filter: filter,
                networkUserId: networkUserId,
                limit: limit || 20,
                offset: offset || null,
                orderBy: orderBy || null,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
                months: months || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        const refetchQuery = () => {
            refetch()
        }

        return {loading, error, data, refetchQuery};
    } catch (err) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_CRM_TASK_DETAILS = gql`
    query getCrmTaskApprovalDetails($id: String) {
        getCrmTaskApprovalDetails(id: $id) {
            ... on CrmTaskApproval {
                _id
                moduleName
                mainModuleName
                subModuleName
                functionName
                actionType
                oldValue
                payload
                status
                comment
                description
                entityId
                result
                isClaimed
                isActionAvailable
                additionalInfo {
                    user {_id ,username, fullName, userType}
                    transactionNo
                    transactionAmount
                    account
                    additionalInformation
                }
                requestedBy {
                    _id
                    username
                    fullName
                }
                approvedBy {
                    _id
                    username
                    fullName
                }
                rejectedBy {
                    username
                }
                claimBy {
                    _id
                    username
                    fullName
                }
                createdAt
                approvedDatetime
                rejectedDatetime
                msg
            }
        }
    }
`;

const getCrmTaskApprovalDetail = (id) => {
    try {
        const {loading, error, data} = useQuery(GET_CRM_TASK_DETAILS, {
            variables: {
                id: id,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const CLAIM_CRM_TASK = gql`
    mutation claimCrmTask($userId: String!, $id: String!) {
        claimCrmTask(userId: $userId, id: $id) {
            ... on CrmTaskApproval {
                _id
                moduleName
                functionName
                actionType
                oldValue
                payload
                status
                comment
                description
                entityId
                result
                isClaimed
                additionalInfo {
                    user {_id ,username, fullName, userType}
                    transactionNo
                    transactionAmount
                    account
                    additionalInformation
                }
                requestedBy {
                    username
                }
                approvedBy {
                    username
                }
                claimBy {
                    username
                }
                createdAt
                approvedDatetime
                rejectedDatetime
                msg
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useClaimCrmTaskMutation = (): {
    claimCrmTask: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const [claimCrmTaskMutation, {data, loading, error}] =
            useMutation(CLAIM_CRM_TASK);

        const claimCrmTask = (userId, id) => {
            return claimCrmTaskMutation({
                variables: {
                    userId: userId,
                    id: id,
                },
            });
        };

        let typename = data?.claimCrmTask?.__typename;
        if (typename === "CrmTaskApproval" || typename === "BaseError") {
            // Notification
        }

        return {claimCrmTask, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {claimCrmTask: null, data: null, loading: false, error: err};
    }
};

const UNCLAIM_CRM_TASK = gql`
    mutation unClaimCrmTask($userId: String!, $id: String!) {
        unClaimCrmTask(userId: $userId, id: $id) {
            ... on CrmTaskApproval {
                _id
                moduleName
                functionName
                actionType
                oldValue
                payload
                status
                comment
                description
                entityId
                result
                isClaimed
                additionalInfo {
                    user {_id ,username, fullName, userType}
                    transactionNo
                    transactionAmount
                    account
                    additionalInformation
                }
                requestedBy {
                    username
                }
                approvedBy {
                    username
                }
                claimBy {
                    username
                }
                createdAt
                approvedDatetime
                rejectedDatetime
                msg
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useUnclaimedCrmTaskMutation = (): {
    unClaimCrmTask: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const [unClaimCrmTaskMutation, {data, loading, error}] =
            useMutation(UNCLAIM_CRM_TASK);

        const unClaimCrmTask = (userId, id) => {
            return unClaimCrmTaskMutation({
                variables: {
                    userId: userId,
                    id: id,
                },
            });
        };

        let typename = data?.unClaimCrmTask?.__typename;
        if (typename === "CrmTaskApproval" || typename === "BaseError") {
            // Notification
        }

        return {unClaimCrmTask, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {unClaimCrmTask: null, data: null, loading: false, error: err};
    }
};

const APPROVE_CRM_TASK = gql`
    mutation approveCrmTask($id: String!, $input: CrmTaskApprovalInput,$isRerun:Boolean) {
        approveCrmTask(id: $id, input: $input,isRerun:$isRerun) {
            ... on CrmTaskApproval {
                _id
                moduleName
                functionName
                actionType
                oldValue
                payload
                status
                comment
                description
                entityId
                result
                isClaimed
                additionalInfo {
                    user {_id ,username, fullName, userType}
                    transactionNo
                    transactionAmount
                    account
                    additionalInformation
                }
                requestedBy {
                    username
                }
                approvedBy {
                    username
                }
                rejectedBy {
                    username
                }
                claimBy {
                    username
                }
                createdAt
                approvedDatetime
                rejectedDatetime
                msg
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;

const useApproveCrmTaskMutation = (): {
    approveCrmTask: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const [approveCrmTaskMutation, {data, loading, error}] =
            useMutation(APPROVE_CRM_TASK);

        const approveCrmTask = (id, extraPayload, isRerun = false) => {
            return approveCrmTaskMutation({
                variables: {
                    id: id,
                    input: {
                        extraPayload
                    },
                    isRerun: isRerun
                },
                refetchQueries: ["GET_CRM_TASK_APPROVAL"]
            });
        };

        let typename = data?.approveCrmTask?.__typename;
        if (typename === "CrmTaskApproval" || typename === "BaseError") {
            // Notification
        }

        return {approveCrmTask, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {approveCrmTask: null, data: null, loading: false, error: err};
    }
};

const REJECT_CRM_TASK = gql`
    mutation rejectCrmTask($id: String!, $comment: String) {
        rejectCrmTask(id: $id, comment: $comment) {
            ... on CrmTaskApproval {
                _id
                moduleName
                functionName
                actionType
                oldValue
                payload
                status
                comment
                description
                entityId
                result
                isClaimed
                additionalInfo {
                    user {_id ,username, fullName, userType}
                    transactionNo
                    transactionAmount
                    account
                    additionalInformation
                }
                requestedBy {
                    username
                }
                approvedBy {
                    username
                }
                rejectedBy {
                    username
                }
                claimBy {
                    username
                }
                createdAt
                approvedDatetime
                rejectedDatetime
                msg
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;

const useRejectCrmTaskMutation = (): {
    rejectCrmTask: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const [rejectCrmTaskMutation, {data, loading, error}] =
            useMutation(REJECT_CRM_TASK);

        const rejectCrmTask = (id, comment?) => {
            return rejectCrmTaskMutation({
                variables: {
                    id: id,
                    comment: comment
                },
                refetchQueries: ["GET_CRM_TASK_APPROVAL"]
            });
        };

        let typename = data?.rejectCrmTask?.__typename;
        if (typename === "CrmTaskApproval" || typename === "BaseError") {
            // Notification
        }

        return {rejectCrmTask, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {rejectCrmTask: null, data: null, loading: false, error: err};
    }
};

const UPDATE_CRM_TASK = gql`
    mutation updateCrmTask($id: String!, $input: CrmTaskApprovalInput) {
        updateCrmTask(id: $id, input: $input) {
            ... on CrmTaskApproval {
                _id
                moduleName
                functionName
                actionType
                oldValue
                payload
                status
                comment
                description
                entityId
                result
                isClaimed
                additionalInfo {
                    user {_id ,username, fullName, userType}
                    transactionNo
                    transactionAmount
                    account
                    additionalInformation
                }
                requestedBy {
                    username
                }
                approvedBy {
                    username
                }
                rejectedBy {
                    username
                }
                claimBy {
                    username
                }
                createdAt
                approvedDatetime
                rejectedDatetime
                msg
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useUpdateCrmTaskMutation = (): {
    updateCrmTask: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const [updateCrmTaskMutation, {data, loading, error}] =
            useMutation(UPDATE_CRM_TASK);

        const updateCrmTask = (id, data) => {
            return updateCrmTaskMutation({
                variables: {
                    id: id,
                    input: data
                },
            });
        };

        let typename = data?.updateCrmTask?.__typename;
        if (typename === "CrmTaskApproval" || typename === "BaseError") {
            // Notification
        }

        return {updateCrmTask, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {updateCrmTask: null, data: null, loading: false, error: err};
    }
};


export {
    getCrmTaskApproval,
    getCrmTaskApprovalDetail,
    useApproveCrmTaskMutation,
    useClaimCrmTaskMutation,
    useRejectCrmTaskMutation,
    useUnclaimedCrmTaskMutation,
    useUpdateCrmTaskMutation,
    GET_CRM_TASK_APPROVAL
}
