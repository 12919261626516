import { Button, Col, DatePicker, Input, InputNumber, Modal, Row, Select, Space, Tabs } from "antd"
import React, { useState } from "react"
import { comparisonOperator, logicalOperator, trueFalseOperator } from "../function/enumerators"
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, SettingOutlined } from "@ant-design/icons"
import dayjs from "dayjs"
import InputFormItem from "../../../../../components/form/inputFormItem"
import { verticalFormLayout } from "../../../../../components/form/formWrapper"
import { useTranslation } from "react-i18next"

const MissionAutomationCriteriaItem = (props) => {
    const {t} = useTranslation();
    const {
        setConditionGroup,
        conditionGroup,
        currentReport,
        reportFields,
        reportFieldTypes,
        item,
        groupIndex,
        languageData,
        mode,
        currentRecord
    } = props

    const [openModal, setOpenModal] = useState(false)
    const [conditionItemIndex, setConditionItemIndex] = useState(0)
    const [activeTab, setActiveTab] = useState("0")

    const getCurrentReportOptions = () => {
        return currentReport?.map(item => {
            return {
                value: item.reportId,
                label: item.displayName
            }
        })
    }

    const getReportFields = (reportId) => {
        return reportFields?.filter(item => item.reportId === reportId)?.map(item => {
            return {
                value: item.fieldId,
                label: item.uiName
            }
        })
    }

    const onChange = (value, fieldName, index) => {
        if(fieldName === "reportId"){
            setConditionGroup(prev => {
                let previous = [...prev]
                previous[groupIndex].criteriaItems[index].type = "report"
                previous[groupIndex].criteriaItems[index][fieldName] = value
                previous[groupIndex].criteriaItems[index].reportFieldId = null
                previous[groupIndex].criteriaItems[index].comparisonOperator = "="
                previous[groupIndex].criteriaItems[index].value = ""
                previous[groupIndex].criteriaItems[index].displayName = []
                return previous
            })
        }
        else {
            setConditionGroup(prev => {
                let previous = [...prev]
                previous[groupIndex].criteriaItems[index][fieldName] = value
                return previous
            })
        }
    }

    const languageOnChange = (value, language, fieldName, index) => {
        let currentLanguageIndex = conditionGroup[groupIndex]?.criteriaItems[index]?.displayName?.findIndex(item => item.language === language)
        if(currentLanguageIndex >= 0){
            setConditionGroup(prev => {
                let previous = [...prev]
                previous[groupIndex].criteriaItems[index].displayName[currentLanguageIndex].content[fieldName] = value
                return previous
            })
        }
        else {
            setConditionGroup(prev => {
                let previous = [...prev]
                let tempDisplayName:any = []
                if(previous[groupIndex]?.criteriaItems[index]?.displayName && previous[groupIndex]?.criteriaItems[index]?.displayName.length > 0){
                    tempDisplayName = [
                        ...previous[groupIndex]?.criteriaItems[index]?.displayName,
                        {language: language, content: {[fieldName]: value}}
                    ]
                }
                else {
                    tempDisplayName = [{language: language, content:{[fieldName]: value}}]
                }
                previous[groupIndex].criteriaItems[index].displayName = tempDisplayName
                return previous
            })
        }
    }
    
    const deleteConditionItem = (index) => {
        let originalRecords = [...conditionGroup]
        let updatedRecord = originalRecords[groupIndex]
        updatedRecord?.criteriaItems.splice(index, 1)
        updatedRecord?.criteriaItems.forEach((item) => {
            if(item.position >= index){
                item.position -= 1;
            }
        });
        originalRecords[groupIndex] = updatedRecord

        setConditionGroup(originalRecords)
    }

    const moveUpConditionItem = (index) => {
        let originalRecord = [...conditionGroup]
        let updatedRecord = originalRecord[groupIndex]?.criteriaItems?.map((item, i) => ({
            ...item,
            position: i === index ? item.position - 1 : i === index - 1 ? item.position + 1 : item.position
        })).sort((a, b) => a.position - b.position);
        originalRecord[groupIndex].criteriaItems = updatedRecord

        setConditionGroup(originalRecord);
    };

    const moveDownConditionItem = (index) => {
        let originalRecord = [...conditionGroup]
        let updatedRecord = originalRecord[groupIndex]?.criteriaItems?.map((item, i) => ({
            ...item,
            position: i === index ? item.position + 1 : i === index + 1 ? item.position - 1 : item.position
        })).sort((a, b) => a.position - b.position);
        originalRecord[groupIndex].criteriaItems = updatedRecord
    
        setConditionGroup(originalRecord);
    };

    const getReportFieldTypeFromConditionItems = (index) => {
        let [targetField] = reportFields.filter(item => item.fieldId === conditionGroup[groupIndex]?.criteriaItems[index]?.reportFieldId)
        let [dataType] = reportFieldTypes.filter(item => item._id === targetField?.typeId)

        return dataType?.name
    }

    const onValueChange = (value, fieldName, index) => {
        const dataType = getReportFieldTypeFromConditionItems(index)
        if(dataType === "integer" || dataType === "double"){
            setConditionGroup(prev => {
                let previous = [...prev]
                previous[groupIndex].criteriaItems[index][fieldName] = value?.toString()
                return previous
            })
        }
        else if(dataType === "datetime" || dataType === "timestamp"){
            setConditionGroup(prev => {
                let previous = [...prev]
                previous[groupIndex].criteriaItems[index][fieldName] = value
                return previous
            })
        }
        else {
            setConditionGroup(prev => {
                let previous = [...prev]
                previous[groupIndex].criteriaItems[index][fieldName] = value?.target?.value
                return previous
            })
        }
    }

    const getDataWithLanguage = (language, fieldName, index) => {
        if(conditionGroup[groupIndex]?.criteriaItems[index]?.displayName && conditionGroup[groupIndex]?.criteriaItems[index]?.displayName.length > 0){
            let currentLanguageIndex = conditionGroup[groupIndex]?.criteriaItems[index]?.displayName?.findIndex(item => item.language === language)
            if(currentLanguageIndex >= 0){
                return conditionGroup[groupIndex]?.criteriaItems[index]?.displayName[currentLanguageIndex]?.content?.[fieldName]
            }
        }
        return ""
    }

    const tabItems = languageData?.map((lang, index) => {
        return {
            key: index?.toString(),
            label: lang?.name,
            children: (
                <Row gutter={16}>
                    <Col className="gutter-row" xs={24} md={12}>
                        <InputFormItem
                            name={`condition_displayLabel_${lang.index}_${lang.name}`}
                            label={"Condition Display Label"}
                            placeholder={"Condition Display Label"}
                            layout={verticalFormLayout}
                            onChange={(e)=> languageOnChange(e, lang.code, "displayLabel", conditionItemIndex)}
                            value={getDataWithLanguage(lang.code, "displayLabel", conditionItemIndex)}
                            disabled={mode === "view"}
                        />
                    </Col>
                    <Col className="gutter-row" xs={24} md={12}>
                        <InputFormItem
                            name={`condition_measurementUnits_${lang.index}_${lang.name}`}
                            label={"Measurement Units"}
                            placeholder={"Measurement Units"}
                            layout={verticalFormLayout}
                            onChange={(e)=> languageOnChange(e, lang.code, "measurementUnits", conditionItemIndex)}
                            value={getDataWithLanguage(lang.code, "measurementUnits", conditionItemIndex)}
                            disabled={mode === "view"}
                        />
                    </Col>
                </Row>
            ),
        };
    });

    return (
        <>
            {
                item && item?.map((x, index) => {
                    return (
                        <Row className={"condition-item"} key={`criteriaItem_${groupIndex}_${index}`}>
                            <Col span={3}/>
                            {index > 0 ?
                                <Col span={2}>
                                    <Select 
                                        value={x?.logicalOperator}
                                        options={logicalOperator}
                                        style={{ width: "80%" }}
                                        onChange={(e)=>onChange(e, "logicalOperator",index)}
                                        disabled={mode === "view" || currentRecord?.missionTotalClaimed > 0 || currentRecord?.missionTotalCompleted > 0}
                                    />
                                </Col>
                                :
                                <Col span={2}/>
                            }
                            <Col span={5}>
                                <Select 
                                    value={x?.reportId}
                                    style={{ width: "90%" }}
                                    options={getCurrentReportOptions()}
                                    onChange={(e)=>onChange(e, "reportId", index)}
                                    disabled={mode === "view" || currentRecord?.missionTotalClaimed > 0 || currentRecord?.missionTotalCompleted > 0}
                                />
                            </Col>
                            <Col span={5}>
                                <Select 
                                    value={x?.reportFieldId}
                                    style={{ width: "90%" }}
                                    options={getReportFields(x?.reportId)}
                                    onChange={(e)=>onChange(e, "reportFieldId", index)}
                                    disabled={mode === "view" || currentRecord?.missionTotalClaimed > 0 || currentRecord?.missionTotalCompleted > 0}
                                    optionFilterProp={"label"}
                                    showSearch
                                />
                            </Col>
                            <Col span={2}>
                                <Select 
                                    value={x?.comparisonOperator}
                                    style={{ width: "80%" }}
                                    options={comparisonOperator}
                                    onChange={(e)=>onChange(e, "comparisonOperator", index)}
                                    disabled={mode === "view" || currentRecord?.missionTotalClaimed > 0 || currentRecord?.missionTotalCompleted > 0}
                                />
                            </Col>
                            <Col span={3}>
                                {
                                    getReportFieldTypeFromConditionItems(index) === "string" &&
                                    <Input 
                                        style={{ width: "80%" }}
                                        value={x?.value}
                                        onChange={(e)=>onValueChange(e, "value", index)}
                                        disabled={mode === "view" || currentRecord?.missionTotalClaimed > 0 || currentRecord?.missionTotalCompleted > 0}
                                    />
                                }
                                {
                                    (getReportFieldTypeFromConditionItems(index) === "double" || getReportFieldTypeFromConditionItems(index) === "integer") &&
                                    <InputNumber
                                        style={{ width: "80%" }}
                                        value={x?.value}
                                        onChange={(e)=>onValueChange(e, "value", index)}
                                        disabled={mode === "view" || currentRecord?.missionTotalClaimed > 0 || currentRecord?.missionTotalCompleted > 0}
                                    />
                                }
                                {
                                    (getReportFieldTypeFromConditionItems(index) === "timestamp" || getReportFieldTypeFromConditionItems(index) === "datetime") &&
                                    <DatePicker
                                        style={{ width: "80%" }}
                                        value={x?.value && dayjs(x?.value)}
                                        onChange={(e)=>onValueChange(e, "value", index)}
                                        disabled={mode === "view" || currentRecord?.missionTotalClaimed > 0 || currentRecord?.missionTotalCompleted > 0}
                                    />
                                }
                                {
                                    getReportFieldTypeFromConditionItems(index) === "boolean" &&
                                    <Select
                                        style={{ width: "80%" }}
                                        value={x?.value}
                                        options={trueFalseOperator}
                                        onChange={(e)=>onValueChange(e, "value", index)}
                                        disabled={mode === "view" || currentRecord?.missionTotalClaimed > 0 || currentRecord?.missionTotalCompleted > 0}
                                    />
                                }
                                {
                                    
                                   ! getReportFieldTypeFromConditionItems(index) &&
                                    <Input
                                        style={{ width: "80%" }}
                                        value={x?.value}
                                        onChange={(e)=>onValueChange(e, "value", index)}
                                        disabled={mode === "view" || currentRecord?.missionTotalClaimed > 0 || currentRecord?.missionTotalCompleted > 0}
                                    />
                                }
                            </Col>
                            <Col span={2}>
                                <Row>
                                    <Space>
                                        <Button type={"link"} style={{padding: "unset"}}  onClick={() => {
                                            moveUpConditionItem(index)
                                        }} disabled={index === 0 || mode === "view" || currentRecord?.missionTotalClaimed > 0 || currentRecord?.missionTotalCompleted > 0}>
                                            <ArrowUpOutlined/>
                                        </Button>
                                        <Button type={"link"} style={{padding: "unset"}}  onClick={() => {
                                            moveDownConditionItem(index)
                                        }}disabled={index === item?.length - 1 || mode === "view" || currentRecord?.missionTotalClaimed > 0 || currentRecord?.missionTotalCompleted > 0}>
                                            <ArrowDownOutlined/>
                                        </Button>
                                        <Button type={"link"} style={{padding: "unset"}} onClick={() => {
                                            setOpenModal(!openModal)
                                            setConditionItemIndex(index)
                                        }}disabled={mode === "view" || currentRecord?.missionTotalClaimed > 0 || currentRecord?.missionTotalCompleted > 0}>
                                            <SettingOutlined/>
                                        </Button>
                                        <Button type={"link"} style={{padding: "unset"}} danger onClick={() => {
                                            deleteConditionItem(index)
                                        }} disabled={mode === "view" || currentRecord?.missionTotalClaimed > 0 || currentRecord?.missionTotalCompleted > 0}>
                                            <DeleteOutlined/>
                                        </Button>
                                    </Space>
                                </Row>
                            </Col>
                        </Row>
                    )
                })
            }
            <>
                <Modal
                    title={t(`Edit Display`)}
                    open={openModal}
                    onCancel={() => {
                        setOpenModal(false)
                        setActiveTab("0")
                    }}
                    footer={null}
                    width={800}
                    destroyOnClose
                >
                    <Tabs
                        defaultActiveKey="0"
                        items={tabItems}
                        onChange={(activeKey) => setActiveTab(activeKey)}
                        activeKey={activeTab}
                    />
                </Modal>
            </>
        </>
    )
}

export default MissionAutomationCriteriaItem