import React, {useEffect, useState} from "react";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import VoucherGroupTopBar from "../../component/voucherGroupTopBar";
import {useTranslation} from "react-i18next";
import { getVoucherGroup, getVoucherGroupCount, useDeleteVoucherGroupMutation } from "../../../../../../api/graphql/voucherGroup";
import VoucherGroupModal from "../../component/voucherGroupModal";
import { Popconfirm, Space, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import useAuthorize from "../../../../../../_common/authorize";

function VoucherGroup_1(props) {
    const {
        tableName,
        permissionActivities,
        column
    } = props;

    const {t} = useTranslation();

    const {deleteVoucherGroup} = useDeleteVoucherGroupMutation()
    const { systemErrorNotification } = useAuthorize();

    // const [formModalType, setFormModalType] = useState("");
    // const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    // const [selectedItem, setSelectedItem] = useState({});

    const [orderBy, setOrderBy] = useState<any>("")
    const [limit, setLimit] = useState<any>(20)
    const [offset, setOffset] = useState<any>(0)
    const [filter, setFilter] = useState<any>(null)

    const {data: totalCount} = getVoucherGroupCount({filter: filter})
    const {data, loading} = getVoucherGroup({filter: filter}, limit, offset, orderBy)

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize)
        setOffset(limit * (page - 1))
    }

    const deleteOnConfirm = async (id) => {
      try {
        await deleteVoucherGroup(id);
      } catch (e) {
        console.log(e);
        systemErrorNotification();
      }
    };

    return (
        <div id={"voucherGroup"}>
            {permissionActivities?.voucher?.add?.add  && (
                <VoucherGroupModal 
                  mode={props.formModalType} 
                  item= {props.selectedItem} 
                  isOpen={props.isFormModalOpen} 
                  closeFormModal={props.closeFormModal}  
                  languageData={props.languageData} 
                  voucher={props.voucher}
                />
            )}
            {column?.length > 0 && (
                <TableAntDesign
                    data={data?.getVoucherGroup?.data}
                    tableName={tableName}
                    columns={[
                        ...column,
                        {
                            title: t("common.action"),
                            key: "action",
                            width: 132,
                            render: (_, record: any) => (
                              <Space size="middle">
                                <Tooltip title={t("View")}>
                                  <a onClick={() => props.openFormModal && props.openFormModal("view", record)}>
                                    <EyeOutlined />
                                  </a>
                                </Tooltip>
                                {permissionActivities?.voucher?.edit?.edit && (
                                  <Tooltip title={t("Edit")}>
                                    <a onClick={() => props.openFormModal && props.openFormModal("edit", record)}>
                                      <EditOutlined />
                                    </a>
                                  </Tooltip>
                                )}
                                {permissionActivities?.voucher?.delete?.delete && (
                                    <Tooltip title={t("Delete")}>
                                      <Popconfirm
                                        placement="left"
                                        title={t("Are you sure to delete this voucher setting?")}
                                        onConfirm={() => {
                                          deleteOnConfirm(record._id);
                                        }}
                                        okText={t("common.yes")}
                                        cancelText={t("common.no")}
                                      >
                                        <a>
                                          <DeleteOutlined />
                                        </a>
                                      </Popconfirm>
                                    </Tooltip>
                                )}
                                </Space>
                            )
                        }
                    ]}
                    size={"small"}
                    loading={loading}
                    filter={setFilter}
                    order={setOrderBy}
                    pagination={{
                        pageSize: limit,
                        showSizeChanger: true,
                        onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                        total: totalCount,
                        showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                    }}
                />
            )}
        </div>
    );
}

export default VoucherGroup_1;
