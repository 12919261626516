import React from "react"
import {Button, Col, Row} from "antd"
import {useTranslation} from "react-i18next"

const TemplatePreviewToolbar = (props) => {
    const { handlePreview } = props 
    const { t } = useTranslation()

    return (
        <>
            <Row className="margin-top-0-75 margin-bottom-0-75">
                <Col xs={2} offset={20}>
                    <Button onClick={handlePreview} type="primary">{t('Preview')}</Button>
                </Col>
            </Row>
        </>
    )
}

export default TemplatePreviewToolbar