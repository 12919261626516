import React, {useEffect, useMemo, useState} from "react";
import {Button, Col, Divider, Form, Input, message, Row, Select, Space, Switch, Tabs, Upload} from "antd";
import {useTranslation} from "react-i18next";
import {getConfigByName} from "../../../../../../../config";
import {BRAND_NAME} from "../../../../../../../config/brandVariable";
import DocumentLanguageForm from "./documentLanguageForm";
import {updateMamPammLegalDocument} from "../../../../../../api/graphql/mamPammLegalDocument";
import useAuthorize from "../../../../../../_common/authorize";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../recoil_state";
import { removeTypenameKey } from "../../../../../../function/_common";

const DocumentsEditingForm = (props) => {
    const {item, isEditing, isEditable, language, getData} = props;

    const {t} = useTranslation();
    const {systemErrorNotification} = useAuthorize();
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState("");
    const [inputData, setInputData] = useState<any>({});

    const [form] = Form.useForm();

    const onTabChange = (activeKey) => {
        try {
            setActiveTab(activeKey);
        } catch (error) {}
    };

    const handleEditorOnChange = (e, langCode) => {
        try {
            setInputData((prevState: any) => {
                let tinyMceDataIndex = prevState?.tinyMce?.findIndex((t) => t.language === langCode);
                let amendedTinyMceData = prevState.tinyMce ? [...prevState.tinyMce] : [];
                if (tinyMceDataIndex !== -1) {
                    amendedTinyMceData[tinyMceDataIndex] = {language: langCode, content: e};
                } else {
                    amendedTinyMceData.push({language: langCode, content: e});
                }
                let newInputData = {
                    ...prevState,
                    tinyMce: amendedTinyMceData,
                };
                return newInputData;
            });
        } catch (error) {}
    };

    const handleDisplayNamerOnChange = (e, langCode) => {
        try {
            setInputData((prevState: any) => {
                let displayNameDataIndex = prevState?.displayName?.findIndex((t) => t.language === langCode);
                let amendedDisplayNameData = prevState.displayName ? [...prevState.displayName] : [];
                if (displayNameDataIndex !== -1) {
                    amendedDisplayNameData[displayNameDataIndex] = {language: langCode, content: e};
                } else {
                    amendedDisplayNameData.push({language: langCode, content: e});
                }
                let newInputData = {
                    ...prevState,
                    displayName: amendedDisplayNameData,
                };
                return newInputData;
            });
        } catch (error) {}
    };

    const handleIsActiveChange = (checked) => {
        try {
            setInputData((prevState: any) => ({
                ...prevState,
                isActive: checked,
            }));
        } catch (error) {}
    };

    const updateDocument = async (value) => {
        try {
            setSubmitLoading(true);
            form.validateFields();
            let input = await removeTypenameKey(inputData);

            const payload = {
                name: input?.name,
                displayName: input?.displayName,
                tinyMce: input?.tinyMce,
                isActive: input?.isActive,
                isDefault: true,
            };

            const res = await updateMamPammLegalDocument(isEditing, payload);
            const response = res?.updateMamPammLegalDocument;
            if (response?.__typename === "MamPammLegalDocument") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("common.updateSuccess"),
                    key: "updateMamPammLegalDocument",
                });
                getData();
            } else if (response?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.errKey),
                    key: "updateMamPammLegalDocument",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.msg),
                    key: "updateMamPammLegalDocument",
                });
            }
        } catch (error: any) {
            systemErrorNotification();
        } finally {
            setSubmitLoading(false);
        }
    };

    useEffect(() => {
        if (language?.length > 0) setActiveTab(language?.[0]?.value);
    }, [language]);

    useEffect(() => {
        if (item) {
            let input = {...item};
            delete input?.id;
            delete input?._id;
            delete input?.createdAt;
            delete input?.updatedAt;
            delete input?.__typename;
            setInputData(input);
        }
    }, [item]);

    const tabItems: any = useMemo(() => {
        return language?.map((lang, index) => {
            return {
                key: lang?.value,
                label: lang?.label,
                children: (
                    <DocumentLanguageForm
                        activeTab={activeTab}
                        languageCode={lang.value}
                        inputData={inputData}
                        handleDisplayNamerOnChange={handleDisplayNamerOnChange}
                        handleEditorOnChange={handleEditorOnChange}
                        handleIsActiveChange={handleIsActiveChange}
                        isEditable={isEditable}
                        form={form}
                        submitLoading={submitLoading}
                    />
                ),
            };
        });
    }, [language, inputData, activeTab]);

    return (
        <Form onFinish={updateDocument} form={form}>
            <Tabs tabPosition={"right"} activeKey={activeTab} items={tabItems} onChange={onTabChange} />
        </Form>
    );
};

export default DocumentsEditingForm;
