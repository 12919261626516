import React from "react";
import {Modal} from "antd";
import {useTranslation} from "react-i18next";
import MissionGroupForm from "./missionGroupForm";

const MissionGroupModal = (props) => {
    const {mode, item, isOpen, closeFormModal} = props;
    const {t} = useTranslation();

    const getTitle = (): String => {
        switch (mode) {
            case "add":
                return `${t("Add New Mission Group")}`;
            case "view":
                return `${t("View Mission Group")}`;
            case "edit":
                return `${t("Edit Mission Group")}`;
            default:
                return `${t("Add New Mission Group")}`;
        }
    };

    return (
        <Modal
            getContainer="#missionGroup"
            title={getTitle()}
            open={isOpen}
            width={"80%"}
            footer={null}
            closable={true}
            destroyOnClose
            onCancel={closeFormModal}
        >
            <MissionGroupForm 
                mode={mode} 
                item={item} 
                closeFormModal={closeFormModal} 
                languageData={props.languageData} 
            />
        </Modal>
    );
};

export default MissionGroupModal;
