import React from "react";
import type {ColumnsType} from "antd/es/table";

interface DataType {
    email: string;
    isActive: string;
    host: string;
    driver: string;
    secure: string;
}

export const columns: ColumnsType<DataType> = [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Email Type",
        dataIndex: "emailType",
        key: "emailType",
        render: (emailType) => <p>{emailType?.name || "-"}</p>,
    },
    {
        title: "Email Domain",
        dataIndex: "emailDomain",
        key: "emailDomain",
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Host",
        dataIndex: "host",
        key: "host",
        width: 100,
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Port",
        dataIndex: "port",
        key: "port",
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Sender Name",
        dataIndex: "senderName",
        key: "senderName",
        render: (text) => <p>{text || "-"}</p>,
    },
    {
        title: "Status",
        dataIndex: "isActive",
        key: "isActive",
        render: (isActive) => <p>{isActive ? "Active" : "Inactive"}</p>,
    },
];
