import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Col, Empty, Modal, Row, Space, Spin} from "antd";
import {getReopenClosedPositionPayload, reopenClosedPosition, voidClosedPosition} from "../../../../../../../api/graphql/voidReopenClosedPosition";
import useAuthorize from "../../../../../../../_common/authorize";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";

const ReopenPositionModalCT = ({isOpen, handleCancel, closedPosition, accountId, status, getReportDetails}) => {
    const {t} = useTranslation();
    const [initialPayload, setInitialPayload] = useState(null);
    const [payloadLoading, setPayloadLoading] = useState<boolean>(false);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {baseErrorChecking, systemErrorNotification} = useAuthorize();

    const positionId = closedPosition?.positionId?.displayValue?.toString();
    const title = status === "reopen" ? "Reopen Closed Position" : "Void and Reopen Closed Position";

    const getInitialPayload = async () => {
        try {
            setPayloadLoading(true);
            const response = await getReopenClosedPositionPayload(accountId, positionId);
            setInitialPayload(response?.getReopenClosedPositionPayload?.response);
        } catch (e) {
        } finally {
            setPayloadLoading(false);
        }
    };

    const handleReopenClosedPosition = async () => {
        try {
            const response = await reopenClosedPosition(accountId, positionId, {
                fieldName: "commissionStatus",
                rowId: closedPosition?._id?.displayValue,
                value: "VOID_AND_REOPENED",
            });

            if (response?.reopenClosedPosition?.__typename === "BaseError") {
                baseErrorChecking(response?.reopenClosedPosition);
            } else if (response?.reopenClosedPosition?.status === "success") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Reopen Closed Position Successfully"),
                    key: "voidClosedPosition",
                });
            }

            getReportDetails();
        } catch (e) {
            systemErrorNotification();
        }
    };

    const handleVoidAndReopenClosedPosition = async () => {
        try {
            setSubmitLoading(true);
            if (status === "voidAndReopen") {
                const response = await voidClosedPosition(
                    accountId,
                    positionId,
                    {
                        fieldName: "commissionStatus",
                        rowId: closedPosition?._id?.displayValue,
                        value: "VOID",
                    },
                    true
                );

                if (response?.voidClosedPosition?.__typename === "BaseError") {
                    baseErrorChecking(response?.voidClosedPosition);
                } else if (response?.voidClosedPosition?.status === "success") {
                    await handleReopenClosedPosition();
                }
            } else if (status === "reopen") {
                await handleReopenClosedPosition();
            }
        } catch (e) {
            systemErrorNotification();
        } finally {
            setSubmitLoading(false);
            handleCancel();
        }
    };

    useEffect(() => {
        if (closedPosition && accountId && isOpen) {
            getInitialPayload();
        } else if (!isOpen) {
            setInitialPayload(null);
        }
    }, [closedPosition, isOpen]);

    return (
        <Modal
            title={title}
            open={isOpen}
            onCancel={handleCancel}
            footer={
                !payloadLoading &&
                initialPayload && (
                    <Row justify={"end"}>
                        <Space>
                            <Button onClick={handleCancel}>{t("common.cancel")}</Button>
                            <Button loading={submitLoading} onClick={handleVoidAndReopenClosedPosition} type={"primary"}>
                                {t("common.confirm")}
                            </Button>
                        </Space>
                    </Row>
                )
            }
        >
            {payloadLoading ? (
                <Row justify={"center"} style={{marginTop: 40}}>
                    <Spin />
                </Row>
            ) : initialPayload ? (
                <Row gutter={[16, 8]} justify={"start"}>
                    {Object.keys(initialPayload)
                        ?.filter((d) => d !== "stopLoss" && d !== "newWay")
                        ?.map((key) => {
                            const keys = ["unrealizedSwaps", "usedMargin"];
                            let value,
                                label = key;

                            switch (key) {
                                case "unrealizedSwaps":
                                case "usedMargin":
                                    value = initialPayload[key] / 100;
                                    break;
                                case "volume":
                                    value = closedPosition?.volumeLots?.displayValue;
                                    label = "Volume (lots)";
                                    break;
                                case "chargeCommission":
                                    value = initialPayload[key] ? "True" : "False";
                                    break;
                                case "traderId":
                                    value = closedPosition?.accountNo?.displayValue;
                                    label = "Account No";
                                    break;
                                case "symbolId":
                                    value = closedPosition?.symbol?.displayValue;
                                    label = "Symbol";
                                    break;
                                // case "reopenAdjs":
                                //     value = initialPayload[key];
                                //     label = "Reopen Adjustment:";
                                //     break;
                                default:
                                    value = initialPayload[key];
                            }

                            if (key === "reopenAdjs") {
                                if (status !== "reopen") {
                                    return (
                                        <>
                                            <br />
                                            <Col span={24}>
                                                <h4>Reopen Adjustment:</h4>
                                            </Col>
                                            <Row gutter={[16, 8]} style={{marginLeft: 8}}>
                                                {Object.keys(initialPayload[key])?.map((reopenKey) => {
                                                    switch (reopenKey) {
                                                        case "profit":
                                                            label = "P&L";
                                                            value = initialPayload[key][reopenKey] / 100;
                                                            break;
                                                        case "commission":
                                                            label = "Commission";
                                                            value = initialPayload[key][reopenKey] / 100;
                                                            break;
                                                        case "swap":
                                                            label = "Swaps";
                                                            value = initialPayload[key][reopenKey] / 100;
                                                            break;
                                                        case "comment":
                                                            label = "Adjustment Comment";
                                                            value = initialPayload[key][reopenKey];
                                                            break;
                                                        case "total":
                                                            label = "Total Adjustment";
                                                            value = -initialPayload[key][reopenKey] / 100;
                                                            break;
                                                        default:
                                                            label = reopenKey;
                                                    }

                                                    let className = ["total"]?.includes(reopenKey) ? "text-bold" : "";
                                                    return (
                                                        <>
                                                            <Col span={11} className={className}>
                                                                {label}
                                                            </Col>
                                                            <Col span={1} className={className}>
                                                                :
                                                            </Col>
                                                            <Col span={12} className={className}>
                                                                {value ? value?.toLocaleString() : value}
                                                            </Col>
                                                        </>
                                                    );
                                                })}
                                            </Row>
                                        </>
                                    );
                                }
                                return <></>;
                            }
                            return (
                                <>
                                    <Col span={11}>{label}</Col>
                                    <Col span={1}>:</Col>
                                    <Col span={12}>{value}</Col>
                                </>
                            );
                        })}
                </Row>
            ) : (
                <Row justify={"center"} style={{marginTop: 30}}>
                    <Empty />
                </Row>
            )}
        </Modal>
    );
};

export default ReopenPositionModalCT;
