import {
    cancelUserVerificationRecord,
    completeUserVerificationRecord,
    createUserVerificationRecord,
    createUserVerificationRecordDocument,
    getUserVerificationRecord,
    getUserVerificationRecordValue,
    saveUserVerificationRecord
} from "../../../../../api/graphql/userVerification";
import useAuthorize from "../../../../../_common/authorize";
import {useSetRecoilState} from "recoil";
import {userAccountVerificationRecordsState, userVerificationRecordLoadingState, userVerificationRecordValueState} from "../../state";
import {notificationMessage} from "../../../../../../recoil_state";
import {useTranslation} from "react-i18next";

const useClientDetailFunction = () => {
    const {baseErrorChecking, systemErrorNotification} = useAuthorize()
    const setUserVerificationRecordValue = useSetRecoilState<any>(userVerificationRecordValueState)
    const setUserVerificationRecordLoading = useSetRecoilState<any>(userVerificationRecordLoadingState)
    const setVerificationRecords = useSetRecoilState<any>(userAccountVerificationRecordsState);
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {t} = useTranslation()

    const getUserVerificationRecordValueFunction = async (filter) => {
        try {
            const response: any = await getUserVerificationRecordValue(filter);
            if (response?.getUserVerificationRecordValue?.__typename === "UserVerificationRecordValueArray") {
                const data = response?.getUserVerificationRecordValue?.data;
                setUserVerificationRecordValue(data);
                return data
            }
        } catch (e) {
            systemErrorNotification()
        }
    };

    const getUserVerificationRecordFunction = async (filter) => {
        try {
            setUserVerificationRecordLoading(true)
            const response: any = await getUserVerificationRecord(filter);
            if (response?.getUserVerificationRecord?.__typename === "UserVerificationRecordArray") {
                const data = response?.getUserVerificationRecord?.data;
                setVerificationRecords(data?.slice()?.sort((a, b) => new Date(b?.clientEndTime).getTime() - new Date(a?.clientEndTime).getTime()));
                return data
            }
        } catch (e) {
            systemErrorNotification()
        } finally {
            setUserVerificationRecordLoading(false)
        }
    };

    const handleCreateNewDocumentRecord = async ({
                                                     groupSetting,
                                                     document,
                                                     user,
                                                     record,
                                                 }) => {
        let deviceInfo;
        try {
            // deviceInfo = await getClientDeviceInfo();

            const createUserVerificationPayload = {
                groupSetting: groupSetting,
                document: document,
                user,
                record,
                // browser: deviceInfo?.browser,
                // device: deviceInfo?.device,
                // deviceToken: deviceInfo?.deviceToken,
                // ip: deviceInfo?.ip,
            };

            return createUserVerificationRecordDocument(createUserVerificationPayload);
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error",
                key: "systemNotification",
            });
        }
    };

    const handleCreateUserVerificationRecord = async ({id, countryName, clientType,}) => {
        try {
            // let deviceInfo = await getClientDeviceInfo();

            const createUserVerificationRecordPayload = {
                user: id,
                countryName,
                clientType,
                // browser: deviceInfo?.browser,
                // device: deviceInfo?.device,
                // deviceToken: deviceInfo?.deviceToken,
                // ip: deviceInfo?.ip,
            };
            const res = await createUserVerificationRecord(createUserVerificationRecordPayload);
            if (res.createUserVerificationRecord) {
                // setVerificationRecords(res.createUserVerificationRecord);
                return true;
            }
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error",
                key: "systemNotification",
            });
        }
        return false;
    };

    const handleCancelUserVerificationRecord = async ({userId, record}) => {
        try {
            const createUserVerificationRecordPayload = {
                user: userId,
                record: record,
            };

            const res = await cancelUserVerificationRecord(createUserVerificationRecordPayload);
            if (res.cancelUserVerificationRecord) {
                setVerificationRecords([]);
                return true;
            }
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error",
                key: "systemNotification",
            });
            return false;
        }
        return false;
    };

    const handleSaveUserVerificationRecord = async ({userId, field, documentRecord, record,}) => {
        try {
            const res = await saveUserVerificationRecord({
                user: userId,
                field,
                record,
                documentRecord,
            });

            if (res.saveUserVerificationRecord?.__typename === "BaseError") {
                await baseErrorChecking(res?.saveUserVerificationRecord)
                return false
            }

            return true;
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error",
                key: "systemNotification",
            });
            return false;
        }
    };

    const handleCompleteVerification = async (userId, record) => {
        try {
            await completeUserVerificationRecord({
                user: userId,
                record,
            });

            // await refetchUser();

            setNotificationMessage({
                status: "success",
                title: "",
                msg: "Submit Successfully",
                key: "accountVerificationNotification",
            });
            return true;
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error",
                key: "systemNotification",
            });
            return false;
        }
    };

    const fetchVerificationRecord = async (userId) => {
        try {
            const res = await getUserVerificationRecord({
                user: userId,
            });
            if (res.getUserVerificationRecord?.data) {
                setVerificationRecords(res.getUserVerificationRecord?.data);
                return true;
            }
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error",
                key: "systemNotification",
            });
            return false;
        }
        return false;
    }

    return {
        getUserVerificationRecordValueFunction,
        getUserVerificationRecordFunction,
        handleCreateNewDocumentRecord,
        handleCreateUserVerificationRecord,
        handleCancelUserVerificationRecord,
        handleSaveUserVerificationRecord,
        handleCompleteVerification,
        fetchVerificationRecord
    }
}

export {useClientDetailFunction}