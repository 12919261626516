import React from "react";
import {Popconfirm, Space, Table, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined, MailOutlined,} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useDeleteEmailTemplateMutation} from "../../../../../../api/graphql/emailTemplate";
import {columns} from "../../../function/tableColumn";
import useAuthorize from "../../../../../../_common/authorize";

const TemplateTable = (props) => {
    const {
        loading,
        item,
        openFormModal,
        openSendEmailModal,
        emailTemplatePermission,
    } = props;
    const {t} = useTranslation();
    const {systemErrorNotification} = useAuthorize();
    const {deleteEmailTemplate} = useDeleteEmailTemplateMutation();

    const deleteOnConfirm = async (id) => {
        try {
            await deleteEmailTemplate(id);
        } catch (e) {
            console.log(e);
            systemErrorNotification();
        }
    };

    return (
        <Table
            className={"margin-top-0-75"}
            loading={loading}
            dataSource={item}
            scroll={{x: true}}
            columns={[
                ...columns,
                {
                    title: t("common.action"),
                    key: "action",
                    width: 132,
                    render: (_, record: any) => (
                        <Space size="middle">
                            <Tooltip title={t("View")}>
                                <a onClick={() => openFormModal("view", record)}>
                                    <EyeOutlined/>
                                </a>
                            </Tooltip>
                            {emailTemplatePermission?.edit?.edit && (
                                <Tooltip title={t("Edit")}>
                                    <a onClick={() => openFormModal("edit", record)}>
                                        <EditOutlined/>
                                    </a>
                                </Tooltip>
                            )}
                            {record.type !== "system" &&
                            emailTemplatePermission?.delete?.delete && (
                                <Tooltip title={t("Delete")}>
                                    <Popconfirm
                                        placement="left"
                                        title={t("Are you sure to delete this template?")}
                                        // description={"Delete the Template"}
                                        onConfirm={() => {
                                            deleteOnConfirm(record._id);
                                        }}
                                        okText={t("common.yes")}
                                        cancelText={t("common.no")}
                                    >
                                        <a>
                                            <DeleteOutlined/>
                                        </a>
                                    </Popconfirm>
                                </Tooltip>
                            )}
                            {emailTemplatePermission?.testSend &&
                            !record?.isNotImplemented && (
                                <Tooltip title={t("Test Send Email")}>
                                    <a onClick={() => openSendEmailModal(record?.name)}>
                                        <MailOutlined/>
                                    </a>
                                </Tooltip>
                            )}
                        </Space>
                    ),
                },
            ]}
            rowKey="id"
            size="small"
            pagination={{
                // pageSize: 10,
                showSizeChanger: true,
                total: item?.length,
                showTotal: (total) => (
                    <p>{t("pagination.totalItems", {totalCount: total})}</p>
                ),
            }}
        />
    );
};

export default TemplateTable;
