import React from "react";
import {Divider} from "antd";
import PspCurrencyForm from "./pspCurrencyForm";
import PspDetailForm from "./pspDetailForm";

const PspSettingFormModalTabItem = (props) => {
  const {
    index,
    formMode,
    pspSettingName,
    pspCurrencyData,
    paymentMethodList,
    pspSettingCurrencyForm,
    pspSettingDetailForm,
  } = props;

  const labelCol = { span: 10, offset: 0 };

  return (
    <div key={index}>
      <PspCurrencyForm
        form={pspSettingCurrencyForm}
        item={pspCurrencyData}
        formMode={formMode}
        labelCol={labelCol}
      />
      <Divider />
      {paymentMethodList?.map((data, index) => {
        return (
          <div key={index}>
            <PspDetailForm
              key={data?.paymentMethod}
              form={pspSettingDetailForm}
              item={data}
              formMode={formMode}
              labelCol={labelCol}
              pspSettingName={pspSettingName}
            />
            {index === paymentMethodList?.length - 1 ? null : <Divider />}
          </div>
        );
      })}
    </div>
  );
};

export default PspSettingFormModalTabItem;
