import React, {useEffect, useState} from "react";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import LeadsTopBar from "../../component/leadsTopBar";
import LeadsAddForm from "../../component/leadsAddForm";
import LeadsImportForm from "../../component/leadsImportForm_New";
import {useTranslation} from "react-i18next";
import {formatSensitiveFields, renderColumns} from "../../../../_common/function";
import {Columns} from "../../function/tableColumn";
import {Button, Col, Empty, Row} from "antd";
import dayjs from "dayjs";
import {useGetUserLeadsLite} from "../../function/useQueryHooks";
import {formatDateTimeTimezone, formatDateUTC} from "../../../../../../function/_common";

function Leads_1(props) {
    const {tableName, profileInformation, permission} = props;
    const {t} = useTranslation();
    const [orderBy, setOrderBy] = useState<any>("");
    const [limit, setLimit] = useState<any>(20);
    const [offset, setOffset] = useState<any>(0);
    const [filter, setFilter] = useState<any>({});
    const [isArchived, setIsArchived] = useState<any>(false);
    const [filterType, setFilterType] = useState<any>("all");
    const [filterTypeId, setFilterTypeId] = useState<any>("");
    const [startDate, setStartDate] = useState<any>("");
    const [endDate, setEndDate] = useState<any>("");
    const [dateKey, setDateKey] = useState<any>("");

    const [isOpenAddFormModal, setIsOpenAddFormModal] = useState(false);
    const [isOpenImportFormModal, setIsOpenImportFormModal] = useState(false);
    const [batchUpdateRecord, setBatchUpdateRecord] = useState<any>([]);
    const [formattedExportData, setFormattedExportData] = useState<any>([])
    const [formattedData, setFormattedData] = useState<any>([])
    const columns = Columns()

    const [data, loading, fetchLeads, refetchLeads] = useGetUserLeadsLite()

    const isClearFilterVisible = Object.keys(filter)?.length > 0 || filterTypeId || (dateKey && startDate)
    const resetFilters = () => {
        setFilter({})
        setFilterType("all")
        setFilterTypeId("")
        setStartDate("")
        setEndDate("")
        setDateKey("")
    }

    const [column, setColumn] = useState<any>();

    useEffect(() => {
        if (profileInformation?.length > 0) {
            renderColumns(profileInformation, setColumn, columns, "lead", t);
        }
    }, [profileInformation]);

    useEffect(() => {
        fetchLeads({
            filter: {
                ...filter,
                isArchived: isArchived
            },
            filterType, filterTypeId, limit, offset, orderBy, startDate, endDate, dateKey
        })
    }, [filter, isArchived, filterType, filterTypeId, limit, offset, orderBy, startDate, endDate, dateKey]);

    const formatLeadsData = async (data) => {
        return await data?.map((d) => {
            return {
                ...d,
                assignedDatetime: d?.assignedDatetime ? formatDateUTC(d?.assignedDatetime) : "",
                nextFollowUp: d?.followUpDateTime ? formatDateTimeTimezone(d?.nextFollowUp) : "",
                lastFollowUp: d?.lastFollowUp ? formatDateTimeTimezone(d?.lastFollowUp) : "",
                createdAt: d?.createdAt ? formatDateTimeTimezone(d?.createdAt) : "",
                bindTo: d?.bind?.username,
                comment: t(d?.comment)
            };
        })
    }

    const processData = (data, e, permission) => {
        if (permission) {
            formatLeadsData(data)?.then(value => e(value))
        } else {
            formatLeadsData(data)?.then(value => formatSensitiveFields(value, profileInformation))?.then(value => e(value))
        }
    }

    useEffect(() => {
        if (data?.total > 0) {
            processData(data?.data, setFormattedData, permission?.view?.sensitiveInformation)
        } else {
            setFormattedData([])
        }
    }, [data, profileInformation, permission])

    useEffect(() => {
        setOffset(0)
    }, [isArchived])

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize);
        setOffset(limit * (page - 1));
    };

    const onRowSelection = (selectedRowKeys, selectedRows) => {
        setBatchUpdateRecord(selectedRows);
    };

    const setArchived = () => {
        if (isArchived) {
            setIsArchived(false);
        } else {
            setIsArchived(true);
        }
    };

    const openAddFormModal = () => {
        setIsOpenAddFormModal(true);
    };

    const closeAddFormModal = () => {
        setIsOpenAddFormModal(false);
    };

    const openImportFormModal = () => {
        setIsOpenImportFormModal(true);
    };

    const closeImportFormModal = () => {
        setIsOpenImportFormModal(false);
    };

    useEffect(() => {
        setFilterTypeId("");
    }, [filterType]);

    return (
        <div id={"leads"}>
            {permission?.add?.add && (
                <>
                    <LeadsAddForm
                        isOpen={isOpenAddFormModal}
                        close={closeAddFormModal}
                        tableName={tableName}
                        refetchLeads={refetchLeads}
                        profileInformation={profileInformation}
                    />
                    <LeadsImportForm
                        isOpen={isOpenImportFormModal}
                        close={closeImportFormModal}
                        profileInformation={profileInformation}
                    />
                </>
            )}
            <h1>
                {isArchived ? t("user.tableArchived", {tableName: t("module.leads")}) : t("user.tableActive", {tableName: t("module.leads")})}
                {
                    isClearFilterVisible &&
                    <Button className={"margin-left-0-5"} size={"small"} onClick={() => resetFilters()}>
                        {t("common.clearFilter")}
                    </Button>
                }
            </h1>
            <LeadsTopBar
                tableName={tableName}
                setArchived={setArchived}
                isArchived={isArchived}
                filterType={filterType}
                setFilterType={setFilterType}
                setFilterTypeId={setFilterTypeId}
                filterTypeId={filterTypeId}
                openAddFormModal={openAddFormModal}
                openImportFormModal={openImportFormModal}
                batchUpdateRecord={batchUpdateRecord}
                setBatchUpdateRecord={setBatchUpdateRecord}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setDateKey={setDateKey}
                exportData={formattedExportData}
                exportColumn={column}
                permission={permission}
                profileInformation={profileInformation}
                dateValue={dateKey === "createdAt" ? [dayjs(startDate), dayjs(endDate)] : [null, null]}
                refetchLeads={refetchLeads}
            />
            <Row>
                {column?.length > 0 ? (
                    <Col span={24}>
                        <TableAntDesign
                            data={formattedData}
                            tableName={tableName}
                            columns={column}
                            size={"small"}
                            loading={loading}
                            filter={setFilter}
                            startDate={setStartDate}
                            endDate={setEndDate}
                            dateKey={setDateKey}
                            order={setOrderBy}
                            filterValue={filter}
                            selectionType={"checkbox"}
                            onRowSelectionHandler={onRowSelection}
                            selectedRowKeys={batchUpdateRecord?.map((d) => d?._id)}
                            pagination={{
                                pageSize: limit,
                                showSizeChanger: true,
                                pageSizeOptions: [10, 20, 50, 100, 200, 500, 1000],
                                onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                                total: data?.total,
                                showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                            }}
                        />
                    </Col>
                ) : (
                    <Col span={24} style={{paddingTop: "10%"}}>
                        <Empty/>
                    </Col>
                )}
            </Row>
        </div>
    )
};

export default Leads_1
