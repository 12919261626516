import React from "react";
import {BsCheck, BsX} from "react-icons/bs";

export const Columns = () => {

    return [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            // ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: "Enabled",
            dataIndex: "isEnabled",
            key: "isEnabled",
            render: (text) => text ? <BsCheck style={{color: "green"}} /> : <BsX style={{color: "red"}} />,
        },
    ]
}