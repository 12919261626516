import {Editor} from "@tinymce/tinymce-react";
import {Form} from "antd";
import React, {useEffect, useRef} from "react";

const TinyMceEditor = ({apiKey, rules, name, label, setValue}) => {
    const editorRef = useRef(null);

    return (
        <Form.Item getValueFromEvent={e => e.target && e.target.getContent()} rules={rules} name={name} label={label}>
            <Editor
                onInit={(_, editor: any) => (editorRef.current = editor)}
                apiKey={apiKey}
                onEditorChange={(newValue, editor) =>
                    setValue(name, newValue)
                }
                init={{
                    height: 500,
                    menubar: true,
                    plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount",
                    ],
                    toolbar:
                        "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media component link anchor codesample | ltr rtl",
                    content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
            />
        </Form.Item>
    )
}

export default TinyMceEditor