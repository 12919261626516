import React, {useEffect, useMemo, useState} from "react";
import {useRecoilState} from "recoil";
import {useStore} from "react-context-hook";
import {Layout, Menu} from "antd";
import {MenuOutlined} from "@ant-design/icons";
import SimpleBarReact from "simplebar-react";
import {items} from "../../function/menuItem";
import "simplebar/src/simplebar.css";
import {selfPermissionList} from "../../../../../recoil_state";
import {BRAND_NAME} from "../../../../../config/brandVariable";

const {Sider} = Layout;

const Sider_1 = (props) => {
    const {collapsed, setCollapsed, collapsedWidth} = props;
    const [selfPermission] = useRecoilState<any>(selfPermissionList);
    const [theme] = useStore<any>("theme");
    const [inSetting, setInSetting] = useState(false);
    const [menuSelectedKeys, setMenuSelectedKeys] = useState<any[]>([]);

    const permittedMenuItems: any = useMemo(() => {
        const menuItems = items[0]?.children
            ?.filter((v: any) => {
                if (!v?.permission) {
                    return v?.children?.some(
                        (child) => !child?.permission || child.permission.some((permission) => !permission || selfPermission?.includes(permission))
                    );
                }
                return v.permission.some((p) => selfPermission?.includes(p));
            })
            .map((v: any) => ({
                ...v,
                children: v?.children?.filter(
                    (child) => !child?.permission || child.permission.some((permission) => !permission || selfPermission?.includes(permission))
                ),
            }));

        // Hard code automation setting for Antos and Exco
        if (!["antospnc", "antospncOld", "blueocean", "excotrades", "ridgewaymarkets", "fundamarkets", "minosfutures", "cronoscap", ].includes(BRAND_NAME) && menuItems) {
            return menuItems.filter(menu => menu.key !== "automation-settings")
        }

        return menuItems
    }, [items, selfPermission]);

    const onClickMenu = ({item, key, keyPath, domEvent}) => {
        setMenuSelectedKeys(keyPath);
    };

    const updateCurrentMenuKeys = () => {
        try {
            let updatedMenuKeys: any[] = [];
            permittedMenuItems.forEach((parent) => {
                parent?.children.forEach((child) => {
                    if (child?.path === location.pathname) {
                        updatedMenuKeys = [child?.key, parent?.key];
                    }
                });
            });
            setMenuSelectedKeys(updatedMenuKeys);
        } catch (error) {
        }
    };

    useEffect(() => {
        updateCurrentMenuKeys();
        if (location.pathname.startsWith("/settings")) {
            setInSetting(true);
        } else {
            setInSetting(false);
        }
    }, [location.pathname, permittedMenuItems]);

    return (
        <Sider
            width={"200px"}
            trigger={null}
            collapsible
            collapsed={collapsed}
            collapsedWidth={collapsedWidth}
            className="layout-sider"
            onMouseEnter={() => inSetting && setCollapsed(false)}
            onMouseLeave={() => setCollapsed(true)}
        >
            {React.createElement(MenuOutlined, {
                className: "layout-header-trigger",
                style: {height: 56},
                // onClick: () => setCollapsed(!collapsed),
            })}
            <SimpleBarReact style={{maxHeight: window.innerHeight - 60}}>
                <Menu
                    onClick={onClickMenu}
                    selectedKeys={menuSelectedKeys}
                    theme={theme}
                    mode="inline"
                    items={permittedMenuItems}
                    // inlineCollapsed={true}
                />
            </SimpleBarReact>
        </Sider>
    );
};

export default Sider_1;
