import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {getColumnSearchProps} from "../../../../components/table/function/filterTable";
import {formatDateTimeTimezone} from "../../../../function/_common";
import {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";
import {Tag, Tooltip} from "antd";

export const columns = () => {
    const {t} = useTranslation();
    const language = localStorage.getItem("lang")

    const statusOptions = [
        {text: "In Queue", value: "in_queue"},
        {text: "Failed", value: "failed"},
        {text: "Processing", value: "processing"},
        {text: "Success", value: "success"}
    ];

    const SeeMoreRecipientComponent = (props) => {
        const [view, setView] = useState(false);
        const {text, record} = props;
        const recipient = Array.isArray(text) ? text : (record?.recipient || []);

        let tagsToShow = recipient.slice(0, view ? recipient.length : 3);

        const renderTag = (tag, i) => (
            <Tag
                bordered={false}
                key={i}
                style={{margin: 2}}
                color={tag?.isDeleted ? "red" : i === 0 ? "blue" : "default"}
            >
                {tag?.username}
            </Tag>
        );

        const renderSeeMoreTag = () => (
            <Tag
                bordered={false}
                style={{margin: 2, cursor: "pointer"}}
                color={"green"}
                onClick={() => setView(true)}
            >
                ...
            </Tag>
        );

        return (
            <div>
                {tagsToShow.length > 0 ? (
                    <>
                        {tagsToShow.map(renderTag)}
                        {!view && recipient.length > 3 && renderSeeMoreTag()}
                    </>
                ) : (
                    "-"
                )}
            </div>
        );
    };

    return [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: "Title",
            dataIndex: "dataArrayOfObject",
            key: "title",
            ...getColumnSearchProps(),
            render: (array) => {
                const text = array?.find(item => item.language === language)?.title ||
                    array?.find(item => item.language === "en")?.title ||
                    "-";

                return <p>{text}</p>;
            },
        },
        {
            title: "Updated At",
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (text) => <p>{text ? formatDateTimeTimezone(text) : "-"}</p>,
        },
        {
            title: "Published",
            dataIndex: "isPublished",
            key: "isPublished",
            filters: [
                {text: "Published", value: true},
                {text: "Not Published", value: false},
            ],
            align: "center",
            render: (isPublished) => {
                if (isPublished)
                    return (
                        <Tooltip title={t("Published")}>
                            <CheckCircleOutlined style={{color: "green"}}/>
                        </Tooltip>
                    )
                else
                    return (
                        <Tooltip title={t("Not Published")}>
                            <CloseCircleOutlined style={{color: "red"}}/>
                        </Tooltip>
                    )
            }
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            filters: statusOptions,
            render: (status, row) => {
                const hideStatus = status === null || status === "success"

                if (!row.isPublished && hideStatus) return <p>-</p>;

                const statusObject = statusOptions.find(option => option.value === status) || {text: "-", value: "-"};

                return <p>{statusObject.text}</p>
            }
        },
        {
            title: "Recipients",
            dataIndex: "recipient",
            key: "recipient",
            ...getColumnSearchProps(),
            render: (text, record) => <SeeMoreRecipientComponent record={record}/>,
        },
        {
            title: "Send in Network?",
            dataIndex: "sendInNetwork",
            key: "sendInNetwork",
            ...getColumnSearchProps(),
            render: (text) => {
                if (text) {
                    return <p>Yes</p>
                } else return <p>No</p>
            },
        },
        {
            title: "User Type",
            dataIndex: "userType",
            key: "userType",
            filters: [
                {text: "All Users", value: "all"},
                {text: "Clients only", value: "client"},
                {text: "CRM Users only", value: "crmUser"},
            ],
            render: (text) => {
                if (text === "all") return <p>All Users</p>;
                else if (text === "client") return <p>Clients only</p>;
                else if (text === "crmUser") return <p>CRM Users only</p>;
                else if (!text) return <p>All Users</p>
            },
        },
    ];
};
