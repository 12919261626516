import React, {useEffect} from "react";
import {Card, Col, Form, Input, Row} from "antd";
import {useTranslation} from "react-i18next";
import {Checkbox} from "antd/lib";

const MarginMt5 = ({initialData}) => {
    const {t} = useTranslation()
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            ...initialData,
            limitSymbols: initialData?.limitSymbols === 0 ? "Unlimited" : initialData?.limitSymbols,
            limitPositions: initialData?.limitPositions === 0 ? "Unlimited" : initialData?.limitPositions,
            limitOrders: initialData?.limitOrders === 0 ? "Unlimited" : initialData?.limitOrders,
        });
    }, [initialData])

    return (
        <>
            <Row gutter={[16, 16]} style={{marginBottom: "1%", marginTop: "2%"}}>
                <Col span={24}>
                    <p>
                        Please set up the mechanism of margin calculation for the group: choose the method of
                        calculation and margin requirements
                    </p>
                </Col>
            </Row>

            <Form form={form}>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={24}>
                        <Form.Item
                            name={"marginMode"}
                            label={t("Risk management")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={8}>
                        <Form.Item
                            name={"marginCall"}
                            label={t("Margin call level")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={"marginStopOut"}
                            label={t("Stop out level")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={"marginSoMode"}
                            label={t("in")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={12}>
                        <Form.Item
                            label={<span style={{color: "red"}}>{t("Stop out fully hedged accounts")}</span>}
                        >
                            <Checkbox disabled={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={12}>
                        <Form.Item
                            label={<span
                                style={{color: "red"}}>{t("Compensate negative balance after stop out")}</span>}
                        >
                            <Checkbox disabled={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={12}>
                        <Form.Item
                            label={<span
                                style={{color: "red"}}>{t("Withdraw credit after negative balance compensation")}</span>}
                        >
                            <Checkbox disabled={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Card title="Profit/loss in free margin" bordered={false} style={{marginBottom: "1%"}}>
                    <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                        <Col span={24}>
                            <Form.Item
                                name={"marginFreeMode"}
                                label={t("Unrealized profit")}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                        <Col span={24}>
                            <Form.Item
                                name={"marginFreeProfitMode"}
                                label={t("Daily fixed profit")}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={24}>
                        <Form.Item
                            name={"tradeVirtualCredit"}
                            label={t("Virtual credit")}
                            extra="applies only to opening new positions"
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default MarginMt5