import React, {useEffect, useState} from "react";
import {Button, Space, Tabs} from "antd";
import ProductGroupTable_1 from "./component/productGroupTable";
import ExportTableButton from "../../../../../components/table/function/exportTable";
import EditTableUserPreferenceButton from "../../../../../components/table/function/editTableUserPreferenceButton";
import {useTranslation} from "react-i18next";
import {getProductGroup, getProductGroupCount} from "../../../../../api/graphql/productGroup";
import {columns} from "../../function/tableColumn_CT";
import ProductGroupModal from "./component/productGroupModal";

const ProductGroup = (props) => {
  const { permission } = props;
  const {t} = useTranslation()
  const [isOpenProductGroupForm, setIsOpenProductGroupForm] = useState(false);
  const [mode, setMode] = useState("");
  const [selectedItem, setSelectedItem] = useState({});
  const tableName = "Product Group";

  const [exportData, setExportData] = useState<any>(null);
  const [exportColumn, setExportColumn] = useState<any>(null);
  const [currentTab, setCurrentTab] = useState<any>("demo");

  const [orderBy, setOrderBy] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [filter, setFilter] = useState<any>({});

  const { data: totalCount } = getProductGroupCount({ filter: {...filter, type: currentTab} });
  const { loading, error, data } = getProductGroup(
      { filter: {...filter, type: currentTab} },
      limit,
      offset,
      orderBy
  );
  const { data: exportDataList, loading: exportLoading } = getProductGroup(
      { filter: {...filter, type: currentTab} },
      0,
      0,
      orderBy
  );

  const openAddModal = () => {
    setMode("Add");
    setIsOpenProductGroupForm(true);
  };

  const openEditModal = (record) => {
    setMode("Edit");
    setIsOpenProductGroupForm(true);
    setSelectedItem(record);
  };

  const closeModal = () => {
    setIsOpenProductGroupForm(false);
    setSelectedItem({});
  };

  useEffect(() => {
    setExportColumn(columns);
    setExportData(exportDataList?.getProductGroup?.data);
  }, [exportDataList?.getProductGroup?.data, currentTab]);

  const onChangePageHandler = (page, pageSize) => {
    setLimit(pageSize);
    setOffset(limit * (page - 1));
  };

  const componentProps = {
    data: data?.getProductGroup?.data,
    totalCount: totalCount?.getProductGroupCount,
    loading: loading,
    tableName: tableName,
    permission: permission,
    limit: limit,
    openEdit: openEditModal,
    onChangePageHandler: onChangePageHandler,
    setOrderBy: setOrderBy,
    setFilter: setFilter,
  }

  const tabItems:any = [
    {
      label: t("Demo"),
      size: "small",
      key: "demo",
      children: (
          <ProductGroupTable_1
              {...componentProps}
          />
      )
    },
    {
      label: t("Live"),
      size: "small",
      key: "live",
      children: (
          <ProductGroupTable_1
              {...componentProps}
          />
      )
    },
  ]

  return (
    <div id="product-group">
      <ProductGroupModal
          mode={mode}
          item={selectedItem}
          isOpen={isOpenProductGroupForm}
          closeModal={closeModal}
          currentTab={currentTab}
      />
      <h1>{t(tableName)}</h1>
        <Tabs
          defaultActiveKey="demo"
          size={"small"}
          items={tabItems}
          tabBarExtraContent={
            <>
              <Space>
                <EditTableUserPreferenceButton
                  tableName={tableName}
                  tableColumn={exportColumn}
                />
                <ExportTableButton
                  loading={exportLoading}
                  tableData={exportData}
                  tableColumn={exportColumn}
                  tableName={tableName}
                />
                { permission?.add?.add &&
                  <Button type={"primary"} onClick={() => openAddModal()}>
                  {t("Add Product Group")}
                </Button>
                }
              </Space>
            </>
          }
          onTabClick={(key) => {
            setCurrentTab(key);
          }}
        />
    </div>
  );
}

export default ProductGroup;
