import React, {useEffect, useState} from "react";
import {getReportingAll, getReportingFields} from "../../../../../../../../api/graphql/reporting";
import {useTranslation} from "react-i18next";
import {getReportingCategoryDropdown} from "../../../../../../../../api/graphql/reportingCategory";
import ScheduleConditionForm from "./scheduleConditionForm";
import TriggerConditionForm from "./triggerConditionForm";
import {useRecoilState} from "recoil";
import {logicOperatorAryState} from "../../../../state";

const ConditionForm = (props) => {
    const {t} = useTranslation()
    const {
        type,
        selectedTrigger,
        conditions,
        setConditions,
        isUpdate,
        isOpen,
        reportTargetedField,
        setReportTargetedField
    } = props
    const [reportFields, setReportFields] = useState<any>([])
    const [fieldOptions, setFieldOptions] = useState<any[]>([])
    const [availableReports, setAvailableReports] = useState<any[]>([])
    const [allReports, setAllReports] = useState<any[]>([])
    const [logicOperatorAry, setLogicOperatorAry] = useRecoilState<any>(logicOperatorAryState)

    const getReportFields = async () => {
        try {
            if (!reportFields || reportFields.length === 0) {
                const res = await getReportingCategoryDropdown()
                const list = res?.getReportingCategoryDropdown?.data || []

                const response = await getReportingFields();
                if (response?.getReportingFields?.__typename === "ReportingDataAry") {
                    const ids: string[] = Array.from(new Set(list?.flatMap(d => d?.reports?.map(c => c?.reportId)))) || []
                    let seenValues = new Set();

                    let filteredReportingFields = response?.getReportingFields?.data?.filter(item => item?.isUsedForAutomation && ids.includes(item?.reportId))
                    setReportFields(filteredReportingFields)

                    const options = filteredReportingFields?.reduce((acc, d) => {
                        const value = d.reportFieldName;
                        if (!seenValues.has(value)) {
                            seenValues.add(value);
                            acc.push({
                                label: d.uiName || d.reportFieldName,
                                value: d.reportFieldName
                            });
                        }
                        return acc;
                    }, []);

                    setFieldOptions(options);

                    const reportResponse = await getReportingAll()
                    if (response?.getReportingFields?.__typename === "ReportingDataAry") {
                        let filteredReport = reportResponse?.getReportingAll?.data?.filter((item: any) => ids?.includes(item?.reportId))
                        setAllReports(filteredReport)
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    const onReportTargetedFieldSelect = (value) => {
        if (value) {
            setReportTargetedField(value)
            const found = reportFields?.filter(d => d.reportFieldName === value)?.map(({reportName, reportId}) => {
                const foundReport = allReports?.find(d => d?._id === reportId)
                return {displayName: foundReport?.displayName, reportId}
            });
            setAvailableReports(found || [])
        } else {
            setReportTargetedField("")
            setAvailableReports([])
        }
    }

    const setLogicalOperator = (value, index) => {
        try {
            const operators = [...logicOperatorAry]
            operators[index + 1] = value

            setLogicOperatorAry(operators)
        } catch (e) {
            console.log(e)
        }
    };


    useEffect(() => {
        getReportFields()
    }, []);

    useEffect(() => {
        setAvailableReports([])
    }, [isOpen]);

    useEffect(() => {
        0
        if (isOpen && isUpdate && reportTargetedField && reportFields?.length > 0 && allReports?.length > 0) {
            onReportTargetedFieldSelect(reportTargetedField)
        }
    }, [isOpen, reportTargetedField, reportFields, allReports]);

    return (
        <div className={"conditions-form"}>
            {
                type === "schedule_report" && allReports?.length > 0 ?
                    <ScheduleConditionForm
                        fieldOptions={fieldOptions}
                        onReportTargetedFieldSelect={onReportTargetedFieldSelect}
                        availableReports={availableReports}
                        allReports={allReports}
                        conditions={conditions}
                        setConditions={setConditions}
                        setLogicalOperator={setLogicalOperator}
                        reportFields={reportFields}
                        reportTargetedField={reportTargetedField}
                    /> : type === "trigger" ?
                        <TriggerConditionForm isUpdate={isUpdate} selectedTrigger={selectedTrigger} conditions={conditions} setConditions={setConditions}
                                              setLogicalOperator={setLogicalOperator}/>
                        : <>Condition Not Available.</>
            }
        </div>
    )
}

export default ConditionForm