import {Space} from "antd";
import React from "react";
import {ColumnTradingAccountTransaction} from "../../../function/tableColumn";
import {useTranslation} from "react-i18next";
import EditTableUserPreferenceButton
    from "../../../../../../../components/table/function/editTableUserPreferenceButton";
import TableReportAntDesign from "../../../../../../../components/table/tableReportAntDesign";
import AccountTransactionLocal from "./accountTransactionLocal";


const AccountTableTransaction = (props) => {

    const tableName = "Deposit/Withdrawal Record"
    const columns = ColumnTradingAccountTransaction()

    const {
        data,
        setPageTransaction,
        rowsTransaction,
        setRowsTransaction,
        setOrderByTransaction,
        setFilterTransaction,
        reportDataTransaction,
        totalCountTransaction,
        loadingTransaction
    } = props

    const {t} = useTranslation()

    const onChangePageHandler = (page, pageSize) => {
        setPageTransaction(page);
        setRowsTransaction(pageSize);
    }


    return (
        <>
            {
                data?.mode === "demo" ? (
                    <AccountTransactionLocal data={data} type={"Deposit/Withdrawal"}/>
                ) : data?.mode === "live" ? (
                    <div id="accountHistory">
                        <h4>{t("tradingAccount.deposit_withdrawal_record")}</h4>
                        <div className={"display-flex margin-bottom-0-75"}>
                            <Space className={"margin-left-auto"}>
                                <EditTableUserPreferenceButton
                                    tableName={tableName}
                                    tableColumn={columns}
                                    displayTableName={t("tradingAccount.deposit_withdrawal_record")}
                                />
                            </Space>
                        </div>

                        <TableReportAntDesign
                            tableName={tableName}
                            size="small"
                            columns={columns}
                            loading={loadingTransaction}
                            data={reportDataTransaction}
                            filter={setFilterTransaction}
                            order={setOrderByTransaction}
                            rowKey={(record, index) => index}
                            scroll={{
                                scrollToFirstRowOnChange: true,
                                x: true,
                            }}
                            pagination={{
                                pageSize: rowsTransaction,
                                showSizeChanger: true,
                                onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                                total: totalCountTransaction,
                                showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
                            }}
                        />
                    </div>
                ) : null
            }
        </>
    )


}

export default AccountTableTransaction;
