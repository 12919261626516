import React from "react";
import SalesRepNetworkTree_1 from "./view/layout1/index";
import {getSalesRepNetworkTree} from "../../../api/graphql/salesRepNetworkTree";

function SalesRepNetworkTree() {
  let layout = 1;
  let component;
  const { data, loading, error, refetch } = getSalesRepNetworkTree();

  switch (layout) {
    case 1:
      component = (
        <SalesRepNetworkTree_1
          data={data?.getSalesRepNetworkTree?.data}
          loading={loading}
          error={error}
          refetch={refetch}
        />
      );
      break;

    default:
      component = (
        <SalesRepNetworkTree_1
          data={data?.getSalesRepNetworkTree?.data}
          loading={loading}
          error={error}
          refetch={refetch}
        />
      );
      break;
  }

  return component;
}

export default SalesRepNetworkTree;
