import React, {useEffect, useState} from "react";
import {Button, Card, Checkbox, Form, Input, Select} from "antd";
import TextArea from "antd/es/input/TextArea";
import {useTranslation} from "react-i18next";
import SendEmailForm from "./customizedActionForm/sendEmail";
import BodyFormList from "../../../../../customAction/view/layout1/components/bodyFormList";

const ActionForm = ({form, actionList, selectedAction, setSelectedAction, isUpdate, mode, emailTemplate}) => {
    const {t} = useTranslation();
    const customFormAction = ["Send Email"]

    const labelCol = {span: 6}
    const wrapperCol = {span: 18}
    const actionDropdown = actionList?.map(d => ({label: d?.name, value: d?.name}))

    const actionOnClickHandler = (value) => {
        const found = actionList?.find(d => d?.name === value)
        if (!customFormAction.includes(found?.name)) {
            form.setFieldsValue({...found, action: found?.name})
        }
        setSelectedAction(found)
    }

    return (
        <div className={"actions-form"}>
            {
                actionList?.length > 0 ? (
                    <Form form={form} labelCol={labelCol} wrapperCol={wrapperCol} labelAlign={"left"}>
                        <Form.Item name={"action"} label={"Action"}>
                            <Select
                                disabled={isUpdate && selectedAction}
                                options={actionDropdown}
                                onSelect={value => actionOnClickHandler(value)}
                            />
                        </Form.Item>

                        {
                            selectedAction && !customFormAction.includes(selectedAction?.name) && selectedAction?.body?.length > 0 ?
                                <>
                                    <Form.Item name={"method"} label={t("Method")}>
                                        <Select
                                            disabled
                                            options={[
                                                {label: "POST", value: "post"},
                                                {label: "GET", value: "get"},
                                            ]}
                                        />
                                    </Form.Item>
                                    <Form.Item name={"url"} label={t("URL")}>
                                        <Input disabled/>
                                    </Form.Item>
                                    <Form.Item label={t("Body")}>
                                        <Card size={"small"}>
                                            <Form.List name={"body"}>
                                                {(fields, {add, remove}, {errors}) => (
                                                    <>
                                                        {fields.map((field, index) => (
                                                            <Form.Item {...field} className={"form-list-item-container"}>
                                                                <BodyFormList field={field}
                                                                              referenceFromOptions={selectedAction?.reportOnly ?
                                                                                  [{label: "Custom", value: "custom"}]
                                                                                  : [
                                                                                      {label: "Custom", value: "custom"},
                                                                                      {label: "Trigger Payload", value: "payload"},
                                                                                  ]}
                                                                              remove={remove}
                                                                              isReportOnly={selectedAction?.reportOnly}
                                                                              fromAutomationSetting={true}
                                                                />
                                                            </Form.Item>
                                                        ))}
                                                    </>
                                                )}
                                            </Form.List>
                                        </Card>
                                    </Form.Item>
                                </>
                                : selectedAction?.name === "Send Email" ? (
                                    <SendEmailForm form={form} mode={mode} emailTemplate={emailTemplate}/>
                                ) : <></>
                        }

                        {/*{*/}
                        {/*    selectedAction && !customFormAction.includes(selectedAction?.name) && selectedAction?.body?.length > 0 ?*/}
                        {/*        // selectedAction?.field?.filter(d => selectedAction !== "Send Email" && d?.fieldName !== "name")?.map(field => {*/}
                        {/*        selectedAction?.body?.map(field => {*/}
                        {/*            let component*/}
                        {/*            let componentWithWrap*/}
                        {/*            let valueProps*/}

                        {/*            switch (field?.dataType) {*/}
                        {/*                case "singleselect":*/}
                        {/*                    component =*/}
                        {/*                        <Select showSearch optionFilterProp={"label"}*/}
                        {/*                                options={field?.value?.map(d => ({...d, label: t(d?.label)}))}/>*/}
                        {/*                    break*/}
                        {/*                case "multiselect":*/}
                        {/*                    component =*/}
                        {/*                        <Select showSearch optionFilterProp={"label"}*/}
                        {/*                                options={field?.value?.map(d => ({...d, label: t(d?.label)}))}*/}
                        {/*                                mode={"multiple"} allowClear/>*/}
                        {/*                    break*/}
                        {/*                case "multiselectinput":*/}
                        {/*                    componentWithWrap = (*/}
                        {/*                        <>*/}
                        {/*                            <Form.Item className={"margin-top-0-75"} key={field?.fieldName} name={field?.fieldName}*/}
                        {/*                                       label={field?.fieldName}>*/}
                        {/*                                <Select showSearch optionFilterProp={"label"}*/}
                        {/*                                        options={field?.value?.map(d => ({...d, label: t(d?.label)}))}*/}
                        {/*                                        mode={"multiple"} allowClear/>*/}
                        {/*                            </Form.Item>*/}
                        {/*                            <Form.Item key={`${field?.fieldName}_extraMultiSelect`} name={`${field?.fieldName}_extraMultiSelect`}*/}
                        {/*                                       label={" "} colon={false}>*/}
                        {/*                                <TextArea placeholder={"Type and press ENTER to add"}*/}
                        {/*                                          onKeyDown={(e) => extraInputOnKeyDown(e, `${field?.fieldName}_extraMultiSelect`)}/>*/}
                        {/*                            </Form.Item>*/}
                        {/*                        </>*/}
                        {/*                    )*/}
                        {/*                    break*/}
                        {/*                case "system":*/}
                        {/*                    component = <Input disabled/>*/}
                        {/*                    break*/}
                        {/*                case "boolean":*/}
                        {/*                    component = <Checkbox/>*/}
                        {/*                    valueProps = "checked"*/}
                        {/*                    break*/}
                        {/*                default:*/}
                        {/*                    component = <Input/>*/}
                        {/*            }*/}

                        {/*            return (*/}
                        {/*                componentWithWrap ? componentWithWrap :*/}
                        {/*                    <Form.Item className={"margin-top-0-75"} key={field?.fieldName} name={field?.fieldName} label={field?.fieldName}*/}
                        {/*                               valuePropName={valueProps}>{component}</Form.Item>*/}
                        {/*            )*/}
                        {/*        }) : selectedAction?.name === "Send Email" ? (*/}
                        {/*            <SendEmailForm form={form} mode={mode} emailTemplate={emailTemplate}/>*/}
                        {/*        ) : <></>*/}
                        {/*}*/}
                    </Form>
                ) : (
                    <p>Action Not Available.</p>
                )
            }
        </div>
    )
}

export default ActionForm