import React from "react";
import {useTranslation} from "react-i18next";
import {Space} from "antd";
import SuccessIcon from "../../../../../../../../../../assets/icon/success-icon";

const VerificationComplete = () => {
    const {t} = useTranslation();

    return (
        <Space
            style={{width: "100%", padding: "40px 0", fontSize: 24}}
            size={0}
            direction="vertical"
            className="verification-complete-section text-align-center"
        >
            <div style={{width: 30}}  className="margin-left-auto margin-right-auto">
                <SuccessIcon/>
            </div>
            <div className="verification-complete-section-title">
                {t("accountVerification.youre_all_set")}
            </div>
        </Space>
    );
}

export default VerificationComplete