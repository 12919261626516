import axios from "axios";
import {getConfigByName} from "../../../../config";
import {BRAND_NAME} from "../../../../config/brandVariable";

const url = getConfigByName(BRAND_NAME).arenaURL
const config = {headers: {"Content-Type": "text/plain", "authorization": `Bearer ${localStorage.getItem("arenaToken")}`}}

export const getLevelSetting = async () => {
    try {
        return await axios.post(`${url}/api/level/setting/get`, {}, config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export const updateLevelSetting = async (input) => {
    try {
        return await axios.post(`${url}/api/level/setting/update`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}