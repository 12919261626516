import {gql, useMutation, useQuery} from '@apollo/client'
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {useTranslation} from "react-i18next";
import {ApolloClientQuery} from "./_apolloClient";

const GET_USER_LEADS_COUNT = gql`
    query getUserLeadsCount($filter: UserLeadsInput, $filterType: String, $filterTypeId: String) {
        getUserLeadsCount(filter: $filter, filterType: $filterType, filterTypeId: $filterTypeId)
    }
`;

const getUserLeadsCount = ({filter}, filterType, filterTypeId) => {
    try {
        const {loading, error, data, refetch} = useQuery(GET_USER_LEADS_COUNT, {
            variables: {
                filter: filter,
                filterType: filterType || null,
                filterTypeId: filterTypeId || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data, refetch};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_USER_LEADS = gql`
    query getUserLeads($filter: UserLeadsInput, $filterType: String, $filterTypeId: String, $limit: Int, $offset: Int, $orderBy: String, $startDate: String, $endDate: String, $dateKey: String) {
        getUserLeads(filter: $filter, filterType: $filterType, filterTypeId: $filterTypeId, limit: $limit, offset: $offset, orderBy: $orderBy, startDate: $startDate, endDate: $endDate, dateKey: $dateKey){
            ... on UserLeadsAry {
                data {
                    _id
                    leadId
                    name
                    countryCode
                    mobileNo
                    completedMobileNo
                    email
                    country
                    leadSource
                    tag
                    comment
                    commentType
                    potential
                    leadStatus
                    salesRep {_id username}
                    owner {_id username}
                    match {_id username}
                    bind {_id username userType}
                    isBind
                    isArchived
                    isDeleted
                    latestFollowUp {followUpDateTime lastFollowUp}
                    previousFollowUp {followUpDateTime}
                    assignedDatetime
                    occupationType
                    purposeOfTransaction
                    nameOfEmployer
                    natureOfBusiness
                    approximateNetWorth
                    countryOfTaxResidency
                    sourceOfWealth
                    address
                    nationality
                    city
                    postalCode
                    state
                    firstName
                    middleName
                    lastName
                    fullName
                    aliasLastName
                    aliasFullName
                    aliasFirstName
                    aliasMiddleName
                    username
                    gender
                    dateOfBirth
                    age
                    identificationNo
                    identificationType
                    language
                    createdAt
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getUserLeads = ({filter}, filterType?, filterTypeId?, limit?, offset?, orderBy?, startDate?, endDate?, dateKey?) => {
    try {
        const {loading, error, data, refetch} = useQuery(GET_USER_LEADS, {
            variables: {
                filter: {
                    ...filter,
                    leadId: filter?.leadId ? parseInt(filter?.leadId) : null
                },
                filterType: filterType || null,
                filterTypeId: filterTypeId || null,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, data, refetch}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const GET_USER_LEADS_LITE = gql`
    query getUserLeadsLite($filter: UserLeadsInput, $filterType: String, $filterTypeId: String, $limit: Int, $offset: Int, $orderBy: String, $startDate: String, $endDate: String, $dateKey: String) {
        getUserLeadsLite(filter: $filter, filterType: $filterType, filterTypeId: $filterTypeId, limit: $limit, offset: $offset, orderBy: $orderBy, startDate: $startDate, endDate: $endDate, dateKey: $dateKey){
            ... on UserLeadsAry {
                data {
                    _id
                    leadId
                    name
                    countryCode
                    mobileNo
                    completedMobileNo
                    email
                    country
                    leadSource
                    tag
                    commentType
                    comment
                    potential
                    leadStatus
                    salesRepUsername
                    ownerUsername
                    isBind
                    isArchived
                    isDeleted
                    createdAt
                    nextFollowUp
                    lastFollowUp
                    assignedDatetime
                    occupationType
                    purposeOfTransaction
                    nameOfEmployer
                    natureOfBusiness
                    approximateNetWorth
                    countryOfTaxResidency
                    sourceOfWealth
                    address
                    nationality
                    city
                    postalCode
                    state
                    firstName
                    middleName
                    lastName
                    fullName
                    aliasFirstName
                    aliasMiddleName
                    aliasLastName
                    aliasFullName
                    username
                    password
                    gender
                    dateOfBirth
                    age
                    identificationType
                    identificationNo
                    language
                    salesRep {
                        _id
                        username
                    }
                    owner {
                        _id
                        username
                    }
                    match {
                        _id
                        username
                    }
                    bind {
                        _id
                        username
                        userType
                    }
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

const getUserLeadsLite = async ({filter}, filterType?, filterTypeId?, limit?, offset?, orderBy?, startDate?, endDate?, dateKey?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_USER_LEADS_LITE,
            {
                filter: {
                    ...filter,
                    leadId: filter?.leadId ? parseInt(filter?.leadId) : null
                },
                filterType: filterType || null,
                filterTypeId: filterTypeId || null,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {fieldList: null, loading: false, error: err};
    }
}

const CHECK_SIMILARITY_USER_LEADS = gql`
    query checkSimilarityByField($field: [String]!, $similarity: Float!) {
        checkSimilarityByField(field: $field, similarity: $similarity){
            ... on UserLeadsAry {
                data {
                    _id
                    leadId
                    name
                    countryCode
                    mobileNo
                    completedMobileNo
                    email
                    country
                    leadSource
                    tag
                    comment
                    commentType
                    potential
                    leadStatus
                    salesRep {_id username}
                    owner {_id username}
                    match {_id username}
                    bind {_id username}
                    isBind
                    isArchived
                    isDeleted
                    latestFollowUp {followUpDateTime lastFollowUp}
                    previousFollowUp {followUpDateTime}
                    assignedDatetime
                    occupationType
                    purposeOfTransaction
                    nameOfEmployer
                    natureOfBusiness
                    approximateNetWorth
                    countryOfTaxResidency
                    sourceOfWealth
                    address
                    nationality
                    city
                    postalCode
                    state
                    firstName
                    middleName
                    lastName
                    fullName
                    aliasLastName
                    aliasFullName
                    aliasFirstName
                    aliasMiddleName
                    username
                    gender
                    dateOfBirth
                    age
                    identificationNo
                    identificationType
                    language
                    createdAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const checkSimilarityUserLeads = (field, similarity) => {
    try {
        const {loading, error, data} = useQuery(CHECK_SIMILARITY_USER_LEADS, {
            variables: {
                field, similarity
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, data}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const GET_USER_LEADS_BY_ID = gql`
    query getUserLeadsById($id: String) {
        getUserLeadsById(id: $id){
            ... on UserLeads {
                _id
                leadId
                name
                countryCode
                mobileNo
                completedMobileNo
                email
                country
                leadSource
                tag
                comment
                commentType
                potential
                leadStatus
                salesRep {_id username}
                owner {_id username}
                match {_id username}
                bind {_id username userType}
                isBind
                isArchived
                isDeleted
                latestFollowUp {followUpDateTime lastFollowUp}
                previousFollowUp {followUpDateTime}
                assignedDatetime
                occupationType
                purposeOfTransaction
                nameOfEmployer
                natureOfBusiness
                approximateNetWorth
                countryOfTaxResidency
                sourceOfWealth
                address
                nationality
                city
                postalCode
                state
                firstName
                middleName
                lastName
                fullName
                aliasLastName
                aliasFullName
                aliasFirstName
                aliasMiddleName
                username
                gender
                dateOfBirth
                age
                identificationNo
                identificationType
                language
                createdAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getUserLeadsById = (id) => {
    try {
        const {loading, error, data} = useQuery(GET_USER_LEADS_BY_ID, {
            variables: {
                id: id
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, data}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const GET_USER_LEADS_AUTO_BIND = gql`
    query getUserLeadsAutoBind($field: [String]!) {
        getUserLeadsAutoBind(field: $field){
            ... on UserLeadsAry {
                data {
                    _id
                    leadId
                    name
                    countryCode
                    mobileNo
                    completedMobileNo
                    email
                    country
                    leadSource
                    tag
                    comment
                    commentType
                    potential
                    leadStatus
                    salesRep {_id username}
                    owner {_id username}
                    match {
                        _id
                        id
                        firstName
                        middleName
                        lastName
                        fullName
                        aliasFirstName
                        aliasMiddleName
                        aliasLastName
                        aliasFullName
                        username
                        email
                        gender
                        dateOfBirth
                        age
                        countryCode
                        mobileNo
                        completedMobileNo
                        domain
                        userType
                        identificationType
                        identificationNo
                        comment
                        language
                        newsletter
                        address
                        city
                        postalCode
                        state
                        country
                        occupationType
                        purposeOfTransaction
                        nameOfEmployer
                        natureOfBusiness
                        approximateNetWorth
                        countryOfTaxResidency
                        sourceOfWealth
                        affiliateCode
                        companyName
                        profileImage
                        eWallet {_id isFreezed balance}
                        pspUserId
                        status
                        clientAccountType
                        rank { _id name }
                        desk { _id name }
                        referrer { _id fullName }
                        salesRep { _id fullName }
                        role { _id name isDefaultCrmUserPermission }
                        lastDepositDate
                        isLoginEnabled
                        isTestAccount
                        isKycApproved
                        isDeleted
                        deletedReason
                        legalName
                        dateOfIncorporation
                        dbaAliasTradingName
                        incorporationAddress
                        incorporationCity
                        incorporationPostalCode
                        incorporationCountry
                        registeredAddress
                        registeredCity
                        registeredPostalCode
                        registeredCountry
                        businessAddress
                        businessCity
                        businessPostalCode
                        businessCountry
                        representativeType
                        formerName
                        formerDbaAliasTradingName
                        registrationNumber
                        corporateEmail
                        corporateCountryCode
                        corporateMobileNo
                        legalForm
                        isSuspicious
                        verifiedEmail
                        previousFollowUp { followUpDateTime }
                        latestFollowUp { followUpDateTime }
                        emailVerificationCode
                        loginStartDate
                        loginEndDate
                        nationality
                        isActive
                        signature
                        theme
                        createdAt
                        updatedAt
                        companyNumber
                        IncorporationDate
                        BusinessNature
                        PositionInCompany
                        representativeAddress
                        representativeCity
                        representativePostalCode
                        representativeState
                        representativeCountry
                        isTemporaryPasswordEnabled
                        directClient
                        account
                        isDeletedByClient
                    }
                    bind {_id username}
                    isBind
                    isArchived
                    isDeleted
                    latestFollowUp {followUpDateTime}
                    previousFollowUp {followUpDateTime}
                    assignedDatetime
                    occupationType
                    purposeOfTransaction
                    nameOfEmployer
                    natureOfBusiness
                    approximateNetWorth
                    countryOfTaxResidency
                    sourceOfWealth
                    city
                    postalCode
                    state
                    firstName
                    middleName
                    lastName
                    fullName
                    aliasLastName
                    aliasFullName
                    aliasFirstName
                    aliasMiddleName
                    username
                    gender
                    dateOfBirth
                    age
                    identificationNo
                    identificationType
                    language
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getUserLeadsAutoBind = (field) => {
    try {
        const {loading, error, data} = useQuery(GET_USER_LEADS_AUTO_BIND, {
            variables: {
                field: field,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, data}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const GET_UPLOAD_USER_LEADS_CSV = gql`
    query getUploadUserLeadsCsv($filter: UserLeadsUploadInput, $limit: Int, $offset: Int, $orderBy: String) {
        getUploadUserLeadsCsv(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on UserLeadsCsvAry {
                data {
                    _id
                    status
                    fileName
                    filePath
                    createdBy {
                        username
                        _id
                    }
                    remarks
                    createdAt
                    updatedAt
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

const getUploadUserLeadsCsv = async (filter, limit, offset, orderBy) => {
    try {
        const execute = await ApolloClientQuery(
            GET_UPLOAD_USER_LEADS_CSV,
            {
                filter: filter,
                limit: limit,
                offset: offset,
                orderBy: orderBy
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

//MUTATION

const ADD_USER_LEADS = gql`
    mutation addUserLeads($userId:String, $input: UserLeadsInput) {
        addUserLeads(userId: $userId, input: $input){
            ... on UserLeads {
                _id
                leadId
                name
                countryCode
                mobileNo
                completedMobileNo
                email
                country
                leadSource
                tag
                comment
                commentType
                potential
                leadStatus
                salesRep {_id username}
                owner {_id username}
                match {_id username}
                bind {_id username}
                isBind
                isArchived
                isDeleted
                latestFollowUp {followUpDateTime lastFollowUp}
                previousFollowUp {followUpDateTime}
                assignedDatetime
                occupationType
                purposeOfTransaction
                nameOfEmployer
                natureOfBusiness
                approximateNetWorth
                countryOfTaxResidency
                sourceOfWealth
                address
                nationality
                city
                postalCode
                state
                firstName
                middleName
                lastName
                fullName
                aliasLastName
                aliasFullName
                aliasFirstName
                aliasMiddleName
                username
                gender
                dateOfBirth
                age
                identificationNo
                identificationType
                language
                createdAt
            }
            ...on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`

const useAddUserLeadsMutation = (): { addUserLeads: any, data: any, loading: boolean, error: any } => {
    try {
        const [addUserLeadsMutation, {data, loading, error}] = useMutation(ADD_USER_LEADS);

        const addUserLeads = (userId, input) => {
            return addUserLeadsMutation({
                variables: {
                    userId: userId,
                    input: input,
                },
            });
        };

        return {addUserLeads, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {addUserLeads: null, data: null, loading: false, error: err};
    }
}

const UPLOAD_USER_LEADS = gql`
    mutation uploadUserLeads($userId:String, $input: UserLeadsInput) {
        uploadUserLeads(userId: $userId, input: $input){
            ...on UserLeadsArray {
                valid {
                    _id
                    leadId
                    name
                    countryCode
                    mobileNo
                    completedMobileNo
                    email
                    country
                    leadSource
                    tag
                    comment
                    commentType
                    potential
                    leadStatus
                    salesRep {_id username}
                    owner {_id username}
                    match {_id username}
                    bind {_id username}
                    isBind
                    isArchived
                    isDeleted
                    latestFollowUp {followUpDateTime lastFollowUp}
                    previousFollowUp {followUpDateTime}
                    assignedDatetime
                    occupationType
                    purposeOfTransaction
                    nameOfEmployer
                    natureOfBusiness
                    approximateNetWorth
                    countryOfTaxResidency
                    sourceOfWealth
                    address
                    nationality
                    city
                    postalCode
                    state
                    firstName
                    middleName
                    lastName
                    fullName
                    aliasLastName
                    aliasFullName
                    aliasFirstName
                    aliasMiddleName
                    username
                    gender
                    dateOfBirth
                    age
                    identificationNo
                    identificationType
                    language
                    createdAt
                }
                invalid {
                    _id
                    leadId
                    name
                    countryCode
                    mobileNo
                    completedMobileNo
                    email
                    country
                    leadSource
                    tag
                    comment
                    commentType
                    potential
                    leadStatus
                    salesRep {_id username}
                    owner {_id username}
                    match {_id username}
                    bind {_id username}
                    isBind
                    isArchived
                    isDeleted
                    latestFollowUp {followUpDateTime lastFollowUp}
                    previousFollowUp {followUpDateTime}
                    assignedDatetime
                    occupationType
                    purposeOfTransaction
                    nameOfEmployer
                    natureOfBusiness
                    approximateNetWorth
                    countryOfTaxResidency
                    sourceOfWealth
                    address
                    nationality
                    city
                    postalCode
                    state
                    firstName
                    middleName
                    lastName
                    fullName
                    aliasLastName
                    aliasFullName
                    aliasFirstName
                    aliasMiddleName
                    username
                    gender
                    dateOfBirth
                    age
                    identificationNo
                    identificationType
                    language
                    createdAt
                }
            }
            ...on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`

const useUploadUserLeadsMutation = (): { uploadUserLeads: any, data: any, loading: boolean, error: any } => {
    try {
        const [uploadUserLeadsMutation, {data, loading, error}] = useMutation(UPLOAD_USER_LEADS);

        const uploadUserLeads = (userId, leadsFile) => {
            return uploadUserLeadsMutation({
                variables: {
                    userId: userId,
                    input: {
                        leadsFile: leadsFile
                    },
                },
            });
        };

        return {uploadUserLeads, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {uploadUserLeads: null, data: null, loading: false, error: err};
    }
}

const UPLOAD_USER_LEADS_VALIDATION = gql`
    mutation uploadUserLeadsValidation($userId:String, $input: UserLeadsInput) {
        uploadUserLeadsValidation(userId: $userId, input: $input){
            ...on UserLeadsUploadValidation { status }
            ...on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

const useUploadUserLeadsValidationMutation = (): { uploadUserLeadsValidation: any, data: any, loading: boolean, error: any } => {
    try {
        const [uploadUserLeadsValidationMutation, {data, loading, error}] = useMutation(UPLOAD_USER_LEADS_VALIDATION);

        const uploadUserLeadsValidation = (userId, leadsFile) => {
            return uploadUserLeadsValidationMutation({
                variables: {
                    userId: userId,
                    input: {
                        leadsFile: leadsFile
                    },
                },
            });
        };

        return {uploadUserLeadsValidation, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {uploadUserLeadsValidation: null, data: null, loading: false, error: err};
    }
}

const UPDATE_USER_LEADS = gql`
    mutation updateUserLeads($id: String!, $input: UserLeadsInput) {
        updateUserLeads(id: $id, input: $input){
            ... on UserLeads {
                _id
                leadId
                name
                countryCode
                mobileNo
                completedMobileNo
                email
                country
                leadSource
                tag
                comment
                commentType
                potential
                leadStatus
                salesRep {_id username}
                owner {_id username}
                match {_id username}
                bind {_id username}
                isBind
                isArchived
                isDeleted
                latestFollowUp {followUpDateTime lastFollowUp}
                previousFollowUp {followUpDateTime}
                assignedDatetime
                occupationType
                purposeOfTransaction
                nameOfEmployer
                natureOfBusiness
                approximateNetWorth
                countryOfTaxResidency
                sourceOfWealth
                address
                nationality
                city
                postalCode
                state
                firstName
                middleName
                lastName
                fullName
                aliasLastName
                aliasFullName
                aliasFirstName
                aliasMiddleName
                username
                gender
                dateOfBirth
                age
                identificationNo
                identificationType
                language
                createdAt
            }
            ...on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`

const useUpdateUserLeadsMutation = (): { updateUserLeads: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [updateUserLeadsMutation, {data, loading, error}] = useMutation(UPDATE_USER_LEADS);

        const updateUserLeads = (id, input) => {
            return updateUserLeadsMutation({
                variables: {
                    id: id,
                    input: input,
                },
                update(cache, {data: {updateUserLeads}}) {
                    const typename = updateUserLeads?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateUserLeads?.errKey),
                            key: "updateUserLeads",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateUserLeads?.msg),
                            key: "updateUserLeads",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update User Leads Successfully"),
                            key: "updateUserLeads",
                        });
                    }
                },
                refetchQueries: [GET_USER_LEADS_BY_ID]
            });
        };

        return {updateUserLeads, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {updateUserLeads: null, data: null, loading: false, error: err};
    }
}

const BATCH_UPDATE_USER_LEADS = gql`
    mutation batchUpdateUserLeads($ids: [String]!, $input: UserLeadsInput) {
        batchUpdateUserLeads(ids: $ids, input: $input){
            ... on UserLeadsAry {
                data {
                    _id
                    name
                    countryCode
                    mobileNo
                    completedMobileNo
                    email
                    country
                    leadSource
                    tag
                    commentType
                    comment
                    potential
                    leadStatus
                    salesRep {username}
                    owner {username}
                    bind {username}
                    isBind
                    isArchived
                    isDeleted
                    createdAt
                }
            }
            ...on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`

const useBatchUpdateUserLeadsMutation = (): { batchUpdateUserLeads: any, data: any, loading: boolean, error: any } => {
    try {
        const [batchUpdateUserLeadsMutation, {data, loading, error}] = useMutation(BATCH_UPDATE_USER_LEADS);

        const batchUpdateUserLeads = (ids, input) => {
            const validatedInput = {
                country: input?.country || null,
                leadSource: input?.leadSource || null,
                tag: input?.tag || null,
                nationality: input?.nationality || null,
                comment: input?.comment || null,
                potential: input?.potential || null,
                isArchived: input?.isArchived ?? null,
                salesRep: input?.salesRep || null,
                owner: input?.owner || null,
            }

            if (!("salesRep" in input)) delete validatedInput.salesRep
            if (!("owner" in input)) delete validatedInput.owner
            return batchUpdateUserLeadsMutation({
                variables: {
                    ids: ids,
                    input: validatedInput,
                },
            });
        };

        return {batchUpdateUserLeads, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {batchUpdateUserLeads: null, data: null, loading: false, error: err};
    }
}

const BATCH_DELETE_USER_LEADS = gql`
    mutation batchDeleteUserLeads($ids: [String]!){
        batchDeleteUserLeads(ids: $ids){
            ...on UserLeads {
                _id
                isDeleted
            }
            ...on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`

const useBatchDeleteUserLeadsMutation = (): { batchDeleteUserLeads: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [deleteUserMutation, {data, loading, error}] = useMutation(BATCH_DELETE_USER_LEADS);

        const batchDeleteUserLeads = (ids) => {
            return deleteUserMutation({
                variables: {
                    ids: ids,
                },
            });
        };

        return {batchDeleteUserLeads, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {batchDeleteUserLeads: null, data: null, loading: false, error: err};
    }

}

const DELETE_USER_LEADS = gql`
    mutation deleteUserLeads($id: String!){
        deleteUserLeads(id: $id){
            ...on UserLeads {
                _id
                isDeleted
            }
            ...on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`

const useDeleteUserLeadsMutation = (): { deleteUserLeads: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [deleteUserMutation, {data, loading, error}] = useMutation(DELETE_USER_LEADS);

        const deleteUserLeads = (id) => {
            return deleteUserMutation({
                variables: {
                    id: id,
                },
                update(cache, {data: {deleteUserLeads}}) {
                    const typename = deleteUserLeads?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(deleteUserLeads?.errKey),
                            key: "deleteUserLeads",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(deleteUserLeads?.msg),
                            key: "deleteUserLeads",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Delete User Leads Successfully"),
                            key: "deleteUserLeads",
                        });
                    }
                },
            });
        };

        return {deleteUserLeads, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {deleteUserLeads: null, data: null, loading: false, error: err};
    }
}

const BIND_USER_LEADS = gql`
    mutation bindUserLeads($id: String!, $isExisting: Boolean, $userId: String, $input: UserInput){
        bindUserLeads(id: $id, isExisting: $isExisting, userId: $userId, input: $input){
            ...on UserLeads {
                _id
                leadId
                name
                countryCode
                mobileNo
                completedMobileNo
                email
                country
                leadSource
                tag
                comment
                commentType
                potential
                leadStatus
                salesRep {_id username}
                owner {_id username}
                match {_id username}
                bind {_id username}
                isBind
                isArchived
                isDeleted
                latestFollowUp {followUpDateTime}
                previousFollowUp {followUpDateTime}
                assignedDatetime
                occupationType
                purposeOfTransaction
                nameOfEmployer
                natureOfBusiness
                approximateNetWorth
                countryOfTaxResidency
                sourceOfWealth
                city
                postalCode
                state
                firstName
                middleName
                lastName
                fullName
                aliasLastName
                aliasFullName
                aliasFirstName
                aliasMiddleName
                username
                gender
                dateOfBirth
                age
                identificationNo
                identificationType
                language
            }
            ...on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`

const useBindUserLeads = (): { bindUserLeads: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [bindUserLeadsMutation, {data, loading, error}] = useMutation(BIND_USER_LEADS);

        const bindUserLeads = (input) => {
            return bindUserLeadsMutation({
                variables: {
                    id: input.id,
                    isExisting: input?.isExisting,
                    userId: input?.userId,
                    input: input?.input
                },
                update(cache, {data: {bindUserLeads}}) {
                    const typename = bindUserLeads?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(bindUserLeads?.errKey),
                            key: "bindUserLeads",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(bindUserLeads?.msg),
                            key: "bindUserLeads",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Auto Bind User Leads Successfully"),
                            key: "bindUserLeads",
                        });
                    }
                },
                refetchQueries: [GET_USER_LEADS_BY_ID]
            });
        };

        return {bindUserLeads, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {bindUserLeads: null, data: null, loading: false, error: err};
    }

}

const AUTO_BIND_USER_LEADS = gql`
    mutation autoBindUserLeads($leadIds: [String]!, $clientIds: [String]!){
        autoBindUserLeads(leadIds: $leadIds, clientIds: $clientIds){
            ... on UserLeadsAry {
                data {
                    _id
                    leadId
                    name
                    countryCode
                    mobileNo
                    completedMobileNo
                    email
                    country
                    leadSource
                    tag
                    commentType
                    comment
                    potential
                    leadStatus
                    salesRep {username}
                    owner {username}
                    bind {username}
                    match {
                        _id
                        name
                        mobileNo
                        email
                    }
                    isBind
                    isArchived
                    isDeleted
                    createdAt
                    latestFollowUp {
                        followUpDateTime
                    }
                    previousFollowUp {
                        lastFollowUp
                    }
                    assignedDatetime
                }
            }
            ...on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`

const useAutoBindUserLeads = (): { autoBindUserLeads: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [autoBindUserLeadsMutation, {data, loading, error}] = useMutation(AUTO_BIND_USER_LEADS);

        const autoBindUserLeads = (leadIds, clientIds) => {
            return autoBindUserLeadsMutation({
                variables: {
                    leadIds: leadIds,
                    clientIds: clientIds,
                },
                update(cache, {data: {autoBindUserLeads}}) {
                    const typename = autoBindUserLeads?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(autoBindUserLeads?.errKey),
                            key: "autoBindUserLeads",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(autoBindUserLeads?.msg),
                            key: "autoBindUserLeads",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Auto Bind User Leads Successfully"),
                            key: "autoBindUserLeads",
                        });
                    }
                },
                refetchQueries: [GET_USER_LEADS_AUTO_BIND]
            });
        };

        return {autoBindUserLeads, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {autoBindUserLeads: null, data: null, loading: false, error: err};
    }

}


const BATCH_CREATE_FOLLOW_UP = gql`
    mutation batchCreateFollowUp($userId: String, $followings: [String], $input: FollowUpInput) {
        batchCreateFollowUp(userId: $userId, followings: $followings, input: $input){
            ...on FollowUpAry {
                data {
                    _id
                    user {username}
                    followingUser {username}
                    followingLeads {name}
                    followingType
                    type
                    description
                    followUpDateTime
                    lastFollowUp
                    isFollowUp
                }
            }
            ...on BaseError {
                errKey
                errMsg
            }
        }
    }
`

const useBatchCreateFollowUp = (): { batchCreateFollowUp: any, data: any, loading: boolean, error: any } => {
    try {
        const [batchCreateFollowUpMutation, {data, loading, error}] = useMutation(BATCH_CREATE_FOLLOW_UP);

        const batchCreateFollowUp = (userId, followings, input) => {
            return batchCreateFollowUpMutation({
                variables: {
                    userId: userId,
                    followings: followings,
                    input: input
                }
            });
        };

        return {batchCreateFollowUp, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {batchCreateFollowUp: null, data: null, loading: false, error: err};
    }
}

const UNBIND_USER_LEADS = gql`
    mutation unbindUserLeads($ids: [String]!){
        unbindUserLeads(ids: $ids){
            ...on UserLeadsAry {
                data {
                    _id
                    leadId
                    name
                    countryCode
                    mobileNo
                    completedMobileNo
                    email
                    country
                    leadSource
                    tag
                    comment
                    commentType
                    potential
                    leadStatus
                    salesRep {_id username}
                    owner {_id username}
                    match {_id username}
                    bind {_id username}
                    isBind
                    isArchived
                    isDeleted
                    latestFollowUp {followUpDateTime}
                    previousFollowUp {followUpDateTime}
                    assignedDatetime
                    occupationType
                    purposeOfTransaction
                    nameOfEmployer
                    natureOfBusiness
                    approximateNetWorth
                    countryOfTaxResidency
                    sourceOfWealth
                    city
                    postalCode
                    state
                    firstName
                    middleName
                    lastName
                    fullName
                    aliasLastName
                    aliasFullName
                    aliasFirstName
                    aliasMiddleName
                    username
                    gender
                    dateOfBirth
                    age
                    identificationNo
                    identificationType
                    language
                }
            }
            ...on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`

const useUnbindUserLeads = (): { unbindUserLeads: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [unbindUserLeadsMutation, {data, loading, error}] = useMutation(UNBIND_USER_LEADS);

        const unbindUserLeads = (ids) => {
            return unbindUserLeadsMutation({
                variables: {
                    ids: ids
                },
                update(cache, {data: {unbindUserLeads}}) {
                    const typename = unbindUserLeads?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(unbindUserLeads?.errKey),
                            key: "unbindUserLeads",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(unbindUserLeads?.msg),
                            key: "unbindUserLeads",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Auto Bind User Leads Successfully"),
                            key: "unbindUserLeads",
                        });
                    }
                },
                refetchQueries: [GET_USER_LEADS_BY_ID]
            });
        };

        return {unbindUserLeads, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {unbindUserLeads: null, data: null, loading: false, error: err};
    }

}

const BATCH_ASSIGN_SALES_REP_LEADS = gql`
    mutation batchAssignSalesRepLeads($input: [UserLeadsBatchAssignInput], $leadIds: [String]) {
        batchAssignSalesRepLeads(input: $input, leadIds: $leadIds) {
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on UserLeadsAry {
                total
                data {
                    _id
                }
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`

const useBatchAssignSalesRepLeads = (): { batchAssignSalesRepLeads: any, data: any, loading: boolean, error: any } => {
    try {
        const [batchAssignSalesRepLeadsMutation, {data, loading, error}] = useMutation(BATCH_ASSIGN_SALES_REP_LEADS);

        const batchAssignSalesRepLeads = (input, leadIds) => {
            return batchAssignSalesRepLeadsMutation({
                variables: {
                    input: input,
                    leadIds: leadIds
                },
            });
        };

        return {batchAssignSalesRepLeads, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {batchAssignSalesRepLeads: null, data: null, loading: false, error: err};
    }

}

export {
    getUserLeadsCount,
    getUserLeads,
    getUserLeadsById,
    getUserLeadsAutoBind,
    getUploadUserLeadsCsv,
    checkSimilarityUserLeads,
    useAddUserLeadsMutation,
    useUploadUserLeadsMutation,
    useUploadUserLeadsValidationMutation,
    useUpdateUserLeadsMutation,
    useBatchUpdateUserLeadsMutation,
    useBatchDeleteUserLeadsMutation,
    useDeleteUserLeadsMutation,
    useBindUserLeads,
    useAutoBindUserLeads,
    useBatchCreateFollowUp,
    useUnbindUserLeads,
    GET_USER_LEADS_BY_ID,
    useBatchAssignSalesRepLeads,
    getUserLeadsLite
}