import React from "react";
import {Modal} from "antd";
import DeskForm from "./deskForm";

const DeskModal = (props) => {
  const { isOpen, closeModal, mode, item } = props;

  const title = mode === "add" ? "Add" : "Edit";

  return (
    <Modal
      title={title + " Desk"}
      open={isOpen}
      onCancel={() => closeModal()}
      footer={null}
      width={500}
      destroyOnClose
    >
      <DeskForm mode={mode} item={item} close={closeModal} />
    </Modal>
  );
};

export default DeskModal;
