import React, {useEffect, useState} from "react";
import {Button, Divider, Form, Input, Row, Select} from "antd";
import {useTranslation} from "react-i18next";
import {getEventSetting, useUpdateEventSettingMutation} from "../../../../../../../api/graphql/eventSetting";
import useAuthorize from "../../../../../../../_common/authorize";

const EventsSetting = (props) => {
    const {setCustomizeSubmitButton, close} = props
    const {t} = useTranslation()

    const {data} = getEventSetting()
    const {updateEventSetting} = useUpdateEventSettingMutation()
    const {systemErrorNotification} = useAuthorize()

    const [input, setInput] = useState<any>()

    const onSubmit = () => {
       try{
           updateEventSetting(input)
           close()
       }catch (e) {
           systemErrorNotification()
       }
    }

    useEffect(() => {
        setInput({
            comments: data?.getEventSetting?.comments,
            createEventPermission: data?.getEventSetting?.createEventPermission
        })
    },[data?.getEventSetting])

    useEffect(() => {
        setCustomizeSubmitButton(
            <Button onClick={onSubmit} type={"primary"}>{t("common.submit")}</Button>
        )
    },[input])

    return (
        <>
            <Divider/>
            <Row style={{padding: 16}}>
                <Form.Item
                    label={t("user.comment")}
                    colon={false}
                    labelCol={{span: 10}}
                    wrapperCol={{span: 14}}
                    style={{width: "100%"}}
                >
                    <Input
                        value={input?.comments}
                        onChange={(e) => setInput({...input, comments: e.target?.value})}
                        placeholder={t("user.comment").toString()}
                    />
                </Form.Item>
                <Form.Item
                    label={t("Who can create event?")}
                    colon={false}
                    labelCol={{span: 10}}
                    wrapperCol={{span: 14}}
                    style={{width: "100%"}}
                    className={"margin-top-0-75"}
                >
                   <Select
                       options={[
                           {label: t("Only Me"), value: "onlyMe"},
                           {label: t("Users but with approval"), value: "userWithApproval"},
                           {label: t("Users (Auto Approval)"), value: "useAutoApprove"},
                       ]}
                       value={input?.createEventPermission}
                       onChange={(value => setInput({...input, createEventPermission: value}))}
                   />
                </Form.Item>
            </Row>
        </>
    )
}

export default EventsSetting