import React, {useEffect} from "react";
import {useLocation} from 'react-router-dom';
import LeadsDetail_1 from "./view/layout1";
import {getUserLeadsById} from "../../../../api/graphql/leads";
import {getProfileInformationSetting} from "../../../../api/graphql/profileInformation";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {selfPermissionObj} from "../../../../../recoil_state";
import {clientProfileInformationSettingState} from "../../_common/state";

function LeadsDetail() {

    let layout = 1;
    let component;

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const setClientProfileInformation = useSetRecoilState(clientProfileInformationSettingState)

    const id = searchParams.get('id');
    const permission = useRecoilValue<any>(selfPermissionObj)
    const {data: profileInformationClient} = getProfileInformationSetting({type: "client"})
    const {data: profileInformationLeads} = getProfileInformationSetting({type: "leads"})
    const filteredLeadsProfileInformation = profileInformationLeads?.getProfileInformationSetting?.data?.filter(d => d?.position !== null)
    const filteredClientProfileInformation = profileInformationClient?.getProfileInformationSetting?.data?.filter(d => d?.position !== null)

    const {data} = getUserLeadsById(id)

    useEffect(() => {
        setClientProfileInformation(filteredClientProfileInformation)
    }, [filteredClientProfileInformation]);

    switch (layout) {
        case 1:
            component = <LeadsDetail_1
                data={data?.getUserLeadsById}
                profileInformation={filteredLeadsProfileInformation}
                permissionUser={permission?.user}
                leadId={id}
            />;
            break;

        default:
            component = <LeadsDetail_1
                data={data?.getUserLeadsById}
                profileInformation={filteredLeadsProfileInformation}
                permissionUser={permission?.user}
                leadId={id}
            />;
            break;
    }

    return component;
}

export default LeadsDetail