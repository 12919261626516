import React, {memo, useState} from "react";
import {Col, Row, Typography} from "antd";

const {Paragraph} = Typography;

const PreviewIphone = (props) => {
    const {title, body, iconPath, imagePath} = props;
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleCollapse = () => setIsCollapsed((prevState) => !prevState);

    let content = (
        <>
            <div style={{padding: "12px 16px"}}>
                <Row align="middle" gutter={10}>
                    <Col span={3}>
                        {iconPath && <img src={iconPath} alt="icon" style={{width: "100%"}}/>}
                    </Col>
                    <Col span={21}>
                        <Row>
                            <Col span={22}>
                                <Paragraph ellipsis={{rows: 1}} className="notisTitle">
                                    {title}
                                </Paragraph>
                            </Col>
                            <Col span={2}>now</Col>
                        </Row>
                        <Row>
                            <Col span={isCollapsed ? 22 : 24}>
                                <Paragraph ellipsis={{rows: isCollapsed && imagePath ? 4 : 8}} className="notisBody">
                                    {body}
                                </Paragraph>
                            </Col>
                            {isCollapsed && imagePath && (
                                <Col span={2}>
                                    <img className="notisImage" src={imagePath} alt="notisImage"/>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            </div>
            {!isCollapsed && imagePath && (
                <div>
                    <img className="notisImage" src={imagePath} alt="notisImage"/>
                </div>
            )}
        </>
    );

    return (
        <div className="previewIphoneContainer">
            <div className={`notisContainer ${isCollapsed ? "collapsedView" : "expandView"}`}>
                <div className="notisContentContainer" onClick={toggleCollapse}>
                    {content}
                </div>
            </div>
        </div>
    );
};

export default memo(PreviewIphone);
