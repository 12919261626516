import React, {useEffect, useState} from "react";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {userAccountVerificationRecordsState, userVerificationModalPropState, userVerificationUploadFileListState} from "../../../../../../state";
import {Button, Col, Form, message, Modal, Progress, Row, Space, Spin} from "antd";
import {useAccountVerificationFunction} from "../../../../../function/accountVerificationFunction";
import {useTranslation} from "react-i18next";
import {useClientDetailFunction} from "../../../../../function";
import {notificationMessage} from "../../../../../../../../../../recoil_state";
import {getUserVerificationAvailableField} from "../../../../../../../../../api/graphql/userVerification";
import SelectVerificationCountry from "./component/selectVerificationCountry";
import SelectVerificationType from "./component/selectVerificationType";
import SelectDocumentGroup from "./component/selectDocumentGroup";
import SelectDocument from "./component/selectDocument";
import VerificationDocumentFieldList from "./component/verificationDocumentFieldList";
import VerificationComplete from "./component/verificationComplete";
import {useIsMobile} from "../../../../../../../../../_common/layout";

const AccountVerificationModal = (props) => {
    const {user, selectedUserType} = props
    const {t} = useTranslation()
    const [form] = Form.useForm();
    const isMobile = useIsMobile(768);

    const [isOpen, setIsOpen] = useRecoilState(userVerificationModalPropState)
    const userVerificationRecord = useRecoilValue<any>(userAccountVerificationRecordsState)
    const {getLastPartOfUrl, isAbleToSubmit, constructSubmitInput, getCleanedFieldTree} = useAccountVerificationFunction()
    const {
        handleCreateNewDocumentRecord,
        handleCompleteVerification,
        handleCreateUserVerificationRecord,
        handleCancelUserVerificationRecord,
        handleSaveUserVerificationRecord,
        fetchVerificationRecord
    } = useClientDetailFunction();

    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const setKycUploadFileList = useSetRecoilState<any>(userVerificationUploadFileListState);

    const [isCancelVerificationModalOpen, setIsCancelVerificationModalOpen] = useState<any>(false);
    const [isCreateVerificationRecordModalOpen, setIsCreateVerificationRecordModalOpen] = useState<any>(false);
    const [isReadyGoNext, setIsReadyGoNext] = useState<any>(false);

    const [selectedCountry, setSelectedCountry] = useState<any>();
    const [selectedVerificationType, setSelectedVerificationType] = useState<any>();
    const [selectedDocumentGroup, setSelectedDocumentGroup] = useState<any>();
    const [selectedDocument, setSelectedDocument] = useState<any>();
    const [fieldTree, setFieldTree] = useState<any>();
    const [isLoadingCreateDocumentRecord, setIsLoadingCreateDocumentRecord] = useState(false);

    const [isFieldTreeLoading, setIsFieldTreeLoading] = useState(false);

    const [isCreatingVerificationRecord, setIsCreatingVerificationRecord] = useState(false);
    const [isCancelingVerificationRecord, setIsCancelingVerificationRecord] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [isCompletingDocumentRecord, setIsCompletingDocumentRecord] = useState(false);

    //STEP
    const isVerificationCompleteKyc = user?.status !== "verification expired" && (user?.isKycApproved || ["verified", "pending verification"].includes(user?.status));
    const isVerificationCompleteKyb = user?.status !== "verification expired" && user?.isKycApproved && (["pending verification"].includes(user?.status) || user?.clientAccountType === "corporate");
    const isVerificationComplete = selectedUserType === "kyc" ? isVerificationCompleteKyc : selectedUserType === "kyb" ? isVerificationCompleteKyb : false;

    const [currentStep, setCurrentStep] = useState(isVerificationComplete ? 5 : -1);
    const verificationSteps = [
        {title: t("accountVerification.selectCountry"), content: "null"},
        {title: t("accountVerification.selectVerificationType"), content: "null"},
        {title: t("accountVerification.fillInDocuments"), content: "null"},
        {
            title:
                user?.status === "verified" || (selectedUserType === "kyc" && user?.isKycApproved)
                    ? t("accountVerification.approved")
                    : user?.status === "pending verification"
                    ? t("accountVerification.submitted")
                    : t("accountVerification.success"),
            content: "null",
            disabled: !isVerificationComplete,
        },
    ];

    const isVerificationPending =
        (selectedUserType === "kyc" && user?.status === "pending verification" && !user?.isKycApproved) ||
        (selectedUserType === "kyb" && user?.status === "pending verification" && user?.isKycApproved);

    const verificationRecord = userVerificationRecord?.find((d) => d?.clientType === selectedUserType && !["cancelled", "verification expired", "verification failed"].includes(d?.status));

    const documentRecord = verificationRecord?.recordDocument?.find(
        (d) =>
            !["cancelled"].includes(d?.status) &&
            d?.group?._id === selectedDocumentGroup?.group?._id &&
            d?.document?._id === selectedDocument?.document?._id
    )

    const preSelectedCountry = verificationRecord?.countryName;

    const isDocumentDraft = documentRecord?.status === "draft verification";
    const clientTypes = fieldTree?.clientType || [];
    const verificationTypes = clientTypes?.find((c) => c.clientType === selectedUserType)?.steps || [];
    const documentGroups = verificationTypes?.find((v) => v?._id === selectedVerificationType?._id)?.documentGroup || [];
    const documents = documentGroups?.find((g) => g?._id === selectedDocumentGroup?._id)?.documents || [];
    const fieldLists = documents?.find((d) => d?._id === selectedDocument?._id)?.fieldList || [];

    const isNextAvailable = () => {
        switch (currentStep) {
            case 0: {
                return !!selectedCountry;
            }
            case 1: {
                return !!selectedVerificationType;
            }
            case 2: {
                return !!selectedDocumentGroup;
            }
            case 3: {
                return !!selectedDocument;
            }
            case 4: {
                return documentRecord?.status === "draft verification";
            }
        }
        return true;
    };
    const showNextButton = () => {
        if (currentStep === 0) {
            return true;
        }
        if (currentStep === 1) {
            if (!verificationRecord?._id) {
                return false;
            }

            return true;
        }

        if (currentStep < 4) {
            return true;
        }

        if (documentRecord?.status === "draft verification" && !isReadyToComplete) {
            return true;
        }
        return false;
    };
    const goNext = async () => {
        if (isLoadingCreateDocumentRecord) {
            return;
        }
        let stepJump = 1;

        let _currentStep = currentStep;
        if (isNextAvailable() || documentRecord?.status === "draft verification") {
            let _verificationTypes;
            let _documentGroups;
            let _documents;
            if (_currentStep === 0) {
                const target = clientTypes?.find((c) => c.clientType === selectedUserType);
                if (!verificationRecord) {
                    setIsCreateVerificationRecordModalOpen(true);
                    return;
                }

                _verificationTypes = target?.steps;
                if (target?.steps.length === 1) {
                    setSelectedVerificationType(target?.steps[0]);
                    stepJump += 1;
                    _currentStep += 1;
                }
            }

            if (_currentStep === 1) {
                const target = (_verificationTypes || verificationTypes)?.find(
                    (c) => c._id === (selectedVerificationType || _verificationTypes?.[0])?._id
                );
                _documentGroups = target?.documentGroup;
                if (target?.documentGroup?.length === 1) {
                    setSelectedDocumentGroup(target?.documentGroup[0]);
                    stepJump += 1;
                    _currentStep += 1;
                }
            }
            if (_currentStep === 2) {
                const target = (documentGroups || _documentGroups)?.find((c) => c._id === (selectedDocumentGroup || _documentGroups?.[0])?._id);
                _documents = target?.documents;
                if (target?.documents.length === 1) {
                    setSelectedDocument(target?.documents[0]);
                    stepJump += 1;
                    _currentStep += 1;
                }
            }

            if (_currentStep === 3) {
                // Check if current document is draft already
                const doc = selectedDocument || _documents?.[0];
                const documentRecord = [...(verificationRecord?.recordDocument || [])]
                    .filter(
                        (d) =>
                            d?.status !== "cancelled" &&
                            d?.group?._id === (selectedDocumentGroup || _documentGroups?.[0])?.group?._id &&
                            d?.document?._id === doc?.document?._id
                    )
                    .sort((a, b) => new Date(b.clientStartTime).getTime() - new Date(a.clientStartTime).getTime())?.[0];

                const skipCreateDocumentRecord = ["verified", "draft verification", "pending verification"].includes(documentRecord?.status);


                if (!skipCreateDocumentRecord) {
                    form.resetFields();
                    setIsLoadingCreateDocumentRecord(true);

                    await createNewDocumentRecord(
                        {
                            document: doc?.document?._id,
                            groupSetting: (selectedDocumentGroup || _documentGroups?.[0])?._id,
                        },
                        doc
                    );
                    setIsLoadingCreateDocumentRecord(false);
                }
            }

            if (_currentStep === 4) {
                if (!isVerificationComplete) {
                    if (documentRecord?.status === "draft verification") {
                        stepJump = 1 - currentStep;
                        setSelectedDocument(null);
                        setSelectedDocumentGroup(null);
                        setSelectedVerificationType(null);
                    } else {
                        return;
                    }
                }
            }

            setCurrentStep((p) => p + stepJump);
        }
    };

    const goBack = (options: any = {}) => {
        let stepJump = 1;
        let _currentStep = currentStep;
        if (isVerificationComplete) {
            if (_currentStep === 5) {
                stepJump += 2;
            }
        } else {
            if (_currentStep === 4) {
                if (documents.length === 1) {
                    stepJump += 1;
                    _currentStep -= 1;
                }
                form.resetFields();
                setSelectedDocument(null);
            }
            if (_currentStep === 3) {
                if (documentGroups.length === 1) {
                    stepJump += 1;
                    _currentStep -= 1;
                }
                setSelectedDocument(null);
            }
            if (_currentStep === 2) {
                setSelectedDocumentGroup(null);
            }
            if (_currentStep === 1) {
                if (options?.skipVerificationRecordChecking) {
                    setSelectedVerificationType(null);
                    setIsCancelVerificationModalOpen(false);
                } else if (verificationRecord?._id) {
                    setIsCancelVerificationModalOpen(true);
                    return;
                } else {
                    setSelectedVerificationType(null);
                    setIsCancelVerificationModalOpen(false);
                }
            }
        }

        setCurrentStep((p) => Math.max(p - stepJump, 0));
    };
    const goToStep = (step) => {
        let _step = step;
        if (_step === 3 && isVerificationComplete) {
            _step = 5;
        }
        setCurrentStep(Math.min(Math.max(_step, 0), 5));
    };

    const requiredDocumentIds = selectedUserType
        ? fieldTree?.clientType?.reduce((a, b) => b?.steps?.length
            ? [
                ...a,
                ...b.steps.reduce(
                    (c, d) =>
                        d?.documentGroup?.length
                            ? [
                                ...c,
                                ...d.documentGroup.reduce(
                                    (e, f) =>
                                        f?.documents?.length
                                            ? [...e, ...f.documents.reduce((g, h) => (h?.isRequired ? [...g, h.document?._id] : g), [])]
                                            : e,
                                    []
                                ),
                            ]
                            : c,
                    []
                ),
            ]
            : a,
            []
        )
        : null;
    const totalDocumentRequired = requiredDocumentIds?.length || 0;
    const totalRequiredDocumentCompleted = (requiredDocumentIds || []).reduce(
        (count, documentId) =>
            verificationRecord?.recordDocument?.find((r) => r?.document?._id === documentId)?.status === "draft verification" ? count + 1 : count,
        0
    );

    const shouldStepShowError = currentStep !== 0 && !isFieldTreeLoading && (!fieldTree || !verificationTypes?.length);
    const canSubmit = verificationRecord?.status !== "not verified" && isAbleToSubmit(verificationTypes, verificationRecord); // all | any | none | partially
    const isReadyToComplete = !isVerificationComplete && canSubmit;

    const onUploadDraggerChange = (info) => {
        if (info.file.status === "uploading") {
            setIsUploading(true);
        }
        if (info.file.status === "error") {
            message.error(`File upload failed.`);
            setIsUploading(false);
        } else if (info.file.status === "done") {
            message.success(`File uploaded successfully`);
            setIsUploading(false);
        }
        return info.fileList.slice(-1);
    };

    const createNewDocumentRecord = async ({groupSetting, document}, resetDocument) => {
        if (resetDocument) {
            form.resetFields();
            // resetDocumentFields(resetDocument);
        }

        setKycUploadFileList({});

        await handleCreateNewDocumentRecord({
            groupSetting,
            document,
            user: user._id,
            record: verificationRecord?._id,
        });
        await fetchVerificationRecord(user?._id);

        // message.success(`File uploaded successfully`);
    };
    const createNewVerificationRecord = async () => {
        setIsCreatingVerificationRecord(true);
        if (verificationRecord) {
            return;
        }
        const isSuccessful = await handleCreateUserVerificationRecord({
            id: user?._id,
            countryName: selectedCountry,
            clientType: selectedUserType,
        });

        if (isSuccessful) {
            await fetchVerificationRecord(user?._id);
        }
        setIsCreatingVerificationRecord(false);
        setIsReadyGoNext(true);

        return isSuccessful;
    };
    const cancelVerificationRecord = async () => {
        setIsCancelingVerificationRecord(true);

        const isSuccessful = await handleCancelUserVerificationRecord({
            userId: user?._id,
            record: verificationRecord?._id,
        });
        if (isSuccessful) {
            await fetchVerificationRecord(user?._id);
            setSelectedVerificationType(null);

            setIsCancelVerificationModalOpen(false);
            goBack({skipVerificationRecordChecking: true});
        }
        setIsCancelingVerificationRecord(false);
    };
    const handleOnFormFinish = async (formData) => {
        if (!selectedDocument || !documentRecord) {
            return;
        }

        if (isUploading || isCompletingDocumentRecord) {
            return;
        }

        setIsCompletingDocumentRecord(true);

        const field = constructSubmitInput(
            selectedDocument?.fieldList.map((f) => ({
                ...f.field,
                hasExpiredDate: f.hasExpiredDate,
                fieldSetting: f._id,
            })),
            null,
            formData
        );

        const isSuccessful = await handleSaveUserVerificationRecord({
            userId: user?._id,
            documentRecord: documentRecord?._id,
            field,
            record: verificationRecord?._id,
        });

        await fetchVerificationRecord(user?._id);

        setIsCompletingDocumentRecord(false);

        if (isSuccessful) {
            setNotificationMessage({
                status: "success",
                title: "",
                msg: t("accountVerification.saveSuccessfully"),
                key: "accountVerificationNotification",
            });

            setSelectedDocument(null);
            goBack();
        }
    };
    const completeVerification = async () => {
        setIsCompletingDocumentRecord(true);
        const isSuccessfullyComplete = await handleCompleteVerification(user?._id, verificationRecord?._id);
        if (isSuccessfullyComplete) {
            await fetchVerificationRecord(user?._id);
            await goToStep(5);
            setIsOpen(false)
        }
        setIsCompletingDocumentRecord(false);
    };

    const handleSelectCountry = (country) => {
        setSelectedCountry(country);
        setSelectedVerificationType(null);
        setSelectedDocumentGroup(null);
        setSelectedDocument(null);
        if (country) {
            setIsFieldTreeLoading(true);
            getUserVerificationAvailableField(country)
                .then((r) => {
                    const _fieldTree = r?.getUserVerificationAvailableField?.data;
                    const cleanedFieldTree = getCleanedFieldTree(_fieldTree);
                    setFieldTree(cleanedFieldTree[0]);
                    setCurrentStep(isVerificationComplete ? 5 : preSelectedCountry ? 1 : 0);
                })
                .catch(console.warn)
                .finally(() => {
                    setIsFieldTreeLoading(false);
                });
        } else {
            setFieldTree(null);
        }
    };
    const handleSelectVerificationType = async (data, steps) => {
        setSelectedVerificationType(data);
        // if (data) {
        //   await fetchVerificationRecord({
        //     clientType: data?.clientType,
        //     countryName: selectedCountry,
        //     user: globalUserDetail._id,
        //   });
        // }

        setSelectedDocumentGroup(null);
        setSelectedDocument(null);
    };
    const handleSelectDocumentGroup = (index, groups) => {
        setSelectedDocumentGroup(index);
        setSelectedDocument(null);
    };
    const handleSelectDocument = (index) => {
        setSelectedDocument(index);
    };
    const handleFormSave = async () => {
        if (isLoadingCreateDocumentRecord) {
            return;
        }
        try {
            await form.validateFields();
            form.submit();
        } catch (error: any) {
            const errorNames = error?.errorFields?.reduce((a, b) => [...a, ...(b.name?.map(t) || [])], []);

            setNotificationMessage({
                status: "error",
                title: "",
                msg: t("admin.err.invalid_field", {field: errorNames.join(", ")}),
                key: "accountVerificationNotification",
            });
        }
    };

    const _stepItems = verificationSteps.map((item, i) => ({
        key: item?.title,
        title: item?.title,
        disabled: i === 0 || isVerificationComplete || item?.disabled || (currentStep < i && !isVerificationComplete),
    }));

    useEffect(() => {
        if (isVerificationComplete) {
            setCurrentStep(5);
        }
    }, [isVerificationComplete]);

    useEffect(() => {
        if (isReadyGoNext) {
            setIsReadyGoNext(false);
            goNext();
        }
    }, [isReadyGoNext]);

    useEffect(() => {
        if (userVerificationRecord) {
            if (preSelectedCountry && !fieldTree) {
                handleSelectCountry(preSelectedCountry);
            } else {
                if (isVerificationComplete) {
                    setCurrentStep(5);
                } else {
                    setCurrentStep(0);
                }
            }
        }
    }, [userVerificationRecord?.length]);

    if (!selectedUserType) {
        return null;
    }

    return (
        <Modal
            title={selectedUserType === "kyc" ? t("accountVerification.accountVerification") : t("user.upgradeToCorporate")}
            open={isOpen}
            destroyOnClose
            maskClosable={false}
            onCancel={() => setIsOpen(false)}
            getContainer="#clientDetail"
            className={"account-verification-modal"}
            width={isMobile ? "95%" : "70%"}
            footer={null}
        >
            <div id={"accountVerify"} className={"margin-top-0-75"}>
                <Row justify={"center"} style={{marginBottom: "60px"}}>
                    <div style={{width: "100%", maxWidth: "820px"}}>
                        <Space style={{width: "100%"}} size={24} direction="vertical">
                            <Row gutter={32}>
                                {
                                    _stepItems.map((d, i) => (
                                        <Col xs={12} md={6}
                                             className={!d?.disabled ? "clickable" : ""}
                                             onClick={(i) => goToStep(i)}
                                        >
                                            <p className={"text-align-center progress-title margin-bottom-0"}>{d?.title}</p>
                                            <Progress
                                                strokeColor={"#8ca1f5"}
                                                percent={currentStep >= i ? 100 : 0}
                                                showInfo={false}
                                            />
                                        </Col>
                                    ))
                                }
                            </Row>
                            {
                                currentStep === -1 ? (
                                    <div style={{height: "400px"}}>
                                        <Spin spinning={true}/>
                                    </div>
                                ) : currentStep === 0 ? (
                                    <SelectVerificationCountry
                                        verificationTypes={verificationTypes}
                                        preSelectedCountry={preSelectedCountry}
                                        selectedCountry={selectedCountry}
                                        handleSelectCountry={handleSelectCountry}
                                        createNewVerificationRecord={() => createNewVerificationRecord()}
                                        isCreatingVerificationRecord={isCreatingVerificationRecord}
                                        isCreateVerificationRecordModalOpen={isCreateVerificationRecordModalOpen}
                                        setIsCreateVerificationRecordModalOpen={setIsCreateVerificationRecordModalOpen}
                                    />
                                ) : isFieldTreeLoading ? (
                                    <div style={{minHeight: "400px"}}>
                                        <Spin spinning={true}/>
                                    </div>
                                ) : currentStep === 1 ? (
                                    <SelectVerificationType
                                        selectedCountry={selectedCountry}
                                        steps={verificationTypes}
                                        selectedStep={selectedVerificationType}
                                        verificationRecord={verificationRecord}
                                        handleSelectVerificationType={handleSelectVerificationType}
                                        isCancelVerificationModalOpen={isCancelVerificationModalOpen}
                                        handleCloseCancelVerification={() => setIsCancelVerificationModalOpen(false)}
                                        handleConfirmCancelVerification={() => {
                                            cancelVerificationRecord();
                                            setSelectedVerificationType(null);
                                            setIsCreateVerificationRecordModalOpen(false);
                                        }}
                                        isCancelingVerificationRecord={isCancelingVerificationRecord}
                                    />
                                ) : currentStep === 2 ? (
                                    <SelectDocumentGroup
                                        requiredType={selectedVerificationType?.requiredType}
                                        numberOfRequired={selectedVerificationType?.numberOfRequired}
                                        partiallyRequiredGroup={selectedVerificationType?.partiallyRequiredGroup}
                                        selectedCountry={selectedCountry}
                                        documentGroups={documentGroups}
                                        selectedVerificationType={selectedVerificationType}
                                        handleSelectDocumentGroup={handleSelectDocumentGroup}
                                        selectedDocumentGroup={selectedDocumentGroup}
                                        verificationRecord={verificationRecord}
                                    />
                                ) : currentStep === 3 ? (
                                    <SelectDocument
                                        requiredType={selectedVerificationType?.requiredType}
                                        numberOfRequired={selectedVerificationType?.numberOfRequired}
                                        partiallyRequiredGroup={selectedVerificationType?.partiallyRequiredGroup}
                                        selectedCountry={selectedCountry}
                                        documents={documents}
                                        verificationRecord={verificationRecord}
                                        handleSelectDocument={handleSelectDocument}
                                        selectedDocument={selectedDocument}
                                        selectedDocumentGroup={selectedDocumentGroup}
                                        selectedVerificationType={selectedVerificationType}
                                    />
                                ) : currentStep === 4 ? (
                                    <VerificationDocumentFieldList
                                        selectedCountry={selectedCountry}
                                        form={form}
                                        fieldLists={fieldLists}
                                        documentRecord={documentRecord}
                                        verificationRecord={verificationRecord}
                                        isDocumentDraft={isDocumentDraft}
                                        selectedDocument={selectedDocument}
                                        selectedDocumentGroup={selectedDocumentGroup}
                                        selectedVerificationType={selectedVerificationType}
                                        handleOnFormFinish={handleOnFormFinish}
                                        onUploadDraggerChange={onUploadDraggerChange}
                                        createNewDocumentRecord={createNewDocumentRecord}
                                    />
                                ) : currentStep === 5 ? (
                                    <VerificationComplete/>
                                ) : (
                                    <></>
                                )}
                            {currentStep >= 0 ? (
                                <Row justify={"end"}>
                                    <Space className="verification-button-section">
                                        {currentStep > 0 && !isVerificationComplete && !isLoadingCreateDocumentRecord ? (
                                            <Button className="secondary-button" onClick={() => goBack()} disabled={isLoadingCreateDocumentRecord}>
                                                {t("common.back")}
                                            </Button>
                                        ) : null}
                                        {currentStep === 4 &&
                                        !isDocumentDraft &&
                                        !isVerificationComplete &&
                                        !isLoadingCreateDocumentRecord &&
                                        !isUploading ? (
                                            <Button
                                                className="primary-button"
                                                htmlType="submit"
                                                onClick={() => {
                                                    handleFormSave();
                                                }}
                                                // disabled={isLoadingCreateDocumentRecord || isUploading}
                                                loading={isUploading || isCompletingDocumentRecord}
                                            >
                                                {isUploading ? t("common.uploading") : t("common.save")}
                                            </Button>
                                        ) : showNextButton() && isNextAvailable() && !isVerificationComplete ? (
                                            <Button
                                                type={"primary"}
                                                className="primary-button"
                                                onClick={() => goNext()}
                                                disabled={
                                                    !isNextAvailable() || isLoadingCreateDocumentRecord
                                                }
                                                loading={isLoadingCreateDocumentRecord}
                                            >
                                                {t("common.next")}
                                            </Button>
                                        ) : null}
                                        {((currentStep >= 1 && currentStep <= 3) ||
                                            (currentStep === 4 && documentRecord?.status === "draft verification")) &&
                                        isReadyToComplete ? (
                                            <Button
                                                disabled={isCompletingDocumentRecord}
                                                loading={isCompletingDocumentRecord}
                                                className="primary-button"
                                                onClick={() => {
                                                    completeVerification();
                                                }}
                                            >
                                                {t("common.complete")}
                                            </Button>
                                        ) : null}
                                    </Space>
                                </Row>
                            ) : null}
                        </Space>
                    </div>
                </Row>
            </div>
        </Modal>
    )
}

export default AccountVerificationModal