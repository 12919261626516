import React, {useEffect, useState} from "react";
import {Button, Card, Form, List, Popconfirm, Row, Space, Tag, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {useGeneralListFunction} from "../../../../function/generalListFunction";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {currentTabState, generalListFieldState, tabBarExtraContentState, userVerificationConfigListState} from "../../../../state";
import GeneralListFieldForm from "./modalForm/generalListFieldForm";
import {switchFieldTypeTagColor} from "../../../../function";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {getIsMainMasterAdmin} from "../../../../../../../api/graphql/_common";
import ImportDBTableButton from "../importDBTable";
import ExportDBTableButton from "../exportDBTable";

const GeneralListField = ({permission}) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const {getGeneralListField, deleteGeneralListField, createGeneralListField, updateGeneralListField} = useGeneralListFunction();
    const field = useRecoilValue<any>(generalListFieldState);
    const config = useRecoilValue<any>(userVerificationConfigListState);

    const [isOpen, setIsOpen] = useState(false);
    const [isAdd, setIsAdd] = useState<any>();
    const [isView, setIsView] = useState<any>(false);
    const [editingItem, setEditingItem] = useState<any>();
    const [submitLoading, setSubmitLoading] = useState(false);
    const setTabBarExtraContent = useSetRecoilState<any>(tabBarExtraContentState);
    const currentTab = useRecoilValue(currentTabState);
    const [isMainMasterAdmin, setIsMainMasterAdmin] = useState(false);

    const checkIsMasterAdmin = async () => {
        let response = await getIsMainMasterAdmin();
        setIsMainMasterAdmin(response?.isMainMasterAdmin);
    };

    useEffect(() => {
        checkIsMasterAdmin();
    }, []);

    useEffect(() => {
        if (currentTab === "field") {
            getGeneralListField({}, true);

            setTabBarExtraContent(
                permission?.add?.add && (
                    <>
                        <Tooltip title={t("accountVerification.addField")}>
                            <Button
                                onClick={() => {
                                    form.resetFields();
                                    setEditingItem(null);
                                    setIsAdd(true);
                                    setIsOpen(true);
                                    setIsView(false);
                                }}
                            >
                                {t("common.add")}
                            </Button>
                        </Tooltip>
                        {isMainMasterAdmin && <ImportDBTableButton />}
                        {isMainMasterAdmin && <ExportDBTableButton />}
                    </>
                )
            );
        }
    }, [currentTab, isMainMasterAdmin, permission?.add?.add]);

    const submitField = async (value) => {
        setSubmitLoading(true);

        const fieldValue = (type) => config?.nationalityList?.map((d) => d?.[type]);

        const formattedValue = await {
            ...value,
            fieldValue:
                value?.fieldType === "nationality" || value?.fieldType === "country"
                    ? fieldValue(value?.fieldType)
                    : value?.fieldValue?.split(",").map((item) => item.trim().replace(/[^\w\s]/g, "")),
            fieldType: value?.fieldType === "nationality" || value?.fieldType === "country" ? "select" : value?.fieldType,
        };

        if (isAdd) {
            await createGeneralListField(formattedValue, true);
        } else {
            await updateGeneralListField(editingItem?._id, formattedValue, true);
        }
        setSubmitLoading(false);
        setIsOpen(false);
    };

    return (
        <div id={"general-list-field"} className={"margin-top-0-75"}>
            <GeneralListFieldForm
                isAdd={isAdd}
                isView={isView}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                submitLoading={submitLoading}
                submitField={submitField}
                config={config}
                item={editingItem}
                form={form}
            />
            <List
                grid={{gutter: 16, column: 4}}
                dataSource={field}
                renderItem={(item: any) => {
                    const fieldTypeTagColor = switchFieldTypeTagColor(item?.fieldType);
                    return (
                        <List.Item>
                            <Card size={"small"}>
                                <Row justify={"space-between"}>
                                    <Space>
                                        {/*{item?.isEnabled ? <BsCheck style={{marginTop: ".5rem"}} color={"green"}/> : <BsX style={{marginTop: ".5rem"}} color={"red"}/>}*/}
                                        {t(item?.label)}
                                        {/*<Tag bordered={false} color={item?.isEnabled ? "green" : "red"}>{item?.isEnabled ? t("common.enabled") : t("common.disabled")}</Tag>*/}
                                        <Tag bordered={false} color={fieldTypeTagColor}>
                                            {item?.fieldType}
                                        </Tag>
                                    </Space>
                                    <Space>
                                        <Tooltip title={t(permission?.edit?.edit ? "common.edit" : "common.view")}>
                                            <Button
                                                onClick={() => {
                                                    form.resetFields();
                                                    setEditingItem(item);
                                                    setIsAdd(false);
                                                    setIsOpen(true);
                                                    setIsView(!permission?.edit?.edit);
                                                }}
                                                icon={permission?.edit?.edit ? <EditOutlined /> : <EyeOutlined />}
                                                type={"link"}
                                                size={"small"}
                                            />
                                        </Tooltip>
                                        {permission?.delete?.delete && (
                                            <Tooltip title={t("common.delete")}>
                                                <Popconfirm
                                                    placement="left"
                                                    title={t("accountVerification.popconfirm.title.field.delete")}
                                                    description={t("accountVerification.popconfirm.description.field.delete")}
                                                    onConfirm={async () => {
                                                        await deleteGeneralListField(item?._id, true);
                                                    }}
                                                    okText={t("common.yes")}
                                                    cancelText={t("common.no")}
                                                >
                                                    <Button icon={<DeleteOutlined />} type={"link"} danger size={"small"} />
                                                </Popconfirm>
                                            </Tooltip>
                                        )}
                                    </Space>
                                </Row>
                            </Card>
                        </List.Item>
                    );
                }}
            />
        </div>
    );
};

export default GeneralListField;
