import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Row, Space} from "antd";
import PspSettingTable_1 from "./component/pspSettingTable";
import EditPspModal from "./component/editPspModal";
import PspSettingFormModal from "./component/pspSettingFormModal";
import ButtonAntDesign from "../../../../../components/button/buttonAntDesign";

const PspSetting_1 = (props) => {
    const {
        pspCurrencyList,
        pspSettingList,
        pspSettingFieldList,
        pspSettingPermission,
        pspSettingCurrencyPermission,
        pspSettingDetailPermission,
    } = props;
    const {t} = useTranslation();
    const tableName = "PSP Setting";
    const [isEditPspModalOpen, setIsEditPspModalOpen] = useState(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [addModalpsp, setAddModalPsp] = useState("");

    const toggleEditPspModal = () => {
        setIsEditPspModalOpen((prevState) => !prevState);
    };

    const toggleAddModal = (psp?) => {
        setAddModalPsp(psp);
        setIsAddModalOpen((prevState) => !prevState);
    };

    return (
        <div>
            <Row justify="space-between" align="middle">
                <h1>{t(tableName)}</h1>
                <Space>
                    {pspSettingPermission?.add?.add && (
                        <>
                            <ButtonAntDesign
                                text={t("Add LocalDepo")}
                                onClick={() => toggleAddModal("LocalDepo")}
                            />
                            <ButtonAntDesign
                                text={t("Add BankWire")}
                                onClick={() => toggleAddModal("BankWire")}
                            />
                        </>
                    )}
                    {pspSettingPermission?.edit?.edit && (
                        <ButtonAntDesign
                            text={t("Edit PSP")}
                            onClick={toggleEditPspModal}
                        />
                    )}
                </Space>
            </Row>
            <PspSettingTable_1
                tableName={tableName}
                pspSettingPermission={pspSettingPermission}
                pspSettingCurrencyPermission={pspSettingCurrencyPermission}
                pspSettingDetailPermission={pspSettingDetailPermission}
            />
            {isAddModalOpen ? (
                <PspSettingFormModal
                    formMode="add"
                    formPsp={addModalpsp}
                    isFormModalOpen={isAddModalOpen}
                    toggleFormModal={toggleAddModal}
                    pspCurrencyList={pspCurrencyList}
                    pspSettingList={pspSettingList}
                />
            ) : null}
            <EditPspModal
                pspSettingList={pspSettingList}
                pspSettingFieldList={pspSettingFieldList}
                isModalOpen={isEditPspModalOpen}
                toggleModal={toggleEditPspModal}
                pspSettingPermission={pspSettingPermission}
            />
        </div>
    );
};

export default PspSetting_1;
