import React, {useEffect, useState} from "react";
import {Form, List, Modal} from "antd";
import {useTranslation} from "react-i18next";
import {useFlowBuilderFunction} from "../../../../../function/flowBuilderFunction";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

const SortStepModalForm = (props) => {
    const {isOpen, close, item} = props
    const {t} = useTranslation()
    const [submitLoading, setSubmitLoading] = useState(false)
    const [form] = Form.useForm()
    const [dataSource, setDataSource] = useState(item?.type?.steps)
    const {sortUserVerificationStepsFunction} = useFlowBuilderFunction()

    const onSubmit = async () => {
        setSubmitLoading(true)
        const ids = dataSource?.map(d => d?._id)
        sortUserVerificationStepsFunction(ids, item?.type?.clientType, item?.country, item?.currentCountry)
        setSubmitLoading(false)
        close()
    }

    useEffect(() => {
        setDataSource(item?.type?.steps?.slice()?.sort((a,b) => a?.sortingNumber - b?.sortingNumber))
    },[item])

    useEffect(() => {
        form.resetFields()
    }, [isOpen])

    const onDragEnd = (result) => {
        const items = Array.from(dataSource);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setDataSource(items);
    };

    return (
        <Modal
            title={`${t("accountVerification.sortStep")} - ${t(item?.type?.clientType)}`}
            open={isOpen}
            onCancel={close}
            onOk={onSubmit}
            cancelText={t("common.cancel")}
            okText={t("common.update")}
            okButtonProps={{loading: submitLoading}}
            destroyOnClose={true}
        >
            <List>
                <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                    <Droppable droppableId={"steps"}>
                        {(droppableProvided) => (
                            <div ref={droppableProvided.innerRef}>
                                {dataSource?.map((data, index) => {
                                    return (
                                        <Draggable key={data?._id} draggableId={data?._id}
                                                   index={index} className={"sort-draggable"}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        ...provided.draggableProps.style,
                                                        opacity: snapshot.isDragging ? 0.8 : 1,
                                                        backgroundColor: snapshot.isDragging ? "#f1f1f1" : "#f9f9f9",
                                                        padding: "0 16px",
                                                        marginBottom: 4,
                                                        borderRadius: 8,
                                                    }}
                                                >
                                                    <List.Item >
                                                        <div>{`${index + 1}. ${t(data?.label)} - ${data?.verificationType}`}</div>
                                                    </List.Item>
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })}
                                {droppableProvided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </List>
        </Modal>
    )
}

export default SortStepModalForm