import React, {useEffect, useState} from "react";
import {Button, Card, Form, Space, Tabs} from "antd";
import {useTranslation} from "react-i18next";
import {EditOutlined} from "@ant-design/icons";
import {useUpdateUserMutation} from "../../../../../../../api/graphql/user";
import BasicInformation from "./InformationForm/basicInformation";
import ResidenceInformation from "./InformationForm/residenceInformation";
import BankInformation from "./InformationForm/bankInformation/bankInformation";
import Account from "./InformationForm/account/account";
import TabPane from "antd/es/tabs/TabPane";
import {formatProfileInformation, validateConfirmKeys} from "../../../../../_common/function";
import CorporateInformation from "./InformationForm/corporateInformation";
import useAuthorize from "../../../../../../../_common/authorize";
import AccountVerification from "./InformationForm/accountVerification/accountVerification";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import RepresentativeInformation from "./InformationForm/representativeInformation";
import {userVerificationModalPropState} from "../../../../state";
import {useGetBankInformation, useGetPspSettingField, useGetTradingAccount} from "../../../function/useQueryHooks";
import {formatDateUTC} from "../../../../../../../function/_common";

const InformationCard = (props) => {
    const {data, profileInformation, permissionUser} = props;
    const {t} = useTranslation();

    const [editable, setEditable] = useState<boolean>(false);
    const [currentTab, setCurrentTab] = useState<any>("basic");
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const setUserVerificationModalIsOpenPropState = useSetRecoilState(userVerificationModalPropState);
    const {systemErrorNotification} = useAuthorize();

    const [bankInfo, bankInfoLoading, fetchBankInformation, refetchBankInformation] = useGetBankInformation()
    const [pspField, pspFieldLoading, fetchPspSettingField] = useGetPspSettingField()
    const [account, accountLoading, fetchTradingAccount] = useGetTradingAccount()

    useEffect(() => {
        if (!data?._id) return

        fetchBankInformation({user: data?._id})
        fetchTradingAccount({userid: data?._id})
    }, [data?._id]);

    useEffect(() => {
        fetchPspSettingField({fieldType: "withdrawal"})
    }, []);

    const {updateUser, loading} = useUpdateUserMutation();

    const [basicForm] = Form.useForm();
    const [residenceForm] = Form.useForm();

    const [fields, setFields] = useState<any>({
        basicField: [],
        loginField: [],
        residenceField: [],
        additionalField: [],
        corporateField: [],
    });
    const {basicField, loginField, residenceField, additionalField, corporateField} = fields;

    useEffect(() => {
        formatProfileInformation(fields, setFields, profileInformation);
    }, [profileInformation]);

    const userInfoOnSubmit = async (value) => {
        try {
            delete value["combinedMobileNo"]
            const nonUpdatableFields = ["tag", "leadSource", "age", "affiliateCode"];
            Object.keys(value).forEach((key) => {
                const match = nonUpdatableFields?.find((d) => d === key);
                if (value[key] === undefined || match) {
                    delete value[key];
                }
            });

            const validated = await validateConfirmKeys(value, setNotificationMessage, t);

            if (validated) {
                let validate;
                if (currentTab === "corporate") {
                    value["corporateMobileNo"] = value?.corporateMobileNo?.toString();
                    delete value["countryCode"];
                    validate = ["corporateInformation", "loginInformation"];
                } else if (currentTab === "basic") {
                    value["mobileNo"] = value?.mobileNo?.toString();
                    value["dateOfBirth"] = value["dateOfBirth"] ? formatDateUTC(value["dateOfBirth"]) : null;
                    validate = ["basicInformation", "loginInformation", "additionalInformation"];
                } else if (currentTab === "residence") {
                    validate = ["residentialInformation"];
                }

                const form = currentTab === "basic" ? basicForm : residenceForm;
                const res = await updateUser(new URLSearchParams(location.search).get("id"), {...value, validationType: validate});
                if (res?.data?.updateUser?.errKey === "admin.err.user_exist") {
                    res?.data?.updateUser?.errObj.forEach(item => {
                        form.setFields([
                            {
                                name: item === "mobileNo" ? "combinedMobileNo" : item,
                                errors: [t("errMsg.fieldAlreadyExisted", {field: t(`user.${item}`)})]
                            }
                        ])
                    })
                } else if (res?.data?.updateUser?.__typename === "User") setEditable(false)
            }
        } catch (e) {
            console.log(e)
            systemErrorNotification();
        }
    };

    useEffect(() => {
        if (data?.clientAccountType === "corporate") setCurrentTab("corporate");
    }, [data]);

    useEffect(() => {
        setEditable(false);
    }, [currentTab]);

    return (
        <Card style={{width: "100%"}}>
            <Tabs
                size={"small"}
                tabBarExtraContent={
                    <>
                        {data?.isDeleted ? (
                            <></>
                        ) : editable ? (
                            <Space>
                                <Button onClick={() => setEditable(false)}>{t("common.cancel")}</Button>
                                {currentTab === "basic" || currentTab === "corporate" || currentTab === "representative" ? (
                                    <Button loading={loading} onClick={() => basicForm.submit()} type={"primary"}>
                                        {t("common.submit")}
                                    </Button>
                                ) : currentTab === "residence" ? (
                                    <Button loading={loading} onClick={() => residenceForm.submit()} type={"primary"}>
                                        {t("common.submit")}
                                    </Button>
                                ) : (
                                    <></>
                                    // <Button loading={kycLoading} onClick={() => onKycSubmit()}>{t("common.submit")}</Button>
                                )}
                            </Space>
                        ) : permissionUser?.clientsUser?.edit?.edit &&
                        (currentTab === "basic" || currentTab === "corporate" || currentTab === "representative" || currentTab === "residence") ? (
                            <Button type={"link"} onClick={() => setEditable(true)} icon={<EditOutlined/>}/>
                        ) : currentTab === "kyc" && permissionUser?.clientsUser?.edit?.verification && !(data?.isKycApproved && data?.clientAccountType === "corporate") ? (
                            <Button
                                type={"primary"}
                                onClick={() => {
                                    setUserVerificationModalIsOpenPropState(true);
                                }}
                            >
                                {t(!data?.isKycApproved ? "common.verify" : "user.upgradeToCorporate")}
                            </Button>
                        ) : (
                            <></>
                        )}
                    </>
                }
                onTabClick={(key) => setCurrentTab(key)}
            >
                {data?.clientAccountType === "corporate" ? (
                    <>
                        <TabPane tab={t("clientUser.tab.userInfo")} key={"corporate"}>
                            <Form form={basicForm} onFinish={userInfoOnSubmit}>
                                <CorporateInformation
                                    data={data}
                                    editable={editable}
                                    corporateField={corporateField}
                                    loginField={loginField}
                                    additionalField={additionalField}
                                    isReadSensitive={permissionUser?.clientsUser?.view?.sensitiveInformation}
                                    form={basicForm}
                                />
                            </Form>
                        </TabPane>
                        <TabPane tab={t("clientUser.tab.representativeInfo")} key={"representative"}>
                            <Form onFinish={userInfoOnSubmit} form={basicForm}>
                                <RepresentativeInformation userId={data?._id}/>
                            </Form>
                        </TabPane>
                    </>
                ) : (
                    <TabPane tab={t("clientUser.tab.userInfo")} key={"basic"}>
                        <Form onFinish={userInfoOnSubmit} form={basicForm}>
                            <BasicInformation
                                data={data}
                                editable={editable}
                                basicField={basicField}
                                loginField={loginField}
                                additionalField={additionalField}
                                isReadSensitive={permissionUser?.clientsUser?.view?.sensitiveInformation}
                                form={basicForm}
                                permissionUser={permissionUser}
                            />
                        </Form>
                    </TabPane>
                )}
                <TabPane tab={t("clientUser.tab.bankInfo")} key={"bank"}>
                    {pspField && bankInfo && (
                        <BankInformation data={data} pspField={pspField} bankInfo={bankInfo} loading={pspFieldLoading || bankInfoLoading}
                                         refetchBankInformation={refetchBankInformation}/>
                    )}
                </TabPane>
                <TabPane tab={t("clientUser.tab.accountVerification")} key={"kyc"}>
                    <AccountVerification user={data}/>
                </TabPane>
                {residenceField?.length > 0 && (
                    <TabPane tab={t("clientUser.tab.residenceInfo")} key={"residence"}>
                        <Form onFinish={userInfoOnSubmit} form={residenceForm}>
                            <ResidenceInformation
                                data={data}
                                editable={editable}
                                residenceField={residenceField}
                                isReadSensitive={permissionUser?.clientsUser?.view?.sensitiveInformation}
                            />
                        </Form>
                    </TabPane>
                )}
                <TabPane tab={t("clientUser.tab.tradingAccount", {totalCount: account?.total})} key={"account"}>
                    <Account data={data} account={account?.data} loading={accountLoading} fetchTradingAccount={() => fetchTradingAccount({userid: data?._id})}/>
                </TabPane>
            </Tabs>
        </Card>
    );
};

export default InformationCard;
