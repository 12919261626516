import React, {useEffect} from "react";
import {Col, Form, Input, Row} from "antd";
import {useTranslation} from "react-i18next";
import {Checkbox} from "antd/lib";

const CommonPanelMt5 = ({initialData}) => {
    const {t} = useTranslation()
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(initialData);
    }, [initialData])

    return (
        <>
            <Row gutter={[16, 16]} style={{marginBottom: "1%", marginTop: "2%"}}>
                <Col span={20} offset={2}>
                    <p>
                        Please set up main parameters of symbols for the group.
                    </p>
                </Col>
            </Row>

            <Form form={form}>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={20} offset={2}>
                        <Form.Item
                            name={"path"}
                            label={t("Symbol")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={10} offset={2}>
                        <Form.Item
                            label={t("Enable market depth")}
                        >
                            <Checkbox disabled={true}
                                      defaultChecked={initialData?.permissionsFlags === "PERMISSION_BOOK"}/>
                        </Form.Item>
                    </Col>
                    <Col span={8} offset={2}>
                        <Form.Item
                            name={"bookDepthLimit"}
                            label={t("Market depth limit")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={10} offset={2}>
                        <Form.Item
                            label={t("Use default spread")}
                        >
                            <Checkbox disabled={true}
                                      defaultChecked={initialData?.spreadDiff === initialData?.spreadDiffDefault}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={10} offset={2}>
                        <Form.Item
                            name={"spreadDiff"}
                            label={t("Spread difference")}
                        >
                            <Input readOnly={true} addonAfter="pt"/>
                        </Form.Item>
                    </Col>
                    <Col span={8} offset={2}>
                        <Form.Item
                            name={"spreadDiffBalance"}
                            label={t("Difference balance")}
                        >
                            <Input readOnly={true} addonAfter="bid/ask"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={10} offset={2}>
                        <Form.Item
                            label={t("Use default volumes")}
                        >
                            <Checkbox disabled={true}
                                      defaultChecked={initialData?.volumeMin === initialData?.volumeMinDefault && initialData?.volumeMax === initialData?.volumeMaxDefault && initialData?.volumeStep === initialData?.volumeStepDefault}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={6} offset={2}>
                        <Form.Item
                            name={"volumeMin"}
                            label={t("Minimum")}
                        >
                            <Input readOnly={true} addonAfter="pt"/>
                        </Form.Item>
                    </Col>
                    <Col span={6} offset={1}>
                        <Form.Item
                            name={"volumeStep"}
                            label={t("Step")}
                        >
                            <Input readOnly={true} addonAfter="bid/ask"/>
                        </Form.Item>
                    </Col>
                    <Col span={6} offset={1}>
                        <Form.Item
                            name={"volumeMax"}
                            label={t("Maximum")}
                        >
                            <Input readOnly={true} addonAfter="bid/ask"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={10} offset={2}>
                        <Form.Item
                            label={t("Use default limit")}
                        >
                            <Checkbox disabled={true}
                                      defaultChecked={initialData?.volumeLimit === initialData?.volumeLimitDefault}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={6} offset={2}>
                        <Form.Item
                            name={"volumeLimit"}
                            label={t("Limit")}
                        >
                            <Input readOnly={true} addonAfter="pt"/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default CommonPanelMt5