import {Form, Select} from "antd";
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import FormErrors from "./formErrors";

import {getNetworkByRoleTemplate} from "../../api/graphql/roleTemplate";
import {formatUserOptions} from "../../function/_common";

const NetworkListByRoleTemplateFormItem = (props) => {
    const {
        layout,
        label,
        name,
        externalErrorMessage,
        onChange,
        hint,
        disabled,
        required,
        mode,
        showSearch,
        showArrow,
        allowClear,
        placeholder,
        roleID,
    } = props;

    const {
        register,
        watch,
        setValue,
        formState: {touchedFields, isSubmitted, errors},
    } = useFormContext();

    const {data} = getNetworkByRoleTemplate(roleID);
    const mapData = (data: any) => {
        let temp = data?.getNetworkByRoleTemplate?.map((item) => {
            return formatUserOptions(item);
        });
        return temp;
    };

    const errorMessage = FormErrors.errorMessage(name, label, errors, touchedFields, isSubmitted, externalErrorMessage);

    const formHelperText = errorMessage || hint;

    useEffect(() => {
        register(name);
    }, [register, name]);

    return (
        <Form.Item {...layout} label={label} required={required} validateStatus={errorMessage ? "error" : "success"} help={formHelperText}>
            <Select
                id={name}
                mode={mode}
                options={data && mapData(data)}
                value={watch(name)}
                onChange={(e) => {
                    setValue(name, e, {shouldValidate: true});
                    onChange && onChange(e);
                }}
                placeholder={placeholder}
                showSearch={showSearch}
                showArrow={showArrow}
                allowClear={allowClear}
                disabled={disabled}
                filterOption={(input, option: any) => option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                optionLabelProp="selectedLabel"
            />
        </Form.Item>
    );
};

NetworkListByRoleTemplateFormItem.defaultProps = {
    required: false,
    disabled: false,
};

NetworkListByRoleTemplateFormItem.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    externalErrorMessage: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    layout: PropTypes.object,
    hint: PropTypes.string,
    showSearch: PropTypes.bool,
    showArrow: PropTypes.bool,
    allowClear: PropTypes.bool,
    placeholder: PropTypes.string,
    mode: PropTypes.string,
    roleID: PropTypes.string,
};

export default NetworkListByRoleTemplateFormItem;
