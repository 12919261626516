import React, {useEffect, useState} from "react";
import {Modal, Collapse, Space, Row, Button} from "antd";
import {FcFolder} from "react-icons/fc";
import {updateMediaFolder} from "../../../../../../api/restful/arena/media_file";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../recoil_state";
import {FolderFilled} from "@ant-design/icons";


const MoveFolderModal = ({open, handleClose, folders, fileId, refetchMedia}) => {
    const {Panel} = Collapse
    const [selected, setSelected] = useState<any>(null)
    const setNotificationMessage = useSetRecoilState(notificationMessage)

    useEffect(() => {
        setSelected(null)
    }, [open])

    const buildFolderTree = (folders, parentId = null) => {
        return folders
            ?.filter(folder => folder.parent === parentId)
            ?.map(folder => ({
                ...folder,
                children: buildFolderTree(folders, folder._id),
            }))
    }

    const renderFolderTree = (folders) => {
        if (!folders.length) {
            return <div>No folders available</div>;
        }

        const items = folders.map(folder => {
            if (folder.children.length > 0) {
                return {
                    key: folder._id,
                    label: (
                        <div onClick={() => setSelected(folder)}>{folder.name}</div>
                    ),
                    children: renderFolderTree(folder.children),
                };
            } else {
                return {
                    key: folder._id,
                    label: (
                        <div className="display-flex clickable w-100" onClick={() => setSelected(folder)}>
                            <FolderFilled className={"folder-icon"} style={{ fontSize: 20, marginRight: 6 }} />
                            <p className="margin-bottom-0">{folder.name}</p>
                        </div>
                    ),
                    collapsible: "disabled",
                    showArrow: false
                };
            }
        });

        return <Collapse className="folders-wrapper" ghost items={items} />;
    };

    const folderTree = buildFolderTree(folders)

    const handleMoveFolder = async () => {
        try {
            const response = await updateMediaFolder({_id: fileId, folder: selected._id})

            if (response.status === "Success") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: `Move File Successfully`,
                    key: "arenaCreateFolderNotification",
                })

                refetchMedia()
                handleClose()
            } else {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: `Move File Failed: ${response?.errObj}`,
                    key: "arenaCreateFolderNotification",
                })
            }
        } catch (e) {}
    }

    return (
        <Modal
            className={"folders-modal"}
            open={open}
            onCancel={handleClose}
            title="Folders"
            closeIcon={false}
            width={400}
            footer={
                <Row justify={"space-between"} align={"middle"}>
                    {
                        selected &&
                        <>
                            <p className={"margin-bottom-0"}>Selected: {selected.name}</p>
                            <Button onClick={handleMoveFolder} type={"primary"}>Move</Button>
                        </>
                    }
                </Row>
            }
            getContainer={"#media-library"}
        >
            {renderFolderTree(folderTree)}
        </Modal>
    );
};

export default MoveFolderModal;
