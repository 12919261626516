import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useRecoilState, useSetRecoilState} from "recoil";
import {FormProvider, useForm} from "react-hook-form";
import {Button, Col, Form, Row, Popconfirm} from "antd";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
    useAddTradingAccountTransactionEWalletMutation,
    useAddTradingAccountTransactionManualMutation,
    useAddTradingAccountTransactionMutation,
    useAddTradingAccountTransactionNegativeBalanceProtectionMutation
} from "../../../../../../../api/graphql/tradingAccount";
import yupFormSchema from "../../../../../../../components/yup/yupFormSchema";
import SelectFormItem from "../../../../../../../components/form/selectFormItem";
import {horizontalFormLayout} from "../../../../../../../components/form/formWrapper";
import SwitchFormItem from "../../../../../../../components/form/switchFormItem";
import {notificationMessage, selfPermissionObj,} from "../../../../../../../../recoil_state";
import TextAreaInputFormItem from "../../../../../../../components/form/textAreaInputFormItem";
import InputNumberFormItem from "../../../../../../../components/form/inputNumberFormItem";
import useAuthorize from "../../../../../../../_common/authorize";
import {formatMoney} from "../../../function";
import {useIsMobile} from "../../../../../../../_common/layout";
import {tradingAccountCreditRefreshState, tradingAccountTransactionRefreshState} from "../../../state";

const AccountTransaction = (props) => {
    const {refreshQuery, refreshQueryTransaction,data} = props
    const [btnLoading, setBtnLoading] = useState(false);
    const [errorFreeMargin, setErrorFreeMargin] = useState(false);
    const [maxFreeMarginAmount, setFreeMarginAmount] = useState(0);
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const setTradingAccountTransactionRefreshState = useSetRecoilState(tradingAccountTransactionRefreshState);
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const tradingAccountAccTxnPermission =
        selfPermission?.tradingAccount?.accountTransaction;

    const [transactionType, setTransactionType] = useState("deposit");
    const [depositType, setDepositType] = useState("");
    const {systemErrorNotification} = useAuthorize()
    const {addTradingAccountTransaction} = useAddTradingAccountTransactionMutation({
        filter: {
            account: props?.id,
        },
    });
    const {addTradingAccountTransactionEWallet} = useAddTradingAccountTransactionEWalletMutation({
        filter: {
            account: props?.id,
        },
    });
    const {addTradingAccountTransactionNegativeBalanceProtection} = useAddTradingAccountTransactionNegativeBalanceProtectionMutation({
        filter: {
            account: props?.id,
        },
    });

    const {addTradingAccountTransactionManual} = useAddTradingAccountTransactionManualMutation({
        filter: {
            account: props?.id,
        },
    });

    const yupSchema = yup.object().shape({
        type: yupFormSchema.string("Type", {required: true}),
        amount: yupFormSchema.number("Amount", {required: true}),
        depositType: yupFormSchema.string("Deposit Type", (props?.data?.user?.clientAccountType !== "demo" && !(props?.data?.product?.mode === "demo")) ? {required: true} : {required: false}),
        comment: yupFormSchema.string("Comment", {required: false}),
        sendEmailNotification: yupFormSchema.boolean("sendEmailNotification", {
            required: false,
        }),
    });

    const isMobile = useIsMobile(768);
    const labelCol = {span: isMobile ? 24 :8, offset: 0};

    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
    });

    const {t} = useTranslation();

    const onSubmit = async (value) => {
        try {
            if (props?.data?.user?.clientAccountType !== "demo" && !(props?.data?.product?.mode === "demo")) {
                switch (value?.depositType) {
                    case "ewallet":
                        await onSubmitEWallet(value);
                        break;
                    case "manual":
                        await onSubmitManual(value);
                        break;
                    case "negativeBalanceProtection":
                        await onSubmitNegativeBalanceProtection(value);
                        break;
                }
            } else {
                await onSubmitManual(value);
            }

        } catch (error: any) {
            systemErrorNotification()
            setBtnLoading(false)
        }
    };

    const onSubmitAll = async (value) => {
        try {
            await setBtnLoading(true)
            const response = await addTradingAccountTransaction(
                transactionType === "deposit"
                    ? {
                        user: props?.data?.user?._id,
                        account: props?.id,
                        type: value?.type,
                        amount: value?.amount.toString(),
                        depositType: (props?.data?.user?.clientAccountType !== "demo" && !(props?.data?.product?.mode === "demo")) ? value?.depositType : "manual",
                        comment: value?.comment,
                        sendEmailNotification: value?.sendEmailNotification,
                    }
                    : {
                        user: props?.data?.user?._id,
                        account: props?.id,
                        type: value?.type,
                        amount: value?.amount.toString(),
                        withdrawalType: (props?.data?.user?.clientAccountType !== "demo" && !(props?.data?.product?.mode === "demo")) ? value?.depositType : "manual",
                        comment: value?.comment,
                        sendEmailNotification: value?.sendEmailNotification,
                    }
            );

            if (
                response?.data?.addTradingAccountTransaction.__typename ===
                "tradingAccountTransaction"
            ) {
                form.reset();
                refreshQuery()
                refreshQueryTransaction()
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("tradingAccount.create_transaction_successfully"),
                    key: "addTradingAccountTransaction",
                });
            } else if (
                response?.data?.addTradingAccountTransaction.__typename === "BaseError"
            ) {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.data?.addTradingAccountTransaction?.errKey),
                    key: "addTradingAccountTransaction",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.data?.addTradingAccountTransaction?.msg),
                    key: "addTradingAccountTransaction",
                });
            }

            setBtnLoading(false)
        } catch (error: any) {
            systemErrorNotification()
            setBtnLoading(false)
        }
    };

    const onSubmitEWallet = async (value) => {
        try {
            await setBtnLoading(true)
            const response = await addTradingAccountTransactionEWallet({
                user: props?.data?.user?._id,
                account: props?.id,
                type: value?.type,
                amount: value?.amount.toString(),
                comment: value?.comment,
                sendEmailNotification: value?.sendEmailNotification,
            });

            if (
                response?.data?.addTradingAccountTransactionEWallet.__typename ===
                "tradingAccountTransaction"
            ) {
                form.reset();
                if(data?.mode === "demo"){
                    setTradingAccountTransactionRefreshState(true)
                }
                refreshQuery()
                refreshQueryTransaction()
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("tradingAccount.create_transaction_successfully"),
                    key: "addTradingAccountTransactionEWallet",
                });
            } else if (
                response?.data?.addTradingAccountTransactionEWallet.__typename === "BaseError"
            ) {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.data?.addTradingAccountTransactionEWallet?.errKey),
                    key: "addTradingAccountTransactionEWallet",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.data?.addTradingAccountTransactionEWallet?.msg),
                    key: "addTradingAccountTransactionEWallet",
                });
            }

            setBtnLoading(false)
        } catch (error: any) {
            systemErrorNotification()
            setBtnLoading(false)
        }
    };
    const onSubmitManual = async (value) => {
        try {
            await setBtnLoading(true)
            const response = await addTradingAccountTransactionManual({
                user: props?.data?.user?._id,
                account: props?.id,
                type: value?.type,
                amount: value?.amount.toString(),
                comment: value?.comment,
                sendEmailNotification: value?.sendEmailNotification,
            });

            if (
                response?.data?.addTradingAccountTransactionManual.__typename ===
                "tradingAccountTransaction"
            ) {
                form.reset();
                if(data?.mode === "demo"){
                    setTradingAccountTransactionRefreshState(true)
                }
                refreshQuery()
                refreshQueryTransaction()
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("tradingAccount.create_transaction_successfully"),
                    key: "addTradingAccountTransactionManual",
                });
            } else if (
                response?.data?.addTradingAccountTransactionManual.__typename === "BaseError"
            ) {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.data?.addTradingAccountTransactionManual?.errKey),
                    key: "addTradingAccountTransactionManual",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.data?.addTradingAccountTransactionManual?.msg),
                    key: "addTradingAccountTransactionManual",
                });
            }

            setBtnLoading(false)
        } catch (error: any) {
            systemErrorNotification()
            setBtnLoading(false)
        }
    };
    const onSubmitNegativeBalanceProtection = async (value) => {
        try {
            await setBtnLoading(true)
            const response = await addTradingAccountTransactionNegativeBalanceProtection({
                user: props?.data?.user?._id,
                account: props?.id,
                type: value?.type,
                amount: value?.amount.toString(),
                comment: value?.comment,
                sendEmailNotification: value?.sendEmailNotification,
            });

            if (
                response?.data?.addTradingAccountTransactionNegativeBalanceProtection.__typename ===
                "tradingAccountTransaction"
            ) {
                form.reset();
                if(data?.mode === "demo"){
                    setTradingAccountTransactionRefreshState(true)
                }
                refreshQuery()
                refreshQueryTransaction()
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("tradingAccount.create_transaction_successfully"),
                    key: "addTradingAccountTransactionNegativeBalanceProtection",
                });
            } else if (
                response?.data?.addTradingAccountTransactionNegativeBalanceProtection.__typename === "BaseError"
            ) {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.data?.addTradingAccountTransactionNegativeBalanceProtection?.errKey),
                    key: "addTradingAccountTransactionNegativeBalanceProtection",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.data?.addTradingAccountTransactionNegativeBalanceProtection?.msg),
                    key: "addTradingAccountTransactionNegativeBalanceProtection",
                });
            }

            setBtnLoading(false)
        } catch (error: any) {
            systemErrorNotification()
            setBtnLoading(false)
        }
    };

    const transactionTypeOptions = [
        {
            value: "deposit",
            label: t("tradingAccount.deposit"),
        },
        {
            value: "withdrawal",
            label: t("tradingAccount.withdrawal"),
        },
    ];

    const depositTypeOptions = [
        {
            value: "manual",
            label: t("tradingAccount.manual_deposit"),
        },
        {
            value: "negativeBalanceProtection",
            label: t("tradingAccount.negative_balance_protection"),
        },
        {
            value: "ewallet",
            label: t("tradingAccount.deposit_via_ewallet"),
        },
    ];

    const withdrawalTypeOptions = [
        {
            value: "manual",
            label: t("tradingAccount.manual_withdrawal"),
        },
        {
            value: "ewallet",
            label: t("tradingAccount.withdrawal_to_ewallet"),
        },
    ];

    const isAddable = tradingAccountAccTxnPermission?.add?.add;

    const verifyFreeMargin = (e) => {
        const accData = props.data
        if (accData?.openPosition && e > accData?.freeMarginWithdrawableAmount) {
            setErrorFreeMargin(true)
            setFreeMarginAmount(accData?.freeMarginWithdrawableAmount)
        } else {
            setErrorFreeMargin(false)
            setFreeMarginAmount(0)
        }
    }

    return (
        <div id="AccountTransaction">
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="form-1">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12}>
                            <SelectFormItem
                                name={"type"}
                                label={t("tradingAccount.transaction_type") + ':'}
                                placeholder={t("tradingAccount.transaction_type")}
                                layout={horizontalFormLayout}
                                options={transactionTypeOptions}
                                mode={"single"}
                                labelCol={labelCol}
                                labelAlign={"left"}
                                required
                                onChange={(e) => {
                                    setTransactionType(e);
                                }}
                                value={transactionType}
                                disabled={!isAddable}
                            />
                        </Col>

                        {
                            (props?.data?.user?.clientAccountType !== "demo" && props?.data?.product?.mode === "live") && (
                                <>
                                    {transactionType === "deposit" ? (
                                        <Col xs={24} md={12}>
                                            <SelectFormItem
                                                name={"depositType"}
                                                label={t("tradingAccount.deposit_type") + ':'}
                                                placeholder={t("tradingAccount.deposit_type")}
                                                onChange={(e) => setDepositType(e)}
                                                layout={horizontalFormLayout}
                                                options={depositTypeOptions}
                                                required
                                                mode={"single"}
                                                labelCol={labelCol}
                                                labelAlign={"left"}
                                                value={""}
                                                disabled={!isAddable}
                                            />
                                        </Col>
                                    ) : (
                                        <Col xs={24} md={12}>
                                            <SelectFormItem
                                                name={"depositType"}
                                                label={t("tradingAccount.withdrawal_type") + ':'}
                                                placeholder={t("tradingAccount.withdrawal_type")}
                                                layout={horizontalFormLayout}
                                                onChange={(e) => setDepositType(e)}
                                                options={withdrawalTypeOptions}
                                                required
                                                mode={"single"}
                                                labelCol={labelCol}
                                                labelAlign={"left"}
                                                value={""}
                                                disabled={!isAddable}
                                            />
                                        </Col>
                                    )}
                                </>
                            )
                        }

                        <Col xs={24} md={12}>
                            <InputNumberFormItem
                                name={"amount"}
                                label={t("tradingAccount.amount") + ':'}
                                placeholder={t("tradingAccount.amount")}
                                layout={horizontalFormLayout}
                                required
                                labelCol={labelCol}
                                labelAlign={"left"}
                                style={{width: "100%"}}
                                disabled={!isAddable}
                                min={0.01}
                                step={2}
                                onChange={verifyFreeMargin}
                                externalErrorMessage={transactionType !== "deposit" && errorFreeMargin && !tradingAccountAccTxnPermission?.add?.overwriteFreeMarginWithdrawal ? "Withdrawal limit exceeded, not more than " + formatMoney(maxFreeMarginAmount) : null}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <SwitchFormItem
                                name={"sendEmailNotification"}
                                label={t("tradingAccount.send_email_notification") + ':'}
                                labelCol={{span: 16, offset: 0}}
                                labelAlign={"left"}
                                value={false}
                                disabled={!isAddable}
                                switchAlignRight={true}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <TextAreaInputFormItem
                                name={"comment"}
                                label={t("tradingAccount.comment")}
                                placeholder={t("tradingAccount.comment")}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign={"left"}
                                // maxLength={25}
                                showCount={true}
                                disabled={!isAddable || depositType !== "manual" && (props?.data?.user?.clientAccountType !== "demo" && props?.data?.product?.mode === "live")}
                            />
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                className="text-align-right"
                                style={{paddingTop: "12px"}}
                            >

                                {
                                    transactionType !== "deposit" && errorFreeMargin && tradingAccountAccTxnPermission?.add?.overwriteFreeMarginWithdrawal ? (
                                        <Popconfirm
                                            title="Warning"
                                            description={"Withdrawal limit exceeded, not more than " + formatMoney(maxFreeMarginAmount) +", Are you sure to continue proceed?"}
                                            onConfirm={form.handleSubmit(onSubmit)}
                                        >
                                            <Button
                                                className="button-submit-1"
                                                loading={btnLoading}
                                                disabled={!isAddable || btnLoading || (transactionType !== "deposit" && errorFreeMargin && !tradingAccountAccTxnPermission?.add?.overwriteFreeMarginWithdrawal)}
                                                type={"primary"}
                                            >
                                                {t("common.submit")}
                                            </Button>
                                        </Popconfirm>
                                    ) : (
                                        <Button
                                            onClick={form.handleSubmit(onSubmit)}
                                            className="button-submit-1"
                                            loading={btnLoading}
                                            disabled={!isAddable || btnLoading || (transactionType !== "deposit" && errorFreeMargin && !tradingAccountAccTxnPermission?.add?.overwriteFreeMarginWithdrawal)}
                                            type={"primary"}
                                        >
                                            {t("common.submit")}
                                        </Button>
                                    )
                                }

                            </Form.Item>
                        </Col>
                    </Row>
                </form>
            </FormProvider>
        </div>
    );
};

export default AccountTransaction;
