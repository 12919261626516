import React, {useEffect, useState} from "react";
import {Button, Card, Form, Space, Spin, Tabs} from "antd";
import {useTranslation} from "react-i18next";
import {EditOutlined} from "@ant-design/icons";
import {useUpdateUserMutation} from "../../../../../../../api/graphql/user";
import BasicInformation from "../../../../../../users/clients/detail/view/layout1/component/InformationForm/basicInformation";
import BankInformation from "../../../../../../users/clients/detail/view/layout1/component/InformationForm/bankInformation/bankInformation";
import ResidenceInformation from "../../../../../../users/clients/detail/view/layout1/component/InformationForm/residenceInformation";
import {getProfileInformationSetting} from "../../../../../../../api/graphql/profileInformation";
import {formatProfileInformation} from "../../../../../../users/_common/function";
import TabPane from "antd/es/tabs/TabPane";
import RepresentativeInformation from "../../../../../../users/clients/detail/view/layout1/component/InformationForm/corporateInformation";
import {getLanguage} from "../../../../../../../api/graphql/language";
import {getNewsletter} from "../../../../../../../api/graphql/newsletter";
import countryList from "../../../../../../../../config/countryList";
import useAuthorize from "../../../../../../../_common/authorize";
import AccountVerification from "../../../../../../users/clients/detail/view/layout1/component/InformationForm/accountVerification/accountVerification";
import {useGetBankInformation, useGetPspSettingField} from "../../../../../../users/clients/detail/function/useQueryHooks";

const ClientDetail = (props) => {
    const {data, permissionUser} = props;
    const {t} = useTranslation();
    const [basicForm] = Form.useForm();
    const [residenceForm] = Form.useForm();

    const [editable, setEditable] = useState<boolean>(false);
    const [currentTab, setCurrentTab] = useState<any>("basic");
    const [submitButton, setSubmitButton] = useState<any>();
    const [newsletterOptions, setNewsletterOptions] = useState<any[]>([]);
    const [languageOptions, setLanguageOptions] = useState<any[]>([]);
    const [componentLoading, setComponentLoading] = useState(true);
    const {systemErrorNotification} = useAuthorize()

    const {data: profileInformation} = getProfileInformationSetting({
        type: "client",
    });

    const [bankInfo, bankInfoLoading, fetchBankInformation, refetchBankInformation] = useGetBankInformation()
    const [pspField, pspFieldLoading, fetchPspSettingField] = useGetPspSettingField()

    const {languageData, loading: languageLoading} = getLanguage({
        filter: null,
    });
    const {data: newsletter, loading: newsletterLoading} = getNewsletter({
        filter: {isDeleted: false},
    });
    const {updateUser} = useUpdateUserMutation();

    const [fields, setFields] = useState<any>({
        basicField: [],
        loginField: [],
        residenceField: [],
        additionalField: [],
    });
    const {basicField, loginField, residenceField, additionalField} = fields;

    useEffect(() => {
        if (!data?._id) return

        fetchBankInformation({user: data?._id})
    }, [data?._id]);

    useEffect(() => {
        fetchPspSettingField({fieldType: "withdrawal"})
    }, []);

    useEffect(() => {
        formatProfileInformation(fields, setFields, profileInformation?.getProfileInformationSetting?.data);
    }, [profileInformation]);

    useEffect(() => {
        if (newsletter?.getNewsletter?.data?.length > 0) {
            const options = newsletter?.getNewsletter?.data?.map((d) => {
                return {
                    label: t(d?.name),
                    value: d?._id,
                };
            });
            setNewsletterOptions(options);
        }

        if (languageData?.getLanguage?.data?.length > 0) {
            const options = languageData?.getLanguage?.data?.map((d) => {
                return {
                    label: t(d?.name),
                    value: d?.code,
                };
            });
            setLanguageOptions(options);
        }

    }, [newsletterLoading, languageLoading]);

    const userInfoOnSubmit = async (value) => {
        try {
            delete value["combinedMobileNo"]
            const nonUpdatableFields = ["tag", "leadSource", "age", "affiliateCode"]
            Object.keys(value).forEach((key) => {
                const match = nonUpdatableFields?.find(d => d === key)
                if (value[key] === undefined || match) {
                    delete value[key];
                }
            });

            let validate
            if (currentTab === "corporate") {
                value["corporateMobileNo"] = value?.corporateMobileNo?.toString()
                value["corporateCountryCode"] = value?.countryCode
                delete value["countryCode"];
            } else if (currentTab === "basic") {
                value["mobileNo"] = value?.mobileNo?.toString()
                validate = ["basicInformation", "loginInformation", "additionalInformation"]
            } else if (currentTab === "residence") {
                validate = ["residentialInformation"]
            }

            updateUser(data?._id, {...value, validationType: validate});
            setEditable(false);
        } catch (e) {
            systemErrorNotification()
        }
    };

    const countryOptions = countryList?.map((d) => {
        return {
            label: t(`${d?.name}`),
            value: d?.name,
        };
    });

    useEffect(() => {
        setEditable(false);
    }, [currentTab]);

    useEffect(() => {
        if (data && Object.keys(data)?.length > 0) {
            setComponentLoading(false)
        }
    }, [data]);

    return (
        <Card style={{width: "100%"}}>
            <Spin tip="Loading..." spinning={componentLoading}>
                <Tabs
                    size={"small"}
                    tabBarExtraContent={
                        <>
                            {editable ? (
                                <Space>
                                    <Button onClick={() => setEditable(false)}>{t("common.cancel")}</Button>
                                    {submitButton}
                                    {currentTab === "residence" ?
                                        <Button onClick={() => residenceForm.submit()}>Submit</Button> :
                                        <Button onClick={() => basicForm.submit()}>Submit</Button>
                                    }
                                </Space>
                            ) : permissionUser?.clientsUser?.edit?.edit &&
                            (currentTab === "basic" ||
                                currentTab === "corporate" ||
                                currentTab === "representative" ||
                                currentTab === "residence") ? (
                                <Button type={"link"} onClick={() => setEditable(true)} icon={<EditOutlined/>}/>
                            ) : (
                                <></>
                            )}
                        </>
                    }
                    onTabClick={(key) => setCurrentTab(key)}
                >
                    {data?.clientAccountType === "corporate" ? (
                        <>
                            <TabPane tab={t("clientUser.tab.basicInfo")} key={"corporate"}>
                                <Form onFinish={userInfoOnSubmit} form={basicForm}>
                                    <BasicInformation
                                        data={data}
                                        editable={editable}
                                        setSubmitButton={setSubmitButton}
                                        newsletterOptions={newsletterOptions}
                                        languageOptions={languageOptions}
                                        basicField={basicField}
                                        loginField={loginField}
                                        isReadSensitive={permissionUser?.clientsUser?.view?.sensitiveInformation}
                                    />
                                </Form>
                            </TabPane>
                            <TabPane tab={t("clientUser.tab.representativeInfo")} key={"representative"}>
                                <Form onFinish={userInfoOnSubmit}>
                                    <RepresentativeInformation
                                        data={data}
                                        editable={editable}
                                        setSubmitButton={setSubmitButton}
                                        countryOptions={countryOptions}
                                        isReadSensitive={permissionUser?.clientsUser?.view?.sensitiveInformation}
                                    />
                                </Form>
                            </TabPane>
                        </>
                    ) : (
                        <TabPane tab={t("clientUser.tab.basicInfo")} key={"basic"}>
                            <Form onFinish={userInfoOnSubmit} form={basicForm}>
                                <BasicInformation
                                    data={data}
                                    editable={editable}
                                    setSubmitButton={setSubmitButton}
                                    newsletterOptions={newsletterOptions}
                                    languageOptions={languageOptions}
                                    basicField={basicField}
                                    loginField={loginField}
                                    isReadSensitive={permissionUser?.clientsUser?.view?.sensitiveInformation}
                                />
                            </Form>
                        </TabPane>
                    )}
                    <TabPane tab={t("clientUser.tab.bankInfo")} key={"bank"}>
                        <BankInformation
                            data={data}
                            pspField={pspField}
                            bankInfo={bankInfo}
                            loading={pspFieldLoading || bankInfoLoading}
                            refetchBankInformation={refetchBankInformation}
                        />
                    </TabPane>
                    <TabPane tab={t("clientUser.tab.accountVerification")} key={"kyc"}>
                        {/*<Form onFinish={onKycSubmit}>*/}
                        <AccountVerification
                            userId={data?._id}
                        />
                        {/*</Form>*/}
                    </TabPane>
                    {residenceField?.length > 0 && (
                        <TabPane tab={t("clientUser.tab.residenceInfo")} key={"residence"}>
                            <Form onFinish={userInfoOnSubmit} form={residenceForm}>
                                <ResidenceInformation
                                    data={data}
                                    editable={editable}
                                    setSubmitButton={setSubmitButton}
                                    countryOptions={countryOptions}
                                    residenceField={residenceField}
                                    isReadSensitive={permissionUser?.clientsUser?.view?.sensitiveInformation}
                                />
                            </Form>
                        </TabPane>
                    )}
                </Tabs>
            </Spin>
        </Card>
    );
};
export default ClientDetail;
