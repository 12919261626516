import React, {useEffect, useState} from "react";
import {Popconfirm, Space} from "antd";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import {useTranslation} from "react-i18next";
import DeskModal from "./deskModal";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import {columns} from "../../../function/tableColumn";
import {getDesk, getDeskCount, useDeleteDeskMutation,} from "../../../../../../api/graphql/desk";
import useAuthorize from "../../../../../../_common/authorize";

function DeskTable_1(props) {
  const {
    tableName,
    activeTab,
    formMode,
    isModalOpen,
    toggleModal,
    setExportData,
    setExportColumn,
    refetchDeskNetwork,
    deskPermission,
    setLoading,
  } = props;
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState({});
  const [orderBy, setOrderBy] = useState("parent_ASC");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [filter, setFilter] = useState({});

  const { data: totalCount } = getDeskCount({ filter: filter });
  const { loading, error, data } = getDesk({ filter: filter }, 0, 0, orderBy);
  // const { loading, error, data } = getDesk(
  //   { filter: filter },
  //   limit,
  //   offset,
  //   orderBy
  // );
  const { deleteDesk } = useDeleteDeskMutation();
  const { systemErrorNotification } = useAuthorize();

  const onChangePageHandler = (page, pageSize) => {
    setLimit(pageSize);
    setOffset(limit * (page - 1));
  };

  const closeModal = () => {
    toggleModal("");
    setSelectedItem({});
  };

  const openEditModal = (record) => {
    setSelectedItem(record);
    toggleModal("edit");
  };

  const handleDeleteDesk = async (id) => {
    try {
      await deleteDesk(id);
    } catch (e) {
      console.log(e);
      systemErrorNotification();
    }
  };

  useEffect(() => {
    setExportColumn(columns);
    const exportData = data?.getDesk?.data?.map((v) => ({
      ...v,
      parent: v?.parent?.name || "-",
    }));
    setExportData(exportData);
    refetchDeskNetwork();
  }, [data?.getDesk?.data]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return (
    <div className="margin-top-0-75">
      <DeskModal
        mode={formMode}
        item={selectedItem}
        isOpen={isModalOpen}
        setOpen={toggleModal}
        closeModal={closeModal}
      />
      <TableAntDesign
        tableName={tableName}
        data={data?.getDesk?.data}
        columns={[
          ...columns,
          {
            title: t("common.action"),
            key: "action",
            width: 100,
            align: "left",
            render: (_, record: any) => (
              <Space>
                {deskPermission?.edit?.edit && (
                  <a
                    style={{ color: "green" }}
                    onClick={() => openEditModal(record)}
                  >
                    <AiOutlineEdit />
                  </a>
                )}
                {!record?.isMasterDesk && deskPermission?.delete?.delete && (
                  <Popconfirm
                    placement="left"
                    description={"Are you sure to delete this desk?"}
                    title={`Delete the Desk`}
                    onConfirm={() => {
                      handleDeleteDesk(record?.id);
                    }}
                    okText={t("common.yes")}
                    okType="danger"
                    cancelText={t("common.no")}
                  >
                    <a>
                      <AiOutlineDelete style={{ color: "red" }} />
                    </a>
                  </Popconfirm>
                )}
              </Space>
            ),
          },
        ]}
        size={"small"}
        loading={loading}
        filter={setFilter}
        order={setOrderBy}
        pagination={{
          pageSize: limit,
          showSizeChanger: true,
          onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
          total: totalCount?.getDeskCount,
          showTotal: (total) => <p>Total {total} items</p>,
        }}
      />
    </div>
  );
}

export default DeskTable_1;
