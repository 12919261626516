import React, {useEffect, useState} from "react"
import {Button, Popconfirm, Space} from "antd"
import TableAntDesign from "../../../../../../../components/table/tableAntDesign"
import {ColumnPosition} from "../../../function/tableColumn"
import EditTableUserPreferenceButton from "../../../../../../../components/table/function/editTableUserPreferenceButton"
import ExportTableButton from "../../../../../../../components/table/function/exportTable"
import useAuthorize from "../../../../../../../_common/authorize"
import moment from "moment"
import {getConfigByName} from "../../../../../../../../config"
import {BRAND_NAME} from "../../../../../../../../config/brandVariable"
import {useTranslation} from "react-i18next"
import {voidAllOpenPosition, voidOpenPosition} from "../../../../../../../api/graphql/voidReopenClosedPosition"
import {useSetRecoilState} from "recoil"
import {notificationMessage} from "../../../../../../../../recoil_state"

const AccountPosition = (props) => {
    const {traderId, detail, tradingAccountPermission} = props
    const {t} = useTranslation()
    const [socket, setSocket] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const columns = ColumnPosition()

    const {baseErrorChecking, systemErrorNotification} = useAuthorize()
    const setNotificationMessage = useSetRecoilState(notificationMessage)

    const brandConfig = getConfigByName(BRAND_NAME)

    const voidAllOpenPositionHandle = async () => {
        try {
            const response = await voidAllOpenPosition(detail?._id, traderId?.toString())

            if (response?.voidAllOpenPosition?.__typename === "BaseError") {
                baseErrorChecking(response?.voidAllOpenPosition)
            } else {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Void All Open Positions Successfully, Please wait for a few minutes for the Positions to appear in Closed Position Report."),
                    key: "voidOpenPosition",
                })
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    const voidOpenPositionHandle = async (record) => {
        try {
            const response = await voidOpenPosition(detail?._id, record?.positionId?.toString())

            if (response?.voidOpenPosition?.__typename === "BaseError") {
                baseErrorChecking(response?.voidOpenPosition)
            } else {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Void Open Position Successfully, Please wait for a few minutes for the Position to appear in Closed Position Report."),
                    key: "voidOpenPosition",
                })
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    useEffect(() => {
        try {
            if (detail?.product?.mode !== "demo") {
                const newSocket = new WebSocket(brandConfig?.nextradesWebSocket + "/positionInfo?traderIds=" + traderId)
                // const newSocket = new WebSocket(brandConfig?.nextradesWebSocket + '/positionInfo?traderIds=' + "14238");

                newSocket.addEventListener("message", (event) => {
                    const rawData = event.data
                    const positionString = rawData.replace("Position: ", "").replace(/False/g, "false").replace(/True/g, "true")

                    const positionData = JSON.parse(positionString)

                    const res = (prevPositions) => {
                        const isClosedPosition = positionData?.positionStatus === "POSITION_STATUS_CLOSED"
                        const existingPositionIndex = prevPositions?.findIndex((position) => position.positionId === positionData.positionId)

                        if (isClosedPosition && existingPositionIndex !== -1) {
                            // Remove the position from the list
                            const updatedPositions = [...prevPositions]
                            updatedPositions.splice(existingPositionIndex, 1)
                            return updatedPositions
                        } else if (existingPositionIndex !== -1) {
                            // Replace existing position with updated position
                            const updatedPositions = [...prevPositions]
                            updatedPositions[existingPositionIndex] = positionData
                            const dataMod = updatedPositions?.map((d) => {
                                return {
                                    ...d,
                                    openTimestamp: d?.openTimestamp && moment(d?.openTimestamp)?.format("YYYY-MM-DD HH:mm:ss"),
                                }
                            })
                            return dataMod
                        } else {
                            const dataMod = [...prevPositions, positionData]?.map((d) => {
                                return {
                                    ...d,
                                    openTimestamp: d?.openTimestamp && moment(d?.openTimestamp)?.format("YYYY-MM-DD HH:mm:ss"),
                                }
                            })
                            return dataMod
                        }
                    }

                    setData(res)
                })

                setSocket(newSocket)

                // Clean up the WebSocket connection on component unmount
                return () => {
                    newSocket.close()
                }
            }
        } catch (e) {
            console.log(e)
        }
    }, [traderId])

    return (
        <div id={"AccountPosition"} style={{marginTop: "15px"}}>
            <h4>
                {t("tradingAccount.openPosition")} ({data?.length})
            </h4>
            <div className={"display-flex margin-bottom-0-75"}>
                <Space className={"margin-left-auto"}>
                    {tradingAccountPermission?.edit?.voidOpenPosition && (
                        <Popconfirm
                            placement="left"
                            description={t("Void All Open Positions")}
                            onConfirm={() => {
                                voidAllOpenPositionHandle()
                            }}
                            okText={t("common.yes")}
                            cancelText={t("common.no")}
                            overlayStyle={{width: 300}}
                            title={`Are you sure you want to void All open positions ?`}
                        >
                            <Button size={"small"}>Void All Open Positions</Button>
                        </Popconfirm>
                    )}
                    <EditTableUserPreferenceButton tableName={"Open Positions"} tableColumn={columns} displayTableName={t("tradingAccount.openPosition")} />
                    <ExportTableButton
                        // tableData={data?.map(d => {
                        //     return {
                        //         ...d,
                        //         openTimestamp: moment(d?.openTimestamp)?.format("YYYY-MM-DD HH:mm:ss")
                        //     }
                        // })}
                        tableData={data}
                        tableColumn={columns}
                        tableName={"Open Positions"}
                    />
                </Space>
            </div>
            <TableAntDesign
                data={data}
                tableName={"Open Positions"}
                columns={[
                    ...columns,
                    {
                        title: t("common.action"),
                        key: "action",
                        render: (_, record) => (
                            <>
                                {tradingAccountPermission?.edit?.voidOpenPosition && (
                                    <Popconfirm
                                        placement="left"
                                        description={t("Void Open Position")}
                                        onConfirm={() => {
                                            voidOpenPositionHandle(record)
                                        }}
                                        okText={t("common.yes")}
                                        cancelText={t("common.no")}
                                        overlayStyle={{width: 300}}
                                        title={`Are you sure you want to void this open position - PID${record?.positionId}?`}
                                    >
                                        <Button size={"small"}>Void Open Position</Button>
                                    </Popconfirm>
                                )}
                            </>
                        ),
                    },
                ]}
                size={"small"}
                loading={!traderId}
                isTableLocalSearch={true}
                pagination={{
                    pageSize: 10,
                    showSizeChanger: true,
                    position: "bottomCenter",
                    total: data?.length,
                    showTotal: (total) => <p>Total {total} items</p>,
                }}
            />
        </div>
    )
}

export default AccountPosition
