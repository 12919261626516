import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";

const GET_SALES_OPPORTUNITY_STATUS = gql`
    query getSalesOpportunityStatus($filter: SalesOpportunityStatusFilter, $orderBy: String) {
        getSalesOpportunityStatus(filter: $filter, orderBy: $orderBy) {
            ... on SalesOpportunityStatusAry {
                total
                data {
                    _id
                    name
                    displayName
                    position
                    type
                    isDeleted
                    totalSalesOpp
                    totalSalesOppAmount
                }
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;

const getSalesOpportunityStatus = async () => {
    try {
        return await ApolloClientQuery(
            GET_SALES_OPPORTUNITY_STATUS,
            {
                // filter: filter,
                // orderBy: orderBy,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const CREATE_SALES_OPPORTUNITY_STATUS = gql`
    mutation createSalesOpportunityStatus($input: SalesOpportunityCreateInput!) {
        createSalesOpportunityStatus(input: $input) {
            ... on SalesOpportunityStatus {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const createSalesOpportunityStatus = async (input) => {
    const variables = {
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(CREATE_SALES_OPPORTUNITY_STATUS, variables, true);

    return execute;
}

const UPDATE_SALES_OPPORTUNITY_STATUS = gql`
    mutation updateSalesOpportunityStatus($id: String!, $input: SalesOpportunityUpdateInput) {
        updateSalesOpportunityStatus(id: $id, input: $input) {
            ... on SalesOpportunityStatus {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const updateSalesOpportunityStatus = async (id, input) => {
    const variables = {
        id: id,
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(UPDATE_SALES_OPPORTUNITY_STATUS, variables, true);

    return execute;
}

const DELETE_SALES_OPPORTUNITY_STATUS = gql`
    mutation deleteSalesOpportunityStatus($id: String!) {
        deleteSalesOpportunityStatus(id: $id) {
            ... on SalesOpportunityStatus {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const deleteSalesOpportunityStatus = async (id) => {
    const variables = {
        id: id,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(DELETE_SALES_OPPORTUNITY_STATUS, variables, true);

    return execute;
}

const SORT_SALES_OPPORTUNITY_STATUS = gql`
    mutation sortSalesOpportunityStatus($input: SalesOpportunitySortInput!) {
        sortSalesOpportunityStatus(input: $input) {
            ... on SalesOpportunityStatusAry {
                data {
                    _id
                }
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const sortSalesOpportunityStatus = async (input) => {
    const variables = {
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(SORT_SALES_OPPORTUNITY_STATUS, variables, true);

    return execute;
}

export {
    getSalesOpportunityStatus,
    createSalesOpportunityStatus,
    updateSalesOpportunityStatus,
    deleteSalesOpportunityStatus,
    sortSalesOpportunityStatus
}