import React from "react";
import {getColumnSearchProps, getDigitsExactFilterProps} from "../../../../../components/table/function/filterTable";

export const SymbolMappingModalColumns = [
    {
        title: "Ticker Symbol",
        dataIndex: "ticker_symbol",
        key: "ticker_symbol",
        ...getColumnSearchProps(),
        render: (text) => text || "-",
    },
    {
        title: "Instrument Name",
        dataIndex: "instrument_name",
        key: "instrument_name",
        ...getColumnSearchProps(),
        render: (text) => text || "-",
    },
    {
        title: "Base Symbol ID",
        dataIndex: "base_symbol_id",
        key: "base_symbol_id",
        ...getDigitsExactFilterProps(0, 100000, 1),
        render: (text) => text,
    },
    {
        title: "Country",
        dataIndex: "country",
        key: "country",
        ...getColumnSearchProps(),
        render: (text) => text,
    },
    {
        title: "Stock Exchange",
        dataIndex: "stock_exchange_acronym",
        key: "stock_exchange_acronym",
        ...getColumnSearchProps(),
        render: (text) => text,
    },
    {
        title: "Type",
        dataIndex: "type",
        key: "type",
        filters: [
            {text: "Stock", value: "1"},
            {text: "Commodity", value: "2"},
            {text: "Crypto", value: "3"},
            {text: "Currency", value: "4"},
            {text: "Indices", value: "5"},
        ],
        render: (text) => {
            let type
            switch (text) {
                case 1: type = "Stock"
                break
                case 2: type = "Commodity"
                break
                case 3: type = "Crypto"
                break
                case 4: type = "Currency"
                break
                case 5: type = "Indices"
                break
            }
            return (
                type
            )
        },
    },
];
