import React from 'react';
import {Badge, Button, Card, Col, Empty, List, Popconfirm, Row, Spin, Tag, Tooltip} from 'antd';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useTranslation} from "react-i18next";
import {AiOutlineDelete} from "react-icons/ai";
import {useUpdateRegistrationSettingMutation} from "../../../../../../api/graphql/register";
import {useRecoilValue} from "recoil";
import {
    registrationSettingLoadingState,
    registrationSettingModeState,
    registrationSettingPlatformState
} from "../../../state";
import useAuthorize from '../../../../../../_common/authorize';


const RegistrationSettingCard = (props) => {
    const {fields, setFields, permission} = props
    const {basicField, loginField, residenceField, additionalField, corporateField, registrationField, demoField} = fields;
    const ContainerHeight = "70vh";
    const {t} = useTranslation();

    const platform = useRecoilValue(registrationSettingPlatformState)
    const mode = useRecoilValue(registrationSettingModeState)
    const loading = useRecoilValue(registrationSettingLoadingState)

    const {updateRegistrationSetting} = useUpdateRegistrationSettingMutation()
    const {systemErrorNotification} = useAuthorize();

    const deleteRegistrationField = async (id) => {
        try {
            const response = await updateRegistrationSetting({
                fieldList: id,
                platform: platform,
                mode: mode,
                position: null
            }) 
        } catch (error) {
            systemErrorNotification();
        }
    }

    const onDragEnd = async (result) => {
        if (!result.destination) return
        let position = getIndexFromField(result.destination.index, result.destination.droppableId)

        if (result.destination.index !== result.source.index) {
            const reorderedItems = Array.from(fields[result.destination.droppableId]);
            const [movedItem] = reorderedItems.splice(result.source.index, 1);
            reorderedItems.splice(result.destination.index, 0, movedItem);

            setFields({...fields, [result.destination.droppableId]: reorderedItems})

            const response = await updateRegistrationSetting({
                fieldList: result.draggableId,
                platform: platform,
                mode: mode,
                position: position
            })
        }
    };

    const getIndexFromField = (index, droppableId) => {
        return fields[droppableId][index].position
    }

    const renderListItem = (item, categoryColor) => {
        return (
            <List.Item key={item?._id}>
                <Card
                    className={"registrationCard margin-top-0-75"}
                    key={item?._id}
                    style={{width: "100%"}}
                >
                    <Row>
                        <Col flex={"auto"}>
                            <h4>{t(item?.fieldList?.label)} {item?.fieldList?.isRequired ?
                                <span
                                    style={{color: "red"}}> * </span> : undefined}
                            </h4>
                        </Col>
                        {permission?.registrationWorkflow?.edit?.edit &&
                        <Col flex={"30px"}>
                            <Popconfirm
                                placement="left"
                                description={t("Are you sure to remove this field?")}
                                title={t(`Remove Profile Information Field`)}
                                onConfirm={(e) => {
                                    deleteRegistrationField(item?.fieldList?._id)
                                }}
                                okText={t("Yes")}
                                okType="danger"
                                cancelText={t("No")}
                            >
                                <Tooltip
                                    title={t("Delete")}>
                                    <Button type={"link"}
                                            icon={
                                                <AiOutlineDelete/>}
                                            danger/>
                                </Tooltip>
                            </Popconfirm>
                        </Col>}
                        <Col span={24}>
                            <Tag color="blue"
                                 bordered={false}><small>{item?.fieldList?.fieldType?.toUpperCase()}</small></Tag>
                            <Tag color={categoryColor}
                                 bordered={false}><small>{item?.fieldList?.category?.replace(/(INFORMATION)/i, "")?.toUpperCase()}</small></Tag>
                            {
                                item?.fieldList?.isConfirmRequired &&
                                <Tag color="magenta" style={{marginTop: 4}}
                                     bordered={false}><small>{t("CONFIRM REQUIRED")}</small></Tag>
                            }
                            {
                                item?.fieldList?.isSensitive &&
                                <Tag color="volcano"
                                     bordered={false}><small>{t("SENSITIVE")}</small></Tag>
                            }
                            {
                                item?.fieldList?.isUnique &&
                                <Tag color="orange"
                                     bordered={false}><small>{t("UNIQUE")}</small></Tag>
                            }
                            {
                                item?.fieldList?.isUsedForLogin &&
                                <Tag color="red"
                                     bordered={false}><small>{t("USED FOR LOGIN")}</small></Tag>
                            }
                        </Col>
                    </Row>
                </Card>
            </List.Item>
        )
    }

    const draggableList = (listItem, title, style, badgeColor, field) => {
        return (
            <Badge.Ribbon text={t(title)} color={badgeColor}>
                <List style={{...style, padding: 10, paddingTop: 32, borderRadius: 12}}
                      className={"margin-bottom-0-75"}>
                    <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                        <Droppable droppableId={field}>
                            {(droppableProvided) => (
                                <div ref={droppableProvided.innerRef}>
                                    {listItem.map((item, index) => {
                                        let categoryColor = ""; // Initialize with a default value
                                        switch (item?.fieldList?.category) {
                                            case "basicInformation":
                                                categoryColor = "gold";
                                                break;
                                            case "loginInformation":
                                                categoryColor = "lime";
                                                break;
                                            case "residentialInformation":
                                                categoryColor = "green";
                                                break;
                                            case "additionalInformation":
                                                categoryColor = "geekblue";
                                                break;
                                            case "corporateInformation":
                                                categoryColor = "purple";
                                                break;
                                            case "registrationInformation" :
                                                categoryColor = "cyan"
                                                break
                                        }

                                        return (
                                            permission?.registrationWorkflow?.edit?.edit ? (
                                                <Draggable key={item?._id} draggableId={item?.fieldList?._id}
                                                           index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={{
                                                                ...provided.draggableProps.style,
                                                                opacity: snapshot.isDragging ? 0.8 : 1,
                                                            }}
                                                        >
                                                            {renderListItem(item, categoryColor)}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ) : (
                                                <>{renderListItem(item, categoryColor)}</>
                                            )
                                        );
                                    })}
                                    {droppableProvided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </List>
            </Badge.Ribbon>
        );
    };

    return (
        <div id={"registration-setting-card"}>
            <Row style={{marginBottom: "15px"}}>
                <Col flex={"auto"}>
                    <h3>{t("Setting")}</h3>
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{height: ContainerHeight, overflow: "auto", padding: "0 16px"}}>
                    {
                        basicField?.length > 0 || loginField?.length > 0 || residenceField?.length > 0 || additionalField?.length > 0 || corporateField?.length > 0 ? (
                            <>
                                {
                                    basicField?.length > 0 &&
                                    draggableList(basicField, "Basic Information", {backgroundColor: "#fffbe6"}, "gold", "basicField")
                                }
                                {
                                    loginField?.length > 0 &&
                                    draggableList(loginField, "Login Information", {backgroundColor: "#fcffe6"}, "lime", "loginField")
                                }
                                {
                                    residenceField?.length > 0 &&
                                    draggableList(residenceField, "Residence Information", {backgroundColor: "#f6ffed"}, "green", "residenceField")
                                }
                                {
                                    additionalField?.length > 0 &&
                                    draggableList(additionalField, "Additional Information", {backgroundColor: "#f0f5ff"}, "geekblue", "additionalField")
                                }
                                {
                                    corporateField?.length > 0 &&
                                    draggableList(corporateField, "Corporate Information", {backgroundColor: "#faf0ff"}, "purple", "corporateField")
                                }
                                {
                                    demoField?.length > 0 &&
                                    draggableList(demoField, "Demo Information", {backgroundColor: "#ebebeb"}, "#999", "demoField")
                                }
                                {
                                    registrationField?.length > 0 &&
                                    draggableList(registrationField, "Registration Information", {backgroundColor: "#d9fbfb"}, "cyan", "registrationField")
                                }
                            </>
                        ) : loading ? (
                            <Col span={24} style={{marginTop: "20%", textAlign: "center"}}>
                                <Spin size="large"/>
                            </Col>
                        ) : (
                            <Col span={24} style={{paddingTop: "20%"}}>
                                <Empty/>
                            </Col>
                        )
                    }
                </Col>
            </Row>
        </div>
    )
}

export default RegistrationSettingCard