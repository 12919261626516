import {Form, Input} from "antd";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import FormErrors from "./formErrors";
import {TextField} from "@mui/material";


const InputFormItemMui = (props) => {
    const {
        layout,
        label,
        name,
        placeholder,
        prefix,
        defaultValue,
        value,
        type,
        externalErrorMessage,
        required,
        variant,
        onChange,
        disabled,
        maxLength,
        showCount,
    } = props;

    const {
        register,
        watch,
        setValue,
        formState: {touchedFields, isSubmitted, errors},
    } = useFormContext();

    const errorMessage = FormErrors.errorMessage(
        name,
        label,
        errors,
        touchedFields,
        isSubmitted,
        externalErrorMessage
    );

    useEffect(() => {
        register(name);
    }, [register, name]);


    return (
        <TextField
            required={required}
            id={name}
            variant={variant ?? "standard"}
            name={name}
            label={label}
            value={value ?? watch(name)}
            defaultValue={defaultValue || watch(name)}
            error={errorMessage ? true : false}
            helperText={errorMessage}
            placeholder={placeholder}
            disabled={disabled}
            InputProps={{
                startAdornment: prefix || <span />,
            }}
            style={{width: "100%"}}
            onChange={(e) => {
                setValue(name, e?.target?.value, {shouldValidate: true});
                onChange && onChange(e?.target?.value);
            }}
            focused={watch(name) && true}
        />
    );
};

InputFormItemMui.defaultProps = {
    type: "text",
    required: false,
    disabled: false,
};

InputFormItemMui.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    prefix: PropTypes.element,
    value: PropTypes.string,
    type: PropTypes.string,
    externalErrorMessage: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    layout: PropTypes.object,
    maxLength: PropTypes.number,
    showCount: PropTypes.bool,
    defaultValue: PropTypes.string,
    variant: PropTypes.string,
};

export default InputFormItemMui;
