import React, {useEffect, useMemo, useState} from "react";
import {useSetRecoilState, useRecoilValue} from "recoil";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Tooltip} from "antd";
import {CloseOutlined, DeleteOutlined, EditOutlined, MinusCircleOutlined, PlusCircleOutlined, SaveOutlined} from "@ant-design/icons";
import useAuthorize from "../../../../../../_common/authorize";
import {getPspSettingDetailDropdown} from "../../../../../../api/graphql/pspSettingDetail";
import {getAllDeskDropdown} from "../../../../../../api/graphql/desk";
import {getCurrencyRate} from "../../../../../../api/graphql/pspSetting";
import {
    addPspSettlement,
    deletePspSettlement,
    getPspSettlement,
    getPspSettlementCount,
    updatePspSettlement,
} from "../../../../../../api/graphql/pspSettlement";
import {notificationMessage, selfPermissionObj} from "../../../../../../../recoil_state";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import {pspSettlementTableColumns} from "../../../function/pspSettlementTableColumn";
import {groupByPspSettingName} from "../../../function/pspFunction";

const newDesk = {
    desk: "",
    percentage: 0,
};

const labelCol = {span: 8, offset: 0};

const PspSettlement = (props) => {
    const {setRefreshReportData} = props;
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const tableName = "PSP Settlement";
    const {baseErrorChecking, systemErrorNotification} = useAuthorize();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const selfPermission = useRecoilValue<any>(selfPermissionObj);
    const pspSettlementPermission = selfPermission?.reports?.pspSettlement;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
    const [formMode, setFormMode] = useState("");
    const [selectedRecord, setSelectedRecord] = useState<any>({});
    const [isCurrencyRateLoading, setIsCurrencyRateLoading] = useState(true);
    const [isRequesting, setIsRequesting] = useState(false);
    const [currencyRateList, setCurrencyRateList] = useState([]);
    const [pspId, setPspId] = useState("");
    const [pspCurrencyId, setPspCurrencyId] = useState("");
    const [desks, setDesks] = useState([newDesk]);
    const [rate, setRate] = useState(0);

    // For Table
    const [dataSource, setDataSource] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [filter, setFilter] = useState<any>({});
    const [orderBy, setOrderBy] = useState<any>("");
    const [limit, setLimit] = useState<any>(10);
    const [offset, setOffset] = useState<any>(0);

    const {loading, error, data: pspSettingDetailList} = getPspSettingDetailDropdown({filter: {}});
    const {data: deskList} = getAllDeskDropdown();

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize);
        setOffset(limit * (page - 1));
    };

    const toggleModal = () => {
        try {
            setIsModalOpen((prevState) => !prevState);
        } catch (error) {}
    };

    const toggleCreateEditModal = (mode, record?) => {
        try {
            setIsCreateEditModalOpen((prevState) => !prevState);
            if (record) {
                setSelectedRecord(record);
            }
            setFormMode(mode);
        } catch (error) {}
    };

    const getCurrencyRateList = async () => {
        try {
            setIsCurrencyRateLoading(true);
            const currencyRate = await getCurrencyRate();
            setCurrencyRateList(currencyRate?.getCurrencyRate?.data);
        } catch (error) {
        } finally {
            setIsCurrencyRateLoading(false);
        }
    };

    const getCurrentCurrencyRate = () => {
        try {
            const pspCurrencyData = pspSettingCurrencyOptions?.find((v) => v?.value === pspCurrencyId);
            let currencyRate = pspCurrencyData?.fixedRate || 0;

            if (!currencyRate) {
                const curRateData: any = currencyRateList?.find((c: any) => c.currency === pspCurrencyName);
                currencyRate = curRateData?.rate || 0;
            }

            if (currencyRate) {
                setRate(currencyRate);
                form.setFieldValue("rate", currencyRate);
            }
        } catch (error) {
            // Handle the error if needed
        }
    };

    const getPspSettlementData = async () => {
        try {
            const response = await getPspSettlement({filter}, limit, offset, orderBy);
            setDataSource(response?.getPspSettlement?.data || []);
            setTotalCount(response?.getPspSettlement?.total);
        } catch (error) {}
    };

    const handleAddDesk = () => {
        try {
            setDesks((prevState) => {
                // form.setFieldValue("desks", [...prevState, newDesk]);
                return [...prevState, newDesk];
            });
        } catch (error) {}
    };

    const handleDeleteDesk = (index) => {
        try {
            setDesks((prevState) => {
                const newArray = [...prevState];
                newArray.splice(index, 1);
                // form.setFieldValue("desks", newArray);
                return newArray;
            });
        } catch (error) {}
    };

    const handleDeskChg = (value, index) => {
        try {
            setDesks((prevState) => {
                let newArray = [...prevState];
                newArray[index] = {...newArray[index], desk: value};
                // form.setFieldValue("desks", newArray);
                return newArray;
            });
        } catch (error) {}
    };

    const handleDeskPercentageChg = (value, index) => {
        try {
            setDesks((prevState) => {
                let newArray = [...prevState];
                newArray[index] = {...newArray[index], percentage: value};
                // form.setFieldValue("desks", newArray);
                return newArray;
            });
        } catch (error) {}
    };

    const handleDeleteSettlement = async (id) => {
        try {
            const response = await deletePspSettlement(id);
            const typename = response?.deletePspSettlement?.__typename;
            if (typename === "BaseError") {
                return await baseErrorChecking(response?.deletePspSettlement);
            } else if (typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.deletePspSettlement?.msg),
                    key: "deletePspSettlement",
                });
            } else {
                getPspSettlementData();
                setTimeout(() => {
                    setRefreshReportData(true);
                }, 3000);
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Delete Successfully",
                    key: "deletePspSettlement",
                });
            }
        } catch (error) {}
    };

    const onSubmit = async (value) => {
        try {
            const isEveryDeskValid = desks?.every((desk) => !!desk?.desk);
            const key = formMode === "add" ? "addPspSettlement" : "updatePspSettlement";

            if (isEveryDeskValid) {
                setIsRequesting(true);

                const input = {
                    ...value,
                    settlementDate: moment(value?.settlementDate?.format("YYYY-MM-DD") + "T00:00:00.000+00:00"),
                    desks: desks?.map((desk) => ({...desk, percentage: desk?.percentage?.toString()})),
                    rate: rate?.toString(),
                };

                const response = formMode === "add" ? await addPspSettlement(input) : await updatePspSettlement(selectedRecord?._id, input);
                const typename = formMode === "add" ? response?.addPspSettlement?.__typename : response?.updatePspSettlement?.__typename;

                if (typename === "BaseError") {
                    return await baseErrorChecking(formMode === "add" ? response?.addPspSettlement : response?.updatePspSettlement);
                }

                const responseMsg = formMode === "add" ? t(response?.addPspSettlement?.msg) : t(response?.updatePspSettlement?.msg);

                if (typename === "CrmTaskApproval") {
                    setNotificationMessage({
                        status: "info",
                        title: "",
                        msg: responseMsg,
                        key: key,
                    });
                    toggleCreateEditModal("");
                } else {
                    getPspSettlementData();
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: responseMsg,
                        key: key,
                    });
                    toggleCreateEditModal("");
                    setTimeout(() => {
                        setRefreshReportData(true);
                    }, 3000);
                }
            } else {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t("errorMsg.required.requiredField", {field: t("user.desk")}),
                    key: key,
                });
            }
        } catch (e) {
            console.log(e);
            systemErrorNotification();
        } finally {
            setIsRequesting(false);
        }
    };

    const resetTable = () => {
        try {
            setFilter({});
            setOrderBy("");
            setOffset(0);
        } catch (error) {}
    };

    const restCreateEditForm = () => {
        try {
            getPspSettlementData();
            setRate(0);
            setDesks([newDesk]);
            form.resetFields();
            getCurrencyRateList();
        } catch (error) {}
    };

    const preload = () => {
        try {
            if (formMode === "edit" && Object?.keys(selectedRecord)?.length > 0) {
                form.setFieldValue("pspSettingId", selectedRecord?.pspSettingId?._id);
                setPspId(selectedRecord?.pspSettingId?._id);
                form.setFieldValue("pspSettingCurrencyId", selectedRecord?.pspSettingCurrencyId?._id);
                setPspCurrencyId(selectedRecord?.pspSettingCurrencyId?._id);
                form.setFieldValue("settlementDate", moment(selectedRecord?.settlementDate));
                form.setFieldValue("convertedAmount", selectedRecord?.convertedAmount);
                form.setFieldValue("rate", selectedRecord?.rate);
                setRate(selectedRecord?.rate);
                let desksData = selectedRecord?.desks?.map((v) => ({desk: v?.desk?._id, percentage: v?.percentage}));
                setDesks(desksData);
                form.setFieldValue("desks", desksData);
                form.setFieldValue("remark", selectedRecord?.remark);
                form.setFieldValue("excludeFee", selectedRecord?.excludeFee);
            }
        } catch (error) {}
    };

    useEffect(() => {
        getCurrentCurrencyRate();
    }, [pspCurrencyId]);

    useEffect(() => {
        if (pspId && formMode !== "edit") {
            setPspCurrencyId("");
            form.setFieldValue("pspSettingCurrencyId", "");
        }
    }, [pspId]);

    useEffect(() => {
        getPspSettlementData();
    }, [filter, limit, offset, orderBy, isModalOpen]);

    useEffect(() => {
        if (!isCreateEditModalOpen) {
            restCreateEditForm();
        }
    }, [isCreateEditModalOpen]);

    useEffect(() => {
        if (!isModalOpen) {
            resetTable();
        }
    }, [isModalOpen]);

    useEffect(() => {
        preload();
    }, [formMode, selectedRecord]);

    const validateMessages = {
        required: t("errorMsg.required.requiredField", {field: "${label}"}),
    };

    const groupedDataByPsp = groupByPspSettingName(pspSettingDetailList?.getPspSettingDetailDropdown?.data);

    const pspSettingOptions = useMemo(() => {
        return Object?.keys(groupedDataByPsp)?.map((psp) => ({
            label: psp + " - " + groupedDataByPsp[psp]?.[0]?.pspSetting?.displayName,
            value: groupedDataByPsp[psp]?.[0]?.pspSetting?._id,
        }));
    }, [groupedDataByPsp]);

    const pspSettingCurrencyOptions = useMemo(() => {
        const pspName = pspSettingDetailList?.getPspSettingDetailDropdown?.data?.find((detail) => detail?.pspSetting?._id === pspId)?.pspSetting
            ?.name;
        const curList = groupedDataByPsp?.[pspName]?.map((pspDetail) => {
            return {
                label: pspDetail?.pspCurrency?.currency,
                value: pspDetail?.pspCurrency?._id,
                fixedRate: pspDetail?.pspCurrency?.fixedRate,
                digits: pspDetail?.pspCurrency?.digits,
            };
        });
        const amendedCurList = curList?.filter(
            (obj, index, self) => index === self.findIndex((o) => o.label === obj.label && o.value === obj.value && o.fixedRate === obj.fixedRate)
        );
        return amendedCurList || [];
    }, [groupedDataByPsp, pspId]);

    const deskOptions = useMemo(() => {
        return deskList?.getAllDeskDropdown?.data?.map((desk) => ({label: desk?.name, value: desk?._id}));
    }, [deskList?.getAllDeskDropdown?.data?.length]);

    const pspCurrencyName = pspSettingDetailList?.getPspSettingDetailDropdown?.data?.find((detail) => detail?.pspCurrency?._id === pspCurrencyId)
        ?.pspCurrency?.currency;

    return (
        <>
            <Button onClick={toggleModal}>{t("Create Settlement")}</Button>
            <Modal
                title={t("psp.pspSettlement")}
                open={isModalOpen}
                width={"90%"}
                footer={null}
                destroyOnClose
                getContainer="#reports"
                className="pspSettlementModal"
                maskClosable={false}
                onCancel={toggleModal}
            >
                {pspSettlementPermission?.add?.add && (
                    <Button className="margin-bottom-0-75" onClick={() => toggleCreateEditModal("add")}>
                        {t("Create Settlement")}
                    </Button>
                )}
                <TableAntDesign
                    data={dataSource}
                    tableName={tableName}
                    columns={[
                        ...pspSettlementTableColumns,
                        {
                            title: "",
                            dataIndex: "",
                            key: "",
                            render: (_, record) => (
                                <Space size="middle">
                                    {pspSettlementPermission?.edit?.edit && (
                                        <Tooltip title={t("common.edit")}>
                                            <a onClick={() => toggleCreateEditModal("edit", record)}>
                                                <EditOutlined />
                                            </a>
                                        </Tooltip>
                                    )}
                                    {pspSettlementPermission?.delete?.delete && (
                                        <Tooltip title={t("common.delete")}>
                                            <Popconfirm
                                                placement="left"
                                                title={t("common.deleteConfirm")}
                                                // description={t("roleSetting.delete.popconfirm.description")}
                                                onConfirm={() => {
                                                    handleDeleteSettlement(record?._id);
                                                }}
                                                okText={t("common.yes")}
                                                cancelText={t("common.no")}
                                            >
                                                <a>
                                                    <DeleteOutlined />
                                                </a>
                                            </Popconfirm>
                                        </Tooltip>
                                    )}
                                </Space>
                            ),
                        },
                    ]}
                    size={"small"}
                    loading={loading}
                    tableSpin={false}
                    filter={setFilter}
                    order={setOrderBy}
                    rowKey={(record) => record.id || record?._id}
                    pagination={{
                        pageSize: limit,
                        showSizeChanger: true,
                        onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                        total: totalCount,
                        showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
                    }}
                />
            </Modal>
            {isCreateEditModalOpen && (
                <Modal
                    title={formMode === "add" ? "Create PSP Settlement" : "Edit PSP Settlement"}
                    open={isCreateEditModalOpen}
                    width={"90%"}
                    footer={null}
                    destroyOnClose
                    getContainer="#reports"
                    className="createSettlementModal"
                    maskClosable={false}
                    onCancel={() => toggleCreateEditModal("")}
                >
                    <Form onFinish={onSubmit} form={form} validateMessages={validateMessages} colon={false} labelWrap={true}>
                        <Row gutter={[16, 16]} className={"margin-top-0-75 margin-bottom-0-75"}>
                            <Col span={24}>
                                <Form.Item name={"pspSettingId"} label={t("PSP")} labelCol={labelCol} labelAlign="left" rules={[{required: true}]}>
                                    <Select
                                        options={pspSettingOptions}
                                        optionFilterProp={"label"}
                                        allowClear
                                        showSearch
                                        value={pspId}
                                        onChange={(e) => setPspId(e)}
                                        disabled={formMode === "edit" || isCurrencyRateLoading}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name={"pspSettingCurrencyId"}
                                    label={t("common.currency")}
                                    labelCol={labelCol}
                                    labelAlign="left"
                                    rules={[{required: true}]}
                                >
                                    <Select
                                        options={pspSettingCurrencyOptions}
                                        optionFilterProp={"label"}
                                        allowClear
                                        showSearch
                                        onChange={(e) => setPspCurrencyId(e)}
                                        disabled={!pspId || formMode === "edit" || isCurrencyRateLoading}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name={"settlementDate"}
                                    label={t("psp.settlementDate")}
                                    labelCol={labelCol}
                                    labelAlign="left"
                                    rules={[{required: true}]}
                                >
                                    <DatePicker />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name={"convertedAmount"}
                                    label={t("common.amount")}
                                    labelCol={labelCol}
                                    labelAlign="left"
                                    rules={[{required: true}]}
                                >
                                    <InputNumber />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name={"rate"} label={t("common.rate")} labelCol={labelCol} labelAlign="left" rules={[{required: true}]}>
                                    <div>
                                        1 USD = <InputNumber value={rate} onChange={(value: any) => setRate(value)} /> {pspCurrencyName}
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name={"desks"} label={t("common.desks")} labelCol={labelCol} labelAlign="left">
                                    {desks?.map((v, index) => (
                                        <div className="margin-bottom-0-5">
                                            <Space>
                                                <Select
                                                    value={v?.desk}
                                                    onChange={(e) => handleDeskChg(e, index)}
                                                    options={deskOptions}
                                                    optionFilterProp={"label"}
                                                    allowClear
                                                    showSearch
                                                    style={{minWidth: 250}}
                                                />
                                                <InputNumber
                                                    value={v?.percentage}
                                                    onChange={(e: any) => handleDeskPercentageChg(e, index)}
                                                    min={0}
                                                    max={100}
                                                    addonAfter="%"
                                                />
                                                {index !== 0 && <MinusCircleOutlined onClick={() => handleDeleteDesk(index)} />}
                                                {index === desks?.length - 1 && <PlusCircleOutlined onClick={handleAddDesk} />}
                                            </Space>
                                        </div>
                                    ))}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name={"remark"} label={t("common.remark")} labelCol={labelCol} labelAlign="left">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name={"excludeFee"}
                                    label={t("common.excludeFee")}
                                    labelCol={labelCol}
                                    labelAlign="left"
                                    valuePropName="checked"
                                >
                                    <Checkbox />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="end" className="section">
                            <Button onClick={() => toggleCreateEditModal("")} icon={<CloseOutlined />} className="cancelBtn" disabled={isRequesting}>
                                {t("common.cancel")}
                            </Button>
                            <Button htmlType="submit" icon={<SaveOutlined />} className="button-submit-1" loading={isRequesting}>
                                {formMode === "add" ? t("common.submit") : t("common.update")}
                            </Button>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default PspSettlement;
