import {gql, useMutation, useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {ApolloClientQuery} from "./_apolloClient";

const GET_VOUCHER_COUNT = gql`
    query getVoucherCount($filter: VoucherSearchInput) {
        getVoucherCount(filter: $filter)
    }
`;

const getVoucherCount = ({filter}) => {
    try {
        const {loading, error, data} = useQuery(GET_VOUCHER_COUNT, {
            variables: {
                filter: {
                    name: filter?.name || null,
                    voucherType: filter?.voucherType || null,
                },
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_VOUCHER = gql`
    query getVoucher($filter: VoucherSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
        getVoucher(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on VoucherArray {
                data {
                    _id
                    id
                    displayName {
                        content
                        language
                    }
                    name
                    description {
                        content
                        language
                    }
                    termAndConditionEnabled
                    termAndCondition {
                        content
                        language
                    }
                    rewardType
                    product {
                        id
                        _id
                        name
                    }
                    voucherType
                    voucherValueIsRange
                    voucherValue
                    voucherValueStartRange
                    voucherValueEndRange
                    voucherStackable
                    stockVoucher {
                        id
                        _id
                        name
                    }
                    maxAmount
                    imageLight {
                        fileList
                        path
                    }
                    imageDark {
                        fileList
                        path
                    }
                    defaultImageLight {
                        fileList
                        path
                    }
                    defaultImageDark {
                        fileList
                        path
                    }
                    targetReportId
                    targetReportFieldId
                    isDeleted
                    createdAt
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getVoucher = ({filter}, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data, refetch} = useQuery(GET_VOUCHER, {
            variables: {
                filter: filter,
                limit: limit,
                offset: offset,
                orderBy: orderBy,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        const refreshQuery = () => {
            refetch();
        };

        return {loading, error, data, refreshQuery};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_USER_VOUCHERS = gql`
    query getUserVouchers($filter: UserVouchersSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
        getUserVouchers(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on UserVouchersArray {
                data {
                    _id
                    id
                    voucherIssuedDate
                    voucherAppliedDate
                    voucherExpiryDate
                    result
                    isVoucherApplied
                    isVoucherExpired
                    createdAt
                    updatedAt
                    tradingAccountId {
                        _id
                        accountId
                        product {
                            name
                        }
                        tradingVoucher
                        credit
                    }
                    userClaimMissionId {
                        _id
                    }
                    voucherGroupId {
                        _id
                        id
                        name
                        isRandomReward
                        termAndConditionEnabled
                        rewardValue
                        isDeleted
                        createdAt
                        updatedAt
                        displayName {
                            language
                            content
                        }
                        description {
                            language
                            content
                        }
                        termAndCondition {
                            language
                            content
                        }
                        defaultImageLight {
                            fileList
                            path
                        }
                        defaultImageDark {
                            fileList
                            path
                        }
                        imageLight {
                            fileList
                            path
                        }
                        imageDark {
                            fileList
                            path
                        }
                    }
                    voucherId {
                        _id
                        id
                        name
                        termAndConditionEnabled
                        rewardType
                        voucherType
                        voucherValueIsRange
                        voucherValue
                        voucherValueStartRange
                        voucherValueEndRange
                        voucherStackable
                        maxAmount
                        targetReportId
                        targetReportFieldId
                        isDeleted
                        createdAt
                        updatedAt
                        displayName {
                            language
                            content
                        }
                        description {
                            language
                            content
                        }
                        termAndCondition {
                            language
                            content
                        }
                        product {
                            _id
                            id
                            name
                            code
                            minDeposit
                            networkType
                            leverage
                            interestRate
                            productType
                            interestRateType
                            showPublic
                            isDeleted
                            createdAt
                            updatedAt
                            productGroup {
                                _id
                                name
                            }
                            server {
                                _id
                                plantId
                                name
                            }
                        }
                        stockVoucher {
                            _id
                            name
                            baseSymbolId
                        }
                        defaultImageLight {
                            fileList
                            path
                        }
                        defaultImageDark {
                            fileList
                            path
                        }
                        imageLight {
                            fileList
                            path
                        }
                        imageDark {
                            fileList
                            path
                        }
                    }
                    missionId {
                        _id
                        displayName {
                            language
                            content
                        }
                    }
                    canClaimForTradingAccount {
                        _id
                        accountId
                        mode
                        product {
                            name
                        }
                    }
                    user {
                        _id
                        username
                    }
                }
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;

async function getUserVouchers(filter, limit, offset, orderBy) {
    const variables = {
        filter: filter,
        limit: limit,
        offset: offset,
        orderBy: orderBy,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_USER_VOUCHERS, variables, false);

    return execute;
}

const GET_USER_VOUCHERS_COUNT = gql`
    query getUserVouchersCount($filter: UserVouchersSearchInput) {
        getUserVouchersCount(filter: $filter)
    }
`;

async function getUserVouchersCount(filter) {
    const variables = {
        filter: filter,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_USER_VOUCHERS_COUNT, variables, false);

    return execute;
}

const ADD_VOUCHER = gql`
    mutation addVoucher($input: VoucherInput) {
        addVoucher(input: $input) {
            ... on Voucher {
                _id
                id
                displayName {
                    content
                    language
                }
                name
                description {
                    content
                    language
                }
                termAndConditionEnabled
                termAndCondition {
                    content
                    language
                }
                rewardType
                product {
                    id
                    _id
                    name
                }
                voucherType
                voucherValueIsRange
                voucherValue
                voucherValueStartRange
                voucherValueEndRange
                voucherStackable
                stockVoucher {
                    id
                    _id
                    name
                }
                maxAmount
                imageLight {
                    fileList
                    path
                }
                imageDark {
                    fileList
                    path
                }
                defaultImageLight {
                    fileList
                    path
                }
                defaultImageDark {
                    fileList
                    path
                }
                targetReportId
                targetReportFieldId
                isDeleted
                createdAt
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useAddVoucherMutation = (): {
    addVoucher: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [addVoucherMutation, {data, loading, error}] = useMutation(ADD_VOUCHER);

        const addVoucher = (input) => {
            return addVoucherMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {addVoucher}}) {
                    const typename = addVoucher?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(addVoucher?.errKey),
                            key: "addVoucher",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(addVoucher?.msg),
                            key: "addVoucher",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Add Successfully"),
                            key: "addVoucher",
                        });
                    }
                },
                refetchQueries: [GET_VOUCHER, GET_VOUCHER_COUNT],
            });
        };

        return {addVoucher, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            addVoucher: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const UPDATE_VOUCHER = gql`
    mutation updateVoucher($id: String!, $input: VoucherInput) {
        updateVoucher(id: $id, input: $input) {
            ... on Voucher {
                _id
                id
                displayName {
                    content
                    language
                }
                name
                description {
                    content
                    language
                }
                termAndConditionEnabled
                termAndCondition {
                    content
                    language
                }
                rewardType
                product {
                    id
                    _id
                    name
                }
                voucherType
                voucherValueIsRange
                voucherValue
                voucherValueStartRange
                voucherValueEndRange
                voucherStackable
                stockVoucher {
                    id
                    _id
                    name
                }
                maxAmount
                imageLight {
                    fileList
                    path
                }
                imageDark {
                    fileList
                    path
                }
                defaultImageLight {
                    fileList
                    path
                }
                defaultImageDark {
                    fileList
                    path
                }
                targetReportId
                targetReportFieldId
                isDeleted
                createdAt
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useUpdateVoucherMutation = (): {
    updateVoucher: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const {t} = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updateVoucherMutation, {data, loading, error}] = useMutation(UPDATE_VOUCHER);

        const updateVoucher = (id, input) => {
            return updateVoucherMutation({
                variables: {
                    id: id,
                    input: input,
                },
                update(cache, {data: {updateVoucher}}) {
                    const typename = updateVoucher?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateVoucher?.errKey),
                            key: "updateVoucher",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateVoucher?.msg),
                            key: "updateVoucher",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Successfully"),
                            key: "updateVoucher",
                        });
                    }
                },
                refetchQueries: [GET_VOUCHER, GET_VOUCHER_COUNT],
            });
        };

        return {updateVoucher, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            updateVoucher: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const DELETE_VOUCHER = gql`
    mutation deleteVoucher($id: String!) {
        deleteVoucher(id: $id) {
            ... on Voucher {
                _id
                id
                isDeleted
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useDeleteVoucherMutation = (): {
    deleteVoucher: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [deleteVoucherMutation, {data, loading, error}] = useMutation(DELETE_VOUCHER);

        const deleteVoucher = (id) => {
            return deleteVoucherMutation({
                variables: {
                    id: id,
                },
                update(cache, {data: {deleteVoucher}}) {
                    const typename = deleteVoucher?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(deleteVoucher?.errKey),
                            key: "deleteVoucher",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(deleteVoucher?.msg),
                            key: "deleteVoucher",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Delete Successfully"),
                            key: "deleteVoucher",
                        });
                    }
                },
                refetchQueries: [GET_VOUCHER, GET_VOUCHER_COUNT],
            });
        };

        return {deleteVoucher, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            deleteVoucher: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const APPLY_VOUCHER = gql`
    mutation applyVoucher($id: String!, $input: UserVouchersTargetInput) {
        applyVoucher(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on UserVouchers {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;

async function applyVoucher(id, input) {
    const variables = {
        id: id,
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(APPLY_VOUCHER, variables, true);

    return execute;
}

export {
    getVoucher,
    getVoucherCount,
    getUserVouchers,
    getUserVouchersCount,
    useAddVoucherMutation,
    useUpdateVoucherMutation,
    useDeleteVoucherMutation,
    applyVoucher,
};
