import React, {useState} from "react";
import {useRecoilState} from "recoil";
import RoleTemplate_1 from "./view/layout1/index";
import {getParentPermissionArray} from "../../../api/graphql/roleTemplate";
import {getUserNetworkTreeDownline} from "../../../api/graphql/userNetworkTree";
import {getRoleNetworkTree} from "../../../api/graphql/roleNetworkTree";
import {selfPermissionObj} from "../../../../recoil_state";

function Roles() {
  let layout = 1;
  let component;
  const [targetParent, setTargetParent] = useState("");
  const [networkList, setNetworkList] = useState("");
  const [selfPermission] = useRecoilState<any>(selfPermissionObj);
  const roleTemplatePermission = selfPermission?.userSetting?.roleTemplate;

  const {
    loading: networkTreeLoading,
    error: networkTreeError,
    data: networkTreeData,
  } = getRoleNetworkTree();
  const { permissionArrayData } = getParentPermissionArray(targetParent);
  const { networkTreeDownlineData } = getUserNetworkTreeDownline(networkList);

  switch (layout) {
    case 1:
      component = (
        <RoleTemplate_1
          networkData={networkTreeData?.getRoleNetworkTree?.data}
          parentPermissionArrayData={
            permissionArrayData?.getParentPermissionArray
          }
          networkTreeDownlineData={
            networkTreeDownlineData?.getUserNetworkTreeDownline?.data
          }
          setNetworkList={setNetworkList}
          targetParent={targetParent}
          setTargetParent={setTargetParent}
          roleTemplatePermission={roleTemplatePermission}
        />
      );
      break;

    default:
      component = (
        <RoleTemplate_1
          networkData={networkTreeData?.getRoleNetworkTree?.data}
          parentPermissionArrayData={
            permissionArrayData?.getParentPermissionArray
          }
          networkTreeDownlineData={
            networkTreeDownlineData?.getUserNetworkTreeDownline?.data
          }
          setNetworkList={setNetworkList}
          targetParent={targetParent}
          setTargetParent={setTargetParent}
          roleTemplatePermission={roleTemplatePermission}
        />
      );
      break;
  }
  return component;
}

export default Roles;
