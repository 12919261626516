import React, {useState} from "react";
import {Typography} from "antd";
import {currentEditingElement} from "../../state/state";
import {useRecoilState} from "recoil";
import TextComponent from "./gridItemComponent/textComponent";
import ImageComponent from "./gridItemComponent/imageComponent";
import RankingComponent from "./gridItemComponent/rankingComponent";
import PodiumComponent from "./gridItemComponent/podiumComponent";
import LegendComponent from "./gridItemComponent/legendComponent";

const GridItem = ({data, gridLayout, setDetailData, currentBreakpoint}) => {
    const {Paragraph, Text, Title} = Typography
    const [editing, setEditing] = useRecoilState(currentEditingElement);
    const [menuPosition, setMenuPosition] = useState({x: 0, y: 0});
    const [menuOpen, setMenuOpen] = useState(false);
    let component
    const {setting = {}, ...found} = data?.interfaceDetail?.find(data => data?.layoutId === gridLayout?.i)

    const onChange = (e) => {
        const newData = {...data}?.interfaceDetail.map(item => {
            if (item.layoutId === gridLayout?.i) {
                return {
                    ...item,
                    value: e
                }
            }

            return item
        })

        setDetailData({...data, interfaceDetail: newData})
    }

    const onElementClicked = (e, i) => {
        if (e.detail === 2) {
            setEditing(i)
        }
    }


    switch (found?.type || gridLayout?.i?.split("-")[0]) {
        case "text":
            const props = {data, gridLayout, setting, setDetailData, found}
            component = <TextComponent {...props}/>
            break
        case "image":
            component = <ImageComponent setting={setting}/>
            break
        case "blank":
            component = <div/>
            break
        case "ranking":
            component = <RankingComponent/>
            break
        case "podium":
            component = <PodiumComponent/>
            break
        case "legend":
            component = <LegendComponent setting={setting} currentBreakpoint={currentBreakpoint}/>
            break
        default:
            component = <>{gridLayout?.i}</>
    }

    return (
        <>
            <div className={`grid-item-container h-100 ${editing === gridLayout?.i ? "editing" : ""}`} onClick={(e) => onElementClicked(e, gridLayout?.i)}>
                {component}
            </div>
        </>
    )
}

export default GridItem