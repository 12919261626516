import React, {useEffect, useState} from "react";
import {Button, Row, Space, Tabs} from "antd";
import {useTranslation} from "react-i18next";
import {MdDelete, MdDrafts, MdInbox, MdOutbox} from "react-icons/md";
import Outbox from "../components/outbox";
import Inbox from "../components/inbox";
import Draft from "../components/draft";
import Trash from "../components/trash";
import {
    useGetEmailLog,
    useGetEmailSendDraft, useGetEmailSendDraftArchive,
    useGetEmailSenderDropdown,
    useGetEmailTemplateDropdown,
    useGetPushNotificationDraft, useGetPushNotificationDraftArchive,
    useGetPushNotificationLog,
    useGetPushNotificationTemplateDropdown
} from "../../function/useGetQueryHooks";
import SendMessageModal from "../components/sendMessageModal";
import {useRecoilState} from "recoil";
import {selfPermissionObj} from "../../../../../recoil_state";
import SendPushNotificationModal from "../components/sendPushNotificationModal";
import {getDynamicVariable} from "../../../../api/graphql/dynamicVariable";

const Message_1 = () => {
    const {t} = useTranslation();

    const [modalIsOpen, setModalIsOpen] = useState("");
    const [mode, setMode] = useState("add");
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const [activeTab, setActiveTab] = useState("inbox");
    const messagePermission = selfPermission?.message?.message

    const [emailTemplate, fetchEmailTemplate] = useGetEmailTemplateDropdown()
    const [pushNotificationTemplate, fetchPushNotificationTemplate] = useGetPushNotificationTemplateDropdown()
    const [emailDraftData, emailDraftTotal, fetchEmailDraftData, emailDraftProps, setEmailDraftProps] = useGetEmailSendDraft()
    const [pushNotificationDraftData, pushNotificationDraftTotal, fetchPushNotificationDraftData, pushNotificationDraftProps, setPushNotificationDraftProps] = useGetPushNotificationDraft()
    const [emailDraftDeletedData, emailDraftDeletedTotal, fetchEmailDraftDeletedData, emailDraftDeletedProps, setEmailDraftDeletedProps] = useGetEmailSendDraftArchive()
    const [pushNotificationDraftDeletedData, pushNotificationDraftDeletedTotal, fetchPushNotificationDraftDeletedData, pushNotificationDraftDeletedProps, setPushNotificationDraftDeletedProps] = useGetPushNotificationDraftArchive()
    const [emailLogData, emailLogTotal, emailLogProps, setEmailLogProps, fetchEmailLogData] = useGetEmailLog()
    const [pushNotificationLogData, pushNotificationLogTotal, pushNotificationLogProps, setPushNotificationLogProps, fetchPushNotificationLogData] = useGetPushNotificationLog()
    const [emailType, senderEmail, fetchEmailType] = useGetEmailSenderDropdown()
    const [selectedPushNotificationTemplate, setSelectedPushNotificationTemplate] = useState<any>(null);
    const [draftTemplate, setDraftTemplate] = useState<any>([])
    const [isDraft, setIsDraft] = useState(false)
    const [notificationDataObject, setNotificationDataObject] = useState([]);

    const openEditModal = (record, modalType) => {
        setMode("edit")
        setModalIsOpen(modalType)
        setSelectedRecord(record)
    }

    const {variableData: imageVarData} = getDynamicVariable({
        filter: {variableType: "image", isActive: true},
    });
    const {variableData: textVarData} = getDynamicVariable({
        filter: {variableType: "text", isActive: true},
    });
    const {variableData: pdfVarData} = getDynamicVariable({
        filter: {variableType: "pdf", isActive: true},
    });

    const handleCancel = () => {
        setIsDraft(false)
        setModalIsOpen("")
        setSelectedPushNotificationTemplate(null)
        setDraftTemplate([])
        setNotificationDataObject([])
    }

    const tabItems: any = [
        {
            label: <Space size={"small"}><MdInbox/>{t("message.inbox")}</Space>,
            key: "inbox",
            children: <Inbox/>,
        },
        {
            label: <Space size={"small"}><MdOutbox/>{t("message.outbox")}</Space>,
            key: "outbox",
            children:
                <Outbox
                    emailTemplate={emailTemplate}
                    pushNotificationTemplate={pushNotificationTemplate}
                    emailLogData={emailLogData}
                    emailLogTotal={emailLogTotal}
                    emailLogProps={emailLogProps}
                    setEmailLogProps={setEmailLogProps}
                    pushNotificationLogData={pushNotificationLogData}
                    pushNotificationLogTotal={pushNotificationLogTotal}
                    pushNotificationLogProps={pushNotificationLogProps}
                    setPushNotificationLogProps={setPushNotificationLogProps}
                    imageVarData={imageVarData?.getDynamicVariable?.data}
                    textVarData={textVarData?.getDynamicVariable?.data}
                />,
        },
        {
            label: <Space size={"small"}><MdDrafts/>{t("message.draft")}</Space>,
            key: "draft",
            children:
                <Draft
                    messagePermission={messagePermission}
                    openEditModal={openEditModal}
                    emailTemplate={emailTemplate}
                    isDraft={isDraft}
                    setIsDraft={setIsDraft}

                    fetchEmailDraftDeletedData={fetchEmailDraftDeletedData}
                    emailDraftData={emailDraftData}
                    emailDraftTotal={emailDraftTotal}
                    fetchEmailDraftData={fetchEmailDraftData}
                    emailDraftProps={emailDraftProps}
                    setEmailDraftProps={setEmailDraftProps}

                    fetchPushNotificationDraftDeletedData={fetchPushNotificationDraftDeletedData}
                    pushNotificationDraftData={pushNotificationDraftData}
                    pushNotificationDraftTotal={pushNotificationDraftTotal}
                    fetchPushNotificationDraftData={fetchPushNotificationDraftData}
                    pushNotificationDraftProps={pushNotificationDraftProps}
                    setPushNotificationDraftProps={setPushNotificationDraftProps}
                />,
        },
        (messagePermission?.delete && {
            label: <Space size={"small"}><MdDelete/>{t("message.trash")}</Space>,
            key: "trash",
            children:
                <Trash
                    messagePermission={messagePermission}
                    emailDraftDeletedData={emailDraftDeletedData}
                    emailDraftDeletedProps={emailDraftDeletedProps}
                    setEmailDraftDeletedProps={setEmailDraftDeletedProps}
                    emailDraftDeletedTotal={emailDraftDeletedTotal}
                    emailTemplate={emailTemplate}
                    fetchEmailDraftDeletedData={fetchEmailDraftDeletedData}
                    pushNotificationDraftDeletedTotal={pushNotificationDraftDeletedTotal}
                    pushNotificationDraftDeletedProps={pushNotificationDraftDeletedProps}
                    setPushNotificationDraftDeletedProps={setPushNotificationDraftDeletedProps}
                    pushNotificationDraftDeletedData={pushNotificationDraftDeletedData}
                    pushNotificationTemplate={pushNotificationTemplate}
                    fetchPushNotificationDraftDeletedData={fetchPushNotificationDraftDeletedData}
                />,
        })
    ]?.filter(d => d)

    useEffect(() => {
        fetchEmailTemplate()
        fetchPushNotificationTemplate()
        fetchEmailType()
    }, []);

    useEffect(() => {
        fetchPushNotificationLogData()
    }, [pushNotificationLogProps]);

    useEffect(() => {
        fetchPushNotificationDraftData()
    }, [pushNotificationDraftProps]);

    useEffect(() => {
        fetchEmailLogData()
    }, [emailLogProps]);

    useEffect(() => {
        fetchEmailDraftData()
    }, [emailDraftProps]);

    useEffect(() => {
        fetchEmailDraftDeletedData()
    }, [emailDraftDeletedProps]);

    useEffect(() => {
        fetchPushNotificationDraftDeletedData()
    }, [pushNotificationDraftDeletedProps]);

    return (
        <div id={"messages"}>
            <Row justify={"space-between"}>
                <h1>{t("module.message")}</h1>
                <Space>
                    {messagePermission?.send && (
                        <Button
                            type={"primary"}
                            onClick={() => {
                                setModalIsOpen("message")
                                setMode("add")
                            }}
                        >
                            {t("message.send_message")}
                        </Button>
                    )}
                    {messagePermission?.send && (
                        <Button
                            type={"primary"}
                            onClick={() => {
                                setModalIsOpen("pushNotification")
                                setMode("add")
                            }}
                        >
                            {t("message.send_push_notification")}
                        </Button>
                    )}
                </Space>

            </Row>
            <div className="messages-tab-container">
                <Tabs
                    tabPosition={"left"}
                    items={tabItems}
                    activeKey={activeTab}
                    onTabClick={(key) => setActiveTab(key)}
                    className={"messages-tab"}
                />
            </div>
            <div style={{height: "20px"}}/>
            {messagePermission?.send && (
                <SendMessageModal
                    isOpen={modalIsOpen}
                    handleCancel={handleCancel}
                    emailTemplate={emailTemplate}
                    mode={mode}
                    selectedRecord={selectedRecord}
                    fetchEmailLogData={fetchEmailLogData}
                    fetchEmailDraftData={fetchEmailDraftData}
                    fetchEmailTemplate={fetchEmailTemplate}
                    emailTypeDropdown={emailType}
                    senderEmailDropdown={senderEmail}
                    setActiveTab={setActiveTab}
                />
            )}
            {messagePermission?.send && (
                <SendPushNotificationModal
                    isOpen={modalIsOpen}
                    handleCancel={handleCancel}
                    pushNotificationTemplate={pushNotificationTemplate}
                    mode={mode}
                    selectedRecord={selectedRecord}
                    fetchPushNotificationLogData={fetchPushNotificationLogData}
                    fetchPushNotificationDraftData={fetchPushNotificationDraftData}
                    fetchPushNotificationTemplate={fetchPushNotificationTemplate}
                    setActiveTab={setActiveTab}
                    selectedPushNotificationTemplate={selectedPushNotificationTemplate}
                    setSelectedPushNotificationTemplate={setSelectedPushNotificationTemplate}
                    draftTemplate={draftTemplate}
                    setDraftTemplate={setDraftTemplate}
                    isDraft={isDraft}
                    imageVarData={imageVarData?.getDynamicVariable?.data}
                    textVarData={textVarData?.getDynamicVariable?.data}
                    pdfVarData={pdfVarData?.getDynamicVariable?.data}
                    notificationDataObject={notificationDataObject}
                    setNotificationDataObject={setNotificationDataObject}
                />
            )}
        </div>
    )
}

export default Message_1