import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Col, Row} from "antd";
import {getDataWithLanguage, handleOnChangeWithLanguage,} from "../../../function/common";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import {SaveOutlined} from "@ant-design/icons";

const CompanyInfoForm = (props) => {
  const { languageCode, data, setData, isEditable, onBrandDetailsSubmit, submitLoading } = props;
  const { t } = useTranslation();

  const handleOnchange = (e, fieldName, fieldType = "content") => {
    handleOnChangeWithLanguage(e, fieldName, fieldType, languageCode, setData);
  };

  return (
    <>
      <Row gutter={[16, 16]} style={{ marginTop: "30px" }}>
        <Col xs={24} md={12}>
          <InputFormItem
            name={`aboutPage_${languageCode}`}
            label={t("brandSetting.companyWebsiteAboutPage")}
            placeholder={t("brandSetting.companyWebsiteAboutPage")}
            value={getDataWithLanguage(data, "aboutPage", "link", languageCode)}
            onChange={(e) => handleOnchange(e, "aboutPage", "link")}
            prefix={data?.defaultDomain || "https://"}
            disabled={!isEditable}
          />
        </Col>
        <Col xs={24} md={12}>
          <InputFormItem
            name={`supportPage_${languageCode}`}
            label={t("brandSetting.companyWebsiteSupportPage")}
            placeholder={t("brandSetting.companyWebsiteSupportPage")}
            value={getDataWithLanguage(
              data,
              "supportPage",
              "link",
              languageCode
            )}
            onChange={(e) => handleOnchange(e, "supportPage", "link")}
            prefix={data?.defaultDomain || "https://"}
            disabled={!isEditable}
          />
        </Col>
      </Row>
      <Row className={"margin-top-0-75"} justify={"end"}>
        {isEditable &&
        <Button
            onClick={onBrandDetailsSubmit}
            icon={<SaveOutlined/>}
            loading={submitLoading}
            type={"primary"}
        >
          {t("common.update")}
        </Button>
        }
      </Row>
    </>
  );
};

export default CompanyInfoForm;
