import {initializeApp} from "firebase/app"
import {getMessaging} from "firebase/messaging"
import {checkBrowserFirebaseCompatibility} from "../shared/function/_common"

let messaging: any = null
async function initializeFirebase(firebaseConfig) {
    try {
        if (Object.keys(firebaseConfig).length > 0 && checkBrowserFirebaseCompatibility()) {
            const app = initializeApp(firebaseConfig)
            messaging = getMessaging(app)
        } else {
            console.log("Firebase config is missing, empty, or browser doesn't support Firebase Messaging.")
        }
    } catch (error) {
    }
}

export {initializeFirebase, messaging}
