import React, {useState} from "react";
import {Button, Form, Modal, Row, Upload} from "antd";
import {useTranslation} from "react-i18next";
import {convertCsvToArray} from "../../../function/common";
import * as XLSX from "xlsx";
import {UploadOutlined} from "@ant-design/icons";

const ExcelMappingModalModal_1 = (props) => {
    const {
        isOpen,
        closeModal,
        server,
    } = props
    const { t } = useTranslation()

    const [excelFileValue, setExcelFileValue] = useState<any>([])
    const [fileList, setFileList] = useState<any>([])
    const onConfirm = () => {
        console.log("confirm")
    }

    return (
        <Modal
            title={(
                <Row>
                    <p>{t("Symbol Mapping with Excel")}</p>
                    <Button className={"margin-left-auto"}
                            type={"primary"}
                            onClick={onConfirm}>
                        {t("Confirm")}
                    </Button>
                </Row>
            )}
            open={isOpen}
            onCancel={() => {
                closeModal()
            }}
            footer={null}
            width={500}
            destroyOnClose
        >
            <Form>
                {/* <SelectFormItem
                    name={"server"}
                    label={t("Server")}
                    placeholder={t("Select a server")}
                    options={server.map(item => {return {id: item.id, label: item.name}})}
                    mode={"single"}
                    required
                /> */}
                <Upload
                    className="margin-right-0-75"
                    accept=".xlsx, .csv"
                    fileList={excelFileValue}
                    beforeUpload={file => {
                        const [fileExtension] = file?.name?.split(".")?.reverse()
                        const reader = new FileReader();

                        if(fileExtension === "csv"){
                            reader.onload = e => {
                                setExcelFileValue(convertCsvToArray(e?.target?.result));
                            };
                            reader.readAsText(file);
                        }
                        else if (fileExtension === "xlsx"){
                            reader.onload = e => {
                            const workbook = XLSX.read(e?.target?.result, { type: "binary" });
                            for (const Sheet in workbook.Sheets) {
                                var data: any =  XLSX.utils.sheet_to_json(workbook?.Sheets[Sheet]);
                                var result = data?.map((item, index) => {
                                    return {
                                        ...item,
                                    }
                                })
                                setExcelFileValue(result)
                            }
                        };
                        reader.readAsBinaryString(file);
                        }
                        setFileList([...fileList, file])
                        // console.log(excelFileValue,"excel")
                        // console.log(fileList, "fileList")

                        // Prevent upload
                        return false;
                    }}
                    onRemove={(file) => {
                        const index = fileList?.indexOf(file);
                        const newFileList = fileList?.slice();
                        newFileList?.splice(index, 1);
                        setFileList(newFileList);
                        setExcelFileValue([])
                      }}
                >
                    <Button icon={<UploadOutlined />}>
                        {t('Select File')}
                    </Button>
                </Upload>
                
            </Form>
        </Modal>
    );
};

export default ExcelMappingModalModal_1;
