import React, {useEffect, useMemo, useState} from "react";
import {Button, Col, DatePicker, Form, message, Modal, Row, Select, Space} from "antd";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {useAddUserMutation} from "../../../../../api/graphql/user";
import {
    formatProfileInformation,
    getReferralDeskDropdownFunction,
    getUserByIdFunction,
    renderProfileInformation,
    validateConfirmKeys,
} from "../../../_common/function";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {deskOptionsState, rankOptionsState, roleOptionsState, userOptionsState} from "../../../_common/state";
import useAuthorize from "../../../../../_common/authorize";
import {fieldListState, notificationMessage} from "../../../../../../recoil_state";
import dayjs from "dayjs";
import {getRoleTemplateDropdownBasedOnPermission, getUserDropdown} from "../../../../../api/graphql/_common";
import {formatDateUTC, formatUserOptions} from "../../../../../function/_common";
import {useSetDropdown} from "../../../../../_common/dropdowns";

const CrmUserAddForm = (props) => {
    const {isOpen, close, profileInformation, permission, refetchUserData} = props;
    const {t} = useTranslation();
    const [form] = Form.useForm();

    const [userOptions, setUserOptions] = useRecoilState<any>(userOptionsState);
    const roleOptions = useRecoilValue<any>(roleOptionsState);
    const rankOptions = useRecoilValue<any>(rankOptionsState);
    const deskOptions = useRecoilValue<any>(deskOptionsState);
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [roleTemplateOptions, setRoleTemplateOptions] = useState<any>([])
    const {data: roleTemplateData} = getRoleTemplateDropdownBasedOnPermission({permission: "user:crmUser:edit:role"})
    const {setUpUserOptions} = useSetDropdown()

    const rankOptionsCrmUser = useMemo(() => {
        return rankOptions?.filter((v) => v?.type === "crmUser");
    }, [rankOptions]);

    const [referralDeskDropdown, setReferralDeskDropdown] = useState([]);
    const [startDate, setStartDate] = useState<any>();
    const [endDate, setEndDate] = useState<any>();

    const [referrerId, setReferrerId] = useState("");

    const {addUser, loading} = useAddUserMutation();
    const {baseErrorChecking, systemErrorNotification} = useAuthorize();
    const fieldList = useRecoilValue<any>(fieldListState)

    const [fields, setFields] = useState<any>({
        basicField: [],
        loginField: [],
        residenceField: [],
        additionalField: [],
    });
    const {basicField, loginField, residenceField, additionalField} = fields;

    useEffect(() => {
        setEndDate(null);
        setStartDate(null);
        setReferrerId("");
        refreshUserOptions();
    }, [isOpen]);

    useEffect(()=> {
        if(roleTemplateData?.getRoleTemplateDropdownBasedOnPermission?.data?.length > 0){
            setRoleTemplateOptions(roleTemplateData?.getRoleTemplateDropdownBasedOnPermission?.data?.map(item => {
                return {
                    label: item?.name,
                    value: item?._id
                }
            }))
        }

    }, [roleTemplateData])

    const refreshUserOptions = async () => {
        const response: any = await getUserDropdown(localStorage.getItem("g_userId") || localStorage.getItem("userId"));
        setUserOptions(response?.getUserDropdown?.data?.map((d) => formatUserOptions(d)));
    };

    const getReferrerDetail = async () => {
        const response = await getUserByIdFunction(referrerId);
        const desk = await getReferralDeskDropdownFunction(referrerId);
        setReferralDeskDropdown(desk?.getDeskDropdown?.data?.map((d) => ({label: d?.name, value: d?._id})));

        if (response?.getUserByID?.rank?._id && rankOptionsCrmUser?.find((d) => d?.value === response?.getUserByID?.rank?._id)) {
            form.setFieldValue("rank", response?.getUserByID?.rank?._id);
        } else {
            form.setFieldValue("rank", rankOptionsCrmUser?.find((d) => d?.isDefault)?.value);
        }

        if (response?.getUserByID?.desk?._id) {
            form.setFieldValue("desk", response?.getUserByID?.desk?._id);
        } else {
            form.setFieldValue("desk", null);
        }
    };

    useEffect(() => {
        if (referrerId) {
            getReferrerDetail();
        }
    }, [referrerId]);

    useEffect(() => {
        if (isOpen) {
            form.resetFields();
            form.setFieldValue("rank", rankOptionsCrmUser?.find((d) => d?.isDefault)?.value);
            form.setFieldValue("desk", null);
            form.setFieldValue("role", [roleOptions?.find((d) => d?.isDefault)?.value]);
        }
    }, [isOpen]);

    const onSubmit = async (value) => {
        try {
            const formattedValue = await {
                ...value,
                mobileNo: value?.mobileNo?.toString(),
                loginStartDate: value?.loginStartDate ? new Date(value?.loginStartDate).toISOString() : undefined,
                loginEndDate: value?.loginEndDate ? new Date(value?.loginEndDate).toISOString() : undefined,
                dateOfBirth: value?.dateOfBirth ? new Date(value?.dateOfBirth).toISOString() : undefined,
            };

            const nonUpdatableFields = ["tag", "leadSource", "age", "affiliateCode"];
            await Object.keys(formattedValue).forEach((key) => {
                const match = nonUpdatableFields?.find((d) => d === key);
                if (formattedValue[key] === undefined || formattedValue[key] === null || match) {
                    delete formattedValue[key];
                }
            });

            const validated = await validateConfirmKeys(formattedValue, setNotificationMessage, t);

            if (validated) {
                delete formattedValue["confirmPassword"];
                delete formattedValue["combinedMobileNo"]
                value["dateOfBirth"] = value["dateOfBirth"] ? formatDateUTC(value["dateOfBirth"]) : null;
                let response = await addUser({
                    ...formattedValue,
                    userType: "crmUser",
                });

                if (response?.data?.addUser?.__typename === "BaseError") {
                    if (response?.data?.addUser?.errKey === "admin.err.user_exist") {
                        response?.data?.addUser?.errObj.forEach((item) => {
                            form.setFields([
                                {
                                    name: item === "mobileNo" ? "combinedMobileNo" : item,
                                    errors: [t("errMsg.fieldAlreadyExisted", {field: t(`user.${item}`)})],
                                },
                            ]);
                        });
                    } else {
                        return await baseErrorChecking(response?.data?.addUser);
                    }
                } else {
                    if (response?.data?.addUser?.__typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(response?.data?.addUser?.msg),
                            key: "addUserMsg",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("successMsg.addUserSuccessfully"),
                            key: "addUserMsg",
                        });
                    }

                    setUpUserOptions();
                    refetchUserData()

                    form.resetFields();
                    close();
                }
            }
        } catch (e) {
            console.log(e);
            systemErrorNotification();
        }
    };

    useEffect(() => {
        try {
            formatProfileInformation(fields, setFields, profileInformation);
        } catch (e) {
            console.log(e);
            systemErrorNotification();
        }
    }, [profileInformation]);

    const validateMessages = {
        required: t("errorMsg.required.requiredField", {field: "${label}"}),
    };

    return (
        <Modal
            title={t(`crmUser.addCRMUser`)}
            open={isOpen}
            footer={null}
            width={800}
            onCancel={() => {
                form.resetFields();
                close();
            }}
        >
            <Form onFinish={onSubmit} form={form} validateMessages={validateMessages}>
                <Row gutter={[16, 16]} className={"margin-top-0-75 margin-bottom-0-75"}>
                    {basicField?.length > 0 && (
                        <Col span={24}>
                            <h4>{t("field.category.basicInformation")}</h4>
                            <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                                {renderProfileInformation(basicField, {}, t, false)}
                                <Col span={12}>
                                    <Form.Item
                                        name={"loginStartDate"}
                                        label={t("user.startDate")}
                                        rules={[{required: true}]}
                                        labelCol={{span: 10, offset: 0}}
                                        labelAlign="left"
                                    >
                                        <DatePicker
                                            style={{width: "100%"}}
                                            placeholder={""}
                                            disabledDate={(current) => {
                                                return endDate ? current.isAfter(dayjs(endDate).startOf("day")) : false;
                                            }}
                                            onChange={(value) => {
                                                setStartDate(value);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name={"loginEndDate"} label={t("user.endDate")} labelCol={{span: 10, offset: 0}} labelAlign="left">
                                        <DatePicker
                                            style={{width: "100%"}}
                                            placeholder={""}
                                            disabledDate={(current) => {
                                                return startDate ? current.isBefore(dayjs(startDate).startOf("day")) : false;
                                            }}
                                            onChange={(value) => {
                                                setEndDate(value);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={"referrer"}
                                        label={t("user.referrer")}
                                        rules={[{required: true}]}
                                        labelCol={{span: 10, offset: 0}}
                                        labelAlign="left"
                                    >
                                        <Select
                                            options={userOptions}
                                            optionFilterProp={"label"}
                                            optionLabelProp="selectedLabel"
                                            allowClear
                                            showSearch
                                            onSelect={(value) => setReferrerId(value)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name={"role"} label={t("user.roles")} labelCol={{span: 10, offset: 0}} labelAlign="left">
                                        <Select
                                            mode={"multiple"}
                                            options={roleTemplateOptions}
                                            allowClear
                                            showSearch
                                            optionFilterProp={"label"}
                                            disabled={!permission?.edit?.role}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={"desk"}
                                        label={t("user.desk")}
                                        labelCol={{span: 10, offset: 0}}
                                        rules={[{required: true}]}
                                        labelAlign="left"
                                    >
                                        <Select
                                            options={referralDeskDropdown?.length > 0 ? referralDeskDropdown : deskOptions}
                                            allowClear
                                            showSearch
                                            optionFilterProp={"label"}
                                            disabled={!permission?.edit?.desk}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={"rank"}
                                        label={t("user.rank")}
                                        rules={[{required: true}]}
                                        labelCol={{span: 10, offset: 0}}
                                        labelAlign="left"
                                    >
                                        <Select
                                            options={rankOptionsCrmUser}
                                            allowClear
                                            showSearch
                                            optionFilterProp={"label"}
                                            disabled={!permission?.edit?.rank}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={"comment"}
                                        label={t("user.comment")}
                                        labelCol={{span: 10, offset: 0}}
                                        labelAlign={"left"}
                                    >
                                        <Select
                                            allowClear
                                            showSearch
                                            options={fieldList?.find(d => d?.fieldName === "crmUserComment")?.optionValue?.map(d => ({...d, label: t(d?.label)}))}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    )}
                    {loginField?.length > 0 && (
                        <Col span={24}>
                            <h4>{t("field.category.loginInformation")}</h4>
                            <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                                {renderProfileInformation(loginField, {}, t, false, true, form)}
                            </Row>
                        </Col>
                    )}
                    {residenceField?.length > 0 && (
                        <Col span={24}>
                            <h4>{t("field.category.residenceInformation")}</h4>
                            <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                                {renderProfileInformation(residenceField, {}, t, false)}
                            </Row>
                        </Col>
                    )}
                    {additionalField?.length > 0 && (
                        <Col span={24}>
                            <h4>{t("field.category.additionalInformation")}</h4>
                            <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                                {renderProfileInformation(additionalField, {}, t, false)}
                            </Row>
                        </Col>
                    )}
                </Row>
                <Row>
                    <Form.Item className={"margin-left-auto"}>
                        <Space>
                            <Button onClick={close}>{t("common.cancel")}</Button>
                            <Button type={"primary"} loading={loading} onClick={() => form.submit()}>
                                {t("common.add")}
                            </Button>
                        </Space>
                    </Form.Item>
                </Row>
            </Form>
        </Modal>
    );
};

export default CrmUserAddForm;
