import React from "react";
import {getColumnSearchProps} from "../../../../components/table/function/filterTable";

export const columnsInvestor = [
    {
        title: "Platform",
        dataIndex: "platform",
        key: "platform",
        ...getColumnSearchProps(),
        render: (text, record) => <p>{text === "mt5" ? "MT5" : text === "xTrader" ? record?.product?.server?.platformDisplayName : text || "-"}</p>,
    },
    {
        title: "Money Manager",
        dataIndex: "moneyManager",
        key: "moneyManager",
        render: (_, record) => {
            let displayText = record?.moneyManager?.account?.user?.username;
            let id = record?.moneyManager?.account?.user?._id;
            return <a onClick={() => window.open("/users/client/detail?id=" + id, "_blank")}>{displayText}</a>;
        },
    },
    {
        title: "Money Manager Account",
        dataIndex: "moneyManagerAccount",
        key: "moneyManagerAccount",
        render: (_, record) => {
            let displayText = record?.moneyManager?.account?.accountId;
            let id = record?.moneyManager?.account?._id;
            return <a onClick={() => window.open("/account/trading-account/detail?id=" + id, "_blank")}>{displayText}</a>;
        },
    },
    {
        title: "Investor",
        dataIndex: "user",
        key: "user",
        ...getColumnSearchProps(),
        render: (text, record) => {
            let displayText = text?.username;
            let id = text?._id;
            return <a onClick={() => window.open("/users/client/detail?id=" + id, "_blank")}>{displayText}</a>;
        },
    },
    {
        title: "Investor Account",
        dataIndex: "accountId",
        key: "accountId",
        ...getColumnSearchProps(),
        render: (text, record) => {
            let displayText = text;
            let id = record?._id;
            return <a onClick={() => window.open("/account/trading-account/detail?id=" + id, "_blank")}>{displayText}</a>;
        },
    },
    {
        title: "Product",
        dataIndex: "product",
        key: "product",
        ...getColumnSearchProps(),
        render: (text) => <p>{text?.name}</p>,
    },
    {
        title: "Spread Group",
        dataIndex: "spreadGroup",
        key: "spreadGroup",
        // ...getColumnSearchProps(),
        render: (_, record) => <p>{record?.platform === "mt5" ? record?.mt5SpreadGroup?.group : record?.spreadGroup?.name}</p>,
    },
    {
        title: "Leverage",
        dataIndex: "leverage",
        key: "leverage",
        // ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Balance",
        dataIndex: "balance",
        key: "balance",
        // ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Credit",
        dataIndex: "creditInUsd",
        key: "creditInUsd",
        // ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Equity",
        dataIndex: "equity",
        key: "equity",
        // ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Stop Loss",
        dataIndex: "investorAccountSetting",
        key: "stopLoss",
        // ...getColumnSearchProps(),
        render: (text) => <p>{text?.easyMAM?.stopLoss || "-"}</p>,
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        ...getColumnSearchProps(),
        render: (_, record) => <p>{record?.investorAccountSetting?.status}</p>,
    },
];
