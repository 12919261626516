import React, {useEffect, useState} from "react";
import {Col, Row, Spin} from "antd";
import {useTranslation} from "react-i18next";
import {getUserByIdAsync} from "../../../../../../../api/graphql/user";
import TaskClientDetails from "../../clientDetails";
import {formatMoney} from "../../../../../function";
import AccountDetailCard from "../../../../../../accounts/tradingAccount/detail/view/layout1/component/accountDetailCard";
import {getTradingAccountMt5Detail} from "../../../../../../../api/graphql/tradingAccountMt5";
import {formatDateTimeTimezone} from "../../../../../../../function/_common";

function TradingAccountMt5CreditLayout_1(props) {
    const {data, mode} = props

    const {t} = useTranslation()

    const [accDetails, setAccDetails] = useState<any>({});
    const [userDetails, setUserDetails] = useState<any>({});

    const getTradingAccountMt5Details = async () => {

        if (data?.payload?.input?.account) {
            const tradingAccountDetail = await getTradingAccountMt5Detail(data?.payload?.input?.account)
            setAccDetails(tradingAccountDetail?.getTradingAccountDetail)

            const userDetail = await getUserByIdAsync(tradingAccountDetail?.getTradingAccountDetail?.user?._id)

            setUserDetails(userDetail?.getUserByID)
        }

    }

    useEffect(() => {
        getTradingAccountMt5Details()
    }, [data])

    return (
        <div id={"tradingAccountLayout"}>
            <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                    <Spin spinning={!accDetails._id}>
                        <AccountDetailCard data={accDetails}
                                           traderId={accDetails.traderId}
                                           viewOnly={true}/>
                    </Spin>
                    <br/>
                    {
                        userDetails && (
                            <TaskClientDetails data={userDetails}/>
                        )
                    }
                </Col>

                <Col xs={24} md={12}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <h3>{t("task.account_credit_details")}</h3>
                        </Col>
                        <Col span={6}>
                            {t("task.account_id")}:
                        </Col>
                        <Col span={18}>
                            {accDetails.accountId}
                        </Col>
                        <Col span={6}>
                            {t("task.account_user")}:
                        </Col>
                        <Col span={18}>
                            {accDetails.user?.username}
                        </Col>
                        <Col span={6}>
                            {t("task.amount")}:
                        </Col>
                        <Col span={18}>
                            {formatMoney(data?.payload?.input?.amount)}
                        </Col>
                        <Col span={6}>
                            {t("task.type")}:
                        </Col>
                        <Col span={18}>
                            {data?.payload?.input?.type === "in" ? t("task.credit_in") : t("task.credit_out")}
                        </Col>
                        <Col span={6}>
                            {t("task.comment")}:
                        </Col>
                        <Col span={18}>
                            {data?.payload?.input?.comment || "-"}
                        </Col>
                        <Col span={6}>
                            {t("task.created_time")}:
                        </Col>
                        <Col span={18}>
                            {formatDateTimeTimezone(data?.createdAt)}
                        </Col>
                        <Col span={6}>
                            {t("task.requested_by")}:
                        </Col>
                        <Col span={18}>
                            {data?.requestedBy?.username}
                        </Col>
                    </Row>
                </Col>

            </Row>


        </div>
    )

}

export default TradingAccountMt5CreditLayout_1