import React, {useEffect, useState} from "react";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {Button, Col, Form, Row, Tabs} from "antd";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import yupFormSchema from "../../../../../components/yup/yupFormSchema";
import { useAddMissionGroupMutation, useUpdateMissionGroupMutation } from "../../../../../api/graphql/missionGroup";
import useAuthorize from "../../../../../_common/authorize";
import InputFormItem from "../../../../../components/form/inputFormItem";
import { verticalFormLayout } from "../../../../../components/form/formWrapper";
import { clean_value, getDataWithLanguage, handleOnChangeWithLanguage } from "../function/common";
import UploadSingleFormItem from "../../../../../components/form/uploadSingleFormItem";
import { notificationMessage } from "../../../../../../recoil_state";
import { useSetRecoilState } from "recoil";
import { getConfigByName } from "../../../../../../config";
import { BRAND_NAME } from "../../../../../../config/brandVariable";


const MissionGroupForm = (props) => {
    const {closeFormModal, item, mode, languageData } = props;

    let backendURL = getConfigByName(BRAND_NAME).backendURL;
    const getDefaultLogoUrl = () => {
        return {
            fileList: [{url: `${backendURL}/assets/icon/activitiesRewards/mission-reward.png`, response: [{link: `${backendURL}/assets/icon/activitiesRewards/mission-reward.png`}]}],
            path: `${backendURL}/assets/icon/activitiesRewards/mission-reward.png`
        }
    };

    const {t} = useTranslation();
    const [errMsg, setErrMsg] = useState("");
    const [activeTab, setActiveTab] = useState("0");
    const [languageObj, setLanguageObj] = useState<any>({
        displayName: item?.displayName || [],
        description: item?.description || [],
        termAndCondition: item?.termAndCondition || [],
    })
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [imageLight, setImageLight] = useState(item?.imageLight? item?.imageLight[0] : getDefaultLogoUrl())
    const [imageDark, setImageDark] = useState(item?.imageDark? item?.imageDark[0] : getDefaultLogoUrl())

    const [initialValues] = useState(() => ({
        displayName: item?.displayName || [],
        name: item?.name || "",
        description: item?.description || [],
    }));

    let schemaObj: any = {
        name: yupFormSchema.string("name", {required: true})
    };

    const missionGroupSchema = yup.object().shape(schemaObj);

    const form = useForm({
        resolver: yupResolver(missionGroupSchema),
        mode: "all",
        defaultValues: initialValues,
    });

    const onCancel = () => {
        closeFormModal && closeFormModal();
    };

    const handleOnchange = (e, fieldName, fieldType = "content", languageCode) => {
        handleOnChangeWithLanguage(e, fieldName, fieldType, languageCode, setLanguageObj);
    };

    const {addMissionGroup} = useAddMissionGroupMutation();
    const {updateMissionGroup} = useUpdateMissionGroupMutation();
    const {systemErrorNotification} = useAuthorize();
    
    const uploadOnChange = (e, field) => {
        try {
        if (e?.file?.status === "uploading") {
            switch(field){
                case "light":
                    setImageLight(prev => {
                        return {
                            ...prev,
                            fileList: e?.fileList
                        }
                    })
                    break;
                case "dark":
                    setImageDark(prev => {
                        return {
                            ...prev,
                            fileList: e?.fileList
                        }
                    })
                    break;
            }
        }
        if (e?.file?.status === "done") {
            switch(field){
                case "light":
                    setImageLight(prev => {
                        return {
                            ...prev,
                            fileList: e?.fileList,
                            path: e?.file?.response[0]?.link
                        }
                    })
                    break;
                case "dark":
                    setImageDark(prev => {
                        return {
                            ...prev,
                            fileList: e?.fileList,
                            path: e?.file?.response[0]?.link
                        }
                    })
                    break;
            }

            setNotificationMessage({
            status: "success",
            title: "",
            msg: `${e?.file?.name} file uploaded successfully`,
            key: "updateDesk",
            });
        } else if (e?.file?.status === "error") {
            switch(field){
                case "light":
                    setImageLight(null)
                    break;
                case "dark":
                    setImageDark(null)
                    break;
            }

            setNotificationMessage({
            status: "error",
            title: "",
            msg: `${e?.file?.name} file upload failed. - ${e?.file?.response}`,
            key: "updateDesk",
            });
        }
        } catch (e) {
        systemErrorNotification();
        }
    };

    const onSubmit = async (values) => {
        try {
            setErrMsg("");
            const cleanValue = clean_value(values)
            const result = {
                ...cleanValue,
                displayName: languageObj?.displayName || [],
                description: languageObj?.description || [],
                imageLight: imageLight,
                imageDark: imageDark
            };

            const response = mode === "add" ? await addMissionGroup(result) : await updateMissionGroup(item?._id, result);
            const {data} = response;
            const res = mode === "add" ? data?.addMissionGroup : data?.updateMissionGroup;
            if (res?.__typename === "BaseError") {
                let errMsg = t(res?.errKey);
                setErrMsg(errMsg);
            } else {
                closeFormModal && closeFormModal();
            }
        } catch (e: any) {
            systemErrorNotification();
        }
    };

    const tabItems = languageData?.map((lang, index) => {
        return {
            key: index?.toString(),
            label: lang?.name,
            children: (
                <Row gutter={16}>
                    <Col xs={24} md={12}>
                        <Col className="gutter-row" xs={24}>
                            <InputFormItem
                                name={`dislayName_${lang.name}`}
                                label={"Display Name"}
                                placeholder={"Display Name"}
                                layout={verticalFormLayout}
                                onChange={(e)=> handleOnchange(e, "displayName", "content", lang.code)}
                                value={getDataWithLanguage(item, "displayName", "content", lang.code)}
                                disabled={mode === "view"}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24}>
                            <InputFormItem
                                name={`description_${lang.name}`}
                                label={"Description"}
                                placeholder={"Description"}
                                layout={verticalFormLayout}
                                onChange={(e)=> handleOnchange(e, "description", "content", lang.code)}
                                value={getDataWithLanguage(item, "description", "content", lang.code)}
                                disabled={mode === "view"}
                            />
                        </Col>
                    </Col>
                </Row>
            ),
        };
    });

    return (
        <div>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="missionGroup-form-1">
                    {errMsg && <p style={{color: "red"}}>{errMsg}</p>}
                    <Row gutter={16}>
                        <Col className="gutter-row" xs={24} md={12}>
                            <UploadSingleFormItem
                                name={`imageLight`}
                                label={t("Image Light")}
                                layout={verticalFormLayout}
                                listType={"picture-card"}
                                mode={"activities"}
                                fileList={imageLight?.fileList}
                                onChange={(e) =>
                                    uploadOnChange(e, "light")
                                }
                                onRemove={(e) =>
                                    setImageLight(null)
                                }
                                disabled={mode === "view"}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} md={12}>
                            <UploadSingleFormItem
                                name={`imageDark`}
                                label={t("Light Dark")}
                                layout={verticalFormLayout}
                                listType={"picture-card"}
                                mode={"activities"}
                                fileList={imageDark?.fileList}
                                onChange={(e) =>
                                    uploadOnChange(e, "dark")
                                }
                                onRemove={(e) =>
                                    setImageDark(null)
                                }
                                disabled={mode === "view"}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} md={12}>
                            <InputFormItem
                                name={"name"}
                                label={"Mission Group Name"}
                                placeholder={"Mission Group Name"}
                                layout={verticalFormLayout}
                                disabled={mode === "view"}
                                required
                            />
                        </Col>
                    </Row>
                    <Tabs
                        defaultActiveKey="0"
                        items={tabItems}
                        onChange={(activeKey) => setActiveTab(activeKey)}
                        activeKey={activeTab}
                    />
                    <Form.Item className="text-align-right" style={{paddingTop: "12px"}}>
                        {onCancel && (
                            <Button onClick={onCancel} icon={<CloseOutlined />} className="button-cancel-1">
                                {mode === "view" ? t("Close") : t("common.cancel")}
                            </Button>
                        )}
                        {mode !== "view" && (
                            <Button onClick={form.handleSubmit(onSubmit)} icon={<SaveOutlined />} className="button-submit-1">
                                {t("common.submit")}
                            </Button>
                        )}
                    </Form.Item>
                </form>
            </FormProvider>
        </div>
    );
};

export default MissionGroupForm;
