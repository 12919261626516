import {gql, useMutation, useQuery} from '@apollo/client'
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {useTranslation} from "react-i18next";
import {ApolloClientQuery} from "./_apolloClient";

const GET_SALES_OPPORTUNITY = gql`
    query getSalesOpportunity($filter: SalesOpportunityFilter, $limit: Int, $offset: Int, $orderBy: String, $startDate: String, $endDate: String, $dateKey: String) {
        getSalesOpportunity(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy, startDate: $startDate, endDate: $endDate, dateKey: $dateKey) {
            ... on SalesOpportunityAry {
                data {
                    _id
                    lead {
                        _id
                        name
                        fullName
                        mobileNo
                        email
                        salesRep {fullName username}
                        latestFollowUp {
                            followUpDateTime
                            lastFollowUp
                        }
                        previousFollowUp {
                            followUpDateTime
                        }
                    }
                    client {
                        _id
                        fullName
                        username
                        email
                        mobileNo
                        salesRep {fullName username}
                        latestFollowUp {
                            followUpDateTime
                        }
                        previousFollowUp {
                            followUpDateTime
                        }
                    }
                    salesOppStatus {_id displayName}
                    attachment
                    salesOpportunityId
                    salesOpportunityName
                    userType
                    status
                    amount
                    date
                    remark
                    isArchived
                    isReviewed
                    createdAt
                    updatedAt
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getSalesOpportunity = ({filter}, limit?, offset?, orderBy?, startDate?, endDate?, dateKey?) => {
    try {
        const formattedFiler = {
            ...filter,
            salesOpportunityId: parseInt(filter?.salesOpportunityId) || null
        }

        Object.keys(formattedFiler).forEach(key => {
            if (formattedFiler[key] === null) {
                delete formattedFiler[key];
            }
        });

        const {loading, error, data} = useQuery(GET_SALES_OPPORTUNITY, {
            variables: {
                filter: formattedFiler,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, data}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const getSalesOpportunity_New = async (filter, limit?, offset?, orderBy?, startDate?, endDate?, dateKey?) => {
    try {
        const formattedFiler = {
            ...filter,
            salesOpportunityId: parseInt(filter?.salesOpportunityId) || null
        }

        Object.keys(formattedFiler).forEach(key => {
            if (formattedFiler[key] === null) {
                delete formattedFiler[key];
            }
        });

        return await ApolloClientQuery(
            GET_SALES_OPPORTUNITY,
            {
                filter: formattedFiler,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const GET_SALES_OPPORTUNITY_LITE = gql`
    query getSalesOpportunityLite($filter: SalesOpportunityFilter, $limit: Int, $offset: Int, $orderBy: String, $startDate: String, $endDate: String, $dateKey: String) {
        getSalesOpportunityLite(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy, startDate: $startDate, endDate: $endDate, dateKey: $dateKey) {
            ... on SalesOpportunityAry {
                total
                data {
                    _id
                    id
                    username
                    name
                    email
                    salesOpportunityId
                    salesOpportunityName
                    userType
                    status
                    attachment
                    amount
                    date
                    remark
                    comment
                    isDeleted
                    isArchived
                    isReviewed
                    createdAt
                    updatedAt
                    salesRepName
                    salesRepId
                    salesOppStatusName
                    salesOppStatus {_id}
                }
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
               msg
            }
        }
    }
`

const getSalesOpportunityLite = async (filter, limit?, offset?, orderBy?, startDate?, endDate?, dateKey?) => {
    try {
        const formattedFiler = {
            ...filter,
            salesOpportunityId: parseInt(filter?.salesOpportunityId) || null
        }

        Object.keys(formattedFiler).forEach(key => {
            if (formattedFiler[key] === null) {
                delete formattedFiler[key];
            }
        });

        return await ApolloClientQuery(
            GET_SALES_OPPORTUNITY_LITE,
            {
                filter: formattedFiler,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

//MUTATION

const ADD_SALES_OPPORTUNITY = gql`
    mutation addSalesOpportunity($input: SalesOpportunityInput) {
        addSalesOpportunity(input: $input) {
            ... on SalesOpportunity {
                _id
                lead {
                    _id
                    name
                    email
                    salesRep {fullName}
                }
                client {
                    _id
                    fullName
                    username
                    email
                    salesRep {fullName}
                }
                salesOpportunityId
                salesOpportunityName
                userType
                status
                amount
                date
                remark
                isArchived
                isReviewed
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }

`

const useAddSalesOpportunityMutation = (): { addSalesOpportunity: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [addSalesOpportunityMutation, {data, loading, error}] = useMutation(ADD_SALES_OPPORTUNITY);

        const addSalesOpportunity = (input) => {
            return addSalesOpportunityMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {addSalesOpportunity}}) {
                    const typename = addSalesOpportunity?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(addSalesOpportunity?.errKey),
                            key: "addSalesOpportunity",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(addSalesOpportunity?.msg),
                            key: "addSalesOpportunity",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Add Sales Opportunity Successfully"),
                            key: "addSalesOpportunity",
                        });
                    }
                },
                refetchQueries: [GET_SALES_OPPORTUNITY]
            });
        };

        return {addSalesOpportunity, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {addSalesOpportunity: null, data: null, loading: false, error: err};
    }
}

const UPDATE_SALES_OPPORTUNITY = gql`
    mutation updateSalesOpportunity($id: String!, $input: SalesOpportunityInput) {
        updateSalesOpportunity(id: $id, input: $input) {
            ... on SalesOpportunity {
                _id
                lead {
                    _id
                    name
                    email
                    salesRep {fullName}
                }
                client {
                    _id
                    fullName
                    username
                    email
                    salesRep {fullName}
                }
                salesOpportunityId
                salesOpportunityName
                userType
                status
                amount
                date
                remark
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }


`

const useUpdateSalesOpportunityMutation = (): { updateSalesOpportunity: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [updateSalesOpportunityMutation, {data, loading, error}] = useMutation(UPDATE_SALES_OPPORTUNITY);

        const updateSalesOpportunity = (id, input) => {
            return updateSalesOpportunityMutation({
                variables: {
                    id: id,
                    input: input,
                },
                update(cache, {data: {updateSalesOpportunity}}) {
                    const typename = updateSalesOpportunity?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateSalesOpportunity?.errKey),
                            key: "updateSalesOpportunity",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateSalesOpportunity?.msg),
                            key: "updateSalesOpportunity",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Sales Opportunity Successfully"),
                            key: "updateSalesOpportunity",
                        });
                    }
                },
                refetchQueries: [GET_SALES_OPPORTUNITY]
            });
        };

        return {updateSalesOpportunity, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {updateSalesOpportunity: null, data: null, loading: false, error: err};
    }
}

const DELETE_SALES_OPPORTUNITY = gql`
    mutation deleteSalesOpportunity($id: String!){
        deleteSalesOpportunity(id: $id){
            ...on SalesOpportunity {
                _id
                isDeleted
            }
            ...on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`

const useDeleteSalesOpportunityMutation = (): { deleteSalesOpportunity: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [deleteSalesOpportunityMutation, {data, loading, error}] = useMutation(DELETE_SALES_OPPORTUNITY)

        const deleteSalesOpportunity = (id) => {
            return deleteSalesOpportunityMutation({
                variables: {
                    id: id,
                },
                update(cache, {data: {deleteSalesOpportunity}}) {
                    const typename = deleteSalesOpportunity?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(deleteSalesOpportunity?.errKey),
                            key: "deleteSalesOpportunity",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(deleteSalesOpportunity?.msg),
                            key: "deleteSalesOpportunity",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Delete Sales Opportunity Successfully"),
                            key: "deleteSalesOpportunity",
                        });
                    }
                },
                refetchQueries: [GET_SALES_OPPORTUNITY]
            });
        };

        return {deleteSalesOpportunity, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {deleteSalesOpportunity: null, data: null, loading: false, error: err};
    }
}

const BATCH_REVIEW_SALES_OPPORTUNITY = gql`
    mutation batchReviewSalesOpportunity($ids: [String]!, $input: SalesOpportunityReviewInput) {
        batchReviewSalesOpportunity(ids: $ids, input: $input) {
            ... on SalesOpportunityAry {
                data {
                    _id
                    lead {
                        _id
                        name
                        fullName
                        mobileNo
                        email
                        salesRep {
                            fullName
                            username
                        }
                        latestFollowUp {
                            followUpDateTime
                            lastFollowUp
                        }
                        previousFollowUp {
                            followUpDateTime
                        }
                    }
                    client {
                        _id
                        fullName
                        username
                        email
                        mobileNo
                        salesRep {
                            fullName
                            username
                        }
                        latestFollowUp {
                            followUpDateTime
                        }
                        previousFollowUp {
                            followUpDateTime
                        }
                    }
                    salesOpportunityId
                    salesOpportunityName
                    userType
                    status
                    amount
                    date
                    remark
                    isArchived
                    isReviewed
                    createdAt
                    updatedAt
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`

const useBatchReviewSalesOpportunityMutation = (): { batchReviewSalesOpportunity: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [batchReviewSalesOpportunityMutation, {data, loading, error}] = useMutation(BATCH_REVIEW_SALES_OPPORTUNITY)

        const batchReviewSalesOpportunity = (ids, input) => {
            return batchReviewSalesOpportunityMutation({
                variables: {
                    ids: ids,
                    input: {
                        isReviewed: input
                    }
                },
                update(cache, {data: {batchReviewSalesOpportunity}}) {
                    const typename = batchReviewSalesOpportunity?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(batchReviewSalesOpportunity?.errKey),
                            key: "batchReviewSalesOpportunity",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(batchReviewSalesOpportunity?.msg),
                            key: "batchReviewSalesOpportunity",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Review Sales Opportunity Successfully"),
                            key: "batchReviewSalesOpportunity",
                        });
                    }
                },
                refetchQueries: [GET_SALES_OPPORTUNITY]
            });
        };

        return {batchReviewSalesOpportunity, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {batchReviewSalesOpportunity: null, data: null, loading: false, error: err};
    }
}

const BATCH_UPDATE_SALES_OPPORTUNITY = gql`
    mutation batchUpdateSalesOpportunity($ids: [String]!, $input: SalesOpportunityInput) {
        batchUpdateSalesOpportunity(ids: $ids, input: $input) {
            ... on SalesOpportunityAry {
                data {
                    _id
                    lead {
                        _id
                        name
                        fullName
                        mobileNo
                        email
                        salesRep {
                            fullName
                            username
                        }
                        latestFollowUp {
                            followUpDateTime
                            lastFollowUp
                        }
                        previousFollowUp {
                            followUpDateTime
                        }
                    }
                    client {
                        _id
                        fullName
                        username
                        email
                        mobileNo
                        salesRep {
                            fullName
                            username
                        }
                        latestFollowUp {
                            followUpDateTime
                        }
                        previousFollowUp {
                            followUpDateTime
                        }
                    }
                    salesOpportunityId
                    salesOpportunityName
                    userType
                    status
                    amount
                    date
                    remark
                    isArchived
                    isReviewed
                    createdAt
                    updatedAt
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`

const useBatchUpdateSalesOpportunityMutation = (): { batchUpdateSalesOpportunity: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [batchUpdateSalesOpportunityMutation, {data, loading, error}] = useMutation(BATCH_UPDATE_SALES_OPPORTUNITY)

        const batchUpdateSalesOpportunity = (ids, input) => {
            return batchUpdateSalesOpportunityMutation({
                variables: {
                    ids: ids,
                    input: {
                        isArchived: input
                    }
                },
                update(cache, {data: {batchUpdateSalesOpportunity}}) {
                    const typename = batchUpdateSalesOpportunity?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(batchUpdateSalesOpportunity?.errKey),
                            key: "batchUpdateSalesOpportunity",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(batchUpdateSalesOpportunity?.msg),
                            key: "batchUpdateSalesOpportunity",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Archived Sales Opportunity Successfully"),
                            key: "batchUpdateSalesOpportunity",
                        });
                    }
                },
                refetchQueries: [GET_SALES_OPPORTUNITY]
            });
        };

        return {batchUpdateSalesOpportunity, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {batchUpdateSalesOpportunity: null, data: null, loading: false, error: err};
    }
}

export {
    getSalesOpportunity,
    getSalesOpportunity_New,
    getSalesOpportunityLite,
    useAddSalesOpportunityMutation,
    useUpdateSalesOpportunityMutation,
    useDeleteSalesOpportunityMutation,
    useBatchReviewSalesOpportunityMutation,
    useBatchUpdateSalesOpportunityMutation
}