import { gql, useMutation, useQuery } from "@apollo/client";
import {useSetRecoilState} from "recoil";
import {errorMessage, notificationMessage} from "../../../recoil_state";
import {useTranslation} from "react-i18next";

const GET_SYMBOL_MAPPING_CATEGORY_COUNT = gql`
  query getSymbolMappingCategoryCount(
    $filter: SymbolMappingCategorySearchInput
  ) {
    getSymbolMappingCategoryCount(filter: $filter)
  }
`;

const getSymbolMappingCategoryCount = ({ filter }) => {
  try {
    const { loading, error, data } = useQuery(
      GET_SYMBOL_MAPPING_CATEGORY_COUNT,
      {
        variables: {
          filter: filter,
        },
        context: {
          headers: {
            authorization: "Bearer " + localStorage.getItem("a_t"),
          },
        },
      }
    );

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_SYMBOL_MAPPING_CATEGORY = gql`
  query getSymbolMappingCategory(
    $filter: SymbolMappingCategorySearchInput
    $limit: Int
    $offset: Int
    $orderBy: String
  ) {
    getSymbolMappingCategory(
      filter: $filter
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      ... on SymbolMappingCategoryArray {
        data {
          _id
          id
          category {
            _id
            categoryName
            type
          }
          symbol {
            _id
            symbolId
            name
          }
          symbolMt5 {
            _id
            symbol
          }
          server{
            name
          }
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getSymbolMappingCategory = ({ filter }, limit?, offset?, orderBy?) => {
  try {
    const { loading, error, data } = useQuery(GET_SYMBOL_MAPPING_CATEGORY, {
      variables: {
        filter: filter,
        limit: limit ?? null,
        offset: offset ?? null,
        orderBy: orderBy ?? null,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const UPDATE_SYMBOL_CATEGORY_MAPPING = gql`
  mutation updateSymbolMappingCategory($input: SymbolMappingCategoryInput) {
    updateSymbolMappingCategory(input: $input) {
      ... on CrmTaskApproval {
        msg
      }
      ... on SymbolMappingCategory {
        _id
        id
        category {
          _id
          categoryName
        }
        symbol {
          _id
          symbolId
          name
        }
        symbolMt5 {
          _id
          symbol
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useUpdateSymbolMappingCategory = (): {
  updateSymbolMappingCategory: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const { t } = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [updateSymbolMappingCategoryMutation, { data, loading, error }] =
      useMutation(UPDATE_SYMBOL_CATEGORY_MAPPING);

    const updateSymbolMappingCategory = (input) => {
      return updateSymbolMappingCategoryMutation({
        variables: {
          input: input,
        },
        update(cache, {data: {updateSymbolMappingCategory}}) {
          const typename = updateSymbolMappingCategory?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(updateSymbolMappingCategory?.errKey),
              key: "updateSymbolMappingCategory",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(updateSymbolMappingCategory?.msg),
              key: "updateSymbolMappingCategory",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Update Symbol Mapping Category Successfully"),
              key: "updateSymbolMappingCategory",
            });
          }
        }
      });
    };

    return { updateSymbolMappingCategory, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return {
      updateSymbolMappingCategory: null,
      data: null,
      loading: false,
      error: err,
    };
  }
};

const DELETE_SYMBOL_CATEGORY_MAPPING = gql`
  mutation deleteSymbolMappingCategory($id: String!) {
    deleteSymbolMappingCategory(id: $id) {
      ... on CrmTaskApproval {
        msg
      }
      ... on SymbolMappingCategory {
        id
        isDeleted
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useDeleteSymbolMappingCategory = (): {
  deleteSymbolMappingCategory: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const setErrorMessage = useSetRecoilState(errorMessage);
    const [deleteSymbolMappingCategoryMutation, { data, loading, error }] =
      useMutation(DELETE_SYMBOL_CATEGORY_MAPPING, {
        update(cache, { data: { deleteSymbolMappingCategory } }) {
          let typename = deleteSymbolMappingCategory?.__typename;
          if (typename === "SymbolMappingCategory") {
            cache.modify({
              fields: {
                getSymbolMappingCategory(existingData = [], { readField }) {
                  let data = existingData?.data?.filter((ref) => {
                    const id = readField("id", ref);
                    return id !== deleteSymbolMappingCategory.id;
                  });
                  return { data, __typename: "SymbolMappingCategoryArray" };
                },
                getSymbolMappingCategoryCount(existingData) {
                  return existingData - 1;
                },
              },
            });
          } else if (
            typename === "CrmTaskApproval" ||
            typename === "BaseError"
          ) {
            setErrorMessage(deleteSymbolMappingCategory)
          }
        },
      });

    const deleteSymbolMappingCategory = (id) => {
      return deleteSymbolMappingCategoryMutation({
        variables: {
          id: id,
        },
      });
    };

    return { deleteSymbolMappingCategory, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return {
      deleteSymbolMappingCategory: null,
      data: null,
      loading: false,
      error: err,
    };
  }
};

const GET_SYMBOL_MAPPING_CATEGORY_CORE_COUNT = gql`
  query getSymbolMappingCategoryCoreCount ($filter: SymbolMappingCategoryCoreSearchInput) {
    getSymbolMappingCategoryCoreCount (filter: $filter)
  }
`

const getSymbolMappingCategoryCoreCount = ({ filter }) => {
  try {
    const { loading, error, data } = useQuery(
        GET_SYMBOL_MAPPING_CATEGORY_CORE_COUNT,
        {
          variables: {
            filter: filter,
          },
          context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t"),
            },
          },
        }
    );

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};


const GET_SYMBOL_MAPPING_CATEGORY_CORE = gql`
  query getSymbolMappingCategoryCore(
    $filter: SymbolMappingCategoryCoreSearchInput
    $limit: Int
    $offset: Int
    $orderBy: String
  ) {
    getSymbolMappingCategoryCore(
      filter: $filter
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      ... on SymbolMappingCategoryCoreArray {
        data {
          _id
          id
          label
          categoryName
          type
          parent {
            _id
            id
            categoryName
            label
            type
          }
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getSymbolMappingCategoryCore = ({ filter }, limit?, offset?, orderBy?) => {
  try {
    const { loading, error, data } = useQuery(GET_SYMBOL_MAPPING_CATEGORY_CORE, {
      variables: {
        filter: filter,
        limit: limit ?? null,
        offset: offset ?? null,
        orderBy: orderBy ?? null,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const ADD_SYMBOL_MAPPING_CATEGORY_CORE = gql`
  mutation addSymbolMappingCategoryCore($input: SymbolMappingCategoryCoreInput) {
    addSymbolMappingCategoryCore(input: $input) {
      ... on CrmTaskApproval {
        msg
      }
      ... on SymbolMappingCategoryCore {
        _id
        id
        label
        categoryName
        type
        parent {
          _id
          id
          categoryName
          label
          type
        }
      }
      ... on BaseError {
        errKey
        errMsg
        errObj
      }
    }
  }
`;

const useAddSymbolMappingCategoryCoreMutation = (): {
  addSymbolMappingCategoryCore: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const { t } = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [addSymbolMappingCategoryCoreMutation, { data, loading, error }] =
        useMutation(ADD_SYMBOL_MAPPING_CATEGORY_CORE);

    const addSymbolMappingCategoryCore = (input) => {
      return addSymbolMappingCategoryCoreMutation({
        variables: {
          input: input,
        },
        // update(cache, {data: {addSymbolMappingCategoryCore}}) {
        //   const typename = addSymbolMappingCategoryCore?.__typename;
        //   if (typename === "BaseError") {
        //     setNotificationMessage({
        //       status: "error",
        //       title: "",
        //       msg: t(addSymbolMappingCategoryCore?.errKey),
        //       key: "addSymbolMappingCategoryCore",
        //     });
        //   } else if (typename === "CrmTaskApproval") {
        //     setNotificationMessage({
        //       status: "info",
        //       title: "",
        //       msg: t(addSymbolMappingCategoryCore?.msg),
        //       key: "addSymbolMappingCategoryCore",
        //     });
        //   } else {
        //     setNotificationMessage({
        //       status: "success",
        //       title: "",
        //       msg: t("Add Category Successfully"),
        //       key: "addSymbolMappingCategoryCore",
        //     });
        //   }
        // },
        refetchQueries: [GET_SYMBOL_MAPPING_CATEGORY_CORE, GET_SYMBOL_MAPPING_CATEGORY_CORE_COUNT]
      });
    };

    return { addSymbolMappingCategoryCore, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return {
      addSymbolMappingCategoryCore: null,
      data: null,
      loading: false,
      error: err,
    };
  }
};

const UPDATE_SYMBOL_MAPPING_CATEGORY_CORE = gql`
  mutation updateSymbolMappingCategoryCore($id: String!, $input: SymbolMappingCategoryCoreInput) {
    updateSymbolMappingCategoryCore(id: $id, input: $input) {
      ... on CrmTaskApproval {
        msg
      }
      ... on SymbolMappingCategoryCore {
        _id
        id
        label
        categoryName
        type
        parent {
          _id
          id
          categoryName
          label
          type
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useUpdateSymbolMappingCategoryCoreMutation = (): {
  updateSymbolMappingCategoryCore: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const { t } = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [updateSymbolMappingCategoryCoreMutation, { data, loading, error }] =
        useMutation(UPDATE_SYMBOL_MAPPING_CATEGORY_CORE);

    const updateSymbolMappingCategoryCore = (id, input) => {
      return updateSymbolMappingCategoryCoreMutation({
        variables: {
          id: id,
          input: input,
        },
        update(cache, {data: {updateSymbolMappingCategoryCore}}) {
          const typename = updateSymbolMappingCategoryCore?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(updateSymbolMappingCategoryCore?.errKey),
              key: "updateSymbolMappingCategoryCore",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(updateSymbolMappingCategoryCore?.msg),
              key: "updateSymbolMappingCategoryCore",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Update Category Successfully"),
              key: "updateSymbolMappingCategoryCore",
            });
          }
        },
        refetchQueries: [GET_SYMBOL_MAPPING_CATEGORY_CORE, GET_SYMBOL_MAPPING_CATEGORY_CORE_COUNT]
      });
    };

    return { updateSymbolMappingCategoryCore, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return {
      updateSymbolMappingCategoryCore: null,
      data: null,
      loading: false,
      error: err,
    };
  }
};

const DELETE_SYMBOL_MAPPING_CATEGORY_CORE = gql`
  mutation deleteSymbolMappingCategoryCore($id: String!) {
    deleteSymbolMappingCategoryCore(id: $id) {
      ... on CrmTaskApproval {
        msg
      }
      ... on SymbolMappingCategoryCore {
        isDeleted
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useDeleteSymbolMappingCategoryCoreMutation = (): {
  deleteSymbolMappingCategoryCore: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const { t } = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [deleteSymbolMappingCategoryCoreMutation, { data, loading, error }] =
        useMutation(DELETE_SYMBOL_MAPPING_CATEGORY_CORE);

    const deleteSymbolMappingCategoryCore = (id) => {
      return deleteSymbolMappingCategoryCoreMutation({
        variables: {
          id: id,
        },
        update(cache, {data: {deleteSymbolMappingCategoryCore}}) {
          const typename = deleteSymbolMappingCategoryCore?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(deleteSymbolMappingCategoryCore?.errKey),
              key: "deleteSymbolMappingCategoryCore",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(deleteSymbolMappingCategoryCore?.msg),
              key: "deleteSymbolMappingCategoryCore",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Delete Category Successfully"),
              key: "deleteSymbolMappingCategoryCore",
            });
          }
        },
        refetchQueries: [GET_SYMBOL_MAPPING_CATEGORY_CORE, GET_SYMBOL_MAPPING_CATEGORY_CORE_COUNT]
      });
    };

    return { deleteSymbolMappingCategoryCore, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return {
      deleteSymbolMappingCategoryCore: null,
      data: null,
      loading: false,
      error: err,
    };
  }
};

export {
  getSymbolMappingCategoryCount,
  getSymbolMappingCategory,
  useUpdateSymbolMappingCategory,
  useDeleteSymbolMappingCategory,
  getSymbolMappingCategoryCoreCount,
  getSymbolMappingCategoryCore,
  useAddSymbolMappingCategoryCoreMutation,
  useUpdateSymbolMappingCategoryCoreMutation,
  useDeleteSymbolMappingCategoryCoreMutation
};
