import React, {useEffect, useMemo, useState} from "react";
import {useSetRecoilState, useRecoilValue} from "recoil";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {Button, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Tooltip} from "antd";
import {CloseOutlined, DeleteOutlined, SaveOutlined} from "@ant-design/icons";
import useAuthorize from "../../../../../../_common/authorize";
import {getPspSettingDetailDropdown} from "../../../../../../api/graphql/pspSettingDetail";
import {getAllDeskDropdown} from "../../../../../../api/graphql/desk";
import {getEWalletDepositDropdownBasedOnPermission} from "../../../../../../api/graphql/eWalletDeposit";
import {getEWalletWithdrawalDropdownBasedOnPermission} from "../../../../../../api/graphql/eWalletWithdrawal";
import {getCurrencyRate} from "../../../../../../api/graphql/pspSetting";
import {addPspAdjustment, deletePspAdjustment, getPspAdjustment, getPspAdjustmentCount} from "../../../../../../api/graphql/pspAdjustment";
import {notificationMessage, selfPermissionObj} from "../../../../../../../recoil_state";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import {pspAdjustmentTableColumns} from "../../../function/pspAdjustmentTableColumn";
import {getPspSettlementDropdownBasedOnPermission} from "../../../../../../api/graphql/pspSettlement";
import {groupByPspSettingName} from "../../../function/pspFunction";
import {formatDate, formatDateTime} from "../../../../../users/_common/function";

const labelCol = {span: 8, offset: 0};

const PspAdjustment = (props) => {
    const {setRefreshReportData} = props;
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const tableName = "PSP Adjustment";
    const {baseErrorChecking, systemErrorNotification} = useAuthorize();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const selfPermission = useRecoilValue<any>(selfPermissionObj);
    const pspAdjustmentPermission = selfPermission?.reports?.pspAdjustment;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isCurrencyRateLoading, setIsCurrencyRateLoading] = useState(true);
    const [isRequesting, setIsRequesting] = useState(false);
    const [currencyRateList, setCurrencyRateList] = useState([]);
    const [pspSettlementData, setPspSettlementData] = useState([]);
    const [pspId, setPspId] = useState("");
    const [pspCurrencyId, setPspCurrencyId] = useState("");
    const [type, setType] = useState("");
    const [rate, setRate] = useState(0);

    // For Table
    const [dataSource, setDataSource] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [filter, setFilter] = useState<any>({});
    const [orderBy, setOrderBy] = useState<any>("");
    const [limit, setLimit] = useState<any>(10);
    const [offset, setOffset] = useState<any>(0);

    const {loading, error, data: pspSettingDetailList} = getPspSettingDetailDropdown({filter: null});
    const {data: deskList} = getAllDeskDropdown();
    const {data: eWalletDepositList} = getEWalletDepositDropdownBasedOnPermission({
        filter: {
            pspSettingId: pspId,
            pspSettingCurrencyId: pspCurrencyId,
            transactionStatus: ["approved"],
            permission: "reports:pspAdjustment:add:add",
        },
    });
    const {data: eWalletWithdrawalList} = getEWalletWithdrawalDropdownBasedOnPermission({
        filter: {
            pspSettingId: pspId,
            pspSettingCurrencyId: pspCurrencyId,
            transactionStatus: ["approved"],
            permission: "reports:pspAdjustment:add:add",
        },
    });

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize);
        setOffset(limit * (page - 1));
    };

    const toggleModal = () => {
        try {
            setIsModalOpen((prevState) => !prevState);
        } catch (error) {}
    };

    const toggleCreateModal = () => {
        try {
            setIsCreateModalOpen((prevState) => !prevState);
        } catch (error) {}
    };

    const getCurrencyRateList = async () => {
        try {
            setIsCurrencyRateLoading(true);
            const currencyRate = await getCurrencyRate();
            setCurrencyRateList(currencyRate?.getCurrencyRate?.data);
        } catch (error) {
        } finally {
            setIsCurrencyRateLoading(false);
        }
    };

    const getCurrentCurrncyRate = () => {
        try {
            const curRateData: any = currencyRateList?.find((c: any) => c.currency === pspCurrencyName);
            setRate(curRateData?.rate || 0);
            form.setFieldValue("rate", curRateData?.rate || 0);
        } catch (error) {}
    };

    const getPspAdjustmentData = async () => {
        try {
            let amenededOrderBy = orderBy;
            if (orderBy?.includes("pspSetting")) {
                amenededOrderBy = "pspSettingId_" + orderBy?.split("_")[1];
            } else if (orderBy?.includes("pspSettingCurrency")) {
                amenededOrderBy = "pspSettingCurrencyId" + orderBy?.split("_")[1];
            }
            const response = await getPspAdjustment({filter}, limit, offset, amenededOrderBy);
            setDataSource(response?.getPspAdjustment?.data || []);
            setTotalCount(response?.getPspAdjustment?.total);
        } catch (error) {}
    };

    const getPspSettlementData = async () => {
        try {
            const response = await getPspSettlementDropdownBasedOnPermission({
                filter: {
                    pspSettingId: pspId,
                    pspSettingCurrencyId: pspCurrencyId,
                    permission: "reports:pspAdjustment:add:add",
                },
            });
            setPspSettlementData(response?.getPspSettlementDropdownBasedOnPermission?.data);
        } catch (error) {}
    };

    const handleDeleteAdjustment = async (id) => {
        try {
            const response = await deletePspAdjustment(id);
            const typename = response?.deletePspAdjustment?.__typename;
            if (typename === "BaseError") {
                return await baseErrorChecking(response?.deletePspAdjustment);
            } else if (typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.deletePspAdjustment?.msg),
                    key: "deletePspAdjustment",
                });
            } else {
                getPspAdjustmentData();
                setTimeout(() => {
                    setRefreshReportData(true);
                }, 3000);
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Delete Successfully",
                    key: "deletePspAdjustment",
                });
            }
        } catch (error) {}
    };

    const onSubmit = async (value) => {
        try {
            setIsRequesting(true);
            let input = {...value};
            if (value?.adjustmentDate) {
                input.adjustmentDate = moment(value?.adjustmentDate?.format("YYYY-MM-DD") + "T00:00:00.000+00:00");
            }
            const response = await addPspAdjustment(input);
            const typename = response?.addPspAdjustment?.__typename;
            if (typename === "BaseError") {
                return await baseErrorChecking(response?.addPspAdjustment);
            } else if (typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.addPspAdjustment?.msg),
                    key: "updatePspAdjustment",
                });
                toggleCreateModal();
            } else {
                getPspAdjustmentData();
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Create Successfully",
                    key: "addPspAdjustment",
                });
                toggleCreateModal();
                setTimeout(() => {
                    setRefreshReportData(true);
                }, 3000);
            }
        } catch (e) {
            console.log(e);
            systemErrorNotification();
        } finally {
            setIsRequesting(false);
        }
    };

    const resetTable = () => {
        try {
            setFilter({});
            setOrderBy("");
            setOffset(0);
        } catch (error) {}
    };

    const resetCreateForm = () => {
        try {
            setRate(0);
            form.resetFields();
            getCurrencyRateList();
        } catch (error) {}
    };

    useEffect(() => {
        getCurrentCurrncyRate();
    }, [pspCurrencyId]);

    useEffect(() => {
        if (pspId) {
            setPspCurrencyId("");
            form.setFieldValue("pspSettingCurrencyId", "");
        }
    }, [pspId]);

    useEffect(() => {
        getPspSettlementData();
        form.setFieldValue("transactionId", "");
    }, [pspId, pspCurrencyId]);

    useEffect(() => {
        form.setFieldValue("field", "");
    }, [type]);

    useEffect(() => {
        if (isModalOpen) {
            getPspAdjustmentData();
        }
    }, [filter, limit, offset, orderBy, isModalOpen]);

    useEffect(() => {
        if (isCreateModalOpen) {
            resetCreateForm();
        }
    }, [isCreateModalOpen]);

    useEffect(() => {
        if (!isModalOpen) {
            resetTable();
        }
    }, [isModalOpen]);

    const validateMessages = {
        required: t("errorMsg.required.requiredField", {field: "${label}"}),
    };

    const groupedDataByPsp = groupByPspSettingName(pspSettingDetailList?.getPspSettingDetailDropdown?.data);

    const pspSettingOptions = useMemo(() => {
        return Object?.keys(groupedDataByPsp)?.map((psp) => ({
            label: psp + " - " + groupedDataByPsp[psp]?.[0]?.pspSetting?.displayName,
            value: groupedDataByPsp[psp]?.[0]?.pspSetting?._id,
        }));
    }, [groupedDataByPsp]);

    const pspSettingCurrencyOptions = useMemo(() => {
        const pspName = pspSettingDetailList?.getPspSettingDetailDropdown?.data?.find((detail) => detail?.pspSetting?._id === pspId)?.pspSetting
            ?.name;
        const curList = groupedDataByPsp?.[pspName]?.map((pspDetail) => {
            return {
                label: pspDetail?.pspCurrency?.currency,
                value: pspDetail?.pspCurrency?._id,
                fixedRate: pspDetail?.pspCurrency?.fixedRate,
                digits: pspDetail?.pspCurrency?.digits,
            };
        });
        const amendedCurList = curList?.filter(
            (obj, index, self) => index === self.findIndex((o) => o.label === obj.label && o.value === obj.value && o.fixedRate === obj.fixedRate)
        );
        return amendedCurList || [];
    }, [groupedDataByPsp, pspId]);

    const typeOptions = [
        {label: t("common.deposit"), value: "deposit"},
        {label: t("common.withdrawal"), value: "withdrawal"},
        {label: t("common.settlement"), value: "settlement"},
        {label: t("common.balance"), value: "balance"},
    ];

    const fieldOptions = useMemo(() => {
        const optionsMap = {
            deposit: [
                {label: t("common.amount"), value: "amount"},
                {label: t("common.fee"), value: "fee"},
            ],
            withdrawal: [
                {label: t("common.amount"), value: "amount"},
                {label: t("common.fee"), value: "fee"},
            ],
            settlement: [{label: t("common.fee"), value: "fee"}],
            balance: [{label: t("common.balance"), value: "balance"}],
        };

        return optionsMap[type] || [];
    }, [type]);

    const deskOptions = useMemo(() => {
        return deskList?.getAllDeskDropdown?.data?.map((desk) => ({label: desk?.name, value: desk?._id}));
    }, [deskList?.getAllDeskDropdown?.data?.length]);

    const transactionDepositOptions = useMemo(() => {
        return eWalletDepositList?.getEWalletDepositDropdownBasedOnPermission?.data?.map((dep) => ({
            label: `${dep?.transactionNo} - ${dep?.currency} ${dep?.convertedAmount} : ${t("common.fee")} ${dep?.convertedFeeCompany} - ${t(
                "common.date"
            )}: ${formatDateTime(dep?.transactionDate)}`,
            value: dep?._id,
        }));
    }, [eWalletDepositList]);

    const transactionWithdrawalOptions = useMemo(() => {
        return eWalletWithdrawalList?.getEWalletWithdrawalDropdownBasedOnPermission?.data?.map((wit) => ({
            label: `${wit?.transactionNo} - ${wit?.currency} ${wit?.convertedAmount} : ${t("common.fee")} ${wit?.convertedFeeCompany} - ${t(
                "common.date"
            )}: ${formatDateTime(wit?.transactionDate)}`,
            value: wit?._id,
        }));
    }, [eWalletWithdrawalList]);

    const transactionSettlementOptions = useMemo(() => {
        return pspSettlementData?.map((v: any) => ({
            label: `${v?.transactionNo} (${formatDate(v?.settlementDate)}) - ${v?.pspSettingCurrencyId?.currency} ${v?.convertedAmount} : ${t(
                "common.fee"
            )} ${v?.convertedFee} - ${t("common.date")}: ${formatDate(v?.transactionDate)}`,
            value: v?._id,
        }));
    }, [pspSettlementData]);

    const transactionOptions = useMemo(() => {
        const optionsMap = {
            deposit: transactionDepositOptions,
            withdrawal: transactionWithdrawalOptions,
            settlement: transactionSettlementOptions,
            balance: [],
        };

        return optionsMap[type] || [];
    }, [type, eWalletDepositList, eWalletWithdrawalList, transactionSettlementOptions]);

    const isTransctionAvailable = pspId?.length > 0 && pspCurrencyId?.length > 0 && type?.length > 0;

    const pspCurrencyName = pspSettingDetailList?.getPspSettingDetailDropdown?.data?.find((detail) => detail?.pspCurrency?._id === pspCurrencyId)
        ?.pspCurrency?.currency;

    return (
        <>
            <Button onClick={toggleModal}>{t("Create Adjustment")}</Button>
            <Modal
                title={t("psp.pspAdjustment")}
                open={isModalOpen}
                width={"80%"}
                footer={null}
                destroyOnClose
                getContainer="#reports"
                className="pspAdjustmentModal"
                maskClosable={false}
                onCancel={toggleModal}
            >
                {pspAdjustmentPermission?.add?.add && (
                    <Button className="margin-bottom-0-75" onClick={toggleCreateModal}>
                        {t("Create Adjustment")}
                    </Button>
                )}
                <TableAntDesign
                    data={dataSource}
                    tableName={tableName}
                    columns={[
                        ...pspAdjustmentTableColumns(),
                        {
                            title: "",
                            dataIndex: "",
                            key: "",
                            render: (_, record) => (
                                <Space size="middle">
                                    {pspAdjustmentPermission?.delete?.delete && (
                                        <Tooltip title={t("common.delete")}>
                                            <Popconfirm
                                                placement="left"
                                                title={t("common.deleteConfirm")}
                                                onConfirm={() => {
                                                    handleDeleteAdjustment(record?._id);
                                                }}
                                                okText={t("common.yes")}
                                                cancelText={t("common.no")}
                                            >
                                                <a>
                                                    <DeleteOutlined />
                                                </a>
                                            </Popconfirm>
                                        </Tooltip>
                                    )}
                                </Space>
                            ),
                        },
                    ]}
                    size={"small"}
                    loading={loading}
                    tableSpin={false}
                    filter={setFilter}
                    order={setOrderBy}
                    rowKey={(record) => record.id || record?._id}
                    pagination={{
                        pageSize: limit,
                        showSizeChanger: true,
                        onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                        total: totalCount,
                        showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
                    }}
                />
            </Modal>
            {isCreateModalOpen && (
                <Modal
                    title={"Create PSP Adjustment"}
                    open={isCreateModalOpen}
                    width={"80%"}
                    footer={null}
                    destroyOnClose
                    getContainer="#reports"
                    className="createAdjustmentModal"
                    maskClosable={false}
                    onCancel={toggleCreateModal}
                >
                    <Form onFinish={onSubmit} form={form} validateMessages={validateMessages} colon={false} labelWrap={true}>
                        <Row gutter={[16, 16]} className={"margin-top-0-75 margin-bottom-0-75"}>
                            <Col span={24}>
                                <Form.Item name={"pspSettingId"} label={t("PSP")} labelCol={labelCol} labelAlign="left" rules={[{required: true}]}>
                                    <Select
                                        options={pspSettingOptions}
                                        optionFilterProp={"label"}
                                        allowClear
                                        showSearch
                                        value={pspId}
                                        onChange={(e) => setPspId(e)}
                                        disabled={isCurrencyRateLoading}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name={"pspSettingCurrencyId"}
                                    label={t("common.currency")}
                                    labelCol={labelCol}
                                    labelAlign="left"
                                    rules={[{required: true}]}
                                >
                                    <Select
                                        options={pspSettingCurrencyOptions}
                                        optionFilterProp={"label"}
                                        allowClear
                                        showSearch
                                        disabled={!pspId || isCurrencyRateLoading}
                                        onChange={(e) => setPspCurrencyId(e)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name={"type"} label={t("Type")} labelCol={labelCol} labelAlign="left" rules={[{required: true}]}>
                                    <Select onChange={(e) => setType(e)} options={typeOptions} optionFilterProp={"label"} allowClear showSearch />
                                </Form.Item>
                            </Col>
                            {type === "balance" ? (
                                <>
                                    <Col span={24}>
                                        <Form.Item
                                            name={"deskId"}
                                            label={t("user.desk")}
                                            labelCol={labelCol}
                                            labelAlign="left"
                                            rules={[{required: true}]}
                                        >
                                            <Select options={deskOptions} optionFilterProp={"label"} allowClear showSearch />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name={"adjustmentDate"}
                                            label={t("common.date")}
                                            labelCol={labelCol}
                                            labelAlign="left"
                                            rules={[{required: true}]}
                                        >
                                            <DatePicker />
                                        </Form.Item>
                                    </Col>
                                </>
                            ) : (
                                <Col span={24}>
                                    <Form.Item
                                        name={"transactionId"}
                                        label={t("common.transaction")}
                                        labelCol={labelCol}
                                        labelAlign="left"
                                        rules={[{required: true}]}
                                    >
                                        {isTransctionAvailable && (
                                            <Select options={transactionOptions} optionFilterProp={"label"} allowClear showSearch />
                                        )}
                                    </Form.Item>
                                </Col>
                            )}
                            <Col span={24}>
                                <Form.Item name={"field"} label={t("Field")} labelCol={labelCol} labelAlign="left" rules={[{required: true}]}>
                                    <Select options={fieldOptions} optionFilterProp={"label"} allowClear showSearch />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name={"convertedAmount"}
                                    label={t("common.amount")}
                                    labelCol={labelCol}
                                    labelAlign="left"
                                    rules={[{required: true}]}
                                >
                                    <InputNumber />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name={"rate"} label={t("common.rate")} labelCol={labelCol} labelAlign="left" rules={[{required: true}]}>
                                    <div>
                                        1 USD = <InputNumber value={rate} onChange={(value: any) => setRate(value)} /> {pspCurrencyName}
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name={"remark"} label={t("common.remark")} labelCol={labelCol} labelAlign="left">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="end" className="section">
                            <Button onClick={toggleCreateModal} icon={<CloseOutlined />} className="cancelBtn" disabled={isRequesting}>
                                {t("common.cancel")}
                            </Button>
                            <Button htmlType="submit" icon={<SaveOutlined />} className="button-submit-1" loading={isRequesting}>
                                {t("common.submit")}
                            </Button>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default PspAdjustment;
