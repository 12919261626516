import {formatDateTime} from "../../../_common/function";
import {useTranslation} from "react-i18next";

export const AccountVerificationColumns = () => {
    const {t} = useTranslation()

    return [
        {
            title: t("accountVerification.country"),
            dataIndex: "countryName",
            key: "countryName",
            render: (text) => text || "-",
        },
        {
            title: t("accountVerification.clientType"),
            dataIndex: "clientType",
            key: "clientType",
            render: (text) => text || "-",
        },
        {
            title: t("accountVerification.recordId"),
            dataIndex: "recordId",
            key: "recordId",
            render: (text) => text || "-",
        },
        {
            title: t("common.browser"),
            dataIndex: "browser",
            key: "browser",
            render: (text) => text || "-",
        },
        {
            title: t("mission.device"),
            dataIndex: "device",
            key: "device",
            render: (text) => text || "-",
        },
        {
            title: t("accountVerification.clientStartTime"),
            dataIndex: "clientStartTime",
            key: "clientStartTime",
            render: (text) => text ? text: "-",
        },
        {
            title: t("accountVerification.clientEndTime"),
            dataIndex: "clientEndTime",
            key: "clientEndTime",
            render: (text) => text ? text : "-",
        },
        {
            title: t("accountVerification.duration"),
            dataIndex: "duration",
            key: "duration",
            render: (text) => text ? `${text?.days}days ${text?.hours}hrs ${text?.minutes}mins ${text?.seconds}s` : "-",
        },
        {
            title: t("common.status"),
            dataIndex: "status",
            key: "status",
            render: (text) => text || "-",
        },
        {
            title: t("accountVerification.taskStatus"),
            dataIndex: "taskStatus",
            key: "taskStatus",
            render: (text) => text || "-",
        },
        {
            title: t("accountVerification.actionBy"),
            dataIndex: "actionBy",
            key: "actionBy",
            render: (text) => text || "-",
        },
        {
            title: t("accountVerification.actionDateTime"),
            dataIndex: "actionDateTime",
            key: "actionDateTime",
            render: (text) => text || "-",
        },
    ]
}