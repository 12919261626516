import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";


const GET_TRADING_ACCOUNT_TRANSACTION = gql`
    query getTradingAccountTransaction(
        $filter: tradingAccountTransactionInput
        $orderBy: String
        $limit: Int
        $offset: Int
        $startDate: String
        $endDate: String
        $dateKey: String
    ) {
        getTradingAccountTransaction(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
            startDate: $startDate
            endDate: $endDate
            dateKey: $dateKey
        )  {
            ... on tradingAccountTransactionAry {
                data{
                    _id
                    balanceHistoryIdDisplay
                    balanceHistoryId
                    createdAt
                    transactionType
                    amount
                    comment
                }
                total
            }
            ... on BaseError {
                errObj
            }
        }
    }
`;

const getTradingAccountTransaction = async (filter, orderBy?, limit?, offset?, startDate?, endDate?, dateKey?) => {
    try {
        const execute = await ApolloClientQuery(GET_TRADING_ACCOUNT_TRANSACTION, {
                filter: filter,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
                orderBy: orderBy || null,
                limit: limit || null,
                offset: offset || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_TRADING_CREDIT_TRANSACTION = gql`
    query getTradingCreditTransaction(
        $filter: tradingCreditTransactionInput
        $orderBy: String
        $limit: Int
        $offset: Int
        $startDate: String
        $endDate: String
        $dateKey: String
    ) {
        getTradingCreditTransaction(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
            startDate: $startDate
            endDate: $endDate
            dateKey: $dateKey
        )  {
            ... on tradingCreditTransactionAry {
                data{
                    _id
                    expirationDatetime
                    balanceHistoryIdDisplay
                    balanceHistoryId
                    createdAt
                    transactionType
                    amount
                    comment
                    remark
                    debt
                    status
                }
                total
            }
            ... on BaseError {
                errObj
            }
        }
    }
`;

const getTradingCreditTransaction = async (filter, orderBy?, limit?, offset?, startDate?, endDate?, dateKey?) => {
    try {
        const execute = await ApolloClientQuery(GET_TRADING_CREDIT_TRANSACTION, {
                filter: filter,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
                orderBy: orderBy || null,
                limit: limit || null,
                offset: offset || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

export {
    getTradingAccountTransaction,
    getTradingCreditTransaction
}