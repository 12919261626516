import React from "react";
import {Checkbox, Col, Divider, Form, Input, Row, Select} from "antd";
import {useTranslation} from "react-i18next";
import {renderProfileInformation} from "../../../../../../_common/function";
import {useRecoilValue} from "recoil";
import {languageOptionsState, newsletterOptionsState, userOptionsState} from "../../../../../../_common/state";
import {useUpdateUserReferrerMutation, useUpdateUserSalesRepMutation} from "../../../../../../../../api/graphql/user";
import {fieldListState} from "../../../../../../../../../recoil_state";

const BasicInformation = (props) => {
    const {
        data,
        editable,
        isTask,
        basicField,
        loginField,
        additionalField,
        isReadSensitive,
        form,
        permissionUser
    } = props
    const {t} = useTranslation()

    const newsletterOptions = useRecoilValue<any>(newsletterOptionsState)
    const languageOptions = useRecoilValue<any>(languageOptionsState)
    const userOptions = useRecoilValue<any>(userOptionsState);
    const {updateUserReferrer} = useUpdateUserReferrerMutation();
    const {updateUserSalesRep} = useUpdateUserSalesRepMutation();
    const fieldList = useRecoilValue<any>(fieldListState)
    const commentValue = ["deposited", "followingUp", "noAnswer", "garbage/invalidNo", "blocked/giveUp", "assistanceNeeded", "wrongPerson"]

    return (
        data &&
        <Row gutter={[16, 16]} className={"margin-top-0-75 margin-bottom-0-75"}>
            {basicField?.length > 0 && (
                <Col span={24}>
                    <h3>{t("field.category.basicInformation")}</h3>
                    <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                        {renderProfileInformation(basicField, data, t, !editable, isReadSensitive)}
                        <Col xs={24} md={12}>
                            <Form.Item
                                name={"language"}
                                label={t("user.language")}
                                labelCol={{span: 10, offset: 0}}
                                initialValue={data?.language}
                                labelAlign={"left"}
                            >
                                <Select
                                    placeholder={t("user.language").toString()}
                                    options={languageOptions}
                                    disabled={!editable}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name={"comment"}
                                label={t("user.comment")}
                                labelCol={{span: 10, offset: 0}}
                                initialValue={data?.comment}
                                labelAlign={"left"}
                            >
                                <Select
                                    showSearch
                                    disabled={!editable}
                                    allowClear
                                    options={fieldList?.find(d => d?.fieldName === "clientComment")?.optionValue?.map(d => ({...d, label: t(d?.label)}))}
                                    // options={[
                                    //     {value: "deposited", label: t("comment.deposited")},
                                    //     {value: "followingUp", label: t("comment.followingUp")},
                                    //     {value: "noAnswer", label: t("comment.noAnswer")},
                                    //     {value: "garbage/invalidNo", label: t("comment.garbage_invalidNo")},
                                    //     {value: "blocked/giveUp", label: t("comment.blocked_giveUp")},
                                    //     {value: "assistanceNeeded", label: t("comment.assistanceNeeded")},
                                    //     {value: 'wrongPerson', label: t('comment.wrongPerson')},
                                    // ]}
                                    // dropdownRender={(menu) => {
                                    //     return (
                                    //         <>
                                    //             {menu}
                                    //             <Input placeholder={t("comment.others").toString()}
                                    //                    defaultValue={!commentValue?.includes(data?.comment) ? data?.comment : ""}
                                    //                    onChange={(e) => form.setFieldsValue({comment: e.target?.value})}
                                    //                    onKeyDown={(e) => e.stopPropagation()}
                                    //             />
                                    //         </>
                                    //     )
                                    // }}
                                />
                            </Form.Item>
                        </Col>
                        {!isTask && (
                            <>
                                <Col span={12}>
                                    <Form.Item
                                        name={"isLoginEnabled"}
                                        label={t("user.isLoginEnabled")}
                                        labelCol={{span: 10, offset: 0}}
                                        initialValue={data?.isLoginEnabled}
                                        valuePropName="checked"
                                        labelAlign={"left"}
                                    >
                                        <Checkbox disabled={!editable}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={"isTestAccount"}
                                        label={t("user.isTestAccount")}
                                        labelCol={{span: 10, offset: 0}}
                                        initialValue={data?.isTestAccount}
                                        valuePropName="checked"
                                        labelAlign={"left"}
                                    >
                                        <Checkbox disabled={!editable}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name={"newsletter"}
                                        label={t("user.newsletter")}
                                        labelCol={{span: 10, offset: 0}}
                                        initialValue={data?.newsletter}
                                        labelAlign={"left"}
                                    >
                                        <Checkbox.Group style={{width: "100%"}}>
                                            <Row>
                                                {newsletterOptions &&
                                                newsletterOptions?.map((d) => {
                                                    return (
                                                        <Col span={24} style={{marginBottom: 8}} key={d?.value}>
                                                            <Checkbox
                                                                disabled={!editable}
                                                                value={d?.value}
                                                            >
                                                                {t(`newsletter.${d?.label}`)}
                                                            </Checkbox>
                                                        </Col>
                                                    );
                                                })}
                                            </Row>
                                        </Checkbox.Group>
                                    </Form.Item>
                                </Col>
                            </>
                        )}
                        {
                            isTask &&
                            <>
                                <Col xs={24} md={12}>
                                    <Form.Item labelAlign={"left"} label={t("user.referrer")} labelCol={{span: 10, offset: 0}}
                                               initialValue={data?.referrer?._id}>
                                        <Select
                                            value={data?.referrer?._id}
                                            placeholder={t("user.referrer")}
                                            options={userOptions?.filter((d) => d?.value !== data?._id)}
                                            optionFilterProp={"label"}
                                            showSearch
                                            onChange={(value) => updateUserReferrer(data?._id, {referrer: value})}
                                            style={{width: "100%"}}
                                            disabled={!editable || !permissionUser?.clientsUser?.edit?.referrer}
                                            optionLabelProp={"selectedLabel"}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item labelAlign={"left"} label={t("user.salesRep")} labelCol={{span: 10, offset: 0}}
                                               initialValue={data?.salesRep?._id}>
                                        <Select
                                            value={data?.salesRep?._id}
                                            placeholder={t("user.salesRep")}
                                            options={userOptions?.filter((d) => d?.value !== data?._id)}
                                            optionFilterProp={"label"}
                                            showSearch
                                            onChange={(value) => updateUserSalesRep(data?._id, {salesRep: value})}
                                            style={{width: "100%"}}
                                            disabled={!editable || !permissionUser?.clientsUser?.edit?.salesRep}
                                            optionLabelProp={"selectedLabel"}
                                        />
                                    </Form.Item>
                                </Col>
                            </>
                        }
                    </Row>
                </Col>
            )}
            <Divider/>
            {loginField?.length > 0 && (
                <Col span={24}>
                    <h3>{t("field.category.loginInformation")}</h3>
                    <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                        {renderProfileInformation(loginField, data, t, !editable, isReadSensitive, form)}
                    </Row>
                </Col>
            )}
            {additionalField?.length > 0 && (
                <Col span={24}>
                    <h3>{t("field.category.additionalInformation")}</h3>
                    <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                        {renderProfileInformation(additionalField, data, t, !editable, isReadSensitive)}
                    </Row>
                </Col>
            )}
        </Row>
    )
}

export default BasicInformation