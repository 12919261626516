import {getColumnSearchProps} from "../../../../../components/table/function/filterTable";
import React from "react";
import {Tag} from "antd";

export const columns = [
    {
        title: "Rank",
        dataIndex: "rank",
        key: "rank",
        width: "20%",
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Rank Name",
        dataIndex: "name",
        key: "name",
        ...getColumnSearchProps(),
        render: (text,record) => <p>{text}{record?.isDefaultRank && <Tag className={"margin-left-0-25"} color={"blue"}>default</Tag>}</p>,
    },
    {
        title: "Client User Count",
        dataIndex: "clientUserCount",
        key: "clientUserCount",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "CRM User Count",
        dataIndex: "crmUserCount",
        key: "crmUserCount",
        ...getColumnSearchProps(),
        render: (text) => <p>{text}</p>,
    },
]