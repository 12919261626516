import React, { useEffect, useState } from "react"
import { useUpdateEwalletWithdrawalPaymentMethodMutation } from "../../../../../../../api/graphql/eWalletWithdrawal";
import { useSetRecoilState } from "recoil";
import { notificationMessage } from "../../../../../../../../recoil_state";
import { useTranslation } from "react-i18next";
import useAuthorize from "../../../../../../../_common/authorize";
import { Button, Col, Modal, Row, Select } from "antd";
import { getPspSettingCurrency } from "../../../../../../../api/graphql/pspSettingCurrency";
import { getPspSettingDetail } from "../../../../../../../api/graphql/pspSettingDetail";
import { getPspSetting } from "../../../../../../../api/graphql/pspSetting";

const EWalletUpdatePaymentMethodModal = (props) => {
    const {taskDetail, open, setOpen} = props
    const {updateEwalletWithdrawalPaymentMethod} = useUpdateEwalletWithdrawalPaymentMethodMutation()
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {systemErrorNotification} = useAuthorize();
    const {t} = useTranslation();

    const {data: pspSettingData} = getPspSetting({filter: null});
    const {pspCurrencyData} = getPspSettingCurrency({filter: null});
    const {data: pspSettingDetailData} = getPspSettingDetail({filter: null});

    const [pspSetting, setPspSetting] = useState(taskDetail?.payload?.input?.pspSettingId)
    const [pspSettingCurrency, setPspSettingCurrency] = useState(taskDetail?.payload?.input?.pspSettingCurrencyId)
    const [pspSettingDetail, setPspSettingDetail] = useState(taskDetail?.payload?.input?.pspSettingDetailId)

    useEffect(() => {
        setPspSetting(taskDetail?.payload?.input?.pspSettingId)
        setPspSettingCurrency(taskDetail?.payload?.input?.pspSettingCurrencyId)
        setPspSettingDetail(taskDetail?.payload?.input?.pspSettingDetailId)
    }, [taskDetail])

    const pspSettingOptions = pspSettingData?.getPspSetting?.data?.map(item => {
        return {
            key: item._id,
            value: item._id,
            label: item.displayName ?? item.name
        }
    })

    const pspSettingCurrencyOptions = pspCurrencyData?.getPspSettingCurrency?.data?.filter(item => item?.pspSetting?._id?.toString() === pspSetting?.toString()).map(item => {
        return {
            key: item._id,
            value: item._id,
            label: item.currency
        }
    })

    const pspSettingDetailOptions = pspSettingDetailData?.getPspSettingDetail?.data?.filter(item => item?.pspSetting?._id?.toString() === pspSetting?.toString() && item?.pspCurrency?._id?.toString() === pspSettingCurrency?.toString()).map(item => {
        return {
            key: item._id,
            value: item._id,
            label: item.paymentMethod
        }
    })
    
    const changeEwalletWithdrawalPaymentMethod = async () => {
        try {
            const response = await updateEwalletWithdrawalPaymentMethod(taskDetail?.payload?.input._id, {
                pspSettingId: pspSetting,
                pspSettingCurrencyId: pspSettingCurrency,
                pspSettingDetailId: pspSettingDetail,
            });
            if (response?.data?.updateEwalletWithdrawalPaymentMethod?.__typename === "CrmTaskApproval") {
                setOpen(false)
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Update Payment Method"),
                    key: "updatePaymentMethod",
                });
            } else if (response?.data?.updateEwalletWithdrawalPaymentMethod?.__typename === "BaseError") {
                setOpen(false)
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.data?.updateEwalletWithdrawalPaymentMethod?.errKey),
                    key: "updatePaymentMethod",
                });
            } else {
                setOpen(false)
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.data?.updateEwalletWithdrawalPaymentMethod?.msg),
                    key: "updatePaymentMethod",
                });
            }
        } catch (e) {
            setOpen(false)
            systemErrorNotification();
        }
    };

    return (
    <Modal
        title={t("Update Payment Method")}
        open={open}
        onCancel={() => setOpen(false)}
        closable={true}
        footer={null}
        width={640}
        destroyOnClose
    >
        <Row style={{marginTop: "12px"}}>
            <Col span={5} >{t("PSP")} :</Col>
            <Col span={1}/>
            <Col span={17}>
                <Select
                    options={pspSettingOptions}
                    onChange={e=>{
                        setPspSetting(e)
                        setPspSettingCurrency("")
                        setPspSettingDetail("")
                    }}
                    value={pspSetting}
                    style={{width: "446px"}}
                />
            </Col>
        </Row>
        <Row style={{marginTop: "12px"}}>
            <Col span={5}>{t("Currency")} :</Col>
            <Col span={1}/>
            <Col span={17}>
                <Select
                    options={pspSettingCurrencyOptions}
                    onChange={e=>{
                        setPspSettingCurrency(e)
                        setPspSettingDetail("")
                    }}
                    value={pspSettingCurrency}
                    style={{width: "446px"}}
                />
            </Col>
        </Row>
        <Row style={{marginTop: "12px"}}>
            <Col span={5}>{t("Payment Method")} :</Col>
            <Col span={1}/>
            <Col span={17}>
                <Select
                    options={pspSettingDetailOptions}
                    onChange={e=>setPspSettingDetail(e)}
                    value={pspSettingDetail}
                    style={{width: "446px"}}
                />
            </Col>
        </Row>
        <Row>
            <Col span={24} style={{textAlign: "right", marginTop: "15px"}}>
                <Button onClick={() => changeEwalletWithdrawalPaymentMethod()}>
                    {t("Save")}
                </Button>
            </Col>
        </Row>
    </Modal>
    )
}

export default EWalletUpdatePaymentMethodModal