import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";

const ADD_MAM_PAMM_TRANSACTION = gql`
    mutation addMamPammTransaction($input: MamPammTransactionInput) {
        addMamPammTransaction(input: $input) {
            ... on MamPammTransaction {
                _id
                status
                moneyManagerAccount {
                    _id
                    accountId
                }
                investorAccount {
                    _id
                    accountId
                }
                user {
                    username
                }
                amount
                transactionType
                depositType
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const addMamPammTransaction = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            ADD_MAM_PAMM_TRANSACTION,
            {
                input: input,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

export {addMamPammTransaction};
