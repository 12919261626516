import React, {useEffect, useState} from "react";
import {Button, Col, Input, InputNumber, Radio, Row, Select, Space, Upload} from "antd";
import {useContextMenuFunctions} from "../../../function/contextMenuFunctions";

const ImageMenuItems = ({setDetailData, setting, getContainer}) => {
    const {handleFormInputValueChange} = useContextMenuFunctions(setDetailData)

    const handleBeforeUpload = (file) => {
        return false;
    };

    const handleOnChange = ({fileList}) => {
        handleFormInputValueChange(URL.createObjectURL(fileList?.[0]?.originFileObj), "src")
    };

    const props = {
        name: 'file',
        beforeUpload: handleBeforeUpload,
        onChange: handleOnChange,
        maxCount: 1,
        showUploadList: false
    };

    return (
        <>
            <div className={"sections"}>
                <Row justify={"space-between"}>
                    <p className={"margin-bottom-0"}>Source</p>
                    <Upload {...props}>
                        <Button>Upload</Button>
                    </Upload>
                </Row>
            </div>
            <div className={"sections"}>
                <p>Size</p>
                <Radio.Group
                    value={setting?.sizeAuto ? "auto" : "custom"}
                    className={"margin-top-0-3"}
                    onChange={(value) => {
                        handleFormInputValueChange(value.target.value === "auto", "sizeAuto")
                        if (value.target.value === "auto") {
                            handleFormInputValueChange("100%", "width")
                            handleFormInputValueChange("100%", "height")
                        }
                    }}
                >
                    <Space direction="vertical">
                        <Radio value={"auto"}>Automatic</Radio>
                        <Radio value={"custom"}>Custom</Radio>
                    </Space>
                </Radio.Group>
                {
                    !setting?.sizeAuto &&
                    <Row className={"margin-top-0-5"} gutter={8}>
                        <Col span={12}>
                            <p>Width</p>
                            <InputNumber className={"w-100"}
                                         value={setting?.width}
                                         onChange={value => handleFormInputValueChange(value, "width")}
                            />
                        </Col>
                        <Col span={12}>
                            <p>Height</p>
                            <InputNumber className={"w-100"}
                                         value={setting?.height}
                                         onChange={value => handleFormInputValueChange(value, "height")}
                            />
                        </Col>
                    </Row>
                }
                {/*<p>Background</p>*/}
            </div>
            <div className={"sections"}>
                <p>What's inside the image?</p>
                <Input placeholder={"Add Alt Text Here"}
                       onChange={e => handleFormInputValueChange(e.target.value, "alt")}
                />
            </div>
            <div className={"sections"}>
                {/*<p>Object Fit</p>*/}
                {/*<Select getPopupContainer={getContainer} options={[*/}
                {/*    {label: "None", value: "none"},*/}
                {/*    {label: "Fill", value: "fill"},*/}
                {/*    {label: "Contain", value: "contain"},*/}
                {/*    {label: "Cover", value: "cover"},*/}
                {/*    {label: "Scale Down", value: "scale-down"},*/}
                {/*]}*/}
                {/*        onChange={value => handleFormInputValueChange(value, "objectFit")}*/}
                {/*/>*/}

                <p className={"margin-top-0-75"}>Tooltip</p>
                <Input placeholder={"Enter tooltip here"}
                       onChange={e => handleFormInputValueChange(e.target.value, "tooltip")}
                />
            </div>
        </>
    )
}

export default ImageMenuItems