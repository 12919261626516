import React, {useEffect, useState} from "react";
import {Button, Col, Modal, Row, Spin} from "antd";
import {useTranslation} from "react-i18next";
import {getCommissionCondition, getCommissionParameter} from "../../../../../../../api/graphql/commission";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import useAuthorize from "../../../../../../../_common/authorize";
import ParameterCard from "./parameterCard";
import ParameterEditModal from "./parameterEditModal";


const ParameterModal = (props) => {

    const {
        isOpen,
        closeModal,
        data,
        rankData,
        userData,
    } = props;
    const {t} = useTranslation()

    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {systemErrorNotification} = useAuthorize()
    const [loading, setLoading] = useState(false)
    const [multiRankData, setMultiRankData] = useState([])
    const [parameter, setParameter] = useState([])
    const [addModal, setAddModal] = useState(false)
    const [conditions, setConditions] = useState([])

    const getCommissionConditionFunction = async () => {
        const response = await getCommissionCondition();
        const fields: any = []

        for (let i = 0; i < response?.getCommissionCondition?.data?.length; i++) {
            const data = response?.getCommissionCondition?.data[i]
            fields.push({
                ...data
            })
        }

        setConditions(fields)
    }


    const getRank = () => {
        if (data?.commRankType === "some") {
            setMultiRankData(data?.commRankFormatted)
        } else {

            const ranks = rankData?.map((item) => {
                return {
                    label: item?.name,
                    value: item?._id,
                    rank: item?.rank
                }
            })
            setMultiRankData(ranks)
        }
    }

    const getParameter = async () => {
       try{
           await setLoading(true)
           if (data?._id) {
               const response = await getCommissionParameter({
                   rule: data?._id
               })
               setParameter(response?.getCommissionParameter?.data)
           } else {
               await setParameter([])
           }
       }catch (e) {
           console.log(e)
       }finally {
           setLoading(false)
       }
    }

    useEffect(() => {
        getCommissionConditionFunction()
    }, [])

    useEffect(() => {

        const functional = async () => {
            await setParameter([])
            getRank()
            getParameter()
        }

        if (data) {
            functional()
        } else {
            setParameter([])
        }
    }, [data?._id, data?.commRankType, data?.commRankFormatted])

    return (
        <Modal
            title={"Parameter Settings  - " + data?.name + " - Mode: " + data?.commMode}
            open={isOpen}
            onCancel={() => closeModal()}
            footer={null}
            width={1300}
            destroyOnClose
        >
            <Spin spinning={loading}>
                <Row justify="end">
                    <Col style={{marginBottom: "15px"}}>
                        <Button onClick={() => setAddModal(true)}>
                            Add Parameters
                        </Button>
                    </Col>
                </Row>
                {
                    parameter?.map((item) => {
                        return (
                            <div style={{marginBottom: "15px"}}>
                                <ParameterCard rank={multiRankData} data={item} userData={userData} ruleData={data}
                                               getParameter={getParameter} conditions={conditions}/>
                            </div>
                        )
                    })
                }
            </Spin>

            <ParameterEditModal isOpen={addModal} closeModal={() => setAddModal(false)} ruleData={data} type={"add"}
                                getParameter={getParameter}/>


        </Modal>

    )

}

export default ParameterModal