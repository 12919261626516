import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Input, Select, Space, Tabs} from 'antd';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {fieldCategoryState, fieldLabelState, fieldListFormPropState, userTypeState} from '../../state';
import FieldSetting from './component/fieldSetting';
import SettingFields from './component/settingFields';
import FieldSettingModalForm from "./component/fieldSettingModalForm";

const Fields_1 = (props) => {
    const {fieldListPermission, profileInformationPermission} = props;
    const {t} = useTranslation();
    const [currentTab, setCurrentTab] = useState<any>('fields');
    const [items, setItems] = useState<any[]>([]);
    const [categoryList, setCategoryList] = useState<any>([])
    const [category, setCategory] = useRecoilState<any>(fieldCategoryState);
    const setLabel = useSetRecoilState(fieldLabelState);
    const setUserType = useSetRecoilState(userTypeState);
    const fieldListModalFormProp = useRecoilValue(fieldListFormPropState)

    const propsSettingField = useMemo(
        () => ({fieldListPermission, profileInformationPermission, currentTab}),
        [fieldListPermission, profileInformationPermission, currentTab]
    );

    const profileItem = useMemo(
        () => [
            {
                key: 'client',
                label: t('Client Fields'),
                children: <SettingFields {...propsSettingField} tab={"client"}/>,
            },
            {
                key: 'crmUser',
                label: t('CRM User Fields'),
                children: <SettingFields {...propsSettingField} tab={"crmUser"}/>,
            },
            {
                key: 'leads',
                label: t('Leads Fields'),
                children: <SettingFields {...propsSettingField} tab={"leads"}/>,
            },
            {
                key: 'corporate',
                label: t('Corporate Fields'),
                children: <SettingFields {...propsSettingField} tab={"corporate"}/>,
            },
        ],
        [t, propsSettingField]
    );

    const handleTabChange = (key) => {
        setCurrentTab(key);
    };

    useEffect(() => {
        if (fieldListPermission) {
            setItems([
                fieldListPermission?.view && {
                    key: 'fields',
                    label: t('Fields Setting'),
                    children: <FieldSetting fieldListPermission={fieldListPermission}/>,
                },
                ...(profileInformationPermission?.view ? profileItem : []),
            ]);

            setCategory([])
        }
    }, [fieldListPermission, profileItem, profileInformationPermission]);

    useEffect(() => {
        if (currentTab !== 'fields') {
            setUserType(currentTab);

            if (currentTab === 'client' || currentTab === 'crmUser') setCategory(['basicInformation', 'loginInformation', 'residentialInformation']);
            else if (currentTab === 'corporate') setCategory(['corporateInformation', 'additionalInformation', 'loginInformation']);
            else if (currentTab === 'leads') setCategory([]);
        } else setCategory([])
    }, [currentTab, setUserType, setCategory]);

    useEffect(() => {
        if (!fieldListPermission?.view) {
            setCurrentTab('client');
        }
    }, [fieldListPermission]);

    return (
        <div id={'field'}>
            <FieldSettingModalForm {...fieldListModalFormProp}/>
            <h1>{t('Fields')}</h1>
            <Tabs
                defaultActiveKey={currentTab}
                items={items}
                tabBarExtraContent={
                    fieldListPermission?.view && (
                        <Space>
                            <Input.Search placeholder={t('Search')?.toString()} onSearch={(val) => setLabel(val)} allowClear/>
                            <Select
                                style={{width: 200}}
                                placeholder={t('Field Category')}
                                allowClear
                                mode={'multiple'}
                                maxTagCount={1}
                                options={[
                                    ...(currentTab === 'fields' ? [{label: 'All', value: 'all'}] : []),
                                    {label: 'Login', value: 'loginInformation'},
                                    ...(currentTab !== 'corporate' ? [
                                        {label: 'Basic', value: 'basicInformation'},
                                        {label: 'Residential', value: 'residentialInformation'}] : []),
                                    ...(currentTab !== 'client' && currentTab !== 'crmUser' && currentTab !== 'leads'
                                        ? [
                                            {label: 'Additional', value: 'additionalInformation'},
                                            {label: 'Corporate', value: 'corporateInformation'}
                                        ]
                                        : []),
                                ]}
                                value={category}
                                onChange={(val) => {
                                    if (val.includes("all")) {
                                        setCategory([])
                                    } else {
                                        setCategory(val)
                                    }
                                }}
                            />
                        </Space>
                    )
                }
                onTabClick={handleTabChange}
            />
        </div>
    );
};

export default Fields_1;
