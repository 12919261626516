import React from "react";
import {ColumnsType} from "antd/es/table";
import { BsCheck, BsX } from "react-icons/bs";
import { Image } from "antd";


export const columns: ColumnsType<any> = [
    {
        title: "",
        dataIndex: "imageLight",
        key: "imageLight",
        render: (field) => {
            if(field?.length > 0){
                return <Image className="tableIcon" src={field[0]?.path} alt="logo" />
            }
            else {
                return <></>
            }
        },
    },
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        defaultSortOrder: "ascend",
        sorter: (a, b) => a?.name?.localeCompare(b?.name),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Display Name",
        dataIndex: "displayName",
        key: "displayName",
        defaultSortOrder: "ascend",
        sorter: (a, b) => a?.displayName?.filter(item => item.language === "en")?.[0]?.content.localeCompare(b?.displayName?.filter(item => item.language === "en")?.[0]?.content),
        render: (_, record) => <p>{record?.displayName?.filter(item => item.language === "en")?.[0]?.content}</p>,
    },
    {
        title: "Reward Type",
        dataIndex: "rewardType",
        key: "rewardType",
        sorter: (a, b) => a?.rewardType?.localeCompare(b?.rewardType),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Voucher Type",
        key: "voucherType",
        dataIndex: "voucherType",
        sorter: (a, b) => a?.voucherType?.localeCompare(b?.voucherType),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Voucher Value",
        key: "voucherValue",
        dataIndex: "voucherValue",
        render: (_, record) => {
            if(record?.voucherValueIsRange){
                return <p>{record?.voucherValueStartRange} ~ {record?.voucherValueEndRange}</p>
            }
            else{
                return <p>{record?.voucherValue}</p>
            }
        },
    },
    {
        title: "Term and Conditions Enabled",
        dataIndex: "termAndConditionEnabled",
        key: "termAndConditionEnabled",
        sorter: (a, b) => a?.termAndConditionEnabled - b?.termAndConditionEnabled,
        render: (text) => <p>{text.toString() === "true"? <BsCheck style={{ color: 'green' }}/> : <BsX style={{ color: 'red' }}/>}</p>,
    },
    {
        title: "Term and Conditions",
        dataIndex: "termAndCondition",
        key: "termAndCondition",
        width: 250,
        sorter: (a, b) => a?.termAndCondition?.filter(item => item.language === "en")?.[0]?.content.localeCompare(b?.termAndCondition?.filter(item => item.language === "en")?.[0]?.content),
        render: (_, record) => {
            if(record?.termAndCondition?.filter(item => item.language === "en").length > 0){
                return <p>{record?.termAndCondition?.filter(item => item.language === "en")?.[0]?.content?.substring(0, 30)}...</p>
            }
            else {
                return <></>
            }
        },
    },
];
