import React from "react";
import {Modal} from "antd";
import {useTranslation} from "react-i18next";
import VoucherGroupForm from "./voucherGroupForm";

const VoucherGroupModal = (props) => {
    const {mode, item, isOpen, closeFormModal} = props;
    const {t} = useTranslation();

    const getTitle = (): String => {
        switch (mode) {
            case "add":
                return `${t("Add New Voucher Group")}`;
            case "view":
                return `${t("View Voucher Group")}`;
            case "edit":
                return `${t("Edit Voucher Group")}`;
            default:
                return `${t("Add New Voucher Group")}`;
        }
    };

    return (
        <Modal
            getContainer="#voucherGroup"
            title={getTitle()}
            open={isOpen}
            width={"80%"}
            footer={null}
            closable={true}
            destroyOnClose
            onCancel={closeFormModal}
        >
            <VoucherGroupForm 
                mode={mode} 
                item={item} 
                closeFormModal={closeFormModal} 
                languageData={props.languageData} 
                voucher={props.voucher}
            />
        </Modal>
    );
};

export default VoucherGroupModal;
