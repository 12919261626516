import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Input, Row, Select, Space} from "antd";
import {useTranslation} from "react-i18next";
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    DeleteOutlined,
    EditOutlined,
    MinusCircleOutlined,
    PlusOutlined
} from "@ant-design/icons";
import Paragraph from "antd/es/typography/Paragraph";
import {useRecoilValue} from "recoil";
import {rankOptionsState} from "../../../../../../users/_common/state";


const ParameterRankCriteriaCard = (props) => {

    const {
        rankAbove,
        tierId,
        tierCriteria,
        setTierCriteria,
        tier,
        deleteTier,
        initialCriteria,
        moveUp,
        moveDown,
        conditions,
        commType,
        position,
        totalPosition
    } = props;
    const {t} = useTranslation()

    const [openCriteria, setOpenCriteria] = useState(false)
    const [initialRun, setInitialRun] = useState(false)
    const [tierName, setTierName] = useState<any>("")
    const [tierRank, setTierRank] = useState<any>([])
    const [tierLevel, setTierLevel] = useState<any>([])
    const [criteriaCondition, setCriteriaCondition] = useState<any>([])
    const [criteriaGroup, setCriteriaGroup] = useState<any>([])
    const rankOptions = useRecoilValue(rankOptionsState)

    const logicalOperator = [
        {
            label: "AND",
            value: "AND"
        },
        {
            label: "OR",
            value: "OR"
        },
    ]

    const comparisonOperator = [
        {
            label: "=",
            value: "="
        },
        {
            label: "≥",
            value: ">="
        },
        {
            label: ">",
            value: ">"
        },
        {
            label: "≤",
            value: "<="
        },
        {
            label: "<",
            value: "<"
        },
        {
            label: "≠",
            value: "<>"
        },
    ]

    const getCriteriaCondition = () => {
        const items: any = [];
        for (const condition of conditions) {
            items.push({
                label: condition?.fieldName + " - " + condition?.reportName,
                value: condition?.key
            })
        }
        setCriteriaCondition([...items, {label: "Rank", value: "rank"}])
    }

    const setLogicalOperator = (value, batch) => {

        try {
            const updatedCriteriaGroup = criteriaGroup.map((item) => {
                if (item.batchId === batch) {
                    return {...item, logicalOperator: value};
                }
                return item; // Keep other objects unchanged
            });

            setCriteriaGroup(updatedCriteriaGroup)
            setTierCriteria((prevTierCriteria: any = []) => {
                return prevTierCriteria.map((item) => {
                    if (item.tierId === tierId) {
                        return {
                            ...item, criteriaGroup: updatedCriteriaGroup
                        };
                    }
                    return item;
                });
            });
        } catch (e) {
            console.log(e)
        }
    };

    const rankAction = (event, id) => {
        try {
            const value = event?.target?.value

            if (commType === "rank") {
                let foundItem = tierRank?.find((item) => item?.rank === id)

                if (foundItem) {
                    const updatedTierRank = tierRank.map((item) => {
                        if (item.rank === id) {
                            return {rank: id, value: value};
                        }
                        return item; // Keep other objects unchanged
                    });
                    setTierRank(updatedTierRank)
                    setTierCriteria((prevTierCriteria: any = []) => {
                        return prevTierCriteria.map((item) => {
                            if (item.tierId === tierId) {
                                return {...item, rankAmount: updatedTierRank};
                            }
                            return item;
                        });
                    });
                } else {

                    let items = tierRank || []

                    const newObj = {
                        rank: id,
                        value: value,
                    }

                    items.push(newObj)
                    setTierRank(items)
                    setTierCriteria((prevTierCriteria: any = []) => {
                        return prevTierCriteria.map((item) => {
                            if (item.tierId === tierId) {
                                return {...item, rankAmount: items};
                            }
                            return item;
                        });
                    });
                }

            } else {
                let foundItem = tierLevel?.find((item) => item?.level === id)

                if (foundItem) {
                    const updatedTierRank = tierLevel.map((item) => {
                        if (item.level === id) {
                            return {level: id, value: value};
                        }
                        return item; // Keep other objects unchanged
                    });
                    setTierLevel(updatedTierRank)
                    setTierCriteria((prevTierCriteria: any = []) => {
                        return prevTierCriteria.map((item) => {
                            if (item.tierId === tierId) {
                                return {...item, levelAmount: updatedTierRank};
                            }
                            return item;
                        });
                    });
                } else {

                    let items = tierLevel || []

                    const newObj = {
                        level: id,
                        value: value,
                    }

                    items.push(newObj)
                    setTierLevel(items)
                    setTierCriteria((prevTierCriteria: any = []) => {
                        return prevTierCriteria.map((item) => {
                            if (item.tierId === tierId) {
                                return {...item, levelAmount: items};
                            }
                            return item;
                        });
                    });
                }

            }


        } catch (e) {
            console.log(e)
        }

    };

    const tierNameAction = async (event) => {
        try {
            await setTierName(event?.target?.value)

            setTierCriteria((prevTierCriteria: any = []) => {
                return prevTierCriteria.map((item) => {
                    if (item.tierId === tierId) {
                        return {...item, name: event?.target?.value};
                    }
                    return item;
                });
            });

        } catch (e) {
            console.log(e)
        }

    };

    const addCriteriaGroup = () => {
        try {
            const largestBatchId = criteriaGroup.reduce((maxBatchId, obj) => {
                return obj.batchId > maxBatchId ? obj.batchId : maxBatchId;
            }, -1);

            const newCriteriaGroup = [...criteriaGroup, {
                batchId: largestBatchId + 1 || 1,
                form: React.createRef(),
                criteria: [{comparisonOperator: null, type: null, value: null}],
            }]

            setCriteriaGroup(newCriteriaGroup);

            setTierCriteria((prevTierCriteria: any = []) => {
                return prevTierCriteria.map((item) => {
                    if (item.tierId === tierId) {
                        return {
                            ...item, criteriaGroup: newCriteriaGroup
                        };
                    }
                    return item;
                });
            });
        } catch (e) {
            console.log(e)
        }
    };

    const removeCriteriaGroup = (indexToFilter) => {
        try {
            const filteredArray = criteriaGroup.filter((element) => element?.batchId !== indexToFilter);
            setCriteriaGroup(filteredArray);

            setTierCriteria((prevTierCriteria: any = []) => {
                return prevTierCriteria.map((item) => {
                    if (item.tierId === tierId) {
                        return {
                            ...item, criteriaGroup: filteredArray
                        };
                    }
                    return item;
                });
            });
        } catch (e) {
            console.log(e)
        }


    };

    const onFinish = (form, batch) => {
        try {
            const formFound = form.current;
            if (formFound) {
                const values = formFound.getFieldsValue();

                const updatedCriteriaGroup = criteriaGroup.map((item) => {
                    if (item.batchId === batch) {
                        return {...item, criteria: values?.["criteria" + batch]};
                    }
                    return item; // Keep other objects unchanged
                });

                setTierCriteria((prevTierCriteria: any = []) => {
                    return prevTierCriteria.map((item) => {
                        if (item.tierId === tierId) {
                            return {
                                ...item, criteriaGroup: updatedCriteriaGroup
                            };
                        }
                        return item;
                    });
                });

                setCriteriaGroup(updatedCriteriaGroup)

            }
        } catch (e) {
            console.log(e)
        }

    }

    const preload = async () => {
        const functional = () => {
            let foundItem = tierCriteria?.find((item) => item?.tierId === tierId)
            setTierName(foundItem?.name || "")

            if (commType === "rank") {
                setTierRank(foundItem?.rankAmount || [])
            } else {
                setTierLevel(foundItem?.levelAmount || [])
            }

            setCriteriaGroup(foundItem?.criteriaGroup || [])
            if (foundItem) {
                setTierCriteria((prevTierCriteria: any = []) => {
                    return prevTierCriteria.map((item) => {
                        if (item.tierId === tierId) {
                            return {
                                ...item,
                                rankAmount: foundItem?.rankAmount,
                                levelAmount: foundItem?.levelAmount,
                                name: foundItem?.name,
                                criteriaGroup: foundItem?.criteriaGroup || []
                            };
                        }
                        return item;
                    });
                });
            } else {
                setTierCriteria((prevTierCriteria: any = []) => {
                    const newObj = {
                        tierId,
                        name: tierName,
                        criteriaGroup: criteriaGroup || [],
                        rankAmount: tierRank,
                        levelAmount: tierLevel,
                    };
                    return [...prevTierCriteria, newObj];
                });
            }

        }

        await functional()
        setInitialRun(true)
    }

    // First useEffect
    useEffect(() => {
        if (initialCriteria) {
            const runPreload = async () => {
                await preload();
            };
            runPreload();
        }
        getCriteriaCondition()
    }, [initialCriteria, commType]);


    return (
        <>
            {
                initialRun && (
                    <Card style={{background: "#fafafa"}} bordered={false}>
                        <Row align="middle" gutter={16}>
                            <Col span={3} style={{marginBottom: "15px"}}>
                                <h5>Name/Tier</h5>
                                <Input onKeyUp={tierNameAction} defaultValue={tierName}/>
                            </Col>
                            <Col span={2}>
                                <Row style={{marginTop: "15px"}}>
                                    <Space>
                                        <Button type={"link"} style={{padding: "unset"}} onClick={() => {
                                            openCriteria ? setOpenCriteria(false) : setOpenCriteria(true)
                                        }}>
                                            <EditOutlined/>
                                        </Button>
                                        <Button type={"link"} style={{padding: "unset", color: "red"}} onClick={() => {
                                            deleteTier(tierId)
                                        }}>
                                            <DeleteOutlined/>
                                        </Button>
                                        {/*{*/}
                                        {/*    position !== 1 && (*/}
                                        {/*        <Button type={"link"} style={{padding: "unset"}} onClick={() => {*/}
                                        {/*            moveUp(tierId)*/}
                                        {/*        }}>*/}
                                        {/*            <ArrowUpOutlined/>*/}
                                        {/*        </Button>*/}
                                        {/*    )*/}
                                        {/*}*/}

                                        {/*{*/}
                                        {/*    position !== totalPosition &&(*/}
                                        {/*        <Button type={"link"} style={{padding: "unset"}} onClick={() => {*/}
                                        {/*            moveDown(tierId)*/}
                                        {/*        }}>*/}
                                        {/*            <ArrowDownOutlined/>*/}
                                        {/*        </Button>*/}
                                        {/*    )*/}
                                        {/*}*/}

                                    </Space>
                                </Row>
                            </Col>
                            <Col span={17} offset={1}>
                                <Row gutter={16} align={"bottom"}>
                                    {
                                        commType === "rank" ? (
                                            <>
                                                {
                                                    rankAbove?.sort((a, b) => {
                                                        return b.rank - a.rank;
                                                    })?.map((item) => {
                                                        const value = tierRank?.find((i) => i?.rank === item?.value)?.value
                                                        return (
                                                            <Col span={4} style={{textAlign: "center", marginBottom: "15px"}}>
                                                                <Paragraph className={"margin-bottom-0"} ellipsis={{rows: 2, tooltip: true}}>
                                                                    <h5 style={{fontSize: ".83em", height: 32}}>{item?.label}</h5>
                                                                </Paragraph>
                                                                <Input defaultValue={value}
                                                                       onKeyUp={(e) => rankAction(e, item?.value)}/>
                                                            </Col>
                                                        )
                                                    })
                                                }
                                            </>
                                        ) : (
                                            <>
                                                {
                                                    rankAbove?.sort((a, b) => {
                                                        return a.level - b.level;
                                                    })?.map((item) => {

                                                        const value = tierLevel?.find((i) => i?.level === item?.level)?.value

                                                        return (
                                                            <Col span={4} key={item?.level}
                                                                 style={{textAlign: "center", marginBottom: "15px"}}>
                                                                <Paragraph className={"margin-bottom-0"} ellipsis={{rows: 2, tooltip: true}}>
                                                                    <h5 style={{fontSize: ".83em", height: 32}}>{"Level " + item?.level}</h5>
                                                                </Paragraph>
                                                                <Input defaultValue={value}
                                                                       onKeyUp={(e) => rankAction(e, item?.level)}/>
                                                            </Col>
                                                        )
                                                    })
                                                }
                                            </>
                                        )
                                    }

                                </Row>
                            </Col>
                        </Row>

                        {
                            openCriteria && (
                                <>

                                    <Col span={24} style={{textAlign: "end"}}>
                                        <Button onClick={addCriteriaGroup}>
                                            Add Criteria Group
                                        </Button>
                                    </Col>
                                    {criteriaGroup.map((detail, index) => {

                                        const keys = criteriaGroup?.length - 1
                                        const logicalOperatorValue = criteriaGroup?.find((item) => item?.batchId === detail.batchId)?.logicalOperator
                                        return (
                                            <Row gutter={16}>
                                                <Col span={24}>
                                                    <>
                                                        <Card size={"small"} style={{background: "#efefef", marginTop: "15px"}}
                                                              bordered={false}>
                                                            <Form
                                                                name={"dynamic_form_nest_item-" + detail.batchId}
                                                                ref={detail.form}
                                                                autoComplete="off"
                                                                key={detail.batchId}
                                                            >
                                                                <Form.List key={detail.batchId}
                                                                           name={"criteria" + detail?.batchId}
                                                                           initialValue={detail?.criteria}>
                                                                    {(fields, {add, remove}) => {
                                                                        return (
                                                                            <>
                                                                                <Row align={"middle"} className={"margin-bottom-0-5"}>
                                                                                    <Col xs={12} sm={20}>
                                                                                        <span>{t("Condition Group")} {index + 1}</span>
                                                                                    </Col>
                                                                                    <Col className={"text-align-right padding-right-0-75"} xs={12} sm={4}>
                                                                                        <Space>
                                                                                            <Button type="link"
                                                                                                    style={{padding: "unset"}}
                                                                                                    onClick={() => add()}
                                                                                                // icon={<PlusOutlined/>}
                                                                                            >
                                                                                                Add Criteria
                                                                                            </Button>
                                                                                            <Button type={"link"}
                                                                                                    style={{padding: "unset"}}
                                                                                                    danger
                                                                                                    onClick={() => removeCriteriaGroup(detail?.batchId)}>
                                                                                                <DeleteOutlined/>
                                                                                            </Button>
                                                                                        </Space>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Card className={"condition-item-container"}>
                                                                                    {fields.map(({
                                                                                                     key,
                                                                                                     name,
                                                                                                     ...restField
                                                                                                 }) => {
                                                                                        const init = detail?.criteria?.[key]

                                                                                        return (
                                                                                            <Row gutter={20} justify={"center"}>
                                                                                                <Col span={2}>
                                                                                                    {
                                                                                                        key > 0 && (
                                                                                                            <Form.Item
                                                                                                                {...restField}
                                                                                                                name={[name, 'logicalOperator']}
                                                                                                                initialValue={init?.logicalOperator}
                                                                                                            >
                                                                                                                <Select
                                                                                                                    style={{width: "100%"}}
                                                                                                                    options={logicalOperator}
                                                                                                                    onChange={() => onFinish(detail.form, detail.batchId)}/>
                                                                                                            </Form.Item>
                                                                                                        )
                                                                                                    }
                                                                                                </Col>
                                                                                                <Col span={6}>
                                                                                                    <Form.Item
                                                                                                        {...restField}
                                                                                                        name={[name, 'type']}
                                                                                                        initialValue={init?.type}
                                                                                                    >
                                                                                                        <Select
                                                                                                            style={{width: "100%"}}
                                                                                                            options={criteriaCondition}
                                                                                                            onChange={() => onFinish(detail.form, detail.batchId)}/>
                                                                                                    </Form.Item>
                                                                                                </Col>
                                                                                                <Col span={2}>
                                                                                                    <Form.Item
                                                                                                        {...restField}
                                                                                                        name={[name, 'comparisonOperator']}
                                                                                                        initialValue={init?.comparisonOperator}
                                                                                                    >
                                                                                                        <Select
                                                                                                            style={{width: "100%"}}
                                                                                                            options={comparisonOperator}
                                                                                                            onChange={() => onFinish(detail.form, detail.batchId)}/>
                                                                                                    </Form.Item>
                                                                                                </Col>
                                                                                                <Col span={5}>
                                                                                                    <Form.Item
                                                                                                        {...restField}
                                                                                                        name={[name, 'value']}
                                                                                                        initialValue={init?.value}
                                                                                                    >
                                                                                                        {
                                                                                                            init?.type === "rank" ? (
                                                                                                                <Select
                                                                                                                    style={{width: "100%"}}
                                                                                                                    options={rankOptions}
                                                                                                                    onChange={() => onFinish(detail.form, detail.batchId)}
                                                                                                                />
                                                                                                            ) : (
                                                                                                                <Input
                                                                                                                    style={{width: "100%"}}
                                                                                                                    onKeyUp={() => onFinish(detail.form, detail.batchId)}/>
                                                                                                            )
                                                                                                        }
                                                                                                    </Form.Item>
                                                                                                </Col>
                                                                                                <Col span={1}>
                                                                                                    <Button
                                                                                                        type={"link"}
                                                                                                        danger
                                                                                                        onClick={() => {
                                                                                                            remove(name)
                                                                                                            onFinish(detail.form, detail.batchId)
                                                                                                        }}
                                                                                                        icon={<DeleteOutlined/>}
                                                                                                    />
                                                                                                </Col>
                                                                                            </Row>
                                                                                        )
                                                                                    })}
                                                                                </Card>
                                                                            </>
                                                                        )
                                                                    }}
                                                                </Form.List>
                                                            </Form>

                                                        </Card>
                                                        {
                                                            criteriaGroup?.length > 0 && index !== keys && (
                                                                <Col span={24}
                                                                     style={{textAlign: "center", marginTop: "15px"}}>
                                                                    <Select style={{width: "6%"}}
                                                                            value={logicalOperatorValue}
                                                                            options={logicalOperator}
                                                                            onChange={(e) => setLogicalOperator(e, detail.batchId)}/>
                                                                </Col>
                                                            )
                                                        }
                                                    </>
                                                </Col>
                                            </Row>
                                        )
                                    })}

                                </>
                            )
                        }
                    </Card>
                )
            }
        </>
    )

}

export default ParameterRankCriteriaCard