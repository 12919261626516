import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, Modal, Row, Select, Spin} from "antd";
import {useTranslation} from "react-i18next";
import {
    accEnvMode,
    accountType,
    calcFrequency,
    calculationNetworkType,
    coEfficientType,
    commMode,
    commPayout,
    commRanksType,
    commType,
    payoutNetworkType,
    productsType,
    unpaidComm
} from "../../../function/ruleSettingData";
import {addCommissionRule, updateCommissionRule} from "../../../../../../../api/graphql/commission";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import useAuthorize from "../../../../../../../_common/authorize";
import {getProduct} from "../../../../../../../api/graphql/product";
import {getTradingAccount} from "../../../../../../../api/graphql/tradingAccount";
import {InputNumber} from "antd/lib";
import {getReportingFieldsByReportId, getReportingTypes} from "../../../../../../../api/graphql/reporting";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";


const RuleSettingModal = (props) => {

    const {
        editData,
        modalType,
        isOpen,
        closeModal,
        commSetting,
        userData,
        commGroup,
        rankData,
        preload
    } = props;
    const {t} = useTranslation()
    const labelCol = {span: 24, offset: 0}
    const [symbolType, setSymbolType] = useState("")
    const [networkType, setNetworkType] = useState("")
    const [accMode, setAccMode] = useState("")
    const [accProductType, setAccProductType] = useState([])
    const [commTypeData, setCommTypeData] = useState("")
    const [accType, setAccType] = useState("")
    const [productType, setProductType] = useState("")
    const [commRankType, setCommRankType] = useState("")
    const [commGroupSetting, setCommGroupSetting] = useState(null)
    const [submitBtn, setSubmitBtn] = useState(true)
    const [product, setProduct] = useState([]);
    const [accounts, setAccounts] = useState<any>([]);
    const [user, setUser] = useState([]);
    const [rank, setRank] = useState([]);
    const [fields, setFields] = useState<any>([]);
    const [fieldsSelect, setFieldsSelect] = useState<any>([]);
    const [loading, setLoading] = useState<any>(false)
    const [isCommPayoutChanged, setIsCommPayoutChanged] = useState(false);
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {systemErrorNotification} = useAuthorize()

    const [form] = Form.useForm();

    const comparisonOperator = [
        {
            label: "=",
            value: "E"
        },
        {
            label: "≥",
            value: "GTE"
        },
        {
            label: ">",
            value: "GT"
        },
        {
            label: "≤",
            value: "LTE"
        },
        {
            label: "<",
            value: "LT"
        },
        {
            label: "≠",
            value: "NE"
        },
        {
            label: "Include",
            value: "CONTAINS"
        },
    ]

    const getFields = async () => {

        const response = await getReportingFieldsByReportId(commSetting?.reportId)
        const types = await getReportingTypes()

        const typesResult = types?.getReportingTypes?.data

        const fieldSelectData: any = []
        const fieldCalculatedSelectData: any = []
        const fieldsArray: any = []

        if (response?.getReportingFieldsByReportId?.data && response?.getReportingFieldsByReportId?.data?.length > 0) {
            for (const field of response?.getReportingFieldsByReportId?.data) {
                fieldSelectData.push({
                    label: field?.uiName,
                    value: field?.reportFieldName,
                    typeId: field?.typeId
                })

                if (field?.typeId === 2 || field?.typeId === 6) {
                    fieldCalculatedSelectData.push({
                        label: field?.uiName,
                        value: field?.reportFieldName,
                        typeId: field?.typeId
                    })
                }


                fieldsArray.push({
                    ...field,
                    fieldType: typesResult?.find((item) => item?._id === field?.typeId)
                })

            }
        }

        setFieldsSelect(fieldSelectData)
        setFields(fieldsArray)
    }

    const allProduct = getProduct({
        filter: {
            productType: accProductType
        }
    });

    const allAccounts = getTradingAccount({
        filter: {
            accountId: null
        }
    });

    const preloadAccounts = async () => {
        const products = allAccounts?.data?.getTradingAccount?.data?.map((item) => {
            return {
                label: item?.user?.username + " - " + item?.accountId + "(" + item?.platform + ")",
                value: item?._id
            }
        })

        setAccounts(products)
    }

    const preloadProduct = async () => {
        const products = allProduct?.data?.getProduct?.data?.map((item) => {
            return {
                label: item?.name,
                value: item?._id
            }
        })

        setProduct(products)
    }

    const preloadRank = async () => {
        if (rankData?.length > 0) {

            const ranks = rankData?.map((item) => {
                return {
                    label: item?.name,
                    value: item?._id,
                    rank: item?.rank
                }
            })
            ranks?.sort((a, b) => a.rank - b.rank);
            setRank(ranks)
        }
    }

    const preloadUser = async () => {

        const users = userData?.map((item) => {

            let label = item?.username;
            if (item?.identificationNo) {
                label += ` - ${item?.identificationNo}`;
            }
            if (item?.email) {
                label += ` (${item?.email})`;
            }
            return {
                label: label,
                value: item?._id
            }
        })
        setUser(users)
    }

    useEffect(() => {
        preloadRank()
    }, [rankData])

    useEffect(() => {
        preloadProduct()
    }, [allProduct?.loading])

    useEffect(() => {
        preloadAccounts()
    }, [allAccounts?.loading])

    useEffect(() => {
        preloadUser()
    }, [userData])

    useEffect(() => {

        let productType: any = []

        if (accType === "self-trading") {
            if (accMode === "live") {
                productType = ['selfTradeLive']
            } else if (accMode === "demo") {
                productType = ['selfTradeDemo']
            } else {
                productType = ['selfTradeDemo', 'selfTradeLive']
            }
        } else if (accType === "investor") {
            if (accMode === "live") {
                productType = ['investorLive']
            } else if (accMode === "demo") {
                productType = ['investorDemo']
            } else {
                productType = ['investorLive', 'investorDemo']
            }
        } else if (accType === "follower") {
            if (accMode === "live") {
                productType = ['followerLive']
            } else if (accMode === "demo") {
                productType = ['followerDemo']
            } else {
                productType = ['followerLive', 'followerDemo']
            }
        } else if (accType === "all") {
            if (accMode === "live") {
                productType = ['selfTradeLive', 'followerLive', 'investorLive']
            } else if (accMode === "demo") {
                productType = ['selfTradeDemo', 'investorDemo', 'followerDemo']
            } else {
                productType = ['selfTradeLive', 'followerLive', 'investorLive', 'selfTradeDemo', 'investorDemo', 'followerDemo']
            }
        }

        setAccProductType(productType)

    }, [accMode, accType])

    useEffect(() => {
        if (editData && Object.keys(editData).length > 0) {
            form.setFieldsValue(editData);
        } else {
            form.resetFields();
        }

        setSymbolType(editData?.calculationSymbolType)
        setNetworkType(editData?.payoutNetworkType)
        setCommGroupSetting(editData?.payoutCommGroup)
        setProductType(editData?.productType)
        setCommRankType(editData?.commRankType)
        setAccProductType(editData?.accountProductType)
        setAccMode(editData?.accountEnvMode)
        setAccType(editData?.accountType)
        setCommTypeData(editData?.commType)
        setIsCommPayoutChanged(false)
    }, [editData, form]);

    useEffect(() => {
        if (commSetting) {
            setSubmitBtn(false)
            getFields()
        }
    }, [commSetting])

    const onSubmit = async (values) => {
        await setLoading(true)
        try {

            let reportFilterField: any = []

            if (values?.reportFilterField?.length > 0) {
                reportFilterField = values?.reportFilterField?.map((field) => {

                    const foundField = fields?.find((item) => item?.reportFieldName === field?.fieldName)

                    return {
                        ...field,
                        value: field?.value?.toString(),
                        fieldType: foundField?.fieldType?.name
                    }
                })
            }

            if (modalType === "add") {

                const response = await addCommissionRule({
                    ...values,
                    reportFilterField: reportFilterField,
                    accountProductType: accProductType,
                    commissionSetting: commSetting?._id,
                    bonusCoEfficientIncluded: commSetting?.reportId === "64d0a107847e88a7d5978e3a" ? values?.bonusCoEfficientIncluded : false
                })

                if (response?.createCommissionRule?.__typename === "CommissionRule") {
                    setNotificationMessage({
                        status: "success",
                        title: "Added successfully",
                        msg: t("Account tagging might take up to 1-2 mins to be in effect"),
                        key: "CommissionRule",
                    });

                    // await commissionTaggingUser(commSetting?._id)

                    preload()
                    closeModal()
                    setSymbolType("")
                    setNetworkType("")
                    setCommGroupSetting(null)
                    setProductType("")
                    setCommRankType("")


                } else if (response?.createCommissionRule.__typename === "BaseError") {
                    setNotificationMessage({
                        status: "error",
                        title: "",
                        msg: t(response?.createCommissionRule?.errKey),
                        key: "CommissionRule",
                    });
                } else {
                    setNotificationMessage({
                        status: "info",
                        title: "",
                        msg: t(response?.createCommissionRule?.msg),
                        key: "CommissionRule",
                    });
                }
            } else {
                const response = await updateCommissionRule(editData._id, {
                    ...values,
                    reportFilterField: reportFilterField,
                    accountProductType: accProductType,
                    commissionSetting: commSetting?._id,
                    payoutCommGroup: commGroupSetting || "",
                    bonusCoEfficientIncluded: commSetting?.reportId === "64d0a107847e88a7d5978e3a" ? values?.bonusCoEfficientIncluded : false

                })

                if (response?.updateCommissionRule?.__typename === "CommissionRule") {
                    setNotificationMessage({
                        status: "success",
                        title: t("common.updateSuccess"),
                        msg: t("Account tagging might take up to 1-2 mins to be in effect"),
                        key: "CommissionRule",
                    });

                    // await commissionTaggingUser(commSetting?._id)

                    preload()
                    closeModal()
                    setSymbolType("")
                    setNetworkType("")
                    setCommGroupSetting(null)
                    setProductType("")
                    setCommRankType("")


                } else if (response?.updateCommissionRule.__typename === "BaseError") {
                    setNotificationMessage({
                        status: "error",
                        title: "",
                        msg: t(response?.updateCommissionRule?.errKey),
                        key: "CommissionRule",
                    });
                } else {
                    setNotificationMessage({
                        status: "info",
                        title: "",
                        msg: t(response?.updateCommissionRule?.msg),
                        key: "CommissionRule",
                    });
                }
            }

        } catch (e) {
            systemErrorNotification()
        } finally {
            await setLoading(false)
        }
    }

    const addTypeIdFunction = (e, key) => {
        const fieldValues = form.getFieldValue("reportFilterField")
        const fieldData = fieldValues?.map((v) => {

            const foundFieldTypeId = fieldsSelect?.find((item) => item?.value === v?.fieldName)

            return {
                ...v,
                typeId: foundFieldTypeId?.typeId
            }

        })

        form.setFieldsValue({
            reportFilterField: fieldData
        })

    }

    const commPayoutOnChange = (value) => {
        if (modalType === "edit" && value !== editData?.commPayout) setIsCommPayoutChanged(true)
        else setIsCommPayoutChanged(false)
    }

    return (
        <Modal
            title={modalType === "add" ? "Add Rule" : "Edit Rule"}
            open={isOpen}
            onCancel={() => closeModal()}
            footer={null}
            width={1000}
            destroyOnClose={true}
        >
            <Spin spinning={loading}>
                {
                    (modalType === "add" || editData && Object.keys(editData)?.length > 0) && (
                        <Form form={form} onFinish={onSubmit}>

                            <Row gutter={[16, 0]} style={{marginTop: "15px"}}>

                                <Col span={24}>
                                    <h4>
                                        Comm Rule setting
                                    </h4>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        labelCol={labelCol}
                                        label={t("Rule Name")}
                                        name={"name"}
                                        rules={[{required: true}]}
                                        initialValue={editData?.name}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        labelCol={labelCol}
                                        label={t("Comm Group")}
                                        name={"payoutCommGroup"}
                                        initialValue={editData?.payoutCommGroup}
                                    >
                                        <Select options={commGroup} allowClear onClear={() => setCommGroupSetting(null)}
                                                onChange={(e) => setCommGroupSetting(e)} showSearch
                                                optionFilterProp={"label"}/>
                                    </Form.Item>
                                </Col>

                                {
                                    commSetting?.commissionTagging === "Trading Account" && (
                                        <Col span={12}>
                                            <Form.Item
                                                labelCol={labelCol}
                                                label={t("Environment Mode")}
                                                name={"accountEnvMode"}
                                                rules={[{required: true}]}
                                                initialValue={editData?.accountEnvMode}
                                            >
                                                <Select options={accEnvMode} showSearch optionFilterProp={"label"}
                                                        onChange={(e) => setAccMode(e)}/>
                                            </Form.Item>
                                        </Col>
                                    )
                                }


                                <Col span={12}>
                                    <Form.Item
                                        labelCol={labelCol}
                                        label={t("Network")}
                                        name={"payoutNetworkType"}
                                        initialValue={editData?.payoutNetworkType}
                                    >
                                        <Select options={payoutNetworkType} showSearch optionFilterProp={"label"}
                                                disabled={!commGroupSetting ? false : true}
                                                onChange={(e) => setNetworkType(e)}/>
                                    </Form.Item>
                                    {
                                        networkType === "partial" && (
                                            <Form.Item
                                                labelCol={labelCol}
                                                name={"payoutSomeUsers"}
                                                initialValue={editData?.payoutSomeUsers}
                                            >
                                                <Select mode="multiple" options={user} showSearch
                                                        disabled={!commGroupSetting ? false : true}
                                                        optionFilterProp={"label"}/>
                                            </Form.Item>
                                        )
                                    }
                                </Col>

                                {
                                    commSetting?.commissionTagging === "Trading Account" && (
                                        <Col span={12}>
                                            <Form.Item
                                                labelCol={labelCol}
                                                label={t("Account Type")}
                                                name={"accountType"}
                                                rules={[{required: true}]}
                                                initialValue={editData?.accountType}
                                            >
                                                <Select options={accountType} showSearch optionFilterProp={"label"}
                                                        onChange={(e) => setAccType(e)}/>
                                            </Form.Item>
                                        </Col>
                                    )
                                }


                                <Col span={12}>
                                    {
                                        networkType !== "only me" && (
                                            <Form.Item
                                                labelCol={labelCol}
                                                label={t("Partially Hidden")}
                                                name={"partiallyHidden"}
                                                initialValue={editData?.partiallyHidden}
                                            >
                                                <Select mode="multiple" options={user} showSearch
                                                        disabled={!commGroupSetting ? false : true}
                                                        optionFilterProp={"label"}/>
                                            </Form.Item>
                                        )
                                    }
                                </Col>

                                {
                                    accProductType?.length > 0 && commSetting?.commissionTagging === "Trading Account" && (
                                        <Col span={12}>

                                            <Form.Item
                                                labelCol={labelCol}
                                                label={t("Product")}
                                                name={"productType"}
                                                rules={[{required: true}]}
                                                initialValue={editData?.productType}
                                            >
                                                <Select options={productsType} showSearch optionFilterProp={"label"}
                                                        onChange={(e) => setProductType(e)}/>
                                            </Form.Item>

                                            {
                                                productType === "some" && (
                                                    <Form.Item
                                                        labelCol={labelCol}
                                                        name={"product"}
                                                        initialValue={editData?.product}
                                                    >
                                                        <Select mode="multiple" options={product} allowClear showSearch
                                                                optionFilterProp={"label"}/>
                                                    </Form.Item>
                                                )
                                            }

                                        </Col>
                                    )
                                }
                                {
                                    commSetting?.commissionTagging === "Trading Account" && commSetting?.reportId === "64d0a107847e88a7d5978e3a" && (
                                        <Col span={12}>

                                            <Form.Item
                                                labelCol={labelCol}
                                                label={t("Bonus Coefficient")}
                                                name={"bonusCoEfficientIncluded"}
                                                rules={[{required: true}]}
                                                initialValue={editData?.bonusCoEfficientIncluded}
                                            >
                                                <Select options={coEfficientType} showSearch
                                                        optionFilterProp={"label"}/>
                                            </Form.Item>
                                        </Col>
                                    )
                                }

                            </Row>

                            <Row gutter={[16, 0]} style={{marginTop: "15px"}}>
                                <Col span={24}>
                                    <h4>
                                        Comm payout setting
                                    </h4>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        labelCol={labelCol}
                                        label={t("Comm Mode")}
                                        name={"commMode"}
                                        rules={[{required: true}]}
                                        initialValue={editData?.commMode}
                                    >
                                        <Select options={commMode}
                                                showSearch optionFilterProp={"label"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        labelCol={labelCol}
                                        label={t("Comm Type")}
                                        name={"commType"}
                                        rules={[{required: true}]}
                                        initialValue={editData?.commType}
                                    >
                                        <Select options={commType} onChange={(e) => setCommTypeData(e)} showSearch
                                                optionFilterProp={"label"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        labelCol={labelCol}
                                        label={t("Comm Payout")}
                                        name={"commPayout"}
                                        rules={[{required: true}]}
                                        initialValue={editData?.commPayout}
                                    >
                                        <Select options={commPayout} showSearch optionFilterProp={"label"}
                                                onChange={commPayoutOnChange}/>
                                    </Form.Item>
                                    {
                                        isCommPayoutChanged &&
                                        <p style={{color: "red"}}>* Update comm payout will create a new batch log</p>
                                    }
                                </Col>
                                <Col span={12}>
                                    {
                                        commTypeData === "level" ? (
                                            <Form.Item
                                                labelCol={labelCol}
                                                label={t("Comm Level")}
                                                name={"commLevel"}
                                                rules={[{required: true}]}
                                                initialValue={editData?.commLevel}
                                            >
                                                <InputNumber min={1} step={1} style={{width: "100%"}}/>
                                            </Form.Item>
                                        ) : commTypeData === "rank" ? (
                                            <>
                                                <Form.Item
                                                    labelCol={labelCol}
                                                    label={t("Comm Rank")}
                                                    name={"commRankType"}
                                                    rules={[{required: true}]}
                                                    initialValue={editData?.commRankType}
                                                >
                                                    <Select options={commRanksType} showSearch
                                                            optionFilterProp={"label"}
                                                            onChange={(e) => setCommRankType(e)}/>
                                                </Form.Item>
                                                {
                                                    commRankType === "some" && (
                                                        <Form.Item
                                                            labelCol={labelCol}
                                                            name={"commRank"}
                                                            initialValue={editData?.commRank}
                                                        >
                                                            <Select mode="multiple" options={rank} allowClear showSearch
                                                                    optionFilterProp={"label"}/>
                                                        </Form.Item>
                                                    )
                                                }
                                            </>
                                        ) : null
                                    }
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        labelCol={labelCol}
                                        label={t("Calc. Frequency")}
                                        name={"calcFrequency"}
                                        rules={[{required: true}]}
                                        initialValue={editData?.calcFrequency}
                                    >
                                        <Select options={calcFrequency} showSearch optionFilterProp={"label"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        labelCol={labelCol}
                                        label={t("Unpaid Comm")}
                                        name={"unpaidComm"}
                                        rules={[{required: true}]}
                                        initialValue={editData?.unpaidComm}
                                    >
                                        <Select options={unpaidComm} showSearch optionFilterProp={"label"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        labelCol={labelCol}
                                        label={t("Payout Network")}
                                        name={"calculationNetworkType"}
                                        rules={[{required: true}]}
                                        initialValue={editData?.calculationNetworkType}
                                    >
                                        <Select options={calculationNetworkType} showSearch optionFilterProp={"label"}/>
                                    </Form.Item>
                                </Col>

                                {/*<Col span={12}>*/}
                                {/*    <Form.Item*/}
                                {/*        labelCol={labelCol}*/}
                                {/*        label={t("Deal entry")}*/}
                                {/*        name={"dealEntry"}*/}
                                {/*        initialValue={editData?.dealEntry || "out"}*/}
                                {/*    >*/}
                                {/*        <Select options={dealEntry} showSearch optionFilterProp={"label"}/>*/}
                                {/*    </Form.Item>*/}
                                {/*</Col>*/}
                                {/*<Col span={12}>*/}
                                {/*    <Form.Item*/}
                                {/*        labelCol={labelCol}*/}
                                {/*        label={t("Deal action")}*/}
                                {/*        name={"dealAction"}*/}
                                {/*        initialValue={editData?.dealAction || "all"}*/}
                                {/*    >*/}
                                {/*        <Select options={dealAction} showSearch optionFilterProp={"label"}/>*/}
                                {/*    </Form.Item>*/}
                                {/*</Col>*/}
                                {/*<Col span={12}>*/}
                                {/*    <Form.Item*/}
                                {/*        labelCol={labelCol}*/}
                                {/*        label={t("Deal profit")}*/}
                                {/*        name={"dealProfit"}*/}
                                {/*        initialValue={editData?.dealProfit || "all"}*/}
                                {/*    >*/}
                                {/*        <Select options={dealProfit} showSearch optionFilterProp={"label"}/>*/}
                                {/*    </Form.Item>*/}
                                {/*</Col>*/}
                                {/*<Col span={12}>*/}
                                {/*    <Form.Item*/}
                                {/*        labelCol={labelCol}*/}
                                {/*        label={t("Deal reason")}*/}
                                {/*        name={"dealReason"}*/}
                                {/*        initialValue={editData?.dealReason || 0}*/}
                                {/*    >*/}
                                {/*        <Select options={dealReason} showSearch optionFilterProp={"label"}/>*/}
                                {/*    </Form.Item>*/}
                                {/*</Col>*/}
                            </Row>

                            <Row gutter={[16, 0]} style={{marginTop: "15px"}}>
                                <Col span={24}>
                                    <h4>
                                        Comm Rule Report Filter
                                    </h4>
                                </Col>

                                <Col span={24}>
                                    <label style={{marginBottom: "1%"}}>
                                        Report Filter Field
                                    </label>
                                    <Form.List
                                        name={"reportFilterField"}
                                        initialValue={editData?.reportFilterField}
                                    >
                                        {(fields, {add, remove}) => {
                                            return (
                                                <>
                                                    {fields.map(({
                                                                     key,
                                                                     name,
                                                                     ...restField
                                                                 }) => {

                                                        const init = editData?.reportFilterField?.[key]

                                                        return (
                                                            <Row gutter={20}>
                                                                <Col span={8} offset={1}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'fieldName']}
                                                                        initialValue={init?.fieldName}
                                                                    >
                                                                        <Select options={fieldsSelect} allowClear
                                                                                showSearch
                                                                                onChange={(e) => addTypeIdFunction(e, key)}
                                                                                optionFilterProp={"label"}/>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={5}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'operator']}
                                                                        initialValue={init?.operator}
                                                                    >
                                                                        <Select
                                                                            style={{width: "100%"}}
                                                                            options={comparisonOperator}/>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={8}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'value']}
                                                                        initialValue={init?.value}
                                                                    >
                                                                        {
                                                                            form.getFieldValue("reportFilterField")?.[key]?.typeId === 2 || form.getFieldValue("reportFilterField")?.[key]?.typeId === 6 ? (
                                                                                <InputNumber style={{width: "100%"}}/>
                                                                            ) : (
                                                                                <Input style={{width: "100%"}}/>
                                                                            )
                                                                        }

                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={2}>
                                                                    <MinusCircleOutlined
                                                                        onClick={() => {
                                                                            remove(name)
                                                                        }}/>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    })}
                                                    <Form.Item>
                                                        <Button type="dashed"
                                                                onClick={() => add()}
                                                                style={{marginTop: "15px"}}
                                                                block
                                                                icon={<PlusOutlined/>}>
                                                            Add Criteria
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            )
                                        }}
                                    </Form.List>
                                </Col>
                            </Row>


                            <Col span={24} style={{textAlign: "end", marginTop: "30px"}}>
                                <Button htmlType={"submit"} disabled={submitBtn}>
                                    Submit
                                </Button>
                            </Col>

                        </Form>
                    )
                }
            </Spin>

        </Modal>

    )

}

export default RuleSettingModal