import { gql, useMutation, useQuery } from "@apollo/client";
import { useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { notificationMessage } from "../../../recoil_state";
import { ApolloClientQuery } from "./_apolloClient";

const GET_MULTI_TIER_SETTING_COUNT = gql`
  query getMultiTierSettingCount($filter: MultiTierSettingSearchInput) {
    getMultiTierSettingCount(filter: $filter)
  }
`;

const getMultiTierSettingCount = async ({ filter }) => {
  try {
    const execute = await ApolloClientQuery(
      GET_MULTI_TIER_SETTING_COUNT,
      {
        filter: {
          name: filter?.name || null,
          rank: filter?.rank || null,
          type: filter?.type || null,
          rankRange: filter?.rankRange || null,
        },
        context: {
          headers: {
            authorization: "Bearer " + localStorage.getItem("a_t"),
          },
        },
      },
      false
    );

    return execute;
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_MULTI_TIER_SETTING_BY_ID = gql`
  query getMultiTierSettingByID($id: String!) {
    getMultiTierSettingByID(id: $id) {
      ... on MultiTierSetting {
        _id
        id
        name
        rank
        type
        userCount
        isDefaultRank
        createdBy {
          username
        }
        updatedBy {
          username
        }
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getMultiTierSettingById = async (id) => {
  try {
    const execute = await ApolloClientQuery(
      GET_MULTI_TIER_SETTING_BY_ID,
      {
        id: id,
        context: {
          headers: {
            authorization: "Bearer " + localStorage.getItem("a_t"),
          },
        },
      },
      false
    );

    return execute;
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_MULTI_TIER_SETTING = gql`
  query getMultiTierSetting(
    $filter: MultiTierSettingSearchInput
    $limit: Int
    $offset: Int
    $orderBy: String
  ) {
    getMultiTierSetting(
      filter: $filter
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      ... on MultiTierSettingArray {
        data {
          _id
          id
          name
          rank
          type
          userCount
          clientUserCount
          crmUserCount
          isDefaultRank
          isDeleted
          createdBy {
            username
          }
          updatedBy {
            username
          }
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getMultiTierSetting = async ({ filter }, limit?, offset?, orderBy?) => {
  try {
    const execute = await ApolloClientQuery(
      GET_MULTI_TIER_SETTING,
      {
        filter: {
          name: filter?.name || null,
          rank: filter?.rank || null,
          type: filter?.type || null,
          rankRange: filter?.rankRange || null,
        },
        limit: limit || null,
        offset: offset || null,
        orderBy: orderBy || null,
        context: {
          headers: {
            authorization: "Bearer " + localStorage.getItem("a_t"),
          },
        },
      },
      false
    );

    return execute;
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const ADD_MULTI_TIER_SETTING = gql`
  mutation addMultiTierSetting($input: MultiTierSettingInput) {
    addMultiTierSetting(input: $input) {
      ... on MultiTierSetting {
        _id
        id
        name
        rank
        type
        userCount
        isDefaultRank
        createdBy {
          username
        }
        updatedBy {
          username
        }
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`;

const useAddMultiTierSettingMutation = (): {
  addMultiTierSetting: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const { t } = useTranslation();
    const [addMultiTierSettingMutation, { data, loading, error }] = useMutation(
      ADD_MULTI_TIER_SETTING
    );

    const addMultiTierSetting = (input) => {
      return addMultiTierSettingMutation({
        variables: {
          input: {
            name: input.name || null,
            rank: input.rank || null,
            type: input.type || null,
          },
        },
        update(cache, { data: { addMultiTierSetting } }) {
          const typename = addMultiTierSetting?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(addMultiTierSetting?.errKey),
              key: "addMultiTierSetting",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(addMultiTierSetting?.msg),
              key: "addMultiTierSetting",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Add Rank Successfully"),
              key: "addMultiTierSetting",
            });
          }
        },
        refetchQueries: [GET_MULTI_TIER_SETTING, GET_MULTI_TIER_SETTING_COUNT],
      });
    };

    return { addMultiTierSetting, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return {
      addMultiTierSetting: null,
      data: null,
      loading: false,
      error: err,
    };
  }
};

const UPDATE_MULTI_TIER_SETTING = gql`
  mutation updateMultiTierSetting($id: String!, $input: MultiTierSettingInput) {
    updateMultiTierSetting(id: $id, input: $input) {
      ... on MultiTierSetting {
        _id
        id
        name
        rank
        type
        userCount
        isDefaultRank
        createdBy {
          username
        }
        updatedBy {
          username
        }
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`;

const useUpdateMultiTierSettingMutation = (): {
  updateMultiTierSetting: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const { t } = useTranslation();
    const [updateMultiTierSettingMutation, { data, loading, error }] =
      useMutation(UPDATE_MULTI_TIER_SETTING);

    const updateMultiTierSetting = (id, input) => {
      return updateMultiTierSettingMutation({
        variables: {
          id: id,
          input: input,
        },
        update(cache, { data: { updateMultiTierSetting } }) {
          const typename = updateMultiTierSetting?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(updateMultiTierSetting?.errKey),
              key: "updateMultiTierSetting",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(updateMultiTierSetting?.msg),
              key: "updateMultiTierSetting",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Update Rank Successfully"),
              key: "updateMultiTierSetting",
            });
          }
        },
        refetchQueries: [GET_MULTI_TIER_SETTING],
      });
    };

    return { updateMultiTierSetting, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return {
      updateMultiTierSetting: null,
      data: null,
      loading: false,
      error: err,
    };
  }
};

const DELETE_MULTI_TIER_SETTING = gql`
  mutation deleteMultiTierSetting($id: String!) {
    deleteMultiTierSetting(id: $id) {
      ... on MultiTierSetting {
        _id
        id
        isDeleted
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`;

const useDeleteMultiTierSettingMutation = (): {
  deleteMultiTierSetting: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const { t } = useTranslation();
    const [deleteMultiTierSettingMutation, { data, loading, error }] =
      useMutation(DELETE_MULTI_TIER_SETTING);

    const deleteMultiTierSetting = (id) => {
      return deleteMultiTierSettingMutation({
        variables: {
          id: id,
        },
        update(cache, { data: { deleteMultiTierSetting } }) {
          const typename = deleteMultiTierSetting?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(deleteMultiTierSetting?.errKey),
              key: "deleteMultiTierSetting",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(deleteMultiTierSetting?.msg),
              key: "deleteMultiTierSetting",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Delete Rank Successfully"),
              key: "deleteMultiTierSetting",
            });
          }
        },
        refetchQueries: [GET_MULTI_TIER_SETTING, GET_MULTI_TIER_SETTING_COUNT],
      });
    };

    return { deleteMultiTierSetting, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return {
      deleteMultiTierSetting: null,
      data: null,
      loading: false,
      error: err,
    };
  }
};

const GET_MULTITIER_SETTING_EXPORT_DATA = gql`
  query getMultiTierSettingExportData{
    getMultiTierSettingExportData
  }
`;

const getMultiTierSettingExportData = () => {
  try {
    const { loading, error, data } = useQuery(GET_MULTITIER_SETTING_EXPORT_DATA, {
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const IMPORT_MULTITIER_SETTING_DATA = gql`
  mutation importMultiTierSettingData($input: String) {
    importMultiTierSettingData(input: $input) {
      ... on MultiTierSettingArray {
        data {
          _id
          id
          name
          rank
          type
          userCount
          clientUserCount
          crmUserCount
          isDefaultRank
          isDeleted
          createdBy {
            username
          }
          updatedBy {
            username
          }
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useImportMultiTierSettingData = (): {
  importMultiTierSettingData: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const { t } = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [importMultiTierSettingDataMutation, { data, loading, error }] =
      useMutation(IMPORT_MULTITIER_SETTING_DATA);

    const importMultiTierSettingData = (input) => {
      return importMultiTierSettingDataMutation({
        variables: {
          input: input,
        },
        update(cache, { data: { importMultiTierSettingData } }) {
          const typename = importMultiTierSettingData?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(importMultiTierSettingData?.errKey),
              key: "importMultiTierSettingData",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(importMultiTierSettingData?.msg),
              key: "importMultiTierSettingData",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Update Successfully"),
              key: "importMultiTierSettingData",
            });
          }
        },
        refetchQueries: [GET_MULTI_TIER_SETTING, GET_MULTI_TIER_SETTING_COUNT],
      });
    };

    return { importMultiTierSettingData, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return { importMultiTierSettingData: null, data: null, loading: false, error: err };
  }
};

export {
  getMultiTierSettingCount,
  getMultiTierSettingById,
  getMultiTierSetting,
  useAddMultiTierSettingMutation,
  useUpdateMultiTierSettingMutation,
  useDeleteMultiTierSettingMutation,
  getMultiTierSettingExportData,
  useImportMultiTierSettingData
};
