import React from "react";

export function Dots(props) {
    const { className, onHover } = props
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="119"
            viewBox="0 0 36 119"
            fill="none"
            className={className}
            onMouseEnter={onHover}
        >
            <path
                d="M3.73009 119C5.38794 119 6.73188 117.656 6.73188 115.998C6.73188 114.34 5.38794 112.996 3.73009 112.996C2.07224 112.996 0.728271 114.34 0.728271 115.998C0.728271 117.656 2.07224 119 3.73009 119Z"
                fill="white"
            />
            <path
                d="M3.73009 107.695C5.38794 107.695 6.73188 106.351 6.73188 104.693C6.73188 103.035 5.38794 101.691 3.73009 101.691C2.07224 101.691 0.728271 103.035 0.728271 104.693C0.728271 106.351 2.07224 107.695 3.73009 107.695Z"
                fill="white"
            />
            <path
                d="M3.73009 90.3855C5.38693 90.3855 6.73188 91.7305 6.73188 93.3873C6.73188 95.0442 5.38693 96.3891 3.73009 96.3891C2.07325 96.3891 0.728271 95.0442 0.728271 93.3873C0.728271 91.7305 2.07325 90.3855 3.73009 90.3855Z"
                fill="white"
            />
            <path
                d="M3.73009 85.1031C5.38794 85.1031 6.73188 83.7591 6.73188 82.1012C6.73188 80.4434 5.38794 79.0994 3.73009 79.0994C2.07224 79.0994 0.728271 80.4434 0.728271 82.1012C0.728271 83.7591 2.07224 85.1031 3.73009 85.1031Z"
                fill="white"
            />
            <path
                d="M3.73009 73.7975C5.38794 73.7975 6.73188 72.4536 6.73188 70.7958C6.73188 69.1379 5.38794 67.7939 3.73009 67.7939C2.07224 67.7939 0.728271 69.1379 0.728271 70.7958C0.728271 72.4536 2.07224 73.7975 3.73009 73.7975Z"
                fill="white"
            />
            <path
                d="M3.73009 62.5116C5.38794 62.5116 6.73188 61.1676 6.73188 59.5098C6.73188 57.8519 5.38794 56.5079 3.73009 56.5079C2.07224 56.5079 0.728271 57.8519 0.728271 59.5098C0.728271 61.1676 2.07224 62.5116 3.73009 62.5116Z"
                fill="white"
            />
            <path
                d="M3.73009 51.206C5.38794 51.206 6.73188 49.8621 6.73188 48.2042C6.73188 46.5464 5.38794 45.2025 3.73009 45.2025C2.07224 45.2025 0.728271 46.5464 0.728271 48.2042C0.728271 49.8621 2.07224 51.206 3.73009 51.206Z"
                fill="white"
            />
            <path
                d="M3.73009 39.9006C5.38794 39.9006 6.73188 38.5566 6.73188 36.8988C6.73188 35.2409 5.38794 33.897 3.73009 33.897C2.07224 33.897 0.728271 35.2409 0.728271 36.8988C0.728271 38.5566 2.07224 39.9006 3.73009 39.9006Z"
                fill="white"
            />
            <path
                d="M3.73009 22.611C5.38693 22.611 6.73188 23.9559 6.73188 25.6128C6.73188 27.2696 5.38693 28.6146 3.73009 28.6146C2.07325 28.6146 0.728271 27.2696 0.728271 25.6128C0.728271 23.9559 2.07325 22.611 3.73009 22.611Z"
                fill="white"
            />
            <path
                d="M13.3201 112.996C14.977 112.996 16.3219 114.341 16.3219 115.998C16.3219 117.655 14.977 119 13.3201 119C11.6633 119 10.3184 117.655 10.3184 115.998C10.3184 114.341 11.6633 112.996 13.3201 112.996Z"
                fill="white"
            />
            <path
                d="M13.3201 101.691C14.977 101.691 16.3219 103.036 16.3219 104.693C16.3219 106.35 14.977 107.695 13.3201 107.695C11.6633 107.695 10.3184 106.35 10.3184 104.693C10.3184 103.036 11.6633 101.691 13.3201 101.691Z"
                fill="white"
            />
            <path
                d="M13.3201 90.3855C14.977 90.3855 16.3219 91.7305 16.3219 93.3873C16.3219 95.0442 14.977 96.3891 13.3201 96.3891C11.6633 96.3891 10.3184 95.0442 10.3184 93.3873C10.3184 91.7305 11.6633 90.3855 13.3201 90.3855Z"
                fill="white"
            />
            <path
                d="M13.3201 79.0994C14.977 79.0994 16.3219 80.4444 16.3219 82.1012C16.3219 83.7581 14.977 85.1031 13.3201 85.1031C11.6633 85.1031 10.3184 83.7581 10.3184 82.1012C10.3184 80.4444 11.6633 79.0994 13.3201 79.0994Z"
                fill="white"
            />
            <path
                d="M13.3201 67.7939C14.977 67.7939 16.3219 69.1389 16.3219 70.7958C16.3219 72.4526 14.977 73.7975 13.3201 73.7975C11.6633 73.7975 10.3184 72.4526 10.3184 70.7958C10.3184 69.1389 11.6633 67.7939 13.3201 67.7939Z"
                fill="white"
            />
            <path
                d="M13.3201 56.5079C14.977 56.5079 16.3219 57.8529 16.3219 59.5098C16.3219 61.1666 14.977 62.5116 13.3201 62.5116C11.6633 62.5116 10.3184 61.1666 10.3184 59.5098C10.3184 57.8529 11.6633 56.5079 13.3201 56.5079Z"
                fill="white"
            />
            <path
                d="M13.3201 45.2025C14.977 45.2025 16.3219 46.5474 16.3219 48.2042C16.3219 49.8611 14.977 51.206 13.3201 51.206C11.6633 51.206 10.3184 49.8611 10.3184 48.2042C10.3184 46.5474 11.6633 45.2025 13.3201 45.2025Z"
                fill="white"
            />
            <path
                d="M13.3201 33.897C14.977 33.897 16.3219 35.242 16.3219 36.8988C16.3219 38.5556 14.977 39.9006 13.3201 39.9006C11.6633 39.9006 10.3184 38.5556 10.3184 36.8988C10.3184 35.242 11.6633 33.897 13.3201 33.897Z"
                fill="white"
            />
            <path
                d="M13.3201 22.611C14.977 22.611 16.3219 23.9559 16.3219 25.6128C16.3219 27.2696 14.977 28.6146 13.3201 28.6146C11.6633 28.6146 10.3184 27.2696 10.3184 25.6128C10.3184 23.9559 11.6633 22.611 13.3201 22.611Z"
                fill="white"
            />
            <path
                d="M22.8912 90.3855C24.5481 90.3855 25.893 91.7305 25.893 93.3873C25.893 95.0442 24.5481 96.3891 22.8912 96.3891C21.2344 96.3891 19.8894 95.0442 19.8894 93.3873C19.8894 91.7305 21.2344 90.3855 22.8912 90.3855Z"
                fill="white"
            />
            <path
                d="M22.8912 85.1031C24.5491 85.1031 25.893 83.7591 25.893 82.1012C25.893 80.4434 24.5491 79.0994 22.8912 79.0994C21.2334 79.0994 19.8894 80.4434 19.8894 82.1012C19.8894 83.7591 21.2334 85.1031 22.8912 85.1031Z"
                fill="white"
            />
            <path
                d="M22.8912 73.7975C24.5491 73.7975 25.893 72.4536 25.893 70.7958C25.893 69.1379 24.5491 67.7939 22.8912 67.7939C21.2334 67.7939 19.8894 69.1379 19.8894 70.7958C19.8894 72.4536 21.2334 73.7975 22.8912 73.7975Z"
                fill="white"
            />
            <path
                d="M22.8912 62.5116C24.5491 62.5116 25.893 61.1676 25.893 59.5098C25.893 57.8519 24.5491 56.5079 22.8912 56.5079C21.2334 56.5079 19.8894 57.8519 19.8894 59.5098C19.8894 61.1676 21.2334 62.5116 22.8912 62.5116Z"
                fill="white"
            />
            <path
                d="M22.8912 51.206C24.5491 51.206 25.893 49.8621 25.893 48.2042C25.893 46.5464 24.5491 45.2025 22.8912 45.2025C21.2334 45.2025 19.8894 46.5464 19.8894 48.2042C19.8894 49.8621 21.2334 51.206 22.8912 51.206Z"
                fill="white"
            />
            <path
                d="M22.8912 39.9006C24.5491 39.9006 25.893 38.5566 25.893 36.8988C25.893 35.2409 24.5491 33.897 22.8912 33.897C21.2334 33.897 19.8894 35.2409 19.8894 36.8988C19.8894 38.5566 21.2334 39.9006 22.8912 39.9006Z"
                fill="white"
            />
            <path
                d="M22.8912 22.611C24.5481 22.611 25.893 23.9559 25.893 25.6128C25.893 27.2696 24.5481 28.6146 22.8912 28.6146C21.2344 28.6146 19.8894 27.2696 19.8894 25.6128C19.8894 23.9559 21.2344 22.611 22.8912 22.611Z"
                fill="white"
            />
            <path
                d="M22.8912 17.3091C24.5491 17.3091 25.893 15.9651 25.893 14.3072C25.893 12.6494 24.5491 11.3054 22.8912 11.3054C21.2334 11.3054 19.8894 12.6494 19.8894 14.3072C19.8894 15.9651 21.2334 17.3091 22.8912 17.3091Z"
                fill="white"
            />
            <path
                d="M22.8912 6.00358C24.5491 6.00358 25.893 4.65961 25.893 3.00176C25.893 1.34391 24.5491 0 22.8912 0C21.2334 0 19.8894 1.34391 19.8894 3.00176C19.8894 4.65961 21.2334 6.00358 22.8912 6.00358Z"
                fill="white"
            />
            <path
                d="M32.4615 90.3855C34.1183 90.3855 35.4633 91.7305 35.4633 93.3873C35.4633 95.0442 34.1183 96.3891 32.4615 96.3891C30.8047 96.3891 29.4597 95.0442 29.4597 93.3873C29.4597 91.7305 30.8047 90.3855 32.4615 90.3855Z"
                fill="white"
            />
            <path
                d="M32.4615 85.1031C34.1194 85.1031 35.4633 83.7591 35.4633 82.1012C35.4633 80.4434 34.1194 79.0994 32.4615 79.0994C30.8037 79.0994 29.4597 80.4434 29.4597 82.1012C29.4597 83.7591 30.8037 85.1031 32.4615 85.1031Z"
                fill="white"
            />
            <path
                d="M32.4615 73.7975C34.1194 73.7975 35.4633 72.4536 35.4633 70.7958C35.4633 69.1379 34.1194 67.7939 32.4615 67.7939C30.8037 67.7939 29.4597 69.1379 29.4597 70.7958C29.4597 72.4536 30.8037 73.7975 32.4615 73.7975Z"
                fill="white"
            />
            <path
                d="M32.4615 62.5116C34.1194 62.5116 35.4633 61.1676 35.4633 59.5098C35.4633 57.8519 34.1194 56.5079 32.4615 56.5079C30.8037 56.5079 29.4597 57.8519 29.4597 59.5098C29.4597 61.1676 30.8037 62.5116 32.4615 62.5116Z"
                fill="white"
            />
            <path
                d="M32.4615 51.206C34.1194 51.206 35.4633 49.8621 35.4633 48.2042C35.4633 46.5464 34.1194 45.2025 32.4615 45.2025C30.8037 45.2025 29.4597 46.5464 29.4597 48.2042C29.4597 49.8621 30.8037 51.206 32.4615 51.206Z"
                fill="white"
            />
            <path
                d="M32.4615 39.9006C34.1194 39.9006 35.4633 38.5566 35.4633 36.8988C35.4633 35.2409 34.1194 33.897 32.4615 33.897C30.8037 33.897 29.4597 35.2409 29.4597 36.8988C29.4597 38.5566 30.8037 39.9006 32.4615 39.9006Z"
                fill="white"
            />
            <path
                d="M32.4615 22.611C34.1183 22.611 35.4633 23.9559 35.4633 25.6128C35.4633 27.2696 34.1183 28.6146 32.4615 28.6146C30.8047 28.6146 29.4597 27.2696 29.4597 25.6128C29.4597 23.9559 30.8047 22.611 32.4615 22.611Z"
                fill="white"
            />
            <path
                d="M32.4615 17.3091C34.1194 17.3091 35.4633 15.9651 35.4633 14.3072C35.4633 12.6494 34.1194 11.3054 32.4615 11.3054C30.8037 11.3054 29.4597 12.6494 29.4597 14.3072C29.4597 15.9651 30.8037 17.3091 32.4615 17.3091Z"
                fill="white"
            />
            <path
                d="M32.4615 6.00358C34.1194 6.00358 35.4633 4.65961 35.4633 3.00176C35.4633 1.34391 34.1194 0 32.4615 0C30.8037 0 29.4597 1.34391 29.4597 3.00176C29.4597 4.65961 30.8037 6.00358 32.4615 6.00358Z"
                fill="white"
            />
        </svg>
    );
}