import React, {useEffect, useState} from "react";
import Country_1 from "./view/layout1";
import {getCountryCodeLabelList} from "../../../api/graphql/_common";

const Country = () => {
    let layout = 1;
    let component;
    const tableName = "Country";

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(0);
    const [filter, setFilter] = useState({});
    const [orderBy, setOrderBy] = useState("name_ASC");

    const preload = async () => {
        try {
            setLoading(true);
            const response = await getCountryCodeLabelList();
            setData(response?.getCountryCodeLabelList);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    // const filterData = async () => {
    //     try {
    //         const filterList = Object.keys(filter).forEach((key) => {});
    //     } catch (error) {}
    // };

    useEffect(() => {
        preload();
    }, []);

    // useEffect(() => {
    //     filterData();
    // }, [filter]);

    switch (layout) {
        case 1:
            component = (
                <Country_1
                    data={data}
                    exportData={data}
                    loading={loading}
                    // totalCount={totalCount}
                    setLimit={setLimit}
                    limit={limit}
                    setOffset={setOffset}
                    setFilter={setFilter}
                    setOrderBy={setOrderBy}
                    tableName={tableName}
                />
            );
            break;

        default:
            component = (
                <Country_1
                    data={data}
                    exportData={data}
                    loading={loading}
                    // totalCount={totalCount}
                    setLimit={setLimit}
                    limit={limit}
                    setOffset={setOffset}
                    setFilter={setFilter}
                    setOrderBy={setOrderBy}
                    tableName={tableName}
                />
            );
            break;
    }

    return component;
};

export default Country;
