import {gql, useMutation, useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";

const GET_NEWS_FEED_SETTING = gql`
  query getNewsfeedSetting {
    getNewsfeedSetting {
      ... on NewsfeedSetting {
        comments
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getNewsfeedSetting = () => {
    try {
        const {loading, error, data} = useQuery(GET_NEWS_FEED_SETTING, {
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, data}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const UPDATE_NEWS_FEED_SETTING = gql`
  mutation updateNewsfeedSetting($input: NewsfeedSettingInput) {
    updateNewsfeedSetting(input: $input) {
      ... on NewsfeedSetting {
        comments
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useUpdateNewsFeedSettingMutation = (): {
    updateNewsfeedSetting: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const { t } = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updateNewsfeedSettingMutation, {data, loading, error}] = useMutation(UPDATE_NEWS_FEED_SETTING);

        const updateNewsfeedSetting = (input) => {
            return updateNewsfeedSettingMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {updateNewsfeedSetting}}) {
                    const typename = updateNewsfeedSetting?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateNewsfeedSetting?.errKey),
                            key: "updateNewsfeedSetting",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateNewsfeedSetting?.msg),
                            key: "updateNewsfeedSetting",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update News Feed Setting Successfully"),
                            key: "updateNewsfeedSetting",
                        });
                    }
                },
            });
        };

        return {updateNewsfeedSetting, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {updateNewsfeedSetting: null, data: null, loading: false, error: err};
    }
};

export {
    getNewsfeedSetting,
    useUpdateNewsFeedSettingMutation
}


