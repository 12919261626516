import React, {useEffect, useState} from "react";
import {Col, Collapse, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Switch, Tag} from "antd";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {languageOptionsState} from "../../../../../../users/_common/state";
import {notificationMessage} from "../../../../../../../../recoil_state";
import {createBadgeSetting, updateBadgeSetting} from "../../../../../../../api/restful/arena/badgeSetting";
import MediaSelect from "../../../../../_common/mediaComponent";
import {createRewardSetting, updateRewardSetting} from "../../../../../../../api/restful/arena/rewardSetting";
import dayjs from "dayjs";

const CreateUpdateModal = ({open, handleClose, item, fetchRewards, rewardOption, preSelectType, preSelectId}) => {
    const [form] = Form.useForm()
    const languageOptions = useRecoilValue<any>(languageOptionsState)
    const [loading, setLoading] = useState(false)
    const isUpdate = !!item
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const onSubmit = async (value) => {
        setLoading(false)
        if (!isUpdate) {
            try {
                const response = await createRewardSetting(value)

                if (response?.status === "Success") {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: "Create Reward Successfully",
                        key: "arenaRewardSettingNotification",
                    })

                    fetchRewards()
                    handleClose()
                } else {
                    setNotificationMessage({
                        status: "error",
                        title: "",
                        msg: `Create Reward Failed: ${response?.errObj}`,
                        key: "arenaRewardSettingNotification",
                    })
                }
            } catch (e) {
            } finally {
                setLoading(false)
            }
        } else {
            try {
                const response = await updateRewardSetting({...value, _id: item?._id})

                if (response?.status === "Success") {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: "Update Reward Successfully",
                        key: "arenaRewardSettingNotification",
                    });

                    fetchRewards()
                    handleClose()
                } else {
                    setNotificationMessage({
                        status: "error",
                        title: "",
                        msg: `Update Reward Failed: ${response?.errObj}`,
                        key: "arenaRewardSettingNotification",
                    });
                }
            } catch (e) {
            } finally {
                setLoading(false)
            }
        }
    }


    useEffect(() => {
        if (!item) {
            if (preSelectId && preSelectType) {
                form.setFieldsValue({source: preSelectType, sourceSetting: preSelectId})
                return
            }

            form.resetFields()
        } else {
            form.setFieldsValue({...item, expiredAt: dayjs(item?.expiredAt)})
        }
    }, [open, item, preSelectType, preSelectId]);


    return (
        <Modal
            open={open}
            onCancel={handleClose}
            title={"Create New Reward Setting"}
            onOk={form.submit}
            okButtonProps={{loading: loading}}
            destroyOnClose
            okText={"Submit"}
        >
            <Form form={form} onFinish={onSubmit} layout={"vertical"}>
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                        <Form.Item name={"isEnabled"} label={"Is Enabled"} valuePropName={"checked"}>
                            <Switch/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={"source"} label={"Reward Type"}>
                            <Select
                                options={[
                                    {label: "Voucher", value: "voucher"},
                                    {label: "Experience Points", value: "exp"},
                                    {label: "Gifts", value: "gifts"},
                                    {label: "Lives", value: "lives"},
                                    {label: "Pass", value: "pass"},
                                    {label: "Coin", value: "coin"},
                                    {label: "Badges", value: "badges"},
                                    {label: "Box", value: "box"},
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={"maxCount"} label={"Max Count"}>
                            <InputNumber min={0} className={"w-100"}/>
                        </Form.Item>
                    </Col>

                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.source !== currentValues.source}
                    >
                        {({getFieldValue}) => {
                            return (
                                getFieldValue("source") && !["lives", "exp"].includes(getFieldValue("source")) &&
                                <Col span={12}>
                                    <Form.Item name={"sourceSetting"} label={"Available Reward"}>
                                        <Select
                                            options={rewardOption?.filter(d => d?.type === getFieldValue("source"))}
                                            optionRender={(option) => {
                                                let color
                                                switch (option.data.type) {
                                                    case "badges":
                                                        color = "cyan"
                                                        break
                                                    case "gifts":
                                                        color = "volcano"
                                                        break
                                                    case "voucher":
                                                        color = "lime"
                                                        break
                                                    case "exp":
                                                        color = "geekblue"
                                                        break
                                                    case "lives":
                                                        color = "purple"
                                                        break
                                                    case "coin":
                                                        color = "gold"
                                                        break
                                                    case "pass":
                                                        color = "green"
                                                        break
                                                }

                                                return (
                                                    <Row justify={"space-between"}>
                                                        {option.data.label} <Tag bordered={false} color={color}>{option.data.type?.toUpperCase()}</Tag>
                                                    </Row>
                                                )
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            )
                        }}
                    </Form.Item>
                    <Col span={12}>
                        <Form.Item name={"expiredAt"} label={"Expired At"}>
                            <DatePicker showTime={false} showNow={false} className={"w-100"}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default CreateUpdateModal