import React from "react";
import {Form, Modal, Select} from "antd";
import {useTranslation} from "react-i18next";
import {labelCol, validateMessages} from "../../../../../function";
import {useRecoilValue} from "recoil";
import {generalListCountryState} from "../../../../../state";

const GeneralListCountryForm = (props) => {
    const {createLoading, isOpen, setIsOpen, addCountry, countryOptions, form} = props
    const {t} = useTranslation()

    const countryList = useRecoilValue<any>(generalListCountryState)
    const countryOption = countryList?.map(d => ({label: d?.country, value: d?._id}))

    return (
        <Modal
            title={t("accountVerification.addCountry")}
            open={isOpen}
            onCancel={() => setIsOpen(false)}
            onOk={form.submit}
            cancelText={t("common.cancel")}
            okText={t("common.add")}
            okButtonProps={{loading: createLoading}}
            destroyOnClose={true}
        >
            <Form form={form} onFinish={addCountry} validateMessages={validateMessages}>
                <Form.Item
                    labelCol={labelCol}
                    label={t("accountVerification.country")}
                    name={"country"}
                    rules={[{required: true}]}
                >
                    <Select
                        placeholder={t("accountVerification.placeholder.selectCountry")}
                        options={countryOptions()}
                        showSearch
                        optionFilterProp={"label"}
                    />
                </Form.Item>
                <Form.Item
                    labelCol={labelCol}
                    label={t("accountVerification.duplicateFlowFrom")}
                    name={"from"}
                    // rules={[{required: true}]}
                >
                    <Select
                        placeholder={t("accountVerification.placeholder.selectCountry")}
                        options={countryOption}
                        showSearch
                        optionFilterProp={"label"}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default GeneralListCountryForm