import React, {useState} from 'react'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import yupFormSchema from '../../../../../../components/yup/yupFormSchema'
import InputFormItem from '../../../../../../components/form/inputFormItem'
import {verticalFormLayout} from '../../../../../../components/form/formWrapper'

import {FormProvider, useForm} from 'react-hook-form'
import {Button, Col, Form, Row} from 'antd'
import SelectFormItem from '../../../../../../components/form/selectFormItem'
import RadioGroupFormItem from '../../../../../../components/form/radioGroupFormItem'
import {useTranslation} from 'react-i18next'
import {CloseOutlined, SaveOutlined} from '@ant-design/icons'
import {useAddDynamicEmailComponentMutation, useUpdateDynamicEmailComponentMutation} from '../../../../../../api/graphql/dynamicEmailComponent'

const dynamicEmailComponentSchema = yup.object().shape({
    name: yupFormSchema.string('name', {required: true}),
    html: yupFormSchema.string('html'),
    componentType: yupFormSchema.string('componentType'),
    isActive: yupFormSchema.boolean('isActive'),
})

const DynamicEmailComponentForm = (props) => {
    const {
        mode,
        item,
        htmlState,
        closeModal
    } = props

    const {t} = useTranslation()
    const [errMsg, setErrMsg] = useState("")

    const [initialValues] = useState(() => ({
        name: item?.name || '',
        componentType: item?.componentType || '',
        isActive: item?.isActive ? true : false,
    }))

    const form = useForm({
        resolver: yupResolver(dynamicEmailComponentSchema),
        mode: 'all',
        defaultValues: initialValues
    })

    const onCancel = () => {
        closeModal()
    }

    const {addDynamicEmailComponent} = useAddDynamicEmailComponentMutation();
    const {updateDynamicEmailComponent} = useUpdateDynamicEmailComponentMutation();

    const onSubmit = async (values) => {
        const result = {
            ...values,
            htmlObject: htmlState
        }
        try {
            const response = mode === "add"
                ? await addDynamicEmailComponent(result)
                : await updateDynamicEmailComponent(item._id, result);
            const {data} = response;
            if (mode === "add" && data?.addDynamicEmailComponent.errMsg) {
                setErrMsg(data?.addDynamicEmailComponent.errMsg);
            } else if (mode === "edit" && data?.updateDynamicEmailComponent.errMsg) {
                setErrMsg(data?.updateDynamicEmailComponent.errMsg);
            } else {
                setErrMsg("");
                closeModal();
            }
        } catch (error: any) {
            setErrMsg(error.message);
        }
    }

    return (
        <div className='email-component-general-form-1'>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className='dynamicEmailComponent-form-1'>
                    {errMsg && <p style={{color: "red"}}>{errMsg}</p>}
                    <Row>
                        <Col span={12}>
                            <InputFormItem
                                name={'name'}
                                label={'Name'}
                                placeholder={'Name'}
                                layout={verticalFormLayout}
                                disabled={mode === "view"}
                                required
                            />
                        </Col>
                        <Col xs={12}>
                            <RadioGroupFormItem
                                name={"isActive"}
                                label={t("Status")}
                                options={[
                                    {label: t("Active"), value: true},
                                    {label: t("Inactive"), value: false},
                                ]}
                                layout={verticalFormLayout}
                                required
                                disabled={mode === "view"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <SelectFormItem
                                name={"componentType"}
                                label={t("Component Type")}
                                placeholder={t("Component Type")}
                                options={[
                                    {label: t("Header"), value: "header"},
                                    {label: t("Footer"), value: "footer"},
                                ]}
                                layout={verticalFormLayout}
                                disabled={mode === "view"}
                            />
                        </Col>
                    </Row>

                    {props.children}

                    <Form.Item className="text-align-right" style={{paddingTop: "12px"}}>
                        {onCancel && (
                            <Button
                                onClick={onCancel}
                                icon={<CloseOutlined/>}
                                className="button-cancel-1"
                            >
                                {t('Cancel')}
                            </Button>
                        )}
                        {mode !== "view" &&
                        <Button
                            onClick={form.handleSubmit(onSubmit)}
                            icon={<SaveOutlined/>}
                            className="button-submit-1"
                        >
                            {t('Submit')}
                        </Button>
                        }
                    </Form.Item>
                </form>
            </FormProvider>
        </div>
    )
}

export default DynamicEmailComponentForm