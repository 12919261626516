import React from "react";
import {Switch, Tag} from "antd";
import {useRecoilValue} from "recoil";
import {getColumnSearchProps} from "../../../../components/table/function/filterTable";
import TranslationText from "../../../../components/table/component/translationText";
import {reportingAllState} from "../../_general/state";

const ReportsColumn = ({reports}) => {
    const reportingAll = useRecoilValue<any>(reportingAllState);
    return (
        <>
            {reports?.map((item) => {
                let reportObj = reportingAll?.find((v) => v?.reportId === item?.reportId);
                const isSystemReport = item?.isSystem;
                if (reportObj) {
                    return (
                        <Tag key={item?.reportId} color={isSystemReport ? "blue" : "default"} className="margin-left-0-5">
                            {reportObj?.displayName}
                        </Tag>
                    );
                }
            })}
        </>
    );
};

export const columnsCategory = () => {
    return [
        {
            title: "",
            dataIndex: "sortingNumber",
            key: "sortingNumber",
            // defaultSortOrder: "ascend",
            // sorter: (a, b) => a?.sortingNumber - b?.sortingNumber,
            // ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            ...getColumnSearchProps(),
            render: (text, record) => (
                <span>
                    {text}
                    {record?.isSystem && (
                        <Tag color="red" className="margin-left-0-5">
                            <TranslationText languageKey="common.system" />
                        </Tag>
                    )}
                    {record?.isDefault && (
                        <Tag color="blue" className="margin-left-0-5">
                            <TranslationText languageKey="common.default" />
                        </Tag>
                    )}
                </span>
            ),
        },
        {
            title: "Label (language key)",
            dataIndex: "label",
            key: "label",
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: "Enabled",
            dataIndex: "isEnabled",
            key: "isEnabled",
            ...getColumnSearchProps(),
            render: (text) => <Switch checked={text} size="small" disabled />,
        },
        {
            title: "Reports",
            dataIndex: "reports",
            key: "reports",
            width: 300,
            ...getColumnSearchProps(),
            render: (reports) => <ReportsColumn reports={reports} />,
        },
    ];
};
