import {atom} from "recoil";

const informationTypeState = atom({
    key: "informationType",
    default: "client"
})

const userVerificationRecordValueState = atom({
    key: "userVerificationRecordValue",
    default: []
})

const userVerificationRecordLoadingState = atom({
    key: "userVerificationRecordLoading",
    default: false
})

const userVerificationModalPropState = atom({
    key: "userVerificationModalProp",
    default: false
})

const userVerificationUploadFileListState = atom({
    key: "userVerificationUploadFileListState",
    default: [],
});

const userAccountVerificationRecordsState = atom({
    key: "userAccountVerificationRecordsState",
    default: [],
});

export {
    informationTypeState,
    userVerificationRecordValueState,
    userVerificationRecordLoadingState,
    userVerificationModalPropState,
    userVerificationUploadFileListState,
    userAccountVerificationRecordsState
}