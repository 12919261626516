import {gql, useMutation, useQuery} from '@apollo/client'
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {useTranslation} from "react-i18next";
import { GET_MOBILE_MODULE_SETTING } from './mobileModuleSetting';
import { GET_WEB_MODULE_SETTING } from './webModuleSetting';

const GET_MODULE_SETTING_COUNT = gql`
    query getModuleSettingCount($filter: ModuleSettingInput) {
        getModuleSettingCount(filter: $filter)
    }
`;

const getModuleSettingCount = ({filter}) => {
    try {
        const {loading, error, data} = useQuery(GET_MODULE_SETTING_COUNT, {
            variables: {
                filter: {
                    name: filter?.name || null,
                    description: filter?.description || null,
                    moduleStatus: filter?.moduleStatus || null,
                    parent: filter?.parent || null,
                }
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, data}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const GET_MODULE_SETTING_BY_ID = gql`
  query getModuleSettingByID($id: String!) {
    getModuleSettingByID(id: $id){
      ... on ModuleSetting {
        _id
        id
        name
        description
        parent {name _id}
        isEnabled
        isEditable
        createdBy {email}
        updatedBy {email}
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getModuleSettingById = (id) => {
    try {
        const {loading, error, data} = useQuery(GET_MODULE_SETTING_BY_ID, {
            variables: {
                id: id
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, data}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}


const GET_MODULE_SETTING = gql`
    query getModuleSetting($filter: ModuleSettingSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
        getModuleSetting(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy){
            ... on ModuleSettingArray {
                data {
                    _id
                    id
                    name
                    description
                    parent {name _id}
                    iconLightActive {fileList path}
                    iconLightInactive {fileList path}
                    iconDarkActive {fileList path}
                    iconDarkInactive {fileList path}
                    defaultIconLightActive {fileList path}
                    defaultIconLightInactive {fileList path}
                    defaultIconDarkActive {fileList path}
                    defaultIconDarkInactive {fileList path}
                    isEnabled
                    isEditable
                    createdBy {email}
                    updatedBy {email}
                    createdAt
                    updatedAt
                    visibleToRank {_id}
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getModuleSetting = ({filter}, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data} = useQuery(GET_MODULE_SETTING, {
            variables: {
                filter: filter,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, data}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const ADD_MODULE_SETTING = gql`
  mutation addModuleSetting($input: ModuleSettingInput) {
    addModuleSetting(input: $input){
      ... on ModuleSetting {
        _id
        id
        name
        description
        parent {name}
        isEnabled
        isEditable
        iconLightActive {fileList path}
        iconLightInactive {fileList path}
        iconDarkActive {fileList path}
        iconDarkInactive {fileList path}
        defaultIconLightActive {fileList path}
        defaultIconLightInactive {fileList path}
        defaultIconDarkActive {fileList path}
        defaultIconDarkInactive {fileList path}
        createdBy {email}
        updatedBy {email}
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useAddModuleSettingMutation = (): { addModuleSetting: any, data: any, loading: boolean, error: any } => {
    try {
        const [addModuleSettingMutation, {data, loading, error}] = useMutation(ADD_MODULE_SETTING);

        const addModuleSetting = (input) => {
            return addModuleSettingMutation({
                variables: {
                    input: {
                        name: input.name || null,
                        description: input.description || null,
                        moduleStatus: input.moduleStatus || null,
                        parent: input.parent || null,
                    },
                },
            });
        };

        return {addModuleSetting, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {addModuleSetting: null, data: null, loading: false, error: err};
    }
}

const UPDATE_MODULE_SETTING = gql`
  mutation updateModuleSetting($id: String!, $input: ModuleSettingInput) {
    updateModuleSetting(id: $id, input: $input){
      ... on ModuleSetting {
        _id
        id
        name
        description
        parent {name}
        isEnabled
        isEditable
        createdBy {email}
        updatedBy {email}
        iconLightActive {fileList path}
        iconLightInactive {fileList path}
        iconDarkActive {fileList path}
        iconDarkInactive {fileList path}
        defaultIconLightActive {fileList path}
        defaultIconLightInactive {fileList path}
        defaultIconDarkActive {fileList path}
        defaultIconDarkInactive {fileList path}
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useUpdateModuleSettingMutation = (): {
    updateModuleSetting: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const {t} = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updateModuleSettingMutation, {data, loading, error}] = useMutation(UPDATE_MODULE_SETTING);

        const updateModuleSetting = (id, input) => {
            return updateModuleSettingMutation({
                variables: {
                    id: id,
                    input: input,
                },
                update(cache, {data: {updateModuleSetting}}) {
                    const typename = updateModuleSetting?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateModuleSetting?.errKey),
                            key: "updateModuleSetting",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateModuleSetting?.msg),
                            key: "updateModuleSetting",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t(`Update ${updateModuleSetting?.name} Module Setting Successfully`),
                            key: "updateModuleSetting",
                        });
                    }
                },
                refetchQueries: [GET_MOBILE_MODULE_SETTING, GET_WEB_MODULE_SETTING, GET_MODULE_SETTING]
            });
        };

        return {updateModuleSetting, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {updateModuleSetting: null, data: null, loading: false, error: err};
    }
};

const DELETE_MODULE_SETTING = gql`
  mutation deleteModuleSetting($id: String!){
    deleteModuleSetting(id: $id){
      ... on ModuleSetting {
        _id
        id
        isDeleted
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

export {
    getModuleSettingCount,
    getModuleSettingById,
    getModuleSetting,
    useAddModuleSettingMutation,
    useUpdateModuleSettingMutation,

}