import React from "react";
import {Modal} from 'antd';
import ProductGroupForm from "./productGroupForm";
import {useTranslation} from "react-i18next";

const ProductGroupModal = (props) => {
    const {isOpen, closeModal, mode, item, currentTab} = props
    const {t} = useTranslation()

    return (
        <Modal
            title={t(`${mode} Product Group`)}
            open={isOpen}
            onCancel={() => closeModal()}
            footer={null}
            width={500}
            destroyOnClose
        >
            <ProductGroupForm mode={mode} item={item} close={closeModal} currentTab={currentTab}/>
        </Modal>
    );
}

export default ProductGroupModal