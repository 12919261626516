import React, {useEffect, useState} from "react";
import {getCommissionGroup} from "../../../../../../../api/graphql/commission";
import useAuthorize from "../../../../../../../_common/authorize";
import {Button, Row, Space} from "antd";
import {useTranslation} from "react-i18next";
import {getUser} from "../../../../../../../api/graphql/user";
import CommGroupTable_1 from "./commGroupTable";
import CommGroupModal from "./commGroupModal";

const CommGroup = (props) => {

    const {t} = useTranslation();

    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [btnLoading, setBtnLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [orderBy, setOrderBy] = useState("");
    const [filter, setFilter] = useState({});
    const [tableName, setTableName] = useState("");
    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(false);

    const {systemErrorNotification} = useAuthorize()

    const allUsers = getUser({
        filter: {
            userType: null
        }
    });

    const preload = async () => {
        await setLoading(true)
        try {
            let response

            setTableName("Comm Group")

            response = await getCommissionGroup({
                ...filter
            }, limit, offset, orderBy)

            const filteredData = response?.getCommissionGroup?.data?.map((item) => {
                return {
                    ...item,
                }
            })

            setData(filteredData)

        } catch (e) {
            systemErrorNotification();
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        preload()
    }, [filter, limit, offset, orderBy])


    return (
        <div style={{marginTop: "15px"}}>

            <Row>
                <Space direction={"horizontal"}>
                    <Button type={"primary"} onClick={() => setOpenModal(true)}>
                        {t("common.add")}
                    </Button>
                </Space>
            </Row>

            <CommGroupTable_1 limit={limit} setLimit={setLimit} setOffset={setOffset} setBtnLoading={setBtnLoading}
                              setOrderBy={setOrderBy} server={null} tableName={tableName}
                              loading={loading} data={data} setFilter={setFilter} userData={allUsers?.data?.getUser?.data} preload={preload}/>

            <CommGroupModal isOpen={openModal} closeModal={() => setOpenModal(false)}
                              userData={allUsers?.data?.getUser?.data} preload={preload} type={"add"}/>

        </div>
    )
}

export default CommGroup