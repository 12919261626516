import React from "react";
import {useRecoilState} from "recoil";
import VariablePage_1 from "./view/layout1";
import {getLanguage} from "../../../api/graphql/language";
import {sortEn} from "../../../function/language";
import {selfPermissionObj} from "../../../../recoil_state";

function VariablePage() {
    let layout = 1;
    let component;
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const dynamicVariablePermission = selfPermission?.messageSetting?.dynamicVariable;
    const {languageData} = getLanguage({filter: null});

    switch (layout) {
        case 1:
            component = (
                <VariablePage_1 dynamicVariablePermission={dynamicVariablePermission} languageData={sortEn(languageData?.getLanguage?.data)} />
            );
            break;

        default:
            component = (
                <VariablePage_1 dynamicVariablePermission={dynamicVariablePermission} languageData={sortEn(languageData?.getLanguage?.data)} />
            );
            break;
    }

    return component;
}

export default VariablePage;
