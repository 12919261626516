import React, {useEffect, useState} from "react";
import "./css/index.css";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import yupFormSchema from "../../../../../shared/components/yup/yupFormSchema";
import InputFormItem from "../../../../../shared/components/form/inputFormItem";
import {FormProvider, useForm} from "react-hook-form";
import {Button, Col, Image, Row} from "antd";
import {verticalFormLayout} from "../../../../../shared/components/form/formWrapper";
import {useTranslation} from "react-i18next";
import backgroundImage from "./assets/login-page-bg.jpg";

const LoginPage_cronoscap = (props) => {
    const {loginAction, loginData, loginLoading, brandSetting, languageList, setLanguage} = props;
    const {t} = useTranslation();

    const [isTyping, setIsTyping] = useState<any>(false);
    const [sloganIndex, setSloganIndex] = useState(2);

    const brandSlogan = brandSetting?.companySlogan;

    useEffect(() => {
        if (sloganIndex < brandSlogan?.length) {
            let timeout = setTimeout(() => {
                setSloganIndex((i) => i + 1);
            }, 50);
            return () => clearTimeout(timeout);
        }
    }, [sloganIndex, brandSlogan]);

    const loginSchema = yup.object().shape({
        login: yupFormSchema.string("login", {
            required: true,
            requiredErrorMessage: "Username Credential is required",
        }),
        password: yupFormSchema.string("password", {
            required: true,
            requiredErrorMessage: "Password is required",
        }),
    });

    const [initialValues] = useState(() => ({
        login: "",
        password: "",
    }));

    const form = useForm({
        resolver: yupResolver(loginSchema),
        mode: "all",
        defaultValues: initialValues,
    });

    const onSubmit = async (values) => {
        const record = {
            ...values,
            type: "crmUser",
        };
        loginAction(record);
        setIsTyping(false);
    };

    return (
        <div id="cronoscap-login-page" style={{background: `url(${backgroundImage})`}}>
            <Row>
                <Col xs={0} md={10} lg={14} className={"image-container"} style={{background: `url(${backgroundImage})`}}>
                    <Image width={130} preview={false} src={brandSetting?.companyLogoInverse} className={"clickable margin-1"} />
                </Col>
                <Col xs={22} md={14} lg={10} className={"login-card"}>
                    <div className={"form-content"}>
                        <Row justify={"space-between"} align={"middle"}>
                            <h1 className="loginHeading">{t("Login")}</h1>
                            <a
                                className={"switch-to-old-site-link"}
                                onClick={() => window.open(`https://secures.${window.location.hostname.split(".").slice(1).join(".")}`)}
                            >
                                {/* {t("common.switchToOldSite")} */}
                                Switch To Old Site
                            </a>
                        </Row>
                        <Row justify={"center"} className={"mt-4"}>
                            <Col xs={24} className={"login-input-area"}>
                                <FormProvider {...form}>
                                    <form onSubmit={form.handleSubmit(onSubmit)} className="login-form-1">
                                        <InputFormItem
                                            className={"login-input margin-bottom-0-75"}
                                            name={"login"}
                                            label={"Username"}
                                            placeholder={"Username"}
                                            layout={verticalFormLayout}
                                            onChange={(e) => setIsTyping(e)}
                                            required
                                        />
                                        <InputFormItem
                                            name={"password"}
                                            label={"Password"}
                                            placeholder={"Password"}
                                            type={"password"}
                                            layout={verticalFormLayout}
                                            onChange={(e) => setIsTyping(e)}
                                            required
                                        />
                                        {loginData && loginData.login && loginData.login.errMsg && isTyping === false && (
                                            <p
                                                style={{
                                                    color: "red",
                                                    marginTop: 16,
                                                    fontSize: 12,
                                                    textAlign: "center",
                                                }}
                                            >
                                                {loginData.login.errMsg}
                                            </p>
                                        )}
                                        <Row justify={"center"}>
                                            <Button
                                                block
                                                className="btn-login"
                                                type="primary"
                                                onClick={form.handleSubmit(onSubmit)}
                                                disabled={loginLoading}
                                                loading={loginLoading}
                                            >
                                                {t("Login")}
                                            </Button>
                                        </Row>
                                    </form>
                                </FormProvider>
                                <p className={"text-align-center margin-top-0-75"} style={{color: "rgba(255,255,255,.5)", fontSize: 11}}>
                                    Copyright © {brandSetting?.copyRight} {brandSetting?.companyName} - All rights reserved.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default LoginPage_cronoscap;
