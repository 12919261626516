import React from "react";
import ListTableCell from "./listTableCell";

export const tableColumn: any = [
  {
    title: "Position",
    key: "position",
    align: "center",
    width:  50,
    sorter: (a, b) => a?.pspSetting?.position - b?.pspSetting?.position,
    render: (_, record) => <p>{record?.pspSetting?.position}</p>,
  },
  {
    title: "PSP",
    key: "psp",
    width: 300,
    sorter: (a, b) => a?.pspSetting?.name?.localeCompare(b?.pspSetting?.name),
    render: (_, record) => {
      return (
        <ListTableCell
          record={record}
          cellName="name"
          listGrid={{ column: 4, xs: 1, sm: 2, md: 3 }}
        />
      );
    },
  },
  {
    title: "Main Settings",
    key: "main-settings",
    className: "mainSettingsCell",
    width: 350,
    sorter: (a, b) => a?.pspSetting?.enabled - b?.pspSetting?.enabled,
    render: (_, record) => {
      return (
        <ListTableCell
          record={record}
          cellName="mainSettings"
          listGrid={{ column: 4, xs: 1, sm: 2, md: 3 }}
        />
      );
    },
  },
];
