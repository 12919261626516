import {useRecoilValue} from "recoil";
import {currentEditingElement} from "../state/state";

export const useContextMenuFunctions = (setData) => {
    const editingElement = useRecoilValue(currentEditingElement)

    const handleFormInputValueChange = (value, field) => {
        setData(prev => {
                const data = prev?.interfaceDetail?.map(item => {
                    if (item.layoutId === editingElement) {
                        return {
                            ...item,
                            setting: {
                                ...item.setting,
                                [field]: value
                            }
                        }
                    }
                    return item
                })

                return {...prev, interfaceDetail: data}
            }
        )
    }

    return {
        handleFormInputValueChange
    }
}