import React, {useEffect, useMemo, useState} from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {Card, Col, Form, message, Modal, Row, Space, Tabs, Tag} from "antd";
import {FormProvider, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {CloseOutlined} from "@ant-design/icons";
import yupFormSchema from "../../../../../../components/yup/yupFormSchema";
import {verticalFormLayout} from "../../../../../../components/form/formWrapper";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import useAuthorize from "../../../../../../_common/authorize";
import SelectFormItem from "../../../../../../components/form/selectFormItem";
import {userOptionsState} from "../../../../../users/_common/state";
import {getUserNetworkTreeDownline} from "../../../../../../api/graphql/userNetworkTree";
import {formatUserOptions, removeTypenameKey} from "../../../../../../function/_common";
import {notificationMessage} from "../../../../../../../recoil_state";
import {addAnnouncement, updateAnnouncement} from "../../../../../../api/graphql/announcement";
import ButtonAntDesign from "../../../../../../components/button/buttonAntDesign";
import TemplatePreviewGrapeJS from "../component/templatePreviewGrapeJS";
import {handleOnChangeWithLanguage} from "../../../function/common";
import TextAreaInputFormItem from "../../../../../../components/form/textAreaInputFormItem";
import "../css/style.css"
import CheckboxFormItem from "../../../../../../components/form/checkboxFormItem";

const AnnouncementModal = (props) => {
    const {
        isOpen,
        closeModal,
        mode,
        item,
        openEditor,
        languageData,
        getTableData,
        textVarData,
    } = props;
    const {t} = useTranslation();
    const [loading, setLoading] = useState<boolean>(false)
    const [values, setValues] = useState<any>({});
    const userOptions: any = useRecoilValue(userOptionsState);
    const {systemErrorNotification} = useAuthorize();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {networkTreeDownlineData} = getUserNetworkTreeDownline(values?.network);
    const [activeTab, setActiveTab] = useState("0");

    const yupSchema = yup.object().shape({
        name: yupFormSchema.string("name", {required: true}),
        userType: yupFormSchema.string("userType", {required: true}),
        recipient: yupFormSchema.array("recipient", {required: true}),
        sendInNetwork: yupFormSchema.boolean("sendInNetwork"),
    });

    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
    });

    const setupInitialValues = async () => {
        try {
            const formValues: any = {
                name: item?.name || null,
                recipient: item?.recipient?.map((user) => ({value: user?._id, label: user?.username})) || null,
                sendInNetwork: item?.sendInNetwork || false,
                userType: item?.userType || "all",
                dataArrayOfObject: item?.dataArrayOfObject || [],
            }

            form.reset(formValues);
            setValues(formValues);
        } catch (error) {
            console.log(error);
        }
    };

    let textVarByLanguage = useMemo(
        () =>
            // textVarData?.filter((v: any) => {
            //   return v?.textObject?.find((v) => v?.language === languageCode)?.text;
            // }),
            textVarData,
        [textVarData]
    );

    const copyText = (name) => {
        try {
            let copiedName = "{{dynamicVariable:" + name + "}}";

            navigator.clipboard.writeText(copiedName);
            message.success(t(`Copied ${name} to clipboard`));
        } catch (error) {
        }
    };

    const tabItems = languageData?.map((lang, index) => {
        let inputValue = values?.dataArrayOfObject?.find((v) => v?.language === lang.code);

        return {
            key: index?.toString(),
            label: lang?.name,
            children: (
                <Row gutter={[16, 16]} style={{marginTop: "12px"}}>
                    <Col xs={24} lg={12}>
                        <Row gutter={[16, 16]} align={"top"}>
                            <Col className="gutter-row" xs={24}>
                                <InputFormItem
                                    name={`title_${lang.code}`}
                                    label={"Title"}
                                    placeholder={"Title"}
                                    layout={verticalFormLayout}
                                    disabled={mode === "View"}
                                    value={inputValue?.title}
                                    onChange={(e) => handleOnChangeWithLanguage(e, "dataArrayOfObject", "title", lang.code, setValues)}
                                    required={lang?.code === "en"}
                                />
                            </Col>
                            <Col span={24}>
                                <TextAreaInputFormItem
                                    name={`description_${lang.code}`}
                                    label={"Description"}
                                    placeholder={"Description"}
                                    layout={verticalFormLayout}
                                    disabled={mode === "View"}
                                    value={inputValue?.description}
                                    onChange={(e) => handleOnChangeWithLanguage(e, "dataArrayOfObject", "description", lang.code, setValues)}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Row gutter={[16, 16]} align={"top"}>
                            <Col xs={24}>
                                <Card title="Text Variable" className="variableCard">
                                    <div className="variableContainer">
                                        {textVarByLanguage?.map((v, i) => (
                                            <Tag key={i} color="blue" onClick={() => copyText(v?.name)}>
                                                {v?.name}
                                            </Tag>
                                        ))}
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    {mode !== "Add" && (
                        <Col span={24}>
                            <h3>Preview</h3>
                            <TemplatePreviewGrapeJS
                                languageCode={lang?.code}
                                data={item}
                                activeTab={activeTab}
                                className={"margin-top-0-75"}
                            />
                        </Col>
                    )}
                </Row>
            ),
        };
    });

    const handleValueChange = async (field, value) => {
        try {
            form.setValue(field, value);
            setValues((prevState) => ({...prevState, [field]: value}));
        } catch (error) {
        }
    };

    const onSubmit = async () => {
        try {
            setLoading(true)
            let input = values;
            input = await removeTypenameKey(input);

            if (Array.isArray(input?.recipient)) {
                input.recipient = input?.recipient?.map((item: any) => {
                    return typeof item === "object" && item?.value ? item.value : item;
                });
            }

            const res =
                mode === "Add" ?
                    await addAnnouncement(input)
                    :
                    await updateAnnouncement(item?._id, input);
            const response = mode === "Add" ? res?.addAnnouncement : res?.updateAnnouncement;
            if (response?.__typename === "Announcement") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: mode === "Add" ? t("common.addSuccess") : t("common.updateSuccess"),
                    key: "addUpdateAnnouncement",
                });
                closeModal();
                mode === "Add" && openEditor(response?._id, mode)
            } else if (response?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.errKey),
                    key: "addUpdateAnnouncement",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.msg),
                    key: "addUpdateAnnouncement",
                });
                closeModal();
            }
        } catch (error: any) {
            systemErrorNotification();
        } finally {
            if (mode !== "Add") {
                await getTableData()
            }
            setLoading(false)
        }
    };

    useEffect(() => {
        setupInitialValues();
    }, [item, isOpen]);

    const networkTreeDownLineOptions = networkTreeDownlineData?.getUserNetworkTreeDownline?.data?.map((d) => {
        return formatUserOptions(d?.userid);
    });

    const netWorkSelectOptions = [
        {value: "all", label: t("All Users")},
        {value: "partial", label: t("Some Users")},
        {value: "onlyMe", label: t("Only Me")},
    ];

    return (
        <Modal
            title={t(`${mode} Announcement`)}
            getContainer="#announcement"
            open={isOpen}
            onCancel={() => closeModal()}
            footer={null}
            width={"60%"}
            destroyOnClose
        >
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="form-1">
                    <Row gutter={[8, 8]} align={"top"}>
                        <Col span={24}>
                            <InputFormItem
                                className={"announcement-form-item"}
                                name={"name"}
                                label={t("Name")}
                                labelCol={{span: 4, offset: 0}}
                                colon
                                placeholder={t("Name")}
                                required
                                disabled={mode === "View"}
                                onChange={(e) => handleValueChange("name", e)}
                            />
                        </Col>
                        <Col span={24}>
                            <Row gutter={8} wrap={false}>
                                <Col span={4}>
                                    <Form.Item colon label={t("message.to")} required/>
                                </Col>
                                <Col span={20}>
                                    <Row gutter={8}>
                                        <Col span={5}>
                                            <SelectFormItem
                                                className={"announcement-form-item"}
                                                name={"userType"}
                                                options={[
                                                    {label: t("common.all"), value: "all"},
                                                    {label: t("user.client"), value: "client"},
                                                    {label: t("user.crmUser"), value: "crmUser"},
                                                ]}
                                                placeholder={t("message.select_group")}
                                                required
                                                disabled={mode === "View"}
                                                onChange={(e) => handleValueChange("userType", e)}
                                            />
                                        </Col>
                                        <Col span={19}>
                                            <SelectFormItem
                                                className={"announcement-form-item"}
                                                name={"recipient"}
                                                options={userOptions
                                                    ?.map(d => ({
                                                        ...d,
                                                        label: d?.selectedLabel,
                                                    }))
                                                }
                                                required
                                                maxTagCount={2}
                                                optionFilterProp={"label"}
                                                mode={"multiple"}
                                                disabled={mode === "View"}
                                                onChange={(e) => handleValueChange("recipient", e)}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <CheckboxFormItem
                                className={"announcement-form-item"}
                                name={"sendInNetwork"}
                                label={t("Network")}
                                labelCol={{span: 4, offset: 0}}
                                colon
                                disabled={mode === "View"}
                                text={t("message.network_note")}
                                onChange={(e) => handleValueChange("sendInNetwork", e)}
                            />
                        </Col>
                    </Row>
                    <Tabs
                        defaultActiveKey="0"
                        items={tabItems}
                        onChange={(activeKey) => setActiveTab(activeKey)}
                        tabBarExtraContent={
                            mode === "Edit" ? (
                                <Space>
                                    <ButtonAntDesign
                                        text={t("Open in Editor")}
                                        onClick={() => openEditor(item?._id)}
                                        type="primary"
                                    />
                                </Space>
                            ) : null
                        }
                    />
                    <div className="text-align-right" style={{paddingTop: "12px"}}>
                        <Row justify={"end"}>
                            <Col>
                                {closeModal && (
                                    <ButtonAntDesign
                                        text={mode === "View" ? t("common.close") : t("common.cancel")}
                                        onClick={closeModal}
                                        icon={<CloseOutlined/>}
                                        className={"button-cancel-1"}
                                    />
                                )}
                                {mode !== "View" && (
                                    <ButtonAntDesign
                                        text={t("common.submit")}
                                        onClick={form.handleSubmit(onSubmit)}
                                        className={"button-submit-1"}
                                        loading={loading}
                                    />
                                )}
                            </Col>
                        </Row>
                    </div>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default AnnouncementModal;
