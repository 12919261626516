import React from "react";
import {
    ClockCircleOutlined,
    CrownOutlined,
    DollarOutlined,
    FundOutlined,
    GiftOutlined,
    MailOutlined,
    MoneyCollectOutlined,
    ProfileOutlined,
    RobotOutlined,
    SettingOutlined,
    SolutionOutlined,
    TeamOutlined,
    UsergroupAddOutlined,
} from "@ant-design/icons";
// import type { MenuProps } from "antd";
// import { users } from "./menuCategory/users";
// import { account } from "./menuCategory/account";
// import { role } from "./menuCategory/role";
import {Link} from "react-router-dom";

// type MenuItem = Required<MenuProps>["items"][number];

// function getItem(
//   label: React.ReactNode,
//   key: React.Key,
//   icon?: React.ReactNode | null,
//   children?: MenuItem[] | null,
//   type?: "group" | null,
//   permission?: String[] | null
// ): MenuItem {
//   return {
//     key,
//     icon,
//     children,
//     label,
//     type,
//     permission,
//   } as MenuItem;
// }

const items = [
    {
        label: "Settings",
        key: "settings",
        children: [
            {
                label: "Brand Settings",
                key: "brand-settings",
                icon: <SettingOutlined/>,
                children: [
                    {
                        key: "server-settings",
                        path: "/settings/server-settings",
                        label: <Link to="/settings/server-settings">Server Setting</Link>,
                        permission: ["brandSetting:serverSetting:view"],
                    },
                    {
                        key: "push-notification-server-settings",
                        path: "/settings/push-notification-server-settings",
                        label: <Link to="/settings/push-notification-server-settings">Notification Server Setting</Link>,
                        permission: ["brandSetting:pushNotificationServerSetting:view"],
                    },
                    {
                        key: "brand-details",
                        path: "/settings/brand-details",
                        label: <Link to="/settings/brand-details">Brand Details</Link>,
                        permission: ["brandSetting:brandDetails:view"],
                    },
                    {
                        key: "icon-list",
                        path: "/settings/icon-list",
                        label: <Link to="/settings/icon-list">Icon List</Link>,
                        permission: ["brandSetting:iconList:view"],
                    },
                    {
                        key: "currency-settings",
                        path: "/settings/currency-settings",
                        label: <Link to="/settings/currency-settings">Currency Setting</Link>,
                        permission: ["brandSetting:currencySetting:view"],
                    },
                    {
                        key: "country",
                        path: "/settings/country",
                        label: <Link to="/settings/country">Country</Link>,
                        permission: ["brandSetting:brandDetails:view"],
                    },
                    // {
                    //   key: "mb5",
                    //   label: <Link to="/settings/ewallet-settings">EWallet Setting</Link>,
                    //   permission: ["brandSetting:eWalletSetting:view"],
                    // },
                    {
                        key: "platform-download",
                        path: "/settings/platform-download",
                        label: <Link to="/settings/platform-download">Platform Download</Link>,
                        permission: ["brandSetting:platformDownload:view"],
                    },
                    {
                        key: "company-email",
                        path: "/settings/company-email",
                        label: <Link to="/settings/company-email">Company Email</Link>,
                        permission: ["brandSetting:companyEmail:view"],
                    },
                    {
                        key: "modules",
                        path: "/settings/modules",
                        label: <Link to="/settings/modules">Modules</Link>,
                        permission: ["brandSetting:moduleSettings:view"],
                    },
                    {
                        key: "language",
                        path: "/settings/language",
                        label: <Link to="/settings/language">Languages</Link>,
                        permission: ["brandSetting:language:view"],
                    },
                ],
            },
            {
                label: "User Settings",
                key: "user-settings",
                icon: <TeamOutlined/>,
                children: [
                    {
                        key: "role-settings",
                        path: "/settings/role-settings",
                        label: <Link to="/settings/role-settings">Role Settings</Link>,
                        permission: ["userSetting:roleTemplate:view"],
                    },
                    {
                        key: "user-network-tree",
                        path: "/settings/user-network-tree",
                        label: <Link to="/settings/user-network-tree">User Network Tree</Link>,
                        permission: ["userSetting:userNetworkTree:view"],
                    },
                    {
                        key: "sales-rep-network-tree",
                        path: "/settings/sales-rep-network-tree",
                        label: <Link to="/settings/sales-rep-network-tree">Sales Rep Network Tree</Link>,
                        permission: ["userSetting:salesRepNetworkTree:view"],
                    },
                    {
                        key: "desk-settings",
                        path: "/settings/desk-settings",
                        label: <Link to="/settings/desk-settings">Desk Settings</Link>,
                        permission: ["userSetting:desk:view"],
                    },
                    {
                        key: "fields",
                        path: "/settings/fields",
                        label: <Link to="/settings/fields">Fields</Link>,
                        permission: ["userSetting:fields:view"],
                    },
                    {
                        key: "registration-workflow",
                        path: "/settings/registration-workflow",
                        label: <Link to="/settings/registration-workflow">Registration Workflow</Link>,
                        permission: ["userSetting:registrationWorkflow:view"],
                    },
                    {
                        key: "account-verification",
                        path: "/settings/account-verification",
                        label: <Link to="/settings/account-verification">Account Verification</Link>,
                        permission: ["userSetting:accountVerification:view"],
                    },
                    {
                        key: "sales-opportunity-settings",
                        path: "/settings/sales-opportunity-setting",
                        label: <Link to="/settings/sales-opportunity-setting">Sales Opportunity Setting</Link>,
                        permission: ["userSetting:salesOpportunityStatus:view"],
                    },
                    // {
                    //     key: "mu8",
                    //     label: <Link to="/settings/kyc-setting">Kyc Settings</Link>,
                    //     permission: ["generalSetting:kycSetting:view"],
                    // },
                    // {
                    //     key: "mu9",
                    //     label: <Link to="/settings/kyb-setting">Kyb Settings</Link>,
                    //     permission: ["generalSetting:kycSetting:view"],
                    // },
                ],
            },
            {
                label: "Account Settings",
                key: "account-settings",
                icon: <SolutionOutlined/>,
                children: [
                    {
                        key: "assets",
                        path: "/settings/assets",
                        label: <Link to="/settings/assets">Assets</Link>,
                        permission: ["accountSetting:assets:view"],
                    },
                    {
                        key: "symbols",
                        path: "/settings/symbols",
                        label: <Link to="/settings/symbols">Symbols</Link>,
                        permission: ["accountSetting:symbol:view"],
                    },
                    {
                        key: "securities",
                        path: "/settings/securities",
                        label: <Link to="/settings/securities">Securities Group</Link>,
                        permission: ["accountSetting:securitiesGroup:view"],
                    },
                    {
                        key: "spread-group",
                        path: "/settings/spread-group",
                        label: <Link to="/settings/spread-group">Spread Group</Link>,
                        permission: ["accountSetting:spreadGroup:view"],
                    },
                    {
                        key: "product-group",
                        path: "/settings/product-group",
                        label: <Link to="/settings/product-group">Product Group</Link>,
                        permission: ["accountSetting:productGroup:view"],
                    },
                    {
                        key: "product",
                        path: "/settings/product",
                        label: <Link to="/settings/product">Product</Link>,
                        permission: ["accountSetting:product:view"],
                    },
                ],
            },
            {
                label: "Deposit/Withdrawal Settings",
                icon: <DollarOutlined/>,
                key: "deposit-withdrawal-settings",
                children: [
                    {
                        key: "psp-settings",
                        path: "/settings/psp-settings",
                        label: <Link to="/settings/psp-settings">PSP Settings</Link>,
                        permission: ["depositWithdrawalSetting:pspSetting:view"],
                    },
                ],
            },
            {
                label: "Commissions Settings",
                key: "commissions-settings",
                icon: <MoneyCollectOutlined/>,
                children: [
                    {
                        key: "commission-rule-settings",
                        path: "/settings/commission-rule-settings",
                        label: <Link to="/settings/commission-rule-settings">Commission Rule Settings</Link>,
                        permission: ["commission:setting:view"],
                    },
                ],
            },
            {
                label: "Operation Logs",
                key: "operation-logs",
                icon: <ProfileOutlined/>,
            },
            {
                label: "Message Settings",
                key: "message-settings",
                icon: <MailOutlined/>,
                children: [
                    {
                        key: "variable",
                        path: "/settings/variable",
                        label: <Link to="/settings/variable">Variable</Link>,
                        permission: ["messageSetting:dynamicVariable:view"],
                    },
                    // {
                    //     key: "sm2",
                    //     label: <Link to="/settings/email-component">Email Component</Link>,
                    //     permission: ["messageSetting:dynamicEmailComponent:view"],
                    // },
                    {
                        key: "email-template",
                        path: "/settings/email-template",
                        label: <Link to="/settings/email-template">Email Template</Link>,
                        permission: ["messageSetting:emailTemplate:view"],
                    },
                    {
                        key: "push-notification-template",
                        path: "/settings/push-notification-template",
                        label: <Link to="/settings/push-notification-template">Notification Template</Link>,
                        permission: ["messageSetting:pushNotificationTemplate:view"],
                    },
                    {
                        key: "announcement",
                        path: "/settings/announcement",
                        label: <Link to="/settings/announcement">Announcement</Link>,
                        permission: ["messageSetting:announcement:view"],
                    },
                ],
            },
            {
                label: "Activities",
                key: "activities",
                icon: <CrownOutlined/>,
                children: [
                    {
                        key: "mission",
                        label: <Link to="/settings/mission">Missions</Link>,
                        permission: ["activitiesAndRewardSetting:mission:view"],
                    },
                    {
                        key: "promo-material",
                        path: "/settings/promo-material",
                        label: <Link to="/settings/promo-material">Promo Material</Link>,
                        permission: ["activities:promoMaterial:view"],
                    },
                ],
            },
            {
                label: "Rewards",
                key: "rewards",
                icon: <GiftOutlined/>,
                children: [
                    {
                        key: "voucher",
                        label: <Link to="/settings/voucher">Voucher</Link>,
                        permission: ["activitiesAndRewardSetting:voucher:view"],
                    },
                    {
                        key: "voucher-program",
                        label: <Link to="/settings/voucher-program">Voucher Program</Link>,
                        permission: ["activitiesAndRewardSetting:voucherProgram:view"],
                    },
                ],
            },
            {
                label: "Arena - Settings",
                key: "arena-settings",
                icon: <SettingOutlined />,
                children: [
                    {
                        key: "general-setting",
                        path: "/settings/arena/general-setting",
                        label: <Link to="/settings/arena/general-setting">General Setting</Link>,
                        // todo - permission
                        permission: ["arena"],

                    },
                    {
                        key: "arena-color-setting",
                        path: "/settings/arena/color-setting",
                        label: <Link to="/settings/arena/color-setting">Arena Color Setting</Link>,
                        // todo - permission
                        permission: ["arena"],

                    },
                    {
                        key: "media-library",
                        path: "/settings/arena/media-library",
                        label: <Link to="/settings/arena/media-library">Media Library</Link>,
                        // todo - permission
                        permission: ["arena"],

                    },
                ],
            },
            {
                label: "Arena - Activities",
                key: "arena-activities",
                icon: <CrownOutlined/>,
                children: [
                    {
                        key: "leaderboard-setting",
                        path: "/settings/arena/leaderboard-setting",
                        label: <Link to="/settings/arena/leaderboard-setting">Leaderboard Setting</Link>,
                        // todo - permission
                        permission: ["arena"],

                    },
                ],
            },
            {
                label: "Arena - Rewards",
                key: "arena-rewards",
                icon: <GiftOutlined/>,
                children: [
                    {
                        key: "reward-setting",
                        path: "/settings/arena/reward-setting",
                        label: <Link to="/settings/arena/reward-setting">Reward Setting</Link>,
                        // todo - permission
                        permission: ["arena"],

                    },
                    {
                        key: "gift-setting",
                        path: "/settings/arena/gift-setting",
                        label: <Link to="/settings/arena/gift-setting">Gift Setting</Link>,
                        // todo - permission
                        permission: ["arena"],
                    },
                    {
                        key: "badge-setting",
                        path: "/settings/arena/badge-setting",
                        label: <Link to="/settings/arena/badge-setting">Badge Setting</Link>,
                        // todo - permission
                        permission: ["arena"],
                    },
                    {
                        key: "box-setting",
                        path: "/settings/arena/box-setting",
                        label: <Link to="/settings/arena/box-setting">Box Setting</Link>,
                        // todo - permission
                        permission: ["arena"],
                    },
                ],
            },
            {
                label: "MAM/PAMM",
                key: "mampamm",
                icon: <UsergroupAddOutlined/>,
                children: [
                    {
                        key: "provider",
                        label: <Link to="/settings/mampamm/provider">Provider</Link>,
                        permission: ["mampamm:provider:view"],
                    },
                    {
                        key: "mampamm-product",
                        label: <Link to="/settings/mampamm/product">Product</Link>,
                        permission: ["mampamm:product:view"],
                    },
                    {
                        key: "legal-document",
                        label: <Link to="/settings/mampamm/legal-document">Legal Document</Link>,
                        permission: ["mampamm:legalDocument:view"],
                    },
                ],
            },
            // {
            //     label: "Bot Settings",
            //     key: "botSettings",
            //     icon: <UsergroupAddOutlined/>,
            //     children: [
            //         {
            //             key: "telegram-bot-settings",
            //             label: <Link to="/settings/telegram-bot-settings">Telegram Bot Settings</Link>,
            //             permission: ["telegram:telegramBot:view"],
            //         },
            //     ],
            // },
            {
                label: "Automation Setting",
                key: "automation-settings",
                icon: <ClockCircleOutlined/>,
                children: [
                    {
                        key: "automation-setting",
                        path: "/settings/automation-setting/automation-setting",
                        label: <Link to="/settings/automation-setting/automation-setting">Automation Setting</Link>,
                        permission: ["automation:setting:view"],
                    },
                    {
                        key: "custom-trigger",
                        path: "/settings/automation-setting/custom-trigger",
                        label: <Link to="/settings/automation-setting/custom-trigger">Custom Trigger</Link>,
                        permission: ["automation:setting:view"],
                    },
                    {
                        key: "custom-action",
                        path: "/settings/automation-setting/custom-action",
                        label: <Link to="/settings/automation-setting/custom-action">Custom Action</Link>,
                        permission: ["automation:setting:view"],
                    },
                ],
            },
            {
                label: "System Settings",
                key: "system-settings",
                icon: <RobotOutlined/>,
                children: [
                    {
                        key: "cron-setting",
                        path: "/settings/cron",
                        label: <Link to="/settings/cron">Cron Settings</Link>,
                        permission: ["systemSetting:cron:view"],
                    },
                ],
            },
            {
                label: "System Logs",
                key: "system-log",
                icon: <FundOutlined/>,
                children: [
                    {
                        key: "audit-log",
                        path: "/log/audit",
                        label: <Link to="/settings/log/audit">Audit Logs</Link>,
                        permission: ["systemLog:log:view"],
                    }
                ],
            },
        ],
        type: "group",
    },
];

// const items: MenuProps["items"] = [
//   getItem(
//     "Settings",
//     "ex",
//     null,
//     [
//       getItem("Message Settings", "message-settings", <MailOutlined />, [
//         getItem(
//           <Link to="/settings/variable">Variable</Link>,
//           "sm1",
//           null,
//           null,
//           null,
//           ["messageSetting:dynamicVariable:view"]
//         ),
//         getItem(
//           <Link to="/settings/email-component">Email Component</Link>,
//           "sm2"
//         ),
//         getItem(
//           <Link to="/settings/email-template">Email Template</Link>,
//           "sm3"
//         ),
//         getItem(
//           <Link to="/settings/push-notification-template">
//             Notification Template
//           </Link>,
//           "sm5"
//         ),
//       ]),
//       getItem("User Settings", "user-settings", <TeamOutlined />, [
//         getItem(<Link to="/settings/role-settings">Role Settings</Link>, "mu1"),
//         getItem(
//           <Link to="/settings/user-network-tree">User Network Tree</Link>,
//           "mu2"
//         ),
//         getItem(
//           <Link to="/settings/sales-rep-network-tree">
//             Sales Rep Network Tree
//           </Link>,
//           "mu3"
//         ),
//         getItem(<Link to="/settings/desk-settings">Desk Settings</Link>, "mu4"),
//         getItem(<Link to="/settings/fields">Fields</Link>, "mu5"),
//         getItem(
//           <Link to="/settings/registration-workflow">
//             Registration Workflow
//           </Link>,
//           "mu6"
//         ),
//         getItem(<Link to="/settings/kyc-setting">Kyc Settings</Link>, "mu7"),
//         getItem(<Link to="/settings/kyb-setting">Kyb Settings</Link>, "mu8"),
//         // getItem(<Link to="/settings/workflow">Workflow</Link>, "mu9"),
//       ]),
//       getItem("Account Settings", "account-settings", <SolutionOutlined />, [
//         getItem(<Link to="/settings/assets">Assets</Link>, "ac6"),
//         getItem(<Link to="/settings/symbols">Symbols</Link>, "ac5"),
//         getItem(<Link to="/settings/securities">Securities Group</Link>, "ac4"),
//         getItem(<Link to="/settings/spread-group">Spread Group</Link>, "ac3"),
//         getItem(<Link to="/settings/product-group">Product Group</Link>, "ac2"),
//         getItem(<Link to="/settings/product">Product</Link>, "ac1"),
//       ]),
//       // getItem("Transaction", "s6", <TransactionOutlined />),
//       getItem("Brand Settings", "brand-settings", <SettingOutlined />, [
//         getItem(
//           <Link to="/settings/server-settings">Server Setting</Link>,
//           "mb1"
//         ),
//         getItem(<Link to="/settings/brand-details">Brand Details</Link>, "mb2"),
//         getItem(
//           <Link to="/settings/platform-download">Platform Download</Link>,
//           "mb3"
//         ),
//         getItem(<Link to="/settings/company-email">Company Email</Link>, "mb4"),
//         getItem(<Link to="/settings/modules">Modules</Link>, "mb5"),
//         getItem(<Link to="/settings/language">Languages</Link>, "mb6"),
//       ]),
//       getItem(
//         "Deposit/Withdrawal Settings",
//         "deposit-withdrawal-settings",
//         <MoneyCollectOutlined />,
//         [getItem(<Link to="/settings/psp-settings">PSP Settings</Link>, "dw1")]
//       ),
//       getItem(
//         "Commissions Settings",
//         "commissions-settings",
//         <MoneyCollectOutlined />
//       ),
//       getItem("Operation Logs", "operation-logs", <ProfileOutlined />),
//       // getItem("Preferences", "s8", <SolutionOutlined />, [
//       //     getItem(<Link to="settings/table-setting">Table Setting</Link>, "pf1"),
//       // ]),
//     ],
//     "group"
//   ),
// ];

export {items};
