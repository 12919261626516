import React, {useState} from "react";
import {Button, Popconfirm, Row, Space, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import CreateUpdateModal from "./component/createUpdateModal";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import {columns} from "../../function/tableColumn";
import "./css/style.css"
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {deleteGiftSetting} from "../../../../../../api/restful/arena/giftSetting";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../recoil_state";
import RewardReminder from "../../../../_common/rewardReminder";

const GiftSettings_1 = ({data, fetchGiftSetting}) => {
    const {t} = useTranslation()
    const [modalOpen, setModalOpen] = useState(false)
    const [currentItem, setCurrentItem] = useState(null)
    const column = columns()
    const [deleteLoading, setDeleteLoading] = useState(false)
    const setNotificationMessage = useSetRecoilState(notificationMessage)

    const [reminderModalOpen, setReminderModalOpen] = useState(false)
    const [newGiftSetting, setNewGiftSetting] = useState(null)

    const handleModalClose = () => {
        setModalOpen(false)
        setCurrentItem(null)
    }

    const handleModalOpen = (item?) => {
        setModalOpen(true)
        setCurrentItem(item || null)
    }

    const handleGiftCreatedRemind = (item) => {
        setNewGiftSetting(item)
        setReminderModalOpen(true)
    }

    const handleDeleteGiftSetting = async (id) => {
        try {
            setDeleteLoading(true)
            const response = await deleteGiftSetting({_id: id})

            if (response?.status === "Success") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Delete Gift Successfully",
                    key: "arenaGiftSettingNotification",
                });

                fetchGiftSetting()
            } else {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: `Delete Gift Failed: ${response?.errObj}`,
                    key: "arenaGiftSettingNotification",
                });
            }
        } catch (e) {} finally {
            setDeleteLoading(false)
        }
    }

    return (
        <div id={"gift-setting"}>
            <Row justify={"space-between"} className={"margin-bottom-0-75"}>
                <h1 className={"margin-bottom-0"}>Gift Setting</h1>
                <Button type={"primary"} onClick={() => handleModalOpen()}>{t("common.add")}</Button>
            </Row>

            <TableAntDesign
                data={data || []}
                columns={[
                    ...column,
                    {
                        title: t("common.action"),
                        key: "action",
                        render: (_, record) => {

                            return (
                                <Space>
                                    <Tooltip title={t("Edit")}>
                                        <a onClick={() => handleModalOpen(record)}>
                                            <EditOutlined />
                                        </a>
                                    </Tooltip>
                                    <Popconfirm
                                        placement="left"
                                        title={t("Delete Gift Setting")}
                                        description={t("Are you sure you want to delete this gift setting?")}
                                        onConfirm={() => handleDeleteGiftSetting(record?._id)}
                                        okText={t("common.yes")}
                                        okButtonProps={{loading: deleteLoading}}
                                        cancelText={t("common.no")}
                                    >
                                        <a style={{color: "red"}}><DeleteOutlined /></a>
                                    </Popconfirm>
                                </Space>
                            )
                        }
                    }
                ]}
                size={"small"}
                isTableLocalSearch={true}
                tableName={"Arena Gift Setting"}
            />

            <CreateUpdateModal
                open={modalOpen}
                handleClose={handleModalClose}
                item={currentItem}
                fetch={fetchGiftSetting}
                handleGiftCreatedRemind={handleGiftCreatedRemind}
            />

            <RewardReminder
                open={reminderModalOpen}
                handleClose={() => setReminderModalOpen(false)}
                item={newGiftSetting}
                type={"gifts"}
            />
        </div>
    )
}

export default GiftSettings_1