import {atom} from "recoil";


const tradingAccountCreditRefreshState = atom({
    key: 'tradingAccountCreditRefreshState',
    default: false,
});

const tradingAccountTransactionRefreshState = atom({
    key: 'tradingAccountTransactionRefreshState',
    default: false,
});

const tradingAccountMt5OpenPositionProfitState = atom({
    key: 'tradingAccountMt5OpenPositionProfitState',
    default: 0,
});

export {
    tradingAccountCreditRefreshState,
    tradingAccountTransactionRefreshState,
    tradingAccountMt5OpenPositionProfitState
}