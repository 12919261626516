import React, {useEffect} from "react";
import {getRegistrationSetting} from "../../../api/graphql/register";
import RegistrationSetting_1 from "./view/layout1";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
    registrationSettingLoadingState,
    registrationSettingModeState,
    registrationSettingPlatformState,
    registrationSettingState
} from "./state";
import {getFieldList} from "../../../api/graphql/fieldsSetting";
import {fieldCategoryState, fieldLabelState, fieldListLoading, fieldListState} from "../fields/state";
import {selfPermissionObj} from "../../../../recoil_state";
import {getProfileInformationSetting} from "../../../api/graphql/profileInformation";


function RegistrationSetting() {
    const setRegistrationSetting = useSetRecoilState(registrationSettingState)
    const setFieldList = useSetRecoilState(fieldListState)
    const setLoading = useSetRecoilState(fieldListLoading)
    const setRegistrationLoading = useSetRecoilState(registrationSettingLoadingState)

    const permission = useRecoilValue<any>(selfPermissionObj)
    const platform = useRecoilValue(registrationSettingPlatformState)
    const mode = useRecoilValue(registrationSettingModeState)
    const label = useRecoilValue(fieldLabelState)
    const [category, setCategory] = useRecoilState(fieldCategoryState)

    const {data: clientField} = getProfileInformationSetting({type: "client"})
    const {data, loading:dataLoading} = getRegistrationSetting({mode: mode, platform:platform})
    const {fieldList, loading} = getFieldList({label: label, category: category})

    let layout = 1;
    let component;

    const processData = async (data) => {
        if (data?.length > 0) {
            const activeField:any = []
            await data?.map((item) => {
                if (item?.position !== null) {
                    activeField?.push(item);
                }
            });

            return activeField
        }
    }

    useEffect(() => {
        setCategory([])
    },[])

    useEffect(() => {
        processData(data?.getRegistrationSetting?.data).then(data => setRegistrationSetting(data))
        setRegistrationLoading(dataLoading)
    },[data?.getRegistrationSetting?.data, dataLoading])

    useEffect(() => {
        setFieldList(fieldList?.getFieldList?.data)
        setLoading(loading)
    },[fieldList?.getFieldList?.data, loading])

    switch (layout) {
        case 1:
            component = <RegistrationSetting_1 permission={permission?.userSetting} clientField={clientField?.getProfileInformationSetting?.data}/>;
            break;

        default:
            component = <RegistrationSetting_1 permission={permission?.userSetting} clientField={clientField?.getProfileInformationSetting?.data}/>;
            break;
    }

    return component;
}

export default RegistrationSetting;
