import {useRecoilState, useSetRecoilState} from "recoil";
import {getReportingCategory} from "../../../../api/graphql/reportingCategory";
import {reportingCategoryLoadingState, reportingCategoryState} from "../../_general/state";
import {notificationMessage} from "../../../../../recoil_state";

const useReportingCategoryFunction = () => {
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const setreportingCategoryLoading = useSetRecoilState<any>(reportingCategoryLoadingState);
    const setReportCategories = useSetRecoilState<any>(reportingCategoryState);

    const getReportCategory = async (params?) => {
        try {
            setreportingCategoryLoading(true);
            const filter = params || null;
            const response = await getReportingCategory({filter});
            if (response?.getReportingCategory?.data) {
                const list = response?.getReportingCategory?.data;
                const sortedList = [...list]?.sort((a, b) => a?.sortingNumber - b?.sortingNumber);
                setReportCategories(sortedList);
            }
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error",
                key: "systemNotification",
            });
        } finally {
            setreportingCategoryLoading(false);
        }
    };

    return {
        getReportCategory,
    };
};

export {useReportingCategoryFunction};
