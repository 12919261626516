const getObjectValue = (data, objectName, fieldName) => {
  if (!data || !data[objectName]) {
    return "";
  }

  return data[objectName][fieldName];
};

const handleObjectChange = (setArray, value, objectName, fieldName) => {
  setArray((prevState) => {
    const temp = { ...prevState };

    if (!temp[objectName]) {
      temp[objectName] = {};
    }

    const updatedObj = { ...temp[objectName], [fieldName]: value };
    temp[objectName] = updatedObj;

    return temp;
  });
};

const getFileListFromObject = (value, objectName, fieldName) => {
  if (!value || !value[objectName]) {
    return [];
  }

  return value[objectName][fieldName] || [];
};

export { getObjectValue, handleObjectChange, getFileListFromObject };
