import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Checkbox, Col, Row, Steps} from "antd";
import { getReportingAll, getReportingFields, getReportingTypes } from "../../../../../api/graphql/reporting";
import MissionAutomationCriteria from "./missionAutomationCriteriaGroup";

const MissionAutomation = (props) => {
    const { conditionGroup, setConditionGroup, trigger, selectedTrigger, setSelectedTrigger, languageData, mode, currentRecord } = props
    const {t} = useTranslation();
    const [currentStep, setCurrentStep] = useState(0);
    const [currentTriggerGroup, setCurrentTriggerGroup] = useState<any>(null)
    const [reportFields, setReportFields] = useState<any>([])
    const [reportFieldTypes, setReportFieldTypes] = useState<any>([])
    const [currentReport, setCurrentReport] = useState<any>([])

    const triggerGroupList:any = Array.from(new Set(trigger?.map(item => item?.triggerGroup)))


    useEffect(() => {
        getReportFields()
        getReportFieldTypes()
    },[])
    
    const steps = [
        {
            key: t("Trigger"),
            title: t("Trigger"),
        },
        {
            key: t("Condition"),
            title: t("Condition"),
        },
    ];

    const next = () => {
        setCurrentStep(currentStep + 1);
    };

    const prev = () => {
        setCurrentStep(currentStep - 1);
    };

    const getReportFields = async () => {
        try {
            if(!reportFields || reportFields.length === 0){
                const response = await getReportingFields();
                if (response?.getReportingFields?.__typename === "ReportingDataAry") {
                    let filteredReportingFields = response?.getReportingFields?.data?.filter(item => item?.isUsedForAutomation)
                    setReportFields(filteredReportingFields);

                    let reportIdSet = new Set()
                    filteredReportingFields.forEach(item => {
                        reportIdSet.add(item?.reportId)
                    })

                    const reportResponse = await getReportingAll()
                    if (response?.getReportingFields?.__typename === "ReportingDataAry") {
                        let filteredReport = reportResponse?.getReportingAll?.data?.filter(item => reportIdSet.has(item?.reportId))
                        setCurrentReport(filteredReport)
                    }
                }
            }
        } catch (err) {
            console.log(err);
        } 
    };
    const getReportFieldTypes = async () => {
        try{
            const response = await getReportingTypes();
            const list = response?.getReportingTypes?.data;
            if (list) {
                setReportFieldTypes(list);
            }
        } catch (err) {
            console.log(err);
        } 
    };

    const getTriggerCheckboxValue = (triggerGroup, triggerName) => {
        const tempFilterGroup = selectedTrigger?.filter(item => item?.triggerGroup === triggerGroup)
        const tempFilterName = tempFilterGroup?.filter(item => item?.triggerName === triggerName)
        return tempFilterName?.length > 0
    }

    const onTriggerGroupChange = (item) => {
        setCurrentTriggerGroup(item)
    }

    const onTriggerChange = (e, triggerGroup, triggerName) => {
        if(e.target.checked){
            if(selectedTrigger && selectedTrigger.length > 0){
                const tempList = [...selectedTrigger, {
                    triggerGroup: triggerGroup,
                    triggerName: triggerName,
                }]
                setSelectedTrigger(tempList)
            }
            else {
                const tempList = [{
                    triggerGroup: triggerGroup,
                    triggerName: triggerName,
                }]
                setSelectedTrigger(tempList)
            }
        }else{
            const tempList = selectedTrigger?.filter(item => {
                return item?.triggerName !== triggerName
            })
            setSelectedTrigger(tempList)
        }
    }

    const setTriggerForTriggerGroup = () => {
        const tempList = trigger?.filter(item => item?.triggerGroup === currentTriggerGroup)
        return tempList?.map(item => {
            return (
                <div>
                    <Checkbox 
                        id={`${item?.triggerGroup}_${item?.triggerName}`}
                        checked={getTriggerCheckboxValue(item?.triggerGroup,item?.triggerName)} 
                        onChange={(e)=> onTriggerChange(e, item?.triggerGroup, item?.triggerName)}
                        disabled={currentRecord?.missionTotalClaimed > 0 || currentRecord?.missionTotalCompleted > 0}
                        >
                        {item?.triggerName}
                    </Checkbox>
                </div>
        )})
    }

    const returnSelectedTriggerList = () => {
        var displayTrigger:any = []
        for(var i = 0; i < selectedTrigger?.length; i++){
            let existIndex = displayTrigger?.findIndex(item => item.triggerGroup === selectedTrigger[i].triggerGroup)
            if(existIndex >= 0){
                displayTrigger[existIndex].triggers.push(selectedTrigger[i].triggerName)
            }
            else {
                displayTrigger.push(
                    {
                        triggerGroup: selectedTrigger[i].triggerGroup,
                        triggers: [selectedTrigger[i].triggerName]
                    }
                )
            }
        }
        return displayTrigger
    }

    const addConditionGroup = () => {
        setConditionGroup(prev => {
            return ([
                ...prev,
                {
                    position: prev?.length,
                    logicalOperator: "and",
                    criteriaItems: [{
                        comparisonOperator: "=",
                        reportId: "",
                        reportFieldId: "",
                        logicalOperator: "and",
                        position: 0,
                        value: ""
                    }]
                }
            ])
        })
    }
    
    return (
        <>
            <div className="automationCard">
                <Row className="automationCardSteps">
                    <Steps current={currentStep} items={steps} />
                </Row>

                {
                    currentStep === 0 &&
                    <Row className="automationCardContent">
                        <Col xs={6}>
                            <span className="automationBoxLabel">{t("Trigger Group")}</span>
                            <div className="automationBox">
                                <p>
                                    {triggerGroupList?.map(item => {
                                        return <div
                                            onClick={() => onTriggerGroupChange(item)} 
                                            className={(item === currentTriggerGroup)? "button-active" : "button:-inactive"}>
                                                {item}<br/>
                                            </div>
                                    })}
                                </p>
                            </div>
                        </Col>
                        <Col xs={9}>
                            <span className="automationBoxLabel">{t("Trigger")}</span>
                            <div className="automationBox">
                                {setTriggerForTriggerGroup()}
                            </div>
                        </Col>
                        <Col xs={9}>
                            <span className="automationBoxLabel">{t("Selected Trigger")}</span>
                            <div className="automationBox">
                                {returnSelectedTriggerList()?.map(item => {
                                    return (
                                        <>
                                            <div>
                                                {item?.triggerGroup}
                                            </div>
                                            <ul>
                                                {item?.triggers?.map(x => {
                                                    return (
                                                        <li>{x}</li>
                                                    )
                                                })}
                                            </ul>
                                        </>
                                    )
                                })}
                            </div>
                        </Col>
                    </Row>
                }

                {
                    currentStep === 1 &&
                    <div className="automationCardContent">
                        <div className="automationConditionContent">
                            <div className="topRightButton">
                                <Button onClick={()=> addConditionGroup()} disabled={currentRecord?.missionTotalClaimed > 0 || currentRecord?.missionTotalCompleted > 0}>
                                    {t("Add Condition Group")}
                                </Button>
                            </div>
                            <MissionAutomationCriteria
                                setConditionGroup={setConditionGroup}
                                conditionGroup={conditionGroup}
                                currentReport={currentReport}
                                reportFields={reportFields}
                                reportFieldTypes={reportFieldTypes}
                                languageData={languageData}
                                currentRecord={currentRecord}
                                mode={mode}
                            />
                        </div>
                    </div>
                }
                
                <div className="automationFooterButton">
                    {currentStep === 1 && (
                        <>
                        <Button className="previousBtn"onClick={() => prev()}>
                            {t("common.previous")}
                        </Button>
                        </>
                    )}
                    {currentStep === 0 && (
                        <>
                        <Button type="primary" className="nextBtn" onClick={() => next()}>
                            {t("common.next")}
                        </Button>
                        </>
                    )}
                </div>
            </div>
            
            {/* <Modal title="Conditions" open={conditionModal} onOk={() => addIntoCondition()} okText={t("Add Condition")} onCancel={() => onConditionModalClose()} destroyOnClose>
                <div id="conditionModal" >
                    <div className="automationCard">
                        <Row className="automationCardContent">
                            <Col xs={10}>
                                <span className="automationBoxLabel">{t("Condition Group")}</span>
                                <div className="automationBox">
                                    <p>
                                        {tempReport?.map(item => {
                                            return <div
                                                onClick={() => onConditionGroupChange(item)} 
                                                className={(item === currentConditionGroup)? "button-active" : "button:-inactive"}>
                                                    {item}<br/>
                                                </div>
                                        })}
                                    </p>
                                </div>
                            </Col>
                            <Col xs={14}>
                                <span className="automationBoxLabel">{t("Condition")}</span>
                                <div className="automationBox">
                                    {setConditionsForConditionGroup()}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Modal> */}
        </>
    );
};

export default MissionAutomation;
