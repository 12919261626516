import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import moment from "moment";

const getSizeOptionsListing = (type) => {
    let options;
    switch (type) {
        case "VIDEO":
            options = ["Video"];
            break;
        case "LANDING":
            options = ["Landing Page Thumbnail"];
            break;
        case "LOGO":
            options = ["1200x1200"];
            break;
        case "GIF":
            options = ["300x300", "720x90", "300x600"];
            break;
        case "BANNER":
            options = [
                "120x600",
                "160x600",
                "300x250",
                "300x600",
                "320x100",
                "320x50",
                "320x480",
                "720x90",
                "728x90",
                "800x800",
                "828x1200",
                "970x250",
                "980x250",
                "990x250",
                "1200x628",
                "1200x1200",
                "1200x1500",
            ];
            break;
        default:
            options = [];
            break;
    }
    return options;
};

const handleOnChangeWithLanguage = (value, field, fieldType, languageCode, setData) => {
    try {
        setData((prevState) => {
            if (!prevState || !prevState[field]) {
                return {
                    ...prevState,
                    [field]: [
                        {
                            [fieldType]: value,
                            language: languageCode,
                        },
                    ],
                };
            }

            var currentData = [...prevState[field]] || [];
            var index = currentData?.findIndex((item) => item?.language === languageCode);

            if (index === -1) {
                currentData?.push({[fieldType]: value, language: languageCode});
            } else {
                const updatedObj = {...currentData[index], [fieldType]: value};
                currentData[index] = updatedObj;
            }

            var result = {
                ...prevState,
                [field]: currentData,
            };

            return result;
        });
    } catch (error) {
        console.log(error);
    }
};

const handleObjectChange = (setArray, value, field, language, size, type, status:any | null = null) => {
    try {
        setArray((prevState) => {
            const index = prevState.findIndex((item) => item.language === language && item.size === size && item?.type === type);
            const temp = [...prevState];
            const isUploading = status?.percent !== 100
            const uploadProgress = {status: isUploading ? "uploading" : "", percent: status?.percent}

            if (index === -1) {
                temp.push({[field]: value, language: language, size: size, type: type, ...uploadProgress});
            } else {
                const updatedObj = {...temp[index], [field]: value, ...uploadProgress};
                temp[index] = updatedObj;
            }

            return temp;
        });
    } catch (error) {}
};

const getTextFromObject = (array, field, language) => {
    try {
        const index = array.findIndex((item) => item.language === language);

        if (!array || !array[index] || !array[index][field]) {
            return "";
        }

        return array[index][field];
    } catch (error) {}
};

const getListFromObject = (array, field, language, size, type) => {
    try {
        const index = array?.findIndex((item) => item.language === language && item.size === size && item.type === type);

        if (!array || !array[index]) {
            return [];
        }

        return array[index][field];
    } catch (error) {}
};

function exportToExcel(file, fileType: any = "xlsx") {
    try {
        var fileFormat = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;";
        if (fileType === "csv") {
            fileFormat = "text/csv;charset=utf-8";
        }
        if (!file) {
            return null;
        }
        let header = ["variableName", "type", "emailValue", "displayName", "fileType", "text", "fileName", "path", "language", "isActive"];
        const ws = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [header]);
        XLSX.utils.sheet_add_json(ws, file, {origin: "A2", skipHeader: true});
        const wb = {Sheets: {data: ws}, SheetNames: ["data"]};
        const buffer = XLSX.write(wb, {bookType: fileType, type: "array", cellStyles: true});
        const finalData = new Blob([buffer], {type: fileFormat});
        FileSaver.saveAs(finalData, `VariableFile_${moment().format("DD-MM-YYYY")}.${fileType}`);
    } catch (error) {}
}

export {getSizeOptionsListing, handleOnChangeWithLanguage, getListFromObject, getTextFromObject, handleObjectChange, exportToExcel};
