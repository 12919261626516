import {useState} from "react";
import {getRewardSetting} from "../../../../../api/restful/arena/rewardSetting";

export const useGetRewardSetting = () => {
    const [data, setData] = useState<any>(null)

    const fetchRewardSetting = async () => {
        try {
            const {data} = await getRewardSetting()

            console.log(data)
            setData(data)
        } catch (e) {

        }
    }

    return [data, fetchRewardSetting] as const
}