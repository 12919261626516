import React from "react";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import {columns} from "../../../function/tableColumn_CT";
import {useDeleteProductGroupMutation,} from "../../../../../../api/graphql/productGroup";
import {Popconfirm, Space} from "antd";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import {useTranslation} from "react-i18next";
import useAuthorize from "../../../../../../_common/authorize";

function ProductGroupTable_1(props) {
  const {
      data,
      totalCount,
      loading,
      limit,
      tableName,
      permission,
      openEdit,
      setOrderBy,
      setFilter,
      onChangePageHandler
  } = props;
  const {t} = useTranslation()

  const { deleteProductGroup } = useDeleteProductGroupMutation();
  const { systemErrorNotification } = useAuthorize();

  const handleProductGroupDelete = (id) => {
    try {
      deleteProductGroup(id);
    } catch (error) {
      systemErrorNotification();
    }
  }

  return (
    <div className={"margin-top-0-75"}>
      <TableAntDesign
        data={data}
        tableName={tableName}
        columns={[
          ...columns,
          {
            title: t("common.action"),
            key: "action",
            width: 100,
            align: "center",
            render: (_, record: any) => (
              <Space>
                { permission?.edit?.edit &&
                  <a style={{color: "green"}} onClick={() => openEdit(record)}>
                  <AiOutlineEdit/>
                </a>
                }
                { permission?.delete?.delete &&
                  <Popconfirm
                    placement="left"
                    description={t("Are you sure to delete this product group?")}
                    title={t(`Delete the Product Group`)}
                    onConfirm={() => {
                      handleProductGroupDelete(record?.id || record?._id);
                    }}
                    okText={t("Yes")}
                    okType="danger"
                    cancelText={t("No")}
                >
                  <a>
                    <AiOutlineDelete style={{color: "red"}}/>
                  </a>
                </Popconfirm>
                }
              </Space>
            ),
          },
        ]}
        size={"small"}
        loading={loading}
        filter={setFilter}
        actionPermission={permission?.edit?.edit || permission?.delete?.delete}
        order={setOrderBy}
        pagination={{
          pageSize: limit,
          showSizeChanger: true,
          onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
          total: totalCount,
          showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
        }}
      />
    </div>
  );
}

export default ProductGroupTable_1;
