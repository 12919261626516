import {
    getBooleanFilterProps,
    getColumnSearchProps,
    getDateRangeFilterProps
} from "../../../../components/table/function/filterTable";
import {BsCheck, BsX} from "react-icons/bs";
import moment from "moment";
import {formatMoney} from "../../../accounts/tradingAccount/_general/function";
import React, {useState} from "react";
import {Button, Tag} from "antd";
import CronDataModal from "../view/layout1/component/logModal";
import LogModal from "../view/layout1/component/logModal";


export const columnLoggerDetails = (idModal = true) => {

    const AssignedUser = ({text,record}) => {
        if (record?.requestedUserType === "client") {
            return (
                <Button
                    style={{fontSize: "12px", padding: "unset"}}
                    onClick={() => window.open("/users/client/detail?id=" + record?.requestedId, "_blank")}
                    type={"link"}>{text}</Button>
            )
        } else {
            return (
                <Button
                    style={{fontSize: "12px", padding: "unset"}}
                    onClick={() => window.open("/users/crm-user/detail?id=" + record?.requestedId, "_blank")}
                    type={"link"}>{text}</Button>
            )
        }
    }

    const TitleModal = ({text, record}) => {
        const [isOpen, setIsOpen] = useState(false)

        return (
            <>
                <a onClick={() => setIsOpen(true)}>{text}</a>
                <LogModal isOpen={isOpen} closeModal={() => setIsOpen(false)} parent={record?.parent} idModal={false}/>
            </>
        )
    }

    return [
        {
            title: "Id",
            dataIndex: "id",
            key: "id",
            ...getColumnSearchProps(),
            render: (text, record) => idModal ? <TitleModal text={text} record={record}/> : text,
        },
        {
            title: "Module Name",
            dataIndex: "moduleName",
            key: "moduleName",
            ...getColumnSearchProps(),
            render: (text) => text,
        },
        {
            title: "Sub Module Name",
            dataIndex: "subModuleName",
            key: "subModuleName",
            ...getColumnSearchProps(),
            render: (text) => text,
        },
        {
            title: "Log level",
            dataIndex: "logLevel",
            key: "logLevel",
            ...getColumnSearchProps(),
            render: (text) => text === "info" ? <Tag bordered={false} color={"processing"}>{text}</Tag> : text === "error" || text === "fatal" ?
                <Tag bordered={false} color={"error"}>{text}</Tag> : <Tag bordered={false} color={"default"}>{text}</Tag>,
        },
        {
            title: "Content",
            dataIndex: "content",
            key: "content",
            ...getColumnSearchProps(),
            render: (text) => text || "-",
        },
        {
            title: "Requested By",
            dataIndex: "requestedUsername",
            key: "requestedUsername",
            ...getColumnSearchProps(),
            render: (text,record) => <AssignedUser text={text} record={record}/>,
        },
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            ...getDateRangeFilterProps(),
            render: (text) => <p>{text ? moment(text)?.format("YYYY-MM-DD HH:mm:ss") : "-"}</p>
        },
    ]
};