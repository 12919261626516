import React, {useEffect, useState} from "react";
import {Col, Row, Spin, Tabs} from "antd";
import {Link} from "react-router-dom";
import GrapeJSEditor from "./component/GrapeJSEditor";
import {useTranslation} from "react-i18next";
import {LoadingOutlined} from "@ant-design/icons";
import {getTextFromObject} from "../../function/common";

function AnnouncementEditor_1(props) {
    const {data, languageData, updateLoading} = props;
    const {t} = useTranslation();
    const [activeTab, setActiveTab] = useState("en");
    const [announcementName, setAnnouncementName] = useState();

    const fetchData = async () => {
        try {
            const dataArray = await data;
            if (dataArray?.getAnnouncementById) {
                setAnnouncementName(dataArray?.getAnnouncementById?.name || null);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        fetchData();
    }, [data]);

    const onTabChange = (key: string) => {
        setActiveTab(key);
    };

    const tabItems = languageData?.map((item, index) => {
        return {
            key: item.code,
            label: item.name,
            disabled: updateLoading,
            children: <GrapeJSEditor languageCode={item.code} {...props} />,
        };
    });

    return (
        <div id="announcementEditor">
            <Row justify={"space-between"} wrap={false}>
                <Col>
                    <h1>
                        {t("Announcement Editor")}{announcementName && ` - ${announcementName}`}
                    </h1>
                </Col>
                <Col className="backLinkContainer">
                    <Link to="/settings/announcement" className="text-link">
                        Back to Announcement
                    </Link>
                </Col>
            </Row>
            <Tabs
                defaultActiveKey="en"
                items={tabItems}
                onChange={onTabChange}
                tabBarExtraContent={updateLoading && <Spin tip="Loading..." indicator={<LoadingOutlined/>}/>}
            />
        </div>
    );
}

export default AnnouncementEditor_1;
