import React, {useEffect, useRef, useState} from "react"
import {Table} from "antd"
import {useTranslation} from "react-i18next"
import {isArray} from "@apollo/client/utilities"
import {getUserTableAdminSetting, getUserTableSetting} from "../../api/graphql/tableSetting"

const TableReportAntDesign = (props) => {
    const {
        data, //Table Data
        columns, // Table Column
        displayMode, // Table Report Display Mode
        size,
        loading,
        pagination, // Pagination options
        filter, // Set API Filter
        startDate, // Date Filter StartDate
        endDate, // Date Filter EndDate
        dateKey, // Date Filter DateKey
        server, // Server Name
        order, // Set API Order
        onRow,
        rowKey, // specified rowKey else _id
        rowClassName,
        selectionType,
        onRowSelectionHandler,
        selectedRowKeys,
        tableName, // For Export and Table Preference
        sort,
        scroll,
        isTableLocalSearch,
        actionPermission = true, // permission for Action Column
        summary,
        showSorterTooltip = true,
        summaryData,
        expandable,
    } = props

    const sortEnable = sort === undefined

    const tableRef = useRef<any>()
    const {t} = useTranslation()
    const [tableColumn, setTableColumn] = useState<any>(columns)
    const [dataSource, setDataSource] = useState<any>()

    const {data: adminSettingTable, loading: adminSettingLoading} = getUserTableAdminSetting({
        filter: {
            tableName: tableName,
            serverName: server || null,
        },
    })
    const {data: userTablePreference, loading: userSettingLoading} = getUserTableSetting(localStorage.getItem("userId"), {
        filter: {
            tableName: tableName,
            serverName: server || null,
        },
    })

    useEffect(() => {
        setDataSource(data)
    }, [data])

    // useEffect(() => {
    //     setTableColumn(columns);
    // }, [columns]);

    // console.log("tableColumn");
    // console.log(tableColumn);
    // console.log("dataSource");
    // console.log(dataSource);

    const formatColumns = async () => {
        const actionObj = columns?.find((obj) => obj?.key === "action")
        if (userTablePreference?.getUserTableSetting?.tableColumn?.length > 0 && adminSettingTable?.getUserTableAdminSetting?.data?.[0]?.fieldData?.length > 0) {
            const userPreference = userTablePreference.getUserTableSetting.tableColumn
            const adminPreference = adminSettingTable.getUserTableAdminSetting?.data[0].fieldData
            return await userPreference
                .map((d) => {
                    const matchingAdminObj = adminPreference?.find((obj) => obj?.fieldName === d?.columnName || (Array.isArray(obj?.fieldName) && obj?.fieldName.join("_") === d?.columnName))

                    const matchingObj = columns?.find((obj) => (obj?.dataIndex === d?.columnName || (Array.isArray(obj?.dataIndex) && obj?.dataIndex.join("_") === d?.columnName)) && matchingAdminObj?.isSelectableByUser)
                    return {
                        ...matchingObj,
                        sorter: d?.key !== "action" && sortEnable && !isTableLocalSearch,
                    }
                })
                ?.filter((d) => d?.key)
                .concat(actionPermission && actionObj ? actionObj : [])
        } else if (userTablePreference?.getUserTableSetting?.tableColumn?.length > 0 && !adminSettingTable?.getUserTableAdminSetting?.data?.[0]?.fieldData?.length) {
            const userPreference = userTablePreference.getUserTableSetting.tableColumn
            return await userPreference
                .map((d) => {
                    const matchingObj = columns?.find((obj) => obj?.dataIndex === d?.columnName || (Array.isArray(obj?.dataIndex) && obj?.dataIndex.join("_") === d?.columnName))
                    return {
                        ...matchingObj,
                        sorter: d?.key !== "action" && sortEnable && !isTableLocalSearch,
                    }
                })
                .concat(actionPermission && actionObj ? actionObj : [])
        } else if (adminSettingTable?.getUserTableAdminSetting?.data?.[0]?.fieldData?.length > 0 && !userTablePreference?.getUserTableSetting?.tableColumn?.length) {
            const userPreference = adminSettingTable.getUserTableAdminSetting?.data[0].fieldData
            return await userPreference
                ?.filter((d) => d?.isDisplayToUser)
                .map((d) => {
                    const matchingObj = columns?.find((obj) => obj?.dataIndex === d?.fieldName || (Array.isArray(obj?.dataIndex) && obj?.dataIndex.join("_") === d?.columnName))
                    return {
                        ...matchingObj,
                        sorter: d?.key !== "action" && sortEnable && !isTableLocalSearch,
                    }
                })
                .concat(actionPermission && actionObj ? actionObj : [])
        } else {
            return await columns
                ?.map((d) => {
                    return {
                        ...d,
                        sorter: d?.key !== "action" && sortEnable && !isTableLocalSearch,
                    }
                })
                ?.filter((d) => !(d?.key === "action"))
                ?.concat(actionPermission && actionObj ? actionObj : [])
        }
    }

    useEffect(() => {
        formatColumns()?.then((data) => {
            let amendedColumns = data?.sort((a, b) => a?.defaultPosition - b?.defaultPosition)
            if (displayMode !== "DATE") {
                amendedColumns = amendedColumns?.filter((v) => !v?.toBeExcludedFromColumns)
            }
            setTableColumn(amendedColumns)
        })
    }, [JSON.stringify(columns), userTablePreference?.getUserTableSetting?.tableColumn, adminSettingTable?.getUserTableAdminSetting?.data, adminSettingLoading, userSettingLoading])

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => onRowSelectionHandler(selectedRowKeys, selectedRows) || undefined,
    }

    const onChange = (pagination, filters, sorter, extra) => {
        if (isTableLocalSearch) {
            const filteredData: any = []

            for (let key in filters) {
                if (filters[key] === null) {
                    delete filters[key]
                }
            }

            if (Object.keys(filters).length > 0) {
                for (const [key, value] of Object.entries(filters)) {
                    const [val] = isArray(value) ? value : ""
                    data?.map((d) => {
                        if (isArray(val) && (val[0] === "true" || val[0] === "false")) {
                            if (d[key]?.toString()?.toLowerCase()?.includes(val[0]?.toLowerCase())) filteredData.push(d)
                        } else if (d[key]?.toString()?.toLowerCase()?.includes(val?.toLowerCase())) {
                            filteredData.push(d)
                        }
                    })
                }
                setDataSource(filteredData)
            } else {
                setDataSource(data)
            }
        } else {
            const newFilters: any[] = []
            for (const [key, value] of Object.entries(filters)) {
                const [...val] = isArray(value) ? value : ""
                if (isArray(val)) {
                    // if (val[0] === "true") {
                    //     newFilters[key] = true;
                    // } else if (val[0] === "false") {
                    //     newFilters[key] = false;
                    // } else if (val[0] === "date") {
                    //     startDate(val[1][0]);
                    //     endDate(val[1][1]);
                    //     dateKey(key);
                    // } else {
                    //     newFilters[key] = val?.length > 0 ? val[0] : undefined;
                    // }
                    if (val?.length > 0) {
                        newFilters.push({
                            fieldName: key?.split("-")?.[0], // filter's key is the key of the column - which currently is reportFieldName-fieldId
                            operator: "CONTAINS",
                            value: val,
                        })
                    }
                } else {
                    newFilters[key] = val ? val : undefined
                }
            }

            // console.log("newFilters");
            // console.log(newFilters);

            // const formattedFilters = Object.fromEntries(Object.entries(newFilters).filter(([key, value]) => value !== undefined));

            // const filterParams = server !== null && server !== undefined ? {...formattedFilters, serverName: server} : formattedFilters;

            // For Debug
            //       {
            //         fieldName: "positionId",
            //         operator: "E",
            //         value: ["177636"],
            //       },

            filter(newFilters)

            // For Debug
            //     {
            //         fieldName: "depositAmountEWallet_user",
            //         operator: "DESCENDING", // "DESCENDING / ASCENDING"
            //         value: null,
            //     },

            if (sorter.order === "ascend" || sorter.order === "descend") {
                order({
                    fieldName: sorter?.column?.reportFieldName,
                    operator: sorter.order === "ascend" ? "ASCENDING" : "DESCENDING",
                    value: null,
                })
            } else {
                order(null)
            }
        }
    }

    const SummaryRow = () => {
        if (summaryData && Object.keys(summaryData)?.length > 0) {
            const formatDisplayValue = (value) => (value !== undefined ? (value != null ? value.toLocaleString() : "0") : false)
            return (
                <Table.Summary fixed="bottom">
                    <Table.Summary.Row>
                        {tableColumn?.map((column: any, index: number) => {
                            return (
                                <Table.Summary.Cell index={index} align="right" className="totalCellText">
                                    {Object.keys(summaryData)?.map((key) => {
                                        let keysToHide = ["uniqueCount"]
                                        if (!keysToHide.includes(key)) {
                                            let value = summaryData[key]?.[column?.dataIndex]?.displayValue
                                            if (value) {
                                                return <p>{key === "total" ? formatDisplayValue(value) : `${t(`reports.${key}`)}: ${formatDisplayValue(value)}`}</p>
                                            }
                                        }
                                    })}
                                </Table.Summary.Cell>
                            )
                        })}
                    </Table.Summary.Row>
                </Table.Summary>
            )
        } else {
            return null
        }
    }

    const amendedPagination = isTableLocalSearch
        ? {
              showSizeChanger: true,
              total: dataSource?.length,
              showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
          }
        : pagination

    return (
        <>
            {/* {!loading && ( */}
            <Table
                ref={tableRef}
                bordered={false}
                columns={tableColumn}
                rowKey={rowKey ? rowKey : (record) => record.id || record?._id}
                onRow={onRow ? (record, rowIndex) => onRow(record, rowIndex) : undefined}
                rowClassName={rowClassName}
                dataSource={!loading && dataSource}
                size={size}
                pagination={amendedPagination}
                onChange={onChange}
                scroll={scroll || undefined}
                loading={loading}
                rowSelection={
                    selectionType
                        ? {
                              type: selectionType,
                              ...rowSelection,
                          }
                        : undefined
                }
                summary={summaryData && Object.keys(summaryData)?.length > 0 ? SummaryRow : undefined}
                showSorterTooltip={showSorterTooltip}
                expandable={expandable}
            />
            {/* )} */}
        </>
    )
}

export default TableReportAntDesign
