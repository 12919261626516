import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Tag} from "antd";
import {getColumnSearchProps} from "../../../../components/table/function/filterTable";

export const columnsPromoMaterial = () => {
    const {t} = useTranslation();
    const language = localStorage.getItem("language");

    const SeeMoreNetworkComponent = (prop) => {
        const [view, setView] = useState(false);
        const {text, record} = prop;
        const network = text || record?.network || [];

        let tagsToShow = network?.slice(0, view ? network.length : 3);

        const renderTag = (tag, i) => (
            <Tag bordered={false} key={i} style={{margin: 2}} color={i === 0 ? "blue" : "default"}>
                {tag?.username}
            </Tag>
        );

        const renderSeeMoreTag = () => (
            <Tag bordered={false} style={{margin: 2, cursor: "pointer"}} color={"green"} onClick={() => setView(true)}>
                ...
            </Tag>
        );

        return (
            <div>
                {tagsToShow?.length > 0 ? (
                    <>
                        {tagsToShow?.map(renderTag)}
                        {!view && text?.length > 3 && renderSeeMoreTag()}
                    </>
                ) : (
                    "-"
                )}
            </div>
        );
    };

    const SeeMoreTagComponent = ({text}) => {
        const [view, setView] = useState(false);

        let tagsToShow = text?.slice(0, view ? text.length : 3);

        const renderTag = (tag, i) => (
            <Tag bordered={false} key={i} style={{margin: 2}} color={tag?.isDeleted ? "red" : "blue"}>
                {tag?.name}
            </Tag>
        );

        const renderSeeMoreTag = () => (
            <Tag bordered={false} style={{margin: 2, cursor: "pointer"}} color={"green"} onClick={() => setView(true)}>
                ...
            </Tag>
        );

        return (
            <div>
                {tagsToShow?.length > 0 ? (
                    <>
                        {tagsToShow?.map(renderTag)}
                        {!view && text?.length > 3 && renderSeeMoreTag()}
                    </>
                ) : (
                    "-"
                )}
            </div>
        );
    };

    return [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            ...getColumnSearchProps(),
            render: (text) => <p>{text}</p>,
        },
        {
            title: "Display Name",
            dataIndex: "displayName",
            key: "displayName",
            render: (displayName) => {
                let displayText = displayName?.find((d) => d?.language === language)?.label || displayName?.[0]?.label;
                return <p>{displayText || "-"}</p>;
            },
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            filters: [
                {text: "BANNER", value: "BANNER"},
                {text: "VIDEO", value: "VIDEO"},
                {text: "LANDING", value: "LANDING"},
                {text: "GIF", value: "GIF"},
                {text: "LOGO", value: "LOGO"},
            ],
            render: (text) => <p>{text}</p>,
        },
        {
            title: "Status",
            dataIndex: "isActive",
            key: "isActive",
            filters: [
                {text: "Active", value: true},
                {text: "Inactive", value: false},
            ],
            render: (isActive) => <p>{isActive ? t("common.active") : t("common.inactive")}</p>,
        },
        {
            title: "Which network can view",
            dataIndex: "networkType",
            key: "networkType",
            filters: [
                {text: "All Users", value: "ALL"},
                {text: "Some Users", value: "PARTIAL"},
                {text: "Only Me", value: "OWN"},
            ],
            render: (text) => {
                if (text === "PARTIAL") return <p>Some Users</p>;
                else if (text === "ALL") return <p>All Users</p>;
                else if (text === "OWN") return <p>Only Me</p>;
            },
        },
        {
            title: "Include",
            dataIndex: "network",
            key: "network",
            ...getColumnSearchProps(),
            render: (text, record) => <SeeMoreNetworkComponent record={record} />,
        },
        {
            title: "Exclude",
            dataIndex: "exclude",
            key: "exclude",
            ...getColumnSearchProps(),
            render: (text) => <SeeMoreNetworkComponent text={text || "-"} />,
        },
        {
            title: "Campaign",
            dataIndex: "campaign",
            key: "campaign",
            ...getColumnSearchProps(),
            render: (text) => <p>{text?.name || "-"}</p>,
        },
        {
            title: "Promo Tag",
            dataIndex: "promoTag",
            key: "promoTag",
            ...getColumnSearchProps(),
            render: (text) => <SeeMoreTagComponent text={text || "-"} />,
        },
        {
            title: "Lead Tag",
            dataIndex: "leadTag",
            key: "leadTag",
            ...getColumnSearchProps(),
            render: (text) => <SeeMoreTagComponent text={text || "-"} />,
        },
        {
            title: "Redirect URL",
            dataIndex: "redirectUrl",
            key: "redirectUrl",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
    ];
};
