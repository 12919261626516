import React, {useEffect, useState} from "react";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import {getAssetClassCount, getAssetClassDetails} from "../../../../../../api/graphql/assetClass";
import {columnsCT} from "../../../function/tableColumn_CT";
import {useTranslation} from "react-i18next";

function SecuritiesTable_1(props) {
    const {server, setExportData, setExportColumn, tableName, currentServer} = props;
    const {t} = useTranslation();
    const [filteredData, setFilteredData] = useState([]);
    const [orderBy, setOrderBy] = useState("");
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [filter, setFilter] = useState({serverName: server?.name});

    const {data: totalCount} = getAssetClassCount({filter});
    const {loading, error, data} = getAssetClassDetails({filter}, limit, offset, orderBy);
    const columns = server?.platform === "cTrader" ? columnsCT : [];
    const {data: exportData} = getAssetClassDetails({filter}, 0, 0, orderBy);

    const filterData = async (data) => {
        const latestData = await data?.map((d, i) => {
            return {
                ...d,
                server: d?.server?.name || "-",
                symbolName: d?.symbolCategoryList?.symbolList?.map((symbol) => symbol?.name) || "",
                key: i + 1,
            };
        });
        return latestData;
    };

    const finalExportData = async (data) => {
        const filtered = await data?.map((d, i) => {
            return {
                ...d,
                symbolName: d?.symbolName.length > 0 ? d?.symbolName?.join(", ") : d?.symbolName,
            };
        });

        return filtered;
    };

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize);
        setOffset(limit * (page - 1));
    };

    useEffect(() => {
        filterData(data?.getAssetClassDetails?.data)?.then((latestData) => {
            setFilteredData(latestData);
        });
    }, [data]);

    useEffect(() => {
        if (exportData?.getAssetClassDetails?.data?.length > 0 && columns) {
            filterData(exportData?.getAssetClassDetails?.data)?.then((latestData) => {
                finalExportData(latestData)?.then((exportData) => setExportData(exportData));
            });
        }
        setExportColumn(columns);
    }, [exportData, currentServer]);


    return (
        <div className={"margin-top-0-75"}>
            <TableAntDesign data={filteredData}
                            tableName={tableName}
                            columns={columns}
                            size={"small"}
                            loading={loading}
                            filter={setFilter}
                            scroll={false}
                            server={server?.name}
                            order={setOrderBy}
                            pagination={{
                                pageSize: limit,
                                showSizeChanger: true,
                                onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                                total: totalCount?.getAssetClassCount,
                                showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                            }}
            />
        </div>
    )
}

export default SecuritiesTable_1;
