import React from "react";
import PendingList_1 from "./view/layout1";
import {useGetUploadUserLeadsCsv} from "./function/useQueryHooks";

const PendingList = () => {
    let layout = 1;
    let component;

    const [data, loading, fetchData, setFilter, limit, setLimit, setOffset, setOrder] = useGetUploadUserLeadsCsv()

    const props = {
        tableName: "Leads Upload Pending List",
        tableDisplayName: "lead.leads_upload_pending_list",
        data: data?.data,
        total: data?.total,
        loading: loading,
        limit: limit,
        setLimit: setLimit,
        setOffset: setOffset,
        setFilter: setFilter,
        fetchData: fetchData
    }

    switch (layout) {
        case 1:
            component = <PendingList_1 {...props}/>
            break;

        default:
            component = <PendingList_1 {...props}/>
            break;
    }

    return component;
}

export default PendingList