import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {FormProvider} from "react-hook-form";
import {Col, Form, Row} from "antd";
import {horizontalFormLayout, verticalFormLayout} from "../../../../../../components/form/formWrapper";
import SwitchFormItem from "../../../../../../components/form/switchFormItem";
import RadioButtonGroupFormItem from "../../../../../../components/form/radioButtonGroupFormItem";
import InputNumberFormItem from "../../../../../../components/form/inputNumberFormItem";
import InputNumberSelectFormItem from "../../../../../../components/form/inputNumberSelectFormItem";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import SelectSelectFormItem from "../../../../../../components/form/selectSelectFormItem";
import { useIsMobile } from "../../../../../../_common/layout";

const PspDetailForm = (props) => {
    const {form, item, labelCol, pspSettingName, formMode} = props;
    const {t} = useTranslation();
    const isMobile = useIsMobile(768);

    const [errMsg, setErrMsg] = useState("");
    const [ depositFixFeeAlert, setDepositFixFeeAlert] = useState(false)
    const [ withdrawalFixFeeAlert, setWithdrawalFixFeeAlert] = useState(false)

    const [initialValues] = useState(() => ({
        // enabled: item?.enabled ? true : false,
        // depositEnabled: item?.depositEnabled ? true : false,
        // withdrawalEnabled: item?.withdrawalEnabled ? true : false,
        // name: item?.name || "",
        // displayName: item?.displayName || "",
        // processingTime: item?.processingTime || "",
        // logo: item?.logo[0]?.link || "",
        // logoSmall: item?.logoSmall[0]?.link || "",
    }));

    let paymentMethodName = item?.paymentMethod;
    let currencyName = item?.pspCurrency?.currency.replace(/\./g, "_");

    const crytoPspList = ["BTC", "ETH", "USDT"];

    const feeTypeOptions = [
        {label: t("psp.feeTypeValue.markUp"), value: "markUp"},
        {label: t("psp.feeTypeValue.separate"), value: "separate"},
    ];

    const feeRoundingOptions = [
        {label: "0.01", value: "0.01"},
        {label: "0.1", value: "0.1"},
        {label: "1", value: "1"},
        {label: "10", value: "10"},
        {label: "100", value: "100"},
        {label: "1000", value: "1000"},
    ];

    const feeRoundingTypeOptions = [
        {label: "-", value: ""},
        {label: t("psp.feeRoundingTypeValue.up"), value: "up"},
        {label: t("psp.feeRoundingTypeValue.down"), value: "down"},
    ];

    const feeConditionOptions = [
        {label: "AND (+)", value: "and"},
        {label: "OR (/)", value: "or"},
    ];

    const handleFormValueChg = (fieldName, fieldValue) => {
        try {
            form.setValue(fieldName, fieldValue);
        } catch (err) {}
    };

    let prefixFormName = `${currencyName}-${paymentMethodName?.replace(/[.-]/g, "_")}`;

    // const minDepositValue = form?.getValues(`${prefixFormName}-minDeposit`);
    // let maxDepositValue =
    //     form?.getValues(`${prefixFormName}-maxDeposit`) > minDepositValue ? form?.getValues(`${prefixFormName}-maxDeposit`) : minDepositValue;
    // const minWithdrawalValue = form?.getValues(`${prefixFormName}-minWithdrawal`);
    // let maxWithdrawalValue =
    //     form?.getValues(`${prefixFormName}-maxWithdrawal`) > minWithdrawalValue
    //         ? form?.getValues(`${prefixFormName}-maxWithdrawal`)
    //         : minWithdrawalValue;
    const calculateAlert = (currency, type) => {
        let chargesValue = 0
        switch(type){
            case "deposit":
                let minDeposit = form.getValues(`${currency}-minDeposit`)
                let depositFee = form.getValues(`${currency}-depositFee`)
                let depositFeeClient = form.getValues(`${currency}-depositFeeClient`)
                let depositFeeFixed = form.getValues(`${currency}-depositFeeFixed`)
                let depositFeeFixedClient = form.getValues(`${currency}-depositFeeFixedClient`)
                let depositFeeCondition = form.getValues(`${currency}-depositFeeCondition`)

                if(depositFeeCondition === "or"){
                    if(!minDeposit || minDeposit === 0){
                        setDepositFixFeeAlert(false)
                        return
                    }
                    if(depositFee && depositFee > 0){
                        if(depositFeeFixed && depositFeeFixed > 0){
                            chargesValue = depositFee * minDeposit / 100
                            if(chargesValue < depositFeeFixed){
                                setDepositFixFeeAlert(true)
                                return
                            }
                        }
                    }

                    if(depositFeeClient && depositFeeClient > 0){
                        if(depositFeeFixedClient && depositFeeFixedClient > 0){
                            chargesValue = depositFeeClient * minDeposit / 100
                            if(chargesValue < depositFeeFixedClient){
                                setDepositFixFeeAlert(true)
                                return
                            }
                        }
                    }
                }
                setDepositFixFeeAlert(false)
                break
            case "withdrawal":
                let minWithdrawal = form.getValues(`${currency}-minWithdrawal`)
                let withdrawalFee = form.getValues(`${currency}-withdrawalFee`)
                let withdrawalFeeClient = form.getValues(`${currency}-withdrawalFeeClient`)
                let withdrawalFeeFixed = form.getValues(`${currency}-withdrawalFeeFixed`)
                let withdrawalFeeFixedClient = form.getValues(`${currency}-withdrawalFeeFixedClient`)
                let withdrawalFeeCondition = form.getValues(`${currency}-withdrawalFeeCondition`)

                if(withdrawalFeeCondition === "or"){
                    if(!minWithdrawal || minWithdrawal === 0){
                        setWithdrawalFixFeeAlert(false)
                        return
                    }
                    if(withdrawalFee && withdrawalFee > 0){
                        if(withdrawalFeeFixed && withdrawalFeeFixed > 0){
                            chargesValue = withdrawalFee * minWithdrawal / 100
                            if(chargesValue < withdrawalFeeFixed){
                                setWithdrawalFixFeeAlert(true)
                                return
                            }
                        }
                    }

                    if(withdrawalFeeClient && withdrawalFeeClient > 0){
                        if(withdrawalFeeFixedClient && withdrawalFeeFixedClient > 0){
                            chargesValue = withdrawalFeeClient * minWithdrawal / 100
                            if(chargesValue < withdrawalFeeFixedClient){
                                setWithdrawalFixFeeAlert(true)
                                return
                            }
                        }
                    }
                }
                setWithdrawalFixFeeAlert(false)
                break
        }
    }

    return (
        <div id="PspDetailForm">
            <h4>
                {paymentMethodName} ({currencyName})
            </h4>
            { depositFixFeeAlert && <p style={{color: 'red'}}>* {t("If fee percantage charges on deposit is less than the fixed fee charges, system will charge based on whichever is higher")}</p>} 
            { withdrawalFixFeeAlert && <p style={{color: 'red'}}>* {t("If fee percantage charges on withdrawal is less than the fixed fee charges, system will charge based on whichever is higher")}</p>}
            <FormProvider {...form}>
                <Form className="pspDetail-form-1" labelWrap={true}>
                    {errMsg && <p style={{color: "red"}}>{errMsg}</p>}
                    <Row>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <InputFormItem
                                name={`${prefixFormName}-displayName`}
                                label={"Display Name"}
                                placeholder={"Display Name"}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                disabled={formMode === "view"}
                            />
                        </Col>
                    </Row>
                    <Row gutter={10}>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <SwitchFormItem
                                name={`${prefixFormName}-depositEnabled`}
                                label={t("Deposit Enabled")}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                size="small"
                                disabled={formMode === "view"}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <SwitchFormItem
                                name={`${prefixFormName}-withdrawalEnabled`}
                                label={t("Withdrawal Enabled")}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                size="small"
                                disabled={formMode === "view"}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <RadioButtonGroupFormItem
                                name={`${prefixFormName}-feeType`}
                                label={t("Fee Type")}
                                options={feeTypeOptions}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                disabled={formMode === "view"}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <SelectSelectFormItem
                                label={t("Fee Rounding")}
                                labelCol={labelCol}
                                labelAlign="left"
                                colon={false}
                                disabled={formMode === "view"}
                                nameSelect={`${prefixFormName}-feeRounding`}
                                nameSelect2={`${prefixFormName}-feeRoundingType`}
                                options={feeRoundingOptions}
                                options2={feeRoundingTypeOptions}
                                onChange={(value) => handleFormValueChg(`${prefixFormName}-feeRounding`, value)}
                                onChange2={(value) => handleFormValueChg(`${prefixFormName}-feeRoundingType`, value)}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        {crytoPspList?.includes(pspSettingName) ? (
                            <Col className="gutter-row" xs={24} sm={12} md={12}>
                                <InputFormItem
                                    name={`${prefixFormName}-cryptoWalletAddress`}
                                    label={"Crypto Wallet Address"}
                                    placeholder={"Crypto Wallet Address"}
                                    layout={horizontalFormLayout}
                                    labelCol={labelCol}
                                    labelAlign="left"
                                    disabled={formMode === "view"}
                                />
                            </Col>
                        ) : null}
                    </Row>
                    <Row gutter={16}>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <InputNumberFormItem
                                name={`${prefixFormName}-minDeposit`}
                                label={"Min. Deposit"}
                                placeholder={"Min. Deposit"}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                min={0}
                                disabled={formMode === "view"}
                                onChange={()=>calculateAlert(prefixFormName, "deposit")}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <InputNumberFormItem
                                name={`${prefixFormName}-minWithdrawal`}
                                label={"Min. Withdrawal"}
                                placeholder={"Min. Withdrawal"}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                min={0}
                                disabled={formMode === "view"}
                                onChange={()=>calculateAlert(prefixFormName, "withdrawal")}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <InputNumberFormItem
                                name={`${prefixFormName}-maxDeposit`}
                                label={"Max. Deposit"}
                                placeholder={"Max. Deposit"}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                // value={maxDepositValue}
                                min={0}
                                disabled={formMode === "view"}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <InputNumberFormItem
                                name={`${prefixFormName}-maxWithdrawal`}
                                label={"Max. Withdrawal"}
                                placeholder={"Max. Withdrawal"}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                // value={maxWithdrawalValue}
                                min={0}
                                disabled={formMode === "view"}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <InputNumberSelectFormItem
                                nameInput={`${prefixFormName}-depositFee`}
                                nameSelect={`${prefixFormName}-depositFeeCondition`}
                                label={t("Deposit Fee %")}
                                placeholder={t("Deposit Fee")}
                                layout={isMobile ? verticalFormLayout : horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                selectFieldWidth={"30%"}
                                options={feeConditionOptions}
                                min={0}
                                disabled={formMode === "view"}
                                onChange={()=>calculateAlert(prefixFormName, "deposit")}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <InputNumberFormItem
                                name={`${prefixFormName}-depositFeeFixed`}
                                label={"Deposit Fee (Fixed)"}
                                placeholder={"Deposit Fee (Fixed)"}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                min={0}
                                disabled={formMode === "view"}
                                onChange={()=>calculateAlert(prefixFormName, "deposit")}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <InputNumberFormItem
                                name={`${prefixFormName}-depositFeeClient`}
                                label={"Deposit Fee Client %"}
                                placeholder={"Deposit Fee Client %"}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                min={0}
                                disabled={formMode === "view"}
                                onChange={()=>calculateAlert(prefixFormName, "deposit")}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <InputNumberFormItem
                                name={`${prefixFormName}-depositFeeFixedClient`}
                                label={"Deposit Fee (Fixed) Client"}
                                placeholder={"Deposit Fee (Fixed) Client"}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                min={0}
                                disabled={formMode === "view"}
                                onChange={()=>calculateAlert(prefixFormName, "deposit")}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <InputNumberSelectFormItem
                                nameInput={`${prefixFormName}-withdrawalFee`}
                                nameSelect={`${prefixFormName}-withdrawalFeeCondition`}
                                label={t("Withdrawal Fee %")}
                                placeholder={t("Withdrawal Fee")}
                                layout={isMobile ? verticalFormLayout : horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                selectFieldWidth={"30%"}
                                options={feeConditionOptions}
                                min={0}
                                disabled={formMode === "view"}
                                onChange={()=>calculateAlert(prefixFormName, "withdrawal")}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <InputNumberFormItem
                                name={`${prefixFormName}-withdrawalFeeFixed`}
                                label={"Withdrawal Fee (Fixed)"}
                                placeholder={"Withdrawal Fee (Fixed)"}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                min={0}
                                disabled={formMode === "view"}
                                onChange={()=>calculateAlert(prefixFormName, "withdrawal")}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <InputNumberFormItem
                                name={`${prefixFormName}-withdrawalFeeClient`}
                                label={"Withdrawal Fee Client %"}
                                placeholder={"Withdrawal Fee Client %"}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                min={0}
                                disabled={formMode === "view"}
                                onChange={()=>calculateAlert(prefixFormName, "withdrawal")}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={12}>
                            <InputNumberFormItem
                                name={`${prefixFormName}-withdrawalFeeFixedClient`}
                                label={"Withdrawal Fee (Fixed) Client"}
                                placeholder={"Withdrawal Fee (Fixed) Client"}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                min={0}
                                disabled={formMode === "view"}
                                onChange={()=>calculateAlert(prefixFormName, "withdrawal")}
                            />
                        </Col>
                    </Row>
                </Form>
            </FormProvider>
        </div>
    );
};

export default PspDetailForm;
