import {useEffect, useState} from "react";
import {getUploadUserLeadsCsv} from "../../../../../api/graphql/leads";

export const useGetUploadUserLeadsCsv = () => {
    const [data, setData] = useState<any>({data: [], total: 0});
    const [loading, setLoading] = useState<boolean>(false);
    const [param, setParam] = useState<any>({});

    const [filter, setFilter] = useState<any>({});
    const [limit,setLimit] = useState<number>(20);
    const [offset,setOffset] = useState<number>(0);
    const [order, setOrder] = useState<string>("");

    const fetchData = async () => {
        setLoading(true);
        try {
            const {getUploadUserLeadsCsv:response} = await getUploadUserLeadsCsv(filter, limit, offset, order)
            setData(response)
            setLoading(false);
        } catch (e) {

        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData()
    }, [filter, limit, offset, order]);

    return [data, loading, fetchData, setFilter, limit, setLimit, setOffset, setOrder] as const
}