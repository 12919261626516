import React, {useEffect, useState} from "react";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import {columnsCT} from "../../../function/tableColumn_CT";
import {getSpreadGroup, getSpreadGroupById, getSpreadGroupCount,} from "../../../../../../api/graphql/spreadGroup";
import {Col, Row} from "antd";
import {SpreadGroupSider} from "./spreadGroupSider";
import {useTranslation} from "react-i18next";
import {useRecoilValue} from "recoil";
import {currentServerState} from "../../../state";

function SpreadGroupTable_1(props) {
    const {server, setExportData, setExportColumn, tableName, currentServer} = props;
    const {t} = useTranslation()
    // const currentServer = useRecoilValue<any>(currentServerState)
    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(0);
    const [filter, setFilter] = useState({serverName: server?.name});
    const [orderBy, setOrderBy] = useState("");
    const [selectedSpreadGroup, setSelectedSpreadGroup] = useState({
        id: "initial",
        serverName: "initial",
    });
    // const [sideBarOpen, setSideBarOpen] = useState(false);


    const {data: totalCount} = getSpreadGroupCount({filter: filter});

    const {loading, error, data} = getSpreadGroup(
        {filter: filter},
        limit,
        offset,
        orderBy
    );
    const {data: exportData} = getSpreadGroup(
        {filter: filter},
        0,
        0,
        orderBy
    );
    const {data: initialData} = getSpreadGroupById(selectedSpreadGroup);

    const columns = server?.platform === "cTrader" ? columnsCT : [];

    useEffect(() => {
        if (currentServer) {

            setFilter({
                ...filter,
                serverName: currentServer?.name
            })
        }
    }, [currentServer]);

    useEffect(() => {
        if (columns?.length > 0 && exportData?.getSpreadGroup?.data?.length > 0) {
            setExportColumn(columns);
            setExportData(exportData?.getSpreadGroup?.data);
        }
    }, [exportData?.getSpreadGroup?.data, server?.name, currentServer?.name]);

    useEffect(() => {
        if (data?.getSpreadGroup?.data?.length > 0) {
            setSelectedSpreadGroup({
                id: data?.getSpreadGroup?.data?.[0]?.id,
                serverName: server?.name,
            });
        }
    }, [data?.getSpreadGroup?.data, currentServer])

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize);
        setOffset(limit * (page - 1));
    };

    const onRow = (record, rowIndex) => {
        return {
            onClick: () => {
                setSelectedSpreadGroup({
                    id: record?.id,
                    serverName: server?.name,
                });
            },
        };
    };

    return (
        <Row gutter={[16, 16]} className={"margin-top-0-75"}>
            <Col span={6}>
                <TableAntDesign
                    data={data?.getSpreadGroup?.data}
                    tableName={tableName}
                    columns={columns}
                    size={"small"}
                    loading={loading}
                    filter={setFilter}
                    server={server?.name}
                    order={setOrderBy}
                    onRow={onRow}
                    pagination={{
                        pageSize: limit,
                        showSizeChanger: true,
                        onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                        total: totalCount?.getSpreadGroupCount,
                        showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                    }}
                />
            </Col>
            <Col span={18}>
                {/*{sideBarOpen && initialData?.getSpreadGroupByID && (*/}
                <SpreadGroupSider
                    initialData={initialData?.getSpreadGroupByID}
                    server={server}
                />
                {/*)}*/}
            </Col>
        </Row>
    );
}

export default SpreadGroupTable_1;
