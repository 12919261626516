import React, {useEffect, useState} from "react";
import {Button, Col, Form, Modal, Row, Select, Spin} from "antd";
import {useTranslation} from "react-i18next";
import {createCommissionParameter, updateCommissionParameter} from "../../../../../../../api/graphql/commission";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import useAuthorize from "../../../../../../../_common/authorize";
import {SwapOutlined} from "@ant-design/icons";
import {getSymbol} from "../../../../../../../api/graphql/symbol";
import {getSymbolMappingCategory} from "../../../../../../../api/graphql/symbolMappingCategory";
import {getServerSetting} from "../../../../../../../api/graphql/serverSetting";
import {getSymbolsMt5} from "../../../../../../../api/graphql/symbolMt5";
import {isArray} from "@apollo/client/utilities";
import {getAssetClassMt5} from "../../../../../../../api/graphql/assetClassMt5";
import {getAssetClass} from "../../../../../../../api/graphql/assetClass";


const ParameterEditModal = (props) => {

    const {
        type,
        isOpen,
        closeModal,
        ruleData,
        data,
        getParameter
    } = props;
    const {t} = useTranslation()
    const [symbolType, setSymbolType] = useState("group");
    const [serverName, setServerName] = useState(null);
    const [serverId, setServerId] = useState(null);
    const [initialDataSymbol, setInitialDataSymbol] = useState<any[]>([])
    const [initialDataSymbolGroup, setInitialDataSymbolGroup] = useState<any>([])
    const [mt5AssetClass, setMt5AssetClass] = useState<any[]>([])
    const [mt5AssetClassLoading, setMt5AssetClassLoading] = useState(false)
    const [mt5Symbols, setMt5Symbols] = useState<any[]>([])
    const [mt5Loading, setMt5Loading] = useState(false)
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {systemErrorNotification} = useAuthorize()
    const labelCol = {span: 24, offset: 0}

    const changeSymbolType = () => {
        if (symbolType === "symbol") {
            setSymbolType("group")
        } else {
            setSymbolType("symbol")
        }
    }

    const symbolCt = getSymbol({filter: {serverName: null}});
    const getSymbolMt5 = async () => {
        try {
            setMt5Loading(true)
            const symbols = await getSymbolsMt5({})
            setMt5Symbols(symbols?.getSymbolsMt5?.data)
            setMt5Loading(false)
        } catch (e) {

        } finally {
            setMt5Loading(false)
        }
    }

    const {data: assetClassCt} = getAssetClass({filter: {serverName: ""}});

    const getAssetClassMt5Function = async () => {
        try {
            setMt5AssetClassLoading(true)

            const response = await getAssetClassMt5({server: null})

            if(response?.getAssetClassMt5?.__typename !== "BaseError"){
                setMt5AssetClass(response?.getAssetClassMt5?.data)
            }

        } catch (e) {

        } finally {
            setMt5AssetClassLoading(false)
        }
    }

    const symbolSetting = Array.isArray(symbolCt?.data?.getSymbol?.data) ? [
        ...(symbolCt?.data?.getSymbol?.data?.map(item => {
            return {
                label: t(item?.server?.name) + " - " + t(item?.name),
                value: `${item?.id}_ct`,
            };
        }) || []),
        ...(mt5Symbols?.map(item => {
            return {
                label: t(item?.server?.name) + " - " + t(item?.symbol),
                value: `${item?._id}_mt5`
            };
        }) || [])
    ] : [];

    const assetClassSetting = Array.isArray(assetClassCt?.getAssetClass?.data) ? [
        ...(assetClassCt?.getAssetClass?.data?.map(item => {
            return {
                label: t(item?.server?.name) + " - " + t(item?.name),
                value: `${item?._id}_ct`,
            };
        }) || []),
        ...(mt5AssetClass?.map(item => {
            return {
                label: t(item?.server?.name) + " - " + t(item?.name),
                value: `${item?._id}_mt5`
            };
        }) || [])
    ] : [];

    const allServerSetting = getServerSetting({
        filter: null
    });

    const serverSetting = allServerSetting?.data?.getServerSetting?.data?.map((item) => {
        return {
            label: t(item?.name),
            value: item?.id,
            name: item?.name
        }
    })

    const onFinish = async (values) => {
        try {
            let symbolMt5:string[] = []; let symbolCT:string[] = []
            let assetClassMt5:string[] = []; let assetClassCT:string[] = []

            await values?.symbol?.map(d => {
                if (d.includes("_mt5")) {
                    symbolMt5.push(d?.replace("_mt5", ""))
                } else if (d.includes("_ct")) {
                    symbolCT.push(d?.replace("_ct", ""))
                }
            })

            await values?.symbolGroup?.map(d => {
                if (d.includes("_mt5")) {
                    assetClassMt5.push(d?.replace("_mt5", ""))
                } else if (d.includes("_ct")) {
                    assetClassCT.push(d?.replace("_ct", ""))
                }
            })

            if (type === "add") {
                const response = await createCommissionParameter({
                    rule: ruleData?._id,
                    isAllSymbol: false,
                    symbolType: symbolType,
                    assetClass: symbolType === "group" ? assetClassCT : [],
                    assetClassMt5: symbolType === "group" ? assetClassMt5 : [],
                    symbol: symbolType === "symbol" ? symbolCT : [],
                    symbolMt5: symbolType === "symbol" ? symbolMt5 : []
                })

                if (response?.createCommissionParameter?.__typename === "CommissionParameter") {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: t("Added Successfully"),
                        key: "CommissionParameter",
                    });
                } else if (response?.createCommissionParameter.__typename === "BaseError") {
                    setNotificationMessage({
                        status: "error",
                        title: "",
                        msg: t(response?.createCommissionParameter?.errKey),
                        key: "CommissionParameter",
                    });
                } else {
                    setNotificationMessage({
                        status: "info",
                        title: "",
                        msg: t(response?.createCommissionParameter?.msg),
                        key: "CommissionParameter",
                    });
                }
            } else {
                const response = await updateCommissionParameter(data._id, {
                    symbolType: symbolType,
                    assetClass: symbolType === "group" ? assetClassCT : [],
                    assetClassMt5: symbolType === "group" ? assetClassMt5 : [],
                    symbol: symbolType === "symbol" ? symbolCT : [],
                    symbolMt5: symbolType === "symbol" ? symbolMt5 : [],
                    server: values?.server
                })

                if (response?.updateCommissionParameter?.__typename === "CommissionParameter") {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: t("Updated Successfully"),
                        key: "CommissionParameter",
                    });
                } else if (response?.updateCommissionParameter.__typename === "BaseError") {
                    setNotificationMessage({
                        status: "error",
                        title: "",
                        msg: t(response?.updateCommissionParameter?.errKey),
                        key: "CommissionParameter",
                    });
                } else {
                    setNotificationMessage({
                        status: "info",
                        title: "",
                        msg: t(response?.updateCommissionParameter?.msg),
                        key: "CommissionParameter",
                    });
                }

            }
        } catch (e) {
            systemErrorNotification()
        } finally {
            closeModal()
            getParameter()
        }
    }

    useEffect(() => {
        if (serverSetting?.length > 0) {
            const foundServer = serverSetting?.find((item) => item?.value === serverId)

            setServerName(foundServer?.name)
        }

    }, [serverId])

    useEffect(() => {
        if (data) {
            setServerId(data?.server?._id)
            setServerName(data?.server?.name)
            setSymbolType(data?.symbolType)

            const groups:any[] = [...data?.assetClass?.map((item) => {
                return `${item?._id}_ct`
            }),  ...data?.assetClassMt5?.map(item => {
                return `${item?._id}_mt5`
            })]

            const symbols:any[] = [...data?.symbol?.map((item) => {
                return `${item?._id}_ct`
            }),  ...data?.symbolMt5?.map(item => {
                return `${item?._id}_mt5`
            })]

            setInitialDataSymbolGroup(groups)
            setInitialDataSymbol(symbols)
        }
    }, [data])

    useEffect(() => {
        getSymbolMt5()
        getAssetClassMt5Function()
    },[])

    return (
        <Modal
            title={type !== "edit" ? "Add Parameter Settings" : "Edit Parameter Settings"}
            open={isOpen}
            onCancel={() => closeModal()}
            footer={null}
            width={800}
            destroyOnClose
        >
            <Form onFinish={onFinish}>
                <Row gutter={20}>

                    {
                        (type === "add" || data) && (
                            <>

                                {
                                    symbolType === "symbol" ? (
                                        <Col span={24}>
                                            <Spin
                                                spinning={symbolCt.loading || mt5Loading}>
                                                <Form.Item
                                                    key={1}
                                                    labelCol={labelCol}
                                                    label={<>
                                                        {t("Symbol")}
                                                        <span
                                                            style={{marginLeft: "15px"}} onClick={changeSymbolType}>
                                                                <SwapOutlined/>
                                                        </span>
                                                    </>}
                                                    name={"symbol"}
                                                    initialValue={initialDataSymbol}
                                                    rules={[{required: true}]}
                                                >
                                                    {
                                                        (symbolType === "symbol" && !symbolCt.loading && !mt5Loading) ? (
                                                            <Select mode="multiple"
                                                                    options={symbolSetting}
                                                                    showSearch optionFilterProp={"label"}/>
                                                        ) : null
                                                    }
                                                </Form.Item>
                                            </Spin>
                                        </Col>
                                    ) : (
                                        <Col span={24}>
                                            <Spin
                                                spinning={mt5AssetClassLoading}>
                                                <Form.Item
                                                    key={2}
                                                    labelCol={labelCol}
                                                    label={<>
                                                        {t("Security Group")}
                                                        <span
                                                            style={{marginLeft: "15px"}} onClick={changeSymbolType}>
                                                                <SwapOutlined/>
                                                        </span>
                                                    </>}
                                                    name={"symbolGroup"}
                                                    initialValue={initialDataSymbolGroup}
                                                    rules={[{required: true}]}
                                                >
                                                    {
                                                        (symbolType === "group" && !mt5AssetClassLoading) ? (
                                                            <Select mode="multiple"
                                                                    options={assetClassSetting}
                                                                    showSearch optionFilterProp={"label"}/>
                                                        ) : null
                                                    }
                                                </Form.Item>
                                            </Spin>
                                        </Col>
                                    )
                                }


                                <Col span={24} style={{textAlign: "center", marginTop: "15px"}}>
                                    <Button type={"primary"} htmlType={"submit"} style={{width: "100%"}}>
                                        {type === "add" ? "Add" : "Save"}
                                    </Button>
                                </Col>
                            </>
                        )
                    }


                </Row>
            </Form>
        </Modal>

    )

}

export default ParameterEditModal