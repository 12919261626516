import {gql, useMutation, useQuery} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";

const GET_REPORTING_CATEGORY = gql`
    query getReportingCategory($filter: ReportingCategoryFilter, $limit: Int, $offset: Int, $orderBy: String) {
        getReportingCategory(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on ReportingCategoryArray {
                data {
                    _id
                    name
                    label
                    isDeleted
                    sortingNumber
                    isDefault
                    isEnabled
                    isSystem
                    reports {
                        reportId
                        isSystem
                        isDefault
                    }
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getReportingCategory = async ({filter}, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_REPORTING_CATEGORY,
            {
                filter: filter,
                limit: limit ?? null,
                offset: offset ?? null,
                orderBy: orderBy ?? null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_REPORTING_CATEGORY_DROPDOWN = gql`
    query getReportingCategoryDropdown {
        getReportingCategoryDropdown {
            ... on ReportingCategoryArray {
                data {
                    _id
                    name
                    label
                    isDeleted
                    isEnabled
                    sortingNumber
                    reports {
                        reportId
                    }
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getReportingCategoryDropdown = async () => {
    try {
        const execute = await ApolloClientQuery(
            GET_REPORTING_CATEGORY_DROPDOWN,
            {
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UPDATE_REPORTING_CATEGORY = gql`
    mutation updateReportingCategory($input: ReportingCategoryUpdateInput) {
        updateReportingCategory(input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on ReportingCategory {
                _id
                name
                label
                isDeleted
                sortingNumber
                isDefault
                isEnabled
                isSystem
                reports {
                    reportId
                    isSystem
                    isDefault
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useUpdateReportingCategory = (): {
    updateReportingCategory: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const [updateReportingCategoryMutation, {data, loading, error}] = useMutation(UPDATE_REPORTING_CATEGORY);

        const updateReportingCategory = (input) => {
            return updateReportingCategoryMutation({
                variables: {
                    input: input,
                },
            });
        };

        return {updateReportingCategory, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            updateReportingCategory: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const DELETE_REPORTING_CATEGORY = gql`
    mutation deleteReportingCategory($id: String!) {
        deleteReportingCategory(id: $id) {
            ... on CrmTaskApproval {
                msg
            }
            ... on ReportingCategory {
                _id
                isDeleted
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useDeleteReportingCategory = (): {
    deleteReportingCategory: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const [deleteReportingCategoryMutation, {data, loading, error}] = useMutation(DELETE_REPORTING_CATEGORY);

        const deleteReportingCategory = (id) => {
            return deleteReportingCategoryMutation({
                variables: {
                    id: id,
                },
            });
        };

        return {deleteReportingCategory, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            deleteReportingCategory: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const SORT_SYMBOL_REPORTING_CATEGORY = gql`
    mutation sortReportingCategory($ids: [String]!) {
        sortReportingCategory(ids: $ids) {
            ... on CrmTaskApproval {
                msg
            }
            ... on ReportingCategoryArray {
                data {
                    _id
                    name
                    sortingNumber
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useSortReportingCategory = (): {
    sortReportingCategory: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        // const setErrorMessage = useSetRecoilState(errorMessage);
        const [sortReportingCategoryMutation, {data, loading, error}] = useMutation(SORT_SYMBOL_REPORTING_CATEGORY);

        const sortReportingCategory = (ids) => {
            return sortReportingCategoryMutation({
                variables: {
                    ids: ids,
                },
            });
        };

        return {sortReportingCategory, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            sortReportingCategory: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const CREATE_REPORTING_CATEGORY = gql`
    mutation createReportingCategory($input: ReportingCategoryInput) {
        createReportingCategory(input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on ReportingCategory {
                _id
                name
                label
                isDeleted
                sortingNumber
                isDefault
                isEnabled
                isSystem
                reports {
                    reportId
                    isSystem
                    isDefault
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useCreateReportingCategoryMutation = (): {
    createReportingCategory: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const [createReportingCategoryMutation, {data, loading, error}] = useMutation(CREATE_REPORTING_CATEGORY);

        const createReportingCategory = (input) => {
            return createReportingCategoryMutation({
                variables: {
                    input: input,
                },
            });
        };

        return {createReportingCategory, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            createReportingCategory: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

export {getReportingCategory, getReportingCategoryDropdown, useUpdateReportingCategory, useDeleteReportingCategory, useSortReportingCategory, useCreateReportingCategoryMutation};
