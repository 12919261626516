import React, {useEffect, useState} from "react";
import {Button, Modal, Space} from "antd";
import {useTranslation} from "react-i18next";
import {getMamPammLegalDocument} from "../../../../../../../../../api/graphql/mamPammLegalDocument";
import {PlusOutlined} from "@ant-design/icons";
import LegalDocumentModal from "../../../../../../../../accounts/mamPamm/view/layout1/component/legalDocumentModal";

const NewMoneyManagerLegalDocumentsModal = ({isOpen, handleCancel, moneyManager, allAccount}) => {
    const {t} = useTranslation()

    const [selectedMoneyManagerAccount, setSelectedMoneyManagerAccount] = useState<any>({})
    const [legalDocuments, setLegalDocuments] = useState<any>([])

    const [legalDocumentIsOpen, setLegalDocumentIsOpen] = useState(false)
    const [selectedDoc, setSelectedDoc] = useState<any>({})
    const [mode, setMode] = useState(null)

    const fetchLegalDocumentsFunction = async () => {
        const documents = await getMamPammLegalDocument({filter: {moneyManagerAccount: moneyManager}})
        setLegalDocuments(documents?.getMamPammLegalDocument?.data)
    }

    const handleOpenLegalDocumentModal = (mode, doc?) => {
        setLegalDocumentIsOpen(true)
        setMode(mode)
        setSelectedDoc(doc)
    }

    useEffect(() => {
        if (moneyManager) {
            setSelectedMoneyManagerAccount(allAccount?.find(d => d?._id === moneyManager))
            fetchLegalDocumentsFunction()
        }
    }, [moneyManager, allAccount]);

    return (
        <>
            <Modal
                title={`${t("mampamm.legal_documents")} - ${selectedMoneyManagerAccount?.accountId}`}
                open={isOpen}
                onCancel={handleCancel}
                footer={null}
                maskClosable={false}
            >
                <Space wrap>
                    {
                        legalDocuments?.map(doc => {
                            const displayName = doc?.displayName?.find(d => d?.language === localStorage.getItem("lang"))?.content

                            return <Button onClick={() => handleOpenLegalDocumentModal("edit", doc)}>{displayName}</Button>
                        })
                    }

                    <Button onClick={() => handleOpenLegalDocumentModal("add")} icon={<PlusOutlined/>} type={"primary"}>{t("common.add")}</Button>
                </Space>
            </Modal>
            <LegalDocumentModal isOpen={legalDocumentIsOpen} handleCancel={() => setLegalDocumentIsOpen(false)} mode={mode} selectedDocument={selectedDoc} moneyManagerAccountId={moneyManager} fetchLegalDocumentsFunction={fetchLegalDocumentsFunction}/>
        </>
    )
}

export default NewMoneyManagerLegalDocumentsModal