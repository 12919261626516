import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";


const GET_COMMISSION_RULE = gql`
    query getCommissionRule($filter: CommissionRuleFilter, $limit: Int, $offset: Int, $orderBy: String, $startDate: String, $endDate: String, $dateKey: String){
        getCommissionRule(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy, startDate: $startDate, endDate: $endDate, dateKey: $dateKey){
            ... on CommissionRuleArray {
                data {
                    _id
                    name
                    ruleType
                    accountType
                    productType
                    product{
                        _id
                        name
                    }
                    commMode
                    commPayout
                    calcFrequency
                    unpaidComm
                    calculationNetworkType
                    payoutSomeUsers{
                        _id
                        username
                    }
                    partiallyHidden{
                        _id
                        username
                    }
                    payoutCommGroup{
                        _id
                        name
                    }
                    payoutNetworkType
                    commType
                    commRankType
                    commRank{
                        _id
                        name
                        rank
                    }
                    commissionSetting{
                        _id
                        name
                    }
                    commBase
                    commLevel
                    withdrawal
                    deductProfit
                    isDeleted
                    accountEnvMode
                    accountProductType
                    isEnabled
                    createdBy{
                        username
                    }
                    updatedBy{
                        username
                    }
                    createdAt
                    dealEntry
                    dealAction
                    dealProfit
                    dealReason
                    bonusCoEfficientIncluded
                    tradingAccountCount
                    clientUserCount
                    crmUserCount
                    reportFilterField{
                        fieldName
                        fieldType
                        operator
                        value
                        typeId
                    }
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;
const getCommissionRule = async (filter, limit?, offset?, orderBy?, startDate?, endDate?, dateKey?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_COMMISSION_RULE,
            {
                filter: filter,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const GET_COMMISSION_RULE_BY_Id = gql`
    query getCommissionRuleById($id: String!){
        getCommissionRuleById(id: $id){
            ... on CommissionRule {
                _id
                name
                ruleType
                accountType
                productType
                product{
                    _id
                    name
                }
                commMode
                commPayout
                calcFrequency
                unpaidComm
                calculationNetworkType
                payoutSomeUsers{
                    _id
                    username
                }
                partiallyHidden{
                    _id
                    username
                }
                payoutCommGroup{
                    _id
                    name
                }
                payoutNetworkType
                commType
                commRankType
                commRank{
                    _id
                    name
                    rank
                }
                commissionSetting{
                    _id
                    name
                }
                commBase
                commLevel
                withdrawal
                deductProfit
                isDeleted
                accountEnvMode
                accountProductType
                isEnabled
                createdBy{
                    username
                }
                updatedBy{
                    username
                }
                createdAt
                dealEntry
                dealAction
                dealProfit
                dealReason
                bonusCoEfficientIncluded
                reportFilterField{
                    fieldName
                    fieldType
                    operator
                    value
                    typeId
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getCommissionRuleById = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            GET_COMMISSION_RULE_BY_Id,
            {
                id: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const ADD_COMMISSION_RULE = gql`
    mutation createCommissionRule($input: CommissionRuleInput!) {
        createCommissionRule(input: $input){
            ... on CommissionRule {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`
const addCommissionRule = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            ADD_COMMISSION_RULE,
            {
                input: input
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const UPDATE_COMMISSION_RULE = gql`
    mutation updateCommissionRule($id: String!,$input: CommissionRuleInput!) {
        updateCommissionRule(id: $id,input: $input){
            ... on CommissionRule {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`
const updateCommissionRule = async (id, input) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_COMMISSION_RULE,
            {
                id: id,
                input: input
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const DELETE_COMMISSION_RULE = gql`
    mutation deleteCommissionRule($id: String!) {
        deleteCommissionRule(id: $id){
            ... on CommissionRule {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`
const deleteCommissionRule = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            DELETE_COMMISSION_RULE,
            {
                id: id,
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const DUPLICATE_COMMISSION_RULE = gql`
    mutation duplicateCommissionRule($id: String!) {
        duplicateCommissionRule(id: $id){
            ... on CommissionRule {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`
const duplicateCommissionRule = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            DUPLICATE_COMMISSION_RULE,
            {
                id: id,
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const GET_COMMISSION_GROUP = gql`
    query getCommissionGroup($filter: CommissionGroupSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
        getCommissionGroup(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy){
            ... on CommissionGroupArray {
                data {
                    id
                    _id
                    name
                    type
                    commissionSetting{
                        _id
                        name
                    }
                    network {
                        _id
                        username
                    }
                    createdBy {username}
                    updatedBy {username}
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;
const getCommissionGroup = async (filter, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_COMMISSION_GROUP,
            {
                filter: filter,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const ADD_COMMISSION_GROUP = gql`
    mutation addCommissionGroup($input: CommissionGroupInput) {
        addCommissionGroup(input: $input){
            ... on CommissionGroup {
                id
                name
                type
                network {
                    username
                }
                createdBy {username}
                updatedBy {username}
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`
const addCommissionGroup = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            ADD_COMMISSION_GROUP,
            {
                input: input
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const UPDATE_COMMISSION_GROUP = gql`
    mutation updateCommissionGroup($id: String!, $input: CommissionGroupInput) {
        updateCommissionGroup(id: $id, input: $input){
            ... on CommissionGroup {
                id
                name
                type
                network {username}
                createdBy {username}
                updatedBy {username}
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`
const updateCommissionGroup = async (id, input) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_COMMISSION_GROUP,
            {
                id: id,
                input: input
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const DELETE_COMMISSION_GROUP = gql`
    mutation deleteCommissionGroup($id: String!){
        deleteCommissionGroup(id: $id){
            ... on CommissionGroup {
                id
                isDeleted
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`
const deleteCommissionGroup = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            DELETE_COMMISSION_GROUP,
            {
                id: id,
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const GET_COMMISSION_PARAMETER = gql`
    query getCommissionParameter($filter: CommissionParameterFilter, $limit: Int, $offset: Int, $orderBy: String, $startDate: String, $endDate: String, $dateKey: String){
        getCommissionParameter(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy, startDate: $startDate, endDate: $endDate, dateKey: $dateKey){
            ... on CommissionParameterArray {
                data {
                    _id
                    rule{
                        _id
                        name
                    }
                    symbol{
                        _id
                        name
                        server{
                            name
                        }
                    }
                    symbolMt5{
                        _id
                        symbol
                        server{
                            name
                        }
                    }
                    assetClass {
                        _id
                        name
                        server{
                            name
                        }
                    }
                    assetClassMt5 {
                        _id
                        name
                        server{
                            name
                        }
                    }
                    symbolGroup{
                        _id
                        category{
                            categoryName
                        }
                        server{
                            name
                        }
                    }
                    symbolType
                    overriding{
                        user{
                            _id
                            username
                        }
                        amount
                    }
                    excess{
                        user{
                            _id
                            username
                        }
                        percentage
                    }
                    cashbackClient
                    isAllSymbol
                    isEnabled
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;
const getCommissionParameter = async (filter, limit?, offset?, orderBy?, startDate?, endDate?, dateKey?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_COMMISSION_PARAMETER,
            {
                filter: filter,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const UPDATE_COMMISSION_PARAMETER = gql`
    mutation updateCommissionParameter($id: String!,$input: CommissionParameterUpdateInput!) {
        updateCommissionParameter(id: $id,input: $input){
            ... on CommissionParameter {
                _id
                rule{
                    _id
                    name
                }
                symbol{
                    _id
                    name
                }
                overriding{
                    user{
                        _id
                        username
                    }
                    amount
                }
                excess{
                    user{
                        _id
                        username
                    }
                    percentage
                }
                cashbackClient
                isAllSymbol
                isEnabled
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`
const updateCommissionParameter = async (id, input) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_COMMISSION_PARAMETER,
            {
                id: id,
                input: input
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const CREATE_COMMISSION_PARAMETER = gql`
    mutation createCommissionParameter($input: CommissionParameterInput!) {
        createCommissionParameter(input: $input){
            ... on CommissionParameter {
                _id
                rule{
                    _id
                    name
                }
                symbol{
                    _id
                    name
                }
                overriding{
                    user{
                        _id
                        username
                    }
                    amount
                }
                excess{
                    user{
                        _id
                        username
                    }
                    percentage
                }
                cashbackClient
                isAllSymbol
                isEnabled
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`
const createCommissionParameter = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            CREATE_COMMISSION_PARAMETER,
            {
                input: input
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const UPDATE_COMMISSION_PARAMETER_SETTING = gql`
    mutation updateCommissionParameterSetting($input: CommissionParameterSettingInput!) {
        updateCommissionParameterSetting(input: $input){
            ... on CommissionParameterSetting {
                _id
                rule{
                    _id
                    name
                }
                rank{
                    _id
                    name
                }
                total
                criteria{
                    name
                    criteriaGroup{
                        logicalOperator
                        criteria{
                            logicalOperator
                            type
                            comparisonOperator
                            value
                        }

                    }
                    rankAmount{
                        rank{
                            _id
                            name
                        }
                        value
                    }
                }

            }
            ... on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`
const updateCommissionParameterSetting = async (input) => {

    try {
        const execute = await ApolloClientQuery(
            UPDATE_COMMISSION_PARAMETER_SETTING,
            {
                input: input
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const DELETE_COMMISSION_PARAMETER_SETTING = gql`
    mutation deleteCommissionParameter($id: String!) {
        deleteCommissionParameter(id: $id){
            ... on CommissionParameter {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`
const deleteCommissionParameter = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            DELETE_COMMISSION_PARAMETER_SETTING,
            {
                id: id
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const GET_COMMISSION_PARAMETER_SETTING = gql`
    query getCommissionParameterSetting($filter: CommissionParameterSettingFilter!) {
        getCommissionParameterSetting(filter: $filter){
            ... on CommissionParameterSettingArray {
                data{
                    _id
                    rule{
                        _id
                        name
                    }
                    rank{
                        _id
                        name
                    }
                    level
                    commType
                    total
                    criteria{
                        name
                        position
                        criteriaGroup{
                            logicalOperator
                            position
                            criteria{
                                type
                                position
                                comparisonOperator
                                value
                                logicalOperator
                            }

                        }
                        rankAmount{
                            rank{
                                _id
                                name
                            }
                            value
                        }
                        levelAmount{
                            level
                            value
                        }
                        total
                    }

                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`
const getCommissionParameterSetting = async (filter) => {
    try {
        const execute = await ApolloClientQuery(
            GET_COMMISSION_PARAMETER_SETTING,
            {
                filter: filter,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const COMMISSION_TAGGING_USER = gql`
    mutation commissionTaggingUser($commissionSetting:String!){
        commissionTaggingUser(commissionSetting:$commissionSetting)
    }
`
const commissionTaggingUser = async (commissionSetting) => {
    try {
        const execute = await ApolloClientQuery(
            COMMISSION_TAGGING_USER,
            {
                commissionSetting: commissionSetting
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const GET_COMMISSION_LOG_BATCH = gql`
    query getCommissionLogBatch($filter: CommissionLogBatchInput, $limit: Int, $offset: Int, $orderBy: String, $startDate: String, $endDate: String, $dateKey: String){
        getCommissionLogBatch(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy, startDate: $startDate, endDate: $endDate, dateKey: $dateKey){
            ... on CommissionLogBatchArray {
                data {
                    _id
                    transactionNo
                    transactionName
                    batchName
                    rule{
                        _id
                        name
                    }
                    totalPayout
                    calculatedStartDate
                    calculatedEndDate
                    networkTreeHistoryDate
                    isFinished
                    status
                    createdAt
                    lastCalculation
                    requestedPaymentBy{
                        _id
                        username
                        userType
                    }
                    approvedPaymentBy{
                        _id
                        username
                        userType
                    }
                    rejectedPaymentBy{
                        _id
                        username
                        userType
                    }
                    reversePaymentBy{
                        _id
                        username
                        userType
                    }
                    requestedAt
                    approvedAt
                    rejectedAt
                    reversedAt
                    isProcessing
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;
const getCommissionLogBatch = async (filter, limit?, offset?, orderBy?, startDate?, endDate?, dateKey?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_COMMISSION_LOG_BATCH,
            {
                filter: filter,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const GET_COMMISSION_LOG = gql`
    query getCommissionLog($filter: CommissionLogFilter, $limit: Int, $offset: Int, $orderBy: String, $startDate: String, $endDate: String, $dateKey: String){
        getCommissionLog(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy, startDate: $startDate, endDate: $endDate, dateKey: $dateKey){
            ... on CommissionLogArray {
                data {
                    _id
                    batch{
                        transactionNo
                        transactionName
                        batchName
                    }
                    transactionNo
                    transactionName
                    calculatedTradingAccount{
                        _id
                        traderId
                        accountId
                    }
                    reportSnapshotIndex
                    reportSnapshot
                    commSettingSnapshot
                    commRuleSnapshot
                    commParameterSnapshot
                    commParameterSettingSnapshot
                    commParameterSettingCriteriaSnapshot
                    commMode
                    commType
                    calculatedUser{
                        _id
                        username
                        userType
                    }
                    calculatedSymbol{
                        name
                    }
                    calculatedServer
                    calculatedAmount
                    type
                    referrerUser{
                        _id
                        username
                        userType
                    }
                    assignedUser{
                        _id
                        username
                        userType
                    }
                    assignedUserRank{
                        _id
                        name
                    }
                    assignedUserLevel
                    assignedAmount
                    assignedCompressedAmount
                    includedCoefficient
                    calculatedCoefficient
                    totalAssignedAmount
                    totalAssignedAmountRound
                    createdAt
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;
const getCommissionLog = async (filter, limit?, offset?, orderBy?, startDate?, endDate?, dateKey?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_COMMISSION_LOG,
            {
                filter: filter,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const GET_COMMISSION_LOG_PAYMENT = gql`
    query getCommissionLogPayment($filter: CommissionLogPaymentFilter, $limit: Int, $offset: Int, $orderBy: String, $startDate: String, $endDate: String, $dateKey: String){
        getCommissionLogPayment(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy, startDate: $startDate, endDate: $endDate, dateKey: $dateKey){
            ... on CommissionLogPaymentArray {
                total
                data {
                    _id
                    batch{
                        transactionName
                        batchName
                    }
                    rule{
                        name
                    }
                    transactionNo
                    transactionName
                    value
                    payoutAmount
                    payoutAmountRound
                    status
                    createdAt
                    isPaid
                    user {
                        _id
                        userType
                        username
                        rank{
                            name
                        }
                    }
                }
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;
const getCommissionLogPayment = async (filter, limit?, offset?, orderBy?, startDate?, endDate?, dateKey?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_COMMISSION_LOG_PAYMENT,
            {
                filter: filter,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const COMMISSION_RERUN_BATCH = gql`
    mutation commissionRerunBatch($id: String!,$logBatchId:String,$networkDate:String,$isLocalCalculation:Boolean){
        commissionRerunBatch(id: $id,logBatchId:$logBatchId,networkDate:$networkDate,isLocalCalculation:$isLocalCalculation){
            ... on CommissionLogBatch {
                _id
                transactionNo
                rule{
                    _id
                    name
                }
                totalPayout
                calculatedStartDate
                calculatedEndDate
                isFinished
                createdAt
                lastCalculation
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;
const commissionRerunBatch = async (id, logBatchId, date, isLocalCalculation) => {
    try {
        const execute = await ApolloClientQuery(
            COMMISSION_RERUN_BATCH,
            {
                id: id,
                logBatchId: logBatchId,
                networkDate: date,
                isLocalCalculation: isLocalCalculation,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const COMMISSION_FORCE_REFRESH_BATCH = gql`
    mutation commissionForceRefreshBatch($id: String!,$logBatchId:String){
        commissionForceRefreshBatch(id: $id,logBatchId:$logBatchId){
            ... on CommissionLogBatch {
                _id
                transactionNo
                rule{
                    _id
                    name
                }
                totalPayout
                calculatedStartDate
                calculatedEndDate
                isFinished
                createdAt
                lastCalculation
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;
const commissionForceRefreshBatch = async (id, logBatchId) => {
    try {
        const execute = await ApolloClientQuery(
            COMMISSION_FORCE_REFRESH_BATCH,
            {
                id: id,
                logBatchId: logBatchId,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const COMMISSION_CREATE_BATCH = gql`
    mutation commissionCreateBatch($id: String!,$startDate:String!,$endDate:String!,$networkDate:String){
        commissionCreateBatch(id: $id,startDate:$startDate,endDate:$endDate,networkDate:$networkDate){
            ... on CommissionLogBatch {
                _id
                transactionNo
                rule{
                    _id
                    name
                }
                totalPayout
                calculatedStartDate
                calculatedEndDate
                isFinished
                createdAt
                lastCalculation
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;
const commissionCreateBatch = async (id, startDate, endDate, date) => {
    try {
        const execute = await ApolloClientQuery(
            COMMISSION_CREATE_BATCH,
            {
                id: id,
                startDate: startDate,
                endDate: endDate,
                networkDate: date,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const COMMISSION_DELETE_BATCH = gql`
    mutation commissionDeleteBatch($batchId: String!) {
        commissionDeleteBatch(batchId: $batchId){
            ... on CommissionLogBatch {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`
const commissionDeleteBatch = async (batchId) => {
    try {
        const execute = await ApolloClientQuery(
            COMMISSION_DELETE_BATCH,
            {
                batchId: batchId,
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const GET_DEAL_REASON = gql`
    query getDealReason{
        getDealReason{
            ... on CommissionJsonResponse {
                data
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`
const getDealReason = async () => {
    try {
        const execute = await ApolloClientQuery(
            GET_DEAL_REASON,
            {
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const GET_COMMISSION_RULE_BY_NETWORK = gql`
    query getCommissionRuleByNetwork($account: String) {
        getCommissionRuleByNetwork(account: $account){
            ... on CommissionRuleByNetwork {
                rules{
                    _id
                    ruleType
                    commissionSetting{
                        _id
                        name
                    }
                    name
                }
                group{
                    _id
                    type
                    commissionSetting{
                        _id
                        name
                    }
                    name
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`
const getCommissionRuleByNetwork = async (account) => {
    try {
        const execute = await ApolloClientQuery(
            GET_COMMISSION_RULE_BY_NETWORK,
            {
                account: account,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const GET_COMMISSION_RULE_BY_USER_NETWORK = gql`
    query getCommissionRuleByUserNetwork($id: String) {
        getCommissionRuleByUserNetwork(id: $id){
            ... on CommissionRuleByNetwork {
                rules{
                    _id
                    ruleType
                    commissionSetting{
                        _id
                        name
                    }
                    name
                }
                group{
                    _id
                    type
                    commissionSetting{
                        _id
                        name
                    }
                    name
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`
const getCommissionRuleByUserNetwork = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            GET_COMMISSION_RULE_BY_USER_NETWORK,
            {
                id: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const GET_COMMISSION_CONDITION = gql`
    query getCommissionCondition{
        getCommissionCondition{
            ... on CommissionConditionArray {
                data {
                    key
                    reportId
                    reportName
                    fieldName
                    dataType
                }
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;
const getCommissionCondition = async () => {
    try {
        const execute = await ApolloClientQuery(
            GET_COMMISSION_CONDITION,
            {
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const SUBMIT_COMMISSION_PAYMENT = gql`
    mutation submitCommissionPayment($batchId: String!) {
        submitCommissionPayment(batchId: $batchId){
            ... on CommissionLogBatch {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`
const submitCommissionPayment = async (batchId) => {
    try {
        const execute = await ApolloClientQuery(
            SUBMIT_COMMISSION_PAYMENT,
            {
                batchId: batchId,
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const REVERSE_COMMISSION_PAYMENT = gql`
    mutation reverseCommissionPayment($batchId: String!) {
        reverseCommissionPayment(batchId: $batchId){
            ... on CommissionLogBatch {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`
const reverseCommissionPayment = async (batchId) => {
    try {
        const execute = await ApolloClientQuery(
            REVERSE_COMMISSION_PAYMENT,
            {
                batchId: batchId,
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const GET_COMMISSION_SETTING = gql`
    query getCommissionSetting($filter: CommissionSettingFilter, $limit: Int, $offset: Int, $orderBy: String, $startDate: String, $endDate: String, $dateKey: String){
        getCommissionSetting(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy, startDate: $startDate, endDate: $endDate, dateKey: $dateKey){
            ... on CommissionSettingArray {
                data {
                    _id
                    name
                    isEnabled
                    label
                    createdBy{
                        username
                    }
                    reportId
                    reportName
                    reportPayoutId
                    reportPayoutName
                    reportCalculationField
                    commissionTagging
                    reportFilterField{
                        fieldName
                        fieldType
                        operator
                        value
                        typeId
                    }
                    updatedBy{
                        username
                    }
                    position
                    isSystem
                    createdAt
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;
const getCommissionSetting = async (filter, limit?, offset?, orderBy?, startDate?, endDate?, dateKey?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_COMMISSION_SETTING,
            {
                filter: filter,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const GET_COMMISSION_SETTING_DROPDOWN = gql`
    query getCommissionSettingDropdown {
        getCommissionSettingDropdown {
            ... on CommissionSettingDropdownArray {
                data {
                    _id
                    label
                    name
                    reportId
                }
            }
        }
    }
`

const getCommissionSettingDropdown = async () => {
    try {
        const execute = await ApolloClientQuery(
            GET_COMMISSION_SETTING_DROPDOWN,
            {
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
}

const CREATE_COMMISSION_SETTING = gql`
    mutation createCommissionSetting($input: CommissionSettingInput!) {
        createCommissionSetting(input: $input){
            ... on CommissionSetting {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`
const createCommissionSetting = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            CREATE_COMMISSION_SETTING,
            {
                input: input
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const UPDATE_COMMISSION_SETTING = gql`
    mutation updateCommissionSetting($id:String!,$input: CommissionSettingInput!) {
        updateCommissionSetting(id:$id,input: $input){
            ... on CommissionSetting {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`
const updateCommissionSetting = async (id, input) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_COMMISSION_SETTING,
            {
                id: id,
                input: input
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const UPDATE_COMMISSION_SETTING_SYSTEM = gql`
    mutation updateCommissionSettingSystem($id:String!,$input: CommissionSettingSystemInput) {
        updateCommissionSettingSystem(id:$id,input: $input){
            ... on CommissionSetting {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`
const updateCommissionSettingSystem = async (id, input) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_COMMISSION_SETTING_SYSTEM,
            {
                id: id,
                input: input
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const DELETE_COMMISSION_SETTING = gql`
    mutation deleteCommissionSetting($id: String!) {
        deleteCommissionSetting(id: $id){
            ... on CommissionSetting {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`
const deleteCommissionSetting = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            DELETE_COMMISSION_SETTING,
            {
                id: id,
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};

const SORT_COMMISSION_SETTING = gql`
    mutation sortCommissionSetting($ids: [String]) {
        sortCommissionSetting(ids: $ids){
            ... on CommissionSettingArray {
                data {
                    _id
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ...on CrmTaskApproval {
                msg
            }
        }
    }
`
const sortCommissionSetting = async (ids) => {
    try {
        const execute = await ApolloClientQuery(
            SORT_COMMISSION_SETTING,
            {
                ids: ids,
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};

    }
};


export {
    sortCommissionSetting,
    getCommissionRule,
    getCommissionGroup,
    addCommissionGroup,
    updateCommissionGroup,
    deleteCommissionGroup,
    addCommissionRule,
    updateCommissionRule,
    deleteCommissionRule,
    getCommissionParameter,
    updateCommissionParameter,
    updateCommissionParameterSetting,
    getCommissionParameterSetting,
    createCommissionParameter,
    deleteCommissionParameter,
    commissionTaggingUser,
    getDealReason,
    getCommissionRuleByNetwork,
    getCommissionRuleByUserNetwork,
    getCommissionCondition,
    getCommissionLogBatch,
    commissionRerunBatch,
    getCommissionLog,
    getCommissionLogPayment,
    submitCommissionPayment,
    getCommissionSetting,
    getCommissionSettingDropdown,
    createCommissionSetting,
    updateCommissionSetting,
    updateCommissionSettingSystem,
    deleteCommissionSetting,
    commissionCreateBatch,
    commissionDeleteBatch,
    reverseCommissionPayment,
    getCommissionRuleById,
    duplicateCommissionRule,
    commissionForceRefreshBatch
}