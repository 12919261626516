import {Form, Input, InputNumber} from "antd";
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import FormErrors from "./formErrors";

const InputNumberFormItem = (props) => {
    const {
        layout,
        labelCol,
        wrapperCol,
        label,
        labelAlign,
        name,
        placeholder,
        prefix,
        value,
        externalErrorMessage,
        required,
        onChange,
        disabled,
        max,
        min,
        addonBefore,
        addonAfter,
        extra,
        decimalSeparator,
        formatter,
        parser,
        precision,
        step,
        colon,
        style
    } = props;

    const {
        register,
        watch,
        setValue,
        formState: {touchedFields, isSubmitted, errors},
    } = useFormContext();

    const errorMessage = FormErrors.errorMessage(
        name,
        label,
        errors,
        touchedFields,
        isSubmitted,
        externalErrorMessage
    );

    useEffect(() => {
        register(name);
    }, [register, name]);

    useEffect(() => {
        if (value) {
            setValue(name, value, {shouldValidate: true});
        }
    }, [value]);

    return (
        <Form.Item
            {...layout}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            label={label}
            labelAlign={labelAlign}
            required={required}
            colon={colon}
            validateStatus={errorMessage ? "error" : "success"}
            help={errorMessage}
            extra={extra}
        >
            <InputNumber
                id={name}
                name={name}
                max={max}
                min={min}
                value={watch(name)}
                onChange={(value) => {
                    setValue(name, value, {shouldValidate: true});
                    onChange && onChange(value);
                }}
                placeholder={placeholder}
                prefix={prefix}
                disabled={disabled}
                addonAfter={addonAfter}
                addonBefore={addonBefore}
                decimalSeparator={decimalSeparator}
                formatter={formatter}
                parser={parser}
                precision={precision}
                step={step}
                style={style}
            />
        </Form.Item>
    );
};

InputNumberFormItem.defaultProps = {
    required: false,
    disabled: false,
    controls: true,
    colon: false,
    labelCol: {span: 24, offset: 0},
};

InputNumberFormItem.propTypes = {
    label: PropTypes.string,
    labelAlign: PropTypes.string,
    wrapperCol: PropTypes.object,
    style: PropTypes.object,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    prefix: PropTypes.object,
    addonBefore: PropTypes.object,
    addonAfter: PropTypes.object,
    value: PropTypes.string,
    type: PropTypes.string,
    externalErrorMessage: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    layout: PropTypes.object,
    max: PropTypes.number,
    min: PropTypes.number,
    extra: PropTypes.string,
    controls: PropTypes.bool || PropTypes.func,
    decimalSeparator: PropTypes.string,
    formatter: PropTypes.func,
    parser: PropTypes.func,
    precision: PropTypes.number,
    readOnly: PropTypes.bool,
    step: PropTypes.number || PropTypes.string,
    stringMode: PropTypes.bool,
    onStep: PropTypes.func,
    colon: PropTypes.bool,
};

export default InputNumberFormItem;
