import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import {Button, Col, Divider, Form, message, Row, Tabs} from "antd";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import _ from "lodash";

import yupFormSchema from "../../../../../../components/yup/yupFormSchema";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import SelectFormItem from "../../../../../../components/form/selectFormItem";
import {verticalFormLayout} from "../../../../../../components/form/formWrapper";
import {useAddDynamicVariableMutation, useUpdateDynamicVariableMutation} from "../../../../../../api/graphql/dynamicVariable";
import RadioGroupFormItem from "../../../../../../components/form/radioGroupFormItem";
import PDFUploadFormItem from "../../../../../../components/form/pdfUploadFormItem";
import InputFormItem2 from "../../../../../../components/form/inputFormItem2";

import {getListFromObject, getTextFromObject, handleObjectChange} from "../../../function/common";
import useAuthorize from "../../../../../../_common/authorize";
import ReportTableForm from "./reportTableForm";

const yupSchema = yup.object().shape({
  name: yupFormSchema.string("name", {required: true}),
  variableType: yupFormSchema.string("variableType", {required: true}),
  isActive: yupFormSchema.boolean("isActive"),
});

const VariableForm = (props) => {
  const {mode, item, closeModal, languageData} = props;
  const {t} = useTranslation();
  const [errMsg, setErrMsg] = useState("");

  const [textObjectValue, setTextObjectValue] = useState(item?.textObject || []);
  const [fileObjectValue, setFileObjectValue] = useState(item?.fileObject || []);

  const [initialValues] = useState(() => ({
    name: item?.name || "",
    variableType: item?.variableType || "",
    isActive: item?.isActive ?? true,
  }));

  var form = useForm({
    resolver: yupResolver(yupSchema),
    mode: "all",
    defaultValues: initialValues,
  });

  const {addDynamicVariable} = useAddDynamicVariableMutation();
  const {updateDynamicVariable} = useUpdateDynamicVariableMutation();
  const {systemErrorNotification} = useAuthorize();

  const onCancel = () => {
    closeModal();
  };

  const handleVariableTypeChg = (value) => {
    try {
      form.setValue("variableType", value);
      const pdfTypeList = ["application/pdf"];
      const imageTypelist = ["image/png", "image/jpg", "image/jpeg"];
      const fileType = fileObjectValue?.[0]?.type;
      const isFileTypeMatch = (value === "pdf" && pdfTypeList?.includes(fileType)) || (value === "image" && imageTypelist?.includes(fileType));
      if (!isFileTypeMatch) {
        setFileObjectValue([]);
      }
    } catch (error) {}
  };

  const uploadOnChange = (info, language) => {
    try {
      if (info?.file?.status === "uploading") {
        handleObjectChange(setFileObjectValue, info?.fileList, "fileList", language);
      }
      if (info?.file?.status === "done") {
        handleObjectChange(setFileObjectValue, info?.file?.name, "fileName", language);
        handleObjectChange(setFileObjectValue, info?.file?.response[0]?.link, "path", language);
        handleObjectChange(setFileObjectValue, info?.fileList, "fileList", language);
        message?.success(`${info?.file?.name} file uploaded successfully`);
      } else if (info?.file?.status === "error") {
        handleObjectChange(setFileObjectValue, null, "fileList", language);
        message?.error(`${info?.file?.name} file upload failed.`);
      }
    } catch (error) {}
  };

  const uploadOnRemove = (value, language) => {
    try {
      const fileList = getListFromObject(fileObjectValue, "fileList", language);
      const latestFileList = fileList?.filter((item) => item?._uid === value?.uid);
      handleObjectChange(setFileObjectValue, latestFileList, "fileList", language);
      handleObjectChange(setFileObjectValue, "", "fileName", language);
      handleObjectChange(setFileObjectValue, "", "path", language);
    } catch (error) {}
  };

  const onSubmit = async (e) => {
    try {
      const payload = {
        ...e,
        textObject: textObjectValue,
        fileObject: fileObjectValue,
      };
      let amendedPayload: any = _.cloneDeep(payload);
      delete amendedPayload?.reportTableObject?.fromDate;
      delete amendedPayload?.reportTableObject?.toDate;
      if (payload?.variableType === "reportTable" && payload?.reportTableObject?.dateRangeType === "CUSTOM") {
        if (payload?.reportTableObject?.fromDate) {
          let amendedFromDate = dayjs(payload?.reportTableObject?.fromDate)?.format("YYYY-MM-DD");
          amendedPayload.reportTableObject.fromDate = amendedFromDate;
        }
        if (payload?.reportTableObject?.toDate) {
          let amendedToDate = dayjs(payload?.reportTableObject?.toDate)?.format("YYYY-MM-DD");
          amendedPayload.reportTableObject.toDate = amendedToDate;
        }
      }
      const response = mode === "add" ? await addDynamicVariable(amendedPayload) : await updateDynamicVariable(item?.id, amendedPayload);
      const {data} = response;
      if (response) {
        closeModal();
      }
    } catch (e) {
      console.log(e);
      systemErrorNotification();
    }
  };

  const textObjectItems =
    languageData
      ?.sort((a, b) => {
        if (a.name === "English") {
          return -1;
        } else if (b?.name === "English") {
          return 1;
        } else {
          return a?.name?.localeCompare(b?.name);
        }
      })
      ?.map((item, index) => {
        return {
          key: index?.toString(),
          label: item?.name,
          children: (
            <Row>
              <Col xs={12}>
                <InputFormItem2
                  name={`text_${index}`}
                  label={t("Text")}
                  placeholder={t("Text")}
                  layout={verticalFormLayout}
                  value={getTextFromObject(textObjectValue, "text", item?.code)}
                  onChange={(e) => handleObjectChange(setTextObjectValue, e, "text", item?.code)}
                  disabled={mode === "view"}
                />
              </Col>
            </Row>
          ),
        };
      }) || [];

  const fileObjectItems =
    languageData
      ?.sort((a, b) => {
        if (a?.name === "English") {
          return -1;
        } else if (b?.name === "English") {
          return 1;
        } else {
          return a?.name?.localeCompare(b?.name);
        }
      })
      ?.map((item, index) => {
        return {
          key: index?.toString(),
          label: item?.name,
          children: (
            <>
              <Row gutter={10}>
                <Col xs={12}>
                  <PDFUploadFormItem
                    name={`fileList_${index}`}
                    label={t("Upload")}
                    layout={verticalFormLayout}
                    disabled={mode === "view"}
                    mode={form?.getValues()?.variableType === "pdf" ? "pdf" : "image"}
                    listType={"picture"}
                    fileList={getListFromObject(fileObjectValue, "fileList", item?.code)}
                    onChange={(e) => uploadOnChange(e, item?.code)}
                    onRemove={(e) => uploadOnRemove(e, item?.code)}
                    setErrMsg={setErrMsg}
                    // externalErrorMessage={
                    //   form.getValues().variableType === "pdf" ? "Allowed File Types: pdf" : "Allowed File Types: png,jpg,jpeg"
                    // }
                  />
                </Col>
              </Row>
              <Row gutter={10}>
                <Col xs={12}>
                  <InputFormItem
                    name={`displayName_${index}`}
                    label={t("Display Name")}
                    placeholder={t("Display Name")}
                    value={getTextFromObject(fileObjectValue, "displayName", item?.code)}
                    onChange={(e) => handleObjectChange(setFileObjectValue, e, "displayName", item?.code)}
                    layout={verticalFormLayout}
                    disabled={mode === "view"}
                  />
                </Col>
              </Row>
              <Row gutter={10}>
                <Col xs={12}>
                  <InputFormItem
                    name={`fileName_${index}`}
                    label={t("File Name")}
                    placeholder={t("File Name")}
                    value={getTextFromObject(fileObjectValue, "fileName", item?.code)}
                    onChange={(e) => handleObjectChange(setFileObjectValue, e, "fileName", item?.code)}
                    layout={verticalFormLayout}
                    disabled
                  />
                </Col>
                <Col xs={12}>
                  <InputFormItem
                    name={`path_${index}`}
                    label={t("Path")}
                    placeholder={t("Path")}
                    value={getTextFromObject(fileObjectValue, "path", item?.code)}
                    onChange={(e) => handleObjectChange(setFileObjectValue, e, "path", item?.code)}
                    layout={verticalFormLayout}
                    disabled
                  />
                </Col>
              </Row>
            </>
          ),
        };
      }) || [];

  return (
    <FormProvider {...form}>
      <form onSubmit={form?.handleSubmit(onSubmit)} className="form-1">
        <>
          {errMsg && <p style={{color: "red"}}>{errMsg}</p>}
          <Row gutter={10}>
            <Col xs={12}>
              <InputFormItem
                name={"name"}
                label={t("Name")}
                placeholder={t("Name")}
                layout={verticalFormLayout}
                disabled={mode === "view"}
                required
              />
            </Col>
            <Col xs={12}>
              <SelectFormItem
                name={"variableType"}
                label={t("Variable Type")}
                placeholder={t("Variable Type")}
                options={[
                  {label: t("Image"), value: "image"},
                  {label: t("Text"), value: "text"},
                  {label: t("PDF"), value: "pdf"},
                  {label: t("Report Table"), value: "reportTable"},
                ]}
                layout={verticalFormLayout}
                disabled={mode === "view"}
                onChange={handleVariableTypeChg}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <RadioGroupFormItem
                name={"isActive"}
                label={t("Status")}
                options={[
                  {label: t("Active"), value: true},
                  {label: t("Inactive"), value: false},
                ]}
                layout={verticalFormLayout}
                required
                disabled={mode === "view"}
              />
            </Col>
          </Row>
          {(form?.getValues()?.variableType === "image" || form?.getValues()?.variableType === "pdf") && (
            <>
              <Tabs defaultActiveKey="0" items={fileObjectItems} />
            </>
          )}
          {form?.getValues()?.variableType === "text" && (
            <>
              <Tabs defaultActiveKey="0" items={textObjectItems} />
            </>
          )}
          {form?.getValues()?.variableType === "reportTable" && (
            <>
              <Divider />
              <ReportTableForm form={form} mode={mode} reportTableObjectData={item?.reportTableObject} />
            </>
          )}
        </>
        <Form.Item className="text-align-right" style={{paddingTop: "12px"}}>
          {onCancel && (
            <Button onClick={onCancel} icon={<CloseOutlined />} className="button-cancel-1">
              {mode === "view" ? t("common.close") : t("common.cancel")}
            </Button>
          )}
          {mode !== "view" && (
            <Button onClick={form?.handleSubmit(onSubmit)} icon={<SaveOutlined />} className="button-submit-1">
              {t("common.submit")}
            </Button>
          )}
        </Form.Item>
      </form>
    </FormProvider>
  );
};

export default VariableForm;
