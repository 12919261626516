const handleObjectChange = (setArray, value, field, language) => {
    setArray(prevState => {
      const index = prevState.findIndex(item => item.language === language);
      const temp = [...prevState];
      if (index === -1) {
        temp.push({ [field]: value, language: language });
      } else {
        const updatedObj = { ...temp[index], [field]: value };
        temp[index] = updatedObj;
      }
      
      return temp;
    });
  };


  const getTextFromObject = (array, field, language) => {
    const index = array.findIndex(item => item.language === language)

    if(!array || !array[index] || !array[index][field]){
      return ""
    }
    
    return array[index][field]
  }

  const getListFromObject = (array, field, language) => {
    const index = array.findIndex(item => item.language === language)
    
    if(!array || !array[index]){
      return []
    }
    
    return array[index][field]
  }

  export {
    getListFromObject,
    getTextFromObject,
    handleObjectChange
  }