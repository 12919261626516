import React from "react";
import {BsCheck, BsX} from "react-icons/bs";
import {getColumnSearchProps} from "../../../../components/table/function/filterTable";

export const columnsCT = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    ...getColumnSearchProps(),
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Swaps",
    dataIndex: "swapEnabled",
    key: "swapEnabled",
    align: "center",
    render: (text) => (text) ? <BsCheck style={{ color: 'green' }}/> : <BsX style={{ color: 'red' }}/>,
  },
  {
    title: "SO",
    dataIndex: "marginStopout",
    key: "marginStopout",
    align: "center",
    render: (text) => <p>{text * 100}%</p>,
  },
  {
    title: "Accounts",
    dataIndex: "accountCount",
    key: "accountCount",
    align: "center",
    render: (text, record) => text > 0 ? <a onClick={() => window.open(`/account/trading-account?spreadGroup=${record?.id}`)}>{text}</a> : text,
  },
];
