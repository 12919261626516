import {useState} from "react";
import {getUserSubset} from "../../../../../api/graphql/user";

export const useGetUser = () => {
    const [userData, setUserData] = useState<any[]>([])
    const [userTotal, setUserTotal] = useState<number>(0)
    const [userLoading, setUserLoading] = useState<boolean>(false)
    const [param, setParam] = useState<any>(null)

    const fetchUserData = async (filter, limit, offset, orderBy) => {
        try {
            setUserLoading(true)
            setParam({ filter, limit, offset, orderBy })
            const response = await getUserSubset(filter, limit, offset, orderBy)

            setUserData(response?.getUserSubset?.data)
            setUserTotal(response?.getUserSubset?.total)
        } catch (e) {
            console.error(e)
        } finally {
            setUserLoading(false)
        }
    }

    const refetchUserData = async () => {
        await fetchUserData(param.filter, param.limit, param.offset, param.orderBy)
    }

    return [userData, userTotal, userLoading, fetchUserData, refetchUserData] as const
}