import React, {useMemo, useState} from "react";
import {useSetRecoilState} from "recoil";
import {Button, Form, Modal, Row} from "antd";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import {t} from "i18next";
import {FormProvider, useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import SelectFormItem from "../../../../../../../../components/form/selectFormItem";
import {horizontalFormLayout} from "../../../../../../../../components/form/formWrapper";
import yupFormSchema from "../../../../../../../../components/yup/yupFormSchema";
import {getVoucherProgramDropdown, useAddUserVoucherProgramMutation} from "../../../../../../../../api/graphql/voucherProgram";
import useAuthorize from "../../../../../../../../_common/authorize";
import {notificationMessage} from "../../../../../../../../../recoil_state";
import {useTranslation} from "react-i18next";

const IssueVoucherModal = (props) => {
    const {isOpen, close, data, isVoucherProgramActionLoading, setIsVoucherProgramActionLoading} = props;
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {baseErrorChecking, systemErrorNotification} = useAuthorize();
    const {data: voucherProgramDropdownData, loading} = getVoucherProgramDropdown();
    const {addUserVoucherProgram, loading: addLoading} = useAddUserVoucherProgramMutation();
    const {t} = useTranslation()

    const [initialValues] = useState(() => ({
        voucherProgramId: null,
    }));

    const yupSchema = yup.object().shape({
        voucherProgramId: yupFormSchema.string("voucherProgramId", {required: true}),
    });

    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
        defaultValues: initialValues,
    });

    const onSubmit = async () => {
        try {
            setIsVoucherProgramActionLoading(true);
            const formValues = form.getValues();
            // let missionCode = voucherProgramDropdownData?.getMission?.data?.find((m) => m?._id === formValues?.voucherProgramId)?.missionCode;
            let input = {...formValues, user: data?._id};
            await addUserVoucherProgram(input);
            close();
        } catch (error) {
            systemErrorNotification();
        } finally {
            setIsVoucherProgramActionLoading(false);
        }
    };

    const labelCol = {span: 8, offset: 0};

    const options = useMemo(() => {
        return voucherProgramDropdownData?.getVoucherProgramDropdown?.data?.map((m) => ({label: m?.displayName?.[0]?.content || m?.name, value: m?._id}));
    }, [voucherProgramDropdownData]);

    return (
        <Modal
            title={t("reward.issueVoucherProgram")}
            open={isOpen}
            width={"80%"}
            footer={null}
            destroyOnClose
            getContainer="#clientDetail"
            onCancel={close}
        >
            <FormProvider {...form}>
                <Form onFinish={onSubmit} className="create-report-form-1" labelWrap={true}>
                    <SelectFormItem
                        name={"voucherProgramId"}
                        label={t("reward.voucherProgram")}
                        placeholder={t("reward.voucherProgram")}
                        layout={horizontalFormLayout}
                        mode={"single"}
                        labelCol={labelCol}
                        labelAlign="left"
                        options={options}
                        getPopupContainer={(triggerNode) => triggerNode.parentElement}
                        disabled={isVoucherProgramActionLoading || loading}
                    />
                    <Row justify="end" className="margin-top-0-5">
                        <Button onClick={close} icon={<CloseOutlined />} className="cancelBtn" disabled={addLoading || loading}>
                            {t("common.cancel")}
                        </Button>
                        <Button
                            htmlType="submit"
                            icon={<SaveOutlined />}
                            className="button-submit-1"
                            disabled={isVoucherProgramActionLoading || loading}
                            loading={isVoucherProgramActionLoading || loading}
                        >
                            {t("common.submit")}
                        </Button>
                    </Row>
                </Form>
            </FormProvider>
        </Modal>
    );
};

export default IssueVoucherModal;
