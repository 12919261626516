import React, {useRef} from "react";
import {Button, Form, Modal} from "antd";
import {Editor} from "@tinymce/tinymce-react";
import {getConfigByName} from "../../../../../../../config";
import {BRAND_NAME} from "../../../../../../../config/brandVariable";
import {useTranslation} from "react-i18next";

const DocumentsTinyMceEditor = ({title, isOpen, setIsOpen, disabled, name, setValue, initialValue}) => {
    const {t} = useTranslation();
    const editorRef = useRef<any>(null);
    const brandConfig = getConfigByName(BRAND_NAME);

    return (
        <>
            <Modal
                title={title}
                open={isOpen}
                width={"80%"}
                footer={
                        !disabled && (
                            <Button type="primary" onClick={() => setIsOpen(false)}>
                                {t("common.ok")}
                            </Button>
                        )
                }
                closeIcon={false}
                getContainer={".document-form"}
                onCancel={() => setIsOpen(false)}
            >
                <Form.Item wrapperCol={{span: 24}} getValueFromEvent={e => e.target && e.target.getContent()} name={name} initialValue={initialValue}>
                    <Editor
                        onInit={(_, editor) => (editorRef.current = editor)}
                        apiKey={brandConfig?.tinyMCEApiKey}
                        init={{
                            height: 500,
                            menubar: true,
                            plugins: [
                                "advlist",
                                "autolink",
                                "lists",
                                "link",
                                "image",
                                "charmap",
                                "preview",
                                "anchor",
                                "searchreplace",
                                "visualblocks",
                                "code",
                                "fullscreen",
                                "insertdatetime",
                                "media",
                                "table",
                                "code",
                                "help",
                                "wordcount",
                            ],
                            toolbar:
                                "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media component link anchor codesample | ltr rtl",
                            content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                        onEditorChange={(newValue, editor) =>
                            setValue(name, newValue)
                        }
                        disabled={disabled}
                    />
                </Form.Item>
            </Modal>
        </>
    )
}

export default DocumentsTinyMceEditor