import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";

const GET_SECURE_INITIAL_DATA = gql`
    query getSecureInitialData($languageCode: String) {
        getSecureInitialData(languageCode: $languageCode) {
            getFieldList {
                ... on FieldListArray {
                    data {
                        _id
                        label
                        fieldName
                        fieldType
                        isSensitive
                        isRequired
                        isConfirmRequired
                        isUnique
                        optionValue {
                            label
                            value
                            key
                        }
                        category
                        isOptionValuesEditable
                        isDisplayToClient
                        columnWidth
                        minCharacterLimit
                        maxCharacterLimit
                        isUsedForLogin
                    }
                }
                ... on BaseError {
                    errKey
                    errMsg
                    errObj
                }
            }
            getSelfPermissionList
            getSelfPermissionListObject
            getCurrencySetting {
                ... on BaseError {
                    errKey
                    errMsg
                    errObj
                }
                ... on CurrencySettingArray {
                    data {
                        _id
                        id
                        label
                        name
                        symbol
                        code
                        digits
                    }
                }
            }
            getBrandDomainDropdown {
                ... on BrandDomainArray {
                    data {
                        label
                        value
                        key
                    }
                }
                ... on BaseError {
                    errKey
                    errMsg
                    errObj
                }
            }
            getUserNetworkTree {
                ... on UserNetworkTreeArray {
                    data {
                        _id
                        userid {
                            username
                            identificationNo
                            email
                        }
                    }
                }
                ... on BaseError {
                    errKey
                    errMsg
                    errObj
                }
            }
            getUserDropdown {
                ... on UserAry {
                    data {
                        _id
                        username
                        email
                        identificationNo
                        userType
                    }
                }
                ... on BaseError {
                    errKey
                    errMsg
                    errObj
                }
            }
            getSalesRepNetworkTree {
                ... on SalesRepNetworkTreeArray {
                    data {
                        _id
                        userid {
                            username
                            email
                            identificationNo
                        }
                    }
                }
                ... on BaseError {
                    errKey
                    errMsg
                    errObj
                }
            }
            getDeskDropdown {
                ... on BaseError {
                    errKey
                    errMsg
                    errObj
                }
                ... on DeskArray {
                    data {
                        _id
                        name
                    }
                }
            }
            getRoleTemplateDropdown {
                ... on RoleTemplateObjArray {
                    data {
                        _id
                        name
                        isDefaultCrmUserPermission
                    }
                }
                ... on BaseError {
                    errKey
                    errMsg
                    errObj
                }
            }
            getMultiTierSettingDropdown {
                ... on MultiTierSettingArray {
                    data {
                        _id
                        name
                        type
                        isDefaultRank
                    }
                }
                ... on BaseError {
                    errKey
                    errMsg
                    errObj
                }
            }
        }
    }
`

export const getSecureInitialData = async (languageCode) => {
    try {
        const execute = await ApolloClientQuery(
            GET_SECURE_INITIAL_DATA,
            {
                languageCode: languageCode,
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const GET_INITIAL_DATA_WITHOUT_TOKEN = gql`
    query getSecureInitialDataWithoutToken($languageCode: String) {
        getSecureInitialDataWithoutToken(languageCode: $languageCode) {
            getLanguage {
                ... on LanguageArray {
                    data {
                        _id
                        name
                        nativeName
                        code
                        translationFile
                        countryCode
                        isActive
                    }
                }
                ... on BaseError {
                    errKey
                    errMsg
                    errObj
                }
            }
            getLanguageDropdown {
                ... on LanguageDropdownArray {
                    data {
                        nativeName
                        name
                        code
                        _id
                    }
                }
                ... on BaseError {
                    errKey
                    errMsg
                    errObj
                }
            }
            getCrmUserBrandSetting {
                ... on BaseError {
                    errKey
                    errMsg
                    errObj
                }
                ... on UserBrandSetting {
                    companyLogo
                    companyLogoInverse
                    companyLogoSmall
                    companyLogoInverseSmall
                    siteFavicon
                    siteFaviconInverse
                    companyName
                    companyNameShort
                    supportEmail
                    companyAddress
                    copyRight
                    timezone
                    pushNotification
                    facebookLink
                    instaLink
                    youtubeLink
                    twitterLink
                    linkedInLink
                    companySlogan
                    domain
                    defaultDomain
                    aboutPage
                    supportPage
                    companyTermOfUseLink
                    companyPrivacyPolicyLink
                    companyReturnRefundPolicyLink
                    companyRiskDisclosureLink
                    companyTermOfBusinessLink
                    website
                    adminPanelAutoLogoutTimer
                    tradersRoomAutoLogoutTimer
                    theme
                    layout
                    usernameCharacterLimit
                    companyNo
                    licenseNo
                    incorporationCountry
                    redirectNavigation {
                        conditionType
                        conditionValue
                        redirectUri
                        deviceType
                        webNavigation {
                            _id
                            name
                            id
                        }
                        appNavigation {
                            _id
                            name
                            id
                        }
                    }
                }
            }
        }
    }
`

export const getSecureInitialDataWithoutToken = async (languageCode) => {
    try {
        const execute = await ApolloClientQuery(
            GET_INITIAL_DATA_WITHOUT_TOKEN,
            {
                languageCode: languageCode,
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const GET_SECURE_INITIAL_TRADING_ACCOUNT_DETAILS = gql`
    query GetSecureInitialTradingAccountDetails($accId: String)  {
        getSecureInitialTradingAccountDetails(accId: $accId) {
            getTradingAccountDetail {
                ... on TradingAccount {
                    _id
                    user {
                        _id
                        username
                        isLoginEnabled
                        clientAccountType
                    }
                    status
                    accountId
                    adminPanelAccountStatus
                    traderId
                    brokerName
                    accessRights
                    depositAssetId
                    hasTradingPermission
                    spreadGroup {
                        _id
                        groupId
                        name
                    }
                    mt5SpreadGroup {
                        _id
                        group
                    }
                    product {
                        _id
                        id
                        name
                        leverage
                        productType
                        server {
                            _id
                            platform
                            platformDisplayName
                            mode
                            name
                            resetPasswordLink
                        }
                        spreadGroup {
                            _id
                            name
                        }
                        spreadGroupMt5 {
                            _id
                            group
                        }
                        mode
                    }
                    leverage
                    leverageInCents
                    balance
                    lockBalance
                    asset
                    totalBalance
                    margin
                    tradingVoucher
                    availableMargin
                    marginLevel
                    credit
                    activeCredit
                    mode
                    platform
                    equity
                    registrationDatetime
                    hasDepositPermission
                    hasWithdrawalPermission
                    createdAt
                    isSendReport
                    isSuspicious
                    lastUpdated
                    isDefault
                    isPrivate
                    commRule {
                        rule
                        ruleName
                        group
                        groupName
                        commissionSetting
                        ruleType
                        commRuleType
                        commRuleAuto
                    }
                    openPosition
                    freeMarginWithdrawableAmount
                    managerUpdateDatetime
                    mt5Rights
                    status_mt5
                    comment_mt5
                    moneyManagerAccountSetting {
                        _id
                        account {
                            _id
                            accountId
                        }
                    }
                    investorAccountSetting {
                        status
                        moneyManagerAccount {
                            _id
                            accountId
                        }
                        investorAccount {
                            _id
                            accountId
                        }
                    }
                }
                ... on BaseError {
                    errKey
                    errMsg
                    errObj
                }
            }
        }
    }

`

export const getSecureInitialTradingAccountDetails = async (accId) => {
    try {
        const execute = await ApolloClientQuery(
            GET_SECURE_INITIAL_TRADING_ACCOUNT_DETAILS,
            {
                accId: accId,
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}