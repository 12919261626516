import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Form, Modal, Row, Upload} from "antd";
import * as XLSX from "xlsx";
import FileSaver from 'file-saver'
import useAuthorize from "../../../../../_common/authorize";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../recoil_state";
import {UploadOutlined} from "@ant-design/icons";
import axios from "axios";
import {getConfigByName} from "../../../../../../config";
import {BRAND_NAME} from "../../../../../../config/brandVariable";
import {useNavigate} from "react-router-dom";


const LeadsImportForm = (props) => {
    const {close, isOpen, profileInformation} = props;
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {systemErrorNotification} = useAuthorize()

    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState<any>([])
    const [uploadedSuccessfully, setUploadedSuccessfully] = useState(false)

    const requiredField = profileInformation?.filter(d => d?.fieldList?.isRequired)?.map(d => t(d?.fieldList?.label))?.join(", ")
    const brandConfig = getConfigByName(BRAND_NAME)

    const onSubmit = async () => {
        setLoading(true)

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(`${brandConfig?.graphqlLoadURL.replace("/graphql", "")}/api/lead/upload/csv`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    authorization: localStorage.getItem("a_t"),
                }
            });

            if (response.data.status === "success") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t(`lead.import_leads.success_message`),
                    key: "importLeads",
                });

                setUploadedSuccessfully(true)
            } else {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t(`lead.import_leads.error_message`, {message: response.data.message}),
                    key: "importLeads",
                });
            }
        } catch (error) {
            systemErrorNotification()
        } finally {
            setLoading(false)
        }
    }

    const exportToExcelFile = (fileName) => {
        try {
            const header = profileInformation?.slice()?.sort((a, b) => a?.position - b?.position)?.map(d => {
                if (d?.fieldList?.fieldName === "mobileNo") return ["countryCode", "mobileNo"]
                return d?.fieldList?.fieldName
            }).flat()

            const data: any = [];

            const ws = XLSX.utils.aoa_to_sheet([[...header, "salesRep"], ...data]);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
            const buffer = XLSX.write(wb, {type: 'array', bookType: 'xlsx'});
            const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            FileSaver.saveAs(blob, `${fileName}.xlsx`);
        } catch (e) {
            console.log(e)
            systemErrorNotification()
        }
    }

    const dummyRequest = ({file, onSuccess}: any) => {
        setFile(file)
        onSuccess("ok");
    };

    useEffect(() => {
        if (isOpen) setUploadedSuccessfully(false)
    }, [isOpen]);

    return (
        <Modal
            title={t("lead.importForm.modalTitle")}
            open={isOpen}
            width={"80%"}
            footer={
                !uploadedSuccessfully &&
                <>
                    <Button disabled={loading} onClick={() => close()}>{t("common.cancel")}</Button>
                    <Button loading={loading} disabled={false} onClick={() => onSubmit()}>{t("common.submit")}</Button>
                </>
            }
            destroyOnClose
            onCancel={() => close()}
        >
            {
                uploadedSuccessfully ?
                    <div className={"text-align-center"}>
                        <p>{t("lead.uploaded_successfully_note")}</p>
                        <Button onClick={() => navigate("/users/lead/pending-list")}>{t("lead.pendingList")}</Button>
                    </div> :

                    <>
                        <h3>{t("lead.importForm.setupImportFiles.title")}</h3>
                        <p>{t("lead.importForm.setupImportFiles.content")}</p>
                        <br/>
                        <h3>{t("lead.importForm.importRequirement.title")}</h3>
                        <ol>
                            <li>{t("lead.importForm.importRequirement.content1")}</li>
                            {
                                requiredField &&
                                <li>{t("lead.importForm.importRequirement.content2", {requiredField: requiredField})}</li>
                            }
                            <li>{t("lead.importForm.importRequirement.content3")}</li>
                            <li>{t("lead.importForm.importRequirement.content4")}</li>
                        </ol>
                        <br/>
                        <h3>{t("common.downloadTemplate")}</h3>
                        <ol>
                            <li>{t("common.download")} <a
                                href={window.location.origin + "/template/Country_Code_Template.xlsx"}>{t("lead.country_code_template")}.xls</a>
                            </li>
                            <li>{t("common.download")} <a
                                href={window.location.origin + "/template/Country_Template.xlsx"}>{t("lead.country_template")}.xls</a>
                            </li>
                            <li>{t("common.download")} <a
                                href={window.location.origin + "/template/Nationality_Template.xlsx"}>{t("lead.nationality_template")}.xls</a>
                            </li>
                            <li>{t("common.download")} <a onClick={() => exportToExcelFile('Lead_Template')}>{t("lead.lead_template")}.xls</a>
                            </li>
                        </ol>
                        <br/>
                        <h3>{t("common.importFiles")}</h3>
                        <p>* {t("common.CSV/XLSFiles")}</p>
                        <Upload
                            accept=".xlsx, .xls"
                            showUploadList={true}
                            customRequest={dummyRequest}
                            maxCount={1}
                        >
                            <Button disabled={loading} icon={<UploadOutlined/>}>{t("common.clickToUpload")}</Button>
                        </Upload>
                    </>
            }
        </Modal>
    )
}

export default LeadsImportForm