import React from "react";
import {Rate} from "antd";
import {useUpdateUserLeadsMutation} from "../../../../../api/graphql/leads";
import {useRecoilValue} from "recoil";
import {fieldListState, selfPermissionObj} from "../../../../../../recoil_state";
import {useTranslation} from "react-i18next";
import {getColumnSearchProps, getDateRangeFilterProps, getSingleSelectFilterProps} from "../../../../../components/table/function/filterTable";

const PotentialColumn = (props) => {
    const {updateUserLeads} = useUpdateUserLeadsMutation();
    const permission = useRecoilValue<any>(selfPermissionObj)

    const potentialOnChange = (value, record) => {
        updateUserLeads(record?._id, {potential: value?.toString()});
    };

    return (
        <Rate
            value={props.value}
            onChange={(value) => potentialOnChange(value, props.record)}
            style={{fontSize: 12}}
            disabled={!permission?.user?.leads?.edit?.edit}
        />
    );
};

export const Columns = () => {
    const {t} = useTranslation()
    const fieldList = useRecoilValue<any>(fieldListState)

    return [
        {
            title: t("lead.leadId"),
            dataIndex: "leadId",
            key: "leadId",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("common.status"),
            dataIndex: "leadStatus",
            key: "leadStatus",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("lead.comment"),
            dataIndex: "comment",
            key: "comment",
            ...getSingleSelectFilterProps(fieldList?.find(d => d?.fieldName === "leadsComment")?.optionValue?.map(d => ({label: t(d?.label), value: d?.value}))),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("lead.owner"),
            dataIndex: "ownerUsername",
            key: "ownerUsername",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("lead.salesRep"),
            dataIndex: "salesRepUsername",
            key: "salesRepUsername",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("lead.Assigned DateTime"),
            dataIndex: "assignedDatetime",
            key: "assignedDatetime",
            ...getDateRangeFilterProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("lead.potential"),
            dataIndex: "potential",
            key: "potential",
            ...getColumnSearchProps(),
            render: (text, record) => <PotentialColumn value={text} record={record}/>,
        },
        {
            title: t("user.nextFollowUp"),
            dataIndex: "nextFollowUp",
            key: "nextFollowUp",
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("user.lastFollowUp"),
            dataIndex: "lastFollowUp",
            key: "lastFollowUp",
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("user.registrationTime"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("lead.bind"),
            dataIndex: "bindTo",
            key: "bindTo",
            render: (text, record) => record?.bind && record?.bind?.userType ?
                <a href={`/users/${record?.bind?.userType === "client" ? record?.bind?.userType : "crm-user"}/detail?id=` + record?.bind?._id}
                   target={"_blank"}>{record?.bind?.username}</a> : <p>-</p>,
        },
    ]
}