import {atom} from "recoil";


const tradingAccountFilterState = atom({
    key: 'tradingAccountFilterState',
    default: {},
});

const tradingAccountLimitState = atom({
    key: 'tradingAccountLimitState',
    default: 20,
});

const tradingAccountOffsetState = atom({
    key: 'tradingAccountOffsetState',
    default: 0,
});

const tradingAccountFilterTypeSearchState = atom({
    key: 'tradingAccountFilterTypeSearchState',
    default: null,
});
const tradingAccountFilterTypeIdSearchState = atom({
    key: 'tradingAccountFilterTypeIdSearchState',
    default: null,
});

const tradingAccountStartTimeSearchState = atom({
    key: 'tradingAccountStartTimeSearchState',
    default: "",
});

const tradingAccountEndTimeSearchState = atom({
    key: 'tradingAccountEndTimeSearchState',
    default: "",
});

const tradingAccountDateKeySearchState = atom({
    key: 'tradingAccountDateKeySearchState',
    default: "",
});

const tradingAccountOrderBySearchState = atom({
    key: 'tradingAccountOrderBySearchState',
    default: "registrationDate_DESC",
});



export {
    tradingAccountFilterState,
    tradingAccountLimitState,
    tradingAccountOffsetState,
    tradingAccountStartTimeSearchState,
    tradingAccountEndTimeSearchState,
    tradingAccountDateKeySearchState,
    tradingAccountOrderBySearchState,
    tradingAccountFilterTypeIdSearchState,
    tradingAccountFilterTypeSearchState
}