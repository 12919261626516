import React, {memo, useEffect, useState} from "react";
import {Col, Row, Typography} from "antd";
import {CloseOutlined, DownOutlined, EllipsisOutlined, RightOutlined,} from "@ant-design/icons";
import GoogleChrome from "../../../../../../assets/image/googleChrome";

const {Paragraph} = Typography;

const PreviewMacOS = (props) => {
    const {title, body, iconPath, imagePath} = props;
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleCollapse = () => {
        setIsCollapsed((prevState) => !prevState);
    };

    useEffect(() => {
        if (!imagePath) {
            setIsCollapsed(true);
        }
    }, [imagePath]);

    let content = (
        <div>
            {!isCollapsed && (
                <>
                    <Row className="topMenu">
                        <Col span={2} offset={20}>
                            <EllipsisOutlined className="arrowDownIcon"/>
                        </Col>
                        <Col span={2}>
                            <DownOutlined onClick={toggleCollapse} className="arrowDownIcon"/>
                        </Col>
                    </Row>
                    {imagePath && (
                        <Row>
                            <Col span={24}>
                                <img className="notisImage" src={imagePath} alt="notisImage"/>
                            </Col>
                        </Row>
                    )}
                </>
            )}
            <Row align="top" gutter={10} className="textBox">
                <Col span={18} offset={4}>
                    <Paragraph ellipsis={{rows: 1}} className="notisTitle">
                        {title}
                    </Paragraph>
                </Col>
                <Col span={2}>
                    {imagePath && <RightOutlined onClick={toggleCollapse} className="arrowRightIcon"/>}
                </Col>

                <Col span={4}>
                    <div className="googleChromeIconBox">
                        <GoogleChrome className="googleChromeIcon"/>
                    </div>
                </Col>
                <Col span={16}>
                    <Row>
                        <Col span={24}>
                            <Paragraph ellipsis={{rows: 1}} className="notisTitle">
                                example.com
                            </Paragraph>
                        </Col>
                        <Col span={24}>
                            <Paragraph ellipsis={{rows: isCollapsed ? 3 : 5}} className="notisBody">
                                {body}
                            </Paragraph>
                        </Col>
                    </Row>
                </Col>
                {isCollapsed && imagePath && (
                    <Col span={4}>
                        <img className="notisImage" src={imagePath} alt="notisImage"/>
                    </Col>
                )}
            </Row>
        </div>
    );

    return (
        <div className="previewMacOSContainer">
            <div className={`notisContainer ${isCollapsed ? "collapsedView" : "expandView"}`}>
                <div className="notisContentContainer">
                    <CloseOutlined className="closeIcon"/>
                    {content}
                </div>
            </div>
        </div>
    );
};

export default memo(PreviewMacOS);
