import React, {useState} from "react";
import {Button, Card, Col, Divider, Form, message, Row, Spin,} from "antd";
import {useTranslation} from "react-i18next";
import {useUpdateBankInformationMutation,} from "../../../../../../../../../api/graphql/bankInformation";
import useAuthorize from "../../../../../../../../../_common/authorize";
import PspForm from "./pspForm";
import BankForm from "./bankForm";
import AddBankModal from "./addBankModal";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../../../recoil_state";

const BankInformation = (props) => {
    const {data, pspField, bankInfo, loading, refetchBankInformation} = props;
    const {t} = useTranslation();

    const [editable, setEditable] = useState<any>();
    const [isOpenAddBankModal, setIsOpenAddBankModal] = useState(false)
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const {updateBankInformation, loading: updateLoading} = useUpdateBankInformationMutation();
    const {systemErrorNotification} = useAuthorize()

    const groupedData = pspField?.reduce((result, item) => {
        const displayName = item?.pspSetting?.displayName;
        if (displayName) {
            if (!result[displayName]) {
                result[displayName] = [];
            }
            result[displayName].push(item);
        }
        return result;
    }, {});

    const updateBankInformationHandler = async (input) => {
        const {updateBankInformation: response} = await updateBankInformation(input);

        if (response?.__typename === "BaseError") {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: t(updateBankInformation?.errKey),
                key: "updateBankInformation",
            });
        } else if (response === "CrmTaskApproval") {
            setNotificationMessage({
                status: "info",
                title: "",
                msg: t(updateBankInformation?.msg),
                key: "updateBankInformation",
            });
        } else {
            setNotificationMessage({
                status: "success",
                title: "",
                msg: t("Update Bank Information Successfully"),
                key: "updateBankInformation",
            });

            refetchBankInformation()
        }
    }

    const onSubmitHandler = async (value) => {
        try {
            const modifiedObject = {};
            let psp;
            for (const key in value) {
                const lastUnderscoreIndex = key?.lastIndexOf("_");
                const modifiedKey = key?.slice(0, lastUnderscoreIndex);
                psp = key?.slice(lastUnderscoreIndex + 1);
                modifiedObject[modifiedKey] = value[key];
            }

            await updateBankInformationHandler({
                user: data?._id,
                psp: psp,
                formData: modifiedObject,
            })

            setEditable("");
        } catch (e) {
            systemErrorNotification()
        }
    };

    const onSubmitBankHandler = async (value) => {
        try {
            const requiredField = [
                "bank",
                "account_no",
                "account_holder",
                "currency",
                "swift_code",
            ];
            const modifiedObject: any = {};
            for (const key in value) {
                const lastUnderscoreIndex = key?.lastIndexOf("_");
                const modifiedKey = key?.slice(0, lastUnderscoreIndex);
                modifiedObject[modifiedKey] = value[key];
            }

            const missingFields: any = [];
            for (const field of requiredField) {
                if (
                    !modifiedObject.hasOwnProperty(field) ||
                    modifiedObject[field] === undefined ||
                    modifiedObject[field] === null ||
                    modifiedObject[field] === ""
                ) {
                    missingFields.push(field);
                }
            }

            if (missingFields.length > 0) {
                message.error(`Please fill in all required field.`);
            } else {
                await updateBankInformationHandler({
                    id: modifiedObject?.id,
                    user: data?._id,
                    formData: modifiedObject,
                })

                setEditable("");
                setIsOpenAddBankModal(false)
            }
        } catch (e) {
            systemErrorNotification()
        }
    };

    return (
        <div className="margin-top-0-75 margin-bottom-0-75">
            <Spin spinning={loading}>
                <Form
                    onFinish={onSubmitBankHandler}
                    style={{width: "100%"}}
                    labelCol={{flex: "150px"}}
                    labelWrap
                >
                    <Row className={"margin-top-0-75 margin-bottom-0-75"}>
                        <Col span={24}>
                            <Row justify={"space-between"}>
                                <h3 className={"margin-bottom-0"}>{t("psp.bank")}</h3>
                                <Button type={"primary"} onClick={() => setIsOpenAddBankModal(true)}>
                                    {t("common.addBank")}
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                    {
                        bankInfo?.filter(d => !d?.psp)?.length > 0 && bankInfo?.filter(d => !d?.psp)?.map(item =>
                            <Card className={"bank-info-card margin-top-0-75"} style={{width: "100%"}}>
                                <BankForm
                                    data={item}
                                    editable={editable}
                                    setEditable={setEditable}
                                    onSubmitHandler={onSubmitBankHandler}
                                    updateLoading={updateLoading}
                                />
                            </Card>
                        )
                    }
                </Form>
                <Divider/>
                <h3 className={"margin-bottom-0"}>{t("PSP")}</h3>
                {pspField &&
                    Object.entries(groupedData).map(([sectionTitle, sectionItems]) => {
                        const matchedData = bankInfo?.find((item) => item?.psp?._id === (sectionItems as any[])[0]?.pspSetting?._id)?.formData;
                        return (
                            <div key={sectionTitle}>
                                <PspForm
                                    onSubmitHandler={onSubmitHandler}
                                    editable={editable}
                                    setEditable={setEditable}
                                    sectionTitle={sectionTitle}
                                    sectionItems={sectionItems}
                                    matchedData={matchedData}
                                />
                            </div>
                        );
                    })}
                <AddBankModal isOpen={isOpenAddBankModal} setIsOpen={setIsOpenAddBankModal} onSubmitHandler={onSubmitBankHandler}
                              updateLoading={updateLoading}/>
            </Spin>
        </div>
    );
};

export default BankInformation;
