import React from "react";
import {Modal} from "antd";
import LanguageForm from "./languageForm";
import LanguageTranslationImportToolbar from "./languageTranslationImportToolbar";
import LanguageTableForm from "./languageTableForm";
import {useTranslation} from "react-i18next";

const LanguageModal = (props) => {
    const {
        mode,
        item,
        isOpen,
        closeModal,
        languageCode,
        setLanguageCode,
        translationFile,
        setTranslationFile,
        languageData
    } = props
    const { t } = useTranslation()

    const getTitle = ():String => {
      switch(mode){
        case "add":
          return `${t('Add New Language')}`
        case "view":
          return `${t('View Language')}`
        case "edit":
          return `${t('Edit Language')}`
        case "upload":
          return `${t('Upload Languages')}`
        default: 
         return `${t('Add New Language')}`
      }
    }
    
  return (
    <Modal
      getContainer={"#language"}
      title={getTitle()}
      open={isOpen}
      width={"80%"}
      footer={null}
      destroyOnClose
      onCancel={closeModal}
    >
      <LanguageForm languageData={languageData} mode={mode} item={item} closeModal={closeModal} languageCode={languageCode} setLanguageCode={setLanguageCode} translationFile={translationFile} setTranslationFile={setTranslationFile}>
        <LanguageTranslationImportToolbar translationFile={translationFile} setTranslationFile={setTranslationFile} mode={mode} languageCode={languageCode}/>
        <LanguageTableForm languageCode={languageCode} mode={mode} translationFile={translationFile} setTranslationFile={setTranslationFile} />
      </LanguageForm>
    </Modal>
  );
};

export default LanguageModal;
