import {gql, useMutation} from "@apollo/client"
import {ApolloClientQuery} from "./_apolloClient"
import {getConfigByName} from "../../../config"
import {BRAND_NAME} from "../../../config/brandVariable"

const GET_REPORTING_ALL = gql`
    query getReportingAll {
        getReportingAll {
            ... on ReportingDataAry {
                data
                total
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const getReportingAll = async () => {
    try {
        const execute = await ApolloClientQuery(
            GET_REPORTING_ALL,
            {
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        )

        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const GET_REPORTING_DETAILS = gql`
    query getReportingDetails(
        $id: String
        $data: ReportingFilters
        $fromDate: String
        $toDate: String
        $page: Int
        $rows: Int
        $displayMode: String
        $serverEnvironment: String
        $exportReport: Boolean
        $filterNetwork: Boolean
        $ctServerName: String
        $isTestAccountExcluded: Boolean
        $isTreeView: Boolean
        $platform: String
        $isTradingAccountPage: Boolean
    ) {
        getReportingDetails(
            id: $id
            data: $data
            fromDate: $fromDate
            toDate: $toDate
            page: $page
            rows: $rows
            displayMode: $displayMode
            serverEnvironment: $serverEnvironment
            exportReport: $exportReport
            filterNetwork: $filterNetwork
            ctServerName: $ctServerName
            isTestAccountExcluded: $isTestAccountExcluded
            isTreeView: $isTreeView
            platform: $platform
            isTradingAccountPage: $isTradingAccountPage
        ) {
            ... on ReportingDataAry {
                total
                res
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const getReportingDetails = async (id, data, fromDate?, toDate?, page?, rows?, displayMode?, serverEnvironment?, exportReport?, filterNetwork?, ctServerName?, isTestAccountExcluded?, isTreeView?, isTradingAccountPage?) => {
    try {
        let tradingPlatform = getConfigByName(BRAND_NAME).tradingPlatform
        const execute = await ApolloClientQuery(
            GET_REPORTING_DETAILS,
            {
                id: id,
                data: data,
                fromDate: fromDate,
                toDate: toDate,
                page: page,
                rows: rows,
                displayMode: displayMode,
                serverEnvironment: serverEnvironment,
                exportReport: exportReport,
                filterNetwork: filterNetwork ?? false,
                ctServerName: ctServerName,
                isTestAccountExcluded: isTestAccountExcluded,
                isTreeView: isTreeView ?? false,
                platform: tradingPlatform,
                isTradingAccountPage: isTradingAccountPage,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        )

        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const GET_REPORTING_TRANSACTION = gql`
    query getReportingBalanceHistory($data: ReportingFilters, $fromDate: String, $toDate: String, $page: Int, $rows: Int, $displayMode: String, $serverEnvironment: String, $tradingAccount: String, $isTradingAccountPage: Boolean, $platform: String) {
        getReportingBalanceHistory(
            data: $data
            fromDate: $fromDate
            toDate: $toDate
            page: $page
            rows: $rows
            displayMode: $displayMode
            serverEnvironment: $serverEnvironment
            tradingAccount: $tradingAccount
            isTradingAccountPage: $isTradingAccountPage
            platform: $platform
        ) {
            ... on ReportingDataAry {
                data
                total
            }
            ... on BaseError {
                errMsg
                errKey
                errObj
            }
        }
    }
`

const getReportingBalanceHistory = async (data, fromDate?, toDate?, page?, rows?, displayMode?, serverEnvironment?, tradingAccount?, platform?) => {
    try {
        let cTraderServer: any = null

        // if (ctServerName) {
        //     cTraderServer = ctServerName === "blueocean" ? "server_1" : "server_2"
        // }

        const execute = await ApolloClientQuery(
            GET_REPORTING_TRANSACTION,
            {
                data: data,
                fromDate: fromDate,
                toDate: toDate,
                page: page,
                rows: rows,
                displayMode: displayMode,
                serverEnvironment: serverEnvironment,
                tradingAccount: tradingAccount,
                isTradingAccountPage: true,
                platform: platform,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        )

        return execute
    } catch (err: any) {
        console.log("err api", err)
        return {data: null, loading: false, error: err}
    }
}

const GET_REPORTING_SUMMARY = gql`
    query getReportingSummary($id: String, $serverEnvironmentSetting: String) {
        getReportingSummary(id: $id, serverEnvironmentSetting: $serverEnvironmentSetting) {
            ... on ReportingData {
                data
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const getReportingSummary = async (id, serverEnvironmentSetting) => {
    try {
        const execute = await ApolloClientQuery(
            GET_REPORTING_SUMMARY,
            {
                id: id,
                serverEnvironmentSetting: serverEnvironmentSetting,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        )
        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const GET_REPORTING_RELATIONSHIP = gql`
    query getReportingRelationship($baseReportId: String, $groupBy: Boolean) {
        getReportingRelationship(baseReportId: $baseReportId, groupBy: $groupBy) {
            ... on ReportingData {
                data
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const getReportingRelationship = async (baseReportId, groupBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_REPORTING_RELATIONSHIP,
            {
                baseReportId: baseReportId,
                groupBy: groupBy,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        )
        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const GET_REPORTING_LINK_TO_RELATIONSHIP = gql`
    query getReportingLinkToRelationship($input: ReportingLinkToRelationshipInput) {
        getReportingLinkToRelationship(input: $input) {
            ... on ReportingData {
                data
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const getReportingLinkToRelationship = async (operation, groupBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_REPORTING_LINK_TO_RELATIONSHIP,
            {
                input: {
                    operation: operation,
                    groupBy: groupBy,
                },
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        )
        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const GET_REPORTING_BASE_REPORT_ID = gql`
    query getReportingBaseReportId($operations: [String]) {
        getReportingBaseReportId(operations: $operations) {
            ... on ReportingData {
                data
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const getReportingBaseReportId = async (operations) => {
    try {
        const execute = await ApolloClientQuery(
            GET_REPORTING_BASE_REPORT_ID,
            {
                operations: operations,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        )
        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const GET_REPORTING_GROUP_BY_BASE_REPORT_ID = gql`
    query getReportingGroupByBaseReportId($operations: [String]) {
        getReportingGroupByBaseReportId(operations: $operations) {
            ... on ReportingData {
                data
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const getReportingGroupByBaseReportId = async (operations) => {
    try {
        const execute = await ApolloClientQuery(
            GET_REPORTING_GROUP_BY_BASE_REPORT_ID,
            {
                operations: operations,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        )
        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const GET_REPORTING_LAST_FUNCITON_FIELD = gql`
    query getReportingLastFunctionField($operation: String) {
        getReportingLastFunctionField(operation: $operation) {
            ... on ReportingData {
                data
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const getReportingLastFunctionField = async (operation) => {
    try {
        const execute = await ApolloClientQuery(
            GET_REPORTING_LAST_FUNCITON_FIELD,
            {
                operation: operation,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        )
        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const GET_REPORTING_VALIDATE_OPERATION = gql`
    query getReportingValidateOperation($operation: String, $groupBy: Boolean) {
        getReportingValidateOperation(operation: $operation, groupBy: $groupBy) {
            ... on ReportingData {
                data
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const getReportingValidateOperation = async (operation, groupBy) => {
    try {
        const execute = await ApolloClientQuery(
            GET_REPORTING_VALIDATE_OPERATION,
            {
                operation: operation,
                groupBy: groupBy,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        )
        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const GET_REPORTING_FIELDS = gql`
    query getReportingFields {
        getReportingFields {
            ... on ReportingDataAry {
                data
                total
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const getReportingFields = async () => {
    try {
        const execute = await ApolloClientQuery(
            GET_REPORTING_FIELDS,
            {
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        )
        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const GET_REPORTING_FIELDS_BY_REPORT_ID = gql`
    query getReportingFieldsByReportId($id: String) {
        getReportingFieldsByReportId(id: $id) {
            ... on ReportingDataAry {
                data
                total
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const getReportingFieldsByReportId = async (reportId) => {
    try {
        const execute = await ApolloClientQuery(
            GET_REPORTING_FIELDS_BY_REPORT_ID,
            {
                id: reportId,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        )
        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const GET_REPORTING_REPORT_IDENTIFIER = gql`
    query getReportingReportIdentifier {
        getReportingReportIdentifier {
            ... on ReportingData {
                data
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const getReportingReportIdentifier = async () => {
    try {
        const execute = await ApolloClientQuery(
            GET_REPORTING_REPORT_IDENTIFIER,
            {
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        )
        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const GET_REPORTING_SAME_RELATIONSHIP = gql`
    query getReportingSameRelationship {
        getReportingSameRelationship {
            ... on ReportingData {
                data
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const getReportingSameRelationship = async () => {
    try {
        const execute = await ApolloClientQuery(
            GET_REPORTING_SAME_RELATIONSHIP,
            {
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        )
        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const GET_REPORTING_SAME_RELATIONSHIP_BY_REPORT = gql`
    query getReportingSameRelationshipByReport {
        getReportingSameRelationshipByReport {
            ... on ReportingData {
                data
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const getReportingSameRelationshipByReport = async (reportId) => {
    try {
        const execute = await ApolloClientQuery(
            GET_REPORTING_SAME_RELATIONSHIP_BY_REPORT,
            {
                id: reportId,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        )
        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const GET_REPORTING_FIELD_IDENTIFIER = gql`
    query getReportingFieldIdentifier {
        getReportingFieldIdentifier {
            ... on ReportingData {
                data
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const getReportingFieldIdentifier = async () => {
    try {
        const execute = await ApolloClientQuery(
            GET_REPORTING_FIELD_IDENTIFIER,
            {
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        )
        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const GET_REPORTING_FIELD_IDENTIFIER_BY_REPORT = gql`
    query getReportingFieldIdentifierByReport {
        getReportingFieldIdentifierByReport {
            ... on ReportingData {
                data
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const getReportingFieldIdentifierByReport = async (reportId) => {
    try {
        const execute = await ApolloClientQuery(
            GET_REPORTING_FIELD_IDENTIFIER_BY_REPORT,
            {
                id: reportId,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        )
        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const GET_REPORTING_REPORT_IDENTIFIER_BY_REPORT = gql`
    query getReportingReportIdentifierByReport($id: String) {
        getReportingReportIdentifierByReport(id: $id) {
            ... on ReportingData {
                data
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const getReportingReportIdentifierByReport = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            GET_REPORTING_REPORT_IDENTIFIER_BY_REPORT,
            {
                id: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        )
        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const GET_REPORTING_AVAILABLE_VALUES = gql`
    query getReportingAvailableValues {
        getReportingAvailableValues {
            ... on ReportingData {
                data
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const getReportingAvailableValues = async (fieldId) => {
    try {
        const execute = await ApolloClientQuery(
            GET_REPORTING_AVAILABLE_VALUES,
            {
                id: fieldId,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        )
        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const UPDATE_REPORT_CELL = gql`
    mutation updateReportCell($reportId: String!, $input: ReportingUpdateCellValuesInput) {
        updateReportCell(reportId: $reportId, input: $input) {
            ... on ReportingData {
                data
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const useUpdateReportCellMutation = (): {
    updateReportCell: any
    data: any
    loading: boolean
    error: any
} => {
    try {
        const [updateReportCellMutation, {data, loading, error}] = useMutation(UPDATE_REPORT_CELL)

        const updateReportCell = (reportId, input) => {
            return updateReportCellMutation({
                variables: {
                    reportId: reportId,
                    input: input,
                },
            })
        }

        return {updateReportCell, data, loading, error}
    } catch (err: any) {
        console.error(err)
        return {
            updateReportCell: null,
            data: null,
            loading: false,
            error: err,
        }
    }
}

const GET_REPORTING_FUNCTIONS = gql`
    query getReportingFunctions {
        getReportingFunctions {
            ... on ReportingDataAry {
                data
                total
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const getReportingFunctions = async () => {
    try {
        const execute = await ApolloClientQuery(
            GET_REPORTING_FUNCTIONS,
            {
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        )
        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const GET_REPORTING_OPERATIONS = gql`
    query getReportingOperations {
        getReportingOperations {
            ... on ReportingDataAry {
                data
                total
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const getReportingOperations = async () => {
    try {
        const execute = await ApolloClientQuery(
            GET_REPORTING_OPERATIONS,
            {
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        )
        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const GET_REPORTING_TYPES = gql`
    query getReportingTypes {
        getReportingTypes {
            ... on ReportingDataAry {
                data
                total
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const getReportingTypes = async () => {
    try {
        const execute = await ApolloClientQuery(
            GET_REPORTING_TYPES,
            {
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        )
        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const GET_REPORTING_TARGER_REPORT_FIELD_DISTINCT_VALUE = gql`
    query getReportingTargetReportFieldDistinctValue($targetReportId: String, $targetFieldKey: String, $platform: String) {
        getReportingTargetReportFieldDistinctValue(targetReportId: $targetReportId, targetFieldKey: $targetFieldKey, platform: $platform) {
            ... on ReportingData {
                data
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const getReportingTargetReportFieldDistinctValue = async (targetReportId, targetFieldKey) => {
    try {
        let tradingPlatform = getConfigByName(BRAND_NAME).tradingPlatform
        const execute = await ApolloClientQuery(
            GET_REPORTING_TARGER_REPORT_FIELD_DISTINCT_VALUE,
            {
                targetReportId: targetReportId || null,
                targetFieldKey: targetFieldKey || null,
                platform: tradingPlatform || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        )
        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const GET_REPORTING_GROUP_BY_ROW_REPORT_SETTING = gql`
    query getReportingGroupByRowReportSetting($reportId: String) {
        getReportingGroupByRowReportSetting(reportId: $reportId) {
            ... on ReportingData {
                data
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const getReportingGroupByRowReportSetting = async (reportId) => {
    try {
        const execute = await ApolloClientQuery(
            GET_REPORTING_GROUP_BY_ROW_REPORT_SETTING,
            {
                reportId: reportId,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        )
        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

// To check
const CREATE_REPORT = gql`
    mutation createReport($input: ReportingCreateInput) {
        createReport(input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on Reporting {
                reportId
                category {
                    name
                    label
                    isEnabled
                }
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const useCreateReportMutation = (): {
    createReport: any
    data: any
    loading: boolean
    error: any
} => {
    try {
        const [createReportMutation, {data, loading, error}] = useMutation(CREATE_REPORT)

        const createReport = (input) => {
            return createReportMutation({
                variables: {
                    input: input,
                },
            })
        }

        return {createReport, data, loading, error}
    } catch (err: any) {
        console.error(err)
        return {
            createReport: null,
            data: null,
            loading: false,
            error: err,
        }
    }
}

const CREATE_GROUP_BY_ROW_REPORT = gql`
    mutation createGroupByRowReport($input: ReportingCreateGroupByRowInput) {
        createGroupByRowReport(input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on Reporting {
                reportId
                category {
                    name
                    label
                    isEnabled
                }
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const createGroupByRowReport = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            CREATE_GROUP_BY_ROW_REPORT,
            {
                input: {
                    // input: input.input || null,
                    name: input.name || null,
                    description: input.description || null,
                    baseReportId: input.baseReportId || null,
                    groupByRowColumnName: input.groupByRowColumnName || null,
                    groupByRowIdentifierColumns: input.groupByRowIdentifierColumns || null,
                    categoryId: input.categoryId || null,
                },
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        )

        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const UPDATE_GROUP_BY_ROW_REPORT = gql`
    mutation updateGroupByRowReport($id: String, $input: ReportingUpdateGroupByRowInput) {
        updateGroupByRowReport(id: $id, input: $input) {
            ... on ReportingData {
                data
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const updateGroupByRowReport = async (id, input) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_GROUP_BY_ROW_REPORT,
            {
                id: id,
                input: {
                    name: input.name || null,
                    description: input.description || null,
                    groupByRowColumnName: input.groupByRowColumnName || null,
                    groupByRowIdentifierColumns: input.groupByRowIdentifierColumns || null,
                },
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        )

        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const UPDATE_REPORT = gql`
    mutation updateReport($id: String, $input: ReportingUpdateInput) {
        updateReport(id: $id, input: $input) {
            ... on ReportingData {
                data
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const useUpdateReportMutation = (): {
    updateReport: any
    data: any
    loading: boolean
    error: any
} => {
    try {
        const [updateReportMutation, {data, loading, error}] = useMutation(UPDATE_REPORT)

        const updateReport = (id, input) => {
            return updateReportMutation({
                variables: {
                    id: id,
                    input: input,
                },
            })
        }

        return {updateReport, data, loading, error}
    } catch (err: any) {
        console.error(err)
        return {
            updateReport: null,
            data: null,
            loading: false,
            error: err,
        }
    }
}

const CHANGE_REPORT_GROUP_BY_BASE = gql`
    mutation changeReportGroupByBase($input: ReportingChangeGroupByBaseInput) {
        changeReportGroupByBase(input: $input) {
            ... on ReportingData {
                data
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const useChangeReportGroupByBaseMutation = (): {
    changeReportGroupByBase: any
    data: any
    loading: boolean
    error: any
} => {
    try {
        const [changeReportGroupByBaseMutation, {data, loading, error}] = useMutation(CHANGE_REPORT_GROUP_BY_BASE)

        const changeReportGroupByBase = (input) => {
            return changeReportGroupByBaseMutation({
                variables: {
                    input: input,
                },
            })
        }

        return {changeReportGroupByBase, data, loading, error}
    } catch (err: any) {
        console.error(err)
        return {
            changeReportGroupByBase: null,
            data: null,
            loading: false,
            error: err,
        }
    }
}

const REPOPULATE_REPORT_DATA = gql`
    mutation repopulateReportData($input: ReportingRepopulateReportDataInput) {
        repopulateReportData(input: $input) {
            ... on ReportingData {
                data
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const repopulateReportData = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            REPOPULATE_REPORT_DATA,
            {
                input: {
                    fromDate: input.fromDate || null,
                    toDate: input.toDate || null,
                    populationPath: input.populationPath || null,
                },
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        )

        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const MULTI_GROUP_BY_REPORT = gql`
    mutation multiGroupByReport($baseReportIds: [String]) {
        multiGroupByReport(baseReportIds: $baseReportIds) {
            ... on ReportingData {
                data
            }
            ... on BaseError {
                errObj
            }
        }
    }
`

const useMultiGroupByReportMutation = (): {
    multiGroupByReport: any
    data: any
    loading: boolean
    error: any
} => {
    try {
        const [multiGroupByReportMutation, {data, loading, error}] = useMutation(MULTI_GROUP_BY_REPORT)

        const multiGroupByReport = (baseReportIds) => {
            return multiGroupByReportMutation({
                variables: {
                    baseReportIds: baseReportIds,
                },
            })
        }

        return {multiGroupByReport, data, loading, error}
    } catch (err: any) {
        console.error(err)
        return {
            multiGroupByReport: null,
            data: null,
            loading: false,
            error: err,
        }
    }
}

const UPDATE_TRADING_ACCOUNT_TRANSACTION_TYPE = gql`
    mutation updateTradingAccountTransactionType($input: [ReportingUpdateTradingAcccountTransactionTypeInput]) {
        updateTradingAccountTransactionType(input: $input) {
            ... on ReportingData {
                data
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

const updateTradingAccountTransactionType = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_TRADING_ACCOUNT_TRANSACTION_TYPE,
            {
                input: input,
            },
            true
        )

        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const REPOPULATE_TRADING_PL_REPORT_BY_TRADER = gql`
    mutation repopulateTradingPlReportByTrader($tradingAccountId: String, $fromDate: String) {
        repopulateTradingPlReportByTrader(tradingAccountId: $tradingAccountId, fromDate: $fromDate) {
            ... on ReportingData {
                data
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

const repopulateTradingPlReportByTrader = async (tradingAccountId, fromDate) => {
    try {
        const execute = await ApolloClientQuery(
            REPOPULATE_TRADING_PL_REPORT_BY_TRADER,
            {
                tradingAccountId: tradingAccountId,
                fromDate: fromDate,
            },
            true
        )

        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const UPDATE_CLOSED_POSITION_REPORT_COMMISSION_STATUS = gql`
    mutation updateClosedPositionReportCommissionStatus($input: ReportingUpdateCellValuesInput) {
        updateClosedPositionReportCommissionStatus(input: $input)
    }
`

const updateClosedPositionReportCommissionStatus = async (input) => {
    const variables = {
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    }

    const execute = await ApolloClientQuery(UPDATE_CLOSED_POSITION_REPORT_COMMISSION_STATUS, variables, true)

    return execute
}

export {
    getReportingAll,
    getReportingDetails,
    getReportingBalanceHistory,
    getReportingRelationship,
    getReportingLinkToRelationship,
    getReportingBaseReportId,
    getReportingGroupByBaseReportId,
    getReportingLastFunctionField,
    getReportingValidateOperation,
    getReportingFields,
    getReportingFieldsByReportId,
    getReportingReportIdentifier,
    getReportingReportIdentifierByReport,
    getReportingSameRelationship,
    getReportingSameRelationshipByReport,
    getReportingFieldIdentifier,
    getReportingFieldIdentifierByReport,
    getReportingAvailableValues,
    useUpdateReportCellMutation,
    getReportingFunctions,
    getReportingOperations,
    getReportingTypes,
    getReportingTargetReportFieldDistinctValue,
    getReportingGroupByRowReportSetting,
    useCreateReportMutation,
    useUpdateReportMutation,
    createGroupByRowReport,
    updateGroupByRowReport,
    useChangeReportGroupByBaseMutation,
    useMultiGroupByReportMutation,
    repopulateReportData,
    getReportingSummary,
    updateTradingAccountTransactionType,
    repopulateTradingPlReportByTrader,
    updateClosedPositionReportCommissionStatus
}
