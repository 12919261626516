import React, {useState} from "react";
import TableAntDesign from "../../../../components/table/tableAntDesign";
import {MessageOutboxTableColumns, NotificationOutboxTableColumns} from "../../function/tableColumns";
import {useTranslation} from "react-i18next";
import {Button, Col, Empty, Modal, Row, Segmented, Select, Tabs, Tag} from "antd";
import {useRecoilValue} from "recoil";
import {languageOptionsState, userOptionsState} from "../../../users/_common/state";
import TemplatePreview from "../../../settings/pushNotificationTemplateEditor/view/layout1/component/templatePreview";
import {getPushNotificationTemplateById} from "../../../../api/graphql/pushNotificationTemplate";


const Outbox = ({
                    emailLogData,
                    emailLogTotal,
                    emailLogProps,
                    setEmailLogProps,
                    emailTemplate,
                    pushNotificationLogData,
                    pushNotificationLogTotal,
                    pushNotificationLogProps,
                    setPushNotificationLogProps,
                    pushNotificationTemplate,
                    imageVarData,
                    textVarData,
                }) => {
    const {t} = useTranslation();
    const [viewHTML, setViewHTML] = useState<any>(null);
    const [selectedRecord, setSelectedRecord] = useState<any>(null);
    const languageOptions = useRecoilValue<any>(languageOptionsState)
    const userOptions = useRecoilValue<any>(userOptionsState);
    const [type, setType] = useState<any>(1)
    const [deviceType, setDeviceType] = useState("android");
    const [currentPage, setCurrentPage] = useState(1)
    let allFetchedPushNotificationTemplate = pushNotificationTemplate

    const handleDeviceTypeChg = (value: string) => {
        setDeviceType(value);
    };

    const handleCancel = () => {
        setViewHTML(null)
        setSelectedRecord(null)
    }

    const tabItems = languageOptions?.map((lang) => {
        let object, html, subject, className
        switch (type) {
            case 1:
            default:
                object = viewHTML?.htmlObject?.find(d => d?.language === lang?.value)
                html = viewHTML?.htmlObject?.find(d => d?.language === lang?.value)?.html
                subject = viewHTML?.displaySubjectLanguage?.find(d => d?.language === lang?.value)?.label
                className = "emailViewModal"
                break
            case 2:
                object = viewHTML?.dataArrayOfObject?.find(d => d?.language === lang?.value)
                html = viewHTML?.dataArrayOfObject?.find(d => d?.language === lang?.value)?.longContent
                subject = viewHTML?.name
                className = "pushNotificationViewModal"
                break
        }

        const displayName = () => {
            if (subject && subject?.includes("- Edited")) {
                const originalName = subject?.split("- Edited")[0]?.trim();

                return (
                    <>
                        {originalName}<Tag className={"edited-tag"} style={{marginInlineStart: "0.5rem"}}>Edited</Tag>
                    </>
                );
            }
            return subject;
        }

        return {
            key: lang?.value,
            label: lang?.label,
            children: (
                <Row className={`margin-top-0-75 ${className}`}>
                    <Col span={24}>
                        <h1>{type === 1 ? t("message.subject") : t("message.from_template")}: {displayName()}</h1>
                    </Col>
                    {!!viewHTML ? (
                        <>
                            {type === 2 && (
                                <Col span={24}>
                                    <Col
                                        span={24}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            margin: "1rem",
                                        }}
                                    >
                                        <Select
                                            // showSearch
                                            value={deviceType}
                                            onChange={handleDeviceTypeChg}
                                            placeholder={t("Component Type")}
                                            options={[
                                                {label: t("Android"), value: "android"},
                                                {label: t("iPhone"), value: "iphone"},
                                                {label: t("Windows"), value: "windows"},
                                                {label: t("MacOS"), value: "macos"},
                                            ]}
                                            style={{width: 150}}
                                        />
                                    </Col>
                                    <Col
                                        span={24}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            margin: "1rem",
                                        }}
                                    >
                                        <TemplatePreview
                                            notificationData={object}
                                            deviceType={deviceType}
                                            languageCode={lang?.code}
                                            imageVarData={imageVarData}
                                            textVarData={textVarData}
                                        />
                                    </Col>
                                </Col>
                            )}
                            <Col span={24} className={"tinyMceContainer"}>
                                <div className={"tinyMceParagraph margin-top-0-75"}
                                     dangerouslySetInnerHTML={{__html: html}}/>
                            </Col>
                        </>
                    ) : (
                        <Empty style={{width: "100%"}}/>
                    )}
                </Row>
            )
        }
    })

    const onChangePageHandler = (page, pageSize) => {
        setCurrentPage(page)
        switch (type) {
            case 1:
                setEmailLogProps(prev => ({...prev, limit: pageSize, offset: prev.limit * (page - 1)}))
                break
            case 2:
                setPushNotificationLogProps(prev => ({...prev, limit: pageSize, offset: pageSize * (page - 1)}))
                break
        }
    }

    const segmentedOptions = [
        {label: "Email", value: 1},
        {label: "Notification", value: 2}
    ]

    return (
        <div id={"outbox"}>
            <Segmented className={"margin-bottom-0-5"} options={segmentedOptions} onChange={(value) => setType(value)}/>
            {type === 1 && (
                <>
                    <TableAntDesign
                        data={emailLogData}
                        columns={[...MessageOutboxTableColumns(emailTemplate),
                            {
                                label: t("common.action"),
                                key: "action",
                                render: (_, record) => {
                                    return (
                                        <Button
                                            size={"small"}
                                            onClick={() => {
                                                const found = emailTemplate?.find(d => d?.name === record?.fromTemplate?.name)
                                                setSelectedRecord(record)
                                                setViewHTML(found)
                                            }}
                                        >
                                            {t("common.view")}
                                        </Button>
                                    )
                                }
                            }
                        ]}
                        tableName={"Outbox"}
                        pagination={{
                            current: currentPage,
                            pageSize: emailLogProps.limit,
                            showSizeChanger: true,
                            total: emailLogTotal,
                            onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                            showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
                        }}
                    />
                    <Modal
                        width={900}
                        open={!!selectedRecord}
                        onCancel={handleCancel}
                        closeIcon={false}
                        footer={null}
                        destroyOnClose
                    >
                        <Row gutter={16}>
                            <Col span={2}>
                                <h1>{t("message.to")}:</h1>
                            </Col>
                            <Col span={22}>
                                {selectedRecord?.to?.map(user => (
                                    <Tag
                                        className={"margin-bottom-0-3"}
                                        key={user?.userId || user?.email}
                                    >
                                        {user?.email}
                                    </Tag>
                                ))}
                            </Col>
                        </Row>
                        <Tabs items={tabItems}/>
                    </Modal>
                </>
            )}
            {type === 2 && (
                <>
                    <TableAntDesign
                        data={pushNotificationLogData}
                        columns={[...NotificationOutboxTableColumns(),
                            {
                                label: t("common.action"),
                                key: "action",
                                render: (_, record) => {
                                    return (
                                        <Button
                                            size={"small"}
                                            onClick={async () => {
                                                let found = allFetchedPushNotificationTemplate?.find(d => d?._id === record?.fromTemplate?._id);

                                                if (!found) {
                                                    const response = await getPushNotificationTemplateById(record?.fromTemplate?._id);
                                                    found = response?.getPushNotificationTemplateById;

                                                    if (found && !allFetchedPushNotificationTemplate.some(d => d._id === found?._id)) {
                                                        allFetchedPushNotificationTemplate = [...allFetchedPushNotificationTemplate, found];
                                                    }
                                                }

                                                setSelectedRecord(record);
                                                setViewHTML(found);
                                            }}
                                        >
                                            {t("common.view")}
                                        </Button>
                                    )
                                }
                            }
                        ]}
                        tableName={"Outbox"}
                        pagination={{
                            current: currentPage,
                            pageSize: pushNotificationLogProps.limit,
                            showSizeChanger: true,
                            total: pushNotificationLogTotal,
                            onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                            showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
                        }}
                    />
                    <Modal
                        width={900}
                        open={!!selectedRecord}
                        onCancel={handleCancel}
                        closeIcon={false}
                        footer={null}
                        destroyOnClose
                    >
                        <Row gutter={16}>
                            <Col span={2}>
                                <h1>{t("message.to")}:</h1>
                            </Col>
                            <Col span={22}>
                                {selectedRecord?.user?.map(user => {
                                    const mappedTag = userOptions.find(u => u.value === user)
                                    return (
                                        <Tag
                                            className={"margin-bottom-0-3"}
                                            key={mappedTag?.value}
                                        >
                                            {mappedTag?.selectedLabel}
                                        </Tag>
                                    )
                                })}
                            </Col>
                        </Row>
                        <Tabs items={tabItems}/>
                    </Modal>
                </>
            )}
        </div>
    )
}

export default Outbox