import React from "react";
import InvalidPermisson_1 from "./view/layout1";

function InvalidPermisson() {
  let layout = 1;
  let component;

  switch (layout) {
    case 1:
      component = <InvalidPermisson_1 />;
      break;

    default:
      component = <InvalidPermisson_1 />;
      break;
  }

  return component;
}

export default InvalidPermisson;
