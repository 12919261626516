const convertLanguageFile = (record) => {
    let resources: any = {};
    record?.forEach((item) => {
      resources[item.code] = { translation: item.translationFile };
    });

    return resources;
  };
  
  export { convertLanguageFile };
  