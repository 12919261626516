import React, {useEffect, useState} from "react";
import {Tabs} from "antd";
import {useTranslation} from "react-i18next";
import AnnouncementListing from "../components/announcementListing";
import {useSetRecoilState} from "recoil";
import {unreadAnnouncementCountState} from "../../../../../recoil_state";
import {getUserAnnouncementByUserId} from "../../../../api/graphql/announcement";
import PushNotificationListing from "../components/pushNotificationListing";

const Announcement_1 = (props) => {
    const {isHeader} = props
    const {t} = useTranslation();
    const [activeTab, setActiveTab] = useState("1");
    const setUnreadAnnouncementCount = useSetRecoilState(unreadAnnouncementCountState);
    const [total, setTotal] = useState(0)
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false)

    const userId = localStorage.getItem("userId")

    const preload = async () => {
        setLoading(true)
        const response = await getUserAnnouncementByUserId(userId, 8, 0);
        setData(response?.getUserAnnouncementByUserId?.data);
        setUnreadAnnouncementCount(response?.getUserAnnouncementByUserId?.unreadTotal);
        setTotal(response?.getUserAnnouncementByUserId?.total);
        setLoading(false)
    }

    useEffect(() => {
        preload()
    }, [])

    const tabItems: any = [
        {
            label: t("Announcement"),
            key: "1",
            children: <AnnouncementListing isHeader={isHeader} data={data} total={total} loading={loading}/>,
        },
        {
            label:  t("Push Notification"),
            key: "2",
            children: <PushNotificationListing isHeader={isHeader}/>,
        },
    ]

    return (
        <div id={"announcement"}>
            <div className="announcement-tab-container">
                <Tabs
                    items={tabItems}
                    activeKey={activeTab}
                    size={"small"}
                    defaultActiveKey={"1"}
                    onChange={(e) => setActiveTab(e)}
                />
            </div>
            {/*<SendMessageModal isOpen={modalIsOpen} handleCancel={() => setModalIsOpen(false)}/>*/}
        </div>
    )
}

export default Announcement_1