import React, {useRef} from "react";
import {Editor} from "@tinymce/tinymce-react";
import {getConfigByName} from "../../../../../../../config";
import {BRAND_NAME} from "../../../../../../../config/brandVariable";

const RichTextEditor = (props) => {
    const {formMode, langaugeCode, editorState, onEditorStateChange} = props;
    const editorRef = useRef<any>(null);
    const brandConfig = getConfigByName(BRAND_NAME);

    return (
        <>
            <Editor
                disabled={formMode === "view"}
                onInit={(_, editor) => (editorRef.current = editor)}
                value={editorState}
                apiKey={brandConfig?.tinyMCEApiKey}
                init={{
                    height: 500,
                    menubar: true,
                    plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount",
                    ],
                    toolbar:
                        "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media component link anchor codesample | ltr rtl",
                    content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
                onEditorChange={(value) => onEditorStateChange(langaugeCode, value)}
            />
        </>
    );
};

export default RichTextEditor;
