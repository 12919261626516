import {gql, useMutation, useQuery} from '@apollo/client'
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {useTranslation} from "react-i18next";

const GET_ASSET_COUNT = gql`
  query getAssetsCount($filter: AssetsSearchInput) {
    getAssetsCount(filter: $filter)
  }
`;

const getAssetCount = ({filter}) => {
    try {
        const {loading, error, data} = useQuery(GET_ASSET_COUNT, {
            variables: {
                filter: {
                    assetId: filter?.assetId ?? null,
                    name: filter?.name ?? null,
                    serverName: filter?.serverName ?? null,
                    description: filter?.description ?? null,
                    type: filter?.type ?? null,
                    depositAsset: filter?.depositAsset ?? null,
                    depositable: filter?.depositable ?? null,
                    displayName: filter?.displayName ?? null,
                    major: filter?.major ?? null,
                    digits: filter?.digits ?? null,
                },
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                },
            }
        });

        return {loading, error, data}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const GET_ASSET_BY_ID = gql`
  query getAssetByID($id: String!) {
    getAssetByID(id: $id){
    ... on Assets {
      id
      assetId
      name
      description
      type
      depositAsset
      depositable
      displayName
      major
      digits
      calendarAlias
      server {
        name
      }
      createdBy {
        username
      }
      updatedBy {
        username
      }
      createdAt
      updatedAt
    }
    ... on BaseError {
      errKey
      errMsg
    }
  }
}
`;

const getAssetById = (id) => {
    try {
        const {loading, error, data} = useQuery(GET_ASSET_BY_ID, {
            variables: {
                id: id
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, data}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}


const GET_ASSET = gql`
  query getAssets($filter: AssetsSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
    getAssets(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy){
     ... on AssetsArray {
          data {
            id
            assetId
            name
            description
            type
            depositAsset
            depositable
            displayName
            major
            digits
            calendarAlias
            server { name }
            createdBy { username }
            updatedBy { username }
            createdAt
            updatedAt
          }
        }
        ... on BaseError {
          errKey
          errMsg
        }
      }
    }
`;

const getAsset = ({filter}, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data} = useQuery(GET_ASSET, {
            variables: {
                filter: {
                    assetId: filter?.assetId ?? null,
                    name: filter?.name ?? null,
                    serverName: filter?.serverName ?? null,
                    description: filter?.description ?? null,
                    type: filter?.type ?? null,
                    depositAsset: filter?.depositAsset ?? null,
                    depositable: filter?.depositable ?? null,
                    displayName: filter?.displayName ?? null,
                    major: filter?.major ?? null,
                    digits: filter?.digits ?? null,
                },
                limit: limit ?? null,
                offset: offset ?? null,
                orderBy: orderBy ?? null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, data}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const REFRESH_ASSET = gql`
    mutation refreshAssets($server: String!) {
        refreshAssets(server: $server){
         ... on AssetsArray {
              data {
                id
                assetId
                name
                description
                type
                depositAsset
                depositable
                displayName
                major
                digits
                calendarAlias
                server { name }
                createdBy { username }
                updatedBy { username }
                createdAt
                updatedAt
              }
            }
            ... on BaseError {
              errKey
              errMsg
            }
          }
        }
    `;

const useRefreshAssetMutation = (): { refreshAssets: any, data: any, loading: boolean, error: any } => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [useRefreshAssetMutation, {data, loading, error}] = useMutation(REFRESH_ASSET);

        const refreshAssets = (server) => {
            return useRefreshAssetMutation({
                variables: {
                    server: server,
                },
                update(cache, {data: {refreshAssets}}) {
                    const typename = refreshAssets?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(refreshAssets?.errKey),
                            key: "refreshAssets",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Refresh Assets Successfully"),
                            key: "refreshAssets",
                        });
                    }
                },
                refetchQueries: [GET_ASSET, GET_ASSET_COUNT]
            });
        };

        return {refreshAssets, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {refreshAssets: null, data: null, loading: false, error: err};
    }
}

export {
    getAssetCount,
    getAssetById,
    getAsset,
    useRefreshAssetMutation
}