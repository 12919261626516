import React, {useEffect} from "react";
import {Routes, Route} from "react-router-dom";
import {useRecoilState} from "recoil";
import {protectedRoute} from "./protectedRoute";
import {publicRoute} from "./publicRoute";

import {Layout_1, LayoutLogin_1} from "../layouts";
import {withLazyComponent} from "./lazyComponent";
import {selfPermissionList, selfPermissionLoading, languageDataLoading} from "../../recoil_state";
import InvalidPermisson from "../components/invalidPermission";
import LoadingScreen from "../../brand/_auth/loadingScene";
import {checkIsMasterAdmin} from "../api/graphql/user";

const Page404 = withLazyComponent(React.lazy(() => import("../pages/error/page404")));

const RouterComponent = () => {
    const [selfPermission] = useRecoilState<any>(selfPermissionList);
    const [permissionLoading] = useRecoilState<any>(selfPermissionLoading);
    const [languageLoading] = useRecoilState<any>(languageDataLoading);
    const {isMasterAdmin} = checkIsMasterAdmin(localStorage.getItem("userId"));

    useEffect(() => {
        if (isMasterAdmin) protectedRoute.push(
            {
                path: "/developer",
                element: withLazyComponent(React.lazy(() => import("../pages/developer/developer"))),
            }
        )
    }, [isMasterAdmin]);

    return (
        <Routes>
            <Route path="/" element={<Layout_1/>}>
                {protectedRoute &&
                    protectedRoute.map((item, index) => {
                        let isAllowedAccess = item?.permission ? selfPermission?.includes(item?.permission) : true;
                        return (
                            <Route
                                key={index}
                                path={item.path}
                                element={
                                    (permissionLoading || languageLoading) ? (
                                        <LoadingScreen/>
                                    ) : isAllowedAccess ? (
                                        <item.element/>
                                    ) : (
                                        <InvalidPermisson/>
                                    )
                                }
                            />
                        );
                    })}
                <Route path="*" element={<Page404/>}/>
            </Route>

            <Route path="/" element={<LayoutLogin_1/>}>
                {publicRoute &&
                    publicRoute.map((item, index) => {
                        return (
                            <Route
                                key={index}
                                path={item.path}
                                element={
                                    languageLoading ? (
                                        <LoadingScreen/>
                                    ) : (
                                        <item.element/>
                                    )
                                }
                            />
                        )
                    })}
            </Route>
        </Routes>
    );
};

export default RouterComponent;
