

export function formatMoney(amount: any, digits = 2){
    let amountParse = parseFloat(amount)
    return (amountParse)?.toFixed(digits)?.replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export const formatValue = (value) => {
    if (value === '') return value; // Return empty string as is
    const number = parseFloat(value);
    if (isNaN(number)) return ''; // Return empty string for invalid input
    return number.toFixed(2); // Format to two decimal places
};

// Helper function to parse the input value back to a valid number
export const parseValue = (value) => {
    // Remove all non-numeric and non-decimal characters
    const cleanValue = value.replace(/[^\d.]/g, '');
    const number = parseFloat(cleanValue);
    if (isNaN(number)) return ''; // Return empty string for invalid input
    return number.toFixed(2); // Format to two decimal places
};