import React, {useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import {useRecoilState} from "recoil";
import AnnouncementEditor_1 from "./view/layout1";
import {getLanguage} from "../../../api/graphql/language";
import {sortEn} from "../../../function/language";
import {getDynamicVariable} from "../../../api/graphql/dynamicVariable";
import {selfPermissionObj} from "../../../../recoil_state";
import useAuthorize from "../../../_common/authorize";
import "./css/common/index.css"
import {getAnnouncementById, updateAnnouncement} from "../../../api/graphql/announcement";

function AnnouncementEditor() {
    let layout = 1;
    let component;
    const {announcementId} = useParams();
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const announcementPermission = selfPermission?.messageSetting?.announcement;
    const dynamicVariablePermission = selfPermission?.messageSetting?.dynamicVariable;
    const [searchParams, setSearchParams] = useSearchParams();
    const mode = searchParams.get("mode");
    const [updateLoading, setUpdateLoading] = useState(false)

    const data = getAnnouncementById(announcementId);

    const {languageData} = getLanguage({filter: {isActive: true}});
    const {variableData: imageVarData} = getDynamicVariable({
        filter: {variableType: "image", isActive: true},
    });
    const {variableData: textVarData} = getDynamicVariable({
        filter: {variableType: "text", isActive: true},
    });
    const {variableData: pdfVarData} = getDynamicVariable({
        filter: {variableType: "pdf", isActive: true},
    });
    const {variableData: reportTableVarData} = getDynamicVariable({
        filter: {variableType: "reportTable", isActive: true},
    });
    const {systemErrorNotification} = useAuthorize();

    const handleAnnouncementUpdate = async (input) => {
        try {
            setUpdateLoading(true)
            await updateAnnouncement(announcementId, input)
            setUpdateLoading(false)
        } catch (e: any) {
            console.log(e);
            systemErrorNotification();
        }
    };

    const amendedImageVarData = dynamicVariablePermission?.view ? imageVarData?.getDynamicVariable?.data : [];

    switch (layout) {
        case 1:
            component = (
                <AnnouncementEditor_1
                    mode={mode}
                    data={data}
                    imageVarData={amendedImageVarData}
                    textVarData={textVarData?.getDynamicVariable?.data}
                    pdfVarData={pdfVarData?.getDynamicVariable?.data}
                    reportTableVarData={reportTableVarData?.getDynamicVariable?.data}
                    languageData={sortEn(languageData?.getLanguage?.data)}
                    handleAnnouncementUpdate={handleAnnouncementUpdate}
                    announcementPermission={announcementPermission}
                    updateLoading={updateLoading}
                />
            );
            break;

        default:
            component = (
                <AnnouncementEditor_1
                    data={data}
                    imageVarData={amendedImageVarData}
                    textVarData={textVarData?.getDynamicVariable?.data}
                    pdfVarData={pdfVarData?.getDynamicVariable?.data}
                    reportTableVarData={reportTableVarData?.getDynamicVariable?.data}
                    languageData={sortEn(languageData?.getLanguage?.data)}
                    handleAnnouncementUpdate={handleAnnouncementUpdate}
                    announcementPermission={announcementPermission}
                    updateLoading={updateLoading}
                />
            );
            break;
    }

    return component;
}

export default AnnouncementEditor;
