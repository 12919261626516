import React, {useEffect} from "react";
import {Col, Form, Input, Modal, Row, Select} from "antd";
import {createFolder} from "../../../../../../api/restful/arena/media_folder";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../recoil_state";

const CreateFolderModal = ({open, handleClose, refetchFolder, currentPath, folderOption}) => {
    const [form] = Form.useForm()
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    useEffect(() => {
        if (open) {
            form.setFieldsValue({name: ""})
        }
    },[open, currentPath])

    const onCreateFolder = async ({name}) => {
        try {
            const response = await createFolder({name, parent: currentPath.at(-1)?._id || null})
            if (response.status === "Success") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: `Create Folder Successfully`,
                    key: "arenaCreateFolderNotification",
                })

                refetchFolder()
                handleClose()
            } else {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: `Create Folder Failed: ${response?.errObj}`,
                    key: "arenaCreateFolderNotification",
                })
            }
        } catch (e) {}
    }

    return (
        <Modal
            title={"Create New Folder"}
            open={open}
            onCancel={handleClose}
            okText={"Create"}
            width={350}
            closeIcon={false}
            onOk={form.submit}
            centered={true}
        >
            <Form layout={"vertical"} form={form} onFinish={onCreateFolder} className={"margin-bottom-0-75"}>
                <Row gutter={[8, 8]}>
                    <Col span={24}>
                        <Form.Item rules={[{required: true}]} label={"Name"} name={"name"}>
                            <Input placeholder={"Folder Name"}/>
                        </Form.Item>
                    </Col>
                    {/*<Col span={24}>*/}
                    {/*    <Form.Item label={"Parent"} name={"parent"}>*/}
                    {/*        <Select options={folderOption} className={"margin-bottom-0-75"}/>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                </Row>
            </Form>
        </Modal>
    )
}

export default CreateFolderModal