import React from "react";
import CrmUsers_1 from "./view/layout1";
import {getProfileInformationSetting} from "../../../../api/graphql/profileInformation";
import {useRecoilValue} from "recoil";
import {selfPermissionObj} from "../../../../../recoil_state";

function CrmUsers() {
    let layout = 1;
    let component;
    const tableName = "CRM Users"

    const searchParams = new URLSearchParams(location.search);
    const roleId = searchParams.get('role');
    const commRule = searchParams.get('commRule');
    const permission = useRecoilValue<any>(selfPermissionObj);
    const {data:profileInformation} = getProfileInformationSetting({type: "crmUser"})
    const filteredProfileInformation = profileInformation?.getProfileInformationSetting?.data?.filter(d => d?.position !== null)

    switch (layout) {
        case 1:
            component = (
                <CrmUsers_1 tableName={tableName} roleId={roleId} commRule={commRule} profileInformation={filteredProfileInformation} permissionUser={permission?.user}/>
            );
            break;

        default:
            component = (
                <CrmUsers_1 tableName={tableName} roleId={roleId} commRule={commRule} profileInformation={filteredProfileInformation} permissionUser={permission?.user}/>
            );
            break;
    }

    return component;
};

export default CrmUsers;
