import React, {useEffect, useMemo, useState} from "react"
import {Button, Divider, Modal} from "antd"
import {FormProvider, useForm} from "react-hook-form"
import {useSetRecoilState} from "recoil"
import {Row, Col, Form} from "antd"
import {CloseOutlined, MinusOutlined, PlusOutlined, SaveOutlined} from "@ant-design/icons"
import {useTranslation} from "react-i18next"
import * as yup from "yup"
import {useRecoilValue} from "recoil"
import {yupResolver} from "@hookform/resolvers/yup"
import InputFormItem from "../../../../../../components/form/inputFormItem"
import {horizontalFormLayout} from "../../../../../../components/form/formWrapper"
import {reportingAllState, reportingCategoryState} from "../../../state"
import {createGroupByRowReport, getReportingFieldsByReportId, getReportingGroupByRowReportSetting, getReportingTargetReportFieldDistinctValue, updateGroupByRowReport} from "../../../../../../api/graphql/reporting"
import yupFormSchema from "../../../../../../components/yup/yupFormSchema"
import SelectFormItem from "../../../../../../components/form/selectFormItem"
import {notificationMessage} from "../../../../../../../recoil_state"
import {getUserTableAdminSetting, useUpdateUserTableAdminSetting} from "../../../../../../api/graphql/tableSetting"
import {removeTypenameKey} from "../../../../../../function/_common"

const labelCol = {span: 8, offset: 0}

const GroupByRowReportModal = (props) => {
    const {formMode, isModalOpen, toggleModal, selectedReportID, setSelectedReportID, setSelectedCategoryID, reportSummaryData} = props

    const yupSchema = yup.object().shape({
        name: yupFormSchema.string("name", {required: true}),
        description: yupFormSchema.string("description", {required: false}),
        groupByRowColumnName: yupFormSchema.string("groupByRowColumnName", {required: true}),
        groupByRowColumnFieldKey: yupFormSchema.string("groupByRowColumnFieldKey", {required: true}),
    })

    const {t} = useTranslation()
    const setNotificationMessage = useSetRecoilState(notificationMessage)
    const reportingCategory = useRecoilValue<any>(reportingCategoryState)
    const reportingAll = useRecoilValue<any>(reportingAllState)

    const {updateUserTableAdminSetting} = useUpdateUserTableAdminSetting()
    const {data: baseReportAdminSettingTable} = getUserTableAdminSetting({
        filter: {
            tableName: selectedReportID,
            serverName: null,
        },
    })

    const [submitLoading, setSubmitLoading] = useState(false)
    const [groupByColumnFieldKey, setGroupByColumnFieldKey] = useState("")
    const [reportFields, setReportFields] = useState<any>([])
    const [groupByRowReportSetting, setGroupByRowReportSetting] = useState<any>({})
    const [targetReportFieldDistinctValue, setTargetReportFieldDistinctValue] = useState([])
    const [distinctValueOptionLoading, setDistinctValueOptionLoading] = useState(false)
    const [groups, setGroups] = useState([
        {
            name: "",
            description: "",
            groupByRowIdentifier: {
                fieldValues: [],
            },
        },
    ])

    const [initialValues] = useState<any>(() => ({
        categoryId: null,
        name: "",
        description: "",
    }))

    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
        defaultValues: initialValues,
    })

    const getGroupByRowReportSetting = async () => {
        try {
            if (selectedReportID) {
                const response = await getReportingGroupByRowReportSetting(selectedReportID)
                if (response?.getReportingGroupByRowReportSetting?.__typename === "ReportingData") {
                    setGroupByRowReportSetting(response?.getReportingGroupByRowReportSetting?.data?.[0])
                }
            }
        } catch (err) {
            console.log(err)
        } finally {
        }
    }

    const getReportFields = async () => {
        try {
            let targetReportId = formMode === "add" ? selectedReportID : groupByRowReportSetting?.targetReportId
            if (targetReportId) {
                const response = await getReportingFieldsByReportId(targetReportId)
                if (response?.getReportingFieldsByReportId?.__typename === "ReportingDataAry") {
                    setReportFields(response?.getReportingFieldsByReportId?.data)
                }
            }
        } catch (err) {
            console.log(err)
        } finally {
        }
    }

    const getReportTargetReportFieldDistinctValue = async () => {
        try {
            let targetReportId = formMode === "add" ? selectedReportID : groupByRowReportSetting?.targetReportId
            setDistinctValueOptionLoading(true)
            const response = await getReportingTargetReportFieldDistinctValue(targetReportId, groupByColumnFieldKey)
            let data = response?.getReportingTargetReportFieldDistinctValue?.data?.distinctIdentifiers
            setTargetReportFieldDistinctValue(data)
        } catch (error) {
        } finally {
            setDistinctValueOptionLoading(false)
        }
    }

    const addGroup = (index) => {
        setGroups((prevState) => {
            const newGroup = {
                name: "",
                description: "",
                groupByRowIdentifier: {
                    fieldValues: [],
                },
            }

            form.setValue(`groups.${index + 1}.name`, "")
            form.setValue(`groups.${index + 1}.description`, "")
            form.setValue(`groups.${index + 1}.groupByRowIdentifier.fieldValues`, [])

            const newArray = [...prevState]
            newArray.splice(index + 1, 0, newGroup) // Insert newGroup after the specified index

            return newArray
        })
    }

    const deleteGroup = (index) => {
        try {
            setGroups((prevState) => prevState.filter((_, i) => i !== index))

            form.setValue(`groups.${index}.name`, groups?.[index + 1]?.name)
            form.setValue(`groups.${index}.description`, groups?.[index + 1]?.description)
            form.setValue(`groups.${index}.groupByRowIdentifier.fieldValues`, groups?.[index + 1]?.groupByRowIdentifier.fieldValues)
        } catch (error) {}
    }

    const handleGroupChange = (field, index, value) => {
        setGroups((prevState) =>
            prevState.map((group, i) =>
                i === index
                    ? field === "groupByRowIdentifier.fieldValues"
                        ? {
                              ...group,
                              groupByRowIdentifier: {
                                  fieldValues: value,
                              },
                          }
                        : {...group, [field]: value}
                    : group
            )
        )
    }
    
    const onSubmit = async () => {
        try {
            setSubmitLoading(true)
            const formValues = form.getValues()
            let amendedData = groups
            if (formMode === "add") {
                amendedData = groups?.map((group, i) => ({
                    ...group,
                    fieldKey: groupByColumnFieldKey,
                }))
            }
            const input = {
                name: formValues?.name,
                description: formValues?.description,
                groupByRowColumnName: formValues?.groupByRowColumnName,
                groupByRowIdentifierColumns: amendedData,
                ...(formMode === "add" && {
                    categoryId: formValues?.categoryId,
                    baseReportId: selectedReportID,
                }),
            }

            const response = formMode === "add" ? await createGroupByRowReport(input) : await updateGroupByRowReport(selectedReportID, input)
            const resError = response?.createGroupByRowReport?.__typename === "BaseError" || response?.updateGroupByRowReport?.__typename === "BaseError"
            if (response?.createGroupByRowReport?.__typename === "Reporting") {
                // createGroupByRowReport
                if (formValues?.categoryId) {
                    setSelectedCategoryID(formValues?.categoryId)
                } else {
                    setSelectedCategoryID("")
                }
                setSelectedReportID(response?.createGroupByRowReport?.reportId)
                let baseReportAdminColumnFieldData = baseReportAdminSettingTable?.getUserTableAdminSetting?.data?.[0]?.fieldData?.map((v) => ({...v, defaultPosition: v?.defaultPosition + 1}))
                baseReportAdminColumnFieldData.unshift({
                    defaultPosition: 1,
                    fieldName: formValues?.groupByRowColumnName.replace(/\s+/g, "_").replace(/^./, (match) => match.toLowerCase()),
                    isDisplayToUser: true,
                    isLock: true,
                    isSelectableByUser: true,
                })
                await updateUserTableAdminSetting({
                    tableName: response?.createGroupByRowReport?.reportId,
                    fieldData: removeTypenameKey(baseReportAdminColumnFieldData),
                })
                toggleModal("")
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Create Successfully",
                    key: "createGroupByRowReport",
                })
            } else if (response?.updateGroupByRowReport?.__typename === "ReportingData") {
                // UpdateGroupByRowReport
                toggleModal("")
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Update Successfully",
                    key: "updateGroupByRowReport",
                })
            } else if (resError) {
                let errMsg = response?.createGroupByRowReport?.errObj?.title ? `${response?.createGroupByRowReport?.errObj?.title}: ${response?.createGroupByRowReport?.errObj?.errors?.description}` : response?.updateGroupByRowReport?.errObj?.message
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: errMsg,
                    key: formMode === "add" ? "createGroupByRowReport" : "updateGroupByRowReport",
                })
            }
        } catch (error) {
        } finally {
            setSubmitLoading(false)
        }
    }

    useEffect(() => {
        if (formMode !== "add" && reportSummaryData && Object.keys(reportSummaryData)?.length > 0 && groupByRowReportSetting && Object.keys(groupByRowReportSetting)?.length > 0) {
            form.setValue("name", reportSummaryData?.displayName)
            form.setValue("description", reportSummaryData?.description)
            form.setValue("groupByRowColumnName", groupByRowReportSetting?.groupByRowColumnName)
            form.setValue("groupByRowColumnFieldKey", groupByRowReportSetting?.groupByRowIdentifierField?.key)
            setGroupByColumnFieldKey(groupByRowReportSetting?.groupByRowIdentifierField?.key)
            groupByRowReportSetting?.groupByRowIdentifierColumns?.map((v, index) => {
                form.setValue(`groups.${index}.name`, v?.name)
                form.setValue(`groups.${index}.description`, v?.description)
                form.setValue(`groups.${index}.groupByRowIdentifier.fieldValues`, v?.groupByRowIdentifier?.fieldValues)
            })
            setGroups(groupByRowReportSetting?.groupByRowIdentifierColumns)
        }
    }, [reportSummaryData, groupByRowReportSetting])

    useEffect(() => {
        if (groupByColumnFieldKey) {
            getReportTargetReportFieldDistinctValue()
        }
    }, [groupByColumnFieldKey, groupByRowReportSetting])

    useEffect(() => {
        getReportFields()
    }, [selectedReportID, groupByRowReportSetting])

    useEffect(() => {
        if (formMode !== "add") {
            getGroupByRowReportSetting()
        }
    }, [formMode])

    const reportingCategoryOptions = useMemo(() => {
        let category = reportingCategory?.map((category) => ({
            label: category?.name,
            value: category?._id,
        }))
        return [{label: "-", value: ""}, ...category]
    }, [reportingCategory])

    const modalTitle = useMemo(() => {
        switch (formMode) {
            case "add":
                return "Create Group By Row Report" + ` (based on ${reportSummaryData?.displayName})`
            case "edit":
                return "Edit Group By Row Report"
            case "view":
                return "View Group By Row Report"
            default:
                break
        }
    }, [formMode])

    const groupByRowColumnFieldKeyOptions = useMemo(() => {
        let targetReportId = formMode === "add" ? selectedReportID : groupByRowReportSetting?.targetReportId
        let targetReportData = reportingAll?.find((v) => v?.reportId === targetReportId)
        return reportFields
            ?.filter((f) => f?.reportId === targetReportId && f?.isGroupByRowEnabled)
            ?.map((d) => {
                let colDisplayName = d?.uiName
                if (targetReportData?.groupBy && d?.uiName === "baseField") {
                    switch (targetReportData?.groupByType) {
                        case "USER":
                            colDisplayName = "Username"
                            break
                        case "CRMUSER":
                            colDisplayName = "CRM User"
                            break
                        case "SALESREP":
                            colDisplayName = "Sales Rep"
                            break
                        case "DESK":
                            colDisplayName = "Desk Name"
                            break
                        case "TRADINGACCOUNT":
                            colDisplayName = "Account No."
                            break
                        case "PSPSETTING":
                            colDisplayName = "PSP"
                            break
                        default:
                            break
                    }
                }
                return {label: colDisplayName, value: d?.key}
            })
    }, [formMode, reportingAll, reportFields, selectedReportID, reportSummaryData, groupByRowReportSetting])

    const groupByRowIdentifierOptions = useMemo(() => {
        return targetReportFieldDistinctValue?.map((v) => ({label: v, value: v}))
    }, [targetReportFieldDistinctValue])

    return (
        <Modal
            title={modalTitle}
            open={isModalOpen}
            width={"80%"}
            footer={null}
            destroyOnClose
            getContainer="#reports"
            className="createEditReportModal"
            maskClosable={false}
            onCancel={() => toggleModal("")}
            // onOk={() => toggleModal("")}
        >
            <FormProvider {...form}>
                <Form onFinish={form.handleSubmit(onSubmit)} className="create-report-form-1" labelWrap={true}>
                    <Row gutter={10} className="section">
                        <Col className="gutter-row" xs={24} sm={24} md={24}>
                            <label className="sectionLabel">1. Details</label>
                        </Col>
                        <Col className="gutter-row" xs={24} sm={24} md={24}>
                            <InputFormItem name={"name"} label={"Name"} placeholder={"Name"} layout={horizontalFormLayout} labelCol={labelCol} labelAlign="left" required disabled={formMode === "view" || submitLoading} />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={24} md={24}>
                            <InputFormItem name={"description"} label={"Description"} placeholder={"Description"} layout={horizontalFormLayout} labelCol={labelCol} labelAlign="left" disabled={formMode === "view" || submitLoading} />
                        </Col>
                        {formMode === "add" && (
                            <>
                                <Col className="gutter-row" xs={24} sm={24} md={24}>
                                    <SelectFormItem
                                        name={"categoryId"}
                                        label={t("Category")}
                                        placeholder={t("Category")}
                                        layout={horizontalFormLayout}
                                        mode={"single"}
                                        labelCol={labelCol}
                                        labelAlign="left"
                                        options={reportingCategoryOptions}
                                        getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                        disabled={formMode !== "add" || submitLoading}
                                    />
                                </Col>
                            </>
                        )}
                        <Col className="gutter-row" xs={24} sm={24} md={24}>
                            <InputFormItem
                                name={"groupByRowColumnName"}
                                label={"Group by Row Column Name"}
                                placeholder={"Group by Row Column Name"}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign="left"
                                required
                                disabled={formMode === "view" || submitLoading}
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={24} md={24}>
                            <SelectFormItem
                                name={"groupByRowColumnFieldKey"}
                                label={t("Group By Which Column")}
                                placeholder={t("Group By Which Column")}
                                layout={horizontalFormLayout}
                                mode={"single"}
                                labelCol={labelCol}
                                labelAlign="left"
                                options={groupByRowColumnFieldKeyOptions}
                                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                disabled={formMode !== "add" || submitLoading}
                                onChange={(value) => setGroupByColumnFieldKey(value)}
                                required
                            />
                        </Col>
                    </Row>
                    <Divider />
                    <Col className="gutter-row" xs={24} sm={24} md={24}>
                        <label className="sectionLabel">2. Group Section</label>
                        <p>Please select Group by Which Column first before proceed to this section</p>
                    </Col>
                    {groups?.map((group, index) => (
                        <Row key={index}>
                            <Col className="gutter-row" xs={6} sm={6} md={6}>
                                <InputFormItem
                                    name={`groups.${index}.name`}
                                    // label={"Group Name"}
                                    placeholder={"Group Name"}
                                    layout={horizontalFormLayout}
                                    labelCol={labelCol}
                                    labelAlign="left"
                                    required
                                    disabled={formMode === "view" || submitLoading || !groupByColumnFieldKey?.length}
                                    value={group?.name}
                                    onChange={(value) => handleGroupChange("name", index, value)}
                                />
                            </Col>
                            <Col className="gutter-row" xs={6} sm={6} md={6}>
                                <InputFormItem
                                    name={`groups.${index}.description`}
                                    // label={"Group Description"}
                                    placeholder={"Group Description"}
                                    layout={horizontalFormLayout}
                                    labelCol={labelCol}
                                    labelAlign="left"
                                    required
                                    disabled={formMode === "view" || submitLoading || !groupByColumnFieldKey?.length}
                                    value={group?.description}
                                    onChange={(value) => handleGroupChange("description", index, value)}
                                />
                            </Col>
                            <Col className="gutter-row" xs={9} sm={9} md={9}>
                                <SelectFormItem
                                    name={`groups.${index}.groupByRowIdentifier.fieldValues`}
                                    // label={t("Field Value")}
                                    placeholder={t("Field Value")}
                                    layout={horizontalFormLayout}
                                    mode={"multiple"}
                                    labelCol={labelCol}
                                    labelAlign="left"
                                    options={groupByRowIdentifierOptions}
                                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                    disabled={formMode === "view" || submitLoading || distinctValueOptionLoading || !groupByColumnFieldKey?.length}
                                    loading={distinctValueOptionLoading}
                                    required
                                    value={group?.groupByRowIdentifier?.fieldValues}
                                    onChange={(value) => handleGroupChange("groupByRowIdentifier.fieldValues", index, value)}
                                />
                            </Col>
                            <Col className="gutter-row" xs={1} sm={1} md={1} />
                            <Col className="gutter-row" xs={1} sm={1} md={1}>
                                <Button type="primary" onClick={() => addGroup(index)} style={{width: "90%"}} icon={<PlusOutlined />} />
                            </Col>
                            {index !== 0 && (
                                <Col className="gutter-row" xs={1} sm={1} md={1}>
                                    <Button type="primary" onClick={() => deleteGroup(index)} style={{width: "90%"}} icon={<MinusOutlined />} />
                                </Col>
                            )}
                        </Row>
                    ))}
                    <Row justify="end" className="section">
                        <Button onClick={() => toggleModal("")} icon={<CloseOutlined />} className="cancelBtn" disabled={submitLoading}>
                            {formMode === "view" ? t("Close") : t("common.cancel")}
                        </Button>
                        {formMode !== "view" && (
                            <Button htmlType="submit" icon={<SaveOutlined />} className="button-submit-1" disabled={submitLoading} loading={submitLoading}>
                                {t("common.submit")}
                            </Button>
                        )}
                    </Row>
                </Form>
            </FormProvider>
        </Modal>
    )
}

export default GroupByRowReportModal
