import React, {useEffect, useMemo, useState} from "react";
import {useRecoilState} from "recoil";
import {columns} from "./function/tableColumn";
import CompanyEmail_1 from "./view/layout1";
import {getCompanyEmail} from "../../../api/graphql/companyEmail";
import {selfPermissionObj} from "../../../../recoil_state";
import {getCompanyEmailTypes} from "../../../api/graphql/companyEmailType";
import {getBrandSetting} from "../../../api/graphql/brandSetting";

function CompanyEmail() {
    let layout = 1;
    let component;
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const [emailData, setEmailData] = useState([]);
    const [emailDataLoading, setEmailDataLoading] = useState(false);
    const [emailTypesData, setEmailTypesData] = useState([]);
    const {data: brandSettingData} = getBrandSetting();
    const companyEmailPermission = selfPermission?.brandSetting?.companyEmail;

    const emailTypeOptions = useMemo(() => {
        return emailTypesData?.map((type: any) => ({
            label: type?.name,
            value: type?._id,
        }));
    }, [emailTypesData]);

    const domainOptions = useMemo(() => {
        let domainList = brandSettingData?.getBrandSetting?.domain;
        return domainList?.map((domain) => ({
            label: domain,
            value: domain,
        }));
    }, [brandSettingData]);

    const getCompanyEmailData = async () => {
        try {
            setEmailDataLoading(true);
            const response = await getCompanyEmail({
                filter: null,
            });
            const data = response?.getCompanyEmail?.data;
            setEmailData(data);
        } catch (error) {
        } finally {
            setEmailDataLoading(false);
        }
    };

    const getCompanyEmailTypesData = async () => {
        try {
            const response = await getCompanyEmailTypes({
                filter: null,
            });
            const data = response?.getCompanyEmailTypes?.data;
            setEmailTypesData(data);
        } catch (error) {}
    };

    useEffect(() => {
        getCompanyEmailTypesData();
        getCompanyEmailData();
    }, []);

    switch (layout) {
        case 1:
            component = (
                <CompanyEmail_1
                    data={emailData}
                    emailTypesData={emailTypesData}
                    columns={columns}
                    loading={emailDataLoading}
                    // error={error}
                    companyEmailPermission={companyEmailPermission}
                    getCompanyEmailData={getCompanyEmailData}
                    getCompanyEmailTypesData={getCompanyEmailTypesData}
                    domainOptions={domainOptions}
                    emailTypeOptions={emailTypeOptions}
                />
            );
            break;

        default:
            <CompanyEmail_1
                data={emailData}
                emailTypesData={emailTypesData}
                columns={columns}
                loading={emailDataLoading}
                // error={error}
                companyEmailPermission={companyEmailPermission}
                getCompanyEmailData={getCompanyEmailData}
                getCompanyEmailTypesData={getCompanyEmailTypesData}
                domainOptions={domainOptions}
                emailTypeOptions={emailTypeOptions}
            />;
    }

    return component;
}

export default CompanyEmail;
