import * as XLSX from "xlsx";
import FileSaver from 'file-saver'
import moment from "moment";

const makeId = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

const getAllColumnFromLanguage = (record, languageStandardListing) => {
    if (!record || !(record.length > 0)) {
        return []
    }
    var columns: any[] = []
    var langaugeList: any[] = []

    record.forEach(item => {
        for (const [key, _] of Object.entries(item)) {
            if (key !== "key" && key !== "ids" && !langaugeList.includes(key)) {
                langaugeList.push(key)
            }
        }
    })

    langaugeList.forEach(code => {
        const [tempLang] = languageStandardListing?.getLanguageStandardListing?.filter(item => item.code === code) || []
        columns.push(
            {
                title: tempLang?.nativeName || "",
                dataIndex: code,
                key: code,
                editable: code !== "key" ? true : false
            }
        )
    })
    return columns
}

const getColumnFromLanguage = (langaugeCode, languageStandardListing, mode = "view") => {
    var temp: any[] = []
    var [label] = languageStandardListing?.getLanguageStandardListing?.filter(item => item.code === langaugeCode) || []
    if (langaugeCode !== "en") {
        temp.push({
            title: "English",
            dataIndex: "en",
            key: "en",
            editable: false,
        })
    }
    temp.push(
        {
            title: label?.nativeName,
            dataIndex: langaugeCode,
            key: langaugeCode,
            editable: mode !== "view" ? true : false,
        })

    return temp
}

const dataConversion = (record) => {
    if (!record) {
        return []
    }
    if (Object.prototype.toString.call(record) === "[object Object]") {
        record = [record]
    }

    var result: any[] = []
    record.forEach(item => {
        let flattenObject = convertObjectToKeyValuePairs(item.translationFile)
        let final = flattenObject.map(x => {
            return {
                ...x,
                code: item.code || "",
            }
        })
        result.push(...final)
    })
    return groupByLanguage(result)
}

function convertObjectToKeyValuePairs(obj, prefix = "") {
    let result: any[] = [];

    for (let key in obj) {
        if (typeof obj[key] === "object") {
            const newPrefix = prefix ? `${prefix}.${key}` : key;
            result.push(...convertObjectToKeyValuePairs(obj[key], newPrefix));
        } else {
            const newKey = prefix ? `${prefix}.${key}` : key;
            result.push({key: newKey, value: obj[key]});
        }
    }

    return result;
}

function groupByLanguage(arr) {
    let result: any[] = [];
    let tempObj: any = {};

    for (let i = 0; i < arr.length; i++) {
        const {key, value, code} = arr[i];

        if (tempObj[key]) {
            tempObj[key][code] = value;
        } else {
            tempObj[key] = {[code]: value};
        }
    }

    for (let key in tempObj) {
        result.push({key, ...tempObj[key], ids: makeId(20)});
    }

    return result;
}

function exportToExcel(translationFile, fileType: any = "xlsx", languageCode: any[] = []) {
    var fileFormat = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
    if (fileType === "csv") {
        fileFormat = "text/csv;charset=utf-8"
    }
    if (!translationFile) {
        return null
    }
    var tempFile = translationFile.map(item => {
        delete item.ids
        return item
    })
    tempFile = tempFile.map(item => sortObjectKeys(item, "key", "en"))
    let header = Object.keys(tempFile[0] || []);

    if (languageCode) {
        languageCode.forEach(item => {
            if (!header.includes(item)) {
                header.push(item)
            }
        })
    }
    let fileName = `TranslationFile_${moment().format("DD-MM-YYYY")}`
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [header]);
    XLSX.utils.sheet_add_json(ws, tempFile, {origin: 'A2', skipHeader: true});
    const wb = {Sheets: {[fileName]: ws}, SheetNames: [fileName]};
    const buffer = XLSX.write(wb, {bookType: fileType, type: 'array', cellStyles: true});
    const finalData = new Blob([buffer], {type: fileFormat});
    FileSaver.saveAs(finalData, fileName + `.${fileType}`);
}

function sortObjectKeys(obj, keyToKeepAtPosition0, keyToKeepAtPosition1) {
    const keys = Object.keys(obj);
    const sortedKeys = keys.sort((a, b) => {
        if (a === keyToKeepAtPosition0) {
            return -1;
        } else if (b === keyToKeepAtPosition0) {
            return 1;
        } else if (a === keyToKeepAtPosition1) {
            return -1;
        } else if (b === keyToKeepAtPosition1) {
            return 1;
        }
        return a.localeCompare(b);
    });

    const sortedObj = {};
    for (let i = 0; i < sortedKeys.length; i++) {
        sortedObj[sortedKeys[i]] = obj[sortedKeys[i]];
    }

    return sortedObj;
}


function convertCsvToArray(data) {
    // const test = data?.replace(/\r\n|\n\r|\r/g, "{row}").split("{row}")
    // const rowKey = test.shift()?.split(",")
    // const result = test.map(item => {
    //     const tempItem = item.split(",")
    //     var rows: any = {}
    //     tempItem.forEach((value, index) => {
    //         if (value && value !== "") {
    //             rows["ids"] = makeId(20) + "_" + index
    //             rows[rowKey[index]] = value
    //         }
    //     })
    //     return rows
    // })
    // return result.slice(0, -1)

    const [headerLine, ...rows] = data?.trim().split('\n')
    const headers = headerLine.split(",")

    return rows.map(row => {
        const values = row.split(",")
        let obj = {}
        headers.forEach((header, index) => {
            obj[header] = values[index]
        })
        return obj
    });
}

export {
    getAllColumnFromLanguage,
    getColumnFromLanguage,
    dataConversion,
    makeId,
    exportToExcel,
    convertCsvToArray
}