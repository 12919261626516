import {gql, useMutation, useQuery} from "@apollo/client";
import {useSetRecoilState} from "recoil";
import {errorMessage, notificationMessage} from "../../../recoil_state";
import {useTranslation} from "react-i18next";
import {ApolloClientQuery} from "./_apolloClient";

const GET_LANGUAGE_COUNT = gql`
    query getLanguageCount($filter: LanguageSearchInput) {
        getLanguageCount(filter: $filter)
    }
`;

const getLanguageCount = ({filter}) => {
    try {
        const {loading, error, data} = useQuery(GET_LANGUAGE_COUNT, {
            variables: {
                filter: {
                    name: filter?.name || null,
                    code: filter?.code || null,
                    isActive: filter?.isActive === null ? null : filter?.isActive,
                },
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_LANGUAGE_BY_ID = gql`
    query getLanguageByID($id: String!) {
        getLanguageByID(id: $id) {
            ... on Language {
                _id
                id
                name
                code
                translationFile
                isActive
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getLanguageById = (id) => {
    try {
        const {loading, error, data} = useQuery(GET_LANGUAGE_BY_ID, {
            variables: {
                id: id,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_LANGUAGE = gql`
    query getLanguage($filter: LanguageSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
        getLanguage(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on LanguageArray {
                data {
                    _id
                    id
                    name
                    nativeName
                    code
                    translationFile
                    isActive
                    countryCode
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getLanguage = ({filter}, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data, refetch} = useQuery(GET_LANGUAGE, {
            variables: {
                filter: {
                    name: filter?.name || null,
                    code: filter?.code || null,
                    isActive: filter?.isActive === null ? null : filter?.isActive,
                },
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        const refreshQuery = () => {
            refetch();
        };

        return {loading, error, languageData: data, refreshQuery};
    } catch (err: any) {
        console.error(err);
        return {languageData: null, loading: false, error: err};
    }
};

const getLanguageAsync = async ({filter}, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_LANGUAGE,
            {
                filter: {
                    name: filter?.name || null,
                    code: filter?.code || null,
                    isActive: filter?.isActive === null ? null : filter?.isActive,
                },
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {languageData: null, loading: false, error: err};
    }
};

const GET_LANGUAGE_DROPDOWN = gql`
    query getLanguageDropdown {
        getLanguageDropdown {
            ...on LanguageDropdownArray {
                data {
                    _id
                    name
                    code
                    nativeName
                }
            }
        }
    }
`

const getLanguageDropdown = async () => {
    try {
        const execute = await ApolloClientQuery(
            GET_LANGUAGE_DROPDOWN,
            {
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {languageData: null, loading: false, error: err};
    }
};


const ADD_LANGUAGE = gql`
    mutation addLanguage($input: LanguageInput) {
        addLanguage(input: $input) {
            ... on Language {
                _id
                id
                name
                code
                translationFile
                isActive
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useAddLanguageMutation = (): {addLanguage: any; data: any; loading: boolean; error: any} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const { t } = useTranslation();
        const [addLanguageMutation, {data, loading, error}] = useMutation(ADD_LANGUAGE);

        const addLanguage = (input) => {
            return addLanguageMutation({
                variables: {
                    input: {
                        name: input.name || null,
                        code: input.code || null,
                        translationFile: input.translationFile || null,
                        isActive: input.isActive ? true : false,
                    },
                },
                update(cache, {data: {addLanguage}}) {
                    const typename = addLanguage?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(addLanguage?.errKey),
                            key: "addLanguage",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(addLanguage?.msg),
                            key: "addLanguage",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Add Language Successfully"),
                            key: "addLanguage",
                        });
                    }
                },
                refetchQueries: [GET_LANGUAGE, GET_LANGUAGE_COUNT]
            });
        };

        return {addLanguage, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {addLanguage: null, data: null, loading: false, error: err};
    }
};

const UPLOAD_LANGUAGE = gql`
    mutation uploadLanguage($input: LanguageInput) {
        uploadLanguage(input: $input) {
            ... on LanguageArray {
                data {
                    id
                    name
                    code
                    translationFile
                    isActive
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useUploadLanguageMutation = (): {uploadLanguage: any; data: any; loading: boolean; error: any} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const { t } = useTranslation();
        const [uploadLanguageMutation, {data, loading, error}] = useMutation(UPLOAD_LANGUAGE)

        const uploadLanguage = (input) => {
            return uploadLanguageMutation({
                variables: {
                    input: {
                        translationFile: input.translationFile || null,
                        isActive: input.isActive ? true : false,
                    },
                },
                update(cache, {data: {uploadLanguage}}) {
                    const typename = uploadLanguage?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(uploadLanguage?.errKey),
                            key: "uploadLanguage",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(uploadLanguage?.msg),
                            key: "uploadLanguage",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Upload Language Successfully"),
                            key: "uploadLanguage",
                        });
                    }
                },
                refetchQueries: [GET_LANGUAGE, GET_LANGUAGE_COUNT]
            });
        };

        return {uploadLanguage, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {uploadLanguage: null, data: null, loading: false, error: err};
    }
};

const UPDATE_LANGUAGE = gql`
    mutation updateLanguage($id: String!, $input: LanguageInput) {
        updateLanguage(id: $id, input: $input) {
            ... on Language {
                _id
                id
                name
                code
                translationFile
                isActive
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useUpdateLanguageMutation = (): {updateLanguage: any; data: any; loading: boolean; error: any} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const { t } = useTranslation();
        const [updateLanguageMutation, {data, loading, error}] = useMutation(UPDATE_LANGUAGE);

        const updateLanguage = (id, input) => {
            return updateLanguageMutation({
                variables: {
                    id: id,
                    input: {
                        name: input.name || null,
                        code: input.code || null,
                        translationFile: input.translationFile || null,
                        isActive: input.isActive ? true : false,
                    },
                },
                update(cache, {data: {updateLanguage}}) {
                    const typename = updateLanguage?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateLanguage?.errKey),
                            key: "updateLanguage",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateLanguage?.msg),
                            key: "updateLanguage",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Language Successfully"),
                            key: "updateLanguage",
                        });
                    }
                },
                refetchQueries: [GET_LANGUAGE, GET_LANGUAGE_COUNT]
            });
        };

        return {updateLanguage, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {updateLanguage: null, data: null, loading: false, error: err};
    }
};

const DELETE_LANGUAGE = gql`
    mutation deleteLanguage($id: String!) {
        deleteLanguage(id: $id) {
            ... on Language {
                _id
                id
                isDeleted
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useDeleteLanguageMutation = (): {deleteLanguage: any; data: any; loading: boolean; error: any} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const { t } = useTranslation();
        const [deleteLanguageMutation, {data, loading, error}] = useMutation(DELETE_LANGUAGE);

        const deleteLanguage = (id) => {
            return deleteLanguageMutation({
                variables: {
                    id: id,
                },
                update(cache, {data: {deleteLanguage}}) {
                    const typename = deleteLanguage?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(deleteLanguage?.errKey),
                            key: "deleteLanguage",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(deleteLanguage?.msg),
                            key: "deleteLanguage",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Delete Language Successfully"),
                            key: "deleteLanguage",
                        });
                    }
                },
                refetchQueries: [GET_LANGUAGE, GET_LANGUAGE_COUNT]
            });
        };

        return {deleteLanguage, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {deleteLanguage: null, data: null, loading: false, error: err};
    }
};

export {
    getLanguageCount,
    getLanguageById,
    getLanguage,
    getLanguageAsync,
    getLanguageDropdown,
    useAddLanguageMutation,
    useUpdateLanguageMutation,
    useDeleteLanguageMutation,
    useUploadLanguageMutation,
};
