import React, {useEffect} from "react";
import SalesOpportunity_1 from "./view/layout1";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {selfPermissionObj} from "../../../../recoil_state";
import {salesOpportunityStatusData} from "../../settings/salesOpportunitySetting/state";
import {useSalesOpportunitySettingFunction} from "../../settings/salesOpportunitySetting/function";

function SalesOpportunity() {
    let layout = 1;
    let component;
    const tableName = "Sales Opportunity"
    const permission = useRecoilValue<any>(selfPermissionObj)
    const statusList = useRecoilValue<any>(salesOpportunityStatusData)
    const {getSalesOpportunityStatusFunction} = useSalesOpportunitySettingFunction()

    useEffect(() => {
        getSalesOpportunityStatusFunction()
    }, [])

    const props = {
        tableName,
        statusList,
        permissionUser: permission?.user,
    }

    switch (layout) {
        case 1:
            component = (
                <SalesOpportunity_1 {...props}/>
            );
            break;

        default:
            component = (
                <SalesOpportunity_1 {...props}/>
            );
            break;
    }

    return component;
};

export default SalesOpportunity;
