import { gql, useMutation, useQuery } from '@apollo/client'
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {ApolloClientQuery} from "./_apolloClient";

const GET_NEWSLETTER_COUNT = gql`
  query getNewsletterCount($filter: NewsletterInput) {
    getNewsletterCount(filter: $filter)
  }
`;

const getNewsletterCount = ({filter}) => {
  try{
    const { loading, error, data } = useQuery(GET_NEWSLETTER_COUNT, {
        variables: {
            filter: {
              name: filter?.name || null,
              description: filter?.description || null,
            }
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}

const GET_NEWSLETTER_BY_ID = gql`
  query getNewsletterByID($id: String!) {
    getNewsletterByID(id: $id){
      ... on Newsletter {
        _id
        id
        name
        description
        displayNameLanguage {label language}
        createdBy {username}
        updatedBy {username}
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getNewsletterById = (id) => {
  try{
    const { loading, error, data } = useQuery(GET_NEWSLETTER_BY_ID, {
        variables: {
          id: id
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}


const GET_NEWSLETTER = gql`
  query getNewsletter($filter: NewsletterInput, $limit: Int, $offset: Int, $orderBy: String) {
    getNewsletter(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy){
      ... on NewsletterArray {
        data {
          _id
          id
          name
          description
          image {
            fileList
            path
          }
          displayNameLanguage {label language}
          isEnabled
          createdBy {username}
          updatedBy {username}
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getNewsletter = ({filter}, limit?, offset?, orderBy?) => {
  try{
    const { loading, error, data } = useQuery(GET_NEWSLETTER, {
        variables: {
          filter: {
              name: filter?.name || null,
              description: filter?.description || null,
          },
          limit: limit || null,
          offset: offset || null,
          orderBy: orderBy || null,
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}

const getNewsletterAsync = async ({filter}, limit?, offset?, orderBy?) => {
  try {
    const execute = await ApolloClientQuery(
        GET_NEWSLETTER,
        {
          filter: {
            name: filter?.name || null,
            description: filter?.description || null,
          },
          limit: limit || null,
          offset: offset || null,
          orderBy: orderBy || null,
          context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t"),
            },
          },
        },
        false
    );

    return execute;
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const ADD_NEWSLETTER = gql`
  mutation addNewsletter($input: NewsletterInput) {
    addNewsletter(input: $input){
      ... on Newsletter {
        _id
        id
        name
        description
        image {
            fileList
            path
          }
        displayNameLanguage {label language}
        createdBy {username}
        updatedBy {username}
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useAddNewsletterMutation = (): { addNewsletter: any, data: any, loading: boolean, error: any } => {
  try{
    const { t } = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [addNewsletterMutation, { data, loading, error }] = useMutation(ADD_NEWSLETTER);

    const addNewsletter = (input) => {
      return addNewsletterMutation({
        variables: {
          input: input,
        },
        update(cache, { data: { addNewsletter } }) {
          const typename = addNewsletter?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(addNewsletter?.errKey),
              key: "addNewsletter",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(addNewsletter?.msg),
              key: "addNewsletter",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Add Newsletter Successfully"),
              key: "addNewsletter",
            });
          }
        },
        refetchQueries: [GET_NEWSLETTER]
      });
    };

    return { addNewsletter, data, loading, error } 
  }
  catch(err: any){
    console.error(err);
    return { addNewsletter: null, data: null, loading: false, error: err };
  }
}


const UPDATE_NEWSLETTER = gql`
mutation updateNewsletter($id: String!, $input: NewsletterInput) {
    updateNewsletter(id: $id, input: $input){
      ... on Newsletter {
        _id
        id
        name
        description
        image {
            fileList
            path
          }
        displayNameLanguage {label language}
        createdBy {username}
        updatedBy {username}
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useUpdateNewsletterMutation = (): { updateNewsletter: any, data: any, loading: boolean, error: any } => {
  try{
    const { t } = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [updateNewsletterMutation, { data, loading, error }] = useMutation(UPDATE_NEWSLETTER);

    const updateNewsletter = (id, input) => {
      return updateNewsletterMutation({
        variables: {
          id: id,
          input: input
        },
        update(cache, { data: { updateNewsletter } }) {
          const typename = updateNewsletter?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(updateNewsletter?.errKey),
              key: "updateNewsletter",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(updateNewsletter?.msg),
              key: "updateNewsletter",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Update Newsletter Successfully"),
              key: "updateNewsletter",
            });
          }
        }
      });
    };

    return { updateNewsletter, data, loading, error } 
  }
  catch(err: any){
    console.error(err);
    return { updateNewsletter: null, data: null, loading: false, error: err };
  }
}


const DELETE_NEWSLETTER = gql`
  mutation deleteNewsletter($id: String!){
    deleteNewsletter(id: $id){
      ... on Newsletter {
        _id
        id
        isDeleted
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useDeleteNewsletterMutation = (): { deleteNewsletter: any, data: any, loading: boolean, error: any } => {
  try{
    const { t } = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [deleteNewsletterMutation, { data, loading, error }] = useMutation(DELETE_NEWSLETTER);

    const deleteNewsletter = (id) => {
      return deleteNewsletterMutation({
        variables: {
          id: id,
        },
        update(cache, { data: { deleteNewsletter } }) {
          const typename = deleteNewsletter?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(deleteNewsletter?.errKey),
              key: "deleteNewsletter",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(deleteNewsletter?.msg),
              key: "deleteNewsletter",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Delete Newsletter Successfully"),
              key: "deleteNewsletter",
            });
          }
        },
        refetchQueries: [GET_NEWSLETTER]
      });
    };

    return { deleteNewsletter, data, loading, error } 
  }
  catch(err: any){
    console.error(err);
    return { deleteNewsletter: null, data: null, loading: false, error: err };
  }
}

const GET_NEWSLETTER_SETTING = gql`
  query getNewsletterSetting($filter: NewsletterSettingSearchInput) {
    getNewsletterSetting(filter: $filter){
      ... on NewsletterSettingArray {
        data {
          _id
          newsletter {
            _id
            name
          }
          enabled
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getNewsletterSetting = (filter?) => {
  try{
    const { loading, error, data } = useQuery(GET_NEWSLETTER_SETTING, {
      variables: {
        filter: filter,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t")
        }
      }
    });

    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}


const UPDATE_NEWSLETTER_SETTING = gql`
mutation updateNewsletterSetting($input: NewsletterSettingInput) {
    updateNewsletterSetting(input: $input){
      ... on NewsletterSetting {
        _id
        newsletter {
          _id
          name
        }
        enabled
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useUpdateNewsletterSettingMutation = (): { updateNewsletterSetting: any, data: any, loading: boolean, error: any } => {
  try{
    const { t } = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [updateNewsletterSettingMutation, { data, loading, error }] = useMutation(UPDATE_NEWSLETTER_SETTING);

    const updateNewsletterSetting = (input) => {
      return updateNewsletterSettingMutation({
        variables: {
          input: input,
        },
        update(cache, { data: { updateNewsletterSetting } }) {
          const typename = updateNewsletterSetting?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(updateNewsletterSetting?.errKey),
              key: "updateNewsletterSetting",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(updateNewsletterSetting?.msg),
              key: "updateNewsletterSetting",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Update Newsletter Setting Successfully"),
              key: "updateNewsletterSetting",
            });
          }
        },
      });
    };

    return { updateNewsletterSetting, data, loading, error }
  }
  catch(err: any){
    console.error(err);
    return { updateNewsletterSetting: null, data: null, loading: false, error: err };
  }
}



export {
  getNewsletterCount, 
  getNewsletterById, 
  getNewsletter,
  getNewsletterAsync,
  useAddNewsletterMutation, 
  useUpdateNewsletterMutation, 
  useDeleteNewsletterMutation,
  getNewsletterSetting,
  useUpdateNewsletterSettingMutation
}