import {gql, useQuery} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";

const GET_TIMEZONE_LIST = gql`
    query getTimezoneList {
        getTimezoneList {
            label
            value
        }
    }
`;

const getTimezoneList = () => {
    try {
        const {loading, error, data} = useQuery(GET_TIMEZONE_LIST, {
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_LANGUAGE_Standard_LISTING = gql`
    query getLanguageStandardListing {
        getLanguageStandardListing {
            code
            name
            nativeName
        }
    }
`;

const getLanguageStandardListing = () => {
    try {
        const {loading, error, data} = useQuery(GET_LANGUAGE_Standard_LISTING, {
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, languageStandardListing: data};
    } catch (err: any) {
        console.error(err);
        return {languageStandardListing: null, loading: false, error: err};
    }
};

const GET_PSP_CURRENCY_LIST = gql`
    query getPspCurrencyList {
        getPspCurrencyList
    }
`;

const getPspCurrencyList = () => {
    try {
        const {loading, error, data} = useQuery(GET_PSP_CURRENCY_LIST, {
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UPDATE_USER_PASSWORD = gql`
    mutation updateUserPassword($id: String, $input: UserPasswordInput) {
        updateUserPassword(id: $id, input: $input) {
            ... on User {
                _id
                username
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const updateUserPassword = async (id, input) => {
    const variables = {
        id: id,
        input: input,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(UPDATE_USER_PASSWORD, variables, true);

    return execute;
};

const GET_DESK_DROPDOWN = gql`
    query getDeskDropdown($userId: String) {
        getDeskDropdown(userId: $userId) {
            ... on DeskArray {
                data {
                    _id
                    id
                    name
                    description
                    isDeleted
                    isMasterDesk
                    createdAt
                    updatedAt
                    parent {
                        _id
                        name
                    }
                }
            }
        }
    }
`;

const getDeskDropdown = (userId) => {
    try {
        const {loading, error, data} = useQuery(GET_DESK_DROPDOWN, {
            variables: {
                userId: userId,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const getDeskDropdownAsync = async (userId) => {
    try {
        const execute = await ApolloClientQuery(
            GET_DESK_DROPDOWN,
            {
                userId: userId,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_ROLE_TEMPLATE_DROPDOWN = gql`
    query getRoleTemplateDropdown($userId: String) {
        getRoleTemplateDropdown(userId: $userId) {
            ... on RoleTemplateObjArray {
                data {
                    _id
                    name
                    isDefaultCrmUserPermission
                }
            }
        }
    }
`;

const getRoleTemplateDropdown = (userId) => {
    try {
        const {loading, error, data} = useQuery(GET_ROLE_TEMPLATE_DROPDOWN, {
            variables: {
                userId: userId,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const getRoleTemplateDropdownAsync = async (userId) => {
    try {
        const execute = await ApolloClientQuery(
            GET_ROLE_TEMPLATE_DROPDOWN,
            {
                userId: userId,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_ROLE_TEMPLATE_DROPDOWN_BASED_ON_PERMISSION = gql`
    query getRoleTemplateDropdownBasedOnPermission($userId: String, $permission: String) {
        getRoleTemplateDropdownBasedOnPermission(userId: $userId, permission: $permission) {
            ... on RoleTemplateObjArray {
                data {
                    _id
                    name
                    isDefaultCrmUserPermission
                }
            }
        }
    }
`;

const getRoleTemplateDropdownBasedOnPermission = (input) => {
    try {
        const {loading, error, data} = useQuery(GET_ROLE_TEMPLATE_DROPDOWN_BASED_ON_PERMISSION, {
            variables: input,
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_MULTI_TIER_SETTING_DROPDOWN = gql`
    query getMultiTierSettingDropdown($userId: String, $type: String) {
        getMultiTierSettingDropdown(userId: $userId, type: $type) {
            ... on MultiTierSettingArray {
                data {
                    _id
                    name
                    type
                    isDefaultRank
                }
            }
        }
    }
`;

const getMultiTierSettingDropdown = (userId, type:any = null) => {
    try {
        const {loading, error, data} = useQuery(GET_MULTI_TIER_SETTING_DROPDOWN, {
            variables: {
                userId: userId,
                type: type
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const getMultiTierSettingDropdownAsync = async (userId, type:any = null) => {
    try {
        const execute = await ApolloClientQuery(
            GET_MULTI_TIER_SETTING_DROPDOWN,
            {
                userId: userId,
                type: type,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};


const GET_USER_DROPDOWN = gql`
    query getUserDropdown($userId: String, $additionalIds: [String]) {
        getUserDropdown(userId: $userId, additionalIds: $additionalIds) {
            ... on UserAry {
                data {
                    _id
                    username
                    email
                    identificationNo
                    userType
                }
            }
            ... on BaseError {
                errKey
                errObj
                errMsg
            }
        }
    }
`;

const getUserDropdown = async (userId, additionalIds?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_USER_DROPDOWN,
            {
                userId: userId,
                additionalIds: additionalIds,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_COUNTRY_CODE_LABEL_LIST = gql`
    query getCountryCodeLabelList {
        getCountryCodeLabelList
    }
`;

const getCountryCodeLabelList = async () => {
    try {
        const execute = await ApolloClientQuery(
            GET_COUNTRY_CODE_LABEL_LIST,
            {
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_IS_MAIN_MASTER_ADMIN = gql`
    query isMainMasterAdmin{
        isMainMasterAdmin
    }
`


const getIsMainMasterAdmin = async () => {
    try {
        const execute = await ApolloClientQuery(
            GET_IS_MAIN_MASTER_ADMIN,
            {
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

export {
    getTimezoneList,
    getLanguageStandardListing,
    getPspCurrencyList,
    updateUserPassword,
    getDeskDropdown,
    getDeskDropdownAsync,
    getRoleTemplateDropdown,
    getRoleTemplateDropdownAsync,
    getRoleTemplateDropdownBasedOnPermission,
    getMultiTierSettingDropdown,
    getMultiTierSettingDropdownAsync,
    getUserDropdown,
    getCountryCodeLabelList,
    GET_DESK_DROPDOWN,
    GET_MULTI_TIER_SETTING_DROPDOWN,
    GET_ROLE_TEMPLATE_DROPDOWN,
    getIsMainMasterAdmin
};
