import { gql, useMutation, useQuery } from '@apollo/client'
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";

const GET_TRIGGER_COUNT = gql`
  query getTriggerCount($filter: TriggerInput) {
    getTriggerCount(filter: $filter)
  }
`;

const getTriggerCount = ({filter}) => {
  try{
    const { loading, error, data } = useQuery(GET_TRIGGER_COUNT, {
        variables: {
            filter: {
              triggerGroup: filter?.triggerGroup || null,
              triggerName: filter?.triggerName || null,
            }
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}

const GET_TRIGGER = gql`
  query getTrigger($filter: TriggerInput, $limit: Int, $offset: Int, $orderBy: String) {
    getTrigger(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy){
      ... on TriggerArray {
        data {
          _id
          id
          triggerGroup
          triggerName
          isDeleted
          createdBy {username}
          updatedBy {username}
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getTrigger = ({filter}, limit?, offset?, orderBy?) => {
  try{
    const { loading, error, data } = useQuery(GET_TRIGGER, {
        variables: {
          filter: {
            triggerGroup: filter?.triggerGroup || null,
            triggerName: filter?.triggerName || null,
          },
          limit: limit || null,
          offset: offset || null,
          orderBy: orderBy || null,
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}

export {
  getTriggerCount, 
  getTrigger
}