import {Card, Col, message, Row, Tag} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";
import {getDynamicVariable} from "../../../../../../api/graphql/dynamicVariable";

const LabelWithCopyFunction = (props) => {
  const { data, mode } = props;
  const { t } = useTranslation();

  const copyText = () => {
    let copiedName = "";

    if (mode === "variable") {
      copiedName = "{{dynamicVariable:" + data?.name + "}}";
    }

    navigator.clipboard.writeText(copiedName);
    message.success(t(`Copied to clipboard`));
  };

  return (
    <Tag onClick={copyText} style={{ cursor: "pointer" }}>
      {data?.name}
    </Tag>
  );
};

const VariableForm = () => {
  const { variableData } = getDynamicVariable({ filter: null });
  const { t } = useTranslation();

  const textVariable = variableData?.getDynamicVariable?.data?.filter(
    (item) => item.variableType === "text"
  );
  const imageVariable = variableData?.getDynamicVariable?.data?.filter(
    (item) => item.variableType === "image"
  );

  return (
    <>
      <Row>
        {textVariable && textVariable.length > 0 && (
          <Col xs={11}>
            <Card title={t("Text")}>
              {textVariable.map((item) => {
                return <LabelWithCopyFunction data={item} mode={"variable"} />;
              })}
            </Card>
          </Col>
        )}
        {imageVariable && imageVariable.length > 0 && (
          <Col xs={11}>
            <Card title={t("Image")}>
              {imageVariable.map((item) => {
                return <LabelWithCopyFunction data={item} mode={"variable"} />;
              })}
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
};

export default VariableForm;
