import {getSalesOpportunityLite} from "../../../../api/graphql/salesOpportunity";
import useAuthorize from "../../../../_common/authorize";
import {useState} from "react";

export const useSalesOpportunityFunction = () => {
    const {systemErrorNotification} = useAuthorize()

    const fetchSalesOpportunityBoardView = async (status, filter, limit?, offset?, orderBy?) => {
        try {
            return await getSalesOpportunityLite({...filter, salesOppStatus: [status]}, limit, offset, orderBy)
        } catch (e) {
            systemErrorNotification()
        }
    }

    return {
        fetchSalesOpportunityBoardView
    }
}

export const useGetSalesOpportunity = () => {
    const [data, setData] = useState({data: [], total: 0})
    const [loading, setLoading] = useState(true)
    const [param, setParams] = useState<any>({})

    const fetchSalesOpportunity = async (status, filter, limit?, offset?, orderBy?, endDate?, dateKey?) => {
        try {
            setLoading(true)
            setParams({status, filter, limit, offset, orderBy, endDate, dateKey})

            const response = await getSalesOpportunityLite(status, filter, limit, offset, orderBy, endDate, dateKey)
            setData(response?.getSalesOpportunityLite)
        } catch (e) {

        } finally {
            setLoading(false)
        }
    }

    const refetchSalesOpportunity = () => {
        const {status, filter, limit, offset, orderBy, endDate, dateKey} = param
        fetchSalesOpportunity(status, filter, limit, offset, orderBy, endDate, dateKey)
    }

    return [data, loading, fetchSalesOpportunity, refetchSalesOpportunity] as const
}