import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {Breadcrumb, Button, Card, Col, Empty, Modal, Row, Space, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleRight} from "@fortawesome/free-regular-svg-icons";
import {useAccountVerificationFunction} from "../../../../../../function/accountVerificationFunction";
import OptionalRibbonOnCard from "./optionalRibbonOnCard";
import OptionalTooltipCard from "./optionalTooltipCard";

const SelectVerificationType = ({
                                    selectedCountry,
                                    steps,
                                    selectedStep,
                                    verificationRecord,
                                    handleSelectVerificationType,
                                    isCancelVerificationModalOpen,
                                    handleCloseCancelVerification,
                                    handleConfirmCancelVerification,
                                    isCancelingVerificationRecord,
                                }) => {
    const {t} = useTranslation();
    const sortedStep = useMemo(() => [...steps].sort((a, b) => a.sortingNumber - b.sortingNumber), [steps]);
    const {getVerificationStepStatus, getRequiredDescription, statusToRibbonText} = useAccountVerificationFunction()

    if (!steps?.length) {
        return (
            <>
                <Row justify={"center"}>
                    <Col>
                        <Space direction="vertical" align="center" className="text-center" size={16}>
                            <Empty />
                            <div>
                                {t("accountVerification.verificationNotAvailable", {
                                    country: selectedCountry,
                                })}
                            </div>
                        </Space>
                    </Col>
                </Row>
            </>
        );
    }

    return (
        <div className="narrow-padding">
            <Modal
                className="verification-modal"
                title={t("accountVerification.cancelVerification")}
                getContainer="#layout-4"
                open={isCancelVerificationModalOpen}
                onCancel={() => handleCloseCancelVerification()}
                onOk={() => handleConfirmCancelVerification()}
                cancelText= {t("common.no")}
                okText={t("accountVerification.cancelVerificationProcess")}
                okButtonProps={{loading: isCancelingVerificationRecord}}
                destroyOnClose={true}
                width={"600px"}
            >
                <div>{t("accountVerification.cancelVerificationDescription")}</div>
            </Modal>
            <Space style={{width: "100%"}} size={16} direction="vertical">
                <div className="account-verification-title">
                    <Breadcrumb items={[{title: t(selectedCountry)}]}/>
                </div>
                {sortedStep.map((step, i, steps) => {
                    const status = getVerificationStepStatus(step, verificationRecord);
                    const className = status === "verification failed" ? "error" : "default";
                    return (
                        <OptionalRibbonOnCard
                            showRibbon={getVerificationStepStatus(step, verificationRecord)}
                            className={className}
                            text={statusToRibbonText(status)}
                        >
                            <OptionalTooltipCard
                                title={getRequiredDescription({
                                    requiredType: step.requiredType,
                                    numberOfRequired: step.numberOfRequired,
                                })}
                            >
                                <Card
                                    className={`account-verification-card-selection clickable ${
                                        selectedStep?._id === step._id ? "account-verification-card-selected" : ""
                                    }`}
                                    onClick={() => handleSelectVerificationType(step)}
                                    size={"small"}
                                >
                                    <Row justify={"space-between"} align={"middle"} gutter={48}>
                                        <Col sm={24} md={6}>
                                            <div className="account-verification-card-selection-title">
                                                {step.requiredType === "all" ||
                                                step.requiredType === "any" ||
                                                (step.requiredType === "partially" &&
                                                    step.partiallyRequiredGroup?.some((groupId) =>
                                                        step.documentGroup?.some((d) => d.group?._id === groupId)
                                                    )) ? (
                                                    <Tooltip title={"This section is required"}>
                                                        <span className="account-verification-required-mark">*</span>
                                                    </Tooltip>
                                                ) : null}
                                                {t(step.label)}
                                            </div>
                                        </Col>
                                        <Col sm={24} md={18}>
                                            {step.description ? (
                                                <div className="account-verification-card-selection-description">
                                                    <p className="margin-bottom-0">
                                                        <small>{t(step.description)}</small>
                                                    </p>
                                                </div>
                                            ) : null}
                                        </Col>
                                        {/*<Col sm={12} md={4} className={"text-end"}>*/}
                                        {/*    <a*/}
                                        {/*        onClick={() => handleSelectVerificationType(step)}*/}
                                        {/*        className={"action mt-2"}>{t("common.proceed")} <FontAwesomeIcon icon={faArrowAltCircleRight}/>*/}
                                        {/*    </a>*/}
                                        {/*</Col>*/}
                                    </Row>
                                </Card>
                             </OptionalTooltipCard>
                         </OptionalRibbonOnCard>
                    );
                })}
            </Space>
        </div>
    )
}

export default SelectVerificationType