import React, {useEffect, useState} from "react";
import {Col, Form, Modal, Row, Select, Tabs, TabsProps} from "antd";
import {useTranslation} from "react-i18next";
import {useBindUserLeads} from "../../../../../../../api/graphql/leads";
import {formatProfileInformation, renderProfileInformation} from "../../../../../_common/function";
import {useRecoilValue} from "recoil";
import {clientProfileInformationSettingState, userOptionsState} from "../../../../../_common/state";
import useAuthorize from "../../../../../../../_common/authorize";

const BindModal = (props) => {
        const {isOpen, setIsOpen, data, userOptions} = props;
        const {t} = useTranslation();
        const labelCol = {span: 8, offset: 0};
        const [mode, setMode] = useState("create");
        const userNetworkOptions = useRecoilValue(userOptionsState);
        const filteredProfileInformation = useRecoilValue(clientProfileInformationSettingState)
        const {systemErrorNotification} = useAuthorize();
        const {bindUserLeads, loading} = useBindUserLeads();
        const [form] = Form.useForm();

        const [fields, setFields] = useState<any>({
            basicField: [],
            loginField: [],
            residenceField: [],
            additionalField: [],
        });
        const {basicField, loginField, residenceField, additionalField} = fields;

        useEffect(() => {
            formatProfileInformation(fields, setFields, filteredProfileInformation);
        },[filteredProfileInformation])

        const onCancel = () => {
            setIsOpen(false);
        };

        useEffect(() => {
            form.resetFields()
        },[isOpen])

        const onSubmit = async (value) => {
            try {
                let bindId;
                value["mobileNo"] = value?.mobileNo?.toString();
                delete value["combinedMobileNo"];

                await Object.keys(value).forEach((key) => {
                    if (!value[key]) {
                        delete value[key];
                    }
                    if (key === "bind") {
                        bindId = value["bind"];
                        delete value["bind"];
                    }
                });

                mode === "create"
                    ? await bindUserLeads({
                        id: data?._id,
                        isExisting: false,
                        input: {
                            ...value,
                            userType: "client",
                        },
                    })
                    : await bindUserLeads({
                        id: data?._id,
                        isExisting: true,
                        userId: bindId,
                    });

                setIsOpen(false);
            } catch (e) {
                systemErrorNotification();
            }
        };

        const items: TabsProps["items"] = [
            {
                label: t("Create A User and Bind it"),
                key: "create",
                children: (
                    <Form form={form} onFinish={onSubmit}>
                        <Row gutter={[16, 16]} className={"margin-top-0-75 margin-bottom-0-75"}>
                            {basicField?.length > 0 && (
                                <Col span={24}>
                                    <h4>{t("field.category.basicInformation")}</h4>
                                    <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                                        {renderProfileInformation(basicField, data, t, false, true, form)}
                                        <Col span={12}>
                                            <Form.Item
                                                name={"referrer"}
                                                label={t("user.referrer")}
                                                required
                                                labelCol={{span: 10, offset: 0}}
                                                labelAlign="left"
                                            >
                                                <Select options={userNetworkOptions} optionFilterProp={"label"} optionLabelProp="selectedLabel"/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name={"clientAccountType"}
                                                label={t("user.clientAccountType")}
                                                required
                                                labelCol={{span: 10, offset: 0}}
                                                labelAlign="left"
                                            >
                                                <Select
                                                    options={[
                                                        {label: t("Individual"), value: "individual"},
                                                        {label: t("Demo"), value: "demo"},
                                                    ]}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                            {loginField?.length > 0 && (
                                <Col span={24}>
                                    <h4>{t("field.category.loginInformation")}</h4>
                                    <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                                        {renderProfileInformation(loginField, data, t, false, true, form)}
                                    </Row>
                                </Col>
                            )}
                            {residenceField?.length > 0 && (
                                <Col span={24}>
                                    <h4>{t("field.category.residenceInformation")}</h4>
                                    <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                                        {renderProfileInformation(residenceField, data, t, false, true, form)}
                                    </Row>
                                </Col>
                            )}
                            {additionalField?.length > 0 && (
                                <Col span={24}>
                                    <h4>{t("field.category.additionalInformation")}</h4>
                                    <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                                        {renderProfileInformation(additionalField, data, t, false, true, form)}
                                    </Row>
                                </Col>
                            )}
                        </Row>
                    </Form>
                ),
            },
            {
                label: t("Bind An Existing User"),
                key: "existing",
                children: (
                    <Form form={form} onFinish={onSubmit}>
                        <Row gutter={[16, 16]} className={"margin-top-0-75 margin-bottom-0-75"}>
                            <Col span={24}>
                                <Form.Item name={"bind"} label={t("Bind to")} labelCol={labelCol} rules={[{required: true}]}>
                                    <Select options={userOptions} placeholder={t("Bind")} value={data?.bind?._id}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                ),
            },
        ];

        return (
            <Modal
                title={t(`Bind Leads`)}
                open={isOpen}
                onOk={form.submit}
                onCancel={onCancel}
                okText={t("lead.bind")}
                cancelText={t("common.cancel")}
                okButtonProps={{loading: loading}}
                width={800}
            >
                <Tabs destroyInactiveTabPane size={"small"} items={items} onTabClick={(key) => setMode(key)}/>
            </Modal>
        );
    }
;

export default BindModal;
