import {CountryCodeList_Language} from "../../../../../../config/countryCodeList_Language";
import {useTranslation} from "react-i18next";
import moment from "moment";

export const useAccountVerificationFunction = () => {
    const {t} = useTranslation()

    const DATE_FORMAT = "YYYY-MM-DD";
    const VERIFICATION_STATUS_PRIORITY = [
        "start",
        "verification expired",
        //   "cancelled",
        "draft verification",
        "pending verification",
        "verification failed",
        "verified",
    ];

    function getLastPartOfUrl(url) {
        const IMAGE_EXT = [".jpg", ".jpeg", ".png", ".webp"];
        const VIDEO_EXT = [".mp4", ".wmv", ".avi", ".webm"];
        const DOCUMENT_EXT = [".pdf", ".xlsx", ".xls"];

        if (!url) {
            return null;
        }
        // Split the URL by slashes "/"
        const parts = url?.split("/");

        // Get the last part of the URL
        const name = parts[parts.length - 1];

        const category = IMAGE_EXT.some((d) => name?.includes(d))
            ? "image"
            : VIDEO_EXT.some((d) => name?.includes(d))
                ? "video"
                : DOCUMENT_EXT.some((d) => name?.includes(d))
                    ? "document"
                    : null;

        const formats = name.split(".");
        const format = formats[formats.length - 1];

        return {name, format, category};
    }

    const isAbleToSubmit = (verificationSteps, verificationRecord) => {
        const stepResults = verificationSteps.map((vs) =>
            validateVerificationStep(vs, verificationRecord)
        );
        const result = !stepResults.some((pass) => !pass);

        return result;

        function validateVerificationStep(verificationStep, verificationRecord) {
            if (!verificationStep || !verificationRecord) {
                return false;
            }
            const {
                requiredType, // all | none | any | partially
                numberOfRequired,
                partiallyRequiredGroup,
                documentGroup,
            } = verificationStep;

            const { recordDocument } = verificationRecord;
            if (!recordDocument) {
                return false;
            }
            switch (requiredType) {
                case "all": {
                    const documentIds = documentGroup?.reduce(
                        (a, b) => [...a, ...b?.documents?.map((c) => c?.document?._id)],
                        []
                    );

                    const completedDocuments = recordDocument?.filter(
                        (rd) =>
                            documentIds?.includes(rd?.document?._id) &&
                            ["draft verification", "verified"].includes(rd?.status)
                    );

                    const completedDocumentCount = completedDocuments?.length || 0;

                    const incompleteDocument = recordDocument?.find(
                        (rd) =>
                            documentIds?.includes(rd?.document?._id) &&
                            !["draft verification", "cancelled"].includes(rd?.status)
                    );
                    if (
                        incompleteDocument ||
                        documentIds.length !== completedDocumentCount
                    ) {
                        return false;
                    }
                    return true;
                }
                case "partially": {
                    const documentGroupsToCheck = documentGroup?.filter((dg) =>
                        partiallyRequiredGroup?.includes(dg?.group?._id)
                    );
                    const documentIds = documentGroupsToCheck?.reduce(
                        (a, b) => [...a, ...b?.documents.map((c) => c?.document?._id)],
                        []
                    );

                    const incompleteDocument = recordDocument?.some(
                        (rd) =>
                            documentIds?.includes(rd?.document?._id) &&
                            rd?.status !== "draft verification"
                    );
                    if (incompleteDocument) {
                        return false;
                    }
                    return true;
                }
                case "any": {
                    const documentIds = documentGroup?.reduce(
                        (a, b) => [...a, ...b?.documents?.map((c) => c?.document?._id)],
                        []
                    );

                    const completedDocuments = recordDocument?.filter(
                        (rd) =>
                            documentIds.includes(rd?.document?._id) &&
                            rd?.status === "draft verification"
                    );

                    if (completedDocuments?.length < numberOfRequired) {
                        return false;
                    }
                    return true;
                }
                case "none":
                default: {
                    return true;
                }
            }
        }
    }

    const constructSubmitInput = (fieldList, fieldSetting, formData) => {
        const mmm = Object.keys(formData).reduce((a, b) => {
            const k = b?.split("___");

            if (k.length === 1) {
                return a;
            }
            const [originalKey, memberId] = k;

            if (memberId.includes(".mobile")) {
                return a;
            }
            const currentSize = Object.entries(a).length;
            if (memberId in a) {
                return a;
            }
            return { ...a, [memberId]: currentSize + 1 };
        }, {});

        return fieldList.reduce((a, b) => {
            let payload:any = {
                fieldSetting: b.fieldSetting,
                value: ["image", "document", "video", "file"].includes(b.fieldType)
                    ? typeof formData[b.label] !== "string"
                        ? formData[b.label]?.[0]?.response?.[0]?.link
                        : formData[b.label]
                    : formData[b.label],
                expiredDate: b.hasExpiredDate ? moment(formData[`${b.label}.expiredDate`]?.format(DATE_FORMAT) + "T00:00:00.000+00:00") : null,
            };

            if (fieldSetting) {
                payload.fieldSetting = fieldSetting;
                payload.referenceId = b._id;
            }

            if (b.fieldType === "group" && b.fieldGroup?.length) {
                return [
                    ...a,
                    ...constructSubmitInput(b.fieldGroup, b.fieldSetting, formData),
                ];
            }
            if (b.fieldType === "mobile") {
                const selectedMobileCode = CountryCodeList_Language.find(
                    (d) => d.value === formData[`${b.label}.mobile`]
                );

                const value = formData[b.label];
                if (selectedMobileCode && value) {
                    const countryCode = selectedMobileCode.value;
                    // const mobileNo = `${selectedMobileCode.dial_code}${value}`;
                    const mobileNo = `${value}`;
                    payload.valueMobile = {countryCode, mobileNo};
                    delete payload.value;
                }
            }

            if (b.fieldType === "date") {
                payload.value = moment(payload.value?.format(DATE_FORMAT) + "T00:00:00.000+00:00")
            }

            const memF = Object.keys(formData).filter(
                (k) => k.startsWith(b.label) && k.includes("___")
            );

            if (memF.length) {
                const groupedMultipleFields = memF.reduce(
                    (c:any, d:any) => {
                        const k = d.split("___")[1];
                        if (k.includes(".mobile")) {
                            return c;
                        }

                        const memberId = mmm[k];

                        payload.value = ["image", "document", "video", "file"].includes(
                            b.fieldType
                        )
                            ? typeof formData[d] !== "string"
                                ? formData[d]?.file?.response?.[0]?.link
                                : formData[d]
                            : formData[d];

                        const mobileField = formData[d + ".mobile"];
                        if (mobileField) {
                            const selectedMobileCode:any = CountryCodeList_Language?.find(
                                (d:any) => d.code === mobileField
                            );

                            const countryCode = selectedMobileCode?.code;
                            // const mobileNo = `${selectedMobileCode.dial_code}${payload.value}`;
                            const mobileNo = `${payload.value}`;
                            payload.valueMobile = { countryCode, mobileNo };
                            delete payload.value;
                        }

                        return [...c, { ...payload, memberId }];
                    },

                    []
                );

                payload = groupedMultipleFields;
            } else {
                payload = [payload];
            }

            return [...a, ...payload];
        }, []);
    }

    const getCleanedFieldTree = (arr) => {
        return arr.reduce((x, y) => {
            if (!y.clientType?.length) {
                // remove
                return x;
            }

            return [
                ...x,
                {
                    ...y,
                    clientType: y.clientType.reduce((a, b) => {
                        if (!b.steps?.length) {
                            // remove
                            return a;
                        }

                        return [
                            ...a,
                            {
                                ...b,
                                steps: b.steps.reduce((c, d) => {
                                    if (!d.documentGroup?.length) {
                                        // remove
                                        return c;
                                    }

                                    return [
                                        ...c,
                                        {
                                            ...d,
                                            documentGroup: d.documentGroup.reduce((e, f) => {
                                                if (!f.documents?.length) {
                                                    // remove
                                                    return e;
                                                }

                                                return [
                                                    ...e,
                                                    {
                                                        ...f,
                                                        documents: f.documents.reduce((g, h) => {
                                                            if (!h.fieldList?.length) {
                                                                // remove
                                                                return g;
                                                            }
                                                            return [...g, h];
                                                        }, []),
                                                    },
                                                ];
                                            }, []),
                                        },
                                    ];
                                }, []),
                            },
                        ];
                    }, []),
                },
            ];
        }, []);
    }

    const getDocumentGroupStatus = (documentGroup, verificationRecord) => {
        const documentIds = documentGroup?.documents?.reduce(
            (a, b) => [...a, b?.document?._id],
            []
        );

        if (!verificationRecord) {
            return;
        }

        const documentRecords = [
            ...(verificationRecord?.recordDocument?.filter(
                (d) =>
                    documentIds.includes(d?.document?._id) &&
                    d?.group?._id === documentGroup?.group?._id &&
                    d?.status !== "cancelled" &&
                    d?.status !== "start"
            ) || []),
        ].sort(
            (a, b) =>
                new Date(b.clientStartTime).getTime() -
                new Date(a.clientStartTime).getTime()
        );

        if (!documentRecords) {
            return;
        }

        const status = VERIFICATION_STATUS_PRIORITY.find((status) =>
            documentRecords?.some((r) => r?.status === status)
        );

        return status;
    }

    const getDocumentStatus = (documentId, documentGroup, verificationRecord) => {
        const documentRecord = verificationRecord?.recordDocument?.filter(
            (d) =>
                d?.document?._id === documentId &&
                d?.group?._id === documentGroup?.group?._id &&
                d?.status !== "cancelled" &&
                d?.status !== "start"
        );

        const status = documentRecord?.[0]?.status;

        return status;
    }


    const getVerificationStepStatus = (step, verificationRecord) => {
        const groupStatus = step?.documentGroup?.map((group) =>
            getDocumentGroupStatus(group, verificationRecord)
        );

        const status = VERIFICATION_STATUS_PRIORITY.find((status) =>
            groupStatus?.some((r) => r === status)
        );

        return status;
    }

    const getRequiredDescription = ({requiredType, numberOfRequired}) => {
        switch (requiredType) {
            case "any": {
                if (!numberOfRequired) {
                    return null;
                }
                return t("accountVerification.pleaseCompleteAnySections", {
                    numberOfRequired: numberOfRequired,
                });
            }
            case "all": {
                return t("accountVerification.pleaseCompleteAllSections");
            }
            case "partially": {
                return t("accountVerification.pleaseCompleteRequiredSections");
            }

            default:
                return null;
        }
    }

    const statusToRibbonText = (status) => {
        if (!status) return

        const words = status?.split(" ");
        const capitalizedWords = words?.map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1)
        );
        return capitalizedWords.join(" ");
    };

    const RequiredTypeToMessage = (requiredType, anyAmount) => {
        const { t } = useTranslation();
        switch (requiredType) {
            case "all":
            case "partially":
                return t("accountVerification.completeAllSections");
            case "any":
                return t("accountVerification.completeAnySections", {
                    amount: anyAmount,
                });

            default:
                return null;
        }
    }

    const getFlatFieldList = (fieldList = []) => {
        return fieldList.reduce((a:any, b:any) => {
            let label = b.field.label;

            let settingId = b._id;

            let referenceId = b.field._id;
            const data = { label, settingId, referenceId, type: b.field.fieldType };
            const payload = [data];

            if (b.field.fieldType === "group") {
                b.field.fieldGroup.forEach((b) => {
                    const label = b?.label;
                    const referenceId = b?._id;
                    payload.push({ label, settingId, referenceId, type: b.fieldType });
                });
            }
            return [...a, ...payload];
        }, []);
    }


    return {
        DATE_FORMAT,
        VERIFICATION_STATUS_PRIORITY,
        getLastPartOfUrl,
        isAbleToSubmit,
        constructSubmitInput,
        getCleanedFieldTree,
        getDocumentGroupStatus,
        getDocumentStatus,
        getVerificationStepStatus,
        getRequiredDescription,
        statusToRibbonText,
        RequiredTypeToMessage,
        getFlatFieldList
    }
}