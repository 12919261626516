import {gql, useMutation, useQuery} from "@apollo/client";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {useTranslation} from "react-i18next";
import {ApolloClientQuery} from "./_apolloClient";

const GET_EWALLET_COUNT = gql`
    query getEWalletCount($filter: EWalletInput) {
        getEWalletCount(filter: $filter)
    }
`;

const getEWalletCount = ({filter}) => {
    try {
        const {loading, error, data} = useQuery(GET_EWALLET_COUNT, {
            variables: {
                filter: {
                    user: filter?.user || null,
                    balance: filter?.balance || null,
                },
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_EWALLET_BY_ID = gql`
    query getEWalletByID($id: String!) {
        getEWalletByID(id: $id) {
            ... on EWallet {
                _id
                id
                user {
                    fullName
                }
                balance
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getEWalletById = (id) => {
    try {
        const {loading, error, data} = useQuery(GET_EWALLET_BY_ID, {
            variables: {
                id: id,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_EWALLET = gql`
    query getEWallet($filter: EWalletInput, $limit: Int, $offset: Int, $orderBy: String) {
        getEWallet(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on EWalletArray {
                data {
                    _id
                    id
                    user {
                        fullName
                    }
                    balance
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getEWallet = ({filter}, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data} = useQuery(GET_EWALLET, {
            variables: {
                filter: {
                    user: filter?.user || null,
                    balance: filter?.balance || null,
                },
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_EWALLET_RECORD = gql`
    query getClientEWalletRecord($filter: EWalletClientSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
        getClientEWalletRecord(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on EWalletRecordArray {
                data {
                    transactionDate
                    transactionNo
                    eWalletTransactionId
                    description
                    remark
                    transactionType
                    amount
                    transactionStatus
                    comment
                }
                totalDeposit
                totalWithdrawal
                netAdjustment
                netDeposit
                pendingWithdrawal
                pendingDeposit
                count
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getClientEWalletRecord = ({filter}, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data} = useQuery(GET_EWALLET_RECORD, {
            variables: {
                filter: filter,
                limit: limit,
                offset: offset,
                orderBy: orderBy,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_EWALLET = gql`
    mutation addEWallet($input: EWalletInput) {
        addEWallet(input: $input) {
            ... on EWallet {
                _id
                id
                user {
                    fullName
                }
                balance
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useAddEWalletMutation = (): {addEWallet: any; data: any; loading: boolean; error: any} => {
    try {
        const [addEWalletMutation, {data, loading, error}] = useMutation(ADD_EWALLET);

        const addEWallet = (input) => {
            return addEWalletMutation({
                variables: {
                    input: {
                        user: input.user || null,
                        balance: input.balance || null,
                    },
                },
            });
        };

        return {addEWallet, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {addEWallet: null, data: null, loading: false, error: err};
    }
};

const UPDATE_EWALLET = gql`
    mutation updateEWallet($id: String!, $input: EWalletInput) {
        updateEWallet(id: $id, input: $input) {
            ... on EWallet {
                _id
                id
                user {
                    fullName
                }
                balance
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useUpdateEWalletMutation = (): {updateEWallet: any; data: any; loading: boolean; error: any} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [updateEWalletMutation, {data, loading, error}] = useMutation(UPDATE_EWALLET);

        const updateEWallet = (id, input) => {
            return updateEWalletMutation({
                variables: {
                    id: id,
                    input: {
                        user: input.user || null,
                        isFreezed: input.isFreezed || null,
                    },
                },
                update(cache, {data: {updateEWallet}}) {
                    const typename = updateEWallet?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateEWallet?.errKey),
                            key: "updateEWallet",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateEWallet?.msg),
                            key: "updateEWallet",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update EWallet Successfully"),
                            key: "updateEWallet",
                        });
                    }
                },
            });
        };

        return {updateEWallet, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {updateEWallet: null, data: null, loading: false, error: err};
    }
};

const DELETE_EWALLET = gql`
    mutation deleteEWallet($id: String!) {
        deleteEWallet(id: $id) {
            ... on EWallet {
                _id
                id
                isDeleted
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useDeleteEWalletMutation = (): {deleteEWallet: any; data: any; loading: boolean; error: any} => {
    try {
        const [deleteEWalletMutation, {data, loading, error}] = useMutation(DELETE_EWALLET);

        const deleteEWallet = (id) => {
            return deleteEWalletMutation({
                variables: {
                    id: id,
                },
            });
        };

        return {deleteEWallet, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {deleteEWallet: null, data: null, loading: false, error: err};
    }
};

const GET_EWALLET_STATEMENT_WITH_TASK = gql`
    query getEWalletStatementWithTask($filter: EWalletStatementSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
        getEWalletStatementWithTask(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on EWalletStatementArray {
                data {
                    _id
                    id
                    statementDate
                    type
                    description
                    # user {
                    #     _id
                    #     username
                    # }
                    debit
                    credit
                    balance
                    refNo
                    eWalletTransactionId
                    transactionType
                    importId
                    createdAt
                    updatedAt
                    task {
                        errMsg
                        comment
                        payload
                        result
                        status
                        approvedBy {
                            _id
                            username
                        }
                        requestedBy {
                            _id
                            username
                        }
                        _id
                    }
                }
                totalDeposit
                totalWithdrawal
                netAdjustment
                netDeposit
                pendingWithdrawal
                pendingDeposit
                commission
                count
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;

const getEWalletStatementWithTask = async ({filter}, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_EWALLET_STATEMENT_WITH_TASK,
            {
                filter: filter,
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

export {
    getEWalletCount,
    getEWalletById,
    getEWallet,
    getClientEWalletRecord,
    useAddEWalletMutation,
    useUpdateEWalletMutation,
    useDeleteEWalletMutation,
    GET_EWALLET_RECORD,
    getEWalletStatementWithTask,
    GET_EWALLET_STATEMENT_WITH_TASK,
};
