import React from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {fieldListFormPropState, fieldListLoading, fieldListState} from "../../../state";
import {Button, Card, Col, Empty, List, Row, Space, Spin, Tag, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {BsBookmarkStar, BsFillBookmarkStarFill} from "react-icons/bs";
import {RiLoginCircleFill, RiLoginCircleLine} from "react-icons/ri";
import {TbFingerprint, TbFingerprintOff} from "react-icons/tb";
import {useUpdateFieldListMutation} from "../../../../../../api/graphql/fieldsSetting";
import {AiFillEyeInvisible, AiOutlineEdit, AiOutlineEye} from "react-icons/ai";
import useAuthorize from "../../../../../../_common/authorize";

const FieldSetting = (props) => {
    const {fieldListPermission} = props
    const fieldList = useRecoilValue(fieldListState)
    const loading = useRecoilValue(fieldListLoading)
    const {t} = useTranslation()

    const {updateFieldList} = useUpdateFieldListMutation()
    const {systemErrorNotification} = useAuthorize()
    const setFieldListFormProp = useSetRecoilState<any>(fieldListFormPropState)

    const uniqueIcon = (item) => {
        const handleUniqueButtonClick = () => {
            try {
                const newIsUnique = !item?.isUnique;
                updateFieldList(item?._id, {isUnique: newIsUnique});
            } catch (e) {
                systemErrorNotification()
            }
        };

        const uniqueButtonIcon = item?.isUnique ? <TbFingerprint/> : <TbFingerprintOff/>;
        const uniqueButtonTitle = item?.isUnique ? t("Is Unique") : t("Is Not Unique");

        return (
            <Tooltip title={uniqueButtonTitle}>
                <Button
                    type="link"
                    icon={uniqueButtonIcon}
                    onClick={handleUniqueButtonClick}
                    disabled={!fieldListPermission?.edit?.edit}
                />
            </Tooltip>
        );

    }

    const requiredIcon = (item) => {
        const handleRequiredButtonClick = () => {
            try {
                const newIsRequired = !item?.isRequired;
                updateFieldList(item?._id, {isRequired: newIsRequired});
            } catch (e) {
                systemErrorNotification()
            }
        };

        const requiredButtonIcon = item?.isRequired ? <BsFillBookmarkStarFill/> : <BsBookmarkStar/>;
        const requiredButtonTitle = item?.isRequired ? t("Is Required") : t("Is Not Required");

        return (
            <Tooltip title={requiredButtonTitle}>
                <Button
                    type="link"
                    icon={requiredButtonIcon}
                    onClick={handleRequiredButtonClick}
                    disabled={!fieldListPermission?.edit?.edit}
                />
            </Tooltip>
        );

    }

    const sensitiveIcon = (item) => {
        const handleSensitiveButtonClick = () => {
            try {
                const newIsSensitive = !item?.isSensitive;
                updateFieldList(item?._id, {isSensitive: newIsSensitive});
            } catch (e) {
                systemErrorNotification()
            }
        };

        const sensitiveButtonIcon = item?.isSensitive ? <AiFillEyeInvisible/> : <AiOutlineEye/>;
        const sensitiveButtonTitle = item?.isSensitive ? t("Is Sensitive") : t("Is Not Sensitive");

        return (
            <Tooltip title={sensitiveButtonTitle}>
                <Button
                    type="link"
                    icon={sensitiveButtonIcon}
                    onClick={handleSensitiveButtonClick}
                    disabled={!fieldListPermission?.edit?.edit}
                />
            </Tooltip>
        );
    }

    const usedForLoginIcon = (item) => {
        const handleUsedForLoginButtonClick = () => {
            try {
                const newIsUsedForLogin = !item?.isUsedForLogin;
                updateFieldList(item?._id, {isUsedForLogin: newIsUsedForLogin});
            } catch (e) {
                systemErrorNotification()
            }
        };

        const usedForLoginButtonIcon = item?.isUsedForLogin ? <RiLoginCircleFill/> : <RiLoginCircleLine/>;
        const usedForLoginButtonTitle = item?.isUsedForLogin ? t("Is Used For Login") : t("Is Not Used For Login");

        return (
            <Tooltip title={usedForLoginButtonTitle}>
                <Button
                    type="link"
                    icon={usedForLoginButtonIcon}
                    onClick={handleUsedForLoginButtonClick}
                    disabled={!fieldListPermission?.edit?.edit}
                />
            </Tooltip>
        );

    }

    let categoryColor: string
    return (
        <div className={"margin-top-0-75"}>
            {
                fieldList?.length > 0 ?
                    <List
                        grid={{gutter: 16, column: 4}}
                        dataSource={fieldList}
                        rowKey={(item => item?._id)}
                        renderItem={(item: any) => {
                            switch (item?.category) {
                                case "basicInformation" :
                                    categoryColor = "gold"
                                    break
                                case "loginInformation" :
                                    categoryColor = "lime"
                                    break
                                case "residentialInformation" :
                                    categoryColor = "green"
                                    break
                                case "additionalInformation" :
                                    categoryColor = "geekblue"
                                    break
                                case "corporateInformation" :
                                    categoryColor = "purple"
                                    break
                                case "registrationInformation" :
                                    categoryColor = "cyan"
                                    break
                                case "demoInformation" :
                                    categoryColor = "default"
                                    break
                            }

                            return (
                                <List.Item key={item?._id}>
                                    <Card className={"field-card"} key={item?._id}>
                                        <Row>
                                            <Col flex={"auto"}>
                                                <Row justify={"space-between"}>
                                                <h4>{t(item?.label)}{item?.isRequired ?
                                                    <span style={{color: "red"}}> * </span> : undefined}</h4>
                                                    <Space size={"small"}>
                                                        {fieldListPermission?.edit?.edit &&
                                                        <Tooltip title={t("common.edit")}>
                                                            <Button type={"link"}
                                                                    onClick={() => {
                                                                        setFieldListFormProp({
                                                                            isOpen: true,
                                                                            type: "general",
                                                                            item: item,
                                                                            close: () => {
                                                                                setFieldListFormProp({isOpen: false})
                                                                            }
                                                                        })
                                                                    }}
                                                                    icon={<AiOutlineEdit />}
                                                            />
                                                        </Tooltip>
                                                        }
                                                        {/*{*/}
                                                        {/*    (*/}
                                                        {/*        (item?.fieldName === "email" && item?.isUnique && item?.isRequired) ||*/}
                                                        {/*        (item?.fieldName === "username" && item?.isUnique && item?.isRequired) ||*/}
                                                        {/*        (item?.fieldName === "mobilNo" && item?.isUnique && item?.isRequired)) &&*/}
                                                        {/*    <>*/}
                                                        {/*        {usedForLoginIcon(item)}*/}
                                                        {/*    </>*/}
                                                        {/*}*/}
                                                        {/*{requiredIcon(item)}*/}
                                                        {/*{uniqueIcon(item)}*/}
                                                        {/*{sensitiveIcon(item)}*/}
                                                    </Space>
                                                </Row>
                                                <Tag color={"blue"}
                                                     bordered={false}><small>{item?.fieldType?.toUpperCase()}</small></Tag>
                                                <Tag color={categoryColor}
                                                     bordered={false}><small>{item?.category?.replace(/(INFORMATION)/i, "")?.toUpperCase()}</small></Tag>
                                                {
                                                    item?.isConfirmRequired &&
                                                    <Tag color="magenta" style={{marginTop: 4}}
                                                         bordered={false}><small>{t("CONFIRM REQUIRED")}</small></Tag>
                                                }
                                                {
                                                    item?.isSensitive &&
                                                    <Tag color="volcano"
                                                         bordered={false}><small>{t("SENSITIVE")}</small></Tag>
                                                }
                                                {
                                                    item?.isUnique &&
                                                    <Tag color="orange"
                                                         bordered={false}><small>{t("UNIQUE")}</small></Tag>
                                                }
                                                {
                                                    item?.isUsedForLogin &&
                                                    <Tag color="red"
                                                         bordered={false}><small>{t("USED FOR LOGIN")}</small></Tag>
                                                }
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )
                        }}
                    /> : loading ? (
                        <Col span={24} style={{marginTop: "20%", textAlign: "center"}}>
                            <Spin size="large"/>
                        </Col>
                    ) : (
                        <Col span={24} style={{paddingTop: "20%"}}>
                            <Empty/>
                        </Col>
                    )}
        </div>
    )
}

export default FieldSetting