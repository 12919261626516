import React, {useCallback, useEffect, useState} from "react";
import {useClientDetailFunction} from "../../../../function";
import {useRecoilValue} from "recoil";
import {userVerificationRecordLoadingState, userVerificationRecordValueState} from "../../../../../state";
import {Col, Collapse, Empty, Image, Input, Rate, Row, Spin} from "antd";
import TextArea from "antd/es/input/TextArea";
import {formatDate} from "../../../../../../_common/function";
import {useTranslation} from "react-i18next";

const RepresentativeInformation = ({userId}) => {
    const {Panel} = Collapse
    const {t} = useTranslation()
    const {getUserVerificationRecordValueFunction} = useClientDetailFunction()
    const userVerificationRecordValue = useRecoilValue<any>(userVerificationRecordValueState)
    const userVerificationRecordValueLoading = useRecoilValue<any>(userVerificationRecordLoadingState)
    const [data, setData] = useState<any>([])


    useEffect(() => {
        getUserVerificationRecordValueFunction({user: userId})
    }, [userId])

    const processData = useCallback(async (data) => {
        const representativeInfoData = await data?.filter(item => item?.status === "verified" && item?.fieldSetting?.isRepresentativeInfo) || []

        if (representativeInfoData?.length > 0) {
            const formattedData = await representativeInfoData.reduce((acc, currentValue) => {
                const existingMember = acc?.find(item => item?.id === currentValue?.memberId)

                if (existingMember) {
                    existingMember.fieldValues?.push(currentValue)
                } else {
                    acc?.push({
                        id: currentValue?.memberId,
                        fieldValues: [currentValue]
                    })
                }

                return acc
            }, [])

            setData(formattedData)
        }
    }, [userVerificationRecordValue])

    const renderField = (data) => {
        let component
        switch (data?.referenceId?.fieldType) {
            case "textarea":
                component = <TextArea value={data?.value} disabled/>
                break
            case "date":
                component =
                    <Input value={formatDate(data?.value)} disabled/>
                break
            case "image":
                component =
                    <Image height={100} style={{objectFit: "cover"}}
                           src={data?.value}/>
                break
            case "multiSelect":
                component = <Input value={data?.value?.join(", ")} disabled/>
                break
            case "rate":
                component = <Rate value={parseInt(data?.value)} disabled/>
                break
            default :
                component = <Input value={data?.value} disabled/>
                break
        }

        return (
            <Col span={12} key={data?._id}>
                <div>
                    <small>{t(data?.referenceId?.label)}</small>
                </div>
                <div>{data?.value ? component : t("EMPTY")}</div>
            </Col>
        )
    }


    useEffect(() => {
        if (userVerificationRecordValue?.length > 0) {
            processData(userVerificationRecordValue)
        }
    }, [userVerificationRecordValue])

    return (
        <div className={"margin-top-0-75"}>
            <Spin spinning={userVerificationRecordValueLoading}>
                { data?.length > 0 ?
                    <Collapse bordered={false} defaultActiveKey={['1']}>
                        {data && data?.map((d, idx) => {
                            return (
                                <Panel header={`Member ${idx + 1}`} key={d?.id}>
                                    <Row gutter={[16, 16]}>
                                        {
                                            d?.fieldValues?.sort((a,b) => a?.referenceId?.label.localeCompare(b?.referenceId?.label))?.map(d => {
                                                return renderField(d)
                                            })
                                        }
                                    </Row>
                                </Panel>
                            )
                        })}
                    </Collapse>
                    :
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                }
            </Spin>
        </div>
    )
}

export default RepresentativeInformation