import React, {useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {Button, Checkbox, Col, Collapse, Form, Input, List, Modal, Row, Space, Spin, Tag, Tooltip} from "antd";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {horizontalFormLayout, verticalFormLayout} from "../../../../../../components/form/formWrapper";
import arithmeticData from "../../../data/arithmetic.json";
import {
    reportingAllState,
    reportingAllFieldsState,
    reportingAllFunctionsState,
    reportingAllFieldIdentifiersState,
} from "../../../state";
import {
    getReportingLastFunctionField,
    getReportingLinkToRelationship,
    getReportingReportIdentifierByReport,
    getReportingValidateOperation,
} from "../../../../../../api/graphql/reporting";
import InputButtonFormItem from "../../../../../../components/form/inputButtonFormItem";
import SelectFormItem from "../../../../../../components/form/selectFormItem";
import {
    // extractUniqueAlphanumericWordsInParentheses,
    extractUniqueAlphanumericWordsWithRegex,
    getTextBeforeOpeningParentheses,
} from "../../../function/common";
import { notificationMessage } from "../../../../../../../recoil_state";

const labelCol = {span: 8, offset: 0};

const yupSchema = yup.object().shape({
    // name: yupFormSchema.string("name", {required: true}),
});

const EditOperationModal = (props) => {
    const {
        formMode,
        item,
        // itemIndex,
        groupBy,
        isModalOpen,
        toggleModal,
        // currentColumnValue,
        setCurrentColumnValue,
        columnsSelection,
        // availableReports,
        baseReportIds,
    } = props;
    const {t} = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const reportingAllFields = useRecoilValue<any>(reportingAllFieldsState);
    const reportingFunctions = useRecoilValue<any>(reportingAllFunctionsState);
    const reportingFieldIdentifiers = useRecoilValue<any>(reportingAllFieldIdentifiersState);
    const reportingAll = useRecoilValue(reportingAllState);
    const [groupedFieldsSelections, setGroupedFieldsSelection] = useState<any[]>([]);
    const [selectedFields, setSelectedFields] = useState<any[]>([]);
    const [selectedFieldsObj, setSelectedFieldsObj] = useState<any[]>([]);
    const [operationMsg, setOperationMsg] = useState({status: "", msg: ""});
    const [isLoading, setIsLoading] = useState(false);
    const [searchValueReports, setSearchValueReports] = useState("");
    const [searchValueFields, setSearchValueFields] = useState("");
    const [linkable, setLinkable] = useState(false);
    const [adminPanelLinkable, setAdminPanelLinkable] = useState(false);
    const [enableChangeDisplayName, setEnableChangeDisplayName] = useState(false);
    const [enableChangeDisplayMode, setEnableChangeDisplayMode] = useState(false);
    const [mustGroupBy, setMustGroupBy] = useState(false);
    const [isLinkSelected, setIsLinkSelected] = useState(false);
    const [isChgDisplayNameSelected, setIsChgDisplayNameSelected] = useState(false);
    const [isChgDisplayModeSelected, setIsChgDisplayModeSelected] = useState(false);
    const [isLiveData, setIsLiveData] = useState(false);
    const [availableLinkToReports, setAvailableLinkToReports] = useState<any[]>([]);
    const [availableLinkToReportsFields, setAvailableLinkToReportsFields] = useState<any[]>([]);
    const operationInputRef: any = useRef();

    const [initialValues] = useState(() => ({
        operation: item?.operation || "",
        linkedTo: item?.linkedTo || "",
        linkedToReportId: item?.linkedToReportId || "",
        linkedToReportFieldName: item?.linkedToReportFieldName || "",
        displayFieldName: item?.displayFieldName || "" || "",
        displayMode: item?.displayMode || "DEFAULT",
    }));

    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
        defaultValues: initialValues,
    });

    const linkedTo = form?.getValues()?.linkedTo;
    const linkedToReportId = form?.getValues()?.linkedToReportId;
    const operation = form?.getValues()?.operation;

    const groupFieldsByReportId = () => {
        const grouped = columnsSelection.reduce((result, obj) => {
            const {reportId} = obj;
            (result[reportId] = result[reportId] || []).push(obj);
            return result;
        }, {});

        const resultArray = Object.keys(grouped).map((reportId) => {
            const reportObj: any = reportingAll?.find((v: any) => v?.reportId?.toString() === reportId);
            const reportName = reportObj?.displayName;
            return {
                reportId,
                reportName,
                level: reportObj?.level,
                groupBy: reportObj?.groupBy,
                fields: grouped[reportId],
                totalFields: grouped[reportId]?.length,
            };
        });

        resultArray.sort((a, b) => a.reportName?.localeCompare(b.reportName));
        setGroupedFieldsSelection(resultArray);
    };

    const getSelectedFields = () => {
        try {
            if (item?.keys) {
                setSelectedFields(item?.keys);
            }
        } catch (error) {}
    };

    const getSelectedFieldsObj = () => {
        try {
            if (item?.keys) {
                setSelectedFieldsObj(item?.keysObj);
            }
        } catch (error) {}
    };

    const getMatchedFieldIdentifier = () => {
        try {
            setSelectedFieldsObj((prevState) => {
                return prevState?.map((obj) => {
                    if (baseReportIds?.length > 0 && baseReportIds[0] && reportingFieldIdentifiers?.length > 0) {
                        const matchedFieldIdentifiers = reportingFieldIdentifiers.find(
                            (v) => v?.fromReportId === obj?.reportId && v?.toReportId === baseReportIds[0]
                            // (v) => v?.toReportId === obj?.reportId // debug
                        );

                        return matchedFieldIdentifiers
                            ? {
                                  ...obj,
                                  fieldFiltersOptions: matchedFieldIdentifiers?.identifiers,
                                  fieldFiltersSelected: obj?.fieldFiltersSelected || "",
                              }
                            : obj;
                    } else if (baseReportIds?.length === 0) {
                        const matchedFieldIdentifiers = reportingFieldIdentifiers.find(
                            (v) => v?.fromReportId === obj?.reportId
                            // (v) => v?.toReportId === obj?.reportId // debug
                        );

                        return matchedFieldIdentifiers
                            ? {
                                  ...obj,
                                  fieldFiltersOptions: matchedFieldIdentifiers?.identifiers,
                                  fieldFiltersSelected: obj?.fieldFiltersSelected || "",
                              }
                            : obj;
                    }
                    return obj;
                });
            });
        } catch (error) {
            // Handle errors if needed
        }
    };

    const getMatchedAdditionalFieldIdentifier = async () => {
        try {
            if (operation) {
                const response = await getReportingLastFunctionField(operation);
                const fieldObj = response?.getReportingLastFunctionField?.data;
                let reportFieldIdentifiers: any = [];
                if (fieldObj) {
                    const resReportIdentifier = await getReportingReportIdentifierByReport(fieldObj?.reportId);
                    reportFieldIdentifiers = resReportIdentifier?.getReportingReportIdentifierByReport?.data?.payload;
                }
                setSelectedFieldsObj((prevState) => {
                    return prevState?.map((obj) => {
                        if (fieldObj?.key === obj?.key) {
                            return {
                                ...obj,
                                additionalFieldFiltersOptions: reportFieldIdentifiers?.[0]?.identifiers,
                                additionalFieldFiltersSelected: obj?.additionalFieldFiltersSelected || "",
                            };
                        }
                        return obj;
                    });
                });
            }
        } catch (error) {
            // Handle errors if needed
        }
    };

    const handleKeysChange = (checkedValue, col) => {
        try {
            const updatedFields = checkedValue ? [...selectedFields, col?.key] : selectedFields.filter((v) => v !== col?.key);
            let updatedFieldsObj = checkedValue ? [...selectedFieldsObj, col] : selectedFieldsObj.filter((v) => v?.key !== col?.key);
            setSelectedFieldsObj(updatedFieldsObj);
            setSelectedFields(updatedFields);
        } catch (error) {}
    };

    useEffect(() => {
        getMatchedFieldIdentifier();
        getMatchedAdditionalFieldIdentifier();
    }, [operation, selectedFields]);

    const handleFieldTypeChange = (value, fieldId) => {
        try {
            setSelectedFieldsObj((prevState) => {
                const newFieldsObj = prevState?.map((v) => {
                    if (v?.fieldId === fieldId) {
                        return {...v, fieldFiltersSelected: value};
                    } else {
                        return v;
                    }
                });
                return newFieldsObj;
            });
        } catch (error) {}
    };

    const handleAddtionalFieldFilterChange = (value, fieldId) => {
        try {
            setSelectedFieldsObj((prevState) => {
                const newFieldsObj = prevState?.map((v) => {
                    if (v?.fieldId === fieldId) {
                        return {...v, additionalFieldFiltersSelected: value};
                    } else {
                        return v;
                    }
                });
                return newFieldsObj;
            });
        } catch (error) {}
    };

    const insertKeyToOperation = (value, type?) => {
        try {
            const inputElement: any = document.getElementById("operation");
            const startPos = inputElement?.selectionStart;
            const endPos = inputElement?.selectionEnd;
            const formValues = form.getValues();
            const inputValue = formValues?.operation;

            // const newInputValue = inputValue ? inputValue.substring(0, startPos) + value + inputValue.substring(endPos) : value;
            // form.setValue("operation", newInputValue); // To check - got issue

            // Input at the end of the current operation value
            let newInputValue = inputValue ? inputValue + "." + value : value;

            switch (type) {
                case "function":
                    newInputValue = inputValue ? inputValue + "." + value : value;
                    break;
                default:
                    newInputValue = inputValue + value;
                    break;
            }

            form.setValue("operation", newInputValue);

            inputElement?.setSelectionRange(startPos + value?.length, startPos + value?.length);
            inputElement?.focus();
        } catch (error) {}
    };

    const handleCheckOperation = async (operation, checkType) => {
        try {
            setIsLoading(true);
            const response = await getReportingValidateOperation(operation, groupBy);
            const res = response?.getReportingValidateOperation;
            if (res?.data?.valid) {
                let successMsg = res?.data?.message;
                setOperationMsg({status: "success", msg: successMsg});
                setLinkable(res?.data?.linkable);
                setAdminPanelLinkable(res?.data?.adminPanelLinkable);
                setEnableChangeDisplayName(res?.data?.enableChangeDisplayName);
                setEnableChangeDisplayMode(res?.data?.enableChangeDisplayMode);
                setMustGroupBy(res?.data?.mustGroupBy);
                if (res?.data?.linkable) {
                    getLinkToReportRelationship(operation);
                    // if (checkType === "initial") {
                    //     setIsLinkSelected(true);
                    // }
                }
                return true;
            } else if (res?.errObj?.status === "Fail") {
                const errMsg = res?.errObj?.message;
                setOperationMsg({status: "error", msg: errMsg});
                return false;
            } else {
                const errMsg = res?.errObj?.errors?.operation?.[0];
                setOperationMsg({status: "error", msg: errMsg});
                return false;
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const getLinkToReportRelationship = async (operation) => {
        try {
            const response = await getReportingLinkToRelationship(operation, groupBy);
            const res = response?.getReportingLinkToRelationship;
            if (res?.__typename === "ReportingData") {
                const availableFields = res?.data?.fields?.fields;
                const availableReportIds = res?.data?.reportIds?.reportIds;
                const availableReportNames = res?.data?.reportNames?.reportNames;
                let reports = availableReportIds?.map((id, index) => ({displayName: availableReportNames?.[index], reportId: id}));
                setAvailableLinkToReports(reports);
                setAvailableLinkToReportsFields(availableFields);
            }
        } catch (error) {}
    };

    const onSubmit = async () => {
        try {
            const formValues = form.getValues();
            const isOperationValid = formMode === "add" ? await handleCheckOperation(form?.getValues()?.operation, "form") : true;
            let isValid = false;
            const usedFieldsList = extractUniqueAlphanumericWordsWithRegex(operation);
            isValid = usedFieldsList?.every((key) => {
                let obj = selectedFieldsObj?.find((field) => field?.key === key);
                if (obj && obj?.additionalFieldFiltersOptions?.length && !obj?.additionalFieldFiltersSelected) {
                    return false;
                }
                return true;
            });
            if (!isValid) {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: "Please select Additional Filter for Selected Fields",
                    key: "addtionalFieldFiltersError",
                });
            }
            if (isOperationValid && isValid) {
                const fieldFilters = selectedFieldsObj
                    ?.filter((obj) => obj.fieldFiltersSelected)
                    ?.map((v) => ({
                        fieldKey: v.key,
                        identifiers: v?.fieldFiltersSelected,
                    }));

                const additionalFieldFilters = selectedFieldsObj
                    ?.filter((obj) => obj.additionalFieldFiltersSelected)
                    ?.map((v) => ({
                        fieldKey: v.key,
                        identifiers: v?.additionalFieldFiltersSelected,
                    }));

                setCurrentColumnValue({
                    ...formValues,
                    keys: selectedFields,
                    keysObj: selectedFieldsObj,
                    linkedTo: isLinkSelected ? formValues?.linkedTo : null,
                    linkedToReportId: isLinkSelected ? formValues?.linkedToReportId : null,
                    linkedToReportFieldName: isLinkSelected ? formValues?.linkedToReportFieldName : null,
                    displayFieldName: isLinkSelected ? formValues?.displayFieldName : null,
                    displayMode: isChgDisplayModeSelected ? formValues?.displayMode : null,
                    isLiveData: linkable ? isLiveData : null,
                    fieldFilters: fieldFilters ?? [],
                    additionalFieldFilters: additionalFieldFilters ?? [],
                    mustGroupBy: mustGroupBy ?? [],
                });
                toggleModal("");
            }
        } catch (error) {}
    };

    useEffect(() => {
        groupFieldsByReportId();
        getSelectedFields();
        getSelectedFieldsObj();
        if (item?.operation) {
            handleCheckOperation(item?.operation, "initial");
        }
        if (item?.linkedTo) {
            setIsLinkSelected(true);
        }
        if (item?.displayFieldName) {
            setIsChgDisplayNameSelected(true);
        }
        if (item?.displayMode) {
            setIsChgDisplayModeSelected(true);
        }
        if (item?.mustGroupBy) {
            setMustGroupBy(true);
        }
        if (item?.isLiveData) {
            setIsLiveData(true);
        }
    }, [item, columnsSelection]);

    useEffect(() => {
        if (!isChgDisplayNameSelected) {
            form?.setValue("displayFieldName", "");
        }
        if (!isLinkSelected) {
            form?.setValue("linkedTo", "");
            form?.setValue("linkedToReportId", "");
            form?.setValue("linkedToReportFieldName", "");
        }
    }, [isLinkSelected, isChgDisplayNameSelected]);

    let renderFieldGroups = useMemo(() => {
        const fieldCheckBoxes = (groupCol) => {
            const filteredGroupCol = groupCol?.filter(
                (c) =>
                    c?.uiName?.toLowerCase()?.includes(searchValueFields?.toLowerCase()) ||
                    c?.key?.toLowerCase()?.includes(searchValueFields?.toLowerCase())
            );
            return (
                <Row>
                    {filteredGroupCol?.map((col: any, index: number) => {
                        let colKey = col?.key;
                        let isSelected = selectedFields?.includes(colKey);
                        return (
                            <Col key={index} span={8}>
                                <Checkbox
                                    key={index}
                                    checked={isSelected}
                                    onChange={(e) => handleKeysChange(e?.target?.checked, col)}
                                    className="keysCheckbox"
                                >
                                    {col?.uiName} <span className="colKey">[{colKey}]</span>
                                </Checkbox>
                            </Col>
                        );
                    })}
                </Row>
            );
        };

        const filteredGroupdFieldsSelections = groupedFieldsSelections?.filter(
            (group) =>
                group?.reportName?.toLowerCase().includes(searchValueReports?.toLowerCase()) &&
                group?.fields?.some(
                    (field) =>
                        field?.uiName.toLowerCase().includes(searchValueFields?.toLowerCase()) ||
                        field?.key?.toLowerCase()?.includes(searchValueFields?.toLowerCase())
                )
        );

        return (
            <>
                {filteredGroupdFieldsSelections?.map((group: any, index) => {
                    let groupCol = group?.fields;
                    let level = group?.level;
                    let groupBy = group?.groupBy;
                    let totalSelectedFieldsInGroup = groupCol?.filter((col) => selectedFields?.includes(col?.key))?.length;
                    return (
                        <Collapse key={index} size="small" expandIconPosition="end" className="keysCollapse">
                            <Collapse.Panel
                                key={group?.reportId}
                                // header={
                                //   <div>
                                //     {group?.reportName} ({group?.totalFields})
                                //   </div>
                                // }
                                header={
                                    <div>
                                        <Tag color="blue">Level {level}</Tag> {group?.reportName}{" "}
                                        {totalSelectedFieldsInGroup > 0 && <span className="colKey">({totalSelectedFieldsInGroup} selected)</span>}
                                        {groupBy && <Tag color="green">Group By</Tag>}
                                    </div>
                                }
                                children={fieldCheckBoxes(groupCol)}
                            />
                        </Collapse>
                    );
                })}
            </>
        );
    }, [groupedFieldsSelections, selectedFields, columnsSelection, searchValueReports, searchValueFields]);

    const operatorsSection = useMemo(() => {
        let buttons = arithmeticData?.map((v: any) => (
            <Tooltip key={v?.value} title={v?.description} placement="bottom">
                <Button
                    onClick={() => insertKeyToOperation(v?.value, "operator")}
                    style={{minWidth: 30, marginRight: "0.5rem", marginBottom: "0.5rem"}}
                >
                    {v?.label}
                </Button>
            </Tooltip>
        ));
        return (
            <>
                <label>Operators</label>
                <div>{buttons}</div>
            </>
        );
    }, []);

    const functionSection = useMemo(() => {
        const textBeforeParentheses = getTextBeforeOpeningParentheses(operation);

        // const sortedFunctionList = reportingFunctions?.sort((a, b) => a?.name.localeCompare(b?.name));
        // const sortedFunctionList = reportingFunctions.sort((a, b) => a.name.localeCompare(b.name));
        const sortedFunctionList = reportingFunctions
            ?.filter((item) => item != null) // Remove undefined or null items
            .sort((a, b) => (a?.name || "").localeCompare(b?.name || ""))
            .sort((a, b) => textBeforeParentheses?.includes(b?.name) - textBeforeParentheses?.includes(a?.name));

        return (
            <>
                <label>Functions</label>
                <div style={{height: 200, overflow: "scroll"}}>
                    <List
                        size="small"
                        bordered
                        dataSource={sortedFunctionList}
                        renderItem={(item: any, index: number) => {
                            const isUsedInOperation = textBeforeParentheses?.includes(item?.name);
                            return (
                                <List.Item
                                    onClick={() => insertKeyToOperation(item?.name + "(", "function")}
                                    className={isUsedInOperation ? "usedInOperation" : ""}
                                >
                                    <List.Item.Meta
                                        title={item?.name}
                                        description={
                                            <div>
                                                <p className="margin-bottom-0">{item?.description}</p>
                                                <p className="margin-bottom-0">Example: {item?.example}</p>
                                            </div>
                                        }
                                    />
                                </List.Item>
                            );
                        }}
                    />
                </div>
            </>
        );
    }, [reportingFunctions, operation]);

    const selectedFieldsSection = useMemo(() => {
        // const isAddionalFieldFilterShown = operation?.includes("SUBSET");
        let isAddionalFieldFilterShown = true;
        const usedFieldsList = extractUniqueAlphanumericWordsWithRegex(operation);
        const list = selectedFieldsObj?.map((fieldObj) => {
            const isUsedInOperation = usedFieldsList?.includes(fieldObj?.key);
            const fieldName = fieldObj?.uiName;
            const fieldReportName = fieldObj?.reportName;
            const fieldFiltersOptions = fieldObj?.fieldFiltersOptions;
            const fieldFiltersSelected = fieldObj?.fieldFiltersSelected;
            const additionalFieldFiltersOptions = fieldObj?.additionalFieldFiltersOptions;
            const additionalFieldFiltersSelected = fieldObj?.additionalFieldFiltersSelected;
            return (
                <>
                    <Col span={isAddionalFieldFilterShown ? 8 : 12} className="margin-bottom-0-5">
                        <div className="display-flex align-items-center">
                            <Tooltip title={`Report: ${fieldReportName}`}>
                                <div
                                    key={fieldObj?.key}
                                    onClick={() => insertKeyToOperation(fieldObj?.key, "key")}
                                    className={`fieldButton ${isUsedInOperation ? "usedInOperation" : ""}`}
                                >
                                    {/* {fieldReportName} - {fieldName} <span className="colKey margin-left-0-25">[{key}]</span> */}
                                    {fieldName} <span className="colKey margin-left-0-25">[{fieldObj?.key}]</span>
                                </div>
                            </Tooltip>
                            {!isUsedInOperation && (
                                <Tooltip title="Remove">
                                    <div className="clickable" onClick={() => handleKeysChange(false, fieldObj)}>
                                        <CloseOutlined />
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    </Col>
                    <Col span={isAddionalFieldFilterShown ? 8 : 12}>
                        <div className="display-flex">
                            {fieldFiltersOptions?.map((opt) => {
                                const selectedOpt = opt === fieldFiltersSelected;
                                return (
                                    <div
                                        key={opt}
                                        onClick={() => handleFieldTypeChange(opt, fieldObj?.fieldId)}
                                        className={`fieldButton ${selectedOpt ? "usedInOperation" : ""}`}
                                    >
                                        {opt}
                                    </div>
                                );
                            })}
                        </div>
                    </Col>
                    {isAddionalFieldFilterShown && (
                        <Col span={8}>
                            <div className="display-flex">
                                {additionalFieldFiltersOptions?.map((opt) => {
                                    const selectedOpt = opt === additionalFieldFiltersSelected;
                                    return (
                                        <div
                                            key={opt}
                                            onClick={() => handleAddtionalFieldFilterChange(opt, fieldObj?.fieldId)}
                                            className={`fieldButton ${selectedOpt ? "usedInOperation" : ""}`}
                                        >
                                            {opt}
                                        </div>
                                    );
                                })}
                            </div>
                        </Col>
                    )}
                </>
            );
        });
        return (
            <>
                {selectedFieldsObj?.length > 0 && (
                    <Row>
                        <Col span={isAddionalFieldFilterShown ? 8 : 12}>
                            <label>Selected Fields</label>
                        </Col>
                        <Col span={isAddionalFieldFilterShown ? 8 : 12}>
                            <label>Field Type/ Filter (optional)</label>
                        </Col>
                        {isAddionalFieldFilterShown && (
                            <Col span={8}>
                                <label>Additional Filter (compulsory)</label>
                            </Col>
                        )}
                    </Row>
                )}
                <Row>{list}</Row>
            </>
        );
    }, [selectedFields, selectedFieldsObj, item, operation]);

    const linkedToOptions = [
        {label: "Report", value: "report"}, //linkedToReportId
        {label: "Admin Panel", value: "adminPanel"}, //linkedAdminPanelPage
    ];

    const linkedToReportIdOptions = useMemo(() => {
        return availableLinkToReports?.map((v: any) => ({label: v?.displayName, value: v?.reportId}));
    }, [availableLinkToReports]);

    const linkedToReportFieldNameOptions = useMemo(() => {
        let fieldsByReportId = availableLinkToReportsFields?.filter((v: any) => v?.reportId === linkedToReportId);
        return fieldsByReportId?.map((v) => ({label: v?.uiName, value: v?.reportFieldName}));
    }, [availableLinkToReportsFields, linkedToReportId]);

    const displayFieldNameOptions = useMemo(() => {
        if (linkable) {
            const fieldLinkedByReportId = reportingAllFields?.find((v) => v?.key === operation)?.linkedByReportId;
            let fieldsByReportId = reportingAllFields?.filter((v: any) => v?.reportId === fieldLinkedByReportId);
            return fieldsByReportId?.map((v) => ({label: v?.uiName, value: v?.reportFieldName}));
        }
        return [];
    }, [reportingAllFields, operation, linkable]);

    const displayModeOptions = useMemo(() => {
        return [
            {label: "Default", value: "DEFAULT"},
            {label: "To Date", value: "TODATE"},
        ];
    }, []);

    const modalTitle = (
        <Row justify="space-between">
            <div>Edit Operation</div>
        </Row>
    );

    const OperationInputSection = useMemo(
        () => (
            <Row className="section">
                <Col className="gutter-row" xs={24} sm={24} md={24}>
                    <label>Operation</label>
                    <InputButtonFormItem
                        // ref={operationInputRef}
                        name={"operation"}
                        // label={"Operation"}
                        placeholder={"Operation"}
                        layout={verticalFormLayout}
                        // labelCol={labelCol}
                        labelAlign="left"
                        buttonLabel="Validate"
                        buttonOnClick={() => handleCheckOperation(form?.getValues()?.operation, "form")}
                        validateStatus={operationMsg?.status}
                        externalErrorMessage={operationMsg?.status === "error" ? operationMsg?.msg : ""}
                        autoComplete="off"
                        disabled={formMode !== "add"}
                    />
                </Col>
            </Row>
        ),
        [form, operationMsg, formMode]
    );

    const LinkableSection = () => (
        <Row justify="end">
            <Col className="display-flex justify-content-end" xs={24} sm={24} md={24}>
                {linkable && (
                    <>
                        <Checkbox checked={isLiveData} onChange={(e) => setIsLiveData(e.target.checked)} disabled={formMode !== "add"}>
                            Show Live Data
                        </Checkbox>
                        <Checkbox checked={isLinkSelected} onChange={(e) => setIsLinkSelected(e.target.checked)}>
                            Link
                        </Checkbox>
                        <Checkbox checked={isChgDisplayNameSelected} onChange={(e) => setIsChgDisplayNameSelected(e.target.checked)}>
                            Change display name
                        </Checkbox>
                    </>
                )}
                {enableChangeDisplayMode && (
                    <Checkbox checked={isChgDisplayModeSelected} onChange={(e) => setIsChgDisplayModeSelected(e.target.checked)}>
                        Change display mode
                    </Checkbox>
                )}
            </Col>
        </Row>
    );

    const DisplayModeSection = () => (
        <>
            {enableChangeDisplayMode && isChgDisplayModeSelected && (
                <Row>
                    <Col className="gutter-row" xs={24} sm={24} md={24}>
                        <SelectFormItem
                            name={"displayMode"}
                            label={t("Display Mode")}
                            placeholder={t("Display Mode")}
                            layout={horizontalFormLayout}
                            mode={"single"}
                            labelCol={labelCol}
                            labelAlign="left"
                            options={displayModeOptions}
                            showSearch
                            optionFilterProp="label"
                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                        />
                    </Col>
                </Row>
            )}
        </>
    );

    const DisplayFieldNameSection = () => (
        <>
            {(linkable || enableChangeDisplayName) && isChgDisplayNameSelected && (
                <Row>
                    <Col className="gutter-row" xs={24} sm={24} md={24}>
                        <SelectFormItem
                            name={"displayFieldName"}
                            label={t("Display Field Name")}
                            placeholder={t("Display Field Name")}
                            layout={horizontalFormLayout}
                            mode={"single"}
                            labelCol={labelCol}
                            labelAlign="left"
                            options={displayFieldNameOptions}
                            showSearch
                            optionFilterProp="label"
                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                        />
                    </Col>
                </Row>
            )}
        </>
    );

    const LinkedToSection = () => (
        <>
            {(linkable || adminPanelLinkable) && isLinkSelected && (
                <Row>
                    <Col className="gutter-row" xs={24} sm={24} md={24}>
                        <SelectFormItem
                            name={"linkedTo"}
                            label={t("Linked To")}
                            placeholder={t("Linked To")}
                            layout={horizontalFormLayout}
                            mode={"single"}
                            labelCol={labelCol}
                            labelAlign="left"
                            options={linkedToOptions}
                            showSearch
                            optionFilterProp="label"
                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                        />
                    </Col>
                    {linkedTo === "report" && (
                        <>
                            <Col className="gutter-row" xs={24} sm={24} md={24}>
                                <SelectFormItem
                                    name={"linkedToReportId"}
                                    label={t("Linked To Report")}
                                    placeholder={t("Linked To Report")}
                                    layout={horizontalFormLayout}
                                    mode={"single"}
                                    labelCol={labelCol}
                                    labelAlign="left"
                                    options={linkedToReportIdOptions}
                                    showSearch
                                    optionFilterProp="label"
                                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                />
                            </Col>
                            <Col className="gutter-row" xs={24} sm={24} md={24}>
                                <SelectFormItem
                                    name={"linkedToReportFieldName"}
                                    label={t("Linked To Report Field Name")}
                                    placeholder={t("Linked To Report Field Name")}
                                    layout={horizontalFormLayout}
                                    mode={"single"}
                                    labelCol={labelCol}
                                    labelAlign="left"
                                    options={linkedToReportFieldNameOptions}
                                    showSearch
                                    optionFilterProp="label"
                                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                />
                            </Col>
                        </>
                    )}
                </Row>
            )}
        </>
    );

    const FieldsSection = useMemo(
        () => (
            <>
                {formMode === "add" && (
                    <>
                        <Row gutter={10} align="middle" className="margin-top-0-5 margin-bottom-0-5">
                            <Col span={8}>
                                <label>Fields ({columnsSelection?.length})</label>
                            </Col>
                            {/* <Col span={16} className="keysSection">
                                        {selectedFieldsList}
                                    </Col> */}
                        </Row>
                        <Row gutter={10} className="margin-bottom-0-5">
                            <Col span={12}>
                                <Input.Search
                                    value={searchValueReports}
                                    onChange={(e) => setSearchValueReports(e?.target?.value)}
                                    allowClear
                                    // enterButton="Search Report"
                                    placeholder="Search Report"
                                />
                            </Col>
                            <Col span={12}>
                                <Input.Search
                                    value={searchValueFields}
                                    onChange={(e) => setSearchValueFields(e?.target?.value)}
                                    allowClear
                                    // enterButton="Search Fields"
                                    placeholder="Search Fields"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>{renderFieldGroups}</Col>
                        </Row>
                    </>
                )}
            </>
        ),
        [columnsSelection, searchValueReports, searchValueFields, renderFieldGroups]
    );

    return (
        <Modal
            title={modalTitle}
            open={isModalOpen}
            width={"80%"}
            footer={null}
            destroyOnClose
            getContainer="#reports"
            className="editOperationModal"
            maskClosable={false}
            onCancel={() => toggleModal("")}
        >
            <Spin spinning={isLoading}>
                <FormProvider {...form}>
                    <Form onFinish={form.handleSubmit(onSubmit)} className="edit-column-form-1" labelWrap={true}>
                        {OperationInputSection}
                        {mustGroupBy && <div className="">*This is a Group By Column</div>}
                        <LinkableSection />
                        <DisplayModeSection />
                        <DisplayFieldNameSection />
                        <LinkedToSection />
                        {formMode === "add" && (
                            <Row className="section">
                                <Col span={12}>{operatorsSection}</Col>
                                <Col span={12}>{functionSection}</Col>
                            </Row>
                        )}
                        <div className="section" style={{marginTop: "1rem"}}>
                            {selectedFieldsSection}
                        </div>
                        <Row gutter={10} align="middle" justify="end" className="section">
                            <Space>
                                <Button onClick={() => toggleModal("")} icon={<CloseOutlined />} className="cancelBtn">
                                    {t("common.cancel")}
                                </Button>
                                <Button
                                    // htmlType="submit"
                                    // onClick={form.handleSubmit(onSubmit)}
                                    onClick={onSubmit}
                                    icon={<CheckOutlined />}
                                    className="button-submit-1"
                                    // disabled={isSubmitBtnDisabled}
                                >
                                    {t("Save")}
                                </Button>
                            </Space>
                        </Row>
                        {FieldsSection}
                    </Form>
                </FormProvider>
            </Spin>
        </Modal>
    );
};

export default EditOperationModal;
