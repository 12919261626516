import React from "react";
import {Col, Row} from "antd";
import moment from "moment";
import {getUserById} from "../../../../../../../../api/graphql/user";
import {formatDateTimeTimezone} from "../../../../../../../../function/_common";

const EWalletTransfer = ({record}) => {
    const task = record?.task

    const userDetail = getUserById(task?.result?.fromUserId)
    const toUserDetail = getUserById(task?.result?.toUserId)

    return (
        <Row gutter={[16,16]}>
            <Col xs={24}>
                <Row gutter={[16,16]}>
                    <Col xs={24} md={6}>Status</Col>
                    <Col xs={24} md={18}>{task?.status}</Col>

                    <Col xs={24} md={6}>Transaction No</Col>
                    <Col xs={24} md={18}>{task?.result?.transactionNo}</Col>

                    <Col xs={24} md={6}>Transaction Date</Col>
                    <Col xs={24} md={18}>{formatDateTimeTimezone(task?.result?.transactionDate)}</Col>

                    <Col xs={24} md={6}>From User</Col>
                    <Col xs={24} md={18}>{userDetail?.data?.getUserByID.username}</Col>

                    <Col xs={24} md={6}>To User</Col>
                    <Col xs={24} md={18}>{toUserDetail?.data?.getUserByID.username}</Col>

                    <Col xs={24} md={6}>Amount</Col>
                    <Col xs={24} md={18}>{task?.result?.amount}</Col>

                    {/*<Col xs={24} md={6}>Amount paid by Client ({mode === "verify"? record?.payload?.input?.currency : record?.task?.result?.currency ?  record?.task?.result?.currency : record?.task?.payload?.input?.currency}):</Col>*/}
                    <Col xs={24} md={6}>Remark</Col>
                    <Col xs={24} md={18}>{task?.result?.comment}</Col>

                    {/*<Col xs={24} md={6}>Fee Charged on Client ({mode === "verify"? pspCurrency : item?.result?.currency ?  item?.result?.currency : item?.payload?.input?.currency}):</Col>*/}
                    <Col xs={24} md={6}>Requested By</Col>
                    <Col xs={24} md={18}>{task?.requestedBy?.username}</Col>

                    {/*<Col xs={24} md={6}>Net Amount ({mode === "verify"? pspCurrency : item?.result?.currency ?  item?.result?.currency : item?.payload?.input?.currency}):</Col>*/}
                    <Col xs={24} md={6}>Comment</Col>
                    <Col xs={24} md={18}>{task?.result?.comment || task?.comment}</Col>
                </Row>
            </Col>
        </Row>
    )

}

export default EWalletTransfer