import React, {useState} from "react";
import {Button, Col, Empty, Form, Input, List, Modal, Popconfirm, Row, Space, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import VirtualList from "rc-virtual-list";
import {getLegalDocumentDataWithLanguage} from "../../../function/common";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {useRecoilState, useSetRecoilState} from "recoil";
import {isEditingLegalDocumentState} from "../../../state";
import DocumentsEditingForm from "./documentsEditingForm";
import {addBrandSettingLegalDocuments, deleteBrandSettingLegalDocuments} from "../../../../../../api/graphql/legalDocuments";
import useAuthorize from "../../../../../../_common/authorize";
import {notificationMessage} from "../../../../../../../recoil_state";

const DocumentsForm = (props) => {
    const {languageCode, isEditable, legalDocList, fetchLegalDoc, data} = props
    const {t} = useTranslation()
    const {baseErrorChecking, systemErrorNotification} = useAuthorize();
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const ContainerHeight = "65vh";
    const [isEditing, setIsEditing] = useRecoilState<any>(isEditingLegalDocumentState);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [form] = Form.useForm();
    const labelCol = {span: 8, offset: 0};

    const handleAddModalClose = () => {
        form.resetFields();
        setIsOpen(false);

        console.log(legalDocList)
    }

    const addLegalDocOnClick = async (value) => {
        setSubmitLoading(true)
        try {
            const {addBrandSettingLegalDocuments: res} = await addBrandSettingLegalDocuments(value)
            if (res?.__typename === "BaseError") {
                await baseErrorChecking(res)
            } else if (res?.__typename === "LegalDocumentObject") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Add Legal Documents Successfully!"),
                    key: "legalDocuments",
                });

                await fetchLegalDoc()
                handleAddModalClose()
            }
        } catch (e) {
            systemErrorNotification()
        } finally {
            setSubmitLoading(false)
            handleAddModalClose()
        }
    };

    const deleteLegalDocOnClick = async (id) => {
        try {
            const {deleteBrandSettingLegalDocuments: res} = await deleteBrandSettingLegalDocuments(id)
            if (res?.__typename === "BaseError") {
                await baseErrorChecking(res)
            } else if (res?.__typename === "LegalDocumentObject") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Delete Legal Documents Successfully!"),
                    key: "legalDocuments",
                });

                await fetchLegalDoc()
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    return (
        <>
            <Modal
                title={t(`legalDocument.addLegalDocument`)}
                open={isOpen}
                onCancel={() => handleAddModalClose()}
                footer={null}
                width={500}
                destroyOnClose
            >
                <Form onFinish={addLegalDocOnClick} form={form}>
                    <Form.Item
                        label={t("legalDocument.documentName")}
                        name={"name"}
                        labelCol={labelCol}
                        rules={[{required: true, message: "Please input document name"}]}
                    >
                        <Input placeholder={t("Document Name").toString()}/>
                    </Form.Item>
                    {/*<Form.Item*/}
                    {/*    label={t("legalDocument.usedForRegistration")}*/}
                    {/*    name={"isUsedForRegistration"}*/}
                    {/*    labelCol={labelCol}*/}
                    {/*    valuePropName={"checked"}*/}
                    {/*>*/}
                    {/*    <Switch/>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item label={t("legalDocument.showInLoginPage")} name={"isShowInLoginPage"} labelCol={labelCol} valuePropName={"checked"}>*/}
                    {/*    <Switch/>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item label={t("legalDocument.usedForWebsite")} name={"isUsedForWebsite"} labelCol={labelCol} valuePropName={"checked"}>*/}
                    {/*    <Switch/>*/}
                    {/*</Form.Item>*/}
                    <Form.Item>
                        <Row justify={"end"} className={"margin-top-0-75"}>
                            <Space>
                                <Button onClick={() => setIsOpen(false)}>{t("common.cancel")}</Button>
                                <Button type={"primary"} loading={submitLoading} htmlType={"submit"}>
                                    {t("common.add")}
                                </Button>
                            </Space>
                        </Row>
                    </Form.Item>
                </Form>
            </Modal>

            <Row gutter={[16, 16]} style={{marginTop: "30px"}}>
                <Col span={12}>
                    {
                        isEditable &&
                        <Button type={"dashed"} block onClick={() => setIsOpen(true)}>
                            {t("brandSetting.addDocument")}
                        </Button>
                    }

                    {legalDocList?.length === 0 && <Empty className="margin-top-0-75"/>}
                    <List>
                        <VirtualList data={legalDocList} style={{height: ContainerHeight, overflowY: "auto", marginTop: 16}} itemKey="_id">
                            {(item, index) => (
                                <List.Item key={index} style={{padding: 4}}>
                                    <Row
                                        justify={"space-between"}
                                        align={"middle"}
                                        style={{
                                            width: "100%",
                                            padding: "8px 0 8px 16px",
                                            borderRadius: 8,
                                            backgroundColor: isEditing === item?.name ? "#f1f1f1" : "transparent",
                                            transition: ".5s",
                                        }}
                                    >
                                        <h4
                                            style={{
                                                color: isEditing === item.name ? "#1677ff" : "inherit",
                                                marginBottom: 0,
                                                transition: ".5s",
                                            }}
                                        >
                                            {index + 1}.{" "}
                                            {getLegalDocumentDataWithLanguage(
                                                legalDocList,
                                                `${item._id}_displayName_${languageCode}`,
                                                "content",
                                                languageCode
                                            ) || item.name}
                                        </h4>
                                        <Space>
                                            {
                                                isEditable ?
                                                    <>
                                                        <Tooltip title={t("common.delete")}>
                                                            <Popconfirm
                                                                placement="left"
                                                                title={t("legalDocument.delete.popconfirm.title")}
                                                                description={t("legalDocument.delete.popconfirm.description")}
                                                                onConfirm={async () => await deleteLegalDocOnClick(item?._id)}
                                                                okText={t("common.yes")}
                                                                cancelText={t("common.no")}
                                                            >
                                                                <Button type={"link"} danger icon={<DeleteOutlined/>}/>
                                                            </Popconfirm>
                                                        </Tooltip>
                                                        <Tooltip title={t("common.edit")}>
                                                            <Button
                                                                onClick={() => setIsEditing(item._id)}
                                                                type={"link"}
                                                                style={{marginRight: 16}}
                                                                icon={<EditOutlined/>}
                                                            />
                                                        </Tooltip>
                                                    </> :
                                                    <Tooltip title={t("common.view")}>
                                                        <Button
                                                            onClick={() => setIsEditing(item._id)}
                                                            type={"link"}
                                                            style={{marginRight: 16}}
                                                            icon={<EyeOutlined/>}
                                                        />
                                                    </Tooltip>
                                            }

                                        </Space>
                                    </Row>
                                </List.Item>
                            )}
                        </VirtualList>
                    </List>
                </Col>

                {legalDocList?.length > 0 &&
                    legalDocList?.map((d) => {
                        return (
                            isEditing === d._id &&
                            <Col span={12} key={d?.name}>
                                <DocumentsEditingForm
                                    item={d}
                                    isEditing={isEditing}
                                    languageCode={languageCode}
                                    getLegalDocumentDataWithLanguage={getLegalDocumentDataWithLanguage}
                                    legalDocumentData={legalDocList}
                                    data={data}
                                    isEditable={isEditable}
                                    fetchLegalDoc={fetchLegalDoc}
                                />
                            </Col>
                        );
                    })}
            </Row>
        </>
    )
}

export default DocumentsForm