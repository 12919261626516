import React from "react";
import {Col, Collapse, Spin} from "antd";
import {useTranslation} from "react-i18next";
import SymbolsMt5 from "./siderMt5/detailPanel/SymbolsPanel";
import DetailPanelMt5 from "./siderMt5/detailPanel";
import SymbolSettingMt5 from "./siderMt5/SymbolSettingPanel";

const {Panel} = Collapse;

const SpreadGroupMt5Sider = (props) => {
    const {initialData, server} = props;
    const {t} = useTranslation();

    return (
        initialData ? (
            <Collapse accordion size={"small"}>

                <Panel showArrow={false} header={initialData?.group || t("Detail")} key="detail">
                    <DetailPanelMt5 initialData={initialData}/>
                </Panel>

                <Panel showArrow={false} header={t("Symbol Settings")} key="symbol">
                    <SymbolSettingMt5 initialData={initialData}/>
                </Panel>

            </Collapse>
        ) : (
            <Col span={24} style={{marginTop: "20%", textAlign: "center"}}>
                <Spin size="large"/>
            </Col>
        )
    );
};

export {SpreadGroupMt5Sider};
