import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";


const GET_SYMBOLS_MT5 = gql`
    query getSymbolsMt5(
        $filter: SymbolMt5Filter
        $orderBy: String
        $limit: Int
        $offset: Int
        $startDate: String
        $endDate: String
        $dateKey: String
    ) {
        getSymbolsMt5(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
            startDate: $startDate
            endDate: $endDate
            dateKey: $dateKey
        )  {
            ... on SymbolMt5Array {
                data{
                    _id
                    symbol
                    path
                    isin
                    description
                    international
                    category
                    exchange
                    cfi
                    sector
                    industry
                    country
                    basis
                    source
                    page
                    currencyBase
                    currencyBaseDigits
                    currencyProfit
                    currencyProfitDigits
                    currencyMargin
                    currencyMarginDigits
                    color
                    colorBackground
                    digits
                    point
                    multiply
                    tickFlags
                    tickBookDepth
                    filterSoft
                    filterSoftTicks
                    filterHard
                    filterHardTicks
                    filterDiscard
                    filterSpreadMax
                    filterSpreadMin
                    filterGap
                    filterGapTicks
                    tradeMode
                    calcMode
                    execMode
                    gtcMode
                    fillFlags
                    expirFlags
                    spread
                    spreadBalance
                    spreadDiff
                    spreadDiffBalance
                    tickValue
                    tickSize
                    contractSize
                    stopsLevel
                    freezeLevel
                    quotesTimeout
                    volumeMin
                    volumeMinExt
                    volumeMax
                    volumeMaxExt
                    volumeStep
                    volumeStepExt
                    volumeLimit
                    volumeLimitExt
                    marginFlags
                    marginInitial
                    marginMaintenance
                    marginLong
                    marginShort
                    marginLimit
                    marginStop
                    marginStopLimit
                    marginHedged
                    marginRateCurrency
                    marginRateLiquidity
                    swapMode
                    swapLong
                    swapShort
                    swap3Day
                    timeStart
                    timeExpiration
                    reFlags
                    reTimeout
                    ieCheckMode
                    ieTimeout
                    ieSlipProfit
                    ieSlipLosing
                    ieVolumeMax
                    ieVolumeMaxExt
                    ieFlags
                    priceSettle
                    priceLimitMax
                    priceLimitMin
                    tradeFlags
                    orderFlags
                    faceValue
                    accruedInterest
                    spliceType
                    spliceTimeType
                    chartMode
                    optionMode
                    priceStrike
                    isArchived
                    server{
                        _id
                        name
                    }
                }
                total
            }
            ... on BaseError {
                errObj
            }
        }
    }
`;

const getSymbolsMt5 = async (filter, orderBy?, limit?, offset?, startDate?, endDate?, dateKey?) => {
    try {
        const execute = await ApolloClientQuery(GET_SYMBOLS_MT5, {
                filter: filter,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
                orderBy: orderBy || null,
                limit: limit || null,
                offset: offset || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

// const GET_SYMBOLS_MT5_DROPDOWN = gql`
//     query getSymbolsMt5Dropdown(
//         $filter: SymbolMt5Filter
//         $orderBy: String
//         $limit: Int
//         $offset: Int
//         $startDate: String
//         $endDate: String
//         $dateKey: String
//     ) {
//         getSymbolsMt5Dropdown(
//             filter: $filter
//             orderBy: $orderBy
//             limit: $limit
//             offset: $offset
//             startDate: $startDate
//             endDate: $endDate
//             dateKey: $dateKey
//         )  {
//             ... on SymbolMt5Array {
//                 data{
//                     _id
//                     symbol
//                 }
//             }
//             ... on BaseError {
//                 errObj
//             }
//         }
//     }
// `;

// const getSymbolsMt5Dropdown = async (filter) => {
//     try {
//         const execute = await ApolloClientQuery(GET_SYMBOLS_MT5_DROPDOWN, {
//                 filter: filter,
//                 context: {
//                     headers: {
//                         authorization: "Bearer " + localStorage.getItem("a_t"),
//                     },
//                 },
//             },
//             false
//         );

//         return execute;
//     } catch (err: any) {
//         console.error(err);
//         return {data: null, loading: false, error: err};
//     }
// };

const REFRESH_SYMBOL_MT5 = gql`
    query refreshSymbolsMt5($serverId: String){
        refreshSymbolsMt5(serverId:$serverId) {
            ... on SymbolMt5Array {
                data{
                    _id
                }
                total
            }
            ... on BaseError {
                errObj
            }
        }
    }
`;

const refreshSymbolsMt5 = async (serverId) => {
    try {
        const execute = await ApolloClientQuery(
            REFRESH_SYMBOL_MT5,
            {
                serverId:serverId,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

export {
    getSymbolsMt5,
    // getSymbolsMt5Dropdown,
    refreshSymbolsMt5
}