import React from "react";
import {useTranslation} from "react-i18next";
import {Form, Row, Select} from "antd";

const EWalletSetting_1 = (props) => {
    const {t} = useTranslation()

    return (
        <>
            <h1>{t("E-Wallet Setting")}</h1>
            <Row className={"margin-top-0-75"}>
                <Form.Item
                    label={t("Currency")}
                >
                    <Select
                        defaultValue={"USD"}
                        options={[{label: t("USD"), value: "USD"}]}
                        style={{width: 150}}
                    />
                </Form.Item>
            </Row>
        </>
    );
};

export default EWalletSetting_1