import {Card, Row, Space} from "antd";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import EditTableUserPreferenceButton from "../../../../../../components/table/function/editTableUserPreferenceButton";
import {columnsInvestor} from "../../../function/columnsInvestor";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import {AiOutlineEdit} from "react-icons/ai";
// import ExportTableButton from "../../../../../../components/table/function/exportTable";

const InvestorTable = (props) => {
    const {dataInvestor, loading, setFilterInvestor, setOrderByInvestor, limitInvestor, totalCountInvestor, setLimitInvestor, setOffsetInvestor} =
        props;
    const {t} = useTranslation();
    const tableName = "Investor";

    const onChangePageHandler = (page, pageSize) => {
        setLimitInvestor(pageSize);
        setOffsetInvestor(limitInvestor * (page - 1));
    };

    return (
        <Card>
            <Row justify={"space-between"} className={"margin-bottom-0-75"}>
                <h1>{t(tableName)}</h1>
                <Space>
                    <EditTableUserPreferenceButton tableName={tableName} tableColumn={columnsInvestor} />
                    {/* <ExportTableButton tableData={exportData} tableColumn={columns} tableName={tableName} /> */}
                </Space>
            </Row>
            <TableAntDesign
                data={dataInvestor}
                tableName={tableName}
                columns={columnsInvestor}
                size={"small"}
                loading={loading}
                filter={setFilterInvestor}
                order={setOrderByInvestor}
                pagination={{
                    pageSize: limitInvestor,
                    showSizeChanger: true,
                    position: "bottomCenter",
                    total: totalCountInvestor,
                    onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                    showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
                }}
            />
        </Card>
    );
};

export default InvestorTable;
