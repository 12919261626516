import {useState} from "react";
import {getBrandSettingLegalDocuments} from "../../../../api/graphql/legalDocuments";

export const useGetBrandSettingLegalDocuments = () => {
    const [data, setData] = useState<any>([])

    const fetchBrandSettingLegalDocuments = async () => {
        try {
            const {getBrandSettingLegalDocuments: response} = await getBrandSettingLegalDocuments()
            setData(response?.data)
        } catch (e) {
        }
    }

    return [data, fetchBrandSettingLegalDocuments]
}