import { gql, useMutation, useQuery } from "@apollo/client";

const GET_SWAP_FREE_PROFILE_COUNT = gql`
  query getSwapFreeProfileCount($filter: SwapFreeProfileInput) {
    getSwapFreeProfileCount(filter: $filter)
  }
`;

const getSwapFreeProfileCount = ({ filter }) => {
  try {
    const { loading, error, data } = useQuery(GET_SWAP_FREE_PROFILE_COUNT, {
      variables: {
        filter: {
          swapFreeProfileId: filter?.swapFreeProfileId || null,
          name: filter?.name || null,
          serverName: filter?.serverName || null,
        },
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_SWAP_FREE_PROFILE_BY_ID = gql`
  query getSwapFreeProfileByID($id: String!) {
    getSwapFreeProfileByID(id: $id) {
      ... on SwapFreeProfile {
        id
        swapFreeProfileId
        name
        description
        rolloverCommission
        skipRolloverDays
        rolloverChargePeriod
        rolloverCommission3Days
        utcLastUpdateTimestamp
        server {
          name
        }
        createdBy {
          username
        }
        updatedBy {
          username
        }
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getSwapFreeProfileById = (id) => {
  try {
    const { loading, error, data } = useQuery(GET_SWAP_FREE_PROFILE_BY_ID, {
      variables: {
        id: id,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_SWAP_FREE_PROFILE = gql`
  query getSwapFreeProfile(
    $filter: SwapFreeProfileInput
    $limit: Int
    $offset: Int
    $orderBy: String
  ) {
    getSwapFreeProfile(
      filter: $filter
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      ... on SwapFreeProfileArray {
        data {
          id
          swapFreeProfileId
          name
          description
          rolloverCommission
          skipRolloverDays
          rolloverChargePeriod
          rolloverCommission3Days
          utcLastUpdateTimestamp
          server {
            name
          }
          createdBy {
            username
          }
          updatedBy {
            username
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

const getSwapFreeProfile = ({ filter }, limit?, offset?, orderBy?) => {
  try {
    const { loading, error, data } = useQuery(GET_SWAP_FREE_PROFILE, {
      variables: {
        filter: {
          swapFreeProfileId: filter?.swapFreeProfileId || null,
          name: filter?.name || null,
          serverName: filter?.serverName || null,
        },
        limit: limit || null,
        offset: offset || null,
        orderBy: orderBy || null,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const REFRESH_SWAP_FREE_PROFILE = gql`
  mutation refreshSwapFreeProfile($server: String!) {
    refreshSwapFreeProfile(server: $server) {
      ... on SwapFreeProfileArray {
        data {
          id
          swapFreeProfileId
          name
          description
          rolloverCommission
          skipRolloverDays
          rolloverChargePeriod
          rolloverCommission3Days
          utcLastUpdateTimestamp
          server {
            name
          }
          createdBy {
            username
          }
          updatedBy {
            username
          }
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useRefreshSwapFreeProfileMutation = (): {
  refreshSwapFreeProfile: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const [useRefreshSwapFreeProfileMutation, { data, loading, error }] =
      useMutation(REFRESH_SWAP_FREE_PROFILE);

    const refreshSwapFreeProfile = (input) => {
      return useRefreshSwapFreeProfileMutation({
        variables: {
          input: {
            server: input.server || null,
          },
        },
      });
    };

    return { refreshSwapFreeProfile, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return {
      refreshSwapFreeProfile: null,
      data: null,
      loading: false,
      error: err,
    };
  }
};

export {
  getSwapFreeProfileCount,
  getSwapFreeProfileById,
  getSwapFreeProfile,
  useRefreshSwapFreeProfileMutation,
};
