import {
    getBooleanFilterProps,
    getColumnSearchProps,
    getDateRangeFilterProps
} from "../../../../components/table/function/filterTable";
import {BsCheck, BsX} from "react-icons/bs";
import moment from "moment";
import React from "react";
import {Button, Switch, Tag} from "antd";
import {useRecoilValue} from "recoil";
import {selfPermissionObj} from "../../../../../recoil_state";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {useUpdateCronMutation} from "../../../../api/graphql/cron";

export const columnCron = (refetchCronData) => {
    const IsWriteToFileEnabledColumn = (props) => {
        const {updateCron} = useUpdateCronMutation();
        const permission = useRecoilValue<any>(selfPermissionObj);

        const isActiveOnChange = async (value, record) => {
            try {
                await updateCron(record?.name, {writeToFile: value})
            } finally {
                await refetchCronData()
            }
        };

        let component
        if (permission?.systemSetting?.cron?.edit?.edit) {
            component = <Switch
                checkedChildren={<CheckOutlined/>}
                unCheckedChildren={<CloseOutlined/>}
                checked={props?.value}
                size={"small"}
                onChange={(value) => isActiveOnChange(value, props.record)}
            />
        } else {
            component = props?.value ? <BsCheck style={{color: 'green'}}/> : <BsX style={{color: 'red'}}/>
        }

        return component
    };

    return [
        {
            title: "Title",
            dataIndex: "name",
            key: "name",
            ...getColumnSearchProps(),
            render: (text, record) => text,
        },
        {
            title: "Total Execution",
            dataIndex: "totalExecution",
            key: "totalExecution",
            ...getColumnSearchProps(),
            render: (text) => text,
        },
        {
            title: "Last Execution",
            dataIndex: "lastExecution",
            key: "lastExecution",
            ...getDateRangeFilterProps(),
            render: (text) => <p>{text ? moment(text)?.format("YYYY-MM-DD HH:mm:ss") : "-"}</p>
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            ...getColumnSearchProps(),
            render: (text) => text,
        },
        {
            title: "Write to file",
            dataIndex: "writeToFile",
            key: "writeToFile",
            ...getBooleanFilterProps(),
            render: (text, record) => <IsWriteToFileEnabledColumn value={text} record={record}/>,
        },
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            ...getDateRangeFilterProps(),
            render: (text) => <p>{text ? moment(text)?.format("YYYY-MM-DD HH:mm:ss") : "-"}</p>
        },
    ]
};

export const columnCronLog = () => {
        const searchParams = new URLSearchParams(location.search);
        const batchId = searchParams.get('batchId');

        const TitleBtn = ({text}) => {
            return (
                <>
                    <Button type={"link"} style={{fontSize: "12px"}}
                            onClick={() => window.open("/log/cron?batchId=" + text, "_blank")}>
                        {text}
                    </Button>
                </>
            )
        }

        return [
            {
                title: "Id",
                dataIndex: "logTitle",
                key: "logTitle",
                ...getColumnSearchProps(batchId),
                render: (text) => <TitleBtn text={text}/>,
            },
            {
                title: "Status",
                dataIndex: "status",
                key: "status",
                ...getColumnSearchProps(),
                render: (text) => text === "success" ? <Tag color={"success"}>Success</Tag> : text === "failed" ?
                    <Tag color={"error"}>Failed</Tag> : <Tag color={"processing"}>Processing</Tag>,
            },
            {
                title: "Total Execution",
                dataIndex: "totalDataExecution",
                key: "totalDataExecution",
                ...getColumnSearchProps(),
                render: (text) => text,
            },
            {
                title: "Total Success Execution",
                dataIndex: "totalSuccessExecution",
                key: "totalSuccessExecution",
                ...getColumnSearchProps(),
                render: (text) => text,
            },
            {
                title: "Total Fail Execution",
                dataIndex: "totalFailExecution",
                key: "totalFailExecution",
                ...getColumnSearchProps(),
                render: (text) => text,
            },
            {
                title: "Start Date Time",
                dataIndex: "startTime",
                key: "startTime",
                ...getDateRangeFilterProps(),
                render: (text) => <p>{text ? moment(text)?.format("YYYY-MM-DD HH:mm:ss") : "-"}</p>
            },
            {
                title: "End Date Time",
                dataIndex: "endTime",
                key: "endTime",
                ...getDateRangeFilterProps(),
                render: (text) => <p>{text ? moment(text)?.format("YYYY-MM-DD HH:mm:ss") : "-"}</p>
            },
            {
                title: "Created At",
                dataIndex: "createdAt",
                key: "createdAt",
                ...getDateRangeFilterProps(),
                render: (text) => <p>{text ? moment(text)?.format("YYYY-MM-DD HH:mm:ss") : "-"}</p>
            },
        ]
    }
;