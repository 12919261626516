import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Col, Row,} from "antd";
import {getDataWithLanguage, handleOnChangeWithLanguage} from "../../../function/common";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import {SaveOutlined} from "@ant-design/icons";


const SocialForm = (props) => {
    const {languageCode, data, setData, isEditable, onBrandDetailsSubmit, submitLoading} = props
    const {t} = useTranslation();

    const handleOnchange = (e, fieldName, fieldType = "content") => {
        handleOnChangeWithLanguage(e, fieldName, fieldType, languageCode, setData)
    }
    return (
        <>
            <Row gutter={[16, 16]} style={{marginTop: "30px"}}>
                <Col xs={24} md={12}>
                    <InputFormItem
                        name={`facebookLink_${languageCode}`}
                        label={t("brandSetting.facebookLink")}
                        placeholder={t("brandSetting.facebookLink")}
                        value={getDataWithLanguage(data, "facebookLink", "link", languageCode)}
                        onChange={(e) => handleOnchange(e, "facebookLink", "link")}
                        disabled={!isEditable}
                    />
                </Col>
                <Col xs={24} md={12}>
                    <InputFormItem
                        name={`instaLink_${languageCode}`}
                        label={t("brandSetting.instagramLink")}
                        placeholder={t("brandSetting.instagramLink")}
                        value={getDataWithLanguage(data, "instaLink", "link", languageCode)}
                        onChange={(e) => handleOnchange(e, "instaLink", "link")}
                        disabled={!isEditable}
                    />
                </Col>
            </Row>

            <Row gutter={[16, 16]} style={{marginTop: "30px"}}>
                <Col xs={24} md={12}>
                    <InputFormItem
                        name={`youtubeLink_${languageCode}`}
                        label={t("brandSetting.youtubeLink")}
                        placeholder={t("brandSetting.youtubeLink")}
                        value={getDataWithLanguage(data, "youtubeLink", "link", languageCode)}
                        onChange={(e) => handleOnchange(e, "youtubeLink", "link")}
                        disabled={!isEditable}
                    />
                </Col>
                <Col xs={24} md={12}>
                    <InputFormItem
                        name={`twitterLink_${languageCode}`}
                        label={t("brandSetting.twitterLink")}
                        placeholder={t("brandSetting.twitterLink")}
                        value={getDataWithLanguage(data, "twitterLink", "link", languageCode)}
                        onChange={(e) => handleOnchange(e, "twitterLink", "link")}
                        disabled={!isEditable}
                    />
                </Col>
            </Row>


            <Row gutter={[16, 16]} style={{marginTop: "30px"}}>
                <Col xs={24} md={12}>
                    <InputFormItem
                        name={`linkedInLink_${languageCode}`}
                        label={t("brandSetting.linkedInLink")}
                        placeholder={t("brandSetting.linkedInLink")}
                        value={getDataWithLanguage(data, "linkedInLink", "link", languageCode)}
                        onChange={(e) => handleOnchange(e, "linkedInLink", "link")}
                        disabled={!isEditable}
                    />
                </Col>
            </Row>
            {isEditable &&
            <Row className={"margin-top-0-75"} justify={"end"}>
                <Button
                    onClick={onBrandDetailsSubmit}
                    loading={submitLoading}
                    type={"primary"}
                >
                    {t("common.update")}
                </Button>
            </Row>
            }
        </>
    );
};

export default SocialForm;
