import React, {useState} from "react";
import {Dropdown, Input, Menu, Select, Space, Tabs} from "antd";
import {useTranslation} from "react-i18next";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {iconTypeState} from "../../../../../components/sider/state";
import IconListing from "./component/iconListing";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {selfPermissionObj} from "../../../../../../recoil_state";

const IconList_1 = (props) => {
    const {data, loading} = props
    const {t} = useTranslation()
    const iconTypeAry: any = ["Common", "Module", "Flag"]
    const setIconType = useSetRecoilState(iconTypeState)
    const permission = useRecoilValue<any>(selfPermissionObj)

    const [filter, setFilter] = useState<any>("")
    const [iconFilter, setIconFilter] = useState<any[]>([])

    return (
        <Tabs
            defaultActiveKey="1"
            size={"small"}
            items={
                iconTypeAry.map((item, i) => {
                    return {
                        label: t(item),
                        key: item,
                        children: <IconListing data={data} loading={loading} filter={filter} iconFilter={iconFilter}
                                               permission={permission?.brandSetting?.iconList?.edit?.edit}
                        />,
                    };
                })
            }
            tabBarExtraContent={
                <Space>
                    {
                        permission?.brandSetting?.iconList?.edit?.edit &&
                        <Dropdown
                            overlay={
                                <Menu>
                                    <div style={{padding: 10}}>
                                        <p className={"margin-bottom-0"}>
                                            - {t("common.onlyImage")}
                                        </p>
                                        <p className={"margin-bottom-0"}>
                                            - {t("common.notExceed50px")}
                                        </p>
                                    </div>
                                </Menu>
                            }
                            trigger={["click"]}
                            arrow={{pointAtCenter: true}}
                        >
                            <QuestionCircleOutlined/>
                        </Dropdown>
                    }
                    <Select
                        options={[
                            // {label: t("common.all"), value: "all"},
                            {label: t("common.light"), value: "Light"},
                            {label: t("common.dark"), value: "Dark"},
                            {label: t("common.active"), value: "Active"},
                            {label: t("common.inactive"), value: "Inactive"},
                        ]}
                        placeholder={t("Type")}
                        maxTagCount={1}
                        mode={"multiple"}
                        allowClear
                        style={{width: 200}}
                        value={iconFilter}
                        onChange={(value) => {
                            // if (value.includes("all")) {
                            //     setIconFilter([])
                            // } else {
                                setIconFilter(value)
                            // }
                        }}
                    />
                    <Input.Search placeholder={t("Search").toString()} onSearch={e => setFilter(e)} allowClear/>
                </Space>

            }
            onTabClick={(key) => setIconType(key?.toLowerCase())}
        />
    );
};

export default IconList_1