import {gql, useMutation, useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";

const GET_TRADE_IDEA_SETTING = gql`
  query getTradeIdeaSetting {
    getTradeIdeaSetting {
      ... on TradeIdeaSetting {
        brandMappedDataOnly
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getTradeIdeaSetting = () => {
    try {
        const {loading, error, data} = useQuery(GET_TRADE_IDEA_SETTING, {
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, data}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const UPDATE_TRADE_IDEA_SETTING = gql`
  mutation updateTradeIdeaSetting($input: TradeIdeaSettingInput) {
    updateTradeIdeaSetting(input: $input) {
      ... on TradeIdeaSetting {
        brandMappedDataOnly
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useUpdateTradeIdeaSettingMutation = (): {
    updateTradeIdeaSetting: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const { t } = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updateTradeIdeaSettingMutation, {data, loading, error}] = useMutation(UPDATE_TRADE_IDEA_SETTING);

        const updateTradeIdeaSetting = (input) => {
            return updateTradeIdeaSettingMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {updateTradeIdeaSetting}}) {
                    const typename = updateTradeIdeaSetting?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateTradeIdeaSetting?.errKey),
                            key: "updateTradeIdeaSetting",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateTradeIdeaSetting?.msg),
                            key: "updateTradeIdeaSetting",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Trade Idea Setting Successfully"),
                            key: "updateTradeIdeaSetting",
                        });
                    }
                },
            });
        };

        return {updateTradeIdeaSetting, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {updateTradeIdeaSetting: null, data: null, loading: false, error: err};
    }
};

export {
    getTradeIdeaSetting,
    useUpdateTradeIdeaSettingMutation
}


