import React, {useEffect, useState} from "react";
import RuleSettingTable_1 from "./ruleSettingTable";
import {getCommissionGroup, getCommissionRule, getDealReason,} from "../../../../../../../api/graphql/commission";
import useAuthorize from "../../../../../../../_common/authorize";
import {Button, Row, Space} from "antd";
import {useTranslation} from "react-i18next";
import RuleSettingModal from "./ruleSettingModal";
import {getProduct} from "../../../../../../../api/graphql/product";
import {getSymbol} from "../../../../../../../api/graphql/symbol";
import {getUser} from "../../../../../../../api/graphql/user";
import {getMultiTierSetting} from "../../../../../../../api/graphql/multiTierSetting";
import EditTableUserPreferenceButton
  from "../../../../../../../components/table/function/editTableUserPreferenceButton";
import {columnsRuleSetting} from "../../../function/ruleSettingColumns";

const RuleSetting = (props) => {
  const { type, commSetting } = props;
  const { t } = useTranslation();

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [btnLoading, setBtnLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [orderBy, setOrderBy] = useState("");
  const [filter, setFilter] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dateKey, setDateKey] = useState("");
  const [tableName, setTableName] = useState("");
  const [data, setData] = useState([]);
  const [commGroup, setCommGroup] = useState([]);
  const [dealReason, setDealReason] = useState([]);
  const [allRank, setAllRank] = useState([]);

  const [loading, setLoading] = useState(false);
  const { systemErrorNotification } = useAuthorize();

  const allProduct = getProduct({
    filter: null,
  });

  const allSymbol = getSymbol({
    filter: null,
  });

  const allUsers = getUser({
    filter: {
      userType: null,
    },
  });

  const getAllRank = async () => {
    try {
      const res = await getMultiTierSetting({
        filter: {
          name: null,
          isDeleted: false
        },
      });
      if (res?.getMultiTierSetting?.__typename === "MultiTierSettingArray") {
        setAllRank(res?.getMultiTierSetting?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAllRank();
  }, []);

  const preload = async () => {
    await setLoading(true);
    try {
      let response;
      let allCommGroup;

      setTableName(commSetting?.name);

      response = await getCommissionRule(
        {
          commissionSetting: commSetting?._id,
          ...filter,
        }
        // limit,
        // offset,
        // orderBy,
        // startDate,
        // endDate,
        // dateKey
      );

      allCommGroup = await getCommissionGroup({
        commissionSetting: commSetting?._id,
      });

      const filteredCommGroup = allCommGroup?.getCommissionGroup?.data?.map(
        (item) => {
          return {
            label: item?.name,
            value: item?._id,
          };
        }
      );

      setCommGroup(filteredCommGroup);

      setData(response?.getCommissionRule?.data);
    } catch (e) {
      systemErrorNotification();
    } finally {
      setLoading(false);
    }
  };

  const getDealReasonData = async () => {
    const response = await getDealReason();

    let reason = response?.getDealReason?.data?.map((item) => {
      return {
        label: item?.name,
        value: item?._id,
      };
    });

    if (response?.getDealReason?.data) {
      reason = [{ label: "All", value: 0 }, ...reason];
      setDealReason(reason);
    }
  };

  useEffect(() => {
    getDealReasonData();
  }, []);

  useEffect(() => {
    preload();
  }, [commSetting, filter, limit, offset, orderBy]);

  return (
    <div style={{ marginTop: "15px" }}>
      <Row justify={"end"} className={"margin-bottom-0-75"}>
        <Space>
          <EditTableUserPreferenceButton tableName={tableName} tableColumn={columnsRuleSetting} />
          <Button type={"primary"} onClick={() => setOpenModal(true)}>
            {t("common.add")}
          </Button>
        </Space>
      </Row>

      <RuleSettingTable_1
        limit={limit}
        setLimit={setLimit}
        setOffset={setOffset}
        setBtnLoading={setBtnLoading}
        setOrderBy={setOrderBy}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setDateKey={setDateKey}
        server={null}
        tableName={tableName}
        loading={loading}
        data={data}
        setFilter={setFilter}
        productData={allProduct?.data?.getProduct?.data}
        symbolData={allSymbol?.data?.getSymbol?.data}
        commSetting={commSetting}
        dealReason={dealReason}
        userData={allUsers?.data?.getUser?.data}
        commGroup={commGroup}
        rankData={allRank}
        preload={preload}
      />

      <RuleSettingModal
        isOpen={openModal}
        closeModal={() => setOpenModal(false)}
        productData={allProduct?.data?.getProduct?.data}
        symbolData={allSymbol?.data?.getSymbol?.data}
        commSetting={commSetting}
        dealReason={dealReason}
        userData={allUsers?.data?.getUser?.data}
        commGroup={commGroup}
        rankData={allRank}
        preload={preload}
        modalType={"add"}
      />
    </div>
  );
};

export default RuleSetting;
