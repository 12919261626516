import {Col, Form, Image, message, Progress, Space, Tooltip, Upload} from "antd";
import {DeleteOutlined, EyeOutlined, PlusOutlined, RedoOutlined, VideoCameraOutlined} from "@ant-design/icons";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import useAuthorize from "../../../../../../_common/authorize";
import {getConfigByName} from "../../../../../../../config";
import {BRAND_NAME} from "../../../../../../../config/brandVariable";

const MediaUploadFormItem = (props) => {
    const {data, fileList, handlePreview, onChange, onRemove, name, previewEnable = true, condition, disabled, fileType} = props;
    const {t} = useTranslation();
    const {systemErrorNotification} = useAuthorize();

    const brandConfig = getConfigByName(BRAND_NAME);

    const label = name?.split("_")?.[2];

    const imgUrl = fileList?.[0]?.link || fileList?.[0]?.response?.[0]?.link;
    const isVideo = fileList?.[0]?.type?.includes("video") || fileType === "VIDEO"

    let action;
    let accept;
    switch (fileType) {
        case "IMAGE":
        case "LOGO":
        case "BANNER":
        case "LANDING":
            action = `${brandConfig?.backendURL}/api/upload/promoMaterial/image`;
            accept = "image/*";
            break;
        case "VIDEO":
            action = `${brandConfig?.backendURL}/api/upload/promoMaterial/video`;
            accept = "video/*";
            break;
        case "GIF":
            action = `${brandConfig?.backendURL}/api/upload/promoMaterial/gif`;
            accept = "image/gif";
            break;
    }

    const handleFileSizeCheck = (file) => {
        const limit = fileType === "VIDEO" ? 20 : 10
        const maxFileSize = limit * 1024 * 1024

        if (file?.size > maxFileSize) {
            message.error(`${file.name} file upload failed (exceeds ${limit}MB)`);
            return false;
        }

        return true;
    }

    return (
        <Col span={12} key={data?._id + name}>
            <Form.Item>
                {imgUrl ? (
                    <div className={"preview-image-container"} style={{display:"flex"}}>
                        {
                            isVideo ?
                                <VideoCameraOutlined style={{fontSize: 48, margin: "auto", color: "var(--description_text_color)"}}/>
                                :
                                <Image className={"preview-image"} src={imgUrl} height={"100%"} width={"100%"} preview={false}/>
                        }
                        <div className={"action-bar"}>
                            <Space className={"action-bar-inner"}>
                                {previewEnable && (
                                    <Tooltip title={t("Preview")}>
                                        <a onClick={() => handlePreview(imgUrl, isVideo)}>
                                            <EyeOutlined color={"#d2d2d2"}/>
                                        </a>
                                    </Tooltip>
                                )}
                                <Tooltip title={t("common.upload")}>
                                    <Upload
                                        name={name}
                                        action={action}
                                        style={{width: "100%"}}
                                        accept={accept}
                                        maxCount={1}
                                        showUploadList={false}
                                        fileList={fileList}
                                        onChange={(e) => {
                                            try {
                                                onChange && onChange(e);
                                            } catch (e) {
                                                systemErrorNotification();
                                            }
                                        }}
                                        disabled={disabled}
                                    >
                                        <a>{!disabled && <PlusOutlined/>}</a>
                                    </Upload>
                                </Tooltip>
                                {!disabled && (
                                    <Tooltip title={t("Delete")}>
                                        <a onClick={() => onRemove(name)}>
                                            <DeleteOutlined/>
                                        </a>
                                    </Tooltip>
                                )}
                            </Space>
                        </div>
                    </div>
                ) : (
                    <Upload
                        className={"ori-upload"}
                        name={name}
                        action={action}
                        style={{width: "100%"}}
                        listType={"picture-card"}
                        accept={accept}
                        maxCount={1}
                        showUploadList={false}
                        fileList={fileList}
                        beforeUpload={(file, fileList) => handleFileSizeCheck(file)}
                        onChange={(e) => {
                            try {
                                onChange && onChange(e);
                            } catch (e) {
                                systemErrorNotification();
                            }
                        }}
                        disabled={disabled || fileList?.[0]?.status === "uploading"}
                    >
                        {!disabled && fileList?.[0]?.status === "uploading" ? (
                            <div>
                                Uploading
                                <Progress percent={fileList?.[0]?.percent} size={"small"} showInfo={false}/>
                            </div>
                        ) : (
                            <div>
                                <PlusOutlined/>
                                <div style={{marginTop: 8}}>{t("common.upload")}</div>
                            </div>
                        )}
                    </Upload>
                )}
                <p className={"image-title"}>{label}</p>
                {condition &&
                    condition.length > 0 &&
                    condition.map((item, index) => {
                        return (
                            <p key={index} style={{color: "red", fontSize: "12px", marginBottom: 0}}>
                                {item}
                            </p>
                        );
                    })}
            </Form.Item>
        </Col>
    );
};

export default MediaUploadFormItem;
