import React, {useState} from "react";
import {Button, Popconfirm, Row, Space, Tooltip} from "antd";
import {CopyOutlined, DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import PushNotificationServerSettingsModal from "./component/pushNotificationServerSettingsModal";
import TableAntDesign from "../../../../../components/table/tableAntDesign";
import ExportTableButton from "../../../../../components/table/function/exportTable";
import "./css/style.css"
import {deletePushNotificationServerSetting} from "../../../../../api/graphql/pushNotificationServerSetting";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../recoil_state";

const PushNotificationServerSettings_1 = (props) => {
    const {data, loading, columns, pushNotificationServerSettingPermission, setOrderBy, orderBy, getTableData} = props;
    const {t} = useTranslation();
    const [openPushNotificationServerSettingsModal, setOpenPushNotificationServerSettingsModal] = useState(false);
    const [mode, setMode] = useState("");
    const [selectedItem, setSelectedItem] = useState({});
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const tableName = "Push Notification Server Setting";

    const openModal = (record, mode) => {
        setSelectedItem(record);
        setOpenPushNotificationServerSettingsModal(true);
        setMode(mode)
    };

    const closeModal = () => {
        setOpenPushNotificationServerSettingsModal(false);
        getTableData();
        setSelectedItem({});
        setMode("")
    };

    const deleteOnConfirm = async (id) => {
        try {
            const res = await deletePushNotificationServerSetting(id);
            const response = res?.deletePushNotificationServerSetting;
            if (response?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.errKey),
                    key: "deletePushNotification",
                });
            } else {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("common.deleteSuccess"),
                    key: "deletePushNotification",
                });
            }
        } catch (error) {
        } finally {
            await getTableData()
        }
    };

    return (
        <div id="pushNotificationServerSettings">
            <PushNotificationServerSettingsModal
                mode={mode}
                item={selectedItem}
                isOpen={openPushNotificationServerSettingsModal}
                setOpen={setOpenPushNotificationServerSettingsModal}
                closeModal={closeModal}
                data={data}
            />
            <Row justify={"space-between"} className={"margin-bottom-0-75"}>
                <h1>{t("Push Notification Server Settings")}</h1>
                <Space>
                    <ExportTableButton tableData={data} tableColumn={columns} tableName={tableName}/>
                    {pushNotificationServerSettingPermission?.add?.add && (
                        <Button className="margin-left-auto" onClick={() => openModal({}, "add")}>
                            {t("Add Server Settings")}
                        </Button>
                    )}
                </Space>
            </Row>
            <TableAntDesign
                data={data}
                size="small"
                tableName={tableName}
                loading={loading}
                order={setOrderBy}
                columns={[
                    ...columns,
                    {
                        title: t("common.action"),
                        key: "action",
                        width: 132,
                        render: (_, record: any) => (
                            <Space size="middle">
                                <Tooltip title={t("View")}>
                                    <a onClick={() => openModal(record, "view")}>
                                        <EyeOutlined/>
                                    </a>
                                </Tooltip>
                                {pushNotificationServerSettingPermission?.edit?.edit && (
                                    <Tooltip title={t("Edit")}>
                                        <a onClick={() => openModal(record, "edit")}>
                                            <EditOutlined/>
                                        </a>
                                    </Tooltip>
                                )}
                                {pushNotificationServerSettingPermission?.add?.add && (
                                    <Popconfirm
                                        placement="left"
                                        description={t("Are you sure to duplicate this server?")}
                                        title={t("Duplicate Server")}
                                        onConfirm={() => openModal(record, "add")}
                                        okText={t("Yes")}
                                        okType="danger"
                                        cancelText={t("No")}
                                    >
                                        <Tooltip title={t("Duplicate")}>
                                            <a>
                                                <CopyOutlined/>
                                            </a>
                                        </Tooltip>
                                    </Popconfirm>
                                )}
                                {pushNotificationServerSettingPermission?.delete?.delete && (
                                    <Popconfirm
                                        placement="left"
                                        title={"Are you sure to delete this server?"}
                                        description={`Delete the Server`}
                                        onConfirm={() => deleteOnConfirm(record._id)}
                                        okText={t("common.yes")}
                                        cancelText={t("common.no")}
                                    >
                                        <a>
                                            <DeleteOutlined/>
                                        </a>
                                    </Popconfirm>
                                )}
                            </Space>
                        ),
                    },
                ]}
            />
        </div>
    );
};

export default PushNotificationServerSettings_1;
