import {Button, Space} from "antd";
import React, {useCallback, useState} from "react";
import {useUploadLanguageKeyMutation} from "../../api/graphql/languageKey";
import common from "../../data/languageKey/common.json";
import country from "../../data/languageKey/country.json";
import nationality from "../../data/languageKey/nationality.json";
import currency from "../../data/languageKey/currency.json";
import icon from "../../data/languageKey/icon.json";
import msg from "../../data/languageKey/msg.json";
import frontendError from "../../data/languageKey/frontendError.json";
import backendError from "../../data/languageKey/backendError.json";
import user from "../../data/languageKey/user.json";
import brandSetting from "../../data/languageKey/brandSetting.json";
import accountVerification from "../../data/languageKey/accountVerification.json";
import module from "../../data/languageKey/module.json";
import fields from "../../data/languageKey/fields.json";
import permission from "../../data/languageKey/permission.json";
import marketBuzz from "../../data/languageKey/marketBuzz.json";
import mission from "../../data/languageKey/mission.json";
import depositWithdrawal from "../../data/languageKey/depositWithdrawal.json";
import reward from "../../data/languageKey/reward.json";
import task from "../../data/languageKey/task.json";
import tradingAcc from "../../data/languageKey/tradingAccount.json";
import reports from "../../data/languageKey/reports.json";
import trade from "../../data/languageKey/trade.json";
import aiTechScreener from "../../data/languageKey/aiTechScreener.json";
import mamPamm from "../../data/languageKey/mamPamm.json"
import promoMaterial from "../../data/languageKey/promoMaterials.json"
import message from "../../data/languageKey/message.json"
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {updateAdminPermission} from "../../api/graphql/roleTemplate";
import useAuthorize from "../../_common/authorize";

const Developer = () => {
    const {t} = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {uploadLanguageKey} = useUploadLanguageKeyMutation();
    const [isUploading, setIsUploading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const {baseErrorChecking} = useAuthorize();

    const data = [
        common,
        user,
        country,
        nationality,
        currency,
        icon,
        msg,
        brandSetting,
        accountVerification,
        module,
        permission,
        fields,
        marketBuzz,
        mission,
        depositWithdrawal,
        reward,
        frontendError,
        backendError,
        task,
        tradingAcc,
        reports,
        trade,
        aiTechScreener,
        mamPamm,
        promoMaterial,
        message,
    ];

    const uploadAll = useCallback(async () => {
        setIsUploading(true);
        try {
            for (const language of data) {
                await uploadLanguageKey(language);
            }
        } catch (error) {
            console.error("Error uploading language keys: ", error);
        } finally {
            setNotificationMessage({
                status: "success",
                title: "",
                msg: t("Upload Successfully"),
                key: "uploadLanguageKey",
            });

            setIsUploading(false);
        }
    }, [data, uploadLanguageKey]);

    const updatePermission = async () => {
        setIsUpdating(true);

        try {
            const {updateAdminPermission: res} = await updateAdminPermission()

            if (res?.__typename === "RoleTemplate") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Update Successfully"),
                    key: "uploadLanguageKey",
                });
            } else {
                return await baseErrorChecking(res);
            }
        } catch (e) {
            console.error(e)
        } finally {
            setIsUpdating(false);
        }
    }

    return (
        <>
            <div>
                <h3>Actions</h3>
                <Space>
                    <Button onClick={uploadAll} disabled={isUploading}>
                        {isUploading ? "Uploading..." : "Upload All Languages Key"}
                    </Button>
                    <Button onClick={updatePermission} disabled={isUpdating} loading={isUpdating}>
                        Update Master Admin Permission
                    </Button>
                </Space>
            </div>
        </>
    );
};

export default Developer;
