import {gql, useMutation, useQuery} from '@apollo/client'
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {ApolloClientQuery} from "./_apolloClient";

const GET_REGISTRATION_FIELD = gql`
query getRegistrationField($filter: RegistrationFieldInput, $limit: Int, $offset: Int, $orderBy: String) {
  getRegistrationField(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
    ...on RegistrationFieldArray {
      data {
        _id
        label
        fieldName
        fieldType
        optionValue {
          label
          value
          key
        }
        category
        type
        isSensitive
      }
    }
    ...on BaseError {
      errKey
      errMsg
    }
  }
}
`

const getRegistrationField = (filter?, limit?, offset?, orderBy?) => {
  try {
    const {loading, error, data:registrationFieldList} = useQuery(GET_REGISTRATION_FIELD, {
      variables: {
        filter: filter,
        limit: limit,
        offset: offset,
        orderBy: orderBy,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t")
        }
      }
    });

    return {loading, error, registrationFieldList}
  } catch (err: any) {
    console.error(err);
    return {registrationFieldList: null, loading: false, error: err};
  }
}


const GET_REGISTRATION_SETTING = gql`
query getRegistrationSetting($filter: RegistrationSettingSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
  getRegistrationSetting(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
    ...on RegistrationSettingArray {
      data {
        _id
        fieldList {
          _id
          label
          fieldName
          fieldType
          isSensitive
          isRequired
          isUnique
          isConfirmRequired
          isUsedForLogin
          optionValue {
            label
            value
            key
          }
          category
          isSensitive
        }
        position
        platform
        mode
      }
    }
    ...on BaseError {
      errKey
      errMsg
    }
  }
}
`;

const getRegistrationSetting = (filter?, limit?, offset?, orderBy?) => {
  try {
    const {loading, error, data} = useQuery(GET_REGISTRATION_SETTING, {
      variables: {
        filter: filter,
        limit: limit || null,
        offset: offset || null,
        orderBy: orderBy || null,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t")
        }
      }
    });

    return {loading, error, data}
  } catch (err: any) {
    console.error(err);
    return {data: null, loading: false, error: err};
  }
}

const getRegistrationSettingAsync = async (filter?, limit?, offset?, orderBy?) => {
  try {
    const execute = await ApolloClientQuery(
        GET_REGISTRATION_SETTING,
        {
          filter: filter,
          limit: limit || null,
          offset: offset || null,
          orderBy: orderBy || null,
        },
        false
    );

    return execute;
  } catch (err: any) {
    console.error(err);
    return {data: null, loading: false, error: err};
  }
}

const UPDATE_REGISTRATION_SETTING = gql`
mutation updateRegistrationSetting($input: RegistrationSettingInput) {
  updateRegistrationSetting(input: $input) {
    ...on RegistrationSetting {
      _id
      fieldList {
        _id
        label
        fieldName
        fieldType
        isSensitive
        isRequired
        isUnique
        isUsedForLogin
        isConfirmRequired
        optionValue {
          label
          value
          key
        }
        category
        isSensitive
      }
      position
      platform
      mode
    }
    ...on BaseError {
      errKey
      errMsg
    }
    ...on CrmTaskApproval {
      msg
    }
  }
}
`;

const useUpdateRegistrationSettingMutation = (): { updateRegistrationSetting: any, data: any, loading: boolean, error: any } => {
  try{
    const { t } = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [updateRegistrationSettingMutation, { data, loading, error }] = useMutation(UPDATE_REGISTRATION_SETTING);

    const updateRegistrationSetting = (input) => {
      return updateRegistrationSettingMutation({
        variables: {
          input: input,
        },
        update(cache, {data: {updateRegistrationSetting}}) {
          const typename = updateRegistrationSetting?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(updateRegistrationSetting?.errKey),
              key: "updateRegistrationSetting",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(updateRegistrationSetting?.msg),
              key: "updateRegistrationSetting",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t(`Update Registration Setting Successfully`),
              key: "updateRegistrationSetting",
            });
          }
        },
        refetchQueries: [GET_REGISTRATION_SETTING]
      });
    };

    return { updateRegistrationSetting, data, loading, error }
  }
  catch(err: any){
    console.error(err);
    return { updateRegistrationSetting: null, data: null, loading: false, error: err };
  }
}

const DELETE_REGISTRATION_SETTING = gql`
mutation deleteRegistrationSetting($id: String!) {
  deleteRegistrationSetting(id: $id) {
    ...on RegistrationSetting {
      _id
      registrationField {
        _id
        label
        fieldName
        fieldType
        optionValue {
          label
          value
          key
        }
        category
        type
        isSensitive
      }
      position
      platform
      mode
      isRequired
      isUnique
    }
    ...on BaseError {
      errKey
      errMsg
    }
    ...on CrmTaskApproval {
      msg
    }
  }
}
`;

const useDeleteRegistrationSettingMutation = (): { deleteRegistrationSetting: any, data: any, loading: boolean, error: any } => {
  try{
    const { t } = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [deleteRegistrationSettingMutation, { data, loading, error }] = useMutation(DELETE_REGISTRATION_SETTING);

    const deleteRegistrationSetting = (id) => {
      return deleteRegistrationSettingMutation({
        variables: {
          id: id,
        },
        update(cache, {data: {deleteRegistrationSetting}}) {
          const typename = deleteRegistrationSetting?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(deleteRegistrationSetting?.errKey),
              key: "deleteRegistrationSetting",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(deleteRegistrationSetting?.msg),
              key: "deleteRegistrationSetting",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t(`Delete Registration Setting Successfully`),
              key: "deleteRegistrationSetting",
            });
          }
        },
        refetchQueries: [GET_REGISTRATION_SETTING]
      });
    };

    return { deleteRegistrationSetting, data, loading, error }
  }
  catch(err: any){
    console.error(err);
    return { deleteRegistrationSetting: null, data: null, loading: false, error: err };
  }
}

const VERIFY_EMAIL = gql`
mutation verifyEmail($input: VerifyEmailInput) {
    verifyEmail(input: $input){
      ... on User {
        id
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`

const useVerifyEmailMutation = (): { verifyEmail: any, data: any, loading: boolean, error: any } => {
  try{
    const [useVerifyEmailMutation, { data, loading, error }] = useMutation(VERIFY_EMAIL);

    const verifyEmail = (input) => {
      return useVerifyEmailMutation({
        variables: {
          input: {
            email: input.email || null,
            token: input.token || null,
          },
        },
      });
    };

    return { verifyEmail, data, loading, error } 
  }
  catch(err: any){
    console.error(err);
    return { verifyEmail: null, data: null, loading: false, error: err };
  }
}


export {
  getRegistrationField,
  getRegistrationSetting,
  getRegistrationSettingAsync,
  useUpdateRegistrationSettingMutation,
  useDeleteRegistrationSettingMutation,
  useVerifyEmailMutation
}
