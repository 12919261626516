import React, {useEffect, useState} from "react";
import {Form, Input, InputNumber, Modal, Space} from "antd";
import {useTranslation} from "react-i18next";
import {useAddMultiTierSettingMutation, useUpdateMultiTierSettingMutation} from "../../../../../../../api/graphql/multiTierSetting";
import useAuthorize from "../../../../../../../_common/authorize";

const MultiTierSettingForm = (props) => {
    const {mode, type, isOpen, setIsOpen, item, totalRank} = props;
    const {t} = useTranslation();
    const labelCol = {span: 8, offset: 0};

    const {addMultiTierSetting, loading: addLoading} = useAddMultiTierSettingMutation();
    const {updateMultiTierSetting, loading: editLoading} = useUpdateMultiTierSettingMutation();
    const {systemErrorNotification} = useAuthorize();

    const [actionLoading, setActionLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            rank: item?.rank,
            name: item?.name,
        });
    }, [item]);

    useEffect(() => {
        if (addLoading || editLoading) setActionLoading(true);
        else setActionLoading(false);
    }, [addLoading, editLoading]);

    const closeModal = () => {
        setIsOpen(false);
    };

    const onSubmit = async (val) => {
        try {
            if (mode === "Add") {
                await addMultiTierSetting({...val, type});
            } else if (mode === "Edit") {
                let value = val;
                if (item?.type === "client") {
                    value.rank = item?.originalRank;
                }
                await updateMultiTierSetting(item?._id, value);
            }
            closeModal();
        } catch (e) {
            systemErrorNotification();
        }
    };

    const validateMessages = {
        required: "${label} is a required field!",
        types: {
            number: "${label} is not a valid number!",
        },
        number: {
            range: "${label} must be between ${min} and ${max}",
        },
    };

    return (
        <Modal
            title={t(`${mode === "Add" ? "common.add" : "common.edit"}`) + " " + t("commission.rank")}
            open={isOpen}
            width={500}
            onCancel={closeModal}
            onOk={form.submit}
            okText={t(mode === "Edit" ? "common.submit" : "common.add")}
            okButtonProps={{loading: actionLoading}}
        >
            <Form onFinish={onSubmit} validateMessages={validateMessages} form={form}>
                <Space direction={"vertical"} style={{width: "100%"}}>
                    <Form.Item
                        name={"rank"}
                        label={t("commission.rank")}
                        labelCol={labelCol}
                        rules={[
                            {type: "number", min: 1, max: mode === "Add" || item?.rank === totalRank ? totalRank : totalRank - 1, required: true},
                        ]}
                    >
                        <InputNumber placeholder={t("commission.rank").toString()} style={{width: "100%"}} disabled={item?.isDefaultRank} required />
                    </Form.Item>
                    <Form.Item name={"name"} label={t("commission.rankName")} labelCol={labelCol} rules={[{required: true}]}>
                        <Input placeholder={t("commission.rankName").toString()} required />
                    </Form.Item>
                </Space>
            </Form>
        </Modal>
    );
};

export default MultiTierSettingForm;
