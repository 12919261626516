const rewardType = [
    {key: "voucher", label: "Voucher", value: "voucher"},
    {key: "voucherGroup", label: "Voucher Group", value: "voucherGroup"},
]


const missionCodeType = [
    {key: "autoGenerate", label: "Auto Generate", value: "autoGenerate"},
    {key: "manual", label: "Manual", value: "manual"},
]

const voucherExpiryType = [
    {key: "unlimited", label: "Unlimited", value: "unlimited"},
    {key: "days", label: "Days", value: "days"},
    {key: "endDate", label: "End Date", value: "endDate"},
]

const missionDurationType = [
    {key: "unlimited", label: "Unlimited", value: "unlimited"},
    {key: "days", label: "Days", value: "days"},
    {key: "endDate", label: "End Date", value: "endDate"},
]

const voucherAvailability = [
    {key: "all", label: "All", value: "all"},
    {key: "tradersRoomWeb", label: "Web Traders Room", value: "tradersRoomWeb"},
    {key: "tradersRoomApp", label: "App Traders Room", value: "tradersRoomApp"},
]

const missionEnd = [
    {key: "byMissionDurationEndDate", label: "By Mission Duration End Date", value: "byMissionDurationEndDate"},
    {key: "byMissionDurationEndDateAndMissionEventDurationEndDate", label: "By Mission Duration End Date and Mission Event Duration End Date", value: "byMissionDurationEndDateAndMissionEventDurationEndDate"},
]

const conditionCalculationPeriod = [
    {key: "startFromClaimDate", label: "Start from Claim Date", value: "startFromClaimDate"},
    {key: "startFromEventDuration", label: "Start from Event Duration", value: "startFromEventDuration"},
]

const autoCompleteExpiredMission = [
    {key: "autoCompleteMission", label: "Auto Complete Mission", value: "autoCompleteMission"},
    {key: "forfeitMission", label: "Forfeit Mission", value: "forfeitMission"},
]

const trueFalseOperator = [
    {
        label: "True",
        value: "true"
    },
    {
        label: "False",
        value: "false"
    }
]

const logicalOperator = [
    {
        label: "AND",
        value: "and"
    },
    {
        label: "OR",
        value: "or"
    }
]

const comparisonOperator = [
    {
        label: "=",
        value: "="
    },
    {
        label: "≥",
        value: ">="
    },
    {
        label: ">",
        value: ">"
    },
    {
        label: "≤",
        value: "<="
    },
    {
        label: "<",
        value: "<"
    },
    {
        label: "≠",
        value: "<>"
    }
]

export {
    rewardType,
    missionCodeType,
    voucherExpiryType,
    voucherAvailability,
    missionDurationType,
    missionEnd,
    conditionCalculationPeriod,
    autoCompleteExpiredMission,
    logicalOperator,
    comparisonOperator,
    trueFalseOperator
}