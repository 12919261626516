import React from "react";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import FormErrors from "./formErrors";
import {Button, Col, Form, Input, Row, Tooltip} from "antd";
import {useTranslation} from "react-i18next";

const MultipleInputFormItem = (props) => {
    const {
        layout,
        label,
        name,
        value,
        setValue,
        placeholder,
        externalErrorMessage,
        required,
        onChange,
        disabled,
        prefix,
    } = props;

    const {t} = useTranslation()
    const {
        formState: {touchedFields, isSubmitted, errors},
    } = useFormContext();

    const errorMessage = FormErrors.errorMessage(
        name,
        label,
        errors,
        touchedFields,
        isSubmitted,
        externalErrorMessage
    );

    function addEmptyObjectToArray(arr: any): any {
        if (!value) {
            setValue([""]);
        } else {
            const latestAry = [...value, ""];
            setValue(latestAry);
        }
    }

    function removeObjectFromArray(keyToRemove: number): any {
        const latestAry = value?.filter((obj, index) => index !== keyToRemove);

        setValue(latestAry);
    }

    function updateValueInArray(keyToUpdate: number, newValue: string): any {
        const latestAry = value?.map((obj, index) => {
            if (index === keyToUpdate) {
                return newValue;
            }
            return obj;
        });
        setValue(latestAry);
    }

    return (
        <>
            <div style={{height: "32px"}}>
                <label>{label}</label>
            </div>
            <Row gutter={[8, 8]} align={"bottom"}>
                {value &&
                value?.length > 0 &&
                value?.map((item, index) => {
                    return (
                        <>
                            <Col span={22}>
                                <Form.Item
                                    {...layout}
                                    required={required}
                                    validateStatus={errorMessage ? "error" : "success"}
                                    help={errorMessage}
                                    style={{marginBottom: 0}}
                                >
                                    <Input
                                        required={required}
                                        id={name}
                                        name={name}
                                        value={item}
                                        defaultValue={item}
                                        placeholder={placeholder}
                                        disabled={disabled}
                                        prefix={prefix}
                                        style={{width: "100%"}}
                                        onChange={(e) => {
                                            updateValueInArray(index, e?.target?.value);
                                            onChange && onChange(e?.target?.value);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                <Tooltip title={t("common.delete")}>
                                    <Button
                                        onClick={() => removeObjectFromArray(index)}
                                        danger={true}
                                        disabled={disabled}
                                    >
                                        -
                                    </Button>
                                </Tooltip>
                            </Col>
                        </>
                    );
                })}

                <Col span={22}>
                    <Button
                        type="dashed"
                        onClick={addEmptyObjectToArray}
                        style={{width: "100%"}}
                        disabled={disabled}
                    >
                        {t("common.add")}
                    </Button>
                </Col>
            </Row>
        </>
    );
};

MultipleInputFormItem.defaultProps = {
    type: "text",
    required: false,
    disabled: false,
};

MultipleInputFormItem.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    prefix: PropTypes.any,
    value: PropTypes.array,
    type: PropTypes.string,
    externalErrorMessage: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    layout: PropTypes.object,
    maxLength: PropTypes.number,
    showCount: PropTypes.bool,
    setValue: PropTypes.func,
};

export default MultipleInputFormItem;
