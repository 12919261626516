import React from "react";
import {FormProvider, useForm} from "react-hook-form";
import {Col, Form, Input, Row, Space} from "antd";
import CheckboxFormItem from "../../../../../../../components/form/checkboxFormItem";
import InputFormItem from "../../../../../../../components/form/inputFormItem";
import SelectFormItem from "../../../../../../../components/form/selectFormItem";
import {useTranslation} from "react-i18next";

const CommonPanel = ({initialData}) => {
    const {t} = useTranslation()
    const labelColInput = {span: 10, offset: 0};
    const labelColCheckbox = {span: 21, offset: 0};

    const form = useForm({
        mode: "all",
    });

    return (
        <FormProvider {...form}>
            <form className="form-1">
                <Row gutter={[16, 16]}>
                    <Col span={16}>
                        <Row>
                            <Col span={8}>
                                <CheckboxFormItem
                                    name={"enabled"}
                                    label={t("Enabled")}
                                    labelCol={labelColCheckbox}
                                    value={initialData?.enabled}
                                    disabled
                                />
                            </Col>
                            <Col span={8}>
                                <CheckboxFormItem
                                    name={"swapEnabled"}
                                    label={"Enabled Swaps"}
                                    labelCol={labelColCheckbox}
                                    value={initialData?.swapEnabled}
                                    disabled
                                />
                            </Col>
                            <Col span={8}>
                                <CheckboxFormItem
                                    name={"negativeBalanceProtection"}
                                    label={t("tradingAccount.negative_balance_protection")}
                                    labelCol={labelColCheckbox}
                                    value={initialData?.negativeBalanceProtection}
                                    disabled
                                />
                            </Col>
                            <Col span={8}>
                                <CheckboxFormItem
                                    name={"disablePasswordLogin"}
                                    label={t("Only CTID authentication")}
                                    labelCol={labelColCheckbox}
                                    disabled
                                />
                            </Col>
                            <Col span={8}>
                                <CheckboxFormItem
                                    name={"allowWorseGSL"}
                                    label={t("Allow Worse GSL")}
                                    labelCol={labelColCheckbox}
                                    value={initialData?.disablePasswordLogin}
                                    disabled
                                />
                            </Col>
                            <Col span={8}>
                                <CheckboxFormItem
                                    name={"copyDisabled"}
                                    label={t("Disable Copy Trading")}
                                    labelCol={labelColCheckbox}
                                    value={initialData?.copyDisabled}
                                    disabled
                                />
                            </Col>
                            <Col span={8}>
                                <CheckboxFormItem
                                    name={"copyProvidingDisabled"}
                                    label={"Disable Strategy Creation"}
                                    labelCol={labelColCheckbox}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <InputFormItem
                                    name={"name"}
                                    label={t("Name")}
                                    labelCol={labelColInput}
                                    value={initialData?.name}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"accounts"}
                                    label={t("Accounts")}
                                    labelCol={labelColInput}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"description"}
                                    label={t("user.comment")}
                                    labelCol={labelColInput}
                                    value={initialData?.description}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <InputFormItem
                                    name={"marginStopout"}
                                    label={t("Stop Out Level (in %)")}
                                    labelCol={labelColInput}
                                    value={(initialData?.marginStopout * 100)?.toString()}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <SelectFormItem
                                    name={"bonusConvertLots"}
                                    label={t("Bonus Conversion Strategy")}
                                    labelCol={labelColInput}
                                    options={[
                                        {value: true, label: t("Base on Lots Amount")},
                                        {value: false, label: t("Base on USD Volume")},
                                    ]}
                                    value={initialData?.bonusConvertLots}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <SelectFormItem
                                    name={"fairStopOut"}
                                    label={t("Stop Out Type")}
                                    labelCol={labelColInput}
                                    options={[
                                        {value: true, label: t("Fair Stop Out")},
                                        {value: false, label: t("Smart Stop Out")},
                                    ]}
                                    value={initialData?.fairStopOut}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"bonusConversionRate"}
                                    label={t("Bonus Conversion Rate")}
                                    labelCol={labelColInput}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <SelectFormItem
                                    name={"stopoutDisabled"}
                                    label={t("Allow Traders to Change Stop Out Type")}
                                    labelCol={labelColInput}
                                    options={[
                                        {value: true, label: t("Enabled")},
                                        {value: false, label: t("Disabled")},
                                    ]}
                                    value={initialData?.bonusConversionRate}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"priceStreamProfile"}
                                    label={t("Price Stream Profile")}
                                    labelCol={labelColInput}
                                    value={initialData?.priceStreamId?.[0]?.name}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"swapsAndDividendsProfile"}
                                    label={t("Swaps and Dividends Profile")}
                                    labelCol={labelColInput}
                                    value={initialData?.swapAndDividendProfileId?.[0]?.name}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"inactivityFeeAmount"}
                                    label={t("Inactivity Fee Amount")}
                                    labelCol={labelColInput}
                                    value={initialData?.inactivityFeeAmount?.toString()}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"inactivityGracePeriod"}
                                    label={t("Inactivity Grace Period")}
                                    labelCol={labelColInput}
                                    value={initialData?.inactivityGracePeriod?.toString()}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"inactivityChargePeriod"}
                                    label={t("Inactivity Charge Period")}
                                    labelCol={labelColInput}
                                    value={initialData?.inactivityChargePeriod?.toString()}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <InputFormItem
                                    name={"blockedChannels"}
                                    label={t("Disable Trading by Channels")}
                                    labelCol={labelColInput}
                                    value={initialData?.blockedChannels}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <InputFormItem
                                    name={"reportingEntity"}
                                    label={t("Reporting Entity")}
                                    labelCol={labelColInput}
                                    value={initialData?.legalEntityId?.toString()}
                                    disabled
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8} style={{borderLeft: ".25px solid #999"}}>
                        <p>Dynamic Commissions</p>
                        <Form.Item>
                            <Space.Compact>
                                <Input
                                    name={"dynamicCommissionVolume"}
                                    placeholder={t("USD Volume Traded (more than)") ?? ""}
                                    defaultValue={initialData?.dynamicCommission?.[0]?.monthlyVolume?.toString()}
                                    disabled
                                />
                                <Input
                                    name={"dynamicCommissionDiscount"}
                                    placeholder={t("Commission Discount %") ?? ""}
                                    defaultValue={initialData?.dynamicCommission?.[0]?.discount?.toString()}
                                    disabled
                                />
                            </Space.Compact>
                        </Form.Item>
                    </Col>
                </Row>
            </form>
        </FormProvider>
    )
}

export default CommonPanel