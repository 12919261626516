import React, {useState} from "react";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import {Columns} from "../../function/columns";
import {useTranslation} from "react-i18next";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import {Button, Popconfirm, Row, Space} from "antd";
import CreateUpdateModal from "./components/createUpdateModal";
import {deleteCustomTrigger} from "../../../../../../api/restful/automationSetting/customTrigger";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../recoil_state";
import "../style.css"

const CustomTrigger_1 = ({data, refetch}) => {
    const {t} = useTranslation();
    const columns = Columns()

    const [isCreateUpdateModalOpen, setCreateUpdateModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const handleOpenModal = (record) => {
        setSelectedRecord(record)
        setCreateUpdateModalOpen(true);
    }

    const handleCloseModal = () => {
        setSelectedRecord(null)
        setCreateUpdateModalOpen(false)
    }

    const deleteCustomTriggerHandler = async (id) => {
        try {
            const response = await deleteCustomTrigger({_id: id})
            if (response?.status === "Success") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: `Delete Trigger - ${response?.data?.trigger} Successfully`,
                    key: "customTriggerInformation",
                })
                refetch()
            }
        } catch (e) {

        }
    }

    return (
        <div id={"custom-trigger"}>
            <Row justify={"space-between"} className={"margin-bottom-0-75"}>
                <h1>Custom Trigger</h1>
                <Button onClick={() => handleOpenModal(null)}>{t("Create Custom Trigger")}</Button>
            </Row>
            <TableAntDesign
                className={"margin-top-0-75"}
                data={data}
                tableName={"Automation Custom Trigger"}
                sort={false}
                columns={[...columns, {
                    title: t("common.action"),
                    key: "action",
                    width: 80,
                    render: (_, record) => {
                        return (
                            <Space>
                                <a onClick={() => handleOpenModal(record)}>
                                    <AiOutlineEdit/>
                                </a>
                                <Popconfirm
                                    placement="left"
                                    title={t("Are you sure to delete this custom trigger?")}
                                    description={t("Delete Custom Trigger")}
                                    onConfirm={() => deleteCustomTriggerHandler(record?._id)}
                                    okText={t("common.yes")}
                                    cancelText={t("common.no")}
                                >
                                <a style={{color: "red"}}>
                                    <AiOutlineDelete/>
                                </a>
                                </Popconfirm>
                            </Space>
                        )
                    }
                }]}
                scroll={false}
            />

            <CreateUpdateModal isOpen={isCreateUpdateModalOpen} handleCancel={handleCloseModal} item={selectedRecord} refetch={refetch}/>
        </div>
    )
}

export default CustomTrigger_1