import React, {useEffect, useState} from "react";
import {Drawer, Image as AntdImage, Modal} from "antd";
import {getConfigByName} from "../../../../../../../config";
import {BRAND_NAME} from "../../../../../../../config/brandVariable";

const url = getConfigByName(BRAND_NAME).arenaURL;
const PreviewModal = ({open, handleClose, data, current, setCurrent}) => {
    const [imageInfo, setImageInfo] = useState({width: 0, height: 0, fileSize: ""});
    const [svgContent, setSvgContent] = useState<any>(null);

    const preload = () => {
        if (current && current.filePath) {
            if (current?.svg) {
                const parser = new DOMParser()
                const svgDoc = parser.parseFromString(current.svg, 'image/svg+xml')
                const svgElement = svgDoc.querySelector('svg')

                if (svgElement) {
                    scaleSvgToFit(svgElement, "100%");
                    const modifiedSvgText = new XMLSerializer().serializeToString(svgElement)
                    setSvgContent(modifiedSvgText)
                }

                return
            }

            const img = new Image()
            img.src = url + current.filePath

            img.onload = async () => {
                try {
                    const response = await fetch(url + current.filePath)
                    const blob = await response.blob();

                    setImageInfo({
                        width: img.naturalWidth,
                        height: img.naturalHeight,
                        fileSize: formatFileSize(blob.size)
                    });
                } catch (error) {
                    console.error("Failed to fetch image size:", error)
                }
            }
        }
    }

    useEffect(() => {
        preload()
    }, [current]);

    const formatFileSize = (bytes) => {
        if (bytes < 1024) return `${bytes} B`;
        if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(2)} KB`;
        if (bytes < 1024 * 1024 * 1024) return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
        return `${(bytes / (1024 * 1024 * 1024)).toFixed(2)} GB`;
    }

    const scaleSvgToFit = (svgElement, maxWidth) => {
        const svgWidth = svgElement.viewBox.baseVal.width
        const svgHeight = svgElement.viewBox.baseVal.height

        const aspectRatio = svgWidth / svgHeight
        const newWidth = maxWidth
        const newHeight = newWidth / aspectRatio

        svgElement.setAttribute('width', newWidth)
        svgElement.setAttribute('height', newHeight)
    };

    return (
        <Drawer
            open={open}
            onClose={handleClose}
            footer={null}
            width={500}
            closeIcon={false}
            title={
                <>
                    <p className={"margin-bottom-0"}>{current?.displayName}</p>
                    {
                        current && !current.svg &&
                        <h1 className={"secondary-text"}>{imageInfo.width} x {imageInfo.height} • {imageInfo.fileSize}</h1>
                    }
                </>
            }
        >
            {
                current && current.svg ?
                    <div dangerouslySetInnerHTML={{__html: svgContent}}/>
                    :
                    <AntdImage
                        preview={false}
                        width={"100%"}
                        style={{maxHeight: imageInfo.height > window.innerHeight * 0.8 ? "80vh" : imageInfo.height, objectFit: "contain"}}
                        src={url + current?.filePath}
                    />

            }
        </Drawer>
    );
};

export default PreviewModal;
