import React, {useMemo, useState} from "react"
import {Col, Row, Spin, Tabs} from "antd"
import {Link} from "react-router-dom"
import GrapeJSEditor from "../../component/GrapeJSEditor"
import {useTranslation} from "react-i18next"
import {LoadingOutlined} from "@ant-design/icons"

function EmailTemplateEditor_1(props) {
    const {data, loading, languageData, updateLoading} = props
    const {t} = useTranslation()
    const [activeTab, setActiveTab] = useState("en")

    const onTabChange = (key: string) => {
        setActiveTab(key)
    }

    const tabItems = useMemo(
        () =>
            languageData?.map((item, index) => {
                return {
                    key: item.code,
                    label: item.name,
                    disabled: updateLoading || loading,
                    children: <GrapeJSEditor languageCode={item.code} {...props} />,
                }
            }),
        [languageData, data, activeTab]
    )

    return (
        <div id="emailTemplateEditor">
            {true && <Spin spinning={loading || updateLoading} style={{position: "absolute", height: "100vh", width: "100vw", backgroundColor: "#0001", zIndex: 9, alignContent: "center"}} />}
            <Row>
                <Col span={16}>
                    <h1>
                        {t("Email Template Editor")} - {data?.displayName}
                    </h1>
                </Col>
                <Col span={8} className="backLinkContainer">
                    <Link to="/settings/email-template" className="text-link">
                        Back to Email Template
                    </Link>
                </Col>
            </Row>
            <Tabs defaultActiveKey="en" items={tabItems} onChange={onTabChange} tabBarExtraContent={updateLoading && <Spin tip="Loading..." indicator={<LoadingOutlined />} />} />
        </div>
    )
}

export default EmailTemplateEditor_1
