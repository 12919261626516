import {atom} from "recoil";

const isEditingLegalDocumentState = atom({
  key: "isEditingLegalDocument",
  default: [],
});

const legalDocumentDataState = atom({
  key: "legalDocumentData",
  default: [],
});

export { isEditingLegalDocumentState, legalDocumentDataState };
