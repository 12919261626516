import {DatePicker, Space} from "antd";
import React, {useEffect, useState} from "react";
import {columnDeals, ColumnTradingAccountTransaction} from "../../../function/tableColumn";
import {useTranslation} from "react-i18next";
import EditTableUserPreferenceButton
    from "../../../../../../../components/table/function/editTableUserPreferenceButton";
import TableAntDesign from "../../../../../../../components/table/tableAntDesign";
import {getTradingAccountDeal} from "../../../../../../../api/graphql/tradingAccount";
import dayjs, {Dayjs} from "dayjs";


const AccountTableDeal = (props) => {
    const tableName = "Trading Account Deal"
    const {RangePicker} = DatePicker;
    const columns = columnDeals()
    const [data, setData] = useState([]);
    const [selectedDates, setSelectedDates] = useState<any>([dayjs().add(-30, "d"), dayjs()]);
    const {detail, totalCount, setTotalCount} = props

    const {t} = useTranslation()

    const rangePresets: {
        label: string;
        value: [Dayjs, Dayjs];
    }[] = [
        {label: t("date.today"), value: [dayjs().add(0, "d"), dayjs()]},
        {label: t("date.yesterday"), value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")]},
        {label: t("date.last7Days"), value: [dayjs().add(-7, "d"), dayjs()]},
        {label: t("date.last14Days"), value: [dayjs().add(-14, "d"), dayjs()]},
        {label: t("date.last30Days"), value: [dayjs().add(-30, "d"), dayjs()]},
        {
            label: t("date.thisWeek"),
            value: [dayjs().startOf("week"), dayjs()],
        },
        {
            label: t("date.lastWeek"),
            value: [dayjs().subtract(1, "week").startOf("week"), dayjs().subtract(1, "week").endOf("week")],
        },
        {
            label: t("date.thisMonth"),
            value: [dayjs().startOf("month"), dayjs()],
        },
        {
            label: t("date.lastMonth"),
            value: [dayjs().subtract(1, "month").startOf("month"), dayjs().subtract(1, "month").endOf("month")],
        },
        // {
        //     label: t("date.thisQuarter"),
        //     value: [dayjs().startOf("quarter"), dayjs()],
        // },
        // {
        //     label: t("date.lastQuarter"),
        //     value: [dayjs().subtract(1, "quarter").startOf("quarter"), dayjs().subtract(1, "quarter").endOf("quarter")],
        // },
        // {
        //     label: t("date.thisYear"),
        //     value: [dayjs().startOf("year"), dayjs()],
        // },
        // {
        //     label: t("date.lastYear"),
        //     value: [dayjs().subtract(1, "year").startOf("year"), dayjs().subtract(1, "year").endOf("year")],
        // },
        // {
        //     label: t("date.allTime"),
        //     value: [dayjs().year(1970).startOf("year"), dayjs()],
        // },
    ];

    const getDatesValueFromRangePreset = (rangeValue) => {
        let dates: any;
        let label: any;
        switch (rangeValue) {
            case "TODAY":
            case "Today":
                label = t("date.today");
                break;
            case "YESTERDAY":
            case "Yesterday":
                label = t("date.yesterday");
                break;
            case "LAST7DAYS":
            case "Last 7 Days":
                label = t("date.last7Days");
                break;
            case "LAST14DAYS":
            case "Last 14 Days":
                label = t("date.last14Days");
                break;
            case "LAST30DAYS":
            case "Last 30 Days":
                label = t("date.last30Days");
                break;
            case "THISMONTH":
            case "This Month":
                label = t("date.thisMonth");
                break;
            case "LASTMONTH":
            case "Last Month":
                label = t("date.lastMonth");
                break;
            // case "ALLTIME":
            // case "All Time":
            //     label = t("common.all");
            //     break;
            default:
                label = "date.lastMonth";
                break;
        }
        dates = rangePresets?.find((v: any) => v?.label === label)?.value;
        return dates;
    };

    const onRangePickerChange: any = async (dates: any, dateStrings: any[]) => {
        try {
            if (dates) {
                await setSelectedDates(dates);
            } else {
                await setSelectedDates([]);
                console.log("Clear");
            }
        } catch (e) {
            console.log(e);
        }
    };

    const preload = async () => {

        let fromDate = selectedDates?.[0]?.format("YYYY-MM-DD");
        let toDate = selectedDates?.[1]?.format("YYYY-MM-DD");

        const response = await getTradingAccountDeal(detail?._id, fromDate, toDate)
        setData(response?.getTradingAccountDeal?.data)
        setTotalCount(response?.getTradingAccountDeal?.data?.length)
    }

    useEffect(() => {
        if (detail?._id) {
            preload()
        }
    }, [selectedDates])

    return (
        <div id="accountDeal">
            <div className={"display-flex margin-bottom-0-75"}>
                <Space className={"margin-left-auto"}>
                    <RangePicker presets={rangePresets} placeholder={[t("common.startDate"), t("common.endDate")]}
                                 onChange={onRangePickerChange} defaultValue={selectedDates}/>

                    <EditTableUserPreferenceButton
                        tableName={tableName}
                        tableColumn={columns}
                    />
                </Space>
            </div>

            <TableAntDesign
                data={data}
                tableName={tableName}
                columns={columns}
                size={"small"}
                isTableLocalSearch={true}
                pagination={{
                    size: "small",
                    pageSize: 5,
                    total: totalCount,
                    showTotal: (total) => <p>Total {total} items</p>
                }}
            />
        </div>
    )
}

export default AccountTableDeal;
