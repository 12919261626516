import React, {useEffect, useState} from "react";
import TradingAccountTable_1 from "./component/tradingAccountTable";
import {Button, Row, Select, Space} from "antd";
import ExportTableButton from "../../../../../../components/table/function/exportTable";
import {columnTradingAccount} from "../../function/tableColumn";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {useRecoilState, useRecoilValue} from "recoil";
import {tradingAccountFilterTypeIdSearchState, tradingAccountFilterTypeSearchState} from "../../state";
import EditTableUserPreferenceButton from "../../../../../../components/table/function/editTableUserPreferenceButton";
import {userOptionsState} from "../../../../../users/_common/state";
import {selfPermissionObj} from "../../../../../../../recoil_state";
import moment from "moment";
import TradingAccountBatchEditForm_1 from "./component/tradingAccountBatchEditForm";
import TradingAccountBatchTransactionForm_1 from "./component/tradingAccountBatchTransactionForm";
import TradingAccountBatchCreditTransactionForm_1 from "./component/tradingAccountBatchCreditTransactionForm";
import {useNavigate} from "react-router-dom";

const TradingAccount_1 = (props) => {
    const {data, total, loading, exportData, refetch, userid, isSpreadGroup, spreadGroup, commRule, resetFilters, isClearFilterVisible} = props;
    const navigate = useNavigate();
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const tradingAccountPermission = selfPermission?.tradingAccount?.account;
    const tradingAccountTransactionPermission = selfPermission?.tradingAccount?.accountTransaction;
    const tradingAccountCreditPermission = selfPermission?.tradingAccount?.creditTransaction;
    const [exportDataLatest, setExportDataLatest] = useState([]);
    const [tradingAccountFilterType, setTradingAccountFilterType] = useRecoilState(tradingAccountFilterTypeSearchState);
    const [tradingAccountFilterId, setTradingAccountFilterId] = useRecoilState(tradingAccountFilterTypeIdSearchState);

    const [batchUpdateRecord, setBatchUpdateRecord] = useState<any>([]);
    const [viewEdit, setViewEdit] = useState<any>(false);
    const [viewTransaction, setViewTransaction] = useState<any>(false);
    const [viewCreditTransaction, setViewCreditTransaction] = useState<any>(false);
    const userOptions = useRecoilValue(userOptionsState);
    const tableName = "Trading Account";
    const {t} = useTranslation();

    const column = columnTradingAccount();

    const processExportData = async () => {
        if (exportData) {
            const sortedLatestData = [...exportData]?.sort((a: any, b: any) => {
                const dateA: Date | undefined = a?.createdAt ? new Date(a.createdAt) : undefined;
                const dateB: Date | undefined = b?.createdAt ? new Date(b.createdAt) : undefined;

                if (dateA && dateB) {
                    return dateB.getTime() - dateA.getTime();
                }

                if (!dateA && !dateB) {
                    return 0;
                } else if (!dateA) {
                    return 1;
                } else {
                    return -1;
                }
            })

            const latestRecord: any = await Promise.all(
                sortedLatestData?.map((d) => {
                    return {
                        ...d,
                        user: d?.user?.username,
                        product: tradingAccountPermission?.view?.product ? d?.product?.name : "-",
                        spreadGroup: tradingAccountPermission?.view?.spreadGroup ? d?.spreadGroup?.name : "-",
                        leverage: tradingAccountPermission?.view?.leverage ? d?.leverage : "-",
                        totalBalance: d?.totalBalance || 0,
                        credit: d?.credit || 0,
                        platform: d?.product?.server?.platform,
                        accountType: d?.product?.mode?.toString(),
                        registrationDatetime: moment(d.registrationDatetime)?.format("YYYY-MM-DD HH:mm:ss"),
                    };
                })
            );

            setExportDataLatest(latestRecord);
        }
    };

    // useEffect(() => {
    //     processExportData();
    // }, [exportData]);

    const filterTypeOptions = [
        {value: null, label: t("common.all")},
        {value: "usertree", label: t("common.filterBasedOnUserTree")},
    ];

    return (
        <div id="tradingAccount">
            {!isSpreadGroup && (
                <Space align={"center"} style={{marginBottom: ".5em"}}>
                    <h1 className={"margin-bottom-0"}>
                        {t("module.tradingAccount")} {userid ? `(${data?.[0]?.user?.username})` : ""}
                    </h1>
                    {(userid || isSpreadGroup || spreadGroup || commRule || isClearFilterVisible) && (
                        <Button size={"small"} onClick={() => {
                            resetFilters()
                            navigate("/account/trading-account")
                        }}>
                            {t("common.clearFilter")}
                        </Button>
                    )}
                </Space>
            )}

            {batchUpdateRecord?.length > 0 ? (
                <div>
                    <Space>
                        {tradingAccountPermission?.batchAction && (
                            <Button
                                onClick={() => {
                                    setViewEdit(true);
                                    setViewTransaction(false);
                                    setViewCreditTransaction(false);
                                }}
                            >
                                {t("common.edit")}
                            </Button>
                        )}
                        {tradingAccountTransactionPermission?.batchAction && (
                            <Button
                                onClick={() => {
                                    setViewTransaction(true);
                                    setViewEdit(false);
                                    setViewCreditTransaction(false);
                                }}
                            >
                                {t("tradingAccount.batchDeposit_Withdrawal")}
                            </Button>
                        )}
                        {tradingAccountCreditPermission?.batchAction && (
                            <Button
                                onClick={() => {
                                    setViewCreditTransaction(true);
                                    setViewEdit(false);
                                    setViewTransaction(false);
                                }}
                            >
                                {t("tradingAccount.batchCredit")}
                            </Button>
                        )}
                        <Button
                            onClick={() => {
                                setViewEdit(false);
                                setViewTransaction(false);
                                setViewCreditTransaction(false);
                                setBatchUpdateRecord([]);
                            }}
                        >
                            {t("common.cancel")}
                        </Button>
                    </Space>
                </div>
            ) : isSpreadGroup ? (
                <Row justify={"end"}>
                    <Space>
                        <ExportTableButton tableData={exportDataLatest} tableColumn={column} tableName={tableName}/>
                        <EditTableUserPreferenceButton displayTableName={t("module.tradingAccount")} tableName={tableName} tableColumn={column}/>
                    </Space>
                </Row>
            ) : (
                <Row justify={"space-between"}>
                    <Space>
                        <Select
                            value={tradingAccountFilterType}
                            options={filterTypeOptions}
                            onChange={(e) => {
                                setTradingAccountFilterType(e);
                            }}
                            defaultValue={null}
                            style={{width: 200}}
                        />
                        {tradingAccountFilterType === "usertree" && (
                            <Select
                                value={tradingAccountFilterId}
                                options={userOptions}
                                optionFilterProp={"label"}
                                showSearch={true}
                                onChange={(value) => {
                                    setTradingAccountFilterId(value);
                                }}
                                optionLabelProp="selectedLabel"
                                style={{width: 200}}
                            />
                        )}
                    </Space>
                    <Space>
                        <ExportTableButton
                            // tableData={exportDataLatest}
                            tableColumn={column}
                            tableName={tableName}
                        />
                        <EditTableUserPreferenceButton displayTableName={t("module.tradingAccount")} tableName={tableName} tableColumn={column}/>
                    </Space>
                </Row>
            )}

            {viewEdit && batchUpdateRecord?.length > 0 && (
                <TradingAccountBatchEditForm_1 batchUpdateRecord={batchUpdateRecord} setView={setViewEdit} refetch={refetch}/>
            )}
            {viewTransaction && batchUpdateRecord?.length > 0 && (
                <TradingAccountBatchTransactionForm_1 batchUpdateRecord={batchUpdateRecord} refetch={refetch} setView={setViewTransaction}/>
            )}
            {viewCreditTransaction && batchUpdateRecord?.length > 0 && (
                <TradingAccountBatchCreditTransactionForm_1
                    batchUpdateRecord={batchUpdateRecord}
                    refetch={refetch}
                    setView={setViewCreditTransaction}
                />
            )}

            <TradingAccountTable_1
                loading={loading}
                data={data}
                total={total}
                tableName={tableName}
                setBatchUpdateRecord={setBatchUpdateRecord}
                batchUpdateRecord={batchUpdateRecord}
                isSpreadGroup={isSpreadGroup}
            />
        </div>
    );
};

export default TradingAccount_1;
