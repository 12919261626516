import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Card, Col, Divider, Row, Table} from "antd";
import EWalletDetailModal from "./eWalletDetail/_eWalletDetailModal";
import {formatDateTime} from "../../../../../_common/function";
import {formatMoney} from "../../../../../../task/function";
import {formatDateTimeTimezone} from "../../../../../../../function/_common";

const EwalletTxsRecordTabItem = (props) => {
    const {data, transactions, summary, transactionLoading, fetchEWalletTransaction} = props;
    const {t} = useTranslation();

    const [detailModalIsOpen, setDetailModalIsOpen] = useState<boolean>(false);
    const [detail, setDetail] = useState<any>(null);

    const openDetailModal = (record) => {
        setDetailModalIsOpen(true);
        setDetail(record);
    };

    const closeDetailModal = () => {
        setDetailModalIsOpen(false);
        setDetail(null);
    };

    const column: any = [
        {
            title: t("common.date"),
            dataIndex: "statementDate",
            render: (text) => <p>{formatDateTimeTimezone(text)}</p>,
        },
        {
            title: t("common.description"),
            dataIndex: "description",
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("common.amount"),
            dataIndex: ["debit", "credit"],
            align: "right",
            render: (_, record) => <p>{record?.credit ? `-${record?.credit?.toFixed(2)}` : record?.debit?.toFixed(2)}</p>,
        },
        {
            title: t("common.transactionType"),
            dataIndex: "transactionType",
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("common.remark"),
            dataIndex: "remark",
            render: (_, record) => <p>{record?.eWalletTransactionId?.remark || "-"}</p>,
        },
        {
            title: t("common.comment"),
            dataIndex: "comment",
            render: (_, record) => <p>{record?.eWalletTransactionId?.comment || "-"}</p>,
        },
        {
            title: t("common.status"),
            dataIndex: "task",
            render: (text, record) => {
                const keywords = ["deposit", "withdrawal"];
                const includesKeyword = keywords.some((keyword) => record?.transactionType?.toLowerCase()?.includes(keyword));
                let displayValue = record?.eWalletTransactionId?.transactionStatus === "pending" && text?.status === "approved" ? "approved & processing" : text?.status
                if (includesKeyword) {
                    return <a onClick={() => openDetailModal(record)}>{displayValue || "-"}</a>;
                }
                return <p>-</p>;
            },
        },
    ];

    useEffect(() => {
        if (data?._id) {
            fetchEWalletTransaction({filter: {user: data?._id}})
        }
    }, [data?._id]);

    return (
        <>
            <div className={"margin-top-0-75"}>
                <Row gutter={[16, 16]}>
                    <Col xs={12} lg={6}>
                        <Card size={"small"} style={{height: "100%"}} title={t("common.deposit")} className={"ewallet-info-card yellow"}>
                            {t("common.total")} :&nbsp;
                            {formatMoney(summary?.totalDeposit || 0)}
                            <Divider type={"vertical"}/>
                            {t("common.pending")} :&nbsp;
                            {formatMoney(summary?.pendingDeposit || 0)}
                        </Card>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Card size={"small"} style={{height: "100%"}} title={t("common.withdrawal")} className={"ewallet-info-card gold"}>
                            {t("common.total")} :&nbsp;
                            {formatMoney(summary?.totalWithdrawal || 0)}
                            <Divider type={"vertical"}/>
                            {t("common.pending")} :&nbsp;
                            {formatMoney(summary?.pendingWithdrawal || 0)}
                        </Card>
                    </Col>
                    <Col xs={8} lg={4}>
                        <Card size={"small"} style={{height: "100%"}} title={t("user.nettDeposit")} className={"ewallet-info-card magenta"}>
                            {t("common.total")} :&nbsp;
                            {formatMoney(summary?.netDeposit || 0)}
                        </Card>
                    </Col>
                    <Col xs={8} lg={4}>
                        <Card size={"small"} style={{height: "100%"}} title={t("user.nettAdjustment")} className={"ewallet-info-card purple"}>
                            {t("common.total")} :&nbsp;
                            {formatMoney(summary?.netAdjustment || 0)}
                        </Card>
                    </Col>
                    <Col xs={8} lg={4}>
                        <Card size={"small"} style={{height: "100%"}} title={t("user.commission")} className={"ewallet-info-card blue"}>
                            {t("common.total")} :&nbsp;
                            {formatMoney(summary?.commission || 0)}
                        </Card>
                    </Col>
                </Row>
                <Table
                    loading={transactionLoading}
                    className={"margin-top-0-75"}
                    columns={column}
                    dataSource={transactions.data}
                    size={"small"}
                    scroll={{x: "max-content"}}
                    pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        total: transactions.total,
                        showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
                    }}
                />
            </div>
            <EWalletDetailModal isOpen={detailModalIsOpen} close={closeDetailModal} record={detail}/>
        </>
    );
};

export default EwalletTxsRecordTabItem;
