import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TableAntDesign from "../../../../../../../components/table/tableAntDesign";
import {columnsCommGroup} from "../../../function/commGroupColumns";
import {Popconfirm, Space} from "antd";
import {EditOutlined} from "@ant-design/icons";
import CommGroupModal from "./commGroupModal";
import {AiOutlineDelete} from "react-icons/ai";
import {deleteCommissionGroup} from "../../../../../../../api/graphql/commission";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import useAuthorize from "../../../../../../../_common/authorize";

function CommGroupTable_1(props) {
    const {
        data,
        total,
        server,
        tableName,
        loading,
        limit,
        setLimit,
        setOffset,
        setFilter,
        setStartTime,
        setEndTime,
        setDateKey,
        setOrderBy,
        userData,
        preload
    } = props
    const {t} = useTranslation()

    const columns = columnsCommGroup
    const [openModal, setOpenModal] = useState(false);
    const [editData, setEditData] = useState({});
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {systemErrorNotification} = useAuthorize()
    const navigate = useNavigate();

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize)
        setOffset(limit * (page - 1))
    }

    const deleteGroup = async (id) => {
        try {
            const response = await deleteCommissionGroup(id)
            if (response?.deleteCommissionGroup?.__typename === "CommissionGroup") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Deleted Successfully"),
                    key: "CommissionGroup",
                });
            } else if (response?.deleteCommissionGroup.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.deleteCommissionGroup?.errKey),
                    key: "CommissionGroup",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.deleteCommissionGroup?.msg),
                    key: "CommissionGroup",
                });
            }
        } catch (e) {
            systemErrorNotification()
        } finally {
            preload()
        }
    }

    return (
        <div className={"margin-top-0-75"}>
            <TableAntDesign data={data}
                            tableName={tableName}
                            columns={[...columns, {
                                title: t("common.action"),
                                key: "action",
                                width: 100,
                                align: "left",
                                render: (_, record: any) => {
                                    const network = record?.network?.map((item) => {
                                        return item?._id
                                    })
                                    const formatted = {
                                        ...record,
                                        network: network
                                    }
                                    return (
                                        <Space>
                                            <a onClick={() => {
                                                setOpenModal(true)
                                                setEditData(formatted)
                                            }}>
                                                <EditOutlined/>
                                            </a>
                                            <Popconfirm
                                                placement="left"
                                                description={t("Are you sure to delete this comm group?")}
                                                title={t(`Delete Comm Group`)}
                                                onConfirm={() => {
                                                    deleteGroup(record?._id);
                                                }}
                                                okText={t("Yes")}
                                                okType="danger"
                                                cancelText={t("No")}
                                            >
                                                <a>
                                                    <AiOutlineDelete style={{color: "red"}}/>
                                                </a>
                                            </Popconfirm>
                                        </Space>
                                    )
                                },
                            }]}
                            size={"small"}
                            server={server}
                            loading={loading}
                            filter={setFilter}
                            startDate={setStartTime}
                            endDate={setEndTime}
                            dateKey={setDateKey}
                            order={setOrderBy}
                            pagination={{
                                pageSize: limit,
                                showSizeChanger: true,
                                position: "bottomCenter",
                                total: total,
                                onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                                showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                            }}
            />

            <CommGroupModal isOpen={openModal} closeModal={() => setOpenModal(false)}
                            userData={userData} preload={preload} type={"edit"} data={editData}/>
        </div>
    )
};

export default CommGroupTable_1;
