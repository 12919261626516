import React from "react";

const MetaproBackground = () => {
    return (
        <svg
            className="badge-bg"
            xmlns="http://www.w3.org/2000/svg"
            width="767"
            height="1080"
            viewBox="0 0 767 1080"
            fill="none"
        >
            <g filter="url(#filter0_dd_1379_40030)">
                <path
                    d="M713 540C713 906.165 416.165 1203 50 1203C-316.165 1203 -613 906.165 -613 540C-613 173.835 -316.165 -123 50 -123C416.165 -123 713 173.835 713 540Z"
                    fill="url(#paint0_linear_1379_40030)"
                />
                <path
                    d="M713 540C713 906.165 416.165 1203 50 1203C-316.165 1203 -613 906.165 -613 540C-613 173.835 -316.165 -123 50 -123C416.165 -123 713 173.835 713 540Z"
                    fill="url(#paint1_linear_1379_40030)"
                    fill-opacity="0.5"
                />
            </g>
            <defs>
                <filter
                    id="filter0_dd_1379_40030"
                    x="-667"
                    y="-177"
                    width="1434"
                    height="1434"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="18" dy="18" />
                    <feGaussianBlur stdDeviation="18" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="overlay"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1379_40030"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="-18" dy="-18" />
                    <feGaussianBlur stdDeviation="18" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="overlay"
                        in2="effect1_dropShadow_1379_40030"
                        result="effect2_dropShadow_1379_40030"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_1379_40030"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_1379_40030"
                    x1="399.268"
                    y1="12.5444"
                    x2="50"
                    y2="1203"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#3E3E3E" />
                    <stop offset="1" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1379_40030"
                    x1="-156.5"
                    y1="-18.5"
                    x2="654.5"
                    y2="325"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop />
                    <stop offset="1" stop-opacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
}

const MetaproFont1 = () => {
    return (
        <svg
            className="badge-font"
            xmlns="http://www.w3.org/2000/svg"
            width="435"
            height="586"
            viewBox="0 0 435 586"
            fill="none"
        >
            <g filter="url(#filter0_dd_1379_40010)">
                <path
                    d="M381.001 410.022V72.4062C381.004 69.9106 380.499 67.4406 379.515 65.1466C378.532 62.8526 377.092 60.7829 375.282 59.0635C373.472 57.3442 371.33 56.0112 368.988 55.1461C366.646 54.281 364.151 53.9017 361.658 54.0315C233.194 59.993 122.692 109.119 30.1526 201.409C-31.2966 262.155 -75.266 348.32 -91.6181 411.162C-92.3879 414.163 -92.4384 417.304 -91.7654 420.328C-91.0923 423.353 -89.7148 426.176 -87.7446 428.568L-7.06258 527.179C-5.5756 528.998 -3.57319 530.325 -1.31807 530.987C0.93705 531.648 3.33952 531.613 5.57428 530.886C7.80904 530.159 9.77168 528.773 11.2047 526.912C12.6377 525.05 13.4742 522.799 13.6044 520.454C20.2114 400.565 87.6671 287.402 196.015 225.234C230.632 205.365 273.792 230.134 273.792 270.032V519.364C273.802 521.764 274.552 524.103 275.941 526.061C277.33 528.019 279.29 529.501 281.553 530.305C283.817 531.109 286.273 531.196 288.587 530.553C290.901 529.91 292.96 528.569 294.484 526.714L374.063 429.426C378.549 423.953 381 417.097 381.001 410.022Z"
                    fill="#222222"
                />
                <path
                    d="M381.001 410.022V72.4062C381.004 69.9106 380.499 67.4406 379.515 65.1466C378.532 62.8526 377.092 60.7829 375.282 59.0635C373.472 57.3442 371.33 56.0112 368.988 55.1461C366.646 54.281 364.151 53.9017 361.658 54.0315C233.194 59.993 122.692 109.119 30.1526 201.409C-31.2966 262.155 -75.266 348.32 -91.6181 411.162C-92.3879 414.163 -92.4384 417.304 -91.7654 420.328C-91.0923 423.353 -89.7148 426.176 -87.7446 428.568L-7.06258 527.179C-5.5756 528.998 -3.57319 530.325 -1.31807 530.987C0.93705 531.648 3.33952 531.613 5.57428 530.886C7.80904 530.159 9.77168 528.773 11.2047 526.912C12.6377 525.05 13.4742 522.799 13.6044 520.454C20.2114 400.565 87.6671 287.402 196.015 225.234C230.632 205.365 273.792 230.134 273.792 270.032V519.364C273.802 521.764 274.552 524.103 275.941 526.061C277.33 528.019 279.29 529.501 281.553 530.305C283.817 531.109 286.273 531.196 288.587 530.553C290.901 529.91 292.96 528.569 294.484 526.714L374.063 429.426C378.549 423.953 381 417.097 381.001 410.022Z"
                    fill="url(#paint0_radial_1379_40010)"
                />
            </g>
            <defs>
                <filter
                    id="filter0_dd_1379_40010"
                    x="-146.235"
                    y="0.00662231"
                    width="581.236"
                    height="585.452"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="18" dy="18" />
                    <feGaussianBlur stdDeviation="18" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="overlay"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1379_40010"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="-18" dy="-18" />
                    <feGaussianBlur stdDeviation="18" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="overlay"
                        in2="effect1_dropShadow_1379_40010"
                        result="effect2_dropShadow_1379_40010"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_1379_40010"
                        result="shape"
                    />
                </filter>
                <radialGradient
                    id="paint0_radial_1379_40010"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(144.383 27.4473) rotate(90) scale(1341.72 1408.32)"
                >
                    <stop stop-color="#101010" stop-opacity="0" />
                    <stop offset="0.280849" stop-color="#101010" />
                    <stop offset="0.349382" stop-color="#4F001F" />
                    <stop offset="0.406726" stop-color="#AF003F" />
                    <stop offset="0.454279" stop-color="#FF0D56" />
                    <stop offset="0.554981" stop-color="white" />
                </radialGradient>
            </defs>
        </svg>
    );
}

const MetaproFont2 = () => {
    return (
        <svg
            className="badge-font"
            xmlns="http://www.w3.org/2000/svg"
            width="434"
            height="586"
            viewBox="0 0 434 586"
            fill="none"
        >
            <g filter="url(#filter0_dd_1379_40012)">
                <path
                    d="M-94 410.015V72.3989C-94.0033 69.9044 -93.4983 67.4354 -92.5158 65.1423C-91.5333 62.8492 -90.0939 60.7801 -88.2853 59.0609C-86.4766 57.3417 -84.3366 56.0086 -81.9957 55.1427C-79.6548 54.2768 -77.162 53.8962 -74.6691 54.0242C53.7945 59.9858 164.3 109.111 256.848 201.401C318.285 262.148 362.267 348.313 378.607 411.154C379.384 414.15 379.44 417.286 378.771 420.307C378.102 423.329 376.727 426.149 374.757 428.537L294.088 527.147C292.599 528.964 290.595 530.29 288.34 530.95C286.085 531.611 283.683 531.575 281.448 530.848C279.213 530.121 277.25 528.737 275.816 526.877C274.381 525.017 273.542 522.767 273.409 520.422C266.802 400.534 199.358 287.37 90.9975 225.203C56.3932 205.334 13.2328 230.103 13.2328 270V519.357C13.2262 521.76 12.4769 524.102 11.0873 526.064C9.69769 528.025 7.73566 529.509 5.46961 530.314C3.20357 531.118 0.744209 531.203 -1.57214 530.558C-3.8885 529.912 -5.94866 528.567 -7.47086 526.707L-87.062 429.455C-91.5479 423.968 -93.9989 417.101 -94 410.015Z"
                    fill="#222222"
                />
                <path
                    d="M-94 410.015V72.3989C-94.0033 69.9044 -93.4983 67.4354 -92.5158 65.1423C-91.5333 62.8492 -90.0939 60.7801 -88.2853 59.0609C-86.4766 57.3417 -84.3366 56.0086 -81.9957 55.1427C-79.6548 54.2768 -77.162 53.8962 -74.6691 54.0242C53.7945 59.9858 164.3 109.111 256.848 201.401C318.285 262.148 362.267 348.313 378.607 411.154C379.384 414.15 379.44 417.286 378.771 420.307C378.102 423.329 376.727 426.149 374.757 428.537L294.088 527.147C292.599 528.964 290.595 530.29 288.34 530.95C286.085 531.611 283.683 531.575 281.448 530.848C279.213 530.121 277.25 528.737 275.816 526.877C274.381 525.017 273.542 522.767 273.409 520.422C266.802 400.534 199.358 287.37 90.9975 225.203C56.3932 205.334 13.2328 230.103 13.2328 270V519.357C13.2262 521.76 12.4769 524.102 11.0873 526.064C9.69769 528.025 7.73566 529.509 5.46961 530.314C3.20357 531.118 0.744209 531.203 -1.57214 530.558C-3.8885 529.912 -5.94866 528.567 -7.47086 526.707L-87.062 429.455C-91.5479 423.968 -93.9989 417.101 -94 410.015Z"
                    fill="url(#paint0_radial_1379_40012)"
                    fill-opacity="0.7"
                />
            </g>
            <defs>
                <filter
                    id="filter0_dd_1379_40012"
                    x="-148"
                    y="0"
                    width="581.234"
                    height="585.421"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="18" dy="18" />
                    <feGaussianBlur stdDeviation="18" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="overlay"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1379_40012"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="-18" dy="-18" />
                    <feGaussianBlur stdDeviation="18" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="overlay"
                        in2="effect1_dropShadow_1379_40012"
                        result="effect2_dropShadow_1379_40012"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_1379_40012"
                        result="shape"
                    />
                </filter>
                <radialGradient
                    id="paint0_radial_1379_40012"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(142.617 27.4424) rotate(90) scale(1341.63 1408.32)"
                >
                    <stop stop-color="#101010" stop-opacity="0" />
                    <stop offset="0.280849" stop-color="#101010" />
                    <stop offset="0.349382" stop-color="#4F001F" />
                    <stop offset="0.406726" stop-color="#AF003F" />
                    <stop offset="0.454279" stop-color="#FF0D56" />
                    <stop offset="0.554981" stop-color="white" />
                </radialGradient>
            </defs>
        </svg>
    );
}

export const MetaproBadge = () => {
    return (
        <div className="metapro-badge">
            <div className="badge-container">
                <MetaproBackground />
                <MetaproFont1 />
                <MetaproFont2 />
            </div>
        </div>
    );
}