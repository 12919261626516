import {atom} from "recoil";

const userToken = atom({
  key: "userToken",
  default: "",
});

const userLogoutState = atom({
  key: "userLogout",
  default: false,
});

const languageDataList = atom({
  key: "languageDataList",
  default: true,
});

const languageDataLoading = atom({
  key: "languageDataLoading",
  default: true,
});

const selfPermissionList = atom({
  key: "selfPermissionList",
  default: [],
});

const selfPermissionObj = atom({
  key: "selfPermissionObj",
  default: {},
});

const selfPermissionLoading = atom({
  key: "selfPermissionLoading",
  default: true,
});

const brandConfigState = atom({
  key: "brandConfig",
  default: {}
})

const timezoneList = atom({
  key: "timezoneList",
  default: "",
});

const currencySettingState = atom({
  key: "currencySettingState",
  default: [],
});

const fieldListState = atom({
  key: "fieldListState",
  default: [],
});

export const unreadAnnouncementCountState = atom({
  key: "unreadAnnouncementCountState",
  default: 0
})

const unreadPushNotificationCountState = atom({
  key: "unreadPushNotificationCountState",
  default: 0
})

const headerPushNotificationDataState = atom({
  key: "headerPushNotificationDataState",
  default: []
})

//Error Handling
const errorMessage = atom({
  key: "errorMessage",
  default: {
    errKey: "",
    msg: "",
  },
});

interface NotificationMessage {
  status: any;
  title: any;
  msg: any;
  key: any;
  icon?: any;
}

const notificationMessage = atom<NotificationMessage>({
  key: "notificationMessage",
  default: {
    status: "",
    title: "",
    msg: "",
    key: "",
    icon: "",
  },
});

const pushNotificationServerSettingState = atom({
  key: "pushNotificationServerSettingState",
  default: {},
});

export {
  userToken,
  userLogoutState,
  languageDataList,
  languageDataLoading,
  selfPermissionList,
  selfPermissionObj,
  selfPermissionLoading,
  brandConfigState,
  timezoneList,
  errorMessage,
  notificationMessage,
  currencySettingState,
  fieldListState,
  unreadPushNotificationCountState,
  headerPushNotificationDataState,
  pushNotificationServerSettingState,
};
