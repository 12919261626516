import React, {useCallback, useEffect} from "react";
import {getFieldList, getUserLeadsFieldList} from "../../../api/graphql/fieldsSetting";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {
    fieldCategoryState,
    fieldLabelState,
    fieldListLoading,
    fieldListState,
    leadsFieldListLoading,
    leadsFieldListState,
    profileInformationSettingLoading,
    profileInformationSettingState,
    userTypeState
} from "./state";
import Fields_1 from "./view/layout1";
import {getProfileInformationSetting} from "../../../api/graphql/profileInformation";
import {selfPermissionObj} from "../../../../recoil_state";


function Fields() {
    let layout = 1;
    let component;

    const permission = useRecoilValue<any>(selfPermissionObj)

    const setFieldList = useSetRecoilState(fieldListState)
    const setLoading = useSetRecoilState(fieldListLoading)
    const setLeadsFieldList = useSetRecoilState(leadsFieldListState)
    const setLeadsLoading = useSetRecoilState(leadsFieldListLoading)
    const label = useRecoilValue(fieldLabelState)
    const category = useRecoilValue(fieldCategoryState)

    const setProfileInformationSetting = useSetRecoilState(profileInformationSettingState)
    const setProfileInformationLoading = useSetRecoilState(profileInformationSettingLoading)
    const type = useRecoilValue(userTypeState)

    const {fieldList, loading} = getFieldList({label: label, category: category})
    const {fieldList:leadsFieldList, loading:leadsLoading} = getUserLeadsFieldList()
    const {data, loading: loadingProfile} = getProfileInformationSetting({type: type})

    useEffect(() => {
        setFieldList(fieldList?.getFieldList?.data)
        setLoading(loading)
    }, [fieldList?.getFieldList?.data, loading])

    useEffect(() => {
        setLeadsFieldList(leadsFieldList?.getUserLeadsFieldList?.data)
        setLeadsLoading(leadsLoading)
    },[leadsFieldList?.getUserLeadsFieldList?.data, leadsLoading])

    const processData = useCallback(async (data) => {
        if (data?.length > 0) {
            const activeField: any = []
            await data?.map((item) => {
                if (item?.position !== null) {
                    activeField?.push(item);
                }
            });

            return activeField
        }
    },[data])

    useEffect(() => {
        processData(data?.getProfileInformationSetting?.data)?.then(data => setProfileInformationSetting(data))
        setProfileInformationLoading(loadingProfile)
    }, [data?.getProfileInformationSetting?.data, loadingProfile])

    switch (layout) {
        case 1:
            component = <Fields_1
                fieldListPermission={permission?.userSetting?.fields}
                profileInformationPermission={permission?.userSetting?.fields}
            />
            break;

        default:
            component = <Fields_1
                fieldListPermission={permission?.userSetting?.fields}
                profileInformationPermission={permission?.userSetting?.fields}
            />;
            break;
    }

    return component;
}

export default Fields;
