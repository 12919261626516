import React from "react";
import {Tabs} from "antd";
import PlatformForm from "./component/platformForm";

const PlatformDownload_1 = (props) => {
    const {platform, platformDownloadPermission, languageData} = props;
    const app = platform?.find(d => d?.type === "app")

    return (
        <div id="platformDownload">
            <h1>Platform Download</h1>
            {platform && (
                <Tabs
                    defaultActiveKey="1"
                    size={"small"}
                    style={{marginBottom: 32, padding: "12px"}}
                    items={[
                        ...platform?.filter(d => d?.type !== "app")?.map((item, idx) => {
                            return {
                                label: `${item?.displayName} (${item?.type})`,
                                key: idx,
                                children: (
                                    <PlatformForm
                                        id={item._id}
                                        serverid={item?.server?._id}
                                        data={item}
                                        platformDownloadPermission={platformDownloadPermission}
                                        languageData={languageData}
                                    />
                                ),
                            };
                        }),
                        {
                            label: `${app?.displayName} (${app?.type})`,
                            key: app?.id,
                            children: (
                                <PlatformForm
                                    id={app?._id}
                                    serverid={app?.server?._id}
                                    data={app}
                                    platformDownloadPermission={platformDownloadPermission}
                                    languageData={languageData}
                                />
                            ),
                        }
                    ]}
                />
            )}
        </div>
    );
};

export default PlatformDownload_1;
