import React, {useCallback, useEffect, useState} from "react";
import {Button, Card, Checkbox, Col, DatePicker, Form, Input, Radio, Row, Select, Space, Upload} from "antd";
import {useTranslation} from "react-i18next";

const UserPreview = (props) => {
    const {fields, currentTab} = props
    const {basicField, loginField, residenceField, additionalField, corporateField} = fields;
    const {t} = useTranslation();
    const ContainerHeight = "68vh";
    const [fixedFields, setFixedFields] = useState<any>()

    const clientFields = () => {
        return (
            <>
                <Col span={12}>
                    <Form.Item
                        name={"language-preview"}
                        label={t("Language")}
                        labelCol={{span: 10, offset: 0}}
                    >
                        <Select
                            placeholder={t("Language").toString()}
                            disabled
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={"comment"}
                        label={t("user.comment")}
                        labelCol={{span: 10, offset: 0}}
                    >
                        <Select
                            showSearch
                            disabled
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={"newsletter"}
                        label={t("Newsletter")}
                        labelCol={{span: 10, offset: 0}}
                    >
                        <Checkbox.Group style={{width: "100%"}}>
                            <Row>
                                <Col span={24} style={{marginBottom: 8}}>
                                    <Checkbox disabled>
                                        {t("field.newsletter")}
                                    </Checkbox>
                                </Col>
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={"isTestAccount"}
                        label={t("Is Test Account?")}
                        labelCol={{span: 10, offset: 0}}
                    >
                        <Checkbox disabled/>
                    </Form.Item>
                </Col>
            </>
        )
    }

    const crmUserFields = () => {
        return (
            <>
                <Col span={12}>
                    <Form.Item
                        name={"comment"}
                        label={t("user.comment")}
                        labelCol={{span: 10, offset: 0}}
                    >
                        <Select
                            placeholder={t("user.comment").toString()}
                            disabled
                        />
                    </Form.Item>
                </Col>
            </>
        )
    }

    useEffect(() => {
        switch (currentTab) {
            case "client":
                setFixedFields(clientFields)
                break
            case "crmUser":
                setFixedFields(crmUserFields)
                break
            default :
                setFixedFields(<></>)
                break
        }
    }, [currentTab])

const renderField = useCallback((item) =>
    {
        const field = item?.fieldList;
        const fieldTypeComponents = {
            password: (
                <Input.Password disabled/>
            ),
            text: (
                <Input disabled/>
            ),
            file: (
                <Upload disabled>
                    <Button disabled>{t("Upload")}</Button>
                </Upload>
            ),
            date: (
                <DatePicker disabled style={{width: "100%"}}/>
            ),
            dropdown: (
                <Select disabled/>
            ),
            textarea: (
                <Input.TextArea disabled/>
            ),
            radioButton: (
                <Radio.Group disabled>
                    {field?.optionValue?.map((option, idx) => (
                        <Radio.Button key={option?.value} id={idx} value={option?.value}>
                            {option?.label}
                        </Radio.Button>
                    ))}
                </Radio.Group>
            ),
            checkbox: (
                <Checkbox disabled/>
            ),
            checkboxGroup: (
                <Checkbox.Group options={field?.optionValue} disabled/>
            ),
            mobile: (
                <Space.Compact style={{width: "100%"}}>
                    <Select
                        options={field?.optionValue} disabled
                        style={{width: 100}}
                    />
                    <Input disabled/>
                </Space.Compact>
            )
        };

        return (
            <>
            <Col span={12} key={item?._id}>
                <Form.Item
                    name={field?.fieldName + item?.type + Date.now()}
                    label={t(field?.label)}
                    required={field?.isRequired}
                >
                    {fieldTypeComponents[field?.fieldType]}
                </Form.Item>
            </Col>
                {
                    field?.isConfirmRequired &&
                    <Col span={12}>
                        <Form.Item
                            name={`${field?.fieldName + item?.type+ Date.now()}_confirm`}
                            label={`${t("user.confirmField", {label: t(field?.label)})}`}
                            required={field?.isRequired}
                        >
                            {fieldTypeComponents[field?.fieldType]}
                        </Form.Item>
                    </Col>
                }
            </>
        );
    }
, [fields]);

return (
    <div id={"user-preview"}>
        <Row style={{marginBottom: "30px"}}>
            <Col flex={"auto"}>
                <h4>User Preview</h4>
            </Col>
        </Row>
        <Card style={{height: ContainerHeight, overflow: "auto", padding: 16}}>
            <Form layout="vertical">
                {basicField?.length > 0 && (
                    <>
                        <h4>{t("field.category.basicInformation")}</h4>
                        <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                            {basicField.map((item) => renderField(item))}
                            {fixedFields}
                        </Row>
                    </>
                )}
                {residenceField?.length > 0 && (
                    <>
                        <h4>{t("Residence Information")}</h4>
                        <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                            {residenceField.map((item) => renderField(item))}
                        </Row>
                    </>
                )}
                {additionalField?.length > 0 && (
                    <>
                        <h4>{t("field.category.additionalInformation")}</h4>
                        <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                            {additionalField.map((item) => renderField(item))}
                        </Row>
                    </>
                )}
                {corporateField?.length > 0 && (
                    <>
                        <h4>{t("Corporate Information")}</h4>
                        <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                            {corporateField.map((item) => renderField(item))}
                        </Row>
                    </>
                )}
                {currentTab !== "leads" && loginField?.length > 0 && (
                    <>
                        <h4>{t("field.category.loginInformation")}</h4>
                        <Row gutter={[16, 8]} style={{marginBottom: 32}}>
                            {loginField.map((item) => renderField(item))}
                        </Row>
                    </>
                )}
            </Form>
        </Card>
    </div>
);
};
export default UserPreview