import React, {useState} from "react";
import {Col, Form, Input, Select} from "antd";
import {DeleteOutlined} from "@ant-design/icons";

const BodyFormList = ({field, referenceFromOptions, remove, isReportOnly, fromAutomationSetting = false}) => {
    const [valueLabel, setValueLabel] = useState<string>(fromAutomationSetting ? "Value" : "Default Value");

    return (
        <>
            <Col span={6}>
                <Form.Item name={[field.name, 'fieldName']}>
                    <Input placeholder={"Field Name"} disabled={fromAutomationSetting}/>
                </Form.Item>
            </Col>
            <Col span={5}>
                <Form.Item name={[field.name, 'dataType']}>
                    <Select
                        disabled={fromAutomationSetting}
                        placeholder={"Data Type"}
                        options={[
                            {label: "String", value: "string"},
                            {label: "Array", value: "array"},
                            {label: "Number", value: "number"},
                            {label: "Boolean", value: "boolean"},
                            {label: "Unknown", value: "unknown"},
                        ]}
                    />
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item name={[field.name, 'referenceFrom']} initialValue={isReportOnly ? "custom" : null}>
                    <Select
                        disabled={fromAutomationSetting}
                        placeholder={"Reference From"}
                        options={referenceFromOptions}
                        onChange={(value) => {
                            if (value === "payload") {
                                setValueLabel("Payload Field Name");
                            } else setValueLabel(fromAutomationSetting ? "Value" : "Default Value");
                        }}
                    />
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item name={[field.name, 'value']}>
                    <Input placeholder={valueLabel}/>
                </Form.Item>
            </Col>
            <Col span={1} className={"text-align-center"}>
                <a style={{color: "red"}} onClick={() => remove(field.name)}>
                    <DeleteOutlined/>
                </a>
            </Col>
        </>
    )
}

export default BodyFormList