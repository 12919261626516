import {gql, useApolloClient, useMutation, useQuery} from '@apollo/client'
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";

const GET_MOBILE_MODULE_SETTING_COUNT = gql`
    query getMobileModuleSettingCount($filter: MobileModuleSettingSearchInput) {
        getMobileModuleSettingCount(filter: $filter)
    }
`;

const getMobileModuleSettingCount = ({filter}) => {
    try {
        const {loading, error, data} = useQuery(GET_MOBILE_MODULE_SETTING_COUNT, {
            variables: {
                filter: {
                    moduleName: filter?.moduleName || null,
                    position: filter?.position || null,
                    isLocked: filter?.isLocked || null,
                    type: filter?.type || null,
                }
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, data}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const GET_MOBILE_MODULE_SETTING_BY_ID = gql`
  query getMobileModuleSettingByID($id: String!) {
    getMobileModuleSettingByID(id: $id){
      ... on MobileModuleSetting {
        _id
        id
        moduleId {name}
        position
        isLocked
        type
        createdBy {email}
        updatedBy {email}
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }

`;

const getMobileModuleSettingById = (id) => {
    try {
        const {loading, error, data} = useQuery(GET_MOBILE_MODULE_SETTING_BY_ID, {
            variables: {
                id: id
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, data}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const GET_MOBILE_MODULE_SETTING = gql`
  query getMobileModuleSetting($filter: MobileModuleSettingSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
    getMobileModuleSetting(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy){
      ... on MobileModuleSettingArray {
        data {
          _id
          id
          moduleId {
              name
              _id
              isEnabled
          }
          position
          isLocked
          type
          createdBy {email}
          updatedBy {email}
          createdAt
          updatedAt
      }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getMobileModuleSetting = ({filter}, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data} = useQuery(GET_MOBILE_MODULE_SETTING, {
            variables: {
                filter: {
                    moduleName: filter?.moduleName || null,
                    position: filter?.position || null,
                    isLocked: filter?.isLocked || null,
                    type: filter?.type || null,
                },
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, data}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const UPDATE_MOBILE_MODULE_SETTING = gql`
  mutation updateMobileModuleSetting($input: MobileModuleSettingInput) {
    updateMobileModuleSetting(input: $input){
      ... on MobileModuleSetting {
        _id
        id
        moduleId {name}
        position
        isLocked
        type
        createdBy {email}
        updatedBy {email}
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useUpdateMobileModuleSettingMutation = (): { updateMobileModuleSetting: any, data: any, loading: boolean, error: any } => {
    try {
        const { t } = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updateMobileModuleSettingMutation, {data, loading, error}] = useMutation(UPDATE_MOBILE_MODULE_SETTING);

        const updateMobileModuleSetting = (input) => {
            return updateMobileModuleSettingMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {updateMobileModuleSetting}}) {
                    const typename = updateMobileModuleSetting?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateMobileModuleSetting?.errKey),
                            key: "updateMobileModuleSetting",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateMobileModuleSetting?.msg),
                            key: "updateMobileModuleSetting",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Mobile Module Setting Successfully"),
                            key: "updateMobileModuleSetting",
                        });
                    }
                },
                refetchQueries: [GET_MOBILE_MODULE_SETTING]
            });
        };

        return {updateMobileModuleSetting, data, loading, error}
    } catch (err: any) {
        console.error(err);
        return {updateMobileModuleSetting: null, data: null, loading: false, error: err};
    }
}

export {
    getMobileModuleSettingCount,
    getMobileModuleSettingById,
    getMobileModuleSetting,
    useUpdateMobileModuleSettingMutation,
    GET_MOBILE_MODULE_SETTING
}