import React from "react";
import {getBooleanFilterProps, getColumnSearchProps, getDateRangeFilterProps} from "../../../../components/table/function/filterTable";
import {BsCheck, BsX} from "react-icons/bs";
import {useTranslation} from "react-i18next";

export const Columns = () => {
    const {t} = useTranslation()

    return [
        {
            title: t("user.username"),
            dataIndex: "username",
            key: "username",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("lead.name"),
            dataIndex: "name",
            key: "name",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("user.email"),
            dataIndex: "email",
            key: "email",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("salesOpportunity.spid"),
            dataIndex: "salesOpportunityId",
            key: "salesOpportunityId",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("salesOpportunity.salesOpportunityName"),
            dataIndex: "salesOpportunityName",
            key: "salesOpportunityName",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("user.salesRep"),
            dataIndex: "salesRepName",
            key: "salesRepName",
            ...getColumnSearchProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("salesOpportunity.dateCreated"),
            dataIndex: "createdAt",
            key: "createdAt",
            ...getDateRangeFilterProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("salesOpportunity.dateUpdated"),
            dataIndex: "updatedAt",
            key: "updatedAt",
            ...getDateRangeFilterProps(),
            render: (text) => <p>{text || "-"}</p>,
        },
        {
            title: t("common.amount"),
            dataIndex: "amount",
            key: "amount",
            align: "right",
            ...getColumnSearchProps(),
            render: (text) => <p>{text ? parseFloat(text).toFixed(2) : "0.00"}</p>,
        },
        {
            title: t("common.status"),
            dataIndex: "salesOppStatusName",
            key: "salesOppStatusName",
            render: (text) => <p>{t(text) || "-"}</p>,
        },
        {
            title: t("salesOpportunity.remark"),
            dataIndex: "remark",
            key: "remark",
            ...getColumnSearchProps(),
            render: (text) => <p>{text && text?.length > 20 ? text?.slice(0, 17)?.padEnd(20, "...") : text}</p>,
        },
        {
            title: t("salesOpportunity.reviewed"),
            dataIndex: "isReviewed",
            key: "isReviewed",
            ...getBooleanFilterProps(),
            render: (text) => text ? <BsCheck style={{color: "green"}} /> : <BsX style={{color: "red"}} />
        },
    ]
}