import React, {useEffect} from "react";
import SalesOpportunitySetting_1 from "./view/layout1";
import {useSalesOpportunitySettingFunction} from "./function";

const SalesOpportunitySetting = () => {
    let layout = 1;
    let component;

    const {getSalesOpportunityStatusFunction} = useSalesOpportunitySettingFunction()

    useEffect(() => {
        getSalesOpportunityStatusFunction()
    }, [])

    switch (layout) {
        case 1:
            component = <SalesOpportunitySetting_1/>
            break;

        default:
            component = <SalesOpportunitySetting_1/>
            break;
    }

    return component;
}

export default SalesOpportunitySetting