import React from "react";
import {useTranslation} from "react-i18next";
import {Breadcrumb, Card, Space, Tooltip} from "antd";
import {useAccountVerificationFunction} from "../../../../../../function/accountVerificationFunction";
import OptionalRibbonOnCard from "./optionalRibbonOnCard";
import OptionalTooltipCard from "./optionalTooltipCard";

const SelectDocumentGroup = ({
                                 requiredType,
                                 numberOfRequired,
                                 partiallyRequiredGroup,
                                 selectedCountry,
                                 documentGroups,
                                 selectedVerificationType,
                                 selectedDocumentGroup,
                                 handleSelectDocumentGroup,
                                 verificationRecord,
                             }) => {
    const {t} = useTranslation();

    const sortedDocumentGroups = [...documentGroups].sort((a, b) => a.sortingNumber - b.sortingNumber);
    const {RequiredTypeToMessage, getDocumentGroupStatus, statusToRibbonText, getRequiredDescription} = useAccountVerificationFunction()


    return (
        <div className="narrow-padding">
            <Space style={{width: "100%"}} size={16} direction="vertical">
                <div className="account-verification-title">
                    <Breadcrumb
                        items={[
                            {title: t(selectedCountry)},
                            {
                                title: `${t(selectedVerificationType?.label)} (${RequiredTypeToMessage(requiredType, numberOfRequired)})`,
                            },
                        ]}
                    />
                    {/* <RequiredFieldTooltip
            msg={RequiredTypeToMessage(requiredType, numberOfRequired)}
          /> */}
                </div>
                {sortedDocumentGroups.map((documentGroup, i, documentGroups) => {
                    const status = getDocumentGroupStatus(documentGroup, verificationRecord);
                    const className = status === "verification failed" ? "error" : "default";
                    return !documentGroup?.isEnabled || documentGroup?.isDeleted ? null : (
                        <OptionalRibbonOnCard
                            key={documentGroup._id}
                            showRibbon={getDocumentGroupStatus(documentGroup, verificationRecord)}
                            className={className}
                            text={statusToRibbonText(status)}
                        >
                            <OptionalTooltipCard
                                title={getRequiredDescription({
                                    requiredType,
                                    numberOfRequired: selectedVerificationType?.numberOfRequired,
                                })}
                            >
                                <Card
                                    className={`account-verification-card-selection clickable ${
                                        selectedDocumentGroup?._id === documentGroup?._id ? "account-verification-card-selected" : ""
                                    }`}
                                    onClick={() => {
                                        handleSelectDocumentGroup(documentGroup);
                                    }}
                                >
                                    <div className="account-verification-card-selection-title">
                                        {requiredType === "all" ||
                                        (requiredType === "partially" &&
                                            partiallyRequiredGroup?.some((groupId) => groupId === documentGroup?.group?._id)) ? (
                                            <Tooltip title={"This section is required"}>
                                                <span className="account-verification-required-mark">*</span>
                                            </Tooltip>
                                        ) : null}
                                        {t(documentGroup?.group?.label)}
                                    </div>
                                </Card>
                             </OptionalTooltipCard>
                         </OptionalRibbonOnCard>
                    );
                })}
            </Space>
        </div>
    );
}

export default SelectDocumentGroup
