import {Modal} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";
import TemplateForm from "./templateForm";

const TemplateFormModal = (props) => {
    const {selectedTemplate, modalFormType, isFormModalOpen, toggleFormModal} = props;
    const {t} = useTranslation();

    const closeModal = () => {
        toggleFormModal("");
    };

    return (
        <Modal
            className="template-editor-1"
            title={t(`${t(`common.${modalFormType}`)} Push Notification Template`)}
            open={isFormModalOpen}
            width={"80%"}
            footer={null}
            closable
            destroyOnClose
            onCancel={closeModal}
        >
            <TemplateForm
                mode={modalFormType}
                item={selectedTemplate}
                closeModal={closeModal}
            />
        </Modal>
    );
};

export default TemplateFormModal;
