import {Modal} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";
import CategoryForm from "./categoryForm";

const CategoryModal = (props) => {
    const {mode, item, isOpen, close, reportOptions} = props;
    const {t} = useTranslation();

    return (
        <Modal title={t(`${mode} Category`)} open={isOpen} onCancel={close} maskClosable={false} footer={null} width={500} destroyOnClose>
            <CategoryForm item={item} mode={mode} close={close} reportOptions={reportOptions} />
        </Modal>
    );
};

export default CategoryModal;
