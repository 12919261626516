import React, {useEffect, useState} from "react";
import {Button, Col, Descriptions, Row} from "antd";
import TableAntDesign from "../../../../../../../components/table/tableAntDesign";
import {useTranslation} from "react-i18next";
import {getMultiTierSetting} from "../../../../../../../api/graphql/multiTierSetting";
import {getReportingFields} from "../../../../../../../api/graphql/reporting";
import {DoubleLeftOutlined} from "@ant-design/icons";

const CommLogSnapshot = (props) => {

    const {data, task, setSnapshotPage} = props

    const [reportColumn, setReportColumn] = useState([])
    const [reportData, setReportData] = useState([])
    const [multiTierSettingList, setMultiTierSettingList] = useState<any>([]);
    const [reportFields, setReportFields] = useState<any>([]);
    const {t} = useTranslation()

    const getMultiTierSettingListing = async () => {
        const res = await getMultiTierSetting({filter: null});
        if (res?.getMultiTierSetting?.__typename === "MultiTierSettingArray") {
            setMultiTierSettingList(res?.getMultiTierSetting?.data);
        }
    };

    useEffect(() => {

        getMultiTierSettingListing()
        getReportFieldFunction()

        if (data?.reportSnapshot?.data?.length > 0) {
            const reports = data.reportSnapshot.data;

            const reportColumns: any = Object.keys(reports[0] || {}).map((key) => ({
                title: key,
                dataIndex: key,
                key: key,
                render: (text) => <p>{text}</p>,
            }));

            const resultReportData: any = []

            reports.map((report, index) => {

                    const result = Object.keys(report).reduce((rowData, key) => {
                        rowData[key] = report[key]?.value;

                        return rowData;
                    }, {})

                    if (index === data?.reportSnapshotIndex) {
                        resultReportData.push(result)
                    }
                }
            );

            setReportColumn(reportColumns);
            setReportData(resultReportData);
        }
    }, [data]);

    const comparisonOperator = [
        {
            label: "=",
            value: "E"
        },
        {
            label: "≥",
            value: "GTE"
        },
        {
            label: ">",
            value: "GT"
        },
        {
            label: "≤",
            value: "LTE"
        },
        {
            label: "<",
            value: "LT"
        },
        {
            label: "≠",
            value: "NE"
        },
        {
            label: "Include",
            value: "CONTAINS"
        },
    ]

    const getReportFieldFunction = async () => {
        const response = await getReportingFields();
        const fields: any = []

        for (let i = 0; i < response?.getReportingFields?.data?.length; i++) {
            const data = response?.getReportingFields?.data[i]
            fields.push({
                ...data
            })
        }
        setReportFields(fields)
    }

    return (
        <>
            <div className={"margin-bottom-0-75"} style={{marginTop: "1%"}}>

                {
                    task && (
                        <Row className={"margin-bottom-0-75 display-flex"} wrap>
                            <Button onClick={() => setSnapshotPage(false)} icon={<DoubleLeftOutlined />}>
                                Back
                            </Button>
                        </Row>
                    )
                }

                <Col span={24}>
                    <h1>
                        Report Snapshot
                    </h1>

                    <TableAntDesign data={reportData}
                                    tableName={"Report Table"}
                                    columns={reportColumn}
                                    size={"small"}
                                    server={null}
                                    isTableLocalSearch={true}
                                    pagination={{
                                        pageSize: 10,
                                        showSizeChanger: true,
                                        position: "bottomCenter",
                                        total: reportData?.length,
                                        showTotal: (total) =>
                                            <p>{t('pagination.totalItems', {totalCount: reportData?.length})}</p>,
                                    }}
                    />

                </Col>
                <Col span={15} className={"margin-bottom-0-75"}>
                    <Descriptions title="Commission Setting" size={"middle"} column={2}>
                        <Descriptions.Item
                            label="Report Name">{t(data?.commSettingSnapshot?.Label)}</Descriptions.Item>
                        <Descriptions.Item
                            label="Report Name">{data?.commSettingSnapshot?.ReportName}</Descriptions.Item>
                        <Descriptions.Item
                            label="Report Calculation Field">{data?.commSettingSnapshot?.ReportCalculationField}</Descriptions.Item>
                        <Descriptions.Item
                            label="Commission Tagging">{data?.commSettingSnapshot?.CommissionTagging || "User"}</Descriptions.Item>
                        {
                            data?.commSettingSnapshot?.ReportFilterFields?.length > 0 && data?.commSettingSnapshot?.ReportFilterFields?.map((data, key) => {


                                const operator = comparisonOperator.find((item) => item?.value === data?.Operator)

                                if (data?.FieldName !== null && data?.FieldName !== undefined) {
                                    return (
                                        <Descriptions.Item
                                            label={"Report Filter Field - " + (key + 1)}>
                                            {data?.FieldName + " " + operator?.label + " " + data?.Value}
                                        </Descriptions.Item>
                                    )
                                }
                            })
                        }
                    </Descriptions>
                </Col>
                <Col span={15} className={"margin-bottom-0-75"}>
                    <Descriptions title="Commission Rule" size={"middle"} column={2}>
                        <Descriptions.Item label="Rule Name">{data?.commRuleSnapshot?.Name}</Descriptions.Item>
                        <Descriptions.Item label="Rule Type">{data?.commRuleSnapshot?.RuleType}</Descriptions.Item>
                        <Descriptions.Item
                            label="Account Env Mode">{data?.commRuleSnapshot?.AccountEnvMode}</Descriptions.Item>
                        <Descriptions.Item
                            label="Account Product Type">{data?.commRuleSnapshot?.AccountProductType?.length > 0 ? data?.commRuleSnapshot?.AccountProductType?.join(", ") : "-"}</Descriptions.Item>
                        <Descriptions.Item
                            label="Account Type">{data?.commRuleSnapshot?.AccountType}</Descriptions.Item>
                        <Descriptions.Item
                            label="Product Type">{data?.commRuleSnapshot?.ProductType}</Descriptions.Item>
                        <Descriptions.Item label="Comm Mode">{data?.commRuleSnapshot?.CommMode}</Descriptions.Item>
                        <Descriptions.Item label="Comm Payout">{data?.commRuleSnapshot?.CommPayout}</Descriptions.Item>
                        <Descriptions.Item
                            label="Calculate Frequency">{data?.commRuleSnapshot?.CalcFrequency}</Descriptions.Item>
                        <Descriptions.Item label="Unpaid Comm">{data?.commRuleSnapshot?.UnpaidComm}</Descriptions.Item>
                        <Descriptions.Item
                            label="Calculation Network Type">{data?.commRuleSnapshot?.CalculationNetworkType}</Descriptions.Item>
                        <Descriptions.Item
                            label="Payout Network Type">{data?.commRuleSnapshot?.PayoutNetworkType}</Descriptions.Item>
                        <Descriptions.Item label="Comm Level">{data?.commRuleSnapshot?.CommLevel}</Descriptions.Item>
                        <Descriptions.Item label="Comm Type">{data?.commRuleSnapshot?.CommType}</Descriptions.Item>
                        <Descriptions.Item
                            label="Comm Rank Type">{data?.commRuleSnapshot?.CommRankType}</Descriptions.Item>
                        <Descriptions.Item
                            label="Bonus Coefficient">{data?.commRuleSnapshot?.BonusCoEfficientIncluded ? "Included" : "Not Included"}</Descriptions.Item>

                    </Descriptions>
                </Col>
                <Col span={15} className={"margin-bottom-0-75"}>
                    <Descriptions title="Commission Parameter" size={"middle"} column={2}>
                        {
                            data?.commRuleSnapshot?.CommType === "level" ? (
                                <>
                                    <Descriptions.Item
                                        label="Level">{data?.commParameterSettingSnapshot?.Level}</Descriptions.Item>
                                </>
                            ) : (
                                <>
                                    <Descriptions.Item
                                        label="Rank">
                                        {multiTierSettingList?.find((item) => item?._id === data?.commParameterSettingSnapshot?.Rank)?.name}
                                    </Descriptions.Item>
                                </>
                            )
                        }
                        <Descriptions.Item
                            label="Total Value">{data?.commParameterSettingSnapshot?.Total}</Descriptions.Item>
                        <Descriptions.Item
                            label="Criteria Name">{data?.commParameterSettingCriteriaSnapshot?.Name}</Descriptions.Item>
                        <Descriptions.Item
                            label="Criteria Position">{data?.commParameterSettingCriteriaSnapshot?.Position}</Descriptions.Item>

                        {
                            data?.commParameterSettingCriteriaSnapshot?.CriteriaGroup?.map((data, key) => {

                                return (
                                    <Descriptions.Item
                                        label={"Criteria Group - " + (key + 1) + (data?.LogicalOperator ? " (" + data?.LogicalOperator + ")" : "")}>
                                        {
                                            data?.Criteria?.map((criteria, index) => {

                                                const fieldName = reportFields?.find((field) => field?.key === criteria?.Type)

                                                return (
                                                    <>
                                                        {(index > 0 ? "  " + criteria?.LogicalOperator + "  " : "") + fieldName?.uiName + "  " + criteria?.ComparisonOperator + "  " + criteria?.Value}
                                                    </>
                                                )

                                            })
                                        }
                                    </Descriptions.Item>
                                )
                            })
                        }

                    </Descriptions>
                </Col>
            </div>

        </>
    )
}

export default CommLogSnapshot