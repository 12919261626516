import React from "react";
import {useTranslation} from "react-i18next";
import GeneralListCountry from "../view/layout1/component/generalList/country";
import GeneralListField from "../view/layout1/component/generalList/field";
import GeneralListFieldGroup from "../view/layout1/component/generalList/fieldGroup";
import GeneralListDocument from "../view/layout1/component/generalList/document";
import GeneralListDocumentGroup from "../view/layout1/component/generalList/documentGroup";
import FlowBuilder from "../view/layout1/component/flowBuilder";
import {useRecoilValue} from "recoil";
import {selfPermissionObj} from "../../../../../recoil_state";

const TabItems = () => {
    const {t} = useTranslation()
    const permission = useRecoilValue<any>(selfPermissionObj)

    const props = {
        permission: permission?.userSetting?.accountVerification
    }

    const item = [
        {
            label: t("accountVerification.field"),
            key: "field",
            children: <GeneralListField {...props}/>
        },
        {
            label: t("accountVerification.fieldGroup"),
            key: "fieldGroup",
            children: <GeneralListFieldGroup {...props}/>
        },
        {
            label: t("accountVerification.document"),
            key: "document",
            children: <GeneralListDocument {...props}/>
        },
        {
            label: t("accountVerification.documentGroup"),
            key: "documentGroup",
            children: <GeneralListDocumentGroup {...props}/>
        },
        {
            label: t("accountVerification.country"),
            key: "country",
            children: <GeneralListCountry {...props}/>
        },
        {
            label: t("accountVerification.flowBuilder"),
            key: "flowBuilder",
            children: <FlowBuilder {...props}/>
        },
    ]

    return item
}

export default TabItems