import React, {useEffect, useMemo} from "react";
import {Card, Tabs, TabsProps} from "antd";
import {useTranslation} from "react-i18next";
import EwalletTxsRecordTabItem from "./ewalletTxsRecordTabItem";
import ActivitiesTabItem from "./activitiesTabItem";
import RewardTabItem from "./rewardTabItem";

const EwalletTxsActivitiesRewardCard = (props) => {
    const {data, permissionUser, transactions, summary, transactionLoading, fetchEWalletTransaction} = props;
    const {t} = useTranslation();

    const items: TabsProps["items"] = useMemo(() => {
        let tabItems: any = [];
        if (permissionUser?.clientsUser?.view?.ewallet) {
            tabItems.push({
                label: t("ewallet.depWithRecord"),
                key: "depositWithdrawal",
                children: <EwalletTxsRecordTabItem data={data} transactions={transactions} summary={summary} transactionLoading={transactionLoading} fetchEWalletTransaction={fetchEWalletTransaction}/>,
            });
        }
        if (permissionUser?.clientsUser?.view?.activities) {
            tabItems.push({
                label: t("module.activities"),
                key: "activities",
                children: <ActivitiesTabItem data={data} permissionUser={permissionUser}/>,
            });
        }
        if (permissionUser?.clientsUser?.view?.reward) {
            tabItems.push({
                label: t("module.reward"),
                key: "reward",
                children: <RewardTabItem data={data} permissionUser={permissionUser}/>,
            });
        }
        return tabItems;
    }, [permissionUser, data, transactions, summary, transactionLoading]);

    return (
        <Card style={{width: "100%"}}>
            <Tabs size={"small"} items={items}/>
        </Card>
    );
};

export default EwalletTxsActivitiesRewardCard;
