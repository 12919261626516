import React, {Fragment, useEffect, useState} from "react";
import {Button, Col, Dropdown, DropdownProps, Form, Image, MenuProps, Modal, Row, Select, Space, Tabs, Upload} from "antd";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {arenaMediaFileState, arenaMediaFolderState} from "../../../users/_common/state";
import {ArrowRightOutlined, CloseOutlined, FolderFilled, InboxOutlined} from "@ant-design/icons";
import "./style.css"
import {getConfigByName} from "../../../../../config";
import {BRAND_NAME} from "../../../../../config/brandVariable";
import _ from "lodash";
import {uploadMedia} from "../../../../api/restful/arena/media_file";
import {notificationMessage} from "../../../../../recoil_state";
import {useSetDropdown} from "../../../../_common/dropdowns";

interface MediaSelectProps {
    title: string;
    passMedia: (media: any) => void;
    selectedFile?: any;
    placement?: DropdownProps['placement'];
}

const MediaSelect:React.FC<MediaSelectProps> = ({title, passMedia, selectedFile, placement = "bottom"}) => {
    const url = getConfigByName(BRAND_NAME).arenaURL
    const media = useRecoilValue<any>(arenaMediaFileState)
    const folder = useRecoilValue<any>(arenaMediaFolderState)
    const [open, setOpen] = useState(false);
    const [currentPath, setCurrentPath] = useState<any[]>(["All Files"]);
    const [viewingFolders, setViewingFolders] = useState<any>([])
    const [viewingMedia, setViewingMedia] = useState<any>([])
    const [selected, setSelected] = useState<any>(null)
    const [previewOpen, setPreviewOpen] = useState(false)
    const [form] = Form.useForm()
    const setNotificationMessage = useSetRecoilState(notificationMessage)
    const {getArenaMedia} = useSetDropdown()

    const handleSelect = (filePath) => {
        passMedia(url + filePath)
        setOpen(false)
    }

    const useManyClickHandlers = (...handlers: Array<(e: React.UIEvent<HTMLElement>, type, item?: any) => void>) => {
        const callEventHandler = (e: React.UIEvent<HTMLElement>, type, item?: any) => {
            if (e.detail <= 0) return
            const handler = handlers[e.detail - 1]
            if (handler) {
                handler(e, type, item)
            }
        }

        const debounceHandler = _.debounce(function (e: React.UIEvent<HTMLElement>, type, item?: any) {
            callEventHandler(e, type, item)
        }, 200)

        return (e: React.UIEvent<HTMLElement>, type, item?: any) => {
            e.persist()
            debounceHandler(e, type, item)
        }
    }

    const singleClickHandler = (_, type, item?: any) => {
        // onCheckHandler(item?._id, !selected.includes(item?._id))
        if (type === "file") setSelected(item)
    }
    const doubleClickHandler = (_, type, item?: any) => {
        if (type === "folder" && item) {
            if (item.parent === currentPath[currentPath.length - 1]?.parent) {
                setCurrentPath(prev => [...prev.slice(0, -1), item])
            } else {
                setCurrentPath(prev => [...prev, item])
            }
        }
    }

    const clickHandler = useManyClickHandlers(singleClickHandler, doubleClickHandler)

    const processCurrentViewingFiles = () => {
        const current = currentPath[currentPath.length - 1]
        if (current === "All Files") {
            setViewingFolders(folder?.data?.filter(d => !d.parent))
            setViewingMedia(media?.data?.filter(d => !d.folder))
        } else {
            setViewingFolders(folder?.data?.filter(d => d.parent === current._id))
            setViewingMedia(media?.data?.filter(d => d.folder === current._id))
        }
    }

    const onPathClick = (item) => {
        if (item === "All Files") setCurrentPath(["All Files"])
        else setCurrentPath(prev => {
            const index = prev.findIndex(d => d._id === item._id)
            return prev.slice(0, index + 1)
        })
    }

    useEffect(() => {
        if (open) setCurrentPath(["All Files"])
    }, [open]);

    useEffect(() => {
        if (media && folder) processCurrentViewingFiles()
    }, [currentPath, media, folder]);

    const handleUpload = async (value) => {
        const {folder, file} = value
        const formData = new FormData()

        formData.append("folder", folder || "")

        if (file && file?.fileList?.length > 0) {
            formData.append("file", file.fileList[0].originFileObj);
        }

        try {
            const response = await uploadMedia(formData)
            if (response.status === "Success") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Upload Media Successfully",
                    key: "arenaMediaLibraryNotification",
                });

                await getArenaMedia()
                handleSelect(response?.data?.[0]?.filePath)
            } else {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: `Upload Media Failed: ${response?.errObj}`,
                    key: "arenaMediaLibraryNotification",
                });
            }
        } catch (e) {
        }
    }

    const items = [
        {
            key: "mediaLibrary",
            label: "Media Library",
            children: (
                <div className={"tab-panel"}>
                    <div className={"media-library-path"}>
                        {
                            currentPath?.map(d => typeof d === "string" ?
                                <a key={"all"} onClick={() => onPathClick("All Files")}>{d}</a> :
                                <Fragment key={d?.name}>
                                    {">"}
                                    <a onClick={() => onPathClick(d)}>{d?.name}</a>
                                </Fragment>
                            )
                        }
                    </div>
                    <Row gutter={[16, 16]}>
                        {
                            viewingFolders?.length > 0 && viewingFolders?.map(item => (
                                <Col onClick={(e) => clickHandler(e, "folder", item)} className={"media-item-wrapper"} span={6} key={item?._id}>
                                    <div className={"media-item"}>
                                        <div className={"media-item-container"}>
                                            <FolderFilled className={"default-icon folder-icon"}/>
                                        </div>
                                    </div>
                                    <Space align={"center"} className={"file-name"}>
                                        <FolderFilled className={"type-icon folder-icon"}/>
                                        <div>
                                            <p onClick={() => doubleClickHandler(null, "folder", item)} className={"path"}>{item?.name}</p>
                                            <p><small>{item?.itemsCount || 0} items</small></p>
                                        </div>
                                    </Space>
                                </Col>
                            ))
                        }
                        {
                            viewingMedia?.length > 0 && viewingMedia?.map(item => (
                                <Col onClick={(e) => clickHandler(e, "file", item)}
                                     className={`media-item-wrapper ${selected?._id === item?._id ? "selected" : ""}`} span={6} key={item?._id}>
                                    <div className={"media-item"}>
                                        <div className={"media-item-container"}>
                                            <Image
                                                preview={false}
                                                src={url + item?.filePath}
                                                className={"display-image"}
                                                loading={"lazy"}
                                            />
                                        </div>
                                    </div>
                                    <Space align={"center"} className={"file-name"}>
                                        <div>
                                            <p className={"path"}>{item?.displayName.replace(item?.fileType, "")}</p>
                                            <p><small>{item?.fileType.replace(".", "").toUpperCase()}</small></p>
                                        </div>
                                    </Space>
                                </Col>
                            ))
                        }
                    </Row>

                    {
                        selected &&
                        <div className={"selected-container"}>
                            <Row justify={"space-between"} align={"middle"}>
                                <Space>
                                    <Button type={"link"} icon={<CloseOutlined/>}/>
                                    <p className={"margin-bottom-0"}>Selected: {selected?.displayName}</p>
                                </Space>

                                <Button type={"link"} onClick={() => handleSelect(selected?.filePath)}>Continue <ArrowRightOutlined/> </Button>
                            </Row>
                        </div>
                    }
                </div>
            )
        },
        {
            key: "upload",
            label: "Upload",
            children: (
                <div className={"tab-panel upload"}>
                    <Form onFinish={handleUpload} form={form} labelCol={{span: 24}} className={"margin-top-0-75"}>
                        <Form.Item label={"Folder"} name={"folder"}>
                            <Select
                                allowClear
                                options={folder?.data?.map(d => ({label: d?.name, value: d?._id}))}
                            />
                        </Form.Item>
                        <Form.Item label={"File"} name={"file"}>
                            <Upload.Dragger
                                beforeUpload={() => false}
                                listType="picture-card"
                                maxCount={1}
                                showUploadList={{showPreviewIcon: false, showRemoveIcon: true}}
                                accept="image/png, image/jpeg, image/gif, image/webp, image/svg+xml"
                            >
                                <p className="ant-upload-drag-icon" style={{marginBottom: 4}}>
                                    <InboxOutlined/>
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Form>
                    <Row justify={"end"} className={"action-row"}>
                        <Button type={"primary"} onClick={() => form.submit()}>Upload</Button>
                    </Row>
                </div>
            )
        }
    ]

    const menuItems: MenuProps["items"] = [
        {
            key: "preview",
            label: "Preview",
            onClick: () => setPreviewOpen(true)
        },
        {
            key: "change",
            label: "Change",
            onClick: () => setOpen(true)
        },
        {
            key: "remove",
            label: "Remove",
            danger: true,
            onClick: () => {
                passMedia(null)
                setSelected(null)
            }
        },
    ]

    return (
        <div className={"media-select-wrapper"}>
            {
                selectedFile ? (
                    <Dropdown menu={{items: menuItems}} arrow placement={placement}>
                        <Image
                            className={"preview-image clickable"}
                            src={selectedFile}
                            style={{maxWidth: "80%"}}
                            preview={false}
                        />
                    </Dropdown>
                ) : (
                    <Button onClick={() => setOpen(true)}>Select</Button>
                )
            }
            <Modal
                width={"70%"}
                open={open}
                onCancel={() => setOpen(false)}
                footer={null}
                title={title}
                getContainer={"#root"}
                className={"media-select-modal"}
                destroyOnClose
                centered
            >
                <Tabs items={items}/>
            </Modal>
            <Modal
                open={previewOpen}
                onCancel={() => setPreviewOpen(false)}
                destroyOnClose
                footer={null}
                closeIcon={false}
            >
                <Image
                    src={selectedFile}
                    width={"100%"}
                    preview={false}
                />
            </Modal>
        </div>
    )
}

export default MediaSelect