import React from "react";
import {Col, ColorPicker, InputNumber, Radio, Row, Select, Slider, Space, Tooltip} from "antd";
import {
    TbAlignCenter,
    TbAlignJustified,
    TbAlignLeft,
    TbAlignRight,
    TbHighlight,
    TbIndentDecrease,
    TbIndentIncrease,
    TbItalic,
    TbLink,
    TbList,
    TbListNumbers,
    TbPaint,
    TbTextColor,
    TbUnderline
} from "react-icons/tb";
import {useContextMenuFunctions} from "../../../function/contextMenuFunctions";

const TextMenuItems = ({detailData, setDetailData, setting, getContainer}) => {
    const {handleFormInputValueChange} = useContextMenuFunctions(setDetailData)

    const EmptyIcon = () => {
        return (
            <svg width="16" height="16" viewBox="0 0 20 20">
                <rect width="20" height="20" fill="#FFF" rx="6"></rect>
                <path fill="#ee5951" fillRule={"evenodd"} d="M3.57 17.99L18.42 3.15 17.26 2 2.42 16.84l1.15 1.15z"></path>
                <path fill="#d8e1e8" fillRule={"evenodd"}
                      d="M14 19c2.76 0 5-2.24 5-5V6c0-2.76-2.24-5-5-5H6C3.24 1 1 3.24 1 6v8c0 2.76 2.24 5 5 5h8m0 1H6c-3.31 0-6-2.69-6-6V6c0-3.31 2.69-6 6-6h8c3.31 0 6 2.69 6 6v8c0 3.31-2.69 6-6 6z"></path>
            </svg>
        )
    }

    const fontWeightPresets = [
        {label: "Light", value: 300},
        {label: "Regular", value: 400},
        {label: "Medium", value: 500},
        {label: "Bold", value: 700},
    ]

    const TooltipWithGetContainer = ({children, title}) => {

        return (
            <Tooltip title={title} getPopupContainer={getContainer}>
                {children}
            </Tooltip>
        )
    }

    return (
        <>
            {/*<div className={"sections"}>*/}
            {/*    <p>Style</p>*/}
            {/*    <Select getPopupContainer={getContainer} options={[]} className={"w-100"}/>*/}
            {/*</div>*/}
            <div className={"sections"}>
                <p>Font Size (px)</p>
                <Row gutter={8}>
                    <Col span={14}>
                        <Slider
                            min={8}
                            max={176}
                            value={setting?.fontSize}
                            onChange={value => handleFormInputValueChange(value, "fontSize")}
                        />
                    </Col>
                    <Col span={10}>
                        <InputNumber
                            min={8}
                            max={176}
                            value={setting?.fontSize}
                            onChange={value => handleFormInputValueChange(value, "fontSize")}
                        />
                    </Col>
                </Row>

                <p className={"margin-top-0-75"}>Font Weight</p>
                <Select getPopupContainer={getContainer} options={fontWeightPresets} className={"w-100"} value={setting?.fontWeight}
                        onChange={(value) => handleFormInputValueChange(value, "fontWeight")}/>
            </div>
            <div className={"sections action-button-wrapper"}>
                <Row gutter={8}>
                    <Col span={4}>
                        <TooltipWithGetContainer title={"Italic"}>
                            <div
                                onClick={() => handleFormInputValueChange(!setting?.italic, "italic")}
                                className={setting?.italic ? "active" : ""}
                            >
                                <TbItalic/>
                            </div>
                        </TooltipWithGetContainer>
                    </Col>
                    <Col span={4}>
                        <TooltipWithGetContainer title={"Underline"}>
                            <div
                                className={setting?.underline ? "active" : ""}
                                onClick={() => handleFormInputValueChange(!setting?.underline, "underline")}
                            >
                                <TbUnderline/>
                            </div>
                        </TooltipWithGetContainer>
                    </Col>
                    <Col span={4}>
                        <TooltipWithGetContainer title={"Color"}>
                            <div style={{display: "block"}}>
                                <ColorPicker
                                    getPopupContainer={getContainer}
                                    value={setting?.color}
                                    format={"rgb"}
                                    allowClear
                                    onChangeComplete={(value) => handleFormInputValueChange(value.toHexString(), "color")}
                                    onClear={() => handleFormInputValueChange("inherit", "color")}
                                >
                                    <div><TbTextColor/></div>
                                </ColorPicker>
                            </div>
                        </TooltipWithGetContainer>
                    </Col>
                    <Col span={4}>
                        <TooltipWithGetContainer title={"Highlight"}>
                            <div style={{display: "block"}}>
                                <ColorPicker
                                    getPopupContainer={getContainer}
                                    value={setting?.highlight}
                                    format={"rgb"}
                                    allowClear
                                    onChangeComplete={(value) => handleFormInputValueChange(value.toHexString(), "highlight")}
                                    onClear={() => handleFormInputValueChange("inherit", "highlight")}
                                >
                                    <div><TbHighlight/></div>
                                </ColorPicker>
                            </div>
                        </TooltipWithGetContainer>
                    </Col>
                    <Col span={4}>
                        <TooltipWithGetContainer title={"Background"}>
                            <div style={{display: "block"}}>
                                <ColorPicker
                                    getPopupContainer={getContainer}
                                    value={setting?.backgroundColor}
                                    format={"rgb"}
                                    allowClear
                                    onChangeComplete={(value) => handleFormInputValueChange(value.toHexString(), "backgroundColor")}
                                    onClear={() => handleFormInputValueChange("inherit", "backgroundColor")}
                                >
                                    <div><TbPaint/></div>
                                </ColorPicker>
                            </div>
                        </TooltipWithGetContainer>
                    </Col>
                    <Col span={4}>
                        <TooltipWithGetContainer title={"Link"}>
                            <div><TbLink/></div>
                        </TooltipWithGetContainer>
                    </Col>
                </Row>
            </div>
            <div className={"sections action-button-wrapper"}>
                <Row gutter={8}>
                    <Col span={6}>
                        <TooltipWithGetContainer title={"Alignment"}>
                            <Select getPopupContainer={getContainer}
                                    className={"no-border"} value={setting?.textAlign}
                                    onChange={(value) => handleFormInputValueChange(value, "textAlign")}
                                    options={[
                                        {label: <TbAlignCenter/>, value: "center"},
                                        {label: <TbAlignJustified/>, value: "justify"},
                                        {label: <TbAlignLeft/>, value: "left"},
                                        {label: <TbAlignRight/>, value: "right"},
                                    ]}/>
                        </TooltipWithGetContainer>
                    </Col>
                    <Col span={6}>
                        <TooltipWithGetContainer title={"Bullets & Numbering"}>
                            <Select getPopupContainer={getContainer}
                                    className={"no-border"} value={setting?.list}
                                    onChange={(value) => handleFormInputValueChange(value, "list")}
                                    options={[
                                        {label: <EmptyIcon/>, value: ""},
                                        {label: <TbList/>, value: "li"},
                                        {label: <TbListNumbers/>, value: "ul"},
                                    ]}/>
                        </TooltipWithGetContainer>
                    </Col>
                    <Col span={6}>
                        <TooltipWithGetContainer title={"Decrease Indent"}>
                            <div onClick={() => handleFormInputValueChange(setting?.textIndent - 1, "textIndent")}><TbIndentDecrease/></div>
                        </TooltipWithGetContainer>
                    </Col>
                    <Col span={6}>
                        <TooltipWithGetContainer title={"Increase Indent"}>
                            <div onClick={() => handleFormInputValueChange(setting?.textIndent + 1, "textIndent")}><TbIndentIncrease/></div>
                        </TooltipWithGetContainer>
                    </Col>
                    {/*<Col span={4}>*/}
                    {/*    <TooltipWithGetContainer title={"Text Direction"}>*/}
                    {/*        <div><TbTextOrientation/></div>*/}
                    {/*    </Tooltip>*/}
                    {/*</Col>*/}
                </Row>
            </div>

            <div className={"sections"}>
                <p className={"secondary-text"}>Character Spacing</p>
                <Row gutter={8}>
                    <Col span={14}>
                        <Slider
                            min={-0.1}
                            max={1}
                            step={0.05}
                            value={setting?.letterSpacing}
                            onChange={value => handleFormInputValueChange(value, "letterSpacing")}
                        />
                    </Col>
                    <Col span={10}>
                        <InputNumber
                            min={-0.1}
                            max={1}
                            step={0.05}
                            value={setting?.letterSpacing}
                            onChange={value => handleFormInputValueChange(value, "letterSpacing")}
                        />
                    </Col>
                </Row>

                <p className={"margin-top-0-75 secondary-text"}>Line Spacing</p>
                <Radio.Group
                    className={"margin-top-0-3"}
                    value={setting?.lineHeight === "normal" ? "auto" : "custom"}
                    onChange={(value) => handleFormInputValueChange(value.target.value === "auto" ? "normal" : 1, "lineHeight")}
                >
                    <Space direction="vertical">
                        <Radio value={"auto"}>Automatic</Radio>
                        <Radio value={"custom"}>Custom</Radio>
                    </Space>
                </Radio.Group>
                {
                    setting?.lineHeight !== "normal" &&
                    <Row gutter={8}>
                        <Col span={14}>
                            <Slider
                                min={.5}
                                max={3}
                                step={.5}
                                value={setting?.lineHeight}
                                onChange={value => handleFormInputValueChange(value, "lineHeight")}
                            />
                        </Col>
                        <Col span={10}>
                            <InputNumber
                                min={.5}
                                max={3}
                                step={.1}
                                value={setting?.lineHeight}
                                onChange={value => handleFormInputValueChange(value, "lineHeight")}
                            />
                        </Col>
                    </Row>
                }

                {/*<Collapse ghost accordion>*/}
                {/*    <CollapsePanel key={"character"} header={"Character & Line Spacing"}>*/}
                {/*        <p className={"secondary-text"}>Character Spacing</p>*/}
                {/*        <Row gutter={8}>*/}
                {/*            <Col span={14}>*/}
                {/*                <Slider*/}
                {/*                    min={8}*/}
                {/*                    max={176}*/}
                {/*                    value={inputValue?.fontSize}*/}
                {/*                    onChange={value => valueOnChange(value, "fontSize")}*/}
                {/*                />*/}
                {/*            </Col>*/}
                {/*            <Col span={10}>*/}
                {/*                <InputNumber*/}
                {/*                    min={8}*/}
                {/*                    max={176}*/}
                {/*                    value={inputValue?.fontSize}*/}
                {/*                    onChange={value => valueOnChange(value, "fontSize")}*/}
                {/*                />*/}
                {/*            </Col>*/}
                {/*        </Row>*/}

                {/*        <p className={"margin-top-0-75 secondary-text"}>Line Spacing</p>*/}
                {/*        <Radio.Group className={"margin-top-0-3"}>*/}
                {/*            <Space direction="vertical">*/}
                {/*                <Radio value={"auto"}>Automatic</Radio>*/}
                {/*                <Radio value={"custom"}>Custom</Radio>*/}
                {/*            </Space>*/}
                {/*        </Radio.Group>*/}
                {/*        <Row gutter={8}>*/}
                {/*            <Col span={14}>*/}
                {/*                <Slider*/}
                {/*                    min={8}*/}
                {/*                    max={176}*/}
                {/*                    value={inputValue?.fontSize}*/}
                {/*                    onChange={value => valueOnChange(value, "fontSize")}*/}
                {/*                />*/}
                {/*            </Col>*/}
                {/*            <Col span={10}>*/}
                {/*                <InputNumber*/}
                {/*                    min={8}*/}
                {/*                    max={176}*/}
                {/*                    value={inputValue?.fontSize}*/}
                {/*                    onChange={value => valueOnChange(value, "fontSize")}*/}
                {/*                />*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*    </CollapsePanel>*/}
                {/*</Collapse>*/}
            </div>
        </>
    )
}

export default TextMenuItems