import React, {useEffect, useState} from "react";
import {Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select, Space, Tooltip} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {getReportingFields} from "../../../../../../../../api/graphql/reporting";
import {formatDateTimeTimezone} from "../../../../../../../../function/_common";
import dayjs from "dayjs";
import moment from "moment";

const TriggerForm = (props) => {
    const {form, trigger, handleTriggerOnChange, item, type, setType, isUpdate} = props
    const {t} = useTranslation();
    const labelCol = {span: 6}
    const wrapperCol = {span: 18}
    const container: any = document.getElementById("automation-setting")

    const [isRepetitionClearVisible, setRepetitionClearVisible] = useState<boolean>(false)
    const [reportFields, setReportFields] = useState<any>([])
    const [startDate, setStartDate] = useState<any>(null)
    const [endDate, setEndDate] = useState<any>(null)

    const triggerOptions = trigger?.map(d => ({label: d.trigger, value: d._id}))

    const getReportFields = async () => {
        try {
            if (!reportFields || reportFields.length === 0) {
                const response = await getReportingFields();
                if (response?.getReportingFields?.__typename === "ReportingDataAry") {
                    let filteredReportingFields = response?.getReportingFields?.data?.filter(item => item?.isUsedForAutomation)?.map(d => ({
                        label: d.uiName || d.reportFieldName,
                        value: d.reportFieldName
                    }))
                    setReportFields(filteredReportingFields);
                    //
                    // let reportIdSet = new Set()
                    // filteredReportingFields.forEach(item => {
                    //     reportIdSet.add(item?.reportId)
                    // })
                    //
                    // const reportResponse = await getReportingAll()
                    // if (response?.getReportingFields?.__typename === "ReportingDataAry") {
                    //     let filteredReport = reportResponse?.getReportingAll?.data?.filter(item => reportIdSet.has(item?.reportId))
                    //     setCurrentReport(filteredReport)
                    // }
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getReportFields()
    }, []);

    useEffect(() => {
        setType(form.getFieldValue("type"));
    }, [form.getFieldValue("type")]);

    useEffect(() => {
        if (!item) {
            setStartDate(null)
            setEndDate(null)
            return
        }

        if (item?.setting?.eventStartDate) setStartDate(formatDateTimeTimezone(item?.setting?.eventStartDate, "formValue"));
        if (item?.setting?.eventEndDate) setEndDate(formatDateTimeTimezone(item?.setting?.eventEndDate, "formValue"));
    }, [item])

    const disabledDate = (current) => {
        return isUpdate ? current && current < moment().startOf('day') : null
    };

    return (
        <div className={"trigger-form"}>
            <p>
                Set up automation of typical procedures based on scenarios. Please start with setting common properties: trigger event, time limits and
                periodicity.
            </p>

            <Form form={form} labelCol={labelCol} wrapperCol={wrapperCol} labelAlign={"left"}>
                <Form.Item label={"Type"} name={"type"}>
                    <Radio.Group
                        options={[
                            {label: "Schedule", value: "schedule_report"},
                            {label: "Trigger", value: "trigger"}
                        ]}
                        onChange={(e) => setType(e.target.value)}
                    />
                </Form.Item>
                {
                    type &&
                    <>
                        <Form.Item label={" "} name={"isEnabled"} valuePropName={"checked"} colon={false}>
                            <Checkbox>Enable</Checkbox>
                        </Form.Item>
                        <Form.Item label={"Name"} name={"triggerName"}
                                   rules={[{required: true, message: t("errorMsg.required.pleaseFillIn", {field: t("Name")}).toString()}]}
                        >
                            <Input/>
                        </Form.Item>
                        {
                            type === "trigger" &&
                            <Form.Item label={"Trigger"} name={"customTrigger"}
                                       rules={[{required: true, message: t("errorMsg.required.pleaseFillIn", {field: t("Trigger")}).toString()}]}
                            >
                                <Select options={triggerOptions} onSelect={handleTriggerOnChange} disabled={isUpdate}/>
                            </Form.Item>
                            // <Form.Item label={"Report Field"} name={"reportTargetedField"}
                            //            rules={[{required: true, message: t("errorMsg.required.pleaseFillIn", {field: t("Report Targeted Field")}).toString()}]}
                            // >
                            //     <Select options={reportFields}/>
                            // </Form.Item>
                        }
                        <br/>

                        <Form.Item label={"Started"}>
                            <Row align={"top"} gutter={[8, 8]}>
                                <Col span={12}>
                                    <Form.Item name={"eventStartDate"}
                                               rules={[
                                                   {
                                                       validator: (_, value) => {
                                                           if (!value || !isUpdate || formatDateTimeTimezone(item?.setting?.eventStartDate, "formValue").format("YYYY-MM-DD HH:mm") === value.format("YYYY-MM-DD HH:mm")) {
                                                               return Promise.resolve()
                                                           } else {
                                                               const timezoneOffset = parseInt(localStorage.getItem("timezone") || "0");
                                                               const currentTime = timezoneOffset === 0 ? dayjs().utc() : dayjs().utcOffset(-timezoneOffset);
                                                               const timePlusTenMinutes = currentTime.add(10, 'minutes');

                                                               const inputTime = timezoneOffset === 0 ? dayjs(value.format("YYYY-MM-DD HH:mm")).utc(true) : dayjs(value.format("YYYY-MM-DD HH:mm")).utcOffset(-timezoneOffset, true).utc()

                                                               if (inputTime.isBefore(timePlusTenMinutes)) {
                                                                   return Promise.reject(new Error('Please select a date and time at least 10 minutes in the future.'))
                                                               }
                                                           }

                                                           return Promise.resolve()
                                                       },
                                                   },
                                               ]}
                                    >
                                        <DatePicker showNow={false} disabledDate={disabledDate} showTime={{format: 'HH:mm'}}
                                                    onChange={value => setStartDate(value)}/>
                                    </Form.Item>
                                </Col>
                                {startDate &&
                                    <p className={"margin-bottom-0 margin-top-0-5 secondary-text"}>
                                        UTC Time:&nbsp;{formatDateTimeTimezone(startDate, "toGMTDisplay")}
                                    </p>
                                }
                            </Row>
                        </Form.Item>

                        <Form.Item label={"Expired"}>
                            <Row align={"top"} gutter={[8, 8]}>
                                <Col span={12}>
                                    <Form.Item name={"eventEndDate"}>
                                        <DatePicker showNow={false} showTime={{format: 'HH:mm'}} onChange={value => setEndDate(value)}/>
                                    </Form.Item>
                                </Col>
                                {endDate &&
                                    <p className={"margin-bottom-0 margin-top-0-5 secondary-text"}>
                                        UTC Time:&nbsp;{formatDateTimeTimezone(endDate, "toGMTDisplay")}
                                    </p>
                                }
                            </Row>
                        </Form.Item>

                        <br/>

                        <Form.Item
                            label={
                                <Space>
                                    Repetitions
                                    <Tooltip getPopupContainer={() => container} title={
                                        <li>
                                            <ol className={"tooltip-note"}>Single Execution: If the repetition field is set to 1, the trigger will execute the
                                                associated action only once.
                                            </ol>
                                            <ol className={"tooltip-note"}>Continuous Execution: If the repetition field is left empty or set to 0, the trigger
                                                will
                                                execute the associated action
                                                continuously.
                                            </ol>
                                        </li>
                                    }><InfoCircleOutlined/></Tooltip>
                                </Space>
                            }
                        >
                            <Space>
                                <Form.Item name={"repetition"}>
                                    <InputNumber min={1} onChange={(value) => setRepetitionClearVisible(value !== null)}/>
                                </Form.Item>
                                {
                                    isRepetitionClearVisible &&
                                    <Button type={"link"} style={{marginBottom: 4}} onClick={() => {
                                        form.setFieldValue("repetition", null)
                                        setRepetitionClearVisible(false)
                                    }}>{t("common.clear")}</Button>
                                }
                            </Space>
                        </Form.Item>

                        <Form.Item label={"Period"}>

                            <Space size={"large"}>
                                <div className={"display-flex"}>
                                    <Form.Item name={"periodDay"}>
                                        <InputNumber min={0} className={"short-input-number"}/>
                                    </Form.Item>
                                    &nbsp; days
                                </div>
                                <div className={"display-flex"}>
                                    <Form.Item name={"periodHour"}>
                                        <InputNumber min={0} max={23} className={"short-input-number"}/>
                                    </Form.Item>
                                    &nbsp; hours
                                </div>
                                <div className={"display-flex"}>
                                    <Form.Item name={"periodMin"}>
                                        <InputNumber min={0} max={59} className={"short-input-number"}/>
                                    </Form.Item>
                                    &nbsp; minutes
                                </div>
                            </Space>
                        </Form.Item>

                        <p className={"note-title"}>Note: </p>
                        <p className={"notes"}>Please ensure that the repetition setting is configured correctly according to your requirements. Continuous
                            execution can result in ongoing operation until manually stopped or interrupted, which may impact system performance. Use with
                            caution.</p>
                    </>
                }
            </Form>
        </div>
    )
}

export default TriggerForm