export const logicalOperator = [
    {
        label: "AND",
        value: "and"
    },
    {
        label: "OR",
        value: "or"
    },
]

export const comparisonOperator = [
    {
        label: "=",
        value: "="
    },
    {
        label: "≥",
        value: ">="
    },
    {
        label: ">",
        value: ">"
    },
    {
        label: "≤",
        value: "<="
    },
    {
        label: "<",
        value: "<"
    },
    {
        label: "≠",
        value: "<>"
    },
]