import { Form, Radio } from "antd";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import FormErrors from "./formErrors";

const RadioButtonGroupFormItem = (props) => {
  const {
    layout,
    label,
    labelCol,
    wrapperCol,
    name,
    externalErrorMessage,
    options,
    defaultValue,
    onChange,
    hint,
    disabled,
    required,
    value,
    buttonStyle,
    labelAlign,
    colon,
  } = props;

  const {
    register,
    watch,
    setValue,
    formState: { touchedFields, isSubmitted, errors },
  } = useFormContext();

  const errorMessage = FormErrors.errorMessage(
    name,
    errors,
    touchedFields,
    isSubmitted,
    externalErrorMessage
  );

  const formHelperText = errorMessage || hint;

  useEffect(() => {
    register(name);
  }, [register, name]);

  useEffect(() => {
    if (value) {
      setValue(name, value, { shouldValidate: true });
    }
  }, [value]);

  return (
    <Form.Item
      {...layout}
      label={label}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      labelAlign={labelAlign}
      colon={colon}
      required={required}
      validateStatus={errorMessage ? "error" : "success"}
      help={formHelperText}
    >
      <Radio.Group
        name={name}
        defaultValue={defaultValue}
        value={watch(name)}
        disabled={disabled}
        onChange={(e) => {
          setValue(name, e?.target?.value, { shouldValidate: true });
          onChange && onChange(e?.target?.value);
        }}
        buttonStyle={buttonStyle}
      >
        {options.map((option, idx) => (
          <Radio.Button key={idx} id={idx} value={option.value}>
            {option.label}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

RadioButtonGroupFormItem.defaultProps = {
  required: false,
  disabled: false,
  colon: false,
  labelCol: { span: 24, offset: 0 },
  wrapperCol: { span: 24, offset: 0 },
};

RadioButtonGroupFormItem.propTypes = {
  label: PropTypes.string,
  labelAlign: PropTypes.string,
  name: PropTypes.string.isRequired,
  externalErrorMessage: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  layout: PropTypes.object,
  hint: PropTypes.string,
  buttonStyle: PropTypes.string,
};

export default RadioButtonGroupFormItem;
