import { Col, Form, Image, Space, Tooltip, Upload } from "antd";
import { EyeOutlined, PlusOutlined, RedoOutlined } from "@ant-design/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { getConfigByName } from "../../../../../config";
import { BRAND_NAME } from "../../../../../config/brandVariable";
import useAuthorize from "../../../../_common/authorize";

const LogoUploadFormItem = (props) => {
  const {
    data,
    handlePreview,
    uploadOnChange,
    resetLogo,
    name,
    previewEnable = true,
    condition,
    disabled,
  } = props;
  const { t } = useTranslation();
  const { systemErrorNotification } = useAuthorize();

  const brandConfig = getConfigByName(BRAND_NAME);

  const src = data;
  const label = t(name);

  return (
    <Col span={12} key={data?._id + name}>
      <Form.Item>
        {src ? (
          <div className={"preview-image-container"}>
            <Image
              className={"preview-image"}
              src={src}
              height={"100%"}
              width={"100%"}
              preview={false}
            />
            <div className={"action-bar"}>
              <Space className={"action-bar-inner"}>
                {previewEnable && (
                  <Tooltip title={t("Preview")}>
                    <a onClick={() => handlePreview(src)}>
                      <EyeOutlined color={"#d2d2d2"} />
                    </a>
                  </Tooltip>
                )}
                <Tooltip title={t("Upload")}>
                  <Upload
                    name={name}
                    action={`${brandConfig?.backendURL}/api/upload/profile/image`}
                    style={{ width: "100%" }}
                    accept="image/png, image/jpeg"
                    maxCount={1}
                    showUploadList={false}
                    defaultFileList={[
                      {
                        uid: data?._id,
                        name: data?.name,
                        url: src,
                      },
                    ]}
                    onChange={(e) => {
                      try {
                        uploadOnChange(e, name, data);
                      } catch (e) {
                        systemErrorNotification();
                      }
                    }}
                    disabled={disabled}
                  >
                    <a>{!disabled && <PlusOutlined />}</a>
                  </Upload>
                </Tooltip>
                {!disabled && (
                  <Tooltip title={t("Reset to default")}>
                    <a onClick={() => resetLogo(name)}>
                      <RedoOutlined />
                    </a>
                  </Tooltip>
                )}
              </Space>
            </div>
          </div>
        ) : (
          <Upload
            className={"ori-upload"}
            name={name}
            action={`${brandConfig?.backendURL}/api/upload/profile/image`}
            style={{ width: "100%" }}
            listType={"picture-card"}
            accept="image/png, image/jpeg"
            maxCount={1}
            showUploadList={false}
            defaultFileList={[
              {
                uid: data?._id,
                name: data?.name,
                url: data?.[name]?.[0]?.path,
              },
            ]}
            onChange={(e) => {
              try {
                uploadOnChange(e, name, data);
              } catch (e) {
                systemErrorNotification();
              }
            }}
            disabled={disabled}
          >
            {!disabled && (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )}
          </Upload>
        )}
        {/* <p className={"image-title margin-top-0-3"} style={{ fontWeight: 500 }}>
          {t(data?.label)}
        </p> */}
        <p className={"image-title"}>{label}</p>
        {condition &&
          condition.length > 0 &&
          condition.map((item, index) => {
            return (
              <p
                key={index}
                style={{ color: "red", fontSize: "12px", marginBottom: 0 }}
              >
                {item}
              </p>
            );
          })}
      </Form.Item>
    </Col>
  );
};

export default LogoUploadFormItem;
