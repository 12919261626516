import {gql, useMutation, useQuery} from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import {notificationMessage} from "../../../recoil_state";

const GET_MISSION_AUTOMATION_SETTING_BY_ID = gql`
    query getMissionAutomationSettingById($id: String!) {
        getMissionAutomationSettingById(id: $id) {
            ...on MissionAutomationSetting {
                id
                _id
                missionId
                triggerGroups {triggerGroup triggerName}
                criteriaGroups {
                    position 
                    logicalOperator 
                    criteriaItems {
                        type
                        reportId
                        adminPanelCollection
                        reportFieldId
                        fieldName
                        comparisonOperator
                        logicalOperator
                        position
                        value
                        isDisplay
                        displayName {
                            language
                            content {
                                displayLabel
                                measurementUnits
                            }
                        }
                    }
                }
                isDeleted
                createdAt
                createdBy {username}
                updatedBy {username}
                updatedAt
            }
            ...on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getMissionAutomationSettingById = (id) => {
    try {
        const {loading, error, data, refetch} = useQuery(GET_MISSION_AUTOMATION_SETTING_BY_ID, {
            variables: {
                id: id
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        const refreshQuery = () => {
            refetch();
        };

        return {loading, error, data, refreshQuery};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_MISSION_AUTOMATION_SETTING = gql`
    mutation addMissionAutomationSetting($input: MissionAutomationSettingInput) {
        addMissionAutomationSetting(input: $input) {
            ... on MissionAutomationSetting {
                id
                _id
                missionId
                triggerGroups {triggerGroup triggerName}
                criteriaGroups {
                    position 
                    logicalOperator 
                    criteriaItems {
                        type
                        reportId
                        adminPanelCollection
                        reportFieldId
                        fieldName
                        comparisonOperator
                        logicalOperator
                        position
                        value
                        isDisplay
                        displayName {
                            language
                            content {
                                displayLabel
                                measurementUnits
                            }
                        }
                    }
                }
                isDeleted
                createdAt
                createdBy {username}
                updatedBy {username}
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useAddMissionAutomationSettingMutation = (): {
    addMissionAutomationSetting: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [addMissionAutomationSettingMutation, {data, loading, error}] = useMutation(ADD_MISSION_AUTOMATION_SETTING);

        const addMissionAutomationSetting = (input) => {
            return addMissionAutomationSettingMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {addMissionAutomationSetting}}) {
                    const typename = addMissionAutomationSetting?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(addMissionAutomationSetting?.errKey),
                            key: "addMissionAutomationSetting",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Add Successfully"),
                            key: "addMissionAutomationSetting",
                        });
                    }
                },
            });
        };

        return {addMissionAutomationSetting, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            addMissionAutomationSetting: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};


const UPDATE_MISSION_AUTOMATION_SETTING = gql`
    mutation updateMissionAutomationSetting($id: String!, $input: MissionAutomationSettingInput) {
        updateMissionAutomationSetting(id: $id, input: $input) {
            ... on MissionAutomationSetting {
                id
                _id
                missionId
                triggerGroups {triggerGroup triggerName}
                criteriaGroups {
                    position 
                    logicalOperator 
                    criteriaItems {
                        type
                        reportId
                        adminPanelCollection
                        reportFieldId
                        fieldName
                        comparisonOperator
                        logicalOperator
                        position
                        value
                        isDisplay
                        displayName {
                            language
                            content {
                                displayLabel
                                measurementUnits
                            }
                        }
                    }
                }
                isDeleted
                createdAt
                createdBy {username}
                updatedBy {username}
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useUpdateMissionAutomationSettingMutation = (): {
    updateMissionAutomationSetting: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const {t} = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updateMissionAutomationSettingMutation, {data, loading, error}] = useMutation(UPDATE_MISSION_AUTOMATION_SETTING);

        const updateMissionAutomationSetting = (id, input) => {
            return updateMissionAutomationSettingMutation({
                variables: {
                    id: id,
                    input: input,
                },
                update(cache, {data: {updateMissionAutomationSetting}}) {
                    const typename = updateMissionAutomationSetting?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateMissionAutomationSetting?.errKey),
                            key: "updateMissionAutomationSetting",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Successfully"),
                            key: "updateMissionAutomationSetting",
                        });
                    }
                },
            });
        };

        return {updateMissionAutomationSetting, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            updateMissionAutomationSetting: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};


const DELETE_MISSION_AUTOMATION_SETTING = gql`
    mutation deleteMissionAutomationSetting($id: String!) {
        deleteMissionAutomationSetting(id: $id) {
            ... on MissionAutomationSetting {
                _id
                id
                isDeleted
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useDeleteMissionAutomationSettingMutation = (): {
    deleteMissionAutomationSetting: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [deleteMissionAutomationSettingMutation, {data, loading, error}] = useMutation(DELETE_MISSION_AUTOMATION_SETTING);

        const deleteMissionAutomationSetting = (id) => {
            return deleteMissionAutomationSettingMutation({
                variables: {
                    id: id,
                },
                update(cache, {data: {deleteMissionAutomationSetting}}) {
                    const typename = deleteMissionAutomationSetting?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(deleteMissionAutomationSetting?.errKey),
                            key: "deleteMissionAutomationSetting",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Delete Successfully"),
                            key: "deleteMissionAutomationSetting",
                        });
                    }
                },
            });
        };

        return {deleteMissionAutomationSetting, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            deleteMissionAutomationSetting: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};


export {
    getMissionAutomationSettingById,
    useAddMissionAutomationSettingMutation,
    useUpdateMissionAutomationSettingMutation,
    useDeleteMissionAutomationSettingMutation
};
