import React, {useState} from "react";
import {Button, Tabs} from "antd";
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import CompanyEmailModal from "./component/companyEmailModal";

import CompanyEmailTable from "./component/companyEmailTable";
import CompanyEmailTypesTable from "./component/companyEmailTypesTable";
import CompanyEmailTypesModal from "./component/companyEmailTypesModal";
import {notificationMessage} from "../../../../../../recoil_state";
import useAuthorize from "../../../../../_common/authorize";
import {deleteCompanyEmailTypes} from "../../../../../api/graphql/companyEmailType";
import {deleteCompanyEmail} from "../../../../../api/graphql/companyEmail";

const CompanyEmail_1 = (props) => {
    const {data, emailTypesData, getCompanyEmailData, getCompanyEmailTypesData, companyEmailPermission, domainOptions, emailTypeOptions} = props;
    const {baseErrorChecking, systemErrorNotification} = useAuthorize();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [activeTab, setActiveTab] = useState("companyEmail");
    const [emailModalMode, setEmailModalMode] = useState("");
    const [emailModalShown, setEmailModalShown] = useState(false);
    const [selectedEmail, setSelectedEmail] = useState({});

    const [emailTypesModalShown, setEmailTypesModalShown] = useState(false);
    const [emailTypesModalMode, setEmailTypesModalMode] = useState("");
    const [selectedType, setSelectedType] = useState({});

    const {t} = useTranslation();

    const toggleEmailModal = (mode = "", record = {}) => {
        setSelectedEmail(record);
        setEmailModalMode(mode);
        setEmailModalShown((prevState) => !prevState);
    };

    const toggleTypesModal = (mode = "", record = {}) => {
        setSelectedType(record);
        setEmailTypesModalMode(mode);
        setEmailTypesModalShown((prevState) => !prevState);
    };

    const handleEmailDelete = async (id) => {
        try {
            const response = await deleteCompanyEmail(id);
            if (response?.deleteCompanyEmail?.__typename === "CompanyEmail") {
                getCompanyEmailData();
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Delete Successfully",
                    key: "deleteCompanyEmail",
                });
            } else {
                return await baseErrorChecking(response?.deleteCompanyEmail);
            }
        } catch (error: any) {
            systemErrorNotification();
        }
    };

    const handleEmailTypesDelete = async (id) => {
        try {
            const response = await deleteCompanyEmailTypes(id);
            if (response?.deleteCompanyEmailTypes?.__typename === "CompanyEmailTypes") {
                getCompanyEmailTypesData();
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Delete Successfully",
                    key: "deleteCompanyEmailTypes",
                });
            } else {
                return await baseErrorChecking(response?.deleteCompanyEmailTypes);
            }
        } catch (error: any) {
            systemErrorNotification();
            // setErrMsg(error.message);
        }
    };

    const tabItems = [
        {
            key: "companyEmail",
            label: t("Company Email"),
            children: (
                <CompanyEmailTable
                    data={data}
                    companyEmailPermission={companyEmailPermission}
                    toggleModal={toggleEmailModal}
                    deleteCompanyEmail={handleEmailDelete}
                    getCompanyEmailData={getCompanyEmailData}
                />
            ),
        },
        {
            key: "emailTypes",
            label: t("Email Types"),
            children: (
                <CompanyEmailTypesTable
                    data={emailTypesData}
                    companyEmailPermission={companyEmailPermission}
                    toggleModal={toggleTypesModal}
                    deleteCompanyEmailTypes={handleEmailTypesDelete}
                    getCompanyEmailTypesData={getCompanyEmailTypesData}
                />
            ),
        },
    ];

    return (
        <div id="companyEmail">
            <CompanyEmailTypesModal
                mode={emailTypesModalMode}
                item={selectedType}
                isOpen={emailTypesModalShown}
                setOpen={toggleTypesModal}
                closeModal={toggleTypesModal}
                getCompanyEmailTypesData={getCompanyEmailTypesData}
            />

            <CompanyEmailModal
                mode={emailModalMode}
                item={selectedEmail}
                isOpen={emailModalShown}
                setOpen={toggleEmailModal}
                closeModal={toggleEmailModal}
                getCompanyEmailData={getCompanyEmailData}
                emailTypeOptions={emailTypeOptions}
                domainOptions={domainOptions}
            />

            <div className="display-flex">
                <h1>{t("Company Email")}</h1>
            </div>

            <Tabs
                defaultActiveKey="companyEmail"
                items={tabItems}
                activeKey={activeTab}
                onChange={(activeKey) => setActiveTab(activeKey)}
                tabBarExtraContent={
                    <>
                        {activeTab === "companyEmail" && companyEmailPermission?.add?.add && (
                            <Button className="margin-left-auto" onClick={() => toggleEmailModal("add")}>
                                {t("Add Company Email")}
                            </Button>
                        )}
                        {activeTab === "emailTypes" && companyEmailPermission?.add?.add && (
                            <Button className="margin-left-auto" onClick={() => toggleTypesModal("add")}>
                                {t("Add Email Types")}
                            </Button>
                        )}
                    </>
                }
            />
        </div>
    );
};

export default CompanyEmail_1;
