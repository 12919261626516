import {gql, useMutation, useQuery} from "@apollo/client";
import {clearTypeNameFromInputList} from "./_commonFunction";
import {useSetRecoilState} from "recoil";
import {useTranslation} from "react-i18next";
import {notificationMessage} from "../../../recoil_state";

const GET_DYNAMIC_VARIABLE_COUNT = gql`
  query getDynamicVariableCount($filter: DynamicVariableSearchInput) {
    getDynamicVariableCount(filter: $filter)
  }
`;

const getDynamicVariableCount = ({filter}) => {
  try {
    const {loading, error, data, refetch} = useQuery(GET_DYNAMIC_VARIABLE_COUNT, {
      variables: {
        filter: {
          name: filter?.name || null,
          variableType: filter?.variableType || null,
          type: filter?.type || null,
          isActive: filter?.isActive === null ? null : filter?.isActive,
        },
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    const refreshQuery = () => {
      refetch();
    };

    return {loading, error, data, refreshQuery};
  } catch (err: any) {
    console.error(err);
    return {data: null, loading: false, error: err};
  }
};

const GET_DYNAMIC_VARIABLE_BY_ID = gql`
  query getDynamicVariableByID($id: String!) {
    getDynamicVariableByID(id: $id) {
      ... on DynamicVariable {
        id
        _id
        name
        textObject {
          text
          language
        }
        fileObject {
          displayName
          fileName
          path
          fileList
          language
        }
        reportTableObject {
          reportCategoryId
          reportId
          reportFieldsName
          displayMode
          reportViewMode
          dateRangeType
          fromDate
          toDate
          data {
            entity
            operation
            id
          }
        }
        variableType
        type
        isActive
        createdBy {
          username
        }
        updatedBy {
          username
        }
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getDynamicVariableById = (id) => {
  try {
    const {loading, error, data} = useQuery(GET_DYNAMIC_VARIABLE_BY_ID, {
      variables: {
        id: id,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return {loading, error, data};
  } catch (err: any) {
    console.error(err);
    return {data: null, loading: false, error: err};
  }
};

const GET_DYNAMIC_VARIABLE = gql`
  query getDynamicVariable($filter: DynamicVariableSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
    getDynamicVariable(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
      ... on DynamicVariableArray {
        data {
          id
          _id
          name
          textObject {
            text
            language
          }
          fileObject {
            displayName
            fileName
            path
            fileList
            language
          }
          reportTableObject {
            reportCategoryId
            reportId
            reportFieldsName
            displayMode
            reportViewMode
            dateRangeType
            fromDate
            toDate
            data {
              entity
              operation
              id
            }
          }
          variableType
          type
          isActive
          isDeleted
          createdBy {
            username
          }
          updatedBy {
            username
          }
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getDynamicVariable = ({filter}, limit?, offset?, orderBy?) => {
  try {
    const {loading, error, data, refetch} = useQuery(GET_DYNAMIC_VARIABLE, {
      variables: {
        filter: {
          name: filter?.name || null,
          variableType: filter?.variableType || null,
          type: filter?.type || null,
          isActive: filter?.isActive === null ? null : filter?.isActive,
        },
        limit: limit || null,
        offset: offset || null,
        orderBy: orderBy || null,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    const refreshQuery = () => {
      refetch();
    };

    return {loading, error, variableData: data, refreshQuery};
  } catch (err: any) {
    console.error(err);
    return {variableData: null, loading: false, error: err};
  }
};

const ADD_DYNAMIC_VARIABLE = gql`
  mutation addDynamicVariable($input: DynamicVariableInput) {
    addDynamicVariable(input: $input) {
      ... on DynamicVariable {
        id
        _id
        name
        textObject {
          text
          language
        }
        fileObject {
          displayName
          fileName
          path
          fileList
          language
        }
        reportTableObject {
          reportCategoryId
          reportId
          reportFieldsName
          displayMode
          reportViewMode
          dateRangeType
          fromDate
          toDate
          data {
            entity
            operation
            id
          }
        }
        variableType
        type
        isActive
        createdBy {
          username
        }
        updatedBy {
          username
        }
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`;

const useAddDynamicVariableMutation = (): {
  addDynamicVariable: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {t} = useTranslation();
    const [addDynamicVariableMutation, {data, loading, error}] = useMutation(ADD_DYNAMIC_VARIABLE);

    const addDynamicVariable = (input) => {
      return addDynamicVariableMutation({
        variables: {
          input: {
            name: input.name || null,
            textObject: input.textObject || null,
            fileObject: input.fileObject || null,
            reportTableObject: input.reportTableObject || null,
            variableType: input.variableType || null,
            isActive: input.isActive ? true : false,
            type: "custom",
          },
        },
        update(cache, {data: {addDynamicVariable}}) {
          const typename = addDynamicVariable?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(addDynamicVariable?.errKey),
              key: "addDynamicVariable",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(addDynamicVariable?.msg),
              key: "addDynamicVariable",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Add Successfully"),
              key: "addDynamicVariable",
            });
          }
        },
        refetchQueries: [GET_DYNAMIC_VARIABLE, GET_DYNAMIC_VARIABLE_COUNT],
      });
    };

    return {addDynamicVariable, data, loading, error};
  } catch (err: any) {
    console.error(err);
    return {addDynamicVariable: null, data: null, loading: false, error: err};
  }
};

const UPDATE_DYNAMIC_VARIABLE = gql`
  mutation updateDynamicVariable($id: String!, $input: DynamicVariableInput) {
    updateDynamicVariable(id: $id, input: $input) {
      ... on DynamicVariable {
        id
        _id
        name
        textObject {
          text
          language
        }
        fileObject {
          displayName
          fileName
          path
          fileList
          language
        }
        reportTableObject {
          reportCategoryId
          reportId
          reportFieldsName
          displayMode
          reportViewMode
          dateRangeType
          fromDate
          toDate
          data {
            entity
            operation
            id
          }
        }
        variableType
        type
        isActive
        isDeleted
        createdBy {
          username
        }
        updatedBy {
          username
        }
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`;

const useUpdateDynamicVariableMutation = (): {
  updateDynamicVariable: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {t} = useTranslation();
    const [updateDynamicVariableMutation, {data, loading, error}] = useMutation(UPDATE_DYNAMIC_VARIABLE);

    const updateDynamicVariable = (id, input) => {
      return updateDynamicVariableMutation({
        variables: {
          id: id,
          input: {
            name: input.name || null,
            textObject: clearTypeNameFromInputList(input.textObject) || null,
            fileObject: clearTypeNameFromInputList(input.fileObject) || null,
            reportTableObject: input.reportTableObject || null,
            variableType: input.variableType || null,
            isActive: input.isActive ? true : false,
          },
        },
        update(cache, {data: {updateDynamicVariable}}) {
          const typename = updateDynamicVariable?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(updateDynamicVariable?.errKey),
              key: "updateDynamicVariable",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(updateDynamicVariable?.msg),
              key: "updateDynamicVariable",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Update Successfully"),
              key: "updateDynamicVariable",
            });
          }
        },
        refetchQueries: [GET_DYNAMIC_VARIABLE],
      });
    };

    return {updateDynamicVariable, data, loading, error};
  } catch (err: any) {
    console.error(err);
    return {
      updateDynamicVariable: null,
      data: null,
      loading: false,
      error: err,
    };
  }
};

const DELETE_DYNAMIC_VARIABLE = gql`
  mutation deleteDynamicVariable($id: String!) {
    deleteDynamicVariable(id: $id) {
      ... on DynamicVariable {
        id
        _id
        isDeleted
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`;

const useDeleteDynamicVariableMutation = (): {
  deleteDynamicVariable: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {t} = useTranslation();
    const [deleteDynamicVariableMutation, {data, loading, error}] = useMutation(DELETE_DYNAMIC_VARIABLE);

    const deleteDynamicVariable = (id) => {
      return deleteDynamicVariableMutation({
        variables: {
          id: id,
        },
        update(cache, {data: {deleteDynamicVariable}}) {
          const typename = deleteDynamicVariable?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(deleteDynamicVariable?.errKey),
              key: "deleteDynamicVariable",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(deleteDynamicVariable?.msg),
              key: "deleteDynamicVariable",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Delete Successfully"),
              key: "deleteDynamicVariable",
            });
          }
        },
        refetchQueries: [GET_DYNAMIC_VARIABLE, GET_DYNAMIC_VARIABLE_COUNT],
      });
    };

    return {deleteDynamicVariable, data, loading, error};
  } catch (err: any) {
    console.error(err);
    return {
      deleteDynamicVariable: null,
      data: null,
      loading: false,
      error: err,
    };
  }
};

export {
  getDynamicVariableCount,
  getDynamicVariableById,
  getDynamicVariable,
  useAddDynamicVariableMutation,
  useUpdateDynamicVariableMutation,
  useDeleteDynamicVariableMutation,
};
