import React from "react";

export const columns = [
  {
    title: "Variable Name",
    dataIndex: "name",
    key: "name",
    render: (text) => <p>{text}</p>,
  },
  {
    title: "Variable Type",
    dataIndex: "variableType",
    key: "variableType",
    render: (text) => <p>{text}</p>,
  },
  {
    title: "Text",
    dataIndex: "text",
    key: "text",
    render: (text) => <p>{text}</p>,
  },
  {
    title: "File Path",
    dataIndex: "path",
    key: "path",
    render: (text) => <p>{text}</p>,
  },
];
