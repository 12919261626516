import React from "react";
import {Button, Col, Row, Space} from "antd";
import {useTranslation} from "react-i18next";
import SelectFormItem from "../../../../../../components/form/selectFormItem";
import TimezoneSelectFormItem from "../../../../../../components/form/timezoneSelectFormtItem";
import {horizontalFormLayout, verticalFormLayout} from "../../../../../../components/form/formWrapper";
import SwitchFormItem from "../../../../../../components/form/switchFormItem";
import RadioGroupFormItem from "../../../../../../components/form/radioGroupFormItem";
import MultipleInputFormItem from "../../../../../../components/form/multipleInputFormItem";
import {SaveOutlined} from "@ant-design/icons";
import InputNumberFormItem from "../../../../../../components/form/inputNumberFormItem";
import InputFormItem from "../../../../../../components/form/inputFormItem";

const DomainForm = (props) => {
    const {data, setData, isEditable, onBrandDetailsSubmit, submitLoading} = props;
    const {t} = useTranslation();

    const handleSetValue = (e, field) => {
        if (field === "domain") {
            if (!e?.includes(data?.defaultDomain) && data?.domain && data?.domain.length > 0) {
                handleSetValue(data?.domain[0], "defaultDomain")
            }
        }

        setData((prevState) => {
            return {
                ...prevState,
                [field]: e,
            };
        });
    };

    return (
        <>
            <Row gutter={[48, 48]} style={{marginTop: "30px"}}>
                <Col xs={24} md={12}>
                    <Space direction={"vertical"} size={"large"} style={{width: "100%"}}>
                        <SelectFormItem
                            name={"defaultDomain"}
                            label={t("brandSetting.defaultDomain")}
                            options={data?.domain?.filter(d => d)?.map(d => {
                                return {label: d, value: d}
                            }) || []}
                            value={data?.defaultDomain || ""}
                            onChange={(e) => handleSetValue(e, "defaultDomain")}
                            disabled={!isEditable}
                        />
                    </Space>
                </Col>
                <Col xs={24} md={12}>
                    <Space direction={"vertical"} size={"large"} style={{width: "100%"}}>
                        <MultipleInputFormItem
                            name={"domain"}
                            label={t("brandSetting.domain")}
                            prefix={"https://"}
                            value={data?.domain}
                            layout={verticalFormLayout}
                            setValue={(e) => handleSetValue(e, "domain")}
                            disabled={!isEditable}
                        />
                    </Space>
                </Col>

                {/* <Col xs={24} md={12}>
                    <Space direction={"vertical"} size={"large"} style={{width: "100%"}}>
                        <TimezoneSelectFormItem
                            name={"timezone"}
                            label={t("brandSetting.timezone")}
                            value={data?.timezone || ""}
                            onChange={(e) => handleSetValue(e, "timezone")}
                            layout={verticalFormLayout}
                            disabled={!isEditable}
                        />
                        <SwitchFormItem
                            name={"pushNotification"}
                            label={t("brandSetting.pushNotification")}
                            value={data?.pushNotification ?? false}
                            onChange={(e) => handleSetValue(e, "pushNotification")}
                            disabled={!isEditable}
                            className={"push-notification-switch"}
                        />
                        <Row>
                            <Col xs={24} md={12}>
                                <RadioGroupFormItem
                                    name={"theme"}
                                    label={t("brandSetting.theme")}
                                    options={[
                                        {label: t("Light"), value: "light"},
                                        {label: t("Dark"), value: "dark"},
                                        {label: t("User Device Setting"), value: "deviceSetting"},
                                    ]}
                                    defaultValue={data?.theme || ""}
                                    direction={"vertical"}
                                    layout={verticalFormLayout}
                                    onChange={(e) => handleSetValue(e, "theme")}
                                    disabled={!isEditable}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <RadioGroupFormItem
                                    name={"layout"}
                                    label={t("brandSetting.layout")}
                                    options={[
                                        {label: t("Layout 1 - Classic"), value: "1"},
                                        {label: t("Layout 2 - Professional"), value: "2"},
                                        {label: t("Layout 3 - Modern"), value: "3"},
                                        {label: t("Layout 4 - Standard"), value: "4"},
                                        {label: t("Layout 5 - Grid"), value: "5"},
                                    ]}
                                    direction={"vertical"}
                                    defaultValue={data?.layout || ""}
                                    layout={verticalFormLayout}
                                    onChange={(e) => handleSetValue(e, "layout")}
                                    disabled={!isEditable}
                                />
                            </Col>
                            <Col xs={24} md={24}>
                                <InputFormItem
                                    name={"companyNo"}
                                    label={t("brandSetting.companyNo")}
                                    layout={verticalFormLayout}
                                    onChange={(e) => handleSetValue(e, "companyNo")}
                                    disabled={!isEditable}
                                />
                            </Col>
                            <Col xs={24} md={24}>
                                <InputFormItem
                                    name={"licenseNo"}
                                    label={t("brandSetting.licenseNo")}
                                    layout={verticalFormLayout}
                                    onChange={(e) => handleSetValue(e, "licenseNo")}
                                    disabled={!isEditable}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <InputNumberFormItem
                                    name={"usernameCharacterLimit"}
                                    label={t("brandSetting.usernameCharacterLimit")}
                                    layout={verticalFormLayout}
                                    onChange={(e) => handleSetValue(e, "usernameCharacterLimit")}
                                    disabled={!isEditable}
                                />
                            </Col>
                        </Row>
                    </Space>
                </Col> */}
                {/* <Col xs={22}>
          <Title level={5}>{t("Show Bank Details")}</Title>
        </Col>
        <Col xs={2}>
          <SwitchFormItemMui
            name={"showBankDetails"}
            options={[
              { label: t("On"), value: true },
              { label: t("Off"), value: false },
            ]}
            value={data?.showBankDetails ?? false}
            onChange={(e) => handleSetValue(e, "showBankDetails")}
            disabled={!isEditable}
          />
        </Col> */}
            </Row>
            {isEditable &&
            <Row className={"margin-top-0-75"} justify={"end"}>
                <Button
                    onClick={onBrandDetailsSubmit}
                    loading={submitLoading}
                    type={"primary"}
                >
                    {t("common.update")}
                </Button>
            </Row>
            }
        </>
    );
};

export default DomainForm;
