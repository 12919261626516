const getQueryStringParams = (paramsData) => {
  let params = "";
  Object.keys(paramsData).map((key, index) => {
    let param = "";
    if (index !== 0) {
      param += `&`;
    }
    param += `${key}=${encodeURIComponent(paramsData[key])}`;
    return (params += param);
  });
  return params;
};

export { getQueryStringParams };
