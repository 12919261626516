import React, {useEffect, useState} from "react";
import {useRecoilState, useSetRecoilState} from "recoil";
import Report_1 from "./_general/view/layout1";
import "./css/common/index.css";
import {
    getReportingAll,
    getReportingFieldIdentifier,
    getReportingFields,
    getReportingFunctions,
    getReportingOperations,
    getReportingSameRelationship,
    getReportingTypes,
} from "../../api/graphql/reporting";
import {
    reportingAllState,
    reportingAllFieldsState,
    reportingAllFunctionsState,
    reportingAllOperationsState,
    reportingAllTypesState,
    reportingAllFieldIdentifiersState,
    reportingAllAddionalFieldIdentifiersState,
} from "./_general/state";
import {selfPermissionObj} from "../../../recoil_state";

function ReportPage() {
    let layout = 1;
    let component;
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const reportsPermission = selfPermission?.reports?.reports;
    const reportCategoryPermission = selfPermission?.reports?.reportCategory;
    const setReportingAll = useSetRecoilState(reportingAllState);
    const setReportingFields = useSetRecoilState(reportingAllFieldsState);
    const setReportingFunctions = useSetRecoilState(reportingAllFunctionsState);
    const setReportingOperations = useSetRecoilState(reportingAllOperationsState);
    const setReportingTypes = useSetRecoilState(reportingAllTypesState);
    const setReportingFieldIdentifiers = useSetRecoilState(reportingAllFieldIdentifiersState);
    const setReportingAddionalFieldIdentifiers = useSetRecoilState(reportingAllAddionalFieldIdentifiersState);

    const getAllReports = async () => {
        try {
            const response = await getReportingAll();
            const list = response?.getReportingAll?.data;
            if (list) {
                setReportingAll(list);
            }
        } catch (error) {}
    };

    const getAllFields = async () => {
        const response = await getReportingFields();
        const list = response?.getReportingFields?.data;
        if (list) {
            setReportingFields(list);
        }
    };

    const getAllFunctions = async () => {
        const response = await getReportingFunctions();
        const list = response?.getReportingFunctions?.data;
        if (list) {
            setReportingFunctions(list);
        }
    };

    const getAllOperations = async () => {
        const response = await getReportingOperations();
        const list = response?.getReportingOperations?.data;
        if (list) {
            setReportingOperations(list);
        }
    };

    const getAllTypes = async () => {
        const response = await getReportingTypes();
        const list = response?.getReportingTypes?.data;
        if (list) {
            setReportingTypes(list);
        }
    };

    const getAllFieldIdentifiers = async () => {
        const response = await getReportingFieldIdentifier();
        const list = response?.getReportingFieldIdentifier?.data?.payload;
        if (list) {
            setReportingFieldIdentifiers(list);
        }
    };

    const getAllAddionalFieldIdentifiers = async () => {
        const response = await getReportingSameRelationship();
        const list = response?.getReportingSameRelationship?.data?.payload;
        if (list) {
            setReportingAddionalFieldIdentifiers(list);
        }
    };

    const refreshAllReportData = async () => {
        await getAllReports();
        await getAllFields();
        await getAllTypes();
    };

    const getAllCreateEditModalData = async () => {
        try {
            await getAllFunctions();
            await getAllOperations();
            await getAllFieldIdentifiers();
            await getAllAddionalFieldIdentifiers();
        } catch (error) {}
    };

    useEffect(() => {
        refreshAllReportData();
    }, []);

    switch (layout) {
        case 1:
            component = (
                <Report_1
                    refreshAllReportData={refreshAllReportData}
                    reportsPermission={reportsPermission}
                    reportCategoryPermission={reportCategoryPermission}
                    getAllReports={getAllReports}
                    getAllCreateEditModalData={getAllCreateEditModalData}
                />
            );
            break;

        default:
            component = (
                <Report_1
                    refreshAllReportData={refreshAllReportData}
                    reportsPermission={reportsPermission}
                    reportCategoryPermission={reportCategoryPermission}
                    getAllReports={getAllReports}
                    getAllCreateEditModalData={getAllCreateEditModalData}
                />
            );
            break;
    }

    return component;
}

export default ReportPage;
