import {atom} from "recoil";

const userVerificationConfigListState = atom({
    key: 'userVerificationConfigList',
    default: [],
});

const generalListCountryState = atom({
    key: 'generalListCategory',
    default: [],
});

const generalListFieldState = atom({
    key: 'generalListField',
    default: [],
});

const generalListFieldGroupState = atom({
    key: 'generalListFieldGroup',
    default: [],
});

const generalListDocumentState = atom({
    key: 'generalListDocument',
    default: [],
});

const generalListDocumentGroupState = atom({
    key: 'generalListDocumentGroup',
    default: [],
});

const userVerificationAvailableFieldState = atom({
    key: 'userVerificationAvailableField',
    default: null,
});

const userVerificationStepState = atom({
    key: 'userVerificationStep',
    default: [],
});

const userVerificationDocumentGroupState = atom({
    key: 'userVerificationDocumentGroup',
    default: [],
});

const tabBarExtraContentState = atom({
    key: "tabBarExtraContent",
    default: null
})

const currentTabState = atom({
    key: "currentTab",
    default: "field"
})

const stepSettingModalPropState = atom({
    key: "stepSettingModalProp",
    default: {isOpen: false}
})

const documentGroupSettingModalPropState = atom({
    key: "documentGroupSettingModalProp",
    default: {isOpen: false}
})

const fieldSettingModalPropState = atom({
    key: "fieldSettingModalProp",
    default: {isOpen: false}
})

const sortStepSettingModalPropState = atom({
    key: "sortStepSettingModalProp",
    default: {isOpen: false}
})

export {
    userVerificationConfigListState,
    generalListCountryState,
    generalListFieldState,
    generalListFieldGroupState,
    generalListDocumentState,
    generalListDocumentGroupState,
    userVerificationAvailableFieldState,
    userVerificationStepState,
    userVerificationDocumentGroupState,
    tabBarExtraContentState,
    currentTabState,
    stepSettingModalPropState,
    documentGroupSettingModalPropState,
    fieldSettingModalPropState,
    sortStepSettingModalPropState,
}