import React, {useEffect, useState} from "react";
import {Col, Modal, Row, Spin} from "antd";
import {calculateMamPammProfitSharingMT5} from "../../../../../../../../api/graphql/mamPamm";
import {formatMoney} from "../../../../function";
import Paragraph from "antd/es/typography/Paragraph";
import {useTranslation} from "react-i18next";

const MamPammWithdrawDisclaimerModal = ({isOpen, id, setIsOpen, setTransactionType}) => {
    const {t} = useTranslation();
    const [profitDetail, setProfitDetail] = useState<any>(null)

    const hiddenKeys = ["__typename", "traderProfitSharingRatio", "commissionProfitSharingRatio", "traderProfitSharingAmount", "commissionProfitSharingAmount"]

    const preload = async () => {
        const {calculateMamPammProfitSharingMT5: res} = await calculateMamPammProfitSharingMT5(id)
        setProfitDetail(res)
    }

    const handleCancel = () => {
        setIsOpen(false)
        setTransactionType("deposit")
    }

    useEffect(() => {
        if (id) preload()
    }, [id]);

    return (
        <Modal
            open={isOpen}
            onCancel={handleCancel}
            maskClosable={false}
            title={t("mampamm.withdraw")}
            closeIcon={false}
            okText={t("common.proceed")}
            onOk={() => setIsOpen(false)}
            cancelText={t("common.cancel")}
        >
            <div className={"withdraw-disclaimer"}>
                {
                    profitDetail ? (
                        <Row className={"mt-3"} gutter={[8, 8]} justify={"space-between"}>
                            {Object.keys(profitDetail)?.map(key => {
                                if (!hiddenKeys.includes(key)) {
                                    const value = key.includes("Ratio") ? `${profitDetail[key]} %` : formatMoney(profitDetail[key])
                                    return (
                                        <>
                                            <Col xl={16}>{t(`mampamm.${key}`)}</Col>
                                            <Col xl={8}>: &nbsp; {value}</Col>
                                        </>
                                    )
                                }
                            })}
                        </Row>
                    ) : (
                        <Row justify={"center"}>
                            <Spin className={"margin-top-0-75 margin-bottom-0-75"} spinning={true}/>
                        </Row>
                    )
                }

                <Paragraph className={"margin-top-0-75"}>
                    <p>
                        <strong>{t("mampamm.disclaimer")}</strong> <br/>
                        {t("mampamm.disclaimer_text")}
                    </p>
                </Paragraph>
            </div>
        </Modal>
    )
}

export default MamPammWithdrawDisclaimerModal