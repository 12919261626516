import { gql, useMutation, useQuery } from '@apollo/client'
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";

const GET_NAVIGATION_ROUTE_SETTING_COUNT = gql`
  query getNavigationRouteSettingCount($filter: NavigationRouteSettingSearchInput) {
    getNavigationRouteSettingCount(filter: $filter)
  }
`;

const getNavigationRouteSettingCount = ({filter}) => {
  try{
    const { loading, error, data } = useQuery(GET_NAVIGATION_ROUTE_SETTING_COUNT, {
        variables: {
            filter: {
              name: filter?.name || null,
              type: filter?.type || null,
            }
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}

const GET_NAVIGATION_ROUTE_SETTING_BY_ID = gql`
  query getNavigationRouteSettingByID($id: String!) {
    getNavigationRouteSettingByID(id: $id){
      ... on NavigationRouteSetting {
        _id
        id
        name
        type
        value
        createdBy {username}
        updatedBy {username}
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getNavigationRouteSettingById = (id) => {
  try{
    const { loading, error, data } = useQuery(GET_NAVIGATION_ROUTE_SETTING_BY_ID, {
        variables: {
          id: id
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}


const GET_NAVIGATION_ROUTE_SETTING = gql`
  query getNavigationRouteSetting($filter: NavigationRouteSettingSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
    getNavigationRouteSetting(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy){
      ... on NavigationRouteSettingArray {
        data {
          _id
          id
          name
          type
          value
          createdBy {username}
          updatedBy {username}
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getNavigationRouteSetting = ({filter}, limit?, offset?, orderBy?) => {
  try{
    const { loading, error, data } = useQuery(GET_NAVIGATION_ROUTE_SETTING, {
        variables: {
          filter: {
              name: filter?.name || null,
              type: filter?.type || null,
          },
          limit: limit || null,
          offset: offset || null,
          orderBy: orderBy || null,
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}

const ADD_NAVIGATION_ROUTE_SETTING = gql`
  mutation addNavigationRouteSetting($input: NavigationRouteSettingInput) {
    addNavigationRouteSetting(input: $input){
      ... on NavigationRouteSetting {
        _id
        id
        name
        type
        value
        createdBy {username}
        updatedBy {username}
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useAddNavigationRouteSettingMutation = (): { addNavigationRouteSetting: any, data: any, loading: boolean, error: any } => {
  try{
    const { t } = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [addNavigationRouteSettingMutation, { data, loading, error }] = useMutation(ADD_NAVIGATION_ROUTE_SETTING);

    const addNavigationRouteSetting = (input) => {
      return addNavigationRouteSettingMutation({
        variables: {
          input: input,
        },
        update(cache, { data: { addNavigationRouteSetting } }) {
          const typename = addNavigationRouteSetting?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(addNavigationRouteSetting?.errKey),
              key: "addNavigationRouteSetting",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(addNavigationRouteSetting?.msg),
              key: "addNavigationRouteSetting",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Add NavigationRouteSetting Successfully"),
              key: "addNavigationRouteSetting",
            });
          }
        },
        refetchQueries: [GET_NAVIGATION_ROUTE_SETTING]
      });
    };

    return { addNavigationRouteSetting, data, loading, error } 
  }
  catch(err: any){
    console.error(err);
    return { addNavigationRouteSetting: null, data: null, loading: false, error: err };
  }
}


const UPDATE_NAVIGATION_ROUTE_SETTING = gql`
mutation updateNavigationRouteSetting($id: String!, $input: NavigationRouteSettingInput) {
    updateNavigationRouteSetting(id: $id, input: $input){
      ... on NavigationRouteSetting {
        _id
        id
        name
        type
        value
        createdBy {username}
        updatedBy {username}
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useUpdateNavigationRouteSettingMutation = (): { updateNavigationRouteSetting: any, data: any, loading: boolean, error: any } => {
  try{
    const { t } = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [updateNavigationRouteSettingMutation, { data, loading, error }] = useMutation(UPDATE_NAVIGATION_ROUTE_SETTING);

    const updateNavigationRouteSetting = (id, input) => {
      return updateNavigationRouteSettingMutation({
        variables: {
          id: id,
          input: input
        },
        update(cache, { data: { updateNavigationRouteSetting } }) {
          const typename = updateNavigationRouteSetting?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(updateNavigationRouteSetting?.errKey),
              key: "updateNavigationRouteSetting",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(updateNavigationRouteSetting?.msg),
              key: "updateNavigationRouteSetting",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Update NavigationRouteSetting Successfully"),
              key: "updateNavigationRouteSetting",
            });
          }
        }
      });
    };

    return { updateNavigationRouteSetting, data, loading, error } 
  }
  catch(err: any){
    console.error(err);
    return { updateNavigationRouteSetting: null, data: null, loading: false, error: err };
  }
}


const DELETE_NAVIGATION_ROUTE_SETTING = gql`
  mutation deleteNavigationRouteSetting($id: String!){
    deleteNavigationRouteSetting(id: $id){
      ... on NavigationRouteSetting {
        _id
        id
        isDeleted
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useDeleteNavigationRouteSettingMutation = (): { deleteNavigationRouteSetting: any, data: any, loading: boolean, error: any } => {
  try{
    const { t } = useTranslation();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [deleteNavigationRouteSettingMutation, { data, loading, error }] = useMutation(DELETE_NAVIGATION_ROUTE_SETTING);

    const deleteNavigationRouteSetting = (id) => {
      return deleteNavigationRouteSettingMutation({
        variables: {
          id: id,
        },
        update(cache, { data: { deleteNavigationRouteSetting } }) {
          const typename = deleteNavigationRouteSetting?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(deleteNavigationRouteSetting?.errKey),
              key: "deleteNavigationRouteSetting",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(deleteNavigationRouteSetting?.msg),
              key: "deleteNavigationRouteSetting",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("Delete NavigationRouteSetting Successfully"),
              key: "deleteNavigationRouteSetting",
            });
          }
        },
        refetchQueries: [GET_NAVIGATION_ROUTE_SETTING]
      });
    };

    return { deleteNavigationRouteSetting, data, loading, error } 
  }
  catch(err: any){
    console.error(err);
    return { deleteNavigationRouteSetting: null, data: null, loading: false, error: err };
  }
}




export {
  getNavigationRouteSettingCount, 
  getNavigationRouteSettingById, 
  getNavigationRouteSetting, 
  useAddNavigationRouteSettingMutation, 
  useUpdateNavigationRouteSettingMutation, 
  useDeleteNavigationRouteSettingMutation,
}