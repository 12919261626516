import React, {useEffect, useState} from "react";
import {Avatar, Button, Card, Col, Divider, Popconfirm, Row, Space, Tag} from "antd";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {FormProvider, useForm} from "react-hook-form";
import {DeleteOutlined} from "@ant-design/icons";
import SelectFormItem from "../../../../../../../components/form/selectFormItem";
import RateFormItem from "../../../../../../../components/form/rateFormItem";
import {useDeleteUserLeadsMutation, useUnbindUserLeads, useUpdateUserLeadsMutation} from "../../../../../../../api/graphql/leads";
import useAuthorize from "../../../../../../../_common/authorize";
import {formatDateTimeMinute} from "../../../../../_common/function";
import {getUserDropdown} from "../../../../../../../api/graphql/_common";
import {formatDateTimeTimezone, formatUserOptions} from "../../../../../../../function/_common";

const ProfileCard = (props) => {
    const {data, setBindModalIsOpen, permissionUser} = props;
    const {t} = useTranslation();
    const labelCol = {span: 8, offset: 0};
    const [saleRepOptions, setSaleRepOptions] = useState([]);
    const [ownerOptions, setOwnerOptions] = useState([]);

    const {updateUserLeads} = useUpdateUserLeadsMutation();
    const {deleteUserLeads, loading} = useDeleteUserLeadsMutation();
    const {unbindUserLeads, loading: unbindLoading} = useUnbindUserLeads();
    const {systemErrorNotification} = useAuthorize();

    const form = useForm({
        mode: "all",
    });

    const getOwnerOptions = async () => {
        let additionalIds: string[] = data?.owner?._id ? [data?.owner?._id] : [];
        const response: any = await getUserDropdown("", additionalIds);
        setOwnerOptions(response?.getUserDropdown?.data?.map((d) => formatUserOptions(d)));
    };

    const getSaleRepOptions = async () => {
        let additionalIds: string[] = data?.salesRep?._id ? [data?.salesRep?._id] : [];
        const response: any = await getUserDropdown("", additionalIds);
        setSaleRepOptions(response?.getUserDropdown?.data?.map((d) => formatUserOptions(d)));
    };

    const onSubmit = async (value) => {
        try {
            await updateUserLeads(data?._id, value);
        } catch (e) {
            console.log(e);
            systemErrorNotification();
        }
    };

    const deleteUserLeadsOnConfirm = async () => {
        try {
            const response = await deleteUserLeads(data?._id);
            if (response?.data?.deleteUserLeads?.__typename === "UserLeads") window.location.reload();
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getOwnerOptions();
        getSaleRepOptions();
    }, [data]);

    return (
        <Card style={{width: "100%"}}>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="form-1" style={{width: "100%"}}>
                    <Row justify={"space-between"}>
                        <Space>
                            <Avatar size={64} src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=2" />
                            <div>
                                <h1>
                                    {data?.username || data?.fullName || data?.firstName || data?.lastName || data?.email || data?.completedMobileNo}
                                </h1>
                                <Tag>{t(data?.leadStatus)}</Tag>
                            </div>
                        </Space>
                        <Space>
                            {permissionUser?.leads?.bind?.bind &&
                                (data?.bind?._id ? (
                                    <div>
                                        <Popconfirm
                                            placement="left"
                                            description={t("Are you sure to unbind this user?")}
                                            title={t(`Unbind User Leads`)}
                                            onConfirm={() => {
                                                unbindUserLeads([data?._id]);
                                            }}
                                            okText={t("common.yes")}
                                            okType="danger"
                                            cancelText={t("common.no")}
                                        >
                                            <Button loading={unbindLoading} danger>
                                                {t("Unbind")}
                                            </Button>
                                        </Popconfirm>
                                    </div>
                                ) : (
                                    <Button onClick={() => setBindModalIsOpen(true)}>{t("Bind")}</Button>
                                ))}
                            {permissionUser?.leads?.edit?.edit && data?.isArchived && (
                                <Popconfirm
                                    placement="left"
                                    title={t("lead.delete.popconfirm.title")}
                                    description={t("lead.delete.popconfirm.description")}
                                    onConfirm={() => {
                                        deleteUserLeadsOnConfirm();
                                    }}
                                    okText={t("common.yes")}
                                    okButtonProps={{loading: loading}}
                                    cancelText={t("common.no")}
                                >
                                    <Button type={"link"} danger icon={<DeleteOutlined />} />
                                </Popconfirm>
                            )}
                        </Space>
                    </Row>
                    <Divider />
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={14}>
                            <RateFormItem
                                name={"potential"}
                                label={t("Potential")}
                                placeholder={t("Sales Rep")}
                                labelCol={labelCol}
                                value={data?.potential}
                                onChange={form.handleSubmit(onSubmit)}
                                disabled={!permissionUser?.leads?.edit?.edit}
                                labelAlign={"left"}
                                colon={false}
                            />
                            <SelectFormItem
                                name={"owner"}
                                label={t("Owner")}
                                options={ownerOptions}
                                placeholder={t("Owner")}
                                mode={"single"}
                                labelCol={labelCol}
                                value={data?.owner?._id}
                                onChange={form.handleSubmit(onSubmit)}
                                disabled={!permissionUser?.leads?.edit?.edit}
                                style={{width: "100%"}}
                                optionFilterProp={"label"}
                                optionLabelProp="selectedLabel"
                                labelAlign={"left"}
                            />
                            <SelectFormItem
                                name={"salesRep"}
                                label={t("Sales Rep")}
                                options={saleRepOptions}
                                placeholder={t("Sales Rep")}
                                mode={"single"}
                                labelCol={labelCol}
                                value={data?.salesRep?._id}
                                onChange={form.handleSubmit(onSubmit)}
                                disabled={!permissionUser?.leads?.edit?.edit}
                                style={{width: "100%"}}
                                optionFilterProp={"label"}
                                optionLabelProp="selectedLabel"
                                labelAlign={"left"}
                            />
                        </Col>
                        <Col xs={24} md={{span: 8, offset: 2}}>
                            <p>
                                {data?.bind?._id && (
                                    <>
                                        {t("Bind to")} :&nbsp;{" "}
                                        <a
                                            onClick={() =>
                                                window.open(
                                                    `/users/${data?.bind?.userType === "client" ? data?.bind?.userType : "crm-user"}/detail?id=${data?.bind?._id}`,
                                                    "_blank"
                                                )
                                            }
                                        >
                                            {data?.bind?.username}
                                        </a>
                                        <br />
                                    </>
                                )}
                                {t(`user.registrationTime`)} :&nbsp; {data?.createdAt ? formatDateTimeTimezone(data?.createdAt, null, "YYYY-MM-DD HH:mm") : ""}
                                <br />
                                {permissionUser?.leads?.view?.followUp && (
                                    <>
                                        {t(`Next Follow-up Time`)} :&nbsp;{" "}
                                        {data?.latestFollowUp?.followUpDateTime ? formatDateTimeTimezone(data?.latestFollowUp?.followUpDateTime, null, "YYYY-MM-DD HH:mm") : "-"}
                                        <br />
                                        {t(`Last Follow-up Time`)} :&nbsp;{" "}
                                        {data?.latestFollowUp?.lastFollowUp ? formatDateTimeTimezone(data?.latestFollowUp?.lastFollowUp, null, "YYYY-MM-DD HH:mm") : "-"}
                                    </>
                                )}
                            </p>
                        </Col>
                    </Row>
                </form>
            </FormProvider>
        </Card>
    );
};

export default ProfileCard;
