import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Empty, Row, Spin, Tag, Tooltip,} from 'antd';
import VirtualList from 'rc-virtual-list';
import {useTranslation} from "react-i18next";
import {useRecoilValue} from "recoil";
import {fieldListLoading, fieldListState, profileInformationSettingState,} from "../../../state";
import {AiOutlinePlus} from "react-icons/ai";


const FieldCards = (props) => {
    const {updateField, fieldListPermission, profileInformationPermission, updateLoading} = props
    const ContainerHeight = "70vh";
    const {t} = useTranslation();

    const fieldList = useRecoilValue(fieldListState)
    const loading = useRecoilValue(fieldListLoading)
    const profileInformation = useRecoilValue(profileInformationSettingState)

    const [fieldData, setFieldData] = useState<any>([])
    const filteredOutFields = ["crmUserComment", "clientComment", "leadsComment"]

    const addField = async (record: any) => {
        const response = await updateField(record?._id, profileInformation?.length ? profileInformation?.length + 1 : 1)
    }

    const formatData = async (presetFields, existingFields) => {
        if (presetFields?.length > 0 && existingFields?.length > 0) {
            return await presetFields?.filter(presetItem => {
                const formattedExistingFields = existingFields?.filter(item => item?.position !== null)
                return !formattedExistingFields?.some(existingItem => presetItem?._id === existingItem?.fieldList?._id);
            })
        } else return presetFields
    }

    useEffect(() => {
        formatData(fieldList, profileInformation)?.then(data => setFieldData(data?.filter(item => item?.category !== "registrationInformation" && !filteredOutFields.includes(item?.fieldName))))
    }, [fieldList, profileInformation])

    let categoryColor
    return (
        <div id={"field-cards"}>
            <Row style={{marginBottom: "15px"}}>
                <Col flex={"auto"}>
                    <h3>{t("Field")}</h3>
                </Col>
            </Row>

            <Row>
                <Col span={24} style={{height: ContainerHeight, overflow: "auto"}}>
                    {
                        fieldListPermission?.view && fieldData?.length > 0 ? (
                            <VirtualList
                                data={fieldData}
                                itemKey="_id"
                            >
                                {(item, i) => {
                                    switch (item?.category) {
                                        case "basicInformation" :
                                            categoryColor = "gold"
                                            break
                                        case "loginInformation" :
                                            categoryColor = "lime"
                                            break
                                        case "residentialInformation" :
                                            categoryColor = "green"
                                            break
                                        case "additionalInformation" :
                                            categoryColor = "geekblue"
                                            break
                                        case "corporateInformation" :
                                            categoryColor = "purple"
                                            break
                                    }

                                    return (
                                        <Card className={"margin-top-0-75"} key={item?._id} style={{marginRight: 8}}>
                                            <Row>
                                                <Col flex={"auto"}>
                                                    <h4>{t(item?.label)} {item?.fieldList?.isRequired ?
                                                        <span style={{color: "red"}}> * </span> : undefined}</h4>
                                                    <Tag color={"blue"}
                                                         bordered={false}><small>{item?.fieldType?.toUpperCase()}</small></Tag>
                                                    <Tag color={categoryColor}
                                                         bordered={false}><small>{item?.category?.replace(/(INFORMATION)/i, "")?.toUpperCase()}</small></Tag>
                                                    {
                                                        item?.fieldList?.isConfirmRequired &&
                                                        <Tag color="magenta" style={{marginTop: 4}}
                                                             bordered={false}><small>{t("CONFIRM REQUIRED")}</small></Tag>
                                                    }
                                                    {
                                                        item?.fieldList?.isSensitive &&
                                                        <Tag color="volcano"
                                                             bordered={false}><small>{t("SENSITIVE")}</small></Tag>
                                                    }
                                                    {
                                                        item?.fieldList?.isUnique &&
                                                        <Tag color="orange"
                                                             bordered={false}><small>{t("UNIQUE")}</small></Tag>
                                                    }
                                                    {
                                                        item?.fieldList?.isUsedForLogin &&
                                                        <Tag color="red"
                                                             bordered={false}><small>{t("USED FOR LOGIN")}</small></Tag>
                                                    }
                                                </Col>
                                                {profileInformationPermission?.edit?.edit &&
                                                    <Col flex={"20px"}>
                                                    <Tooltip title={t("Add")}>
                                                        <Button type={"link"}
                                                            disabled={updateLoading}
                                                            onClick={() => {
                                                                addField(item)
                                                            }}
                                                            icon={
                                                                <AiOutlinePlus />}
                                                            />
                                                    </Tooltip>
                                                </Col>}
                                            </Row>
                                        </Card>
                                    )
                                }}
                            </VirtualList>
                        ) : fieldListPermission?.view && loading ? (
                            <Col span={24} style={{marginTop: "20%", textAlign: "center"}}>
                                <Spin size="large"/>
                            </Col>
                        ) : (
                            <Col span={24} style={{paddingTop: "20%"}}>
                                <Empty/>
                            </Col>
                        )
                    }
                </Col>
            </Row>
        </div>
    )
}

export default FieldCards