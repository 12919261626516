import React, {useContext, useEffect, useRef, useState} from "react";
import type {InputRef} from "antd";
import {Col, Form, Input, Row, Table} from "antd";
import type {FormInstance} from "antd/es/form";
import {translationFileColumns} from "../../../function/tableColumn";
import {getAllColumnFromLanguage, getColumnFromLanguage,} from "../../../function/common";
import {getLanguageStandardListing} from "../../../../../../api/graphql/_common";

const EditableContext = React.createContext<FormInstance<any> | null>(null);

const LanguageTableForm = (props) => {
  const { languageCode, translationFile, setTranslationFile, mode } = props;
  const { languageStandardListing } = getLanguageStandardListing();
  const [searchInput, setSearchInput] = useState("");

  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
      if (editing) {
        inputRef.current!.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();

        toggleEdit();
        handleSave({ ...record, ...values });
      } catch (errInfo) {
        console.log("Save failed:", errInfo);
      }
    };

    if (!children[1]) {
      let temp = [...children];
      temp[1] = "-----";
      children = temp;
    }

    let childNode = children;
    if (editable) {
      childNode = editing ? (
        <Form.Item style={{ margin: 0 }} name={dataIndex}>
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{ paddingRight: 24 }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  };

  const handleSave = (row) => {
    const newData = [...translationFile];
    const index = newData.findIndex((x) => row.ids === x.ids);
    const tempItem = newData[index];
    newData.splice(index, 1, {
      ...tempItem,
      ...row,
    });
    setTranslationFile(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const getDefaultColumns = () => {
    if (mode === "upload") {
      return getAllColumnFromLanguage(translationFile, languageStandardListing);
    } else {
      return getColumnFromLanguage(languageCode, languageStandardListing, mode);
    }
  };

  const defaultColumns = [...translationFileColumns, ...getDefaultColumns()];

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const tableData = translationFile?.filter(
    (v) =>
      v?.key?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
      v?.en?.toLowerCase()?.includes(searchInput?.toLowerCase())
  );

  return (
    <>
      <Row>
        <Col span={3}>
          <p>Search:</p>
        </Col>
        <Col span={8}>
          <Input
            size="small"
            value={searchInput}
            onChange={(e) => setSearchInput(e?.target?.value)}
          />
        </Col>
      </Row>
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={tableData}
        columns={columns}
        tableLayout={"fixed"}
      />
    </>
  );
};

export default LanguageTableForm;
