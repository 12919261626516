import React from "react";
import {ColumnsType} from "antd/es/table";
import { Image } from "antd";

export const columns: ColumnsType<any> = [
    {
        title: "",
        dataIndex: "imageLight",
        key: "imageLight",
        render: (field) => {
            if(field?.length > 0){
                return <Image className="tableIcon" src={field[0]?.path} alt="logo" />
            }
            else {
                return <></>
            }
        },
    },
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        defaultSortOrder: "ascend",
        sorter: (a, b) => a?.name?.localeCompare(b?.name),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Display Name",
        dataIndex: "displayName",
        key: "displayName",
        defaultSortOrder: "ascend",
        sorter: (a, b) => a?.displayName?.filter(item => item.language === "en")?.[0]?.content.localeCompare(b?.displayName?.filter(item => item.language === "en")?.[0]?.content),
        render: (_, record) => <p>{record?.displayName?.filter(item => item.language === "en")?.[0]?.content}</p>,
    },
    {
        title: "Description",
        dataIndex: "description",
        key: "description",
        defaultSortOrder: "ascend",
        sorter: (a, b) => a?.description?.filter(item => item.language === "en")?.[0]?.content.localeCompare(b?.description?.filter(item => item.language === "en")?.[0]?.content),
        render: (_, record) => {
            if(record?.description?.filter(item => item.language === "en").length > 0){
                return <p>{record?.description?.filter(item => item.language === "en")?.[0]?.content?.substring(0, 30)}...</p>
            }
            else {
                return <></>
            }
        },
    },
];
