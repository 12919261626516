import {Button, Card, Col, Form, Row} from "antd";
import {FormProvider, useForm} from "react-hook-form";
import SelectFormItem from "../../../../../../../components/form/selectFormItem";
import {horizontalFormLayout} from "../../../../../../../components/form/formWrapper";
import SwitchFormItem from "../../../../../../../components/form/switchFormItem";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {yupResolver} from "@hookform/resolvers/yup";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import * as yup from "yup";
import yupFormSchema from "../../../../../../../components/yup/yupFormSchema";
import {useAddTradingCreditTransactionMutation} from "../../../../../../../api/graphql/tradingAccount";
import useAuthorize from "../../../../../../../_common/authorize";
import InputNumberFormItem from "../../../../../../../components/form/inputNumberFormItem";
import TextAreaInputFormItem from "../../../../../../../components/form/textAreaInputFormItem";
import DatePickerFormItem from "../../../../../../../components/form/datePickerFormItem";
import moment from "moment";
import {addTradingCreditTransactionMt5} from "../../../../../../../api/graphql/tradingAccountMt5";
import {formatDateUTC} from "../../../../../../../function/_common";


const TradingAccountBatchCreditTransactionForm_1 = (props) => {

    const {batchUpdateRecord, refetch, setView} = props
    const [btnLoading, setBtnLoading] = useState(false)
    const [transactionType, setTransactionType] = useState("deposit");
    const [depositType, setDepositType] = useState("");

    const [invalidAcc, setInvalidAcc] = useState<any>([]);

    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {systemErrorNotification} = useAuthorize()

    const {addTradingCreditTransaction} = useAddTradingCreditTransactionMutation();

    const yupSchema = yup.object().shape({
        type: yupFormSchema.string("type", {required: true}),
        amount: yupFormSchema.number("amount", {required: true}),
        comment: yupFormSchema.string("comment", {required: false}),
        expirationDatetime: yupFormSchema.string("expirationDatetime", {
            required: false,
        }),
        sendEmailNotification: yupFormSchema.boolean("sendEmailNotification", {
            required: false,
        }),
    });

    const labelCol = {span: 8, offset: 0};

    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
    });

    const {t} = useTranslation();

    const onSubmit = async (value) => {
        const valid: any = []
        const invalid: any = []
        const pending: any = []
        try {
            await setBtnLoading(true)
            await setInvalidAcc([])


            for (let j = 0; j < batchUpdateRecord?.length; j++) {
                const acc = batchUpdateRecord[j];

                if (acc?.platform === "mt5") {
                    const response = await addTradingCreditTransactionMt5({
                        user: acc?.userId,
                        account: acc._id,
                        type: value?.type,
                        amount: value?.amount.toString(),
                        expirationDatetime: value?.expirationDatetime ? formatDateUTC(value?.expirationDatetime, "formSubmit") : "",
                        comment: value?.comment,
                        sendEmailNotification: value?.sendEmailNotification,
                    });

                    if (
                        response?.addTradingCreditTransactionMt5.__typename ===
                        "TradingMt5CreditTransaction"
                    ) {
                        valid.push({
                            account: acc,
                            msg: t("tradingAccount.create_transaction_successfully"),
                        })
                    } else if (
                        response?.addTradingCreditTransactionMt5.__typename === "BaseError"
                    ) {
                        invalid.push({
                            account: acc,
                            msg: t(response?.data?.addTradingCreditTransactionMt5?.errKey),
                        })
                    } else {
                        pending.push({
                            account: acc,
                            msg: t(response?.data?.addTradingCreditTransactionMt5?.msg),
                        })
                    }
                } else {
                    const response = await addTradingCreditTransaction({
                        user: acc?.userId,
                        account: acc._id,
                        type: value?.type,
                        amount: value?.amount.toString(),
                        expirationDatetime: value?.expirationDatetime && moment(value?.expirationDatetime).format("YYYY-MM-DD"),
                        comment: value?.comment,
                        sendEmailNotification: value?.sendEmailNotification,
                    });

                    if (response?.data?.addTradingCreditTransaction.__typename === "tradingCreditTransaction") {
                        valid.push({
                            account: acc,
                            msg: t("tradingAccount.create_transaction_successfully"),
                        })
                    } else if (response?.data?.addTradingCreditTransaction.__typename === "BaseError") {
                        invalid.push({
                            account: acc,
                            msg: t(response?.data?.addTradingCreditTransaction?.errKey),
                        })
                    } else {
                        pending.push({
                            account: acc,
                            msg: t(response?.data?.addTradingCreditTransaction?.msg),
                        })
                    }
                }
            }

            if (valid?.length > 0 || pending?.length > 0) {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("tradingAccount.create_transaction_successfully"),
                    key: "addTradingAccountTransaction",
                });
            }

            setInvalidAcc(invalid)
            refetch()

        } catch (error: any) {
            systemErrorNotification()
        } finally {
            setBtnLoading(false)
            if (invalid.length === 0) {
                setView(false)
            }
        }
    };

    const transactionTypeOptions = [
        {
            value: "in",
            label: t("tradingAccount.credit_in"),
        },
        {
            value: "out",
            label: t("tradingAccount.credit_out"),
        },
    ];

    return (
        <Row gutter={[16, 16]}>
            <Col span={8} style={{marginTop: "15px"}}>
                <div>
                    {/*<h4>Credit</h4>*/}
                    <FormProvider {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)} className="form-1">
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <SelectFormItem
                                        labelAlign={"left"}
                                        name={"type"}
                                        label={t("tradingAccount.transaction_type")}
                                        placeholder={t("tradingAccount.transaction_type")}
                                        layout={horizontalFormLayout}
                                        options={transactionTypeOptions}
                                        mode={"single"}
                                        required
                                        labelCol={labelCol}
                                        value={"in"}
                                    />
                                </Col>
                                <Col span={24}>
                                    <DatePickerFormItem
                                        labelAlign={"left"}
                                        name={"expirationDatetime"}
                                        label={t("tradingAccount.expired_date")}
                                        placeholder={t("tradingAccount.expired_date")}
                                        layout={horizontalFormLayout}
                                        labelCol={labelCol}
                                        disabledDate={current => {
                                            return current && current < moment().startOf("day");
                                        }}
                                    />
                                </Col>
                                <Col span={24}>
                                    <InputNumberFormItem
                                        labelAlign={"left"}
                                        name={"amount"}
                                        label={t("tradingAccount.amount")}
                                        placeholder={t("tradingAccount.amount")}
                                        layout={horizontalFormLayout}
                                        required
                                        labelCol={labelCol}
                                        style={{width: "100%"}}
                                        min={1}
                                        step={2}
                                        // formatter={formatValue}
                                        // parser={parseValue}
                                    />
                                </Col>
                                <Col span={24}>
                                    <SwitchFormItem
                                        labelAlign={"left"}
                                        name={"sendEmailNotification"}
                                        label={t("tradingAccount.send_email_notification")}
                                        labelCol={{span: 9, offset: 0}}
                                        value={true}
                                    />
                                </Col>
                                <Col span={24}>
                                    <TextAreaInputFormItem
                                        labelAlign={"left"}
                                        name={"comment"}
                                        label={t("tradingAccount.comment")}
                                        placeholder={t("tradingAccount.comment")}
                                        layout={horizontalFormLayout}
                                        labelCol={labelCol}
                                    />
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        className="text-align-right"
                                        style={{paddingTop: "12px"}}
                                    >
                                        <Button
                                            type={"primary"}
                                            onClick={form.handleSubmit(onSubmit)}
                                            loading={btnLoading}
                                            className="button-submit-1"
                                            disabled={btnLoading}
                                        >
                                            {t("common.submit")}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </form>
                    </FormProvider>

                </div>
            </Col>
            <Col span={14} offset={1}>
                {
                    invalidAcc?.length > 0 && (
                        <Card style={{width: "100%"}}>
                            {
                                invalidAcc?.map((item) => {
                                    return (
                                        <div className={"margin-bottom-0-75"}>
                                            <Col span={24}>
                                                {t("tradingAccount.account")}: {item?.account?.accountId}
                                            </Col>
                                            <Col span={24}>
                                                {t("tradingAccount.message")}: <span
                                                style={{color: "red"}}>{item?.msg}</span>
                                            </Col>
                                        </div>
                                    )
                                })
                            }
                        </Card>
                    )
                }
            </Col>

        </Row>
    )
}

export default TradingAccountBatchCreditTransactionForm_1