import React from "react";
import {Modal} from 'antd';
import ProductForm from "./productForm";
import {useTranslation} from "react-i18next";

const ProductModal = (props) => {
    const {isOpen, close, mode, item, server, spreadGroupList, productGroupList,spreadGroupMt5} = props
    const {t} = useTranslation()

    return (
            <Modal
                title={t(`${mode} Product`)}
                open={isOpen}
                onCancel={() => close()}
                footer={null}
                width={800}
                destroyOnClose
            >
                <ProductForm
                    formMode={mode}
                    item={item}
                    close={close}
                    server={server}
                    spreadGroupList={spreadGroupList}
                    spreadGroupMt5={spreadGroupMt5}
                    productGroupList={productGroupList}/>
            </Modal>
    );
};

export default ProductModal