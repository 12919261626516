import { gql, useMutation, useQuery } from '@apollo/client'
import { clearTypeNameFromInputList } from './_commonFunction';

const GET_DYNAMIC_EMAIL_COMPONENT_COUNT = gql`
  query getDynamicEmailComponentCount($filter: DynamicEmailComponentSearchInput) {
    getDynamicEmailComponentCount(filter: $filter)
  }
`;

const getDynamicEmailComponentCount = ({filter}) => {
  try{
    const { loading, error, data } = useQuery(GET_DYNAMIC_EMAIL_COMPONENT_COUNT, {
        variables: {
            filter: {
              name: filter?.name || null,
              componentType: filter?.componentType || null,
              type: filter?.type || null,
            }
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}

const GET_DYNAMIC_EMAIL_COMPONENT_BY_ID = gql`
  query getDynamicEmailComponentByID($id: String!) {
    getDynamicEmailComponentByID(id: $id){
      ... on DynamicEmailComponent {
        id
        _id
        name
        htmlObject {html, language}
        componentType
        type
        isActive
        createdBy {username}
        updatedBy {username}
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getDynamicEmailComponentById = (id) => {
  try{
    const { loading, error, data } = useQuery(GET_DYNAMIC_EMAIL_COMPONENT_BY_ID, {
        variables: {
          id: id
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}


const GET_DYNAMIC_EMAIL_COMPONENT = gql`
  query getDynamicEmailComponent($filter: DynamicEmailComponentSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
    getDynamicEmailComponent(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy){
      ... on DynamicEmailComponentArray {
        data {
          id
          _id
          name
          htmlObject {html, language}
          componentType
          type
          isActive
          createdBy {username}
          updatedBy {username}
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }

`;

const getDynamicEmailComponent = ({filter}, limit?, offset?, orderBy?) => {
  try{
    const { loading, error, data } = useQuery(GET_DYNAMIC_EMAIL_COMPONENT, {
        variables: {
          filter: {
              name: filter?.name || null,
              componentType: filter?.componentType || null,
              type: filter?.type || null,
          },
          limit: limit || null,
          offset: offset || null,
          orderBy: orderBy || null,
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, componentData: data }
  }
  catch(err: any){
    console.error(err);
    return { componentData: null, loading: false, error: err };
  }
}

const ADD_DYNAMIC_EMAIL_COMPONENT = gql`
  mutation addDynamicEmailComponent($input: DynamicEmailComponentInput) {
    addDynamicEmailComponent(input: $input){
      ... on DynamicEmailComponent {
        id
        _id
        name
        htmlObject {html, language}
        componentType
        type
        isActive
        createdBy {username}
        updatedBy {username}
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval{
        msg
      }
    }
  }
`

const useAddDynamicEmailComponentMutation = (): { addDynamicEmailComponent: any, data: any, loading: boolean, error: any } => {
  try{
    const [addDynamicEmailComponentMutation, { data, loading, error }] = useMutation(ADD_DYNAMIC_EMAIL_COMPONENT, {
      update(cache, { data: { addDynamicEmailComponent } }) {
        let typename = addDynamicEmailComponent?.__typename;
        if (typename === "DynamicEmailComponent") {
          cache.modify({
            fields: {
              getDynamicEmailComponent(existingData = []) {
                const newRef = cache.writeFragment({
                  data: addDynamicEmailComponent,
                  fragment: gql`
                    fragment NewDynamicEmailComponent on DynamicEmailComponent {
                      id
                      _id
                      name
                      htmlObject {html, language}
                      componentType
                      type
                      isActive
                    }
                  `
                });
                return {
                  ...existingData,
                  data:
                      existingData?.data?.length > 0
                          ? [newRef, ...existingData?.data]
                          : [newRef],
                };
              },
              getDynamicEmailComponentCount(existingData) {
                return existingData + 1;
              },
            },
          });
        }else if (typename === "CrmTaskApproval" || typename === "BaseError"){
          // Notification
        }
      },
    });

    const addDynamicEmailComponent = (input) => {
      return addDynamicEmailComponentMutation({
        variables: {
          input: {
            name: input.name || null,
            htmlObject: input.htmlObject || null,
            componentType: input.componentType || null,
            isActive: input.isActive? true : false,
            type: "custom",
          },
        },
      });
    };

    return { addDynamicEmailComponent, data, loading, error } 
  }
  catch(err: any){
    console.error(err);
    return { addDynamicEmailComponent: null, data: null, loading: false, error: err };
  }
}

const UPDATE_DYNAMIC_EMAIL_COMPONENT = gql`
mutation updateDynamicEmailComponent($id: String!, $input: DynamicEmailComponentInput) {
    updateDynamicEmailComponent(id: $id, input: $input){
      ... on DynamicEmailComponent {
        id
        name
        htmlObject {html, language}
        componentType
        type
        isActive
        createdBy {username}
        updatedBy {username}
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval{
        msg
      }
    }
  }
`

const useUpdateDynamicEmailComponentMutation = (): { updateDynamicEmailComponent: any, data: any, loading: boolean, error: any } => {
  try{
    const [updateDynamicEmailComponentMutation, { data, loading, error }] = useMutation(UPDATE_DYNAMIC_EMAIL_COMPONENT);

    const updateDynamicEmailComponent = (id, input) => {
      return updateDynamicEmailComponentMutation({
        variables: {
          id: id,
          input: {
            name: input.name || null,
            htmlObject: clearTypeNameFromInputList(input.htmlObject) || null,
            isActive: input.isActive? true : false,
            componentType: input.componentType || null,
          },
        },
      });
    };

    let typename = data?.updateCompanyEmail?.__typename
    if (typename === "CrmTaskApproval" || typename === "BaseError") {
      // Notification
    }

    return { updateDynamicEmailComponent, data, loading, error } 
  }
  catch(err: any){
    console.error(err);
    return { updateDynamicEmailComponent: null, data: null, loading: false, error: err };
  }
}


const DELETE_DYNAMIC_EMAIL_COMPONENT = gql`
  mutation deleteDynamicEmailComponent($id: String!){
    deleteDynamicEmailComponent(id: $id){
      ... on DynamicEmailComponent {
          id
          isDeleted
        }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useDeleteDynamicEmailComponentMutation = (): { deleteDynamicEmailComponent: any, data: any, loading: boolean, error: any } => {
  try{
    const [deleteDynamicEmailComponentMutation, { data, loading, error }] = useMutation(DELETE_DYNAMIC_EMAIL_COMPONENT, {
      update(cache, { data: { deleteDynamicEmailComponent } }) {
        let typename = deleteDynamicEmailComponent?.__typename;
        if (typename === "DynamicEmailComponent") {
          cache.modify({
            fields: {
              getDynamicEmailComponent(existingData = [], {readField}) {
                let data = existingData?.data?.filter((ref) => {
                  const id = readField("id", ref);
                  return id !== deleteDynamicEmailComponent.id;
                });
                return { data, __typename: "DynamicEmailComponentArray" }
              },
              getDynamicEmailComponentCount(existingData) {
                return existingData - 1;
              },
            },
          });
        }else if (typename === "CrmTaskApproval" || typename === "BaseError"){
          // Notification
        }
      },
    });

    const deleteDynamicEmailComponent = (id) => {
      return deleteDynamicEmailComponentMutation({
        variables: {
          id: id,
        },
      });
    };

    return { deleteDynamicEmailComponent, data, loading, error } 
  }
  catch(err: any){
    console.error(err);
    return { deleteDynamicEmailComponent: null, data: null, loading: false, error: err };
  }
}

export {
  getDynamicEmailComponentCount, 
  getDynamicEmailComponentById, 
  getDynamicEmailComponent, 
  useAddDynamicEmailComponentMutation, 
  useUpdateDynamicEmailComponentMutation, 
  useDeleteDynamicEmailComponentMutation
}