import React from "react";
import LogTable from "./component/logTable";

const Logger_1 = (props) => {

    const {
        data,
        total,
        limit,
        setLimit,
        setOffset,
        setOrder,
        setStartDate,
        setEndDate,
        setDateKey,
        setFilter,
        refetchQuery,
        loading,
        tableName,
        idModal
    } = props

    return (
        <LogTable
            data={data}
            total={total}
            refetchQuery={refetchQuery}
            limit={limit}
            loading={loading}
            setLimit={setLimit}
            setOffset={setOffset}
            setOrder={setOrder}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setDateKey={setDateKey}
            setFilter={setFilter}
            tableName={tableName}
            idModal={idModal}
        />
    )
}

export default Logger_1