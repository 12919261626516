import React, {useEffect, useState} from "react";
import {Button, Row} from "antd";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import {getSalesOpportunity} from "../../../../../../api/graphql/salesOpportunity";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {useGetSalesOpportunity} from "../../../function";
import {formatDateTimeTimezone} from "../../../../../../function/_common";
import SalesOpportunityForm from "./salesOpportunityForm";

const DetailTable = (props) => {
    const {filter, permissionUser, user} = props
    const {t} = useTranslation()
    const [limit, setLimit] = useState<any>(10)
    const [offset, setOffset] = useState<any>(0)
    const [isOpen, setIsOpen] = useState<any>(false)
    const [isAdd, setIsAdd] = useState<any>()
    const [formData, setFormData] = useState<any>()

    const [data, loading, fetchSalesOpportunity, refetchSalesOpportunity] = useGetSalesOpportunity()
    const userType = Object.keys(filter)?.[0]

    useEffect(() => {
        fetchSalesOpportunity(filter, limit, offset)
    }, [JSON.stringify(filter), limit, offset]);

    const columns = [
        {
            title: t("salesOpportunity.spid"),
            dataIndex: "salesOpportunityId",
            key: "salesOpportunityId",
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("salesOpportunity.salesOpportunityName"),
            dataIndex: "salesOpportunityName",
            key: "salesOpportunityName",
            render: (text, record) => <OpportunityName text={text} record={record}/>,
        },
        {
            title: t("common.status"),
            dataIndex: "salesOppStatusName",
            key: "salesOppStatusName",
            render: (text) => <p>{t(text)}</p>,
        },
        {
            title: t("common.amount"),
            dataIndex: "amount",
            key: "amount",
            align: "right",
            render: (text) => <p>{text ? text : "0.00"}</p>,
        },
        {
            title: t("salesOpportunity.remark"),
            dataIndex: "remark",
            key: "remark",
            render: (text) => <p>{text && text?.length > 20 ? text?.slice(0, 17)?.padEnd(20, "...") : text}</p>,
        },
        {
            title: t("salesOpportunity.dateCreated"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (text) => <p>{text ? formatDateTimeTimezone(text) : ""}</p>,
        },
        {
            title: t("salesOpportunity.dateUpdated"),
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (text) => <p>{text ? formatDateTimeTimezone(text) : ""}</p>,
        },
    ];

    const OpportunityName = (props) => {
        return (
            <a onClick={() => {
                setFormData(props?.record)
                setIsAdd(false)
                setIsOpen(true)
            }}>{props.text}</a>
        )
    }

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize)
        setOffset(limit * (page - 1))
    }

    return (
        <>
            {(permissionUser?.salesOpportunity?.add?.add || permissionUser?.salesOpportunity?.edit?.edit) &&
                <SalesOpportunityForm
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    data={user}
                    mode={isAdd ? "Add" : "Edit"}
                    formData={formData}
                    type={userType}
                    refetchSalesOpportunity={refetchSalesOpportunity}
                />
            }

            <Row justify={"space-between"} className={"margin-bottom-0-75"}>
                <h1>{t("module.salesOpportunity")}</h1>
                {
                    permissionUser?.salesOpportunity?.add?.add &&
                    <Button onClick={() => {
                        setFormData({})
                        setIsOpen(true)
                        setIsAdd(true)
                    }}>{t("salesOpportunity.addSalesOpportunity")}</Button>
                }
            </Row>
            <TableAntDesign
                data={data?.data}
                isTableLocalSearch={true}
                tableName={"Sales Opportunity"}
                columns={columns}
                size={"small"}
                loading={loading}
                pagination={{
                    pageSize: limit,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                    total: data?.total,
                    showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                }}
            />
        </>
    )
}

export default DetailTable