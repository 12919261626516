import {Form, Input} from "antd";
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import FormErrors from "./formErrors";

const TextAreaInputFormItem = (props) => {
    const {
        layout,
        label,
        name,
        placeholder,
        prefix,
        value,
        externalErrorMessage,
        required,
        onChange,
        disabled,
        maxLength,
        showCount,
        labelCol,
        labelAlign,
        wrapperCol,
        rows,
        colon
    } = props;

    const {
        register,
        watch,
        setValue,
        formState: {touchedFields, isSubmitted, errors},
    } = useFormContext();

    const errorMessage = FormErrors.errorMessage(
        name,
        label,
        errors,
        touchedFields,
        isSubmitted,
        externalErrorMessage
    );

    useEffect(() => {
        register(name);
    }, [register, name]);

    useEffect(() => {
        if(value){
            setValue(name, value, {shouldValidate: true})
        }
    }, [value])

    return (
        <Form.Item
            {...layout}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            label={label}
            required={required}
            labelAlign={labelAlign}
            validateStatus={errorMessage ? "error" : "success"}
            help={errorMessage}
            colon={colon}
        >
            <Input.TextArea
                id={name}
                name={name}
                maxLength={maxLength}
                showCount={showCount}
                value={watch(name)}
                onChange={(e) => {
                    setValue(name, e?.target?.value, {shouldValidate: true});
                    onChange && onChange(e?.target?.value);
                }}
                placeholder={placeholder}
                prefix={prefix}
                disabled={disabled}
                rows={rows}
            />
        </Form.Item>
    );
}

TextAreaInputFormItem.defaultProps = {
    type: "text",
    required: false,
    disabled: false,
    labelCol: {span: 24, offset: 0},
    colon: false
};

TextAreaInputFormItem.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    prefix: PropTypes.object,
    suffix: PropTypes.string,
    addonBefore: PropTypes.object,
    addonAfter: PropTypes.object,
    value: PropTypes.string,
    type: PropTypes.string,
    externalErrorMessage: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    layout: PropTypes.object,
    maxLength: PropTypes.number,
    showCount: PropTypes.bool,
    rows: PropTypes.number,
    wrapperCol: PropTypes.object,
    labelAlign: PropTypes.string
};

export default TextAreaInputFormItem;
