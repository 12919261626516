import React, {useState} from "react";
import {Col, Empty, Form, message, Modal, Row, Spin} from "antd";
import {useUpdateIconListMutation} from "../../../../../../api/graphql/iconList";
import IconImage from "./iconImage";
import {useTranslation} from "react-i18next";
import useAuthorize from "../../../../../../_common/authorize";

const IconListing = (props) => {
    const {data, loading, permission, filter, iconFilter} = props
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [updatingIcon, setUpdatingIcon] = useState<any>()
    const {t} = useTranslation()

    const iconCategory = [
        {
            label: t("icon.iconLightActive"),
            name: "iconLightActive",
        },
        {
            label: t("icon.iconLightInactive"),
            name: "iconLightInactive",
        },
        {
            label: t("icon.iconDarkActive"),
            name: "iconDarkActive",
        },
        {
            label: t("icon.iconDarkInactive"),
            name: "iconDarkInactive",
        }
    ]

    const {updateIconList, loading:updateLoading} = useUpdateIconListMutation()
    const {systemErrorNotification} = useAuthorize()

    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        let filePath = file
        setPreviewImage(filePath);
        setPreviewOpen(true);
    };

    const uploadOnChange = (e, field, item) => {
        try{
            if (e?.file?.status === "done") {
                setUpdatingIcon({
                    label: item?.label,
                    field
                })
                updateIconList({
                    name: item?.name,
                    type: item?.type,
                    [field]: [{
                        path: e?.file?.response[0]?.link,
                        fileList: [e?.file]
                    }]
                })
            } else if (e?.file?.status === "error") {
                message.error(`${e?.file?.name} file upload failed.`);
            }
        }catch (e) {
            systemErrorNotification()
        }
    };

    const resetIcon = (item, currentIcon) => {
        try {
            const defaultIcon = currentIcon?.replace("icon", "defaultIcon")
            updateIconList({
                name: item?.name,
                type: item?.type,
                [currentIcon]: item?.[defaultIcon]
            })
        }catch (e) {
            systemErrorNotification()
        }
    }

    return (
        <div id={"icon-list"}>
            <Row style={{marginTop: 16}}>
                <Modal open={previewOpen} footer={null} onCancel={handleCancel} destroyOnClose>
                    <img style={{width: '100%'}} src={previewImage}/>
                </Modal>
                {
                    data?.length > 0 ? (
                        <Col span={24}>
                            <Form>
                                <Row gutter={[32, 32]} style={{width: "100%"}}>
                                    {data?.map(d => {
                                        return (
                                            <>
                                                {iconCategory.map((currentCategory) => {
                                                    const include = iconFilter?.length === 0 || iconFilter.every(filterItem => currentCategory?.label?.includes(filterItem))
                                                    const includeLabel = filter?.length === 0 || d?.label?.replace(" ", "")?.toLowerCase()?.includes(filter?.replace(" ", "")?.toLowerCase())
                                                    if (include && includeLabel) {
                                                        return (
                                                            <IconImage
                                                                data={d}
                                                                currentFormItem={currentCategory}
                                                                permission={permission}
                                                                handlePreview={handlePreview}
                                                                uploadOnChange={uploadOnChange}
                                                                resetIcon={resetIcon}
                                                                loading={updateLoading}
                                                                updatingIcon={updatingIcon}
                                                            />
                                                        )
                                                    }
                                                })}
                                            </>
                                        )
                                    })}
                                </Row>
                            </Form>
                        </Col>
                    ) : loading ? (
                        <Col span={24} style={{marginTop: "15%", textAlign: "center"}}>
                            <Spin size="large"/>
                        </Col>
                    ) : (
                        <Col span={24} style={{marginTop: "15%", textAlign: "center"}}>
                            <Empty/>
                        </Col>
                    )
                }
            </Row>
        </div>
    )
};

export default IconListing