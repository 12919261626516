import React from "react";
import {useTranslation} from "react-i18next";
import {Col, Divider, Row} from "antd";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import {horizontalFormLayout} from "../../../../../../components/form/formWrapper";

const LocalDepoBankDetailsForm = (props) => {
  const { t } = useTranslation();
  const { labelCol, formMode } = props;

  const fieldsList = [
    "bankName",
    "bankAddress",
    "swiftCode",
    "beneficiaryAccountName",
    "accountNumber",
    "beneficiaryAddress",
  ];

  return (
    <>
      <Divider />
      <h4>{t("Bank Details")}</h4>
      <Row gutter={10}>
        {fieldsList.map((fieldName, i) => (
          <Col key={i} className="gutter-row" xs={24} sm={12} md={12}>
            <InputFormItem
              name={`localDepoBankDetails.${fieldName}`}
              label={t(fieldName)}
              placeholder={t(fieldName)}
              layout={horizontalFormLayout}
              labelCol={labelCol}
              labelAlign="left"
              disabled={formMode === "view"}
            />
          </Col>
        ))}
      </Row>
      <Divider />
    </>
  );
};

export default LocalDepoBankDetailsForm;
