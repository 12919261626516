import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Popconfirm, Row, Space, Tooltip, Table} from "antd";
import {
    getNavigationRouteSetting,
    getNavigationRouteSettingCount,
    useDeleteNavigationRouteSettingMutation,
    useUpdateNavigationRouteSettingMutation,
} from "../../../../../../api/graphql/navigationRouteSetting";
import useAuthorize from "../../../../../../_common/authorize";
import { tableColumn } from "../../../function/tableColumn";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import NavigationRouteSettingModal from "./navigationRouteSettingModal";

const NavigationRouteSettingTable = (props) => {
  const { permission, type } = props;
  const { t } = useTranslation();

  const [mode, setMode] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [currentEditing, setCurrentEditing] = useState<any>({});

  const [orderBy, setOrderBy] = useState("createdAt_DESC");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [filter, setFilter] = useState({type: type});

  const { data: navigationRouteSetting } = getNavigationRouteSetting({filter: filter}, limit, offset, orderBy)
  const { data: navigationRouteSettingCount } = getNavigationRouteSettingCount({filter: filter})

  const { deleteNavigationRouteSetting } =
    useDeleteNavigationRouteSettingMutation();

  const { systemErrorNotification } = useAuthorize();

  const onChangePageHandler = (page, pageSize) => {
    setLimit(pageSize);
    setOffset(limit * (page - 1));
  };

  const openModal = (mode, record) => {
    setIsOpen(true);
    setMode(mode);
    setCurrentEditing(record);
  };

  const closeModal = () => {
    setIsOpen(false);
    setMode("");
    setCurrentEditing(null);
  };

  const deleteNavigationRoute = async (id) => {
    try {
      await deleteNavigationRouteSetting(id);
    } catch (e) {
      systemErrorNotification();
    } 
  };

  return (
    <>
    <div className={"margin-top-0-75"}>
      <Row justify={"space-between"}>
        <h4 className="margin-bottom-0">{t(`Navigation Route - ${type}`)}</h4>
        <Row justify={"end"} >
          <Space>
            {permission && (
              <Button onClick={() => openModal("add", null)}>
                {t("Add Navigation Route")}
              </Button>
            )}
          </Space>
        </Row>
      </Row>
        <Table
            className={"margin-top-0-75"}
            dataSource={navigationRouteSetting?.getNavigationRouteSetting?.data || []}
            scroll={{x: true}}
            columns={[
                ...tableColumn,
                {
                    title: t("common.action"),
                    key: "action",
                    width: 132,
                    render: (_, record: any) => (
                        <Space size="middle">
                            <Tooltip title={t("View")}>
                                <a onClick={() => openModal("view", record)}>
                                    <EyeOutlined />
                                </a>
                            </Tooltip>
                                <Tooltip title={t("Edit Navigation Route Setting")}>
                                    <a onClick={() => openModal("edit", record)}>
                                        <EditOutlined />
                                    </a>
                            </Tooltip>
                            <Tooltip title={t("Delete")}>
                                <Popconfirm
                                    placement="left"
                                    title={"Are you sure to delete this navigation route setting?"}
                                    description={"Delete navigation route setting"}
                                    onConfirm={() => {
                                        deleteNavigationRoute(record._id);
                                    }}
                                    okText={t("common.yes")}
                                    cancelText={t("common.no")}
                                >
                                    <a>
                                        <DeleteOutlined />
                                    </a>
                                </Popconfirm>
                            </Tooltip>
                        </Space>
                    ),
                },
            ]}
            size="small"
            pagination={{
                pageSize: limit,
                showSizeChanger: true,
                onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                total: navigationRouteSettingCount || 0,
                showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
            }}
        />
    </div>
    <NavigationRouteSettingModal mode={mode} item={currentEditing} type={type} isOpen={isOpen} closeModal={closeModal}/>
    </>
  );
};

export default NavigationRouteSettingTable;
