import axios from "axios";
import {getQueryStringParams} from "./_commonFunction";
import {getConfigByName} from "../../../config";
import {BRAND_NAME} from "../../../config/brandVariable";

export async function exportReport(requestBody) {
    // requestBody
    // fromDate, toDate, displayMode, user, data
    // e.g. data: {
    //     "fieldIdsToBeExported": [
    //         "65437208280dc0970ac3e3c5",
    //         "65437208280dc0970ac3e3c6"
    //       ]
    // }

    try {
        const brandConfig = getConfigByName(BRAND_NAME);
        return await axios
            .post(
                `${brandConfig?.backendURL}/api/reporting/export`,
                requestBody,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("ghost_a_t") || localStorage.getItem("a_t") || ""}`,
                        responseType: "blob",
                    },
                }
            )
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                console.error(error);
                return Promise.reject(error);
            });
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export async function getReportByID(reportId, params, filters?) {
    // Params
    // whiteLabel, fromDate, toDate, page, rows

    try {
        const response = await axios.post(`https://reporting.nextrades.ai/api/report/${reportId}?${getQueryStringParams(params)}`, filters);
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export async function getFields() {
    try {
        const response = await axios.get(`https://reporting.nextrades.ai/api/fields`);
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export async function getFieldByID(fieldId) {
    try {
        const response = await axios.get(`https://reporting.nextrades.ai/api/fields/${fieldId}`);
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export async function getBaseReportId(param) {
    // param e.g.
    // {
    //   "operations": [
    //     "I10+H5",
    //     "B1"
    //   ]
    // }
    // Response payload will have a baseReportId
    try {
        const response = await axios.post(`https://reporting.nextrades.ai/api/report/baseId`, param);
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export async function getReportRelationship(baseReportId) {
    // baseReportId to get from the getBaseReportId
    // Response payload will have a list of fields available for selection
    try {
        const response = await axios.get(`https://reporting.nextrades.ai/api/report/relationship?baseReportId=${baseReportId}`);
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export async function validateOperation(param) {
    // param e.g.
    // {
    //     "operation": "J4+(J4*0.3)_"
    // }
    // Response payload will have valid: true/false, & a message
    try {
        const response = await axios.post(`https://reporting.nextrades.ai/api/report/validate`, param);
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export async function createReport(param) {
    // param e.g.
    // {
    //   "name": "Test Report 1",
    //   "userId": 1,
    //   "brandName": "360",
    //   "editable": false,
    //   "columns": [
    //     {
    //       "name": "Test Column 1",
    //       "description": "This is test column",
    //       "operation": "H4+H5*I3+0.5"
    //     }
    //   ],
    //   "baseReportId": 8
    // }
    // Response payload will have ???
    try {
        const response = await axios.post(`https://reporting.nextrades.ai/api/reports`, param);
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

// export async function getClosedPositionReport(params, filters?) {
//   // Params
//   // whiteLabel, fromDate, toDate, page, rows

//   try {
//     const response = await axios.post(
//       `https://reporting.nextrades.ai/api/report/39?${getQueryStringParams(
//         params
//       )}`,
//       filters
//     );
//     return response.data;
//   } catch (error) {
//     console.log(error);
//     throw error;
//   }
// }

// export async function getOrdersReport(params) {
//   // Params
//   // whiteLabel, fromDate, toDate, page, rows

//   try {
//     const response = await axios.post(
//       "https://reporting.nextrades.ai/api/report/41",
//       { params }
//     );
//     return response.data;
//   } catch (error) {
//     console.log(error);
//     throw error;
//   }
// }

// export async function getDealsReport(params) {
//   // Params
//   // whiteLabel, fromDate, toDate, page, rows

//   try {
//     const response = await axios.post(
//       "https://reporting.nextrades.ai/api/report/40",
//       { params }
//     );
//     return response.data;
//   } catch (error) {
//     console.log(error);
//     throw error;
//   }
// }
