import {gql, useMutation, useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";

const GET_REFERRAL_SETTING = gql`
  query getReferralSetting {
    getReferralSetting {
      ... on ReferralSetting {
        referralLink
        liveReferralLink
        demoReferralLink
        referralDisplayType
        level
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getReferralSetting = () => {
    try {
        const {loading, error, data} = useQuery(GET_REFERRAL_SETTING, {
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t")
                }
            }
        });

        return {loading, error, data}
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
}

const UPDATE_REFERRAL_SETTING = gql`
  mutation updateReferralSetting($input: ReferralSettingInput) {
    updateReferralSetting(input: $input) {
      ... on ReferralSetting {
        referralLink
        liveReferralLink
        demoReferralLink
        referralDisplayType
        level
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useUpdateReferralSettingMutation = (): {
    updateReferralSetting: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const { t } = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updateReferralSettingMutation, {data, loading, error}] = useMutation(UPDATE_REFERRAL_SETTING);

        const updateReferralSetting = (input) => {
            return updateReferralSettingMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {updateReferralSetting}}) {
                    const typename = updateReferralSetting?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateReferralSetting?.errKey),
                            key: "updateReferralSetting",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateReferralSetting?.msg),
                            key: "updateReferralSetting",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Referral Setting Successfully"),
                            key: "updateReferralSetting",
                        });
                    }
                },
            });
        };

        return {updateReferralSetting, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {updateReferralSetting: null, data: null, loading: false, error: err};
    }
};

export {
    getReferralSetting,
    useUpdateReferralSettingMutation
}


