import React, {useEffect} from "react";
import {Col, Form, Input, Row} from "antd";
import {useTranslation} from "react-i18next";
import {Checkbox} from "antd/lib";

const SwapPanelMt5 = ({initialData}) => {
    const {t} = useTranslation()
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(initialData);
    }, [initialData])

    return (
        <>
            <Row gutter={[16, 16]} style={{marginBottom: "1%", marginTop: "2%"}}>
                <Col span={20} offset={2}>
                    <p>
                        Please set up parameters of charging swaps by symbols for the group.
                    </p>
                </Col>
            </Row>

            <Form form={form}>

                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={20} offset={2}>
                        <Form.Item
                            name={"swapMode"}
                            label={t("Type")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={10} offset={2}>
                        <Form.Item
                            name={"swapLong"}
                            label={t("Long positions")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={10} offset={2}>
                        <Form.Item
                            name={"swapShort"}
                            label={t("Short positions")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={10} offset={2}>
                        <Form.Item
                            name={""}
                            label={<span style={{color: "red"}}>{t("Days in year")}</span>}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={10} offset={2}>
                        <Form.Item
                            name={""}
                            label={<span style={{color: "red"}}>{t("Consider holidays")}</span>}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={20} offset={2}>
                        <Form.Item
                            name={"swap3Day"}
                            label={<span style={{color: "red"}}>{t("Swap multipliers")}</span>}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </>
    )
}

export default SwapPanelMt5