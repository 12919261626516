export const horizontalFormLayout = {
    labelCol: { 
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 6 },
        lg: { span: 4 },
    },
    wrapperCol: { 
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 18 },
        lg: { span: 16 },
    },
}
export const halfHorizontalFormLayout = {
    labelCol: { 
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 10 },
        lg: { span: 8 },
    },
    wrapperCol: { 
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 14 },
        lg: { span: 16 },
    },
}
export const horizontalLongLabelFormLayout = {
    labelCol: { 
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 10 },
        lg: { span: 8 },
    },
    wrapperCol: { 
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 14 },
        lg: { span: 12 },
    },
}
export const verticalFormLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
}
export const buttonFormLayout = {
    wrapperCol: { span: 8, offset: 16 },
}