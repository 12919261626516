import React from "react";
import {Button, Space} from "antd";
import {useTranslation} from "react-i18next";
import {exportToExcel} from "../../../function/common";
import {getDynamicVariable} from "../../../../../../api/graphql/dynamicVariable";

const VariableToolbar = (props) => {
    const {type, openAddModal, dynamicVariablePermission, loading} = props;
    const {t} = useTranslation();

    const {variableData} = getDynamicVariable({filter: null});

    const convertData = (data) => {
        try {
            if (!data || !(data?.length > 0)) {
                return [];
            }
            let result: any = [];

            let tempFile = data?.filter((item) => {
                return item?.variableType === "pdf" || item?.variableType === "image";
            });
            tempFile?.forEach((item) => {
                if (item?.fileObject && item?.fileObject?.length > 0) {
                    item?.fileObject?.forEach((x) => {
                        result?.push({
                            variableName: item?.name,
                            type: item?.type,
                            emailValue: `{{dynamicVariable:${item?.name}}}`,
                            displayName: x?.displayName ?? "",
                            fileType: item?.variableType,
                            text: "",
                            fileName: x?.fileName,
                            path: x?.path,
                            language: x?.language,
                            isActive: item?.isActive,
                        });
                    });
                } else {
                    result?.push({
                        variableName: item?.name,
                        type: item?.type,
                        emailValue: `{{dynamicVariable:${item?.name}}}`,
                        displayName: "",
                        fileType: item?.variableType,
                        text: "",
                        fileName: "",
                        path: "",
                        language: "",
                        isActive: item?.isActive,
                    });
                }
            });

            let tempText = data?.filter((item) => {
                return item?.variableType !== "pdf" && item?.variableType !== "image";
            });
            tempText.forEach((item) => {
                if (item?.textObject && item?.textObject?.length > 0) {
                    item?.textObject?.forEach((x) => {
                        result?.push({
                            variableName: item?.name,
                            type: item?.type,
                            emailValue: `{{dynamicVariable:${item?.name}}}`,
                            displayName: "",
                            fileType: item?.variableType,
                            text: x?.text,
                            fileName: "",
                            path: "",
                            language: x?.language,
                            isActive: item?.isActive,
                        });
                    });
                } else {
                    result?.push({
                        variableName: item?.name,
                        type: item?.type,
                        emailValue: `{{dynamicVariable:${item?.name}}}`,
                        displayName: "",
                        fileType: item?.variableType,
                        text: "",
                        fileName: "",
                        path: "",
                        language: "",
                        isActive: item?.isActive,
                    });
                }
            });
            return result;
        } catch (error) {}
    };

    return (
        <div className="display-flex margin-bottom-0-75">
            <span className="margin-left-auto">
                <Space>
                    <Button disabled={loading} onClick={() => exportToExcel(convertData(variableData?.getDynamicVariable?.data), "xlsx")}>
                        {t("Export")}
                    </Button>
                    {type === "custom" && dynamicVariablePermission?.add?.add && (
                        <Button disabled={loading} onClick={openAddModal}>
                            {t("Add Dynamic Variable")}
                        </Button>
                    )}
                </Space>
            </span>
        </div>
    );
};

export default VariableToolbar;
