import React, {useEffect, useState} from "react";
import BoxSetting_1 from "./view/layout1";
import {useGetBoxSetting} from "./function/useGetQueryHooks";
import {useGetGiftSetting} from "../giftSetting/function/useGetQueryHooks";
import {useGetBadgeSetting} from "../badgeSetting/function/useGetQueryHooks";
import {useGetRewardSetting} from "../rewardSetting/function/useGetQueryHooks";

const BoxSetting = () => {
    let component, layout = 1
    const [boxes, loading, fetchBoxes] = useGetBoxSetting()
    const [rewardOption, setRewardOption] = useState<any>([])

    //
    const [rewards, fetchRewardsSetting] = useGetRewardSetting()

    useEffect(() => {
        fetchBoxes()

        fetchRewardsSetting()
    }, [])

    const formatOptions = (data, type) => {
        return data?.map(d => ({
            value: d?._id,
            label: d?.displayName?.find(d => d?.language === localStorage.getItem("language"))?.content,
            type: type
        })) || []
    }

    const props = {boxes, loading, fetchBoxes, rewards}

    switch (layout) {
        case 1:
            component = <BoxSetting_1 {...props}/>
            break
    }

    return component
}

export default BoxSetting