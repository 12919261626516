import React, {useEffect, useState} from "react";
import {Col, Row, Spin} from "antd";
import {useTranslation} from "react-i18next";
import {getUserByIdAsync} from "../../../../../../../api/graphql/user";
import TaskClientDetails from "../../clientDetails";
import {formatMoney} from "../../../../../../accounts/tradingAccount/detail/function";
import AccountDetailCard from "../../../../../../accounts/tradingAccount/detail/view/layout1/component/accountDetailCard";
import {getTradingAccountMt5Detail, getTradingAccountMt5Transaction} from "../../../../../../../api/graphql/tradingAccountMt5";
import {formatDateTimeTimezone} from "../../../../../../../function/_common";

function TradingAccountMt5Layout_1(props) {
    const {data} = props

    const {t} = useTranslation()

    const [transactionDetail, setTransactionDetail] = useState<any>({});
    const [accDetails, setAccDetails] = useState<any>({});
    const [userDetails, setUserDetails] = useState<any>({});
    const [type, setType] = useState<any>(null);

    const getTradingAccountMt5Details = async () => {

        const transactionDetailGraphQL = await getTradingAccountMt5Transaction({
            _id: data?.payload?.id
        })

        const transaction = transactionDetailGraphQL?.getTradingAccountMt5Transaction?.data?.[0];

        if (transaction?.depositType) {
            setType("deposit")
        }
        {
            setType("withdrawal")
        }
        setTransactionDetail(transaction)

        if (transaction?.account?._id) {
            const tradingAccountDetail = await getTradingAccountMt5Detail(transaction?.account?._id)
            setAccDetails(tradingAccountDetail?.getTradingAccountDetail)

            const userDetail = await getUserByIdAsync(tradingAccountDetail?.getTradingAccountDetail?.user?._id)

            setUserDetails(userDetail?.getUserByID)
        }

    }

    useEffect(() => {
        getTradingAccountMt5Details()
    }, [data])

    const clarifyTransactionType = (type, deposit) => {
        let keyword
        if (deposit) {
            switch (type) {
                case "manual":
                    keyword = "Manual Deposit"
                    break;
                case "negativeBalanceProtection":
                    keyword = "Negative Balance Protection"
                    break;
                case "ewallet":
                    keyword = "Deposit via E-Wallet"
                    break;
            }
        } else {
            switch (type) {
                case "manual":
                    keyword = "Manual Withdrawal"
                    break;
                case "ewallet":
                    keyword = "Withdrawal to E-Wallet"
                    break;
            }
        }

        return keyword

    }

    return (
        <div id={"tradingAccountLayout"}>
            <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                    <Spin spinning={!accDetails?._id}>
                        <AccountDetailCard data={accDetails}
                                           traderId={accDetails?.traderId}
                                           viewOnly={true}/>
                    </Spin>
                    <br/>
                    {
                        userDetails && (
                            <TaskClientDetails data={userDetails}/>
                        )
                    }
                </Col>

                <Col xs={24} md={12}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <h3>{t("task.account_withdrawal_details")}</h3>
                        </Col>
                        <Col span={6}>
                            {t("task.account_id")}:
                        </Col>
                        <Col span={18}>
                            {transactionDetail?.account?.platform === "xtrader" ? transactionDetail?.account?.platformAccountId : transactionDetail?.account?.accountId}
                        </Col>
                        <Col span={6}>
                            {t("task.account_user")}:
                        </Col>
                        <Col span={18}>
                            {userDetails?.username}
                        </Col>
                        <Col span={6}>
                            {t("task.amount")}:
                        </Col>
                        <Col span={18}>
                            {formatMoney(transactionDetail?.amount)}
                        </Col>
                        <Col span={6}>
                            {t("task.type")}:
                        </Col>
                        <Col span={18}>
                            {type}
                        </Col>
                        <Col span={6}>
                            {type === "withdrawal" ? `${t("task.withdrawal_type")}:` : `${t("task.deposit_type")}:`}
                        </Col>
                        <Col span={18}>
                            {type === "withdrawal" ? clarifyTransactionType(transactionDetail?.withdrawalType, false) : clarifyTransactionType(transactionDetail?.depositType, true)}
                        </Col>
                        <Col span={6}>
                            {t("task.comment")}:
                        </Col>
                        <Col span={18}>
                            {transactionDetail?.comment}
                        </Col>
                        <Col span={6}>
                            {t("task.created_time")}:
                        </Col>
                        <Col span={18}>
                            {formatDateTimeTimezone(transactionDetail?.createdAt)}
                        </Col>
                        <Col span={6}>
                            {t("task.requested_by")}:
                        </Col>
                        <Col span={18}>
                            {data?.requestedBy?.username}
                        </Col>
                    </Row>
                </Col>

            </Row>


        </div>
    )

}

export default TradingAccountMt5Layout_1