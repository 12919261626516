import {gql, useMutation, useQuery} from "@apollo/client";
import {useSetRecoilState} from "recoil";
import {useTranslation} from "react-i18next";
import {notificationMessage} from "../../../recoil_state";
import {ApolloClientQuery} from "./_apolloClient";

const GET_PSP_ADJUTMENT_COUNT = gql`
    query getPspAdjustmentCount($filter: PspAdjustmentInput) {
        getPspAdjustmentCount(filter: $filter)
    }
`;

// const getPspAdjustmentCount = ({filter}) => {
//     try {
//         const {loading, error, data} = useQuery(GET_PSP_ADJUTMENT_COUNT, {
//             variables: {
//                 filter: {
//                     pspSettingId: filter?.pspSettingId || null,
//                     pspSettingCurrencyId: filter?.pspSettingCurrencyId || null,
//                     transactionId: filter?.transactionId || null,
//                     deskId: filter?.deskId || null,
//                     type: filter?.type || null,
//                     field: filter?.field || null,
//                     rate: filter?.rate || null,
//                     adjustmentDate: filter?.adjustmentDate || null,
//                     remark: filter?.remark || null,
//                     convertedAmount: filter?.convertedAmount || null,
//                 },
//             },
//             context: {
//                 headers: {
//                     authorization: "Bearer " + localStorage.getItem("a_t"),
//                 },
//             },
//         });

//         return {loading, error, data};
//     } catch (err: any) {
//         console.error(err);
//         return {data: null, loading: false, error: err};
//     }
// };

const getPspAdjustmentCount = async ({filter}) => {
    try {
        const execute = await ApolloClientQuery(
            GET_PSP_ADJUTMENT_COUNT,
            {
                filter: {
                    pspSettingId: filter?.pspSettingId || null,
                    pspSettingCurrencyId: filter?.pspSettingCurrencyId || null,
                    transactionId: filter?.transactionId || null,
                    deskId: filter?.deskId || null,
                    type: filter?.type || null,
                    field: filter?.field || null,
                    rate: filter?.rate || null,
                    adjustmentDate: filter?.adjustmentDate || null,
                    remark: filter?.remark || null,
                    convertedAmount: filter?.convertedAmount || null,
                },
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_PSP_ADJUTMENT_BY_ID = gql`
    query getPspAdjustmentByID($id: String!) {
        getPspAdjustmentByID(id: $id) {
            ... on PspAdjustment {
                _id
                id
                pspSettingId {
                    displayName
                    name
                }
                pspSettingCurrencyId {
                    currency
                }
                transactionId
                deskId {
                    name
                }
                details {
                    ... on PspSettlement {
                        transactionNo
                        settlementDate
                        convertedAmount
                        convertedFee
                    }
                    ... on EWalletDeposit {
                        transactionNo
                        transactionDate
                        convertedAmount
                        convertedFeeCompany
                    }
                    ... on EWalletWithdrawal {
                        transactionNo
                        transactionDate
                        convertedAmount
                        convertedFeeCompany
                    }
                }
                type
                field
                rate
                adjustmentDate
                remark
                amount
                convertedAmount
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

// const getPspAdjustmentById = (id) => {
//     try {
//         const {loading, error, data} = useQuery(GET_PSP_ADJUTMENT_BY_ID, {
//             variables: {
//                 id: id,
//             },
//             context: {
//                 headers: {
//                     authorization: "Bearer " + localStorage.getItem("a_t"),
//                 },
//             },
//         });

//         return {loading, error, data};
//     } catch (err: any) {
//         console.error(err);
//         return {data: null, loading: false, error: err};
//     }
// };

const getPspAdjustmentById = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            GET_PSP_ADJUTMENT_BY_ID,
            {
                id: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_PSP_ADJUTMENT = gql`
    query getPspAdjustment($filter: PspAdjustmentSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
        getPspAdjustment(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on PspAdjustmentArray {
                total
                data {
                    _id
                    id
                    pspSettingId {
                        displayName
                        name
                    }
                    pspSettingCurrencyId {
                        currency
                    }
                    transactionId
                    deskId {
                        name
                    }
                    details {
                        ... on PspSettlement {
                            transactionNo
                            settlementDate
                            convertedAmount
                            convertedFee
                            desks {
                                desk {
                                    _id
                                    name
                                }
                                percentage
                            }
                        }
                        ... on EWalletDeposit {
                            transactionNo
                            transactionDate
                            convertedAmount
                            convertedFeeCompany
                        }
                        ... on EWalletWithdrawal {
                            transactionNo
                            transactionDate
                            convertedAmount
                            convertedFeeCompany
                        }
                    }
                    type
                    field
                    rate
                    adjustmentDate
                    remark
                    amount
                    convertedAmount
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

// const getPspAdjustment = ({filter}, limit?, offset?, orderBy?) => {
//     try {
//         const {loading, error, data} = useQuery(GET_PSP_ADJUTMENT, {
//             variables: {
//                 filter: {
//                     pspSettingId: filter?.pspSettingId || null,
//                     pspSettingCurrencyId: filter?.pspSettingCurrencyId || null,
//                     transactionId: filter?.transactionId || null,
//                     deskId: filter?.deskId || null,
//                     type: filter?.type || null,
//                     field: filter?.field || null,
//                     rate: filter?.rate || null,
//                     adjustmentDate: filter?.adjustmentDate || null,
//                     remark: filter?.remark || null,
//                     convertedAmount: filter?.convertedAmount || null,
//                 },
//                 limit: limit || null,
//                 offset: offset || null,
//                 orderBy: orderBy || null,
//             },
//             context: {
//                 headers: {
//                     authorization: "Bearer " + localStorage.getItem("a_t"),
//                 },
//             },
//         });

//         return {loading, error, data};
//     } catch (err: any) {
//         console.error(err);
//         return {data: null, loading: false, error: err};
//     }
// };

const getPspAdjustment = async ({filter}, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_PSP_ADJUTMENT,
            {
                filter: {
                    pspSettingId: filter?.pspSettingId || null,
                    pspSettingName: filter?.pspSettingName || null,
                    pspSettingDisplayName: filter?.pspSettingDisplayName || null,
                    pspSettingCurrencyId: filter?.pspSettingCurrencyId || null,
                    pspSettingCurrencyName: filter?.pspSettingCurrencyName || null,
                    transactionId: filter?.transactionId || null,
                    deskId: filter?.deskId || null,
                    type: filter?.type || null,
                    field: filter?.field || null,
                    rate: filter?.rate || null,
                    adjustmentDate: filter?.adjustmentDate || null,
                    remark: filter?.remark || null,
                    amount: filter?.amount || null,
                    convertedAmount: filter?.convertedAmount || null,
                },
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_PSP_ADJUTMENT = gql`
    mutation addPspAdjustment($input: PspAdjustmentInput) {
        addPspAdjustment(input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on PspAdjustment {
                _id
                id
                pspSettingId {
                    displayName
                }
                pspSettingCurrencyId {
                    currency
                }
                transactionId
                deskId {
                    name
                }
                details {
                    ... on PspSettlement {
                        transactionNo
                        settlementDate
                        convertedAmount
                        convertedFee
                    }
                    ... on EWalletDeposit {
                        transactionNo
                        transactionDate
                        convertedAmount
                        convertedFeeCompany
                    }
                    ... on EWalletWithdrawal {
                        transactionNo
                        transactionDate
                        convertedAmount
                        convertedFeeCompany
                    }
                }
                type
                field
                rate
                adjustmentDate
                remark
                amount
                convertedAmount
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const addPspAdjustment = async (input) => {
    const variables = {
        input: {
            pspSettingId: input?.pspSettingId || null,
            pspSettingCurrencyId: input?.pspSettingCurrencyId || null,
            transactionId: input?.transactionId || null,
            deskId: input?.deskId || null,
            type: input?.type || null,
            field: input?.field || null,
            rate: input?.rate || null,
            adjustmentDate: input?.adjustmentDate || null,
            remark: input?.remark || null,
            convertedAmount: input?.convertedAmount || null,
        },
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(ADD_PSP_ADJUTMENT, variables, true);

    return execute;
};

const DELETE_PSP_ADJUSTMENT = gql`
    mutation deletePspAdjustment($id: String!) {
        deletePspAdjustment(id: $id) {
            ... on PspAdjustment {
                _id
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const deletePspAdjustment = async (id) => {
    const variables = {
        id: id,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(DELETE_PSP_ADJUSTMENT, variables, true);

    return execute;
};

export {getPspAdjustmentCount, getPspAdjustmentById, getPspAdjustment, addPspAdjustment, deletePspAdjustment};
