import React, {useEffect, useState} from "react";
import moment from "moment";
import {Columns} from "../../../function/tableColumn";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import {useTranslation} from "react-i18next";
import SalesOpportunityForm from "./salesOpportunityForm";
import {formatDateTimeTimezone} from "../../../../../../function/_common";

const TableView = (props) => {
    const {
        data,
        statusList,
        loading,
        setFilter,
        setStartDate,
        setEndDate,
        setDateKey,
        setOrderBy,
        tableName,
        limit,
        setLimit,
        setOffset,
        permissionUser,
        isViewArchive,
        batchUpdateRecord,
        setBatchUpdateRecord,
        filter,
        refetchSalesOpportunity
    } = props
    const {t} = useTranslation()

    const [dataSource, setDataSource] = useState<any>(data)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [formData, setFormData] = useState<any>()
    const columns = Columns()

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize)
        setOffset(limit * (page - 1))
    }

    const OpportunityName = (props) => {
        return (
            permissionUser?.salesOpportunity?.edit?.edit ?
                <a onClick={() => {
                    setFormData(props?.record)
                    setIsOpen(true)
                }}>{props.text}</a>
                : <p>{props.text}</p>
        )
    }

    const formatData = async (data) => {
        return await data?.map(d => {
            return {
                ...d,
                createdAt: d?.createdAt ? formatDateTimeTimezone(d?.createdAt) : "",
                updatedAt: d?.updatedAt ? formatDateTimeTimezone(d?.updatedAt) : "",
            }
        })
    }

    useEffect(() => {
        formatData(data?.data)?.then(data => setDataSource(data))
    }, [data?.data])

    const onRowSelection = (selectedRowKeys, selectedRows) => {
        setBatchUpdateRecord(selectedRows);
    };

    return (
        <>
            {(permissionUser?.salesOpportunity?.add?.add || permissionUser?.salesOpportunity?.edit?.edit) &&
            <SalesOpportunityForm
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                mode={"Edit"}
                formData={formData}
                type={formData?.userType}
                statusList={statusList}
                refetchSalesOpportunity={refetchSalesOpportunity}
            />}
            <TableAntDesign data={dataSource}
                            tableName={tableName}
                            columns={
                                columns.map(column => {
                                    if (column.dataIndex === "salesOpportunityName") {
                                        return {
                                            ...column,
                                            render: (text, record) => <OpportunityName text={text} record={record}/>
                                        };
                                    }
                                    // else if (column.dataIndex === "status") {
                                    //     return {
                                    //         ...column,
                                    //         filters: status,
                                    //     }
                                    // }
                                    return column;
                                })
                            }
                            filterValue={filter}
                            size={"small"}
                            loading={loading}
                            filter={setFilter}
                            startDate={setStartDate}
                            endDate={setEndDate}
                            dateKey={setDateKey}
                            order={setOrderBy}
                            selectionType={permissionUser?.clientsUser?.batchAction && !isViewArchive ? "checkbox" : undefined}
                            onRowSelectionHandler={onRowSelection}
                            selectedRowKeys={batchUpdateRecord?.map((d) => d?._id)}
                            pagination={{
                                pageSize: limit,
                                showSizeChanger: true,
                                onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                                total: data?.total,
                                showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                            }}
            />
        </>
    )
}

export default TableView