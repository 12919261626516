import {useTranslation} from "react-i18next"
import React, {useEffect, useState} from "react"
import {FormProvider, useForm} from "react-hook-form"
import * as yup from "yup"
import {yupResolver} from "@hookform/resolvers/yup"
import {Button, Form} from "antd"
import {useRecoilValue, useSetRecoilState} from "recoil"
import {CloseOutlined, SaveOutlined} from "@ant-design/icons"
import yupFormSchema from "../../../../../../components/yup/yupFormSchema"
import SelectFormItem from "../../../../../../components/form/selectFormItem"
import {horizontalFormLayout} from "../../../../../../components/form/formWrapper"
import InputFormItem from "../../../../../../components/form/inputFormItem"
import {useCreateReportingCategoryMutation, useUpdateReportingCategory} from "../../../../../../api/graphql/reportingCategory"
import SwitchFormItem from "../../../../../../components/form/switchFormItem"
import {useReportingCategoryFunction} from "../../../function"
import {notificationMessage} from "../../../../../../../recoil_state"
import {reportingCategoryState} from "../../../../_general/state"

const CategoryForm = (props) => {
    const {mode, item, close, reportOptions} = props
    const {t} = useTranslation()
    const setNotificationMessage = useSetRecoilState(notificationMessage)
    const [isRequesting, setIsRequesting] = useState(false)
    const [defaultReportOptions, setDefaultReportOptions] = useState<any[]>([])
    const reportingCategory = useRecoilValue<any>(reportingCategoryState)

    const {getReportCategory} = useReportingCategoryFunction()
    const {createReportingCategory} = useCreateReportingCategoryMutation()
    const {updateReportingCategory} = useUpdateReportingCategory()

    const yupSchema = yup.object().shape({
        name: yupFormSchema.string("name", {required: true}),
        label: yupFormSchema.string("label", {required: true}),
        isEnabled: yupFormSchema.boolean("isEnabled"),
        isDefault: yupFormSchema.boolean("isDefault"),
    })

    const [initialValues] = useState(() => ({
        name: item?.name || "",
        label: item?.label || "",
        systemReports: item?.reports?.filter((v) => v?.isSystem && reportOptions?.some((y) => y?.value === v?.reportId))?.map((x) => x?.reportId) || [],
        reports: item?.reports?.filter((v) => !v?.isSystem && reportOptions?.some((y) => y?.value === v?.reportId))?.map((x) => x?.reportId) || [],
        isEnabled: item?.isEnabled || false,
        isDefault: item?.isDefault || false,
        defaultReport: item?.reports?.find((v) => v?.isDefault)?.reportId || "",
    }))

    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
        defaultValues: initialValues,
    })

    const setupLabel = (e) => {
        form.setValue("label", "reportingCategory." + e?.toLowerCase()?.replaceAll(" ", "_"))
    }

    const onSubmit = async (value) => {
        try {
            setIsRequesting(true)
            let amendedValue = {...value}
            amendedValue.reports = [...value.systemReports, ...value.reports]
            delete amendedValue?.label
            delete amendedValue?.systemReports
            if (mode === "Add") {
                await handleAdd(amendedValue)
            } else if (mode === "Edit") {
                await handleEdit(amendedValue)
            }
            close()
        } catch (error) {
            console.log(error)
        } finally {
            setIsRequesting(false)
        }
    }

    const handleAdd = async (value) => {
        try {
            const response = await createReportingCategory(value)
            const typename = response?.data?.createReportingCategory?.__typename
            if (typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.data?.createReportingCategory?.errKey),
                    key: "createReportingCategory",
                })
            } else if (typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.data?.createReportingCategory?.msg),
                    key: "createReportingCategory",
                })
            } else {
                getReportCategory({isEnabled: null})
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Add Successfully"),
                    key: "createReportingCategory",
                })
            }
            return response?.data?.createReportingCategory?.__typename === "ReportingCategory"
        } catch (error) {}
    }

    const handleEdit = async (value) => {
        try {
            if (value?.name === item?.name) {
                delete value?.name
            }
            const response = await updateReportingCategory({
                id: item?._id,
                ...value,
            })
            const typename = response?.data?.updateReportingCategory?.__typename
            if (typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.data?.updateReportingCategory?.errKey),
                    key: "updateReportingCategory",
                })
            } else if (typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.data?.updateReportingCategory?.msg),
                    key: "updateReportingCategory",
                })
            } else {
                getReportCategory({isEnabled: null})
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Update Successfully"),
                    key: "updateReportingCategory",
                })
            }
            return response?.data?.updateReportingCategory?.__typename === "ReportingCategory"
        } catch (error) {}
    }

    useEffect(() => {
        const options = item?.reports
            ?.filter((v) => reportOptions?.some((y) => y?.value === v?.reportId))
            .map((d) => {
                const label = reportOptions?.find((o) => o?.value === d?.reportId)?.label
                return {
                    label: label,
                    value: d?.reportId,
                }
            })
        setDefaultReportOptions(options)
    }, [item?.reports])

    const reportsWithCategory = reportingCategory?.flatMap((d) => d?.reports?.map((e) => e?.reportId)) ?? []
    const nonSystemReportsOfTheCategory = reportingCategory
        ?.find((d) => d?._id === item?._id)
        ?.reports?.filter((r) => !r.isSystem)
        ?.map((e) => e?.reportId)
    const amendedReportOptions = reportOptions?.filter((report) => report?.level >= 2 && (nonSystemReportsOfTheCategory?.includes(report?.value) || !reportsWithCategory.includes(report?.value)))

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="form-1">
                <InputFormItem
                    name={"name"}
                    label={t("Category Name")}
                    placeholder={t("Category Name")}
                    labelCol={{span: 8, offset: 0}}
                    wrapperCol={{span: 16, offset: 0}}
                    layout={horizontalFormLayout}
                    onChange={setupLabel}
                    required
                    disabled={isRequesting || item?.isSystem}
                />
                <InputFormItem name={"label"} label={t("Label")} placeholder={t("Label")} labelCol={{span: 8, offset: 0}} wrapperCol={{span: 16, offset: 0}} layout={horizontalFormLayout} disabled required />
                {mode === "Edit" && (
                    <SelectFormItem
                        name={"systemReports"}
                        label={t("System Reports")}
                        labelCol={{span: 8, offset: 0}}
                        wrapperCol={{span: 16, offset: 0}}
                        layout={horizontalFormLayout}
                        options={reportOptions}
                        optionFilterProp="label"
                        mode="multiple"
                        disabled={true}
                    />
                )}
                <SelectFormItem
                    name={"reports"}
                    label={t("Reports")}
                    labelCol={{span: 8, offset: 0}}
                    wrapperCol={{span: 16, offset: 0}}
                    layout={horizontalFormLayout}
                    options={amendedReportOptions}
                    optionFilterProp="label"
                    mode="multiple"
                    disabled={isRequesting}
                />
                <SwitchFormItem name={"isEnabled"} label={t("Enabled")} labelCol={{span: 8, offset: 0}} size="small" disabled={isRequesting} />
                <SwitchFormItem name={"isDefault"} label={t("Set to Default")} labelCol={{span: 8, offset: 0}} size="small" disabled={isRequesting} />
                {form.getValues("isDefault") && (
                    <SelectFormItem
                        name={"defaultReport"}
                        label={t("Default Report")}
                        labelCol={{span: 8, offset: 0}}
                        wrapperCol={{span: 16, offset: 0}}
                        layout={horizontalFormLayout}
                        options={defaultReportOptions}
                        optionFilterProp="label"
                        disabled={isRequesting}
                    />
                )}
                <Form.Item className="text-align-right" style={{paddingTop: "12px"}}>
                    <Button disabled={isRequesting} onClick={close} icon={<CloseOutlined />} className="button-cancel-1">
                        {t("common.cancel")}
                    </Button>
                    <Button loading={isRequesting} onClick={form.handleSubmit(onSubmit)} icon={<SaveOutlined />} className="button-submit-1">
                        {t("common.submit")}
                    </Button>
                </Form.Item>
            </form>
        </FormProvider>
    )
}

export default CategoryForm
