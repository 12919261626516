import React, {useEffect, useState} from "react";
import {Button, Card, Col, Popconfirm, Row, Space, Spin, Tag, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {formatMoney} from "../../../../../../../../accounts/tradingAccount/detail/function";
import {DeleteOutlined, WarningTwoTone} from "@ant-design/icons";
import {useDeleteTradingAccountMutation} from "../../../../../../../../../api/graphql/tradingAccount";
import useAuthorize from "../../../../../../../../../_common/authorize";
import {formatDateTimeTimezone} from "../../../../../../../../../function/_common";

const AccountDetailCard = (props) => {
    const {account} = props
    const {t} = useTranslation()

    const [socketData, setSocketData] = useState<any>({});
    const [socket, setSocket] = useState<any>(null);
    const [isDeleted, setIsDeleted] = useState<any>(false);

    const {deleteTradingAccount} = useDeleteTradingAccountMutation()
    const {systemErrorNotification} = useAuthorize()

    useEffect(() => {
        const newSocket = new WebSocket('wss://socket2-demo.nextrades.ai/traderInfo?traderIds=' + account?.traderId);

        newSocket.addEventListener('message', (event) => {
            const rawData = event.data;
            const jsonStartIndex = rawData.indexOf('{');
            const jsonString = rawData.substring(jsonStartIndex);
            const receivedData = JSON.parse(jsonString);
            setSocketData(receivedData);
        });

        setSocket(newSocket);

        return () => {
            newSocket.close();
        };
    }, [account?.traderId]);

    const onDelete = async () => {
        try {
            const response = await deleteTradingAccount(account?._id)
            if (response) {
                setIsDeleted(response?.deleteTradingAccount?.isDeleted)
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    return (
        <Col xs={24} md={12}>
            {isDeleted === false &&
                (
                    <Card
                        className={`account-detail-card ${account?.mode}`}
                        size={"small"}
                    >
                        <Row className={"margin-bottom-0-3"}>
                            <strong>{t("tradingAccount.accountId")}: &nbsp;
                                <a style={{fontSize: 12}}
                                   onClick={() => window.open("/account/trading-account/detail?id=" + account?._id)}>{account?.accountId}</a>
                            </strong>
                            <Tag className={"margin-left-0-5"} bordered={false}
                                 color={account?.product?.server?.platform === "cTrader" ? "geekblue" : "gold"}>{account?.product?.server?.platform?.toUpperCase()}</Tag>
                            <Tag bordered={false}
                                 color={account?.product?.mode === "live" ? "lime" : "green"}>
                                {t(`accountSetting.server_${account?.mode}`)}
                            </Tag>
                            {
                                account?.product?.productType &&
                                <Tag bordered={false}>
                                    {t(`product.productType_${account?.product?.productType?.replace("Demo", "")?.replace("Live", "")}`)}
                                </Tag>
                            }
                            {
                                account?.product?.productType?.includes("investor") &&
                                <Tag bordered={false}>
                                    {account?.investorAccountSetting?.status}
                                </Tag>
                            }
                            <Space className={"margin-left-auto"}>
                                {account?.isSuspicious &&
                                    <Tooltip title={t("common.suspicious")}>
                                        <p style={{fontSize: 12, marginBottom: 0}}><WarningTwoTone twoToneColor={"#FFCC00"}/></p>
                                    </Tooltip>}
                                <Tooltip title={t("common.delete")}>
                                    <Popconfirm
                                        placement="left"
                                        title={t("tradingAcc.delete.popconfirm.title")}
                                        description={t("tradingAcc.delete.popconfirm.description")}
                                        onConfirm={onDelete}
                                        okText={t("common.yes")}
                                        cancelText={t("common.no")}
                                    >
                                        <Button
                                            type={"link"}
                                            icon={<DeleteOutlined/>}
                                            danger
                                            size={"small"}
                                        />
                                    </Popconfirm>
                                </Tooltip>
                            </Space>
                        </Row>
                        <Row className={"description"}>
                            <Col span={12}>
                                <label>{t("tradingAcc.balance")}</label>
                                <p className={"margin-bottom-0-3"}>{socketData?.balanceDouble !== undefined ? formatMoney(socketData?.balanceDouble) : !isNaN(account?.totalBalance) ? formatMoney(account?.totalBalance) :
                                    <Spin style={{marginLeft: "15px"}} size="small"/>}</p>

                                <label>{t("tradingAcc.credit")}</label>
                                <p className={"margin-bottom-0-3"}>{socketData?.totalBonus !== undefined ? formatMoney(socketData?.totalBonus) : !isNaN(account?.credit) ? formatMoney(account?.credit) :
                                    <Spin style={{marginLeft: "15px"}} size="small"/>}</p>

                                <label>{t("tradingAcc.tradingVoucher")}</label>
                                <p className={"margin-bottom-0-3"}>{account?.tradingVoucher !== undefined ? formatMoney(account?.tradingVoucher) :
                                    <Spin style={{marginLeft: "15px"}} size="small"/>}</p>

                                <label>{t("tradingAcc.equity")}</label>
                                <p className={"margin-bottom-0-3"}>{socketData?.equity !== undefined ? formatMoney(socketData?.equity) : !isNaN(account?.equity) ? formatMoney(account?.equity) :
                                    <Spin style={{marginLeft: "15px"}} size="small"/>}</p>

                                <label>{t("tradingAcc.registrationTime")}</label>
                                <p className={"margin-bottom-0-3"}>{formatDateTimeTimezone(account?.registrationDatetime)}</p>

                                <label>{t("tradingAcc.firstDepositTime")}</label>
                                <p className={"margin-bottom-0-3"}>{account?.depositDateTime || "-"}</p>

                                <label>{t("tradingAccount.commissionRules")}</label>
                                <p className={"margin-bottom-0-3"}>
                                    {
                                        account?.commRulePopulate && account?.commRulePopulate?.length > 0 ? account?.commRulePopulate?.map(d => {
                                            const name = (d?.commRuleType === "commissionRule" ? d?.rule?.name : d?.group?.name) || ""
                                            const isTooltipNeeded = name.length > 20
                                            const truncatedRuleName = name.length > 20 ? name.slice(0, 20) + "..." : name;

                                            return (
                                                <Tooltip title={isTooltipNeeded ? name : undefined}>
                                                    <Tag>{truncatedRuleName}</Tag>
                                                </Tooltip>
                                            )
                                        }) : "-"
                                    }
                                </p>

                            </Col>
                            <Col span={12}>
                                <label>{t("tradingAcc.margin")}</label>
                                <p className={"margin-bottom-0-3"}>{socketData?.marginUsed !== undefined ? formatMoney(socketData?.marginUsed) : !isNaN(account?.margin) ? formatMoney(account?.margin) :
                                    <Spin style={{marginLeft: "15px"}} size="small"/>}</p>
                                <label>{t("tradingAcc.availableMargin")}</label>
                                <p className={"margin-bottom-0-3"}>{socketData?.freeMargin !== undefined ? formatMoney(socketData?.freeMargin) : !isNaN(account?.availableMargin) ? formatMoney(account?.availableMargin) :
                                    <Spin style={{marginLeft: "15px"}} size="small"/>}</p>

                                <label>{t("tradingAcc.marginLevel")}</label>
                                <p className={"margin-bottom-0-3"}>{socketData?.marginLevel !== undefined ? formatMoney(socketData?.marginLevel) : !isNaN(account?.marginLevel) ? formatMoney(account?.marginLevel) :
                                    <Spin style={{marginLeft: "15px"}} size="small"/>}</p>

                                <label>{t("tradingAcc.unrealizedNetProfit")}</label>
                                <p className={"margin-bottom-0-3"}>{socketData?.unrealizedNetProfit !== undefined ? formatMoney(socketData?.unrealizedNetProfit) : "-"}</p>

                                <label>{t("tradingAcc.leverage")}</label>
                                <p className={"margin-bottom-0-3"}>{account?.leverage}</p>

                                <label>{t("tradingAcc.product")}</label>
                                <p className={"margin-bottom-0-3"}>{account?.product?.name || "-"}</p>
                            </Col>
                        </Row>
                    </Card>
                )}
        </Col>
    )
}

export default AccountDetailCard