import React, {useCallback, useEffect, useState} from "react";
import {Col, Form, Modal, Row, Select, Switch, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {labelCol, validateMessages} from "../../../../../function";
import {useRecoilValue} from "recoil";
import {generalListFieldGroupState, userVerificationConfigListState} from "../../../../../state";
import {useFlowBuilderFunction} from "../../../../../function/flowBuilderFunction";
import field from "../../generalList/field";
import {InfoCircleOutlined} from "@ant-design/icons";

const FieldModalForm = (props) => {
    const {isAdd, isOpen, close, item, isView} = props
    const {t} = useTranslation()
    const config = useRecoilValue<any>(userVerificationConfigListState)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [form] = Form.useForm()
    const {createUserVerificationFieldFunction, updateUserVerificationFieldFunction} = useFlowBuilderFunction()
    const fieldList = useRecoilValue<any>(generalListFieldGroupState)
    const [currentFieldType, setCurrentFieldType] = useState<any>()
    const [linkFieldList, setLinkFieldList] = useState<any>()
    const [isAbleRepresentativeInfo, setIsAbleRepresentativeInfo] = useState<boolean>()
    const [representativeChecked, setRepresentativeChecked] = useState<any>(item?.data?.isRepresentativeInfo)
    const isGroup = !((currentFieldType?.fieldType && currentFieldType?.fieldType !== "group") || (item?.data?.field?.fieldType && item?.data?.field?.fieldType !== "group"))

    useEffect(() => {
        setIsAbleRepresentativeInfo(((currentFieldType?.fieldType && currentFieldType?.fieldType === "group" && currentFieldType?.type === "multiple") || (item?.data?.field?.fieldType && item?.data?.field?.fieldType === "group" && item?.data?.field?.fieldGroupType === "multiple")))
    },[currentFieldType, item])

    const linkFieldRequiredValue = Form.useWatch('isLinkRequired', form);

    const excludeExistingField = useCallback(() => {
        const option = fieldList?.map(f => {
            const match = item?.document?.fieldList?.find(d => d?.field?._id === f?._id)
            if (!match) {
                return {label: t(f?.label), value: f?._id}
            }
        })?.filter(d => d !== undefined)

        return option ? option : []
    }, [fieldList, item])

    const processLinkFieldList = useCallback(() => {
        const fieldType = currentFieldType?.fieldType
        if (fieldType === "text" || fieldType === "textArea" || fieldType === "select" || fieldType === "radio") {
            setLinkFieldList(config?.textLinkField)
        } else if (fieldType === "date") {
            setLinkFieldList(config?.dateLinkField)
        } else if (fieldType === "image" || fieldType === "document" || fieldType === "video") {
            setLinkFieldList(config?.fileLinkField)
        } else if (fieldType === "mobile") {
            setLinkFieldList(config?.mobileLinkField)
        } else {
            setLinkFieldList([])
        }
    }, [currentFieldType])

    useEffect(() => {
        processLinkFieldList()
    }, [currentFieldType])

    const onSubmit = async (value) => {
        setSubmitLoading(true)
        if (isAdd) {
            await createUserVerificationFieldFunction({
                ...value,
                groupSetting: item?.group?._id,
                document: item?.document?.document?._id,
                isRepresentativeInfo: currentFieldType?.fieldType && currentFieldType?.fieldType === "group" && currentFieldType?.type === "multiple" && value?.isRepresentativeInfo
            }, item?.currentCountry)
        } else {
            delete value["field"]
            await updateUserVerificationFieldFunction(
                item?.data?._id, value, item?.currentCountry)
        }
        setSubmitLoading(false)
        close()
    }

    useEffect(() => {
        setCurrentFieldType({fieldType: item?.data?.field?.fieldType, type: item?.data?.field?.fieldGroupType} || null)
        form.resetFields()
    }, [isOpen])

    const setCurrentField = (val) => {
        const field = fieldList?.find(d => d?._id === val)
        setCurrentFieldType({fieldType: field?.fieldType, type: field?.fieldGroupType})
    }

    return (
        <Modal
            title={isAdd ? t("accountVerification.addField") : isView ? t("accountVerification.field") : t("accountVerification.updateField")}
            open={isOpen}
            onCancel={close}
            onOk={form.submit}
            cancelText={t("common.cancel")}
            okText={isAdd ? t("common.add") : t("common.update")}
            okButtonProps={{loading: submitLoading}}
            footer={isView ? null : undefined}
            destroyOnClose={true}
        >
            <Form form={form} onFinish={onSubmit} validateMessages={validateMessages}>
                <Form.Item
                    labelCol={labelCol}
                    label={t("accountVerification.field")}
                    name={"field"}
                    rules={[{required: true}]}
                    initialValue={item?.data?.field?._id}
                >
                    <Select
                        placeholder={t("accountVerification.placeholder.selectField")}
                        options={isAdd ? excludeExistingField() : fieldList?.map(d => ({
                            label: d?.name,
                            value: d?._id
                        }))}
                        showSearch
                        optionFilterProp={"label"}
                        onSelect={(val) => setCurrentField(val)}
                        disabled={!isAdd || isView}
                    />
                </Form.Item>
                {linkFieldList &&
                <Row gutter={16} align={"bottom"}>
                    <Col flex={"auto"}>
                        <Form.Item
                            labelCol={labelCol}
                            label={t("accountVerification.linkField")}
                            name={"linkField"}
                            initialValue={item?.data?.linkField}
                        >
                            <Select
                                placeholder={t("accountVerification.placeholder.selectLinkField")}
                                options={linkFieldList}
                                showSearch
                                optionFilterProp={"label"}
                                onSelect={() => {
                                    form.setFieldValue("isLinkRequired", true)
                                }}
                                disabled={isView}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            // label={t("accountVerification.isLinkRequired")}
                            name={"isLinkRequired"}
                            colon={false}
                            style={{textAlign: "right"}}
                            initialValue={item?.data?.isLinkRequired}
                            className={"margin-top-0-75"}
                        >
                            <Switch checked={linkFieldRequiredValue} disabled={isView}/>
                        </Form.Item>
                    </Col>
                </Row>
                }
                <>
                    <Form.Item
                        label={t("accountVerification.isEnabled")}
                        name={"isEnabled"}
                        colon={false}
                        style={{textAlign: "right"}}
                        initialValue={isAdd ? true : item?.data?.isEnabled}
                        className={"margin-top-0-75"}
                    >
                        <Switch defaultChecked={item?.data?.isEnabled} disabled={isView}/>
                    </Form.Item>
                    <Form.Item
                        label={t("accountVerification.isRequired")}
                        name={"isRequired"}
                        colon={false}
                        style={{textAlign: "right"}}
                        initialValue={item?.data?.isRequired}
                        className={"margin-top-0-75"}
                    >
                        <Switch defaultChecked={item?.data?.isRequired} disabled={isView}/>
                    </Form.Item>
                    <Form.Item
                        label={t("accountVerification.hasExpiredDate")}
                        name={"hasExpiredDate"}
                        colon={false}
                        style={{textAlign: "right"}}
                        initialValue={item?.data?.hasExpiredDate}
                        className={"margin-top-0-75"}
                    >
                        <Switch defaultChecked={item?.data?.hasExpiredDate} disabled={isView}/>
                    </Form.Item>
                    { isAbleRepresentativeInfo &&
                        <Form.Item
                        label={<>
                            {t("accountVerification.isRepresentativeInfo")}
                            <Tooltip title={t("Field must be field group type and multiple to enable.")}>
                                <InfoCircleOutlined className={"margin-left-0-25"} size={10}/>
                            </Tooltip>
                        </>}
                        name={"isRepresentativeInfo"}
                        colon={false}
                        style={{textAlign: "right"}}
                        initialValue={item?.data?.isRepresentativeInfo}
                        className={"margin-top-0-75"}
                    >
                        <Switch defaultChecked={item?.data?.isRepresentativeInfo}
                                onChange={(checked) => setRepresentativeChecked(checked)}
                                checked={representativeChecked && isAbleRepresentativeInfo}
                                disabled={isView || !isAbleRepresentativeInfo}/>
                    </Form.Item>
                    }
                </>
            </Form>
        </Modal>
    )
}

export default FieldModalForm