import React from "react";
import {useTranslation} from "react-i18next";
import {Modal} from "antd";
import RoleTemplateForm from "./roleTemplateForm";

const RoleTemplateModal = (props) => {
    const {
        mode,
        item,
        isOpen,
        closeModal,
        parentPermissionArrayData,
        targetParent,
        setTargetParent,
        roleTemplateList,
        networkTreeDownlineData,
        setNetworkList,
        editType,
        setEditType,
    } = props;
    const {t} = useTranslation();

    const getTitle = (): String => {
        let titleText
        if (mode === "view") {
            titleText = `${t(`common.${mode}`)} ${t("user.role")} - ${item?.name}`;
        } else if (editType === "both" && mode !== "view") {
            titleText = t(`roleSetting.addRole`);
        } else {
            titleText = `${t(`common.${mode}`)} ${t(`common.${editType}`)} - ${item?.name}`;
        }

        return titleText;
    };

    return (
        <Modal
            getContainer={"#roleTemplate"}
            title={getTitle()}
            open={isOpen}
            width={"80%"}
            footer={null}
            destroyOnClose
            onCancel={closeModal}
            maskClosable={false}
            className="roleSettingsModal"
        >
            <>
                <RoleTemplateForm
                    mode={mode}
                    item={item}
                    closeModal={closeModal}
                    parentPermissionArrayData={parentPermissionArrayData}
                    targetParent={targetParent}
                    setTargetParent={setTargetParent}
                    roleTemplateList={roleTemplateList}
                    networkTreeDownlineData={networkTreeDownlineData}
                    setNetworkList={setNetworkList}
                    editType={editType}
                    setEditType={setEditType}
                />
            </>
        </Modal>
    );
};

export default RoleTemplateModal;
