import React from "react";
import {Button, Col, Form, Input, message, Popconfirm, Row, Select, Space} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {CurrencyList} from "../../../../../../../../../../config/currencyList";
import {useDeleteBankInformationMutation, useUpdateBankInformationMutation} from "../../../../../../../../../api/graphql/bankInformation";

const BankForm = (props) => {
    const {
        data,
        editable,
        setEditable,
        onSubmitHandler,
        updateLoading
    } = props;
    const {t} = useTranslation();

    const [form] = Form.useForm()
    const {deleteBankInformation, loading: deleteLoading} = useDeleteBankInformationMutation();

    const currencyOptions = Object.entries(CurrencyList).map(([code, data]) => ({
        label: t(`${code} - ${data?.name}`),
        value: code,
    }));

    return (
        <Form
            onFinish={onSubmitHandler}
            style={{width: "100%"}}
            labelCol={{flex: "150px"}}
            labelWrap
            form={form}
            colon={false}
        >
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    <Row justify={"space-between"}>
                        <h3 className={"margin-bottom-0"}>
                            {t(data?.formData?.bank)}
                        </h3>
                        <Space>
                            {editable === data?._id ? (
                                    <>
                                        <Popconfirm
                                            placement="left"
                                            title={t("clientUser.bank.delete.popconfirm.title")}
                                            description={t("clientUser.bank.delete.popconfirm.description")}
                                            onConfirm={async () => {
                                                await deleteBankInformation(data?._id)
                                            }}
                                            okText="Yes"
                                            cancelText="No"
                                            okButtonProps={{loading: deleteLoading}}
                                        >
                                            <Button
                                                size={"small"}
                                                icon={<DeleteOutlined/>}
                                                danger
                                                type={"link"}
                                                disabled={deleteLoading || updateLoading}
                                            />
                                        </Popconfirm>
                                        <Button
                                            onClick={() => {
                                                setEditable("")
                                            }}
                                            disabled={deleteLoading || updateLoading}
                                        >
                                            {t("common.cancel")}
                                        </Button>
                                        <Button loading={updateLoading} type={"primary"} onClick={form.submit} disabled={deleteLoading || updateLoading}>{t("common.submit")}</Button>
                                    </>
                                ) :
                                <Button
                                    type={"link"}
                                    onClick={() => setEditable(data?._id)}
                                    icon={<EditOutlined/>}
                                />
                            }
                        </Space>
                    </Row>
                </Col>
                <Form.Item
                    name={`id_${data?._id}`}
                    initialValue={data?._id}
                >
                    <></>
                </Form.Item>
                <Col xs={24} md={12}>
                    <Form.Item
                        labelAlign={"left"}
                        label={t("clientUser.bank.bankName")}
                        name={`bank_${data?._id}`}
                        initialValue={data?.formData?.bank}
                        required
                    >
                        <Input
                            disabled={editable !== data?._id}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        labelAlign={"left"}
                        label={t("clientUser.bank.accountNo")}
                        name={`account_no_${data?._id}`}
                        required
                        initialValue={data?.formData?.account_no}
                    >
                        <Input
                            disabled={editable !== data?._id}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        labelAlign={"left"}
                        label={t("clientUser.bank.accountHolder")}
                        name={`account_holder_${data?._id}`}
                        required
                        initialValue={
                            data?.formData?.account_holder
                        }
                    >
                        <Input
                            disabled={editable !== data?._id}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        labelAlign={"left"}
                        label={t("clientUser.bank.currency")}
                        name={`currency_${data?._id}`}
                        required
                        initialValue={data?.formData?.currency}
                    >
                        <Select
                            options={currencyOptions}
                            disabled={editable !== data?._id}
                            showSearch
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        labelAlign={"left"}
                        label={t("clientUser.bank.swiftCode")}
                        name={`swift_code_${data?._id}`}
                        required
                        initialValue={data?.formData?.swift_code}
                    >
                        <Input
                            disabled={editable !== data?._id}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        labelAlign={"left"}
                        label={t("clientUser.bank.iban")}
                        name={`iban_${data?._id}`}
                        initialValue={data?.formData?.iban}
                    >
                        <Input
                            disabled={editable !== data?._id}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        labelAlign={"left"}
                        label={t("clientUser.bank.bankAddress1")}
                        name={`bank_address_1_${data?._id}`}
                        initialValue={
                            data?.formData?.bank_address_1
                        }
                    >
                        <Input
                            disabled={editable !== data?._id}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        labelAlign={"left"}
                        label={t("clientUser.bank.bankAddress2")}
                        name={`bank_address_2_${data?._id}`}
                        initialValue={
                            data?.formData?.bank_address_2
                        }
                    >
                        <Input
                            disabled={editable !== data?._id}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        labelAlign={"left"}
                        label={t("clientUser.bank.city")}
                        name={`city_${data?._id}`}
                        initialValue={data?.formData?.city}
                    >
                        <Input
                            disabled={editable !== data?._id}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        labelAlign={"left"}
                        label={t("clientUser.bank.state")}
                        name={`state_${data?._id}`}
                        initialValue={data?.formData?.state}
                    >
                        <Input
                            disabled={editable !== data?._id}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        labelAlign={"left"}
                        label={t("clientUser.bank.postcode")}
                        name={`postcode_${data?._id}`}
                        initialValue={data?.formData?.postcode}
                    >
                        <Input
                            disabled={editable !== data?._id}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        labelAlign={"left"}
                        label={t("clientUser.bank.country")}
                        name={`country_${data?._id}`}
                        initialValue={data?.formData?.country}
                    >
                        <Input
                            disabled={editable !== data?._id}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default BankForm;
