import React, {useEffect, useState} from "react";
import {Button, Col, Collapse, Form, Input, InputNumber, Modal, Row, Select, Space, Switch, Tag} from "antd";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {languageOptionsState} from "../../../../../../users/_common/state";
import {notificationMessage} from "../../../../../../../../recoil_state";
import {createBadgeSetting, updateBadgeSetting} from "../../../../../../../api/restful/arena/badgeSetting";
import {createBoxSetting, updateBoxSetting} from "../../../../../../../api/restful/arena/boxSetting";
import MediaSelect from "../../../../../_common/mediaComponent";

const CreateUpdateModal = ({open, handleClose, item, fetchBoxes, rewards, handleBoxCreatedRemind}) => {
    const [form] = Form.useForm()
    const languageOptions = useRecoilValue<any>(languageOptionsState)
    const [loading, setLoading] = useState(false)
    const isUpdate = !!item
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const onSubmit = async (value) => {
        setLoading(false)
        if (!isUpdate) {
            try {
                const response = await createBoxSetting(value)

                if (response?.status === "Success") {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: "Create Box Successfully",
                        key: "arenaBoxSettingNotification",
                    });

                    fetchBoxes()
                    handleClose()

                    handleBoxCreatedRemind(response?.data)
                } else {
                    setNotificationMessage({
                        status: "error",
                        title: "",
                        msg: `Create Box Failed: ${response?.errObj}`,
                        key: "arenaBoxSettingNotification",
                    });
                }
            } catch (e) {
            } finally {
                setLoading(false)
            }
        } else {
            try {
                const response = await updateBoxSetting({...value, _id: item?._id})

                if (response?.status === "Success") {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: "Update Box Successfully",
                        key: "arenaBoxSettingNotification",
                    });

                    fetchBoxes()
                    handleClose()
                } else {
                    setNotificationMessage({
                        status: "error",
                        title: "",
                        msg: `Update Box Failed: ${response?.errObj}`,
                        key: "arenaBoxSettingNotification",
                    });
                }
            } catch (e) {
            } finally {
                setLoading(false)
            }
        }
    }

    const items = [
        {
            key: "displayName",
            label: "Display Name",
            children: (
                <Form.List name={"displayName"}>
                    {(fields) => (
                        <>
                            {
                                fields.map(field => {
                                    const language = languageOptions.find(d => d.value === form.getFieldValue(['displayName', field.name, 'language']))?.label
                                    return (
                                        (
                                            <Row className={"margin-bottom-0-3"} gutter={[8, 8]} key={field.name}>
                                                <Col span={8}>
                                                    <>{language}</>
                                                </Col>
                                                <Col span={16}>
                                                    <Form.Item noStyle name={[field.name, 'content']}>
                                                        <Input/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        )
                                    )
                                })
                            }
                        </>
                    )}
                </Form.List>
            )
        },
        {
            key: "description",
            label: "Description",
            children: (
                <Form.List name={"description"}>
                    {(fields) => (
                        <>
                            {
                                fields.map(field => {
                                    const language = languageOptions.find(d => d.value === form.getFieldValue(['description', field.name, 'language']))?.label
                                    return (
                                        (
                                            <Row className={"margin-bottom-0-3"} gutter={[8, 8]} key={field.name}>
                                                <Col span={8}>
                                                    <>{language}</>
                                                </Col>
                                                <Col span={16}>
                                                    <Form.Item noStyle name={[field.name, 'content']}>
                                                        <Input/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        )
                                    )
                                })
                            }
                        </>
                    )}
                </Form.List>
            )
        }
    ]

    useEffect(() => {
        if (!item) {
            form.resetFields()
            form.setFieldValue("displayName", languageOptions?.map(lang => ({language: lang?.value, content: ""})))
            form.setFieldValue("description", languageOptions?.map(lang => ({language: lang?.value, content: ""})))
            form.setFieldValue("rewards", [])
        } else {
            form.setFieldsValue(item)
        }
    }, [open, item, languageOptions]);

    return (
        <Modal
            open={open}
            onCancel={handleClose}
            title={"Create New Box Setting"}
            onOk={form.submit}
            okButtonProps={{loading: loading}}
        >
            <Form form={form} onFinish={onSubmit} layout={"vertical"}>
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                        <Form.Item name={"name"} label={"Name"}>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={"type"} label={"Type"}>
                            <Select
                                className={"w-100"}
                                options={[
                                    {label: "All", value: "all"},
                                    {label: "Random", value: "random"},
                                    {label: "Selectable", value: "selected"},
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={"maxCount"} label={"Max Count"}>
                            <InputNumber min={0} className={"w-100"}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
                        >
                            {({getFieldValue}) =>
                                getFieldValue('type') === 'random' ? (
                                    <Form.Item label={"Random Percentage"} name={"randomPercentage"}>
                                        <InputNumber className={"w-100"} min={0} max={100}/>
                                    </Form.Item>
                                ) : getFieldValue('type') === 'selected' ? (
                                    <Form.Item label={"Selectable Count"} name={"selectedCount"}>
                                        <InputNumber min={0} className={"w-100"}/>
                                    </Form.Item>
                                ) : (
                                    <></>
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={"rewards"} label={"Rewards"}>
                            <Select
                                mode={"multiple"}
                                options={rewards?.map(d => ({label: d.sourceSetting, value: d._id, type: d.source}))}
                                optionRender={(option) => {
                                    let color
                                    switch (option.data.type) {
                                        case "badges":
                                            color = "cyan"
                                            break
                                        case "gifts":
                                            color = "volcano"
                                            break
                                        case "voucher":
                                            color = "lime"
                                            break
                                        case "exp":
                                            color = "geekblue"
                                            break
                                        case "live":
                                            color = "purple"
                                            break
                                        case "coin":
                                            color = "gold"
                                            break
                                        case "pass":
                                            color = "green"
                                            break
                                    }

                                    return (
                                        <Row justify={"space-between"}>
                                            {option.data.label} <Tag bordered={false} color={color}>{option.data.type?.toUpperCase()}</Tag>
                                        </Row>
                                    )
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={"light"} hidden/>
                        <Form.Item label={"Icon (Light)"} shouldUpdate className={"align-start"}>
                            {({getFieldValue, setFieldValue}) => {
                                const currentValue = getFieldValue('light');
                                return (
                                    <MediaSelect
                                        title={`Select Icon (Light)`}
                                        passMedia={(value) => setFieldValue("light", value)}
                                        selectedFile={currentValue}
                                        placement={"bottomLeft"}
                                    />
                                );
                            }}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={"dark"} hidden/>
                        <Form.Item label={"Icon (Dark)"} shouldUpdate className={"align-start"}>
                            {({getFieldValue, setFieldValue}) => {
                                const currentValue = getFieldValue('dark');
                                return (
                                    <MediaSelect
                                        title={`Select Icon (Dark)`}
                                        passMedia={(value) => setFieldValue("dark", value)}
                                        selectedFile={currentValue}
                                        placement={"bottomLeft"}
                                    />
                                );
                            }}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Collapse ghost items={items}/>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default CreateUpdateModal