import React, {useState} from "react";
import {Button, Popconfirm, Popover, Space, Table, Tooltip, Upload} from "antd";
import {DeleteOutlined, DownloadOutlined, EditOutlined, EyeOutlined, UploadOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useDeleteLanguageMutation} from "../../../../../api/graphql/language";
import LanguageNewModal from "./component/languageModal";
import LanguageTableToolbar from "./component/languageTableToolbar";
import {convertCsvToArray, dataConversion, exportToExcel, makeId} from "../../function/common";
import * as XLSX from "xlsx";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../recoil_state";

const Language_1 = (props) => {
    const {data, columns, languagePermission} = props;
    const {t} = useTranslation();
    const [selectedItem, setSelectedItem] = useState<any>({});
    const [languageCode, setLanguageCode] = useState("");
    const [translationFile, setTranslationFile] = useState<any>([]);

    const {deleteLanguage} = useDeleteLanguageMutation();
    const [addLanguageModal, setAddLanguageModal] = useState(false);
    const [editLanguageModal, setEditLanguageModal] = useState(false);
    const [viewLanguageModal, setViewLanguageModal] = useState(false);
    const [uploadLanguageModal, setUploadLanguageModal] = useState(false);
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const openViewModal = (record) => {
        setSelectedItem(record);
        setTranslationFile(dataConversion([record, ...data?.filter((item) => item.code === "en")]));
        setLanguageCode(record?.code);
        setViewLanguageModal(true);
    };

    const closeViewModal = () => {
        setViewLanguageModal(false);
        setSelectedItem({});
        setTranslationFile([]);
        setLanguageCode("");
    };

    const openAddModal = () => {
        setAddLanguageModal(true);
    };

    const closeAddModal = () => {
        setAddLanguageModal(false);
        setSelectedItem({});
        setTranslationFile([]);
        setLanguageCode("");
    };

    const openEditModal = (record) => {
        setSelectedItem(record);
        setTranslationFile(dataConversion([record, ...data?.filter((item) => item.code === "en")]));
        setLanguageCode(record?.code);
        setEditLanguageModal(true);
    };

    const closeEditModal = () => {
        setEditLanguageModal(false);
        setSelectedItem({});
        setTranslationFile([]);
        setLanguageCode("");
    };

    const openUploadModal = () => {
        setUploadLanguageModal(true);
    };

    const closeUploadModal = () => {
        setUploadLanguageModal(false);
        setTranslationFile([]);
    };

    return (
        <div id="language">
            <LanguageNewModal
                mode="add"
                isOpen={addLanguageModal}
                languageCode={languageCode}
                translationFile={translationFile}
                languageData={data}
                setOpen={setAddLanguageModal}
                closeModal={closeAddModal}
                setLanguageCode={setLanguageCode}
                setTranslationFile={setTranslationFile}
            />
            <LanguageNewModal
                mode="edit"
                item={selectedItem}
                isOpen={editLanguageModal}
                languageCode={languageCode}
                translationFile={translationFile}
                languageData={data}
                setOpen={setEditLanguageModal}
                closeModal={closeEditModal}
                setLanguageCode={setLanguageCode}
                setTranslationFile={setTranslationFile}
            />
            <LanguageNewModal
                mode="view"
                item={selectedItem}
                isOpen={viewLanguageModal}
                languageCode={languageCode}
                translationFile={translationFile}
                languageData={data}
                setOpen={setViewLanguageModal}
                closeModal={closeViewModal}
                setLanguageCode={setLanguageCode}
                setTranslationFile={setTranslationFile}
            />
            <LanguageNewModal
                mode="upload"
                isOpen={uploadLanguageModal}
                translationFile={translationFile}
                setOpen={setUploadLanguageModal}
                closeModal={closeUploadModal}
                setTranslationFile={setTranslationFile}
            />

            <div className="display-flex">
                <h1>{t("Languages")}</h1>
                <LanguageTableToolbar
                    data={data}
                    translationFile={selectedItem}
                    setTranslationFile={setTranslationFile}
                    openAddModal={openAddModal}
                    openUploadModal={openUploadModal}
                    languagePermission={languagePermission}
                />
            </div>

            <Table
                className={"margin-top-0-75"}
                dataSource={data}
                scroll={{x: data ? true : undefined}}
                columns={[
                    ...columns,
                    {
                        title: t("common.action"),
                        key: "action",
                        width: 132,
                        render: (_, record: any) => (
                            <Space size="middle">
                                <Tooltip title={t("View")}>
                                    <a onClick={() => openViewModal(record)}>
                                        <EyeOutlined />
                                    </a>
                                </Tooltip>
                                {languagePermission?.edit?.edit && (
                                    <Tooltip title={t("Edit")}>
                                        <a onClick={() => openEditModal(record)}>
                                            <EditOutlined />
                                        </a>
                                    </Tooltip>
                                )}
                                {record.type !== "system" && languagePermission?.delete?.delete && (
                                    <Tooltip title={t("Delete")}>
                                        <Popconfirm
                                            placement="left"
                                            title={"Are you sure to delete this language?"}
                                            description={"Delete the Language"}
                                            onConfirm={() => {
                                                deleteLanguage(record.id);
                                            }}
                                            okText={t("common.yes")}
                                            cancelText={t("common.no")}
                                        >
                                            <a>
                                                <DeleteOutlined />
                                            </a>
                                        </Popconfirm>
                                    </Tooltip>
                                )}
                                {languagePermission?.upload?.upload && (
                                    <Tooltip title={t("Upload")}>
                                        <Upload
                                            accept=".xlsx, .csv"
                                            showUploadList={false}
                                            beforeUpload={(file) => {
                                                const [fileExtension] = file?.name.split(".").reverse();
                                                const reader = new FileReader();

                                                if (fileExtension === "csv") {
                                                    reader.onload = (e) => {
                                                        setTranslationFile(convertCsvToArray(e?.target?.result));
                                                    };
                                                    reader.readAsText(file);
                                                    openEditModal(record);
                                                } else if (fileExtension === "xlsx") {
                                                    reader.onload = (e) => {
                                                        const workbook = XLSX.read(e?.target?.result, {
                                                            type: "binary",
                                                        });
                                                        for (const Sheet in workbook.Sheets) {
                                                            var data: any = XLSX.utils.sheet_to_json(workbook.Sheets[Sheet]);
                                                            var result = data?.map((item, index) => {
                                                                return {
                                                                    ...item,
                                                                    ids: makeId(20) + "_" + index,
                                                                };
                                                            });
                                                            setTranslationFile(result);
                                                        }
                                                    };
                                                    reader.readAsBinaryString(file);
                                                    openEditModal(record);
                                                } else {
                                                    setNotificationMessage({
                                                        status: "error",
                                                        title: "",
                                                        msg: t("Invalid File"),
                                                        key: "addLanguage",
                                                    });
                                                }
                                                // Prevent upload
                                                return false;
                                            }}
                                        >
                                            <a>
                                                <UploadOutlined />
                                            </a>
                                        </Upload>
                                    </Tooltip>
                                )}

                                <Tooltip title={t("Export")}>
                                    <Popover
                                        placement="left"
                                        content={
                                            <>
                                                <Button
                                                    className="margin-right-0-75"
                                                    onClick={() =>
                                                        exportToExcel(
                                                            dataConversion([
                                                                ...(record.code !== "en" ? data?.filter((item) => item.code === "en") : []),
                                                                record,
                                                            ]),
                                                            "xlsx",
                                                            [record.code]
                                                        )
                                                    }
                                                >
                                                    xlsx
                                                </Button>
                                                <Button
                                                    onClick={() =>
                                                        exportToExcel(
                                                            dataConversion([
                                                                ...(record.code !== "en" ? data?.filter((item) => item.code === "en") : []),
                                                                record,
                                                            ]),
                                                            "csv",
                                                            [record.code]
                                                        )
                                                    }
                                                >
                                                    csv
                                                </Button>
                                            </>
                                        }
                                    >
                                        <a>
                                            <DownloadOutlined />
                                        </a>
                                    </Popover>
                                </Tooltip>
                            </Space>
                        ),
                    },
                ]}
                rowKey="id"
                size="small"
                pagination={{
                    showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
                }}
            />
        </div>
    );
};

export default Language_1;
