import React from "react";
import VoucherProgram_1 from "./view/layout1";
import {useRecoilValue} from "recoil";
import {columns} from "./function/tableColumn";
import { selfPermissionObj } from "../../../../recoil_state";
import { getLanguage } from "../../../api/graphql/language";
import { sortEn } from "../../../function/language";
import "./css/common/index.css"

const VoucherProgram = (props) => {
    let layout = 1;
    let component;
    const tableName = "VoucherProgram"

    const permission = useRecoilValue<any>(selfPermissionObj);
    const {languageData} = getLanguage({filter: {isActive: true}});
    
    switch (layout) {
        case 1:
            component = (
                <VoucherProgram_1
                    tableName={tableName}
                    permissionActivities={permission?.activitiesAndRewardSetting}
                    column={columns}
                    languageData={sortEn(languageData?.getLanguage?.data)} 
                />
            );
            break;

        default:
            component = (
                <VoucherProgram_1
                    tableName={tableName}
                    permissionActivities={permission?.activitiesAndRewardSetting}
                    column={columns}
                    languageData={sortEn(languageData?.getLanguage?.data)} 
                />
            );
            break;
    }

    return component;
};

export default VoucherProgram;
