import {Avatar, Checkbox, Col, Form, Input, InputNumber, Modal, Row, Select, Space, Upload, message, Button} from "antd";
import React, {useEffect, useMemo, useState} from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {useTranslation} from "react-i18next";
import {PlusOutlined, UploadOutlined} from "@ant-design/icons";
import {BRAND_NAME} from "../../../../../../../config/brandVariable";
import {getConfigByName} from "../../../../../../../config";
import {getMoneyManagerAccountSettingByID, updateMoneyManagerAccountSetting} from "../../../../../../api/graphql/mamPamm";
import useAuthorize from "../../../../../../_common/authorize";
import {getUserNetworkTreeDownline} from "../../../../../../api/graphql/userNetworkTree";
import {getAvailableProductByUserId} from "../../../../../../api/graphql/product";
import {userOptionsState} from "../../../../../users/_common/state";
import {formatUserOptions} from "../../../../../../function/_common";
import {notificationMessage} from "../../../../../../../recoil_state";
import {getMamPammLegalDocument} from "../../../../../../api/graphql/mamPammLegalDocument";
import LegalDocumentModal from "./legalDocumentModal";

const MoneyManagerAccountSettingModal = (props) => {
  const {isOpen, onCancel, selectedRecord, getMoneyManagerListing} = props;
  const {t} = useTranslation();
  const {systemErrorNotification} = useAuthorize();
  const setNotificationMessage = useSetRecoilState(notificationMessage);

  const [form] = Form.useForm();
  const brandConfig = getConfigByName(BRAND_NAME);
  const labelCol = {span: 8, offset: 0};

  const [data, setData] = useState<any>({});
  const [actionLoading, setActionLoading] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [autoDeposit, setAutoDeposit] = useState(false);
  const [autoWithdrawal, setAutoWithdrawal] = useState(false);

  const selectedSettingId = selectedRecord?.moneyManagerAccountSetting?._id;
  const selectedProductObj = selectedRecord?.product;
  const selectedProduct = selectedRecord?.product?._id;

  const [currentSelectUsers, setCurrentSelectUsers] = useState<any[]>([]);
  const [isSubscriptionFeeTicked, setIsSubscriptionFeeTicked] = useState(false);
  const [isMoneyManagerProfitSharingTicked, setIsMoneyManagerProfitSharingTicked] = useState(false);
  const [networkTypeSelect, setNetworkTypeSelect] = useState("all");

  const [legalDocuments, setLegalDocuments] = useState<any[]>([]);
  const [legalDocumentIsOpen, setLegalDocumentIsOpen] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState<any>(null);
  const [mode, setMode] = useState(null);

  const {networkTreeDownlineData} = getUserNetworkTreeDownline(currentSelectUsers);
  const {data: allAvailableProductByServer} = getAvailableProductByUserId(data?.account?.user?._id, selectedProductObj?.server?.name);

  const userOptions = useRecoilValue(userOptionsState);
  const networkTreeDownLineOptions = networkTreeDownlineData?.getUserNetworkTreeDownline?.data?.map((d) => {
    return formatUserOptions(d?.userid);
  });

  const getSettingData = async () => {
    try {
      if (selectedSettingId) {
        const res = await getMoneyManagerAccountSettingByID(selectedSettingId);
        let data = res.getMoneyManagerAccountSettingByID;
        setData(data);
        setAutoDeposit(data?.autoDeposit);
        setAutoWithdrawal(data?.autoWithdrawal);
        setIsSubscriptionFeeTicked(data?.subscriptionFee > 0);
        setIsMoneyManagerProfitSharingTicked(data?.moneyManagerProfitSharing > 0);
        setNetworkTypeSelect(data?.networkType)
        setCurrentSelectUsers(data?.network?.map(v => v?._id))
      }
    } catch (error) {}
  };

  const uploadOnChange = (e) => {
    try {
      if (e?.file?.status === "done") {
        setProfileImage(e?.file?.response?.[0]?.link);
        message.success(`${e?.file?.name} file upload successfully.`);
      } else if (e?.file?.status === "error") {
        message.error(`${e?.file?.name} file upload failed.`);
      }
    } catch (e) {
      systemErrorNotification();
    }
  };

  const handleSubscriptionFeeCheckBox = async (e) => {
    try {
      setIsSubscriptionFeeTicked(e?.target?.checked);
    } catch (error) {}
  };

  const handleMoneyManagerProfitSharingCheckBox = async (e) => {
    try {
      setIsMoneyManagerProfitSharingTicked(e?.target?.checked);
    } catch (error) {}
  };

  const onSubmit = async (values) => {
    try {
      setActionLoading(true);
      let input: any = {};
      if (selectedProductObj?.mamPammProvider?.provider === "easyMAM") {
        input = {
          ...values,
          account: data?.account?._id,
          profileImage: profileImage,
          autoDeposit: autoDeposit,
          autoWithdrawal: autoWithdrawal,
          easyMAM: {
            tradingMode: values?.easyMAM_tradingMode,
          },
        };
        delete input?.easyMAM_tradingMode;
        delete input?.accountId;
      }
      const res = await updateMoneyManagerAccountSetting(selectedSettingId, input);
      const typename = res?.updateMoneyManagerAccountSetting?.__typename;
      if (typename === "BaseError") {
        setNotificationMessage({
          status: "error",
          title: "",
          msg: t(res?.updateMoneyManagerAccountSetting?.errKey),
          key: "updateMoneyManagerAccountSetting",
        });
      } else if (typename === "CrmTaskApproval") {
        setNotificationMessage({
          status: "info",
          title: "",
          msg: t(res?.updateMoneyManagerAccountSetting?.msg),
          key: "updateMoneyManagerAccountSetting",
        });
      } else {
        setNotificationMessage({
          status: "success",
          title: "",
          msg: t("Update Successfully"),
          key: "updateMoneyManagerAccountSetting",
        });
        getMoneyManagerListing();
        onCancel();
      }
    } catch (error) {
    } finally {
      setActionLoading(false);
    }
  };

  useEffect(() => {
    getSettingData();
  }, [selectedRecord]);

  useEffect(() => {
    form.resetFields();
  }, [data]);

  const investorProductOptions = useMemo(() => {
    let options = [];
    if (selectedProductObj?.server?._id) {
      options = allAvailableProductByServer?.getAvailableProductByUserId?.data
        ?.filter((d) => {
          let productType = selectedProductObj?.mode === "live" ? "investorLive" : "investorDemo";
          return (
            d?.mode === selectedProductObj?.mode &&
            d?.server?._id === selectedProductObj?.server?._id &&
            d?.mamPammProvider?.provider === selectedProductObj?.mamPammProvider?.provider &&
            productType?.includes(d?.productType)
          );
        })
        ?.map((d) => ({
          label: (
            <Row key={d?.name} justify={"space-between"}>
              <span>{d?.server?.name + " - " + d?.name}</span> <span>{d?.server?.platform?.toUpperCase()}</span>
            </Row>
          ),
          value: d?._id,
        }));
    }
    return options;
  }, [allAvailableProductByServer, selectedProductObj, selectedProduct, data]);

  const timeframeOptions = [
    {label: t("mampamm.timeframe_option.daily"), value: "DAILY"},
    {label: t("mampamm.timeframe_option.weekly"), value: "WEEKLY"},
    {label: t("mampamm.timeframe_option.monthly"), value: "MONTHLY"},
    {label: t("mampamm.timeframe_option.quarterly"), value: "QUARTERLY"},
    {label: t("mampamm.timeframe_option.yearly"), value: "YEARLY"},
  ];

  const networkTypeOptions = [
    {label: t("network.all"), value: "all"},
    {label: t("network.partial"), value: "partial"},
    {label: t("network.onlyMe"), value: "onlyMe"},
  ];

  const moneyManagerProfitSharingTypeOptions = [
    // {label: t("mampamm.money_manager_profit_sharing_type_option.gross_profit"), value: "GROSS_PROFIT"},
    // {label: t("mampamm.money_manager_profit_sharing_type_option.gross_realised_profit"), value: "GROSS_REALISED_PROFIT"},
    {label: t("mampamm.money_manager_profit_sharing_type_option.net_profit"), value: "NET_PROFIT"},
    // {label: t("mampamm.money_manager_profit_sharing_type_option.net_realised_profit"), value: "NET_REALISED_PROFIT"},
  ];

  const easyMAMTradingModeOptions = [
    {label: t("mampamm.trading_mode_option.equity"), value: "EQUITY"},
    {label: t("mampamm.trading_mode_option.balance"), value: "BALANCE"},
    {label: t("mampamm.trading_mode_option.free_margin"), value: "FREE_MARGIN"},
    {label: t("mampamm.trading_mode_option.post_trade_equity"), value: "POST_TRADE_EQUITY"},
    {label: t("mampamm.trading_mode_option.post_trade_balance"), value: "POST_TRADE_BALANCE"},
    {label: t("mampamm.trading_mode_option.post_trade_free_margin"), value: "POST_TRADE_FREE_MARGIN"},
  ];

  const modalTitle = "Update Account Settings";

  // const isDataAvailable = Object.keys(data)?.length > 0;

  const fetchLegalDocumentsFunction = async () => {
    const documents = await getMamPammLegalDocument({filter: {moneyManagerAccount: selectedRecord?._id}});
    setLegalDocuments(documents?.getMamPammLegalDocument?.data);
  };

  useEffect(() => {
    if (selectedRecord?._id) {
      fetchLegalDocumentsFunction();
    }
  }, [selectedRecord]);

  const handleOpenLegalDocumentModal = (mode, document?) => {
    setLegalDocumentIsOpen(true);
    setMode(mode);
    setSelectedDoc(document);
  };

  return (
    <>
      <Modal
        title={modalTitle}
        open={isOpen}
        onCancel={onCancel}
        onOk={form.submit}
        okText={t("common.submit")}
        cancelText={t("common.cancel")}
        okButtonProps={{loading: actionLoading}}
        cancelButtonProps={{loading: actionLoading}}
        width={"80%"}
        confirmLoading={actionLoading}
      >
        <Form form={form} onFinish={onSubmit} className="form-1 margin-top-0-75 margin-bottom-0-75">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Space direction={"vertical"} style={{width: "100%"}}>
                <Form.Item name={"name"} label={t("mampamm.name")} labelCol={labelCol} rules={[{required: true}]} initialValue={data?.name}>
                  <Input placeholder={t("mampamm.name").toString()} />
                </Form.Item>
                <Form.Item name={"description"} label={t("mampamm.description")} labelCol={labelCol} initialValue={data?.description}>
                  <Input placeholder={t("mampamm.description").toString()} />
                </Form.Item>
                <Form.Item name={"profileImage"} label={t("user.profileImage")} labelCol={labelCol}>
                  <Upload
                    action={`${brandConfig?.backendURL}/api/upload/mamPamm/image`}
                    listType="picture-card"
                    maxCount={1}
                    showUploadList={true}
                    defaultFileList={
                      data?.profileImage
                        ? [
                            {
                              uid: "profile-image",
                              name: "profile-image",
                              url: data?.profileImage,
                            },
                          ]
                        : []
                    }
                    onChange={(e) => uploadOnChange(e)}
                    onRemove={() => setProfileImage("")}
                  >
                    {!profileImage && <Avatar size={64} icon={<UploadOutlined />} />}
                  </Upload>
                </Form.Item>
                <Form.Item
                  name={"investorProduct"}
                  label={t("mampamm.investor_product")}
                  labelCol={labelCol}
                  rules={[{required: true}]}
                  initialValue={data?.investorProduct?._id}
                >
                  <Select options={investorProductOptions} placeholder={t("mampamm.investor_product")} />
                </Form.Item>
                <Form.Item
                  name={"minDeposit"}
                  label={t("common.minDeposit")}
                  labelCol={labelCol}
                  rules={[{required: true}]}
                  initialValue={data?.minDeposit}
                >
                  <InputNumber placeholder={t("common.minDeposit").toString()} min={0} />
                </Form.Item>
                <Form.Item name={"autoDeposit"} label={t("mampamm.deposit_auto_approve")} labelCol={labelCol}>
                  <Checkbox onChange={(e) => setAutoDeposit(e?.target?.checked)} checked={autoDeposit} />
                </Form.Item>
                <Form.Item name={"autoWithdrawal"} label={t("mampamm.withdrawal_auto_approve")} labelCol={labelCol}>
                  <Checkbox onChange={(e) => setAutoWithdrawal(e?.target?.checked)} checked={autoWithdrawal} />
                </Form.Item>

                {/* Subscription */}
                <Form.Item label={t("mampamm.has_subscription_fee")} labelCol={labelCol}>
                  <Checkbox onChange={handleSubscriptionFeeCheckBox} checked={isSubscriptionFeeTicked} />
                </Form.Item>
                {isSubscriptionFeeTicked && (
                  <>
                    <Form.Item
                      name={"subscriptionFee"}
                      label={t("mampamm.subscription_fee")}
                      labelCol={labelCol}
                      rules={[{required: true}]}
                      initialValue={data?.subscriptionFee}
                    >
                      <InputNumber placeholder={t("mampamm.subscription_fee").toString()} min={0} />
                    </Form.Item>
                    <Form.Item
                      name={"subscriptionFeeTimeframe"}
                      label={t("mampamm.subscription_fee_timeframe")}
                      labelCol={labelCol}
                      rules={[{required: true}]}
                      initialValue={data?.subscriptionFeeTimeframe}
                    >
                      <Select options={timeframeOptions} placeholder={t("mampamm.subscription_fee_timeframe")} />
                    </Form.Item>
                  </>
                )}

                {/* Money Manager Profit Sharing */}
                <Form.Item label={t("mampamm.has_money_manager_profit_sharing")} labelCol={labelCol}>
                  <Checkbox onChange={handleMoneyManagerProfitSharingCheckBox} checked={isMoneyManagerProfitSharingTicked}></Checkbox>
                </Form.Item>
                {isMoneyManagerProfitSharingTicked && (
                  <>
                    <Form.Item
                      name={"moneyManagerProfitSharingType"}
                      label={t("mampamm.money_manager_profit_sharing_type")}
                      labelCol={labelCol}
                      rules={[{required: true}]}
                      initialValue={data?.moneyManagerProfitSharingType}
                    >
                      <Select options={moneyManagerProfitSharingTypeOptions} placeholder={t("mampamm.money_manager_profit_sharing_type")} />
                    </Form.Item>
                    <Form.Item
                      name={"moneyManagerProfitSharingTimeframe"}
                      label={t("mampamm.money_manager_profit_timeframe")}
                      labelCol={labelCol}
                      rules={[{required: true}]}
                      initialValue={data?.moneyManagerProfitSharingTimeframe}
                    >
                      <Select options={timeframeOptions} placeholder={t("mampamm.money_manager_profit_timeframe")} />
                    </Form.Item>
                    <Form.Item
                      name={"moneyManagerProfitSharing"}
                      label={t("mampamm.money_manager_profit_sharing")}
                      labelCol={labelCol}
                      rules={[{required: true}]}
                      initialValue={data?.moneyManagerProfitSharing}
                    >
                      <InputNumber placeholder={t("mampamm.money_manager_profit_sharing").toString()} addonAfter="%" min={0} max={100} />
                    </Form.Item>
                    <Form.Item
                      name={"traderProfitSharing"}
                      label={t("mampamm.trader_profit_sharing")}
                      labelCol={labelCol}
                      rules={[{required: true}]}
                      initialValue={data?.traderProfitSharing}
                    >
                      <InputNumber
                        placeholder={t("mampamm.trader_profit_sharing").toString()}
                        addonAfter="%"
                        min={0}
                        max={100}
                        style={{marginLeft: 20}}
                      />
                    </Form.Item>
                    <Form.Item
                      name={"commissionProfitSharing"}
                      label={t("mampamm.commission_profit_sharing")}
                      labelCol={labelCol}
                      rules={[{required: true}]}
                      initialValue={data?.commissionProfitSharing}
                      help={
                        <small style={{marginLeft: 20}}>
                          Trader Profit Sharing + Commission Profit Sharing must be equal to Money Manager Profit Sharing
                        </small>
                      }
                    >
                      <InputNumber
                        placeholder={t("mampamm.commission_profit_sharing").toString()}
                        addonAfter="%"
                        min={0}
                        max={100}
                        style={{marginLeft: 20}}
                      />
                    </Form.Item>
                  </>
                )}

                <Form.Item
                  name={"investorProfitSharing"}
                  label={t("mampamm.investor_profit_sharing")}
                  initialValue={data?.investorProfitSharing || 100}
                  labelCol={labelCol}
                  rules={[{required: true}]}
                >
                  <InputNumber placeholder={t("mampamm.investor_profit_sharing").toString()} addonAfter="%" min={0} max={100} />
                </Form.Item>

                <Form.Item
                  name={"networkType"}
                  label={t("Which Network can View")}
                  labelCol={labelCol}
                  rules={[{required: true}]}
                  initialValue={data?.networkType || "all"}
                >
                  <Select options={networkTypeOptions} onChange={(value) => setNetworkTypeSelect(value)} value={networkTypeSelect} />
                </Form.Item>
                {networkTypeSelect === "partial" && (
                  <>
                    <Form.Item name={"network"} label={t("network.include")} labelCol={labelCol} initialValue={data?.network?.map(v => v?._id)}>
                      <Select
                        placeholder={t("network.include")}
                        onChange={(val) => setCurrentSelectUsers(val)}
                        options={userOptions}
                        optionLabelProp="selectedLabel"
                        mode={"multiple"}
                        optionFilterProp={"selectedLabel"}
                      />
                    </Form.Item>
                    <Form.Item name={"exclude"} label={t("network.exclude")} labelCol={labelCol} initialValue={data?.exclude?.map(v => v?._id)}>
                      <Select
                        placeholder={t("network.exclude")}
                        options={networkTreeDownLineOptions}
                        optionLabelProp="selectedLabel"
                        mode={"multiple"}
                        optionFilterProp={"selectedLabel"}
                      />
                    </Form.Item>
                  </>
                )}

                {selectedProductObj?.mamPammProvider?.provider === "easyMAM" && (
                  <>
                    <Form.Item
                      name={"easyMAM_tradingMode"}
                      label={t("mampamm.trading_mode")}
                      labelCol={labelCol}
                      rules={[{required: true}]}
                      initialValue={data?.easyMAM?.tradingMode}
                    >
                      <Select placeholder={t("mampamm.easyMAMTradingMode")} options={easyMAMTradingModeOptions} />
                    </Form.Item>
                  </>
                )}

                <Form.Item label={t("mampamm.legal_documents")} labelCol={labelCol}>
                  <Space wrap>
                    {legalDocuments?.map((doc) => {
                      const displayName = doc?.displayName?.find((d) => d?.language === localStorage.getItem("lang"))?.content;

                      return (
                        <Button key={doc?._id} onClick={() => handleOpenLegalDocumentModal("edit", doc)}>
                          {displayName}
                        </Button>
                      );
                    })}

                    <Button onClick={() => handleOpenLegalDocumentModal("add")} icon={<PlusOutlined />} type={"primary"}>
                      {t("common.add")}
                    </Button>
                  </Space>
                </Form.Item>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
      <LegalDocumentModal
        isOpen={legalDocumentIsOpen}
        handleCancel={() => setLegalDocumentIsOpen(false)}
        mode={mode}
        selectedDocument={selectedDoc}
        moneyManagerAccountId={selectedRecord?._id}
        fetchLegalDocumentsFunction={fetchLegalDocumentsFunction}
      />
    </>
  );
};

export default MoneyManagerAccountSettingModal;
