import React from "react";
import MissionInformation from "./activities/missionInformation";

const ActivitiesTabItem = (props) => {
    const {data, permissionUser} = props;

    return (
        <>
            <MissionInformation data={data} permissionUser={permissionUser} />
        </>
    );
};

export default ActivitiesTabItem;
