import React, {useEffect, useState} from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {Card, Col, Row, Spin, Tabs} from "antd";
import AccountDetailCard from "./component/accountDetailCard";
import AccountSettingCard from "./component/accountSettingCard";
import AccountTableTransaction from "./component/accountTableTransaction";
import AccountTransaction from "./component/accountTransaction";
import AccountTransactionCredit from "./component/accountTransactionCredit";
import AccountTransactionTradingVoucher from "./component/accountTransactionTradingVoucher";
import AccountPosition from "./component/accountPosition";
import AccountChangePassword from "./component/accountPassword";
import ClientDetail from "./component/clientDetail";
import {selfPermissionObj} from "../../../../../../../recoil_state";
import AccountTabDetails from "./component/accountTabDetails";
import {getReportingBalanceHistory} from "../../../../../../api/graphql/reporting";
import {
    getTradingCreditTransactionByBalanceHistoryIds,
    getTradingVoucherTransactionByBalanceHistoryIds
} from "../../../../../../api/graphql/tradingAccount";
import {useTranslation} from "react-i18next";
import AccountMt5DetailCard from "./component/mt5/accountMt5DetailCard";
import AccountMt5SettingCard from "./component/mt5/accountMt5SettingCard";
import AccountMt5Transaction from "./component/mt5/accountMt5Transaction";
import AccountMt5TableTransaction from "./component/mt5/accountMt5TableTransaction";
import AccountMT5TransactionCredit from "./component/mt5/accountMt5TransactionCredit";
import AccountMt5TransactionTradingVoucher from "./component/mt5/accountMt5TransactionTradingVoucher";
import AccountMt5ChangePassword from "./component/mt5/accountMt5Password";
import AccountMt5Position from "./component/mt5/accountMt5Position";

const TradingAccountDetail_1 = (props) => {
    const {data, id, refreshQuery, initialDataLoading, userDetail} = props;
    const {t} = useTranslation()

    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const permission = useRecoilValue<any>(selfPermissionObj);

    const tradingAccountPermission = selfPermission?.tradingAccount?.account;

    const tradingAccountAccTxnPermission =
        selfPermission?.tradingAccount?.accountTransaction;
    const tradingAccountCreditTxnPermission =
        selfPermission?.tradingAccount?.creditTransaction;
    const tradingAccountVoucherTxnPermission =
        selfPermission?.tradingAccount?.voucherTransaction;

    const tradingAccountMT5Permission = selfPermission?.tradingAccountMt5?.account;

    const tradingAccountMT5AccTxnPermission =
        selfPermission?.tradingAccountMt5?.accountTransaction;
    const tradingAccountMT5CreditTxnPermission =
        selfPermission?.tradingAccountMt5?.creditTransaction;
    const tradingAccountMT5VoucherTxnPermission =
        selfPermission?.tradingAccountMt5?.voucherTransaction;

    const clientsUserPermission = selfPermission?.user?.clientsUser;

    const [pageTransaction, setPageTransaction] = useState(1);
    const [rowsTransaction, setRowsTransaction] = useState(10);
    const [orderByTransaction, setOrderByTransaction] = useState<any>({
        fieldName: "createdAt",
        operator: "DESCENDING",
        value: null
    });
    const [filterTransaction, setFilterTransaction] = useState([]);
    const [reportDataTransaction, setReportDataTransaction] = useState([]);
    const [totalCountTransaction, setTotalCountTransaction] = useState(0);
    const [loadingTransaction, setLoadingTransaction] = useState(false);

    const [pageCredit, setPageCredit] = useState(1);
    const [rowsCredit, setRowsCredit] = useState(10);
    const [orderByCredit, setOrderByCredit] = useState<any>({
        fieldName: "createdAt",
        operator: "DESCENDING",
        value: null
    });
    const [filterCredit, setFilterCredit] = useState([]);
    const [reportDataCredit, setReportDataCredit] = useState([]);
    const [totalCountCredit, setTotalCountCredit] = useState(0);
    const [loadingCredit, setLoadingCredit] = useState(false);

    const [pageVoucher, setPageVoucher] = useState(1);
    const [rowsVoucher, setRowsVoucher] = useState(10);
    const [orderByVoucher, setOrderByVoucher] = useState<any>({
        fieldName: "createdAt",
        operator: "DESCENDING",
        value: null
    });
    const [filterVoucher, setFilterVoucher] = useState([]);
    const [reportDataVoucher, setReportDataVoucher] = useState([]);
    const [totalCountVoucher, setTotalCountVoucher] = useState(0);
    const [loadingVoucher, setLoadingVoucher] = useState(false);


    const getBalanceHistoryReport = async () => {
        await setLoadingTransaction(true)
        const accountId = data?.accountId
        const env = data?.mode
        let platform = data?.platform
        try {
            let amendedFilters: any = [
                {
                    fieldName: "accountNo",
                    operator: "E",
                    value: [accountId]
                },
                {
                    fieldName: "transactionType",
                    operator: "E",
                    value: ["DEPOSIT", "WITHDRAWAL", "ADJUSTMENT IN", "ADJUSTMENT OUT"]
                },
            ];
            if (orderByTransaction !== null) {
                amendedFilters = [...amendedFilters, orderByTransaction];
            }
            if (filterTransaction?.length > 0) {
                amendedFilters = [...amendedFilters, ...filterTransaction];
            }

            let data = {
                filters: amendedFilters?.length > 0 ? amendedFilters : null,
            };

            const response: any = await getReportingBalanceHistory(data, "2001-01-01", "2099-01-01", pageTransaction, rowsTransaction, null, env, id, platform);

            if (response?.getReportingBalanceHistory?.__typename === "ReportingDataAry") {
                setReportDataTransaction(response?.getReportingBalanceHistory?.data);
                setTotalCountTransaction(response?.getReportingBalanceHistory?.total);
            } else {
                setReportDataTransaction([]);
                setTotalCountTransaction(0);
            }

        } catch (e) {
            console.log("balance history", e)
        } finally {
            await setLoadingTransaction(false)
        }
    }

    const getCreditHistoryReport = async () => {
        await setLoadingCredit(true)
        const accountId = data?.accountId
        const env = data?.mode
        let platform = data?.platform
        try {

            let amendedFilters: any = [
                {
                    fieldName: "accountNo",
                    operator: "E",
                    value: [accountId]
                },
                {
                    fieldName: "transactionType",
                    operator: "E",
                    value: ["CREDIT IN", "CREDIT OUT"]
                },
            ];

            if (orderByCredit !== null) {
                amendedFilters = [...amendedFilters, orderByCredit];
            }
            if (filterCredit?.length > 0) {
                amendedFilters = [...amendedFilters, ...filterCredit];
            }

            let data = {
                filters: amendedFilters?.length > 0 ? amendedFilters : null,
            };

            const response: any = await getReportingBalanceHistory(data, "2001-01-01", "2099-01-01", pageCredit, rowsCredit, null, env, id, platform);

            const balanceHistoryIds: any = []

            for (const data of response?.getReportingBalanceHistory?.data) {
                balanceHistoryIds.push(data?.value?.value)
            }

            const latestData = await mergeCreditTransactionExpiredData(balanceHistoryIds, response?.getReportingBalanceHistory?.data)

            if (response?.getReportingBalanceHistory?.__typename === "ReportingDataAry") {
                setReportDataCredit(latestData);
                setTotalCountCredit(response?.getReportingBalanceHistory?.total);
            } else {
                setReportDataCredit([]);
                setTotalCountCredit(0);
            }

        } catch (e) {
            console.log(e)
        } finally {
            await setLoadingCredit(false)
        }
    }

    const mergeCreditTransactionExpiredData = async (balanceHistoryIds, reportingData) => {

        const tradingAcc = await getTradingCreditTransactionByBalanceHistoryIds(balanceHistoryIds)

        const adminData = tradingAcc?.getTradingCreditTransactionByBalanceHistoryIds?.data

        const newData: any = []

        for (const data of reportingData) {

            const found = adminData?.find((item) => item?.balanceHistoryId === data?.value?.value)

            newData.push({
                ...data,
                recordId: found?._id,
                recordStatus: found?.status,
                remark: found?.remark,
                debt: found?.debt,
                expirationDatetime: {
                    value: found?.expirationDatetime || null,
                    displayValue: found?.expirationDatetime || null,
                }
            })
        }

        return newData
    }

    const getVoucherHistoryReport = async () => {
        await setLoadingVoucher(true)
        // const traderId = data?.platform === "mt5" || data?.platform === "xTrader" ? data?.accountId : data?.traderId
        const accountId = data?.accountId
        const env = data?.mode
        let platform = data?.platform
        try {

            let amendedFilters: any = [
                {
                    fieldName: "accountNo",
                    operator: "E",
                    value: [accountId]
                },
                {
                    fieldName: "transactionType",
                    operator: "E",
                    value: ["TRADING VOUCHER IN", "TRADING VOUCHER OUT"]
                },
            ];

            if (orderByVoucher !== null) {
                amendedFilters = [...amendedFilters, orderByVoucher];
            }
            if (filterVoucher?.length > 0) {
                amendedFilters = [...amendedFilters, ...filterVoucher];
            }

            let data = {
                filters: amendedFilters?.length > 0 ? amendedFilters : null,
            };

            const response: any = await getReportingBalanceHistory(data, "2001-01-01", "2099-01-01", pageVoucher, rowsVoucher, null, env, id, platform);

            const balanceHistoryIds: any = []

            for (const data of response?.getReportingBalanceHistory?.data) {
                balanceHistoryIds.push(data?.value?.value)
            }

            const latestData = await mergeVoucherTransactionExpiredData(balanceHistoryIds, response?.getReportingBalanceHistory?.data)

            if (response?.getReportingBalanceHistory?.__typename === "ReportingDataAry") {
                setReportDataVoucher(latestData);
                setTotalCountVoucher(response?.getReportingBalanceHistory?.total);
            } else {
                setReportDataVoucher([]);
                setTotalCountVoucher(0);
            }

        } catch (e) {
            console.log(e)
        } finally {
            await setLoadingVoucher(false)
        }
    }

    const mergeVoucherTransactionExpiredData = async (balanceHistoryIds, reportingData) => {

        const tradingAcc = await getTradingVoucherTransactionByBalanceHistoryIds(balanceHistoryIds)

        const adminData = tradingAcc?.getTradingVoucherTransactionByBalanceHistoryIds?.data

        const newData: any = []

        for (const data of reportingData) {

            const found = adminData?.find((item) => item?.balanceHistoryId === data?.value?.value)

            newData.push({
                ...data,
                recordId: found?._id,
                recordStatus: found?.status,
                remark: found?.remark,
                debt: found?.debt,
                expirationDatetime: {
                    value: found?.expirationDatetime || null,
                    displayValue: found?.expirationDatetime || null,
                }
            })
        }

        return newData
    }

    useEffect(() => {
        const preload = async () => {
            if (data?.traderId || data?.accountId) {
                await getVoucherHistoryReport()
            }
        }
        preload()
    }, [data?.traderId, data?.accountId, pageVoucher, filterVoucher, orderByVoucher])

    useEffect(() => {
        const preload = async () => {
            if (data?.traderId || data?.accountId) {
                await getCreditHistoryReport()
            }
        }
        preload()
    }, [data?.traderId, data?.accountId, pageCredit, filterCredit, orderByCredit])

    useEffect(() => {
        const preload = async () => {
            if (data?.traderId || data?.accountId) {
                await getBalanceHistoryReport()
            }
        }
        preload()
    }, [data?.traderId, data?.accountId, pageTransaction, filterTransaction, orderByTransaction])

    const tradingAccountTabItems = [
        {
            key: "1",
            label: t("common.history"),
            shown: tradingAccountAccTxnPermission?.view,
            children: (
                <Col span={24} style={{marginTop: "15px"}}>
                    {
                        tradingAccountPermission?.view?.balanceHistory && (
                            <AccountTableTransaction setPageTransaction={setPageTransaction}
                                                     rowsTransaction={rowsTransaction}
                                                     setRowsTransaction={setRowsTransaction}
                                                     setOrderByTransaction={setOrderByTransaction}
                                                     setFilterTransaction={setFilterTransaction}
                                                     reportDataTransaction={reportDataTransaction}
                                                     totalCountTransaction={totalCountTransaction}
                                                     loadingTransaction={loadingTransaction}
                                                     data={data}
                            />
                        )
                    }
                    {
                        tradingAccountPermission?.view?.tradingOpenPosition && (
                            <AccountPosition detail={data} traderId={data?.traderId} accountId={data?.accountId} tradingAccountPermission={tradingAccountPermission} />
                        )
                    }
                    {
                        tradingAccountPermission?.view?.tradingHistory && (
                            <AccountTabDetails traderId={data?.traderId} user={data?.user} detail={data} accountId={data?.accountId} 
                                               tradingAccountPermission={tradingAccountPermission} refreshQuery={refreshQuery}
                                               tradingAccountMT5Permission={tradingAccountMT5Permission}/>
                        )
                    }
                </Col>
            ),
        },
        {
            key: "2",
            label: t("tradingAccount.clientInfo"),
            shown: clientsUserPermission?.view,
            children: (
                <Col span={24} style={{marginTop: "15px"}}>
                    <ClientDetail data={userDetail} permissionUser={permission?.user}/>
                </Col>
            ),
        },
        {
            key: "3",
            label: t("tradingAccount.deposit_withdrawal"),
            shown: tradingAccountAccTxnPermission?.view,
            children: (
                <Col span={24} style={{marginTop: "15px"}}>
                    <AccountTransaction id={id} data={data} refreshQuery={refreshQuery}
                                        refreshQueryTransaction={getBalanceHistoryReport}/>
                </Col>
            ),
        },
        {
            key: "4",
            label: t("tradingAcc.credit"),
            shown: tradingAccountCreditTxnPermission?.view,
            children: (
                <Col span={24} style={{marginTop: "15px"}}>
                    <AccountTransactionCredit id={id} data={data} refreshQuery={refreshQuery}
                                              refreshQueryTransaction={getCreditHistoryReport}
                                              setPageCredit={setPageCredit}
                                              rowsCredit={rowsCredit}
                                              setRowsCredit={setRowsCredit}
                                              setOrderByCredit={setOrderByCredit}
                                              setFilterCredit={setFilterCredit}
                                              reportDataCredit={reportDataCredit}
                                              totalCountCredit={totalCountCredit}
                                              loadingCredit={loadingCredit}/>
                </Col>
            ),
        },
        {
            key: "5",
            label: t("tradingAccount.tradingVoucher"),
            shown: tradingAccountVoucherTxnPermission?.view,
            children: (
                <Col span={24} style={{marginTop: "15px"}}>
                    <AccountTransactionTradingVoucher id={id} data={data} refreshQuery={refreshQuery}
                                                      refreshQueryTransaction={getVoucherHistoryReport}
                                                      setPageVoucher={setPageVoucher}
                                                      rowsVoucher={rowsVoucher}
                                                      setRowsVoucher={setRowsVoucher}
                                                      setOrderByVoucher={setOrderByVoucher}
                                                      setFilterVoucher={setFilterVoucher}
                                                      reportDataVoucher={reportDataVoucher}
                                                      totalCountVoucher={totalCountVoucher}
                                                      loadingVoucher={loadingVoucher}
                    />
                </Col>
            ),
        },
        {
            key: "6",
            label: t("tradingAccount.password"),
            shown: tradingAccountAccTxnPermission?.view,
            children: (
                <Col span={24} style={{marginTop: "15px"}}>
                    <AccountChangePassword id={id} data={data}/>
                </Col>
            ),
        },
    ];
    const tradingAccountMt5TabItems = [
        {
            key: "1",
            label: t("common.history"),
            shown: tradingAccountMT5AccTxnPermission?.view,
            children: (
                <Col span={24} style={{marginTop: "15px"}}>
                    {
                        tradingAccountPermission?.view?.balanceHistory && (
                            <AccountMt5TableTransaction setPageTransaction={setPageTransaction}
                                                        rowsTransaction={rowsTransaction}
                                                        setRowsTransaction={setRowsTransaction}
                                                        setOrderByTransaction={setOrderByTransaction}
                                                        setFilterTransaction={setFilterTransaction}
                                                        reportDataTransaction={reportDataTransaction}
                                                        totalCountTransaction={totalCountTransaction}
                                                        loadingTransaction={loadingTransaction}
                                                        data={data}
                            />
                        )
                    }
                    {
                        tradingAccountPermission?.view?.tradingOpenPosition && (
                            <AccountMt5Position detail={data} accountId={data?.accountId}/>
                        )
                    }
                    {
                        tradingAccountPermission?.view?.tradingHistory && (
                            <AccountTabDetails traderId={data?.accountId} user={data?.user} detail={data} accountId={data?.accountId} 
                                               tradingAccountPermission={tradingAccountPermission}
                                               tradingAccountMT5Permission={tradingAccountMT5Permission}/>
                        )
                    }
                </Col>
            ),
        },
        {
            key: "2",
            label: t("tradingAccount.clientInfo"),
            shown: clientsUserPermission?.view,
            children: (
                <Col span={24} style={{marginTop: "15px"}}>
                    <ClientDetail data={userDetail?.data?.getUserByID} permissionUser={permission?.user}/>
                </Col>
            ),
        },
        {
            key: "3",
            label: t("tradingAccount.deposit_withdrawal"),
            shown: tradingAccountMT5AccTxnPermission?.view,
            children: (
                <Col span={24} style={{marginTop: "15px"}}>
                    <AccountMt5Transaction id={id} data={data} refreshQuery={refreshQuery}
                                           refreshQueryTransaction={() => void (0)}/>
                </Col>
            ),
        },
        {
            key: "4",
            label: t("tradingAcc.credit"),
            shown: tradingAccountMT5CreditTxnPermission?.view,
            children: (
                <Col span={24} style={{marginTop: "15px"}}>
                    <AccountMT5TransactionCredit id={id} data={data} refreshQuery={refreshQuery}
                                                 refreshQueryTransaction={() => void (0)}
                                                 setPageCredit={setPageCredit}
                                                 rowsCredit={rowsCredit}
                                                 setRowsCredit={setRowsCredit}
                                                 setOrderByCredit={setOrderByCredit}
                                                 setFilterCredit={setFilterCredit}
                                                 reportDataCredit={reportDataCredit}
                                                 totalCountCredit={totalCountCredit}
                                                 loadingCredit={loadingCredit}/>
                </Col>
            ),
        },
        {
            key: "5",
            label: t("tradingAccount.tradingVoucher"),
            shown: tradingAccountMT5VoucherTxnPermission?.view,
            children: (
                <Col span={24} style={{marginTop: "15px"}}>
                    <AccountMt5TransactionTradingVoucher id={id} data={data} refreshQuery={refreshQuery}
                                                         refreshQueryTransaction={() => void (0)}
                                                         setPageVoucher={setPageVoucher}
                                                         rowsVoucher={rowsVoucher}
                                                         setRowsVoucher={setRowsVoucher}
                                                         setOrderByVoucher={setOrderByVoucher}
                                                         setFilterVoucher={setFilterVoucher}
                                                         reportDataVoucher={reportDataVoucher}
                                                         totalCountVoucher={totalCountVoucher}
                                                         loadingVoucher={loadingVoucher}/>
                </Col>
            ),
        },
        {
            key: "6",
            label: t("tradingAccount.password"),
            shown: tradingAccountMT5AccTxnPermission?.view,
            children: (
                <Col span={24} style={{marginTop: "15px"}}>
                    <AccountMt5ChangePassword id={id} data={data}/>
                </Col>
            ),
        },
    ];

    let tabItems: any

    if (data?.platform === "cTrader") {
        if (props?.data?.user?.clientAccountType !== "demo" && props?.data?.product?.mode !== "demo") {
            tabItems = tradingAccountTabItems?.filter((v: any) => v?.shown);
        } else {
            tabItems = tradingAccountTabItems?.filter((v: any) => v?.shown && v.key !== "5");
        }
    } else if (data?.platform === "mt5" || data?.platform === "xTrader") {
        if (props?.data?.user?.clientAccountType !== "demo" && props?.data?.product?.mode !== "demo") {
            tabItems = tradingAccountMt5TabItems?.filter((v: any) => v?.shown);
        } else {
            tabItems = tradingAccountMt5TabItems?.filter((v: any) => v?.shown && v.key !== "5");
        }
    }

    return (
        <div id="tradingAccountDetail">
            <Spin tip="Loading..." spinning={initialDataLoading}>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={5}>
                        {
                            data?.platform === "cTrader" ? (
                                <>
                                    <AccountDetailCard data={data} traderId={data?.traderId} refreshQuery={refreshQuery}/>
                                    <br/>
                                    <AccountSettingCard data={data}/>
                                </>
                            ) : (data?.platform === "mt5" || data?.platform === "xTrader") ? (
                                <>
                                    <AccountMt5DetailCard data={data} traderId={data?.traderId} refreshQuery={refreshQuery}/>
                                    <br/>
                                    <AccountMt5SettingCard data={data}/>
                                </>
                            ) : null
                        }

                    </Col>
                    <Col xs={24} md={19}>
                        <Card style={{minHeight: "94vh"}}>
                            <Tabs defaultActiveKey="1" items={tabItems}/>
                        </Card>
                    </Col>
                </Row>

            </Spin>
        </div>
    );
};

export default TradingAccountDetail_1;
