import React, {useEffect, useState} from "react";
import {Button, Space, Tabs} from "antd";
import SymbolMappingTable_1 from "./component/symbolMappingTable";
import ExportTableButton from "../../../../../../components/table/function/exportTable";
import {Link} from "react-router-dom";
import EditTableUserPreferenceButton from "../../../../../../components/table/function/editTableUserPreferenceButton";
import {useTranslation} from "react-i18next";
import FavouriteSymbol from "./component/favouriteSymbol";

const SymbolMapping_1 = (props) => {
    const {data, permission} = props;
    const {t} = useTranslation();

    const [exportData, setExportData] = useState(null);
    const [exportColumn, setExportColumn] = useState(null);
    const [currentServer, setCurrentServer] = useState();
    const [showExcelModal, setShowExcelModal] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const tableName = "Symbol Mapping";

    useEffect(() => {
        if (data?.getServerSetting?.data?.length > 0) {
            setCurrentServer(data?.getServerSetting?.data?.[0]);
        }
    }, [data?.getServerSetting?.data]);

    const tabItems = [
        ...(data?.getServerSetting?.data?.map((item, i) => {

            return {
                label: t(item?.name),
                key: i,
                children: (
                    <SymbolMappingTable_1
                        server={item}
                        serverList={data?.getServerSetting?.data}
                        setExportData={setExportData}
                        setExportColumn={setExportColumn}
                        tableName={tableName}
                        showExcelModal={showExcelModal}
                        setShowExcelModal={setShowExcelModal}
                        editPermission={permission?.symbol?.edit?.edit}
                        currentServer={currentServer}
                        setExportLoading={setExportLoading}
                    />
                ),
            };

        }) || []),
        {
            label: t("Favourite Symbol"),
            key: "favourite",
            children: <FavouriteSymbol />,
        },
    ];

    return (
        <div id="symbol-mapping">
            <h1>{t(tableName)}</h1>

            {data && data?.getServerSetting?.data && (
                <Tabs
                    defaultActiveKey="1"
                    size={"small"}
                    items={tabItems}
                    tabBarExtraContent={
                        <Space>
                            {permission?.symbol?.edit?.edit && <Button onClick={() => setShowExcelModal(true)}>{t("Excel Mapping")}</Button>}
                            {permission?.symbol?.view && (
                                <Button>
                                    <Link to="/settings/symbols/category">{t("Symbol Category")}</Link>
                                </Button>
                            )}
                            <EditTableUserPreferenceButton tableName={tableName} server={currentServer} tableColumn={exportColumn} />
                            <ExportTableButton
                                loading={exportLoading}
                                tableData={exportData}
                                tableColumn={exportColumn}
                                tableName={tableName}
                                server={currentServer}
                            />
                        </Space>
                    }
                    onTabClick={(key) => {
                        setCurrentServer(data?.getServerSetting?.data?.[key]);
                    }}
                />
            )}
        </div>
    );
};

export default SymbolMapping_1;
