import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";

const GET_CAMPAIGN_BY_ID = gql`
    query getCampaignByID($id: String!) {
        getCampaignByID(id: $id) {
            ... on Campaign {
                _id
                id
                name
                displayName {
                    label
                    language
                }
                promoMaterialsCount
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getCampaignById = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            GET_CAMPAIGN_BY_ID,
            {
                id: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );
        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_CAMPAIGN = gql`
    query getCampaign($filter: CampaignSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
        getCampaign(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on CampaignArray {
                total
                data {
                    _id
                    id
                    name
                    displayName {
                        label
                        language
                    }
                    promoMaterialsCount
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getCampaign = async ({filter}, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_CAMPAIGN,
            {
                filter: {
                    name: filter?.name || null,
                },
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_CAMPAIGN = gql`
    mutation addCampaign($input: CampaignInput) {
        addCampaign(input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on Campaign {
                _id
                id
                name
                displayName {
                    label
                    language
                }
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const addCampaign = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            ADD_CAMPAIGN,
            {
                input: {
                    name: input.name || null,
                    displayName: input.displayName || null,
                },
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UPDATE_CAMPAIGN = gql`
    mutation updateCampaign($id: String!, $input: CampaignInput) {
        updateCampaign(id: $id, input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on Campaign {
                _id
                id
                name
                displayName {
                    label
                    language
                }
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const updateCampaign = async (id, input) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_CAMPAIGN,
            {
                id: id,
                input: {
                    name: input.name || null,
                    displayName: input.displayName || null,
                },
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {
            data: null,
            loading: false,
            error: err,
        };
    }
};

const DELETE_CAMPAIGN = gql`
    mutation deleteCampaign($id: String!) {
        deleteCampaign(id: $id) {
            ... on CrmTaskApproval {
                msg
            }
            ... on Campaign {
                _id
                id
                isDeleted
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const deleteCampaign = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            DELETE_CAMPAIGN,
            {
                id: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {
            data: null,
            loading: false,
            error: err,
        };
    }
};

export {getCampaignById, getCampaign, addCampaign, updateCampaign, deleteCampaign};
