import React, {useEffect, useState} from "react";
import {Col, Row} from "antd";
import RegistrationFieldCard from "./registrationFieldCard";
import RegistrationSettingCard from "./registrationSettingCard";
import RegistrationPreview from "./registrationPreview";
import {useRecoilValue} from "recoil";
import {registrationSettingState} from "../../../state";
import {formatProfileInformation} from "../../../../../users/_common/function";

const RegistrationSettingTab = (props) => {
    const {permission, clientField, categoryFilter, labelFilter} = props

    const registrationSetting = useRecoilValue(registrationSettingState);
    const [fields, setFields] = useState<any>({
        basicField: [],
        loginField: [],
        residenceField: [],
        additionalField: [],
        corporateField: [],
        registrationField: [],
        demoField: [],
    });

    useEffect(() => {
        formatProfileInformation(fields, setFields, registrationSetting);
    }, [registrationSetting]);


    return (
        <div id={"registration-setting"}>
            <Row gutter={[32, 32]} style={{marginTop: 16, width: "100%"}}>
                <Col span={6}>
                    <RegistrationFieldCard permission={permission} clientField={clientField} categoryFilter={categoryFilter} labelFilter={labelFilter} />
                </Col>
                <Col span={7}>
                    <RegistrationSettingCard fields={fields} setFields={setFields} permission={permission}/>
                </Col>
                <Col span={11}>
                    <RegistrationPreview fields={fields}/>
                </Col>
            </Row>
        </div>
    )
}

export default RegistrationSettingTab