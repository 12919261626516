import {useState} from "react";
import {getLevelSetting} from "../../../../../api/restful/arena/levelSetting";
import {getDescription} from "../../../../../api/restful/arena/description";

export const useGetLevelSetting = () => {
    const [data, setData] = useState<any>(null)

    const fetchLevelSetting = async () => {
        try {
            const {data} = await getLevelSetting()

            console.log(data)
            setData(data)
        } catch (e) {

        }
    }

    return [data, fetchLevelSetting] as const
}

export const useGetDescription = () => {
    const [data, setData] = useState<any>(null)

    const fetchDescription = async () => {
        try {
            const {data} = await getDescription()

            console.log(data)
            setData(data)
        } catch (e) {

        }
    }

    return [data, fetchDescription] as const
}