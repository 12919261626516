import React from "react";
import {useRecoilValue} from "recoil";
import {useTranslation} from "react-i18next";
import {Row, Space} from "antd";
import TableAntDesign from "../../../../../components/table/tableAntDesign";
import {columns} from "../../function/columns";
import {selfPermissionObj} from "../../../../../../recoil_state";
import EditTableUserPreferenceButton from "../../../../../components/table/function/editTableUserPreferenceButton";
import ExportTableButton from "../../../../../components/table/function/exportTable";

const Country_1 = (props) => {
    const {data, exportData, loading, totalCount, setLimit, limit, setOffset, setFilter, setOrderBy, tableName} = props;
    const {t} = useTranslation();
    const permission = useRecoilValue<any>(selfPermissionObj);

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize);
        setOffset(limit * (page - 1));
    };

    return (
        <>
            <Row justify={"space-between"} className={"margin-bottom-0-75"}>
                <h1>{t(tableName)}</h1>
                <Space>
                    <EditTableUserPreferenceButton tableName={tableName} tableColumn={columns} />
                    <ExportTableButton tableData={exportData} tableColumn={columns} tableName={tableName} />
                </Space>
            </Row>
            <TableAntDesign
                data={data}
                tableName={tableName}
                columns={columns}
                size={"small"}
                loading={loading}
                filter={setFilter}
                order={setOrderBy}
                actionPermission={permission?.brandSetting?.currencySetting?.edit?.edit}
                pagination={{
                    pageSize: limit,
                    showSizeChanger: true,
                    position: "bottomCenter",
                    total: totalCount?.getCurrencySettingCount,
                    onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                    showTotal: (total) => <p>Total {total} items</p>,
                }}
                sort={false}
            />
        </>
    );
};
export default Country_1;
