import {clearTypeNameFromInputList, clearTypeNameFromInputObject} from "../../../../api/graphql/_commonFunction";

const processingTimeOptions = [
    {label: "Instantly", value: "Instantly"},
    {label: "1 Working Day", value: "1 Working Day"},
    {label: "2-6 Working Day", value: "2-6 Working Day"},
    {label: "Variable Timeline", value: "Variable Timeline"},
];

const getListFromObject = (array, field, language) => {
    if (!array) {
        return [];
    }

    const index = array.findIndex((item) => item?.language === language);

    if (!array || !array[index]) {
        return [];
    }

    return array[index][field];
};

const setupPspSettingForm = (form, data) => {
    let isProcessingTimeMatched = processingTimeOptions?.some((v) => v?.value === data?.processingTime);
    data &&
        Object.keys(data)?.map((fieldName) => {
            switch (fieldName) {
                case "createdAt":
                case "createdBy":
                case "updatedAt":
                case "updatedBy":
                case "__typename":
                case "id":
                case "_id":
                case "defaultLogo_light":
                case "defaultLogo_dark":
                case "defaultLogoSmall_light":
                case "defaultLogoSmall_dark":
                    break;
                case "processingTime":
                    if (isProcessingTimeMatched) {
                        form.setValue(fieldName, data[fieldName]);
                    } else {
                        form.setValue(fieldName, "");
                        form.setValue("processingTimeInput", data[fieldName]);
                    }
                    break;
                case "logo_light":
                case "logo_dark":
                case "logoSmall_light":
                case "logoSmall_dark":
                    form.setValue(fieldName, data[fieldName] && data[fieldName][0]?.link);
                    break;
                case "bankDetails":
                    // form.setValue(
                    //   fieldName,
                    //   clearTypeNameFromInputList(data[fieldName] || {})
                    // );
                    form.setValue(fieldName, clearTypeNameFromInputObject(data[fieldName] || {}));
                    break;
                case "localDepoBankDetails":
                    form.setValue(fieldName, clearTypeNameFromInputObject(data[fieldName] || {}));
                    break;
                default:
                    form.setValue(fieldName, data[fieldName]);
                    break;
            }
        });
};

const setupPspSettingCurrencyForm = (form, data) => {
    data &&
        data?.map((cur) => {
            let pspCurrencyData = cur?.pspCurrency || {};
            let currencyName = pspCurrencyData?.currency?.replace(/\./g, "_");
            Object.keys(pspCurrencyData)?.map((fieldName) => {
                switch (fieldName) {
                    case "id":
                    case "_id":
                    case "__typename":
                    case "availableBank":
                    case "createdAt":
                    case "createdBy":
                    case "updatedAt":
                    case "updatedBy":
                    case "pspCurrency":
                    case "pspSetting":
                        break;
                    default:
                        form.setValue(`${currencyName}-${fieldName}`, pspCurrencyData[fieldName]);
                        break;
                }
            });
        });
};

const setupPspSettingDetailForm = (form, data) => {
    data &&
        data?.map((cur) => {
            let currencyName = cur?.pspCurrency?.currency?.replace(/\./g, "_");
            let paymentMethodList = cur?.children || [];
            paymentMethodList.map((paymentMethodData) => {
                let paymentMethodName = paymentMethodData?.paymentMethod?.replace(/[.-]/g, "_") || {};
                Object.keys(paymentMethodData)?.map((fieldName) => {
                    switch (fieldName) {
                        case "__typename":
                        case "id":
                        case "_id":
                        case "createdAt":
                        case "createdBy":
                        case "updatedAt":
                        case "updatedBy":
                        case "pspSetting":
                            // case "pspCurrency":
                            break;
                        default:
                            form.setValue(`${currencyName}-${paymentMethodName}-${fieldName}`, paymentMethodData[fieldName]);
                            break;
                    }
                });
            });
        });
};

const setupFileObjectInput = (url) => {
    return [
        {
            // displayName: null,
            // fileName: null,
            // path: null,
            fileList: null,
            language: null,
            // document: null,
            link: url || null,
        },
    ];
};

const setupPspSettingInput = (formMode, data) => {
    let result: any = {};
    data &&
        Object.keys(data)?.map((fieldName) => {
            switch (fieldName) {
                case "createdAt":
                case "createdBy":
                case "updatedAt":
                case "updatedBy":
                case "__typename":
                case "id":
                case "_id":
                case "selectedCurrency":
                case "processingTimeInput":
                    break;
                case "processingTime":
                    result[fieldName] = data[fieldName] || data["processingTimeInput"];
                    break;
                case "logo_light":
                case "logo_dark":
                case "logoSmall_light":
                case "logoSmall_dark":
                    result[fieldName] =
                        data[fieldName] && typeof data[fieldName] === "string"
                            ? setupFileObjectInput(data[fieldName])
                            : clearTypeNameFromInputList(data[fieldName]);
                    break;
                case "bankDetails":
                    // result[fieldName] = data[fieldName]
                    //   ? clearTypeNameFromInputList(data[fieldName])
                    //   : data[fieldName];
                    result[fieldName] =
                        data[fieldName] && Object.keys(data[fieldName]).length > 0 ? clearTypeNameFromInputObject(data[fieldName] || {}) : null;
                    break;
                case "localDepoBankDetails":
                    result[fieldName] =
                        data[fieldName] && Object.keys(data[fieldName]).length > 0 ? clearTypeNameFromInputObject(data[fieldName] || {}) : null;
                    break;
                default:
                    result[fieldName] = data[fieldName];
                    break;
            }
        });

    if (formMode === "add") {
        result["isMerchantSetup"] = true;
    }
    return result;
};

const setupPspSettingCurrencyInput = (formMode, values, pspSettingId, selectedCurrencies?) => {
    const res = Object.values(
        Object.keys(values).reduce((result, key) => {
            const [currency, fieldName] = key.split("-");
            let currencyName = currency?.replace(/\_/g, ".");
            result[currencyName] = {
                ...result[currencyName],
                [fieldName]: values[key],
            };
            return result;
        }, {})
    ).reduce((result: any, curObj: any) => {
        if (formMode === "add") {
            const currency = curObj?.currency;
            if (selectedCurrencies?.includes(currency)) {
                result.push({...curObj});
            }
        } else {
            result.push({...curObj, pspSetting: pspSettingId});
        }
        return result;
    }, []);

    return res;
};

const setupPspSettingDetailInput = (pspSettingName, formMode, values, pspSettingId, selectedCurrencies?) => {
    let res: any = [];
    let data =
        Object.keys(values)?.reduce((result, key) => {
            const group = key?.split("-")[0] + "-" + key?.split("-")[1];
            const fieldName = key?.split("-")[2];

            if (!result[group]) {
                result[group] = [];
            }

            result[group] = {...result[group], [fieldName]: values[key]};

            return result;
        }, {}) || [];

    Object.values(data)?.forEach((curObj: any) => {
        if (pspSettingName.includes("LocalDepo") || pspSettingName.includes("BankWire")) {
            curObj.formType = "document";
        }
        if (formMode === "edit") {
            res.push({
                ...curObj,
                pspSetting: pspSettingId,
                pspCurrency: curObj?.pspCurrency?._id,
            });
        } else if (formMode === "add") {
            const currency = curObj.pspCurrency?.currency;
            if (selectedCurrencies?.includes(currency)) {
                delete curObj.pspCurrency;
                res.push({
                    ...curObj,
                    selectedCurrency: currency,
                });
            }
        }
    });
    return res;
};

export {
    processingTimeOptions,
    getListFromObject,
    setupPspSettingForm,
    setupPspSettingCurrencyForm,
    setupPspSettingDetailForm,
    setupPspSettingInput,
    setupPspSettingCurrencyInput,
    setupPspSettingDetailInput,
};
