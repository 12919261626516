import React, {useCallback, useEffect, useState} from "react";
import {useGeneralListFunction} from "../../../../function/generalListFunction";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {currentTabState, generalListCountryState, tabBarExtraContentState} from "../../../../state";
import {Button, Card, Form, List, Popconfirm, Row, Space, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {DeleteOutlined} from "@ant-design/icons";
import countryList from "../../../../../../../../config/countryList";
import GeneralListCountryForm from "./modalForm/generalListCountryForm";
import {useFlowBuilderFunction} from "../../../../function/flowBuilderFunction";
import {createUserVerificationCountry} from "../../../../../../../api/graphql/generalList";
import {getIsMainMasterAdmin} from "../../../../../../../api/graphql/_common";
import ImportDBTableButton from "../importDBTable";
import ExportDBTableButton from "../exportDBTable";

const GeneralListCountry = ({permission}) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const {createGeneralListCountry, updateGeneralListCountry, deleteGeneralListCountry} = useGeneralListFunction();
    const {duplicateUserVerificationCountryFlowFunction} = useFlowBuilderFunction();

    const country = useRecoilValue<any>(generalListCountryState);

    const [isOpen, setIsOpen] = useState(false);
    const [createLoading, setCreateLoading] = useState(false);
    const setTabBarExtraContent = useSetRecoilState<any>(tabBarExtraContentState);
    const currentTab = useRecoilValue(currentTabState);
    const [isMainMasterAdmin, setIsMainMasterAdmin] = useState(false);

    const checkIsMasterAdmin = async () => {
        let response = await getIsMainMasterAdmin();
        setIsMainMasterAdmin(response?.isMainMasterAdmin);
    };

    useEffect(() => {
        checkIsMasterAdmin();
    }, []);

    useEffect(() => {
        if (currentTab === "country" && permission?.add?.add) {
            setTabBarExtraContent(
                <>
                    <Tooltip title={t("accountVerification.addCountry")}>
                        <Button
                            onClick={() => {
                                form.resetFields();
                                setIsOpen(true);
                            }}
                        >
                            {t("common.add")}
                        </Button>
                    </Tooltip>
                    {isMainMasterAdmin && <ImportDBTableButton />}
                    {isMainMasterAdmin && <ExportDBTableButton />}
                </>
            );
        }
    }, [currentTab, isMainMasterAdmin, permission?.add?.add]);

    const setEnableCountry = async (value, country) => {
        await updateGeneralListCountry(country, value);
    };

    const addCountry = async (value) => {
        setCreateLoading(true);
        const res = await createGeneralListCountry(value?.country, !!value?.from);
        if (value?.from && res?.createUserVerificationCountry) {
            await duplicateUserVerificationCountryFlowFunction({from: value?.from, to: res?.createUserVerificationCountry?._id}, null);
        }
        setCreateLoading(false);
        setIsOpen(false);
    };

    const countryOptions = useCallback(() => {
        const defaultList = [
            // {label: "Default", value: "Default"},
            ...countryList?.map((d) => {
                return {label: d?.name, value: d?.name};
            }),
        ];

        const filteredList = defaultList?.filter((item) => {
            return !country.some((c) => c?.country === item?.label);
        });

        return filteredList;
    }, [country]);

    return (
        <div id={"general-list-country"} className={"margin-top-0-75"}>
            {permission?.add?.add && (
                <GeneralListCountryForm
                    createLoading={createLoading}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    addCountry={addCountry}
                    countryOptions={countryOptions}
                    form={form}
                />
            )}
            <List
                grid={{gutter: 16, column: 4}}
                dataSource={country?.filter((d) => d?.country !== "Default")}
                renderItem={(item: any) => (
                    <List.Item>
                        <Card size={"small"}>
                            <Row justify={"space-between"}>
                                <span>{item?.country}</span>
                                <Space>
                                    {/*<Tooltip title={item?.isEnabled ? t("common.enabled") : t("common.disabled")}>*/}
                                    {/*    <Switch defaultChecked={item?.isEnabled}*/}
                                    {/*            onClick={(e) => setEnableCountry(e, item?._id)}*/}
                                    {/*    />*/}
                                    {/*</Tooltip>*/}
                                    {permission?.delete?.delete && (
                                        <Tooltip title={t("common.delete")}>
                                            <Popconfirm
                                                placement="left"
                                                title={t("accountVerification.popconfirm.title.country.delete")}
                                                description={t("accountVerification.popconfirm.description.country.delete")}
                                                onConfirm={async () => {
                                                    await deleteGeneralListCountry(item?._id);
                                                }}
                                                okText={t("common.yes")}
                                                cancelText={t("common.no")}
                                            >
                                                <Button icon={<DeleteOutlined />} type={"link"} danger size={"small"} />
                                            </Popconfirm>
                                        </Tooltip>
                                    )}
                                </Space>
                            </Row>
                        </Card>
                    </List.Item>
                )}
            />
        </div>
    );
};

export default GeneralListCountry;
