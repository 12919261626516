import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import {Button, Col, Form, Row} from "antd";

import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import yupFormSchema from "../../../../../../components/yup/yupFormSchema";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import {verticalFormLayout} from "../../../../../../components/form/formWrapper";
import {
    useAddLanguageMutation,
    useUpdateLanguageMutation,
    useUploadLanguageMutation,
} from "../../../../../../api/graphql/language";
import RadioGroupFormItem from "../../../../../../components/form/radioGroupFormItem";
import SelectFormItem from "../../../../../../components/form/selectFormItem";
import {dataConversion} from "../../../function/common";
import {getLanguageStandardListing} from "../../../../../../api/graphql/_common";
import useAuthorize from "../../../../../../_common/authorize";

const LanguageForm = (props) => {
    const {
        mode,
        item,
        closeModal,
        translationFile,
        setTranslationFile,
        languageCode,
        setLanguageCode,
        languageData,
    } = props;
    const {t} = useTranslation();
    const {languageStandardListing} = getLanguageStandardListing();
    const [errMsg, setErrMsg] = useState("");
    const yupSchema = yup.object().shape({
        name: yupFormSchema.string("name", {
            required: mode !== "upload" ? true : false,
            requiredErrorMessage: "Language Name is a required field"
        }),
        isActive: yupFormSchema.boolean("isActive"),
    });

    const [initialValues] = useState(() => ({
        name: item?.name || "",
        code: item?.code || "",
        isActive: item?.isActive ?? true,
    }));

    var form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
        defaultValues: initialValues,
    });

    const {addLanguage, loading: addLoading} = useAddLanguageMutation();
    const {updateLanguage, loading: updateLoading} = useUpdateLanguageMutation();
    const {uploadLanguage, loading: uploadLoading} = useUploadLanguageMutation();
    const {systemErrorNotification} = useAuthorize()

    const onCancel = () => {
        closeModal();
    };

    const mapLanguageToOptions = (record) => {
        let result: any[] = [];
        const existingData =
            languageData?.map((item) => {
                return item?.name;
            }) || [];
        record?.forEach((item) => {
            result.push({
                label: item?.name + " - " + item?.nativeName,
                value: item?.name,
            });
        });
        result = result?.filter((item) => {
            return !existingData?.includes(item?.value);
        });
        return result;
    };

    const onLanguageNameChange = (input) => {
        try {
            const [languageRecord] = languageStandardListing?.getLanguageStandardListing?.filter((item) => item?.name === input) || [];
            setLanguageCode(languageRecord?.code);
            if (mode === "add") {
                setTranslationFile(
                    dataConversion([...languageData?.filter((item) => item?.code === "en")])
                );
            }
        } catch (e) {
            systemErrorNotification()
        }
    };

    const onSubmit = async (e) => {
        var updatedTranslationFile = translationFile;
        updatedTranslationFile = updatedTranslationFile.map((item) => {
            var temp = item;
            delete temp.ids;
            return temp;
        });
        const result = {
            ...e,
            translationFile: updatedTranslationFile,
            code: languageCode || null,
        };
        try {
            const response =
                mode === "add"
                    ? await addLanguage(result)
                    : mode === "upload"
                    ? await uploadLanguage(result)
                    : await updateLanguage(item?._id, result);
            const {data} = response;
            if (mode === "add" && data?.addLanguage?.errMsg) {
                setErrMsg(data?.addLanguage?.errMsg);
            } else if (mode === "edit" && data?.updateLanguage?.errMsg) {
                setErrMsg(data?.updateLanguage?.errMsg);
            } else if (mode === "upload" && data?.uploadLanguage?.errMsg) {
                setErrMsg(data?.uploadLanguage?.errMsg);
            } else {
                setErrMsg("");
                closeModal();
            }
        } catch (error: any) {
            systemErrorNotification()
        }
    };

    useEffect(() => {
        form.setValue("name", item?.name);
    }, [item]);

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="form-1">
                <>
                    {errMsg && <p style={{color: "red"}}>{errMsg}</p>}
                    <Row gutter={10}>
                        <Col xs={12}>
                            <SelectFormItem
                                name={"name"}
                                label={t("Language Name")}
                                placeholder={t("Language Name")}
                                layout={verticalFormLayout}
                                disabled={mode === "view" || mode === "upload"}
                                options={mapLanguageToOptions(languageStandardListing?.getLanguageStandardListing)}
                                onChange={onLanguageNameChange}
                                showSearch={true}
                                required={mode !== "upload"}
                                filterOption={(input, option) =>
                                    (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            />
                        </Col>
                        <Col xs={12}>
                            <InputFormItem
                                name={"code"}
                                label={t("Language Code")}
                                placeholder={t("Language Code")}
                                value={languageCode}
                                layout={verticalFormLayout}
                                disabled
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <RadioGroupFormItem
                                name={"isActive"}
                                label={t("Status")}
                                options={[
                                    {label: t("Active"), value: true},
                                    {label: t("Inactive"), value: false},
                                ]}
                                layout={verticalFormLayout}
                                required
                                disabled={mode === "view"}
                            />
                        </Col>
                    </Row>
                </>

                {props.children}
                {
                    mode === "view" &&
                    <Form.Item className="text-align-right" style={{paddingTop: "12px"}}>
                        {onCancel && (
                            <Button
                                onClick={onCancel}
                                icon={<CloseOutlined/>}
                                className="button-cancel-1"
                            >
                                {t("Close")}
                            </Button>
                        )}
                    </Form.Item>
                }
                {mode !== "view" &&
                <Form.Item className="text-align-right" style={{paddingTop: "12px"}}>
                    {onCancel && (
                        <Button
                            onClick={onCancel}
                            icon={<CloseOutlined/>}
                            className="button-cancel-1"
                        >
                            {t("common.cancel")}
                        </Button>
                    )}
                    <Button
                        onClick={form.handleSubmit(onSubmit)}
                        className="button-submit-1"
                        loading={addLoading || updateLoading || uploadLoading}
                    >
                        {t("common.submit")}
                    </Button>
                </Form.Item>
                }
            </form>
        </FormProvider>
    );
};

export default LanguageForm;
