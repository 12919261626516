import React, {useState} from "react";
import {Button, Popconfirm, Row, Space, Tooltip} from "antd";
import CreateUpdateModal from "./components/createUpdateModal";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import {useTranslation} from "react-i18next";
import {columns} from "../../function/tableColumn";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../recoil_state";
import {deleteBoxSetting} from "../../../../../../api/restful/arena/boxSetting";
import "./css/style.css"
import RewardReminder from "../../../../_common/rewardReminder";

const BoxSetting_1 = ({boxes, loading, fetchBoxes, rewards}) => {
    const {t} = useTranslation()
    const [editModalIsOpen, setEditModalIsOpen] = useState(false)
    const [currentItem, setCurrentItem] = useState(null)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const column = columns()
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const [reminderModalOpen, setReminderModalOpen] = useState(false)
    const [newBoxSetting, setNewBoxSetting] = useState(null)

    const handleModalClose = () => {
        setEditModalIsOpen(false)
        setCurrentItem(null)
    }

    const handleModalOpen = (item?) => {
        setEditModalIsOpen(true)
        setCurrentItem(item || null)
    }

    const handleBoxCreatedRemind = (item) => {
        setNewBoxSetting(item)
        setReminderModalOpen(true)
    }

    const handleDeleteBoxSetting = async (id) => {
        try {
            setDeleteLoading(true)
            const response = await deleteBoxSetting({_id: id})

            if (response?.status === "Success") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Delete Box Successfully",
                    key: "arenaBoxSettingNotification",
                });

                fetchBoxes()
            } else {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: `Delete Box Failed: ${response?.errObj}`,
                    key: "arenaBoxSettingNotification",
                });
            }
        } catch (e) {
        } finally {
            setDeleteLoading(false)
        }
    }

    return (
        <div id={"box-setting"}>
            <Row className={"margin-bottom-0-75"} justify={"space-between"}>
                <h1 className={"margin-bottom-0"}>Box Setting</h1>
                <Button type={"primary"} onClick={() => handleModalOpen()}>Create</Button>
            </Row>

            <TableAntDesign
                data={boxes || []}
                columns={[
                    ...column,
                    {
                        title: t("common.action"),
                        key: "action",
                        render: (_, record) => {

                            return (
                                <Space>
                                    <Tooltip title={t("Edit")}>
                                        <a onClick={() => handleModalOpen(record)}>
                                            <EditOutlined/>
                                        </a>
                                    </Tooltip>
                                    <Popconfirm
                                        placement="left"
                                        title={t("Delete Box Setting")}
                                        description={t("Are you sure you want to delete this box setting?")}
                                        onConfirm={() => handleDeleteBoxSetting(record?._id)}
                                        okText={t("common.yes")}
                                        okButtonProps={{loading: deleteLoading}}
                                        cancelText={t("common.no")}
                                    >
                                        <a style={{color: "red"}}><DeleteOutlined/></a>
                                    </Popconfirm>
                                </Space>
                            )
                        }
                    }
                ]}
                size={"small"}
                isTableLocalSearch={true}
                tableName={"Arena Box Setting"}
            />

            <CreateUpdateModal
                open={editModalIsOpen}
                handleClose={handleModalClose}
                item={currentItem}
                fetchBoxes={fetchBoxes}
                rewards={rewards}
                handleBoxCreatedRemind={handleBoxCreatedRemind}
            />
            <RewardReminder
                open={reminderModalOpen}
                handleClose={() => setReminderModalOpen(false)}
                item={newBoxSetting}
                type={"box"}
            />
        </div>
    )
}

export default BoxSetting_1