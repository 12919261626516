import {Button, Form} from 'antd';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useFormContext} from 'react-hook-form';
import FormErrors from './formErrors';
import {PlusOutlined, UploadOutlined} from '@ant-design/icons';
import {Modal, Upload} from 'antd';
import {getConfigByName} from "../../../config";
import {BRAND_NAME} from "../../../config/brandVariable";
import { useSetRecoilState } from 'recoil';
import { notificationMessage } from '../../../recoil_state';
import { t } from 'i18next';

const UploadSingleChunkFormItem = (props) => {
    const setNotificationMessage = useSetRecoilState(notificationMessage);

    const {
        layout,
        label,
        name,
        externalErrorMessage,
        onChange,
        hint,
        required,
        listType,
        maxLength,
        className,
        fileList,
        onRemove,
        disabled,
        mode,
        condition,
        showUploadList,
        labelCol
    } = props

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileField, setFileField] = useState([]);

    const {
        register,
        watch,
        setValue,
        formState: {touchedFields, isSubmitted, errors}
    } = useFormContext()

    const errorMessage = FormErrors.errorMessage(
        name,
        errors,
        touchedFields,
        isSubmitted,
        externalErrorMessage
    )

    const formHelperText = errorMessage || hint

    useEffect(() => {
        register(name)
    }, [register, name])

    const getBase64 = (file): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
        if (!file?.response[0]?.link && !file?.preview) {
            file.preview = await getBase64(file?.thumbUrl);
        }
        setPreviewImage(file?.response[0]?.link || (file?.preview));
        setPreviewOpen(true);
        setPreviewTitle(file?.name || file?.response[0]?.link!.substring(file?.response[0]?.link!.lastIndexOf('/') + 1));
    };

    const textUploadButton = (
        <Button icon={<UploadOutlined/>}>Click to Upload</Button>
    )

    const pictureUploadButton = (
        <div>
            <div className='upload-button-1'><PlusOutlined/> Upload ( Max: {maxLength} )</div>
        </div>
    );

    const pictureCardUploadButton = (
        <div>
            <PlusOutlined/>
            <div style={{marginTop: 8}}>Upload</div>
        </div>
    );

    const brandConfig = getConfigByName(BRAND_NAME)
    const chunkSize = 80 * 1024 * 1024; // 80MB chunk size

    const beforeUpload = (file) => {
        uploadChunks(file)
        return false; // Prevent automatic upload
    };

    const assembleFile = async (fileName, totalChunks) => {
        try {
          const response:any = await fetch(`${brandConfig?.backendURL}/api/assemble/chunk?fileName=${encodeURIComponent(fileName)}&totalChunks=${totalChunks}`, {
            method: 'POST',
          });
          if (response?.status === 200) {
            let result = await response.json()
            setValue(name, result?.link, {shouldValidate: true})
            setFileField(result?.fileList)
            onChange && onChange(result)
          } else {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: t("common.uploadError"),
                key: "systemNotification",
            });
          }
        } catch (error) {
            console.log(error)
            setNotificationMessage({
                status: "error",
                title: "",
                msg: t("common.assembleError"),
                key: "systemNotification",
            });
        }
      };

    const uploadChunks = async (file) => {
        const totalChunks = Math.ceil(file?.size / chunkSize);
        const chunkPromises:any = [];
        let fileName = file?.name?.split('.'); 
        let fileExtension = fileName.pop();
        let finalFileName = fileName.join('.');

        for (let i = 0; i < totalChunks; i++) {
            const start = i * chunkSize;
            const end = Math.min(start + chunkSize, file?.size);
            const chunk = file?.slice(start, end);
    
            const formData = new FormData();
            formData.append('chunk', chunk);

            const uploadPromise:any = fetch(`${brandConfig?.backendURL}/api/upload/chunk?fileName=${encodeURIComponent(`${finalFileName}-${i}.${fileExtension}`)}`, {
                method: 'POST',
                body: formData,
            });
    
            chunkPromises.push(uploadPromise);
        }
        try {
            await Promise.all(chunkPromises);
            assembleFile(file?.name, totalChunks);
        } catch (error) {
            console.log(error)
            setNotificationMessage({
                status: "error",
                title: "",
                msg: t("common.uploadError"),
                key: "systemNotification",
            });
        }
    };

    return (
        <Form.Item
            {...layout}
            labelCol={labelCol || layout?.labelCol}
            className={className}
            label={label}
            required={required}
            validateStatus={errorMessage ? 'error' : 'success'}
            help={formHelperText}
        >
            <Upload
                id={name}
                beforeUpload={beforeUpload}
                showUploadList={showUploadList}
                listType={listType}
                fileList={fileList || (watch(name) ? [] : fileField)}
                onChange={(e: any) => {
                    setValue(name, e.fileList[0]?.response && e.fileList[0]?.response[0]?.link, {shouldValidate: true})
                    setFileField(e.fileList)
                    onChange && onChange(e)

                }}
                multiple={false}
                maxCount={1}
                onRemove={(e) => {
                    onRemove && onRemove(e)
                }}
                disabled={disabled}
            >
                {listType === "picture" && pictureUploadButton}
                {listType === "picture-card" && pictureCardUploadButton}
                {listType === "text" && textUploadButton}
            </Upload>
            {condition && condition.length > 0 && (
                condition.map(item => {
                    return (<p style={{color:"red", fontSize: "12px", maxWidth:"160px"}}>{item}</p>)
                })
            )}
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="placeholder" style={{width: '100%'}} src={previewImage}/>
            </Modal>
        </Form.Item>
    )
}

UploadSingleChunkFormItem.defaultProps = {
    required: false,
    disabled: false,
    maxLength: 1,
    mode: "image"
}

UploadSingleChunkFormItem.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    externalErrorMessage: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    layout: PropTypes.object,
    hint: PropTypes.string,
    listType: PropTypes.string,
    maxLength: PropTypes.number,
    className: PropTypes.string,
    fileList: PropTypes.array,
    onRemove: PropTypes.func,
    mode: PropTypes.string,
    condition: PropTypes.array,
    showUploadList: PropTypes.bool,
    labelCol: PropTypes.object
}

export default UploadSingleChunkFormItem