import { gql, useMutation, useQuery } from "@apollo/client";

const GET_DESK_NETWORK_TREE_COUNT = gql`
  query getDeskNetworkTreeCount {
    getDeskNetworkTreeCount
  }
`;

const getDeskNetworkTreeCount = () => {
  try {
    const { loading, error, data } = useQuery(GET_DESK_NETWORK_TREE_COUNT, {
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_DESK_NETWORK_TREE_BY_ID = gql`
  query getDeskNetworkTreeByID($id: String!) {
    getDeskNetworkTreeByID(id: $id) {
      ... on DeskNetworkTree {
        id
        parent {
          name
        }
        deskid {
          name
        }
        left
        right
        createdBy {
          username
        }
        updatedBy {
          username
        }
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getDeskNetworkTreeById = (id) => {
  try {
    const { loading, error, data } = useQuery(GET_DESK_NETWORK_TREE_BY_ID, {
      variables: {
        id: id,
      },
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const GET_DESK_NETWORK_TREE = gql`
  query getDeskNetworkTree {
    getDeskNetworkTree {
      ... on DeskNetworkTreeArray {
        data {
          _id
          parent {
            _id
            name
            description
          }
          deskid {
            _id
            name
            description
            parent {
              _id
            }
          }
          left
          right
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getDeskNetworkTree = () => {
  try {
    const { loading, error, data, refetch } = useQuery(GET_DESK_NETWORK_TREE, {
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data, refetch };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const SORT_DESK_NETWORK_TREE = gql`
  mutation sortDeskNetworkTree {
    sortDeskNetworkTree {
      ... on DeskNetworkTreeArray {
        data {
          _id
          parent {
            _id
            name
            description
          }
          deskid {
            _id
            name
            description
            parent {
              _id
            }
          }
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useSortDeskNetworkTreeMutation = (): {
  sortDeskNetworkTree: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const [sortDeskNetworkTreeMutation, { data, loading, error }] = useMutation(
      SORT_DESK_NETWORK_TREE
    );

    const sortDeskNetworkTree = () => {
      return sortDeskNetworkTreeMutation();
    };

    return { sortDeskNetworkTree, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return {
      sortDeskNetworkTree: null,
      data: null,
      loading: false,
      error: err,
    };
  }
};

const SORT_TARGET_DESK_NETWORK_TREE = gql`
  mutation sortTargetDeskNetworkTree {
    sortTargetDeskNetworkTree {
      ... on DeskNetworkTreeArray {
        data {
          _id
          parent {
            _id
            name
            description
          }
          deskid {
            _id
            name
            description
            parent {
              _id
            }
          }
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const useSortTargetDeskNetworkTreeMutation = (): {
  sortDeskNetworkTree: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const [sortDeskNetworkTreeMutation, { data, loading, error }] = useMutation(
      SORT_TARGET_DESK_NETWORK_TREE
    );

    const sortDeskNetworkTree = (deskid) => {
      return sortDeskNetworkTreeMutation({
        variables: {
          deskid: deskid,
        },
      });
    };

    return { sortDeskNetworkTree, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return {
      sortDeskNetworkTree: null,
      data: null,
      loading: false,
      error: err,
    };
  }
};

export {
  getDeskNetworkTreeCount,
  getDeskNetworkTreeById,
  getDeskNetworkTree,
  useSortDeskNetworkTreeMutation,
  useSortTargetDeskNetworkTreeMutation,
  GET_DESK_NETWORK_TREE
};
