import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useRecoilState, useSetRecoilState} from "recoil";
import {FormProvider, useForm} from "react-hook-form";
import {Button, Col, Form, Row, Space} from "antd";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import yupFormSchema from "../../../../../../../../components/yup/yupFormSchema";
import SelectFormItem from "../../../../../../../../components/form/selectFormItem";
import {horizontalFormLayout} from "../../../../../../../../components/form/formWrapper";
import SwitchFormItem from "../../../../../../../../components/form/switchFormItem";
import DatePickerFormItem from "../../../../../../../../components/form/datePickerFormItem";
import {ColumnTradingVoucherTransaction,} from "../../../../function/tableColumn";
import TextAreaInputFormItem from "../../../../../../../../components/form/textAreaInputFormItem";
import {notificationMessage, selfPermissionObj,} from "../../../../../../../../../recoil_state";
import InputNumberFormItem from "../../../../../../../../components/form/inputNumberFormItem";
import useAuthorize from "../../../../../../../../_common/authorize";
import {useIsMobile} from "../../../../../../../../_common/layout";
import {addTradingVoucherTransactionMt5} from "../../../../../../../../api/graphql/tradingAccountMt5";
import AccountMt5TransactionLocal from "./accountMt5TransactionLocal";
import EditTableUserPreferenceButton
    from "../../../../../../../../components/table/function/editTableUserPreferenceButton";
import TableReportAntDesign from "../../../../../../../../components/table/tableReportAntDesign";

const AccountMt5TransactionTradingVoucher = (props) => {
    const {
        setPageVoucher,
        rowsVoucher,
        setRowsVoucher,
        setOrderByVoucher,
        setFilterVoucher,
        reportDataVoucher,
        totalCountVoucher,
        loadingVoucher,
        refreshQuery,
        refreshQueryTransaction,
        data
    } = props
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const tradingAccountVoucherTxnPermission =
        selfPermission?.tradingAccount?.voucherTransaction;
    const [btnLoading, setBtnLoading] = useState(false);
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {systemErrorNotification} = useAuthorize()

    const tableName = "Trading Voucher Transactions"
    const columns = ColumnTradingVoucherTransaction()

    const onChangePageHandler = (page, pageSize) => {
        setPageVoucher(page);
        setRowsVoucher(pageSize);
    }

    const yupSchema = yup.object().shape({
        type: yupFormSchema.string("type", {required: true}),
        amount: yupFormSchema.number("amount", {required: true}),
        comment: yupFormSchema.string("comment", {required: false}),
        expirationDatetime: yupFormSchema.string("expirationDatetime", {
            required: false,
        }),
        sendEmailNotification: yupFormSchema.boolean("sendEmailNotification", {
            required: false,
        }),
    });

    const isMobile = useIsMobile(768);
    const labelCol = {span: isMobile ? 24 : 7, offset: 0};

    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
    });

    const {t} = useTranslation();

    const refetchTradindAcc = () => {
        refreshQuery()
        refreshQueryTransaction()
    }

    const onSubmit = async (value) => {
        try {
            await setBtnLoading(true)
            const response = await addTradingVoucherTransactionMt5({
                user: props?.data?.user?._id,
                account: props?.id,
                type: value?.type,
                amount: value?.amount.toString(),
                expirationDatetime: value?.expirationDatetime && moment(value?.expirationDatetime).format("YYYY-MM-DD"),
                comment: value?.comment,
                sendEmailNotification: value?.type === "out" ? false : value?.sendEmailNotification,
            });

            if (
                response?.addTradingVoucherTransactionMt5.__typename ===
                "TradingMt5VoucherTransaction"
            ) {
                refetchTradindAcc()
                form.reset();
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("tradingAccount.create_transaction_successfully"),
                    key: "addTradingVoucherTransactionMt5",
                });


            } else if (
                response?.addTradingVoucherTransactionMt5.__typename === "BaseError"
            ) {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.addTradingVoucherTransactionMt5?.errKey),
                    key: "addTradingVoucherTransactionMt5",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.addTradingVoucherTransactionMt5?.msg),
                    key: "addTradingVoucherTransactionMt5",
                });
            }
            setBtnLoading(false)
        } catch (error: any) {
            systemErrorNotification()
            setBtnLoading(false)
        }
    };

    const transactionTypeOptions = [
        {
            value: "in",
            label: t("tradingAccount.credit_in"),
        },
        {
            value: "out",
            label: t("tradingAccount.credit_out"),
        },
    ];

    const isAddable = tradingAccountVoucherTxnPermission?.add?.add;

    return (
        <div id="AccountTransaction">
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="form-1">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12}>
                            <SelectFormItem
                                name={"type"}
                                label={t("tradingAccount.transaction_type") + ':'}
                                placeholder={t("tradingAccount.transaction_type")}
                                layout={horizontalFormLayout}
                                options={transactionTypeOptions}
                                mode={"single"}
                                required
                                labelCol={labelCol}
                                value={"in"}
                                disabled={!isAddable}
                            />
                        </Col>

                        <Col xs={24} md={12}>
                            {
                                (form.getValues("type") === "in" || !form.getValues("type")) && (
                                    <DatePickerFormItem
                                        name={"expirationDatetime"}
                                        label={t("tradingAccount.expired_date")}
                                        placeholder={t("tradingAccount.expired_date")}
                                        layout={horizontalFormLayout}
                                        labelCol={labelCol}
                                        disabled={!isAddable}
                                        disabledDate={current => {
                                            return current && current < moment().startOf("day");
                                        }}
                                    />

                                )
                            }
                        </Col>
                        <Col xs={24} md={12}>
                            <InputNumberFormItem
                                name={"amount"}
                                label={t("tradingAccount.amount") + ':'}
                                placeholder={t("tradingAccount.amount")}
                                layout={horizontalFormLayout}
                                required
                                labelCol={labelCol}
                                style={{width: "100%"}}
                                disabled={!isAddable}
                                min={0.01}
                                step={2}
                                // formatter={formatValue}
                                // parser={parseValue}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            {
                                (form.getValues("type") === "in" || !form.getValues("type")) && (
                                    <SwitchFormItem
                                        name={"sendEmailNotification"}
                                        label={t("tradingAccount.send_email_notification") + ':'}
                                        labelCol={{span: 9, offset: 0}}
                                        value={false}
                                        disabled={!isAddable}
                                    />
                                )
                            }

                        </Col>
                        <Col xs={24} md={12}>
                            <TextAreaInputFormItem
                                name={"comment"}
                                label={t("tradingAccount.comment")}
                                placeholder={t("tradingAccount.comment")}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                disabled={!isAddable}
                                // maxLength={25}
                                showCount={true}
                            />
                        </Col>
                        <Col lg={24}>
                            <Form.Item
                                className="text-align-right"
                                style={{paddingTop: "12px"}}
                            >
                                <Button
                                    onClick={form.handleSubmit(onSubmit)}
                                    className="button-submit-1"
                                    loading={btnLoading}
                                    disabled={!isAddable || btnLoading}
                                >
                                    {t("common.submit")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </form>
            </FormProvider>

            <Col span={24} style={{marginTop: "15px"}}>
                {/*<AccountMt5TransactionLocal data={data} type={"Voucher Transaction"}/>*/}
                {
                    data?.mode === "demo" ? (
                        <AccountMt5TransactionLocal data={data} type={"Voucher Transaction"}/>
                    ) : (
                        <>
                            <div className={"display-flex margin-bottom-0-75"}>
                                <Space className={"margin-left-auto"}>
                                    <EditTableUserPreferenceButton
                                        tableName={tableName}
                                        tableColumn={columns}
                                    />
                                </Space>
                            </div>
                            <TableReportAntDesign
                                tableName={tableName}
                                size="small"
                                columns={columns}
                                loading={loadingVoucher}
                                data={reportDataVoucher}
                                filter={setFilterVoucher}
                                order={setOrderByVoucher}
                                rowKey={(record, index) => index}
                                scroll={{
                                    scrollToFirstRowOnChange: true,
                                    x: true,
                                }}
                                pagination={{
                                    pageSize: rowsVoucher,
                                    showSizeChanger: true,
                                    onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                                    total: totalCountVoucher,
                                    showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
                                }}
                            />
                        </>
                    )
                }

            </Col>
        </div>
    );
};

export default AccountMt5TransactionTradingVoucher;
