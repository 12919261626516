import {gql, useMutation, useQuery} from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import {notificationMessage} from "../../../recoil_state";

const GET_VOUCHER_GROUP_COUNT = gql`
    query getVoucherGroupCount($filter: VoucherGroupSearchInput) {
        getVoucherGroupCount(filter: $filter)
    }
`;

const getVoucherGroupCount = ({filter}) => {
    try {
        const {loading, error, data} = useQuery(GET_VOUCHER_GROUP_COUNT, {
            variables: {
                filter: {
                    name: filter?.name || null,
                },
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_VOUCHER_GROUP = gql`
    query getVoucherGroup($filter: VoucherGroupSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
        getVoucherGroup(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ...on VoucherGroupArray {
                data {
                    _id
                    id
                    name
                    displayName {content language}
                    description {content language}
                    isRandomReward
                    pool {voucher {name voucherType id _id} maximumRedeem probability}
                    termAndConditionEnabled
                    termAndCondition  {content language}
                    rewardValue
                    imageLight{
                        fileList
                        path
                    }
                    imageDark{
                        fileList
                        path
                    }
                    defaultImageLight{
                        fileList
                        path
                    }
                    defaultImageDark{
                        fileList
                        path
                    }
                    isDeleted
                    createdAt
                    createdBy {username}
                    updatedBy {username}
                    updatedAt
                }
            }
            ...on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getVoucherGroup = ({filter}, limit?, offset?, orderBy?) => {
    try {
        const {loading, error, data, refetch} = useQuery(GET_VOUCHER_GROUP, {
            variables: {
                filter: filter,
                limit: limit,
                offset: offset,
                orderBy: orderBy
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        const refreshQuery = () => {
            refetch();
        };

        return {loading, error, data, refreshQuery};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_VOUCHER_GROUP = gql`
    mutation addVoucherGroup($input: VoucherGroupInput) {
        addVoucherGroup(input: $input) {
            ... on VoucherGroup {
                    _id
                    id
                    name
                    displayName {content language}
                    description {content language}
                    isRandomReward
                    pool {voucher {name voucherType id _id} maximumRedeem probability}
                    termAndConditionEnabled
                    termAndCondition  {content language}
                    rewardValue
                    imageLight{
                        fileList
                        path
                    }
                    imageDark{
                        fileList
                        path
                    }
                    defaultImageLight{
                        fileList
                        path
                    }
                    defaultImageDark{
                        fileList
                        path
                    }
                    isDeleted
                    createdAt
                    createdBy {username}
                    updatedBy {username}
                    updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useAddVoucherGroupMutation = (): {
    addVoucherGroup: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [addVoucherGroupMutation, {data, loading, error}] = useMutation(ADD_VOUCHER_GROUP);

        const addVoucherGroup = (input) => {
            return addVoucherGroupMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {addVoucherGroup}}) {
                    const typename = addVoucherGroup?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(addVoucherGroup?.errKey),
                            key: "addVoucherGroup",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(addVoucherGroup?.msg),
                            key: "addVoucherGroup",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Add Successfully"),
                            key: "addVoucherGroup",
                        });
                    }
                },
                refetchQueries: [GET_VOUCHER_GROUP, GET_VOUCHER_GROUP_COUNT],
            });
        };

        return {addVoucherGroup, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            addVoucherGroup: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};


const UPDATE_VOUCHER_GROUP = gql`
    mutation updateVoucherGroup($id: String!, $input: VoucherGroupInput) {
        updateVoucherGroup(id: $id, input: $input) {
            ... on VoucherGroup {
                _id
                id
                name
                displayName {content language}
                description {content language}
                isRandomReward
                pool {voucher {name voucherType id _id} maximumRedeem probability}
                termAndConditionEnabled
                termAndCondition  {content language}
                rewardValue
                imageLight{
                    fileList
                    path
                }
                imageDark{
                    fileList
                    path
                }
                defaultImageLight{
                    fileList
                    path
                }
                defaultImageDark{
                    fileList
                    path
                }
                isDeleted
                createdAt
                createdBy {username}
                updatedBy {username}
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useUpdateVoucherGroupMutation = (): {
    updateVoucherGroup: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const {t} = useTranslation();
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const [updateVoucherGroupMutation, {data, loading, error}] = useMutation(UPDATE_VOUCHER_GROUP);

        const updateVoucherGroup = (id, input) => {
            return updateVoucherGroupMutation({
                variables: {
                    id: id,
                    input: input,
                },
                update(cache, {data: {updateVoucherGroup}}) {
                    const typename = updateVoucherGroup?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(updateVoucherGroup?.errKey),
                            key: "updateVoucherGroup",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(updateVoucherGroup?.msg),
                            key: "updateVoucherGroup",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Update Successfully"),
                            key: "updateVoucherGroup",
                        });
                    }
                },
                refetchQueries: [GET_VOUCHER_GROUP, GET_VOUCHER_GROUP_COUNT],
            });
        };

        return {updateVoucherGroup, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            updateVoucherGroup: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};


const DELETE_VOUCHER_GROUP = gql`
    mutation deleteVoucherGroup($id: String!) {
        deleteVoucherGroup(id: $id) {
            ... on VoucherGroup {
                _id
                id
                isDeleted
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useDeleteVoucherGroupMutation = (): {
    deleteVoucherGroup: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [deleteVoucherGroupMutation, {data, loading, error}] = useMutation(DELETE_VOUCHER_GROUP);

        const deleteVoucherGroup = (id) => {
            return deleteVoucherGroupMutation({
                variables: {
                    id: id,
                },
                update(cache, {data: {deleteVoucherGroup}}) {
                    const typename = deleteVoucherGroup?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(deleteVoucherGroup?.errKey),
                            key: "deleteVoucherGroup",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(deleteVoucherGroup?.msg),
                            key: "deleteVoucherGroup",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Delete Successfully"),
                            key: "deleteVoucherGroup",
                        });
                    }
                },
                refetchQueries: [GET_VOUCHER_GROUP, GET_VOUCHER_GROUP_COUNT],
            });
        };

        return {deleteVoucherGroup, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            deleteVoucherGroup: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};


export {
    getVoucherGroup,
    getVoucherGroupCount,
    useAddVoucherGroupMutation,
    useUpdateVoucherGroupMutation,
    useDeleteVoucherGroupMutation
};
