import React, {useEffect, useState} from "react";
import {Form, Modal, Select} from "antd";
import {useTranslation} from "react-i18next";
import {labelCol, validateMessages} from "../../../../../function";
import {useRecoilValue} from "recoil";
import {generalListCountryState} from "../../../../../state";
import {useFlowBuilderFunction} from "../../../../../function/flowBuilderFunction";

const DuplicateFlowModalForm = (props) => {
    const {isOpen, setIsOpen, currentCountry} = props
    const {t} = useTranslation()
    const [submitLoading, setSubmitLoading] = useState(false)
    const countryList = useRecoilValue<any>(generalListCountryState)
    const [form] = Form.useForm()
    const {duplicateUserVerificationCountryFlowFunction} = useFlowBuilderFunction()

    const onSubmit = async (value) => {
        setSubmitLoading(true)
        await duplicateUserVerificationCountryFlowFunction(value, currentCountry)
        await setSubmitLoading(false)
        setIsOpen(false)
    }

    const countryOption = countryList?.map(d => ({label: d?.country, value: d?._id}))

    useEffect(() => {
        if (isOpen) {
            form.setFieldValue("from", countryList?.find(d => d?.country === currentCountry)?._id)
            form.resetFields(["to"])
        }
    }, [isOpen])

    return (
        <Modal
            title={t("accountVerification.duplicateFlow")}
            open={isOpen}
            onCancel={() => setIsOpen(false)}
            onOk={form.submit}
            cancelText={t("common.cancel")}
            okText={t("common.submit")}
            okButtonProps={{loading: submitLoading}}
            destroyOnClose={true}
        >
            <Form form={form} onFinish={onSubmit} validateMessages={validateMessages}>
                <Form.Item
                    labelCol={labelCol}
                    label={t("accountVerification.from")}
                    name={"from"}
                    rules={[{required: true}]}
                >
                    <Select
                        placeholder={t("accountVerification.placeholder.selectCountry")}
                        options={countryOption}
                        showSearch
                        optionFilterProp={"label"}
                    />
                </Form.Item>
                <Form.Item
                    labelCol={labelCol}
                    label={t("accountVerification.to")}
                    name={"to"}
                    rules={[{required: true}]}
                >
                    <Select
                        placeholder={t("accountVerification.placeholder.selectCountry")}
                        options={countryOption}
                        showSearch
                        optionFilterProp={"label"}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default DuplicateFlowModalForm