import {Form, DatePicker} from "antd";
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import FormErrors from "./formErrors";
import dayjs from "dayjs";
import moment from "moment";

const DatePickerInputForm = (props) => {
    const {
        layout,
        label,
        name,
        value,
        placeholder,
        externalErrorMessage,
        required,
        onChange,
        disabled,
        labelCol,
        wrapperCol,
        labelAlign,
        style,
        showTime,
        format,
        defaultValue,
        disabledDate,
        disabledDateTime,
        getPopupContainer,
        colon
    } = props;

    const {
        register,
        watch,
        setValue,
        formState: {touchedFields, isSubmitted, errors},
    } = useFormContext();

    const errorMessage = FormErrors.errorMessage(
        name,
        label,
        errors,
        touchedFields,
        isSubmitted,
        externalErrorMessage
    );

    useEffect(() => {
        register(name);
    }, [register, name]);

    useEffect(() => {
        if (value) {
            setValue(name, dayjs(value), { shouldValidate: true });
        }
    }, [value])

    return (
        <Form.Item
            {...layout}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            label={label}
            required={required}
            validateStatus={errorMessage ? "error" : "success"}
            help={errorMessage}
            labelAlign={labelAlign}
            labelWrap
            colon={colon}
        >
            <DatePicker
                id={name}
                name={name}
                value={watch(name)}
                defaultValue={defaultValue}
                placeholder={placeholder}
                disabled={disabled}
                disabledDate={disabledDate}
                disabledTime={disabledDateTime}
                format={format}
                onChange={(val) => {
                    setValue(name, val, {shouldValidate: true});
                    onChange && onChange(val);
                }}
                showTime={showTime}
                style={style || {width: "100%"}}
                getPopupContainer={getPopupContainer}
            />
        </Form.Item>
    );
}

DatePickerInputForm.defaultProps = {
    required: false,
    disabled: false,
    colon: false
};

DatePickerInputForm.propTypes = {
    layout: PropTypes.object,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    defaultValue: PropTypes.object,
    externalErrorMessage: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    labelCol: PropTypes.object,
    wrapperCol: PropTypes.object,
    style: PropTypes.object,
    showTime: PropTypes.object,
    format: PropTypes.string,
    disabledDate: PropTypes.object,
    disabledDateTime: PropTypes.object,
    getPopupContainer: PropTypes.any,
    labelAlign: PropTypes.string
};

export default DatePickerInputForm;
