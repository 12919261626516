import React from "react";
import {useRecoilValue} from "recoil";
import {selfPermissionObj} from "../../../../recoil_state";
import {getLanguage} from "../../../api/graphql/language";
import MamPammProvider_1 from "./view/layout1";
import {getServerSetting} from "../../../api/graphql/serverSetting";

function MamPammProvider() {
    let layout = 1;
    let component;
    const permission = useRecoilValue<any>(selfPermissionObj);
    const {data: serverData} = getServerSetting({filter: null});
    const {languageData} = getLanguage({
        filter: null,
    });

    switch (layout) {
        case 1:
            component = (
                <MamPammProvider_1
                    permission={permission?.mampamm?.provider}
                    serverData={serverData?.getServerSetting?.data}
                    languageData={languageData?.getLanguage?.data}
                />
            );
            break;

        default:
            component = (
                <MamPammProvider_1
                    permission={permission?.mampamm?.provider}
                    serverData={serverData?.getServerSetting?.data}
                    languageData={languageData?.getLanguage?.data}
                />
            );
            break;
    }

    return component;
}

export default MamPammProvider;
