import React from "react";
import {Tooltip} from "antd";
import dayjs from "dayjs";
import moment from "moment/moment";

interface TruncateProps {
    sentence: string;
    length: number;
}

export const Truncate: React.FC<TruncateProps> = ({sentence, length}) => {
    const truncatedSentence = sentence.length > length ? sentence.slice(0, length) + "..." : sentence;

    if (sentence.length > length) {
        return (
            <Tooltip placement="top" title={sentence}>
                <span>{truncatedSentence}</span>
            </Tooltip>
        );
    } else {
        return <span>{truncatedSentence}</span>;
    }
};

export const formatUserOptions = (userData) => {
    try {
        let label = userData?.username;
        if (userData?.identificationNo) {
            label += ` - ${userData?.identificationNo}`;
        }
        if (userData?.email) {
            label += ` (${userData?.email})`;
        }
        return {
            label: label,
            value: userData?._id,
            selectedLabel: userData?.username,
            userType: userData?.userType,
            email: userData?.email
        };
    } catch (error) {
    }
};

export const formatUserNetworkOptions = (networkData) => {
    try {
        let userData = networkData?.userid;
        let label = userData?.username;
        if (userData?.identificationNo) {
            label += ` - ${userData?.identificationNo}`;
        }
        if (userData?.email) {
            label += ` (${userData?.email})`;
        }
        return {
            label: label,
            value: networkData?._id,
            selectedLabel: userData?.username,
        };
    } catch (error) {
    }
};

export const formatSalesRepNetworkOptions = (networkData) => {
    try {
        let userData = networkData?.userid;
        let label = userData?.username;
        if (userData?.identificationNo) {
            label += ` - ${userData?.identificationNo}`;
        }
        if (userData?.email) {
            label += ` (${userData?.email})`;
        }
        return {
            label: label,
            value: networkData?._id,
            selectedLabel: userData?.username,
        };
    } catch (error) {
    }
};

export const getSearchParamsObj = (params) => {
    try {
        let obj = {};
        params.forEach((value, key) => {
            obj[key] = value;
        });
        return obj;
    } catch (error) {
    }
};

export const convertRewardTypeToLabel = (string) => {
    switch (string) {
        case "cashVoucher":
            return "Cash Voucher";
        case "tradingVoucher":
            return "Trading Voucher";
        case "bonusVoucher":
            return "Bonus Voucher";
        case "stockVoucher":
            return "Stock Voucher";
        case "commoditiesVoucher":
            return "Commodities Voucher";
        case "accountUpgradeVoucher":
            return "Account Upgrade Voucher";
        default:
            return "";
    }
};

export function removeTypenameKey(obj: any) {
    try {
        if (Array.isArray(obj)) {
            return obj?.map((item) => removeTypenameKey(item));
        } else if (typeof obj === "object" && obj !== null) {
            const newObj = {};
            for (let key in obj) {
                if (key !== "__typename") {
                    newObj[key] = removeTypenameKey(obj[key]);
                }
            }
            return newObj;
        }
        return obj;
    } catch (error) {
    }
}

export function convertTimezoneToString(offset) {
    // Calculate the hours and minutes
    var hours = Math.floor(offset);
    var minutes = Math.abs((offset - hours) * 60);

    // Format the hours and minutes
    var formattedHours = (hours >= 0 ? "+" : "-") + padZero(Math.abs(hours));
    var formattedMinutes = padZero(minutes);

    // Return the formatted string
    return formattedHours + ":" + formattedMinutes;
}

export function padZero(num) {
    return (num < 10 ? "0" : "") + num;
}

export function convertGMTDateTimeToDisplayDateString(dateTime) {
    return dayjs(dateTime)?.utc()?.format("YYYY-MM-DD") + "T12:00:00+00:00";
}

export const formatDateMT5 = (date) => {
    const timezone = localStorage.getItem("timezone");
    let formattedDate = date ? moment(date)?.format("YYYY-MM-DD") : "";
    if (timezone) {
        formattedDate = moment(date)?.utcOffset(0).format("YYYY-MM-DD");
    }
    return formattedDate;
};

export const formatDateTimeMT5 = (date) => {
    const timezone = localStorage.getItem("timezone");
    let formattedDate = date ? moment(date)?.format("YYYY-MM-DD HH:mm:ss") : "";
    if (timezone) {
        formattedDate = moment(date)?.utcOffset(0).format("YYYY-MM-DD HH:mm:ss");
    }
    return formattedDate;
};

export const formatDateTimeMinuteMT5 = (date) => {
    const timezone = localStorage.getItem("timezone");
    let formattedDate = date ? moment(date)?.format("YYYY-MM-DD HH:mm") : "";
    if (timezone) {
        formattedDate = moment(date)?.utcOffset(0).format("YYYY-MM-DD HH:mm");
    }
    return formattedDate;
};

export const formatDateUTC: any = (date, type?) => {
    if (type === "formValue") {
        return dayjs(date)
    } else if (type === "formSubmit") {
        return dayjs(date.format("YYYY-MM-DD")).utc(true).format()
    } else {
        return dayjs(date)?.format("YYYY-MM-DD")
    }
}

export const formatDateTimeTimezone: any = (date, type?, format = "YYYY-MM-DD HH:mm:ss") => {
    const timezone = localStorage.getItem("timezone") ?? 0

    if (type === "formValue") {
        return dayjs(date).utcOffset(-timezone)
    } else if (type === "formSubmit") {
        return dayjs(date).utc(true).format()
    } else if (type === "toGMT") {
        return timezone === 0
            ? dayjs(date).utc().format()
            : dayjs(date).utcOffset(-timezone, true).utc().format();
    } else if (type === "toGMTDisplay") {
        return timezone === 0
            ? dayjs(date).utc().format(format)
            : dayjs(date).utcOffset(-timezone, true).utc().format(format);
    } else {
        return dayjs(date).utcOffset(-timezone).format(format)
    }
}

export const checkBrowserFirebaseCompatibility = () => {
    return "serviceWorker" in navigator && "Notification" in window && "indexedDB" in window
}