import React from "react";
import TableAntDesign from "../../../../components/table/tableAntDesign";
import {MessageDraftTableColumns} from "../../function/tableColumns";

const Inbox = () => {
    const column = MessageDraftTableColumns()

    return (
        <div id={"inbox"}>
            <TableAntDesign
                data={[]}
                columns={column}
                tableName={"Inbox"}
            />
        </div>
    )
}

export default Inbox