import { gql, useMutation, useQuery } from '@apollo/client'

const GET_EXECUTION_PROFILE_COUNT = gql`
  query getExecutionProfileCount($filter: ExecutionProfileInput) {
    getExecutionProfileCount(filter: $filter)
  }
`;

const getExecutionProfileCount = ({filter}) => {
  try{
    const { loading, error, data } = useQuery(GET_EXECUTION_PROFILE_COUNT, {
        variables: {
            filter: {
              executionProfileId: filter?.executionProfileId || null,
              name: filter?.name || null,
              serverName: filter?.serverName || null,
            }
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}

const GET_EXECUTION_PROFILE_BY_ID = gql`
  query getExecutionProfileByID($id: String!) {
    getExecutionProfileByID(id: $id){
      ... on ExecutionProfile {
        _id
        id
        executionProfileId
        name
        description
        utcLastUpdateTimestamp
        minBookBDelay
        maxBookBDelay
        executionPolicy
        gslExecutionPolicy
        conditionVolumeUsd
        server {name}
        createdBy {username}
        updatedBy {username}
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getExecutionProfileById = (id) => {
  try{
    const { loading, error, data } = useQuery(GET_EXECUTION_PROFILE_BY_ID, {
        variables: {
          id: id
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}


const GET_EXECUTION_PROFILE = gql`
  query getExecutionProfile($filter: ExecutionProfileInput, $limit: Int, $offset: Int, $orderBy: String) {
    getExecutionProfile(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy){
     ... on ExecutionProfileArray {
        data {
          _id
          id
          executionProfileId
          name
          description
          utcLastUpdateTimestamp
          minBookBDelay
          maxBookBDelay
          executionPolicy
          gslExecutionPolicy
          conditionVolumeUsd
          server {name}
          createdBy {username}
          updatedBy {username}
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getExecutionProfile = ({filter}, limit?, offset?, orderBy?) => {
  try{
    const { loading, error, data } = useQuery(GET_EXECUTION_PROFILE, {
        variables: {
          filter: {
            executionProfileId: filter?.executionProfileId || null,
            name: filter?.name || null,
            serverName: filter?.serverName || null,
          },
          limit: limit || null,
          offset: offset || null,
          orderBy: orderBy || null,
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}

const REFRESH_EXECUTION_PROFILE = gql`
  mutation refreshExecutionProfile($server: String!) {
    refreshExecutionProfile(server: $server){
      ... on ExecutionProfileArray {
        data {
          _id
          id
          executionProfileId
          name
          description
          utcLastUpdateTimestamp
          minBookBDelay
          maxBookBDelay
          executionPolicy
          gslExecutionPolicy
          conditionVolumeUsd
          server {name}
          createdBy {username}
          updatedBy {username}
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`

const useRefreshExecutionProfileMutation = (): { refreshExecutionProfile: any, data: any, loading: boolean, error: any } => {
  try{
    const [useRefreshExecutionProfileMutation, { data, loading, error }] = useMutation(REFRESH_EXECUTION_PROFILE);

    const refreshExecutionProfile = (input) => {
      return useRefreshExecutionProfileMutation({
        variables: {
          input: {
            server: input.server || null,
          },
        },
      });
    };

    return { refreshExecutionProfile, data, loading, error } 
  }
  catch(err: any){
    console.error(err);
    return { refreshExecutionProfile: null, data: null, loading: false, error: err };
  }
}

export {
  getExecutionProfileCount, 
  getExecutionProfileById, 
  getExecutionProfile, 
  useRefreshExecutionProfileMutation
}