import {atom} from "recoil";

const fieldListState = atom({
    key: "fieldList",
    default: []
})

const leadsFieldListState = atom({
    key: "leadsFieldList",
    default: []
})

const fieldListLoading = atom({
    key: "fieldLoading",
    default: true
})

const leadsFieldListLoading = atom({
    key: "leadsFieldLoading",
    default: true
})

const fieldLabelState = atom({
    key: "fieldLabel",
    default: ""
})

const fieldCategoryState = atom({
    key: "fieldCategory",
    default: []
})

const userTypeState = atom({
    key: "userType",
    default: ""
})

const profileInformationSettingState = atom<any[]>({
    key: "profileInformation",
    default: []
})

const profileInformationSettingLoading = atom({
    key: "profileInformationLoading",
    default: true
})

const fieldListFormPropState = atom({
    key: "fieldListFormProp",
    default: {isOpen: false}
})

export {
    fieldListState,
    fieldListLoading,
    leadsFieldListState,
    leadsFieldListLoading,
    fieldLabelState,
    fieldCategoryState,
    userTypeState,
    profileInformationSettingState,
    profileInformationSettingLoading,
    fieldListFormPropState
}