import {Form, Input, Tooltip} from "antd";
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import FormErrors from "./formErrors";
import { t } from "i18next";
import { SyncOutlined } from "@ant-design/icons";

const RandomStringFormItem = (props) => {
    const {
        layout,
        label,
        labelAlign,
        name,
        placeholder,
        prefix,
        suffix,
        value,
        type,
        externalErrorMessage,
        required,
        onChange,
        disabled,
        maxLength,
        showCount,
        labelCol,
        wrapperCol,
        addonBefore,
        addonAfter,
        extra,
        colon,
        style,
        className,
        size,
        randomStringLength
    } = props;

    const {
        register,
        watch,
        setValue,
        formState: {touchedFields, isSubmitted, errors},
    } = useFormContext();

    const errorMessage = FormErrors.errorMessage(name, label, errors, touchedFields, isSubmitted, externalErrorMessage);

    useEffect(() => {
        register(name);
    }, [register, name]);

    useEffect(() => {
        if (value) {
            setValue(name, value, {shouldValidate: true});
        }
    }, [value]);

    const  generateRandomString = () => {
        let result = "";
        const characters =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < randomStringLength) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        
        setValue(name, result, {shouldValidate: true});
        onChange && onChange(result);
        return result;
      }

    return (
        <Form.Item
            {...layout}
            labelCol={labelCol}
            className={className}
            wrapperCol={wrapperCol}
            label={label}
            labelAlign={labelAlign}
            colon={colon}
            required={required}
            validateStatus={errorMessage ? "error" : "success"}
            help={errorMessage}
            extra={extra}
            labelWrap
        >
            {type === "password" ? (
                <Input.Password
                    id={name}
                    name={name}
                    maxLength={maxLength}
                    showCount={showCount}
                    value={value || watch(name)}
                    onChange={(e) => {
                        setValue(name, e.target?.value, {shouldValidate: true});
                        onChange && onChange(e.target?.value);
                    }}
                    placeholder={placeholder}
                    prefix={prefix}
                    disabled={disabled}
                    suffix={suffix}
                    addonAfter={addonAfter}
                    addonBefore={addonBefore}
                    style={style}
                    size={size}
                />
            ) : (
                <Input
                    id={name}
                    name={name}
                    type={type}
                    maxLength={maxLength}
                    showCount={showCount}
                    value={watch(name)}
                    onChange={(e) => {
                        setValue(name, e.target?.value, {shouldValidate: true});
                        onChange && onChange(e.target?.value);
                    }}
                    placeholder={placeholder}
                    prefix={prefix}
                    disabled={disabled}
                    suffix={
                      <Tooltip title={t("Refresh")}>
                        <SyncOutlined onClick={() => generateRandomString()} style={{color: "blue"}}/>
                      </Tooltip>
                      }
                    addonAfter={addonAfter}
                    addonBefore={addonBefore}
                    style={style}
                    size={size}
                />
            )}
        </Form.Item>
    );
};

RandomStringFormItem.defaultProps = {
    type: "text",
    required: false,
    disabled: false,
    colon: false,
    size: "middle",
    labelCol: {span: 24, offset: 0},
};

RandomStringFormItem.propTypes = {
    label: PropTypes.string,
    labelAlign: PropTypes.string,
    wrapperCol: PropTypes.object,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    prefix: PropTypes.any,
    suffix: PropTypes.any,
    addonBefore: PropTypes.object,
    addonAfter: PropTypes.object,
    value: PropTypes.string,
    type: PropTypes.string,
    externalErrorMessage: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    layout: PropTypes.object,
    style: PropTypes.object,
    maxLength: PropTypes.number,
    showCount: PropTypes.bool,
    extra: PropTypes.string,
    className: PropTypes.string,
    randomStringLength: PropTypes.number
};

export default RandomStringFormItem;
