import React, {useState} from "react";
import TableAntDesign from "../../../../components/table/tableAntDesign";
import {useTranslation} from "react-i18next";
import {Button, Empty, Popconfirm, Segmented, Space} from "antd";
import {deleteSoftEmailSendDraft} from "../../../../api/graphql/message";
import useAuthorize from "../../../../_common/authorize";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../recoil_state";
import {MessageOutboxTableColumns, NotificationDraftTableColumns} from "../../function/tableColumns";
import {archivePushNotificationDraft} from "../../../../api/graphql/pushNotification";

const Draft = ({
                   emailDraftData,
                   emailDraftTotal,
                   emailDraftProps,
                   setEmailDraftProps,
                   emailTemplate,
                   openEditModal,
                   fetchEmailDraftData,
                   messagePermission,
                   fetchEmailDraftDeletedData,
                   pushNotificationDraftData,
                   pushNotificationDraftTotal,
                   fetchPushNotificationDraftData,
                   pushNotificationDraftProps,
                   setPushNotificationDraftProps,
                   fetchPushNotificationDraftDeletedData,
                   isDraft,
                   setIsDraft,
               }) => {
    const {t} = useTranslation()
    const {baseErrorChecking, systemErrorNotification} = useAuthorize();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const [type, setType] = useState<any>(1)
    const [currentPage, setCurrentPage] = useState(1)

    const deleteDraft = async (id) => {
        switch (type) {
            case 1:
                try {
                    const {deleteSoftEmailSendDraft: res} = await deleteSoftEmailSendDraft(id)

                    if (res?.__typename === "EmailSendDraft") {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("message.draft_deleted"),
                            key: "messagesNotification",
                        });

                        fetchEmailDraftDeletedData()
                    } else if (res?.__typename === "BaseError") {
                        baseErrorChecking(res)
                    }
                } catch (e) {
                    systemErrorNotification()
                }
                break
            case 2:
                try {
                    const {archivePushNotificationDraft: res} = await archivePushNotificationDraft(id)

                    if (res?.__typename === "PushNotificationDraft") {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("message.draft_deleted"),
                            key: "messagesNotification",
                        });

                        fetchPushNotificationDraftDeletedData()
                    } else if (res?.__typename === "BaseError") {
                        baseErrorChecking(res)
                    }
                } catch (e) {
                    systemErrorNotification()
                }
                break
        }
    }

    const segmentedOptions = [
        {label: "Email", value: 1},
        {label: "Notification", value: 2}
    ]

    const onChangePageHandler = (page, pageSize) => {
        setCurrentPage(page)
        switch (type) {
            case 1:
                setEmailDraftProps(prev => ({...prev, limit: pageSize, offset: prev.limit * (page - 1)}))
                break
            case 2:
                setPushNotificationDraftProps(prev => ({...prev, limit: pageSize, offset: pageSize * (page - 1)}))
                break
        }
    }

    return (
        <div id={"draft"}>
            <Segmented className={"margin-bottom-0-5"} options={segmentedOptions} onChange={(value) => setType(value)}/>
            {type === 1 && (
                <TableAntDesign
                    data={emailDraftData}
                    actionPermission={messagePermission?.send || messagePermission?.delete}
                    columns={[...MessageOutboxTableColumns(emailTemplate), {
                        label: t("common.action"),
                        key: "action",
                        render: (_, record) => {
                            return (
                                <Space>
                                    {messagePermission?.send && (
                                        <Button
                                            size={"small"}
                                            onClick={() => openEditModal(record, "email")}
                                        >
                                            {t("common.edit")}
                                        </Button>
                                    )}
                                    {messagePermission?.delete && (
                                        <Popconfirm
                                            title={t("message.delete_draft_popconfirm")}
                                            onConfirm={() => deleteDraft(record?._id)}
                                        >
                                            <Button size={"small"} danger>
                                                {t("message.move_to_trash")}
                                            </Button>
                                        </Popconfirm>
                                    )}
                                </Space>
                            )
                        }
                    }]}
                    pagination={{
                        pageSize: emailDraftProps.limit,
                        showSizeChanger: true,
                        total: emailDraftTotal,
                        current: currentPage,
                        onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                        showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                    }}
                    tableName={"Draft"}
                />
            )}
            {type === 2 && (
                <TableAntDesign
                    data={pushNotificationDraftData}
                    actionPermission={messagePermission?.send || messagePermission?.delete}
                    columns={[...NotificationDraftTableColumns(), {
                        label: t("common.action"),
                        key: "action",
                        render: (_, record) => {
                            return (
                                <Space>
                                    {messagePermission?.send && (
                                        <Button
                                            size={"small"}
                                            onClick={() => {
                                                openEditModal(record, "pushNotification")
                                                setIsDraft(true)
                                            }}
                                        >
                                            {t("common.edit")}
                                        </Button>
                                    )}
                                    {messagePermission?.delete && (
                                        <Popconfirm
                                            title={t("message.delete_draft_popconfirm")}
                                            onConfirm={() => deleteDraft(record?._id)}
                                        >
                                            <Button size={"small"} danger>
                                                {t("message.move_to_trash")}
                                            </Button>
                                        </Popconfirm>
                                    )}
                                </Space>
                            )
                        }
                    }]}
                    pagination={{
                        pageSize: pushNotificationDraftProps.limit,
                        showSizeChanger: true,
                        total: pushNotificationDraftTotal,
                        current: currentPage,
                        onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                        showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                    }}
                    tableName={"Draft"}
                />
            )}
        </div>
    )
}

export default Draft