import React, {useEffect, useRef, useState} from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {currentEditingElement, elementDrawerIsOpen} from "../../../state/state";
import TextMenuItems from "./textMenuItems";
import ImageMenuItems from "./imageMenuItems";
import {Card, Row} from "antd";
import {CloseOutlined} from "@ant-design/icons";
import Draggable from 'react-draggable';
import RankingMenuItems from "./rankingMenuItems";
import PodiumMenuItems from "./podiumMenuItems";
import LegendMenuItems from "./legendMenuItems";

const ElementContextMenu = ({detailData, setDetailData}) => {
    const [position, setPosition] = useState<any>(null);
    const positionRef = useRef(position);
    const [elementType, setElementType] = useState<string | null>(null);
    const [editingElement, setEditingElement] = useRecoilState(currentEditingElement)
    const drawerOpen = useRecoilValue(elementDrawerIsOpen);

    // const registerRightClick = (e) => {
    //     e.preventDefault();
    //     const el = e.target.closest(".grid-item-wrapper");
    //
    //     if (!el) return; // If no parent with the class is found, do nothing
    //
    //     const classNames = el.className;
    //     if (classNames.includes("type-text")) {
    //         setElementType("text")
    //     } else if (classNames.includes("type-image")) {
    //         setElementType("image")
    //     } else {
    //         setElementType(null)
    //     }
    //
    //     positionRef.current = {y: e.clientY - 70, x: e.clientX - 70}
    //     setPosition({y: e.clientY, x: e.clientX});
    // };
    //
    const closeContextMenu = () => {
        positionRef.current = {y: 0, x: 0}
        setPosition(null);
        setElementType(null)
        setEditingElement("")
    };

    useEffect(() => {
        if (editingElement) {
            const type = editingElement.split("-")[0]
            setElementType(type)

            const data = document.getElementById(`grid-element-${editingElement}`)?.getBoundingClientRect()
            const selected = document.getElementById("leaderboard-setting-ui-editor")?.getBoundingClientRect()

            if (data) {
                // positionRef.current = {y: Math.floor(data?.top - 60), x: Math.floor(data?.width - 270)}
                // setPosition({y: Math.floor(data?.top - 60), x: Math.floor(data?.width - 270)});

                positionRef.current = {y: 0, x: Math.floor(selected?.width || 0) - (drawerOpen ? 580 :280)}
                setPosition({y: 0, x: Math.floor(selected?.width || 0) - 280});
            }
        }
    }, [editingElement]);

    // const updateElements = () => {
    //     const gridElements = document.getElementsByClassName("grid-item-wrapper");
    //     setElements(Array.from(gridElements));
    // };
    //
    // const attachListeners = () => {
    //     elements.forEach((el) => {
    //         el.addEventListener("contextmenu", registerRightClick);
    //     });
    //     // document.addEventListener("click", clickAnywhere);
    // };
    //
    // const detachListeners = () => {
    //     elements.forEach((el) => {
    //         el.removeEventListener("contextmenu", registerRightClick);
    //     });
    //     // document.removeEventListener("click", clickAnywhere);
    // };

    // useEffect(() => {
    //     attachListeners()
    //
    //     return () => {
    //         detachListeners()
    //     }
    // }, [elements]);

    // useEffect(() => {
    //     updateElements()
    // }, [layout, currentBreakpoint, drawerOpen]);

    const props = {
        detailData, setDetailData,
        setting: detailData?.interfaceDetail?.find(d => d?.layoutId === editingElement)?.setting,
        getContainer: () => document.getElementById("content-menu") as HTMLElement
    }

    let component, title
    switch (elementType) {
        case "text":
            component = <TextMenuItems {...props}/>
            title = "Text Settings"
            break
        case "image":
            component = <ImageMenuItems {...props}/>
            title = "Image Settings"
            break
        case "ranking":
            component = <RankingMenuItems {...props}/>
            title = "Ranking Settings"
            break
        case "podium":
            component = <PodiumMenuItems {...props}/>
            title = "Podium Settings"
            break
        case "legend":
            component = <LegendMenuItems {...props}/>
            title = "Legend Settings"
            break
    }

    const handleDrag = (e, ui) => {
        positionRef.current = {
            x: positionRef.current.x + ui.deltaX,
            y: positionRef.current.y + ui.deltaY,
        };
    };

    const handleDragStop = (e, ui) => {
        setPosition({
            x: positionRef.current.x,
            y: positionRef.current.y,
        });
    };

    return (
        position && elementType && (
            <Draggable
                axis="both"
                handle=".handle"
                position={positionRef.current}
                onDrag={handleDrag}
                onStop={handleDragStop}
            >
                <Card id="content-menu" size={"small"} title={
                    <Row justify={"space-between"} align={"middle"} className={"handle"}>
                        <h1>{title}</h1>
                        <CloseOutlined onClick={closeContextMenu}/>
                    </Row>
                }>
                    {component}
                </Card>
            </Draggable>
        )
    );
};

export default ElementContextMenu;
