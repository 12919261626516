import React from "react";
import {Card, Empty, Tabs} from "antd";
import {useTranslation} from "react-i18next";
import FollowUpHistory from "./log/followUpHistory";
import OperationHistory from "./log/operationHistory";

const ActivityLogCard = (props) => {
    const {data, permissionUser, followUp, fetchFollowUp, clientId} = props
    const {t} = useTranslation()


    const tabItems = [
        {
            key: "followUp",
            label: t("user.followUp"),
            children: <FollowUpHistory data={data} permissionUser={permissionUser} followUp={followUp} fetchFollowUp={fetchFollowUp} clientId={clientId}/>
        },
        {
            key: "operation",
            label: t("user.operationHistory"),
            children: <OperationHistory data={data} permissionUser={permissionUser}/>
        },
        {
            key: "tradingHistory",
            label: t("user.tradingHistory"),
            children:  <Empty description={t("common.noData")} style={{marginTop: "2rem"}}/>
        }
    ]

    return (
        <>
            <Card style={{width: "100%"}} id={"activity-log"}>
                <Tabs
                    defaultActiveKey="followUp"
                    items={tabItems}
                />
            </Card>
        </>
    )
};

export default ActivityLogCard