import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useAuthorize from "../../../../../../../_common/authorize";
import {getSymbolsMt5} from "../../../../../../../api/graphql/symbolMt5";
import TableAntDesign from "../../../../../../../components/table/tableAntDesign";
import {useRecoilState} from "recoil";
import {symbolMt5ReloadState} from "../../../state";


const SymbolsMt5Table = ({columnMt5, tableNameMt5, server,setMt5ExportLoading,setMt5ExportData}) => {

    const [mt5TableRefresh, setMt5TableRefresh] = useRecoilState(symbolMt5ReloadState)
    const [loading, setLoading] = useState(false)
    const [batchData, setBatchData] = useState([])
    const [batchDataTotal, setBatchDataTotal] = useState(0)
    const [orderBy, setOrderBy] = useState("");
    const [filter, setFilter] = useState({});
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [dateKey, setDateKey] = useState("");
    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(0);

    const {t} = useTranslation()
    const {systemErrorNotification} = useAuthorize()


    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize)
        setOffset(limit * (page - 1))
    }

    const preloadExport = async () => {
        await setMt5ExportLoading(true)
        const responseExport = await getSymbolsMt5({
            ...filter,
            server: server.id
        }, orderBy, null, null, startDate, endDate, dateKey)

        setMt5ExportData(responseExport?.getSymbolsMt5?.data)
        setMt5ExportLoading(false)
    }

    const preload = async () => {
        try {
            await setLoading(true)

            const response = await getSymbolsMt5({
                ...filter,
                server: server.id
            }, orderBy, limit, offset, startDate, endDate, dateKey)

            setBatchDataTotal(response?.getSymbolsMt5?.total)
            setBatchData(response?.getSymbolsMt5?.data)
        } catch (e) {
            systemErrorNotification()
        } finally {
            setLoading(false)
            setMt5TableRefresh(false)
        }
    }

    useEffect(() => {
        if (mt5TableRefresh) {
            preload()
            preloadExport()
        }
    }, [mt5TableRefresh])

    useEffect(() => {

        if(server?.id){
            preload()
            preloadExport()
        }

    }, [server,limit, offset, orderBy, startDate, endDate, dateKey, filter])


    return (
        <div className={"margin-top-0-75"}>
            <TableAntDesign
                data={batchData}
                tableName={tableNameMt5}
                columns={columnMt5}
                size={"small"}
                loading={loading}
                filter={setFilter}
                order={setOrderBy}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setDateKey={setDateKey}
                pagination={{
                    pageSize: limit,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                    total: batchDataTotal,
                    showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
                }}
            />
        </div>
    )
}

export default SymbolsMt5Table