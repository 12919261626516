import React, {useEffect, useState} from "react";
import moment from "moment";
import {useTranslation} from "react-i18next";
import SearchableTree from "../../../../../components/tree/searchableTree";
import {calculateTotalDownline} from "../../../../../function/calculateTotalDownline";
import {checkIsMasterAdmin} from "../../../../../api/graphql/user";
// import { getUserNetworkTreeCount } from "../../../../../api/graphql/userNetworkTree";

const UserNetworkTree_1 = (props) => {
    const {data, refetch} = props;
    const {t} = useTranslation();
    const [amendedData, setAmendedData] = useState<any>([]);
    const [amendedCrmUsersData, setAmendedCrmUsersData] = useState<any>([]);
    const {isMasterAdmin} = checkIsMasterAdmin(localStorage.getItem("g_userId") || localStorage.getItem("userId"));


    const transformData = (data) => {
        try {
            const result: any = [];
            const resultCrm: any = [];
            const lookup: any = {};
            const lookupCrm: any = {}

            data?.forEach((item) => {
                lookup[item?.userid?._id] = {
                    ...item,
                    children: [],
                    totalDirectDownline: 0,
                    totalDownline: 0,
                };

                lookupCrm[item?.userid?._id] = {
                    ...item,
                    children: [],
                    totalDirectDownline: 0,
                    totalDownline: 0,
                };
            });

            data?.forEach((item) => {
                if (lookup && item?.parent?._id && lookup[item?.parent._id]) {
                    lookup[item?.parent._id]?.children?.push(lookup[item?.userid?._id]);
                    lookup[item?.parent._id].totalDirectDownline += 1;
                } else {
                    result?.push(lookup[item?.userid?._id]);
                }

                if (lookupCrm && item?.parent?._id && lookupCrm[item?.parent._id]) {
                    lookupCrm[item?.parent._id]?.children?.push(lookupCrm[item?.userid?._id]);
                    lookupCrm[item?.parent._id].totalDirectDownline += 1;
                } else {
                    resultCrm?.push(lookupCrm[item?.userid?._id])
                }
            });

            resultCrm?.forEach(amendFields)
            resultCrm?.forEach(getCrmUsersOnly)
            setAmendedCrmUsersData(resultCrm)

            result?.forEach(amendFields);
            setAmendedData(result);

        } catch (error) {
        }
    };

    const getCrmUsersOnly = (obj) => {
        try {
            if (obj?.userType === "crmUser") {
                if (!obj.children || obj.children.length === 0) {
                    return obj;
                }
                obj.children = obj.children.map(getCrmUsersOnly).filter((node) => node !== null);
                return obj;
            } else if (obj?.userType === "client") {
                if (!obj.children || obj.children.length === 0) {
                    return null;
                }
                obj.children = obj.children.map(getCrmUsersOnly).filter((node) => node !== null);
                if (obj.children.length > 0) {
                    return obj;
                }
                return null;
            } else return null
        } catch (e) {
            console.error(e);
            return null;
        }
    };

    const amendFields = (obj) => {
        try {
            obj.key = obj?.userid?._id;
            obj.totalDownline = calculateTotalDownline(obj);
            const userData = obj?.userid;
            obj.userType = userData?.userType;

            let joinedDate = moment(userData?.createdAt)?.format("YYYY-MM-DD");
            let name = userData?.fullName || userData?.lastName || "";
            let username = userData?.username || "-";
            const desk = isMasterAdmin ? `| ${userData?.desk?.name || "-"}` : ""
            let totalDirectDownline = obj?.totalDirectDownline;
            let totalDownline = obj?.totalDownline;

            obj.title = `${username} ${name ? `(${name})` : ""} | ${userData?.userType} | ${joinedDate} | ${totalDirectDownline} | ${totalDownline} | ${userData?.rank?.name || "-"} ${desk}`;
            obj.haveCrmUsersChild = false

            if (obj?.children) {
                obj?.children.forEach(amendFields);
                obj.haveCrmUsersChild = obj?.children?.filter(d => d?.userType === "crmUser")?.length > 0
            }

            delete obj?._id;
            delete obj?.userid;
            delete obj?.parent;
            delete obj?.__typename;

            return obj
        } catch (error) {
        }
    };

    const refetchNetwork = () => {
        refetch();
    };

    useEffect(() => {
        if (data?.length > 0) {
            transformData(data);
        }
    }, [data]);

    const note = isMasterAdmin ? "Username (Name) | Type | Join Date | Total Direct Downline | Total Downline | Rank | Desk" : "Username (Name) | Type | Join Date | Total Direct Downline | Total Downline | Rank"
    return (
        <div id="user-network-tree">
            <h1>{t("User Network Tree")}</h1>
            <SearchableTree
                type={"userNetworkTree"}
                defaultData={amendedData}
                note={note}
                refetchNetwork={refetchNetwork}
                crmUserData={amendedCrmUsersData}
            />
        </div>
    );
};

export default UserNetworkTree_1;
