import React, {useRef} from 'react';
import {Editor} from '@tinymce/tinymce-react';
import {getConfigByName} from '../../../../../../../config';
import {Modal} from 'antd';
import {useTranslation} from 'react-i18next';
import {BRAND_NAME} from "../../../../../../../config/brandVariable";

const RichTextEditor = (props) => {
    const { editorState, onEditorStateChange, title, isOpen, setIsOpen } = props
    const editorRef = useRef<any>(null);
    const {t} = useTranslation();
    const brandConfig = getConfigByName(BRAND_NAME)

    return (
        <Modal
            className="template-editor-1"
            title={title}
            open={isOpen}
            width={"60%"}
            onCancel={()=>setIsOpen(false)}
            onOk={()=>setIsOpen(false)}
            okText={t("Save")}
            destroyOnClose
            forceRender
        >
            <Editor
                onInit={(_, editor) => editorRef.current = editor}
                value={editorState}
                apiKey={brandConfig?.tinyMCEApiKey}
                init={{
                    height: 500,
                    menubar: true,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    toolbar: 'undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media component link anchor codesample | ltr rtl',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
                onEditorChange={onEditorStateChange}
            />
        </Modal>
    )
}

export default RichTextEditor