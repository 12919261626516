import React, {useEffect, useState} from "react";
import {useGeneralListFunction} from "../../../../function/generalListFunction";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {currentTabState, generalListDocumentGroupState, tabBarExtraContentState} from "../../../../state";
import {Button, Card, Form, List, Popconfirm, Row, Space, Tag, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import GeneralListDocumentGroupForm from "./modalForm/generalListDocumentGroupForm";
import {getIsMainMasterAdmin} from "../../../../../../../api/graphql/_common";
import ImportDBTableButton from "../importDBTable";
import ExportDBTableButton from "../exportDBTable";

const GeneralListDocumentGroup = ({permission}) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const {
        getGeneralListDocument,
        getGeneralListDocumentGroup,
        createGeneralListDocumentGroup,
        updateGeneralListDocumentGroup,
        deleteGeneralListDocumentGroup,
    } = useGeneralListFunction();
    const documentGroup = useRecoilValue<any>(generalListDocumentGroupState);

    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isAdd, setIsAdd] = useState<any>();
    const [isView, setIsView] = useState<any>(false);
    const [editingItem, setEditingItem] = useState<any>();
    const setTabBarExtraContent = useSetRecoilState<any>(tabBarExtraContentState);
    const currentTab = useRecoilValue(currentTabState);
    const [isMainMasterAdmin, setIsMainMasterAdmin] = useState(false);

    const checkIsMasterAdmin = async () => {
        let response = await getIsMainMasterAdmin();
        setIsMainMasterAdmin(response?.isMainMasterAdmin);
    };

    useEffect(() => {
        checkIsMasterAdmin();
    }, []);

    useEffect(() => {
        if (currentTab === "documentGroup") {
            getGeneralListDocument();
            getGeneralListDocumentGroup();

            permission?.add?.add &&
                setTabBarExtraContent(
                    permission?.add?.add && (
                        <>
                            <Tooltip title={t("accountVerification.addDocumentGroup")}>
                                <Button
                                    onClick={() => {
                                        form.resetFields();
                                        setEditingItem(null);
                                        setIsAdd(true);
                                        setIsOpen(true);
                                        setIsView(false);
                                    }}
                                >
                                    {t("common.add")}
                                </Button>
                            </Tooltip>
                            {isMainMasterAdmin && <ImportDBTableButton />}
                            {isMainMasterAdmin && <ExportDBTableButton />}
                        </>
                    )
                );
        }
    }, [currentTab, isMainMasterAdmin, permission?.add?.add]);

    const onSubmit = async (value) => {
        setLoading(true);
        if (isAdd === true) {
            await createGeneralListDocumentGroup(value);
        } else {
            await updateGeneralListDocumentGroup(editingItem?._id, value);
        }
        setLoading(false);
        setIsOpen(false);
    };

    return (
        <div id={"general-list-country"} className={"margin-top-0-75"}>
            <GeneralListDocumentGroupForm
                loading={loading}
                isView={isView}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                onSubmit={onSubmit}
                form={form}
                item={editingItem}
                isAdd={isAdd}
            />
            <List
                grid={{gutter: 16, column: 4}}
                dataSource={documentGroup}
                renderItem={(item: any) => (
                    <List.Item>
                        <Card
                            size={"small"}
                            title={
                                <Row justify={"space-between"}>
                                    <span>
                                        {item?.isRequired && <span style={{color: "red"}}>*</span>} {t(item?.displayNameLabel || item?.label)}
                                    </span>
                                    <Space>
                                        <Tooltip title={t(permission?.edit?.edit ? "common.edit" : "common.view")}>
                                            <Button
                                                onClick={() => {
                                                    form.resetFields();
                                                    setEditingItem(item);
                                                    setIsAdd(false);
                                                    setIsOpen(true);
                                                    setIsView(!permission?.edit?.edit);
                                                }}
                                                icon={permission?.edit?.edit ? <EditOutlined /> : <EyeOutlined />}
                                                type={"link"}
                                                size={"small"}
                                            />
                                        </Tooltip>
                                        {permission?.delete?.delete && (
                                            <Tooltip title={t("common.delete")}>
                                                <Popconfirm
                                                    placement="left"
                                                    title={t("accountVerification.popconfirm.title.documentGroup.delete")}
                                                    description={t("accountVerification.popconfirm.description.documentGroup.delete")}
                                                    onConfirm={async () => {
                                                        await deleteGeneralListDocumentGroup(item?._id);
                                                    }}
                                                    okText={t("common.yes")}
                                                    cancelText={t("common.no")}
                                                >
                                                    <Button icon={<DeleteOutlined />} type={"link"} danger size={"small"} />
                                                </Popconfirm>
                                            </Tooltip>
                                        )}
                                    </Space>
                                </Row>
                            }
                        >
                            {item?.documents?.map((d) => {
                                return (
                                    <Tag bordered={false} key={d?._id} color={"blue"}>
                                        {t(d?.label)}
                                    </Tag>
                                );
                            })}
                        </Card>
                    </List.Item>
                )}
            />
        </div>
    );
};

export default GeneralListDocumentGroup;
