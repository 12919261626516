import React, {useEffect} from "react";
import {Checkbox, Col, Form, Input, Row} from "antd";
import {useTranslation} from "react-i18next";

const PermissionMt5 = ({initialData}) => {
    const {t} = useTranslation()
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            ...initialData,
            limitSymbols: initialData?.limitSymbols === 0 ? "Unlimited" : initialData?.limitSymbols,
            limitPositions: initialData?.limitPositions === 0 ? "Unlimited" : initialData?.limitPositions,
            limitOrders: initialData?.limitOrders === 0 ? "Unlimited" : initialData?.limitOrders,
            tradeSignals: initialData?.tradeFlags.includes("TRADEFLAGS_SIGNALS_ALL") ? "Enable all signals from all brokers" : initialData?.tradeFlags.includes("TRADEFLAGS_SIGNALS_OWN") ? "Enable signals from my servers only" : "Disabled"
        });
    }, [initialData])

    return (
        <>
            <Row gutter={[16, 16]} style={{marginBottom: "1%", marginTop: "2%"}}>
                <Col span={24}>
                    <p>
                        Please specify the group permissions for symbols, orders, use of Expert Advisors, etc.
                    </p>
                </Col>
            </Row>

            <Form form={form}>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={12}>
                        <Form.Item
                            name={"limitSymbols"}
                            label={t("Maximum symbols")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={"limitHistory"}
                            label={t("Available history")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={12}>
                        <Form.Item
                            name={"limitPositions"}
                            label={t("Maximum positions")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={"limitOrders"}
                            label={t("Maximum orders")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={12}>
                        <Form.Item
                            name={"demoDeposit"}
                            label={t("Deposit by default")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={"demoLeverage"}
                            label={t("Leverage by default")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={12}>
                        <Form.Item
                            name={"tradeInterestrate"}
                            label={t("Annual interest rate")}
                        >
                            <Input readOnly={true} addonAfter="%"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={20}>
                        <Form.Item
                            name={"tradeSignals"}
                            label={t("Trading Signals")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={20}>
                        <Form.Item
                            name={"tradeTransferMode"}
                            label={t("Transfer of funds")}
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={12}>
                        <Form.Item
                            label={t("Enable trading by Expert Advisor")}
                        >
                            <Checkbox disabled={true}
                                      defaultChecked={(initialData?.tradeFlags.includes("TRADEFLAGS_EXPERTS") || initialData?.reportsFlags.includes("TRADEFLAGS_ALL"))}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={t("Enable position closing according to FIFO rule")}
                        >
                            <Checkbox disabled={true}
                                      defaultChecked={(initialData?.tradeFlags.includes("TRADEFLAGS_FIFO_CLOSE") || initialData?.reportsFlags.includes("TRADEFLAGS_ALL"))}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={12}>
                        <Form.Item
                            label={t("Enable trailing stop")}
                        >
                            <Checkbox disabled={true}
                                      defaultChecked={(initialData?.tradeFlags.includes("TRADEFLAGS_TRAILING") || initialData?.reportsFlags.includes("TRADEFLAGS_ALL"))}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={t("prohibit hedge positions")}
                        >
                            <Checkbox disabled={true}
                                      defaultChecked={(initialData?.tradeFlags.includes("TRADEFLAGS_SO_FULLY_HEDGED") || initialData?.reportsFlags.includes("TRADEFLAGS_ALL"))}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{marginBottom: "1%"}}>
                    <Col span={12}>
                        <Form.Item
                            label={t("Enable charge of swaps")}
                        >
                            <Checkbox disabled={true}
                                      defaultChecked={(initialData?.tradeFlags.includes("TRADEFLAGS_SWAPS") || initialData?.reportsFlags.includes("TRADEFLAGS_ALL"))}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={t("Enable deal cost calculation")}
                        >
                            <Checkbox disabled={true}
                                      defaultChecked={(initialData?.tradeFlags.includes("TRADEFLAGS_SO_COMPENSATION") || initialData?.reportsFlags.includes("TRADEFLAGS_ALL"))}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default PermissionMt5