import React, {useEffect, useState} from "react";
import {Button, Space, Tabs, Tooltip} from "antd";
import SecuritiesTable_1 from "./component/securitiesTable";
import ExportTableButton from "../../../../../components/table/function/exportTable";
import EditTableUserPreferenceButton from "../../../../../components/table/function/editTableUserPreferenceButton";
import {SyncOutlined} from "@ant-design/icons";
import {useRefreshAssetClassMutation} from "../../../../../api/graphql/assetClass";
import {useTranslation} from "react-i18next";
import useAuthorize from "../../../../../_common/authorize";
import SecuritiesTableMt5_1 from "./component/securitiesTableMt5";
import {columnsMt5} from "../../function/tableColumn_Mt5";
import {refreshAssetClassMt5} from "../../../../../api/graphql/assetClassMt5";
import {useRecoilState} from "recoil";
import {assetClassMt5ReloadState} from "../../state";

const Securities_1 = (props) => {
    const {data, loading, permission} = props;
    const {t} = useTranslation()
    const [exportData, setExportData] = useState(null)
    const [mt5ExportData, setMt5ExportData] = useState(null)
    const [exportColumn, setExportColumn] = useState(null)
    const [currentServer, setCurrentServer] = useState(data?.getServerSetting?.data?.[0])
    const [mt5TableRefresh, setMt5TableRefresh] = useRecoilState(assetClassMt5ReloadState)
    const tableName = "Securities Group"
    const tableNameMt5 = "Securities Group Mt5"

    const {refreshAssetClass, loading: refreshLoading} = useRefreshAssetClassMutation()
    const {systemErrorNotification} = useAuthorize();

    const handleAssetClassRefresh = (id) => {
        try {
            refreshAssetClass(id);
        } catch (error) {
            systemErrorNotification();
        }
    }

    const handleAssetClassMt5Refresh = async (serverName) => {
        try {
            await refreshAssetClassMt5(serverName)
        } catch (e) {
            systemErrorNotification();
        } finally {
            setMt5TableRefresh(true)
        }
    }


    useEffect(() => {
        if (data?.getServerSetting?.data?.length > 0) {
            setCurrentServer(data?.getServerSetting?.data?.[0])
        }
    }, [data?.getServerSetting?.data?.[0]])

    return (
        <div id="securities">
            <h1>{t(tableName)}</h1>

            {data?.getServerSetting?.data && (
                <Tabs
                    defaultActiveKey="1"
                    size={"small"}
                    items={
                        data &&
                        data?.getServerSetting &&
                        data?.getServerSetting?.data?.map((item, i) => {

                            if (item?.platform === 'mt5' || item?.platform === 'xTrader') {
                                return {
                                    label: t(item?.name),
                                    key: i,
                                    children: <SecuritiesTableMt5_1 server={item} tableName={tableNameMt5}
                                                                    setMt5ExportData={setMt5ExportData}/>,
                                };
                            } else {
                                return {
                                    label: t(item?.name),
                                    key: i,
                                    children: <SecuritiesTable_1 server={item} setExportData={setExportData}
                                                                 setExportColumn={setExportColumn} tableName={tableName}
                                                                 currentServer={currentServer}/>,
                                };
                            }


                        })
                    }
                    tabBarExtraContent={
                        (currentServer?.platform === "mt5" || currentServer?.platform === "xTrader") ? (
                            <>
                                <Space>
                                    <EditTableUserPreferenceButton
                                        tableName={tableNameMt5}
                                        server={currentServer}
                                        tableColumn={columnsMt5}
                                    />
                                    <ExportTableButton
                                        loading={refreshLoading || loading}
                                        tableData={mt5ExportData}
                                        tableColumn={columnsMt5}
                                        tableName={tableNameMt5}
                                        server={currentServer}
                                    />
                                    {
                                        permission?.refreshData &&
                                        <Tooltip title={t("Refresh Securities Group")}>
                                            <Button
                                                type={"link"}
                                                loading={refreshLoading || loading}
                                                disabled={refreshLoading || loading}
                                                onClick={() => handleAssetClassMt5Refresh(currentServer?.id)}
                                                icon={<SyncOutlined/>}
                                            />
                                        </Tooltip>
                                    }
                                </Space>
                            </>
                        ) : (
                            <>
                                <Space>
                                    <EditTableUserPreferenceButton
                                        tableName={tableName}
                                        server={currentServer}
                                        tableColumn={exportColumn}
                                    />
                                    <ExportTableButton
                                        loading={refreshLoading || loading}
                                        tableData={exportData}
                                        tableColumn={exportColumn}
                                        tableName={tableName}
                                        server={currentServer}
                                    />
                                    {
                                        permission?.refreshData &&
                                        <Tooltip title={t("Refresh Securities Group")}>
                                            <Button
                                                type={"link"}
                                                loading={refreshLoading || loading}
                                                disabled={refreshLoading || loading}
                                                onClick={() => handleAssetClassRefresh(currentServer?.id)}
                                                icon={<SyncOutlined/>}
                                            />
                                        </Tooltip>
                                    }
                                </Space>
                            </>
                        )

                    }
                    onTabClick={(key) => {
                        setCurrentServer(data?.getServerSetting?.data[key])
                    }}
                />
            )}
        </div>
    );
}

export default Securities_1;
