const handleOnChangeWithLanguage = (value, field, fieldType, languageCode, setData) => {
    try {
      setData((prevState) => {
        if (!prevState || !prevState[field]) {
          return {
            ...prevState,
            [field]: [
              {
                [fieldType]: value,
                language: languageCode,
              },
            ],
          };
        }
  
        var currentData = [...prevState[field]] || [];
        var index = currentData?.findIndex((item) => item?.language === languageCode);
  
        if (index === -1) {
          currentData?.push({ [fieldType]: value, language: languageCode });
        } else {
          const updatedObj = { ...currentData[index], [fieldType]: value };
          currentData[index] = updatedObj;
        }
  
        var result = {
          ...prevState,
          [field]: currentData,
        };
  
        return result;
      });
    } catch (error) {
      console.log(error);
    }
  };
  
  const generateRandomString = (length, checkList:any = [], maxAttempts = 99999) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const charactersLength = characters.length;
    const checkSet = new Set(checkList);
  
    for (let attempt = 0; attempt < maxAttempts; attempt++) {
      const result = Array.from({ length }, () => characters.charAt(Math.floor(Math.random() * charactersLength))).join('');
  
      if (!checkSet.has(result)) {
        return result;
      }
    }
  
    console.log("Unable to generate a unique string after maximum attempts");
  };

  const getDataWithLanguage = (data, field, fieldType, languageCode) => {
    try {
      const index = data[field]?.findIndex((item) => item?.language === languageCode);
  
      if (!data[field] || !data[field] || !data[field][index]) {
        return "";
      }
  
      return data[field][index][fieldType];
    } catch (error) {
      console.log(error);
    }
  };

  const clean_value = (data) => {
    let result = {...data}
    for (const key in result) {
        if (/_/g.test(key)) {
            delete result[key];
        }
    }
    return result
  }

export {
    handleOnChangeWithLanguage, 
    getDataWithLanguage,
    clean_value,
    generateRandomString
}