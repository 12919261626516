import React from "react";
import {useRecoilState} from "recoil";
import "./css/common/index.css";
import PspSetting_1 from "./view/layout1";
import {getPspCurrencyList} from "../../../api/graphql/_common";
import {getPspSetting} from "../../../api/graphql/pspSetting";
import {getPspSettingField} from "../../../api/graphql/pspSettingField";
import {selfPermissionObj} from "../../../../recoil_state";

function PspSettingPage() {
  let layout = 1;
  let component;
  const [selfPermission] = useRecoilState<any>(selfPermissionObj);
  const pspSettingPermission = selfPermission?.depositWithdrawalSetting?.pspSetting;
  const pspSettingCurrencyPermission =
    selfPermission?.depositWithdrawalSetting?.pspSetting;
  const pspSettingDetailPermission =
    selfPermission?.depositWithdrawalSetting?.pspSetting;

  const PspCurrencyListGraphQL = getPspCurrencyList();
  const PspSettingGraphQL = getPspSetting({ filter: {} });
  const PspSettingFieldGraphQL = getPspSettingField({
    filter: { fieldType: "credential" },
  });

  switch (layout) {
    case 1:
      component = (
        <PspSetting_1
          pspCurrencyList={PspCurrencyListGraphQL?.data?.getPspCurrencyList}
          pspSettingList={PspSettingGraphQL?.data?.getPspSetting?.data}
          pspSettingFieldList={
            PspSettingFieldGraphQL?.data?.getPspSettingField?.data
          }
          pspSettingPermission={pspSettingPermission}
          pspSettingCurrencyPermission={pspSettingCurrencyPermission}
          pspSettingDetailPermission={pspSettingDetailPermission}
        />
      );
      break;

    default:
      component = (
        <PspSetting_1
          pspCurrencyList={PspCurrencyListGraphQL?.data?.getPspCurrencyList}
          pspSettingList={PspSettingGraphQL?.data?.getPspSetting?.data}
          pspSettingFieldList={
            PspSettingFieldGraphQL?.data?.getPspSettingField?.data
          }
          pspSettingPermission={pspSettingPermission}
          pspSettingCurrencyPermission={pspSettingCurrencyPermission}
          pspSettingDetailPermission={pspSettingDetailPermission}
        />
      );
      break;
  }

  return component;
}

export default PspSettingPage;
