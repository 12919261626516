import {gql, useMutation, useQuery} from "@apollo/client";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {useTranslation} from "react-i18next";
import {ApolloClientQuery} from "./_apolloClient";

const GET_TRADING_ACCOUNT = gql`
    query getTradingAccount(
        $filter: TradingAccountFilter
        $filterType: String
        $filterId: String
        $orderBy: String
        $limit: Int
        $offset: Int
        $startDate: String
        $endDate: String
        $dateKey: String
    ) {
        getTradingAccount(
            filter: $filter
            filterType: $filterType
            filterId: $filterId
            orderBy: $orderBy
            limit: $limit
            offset: $offset
            startDate: $startDate
            endDate: $endDate
            dateKey: $dateKey
        ) {
            ... on TradingAccountAry {
                data {
                    _id
                    user {
                        _id
                        username
                        isLoginEnabled
                    }
                    accountId
                    traderId
                    brokerName
                    accessRights
                    depositAssetId
                    isSuspicious
                    spreadGroup {
                        _id
                        name
                    }
                    product {
                        _id
                        id
                        name
                        leverage
                        server {
                            _id
                            platform
                            platformDisplayName
                            mode
                            name
                        }
                        spreadGroup {
                            name
                        }
                        productType
                        mode
                    }
                    leverage
                    leverageInCents
                    lockBalance
                    totalBalance
                    balance
                    tradingVoucher
                    asset
                    credit
                    activeCredit
                    equity
                    margin
                    availableMargin
                    marginLevel
                    hasTradingPermission
                    registrationDatetime
                    hasDepositPermission
                    hasWithdrawalPermission
                    createdAt
                    isSendReport
                    isSuspicious
                    isDefault
                    isLoginEnabled
                    isPrivate
                    platform
                    mode
                    firstDeposit {
                        user {
                            _id
                        }
                        amount
                    }
                    lastDeposit {
                        user {
                            _id
                        }
                        amount
                    }
                    lastUpdated
                    commRule {
                        rule
                        group
                        commissionSetting
                        ruleType
                        commRuleType
                        commRuleAuto
                    }
                    commRulePopulate {
                        rule {
                            name
                            ruleType
                        }
                        group {
                            name
                        }
                        commRuleType
                    }
                    mt5SpreadGroup {
                        group
                    }
                    platformAccountId
                    status_mt5
                    comment_mt5
                    investorAccountSetting {
                        status
                    }
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getTradingAccount = ({filter}, filterType?, filterId?, orderBy?, limit?, offset?, startDate?, endDate?, dateKey?) => {
    try {
        const {loading, error, data, refetch} = useQuery(GET_TRADING_ACCOUNT, {
            variables: {
                filter: filter,
                filterType: filterType || null,
                filterId: filterId || null,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
                orderBy: orderBy || null,
                limit: limit || null,
                offset: offset || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        const refetchQuery = () => {
            refetch();
        };

        return {loading, error, data, refetchQuery};
    } catch (err) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const getTradingAccountAsync = async ({filter}, filterType?, filterId?, orderBy?, limit?, offset?, startDate?, endDate?, dateKey?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_TRADING_ACCOUNT,
            {
                filter: filter,
                filterType: filterType || null,
                filterId: filterId || null,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
                orderBy: orderBy || null,
                limit: limit || null,
                offset: offset || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_TRADING_ACCOUNT_SUBSET = gql`
    query getTradingAccountSubset($filter: TradingAccountFilter, $filterType: String, $filterId: String, $orderBy: String, $limit: Int, $offset: Int, $startDate: String, $endDate: String, $dateKey: String) {
        getTradingAccountSubset(filter: $filter, filterType: $filterType, filterId: $filterId, orderBy: $orderBy, limit: $limit, offset: $offset, startDate: $startDate, endDate: $endDate, dateKey: $dateKey) {
            ... on TradingAccountSubsetAry {
                total
                data {
                    _id
                    parentId
                    registrationDate
                    user
                    username
                    accountId
                    traderId
                    platform
                    mode
                    spreadGroup
                    spreadGroupName
                    product
                    productName
                    productType
                    leverage
                    balance
                    equity
                    credit
                    activeCredit
                    tradingVoucher
                    margin
                    availableMargin
                    marginLevel
                    lockBalance
                    isLoginEnabled
                    isDefault
                    isPrivate
                    hasTradingPermission
                    hasDepositPermission
                    hasWithdrawalPermission
                    commRule
                    createdAt
                    updatedAt
                    server
                    isSuspicious
                }
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`

const getTradingAccountSubset = async ({filter}, filterType?, filterId?, orderBy?, limit?, offset?, startDate?, endDate?, dateKey?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_TRADING_ACCOUNT_SUBSET,
            {
                filter: filter,
                filterType: filterType || null,
                filterId: filterId || null,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
                orderBy: orderBy || null,
                limit: limit || null,
                offset: offset || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_TRADING_ACCOUNT_DETAIL = gql`
    query getTradingAccountDetail($id: String!) {
        getTradingAccountDetail(id: $id) {
            ... on TradingAccount {
                _id
                user {
                    _id
                    username
                    isLoginEnabled
                    clientAccountType
                }
                status
                accountId
                adminPanelAccountStatus
                traderId
                brokerName
                accessRights
                depositAssetId
                hasTradingPermission
                spreadGroup {
                    _id
                    groupId
                    name
                }
                mt5SpreadGroup {
                    _id
                    group
                }
                product {
                    _id
                    id
                    name
                    leverage
                    productType
                    server {
                        _id
                        platform
                        platformDisplayName
                        mode
                        name
                        resetPasswordLink
                    }
                    spreadGroup {
                        _id
                        name
                    }
                    spreadGroupMt5 {
                        _id
                        group
                    }
                    mode
                }
                leverage
                leverageInCents
                balance
                lockBalance
                asset
                totalBalance
                margin
                tradingVoucher
                availableMargin
                marginLevel
                credit
                activeCredit
                mode
                platform
                equity
                registrationDatetime
                hasDepositPermission
                hasWithdrawalPermission
                createdAt
                isSendReport
                isSuspicious
                lastUpdated
                isDefault
                isPrivate
                commRule {
                    rule
                    ruleName
                    group
                    groupName
                    commissionSetting
                    ruleType
                    commRuleType
                    commRuleAuto
                }
                openPosition
                freeMarginWithdrawableAmount
                managerUpdateDatetime
                mt5Rights
                status_mt5
                comment_mt5
                moneyManagerAccountSetting {
                        _id
                    account {
                        _id
                        accountId
                    }
                }
                investorAccountSetting {
                    status
                    moneyManagerAccount {
                        _id
                        accountId
                    }
                    investorAccount {
                        _id
                        accountId
                    }
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getTradingAccountDetail = (id) => {
    try {
        const {loading, error, data, refetch} = useQuery(GET_TRADING_ACCOUNT_DETAIL, {
            variables: {
                id: id,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        const refreshQuery = () => {
            refetch();
        };

        return {loading, error, data, refreshQuery};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const getTradingAccountDetailAsync = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            GET_TRADING_ACCOUNT_DETAIL,
            {
                id: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};


const GET_TRADING_ACCOUNT_TRANSACTION = gql`
    query getTradingAccountTransaction(
        $filter: tradingAccountTransactionInput
        $orderBy: String
        $limit: Int
        $offset: Int
        $startDate: String
        $endDate: String
        $dateKey: String
    ) {
        getTradingAccountTransaction(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
            startDate: $startDate
            endDate: $endDate
            dateKey: $dateKey
        ) {
            ... on tradingAccountTransactionAry {
                data {
                    _id
                    type
                    amount
                    status
                    balanceHistoryId
                    remark
                    depositType
                    withdrawalType
                    comment
                    createdAt
                    account {
                        _id
                        accountId
                        brokerName
                        user {
                            _id
                            username
                        }
                    }
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getTradingAccountTransaction = ({filter}, orderBy?, limit?, offset?, startDate?, endDate?, dateKey?) => {
    try {
        delete filter.serverName;
        const {loading, error, data, refetch} = useQuery(GET_TRADING_ACCOUNT_TRANSACTION, {
            variables: {
                filter: filter,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
                orderBy: orderBy || null,
                limit: limit || null,
                offset: offset || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        const refreshQueryTransaction = () => {
            refetch();
        };

        return {loading, error, data, refreshQueryTransaction};
    } catch (err) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_TRADING_CREDIT_TRANSACTION = gql`
    mutation addTradingCreditTransaction($input: tradingCreditTransactionInput) {
        addTradingCreditTransaction(input: $input) {
            ... on tradingCreditTransaction {
                _id
                user {
                    username
                }
                account {
                    traderId
                }
                type
                amount
                status
                comment
                createdBy {
                    username
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useAddTradingCreditTransactionMutation = (): {
    addTradingCreditTransaction: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const [addTradingCreditTransactionMutation, {data, loading, error}] = useMutation(ADD_TRADING_CREDIT_TRANSACTION);

        const addTradingCreditTransaction = (input) => {
            return addTradingCreditTransactionMutation({
                variables: {
                    input: {
                        user: input.user || null,
                        account: input.account || null,
                        type: input.type || null,
                        amount: input.amount || null,
                        comment: input.comment || null,
                        expirationDatetime: input.expirationDatetime || null,
                        sendEmailNotification: input?.sendEmailNotification || null,
                    },
                },
            });
        };

        return {addTradingCreditTransaction, data, loading, error};
    } catch (err) {
        console.error(err);
        return {
            addTradingCreditTransaction: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const UPDATE_TRADING_CREDIT_TRANSACTION = gql`
    mutation updateTradingCreditTransaction($id: String, $input: tradingCreditTransactionUpdateInput) {
        updateTradingCreditTransaction(id: $id, input: $input) {
            ... on tradingCreditTransaction {
                _id
                user {
                    username
                }
                account {
                    traderId
                }
                type
                amount
                status
                comment
                createdBy {
                    username
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useUpdateTradingCreditTransactionMutation = (): {
    updateTradingCreditTransaction: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const [updateTradingCreditTransactionMutation, {data, loading, error}] = useMutation(UPDATE_TRADING_CREDIT_TRANSACTION);

        const updateTradingCreditTransaction = (id, input) => {
            return updateTradingCreditTransactionMutation({
                variables: {
                    id: id,
                    input: {
                        expirationDatetime: input.expirationDatetime || null,
                    },
                },
            });
        };

        return {updateTradingCreditTransaction, data, loading, error};
    } catch (err) {
        console.error(err);
        return {
            updateTradingCreditTransaction: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const UPDATE_TRADING_VOUCHER_TRANSACTION = gql`
    mutation updateTradingVoucherTransaction($id: String, $input: tradingVoucherTransactionUpdateInput) {
        updateTradingVoucherTransaction(id: $id, input: $input) {
            ... on tradingVoucherTransaction {
                _id
                user {
                    username
                }
                account {
                    traderId
                }
                type
                amount
                status
                comment
                createdBy {
                    username
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useUpdateTradingVoucherTransactionMutation = (): {
    updateTradingVoucherTransaction: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const [updateTradingVoucherTransactionMutation, {data, loading, error}] = useMutation(UPDATE_TRADING_VOUCHER_TRANSACTION);

        const updateTradingVoucherTransaction = (id, input) => {
            return updateTradingVoucherTransactionMutation({
                variables: {
                    id: id,
                    input: {
                        expirationDatetime: input.expirationDatetime || null,
                    },
                },
            });
        };

        return {updateTradingVoucherTransaction, data, loading, error};
    } catch (err) {
        console.error(err);
        return {
            updateTradingVoucherTransaction: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const ADD_TRADING_VOUCHER_TRANSACTION = gql`
    mutation addTradingVoucherTransaction($input: tradingVoucherTransactionInput) {
        addTradingVoucherTransaction(input: $input) {
            ... on tradingVoucherTransaction {
                _id
                user {
                    username
                }
                account {
                    traderId
                }
                type
                amount
                status
                comment
                createdBy {
                    username
                }
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;

const useAddTradingVoucherTransactionMutation = (): {
    addTradingVoucherTransaction: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const [addTradingVoucherTransactionMutation, {data, loading, error}] = useMutation(ADD_TRADING_VOUCHER_TRANSACTION);

        const addTradingVoucherTransaction = (input) => {
            return addTradingVoucherTransactionMutation({
                variables: {
                    input: {
                        user: input.user || null,
                        account: input.account || null,
                        type: input.type || null,
                        amount: input.amount || null,
                        comment: input.comment || null,
                        expirationDatetime: input.expirationDatetime || null,
                        sendEmailNotification: input?.sendEmailNotification || null,
                    },
                },
            });
        };

        return {addTradingVoucherTransaction, data, loading, error};
    } catch (err) {
        console.error(err);
        return {
            addTradingVoucherTransaction: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const GET_TRADING_CREDIT_TRANSACTION = gql`
    query getTradingCreditTransaction(
        $filter: tradingCreditTransactionInput
        $orderBy: String
        $limit: Int
        $offset: Int
        $startDate: String
        $endDate: String
        $dateKey: String
    ) {
        getTradingCreditTransaction(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
            startDate: $startDate
            endDate: $endDate
            dateKey: $dateKey
        ) {
            ... on tradingCreditTransactionAry {
                data {
                    _id
                    user {
                        username
                    }
                    account {
                        traderId
                    }
                    type
                    amount
                    balanceHistoryId
                    remark
                    status
                    comment
                    createdBy {
                        username
                    }
                    createdAt
                    expirationDatetime
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getTradingCreditTransaction = ({filter}, orderBy?, limit?, offset?, startDate?, endDate?, dateKey?) => {
    try {
        const {loading, error, data, refetch} = useQuery(GET_TRADING_CREDIT_TRANSACTION, {
            variables: {
                filter: filter,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
                orderBy: orderBy || null,
                limit: limit || null,
                offset: offset || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        const refreshQuery = () => {
            refetch();
        };

        return {loading, error, data, refreshQuery};
    } catch (err) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_TRADING_CREDIT_TRANSACTION_BY_BALANCE_HISTORY_IDS = gql`
    query getTradingCreditTransactionByBalanceHistoryIds($balanceHistoryIds: [Int]) {
        getTradingCreditTransactionByBalanceHistoryIds(balanceHistoryIds: $balanceHistoryIds) {
            ... on tradingCreditTransactionAry {
                data {
                    _id
                    user {
                        username
                    }
                    account {
                        traderId
                    }
                    type
                    debt
                    amount
                    balanceHistoryId
                    status
                    remark
                    expirationDatetime
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getTradingCreditTransactionByBalanceHistoryIds = async (balanceHistoryIds = []) => {
    try {
        const execute = await ApolloClientQuery(
            GET_TRADING_CREDIT_TRANSACTION_BY_BALANCE_HISTORY_IDS,
            {
                balanceHistoryIds: balanceHistoryIds,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_TRADING_VOUCHER_TRANSACTION_BY_BALANCE_HISTORY_IDS = gql`
    query getTradingVoucherTransactionByBalanceHistoryIds($balanceHistoryIds: [Int]) {
        getTradingVoucherTransactionByBalanceHistoryIds(balanceHistoryIds: $balanceHistoryIds) {
            ... on tradingVoucherTransactionAry {
                data {
                    _id
                    user {
                        username
                    }
                    account {
                        traderId
                    }
                    type
                    debt
                    amount
                    balanceHistoryId
                    status
                    remark
                    expirationDatetime
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getTradingVoucherTransactionByBalanceHistoryIds = async (balanceHistoryIds = []) => {
    try {
        const execute = await ApolloClientQuery(
            GET_TRADING_VOUCHER_TRANSACTION_BY_BALANCE_HISTORY_IDS,
            {
                balanceHistoryIds: balanceHistoryIds,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_TRADING_VOUCHER_TRANSACTION = gql`
    query getTradingVoucherTransaction(
        $filter: tradingVoucherTransactionInput
        $orderBy: String
        $limit: Int
        $offset: Int
        $startDate: String
        $endDate: String
        $dateKey: String
    ) {
        getTradingVoucherTransaction(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
            startDate: $startDate
            endDate: $endDate
            dateKey: $dateKey
        ) {
            ... on tradingVoucherTransactionAry {
                data {
                    _id
                    user {
                        username
                    }
                    account {
                        traderId
                    }
                    type
                    amount
                    balanceHistoryId
                    remark
                    status
                    comment
                    createdBy {
                        username
                    }
                    createdAt
                    expirationDatetime
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getTradingVoucherTransaction = ({filter}, orderBy?, limit?, offset?, startDate?, endDate?, dateKey?) => {
    try {
        const {loading, error, data, refetch} = useQuery(GET_TRADING_VOUCHER_TRANSACTION, {
            variables: {
                filter: filter,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
                orderBy: orderBy || null,
                limit: limit || null,
                offset: offset || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });
        const refreshQuery = () => {
            refetch();
        };
        return {loading, error, data, refreshQuery};
    } catch (err) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_TRADING_ACCOUNT = gql`
    mutation addTradingAccount($input: TradingAccountInput) {
        addTradingAccount(input: $input) {
            ... on TradingAccount {
                _id
                user {
                    _id
                    username
                    isLoginEnabled
                }
                accountId
                traderId
                brokerName
                accessRights
                depositAssetId
                hasTradingPermission
                spreadGroup {
                    _id
                    groupId
                    name
                }
                product {
                    _id
                    id
                    name
                    leverage
                    server {
                        platform
                        mode
                        name
                    }
                    spreadGroup {
                        _id
                        name
                    }
                }
                leverage
                leverageInCents
                balance
                totalBalance
                margin
                tradingVoucher
                availableMargin
                marginLevel
                credit
                equity
                registrationDatetime
                isSendReport
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;

const useAddTradingAccountMutation = (): {
    addTradingAccount: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [addTradingAccountMutation, {data, loading, error}] = useMutation(ADD_TRADING_ACCOUNT);

        const addTradingAccount = (input) => {
            return addTradingAccountMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {addTradingAccount}}) {
                    const typename = addTradingAccount?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(addTradingAccount?.errKey),
                            key: "addTradingAccount",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(addTradingAccount?.msg),
                            key: "addTradingAccount",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Add Trading Account Successfully"),
                            key: "addTradingAccount",
                        });
                    }
                },
                refetchQueries: [GET_TRADING_ACCOUNT],
            });
        };

        return {addTradingAccount, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {addTradingAccount: null, data: null, loading: false, error: err};
    }
};

const DELETE_TRADING_ACCOUNT = gql`
    mutation deleteTradingAccount($id: String!) {
        deleteTradingAccount(id: $id) {
            ... on TradingAccount {
                _id
                isDeleted
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useDeleteTradingAccountMutation = (): {
    deleteTradingAccount: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [deleteTradingAccountMutation, {data, loading, error}] = useMutation(DELETE_TRADING_ACCOUNT);

        const deleteTradingAccount = (id) => {
            return deleteTradingAccountMutation({
                variables: {
                    id: id,
                },
                update(cache, {data: {deleteTradingAccount}}) {
                    const typename = deleteTradingAccount?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(deleteTradingAccount?.errKey),
                            key: "deleteTradingAccount",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(deleteTradingAccount?.msg),
                            key: "deleteTradingAccount",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Delete Trading Account Successfully"),
                            key: "deleteTradingAccount",
                        });
                    }
                },
                refetchQueries: [GET_TRADING_ACCOUNT],
            });
        };

        return {deleteTradingAccount, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {
            deleteTradingAccount: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const LINK_TRADING_ACCOUNT = gql`
    mutation linkTradingAccount($input: TradingAccountInput) {
        linkTradingAccount(input: $input) {
            ... on TradingAccount {
                _id
                user {
                    _id
                    username
                    isLoginEnabled
                }
                accountId
                traderId
                brokerName
                accessRights
                depositAssetId
                hasTradingPermission
                spreadGroup {
                    _id
                    groupId
                    name
                }
                product {
                    _id
                    id
                    name
                    leverage
                    server {
                        platform
                        mode
                        name
                    }
                    spreadGroup {
                        _id
                        name
                    }
                }
                leverage
                leverageInCents
                balance
                totalBalance
                margin
                tradingVoucher
                availableMargin
                marginLevel
                credit
                equity
                registrationDatetime
                isSendReport
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useLinkTradingAccountMutation = (): {
    linkTradingAccount: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const {t} = useTranslation();
        const [linkTradingAccountMutation, {data, loading, error}] = useMutation(LINK_TRADING_ACCOUNT);

        const linkTradingAccount = (input) => {
            return linkTradingAccountMutation({
                variables: {
                    input: input,
                },
                update(cache, {data: {linkTradingAccount}}) {
                    const typename = linkTradingAccount?.__typename;
                    if (typename === "BaseError") {
                        setNotificationMessage({
                            status: "error",
                            title: "",
                            msg: t(linkTradingAccount?.errKey),
                            key: "linkTradingAccount",
                        });
                    } else if (typename === "CrmTaskApproval") {
                        setNotificationMessage({
                            status: "info",
                            title: "",
                            msg: t(linkTradingAccount?.msg),
                            key: "linkTradingAccount",
                        });
                    } else {
                        setNotificationMessage({
                            status: "success",
                            title: "",
                            msg: t("Link Trading Account Successfully"),
                            key: "linkTradingAccount",
                        });
                    }
                },
                refetchQueries: [GET_TRADING_ACCOUNT],
            });
        };

        return {linkTradingAccount, data, loading, error};
    } catch (err: any) {
        console.error(err);
        return {linkTradingAccount: null, data: null, loading: false, error: err};
    }
};

const UPDATE_TRADING_ACCOUNT = gql`
    mutation updateTradingAccount($id: String!, $input: TradingAccountUpdateInput!) {
        updateTradingAccount(id: $id, input: $input) {
            ... on TradingAccount {
                _id
                user {
                    username
                    isLoginEnabled
                }
                accountId
                adminPanelAccountStatus
                populate
                traderId
                brokerName
                accessRights
                depositAssetId
                hasTradingPermission
                spreadGroup {
                    _id
                    groupId
                    name
                }
                product {
                    _id
                    id
                    name
                    leverage
                    server {
                        platform
                        mode
                    }
                }
                leverage
                leverageInCents
                balance
                margin
                tradingVoucher
                hasDepositPermission
                hasWithdrawalPermission
                availableMargin
                marginLevel
                credit
                equity
                registrationDatetime
                isSendReport
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useUpdateTradingAccountMutation = () => {
    const [updateTradingAccountMutation, {data, loading, error}] = useMutation(UPDATE_TRADING_ACCOUNT);

    const updateTradingAccount = (id, input) => {
        return updateTradingAccountMutation({
            variables: {
                id: id,
                input: input,
            },
        });
    };

    let typename = data?.updateTradingAccount?.__typename;
    if (typename === "BaseError") {
        // Handle error
    }

    return {updateTradingAccount, data, loading, error};
};

const UPDATE_TRADING_ACCOUNT_ADMIN_PANEL_ACCOUNT_STATUS = gql`
    mutation updateTradingAccountAdminPanelAccountStatus($id: String!, $input: TradingAccountAdminPanelAccountStatusInput!){
        updateTradingAccountAdminPanelAccountStatus(id: $id, input: $input) {
            ... on TradingAccount {
                _id
                user {
                    username
                    isLoginEnabled
                }
                adminPanelAccountStatus
                populate
                accountId
                traderId
                brokerName
                accessRights
                depositAssetId
                hasTradingPermission
                spreadGroup {
                    _id
                    groupId
                    name
                }
                product {
                    _id
                    id
                    name
                    leverage
                    server {
                        platform
                        mode
                    }
                }
                leverage
                leverageInCents
                balance
                margin
                tradingVoucher
                hasDepositPermission
                hasWithdrawalPermission
                availableMargin
                marginLevel
                credit
                equity
                registrationDatetime
                isSendReport
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const updateTradingAccountAdminPanelAccountStatus = async (id, input) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_TRADING_ACCOUNT_ADMIN_PANEL_ACCOUNT_STATUS,
            {
                id: id,
                input: input,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UPDATE_TRADING_ACCOUNT_COMMISSION = gql`
    mutation updateTradingAccountCommission($id: String!, $input: TradingAccountCommRuleInput!) {
        updateTradingAccountCommission(id: $id, input: $input) {
            ... on TradingAccount {
                _id
                user {
                    username
                    isLoginEnabled
                }
                accountId
                traderId
                brokerName
                accessRights
                depositAssetId
                hasTradingPermission
                spreadGroup {
                    _id
                    groupId
                    name
                }
                product {
                    _id
                    id
                    name
                    leverage
                    server {
                        platform
                        mode
                    }
                }
                leverage
                leverageInCents
                balance
                margin
                tradingVoucher
                hasDepositPermission
                hasWithdrawalPermission
                availableMargin
                marginLevel
                credit
                equity
                registrationDatetime
                isSendReport
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useUpdateTradingAccountCommissionMutation = () => {
    const [updateTradingAccountCommissionMutation, {data, loading, error}] = useMutation(UPDATE_TRADING_ACCOUNT_COMMISSION);

    const updateTradingAccountCommission = (id, input) => {
        return updateTradingAccountCommissionMutation({
            variables: {
                id: id,
                input: input,
            },
        });
    };

    let typename = data?.updateTradingAccountCommission?.__typename;
    if (typename === "BaseError") {
        // Handle error
    }

    return {updateTradingAccountCommission, data, loading, error};
};

const RESET_TRADING_ACCOUNT_COMMISSION = gql`
    mutation resetTradingAccountCommission($id: String!, $commissionSetting: String) {
        resetTradingAccountCommission(id: $id, commissionSetting: $commissionSetting) {
            ... on TradingAccount {
                _id
                user {
                    username
                    isLoginEnabled
                }
                accountId
                traderId
                brokerName
                accessRights
                depositAssetId
                hasTradingPermission
                spreadGroup {
                    _id
                    groupId
                    name
                }
                product {
                    _id
                    id
                    name
                    leverage
                    server {
                        platform
                        mode
                    }
                }
                leverage
                leverageInCents
                balance
                margin
                tradingVoucher
                hasDepositPermission
                hasWithdrawalPermission
                availableMargin
                marginLevel
                credit
                equity
                registrationDatetime
                isSendReport
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useResetTradingAccountCommissionMutation = () => {
    const [resetTradingAccountCommissionMutation, {data, loading, error}] = useMutation(RESET_TRADING_ACCOUNT_COMMISSION);

    const resetTradingAccountCommission = (id, commissionSetting) => {
        return resetTradingAccountCommissionMutation({
            variables: {
                id: id,
                commissionSetting: commissionSetting,
            },
        });
    };

    let typename = data?.resetTradingAccountCommission?.__typename;
    if (typename === "BaseError") {
        // Handle error
    }

    return {resetTradingAccountCommission, data, loading, error};
};

const UPDATE_TRADING_ACCOUNT_PRODUCT = gql`
    mutation updateTradingAccountProduct($id: String!, $input: TradingAccountProductInput!) {
        updateTradingAccountProduct(id: $id, input: $input) {
            ... on TradingAccount {
                _id
                user {
                    username
                    isLoginEnabled
                }
                accountId
                traderId
                brokerName
                accessRights
                depositAssetId
                hasTradingPermission
                spreadGroup {
                    _id
                    groupId
                    name
                }
                product {
                    _id
                    id
                    name
                    leverage
                    server {
                        platform
                        mode
                    }
                }
                leverage
                leverageInCents
                balance
                margin
                tradingVoucher
                availableMargin
                marginLevel
                credit
                equity
                registrationDatetime
                isSendReport
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useUpdateTradingAccountProductMutation = () => {
    const [updateTradingAccountProductMutation, {data, loading, error}] = useMutation(UPDATE_TRADING_ACCOUNT_PRODUCT);

    const updateTradingAccountProduct = (id, input) => {
        return updateTradingAccountProductMutation({
            variables: {
                id: id,
                input: {
                    user: input.user,
                    product: input.product,
                },
            },
        });
    };

    let typename = data?.updateTradingAccountProduct?.__typename;
    if (typename === "BaseError") {
        // Handle error
    }

    return {updateTradingAccountProduct, data, loading, error};
};

const UPDATE_TRADING_ACCOUNT_LEVERAGE = gql`
    mutation updateTradingAccountLeverage($id: String!, $input: TradingAccountLeverageInput!) {
        updateTradingAccountLeverage(id: $id, input: $input) {
            ... on TradingAccount {
                _id
                user {
                    username
                    isLoginEnabled
                }
                accountId
                traderId
                brokerName
                accessRights
                depositAssetId
                hasTradingPermission
                spreadGroup {
                    _id
                    groupId
                    name
                }
                product {
                    _id
                    id
                    name
                    leverage
                    server {
                        platform
                        mode
                    }
                }
                leverage
                leverageInCents
                balance
                margin
                tradingVoucher
                availableMargin
                marginLevel
                credit
                equity
                registrationDatetime
                isSendReport
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useUpdateTradingAccountLeverageMutation = () => {
    const [updateTradingAccountLeverageMutation, {data, loading, error}] = useMutation(UPDATE_TRADING_ACCOUNT_LEVERAGE);

    const updateTradingAccountLeverage = (id, input) => {
        return updateTradingAccountLeverageMutation({
            variables: {
                id: id,
                input: {
                    user: input.user,
                    leverage: input.leverage,
                },
            },
        });
    };

    let typename = data?.updateTradingAccountLeverage?.__typename;
    if (typename === "BaseError") {
        // Handle error
    }

    return {updateTradingAccountLeverage, data, loading, error};
};

const UPDATE_TRADING_ACCOUNT_SPREAD_GROUP = gql`
    mutation updateTradingAccountSpreadGroup($id: String!, $input: TradingAccountSpreadGroupInput!) {
        updateTradingAccountSpreadGroup(id: $id, input: $input) {
            ... on TradingAccount {
                _id
                user {
                    username
                    isLoginEnabled
                }
                accountId
                traderId
                brokerName
                accessRights
                depositAssetId
                hasTradingPermission
                spreadGroup {
                    _id
                    groupId
                    name
                }
                product {
                    _id
                    id
                    name
                    leverage
                    server {
                        platform
                        mode
                    }
                }
                leverage
                leverageInCents
                balance
                margin
                tradingVoucher
                availableMargin
                marginLevel
                credit
                equity
                registrationDatetime
                isSendReport
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useUpdateTradingAccountSpreadGroupMutation = () => {
    const [updateTradingAccountSpreadGroupMutation, {data, loading, error}] = useMutation(UPDATE_TRADING_ACCOUNT_SPREAD_GROUP);

    const updateTradingAccountSpreadGroup = (id, input) => {
        return updateTradingAccountSpreadGroupMutation({
            variables: {
                id: id,
                input: {
                    user: input.user,
                    spreadGroup: input.spreadGroup,
                },
            },
        });
    };

    let typename = data?.updateTradingAccountSpreadGroup?.__typename;
    if (typename === "BaseError") {
        // Handle error
    }

    return {updateTradingAccountSpreadGroup, data, loading, error};
};

const ADD_TRADING_ACCOUNT_TRANSACTION = gql`
    mutation addTradingAccountTransaction($input: tradingAccountTransactionInput) {
        addTradingAccountTransaction(input: $input) {
            ... on tradingAccountTransaction {
                _id
                type
                amount
                status
                remark
                depositType
                withdrawalType
                comment
                createdAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useAddTradingAccountTransactionMutation = ({
    filter,
}): {
    addTradingAccountTransaction: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const [addTradingAccountTransactionMutation, {data, loading, error}] = useMutation(ADD_TRADING_ACCOUNT_TRANSACTION, {
            refetchQueries: [
                {
                    query: GET_TRADING_ACCOUNT_TRANSACTION,
                    variables: {
                        filter: filter,
                        startDate: null,
                        endDate: null,
                        dateKey: null,
                        orderBy: null,
                        limit: 10,
                        offset: null,
                    },
                },
            ],
        });

        const addTradingAccountTransaction = (input) => {
            return addTradingAccountTransactionMutation({
                variables: {
                    input:
                        input?.type === "deposit"
                            ? {
                                  user: input?.user,
                                  account: input?.account,
                                  type: input?.type,
                                  amount: input?.amount,
                                  depositType: input?.depositType,
                                  comment: input?.comment,
                                  sendEmailNotification: input?.sendEmailNotification,
                              }
                            : {
                                  user: input?.user,
                                  account: input?.account,
                                  type: input?.type,
                                  amount: input?.amount,
                                  withdrawalType: input?.withdrawalType,
                                  comment: input?.comment,
                                  sendEmailNotification: input?.sendEmailNotification,
                              },
                },
            });
        };

        return {addTradingAccountTransaction, data, loading, error};
    } catch (err) {
        console.error(err);
        return {
            addTradingAccountTransaction: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const ADD_TRADING_ACCOUNT_TRANSACTION_MANUAL = gql`
    mutation addTradingAccountTransactionManual($input: tradingAccountTransactionFixedTypeInput) {
        addTradingAccountTransactionManual(input: $input) {
            ... on tradingAccountTransaction {
                _id
                type
                amount
                status
                remark
                depositType
                withdrawalType
                comment
                createdAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useAddTradingAccountTransactionManualMutation = ({
    filter,
}): {
    addTradingAccountTransactionManual: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const [addTradingAccountTransactionManualMutation, {data, loading, error}] = useMutation(ADD_TRADING_ACCOUNT_TRANSACTION_MANUAL, {
            refetchQueries: [
                {
                    query: GET_TRADING_ACCOUNT_TRANSACTION,
                    variables: {
                        filter: filter,
                        startDate: null,
                        endDate: null,
                        dateKey: null,
                        orderBy: null,
                        limit: 10,
                        offset: null,
                    },
                },
            ],
        });

        const addTradingAccountTransactionManual = (input) => {
            return addTradingAccountTransactionManualMutation({
                variables: {
                    input: {
                        user: input?.user,
                        account: input?.account,
                        type: input?.type,
                        amount: input?.amount,
                        comment: input?.comment,
                        sendEmailNotification: input?.sendEmailNotification,
                    },
                },
            });
        };

        return {addTradingAccountTransactionManual, data, loading, error};
    } catch (err) {
        console.error(err);
        return {
            addTradingAccountTransactionManual: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const ADD_TRADING_ACCOUNT_TRANSACTION_EWALLET = gql`
    mutation addTradingAccountTransactionEWallet($input: tradingAccountTransactionFixedTypeInput) {
        addTradingAccountTransactionEWallet(input: $input) {
            ... on tradingAccountTransaction {
                _id
                type
                amount
                status
                remark
                depositType
                withdrawalType
                comment
                createdAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useAddTradingAccountTransactionEWalletMutation = ({
    filter,
}): {
    addTradingAccountTransactionEWallet: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const [addTradingAccountTransactionEWalletMutation, {data, loading, error}] = useMutation(ADD_TRADING_ACCOUNT_TRANSACTION_EWALLET, {
            refetchQueries: [
                {
                    query: GET_TRADING_ACCOUNT_TRANSACTION,
                    variables: {
                        filter: filter,
                        startDate: null,
                        endDate: null,
                        dateKey: null,
                        orderBy: null,
                        limit: 10,
                        offset: null,
                    },
                },
            ],
        });

        const addTradingAccountTransactionEWallet = (input) => {
            return addTradingAccountTransactionEWalletMutation({
                variables: {
                    input: {
                        user: input?.user,
                        account: input?.account,
                        type: input?.type,
                        amount: input?.amount,
                        comment: input?.comment,
                        sendEmailNotification: input?.sendEmailNotification,
                    },
                },
            });
        };

        return {addTradingAccountTransactionEWallet, data, loading, error};
    } catch (err) {
        console.error(err);
        return {
            addTradingAccountTransactionEWallet: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const ADD_TRADING_ACCOUNT_TRANSACTION_NEGATIVE_BALANCE = gql`
    mutation addTradingAccountTransactionNegativeBalanceProtection($input: tradingAccountTransactionFixedTypeInput) {
        addTradingAccountTransactionNegativeBalanceProtection(input: $input) {
            ... on tradingAccountTransaction {
                _id
                type
                amount
                status
                remark
                depositType
                withdrawalType
                comment
                createdAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const useAddTradingAccountTransactionNegativeBalanceProtectionMutation = ({
    filter,
}): {
    addTradingAccountTransactionNegativeBalanceProtection: any;
    data: any;
    loading: boolean;
    error: any;
} => {
    try {
        const [addTradingAccountTransactionNegativeBalanceProtectionMutation, {data, loading, error}] = useMutation(
            ADD_TRADING_ACCOUNT_TRANSACTION_NEGATIVE_BALANCE,
            {
                refetchQueries: [
                    {
                        query: GET_TRADING_ACCOUNT_TRANSACTION,
                        variables: {
                            filter: filter,
                            startDate: null,
                            endDate: null,
                            dateKey: null,
                            orderBy: null,
                            limit: 10,
                            offset: null,
                        },
                    },
                ],
            }
        );

        const addTradingAccountTransactionNegativeBalanceProtection = (input) => {
            return addTradingAccountTransactionNegativeBalanceProtectionMutation({
                variables: {
                    input: {
                        user: input?.user,
                        account: input?.account,
                        type: input?.type,
                        amount: input?.amount,
                        comment: input?.comment,
                        sendEmailNotification: input?.sendEmailNotification,
                    },
                },
            });
        };

        return {addTradingAccountTransactionNegativeBalanceProtection, data, loading, error};
    } catch (err) {
        console.error(err);
        return {
            addTradingAccountTransactionNegativeBalanceProtection: null,
            data: null,
            loading: false,
            error: err,
        };
    }
};

const BATCH_UPDATE_TRADING_ACCOUNT = gql`
    mutation batchUpdateTradingAccount($ids: [String]!, $input: TradingAccountBatchUpdateInput) {
        batchUpdateTradingAccount(ids: $ids, input: $input) {
            ... on TradingAccountAry {
                data {
                    _id
                    user {
                        username
                        isLoginEnabled
                    }
                    accountId
                    traderId
                    brokerName
                    accessRights
                    depositAssetId
                    hasTradingPermission
                    spreadGroup {
                        _id
                        groupId
                        name
                    }
                    product {
                        _id
                        id
                        name
                        leverage
                        server {
                            platform
                            mode
                        }
                    }
                    leverage
                    leverageInCents
                    balance
                    margin
                    tradingVoucher
                    availableMargin
                    marginLevel
                    credit
                    equity
                    registrationDatetime
                    isSendReport
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const useBatchUpdateTradingAccountMutation = () => {
    const [batchUpdateTradingAccountMutation, {data, loading, error}] = useMutation(BATCH_UPDATE_TRADING_ACCOUNT);

    const batchUpdateTradingAccount = (ids, input) => {
        return batchUpdateTradingAccountMutation({
            variables: {
                ids: ids,
                input: input,
            },
        });
    };

    let typename = data?.batchUpdateTradingAccount?.__typename;
    if (typename === "BaseError") {
        // Handle error
    }

    return {batchUpdateTradingAccount, data, loading, error};
};

const SYNC_TRADING_ACCOUNT_TRANSACTION = gql`
    mutation syncTradingAccountTransaction($id: String, $mode: String) {
        syncTradingAccountTransaction(id: $id, mode: $mode)
    }
`;

const syncTradingAccountTransaction = async (id, mode) => {
    try {
        const execute = await ApolloClientQuery(
            SYNC_TRADING_ACCOUNT_TRANSACTION,
            {
                id: id,
                mode: mode,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_TRADING_ACCOUNT_DEAL = gql`
    query getTradingAccountDeal($id: String, $fromDate: String, $toDate: String) {
        getTradingAccountDeal(id: $id, fromDate: $fromDate, toDate: $toDate) {
            ... on TradingAccountCtAry {
                data
                total
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;

const getTradingAccountDeal = async (id, fromDate, toDate) => {
    try {
        const execute = await ApolloClientQuery(
            GET_TRADING_ACCOUNT_DEAL,
            {
                id: id,
                fromDate: fromDate,
                toDate: toDate,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_TRADING_ACCOUNT_ORDER = gql`
    query getTradingAccountOrder($id: String, $fromDate: String, $toDate: String) {
        getTradingAccountOrder(id: $id, fromDate: $fromDate, toDate: $toDate) {
            ... on TradingAccountCtAry {
                data
                total
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;

const getTradingAccountOrder = async (id, fromDate, toDate) => {
    try {
        const execute = await ApolloClientQuery(
            GET_TRADING_ACCOUNT_ORDER,
            {
                id: id,
                fromDate: fromDate,
                toDate: toDate,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_TRADING_ACCOUNT_CLOSED_POSITION = gql`
    query getTradingAccountClosedPosition($id: String, $fromDate: String, $toDate: String) {
        getTradingAccountClosedPosition(id: $id, fromDate: $fromDate, toDate: $toDate) {
            ... on TradingAccountCtAry {
                data
                total
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`;

const getTradingAccountClosedPosition = async (id, fromDate, toDate) => {
    try {
        const execute = await ApolloClientQuery(
            GET_TRADING_ACCOUNT_CLOSED_POSITION,
            {
                id: id,
                fromDate: fromDate,
                toDate: toDate,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

export {
    GET_TRADING_ACCOUNT,
    getTradingAccountClosedPosition,
    getTradingAccountOrder,
    getTradingAccountDeal,
    getTradingAccount,
    getTradingAccountAsync,
    getTradingAccountSubset,
    getTradingAccountDetail,
    getTradingCreditTransaction,
    getTradingVoucherTransaction,
    getTradingAccountTransaction,
    useAddTradingAccountTransactionMutation,
    useAddTradingCreditTransactionMutation,
    useAddTradingVoucherTransactionMutation,
    useUpdateTradingAccountMutation,
    updateTradingAccountAdminPanelAccountStatus,
    useUpdateTradingAccountProductMutation,
    useUpdateTradingAccountLeverageMutation,
    useAddTradingAccountMutation,
    useDeleteTradingAccountMutation,
    useLinkTradingAccountMutation,
    useUpdateTradingAccountSpreadGroupMutation,
    useAddTradingAccountTransactionManualMutation,
    useAddTradingAccountTransactionEWalletMutation,
    useAddTradingAccountTransactionNegativeBalanceProtectionMutation,
    useBatchUpdateTradingAccountMutation,
    useUpdateTradingCreditTransactionMutation,
    useUpdateTradingVoucherTransactionMutation,
    useUpdateTradingAccountCommissionMutation,
    useResetTradingAccountCommissionMutation,
    syncTradingAccountTransaction,
    getTradingCreditTransactionByBalanceHistoryIds,
    getTradingVoucherTransactionByBalanceHistoryIds,
    getTradingAccountDetailAsync
};
