import React, {useEffect, useState} from "react";
import grapesjs from "grapesjs";
import newsletterPlugin from "grapesjs-preset-newsletter";
import "grapesjs/dist/css/grapes.min.css";
import {addCommand} from "../../../function/addCommand";
import {getTextFromObject} from "../../../function/common";

function GrapeJSEditor(props) {
    const {data, languageCode, imageVarData, textVarData, handleAnnouncementUpdate} = props;
    const [announcement, setAnnouncement] = useState("");
    const [editor, setEditor] = useState<Record<string, any>>();

    const renderCustomBlocks = (editor: Record<string, any>) => {
        const bm = editor?.Blocks;
        textVarData?.map((v: any) => {
            bm.add(v?.name, {
                // label: "VAR-" + v.name,
                label: v?.name,
                // media: `<i class="fa fa-text-width" aria-hidden="true"></i>`,
                category: "Brand-Text-Variable",
                activate: v?.isActive,
                content: `<div data-gjs-editable="false">{{dynamicVariable:${v?.name}}}</div>`,
            });
        });
        imageVarData?.map((v: any) => {
            let imgPath = v?.fileObject?.find((v) => v?.language === languageCode)?.path;
            if (imgPath) {
                bm?.add(v?.name, {
                    label: v?.name,
                    // media: `<i class="fa fa-file-image-o" aria-hidden="true"></i>`,
                    media: `<img src=${imgPath} alt=${v?.name} />`,
                    category: "Brand-Image-Variable",
                    activate: v?.isActive,
                    content: `<img src=${imgPath} alt=${v?.name} style="box-sizing: border-box; color: black;">`,
                });
            }
        });
    };

    const renderSaveBtn = async (editor: Record<string, any>) => {
        await editor?.Panels?.addButton("options", [
            {
                id: "save",
                className: "editorSaveButton fa fa-floppy-o icon-blank",
                command: handleSaveTemplate,
                attributes: {title: "Save Template"},
            },
        ])
        let saveBtn = document.getElementsByClassName("editorSaveButton")
        if (saveBtn?.length > 0) {
            saveBtn[0].innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V173.3c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32H64zm0 96c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V128zM224 288a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>`
        }
    }

    const renderEditBtn = async (editor: Record<string, any>) => {
        await editor?.Panels?.addButton("options", [
            {
                id: "edit",
                className: "editorEditButton fa fa-edit",
                command: "html-edit",
                attributes: {
                    title: "Edit",
                },
            },
        ])
        let editBtn = document.getElementsByClassName("editorEditButton")
        if (editBtn?.length > 0) {
            editBtn[0].innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"/></svg>`
        }
    }

    const collapseCategoriesBlocks = async (editor) => {
        try {
            const categories = editor.BlockManager.getCategories()
            categories.each((category) => {
                category.set("open", false).on("change:open", (opened) => {
                    opened.get("open") &&
                    categories.each((category) => {
                        category !== opened && category.set("open", false)
                    })
                })
            })
        } catch (error) {
        }
    }

    const handleSaveTemplate = async () => {
        try {
            let htmlData = editor?.runCommand("gjs-get-inlined-html");

            const existingData = await data;
            const dataArray = existingData?.getAnnouncementById?.dataArrayOfObject
            const filteredData = dataArray ? dataArray.filter((item) => item.language !== languageCode) : [];
            const currentLanguageEntry = dataArray ? dataArray.find((item) => item.language === languageCode) : [];

            const updatedEntry = {
                title: currentLanguageEntry?.title || null,
                description: currentLanguageEntry?.description || null,
                content: htmlData,
                language: languageCode,
            };

            const newDataArrayOfObject = [...filteredData, updatedEntry];

            let input = {
                dataArrayOfObject: newDataArrayOfObject
            };
            await handleAnnouncementUpdate(input);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchData = async () => {
        try {
            const dataArray = await data;
            if (dataArray?.getAnnouncementById?.dataArrayOfObject) {
                const htmldata = getTextFromObject(dataArray?.getAnnouncementById?.dataArrayOfObject, "content", languageCode);
                setAnnouncement(htmldata);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        fetchData();
    }, [languageCode, data]);

    // useEffect(() => {
    //   if (editor && imageVarData) {
    //     renderImageSelection(editor);
    //   }
    // }, [editor, imageVarData]);

    useEffect(() => {
        if (editor && textVarData && imageVarData) {
            renderCustomBlocks(editor);
            collapseCategoriesBlocks(editor)
        }
    }, [editor, textVarData, imageVarData]);

    useEffect(() => {
        if (editor && data) {
            addCommand(editor)
            renderEditBtn(editor)
            renderSaveBtn(editor)
        }
    }, [editor, data]);

    useEffect(() => {
        if (editor) {
            editor.on("change:changesCount", (e) => {
                // Change!
                // console.log("editor event");
                // console.log(e);
                const am = editor.AssetManager;
                am.render();
            });
        }
    }, [editor]);

    useEffect(() => {
        // if (announcement && !editor) {
        // if (announcement) {
        const editor = grapesjs.init({
            container: `#gjs-${languageCode}`,
            fromElement: true,
            height: "700px",
            storageManager: false,
            forceClass: false,
            modal: {
                backdrop: false,
            },
            plugins: [newsletterPlugin],
            pluginsOpts: {
                [newsletterPlugin.toString()]: {
                    modalLabelImport: "Paste all your code here below and click import",
                    modalLabelExport: "Copy the code and use it wherever you want",
                    importPlaceholder: '<table class="table"><tr><td class="cell">Hello world!</td></tr></table>',
                    cellStyle: {
                        "font-size": "12px",
                        "font-weight": 300,
                        "vertical-align": "top",
                        color: "rgb(111, 119, 125)",
                        margin: 0,
                        padding: 0,
                    },
                },
            },
            projectData: {
                pages: [
                    {
                        component: announcement,
                    },
                ],
            },
        });
        setEditor(editor);
        // }
        return () => {
        };
    }, [announcement]);

    return (
        <>
            {/* <div
        id={`gjs-${languageCode}`}
        dangerouslySetInnerHTML={{ __html: announcement }}
      ></div> */}
            <div id={`gjs-${languageCode}`}/>
        </>
    );
}

export default GrapeJSEditor;
