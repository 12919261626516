import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useRecoilState, useSetRecoilState} from "recoil";
import {FormProvider, useForm} from "react-hook-form";
import {Button, Col, Form, Popconfirm, Row} from "antd";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
    useAddTradingAccountTransactionEWalletMutation,
    useAddTradingAccountTransactionNegativeBalanceProtectionMutation,
} from "../../../../../../../../api/graphql/tradingAccount";
import yupFormSchema from "../../../../../../../../components/yup/yupFormSchema";
import SelectFormItem from "../../../../../../../../components/form/selectFormItem";
import {horizontalFormLayout} from "../../../../../../../../components/form/formWrapper";
import SwitchFormItem from "../../../../../../../../components/form/switchFormItem";
import {notificationMessage, selfPermissionObj} from "../../../../../../../../../recoil_state";
import TextAreaInputFormItem from "../../../../../../../../components/form/textAreaInputFormItem";
import InputNumberFormItem from "../../../../../../../../components/form/inputNumberFormItem";
import useAuthorize from "../../../../../../../../_common/authorize";
import {formatMoney} from "../../../../function";
import {useIsMobile} from "../../../../../../../../_common/layout";
import {tradingAccountTransactionRefreshState} from "../../../../state";
import {
    depositEWalletTradingAccountMt5,
    depositManualTradingAccountMt5,
    depositNegativeBalanceProtectionTradingAccountMt5,
    withdrawalEWalletTradingAccountMt5,
    withdrawalManualTradingAccountMt5,
} from "../../../../../../../../api/graphql/tradingAccountMt5";
import {addMamPammTransaction} from "../../../../../../../../api/graphql/mamPammTransaction";
import MamPammWithdrawDisclaimerModal from "./mamPammWithdrawDisclaimerModal";

const AccountMt5Transaction = (props) => {
    const {refreshQuery, refreshQueryTransaction, data} = props;
    const [btnLoading, setBtnLoading] = useState(false);
    const [errorFreeMargin, setErrorFreeMargin] = useState(false);
    const [maxFreeMarginAmount, setFreeMarginAmount] = useState(0);
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const setTradingAccountTransactionRefreshState = useSetRecoilState(tradingAccountTransactionRefreshState);
    const [selfPermission] = useRecoilState<any>(selfPermissionObj);
    const tradingAccountAccTxnPermission = selfPermission?.tradingAccount?.accountTransaction;

    const [transactionType, setTransactionType] = useState("deposit");
    const [depositType, setDepositType] = useState("");

    const [mamPammDisclaimerIsOpen, setMamPammDisclaimerIsOpen] = useState<boolean>(false);

    const {systemErrorNotification} = useAuthorize();
    const {addTradingAccountTransactionEWallet} = useAddTradingAccountTransactionEWalletMutation({
        filter: {
            account: props?.id,
        },
    });
    const {addTradingAccountTransactionNegativeBalanceProtection} = useAddTradingAccountTransactionNegativeBalanceProtectionMutation({
        filter: {
            account: props?.id,
        },
    });

    const yupSchema = yup.object().shape({
        type: yupFormSchema.string("Type", {required: true}),
        amount: yupFormSchema.number("Amount", {required: true}),
        depositType: yupFormSchema.string(
            "Deposit Type",
            props?.data?.user?.clientAccountType !== "demo" && !(props?.data?.product?.mode === "demo") ? {required: true} : {required: false}
        ),
        comment: yupFormSchema.string("Comment", {required: false}),
        sendEmailNotification: yupFormSchema.boolean("sendEmailNotification", {
            required: false,
        }),
    });

    const isMobile = useIsMobile(768);
    const labelCol = {span: isMobile ? 24 : 8, offset: 0};

    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
    });

    const isSelfTradeAccount = data?.product?.productType?.includes("selfTrade");
    const isInvestorAccount = data?.product?.productType?.includes("investor");
    const isMoneyManagerAccount = data?.product?.productType?.includes("moneyManager");

    const {t} = useTranslation();

    const onSubmit = async (value) => {
        try {
            if (isSelfTradeAccount || isMoneyManagerAccount) {
                if (props?.data?.user?.clientAccountType !== "demo" && !(props?.data?.product?.mode === "demo")) {
                    switch (value?.depositType) {
                        case "ewallet":
                            await onSubmitEWallet(value);
                            break;
                        case "manual":
                            await onSubmitManual(value);
                            break;
                        case "negativeBalanceProtection":
                            await onSubmitNegativeBalanceProtection(value);
                            break;
                    }
                } else {
                    await onSubmitManual(value);
                }
            } else if (isInvestorAccount) {
                await onSubmitMamPammTransaction(value);
            }
        } catch (error: any) {
            systemErrorNotification();
            setBtnLoading(false);
        }
    };

    const onSubmitMamPammTransaction = async (value) => {
        try {
            await setBtnLoading(true);
            let response;
            if (value?.type === "deposit") {
                response = await addMamPammTransaction({
                    user: props?.data?.user?._id,
                    amount: value?.amount,
                    moneyManagerAccount: data?.investorAccountSetting?.moneyManagerAccount?._id,
                    investorAccount: props?.id,
                    transactionType: value?.type,
                    depositType: isInvestorAccount && data?.product?.mode !== "live" ? "manual" : value?.depositType,
                    comment: value?.comment,
                    sendEmailNotification: value?.sendEmailNotification,
                });
            } else {
                response = await addMamPammTransaction({
                    user: props?.data?.user?._id,
                    amount: value?.amount,
                    moneyManagerAccount: data?.investorAccountSetting?.moneyManagerAccount?._id,
                    investorAccount: props?.id,
                    transactionType: value?.type,
                    withdrawalType: "manual",
                    comment: value?.comment,
                    sendEmailNotification: value?.sendEmailNotification,
                });
            }
            if (response?.addMamPammTransaction.__typename === "MamPammTransaction") {
                form.reset();
                if (data?.mode === "demo") {
                    setTradingAccountTransactionRefreshState(true);
                }
                refreshQuery();
                refreshQueryTransaction();
                let msg =
                    response?.addMamPammTransaction?.status === "pendingMoneyManagerAction"
                        ? "Pending Money Manager Approve"
                        : t("tradingAccount.create_transaction_successfully");
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: msg,
                    key: "addMamPammTransaction",
                });
            } else if (response?.addMamPammTransaction.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.addMamPammTransaction?.errKey),
                    key: "addMamPammTransaction",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.addMamPammTransaction?.msg),
                    key: "addMamPammTransaction",
                });
            }
            // Pending withdrawal flow
            setBtnLoading(false);
        } catch (error: any) {
            systemErrorNotification();
            setBtnLoading(false);
        }
    };

    const onSubmitManual = async (value) => {
        try {
            await setBtnLoading(true);

            if (value?.type === "deposit") {
                const response = await depositManualTradingAccountMt5({
                    user: props?.data?.user?._id,
                    account: props?.id,
                    amount: value?.amount.toString(),
                    comment: value?.comment,
                    sendEmailNotification: value?.sendEmailNotification,
                });

                if (response?.depositManualTradingAccountMt5.__typename === "TradingAccountMt5Transaction") {
                    form.reset();
                    if (data?.mode === "demo") {
                        setTradingAccountTransactionRefreshState(true);
                    }
                    refreshQuery();
                    refreshQueryTransaction();
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: t("tradingAccount.create_transaction_successfully"),
                        key: "depositManualTradingAccountMt5",
                    });
                } else if (response?.depositManualTradingAccountMt5.__typename === "BaseError") {
                    setNotificationMessage({
                        status: "error",
                        title: "",
                        msg: t(response?.depositManualTradingAccountMt5?.errKey),
                        key: "depositManualTradingAccountMt5",
                    });
                } else {
                    setNotificationMessage({
                        status: "info",
                        title: "",
                        msg: t(response?.depositManualTradingAccountMt5?.msg),
                        key: "depositManualTradingAccountMt5",
                    });
                }
            } else if (value?.type === "withdrawal") {
                const response = await withdrawalManualTradingAccountMt5({
                    user: props?.data?.user?._id,
                    account: props?.id,
                    amount: value?.amount.toString(),
                    comment: value?.comment,
                    sendEmailNotification: value?.sendEmailNotification,
                });

                if (response?.withdrawalManualTradingAccountMt5.__typename === "TradingAccountMt5Transaction") {
                    form.reset();
                    if (data?.mode === "demo") {
                        setTradingAccountTransactionRefreshState(true);
                    }
                    refreshQuery();
                    refreshQueryTransaction();
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: t("tradingAccount.create_transaction_successfully"),
                        key: "withdrawalManualTradingAccountMt5",
                    });
                } else if (response?.withdrawalManualTradingAccountMt5.__typename === "BaseError") {
                    setNotificationMessage({
                        status: "error",
                        title: "",
                        msg: t(response?.withdrawalManualTradingAccountMt5?.errKey),
                        key: "withdrawalManualTradingAccountMt5",
                    });
                } else {
                    setNotificationMessage({
                        status: "info",
                        title: "",
                        msg: t(response?.withdrawalManualTradingAccountMt5?.msg),
                        key: "withdrawalManualTradingAccountMt5",
                    });
                }
            }

            setBtnLoading(false);
        } catch (error: any) {
            systemErrorNotification();
            setBtnLoading(false);
        }
    };

    const onSubmitEWallet = async (value) => {
        try {
            await setBtnLoading(true);

            if (value?.type === "deposit") {
                const response = await depositEWalletTradingAccountMt5({
                    user: props?.data?.user?._id,
                    account: props?.id,
                    amount: value?.amount.toString(),
                    comment: value?.comment,
                    sendEmailNotification: value?.sendEmailNotification,
                });

                if (response?.depositEWalletTradingAccountMt5.__typename === "TradingAccountMt5Transaction") {
                    form.reset();
                    if (data?.mode === "demo") {
                        setTradingAccountTransactionRefreshState(true);
                    }
                    refreshQuery();
                    refreshQueryTransaction();
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: t("tradingAccount.create_transaction_successfully"),
                        key: "depositEWalletTradingAccountMt5",
                    });
                } else if (response?.depositEWalletTradingAccountMt5.__typename === "BaseError") {
                    setNotificationMessage({
                        status: "error",
                        title: "",
                        msg: t(response?.depositEWalletTradingAccountMt5?.errKey),
                        key: "depositEWalletTradingAccountMt5",
                    });
                } else {
                    setNotificationMessage({
                        status: "info",
                        title: "",
                        msg: t(response?.depositEWalletTradingAccountMt5?.msg),
                        key: "depositEWalletTradingAccountMt5",
                    });
                }
            } else if (value?.type === "withdrawal") {
                const response = await withdrawalEWalletTradingAccountMt5({
                    user: props?.data?.user?._id,
                    account: props?.id,
                    amount: value?.amount.toString(),
                    comment: value?.comment,
                    sendEmailNotification: value?.sendEmailNotification,
                });

                if (response?.withdrawalEWalletTradingAccountMt5.__typename === "TradingAccountMt5Transaction") {
                    form.reset();
                    if (data?.mode === "demo") {
                        setTradingAccountTransactionRefreshState(true);
                    }
                    refreshQuery();
                    refreshQueryTransaction();
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: t("tradingAccount.create_transaction_successfully"),
                        key: "withdrawalEWalletTradingAccountMt5",
                    });
                } else if (response?.withdrawalEWalletTradingAccountMt5.__typename === "BaseError") {
                    setNotificationMessage({
                        status: "error",
                        title: "",
                        msg: t(response?.withdrawalEWalletTradingAccountMt5?.errKey),
                        key: "withdrawalEWalletTradingAccountMt5",
                    });
                } else {
                    setNotificationMessage({
                        status: "info",
                        title: "",
                        msg: t(response?.withdrawalEWalletTradingAccountMt5?.msg),
                        key: "withdrawalEWalletTradingAccountMt5",
                    });
                }
            }

            setBtnLoading(false);
        } catch (error: any) {
            systemErrorNotification();
            setBtnLoading(false);
        }
    };

    const onSubmitNegativeBalanceProtection = async (value) => {
        try {
            await setBtnLoading(true);

            const response = await depositNegativeBalanceProtectionTradingAccountMt5({
                user: props?.data?.user?._id,
                account: props?.id,
                amount: value?.amount.toString(),
                comment: value?.comment,
                sendEmailNotification: value?.sendEmailNotification,
            });

            if (response?.depositNegativeBalanceProtectionTradingAccountMt5.__typename === "TradingAccountMt5Transaction") {
                form.reset();
                if (data?.mode === "demo") {
                    setTradingAccountTransactionRefreshState(true);
                }
                refreshQuery();
                refreshQueryTransaction();
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("tradingAccount.create_transaction_successfully"),
                    key: "depositNegativeBalanceProtectionTradingAccountMt5",
                });
            } else if (response?.depositNegativeBalanceProtectionTradingAccountMt5.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.depositNegativeBalanceProtectionTradingAccountMt5?.errKey),
                    key: "depositNegativeBalanceProtectionTradingAccountMt5",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.depositNegativeBalanceProtectionTradingAccountMt5?.msg),
                    key: "depositNegativeBalanceProtectionTradingAccountMt5",
                });
            }

            setBtnLoading(false);
        } catch (error: any) {
            systemErrorNotification();
            setBtnLoading(false);
        }
    };

    const transactionTypeOptions = [
        {
            value: "deposit",
            label: t("tradingAccount.deposit"),
        },
        {
            value: "withdrawal",
            label: t("tradingAccount.withdrawal"),
        },
    ];

    // const depositTypeOptions = [
    //     {
    //         value: "manual",
    //         label: t("tradingAccount.manual_deposit"),
    //     },
    //     {
    //         value: "negativeBalanceProtection",
    //         label: t("tradingAccount.negative_balance_protection"),
    //     },
    //     {
    //         value: "ewallet",
    //         label: t("tradingAccount.deposit_via_ewallet"),
    //     },
    // ];

    const depositTypeOptions = useMemo(() => {
        let list = [
            {
                value: "manual",
                label: t("tradingAccount.manual_deposit"),
            },
            {
                value: "negativeBalanceProtection",
                label: t("tradingAccount.negative_balance_protection"),
            },
        ];
        if (isSelfTradeAccount) {
            list.push({
                value: "ewallet",
                label: t("tradingAccount.deposit_via_ewallet"),
            });
        }
        return list;
    }, [data]);

    const withdrawalTypeOptions = useMemo(() => {
        let list = [
            {
                value: "manual",
                label: t("tradingAccount.manual_withdrawal"),
            },
        ];
        if (isSelfTradeAccount) {
            list.push({
                value: "ewallet",
                label: t("tradingAccount.withdrawal_to_ewallet"),
            });
        }
        return list;
    }, [data]);

    const isAddable = tradingAccountAccTxnPermission?.add?.add;

    const verifyFreeMargin = (e) => {
        const accData = props.data;
        if (accData?.openPosition && e > accData?.freeMarginWithdrawableAmount) {
            setErrorFreeMargin(true);
            setFreeMarginAmount(accData?.freeMarginWithdrawableAmount);
        } else {
            setErrorFreeMargin(false);
            setFreeMarginAmount(0);
        }
    };

    return (
        <div id="AccountTransaction">
            {
                isInvestorAccount && transactionType === "withdrawal" &&
                <MamPammWithdrawDisclaimerModal setTransactionType={setTransactionType} isOpen={mamPammDisclaimerIsOpen} setIsOpen={setMamPammDisclaimerIsOpen}
                                                id={props?.id}/>
            }
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="form-1">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12}>
                            <SelectFormItem
                                name={"type"}
                                label={t("tradingAccount.transaction_type") + ":"}
                                placeholder={t("tradingAccount.transaction_type")}
                                layout={horizontalFormLayout}
                                options={transactionTypeOptions}
                                mode={"single"}
                                labelCol={labelCol}
                                labelAlign={"left"}
                                required
                                onChange={(e) => {
                                    setTransactionType(e);
                                    if (e === "withdrawal" && isInvestorAccount) setMamPammDisclaimerIsOpen(true)

                                    const options = e === "withdrawal" ? withdrawalTypeOptions : depositTypeOptions;
                                    if (options?.length === 1) {
                                        form.setValue("depositType", options[0]?.value)
                                    }
                                }}
                                value={transactionType}
                                disabled={!isAddable}
                            />
                        </Col>

                        {props?.data?.user?.clientAccountType !== "demo" && props?.data?.product?.mode === "live" && (
                            <>
                                {transactionType === "deposit" ? (
                                    <Col xs={24} md={12}>
                                        <SelectFormItem
                                            name={"depositType"}
                                            label={t("tradingAccount.deposit_type") + ":"}
                                            placeholder={t("tradingAccount.deposit_type")}
                                            onChange={(e) => setDepositType(e)}
                                            layout={horizontalFormLayout}
                                            options={depositTypeOptions}
                                            required
                                            mode={"single"}
                                            labelCol={labelCol}
                                            labelAlign={"left"}
                                            value={""}
                                            disabled={!isAddable}
                                        />
                                    </Col>
                                ) : (
                                    <Col xs={24} md={12}>
                                        <SelectFormItem
                                            name={"depositType"}
                                            label={t("tradingAccount.withdrawal_type") + ":"}
                                            placeholder={t("tradingAccount.withdrawal_type")}
                                            layout={horizontalFormLayout}
                                            onChange={(e) => setDepositType(e)}
                                            options={withdrawalTypeOptions}
                                            required
                                            mode={"single"}
                                            labelCol={labelCol}
                                            labelAlign={"left"}
                                            value={""}
                                            disabled={!isAddable}
                                        />
                                    </Col>
                                )}
                            </>
                        )}

                        <Col xs={24} md={12}>
                            <InputNumberFormItem
                                name={"amount"}
                                label={t("tradingAccount.amount") + ":"}
                                placeholder={t("tradingAccount.amount")}
                                layout={horizontalFormLayout}
                                required
                                labelCol={labelCol}
                                labelAlign={"left"}
                                style={{width: "100%"}}
                                disabled={!isAddable}
                                min={0.01}
                                step={2}
                                onChange={verifyFreeMargin}
                                externalErrorMessage={
                                    transactionType !== "deposit" &&
                                    errorFreeMargin &&
                                    !tradingAccountAccTxnPermission?.add?.overwriteFreeMarginWithdrawal
                                        ? "Withdrawal limit exceeded, not more than " + formatMoney(maxFreeMarginAmount)
                                        : null
                                }
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <SwitchFormItem
                                name={"sendEmailNotification"}
                                label={t("tradingAccount.send_email_notification") + ":"}
                                labelCol={{span: 16, offset: 0}}
                                labelAlign={"left"}
                                value={false}
                                disabled={!isAddable}
                                switchAlignRight={true}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <TextAreaInputFormItem
                                name={"comment"}
                                label={t("tradingAccount.comment")}
                                placeholder={t("tradingAccount.comment")}
                                layout={horizontalFormLayout}
                                labelCol={labelCol}
                                labelAlign={"left"}
                                // maxLength={25}
                                showCount={true}
                                disabled={
                                    !isAddable ||
                                    (depositType !== "manual" &&
                                        props?.data?.user?.clientAccountType !== "demo" &&
                                        props?.data?.product?.mode === "live")
                                }
                            />
                        </Col>
                        <Col span={24}>
                            <Form.Item className="text-align-right" style={{paddingTop: "12px"}}>
                                {transactionType !== "deposit" &&
                                errorFreeMargin &&
                                tradingAccountAccTxnPermission?.add?.overwriteFreeMarginWithdrawal ? (
                                    <Popconfirm
                                        title="Warning"
                                        description={
                                            "Withdrawal limit exceeded, not more than " +
                                            formatMoney(maxFreeMarginAmount) +
                                            ", Are you sure to continue proceed?"
                                        }
                                        onConfirm={form.handleSubmit(onSubmit)}
                                    >
                                        <Button
                                            className="button-submit-1"
                                            loading={btnLoading}
                                            disabled={
                                                !isAddable ||
                                                btnLoading ||
                                                (transactionType !== "deposit" &&
                                                    errorFreeMargin &&
                                                    !tradingAccountAccTxnPermission?.add?.overwriteFreeMarginWithdrawal)
                                            }
                                            type={"primary"}
                                        >
                                            {t("common.submit")}
                                        </Button>
                                    </Popconfirm>
                                ) : (
                                    <Button
                                        onClick={form.handleSubmit(onSubmit)}
                                        className="button-submit-1"
                                        loading={btnLoading}
                                        disabled={
                                            !isAddable ||
                                            btnLoading ||
                                            (transactionType !== "deposit" &&
                                                errorFreeMargin &&
                                                !tradingAccountAccTxnPermission?.add?.overwriteFreeMarginWithdrawal)
                                        }
                                        type={"primary"}
                                    >
                                        {t("common.submit")}
                                    </Button>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </form>
            </FormProvider>
        </div>
    );
};

export default AccountMt5Transaction;
