import React, {useCallback, useEffect, useState} from "react";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import ClientsTopBar from "../../component/clientsTopBar";
import ClientsAddForm from "../../component/clientsAddForm";
import {useTranslation} from "react-i18next";
import ImportEWalletAdjustmentForm from "../../component/importEWalletAdjustmentImportForm";
import {formatSensitiveFields, renderColumns} from "../../../../_common/function";
import {Button, Col, Spin, Switch} from "antd";
import {useGetUser} from "../../function/useQueryHooks";
import {formatDateTimeTimezone, formatDateUTC} from "../../../../../../function/_common";
import {ColumnsClient} from "../../function/tableColumn";
import {getBooleanFilterProps} from "../../../../../../components/table/function/filterTable";
import {useUpdateUserLoginEnabledMutation} from "../../../../../../api/graphql/user";
import {useRecoilValue} from "recoil";
import {selfPermissionObj} from "../../../../../../../recoil_state";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {BsCheck, BsX} from "react-icons/bs";

function Clients_1(props) {
    const {
        tableName,
        userNetworkId,
        fieldList,
        profileInformation,
        permissionUser,
        commRule
    } = props;

    const {t} = useTranslation();

    const [isShowingArchive, setIsShowingArchive] = useState(false)
    const [orderBy, setOrderBy] = useState<any>("registrationDate_DSC")
    const [limit, setLimit] = useState<any>(20)
    const [offset, setOffset] = useState<any>(0)
    const [filter, setFilter] = useState<any>({})
    const [filterType, setFilterType] = useState<any>("")
    const [filterTypeId, setFilterTypeId] = useState<any>("")
    const [isAddClientsOpen, setIsAddClientsOpen] = useState(false);
    const [isImportEWalletOpen, setIsImportEWalletOpen] = useState(false);
    const [batchUpdateRecord, setBatchUpdateRecord] = useState<any>([]);
    const [formattedExportData, setFormattedExportData] = useState<any>([])
    const [formattedData, setFormattedData] = useState<any>([])

    const [userData, userTotal, userLoading, fetchUserData, refetchUserData] = useGetUser()

    const columns = ColumnsClient(refetchUserData)
    const [column, setColumn] = useState<any>();

    useEffect(() => {
        if (profileInformation?.length > 0) {
            renderColumns(profileInformation, setColumn, columns, "client", t);
        }
    }, [profileInformation]);

    const isLoading = userLoading
    const total = userTotal

    const isClearFilterVisible = Object.keys(filter)?.length > 0 || filterTypeId
    const resetFilter = () => {
        setFilter({})
        setFilterType("")
        setFilterTypeId("")
    }

    useEffect(() => {
        fetchUserData({
            filter: {
                referrerId: userNetworkId,
                commRule: commRule,
                ...filter,
                userType: "client",
                userNetwork: filterTypeId,
                isDeleted: isShowingArchive
            }
        }, limit, offset, orderBy)

    }, [userNetworkId, commRule, filter, filterTypeId, limit, offset, orderBy, isShowingArchive]);

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize)
        setOffset(limit * (page - 1))
    }

    useEffect(() => {
        setOffset(0)
    }, [isShowingArchive])

    const formatUserData = async (data) => {
        return await data?.map((d) => {
            return {
                ...d,
                // rank: d?.rank?.name,
                registrationDate: d?.registrationDate ? formatDateTimeTimezone(d?.registrationDate) : "",
                nextFollowUp: d?.nextFollowUp ? formatDateTimeTimezone(d?.nextFollowUp) : "-",
                lastFollowUp: d?.lastFollowUp ? formatDateTimeTimezone(d?.lastFollowUp) : "-",
                lastDepositDate: d?.lastDepositDate ? formatDateTimeTimezone(d?.lastDepositDate) : "-",
                lastActivitiesDate: d?.lastActivitiesDate ? formatDateTimeTimezone(d?.lastActivitiesDate) : "-",
                // nextFollowUp: d?.latestFollowUp?.followUpDateTime ? formatDateTimeTimezone(d?.latestFollowUp?.followUpDateTime) : "",
                // lastFollowUp: d?.latestFollowUp?.lastFollowUp ? formatDateTimeTimezone(d?.latestFollowUp?.lastFollowUp) : "",
                // lastDepositDate: d?.lastDepositDate ? formatDateUTC(d?.lastDepositDate) : "",
                comment: t(d?.comment)
            };
        });
    }

    const processData = (data, e, permission) => {
        if (permission) {
            formatUserData(data)?.then(value => e(value))
        } else {
            formatUserData(data)?.then(value => formatSensitiveFields(value, profileInformation))?.then(value => e(value))
        }
    }

    useEffect(() => {
        if (!userLoading) {
            // processData(isShowingArchive ? archiveUserData : userData, setFormattedData, permissionUser?.clientsUser?.view?.sensitiveInformation)
            processData(userData, setFormattedData, permissionUser?.clientsUser?.view?.sensitiveInformation)
        }
    }, [isShowingArchive, userData, profileInformation, permissionUser])

    const onRowSelection = (_, selectedRows) => {
        setBatchUpdateRecord(selectedRows);
    };

    const closeAddForm = () => {
        setIsAddClientsOpen(false);
    };

    const closeImportEWalletAdjustmentForm = () => {
        setIsImportEWalletOpen(false);
    };

    const IsLoginEnabledColumn = useCallback((props) => {
        const {updateUserLoginEnabled} = useUpdateUserLoginEnabledMutation();
        const permission = useRecoilValue<any>(selfPermissionObj);

        const isLoginEnabledOnChange = async (value, record) => {
            await updateUserLoginEnabled(record?.parentId, {isLoginEnabled: value})
            fetchUserData({
                filter: {
                    referrerId: userNetworkId,
                    commRule: commRule,
                    ...filter,
                    userType: "client",
                    userNetwork: filterTypeId,
                    isDeleted: isShowingArchive
                }
            }, limit, offset, orderBy)
        };

        let component
        if (permission?.user?.clientsUser?.edit?.edit && props.record?.isDeleted === false) {
            component = <Switch
                checkedChildren={<CheckOutlined/>}
                unCheckedChildren={<CloseOutlined/>}
                checked={props?.value}
                size={"small"}
                onChange={(value) => isLoginEnabledOnChange(value, props.record)}
            />
        } else {
            component = props?.value ? <BsCheck style={{color: 'green'}}/> : <BsX style={{color: 'red'}}/>
        }

        return component
    }, [filter, limit, offset, orderBy]);

    return (
        <div id={"client"}>
            {permissionUser?.clientsUser?.add?.add && (
                <ClientsAddForm isOpen={isAddClientsOpen} close={closeAddForm} profileInformation={profileInformation}
                                permission={permissionUser?.clientsUser} refetchUserData={refetchUserData}/>
            )}
            {permissionUser?.clientsUser?.edit?.ewalletAdjustment && (
                <ImportEWalletAdjustmentForm
                    isOpen={isImportEWalletOpen}
                    close={closeImportEWalletAdjustmentForm}
                    fieldList={fieldList}
                />
            )}
            <h1>
                {isShowingArchive ? t("user.tableArchived", {tableName: t("user.client")}) : t("user.tableActive", {tableName: t("user.client")})}
                {
                    isClearFilterVisible &&
                    <Button className={"margin-left-0-5"} size={"small"} onClick={() => resetFilter()}>
                        {t("common.clearFilter")}
                    </Button>
                }
            </h1>
            <ClientsTopBar
                setIsAddClientsOpen={setIsAddClientsOpen}
                setIsImportEWalletOpen={setIsImportEWalletOpen}
                batchUpdateRecord={batchUpdateRecord}
                setBatchUpdateRecord={setBatchUpdateRecord}
                setFilterType={setFilterType}
                setFilterTypeId={setFilterTypeId}
                filterType={filterType}
                tableName={tableName}
                exportData={formattedExportData}
                exportColumn={column}
                permissionUser={permissionUser}
                isShowingArchive={isShowingArchive}
                setIsShowingArchive={setIsShowingArchive}
                refetchUserData={refetchUserData}
            />
            {column?.length > 0 ? (
                <TableAntDesign
                    data={formattedData}
                    tableName={tableName}
                    columns={[...column, {
                        title: t("user.loginPermission"),
                        dataIndex: "isLoginEnabled",
                        key: "isLoginEnabled",
                        ...getBooleanFilterProps(),
                        align: "center",
                        render: (text, record) => <IsLoginEnabledColumn value={text} record={record}/>,
                    }]}
                    filterValue={filter}
                    size={"small"}
                    loading={isLoading}
                    filter={setFilter}
                    order={setOrderBy}
                    rowKey={"parentId"}
                    selectionType={permissionUser?.clientsUser?.batchAction && !isShowingArchive ? "checkbox" : undefined}
                    onRowSelectionHandler={onRowSelection}
                    selectedRowKeys={batchUpdateRecord?.map((d) => d?.parentId)}
                    pagination={{
                        pageSize: limit,
                        showSizeChanger: true,
                        onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                        total: total,
                        showTotal: (total) => <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                    }}
                />
            ) : (
                <Col span={24} style={{marginTop: "17%", textAlign: "center"}}>
                    <Spin size="large"/>
                </Col>
            )}
        </div>
    );
}

export default Clients_1;
