import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import yupFormSchema from "../../../../../shared/components/yup/yupFormSchema";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {MetaproBadge} from "./component/badge";
import {CommonSphereGroup} from "./component/sphere";
import {Button, Image, Row} from "antd";
import "./css/style.css"
import backgroundImage from "./assets/bg.png"
import InputFormItem from "../../../../../shared/components/form/inputFormItem";
import {verticalFormLayout} from "../../../../../shared/components/form/formWrapper";

const LoginPage_metapro = (props) => {
    const {loginAction, loginData, loginLoading, brandSetting, languageList, setLanguage} = props;
    const {t} = useTranslation();

    const [isTyping, setIsTyping] = useState<any>(false);

    const loginSchema = yup.object().shape({
        login: yupFormSchema.string("login", {
            required: true,
            requiredErrorMessage: "Username Credential is required",
        }),
        password: yupFormSchema.string("password", {
            required: true,
            requiredErrorMessage: "Password is required",
        }),
    });

    const [initialValues] = useState(() => ({
        login: "",
        password: "",
    }));

    const form = useForm({
        resolver: yupResolver(loginSchema),
        mode: "all",
        defaultValues: initialValues,
    });

    const onSubmit = async (values) => {
        const record = {
            ...values,
            type: "crmUser",
        };
        loginAction(record);
        setIsTyping(false);
    };

    const brandSlogan = brandSetting?.getCrmUserBrandSetting?.companySlogan;
    const brandLogo = brandSetting?.getCrmUserBrandSetting?.companyLogoInverse

    return (
        <div id={"metapro-login-page"}>
            <div id={"metapro-login-page-backdrop"} style={{background: `url(${backgroundImage})`}}/>
            <MetaproBadge/>
            <CommonSphereGroup/>

            <div className="headerSection">
                <div className="logo">
                    <Image
                        preview={false}
                        width={100}
                        src={brandLogo}
                    />
                </div>
            </div>

            <div className="mainSection">
                <div className="form-content">
                    <div className="login-title margin-bottom-0-75">{t("Login")}</div>
                    <FormProvider {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)} className="login-form-1">
                            <InputFormItem
                                className={"login-input"}
                                name={"login"}
                                label={"Username"}
                                placeholder={"Username"}
                                layout={verticalFormLayout}
                                onChange={(e) => setIsTyping(e)}
                                required
                            />
                            <InputFormItem
                                name={"password"}
                                label={"Password"}
                                placeholder={"Password"}
                                type={"password"}
                                layout={verticalFormLayout}
                                onChange={(e) => setIsTyping(e)}
                                required
                            />
                            {loginData && loginData.login && loginData.login.errMsg && isTyping === false && (
                                <p
                                    style={{
                                        color: "red",
                                        marginTop: 16,
                                        fontSize: 12,
                                        textAlign: "center",
                                    }}
                                >
                                    {loginData.login.errMsg}
                                </p>
                            )}
                            <Row justify={"center"}>
                                <Button
                                    block
                                    className="btn-login margin-top-0-75"
                                    type="primary"
                                    onClick={form.handleSubmit(onSubmit)}
                                    disabled={loginLoading}
                                >
                                    {t("Login")}
                                </Button>
                            </Row>
                        </form>
                    </FormProvider>
                </div>
            </div>
        </div>
    )
}

export default LoginPage_metapro