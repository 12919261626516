import axios from "axios";
import {getConfigByName} from "../../../../config";
import {BRAND_NAME} from "../../../../config/brandVariable";

const url = getConfigByName(BRAND_NAME).arenaURL
const config = {headers: {"Content-Type": "text/plain", "authorization": `Bearer ${localStorage.getItem("arenaToken")}`}}

export const getGiftSetting = async () => {
    try {
        return await axios.post(`${url}/api/gift/setting/get`, {filter: {}, limit: 10}, config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export const createGiftSetting = async (input) => {
    try {
        return await axios.post(`${url}/api/gift/setting/create`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export const updateGiftSetting = async (input) => {
    try {
        return await axios.post(`${url}/api/gift/setting/update`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export const deleteGiftSetting = async (input) => {
    try {
        return await axios.post(`${url}/api/gift/setting/delete`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}