import React, {useState} from "react";
import DocumentList from "./component/documentList";
import {Button, Col, Form, Input, Modal, Row} from "antd";
import {useTranslation} from "react-i18next";
import {addMamPammLegalDocument} from "../../../../../api/graphql/mamPammLegalDocument";
import useAuthorize from "../../../../../_common/authorize";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../recoil_state";

function MamPammLegalDocument_1(props) {
    const {permission, language, data, getData} = props;
    const {t} = useTranslation();
    const {systemErrorNotification} = useAuthorize();
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const labelCol = {span: 8, offset: 0};

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);

    const [addDocumentform] = Form.useForm();

    const addDocument = async (value) => {
        try {
            addDocumentform.validateFields();

            const res = await addMamPammLegalDocument({...value, isDefault: true});
            const response = res?.addMamPammLegalDocument;
            if (response?.__typename === "MamPammLegalDocument") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("common.addSuccess"),
                    key: "addMamPammLegalDocument",
                });
                getData();
                setIsOpen(false);
            } else if (response?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.errKey),
                    key: "addMamPammLegalDocument",
                });
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.msg),
                    key: "addMamPammLegalDocument",
                });
                setIsOpen(false);
            }
            addDocumentform.resetFields();
        } catch (error: any) {
            systemErrorNotification();
        }
    };

    const onSubmit = async () => {
        try {
            setSubmitLoading(true);
        } catch (error) {
        } finally {
            setSubmitLoading(false);
        }
    };

    const isEditable = permission.edit.edit;

    return (
        <div>
            <Row justify={"space-between"}>
                <Col span={12}>
                    <h1>{t("mampamm.legalDocuments")}</h1>
                </Col>
                <Col>{permission?.add?.add && <Button onClick={() => setIsOpen(true)}>{t("brandSetting.addDocument")}</Button>}</Col>
            </Row>

            <DocumentList
                addLegalDocument={onSubmit}
                submitLoading={submitLoading}
                language={language}
                isEditable={isEditable}
                data={data}
                getData={getData}
                permission={permission}
            />
            <Modal
                title={t(`legalDocument.addLegalDocument`)}
                open={isOpen}
                onOk={addDocumentform.submit}
                okText={t("common.submit")}
                onCancel={() => {
                    addDocumentform.resetFields();
                    setIsOpen(false);
                }}
                width={500}
                destroyOnClose
            >
                <Form onFinish={addDocument} form={addDocumentform}>
                    <Form.Item
                        label={t("legalDocument.documentName")}
                        name={"name"}
                        labelCol={labelCol}
                        rules={[{required: true, message: "Please input document name"}]}
                    >
                        <Input placeholder={t("Document Name").toString()} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default MamPammLegalDocument_1;
