import "./css/index.css"
import React from "react";

export const ExcotradesLoadingScreen = () => {

    return (
        <div id={"excotradesLoadingScreen"}>
            <div className={"loader"}>
                <div className={"temp_loader"}></div>
            </div>
        </div>
    );
}