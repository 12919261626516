import {atom} from "recoil";

export const salesOpportunityStatusData = atom({
    key: "salesOpportunityStatusData",
    default: null,
});

export const salesOpportunityStatusLoading = atom({
    key: "salesOpportunityStatusLoading",
    default: false,
});