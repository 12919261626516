import React, { useState } from "react";
import { Button } from "antd";
import * as FileSaver from "file-saver";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getUserVerificationExportData } from "../../../../../../api/graphql/userVerification";

const ExportDBTableButton = (props) => {
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const { data: userVerificationExport } = getUserVerificationExportData()

    const handleExport = (name) => {
        setLoading(true)
        const currentDate = moment().format("YYYYMMDDHHmm");
        let data:any = userVerificationExport?.getUserVerificationExportData

        var blob = new Blob([JSON.stringify(data)], {type: "application/json"});
        if(blob){
            FileSaver.saveAs(blob, `${name}_${currentDate}`);
            setLoading(false)
        }
    }

    return (
        <Button
            onClick={() => handleExport("userVerification")}
            loading={loading}
            disabled={!userVerificationExport?.getUserVerificationExportData || loading}
        >
            {t("common.exportUserVerificationExport")}
        </Button>
    );
}

export default ExportDBTableButton