import React, {useEffect, useState} from "react";
import type {MenuProps} from 'antd';
import {Button, Dropdown, Popconfirm, Row, Space} from "antd";
import {useTranslation} from "react-i18next";
import {
    commissionDeleteBatch,
    commissionForceRefreshBatch,
    getCommissionLogBatch,
    reverseCommissionPayment,
    submitCommissionPayment
} from "../../../../../../../api/graphql/commission";
import useAuthorize from "../../../../../../../_common/authorize";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import TableAntDesign from "../../../../../../../components/table/tableAntDesign";
import {DownOutlined} from "@ant-design/icons";
import {columnsCommissionLogBatch} from "../../../function/commCalculationColumns";
import CommLogPaymentTable from "./commLogPaymentTable";
import CommLogBatchRecalculateModal from "./commLogBatchRecalculateModal";
import CommLogBatchCreateModal from "./commLogBatchCreateModal";
import EditTableUserPreferenceButton
    from "../../../../../../../components/table/function/editTableUserPreferenceButton";

const CommLogBatchTable = (props) => {

    const {data, currentPage, setHeaderFunction} = props;

    const [createModal, setCreateModal] = useState(false)
    const [batchData, setBatchData] = useState([])
    const [batchId, setBatchId] = useState("")
    const [batchRecord, setBatchRecord] = useState({})
    const [recalculateModal, setRecalculateModal] = useState(false)
    const [recalculateLocalModal, setRecalculateLocalModal] = useState(false)
    const [batchDataTotal, setBatchDataTotal] = useState(0)
    const [orderBy, setOrderBy] = useState("");
    const [filter, setFilter] = useState({});
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [dateKey, setDateKey] = useState("");
    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(0);
    const [tableLoading, setTableLoading] = useState(false);

    const {t} = useTranslation()
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {systemErrorNotification} = useAuthorize()
    const tableName = "Commission Batch Calculation"

    const columns = columnsCommissionLogBatch

    const pageKey = 3

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize)
        setOffset(limit * (page - 1))
    }

    const preload = async () => {
        await setTableLoading(true)
        try {

            if (data?._id) {
                const response = await getCommissionLogBatch({
                    rule: data?._id,
                    ...filter
                }, limit, offset, orderBy, startDate, endDate, dateKey)

                const AssignedUser = (id, userType, username) => {
                    if (userType === "client") {
                        return (
                            <Button
                                style={{fontSize: "12px", padding: "unset"}}
                                onClick={() => window.open("/users/client/detail?id=" + id, "_blank")}
                                type={"link"}>{username}</Button>
                        )
                    } else {
                        return (
                            <Button
                                style={{fontSize: "12px", padding: "unset"}}
                                onClick={() => window.open("/users/crm-user/detail?id=" + id, "_blank")}
                                type={"link"}>{username}</Button>
                        )
                    }
                }

                const result = response?.getCommissionLogBatch?.data?.map((item) => {
                    return {
                        ...item,
                        ruleName: item?.rule?.name,
                        requestedPaymentBy: AssignedUser(item?.requestedPaymentBy?._id, item?.requestedPaymentBy?.userType, item?.requestedPaymentBy?.username),
                        approvedPaymentBy: AssignedUser(item?.approvedPaymentBy?._id, item?.approvedPaymentBy?.userType, item?.approvedPaymentBy?.username),
                        rejectedPaymentBy: AssignedUser(item?.rejectedPaymentBy?._id, item?.rejectedPaymentBy?.userType, item?.rejectedPaymentBy?.username),
                        reversePaymentBy: AssignedUser(item?.reversePaymentBy?._id, item?.reversePaymentBy?.userType, item?.reversePaymentBy?.username),
                        totalPayout: item?.totalPayout && item?.totalPayout > 0 ? item?.totalPayout : 0
                    }
                })

                setBatchDataTotal(response?.getCommissionLogBatch?.total)

                setBatchData(result)
            }

        } catch (e) {
            systemErrorNotification()
        } finally {
            setTableLoading(false)
        }

    }

    const submitPayout = async (id) => {
        try {

            const response = await submitCommissionPayment(id);

            if (response?.submitCommissionPayment?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.submitCommissionPayment?.errKey),
                    key: "submitCommissionPayment",
                });
            } else if (response?.submitCommissionPayment?.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.submitCommissionPayment?.msg),
                    key: "submitCommissionPayment",
                });
            } else {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Submitted Successfully"),
                    key: "submitCommissionPayment",
                });
            }

            await preload()

        } catch (e) {
            systemErrorNotification()
        }
    }

    const deletePayout = async (id) => {

        try {

            const response = await commissionDeleteBatch(id)

            if (response?.commissionDeleteBatch?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.commissionDeleteBatch?.errKey),
                    key: "commissionDeleteBatch",
                });
            } else if (response?.commissionDeleteBatch?.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.commissionDeleteBatch?.msg),
                    key: "commissionDeleteBatch",
                });
            } else {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Delete Batch Successfully"),
                    key: "commissionDeleteBatch",
                });
            }

            await preload()
        } catch (e) {
            systemErrorNotification()
        }

    }

    const reversePayout = async (id) => {

        try {
            const response = await reverseCommissionPayment(id)

            if (response?.reverseCommissionPayment?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.reverseCommissionPayment?.errKey),
                    key: "reverseCommissionPayment",
                });
            } else if (response?.reverseCommissionPayment?.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.reverseCommissionPayment?.msg),
                    key: "reverseCommissionPayment",
                });
            } else {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("Reverse Batch Successfully"),
                    key: "reverseCommissionPayment",
                });
            }
            await preload()
        } catch (e) {
            systemErrorNotification()
        }

    }

    const forceRefreshComm = async (record) => {
        const response = await commissionForceRefreshBatch(record?.rule?._id, record?._id);

        if (response?.commissionForceRefreshBatch?.__typename === "BaseError") {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: t(response?.commissionForceRefreshBatch?.errKey),
                key: "commissionForceRefreshBatch",
            });
        } else if (response?.commissionForceRefreshBatch?.__typename === "CrmTaskApproval") {
            setNotificationMessage({
                status: "info",
                title: "",
                msg: t(response?.commissionForceRefreshBatch?.msg),
                key: "commissionForceRefreshBatch",
            });
        } else {
            setNotificationMessage({
                status: "success",
                title: "",
                msg: t("Force Refresh Batch Successfully"),
                key: "commissionForceRefreshBatch",
            });
        }
    }

    useEffect(() => {
        preload()
    }, [data, filter, startDate, endDate, dateKey, limit, offset, orderBy])

    const itemsRecord = (record) => {

        const items: MenuProps['items'] = [
            {
                key: '1',
                disabled: (record?.isProcessing === false),
                label: (
                    <Button type="link" style={{padding: "2px 4px"}}
                            onClick={() => forceRefreshComm(record)}>
                        Force Refresh
                    </Button>
                ),
            },
            {
                key: '2',
                disabled: (record?.totalPayout === 0),
                label: (
                    <Button type="link" style={{padding: "2px 4px"}}
                            onClick={() => {
                                setBatchId(record?._id)
                                setHeaderFunction(pageKey, "Batch " + record?.transactionName, true)
                            }}>
                        View details
                    </Button>
                ),
            },
            {
                key: '3',
                disabled: ((record?.status !== "pending payout" && record?.status !== "payout rejected") || record?.totalPayout === 0 || record?.isProcessing === true),
                label: (
                    <Popconfirm
                        title="Proceed to payment"
                        description="Are you sure to proceed to task submission?"
                        onConfirm={() => submitPayout(record?._id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="link" style={{padding: "2px 4px"}}>
                            Proceed to payment
                        </Button>
                    </Popconfirm>
                ),
            },
            {
                key: '4',
                disabled: (record?.status === "completed" || record?.isProcessing === true),
                label: (
                    <Button type="link" style={{padding: "2px 4px"}}
                            onClick={() => {
                                setBatchRecord(record)
                                setRecalculateModal(true)
                            }}>
                        Recalculate
                    </Button>
                ),
            },
            {
                key: '4',
                disabled: (record?.status === "completed" || record?.isProcessing === true),
                label: (
                    <Button type="link" style={{padding: "2px 4px"}}
                            onClick={() => {
                                setBatchRecord(record)
                                setRecalculateLocalModal(true)
                            }}>
                        Recalculate based on new setting
                    </Button>
                ),
            },
            {
                key: '5',
                disabled: (record?.status !== "completed" || record?.isProcessing === true),
                label: (
                    <Button type="link" style={{padding: "2px 4px"}}
                            onClick={() => reversePayout(record?._id)}>
                        Reverse payout
                    </Button>
                ),
            },
            {
                key: '6',
                disabled: false,
                // disabled: (record?.status === "completed" || record?.isProcessing === true),
                label: (
                    <Button type="link" style={{padding: "2px 4px"}}
                            onClick={() => deletePayout(record?._id)} danger>
                        Delete payout
                    </Button>
                ),
            },
        ];

        return items?.filter((item: any) => item.disabled === false)
    }

    return (
        <>

            {
                currentPage >= pageKey ? (
                    <>
                        <CommLogPaymentTable id={batchId} setHeaderFunction={setHeaderFunction}
                                             currentPage={currentPage}/>
                    </>
                ) : (
                    <>
                        <Row className={"margin-bottom-0-75 display-flex"} wrap>
                            <Space className={"margin-left-auto margin-bottom-0-3"} wrap>
                                {/*<Button onClick={preload}>*/}
                                {/*    Refresh*/}
                                {/*</Button>*/}
                                <Button onClick={() => setCreateModal(true)}>
                                    Add Batch
                                </Button>
                                <EditTableUserPreferenceButton tableName={tableName} tableColumn={columns}/>
                            </Space>
                        </Row>
                        {
                            data?._id && (
                                <TableAntDesign data={batchData}
                                                tableName={tableName}
                                                columns={[...columns, {
                                                    title: t("common.action"),
                                                    key: "action",
                                                    width: 100,
                                                    align: "left",
                                                    render: (_, record: any) => {

                                                        const items = itemsRecord(record)

                                                        return (
                                                            <Dropdown menu={{items}}>
                                                                <a onClick={(e) => e.preventDefault()}>
                                                                    <Space>
                                                                        Actions
                                                                        <DownOutlined/>
                                                                    </Space>
                                                                </a>
                                                            </Dropdown>
                                                        )
                                                    },
                                                }]}
                                                tableSpin={false}
                                                size={"small"}
                                                server={null}
                                                startDate={setStartDate}
                                                endDate={setEndDate}
                                                dateKey={setDateKey}
                                                filter={setFilter}
                                                order={setOrderBy}
                                                loading={tableLoading}
                                                pagination={{
                                                    pageSize: limit,
                                                    showSizeChanger: true,
                                                    position: "bottomCenter",
                                                    total: batchDataTotal,
                                                    onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                                                    showTotal: (total) =>
                                                        <p>{t('pagination.totalItems', {totalCount: total})}</p>,
                                                }}
                                />
                            )
                        }

                    </>
                )
            }


            <CommLogBatchRecalculateModal isOpen={recalculateModal} closeModal={() => setRecalculateModal(false)}
                                          data={data} batchRecord={batchRecord} preload={preload}
                                          isLocalCalculation={false}/>

            <CommLogBatchRecalculateModal isOpen={recalculateLocalModal}
                                          closeModal={() => setRecalculateLocalModal(false)}
                                          data={data} batchRecord={batchRecord} preload={preload}
                                          isLocalCalculation={true}/>

            <CommLogBatchCreateModal isOpen={createModal} closeModal={() => setCreateModal(false)}
                                     data={data} preload={preload}/>
        </>

    )

}

export default CommLogBatchTable