import React from "react";
import {ColumnsType} from "antd/es/table";

export const columns: ColumnsType<any> = [
    // {
    //     title: "Name",
    //     dataIndex: "name",
    //     key: "name",
    //     defaultSortOrder: "ascend",
    //     sorter: (a, b) => a?.name?.localeCompare(b?.name),
    //     render: (text) => <p>{text}</p>,
    // },
    {
        title: "Name",
        dataIndex: "displayName",
        key: "displayName",
        defaultSortOrder: "ascend",
        sorter: (a, b) => a?.name?.localeCompare(b?.name),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Subject",
        dataIndex: "subject",
        key: "subject",
        sorter: (a, b) => a?.subject?.localeCompare(b?.subject),
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Send By",
        dataIndex: "isFixedEmail",
        key: "isFixedEmail",
        sorter: (a, b) => a?.isFixedEmail - b?.isFixedEmail,
        render: (isFixedEmail) => <p>{isFixedEmail ? "Fixed email" : "User domain"}</p>,
    },
    {
        title: "Sender Email",
        dataIndex: "from",
        key: "from",
        sorter: (a, b) => a?.from?.name?.localeCompare(b?.from?.name),
        render: (_, record) => <p>{record?.from ? record?.from?.name + " - " + record?.from?.email : "-"}</p>,
    },
    {
        title: "Email Type",
        dataIndex: "emailType",
        key: "emailType",
        sorter: (a, b) => a?.emailType?.name?.localeCompare(b?.emailType?.name),
        render: (_, record) => <p>{record?.emailType?.name || "-"}</p>,
    },
    {
        title: "Status",
        dataIndex: "isActive",
        key: "isActive",
        sorter: (a, b) => a?.isActive - b?.isActive,
        render: (isActive) => <p>{isActive ? "Active" : "Inactive"}</p>,
    },
    // {
    //   title: "Implemented",
    //   dataIndex: "isNotImplemented",
    //   key: "isNotImplemented",
    //   width: 40,
    //   sorter: (a, b) => a?.isNotImplemented - b?.isNotImplemented,
    //   render: (isNotImplemented) => <p>{isNotImplemented ? "No" : "Yes"}</p>,
    // },
];
