import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";


const GET_SPREAD_GROUP_MT5 = gql`
    query getSpreadGroupMt5(
        $filter: SpreadGroupMt5Filter
        $orderBy: String
        $limit: Int
        $offset: Int
        $startDate: String
        $endDate: String
        $dateKey: String
    ) {
        getSpreadGroupMt5(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
            startDate: $startDate
            endDate: $endDate
            dateKey: $dateKey
        )  {
            ... on SpreadGroupMt5Array {
                data{
                    _id
                    group
                    server
                    permissionsFlags
                    authMode
                    authPasswordMin
                    company
                    companyPage
                    companyEmail
                    companySupportPage
                    companySupportEmail
                    companyCatalog
                    companyDepositPage
                    companyWithdrawalPage
                    currency
                    currencyDigits
                    reportsMode
                    reportsFlags
                    reportsEmail
                    reportsSmtp
                    reportsSmtpLogin
                    reportsSmtpPass
                    newsMode
                    newsCategory
                    newsLangTotal
                    newsLangData
                    mailMode
                    tradeFlags
                    tradeInterestrate
                    tradeVirtualCredit
                    marginFreeMode
                    marginSoMode
                    marginCall
                    marginStopOut
                    marginFreeProfitMode
                    marginMode
                    marginFlags
                    demoLeverage
                    demoDeposit
                    demoInactivityPeriod
                    limitHistory
                    limitOrders
                    limitSymbols
                    limitPositions
                    commissionTotal
                    commissionData
                    symbolData
                    symbolTotal
                    authOtpMode
                    tradeTransferMode
                    isArchived
                }
                total
            }
            ... on BaseError {
                errKey
                errObj
            }
        }
    }
`;

const getSpreadGroupMt5 = async (filter, orderBy?, limit?, offset?, startDate?, endDate?, dateKey?) => {
    try {
        const execute = await ApolloClientQuery(GET_SPREAD_GROUP_MT5, {
                filter: filter,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
                orderBy: orderBy || null,
                limit: limit || null,
                offset: offset || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_SYMBOL_SETTING_COMPARISON = gql`
    query getSymbolSettingComparison(
        $id: String
    ) {
        getSymbolSettingComparison(
            id: $id
        )  {
            ... on SymbolSettingComparison {
                total
                data
            }
            ... on BaseError {
                errKey
                errObj
            }
        }
    }
`;

const getSymbolSettingComparison = async (id) => {
    try {
        const execute = await ApolloClientQuery(GET_SYMBOL_SETTING_COMPARISON, {
                id: id,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const REFRESH_SPREAD_GROUP_MT5 = gql`
    query refreshSpreadGroupMt5($serverId: String){
        refreshSpreadGroupMt5(serverId:$serverId) {
            ... on SpreadGroupMt5Array {
                data{
                    _id
                }
                total
            }
            ... on BaseError {
                errKey
                errObj
            }
        }
    }
`;

const refreshSpreadGroupMt5 = async (serverId) => {
    try {
        const execute = await ApolloClientQuery(
            REFRESH_SPREAD_GROUP_MT5,
            {
                serverId: serverId,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};


export {
    getSymbolSettingComparison,
    getSpreadGroupMt5,
    refreshSpreadGroupMt5,
}