import React, {useEffect, useState} from "react";
import {Col, Input, Modal, Row} from "antd";
import {useRecoilValue} from "recoil";
import {languageOptionsState} from "../../../../../users/_common/state";
import {useTranslation} from "react-i18next";
import _ from "lodash"

const DisplayNameModal = ({open, handleClose, item, form}) => {
    const {t} = useTranslation()
    const languageOptions = useRecoilValue<any>(languageOptionsState)
    const [input, setInput] = useState<any>(null)

    useEffect(() => {
        if (!open) {
            setInput(null)
            return
        }

        const defaultValue = form.getFieldsValue()?.levels?.[item - 1]?.displayName
        if (defaultValue) {
            let input = {}
            defaultValue.map(d => {
                input[d.language] = d.content
            })

            setInput(input)
        }
    }, [item, open]);

    const onConfirm = () => {
        const displayName = Object.entries(input).map(([language, content]) => ({
            language,
            content
        }))

        form.setFieldsValue({
            levels: form.getFieldValue("levels").map((level, index) =>
                index === item - 1 ? {...level, displayName} : level
            )
        })

        handleClose()
    }

    const onKeyUpHandler = (e, lang) => {
        const result = {...input} || {}
        result[lang] = e.target.value

        setInput(result)
    }

    const debouncedOnKeyUpHandler = _.debounce(onKeyUpHandler, 500)

    return (
        <Modal
            open={open}
            onCancel={handleClose}
            title={`Level ${item} Display Name`}
            maskClosable={false}
            onOk={onConfirm}
            cancelText={t("common.cancel")}
            okText={t("common.apply")}
            destroyOnClose
        >
            {
                languageOptions?.map(lang => (
                    <Row className={"margin-top-0-5"} gutter={[8, 8]} key={lang?.value}>
                        <Col span={8}>
                            <p className={"margin-bottom-0"}>{lang?.label}</p>
                        </Col>
                        <Col span={16}>
                            <Input defaultValue={input?.[lang?.value]} onChange={(e) => debouncedOnKeyUpHandler(e, lang?.value)}/>
                        </Col>
                    </Row>
                ))
            }</Modal>
    )
}

export default DisplayNameModal