import React, {useState} from "react";
import {Tabs} from "antd";
import {useTranslation} from "react-i18next";
import VariableModal from "./component/variableModal";
import VariableToolbar from "./component/variableToolbar";
import VariableTable from "./component/variableTable";

const Variable_1 = (props) => {
    const {dynamicVariablePermission, languageData} = props;
    const tableName = "Dynamic Variable";
    const {t} = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formMode, setFormMode] = useState("");
    const [selectedItem, setSelectedItem] = useState({});
    const [activeTab, setActiveTab] = useState("custom");

    const onTabChange = (value) => {
        setActiveTab(value);
    };

    const toggleModal = (mode = "", record = {}) => {
        setSelectedItem(record);
        setIsModalOpen((prevState) => !prevState);
        setFormMode(mode);
    };

    const tabList = ["custom", "system"];
    const tabItems = tabList?.map((tab, index) => {
        let props: any = {
            type: tab,
            tableName: `${tableName} ${tab}`,
            dynamicVariablePermission,
            toggleModal,
        };
        return {
            key: tab,
            label: t(`common.${tab}`),
            children: <VariableTable {...props} />,
        };
    });

    return (
        <div id="dynamicVariable">
            <VariableModal mode={formMode} item={selectedItem} isOpen={isModalOpen} closeModal={toggleModal} languageData={languageData} />

            <div className="display-flex">
                <h1>{t("Dynamic Variable")}</h1>
            </div>

            <Tabs
                activeKey={activeTab}
                items={tabItems}
                onChange={onTabChange}
                tabBarExtraContent={
                    <VariableToolbar
                        type={activeTab}
                        openAddModal={() => toggleModal("add")}
                        dynamicVariablePermission={dynamicVariablePermission}
                        // loading={loading}
                    />
                }
            />
        </div>
    );
};

export default Variable_1;
