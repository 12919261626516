import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Popconfirm, Space, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import TableAntDesign from "../../../../../../components/table/tableAntDesign";
import {columns} from "../../../function/tableColumn";
import {getDynamicVariable, getDynamicVariableCount, useDeleteDynamicVariableMutation} from "../../../../../../api/graphql/dynamicVariable";
import useAuthorize from "../../../../../../_common/authorize";

const VariableTable = (props) => {
    const {type, tableName, dynamicVariablePermission, toggleModal} = props;
    const {t} = useTranslation();

    const [tableData, setTableData] = useState([]);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [filter, setFilter] = useState({});
    const [orderBy, setOrderBy] = useState("");

    const {variableData, loading, error, refreshQuery}: any = getDynamicVariable({filter: {type, ...filter}}, limit, offset, orderBy);
    const {data: totalCount, refreshQuery: refreshCountQuery}: any = getDynamicVariableCount({filter: {type, ...filter}});
    const {deleteDynamicVariable} = useDeleteDynamicVariableMutation();
    const {systemErrorNotification, baseErrorChecking} = useAuthorize();

    const onChangePageHandler = (page, pageSize) => {
        setLimit(pageSize);
        setOffset(limit * (page - 1));
    };

    useEffect(() => {
        if (offset === totalCount?.getDynamicVariableCount) {
            setOffset(0)
        }
    },[totalCount?.getDynamicVariableCount])

    const handleDelete = async (id) => {
        try {
            const res: any = await deleteDynamicVariable(id);
            if (res?.data?.deleteDynamicVariable?.__typename === "DynamicVariable") {
                // setOffset(0);
                refreshQuery();
                refreshCountQuery();
            } else {
                return await baseErrorChecking(res?.data?.deletePushNotificationTemplate);
            }
        } catch (e) {
            console.log(e);
            systemErrorNotification();
        }
    };

    useEffect(() => {
        setTableData(variableData?.getDynamicVariable?.data);
    }, [variableData]);

    return (
        <div className={"margin-top-0-75"}>
            <TableAntDesign
                data={tableData}
                tableName={tableName}
                rowKey="id"
                size="small"
                loading={loading}
                filter={setFilter}
                order={setOrderBy}
                pagination={{
                    current: (offset / limit + 1),
                    pageSize: limit,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => onChangePageHandler(page, pageSize),
                    total: totalCount?.getDynamicVariableCount,
                    showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
                }}
                columns={[
                    ...columns,
                    {
                        title: t("common.action"),
                        key: "action",
                        width: 99,
                        render: (_, record: any) => (
                            <Space size="middle">
                                <Tooltip title={t("View")}>
                                    <a onClick={() => toggleModal("view", record)}>
                                        <EyeOutlined/>
                                    </a>
                                </Tooltip>
                                {record?.type !== "system" && (
                                    <>
                                        {dynamicVariablePermission?.edit?.edit && (
                                            <Tooltip title={t("Edit")}>
                                                <a onClick={() => toggleModal("edit", record)}>
                                                    <EditOutlined/>
                                                </a>
                                            </Tooltip>
                                        )}
                                        {dynamicVariablePermission?.delete?.delete && (
                                            <Tooltip title={t("Delete")}>
                                                <Popconfirm
                                                    placement="left"
                                                    title={"Are you sure to delete this variable?"}
                                                    description={"Delete the Variable"}
                                                    onConfirm={() => {
                                                        handleDelete(record?.id);
                                                    }}
                                                    okText={t("common.yes")}
                                                    cancelText={t("common.no")}
                                                >
                                                    <a>
                                                        <DeleteOutlined/>
                                                    </a>
                                                </Popconfirm>
                                            </Tooltip>
                                        )}
                                    </>
                                )}
                            </Space>
                        ),
                    },
                ]}
            />
        </div>
    );
};

export default VariableTable;
