import React, {useEffect, useState} from "react";
import {Button, Card, Col, ColorPicker, Form, Input, Row, Space} from "antd";
import {useTranslation} from "react-i18next";
import {DeleteOutlined} from "@ant-design/icons";
import _ from "lodash";
import {createColorSetting, deleteColorSetting, updateColorSetting} from "../../../../../../api/restful/arena/color";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../recoil_state";

const ArenaColorSetting_1 = ({data, fetchData}) => {
    const {t} = useTranslation()
    const [editable, setEditable] = useState(false);
    const [form] = Form.useForm()
    const [initialValue, setInitialValue] = useState<any>({})
    const setNotificationMessage = useSetRecoilState(notificationMessage)

    const formatGradient = (color) => {
        if (Array.isArray(color)) {
            return `linear-gradient(${color.map(stop => `${stop.color} ${stop.percent}%`).join(', ')})`;
        } else if (color && typeof color !== "string") {
            return color.isGradient() ? color.toCssString() : color.toHexString();
        }
        return color;
    };

    const onFinishHandler = async ({ colorSchema }) => {
        const updateAry:any[] = [], createAry:any[] = []

        colorSchema.forEach(item => {
            const formattedItem = { ...item, light: formatGradient(item.light), dark: formatGradient(item.dark) }

            if (formattedItem._id) {
                const found = data?.find(d => d?._id === formattedItem._id)
                if (found && (found.name !== formattedItem.name || found.light !== formattedItem.light || found.dark !== formattedItem.dark)) {
                    updateAry.push(formattedItem)
                }
            } else {
                createAry.push(formattedItem)
            }
        })

        const deleteAry:any[] = _.differenceBy(data, colorSchema, '_id')

        const promises = [
            ...deleteAry.length > 0 ? [deleteColorSetting(deleteAry.map(d => d._id))] : [],
            ...updateAry.map(d => updateColorSetting(d)),
            ...(createAry.length > 0 ? [createColorSetting(createAry)] : [])
        ]

        const handleNotification = (status, msg) => setNotificationMessage({ status, title: "", msg, key: "arenaColorSettingNotification" })

        const results:any[] = await Promise.allSettled(promises)

        // console.log(results)
        const successAry = results.filter(r => r.status === "fulfilled")
        const failedAry = results.filter(r => r.status === "rejected").map(r => {
                const validArray = JSON.parse(`[${r.reason?.response?.data.replace(/}{/g, "},{")}]`);
                return r.reason?.response?.data?.errObj || validArray?.[0] || "Unknown Error"
            })

        if (successAry.length > 0 && failedAry.length === 0) {
            handleNotification("success", "Update Color Setting Successfully");

            fetchData()
            setEditable(false);
        } else if (successAry.length > 0) {
            handleNotification("warning", `Update Color Setting Success with Errors: ${failedAry.join(", ")}`)

            fetchData()
            setEditable(false);
        } else if (failedAry.length > 0) {
            handleNotification("error", `Update Color Setting Failed: ${failedAry.join(", ")}`)
        }


    };

    const parseGradient = (color) => {
        const regex = /rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)\s+(\d{1,3})%/g;
        const colorStops: any[] = [];
        let match;
        while ((match = regex.exec(color)) !== null) {
            const [_, r, g, b, percent] = match;
            colorStops.push({
                color: `rgb(${r},${g},${b})`,
                percent: parseInt(percent),
            });
        }

        return colorStops;
    }

    useEffect(() => {
        const formattedData = data?.map(item => {
            const newItem = {...item};

            ['light', 'dark'].forEach(key => {
                if (!newItem[key].startsWith('#')) {
                    newItem[key] = parseGradient(newItem[key])
                }
            })

            return newItem
        })

        setInitialValue({colorSchema: formattedData})
    }, [data])

    useEffect(() => {
        form.resetFields()
    }, [initialValue]);

    return (
        <div>
            <Row justify={"space-between"} className={"margin-bottom-0-75"}>
                <h1 className={"margin-bottom-0"}>Arena Color Setting</h1>
                {
                    editable ?
                        <Space>
                            <Button onClick={() => {
                                form.resetFields()
                                setEditable(false)
                            }}>{t("common.cancel")}</Button>
                            <Button type={"primary"} onClick={() => form.submit()}>{t("common.update")}</Button>
                        </Space>
                        :
                        <Button onClick={() => setEditable(true)}>{t("common.edit")}</Button>
                }
            </Row>

            <Card>
                <Form form={form} onFinish={onFinishHandler} className={"margin-top-0-75"} initialValues={initialValue}>
                    <Form.List name={"colorSchema"}>
                        {(fields, {add, remove}) => {
                            // Split fields into two halves
                            const middleIndex = fields?.length <= 4 ? 4 : (Math.ceil(fields.length / 2) - 1);
                            const leftFields = fields.slice(0, middleIndex);
                            const rightFields = fields.slice(middleIndex);

                            return (
                                <Row gutter={[16, 8]}>
                                    <Col span={12}>
                                        <Row className={"margin-bottom-0-75"}>
                                            <Col span={12}><p><strong>Variable Name</strong></p></Col>
                                            <Col span={4} className={"text-align-center"}><p><strong>Light</strong></p></Col>
                                            <Col span={4} className={"text-align-center"}><p><strong>Dark</strong></p></Col>
                                            {
                                                editable &&
                                                <Col span={21}>
                                                    <Button type={"dashed"} block onClick={() => add({}, 0)}>+ Add</Button>
                                                </Col>
                                            }
                                        </Row>

                                        {leftFields.map(field => {
                                            const itemData = data?.find(item => item.name === form.getFieldValue(['colorSchema', field.name, 'name']));
                                            const isDefault = itemData?.isDefault || false;

                                            return (
                                                <Row gutter={[16, 8]} key={field.key}>
                                                    <Col span={12}>
                                                        {
                                                            editable ?
                                                                <Form.Item
                                                                    {...field}
                                                                    name={[field.name, 'name']}
                                                                    rules={[
                                                                        {
                                                                            pattern: /^[a-z0-9-]+$/,
                                                                            message: "Only lowercase letters(a-z), numbers(0-9), and hyphens(-) allowed",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input disabled={isDefault} placeholder={"Example: ranking-background-color"}/>
                                                                </Form.Item> :
                                                                <p>{form.getFieldValue(['colorSchema', field.name, 'name'])}</p>
                                                        }
                                                    </Col>
                                                    <Col span={4} className={"text-align-center"}>
                                                        <Form.Item {...field} name={[field.name, 'light']}>
                                                            <ColorPicker disabled={!editable} mode={['single', 'gradient']} allowClear/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4} className={"text-align-center"}>
                                                        <Form.Item {...field} name={[field.name, 'dark']}>
                                                            <ColorPicker disabled={!editable} mode={['single', 'gradient']} allowClear/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4}>
                                                        {
                                                            editable &&
                                                            <Button disabled={isDefault} type={"link"} danger icon={<DeleteOutlined/>}
                                                                    onClick={() => remove(field.name)}/>
                                                        }
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                    </Col>

                                    {/* Right Column */}
                                    <Col span={12}>
                                        {rightFields.map(field => {
                                            const itemData = data?.find(item => item.name === form.getFieldValue(['colorSchema', field.name, 'name']));
                                            const isDefault = itemData?.isDefault || false;

                                            return (
                                                <Row gutter={[16, 8]} key={field.key}>
                                                    <Col span={12}>
                                                        {
                                                            editable ?
                                                                <Form.Item
                                                                    {...field}
                                                                    name={[field.name, 'name']}
                                                                    rules={[
                                                                        {
                                                                            pattern: /^[a-z0-9-]+$/,
                                                                            message: "Only lowercase letters(a-z), numbers(0-9), and hyphens(-) allowed",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input disabled={isDefault} placeholder={"Example: ranking-background-color"}/>
                                                                </Form.Item> :
                                                                <p>{form.getFieldValue(['colorSchema', field.name, 'name'])}</p>
                                                        }
                                                    </Col>
                                                    <Col span={4} className={"text-align-center"}>
                                                        <Form.Item {...field} name={[field.name, 'light']}>
                                                            <ColorPicker disabled={!editable} mode={['single', 'gradient']} allowClear/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4} className={"text-align-center"}>
                                                        <Form.Item {...field} name={[field.name, 'dark']}>
                                                            <ColorPicker disabled={!editable} mode={['single', 'gradient']} allowClear/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4}>
                                                        {
                                                            editable &&
                                                            <Button disabled={isDefault} type={"link"} danger icon={<DeleteOutlined/>}
                                                                    onClick={() => remove(field.name)}/>
                                                        }
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                    </Col>
                                </Row>
                            );
                        }}
                    </Form.List>
                </Form>

            </Card>
        </div>
    )
}

export default ArenaColorSetting_1