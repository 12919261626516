import React from "react";

const SphereHighlight = ({ className }) => {
    return (
        <svg
            width="62"
            height="62"
            viewBox="0 0 62 62"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <circle cx="31" cy="31" r="31" fill="url(#paint0_linear_1507_40828)" />
            <defs>
                <linearGradient
                    id="paint0_linear_1507_40828"
                    x1="47.3308"
                    y1="6.33767"
                    x2="31"
                    y2="62"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#555555" />
                    <stop offset="1" />
                </linearGradient>
            </defs>
        </svg>
    );
}

const Sphere = ({ className }) => {
    return (
        <svg
            width="62"
            height="62"
            viewBox="0 0 62 62"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <circle cx="31" cy="31" r="31" fill="url(#paint0_linear_1583_40920)" />
            <defs>
                <linearGradient
                    id="paint0_linear_1583_40920"
                    x1="47.3308"
                    y1="6.33767"
                    x2="31"
                    y2="62"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#555555" />
                    <stop offset="1" />
                </linearGradient>
            </defs>
        </svg>
    );
}


export const CommonSphereGroup = (props) => {
    const {highlight} = props
    return (
        <>
            {highlight ? (
                <SphereHighlight className={"sphere sphere-1 "} />
            ) : (
                <Sphere className={"sphere sphere-1 "} />
            )}
            {highlight ? (
                <SphereHighlight className={"sphere sphere-2 "} />
            ) : (
                <Sphere className={"sphere sphere-2 "} />
            )}
            {highlight ? (
                <SphereHighlight className={"sphere sphere-3 "} />
            ) : (
                <Sphere className={"sphere sphere-3 "} />
            )}
        </>
    );
}