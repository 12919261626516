import React, { useState } from "react";
import { Button } from "antd";
import { FileAddOutlined } from "@ant-design/icons";

const AddButton = (props) => {
  const { text, addHandler, size } = props;
  const [loadings, setLoadings] = useState<boolean[]>([]);
  const enterLoading = (index: number) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });

    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 2000);
  };

  return (
    <Button size={size} type={"primary"} onClick={addHandler}>
      {text}
    </Button>
  );
};

export default AddButton;
