import { gql, useMutation, useQuery } from '@apollo/client'
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {useTranslation} from "react-i18next";
import {GET_EWALLET_RECORD, GET_EWALLET_STATEMENT_WITH_TASK} from "./eWallet";
import {GET_USER_BY_ID} from "./user";

const GET_EWALLET_TRANSFER_COUNT = gql`
  query getEWalletTransferCount($filter: EWalletTransferSearchInput) {
    getEWalletTransferCount(filter: $filter)
  }
`;

const getEWalletTransferCount = ({filter}) => {
  try{
    const { loading, error, data } = useQuery(GET_EWALLET_TRANSFER_COUNT, {
        variables: {
            filter: {
                  transactionNo: filter?.transactionNo || null
            }
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}

const GET_EWALLET_TRANSFER_BY_ID = gql`
  query getEWalletTransferByID($id: String!) {
    getEWalletTransferByID(id: $id){
      ... on EWalletTransfer {
        _id
        id
        transactionNo
        transactionDate
        fromUserId {username}
        toUserId {username}
        amount
        remark
        createdBy {username}
        updatedBy {username}
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }

`;

const getEWalletTransferById = (id) => {
  try{
    const { loading, error, data } = useQuery(GET_EWALLET_TRANSFER_BY_ID, {
        variables: {
          id: id
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}


const GET_EWALLET_TRANSFER = gql`
  query getEWalletTransfer($filter: EWalletTransferSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
    getEWalletTransfer(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy){
      ... on EWalletTransferArray {
        data {
          _id
          id
          transactionNo
          transactionDate
          fromUserId {username}
          toUserId {username}
          amount
          remark
          createdBy {username}
          updatedBy {username}
          createdAt
          updatedAt
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getEWalletTransfer = ({filter}, limit?, offset?, orderBy?) => {
  try{
    const { loading, error, data } = useQuery(GET_EWALLET_TRANSFER, {
        variables: {
          filter: {
              transactionNo: filter?.transactionNo || null,
          },
          limit: limit || null,
          offset: offset || null,
          orderBy: orderBy || null,
        },
        context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("a_t")
            }
        }
    });
  
    return { loading, error, data }
  }
  catch(err: any){
    console.error(err);
    return { data: null, loading: false, error: err };
  }
}

const ADD_EWALLET_TRANSFER = gql`
  mutation addEWalletTransfer($input: EWalletTransferInput) {
    addEWalletTransfer(input: $input){
      ... on EWalletTransfer {
        _id
        id
        transactionNo
        transactionDate
        fromUserId {username}
        toUserId {username}
        amount
        remark
        createdBy {username}
        updatedBy {username}
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }

`

const useAddEWalletTransferMutation = (): { addEWalletTransfer: any, data: any, loading: boolean, error: any } => {
  try{
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const { t } = useTranslation();
    const [addEWalletTransferMutation, { data, loading, error }] = useMutation(ADD_EWALLET_TRANSFER);

    const addEWalletTransfer = (input) => {
      return addEWalletTransferMutation({
        variables: {
          input: input,
        },
        update(cache, {data: {addEWalletTransfer}}) {
          const typename = addEWalletTransfer?.__typename;
          if (typename === "BaseError") {
            setNotificationMessage({
              status: "error",
              title: "",
              msg: t(addEWalletTransfer?.errKey),
              key: "addEWalletTransfer",
            });
          } else if (typename === "CrmTaskApproval") {
            setNotificationMessage({
              status: "info",
              title: "",
              msg: t(addEWalletTransfer?.msg),
              key: "addEWalletTransfer",
            });
          } else {
            setNotificationMessage({
              status: "success",
              title: "",
              msg: t("E-Wallet Transfer Successfully"),
              key: "addEWalletTransfer",
            });
          }
        },
        refetchQueries: [GET_EWALLET_STATEMENT_WITH_TASK,GET_USER_BY_ID]
      });
    };

    return { addEWalletTransfer, data, loading, error } 
  }
  catch(err: any){
    console.error(err);
    return { addEWalletTransfer: null, data: null, loading: false, error: err };
  }
}


const UPDATE_EWALLET_TRANSFER = gql`
mutation updateEWalletTransfer($id: String!, $input: EWalletTransferInput) {
    updateEWalletTransfer(id: $id, input: $input){
      ... on EWalletTransfer {
        _id
        id
        transactionNo
        transactionDate
        fromUserId {username}
        toUserId {username}
        amount
        remark
        createdBy {username}
        updatedBy {username}
        createdAt
        updatedAt
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useUpdateEWalletTransferMutation = (): { updateEWalletTransfer: any, data: any, loading: boolean, error: any } => {
  try{
    const [updateEWalletTransferMutation, { data, loading, error }] = useMutation(UPDATE_EWALLET_TRANSFER);

    const updateEWalletTransfer = (id, input) => {
      return updateEWalletTransferMutation({
        variables: {
          id: id,
          input: {
            transactionNo: input.transactionNo || null,
            transactionDate: input.transactionDate || null,
            fromUserId: input.fromUserId || null,
            toUserId: input.toUserId || null,
            amount: input.amount || null,
            remark: input.remark || null,
          },
        },
      });
    };

    return { updateEWalletTransfer, data, loading, error } 
  }
  catch(err: any){
    console.error(err);
    return { updateEWalletTransfer: null, data: null, loading: false, error: err };
  }
}

const DELETE_EWALLET_TRANSFER = gql`
  mutation deleteEWalletTransfer($id: String!){
    deleteEWalletTransfer(id: $id){
      ... on EWalletTransfer {
        _id
        id
        isDeleted
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`

const useDeleteEWalletTransferMutation = (): { deleteEWalletTransfer: any, data: any, loading: boolean, error: any } => {
  try{
    const [deleteEWalletTransferMutation, { data, loading, error }] = useMutation(DELETE_EWALLET_TRANSFER);

    const deleteEWalletTransfer = (id) => {
      return deleteEWalletTransferMutation({
        variables: {
          id: id,
        },
      });
    };

    return { deleteEWalletTransfer, data, loading, error } 
  }
  catch(err: any){
    console.error(err);
    return { deleteEWalletTransfer: null, data: null, loading: false, error: err };
  }
}



export {
  getEWalletTransferCount, 
  getEWalletTransferById, 
  getEWalletTransfer, 
  useAddEWalletTransferMutation, 
  useUpdateEWalletTransferMutation,
  useDeleteEWalletTransferMutation
}