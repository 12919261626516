import {useState} from "react";
import {getCustomAction} from "../../../../../api/restful/automationSetting/customAction";
import {getCustomActionTemplate} from "../../../../../api/restful/automationSetting/customActionTemplate";

export const useGetCustomAction = () => {
    const [data, setData] = useState<any>(null)

    const fetchCustomAction = async () => {
       try {
           const {data} = await getCustomActionTemplate({})
           setData(data)
       } catch (e) {

       }
    }

    return [data, fetchCustomAction] as const
}