import React from "react";
import {withLazyComponent} from "./lazyComponent";

export const protectedRoute:any[] = [
    // {
    //   path: "/",
    //   lang: "",
    //   element: withLazyComponent(
    //     React.lazy(() => import("../pages/dashboard/dashboard"))
    //   ),
    // },
    // Task
    {
        path: "/task",
        lang: "module.task",
        element: withLazyComponent(React.lazy(() => import("../pages/task"))),
        permission: "task:task:view",
    },
    // Trading Account
    {
        path: "/account/trading-account",
        lang: "module.tradingAccount",
        name: "Trading Account",
        headerCategory: "Accounts",
        headerSubcategoryName: "module.tradingAccount",
        element: withLazyComponent(React.lazy(() => import("../pages/accounts/tradingAccount/_general"))),
        permission: "tradingAccount:account:view:view",
    },
    {
        path: "/account/trading-account/detail",
        name: "Trading Account Detail",
        element: withLazyComponent(React.lazy(() => import("../pages/accounts/tradingAccount/detail"))),
        permission: "tradingAccount:account:view:view",
    },
    {
        path: "/mampamm/money-manager-investor",
        lang: "module.mampamm",
        name: "MAM/PAMM",
        headerCategory: "Accounts",
        headerSubcategoryName: "module.mampamm",
        element: withLazyComponent(React.lazy(() => import("../pages/accounts/mamPamm"))),
        permission: "tradingAccountMt5:moneyManagerAccount:view:view",
    },
    //User
    {
        path: "/users/client",
        lang: "module.clientsUser",
        name: "Clients",
        headerSubcategoryName: "module.clientsUser",
        headerCategory: "Users",
        element: withLazyComponent(React.lazy(() => import("../pages/users/clients/_general"))),
        permission: "user:clientsUser:view:view",
    },
    {
        path: "/users/client/detail",
        name: "Clients Detail",
        element: withLazyComponent(React.lazy(() => import("../pages/users/clients/detail"))),
        permission: "user:clientsUser:view:view",
    },
    {
        path: "/users/crm-user",
        lang: "module.crmUser",
        name: "CRM User",
        headerSubcategoryName: "module.crmUser",
        headerCategory: "Users",
        element: withLazyComponent(React.lazy(() => import("../pages/users/crmUsers/_general"))),
        permission: "user:crmUser:view:view",
    },
    {
        path: "/users/crm-user/detail",
        name: "CRM User Detail",
        element: withLazyComponent(React.lazy(() => import("../pages/users/crmUsers/detail"))),
        permission: "user:crmUser:view:view",
    },
    {
        path: "/users/lead",
        lang: "module.leads",
        name: "Leads",
        headerSubcategoryName: "module.leads",
        headerCategory: "Users",
        element: withLazyComponent(React.lazy(() => import("../pages/users/leads/_general"))),
        permission: "user:leads:view:view",
    },
    {
        path: "/users/lead/detail",
        name: "Leads Detail",
        element: withLazyComponent(React.lazy(() => import("../pages/users/leads/detail"))),
        permission: "user:leads:view:view",
    },
    // {
    //     path: "/users/lead/duplicate-leads",
    //     name: "Leads",
    //     element: withLazyComponent(React.lazy(() => import("../pages/users/leads/checkDuplicateLeads"))),
    //     permission: "user:leads:bind:bind",
    // },
    {
        path: "/users/lead/autobind-leads",
        name: "Leads",
        element: withLazyComponent(React.lazy(() => import("../pages/users/leads/autoBindLeads"))),
        permission: "user:leads:bind:bind",
    },
    {
        path: "/users/lead/pending-list",
        name: "Leads",
        element: withLazyComponent(React.lazy(() => import("../pages/users/leads/pendingList"))),
        permission: "user:leads:add:add",
    },
    {
        path: "/users/sales-opportunity",
        lang: "module.salesOpportunity",
        name: "Sales Opportunity",
        headerSubcategoryName: "module.salesOpportunity",
        headerCategory: "Users",
        element: withLazyComponent(React.lazy(() => import("../pages/users/salesOpportunity"))),
        permission: "user:salesOpportunity:view",
    },
    // Settings route
    // Brand Settings
    {
        path: "/settings/server-settings",
        lang: "module.brandSetting",
        name: "Brand Settings",
        headerSubcategoryName: "module.brandSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/serverSettings/"))),
        permission: "brandSetting:serverSetting:view",
    },
    {
        path: "/settings/push-notification-server-settings",
        lang: "module.brandSetting",
        name: "Brand Settings",
        headerSubcategoryName: "module.brandSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/pushNotificationServerSettings/"))),
        permission: "brandSetting:pushNotificationServerSetting:view",
    },
    {
        path: "/settings/icon-list",
        lang: "module.iconList",
        name: "Icon List",
        headerSubcategoryName: "module.brandSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/iconList/"))),
        permission: "brandSetting:iconList:view",
    },
    {
        path: "/settings/currency-settings",
        lang: "module.currencySetting",
        name: "Currency Setting",
        headerSubcategoryName: "module.brandSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/currencySetting/"))),
        permission: "brandSetting:currencySetting:view",
    },
    {
        path: "/settings/country",
        lang: "module.country",
        name: "Country",
        headerSubcategoryName: "module.brandSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/country/"))),
        permission: "brandSetting:brandDetails:view",
    },
    {
        path: "/settings/ewallet-settings",
        lang: "module.eWalletSetting",
        name: "EWallet Setting",
        // headerSubcategoryName: "module.brandSetting",
        // headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/eWalletSetting/"))),
        // permission: "brandSetting:eWalletSetting:view",
    },
    {
        path: "/settings/brand-details",
        lang: "module.brandDetails",
        headerSubcategoryName: "module.brandSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/brandDetails/"))),
        permission: "brandSetting:brandDetails:view",
    },

    {
        path: "/settings/company-email",
        lang: "module.companyEmail",
        name: "Brand Settings",
        headerSubcategoryName: "module.brandSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/companyEmail/"))),
        permission: "brandSetting:companyEmail:view",
    },
    {
        path: "/settings/platform-download",
        lang: "module.platformDownload",
        name: "Brand Settings",
        headerSubcategoryName: "module.brandSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/platformDownload/"))),
        permission: "brandSetting:platformDownload:view",
    },
    {
        path: "/settings/modules",
        lang: "module.modules",
        name: "Brand Settings",
        headerSubcategoryName: "module.brandSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/modules"))),
        permission: "brandSetting:moduleSettings:view",
    },
    {
        path: "/settings/language",
        lang: "module.language",
        name: "Brand Settings",
        headerSubcategoryName: "module.brandSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/language/"))),
        permission: "brandSetting:language:view",
    },

    // User Settings
    {
        path: "/settings/user-network-tree",
        lang: "module.userNetworkTree",
        name: "User Network Tree",
        headerSubcategoryName: "module.userSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/userNetworkTree"))),
        permission: "userSetting:userNetworkTree:view",
    },
    {
        path: "/settings/sales-rep-network-tree",
        lang: "module.userSetting",
        name: "User Settings",
        headerSubcategoryName: "module.userSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/salesRepNetworkTree"))),
        permission: "userSetting:salesRepNetworkTree:view",
    },
    {
        path: "/settings/fields",
        lang: "module.fields",
        name: "Fields",
        headerSubcategoryName: "module.userSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/fields"))),
        permission: "userSetting:fields:view",
    },
    {
        path: "/settings/registration-workflow",
        lang: "module.registrationWorkflow",
        name: "Registration Workflow",
        headerSubcategoryName: "module.userSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/registrationWorkflow"))),
        permission: "userSetting:registrationWorkflow:view",
    },
    {
        path: "/settings/account-verification",
        lang: "module.accountVerification",
        name: "Account Verification",
        headerSubcategoryName: "module.userSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/accountVerification"))),
        permission: "userSetting:accountVerification:view",
    },
    {
        path: "/settings/role-settings",
        lang: "module.roleSetting",
        name: "Role Settings",
        headerSubcategoryName: "module.userSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/role/"))),
        permission: "userSetting:roleTemplate:view",
    },
    {
        path: "/settings/desk-settings",
        lang: "module.deskSetting",
        name: "Brand Settings",
        headerSubcategoryName: "module.userSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/desk"))),
        permission: "userSetting:desk:view",
    },
    {
        path: "/settings/sales-opportunity-setting",
        lang: "module.salesOpportunitySetting",
        name: "Sales Opportunity Setting",
        headerSubcategoryName: "module.userSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/salesOpportunitySetting"))),
        permission: "userSetting:salesOpportunityStatus:view",
    },
    // Account Settings
    {
        path: "/settings/assets",
        lang: "module.assets",
        name: "Account Settings",
        headerSubcategoryName: "module.accountSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/assets"))),
        permission: "accountSetting:assets:view",
    },
    {
        path: "/settings/securities",
        lang: "module.securitiesGroup",
        name: "Securities",
        headerSubcategoryName: "module.accountSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/securities"))),
        permission: "accountSetting:securitiesGroup:view",
    },
    {
        path: "/settings/spread-group",
        lang: "module.spreadGroup",
        name: "Spread Group",
        headerSubcategoryName: "module.accountSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/spreadGroup"))),
        permission: "accountSetting:spreadGroup:view",
    },
    {
        path: "/settings/product-group",
        lang: "module.productGroup",
        name: "Product Group",
        headerSubcategoryName: "module.accountSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/productGroup"))),
        permission: "accountSetting:productGroup:view",
    },

    {
        path: "/settings/product",
        lang: "module.product",
        name: "Product",
        headerSubcategoryName: "module.accountSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/product"))),
        permission: "accountSetting:product:view",
    },
    {
        path: "/settings/symbols",
        lang: "module.symbol",
        name: "Symbols",
        headerSubcategoryName: "module.accountSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/symbols/_general"))),
        permission: "accountSetting:symbol:view",
    },
    {
        path: "/settings/symbols/mapping",
        name: "Symbol Mapping",
        headerSubcategoryName: "module.accountSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/symbols/symbolMapping"))),
        permission: "accountSetting:symbol:view",
    },
    {
        path: "/settings/symbols/category",
        name: "Symbol Category",
        headerSubcategoryName: "module.accountSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/symbols/symbolCategory"))),
        permission: "accountSetting:symbol:view",
    },
    // Deposit/Withdrawal Settings
    {
        path: "/settings/psp-settings",
        lang: "module.pspSetting",
        name: "Deposit/Withdrawal Settings",
        headerSubcategoryName: "module.depositWithdrawalSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/pspSetting"))),
        permission: "depositWithdrawalSetting:pspSetting:view",
    },
    // Commission Settings
    {
        path: "/settings/commission-rule-settings",
        lang: "module.commissionRuleSettings",
        name: "Commission Settings",
        headerSubcategoryName: "module.commissionSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/commissionRuleSetting/_general"))),
        permission: "commission:setting:view",
    },
    {
        path: "/settings/commission-rule-settings",
        name: "Commission Rules Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/commissionRuleSetting/_general"))),
        permission: "commission:setting:view",
    },
    {
        path: "/settings/commission-batch-details",
        name: "Commission Batch Details",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/commissionRuleSetting/batchDetails"))),
        permission: "commission:setting:view",
    },
    // Message Settings
    {
        path: "/settings/variable",
        name: "Message Settings",
        lang: "module.messageSetting",
        headerSubcategoryName: "module.messageSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/variable/"))),
        permission: "messageSetting:dynamicVariable:view",
    },
    {
        path: "/settings/email-component",
        name: "Message Settings",
        headerSubcategoryName: "module.messageSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/dynamicEmailComponent/"))),
        permission: "messageSetting:dynamicVariable:view",
    },
    {
        path: "/settings/email-template",
        name: "Message Settings",
        headerSubcategoryName: "module.messageSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/emailTemplate/"))),
        permission: "messageSetting:emailTemplate:view",
    },
    {
        path: "/settings/email-template-editor/:templateId",
        name: "Message Settings",
        headerSubcategoryName: "module.messageSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/emailTemplateEditor/"))),
        permission: "messageSetting:emailTemplate:edit:edit",
    },
    {
        path: "/settings/push-notification-template",
        name: "Message Settings",
        headerSubcategoryName: "module.messageSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/pushNotificationTemplate/"))),
        permission: "messageSetting:pushNotificationTemplate:view",
    },
    {
        path: "/settings/push-notification-template-editor/:templateId",
        name: "Message Settings",
        headerSubcategoryName: "module.messageSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/pushNotificationTemplateEditor/"))),
        permission: "messageSetting:pushNotificationTemplate:edit:edit",
    },
    {
        path: "/settings/announcement",
        name: "Message Settings",
        headerSubcategoryName: "module.messageSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/announcement/"))),
        permission: "messageSetting:announcement:view",
    },
    {
        path: "/settings/announcement-editor/:announcementId",
        name: "Message Settings",
        headerSubcategoryName: "module.messageSetting",
        headerCategory: "Settings",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/announcementEditor/"))),
        permission: "messageSetting:announcement:edit",
    },
    // Activities
    {
        path: "/settings/mission",
        name: "Mission",
        lang: "module.activities",
        headerCategory: "Settings",
        headerSubcategoryName: "module.activities",
        element: withLazyComponent(React.lazy(() => import("../pages/activities/missionSetting"))),
        permission: "activitiesAndRewardSetting:mission:view",
    },
    {
        path: "/settings/promo-material",
        name: "Promo Material",
        lang: "module.promoMaterial",
        headerCategory: "Settings",
        headerSubcategoryName: "module.activities",
        element: withLazyComponent(React.lazy(() => import("../pages/activities/promoMaterial/"))),
        permission: "activities:promoMaterial:view",
    },
    // Rewards
    {
        path: "/settings/voucher",
        name: "Voucher",
        lang: "module.reward",
        headerCategory: "Settings",
        headerSubcategoryName: "module.reward",
        element: withLazyComponent(React.lazy(() => import("../pages/rewards/voucherSetting"))),
        permission: "activitiesAndRewardSetting:voucher:view",
    },
    {
        path: "/settings/voucher-program",
        name: "Voucher Program",
        element: withLazyComponent(React.lazy(() => import("../pages/rewards/voucherProgram"))),
        permission: "activitiesAndRewardSetting:voucherProgram:view",
    },
    // ARENA Settings
    {
        path: "/settings/arena/general-setting",
        name: "General Setting",
        lang: "module.generalSetting",
        headerCategory: "Settings",
        headerSubcategoryName: "Arena",
        element: withLazyComponent(React.lazy(() => import("../pages/arena/settings/generalSetting"))),
        // todo - arena permission
        permission: "arena",
    },
    {
        path: "/settings/arena/color-setting",
        name: "Color Setting",
        lang: "module.colorSetting",
        headerCategory: "Settings",
        headerSubcategoryName: "Arena",
        element: withLazyComponent(React.lazy(() => import("../pages/arena/settings/colorSetting"))),
        // todo - arena permission
        permission: "arena",
    },
    {
        path: "/settings/arena/media-library",
        name: "Media Library",
        lang: "module.mediaLibrary",
        headerCategory: "Settings",
        headerSubcategoryName: "Arena",
        element: withLazyComponent(React.lazy(() => import("../pages/arena/settings/mediaLibrary"))),
        // todo - arena permission
        permission: "arena",
    },
    // ARENA Activities
    {
        path: "/settings/arena/leaderboard-setting",
        name: "Leaderboard Setting",
        lang: "module.leaderboardSetting",
        headerCategory: "Settings",
        headerSubcategoryName: "Arena",
        element: withLazyComponent(React.lazy(() => import("../pages/arena/activities/leaderboardSetting"))),
        // todo - arena permission
        permission: "arena",
    },
    {
        path: "/settings/arena/leaderboard-ui-editor/:id",
        name: "Leaderboard Setting UI",
        lang: "module.leaderboardSetting",
        headerCategory: "Settings",
        headerSubcategoryName: "Arena",
        element: withLazyComponent(React.lazy(() => import("../pages/arena/activities/leaderboardSettingUIEditor"))),
        // todo - arena permission
        permission: "arena",
    },
    // ARENA Rewards
    {
        path: "/settings/arena/reward-setting",
        name: "Reward Setting",
        lang: "module.rewardSetting",
        headerCategory: "Settings",
        headerSubcategoryName: "Arena",
        element: withLazyComponent(React.lazy(() => import("../pages/arena/rewards/rewardSetting"))),
        // todo - arena permission
        permission: "arena",
    },
    {
        path: "/settings/arena/gift-setting",
        name: "Gift Setting",
        lang: "module.giftSetting",
        headerCategory: "Settings",
        headerSubcategoryName: "Arena",
        element: withLazyComponent(React.lazy(() => import("../pages/arena/rewards/giftSetting"))),
        // todo - arena permission
        permission: "arena",
    },
    {
        path: "/settings/arena/badge-setting",
        name: "Badge Setting",
        lang: "module.badgeSetting",
        headerCategory: "Settings",
        headerSubcategoryName: "Arena",
        element: withLazyComponent(React.lazy(() => import("../pages/arena/rewards/badgeSetting"))),
        // todo - arena permission
        permission: "arena",
    },
    {
        path: "/settings/arena/box-setting",
        name: "Box Setting",
        lang: "module.boxSetting",
        headerCategory: "Settings",
        headerSubcategoryName: "Arena",
        element: withLazyComponent(React.lazy(() => import("../pages/arena/rewards/boxSetting"))),
        // todo - arena permission
        permission: "arena",
    },
    // MAM/PAMM
    {
        path: "/settings/mampamm/provider",
        name: "Provider",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/mamPammProvider"))),
        permission: "mampamm:provider:view",
    },
    {
        path: "/settings/mampamm/product",
        name: "Product",
        lang: "module.mampamm",
        headerCategory: "Settings",
        headerSubcategoryName: "module.mampamm",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/mamPammProduct"))),
        permission: "mampamm:product:view",
    },
    {
        path: "/settings/mampamm/legal-document",
        name: "Legal Document",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/mamPammLegalDocument"))),
        permission: "mampamm:legalDocument:view",
    },
    // Reports
    {
        path: "/reports",
        name: "Reports",
        headerCategory: "Reports",
        element: withLazyComponent(React.lazy(() => import("../pages/reports"))),
        permission: "reports:reports:view",
    },
    {
        path: "/reports/category",
        name: "Reports",
        element: withLazyComponent(React.lazy(() => import("../pages/reports/reportCategory"))),
        permission: "reports:reportCategory:view:view",
    },
    // Bots Setting
    // {
    //     path: "/settings/telegram-bot-settings",
    //     name: "Bot Settings",
    //     headerSubcategoryName: "module.botSettings",
    //     headerCategory: "Settings",
    //     element: withLazyComponent(React.lazy(() => import("../pages/settings/telegramBotSetting/"))),
    //     permission: "telegram:telegramBot:view",
    // },
    // automation setting
    {
        path: "/settings/automation-setting/automation-setting",
        name: "Automation Setting",
        headerCategory: "Settings",
        lang: "Automation Setting",
        headerSubcategoryName: "Automation Setting",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/automationSetting/automationSetting"))),
        permission: "automation:setting:view",
    },
    {
        path: "/settings/automation-setting/custom-action",
        name: "Custom Action",
        headerCategory: "Settings",
        lang: "Custom Action",
        headerSubcategoryName: "Automation Setting",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/automationSetting/customAction"))),
        permission: "automation:setting:view",
    },
    {
        path: "/settings/automation-setting/custom-trigger",
        name: "Custom Trigger",
        headerCategory: "Settings",
        lang: "Custom Trigger",
        headerSubcategoryName: "Automation Setting",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/automationSetting/customTrigger"))),
        permission: "automation:setting:view",
    },
    // system setting
    {
        path: "/settings/cron",
        name: "Cron",
        headerCategory: "Settings",
        lang: "module.systemSetting",
        headerSubcategoryName: "module.systemSetting",
        element: withLazyComponent(React.lazy(() => import("../pages/settings/cron"))),
        permission: "systemSetting:cron:view",
    },
    // system logs
    {
        path: "/settings/log/audit",
        name: "Audit Logs",
        lang: "module.systemLog",
        headerCategory: "Settings",
        headerSubcategoryName: "module.systemLog",
        element: withLazyComponent(React.lazy(() => import("../pages/logs/log"))),
        permission: "systemLog:log:view",
    },
    // Message
    {
        path: "/message",
        lang: "module.message",
        element: withLazyComponent(React.lazy(() => import("../pages/messages"))),
        permission: "message:message:view",
    },
];
