import React, {useState} from "react";
import {Button, Popconfirm, Space, Table, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import TemplateModal from "./component/templateModal";
import {useDeleteDynamicEmailComponentMutation} from "../../../../../api/graphql/dynamicEmailComponent";
import {useTranslation} from "react-i18next";

const DynamicEmailComponent_1 = (props) => {
  const { data, columns, languageData, dynamicEmailComponentPermission } =
    props;
  const { t } = useTranslation();
  const { deleteDynamicEmailComponent } =
    useDeleteDynamicEmailComponentMutation();
  const [addDynamicEmailComponentModal, setAddDynamicEmailComponentModal] =
    useState(false);
  const [editDynamicEmailComponentModal, setEditDynamicEmailComponentModal] =
    useState(false);
  const [viewDynamicEmailComponentModal, setViewDynamicEmailComponentModal] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const openViewModal = (record) => {
    setSelectedItem(record);
    setViewDynamicEmailComponentModal(true);
  };

  const closeViewModal = () => {
    setViewDynamicEmailComponentModal(false);
    setSelectedItem({});
  };

  const openAddModal = () => {
    setAddDynamicEmailComponentModal(true);
  };

  const closeAddModal = () => {
    setAddDynamicEmailComponentModal(false);
  };

  const openEditModal = (record) => {
    setSelectedItem(record);
    setEditDynamicEmailComponentModal(true);
  };

  const closeEditModal = () => {
    setEditDynamicEmailComponentModal(false);
    setSelectedItem({});
  };

  return (
    <div id="email-component-layout-1">
      <TemplateModal
        mode="add"
        isOpen={addDynamicEmailComponentModal}
        languageData={languageData}
        setOpen={setAddDynamicEmailComponentModal}
        closeModal={closeAddModal}
      />
      <TemplateModal
        mode="edit"
        item={selectedItem}
        isOpen={editDynamicEmailComponentModal}
        languageData={languageData}
        setOpen={setEditDynamicEmailComponentModal}
        closeModal={closeEditModal}
      />
      <TemplateModal
        mode="view"
        item={selectedItem}
        isOpen={viewDynamicEmailComponentModal}
        languageData={languageData}
        setOpen={setViewDynamicEmailComponentModal}
        closeModal={closeViewModal}
      />

      <div className="display-flex">
        <h1>{t("Email Component")}</h1>
        {dynamicEmailComponentPermission?.add?.add && (
          <Button className="margin-left-auto" onClick={() => openAddModal()}>
            {t("Add Email Component")}
          </Button>
        )}
      </div>

      <Table
        dataSource={data}
        scroll={{ x: true }}
        columns={[
          ...columns,
          {
            title: t("common.action"),
            key: "action",
            width: 132,
            render: (_, record: any) => (
              <Space size="middle">
                <Tooltip title={t("View")}>
                  <a onClick={() => openViewModal(record)}>
                    <EyeOutlined />
                  </a>
                </Tooltip>
                {dynamicEmailComponentPermission?.edit?.edit && (
                  <Tooltip title={t("Edit")}>
                    <a onClick={() => openEditModal(record)}>
                      <EditOutlined />
                    </a>
                  </Tooltip>
                )}
                {record.type !== "system" &&
                  dynamicEmailComponentPermission?.delete?.delete && (
                    <Tooltip title={t("Delete")}>
                      <Popconfirm
                        placement="left"
                        title={"Are you sure to delete this email component?"}
                        description={"Delete the email component"}
                        onConfirm={() => {
                          deleteDynamicEmailComponent(record._id);
                        }}
                        okText={t("common.yes")}
                        cancelText={t("common.no")}
                      >
                        <a>
                          <DeleteOutlined />
                        </a>
                      </Popconfirm>
                    </Tooltip>
                  )}
              </Space>
            ),
          },
        ]}
        rowKey="id"
        size="small"
      />
    </div>
  );
};

export default DynamicEmailComponent_1;
