export const getTextFromObject = (array, field, language) => {
  const index = array?.findIndex((item) => item?.language === language);

  if (!array || !array[index] || !array[index][field]) {
    return "";
  }

  return array[index][field];
};

export const getExportData = (editor) => {
  var htmldata = editor?.getHtml();
  var cssdata = editor?.getCss();
  let exportData = htmldata + " <style>" + cssdata + "</style>";
  // console.log(exportData);
  return exportData;
};
