import React, {useEffect} from "react";
import {useLocation} from 'react-router-dom';
import ClientsDetail_1 from "./view/layout1";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {fieldListState, selfPermissionObj} from "../../../../../recoil_state";
import {useTranslation} from "react-i18next";
import {getReferralSetting} from "../../../../api/graphql/referralSetting";
import {getNewsletterAsync} from "../../../../api/graphql/newsletter";
import {newsletterOptionsState} from "../../_common/state";
import {useGetUserByID} from "./function/useQueryHooks";

function ClientsDetail() {
    let layout = 1;
    let component;

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const {t} = useTranslation()
    const id = searchParams.get('id') || "";

    const permission = useRecoilValue<any>(selfPermissionObj);
    const setNewsletterOptions = useSetRecoilState(newsletterOptionsState);
    const fieldList = useRecoilValue<any>(fieldListState)
    const {data: referralSetting} = getReferralSetting()

    const [data, loading, fetchUserByID, refetchUserByID, profileInformation] = useGetUserByID()

    // Newsletter
    const setUpNewsletter = async () => {
        const newsletter = await getNewsletterAsync({filter: {isDeleted: false},})
        setNewsletterOptions(newsletter?.getNewsletter?.data?.map((d) => {
            return {
                label: t(d?.name),
                value: d?._id,
            };
        }))
    }

    useEffect(() => {
        if (id) {
            fetchUserByID(id, true)
            setUpNewsletter()
        }
    }, [id]);

    switch (layout) {
        case 1:
            component = <ClientsDetail_1
                fieldList={fieldList}
                profileInformation={profileInformation}
                permissionUser={permission?.user}
                referralSetting={referralSetting?.getReferralSetting}
                data={data}
                dataLoading={loading}
                refetchUserByID={refetchUserByID}
                clientId={id}
            />;
            break;

        default:
            component = <ClientsDetail_1
                fieldList={fieldList}
                profileInformation={profileInformation}
                permissionUser={permission?.user}
                referralSetting={referralSetting?.getReferralSetting}
                data={data}
                dataLoading={loading}
                refetchUserByID={refetchUserByID}
                clientId={id}
            />;
            break;
    }

    return component;
}

export default ClientsDetail