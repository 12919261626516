import { Form, Select, Space } from "antd";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import FormErrors from "./formErrors";

const SelectSelectFormItem = (props) => {
  const {
    layout,
    label,
    nameSelect,
    nameSelect2,
    placeholder,
    placeholder2,
    externalErrorMessage,
    required,
    onChange,
    onChange2,
    disabled,
    labelCol,
    labelAlign,
    mode,
    options,
    options2,
    showSearch,
    filterOption,
    filterOption2,
    showArrow,
    firstWidth,
    secondWidth,
    allowClear,
    colon,
  } = props;

  const {
    register,
    watch,
    setValue,
    formState: { touchedFields, isSubmitted, errors },
  } = useFormContext();

  const errorMessage = FormErrors.errorMessage(
    nameSelect,
    errors,
    touchedFields,
    isSubmitted,
    externalErrorMessage
  );

  useEffect(() => {
    register(nameSelect, nameSelect2);
  }, [register, nameSelect, nameSelect2]);

  return (
    <Form.Item
      {...layout}
      labelCol={labelCol}
      labelWrap={true}
      label={label}
      labelAlign={labelAlign}
      colon={colon}
      required={required}
      validateStatus={errorMessage ? "error" : "success"}
      help={errorMessage}
    >
      <Space.Compact block>
        <Select
          id={nameSelect}
          mode={mode}
          options={options}
          value={watch(nameSelect)}
          onChange={(e) => {
            setValue(nameSelect, e, { shouldValidate: true });
            onChange && onChange(e);
          }}
          filterOption={filterOption}
          placeholder={placeholder}
          showSearch={showSearch}
          showArrow={showArrow}
          allowClear={allowClear}
          disabled={disabled}
          style={{ width: firstWidth, textAlign: "center" }}
        />
        <Select
          id={nameSelect2}
          mode={mode}
          options={options2}
          value={watch(nameSelect2)}
          onChange={(e) => {
            setValue(nameSelect2, e, { shouldValidate: true });
            onChange2 && onChange2(e);
          }}
          filterOption={filterOption2}
          placeholder={placeholder2}
          showSearch={showSearch}
          showArrow={showArrow}
          allowClear={allowClear}
          disabled={disabled}
          style={{ width: secondWidth, textAlign: "center" }}
        />
      </Space.Compact>
    </Form.Item>
  );
};

SelectSelectFormItem.defaultProps = {
  type: "text",
  required: false,
  disabled: false,
  colon: false,
  labelCol: { span: 6, offset: 0 },
};

SelectSelectFormItem.propTypes = {
  label: PropTypes.string,
  labelAlign: PropTypes.string,
  nameSelect: PropTypes.string.isRequired,
  nameSelect2: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  placeholder2: PropTypes.string,
  externalErrorMessage: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onChange2: PropTypes.func,
  disabled: PropTypes.bool,
  layout: PropTypes.object,
  options: PropTypes.array,
  options2: PropTypes.array,
  firstWidth: PropTypes.number,
  secondWidth: PropTypes.number,
};

export default SelectSelectFormItem;
