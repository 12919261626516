import {Form, Radio, Space} from "antd";
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import FormErrors from "./formErrors";

const RadioGroupFormItem = (props) => {
    const {
        layout,
        label,
        name,
        value,
        externalErrorMessage,
        options,
        defaultValue,
        onChange,
        hint,
        disabled,
        required,
        direction,
        labelAlign,
        className,
        labelCol,
        colon,
    } = props;

    const {
        register,
        watch,
        setValue,
        formState: {touchedFields, isSubmitted, errors},
    } = useFormContext();

    const errorMessage = FormErrors.errorMessage(
        name,
        errors,
        touchedFields,
        isSubmitted,
        externalErrorMessage
    );

    const formHelperText = errorMessage || hint;

    useEffect(() => {
        register(name);
    }, [register, name]);

    return (
        <Form.Item
            {...layout}
            labelAlign={labelAlign}
            label={label}
            labelCol={labelCol}
            labelWrap={true}
            required={required}
            validateStatus={errorMessage ? "error" : "success"}
            help={formHelperText}
            className={className}
            colon={colon}
        >
            <Radio.Group
                name={name}
                defaultValue={defaultValue}
                value={value || watch(name)}
                disabled={disabled}
                onChange={(e) => {
                    setValue(name, e?.target?.value, {shouldValidate: true});
                    onChange && onChange(e?.target?.value);
                }}
            >
                <Space direction={direction}>
                    {options.map((option) => (
                        <Radio key={option.value} id={option.value} value={option.value}>
                            {option.label}
                        </Radio>
                    ))}
                </Space>
            </Radio.Group>
        </Form.Item>
    );
};

RadioGroupFormItem.defaultProps = {
    required: false,
    disabled: false,
    colon: false,
};

RadioGroupFormItem.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.bool,
    externalErrorMessage: PropTypes.string,
    required: PropTypes.bool,
    options: PropTypes.array,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    layout: PropTypes.object,
    hint: PropTypes.string,
    direction: PropTypes.string,
    labelAlign: PropTypes.string,
    className: PropTypes.string,
    labelCol: PropTypes.object,
    colon: PropTypes.bool,
};

export default RadioGroupFormItem;
