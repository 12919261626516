import React from "react";
import {Spin} from "antd";

export const DefaultLoadingScreen = () => {

    return (
        <div id={"defaultLoadingScreen"} style={{height: "100vh", width: "100vw", display: "flex"}}>
           <Spin size={"large"} style={{margin: "auto"}}/>
        </div>
    );
}