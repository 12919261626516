import React from "react";
import {useTranslation} from "react-i18next";
import {Modal} from "antd";
import CompanyEmailForm from "./companyEmailForm";

const CompanyEmailModal = (props) => {
    const {mode, item, isOpen, closeModal, getCompanyEmailData, emailTypeOptions, domainOptions} = props;
    const {t} = useTranslation();

    const getTitle = (): String => {
        switch (mode) {
            case "add":
                return `${t("Add New Company Email")}`;
            case "view":
                return `${t("View Company Email")}`;
            case "edit":
                return `${t("Edit Company Email")}`;
            default:
                return `${t("Add New Company Email")}`;
        }
    };
    return (
        <Modal getContainer={"#companyEmail"} title={getTitle()} open={isOpen} width={"50%"} footer={null} destroyOnClose onCancel={closeModal}>
            <CompanyEmailForm
                mode={mode}
                item={item}
                closeModal={closeModal}
                getCompanyEmailData={getCompanyEmailData}
                emailTypeOptions={emailTypeOptions}
                domainOptions={domainOptions}
            />
        </Modal>
    );
};

export default CompanyEmailModal;
