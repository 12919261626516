import { gql, useMutation, useQuery } from "@apollo/client";
import { useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { notificationMessage } from "../../../recoil_state";

const GET_LANGUAGE_KEY = gql`
  query getLanguageKey {
    getLanguageKey {
      ... on LanguageKey {
        id
        languageKey
        createdBy {
          username
        }
        updatedBy {
          username
        }
        createdAt
        updatedAt
        errMsg
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getLanguageKey = () => {
  try {
    const { loading, error, data } = useQuery(GET_LANGUAGE_KEY, {
      context: {
        headers: {
          authorization: "Bearer " + localStorage.getItem("a_t"),
        },
      },
    });

    return { loading, error, data };
  } catch (err: any) {
    console.error(err);
    return { data: null, loading: false, error: err };
  }
};

const UPLOAD_LANGUAGE_KEY = gql`
  mutation uploadLanguageKey($input: LanguageKeyInput) {
    uploadLanguageKey(input: $input) {
      ... on LanguageKey {
        id
        languageKey
        createdBy {
          username
        }
        updatedBy {
          username
        }
        createdAt
        updatedAt
        errMsg
      }
      ... on BaseError {
        errKey
        errMsg
      }
      ... on CrmTaskApproval {
        msg
      }
    }
  }
`;

const useUploadLanguageKeyMutation = (): {
  uploadLanguageKey: any;
  data: any;
  loading: boolean;
  error: any;
} => {
  try {
    const [uploadLanguageKeyMutation, { data, loading, error }] = useMutation(
      UPLOAD_LANGUAGE_KEY,
      {
        update(cache, { data: { uploadLanguageKey } }) {
          let typename = uploadLanguageKey?.__typename;
          if (typename === "LanguageKey") {
            cache.modify({
              fields: {
                getLanguageKey() {
                  const newRef = cache.writeFragment({
                    data: uploadLanguageKey,
                    fragment: gql`
                      fragment NewLanguageKey on LanguageKey {
                        id
                        languageKey
                        createdBy {
                          username
                        }
                        updatedBy {
                          username
                        }
                        createdAt
                        updatedAt
                        errMsg
                      }
                    `,
                  });

                  return [newRef];
                },
              },
            });
          } else if (
            typename === "CrmTaskApproval" ||
            typename === "BaseError"
          ) {
            // Notification
          }
        },
      }
    );

    const uploadLanguageKey = (input) => {
      return uploadLanguageKeyMutation({
        variables: {
          input: {
            languageKey: input || null,
          },
        },
      });
    };

    return { uploadLanguageKey, data, loading, error };
  } catch (err: any) {
    console.error(err);
    return { uploadLanguageKey: null, data: null, loading: false, error: err };
  }
};

export { getLanguageKey, useUploadLanguageKeyMutation };
