import React, {useState} from "react";
import {getCurrencySetting, getCurrencySettingCount} from "../../../api/graphql/currencySetting";
import CurrencySetting_1 from "./view/layout1";

const CurrencySetting = () => {
    let layout = 1
    let component
    const tableName = "Currency Setting"

    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [filter, setFilter] = useState({});
    const [orderBy, setOrderBy] = useState("name_ASC");

    const {data, loading} = getCurrencySetting(filter, limit, offset, orderBy)
    const {data:exportData} = getCurrencySetting(filter, null, null, orderBy)
    const {data:totalCount} = getCurrencySettingCount(filter)

    switch (layout) {
        case 1:
            component = (
                <CurrencySetting_1
                    data={data?.getCurrencySetting?.data}
                    exportData={exportData?.getCurrencySetting?.data}
                    loading={loading}
                    totalCount={totalCount}
                    setLimit={setLimit}
                    limit={limit}
                    setOffset={setOffset}
                    setFilter={setFilter}
                    setOrderBy={setOrderBy}
                    tableName={tableName}
                />
            );
            break;

        default:
            component = (
                <CurrencySetting_1
                    data={data?.getCurrencySetting?.data}
                    exportData={exportData?.getCurrencySetting?.data}
                    loading={loading}
                    totalCount={totalCount}
                    setLimit={setLimit}
                    limit={limit}
                    setOffset={setOffset}
                    setFilter={setFilter}
                    setOrderBy={setOrderBy}
                    tableName={tableName}
                />
            );
            break;
    }

    return component;
}

export default CurrencySetting