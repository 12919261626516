import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Divider, Row } from "antd";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import { horizontalFormLayout } from "../../../../../../components/form/formWrapper";
import SwitchFormItem from "../../../../../../components/form/switchFormItem";

const BankDetailsForm = (props) => {
  const { t } = useTranslation();
  const { labelCol, formMode, form } = props;

  const fieldsList = [
    "beneficiaryBank",
    "bankAddress",
    "beneficiaryAccountName",
    "beneficiaryAccountNumber",
    "beneficiarySwiftCode",
    "bankRoutingNumber",
    "paymentDetails",
  ];

  const showBankDetailsValue = form?.getValues()?.showBankDetails;

  return (
    <div>
      <Divider />
      <Row>
        <Col className="gutter-row" xs={24} sm={24} md={24}>
          <SwitchFormItem
            name={"showBankDetails"}
            label={t("Show Bank Details")}
            layout={horizontalFormLayout}
            labelCol={labelCol}
            labelAlign="left"
            size="small"
            disabled={formMode === "view"}
          />
        </Col>
      </Row>
      {showBankDetailsValue && (
        <>
          <Divider />
          <h4>{t("Bank Details")}</h4>
          <Row gutter={10}>
            {fieldsList.map((fieldName, i) => (
              <Col key={i} className="gutter-row" xs={24} sm={12} md={12}>
                <InputFormItem
                  name={`bankDetails.${fieldName}`}
                  label={t(fieldName)}
                  placeholder={t(fieldName)}
                  layout={horizontalFormLayout}
                  labelCol={labelCol}
                  labelAlign="left"
                  disabled={formMode === "view"}
                />
              </Col>
            ))}
          </Row>
        </>
      )}
      <Divider />
    </div>
  );
};

export default BankDetailsForm;
