import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../recoil_state";
import {
    generalListCountryState,
    generalListDocumentGroupState,
    generalListDocumentState,
    generalListFieldGroupState,
    generalListFieldState,
    userVerificationConfigListState
} from "../state";
import {
    createUserVerificationCountry,
    createUserVerificationDocument,
    createUserVerificationDocumentGroup,
    createUserVerificationField,
    deleteUserVerificationCountry,
    deleteUserVerificationDocument,
    deleteUserVerificationDocumentGroup,
    deleteUserVerificationField,
    getUserVerificationConfigList,
    getUserVerificationCountry,
    getUserVerificationDocument,
    getUserVerificationDocumentGroup,
    getUserVerificationField,
    updateUserVerificationCountry,
    updateUserVerificationDocument,
    updateUserVerificationDocumentGroup,
    updateUserVerificationField
} from "../../../../api/graphql/generalList";
import useAuthorize from "../../../../_common/authorize";
import {getLanguage} from "../../../../api/graphql/language";

const useGeneralListFunction = () => {
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const setConfigList = useSetRecoilState(userVerificationConfigListState)
    const setCountry = useSetRecoilState<any>(generalListCountryState);
    const setField = useSetRecoilState<any>(generalListFieldState)
    const setFieldGroup = useSetRecoilState<any>(generalListFieldGroupState)
    const setDocument = useSetRecoilState<any>(generalListDocumentState)
    const setDocumentGroup = useSetRecoilState<any>(generalListDocumentGroupState)
    const {baseErrorChecking, systemErrorNotification} = useAuthorize()
    const {refreshQuery}: any = getLanguage({filter: null})

    const getUserVerificationConfig = async () => {
        try {
            const response: any = await getUserVerificationConfigList();
            if (response?.getUserVerificationConfigList) {
                const config = response?.getUserVerificationConfigList;
                setConfigList(config);
            }
        } catch (e) {
            systemErrorNotification()
        }
    };

    const getGeneralListCountry = async () => {
        try {
            const response: any = await getUserVerificationCountry();
            if (response?.getUserVerificationCountry?.data) {
                const list = response?.getUserVerificationCountry?.data;
                setCountry(list);
            }
        } catch (e) {
            systemErrorNotification()
        }
    };

    const createGeneralListCountry = async (country, duplicating) => {
        try {
            const response: any = await createUserVerificationCountry(country)
            if (response.createUserVerificationCountry.__typename === "UserVerificationCountry") {
                const country = await getUserVerificationCountry()
                setCountry(country?.getUserVerificationCountry?.data)

                if (!duplicating) {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: "Create Country Successfully",
                        key: "userVerificationNotification",
                    });
                }

                return response
            } else {
                return await baseErrorChecking(response?.createUserVerificationCountry)
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    const updateGeneralListCountry = async (id, isEnabled) => {
        try {
            const response = await updateUserVerificationCountry(id, isEnabled)
            if (response.updateUserVerificationCountry.__typename === "UserVerificationCountry") {
                const country = await getUserVerificationCountry()
                setCountry(country?.getUserVerificationCountry?.data)

                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Updated Country Successfully",
                    key: "userVerificationNotification",
                });

                return {
                    status: "success",
                }
            } else {
                return await baseErrorChecking(response?.updateUserVerificationCountry)
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    const deleteGeneralListCountry = async (country) => {
        try {
            const response: any = await deleteUserVerificationCountry(country)
            if (response.deleteUserVerificationCountry.__typename === "UserVerificationCountry") {
                const country = await getUserVerificationCountry()
                setCountry(country?.getUserVerificationCountry?.data)

                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Delete Country Successfully",
                    key: "userVerificationNotification",
                });

                return {
                    status: "success",
                }
            } else {
                return await baseErrorChecking(response?.deleteUserVerificationCountry)
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    const getGeneralListField = async (filter, excludeGroup) => {
        try {
            const response: any = await getUserVerificationField(filter, excludeGroup);
            if (response?.getUserVerificationField?.data) {
                const list = response?.getUserVerificationField?.data;
                if (excludeGroup === true) setField(list)
                else setFieldGroup(list);
            }
        } catch (e) {
            systemErrorNotification()
        }
    };

    const createGeneralListField = async (input, excludeGroup) => {
        try {
            const response: any = await createUserVerificationField(input)
            if (response.createUserVerificationField.__typename === "UserVerificationField") {
                await refreshQuery();

                const field = await getUserVerificationField({}, excludeGroup)
                const list = field?.getUserVerificationField?.data;
                if (excludeGroup === true) setField(list)
                else setFieldGroup(list);

                if (input?.fieldType === "group") {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: "Create Field Group Successfully",
                        key: "userVerificationNotification",
                    });
                } else {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: "Create Field Successfully",
                        key: "userVerificationNotification",
                    });
                }


                return {
                    status: "success",
                }
            } else {
                return await baseErrorChecking(response?.createUserVerificationField)
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    const updateGeneralListField = async (id, input, excludeGroup) => {
        try {
            const response = await updateUserVerificationField(id, input)
            if (response.updateUserVerificationField.__typename === "UserVerificationField") {
                await refreshQuery();

                const field = await getUserVerificationField({}, excludeGroup)
                const list = field?.getUserVerificationField?.data;
                if (excludeGroup === true) setField(list)
                else setFieldGroup(list);

                if (input?.fieldType === "group") {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: "Update Field Group Successfully",
                        key: "userVerificationNotification",
                    });
                } else {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: "Update Field Successfully",
                        key: "userVerificationNotification",
                    });
                }

                return {
                    status: "success",
                }
            } else {
                return await baseErrorChecking(response?.updateUserVerificationField)
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    const deleteGeneralListField = async (id, excludeGroup) => {
        try {
            const response: any = await deleteUserVerificationField(id)
            if (response.deleteUserVerificationField.__typename === "UserVerificationField") {
                const field = await getUserVerificationField({}, excludeGroup)
                const list = field?.getUserVerificationField?.data;
                if (excludeGroup === true) setField(list)
                else setFieldGroup(list);

                if (excludeGroup === false) {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: "Delete Field Group Successfully",
                        key: "userVerificationNotification",
                    });
                } else {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: "Delete Field Successfully",
                        key: "userVerificationNotification",
                    });
                }


                return {
                    status: "success",
                }
            } else {
                return await baseErrorChecking(response?.updateUserVerificationField)
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    const getGeneralListDocument = async () => {
        try {
            const response: any = await getUserVerificationDocument();
            if (response?.getUserVerificationDocument?.data) {
                const document = response?.getUserVerificationDocument?.data;
                setDocument(document);
            }
        } catch (e) {
            systemErrorNotification()
        }
    };

    const createGeneralListDocument = async (input) => {
        try {
            const response: any = await createUserVerificationDocument(input)
            if (response.createUserVerificationDocument.__typename === "UserVerificationDocument") {
                await refreshQuery();

                const document = await getUserVerificationDocument()
                setDocument(document?.getUserVerificationDocument?.data)

                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Create Document Successfully",
                    key: "userVerificationNotification",
                });

                return {
                    status: "success",
                }
            } else {
                return await baseErrorChecking(response?.createUserVerificationDocument)
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    const updateGeneralListDocument = async (id, input) => {
        try {
            const response = await updateUserVerificationDocument(id, input)
            if (response.updateUserVerificationDocument.__typename === "UserVerificationDocument") {
                await refreshQuery();

                const document = await getUserVerificationDocument()
                setDocument(document?.getUserVerificationDocument?.data)

                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Updated Document Successfully",
                    key: "userVerificationNotification",
                });

                return {
                    status: "success",
                }
            } else {
                return await baseErrorChecking(response?.updateUserVerificationDocument)
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    const deleteGeneralListDocument = async (id) => {
        try {
            const response: any = await deleteUserVerificationDocument(id)
            if (response.deleteUserVerificationDocument.__typename === "UserVerificationDocument") {
                const document = await getUserVerificationDocument()
                setDocument(document?.getUserVerificationDocument?.data)

                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Delete Document Successfully",
                    key: "userVerificationNotification",
                });

                return {
                    status: "success",
                }
            } else {
                return await baseErrorChecking(response?.deleteUserVerificationDocument)
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    const getGeneralListDocumentGroup = async () => {
        try {
            const response: any = await getUserVerificationDocumentGroup();
            if (response?.getUserVerificationDocumentGroup?.data) {
                const document = response?.getUserVerificationDocumentGroup?.data;
                setDocumentGroup(document);
            }
        } catch (e) {
            systemErrorNotification()
        }
    };

    const createGeneralListDocumentGroup = async (input) => {
        try {
            const response: any = await createUserVerificationDocumentGroup(input)
            if (response.createUserVerificationDocumentGroup.__typename === "UserVerificationDocumentGroup") {
                await refreshQuery();

                const document = await getUserVerificationDocumentGroup()
                setDocumentGroup(document?.getUserVerificationDocumentGroup?.data)

                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Create Document Group Successfully",
                    key: "userVerificationNotification",
                });

                return {
                    status: "success",
                }
            } else {
                return await baseErrorChecking(response?.createUserVerificationDocumentGroup)
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    const updateGeneralListDocumentGroup = async (id, input) => {
        try {
            const response = await updateUserVerificationDocumentGroup(id, input)
            if (response.updateUserVerificationDocumentGroup.__typename === "UserVerificationDocumentGroup") {
                await refreshQuery();

                const document = await getUserVerificationDocumentGroup()
                setDocumentGroup(document?.getUserVerificationDocumentGroup?.data)

                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Updated Document Group Successfully",
                    key: "userVerificationNotification",
                });

                return {
                    status: "success",
                }
            } else {
                return await baseErrorChecking(response?.updateUserVerificationDocumentGroup)
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    const deleteGeneralListDocumentGroup = async (id) => {
        try {
            const response: any = await deleteUserVerificationDocumentGroup(id)
            if (response.deleteUserVerificationDocumentGroup.__typename === "UserVerificationDocumentGroup") {
                const document = await getUserVerificationDocumentGroup()
                setDocumentGroup(document?.getUserVerificationDocumentGroup?.data)

                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: "Delete Document Group Successfully",
                    key: "userVerificationNotification",
                });

                return {
                    status: "success",
                }
            } else {
                return await baseErrorChecking(response?.deleteUserVerificationDocumentGroup)
            }
        } catch (e) {
            systemErrorNotification()
        }
    }

    return {
        getUserVerificationConfig,
        getGeneralListCountry,
        createGeneralListCountry,
        updateGeneralListCountry,
        deleteGeneralListCountry,
        getGeneralListField,
        createGeneralListField,
        updateGeneralListField,
        deleteGeneralListField,
        getGeneralListDocument,
        createGeneralListDocument,
        updateGeneralListDocument,
        deleteGeneralListDocument,
        getGeneralListDocumentGroup,
        createGeneralListDocumentGroup,
        updateGeneralListDocumentGroup,
        deleteGeneralListDocumentGroup
    };
};

export {useGeneralListFunction};
