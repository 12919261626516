import {gql, useQuery} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";

const GET_COMPANY_EMAIL_COUNT = gql`
    query getCompanyEmailCount($filter: CompanyEmailSearchInput) {
        getCompanyEmailCount(filter: $filter)
    }
`;

const getCompanyEmailCount = async ({filter}) => {
    try {
        const execute = await ApolloClientQuery(
            GET_COMPANY_EMAIL_COUNT,
            {
                filter: {
                    email: filter?.email || null,
                    host: filter?.host || null,
                    driver: filter?.driver || null,
                    emailType: filter?.emailType || null,
                    emailDomain: filter?.emailDomain || null,
                    isActive: filter?.isActive === null ? null : filter?.isActive,
                },
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );
        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_COMPANY_EMAIL_BY_ID = gql`
    query getCompanyEmailByID($id: String!) {
        getCompanyEmailByID(id: $id) {
            ... on CompanyEmail {
                id
                _id
                senderName
                name
                email
                host
                port
                username
                driver
                emailType {
                    id
                    _id
                    name
                }
                emailDomain
                isActive
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getCompanyEmailById = (id) => {
    try {
        const {loading, error, data} = useQuery(GET_COMPANY_EMAIL_BY_ID, {
            variables: {
                id: id,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return {loading, error, data};
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_COMPANY_EMAIL = gql`
    query getCompanyEmail($filter: CompanyEmailSearchInput, $limit: Int, $offset: Int, $orderBy: String) {
        getCompanyEmail(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on CompanyEmailArray {
                data {
                    id
                    _id
                    senderName
                    name
                    email
                    host
                    port
                    username
                    driver
                    emailType {
                        id
                        _id
                        name
                    }
                    emailDomain
                    isActive
                    createdBy {
                        username
                    }
                    updatedBy {
                        username
                    }
                    createdAt
                    updatedAt
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getCompanyEmail = async ({filter}, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_COMPANY_EMAIL,
            {
                filter: {
                    email: filter?.email || null,
                    host: filter?.host || null,
                    driver: filter?.driver || null,
                    emailType: filter?.emailType || null,
                    emailDomain: filter?.emailDomain || null,
                    isActive: filter?.isActive === null ? null : filter?.isActive,
                },
                limit: limit || null,
                offset: offset || null,
                orderBy: orderBy || null,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        );
        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_COMPANY_EMAIL = gql`
    mutation addCompanyEmail($input: CompanyEmailInput) {
        addCompanyEmail(input: $input) {
            ... on CompanyEmail {
                id
                _id
                senderName
                name
                email
                host
                port
                username
                driver
                emailType {
                    id
                    _id
                    name
                }
                emailDomain
                isActive
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const addCompanyEmail = async (input) => {
    const variables = {
        input: {
            senderName: input.senderName || null,
            name: input.name || null,
            email: input.email || null,
            host: input.host || null,
            port: input.port || null,
            username: input.username || null,
            password: input.password || null,
            driver: input.driver || null,
            emailType: input.emailType || null,
            emailDomain: input.emailDomain || null,
            isActive: input.isActive ? true : false,
        },
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(ADD_COMPANY_EMAIL, variables, true);

    return execute;
};

const UPDATE_COMPANY_EMAIL = gql`
    mutation updateCompanyEmail($id: String!, $input: CompanyEmailInput) {
        updateCompanyEmail(id: $id, input: $input) {
            ... on CompanyEmail {
                id
                _id
                senderName
                name
                email
                host
                port
                username
                driver
                emailType {
                    id
                    _id
                    name
                }
                emailDomain
                isActive
                createdBy {
                    username
                }
                updatedBy {
                    username
                }
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const updateCompanyEmail = async (id, input) => {
    const variables = {
        id: id,
        input: {
            senderName: input.senderName || null,
            name: input.name || null,
            email: input.email || null,
            host: input.host || null,
            port: input.port || null,
            username: input.username || null,
            password: input.password || null,
            driver: input.driver || null,
            emailType: input.emailType || null,
            emailDomain: input.emailDomain || null,
            isActive: input.isActive ? true : false,
        },
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(UPDATE_COMPANY_EMAIL, variables, true);

    return execute;
};

const DELETE_COMPANY_EMAIL = gql`
    mutation deleteCompanyEmail($id: String!) {
        deleteCompanyEmail(id: $id) {
            ... on CompanyEmail {
                id
                _id
                isDeleted
            }
            ... on BaseError {
                errKey
                errMsg
            }
            ... on CrmTaskApproval {
                msg
            }
        }
    }
`;

const deleteCompanyEmail = async (id) => {
    const variables = {
        id: id,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(DELETE_COMPANY_EMAIL, variables, true);

    return execute;
};

export {getCompanyEmailCount, getCompanyEmailById, getCompanyEmail, addCompanyEmail, updateCompanyEmail, deleteCompanyEmail};
