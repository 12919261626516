import React from "react";
import {Form, Input, Modal, Select} from "antd";
import {useTranslation} from "react-i18next";
import {useRecoilValue} from "recoil";
import {generalListFieldState} from "../../../../../state";
import {labelCol} from "../../../../../function";

const GeneralListFieldGroupForm = (props) => {
    const {isAdd, isView, isOpen, setIsOpen, submitLoading, submitField, config, item, form} = props
    const {t} = useTranslation()
    const field = useRecoilValue<any>(generalListFieldState)

    const validateMessages = {
        required: '${label} is a required field!',
    };

    return (
        <Modal
            title={t(isAdd ? "accountVerification.addFieldGroup" : isView ? "accountVerification.fieldGroup" : "accountVerification.updateFieldGroup")}
            open={isOpen}
            onCancel={() => setIsOpen(false)}
            onOk={form.submit}
            cancelText={t("common.cancel")}
            okText={isAdd ? t("common.add") : t("common.update")}
            okButtonProps={{loading: submitLoading}}
            footer={isView ? null : undefined}
            destroyOnClose={true}
        >
            <Form form={form} validateMessages={validateMessages} onFinish={submitField}>
                <Form.Item
                    label={t("accountVerification.fieldName")}
                    name={"name"}
                    labelCol={labelCol}
                    initialValue={item?.name}
                    rules={[{required: true}]}
                >
                    <Input placeholder={t("accountVerification.placeholder.fieldName").toString()} disabled={isView}/>
                </Form.Item>
                <Form.Item
                    label={t("accountVerification.tag")}
                    name={"tag"}
                    labelCol={labelCol}
                    initialValue={item?.tag}
                    rules={[{required: true}]}
                >
                    <Select
                        placeholder={t("accountVerification.placeholder.tag")}
                        options={config?.tag?.map(d => {
                            return {label: d, value: d}
                        })}
                        disabled={isView}
                    />
                </Form.Item>
                <Form.Item
                    label={t("accountVerification.fieldGroupType")}
                    name={"fieldGroupType"}
                    labelCol={labelCol}
                    initialValue={item?.fieldGroupType}
                    rules={[{required: true}]}
                >
                    <Select
                        placeholder={t("accountVerification.placeholder.fieldGroupType")}
                        options={config?.fieldGroupType?.filter(item => item !== "group")?.map(d => {
                            return {label: d, value: d}
                        })}
                        disabled={isView}
                    />
                </Form.Item>
                <Form.Item
                    label={t("accountVerification.field")}
                    name={"fieldGroup"}
                    labelCol={labelCol}
                    initialValue={item?.fieldGroup?.map(d => d?._id)}
                    rules={[{required: true}]}
                >
                    <Select
                        placeholder={t("accountVerification.placeholder.fieldGroup")}
                        mode={"multiple"}
                        options={field?.map(d => {
                            return {label: d?.name, value: d?._id}
                        })}
                        showSearch={true}
                        optionFilterProp={"label"}
                        disabled={isView}
                    />
                </Form.Item>
                {/*<Form.Item*/}
                {/*    label={t("accountVerification.isEnabled")}*/}
                {/*    name={"isEnabled"}*/}
                {/*    colon={false}*/}
                {/*    initialValue={item?.isEnabled}*/}
                {/*    style={{textAlign: "right"}}*/}
                {/*    className={"margin-top-0-75"}*/}
                {/*>*/}
                {/*    <Switch defaultChecked={item?.isEnabled}/>*/}
                {/*</Form.Item>*/}
            </Form>
        </Modal>
    )
}

export default GeneralListFieldGroupForm