import {useState} from "react";
import {getRule} from "../../../../../api/restful/automationSetting/rule";

export const useGetRule = () => {
    const [data, setData] = useState<any>(null)

    const fetchRule = async () => {
        try {
            const {data} = await getRule()
            setData(data)
        } catch (e) {

        }
    }

    return [data, fetchRule] as const
}