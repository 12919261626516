import React, {useEffect, useState} from "react";
import {Col, Row, Space, Spin} from "antd";
import ProfileCard from "./component/profileCard";
import SalesOpportunityCard from "./component/salesOpportunityCard";
import InformationCard from "./component/informationCard";
import ActionCard from "./component/actionCard";
import ActivityLogCard from "./component/activityLogCard";
import EwalletTxsActivitiesRewardCard from "./component/ewalletTxsActivitiesRewardCard";
import {useGetEWalletTransaction, useGetFollowUp} from "../../function/useQueryHooks";

const ClientsDetail_1 = (props) => {
    const {fieldList, profileInformation, permissionUser, referralSetting, data, dataLoading, refetchUserByID, clientId} = props
    const [transactions, summary, transactionLoading, fetchEWalletTransaction, refetchEWalletTransaction] = useGetEWalletTransaction()

    const [loading, setLoading] = useState(true)
    const [followUp, fetchFollowUp] = useGetFollowUp()

    const fieldForLogin = fieldList?.find(d => d?.isUsedForLogin && d?.isRequired)?.fieldName

    useEffect(() => {
        if (!dataLoading && permissionUser) {
            setLoading(false)
        }
    }, [data, permissionUser])


    return (
        <div id="clientDetail">
            <Spin tip="Loading..." spinning={loading}>
                <Row gutter={[16, 16]}>
                    <Col xs={24} xl={(permissionUser?.clientsUser?.view?.followUp || permissionUser?.clientsUser?.add?.followUp) ? 16 : 24}>
                        <Space direction={"vertical"} style={{width: "100%"}}>
                            <ProfileCard data={data} permissionUser={permissionUser} fieldForLogin={fieldForLogin} referralSetting={referralSetting}
                                         refetchUserByID={refetchUserByID} refetchEWalletTransaction={refetchEWalletTransaction}/>
                            <EwalletTxsActivitiesRewardCard data={data} permissionUser={permissionUser} transactions={transactions} summary={summary} transactionLoading={transactionLoading} fetchEWalletTransaction={fetchEWalletTransaction}/>
                            <InformationCard data={data} fieldList={fieldList} profileInformation={profileInformation}
                                             permissionUser={permissionUser}/>
                            {permissionUser?.salesOpportunity?.view &&
                                <SalesOpportunityCard data={data} permissionUser={permissionUser} clientId={clientId}/>}
                        </Space>
                    </Col>
                    {(permissionUser?.clientsUser?.view?.followUp || permissionUser?.clientsUser?.add?.followUp) &&
                        <Col xs={24} xl={8}>
                            <Space direction={"vertical"} style={{width: "100%"}}>
                                {permissionUser?.clientsUser?.add?.followUp && <ActionCard data={data} fetchFollowUp={fetchFollowUp} />}
                                {permissionUser?.clientsUser?.view?.followUp && <ActivityLogCard data={data} permissionUser={permissionUser} followUp={followUp} fetchFollowUp={fetchFollowUp} clientId={clientId}/>}
                            </Space>
                        </Col>
                    }
                </Row>
            </Spin>
        </div>
    )
}

export default ClientsDetail_1;

