import React from "react";
import {Button, Upload} from "antd";
import * as XLSX from "xlsx";
import {useTranslation} from "react-i18next";
import {convertCsvToArray, exportToExcel, makeId} from "../../../function/common";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../recoil_state";

const LanguageTranslationImportToolbar = (props) => {
    const { translationFile ,setTranslationFile, mode, languageCode } = props
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const { t } = useTranslation()

    return (
        <div className="display-flex">
            <div className="margin-left-auto">
                {mode !== "view" && 
                    <Upload
                        className="margin-right-0-75"
                        accept=".xlsx, .csv"
                        showUploadList={false}
                        beforeUpload={file => {
                            const [fileExtension] = file?.name.split(".").reverse()
                            const reader = new FileReader();

                            if(fileExtension === "csv"){
                                reader.onload = e => {
                                    setTranslationFile(convertCsvToArray(e?.target?.result));
                                };
                                reader.readAsText(file);
                            }
                            else if (fileExtension === "xlsx"){
                                reader.onload = e => {
                                const workbook = XLSX.read(e?.target?.result, { type: "binary" });
                                for (const Sheet in workbook.Sheets) {
                                    var data: any =  XLSX.utils.sheet_to_json(workbook.Sheets[Sheet]);
                                    var result = data?.map((item, index) => {
                                        return {
                                            ...item,
                                            ids: makeId(20) + "_" + index
                                        }
                                    })
                                    setTranslationFile(result)
                                }
                            }
                            reader.readAsBinaryString(file);
                            }
                            else {
                            setNotificationMessage({
                                status: "error",
                                title: "",
                                msg: t("Invalid File"),
                                key: "addLanguage",
                            });
                            
                            }
                    
                            // Prevent upload
                            return false;
                        }}
                    >
                        <Button>
                            {t('Upload')}
                        </Button>
                    </Upload>
                }
                <Button type="primary" onClick={()=>exportToExcel(translationFile, "xlsx", [languageCode])} disabled={translationFile && !(translationFile.length > 0)}>
                    {t('Export')}
                </Button>
            </div>
        </div>
    )
}

export default LanguageTranslationImportToolbar