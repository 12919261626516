import React, {useState} from "react";
import {useSetRecoilState} from "recoil";
import {Button, Form, Modal, Row} from "antd";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import {t} from "i18next";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import moment from "moment";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {horizontalFormLayout} from "../../../../../../../../components/form/formWrapper";
import yupFormSchema from "../../../../../../../../components/yup/yupFormSchema";
import {updateUserClaimMission} from "../../../../../../../../api/graphql/mission";
import {notificationMessage} from "../../../../../../../../../recoil_state";
import useAuthorize from "../../../../../../../../_common/authorize";
import DatePickerInputForm from "../../../../../../../../components/form/datePickerFormItem";
import {useTranslation} from "react-i18next";

const EditMissionModal = (props) => {
    const {t} = useTranslation()
    const {isOpen, close, data, item} = props;
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {baseErrorChecking, systemErrorNotification} = useAuthorize();
    const [isEditLoading, setIsEditLoading] = useState<boolean>(false);

    const [initialValues] = useState(() => ({
        missionClaimedAtEditedDate: item?.missionClaimedAtEditedDate
            ? dayjs(item?.missionClaimedAtEditedDate)
            : item?.missionClaimedAt
            ? dayjs(item?.missionClaimedAt)
            : null,
    }));

    const yupSchema = yup.object().shape({
        missionClaimedAtEditedDate: yupFormSchema.string("missionClaimedAtEditedDate", {required: true}),
    });

    const form = useForm({
        resolver: yupResolver(yupSchema),
        mode: "all",
        defaultValues: initialValues,
    });

    // Temporary solution for datePicker timezone issue
    const getUTCtime = (typeDay, date) => {
        let utcDate = new Date(date);

        let yyyy = utcDate.getFullYear();
        let mm = ("0" + (utcDate.getMonth() + 1)).slice(-2);
        let dd = ("0" + utcDate.getDate()).slice(-2);
        let fullUTCDate;

        if (typeDay === "startDay") {
            fullUTCDate = yyyy + "-" + mm + "-" + dd + "T00:00:00Z";
        }
        if (typeDay === "endDay") {
            fullUTCDate = yyyy + "-" + mm + "-" + dd + "T23:59:59Z";
        }
        return moment(fullUTCDate).valueOf();
    };

    const onSubmit = async () => {
        try {
            setIsEditLoading(true);
            const formValues = form.getValues();
            // let missionCode = missionDropdownData.find((m) => m?._id === formValues?.missionClaimedAtEditedDate)?.missionCode;
            // let input = {...formValues};
            let input = {missionClaimedAtEditedDate: getUTCtime("startDay", formValues.missionClaimedAtEditedDate)};
            const response = await updateUserClaimMission(item?._id, input);
            if (response?.data?.updateUserClaimMission?.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("task.task_approved"),
                    key: "updateUserClaimMission",
                });
            } else if (response?.data?.updateUserClaimMission?.__typename === "BaseError") {
                return await baseErrorChecking(response?.updateUserClaimMission);
            } else {
                close();
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t(response?.data?.updateUserClaimMission?.msg),
                    key: "updateUserClaimMission",
                });
            }
        } catch (error) {
            systemErrorNotification();
        } finally {
            setIsEditLoading(false);
        }
    };

    const labelCol = {span: 8, offset: 0};

    return (
        <Modal title={t("Edit Mission")} open={isOpen} width={"80%"} footer={null} destroyOnClose getContainer="#clientDetail" onCancel={close}>
            <FormProvider {...form}>
                <Form onFinish={form.handleSubmit(onSubmit)} className="create-report-form-1" labelWrap={true}>
                    <DatePickerInputForm
                        name={"missionClaimedAtEditedDate"}
                        // disabledDate={disabledDate}
                        labelCol={labelCol}
                        label={t("Misison Claimed Date")}
                        layout={horizontalFormLayout}
                        format={"YYYY-MM-DD"}
                        // format={"YYYY-MM-DD HH:mm"}
                        // showTime={{format: "HH:mm"}}
                        getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    />
                    <Row justify="end" className="margin-top-0-5">
                        <Button onClick={close} icon={<CloseOutlined />} className="cancelBtn" disabled={isEditLoading}>
                            {t("common.cancel")}
                        </Button>
                        <Button
                            htmlType="submit"
                            icon={<SaveOutlined />}
                            className="button-submit-1"
                            disabled={isEditLoading}
                            loading={isEditLoading}
                            onClick={onSubmit}
                        >
                            {t("common.submit")}
                        </Button>
                    </Row>
                </Form>
            </FormProvider>
        </Modal>
    );
};

export default EditMissionModal;
