import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Card, Checkbox, Col, Collapse, Divider, Row} from "antd";
import {checkAllValuesInObjTrue, checkSomeValuesInObjTrue, convertArrayToObjectValue, convertObjValueToCheckedValue} from "../../../function/common";

const PermissionFormItem = (props) => {
    const {mode, checkedPermissionKeys, setCheckedPermissionKeys, parentPermissionArrayData, childPermissionConflictObj} = props;
    const {t} = useTranslation();
    const [isCheckAllValue, setIsCheckAllValue] = useState(false);
    const mainModuleList = checkedPermissionKeys && Object.keys(checkedPermissionKeys);
    const isCheckAll = isCheckAllValue || checkAllValuesInObjTrue(checkedPermissionKeys);
    const isCheckSome = !isCheckAll && checkSomeValuesInObjTrue(checkedPermissionKeys);

    const handleSelectAllCheckboxChg = async (checked) => {
        let updatedData = convertArrayToObjectValue(parentPermissionArrayData, checked);
        setCheckedPermissionKeys((prevState) => {
            // let updatedData = convertObjValueToCheckedValue(prevState, checked);
            return {...updatedData};
        });
        setIsCheckAllValue(checked);
    };

    const handleMainModuleCheckboxChg = (checked, mainModule) => {
        setCheckedPermissionKeys((prevState) => {
            const mainModuleObj = prevState[mainModule];
            let amendedMainModuleObj = {};
            for (const [subModuleKey, subModuleValue] of Object.entries(mainModuleObj)) {
                let subModuleObj: any = subModuleValue;
                amendedMainModuleObj[subModuleKey] = {};
                for (const [permissionKey, permissionValue] of Object.entries(subModuleObj)) {
                    amendedMainModuleObj[subModuleKey][permissionKey] =
                        typeof permissionValue !== "object" ? checked : convertObjValueToCheckedValue(permissionValue, checked);
                }
            }
            return {
                ...prevState,
                [mainModule]: {
                    ...amendedMainModuleObj,
                },
            };
        });
    };

    const handleSubModuleCheckboxChg = (checked, mainModule, subModule) => {
        setCheckedPermissionKeys((prevState) => {
            const subModuleObj = prevState[mainModule][subModule];
            let amendedSubModuleObj = {};
            for (const [permissionKey, permissionValue] of Object.entries(subModuleObj)) {
                amendedSubModuleObj[permissionKey] =
                    typeof permissionValue !== "object" ? checked : convertObjValueToCheckedValue(permissionValue, checked);
            }

            return {
                ...prevState,
                [mainModule]: {
                    ...prevState[mainModule],
                    [subModule]: {
                        ...amendedSubModuleObj,
                    },
                },
            };
        });
    };

    const handlePermissionCheckboxChg = (checked, mainModule, subModule, permission, isSubPermissionListAvailable) => {
        setCheckedPermissionKeys((prevState) => {
            let permissionData = prevState[mainModule][subModule][permission];
            let permissionValue = checked;
            if (isSubPermissionListAvailable) {
                permissionValue = Object.fromEntries(Object.entries(permissionData).map(([key]) => [key, checked]));
            }
            return {
                ...prevState,
                [mainModule]: {
                    ...prevState[mainModule],
                    [subModule]: {
                        ...prevState[mainModule][subModule],
                        [permission]: permissionValue,
                    },
                },
            };
        });
    };

    const handleSubPermissionCheckboxChg = (checked, mainModule, subModule, permission, subPermission) => {
        setCheckedPermissionKeys((prevState) => {
            return {
                ...prevState,
                [mainModule]: {
                    ...prevState[mainModule],
                    [subModule]: {
                        ...prevState[mainModule][subModule],
                        [permission]: {
                            ...prevState[mainModule][subModule][permission],
                            [subPermission]: checked,
                        },
                    },
                },
            };
        });
    };

    let SubModuleSection = ({mainModule}) => {
        let subModuleList = Object.keys(checkedPermissionKeys[mainModule]);
        return (
            <Row key={mainModule}>
                {subModuleList?.map((subModule, i) => {
                    let subModuleData = checkedPermissionKeys[mainModule][subModule];
                    let permissionList = checkedPermissionKeys[mainModule][subModule];
                    let isSubModuleCheckAll = checkAllValuesInObjTrue(subModuleData);
                    let isSubModuleCheckSome = !isSubModuleCheckAll && checkSomeValuesInObjTrue(subModuleData);
                    let lastItem = i === subModuleList.length - 1;
                    const isConflictPermission = childPermissionConflictObj?.[mainModule]?.[subModule];
                    return (
                        <React.Fragment key={subModule}>
                            <Col xs={24} sm={6}>
                                <Checkbox
                                    indeterminate={isSubModuleCheckSome}
                                    checked={isSubModuleCheckAll}
                                    className="permissionCheckbox subModule"
                                    onChange={(e) => handleSubModuleCheckboxChg(e?.target?.checked, mainModule, subModule)}
                                    disabled={mode === "view"}
                                >
                                    <span style={{color: isConflictPermission ? "red" : "black"}}>{t(`module.${subModule}`)}</span>
                                </Checkbox>
                            </Col>
                            <Col xs={24} sm={18}>
                                {/* <Card size="small" className="permissionCard"> */}
                                <PermissonCheckboxes mainModule={mainModule} subModule={subModule} permissionList={permissionList} />
                                {/* </Card> */}
                            </Col>
                            {!lastItem && <Divider />}
                        </React.Fragment>
                    );
                })}
            </Row>
        );
    };

    let PermissonCheckboxes = ({mainModule, subModule, permissionList}) => {
        return (
            <div>
                <Row gutter={[5, 5]}>
                    {Object.keys(permissionList)?.map((permission) => {
                        const permissionData = checkedPermissionKeys[mainModule][subModule][permission];
                        let isSubPermissionListAvailable = typeof permissionData !== "boolean";
                        let isPermissionCheckAll = isSubPermissionListAvailable ? checkAllValuesInObjTrue(permissionData) : permissionData;
                        let isPermissionCheckSome = isSubPermissionListAvailable
                            ? !isPermissionCheckAll && checkSomeValuesInObjTrue(permissionData)
                            : permissionData;

                        let subPermissionList: any = [];
                        if (isSubPermissionListAvailable) {
                            subPermissionList = permissionData;
                        }
                        let permissionDisplayText = t(`permission.${permission}`); // debug
                        const isConflictPermission = childPermissionConflictObj?.[mainModule]?.[subModule]?.[permission];

                        return (
                            <Col key={permission} sm={6}>
                                <Card size="small" className="permissionCard">
                                    <Checkbox
                                        indeterminate={isSubPermissionListAvailable && isPermissionCheckSome}
                                        checked={isPermissionCheckAll}
                                        className="permissionCheckbox"
                                        onChange={(e) =>
                                            handlePermissionCheckboxChg(
                                                e?.target?.checked,
                                                mainModule,
                                                subModule,
                                                permission,
                                                isSubPermissionListAvailable
                                            )
                                        }
                                        disabled={mode === "view"}
                                    >
                                        <span style={{color: isConflictPermission ? "red" : "black"}}>{permissionDisplayText}</span>
                                    </Checkbox>
                                    {isSubPermissionListAvailable && (
                                        <SubPermissonCheckboxes
                                            mainModule={mainModule}
                                            subModule={subModule}
                                            permission={permission}
                                            subPermissionList={subPermissionList}
                                        />
                                    )}
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            </div>
        );
    };

    let SubPermissonCheckboxes = ({mainModule, subModule, permission, subPermissionList}) => {
        return (
            <div>
                <Row style={{marginLeft: 10}}>
                    {Object.keys(subPermissionList)?.map((subPermission) => {
                        const subPermissionValue = checkedPermissionKeys[mainModule][subModule][permission][subPermission];
                        let isSubPermissionSameTextWithPermission = permission === subPermission;
                        let subPermissionDisplayText = isSubPermissionSameTextWithPermission
                            ? t(`permission.general`)
                            : t(`permission.${subPermission}`);
                        const isConflictPermission = childPermissionConflictObj?.[mainModule]?.[subModule]?.[permission]?.[subPermission];
                        return (
                            <Col key={subPermission} sm={24}>
                                <Checkbox
                                    checked={subPermissionValue}
                                    className="permissionCheckbox"
                                    onChange={(e) =>
                                        handleSubPermissionCheckboxChg(e.target?.checked, mainModule, subModule, permission, subPermission)
                                    }
                                    disabled={mode === "view"}
                                >
                                    <span style={{color: isConflictPermission ? "red" : "black"}}>{subPermissionDisplayText}</span>
                                </Checkbox>
                            </Col>
                        );
                    })}
                </Row>
            </div>
        );
    };

    return (
        <div>
            {mainModuleList?.length > 0 ? (
                <div className="margin-bottom-0-3">
                    <Checkbox
                        indeterminate={isCheckSome}
                        checked={isCheckAll}
                        className="permissionCheckbox"
                        onChange={(e) => handleSelectAllCheckboxChg(e.target?.checked)}
                        disabled={mode === "view"}
                    >
                        {t("Select All")}
                    </Checkbox>
                </div>
            ) : (
                <Row className="margin-top-0-75 margin-bottom-0-3" justify="center">
                    <p>Parent has no permission.</p>
                </Row>
            )}
            <Collapse
                size="small"
                expandIconPosition="end"
                // collapsible="icon"
                className="permissionCollapse"
            >
                {mainModuleList?.map((mainModule) => {
                    let mainModuleData = checkedPermissionKeys[mainModule];
                    let isMainModuleCheckAll = checkAllValuesInObjTrue(mainModuleData);
                    let isMainModuleCheckSome = !isMainModuleCheckAll && checkSomeValuesInObjTrue(mainModuleData);
                    const isConflictPermission = childPermissionConflictObj?.[mainModule];
                    let languageKey = `module.${mainModule}`;
                    if (mainModule === "tradingAccount") {
                        languageKey = "module.tradingAccountCTrader";
                    } else if (mainModule === "accountSetting") {
                        languageKey = "module.accountSettingCTrader";
                    }
                    return (
                        <Collapse.Panel
                            key={mainModule}
                            header={
                                <div>
                                    <Checkbox
                                        indeterminate={isMainModuleCheckSome}
                                        checked={isMainModuleCheckAll}
                                        className="permissionCheckbox"
                                        onChange={(e) => handleMainModuleCheckboxChg(e.target?.checked, mainModule)}
                                        disabled={mode === "view"}
                                    >
                                        <span style={{color: isConflictPermission ? "red" : "black"}}>{t(languageKey)}</span>
                                    </Checkbox>
                                </div>
                            }
                            children={<SubModuleSection mainModule={mainModule} />}
                        />
                    );
                })}
            </Collapse>
        </div>
    );
};

export default PermissionFormItem;
