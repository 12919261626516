import {gql, useMutation, useQuery} from "@apollo/client";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../recoil_state";
import {useTranslation} from "react-i18next";
import {GET_USER_BY_ID} from "./user";
import {GET_USER_LEADS_BY_ID} from "./leads";
import {ApolloClientQuery} from "./_apolloClient";

const GET_FOLLOW_UP = gql`
  query getFollowUp($filter: FollowUpInput, $limit: Int, $offset: Int, $orderBy: String, $startDate: String, $endDate: String, $dateKey: String) {
    getFollowUp(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy, startDate: $startDate, endDate: $endDate, dateKey: $dateKey) {
      ... on FollowUpAry {
        data {
          _id
          followingUser {
            _id
            fullName
          }
          followingLeads{
            _id
            leadId
            name
          }
          followingType
          type
          description
          followUpDateTime
          lastFollowUp
          isFollowUp
          createdBy {
            fullName
            username
          }
          createdAt
          attachment
          emailTemplateId {_id name}
        }
      }
      ... on BaseError {
        errKey
        errMsg
      }
    }
  }
`;

const getFollowUp = ({filter}, limit?, offset?, orderBy?, startDate?, endDate?, dateKey?) => {
    try {
        const { loading, error, data } = useQuery(GET_FOLLOW_UP, {
            variables: {
                filter: filter,
                limit: limit,
                offset: offset,
                orderBy: orderBy,
                startDate: startDate,
                endDate: endDate,
                dateKey: dateKey,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        return { loading, error, data };
    } catch (err: any) {
        console.error(err);
        return { data: null, loading: false, error: err };
    }
};

const getFollowUpAsync = async ({filter}, limit?, offset?, orderBy?, startDate?, endDate?, dateKey?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_FOLLOW_UP,
            {
                filter: filter,
                limit: limit,
                offset: offset,
                orderBy: orderBy,
                startDate: startDate,
                endDate: endDate,
                dateKey: dateKey,
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_FOLLOW_UP = gql`
mutation addFollowUp($userId: String, $input: FollowUpInput) {
  addFollowUp(userId: $userId, input: $input){
    ...on FollowUp {
      _id
      user {username}
      followingUser {username}
      followingLeads {name}
      followingType
      type
      description
      followUpDateTime
      lastFollowUp
      isFollowUp
    }
    ...on BaseError {
      errKey
      errMsg
    }
  }
}
`

const useAddFollowUp = (): { addFollowUp: any, data: any, loading: boolean, error: any } => {
    try{
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const { t } = useTranslation();
        const [addFollowUpMutation, { data, loading, error }] = useMutation(ADD_FOLLOW_UP);

        const addFollowUp = (userId, input) => {
            return addFollowUpMutation({
                variables: {
                    userId: userId,
                    input: input
                },
                // refetchQueries: [GET_FOLLOW_UP, GET_USER_BY_ID, GET_USER_LEADS_BY_ID]
            });
        };

        return { addFollowUp, data, loading, error }
    }
    catch(err: any){
        console.error(err);
        return { addFollowUp: null, data: null, loading: false, error: err };
    }
}

const UPDATE_FOLLOW_UP = gql`
mutation updateFollowUp($id: String!, $input: FollowUpUpdate) {
  updateFollowUp(id: $id, input: $input){
    ...on FollowUp {
      _id
      user {username}
      followingUser {username}
      followingLeads {name}
      followingType
      type
      description
      followUpDateTime
      lastFollowUp
      isFollowUp
    }
    ...on BaseError {
      errKey
      errMsg
    }
  }
}
`

const useUpdateFollowUp = (): { updateFollowUp: any, data: any, loading: boolean, error: any } => {
    try{
        const { t } = useTranslation();
        const [updateFollowUpMutation, { data, loading, error }] = useMutation(UPDATE_FOLLOW_UP);

        const updateFollowUp = (id, input) => {
            return updateFollowUpMutation({
                variables: {
                    id: id,
                    input: input
                },
                // refetchQueries: [GET_FOLLOW_UP, GET_USER_BY_ID, GET_USER_LEADS_BY_ID]
            });
        };

        return { updateFollowUp, data, loading, error }
    }
    catch(err: any){
        console.error(err);
        return { updateFollowUp: null, data: null, loading: false, error: err };
    }
}

const DELETE_FOLLOW_UP = gql`
mutation deleteFollowUp($id: String!) {
  deleteFollowUp(id: $id){
    ...on FollowUp {
      _id
      user {username}
      followingUser {username}
      followingLeads {name}
      followingType
      type
      description
      followUpDateTime
      lastFollowUp
      isFollowUp
    }
    ...on BaseError {
      errKey
      errMsg
    }
  }
}
`

const useDeleteFollowUp = (): { deleteFollowUp: any, data: any, loading: boolean, error: any } => {
    try{
        const setNotificationMessage = useSetRecoilState(notificationMessage);
        const { t } = useTranslation();
        const [deleteFollowUpMutation, { data, loading, error }] = useMutation(DELETE_FOLLOW_UP);

        const deleteFollowUp = (id) => {
            return deleteFollowUpMutation({
                variables: {
                    id: id,
                },
                // refetchQueries: [GET_FOLLOW_UP, GET_USER_BY_ID, GET_USER_LEADS_BY_ID]
            });
        };

        return { deleteFollowUp, data, loading, error }
    }
    catch(err: any){
        console.error(err);
        return { deleteFollowUp: null, data: null, loading: false, error: err };
    }
}

export {
    getFollowUp,
    getFollowUpAsync,
    useAddFollowUp,
    useUpdateFollowUp,
    useDeleteFollowUp
}