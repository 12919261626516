import React, {useEffect} from "react";
import GeneralSetting_1 from "./layout1";
import {useGetDescription, useGetLevelSetting} from "./function/useGetQueryHooks";

const GeneralSetting = () => {
    let layout = 1, component
    const [levels, fetchLevelSetting] = useGetLevelSetting()
    const [description, fetchDescription] = useGetDescription()

    useEffect(() => {
        fetchLevelSetting()
        fetchDescription()
    }, []);

    const props = {
        levels, fetchLevelSetting,
        description, fetchDescription
    }

    switch (layout) {
        case 1:
            component = <GeneralSetting_1 {...props}/>
            break
    }

    return component
}

export default GeneralSetting