import React, {useEffect, useState} from "react";
import {Button, Col, Divider, Row, Select, Space} from "antd";
import {useTranslation} from "react-i18next";
import {PlusOutlined} from "@ant-design/icons";
import RichTextEditor from "../richTextEditor";
import {getDataWithLanguage, handleOnChangeWithLanguage} from "../../../../../brandDetails/function/common";
import {getMarketSetting, useUpdateMarketSettingMutation} from "../../../../../../../api/graphql/marketSetting";
import useAuthorize from "../../../../../../../_common/authorize";

const MarketSetting = (props) => {
    const {languageList, setCustomizeSubmitButton, close} = props
    const {t} = useTranslation()

    const [isOpenEditor, setIsOpenEditor] = useState(false)
    const [languageCode, setLanguageCode] = useState("en")
    const [data, setData] = useState<any>()

    const {data: marketSetting, loading} = getMarketSetting()
    const {updateMarketSetting} = useUpdateMarketSettingMutation()
    const {systemErrorNotification} = useAuthorize()

    useEffect(() => {
        setData(marketSetting?.getMarketSetting)
    }, [loading])

    const openEditorModal = () => {
        setIsOpenEditor(true)
    }

    const handleOnchange = (e, fieldName, fieldType = "document") => {
        handleOnChangeWithLanguage(e, fieldName, fieldType, languageCode, setData)
    }

    const onSubmit = (value) => {
       try{
           updateMarketSetting(value)
           close()
       }catch (e) {
           systemErrorNotification()
       }
    }

    useEffect(() => {
        setCustomizeSubmitButton(
            <Button onClick={() => onSubmit(data)} type={"primary"}>{t("common.submit")}</Button>
        )
    }, [data])

    return (
        <>
            <RichTextEditor
                isOpen={isOpenEditor}
                setIsOpen={setIsOpenEditor}
                title={t("One Click Trading")}
                editorState={getDataWithLanguage(data, "oneClickTrading", "document", languageCode)}
                onEditorStateChange={(e) => handleOnchange(e, "oneClickTrading", "document")}
            />
            <Divider/>
            <Row style={{padding: 16}}>
                <Col span={24}>
                    <Row justify={"space-between"} className={"margin-bottom-0-75"}>
                        <p className={"margin-bottom-0"}>{t("One click Trading")}</p>
                        <Space.Compact>
                            <Select
                                style={{width: 150}}
                                defaultValue={"en"}
                                options={languageList?.map(lang => {
                                    return {
                                        label: lang?.name,
                                        value: lang?.code
                                    }
                                })}
                                onChange={(value => setLanguageCode(value))}
                            />
                            <Button icon={<PlusOutlined/>} onClick={() => openEditorModal()}/>
                        </Space.Compact>
                    </Row>
                </Col>
            </Row>
        </>
    )
};

export default MarketSetting