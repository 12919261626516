import React, {useState} from "react";
import {Col, Input, Row} from "antd";
import {useTranslation} from "react-i18next";
import {useApproveCrmTaskMutation, useRejectCrmTaskMutation, useUpdateCrmTaskMutation} from "../../../../../../../api/graphql/crmTask";
import {useSetRecoilState} from "recoil";
import {notificationMessage} from "../../../../../../../../recoil_state";
import {getUserById} from "../../../../../../../api/graphql/user";
import TaskClientDetails from "../../clientDetails";
import {formatMoney} from "../../../../../../accounts/tradingAccount/detail/function";
import useAuthorize from "../../../../../../../_common/authorize";
import {formatDateTimeTimezone} from "../../../../../../../function/_common";

function EwalletAdjustmentLayout_1(props) {

    const {data, mode} = props
    const {TextArea} = Input;
    const [approveBtnLoading, setApproveBtnLoading] = useState(false)
    const [rejectBtnLoading, setRejectBtnLoading] = useState(false)
    const setNotificationMessage = useSetRecoilState(notificationMessage);
    const {approveCrmTask} = useApproveCrmTaskMutation()
    const {rejectCrmTask} = useRejectCrmTaskMutation()
    const {updateCrmTask} = useUpdateCrmTaskMutation()
    const {systemErrorNotification} = useAuthorize()

    const ContainerHeight = 600;
    const {t} = useTranslation()

    const approveTaskAction = async () => {
        try {
            await setApproveBtnLoading(true)
            const response = await approveCrmTask(data?._id)
            if (response?.data?.approveCrmTask?.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("task.task_approved"),
                    key: "approveCrmTask",
                });
                setApproveBtnLoading(false)
            } else if (response?.data?.approveCrmTask?.__typename === "BaseError") {

                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.data?.approveCrmTask?.errKey),
                    key: "approveCrmTask",
                });
                setApproveBtnLoading(false)
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.data?.approveCrmTask?.msg),
                    key: "approveCrmTask",
                });
                setApproveBtnLoading(false)
            }
        } catch (e) {
            systemErrorNotification()
            setApproveBtnLoading(false)
        }
    }
    const rejectTaskAction = async () => {
        try {
            await setRejectBtnLoading(true)
            const response = await rejectCrmTask(data?._id)
            if (response?.data?.rejectCrmTask?.__typename === "CrmTaskApproval") {
                setNotificationMessage({
                    status: "success",
                    title: "",
                    msg: t("task.task_rejected"),
                    key: "rejectCrmTask",
                });
                setRejectBtnLoading(false)
            } else if (response?.data?.rejectCrmTask?.__typename === "BaseError") {
                setNotificationMessage({
                    status: "error",
                    title: "",
                    msg: t(response?.data?.rejectCrmTask?.errKey),
                    key: "rejectCrmTask",
                });
                setRejectBtnLoading(false)
            } else {
                setNotificationMessage({
                    status: "info",
                    title: "",
                    msg: t(response?.data?.rejectCrmTask?.msg),
                    key: "rejectCrmTask",
                });
                setRejectBtnLoading(false)
            }
        } catch (e) {
            systemErrorNotification()
            setRejectBtnLoading(false)
        }
    }
    const updateCommentAction = async (value) => {
        try {
            await updateCrmTask(data?._id, {
                comment: value?.target?.value
            })
        } catch (e) {
            systemErrorNotification()
        }
    }

    const userDetail = getUserById(data?.payload?.input?.user)

    return (
        <div id={"eWalletLayout"}>
            <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                    {
                        userDetail?.data?.getUserByID && (
                            <TaskClientDetails data={userDetail?.data?.getUserByID}/>
                        )
                    }
                </Col>
                <Col xs={24} md={12}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <h3>{t("task.ewallet_adjustment")}</h3>
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.transaction_no")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {data?.payload?.input?.transactionNo}
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.transaction_date")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {formatDateTimeTimezone(data?.payload?.input?.transactionDate)}
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.amount")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {formatMoney(data?.payload?.input?.amount)}
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.type")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {data?.payload?.input?.type}
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.description")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {data?.payload?.input?.description}
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.requested_by")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {data?.requestedBy?.username}
                        </Col>
                        <Col xs={12} lg={8}>
                            {t("task.comment")}:
                        </Col>
                        <Col xs={12} lg={16}>
                            {
                                data?.result?.comment ?
                                    data?.result?.comment :
                                    data?.payload?.input?.comment ?
                                        data?.payload?.input?.comment :
                                        "-"
                            }
                        </Col>
                    </Row>
                </Col>

            </Row>


        </div>
    )

}

export default EwalletAdjustmentLayout_1