import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";

// LOG //

const GET_PUSH_NOTIFICATION_LOG = gql`
    query getPushNotificationLog($filter: PushNotificationLogInput) {
        getPushNotificationLog(filter: $filter) {
            ... on PushNotificationLogArray {
                data {
                    _id
                    user {username}
                    fromTemplate {
                        _id
                        name
                        type
                    }
                    platform
                    isRead
                    status
                    error
                    token
                    notification {
                        title
                        body
                        icon
                        image
                    }
                    createdAt
                    updatedAt
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

export const getPushNotificationLog = async (filter) => {
    try {
        const execute = await ApolloClientQuery(
            GET_PUSH_NOTIFICATION_LOG,
            {
                filter: filter
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_PUSH_NOTIFICATION_LOG_BY_USER_ID = gql`
    query getPushNotificationLogByUserId($id: String, $limit: Int, $offset: Int, $orderBy: String) {
        getPushNotificationLogByUserId(id: $id, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on PushNotificationLogWithUnreadArray {
                data {
                    _id
                    user {username}
                    fromTemplate {
                        _id
                        name
                        type
                    }
                    platform
                    isRead
                    status
                    error
                    token
                    notification {
                        title
                        body
                        icon
                        image
                    }
                    longContent
                    createdAt
                    updatedAt
                }
                total
                unreadTotal
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

export const getPushNotificationLogByUserId = async (id, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_PUSH_NOTIFICATION_LOG_BY_USER_ID,
            {
                id: id,
                limit: limit,
                offset: offset,
                orderBy: orderBy,
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const DELETE_PUSH_NOTIFICATION_LOG = gql`
    mutation deletePushNotificationLog($id: [String]!) {
        deletePushNotificationLog(id: $id)
    }
`

export const deletePushNotificationLog = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            DELETE_PUSH_NOTIFICATION_LOG,
            {
                id: id,
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UPDATE_PUSH_NOTIFICATION_LOG_READ_STATUS = gql`
    mutation updatePushNotificationLogReadStatus($id: [String]!, $isRead: Boolean!) {
        updatePushNotificationLogReadStatus(id: $id, isRead: $isRead)
    }
`

export const updatePushNotificationLogReadStatus = async (id, isRead) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_PUSH_NOTIFICATION_LOG_READ_STATUS,
            {
                id: id,
                isRead: isRead,
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

// BATCH LOG //

const GET_PUSH_NOTIFICATION_BATCH_LOG = gql`
    query getPushNotificationBatchLog($filter: PushNotificationLogInput) {
        getPushNotificationBatchLog(filter: $filter) {
            ... on PushNotificationBatchLogArray {
                data {
                    _id
                    recipient
                    userType
                    fromTemplate {
                        _id
                        name
                        type
                    }
                    status
                    sendInNetwork
                    totalSuccess
                    totalFail
                    createdAt
                    updatedAt
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

export const getPushNotificationBatchLog = async (filter) => {
    try {
        const execute = await ApolloClientQuery(
            GET_PUSH_NOTIFICATION_BATCH_LOG,
            {
                filter: filter,
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_PUSH_NOTIFICATION_BATCH_LOG_BY_ID = gql`
    query getPushNotificationBatchLogById($id: String!) {
        getPushNotificationBatchLogById(id: $id) {
            ... on PushNotificationBatchLog {
                _id
                recipient
                userType
                fromTemplate {
                    _id
                    name
                    type
                }
                status
                sendInNetwork
                totalSuccess
                totalFail
                createdBy {username}
                createdAt
                updatedBy {username}
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

export const getPushNotificationBatchLogById = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            GET_PUSH_NOTIFICATION_BATCH_LOG_BY_ID,
            {
                id: id,
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ARCHIVE_PUSH_NOTIFICATION_BATCH_LOG = gql`
    mutation archivePushNotificationBatchLog($id: String!) {
        archivePushNotificationBatchLog(id: $id) {
            ... on PushNotificationBatchLog {
                _id
                recipient
                fromTemplate {
                    _id
                    name
                    type
                }
                status
                sendInNetwork
                totalSuccess
                totalFail
                createdBy {username}
                createdAt
                updatedBy {username}
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

export const archivePushNotificationBatchLog = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            ARCHIVE_PUSH_NOTIFICATION_BATCH_LOG,
            {
                id: id,
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const PUSH_NOTIFICATION_TO_QUEUE_BY_BATCH = gql`
    mutation pushNotificationToQueueByBatch($input: PushNotificationByBatchInput) {
        pushNotificationToQueueByBatch(input: $input) {
            ... on PushNotificationBatchLog {
                _id
                recipient
                fromTemplate {
                    _id
                    name
                    type
                }
                status
                sendInNetwork
                totalSuccess
                totalFail
                createdBy {username}
                createdAt
                updatedBy {username}
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

export const pushNotificationToQueueByBatch = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            PUSH_NOTIFICATION_TO_QUEUE_BY_BATCH,
            {
                input: input,
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

// DRAFT //

const GET_PUSH_NOTIFICATION_DRAFT = gql`
    query getPushNotificationDraft($filter: PushNotificationDraftFilter, $limit: Int, $offset: Int, $orderBy: String) {
        getPushNotificationDraft(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on PushNotificationDraftAry {
                data {
                    _id
                    recipient
                    userType
                    fromTemplate {
                        _id
                        name
                        type
                    }
                    isSent
                    isDeleted
                    sendInNetwork
                    createdBy {username}
                    createdAt
                    updatedBy {username}
                    updatedAt
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

export const getPushNotificationDraft = async (filter, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_PUSH_NOTIFICATION_DRAFT,
            {
                filter: filter,
                limit: limit,
                offset: offset,
                orderBy: orderBy,
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_PUSH_NOTIFICATION_DRAFT_BY_ID = gql`
    query getPushNotificationDraftById($id: String!) {
        getPushNotificationDraftById(id: $id) {
            ... on PushNotificationDraft {
                _id
                recipient
                userType
                fromTemplate {
                    _id
                    name
                    type
                }
                isSent
                sendInNetwork
                createdBy {username}
                createdAt
                updatedBy {username}
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

export const getPushNotificationDraftById = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            GET_PUSH_NOTIFICATION_DRAFT_BY_ID,
            {
                id: id
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_PUSH_NOTIFICATION_DRAFT = gql`
    mutation addPushNotificationDraft($input: PushNotificationDraftInput) {
        addPushNotificationDraft(input: $input) {
            ... on PushNotificationDraft {
                _id
                recipient
                userType
                fromTemplate {
                    _id
                    name
                    type
                }
                sendInNetwork
                isSent
                createdBy {username}
                createdAt
                updatedBy {username}
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

export const addPushNotificationDraft = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            ADD_PUSH_NOTIFICATION_DRAFT,
            {
                input: input,
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ADD_PUSH_NOTIFICATION_TEMPLATE_MESSAGE_TYPE = gql`
    mutation addPushNotificationTemplateMessageType($input: PushNotificationTemplateInput) {
        addPushNotificationTemplateMessageType(input: $input) {
            ... on CrmTaskApproval {
                msg
            }
            ... on PushNotificationTemplate {
                _id
                name
                dataArrayOfObject {
                    data
                    notification {
                        title
                        body
                        icon
                        image
                    }
                    longContent
                    language
                }
                targetPlatform
                type
                isActive
                isDeleted
                createdBy {username}
                updatedBy {username}
                createdAt
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export const addPushNotificationTemplateMessageType = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            ADD_PUSH_NOTIFICATION_TEMPLATE_MESSAGE_TYPE,
            {
                input: input,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UPDATE_PUSH_NOTIFICATION_DRAFT = gql`
    mutation updatePushNotificationDraft($id: String!, $input: PushNotificationDraftInput) {
        updatePushNotificationDraft(id: $id, input: $input) {
            ... on PushNotificationDraft {
                _id
                recipient
                userType
                fromTemplate {
                    _id
                    name
                    type
                }
                sendInNetwork
                isSent
                createdBy {username}
                createdAt
                updatedBy {username}
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

export const updatePushNotificationDraft = async (id, input) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_PUSH_NOTIFICATION_DRAFT,
            {
                id: id,
                input: input,
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const ARCHIVE_PUSH_NOTIFICATION_DRAFT = gql`
    mutation archivePushNotificationDraft($id: String!) {
        archivePushNotificationDraft(id: $id) {
            ... on PushNotificationDraft {
                _id
                recipient
                fromTemplate {
                    _id
                    name
                    type
                }
                sendInNetwork
                isSent
                createdBy {username}
                createdAt
                updatedBy {username}
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

export const archivePushNotificationDraft = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            ARCHIVE_PUSH_NOTIFICATION_DRAFT,
            {
                id: id,
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const DELETE_PUSH_NOTIFICATION_DRAFT = gql`
    mutation deletePushNotificationDraft($id: String!) {
        deletePushNotificationDraft(id: $id)
    }
`

export const deletePushNotificationDraft = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            DELETE_PUSH_NOTIFICATION_DRAFT,
            {
                id: id,
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

// TOKEN //

const GET_PUSH_NOTIFICATION_TOKEN = gql`
    query getPushNotificationToken($filter: PushNotificationTokenInput, $limit: Int, $offset: Int, $orderBy: String) {
        getPushNotificationToken(filter: $filter, limit: $limit, offset: $offset, orderBy: $orderBy) {
            ... on PushNotificationTokenAry {
                data {
                    _id
                    user
                    platform
                    provider
                    browser
                    os
                    token
                    isNewDeviceLogin
                    createdBy {username}
                    createdAt
                    updatedBy {username}
                    updatedAt
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

export const getPushNotificationToken = async (filter, limit?, offset?, orderBy?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_PUSH_NOTIFICATION_TOKEN,
            {
                filter: filter,
                limit: limit,
                offset: offset,
                orderBy: orderBy,
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const UPDATE_USER_PUSH_NOTIFICATION_TOKEN = gql`
    mutation updateUserPushNotificationToken($input: PushNotificationTokenInput) {
        updateUserPushNotificationToken(input: $input) {
            ... on PushNotificationToken {
                _id
                user
                platform
                provider
                browser
                os
                token
                isNewDeviceLogin
                createdBy {username}
                createdAt
                updatedBy {username}
                updatedAt
            }
            ... on BaseError {
                errKey
                errMsg
                errObj
            }
        }
    }
`

export const updateUserPushNotificationToken = async (input) => {
    try {
        const execute = await ApolloClientQuery(
            UPDATE_USER_PUSH_NOTIFICATION_TOKEN,
            {
                input: input,
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const DELETE_USER_PUSH_NOTIFICATION_TOKEN = gql`
    mutation deletePushNotificationToken($id: String!) {
        deletePushNotificationToken(id: $id)
    }
`

export const deletePushNotificationToken = async (id) => {
    try {
        const execute = await ApolloClientQuery(
            DELETE_USER_PUSH_NOTIFICATION_TOKEN,
            {
                id: id,
            },
            true
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const GET_PN_SERVER_SETTING_WITHOUT_TOKEN = gql`
query getPNServerSettingWithoutToken($input: PNServerSettingWithoutToken!) {
    getPNServerSettingWithoutToken(input: $input) {
        ... on PushNotificationServerSetting {
            _id
            name
            targetPlatform
            provider
            firebase {
                config {
                    apiKey
                    authDomain
                    projectId
                    storageBucket
                    messagingSenderId
                    appId
                    measurementId
                }
                vapid
            }
        }
        ... on BaseError {
            errKey
            errMsg
        }
    }
}
`;

export async function getPNServerSettingWithoutToken(targetPlatform, provider) {
    const variables = {
        input: {
            targetPlatform: targetPlatform,
            provider: provider,
        },
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    }

    const execute = await ApolloClientQuery(GET_PN_SERVER_SETTING_WITHOUT_TOKEN, variables, false);

    return execute;
}
