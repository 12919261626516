import axios from "axios";
import {getConfigByName} from "../../../../config";
import {BRAND_NAME} from "../../../../config/brandVariable";

const url = getConfigByName(BRAND_NAME).arenaURL
const config = {headers: {"Content-Type": "text/plain", "authorization": `Bearer ${localStorage.getItem("arenaToken")}`}}

export const getColorSetting = async (param = {}) => {
    try {
        const filter = {filter: param};
        return await axios.post(`${url}/api/color/setting/get`, JSON.stringify(filter), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export const createColorSetting = async (input) => {
    try {
        return await axios.post(`${url}/api/color/setting/create`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export const updateColorSetting = async (input) => {
    try {
        return await axios.post(`${url}/api/color/setting/update`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}

export const deleteColorSetting = async (input) => {
    try {
        return await axios.post(`${url}/api/color/setting/delete`, JSON.stringify(input), config)
            .then((response) => {
                return response?.data;
            })
    } catch (e) {
        throw e;
    }
}