import {gql} from "@apollo/client"
import {ApolloClientQuery} from "./_apolloClient"

const GET_REOPEN_CLOSED_POSITION_PAYLOAD = gql`
    query getReopenClosedPositionPayload($id: String, $positionId: String) {
        getReopenClosedPositionPayload(id: $id, positionId: $positionId)
    }
`

const getReopenClosedPositionPayload = async (id, positionId) => {
    try {
        const execute = await ApolloClientQuery(
            GET_REOPEN_CLOSED_POSITION_PAYLOAD,
            {
                id: id,
                positionId: positionId,
                context: {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("a_t"),
                    },
                },
            },
            false
        )

        return execute
    } catch (err: any) {
        console.error(err)
        return {data: null, loading: false, error: err}
    }
}

const VOID_CLOSED_POSITION = gql`
    mutation voidClosedPosition($id: String, $positionId: String, $reportInput: [ReportingUpdateCellValuesInput], $hasReopen: Boolean) {
        voidClosedPosition(id: $id, positionId: $positionId, reportInput: $reportInput, hasReopen: $hasReopen)
    }
`

const voidClosedPosition = async (id, positionId, reportInput, hasReopen) => {
    const variables = {
        id: id,
        positionId: positionId,
        reportInput: reportInput,
        hasReopen: hasReopen,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    }

    const execute = await ApolloClientQuery(VOID_CLOSED_POSITION, variables, true)

    return execute
}

const REOPEN_CLOSED_POSITION = gql`
    mutation reopenClosedPosition($id: String, $positionId: String, $reportInput: [ReportingUpdateCellValuesInput]) {
        reopenClosedPosition(id: $id, positionId: $positionId, reportInput: $reportInput)
    }
`

const reopenClosedPosition = async (id, positionId, reportInput) => {
    const variables = {
        id: id,
        positionId: positionId,
        reportInput: reportInput,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    }

    const execute = await ApolloClientQuery(REOPEN_CLOSED_POSITION, variables, true)

    return execute
}

const VOID_OPEN_POSITION = gql`
    mutation voidOpenPosition($id: String, $positionId: String) {
        voidOpenPosition(id: $id, positionId: $positionId)
    }
`

const voidOpenPosition = async (id, positionId) => {
    const variables = {
        id: id,
        positionId: positionId,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    }

    const execute = await ApolloClientQuery(VOID_OPEN_POSITION, variables, true)

    return execute
}

const VOID_ALL_OPEN_POSITION = gql`
    mutation voidAllOpenPosition($id: String, $traderId: String) {
        voidAllOpenPosition(id: $id, traderId: $traderId)
    }
`

const voidAllOpenPosition = async (id, traderId) => {
    const variables = {
        id: id,
        traderId: traderId,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    }

    const execute = await ApolloClientQuery(VOID_ALL_OPEN_POSITION, variables, true)

    return execute
}

const ADJUST_COMMISSION_SWAP_BY_CLOSED_POSITION_ID = gql`
    mutation adjustCommissionSwapByClosedPositionId($id: String, $positionId: String, $commissionSelected: Boolean, $swapSelected: Boolean, $commission: Float, $swap: Float, $reportInput: [ReportingUpdateCellValuesInput]) {
        adjustCommissionSwapByClosedPositionId(id: $id, positionId: $positionId, commissionSelected: $commissionSelected, swapSelected: $swapSelected, commission: $commission, swap: $swap, reportInput: $reportInput)
    }
`

const adjustCommissionSwapByClosedPositionId = async (id, positionId, commissionSelected, swapSelected, commission, swap, reportInput) => {
    const variables = {
        id: id,
        positionId: positionId,
        commissionSelected: commissionSelected,
        swapSelected: swapSelected,
        commission: commission,
        swap: swap,
        reportInput: reportInput,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    }

    const execute = await ApolloClientQuery(ADJUST_COMMISSION_SWAP_BY_CLOSED_POSITION_ID, variables, true)

    return execute
}

export {getReopenClosedPositionPayload, voidClosedPosition, reopenClosedPosition, voidOpenPosition, voidAllOpenPosition, adjustCommissionSwapByClosedPositionId}
