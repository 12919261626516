import {getColumnSearchProps} from "../../../../components/table/function/filterTable";
import React from "react";
import {useTranslation} from "react-i18next";

export const columns = () => {
    const {t} = useTranslation()

    return [
        {
            title: "Position",
            dataIndex: "position",
            key: "position",
            render: (text) => <p>{text}</p>,
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (text) => <p>{text}</p>,
        },
        {
            title: "Display Name Language Key",
            dataIndex: "displayName",
            key: "displayName",
            render: (text) => <p>{text}</p>,
        },
        {
            title: "Total Sales Opportunity",
            dataIndex: "totalSalesOpp",
            key: "totalSalesOpp",
            render: (text) => <p>{text}</p>,
        },
        {
            title: "Total Sales Opportunity Amount",
            dataIndex: "totalSalesOppAmount",
            key: "totalSalesOppAmount",
            render: (text) => <p>{text}</p>,
        },
    ]
}