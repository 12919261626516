import {gql, useQuery} from "@apollo/client";
import {ApolloClientQuery} from "./_apolloClient";

const GET_LOGGER = gql`
    query getLogger(
        $filter: LoggerFilter
        $orFilter: LoggerFilter
        $orderBy: String
        $limit: Int
        $offset: Int
        $startDate: String
        $endDate: String
        $dateKey: String
    ) {
        getLogger(
            filter: $filter
            orFilter: $orFilter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
            startDate: $startDate
            endDate: $endDate
            dateKey: $dateKey
        ) {
            ... on LoggerArray {
                data {
                    id
                    moduleName
                    subModuleName
                    permission
                    functionName
                    referenceTable
                    referenceId
                    content
                    payload 
                    result
                    logLevel
                    createdAt
                    parent
                    requestedId
                    requestedUsername
                    requestedUserType
                }
                total
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

const getLogger = ({filter}, orFilter?, orderBy?, limit?, offset?, startDate?, endDate?, dateKey?) => {
    try {
        const {loading, error, data, refetch} = useQuery(GET_LOGGER, {
            variables: {
                filter: filter,
                orFilter: orFilter,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
                orderBy: orderBy || null,
                limit: limit || null,
                offset: offset || null,
            },
            context: {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("a_t"),
                },
            },
        });

        const refetchQuery = () => {
            refetch()
        }

        return {loading, error, data, refetchQuery};
    } catch (err) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

const getLoggerAsync = async ({filter}, orFilter?, orderBy?, limit?, offset?, startDate?, endDate?, dateKey?) => {
    try {
        const execute = await ApolloClientQuery(
            GET_LOGGER,
            {
                filter: filter,
                orFilter: orFilter,
                startDate: startDate || null,
                endDate: endDate || null,
                dateKey: dateKey || null,
                orderBy: orderBy || null,
                limit: limit || null,
                offset: offset || null,
            },
            false
        );

        return execute;
    } catch (err: any) {
        console.error(err);
        return {data: null, loading: false, error: err};
    }
};

export{
    getLogger,
    getLoggerAsync
}