import React, {useEffect, useState} from "react";
import {Button, Checkbox, Col, Form, Input, Modal, Row, Select, Space} from "antd";
import {useTranslation} from "react-i18next";
import useAuthorize from "../../../../../../../../_common/authorize";
import InfoModal from "./infoModal";

const ClaimMissionModal = ({isOpen, handleClose, item, data, handleClaimMission, isMissionActionLoading, setIsMissionActionLoading}) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const {systemErrorNotification} = useAuthorize();

    const [checkedTerms, setCheckedTerms] = useState(false);
    const [infoModalIsOpen, setInfoModalIsOpen] = useState(false);
    const [info, setInfo] = useState("");

    const toggleInfoModal = () => {
        setInfoModalIsOpen((prevState) => !prevState);
    };

    const submitHandle = async (value) => {
        try {
            setIsMissionActionLoading(true);
            await handleClaimMission({
                user: data?._id,
                missionId: item?._id,
                tradingAccount: value?.tradingAcc || null,
                missionCode: value?.claimCode || null,
            });
            handleClose();
        } catch (error) {
            systemErrorNotification();
        } finally {
            setIsMissionActionLoading(false);
        }
    };

    useEffect(() => {
        form.resetFields();
        setCheckedTerms(false);
    }, [isOpen]);

    return (
        <>
            <Modal
                open={isOpen}
                onCancel={handleClose}
                footer={
                    <Button
                        icon={null}
                        loading={isMissionActionLoading}
                        onClick={() => form.submit()}
                        className={"primary-small-button"}
                        disabled={item?.termAndConditionEnabled && !checkedTerms}
                    >
                        {t("mission.claim")}
                    </Button>
                }
                title={t("mission.claimMission")}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24} className="w-100">
                        <Form form={form} layout={"vertical"} onFinish={submitHandle}>
                            {item?.isTradingAccountRequired && (
                                <Form.Item
                                    className={"mb-2"}
                                    label={t("mission.label.selectTradingAccount")}
                                    name={`tradingAcc`}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("errorMsg.required.pleaseFillIn", {field: t("user.tradingAccount")}).toString(),
                                        },
                                    ]}
                                >
                                    <Select options={item?.canClaimForTradingAccount?.map((d) => ({label: d?.accountId, value: d?._id}))} />
                                </Form.Item>
                            )}
                            {item?.isMissionCodeRequired && (
                                <Form.Item
                                    className={"mb-2"}
                                    label={t("mission.label.claimCode")}
                                    name={`claimCode`}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("errorMsg.required.pleaseFillIn", {field: t("mission.label.claimCode")}).toString(),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t("mission.placeholder.enterTheCode").toString()} />
                                </Form.Item>
                            )}
                        </Form>
                    </Col>
                    {item?.termAndConditionEnabled && (
                        <Col span={24}>
                            <Space>
                                <Checkbox checked={checkedTerms} onChange={(e) => setCheckedTerms(e?.target?.checked)} />
                                <a
                                    onClick={() => {
                                        toggleInfoModal();
                                        setInfo("terms");
                                    }}
                                >
                                    {t("common.readAndAgreed_termsAndConditions")}
                                </a>
                            </Space>
                        </Col>
                    )}
                </Row>
            </Modal>
            <InfoModal isOpen={infoModalIsOpen} handleClose={toggleInfoModal} item={item} info={info} />
        </>
    );
};

export default ClaimMissionModal;
