import React, {useEffect, useState} from "react";
import MamPamm_1 from "./view/layout1";
import {getInvestorAccount, getMoneyManagerAccount} from "../../../api/graphql/mamPamm";
import "./style/index.css";

const MamPamm = () => {
    let layout = 1;
    let component;

    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [filter, setFilter] = useState({});
    const [orderBy, setOrderBy] = useState("");
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const [limitInvestor, setLimitInvestor] = useState(10);
    const [offsetInvestor, setOffsetInvestor] = useState(0);
    const [filterInvestor, setFilterInvestor] = useState({});
    const [orderByInvestor, setOrderByInvestor] = useState("");
    const [selectedMoneyManager, setSelectedMoneyManager] = useState("");
    const [dataInvestor, setDataInvestor] = useState([]);
    const [totalCountInvestor, setTotalCountInvestor] = useState(0);

    const getMoneyManagerListing = async () => {
        try {
            setIsLoading(true);
            const res = await getMoneyManagerAccount({filter}, limit, offset, orderBy);
            setData(res?.getMoneyManagerAccount?.data);
            setTotalCount(res?.getMoneyManagerAccount?.total);
            if (res?.getMoneyManagerAccount?.data?.length > 0) {
                setSelectedMoneyManager(res?.getMoneyManagerAccount?.data?.[0]?._id);
            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const getInvestorListing = async () => {
        try {
            setIsLoading(true);
            const res = await getInvestorAccount(
                {filter: {...filterInvestor, moneyManagerAccount: selectedMoneyManager}},
                limitInvestor,
                offsetInvestor,
                orderByInvestor
            );
            setDataInvestor(res?.getInvestorAccount?.data);
            setTotalCountInvestor(res?.getInvestorAccount?.total);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getMoneyManagerListing();
    }, [filter, limit, offset, orderBy]);

    useEffect(() => {
        if (selectedMoneyManager) {
            getInvestorListing();
        }
    }, [filterInvestor, limitInvestor, offsetInvestor, orderByInvestor, selectedMoneyManager]);

    switch (layout) {
        case 1:
            component = (
                <MamPamm_1
                    loading={isLoading}
                    data={data}
                    totalCount={totalCount}
                    setLimit={setLimit}
                    limit={limit}
                    setOffset={setOffset}
                    setFilter={setFilter}
                    setOrderBy={setOrderBy}
                    dataInvestor={dataInvestor}
                    totalCountInvestor={totalCountInvestor}
                    setLimitInvestor={setLimitInvestor}
                    limitInvestor={limitInvestor}
                    setOffsetInvestor={setOffsetInvestor}
                    setFilterInvestor={setFilterInvestor}
                    setOrderByInvestor={setOrderByInvestor}
                    selectedMoneyManager={selectedMoneyManager}
                    setSelectedMoneyManager={setSelectedMoneyManager}
                    getMoneyManagerListing={getMoneyManagerListing}
                />
            );
            break;

        default:
            component = (
                <MamPamm_1
                    loading={isLoading}
                    data={data}
                    totalCount={totalCount}
                    setLimit={setLimit}
                    limit={limit}
                    setOffset={setOffset}
                    setFilter={setFilter}
                    setOrderBy={setOrderBy}
                    dataInvestor={dataInvestor}
                    totalCountInvestor={totalCountInvestor}
                    setLimitInvestor={setLimitInvestor}
                    limitInvestor={limitInvestor}
                    setOffsetInvestor={setOffsetInvestor}
                    setFilterInvestor={setFilterInvestor}
                    setOrderByInvestor={setOrderByInvestor}
                    selectedMoneyManager={selectedMoneyManager}
                    setSelectedMoneyManager={setSelectedMoneyManager}
                    getMoneyManagerListing={getMoneyManagerListing}
                />
            );
            break;
    }

    return component;
};

export default MamPamm;
