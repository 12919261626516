import React, {useCallback, useEffect, useState} from "react";
import {Space} from "antd";
import EditTableUserPreferenceButton from "../../../../../../../components/table/function/editTableUserPreferenceButton";
import ExportTableButton from "../../../../../../../components/table/function/exportTable";
import TableAntDesign from "../../../../../../../components/table/tableAntDesign";
import {getColumnSearchProps} from "../../../../../../../components/table/function/filterTable";
import {useTranslation} from "react-i18next";
import {getAssetClass} from "../../../../../../../api/graphql/assetClass";
import {getLeverageProfile} from "../../../../../../../api/graphql/leverageProfile";
import {getCommissionProfile} from "../../../../../../../api/graphql/commissionProfile";
import {getGslSchedule} from "../../../../../../../api/graphql/gslSchedule";
import {getVolumeProfile} from "../../../../../../../api/graphql/volumeProfile";
import {getExecutionProfile} from "../../../../../../../api/graphql/executionProfile";
import {getProtectionProfile} from "../../../../../../../api/graphql/protectionProfile";
import {getSwapFreeProfile} from "../../../../../../../api/graphql/swapFreeProfile";
import {getSymbol} from "../../../../../../../api/graphql/symbol";
import {useRecoilState, useRecoilValue} from "recoil";
import {
    assetClass,
    commissionProfile,
    executionProfile,
    gslSchedule,
    leverageProfile,
    protectionProfile,
    swapFreeProfile,
    symbol,
    volumeProfile,
} from "../../../../state";
import {currencySettingState} from "../../../../../../../../recoil_state";

const SymbolSettingPanel = ({server, initialData}) => {
    const {t} = useTranslation();

    const [data, setData] = useState<object[]>();
    const [exportData, setExportData] = useState<object[]>();
    const [loading, setLoading] = useState(true);

    const filterInitial = {filter: {serverName: ""}};
    const {data: initialAssetClass} = getAssetClass(filterInitial);
    const {data: initialLeverageProfile} = getLeverageProfile(filterInitial);
    const {data: initialCommissionProfile} = getCommissionProfile(filterInitial);
    const {data: initialGslSchedule} = getGslSchedule(filterInitial);
    const {data: initialVolumeProfile} = getVolumeProfile(filterInitial);
    const {data: initialExecutionProfile} = getExecutionProfile(filterInitial);
    const {data: initialProtectionProfile} = getProtectionProfile(filterInitial);
    const {data: initialSwapFreeProfile} = getSwapFreeProfile(filterInitial);
    const {data: initialSymbol} = getSymbol(filterInitial);

    const [assetClassData, setAssetClassData] = useRecoilState<any>(assetClass);
    const [leverageProfileData, setLeverageProfileData] = useRecoilState<any>(leverageProfile);
    const [commissionProfileData, setCommissionProfileData] = useRecoilState<any>(commissionProfile);
    const [gslScheduleData, setGslScheduleData] = useRecoilState<any>(gslSchedule);
    const [volumeProfileData, setVolumeProfileData] = useRecoilState<any>(volumeProfile);
    const [executionProfileData, setExecutionProfileData] = useRecoilState<any>(executionProfile);
    const [protectionProfileData, setProtectionProfileData] = useRecoilState<any>(protectionProfile);
    const [swapFreeProfileData, setSwapFreeProfileData] = useRecoilState<any>(swapFreeProfile);
    const [symbolData, setSymbolData] = useRecoilState<any>(symbol);
    const currencySetting = useRecoilValue<any>(currencySettingState);

    const [initialPriceData, setInitialPriceData] = useState<any>([]);
    const [socketData, setSocketData] = useState<any>([]);
    const [socket, setSocket] = useState<any>(null);

    const digit = currencySetting?.find((curr) => curr?.code === "USD")?.digits;

    useEffect(() => {
        setAssetClassData(initialAssetClass?.getAssetClass?.data?.filter((v) => v?.server?.name === server?.name));
        setLeverageProfileData(initialLeverageProfile?.getLeverageProfile?.data.filter((v) => v?.server?.name === server?.name));
        setCommissionProfileData(initialCommissionProfile?.getCommissionProfile?.data.filter((v) => v?.server?.name === server?.name));
        setGslScheduleData(initialGslSchedule?.getGslSchedule?.data.filter((v) => v?.server?.name === server?.name));
        setVolumeProfileData(initialVolumeProfile?.getVolumeProfile?.data.filter((v) => v?.server?.name === server?.name));
        setExecutionProfileData(initialExecutionProfile?.getExecutionProfile?.data.filter((v) => v?.server?.name === server?.name));
        setProtectionProfileData(initialProtectionProfile?.getProtectionProfile?.data.filter((v) => v?.server?.name === server?.name));
        setSwapFreeProfileData(initialSwapFreeProfile?.getSwapFreeProfile?.data.filter((v) => v?.server?.name === server?.name));
        setSymbolData(initialSymbol?.getSymbol?.data.filter((v) => v?.server?.name === server?.name));
    }, [
        initialAssetClass?.getAssetClass?.data,
        initialLeverageProfile?.getLeverageProfile?.data,
        initialCommissionProfile?.getCommissionProfile?.data,
        initialGslSchedule?.getGslSchedule?.data,
        initialVolumeProfile?.getVolumeProfile?.data,
        initialExecutionProfile?.getExecutionProfile?.data,
        initialProtectionProfile?.getProtectionProfile?.data,
        initialSwapFreeProfile?.getSwapFreeProfile?.data,
        initialSymbol?.getSymbol?.data,
    ]);

    useEffect(() => {
        if (initialData?.name) setUpData();
    }, [initialData?.name, server]);

    const fixDigit = (value, digit) => {
        return value?.toLocaleString("en-US", {
            minimumFractionDigits: digit,
            maximumFractionDigits: digit,
        });
    };

    const isJson = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            console.log(str);
            return false;
        }
        return JSON.parse(str);
    };

    const setUpData = useCallback(async () => {
        try {
            if (server?.platform === "cTrader" && server?.mode?.[0] === "live") {
                const currentURL = server?.plantId === "blueocean" ? "socket2" : "socket1";
                const currentServer = server?.plantId === "blueocean" ? "server2" : "server1";
                const apiUrl = `https://ctrader.nextrades.ai/api/rest/spreadGroupSymbolPrice?serverName=${currentServer}&spreadGroup=${initialData?.name}`;

                await fetch(apiUrl)
                    .then((response) => response?.json())
                    .then((data) => setInitialPriceData(data?.payload))
                    .catch((error) => console.error("Error fetching data:", error));

                const newSocket = new WebSocket(`wss://${currentURL}.nextrades.ai/spreadGroup=${initialData?.name}`);
                newSocket?.addEventListener("message", (event) => {
                    const rawData = event?.data;
                    const receivedData: any = isJson(rawData) || {};

                    setSocketData((prevData) => {
                        const existingPositionIndex = prevData?.findIndex((position) => position?.symbolId === receivedData?.symbolId);

                        if (existingPositionIndex !== -1) {
                            const updatedPositions = [...prevData];
                            updatedPositions[existingPositionIndex] = receivedData;
                            return updatedPositions;
                        } else {
                            return [...prevData, receivedData];
                        }
                    });
                });

                setSocket(newSocket);

                return () => {
                    newSocket?.close();
                };
            }
        } catch (e) {
            console.log(e);
        }
    }, [server, initialData?.name]);

    const fetchData = useCallback(async () => {
        let symbolDataByServer = symbolData?.filter((sym) => sym?.server?._id === server?.id);
        const initialSymbol = await symbolDataByServer?.map((d) => {
            return {
                ...d,
                symbolId: d?.symbolId,
                name: d?.name,
                server: d?.server,
                assetClassId: d?.symbolCategoryId?.[0]?.assetClassId,
                lotSize: d?.lotSize,
                leverageId: d?.defaultLeverageId?.[0]?.leverageId,
                commissionProfileId: d?.defaultCommissionProfileId?.[0]?.commissionProfileId,
                volumeProfileId: d?.defaultVolumeProfileId?.[0]?.volumeProfileId,
                executionProfileId: d?.defaultExecutionProfileId?.[0]?.executionProfileId,
                protectionProfileId: d?.defaultProtectionProfileId?.[0]?.protectionProfileId,
                swapFreeProfileId: d?.defaultSwapFreeProfileId?.[0]?.swapFreeProfileId,
                gslScheduleId: d?.defaultGSLScheduleId?.[0]?.gslScheduleId,
            };
        });
        const spreadGroupSymbol = await initialData?.symbol?.map((d) => {
            return {
                symbolId: d?.symbolId,
                leverageId: d?.leverageId,
                commissionProfileId: d?.commissionProfileId,
                volumeProfileId: d?.volumeProfileId,
                executionProfileId: d?.executionProfileId,
                protectionProfileId: d?.protectionProfileId,
                swapFreeProfileId: d?.swapFreeProfileId,
                gslScheduleId: d?.gslScheduleId,
            };
        });

        const mergedData = await processMergeData(spreadGroupSymbol, initialSymbol);
        const exportedData = await processExportData(mergedData);

        setExportData(exportedData);
        return await processData(mergedData);
    }, [symbolData, initialData?.name, initialPriceData, socketData]);

    // const fetchDataFunction = async () => {
    //     const data = await fetchData();
    //     setData(data);
    //     setLoading(false);
    // };
    //
    // useEffect(() => {
    //
    //     const intervalId = setInterval(fetchDataFunction, 1000);
    //
    //     return () => {
    //         clearInterval(intervalId);
    //     };
    // }, [socketData]);

    useEffect(() => {
        if (
            assetClassData?.length > 0 &&
            leverageProfileData?.length > 0 &&
            commissionProfileData?.length > 0 &&
            gslScheduleData?.length > 0 &&
            volumeProfileData?.length > 0 &&
            executionProfileData?.length > 0 &&
            protectionProfileData?.length > 0 &&
            swapFreeProfileData?.length > 0 &&
            symbolData?.length > 0 &&
            initialData?.name
        )
            fetchData()?.then((data) => {
                setData(data);
                setLoading(false);
            });
    }, [symbolData, initialData?.name, initialPriceData, socketData]);

    const processExportData = async (data) => {
        return await data?.map((d) => {
            const matchedData = initialPriceData?.find((item) => item?.symbolId === d?.symbolId) || d;
            const currentSocketData = socketData?.find((data) => data?.symbolId === d?.symbolId);

            const minLot =
                (
                    volumeProfileData?.find((obj) => obj?.volumeProfileId === d?.volumeProfileId) ||
                    volumeProfileData?.find((obj) => obj?.volumeProfileId === d?.defaultVolumeProfileId)
                )?.minVolume / d?.lotSize;
            const maxLot =
                (
                    volumeProfileData?.find((obj) => obj?.volumeProfileId === d?.volumeProfileId) ||
                    volumeProfileData?.find((obj) => obj?.volumeProfileId === d?.defaultVolumeProfileId)
                )?.maxVolume / d?.lotSize;

            const swapProfile = initialData?.swapAndDividendProfileId?.[0]?.symbol?.find((s) => s?.symbolId === d?.symbolId);

            const swapLong = swapProfile?.swapLong || d?.defaultSwapLong;
            const swapShort = swapProfile?.swapShort || d?.defaultSwapShort;
            const swapRollover3Days = swapProfile?.swapRollover3Days || d?.defaultSwapRollover3Days;

            const leverage = (
                leverageProfileData?.find((obj) => obj?.leverageId === d?.leverageId) ||
                leverageProfileData?.find((obj) => obj?.leverageId === d?.defaultLeverageId)
            )?.tiers?.[0]?.leverage;
            const marginPercentage = (100 / leverage) * 100;

            return {
                ...d,
                assetClass: assetClassData?.find((obj) => obj?.assetClassId === d?.assetClassId)?.name,
                leverage:
                    leverageProfileData?.find((obj) => obj?.leverageId === d?.leverageId)?.name ||
                    leverageProfileData?.find((obj) => obj?.leverageId === d?.defaultLeverageId)?.name,
                commissionProfile:
                    commissionProfileData?.find((obj) => obj?.commissionProfileId === d?.commissionProfileId)?.name ||
                    commissionProfileData?.find((obj) => obj?.commissionProfileId === d?.defaultCommissionProfileId)?.name,
                volumeProfile:
                    volumeProfileData?.find((obj) => obj?.volumeProfileId === d?.volumeProfileId)?.name ||
                    volumeProfileData?.find((obj) => obj?.volumeProfileId === d?.defaultVolumeProfileId)?.name,
                executionProfile:
                    executionProfileData?.find((obj) => obj?.executionProfileId === d?.executionProfileId)?.name ||
                    executionProfileData?.find((obj) => obj?.executionProfileId === d?.defaultExecutionProfileId)?.name,
                protectionProfile:
                    protectionProfileData?.find((obj) => obj?.protectionProfileId === d?.protectionProfileId)?.name ||
                    protectionProfileData?.find((obj) => obj?.protectionProfileId === d?.defaultProtectionProfileId)?.name,
                swapFreeProfile:
                    swapFreeProfileData?.find((obj) => obj?.swapFreeProfileId === d?.swapFreeProfileId)?.name ||
                    swapFreeProfileData?.find((obj) => obj?.swapFreeProfileId === d?.defaultSwapFreeProfileId)?.name,
                gslSchedule:
                    gslScheduleData?.find((obj) => obj?.gslScheduleId === d?.gslScheduleId)?.name ||
                    gslScheduleData?.find((obj) => obj?.gslScheduleId === d?.defaultGslScheduleId)?.name,
                defaultLeverage: leverageProfileData?.find((obj) => obj?.leverageId === d?.defaultLeverageId)?.name,
                defaultCommissionProfile: commissionProfileData?.find((obj) => obj?.commissionProfileId === d?.defaultCommissionProfileId)?.name,
                defaultVolumeProfile: volumeProfileData?.find((obj) => obj?.volumeProfileId === d?.defaultVolumeProfileId)?.name,
                defaultExecutionProfile: executionProfileData?.find((obj) => obj?.executionProfileId === d?.defaultExecutionProfileId)?.name,
                defaultProtectionProfile: protectionProfileData?.find((obj) => obj?.protectionProfileId === d?.defaultProtectionProfileId)?.name,
                defaultSwapFreeProfile: swapFreeProfileData?.find((obj) => obj?.swapFreeProfileId === d?.defaultSwapFreeProfileId)?.name,
                defaultGslSchedule: gslScheduleData?.find((obj) => obj?.gslScheduleId === d?.defaultGslScheduleId)?.name,
                bid: fixDigit(currentSocketData?.bid, currentSocketData?.digits) || fixDigit(matchedData?.bid, matchedData?.digits),
                ask: fixDigit(currentSocketData?.ask, currentSocketData?.digits) || fixDigit(matchedData?.ask, matchedData?.digits),
                spreadInPoints: currentSocketData?.spreadInPoints || matchedData?.spreadInPoints,
                averageSpreadInPoints: currentSocketData?.averageSpreadInPoints || matchedData?.averageSpreadInPoints,
                spreadInUsd: currentSocketData?.spreadInUsd || matchedData?.spreadInUsd,
                averageSpreadInUsd: fixDigit(currentSocketData?.averageSpreadInUsd, digit) || fixDigit(matchedData?.averageSpreadInUsd, digit),
                spreadInUsdPerLot: fixDigit(currentSocketData?.spreadInUsdPerLot, digit) || fixDigit(matchedData?.spreadInUsdPerLot, digit),
                averageSpreadInUsdPerLot:
                    fixDigit(currentSocketData?.averageSpreadInUsdPerLot, digit) || fixDigit(matchedData?.averageSpreadInUsdPerLot, digit),
                dailyChangeAskPercentage: currentSocketData?.dailyChangeAskPercentage || matchedData?.dailyChangeAskPercentage,
                dailyChangeBidPercentage: currentSocketData?.dailyChangeBidPercentage || matchedData?.dailyChangeBidPercentage,
                lotSize: d?.lotSize / 100,
                minLot,
                maxLot,
                swapLong,
                swapShort,
                swapRollover3Days,
                marginPercentage,
            };
        });
    };

    const processMergeData = async (symbolData, initialSymbolData) => {
        return await symbolData?.map((dataObj) => {
            const matchingObj = initialSymbolData?.find((initialObj) => initialObj?.symbolId === dataObj?.symbolId);
            return {
                ...matchingObj,
                leverageId: dataObj?.leverageId || matchingObj?.leverageId,
                commissionProfileId: dataObj?.commissionProfileId,
                volumeProfileId: dataObj?.volumeProfileId,
                executionProfileId: dataObj?.executionProfileId,
                protectionProfileId: dataObj?.protectionProfileId,
                swapFreeProfileId: dataObj?.swapFreeProfileId,
                gslScheduleId: dataObj?.gslScheduleId,
                defaultLeverageId: matchingObj?.leverageId,
                defaultCommissionProfileId: matchingObj?.commissionProfileId,
                defaultVolumeProfileId: matchingObj?.volumeProfileId,
                defaultExecutionProfileId: matchingObj?.executionProfileId,
                defaultProtectionProfileId: matchingObj?.protectionProfileId,
                defaultSwapFreeProfileId: matchingObj?.swapFreeProfileId,
                defaultGslScheduleId: matchingObj?.gslScheduleId,
            };
        });
    };

    const processData = async (data) => {
        return await data?.map((d, i) => {
            const matchedData = initialPriceData?.find((item) => item?.symbolId === d?.symbolId) || d; // fallback
            const currentSocketData = socketData?.find((data) => data?.symbolId === d?.symbolId);

            const minLot =
                (
                    volumeProfileData?.find((obj) => obj?.volumeProfileId === d?.volumeProfileId) ||
                    volumeProfileData?.find((obj) => obj?.volumeProfileId === d?.defaultVolumeProfileId)
                )?.minVolume / d?.lotSize;
            const maxLot =
                (
                    volumeProfileData?.find((obj) => obj?.volumeProfileId === d?.volumeProfileId) ||
                    volumeProfileData?.find((obj) => obj?.volumeProfileId === d?.defaultVolumeProfileId)
                )?.maxVolume / d?.lotSize;

            const swapProfile = initialData?.swapAndDividendProfileId?.[0]?.symbol?.find((s) => s?.symbolId === d?.symbolId);
            const swapLong = swapProfile?.swapLong;
            const defaultSwapLong = d?.defaultSwapLong;
            const swapShort = swapProfile?.swapShort;
            const defaultSwapShort = d?.defaultSwapShort;
            const swapRollover3Days = swapProfile?.swapRollover3Days;
            const defaultSwapRollover3Days = d?.defaultSwapRollover3Days;

            const leverage = (
                leverageProfileData?.find((obj) => obj?.leverageId === d?.leverageId) ||
                leverageProfileData?.find((obj) => obj?.leverageId === d?.defaultLeverageId)
            )?.tiers?.[0]?.leverage;
            const marginPercentage = (100 / leverage) * 100;

            return {
                ...d,
                id: i,
                assetClass: assetClassData?.find((obj) => obj?.assetClassId === d?.assetClassId)?.name,
                leverage: leverageProfileData?.find((obj) => obj?.leverageId === d?.leverageId)?.name || null,
                commissionProfile: commissionProfileData?.find((obj) => obj?.commissionProfileId === d?.commissionProfileId)?.name || null,
                volumeProfile: volumeProfileData?.find((obj) => obj?.volumeProfileId === d?.volumeProfileId)?.name || null,
                executionProfile: executionProfileData?.find((obj) => obj?.executionProfileId === d?.executionProfileId)?.name || null,
                protectionProfile: protectionProfileData?.find((obj) => obj?.protectionProfileId === d?.protectionProfileId)?.name || null,
                swapFreeProfile: swapFreeProfileData?.find((obj) => obj?.swapFreeProfileId === d?.swapFreeProfileId)?.name || null,
                gslSchedule: gslScheduleData?.find((obj) => obj?.gslScheduleId === d?.gslScheduleId)?.name || null,
                defaultLeverage: leverageProfileData?.find((obj) => obj?.leverageId === d?.defaultLeverageId)?.name,
                defaultCommissionProfile: commissionProfileData?.find((obj) => obj?.commissionProfileId === d?.defaultCommissionProfileId)?.name,
                defaultVolumeProfile: volumeProfileData?.find((obj) => obj?.volumeProfileId === d?.defaultVolumeProfileId)?.name,
                defaultExecutionProfile: executionProfileData?.find((obj) => obj?.executionProfileId === d?.defaultExecutionProfileId)?.name,
                defaultProtectionProfile: protectionProfileData?.find((obj) => obj?.protectionProfileId === d?.defaultProtectionProfileId)?.name,
                defaultSwapFreeProfile: swapFreeProfileData?.find((obj) => obj?.swapFreeProfileId === d?.defaultSwapFreeProfileId)?.name,
                defaultGslSchedule: gslScheduleData?.find((obj) => obj?.gslScheduleId === d?.defaultGslScheduleId)?.name,
                bid: fixDigit(currentSocketData?.bid, currentSocketData?.digits) || fixDigit(matchedData?.bid, matchedData?.digits),
                ask: fixDigit(currentSocketData?.ask, currentSocketData?.digits) || fixDigit(matchedData?.ask, matchedData?.digits),
                spreadInPoints: currentSocketData?.spreadInPoints || matchedData?.spreadInPoints,
                averageSpreadInPoints: currentSocketData?.averageSpreadInPoints || matchedData?.averageSpreadInPoints,
                spreadInUsd: fixDigit(currentSocketData?.spreadInUsd, digit) || fixDigit(matchedData?.spreadInUsd, digit),
                averageSpreadInUsd: fixDigit(currentSocketData?.averageSpreadInUsd, digit) || fixDigit(matchedData?.averageSpreadInUsd, digit),
                spreadInUsdPerLot: fixDigit(currentSocketData?.spreadInUsdPerLot, digit) || fixDigit(matchedData?.spreadInUsdPerLot, digit),
                averageSpreadInUsdPerLot:
                    fixDigit(currentSocketData?.averageSpreadInUsdPerLot, digit) || fixDigit(matchedData?.averageSpreadInUsdPerLot, digit),
                dailyChangeAskPercentage: currentSocketData?.dailyChangeAskPercentage || matchedData?.dailyChangeAskPercentage,
                dailyChangeBidPercentage: currentSocketData?.dailyChangeBidPercentage || matchedData?.dailyChangeBidPercentage,
                lotSize: d?.lotSize / 100,
                minLot,
                maxLot,
                swapLong,
                defaultSwapLong,
                swapShort,
                defaultSwapShort,
                swapRollover3Days,
                defaultSwapRollover3Days,
                marginPercentage,
            };
        });
    };

    const columns = [
        {
            title: t("Symbol"),
            dataIndex: "name",
            key: "name",
            // defaultSortOrder: "ascend",
            // sorter: (a, b) => a.name.localeCompare(b.name),
            ...getColumnSearchProps(),
            render: (text) => (text ? text : "-"),
        },
        {
            title: t("Bid"),
            dataIndex: "bid",
            key: "bid",
            align: "right",
            ...getColumnSearchProps(),
            render: (text) => (text ? text : "-"),
        },
        {
            title: t("Ask"),
            dataIndex: "ask",
            key: "ask",
            align: "right",
            ...getColumnSearchProps(),
            render: (text) => (text ? text : "-"),
        },
        {
            title: t("Spread in Points"),
            dataIndex: "spreadInPoints",
            key: "spreadInPoints",
            align: "right",
            ...getColumnSearchProps(),
            render: (text) => (text ? text : "-"),
        },
        {
            title: t("Avg. Spread in Points"),
            dataIndex: "averageSpreadInPoints",
            key: "averageSpreadInPoints",
            align: "right",
            ...getColumnSearchProps(),
            render: (text) => (text ? text : "-"),
        },
        {
            title: t("Spread in USD"),
            dataIndex: "spreadInUsd",
            key: "spreadInUsd",
            align: "right",
            ...getColumnSearchProps(),
            render: (text) => (text ? text : "-"),
        },
        {
            title: t("Avg. Spread in USD"),
            dataIndex: "averageSpreadInUsd",
            key: "averageSpreadInUsd",
            align: "right",
            ...getColumnSearchProps(),
            render: (text) => (text ? text : "-"),
        },
        {
            title: t("Spread in USD / Lot"),
            dataIndex: "spreadInUsdPerLot",
            key: "spreadInUsdPerLot",
            align: "right",
            ...getColumnSearchProps(),
            render: (text) => (text ? text : "-"),
        },
        {
            title: t("Avg. Spread in USD / Lot"),
            dataIndex: "averageSpreadInUsdPerLot",
            key: "averageSpreadInUsdPerLot",
            align: "right",
            ...getColumnSearchProps(),
            render: (text) => (text ? text : "-"),
        },
        {
            title: t("Long (pips)"),
            dataIndex: "swapLong",
            key: "swapLong",
            align: "right",
            ...getColumnSearchProps(),
            render: (text, record) => {
                return record?.swapLong ? <p style={{color: "#00e500"}}>{record?.swapLong}</p> : <p>{record?.defaultSwapLong}</p>;
            },
        },
        {
            title: t("Short (pips)"),
            dataIndex: "swapShort",
            key: "swapShort",
            align: "right",
            ...getColumnSearchProps(),
            render: (text, record) => {
                return record?.swapShort ? <p style={{color: "#00e500"}}>{record?.swapShort}</p> : <p>{record?.defaultSwapShort}</p>;
            },
        },
        {
            title: t("3-day Swap"),
            dataIndex: "swapRollover3Days",
            key: "swapRollover3Days",
            align: "right",
            ...getColumnSearchProps(),
            render: (text, record) => {
                return record?.swapRollover3Days ? (
                    <p style={{color: "#00e500"}}>{record?.swapRollover3Days}</p>
                ) : (
                    <p>{record?.defaultSwapRollover3Days}</p>
                );
            },
        },
        {
            title: t("Margin (%)"),
            dataIndex: "marginPercentage",
            key: "marginPercentage",
            align: "right",
            ...getColumnSearchProps(),
            render: (text) => (text ? text?.toLocaleString(undefined, {maximumFractionDigits: 2}) : "-"),
        },
        {
            title: t("Min Lot"),
            dataIndex: "minLot",
            key: "minLot",
            align: "right",
            ...getColumnSearchProps(),
            render: (text) => (text ? text : "-"),
        },
        {
            title: t("Max Lot"),
            dataIndex: "maxLot",
            key: "maxLot",
            align: "right",
            ...getColumnSearchProps(),
            render: (text) => (text ? text : "-"),
        },

        {
            title: t("Lot Size"),
            dataIndex: "lotSize",
            key: "lotSize",
            align: "right",
            ...getColumnSearchProps(),
            render: (text) => (text ? text : "-"),
        },
        {
            title: t("Asset Class"),
            dataIndex: "assetClass",
            key: "assetClass",
            ...getColumnSearchProps(),
            render: (text) => (text ? text : "-"),
        },
        {
            title: t("Leverage"),
            dataIndex: ["leverage"],
            key: "leverage",
            ...getColumnSearchProps(),
            render: (text, record) => {
                return record?.leverage ? <p style={{color: "#00e500"}}>{record?.leverage}</p> : <p>{record?.defaultLeverage}</p>;
            },
        },
        {
            title: t("Commission"),
            dataIndex: ["commissionProfile"],
            key: "commissionProfile",
            ...getColumnSearchProps(),
            render: (text, record) => {
                return record?.commissionProfile ? (
                    <p style={{color: "#00e500"}}>{record?.commissionProfile}</p>
                ) : (
                    <p>{record?.defaultCommissionProfile}</p>
                );
            },
        },
        {
            title: t("Volume"),
            dataIndex: ["volumeProfile"],
            key: "volumeProfile",
            ...getColumnSearchProps(),
            render: (text, record) => {
                return record?.volumeProfile ? <p style={{color: "#00e500"}}>{record?.volumeProfile}</p> : <p>{record?.defaultVolumeProfile}</p>;
            },
        },
        {
            title: t("Protection"),
            dataIndex: ["protectionProfile"],
            key: "protectionProfile",
            ...getColumnSearchProps(),
            render: (text, record) => {
                return record?.protectionProfile ? (
                    <p style={{color: "#00e500"}}>{record?.protectionProfile}</p>
                ) : (
                    <p>{record?.defaultProtectionProfile}</p>
                );
            },
        },
        {
            title: t("Swap"),
            dataIndex: ["swapFreeProfile"],
            key: "swapFreeProfile",
            ...getColumnSearchProps(),
            render: (text, record) => {
                return record?.swapFreeProfile ? (
                    <p style={{color: "#00e500"}}>{record?.swapFreeProfile}</p>
                ) : (
                    <p>{record?.defaultSwapFreeProfile}</p>
                );
            },
        },
        {
            title: t("GSL Schedule"),
            dataIndex: ["gslSchedule"],
            key: "gslSchedule",
            ...getColumnSearchProps(),
            render: (text, record) => {
                return record?.gslSchedule ? <p style={{color: "#00e500"}}>{record?.gslSchedule}</p> : <p>{record?.defaultGslSchedule}</p>;
            },
        },
    ];

    // const tableData = data?.sort((a: any, b: any) => a?.symbolId - b?.symbolId);
    const tableData = data?.sort((a: any, b: any) => a?.name?.localeCompare(b?.name));

    return (
        <>
            <div className={"display-flex margin-bottom-0-75"}>
                <Space className={"margin-left-auto"}>
                    <EditTableUserPreferenceButton tableName={"Symbol Settings"} server={server?.name} tableColumn={columns} />
                    <ExportTableButton tableData={exportData} tableColumn={columns} tableName={"Symbol Settings"} server={server?.name} />
                </Space>
            </div>
            <TableAntDesign
                rowKey={"symbolId"}
                columns={columns}
                data={!loading && tableData}
                size={"small"}
                tableName={"Symbol Settings"}
                server={server?.name}
                loading={loading}
                pagination={{
                    showSizeChanger: true,
                    total: tableData?.length,
                    showTotal: (total) => <p>{t("pagination.totalItems", {totalCount: total})}</p>,
                }}
                isTableLocalSearch={true}
            />
        </>
    );
};

export default SymbolSettingPanel;
