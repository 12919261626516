import React from "react";
import {useTranslation} from "react-i18next";
import {Modal} from "antd";
import NavigationRouteSettingForm from "./navigationRouteSettingForm";

const NavigationRouteSettingModal = (props) => {
    const {mode, item, type, isOpen, closeModal} = props;
    const {t} = useTranslation();

    const getTitle = (): String => {
        switch (mode) {
            case "add":
                return `${t("Add New Navigation Route")}`;
            case "view":
                return `${t("View Navigation Route")}`;
            case "edit":
                return `${t("Edit Navigation Route")}`;
            default:
                return `${t("Add New Navigation Route")}`;
        }
    };
    return (
        <Modal getContainer={"#navigationRouteSetting"} title={getTitle()} open={isOpen} width={"50%"} footer={null} destroyOnClose onCancel={closeModal}>
            <NavigationRouteSettingForm mode={mode} item={item} closeModal={closeModal} type={type}/>
        </Modal>
    );
};

export default NavigationRouteSettingModal;
