import React, { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import yupFormSchema from "../../../../../../components/yup/yupFormSchema";
import InputFormItem from "../../../../../../components/form/inputFormItem";
import { verticalFormLayout } from "../../../../../../components/form/formWrapper";

import { FormProvider, useForm } from "react-hook-form";
import { Button, Col, Form, message, Modal, Row } from "antd";
import { useTranslation } from "react-i18next";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { useSendEmailMutation } from "../../../../../../api/graphql/emailTemplate";
import SelectFormItem from "../../../../../../components/form/selectFormItem";
import useAuthorize from "../../../../../../_common/authorize";

const emailTemplateSchema = yup.object().shape({
  to: yupFormSchema.email("Email", { required: true }),
  language: yupFormSchema.string("language"),
});

const TestSendEmailForm = (props) => {
  const { isOpen, templateName, closeModal } = props;
  const { t } = useTranslation();

  const [isRequesting, setIsRequesting] = useState(false)

  const [initialValues] = useState(() => ({
    to: "",
    language: "en",
  }));

  const { sendEmail } = useSendEmailMutation();
  const { systemErrorNotification } = useAuthorize();

  const form = useForm({
    resolver: yupResolver(emailTemplateSchema),
    mode: "all",
    defaultValues: initialValues,
  });

  const onCancel = () => {
    closeModal();
    form.reset();
  };

  const onSubmit = async (values) => {
    try {
      setIsRequesting(true)
      const result = {
        name: templateName,
        to: [values?.to],
        language: values?.language,
      };
      await sendEmail(result);
    } catch (e) {
      console.log(e);
      systemErrorNotification();
    } finally {
      setIsRequesting(false)
    }
  };

  return (
    <Modal
      getContainer="#emailTemplate"
      className="template-editor-1"
      title={t("Test Send Email Template")}
      open={isOpen}
      width={"80%"}
      footer={null}
      destroyOnClose
    >
      <FormProvider {...form}>
        <form
          onSubmit={form?.handleSubmit(onSubmit)}
          className="testsendemail-form-1"
        >
          <Row gutter={10}>
            <Col span={12}>
              <InputFormItem
                name={"to"}
                label={"Email"}
                placeholder={"Email"}
                layout={verticalFormLayout}
                required
              />
            </Col>
            <Col span={12}>
              <SelectFormItem
                name={"language"}
                label={t("Language")}
                placeholder={t("Language")}
                options={[
                  {
                    label: t("English"),
                    value: "en",
                  },
                  {
                    label: t("Chinese"),
                    value: "cn",
                  },
                ]}
                layout={verticalFormLayout}
              />
            </Col>
          </Row>

          <Form.Item
            className="text-align-right"
            style={{ paddingTop: "12px" }}
          >
            {onCancel && (
              <Button
                onClick={onCancel}
                icon={<CloseOutlined />}
                className="button-cancel-1"
                loading={isRequesting}
              >
                {t("common.cancel")}
              </Button>
            )}
            <Button
              onClick={form?.handleSubmit(onSubmit)}
              icon={<SaveOutlined />}
              className="button-submit-1"
              disabled={isRequesting}
              loading={isRequesting}
            >
              {t("common.submit")}
            </Button>
          </Form.Item>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default TestSendEmailForm;
