import React, {useEffect, useState} from "react";
import {Button} from "antd";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {getUserTableAdminSetting, getUserTableSetting} from "../../../api/graphql/tableSetting";

const ExportTableButton = (props) => {
    const {tableData, tableColumn, tableName, server, loading: dataLoading} = props
    const {t} = useTranslation()
    const [loading, setLoading] = useState<boolean>(false);
    const [modifiedTableColumn, setModifiedTableColumns] = useState<any>(tableColumn)
    const serverName = server?.name || ""

    const exportColumn = modifiedTableColumn?.map((d, i) => {
        return (Array?.isArray(d?.dataIndex) ? d?.dataIndex?.join("_") : d?.dataIndex)
    })

    const {data: adminSettingTable, loading: adminSettingLoading} = getUserTableAdminSetting({
        filter: {
            tableName: tableName,
            serverName: serverName || null
        }
    })

    const {
        data: userTablePreference,
        loading: userSettingLoading
    } = getUserTableSetting(localStorage.getItem("userId"), {
        filter: {
            tableName: tableName,
            serverName: serverName || null
        }
    })

    const formatColumns = async () => {
        if (userTablePreference?.getUserTableSetting?.tableColumn?.length > 0 && adminSettingTable?.getUserTableAdminSetting?.data?.[0]?.fieldData?.length > 0) {
            const userPreference = userTablePreference.getUserTableSetting.tableColumn;
            const adminPreference = adminSettingTable.getUserTableAdminSetting?.data?.[0].fieldData;
            return await userPreference.map((d) => {
                const matchingAdminObj = adminPreference.find(
                    (obj) =>
                        obj?.fieldName === d?.columnName ||
                        (Array.isArray(obj?.fieldName) && obj?.fieldName.join("_") === d?.columnName)
                )

                const matchingObj = tableColumn.find(
                    (obj) =>
                        (obj?.dataIndex === d?.columnName ||
                            (Array.isArray(obj?.dataIndex) && obj?.dataIndex.join("_") === d?.columnName)) &&
                        matchingAdminObj?.isSelectableByUser
                );
                return matchingObj
            }).filter(d => d?.key)
        } else if (userTablePreference?.getUserTableSetting?.tableColumn?.length > 0 && !adminSettingTable?.getUserTableAdminSetting?.data?.[0]?.fieldData?.length) {
            const userPreference = userTablePreference.getUserTableSetting.tableColumn;
            return await userPreference.map((d) => {
                const matchingObj = tableColumn.find(
                    (obj) =>
                        obj?.dataIndex === d?.columnName ||
                        (Array.isArray(obj?.dataIndex) && obj?.dataIndex.join("_") === d?.columnName)
                );
                return matchingObj;
            })
        } else if (adminSettingTable?.getUserTableAdminSetting?.data?.[0]?.fieldData?.length > 0 && !userTablePreference?.getUserTableSetting?.tableColumn?.length) {
            const userPreference = adminSettingTable.getUserTableAdminSetting?.data?.[0].fieldData;
            return await userPreference
                .filter((d) => d?.isDisplayToUser)
                .map((d) => {
                    const matchingObj = tableColumn.find(
                        (obj) =>
                            obj?.dataIndex === d?.fieldName ||
                            (Array.isArray(obj?.dataIndex) && obj?.dataIndex.join("_") === d?.columnName)
                    )
                    return matchingObj;
                })
        } else {
            return await tableColumn
                .map((d) => {
                    return d;
                })
                .filter((d) => !(d?.key === "action"))
        }
    };

    useEffect(() => {
        if (!adminSettingLoading && !userSettingLoading && tableColumn?.length > 0) {
            formatColumns()?.then(data => setModifiedTableColumns(data?.sort((a, b) => a?.defaultPosition - b?.defaultPosition)))
        }
    }, [adminSettingTable?.getUserTableAdminSetting?.data, userTablePreference?.getUserTableSetting?.tableColumn, adminSettingLoading, userSettingLoading, tableColumn])

    useEffect(() => {
        setLoading(dataLoading);
    }, [dataLoading]);


    const exportColumnData = tableData?.map((item) => {
        return exportColumn?.reduce((obj, key) => {
            obj[key] = item[key];
            return obj;
        }, {});
    });

    const handleExport = (
        tableData,
        tableName,
        serverName,
        fileType: any = "xlsx"
    ) => {
        setLoading(true)
        var fileFormat =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;";
        if (fileType === "csv") {
            fileFormat = "text/csv;charset=utf-8";
        }
        if (!tableData) {
            return null;
        }
        var tempFile = tableData.map((item) => {
            delete item?.ids;
            return item;
        });
        let header = Object.keys(tempFile[0] || []);
        const index = header.findIndex((item) => item === "en");
        if (index !== -1) {
            const item = header.splice(index, 1)[0];
            header.splice(1, 0, item);
        }
        const ws = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [header]);
        XLSX.utils.sheet_add_json(ws, tempFile, {origin: "A2", skipHeader: true});
        const wb = {Sheets: {data: ws}, SheetNames: ["data"]};
        const buffer = XLSX.write(wb, {
            bookType: fileType,
            type: "array",
            cellStyles: true,
        });
        const finalData = new Blob([buffer], {type: fileFormat});
        const currentDate = moment().format("YYYYMMDDHHmm");

        if(finalData) {
            FileSaver.saveAs(finalData, `${tableName}${serverName ? `_${serverName}` : ""}_${currentDate}`);
            setLoading(false)
        }
    };

    return (
        <Button
            onClick={() => handleExport(exportColumnData, tableName, serverName)}
            loading={loading}
            disabled={!exportColumnData?.length || loading}
        >
            {t("common.export")}
        </Button>
    );
};

export default ExportTableButton;
